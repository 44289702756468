// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';
import {
  getMyParticipantWhenSignatory,
  getUndecidedSignatories,
  isAgreementOwner,
  getAgreementMyParty,
} from 'agreement/selectors';
import { isViewer } from 'agreement/participant';
import hasDeliveryChannelEmail from 'agreement/participant/has-delivery-channel-email';

import {
  MultistepModal,
  ModalStep,
  type ModalStepRenderProps,
} from 'components/modal';
import Confirmable from 'components/confirmable';
import GuestConversion from 'components/modals/guest-conversion';
import CircularSpinner from 'components/icons/circular-spinner';
import Download from 'components/icons/download';
import Documents from 'components/icons/documents';
import Button from 'components/button';
import ParticipantBubble from 'components/contract-card/participant-bubble';
import { UserBadge } from 'components/user-badge';
import DynamicLink from 'components/dynamic-link';
// eslint-disable-next-line import/no-named-as-default
import SendEmailForm from 'components/modals/sign-contract/success/send-email-form';
import listItemsWithAnd from 'utils/list-items-with-and';
import { checkAcl } from 'components/acl';

import style from './signing-completed.module.scss';

export type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  agreementId: number,
  agreement: Agreement,
  disabled: boolean,
  myParticipant: AgreementParticipant,
  guestToken: string,
  rpcState: RpcState,
  onSuccess: () => void,
  sendAgreementCopy: () => void,
  setSignedModalOpen: (boolean) => void,
  isOpen: boolean,
  link: string,
  onClose?: () => void,
  isGuestConversionFeaturedEnabled: boolean,
  hasUser: boolean,
  modalKey: string,
};

type State = {
  isModalOpen: boolean,
};

const GUEST_CONVERSION_MODAL = 'guest conversion modal';
export class SigningCompleted extends React.PureComponent<Props, State> {
  state = {
    isModalOpen: this.props.isOpen,
  }

  static defaultProps = {
    onClose: undefined,
  };

  componentDidMount() {
    const { setSignedModalOpen, myParticipant, guestToken } = this.props;
    const { signRedirectUrl, agreement } = myParticipant;

    setSignedModalOpen(true);

    if (signRedirectUrl) {
      // To avoid potential ad blocking issues we send the user through our api
      // but we show the actual url in the modal
      let actualSignRedirectUrl = `/api/agreements/${agreement.id}/participants/${myParticipant.id}/sign_redirect`;
      if (guestToken) {
        actualSignRedirectUrl = `${actualSignRedirectUrl}?at=${guestToken}`;
      }

      this._redirectTimer = setTimeout(() => {
        const link = document.createElement('a');
        link.href = actualSignRedirectUrl;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, 3000);

      this._redirectTimer.unref?.();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { rpcState, onSuccess } = this.props;

    if (!onSuccess || !rpcState) {
      return;
    }

    if (!prevProps.rpcState.success && rpcState.success) {
      onSuccess();
    }
  }

  componentWillUnmount() {
    clearTimeout(this._redirectTimer);
  }

  isGuestConversionVisible = () => {
    const {
      myParticipant,
      isGuestConversionFeaturedEnabled,
      hasUser,
      agreement,
    } = this.props;
    const { account } = myParticipant;
    const { individual } = getAgreementMyParty(agreement);

    return Boolean(
      !account
      && !individual
      && !hasUser
      && !isAgreementOwner(account, agreement)
      && isGuestConversionFeaturedEnabled
      && hasDeliveryChannelEmail(myParticipant),
    );
  }

  getUndecidedSignatories() {
    const { agreement } = this.props;

    return getUndecidedSignatories(agreement);
  }

  getNames() {
    const fullnames = this.getUndecidedSignatories().map((participant) => participant.fullname);
    return listItemsWithAnd(fullnames);
  }

  renderDownloadPdf = () => {
    const { disabled, agreement } = this.props;

    if (!checkAcl(agreement.acl, 'agreement:download:pdf')) {
      return null;
    }

    const DownloadIconComponent: React.ComponentType<IconProps> = Download;
    const LoadIconComponent: React.ComponentType<IconProps> = CircularSpinner;

    if (disabled) {
      return (
        <>
          <LoadIconComponent />
          <div className={style.LinkText}>
            <Message
              id="Creating signed PDF"
              comment="Label for Creating signed PDF download link when loading in progress a modal."
            />
          </div>
        </>
      );
    }

    return (
      <>
        <DownloadIconComponent />
        <div className={style.LinkText}>
          <Message
            id="Download as PDF"
            comment="Label for download as pdf link in a modal."
          />
        </div>
      </>
    );
  }

  renderConfirmation = () => {
    const {
      agreement,
      guestToken,
      myParticipant,
    } = this.props;

    const isSingleSignEnabled = agreement.config?.singleSign;

    // remove this when API supports sending copy to viewers/updating participant email by a viewer
    if (isViewer(myParticipant) && guestToken) {
      return null;
    }

    if (isSingleSignEnabled) {
      return (
        <p className={style.ConfirmText}>
          <Message
            id="You will be sent a link to the signed version of this document. You can also download it as PDF using the link below."
            comment="Explaning text in a modal when the signing has been finished by all parties."
          />
        </p>
      );
    }

    if (myParticipant
      && myParticipant.email
      && myParticipant.email.length > 0
    ) {
      return (
        <span className={style.ConfirmText}>
          <Message
            id="You will be sent a link to the signed version of this document. You can also download it as PDF using the link below."
            comment="Explaning text in a modal when the signing has been finished by all parties."
          />
        </span>
      );
    }

    return (
      <div className={style.SignEmailContainer} data-testid="sendEmailForm">
        <p>
          <Message
            id="Would you like to receive a copy of this contract by email?"
            comment="The message shown in the success modal when users sign a contract."
          />
        </p>
        <p>
          <Message
            id='Fill in your email address below and click "send" to receive a copy of this contract in your inbox once all parties have signed.'
            comment="The message shown in the success modal when users sign a contract."
          />
        </p>
        <SendEmailForm
          agreement={agreement}
          guestToken={guestToken}
          myParticipant={myParticipant}
          onSuccess={
            () => { this.sendConcludedCopy(); }
          }
        />
      </div>
    );
  }

  renderGuestConversionBody = () => {
    const { myParticipant } = this.props;
    const { signRedirectUrl } = myParticipant;
    const isGuestConversionVisible = this.isGuestConversionVisible();

    if (!isGuestConversionVisible || signRedirectUrl) {
      return null;
    }

    return (
      <div className={style.GuestConversion}>
        <Documents
          width="106"
          height="104"
        />
        <h2 className={style.Heading}>
          <Message
            id="Want to make your own smooth, digital contracts?"
            comment="Heading in the first step of the Guest Conversion signup flow."
          />
        </h2>
        <p>
          <Message
            id="Create a free Oneflow account and be on your way to the future of contracts! 💫"
            comment="Explanatory paragraph in the first step of the Guest Conversion signup flow."
          />
        </p>
      </div>
    );
  };

  renderSignRedirectBody = () => {
    const { myParticipant } = this.props;
    const { signRedirectUrl } = myParticipant;

    if (!signRedirectUrl) {
      return null;
    }

    return (
      <div className={style.SignRedirectBody}>
        <h2 className={style.Heading}>You are being redirected</h2>
        <p>
          <Message
            id="This document's owner is sending you to {signRedirectUrl}. Please wait a few moments or click the link yourself."
            comment="Text for counterparties when the sign redirect functionality is active"
            values={{
              signRedirectUrl: (
                <>
                  <DynamicLink
                    url={signRedirectUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    hideExternalIcon
                  >
                    {signRedirectUrl}
                  </DynamicLink>
                </>
              ),
            }}
          />
        </p>

      </div>
    );
  }

  renderDoneButton = () => {
    const { myParticipant } = this.props;
    const { signRedirectUrl } = myParticipant;
    const isGuestConversionVisible = this.isGuestConversionVisible();

    if (isGuestConversionVisible || signRedirectUrl) {
      return null;
    }

    return (
      <div className={style.ButtonContainer}>
        <Button
          kind="primary"
          data-testid="confirm"
          onClick={this.handleClose}
        >
          <Message
            id="Done"
            comment="Text for button to confirm action."
          />
        </Button>
      </div>
    );
  };

  renderBody = () => {
    const {
      link,
      disabled,
      agreement,
    } = this.props;
    const disabledLink = disabled ? style.DisabledLinkContainer : '';
    const LinkContainer = clsx(
      style.LinkContainer,
      disabledLink,
    );
    const modalContainerClasses = clsx(style.ModalContainer, {
      [style.ModalContainerHeight]: this.isGuestConversionVisible(),
    });
    const successCheckAnimationClasses = clsx('success_check_animation', style.SuccessCheckAnimation);
    const undecidedSignatories = getUndecidedSignatories(agreement);
    const isSingleSignEnabled = agreement.config?.singleSign;
    const myParticipantWhenSignatory = getMyParticipantWhenSignatory(agreement);

    if (!undecidedSignatories.length || isSingleSignEnabled) {
      return (
        <div className={modalContainerClasses}>
          <div className={style.SigningContainer}>
            <div className={successCheckAnimationClasses} />
            <h2 className={style.Heading}>
              <Message
                id="Signing complete"
                comment="Explaning text in a modal when the signing has been finished by all parties."
              />
            </h2>
            {this.renderConfirmation()}
            <div>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={LinkContainer}
              >
                {this.renderDownloadPdf()}
              </a>
            </div>
          </div>
          {this.renderGuestConversionBody()}
          {this.renderSignRedirectBody()}
          {this.renderDoneButton()}
        </div>
      );
    }

    return (
      <div className={modalContainerClasses}>
        <div>
          <div className={style.SigningContainer}>
            <div className={style.SuccessCheckAnimationContainer}>
              <ParticipantBubble
                participant={myParticipantWhenSignatory}
                isContractMarkedAsSigned={false}
                agreement={agreement}
                className={style.MyParticipantBubble}
                renderBadge={() => (
                  <UserBadge
                    diameter={66}
                    borderSize={2}
                    borderColor={style.darkgray}
                  />
                )}
              />
              <div className={successCheckAnimationClasses} />
            </div>
            <h2 className={style.Heading}>
              <Message
                id="You have successfully signed"
                comment="The message shown in the success modal when the current user has successfully signed."
              />
            </h2>
            <div className={style.ConfirmText}>
              <Message
                id="Waiting for {names} to sign. When all parties have signed the document, you will be sent a link to the signed version."
                comment="The message shown in the success modal about awaiting signatures."
                values={{
                  names: <strong>{this.getNames()}</strong>,
                }}
              />
            </div>
          </div>
        </div>
        {this.renderGuestConversionBody()}
        {this.renderSignRedirectBody()}
        {this.renderDoneButton()}
      </div>
    );
  };

  getActions = (onStepComplete: () => void) => () => {
    // eslint-disable-next-line
    const { myParticipant } = this.props;
    const { signRedirectUrl } = myParticipant;

    // eslint-disable-next-line
    const isGuestConversionVisible = this.isGuestConversionVisible();

    if (!isGuestConversionVisible || signRedirectUrl) {
      return null;
    }

    return (
      <div className={style.Buttons}>
        <Button
          kind="linkSeparate"
          // eslint-disable-next-line
          onClick={this.handleClose}
        >
          <Message
            id="No thanks"
            comment="Button text in the Guest Conversion signup flow"
          />
        </Button>
        <Button
          kind="primary"
          onClick={onStepComplete}
        >
          <Message
            id="Create free account"
            comment="Button text in the Guest Conversion signup flow"
          />
        </Button>
      </div>
    );
  };

  handleClose = () => {
    const { onClose, setSignedModalOpen } = this.props;
    this.setState({
      isModalOpen: false,
    });

    if (onClose) {
      setTimeout(() => {
        setSignedModalOpen(false);
      }, 1000);

      onClose();
    }
  };

  sendConcludedCopy = () => {
    const {
      sendAgreementCopy,
      agreement,
      myParticipant,
      rpcState,
    } = this.props;

    if (!myParticipant || !agreement || rpcState.loading) {
      return;
    }

    sendAgreementCopy(agreement.id, myParticipant.id);
  };

  renderSigningCompletedModal: ModalStepRenderProps = ({ onStepComplete }) => {
    const { modalKey } = this.props;
    const isGuestConversionVisible = this.isGuestConversionVisible();
    const { isModalOpen } = this.state;

    return (
      <Confirmable
        actions={this.getActions(onStepComplete)}
        body={this.renderBody()}
        header={(
          <Message
            id="Document signing"
            comment="Modal title when the signing has been finished by all parties."
          />
        )}
        hideFooter={!isGuestConversionVisible}
        isOpen={isModalOpen}
        modalKey={modalKey}
        onClose={this.handleClose}
      />
    );
  };

  renderGuestConversionModal: ModalStepRenderProps = () => {
    const { agreementId } = this.props;
    const { isModalOpen } = this.state;

    return (
      <GuestConversion
        agreementId={agreementId}
        isSecondStep
        onClose={this.handleClose}
        isOpen={isModalOpen}
        modalKey={GUEST_CONVERSION_MODAL}
      />
    );
  };

  render() {
    const { modalKey } = this.props;
    const { isModalOpen } = this.state;

    return (
      <MultistepModal isOpen={isModalOpen}>
        <ModalStep modalKey={modalKey}>
          {this.renderSigningCompletedModal}
        </ModalStep>
        <ModalStep modalKey={GUEST_CONVERSION_MODAL}>
          {this.renderGuestConversionModal}
        </ModalStep>
      </MultistepModal>
    );
  }
}
