// @flow

import React, { useMemo } from 'react';
import { formatDateString } from 'date';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { getParticipantById, getAgreementMyParty } from 'agreement/selectors';
import { isIndividual } from 'agreement/party';

import { isContractBoxPristine } from 'reducers/current-contract';

import {
  isAnnotation,
  isAnnotationResolved,
  isCommentResolved,
  isOrphanComment,
  isSuggestion,
  isSuggestionAccepted,
  isSuggestionDeleted,
  isSuggestionRejected,
} from 'comments';

import useAgreement from 'hooks/use-agreement';
import { isSmallScreenWidth } from 'ui/config';

import ActionButton from 'components/messages-layout/single-message/action-button';
import AuthorBubble from 'components/messages-layout/author-bubble';
import Message from 'components/message';
import SendTo from 'components/document-tabs/messages-tab/send-to';
import PrivacyIndicator from 'components/messages-layout/privacy-indicator';
import type { AmplitudeData } from 'components/messages-layout/single-message-box/single-message-box';

import CommentOrChatContent from './single-message-content/comment-or-chat-content';
import SuggestionContent from './single-message-content/suggestion-content';

import style from './single-message.module.scss';

type Props = {
  agreementId: number,
  amplitudeData?: AmplitudeData,
  boxId?: number,
  guestToken: string,
  isInteractive?: boolean,
  message: Message,
}

const MessageType = ({
  message,
  isMyParticipantIndividual,
}: { message: AgreementMessage, isMyParticipantIndividual: boolean }) => {
  const isMessageTypeVisible = (
    !isMyParticipantIndividual && message.private && isEmpty(message.parent)
  );

  if (!isMessageTypeVisible) {
    return null;
  }

  return (
    <PrivacyIndicator isPrivate={message.private} />
  );
};

const RecipientTag = ({ message }: { message: Message }) => {
  let recipient = null;
  /**
   * Since currently we only have single mention, so
   * the following logic will be always true util we
   * want to do multi mentions
   */
  if (message.mentionedParticipants?.length) {
    // Always the first one atm.
    [recipient] = message.mentionedParticipants;
  }

  if (!recipient) {
    return null;
  }

  return (
    <div className={style.RecipientTags}>
      <SendTo
        recipientLabel={recipient.name || recipient.partyName}
        maxLength={30}
      />
    </div>
  );
};

const SingleMessage = ({
  agreementId,
  amplitudeData,
  boxId,
  guestToken,
  isInteractive,
  message,
}: Props) => {
  const isSmallScreen = isSmallScreenWidth();
  const agreement = useAgreement(agreementId);
  const myParty = getAgreementMyParty(agreement);
  const isMyParticipantIndividual = isIndividual(myParty);
  const isPristine = useSelector((state) => isContractBoxPristine(state, boxId));
  const isMessageResolvable = isAnnotation(message);
  const isResolved = isAnnotationResolved(message);

  const disabled = useMemo(() => {
    if (isSuggestion(message)) {
      return false;
    }

    return !isPristine;
  }, [isPristine, message]);

  const authorParticipant = useMemo(() => (
    getParticipantById(agreement, message.ownerParticipant.id)
  ), [agreement, message.ownerParticipant.id]);

  const {
    id,
    body,
    created,
    subject,
    annotationContent,
  } = message;

  const formattedCreatedDate = formatDateString(created, `${agreement?.config?.dateFormat || 'MMM D'}, HH:mm`);

  const renderRejectButton = () => {
    if (!isSuggestion(message) || (isSmallScreen && Boolean(guestToken))) {
      return null;
    }

    return (
      <ActionButton
        disabled={disabled}
        message={message}
        action="reject"
        amplitudeData={amplitudeData}
      />
    );
  };

  const renderStatusPill = () => {
    if (!isResolved) {
      return null;
    }

    const suggestionAccepted = isSuggestionAccepted(message);
    const suggestionRejected = isSuggestionRejected(message);
    const suggestionDeleted = isSuggestionDeleted(message);
    const commentResolved = isCommentResolved(message);
    const orphanComment = isOrphanComment(message);

    const getStatus = () => {
      if (suggestionAccepted) {
        return <Message id="Accepted" comment="Label for an accepted suggestion." />;
      }

      if (suggestionRejected) {
        return <Message id="Rejected" comment="Label for a rejected suggestion." />;
      }

      if (suggestionDeleted) {
        return <Message id="Deleted" comment="Label for a deleted suggestion." />;
      }

      if (commentResolved) {
        return <Message id="Resolved" comment="Label for a resolved comment." />;
      }

      if (orphanComment) {
        return <Message id="Deleted" comment="Label for a deleted comment." />;
      }

      return null;
    };

    const statusPillClassNames = clsx(style.StatusPill, {
      [style.GreenPill]: commentResolved || suggestionAccepted,
      [style.RedPill]: suggestionRejected || suggestionDeleted,
    });

    return (
      <div className={statusPillClassNames}>
        {getStatus()}
      </div>
    );
  };

  const renderActionButtons = () => {
    if (!isMessageResolvable || isResolved) {
      return null;
    }

    return (
      <div className={style.ActionButtonsWrapper}>
        {renderRejectButton()}
        <ActionButton
          disabled={disabled}
          message={message}
          action="resolve"
          amplitudeData={amplitudeData}
        />
      </div>
    );
  };

  return (
    <div data-id={id} className={style.Message}>
      <div className={style.InfoContainer}>
        <AuthorBubble
          agreementId={agreementId}
          participant={authorParticipant}
          formattedDate={formattedCreatedDate}
          message={message}
        />
        {renderStatusPill()}
        {renderActionButtons()}
      </div>
      <div className={style.RecipientContainer}>
        <MessageType message={message} isMyParticipantIndividual={isMyParticipantIndividual} />
        <RecipientTag message={message} />
      </div>
      <div className={style.Body}>
        {subject && <strong>{subject}</strong>}
        <div className={style.BodyText}>
          {isSuggestion(message)
            ? (
              <SuggestionContent
                annotationContent={annotationContent}
                isInteractive={isInteractive}
              />
            )
            : <CommentOrChatContent content={body} />}
        </div>
      </div>
    </div>
  );
};

export default SingleMessage;
