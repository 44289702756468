import { useSelector } from 'react-redux';
import type { FormRenderProps } from 'react-final-form';

import { getCurrentLanguageSelector } from 'reducers/i18n';
import type { Billing } from 'types/billing';

import ModalForm from 'hocs/modal-form';
import Message from 'components/message';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import Field from 'components/field';
import Button from 'components/button';
import { getCountryListAsOptions } from 'components/countries';

import useEditBillingDetails from './use-edit-billing-details';

type Props = {
  billing: Billing;
  accountId: number;
  isOpen?: boolean;
  closeModal: () => void;
}
const EditBillingDetails = ({
  billing,
  isOpen,
  accountId,
  closeModal,
}:Props) => {
  const {
    formState,
    resetFormState,
    onSubmit,
  } = useEditBillingDetails(accountId, billing);
  const languageCode = useSelector(getCurrentLanguageSelector);

  const renderModalBody = ({
    name,
    vatNumber,
    address,
    zipCode,
    city,
    country,
  }:
  Billing) => {
    const countries = getCountryListAsOptions(languageCode);
    const currentCountry = countries.find((country2) => country2.value === country);

    return (
      <>
        <Field
          name="name"
          label={(
            <Message
              id="Company name"
              comment="Label for input in the edit billing details modal"
            />
          )}
          component={TextField}
          placeholder={(
            <Message
              id="Enter name"
              comment="Placeholder for input in the edit billing details modal"
            />
          )}
          maxLength={50}
          disabled
          initialValue={name}
        />
        <Field
          name="vatNumber"
          label={(
            <Message
              id="VAT number"
              comment="Label for input in the edit billing details modal"
            />
          )}
          component={TextField}
          placeholder={(
            <Message
              id="Enter number"
              comment="Placeholder for input in the edit billing details modal"
            />
          )}
          maxLength={50}
          disabled
          initialValue={vatNumber}
        />
        <Field
          name="address"
          label={(
            <Message
              id="Address"
              comment="Label for input in the edit billing details modal"
            />
          )}
          component={TextField}
          placeholder={(
            <Message
              id="Enter address"
              comment="Placeholder for input in the edit billing details modal"
            />
          )}
          initialValue={address}
          required
          maxLength={100}
        />
        <Field
          name="zipCode"
          label={(
            <Message
              id="ZIP Code"
              comment="Label for input in the edit billing details modal"
            />
          )}
          component={TextField}
          placeholder={(
            <Message
              id="Enter number"
              comment="Placeholder for input in the edit billing details modal"
            />
          )}
          initialValue={zipCode}
          required
          maxLength={12}
        />
        <Field
          name="city"
          label={(
            <Message
              id="City"
              comment="Label for input in the edit billing details modal"
            />
          )}
          component={TextField}
          placeholder={(
            <Message
              id="Enter city"
              comment="Placeholder for input in the edit billing details modal"
            />
          )}
          initialValue={city}
          required
          maxLength={100}
        />
        <Field
          name="country"
          label={(
            <Message
              id="Country"
              comment="Label for input in the edit billing details modal"
            />
          )}
          component={SelectField}
          placeholder={(
            <Message
              id="Enter country"
              comment="Placeholder for input in the edit billing details modal"
            />
          )}
          initialValue={currentCountry}
          options={countries}
          searchable
          disabled
        />
      </>
    );
  };

  const renderActions = ({ formProps }: { formProps: FormRenderProps }) => {
    const disabled = Boolean(
      formProps.pristine
          || formProps.validating
          || formProps.hasValidationErrors
          || formState.loading,
    );

    return (
      <>
        <Button
          onClick={closeModal}
        >
          <Message
            id="Cancel"
            comment="Button text"
          />
        </Button>
        <Button
          onClick={formProps.handleSubmit}
          disabled={disabled}
          kind="primary"
        >
          <Message
            id="Save"
            comment="Button text"
          />
        </Button>
      </>
    );
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Edit billing details"
          comment="Header for the edit billing details modal"
        />
      )}
      isOpen={isOpen}
      onClose={closeModal}
      modalKey="edit-billing-details"
      body={renderModalBody(billing)}
      resetFormState={resetFormState}
      formState={formState}
      onSubmit={onSubmit}
      actions={renderActions}
    />
  );
};

export default EditBillingDetails;
