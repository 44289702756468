// @flow

import * as React from 'react';
import {
  Message, localize, type MessageTranslator,
} from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import agreements from 'reducers/entities/agreements';
import {
  formatDateString,
  getAgreementDateFormat,
} from 'date';
import moment from 'moment';
import {
  getEventById,
  getEventOwner,
  getRef,
  findAssociatedTypeChangeEvent,
} from 'agreement/event';
import { getAgreementMyParticipant } from 'agreement/selectors';
import AuditTrailConstants from 'agreement/audit-trail-constants';
import localStorage from 'utils/local-storage';
import clsx from 'clsx';

import Tooltip from 'components/tooltip';
import NewCheck from 'components/icons/new-check';
import Edit from 'components/icons/edit';
import NewNotification from 'components/icons/new-notification';
import SuggestionAccepted from 'components/document-tabs/audit-trail-tab/suggestion-accepted';
import PrivateEventIndicator from 'components/private-event-indicator';

import { getFormattedEvent } from '../formatters/event-formatter';
import { getParticipantName, hasNoParent } from '../helpers';
import ChildEvents from '../child-events';
import style from '../audit-trail-tab.module.scss';

type Props = {
  event: any,
  agreementId: number,
  message: MessageTranslator,
  isChildEvent: boolean
};

const isNewUpdate = (agreement, event) => {
  const created = moment(event.created);
  const owner = getEventOwner(agreement, event);
  const myParticipant = getAgreementMyParticipant(agreement);
  const storageKey = `agreement.${agreement.id}.prev-visit`;
  const previousVisit = myParticipant
    ? moment(myParticipant.lastVisit)
    // eslint-disable-next-line import/no-named-as-default-member
    : moment(localStorage.getItem(storageKey));
  const isDifferentParticipant = !myParticipant || !owner || myParticipant.id !== owner.id;
  return created.isAfter(previousVisit) && isDifferentParticipant;
};

const Event = ({
  event, agreementId, message, isChildEvent = false,
}: Props) => {
  const agreement = useSelector((state) => (
    agreements.getAgreementSelector(state, { id: agreementId })
  ));

  if (!message) {
    return null;
  }

  /*
  * For internal approver, if it's not to/from organizer,
  * we don't want to render it, since it will be handled by the sibling event
  * Check participantUpdateItem on formatters/participant.js
  */
  if (event && getRef(event, 'key') === 'roles') {
    const parentEvent = getEventById(agreement, event.parent?.id);
    const associatedTypeEvent = findAssociatedTypeChangeEvent(agreement, event.id);

    // if there is a sibling event with key === type, we assume it's not an organizer
    if (parentEvent && associatedTypeEvent) {
      return null;
    }
  }

  const formattedEvent = getFormattedEvent(event, agreement, message);
  const dateFormat = getAgreementDateFormat(agreement.config.dateFormat);
  const owner = getEventOwner(agreement, event);

  const formattedDateString = formatDateString(event.created, dateFormat);
  const formattedDateStringWithTime = formattedDateString + moment(event.created).format(', HH:mm');
  const isNew = isNewUpdate(agreement, event);
  const classes = clsx(style.EventContainer, {
    [style.New]: isNew,
    [style.ChildEvent]: isChildEvent,
  });
  const showEditIcon = () => {
    if (!(formattedEvent.showEditIcon && isChildEvent)) {
      return null;
    }

    if (formattedEvent.icon === 'new-check') {
      return (
        <div className={style.EventIcon}>
          <NewCheck width="13px" height="13px" />
        </div>
      );
    }

    return (
      <div className={style.EventIcon}>
        <Edit width="13px" height="13px" />
      </div>
    );
  };

  const showByline = () => {
    if (!(formattedEvent.byLine && owner && !isChildEvent)) {
      return null;
    }

    return (
      <div className={style.Byline}>
        <Message id="By" comment="To indicate active part in a audit trail event" />
        {': '}
        <span>{getParticipantName(owner)}</span>
      </div>
    );
  };

  const showNewNotification = () => {
    if (!isNew) {
      return null;
    }

    return (
      <div className={style.NewIndicator}>
        <NewNotification width="11" height="10" />
        <Message id="New" comment="Tooltip for new items" />
      </div>
    );
  };

  const showHeader = () => {
    if (!hasNoParent(event)) {
      return null;
    }

    return (
      <div className={style.DateLine}>
        <Tooltip
          message={formattedDateStringWithTime}
          side="top"
          sideOffset={8}
          touchable
        >
          <span className={style.Date}>{formattedDateString}</span>
        </Tooltip>
        {showNewNotification()}
        {event.state === AuditTrailConstants.STATE_PRIVATE && <PrivateEventIndicator />}
      </div>
    );
  };

  const renderSuggestionAcceptedText = () => {
    if (event.type === AuditTrailConstants.TYPE_SUGGESTION_ACCEPTED) {
      return (
        <div className={style.SuggestionAcceptedWrapper}>
          <SuggestionAccepted event={event} />
        </div>
      );
    }

    return null;
  };

  return (
    <div className={classes} data-testid="event">
      <div className={style.EventTexts}>
        {showHeader()}
        {showByline()}
        <div className={style.IconAndText}>
          {showEditIcon()}
          <div className={style.EventText} data-testid="event-text">
            {formattedEvent.text}
            <br />
            {renderSuggestionAcceptedText()}
          </div>
        </div>
        <ChildEvents
          event={event}
          agreementId={agreementId}
          isChildEvent={isChildEvent}
          message={message}
          data-testid="child-events"
        />
      </div>
    </div>
  );
};

export default localize<Props>(React.memo(Event));
