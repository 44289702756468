/*
* Action status
* */
export const ACTION_STATUS_NOT_STARTED = 'NOT_STARTED';
export const ACTION_STATUS_RUNNING = 'RUNNING';
export const ACTION_STATUS_SUCCEEDED = 'SUCCEEDED';
export const ACTION_STATUS_FAILED = 'FAILED';
export const ACTION_STATUS_ABORTED = 'ABORTED';

/*
* Action Types
* */
export const ACTION_GROUP_APPROVAL = 'ACTION_GROUP_APPROVAL';
export const ACTION_GROUP_PENDING_STATE = 'ACTION_GROUP_PENDING_STATE';
