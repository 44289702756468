import { useParams } from 'react-router';
import clsx from 'clsx';
import { useState } from 'react';

import style from './preview-document.module.scss';
import PreviewHeader from './preview-header';

const PreviewDocument = () => {
  const { id } = useParams<{id: string}>();
  const agreementId = Number.parseInt(id, 10);
  const [previewDevice, setPreviewDevice] = useState<'desktop' | 'mobile'>('desktop');

  return (
    <div className={style.Container}>
      <PreviewHeader
        agreementId={agreementId}
        previewDevice={previewDevice}
        setPreviewDevice={setPreviewDevice}
      />
      <div className={style.IframeWrapper}>
        <iframe
          id="document-iframe"
          title="document-iframe"
          src={`/documents/${agreementId}/at/preview`}
          className={clsx(style.Iframe, {
            [style.MobileIframe]: previewDevice === 'mobile',
          })}
        />
      </div>
    </div>
  );
};

export default PreviewDocument;
