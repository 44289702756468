// @flow

import * as React from 'react';
import SelectField, { type SelectFieldProps } from 'components/select-field';

import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import noop from 'lodash/noop';

import style from './filter.module.scss';

type Option = {
  label: string,
  value: any,
  query: {},
  default?: boolean,
};

type Props = {
  ...SelectFieldProps,
  searchable?: boolean,
  onChange: (any) => any,
  options: Array<Option>,
  name: string,
  loading: boolean,
};

type State = {|
  selectedOption?: Option,
|};

class Filter extends React.Component<Props, State> {
  static defaultProps = {
    searchable: false,
  }

  state = {
    selectedOption: undefined,
  };

  getSelectedValue() {
    const { selectedOption } = this.state;

    if (selectedOption) {
      return selectedOption.value;
    }

    return this.defaultValue();
  }

  handleChange = (selectedOption: Option) => {
    const { onChange } = this.props;

    if (selectedOption) {
      this.setState({
        selectedOption,
      });
      onChange(selectedOption);
    } else {
      this.setState({
        selectedOption: undefined,
      });
      onChange({});
    }
  };

  defaultValue() {
    const { options } = this.props;

    return (find(options, (option) => option.default) || {}).value;
  }

  render() {
    const {
      name,
      loading,
      options,
      onChange,
      searchable,
      ...restProps
    } = this.props;
    const input = {
      onChange: noop,
      name,
      value: this.getSelectedValue(),
      onBlur: noop,
      onFocus: noop,
    };

    let selectOptions = options;
    const { selectedOption } = this.state;

    if (selectedOption && !selectedOption.default) {
      selectOptions = uniqBy<Option>([
        selectedOption,
        ...options,
      ], (option) => option.value);
    }

    return (
      <div className={style.Filter}>
        <SelectField
          clearable={false}
          {...restProps}
          multi={false}
          searchable={searchable}
          disabled={loading}
          isLoading={loading}
          labelKey="label"
          input={input}
          options={selectOptions}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default Filter;
