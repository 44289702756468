import { RenameMenuItem } from 'components/menu-items/rename';
import { checkAcl } from 'components/acl';
// eslint-disable-next-line import/named
import { SetContractNameModal } from 'components/modals/set-contract-name';
import { MouseEventHandler } from 'react';

export type Props = {
  agreement: Oneflow.Agreement,
};

const SetContractName = ({ agreement }: Props) => (
  <SetContractNameModal agreement={agreement}>
    {
      (onClick: MouseEventHandler) => (
        <RenameMenuItem
          disabled={!checkAcl(agreement.acl, 'agreement:update:name')}
          onClick={onClick}
        />
      )
    }
  </SetContractNameModal>
);

export default SetContractName;
