import type { CSSProperties } from 'react';
import { getAgreementFontValues } from 'components/document-tabs/settings-tab/sections/formatting-options/formatting-font-helper';
import useAgreement from 'hooks/use-agreement';
import { hexToRgb } from 'utils/color-type-conversion';

const useFormatting = (agreementId: Oneflow.Agreement['id']) => {
  const agreement = useAgreement(agreementId);
  const formattingConfig = agreement.config?.formatting;

  if (!formattingConfig) {
    return {} as CSSProperties;
  }

  const formatting = {
    ...formattingConfig,
    font: { value: getAgreementFontValues(formattingConfig.font) },
  };

  const { r = 0, g = 0, b = 0 } = hexToRgb(formatting.color);

  return {
    '--formatting-font-color': formatting.color,
    '--formatting-font-color-70-percent': `rgba(${r}, ${g}, ${b}, 0.7)`,
    '--formatting-font-size': `${formatting.size}px`,
    '--formatting-font-family': formatting.font?.value,
  } as CSSProperties;
};

export default useFormatting;
