import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import {
  canEditChatConfig,
  canEditCollaborationConfig,
  canEditCommentsConfig,
  canEditCounterpartCommentsResolveConfig,
  canEditSuggestionsConfig,
  canViewCommentsConfig,
  canViewSuggestionsConfig,
} from 'comments';
import agreements from 'reducers/entities/agreements';
import useAgreement from 'hooks/use-agreement';

import Divider from 'components/divider';
import Message from 'components/message';
import Toggle from 'components/toggle';
import TooltipInfo from 'components/tooltip-info';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';

import clsx from 'clsx';
import style from './collaboration-settings.module.scss';

type Props = {
  agreementId: number,
}

const CollaborationSettings = ({ agreementId }: Props) => {
  const { resetRPCStates } = useAPIError(agreementId);
  const agreement = useAgreement(agreementId);
  const dispatch = useDispatch();

  const canEditChat = canEditChatConfig(agreement);
  const canEditCollaboration = canEditCollaborationConfig(agreement);
  const canEditCounterpartCommentsResolve = canEditCounterpartCommentsResolveConfig(agreement);
  const canEditInlineComments = canEditCommentsConfig(agreement);
  const canEditSuggestions = canEditSuggestionsConfig(agreement);
  const canViewComments = canViewCommentsConfig(agreement);
  const canViewSuggestions = canViewSuggestionsConfig(agreement);

  const config = agreement.config || {};
  const {
    collaboration,
    comments,
    counterpartCommentsResolve,
    inlineComments,
    suggestions,
  } = config;

  const isAllDisabled = !comments
    && (!inlineComments || !canViewComments)
    && (!suggestions || !canViewSuggestions);
  const isCollaborationEnabled = Boolean(collaboration) && !isAllDisabled;
  const isChatEnabled = isCollaborationEnabled && Boolean(comments);
  const isInlineCommentingEnabled = isCollaborationEnabled && Boolean(inlineComments);
  const isSuggestionsEnabled = isCollaborationEnabled && Boolean(suggestions);

  if (isEmpty(agreement)) {
    return null;
  }

  const toggleConfigValue = (property: string) => (checked: boolean) => {
    resetRPCStates();
    dispatch(agreements.updateConfig({
      id: agreementId,
      data: {
        [property]: checked,
      },
    }));
  };

  return (
    <div className={style.CollaborationSettingsContainer}>
      <Divider solid className={style.Divider} />
      {canViewComments || canViewSuggestions ? (
        <div className={style.ToggleContainer}>
          <span className={clsx(style.Bold, style.TooltipWrapper)}>
            <Message
              id="Enable collaboration"
              comment="Label for turning the collaboration feature on or off"
            />
            <TooltipInfo
              customClassName={style.Tooltip}
              message={(
                <Message
                  id="Allow and manage collaboration features in this document, including chat, commenting and suggestions."
                  comment="Tooltip hint in the enable collaboration component."
                />
              )}
              side="top"
              theme="oneflow"
              zIndex="1031"
            />
          </span>
          <Toggle
            checked={isCollaborationEnabled}
            disabled={!canEditCollaboration}
            isControlled
            onChange={toggleConfigValue('collaboration')}
            version="latest"
          />
        </div>
      ) : null}
      <div className={style.ToggleContainer}>
        <span
          className={
            !canViewComments && !canViewSuggestions
              ? style.Bold
              : style.Flex
          }
        >
          <Message
            id="Enable chat"
            comment="Label for turning the collaboration feature on or off"
          />
          <TooltipInfo
            customClassName={style.Tooltip}
            message={(
              <Message
                id="Allow colleagues to post instant messages internally to colleagues or publicly to all participants."
                comment="Tooltip hint in the enable collaboration component."
              />
            )}
            side="top"
            theme="oneflow"
            zIndex="1031"
          />
        </span>
        <Toggle
          checked={isChatEnabled}
          disabled={!canEditChat}
          isControlled
          onChange={toggleConfigValue('comments')}
          version="latest"
        />
      </div>
      {canViewComments ? (
        <>
          <Divider solid className={style.Divider} />
          <div
            className={clsx(style.ToggleContainer, {
              'inline-comments-toggle-off': !isInlineCommentingEnabled,
              'inline-comments-toggle-on': isInlineCommentingEnabled,
            })}
          >
            <span className={clsx(style.Flex, style.TooltipWrapper)}>
              <Message
                id="Enable inline commenting"
                comment="Label for turning the collaboration feature on or off"
              />
              <TooltipInfo
                customClassName={style.Tooltip}
                message={(
                  <Message
                    id="Allow all participants to add comments to the text they highlight. Colleagues can resolve comments by default."
                    comment="Tooltip hint in the enable collaboration component."
                  />
                )}
                side="top"
                theme="oneflow"
                zIndex="1031"
              />
            </span>
            <Toggle
              checked={isInlineCommentingEnabled}
              disabled={!canEditInlineComments}
              isControlled
              onChange={toggleConfigValue('inlineComments')}
              version="latest"
            />
          </div>
          <div className={style.ToggleContainer} data-testid="toggle-container">
            <Message
              id="Allow counterparties to resolve comments"
              comment="Label for turning possibility to resolve inline comments on or off"
            />
            <Toggle
              checked={Boolean(counterpartCommentsResolve) && isCollaborationEnabled}
              disabled={!canEditCounterpartCommentsResolve}
              isControlled
              onChange={toggleConfigValue('counterpartCommentsResolve')}
              version="latest"
            />
          </div>
        </>
      ) : null}
      {canViewSuggestions ? (
        <>
          <Divider solid className={style.Divider} />
          <div
            className={clsx(style.ToggleContainer, {
              'suggestion-toggle-off': !isSuggestionsEnabled,
              'suggestion-toggle-on': isSuggestionsEnabled,
            })}
          >
            <span className={style.Flex}>
              <Message
                id="Enable text suggestions"
                comment="Label for turning the collaboration feature on or off"
              />
              <TooltipInfo
                customClassName={style.Tooltip}
                message={(
                  <Message
                    id="Allow all participants to suggest changes on the text they highlight."
                    comment="Tooltip hint in the enable collaboration component."
                  />
                )}
                side="top"
                theme="oneflow"
                zIndex="1031"
              />
            </span>
            <Toggle
              checked={isSuggestionsEnabled}
              disabled={!canEditSuggestions}
              isControlled
              onChange={toggleConfigValue('suggestions')}
              version="latest"
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CollaborationSettings;
