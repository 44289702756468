import { useLayoutEffect, useRef } from 'react';
import type { ReactNode, RefObject } from 'react';

import { useFilterableContext } from '../contexts/filterable';
import { VALUE_ATTR } from '../constants';

const useValue = (
  id: string,
  ref: RefObject<HTMLElement>,
  dependencies: (string | ReactNode | RefObject<HTMLElement>)[],
  aliases: string[] = [],
) => {
  const valueRef = useRef<string>('');
  const context = useFilterableContext();

  useLayoutEffect(() => {
    // eslint-disable-next-line consistent-return
    const value = (() => {
      // eslint-disable-next-line no-restricted-syntax
      for (const part of dependencies) {
        if (typeof part === 'string') {
          return part.trim();
        }

        if (typeof part === 'object' && part !== null && 'current' in part) {
          if (part.current) {
            return part.current.textContent?.trim();
          }
          return valueRef.current;
        }
      }
    })() as string;

    const keywords = aliases.map((alias) => alias.trim());

    context?.value(id, value, keywords);
    ref.current?.setAttribute(VALUE_ATTR, value);
    valueRef.current = value;
  });

  return valueRef;
};
export default useValue;
