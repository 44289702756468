// @flow

import * as React from 'react';

import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from 'components/modals/remove-confirm';

type Props = {
  contact: Contact,
  removeState: RemoveState,
  removeContact: Function,
  resetRemoveState: Function,
  children: Function,
}

export const RemoveContact = ({
  contact,
  removeState,
  removeContact,
  resetRemoveState,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={removeContact}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <Message
        id="You are about to delete {contactName} from the address book."
        comment="Confirm modal text."
        values={{
          contactName: <Badge label={contact.fullname} kind="name" />,
        }}
      />
    )}
    modalKey="delete contact from address book modal"
  >
    {children}
  </RemoveConfirm>
);
