// @flow

import { connect } from 'react-redux';

import { getPathnameSelector } from 'reducers/router';
import {
  getAccountFromSessionSelector,
} from 'reducers/session';

import SupportButton, { type Props } from './support-button';

type StateProps = {|
  isFreshworksAllowed: boolean,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);
  const isFreshworksAllowed = account.dataManagement?.freshworks;

  return {
    isFreshworksAllowed,
    pathname: getPathnameSelector(state),
  };
};

type DispatchProps = $Exact<{}>;

type MapDispatchToProps = () => DispatchProps;
export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, any, StateProps, $Exact<DispatchProps>, State, any>(
  mapStateToProps,
  mapDispatchToProps,
)(SupportButton);
