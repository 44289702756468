import clsx from 'clsx';
import Message from 'components/message';

import style from './state.module.scss';
import StatePill from './helper';

type Props = {
  published: boolean,
  className?: string,
  onClick: () => void,
  isClickable?: boolean,
}

const TemplateState = ({
  published, className, onClick, isClickable = true,
}: Props) => {
  if (published) {
    return (
      <StatePill
        className={clsx(style.TemplateState, style.PublishedTemplate, className)}
        onClick={onClick}
        isClickable={isClickable}
      >
        <Message
          id="Published"
          comment="Current template state"
        />
      </StatePill>
    );
  }

  return (
    <StatePill
      className={clsx(style.TemplateState, style.UnpublishedTemplate, className)}
      onClick={onClick}
      isClickable={isClickable}
    >
      <Message
        id="Unpublished"
        comment="Current template state"
      />
    </StatePill>
  );
};

export default TemplateState;
