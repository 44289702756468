// @flow

import * as React from 'react';
import queryString from 'query-string';
import { Message } from '@oneflowab/pomes';

import { type Location } from 'react-router-dom';

import extensionSettings from 'hocs/extension-settings';

import Button from 'components/button';
import CheckCircle from 'components/icons/check-circle';

import style from './super-office.module.scss';

type Props = {
  location: Location,
};

const SUPER_OFFICE_LOGOUT_LINK = 'https://online.superoffice.com/login/logout';
const SUPER_OFFICE_OAUTH_LINK = '/api/ext/superoffice/oauth_proxy';

export class SuperOfficePage extends React.Component<Props> {
  isConnected() {
    const { location } = this.props;
    const { status } = queryString.parse(location.search);

    return status === 'ok';
  }

  renderConnectionIcon() {
    if (!this.isConnected()) {
      return null;
    }

    return <CheckCircle height="20px" className={style.ConnectionIcon} />;
  }

  renderAuthButtonText() {
    if (!this.isConnected()) {
      return (
        <Message
          id="Authenticate to SuperOffice"
          comment="Button text in SuperOffice settings page"
        />
      );
    }
    return (
      <Message
        id="Re-authenticate to SuperOffice"
        comment="Button text in SuperOffice settings page"
      />
    );
  }

  render() {
    return (
      <>
        <h3 className={style.ExtensionConfigurationHeader}>
          <Message
            id="Install app"
            comment="Section header for the extension details page."
          />
        </h3>
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="This extension requires that you install the module 'Oneflow' in your SuperOffice account. Before continuing with the setup, make sure to logout from SuperOffice by clicking this link: {loginLink}"
            comment="Help text for the SuperOffice extension."
            values={{
              loginLink: (
                <a
                  className={style.LoginLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={SUPER_OFFICE_LOGOUT_LINK}
                >
                  {SUPER_OFFICE_LOGOUT_LINK}
                </a>
              ),
            }}
          />
        </div>
        <h3 className={style.ExtensionConfigurationHeader}>
          <Message
            id="Authentication"
            comment="Section header for the authentication section in an extension."
          />
          {this.renderConnectionIcon()}
        </h3>
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="Please authenticate with your Superoffice login credentials to activate the extension. You will only need to enter your credentials once, unless the credentials are revoked from SuperOffice."
            comment="Help text for the authentication section in an extension"
          />
        </div>

        <Button
          kind="primary"
          external
          href={SUPER_OFFICE_OAUTH_LINK}
          customClass={style.AuthButton}
        >
          {this.renderAuthButtonText()}
        </Button>
      </>
    );
  }
}

export default extensionSettings(SuperOfficePage);
