// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Renew from 'components/icons/renew';

const Renewed = () => (
  <>
    <Renew height="12px" />
    <span>
      <Message
        id="Renewed"
        comment="An insight for lifecycle events that indicates that a contract is renewed."
      />
    </span>
  </>
);

export default Renewed;
