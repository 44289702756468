/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import {
  isNil,
  map,
  unescape,
} from 'lodash';
import { localize } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';

import { getAvailableContentInlineSize } from 'reducers/app';
import { getDataFieldsMap } from 'reducers/current-contract';
import { isBoxDataRemoveAllowed } from 'agreement/box-data-remove-permissions';
import { required } from 'forms/validators';
import { useFormBoxProps } from 'contexts/form-box-props';
import useCurrentBox from 'hooks/use-current-box';
import usePopupDialog from 'hooks/popup/use-popup-dialog';

import { CancelButton } from 'components/buttons';
import * as formBoxHelpers from 'components/contract-boxes/form-box/helpers';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import Delete from 'components/icons/delete';
import Field from 'components/field';
import Message from 'components/message';
import TextArea from 'components/text-area';
import TextField from 'components/text-field';
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectItem,
  SelectIcon,
  SelectValue,
} from 'components/select-compound';
import { FormItem, FormLabel } from 'components/form';

import style from './popover.module.scss';

const {
  CHECKBOX,
  COLUMN_SIZE_1,
  COLUMN_SIZE_2,
  COLUMN_SIZE_3,
  COLUMN_SIZE_4,
  DATE_FIELD,
  DESCRIPTION_MAX_LENGTH,
  DROPDOWN,
  FIELD_SIZE_100,
  FIELD_SIZE_20,
  FIELD_SIZE_40,
  FIELD_SIZE_50,
  FIELD_SIZE_60,
  FIELD_SIZE_80,
  getAvailableColumnSlots,
  NAME_MAX_LENGTH,
  PLACEHOLDER_MAX_LENGTH,
  TEXT_AREA,
  TEXT_FIELD,
} = formBoxHelpers;

const getColumnLabel = (columnCount) => {
  if (columnCount === 1) {
    return (
      <Message
        id="Don't merge"
        comment="A message to select the number of columns in the contract form box"
      />
    );
  }

  return (
    <Message
      id="{count} column"
      pluralId="{count} columns"
      pluralCondition="count"
      values={{ count: columnCount }}
      comment="A message to select the number of columns in the contract form box"
    />
  );
};

const typeOptions = [
  {
    label: (
      <Message
        id="Field"
        comment="Form box fields dropdown option"
      />
    ),
    value: TEXT_FIELD,
  },
  {
    label: (
      <Message
        id="Text"
        comment="Form box fields dropdown option"
      />
    ),
    value: TEXT_AREA,
  },
  {
    label: (
      <Message
        id="Dropdown"
        comment="Form box fields dropdown option"
      />
    ),
    value: DROPDOWN,
  },
  {
    label: (
      <Message
        id="Checkbox"
        comment="Form box fields dropdown option"
      />
    ),
    value: CHECKBOX,
  },
  {
    label: (
      <Message
        id="Date"
        comment="Form box fields dropdown option"
      />
    ),
    value: DATE_FIELD,
  },
];

const inputSizeOptions = [
  { label: '20%', value: FIELD_SIZE_20 },
  { label: '40%', value: FIELD_SIZE_40 },
  { label: '50%', value: FIELD_SIZE_50 },
  { label: '60%', value: FIELD_SIZE_60 },
  { label: '80%', value: FIELD_SIZE_80 },
  { label: '100%', value: FIELD_SIZE_100 },
];

const columnSizeOptions = [
  {
    label: getColumnLabel(COLUMN_SIZE_1),
    value: COLUMN_SIZE_1,
  },
  {
    label: getColumnLabel(COLUMN_SIZE_2),
    value: COLUMN_SIZE_2,
  },
  {
    label: getColumnLabel(COLUMN_SIZE_3),
    value: COLUMN_SIZE_3,
  },
  {
    label: getColumnLabel(COLUMN_SIZE_4),
    value: COLUMN_SIZE_4,
  },
];

const FormBoxPopover = ({
  activePopoverIndexMap,
  children,
  colCount,
  fieldData,
  fieldDataValue = {},
  isActive,
  message,
  onClosePopover,
  order,
}) => {
  const {
    boxId,
    editField,
    removeField,
  } = useFormBoxProps();
  const activeFieldIndex = activePopoverIndexMap?.fieldIndex;
  const activeRowIndex = activePopoverIndexMap?.rowIndex;
  const availableContentInlineSize = useSelector(getAvailableContentInlineSize);
  const box = useCurrentBox(boxId);
  const defaultDropdownPlaceholder = message({
    id: 'Select an option',
    comment: 'Default dropdown placeholder.',
  });
  const isExpandedView = availableContentInlineSize > 700;
  const popupType = isExpandedView ? 'popover' : 'dialog';
  const { Popup, PopupContent } = usePopupDialog(popupType);
  const dataFields = Object.entries(useSelector(getDataFieldsMap));
  const hasDataFields = Boolean(dataFields.length);
  const dataFieldOptions = [
    {
      label: null,
      value: null,
    },
    ...map(dataFields, (dataField) => {
      const { name, key } = dataField[1].value;
      return {
        label: name,
        value: key,
      };
    }),
  ];

  const handleRemove = (formProps) => {
    removeField(activePopoverIndexMap, formProps);
  };

  const handleSubmit = (formProps) => {
    editField(activePopoverIndexMap, formProps);
    onClosePopover();
  };

  const mergeColumnsOptions = useMemo(() => {
    const availableSlots = getAvailableColumnSlots(
      activeFieldIndex,
      activeRowIndex,
      colCount,
      order,
    );
    const availableColumnSizeOptions = columnSizeOptions.slice(0, availableSlots);

    return availableColumnSizeOptions;
  }, [activeFieldIndex, activeRowIndex, colCount, order]);

  const handleTypeChange = (newValue, { form, values }) => {
    const isDropdownValue = (value) => value === DROPDOWN;

    if (isDropdownValue(newValue)) {
      form.change('placeholder', defaultDropdownPlaceholder);
      return;
    }

    const wasDropdown = isDropdownValue(values.type) || isDropdownValue(values.type.value);

    if (wasDropdown && !isDropdownValue(newValue)) {
      form.change('placeholder', '');
    }
  };

  const dropDownValidation = required({
    message,
    field: 'Dropdown values',
    usePlural: true,
  });

  const renderDropdownField = (type) => {
    if (type !== DROPDOWN) {
      return null;
    }

    return (
      <div>
        <Field
          component={TextArea}
          isDropdownValuesField
          label={(
            <>
              <Message
                id="Dropdown values"
                comment="Label for the dropdown values field."
              />
              <span className={style.Required}> *</span>
            </>
          )}
          labelCustomClass={style.Label}
          name="choices"
          resize="vertical"
          validate={dropDownValidation}
        />
      </div>
    );
  };

  const renderPlaceholderField = (type) => {
    if (type === CHECKBOX) {
      return null;
    }

    return (
      <div>
        <Field
          component={TextField}
          label={(
            <Message
              id="Placeholder"
              comment="Label for the placeholder field in the form box popover."
            />
          )}
          labelCustomClass={style.Label}
          maxLength={PLACEHOLDER_MAX_LENGTH}
          name="placeholder"
        />
      </div>
    );
  };

  const renderRemoveButton = () => (
    <Button
      customClass={style.RemoveButton}
      disabled={!isBoxDataRemoveAllowed(box, fieldData)}
      icon={Delete}
      onClick={handleRemove}
    >
      <Message
        id="Remove field"
        comment="Remove field button label in the form box popover."
      />
    </Button>
  );

  const renderSaveButton = (formProps) => (
    <Button
      customClass={style.SaveButton}
      disabled={!formProps.valid}
      onClick={formProps.handleSubmit}
      type="button"
    >
      <Message
        id="Save"
        comment="Save button label in the form box popover."
      />
    </Button>
  );

  const renderForm = (formProps) => (
    <form autoComplete="off" onSubmit={formProps.handleSubmit}>
      <div className={style.Header}>
        <Message
          id="Edit field"
          comment="Header text in the form box popover."
        />
      </div>
      <div className={style.Container}>
        <div>
          <Field
            autoFocus
            component={TextField}
            label={(
              <Message
                id="Name"
                comment="Label for the name field in the form box popover."
              />
            )}
            labelCustomClass={style.Label}
            maxLength={NAME_MAX_LENGTH}
            name="label"
            required
          />
        </div>
        <div>
          <Field
            name="type"
            render={({ input }) => (
              <FormItem>
                <FormLabel className={style.Label} htmlFor={input.name}>
                  <Message
                    id="Type"
                    comment="Label for the type field in the form box popover."
                  />
                </FormLabel>
                <Select
                  value={input.value}
                  onValueChange={(newValue) => {
                    input.onChange(newValue);
                    handleTypeChange(newValue, formProps);
                  }}
                  disabled={input.disabled}
                >
                  <SelectTrigger id={input.name} onFocus={input.onFocus} onBlur={input.onBlur}>
                    <SelectValue />
                    <SelectIcon />
                  </SelectTrigger>
                  <SelectContent>
                    {typeOptions.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {/* TODO: remove this once the form message is implemented in OF-14858 */}
                <div className={style.FormMessage} />
              </FormItem>
            )}
          />
        </div>
        {renderDropdownField(
          formProps.values.type
            ? formProps.values.type
            : fieldDataValue.type,
        )}
        {renderPlaceholderField(
          formProps.values.type
            ? formProps.values.type
            : fieldDataValue.type,
        )}
        <div>
          <Field
            component={TextField}
            label={(
              <Message
                id="Description"
                comment="Label for the description field in the form box popover."
              />
            )}
            labelCustomClass={style.Label}
            maxLength={DESCRIPTION_MAX_LENGTH}
            name="description"
          />
        </div>
        {hasDataFields ? (
          <div>
            <Field
              name="valueDataFieldKey"
              render={({ input }) => (
                <FormItem>
                  <FormLabel className={style.Label} htmlFor={input.name}>
                    <Message
                      id="Data field"
                      comment="Label for the data field field in the form box popover."
                    />
                  </FormLabel>
                  <Select
                    value={input.value}
                    onValueChange={input.onChange}
                    disabled={input.disabled}
                  >
                    <SelectTrigger id={input.name} onFocus={input.onFocus} onBlur={input.onBlur}>
                      <SelectValue />
                      <SelectIcon />
                    </SelectTrigger>
                    <SelectContent>
                      {dataFieldOptions.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* TODO: remove this once the form message is implemented in OF-14858 */}
                  <div className={style.FormMessage} />
                </FormItem>
              )}
            />
          </div>
        ) : null}
        <div className={style.Checkbox}>
          <Field
            component={Checkbox}
            label={(
              <Message
                id="Make this field required"
                comment="Label for the required field in the form box popover."
              />
            )}
            labelCustomClass={style.Label}
            name="required"
            type="checkbox"
          />
        </div>
        <div>
          <Field
            name="size"
            render={({ input }) => (
              <FormItem>
                <FormLabel className={style.Label} htmlFor={input.name}>
                  <Message
                    id="Size"
                    comment="Label for the size field in the form box popover."
                  />
                </FormLabel>
                <Select
                  value={typeof input.value === 'number' ? input.value.toString() : input.value}
                  onValueChange={(newValue) => {
                    input.onChange(Number(newValue));
                  }}
                  disabled={input.disabled}
                >
                  <SelectTrigger id={input.name} onFocus={input.onFocus} onBlur={input.onBlur}>
                    <SelectValue />
                    <SelectIcon />
                  </SelectTrigger>
                  <SelectContent>
                    {inputSizeOptions.map((option) => (
                      <SelectItem key={option.value} value={option.value.toString()}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {/* TODO: remove this once the form message is implemented in OF-14858 */}
                <div className={style.FormMessage} />
              </FormItem>
            )}
          />
        </div>
        {mergeColumnsOptions.length > 1 ? (
          <div>
            <Field
              name="colspan"
              render={({ input }) => (
                <FormItem>
                  <FormLabel className={style.Label} htmlFor={input.name}>
                    <Message
                      id="Merge columns"
                      comment="Label for the merge columns field in the form box popover."
                    />
                  </FormLabel>
                  <Select
                    value={typeof input.value === 'number' ? input.value.toString() : input.value}
                    onValueChange={(newValue) => {
                      input.onChange(Number(newValue));
                    }}
                    disabled={input.disabled}
                  >
                    <SelectTrigger id={input.name} onFocus={input.onFocus} onBlur={input.onBlur}>
                      <SelectValue />
                      <SelectIcon />
                    </SelectTrigger>
                    <SelectContent>
                      {mergeColumnsOptions.map((option) => (
                        <SelectItem key={option.value} value={option.value.toString()}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* TODO: remove this once the form message is implemented in OF-14858 */}
                  <div className={style.FormMessage} />
                </FormItem>
              )}
            />
          </div>
        ) : null}
        <div className={style.ButtonContainer}>
          <div className={style.RemoveButtonContainer}>
            <Popup.Close asChild>
              {renderRemoveButton()}
            </Popup.Close>
          </div>
          <div className={style.Row}>
            <Popup.Close asChild>
              <CancelButton />
            </Popup.Close>
            {renderSaveButton(formProps)}
          </div>
        </div>
      </div>
    </form>
  );

  const getInitialPlaceholder = () => {
    if (fieldDataValue.placeholder) {
      return unescape(fieldDataValue.placeholder);
    }

    if (fieldDataValue.type === DROPDOWN) {
      return defaultDropdownPlaceholder;
    }

    return '';
  };

  if (!fieldDataValue) {
    return children;
  }

  return (
    <Popup.Root
      onOpenChange={() => {
        if (isActive) {
          onClosePopover();
        }
      }}
      open={isActive}
    >
      <Popup.Trigger asChild>
        {children}
      </Popup.Trigger>
      <Popup.Portal>
        <PopupContent className={style.PopoverContent} side="center">
          <Form
            initialValues={{
              choices: fieldDataValue.choices ? unescape(fieldDataValue.choices.join('\n')) : '',
              colspan: fieldDataValue.colspan ? fieldDataValue.colspan : COLUMN_SIZE_1,
              description: fieldDataValue.description ? unescape(fieldDataValue.description) : '',
              label: fieldDataValue.label ? unescape(fieldDataValue.label) : message({
                id: 'Untitled field',
                comment: 'Default field label.',
              }),
              placeholder: getInitialPlaceholder(),
              required: fieldDataValue.required ? fieldDataValue.required : 0,
              size: !isNil(fieldDataValue.size) ? fieldDataValue.size : FIELD_SIZE_100,
              type: fieldDataValue.type ? fieldDataValue.type : TEXT_FIELD,
              valueDataFieldKey: fieldDataValue.valueDataFieldKey
                ? fieldDataValue.valueDataFieldKey : null,
            }}
            onSubmit={handleSubmit}
            render={renderForm}
          />
        </PopupContent>
      </Popup.Portal>
    </Popup.Root>
  );
};

export default localize(FormBoxPopover);
