// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import {
  requestMe,
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';
import workspacesReducer from 'reducers/entities/workspaces';
import accounts from 'reducers/entities/accounts';
import libraryTemplates from 'reducers/entities/library-templates';
import workspaceUsersReducer from 'reducers/entities/workspace-users';
import { USER_WORKSPACE_ROLE_MANAGER_ID, USER_ACTOR_TYPE } from 'user';
import ModalForm from 'hocs/modal-form';

import Field from 'components/field';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import Button from 'components/button';
import { getWorkspaceTypesAsOptions } from 'components/workspace-type';
import CircularSpinner from 'components/icons/circular-spinner';
import { checkAcl } from 'components/acl';

import style from './create-workspace-modal.module.scss';

const getWorkspaceName = ({ virtual, name }: { virtual?: boolean, name: string }) => {
  if (virtual) {
    return '';
  }
  return name;
};

export type Props = {
  message: MessageTranslator,
  onStepComplete?: () => void,
  shouldSkipOnboarding?: boolean,
};

export const CreateWorkspaceModalComponent = ({
  onStepComplete,
  message,
  shouldSkipOnboarding,
}: Props) => {
  const accountId = useSelector((state) => getAccountFromSessionSelector(state).id);
  const currentWorkspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  const formState = useSelector((state) => libraryTemplates.getCreateSelector(state));
  const position = useSelector(getPositionFromSessionSelector);
  const dispatch = useDispatch();

  const resetFormState = () => undefined;

  const renderBody = () => (
    <div className={style.CreateWorkspace}>
      <div className={style.TextContent}>
        <p className={style.Header}>
          <Message
            id="Let's create your first workspace"
            comment="Text in create workspace modal."
          />
        </p>
      </div>
      <p>
        <Message
          id="Name your first workspace; the documents you create will be stored here"
          comment="Onboarding text for workspace name field."
        />
      </p>
      <Field
        autoFocus
        name="name"
        component={TextField}
        label={message({
          id: 'Workspace name',
          comment: 'Workspace name label on the create workspace modal.',
        })}
        maxLength={100}
        responsive
        required
        initialValue={getWorkspaceName(currentWorkspace)}
      />
      <p>
        <Message
          id="Choose a type of workspace for the documents you want to start sending out. You can create additional workspaces later for each department on the admin page."
          comment="Onboarding text for workspace type field."
        />
      </p>
      <Field
        name="type"
        label={message({
          id: 'Workspace type',
          comment: 'Label for the Workspace type field on the create workspace modal.',
        })}
        placeholder={message({
          id: 'Select type',
          comment: 'Workspace type label on the create workspace modal.',
        })}
        component={SelectField}
        options={getWorkspaceTypesAsOptions(message)}
        clearable={false}
        searchable={false}
        required
      />
    </div>
  );

  const updateAccount = () => {
    dispatch(accounts.updateAccount({
      id: accountId,
      data: {
        onboardingStage: 'new',
      },
      pipe: {
        action: requestMe,
      },
    }));
  };

  const updateWorkspace = (data: Object) => {
    dispatch(workspacesReducer.updateWorkspace({
      id: currentWorkspace.id,
      data: {
        type: data.type.value,
        name: data.name,
        amplitudeScope: 'onboarding create workspace modal',
      },
    }));
  };

  const addMyselfToWorkspace = (workspaceId: number) => {
    const hasGrantUserAccessPermission = checkAcl(position.acl, 'position:collection:binding:create');
    if (!hasGrantUserAccessPermission) {
      return;
    }

    const data = {
      roleId: USER_WORKSPACE_ROLE_MANAGER_ID,
      actorId: position.id,
      actorType: USER_ACTOR_TYPE,
      resourceId: workspaceId,
      resourceType: 'collection',
    };

    dispatch(workspaceUsersReducer.createWorkspaceUser({
      data,
      pipe: {
        onSuccess: () => {
          const newLocation = `/c/${workspaceId}/dashboard`;
          window.location.href = newLocation;
        },
      },
    }));
  };

  const createWorkspace = (data: Object) => {
    dispatch(workspacesReducer.createWorkspace({
      id: currentWorkspace.id,
      data: {
        type: data.type.value,
        name: data.name,
      },
      pipe: {
        onSuccess: ({ result: newWorkspaceId }) => {
          addMyselfToWorkspace(newWorkspaceId);
        },
      },
    }));
  };

  const handleSubmit = (data: Object) => {
    if (shouldSkipOnboarding) {
      createWorkspace(data);
      return;
    }
    updateAccount();
    updateWorkspace(data);
    onStepComplete();
  };

  const renderActions = ({ formProps }) => {
    const disabled = Boolean(
      formProps.pristine
      || formProps.validating
      || formProps.hasValidationErrors
      || formState.loading,
    );

    return (
      <Button
        onClick={formProps.handleSubmit}
        disabled={disabled}
        icon={formState.loading ? CircularSpinner : null}
        kind="primary"
      >
        <Message
          id="Create your workspace"
          comment="Button text for create workspace modal"
        />
      </Button>
    );
  };

  return (
    <ModalForm
      title={message({
        id: 'Create Workspace',
        comment: 'Modal title for entering workspace information.',
      })}
      body={renderBody()}
      resetFormState={resetFormState}
      formState={formState}
      isOpen
      onSubmit={handleSubmit}
      portalClassName={style.CreateWorkspaceModal}
      preventClose={!shouldSkipOnboarding}
      actions={renderActions}
      modalKey="onboarding create workspace modal"
    />
  );
};

export default localize<Props>(CreateWorkspaceModalComponent);
