// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import agreements from 'reducers/entities/agreements';

import AgreementComponent from './agreement';
import type { Props } from './agreement';

type MSProps = {|
  fetchState: FetchState,
  agreement: Agreement,
|};

type MDProps = {|
  fetchAgreement: Function,
|};

type OwnProps = $Diff<Props, MSProps & MDProps>;

type MapStateToProps = (State, OwnProps) => MSProps;
export const mapStateToProps: MapStateToProps = (state, ownProps) => {
  const agreement = agreements.getAgreementSelector(state, { id: ownProps.match.params.id });

  return {
    fetchState: agreements.getFetchSelector(state, {
      id: Number(ownProps.match.params.id),
    }),
    agreement,
  };
};

type MapDispatchToProps = (Dispatch<*>, OwnProps) => MDProps;
export const mapDispatchToProps: MapDispatchToProps = (dispatch, ownProps) => ({
  fetchAgreement: () => {
    const action = agreements.fetchAgreement({
      id: ownProps.match.params.id,
    });

    dispatch(action);
  },
});

export default connect<Props, OwnProps, MSProps, MDProps, State, _>(
  mapStateToProps,
  mapDispatchToProps,
)(AgreementComponent);
