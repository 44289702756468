import type { DefaultRootState } from 'react-redux';
import normalizeEntity from 'normalized-redux/entity-normalizer';

const rpcs = [
  'refreshOauth',
  'revokeConnection',
  'authorizeWithDynamics',
  'authorizeWithPipedrive',
  'setupWithGeneric',
] as const;

const normalizedExtensions = normalizeEntity<Oneflow.Extension, 'extension', typeof rpcs>({
  entity: 'extension',
  rpcs,
});

export const getExtensionsById = (state: DefaultRootState) => {
  const allExtensions = normalizedExtensions.getAllExtensionsSelector(state);

  return allExtensions.reduce((acc, extension) => {
    if (!extension.extensionId) {
      return acc;
    }

    return {
      ...acc,
      [extension.extensionId]: extension,
    };
  }, {});
};

export default normalizedExtensions;
