// @flow
import * as React from 'react';

import { Route } from 'react-router';

import type { LocationShape, ContextRouter } from 'react-router';

export const getDisplayName = (WrappedComponent: React.AbstractComponent<any>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return `Page(${displayName})`;
};

export type DecoratedProps<WrappedProps: {}> = {|
  path: string | Array<string>,
  exact?: boolean,
  strict?: boolean,
  location?: LocationShape,
  sensitive?: boolean,
  ...$Exact<$Diff<WrappedProps, ContextRouter>>,
|};

type WithContextRouterProps<WrappedProps: {}> = WrappedProps & ContextRouter;

function withPage<WrappedProps: {}>(
  WrappedComponent: React.AbstractComponent<WithContextRouterProps<WrappedProps>>,
): React.AbstractComponent<DecoratedProps<WithContextRouterProps<WrappedProps>>> {
  const withPageComponent = (props: DecoratedProps<WithContextRouterProps<WrappedProps>>) => {
    const {
      path,
      exact,
      strict,
      location,
      sensitive,
      ...remainingProps
    } = props;

    return (
      <Route
        path={path}
        exact={exact}
        strict={strict}
        location={location}
        sensitive={sensitive}
        render={(routeProps) => (
          <WrappedComponent
            {...routeProps}
            {...remainingProps}
          />
        )}
      />
    );
  };

  withPageComponent.displayName = getDisplayName(WrappedComponent);

  withPageComponent.defaultProps = {
    exact: undefined,
    strict: undefined,
    location: undefined,
    sensitive: undefined,
  };

  return withPageComponent;
}

export default withPage;
