import { MouseEvent } from 'react';
import { Message } from '@oneflowab/pomes';

import DeleteIcon from 'components/icons/delete';
import { MenuItem } from 'components/menu-item';

import style from './move-to-trash.module.scss';

type Props = {
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
};

export const MoveToTrashMenuItem = ({
  disabled,
  onClick,
}: Props) => (
  <MenuItem
    disabled={disabled}
    label={
      <Message id="Move to Trash" comment="Label for move to trash action in menu." />
    }
    onClick={onClick}
    icon={DeleteIcon}
    className={!disabled ? style.Danger : undefined}
  />
);
