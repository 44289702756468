import { useEffect } from 'react';
import type { ReactNode } from 'react';
import type { Modifiers } from '@dnd-kit/core';

import { useDocumentDndContext } from './document-dnd-context';
import type {
  OnDragStart,
  OnDragCancel,
  OnDragEnd,
} from './document-dnd-context';

type Props = {
  children?: ReactNode,
  segmentId: string,
  onDragStart?: OnDragStart,
  onDragCancel?: OnDragCancel,
  onDragEnd?: OnDragEnd,
  modifiers?: Modifiers,
  autoScroll?: boolean,
};

// eslint-disable-next-line import/prefer-default-export
export const DocumentDndSegment = ({
  children,
  segmentId,
  onDragStart,
  onDragCancel,
  onDragEnd,
  modifiers,
  autoScroll,
}: Props) => {
  const {
    registerSegmentDndEventListeners,
    unregisterSegmentDndEventListeners,
  } = useDocumentDndContext();

  useEffect(() => {
    if (!registerSegmentDndEventListeners) {
      return;
    }

    registerSegmentDndEventListeners(segmentId, {
      onDragStart,
      onDragCancel,
      onDragEnd,
      modifiers,
      autoScroll,
    });

    // eslint-disable-next-line consistent-return
    return () => {
      if (unregisterSegmentDndEventListeners) {
        unregisterSegmentDndEventListeners(segmentId);
      }
    };
  }, [
    segmentId,
    registerSegmentDndEventListeners,
    unregisterSegmentDndEventListeners,
    onDragStart,
    onDragCancel,
    onDragEnd,
    modifiers,
    autoScroll,
  ]);

  return <>{children}</>;
};
