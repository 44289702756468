// @flow

import React from 'react';
import reduce from 'lodash/reduce';
import debounce from 'lodash/debounce';

import Search from 'components/search';
import Filter from 'components/filter';

import style from './table-filtering.module.scss';

type Props = {
  onFilterHandler: Function,
  filters?: Array<{
    name: string,
    type: string,
    options?: Array<any>,
    queryKey?: string,
    searchable?: boolean,
    autoFocus?: boolean,
    placeholder?: string,
  }>,
  loading?: boolean,
};

class TableFiltering extends React.Component<Props> {
  static defaultProps = {
    filters: [],
    loading: false,
  };

  state = {
    currentFilters: {},
  };

  componentDidMount() {
    const { filters } = this.props;
    const currentFilters = {};

    filters.filter((filter) => filter.type === 'dropdown').forEach((filter) => {
      const defaultOption = filter.options.find((option) => option.default);
      if (defaultOption) {
        currentFilters[filter.name] = defaultOption.query;
      }
    });

    this.setState({
      currentFilters,
    });
  }

  onSelectFilter = (name) => (selectedOption) => {
    this.filter({
      name,
      query: selectedOption.query,
    });
  }

  onSearchHandler({ name, queryKey, value }) {
    this.filter({
      name,
      query: {
        [queryKey]: value,
      },
    });
  }

  getQueryParamsFromFilter() {
    return reduce(this.state.currentFilters, (acc, value) => ({ ...acc, ...value }), {});
  }

  filter({ name, query }) {
    this.setState((prevState) => ({
      currentFilters: {
        ...prevState.currentFilters,
        [name]: query,
      },
    }), () => {
      this.props.onFilterHandler(this.getQueryParamsFromFilter());
    });
  }

  renderSearchField = (filter) => {
    const {
      name,
      queryKey,
      autoFocus,
      placeholder,
    } = filter;

    return (
      <Search
        onChange={debounce((value) => (
          this.onSearchHandler({ name, queryKey, value })
        ), 400)}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
    );
  }

  renderFilter = (filter) => {
    const {
      loading,
    } = this.props;

    return (
      <Filter
        onChange={this.onSelectFilter(filter.name)}
        loading={loading}
        {...filter}
      />
    );
  }

  render() {
    return (
      <div className={style.TableFiltering}>
        {
          this.props.filters.map((filter) => {
            if (filter.type === 'dropdown') {
              return (
                <React.Fragment key={filter.name}>
                  {this.renderFilter(filter)}
                </React.Fragment>
              );
            }

            if (filter.type === 'text') {
              return (
                <React.Fragment key={filter.name}>
                  {this.renderSearchField(filter)}
                </React.Fragment>
              );
            }

            return null;
          })
        }
      </div>
    );
  }
}

export default TableFiltering;
