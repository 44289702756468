import React from 'react';
import { get } from 'lodash';
import clsx from 'clsx';
import { useDroppable } from '@dnd-kit/core';
import { Message } from '@oneflowab/pomes';

import { useFormBoxProps } from 'contexts/form-box-props';
import Field from 'components/contract-boxes/form-box/components/field/field';
import * as formBoxHelpers from 'components/contract-boxes/form-box/helpers';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import DraggableField from 'components/contract-boxes/form-box/fields/draggable-field';

import style from './form-box-cell.module.scss';

type Props = {
  activePopoverIndexMap: void,
  colCount: number,
  columnIndex: number,
  dndActive: false,
  hasSpaceForField: boolean,
  row: any,
  rowIndex: number,
  setActiveField: () => void,
  setActivePopover: () => void,
};

const {
  COLUMN_SIZE_1,
  getFieldData,
} = formBoxHelpers;

const FormBoxCell = ({
  activePopoverIndexMap,
  colCount,
  columnIndex,
  dndActive,
  hasSpaceForField,
  row,
  rowIndex,
  setActiveField,
  setActivePopover,
}: Props) => {
  const key = `field_${rowIndex}_${columnIndex}`;
  const {
    data,
    order,
    isEditable,
  } = useFormBoxProps();
  const field = row[columnIndex];
  const EMPTY = null;
  const isEmpty = field === EMPTY;
  const [activeId, setActiveId] = React.useState(null);
  const isDraggableRowActive = (active) => active && active.id.indexOf('key-') > -1;
  const fieldId = getId(field);
  const fieldData = getFieldData(data, fieldId);
  const fieldDataValue = get(fieldData, 'value');
  const colSpan = get(fieldDataValue, 'colspan');

  const DropArea = (props: { id: string, children: React.ReactNode }) => {
    const { isOver, setNodeRef, active } = useDroppable({
      id: props.id,
    });

    if (isDraggableRowActive(active)) {
      return null;
    }

    const classesDrop = clsx(style.Drop, {
      [style.Active]: active,
      [style.Over]: isOver,
    });

    const styleOwn = {};
    return (
      <div ref={setNodeRef} className={classesDrop} style={styleOwn}>
        {props.children}
      </div>
    );
  };

  const renderContent = () => {
    if (dndActive && isEmpty) {
      return null;
    }

    return (
      <Field
        activePopoverIndexMap={activePopoverIndexMap}
        colCount={colCount}
        data={data}
        field={field}
        fieldIndex={columnIndex}
        key={key}
        order={order}
        rowIndex={rowIndex}
        setActivePopover={setActivePopover}
      />
    );
  };

  const renderDrop = () => {
    if (isEmpty && hasSpaceForField) {
      return (
        <DropArea id={`droppable_${rowIndex}_${columnIndex}`}>
          <Message
            id="drop field here"
            comment="Label for form box field drop area when dragging a field"
          />
        </DropArea>
      );
    }

    return null;
  };

  const renderDrag = () => {
    if (!isEmpty && isEditable) {
      return (
        <DraggableField
          id={`draggable_${rowIndex}_${columnIndex}`}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          data={data}
          field={field}
          fieldKey={key}
          setActiveField={setActiveField}
          activeId={activeId}
          setActiveId={setActiveId}
        >
          {renderContent()}
        </DraggableField>
      );
    }

    return renderContent();
  };

  const generateColSpanOffsetStyle = (colspan) => {
    const colspanStart = columnIndex + 1;
    const colspanEnd = colspan ? colspanStart + colspan : colspanStart;
    const gridColumnStyle = colCount === COLUMN_SIZE_1 ? 'auto' : `${colspanStart} / ${colspanEnd}`;

    return {
      gridColumn: gridColumnStyle,
    };
  };

  return (
    <div className={style.Cell} style={generateColSpanOffsetStyle(colSpan)}>
      {renderDrag()}
      {renderDrop()}
    </div>
  );
};

export default FormBoxCell;
