import { useLayoutEffect, useRef } from 'react';

const useAsRef = <T>(data: T) => {
  const ref = useRef<T>(data);

  useLayoutEffect(() => {
    ref.current = data;
  });

  return ref;
};

export default useAsRef;
