import { useState } from 'react';

import Message from 'components/message';
import Button from 'components/button';
import BillingSection from 'components/billing-section';
import EditInvoiceDetails from 'components/modals/edit-invoice-details';

import type { Billing } from 'types/billing';

type Props = {
  billing: Billing,
  accountId: number,
}

const InvoiceDetails = ({
  billing,
  accountId,
}: Props) => {
  const [showEditInvoiceDetails, setShowEditInvoiceDetails] = useState<boolean>(false);

  const openModal = () => {
    setShowEditInvoiceDetails(true);
  };

  const closeModal = () => {
    setShowEditInvoiceDetails(false);
  };

  return (
    <>
      <BillingSection
        title={(
          <Message
            id="Invoice details"
            comment="Heading for the invoice details sectin in subscription and billing page"
          />
      )}
        items={[{
          id: 'deliveryChannel',
          label: <Message
            id="Delivered via"
            comment="Heading for the deliver channel"
          />,
          value: billing.invoiceDeliveryChannel,
        },
        {
          id: 'invoiceEmail',
          label: <Message
            id="Email"
            comment="Heading for the email"
          />,
          value: billing.invoiceEmail,
        },
        {
          id: 'reference',
          label: <Message
            id="Reference"
            comment="Heading for the email"
          />,
          value: billing.invoiceReference,
        },
        ]}
      />
      <Button
        kind="secondary"
        color="forest-green"
        onClick={openModal}
      >
        <Message
          id="Edit invoice details"
          comment="Button text for edit invoice details in subscription and billing page"
        />
      </Button>
      <EditInvoiceDetails
        billing={billing}
        isOpen={showEditInvoiceDetails}
        closeModal={closeModal}
        accountId={accountId}
      />
    </>
  );
};

export default InvoiceDetails;
