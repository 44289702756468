import { createContext, useContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';

type SelectionContextType = {
  selectedDocuments: Record<number, boolean>;
  setSelectedDocuments: Dispatch<SetStateAction<Record<number, boolean>>>;
};

export const SelectionContext = createContext<SelectionContextType | null>(null);

export const useSelectionContext = () => {
  const context = useContext(SelectionContext);

  if (!context) {
    throw new Error('useSelectionContext must be used within a SelectionContext provider');
  }

  return context;
};
