// @flow

import * as React from 'react';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Modal from 'components/modal';
import Button from 'components/button';

import TokenInfo from './token-info';

export type TokenEntity = Extension | ApiToken;

export type Props = {|
  tokenEntity: TokenEntity,
  clearToken: TokenEntity => () => void,
  message: MessageTranslator,
  canRegenerateToken?: boolean,
|};

export const GeneratedTokenComponent = ({
  tokenEntity,
  message,
  clearToken,
  canRegenerateToken,
}: Props) => {
  if (!tokenEntity.token) {
    return null;
  }

  return (
    <Modal
      header={message({
        id: 'API token',
        comment: 'Modal header for displaying an API token.',
      })}
      isOpen
      onCancel={clearToken(tokenEntity)}
      error={undefined}
      modalKey="show api token modal"
    >
      <Modal.Body>
        <TokenInfo
          token={tokenEntity.token}
          canRegenerateToken={canRegenerateToken}
        />
      </Modal.Body>
      <Modal.Actions>
        <Button kind="linkSeparate" onClick={clearToken(tokenEntity)}>
          <Message
            id="Close"
            comment="Close button text in API token modal."
          />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

GeneratedTokenComponent.defaultProps = {
  canRegenerateToken: undefined,
};

export default localize<Props>(GeneratedTokenComponent);
