// @flow

import { connect } from 'react-redux';

import workspaceGroupsReducer from 'reducers/entities/workspace-groups';
import userWorkspacesReducer from 'reducers/entities/user-workspaces';
import workspacesReducer from 'reducers/entities/workspaces';

import groupWorkspacesReducer from 'reducers/entities/group-workspaces';
import RemoveGroupFromWorkspace from './remove-group-from-workspace';

export const GROUP_ACCESS_QUERY = 'userWorkspaces/groupAccess';
export const REMOVE_GROUP_PIPE_ACTION = 'queryGroupWorkspaces';
const getQueryName = (id) => `admin/groups/${id}/workspaces`;

export const mapStateToProps = (state, { group }) => ({
  removeState: workspaceGroupsReducer.getRemoveSelector(state, { id: group.id }),
});

export const mapDispatchToProps = (dispatch, {
  group, bindingId, roleId, workspace, pipeAction, position,
}) => ({
  removeGroupFromWorkspace: () => {
    dispatch(workspaceGroupsReducer.removeWorkspaceGroup({
      id: bindingId,
      data: {
        id: bindingId,
        resourceType: 'collection',
        resourceId: workspace.id,
        actorType: 'group',
        actorId: group.id,
        roleId,
      },
      pipe: {
        onSuccess: () => {
          if (pipeAction === GROUP_ACCESS_QUERY) {
            dispatch(userWorkspacesReducer.queryUserWorkspaces({
              name: GROUP_ACCESS_QUERY,
              params: {
                positionId: position.id,
                actorType: 'group',
              },
            }));
          } else if (pipeAction === REMOVE_GROUP_PIPE_ACTION) {
            dispatch(groupWorkspacesReducer.queryGroupWorkspaces({
              name: getQueryName(group.id),
              params: {
                groupId: group.id,
              },
            }));
          } else {
            dispatch(workspaceGroupsReducer.queryWorkspaceGroupsReload({
              name: `workspace/${workspace.id}/relationships`,
            }));
            dispatch(workspacesReducer.fetchWorkspace({
              id: workspace.id,
              params: {
                accessStats: 1,
                includeAgreementStats: 1,
              },
            }));
          }
        },
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(workspaceGroupsReducer.removeWorkspaceGroupReset({
      id: bindingId,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveGroupFromWorkspace);
