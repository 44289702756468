// @flow

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import clsx from 'clsx';

import adminPage from 'hocs/admin-page';

import { AddWorkspaceMessageTemplate } from 'components/modals/add-message-template';
import { EditWorkspaceMessageTemplate } from 'components/modals/edit-message-template';
import { RemoveWorkspaceMessageTemplate } from 'components/modals/remove-message-template';
import { DeleteWorkspaceMessageTemplates } from 'components/modals/delete-message-templates';
import { DeleteBulkAction } from 'components/bulk-action-items/delete';

import { Acl, checkAcl } from 'components/acl';
import ActionsMenu from 'components/actions-menu';
import {
  Table,
  TableFiltering,
} from 'components/table';
import Pagination from 'components/pagination';
import Button from 'components/button';
import ActionBar from 'components/action-bar';
import MessageTemplateType, { getMessageTemplateTypesAsOptions }
  from 'components/message-template-type';
import { HelpCenterLink } from 'components/help-center-link';
import Add from 'components/icons/add';
import SmallAdd from 'components/icons/small-add';
import NoTemplatesIcon from 'components/icons/no-templates';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { EditMenuItem } from 'components/menu-items/edit';
import { LocalizedDateTime } from 'components/localized-date-time';
import style from './message-templates.module.scss';

export type Props = {
  messageTemplates: Array<MessageTemplate>,
  messageTemplatesQuery: Query,
  queryMessageTemplates: QueryFunc,
  queryReload: () => void,
  workspace: Workspace,
  message: MessageTranslator,
};

type State = {
  selectedMessageTemplates: Array<MessageTemplate>,
};

export class WorkspaceMessageTemplatesComponent extends React.Component<Props, State> {
  state = {
    selectedMessageTemplates: [],
  }

  componentDidMount() {
    const { queryMessageTemplates } = this.props;

    queryMessageTemplates({});
  }

  componentDidUpdate(prevProps: Props) {
    const { workspace, queryReload } = this.props;

    if (prevProps.workspace.id !== workspace.id) {
      queryReload();
    }
  }

  onFilter = (params: { type?: string }) => {
    const { queryMessageTemplates } = this.props;

    queryMessageTemplates({ params });
  };

  getName = (messageTemplate: MessageTemplate) => (
    <div className={style.Name}>
      {messageTemplate.name}
    </div>
  );

  getCreated = (messageTemplate: MessageTemplate) => (
    <LocalizedDateTime datetime={messageTemplate.createdTime} />
  );

  getTemplateType = (messageTemplate: MessageTemplate) => (
    <MessageTemplateType type={messageTemplate.type} />
  );

  getEditMenuItem = (messageTemplate: MessageTemplate) => (onClick: () => void) => {
    const canEdit = checkAcl(
      messageTemplate.acl,
      [
        'message_template:update:body',
        'message_template:update:name',
        'message_template:update:subject',
        'message_template:update:type',
      ],
      { match: 'any' },
    );

    return (
      <EditMenuItem
        onClick={onClick}
        disabled={!canEdit}
      />
    );
  }

  getDeleteMenuItem = (messageTemplate: MessageTemplate) => (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!checkAcl(messageTemplate.acl, 'message_template:remove')}
    />
  )

  getActions = (messageTemplate: MessageTemplate) => (
    <ActionsMenu
      actions={[
        <EditWorkspaceMessageTemplate messageTemplate={messageTemplate}>
          {this.getEditMenuItem(messageTemplate)}
        </EditWorkspaceMessageTemplate>,
        <RemoveWorkspaceMessageTemplate template={messageTemplate}>
          {this.getDeleteMenuItem(messageTemplate)}
        </RemoveWorkspaceMessageTemplate>,
      ]}
      focusOnCloseDisabled
    />
  );

  getTemplateTypes() {
    const { message } = this.props;
    const types = getMessageTemplateTypesAsOptions(message).map((type) => ({
      ...type,
      query: {
        type: type.value,
      },
    }));

    return ([{
      default: true,
      query: {},
      label: message({
        id: 'All template types',
        comment: 'Selection dropdown option to show all template types in message template list.',
      }),
      value: 'all',
    }].concat(types): Array<{}>);
  }

  getDeleteBulkAction = (onClick: () => void) => {
    const { selectedMessageTemplates } = this.state;

    const hasDeletePermission = selectedMessageTemplates.every((messageTemplate) => (
      checkAcl(messageTemplate.acl, 'message_template:remove')
    ));

    return (
      <DeleteBulkAction
        onClick={onClick}
        disabled={!hasDeletePermission}
      />
    );
  };

  getTableConfig() {
    const { selectedMessageTemplates } = this.state;
    const { message, messageTemplates } = this.props;

    return {
      items: messageTemplates,
      itemKey: 'id',
      actions: [
        <DeleteWorkspaceMessageTemplates
          selectedMessageTemplates={selectedMessageTemplates}
          key="remove"
        >
          {this.getDeleteBulkAction}
        </DeleteWorkspaceMessageTemplates>,
      ],
      columns: [
        {
          name: 'name',
          label: message({
            id: 'Template name',
            comment: 'Column for name in message templates table.',
          }),
          type: 'cell',
          value: this.getName,
        },
        {
          name: 'type',
          label: message({
            id: 'Type',
            comment: 'Column for type in message templates table.',
          }),
          type: 'cell',
          value: this.getTemplateType,
        },
        {
          name: 'created',
          label: message({
            id: 'Created',
            comment: 'Column for created date in message templates table.',
          }),
          type: 'cell',
          value: this.getCreated,
        },
        {
          name: 'actions',
          label: message({
            id: 'Actions',
            comment: 'Column for action buttons in message templates table.',
          }),
          type: 'actions',
          value: this.getActions,
        },
      ],
    };
  }

  getAddTemplateTextButton = (onClick: () => void) => (
    <Button
      kind="linkInline"
      customClass={clsx(style.CreateButton, style.ActionLink)}
      onClick={onClick}
      icon={<SmallAdd height="10px" />}
    >
      <Message
        id="Create template"
        comment="The button text for creating a message template"
      />
    </Button>
  )

  getAddTemplateButton = (onClick: () => void) => (
    <Button
      data-testid="create-message-template"
      icon={Add}
      kind="primary"
      onClick={onClick}
    >
      <Message
        id="Create template"
        comment="The button text for creating a message template"
      />
    </Button>
  )

  getEmptyContent() {
    const { workspace } = this.props;

    return (
      <div>
        <Message
          id="Click {createMessageTemplateButton} to create a message template in this workspace."
          values={{
            createMessageTemplateButton: (
              <AddWorkspaceMessageTemplate id={workspace.id}>
                {this.getAddTemplateTextButton}
              </AddWorkspaceMessageTemplate>
            ),
          }}
          comment="Empty state text in the message template list for workspaces."
        />
        <br />
        <HelpCenterLink path="support/solutions/articles/77000435908-workspace-message-templates" />
      </div>
    );
  }

  getEmptyState() {
    const { message } = this.props;

    return {
      header: message({
        id: 'This workspace has no message templates',
        comment: 'Empty state header in the message template list for workspaces.',
      }),
      icon: <NoTemplatesIcon height="72px" />,
      content: this.getEmptyContent(),
      showEmptyState: this.shouldShowEmptyState(),
    };
  }

  handlePageChange = ({ offset, limit }: Pagination) => {
    const { queryMessageTemplates, messageTemplatesQuery } = this.props;

    queryMessageTemplates({
      params: messageTemplatesQuery.params,
      pagination: {
        offset,
        limit,
      },
    });
  };

  selectionChangeHandler = (selectedMessageTemplateIds: Array<number>) => {
    const { messageTemplates } = this.props;

    const selectedMessageTemplates = selectedMessageTemplateIds.map((id) => (
      messageTemplates.find((messageTemplate) => messageTemplate.id === id)
    ));

    this.setState({ selectedMessageTemplates });
  };

  shouldShowEmptyState() {
    const { messageTemplatesQuery } = this.props;

    return messageTemplatesQuery.count === 0
      && !messageTemplatesQuery.loading
      && isEmpty(messageTemplatesQuery.params);
  }

  renderFilters() {
    const { messageTemplatesQuery } = this.props;

    if (this.shouldShowEmptyState()) {
      return null;
    }

    return (
      <TableFiltering
        filters={[{
          type: 'dropdown',
          name: 'type',
          options: this.getTemplateTypes(),
        }]}
        onFilterHandler={this.onFilter}
        loading={messageTemplatesQuery.loading}
      />
    );
  }

  renderPagination() {
    const { message, messageTemplatesQuery } = this.props;

    if (messageTemplatesQuery.loading || !messageTemplatesQuery.count) {
      return null;
    }

    return (
      <Pagination
        totalItems={messageTemplatesQuery.count}
        itemsPerPage={messageTemplatesQuery.pagination.limit}
        currentOffset={messageTemplatesQuery.pagination.offset}
        onChange={this.handlePageChange}
        entityName={message({
          id: 'templates',
          comment: 'The plural form when counting message templates in pagination.',
        })}
      />
    );
  }

  render() {
    const {
      messageTemplatesQuery,
      workspace,
    } = this.props;

    return (
      <div>
        <ActionBar collapsed>
          <ActionBar.Group>
            <Acl acl={workspace.acl} check="collection:message:template:create">
              <AddWorkspaceMessageTemplate id={workspace.id}>
                {this.getAddTemplateButton}
              </AddWorkspaceMessageTemplate>
            </Acl>
          </ActionBar.Group>
        </ActionBar>
        {this.renderFilters()}
        <Table
          config={this.getTableConfig()}
          query={messageTemplatesQuery}
          emptyState={this.getEmptyState()}
          onSelectionChanged={this.selectionChangeHandler}
        />
        {this.renderPagination()}
      </div>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({
  props: {
    message,
    workspace,
  },
}: { props: MapperProps }) => ({
  modules: [[]],
  section: message({ id: 'Contracts', comment: 'Used as the title for the section.' }),
  showWorkspace: true,
  title: message({ id: 'Message templates', comment: 'Page title for the message templates page.' }),
  workspace,
});

export default adminPage(propsMapper)(WorkspaceMessageTemplatesComponent);
