import { forwardRef } from 'react';

import { SlottableWithNestedChildren } from './helpers';
import type { Children, DivProps } from './types';

type Props = Children &
  DivProps & {
    /** Estimated progress of loading asynchronous options. */
    progress?: number
    /**
     * Accessible label for this loading progressbar. Not shown visibly.
     */
    label?: string
  }

/**
 * You should conditionally render this with `progress` while loading asynchronous items.
 */
const Loading = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const {
    progress,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children: _,
    label = 'Loading...',
    ...etc
  } = props;

  return (
    <div
      ref={forwardedRef}
      {...etc}
      // eslint-disable-next-line react/no-unknown-property
      cmdk-loading=""
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={100}
      aria-label={label}
    >
      {SlottableWithNestedChildren(props, (child) => (
        <div aria-hidden>{child}</div>
      ))}
    </div>
  );
});

Loading.displayName = 'Loading';

export default Loading;
