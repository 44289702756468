// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

type Props = {
  template: MessageTemplate,
  removeState: RemoveState,
  resetRemoveState: Function,
  removeTemplate: () => void,
  children: Function,
}

export const RemoveMessageTemplate = ({
  template,
  removeState,
  removeTemplate,
  resetRemoveState,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={removeTemplate}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <Message
        id="You are about to delete {template}. This can't be undone."
        values={{
          template: <Badge label={template.name} kind="name" />,
        }}
        comment="Modal text when deleting message template."
      />
    )}
    modalKey="delete message template modal"
  >
    {children}
  </RemoveConfirm>
);
