import { connect } from 'react-redux';
import type { DefaultRootState } from 'react-redux';

import { checkAcl } from 'components/acl';

import SetContractValue from './set-contract-value';
import type { Props } from './set-contract-value';

type StateProps = {
  isAllowedToEdit: boolean,
};

type OwnProps = Omit<Props, keyof StateProps> & {
  agreement: Oneflow.Agreement,
};

export const mapStateToProps = (_: DefaultRootState, { agreement }: OwnProps) => ({
  isAllowedToEdit: checkAcl(agreement.acl, 'agreement:agreement_value:update:amount'),
});

export default connect(
  mapStateToProps,
)(SetContractValue);
