import moment from 'moment';

const MOMENT_TIME_UNITS = {
  s: 'seconds',
  i: 'minutes',
  h: 'hours',
  d: 'days',
  w: 'weeks',
  m: 'months',
  y: 'years',
};

export const getTimePeriodObj = (timePeriod, withDuration) => {
  const obj = {
    scalar: parseInt(timePeriod?.substr(0, timePeriod.length - 1) || 0, 10),
    unit: timePeriod?.substr(-1) || 'm',
  };

  if (withDuration) {
    obj.duration = moment.duration(obj.scalar, MOMENT_TIME_UNITS[obj.unit]);
  }

  return obj;
};

export const getPeriodEndDate = (periodEndTimestamp) => (
  moment(periodEndTimestamp).subtract(6, 'hours').endOf('day').format()
);

export const getNoticeEndDate = (periodEndTimestamp, noticePeriod) => {
  const noticePeriodObj = getTimePeriodObj(noticePeriod, true);

  return moment(periodEndTimestamp).subtract(noticePeriodObj.duration).subtract(6, 'hours').endOf('day')
    .format();
};

export const getNextPeriodEndDate = (periodEndTimestamp, duration) => {
  const durationObj = getTimePeriodObj(duration, true);

  return moment(periodEndTimestamp).add(durationObj.duration).subtract(6, 'hours').endOf('day')
    .format();
};

/**
 * Formatting for the data type "time period"
 *
 * Format: \d+[sihdwmy]
 * where s = seconds, i = minutes, h = hours, d = days, w = weeks, m = months, y = years
 *
 * @param value
 * @returns {*}
 */
export const formatTimePeriod = (value, message) => {
  const valObj = getTimePeriodObj(value);

  switch (valObj.unit) {
    case 's':
      return message({
        id: '{number} second',
        pluralId: '{number} seconds',
        pluralCondition: 'number',
        values: { number: valObj.scalar },
        comment: 'Formatting seconds',
      });
    case 'i':
      return message({
        id: '{number} minute',
        pluralId: '{number} minutes',
        pluralCondition: 'number',
        values: { number: valObj.scalar },
        comment: 'Formatting minutes',
      });
    case 'h':
      return message({
        id: '{number} hour',
        pluralId: '{number} hours',
        pluralCondition: 'number',
        values: { number: valObj.scalar },
        comment: 'Formatting hours',
      });
    case 'd':
      return message({
        id: '{number} day',
        pluralId: '{number} days',
        pluralCondition: 'number',
        values: { number: valObj.scalar },
        comment: 'Formatting days',
      });

    case 'w':
      return message({
        id: '{number} week',
        pluralId: '{number} weeks',
        pluralCondition: 'number',
        values: { number: valObj.scalar },
        comment: 'Formatting weeks',
      });
    case 'y':
      return message({
        id: '{number} year',
        pluralId: '{number} years',
        pluralCondition: 'number',
        values: { number: valObj.scalar },
        comment: 'Formatting years',
      });

    default:
      return message({
        id: '{number} month',
        pluralId: '{number} months',
        pluralCondition: 'number',
        values: { number: valObj.scalar },
        comment: 'Formatting months',
      });
  }
};
