/* eslint-disable react/prop-types */

import React from 'react';
import { useSelector } from 'react-redux';
import { unescape } from 'lodash';

// eslint-disable-next-line import/no-unresolved
import { getGuestToken } from 'agreement/selectors';
import LinkIcon from 'components/icons/link';

import style from './label.module.scss';

const Label = ({
  hasDataField,
  isAllowedToUpdateSharedDataValue,
  label,
  required,
}) => {
  const guestToken = useSelector(getGuestToken);
  const isGuestView = Boolean(guestToken);
  return (
    <div className={style.InputContainer}>
      <div className={style.Label}>
        <p className={style.Text}>{unescape(label)}</p>
        {required ? (
          <span className={style.Required}> *</span>
        ) : null}
        {hasDataField && isAllowedToUpdateSharedDataValue && !isGuestView ? (
          <LinkIcon className={style.DataFieldIcon} height="14px" />
        ) : null}
      </div>
    </div>
  );
};

export default Label;
