import { Message } from '@oneflowab/pomes';

import * as Command from 'components/filterable';

import { AIAssistantPrompts } from '../../constants';

type Props = {
  onSelect: (prompt: string) => void;
}

const SelectionMenuFilterable = ({ onSelect }: Props) => (
  <Command.List>
    <Command.Group
      id="edit-or-review-selection"
      headingId="edit-or-review-selection-heading"
      heading={(
        <Message
          id="Edit or review selection"
          comment="Section header in dropdown that describes the actions to edit or review the selected text."
        />
      )}
    >
      <Command.Item
        id="improve-writing"
        value={AIAssistantPrompts.IMPROVE_WRITING}
        onSelect={() => onSelect(AIAssistantPrompts.IMPROVE_WRITING)}
      >
        <Message
          id="Improve writing"
          comment="An option in dropdown describing the action to improve the writing of the selected text."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="make-shorter"
        value={AIAssistantPrompts.MAKE_SHORTER}
        onSelect={() => onSelect(AIAssistantPrompts.MAKE_SHORTER)}
      >
        <Message
          id="Make shorter"
          comment="An option in dropdown describing the action to make the selected text shorter."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="make-longer"
        value={AIAssistantPrompts.MAKE_LONGER}
        onSelect={() => onSelect(AIAssistantPrompts.MAKE_LONGER)}
      >
        <Message
          id="Make longer"
          comment="An option in dropdown describing the action to make the selected text longer."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="simplify"
        value={AIAssistantPrompts.SIMPLIFY_TEXT}
        onSelect={() => onSelect(AIAssistantPrompts.SIMPLIFY_TEXT)}
      >
        <Message
          id="Simplify"
          comment="An option in dropdown describing the action to simplify the selected text."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
    </Command.Group>
    <Command.Group
      id="change-tone"
      headingId="change-tone-heading"
      heading={(
        <Message
          id="Change tone"
          comment="An option in dropdown describing the action to change the tone of the selected text."
        />
      )}
    >
      <Command.Item
        id="professional"
        value={AIAssistantPrompts.CHANGE_TONE_PROFESSIONAL}
        onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_PROFESSIONAL)}
      >
        <Message
          id="Professional"
          comment="An option in dropdown describing the action to change the tone of the selected text to professional."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="casual"
        value={AIAssistantPrompts.CHANGE_TONE_CASUAL}
        onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_CASUAL)}
      >
        <Message
          id="Casual"
          comment="An option in dropdown describing the action to change the tone of the selected text to casual."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="straight-forward"
        value={AIAssistantPrompts.CHANGE_TONE_STRAIGHT_FORWARD}
        onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_STRAIGHT_FORWARD)}
      >
        <Message
          id="StraightForward"
          comment="An option in dropdown describing the action to change the tone of the selected text to straight forward."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="confident"
        value={AIAssistantPrompts.CHANGE_TONE_CONFIDENT}
        onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_CONFIDENT)}
      >
        <Message
          id="Confident"
          comment="An option in dropdown describing the action to change the tone of the selected text to confident."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="friendly"
        value={AIAssistantPrompts.CHANGE_TONE_FRIENDLY}
        onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_FRIENDLY)}
      >
        <Message
          id="Friendly"
          comment="An option in dropdown describing the action to change the tone of the selected text to friendly."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
    </Command.Group>
    <Command.Group
      id="generate-from-selection"
      headingId="generate-from-selection-heading"
      heading={(
        <Message
          id="Generate from selection"
          comment="A dropdown section header that describes the actions to generate text from the selected text."
        />
      )}
    >
      <Command.Item
        id="summarize"
        value={AIAssistantPrompts.SUMMARIZE_TEXT}
        onSelect={() => onSelect(AIAssistantPrompts.SUMMARIZE_TEXT)}
      >
        <Message
          id="Summarize"
          comment="An option in dropdown describing the action to summarize the selected text."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
    </Command.Group>
    <Command.Group
      id="translate"
      headingId="translate-heading"
      heading={(
        <Message
          id="Translate"
          comment="A dropdown section header that describes the actions to translate the selected text."
        />
      )}
    >
      <Command.Item
        id="translate-to-swedish"
        value={AIAssistantPrompts.TRANSLATE_TO_SWEDISH}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_SWEDISH)}
      >
        <Message
          id="Swedish"
          comment="An option in dropdown describing the action to translate the selected text to Swedish."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-english"
        value={AIAssistantPrompts.TRANSLATE_TO_ENGLISH}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_ENGLISH)}
      >
        <Message
          id="English"
          comment="An option in dropdown describing the action to translate the selected text to English."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-norwegian"
        value={AIAssistantPrompts.TRANSLATE_TO_NORWEGIAN}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_NORWEGIAN)}
      >
        <Message
          id="Norwegian"
          comment="An option in dropdown describing the action to translate the selected text to Norwegian."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-danish"
        value={AIAssistantPrompts.TRANSLATE_TO_DANISH}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_DANISH)}
      >
        <Message
          id="Danish"
          comment="An option in dropdown describing the action to translate the selected text to Danish."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-finnish"
        value={AIAssistantPrompts.TRANSLATE_TO_FINNISH}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_FINNISH)}
      >
        <Message
          id="Finnish"
          comment="An option in dropdown describing the action to translate the selected text to Finnish."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-german"
        value={AIAssistantPrompts.TRANSLATE_TO_GERMAN}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_GERMAN)}
      >
        <Message
          id="German"
          comment="An option in dropdown describing the action to translate the selected text to German."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-spanish"
        value={AIAssistantPrompts.TRANSLATE_TO_SPANISH}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_SPANISH)}
      >
        <Message
          id="Spanish"
          comment="An option in dropdown describing the action to translate the selected text to Spanish."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-french"
        value={AIAssistantPrompts.TRANSLATE_TO_FRENCH}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_FRENCH)}
      >
        <Message
          id="French"
          comment="An option in dropdown describing the action to translate the selected text to French."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-dutch"
        value={AIAssistantPrompts.TRANSLATE_TO_DUTCH}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_DUTCH)}
      >
        <Message
          id="Dutch"
          comment="An option in dropdown describing the action to translate the selected text to Dutch."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-portuguese"
        value={AIAssistantPrompts.TRANSLATE_TO_PORTUGUESE}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_PORTUGUESE)}
      >
        <Message
          id="Portuguese"
          comment="An option in dropdown describing the action to translate the selected text to Portuguese."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
      <Command.Item
        id="translate-to-italian"
        value={AIAssistantPrompts.TRANSLATE_TO_ITALIAN}
        onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_ITALIAN)}
      >
        <Message
          id="Italian"
          comment="An option in dropdown describing the action to translate the selected text to Italian."
        />
        <kbd>&#8629;</kbd>
      </Command.Item>
    </Command.Group>
  </Command.List>
);

export default SelectionMenuFilterable;
