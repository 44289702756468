/* eslint-disable camelcase */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  has,
  keyBy,
} from 'lodash';
import { localize } from '@oneflowab/pomes';
import clsx from 'clsx';
import type {
  FormEvent,
  FormEventHandler,
  KeyboardEventHandler,
} from 'react';
import type {
  MessageProps,
  MessageTranslator,
} from '@oneflowab/pomes';
import type { PopoverContentProps } from '@radix-ui/react-popover';

import {
  getDisplayDiscountPercentage,
  isDiscountPercentage,
} from 'agreement/boxes/product';
import { isBoxDataRemoveAllowed } from 'agreement/box-data-remove-permissions';
import { useProductTableBoxContext } from 'contexts/product-table-box-context';
import useCurrentPriceRoundingMethod from 'hooks/use-current-price-rounding-method';
import usePopupDialog from 'hooks/popup/use-popup-dialog';
import type {
  PriceRoundingMethod,
  Product,
  ProductValue,
} from 'data-validators/entity-schemas/document-box/product-table.ts';

import { CancelButton } from 'components/buttons/cancel';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import {
  NO_DISCOUNT,
  PRODUCT_COUNT_TYPE_SINGLE_CHOICE,
  PRODUCT_SELECTED,
} from 'components/contract-boxes/product-table-box/constants';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import DeleteIcon from 'components/icons/delete';
import Message from 'components/message';
import RemoveIcon from 'components/icons/remove';
import TooltipInfo from 'components/tooltip-info';

import {
  adjustNumberOnArrowKeyDown,
  getPriceRelated,
} from './update-product-helpers';
import {
  createInvalidationMessage,
  makeMaxLengthValidator,
} from './input-validators';
import {
  getDiscountValidators,
  getPriceValidators,
} from './validation-helpers';
import Input from './input';
import QuantityTypeSelect from './quantity-type-select';
import type { UpdatedProduct } from './update-product-helpers';
import type { ValidatorReturnType } from './input-validators';

import style from './update-input-item.module.scss';

export type Props = {
  asChild?: boolean;
  children: React.ReactNode;
  dataPopupId: string,
  disabled?: boolean;
  isResponsiveView?: boolean;
  message: MessageTranslator;
  onClearChosenProduct: (productId: number) => void;
  popoverContentProps?: PopoverContentProps;
  popupType: 'popover' | 'dialog';
  productId: number;
  removeProduct: (productId: number) => void;
  updateProductData: (productId: number, updatedProduct: UpdatedProduct) => void;
  value: ProductValue,
};

type InputName =
  | 'name'
  | 'price_1'
  | 'price_1_discount_amount'
  | 'price_2'
  | 'price_2_discount_amount';

type Validations = Record<InputName, ValidatorReturnType>;

type TooltipInfoProps = {
  message: React.ReactElement<MessageProps>;
  align?: 'start' | 'center' | 'end';
  alignOffset?: number;
}

const ProductTooltipInfo = ({
  message,
  align,
  alignOffset = 0,
}: TooltipInfoProps) => (
  <TooltipInfo
    message={message}
    zIndex={99999999}
    align={align}
    alignOffset={alignOffset}
  />
);

const initialValidations = {
  name: '',
  price_1: '',
  price_1_discount_amount: '',
  price_2: '',
  price_2_discount_amount: '',
};

type Params = Parameters<typeof getPriceRelated>;
export type FormBody = Params[0];
type EnabledColumnsKeyLabelMapper = Params[1];
type PriceKey = Params[3];

export const getUpdatedProduct = (
  bodyData: FormBody,
  enabledColumnsKeyLabelMapper: EnabledColumnsKeyLabelMapper,
  currentPriceRoundingMethod: PriceRoundingMethod,
  pricePrecision: number,
) => ({
  ...getPriceRelated(bodyData, enabledColumnsKeyLabelMapper, currentPriceRoundingMethod, 'price_1', pricePrecision as number),
  ...getPriceRelated(bodyData, enabledColumnsKeyLabelMapper, currentPriceRoundingMethod, 'price_2', pricePrecision as number),
  name: bodyData?.name,
  readOnly: Number(bodyData.counterPartyCanOnlyRead) || 0,
  count_type: Number(bodyData.count_type),
});

export function UpdateProductItemForm(props: Props): React.ReactElement | null {
  const {
    asChild,
    children,
    dataPopupId,
    disabled,
    isResponsiveView,
    message,
    onClearChosenProduct,
    popoverContentProps,
    popupType,
    productId,
    removeProduct,
    updateProductData,
    value,
  } = props;

  const {
    name = '',
    price_1,
    // Used only to add whether `%` or not in initial discount value
    // and not considered in form submission data
    // Since there it will be calculated based on user input
    price_1_discount_type = NO_DISCOUNT,
    price_1_discount_amount,
    price_2,
    price_2_discount_type = NO_DISCOUNT,
    price_2_discount_amount,
    count_type,
    readOnly,
    count,
  } = value;

  const isSelectedProduct = count_type === PRODUCT_COUNT_TYPE_SINGLE_CHOICE
  && count === PRODUCT_SELECTED;
  const { Popup, PopupContent } = usePopupDialog(popupType);
  const {
    box,
    config,
    data,
    enabledColumnsKeyLabelMapper,
    setProductData,
  } = useProductTableBoxContext();
  const products = keyBy(data, getId);
  const product = products[productId] as Product;

  const currentPriceRoundingMethod = useCurrentPriceRoundingMethod();

  const quantityTypeRef = useRef<HTMLButtonElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const closeButtonRef = useRef<HTMLInputElement>(null);

  const initialPrice1DiscountAmount = isDiscountPercentage(price_1_discount_type)
    ? `${String(getDisplayDiscountPercentage(price_1_discount_amount))}%`
    : (price_1_discount_amount || '');

  const initialPrice2DiscountAmount = isDiscountPercentage(price_2_discount_type)
    ? `${String(getDisplayDiscountPercentage(price_2_discount_amount))}%`
    : (price_2_discount_amount || '');

  const formSubmissionPreventedRef = useRef(false);
  const formRef = useRef<HTMLFormElement | null>();

  const getInvalidationMessageForDiscount = useMemo(() => (
    createInvalidationMessage(['price_1_discount_amount', 'price_2_discount_amount'], getDiscountValidators())
  ), []);

  const getInvalidationMessageForPrice = useMemo(() => (
    createInvalidationMessage(['price_1', 'price_2'], getPriceValidators())
  ), []);

  const validationMapper = useMemo(() => ({
    name: (event: FormEvent<HTMLInputElement>) => makeMaxLengthValidator({
      maxLength: 80,
      field: (
        <Message
          id="Product name"
          comment="validation label for product name in edit product"
        />
      ),
      message,
    })(event.currentTarget.value),
    discount: getInvalidationMessageForDiscount,
    price: getInvalidationMessageForPrice,
  }), [
    getInvalidationMessageForDiscount,
    getInvalidationMessageForPrice,
    message,
  ]);

  const [validations, setValidations] = useState<Validations>(initialValidations);
  const [readOnlyState, setReadOnlyState] = useState<boolean>(Boolean(readOnly));

  // Using `some` over `every` since some might stop the iteration as soon as it
  // Satisfies the predicate condition
  const hasSomeValidationIssue = Object.values(validations).some(
    (validationText) => Boolean(validationText),
  );

  const canSubmit = !hasSomeValidationIssue;

  const resetDiscountValidations = useCallback((event: Event) => {
    if (!formSubmissionPreventedRef.current) {
      return;
    }

    // Event registered on form hence the usage of target to get delegated invocation from input
    const targetElement = event.target as HTMLInputElement;
    const formElement = targetElement.form;
    if (targetElement?.name?.startsWith('price_1') && validations.price_1_discount_amount) {
      setValidations({
        ...validations,
        price_1_discount_amount: '',
      });

      if (formElement !== null && formElement instanceof HTMLFormElement) {
        const discountInput = formElement.elements.namedItem(
          'price_1_discount_amount',
        ) as HTMLInputElement;
        discountInput.setCustomValidity('');
      }
    }

    if (targetElement?.name?.startsWith('price_2') && validations.price_2_discount_amount) {
      setValidations({
        ...validations,
        price_2_discount_amount: '',
      });

      if (formElement !== null && formElement instanceof HTMLFormElement) {
        const discountInput = formElement.elements.namedItem(
          'price_2_discount_amount',
        ) as HTMLInputElement;
        discountInput.setCustomValidity('');
      }
    }
  }, [validations]);

  const resetFormChanges = () => {
    setValidations(initialValidations);
    setReadOnlyState(Boolean(readOnly));
  };

  const formFunctionalRef = useCallback((form: HTMLFormElement) => {
    if (!form) {
      formRef.current?.removeEventListener('input', resetDiscountValidations);
      formRef.current = null;
      return;
    }

    if (formRef.current === form) {
      return;
    }

    formRef.current = form;
    formRef.current.addEventListener('input', resetDiscountValidations);
  }, [resetDiscountValidations]);

  const handlePopupKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
    // Handle When user click outside the form area and press enter
    if (
      event.key !== 'Enter' || (
        event.target !== quantityTypeRef.current
        && formRef.current?.contains(event.target as Node)
      )
    ) {
      return;
    }

    formRef.current?.requestSubmit(saveButtonRef.current);
  };

  const validateDiscount = (priceKey: PriceKey, bodyData: FormBody) => {
    const discountKey = `${priceKey}_discount_amount` as keyof FormBody;
    const priceKeyValue = bodyData[priceKey];
    const discountAmount = bodyData[discountKey];

    if (!discountAmount) {
      return '';
    }

    const discountValidators = getDiscountValidators(priceKeyValue);
    const discountValidation = discountValidators.find(
      (validator) => validator.validate(discountAmount),
    );

    if (discountValidation) {
      const validationMessage = discountValidation?.validationMessage() as React.ReactElement<
        MessageProps
      >;
      setValidations({
        ...validations,
        [discountKey]: validationMessage,
      });

      return validationMessage?.props.id || '';
    }

    return '';
  };

  const validateDiscounts = (bodyData: FormBody) => {
    let validationMessage = validateDiscount('price_1', bodyData);
    if (validationMessage) {
      return ['price_1_discount_amount', validationMessage];
    }

    validationMessage = validateDiscount('price_2', bodyData);
    if (validationMessage) {
      return ['price_2_discount_amount', validationMessage];
    }

    return [];
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const formElement = event.target as HTMLFormElement;
    const formData = new FormData(formElement) as unknown as Iterable<
      [FormBody, FormDataEntryValue]
    >;
    const bodyData: FormBody = Object.fromEntries(formData);
    const [inputName, discountValidationMessage] = validateDiscounts(bodyData);

    if (!formElement.checkValidity() || (inputName && discountValidationMessage)) {
      formSubmissionPreventedRef.current = true;
      const inputElement = formElement.elements.namedItem(inputName) as HTMLInputElement;
      inputElement.setCustomValidity(discountValidationMessage);
      return;
    }

    // When unchecked formData will not contain `counterPartyCanOnlyRead` property
    // However we need to include this information in request body
    // And `readOnly` prop will be set 0 in this scenario. @see `getUpdatedProduct`
    bodyData.counterPartyCanOnlyRead = readOnlyState ? '1' : '0';
    formSubmissionPreventedRef.current = false;

    const updatedProductData = getUpdatedProduct(
      bodyData,
      enabledColumnsKeyLabelMapper,
      currentPriceRoundingMethod,
      config.pricePrecision,
    );

    setProductData({ productId, bodyData });
    updateProductData(productId, updatedProductData);

    closeButtonRef.current?.click();
  };

  const onRemove = () => {
    removeProduct(productId);
    closeButtonRef.current?.click();
  };

  const onClearSelected = () => {
    onClearChosenProduct(productId);
    closeButtonRef.current?.click();
  };

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if ((e.target as Element).closest('[data-scrollable]')) return;
      e.stopPropagation();
    };

    const handleTouchMove = (e: TouchEvent) => {
      if ((e.target as Element).closest('[data-scrollable]')) return;
      e.stopPropagation();
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') {
        return;
      }
      const inputElement = e.target as HTMLInputElement;

      if (!inputElement.id.includes('price')) {
        return;
      }

      if (dataPopupId !== inputElement.dataset.popupId) {
        return;
      }
      e.preventDefault();

      const newInputValue = `${adjustNumberOnArrowKeyDown(e.key, inputElement.value)}`;
      inputElement.value = newInputValue;

      const validationText = inputElement.id.includes('discount')
        ? validationMapper.discount(e as unknown as FormEvent<HTMLInputElement>)
        : validationMapper.price(e as unknown as FormEvent<HTMLInputElement>);

      setValidations({
        ...validations,
        [inputElement.id]: validationText,
      });
    };

    document.addEventListener('wheel', handleWheel, true);
    document.addEventListener('touchmove', handleTouchMove, true);
    document.addEventListener('keydown', handleKeyDown, true);

    return () => {
      document.removeEventListener('wheel', handleWheel, true);
      document.removeEventListener('touchmove', handleTouchMove, true);
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [dataPopupId, validationMapper, validations]);

  const productNameLabel = enabledColumnsKeyLabelMapper.name || <Message id="Product" comment="fallback label for missing product name label" />;
  const price1Label = enabledColumnsKeyLabelMapper.price_1 || <Message id="Price 1" comment="fallback label for missing price 1 label" />;
  const price2Label = enabledColumnsKeyLabelMapper.price_2 || <Message id="Price 2" comment="fallback label for missing price 2 label" />;

  return (
    <Popup.Root>
      <Popup.Trigger
        className={clsx(
          style.UpdateProductItemTriggerButton,
          { [style.ResponsiveView]: isResponsiveView },
        )}
        disabled={disabled}
        asChild={asChild}
      >
        {children}
      </Popup.Trigger>
      <PopupContent
        className={style.UpdateProductItemContent}
        isPortal
        onInteractOutside={resetFormChanges}
        onKeyDown={handlePopupKeyDown}
        popoverContentProps={popoverContentProps}
      >
        <div className={style.PopupBody}>
          <div className={style.PopupHeader}>
            <Message id="Edit product" comment="Popup title for edit product" />
          </div>
          <form
            onSubmit={handleSubmit}
            className={style.FormLayout}
            noValidate
            ref={formFunctionalRef}
          >
            {has(enabledColumnsKeyLabelMapper, 'name') && (
              <>
                <Input<'name', Validations>
                  type="text"
                  id="name"
                  name="name"
                  label={productNameLabel}
                  defaultValue={name}
                  validations={validations}
                  setValidations={setValidations}
                  validate={validationMapper.name}
                />
                <div className={style.SectionDivider} />
              </>
            )}
            {has(enabledColumnsKeyLabelMapper, 'price_1') && (
              <>
                <div className={style.PriceFields}>
                  <Input<'price_1', Validations>
                    type="text"
                    id="price_1"
                    name="price_1"
                    dataPopupId={dataPopupId}
                    label={price1Label}
                    defaultValue={price_1 || ''}
                    validations={validations}
                    setValidations={setValidations}
                    validate={validationMapper.price}
                    tooltipInfo={(
                      <ProductTooltipInfo
                        message={(
                          <Message
                            id="Decimal numbers (e.g. 1.2 or 1.25) will match the decimal format selected in the price settings and will be rounded if necessary."
                            comment="Tooltip message for price input"
                          />
                        )}
                        align="start"
                        alignOffset={-40}
                      />
                    )}
                  />
                  <Input<'price_1_discount_amount', Validations>
                    type="text"
                    id="price_1_discount_amount"
                    name="price_1_discount_amount"
                    dataPopupId={dataPopupId}
                    placeholder={message({ id: 'e.g., 1% or 1', comment: 'placeholder that lets user know this field uses either a regular number or a number with a percentage sign' }) as string}
                    label={<Message id="Discount" comment="Input label for product discount field" />}
                    defaultValue={initialPrice1DiscountAmount}
                    validations={validations}
                    setValidations={setValidations}
                    validate={validationMapper.discount}
                    tooltipInfo={(
                      <ProductTooltipInfo
                        message={(
                          <Message
                            id="Add a discount as a percentage (e.g., 1%) or as a specific amount (e.g., 1)."
                            comment="Tooltip message explaining valid discount inputs"
                          />
                        )}
                        align="end"
                        alignOffset={-40}
                      />
                    )}
                  />
                </div>
                <div className={style.SectionDivider} />
              </>
            )}
            {has(enabledColumnsKeyLabelMapper, 'price_2') && (
              <>
                <div className={style.PriceFields}>
                  <Input<'price_2', Validations>
                    type="text"
                    id="price_2"
                    name="price_2"
                    dataPopupId={dataPopupId}
                    label={price2Label}
                    defaultValue={price_2 || ''}
                    validations={validations}
                    setValidations={setValidations}
                    validate={validationMapper.price}
                    tooltipInfo={(
                      <ProductTooltipInfo
                        message={(
                          <Message
                            id="Decimal numbers (e.g. 1.2 or 1.25) will match the decimal format selected in the price settings and will be rounded if necessary."
                            comment="Tooltip message for price input"
                          />
                        )}
                        align="start"
                        alignOffset={-40}
                      />
                    )}
                  />
                  <Input<'price_2_discount_amount', Validations>
                    type="text"
                    id="price_2_discount_amount"
                    name="price_2_discount_amount"
                    dataPopupId={dataPopupId}
                    placeholder={message({ id: 'e.g., 1% or 1', comment: 'placeholder that lets user know this field uses either a regular number or a number with a percentage sign' }) as string}
                    label={<Message id="Discount" comment="Input label for product discount field" />}
                    defaultValue={initialPrice2DiscountAmount}
                    validations={validations}
                    setValidations={setValidations}
                    validate={validationMapper.discount}
                    tooltipInfo={(
                      <ProductTooltipInfo
                        message={(
                          <Message
                            id="Add a discount as a percentage (e.g., 1%) or as a specific amount (e.g., 1)."
                            comment="Tooltip message explaining valid discount inputs"
                          />
                        )}
                        align="end"
                        alignOffset={-40}
                      />
                    )}
                  />
                </div>
                <div className={style.SectionDivider} />
              </>
            )}
            <div className={clsx(style.FieldLayout, style.RelativePosition)}>
              <QuantityTypeSelect
                name="count_type"
                defaultValue={count_type}
                triggerRef={quantityTypeRef}
              />
            </div>
            <div className={style.FieldLayout}>
              <Checkbox
                wrapLabelText
                customCheckboxClass={style.CheckBox}
                customLabelStyle={style.CheckBoxLabelText}
                input={{
                  checked: readOnlyState,
                  name: 'counterPartyCanOnlyRead',
                  onChange: (
                    { target: { checked } }: React.ChangeEvent<HTMLInputElement>,
                  ) => setReadOnlyState(checked),
                }}
                label={(
                  <Message
                    id="Disable counterparties from editing field value"
                    comment="Input label for product discount field"
                  />
                )}
                data-testid="counterparty-can-only-read"
              />
            </div>
            {isSelectedProduct && (
              <div className={style.ClearSelectedContainer}>
                <Button
                  icon={RemoveIcon}
                  onClick={onClearSelected}
                  customClass={style.ClearButton}
                >
                  <Message
                    id="Clear selected"
                    comment="Button label to clear selection from a product"
                  />
                </Button>
              </div>
            )}
            <div className={style.RemoveProductContainer}>
              <Button
                icon={DeleteIcon}
                onClick={onRemove}
                customClass={style.RemoveButton}
                disabled={!isBoxDataRemoveAllowed(box, product)}
              >
                <Message
                  id="Remove product"
                  comment="Button label to remove a product."
                />
              </Button>
            </div>
            <div className={style.FormActions}>
              <Popup.Close asChild>
                <CancelButton onClick={resetFormChanges} />
              </Popup.Close>
              <Button
                data-testid="save-button"
                type="submit"
                kind="secondary"
                disabled={!canSubmit}
                buttonRef={saveButtonRef}
              >
                <Message
                  id="Save"
                  comment="Action to save when the document period reminder should be set."
                />
              </Button>
              <Popup.Close asChild>
                {/* No translation needed and not part of accessibility */}
                <Button
                  aria-hidden="true"
                  customClass={style.HiddenButton}
                  buttonRef={closeButtonRef}
                  type="button"
                >
                  Programmatic hidden close button
                </Button>
              </Popup.Close>
            </div>
          </form>
        </div>
      </PopupContent>
    </Popup.Root>
  );
}

// To overwrite incorrect pomes type hints
const LocalizedUpdateProductItemForm = localize<Props>(
  UpdateProductItemForm,
) as unknown as React.FunctionComponent<Omit<Props, 'message'>>;

export default LocalizedUpdateProductItemForm;
