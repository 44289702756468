// @flow

import isArray from 'lodash/isArray';
import isInteger from 'lodash/isInteger';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import { OPERATOR_AND } from 'oneflow-client/core';

export const getValidValue = (value: Array<number | string>) => {
  if (!value.length) {
    return null;
  }

  return value;
};

export const freeTextSanitizer = (value: string, minFreeTextSearchLength: number) => {
  if (isNull(value) || isUndefined(value) || String(value).length < minFreeTextSearchLength) {
    return null;
  }

  return value;
};

export const statesFilterSanitizer = (filterableStates: Array<number>) => (
  value: Array<number>,
) => {
  if (!isArray(value)) {
    return null;
  }

  const validValue: Array<number> = value
    .filter(isInteger)
    .filter((item) => filterableStates.includes(item));

  return getValidValue(validValue);
};

export const lifecycleFilterSanitizer = statesFilterSanitizer;

export const tagsFilterSanitizer = (value: Array<number>) => {
  if (!isArray(value)) {
    return null;
  }

  const validValue: Array<number> = value.filter(isInteger);

  return getValidValue(validValue);
};

export const operatorSanitizer = (value: Array<number>) => {
  if (!isArray(value) || !value.includes(OPERATOR_AND)) {
    return null;
  }

  return [OPERATOR_AND];
};

export const noTagSanitizer = (value: Array<any>) => {
  if (!isArray(value)
    || value.length !== 1
    || value[0] !== 1) {
    return null;
  }

  return [1];
};

export const signOrderFilterSanitizer = (filterableSignOrders: Array<string>) => (
  value: Array<string>,
) => {
  if (!isArray(value)) {
    return null;
  }

  const validValue: Array<string> = value
    .filter((item) => filterableSignOrders.includes(item));

  return getValidValue(validValue);
};

export const colleaguesSanitizer = (
  isSharedWithMeWorkspace: boolean,
  currentUser: Position,
) => (
  value: Array<number>,
) => {
  if (!isArray(value)) {
    return null;
  }

  if (isSharedWithMeWorkspace) {
    const validValue: Array<number> = value.filter(
      (selectedPositionId) => currentUser.id !== selectedPositionId,
    );
    return getValidValue(validValue);
  }

  const validValue: Array<number> = value.filter(isInteger);
  return getValidValue(validValue);
};

export const workspacesSanitizer = () => (value: Array<number>) => {
  if (!isArray(value)) {
    return null;
  }

  const validValue: Array<number> = value.filter(isInteger);
  return getValidValue(validValue);
};

export const insightsFilterSanitizer = (filterableStates: Array<string>) => (
  value: Array<string>,
) => {
  if (!isArray(value)) {
    return null;
  }

  const validValue: Array<string> = value
    .filter((item) => filterableStates.includes(item));

  return getValidValue(validValue);
};

export const approvalFilterSanitizer = (filterableStates: Array<string>) => (
  value: Array<string>,
) => {
  if (!isArray(value)) {
    return null;
  }

  const validValue: Array<string> = value
    .filter((item) => filterableStates.includes(item));

  return getValidValue(validValue);
};
