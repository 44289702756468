// @flow

import * as React from 'react';
import isFunction from 'lodash/isFunction';
import clsx from 'clsx';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import style from './phone-field.module.scss';

type Props = {
  input: Input,
  meta?: InputMeta,
  disabled?: boolean,
  defaultCountry?: string,
  preferredCountries?: Array<string>,
};

class PhoneField extends React.Component<Props> {
  static defaultProps = {
    meta: undefined,
    disabled: false,
    defaultCountry: 'se',
    preferredCountries: ['fi', 'dk', 'no', 'se'],
  };

  handleChange = (value: string) => {
    const { input } = this.props;

    const fullValue = value.startsWith('+') ? value : '+'.concat(value);

    if (isFunction(input.onChange)) {
      input.onChange({ target: { value: fullValue } });
    }
  };

  isPhoneInputValid = () => true;

  displayError() {
    const { meta = {} } = this.props;

    return meta.dirty || meta.touched;
  }

  hasError() {
    const { meta = {} } = this.props;
    const hasSubmitError = meta.submitError && !meta.dirtySinceLastSubmit;

    return Boolean(meta.error) || Boolean(hasSubmitError);
  }

  renderInput() {
    const {
      input,
      disabled,
      defaultCountry,
      preferredCountries,
      ...inputProps
    } = this.props;

    const inputContainerClasses = clsx(style.InputField, {
      [style.error]: this.displayError() && this.hasError(),
      [style.disabled]: disabled,
    });

    return (
      <div className={inputContainerClasses}>
        <PhoneInput
          {...input}
          {...inputProps}
          country={defaultCountry}
          disabled={disabled}
          placeholder=""
          preferredCountries={preferredCountries}
          disableAreaCodes
          autoFormat={false}
          onChange={this.handleChange}
          buttonClass={style.FlagDropdown}
          inputClass={style.Input}
          isValid={this.isPhoneInputValid}
        />
      </div>
    );
  }

  renderError() {
    const { meta = {} } = this.props;
    let error = '';

    if (this.displayError() && this.hasError()) {
      error = meta.error || meta.submitError;
    }

    return (
      <span className={style.ErrorMessage}>
        {error}
      </span>
    );
  }

  render() {
    return (
      <div className={style.PhoneField}>
        {this.renderInput()}
        {this.renderError()}
      </div>
    );
  }
}

export default PhoneField;
