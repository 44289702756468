// @flow

import * as React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import Tooltip from 'components/tooltip';
import Lock from 'components/icons/lock';
import ShareIcon from 'components/icons/share';
import { isSharedFromAnotherWorkspace } from 'agreement/selectors';

import style from './template-sharing-indicator.module.scss';

type Props = {
  agreement: Agreement,
  height?: string,
  currentWorkspace: Workspace,
  isTemplateAccessible: boolean,
  message: MessageTranslator,
}

const optionsForTooltipInModal = {
  modifiers: {
    addZIndex: {
      enabled: true,
      order: 810,
      fn: (data) => ({
        ...data,
        styles: {
          ...data.styles,
          zIndex: 10003,
        },
      }),
    },
  },
};

export class TemplateSharingIndicatorComponent extends React.PureComponent<Props> {
  static defaultProps = {
    height: undefined,
  };

  getSharedFromWorkspaceTooltipText() {
    const { isTemplateAccessible, message } = this.props;

    if (isTemplateAccessible) {
      return message({
        id: 'This template is shared from another workspace',
        comment: 'Tooltip message for shared template.',
      });
    }

    return message({
      id: "This template is shared from another workspace and can't be accessed",
      comment: 'Tooltip message for shared template.',
    });
  }

  render() {
    const {
      agreement,
      height,
      currentWorkspace,
      message,
    } = this.props;

    if (isSharedFromAnotherWorkspace(agreement, currentWorkspace.id)) {
      return (
        <Tooltip
          message={this.getSharedFromWorkspaceTooltipText()}
          side="top"
          popperOptions={
            optionsForTooltipInModal
          }
          zIndex="10003"
        >
          <div className={style.IconContainer}>
            <Lock height={height} />
          </div>
        </Tooltip>
      );
    }

    if (agreement.isShared) {
      return (
        <Tooltip
          message={message({
            id: 'This template is shared with other workspaces',
            comment: 'Tooltip message for shared template.',
          })}
          side="top"
          popperOptions={
            optionsForTooltipInModal
          }
          zIndex="10003"
        >
          <div className={style.IconContainer}>
            <ShareIcon height={height} />
          </div>
        </Tooltip>
      );
    }

    return null;
  }
}

export default localize<Props>(TemplateSharingIndicatorComponent);
