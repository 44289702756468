// @flow
import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

type Props = {
  group: Group,
  position: Position,
  removeState: RemoveState,
  removeUserFromGroup: () => void,
  resetRemoveState: Function,
  children: () => React.Node,
};

export const handleConfirm = ({ removeUserFromGroup, isLoading }: Props) => () => {
  if (isLoading) {
    return;
  }

  removeUserFromGroup();
};

export const RemoveUserFromGroupComponent = ({
  group,
  position,
  removeState,
  removeUserFromGroup,
  resetRemoveState,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={removeUserFromGroup}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <p>
        <Message
          id="You are about to remove {userName} from {groupName}."
          pluralId="You are about to remove {userName} from {count} groups."
          pluralCondition="count"
          values={{
            userName: <Badge label={position.fullname} kind="name" />,
            groupName: <Badge label={group.name} kind="name" />,
            count: 1,
          }}
          comment="Confirmation message in modal. In remove user from group modal."
        />
      </p>
    )}
    data-testid="remove-user-from-group"
    modalKey="remove group for user modal"
  >
    {children}
  </RemoveConfirm>
);

export default RemoveUserFromGroupComponent;
