import { Message } from '@oneflowab/pomes';

import { MenuItem } from 'components/menu-item';
import DelegateSigningRightsModal from 'components/modals/delegate-signing-rights';
import DelegateSigningIcon from 'components/icons/delegate-signing';

type Props = {
  agreement: Oneflow.Agreement
}

const DelegateSigning = ({ agreement }: Props) => (
  <DelegateSigningRightsModal
    agreement={agreement}
  >
    {(onClick: () => void) => (
      <MenuItem
        icon={DelegateSigningIcon}
        onClick={onClick}
        label={(
          <Message
            id="Delegate signing"
            comment="Label for action to Delegate signing."
          />
          )}
      />
    )}
  </DelegateSigningRightsModal>
);

export default DelegateSigning;
