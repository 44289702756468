import { formatDateString, getAgreementDateFormat } from 'date';
import { Message } from '@oneflowab/pomes';

import { isMarkedAsDeclined } from 'agreement';

import Dot from '../dot';
import style from './style.module.scss';

type Props = {
  agreement: Oneflow.Agreement;
};

const Declined = ({ agreement }: Props) => {
  const dateFormat = getAgreementDateFormat(agreement?.config?.dateFormat);
  const dateString = formatDateString(agreement?.stateTime?.toString(), dateFormat);

  let message = (
    <Message
      id="Declined:"
      comment="Agreement status when the agreement is declined."
    />
  );

  if (isMarkedAsDeclined(agreement)) {
    message = (
      <Message
        id="Marked as declined:"
        comment="Agreement status when the agreement is marked as declined."
      />
    );
  }

  return (
    <div className={style.Insight} data-testid="declined">
      <Dot color="red" />
      <span className={style.SemiBold}>
        {message}
      </span>
      <span data-testid="declined-date">
        {' '}
        {dateString}
      </span>
    </div>
  );
};

export default Declined;
