/* eslint-disable max-len */
import isEmpty from 'lodash/isEmpty';
import useCurrentContractId from './use-current-contract-id';
import useAgreement from './use-agreement';

const defaultPriceRoundingMethod = 'legacy';

/**
 * @typedef {import('data-validators/entity-schemas/document-box/product-table').PriceRoundingMethod PriceRoundingMethod}
 * @type {() => PriceRoundingMethod}
*/
const useCurrentPriceRoundingMethod = () => {
  const currentContractId = useCurrentContractId();
  const agreement = useAgreement(currentContractId);

  if (isEmpty(agreement)) {
    return defaultPriceRoundingMethod;
  }

  return agreement.config?.priceRoundingMethod || defaultPriceRoundingMethod;
};

export default useCurrentPriceRoundingMethod;
