// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import CalendarMinus from 'components/icons/calendar-minus';

import Insight from './insight';

const WithoutDuration = () => (
  <Insight
    IconComponent={CalendarMinus}
    insight={(
      <Message
        id="Without duration"
        comment="Insight for contracts with no duration type. Visible in contract card."
      />
    )}
  />
);

export default WithoutDuration;
