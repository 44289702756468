import { connect } from 'react-redux';

import groupsReducer from 'reducers/entities/groups';

import { RemoveGroup } from './remove-group';

const QUERY_NAME = 'admin/groups';

export const mapStateToProps = (state, { group }) => ({
  removeState: groupsReducer.getRemoveSelector(state, { id: group.id }),
});

export const mapDispatchToProps = (dispatch, { group }) => ({
  removeGroup: () => {
    dispatch(groupsReducer.removeGroup({
      id: group.id,
      data: {
        ids: [group.id],
      },
      pipe: {
        action: () => groupsReducer.queryGroupsReload({
          name: QUERY_NAME,
        }),
        wait: groupsReducer.QUERY_GROUPS_SUCCESS,
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(groupsReducer.removeGroupReset({
      id: group.id,
    }));
  },
});

const connectedRemoveGroup = connect(mapStateToProps, mapDispatchToProps)(RemoveGroup);

export { connectedRemoveGroup as RemoveGroup };
