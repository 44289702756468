import { useSelector, useDispatch } from 'react-redux';
import { isTemplate } from 'agreement';

import useAgreement from 'hooks/use-agreement';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import agreementsReducer from 'reducers/entities/agreements';
import getCSSPropertyValue from 'utils/get-css-property-value';

import { SaveFolderDestinationModal } from 'components/modals/save-folder-destination';
import Button from 'components/button';
import CancelIcon from 'components/icons/cancel';
import FolderIcon from 'components/icons/folder';
import SmallAddIcon from 'components/icons/small-add';

import Message from 'components/message';
import style from './folder.module.scss';

type Props = {
  agreementId: number,
}

const DefaultFolder = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const dispatch = useDispatch();

  const currentWorkspace = useSelector(getCurrentWorkspaceSelector);
  const currentWorkspaceId = currentWorkspace.id;
  const currentWorkspaceName = currentWorkspace.name;

  const removeDefaultFolder = () => {
    dispatch(agreementsReducer.moveAgreement({
      id: agreement.id,
      data: {
        targetFolderId: null,
        targetWorkspaceId: currentWorkspaceId,
      },
    }));
  };

  const renderDefaultFolderContent = () => {
    const folderName = agreement.folder?.name;

    if (folderName) {
      return (
        <div className={style.DefaultFolderNameContainer} data-testid="folder-name-container">
          <SaveFolderDestinationModal
            agreement={agreement}
            currentWorkspaceId={currentWorkspaceId}
            currentWorkspaceName={currentWorkspaceName}
            hasADefaultFolder
          >
            {(onClick: () => void) => (
              <Button
                customClass={style.FolderNameButton}
                icon={<FolderIcon height="14px" />}
                kind="linkInline"
                onClick={onClick}
              >
                {folderName}
              </Button>
            )}
          </SaveFolderDestinationModal>
          <Button
            icon={<CancelIcon height="14px" color={getCSSPropertyValue('--of-forest-green-01')} />}
            kind="linkInline"
            onClick={removeDefaultFolder}
          />
        </div>
      );
    }

    return (
      <div data-testid="folder-select-container">
        <SaveFolderDestinationModal
          agreement={agreement}
          currentWorkspaceId={currentWorkspaceId}
          currentWorkspaceName={currentWorkspaceName}
        >
          {(onClick: () => void) => (
            <Button
              customClass={style.SelectFolderButton}
              icon={<SmallAddIcon height="8px" />}
              kind="secondary-lite"
              onClick={onClick}
            >
              <Message
                id="Select folder"
                comment="Button text for opening a modal to select a folder"
              />
            </Button>
          )}
        </SaveFolderDestinationModal>
      </div>
    );
  };

  if (isTemplate(agreement)) {
    return (
      <div className={style.DefaultFolder} data-testid="folder-container">
        <p className={style.FolderHeader}>
          <Message
            id="Save new documents to"
            comment="Header for saving a document to a selected folder"
          />
        </p>
        <p className={style.FolderText}>
          <Message
            id="Documents created from this template will be saved to the selected folder."
            comment="Text explaining that all documents from this template will be saved in a selected folder"
            data-testid="settings-description"
          />
        </p>
        {renderDefaultFolderContent()}
      </div>
    );
  }
  return null;
};

export default DefaultFolder;
