import clsx from 'clsx';

import * as DropdownMenu from 'components/dropdown-menu-v2';
import NewAddIcon from 'components/icons/new-add';

import style from '../box-menu.module.scss';

type Props = {
  menuClasses: string,
  onMouseOver: () => void,
  onMouseLeave: () => void,
  isBoxDndOver: boolean,
}

const ExpandedTrigger = ({
  menuClasses,
  onMouseOver,
  onMouseLeave,
  isBoxDndOver,
}: Props) => {
  const lineClassName = clsx(style.Line, {
    [style.IsBoxDndOver]: isBoxDndOver,
  });
  const outerClassName = clsx(style.Outer, {
    [style.IsBoxDndOver]: isBoxDndOver,
  });

  return (
    <DropdownMenu.Trigger
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={style.BoxMenuTrigger}
      data-testid="expanded-trigger"
    >
      <div className={menuClasses}>
        <div className={lineClassName} data-testid="line" />
        <div className={outerClassName}>
          <div className={style.ButtonContainer}>
            <div
              data-testid="add-box-button"
              className={style.AddButton}
            >
              <NewAddIcon />
            </div>
          </div>
        </div>
      </div>
    </DropdownMenu.Trigger>
  );
};

export default ExpandedTrigger;
