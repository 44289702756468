// @flow
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

import agreements from 'reducers/entities/agreements';
import { getOffsetForPage } from 'components/pagination/helpers';
import * as agreementConstants from 'agreement/constants';
import EmptyState from 'components/empty-state';
import { ControlledSearch } from 'components/search';
import CircularSpinner from 'components/icons/circular-spinner';
import NoContractsIcon from 'components/icons/no-contracts';
import { Message } from '@oneflowab/pomes';
import MiniContractCard from 'components/mini-contract-card';
import { ApiError, unknownApiError } from 'components/api-error';
import useOutsideClick from 'hooks/use-outside-click';

import style from './contract-selector.module.scss';

type Props = {|
  collectionId?: number,
  onSearchItemClicked: Function,
  searchContainerRef?: any,
  disabled: boolean,
|};

export const ContractSelector = ({
  collectionId,
  onSearchItemClicked,
  searchContainerRef,
  disabled,
}: Props) => {
  const [searchData, setSearchData] = useState({ query: '' });
  const [searchResultVisibility, setSearchResultVisibility] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const query = useSelector((state) => agreements.getQuerySelector(state, { name: 'agreementLinks/search' }));
  const resultAgreements = useSelector(
    (state) => agreements.getAgreementsSelector(state, { ids: query.result }),
  );

  const onClickOutside = React.useCallback(() => {
    setSearchResultVisibility(false);
  }, []);

  useOutsideClick(inputRef, onClickOutside, searchResultVisibility);

  const getParams = React.useCallback((value) => {
    const params = {
      state: [
        agreementConstants.STATE_DRAFT,
        agreementConstants.STATE_PENDING,
        agreementConstants.STATE_OVERDUE,
        agreementConstants.STATE_SIGNED,
        agreementConstants.STATE_DECLINED,
      ],
      q: value,
      sort: [
        '-score',
      ],
    };
    if (collectionId) {
      return {
        ...params,
        collectionIds: [collectionId],
      };
    }

    return {
      ...params,
      global: true,
    };
  }, [collectionId]);

  const fetchContractsFromServer = React.useCallback((value) => {
    setSearchResultVisibility(true);

    dispatch(agreements.queryAgreements({
      name: 'agreementLinks/search',
      params: getParams(value),
      sort: [
        '-score',
      ],
      pagination: {
        limit: 20,
        offset: getOffsetForPage(1, 20),
      },
    }));

    setSearchData({
      query: value,
    });
  }, [dispatch, getParams]);

  const getContracts = React.useCallback((value) => {
    if (value.length > 1) {
      fetchContractsFromServer(value);
    } else {
      setSearchResultVisibility(false);
    }
  }, [fetchContractsFromServer]);

  const handleSelectedAgreement = (agreement) => {
    setSearchResultVisibility(false);
    onSearchItemClicked(agreement);
    setSearchData({ query: searchData.query });
  };

  const renderContractResult = () => {
    const noResult = searchData.query.length > 1 && isEmpty(resultAgreements);

    if (query.loading) {
      return (
        <div className={style.ContractsResult}>
          <div className={style.LoadingSpinner}>
            <CircularSpinner />
          </div>
        </div>
      );
    }

    if (query.error) {
      return (
        <div className={style.ContractsResult}>
          <ApiError customMessage={unknownApiError} />
        </div>
      );
    }

    if (!query.loading && noResult) {
      return (
        <EmptyState
          icon={<NoContractsIcon height="25px" />}
          header={(
            <Message
              id="No contracts found"
              comment="Empty state header. Shown in contract list when no contracts are found."
            />
        )}
          content={(
            <Message
              id="Please adjust your search criteria and try again."
              comment="Empty state content. Shown in contract list when no contracts are found."
            />
        )}
          defaultStyle
          className={clsx(style.ContractsResult, style.NoContract)}
        />
      );
    }

    return (
      <div className={style.ContractsResult}>
        {resultAgreements && Object.entries(resultAgreements).map(([key, value]) => (
          <MiniContractCard
            agreement={value}
            onClick={() => handleSelectedAgreement(value)}
            key={key}
          />
        ))}
      </div>
    );
  };

  const renderContractResultPortal = () => {
    if (!searchResultVisibility) {
      return null;
    }

    if (!searchContainerRef) {
      return renderContractResult();
    }

    if (!searchContainerRef.current) {
      return null;
    }

    return ReactDOM.createPortal(
      renderContractResult(),
      searchContainerRef.current,
    );
  };

  const onKeyDown = React.useCallback((event) => {
    if (event.key !== 'Escape' || !searchResultVisibility) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setSearchResultVisibility(false);
  }, [searchResultVisibility]);

  return (
    <div className={style.SearchContainer}>
      <ControlledSearch
        onChange={getContracts}
        value={searchData.query}
        onKeyDown={onKeyDown}
        inputRef={inputRef}
        disabled={disabled}
      />
      {renderContractResultPortal()}
    </div>
  );
};

ContractSelector.defaultProps = {
  collectionId: undefined,
  searchContainerRef: undefined,
};
