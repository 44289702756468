// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  formatDateString,
  getDaysSince,
} from 'date';

import Edit from 'components/icons/edit';

import Insight from './insight';

import style from './insight.module.scss';

type Props = {
  updated: string,
  dateFormat: string,
};

const Updated = ({ updated, dateFormat }: Props) => {
  const renderInsight = () => {
    const daysSince = getDaysSince(updated);
    const dateString = formatDateString(updated, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    if (daysSince === 0) {
      return (
        <Message
          id="Updated today ({date})"
          values={{
            date,
          }}
          comment="Insight for draft agreement, stating when agreement was updated. Visible in contract card."
        />
      );
    }

    if (daysSince > 7) {
      return (
        <Message
          id="Updated {date}"
          values={{
            date,
          }}
          comment="Insight for draft agreement, stating when agreement was updated. Visible in contract card."
        />
      );
    }

    if (daysSince > 0) {
      return (
        <Message
          id="Updated yesterday ({date})"
          pluralId="Updated {days} days ago ({date})"
          pluralCondition="days"
          values={{
            days: daysSince,
            date,
          }}
          comment="Insight for draft agreement, stating when agreement was updated. Visible in contract card."
        />
      );
    }

    return null;
  };

  return (
    <Insight
      IconComponent={Edit}
      insight={renderInsight()}
    />
  );
};

export default Updated;
