// @flow

import {
  KEY_PIPEDRIVE,
} from 'extensions';

const Pipedrive = {
  key: KEY_PIPEDRIVE,
};

export default Pipedrive;
