// @flow

import { connect } from 'react-redux';

import {
  getAgreementCounterparties,
  getAgreementMyParticipant,
} from 'agreement/selectors';

import { SendContractBody } from './send-contract-body';

type OwnProps = {|
  agreement: Agreement,
|};

type StateProps = {|
  counterparties: Array<AgreementParticipant>,
  myParticipant: AgreementParticipant,
|};

type DispatchProps = {||};

type MapStateToProps = (state: State, OwnProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state: State, { agreement }: OwnProps) => ({
  counterparties: getAgreementCounterparties(agreement),
  myParticipant: getAgreementMyParticipant(agreement),
});

type MapDispatchToProps = () => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, any>(
  mapStateToProps,
  mapDispatchToProps,
)(SendContractBody);
