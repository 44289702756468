// @flow

import * as React from 'react';
import { localize, Message } from '@oneflowab/pomes';
import ModalForm from 'hocs/modal-form';

import InfoBoxClipboard from 'components/info-box-clipboard';
import Field from 'components/field';
import TextField from 'components/text-field';
import Button from 'components/button';
import Edit from 'components/icons/edit';
import { ExtensionName } from 'components/extension/extension-name';
import { subdomainValidator } from 'forms/validators';

import style from './extension-subdomain.module.scss';

type FormData = {
  subdomain: string | null,
};

export class ExtensionSubdomainComponent extends React.PureComponent<Props> {
  validations = (({ message }) => ({
    subdomain: subdomainValidator({
      message,
    }),
  }))(this.props);

  handleSubmit = ({ subdomain }: FormData) => {
    const { updateExtension, extension, configAttribute } = this.props;

    updateExtension({
      id: extension.extensionId,
      config: {
        ...extension.config,
        [configAttribute]: subdomain,
      },
    });
  };

  renderSubDomain() {
    const { configAttribute } = this.props;
    const subdomain = this.props.extension.config[configAttribute];

    if (!subdomain) {
      return (
        <Message
          id="Not set"
          comment="Placeholder for setting extension subdomain."
        />
      );
    }

    return subdomain;
  }

  renderExtensionUrl() {
    const { extension, url, configAttribute } = this.props;
    const subdomain = extension.config[configAttribute];
    const { prefix, suffix } = url;

    if (!subdomain) {
      return null;
    }

    return (
      <InfoBoxClipboard value={`${prefix}${subdomain}${suffix}`} />
    );
  }

  renderChildren = (onClick: Function) => (
    <Button
      icon={Edit}
      kind="round"
      outline
      onClick={onClick}
      customClass={style.EditButton}
    />
  );

  renderEditModal() {
    const {
      message,
      extension,
      updateState,
      resetUpdateState,
      configAttribute,
    } = this.props;

    return (
      <ModalForm
        title={message({
          id: 'Set subdomain',
          comment: 'Modal title for setting extension subdomain.',
        })}
        body={this.renderBody()}
        onSubmit={this.handleSubmit}
        resetFormState={resetUpdateState}
        formState={updateState}
        initialValues={{
          subdomain: extension.config[configAttribute],
        }}
        modalKey="set extension subdomain modal"
      >
        {this.renderChildren}
      </ModalForm>
    );
  }

  renderBody() {
    const { message, url } = this.props;
    const { prefix, suffix } = url;

    return (
      <div>
        {prefix}
        <div className={style.DomainField}>
          <Field
            name="subdomain"
            placeholder={message({
              id: 'Enter subdomain',
              comment: 'Placeholder for setting subdomain for an extension.',
            })}
            autoFocus
            component={TextField}
            validate={this.validations.subdomain}
          />
        </div>
        {suffix}
      </div>
    );
  }

  render() {
    const { extension } = this.props;
    return (
      <>
        <p>
          <Message
            id="Enter the {extensionName} subdomain used by your company in the field below. If you are uncertain about what the subdomain is, contact {extensionName} support to get help."
            comment="Subdomain configuration help text for an extension."
            values={{
              extensionName: <ExtensionName extension={extension} />,
            }}
          />
        </p>
        <span className={style.SubDomain}>
          <span className={style.Label}>
            <Message
              id="Subdomain:"
              comment="Subdomain label on an extension settings page."
            />
          </span>
          {this.renderSubDomain()}
          {this.renderEditModal()}
        </span>
        {this.renderExtensionUrl()}
      </>
    );
  }
}

export default localize<Props>(ExtensionSubdomainComponent);
