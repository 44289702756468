import { useCallback, useEffect } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import {
  DOCUMENT_TAB,
  PARTICIPANTS_TAB,
  MESSAGES_TAB,
  AUDIT_TRAIL_TAB,
  MORE_OPTIONS_TAB,
  SETTINGS_TAB,
  DATA_FIELDS_TAB,
  CONTENT_TAB,
} from 'agreement/constants';
import { getAccountFromSessionSelector } from 'reducers/session';
import { showPaymentReminderBanner } from 'account';

import useResetFocusedEditor from 'hooks/rich-text-editor/use-reset-focused-editor';
import { useVirtualKeyboardInfo } from 'hooks/use-virtual-keyboard-info';

import { FreemiumNoticeBanner, showFreemiumNoticeBanner } from 'components/freemium-notice-banner';
import { ToastProvider } from 'components/toasts';
import FilesUploadStatusBox from 'components/files-upload-status-box';
import PaymentReminderBanner from 'components/payment-reminder-banner';
import DataFieldsTabContainer from 'components/document-tabs/data-fields-tab';
import AuditTrailTab from 'components/document-tabs/audit-trail-tab';
import MessagesTab from 'components/document-tabs/messages-tab';
import MoreOptionsTab from 'components/document-tabs/more-options-tab';
import PartiesTab from 'components/document-tabs/parties-tab';
import SettingsTab from 'components/document-tabs/settings-tab';
import DocumentTab from 'components/document-tabs/document-tab';
import { ContentTab } from 'components/document-tabs/content-tab/content-tab';

import { useCollapsedDocumentLayout } from './context';
import NavigationBar from './navigation-bar';
import style from './collapsed-document-layout.module.scss';

type Props = {
  agreementId: Oneflow.Document['id'],
}

const CollapsedDocumentLayout = ({ agreementId }: Props) => {
  const account: Oneflow.Account = useSelector(getAccountFromSessionSelector);
  const { activeTab, setActiveTab, bottomFloatingContainerRef } = useCollapsedDocumentLayout();
  const { keyboardHeight, isKeyboardOnscreen } = useVirtualKeyboardInfo();
  const closeTab = useCallback(() => {
    setActiveTab(DOCUMENT_TAB);
  }, [setActiveTab]);
  const resetFocusedEditor = useResetFocusedEditor();

  useEffect(() => {
    const defaultBodyOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = defaultBodyOverflow;
    };
  }, []);

  const bodyClasses = clsx(style.ContentBodyContainer, {
    [style.WithBanner]: showFreemiumNoticeBanner(account) || showPaymentReminderBanner(account),
  });

  return (
    <div
      className={style.CollapsedDocumentContainer}
      onPointerDown={resetFocusedEditor}
    >
      <FreemiumNoticeBanner />
      <PaymentReminderBanner />
      <Tabs.Root
        defaultValue={DOCUMENT_TAB}
        value={activeTab}
        data-testid="root"
        className={style.TabsRoot}
      >
        <div className={bodyClasses}>
          <Tabs.Content forceMount value={DOCUMENT_TAB} hidden={activeTab !== DOCUMENT_TAB}>
            <DocumentTab agreementId={agreementId} />
          </Tabs.Content>
          <Tabs.Content value={PARTICIPANTS_TAB} className={style.TabContainer}>
            <PartiesTab onClose={closeTab} />
          </Tabs.Content>
          <Tabs.Content value={CONTENT_TAB} className={style.TabContainer}>
            <ContentTab onClose={closeTab} />
          </Tabs.Content>
          <Tabs.Content value={AUDIT_TRAIL_TAB} className={style.TabContainer}>
            <AuditTrailTab agreementId={agreementId} onClose={closeTab} />
          </Tabs.Content>
          <Tabs.Content value={MESSAGES_TAB} className={style.TabContainer}>
            <MessagesTab agreementId={agreementId} onClose={closeTab} />
          </Tabs.Content>
          <Tabs.Content value={MORE_OPTIONS_TAB} className={style.TabContainer}>
            <MoreOptionsTab agreementId={agreementId} onClose={closeTab} />
          </Tabs.Content>
          <Tabs.Content value={SETTINGS_TAB} className={style.TabContainer}>
            <SettingsTab agreementId={agreementId} onClose={closeTab} />
          </Tabs.Content>
          <Tabs.Content value={DATA_FIELDS_TAB} className={style.TabContainer}>
            <DataFieldsTabContainer agreementId={agreementId} onClose={closeTab} />
          </Tabs.Content>
          <ToastProvider position="bottom-center" viewportClassName={style.ToastProvider} />
        </div>
        {/*
          Please read before changing:
          the position of the FilesUploadStatusBox should be before bottom navigation
          cause we want to avoid using z-index and in the default stacking, the element that
          comes later will be on top, and we want the bottom navigation to be on top

          we're rendering this separately for expanded and collapsed layout because its
          order in the DOM should be different
        */}
        <FilesUploadStatusBox />
        <Tabs.List className={style.BottomNavigationContainer}>
          <NavigationBar
            agreementId={agreementId}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </Tabs.List>
      </Tabs.Root>
      <div
        style={{
          bottom: isKeyboardOnscreen ? keyboardHeight : undefined,
        }}
        className={style.BottomFloatingContainer}
        ref={bottomFloatingContainerRef}
      />
    </div>

  );
};

export default CollapsedDocumentLayout;
