// @flow

import React from 'react';
import get from 'lodash/get.js';
import { Form } from 'react-final-form';
import { Message, localize } from '@oneflowab/pomes';

import queryString from 'query-string';
import { type ContextRouter } from 'react-router-dom';

import type { FormRenderProps } from 'react-final-form';
import Button from 'components/button';

import CircularSpinner from 'components/icons/circular-spinner';
import CheckCircle from 'components/icons/check-circle';
import Error from 'components/icons/error';
import style from './extension-setup.module.scss';

export type Props = {
  ...LocalizeProps,
  ...ContextRouter,
  setupState: RpcState,
  setup: () => void,
};

export class SetupComponent extends React.PureComponent<Props> {
  componentDidUpdate() {
    const { extension, setupState } = this.props;
    if (setupState.success) {
      window.open(extension.setupUrl, '_self');
    }
  }

  isSetupOK() {
    const { location } = this.props;
    const { status } = queryString.parse(location.search);

    return status === 'ok';
  }

  renderSetupStatus() {
    const { setupState: { error } } = this.props;

    if (error && get(error, 'body.status_code') !== 200) {
      return <Error className={style.SetupFailed} />;
    }

    if (this.isSetupOK()) {
      return <CheckCircle className={style.SetupSuccess} />;
    }

    return null;
  }

  renderForm = (formProps: FormRenderProps<any>) => {
    const { setupState } = this.props;

    return (
      <form
        onSubmit={formProps.handleSubmit}
        data-testid="generic-setup-form"
      >
        <Button
          type="submit"
          color="thunder"
          customClass={style.SetupButton}
          icon={setupState.loading ? CircularSpinner : null}
          disabled={setupState.loading}
          data-testid="setup-button"
        >
          <Message
            id="Setup"
            comment="Button text for the generic extension setup."
          />
        </Button>
      </form>
    );
  }

  render() {
    const { setup } = this.props;

    return (
      <>
        <h3 className={style.ExtensionConfigurationHeader}>
          <Message
            id="Extension setup"
            comment="Section header for the generic extension setup."
          />
          {this.renderSetupStatus()}
        </h3>
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="Set up the integration by clicking the Setup button."
            comment="Help text for the generic extension setup."
          />
        </div>
        <Form
          onSubmit={setup}
          render={this.renderForm}
        />
      </>
    );
  }
}

export default localize<Props>(SetupComponent);
