/* eslint-disable react/prop-types */

import React from 'react';

import Button from 'components/button';
import Message from 'components/message';
import SmallAdd from 'components/icons/small-add';

import style from './add-row-button.module.scss';

const AddRowButton = ({ onClick }) => (
  <Button
    customClass={style.AddRow}
    icon={<SmallAdd height="8px" />}
    kind="secondary-lite"
    onClick={onClick}
  >
    <Message id="Add row" comment="Button label for add row in form box." />
  </Button>
);

export default AddRowButton;
