import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { stringToHslColor } from 'utils/color-type-conversion';

import { isNewUpdate } from 'agreement/selectors';
import useAgreement from 'hooks/use-agreement';
import { getSeenMessageThreadsSelector } from 'reducers/app';

import ParticipantBubble from 'components/contract-card/participant-bubble';
import { UserBadge } from 'components/user-badge';

import style from './author-bubble.module.scss';

type Props = {
  agreementId: number,
  participant: AgreementParticipant,
  formattedDate: string,
  message?: Message,
}

const AuthorBubble = ({
  agreementId,
  participant,
  formattedDate,
  message,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const seenThreads = useSelector(getSeenMessageThreadsSelector);
  const isMessageNewUpdate = useMemo(() => isNewUpdate(agreement, message), [agreement, message]);
  const isUpdateSeen = useMemo(() => (
    !message || seenThreads.includes(message.id) || seenThreads.includes(message.parent?.id)
  ), [message, seenThreads]);

  const name = participant.fullname
  || participant.name
  || participant.partyName;

  const newUpdateClasses = clsx(style.NewMessageIndicator, {
    [style.FadeOut]: isUpdateSeen,
  });

  return (
    <div className={style.AuthorBubble}>
      {isMessageNewUpdate && <div className={newUpdateClasses} />}
      <ParticipantBubble
        className={style.ParticipantBubble}
        participant={participant}
        withoutClosedIcons
        renderBadge={() => (
          <UserBadge
            diameter={32}
            borderSize={2}
            borderColor={stringToHslColor(name)}
          />
        )}
      />
      <div className={style.MessageInfoWrapper}>
        <div className={style.Author}>
          {name}
        </div>
        <div className={style.MessageDate}>
          {formattedDate}
        </div>
      </div>
    </div>
  );
};

export default AuthorBubble;
