// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import TerminateIcon from 'components/icons/terminate';
import Confirmable from 'components/confirmable';
import { CancelButton, DeleteButton } from 'components/buttons';

import style from './remove-confirm.module.scss';

export const handleConfirm = (onConfirm: Function, isLoading: boolean) => () => {
  if (isLoading) {
    return;
  }

  onConfirm();
};

type Props = {
  confirmMessage: React.Node,
  confirmState?: RemoveState | UpdateState,
  onConfirm: Function,
  onClose?: Function,
  children?: React.Node | () => React.Node,
  resetConfirmState: Function,
  renderButton?: Function,
  outline?: boolean,
  customClass?: string,
  disabled?: boolean,
  isOpen?: boolean,
  icon?: any,
  header?: any,
  modalKey?: string,
  customErrorMessage?: ErrorMessage,
};

export const RemoveConfirm = ({
  confirmMessage,
  confirmState,
  onConfirm,
  children,
  resetConfirmState,
  renderButton,
  outline,
  customClass,
  disabled,
  isOpen,
  icon,
  header,
  onClose,
  modalKey,
  customErrorMessage,
}: Props) => {
  let actions = ({ closeConfirmation }) => (
    <>
      <CancelButton onClick={closeConfirmation} />
      <DeleteButton
        isLoading={confirmState?.loading}
        disabled={confirmState?.loading}
        onClick={onConfirm}
      />
    </>
  );

  const renderIcon = () => {
    if (!disabled) {
      if (icon) {
        return icon;
      }

      return <TerminateIcon className={style.Terminate} height="69px" />;
    }
    return null;
  };

  const renderHeader = () => {
    if (header) {
      return header;
    }
    return <Message id="Confirm" comment="Modal title when deleting message template." />;
  };

  if (renderButton) {
    actions = renderButton;
  }

  return (
    <Confirmable
      header={renderHeader()}
      body={(
        <div className={style.Wrapper}>
          {renderIcon()}
          {confirmMessage}
        </div>
      )}
      outline={outline}
      customClass={customClass}
      error={confirmState?.error}
      success={confirmState?.success}
      onEnter={handleConfirm(onConfirm, confirmState?.loading)}
      isConfirmLoading={confirmState?.loading}
      onConfirm={onConfirm}
      actions={actions}
      onOpen={resetConfirmState}
      onClose={onClose}
      isOpen={isOpen}
      modalKey={modalKey}
      customErrorMessage={customErrorMessage}
    >
      {children}
    </Confirmable>
  );
};
