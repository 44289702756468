// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import useCurrentBox from 'hooks/use-current-box';
import useCurrentBoxId from 'hooks/use-current-box-id';
import { getMyParticipantWhenUpdater } from 'agreement/selectors';
import agreements from 'reducers/entities/agreements';
import { VideoBoxPropsProvider } from 'contexts/video-box-props';

import { DOCUMENT_COLLAPSED_LAYOUT_SIZE } from 'components/document-layout-container/helpers';
import { getVideoData } from 'components/contract-boxes/video-box/video-box-helpers';

import VideoBoxContainer from './video-box-container';

import style from './video-box.module.scss';
import BoxWrapper from '../box-wrapper/box-wrapper';

type Props = {
  boxId: number,
  isEditable: boolean,
  agreementId: number,
  onRemoveBox: Function,
  onAddSectionRules: Function,
}

const VideoBox = ({
  boxId,
  isEditable,
  agreementId,
  onRemoveBox,
  onAddSectionRules,
}: Props) => {
  const agreement = useSelector((state) => (
    agreements.getAgreementSelector(state, { id: agreementId })
  ));
  const currentBoxId = useCurrentBoxId(boxId);
  const box = useCurrentBox(currentBoxId);

  const myParticipant = React.useMemo(() => {
    if (isEmpty(agreement)) {
      return {};
    }

    return getMyParticipantWhenUpdater(agreement);
  }, [agreement]);

  const width = get(box, 'config.width');

  const data = get(box, 'content.data');
  const existingVideo = getVideoData(data);

  const isMobileAndHasData = !(isEmpty(existingVideo))
    && window.innerWidth < DOCUMENT_COLLAPSED_LAYOUT_SIZE;

  const boxContainerClasses = clsx(style.VideoBoxContainer, {
    [style.FullWidth]: width === 'full',
    [style.IsMobileAndHasData]: isMobileAndHasData,
  });

  return (
    <BoxWrapper
      customClasses={style.VideoBoxWrapper}
      boxId={currentBoxId}
      isAllowedToEdit={isEditable}
      onRemoveBox={onRemoveBox}
      onAddSectionRules={onAddSectionRules}
      nonFixedActions={[]}
    >
      <VideoBoxPropsProvider
        agreementId={agreementId}
        box={box}
        myParticipant={myParticipant}
      >
        <div className={boxContainerClasses}>
          <VideoBoxContainer />
        </div>
      </VideoBoxPropsProvider>
    </BoxWrapper>
  );
};

export default VideoBox;
