import { Message } from '@oneflowab/pomes';

const Declined = ({ declinedDate }: { declinedDate: string }) => (
  <Message
    id="Document was declined on {date}."
    values={{
      date: declinedDate,
    }}
    comment="Text for declined contract. Visible in contract."
  />
);

export default Declined;
