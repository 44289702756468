// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { isMarkedAsSigned } from 'agreement';
import {
  formatDateString,
  getDaysSince,
} from 'date';
import MarkAsSigned from 'components/icons/mark-as-signed';
import NewCheck from 'components/icons/new-check';

import Insight from './insight';

import style from './insight.module.scss';

const markedAsSignedInsights = {
  today: (date: string) => (
    <Message
      id="Marked as signed today ({date})"
      values={{
        date,
      }}
      comment="Insight for signed contract, stating when contract was marked as signed. Visible in contract card."
    />
  ),
  recent: (date: string, daysSince: number) => (
    <Message
      id="Marked as signed yesterday ({date})"
      pluralId="Marked as signed {days} days ago ({date})"
      pluralCondition="days"
      values={{
        days: daysSince,
        date,
      }}
      comment="Insight for signed contract, stating when contract was marked as signed. Visible in contract card."
    />
  ),
  old: (date: string) => (
    <Message
      id="Marked as signed {date}"
      values={{
        date,
      }}
      comment="Insight for signed contract, stating when contract was marked as signed. Visible in contract card."
    />
  ),
};
const signedInsights = {
  today: (date: string) => (
    <Message
      id="Signed today ({date})"
      values={{
        date,
      }}
      comment="Insight for signed contract, stating when contract was signed. Visible in contract card."
    />
  ),
  recent: (date: string, daysSince: number) => (
    <Message
      id="Signed yesterday ({date})"
      pluralId="Signed {days} days ago ({date})"
      pluralCondition="days"
      values={{
        days: daysSince,
        date,
      }}
      comment="Insight for signed contract, stating when contract was signed. Visible in contract card."
    />
  ),
  old: (date: string) => (
    <Message
      id="Signed {date}"
      values={{
        date,
      }}
      comment="Insight for signed contract, stating when contract was signed. Visible in contract card."
    />
  ),
};

type Props = {
  agreement: Agreement,
  dateFormat: string,
};

const Signed = ({ agreement, dateFormat }: Props) => {
  const renderInsight = () => {
    const daysSince = getDaysSince(agreement.stateTime);
    const dateString = formatDateString(agreement.stateTime, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    let insightTexts = signedInsights;
    if (isMarkedAsSigned(agreement)) {
      insightTexts = markedAsSignedInsights;
    }

    if (daysSince === 0) {
      return insightTexts.today(date);
    }

    if (daysSince > 7) {
      return insightTexts.old(date);
    }

    if (daysSince > 0) {
      return insightTexts.recent(date, daysSince);
    }

    return null;
  };

  return (
    <Insight
      IconComponent={isMarkedAsSigned(agreement) ? MarkAsSigned : NewCheck}
      insight={renderInsight()}
    />
  );
};

export default Signed;
