import {
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { BOX_FORM } from 'agreement/constants';

import { canCounterpartEditBoxDataItemField } from 'agreement/permissions';
import { getPositionFromSessionSelector } from 'reducers/session';
import {
  isConcluded,
  isTemplate,
} from 'agreement/states';
import {
  getAgreementMyParticipant,
  getGuestToken,
} from 'agreement/selectors';
import { isBoxConfigUpdateAllowed } from 'agreement/box-config-update-permissions';
import { isBoxDataSharedValueUpdateAllowed } from 'agreement/box-data-shared-value-update-permissions';
import * as userConstants from 'user/constants';
import useAgreement from 'hooks/use-agreement';
import useCurrentBox from 'hooks/use-current-box';
import usePreviousUntilValuesChanged from 'hooks/use-previous-until-values-changed';

import checkAcl from 'components/acl/check-acl';

type PermissionHooks = {
  useCanAlterBoxComposition: (boxId: number) => boolean;
  useCanEditBoxDataItemField: (boxId: number) => (data: any, isReadOnly?: boolean) => boolean;
}

const ignoreEverythingAlwaysReturnFalse = () => false;

export function canAlterBoxComposition(
  boxConfig: ContractView.BoxConfig,
  acl: Oneflow.Document['acl'],
) {
  if (boxConfig?.managerLock) {
    return checkAcl(acl, 'agreement:box:update:locked');
  }

  return checkAcl(acl, 'agreement:box:update:open');
}

export function useDocumentPermissionHooks(agreementId: number) {
  const agreement = useAgreement(agreementId);
  const isAgreementConcluded = isConcluded(agreement);

  const permissionHooks = {} as PermissionHooks;

  // Box level
  function useCanAlterBoxComposition(boxId: number) {
    const box = useCurrentBox(boxId);
    const acl = get(agreement, 'acl');

    if (isAgreementConcluded || !box || !acl) {
      return false;
    }

    return canAlterBoxComposition(box?.config, acl);
  }

  // Individual box item level
  // TODO: This can be used for overlay fields too
  // Need little tweaks with right usage of ACLs based on data type, field types
  // We may need to pass entity/entity name and acl as two params
  // Default entity for ACLs is agreement now
  function useCanEditBoxDataItemField(
    boxId: number,
  ) {
    const guestToken = useSelector(getGuestToken);
    const box = useCurrentBox(boxId);
    const getIsAllowedToUpdateDataValue = useCallback((data: any) => (
      isBoxDataSharedValueUpdateAllowed(box, data)
    ), [box]);

    const isGuestView = Boolean(guestToken);
    const currentUser = usePreviousUntilValuesChanged(useSelector(getPositionFromSessionSelector));

    const canEditBoxDataItemField = useCallback((data?: any) => {
      const acl = get(agreement, 'acl');

      if (!box?.config || !acl) {
        return false;
      }

      const isTemplateDocument = isTemplate(agreement);

      if (isTemplateDocument) {
        return canAlterBoxComposition(box?.config, acl);
      }

      const isAllowedToUpdateDataValue = getIsAllowedToUpdateDataValue(data);
      if (box?.type === BOX_FORM && !isAllowedToUpdateDataValue) {
        return false;
      }

      // TODO: To be deleted when ACLs updated in the backend
      // Temporary client code starts
      const isLimitedUser = currentUser.userRole === userConstants.USER_ROLE_LIMITED;

      if (isLimitedUser) {
        return false;
      }

      const currentUserMayBeAsParticipant = getAgreementMyParticipant(agreement);

      if (!currentUserMayBeAsParticipant) {
        return false;
      }
      // Temporary client code ends

      // Edit Box permission is allowed gives edit field permission
      if (canAlterBoxComposition(box?.config, acl)) {
        return true;
      }

      //  When edit Box permission is not allowed we rely on the box config
      return Boolean(box.config.colleagueEdit); /* && checkAcl(
        acl, aclKeys
      ); */
    }, [
      box?.config,
      box?.type,
      currentUser.userRole,
      getIsAllowedToUpdateDataValue,
    ]);

    if (isAgreementConcluded) {
      return ignoreEverythingAlwaysReturnFalse;
    }

    if (isGuestView && box?.type === BOX_FORM) {
      return (data: any, isReadOnly?: boolean) => {
        const isAllowedToUpdateDataValue = getIsAllowedToUpdateDataValue(data);
        return canCounterpartEditBoxDataItemField({
          agreement,
          box,
          isReadOnly,
          isAllowedToUpdateDataValue,
        });
      };
    }

    if (isGuestView) {
      return (isReadOnly?: boolean) => (
        canCounterpartEditBoxDataItemField({ agreement, box, isReadOnly })
      );
    }

    return canEditBoxDataItemField;
  }

  permissionHooks.useCanAlterBoxComposition = useCanAlterBoxComposition;
  permissionHooks.useCanEditBoxDataItemField = useCanEditBoxDataItemField;

  return permissionHooks;
}

// For component uses Provider and/or
// Has access to params, agreementId and boxId (say, BoxItem)
export function useGetBoxPermissions(agreementId: number, boxId: number) {
  // This eventually could become ACL only checks
  const { useCanAlterBoxComposition } = useDocumentPermissionHooks(agreementId);
  const box = useCurrentBox(boxId);
  const canAlterBox = useCanAlterBoxComposition(boxId);
  const canAlterBoxConfig = isBoxConfigUpdateAllowed(box);

  return useMemo(() => ({
    update: canAlterBox,
    updateConfig: canAlterBoxConfig,
  }), [canAlterBox, canAlterBoxConfig]);
}

type PermissionsObject = {
  update?: boolean,
}

// When /contract view is completely gone instead of empty object
// null could be used
const BoxPermissions = createContext<PermissionsObject>({});

type ProviderProps = {
  children: React.ReactNode,
  agreementId: number,
  boxId: number,
}

export const BoxPermissionsProvider = (props: ProviderProps) => {
  const {
    children,
    agreementId,
    boxId,
  } = props;
  const permissions = useGetBoxPermissions(agreementId, boxId);

  return (
    <BoxPermissions.Provider
      value={permissions}
    >
      {children}
    </BoxPermissions.Provider>
  );
};

// For any descendant components of Provider (say, ProductRow, FormField, OverlayField)
export function useBoxPermissions() {
  const boxPermissions = useContext(BoxPermissions);

  if (!boxPermissions) {
    throw new Error('Component is not the descendant of BoxPermissionsProvider');
  }

  return boxPermissions;
}
