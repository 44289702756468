import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import useAgreement from 'hooks/use-agreement';
import { getPDFContractURL } from 'utils/download-pdf-link';
import { getHelpCenterBaseUrl } from 'utils/help-center';
import agreementsReducer from 'reducers/entities/agreements';
import { getPositionFromSessionSelector } from 'reducers/session';
import { getAgreementMyParticipant } from 'agreement/selectors';
import { getCurrentTokenSelector } from 'reducers/app';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';

import { checkAcl } from 'components/acl';
import { ScrollableArea } from 'components/document-tabs/components/scrollable-area';
import { TabHeader } from 'components/document-tabs/components/tab-header';
import Message from 'components/message';
import Toggle from 'components/toggle';
import Button from 'components/button';
import TurnOffNotifications from 'components/modals/turn-off-notifications';
import CloseSidebarButton from 'components/expanded-layout-sidebar/close-sidebar-button';
import Tooltip from 'components/tooltip';

import DocumentActions from './document-actions';
import style from './more-options-tab.module.scss';

const termsOfUseUrl = 'https://oneflow.com/terms-of-use/';

type Props = {
  agreementId: Oneflow.Agreement['id']
  onClose: () => void
}

const MoreOptionsTab = ({ agreementId, onClose }: Props) => {
  const helpCenterUrl = `${getHelpCenterBaseUrl()}support/home`;
  const agreement = useAgreement(agreementId);
  const position = useSelector(getPositionFromSessionSelector);
  const participant = getAgreementMyParticipant(agreement);
  const participantId = participant?.id;
  const isNotificationsChecked = participant?.notifications;
  const guestToken = useSelector(getCurrentTokenSelector);
  const pdfContractURL = getPDFContractURL(agreement, position, guestToken);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isInPreviewMode = useIsInPreviewMode();

  const canDownloadPdf = checkAcl(agreement.acl, 'agreement:download:pdf');

  const onNotificationsSubmit = () => {
    dispatch(agreementsReducer.toggleNotificationsGuest({
      id: participantId,
      data: {
        agreementId,
        participantId,
        notifications: !isNotificationsChecked,
        token: guestToken,
      },
    }));
  };

  const toggleNotifications = () => {
    if (isNotificationsChecked) {
      setIsModalOpen(true);
    } else {
      onNotificationsSubmit();
    }
  };

  const renderNotificationsToggle = () => {
    const notificationsToggle = (
      <Toggle
        disabled={isInPreviewMode}
        checked={isNotificationsChecked}
        onChange={toggleNotifications}
      />
    );

    if (isInPreviewMode) {
      return (
        <Tooltip
          data-testid="notifications-tooltip"
          message={<Message id="Not available in preview" comment="Tooltip title for the notifications" />}
          side="top"
          sideOffset={4}
        >
          {notificationsToggle}
        </Tooltip>
      );
    }
    return notificationsToggle;
  };

  const renderDocumentActions = () => {
    const actions = (
      <div className={clsx({ [style.DisabledDocumentActions]: isInPreviewMode })}>
        <h3 className={style.SubHeading}>
          <Message
            id="Document actions"
            comment="Sub heading for settings tabs for counterparties in the document view."
          />
        </h3>
        <DocumentActions />
        <Button
          data-testid="download-pdf-button"
          kind="wide"
          color="grey"
          href={pdfContractURL}
          download
          disabled={!canDownloadPdf || isInPreviewMode}
        >
          <Message
            id="Download PDF"
            comment="Button text for counterparties who want to download their document in pdf form."
          />
        </Button>
      </div>
    );

    if (isInPreviewMode) {
      return (
        <Tooltip
          data-testid="document-actions-tooltip"
          message={<Message id="Not available in preview" comment="Tooltip title for the actions" />}
          side="top"
        >
          {actions}
        </Tooltip>
      );
    }

    return actions;
  };

  return (
    <>
      <TurnOffNotifications
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmit={onNotificationsSubmit}
        participantId={participantId}
      />
      <TabHeader>
        <h2 className={style.Header}>
          <Message
            id="More options"
            comment="Sub heading for settings tabs for counterparties in the document view."
          />
        </h2>
        <CloseSidebarButton onClose={onClose} />
      </TabHeader>
      <ScrollableArea>
        <h3 className={style.SubHeading}>
          <Message
            id="Notifications"
            comment="Sub heading for settings tabs for counterparties in the document view."
          />
        </h3>
        <div className={style.NotificationsContainer}>
          <p className={style.Notifications}>
            <Message
              id="I want to receive notifications"
              comment="Explanatory text next to a toggle switch in the document view."
            />
          </p>
          {renderNotificationsToggle()}
        </div>
        {renderDocumentActions()}
        <div className={style.Links}>
          <Button
            kind="linkInline"
            href={helpCenterUrl}
            customClass={style.ButtonPadding}
            target="_blank"
            rel="noopener"
            external
          >
            <Message
              id="Help center"
              comment="Used as the text of help center button."
            />
          </Button>
          <Button
            kind="linkInline"
            href={termsOfUseUrl}
            target="_blank"
            rel="noopener"
            external
          >
            <Message
              id="Terms of use"
              comment="Link to our terms of use page."
            />
          </Button>
        </div>
      </ScrollableArea>
    </>
  );
};

export default MoreOptionsTab;
