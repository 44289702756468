/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  forwardRef,
} from 'react';
import Message from 'components/message';
import clsx from 'clsx';

import { useDispatch } from 'react-redux';

import log from 'logging';

import agreements from 'reducers/entities/agreements';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';

import {
  getMoment,
} from 'date';
import useDocumentViewLayoutInfo from 'hooks/use-document-view-layout-info';
import usePopupDialog from 'hooks/popup/use-popup-dialog';
import useSetDOMAttributes from 'hooks/use-set-dom-attributes';
import Button from 'components/button';
import SelectField from 'components/select-field';
import PencilIcon from 'components/icons/pencil';
import { CancelButton } from 'components/buttons/cancel';

import { getDaysCount, getValidUntilDate } from './helpers/transforms';
import { getSelectedExpiryTypeOption } from './helpers/builders';

import ExpiryDatePicker from './expiry-date-picker';

import style from './unpublished-document-expiry-popover.module.scss';

const FALLBACK_EXPIRY_DAYS_COUNT = 14;

const buttonDatasets = {
  testid: 'unpublishedExpiryDateSaveButton',
};

const popoverContentProps = {
  align: 'start',
};

function UnpublishedDocumentExpiryPopover(props) {
  const {
    expireDate,
    dateFormat,
    expireDateDays,
    expiryOptions,
    agreementId,
    readOnly,
    failureToast,
    setFailureReason,
    placeholder,
  } = props;

  const dispatch = useDispatch();
  const { columnsCount } = useDocumentViewLayoutInfo();
  const isTwoColumnLayout = columnsCount === 2;
  const popupType = isTwoColumnLayout ? 'popover' : 'dialog';
  const { resetRPCStates, updateExpiryDateState } = useAPIError(agreementId);

  const { Popup, PopupContent } = usePopupDialog(popupType);
  const today = useMemo(() => getMoment(), []);
  const formRef = useRef(null);
  const closeButtonRef = useRef(null);
  const expiryDateDaysInputRef = useRef(null);
  const saveButtonInternalRef = useRef(null);
  // DOM attributes could be passed to Button
  // And it's restProps pass it to DOM node
  // We sometime pass restProps in nested React Components
  // Hence the hook
  const saveButtonRef = useSetDOMAttributes({
    ref: saveButtonInternalRef,
    datasets: buttonDatasets,
    refName: 'publishedExpiryDateSaveButton',
  });
  const initialExpiryTypeOption = getSelectedExpiryTypeOption({
    expireDate,
    expireDateDays,
    expiryOptions,
  });

  const getExpireDateDaysValue = () => {
    if (initialExpiryTypeOption.value === 'days') {
      return expireDateDays || FALLBACK_EXPIRY_DAYS_COUNT;
    }
    return FALLBACK_EXPIRY_DAYS_COUNT;
  };
  // Until this popupDialog (which updates expireDate or expireDateDays) is closed
  // it is not likely that initialExpiryTypeOption changed by parent (by passed props),
  // thus no useEffect sync.
  // However expiryOptions could be changed by acl changes and no need to sync the UI (dropdown)
  const [selectedExpiryTypeOption, setSelectedExpiryTypeOption] = useState(
    () => initialExpiryTypeOption,
  );

  const [expireDateValue, setExpireDateValue] = useState(
    () => getMoment(expireDate),
  );

  const [formValidationState, setFormValidationState] = useState('pristine');
  const formInvalid = formValidationState === 'invalidRange' || formValidationState === 'valueMissing';
  const expiryDateDaysInvalid = formInvalid && selectedExpiryTypeOption.value === 'days';
  // There is no validation for fixed date (in date picker) in legacy and in API.
  // The no_expiry type don't need validation as there is no user input to be send to the server
  // And it is pre-defined in code
  const submitDisabled = expiryDateDaysInvalid || updateExpiryDateState.loading;

  useEffect(() => {
    if (selectedExpiryTypeOption.value === 'days') {
      expiryDateDaysInputRef.current?.focus();
    }
  }, [selectedExpiryTypeOption.value]);

  const reset = () => {
    setExpireDateValue(getMoment(expireDate));
    setSelectedExpiryTypeOption(initialExpiryTypeOption);
  };
  // It will also reset local state
  const triggerClose = () => closeButtonRef.current?.click();

  const updateExpireDate = useCallback((bodyData) => {
    resetRPCStates();

    dispatch(agreements.updateExpiryDate({
      id: agreementId,
      data: {
        expireDate: bodyData.expireDate,
      },
      pipe: {
        onSuccess: () => {
          // Click Popover close button programmatically
          closeButtonRef.current?.click();
        },
        onFailure: (reason) => {
          // TO-DO: Toast needed to be replaced here OF-13200
          setFailureReason(reason);
          // Hide it after 10 seconds
          setTimeout(() => {
            setFailureReason('');
          }, 10000);
          log.error(reason, { errorContext: 'Attempt to update signing period as fixed date in contract view' });

          // Click Popover close button programmatically
          closeButtonRef.current?.click();
        },
      },
    }));
  }, [
    agreementId,
    dispatch,
    resetRPCStates,
    setFailureReason,
  ]);

  const updateExpireDateDays = useCallback((bodyData) => {
    resetRPCStates();

    dispatch(agreements.updateExpiryDate({
      id: agreementId,
      data: {
        expireDate: null,
        expireDateDays: Number(bodyData.expireDateDays),
      },
      pipe: {
        onSuccess: () => {
          setExpireDateValue(today);

          // Click Popover close button programmatically
          triggerClose();
        },
        onFailure: (reason) => {
          // TO-DO: Toast needed to be replaced here OF-13200
          setFailureReason(reason);
          // Hide it after 10 seconds
          setTimeout(() => {
            setFailureReason('');
          }, 10000);
          // where you need to log your error
          log.error(reason, { errorContext: 'Attempt to update signing period as days in contract view' });

          // Click Popover close button programmatically
          triggerClose();
        },
      },
    }));
  }, [
    agreementId,
    dispatch,
    setFailureReason,
    today,
    resetRPCStates,
  ]);

  const saveExpiryDate = useCallback((event) => {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      return;
    }

    const bodyData = Object.fromEntries(new FormData(event.target));

    if (selectedExpiryTypeOption.value === 'fixed') {
      updateExpireDate(bodyData);
      return;
    }

    if (selectedExpiryTypeOption.value === 'days') {
      updateExpireDateDays(bodyData);
      return;
    }

    // No expiry
    updateExpireDateDays({ expireDateDays: 0 });
  }, [selectedExpiryTypeOption.value, updateExpireDate, updateExpireDateDays]);

  const renderValidation = () => {
    if (formInvalid && selectedExpiryTypeOption?.value === 'days') {
      return (
        <div className={style.Validation}>
          <Message
            id="The number must be between 1 and 36500"
            comment="validation message when given input value is out of range"
          />
        </div>
      );
    }

    return null;
  };

  if (readOnly) {
    return (
      <ExpiryInfo
        expireDate={expireDate}
        dateFormat={dateFormat}
        expireDateDays={expireDateDays}
        readOnly={readOnly}
        placeholder={placeholder}
      />
    );
  }

  return (
    <Popup.Root>
      {failureToast}
      <Popup.Trigger asChild>
        <ExpiryInfo.AsTrigger
          expireDate={expireDate}
          dateFormat={dateFormat}
          expireDateDays={expireDateDays}
          placeholder={placeholder}
        />
      </Popup.Trigger>
      <PopupContent
        popoverContentProps={popoverContentProps}
        onKeyDown={(event) => {
          // Handle When user click outside the form area and press enter
          if (event.key !== 'Enter' || formRef.current?.contains(event.target)) {
            return;
          }

          formRef.current?.requestSubmit(saveButtonInternalRef.current);
        }}
        onEscapeKeyDown={triggerClose}
        onInteractOutside={triggerClose}
      >
        <div>
          <div className={style.PopupHeader}>
            <Message
              id="Signing period"
              comment="Expiry date popover header"
            />
          </div>
          <form
            className={style.SigningPeriodForm}
            ref={formRef}
            onSubmit={saveExpiryDate}
            noValidate
          >
            <div className={style.ExpiryContainer}>
              <fieldset>
                <div className={style.ExpiryTypeContainer}>
                  <input
                    ref={expiryDateDaysInputRef}
                    name="expireDateDays"
                    type="number"
                    min={1}
                    max={36500}
                    required
                    key={selectedExpiryTypeOption?.value === 'days'}
                    defaultValue={getExpireDateDaysValue()}
                    hidden={selectedExpiryTypeOption?.value !== 'days'}
                    onInput={(event) => {
                      const validityState = event.target.validity;
                      // Add/handle validation state whenever a new validation is defined in input
                      if (validityState.rangeOverflow || validityState.rangeUnderflow) {
                        setFormValidationState('invalidRange');
                        return;
                      }
                      if (validityState.valueMissing) {
                        setFormValidationState('valueMissing');
                        return;
                      }
                      // Refer: https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation#the_constraint_validation_api
                      // When native event.target.setCustomValidity is used use empty string ('')
                      // For validation success. Better avoid custom validation message since
                      // It needs to be translated thus use validityState instead
                      setFormValidationState('valid');
                    }}
                  />
                  <SelectField
                    options={expiryOptions}
                    input={{
                      name: 'expiryType',
                      value: selectedExpiryTypeOption?.value,
                      onChange: setSelectedExpiryTypeOption,
                    }}
                    menuPosition="absolute"
                  />
                </div>
                {renderValidation()}
                {selectedExpiryTypeOption?.value === 'fixed' && (
                  <ExpiryDatePicker
                    id="datePickerDialog"
                    name="expireDate"
                    expireDateValue={expireDateValue}
                    setExpireDateValue={(date) => {
                      setExpireDateValue(date);
                      // For yet to be known reason the focus is set back to body element
                      // After brief focus at selected date when clicking.
                      if (saveButtonInternalRef.current) {
                        saveButtonInternalRef.current?.focus();
                      }
                    }}
                    onAfterFocus={(event) => {
                      if (event.relatedTarget?.type === 'submit' && saveButtonInternalRef.current) {
                        saveButtonInternalRef.current.focus();
                      }
                    }}
                  />
                )}
              </fieldset>
              <div className={style.ActionButtonsContainer}>
                <Popup.Close asChild>
                  <CancelButton onClick={reset} />
                </Popup.Close>
                <Button
                  buttonRef={saveButtonRef}
                  type="submit"
                  kind="primary"
                  disabled={submitDisabled}
                >
                  <Message id="Save" comment="Button text for save button" />
                </Button>
                <Popup.Close asChild>
                  {/* No translation needed and not part of accessibility */}
                  <Button
                    hidden
                    customClass={style.HiddenButton}
                    buttonRef={closeButtonRef}
                    type="button"
                    onClick={reset}
                  >
                    Programmatic hidden close button
                  </Button>
                </Popup.Close>
              </div>
            </div>
          </form>
        </div>
      </PopupContent>
    </Popup.Root>
  );
}

export const ExpiryInfo = (props) => {
  const {
    expireDate,
    dateFormat,
    expireDateDays,
    readOnly,
    triggerRef,
    placeholder,
    ...restProps
  } = props;
  // Derived states
  const daysCount = getDaysCount({ expireDate, expireDateDays });
  const validUntil = placeholder ?? getValidUntilDate({ expireDate, expireDateDays, dateFormat });

  let expiryInfo = (
    <Message
      id="The document can be signed at any time"
      comment="Text explains that the signing period doesn't expire"
    />
  );

  let remainingDays = (
    <Message
      id="No expiration"
      comment="Text explains how many days left to sign the document once it is sent"
    />
  );

  // When expireDate is today daysCount is 0
  if (daysCount > 0 || expireDate) {
    remainingDays = (
      <Message
        id="{daysCountComponent} day after sending"
        pluralId="{daysCountComponent} days after sending"
        pluralCondition="daysCount"
        comment="Text explains how many days left to sign the document once it is sent"
        values={{
          daysCountComponent: (
            <div className={style.DaysCount}>
              {daysCount}
            </div>
          ),
          daysCount,
        }}
      />
    );
  }

  if (validUntil) {
    expiryInfo = (
      <Message
        id="The document can be signed until {date}"
        values={{
          date: (
            <span
              className={clsx(style.Bold, {
                [style.DatePassed]: daysCount < 0,
              })}
            >
              {validUntil}
            </span>
          ),
        }}
        comment="Shows the number of days signing is possible"
      />
    );
  }

  const getRoleProps = (dataTestId) => {
    if (readOnly) {
      return {
        'data-testid': 'read-only-expiry-info',
      };
    }

    return {
      'data-testid': dataTestId,
      role: 'button',
      ref: triggerRef,
    };
  };

  const renderRemainingDays = () => {
    if (readOnly || daysCount < 0) {
      return null;
    }

    return (
      <div className={style.RemainingDays} data-testid="remaining-days">
        <div>
          {remainingDays}
        </div>
      </div>
    );
  };

  return (
    <div
      className={clsx(
        style.SigningPeriodContainer,
        { [style.disabled]: readOnly },
      )}
      {...getRoleProps('expiryInfoTrigger')}
      {...restProps}
    >
      <h3
        className={style.SigningPeriodHeader}
      >
        <Message id="Signing Period" comment="header text for signing period info" />
      </h3>
      {renderRemainingDays()}
      <div
        className={style.ExpiryInfo}
      >
        {expiryInfo}
      </div>
      {!readOnly && <PencilIcon className={style.EditIcon} height="16px" width="16px" />}
    </div>
  );
};

ExpiryInfo.AsTrigger = forwardRef((props, ref) => (
  <ExpiryInfo {...props} readOnly={false} triggerRef={ref} />
));

export default UnpublishedDocumentExpiryPopover;
