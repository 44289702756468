/* eslint-disable react/no-array-index-key */
// @flow

import * as React from 'react';
import { Text } from 'slate';
import map from 'lodash/map';

import { elementTypes, ELEMENT_MAPPERS } from 'components/rich-text-editor/element';
import Leaf from 'components/rich-text-editor/leaf';

const elementChildMapper = (child, index) => {
  if (Text.isText(child)) {
    return (
      <Leaf leaf={child} key={index}>
        {child.text}
      </Leaf>
    );
  }

  return (
    <ElementViewer element={child} key={index}>
      {child.children}
    </ElementViewer>
  );
};

type ChildType = {[key: string]: any};

const ElementViewer = (props: RenderSlateElementProps) => {
  const { element } = props;
  const elementMapper = ELEMENT_MAPPERS[element.type] || ELEMENT_MAPPERS[elementTypes.PARAGRAPH];

  return elementMapper({
    ...props,
    children: map<ChildType, React.Node>(props.children, elementChildMapper),
  });
};

export default ElementViewer;
