import React from 'react';
import ReactModal from 'react-modal';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import clsx from 'clsx';
import type { ComponentProps, ReactElement, ReactNode } from 'react';

import { Message } from '@oneflowab/pomes';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

import WithOnEnter from 'hocs/with-on-enter';
import Button from 'components/button';
import Error from 'components/icons/error';
import CheckCircle from 'components/icons/check-circle';
import CircularSpinner from 'components/icons/circular-spinner';
import Cross from 'components/icons/cross';
import {
  ApiError, getErrorMessage, getErrorCode,
} from 'components/api-error';
import type { ErrorMessage } from 'components/error-box';

import { MultistepModalContext } from './multistep-modal';
import type { Props as ModalProps } from './multistep-modal';
import style from './modal.module.scss';

const CLOSE_BUTTON_ACTION = 'click cross';
const CLICK_KEYBOARD_ESCAPE = 'click keyboard-escape';

const BodySections = ({ children }: { children: ReactNode }) => (
  children
);

const BodySection = ({ children }: { children: ReactNode }) => (
  children
);

type GetFilteredSectionsProps = {
  sections: ReactElement[],
  isLoading: boolean,
};

const getFilteredSections = ({ sections, isLoading }: GetFilteredSectionsProps) => (
  sections.filter((section) => {
    if (!section) {
      return false;
    }
    const { showWhenLoading } = section.props;
    return section.type === BodySection && (!isLoading || showWhenLoading);
  })
);

const getLoadingMessage = () => (
  <div className={`${style.LoadingContent} modal-loading-content`} key="loading-message">
    <CircularSpinner />
    <div className={style.LoadingText}>
      <Message id="Loading" comment="The loading message" />
    </div>
  </div>
);

const Body = ({ children, isLoading }: { children: ReactElement, isLoading: boolean }) => {
  const allChildren = [];
  if (children.type === BodySections) {
    const sections = children.props.children;
    const filteredSections = getFilteredSections({ sections, isLoading });
    allChildren.push(filteredSections);
  } else if (!isLoading) {
    return children;
  }

  if (isLoading) {
    allChildren.push(getLoadingMessage());
  }

  return allChildren;
};

const Actions = ({ children }: { children: ReactNode }) => children;

export type Props = Omit<ModalProps, 'children'> & {
  children?: ReactNode,
  header: ReactNode,
  // TODO: fix this type
  error?: any,
  customErrorMessage?: ErrorMessage,
  success?: boolean,
  onEnter?: ComponentProps<typeof WithOnEnter>['onEnter'],
  onCancel?: () => void,
  isWideModal?: boolean,
  isOpen?: boolean,
  preventClose?: boolean,
  footer?: ReactNode | (() => ReactNode),
  hideFooter?: boolean,
  modalKey: string,
  customModalClass?: string,
  customBodyClass?: string,
  centeredActions?: boolean,
  shouldRenderStaticScrollbar?: boolean,
  shouldReturnFocusAfterClose?: boolean,
  showSuccessMessage?: boolean,
  actionClasses?: string,
};

class Modal extends React.Component<Props> {
  static defaultProps = {
    onEnter: undefined,
    onCancel: undefined,
    isWideModal: undefined,
    isOpen: undefined,
    children: undefined,
    error: undefined,
    success: undefined,
    customErrorMessage: undefined,
    preventClose: false,
    customModalClass: undefined,
    customBodyClass: undefined,
    shouldRenderStaticScrollbar: false,
    showSuccessMessage: true,
    actionClasses: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }

    if (!prevProps.isOpen && this.props.isOpen) {
      window.addEventListener('keydown', this.closeOnEsc);
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  getChildren({ type }) {
    return React.Children.toArray(this.props.children).filter((child) => child.type === type);
  }

  getApiError = () => {
    const { error } = this.props;
    const errorCode = get(error, 'body.api_error_code');

    return getErrorMessage(errorCode);
  };

  onExitModal = (exitType?: string) => {
    const { onCancel, modalKey } = this.props;
    onCancel?.();

    amplitudeLogEvent(
      'Exit Modal',
      {
        location: modalKey,
        'exit action': exitType || 'click outside',
      },
    );
  };

  handleCancel: ModalProps['onRequestClose'] = () => {
    this.onExitModal();
  };

  closeOnEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.onExitModal(CLICK_KEYBOARD_ESCAPE);
      window.removeEventListener('keydown', this.closeOnEsc);
    }
  }

  renderActions() {
    const { success } = this.props;

    if (success) {
      return null;
    }

    return this.getChildren({ type: Actions });
  }

  renderError() {
    const { error, customErrorMessage } = this.props;

    if (!error) {
      return null;
    }

    const errorCode = getErrorCode(error);
    if (errorCode) {
      return <ApiError errorCode={errorCode} />;
    }

    if (customErrorMessage) {
      return (
        <div className={style.Error}>
          <ApiError customMessage={customErrorMessage} />
        </div>
      );
    }

    const apiError = this.getApiError();
    if (apiError) {
      return (
        <div className={style.Error}>
          <ApiError customMessage={apiError} />
        </div>
      );
    }

    return (
      <div className={style.Error}>
        <span className={style.ErrorMessage}>
          <Error className={style.ErrorIcon} />
          <Message
            id="An error has occurred. Please try again."
            comment="Used to show the error message when in confirmation dialog the main action fails."
          />
        </span>
      </div>
    );
  }

  renderSuccessMessage() {
    const { success, showSuccessMessage } = this.props;

    if (!success || !showSuccessMessage) {
      return null;
    }

    return (
      <div className={style.SuccessMessage}>
        <CheckCircle className={style.Icon} height="40px" />
        <Message
          id="Success!"
          comment="Confirmation message displayed in modal after making a successful change."
        />
      </div>
    );
  }

  renderFooter() {
    const {
      customErrorMessage,
      hideFooter,
      footer,
      centeredActions,
      actionClasses,
    } = this.props;
    const apiError = this.getApiError();
    const footerClasses = clsx(style.Footer, {
      [style.CustomError]: apiError || customErrorMessage,
    });
    const modalActionsClasses = clsx(style.Actions, actionClasses, {
      [style.CustomError]: apiError || customErrorMessage,
      [style.CenteredActions]: centeredActions,
    });

    if (hideFooter) {
      return undefined;
    }

    if (isFunction(footer)) {
      return footer();
    }

    return (
      <div className={footerClasses}>
        {this.renderError()}
        <div className={modalActionsClasses}>
          {this.renderActions()}
        </div>
        {this.renderSuccessMessage()}
      </div>
    );
  }

  renderCloseButton = () => {
    const { preventClose } = this.props;

    if (preventClose) {
      return <div className={style.CloseButtonContainer} />;
    }

    return (
      <div className={style.CloseButtonContainer}>
        <Button
          customClass={style.CloseButton}
          icon={Cross}
          onClick={() => { this.onExitModal(CLOSE_BUTTON_ACTION); }}
        />
      </div>
    );
  }

  renderHeader() {
    const { header } = this.props;

    if (isFunction(header)) {
      return header();
    }

    return (
      <div className={style.Header}>
        <div />
        {header}
        {this.renderCloseButton()}
      </div>
    );
  }

  renderModalContent() {
    const { onEnter, customBodyClass } = this.props;

    return (
      <WithOnEnter onEnter={onEnter}>
        {this.renderHeader()}
        <div className={clsx(style.Body, customBodyClass)}>
          {this.getChildren({ type: Body })}
        </div>
        {this.renderFooter()}
      </WithOnEnter>
    );
  }

  renderModal() {
    const {
      header,
      children,
      onCancel,
      isWideModal,
      customModalClass,
      customBodyClass,
      shouldRenderStaticScrollbar,
      ...modalProps
    } = this.props;

    const modalClasses = clsx(style.Modal, {
      [style.IsWide]: isWideModal,
      [style.ScrollBar]: shouldRenderStaticScrollbar,
    }, customModalClass);

    return (
      <ReactModal
        className={modalClasses}
        overlayClassName={style.ModalOverlay}
        ariaHideApp={false}
        {...modalProps}
        onRequestClose={this.handleCancel}
        bodyOpenClassName={style.Open}
      >
        {this.renderModalContent()}
      </ReactModal>
    );
  }

  render() {
    return (
      <MultistepModalContext.Consumer>
        {({ loaded }) => {
          if (loaded) {
            return this.renderModalContent();
          }
          return this.renderModal();
        }}
      </MultistepModalContext.Consumer>
    );
  }
}

Modal.Body = Body;
Modal.BodySections = BodySections;
Modal.BodySection = BodySection;
Modal.Actions = Actions;

export default Modal;
