// @flow

import * as React from 'react';
import { useForm } from 'react-final-form';

import AIAssistFieldButton from 'components/rich-text-editor-toolbars/toolbar-buttons/ai-assist-field-button';
import {
  MessageTemplateNameField,
  MessageTemplateTypeField,
  MessageTemplateSubjectField,
  MessageTemplateBodyField,
} from 'components/fields';

import style from './add-message-template.module.scss';

const AddMessageTemplateBody = () => {
  const { change: formValueChange } = useForm();

  return (
    <fieldset className={style.NoBorder}>
      <MessageTemplateNameField autoFocus />
      <MessageTemplateTypeField />
      <MessageTemplateSubjectField />
      <div className={style.AIAssistWrapper}>
        <div className={style.AIAssistContainer}>
          <AIAssistFieldButton
            context="messageTemplate"
            onInsertGeneratedText={(text) => formValueChange('body', text)}
          />
        </div>
        <MessageTemplateBodyField />
      </div>
    </fieldset>
  );
};

export default AddMessageTemplateBody;
