import { forwardRef, useRef } from 'react';
import { composeRefs } from 'hooks/compose-refs';

import type { DivProps } from './types';
import useFilterableState from './hooks/use-filterable-state';

type Props = DivProps & {
  /** Whether this separator should always be rendered.
   * Useful if you disable automatic filtering.
   */
  alwaysRender?: boolean
}

/**
 * A visual and semantic separator between items or groups.
 * Visible when the search query is empty or `alwaysRender` is true, hidden otherwise.
 */
const Separator = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const { alwaysRender, ...etc } = props;
  const ref = useRef<HTMLDivElement>(null);
  const render = useFilterableState((state) => !state.search);

  if (!alwaysRender && !render) return null;
  // eslint-disable-next-line react/no-unknown-property
  return <div ref={composeRefs(ref, forwardedRef)} {...etc} cmdk-separator="" role="separator" />;
});

Separator.displayName = 'Separator';

export default Separator;
