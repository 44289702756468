// @flow

import React from 'react';
import clsx from 'clsx';

import PaginationInformation from 'components/pagination-information';
import Button from 'components/button';
import Conditional from 'components/conditional';
import NewArrowLeft from 'components/icons/new-arrow-left';
import NewArrowRight from 'components/icons/new-arrow-right';
import {
  getTotalPages,
  getPageNumberForOffset,
  getOffsetForPage,
} from './helpers';
import style from './pagination.module.scss';

type Props = {
  totalItems: number,
  itemsPerPage?: number,
  currentOffset?: number,
  buttonCount?: number,
  onChange: Object => void,
  entityName?: string,
  renderWithoutPages?: boolean,
};

export default class Pagination extends React.Component<Props> {
  static defaultProps = {
    buttonCount: 7,
    itemsPerPage: 10,
    currentOffset: 0,
    entityName: '',
  };

  static getButton(key) {
    return (
      <Button
        key={key}
        kind="round"
        disabled
      >
        ...
      </Button>
    );
  }

  getCurrentPage() {
    return this.getPageNumberForOffset(this.props.currentOffset);
  }

  /** Returns the number of page items to display */
  getNumberOfPagesToDisplay() {
    return (Math.min(this.props.buttonCount, this.getTotalPages()));
  }

  /** Returns the total number of pages */
  getTotalPages() {
    return getTotalPages(this.props.totalItems, this.props.itemsPerPage);
  }

  /** Returns the page number for the specified offset */
  getPageNumberForOffset(offset) {
    return getPageNumberForOffset(offset, this.props.itemsPerPage);
  }

  /** Returns the offset for the specified page */
  getOffsetForPage(page) {
    return getOffsetForPage(page, this.props.itemsPerPage);
  }

  /** Returns the first page to render, not counting with the absolute first page and
   * the left side ...
   */
  getStartPage() {
    let startPage = this.getCurrentPage() - Math.floor(this.getNumberOfPagesToDisplay() * 0.5);
    if (startPage < 1) {
      startPage = 1;
    }

    if (startPage > (this.getTotalPages() - this.getNumberOfPagesToDisplay()) + 1) {
      startPage = (this.getTotalPages() - this.getNumberOfPagesToDisplay()) + 1;
    }

    return startPage;
  }

  /** Returns all the page items needed for the pagination, including any spacing items (...),
   * but excluding back and forward navigation
   */
  getPagesToRender() {
    const items = [];
    for (let i = 0; i < this.getNumberOfPagesToDisplay(); i += 1) {
      let pageNumber = this.getStartPage() + i;
      if (i === 0) {
        pageNumber = 1;
      } else if (i === this.getNumberOfPagesToDisplay() - 1) {
        pageNumber = this.getTotalPages();
      }
      items.push(this.getItemToRenderAtPosition(pageNumber));
    }

    if (this.shouldDisplayRightSpace()) {
      items[1] = Pagination.getButton('page_prev');
    }
    if (this.shouldDisplayLeftSpace()) {
      items[this.getNumberOfPagesToDisplay() - 2] = Pagination.getButton('page_next');
    }

    return items;
  }

  /** Calculates the last item being displayed for the current page  */
  getLastItemOnPage() {
    return Math.min((this.getCurrentPage() * this.props.itemsPerPage), this.props.totalItems);
  }

  /** Calculates the first item being displayed for the current page  */
  getFirstItemOnPage() {
    return ((this.getCurrentPage() - 1) * this.props.itemsPerPage) + 1;
  }

  /** Gets the configured Button item to be rendered at the specified position  */
  getItemToRenderAtPosition(pageNumber) {
    return (
      <Button
        key={pageNumber}
        onClick={() => this.navigateToPage(pageNumber)}
        selected={(pageNumber === this.getCurrentPage())}
        kind="round"
      >
        {pageNumber.toString()}
      </Button>
    );
  }

  /** Returns true if the left side ... should be displayed, i.e., if there are
   * pages between the first displayed page and the absolute first page
   */
  shouldDisplayLeftSpace() {
    if (this.getNumberOfPagesToDisplay() < 5) {
      return false;
    }

    return this.getCurrentPage()
      < this.getTotalPages() - Math.floor(this.getNumberOfPagesToDisplay() * 0.5);
  }

  /** Returns true if the right side ... should be displayed, i.e., if there are
   * pages between the last displayed page and the absolute last page
   */
  shouldDisplayRightSpace() {
    if (this.getNumberOfPagesToDisplay() < 5) {
      return false;
    }
    return this.getCurrentPage() > Math.ceil(this.getNumberOfPagesToDisplay() * 0.5);
  }

  /** Navigates to the specified page number  */
  navigateToPage(targetPage: number) {
    const { onChange, itemsPerPage } = this.props;
    const targetOffset = this.getOffsetForPage(targetPage);

    onChange({ offset: targetOffset, limit: itemsPerPage });

    window.scrollTo(0, 0);
  }

  /** Renders back button, disabling it in case we are already in the first page  */
  renderArrowButton(forward: boolean) {
    const key = (forward) ? 'forward' : 'back';
    const Icon = (forward) ? NewArrowRight : NewArrowLeft;
    let enabled;
    let targetPage;

    if (forward) {
      enabled = (this.getCurrentPage() < this.getTotalPages());
      targetPage = this.getCurrentPage() + 1;
    } else {
      enabled = !(this.getCurrentPage() === 1);
      targetPage = this.getCurrentPage() - 1;
    }

    return (
      <Button
        key={key}
        data-testid={key}
        disabled={!enabled}
        onClick={() => this.navigateToPage(targetPage)}
        customClass={style.ArrowButton}
        kind="round"
      >
        <Icon width="11" height="10" className={style.ArrowIcon} />
      </Button>
    );
  }

  renderPages() {
    if (this.getTotalPages() > 1) {
      return (
        <div className={style.Buttons}>
          {this.renderArrowButton(false)}
          {this.getPagesToRender()}
          {this.renderArrowButton(true)}
        </div>
      );
    }

    return (<div />);
  }

  render() {
    const { renderWithoutPages } = this.props;
    const paginationClasses = clsx(style.Pagination, {
      [style.WithoutPages]: renderWithoutPages,
    });

    return (
      <div className={paginationClasses}>
        <Conditional ifCondition={!renderWithoutPages}>
          {this.renderPages()}
        </Conditional>
        <PaginationInformation
          start={this.getFirstItemOnPage()}
          end={this.getLastItemOnPage()}
          total={this.props.totalItems}
          itemsPerPage={this.props.itemsPerPage}
          type={this.props.entityName}
        />
      </div>
    );
  }
}
