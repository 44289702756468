/* eslint-disable react/prop-types */
import * as React from 'react';
import { useSlate } from 'slate-react';
import clsx from 'clsx';

import { getColumnIndex } from '../../../editor-plugins/table-utils/block-matrix';
import {
  resizeTableColumnRefs,
  resizeTableColumns,
} from '../../../editor-plugins/table-utils/table-columns';
import useTableElement from '../table/use-table-element';
import useTableRowElement from '../table-row/use-table-row-element';
import tableCellStyle from './table-cell.module.scss';

const TableColumnResizer = ({
  tableCellElement,
}) => {
  const editor = useSlate();
  const {
    tableElement,
    tableDomElement,
    columnCount,
    colgroupRef,
    tableMatrix,
  } = useTableElement();
  const { tableRowElement } = useTableRowElement();
  const columnIndex = getColumnIndex(tableMatrix, tableRowElement, tableCellElement);
  const [isResizing, setIsResizing] = React.useState(false);

  const onResizeMouseDown = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const currentCell = e.target.parentElement;
    const nextCell = currentCell.nextElementSibling;
    if (!currentCell || !nextCell) {
      return;
    }

    const initialPageX = e.pageX;
    const initialCurrentColumnWidth = currentCell.offsetWidth;
    const initialNextColumnWidth = nextCell.offsetWidth;

    tableDomElement.classList.toggle('resizing-table', true);
    setIsResizing(true);

    const onResizeMouseMove = (event) => {
      event.preventDefault();
      event.stopPropagation();

      const diffX = event.pageX - initialPageX;

      resizeTableColumnRefs({
        colgroupRef,
        columnIndex,
        width: initialCurrentColumnWidth + diffX,
        nextColumnWidth: initialNextColumnWidth - diffX,
      });
    };

    const onResizeMouseUp = (event) => {
      event.preventDefault();
      event.stopPropagation();
      tableDomElement.classList.toggle('resizing-table', false);
      setIsResizing(false);

      resizeTableColumns({
        editor,
        tableElement,
        columnIndex,
        colgroupRef,
      });

      document.removeEventListener('mousemove', onResizeMouseMove);
      document.removeEventListener('mouseup', onResizeMouseUp);
    };

    document.addEventListener('mousemove', onResizeMouseMove);
    document.addEventListener('mouseup', onResizeMouseUp);
  }, [
    editor,
    tableElement,
    tableDomElement,
    colgroupRef,
    columnIndex,
  ]);

  if (!tableMatrix
    || !tableRowElement
    || Number(columnCount) - 1 === columnIndex) {
    return null;
  }

  const handlerClassNames = clsx(tableCellStyle.TableCellResizeHandler, {
    [tableCellStyle.IsResizing]: isResizing,
  });

  return (
    <div
      className={handlerClassNames}
      onMouseDown={onResizeMouseDown}
      onMouseUp={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      aria-hidden
      contentEditable={false}
    />
  );
};

export default TableColumnResizer;
