import { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import useAgreement from 'hooks/use-agreement';
import { isContractPristine } from 'reducers/current-contract';
import { AGREEMENT_VIDEO_PROCESSING, AGREEMENT_WITH_VIDEO } from 'agreement/constants';

import SmallAddIcon from 'components/icons/small-add';
import Button from 'components/button';
import getHref from '../helpers';
import style from './add-video.module.scss';

type Props = {
  agreementId: number,
  children?: ReactNode,
}

const AddVideo = ({ agreementId, children }: Props) => {
  const agreement = useAgreement(agreementId);
  const isPristine = useSelector(isContractPristine);

  const href = getHref(isPristine, agreementId.toString());
  const hasVideo = useMemo(() => [
    AGREEMENT_VIDEO_PROCESSING,
    AGREEMENT_WITH_VIDEO,
  ].includes(agreement.welcomeVideo), [agreement.welcomeVideo]);

  return (
    <div
      className={style.AddVideoContainer}
      data-testid="redesigned-add-video-container"
    >
      {children}
      {!hasVideo && (
        <Button
          kind="secondary-lite"
          icon={<SmallAddIcon height="8px" />}
          href={href}
          external
          target="_self"
          rel="noopener"
          disabled={!isPristine}
          customClass={style.AddButton}
        >
          <Message
            id="Add video"
            comment="Button text for selecting a video to upload"
          />
        </Button>
      )}
    </div>
  );
};

export default AddVideo;
