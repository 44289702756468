// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  formatDateString,
  getDaysSince,
} from 'date';

import CalendarAlert from 'components/icons/calendar-alert';

import Insight from './insight';

import style from './insight.module.scss';

type Props = {
  signingPeriodExpiryTime: string | null,
  dateFormat: string,
};

const Overdue = ({ signingPeriodExpiryTime, dateFormat }: Props) => {
  const renderInsight = () => {
    if (!signingPeriodExpiryTime) {
      return <Message id="Overdue" comment="Insight for overdue contract. Visible in contract card." />;
    }

    const daysSince = getDaysSince(signingPeriodExpiryTime);
    const dateString = formatDateString(signingPeriodExpiryTime, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    if (daysSince > 7) {
      return (
        <Message
          id="Overdue since {date}"
          values={{
            date,
          }}
          comment="Insight for overdue contract, stating when it became overdue. Visible in contract card."
        />
      );
    }

    if (daysSince > 0) {
      return (
        <Message
          id="Overdue since yesterday ({date})"
          pluralId="Overdue since {days} days ago ({date})"
          pluralCondition="days"
          values={{
            days: daysSince,
            date,
          }}
          comment="Insight for overdue contract, stating when it became overdue. Visible in contract card."
        />
      );
    }

    return null;
  };

  return (
    <Insight
      IconComponent={CalendarAlert}
      insight={renderInsight()}
    />
  );
};

export default Overdue;
