// @flow

import React, { useCallback } from 'react';

export const DataFieldsContext = React.createContext<any>();

type Props = {|
  children: React.Node,
|};

export function DataFieldsProvider({ children }: Props) {
  const getDataFieldVisibleText = useCallback((
    dataFieldObject,
    agreement,
    account,
    boxId,
    canAlterBoxComposition,
  ) => {
    if (!dataFieldObject || !agreement.boxes) {
      return '';
    }

    const { value, placeholder, name } = dataFieldObject.value;

    if (canAlterBoxComposition) {
      return value || placeholder || name;
    }

    return value || '';
  }, []);

  const contextValue = React.useMemo(() => ({
    getDataFieldVisibleText,
  }), [getDataFieldVisibleText]);

  return (
    <DataFieldsContext.Provider value={contextValue}>
      {children}
    </DataFieldsContext.Provider>
  );
}

export const useDataFields = () => {
  const contextValue = React.useContext(DataFieldsContext);

  if (!contextValue) {
    throw new Error('useDataFields should be used inside a DataFieldsProvider');
  }

  return contextValue;
};
