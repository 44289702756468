import { uniqueId } from 'lodash';
import Svg from 'components/svg';

export const DownChevronIcon = Svg((
  <path fillRule="evenodd" clipRule="evenodd" d="M0.991889 0.468498L4.91611 5.10047L8.84033 0.468498L9.83222 1.30883L5.72672 6.15477L5.71713 6.16583C5.61957 6.27587 5.49947 6.36785 5.3614 6.4323C5.22303 6.4969 5.07145 6.5315 4.91611 6.5315C4.76077 6.5315 4.60919 6.4969 4.47082 6.4323C4.33275 6.36785 4.21265 6.27587 4.11509 6.16583L4.1055 6.15477L0 1.30883L0.991889 0.468498Z" fill="currentColor" />

), { viewBox: '0 0 10 7', fill: 'none' });

export const UpChevronIcon = Svg((
  <path fillRule="evenodd" clipRule="evenodd" d="M0.991889 6.5315L4.91611 1.89953L8.84033 6.5315L9.83222 5.69117L5.72672 0.845227L5.71713 0.834167C5.61957 0.724127 5.49947 0.632147 5.3614 0.567692C5.22303 0.5031 5.07145 0.4685 4.91611 0.4685C4.76077 0.4685 4.60919 0.5031 4.47082 0.567692C4.33275 0.632147 4.21265 0.724127 4.11509 0.834167L4.1055 0.845227L0 5.69117L0.991889 6.5315Z" fill="currentColor" />
), { viewBox: '0 0 10 7', fill: 'none' });

const Fragment = () => {
  const id = uniqueId('selected_icon_clip_path');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M0.666748 7.32634L2.91003 10.3365C2.98549 10.4376 3.08177 10.5199 3.19176 10.577C3.30175 10.6342 3.42264 10.6648 3.5455 10.6667C3.66837 10.6686 3.79007 10.6417 3.90163 10.5879C4.01318 10.5342 4.11174 10.455 4.19003 10.3561L11.3334 1.3335" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

export const SelectedIcon = Svg(
  Fragment,
  { viewBox: '0 0 12 12', fill: 'none' },
);
