// @flow

import {
  getMultiple,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import { decamelizeKeys } from 'humps';
import { SHARED_WITH_ME_ID } from 'oneflow-client/agreements';

type DelayedEventsResponse = Promise<{
  collection: Array<DelayedEvent>,
  count: number,
}>;
type GetDelayedEventsParams = {
  workspaceId: number,
  fromDate?: string,
  toDate?: string,
  createdBy?: Array<string>,
  eventTypes?: Array<string>,
  agreementId?: number,
  userInRecipients?: boolean,
};
type GetDelayedEvents = GetDelayedEventsParams => DelayedEventsResponse;

export const getParams = (
  workspaceId: number,
  fromDate?: string,
  toDate?: string,
  createdBy?: Array<string>,
  eventTypes?: Array<string>,
  agreementId?: number,
  userInRecipients?: boolean,
) => {
  const params = {
    fromDate,
    toDate,
    createdBy,
    eventTypes,
    agreementId,
    userInRecipients,
  };

  if (workspaceId === SHARED_WITH_ME_ID) {
    params.sharedWithMe = 1;
  } else {
    params.collectionId = workspaceId;
  }

  return decamelizeKeys(params);
};

// eslint-disable-next-line import/prefer-default-export
export const getDelayedEvents: GetDelayedEvents = ({
  workspaceId,
  fromDate = undefined,
  toDate = undefined,
  createdBy,
  eventTypes,
  agreementId = undefined,
  userInRecipients = false,
}) => (
  getMultiple({
    url: '/agreements/delayed_events/',
    params: getParams(
      workspaceId,
      fromDate,
      toDate,
      createdBy,
      eventTypes,
      agreementId,
      userInRecipients,
    ),
  })
    .then(extractResponseBodyAsJSON)
);
