import { useState } from 'react';
import { isEmpty } from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import clsx from 'clsx';
import type { ReactNode } from 'react';

import { getAgreementMyParticipant } from 'agreement/selectors';
import {
  getCurrentContractId,
  setCurrentContractError,
} from 'reducers/current-contract';
import { isTemplate } from 'agreement/states';
import { isViewer } from 'agreement/participant';
import useAgreement from 'hooks/use-agreement';
import useCurrentBox from 'hooks/use-current-box';
import type { Box } from 'data-validators/entity-schemas/document-box';

import { ContractBoxError } from 'components/contract-boxes/error';
import { getSectionRule } from 'components/contract-boxes/box-wrapper/helpers';
import { ImportWrapper } from 'components/import-wrapper/import-wrapper';
import BoxActionsMenu from 'components/contract-boxes/box-actions-menu';
import ErrorBoundary from 'components/error-boundary';
import SectionRuleWrapper from 'components/contract-boxes/box-wrapper/section-rule-wrapper/section-rule-wrapper';
import useFormatting from 'components/contract-boxes/box-wrapper/hooks/use-formatting';

import {
  BOX_ATTACHMENTS,
  BOX_DURATION,
  BOX_FORM,
  BOX_PDF,
  BOX_PRODUCT_SUMMATION,
  BOX_PRODUCT_TABLE,
  BOX_TEXT_AND_IMAGE,
  BOX_VIDEO,
} from 'agreement/constants';

import style from './box-wrapper.module.scss';

const errorContextMapper = {
  [BOX_ATTACHMENTS]: 'Attachment',
  [BOX_DURATION]: 'Duration',
  [BOX_FORM]: 'Form',
  [BOX_PDF]: 'Pdf',
  [BOX_PRODUCT_SUMMATION]: 'ProductSummation',
  [BOX_PRODUCT_TABLE]: 'ProductTable',
  [BOX_TEXT_AND_IMAGE]: 'TextAndImage',
  [BOX_VIDEO]: 'Video',
};

type Props = {
  boxId: number,
  children: ReactNode,
  customClasses?: string,
  isAllowedToEdit: boolean,
  nonFixedActions?: ReactNode[],
  onAddSectionRules?: () => void,
  onRemoveBox: () => void,
  triggerToggleLock?: () => void,
};

const BoxWrapper = ({
  boxId,
  children,
  customClasses,
  isAllowedToEdit,
  nonFixedActions,
  onAddSectionRules,
  onRemoveBox,
  triggerToggleLock,
}: Props) => {
  const [boxActionsMenuVisible, setBoxActionsMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const onError = () => dispatch(setCurrentContractError(true));

  const agreementId = useSelector(getCurrentContractId) as number;
  const agreement = useAgreement(agreementId);
  const box = useCurrentBox(boxId) as Box;

  const boxContainerClasses = clsx(style.BoxContainer, customClasses);
  const formattingStyles = useFormatting(agreementId);
  const myParticipant = agreement?.parties && getAgreementMyParticipant(agreement);
  const isViewerParticipant = (
    !isEmpty(myParticipant)
      && !isTemplate(agreement)
      && isViewer(myParticipant)
  );
  const rule = getSectionRule(box);

  return (
    <ErrorBoundary
      customError={(
        <ContractBoxError
          box={box}
          isEditable={isAllowedToEdit}
          isViewer={isViewerParticipant}
          onRemoveBox={onRemoveBox}
        />
      )}
      errorContext={`BoxRenderError#${errorContextMapper[box.type]}AgreementId#${agreementId}`}
      onError={onError}
    >
      <div className={boxContainerClasses}>
        <div className="box-menu-container">
          <div className={clsx(style.BoxMenu, { [style.Visible]: boxActionsMenuVisible })}>
            <BoxActionsMenu
              box={box}
              isEditable={isAllowedToEdit}
              isViewer={isViewerParticipant}
              nonFixedActions={nonFixedActions}
              onAddSectionRules={() => {
                onAddSectionRules?.();
              }}
              onRemoveBox={onRemoveBox}
              setVisibility={setBoxActionsMenuVisible}
              triggerToggleLock={triggerToggleLock}
            />
          </div>
        </div>
        <ImportWrapper
          agreementId={agreementId}
          boxType={box.type}
          boxId={box.id}
        >
          <SectionRuleWrapper
            rule={rule}
            boxId={boxId}
          >
            <div style={formattingStyles}>
              {children}
            </div>
          </SectionRuleWrapper>
        </ImportWrapper>
      </div>
    </ErrorBoundary>
  );
};

export default BoxWrapper;
