import { Message } from '@oneflowab/pomes';

import style from './free-plan.module.scss';

const FreePlan = () => (
  <div className={style.Container}>
    <p>
      <Message
        id="Don't worry, we got you covered ✨"
        comment="Welcome text in the free plan modal"
      />
    </p>
    <p>
      <Message
        id="Our free plan is a great alternative if you want to upload, send and sign non-editable documents (PDF)."
        comment="Text in the free plan modal"
      />
    </p>
  </div>
);

export default FreePlan;
