import {
  hasDraftApprovalFlow,
  isDraftApprovalFlowSucceeded,
} from 'agreement';

const isDraftApproved = (agreement) => {
  if (hasDraftApprovalFlow(agreement) && isDraftApprovalFlowSucceeded(agreement)) {
    return true;
  }

  return false;
};

export default isDraftApproved;
