// @flow

import * as React from 'react';
import find from 'lodash/find';
import { PDF_TEMPLATE_NAME } from 'agreement/template-library';

import TemplateItem from 'components/template-item';

import style from './template-picker.module.scss';

export type Props = {
  templates: Array<any>,
  onSelectionChange: (item: any, state: boolean) => void,
  selectedItems: Array<Template>,
  isFreemium?: boolean,
};

class TemplatePicker extends React.Component<Props> {
  static defaultProps = {
    isFreemium: undefined,
  }

  isFreemiumTooltipVisible = (templateName: string) => {
    const { isFreemium } = this.props;

    return isFreemium && templateName !== PDF_TEMPLATE_NAME;
  }

  onItemClick = (item: Template, isSelected: boolean) => {
    const { onSelectionChange } = this.props;
    onSelectionChange(item, isSelected);
  };

  isItemSelected(id: number) {
    const { selectedItems } = this.props;
    return Boolean(find(selectedItems, (item) => item.id === id));
  }

  render() {
    const { templates } = this.props;

    return (
      <div className={style.TemplatePicker}>
        {
          templates.map(
            (template) => (
              <TemplateItem
                key={`id_${template.id}`}
                id={template.id}
                agreementId={template.agreement}
                title={template.name}
                onClick={this.onItemClick}
                isSelected={this.isItemSelected(template.id)}
                isFreemiumTooltipVisible={this.isFreemiumTooltipVisible(template.name)}
              />
            ),
          )
        }
      </div>
    );
  }
}

export default TemplatePicker;
