// @flow

import * as React from 'react';
import get from 'lodash/get';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import { PasswordVerificationField } from 'components/fields';

import ModalForm from 'hocs/modal-form';
import SecurityUnlock from 'components/icons/security-unlock';
import { getErrorMessage } from 'components/api-error';

export type FormData = {
  passwordVerification: string,
};

export const parseSubmitError = (error) => {
  const errorCode = get(error, 'body.api_error_code');
  const wrongPasswordErrorCode = 1013;
  const errorMessage = getErrorMessage(wrongPasswordErrorCode);
  if (errorMessage && errorCode === wrongPasswordErrorCode) {
    return {
      passwordVerification: errorMessage.headerText,
    };
  }

  return null;
};

export type Props = {|
  message: MessageTranslator,
  resetFormState: () => void,
  children: React.Node,
  formState: CreateState,
  formActions: {
    start: string,
    success: string,
    fail: string,
  },
  sendData: FormData => void,
|};

export const DisableMfaModal = ({
  message,
  resetFormState,
  children,
  formState,
  formActions,
  sendData,
}: Props) => (
  <ModalForm
    title={message({
      id: 'Confirm disabling two-step authentication',
      comment: 'Modal title, confirmation for disabling two-factor authentication.',
    })}
    body={(
      <>
        <p>
          <Message
            id="You are about to disable two-factor authentication"
            comment="Modal text, explanation for disabling two-factor authentication."
          />
        </p>
        <div>
          <PasswordVerificationField autoFocus />
        </div>
      </>
    )}
    resetFormState={resetFormState}
    formState={formState}
    icon={SecurityUnlock}
    outline
    formActions={formActions}
    prepareFormData={sendData}
    parseSubmitError={parseSubmitError}
    modalKey="disable 2fa modal"
  >
    {children}
  </ModalForm>
);

export default localize<Props>(DisableMfaModal);
