import { useCallback, useMemo } from 'react';
import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { getDataField } from 'reducers/current-contract';
import { useFormBoxProps } from 'contexts/form-box-props';
import useDebouncedCallback from 'hooks/use-debounced-callback';

import { FormFieldContext } from './form-field-context';
import { useFieldValidationContext } from './field-validation-provider';

type Props = {
  children: ReactNode,
  valueDataFieldKey: string,
};

const DataFieldInputProvider = ({
  valueDataFieldKey,
  children,
}: Props) => {
  const dataFieldObject = useSelector((state) => getDataField(state, valueDataFieldKey));
  const dataFieldValue = dataFieldObject?.value.value;
  const fieldValue = dataFieldValue || '';
  const { updateDataFieldObject } = useFormBoxProps();
  const { setTouched } = useFieldValidationContext();

  const onFieldValueChange = useCallback((newFieldValue: string) => {
    setTouched(true);
    updateDataFieldObject(dataFieldObject, newFieldValue);
  }, [
    dataFieldObject,
    updateDataFieldObject,
  ]);

  const onFieldValueChangeDebounced = useDebouncedCallback((newFieldValue) => {
    updateDataFieldObject(dataFieldObject, newFieldValue);
  }, [
    dataFieldObject,
    updateDataFieldObject,
  ]);

  const contextValue = useMemo(() => ({
    fieldValue,
    onFieldValueChange,
    onFieldValueChangeDebounced,
    hasDataField: true,
  }), [
    fieldValue,
    onFieldValueChange,
    onFieldValueChangeDebounced,
  ]);

  return (
    <FormFieldContext.Provider value={contextValue}>
      {children}
    </FormFieldContext.Provider>
  );
};

export default DataFieldInputProvider;
