// @flow

const TOTAL_INLINE_PORTIONS = 21;
const TOTAL_BLOCK_PORTIONS = 8;

export function getPreferredCavasSizeForWidth(width: number, aspectRatio = `${TOTAL_INLINE_PORTIONS}/${TOTAL_BLOCK_PORTIONS}`) {
  const canvasSize = new Map();
  const [totalInlinePortions, totalBlockPortions] = aspectRatio.split('/')
    .map((portion) => parseInt(portion.trim(), 10));

  // Keep it as integer
  let singleRoundedPortion = Math.floor(width / totalInlinePortions);
  if (singleRoundedPortion === 0) {
    singleRoundedPortion = 1;
  }

  canvasSize.set('width', singleRoundedPortion * totalInlinePortions);
  canvasSize.set('height', singleRoundedPortion * totalBlockPortions);

  return canvasSize;
}

export function getPreferredCavasSizeForHeight(height: number, aspectRatio = `${TOTAL_INLINE_PORTIONS}/${TOTAL_BLOCK_PORTIONS}`) {
  const canvasSize = new Map();

  const [totalInlinePortions, totalBlockPortions] = aspectRatio.split('/')
    .map((portion) => parseInt(portion.trim(), 10));
  // Keep it as integer
  let singleRoundedPortion = Math.floor(height / totalBlockPortions);
  if (singleRoundedPortion === 0) {
    singleRoundedPortion = 1;
  }

  canvasSize.set('width', singleRoundedPortion * totalInlinePortions);
  canvasSize.set('height', singleRoundedPortion * totalBlockPortions);

  return canvasSize;
}
