// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { isRecordingSupported } from 'utils/browser';

import Button from 'components/button';
import MeetingCamera from 'components/icons/meeting-camera';
import MonitorCamera from 'components/icons/monitor-camera';
import VideoFileUpload from 'components/icons/video-file-upload';

import style from './recorder-controls.module.scss';

type Props = {|
  requestCamera: () => void,
  requestScreen: () => void,
  requestUpload: () => void,
|};

class RecorderControls extends React.Component<Props> {
  renderRequestCamera(recordingIsSupported: boolean) {
    const { requestCamera } = this.props;

    return (
      <Button
        onClick={requestCamera}
        customClass={style.RecorderControl}
        kind="special"
        icon={MeetingCamera}
        disabled={!recordingIsSupported}
        data-testid="start-camera"
      >
        <Message
          id="Start camera"
          comment="Start recording a video from webcam"
        />
      </Button>
    );
  }

  renderRequestScreen(recordingIsSupported: boolean) {
    const { requestScreen } = this.props;

    return (
      <Button
        onClick={requestScreen}
        customClass={style.RecorderControl}
        kind="special"
        icon={MonitorCamera}
        disabled={!recordingIsSupported}
        data-testid="record-screen"
      >
        <Message
          id="Record screen"
          comment="Start recording a video from the user's screen"
        />
      </Button>
    );
  }

  renderRequestUpload() {
    const { requestUpload } = this.props;

    return (
      <Button
        onClick={requestUpload}
        customClass={style.RecorderControl}
        kind="special"
        icon={VideoFileUpload}
      >
        <Message
          id="Upload a video"
          comment="Select a video file from the user's computer"
        />
      </Button>
    );
  }

  render() {
    const recordingIsSupported = isRecordingSupported();
    let recordingNotSupported = null;

    if (!recordingIsSupported) {
      recordingNotSupported = (
        <div className={style.RecordingNotSupported}>
          <Message
            id="Recording is only supported in Chrome and Firefox browsers."
            comment="A helper text for users who use browsers other than Chrome and Firefox and might want to record a video from webcam"
          />
        </div>
      );
    }

    return (
      <>
        {recordingNotSupported}
        <div className={style.RecorderControls}>
          {this.renderRequestCamera(recordingIsSupported)}
          {this.renderRequestScreen(recordingIsSupported)}
          {this.renderRequestUpload()}
        </div>
      </>
    );
  }
}

export default RecorderControls;
