/* eslint-disable import/named */
import React, { forwardRef } from 'react';
import omit from 'lodash/omit';
import * as ReactPopover from '@radix-ui/react-popover';
import * as ReactDialog from '@radix-ui/react-dialog';
import clsx from 'clsx';

import { PopoverPrimitive, PopoverContent } from 'components/popover';
import { DialogPrimitive, DialogContent } from 'components/dialog';

import style from './popup-content.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  dialogContentProps?: ReactDialog.DialogContentProps;
  fullViewWidth?: boolean;
  isPortal?: boolean;
  onInteractOutside?: () => void;
  popoverContentProps?: ReactPopover.PopoverContentProps;
  side?: ReactPopover.PopoverContentProps['side'];
} & React.HTMLAttributes<HTMLElement>;

export const PopupDialogContent = forwardRef<HTMLElement, Props>((
  props,
  ref,
) => {
  const {
    children,
    className,
    dialogContentProps,
    isPortal = false,
    ...restProps
  } = omit(props, 'popoverContentProps', 'side', 'sideOffset', 'arrowHeight');

  const dialogContentClasses = clsx(style.DialogContent, className);

  let popupContent = (
    <DialogPrimitive.Overlay className={style.DialogOverlay}>
      <DialogContent
        ref={ref}
        className={dialogContentClasses}
        {...dialogContentProps}
        {...restProps}
      >
        {children}
      </DialogContent>
    </DialogPrimitive.Overlay>
  );

  if (isPortal) {
    popupContent = (
      <DialogPrimitive.Portal>
        {popupContent}
      </DialogPrimitive.Portal>
    );
  }

  return popupContent;
});

PopupDialogContent.displayName = 'PopupDialogContent';

export const PopupPopoverContent = forwardRef<HTMLElement, Props>((
  props,
  ref,
) => {
  const {
    children,
    className,
    isPortal = false,
    popoverContentProps,
    side = 'left',
    ...restProps
  } = omit(props, 'dialogContentProps', 'fullViewWidth');

  let popupContent = (
    <PopoverContent
      ref={ref}
      data-testid="popover-content"
      style={{ zIndex: 1 }}
      side={side}
      sideOffset={15}
      arrowHeight={0}
      className={clsx(style.PopoverContent, className)}
      {...popoverContentProps}
      {...restProps}
    >
      {children}
    </PopoverContent>
  );

  if (isPortal) {
    popupContent = (
      <PopoverPrimitive.Portal>
        {popupContent}
      </PopoverPrimitive.Portal>
    );
  }

  return popupContent;
});

PopupPopoverContent.displayName = 'PopupPopoverContent';
