// @flow

import * as React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import {
  getMessageRecipients,
  getAgreementSignatoriesWithoutSelf,
  getAgreementOwnerParticipantsWithoutSelf,
  getOtherEnabledParticipants,
  getCounterpartyParticipants,
  getMessageRecipientsForEnabledBlocks,
} from 'agreement/selectors';

import { sortParticipantsByFullname } from 'agreement/participant';
import isSignatory from 'agreement/participant/is-signatory';
import isMessageRecipientHasNotSigned from 'agreement/participant/is-message-recipient-has-not-signed';

import { getPositionFromSessionSelector } from 'reducers/session';

import Field from 'components/field';
import SelectField from 'components/select-field';
import style from './recipients-category-picker.module.scss';

export type Props = {
  agreement: Agreement,
  defaultRecipientsCategory: any,
  message: MessageTranslator,
}

function filterRecipientsFromOptions(
  agreement: Agreement,
  categoryRecipients: Array<AgreementParticipant>,
): Array<AgreementParticipant> {
  const recipientOptions = getOtherEnabledParticipants(agreement);

  if (agreement.config?.signOrder) {
    return getMessageRecipientsForEnabledBlocks(agreement, recipientOptions);
  }

  return recipientOptions
    .filter((option) => !categoryRecipients
      .find((categoryRecipient) => categoryRecipient.id === option.id));
}

function getRecipientsSortedByName(agreement, category) {
  return sortParticipantsByFullname(getMessageRecipients(agreement, category));
}

export const RecipientsCategoryPicker = ({
  agreement,
  defaultRecipientsCategory,
  message,
}: Props) => {
  const form = useForm();
  const position = useSelector((state) => (getPositionFromSessionSelector(state)));
  const { values } = useFormState();
  const isSignOrderActive = agreement.config?.signOrder;
  const categoryVisible = React.useMemo(() => ({
    unsignedParticipants: getOtherEnabledParticipants(agreement)
      .filter(isMessageRecipientHasNotSigned)
      .filter(isSignatory).length > 0,
    signatories: getAgreementSignatoriesWithoutSelf(agreement).length > 0,
    allColleagues: getAgreementOwnerParticipantsWithoutSelf(agreement).length > 0,
    allCounterparties: getCounterpartyParticipants(agreement).length > 0,
  }), [agreement]);

  const isCategoryVisible = React.useCallback((categoryName) => {
    if (isSignOrderActive) {
      return getMessageRecipients(agreement, categoryName).length > 0;
    }
    return categoryVisible[categoryName];
  }, [agreement, isSignOrderActive, categoryVisible]);

  const allCategoryInitialOptions = React.useMemo(
    () => [
      {
        categoryLabel: message({
          id: 'Unsigned participants',
          comment: 'A label for an option in a dropdown menu',
        }),
        recipientsCategory: 'unsignedParticipants',
        isVisible: isCategoryVisible('unsignedParticipants'),
      },
      {
        categoryLabel: message({
          id: 'Signatories',
          comment: 'A label for an option in a dropdown menu',
        }),
        recipientsCategory: 'signatories',
        isVisible: isCategoryVisible('signatories'),
      },
      {
        categoryLabel: message({
          id: 'All colleagues',
          comment: 'A label for an option in a dropdown menu',
        }),
        recipientsCategory: 'allColleagues',
        isVisible: isCategoryVisible('allColleagues'),
      },
      {
        categoryLabel: message({
          id: 'All counterparties',
          comment: 'A label for an option in a dropdown menu',
        }),
        recipientsCategory: 'allCounterparties',
        isVisible: isCategoryVisible('allCounterparties'),
      },
      {
        categoryLabel: message({
          id: 'All participants',
          comment: 'A label for an option in a dropdown menu',
        }),
        recipientsCategory: 'allParticipants',
        isVisible: true,
      },
    ].filter(({ isVisible }) => isVisible),
    [isCategoryVisible, message],
  );

  const [allCategoryOptions, setAllCategoryOptions] = React.useState(allCategoryInitialOptions);
  // eslint-disable-next-line no-unused-vars
  const [selectedRecipientCategory, setSelectedRecipientCategory] = React.useState(
    defaultRecipientsCategory,
  );
  const [recipients, setRecipients] = React.useState(
    () => getMessageRecipients(agreement, defaultRecipientsCategory),
  );

  const categoryRecipientsFromAgreement = React.useCallback((currentAgreement, categoryName) => {
    let updatedRecipients;
    if (isSignOrderActive) {
      return getMessageRecipients(currentAgreement, categoryName);
    }

    switch (categoryName) {
      case 'signatories':
        updatedRecipients = getAgreementSignatoriesWithoutSelf(currentAgreement);
        break;
      case 'allColleagues':
        updatedRecipients = getAgreementOwnerParticipantsWithoutSelf(currentAgreement);
        break;
      case 'allCounterparties':
        updatedRecipients = getCounterpartyParticipants(currentAgreement);
        break;
      case 'allParticipants':
        updatedRecipients = [
          ...recipients,
          ...filterRecipientsFromOptions(currentAgreement, recipients),
        ];
        break;
      default:
        break;
    }

    return updatedRecipients;
  }, [recipients, isSignOrderActive]);

  const onRecipientCategoryChange = React.useCallback((selectedCategory: any) => {
    let updatedRecipients = getRecipientsSortedByName(
      agreement,
      selectedCategory.recipientsCategory,
    );

    if (selectedCategory?.recipientsCategory !== 'custom') {
      const categoryRecipients = categoryRecipientsFromAgreement(
        agreement,
        selectedCategory?.recipientsCategory,
      );

      if (categoryRecipients) {
        updatedRecipients = categoryRecipients;
      }

      setSelectedRecipientCategory(selectedCategory.recipientsCategory);
      setAllCategoryOptions(allCategoryInitialOptions);

      updatedRecipients = sortParticipantsByFullname(updatedRecipients);
      setRecipients(updatedRecipients);

      form.change('recipients', updatedRecipients);
    }
  }, [
    agreement,
    form,
    allCategoryInitialOptions,
    categoryRecipientsFromAgreement,
  ]);

  const isReminderPrivate = React.useCallback(() => {
    if (!values || values.recipients?.length === 0) {
      return false;
    }

    const isRecipientsColleagues = values.recipients.map((recipient) => recipient.account?.id);

    const areAllRecipientsColleagues = isRecipientsColleagues
      .every((recipientAccount) => recipientAccount === position.account);
    return areAllRecipientsColleagues;
  }, [position, values]);

  const showPrivateTooltip = React.useCallback(() => {
    if (!isReminderPrivate()) {
      return undefined;
    }
    const privateTag = (
      message({
        id: 'Private',
        comment: 'Tooltip visual cue for private tag',
      })
    );

    const privateTagsDescription = (
      message({
        id: 'This reminder will only be visible in the comment section to participants in your own organization.',
        comment: 'Tooltip text for signing method field in the Add Colleague modal.',
      })
    );

    return {
      message: privateTagsDescription,
      icon: <div className={style.PrivateTag}>{privateTag}</div>,
      side: 'bottom',
      arrow: true,
      theme: 'oneflow',
      zIndex: 10003,
    };
  }, [message, isReminderPrivate]);

  const onRecipientsChange = React.useCallback(() => {
    const reminderRecipients = getMessageRecipients(agreement, 'custom');
    const customOption = {
      categoryLabel: message({
        id: 'Custom',
        comment: 'A label for an option in a dropdown menu',
      }),
      recipientsCategory: 'custom',
    };
    const allOptionsWithCustom = [
      ...allCategoryInitialOptions,
      customOption,
    ];
    setAllCategoryOptions(allOptionsWithCustom);
    setRecipients(reminderRecipients);
    setSelectedRecipientCategory('custom');

    // Update the other connected field
    form.change(
      'recipientsCategory',
      customOption,
    );
  }, [form, agreement, message, allCategoryInitialOptions]);

  const getRecipientsOptions = () => {
    if (recipients) {
      const options = filterRecipientsFromOptions(agreement, recipients);

      if (isSignOrderActive) {
        return options;
      }
      return [...recipients, ...options];
    }
    return [];
  };

  return (
    <div className={style.RecipientsCategoryPicker}>
      <Field
        label={message({ id: 'To', comment: 'A label for the relevant field' })}
        name="recipientsCategory"
        component={SelectField}
        valueKey="recipientsCategory"
        labelKey="categoryLabel"
        options={allCategoryOptions}
        onChange={onRecipientCategoryChange}
        required
        clearable={false}
        searchable={false}
        fieldinfo={showPrivateTooltip()}
      />
      <Field
        name="recipients"
        label={message({ id: 'Recipient', comment: 'A label for the relevant field' })}
        component={SelectField}
        multi
        backspaceRemoves
        valueKey="id"
        labelKey="fullname"
        options={getRecipientsOptions()}
        onChange={onRecipientsChange}
        required
        hideLabel
      />
    </div>
  );
};

export default localize<Props>(RecipientsCategoryPicker);
