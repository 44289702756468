import { Message } from '@oneflowab/pomes';

import type { AuditLogEvent } from 'types/entities';
import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent;
}

export const ExtensionDisableEvent = ({ auditLogEvent }: Props) => (
  <Message
    id='The marketplace item "{extensionName}" was {jsx-start}disabled{jsx-end}'
    comment="Audit log event message for disabling an extension"
    component="span"
    className={style.Red}
    values={{
      extensionName: auditLogEvent.target?.name,
    }}
  />
);
