import Loading from 'components/legacy/loading';
import style from './loading-screen.module.scss';

type Props = {
  isLegacy: boolean,
}

const LoadingScreen = ({
  isLegacy,
}: Props) => {
  if (isLegacy) {
    return (
      <div className="Loading">
        <div className="LoadingContainer">
          <div className="LoadingOneflowLogo" />
          <div className="LoadingAppLoader" />
        </div>
      </div>
    );
  }

  return (
    <div className={style.Loading}>
      <Loading />
    </div>
  );
};

export default LoadingScreen;
