import getFontAssetsDomain from './get-font-asset-domain.js';

const loadAnton = () => {
  const normalAnton = new FontFace(
    'Anton',
    `url(${getFontAssetsDomain()}/resources/fonts/anton/anton_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );

  return [normalAnton];
};

export default loadAnton;
