export default (message) => [
  {
    header: message({
      id: 'Contract events',
      comment: 'One of the events used in the notifications page',
    }),
    events: [
      {
        name: 'positionNotificationsEmailAgreementPublish',
        label: message({
          id: 'I get invited to a contract',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailAgreementExpired',
        label: message({
          id: 'An unsigned contract becomes overdue',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailAgreementRevived',
        label: message({
          id: 'The signing period is extended for an overdue contract',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailAgreementClose',
        label: message({
          id: 'A contract is signed or declined',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailAgreementCancel',
        label: message({
          id: 'A contract is canceled',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailAgreementUpdate',
        label: message({
          id: 'The content in a contract is changed',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailAgreementMessage',
        label: message({
          id: 'A comment is added to a contract',
          comment: 'One of the events used in the notifications page',
        }),
      },
    ],
  },
  {
    header: message({
      id: 'Participant events',
      comment: 'One of the events used in the notifications page',
    }),
    events: [
      {
        name: 'positionNotificationsEmailParticipantSign',
        label: message({
          id: 'A participant signs a contract',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailParticipantFirstVisit',
        label: message({
          id: 'A participant opens a contract for the first time',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailPartiesUpdate',
        label: message({
          id: 'A participant or party is added, updated or removed in a contract',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailParticipantEmailFeedback',
        label: message({
          id: "A contract invitation couldn't be delivered",
          comment: 'One of the events used in the notifications page',
        }),
      },
    ],

  },
  {
    header: message({
      id: 'Lifecycle events',
      comment: 'One of the events used in the notifications page',
    }),
    events: [
      {
        name: 'positionNotificationsEmailLifecycleStart',
        label: message({
          id: "A contract starts (only relevant when start date isn't the same as sign date)",
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailLifecycleNewPeriod',
        label: message({
          id: 'A contract enters a new period (only relevant for recurring contracts)',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailLifecycleEnd',
        label: message({
          id: 'A contract ends (only relevant for contracts with a single period)',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailLifecycleTerminated',
        label: message({
          id: 'A canceled contract ends (only relevant for recurring contracts)',
          comment: 'One of the events used in the notifications page',
        }),
      },
    ],
  },
  {
    header: message({
      id: 'Colleague events',
      comment: 'One of the events used in the notifications page',
    }),
    events: [
      {
        name: 'positionNotificationsRecieveAccountContractWins',
        label: message({
          id: "Notify me when a colleague's contract is signed by everyone",
          comment: 'One of the events used in the notifications page',
        }),
      },
    ],
  },
  {
    header: message({
      id: 'Reminders',
      comment: 'One of the events used in the notifications page',
    }),
    events: [
      {
        name: 'positionNotificationsEmailReminderExpired',
        label: message({
          id: '3 days before signing period expires',
          comment: 'One of the events used in the notifications page',
        }),
      },
      {
        name: 'positionNotificationsEmailReminderLifecycles',
        label: message({
          id: 'Remind me when the contract notice period ends',
          comment: 'One of the events used in the notifications page',
        }),
        tooltip: message({
          id: 'Reminders can be set in the template or the contract settings tab.',
          comment: 'Tooltip to give more information when user hover the icon with mouse.',
        }),
      },
    ],
  },
];
