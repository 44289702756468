import type { Acl as AclType } from 'types/acl';

const mapAclToPermissions = (acl: AclType): AclType => {
  const aclKeys = Object.keys(acl);
  let permissions: AclType;

  if (aclKeys.length === 0 || aclKeys[0].includes(':')) {
    // if object is empty, or
    // if first key contains ':' (object of permissions),
    // then set permissions to the sent in ACL
    permissions = acl;
  } else {
    // else, iterate of the nested ACls and
    // compile a permissions list from those ACls
    permissions = {};
    aclKeys.forEach((aclKey) => {
      Object.keys(acl[aclKey]).forEach((permission) => {
        if (permissions[permission] === 'allow' || acl[aclKey][permission] === 'allow') {
          permissions[permission] = 'allow';
        } else {
          permissions[permission] = 'deny';
        }
      });
    });
  }

  return permissions;
};

export default mapAclToPermissions;
