import {
  isDraft, isTemplate,
  isSignedAndEndedAndTerminated,
  isSignedAndEndedWithNoDurationType,
  isSignedAndEnded,
  isSignedWithRecurringTypeAndCanceled,
  isSignedAndAwaiting,
  isSignedAndActive,
  isSignedWithoutLifecycle,
  isOverdue,
  isAnyDeclinedState,
} from 'agreement';

import {
  ActiveUntil,
  Awaiting,
  Canceled,
  Declined,
  Ended,
  MarkedAsSignMessage,
  Overdue,
  Signed,
  Terminated,
} from './insights';

import style from './insight.module.scss';

type Props = {
  agreement: Oneflow.Agreement
}

const insightComponents = (agreement: Oneflow.Agreement) => [
  {
    condition: isSignedAndEndedAndTerminated(agreement),
    component: (
      <>
        <Signed agreement={agreement} />
        <Terminated agreement={agreement} />
      </>
    ),
  },
  {
    condition: isSignedWithRecurringTypeAndCanceled(agreement),
    component: (
      <>
        <Signed agreement={agreement} />
        <Canceled agreement={agreement} />
        <ActiveUntil agreement={agreement} />
        <MarkedAsSignMessage agreement={agreement} />
      </>
    ),
  },
  {
    condition: isSignedAndEndedWithNoDurationType(agreement)
      || isSignedWithoutLifecycle(agreement)
      || isSignedAndActive(agreement),
    component: (
      <>
        <Signed agreement={agreement} />
        <MarkedAsSignMessage agreement={agreement} />
      </>
    ),
  },
  {
    condition: isSignedAndEnded(agreement),
    component: (
      <>
        <Signed agreement={agreement} />
        <Ended agreement={agreement} />
      </>
    ),
  },
  {
    condition: isSignedAndAwaiting(agreement),
    component: (
      <>
        <Signed agreement={agreement} />
        <Awaiting agreement={agreement} />
      </>
    ),
  },
  {
    condition: isOverdue(agreement),
    component: <Overdue agreement={agreement} />,
  },
  {
    condition: isAnyDeclinedState(agreement),
    component: <Declined agreement={agreement} />,
  },
];

const Insight = ({ agreement }: Props) => {
  if (isDraft(agreement) || isTemplate(agreement)) {
    return null;
  }

  const insights = insightComponents(agreement);

  return (
    <div className={style.InsightContainer} data-testid="insights-container">
      {insights.find((insight) => insight.condition)?.component}
    </div>
  );
};

export default Insight;
