// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import dataFieldsReducer from 'reducers/entities/data-fields';

import { RemoveDataFieldConfirmation } from './remove-data-field';
import type { Props } from './remove-data-field';

type StateProps = {|
  removeState: RemoveState,
|};

type DispatchProps = {|
  removeDataField: () => void,
  resetRemoveState: Function,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  dataField: DataField,
  dataFieldSetId: number,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { dataField }) => ({
  removeState: dataFieldsReducer.getRemoveSelector(state, { id: dataField.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  dataField,
  dataFieldSetId,
}) => ({
  removeDataField: () => {
    dispatch(dataFieldsReducer.removeDataField({
      id: dataField.id,
      data: {
        dataFieldSetId,
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(dataFieldsReducer.removeDataFieldReset({
      id: dataField.id,
    }));
  },
});

const connectedRemoveDataFieldConfirmation = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveDataFieldConfirmation);

export { connectedRemoveDataFieldConfirmation as RemoveDataFieldConfirmation };
