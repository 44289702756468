import { useState } from 'react';
import { useSelector } from 'react-redux';

import { isContractPristine, getHasNonPdfSections } from 'reducers/current-contract';
import { getAccountFromSessionSelector } from 'reducers/session';
import { canBePublishedByMyParticipant } from 'agreement';
import hasParticipantApprovedDraft from 'agreement/draft-approval-flow';
import isDraftApproved from 'agreement/actions/is-draft-approved';
import { getAgreementMyParticipant, getEnabledParticipants, usesSameDeviceAll } from 'agreement/selectors';
import useAgreement from 'hooks/use-agreement';
import {
  hasSignatoriesInDocument,
  hasPendingStateApprovers,
  anApproverIsWithoutSignatoryAfter,
} from 'agreement/pending-state-flow';

import type { Layout } from 'components/document-layout-container/types';
import { checkAcl } from 'components/acl';
import { isContractEditable } from 'components/document-layout-container/helpers';
import { DiscardSaveSendActions, SignOnSameDeviceActions } from './buttons-call-to-action';
import { SendDocumentButton, SendDraftForApprovalButton } from './buttons-call-to-action/buttons';
import { ApprovedState } from './states';
import { ApproverLastAction } from './buttons-call-to-action/actions/approver-last-action';

type Props = {
  agreementId: Oneflow.Agreement['id'],
  layout: Layout
}

const DraftApprovalFlowActions = ({ agreementId, layout }: Props) => {
  const agreement = useAgreement(agreementId);
  const myParticipant = getAgreementMyParticipant(agreement);
  const participants = getEnabledParticipants(agreement);
  const account: Oneflow.Account = useSelector(getAccountFromSessionSelector);
  const hasNonPdfSections = useSelector(getHasNonPdfSections);
  const hasPermissionToPublish = checkAcl(agreement.acl, 'agreement:publish');
  const isFreemiumWithNonPdfSections = account.isFreemium && hasNonPdfSections;
  const isPristine = useSelector(isContractPristine);
  const isEditable = isContractEditable(agreement);
  const hasApproved = hasParticipantApprovedDraft(agreement, myParticipant);
  const allUsingSameDevice = usesSameDeviceAll(agreement, account);
  const [showApproveDraftModal, setShowApproveDraftModal] = useState(false);

  const canPublish = canBePublishedByMyParticipant(
    agreement,
    myParticipant,
    participants,
    isFreemiumWithNonPdfSections,
    hasPermissionToPublish,
  );

  if (!isPristine && isEditable) {
    return (
      <DiscardSaveSendActions agreementId={agreementId} layout={layout} />
    );
  }

  const renderSendButton = () => {
    if (hasPendingStateApprovers(agreement)
      && hasSignatoriesInDocument(agreement)
      && anApproverIsWithoutSignatoryAfter(agreement)) {
      return (
        <ApproverLastAction
          agreement={agreement}
          hasPermissionToPublish={hasPermissionToPublish}
        />
      );
    }

    return (
      <SendDocumentButton
        agreementId={agreementId}
        canPublish={canPublish}
        layout={layout}
      />
    );
  };

  if (hasApproved && !showApproveDraftModal) {
    return (
      <>
        <ApprovedState layout={layout} />
        {isDraftApproved(agreement) && !allUsingSameDevice
          && renderSendButton()}
        {isDraftApproved(agreement) && allUsingSameDevice && (
          <SignOnSameDeviceActions agreement={agreement} layout={layout} />
        )}
      </>
    );
  }

  return (
    <SendDraftForApprovalButton
      agreementId={agreementId}
      layout={layout}
      canPublish={canPublish}
      showApproveDraftModal={showApproveDraftModal}
      setShowApproveDraftModal={setShowApproveDraftModal}
    />
  );
};

export default DraftApprovalFlowActions;
