// @flow

import React from 'react';
import get from 'lodash/get';
import { Message, type MessageTranslator } from '@oneflowab/pomes';
import { Form, FormSpy } from 'react-final-form';
import MakeAsyncFunction from 'react-redux-promise-listener';

import promiseListener from 'store/promise-listener';
import { hasAnyPosition } from 'oneflow-client/positions';
import adminPage from 'hocs/admin-page';

import * as EditableForm from 'components/editable-form';
import Field from 'components/field';
import { DetailsFooter } from 'components/details-footer';
import { LocalizedDateTime } from 'components/localized-date-time';
import { checkAcl } from 'components/acl';
import TextField, { PasswordField } from 'components/text-field';
import SelectField from 'components/select-field';
import TextArea from 'components/text-area';
import EnabledLanguage, { getEnabledLanguagesAsOptions } from 'components/languages';
import ChangePasswordModal from 'components/change-password-modal';
import emailValidationText from 'components/email-validation-text';
import UserRole from 'components/user-roles';
import UserSecurityStatus from 'components/user-security-status';
import EmailChangeNotification from 'components/details/notification/email-change-notification';
import ChangePending from 'components/details/status-pill/change-pending';

import clsx from 'clsx';
import style from './profile.module.scss';

type Actions = {
  start: string,
  success: string,
  fail: string,
};
type Props = {
  requestMe: () => void,
  resetUpdateState: (positionId: number) => void,
  updateState: UpdateState,
  position: Position,
  positionId: number,
  actions: Actions,
  updateData: () => void,
  message: MessageTranslator,
};

type State = {
  isEditMode: boolean,
  pendingEmail: string,
  currentLanguage: string,
};

export class ProfilePage extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.updateState.success) {
      const newEmail = get(props.updateState, 'data.email');
      const emailChanged = props.position.email !== newEmail;

      const langChanged = state.currentLanguage !== props.position.language;

      if (langChanged) {
        window.location.reload(true);
      }

      if (newEmail && emailChanged) {
        return {
          pendingEmail: newEmail,
          isEditMode: false,
        };
      }

      if (state.isEditMode) {
        return { isEditMode: false };
      }
    }

    return null;
  }

  state = {
    isEditMode: false,
    pendingEmail: '',
    // Used in getDerivedStateFromProps
    // eslint-disable-next-line react/no-unused-state
    currentLanguage: this.props.position.language,
  };

  componentDidMount() {
    this.props.requestMe();
  }

  getInitialValues() {
    const {
      fullname,
      email,
      signature,
      phoneNumber,
      language,
      title,
    } = this.props.position;

    return {
      fullname,
      email,
      signature,
      phoneNumber,
      language,
      title,
    };
  }

  getNotification() {
    const { message } = this.props;
    const notifications = this.state.pendingEmail
      ? [
        message({
          id: 'An email was sent to {email}. Please follow the instructions in the email to validate your new email address.',
          values: {
            email: this.state.pendingEmail,
          },
          comment: 'Used as a notification message after sending an email on the profile page',
        }),
      ]
      : [];

    return notifications;
  }

  uniquePositionEmail = ({ params }: { params: any }) => {
    const { position } = this.props;

    return hasAnyPosition({
      params,
      entityId: position.id,
      props: {
        returnEntity: true,
      },
    });
  };

  setIsEditMode = (_isEditMode) => {
    const { updateState, resetUpdateState } = this.props;

    if (!updateState.pristine) {
      resetUpdateState({ positionId: this.props.positionId });
    }

    this.setState({
      isEditMode: _isEditMode,
    });
  };

  parseSubmitError = (error) => {
    const { message } = this.props;

    if (get(error, 'body.api_error_code') === 1013) {
      return {
        passwordVerification: message({
          id: 'The password you entered is incorrect. Please try again.',
          comment: 'Showed when the entered password is wrong in profile page',
        }),
      };
    }

    return null;
  }

  getSubmitHandler = (handleSubmitAsync) => {
    const submitHandler = async (values) => {
      try {
        await handleSubmitAsync(values);
      } catch (error) {
        return error;
      }
      return null;
    };
    return submitHandler;
  }

  getPlaceholder = (placeholder: string, value: string) => {
    const { isEditMode } = this.state;

    return isEditMode ? placeholder : !value && '-';
  }

  render() {
    const {
      message,
      position,
      actions,
      updateData,
      resetUpdateState,
    } = this.props;

    const { isEditMode } = this.state;

    const canEdit = checkAcl(
      this.props.position.acl,
      [
        'position:update:fullname',
        'position:update:email',
        'position:update:phone_number',
        'position:update:password',
        'position:update:signature',
        'position:update:title',
        'position:update:language',
      ],
      { match: 'any' },
    );

    const infoPairs = [
      {
        label: <Message
          id="User ID"
          comment="ID label for the details footer."
        />,
        value: position.id,
      },
      {
        label: <Message
          id="Creation date"
          comment="Creation date label for the details footer"
        />,
        value: <LocalizedDateTime datetime={position.createdTime} />,
      },
      {
        label: <Message
          id="User type"
          comment="User type label for the details footer"
        />,
        value: <UserRole userRole={position.userRole} withTooltip />,
      },
      {
        label: <Message
          id="Two-step authentication"
          comment="Two-step authentication label for the details footer"
        />,
        value: <UserSecurityStatus position={position} />,
      },
    ];

    const checkChangedEmail = (newEmail: string) => {
      const emailChanged = position.email !== newEmail;

      if (newEmail && emailChanged) {
        this.setState({
          pendingEmail: newEmail,
          isEditMode: false,
        });
      }
    };

    return (
      <>
        <MakeAsyncFunction
          listener={promiseListener}
          start={actions.start}
          resolve={actions.success}
          reject={actions.fail}
          setPayload={(action, payload) => ({
            ...action,
            ...updateData(payload),
          })}
          getError={(action) => this.parseSubmitError(action.error)}
        >
          {(handleSubmitAsync) => (
            <Form
              initialValues={this.getInitialValues()}
              onSubmit={this.getSubmitHandler(handleSubmitAsync)}
              render={({ handleSubmit, ...formProps }) => (
                <form onSubmit={(values) => {
                  handleSubmit(values);
                  checkChangedEmail(formProps.values.email);
                  formProps.form.change('email', position.email);
                }}
                >
                  <EditableForm.Header>
                    <EditableForm.Actions
                      resetFormState={() => resetUpdateState({ positionId: this.props.positionId })}
                      formState={formProps}
                      isEditMode={isEditMode}
                      disabled={!canEdit}
                      setIsEditMode={this.setIsEditMode}
                    />
                  </EditableForm.Header>
                  <EditableForm.Body>
                    <h2 className={style.Header}>
                      <Message
                        id="User details"
                        comment="Used as the header title of the user details section in the profile page"
                      />
                    </h2>

                    <div className={style.Row}>
                      <EditableForm.Label>
                        <Message
                          id="Full name"
                          comment="Used as the label of the relevant field in the details form of the profile page"
                        />
                      </EditableForm.Label>
                      <Field
                        name="fullname"
                        component={TextField}
                        placeholder={message({
                          id: 'Enter full name',
                          comment: 'Used as the placeholder of the relevant field in the details form of the profile page',
                        })}
                        maxLength={100}
                        required
                        autoFocus
                        disabled={!isEditMode || !checkAcl(position.acl, 'position:update:fullname')}
                        label={message({
                          id: 'Full name',
                          comment: 'Used as the label of the relevant field in the details form of the profile page',
                        })}
                        hideLabel
                      />
                    </div>
                    <div className={clsx(style.Row, style.EmailRow)}>
                      <div className={style.EmailField}>
                        <EditableForm.Label>
                          <Message
                            id="Email address"
                            comment="Used as the label of the relevant field in the details form of the profile page"
                          />
                        </EditableForm.Label>
                        <Field
                          name="email"
                          component={TextField}
                          placeholder={message({
                            id: 'Enter email',
                            comment: 'Used as the placeholder of the relevant field in the details form of the profile page',
                          })}
                          maxLength={70}
                          required
                          label={message({
                            id: 'Email address',
                            comment: 'Used as the label of the relevant field in the details form of the profile page',
                          })}
                          hideLabel
                          disabled={!isEditMode || !checkAcl(position.acl, 'position:update:email')}
                          unique={{
                            text: emailValidationText,
                            param: 'email',
                            request: this.uniquePositionEmail,
                            initialValue: position.email,
                          }}
                          email
                        />
                      </div>
                      {this.state.pendingEmail && (
                        <ChangePending />
                      )}
                    </div>
                    {this.state.pendingEmail && (
                      <EmailChangeNotification email={this.state.pendingEmail} />
                    )}

                    <FormSpy subscription={{ dirtyFields: true }}>
                      {({ dirtyFields }) => {
                        if (dirtyFields.email) {
                          return (
                            <div className={style.Row}>
                              <EditableForm.Label>
                                <Message
                                  id="Current password"
                                  comment="Used as the label of the relevant field in the details form of the profile page"
                                />
                              </EditableForm.Label>
                              <Field
                                name="passwordVerification"
                                component={PasswordField}
                                placeholder={message({
                                  id: 'Enter current password',
                                  comment: 'Used as the placeholder of the relevant field in the details form of the profile page',
                                })}
                                displayErrorEarly
                                required
                                label={message({
                                  id: 'Current password',
                                  comment: 'Used as the label of the relevant field in the details form of the profile page',
                                })}
                                hideLabel
                                disabled={!isEditMode}
                              />
                            </div>
                          );
                        }
                        return null;
                      }}
                    </FormSpy>

                    <div className={style.Row}>
                      <EditableForm.Label>
                        <Message
                          id="Title"
                          comment="Used as the label of the relevant field in the details form of the profile page"
                        />
                      </EditableForm.Label>
                      <Field
                        name="title"
                        component={TextField}
                        maxLength={100}
                        disabled={!isEditMode || !checkAcl(position.acl, 'position:update:title')}
                        placeholder={this.getPlaceholder(
                          message({
                            id: 'Enter title',
                            comment: 'Used as the placeholder of the relevant field in the details form of the profile page',
                          }),
                          position.title,
                        )}
                        value={position.title || '-'}
                      />
                    </div>

                    <div className={style.Row}>
                      <EditableForm.Label>
                        <Message
                          id="Phone number"
                          comment="Used as the label of the relevant field in the details form of the profile page"
                        />
                      </EditableForm.Label>
                      <Field
                        name="phoneNumber"
                        component={TextField}
                        maxLength={50}
                        disabled={!isEditMode || !checkAcl(position.acl, 'position:update:phone_number')}
                        placeholder={
                          this.getPlaceholder(
                            message({
                              id: 'Enter phone number',
                              comment: 'Used as the placeholder of the relevant field in the details form of the profile page',
                            }),
                            position.phoneNumber,
                          )
                        }
                        value={position.phoneNumber || '-'}
                      />
                    </div>

                    <div className={style.Row}>
                      <EditableForm.Label>
                        <Message
                          id="Signature"
                          comment="Used as the label of the relevant field in the details form of the profile page"
                        />
                      </EditableForm.Label>
                      <Field
                        name="signature"
                        component={TextArea}
                        maxLength={500}
                        disabled={!isEditMode || !checkAcl(position.acl, 'position:update:signature')}
                        placeholder={this.getPlaceholder(
                          message({
                            id: 'Enter signature',
                            comment: 'Used as the placeholder of the relevant field in the details form of the profile page',
                          }),
                          position.signature,
                        )}
                        value={position.signature || '-'}
                      />
                    </div>

                    <div className={style.Row}>
                      <EditableForm.Label>
                        <Message
                          id="Language"
                          comment="Used as the label of the relevant field in the details form of the profile page"
                        />
                      </EditableForm.Label>
                      <Field
                        name="language"
                        component={SelectField}
                        options={getEnabledLanguagesAsOptions()}
                        clearable={false}
                        searchable={false}
                        disabled={!isEditMode || !checkAcl(position.acl, 'position:update:language')}
                        placeholder={message({
                          id: 'Select language',
                          comment: 'Used as the placeholder of the relevant field in the application settings form of the profile page',
                        })}
                        value={<EnabledLanguage lang={position.language} />}
                      />
                    </div>

                    {!isEditMode && (
                      <div className={style.Row}>
                        <EditableForm.Label>
                          <Message
                            id="Password"
                            comment="Used as the label of the relevant field in the details form of the profile page"
                          />
                        </EditableForm.Label>
                        <div className={style.ChangePassword}>
                          <ChangePasswordModal />
                        </div>
                      </div>
                    )}

                  </EditableForm.Body>
                </form>
              )}
            />
          )}
        </MakeAsyncFunction>
        <DetailsFooter infoPairs={infoPairs} />
      </>
    );
  }
}

export default adminPage(({ props: { message } }) => ({
  title: message({
    id: 'Profile',
    comment: 'Used as the title of the profile page',
  }),
  modules: [[]],
}))(ProfilePage);
