// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import RedoIcon from 'components/icons/redo';
import UndoIcon from 'components/icons/undo';
import ToolbarButton from './toolbar-button';

type Props = {
  action: 'undo' | 'redo',
  disabled: boolean,
};

const HistoryButton = ({
  action,
  disabled,
}: Props) => {
  const editor = useSlate();

  const onTrigger = React.useCallback((e) => {
    e.preventDefault();

    if (action === 'undo') {
      editor.undo();
      return;
    }

    editor.redo();
  }, [editor, action]);

  let icon = <UndoIcon />;
  let hasHistory = editor?.history?.undos?.length;
  let label = (
    <Message
      id="Undo"
      comment="This is the label for the undo button in the toolbar."
    />
  );
  if (action === 'redo') {
    icon = <RedoIcon />;
    hasHistory = editor?.history?.redos?.length;
    label = (
      <Message
        id="Redo"
        comment="This is the label for the redo button in the toolbar."
      />
    );
  }

  return (
    <ToolbarButton
      isMenuItem={false}
      disabled={disabled || !hasHistory}
      label={label}
      onTrigger={onTrigger}
      icon={icon}
    />
  );
};

export default HistoryButton;
