// @flow
import * as React from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';

const options = (message) => ([
  {
    value: 'last',
    label: message({
      id: 'My turn to sign',
      comment: 'Quick filter label. Used to filter out contracts that are waiting for my signature',
    }),
  },
  {
    value: 'all',
    label: message({
      id: 'I can sign',
      comment: 'Quick filter label. Used to filter out contracts that are waiting for my signature',
    }),
  },
]);

type FilterOption = {
  value: string,
  label: React.Node,
};

type GetOptions = (message: MessageTranslator, filterables: Array<string>) => Array<FilterOption>;

const signOrderFilterOptions: GetOptions = (message, filterables) => options(message)
  .filter((option) => filterables.includes(option.value));

export default signOrderFilterOptions;
