// @flow

import React from 'react';
import extensionSettings from 'hocs/extension-settings';

export const LimePage = () => (
  <></>
);

export default extensionSettings(LimePage);
