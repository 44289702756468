import getFontAssetsDomain from './get-font-asset-domain.js';

const loadJost = () => {
  const normalJost = new FontFace(
    'Jost',
    `url(${getFontAssetsDomain()}/resources/fonts/jost/jost_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicJost = new FontFace(
    'Jost',
    `url(${getFontAssetsDomain()}/resources/fonts/jost/jost_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldJost = new FontFace(
    'Jost',
    `url(${getFontAssetsDomain()}/resources/fonts/jost/jost_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicJost = new FontFace(
    'Jost',
    `url(${getFontAssetsDomain()}/resources/fonts/jost/jost_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalJost, italicJost, boldJost, boldItalicJost];
};

export default loadJost;
