import { Message } from '@oneflowab/pomes';

import { hasDraftApprovers } from 'agreement';
import useAgreement from 'hooks/use-agreement';

import Modal from 'components/modal';
import Button from 'components/button';
// eslint-disable-next-line import/named
import { CancelButton } from 'components/buttons';
import WarningIcon from 'components/icons/warning';

import style from './reset-approvals-signatures.module.scss';

export type Props = {
  agreementId: Oneflow.Agreement['id'];
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const ResetApprovalsSignaturesWarning = ({
  agreementId,
  isOpen,
  onCancel,
  onConfirm,
}: Props) => {
  const agreement = useAgreement(agreementId);

  return (
    <Modal
      header={(
        <Message
          id="Save and reset"
          comment="Modal title for save and reset approvals warning modal."
        />
      )}
      isOpen={isOpen}
      onCancel={onCancel}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc
      modalKey="reset approvals and signatures modal"
    >
      <Modal.Body>
        <div className={style.BodyContainer}>
          <WarningIcon className={style.WarningIcon} />
          <div className={style.BodyContainerText}>
            <b>
              <Message
                id="Saving the changes will reset all existing approvals and signatures."
                comment="Modal body text for save and reset approvals and signatures warning modal."
              />
            </b>
            <p>
              <Message
                id="The document will need to be approved again."
                comment="Modal body text for save and reset approvals and signatures warning modal."
              />
            </p>
            {hasDraftApprovers(agreement) && (
              <p>
                <Message
                  id="(Internal approvals will not be reset)"
                  comment="Modal body text for save and reset approvals and signatures warning modal."
                />
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <div className={style.CancelButton}>
          <CancelButton
            onClick={onCancel}
            modalKey="reset approvals modal"
          />
        </div>
        <Button
          onClick={onConfirm}
          kind="primary"
        >
          <Message
            id="Save and reset"
            comment="Button text in the save and reset approvals warning modal."
          />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ResetApprovalsSignaturesWarning;
