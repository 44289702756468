import {
  createContext,
  useContext,
  useMemo,
} from 'react';
import type { FC, ReactNode } from 'react';
import type { Localization } from 'localization';
import type { RequireAtLeastOne } from 'types/utils';

type LocalizationContextProps = {
  primary: Localization,
  secondary: Localization | null,
};

const defaultDataFormat = 'YYYY-MM-DD';
export const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const defaultLocalization: LocalizationContextProps = {
  primary: {
    dateFormat: defaultDataFormat,
    timezone: browserTimezone,
  },
  secondary: null,
};

const LocalizationContext = createContext<LocalizationContextProps>(defaultLocalization);

export const useLocalization = (): LocalizationContextProps => {
  const contextValue = useContext(LocalizationContext);

  if (!contextValue) {
    return defaultLocalization;
  }

  return contextValue;
};

type LocalizationProviderProps = RequireAtLeastOne<{
  timezone: string;
  dateFormat: string;
}> & {
  children: ReactNode;
};

export const LocalizationProvider: FC<LocalizationProviderProps> = ({
  timezone,
  dateFormat,
  children,
}) => {
  const { primary: parentPrimary } = useLocalization();
  const localization = useMemo<LocalizationContextProps>(() => {
    const primary = {
      dateFormat: dateFormat || parentPrimary.dateFormat,
      timezone: timezone || parentPrimary.timezone,
    };

    return ({
      primary,
      secondary: parentPrimary,
    });
  }, [dateFormat, parentPrimary, timezone]);

  return (
    <LocalizationContext.Provider value={localization}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const usePrimaryLocalization = (): Localization => {
  const { primary } = useLocalization();

  return primary;
};

export const useSecondaryLocalization = (): Localization | null => {
  const { secondary } = useLocalization();

  return secondary;
};
