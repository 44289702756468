import * as React from 'react';
import type { ReactNode } from 'react';
import clsx from 'clsx';

import Button from 'components/button';
import { BetaLabel } from 'components/beta-label';
import type { IconProps } from 'types/svg-icon-props';
import type { Trackable } from 'types/trackable';

import style from './menu-item.module.scss';

type Props = {
  icon?: React.ComponentType<IconProps>,
  label: ReactNode,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  className?: string,
  disabled?: boolean,
  tooltip?: string,
  href?: string,
  external?: boolean,
  download?: string,
  target?: string,
  buttonId?: string,
  rel?: string,
  children?: ReactNode,
  trackable?: string | Trackable,
  isBeta?: boolean,
};

export const MenuItem = ({
  icon,
  label,
  onClick,
  onMouseDown,
  className,
  disabled,
  tooltip,
  href,
  external,
  target,
  download,
  buttonId,
  rel,
  children,
  trackable,
  isBeta,
}: Props) => (
  <li className={clsx(
    style.MenuItem,
    className, {
      [style.MenuItemActive]: !disabled,
      [style.Disabled]: disabled,
      [style.Beta]: isBeta,
    },
  )}
  >
    {
      children ?? (
        <Button
          customClass={style.Item}
          onClick={onClick}
          onMouseDown={onMouseDown}
          icon={icon}
          disabled={disabled}
          tooltip={tooltip}
          href={href}
          external={external}
          target={target}
          download={download}
          buttonId={buttonId}
          rel={rel}
          trackable={trackable}
        >
          {label}
        </Button>
      )
    }
    {isBeta && <BetaLabel />}
  </li>
);
