import type { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import style from './bulk-actions.module.scss';

type Props = {
  actions: Array<ReactNode>;
  count: number;
  visible?: boolean;
  topSpacing?: boolean;
};

const BulkActions = ({
  actions,
  count,
  visible = false,
  topSpacing,
}: Props) => {
  if (!visible) {
    return null;
  }

  let bulkActions = null;

  if (count) {
    bulkActions = actions;
  }

  const bulkActionsClassNames = clsx(style.BulkActions, {
    [style.Spacing]: topSpacing,
  });

  return (
    <div className={bulkActionsClassNames}>
      <Message
        id="1 selected"
        pluralId="{count} selected"
        pluralCondition="count"
        values={{ count }}
        comment="A message to show the current selected list"
      />
      <div className={style.Actions}>
        {bulkActions}
      </div>
    </div>
  );
};

export default BulkActions;
