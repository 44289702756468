// @flow

import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import type { MessageTranslator } from '@oneflowab/pomes';
import { localize } from '@oneflowab/pomes';
import { orderBy } from 'natural-orderby';

import { getContractMessageTemplates } from 'oneflow-client/message-templates';

import { getGuestToken } from 'agreement/selectors';
import { getStandardInviteMessageTemplate } from 'agreement/message-templates';

import { ExpandableOptionsHeader } from 'components/expandable-options-header';
import MessageTemplatePicker from 'components/message-template-picker';

import style from './message-section.module.scss';

type Props = {
  message: MessageTranslator,
  agreement: Agreement,
  position: Position,
  updateSelectedMessageTemplate: Function,
  getMessageBody: Function,
  selectedMessageTemplate: MessageTemplate,
}

export const MessageSection = ({
  message,
  agreement,
  position,
  updateSelectedMessageTemplate,
  selectedMessageTemplate,
  getMessageBody,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoadingMessageTemplates, setIsLoadingMessageTemplates] = useState(false);
  const [hasLoadedMessages, setHasLoadedMessages] = useState(false);
  const [contractMessageTemplates, setContractMessageTemplates] = useState([]);
  const [error, setError] = useState(null);
  const standardMessageTemplate = getStandardInviteMessageTemplate(message, position);

  const fetchMessageTemplates = useCallback(async () => {
    setIsLoadingMessageTemplates(true);
    try {
      const { collection: contractMessageTemplatesData } = await getContractMessageTemplates({
        contractId: agreement.id,
        type: 'publish',
      });
      const orderedContractMessages = orderBy(
        contractMessageTemplatesData,
        [(v) => v.name],
        ['asc'],
      );

      setContractMessageTemplates([standardMessageTemplate, ...orderedContractMessages]);
      setIsLoadingMessageTemplates(false);
      setHasLoadedMessages(true);
    } catch (e) {
      setContractMessageTemplates([]);
      setError(e);
      setIsLoadingMessageTemplates(false);
      setHasLoadedMessages(false);
    }
  }, []);

  const guestToken = useSelector((state) => getGuestToken(state));
  const isGuest = Boolean(guestToken);

  useEffect(() => {
    if (!hasLoadedMessages && isExpanded && !isGuest) {
      fetchMessageTemplates();
    }
  }, [hasLoadedMessages, isExpanded, fetchMessageTemplates, isGuest]);

  const toggleArea = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
  };
  const areaClassNames = clsx(style.MessageArea, {
    [style.Hidden]: !isExpanded,
  });

  const renderMessageTemplatePicker = () => {
    if (!isLoadingMessageTemplates && !error) {
      return (
        <MessageTemplatePicker
          contractMessageTemplates={contractMessageTemplates}
          selectedMessageTemplate={selectedMessageTemplate}
          updateSelectedMessageTemplate={updateSelectedMessageTemplate}
          isSubjectFieldRequired
          isMessageFieldRequired
          getMessageBody={getMessageBody}
          checkForAgreementOwner
          agreement={agreement}
          context="agreementParticipantCreate"
        />
      );
    }

    return null;
  };

  return (
    <div className={style.ExpandableArea}>
      <ExpandableOptionsHeader
        title={message({
          id: 'Message',
          comment: 'Used as the header of the section in the counterparty modal.',
        })}
        onClick={toggleArea}
        isExpanded={isExpanded}
        isExpandable
        customClass={style.SectionHeader}
      />
      <div className={areaClassNames}>
        {renderMessageTemplatePicker()}
      </div>
    </div>
  );
};

export default localize<Props>(MessageSection);
