import { ReactNode } from 'react';
import type { FormRenderProps } from 'react-final-form';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import { ModalForm } from 'hocs/modal-form/modal-form-v2';

import style from './create-document-from-template.module.scss';

type FormState = {
  data: object,
  error: object | undefined,
  loading: boolean,
  pristine: boolean,
  result: number[] | undefined,
  success: boolean,
};

type Props = {
  children: ReactNode,
  documentName?: string,
  resetFormState: () => void,
  createDocument: (name: string) => void,
  formState: FormState,
  message: MessageTranslator
}

const CreateDocumentFromTemplateModal = ({
  children,
  documentName,
  resetFormState,
  formState,
  createDocument,
  message,
}: Props) => {
  const getModalBody = () => (
    <>
      <div className={style.Title}>
        <Message
          id="Set document name"
          comment="Title of the action in the modal."
        />
      </div>
      <div className={style.ModalContent}>
        <Field
          name="name"
          label={(
            <Message
              id="Document name"
              comment="Label for the document name input field."
            />
          )}
          placeholder={message({
            id: 'Untitled document',
            comment: 'Placeholder for the document name input field.',
          })}
          component={TextField}
          maxLength={130}
          autoFocus
        />
        <span className={style.FieldDescription}>
          <Message
            id="Document names are not visible to counterparties."
            comment="Informational message about document names."
          />
        </span>
      </div>
    </>
  );

  const getActions = ({ formProps, closeConfirmation }: {
    formProps: FormRenderProps,
    closeConfirmation: () => void
  }) => {
    const isLoading = formState.loading;

    const isDisabled = (
      formProps.hasValidationErrors
      || formProps.validating
      || isLoading);

    return (
      <>
        <CancelButton onClick={closeConfirmation} />
        <Button
          type="submit"
          onClick={formProps.handleSubmit}
          kind="primary"
          disabled={isDisabled}
        >
          <Message
            id="Create document"
            comment="Button text to create a document."
          />
        </Button>
      </>
    );
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Use template to create document"
          comment="Modal title for creating a document out of the template."
        />
      )}
      body={getModalBody()}
      onSubmit={({ name }: { name: string }) => createDocument(name)}
      resetFormState={resetFormState}
      formState={formState}
      initialValues={{
        name: documentName,
      }}
      modalKey="create document from template modal"
      actions={getActions}
      allowPristine
      customModalClass={style.Modal}
    >
      {children}
    </ModalForm>
  );
};

export default localize<Props>(CreateDocumentFromTemplateModal);
