import { useSelector } from 'react-redux';
import { getCurrentContractData } from 'reducers/current-contract';

const useBoxIdMap = () => {
  const currentContractData = useSelector(getCurrentContractData);

  if (!currentContractData) {
    return {
      idMap: {},
    };
  }

  return currentContractData.idMap;
};

export default useBoxIdMap;
