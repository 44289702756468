// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import { timestampToDateString } from 'date';

import Dashboard from 'components/icons/dashboard';

import style from './dashboard-no-activity.module.scss';

type Props = {
  fromDate: number,
  toDate: number,
  dateFormat: string,
}

export const NoActivity = (props: Props) => {
  const {
    fromDate,
    toDate,
    dateFormat,
  } = props;

  return (
    <React.Fragment>
      <div className={style.NoActivityIconContainer}>
        <Dashboard height="33" />
      </div>
      <div className={style.NoActivityHeader}>
        <Message
          id="There are no statistics to be shown for this period"
          comment="Empty dashboard information text"
        />
      </div>
      <div className={style.NoActivityContent}>
        <Message
          id="No activity in the time between {from} and {to}."
          values={{
            from: timestampToDateString(fromDate, dateFormat),
            to: timestampToDateString(toDate, dateFormat),
          }}
          comment="Empty dashboard information text"
        />
      </div>
    </React.Fragment>
  );
};
