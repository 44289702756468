// @flow

import mapDelayedEvents from 'components/lifecycle-calendar/map-delayed-events';
import isPastEvent from './is-past-event';

function getMyInternalReminders(events: Array<any>, currentUser: Position) {
  return mapDelayedEvents(events)
    .filter(
      (event) => event?.sourceEvent?.customReminderData?.recipients?.find(
        (recipientId) => recipientId === currentUser.id,
      ),
    ).map((event) => ({
      ...event,
      sourceEvent: {
        ...event.sourceEvent,
        isEditable: event?.sourceEvent?.customReminderData?.createdBy === currentUser.id
          && !isPastEvent(event?.sourceEvent?.date)
          && Object.keys(event?.sourceEvent?.agreement).length > 1,
      },
    }));
}

export default getMyInternalReminders;
