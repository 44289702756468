// @flow

import React from 'react';
import { Element, Transforms } from 'slate';
import {
  ReactEditor,
  useSlate,
  useSelected,
  useFocused,
} from 'slate-react';
import { Resizable } from 're-resizable';
import clsx from 'clsx';

import useFocusedEditor from 'hooks/rich-text-editor/use-focused-editor';
import { useRichTextProps } from 'contexts/rich-text';
import { getActiveImage } from 'components/rich-text-editor/editor-plugins/image-utils';
import ImageToolbar from 'components/rich-text-editor-toolbars/image-toolbar';
import ContractImage from './contract-image';
import style from './image.module.scss';

export const HandleResizeComponent = (
  { focused, direction, selected }: { focused: boolean, direction: string, selected: boolean },
) => {
  const className = clsx(
    style[`HandleResizeComponent_${direction}`],
    {
      [style.focused]: focused && selected,
    },
  );
  return (
    <div className={style.HandleResizeComponentWrapper}>
      <div className={className} />
    </div>
  );
};

export const HandleResizeCornerComponent = (
  { focused, selected }: { focused: boolean, selected: boolean },
) => {
  const className = clsx(
    style.HandleResizeCornerComponent,
    {
      [style.focused]: focused && selected,
    },
  );
  return (
    <div className={style.HandleResizeCornerComponentWrapper}>
      <div className={className} />
    </div>
  );
};

const getWrapTextClassName = (element: Element, editable: boolean = false) => (
  clsx(element.className, {
    [style.ImageWithToolbarContainer]: editable,
    [style.ImageWrapTextLeft]: element.wrapText === 'left',
    [style.ImageWrapTextRight]: element.wrapText === 'right',
  })
);

export const EditableImage = ({ element, attributes, children }: RenderSlateElementProps) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();
  const focusedEditor = useFocusedEditor();
  const [editorElement, setEditorElement] = React.useState(null);
  const activeImage = getActiveImage(editor);
  const imageRef = React.useRef(null);
  const refWidth = React.useRef(null);
  const refHeight = React.useRef(null);

  // Need to use useEffect for editor nodes to be first rendered and painted to browser
  // So that we can get the DOM element of the editor node
  React.useEffect(() => {
    setEditorElement(ReactEditor.toDOMNode(editor, editor));
  }, [editor]);

  let toolbar = null;
  if (editor === focusedEditor && activeImage === element) {
    toolbar = (
      <ImageToolbar />
    );
  }

  return (
    <div
      className={getWrapTextClassName(element, true)}
      style={{
        lineHeight: 0,
        display: 'inline-block',
        margin: 0,
        padding: 0,
        ...element.style,
      }}
      {...attributes}
    >
      {toolbar}
      <Resizable
        style={{
          lineHeight: 0,
          display: 'inline-block',
        }}
        lockAspectRatio
        size={{
          width: 'auto',
          height: 'auto',
        }}
        className={element.className}
        onResizeStart={() => {
          if (!imageRef.current) {
            return;
          }

          refWidth.current = imageRef.current.clientWidth;
          refHeight.current = imageRef.current.clientHeight;
        }}
        onResizeStop={(e, direction, ref, delta) => {
          const newWidth = refWidth.current + Number(delta.width);
          const newHeight = refHeight.current + Number(delta.height);

          if (newWidth <= 0 || newHeight <= 0) {
            return;
          }

          Transforms.setNodes(editor, {
            width: newWidth,
            height: newHeight,
          }, {
            at: ReactEditor.findPath(editor, element),
          });
        }}
        boundsByDirection
        bounds={editorElement}
        handleClasses={{
          top: style.ImageResizeHandleTop,
          right: style.ImageResizeHandleRight,
          bottom: style.ImageResizeHandleBottom,
          left: style.ImageResizeHandleLeft,
          topRight: style.ImageResizeHandleTopRight,
          bottomRight: style.ImageResizeHandleBottomRight,
          bottomLeft: style.ImageResizeHandleBottomLeft,
          topLeft: style.ImageResizeHandleTopLeft,
        }}
        handleComponent={{
          top: <HandleResizeComponent direction="horizontal" focused={focused} selected={selected} />,
          right: <HandleResizeComponent direction="vertical" focused={focused} selected={selected} />,
          bottom: <HandleResizeComponent direction="horizontal" focused={focused} selected={selected} />,
          left: <HandleResizeComponent direction="vertical" focused={focused} selected={selected} />,
          topRight: <HandleResizeCornerComponent focused={focused} selected={selected} />,
          bottomRight: <HandleResizeCornerComponent focused={focused} selected={selected} />,
          bottomLeft: <HandleResizeCornerComponent focused={focused} selected={selected} />,
          topLeft: <HandleResizeCornerComponent focused={focused} selected={selected} />,
        }}
      >
        <ContractImage
          imageRef={imageRef}
          element={element}
          draggable
        />
      </Resizable>
      {children}
    </div>
  );
};

const breakTextWrapper = (
  el: React.Node,
  breakText?: 'left' | 'center' | 'right',
  attributes: any,
) => {
  if (!breakText) {
    return el;
  }

  const breakTextClassName = clsx(style.ImageBreakText, {
    [style.ImageBreakTextLeft]: breakText === 'left',
    [style.ImageBreakTextRight]: breakText === 'right',
    [style.ImageBreakTextCenter]: breakText === 'center',
  });

  return (
    <div
      className={breakTextClassName}
      {...attributes}
    >
      {el}
    </div>
  );
};

const Image = ({
  element,
  attributes,
  children,
  ...rest
}: RenderSlateElementProps) => {
  const { isReadOnly } = useRichTextProps();
  const { breakText } = element;
  const imageAttributes = breakText ? {} : attributes;

  if (!isReadOnly) {
    return breakTextWrapper((
      <EditableImage
        element={element}
        attributes={imageAttributes}
        {...rest}
      >
        {children}
      </EditableImage>
    ), breakText, attributes);
  }

  return breakTextWrapper((
    <div
      className={getWrapTextClassName(element)}
      style={{
        lineHeight: 0,
        display: 'inline-block',
        margin: 0,
        padding: 0,
        ...element.style,
      }}
      {...imageAttributes} // includes contenteditable=false
    >
      <ContractImage element={element} draggable={false} />
      {children}
    </div>
  ), breakText, attributes);
};

export default Image;
