import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import { entries } from 'lodash';

import Tooltip from 'components/tooltip';
import agreementsReducer from 'reducers/entities/agreements';

import { MoveContractBulk } from 'components/modals/move-contract-bulk/move-contract-bulk';
import { hasDocumentMovePermission } from 'components/modals/move-contract-bulk/steps/move-contract-step/helpers';
import { useSelectionContext } from 'components/contract-list/selection-context';
import MoveIcon from 'components/icons/move-to';

import style from './bulk-move-action.module.scss';

const BulkMoveAction = ({ queryName }: { queryName: string }) => {
  const { selectedDocuments } = useSelectionContext();
  const selectedDocumentsData = useSelector((state) => (
    agreementsReducer.getAgreementsSelector(state, {
      ids: entries(selectedDocuments)
        .filter(([_, value]) => value)
        .map(([key]) => Number(key)),
    })
  ));

  const documentsThatCanNotBeMoved = selectedDocumentsData.filter((document) => (
    !hasDocumentMovePermission(document)
  ));

  const isDisabled = documentsThatCanNotBeMoved.length === selectedDocumentsData.length;

  return (
    <MoveContractBulk
      queryName={queryName}
    >
      {(openModal) => (
        <Tooltip
          message={(
            <Message
              id="Permission is required."
              comment="Label for the move to button"
            />
          )}
          side="top"
          hideContent={!isDisabled}
        >
          <button
            onClick={openModal}
            className={style.MoveButton}
            disabled={isDisabled}
          >
            <MoveIcon />
            <Message
              id="Move to"
              comment="Label for the move to button"
            />
          </button>
        </Tooltip>
      )}
    </MoveContractBulk>
  );
};

export default BulkMoveAction;
