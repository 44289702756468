import { ProductTableBoxContextProvider } from 'contexts/product-table-box-context';

import { ProductTableBoxContainer } from 'components/contract-boxes/product-table-box/product-table-box-container';

const ProductTableBox = ({
  boxId,
  permissions,
  agreementId,
  onRemoveBox,
  onAddSectionRules,
}: ContractView.BoxItemProps) => (
  <ProductTableBoxContextProvider
    boxId={boxId}
    agreementId={agreementId}
    permissions={permissions}
  >
    <ProductTableBoxContainer
      onRemoveBox={onRemoveBox}
      onAddSectionRules={onAddSectionRules}
      agreementId={agreementId}
    />
  </ProductTableBoxContextProvider>
);

export default ProductTableBox;
