/* eslint-disable react/prop-types */

import React from 'react';

import Delete from 'components/icons/delete';

const DeleteRowButton = ({ className, onClick }) => (
  <div
    className={className}
    onClick={onClick}
    onKeyDown={() => null}
    role="button"
    tabIndex={0}
  >
    <Delete height="12px" />
  </div>
);

export default DeleteRowButton;
