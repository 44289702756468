// @flow

import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Message, localize } from '@oneflowab/pomes';
import clsx from 'clsx';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Confirmable from 'components/confirmable';
import {
  aiAssistStreamResponse,
} from 'oneflow-client/ai-assist';
import useCurrentContractId from 'hooks/use-current-contract-id';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getPositionFromSessionSelector } from 'reducers/session';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

import MagicDust from './magic-dust.gif';
import AIAssistActions from './ai-assist-actions';
import AIAssistFooter from './ai-assist-footer';
import AIAssistHeader from './ai-assist-header';
import AIAssistTypewriter from './ai-assist-typewriter';
import AIAssistSuggestion from './ai-assist-suggestion';
import style from './ai-assist.module.scss';
import getSuggestionMessages from './suggestion-messages';
import getInputPlaceholders from './input-placeholders';

const modalKey = 'generate AIAssist text modal';
const MAX_LENGTH_OF_PROMPT = 450;

type Props = {
  message: any,
  isOpen: boolean,
  onInsertGeneratedNodes: any,
  onInsertGeneratedText: any,
  onModalClose: any,
  children: React.Node,
  setModalOpen: any,
  insertIntoAField?: boolean,
  context?: string,
};

const fetchResults = async (
  response,
  onData,
  onSuccess,
  onFailure,
) => {
  try {
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let chunk = '';

    const processNextChunk = async () => {
      const { done, value } = await reader.read();

      if (done) return;

      chunk += decoder.decode(value, { stream: true });

      let startIndex = 0;
      let index;
      while (chunk.indexOf('\n\n', startIndex) !== -1) {
        index = chunk.indexOf('\n\n', startIndex);
        const messageText = chunk.slice(startIndex, index);
        const rawData = JSON.parse(messageText.substring('data: '.length));
        // Client only supports a single choice at the moment,
        // but change this if we wish to support multiple
        const [data] = rawData.choices;

        if (data.finish_reason === 'stop') return;

        onData(data.delta);
        startIndex = index + 2;
      }
      chunk = chunk.slice(startIndex);

      // Recursively process the next chunk
      await processNextChunk();
    };

    // Start processing the chunks
    await processNextChunk();

    onSuccess();
  } catch (e) {
    onFailure(e);
  }
};

const AIAssist = ({
  children,
  isOpen,
  setModalOpen,
  onInsertGeneratedNodes,
  onInsertGeneratedText,
  onModalClose,
  message,
  insertIntoAField,
  context,
}: Props) => {
  const contractId = useCurrentContractId();
  const [success, setSuccess] = useState(false);
  const [generatingResponse, setGeneratingResponse] = useState(false);
  const [text, setText] = useState('');
  const [hasSearched, setHasSearched] = useState(0);
  const [isInProgress, setIsInProgress] = useState(false);
  const position = useSelector(getPositionFromSessionSelector);

  const [characterCounterWarning, setCharacterCounterWarning] = useState(false);
  const [error, setError] = useState('');
  const contentRef = useRef('');
  const abortControllerRef = useRef(null);
  const [, setResultsCounter] = useState(0);
  const currentWorkspaceType = useSelector((state) => getCurrentWorkspaceSelector(state))?.type;
  const isGeneratedTextEmpty = contentRef.current.length === 0;
  const shouldGenerateButtonBeDisabled = generatingResponse
  || (!isGeneratedTextEmpty && isInProgress)
  || !text;

  const clearContentRef = () => {
    contentRef.current = '';
  };

  const handleAbortRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setGeneratingResponse(false);
    }
    setError('');
    setIsInProgress(false);
  };

  const handleClose = () => {
    clearContentRef();
    handleAbortRequest();
    setModalOpen(false);
    setSuccess(false);
    setHasSearched(0);
    setText('');

    if (onModalClose) {
      onModalClose();
    }
  };

  const onMessageChanged = (ev) => {
    if (ev.target.value.length > MAX_LENGTH_OF_PROMPT) {
      setCharacterCounterWarning(true);
      return;
    }
    setCharacterCounterWarning(false);
    setText(ev.target.value);
  };

  const renderAiAssistTypewriter = () => {
    if (error) {
      return (
        <AIAssistTypewriter
          text={contentRef?.current}
          error={error}
          insertIntoAField={insertIntoAField}
        />
      );
    }

    if (generatingResponse) {
      return (
        <div className={style.LoadingSpinner}>
          <img src={MagicDust} height="328px" width="328px" alt="oneflow avatar" />
          <p className={style.LoadingText}>
            <Message
              id="GENERATING..."
              comment="Message that will be shown during loading"
            />
          </p>
        </div>
      );
    }

    if (isGeneratedTextEmpty && hasSearched === 0 && !error) {
      return (
        <div className={style.EmptyContainer}>
          <p className={style.EmptyText}>
            <Message
              id="Try these example ideas, or ask something yourself"
              comment="Title of the ai assist suggestions"
            />
          </p>
          <div className={style.SuggestionsContainer}>
            {getSuggestionMessages(currentWorkspaceType, context).map((suggestion) => (
              <AIAssistSuggestion
                key={suggestion.props.id}
                setText={setText}
                suggestion={suggestion}
                context={context}
              />
            ))}
          </div>
        </div>
      );
    }

    return (
      <AIAssistTypewriter
        text={contentRef?.current}
        error={error}
        insertIntoAField={insertIntoAField}
      />
    );
  };

  const handleChunkedData = useCallback((delta) => {
    if (delta.content) {
      contentRef.current += delta.content;
      setResultsCounter((prevResults) => prevResults + 1); // Trigger a state update
    }
  }, []);

  const onSubmit = async (ev) => {
    ev.preventDefault();

    const controller = new AbortController();
    abortControllerRef.current = controller;

    amplitudeLogEvent(
      'Generate AI Assistance',
      {
        'assistance type': isGeneratedTextEmpty ? '[CUSTOM_PROMPT]' : '[TRY_AGAIN]',
        location: 'send contract modal',
      },
      { 'document id': contractId },
    );

    clearContentRef();
    setGeneratingResponse(true);
    setIsInProgress(true);
    setHasSearched(1);

    try {
      const response = await aiAssistStreamResponse({
        targetType: contractId ? 'agreement' : 'position',
        targetId: contractId || position.id,
        prompt: text,
        signal: abortControllerRef.current?.signal,
        context: context || 'agreementEditor',
      });
      setGeneratingResponse(false);

      fetchResults(
        response,
        (result) => {
          handleChunkedData(result);
        },
        () => { setIsInProgress(false); },
        (e) => {
          setIsInProgress(false);
          setGeneratingResponse(false);
          if (!abortControllerRef.current) {
            return;
          }
          setError(e);
        },
      );
    } catch (e) {
      setError(e);
    }
  };

  const handleInsertText = () => {
    let nodes = [];
    if (contentRef.current) {
      const splitParagraphs = contentRef.current.split('\n\n');
      nodes = splitParagraphs.map((paragraph) => ({
        type: 'paragraph',
        children: [
          {
            text: paragraph,
          },
        ],
      }));
    }

    setModalOpen(false);

    if (insertIntoAField) {
      onInsertGeneratedText(contentRef.current);
    } else {
      onInsertGeneratedNodes(nodes);
    }

    amplitudeLogEvent('Approve AI Assistance', {
      location: 'send contract modal',
    }, {
      'document id': contractId,
    });
  };

  const characterCounterClassNames = clsx(style.CharacterCount, {
    [style.Warning]: characterCounterWarning,
  });

  const renderBody = () => (
    <div
      className={clsx(style.AIAssistBody, hasSearched === 0 ? '' : style.ExtendBodyContainer)}
    >
      {renderAiAssistTypewriter()}
      <div className={style.TextAreaContainer}>
        <TextareaAutosize
          className={style.TextArea}
          name="prompt"
          placeholder={getInputPlaceholders(context, message)}
          onChange={onMessageChanged}
          value={text}
          autoFocus
        />
        <span className={characterCounterClassNames}>
          {text.length}
          /
          {MAX_LENGTH_OF_PROMPT}
        </span>
      </div>
    </div>
  );

  const renderHeader = () => (
    <AIAssistHeader
      hasSearched={hasSearched}
      generatingResponse={generatingResponse}
      handleClose={handleClose}
    />
  );

  const renderActions = () => (
    <AIAssistActions
      shouldGenerateButtonBeDisabled={shouldGenerateButtonBeDisabled}
      handleInsertText={handleInsertText}
      handleClose={handleClose}
      isGeneratedTextEmpty={isGeneratedTextEmpty}
      isInProgress={isInProgress}
      error={error}
      handleAbortRequest={handleAbortRequest}
      onSubmit={onSubmit}
      insertIntoAField={insertIntoAField}
    />
  );

  const renderFooter = () => (
    <AIAssistFooter renderActions={renderActions} />
  );

  return (
    <Confirmable
      data-testid="oneflowai"
      body={renderBody()}
      header={renderHeader}
      isOpen={isOpen}
      success={success}
      onEnter={() => {}}
      onConfirm={handleClose}
      onClose={handleClose}
      modalKey={modalKey}
      portalClassName="rich-text-region"
      customModalClass={insertIntoAField ? '' : style.CustomModalClass}
      customBodyClass={style.CustomModalBodyClass}
      footer={renderFooter}
      error={error}
    >
      {children}
    </Confirmable>
  );
};

export default localize<Props>(AIAssist);
