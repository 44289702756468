// @flow

import map from 'lodash/map';
import type { MessageTranslator } from '@oneflowab/pomes';

export const emailProviders = (message: any) => ({
  postmark: message({
    id: 'Postmark (US)',
    comment: 'Email provider value',
  }),
  aws_ses_eu: message({
    id: 'AWS SES (EU)',
    comment: 'Email provider value',
  }),
});

export const getEmailProvider = (provider: string, message: MessageTranslator) => (
  emailProviders(message)[provider]
);

export const getEmailProvidersAsOptions = ({ message }) => (
  map(emailProviders(message), (provider, key: string) => ({
    label: provider,
    value: key,
  }))
);
