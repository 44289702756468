// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import { ExtensionName } from 'components/extension/extension-name';
import style from './extension-generic-instructions.module.scss';

type Props = {
  extension: Extension,
}

export const ExtensionGenericInstructions = ({ extension }: Props) => (
  <>
    <h3 className={style.ExtensionConfigurationHeader}>
      <Message
        id="Activate integration in {extensionName}"
        comment="Section header for extension instructions."
        values={{
          extensionName: <ExtensionName extension={extension} />,
        }}
      />
    </h3>
    <div className={style.ExtensionConfigurationBody}>
      <Message
        id="This extension requires that you have an account and enable the Oneflow integration in {extensionName}. If you are unsure of how to do this, contact {extensionName} support and they will help you out!"
        comment="Help text for extension instructions."
        values={{
          extensionName: <ExtensionName extension={extension} />,
        }}
      />
    </div>
  </>
);
