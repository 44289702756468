// @flow

import * as React from 'react';

import { TableRowElementProvider } from './use-table-row-element';
import { getStyle } from '../../utils';

const TableRow = ({ attributes, children, element }: RenderSlateElementProps) => {
  const style = getStyle(element);

  return (
    <tr {...attributes} style={style}>
      <TableRowElementProvider element={element}>
        {children}
      </TableRowElementProvider>
    </tr>
  );
};

export default TableRow;
