// @flow

import { connect } from 'react-redux';

import workspaceUsersReducer from 'reducers/entities/workspace-users';
import roles from 'reducers/entities/roles';
import userWorkspacesReducer from 'reducers/entities/user-workspaces';
import { USER_ROLE_SCOPE_WORKSPACE } from 'user';

import EditWorkspaceRole from './edit-workspace-role';

const defaultPagination = {
  limit: 20,
  offset: 0,
};

export const mapStateToProps = (state, { position }) => {
  const rolesQuery = roles.getQuerySelector(state, { name: 'edit-workspace-user-role' });

  return ({
    formState: workspaceUsersReducer.getUpdateSelector(state, { id: position.id }),
    roles: roles.getRolesSelector(state, { ids: rolesQuery.result }),
    rolesQuery,
  });
};

export const GROUP_ACCESS_QUERY = 'userWorkspaces/groupAccess';
export const DIRECT_ACCESS_QUERY = 'userWorkspaces/directAccess';
const WORKSPACE_USERS_ACCESS_QUERY = 'workspaceUsers/directAccess';

export const mapDispatchToProps = (
  dispatch,
  {
    workspaceId,
    bindingId,
    role,
    position,
    pipeAction,
  },
) => ({
  queryRoles: ({ name, pagination = defaultPagination }) => {
    dispatch(roles.queryRoles({
      name: 'edit-workspace-user-role',
      params: {
        scope: USER_ROLE_SCOPE_WORKSPACE,
        q: name,
      },
      pagination,
      sort: ['-type', 'name'],
    }));
  },
  queryRolesLoadMore: ({ additionalResults }) => {
    dispatch(roles.queryRolesLoadMore({
      name: 'edit-workspace-user-role',
      params: {
        scope: USER_ROLE_SCOPE_WORKSPACE,
      },
      additionalResults,
    }));
  },
  resetFormState: () => {
    dispatch(workspaceUsersReducer.updateWorkspaceUserReset({
      id: position.id,
    }));
  },
  onSubmit: (formData) => {
    dispatch(workspaceUsersReducer.updateWorkspaceUser({
      id: position.id,
      data: {
        removeData: {
          id: bindingId,
          resourceType: 'collection',
          resourceId: workspaceId,
          actorType: 'position',
          actorId: position.id,
          roleId: role.id,
        },
        createData: {
          id: position.id,
          resourceType: 'collection',
          resourceId: workspaceId,
          actorType: 'position',
          actorId: position.id,
          roleId: formData.role.id,
        },
      },
      pipe: {
        action: () => {
          if (pipeAction === WORKSPACE_USERS_ACCESS_QUERY) {
            return workspaceUsersReducer.queryWorkspaceUsers({
              name: `workspace/${workspaceId}/relationships`,
              pagination: {},
              params: {
                workspaceId,
                actorType: 'position',
              },
            });
          }

          return userWorkspacesReducer.queryUserWorkspaces({
            name: DIRECT_ACCESS_QUERY,
            pagination: {},
            params: {
              positionId: position.id,
            },
          });
        },
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditWorkspaceRole);
