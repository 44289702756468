import { useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import identity from 'lodash/identity';
import { Message } from '@oneflowab/pomes';

import {
  getDataField,
  updateDataFieldAction,
} from 'reducers/current-contract';
import type { DataField } from 'data-validators/entity-schemas/agreement-data';

import ModalForm from 'hocs/modal-form';

import {
  DataFieldDescription,
  DataFieldName,
  DataFieldPlaceholder,
  DataFieldValue as DataFieldValueComponent,
} from 'components/fields';

import { noop } from 'lodash';
import style from './edit-document-data-field.module.scss';

export type Props = {
  isInDocumentPage: boolean,
  dataFieldKey: DataField['value']['key'],
  children: ReactNode,
  focusEditor?: () => void,
  onOpen?: () => void,
  onClose?: () => void,
};

type formProps = {
  description: string,
  externalKey: string,
  placeholder: string,
  value: string,
  name: string,
}

const EditDataFieldModal = ({
  dataFieldKey,
  isInDocumentPage,
  children,
  focusEditor,
  onOpen,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const dataFieldObject = useSelector((state) => getDataField(state, dataFieldKey));
  const {
    description,
    name,
    value,
    placeholder,
    /* eslint-disable camelcase */
    external_key,
    externalKey,
  } = dataFieldObject?.value;
  const [success, setSuccess] = useState(false);

  const onSubmit = (values: formProps) => {
    const newDataFieldObject = {
      ...dataFieldObject,
      value: {
        ...dataFieldObject.value,
        ...values,
      },
    };

    dispatch(updateDataFieldAction(newDataFieldObject));

    setSuccess(true);
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Edit data field"
          comment="Modal title for editing data field."
        />
      )}
      body={(
        <>
          <div className={style.Row}>
            <DataFieldName disabled />
            <DataFieldDescription parse={identity} autoFocus />
          </div>
          <div className={style.Row}>
            <DataFieldPlaceholder parse={identity} />
            <DataFieldValueComponent isInDocumentPage={isInDocumentPage} parse={identity} />
          </div>
        </>
      )}
      initialValues={{
        name,
        description,
        placeholder,
        value,
        externalKey: external_key || externalKey,
      }}
      onSubmit={onSubmit}
      resetFormState={noop}
      formState={{ success }}
      onOpen={onOpen}
      onClose={() => {
        if (onClose) {
          onClose();
        }
        setSuccess(false);
        if (focusEditor) {
          focusEditor();
        }
      }}
      isWideModal
      modalKey="edit data field in document view"
      portalClassName={style.EditDataFieldModal}
    >
      {children}
    </ModalForm>
  );
};

export default EditDataFieldModal;
