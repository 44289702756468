import * as React from 'react';
import { localize, Message, MessageTranslator } from '@oneflowab/pomes';
import clsx from 'clsx';

import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import * as validators from 'forms/validators';
import { getPreferredCavasSizeForWidth } from 'utils/get-preferred-cavas-size';

import HandwrittenContext from './handwritten-context';

import style from './handwritten.module.scss';

export type Props = {
  maxCanvasWidth: number,
  initialSignatureText: string,
  message: MessageTranslator
};

const getCustomOptionComponent = (participantName: string) => ({
  innerProps,
  data: fontSelection,
}: CustomOptionProps) => (
  <div className={style.CustomOption} {...innerProps}>
    <p className={style.CustomOptionText} style={{ fontFamily: fontSelection.value }}>
      {participantName || fontSelection.value}
    </p>
  </div>
);

const defaultFontValue = 'Caveat';

const getOptions = (typedSignature: string) => [
  { label: typedSignature || defaultFontValue, value: defaultFontValue },
  { label: typedSignature || 'Sacramento', value: 'Sacramento' },
  { label: typedSignature || 'Whisper', value: 'Whisper' },
];

const MAX_SIGNATURE_TEXT_LIMIT = 50;
const SIGNATURE_INITIAL_FONT_SIZE = 70;
const BUFFER_FOR_CANVAS = 10;

const calculatePercentage = (percentage: number, total: number) => (
  Math.round((percentage / total) * 100)
);

const getInitialSignatureText = (initialSignatureText: string, setButtonDisabled: any) => {
  if (initialSignatureText.length > MAX_SIGNATURE_TEXT_LIMIT) {
    return '';
  }
  setButtonDisabled(false);
  return initialSignatureText;
};

let defaultCanvasTextFontLoaded = false;

const TypedSignature = (props: Props) => {
  const { maxCanvasWidth, initialSignatureText, message } = props;
  const canvasSize = React.useMemo(
    () => getPreferredCavasSizeForWidth(maxCanvasWidth),
    [maxCanvasWidth],
  );
  const canvasWidth = canvasSize.get('width');
  const canvasHeight = canvasSize.get('height');

  const [characterCounterWarning, setCharacterCounterWarning] = React.useState(false);
  const [signature, setSignature] = React.useState<any>();
  const [font, setFont] = React.useState({ label: initialSignatureText, value: defaultFontValue });
  const { setButtonDisabled } = React.useContext(HandwrittenContext);
  const [fontSize, setFontSize] = React.useState(SIGNATURE_INITIAL_FONT_SIZE);
  const [signatureText, setSignatureText] = React.useState(
    () => getInitialSignatureText(initialSignatureText, setButtonDisabled),
  );
  const canvasRef = React.useRef(null);
  const [validationErrorMessage, setValidationErrorMessage] = React.useState('');

  const getCavasTextFont = React.useCallback((selectedFont) => {
    const ctx = canvasRef.current?.getContext('2d');
    const widthOfSignature = Math.floor(ctx.measureText(signatureText).width);

    if (widthOfSignature - calculatePercentage(BUFFER_FOR_CANVAS, widthOfSignature) > canvasWidth) {
      setFontSize(fontSize - 1);
    } else if
    (widthOfSignature <= canvasWidth - calculatePercentage(BUFFER_FOR_CANVAS, widthOfSignature)) {
      if (fontSize < SIGNATURE_INITIAL_FONT_SIZE) {
        setFontSize(fontSize + 1);
      }
    }

    if (signatureText.length === 0) {
      setFontSize(SIGNATURE_INITIAL_FONT_SIZE);
    }

    const canvasTextFont = `400 ${fontSize}px ${selectedFont.value}`;

    return canvasTextFont;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasWidth, signatureText]);

  const updateSignature = React.useCallback(
    (cavasState = {}) => {
      const {
        selectedFont = font,
        updatedSingatureText = signatureText,
        width = canvasWidth,
        height = canvasHeight,
      } = cavasState;
      const ctx = canvasRef.current?.getContext('2d');
      ctx.clearRect(0, 0, width, height);
      ctx.font = getCavasTextFont(selectedFont);
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(updatedSingatureText,
        Math.floor((width / 2)) - 5, Math.floor((height / 2)), width - 20);
      setSignature(canvasRef.current?.toDataURL());
      if (
        updatedSingatureText.length <= MAX_SIGNATURE_TEXT_LIMIT
        && updatedSingatureText.length > 0) {
        setButtonDisabled(false);
      }
    },
    [canvasHeight, canvasWidth, font, getCavasTextFont, setButtonDisabled, signatureText],
  );

  const signatureTextValidator = React.useMemo(() => validators.composeValidators(
    validators.required({
      message,
      field: 'This field', // UX wants to have "this field" in the validation message instead of signature text.
    }),
  ), [message]);

  const handleSignatureTextChange = React.useCallback((e) => {
    const updatedSingatureText = e.target.value;
    const validationMessage = signatureTextValidator(e.target.value);

    const isInValid = !!validationMessage;

    // prevent user from typing more than 50 (MAX_SIGNATURE_TEXT_LIMIT) characters
    // and make character couter red without making the input invalid
    if (updatedSingatureText.length > MAX_SIGNATURE_TEXT_LIMIT) {
      setCharacterCounterWarning(true);
      return;
    }

    setValidationErrorMessage(validationMessage);

    if (isInValid) {
      setButtonDisabled(true);
      e.target.setCustomValidity(validationMessage);
    } else {
      e.target.setCustomValidity(''); // Reset validation
    }

    setCharacterCounterWarning(false);
    setSignatureText(updatedSingatureText);
    updateSignature({ selectedFont: font, updatedSingatureText });
  }, [setButtonDisabled, signatureTextValidator, updateSignature, font]);

  const onInputBlur = React.useCallback(() => {
    setCharacterCounterWarning(false);
  }, [setCharacterCounterWarning]);

  const onFontSelect = React.useCallback((fontOption: Option) => {
    setFont(fontOption);
    updateSignature({ selectedFont: fontOption, updatedSingatureText: signatureText });
  }, [updateSignature, signatureText]);

  const canvasDataSyncingRef = React.useCallback((canvasNode) => {
    if (!canvasNode) {
      return;
    }
    canvasRef.current = canvasNode;

    if (defaultCanvasTextFontLoaded) {
      updateSignature();
      return;
    }

    document.fonts?.load(`400 ${fontSize}px ${defaultFontValue}`).then(() => {
      defaultCanvasTextFontLoaded = true;
      updateSignature();
    });
  }, [fontSize, updateSignature]);

  const fullnameFieldLabel = (
    <span className={style.FullnameLabel}>
      <Message
        id="Your name"
        comment="Label for name field in typed signature modal"
      />
      <p className={style.AsteriskStyle}>*</p>
    </span>
  );

  const characterCounterClassNames = clsx(style.CharacterCount, {
    [style.Warning]: characterCounterWarning,
  });

  return (
    <div className={style.TextFieldSelectFieldContainer}>
      <div className={style.TextField}>
        <TextField
          label={fullnameFieldLabel}
          name="signatureText"
          input={{
            value: signatureText,
            name: 'signatureText',
            onChange: handleSignatureTextChange,
            onBlur: onInputBlur,
          }}
          meta={{
            error: validationErrorMessage,
          }}
          displayErrorEarly
          autoFocus
        />
        <span className={characterCounterClassNames}>
          {signatureText.length}
          /
          {MAX_SIGNATURE_TEXT_LIMIT}
        </span>
      </div>
      <div className={style.FontSelectField}>
        <SelectField
          name="font"
          controlShouldRenderValue={false}
          includeNativeSelect
          components={{ Option: getCustomOptionComponent(signatureText) }}
          options={getOptions(signatureText)}
          input={{
            value: font,
            name: 'font',
            onChange: onFontSelect,
          }}
          placeholder={message({
            id: 'Select style',
            comment: 'Placeholder for selecting font style to use in signature',
          })}
          clearable={false}
          searchable={false}
          menuPosition="absolute"
        />
      </div>
      <canvas
        className={style.Canvas}
        width={canvasWidth}
        height={canvasHeight}
        ref={canvasDataSyncingRef}
      />
      <input name="signature" type="hidden" defaultValue={signature} key={signature} />
    </div>
  );
};

export default localize<Props>(TypedSignature);
