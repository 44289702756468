import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as RadixToast from '@radix-ui/react-toast';

import Content from 'components/toasts/content';
import { removeReoccurred } from 'components/toasts/toast';
import { TOAST_TYPES } from 'components/toasts/constants';
import type { Height, Toast } from 'components/toasts/types';

import styles from './toast.module.scss';

const TIME_BEFORE_UNMOUNT = 200;
const TOAST_WIDTH = 496;
const GAP = 14;

type Props = {
  setHeights: React.Dispatch<React.SetStateAction<Height[]>>;
  heights: Height[];
  observerId: string;
  removeToast: (toastId: Toast['id']) => void;
}

const ToastComponent = ({
  id,
  content,
  title,
  description,
  action,
  type,
  duration,
  heights,
  setHeights,
  reoccurred,
  observerId,
  remove,
  removeToast,
}: Toast & Props) => {
  const [mounted, setMounted] = useState(false);
  const [offsetBeforeRemove, setOffsetBeforeRemove] = useState(0);
  // to control the pulse-like animation when the toast reoccurs
  const [reoccurredAnimating, setReoccurredAnimating] = useState(false);
  const toastRef = useRef<HTMLLIElement>(null);
  // Defined as a useRef instead of a useMemo because its value needs to
  // persist across re-renders without causing a re-render itself.
  const offset = useRef(0);
  const closeTimeoutRef = useRef<NodeJS.Timer>();
  const heightIndex = useMemo(
    () => heights.findIndex((height) => height.toastId === id) || 0,
    [heights, id],
  );

  const totalPrevToastsHeight = useMemo(() => heights.reduce(
    (heightsAggregate, currentHeightObject, index) => {
      // Calculate offset up until current toast
      if (index >= heightIndex) {
        return heightsAggregate;
      }

      return heightsAggregate + currentHeightObject.height;
    }, 0,
  ), [heights, heightIndex]);

  offset.current = useMemo(
    () => heightIndex * GAP + totalPrevToastsHeight,
    [heightIndex, totalPrevToastsHeight],
  );

  const handleClose = useCallback(() => {
    setMounted(false);

    setOffsetBeforeRemove(offset.current);
    setHeights((_heights) => _heights.filter((height) => height.toastId !== id));

    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }

    setTimeout(() => {
      removeToast(id);
    }, TIME_BEFORE_UNMOUNT);
  }, [setHeights, id, removeToast]);

  const resetCloseTimeout = useCallback(() => {
    if (!closeTimeoutRef.current) {
      return;
    }

    clearTimeout(closeTimeoutRef.current);

    if (typeof duration === 'number') {
      closeTimeoutRef.current = setTimeout(() => {
        handleClose();
      }, duration);
    }
  }, [duration, handleClose]);

  useEffect(() => {
    setMounted(true);

    if (typeof duration === 'number') {
      closeTimeoutRef.current = setTimeout(() => handleClose(), duration);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // The toast is removed by setting the remove prop to true
  // This is to let the toast animate out before it is removed form the array of toasts
  // After the animation is done, the toast is removed from the array
  useEffect(() => {
    if (remove) {
      handleClose();
    }
  }, [handleClose, remove]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const toastNode = toastRef.current;

    if (toastNode) {
      const { height } = toastNode.getBoundingClientRect();

      // Add toast height tot heights array after the toast is mounted
      setHeights((_heights) => [{ toastId: id, height }, ..._heights]);

      return () => setHeights((_heights) => _heights.filter((_height) => _height.toastId !== id));
    }
  }, [setHeights, id]);

  useEffect(() => {
    if (reoccurred) {
      setReoccurredAnimating(true);

      resetCloseTimeout();

      setTimeout(() => {
        setReoccurredAnimating(false);
        removeReoccurred({ toastId: id, observerId });
      }, 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reoccurred]);

  return (
    <RadixToast.Root
      className={styles.ToastItem}
      ref={toastRef}
      style={{
        '--gap': `${GAP}px`,
        '--width': `${TOAST_WIDTH}px`,
        '--offset': `${!mounted ? offsetBeforeRemove : offset.current}px`,
      } as React.CSSProperties}
      data-mounted={mounted}
    >
      <div className={styles.ContentWrapper} data-reoccurred={reoccurredAnimating}>
        {content || (
          <Content
            title={title}
            description={description}
            action={action}
            id={id}
            observerId={observerId}
            type={type || TOAST_TYPES.INFO}
          />
        )}
      </div>
    </RadixToast.Root>
  );
};

export default ToastComponent;
