import { useState } from 'react';
import Message from 'components/message';
import Button from 'components/button';
import CancelSubscriptionFlow from 'components/cancel-subscription/flows/cancel-subscription-flow';

import type { FlowData } from 'types/cancellation-flow';

import YourAlternative from 'components/modals/cancellation/your-alternative';

type Props = {
  onClose: () => void;
  onPreviousStep: () => void;
  setCurrentFlow: (flow: string) => void;
  onSyncFlowData: (data: FlowData) => void;
};

const OtherAlternative = ({
  onClose,
  onPreviousStep,
  setCurrentFlow,
  onSyncFlowData,
}: Props) => {
  const [chosenAlternative, setChosenAlternative] = useState<string>('');
  const [hasTextFieldValue, setHasTextFieldValue] = useState<boolean>(false);

  const allowContinue = () => {
    if (chosenAlternative === 'other' && !hasTextFieldValue) {
      return false;
    }

    return Boolean(chosenAlternative);
  };

  return (
    <CancelSubscriptionFlow
      onCancel={onClose}
      setFlow={setCurrentFlow}
    >
      <CancelSubscriptionFlow.Steps>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="Your alternative?"
              comment="Header in the modal"
            />
          )}
          handlePreviousStep={onPreviousStep}
          allowContinue={allowContinue()}
        >
          <CancelSubscriptionFlow.StepBody>
            <YourAlternative
              chosenAlternative={chosenAlternative}
              setChosenAlternative={setChosenAlternative}
              setHasTextFieldValue={setHasTextFieldValue}
            />
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            <Button
              kind="linkSeparate"
              onClick={onClose}
            >
              <Message
                id="Keep my Subscription Plan"
                comment="Button text for keeping the subscription plan"
              />
            </Button>
            <CancelSubscriptionFlow.ShowFlow
              kind="secondary"
              syncData={{ chosen_alternative: chosenAlternative }}
              onSyncFlowData={onSyncFlowData}
              flow="confirm_cancel"
            >
              <Message
                id="Continue to cancel"
                comment="Button text to continue with the cancellation"
              />
            </CancelSubscriptionFlow.ShowFlow>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
      </CancelSubscriptionFlow.Steps>
    </CancelSubscriptionFlow>
  );
};

export default OtherAlternative;
