import { connect } from 'react-redux';

import templateGroupsReducer from 'reducers/entities/template-groups';

import AddTemplateGroup from './add-template-group';

const QUERY_NAME = '/admin/account/extensions/template-groups';

export const mapStateToProps = (state) => ({
  createState: templateGroupsReducer.getCreateSelector(state),
});

export const mapDispatchToProps = (dispatch) => ({
  createTemplateGroup: (templateGroupData) => {
    dispatch(templateGroupsReducer.createTemplateGroup({
      data: templateGroupData,
      pipe: {
        action: () => templateGroupsReducer.queryTemplateGroupsReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
  resetCreateState: () => {
    dispatch(templateGroupsReducer.createTemplateGroupReset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplateGroup);
