import { useCallback, useMemo } from 'react';
import type { ReactNode } from 'react';

import { useFormBoxProps } from 'contexts/form-box-props';
import useDebouncedCallback from 'hooks/use-debounced-callback';

import { FormFieldContext } from './form-field-context';
import { useFieldValidationContext } from './field-validation-provider';

type Props = {
  children: ReactNode,
  fieldId: string,
  value: string,
};

const SimpleInputProvider = ({
  fieldId,
  value,
  children,
}: Props) => {
  const fieldValue = value || '';
  const { updateFieldValue } = useFormBoxProps();
  const { setTouched } = useFieldValidationContext();

  const onFieldValueChange = useCallback((newFieldValue: string) => {
    setTouched(true);
    updateFieldValue(fieldId, newFieldValue);
  }, [
    fieldId,
    updateFieldValue,
  ]);

  const onFieldValueChangeDebounced = useDebouncedCallback((newFieldValue) => {
    updateFieldValue(fieldId, newFieldValue);
  }, [
    fieldId,
    updateFieldValue,
  ]);

  const contextValue = useMemo(() => ({
    fieldValue,
    onFieldValueChange,
    onFieldValueChangeDebounced,
    hasDataField: false,
  }), [fieldValue, onFieldValueChange, onFieldValueChangeDebounced]);

  return (
    <FormFieldContext.Provider value={contextValue}>
      {children}
    </FormFieldContext.Provider>
  );
};

export default SimpleInputProvider;
