/* eslint-disable react/no-unknown-property */
// @flow

import * as React from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';
import 'styles/external/video-player.scss';

import style from './video-player.module.scss';

const videoConfig = {
  controls: true,
  width: 1280,
  height: 720,
  fluid: false,
  autoplay: false,
  responsive: true,
};

export type Props = {|
  videoUrl: string,
  poster?: string,
  onDeviceReady?: () => void,
  onError?: (element: Element, error: Error) => void,
  onPlay?: Function,
|};

type State = {
  config: {},
};

class VideoPlayer extends React.Component<Props, State> {
  static defaultProps = {
    onDeviceReady: undefined,
    onError: undefined,
    onPlay: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.playerRef = React.createRef();
  }

  componentDidMount() {
    this.setupVideoPlayer();
    this.initPlayer();
  }

  componentDidUpdate(prevProps: Props) {
    const { videoUrl } = this.props;

    if (prevProps.videoUrl !== videoUrl) {
      this.initPlayer();
    }
  }

  componentWillUnmount() {
    this.dispose();
  }

  setupVideoPlayer() {
    const {
      onDeviceReady,
      onError,
    } = this.props;

    if (this.player) {
      return;
    }

    this.player = videojs(this.playerRef.current, videoConfig);

    if (onDeviceReady) {
      this.player.on('deviceReady', onDeviceReady);
    }

    if (onError) {
      this.player.on('error', onError);
    }
  }

  initPlayer() {
    const { videoUrl, poster } = this.props;

    if (this.player) {
      this.player.src({
        src: videoUrl,
        type: 'video/mp4',
        poster: poster || `${videoUrl}/thumbnail?__key__=${uniqueId()}`,
      });
    }
  }

  dispose() {
    if (this.player) {
      this.player.dispose();
    }
  }

  player: any;

  playerRef: {
    current: HTMLVideoElement | null,
  };

  render() {
    const { onPlay } = this.props;

    return (
      <div className={style.VideoPlayer}>
        <div data-vjs-player>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            ref={this.playerRef}
            cid="videoPlayer"
            className={clsx('video-js', 'vjs-default-skin', style.VideoElement)}
            onPlay={onPlay}
            playsInline
          />
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
