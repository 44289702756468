// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import WrapLeft from 'components/icons/wrap-left';
import WrapRight from 'components/icons/wrap-right';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { isWrapTextActive, toggleWrapText } from './wrap-plugin';
import ToolbarButton from '../toolbar-button';

type Props = {
  direction: 'left' | 'right',
};

const getLabel = (direction: 'left' | 'right') => {
  if (direction === 'left') {
    return (
      <Message
        id="Wrap text left"
        comment="The label of the editor's image toolbar plugin that wraps the text to the left of image."
      />
    );
  }

  return (
    <Message
      id="Wrap text right"
      comment="The label of the editor's image toolbar plugin that wraps the text to the right of image."
    />
  );
};

const getIcon = (direction: 'left' | 'right') => {
  if (direction === 'right') {
    return <WrapRight />;
  }

  return <WrapLeft />;
};

const WrapText = ({ direction }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      isActive={isWrapTextActive(editor, direction)}
      label={getLabel(direction)}
      onTrigger={() => {
        focusEditor();
        toggleWrapText(editor, direction);
      }}
      icon={getIcon(direction)}
    />
  );
};

export default WrapText;
