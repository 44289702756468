import { createContext, useContext } from 'react';

export type State = {
  search: string;
  value: string | null;
  filtered: { count: number; items: Map<string, number>; groups: Set<string> };
};

export type Store = {
  subscribe: (callback: () => void) => () => void
  snapshot: () => State
  setState: <K extends keyof State>(key: K, value: State[K], opts?: any) => void
  emit: () => void
}

export const StoreContext = createContext<Store | null>(null);
export const useStore = () => {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error('useStore must be used within a StoreProvider');
  }

  return store;
};
