// @flow

import {
  KEY_JOBYLON,
} from 'extensions';

const Jobylon = {
  key: KEY_JOBYLON,
};

export default Jobylon;
