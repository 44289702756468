import { useSelector } from 'react-redux';
import { getCurrentData } from 'reducers/current-contract';

const useCurrentData = () => {
  const data = useSelector(getCurrentData);

  if (!data) {
    return {};
  }

  return data;
};

export default useCurrentData;
