// @flow

import { connect } from 'react-redux';
import type { Match } from 'react-router';
import { replace } from 'connected-react-router';
import get from 'lodash/get';
import workspacesReducer from 'reducers/entities/workspaces';

import Details, { type FormData } from './workspace-details';

type OwnProps = {
  match: Match,
};

export const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const workspace = workspacesReducer.getWorkspaceSelector(state, { id: ownProps.match.params.id });
  const updateState = workspacesReducer.getUpdateSelector(state, { id: ownProps.match.params.id });

  return ({
    workspace,
    updateState,
  });
};

export const mapDispatchToProps = (dispatch: Function, ownProps: OwnProps) => ({
  resetUpdateState: () => {
    dispatch(workspacesReducer.updateWorkspaceReset({ id: ownProps.match.params.id }));
  },
  updateWorkspace: ({ name, type, description = null }: FormData) => {
    dispatch(workspacesReducer.updateWorkspace({
      id: ownProps.match.params.id,
      data: {
        name,
        type: get(type, 'value') || type,
        description,
      },
    }));
  },
  updatePath: (pathname) => {
    dispatch(replace(pathname));
  },
});

export default connect<Details, OwnProps, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Details);
