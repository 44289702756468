import { memo } from 'react';
import { pdfjs, Document } from 'react-pdf';

const assetsDomain = window.ASSETS_DOMAIN || 'https://static.oneflow.com';
// Refer the module name https://github.com/Flowsystem/oneflow-pdfjs-worker/tree/master/dist
const workerFileName = 'pdf-worker.js'; //

pdfjs.GlobalWorkerOptions.workerSrc = `${assetsDomain}/libs/oneflow-pdfjs-worker@1.1.0/${workerFileName}`;
export const cMapUrl = `${assetsDomain}/libs/oneflow-pdfjs-worker@1.1.0/cmaps/`;

export default memo(Document);
