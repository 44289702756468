// @flow

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { isEmpty } from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import clsx from 'clsx';

import { formatDateString } from 'date';
import {
  isAnnotation,
  isComment,
  isCommentResolved,
  isOrphanComment,
  isSuggestion,
  isSuggestionAccepted,
  isSuggestionDeleted,
  isSuggestionRejected,
  isSuggestionResolved,
  resetMessageIdInSessionStorage,
} from 'comments';
import { getGuestToken } from 'agreement/selectors';
import { isSmallScreenWidth } from 'ui/config';
import { updateSeenMessageThreads } from 'reducers/app';
import { useAnnotationProps } from 'contexts/annotation-props';
import useAgreement from 'hooks/use-agreement';
import useAnnotationsMap from 'hooks/use-annotations-map';
import useCurrentBoxes from 'hooks/use-current-boxes';
import useOnScreen from 'hooks/use-on-screen';
import useOutsideClick from 'hooks/use-outside-click';

import { useCollapsedDocumentLayout } from 'components/document-layout-container/collapsed-document-layout/context';
import {
  changeAnnotationColor,
  isOrphanAnnotation,
  showAndFocusAnnotation,
} from 'components/contract-text-editor/editor-plugins/annotation-plugin';
import { scrollToElement } from 'components/document-tabs/messages-tab/post-message/auto-scroll';
import AuthorBubble from 'components/messages-layout/author-bubble';
import Message from 'components/message';
import MessageReplies from 'components/messages-layout/message-replies';
import MessageReply from 'components/messages-layout/message-reply';
import SingleMessage from 'components/messages-layout/single-message';

import style from './single-message-box.module.scss';

export type AmplitudeData = {
  location?: 'contract view - popover' | 'collaboration menu';
};

type Props = {
  agreementId: number,
  amplitudeData?: AmplitudeData,
  isInteractive?: boolean,
  isMobileView?: boolean,
  message: any,
  messageFromUrlRef?: React.Node | null,
};

const removeHoverColor = 'transparent';

const SingleMessageBox = ({
  agreementId,
  amplitudeData,
  isInteractive,
  isMobileView = false,
  message,
  messageFromUrlRef,
}: Props) => {
  const [isMessageClicked, setIsMessageClicked] = useState(false);
  const annotationsMap = useAnnotationsMap();
  const { isRecipientSelectorOpen } = useCollapsedDocumentLayout();
  const { editor } = annotationsMap[message.id] || {};
  const { highlightedAnnotationId, setHighlightedAnnotationId } = useAnnotationProps();
  const agreement = useAgreement(agreementId);
  const boxesMap = useCurrentBoxes();
  const dispatch = useDispatch();
  const guestToken = useSelector(getGuestToken);
  const hoverColor = isSuggestion(message) ? '#f7dcd7' : 'rgba(255, 208, 99, 0.6)';
  const isCurrentAnnotationHighlighted = highlightedAnnotationId === message.id;
  const messageRef = useRef(null);
  const isMessageOnScreen = useOnScreen(messageRef);
  const isSmallScreen = isSmallScreenWidth();

  useEffect(() => {
    if (isCurrentAnnotationHighlighted) {
      showAndFocusAnnotation(
        editor,
        message,
        Boolean(guestToken),
        isSmallScreen,
      );
      setIsMessageClicked(true);

      setTimeout(() => {
        scrollToElement(messageRef.current, {
          behavior: 'instant',
        });
      }, 100);
    }
  }, [
    editor,
    guestToken,
    isCurrentAnnotationHighlighted,
    message,
    hoverColor,
    isSmallScreen,
  ]);

  const isOrphan = useMemo(() => isOrphanAnnotation(
    message.id,
    annotationsMap[message.id],
    boxesMap,
  ), [
    annotationsMap,
    boxesMap,
    message.id,
  ]);

  const showMessageFromUrl = useCallback((ref: Props.messageFromUrlRef) => {
    setIsMessageClicked(true);
    changeAnnotationColor(message.id, hoverColor);
    scrollToElement(ref?.current, {
      behavior: 'instant',
    });

    if (editor) {
      showAndFocusAnnotation(
        editor,
        message,
        Boolean(guestToken),
        isSmallScreen,
      );
    }

    queueMicrotask(() => {
      resetMessageIdInSessionStorage();
    });
  }, [
    guestToken,
    hoverColor,
    message,
    editor,
    isSmallScreen,
  ]);

  useEffect(() => {
    if (messageFromUrlRef) {
      queueMicrotask(() => {
        showMessageFromUrl(messageFromUrlRef);
      });
    }
  }, [messageFromUrlRef, showMessageFromUrl]);

  const onClickOutside = useCallback((event) => {
    const isAnnotationClick = event.target.id.includes('annotation');

    if (!isAnnotationClick) {
      setIsMessageClicked(false);
      changeAnnotationColor(message.id, removeHoverColor);
      setHighlightedAnnotationId(null);
    }
  }, [message.id, setIsMessageClicked, setHighlightedAnnotationId]);

  const getMessageRef = useCallback(() => {
    if (messageFromUrlRef) {
      return messageFromUrlRef;
    }

    return messageRef;
  }, [messageFromUrlRef]);

  useOutsideClick(getMessageRef(), onClickOutside, isMessageClicked);

  const resolvedStatusLabel = useMemo(() => {
    if (isSuggestion(message)) {
      if (isSuggestionAccepted(message)) {
        return (
          <Message
            id="Accepted suggestion"
            comment="Text to show this suggestion is marked as accepted"
          />
        );
      }

      if (isSuggestionRejected(message)) {
        return (
          <Message
            id="Rejected suggestion"
            comment="Text to show this suggestion is marked as rejected"
          />
        );
      }

      if (isSuggestionDeleted(message)) {
        return (
          <Message
            id="Deleted text associated with the suggestion"
            comment="Text to show that the text that the comment was referring to, was deleted"
          />
        );
      }
    }

    if (isOrphanComment(message)) {
      return (
        <Message
          id="Deleted text associated with the comment"
          comment="Text to show that the text that the comment was referring to, was deleted"
        />
      );
    }

    return (
      <Message
        id="Marked comment as resolved"
        comment="Text to show this comment is marked as resolved"
      />
    );
  }, [message]);

  const renderReplyButton = useCallback(() => {
    if (isCommentResolved(message) || isSuggestionResolved(message)) {
      return null;
    }

    return (
      <MessageReply
        parentMessage={message}
        agreementId={agreementId}
        containerRef={messageRef}
        amplitudeData={amplitudeData}
        isInteractive={isInteractive}
      />
    );
  }, [
    agreementId,
    message,
    amplitudeData,
    isInteractive,
  ]);

  const renderResolvedInfo = useCallback(() => {
    if (!isCommentResolved(message) && !isSuggestionResolved(message)) {
      return null;
    }

    const { resolvedByParticipant, updated } = message;
    const formattedResolvedDate = formatDateString(updated, `${agreement?.config.dateFormat || 'MMM D'}, HH:mm`);
    return (
      <div className={style.ResolvedContainer}>
        <AuthorBubble
          agreementId={agreementId}
          participant={resolvedByParticipant}
          formattedDate={formattedResolvedDate}
        />
        <div className={style.ResolvedStatusLabel}>
          {resolvedStatusLabel}
        </div>
      </div>
    );
  }, [
    agreement?.config.dateFormat,
    agreementId,
    message,
    resolvedStatusLabel,
  ]);

  const isInteractiveMessage = useMemo(() => isAnnotation(message)
    && isInteractive
    && !isCommentResolved(message)
    && !isOrphan,
  [
    isInteractive,
    isOrphan,
    message,
  ]);

  useEffect(() => {
    if (isMessageOnScreen) {
      setTimeout(() => {
        dispatch(updateSeenMessageThreads(message.id));
      }, 5000);
    }
  }, [
    dispatch,
    isMessageOnScreen,
    message.id,
  ]);

  const interactiveDivProps = useMemo(() => {
    if (!isInteractiveMessage) {
      return {};
    }

    return {
      onClick: (event) => {
        if (event.target.form) {
          return;
        }
        showAndFocusAnnotation(
          editor,
          message,
          Boolean(guestToken),
          isSmallScreen,
        );
        setIsMessageClicked(true);
      },
      role: 'button',
      tabIndex: 0,
      onKeyDown: () => undefined,
      style: { cursor: 'pointer' },
      onMouseEnter: () => {
        changeAnnotationColor(message.id, hoverColor);
      },
      onMouseLeave: () => {
        changeAnnotationColor(message.id, removeHoverColor);
      },
    };
  }, [
    editor,
    isInteractiveMessage,
    guestToken,
    hoverColor,
    message,
    isSmallScreen,
    setIsMessageClicked,
  ]);

  const singleMessageBoxClasses = clsx(style.SingleMessageBox, {
    [style.HoveredComment]: isInteractiveMessage && !isMessageClicked && !isSmallScreen,
    [style.ClickedMessage]: isMessageClicked && !isSmallScreen,
    [style.Comment]: isComment(message),
    [style.Suggestion]: isSuggestion(message),
    [style.ResolvedComment]: isCommentResolved(message) || isOrphanComment(message),
    [style.ResolvedSuggestion]: isSuggestionResolved(message),
    [style.MobileView]: isMobileView,
  });

  return (
    <div
      className={singleMessageBoxClasses}
      ref={getMessageRef()}
      {...interactiveDivProps}
    >
      <span className={clsx({
        [style.Hidden]: (
          isSmallScreen
          && isRecipientSelectorOpen
          && !isInteractiveMessage
          && (isComment(message) || isSuggestion(message))
        ),
      })}
      >
        <SingleMessage
          message={message}
          guestToken={guestToken}
          agreementId={agreementId}
          boxId={annotationsMap[message.id]?.boxId}
          isInteractive={isInteractive}
          amplitudeData={amplitudeData}
        />
        {!isEmpty(message.replies) && <hr />}
        <MessageReplies
          replies={message.replies || []}
          guestToken={guestToken}
          agreementId={agreementId}
        />
      </span>
      {renderReplyButton()}
      {renderResolvedInfo()}
    </div>
  );
};

export default SingleMessageBox;
