/* eslint-disable react/no-this-in-sfc */
// @flow

import * as React from 'react';

import Message from 'components/message';
import type { MessageTranslator } from '@oneflowab/pomes';

import joinListWithSeparator from 'utils/join-list-with-separator';

import DeleteTemplate from 'components/modals/delete-template';
import ShareTemplate from 'components/modals/share-template';
import TemplateLibrary from 'components/modals/template-library';
import { DeleteTemplates } from 'components/modals/delete-templates';
import { SetContractNameModal } from 'components/modals/set-contract-name';
import { CreateDocumentFromTemplate } from 'components/create-document-from-template';
import CopyContract from 'components/contract-actions/copy-contract';
import MoveContract from 'components/contract-actions/move-contract';
import { Acl, checkAcl } from 'components/acl';
import adminPage from 'hocs/admin-page';
import ActionsMenu from 'components/actions-menu';
import Button from 'components/button';
import ActionBar from 'components/action-bar';
import { Table } from 'components/table';
import TableFiltering from 'components/table/table-filtering';
import Tags from 'components/tags';
import Pagination from 'components/pagination';
import NoTemplatesIcon from 'components/icons/no-templates';
import Add from 'components/icons/add';
import CircularSpinner from 'components/icons/circular-spinner';
import PublishIcon from 'components/icons/publish';
import UnpublishIcon from 'components/icons/unpublish';
import UseTemplateIcon from 'components/icons/use-template';
import { MenuItem } from 'components/menu-item';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { ShareMenuItem } from 'components/menu-items/share';
import { RenameMenuItem } from 'components/menu-items/rename';
import { DeleteBulkAction } from 'components/bulk-action-items/delete';
import AddTag from 'components/contract-actions/add-tag';
import { LocalizedDateTime } from 'components/localized-date-time';

import { isSharedFromAnotherWorkspace } from 'agreement/selectors';
import TemplateState from 'components/document-information/state/template-state';
import TemplateName from './template-name';

import style from './contract-templates.module.scss';

export type Props = {|
  templates: Array<AgreementTemplate>,
  templatesQuery: Query,
  queryReload: () => void,
  queryTemplates: QueryFunc,
  createTemplate: number => void,
  createState: CreateState,
  workspace: Workspace,
  message: MessageTranslator,
  queryName: string,
  changeTemplateStatus: ({ id: number, status: boolean }) => void,
|};

export type State = {
  selectedAgreementTemplates: Array<AgreementTemplate>,
};

export class ContractTemplatesComponent extends React.Component<Props, State> {
  state = {
    selectedAgreementTemplates: [],
  }

  componentDidMount() {
    const { queryTemplates } = this.props;

    queryTemplates({});
  }

  componentDidUpdate(prevProps: Props) {
    const { queryReload, workspace } = this.props;

    if (prevProps.workspace.id !== workspace.id) {
      queryReload();
    }
  }

  getNameAndTags = (template: AgreementTemplate) => {
    const { workspace } = this.props;

    return (
      <>
        <TemplateName
          template={template}
        />
        <Tags
          targetId={template.agreement.id}
          targetType="agreement"
          allowTagChange={checkAcl(template.agreement.acl, 'agreement:tag:use') && !isSharedFromAnotherWorkspace(template.agreement, workspace.id)}
          responsive
          numberOfTags={3}
          amplitudeScope="template list"
        />
      </>
    );
  };

  getId = (template: AgreementTemplate) => (
    <div>
      {template.agreement.id}
    </div>
  );

  getStatus = (template: AgreementTemplate) => (
    <TemplateState
      published={Boolean(template.agreement.config.templateActive)}
      className={style.TemplateState}
      isClickable={false}
    />
  );

  getSetNameMenuItem = (template: AgreementTemplate) => (onClick: () => void) => {
    const { workspace } = this.props;

    return (
      <RenameMenuItem
        onClick={onClick}
        disabled={!checkAcl(template.agreement.acl, 'agreement:update:name')
          || isSharedFromAnotherWorkspace(template.agreement, workspace.id)}
      />
    );
  };

  getTemplatePublishingMenuItem = (template: any) => {
    const { changeTemplateStatus } = this.props;
    const isPublished = Boolean(template.agreement.config?.templateActive);
    return (
      <MenuItem
        onClick={() => changeTemplateStatus({
          id: template.agreement.id,
          status: !isPublished,
        })}
        icon={isPublished ? UnpublishIcon : PublishIcon}
        label={isPublished ? (
          <Message
            id="Unpublish template"
            comment="Label for unpublish action in menu."
          />
        ) : (
          <Message
            id="Publish template"
            comment="Label for unpublish action in menu."
          />
        )}
      />
    );
  }

  getUseTemplateMenuItem = (onClick: () => void) => {
    const { workspace } = this.props;

    return (
      <MenuItem
        onClick={onClick}
        icon={UseTemplateIcon}
        disabled={!checkAcl(workspace.acl, 'collection:agreement:create')}
        label={(
          <Message
            id="Use template"
            comment="Label for use template action in menu."
          />
        )}
      />
    );
  }

  getCreated = (template: AgreementTemplate) => (
    <div className={style.CreatedCellContainer}>
      <span className={style.Date}>
        <LocalizedDateTime datetime={template.createdTime} />
      </span>
      <span className={style.Author}>
        {template.agreement.createdBy.name || '-'}
      </span>
    </div>
  );

  getUpdated = (template: AgreementTemplate) => {
    const updatedOrCreated = template.agreement.updatedBy.updatedTime || template.createdTime;
    return (
      <div className={style.UpdatedCellContainer}>
        <span className={style.Date}>
          <LocalizedDateTime datetime={updatedOrCreated} />
        </span>
        <span className={style.Author}>
          {template.agreement.updatedBy.actorName || '-'}
        </span>
      </div>
    );
  }

  getDeleteMenuItem = (template: AgreementTemplate) => (onClick: () => void) => {
    const { workspace } = this.props;

    return (
      <DeleteMenuItem
        onClick={onClick}
        disabled={!checkAcl(template.agreement.acl, 'agreement:remove')
          || isSharedFromAnotherWorkspace(template.agreement, workspace.id)}
      />
    );
  };

  getShareMenuItem = (template: AgreementTemplate) => (onClick: () => void) => {
    const { workspace } = this.props;
    const notAllowedToShare = !checkAcl(workspace.acl, 'collection:agreement:template:share');

    if (notAllowedToShare) {
      return null;
    }

    return (
      <ShareMenuItem
        onClick={onClick}
        disabled={isSharedFromAnotherWorkspace(template.agreement, workspace.id)}
      />
    );
  };

  getActions = (template: AgreementTemplate) => {
    const { workspace, queryName } = this.props;

    const templateEditActions = [
      <SetContractNameModal agreement={template.agreement}>
        {this.getSetNameMenuItem(template)}
      </SetContractNameModal>,
      <AddTag
        agreement={template.agreement}
        visible={
          checkAcl(template.agreement.acl, 'agreement:tag:use') && !isSharedFromAnotherWorkspace(template.agreement, workspace.id)
        }
      />,
    ];

    const templatePublishingActions = [
      this.getTemplatePublishingMenuItem(template),
      <CreateDocumentFromTemplate
        agreement={template.agreement}
        amplitudeScope="template list - action menu"
      >
        {this.getUseTemplateMenuItem}
      </CreateDocumentFromTemplate>,
    ];

    const templateActions = [
      <MoveContract
        agreement={template.agreement}
        disabled={isSharedFromAnotherWorkspace(template.agreement, workspace.id)}
        queryName={queryName}
      />,
      <CopyContract
        agreement={template.agreement}
        disabled={isSharedFromAnotherWorkspace(template.agreement, workspace.id)}
        amplitudeScope="template list - action menu"
      />,
      <ShareTemplate template={template}>
        {this.getShareMenuItem(template)}
      </ShareTemplate>,
    ];

    return (
      <ActionsMenu
        actions={joinListWithSeparator([
          templatePublishingActions,
          templateEditActions,
          templateActions,
          <DeleteTemplate agreement={template.agreement} queryName={queryName}>
            {this.getDeleteMenuItem(template)}
          </DeleteTemplate>,
        ], 'separator').flat()}
        focusOnCloseDisabled
      />
    );
  };

  getDeleteBulkAction = (onClick: () => void) => {
    const { selectedAgreementTemplates } = this.state;
    const { workspace } = this.props;
    const label = (
      <Message
        id="Delete"
        comment="Label for delete in bulk actions menu"
      />
    );

    const hasDeletePermission = selectedAgreementTemplates.every((template) => (
      checkAcl(template.agreement.acl, 'agreement:remove')
      && template.agreement.collection.id === workspace.id
    ));

    return (
      <DeleteBulkAction
        onClick={onClick}
        disabled={!hasDeletePermission}
        label={label}
      />
    );
  }

  getTableConfig() {
    const { templates, queryName } = this.props;
    const { selectedAgreementTemplates } = this.state;

    return {
      items: templates,
      itemKey: 'agreement.id',
      actions: [
        <DeleteTemplates
          selectedAgreementTemplates={selectedAgreementTemplates}
          queryName={queryName}
          key="remove"
        >
          {this.getDeleteBulkAction}
        </DeleteTemplates>,
      ],
      columns: [
        {
          name: 'name',
          label: <Message id="Template" comment="Table header" />,
          type: 'cell',
          className: style.NameColumn,
          value: this.getNameAndTags,
        },
        {
          name: 'id',
          label: <Message id="ID" comment="Table header" />,
          type: 'cell',
          className: style.IDColumn,
          value: this.getId,
        },
        {
          name: 'created',
          label: <Message id="Created" comment="Table header" />,
          type: 'cell',
          className: style.CreatedColumn,
          value: this.getCreated,
        },
        {
          name: 'updated',
          label: <Message id="Last updated" comment="Table header" />,
          type: 'cell',
          className: style.UpdatedColumn,
          value: this.getUpdated,
        },
        {
          name: 'status',
          label: <Message id="Status" comment="Table header" />,
          type: 'cell',
          className: style.StatusColumn,
          value: this.getStatus,
        },
        {
          name: 'actions',
          label: <Message id="Actions" comment="Table header" />,
          type: 'actions',
          value: this.getActions,
        },
      ],
    };
  }

  getEmptyState() {
    const { message, templatesQuery } = this.props;
    const { count, loading } = templatesQuery;

    return {
      header: message({
        id: 'No templates found',
        comment: 'Empty state header in the template list.',
      }),
      icon: <NoTemplatesIcon height="72px" />,
      content: message({
        id: 'Please adjust your search criteria and try again.',
        comment: 'Empty state content in the template list when no templates are found',
      }),
      showEmptyState: count === 0 && !loading,
    };
  }

  selectionChangeHandler = (selectedAgreementIds: Array<number>) => {
    const { templates } = this.props;

    const selectedAgreementTemplates = selectedAgreementIds.map((id) => (
      templates.find((template) => template.agreement.id === id)
    ));

    this.setState({ selectedAgreementTemplates });
  }

  handleFilter = (params: { q: string }) => {
    const { queryTemplates } = this.props;

    queryTemplates({ params });
  };

  handlePageChange = ({ offset, limit }: Pagination) => {
    const { queryTemplates, templatesQuery } = this.props;

    queryTemplates({
      params: templatesQuery.params,
      pagination: {
        offset,
        limit,
      },
    });
  };

  handleTemplateCreate = () => {
    const { createTemplate, workspace } = this.props;

    createTemplate(workspace.id);
  };

  renderPagination() {
    const { message, templatesQuery } = this.props;
    const { loading, count, pagination } = templatesQuery;

    if (loading || !count) {
      return null;
    }

    return (
      <Pagination
        totalItems={count}
        itemsPerPage={pagination.limit}
        currentOffset={pagination.offset}
        onChange={this.handlePageChange}
        entityName={message({
          id: 'templates',
          comment: 'Plural form in pagination of contract templates.',
        })}
      />
    );
  }

  renderActionBar() {
    const {
      workspace,
      createState,
    } = this.props;

    return (
      <ActionBar collapsed>
        <ActionBar.Group>
          <Acl acl={workspace.acl} check="collection:agreement:template:create">
            <Button
              data-testid="create-template"
              icon={createState.loading ? <CircularSpinner height="16px" /> : Add}
              kind="primary"
              disabled={createState.loading}
              onClick={this.handleTemplateCreate}
            >
              <Message id="Create template" comment="Action button text" />
            </Button>
            <TemplateLibrary />
          </Acl>
        </ActionBar.Group>
      </ActionBar>
    );
  }

  render() {
    const { templatesQuery, message } = this.props;

    return (
      <div className={style.ContractTemplates}>
        {this.renderActionBar()}
        <TableFiltering
          filters={[
            {
              type: 'text',
              name: 'search',
              defaultValue: '',
              queryKey: 'q',
              autoFocus: true,
              placeholder: message({
                id: 'Find templates',
                comment: 'Placeholder in search field.',
              }),
            },
            {
              type: 'dropdown',
              name: 'status',
              options: [
                {
                  default: true,
                  value: 0,
                  label: message({ id: 'All templates', comment: 'Dropdown label for table filters in templates page' }),
                },
                {
                  default: false,
                  value: 1,
                  query: { visible: 1 },
                  label: message({ id: 'Published', comment: 'Dropdown label for table filters in templates page' }),
                },
                {
                  default: false,
                  value: 2,
                  query: { visible: 0 },
                  label: message({ id: 'Unpublished', comment: 'Dropdown label for table filters in templates page' }),
                },
              ],
            },
          ]}
          onFilterHandler={this.handleFilter}
          loading={templatesQuery.loading}
        />
        <Table
          config={this.getTableConfig()}
          query={templatesQuery}
          emptyState={this.getEmptyState()}
          onSelectionChanged={this.selectionChangeHandler}
        />
        {this.renderPagination()}
      </div>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({
  props: {
    message,
    workspace,
  },
}: { props: MapperProps }) => ({
  modules: [[]],
  section: message({ id: 'Contracts', comment: 'Used as the title for the section.' }),
  showWorkspace: true,
  title: message({ id: 'Contract templates', comment: 'Page title for the contract templates page.' }),
  workspace,
});

export default adminPage(propsMapper)(ContractTemplatesComponent);
