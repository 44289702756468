import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';
import { useSlate } from 'slate-react';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import Button from 'components/button';
import RichTextEditorToolbarDivider from 'components/rich-text-editor-toolbar-divider';
import Tooltip from 'components/tooltip';
import UnlinkIcon from 'components/icons/unlink';
import EmailIcon from 'components/icons/email';
import ExternalLinkIcon from 'components/icons/external-link';
import EditIcon from 'components/icons/edit';
import {
  unwrapLink,
  isLinkActive,
  getText,
} from 'components/rich-text-editor-toolbars/toolbar-buttons/link-button/plugin';

import style from './link-information.module.scss';

type Props = {
  existingLink: string,
  linkType: 'url' | 'email',
  visible: boolean,
  onEditClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

const LinkInfo = ({
  existingLink,
  linkType,
  visible,
  onEditClick,
}: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  const unlink = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    focusEditor();
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }
  };

  const tooltipOpenLinkMessage = linkType === 'url' ? (
    <Message
      id="Open link in a new tab"
      comment="The label of the button that opens the link."
    />
  ) : (
    <Message
      id="Open email"
      comment="The label of the button that opens the link."
    />
  );

  if (!visible) {
    return null;
  }

  return (
    <div className={style.LinkInformation}>
      <Button
        kind="linkSeperate"
        customClass={style.UrlButton}
        href={existingLink}
        external
        target="_blank"
        rel="noopener noreferrer"
        icon={(
          <Tooltip
            message={tooltipOpenLinkMessage}
            messageClassName={style.Tooltip}
            side="bottom"
            zIndex="9999"
          >
            <div>
              {linkType === 'url' ? <ExternalLinkIcon height="15px" /> : <EmailIcon height="18px" />}
            </div>
          </Tooltip>
        )}
      >
        <span>{getText(existingLink)}</span>
      </Button>
      <RichTextEditorToolbarDivider />
      <Tooltip
        message={(
          <Message
            id="Edit"
            comment="The label of the button that opens the edit mode."
          />
        )}
        side="bottom"
        messageClassName={style.Tooltip}
        zIndex="9999"
      >
        <div>
          <Button
            onClick={onEditClick}
            icon={<EditIcon className={style.EditIcon} height="19px" />}
            customClass={style.LinkButton}
          />
        </div>
      </Tooltip>
      <RichTextEditorToolbarDivider />
      <Tooltip
        message={(
          <Message
            id="Remove link"
            comment="The label of the button that removes the link."
          />
        )}
        side="bottom"
        messageClassName={style.Tooltip}
        zIndex="9999"
      >
        <div>
          <Button
            onClick={unlink}
            icon={(
              <UnlinkIcon
                className={style.UnlinkIcon}
                height="24px"
              />
            )}
            customClass={clsx(style.LinkButton, style.RemoveButton)}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default LinkInfo;
