import * as React from 'react';

import {
  isPending,
  isDraft,
  isOverdue,
  isSignedAndEndedAndTerminated,
  isDelegateVisible,
} from 'agreement';

import {
  isMessageParticipantsVisible,
  getAgreementMyParticipant,
} from 'agreement/selectors';
import * as permissions from 'agreement/permissions';
import { isPendingStateApprover } from 'agreement/participant/is-pending-state-approver';

import { checkAcl } from 'components/acl';

import ActionsMenu from 'components/actions-menu';
import joinListWithSeparator from 'utils/join-list-with-separator';

import DelegateSigning from 'components/contract-actions/delegate-signing';
import { DelegatePendingApproval } from 'components/contract-actions/delegate-pending-approval';
import AddTag from 'components/contract-actions/add-tag';
import AiReview from './ai-review';
import SetContractValue from './set-contract-value';
import SetContractName from './set-contract-name';
import MoveContract from './move-contract';
import { DeleteContract } from './delete-contract';
import DownloadPdf from './download-pdf';
import CopyContract from './copy-contract';
import SetExpiryDate from './set-expiry-date';
import { MarkAsDeclined } from './mark-as-declined';
import { MarkAsTerminated } from './mark-as-terminated';
import { MarkAsSigned } from './mark-as-signed';
import SignContract from './sign-contract-item';
import CreateInternalReminder from './create-internal-reminder';
import { MessageParticipants } from './message-participants';
import LinkToContract from './link-to-contract';

type Props = {
  agreement: Oneflow.Agreement,
  // eslint-disable-next-line react/no-unused-prop-types
  queryName: string,
  disabled?: boolean,
};

type Action = {
  element: React.ReactNode,
  isActionVisible: () => void,
}

export const isDraftOrPendingOrOverdue = (agreement: Oneflow.Agreement) => (
  isDraft(agreement) || isPending(agreement) || isOverdue(agreement)
);

export const isMarkAsTerminatedVisible = (agreement: Oneflow.Agreement) => (
  !isSignedAndEndedAndTerminated(agreement) && !isDraftOrPendingOrOverdue(agreement)
);

export const isSetExpiryDateVisible = (agreement: Oneflow.Agreement) => (
  isPending(agreement) || isOverdue(agreement)
);

const ContractActions = ({
  agreement,
  queryName,
  disabled,
}: Props) => {
  const hasAccessToInternalReminders = checkAcl(agreement.acl, 'agreement:internal_reminder:create');
  const participant = getAgreementMyParticipant(agreement);
  const agreementAllowsDelegate = React.useMemo(
    () => checkAcl(agreement.acl, 'agreement:participant:pending_state_approver:create'), [agreement.acl],
  );
  const canParticipantDelegate = React.useMemo(
    () => checkAcl(participant?.acl, 'participant:delegate:from'), [participant?.acl],
  );

  let availableActions = [];
  const setActions = [
    <SetContractName agreement={agreement} key="setContractName" />,
    <SetContractValue agreement={agreement} key="setContractValue" />,
    <AddTag agreement={agreement} visible={checkAcl(agreement.acl, 'agreement:tag:use')} key="addTag" />,
    <AiReview
      agreement={agreement}
      visible={checkAcl(agreement.acl, 'agreement:ai_review:view')}
      key="aiReview"
      location="contract list - action menu"
    />,
  ];

  const editActions = [
    <MoveContract agreement={agreement} queryName={queryName} key="moveContract" />,
    <CopyContract agreement={agreement} amplitudeScope="contract list - copy contract modal" key="copyContract" />,
    <LinkToContract agreement={agreement} key="linkToContract" />,
    <DownloadPdf agreement={agreement} amplitudeScope="contract list" key="downloadPdf" />,
  ];

  const dateActions = [
    {
      element: <SetExpiryDate agreement={agreement} />,
      isActionVisible: isSetExpiryDateVisible,
    },
  ]
    .filter<Array<Action>, Action>(({ isActionVisible }) => isActionVisible(agreement))
    .map(({ element }) => element);

  const deleteActions = [
    <DeleteContract agreement={agreement} queryName={queryName} key="deleteContract" />,
  ];

  const concludeActions = [
    {
      element: <SignContract agreement={agreement} />,
      isActionVisible: () => permissions.isSignContractVisible(agreement),
    },
    {
      element: <DelegateSigning agreement={agreement} />,
      isActionVisible: () => (
        isDelegateVisible(agreement)
        && permissions.isSignContractVisible(agreement)
      )
      ,
    },
    {
      element: <DelegatePendingApproval agreement={agreement} />,
      isActionVisible: () => (
        agreementAllowsDelegate && canParticipantDelegate && isPendingStateApprover(participant)
      )
      ,
    },
    // Cancel contract not added because ACL agreement:cancel is deny for documents in contract list
    {
      element: <MarkAsTerminated agreement={agreement} />,
      isActionVisible: isMarkAsTerminatedVisible,
    },
    {
      element: <MarkAsDeclined agreementId={agreement.id} />,
      isActionVisible: isDraftOrPendingOrOverdue,
    },
    {
      element: <MarkAsSigned agreementId={agreement.id} />,
      isActionVisible: isDraftOrPendingOrOverdue,
    },

  ]
    .filter<Array<Action>, Action>(({ isActionVisible }) => isActionVisible(agreement))
    .map(({ element }) => element);

  const remindAction = [
    {
      element: <CreateInternalReminder agreement={agreement} />,
      isActionVisible: () => hasAccessToInternalReminders,
    },
    {
      element: <MessageParticipants agreement={agreement} />,
      isActionVisible: isMessageParticipantsVisible,
    },
  ]
    .filter<Array<Action>, Action>(({ isActionVisible }) => isActionVisible(agreement))
    .map(({ element }) => element);

  availableActions = joinListWithSeparator(
    [
      setActions,
      editActions,
      concludeActions,
      dateActions,
      remindAction,
      deleteActions,
    ], 'separator',
  ).flat();

  if (disabled) {
    return <div style={{ padding: 15 }} />;
  }

  return (
    <ActionsMenu
      actions={availableActions}
      focusOnCloseDisabled
    />
  );
};

ContractActions.defaultProps = {
  disabled: undefined,
};

export default ContractActions;
