// @flow

import { type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getPositionFromSessionSelector } from 'reducers/session';
import { getLocationSelector } from 'reducers/router';

import Dashboard from './dashboard-module';

import type { Props } from './dashboard-module';

export const mapStateToProps = (state: State) => ({
  location: getLocationSelector(state),
  position: getPositionFromSessionSelector(state),
});

type DispatchProps = $Exact<{}>;
type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, any, any, $Exact<DispatchProps>, State, Dispatch<*>>(
  mapStateToProps, mapDispatchToProps,
)(Dashboard);
