import {
  getMoment,
  addDaysToDate,
  formatDateString,
  getDaysUntil,
} from 'date';

export function getValidUntilDate({ expireDate, expireDateDays, dateFormat }) {
  if (expireDate) {
    return formatDateString(expireDate, dateFormat);
  }

  if (expireDateDays) {
    return addDaysToDate(
      getMoment(),
      expireDateDays,
    ).format(dateFormat);
  }

  return null;
}

export function getDaysCount(params) {
  const { expireDate, expireDateDays } = params;

  if (expireDate) {
    return getDaysUntil(expireDate);
  }

  if (expireDateDays) {
    return expireDateDays;
  }

  return 0;
}
