// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import dataFieldsReducer from 'reducers/entities/data-fields';

import AddDataField, { type CreateDataField } from './add-data-field';

export const QUERY_NAME = '/admin/account/extensions/template-groups/data-fields';

type StateProps = {|
  createState: CreateState,
|};

type DispatchProps = {|
  createDataField: CreateDataField => void,
  resetCreateState: () => void,
|};

type OwnProps = {|
  dataFieldSetId: number,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  createState: dataFieldsReducer.getCreateSelector(state),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { dataFieldSetId }) => ({
  createDataField: ({
    name,
    description,
    placeholder,
    value,
    externalKey,
  }) => {
    dispatch(dataFieldsReducer.createDataField({
      data: {
        dataFieldSetId,
        name,
        description,
        placeholder,
        value,
        externalKey,
      },
      pipe: {
        action: () => dataFieldsReducer.queryDataFieldsReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
  resetCreateState: () => {
    dispatch(dataFieldsReducer.createDataFieldReset());
  },
});

export default connect<{}, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(AddDataField);
