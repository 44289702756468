// @flow

import * as React from 'react';

import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';
import { useUserEvents } from 'contexts/user-events';

import { CloseButton } from 'components/buttons';

import InternalReminderReadBody from './internal-reminder-read-body';
import useInitialValues from './use-initial-values';

export type FormData = {|
  contractFieldOption: {},
  recipients: [],
  date: string,
  timeFieldOption: string,
  subject: string,
  message: string,
|};

export type Props = {
  message: MessageTranslator,
  refetchInternalReminders: () => void,
  children?: React.Element<Message>,
  internalReminderEvent?: InternalReminderEvent,
};

const rpcState = {
  data: {},
  error: undefined,
  id: undefined,
  loading: false,
  pristine: true,
  success: false,
};

const noop = () => {};

export const InternalReminderReadComponent = ({
  message,
  children,
  internalReminderEvent,
  refetchInternalReminders,
  ...restProps
}: Props) => {
  const { currentUser } = useUserEvents();

  const initialValues = useInitialValues.forSelectedEvent({
    message,
    currentUser,
    internalReminderEvent,
  });

  const resetRpcState = () => rpcState;

  const getActions = ({ closeConfirmation }) => (
    <CloseButton onClick={closeConfirmation} />
  );

  const getTitle = () => (
    <Message
      id="Reminder information"
      comment="Modal title for viewing a reminder in read only mode."
    />
  );

  return (
    <ModalForm
      title={getTitle()}
      body={<InternalReminderReadBody internalReminderEvent={internalReminderEvent} />}
      formState={rpcState}
      resetFormState={resetRpcState}
      actions={getActions}
      onSubmit={noop}
      onClose={resetRpcState}
      initialValues={initialValues}
      modalKey="read reminder modal"
      {...restProps}
    >
      {children || null}
    </ModalForm>
  );
};

export default localize<Props>(InternalReminderReadComponent);
