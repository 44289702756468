// @flow

import React from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';
import clsx from 'clsx';
import style from './radio-group-item.module.scss';

type InternalProps = {
  value: string,
  label: MessageTranslator,
  checked: boolean,
  disabled?: boolean,
  className?: string,
  onChange: (string) => void,
  groupName: string,
};

type Props = {
  value: string,
  label: MessageTranslator,
  className?: string,
};

export const RadioGroupItemInternal = ({
  value,
  label,
  onChange,
  checked,
  disabled,
  className,
  groupName,
}: InternalProps) => {
  const onChangeHandler = (event) => onChange(event.target.value);
  return (
    <div className={clsx(style.RadioGroupItem, className)}>
      <label htmlFor={value}>
        <input
          name={groupName}
          type="radio"
          value={value}
          id={value}
          checked={checked}
          disabled={disabled}
          onChange={onChangeHandler}
        />
        {label}
      </label>
    </div>
  );
};

export const RadioGroupItem = ({
  value,
  label,
  className,
}: Props) => (
  <RadioGroupItemInternal className={className} value={value} label={label} onChange={null} groupName="default" />
);
