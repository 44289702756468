import {
  useState,
  useRef,
  useCallback,
  ComponentProps,
} from 'react';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

// eslint-disable-next-line import/named
import { Popover, PopoverContent } from 'components/popover';
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';
import ColorPicker from 'components/color-picker';
import ColorBubble from 'components/color-picker/color-bubble';
import style from './color-picker-field.module.scss';

type Props = {
  disabled?: boolean,
  color: string,
  setColor: (color: string) => void,
}

export const ColorPickerField = ({
  color,
  setColor,
  disabled = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const triggerRef = useRef<HTMLDivElement>(null);

  const applyColor = useCallback((selectedColor: string) => {
    setIsOpen(false);
    setColor(selectedColor);
  }, [setColor]);

  const removeColor = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClick: ComponentProps<typeof PopoverTriggerButton>['onClick'] = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const handlePointerDownOutside: ComponentProps<typeof PopoverContent>['onPointerDownOutside'] = (event) => {
    if (event.target === triggerRef.current) {
      return;
    }
    setIsOpen(false);
  };

  const colorPickerField = clsx(style.ColorPickerField, {
    [style.Disabled]: disabled,
  });

  return (
    <Popover open={isOpen}>
      <div>
        <div
          className={!disabled ? style.ColorPickerFieldTitle : style.ColorPickerFieldTitleDisabled}
        >
          <Message
            id="Color"
            comment="Label for color picker field in formatting section"
          />
        </div>
        <div className={style.ColorButtonWrapper}>
          <PopoverTriggerButton
            customClass={style.ColorButton}
            onClick={handleClick}
            disabled={disabled}
          >
            <div
              ref={triggerRef}
              className={colorPickerField}
            >
              <ColorBubble
                color={color}
                disabled={disabled}
              />
              <p>
                {color}
              </p>
            </div>
          </PopoverTriggerButton>
        </div>
      </div>
      <PopoverContent
        sideOffset={2}
        onEscapeKeyDown={() => setIsOpen(false)}
        onPointerDownOutside={handlePointerDownOutside}
        className={style.ColorPickerPopover}
      >
        <ColorPicker
          preSelectedColor={color}
          applyColor={applyColor}
          removeColor={removeColor}
        />
      </PopoverContent>
    </Popover>
  );
};
