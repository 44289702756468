// @flow

import { put } from 'redux-saga/effects';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getLibraryTemplates,
  createTemplateFromLibrary,
} from 'oneflow-client/library-templates';

import type { NormalizedLibraryTemplates } from 'oneflow-client/library-templates';
import type { PutEffect } from 'redux-saga';

import apiWrapper from 'sagas/api-wrapper';

import libraryTemplates from 'reducers/entities/library-templates';

type MapperArgs = {
  data: NormalizedLibraryTemplates,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(libraryTemplates.setLibraryTemplates(data.entities.libraryTemplates));
}

const mappers = {
  query: {
    mapper,
    request: getLibraryTemplates,
  },
  create: {
    mapper,
    request: createTemplateFromLibrary,
  },
};

const libraryTemplatesSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: libraryTemplates,
  mappers,
});

export default libraryTemplatesSagas;
