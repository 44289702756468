// @flow

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';
import { push } from 'connected-react-router';

import rolesReducer from 'reducers/entities/roles';
import workspaceUsersReducer from 'reducers/entities/workspace-users';
import workspacesReducer from 'reducers/entities/workspaces';
import ModalForm from 'hocs/modal-form';
import {
  isUserLimited,
  isUserActive,
  USER_WORKSPACE_ROLE_READ_ONLY,
  USER_ROLE_SCOPE_WORKSPACE,
} from 'user';

import { checkAcl } from 'components/acl';
import GrantUserAccessIcon from 'components/icons/grant-user-access';
import Button from 'components/button';
import SelectField from 'components/select-field';
import Field from 'components/field';

export type AvailableWorkspace = {
  id: number,
  name: string,
}

export type FormData = {
  workspaces: Array<AvailableWorkspace>,
  role: Role,
}

export type QueryFuncArgs = {
  name: string,
  pagination: Pagination,
}

export type LoadMoreArgs = {
  additionalResults: number
}

export type Props = {
  position: Position,
  shouldRedirectToWorkspaceAccess?: boolean,
  message: MessageTranslator,
  children: React.Node,
};

const rolesQueryName = 'grant-user-workspace-access/roles';

const defaultPagination = {
  limit: 50,
  offset: 0,
};

const WORKSPACES_QUERY_NAME = 'admin/workspaces';

export const AssignUserToWorkspaces = ({
  children,
  message,
  position,
  shouldRedirectToWorkspaceAccess,
}: Props) => {
  const dispatch = useDispatch();
  const workspacesQuery = useSelector((state) => (
    workspacesReducer.getQuerySelector(state, {
      name: WORKSPACES_QUERY_NAME,
    })
  ));
  const allWorkspaces = useSelector((state) => (
    workspacesReducer.getWorkspacesSelector(state, {
      ids: workspacesQuery.result,
    })
  ));
  const rolesQuery = useSelector((state) => (
    rolesReducer.getQuerySelector(state, { name: rolesQueryName })
  ));
  const roles = useSelector((state) => (
    rolesReducer.getRolesSelector(state, { ids: rolesQuery.result })
  ));
  const formState = useSelector((state) => (
    workspaceUsersReducer.getAssignUserWorkspacesSelector(state, { id: position.id })
  ));
  const resetFormState = () => {
    dispatch(workspaceUsersReducer.assignUserWorkspacesReset({ id: position.id }));
  };

  const onSubmit = ({ workspaces, role }) => {
    const workspaceIds = workspaces.map((workspace) => workspace.id);
    let actionData = {
      id: position.id,
      data: {
        actorId: position.id,
        actorType: 'position',
        positionId: position.id,
        resourceId: workspaces[0].id,
        resourceType: 'collection',
        roleId: role.id,
        workspaceIds,
      },
    };

    if (shouldRedirectToWorkspaceAccess && workspaceIds.length === 1) {
      actionData = {
        ...actionData,
        pipe: {
          action: () => push(`/admin/workspaces/${workspaceIds[0]}/access`),
        },
      };
    }

    dispatch(workspaceUsersReducer.assignUserWorkspaces(actionData));
  };

  const queryRoles = ({ name, pagination = defaultPagination }) => {
    dispatch(rolesReducer.queryRoles({
      name: rolesQueryName,
      params: {
        q: name,
        scope: USER_ROLE_SCOPE_WORKSPACE,
      },
      pagination,
      sort: ['-type', 'name'],
    }));
  };

  const queryRolesLoadMore = ({ additionalResults }) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: rolesQueryName,
      additionalResults,
    }));
  };

  const queryWorkspaces = ({ pagination = defaultPagination }) => {
    dispatch(workspacesReducer.queryWorkspaces({
      name: WORKSPACES_QUERY_NAME,
      pagination,
      params: {
        workspaceAdmin: 1,
        canBind: 1,
      },
    }));
  };

  const queryWorkspacesLoadMore = ({ additionalResults }) => {
    dispatch(workspacesReducer.queryWorkspacesLoadMore({
      name: WORKSPACES_QUERY_NAME,
      additionalResults,
    }));
  };

  const runInitialQueries = () => {
    queryWorkspaces({});
    queryRoles({});
  };

  const handleLoadMoreWorkspaces = (additionalResults: number) => {
    queryWorkspacesLoadMore({
      additionalResults,
    });
  };

  const handleLoadMoreRoles = (additionalResults: number) => {
    queryRolesLoadMore({
      additionalResults,
    });
  };

  const getDefaultValue = () => {
    if (isUserLimited(position)) {
      return roles.find((role) => role.id === USER_WORKSPACE_ROLE_READ_ONLY);
    }

    return undefined;
  };

  const renderBody = () => (
    <div>
      <Field
        name="workspaces"
        label={message({
          id: 'Workspace',
          comment: 'Input label',
        })}
        placeholder={message({
          id: 'Select workspace',
          comment: 'Input field placeholder for the workspace field',
        })}
        component={SelectField}
        options={allWorkspaces}
        valueKey="id"
        labelKey="name"
        isLoading={workspacesQuery.loading}
        loadMoreItems={handleLoadMoreWorkspaces}
        required
        multi
      />
      <Field
        name="role"
        label={message({
          id: 'Role',
          comment: 'Input label of the role field',
        })}
        placeholder={message({
          id: 'Select role',
          comment: 'Input field placeholder of the role field',
        })}
        component={SelectField}
        options={roles}
        valueKey="id"
        labelKey="name"
        backspaceRemoves
        isLoading={rolesQuery.loading}
        loadMoreItems={handleLoadMoreRoles}
        defaultValue={getDefaultValue()}
        disabled={isUserLimited(position)}
        required
      />
    </div>
  );

  const getChildren = (onClick: Function) => {
    if (children) {
      return children(onClick);
    }

    const disabledCheck = !isUserActive(position) || !checkAcl(position.acl, 'position:collection:binding:create');

    return (
      <Button
        icon={GrantUserAccessIcon}
        kind="primary"
        onClick={onClick}
        disabled={disabledCheck}
      >
        <Message
          id="Grant workspace access"
          comment="Button for opening grant workspace access modal."
        />
      </Button>
    );
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Grant workspace access"
          comment="Modal title for granting workspace access"
        />
      )}
      body={renderBody()}
      onSubmit={onSubmit}
      resetFormState={resetFormState}
      formState={formState}
      onOpen={runInitialQueries}
      modalKey="grant workspace access modal"
    >
      {getChildren}
    </ModalForm>
  );
};

export default localize<Props>(AssignUserToWorkspaces);
