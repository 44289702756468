/* eslint-disable react/prop-types */

import React, { useState, useCallback } from 'react';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  pointerWithin,
  DndContext,
  MeasuringStrategy,
} from '@dnd-kit/core';
import { findIndex } from 'lodash';
import { localize } from '@oneflowab/pomes';
import clsx from 'clsx';

import { useFormBoxProps } from 'contexts/form-box-props';

import joinListWithSeparator from 'utils/join-list-with-separator';

import BoxWrapper from 'components/contract-boxes/box-wrapper';
import { MenuItem } from 'components/menu-item';
import Message from 'components/message';
import NewCheck from 'components/icons/new-check';
import FormBoxLayout from 'components/contract-boxes/form-box/layout/form-box-layout';

import * as formBoxHelpers from './helpers';
import style from './form-box.module.scss';

const {
  COLUMN_OPTIONS,
  getInitialFieldData,
  getIsBoxEmpty,
  INITIAL_INDEX_MAP,
} = formBoxHelpers;

const getIconClasses = (showIcon) => (
  clsx(style.Icon, {
    [style.ShowIcon]: showIcon,
  })
);

const FormBox = ({
  message,
  onAddSectionRules,
  onRemoveBox,
  permissions,
}) => {
  const [activePopoverIndexMap, setActivePopoverIndexMap] = useState(null);
  const [activeField, setActiveField] = useState(null);

  const {
    addRow,
    boxId,
    config,
    editField,
    isEditable,
    order,
    setColumnCount,
    toggleColleagueEdit,
    toggleCounterpartyEdit,
    updateOrder,
  } = useFormBoxProps();

  const {
    colCount,
    colleagueEdit,
    counterpartEdit,
    managerLock,
  } = config;
  const isEmpty = getIsBoxEmpty(order);
  const [dndActive, setDndActive] = useState(false);

  const getMenuItem = useCallback((columnCount) => (
    <MenuItem
      disabled={!permissions.updateConfig}
      icon={<NewCheck className={getIconClasses(colCount === columnCount)} />}
      key={columnCount}
      label={(
        <Message
          id="1 column"
          pluralId="{count} columns"
          pluralCondition="count"
          values={{ count: columnCount }}
          comment="A message to select the number of columns in the contract form box"
        />
      )}
      onClick={setColumnCount(columnCount)}
    />
  ), [colCount, permissions.updateConfig, setColumnCount]);

  const setActivePopover = (indexMap) => () => {
    setActivePopoverIndexMap(indexMap);
  };

  const isDraggableFieldActive = (active) => active && active.id.indexOf('draggable') > -1;
  const isDraggableRowActive = (active) => active && active.id.indexOf('key-') > -1;

  const handleDragEnd = ({ active, over }) => {
    setDndActive(false);
    if (isDraggableFieldActive(active)) {
      if (over) {
        const sourceIdxs = active.id.split('_');
        const targetIdxs = over.id.split('_');
        const sourceRow = sourceIdxs[1];
        const sourceColumn = sourceIdxs[2];
        const targetRow = targetIdxs[1];
        const targetColumn = targetIdxs[2];
        if (sourceRow && sourceColumn && targetRow && targetColumn) {
          const newField = order[sourceRow][sourceColumn];
          order[targetRow][targetColumn] = newField;
          order[sourceRow][sourceColumn] = null;
          updateOrder(order);
        }
      }
      return;
    }

    if (over && active.id !== over.id) {
      const activeIndex = findIndex(order, (item) => item.id === active.id);
      const overIndex = findIndex(order, (item) => item.id === over.id);
      const updatedBoxOrder = arrayMove(order, activeIndex, overIndex);

      updateOrder(updatedBoxOrder);
    }
  };

  const handleDragStart = ({ active }) => {
    if (isDraggableFieldActive(active)) {
      setDndActive(true);
    } else if (isDraggableRowActive(active)) {
      setActiveField(null);
    }
  };

  const toggleLock = () => {
    toggleColleagueEdit(true);
  };

  const menuItems = COLUMN_OPTIONS.map((columnCount) => getMenuItem(columnCount));

  let additionalMenuOptions = [
    <MenuItem
      disabled={!permissions.updateConfig}
      icon={<NewCheck className={getIconClasses(counterpartEdit)} height="14px" />}
      label={(
        <Message
          id="Allow counterparties to edit field values"
          comment="Menu option for allowing counterparties to edit field values"
        />
      )}
      onClick={toggleCounterpartyEdit}
    />,
  ];

  if (managerLock) {
    additionalMenuOptions = [
      ...additionalMenuOptions,
      <MenuItem
        disabled={!permissions.updateConfig}
        icon={<NewCheck className={getIconClasses(colleagueEdit)} height="14px" />}
        label={(
          <Message
            id="Allow colleagues to edit field values"
            comment="Menu option for allowing counterparties to edit field values"
          />
        )}
        onClick={toggleColleagueEdit}
      />,
    ];
  }

  const actions = joinListWithSeparator([
    menuItems,
    additionalMenuOptions,
  ], 'separator').flat();

  const onAddFieldFromEmpty = useCallback(() => {
    editField(INITIAL_INDEX_MAP, getInitialFieldData(message));
    setActivePopoverIndexMap(INITIAL_INDEX_MAP);
  }, [editField, message]);

  return (
    <BoxWrapper
      boxId={boxId}
      isAllowedToEdit={isEditable}
      nonFixedActions={actions}
      onAddSectionRules={onAddSectionRules}
      onRemoveBox={onRemoveBox}
      triggerToggleLock={toggleLock}
    >
      <DndContext
        collisionDetection={pointerWithin}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <SortableContext
          items={order.map((row) => row.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className={style.FormWrapper}>
            <FormBoxLayout
              activePopoverIndexMap={activePopoverIndexMap}
              isEmpty={isEmpty}
              onAddFieldFromEmpty={onAddFieldFromEmpty}
              onAddRow={addRow}
              setActivePopover={setActivePopover}
              dndActive={dndActive}
              setActiveField={setActiveField}
              activeField={activeField}
            />
          </div>
        </SortableContext>
      </DndContext>
    </BoxWrapper>
  );
};

export default localize(FormBox);
