/* eslint-disable import/named */
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Message from 'components/message';

import { getGuestToken } from 'agreement/selectors';
import { DurationType } from 'data-validators/entity-schemas/document-box/duration-box';

import { BoxEmptyDraftStateDuration } from 'components/box-empty-draft-state';
import { Recurring } from 'components/contract-boxes/duration-box/recurring';
import { RecurringWithTwoPeriods } from 'components/contract-boxes/duration-box/recurring-with-two-periods';
import { SetDate } from 'components/contract-boxes/duration-box/set-date';
import { SinglePeriod } from 'components/contract-boxes/duration-box/single-period';
import {
  TYPE_NO_DURATION,
  TYPE_RECURRING_WITH_INITIAL,
  TYPE_RECURRING,
  TYPE_SINGLE_PERIOD,
} from 'agreement/constants';
import { useDurationBoxProps } from 'contexts/duration-box-props';
import Calendar from 'components/icons/calendar-box';

type Props = {
  onChangeDurationType: ({ type }: { type: DurationType }) => void,
}

export const DurationContainer = ({
  onChangeDurationType,
}: Props) => {
  const {
    isAllowedToEditBoxData,
    isViewer,
    startDate,
    type: contractType,
  } = useDurationBoxProps();

  const guestToken = useSelector(getGuestToken);
  const isGuest = Boolean(guestToken);

  const onChangeInitialDurationType = useCallback((event: { value: DurationType }) => {
    const type = event?.value;
    onChangeDurationType({ type });
  }, [onChangeDurationType]);

  if (contractType === TYPE_NO_DURATION) {
    if (!startDate) {
      return (
        <Message
          id="The contract has no duration."
          comment="This message shows that user picked no duration"
        />
      );
    }

    return (
      <Message
        id="The start date for the contract is {setStartDateComponent}."
        comment="Text to show the start date of contract."
        values={{
          setStartDateComponent: <SetDate dateType="startDate" />,
        }}
      />
    );
  }

  if (contractType === TYPE_SINGLE_PERIOD) {
    return <SinglePeriod />;
  }

  if (contractType === TYPE_RECURRING) {
    return <Recurring />;
  }

  if (contractType === TYPE_RECURRING_WITH_INITIAL) {
    return <RecurringWithTwoPeriods />;
  }

  if (isGuest) {
    return null;
  }

  if (isViewer) {
    return (
      <i>
        <Message
          id="No duration specified for this contract."
          comment="Text showing when there is no duration specified for this contract."
        />
      </i>
    );
  }

  return (
    <BoxEmptyDraftStateDuration
      icon={Calendar}
      isDisabled={!isAllowedToEditBoxData}
      onChange={onChangeInitialDurationType}
    />
  );
};
