import { useEffect, useState } from 'react';

export const useVirtualKeyboardInfo = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [isKeyboardOnscreen, setIsKeyboardOnscreen] = useState(false);

  useEffect(() => {
    const handleViewportChange = () => {
      if (window.visualViewport) {
        const height = window.innerHeight - window.visualViewport.height;
        setKeyboardHeight(height > 0 ? height : 0);
        setIsKeyboardOnscreen(height > 0);
      }
    };

    window.visualViewport?.addEventListener('resize', handleViewportChange);
    window.visualViewport?.addEventListener('scroll', handleViewportChange);
    handleViewportChange();

    return () => {
      window.visualViewport?.removeEventListener('resize', handleViewportChange);
      window.visualViewport?.removeEventListener('scroll', handleViewportChange);
    };
  }, []);

  return {
    keyboardHeight,
    isKeyboardOnscreen,
  };
};
