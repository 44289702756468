import styles from './skeleton.module.scss';

type SkeletonProps = React.HTMLAttributes<HTMLDivElement>

function Skeleton({ className, ...props }: SkeletonProps) {
  return (
    <div
      className={`${styles.Skeleton} ${className}`}
      {...props}
    />
  );
}

export default Skeleton;
