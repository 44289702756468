import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import type { MessageTranslator } from '@oneflowab/pomes';
import { USER_ROLE_SCOPE_ACCOUNT } from 'user';

import rolesReducer from 'reducers/entities/roles';

import Field from 'components/field';
import Checkbox from 'components/checkbox';
import SelectField from 'components/select-field';
import Message from 'components/message';

import {
  LIMIT,
  INPUT_DEBOUNCE_TIME,
} from '../constants';

import style from '../invite-user.module.scss';

export const ACCOUNT_ROLES_QUERY_NAME = 'InviteUser/accountRoles';

type AccountSectionProps = {
  isLimitedSelected: boolean,
  hasAccessToSystemAccountRoles: boolean,
  hasAccessToCustomAccountRoles: boolean,
  message: MessageTranslator,
};

const AccountAccessSection = ({
  isLimitedSelected,
  hasAccessToSystemAccountRoles,
  hasAccessToCustomAccountRoles,
  message,
}: AccountSectionProps): JSX.Element => {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const accountRolesQuery = useSelector((state) => (
    rolesReducer.getQuerySelector(state, { name: ACCOUNT_ROLES_QUERY_NAME })
  ));

  const accountRoles = useSelector((state) => (
    rolesReducer.getRolesSelector(state, { ids: accountRolesQuery.result })
  ));

  const dispatch = useDispatch();
  const queryMatchingAccountRoles = useCallback(({ searchValue }: { searchValue: string }) => (
  ) => {
    dispatch(rolesReducer.queryRoles({
      name: ACCOUNT_ROLES_QUERY_NAME,
      pagination: {
        limit: LIMIT,
      },
      params: {
        scope: USER_ROLE_SCOPE_ACCOUNT,
        q: searchValue,
      },
      sort: ['-type', 'name'],
    }));
  }, [dispatch]);
  const queryAccountRolesLoadMore = ({ additionalResults }: { additionalResults: number}) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: ACCOUNT_ROLES_QUERY_NAME,
      params: {
        scope: USER_ROLE_SCOPE_ACCOUNT,
      },
      sort: ['-type', 'name'],
      additionalResults,
    }));
  };

  const onLoadMoreAccountRoles = (additionalResults: number) => {
    queryAccountRolesLoadMore({ additionalResults });
  };

  const debouncedQueryAccountRolesSearch = useMemo(() => debounce((searchValue: string) => {
    queryMatchingAccountRoles({ searchValue });
  }, INPUT_DEBOUNCE_TIME, { leading: true }), [queryMatchingAccountRoles]);

  const getAccountRolesOnInputChange = (searchValue: string) => {
    debouncedQueryAccountRolesSearch(searchValue);
  };

  const renderAccountRolesField = () => {
    if (!hasAccessToCustomAccountRoles || !hasAccessToSystemAccountRoles) {
      return null;
    }

    return (
      <>
        <p className={style.Description}>
          <Message
            id="Select an account role that allows the user to control certain or all areas of the account."
            comment="Section header in invite user modal."
          />
        </p>
        <div className={style.AccountRoleField}>
          <Field
            name="accountRole"
            data-testid="account-role-field"
            placeholder={message({
              id: 'Select account role',
              comment: 'Field placeholder in invite user modal.',
            })}
            component={SelectField}
            valueKey="id"
            labelKey="name"
            options={isLimitedSelected ? [] : accountRoles}
            isLoading={accountRolesQuery?.loading}
            onInputChange={getAccountRolesOnInputChange}
            disabled={isLimitedSelected}
            loadMoreItems={onLoadMoreAccountRoles}
            searchable
            clearable
          />
        </div>
      </>
    );
  };

  const renderAccountRoleCheckbox = () => {
    if (hasAccessToCustomAccountRoles || !hasAccessToSystemAccountRoles) {
      return null;
    }

    return (
      <>
        <p className={style.CheckboxDescription}>
          <Message
            id="Grant this user account administrator rights. An account administrator can manage role permissions for all users in this account."
            comment="Section header in invite user modal."
          />
        </p>
        {isLimitedSelected
          ? (
            <div className={style.AccountRoleCheckbox}>
              <Field
                name="accountRoleCheckbox"
                data-testid="account-role-checkbox"
                type="checkbox"
                label={message({
                  id: 'Assign account admin role',
                  comment: 'Field placeholder in invite user modal.',
                })}
                component={Checkbox}
                disabled
                input={{
                  value: checkboxValue,
                  checked: checkboxValue,
                  onChange: () => setCheckboxValue(!checkboxValue),
                }}
                handleTextOverflow
              />
            </div>
          ) : (
            <div className={style.AccountRoleCheckbox}>
              <Field
                name="accountRoleCheckbox"
                data-testid="account-role-checkbox"
                type="checkbox"
                label={message({
                  id: 'Assign account admin role',
                  comment: 'Field placeholder in invite user modal.',
                })}
                component={Checkbox}
                handleTextOverflow
              />
            </div>
          )}
      </>
    );
  };

  return (
    <div>
      <h2 className={style.Title}>
        <Message
          id="Access and roles"
          comment="Heading for section within the invite user modal"
        />
      </h2>
      <h3 className={style.SectionTitleAccount}>
        <Message id="Account role" comment="Section title in invite user modal" />
      </h3>
      {renderAccountRolesField()}
      {renderAccountRoleCheckbox()}
    </div>
  );
};

export default AccountAccessSection;
