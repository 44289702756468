// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { ConfirmButton } from 'components/buttons';
import Confirmable from 'components/confirmable';
import { ContractDataFields } from 'components/fields';

export type Props = {|
  children: ((onClick: Function) => React.Node) | React.Node,
  onAddDataField: Function,
  agreement: Agreement,
|};

export const ContractDataFieldsModal = ({
  children,
  onAddDataField,
  agreement,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedDataField, setSelectedDataField] = React.useState(null);

  const handleClose = () => {
    setIsOpen(false);
    setSelectedDataField(null);
  };

  const handleConfirm = () => {
    if (!selectedDataField) {
      return;
    }

    onAddDataField(selectedDataField);
    handleClose();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleOnChange = (dataField: any) => {
    setSelectedDataField(dataField);
  };

  const renderBody = () => (
    <ContractDataFields
      agreement={agreement}
      onChange={handleOnChange}
      selectedDataField={selectedDataField}
    />
  );

  const getActions = () => (
    <ConfirmButton
      disabled={!selectedDataField}
      onClick={() => {
        handleConfirm();
      }}
      isLoading={false}
    />
  );

  return (
    <Confirmable
      header={(
        <Message
          id="Select a data field"
          comment="Modal title for selecting a data field."
        />
      )}
      body={renderBody()}
      actions={getActions}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      onClose={handleClose}
      onOpen={handleOpen}
      isOpen={isOpen}
      modalKey="select data field modal"
      portalClassName="rich-text-region"
    >
      {children}
    </Confirmable>
  );
};
