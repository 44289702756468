// @flow

import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import contactsReducer from 'reducers/entities/contacts';
import { getCurrentWorkspaceSelector } from 'reducers/app';

import Contact from './contact';

export const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  const contact = contactsReducer.getContactSelector(state, { id });

  return {
    contact,
    workspace: getCurrentWorkspaceSelector(state),
    fetchState: contactsReducer.getFetchSelector(state, { id }),
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchContact: () => {
    dispatch(contactsReducer.fetchContact({ id: ownProps.match.params.id }));
  },
  navigateToContactList: () => {
    dispatch(replace('/address-book/contacts'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
