/* eslint-disable import/named */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';
import { localize } from '@oneflowab/pomes';

import { isFeatureEnabledSelector } from 'reducers/session';
import type { RootState } from 'reducers';

import {
  getAvailableDeliveryChannels,
  getAvailableTwoStepAuthenticationChannels,
  getAvailableSignMethods,
  getFirstAvailableSignMethod,
} from 'agreement';
import { shouldDisableField } from 'agreement/agreement-participants';
import * as participantConstants from 'agreement/participant/constants';

import { ExpandableOptionsHeader } from 'components/expandable-options-header';
import {
  SignMethodField,
  DeliveryChannelField,
  TwoStepAuthenticationMethodField,
} from 'components/fields';

import style from './signing-details.module.scss';

type SingDetails = {
  signMethod?: number
  deliveryChannel?: number
  twoStepAuthenticationMethod?: string | null
}

type Props = {
  message: MessageTranslator,
  agreement: Oneflow.Agreement,
  participant?: Oneflow.Participant,
  colleague?: boolean,
  onChange?: (details: SingDetails) => void,
  hideSignMethod?: boolean,
  values?: SingDetails,
  participantAcl?: Oneflow.Participant['acl'],
  account: Oneflow.Account,
  signMethodsDescription?: ReactNode,
}

export const SigningDetails = ({
  message,
  agreement,
  participant,
  colleague,
  onChange,
  hideSignMethod,
  values,
  participantAcl,
  account,
  signMethodsDescription,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleArea = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
  };

  const isPersonalIdFeatureEnabled = useSelector(
    (state: RootState) => isFeatureEnabledSelector(state, {
      feature: 'personalIdentificationMfa',
    }),
  );

  const areaClassNames = clsx(style.SigningDetailsArea, {
    [style.Hidden]: !isExpanded,
  });

  const shouldRenderSignMethod = () => !hideSignMethod;

  const renderSignMethod = () => {
    if (!shouldRenderSignMethod()) {
      return null;
    }

    const currentDeliveryChannel = values?.deliveryChannel;

    const firstAvailable = getFirstAvailableSignMethod(
      agreement,
      values?.signMethod,
      currentDeliveryChannel,
      colleague,
      account,
    );

    return (
      <SignMethodField
        availableSignMethods={
          getAvailableSignMethods(
            agreement,
            currentDeliveryChannel,
            colleague,
            account,
          )
        }
        onChange={(option: { value: number }) => onChange && onChange({ signMethod: option.value })}
        initialValue={firstAvailable}
        disabled={shouldDisableField(participantAcl, 'participant:update:sign_method')}
        signMethodsDescription={signMethodsDescription}
      />
    );
  };

  const shouldRenderDeliveryChannel = () => !colleague;

  const renderDeliveryChannel = () => {
    if (!shouldRenderDeliveryChannel()) {
      return null;
    }

    const onDeliveryChannelChange = (option: { value: number }) => {
      if (onChange) {
        const updatedSignMethod = getFirstAvailableSignMethod(
          agreement,
          values?.signMethod,
          option.value,
          colleague,
          account,
        );

        // also need to update the sign method if same device is selected as delivery channel
        onChange({
          deliveryChannel: option.value,
          signMethod: updatedSignMethod,
        });
      }
    };

    return (
      <DeliveryChannelField
        availableChannels={getAvailableDeliveryChannels(agreement, participant, account)}
        onChange={onDeliveryChannelChange}
        initialValue={values?.deliveryChannel}
        disabled={shouldDisableField(participantAcl, 'participant:update:delivery_channel')}
      />
    );
  };

  const mapTwoStepAuthenticationMethod = (method: string | null | undefined) => {
    if (method === null) {
      return 'none';
    }

    return method;
  };

  const shouldRenderTwoStepAuthentication = () => (
    !colleague && values?.deliveryChannel !== participantConstants.DELIVERY_CHANNEL_SAME_DEVICE
  );

  const renderTwoStepAuthenticationField = () => {
    if (!shouldRenderTwoStepAuthentication()) {
      return null;
    }

    return (
      <TwoStepAuthenticationMethodField
        availableMethods={getAvailableTwoStepAuthenticationChannels(
          agreement,
          null,
          isPersonalIdFeatureEnabled,
          account,
        )}
        onChange={
          (option: { value: string | null }) => onChange && onChange(
            { twoStepAuthenticationMethod: option.value },
          )
        }
        initialValue={mapTwoStepAuthenticationMethod(values?.twoStepAuthenticationMethod)}
        disabled={shouldDisableField(participantAcl, 'participant:update:mfa_channel')}
      />
    );
  };

  if (
    !shouldRenderDeliveryChannel()
    && !shouldRenderSignMethod()
    && !shouldRenderTwoStepAuthentication()
  ) {
    return null;
  }

  return (
    <div className={style.ExpandableArea}>
      <ExpandableOptionsHeader
        title={message({
          id: 'Signing details',
          comment: 'Used as the header of the section in the counterparty modal.',
        })}
        onClick={toggleArea}
        isExpanded={isExpanded}
        customClass={style.SectionHeader}
      />
      <div className={areaClassNames}>
        <div className={style.Row}>
          {renderSignMethod()}
          {renderDeliveryChannel()}
        </div>
        <div className={style.Row}>
          {renderTwoStepAuthenticationField()}
        </div>
      </div>
    </div>
  );
};

export default localize<Props>(SigningDetails);
