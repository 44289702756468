import clsx from 'clsx';

import sessionStorage from 'utils/session-storage';
import type { FlattenedAiData } from 'components/modals/import-contract/helpers';
import AiSparkleIcon from 'components/icons/ai-sparkle';
import style from './import-sparkle.module.scss';

type Props = {
  agreementId: number;
  type: 'boxes' | 'parties' | 'participants' | 'closeTime';
  matchId?: number;
  className?: string;
};

export const ImportSparkle = ({
  agreementId,
  type,
  matchId,
  className,
}: Props) => {
  const storedData = sessionStorage.getItem('extracted_data');
  if (!storedData) {
    return null;
  }
  const extractedData = JSON.parse(storedData)?.[agreementId] as FlattenedAiData;
  const isAiExtracted = () => {
    if (type === 'closeTime') {
      return Boolean(extractedData?.closeTime);
    }
    return Boolean(extractedData?.[type]?.[matchId as number]);
  };

  if (isAiExtracted()) {
    return (
      <div className={clsx(style.ImportSparkle, className)}>
        <AiSparkleIcon className={style.SparkleIcon} height="12px" />
        <small>AI Extract</small>
      </div>
    );
  }

  return null;
};
