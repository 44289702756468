/* eslint-disable import/named */
import { useMemo } from 'react';
import clsx from 'clsx';

import useMatchMedia, { DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY } from 'hooks/use-match-media';

import { useRichTextProps } from 'contexts/rich-text';

import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';

import {
  Popover,
  PopoverContent,
  PopoverAnchor,
  PopoverPortal,
} from 'components/popover';

import { useSelectedRectanglePopoverProps } from './use-selected-rectangle-popover-props';

import style from './selected-rectangle-popover.module.scss';

type Props = {
  children: React.ReactNode,
  handleEscapeKeyDown: () => void,
  handleInteractOutside: () => void,
  contentClasses: string,
};

const SelectedRectanglePopover = ({
  children,
  handleEscapeKeyDown,
  handleInteractOutside,
  contentClasses,
}: Props) => {
  const { documentScrollContainerRef, documentContentWrapperNode } = useDocumentLayout();
  const isCollapsedLayout = useMatchMedia(DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY);

  const {
    isVisible,
    toolbarRef,
    sideOffset,
    side,
  } = useSelectedRectanglePopoverProps();

  const { selectedRectangleAnchorRef, editorRef } = useRichTextProps();

  const collisionBoundary = useMemo(() => {
    if (!isVisible) {
      return null;
    }

    if (isCollapsedLayout && documentScrollContainerRef) {
      return documentScrollContainerRef.current;
    }

    if (documentContentWrapperNode) {
      return documentContentWrapperNode;
    }

    return null;
  }, [
    documentContentWrapperNode,
    documentScrollContainerRef,
    isCollapsedLayout,
    isVisible,
  ]);

  const collisionPadding = useMemo(() => {
    if (!isVisible) {
      return 0;
    }

    const editorRect = editorRef.current?.getBoundingClientRect();
    const documentContentWrapperRect = documentContentWrapperNode?.getBoundingClientRect();

    if (isCollapsedLayout || !documentContentWrapperRect || !editorRect) {
      return 0;
    }

    const collisionLeft = editorRect.left - documentContentWrapperRect.left;
    const collisionRight = documentContentWrapperRect.right - editorRect.right;

    return ({
      right: collisionRight,
      left: collisionLeft,
    });
  }, [
    documentContentWrapperNode,
    editorRef,
    isCollapsedLayout,
    isVisible,
  ]);

  const popoverClassName = clsx(style.PopoverContent, contentClasses, 'rich-text-region');

  return (
    <Popover open={isVisible}>
      <PopoverAnchor virtualRef={selectedRectangleAnchorRef} />
      <PopoverPortal
        container={documentScrollContainerRef?.current}
      >
        <div className={style.PopoverContentContainer}>
          <PopoverContent
            onOpenAutoFocus={(event) => {
              event.preventDefault();
            }}
            arrowHeight={0}
            className={popoverClassName}
            sideOffset={sideOffset}
            collisionBoundary={collisionBoundary}
            side={side}
            hideWhenDetached
            collisionPadding={collisionPadding}
            onEscapeKeyDown={handleEscapeKeyDown}
            onInteractOutside={handleInteractOutside}
          >
            <div
              ref={toolbarRef}
              className={style.SelectedRectanglePopoverContainer}
            >
              {children}
            </div>
          </PopoverContent>
        </div>
      </PopoverPortal>
    </Popover>
  );
};

export default SelectedRectanglePopover;
