// @flow

import { normalize, schema } from 'normalizr';

import { get, put, extractResponseBodyAsJSON } from 'oneflow-client/core';
import { decamelizeKeys } from 'humps';

import { accountSeatsSchema } from 'oneflow-client/accounts';

const billingSchema = new schema.Entity('billings', {
  seats: accountSeatsSchema,
});

export type NormalizedBillings = {
  entities: {
    billings: {
      [number]: Billing,
    },
    accountSeats: {
      [number]: AccountSeats,
    },
  },
  result: number,
  count: number,
};

type BillingNormalizer = (any) => NormalizedBillings;

export const normalizeBilling: BillingNormalizer = (billing) => {
  const normalizedData = normalize(billing, billingSchema);
  delete normalizedData.entities.billings[normalizedData.result].seats;

  return {
    ...normalizedData,
    count: 1,
  };
};

type GetBilling = ({
  id: number,
}) => Promise<NormalizedBillings>;

export const getBilling: GetBilling = ({
  id,
}) => (
  get({
    url: `/accounts/${id}/billing/`,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeBilling)
);

type UpsertBilling = ({
  address: string,
  city: string,
  country: string,
  id: number,
  invoiceEmail: string,
  invoiceReference: string,
  name: string,
  vatNumber: string,
  zipCode: string,
  period: number,
  options: Object,
}) => Promise<NormalizedBillings>;

export const upsertBilling: UpsertBilling = ({
  address,
  city,
  country,
  id,
  invoiceEmail,
  invoiceReference,
  name,
  vatNumber,
  zipCode,
  period,
  amplitudeScope,
}) => put({
  url: `/accounts/${id}/billing/`,
  body: decamelizeKeys({
    address,
    city,
    country,
    invoiceEmail,
    invoiceReference,
    name,
    vatNumber,
    zipCode,
    period,
  }, { separator: '_' }),
}, { amplitudeScope }).then(extractResponseBodyAsJSON)
  .then(normalizeBilling);

type UpdateBilling = ({
  address: string,
  city: string,
  country: string,
  id: number,
  invoiceEmail: string,
  invoiceReference: string,
  name: string,
  vatNumber: string,
  zipCode: string,
  period: number,
  options: Object,
}) => Promise<NormalizedBillings>;

export const updateBilling: UpdateBilling = ({
  address,
  city,
  country,
  id,
  invoiceEmail,
  invoiceReference,
  name,
  vatNumber,
  zipCode,
  period,
  amplitudeScope,
}) => put({
  url: `/accounts/${id}/billing/default/`,
  body: decamelizeKeys({
    address,
    city,
    country,
    invoiceEmail,
    invoiceReference,
    name,
    vatNumber,
    zipCode,
    period,
  }, { separator: '_' }),
}, { amplitudeScope }).then(extractResponseBodyAsJSON)
  .then(normalizeBilling);
