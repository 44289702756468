// @flow

import React from 'react';
import urlJoin from 'url-join';
import { Redirect, type Match } from 'react-router';

import adminPage from 'hocs/admin-page';
import type { MessageTranslator } from '@oneflowab/pomes';

import GroupDetails from './details';
import GroupMembers from './members';
import GroupWorkspaceAccess from './workspace-access';
import GroupAccountAccess from './account-access';

type Props = {
  match: Match,
};

type GroupModule = {
  props: {
    group: Group,
    account: Account,
    fetchGroup: (arg: number) => void,
    match: Match,
    message: MessageTranslator,
    membersState: CreateState,
    removeState: RemoveState,
  },
};

export const GroupComponent = ({ match }: Props) => (
  <Redirect from={match.path} to={urlJoin(match.url, '/details')} />
);

export const groupModule = ({
  props: {
    group,
    fetchGroup,
    match,
    message,
    membersState,
    removeState,
  },
}: GroupModule) => ({
  title: group.name || '',
  showAsLink: false,
  modules: [
    [
      {
        path: '/details',
        title: message({ id: 'Details', comment: 'Tab title for group details.' }),
        section: message({
          id: 'Groups',
          comment: 'Used as the title for the section.',
        }),
        component: GroupDetails,
      },
      {
        path: '/members',
        title: message({ id: 'Members', comment: 'Tab title for group members.' }),
        section: message({
          id: 'Groups',
          comment: 'Used as the title for the section.',
        }),
        component: GroupMembers,
      },
      {
        path: '/workspace-access',
        title: message({
          id: 'Workspace access',
          comment: 'Tab title for the workspace access tab in the group pages.',
        }),
        section: message({
          id: 'Groups',
          comment: 'Used as the title for the section.',
        }),
        component: GroupWorkspaceAccess,
      },
      {
        path: '/account-access',
        title: message({
          id: 'Account access',
          comment: 'Tab title for the account access tab in the group pages.',
        }),
        section: message({
          id: 'Groups',
          comment: 'Used as the title for the section.',
        }),
        component: GroupAccountAccess,
      },
    ],
  ],
  componentDidMount: () => {
    fetchGroup(match.params.id);
  },
  componentDidUpdate: (prevProps: Props) => {
    const updateStateChanged = (
      membersState.success !== prevProps.membersState.success && membersState.success === true
    );
    const removeStateChanged = (
      removeState.success !== prevProps.removeState.success && removeState.success === true
    );

    if (updateStateChanged || removeStateChanged) {
      fetchGroup(match.params.id);
    }
  },
});

export default adminPage(groupModule)(GroupComponent);
