// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

type Props = {
  message: MessageTranslator,
};

// eslint-disable-next-line import/prefer-default-export
export const getDaysToRetainText = ({ message }: Props) => (days?: string) => {
  if (!days) {
    return message({
      id: 'Retained forever',
      comment: 'Default text for data retention policy setting.',
    });
  }

  return message({
    id: '{count} day',
    pluralId: '{count} days',
    pluralCondition: 'count',
    values: {
      count: days,
    },
    comment: 'Number of days for a data retention policy setting on the data retention page.',
  });
};
