// @flow

import { connect } from 'react-redux';
import type { Match } from 'react-router';
import { replace } from 'connected-react-router';

import rolesReducer from 'reducers/entities/roles';

import Details from './details';

const QUERY_NAME = 'admin/roles';

type OwnProps = {
  match: Match,
};

export const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const id = parseInt(ownProps.match.params.id, 10);
  const rolesQuery = rolesReducer.getQuerySelector(state, { name: QUERY_NAME });
  const roles = rolesReducer.getRolesSelector(state, { ids: rolesQuery.result });
  const role = roles.find((x) => x.id === id);

  return ({
    role,
    updateState: rolesReducer.getUpdateSelector(state, { id }),
  });
};

export const mapDispatchToProps = (dispatch: Function, ownProps: OwnProps) => {
  const id = parseInt(ownProps?.match.params.id, 10);

  return {
    updateRole: (formData) => {
      dispatch(rolesReducer.updateRole({
        id,
        data: formData,
      }));
    },
    resetUpdateState: () => {
      dispatch(rolesReducer.updateRoleReset({
        id,
      }));
    },
    updatePath: (pathname) => {
      dispatch(replace(pathname));
    },
  };
};

export default connect<Details, OwnProps, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Details);
