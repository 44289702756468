import { Message } from '@oneflowab/pomes';

import { MenuItem } from 'components/menu-item';
import AiSparkleIcon from 'components/icons/ai-sparkle';

import style from './ai-review.module.scss';

export type Props = {
  location: string,
  disabled?: boolean,
  href: string,
};

export const AiReviewMenuItem = ({
  location,
  disabled,
  href,
}: Props) => (
  <MenuItem
    icon={AiSparkleIcon}
    label={(
      <Message
        id="Review with AI"
        comment="Label for AI Review action in menu."
      />
    )}
    disabled={disabled}
    href={href}
    external
    rel="noopener noreferrer"
    target="_blank"
    className={style.AiReviewButton}
    trackable={{
      name: 'Go To Single AI Review',
      props: {
        location,
      },
    }}
    isBeta
  />
);

AiReviewMenuItem.defaultProps = {
  disabled: undefined,
};
