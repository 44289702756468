/* eslint-disable import/named */
import { useRef, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { uniqueId } from 'lodash';
import clsx from 'clsx';
import type { MessageTranslator } from '@oneflowab/pomes';
import { localize } from '@oneflowab/pomes';

import useOutsideClick from 'hooks/use-outside-click';
import { searchAddressBook } from 'oneflow-client/address-book';
import { getCurrentWorkspaceIdSelector } from 'reducers/app';
import { NormalizedContacts } from 'oneflow-client/contacts';

import { AddressBookSearchResult, AddressBookResult } from 'components/address-book-search-result';
import AddressBookSearchNoResults from 'components/address-book-search-no-results';
import { ApiError, unknownApiError } from 'components/api-error';
import CircularSpinner from 'components/icons/circular-spinner';
import { ControlledSearch } from 'components/search';

import style from './address-book-search.module.scss';

type Props = {
  message: MessageTranslator,
  onSearchResultSelected: (param: AddressBookResult) => void,
  companyName?: string,
};

export const AddressBookSearch = ({
  message,
  onSearchResultSelected,
  companyName,
}: Props) => {
  const workspaceId = useSelector((state) => getCurrentWorkspaceIdSelector(state));
  const [searchData, setSearchData] = useState<{ query: string, results: AddressBookResult[] }>({ query: '', results: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [searchResultVisibility, setSearchResultVisibility] = useState(false);
  const inputRef = useRef(null);
  const searchContainerRef = useRef<null | HTMLDivElement>(null);

  const onClickOutside = useCallback(() => {
    setSearchResultVisibility(false);
  }, []);

  useOutsideClick(inputRef, onClickOutside, searchResultVisibility);

  const renderSearchResults = () => {
    const noResult = searchData.query.length > 1 && !searchData.results.length;
    if (isLoading) {
      return (
        <div className={style.SearchResults}>
          <div className={style.LoadingSpinner}>
            <CircularSpinner />
          </div>
        </div>
      );
    }

    if (hasError) {
      const errorClasses = clsx(style.SearchResults, style.SearchError);
      return (
        <div className={errorClasses}>
          <ApiError customMessage={unknownApiError} />
        </div>
      );
    }

    if (!isLoading && noResult) {
      return (
        <div className={style.SearchResults}>
          <AddressBookSearchNoResults />
        </div>
      );
    }

    return (
      <div className={style.SearchResults}>
        {
          searchData.results.map((result) => (
            <AddressBookSearchResult
              key={uniqueId()}
              contact={result}
              onResultClick={onSearchResultSelected}
            />
          ))
        }
      </div>
    );
  };

  const getParams = useCallback((value: string) => {
    const params = {
      q: value,
      sort: [
        '-score',
      ],
      collection_id: workspaceId,
      company_name: companyName || undefined,
    };
    return {
      ...params,
    };
  }, [workspaceId, companyName]);

  const mapSearchResults = useCallback(
    (results: NormalizedContacts) => Object.entries(results.result).map(([, id]) => {
      const person = results.entities.contacts[id];
      return { [`contactId-${id}`]: id, person };
    }), [],
  );

  const fetchResults = useCallback(async (value: string) => {
    setSearchResultVisibility(true);
    try {
      setIsLoading(true);
      const results = await searchAddressBook({
        name: 'address-book/contacts',
        pagination: {
          limit: 50,
        },
        params: getParams(value),
      });

      setIsLoading(false);
      setSearchData({
        query: value,
        results: mapSearchResults(results),
      });
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
    }
  }, [getParams, mapSearchResults]);

  const getContacts = useCallback((value: string) => {
    if (value.length > 1) {
      fetchResults(value);
    } else {
      setSearchResultVisibility(false);
    }
  }, [fetchResults]);

  const renderSearchResultPortal = () => {
    if (!searchResultVisibility) {
      return null;
    }

    if (!searchContainerRef) {
      return renderSearchResults();
    }

    if (!searchContainerRef.current) {
      return null;
    }

    return ReactDOM.createPortal(
      renderSearchResults(),
      searchContainerRef.current,
    );
  };

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key !== 'Escape' || !searchResultVisibility) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setSearchResultVisibility(false);
  }, [searchResultVisibility]);

  return (
    <div
      className={style.InputContainer}
      ref={
        (el) => {
          searchContainerRef.current = el;
        }
      }
    >
      <div className={style.SearchContainer}>
        <ControlledSearch
          onChange={getContacts}
          value={searchData.query}
          onKeyDown={onKeyDown}
          inputRef={inputRef}
          placeholder={message({
            id: 'Search participant from the address book',
            comment: 'Placeholder message the search field to find contacts from the address book.',
          })}
        />
        {renderSearchResultPortal()}
      </div>
    </div>
  );
};

export default localize<Props>(AddressBookSearch);
