// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

type Props = {
  group: Group,
  workspace: Workspace,
  removeState: RemoveState,
  resetRemoveState: Function,
  removeGroupFromWorkspace: Function,
  children: Function,
};

export const handleConfirm = (removeGroupFromWorkspace: Function, isLoading: boolean) => () => {
  if (isLoading) {
    return;
  }

  removeGroupFromWorkspace();
};

const RemoveGroupFromWorkspace = ({
  group,
  workspace,
  removeState,
  removeGroupFromWorkspace,
  resetRemoveState,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={removeGroupFromWorkspace}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <Message
        id="You are about to remove access to {workspace} for {actorName}."
        values={{
          actorName: <Badge label={group.name} kind="name" />,
          workspace: <Badge label={workspace.name || ''} kind="name" />,
        }}
        comment="Modal text when removing workspace access for a group."
      />
    )}
    modalKey="remove workspace access group modal"
  >
    {children}
  </RemoveConfirm>
);

export default RemoveGroupFromWorkspace;
