// @flow

import React from 'react';
import { localize, Message } from '@oneflowab/pomes';
import { RadioGroup } from 'components/radio-group';
import { RadioGroupItem } from 'components/radio-group-item';
import * as partyConstants from 'agreement/party/constants';

type Props = {
  onChange?: (string) => void,
  defaultValue: 'company' | 'individual',
}

export const PartyTypeSelector = ({ onChange, defaultValue }: Props) => (
  <RadioGroup
    name="partyType"
    defaultValue={defaultValue}
    onChange={onChange}
  >
    <RadioGroupItem
      value={partyConstants.COMPANY}
      label={(
        <Message
          id="Company"
          comment="Label for the 'company' option in the counterparty type selector"
        />
      )}
    />
    <RadioGroupItem
      value={partyConstants.INDIVIDUAL}
      label={(
        <Message
          id="Individual"
          comment="Label for the 'individual' option in the counterparty type selector"
        />
      )}
    />
  </RadioGroup>
);

export default localize<Props>(PartyTypeSelector);
