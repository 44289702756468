import { Message } from '@oneflowab/pomes';

const MarkedAsSigned = ({ signedDate }: { signedDate: string }) => (
  <Message
    id="Document was marked as signed on {date} and has no legally binding e-signature."
    values={{
      date: signedDate,
    }}
    comment="Text for marked as signed contract. Visible in contract."
  />
);

export default MarkedAsSigned;
