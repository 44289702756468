import { isEmpty } from 'lodash';
import type { SubscribedFilters } from 'hocs/search/filter/types';
import AiSparkleIcon from 'components/icons/ai-sparkle';
import EmptyState from 'components/empty-state';
import Message from 'components/message';
import style from '../ai-insights.module.scss';

type Props = {
  success: boolean;
  documentsLoading: boolean;
  documentCount: number;
  insightsLength: number;
  activeFilters: SubscribedFilters;

}

const commonProps = {
  defaultStyle: true,
  className: style.EmptyStateContainer,
};

export const InsightsEmptyState = ({
  success, documentCount, documentsLoading, insightsLength, activeFilters,
}: Props) => {
  if (insightsLength === 0 && !isEmpty(activeFilters) && success) {
    return (
      <EmptyState
        icon={<AiSparkleIcon height="33px" />}
        header={(
          <Message
            id="No documents found"
            comment="Empty state header for Ai Insights tab when there are no insights yet"
          />
        )}
        content={(
          <div>
            <Message
              id="Please adjust your search criteria and try again."
              comment="Empty state content for Ai Insights tab when there are no insights yet"
            />
          </div>
        )}
        {...commonProps}
      />
    );
  }

  if (documentCount > 0 && insightsLength === 0 && success) {
    return (
      <EmptyState
        icon={<AiSparkleIcon height="33px" />}
        header={(
          <Message
            id="No documents have been reviewed yet"
            comment="Empty state header for Ai Insights tab when there are no insights yet"
          />
        )}
        content={(
          <div>
            <Message
              id="Once documents have been reviewed, you will see insights here."
              comment="Empty state content for Ai Insights tab when there are no insights yet"
            />
          </div>
        )}
        {...commonProps}
      />
    );
  }
  if (!documentsLoading && documentCount === 0 && isEmpty(activeFilters)) {
    return (
      <EmptyState
        icon={<AiSparkleIcon height="33px" />}
        header={(
          <Message
            id="No documents in this workspace"
            comment="Empty state header for Ai Insights tab when there are no insights yet"
          />
        )}
        content={(
          <div>
            <Message
              id="You can move documents from other workspaces or create a new one."
              comment="Empty state text. Used in document list when there are no documents in the workspace."
            />
          </div>
        )}
        {...commonProps}
      />
    );
  }

  return null;
};
