// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';
import {
  isUserActive,
  USER_ACTIVE,
  USER_INACTIVE,
} from 'user';

import Badge from 'components/badge';
import CircularSpinner from 'components/icons/circular-spinner';
import SingleUserActionsBlock from 'components/icons/single-user-actions-block';
import Button from 'components/button';
import Confirmable from 'components/confirmable';
import {
  ConfirmButton,
} from 'components/buttons';
import { RemoveConfirm } from '../remove-confirm';

import style from './update-user-status.module.scss';

type Props = {
  position: Position,
  updateState: UpdateState,
  accountId: number,
  updatePosition: (number, number) => void,
  children: Function,
  resetUpdateState: () => void,
};

export class UpdateUserStatus extends React.Component<Props> {
  getActivateActions = () => {
    const { updateState } = this.props;

    return (
      <ConfirmButton
        isLoading={updateState.loading}
        onClick={this.handleActivateConfirm}
      />
    );
  }

  getDeactivateActions = () => {
    const { updateState } = this.props;

    return (
      <Button
        icon={updateState.loading ? CircularSpinner : SingleUserActionsBlock}
        disabled={updateState.loading}
        onClick={this.handleDeactivateConfirm}
        color="red"
      >
        <Message
          id="Deactivate"
          comment="Text for button to confirm action."
        />
      </Button>
    );
  }

  handleActivateConfirm = () => {
    const { updatePosition, updateState, accountId } = this.props;

    if (updateState.loading) {
      return;
    }

    updatePosition(USER_ACTIVE, accountId);
  };

  handleDeactivateConfirm = () => {
    const { updatePosition, updateState, accountId } = this.props;

    if (updateState.loading) {
      return;
    }

    updatePosition(USER_INACTIVE, accountId);
  };

  renderActivateUser() {
    const {
      position,
      resetUpdateState,
      updateState,
      children,
    } = this.props;

    return (
      <Confirmable
        header={(
          <Message
            id="Confirm activating user"
            comment="Modal title when activating a user."
          />
        )}
        body={(
          <>
            <Message
              id="You are about to activate {userName}."
              values={{
                userName: <Badge label={position.fullname} kind="name" />,
              }}
              comment="Modal text when activating a user."
            />
            <Message
              id="This user will be able to login."
              comment="Modal text when activating a user."
            />
          </>
        )}
        actions={this.getActivateActions}
        success={updateState.success}
        error={updateState.error}
        onOpen={resetUpdateState}
        data-testid="update-status"
        onEnter={this.handleActivateConfirm}
        modalKey="re-activate user modal"
      >
        {children}
      </Confirmable>
    );
  }

  renderDeactivateUser() {
    const {
      position,
      resetUpdateState,
      updateState,
      children,
    } = this.props;

    return (
      <RemoveConfirm
        onConfirm={this.handleDeactivateConfirm}
        confirmState={updateState}
        resetConfirmState={resetUpdateState}
        confirmMessage={(
          <>
            <Message
              id="You are about to deactivate {userName}. This user will no longer be able to login."
              values={{
                userName: <Badge label={position.fullname} kind="name" />,
              }}
              comment="Modal text when deactivating a user."
            />
            <Message
              id="Deactivating a user will free up a seat. To reduce seats, please contact {email}."
              values={{
                email: <a className={style.SupportLink} href="mailto:support@oneflow.com">support@oneflow.com</a>,
              }}
              comment="Modal text when deactivating a user."
            />
          </>
        )}
        renderButton={this.getDeactivateActions}
        modalKey="deactivate user modal"
      >
        {children}
      </RemoveConfirm>
    );
  }

  render() {
    const { position } = this.props;

    if (isUserActive(position)) {
      return this.renderDeactivateUser();
    }

    return this.renderActivateUser();
  }
}
