import { noop } from 'lodash';
import { Message } from '@oneflowab/pomes';
import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import ModalForm from 'hocs/modal-form';
import { discardChanges } from 'reducers/current-contract';
import useAgreement from 'hooks/use-agreement';
import useCurrentContractId from 'hooks/use-current-contract-id';

import WarningIcon from 'components/icons/warning';
import Button from 'components/button';

import style from './discard-modal.module.scss';

type DiscardModalProps = {
  isOpen: boolean,
  onClose: () => void,
  children: () => ReactNode,
}

export const DiscardModal = ({
  isOpen,
  onClose,
  children,
}: DiscardModalProps) => {
  const dispatch = useDispatch();
  const agreementId = useCurrentContractId();
  const agreement = useAgreement(agreementId);

  const getActions = () => (
    <div className={style.Actions}>
      <Button kind="linkSeparate" onClick={onClose}>
        <Message id="Cancel" comment="Button label to close/cancel" />
      </Button>
      <Button
        kind="send"
        data-testid="discard-changes"
        customClass={style.DiscardChangesButton}
        onClick={() => {
          dispatch(discardChanges(agreement));
        }}
      >
        <Message id="Discard changes" comment="Button label to discard changes" />
      </Button>
    </div>
  );

  return (
    <ModalForm
      title={(
        <Message id="Discard changes?" comment="Modal title" />
      )}
      customBodyClass={style.DiscardChangesModal}
      body={(
        <div className={style.Content}>
          <WarningIcon width="74px" height="74px" />
          <p className={style.DiscardLabel}>
            <Message id="You have unsaved changes." comment="Label for modal for discarding changes" />
          </p>
          <div className={style.DiscardInformation}>
            <Message
              id="Are you sure you want to discard the changes? Any unsaved edits will be lost, and this action cannot be undone."
              comment="More information in modal for discarding changes"
            />
          </div>
        </div>
      )}
      actions={getActions}
      onSubmit={noop}
      onClose={onClose}
      modalKey="discard changes modal"
      isOpen={isOpen}
    >
      {() => children()}
    </ModalForm>
  );
};
