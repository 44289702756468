import { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { Popover } from '@radix-ui/react-popover';

import { useRootMenuStateContext } from './root';
import { MenuState, createMenuStateContext } from './menu-state';

const [
  SubMenuStateProvider,
  subMenuStateContext,
  useSubMenuStateContext,
] = createMenuStateContext();

const SubRoot = ({ children, id }: {
  children: ReactNode
  id: string,
}) => {
  const { state: parentState, setState: setRootState } = useRootMenuStateContext();
  const listRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setRootState((prevState) => ({
      ...prevState,
      subMenus: {
        ...prevState.subMenus,
        [id]: false,
      },
    }));
    // the effect should only run once when the component mounts so that the submenu registers
    // itself in the parent menu
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState<MenuState>({
    id,
    activeItemId: null,
    subMenus: {},
  });

  return (
    <Popover open={parentState.subMenus[id]}>
      <SubMenuStateProvider
        value={{
          state: { ...state, isOpen: parentState.subMenus[id] },
          setState,
          listRef,
        }}
      >
        {children}
      </SubMenuStateProvider>
    </Popover>
  );
};

export default SubRoot;

export { subMenuStateContext, useSubMenuStateContext };
