import clsx from 'clsx';
import * as DropdownMenu from 'components/dropdown-menu-v2';

import NewAddIcon from 'components/icons/new-add';
import style from '../box-menu.module.scss';

type Props = {
  menuClasses: string,
  onMouseOver: () => void,
  onMouseLeave: () => void,
  setDropdownStatus: (status: boolean) => void,
  isDropdownOpen: boolean,
}

const CollapsedTrigger = ({
  menuClasses,
  onMouseOver,
  onMouseLeave,
  setDropdownStatus,
  isDropdownOpen,
}: Props) => (
  <div className={menuClasses} data-testid="collapsed-trigger">
    <div className={clsx(style.Line, style.LineCollapsedLayout)} data-testid="line" />
    <div className={clsx(style.Outer, style.Collapsed)}>
      <DropdownMenu.Trigger
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        className={clsx(style.BoxMenuTrigger, style.Collapsed)}
        // Collapsed view: onPointerDown & onClick
        // Prevents the menu to open when pressing the button whilst scrolling
        onPointerDown={(e) => e.preventDefault()}
        onClick={() => setDropdownStatus(!isDropdownOpen)}
      >
        <div className={style.ButtonContainer}>
          <div
            data-testid="add-box-button"
            className={style.AddButton}
          >
            <NewAddIcon />
          </div>
        </div>
      </DropdownMenu.Trigger>
    </div>
  </div>
);

export default CollapsedTrigger;
