// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const clipPathId = uniqueId('clip0_127_3206');
  const filterId1 = uniqueId('filter0_d_127_3206');
  const filterId2 = uniqueId('filter1_d_127_3206');
  const filterId3 = uniqueId('filter2_d_127_3206');
  return (
    <>
      <g filter={`url(#${filterId1})`}>
        <path d="M53.3928 24.1169C51.7933 24.7931 49.9911 24.8073 48.3812 24.1564C46.7713 23.5055 45.4854 22.2426 44.8053 20.6449L41.257 12.274L14.3198 23.6925C12.2331 24.5771 10.5832 26.2543 9.73313 28.3553C8.88307 30.4564 8.90249 32.809 9.78704 34.8957L29.8924 82.3258C30.777 84.4126 32.4542 86.0624 34.5552 86.9125C36.6562 87.7626 39.0089 87.7432 41.0956 86.8586L73.433 73.151C75.5198 72.2664 77.1696 70.5892 78.0196 68.4882C78.8697 66.3871 78.8504 64.0345 77.9658 61.9478L60.6292 21.0494L53.3928 24.1169Z" fill="white" />
        <path d="M60.6292 21.0496L53.3927 24.1171C51.7933 24.7933 49.991 24.8074 48.3812 24.1566C46.7713 23.5057 45.4853 22.2428 44.8053 20.645L41.2569 12.2742L60.6292 21.0496Z" fill="#E8ECEE" />
        <path d="M55.0113 35.3236L24.8259 48.119C24.5391 48.2235 24.2232 48.2138 23.9433 48.0921C23.6634 47.9704 23.441 47.7458 23.3219 47.4648C23.2028 47.1838 23.1961 46.8678 23.3032 46.5821C23.4103 46.2963 23.6231 46.0626 23.8976 45.9291L54.083 33.1336C54.2283 33.063 54.3865 33.0226 54.5479 33.015C54.7092 33.0075 54.8705 33.0328 55.0218 33.0895C55.1731 33.1462 55.3112 33.2331 55.4279 33.3449C55.5445 33.4567 55.6372 33.5911 55.7002 33.7398C55.7633 33.8886 55.7954 34.0486 55.7947 34.2102C55.7939 34.3717 55.7603 34.5315 55.6958 34.6796C55.6314 34.8278 55.5375 34.9613 55.4198 35.072C55.3021 35.1827 55.1631 35.2683 55.0113 35.3236Z" fill="#D6DFE2" />
        <path d="M57.7838 41.8643L27.5984 54.6597C27.3116 54.7642 26.9957 54.7545 26.7158 54.6328C26.4359 54.5111 26.2135 54.2865 26.0944 54.0055C25.9753 53.7246 25.9686 53.4086 26.0757 53.1228C26.1828 52.837 26.3956 52.6033 26.6701 52.4698L56.8555 39.6743C57.0008 39.6037 57.159 39.5633 57.3204 39.5558C57.4817 39.5482 57.643 39.5735 57.7943 39.6302C57.9455 39.6869 58.0837 39.7738 58.2004 39.8856C58.317 39.9974 58.4097 40.1318 58.4727 40.2805C58.5358 40.4293 58.5679 40.5893 58.5672 40.7509C58.5664 40.9124 58.5327 41.0722 58.4683 41.2203C58.4039 41.3685 58.3099 41.502 58.1923 41.6127C58.0746 41.7234 57.9356 41.809 57.7838 41.8643Z" fill="#CCD5D8" />
        <path d="M60.5564 48.4047L30.3709 61.2002C30.0842 61.3047 29.7682 61.295 29.4884 61.1733C29.2085 61.0515 28.9861 60.827 28.867 60.546C28.7479 60.265 28.7412 59.949 28.8483 59.6632C28.9554 59.3775 29.1682 59.1437 29.4426 59.0103L59.6281 46.2148C59.7734 46.1442 59.9316 46.1038 60.0929 46.0962C60.2543 46.0886 60.4156 46.114 60.5668 46.1707C60.7181 46.2274 60.8563 46.3143 60.973 46.4261C61.0896 46.5379 61.1822 46.6723 61.2453 46.821C61.3083 46.9698 61.3405 47.1298 61.3397 47.2913C61.339 47.4529 61.3053 47.6126 61.2409 47.7608C61.1764 47.9089 61.0825 48.0424 60.9649 48.1531C60.8472 48.2638 60.7082 48.3494 60.5564 48.4047Z" fill="#D6DFE2" />
        <path d="M51.4218 59.9894L33.1421 67.7381C32.8553 67.8426 32.5394 67.8329 32.2595 67.7112C31.9796 67.5894 31.7572 67.3649 31.6381 67.0839C31.519 66.8029 31.5123 66.4869 31.6194 66.2011C31.7265 65.9154 31.9393 65.6816 32.2138 65.5482L50.4935 57.7995C50.6388 57.7288 50.7969 57.6885 50.9583 57.6809C51.1197 57.6733 51.2809 57.6987 51.4322 57.7554C51.5835 57.8121 51.7217 57.899 51.8383 58.0108C51.9549 58.1226 52.0476 58.2569 52.1106 58.4057C52.1737 58.5545 52.2058 58.7145 52.2051 58.876C52.2043 59.0376 52.1707 59.1973 52.1062 59.3455C52.0418 59.4936 51.9479 59.6271 51.8302 59.7378C51.7125 59.8485 51.5736 59.9341 51.4218 59.9894Z" fill="#CCD5D8" />
      </g>
      <g filter={`url(#${filterId2})`}>
        <path d="M70.059 30.0683C68.3332 30.2612 66.6015 29.7619 65.2434 28.6797C63.8854 27.5976 63.012 26.0209 62.8149 24.2957L61.7957 15.2611L32.7228 18.5407C30.4706 18.7948 28.4116 19.9331 26.9987 21.7053C25.5858 23.4775 24.9348 25.7383 25.1889 27.9905L30.9635 79.1813C31.2176 81.4335 32.3559 83.4925 34.128 84.9054C35.9002 86.3182 38.1611 86.9693 40.4133 86.7152L75.3147 82.7781C77.5669 82.524 79.6258 81.3857 81.0387 79.6135C82.4516 77.8414 83.1026 75.5805 82.8486 73.3283L77.8692 29.1872L70.059 30.0683Z" fill="white" />
        <path d="M77.8691 29.1873L70.0589 30.0684C68.3332 30.2614 66.6014 29.762 65.2434 28.6798C63.8854 27.5977 63.012 26.0211 62.8148 24.2958L61.7957 15.2612L77.8691 29.1873Z" fill="#E8ECEE" />
        <path d="M68.421 41.2724L35.8422 44.9475C35.5375 44.966 35.2374 44.8668 35.0038 44.6705C34.7701 44.4741 34.6208 44.1956 34.5866 43.8923C34.5524 43.589 34.6359 43.2842 34.8199 43.0407C35.004 42.7972 35.2744 42.6337 35.5755 42.5839L68.1544 38.9088C68.3138 38.8824 68.4769 38.8888 68.6337 38.9274C68.7906 38.9661 68.938 39.0363 69.0669 39.1337C69.1957 39.2311 69.3035 39.3537 69.3835 39.4941C69.4635 39.6345 69.514 39.7897 69.5321 39.9502C69.5503 40.1108 69.5355 40.2733 69.4888 40.428C69.4421 40.5826 69.3644 40.7262 69.2604 40.8499C69.1565 40.9736 69.0285 41.0748 68.8842 41.1474C68.7398 41.2201 68.5823 41.2626 68.421 41.2724Z" fill="#CCD5D8" />
        <path d="M69.2173 48.3312L36.6385 52.0063C36.3339 52.0248 36.0338 51.9257 35.8001 51.7293C35.5665 51.533 35.4171 51.2544 35.3829 50.9511C35.3487 50.6479 35.4322 50.343 35.6163 50.0995C35.8003 49.8561 36.0708 49.6926 36.3719 49.6427L68.9507 45.9676C69.1101 45.9413 69.2732 45.9476 69.4301 45.9863C69.5869 46.0249 69.7343 46.0951 69.8632 46.1925C69.9921 46.2899 70.0998 46.4126 70.1798 46.5529C70.2598 46.6933 70.3104 46.8485 70.3285 47.009C70.3466 47.1696 70.3319 47.3321 70.2851 47.4868C70.2384 47.6415 70.1607 47.785 70.0568 47.9087C69.9528 48.0324 69.8248 48.1336 69.6805 48.2063C69.5362 48.2789 69.3786 48.3214 69.2173 48.3312Z" fill="#D6DFE2" />
        <path d="M70.0136 55.3907L37.4348 59.0658C37.1302 59.0843 36.8301 58.9851 36.5964 58.7888C36.3628 58.5924 36.2134 58.3138 36.1792 58.0106C36.145 57.7073 36.2286 57.4025 36.4126 57.159C36.5966 56.9155 36.8671 56.752 37.1682 56.7022L69.747 53.0271C69.9064 53.0007 70.0695 53.0071 70.2264 53.0457C70.3833 53.0844 70.5306 53.1545 70.6595 53.252C70.7884 53.3494 70.8961 53.472 70.9761 53.6124C71.0561 53.7528 71.1067 53.9079 71.1248 54.0685C71.1429 54.229 71.1282 54.3916 71.0815 54.5463C71.0348 54.7009 70.957 54.8445 70.8531 54.9682C70.7492 55.0918 70.6211 55.1931 70.4768 55.2657C70.3325 55.3384 70.1749 55.3809 70.0136 55.3907Z" fill="#CCD5D8" />
        <path d="M57.9598 63.896L38.2307 66.1216C37.9261 66.1401 37.626 66.041 37.3924 65.8446C37.1587 65.6482 37.0094 65.3697 36.9752 65.0664C36.941 64.7631 37.0245 64.4583 37.2085 64.2148C37.3925 63.9714 37.663 63.8079 37.9641 63.758L57.6932 61.5325C57.8526 61.5061 58.0157 61.5124 58.1726 61.5511C58.3294 61.5898 58.4768 61.6599 58.6057 61.7573C58.7346 61.8548 58.8423 61.9774 58.9223 62.1178C59.0023 62.2581 59.0529 62.4133 59.071 62.5739C59.0891 62.7344 59.0744 62.897 59.0276 63.0516C58.9809 63.2063 58.9032 63.3498 58.7993 63.4735C58.6953 63.5972 58.5673 63.6985 58.423 63.7711C58.2787 63.8437 58.1211 63.8862 57.9598 63.896Z" fill="#D6DFE2" />
      </g>
      <g filter={`url(#${filterId3})`}>
        <path d="M84.1863 40.5818C82.4768 40.2771 80.9579 39.3068 79.9628 37.8838C78.9677 36.4607 78.5777 34.7011 78.8782 32.9908L80.4649 24.0384L51.6564 18.9327C49.4248 18.5372 47.1274 19.0444 45.2697 20.3428C43.412 21.6411 42.1461 23.6243 41.7506 25.856L32.7606 76.5809C32.3651 78.8126 32.8723 81.11 34.1707 82.9677C35.469 84.8255 37.4521 86.0913 39.6838 86.4868L74.2677 92.6161C76.4993 93.0116 78.7968 92.5044 80.6545 91.2061C82.5122 89.9077 83.778 87.9245 84.1735 85.6929L91.9254 41.9534L84.1863 40.5818Z" fill="white" />
        <path d="M91.9254 41.9534L84.1863 40.5818C82.4767 40.2772 80.9579 39.3069 79.9628 37.8838C78.9677 36.4607 78.5776 34.7011 78.8782 32.9908L80.4648 24.0385L91.9254 41.9534Z" fill="#E8ECEE" />
        <path d="M75.7176 71.8419L43.4353 66.1205C43.1379 66.0518 42.8783 65.8715 42.71 65.617C42.5416 65.3624 42.4774 65.0529 42.5307 64.7524C42.5839 64.4519 42.7506 64.1833 42.9962 64.002C43.2417 63.8208 43.5475 63.7408 43.8504 63.7784L76.1327 69.4998C76.2931 69.5198 76.4477 69.5722 76.5871 69.6537C76.7266 69.7353 76.8479 69.8444 76.9439 69.9744C77.0398 70.1044 77.1083 70.2526 77.1451 70.4099C77.182 70.5672 77.1865 70.7303 77.1583 70.8894C77.1301 71.0485 77.0698 71.2002 76.9811 71.3352C76.8924 71.4703 76.7773 71.5859 76.6425 71.675C76.5077 71.7641 76.3562 71.8248 76.1972 71.8535C76.0382 71.8822 75.8751 71.8783 75.7176 71.8419Z" fill="#D6DFE2" />
        <path d="M78.058 58.6369L45.7756 52.9155C45.4783 52.8468 45.2186 52.6665 45.0503 52.412C44.882 52.1574 44.8178 51.8479 44.871 51.5474C44.9243 51.2468 45.091 50.9783 45.3365 50.797C45.5821 50.6158 45.8879 50.5357 46.1907 50.5734L78.4731 56.2948C78.6334 56.3148 78.788 56.3671 78.9275 56.4487C79.0669 56.5303 79.1883 56.6394 79.2842 56.7694C79.3802 56.8994 79.4486 57.0476 79.4855 57.2049C79.5224 57.3622 79.5269 57.5253 79.4987 57.6844C79.4705 57.8435 79.4102 57.9952 79.3215 58.1302C79.2328 58.2653 79.1176 58.3809 78.9829 58.47C78.8481 58.5591 78.6966 58.6198 78.5376 58.6485C78.3786 58.6772 78.2154 58.6733 78.058 58.6369Z" fill="#D6DFE2" />
        <path d="M76.8875 65.2412L44.6051 59.5198C44.3078 59.4511 44.0481 59.2708 43.8798 59.0163C43.7115 58.7617 43.6473 58.4522 43.7005 58.1517C43.7538 57.8511 43.9205 57.5826 44.166 57.4013C44.4116 57.2201 44.7174 57.1401 45.0202 57.1777L77.3026 62.8991C77.4629 62.9191 77.6175 62.9714 77.757 63.053C77.8964 63.1346 78.0178 63.2437 78.1137 63.3737C78.2097 63.5037 78.2781 63.6519 78.315 63.8092C78.3519 63.9665 78.3564 64.1296 78.3282 64.2887C78.3 64.4478 78.2397 64.5995 78.151 64.7345C78.0623 64.8696 77.9471 64.9852 77.8123 65.0743C77.6776 65.1634 77.5261 65.2241 77.3671 65.2528C77.2081 65.2815 77.0449 65.2776 76.8875 65.2412Z" fill="#CCD5D8" />
        <path d="M66.4956 49.7759L46.9461 46.3111C46.6488 46.2424 46.3891 46.0622 46.2208 45.8076C46.0525 45.553 45.9883 45.2435 46.0415 44.943C46.0948 44.6425 46.2615 44.3739 46.507 44.1927C46.7526 44.0114 47.0584 43.9314 47.3612 43.9691L66.9107 47.4338C67.2081 47.5025 67.4678 47.6828 67.6361 47.9374C67.8044 48.192 67.8685 48.5015 67.8153 48.802C67.762 49.1025 67.5954 49.3711 67.3499 49.5523C67.1043 49.7335 66.7985 49.8136 66.4956 49.7759Z" fill="#CCD5D8" />
        <path d="M67.666 43.1722L48.1165 39.7074C47.8191 39.6387 47.5595 39.4585 47.3912 39.2039C47.2229 38.9493 47.1586 38.6398 47.2119 38.3393C47.2652 38.0388 47.4318 37.7702 47.6774 37.589C47.9229 37.4077 48.2287 37.3277 48.5316 37.3654L68.081 40.8301C68.3784 40.8988 68.6381 41.0791 68.8064 41.3337C68.9747 41.5883 69.0389 41.8978 68.9856 42.1983C68.9324 42.4988 68.7658 42.7674 68.5202 42.9486C68.2747 43.1298 67.9688 43.2099 67.666 43.1722Z" fill="#D6DFE2" />
        <path d="M80.2418 42.9851L74.7134 42.0053C73.7497 41.8345 72.8301 42.4772 72.6593 43.4409L71.6795 48.9693C71.5087 49.9329 72.1515 50.8526 73.1151 51.0233L78.6435 52.0031C79.6071 52.1739 80.5267 51.5312 80.6975 50.5675L81.6773 45.0391C81.8481 44.0755 81.2054 43.1559 80.2418 42.9851Z" fill="#99B0B7" />
        <path d="M43.1727 76.894C44.947 76.375 46.5683 75.4585 48.2876 74.7881C49.3362 74.3792 48.7061 74.8512 48.2308 75.4246C47.7811 75.9671 46.9971 76.7836 46.9449 77.5387C46.9265 77.8061 47.5062 77.5021 47.7265 77.3493C48.8307 76.5833 49.8583 75.7123 50.9484 74.927C51.0964 74.8204 51.6624 74.3125 51.919 74.4113C52.2796 74.5501 51.6984 76.2379 51.6435 76.4543C51.0818 78.665 53.7689 75.6975 54.443 75.9752C54.6984 76.0804 54.3074 77.7499 54.3846 78.0268C55.0148 80.2881 57.6543 81.2736 59.5 80.8062C61.5 80.2998 62.5 78.3062 62.5 78.3062" stroke="#187BB7" strokeWidth="1.59141" strokeLinecap="round" fill="none" />
      </g>
      <circle cx="83" cy="79.8061" r="6" fill="white" />
      <g clipPath={`url(#${clipPathId})`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M77.8582 89.5205C80.3497 90.7039 83.2092 90.8491 85.8077 89.9242C88.4063 88.9993 90.531 87.0799 91.7144 84.5884C92.8978 82.097 93.043 79.2374 92.1181 76.6389C91.1932 74.0403 89.2738 71.9156 86.7823 70.7322C84.2909 69.5488 81.4313 69.4036 78.8328 70.3285C76.2342 71.2535 74.1095 73.1728 72.9261 75.6643C71.7427 78.1558 71.5975 81.0153 72.5224 83.6138C73.4474 86.2124 75.3667 88.3371 77.8582 89.5205ZM87.3945 80.6757C87.7136 80.5558 87.9728 80.3154 88.1164 80.0062C88.2599 79.6971 88.2763 79.3439 88.162 79.0228C88.0477 78.7016 87.8119 78.4382 87.5052 78.2893C87.1986 78.1404 86.8458 78.1179 86.5227 78.2266L80.8191 80.2567L80.022 78.0172C79.9021 77.6981 79.6617 77.4389 79.3526 77.2953C79.0434 77.1517 78.6902 77.1353 78.3691 77.2496C78.048 77.3639 77.7846 77.5998 77.6357 77.9064C77.4867 78.213 77.4642 78.5659 77.5729 78.889L78.8059 82.353C78.9216 82.6777 79.1615 82.9433 79.4728 83.0912C79.7842 83.239 80.1416 83.2572 80.4663 83.1417L87.3945 80.6757Z" fill="#47E599" />
      </g>
      <defs>
        <filter id={filterId1} x="2.00592" y="3.79157" width="83.741" height="92.436" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.58783" />
          <feGaussianBlur stdDeviation="2.22296" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_127_3206" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_127_3206" result="shape" />
        </filter>
        <filter id={filterId2} x="19.785" y="10.7876" width="68.4674" height="82.9193" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.58783" />
          <feGaussianBlur stdDeviation="2.22296" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_127_3206" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_127_3206" result="shape" />
        </filter>
        <filter id={filterId3} x="26.8234" y="14.5833" width="72.2774" height="85.5579" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.58783" />
          <feGaussianBlur stdDeviation="2.22296" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_127_3206" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_127_3206" result="shape" />
        </filter>
        <clipPath id={clipPathId}>
          <rect width="26" height="26" fill="white" transform="translate(76.1552 62.8061) rotate(25.4071)" />
        </clipPath>
      </defs>
    </>
  );
};

const Documents = Svg(
  Fragment,
  { viewBox: '0 0 106 104' },
);

Documents.displayName = 'Documents';

export default Documents;
