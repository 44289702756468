import getFontAssetsDomain from './get-font-asset-domain.js';

const loadWhisper = () => {
  const normalWhisper = new FontFace(
    'Whisper',
    `url(${getFontAssetsDomain()}/resources/fonts/whisper/whisper_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );

  return [normalWhisper];
};

export default loadWhisper;
