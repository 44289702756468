// @flow

import React from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import type Moment from 'moment';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import {
  getStartOfDayTimestamp,
  getEndOfDayTimestamp,
  dateAtNoon,
  toMoment,
} from 'date';

import { DatePicker } from 'components/date-picker';
import Tooltip from 'components/tooltip';

import DatePresets from './date-presets';

import style from './dashboard-date-picker.module.scss';

type Props = {
  onChange: ({| fromDate?: number, toDate?: number |}) => void,
  from?: number,
  to?: number,
  dateFormat: string,
  message: MessageTranslator,
};

type State = {
  from?: moment$Moment,
  to?: moment$Moment,
  focusedInput?: 'startDate' | 'endDate',
  propagateChange: boolean,
  isTooltipDisabled: boolean,
};

type OnDatesChange = ({
  startDate?: moment$Moment,
  endDate?: moment$Moment,
}) => void;

export const isOutsideRange = (day: Moment) => (
  dateAtNoon(moment()).isBefore(day)
);

export class DashboardDatePicker extends React.PureComponent<Props, State> {
  static defaultProps = {
    from: undefined,
    to: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = this.getCleanState();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { onChange } = this.props;
    const { from, to } = this.state;

    if (this.valueHasChanged(prevProps)) {
      this.resetState();

      return;
    }

    if (!this.state.propagateChange) {
      return;
    }

    if (this.hasChanged(prevState) && this.isComplete()) {
      onChange({
        fromDate: from && getStartOfDayTimestamp(from),
        toDate: to && getEndOfDayTimestamp(to),
      });
    }
  }

  getCleanState(propagateChange?: boolean = true) {
    let from;
    let to;

    if (this.props.from) {
      from = toMoment(this.props.from);
    }

    if (this.props.to) {
      to = toMoment(this.props.to);
    }

    return {
      from,
      to,
      propagateChange,
      isTooltipDisabled: false,
    };
  }

  onDatesChange: OnDatesChange = ({ startDate, endDate }) => {
    if (!startDate && !endDate) {
      this.setState({
        from: undefined,
        to: undefined,
      });
    } else {
      this.setState({
        from: startDate,
        to: endDate,
      });
    }
  }

  onFocusChange = (focusedInput: 'startDate' | 'endDate') => {
    this.setState({ focusedInput, propagateChange: true });
  };

  onPresetSelect: OnDatesChange = ({ startDate, endDate }) => {
    this.onDatesChange({ startDate, endDate });

    this.applySelectedRange();
  }

  applySelectedRange = () => {
    this.setState((prevState) => ({
      focusedInput: undefined,
      to: prevState.to || moment(),
    }));
  };

  onClose = () => {
    this.applySelectedRange();
    this.setState({
      isTooltipDisabled: false,
    });
  }

  canApplyRange = () => {
    const {
      focusedInput,
      from,
      to,
    } = this.state;

    return Boolean((
      focusedInput
      && (from || to)
    ));
  }

  shouldDisableTooltip = () => Boolean(this.state.focusedInput);

  resetState() {
    this.setState(this.getCleanState(false));
  }

  hasChanged(prevState: State) {
    return prevState.focusedInput && !this.state.focusedInput;
  }

  isComplete() {
    const {
      focusedInput,
      from,
      to,
    } = this.state;

    return !focusedInput && (from || to);
  }

  valueHasChanged(prevProps: Props) {
    const { from, to } = this.props;

    return !isEqual(prevProps.from, from) || !isEqual(prevProps.to, to);
  }

  renderPresets = () => (
    <DatePresets onDatesChange={this.onPresetSelect} />
  );

  render() {
    const { dateFormat, message } = this.props;
    const { focusedInput } = this.state;

    let { from, to } = this.state;

    if (!moment(from).isValid()) {
      from = null;
    }

    if (!moment(to).isValid()) {
      to = null;
    }

    return (
      <Tooltip
        message={message({
          id: 'Documents sent within a specific date range',
          comment: 'Tooltip help text explaining what a certain statistics filter does',
        })}
        side="bottom"
        disabled={this.shouldDisableTooltip()}
        sideOffset={1}
        hideContent={this.state.isTooltipDisabled}
      >
        <div
          tabIndex={0}
          onClick={() => this.setState({ isTooltipDisabled: true })}
          className={clsx(style.DashboardDatePicker, 'DashboardDateRange')}
          aria-hidden
          role="button"
        >
          <DatePicker
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            focusedInput={focusedInput}
            startDate={from}
            endDate={to}
            openDirection="down"
            isOutsideRange={isOutsideRange}
            onClose={this.onClose}
            showClearDates={false}
            noBorder
            onApply={this.applySelectedRange}
            canApply={this.canApplyRange()}
            renderPresets={this.renderPresets}
            dateFormat={dateFormat}
            horizontalMargin={25}
            enableTooltip={() => this.setState({ isTooltipDisabled: false })}
          />
        </div>
      </Tooltip>
    );
  }
}

export default localize<Props>(DashboardDatePicker);
