import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import agreementsReducer from 'reducers/entities/agreements';
import { getCurrentWorkspaceIdSelector } from 'reducers/app';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';

import CreateContract from 'components/icons/create-contract';

import style from './blank-document.module.scss';

type Props = {
  fromTemplateResults?: boolean,
  folderId?: number,
};

export const BlankDocumentButton = ({ fromTemplateResults, folderId }: Props) => {
  // TODO: Replace useRouterHistory with useHistory after the migrating away from backbone
  const workspaceId: Oneflow.Workspace['id'] = useSelector(getCurrentWorkspaceIdSelector);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(agreementsReducer.createAgreement({
      data: {
        workspaceId,
        folderId,
        amplitudeScope: 'welcome modal - create blank',
      },
      pipe: {
        onSuccess: redirectToDocumentOnDocumentCreate,
      },
    }));
  }, [dispatch, folderId, workspaceId]);

  let actionText = (
    <span>
      <Message
        id="Blank document"
        comment="Text for button to finalize action."
      />
    </span>
  );

  if (!fromTemplateResults) {
    actionText = (
      <span className={style.CreateCustomDocumentText}>
        <Message
          id="Create custom document"
          comment="Text for button to finalize action."
        />
      </span>
    );
  }

  return (
    <button
      className={style.CustomDocument}
      onClick={onClick}
      data-testid="create-document-button"
    >
      <CreateContract height="25px" />
      {actionText}
    </button>
  );
};
