import { useState } from 'react';
import clsx from 'clsx';
import * as Popover from '@radix-ui/react-popover';
import Message from 'components/message';
import type { Formatting } from 'types/overlay';
import Tooltip from 'components/tooltip';
import BoldIcon from 'components/icons/bold';
import TextColorIcon from 'components/icons/text-color';
import ColorPicker from 'components/color-picker';
import { TextSizeSelector } from './text-size-selector';
import style from './overlay-field-formatting.module.scss';

type Props = {
  changeOverlayFieldFormatting : (formatting: Partial<Formatting>) => void;
  overlayFieldStyle: {
    fontSize: number,
    color: string,
    fontFamily: string[],
    fontWeight?: number | null;
    fontStyle?: string;
  };
  agreementColor: string;
}

export const OverlayFieldFormatting = ({
  changeOverlayFieldFormatting,
  overlayFieldStyle,
  agreementColor,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const isBolded = Boolean(overlayFieldStyle.fontWeight);

  const handleTextSizeChange = (fontSize: string) => {
    changeOverlayFieldFormatting({ size: Number(fontSize) });
  };

  const handleApplyColor = (hex: string) => {
    setIsPopoverOpen(false);
    changeOverlayFieldFormatting({ color: hex });
  };
  const handleResetColor = () => {
    setIsPopoverOpen(false);
    changeOverlayFieldFormatting({ color: agreementColor });
  };

  const handleBoldButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    changeOverlayFieldFormatting({ weight: isBolded ? null : 700 });
  };

  return (
    <>
      <TextSizeSelector
        fontSize={overlayFieldStyle.fontSize}
        onTextSizeChange={handleTextSizeChange}
      />
      <Tooltip
        messageClassName={style.ToggleTooltipMessage}
        side="top"
        message={(
          <Message
            id="Bold"
            comment="The label overlay field's toolbar that makes a text Bold."
          />
          )}
      >
        <div>
          <button
            onClick={handleBoldButtonClick}
            className={clsx(style.FormattingTrigger, { [style.Bolded]: isBolded })}
          >
            <BoldIcon height="24px" />
          </button>
        </div>
      </Tooltip>
      <Popover.Root open={isPopoverOpen}>
        <Popover.Trigger
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          className={style.FormattingTrigger}
        >
          <TextColorIcon height="24px" pathColor={overlayFieldStyle.color} />
        </Popover.Trigger>
        <Popover.Content
          className={style.ColorPickerContent}
          onInteractOutside={() => setIsPopoverOpen(false)}
        >
          <ColorPicker
            preSelectedColor={overlayFieldStyle.color}
            applyColor={handleApplyColor}
            removeColor={handleResetColor}
          />
        </Popover.Content>
      </Popover.Root>
    </>
  );
};
