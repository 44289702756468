import { useSelector } from 'react-redux';
import type { Editor } from 'slate';

import { getFocusedEditor } from 'reducers/editor';

const useFocusedEditor = (): Editor => {
  const focusedEditor = useSelector(getFocusedEditor);

  return focusedEditor as Editor;
};

export default useFocusedEditor;
