import { useMemo } from 'react';
import { useSlate } from 'slate-react';

import { getAgreementMyParticipant } from 'agreement/selectors';
// eslint-disable-next-line import/named
import { useRichTextProps } from 'contexts/rich-text';

import useAgreement from 'hooks/use-agreement';

import {
  isCollaborationEnabled,
  isCommentsEnabled,
  isSuggestionsEnabled,
} from 'comments';
import {
  hasAnnotationInSelection,
} from 'components/contract-text-editor/editor-plugins/annotation-plugin';

const useShouldRenderAnnotationToolbar = (contractId: Oneflow.Agreement['id']) => {
  const agreement = useAgreement(contractId);
  const myParticipant = getAgreementMyParticipant(agreement);
  const inlineCommentsEnabled = isCommentsEnabled(agreement);
  const suggestionsEnabled = isSuggestionsEnabled(agreement);
  const editor = useSlate();
  const { editorSelection } = useRichTextProps();

  const hasAnnotation = useMemo(() => (
    hasAnnotationInSelection(editor, editorSelection)
  ), [editor, editorSelection]);

  return (
    (inlineCommentsEnabled || suggestionsEnabled)
    && isCollaborationEnabled(agreement, myParticipant)
    && !hasAnnotation
  );
};
export default useShouldRenderAnnotationToolbar;
