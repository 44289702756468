import moment from 'moment-timezone';
import type { MomentInput } from 'moment';

export type Localization = {
  dateFormat: string;
  timezone: string;
};

export const getLocalizedDateTime = (
  localization: Localization,
  datetime: MomentInput,
): string => {
  const { dateFormat, timezone } = localization;

  const formattedDate = moment(datetime)
    .tz(timezone)
    .format(`${dateFormat} HH:mm`);

  return formattedDate;
};

export const getLocalizedDateTimeWithTimezone = (
  localization: Localization,
  datetime: MomentInput,
): string => {
  const { dateFormat, timezone } = localization;

  const formattedDate = moment(datetime)
    .tz(timezone)
    .format(`${dateFormat} HH:mm:ss z`);

  return formattedDate;
};
