// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const GroupCreateEvent = ({ auditLogEvent }: Props) => (
  <Message
    id="The role {roleName} was {jsx-start}created{jsx-end}"
    comment="Audit log event message for created role"
    component="span"
    className={style.Green}
    values={{
      roleName: auditLogEvent.information.name.to,
    }}
  />
);

export default GroupCreateEvent;
