// @flow

import * as React from 'react';
import compact from 'lodash/compact';
import minBy from 'lodash/minBy';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import moment from 'moment';
import type Moment from 'moment';
import { DateRangePicker } from 'react-dates';

import { dateAtNoon, isToday } from 'date';

import Button from 'components/button';
import NewArrowLeft from 'components/icons/new-arrow-left';
import NewArrowRight from 'components/icons/new-arrow-right';

import style from './date-range-picker.module.scss';

const defaultProps = {
  // input related props
  startDateId: 'from',
  endDateId: 'to',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,
  block: true,
  small: true,
  regular: false,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: 'horizontal',
  anchorDirection: 'left',
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  enableOutsideDays: false,
  isDayBlocked: () => false,

  // internationalization
  monthFormat: 'MMMM YYYY',
};

type OnDatesChange = ({
  startDate?: Moment,
  endDate?: Moment,
}) => void;

type Props = {
  onDatesChange: OnDatesChange,
  onFocusChange: 'startDate' | 'endDate' => void,
  focusedInput?: 'startDate' | 'endDate',
  startDate?: Moment,
  endDate?: Moment,
  openDirection: 'up' | 'down',
  onClose?: () => void,
  isDayHighlighted?: Moment => boolean,
  isOutsideRange?: Moment => boolean,
  showClearDates?: boolean,
  noBorder?: boolean,
  readOnly?: boolean,
  dateFormat: string,
  horizontalMargin: number,
  onApply?: () => void,
  canApply?: boolean,
  renderPresets?: () => React.Node,
  message: MessageTranslator,
  minimumNights: number,
};

export const getDateAtNoon = (date?: Moment): ?Moment => {
  if (!date) {
    return undefined;
  }

  return dateAtNoon(date.clone().local());
};

export const DateRangePickerComponent = ({
  onDatesChange,
  onFocusChange,
  focusedInput,
  startDate,
  endDate,
  openDirection,
  onClose,
  isDayHighlighted = isToday,
  isOutsideRange = () => false,
  showClearDates = false,
  noBorder = false,
  readOnly = false,
  dateFormat,
  horizontalMargin,
  message,
  onApply,
  canApply = false,
  renderPresets,
  minimumNights,
}: Props) => {
  const getNavigationButton = (next: boolean) => {
    if (next) {
      return (
        <Button
          kind="link"
          customClass={style.NavigationButtonNext}
          icon={<NewArrowRight height="11px" />}
        />
      );
    }

    return (
      <Button
        kind="link"
        customClass={style.NavigationButtonPrev}
        icon={<NewArrowLeft height="11px" />}
      />
    );
  };

  const initialVisibleMonth = () => {
    const now = moment();

    if (!startDate && !endDate) {
      return now;
    }

    return minBy(
      compact([startDate, endDate]),
      (date) => Math.abs(date.diff(now)),
    );
  };

  const renderApplyButton = () => {
    if (!onApply) {
      return null;
    }

    return (
      <Button
        kind="link"
        customClass={style.Apply}
        onClick={onApply}
        disabled={!canApply}
      >
        <Message
          id="Apply"
          comment="Button to apply currently selected date range inside the DateFilter component"
        />
      </Button>
    );
  };

  const renderCalendarInfo = () => {
    if (!onApply && !renderPresets) {
      return null;
    }

    let calendarInfoChildren = null;

    if (renderPresets) {
      calendarInfoChildren = renderPresets();
    }

    return (
      <div className={style.CalendarInfo}>
        {calendarInfoChildren}
        {renderApplyButton()}
      </div>
    );
  };

  return (
    <DateRangePicker
      {...defaultProps}
      startDatePlaceholderText={message({
        id: 'Start date',
        comment: 'Date range picker placeholder for start date',
      })}
      endDatePlaceholderText={message({
        id: 'End date',
        comment: 'Date range picker placeholder for end date',
      })}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      focusedInput={focusedInput}
      startDate={getDateAtNoon(startDate)}
      endDate={getDateAtNoon(endDate)}
      initialVisibleMonth={initialVisibleMonth}
      openDirection={openDirection}
      hideKeyboardShortcutsPanel
      renderCalendarInfo={renderCalendarInfo}
      onClose={onClose}
      isDayHighlighted={isDayHighlighted}
      isOutsideRange={isOutsideRange}
      showClearDates={showClearDates}
      noBorder={noBorder}
      readOnly={readOnly}
      displayFormat={dateFormat}
      daySize={31}
      navPrev={getNavigationButton()}
      navNext={getNavigationButton(true)}
      horizontalMargin={horizontalMargin}
      minimumNights={minimumNights}
    />
  );
};

export const DatePicker = localize(DateRangePickerComponent);
