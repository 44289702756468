// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const WorkspaceAccessUpdateEvent = ({ auditLogEvent }: Props) => {
  const getStringVariables = () => ({
    name: auditLogEvent.target.name,
    workspace: auditLogEvent.secondaryTarget.name,
    roleName: auditLogEvent.information.roleName
      || (
        <Message
          id="Deleted"
          comment="When we can't find the role name that we are trying to display in an audit log entry."
        />
      ),
  });

  const renderGrantedAccessText = () => {
    if (auditLogEvent.target.type === 'position') {
      return (
        <Message
          id="The user {name} was {jsx-start}granted access{jsx-end} to the workspace {workspace} with a {roleName} role"
          comment="Audit log event message for updated workspace access"
          component="span"
          className={style.Green}
          values={getStringVariables()}
        />
      );
    }

    return (
      <Message
        id="The group {name} was {jsx-start}granted access{jsx-end} to the workspace {workspace} with a {roleName} role"
        comment="Audit log event message for updated workspace access"
        component="span"
        className={style.Green}
        values={getStringVariables()}
      />
    );
  };

  const renderAccessRemovedText = () => {
    if (auditLogEvent.target.type === 'position') {
      return (
        <Message
          id="The user {name} with a {roleName} role had their {jsx-start}access removed{jsx-end} from the workspace {workspace}"
          comment="Audit log event message for updated workspace access"
          component="span"
          className={style.Red}
          values={getStringVariables()}
        />
      );
    }

    return (
      <Message
        id="The group {name} with a {roleName} role had its {jsx-start}access removed{jsx-end} from the workspace {workspace}"
        comment="Audit log event message for updated workspace access"
        component="span"
        className={style.Red}
        values={getStringVariables()}
      />
    );
  };

  const getMessageText = () => {
    if (auditLogEvent.information.access === 'remove') {
      return renderAccessRemovedText();
    }

    return renderGrantedAccessText();
  };

  return getMessageText();
};

export default WorkspaceAccessUpdateEvent;
