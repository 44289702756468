// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import Field from 'components/field';
import TextField from 'components/text-field';

export type FormData = {
  description: string,
};

export type Props = {|
  message: MessageTranslator,
  onSubmit: FormData => void,
  createState: CreateState,
  resetCreateState: () => void,
  children: ((onClick?: Function) => React.Node) | React.Node,
|};

type State = {
  isOpen: boolean,
}

export class AddApiTokenForm extends React.Component<Props, State> {
  state = {
    isOpen: false,
  };

  handleOpen = () => {
    this.setState({
      isOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  renderBody() {
    const { message } = this.props;

    return (
      <div>
        <p>
          <Message
            id="You may generate as many tokens as you need. Note that your tokens are secret and that you will only be able to view them once after they have been created."
            comment="Help text for adding API token on API token list page."
          />
        </p>
        <Field
          name="description"
          label={String(message({
            id: 'Token name',
            comment: 'Label for the name field on the add API token page.',
          }))}
          placeholder={message({
            id: 'Enter token name',
            comment: 'Name placeholder on the add API token page.',
          })}
          component={TextField}
          maxLength={100}
          required
          autoFocus
        />
      </div>
    );
  }

  render() {
    const { isOpen } = this.state;
    const {
      onSubmit,
      createState,
      resetCreateState,
      children,
    } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Generate a new token"
            comment="Modal title for generating a new API token"
          />
        )}
        body={this.renderBody()}
        onSubmit={onSubmit}
        formState={createState}
        resetFormState={resetCreateState}
        isOpen={createState.success ? false : isOpen}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        modalKey="generate api token modal"
      >
        {children}
      </ModalForm>
    );
  }
}

export default localize<Props>(AddApiTokenForm);
