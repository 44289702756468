import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Confirmable from 'components/confirmable';
import ToggleOff from 'components/icons/toggle-off';

import style from './disable-ai-review.module.scss';

type Args = {
  disableAiReview: () => void,
  isLoading: boolean,
};

export const handleConfirm = ({ disableAiReview, isLoading }: Args) => () => {
  if (isLoading) {
    return;
  }

  disableAiReview();
};

type Props = {
  disableAiReview: () => void,
  updateState: {
    error: string,
    loading: boolean,
  },
  message: MessageTranslator,
};

const DisableAiReviewComponent = ({
  updateState,
  disableAiReview,
  message,
}: Props) => (
  <Confirmable
    data-testid="ai-review-disable"
    header={message({
      id: 'Disable {featureName}',
      comment: 'Used as the title of the confirmation dialog',
      values: { featureName: 'AI Review' },
    })}
    modalKey="disable ai review modal"
    body={(
      <p>
        <Message
          id="You are about to disable {featureName} for this workspace. Are you sure you want to disable it?"
          comment="Used as warning text for deactivating a feature."
          values={{ featureName: 'AI Review' }}
        />
      </p>
    )}
    error={updateState.error}
    kind="secondary"
    icon={ToggleOff}
    customClass={style.Button}
    onEnter={handleConfirm({ disableAiReview, isLoading: updateState.loading })}
    isConfirmLoading={updateState.loading}
    onConfirm={disableAiReview}
  >
    <Message
      id="Disable"
      comment="CTA Button text"
    />
  </Confirmable>
);

export const DisableAiReview = localize<Props>(DisableAiReviewComponent);
