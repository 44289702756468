// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getCurrentWorkspaceIdSelector } from 'reducers/app';
import agreementTemplatesReducer from 'reducers/entities/agreement-templates';
import agreementsReducer from 'reducers/entities/agreements';
import { SYSTEM_TAG_ID } from 'tags/constants';

import { TemplateLauncher } from './template-launcher';

type StateProps = {|
  query: Query,
  createState: CreateState,
  queryName: string,
  hasTemplates: boolean,
  queryNameWithImportTag: Query,
|};

type DispatchProps = {|
  requestTemplates: (string, ?any) => void,
  resetCreateState: () => void,
  requestTemplatesWithImportTag: (string, ?any) => void,
|};

type MapStateToProps = (state: State) => StateProps;

const getWorkspaceQuerySelector = (state, { queryName }) => (
  `workspace-${getCurrentWorkspaceIdSelector(state)}::${queryName}`
);

export const mapStateToProps: MapStateToProps = (state) => {
  const queryName = getWorkspaceQuerySelector(state, { queryName: 'active-templates' });
  const query = agreementTemplatesReducer.getQuerySelector(state, { name: queryName });

  const queryNameWithImportTag = getWorkspaceQuerySelector(state, {
    queryName: 'active-templates-with-import-tag',
  });

  const createState = agreementsReducer.getCreateSelector(state);
  const hasTemplates = Boolean(query.result.length);

  return {
    query,
    queryName,
    queryNameWithImportTag,
    createState,
    hasTemplates,
  };
};

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  requestTemplates: (queryName, value) => {
    dispatch(agreementTemplatesReducer.queryAgreementTemplates({
      name: queryName,
      pagination: {
        limit: 10000,
        offset: 0,
      },
      params: {
        visible: 1,
        q: value,
      },
    }));
  },
  requestTemplatesWithImportTag: (queryName, value) => {
    dispatch(agreementTemplatesReducer.queryAgreementTemplates({
      name: queryName,
      pagination: {
        limit: 10000,
        offset: 0,
      },
      params: {
        visible: 1,
        q: value,
        tags: [SYSTEM_TAG_ID],
      },
    }));
  },
  resetCreateState: () => {
    dispatch(agreementsReducer.createAgreementReset());
  },
});

const connectedTemplateLauncher = connect<StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateLauncher);

export { connectedTemplateLauncher as TemplateLauncher };
