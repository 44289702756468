// @flow

import { type Dispatch } from 'redux';
import { connect } from 'react-redux';

import extensionsReducer from 'reducers/entities/extensions';

import ExtensionSetup, { type Props, type LocalizeProps } from './extension-setup';

type MSProps = {|
  setupState: RpcState,
|};

type MDProps = {|
  setup: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, {|...LocalizeProps, ...MSProps, ...MDProps |}>;

type MapStateToProps = (State, ownProps) => MSProps;
export const mapStateToProps: MapStateToProps = (state, { extension }) => ({
  setupState: extensionsReducer.getSetupWithGenericSelector(state, {
    id: extension.key,
    extensionClass: extension.extensionClass,
  }),
});

type MapDispatchToProps = (Dispatch<*>) => MDProps
export const mapDispatchToProps: MapDispatchToProps = (
  dispatch: Dispatch<*>, ownProps: OwnProps,
) => ({
  setup: () => {
    dispatch(extensionsReducer.setupWithGeneric({
      id: ownProps.extension.key,
      data: {
        extensionId: ownProps.extension.extensionId,
      },
    }));
  },
});

export default connect<Props, OwnProps, MSProps, MDProps, State, _>(
  mapStateToProps,
  mapDispatchToProps,
)(ExtensionSetup);
