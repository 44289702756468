import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import { get } from 'lodash';

import useAgreement from 'hooks/use-agreement';
import { getPositionFromSessionSelector } from 'reducers/session';
import agreements from 'reducers/entities/agreements';

import { shouldBeDisabled, areSettingsDisabled } from 'components/document-tabs/settings-tab/helpers';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';
import SelectField, { Props as SelectFieldProps } from 'components/document-tabs/settings-tab/components/select-field';
import Label from 'components/document-tabs/settings-tab/components/label';

import { SIGN_METHOD_EID_SIGN } from 'agreement/participant/constants';

import style from '../../signing-and-security.module.scss';

type Props = {
  agreementId: number;
};

const allOptions = [
  { value: 0, label: <Message id="Basic" comment="Signature mode" /> },
  { value: 1, label: <Message id="Qualified" comment="Signature Mode" /> },
];

type Option = typeof allOptions[number];

export const SignatureMode = ({ agreementId }: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const isQesEnabled = get(agreement, 'availableOptions.qes');
  const { resetRPCStates } = useAPIError(agreementId);

  const getDefaultSignatureMode = agreement.config?.signatureMode === 'qualified' ? 1 : 0;

  const onChange: SelectFieldProps<Option['value']>['onChange'] = (value, options) => {
    let config;
    if (value === 0) {
      config = {
        signatureMode: 'basic',
      };
    } else {
      config = {
        signatureMode: 'qualified',
        defaultSignMethod: SIGN_METHOD_EID_SIGN,
      };
    }

    resetRPCStates();
    dispatch(agreements.updateAgreementPreferences({
      id: agreementId,
      data: {
        config,
      },
      pipe: {
        onFailure: options?.onFailure,
      },
    }));
  };

  const label = (
    <Label
      hint={(
        <Message
          id="Can only be set in templates"
          comment="Tooltip for sign method dropdown"
        />
      )}
    >
      <Message
        id="Signature mode"
        comment="Label for signature mode dropdown"
      />
    </Label>
  );

  return (
    <SelectField
      name="signature-mode"
      disabled={!isQesEnabled}
      value={getDefaultSignatureMode}
      onChange={onChange}
      label={label}
      options={allOptions}
    />
  );
};
