import { useRef, useState } from 'react';
import type { ReactNode } from 'react';

import { createMenuStateContext } from './menu-state';
import type { MenuState } from './menu-state';

const [
  RootMenuStateProvider,
  rootMenuStateContext,
  useRootMenuStateContext,
] = createMenuStateContext();

const Root = ({ children, id }: { children: ReactNode, id: string }) => {
  const [state, setState] = useState<MenuState>({
    id,
    activeItemId: null,
    subMenus: {},
  });
  const listRef = useRef<HTMLDivElement>(null);
  return (
    <RootMenuStateProvider value={{ state, listRef, setState }}>
      {children}
    </RootMenuStateProvider>
  );
};

export default Root;

export { rootMenuStateContext, useRootMenuStateContext };
