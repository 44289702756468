import clsx from 'clsx';
import type { ComponentProps } from 'react';

import { Item as CommandItem } from 'components/filterable';

import style from './item.module.scss';

type ItemProps = ComponentProps<typeof CommandItem>;

const Item = ({ children, className, ...props }: ItemProps) => (
  <CommandItem
    {...props}
    onMouseEnter={(e) => {
      e.currentTarget.scrollIntoView({ block: 'nearest' });
    }}
    className={clsx(style.Item, className)}
  >
    <div className={style.Content}>{children}</div>
  </CommandItem>
);

export default Item;
