import React from 'react';
import { Message } from '@oneflowab/pomes';

import { MAX_MESSAGE_LENGTH } from 'comments/constants';

export const validationErrors = {
  private: (
    <Message
      id="Only colleagues can be mentioned in internal comments."
      comment="Validation error showing in a form field"
    />
  ),
  public: (
    <Message
      id="To post public messages, send the document to counterparties."
      comment="Validation error showing in a form field"
    />
  ),
};

export const getApiErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 403:
      return (
        <Message
          id="You don't have sufficient privileges for this action. If you think this is wrong, please contact your account administrator."
          comment="Error message when trying to post a comment"
        />
      );
    case 502:
    default:
      return (
        <Message
          id="Encountered an error, please try again. If the problem persists, please contact support. We apologize for the inconvenience"
          comment="Error message when trying to post a comment"
        />
      );
  }
};

export const getMaxLengthError = (currentLength) => (
  <Message
    id="Your comment is too long ({currentLength}/{maxLength})."
    comment="Validation error showing in a form field"
    values={{
      currentLength,
      maxLength: MAX_MESSAGE_LENGTH,
    }}
  />
);
