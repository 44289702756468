import * as zod from 'zod';
import log from 'logging';

const withSafeCatch = (schema, safetyCallback) => schema.catch((ctx) => {
  const result = safetyCallback(ctx);
  return Array.isArray(result) ? result : [result];
});

export const mfaChannelValue = withSafeCatch(
  zod.enum(['none', 'email', 'sms', 'personal_identification']),
  (ctx) => {
    if (ctx.input && typeof ctx.input === 'string') {
      const message = 'enum value from the API not present in the client: please add it to the schema';
      log.warning(message, { input: ctx.input });
      return ctx.input;
    }
    throw ctx.error;
  },
);

export const aclValue = zod.enum(['allow', 'deny']);

export const language = zod.enum(['en', 'sv', 'no', 'da', 'de', 'fi', 'es', 'fr', 'nl', 'pt', 'it']);
