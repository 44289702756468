import { Message } from '@oneflowab/pomes';
import type { MouseEventHandler } from 'react';

import { checkAcl } from 'components/acl';
import { MenuItem } from 'components/menu-item';
import MoveContractModal from 'components/modals/move-contract';
import MoveTo from 'components/icons/move-to';

export type Props = {
  agreement: Oneflow.Agreement,
  canPerformAction: boolean,
  disabled?: boolean,
  onSuccess?: () => void,
  queryName?: string,
};

const MoveContract = ({
  agreement,
  canPerformAction,
  disabled,
  onSuccess,
  queryName,
}: Props) => {
  const renderMenuText = () => <Message id="Move to..." comment="Menu option to open the move to modal." />;

  return (
    <MoveContractModal agreement={agreement} onSuccess={onSuccess} queryName={queryName}>
      {(onClick: MouseEventHandler) => (
        <MenuItem
          disabled={(!canPerformAction && !checkAcl(agreement.acl, 'agreement:move')) || Boolean(disabled)}
          label={renderMenuText()}
          onClick={onClick}
          icon={MoveTo}
        />
      )}
    </MoveContractModal>
  );
};

MoveContract.defaultProps = {
  onSuccess: undefined,
  queryName: undefined,
  disabled: undefined,
};

export default MoveContract;
