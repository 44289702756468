import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import NewCheckIcon from 'components/icons/new-check';
import { COLLAPSED } from 'components/document-layout-container/helpers';
import type { Layout } from 'components/document-layout-container/types';

import style from './you-have-signed.module.scss';

type Props = {
  layout: Layout;
}

const YouHaveSignedState = ({ layout }: Props) => (
  <div className={clsx(style.Container, { [style.Collapsed]: layout === COLLAPSED })}>
    <NewCheckIcon height="14px" />
    <Message
      id="You have signed"
      comment="Text for you have signed badge."
    />
  </div>
);

export default YouHaveSignedState;
