// @flow

import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import MyProfile from 'components/my-profile';

type Props = {
  isContractView: boolean,
  updatesCount: number,
  expanded: boolean,
  isMobile: boolean,
  mobileMenuOpen: boolean,
  toggleMobileMenu: () => void,
};

const MyProfileButton = ({
  isContractView,
  updatesCount,
  expanded,
  isMobile,
  mobileMenuOpen,
  toggleMobileMenu,
}: Props) => {
  if (isContractView) {
    return (
      <Router>
        <MyProfile
          expanded={expanded}
          isContractView={isContractView}
          isMobile={isMobile}
          mobileMenuOpen={mobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          updatesCount={updatesCount}
        />
      </Router>
    );
  }

  return (
    <MyProfile
      expanded={expanded}
      isMobile={isMobile}
      mobileMenuOpen={mobileMenuOpen}
      toggleMobileMenu={toggleMobileMenu}
      updatesCount={updatesCount}
    />
  );
};

export default MyProfileButton;
