// @flow

import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';

import { makeFilterValueGetter, type SubscribedFilters } from 'hocs/search';
import {
  isValidMonth,
  getCurrentMonth,
  getCurrentYear,
  getMonthsSinceCurrent,
} from 'date';

type IsInAllowedRange = (value: number) => boolean;
export const isInAllowedRange: IsInAllowedRange = (value) => {
  const allowedMonthsBeforeCurrent = -3;
  const allowedMonthsAfterCurrent = 12;

  return value >= allowedMonthsBeforeCurrent && value <= allowedMonthsAfterCurrent;
};

export const getSanitizers = () => ({
  calendar(value: any) {
    if (isEmpty(value)) {
      return {
        month: getCurrentMonth(),
        year: getCurrentYear(),
      };
    }

    const monthDiff = getMonthsSinceCurrent(value.month, value.year);

    if (!isValidMonth(value.month) || !isInAllowedRange(monthDiff)) {
      return {
        month: getCurrentMonth(),
        year: getCurrentYear(),
      };
    }

    return value;
  },
});

const filterIteratee = (availableFilters, sanitizer, filterName) => ({
  ...availableFilters,
  [filterName]: makeFilterValueGetter(filterName, sanitizer),
});

type GetLifecycleFilters = () => SubscribedFilters;

const getLifecycleFilters: GetLifecycleFilters = () => (
  reduce(getSanitizers(), filterIteratee, {})
);

export default getLifecycleFilters;
