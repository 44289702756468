import { MouseEventHandler } from 'react';
import type { DefaultRootState } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import { locationAssign } from 'reducers/router';
import { withWorkspaceNamespace } from 'hocs/with-current-workspace';
import agreementsReducer from 'reducers/entities/agreements';
import foldersReducer from 'reducers/entities/folders';
import useFeatureFlag from 'hooks/use-feature-flag';
import { isDraft, isImport, isTemplate } from 'agreement';

import { checkAcl } from 'components/acl';
import { DeleteMenuItem } from 'components/menu-items/delete';
import Badge from 'components/badge';
// eslint-disable-next-line import/named
import { RemoveConfirm } from 'components/modals/remove-confirm';
import toast from 'components/toasts';
import PermanentDeleteThinIcon from 'components/icons/permanent-delete-thin';
import DeleteThinIcon from 'components/icons/delete-thin';
import { CancelButton, DeleteButton } from 'components/buttons';
import { MoveToTrashButton } from 'components/modals/remove-confirm/button';
import { MoveToTrashMenuItem } from 'components/menu-items/move-to-trash';
import style from './delete-contract.module.scss';

export type Props = {
  agreement: Oneflow.Agreement,
  queryName?: string,
};

const FOLDERS_QUERY = 'folders';

export const DeleteContract = ({
  agreement,
  queryName,
}: Props) => {
  const dispatch = useDispatch();
  const documentTrashCanFeatureEnabled = useFeatureFlag('temporaryDocumentTrashCan');
  const isAgreementImported = isImport(agreement) && isDraft(agreement);

  const removeState = useSelector((state: DefaultRootState) => (
    agreementsReducer.getRemoveSelector(state, { id: agreement.id })
  ));

  const getWorkspaceDependingPath = (workspace: Oneflow.Workspace, path: string) => {
    if (!workspace) {
      return path;
    }

    return withWorkspaceNamespace(workspace)(path);
  };

  const triggerDeleteContractToast = () => {
    if (documentTrashCanFeatureEnabled && !isTemplate(agreement) && !isAgreementImported) {
      toast.success({
        id: `deleted-document-${agreement.id}`,
        title: (
          <Message
            id="Document moved to trash"
            comment="Title for the info message when the user has moved the document to trash."
          />
        ),
        duration: 5000,
      });
    }
  };

  const deleteContract = () => {
    dispatch(agreementsReducer.removeAgreement({
      id: agreement.id,
      pipe: {
        onSuccess: () => {
          dispatch(foldersReducer.queryFoldersReload({
            name: FOLDERS_QUERY,
          }));
          if (queryName) {
            dispatch(agreementsReducer.queryAgreementsReload({
              name: queryName,
            }));
          } else {
            dispatch(locationAssign({
              path: getWorkspaceDependingPath(agreement.collection, '/contracts'),
            }));
          }
          triggerDeleteContractToast();
        },
      },
    }));
  };

  const resetRemoveState = () => {
    dispatch(agreementsReducer.removeAgreementReset({
      id: agreement.id,
    }));
  };

  const getChildren = (onClick: MouseEventHandler) => {
    if (documentTrashCanFeatureEnabled && !isAgreementImported) {
      return (
        <MoveToTrashMenuItem
          onClick={onClick}
          disabled={!checkAcl(agreement.acl, 'agreement:remove')}
        />
      );
    }

    return (
      <DeleteMenuItem
        onClick={onClick}
        disabled={!checkAcl(agreement.acl, 'agreement:remove')}
      />
    );
  };

  const deleteInfoText = (
    <>
      <Message
        id="The document with ID {id} will be deleted."
        values={{
          id: <Badge label={agreement.id.toString()} kind="name" />,
        }}
        comment="Warning message in modal when attempting to remove a document"
      />
      <p className={style.deleteInfoText}>
        <Message
          id="This action cannot be undone."
          comment="Warning message in modal when attempting to remove a document"
        />
      </p>
      <Message
        id="Delete the document?"
        comment="Warning message in modal when attempting to remove a document"
      />
    </>
  );

  const getHeader = () => {
    if (documentTrashCanFeatureEnabled && isAgreementImported) {
      return (
        <Message
          id="Delete permanently?"
          comment="The title of the permanent delete modal"
        />
      );
    }

    if (documentTrashCanFeatureEnabled) {
      return (
        <Message
          id="Move to trash"
          comment="The title of the move to trash modal"
        />
      );
    }

    return (
      <Message id="Delete document" comment="Modal title when deleting a document." />
    );
  };

  let confirmMessage = documentTrashCanFeatureEnabled ? (
    <Message
      id="The document with ID {id} will be moved to trash."
      values={{
        id: agreement.id.toString(),
      }}
      comment="Message in modal when attempting to delete a document"
    />
  ) : deleteInfoText;

  if (isAgreementImported) {
    confirmMessage = (
      <Message
        id="This document with ID {id} will be permanently deleted and it won’t be possible to restore it."
        comment="The body of the permanent delete modal"
        values={{
          id: agreement?.id,
        }}
      />
    );
  }

  const getIcon = () => {
    if (documentTrashCanFeatureEnabled && agreement && !isTemplate(agreement)) {
      if (isAgreementImported) {
        return <PermanentDeleteThinIcon className={style.PermanentDelete} height="74px" />;
      }

      return <DeleteThinIcon className={style.Delete} height="69px" />;
    }

    return null;
  };

  const modalKey = 'delete contract modal';

  const getActions = ({ closeConfirmation }: { closeConfirmation: () => void }) => {
    if (documentTrashCanFeatureEnabled && agreement && !isTemplate(agreement)) {
      if (isAgreementImported) {
        return (
          <>
            <CancelButton
              onClick={closeConfirmation}
              modalKey={modalKey}
              data-testid="cancel-button"
            />
            <DeleteButton
              isLoading={removeState?.loading}
              disabled={removeState?.loading}
              onClick={deleteContract}
              data-testid="delete-permanently-button"
            />
          </>
        );
      }

      return (
        <>
          <CancelButton
            onClick={closeConfirmation}
            modalKey={modalKey}
            data-testid="cancel-button"
          />
          <MoveToTrashButton
            isLoading={removeState?.loading}
            disabled={removeState?.loading}
            onClick={deleteContract}
            data-testid="move-to-trash-button"
          />
        </>
      );
    }

    return null;
  };

  return (
    <RemoveConfirm
      onConfirm={deleteContract}
      confirmState={removeState}
      resetConfirmState={resetRemoveState}
      header={getHeader()}
      confirmMessage={confirmMessage}
      modalKey={modalKey}
      agreement={agreement}
      icon={getIcon()}
      renderButton={documentTrashCanFeatureEnabled ? getActions : undefined}
    >
      {getChildren}
    </RemoveConfirm>
  );
};
