/* eslint-disable jsx-a11y/no-static-element-interactions */
// @flow

import React, {
  useState,
  useCallback,
  useEffect,
  type Node,
} from 'react';
import clsx from 'clsx';
import Message from 'components/message';

import useCurrentContractId from 'hooks/use-current-contract-id';
import useResetFocusedEditor from 'hooks/rich-text-editor/use-reset-focused-editor';

import { useAnnotationProps } from 'contexts/annotation-props';
import { isSuggestion, isComment } from 'comments';
import { isSmallScreenWidth } from 'ui/config';

import SingleMessageBox from 'components/messages-layout/single-message-box';
import { Drawer } from 'components/drawer';
import { useCollapsedDocumentLayout } from 'components/document-layout-container/collapsed-document-layout/context';

import SingleComment from './single-comment';
import style from './annotation.module.scss';

type Props = {
  children: Node,
  isOpen?: boolean,
  className: string,
  annotation: Object,
  isReadOnly?: boolean,
  onClosePopover?: Function,
};

const AnnotationWrapper = ({
  children,
  isOpen = false,
  className,
  annotation,
  isReadOnly,
  onClosePopover,
}: Props) => {
  const resetFocusedEditor = useResetFocusedEditor();
  const isSmallScreen = isSmallScreenWidth();
  const contractId = useCurrentContractId();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const {
    setIsRecipientSelectorOpen,
  } = useCollapsedDocumentLayout();
  const {
    acceptedSuggestion,
    hoveredAnnotationId,
    newAnnotationIdRef,
    openAnnotationId,
    setHoveredAnnotationId,
    setOpenAnnotationId,
  } = useAnnotationProps();
  const { isAcceptedSuggestion, messageId } = acceptedSuggestion;
  const annotationId = annotation.id || annotation._id;
  const isCurrentAnnotationOpen = openAnnotationId === annotationId;
  const isCurrentAnnotationHovered = hoveredAnnotationId === annotationId;

  useEffect(() => {
    setTimeout(() => {
      const newIsPopoverOpen = isOpen
        && !isReadOnly
        && newAnnotationIdRef?.current !== annotationId;

      setIsPopoverOpen(newIsPopoverOpen);
      setOpenAnnotationId(newIsPopoverOpen ? annotationId : null);
    }, 0);
  }, [annotationId, isOpen, isReadOnly, newAnnotationIdRef, setOpenAnnotationId]);

  const onClick = useCallback(() => {
    if (isPopoverOpen) {
      return;
    }

    setIsPopoverOpen(true);
    setOpenAnnotationId(annotationId);
  }, [annotationId, isPopoverOpen, setOpenAnnotationId]);

  const onClose = useCallback(() => {
    setIsPopoverOpen(false);
    setIsRecipientSelectorOpen(false);
    setOpenAnnotationId(null);

    if (onClosePopover) {
      onClosePopover();
    }
  }, [
    onClosePopover,
    setIsRecipientSelectorOpen,
    setOpenAnnotationId,
  ]);

  const shouldHighlightAnnotation = isCurrentAnnotationHovered || isCurrentAnnotationOpen;
  const annotationClassName = clsx(className, 'annotation', {
    [style.AcceptSuggestionAnimation]: annotationId === messageId && isAcceptedSuggestion,
    [style.AnimateComment]: isComment(annotation)
      && newAnnotationIdRef?.current === annotationId,
    [style.AnimateSuggestion]: isSuggestion(annotation)
      && newAnnotationIdRef?.current === annotationId,
    [style.HighlightedComment]: shouldHighlightAnnotation && isComment(annotation),
    [style.HighlightedSuggestion]: shouldHighlightAnnotation && isSuggestion(annotation),
    [style.SuggestionAnnotation]: isSuggestion(annotation),
  });

  if (isSmallScreen) {
    return (
      <>
        <Drawer
          onOpenChange={(open) => open && resetFocusedEditor()}
          onClose={onClose}
          id={`annotation-${annotationId}`}
          isDragHandleVisible
          isOverlayEnabled
          renderTitle={() => (
            <>
              {isSuggestion(annotation) && (
              <Message
                id="Suggestion"
                comment="Text to show this message is a suggestion"
              />
              )}
              {isComment(annotation) && (
              <Message
                id="Comment"
                comment="Text to show this message is an inline comment"
              />
              )}
            </>
          )}
          renderTrigger={() => (
            <span
              id={`annotation-${annotationId}`}
              aria-hidden
              className={annotationClassName}
              role="button"
              tabIndex={isReadOnly ? 0 : 'none'}
            >
              {children}
            </span>
          )}
        >
          <SingleMessageBox
            agreementId={contractId}
            message={annotation}
            amplitudeData={{ location: 'contract view - popover' }}
            isMobileView={isSmallScreen}
          />
        </Drawer>
      </>
    );
  }

  return (
    <SingleComment
      annotation={annotation}
      isOpen={isPopoverOpen}
      onEscapeKeyDown={onClose}
      onInteractOutside={onClose}
    >
      <span
        aria-hidden
        className={annotationClassName}
        id={`annotation-${annotationId}`}
        onClick={onClick}
        onMouseEnter={() => setHoveredAnnotationId(annotationId)}
        onMouseLeave={() => setHoveredAnnotationId(null)}
        role="button"
        tabIndex={isReadOnly ? 0 : 'none'}
      >
        {children}
      </span>
    </SingleComment>
  );
};

export default AnnotationWrapper;
