import type { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import { get } from 'lodash';

import userAccountsReducer from 'reducers/entities/user-accounts';
import accountUsersReducer from 'reducers/entities/account-users';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getAccountUsersQueryName } from 'routes/admin/account-access/account-access';

import Badge from 'components/badge';

// eslint-disable-next-line import/named
import { RemoveConfirm } from '../remove-confirm';

type Props = {
  position: Oneflow.Position,
  binding: Oneflow.AccountUserRoleBinding,
  children: ReactNode,
  renderedFromAccountAccessPage: boolean,
}

const RemoveUserAccountAccess = ({
  position, binding, children, renderedFromAccountAccessPage,
}: Props) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountFromSessionSelector);

  const removeState = useSelector((state) => (
    userAccountsReducer.getRemoveUserAccountAccessSelector(state, { id: binding.id })
  ));

  const resetRemoveState = () => {
    dispatch(userAccountsReducer.removeUserAccountAccessReset({ id: binding.id }));
  };

  const removeUserAccountAccess = () => {
    const USER_ACCOUNTS_BINDINGS_QUERY_NAME = `admin/user/${binding.id}/account-access`;

    const actionData = {
      id: binding.id,
      data: {
        positionId: position.id,
        roleId: binding.role.id,
      },
      pipe: {
        action: () => userAccountsReducer.queryUserAccounts({
          name: USER_ACCOUNTS_BINDINGS_QUERY_NAME,
          params: {
            id: position.id,
            actorType: 'position',
          },
          sort: ['-role.type', 'role.name'],
        }),
      },
    };

    if (renderedFromAccountAccessPage) {
      actionData.pipe.action = () => accountUsersReducer.queryAccountUsers({
        name: getAccountUsersQueryName(account.id),
        params: {
          actorType: 'position',
        },
        sort: ['fullname'],
      });
    }
    dispatch(userAccountsReducer.removeUserAccountAccess(actionData));
  };

  const getCustomErrorMessage = () => {
    const apiErrorCode = get(removeState?.error, 'body.api_error_code');

    // Backend decided to return 102 (conflict) for the following case.
    // Since we don't return a conflict message for this case I haven't added it to constants.
    if (apiErrorCode !== 102) {
      return undefined;
    }

    return {
      bodyText: (
        <Message
          id="You cannot remove this role. At least one active admin must remain."
          comment="Error text when not allowing removal of the administrator role."
        />
      ),
    };
  };

  return (
    <RemoveConfirm
      onConfirm={removeUserAccountAccess}
      confirmState={removeState}
      resetConfirmState={resetRemoveState}
      confirmMessage={(
        <Message
          id="You are about to remove the {role} account role from {actorName}. This action will affect their account permissions. Remove the role?"
          values={{
            actorName: <Badge label={position.fullname} kind="name" />,
            role: <Badge label={binding.role.name || ''} kind="name" />,
          }}
          comment="Modal text when removing account role for a user."
        />
      )}
      modalKey="remove user account access user modal"
      header={(
        <Message
          id="Remove role"
          comment="Modal title for removing account role for a user"
        />
      )}
      customErrorMessage={getCustomErrorMessage()}
    >
      {children}
    </RemoveConfirm>
  );
};

export default RemoveUserAccountAccess;
