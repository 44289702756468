import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import type { ComponentType } from 'react';

import style from './content-tab.module.scss';
import { DraggableField } from './draggable-field/draggable-field';

type Props = {
  message: MessageTranslator,
};

const ContentFieldsComponent = ({
  message,
}: Props) => (
  <div
    className={style.Sections}
  >
    <DraggableField
      label={message({ id: 'Signature', comment: 'Label for draggable field' })}
      type="signature"
    />
  </div>
);

type ExposedProps = Record<string, never>;

// eslint-disable-next-line max-len
export const ContentFields = localize<Props>(ContentFieldsComponent) as unknown as ComponentType<ExposedProps>;
