import { Message } from '@oneflowab/pomes';

import ExternalLink from 'components/icons/external-link';
import { getHelpCenterBaseUrl } from 'utils/help-center';

import style from './help-center-link.module.scss';

type Props = {
  path?: string,
}

const getHelpCenterUrl = (path: string | undefined) => {
  const baseUrl = getHelpCenterBaseUrl();
  if (!path) {
    return baseUrl;
  }

  return `${baseUrl}${path}`;
};

export const HelpCenterLink = ({ path }: Props) => (
  <a
    className={style.LinkButton}
    href={getHelpCenterUrl(path)}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Message
      id="Learn more"
      comment="Used as the text in support link description"
    />
    <ExternalLink className={style.ExternalLink} height="12px" />
  </a>
);
