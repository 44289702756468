import * as React from 'react';
import get from 'lodash/get';
import { localize, Message, MessageTranslator } from '@oneflowab/pomes';
import { Form, FormRenderProps } from 'react-final-form';

// eslint-disable-next-line import/named, import/no-named-as-default
import { ApiError, getErrorMessage } from 'components/api-error';
import Button from 'components/button';
import TextField from 'components/text-field';
import Field from 'components/field';
import { updateParticipantEmail } from 'oneflow-client/agreement-participants';

import style from './send-email-form.module.scss';

export type Props = {
  message: MessageTranslator,
  onSuccess: () => void,
  agreement: Agreement,
  myParticipant: AgreementParticipant,
  guestToken?: string
};

type State = {
  error: null | Error
};

const updateParticipantError = {
  headerText: (
    <Message
      id="Please try again."
      comment="Header for general error message header when updating the participant."
    />
  ),
  bodyText: (
    <Message
      id="Something went wrong."
      comment="General error message body when updating the participant."
    />
  ),
} as const;

export class SendEmailFormComponent extends React.PureComponent<Props, State> {
  static defaultProps = {
    guestToken: undefined,
  };

  state = {
    error: null,
  };

  handleOnSubmit = ({ email }) => {
    this.updateParticipant(email);
  };

  getApiError = () => {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    const errorCode = get(error, 'body.api_error_code');

    return getErrorMessage(errorCode) || updateParticipantError;
  };

  async updateParticipant(email: string) {
    const {
      agreement,
      guestToken,
      myParticipant,
      onSuccess,
    } = this.props;

    try {
      await updateParticipantEmail({
        agreement,
        participantId: myParticipant.id,
        guestToken,
        email,
      });

      this.setState({ error: null });
      onSuccess();
    } catch (error: any) {
      this.setState({ error });
    }
  }

  renderError() {
    const apiError = this.getApiError();

    if (!apiError) {
      return null;
    }

    return <ApiError customMessage={apiError} />;
  }

  renderForm = (formProps: FormRenderProps<any>) => {
    const { message } = this.props;

    return (
      <form onSubmit={formProps.handleSubmit} className={style.SendEmailForm}>
        <Field
          name="email"
          component={TextField}
          label={message({
            id: 'Email address',
            comment: 'Label for an email input field.',
          })}
          placeholder={message({
            id: 'Enter your email',
            comment: 'Placeholder for an email input field.',
          })}
          maxLength={70}
          hideLabel
          email
          displayErrorEarly={false}
          hideErrorsUntilTouched
        />
        <Button
          disabled={formProps.pristine || !formProps.valid}
          kind="special"
          onClick={formProps.handleSubmit}
        >
          <Message
            id="Send"
            comment="A message to send a copy of the contract to users email"
          />
        </Button>
      </form>
    );
  };

  render() {
    return (
      <>
        <Form
          onSubmit={this.handleOnSubmit}
          initialValues={{ email: '' }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          validate={() => {}}
          render={this.renderForm}
        />
        {this.renderError()}
      </>
    );
  }
}

export default localize<Props>(SendEmailFormComponent);
