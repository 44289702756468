// @flow

import * as React from 'react';

import {
  willRenew,
  isOverdueEvent,
  becomesOverdue,
  willTerminate,
  willStart,
  isStarted,
  isRenewed,
  isTerminated,
  willRenewReminder,
  isRenewedReminder,
  willOverdueReminder,
  isOverdueReminder,
  isEnded,
  willEnd,
  isEndedReminder,
  willEndReminder,
  isInternalReminder,
} from 'agreement/delayed-events';

import {
  WillRenew,
  Overdue,
  BecomesOverdue,
  WillTerminate,
  WillStart,
  Started,
  Renewed,
  Terminated,
  OverdueReminder,
  RenewalReminder,
  Ended,
  WillEnd,
  EndingReminder,
  InternalReminder,
} from './event-insights';
import style from './lifecycle-event-insight.module.scss';

type Props = {
  event: LifecycleCalendarEvent,
};

const EventInsights = [
  {
    component: Overdue,
    condition: isOverdueEvent,
  },
  {
    component: BecomesOverdue,
    condition: becomesOverdue,
  },
  {
    component: OverdueReminder,
    condition: isOverdueReminder,
  },
  {
    component: OverdueReminder,
    condition: willOverdueReminder,
  },
  {
    component: Ended,
    condition: isEnded,
  },
  {
    component: WillEnd,
    condition: willEnd,
  },
  {
    component: EndingReminder,
    condition: isEndedReminder,
  },
  {
    component: EndingReminder,
    condition: willEndReminder,
  },
  {
    component: Renewed,
    condition: isRenewed,
  },
  {
    component: WillRenew,
    condition: willRenew,
  },
  {
    component: RenewalReminder,
    condition: isRenewedReminder,
  },
  {
    component: RenewalReminder,
    condition: willRenewReminder,
  },
  {
    component: Started,
    condition: isStarted,
  },
  {
    component: WillStart,
    condition: willStart,
  },
  {
    component: Terminated,
    condition: isTerminated,
  },
  {
    component: WillTerminate,
    condition: willTerminate,
  },
  {
    component: InternalReminder,
    condition: isInternalReminder,
  },
];

const LifecycleEventInsight = ({ event }: Props) => {
  const { sourceEvent } = event;
  const eventInsight = EventInsights.find((insight) => insight.condition(sourceEvent));

  if (!eventInsight) {
    return null;
  }

  const { component: EventInsight } = eventInsight;

  return (
    <div className={style.EventInsight}>
      <EventInsight event={event} />
    </div>
  );
};

export default LifecycleEventInsight;
