// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import Confirmable from 'components/confirmable';

export type Props = {
  position: Position,
  resendInvitationState: RpcState,
  resetResendInvitationState: () => void,
  resendInvitation: () => void,
  children: Function,
};

export const handleConfirm = ({ resendInvitation, isLoading }: Props) => () => {
  if (isLoading) {
    return;
  }

  resendInvitation();
};

export const ResendUserInvitation = ({
  position,
  resendInvitationState,
  resendInvitation,
  resetResendInvitationState,
  children,
}: Props) => (
  <Confirmable
    header={(
      <Message
        id="Confirm resending invitation email"
        comment="Modal title when resending invitation emails."
      />
    )}
    body={(
      <p>
        <Message
          id="You are about to resend the invitation email to {userName}."
          values={{
            userName: <Badge label={position.fullname} kind="name" />,
          }}
          comment="Modal text when resending invitation emails."
        />
      </p>
    )}
    success={resendInvitationState.success}
    error={resendInvitationState.error}
    onOpen={resetResendInvitationState}
    onEnter={handleConfirm({ resendInvitation, isLoading: resendInvitationState.loading })}
    isConfirmLoading={resendInvitationState.loading}
    onConfirm={resendInvitation}
    modalKey="resend invite modal"
  >
    {children}
  </Confirmable>
);
