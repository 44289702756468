import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import type { ReactNode } from 'react';

import {
  getDataFieldExternalKeyValueMap,
  getDataFieldExternalKeyMap,
  getCollapsedBoxIds,
  updateCollapsedBoxIds,
} from 'reducers/current-contract';
import { getGuestToken } from 'agreement/selectors';

import {
  getHasBrokenSectionRule,
  getExternalKeyFromRule,
  getVisibility,
} from 'components/contract-boxes/box-wrapper/helpers';

import Tooltip from 'components/tooltip';
import Message from 'components/message';
import AdjusterIcon from 'components/icons/adjuster';
import ChevronDownIcon from 'components/icons/chevron-down';
import ChevronUpIcon from 'components/icons/chevron-up';
import InfoIcon from 'components/icons/info';
import LinkIcon from 'components/icons/link';

import style from './section-rule-wrapper.module.scss';

type Props = {
  boxId: number;
  rule?: Record<string, ({ var: string } | string)[]>;
  children: ReactNode;
};

const SectionRuleWrapper = ({ boxId, rule, children }: Props) => {
  const dispatch = useDispatch();
  const collapsedBoxIds = useSelector(getCollapsedBoxIds);
  const isCurrentBoxCollapsed = collapsedBoxIds.includes(boxId);
  const guestToken = useSelector(getGuestToken);

  const dataFieldExternalKeyMap = useSelector(getDataFieldExternalKeyMap);
  const dataFieldExternalKeyValueMap = useSelector(getDataFieldExternalKeyValueMap);
  const dataFieldName = rule && getExternalKeyFromRule(rule);

  const isSectionVisible = useMemo(() => (
    getVisibility(rule, dataFieldExternalKeyValueMap)
  ), [rule, dataFieldExternalKeyValueMap]);

  const isSectionBroken = getHasBrokenSectionRule(rule, dataFieldExternalKeyMap);
  const isSectionHidden = !isSectionVisible && !isSectionBroken;

  const handleClick = useCallback(() => {
    if (isCurrentBoxCollapsed) {
      const updatedCollapsedBoxIds = collapsedBoxIds.filter((id) => id !== boxId);
      dispatch(updateCollapsedBoxIds(updatedCollapsedBoxIds));
    } else {
      dispatch(updateCollapsedBoxIds(
        [...collapsedBoxIds, boxId],
      ));
    }
  }, [
    isCurrentBoxCollapsed,
    collapsedBoxIds,
    dispatch,
    boxId,
  ]);

  const conditionalClasses = {
    [style.Visible]: isSectionVisible,
    [style.Broken]: isSectionBroken,
    [style.Hidden]: isSectionHidden,
    [style.Collapsed]: isSectionHidden && isCurrentBoxCollapsed,
  };

  if (!rule || guestToken) {
    // The fragment is to make sure the return type is ReactElement. The reason is that the
    // ErrorBoundary component expects a ReactElement as its child and throws an error if it's not.
    // This component is a child of ErrorBoundary in the BoxWrapper component.
    return <>{children}</>;
  }

  const renderChildren = () => {
    if (isSectionHidden && isCurrentBoxCollapsed) {
      return null;
    }

    return children;
  };

  return (
    <div className={clsx(style.SectionRuleWrapper, conditionalClasses)}>
      <div
        role="button"
        className={clsx(style.SectionRuleHeader, conditionalClasses)}
        onClick={handleClick}
        onKeyDown={handleClick}
        tabIndex={0}
      >
        <div className={style.StatusWrapper}>
          <AdjusterIcon height="12px" />
          <div className={style.Pill}>
            {isSectionVisible && (
              <Message
                id="Visible"
                comment="Message for the section rule header when the section is visible"
              />
            )}
            {isSectionBroken && (
              <>
                <Message
                  id="Broken section rule"
                  comment="Message for the section rule header when the section rule is not available in this contract"
                />
                <Tooltip
                  messageClassName={style.TooltipText}
                  message={(
                    <Message
                      id="The rule set for this section is broken: the data field may have been removed, or the template group was modified. Review the section rules in the section settings."
                      comment="Tooltip message explaining what it means to have a broken section rule in a contract section"
                    />
                  )}
                  side="top"
                  sideOffset={8}
                  zIndex="9999"
                >
                  <InfoIcon height="12px" />
                </Tooltip>
              </>
            )}
            {isSectionHidden && (
              <Message
                id="Hidden"
                comment="Message for the section rule header when the section is hidden"
              />
            )}
          </div>
        </div>
        <div className={style.DataFieldInfoWrapper}>
          <div className={style.DataFieldInfo}>
            <LinkIcon height="12px" />
            <span className={style.Bold}>
              <Message
                id="data field"
                comment="Text in the section rule header where name of section rule is displayed"
              />
              :
            </span>
            <span>{dataFieldName}</span>
          </div>
          <div className={style.ArrowIcon}>
            {isSectionHidden && (
              isCurrentBoxCollapsed ? <ChevronDownIcon height="12px" /> : <ChevronUpIcon height="12px" />
            )}
          </div>
        </div>
      </div>
      {renderChildren()}
    </div>
  );
};

export default SectionRuleWrapper;
