import * as zod from 'zod';

import {
  isAnyDeclinedState,
} from 'agreement';

import {
  getAgreementMyParticipant,
} from 'agreement/selectors';

// Sepcial validations
const declinedContractMyParticipantHasAccount = (contract) => {
  if (isAnyDeclinedState(contract)) {
    const myParticipant = getAgreementMyParticipant(contract);

    if (!myParticipant || !myParticipant?.account) {
      return;
    }

    const AccountSchema = zod.object({
      id: zod.number(),
    });

    const myParticipantSchema = zod.object({
      account: AccountSchema,
    });

    // eslint-disable-next-line consistent-return
    return {
      schema: myParticipantSchema,
      data: myParticipant,
      validationMessage: 'My participant missing connected account',
    };
  }

  // eslint-disable-next-line consistent-return
  return undefined;
};

const conditionalValidations = [
  declinedContractMyParticipantHasAccount,
];

export default conditionalValidations;
