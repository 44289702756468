// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Reminder from 'components/icons/reminder';

const EndingReminder = () => (
  <>
    <Reminder height="12px" />
    <span>
      <Message
        id="Ending reminder"
        comment="An insight for lifecycle events that reminds users that a contract is ended or will end."
      />
    </span>
  </>
);

export default EndingReminder;
