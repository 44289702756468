import getFontAssetsDomain from './get-font-asset-domain.js';

const loadGaramond = () => {
  const normalGaramond = new FontFace(
    'Garamond',
    `url(${getFontAssetsDomain()}/resources/fonts/garamond/cormorant-garamond_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicGaramond = new FontFace(
    'Garamond',
    `url(${getFontAssetsDomain()}/resources/fonts/garamond/cormorant-garamond_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldGaramond = new FontFace(
    'Garamond',
    `url(${getFontAssetsDomain()}/resources/fonts/garamond/cormorant-garamond_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicGaramond = new FontFace(
    'Garamond',
    `url(${getFontAssetsDomain()}/resources/fonts/garamond/cormorant-garamond_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalGaramond, italicGaramond, boldGaramond, boldItalicGaramond];
};

export default loadGaramond;
