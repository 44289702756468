// @flow
import * as React from 'react';
import Expandable from 'components/expandable';
import style from '../audit-trail-tab.module.scss';
import Event from '../event';

type ChildEventProps = {
  event: any,
  agreementId: number,
  message: MessageTranslator,
  isChildEvent: boolean,
}
const ChildEvents = ({
  event, agreementId, isChildEvent, message,
}: ChildEventProps) => {
  if (!event?.childEvents) {
    return null;
  }
  const allChildren = event.childEvents.map((childEvent) => (
    <Event
      event={childEvent}
      agreementId={agreementId}
      key={childEvent.id}
      message={message}
      isChildEvent
    />
  ));

  if (isChildEvent) {
    return allChildren;
  }

  return (
    <Expandable
      content={allChildren}
      initiallyHidden
      showText={message({
        id: 'Show details',
        comment: 'Button text for showing details in audit trail',
      })}
      hideText={message({
        id: 'Hide details',
        comment: 'Button text for hiding details in audit trail',
      })}
      buttonClasses={style.ShowHideButton}
      data-testid="expandable-container"
    />
  );
};

export default ChildEvents;
