// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import { useSlate } from 'slate-react';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';

import useDOMRangeRectangles from './use-dom-range-rectangles';
import style from './dom-range-highlighter.module.scss';

export type Rectangle = {
  top: number,
  bottom: number,
  left: number,
  right: number,
  width: number,
  height: number,
  x: number,
  y: number,
};

type DOMRangeHighlightProps = {
  rectangle: Rectangle,
  onMouseDown: Function,
  documentScrollContainerRef: React.RefObject<HTMLElement>,
};

const DOMRangeHighlight = ({
  rectangle,
  onMouseDown,
  documentScrollContainerRef,
}: DOMRangeHighlightProps) => {
  const scrollValueTop = React.useMemo(() => (Number(documentScrollContainerRef.current.scrollTop)),
    [documentScrollContainerRef]);

  const highlightStyle = {
    top: `${rectangle.top + scrollValueTop}px`,
    left: `${rectangle.left}px`,
    width: `${Math.max(rectangle.width, 2)}px`,
    height: `${rectangle.height}px`,
  };

  return (
    <div
      onMouseDown={onMouseDown}
      className={style.DOMRangeHighlight}
      style={highlightStyle}
      aria-hidden
    />
  );
};

const DOMRangeHighlighter = () => {
  const editor = useSlate();
  const rectangles = useDOMRangeRectangles(editor);
  const focusEditor = useFocusEditor();
  const { documentScrollContainerRef } = useDocumentLayout();

  const onMouseDown = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    focusEditor();
  }, [focusEditor]);

  if (!documentScrollContainerRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    rectangles.map((rectangle, index) => (
      <DOMRangeHighlight
        rectangle={rectangle}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        onMouseDown={onMouseDown}
        documentScrollContainerRef={documentScrollContainerRef}
      />
    )),
    documentScrollContainerRef.current,
  );
};

export default DOMRangeHighlighter;
