// @flow

import {
  isDeclined,
  isSigned,
  isAnyDeclinedState,
  isAnySignedState,
  isTemplate,
  isPending,
} from 'agreement/states';
import {
  isAgreementOwner,
  getAgreementMyParty,
  getAgreementMyParticipant,
} from 'agreement/selectors';
import { isViewer } from 'agreement/participant';
import hasDeliveryChannelSameDevice from 'agreement/participant/has-delivery-channel-same-device';
import { isDraft, hasSignOrder } from 'agreement';

import { checkAcl } from 'components/acl';
import * as partyConstants from './constants';

type PartyHelper = (participant: AgreementParty) => boolean;

export const isIndividual: PartyHelper = (party) => (
  party.individual === partyConstants.IS_INDIVIDUAL
);

export const getPartyByParticipantId = (
  agreement: Agreement,
  participantId: number,
): AgreementParty | null => agreement.parties.find(
  (party) => party.participants.findIndex((participant) => participant.id === participantId) > -1,
);

/* state */
type IsEditable = (Account, Agreement, AgreementParty) => boolean;
export const isEditable: IsEditable = (account, agreement, party) => {
  const myParty = getAgreementMyParty(agreement);
  const myParticipant = getAgreementMyParticipant(agreement);

  if (isTemplate(agreement)) {
    return true;
  }

  if (
    !myParty
    || !myParticipant
    || isViewer(myParticipant)
    || isAnySignedState(agreement)
    || isAnyDeclinedState(agreement)
  ) {
    return false;
  }

  const currentUserIsOwner = isAgreementOwner(account, agreement);
  const isPartyOwner = currentUserIsOwner || party.id === myParty.id;

  return isPartyOwner;
};

type AllowAddParticipant = (Account, Agreement, AgreementParty) => boolean;
export const allowAddParticipant: AllowAddParticipant = (
  account,
  agreement,
  party,
) => {
  const currentUserIsOwner = isAgreementOwner(account, agreement);
  const myParty = getAgreementMyParty(agreement);
  const myParticipant = getAgreementMyParticipant(agreement);

  if (isIndividual(party)) {
    return false;
  }

  if (isTemplate(agreement)) {
    return true;
  }

  if (!myParticipant || isViewer(myParticipant)) {
    return false;
  }

  if (isDeclined(agreement) || isSigned(agreement)) {
    return false;
  }

  const isPartyOwner = currentUserIsOwner || party.id === myParty.id;
  const myParticipantIsSameDevice = hasDeliveryChannelSameDevice(myParticipant);

  if (isPartyOwner) {
    if (isDraft(agreement) || !hasSignOrder(agreement)) {
      return !myParticipantIsSameDevice;
    }
  }

  if (
    currentUserIsOwner
    && hasSignOrder(agreement)
    && isPending(agreement)
  ) {
    return true;
  }

  return false;
};

type AllowAddColleague = (
  Account,
  Agreement,
  AgreementParty,
) => boolean;
export const allowAddColleague: AllowAddColleague = (
  account,
  agreement,
  party,
) => {
  const currentUserIsOwner = isAgreementOwner(account, agreement);
  const myParty = getAgreementMyParty(agreement);
  const myParticipant = getAgreementMyParticipant(agreement);
  const myParticipantIsViewer = myParticipant && isViewer(myParticipant);

  const allowedToAddColleagues = currentUserIsOwner
    && (
      isDraft(agreement)
      || isAnySignedState(agreement)
      || (isPending(agreement) && hasSignOrder(agreement))
      || !hasSignOrder(agreement)
      || isPending(agreement)
    );

  const isPartyOwner = currentUserIsOwner || (party && myParty && party?.id === myParty?.id);

  if (isTemplate(agreement)) {
    return true;
  }

  if (isIndividual(party)) {
    return false;
  }

  let allowColleague = false;

  if (checkAcl(agreement.acl, 'agreement:participant:colleague:create')) {
    allowColleague = true;
    const isConcluded = isAnySignedState(agreement) || isAnyDeclinedState(agreement);
    const isPublished = !isDraft(agreement) && !isTemplate(agreement);

    if (
      hasSignOrder(agreement)
      && isPublished
      && !isConcluded
      && !currentUserIsOwner
    ) {
      allowColleague = false;
    }

    return allowColleague;
  }

  if (!myParticipant || myParticipantIsViewer) {
    return allowColleague;
  }

  if (allowedToAddColleagues) {
    allowColleague = true;
  }

  if (isDeclined(agreement) || isSigned(agreement)) {
    return allowColleague;
  }

  const myParticipantIsSameDevice = hasDeliveryChannelSameDevice(myParticipant);

  if (isPartyOwner) {
    return !myParticipantIsSameDevice;
  }

  return false;
};

export const canEditAnyPartyDetails: PartyHelper = (party) => {
  const hasUpdateNamePermission = checkAcl(party.acl, 'party:update:name');
  const hasUpdateOrgPermission = checkAcl(party.acl, 'party:update:orgnr');
  const hasUpdateCountryPermission = checkAcl(party.acl, 'party:update:country');

  const result = hasUpdateNamePermission
    || hasUpdateOrgPermission
    || hasUpdateCountryPermission;

  return result;
};
