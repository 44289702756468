import {
  memo,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import log from 'logging';
import clsx from 'clsx';
import { uniqueId } from 'lodash';

import {
  contractVersionNotUpToDateToast,
  ANNOTATION_ACTION_IN_TEXT_BOX_ERROR,
  SAVE_ANNOTATION_IN_TEXT_BOX_ERROR_TITLE,
} from 'comments/constants';
import {
  addAcceptedSuggestion,
  addRejectedSuggestion,
  getCurrentContractId,
  onResolveComment,
  updateSuggestionStatusWithoutSaving,
} from 'reducers/current-contract';
import { setFocusedEditor } from 'reducers/editor';
import { isCommentResolved, isSuggestion, isSuggestionResolved } from 'comments';
import { getAgreementMyParticipant, getGuestToken } from 'agreement/selectors';

import { isSmallScreenWidth } from 'ui/config';

import useAgreement from 'hooks/use-agreement';
import useAnnotationEditor from 'hooks/use-annotation-editor';
import { useAnnotationProps } from 'contexts/annotation-props';
import useAnnotationsMap from 'hooks/use-annotations-map';
import useCurrentBoxes from 'hooks/use-current-boxes';

import type { AmplitudeData } from 'components/messages-layout/single-message-box/single-message-box';
import { checkAcl } from 'components/acl';
import * as DropdownMenu from 'components/dropdown-menu-v2';
import { getErrorCode } from 'components/api-error';
import Message from 'components/message';
import Tooltip from 'components/tooltip';
import Button from 'components/button';
import {
  acceptSuggestion,
  rejectSuggestion,
  findAnnotationMatch,
  isOrphanAnnotation,
} from 'components/contract-text-editor/editor-plugins/annotation-plugin';
import ActionsDotsIcon from 'components/icons/actions-dots';
import NewCheck from 'components/icons/new-check';
import NewCross from 'components/icons/new-cross';
import toast from 'components/toasts';

import style from './action-button.module.scss';

type MessageType = {
  acl: object,
  id: number,
  config: object,
  parent?: number | null,
  isResolvable: boolean,
  ownerParticipant: { id: number },
  type: number,
};

export type Props = {
  disabled?: boolean,
  message: MessageType,
  action: 'resolve' | 'reject',
  amplitudeData: AmplitudeData,
};

const ActionButton = ({
  message,
  disabled,
  action,
  amplitudeData,
}: Props) => {
  const isSmallScreen = isSmallScreenWidth();
  const editor = useAnnotationEditor(message.id);
  const annotationsMap = useAnnotationsMap();
  const boxesMap = useCurrentBoxes();

  const isOrphan = isOrphanAnnotation(
    message.id,
    annotationsMap[message.id],
    boxesMap,
  );

  const getNodes = useCallback(() => {
    const nodePathPairs = findAnnotationMatch(editor, message.id);
    const nodesToPerformActionOn = nodePathPairs.map((pair) => pair[0]);

    return nodesToPerformActionOn;
  }, [editor, message.id]);

  const dispatch = useDispatch();
  const {
    setAcceptedSuggestion,
  } = useAnnotationProps();
  const amplitudeScope = amplitudeData?.location;
  const guestToken = useSelector(getGuestToken);
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const isMessageSuggestion = isSuggestion(message);
  const annotationType = isMessageSuggestion ? 'suggestion' : 'comment';

  const isResolved = isCommentResolved(message) || isSuggestionResolved(message);
  const myParticipant = getAgreementMyParticipant(agreement);
  const canResolve = checkAcl(message.acl, 'message:resolve');
  const canAcceptSuggestion = isMessageSuggestion && checkAcl(message.acl, 'message:suggestion:accept');
  const canRejectSuggestion = isMessageSuggestion && checkAcl(message.acl, 'message:suggestion:reject');

  const onFailure = useCallback((error: ReactNode) => {
    const rollbarMessage = `${annotationType.charAt(0).toUpperCase() + annotationType.slice(1)}s: ${action.charAt(0).toUpperCase() + action.slice(1)} failed`;
    log.error(error, rollbarMessage);
    const errorCodeMessage = getErrorCode(error);
    if (errorCodeMessage === 'DATA_NODES_CONFLICT') {
      contractVersionNotUpToDateToast();
    } else {
      toast.error({
        id: 'resolve-comment',
        title: SAVE_ANNOTATION_IN_TEXT_BOX_ERROR_TITLE,
        description: ANNOTATION_ACTION_IN_TEXT_BOX_ERROR,
      });
    }
  }, [action, annotationType]);

  const resolveSuggestion = useCallback(() => {
    setAcceptedSuggestion({
      isAcceptedSuggestion: true,
      messageId: message.id,
    });

    // updates currentContract.acceptedSuggestions
    dispatch(addAcceptedSuggestion(message.id, getNodes()));

    setTimeout(() => {
      // updates nodes
      acceptSuggestion(editor, message.id);

      // updates agreement.messages
      dispatch(updateSuggestionStatusWithoutSaving(message.id, agreementId, myParticipant, 'accepted', editor));

      dispatch(setFocusedEditor(null));
    }, 300);
  }, [
    agreementId,
    dispatch,
    editor,
    getNodes,
    message.id,
    myParticipant,
    setAcceptedSuggestion,
  ]);

  const rejectSuggestions = useCallback(() => {
    // updates currentContract.rejectedSuggestions
    dispatch(addRejectedSuggestion(message.id, getNodes()));

    setTimeout(() => {
      // updates nodes
      rejectSuggestion(editor, message.id);

      // updates agreement.messages
      dispatch(updateSuggestionStatusWithoutSaving(message.id, agreementId, myParticipant, 'rejected', editor));

      dispatch(setFocusedEditor(null));
    }, 0);
  }, [
    agreementId,
    dispatch,
    editor,
    getNodes,
    message.id,
    myParticipant,
  ]);

  const resolveComment = useCallback(() => {
    dispatch(onResolveComment({
      annotationId: message.id,
      resolvedByParticipant: myParticipant,
      amplitudeScope,
      onFailure,
    }));
  }, [
    amplitudeScope,
    dispatch,
    message.id,
    myParticipant,
    onFailure,
  ]);

  const onActionButtonClick = useCallback((event: Event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isMessageSuggestion) {
      if (!editor) {
        return;
      }

      if (action === 'resolve') {
        resolveSuggestion();
      } else {
        rejectSuggestions();
      }

      return;
    }

    resolveComment();
  }, [
    isMessageSuggestion,
    resolveComment,
    editor,
    action,
    resolveSuggestion,
    rejectSuggestions,
  ]);

  const disabledTooltipMessage = useMemo(() => {
    if (isMessageSuggestion) {
      if (action === 'resolve') {
        return (
          <Message
            id="Save changes to resolve suggestion"
            comment="Text in the tooltip to show you need to save changes to be able to resolve or reject annotation."
          />
        );
      }

      return (
        <Message
          id="Save changes to reject suggestion"
          comment="Text in the tooltip to show you need to save changes to be able to resolve or reject annotation."
        />
      );
    }

    return (
      <Message
        id="Save changes to resolve comment"
        comment="Text in the tooltip to show you need to save changes to be able to resolve or reject annotation."
      />
    );
  }, [action, isMessageSuggestion]);

  const renderTooltipMessage = useCallback(() => {
    if (disabled) {
      return disabledTooltipMessage;
    }

    if (isMessageSuggestion) {
      if (action === 'resolve') {
        return (
          <Message
            id="Accept"
            comment="Text in the tooltip to show you can accept suggestion."
          />
        );
      }

      return (
        <Message
          id="Reject"
          comment="Text in the tooltip to show you can reject suggestion."
        />
      );
    }

    return (
      <Message
        id="Mark as resolved"
        comment="Text in the tooltip to show you can mark as resolved comment or accept suggestion."
      />
    );
  }, [action, disabled, disabledTooltipMessage, isMessageSuggestion]);

  if (isResolved) {
    return null;
  }
  if (isMessageSuggestion) {
    if (!isSmallScreen) {
      if (action === 'resolve' && !canAcceptSuggestion) {
        return null;
      }
      if (action === 'reject' && !canRejectSuggestion) {
        return null;
      }
    }
  } else if (!canResolve) {
    return null;
  }

  const actionButtonClasses = clsx(style.ActionButton, {
    [style.RedIcon]: action === 'reject',
  });

  if (isMessageSuggestion && isOrphan) {
    return null;
  }

  if (isSmallScreen && Boolean(guestToken)) {
    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className={clsx(style.Trigger, {
            [style.HiddenMenu]: (
              (isMessageSuggestion
              && !canAcceptSuggestion
              && !canRejectSuggestion)
              || (
                !isMessageSuggestion
                && !canResolve
              )
            ),
          })}
          >
            <ActionsDotsIcon width="16px" />
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="center"
            collisionPadding={8}
            onCloseAutoFocus={(event) => {
              event.preventDefault();
            }}
          >
            {canResolve && (
              <DropdownMenu.Item
                key={uniqueId()}
                onSelect={resolveComment}
              >
                <div className={style.MenuItemWrapper}>
                  <NewCheck color="#187bb7" />
                  <Message
                    id="Mark as resolved"
                    comment="Label for resolving a comment."
                  />
                </div>
              </DropdownMenu.Item>
            )}
            {canAcceptSuggestion && (
              <DropdownMenu.Item
                key={uniqueId()}
                onSelect={resolveSuggestion}
              >
                <div className={style.MenuItemWrapper}>
                  <NewCheck color="#187bb7" />
                  <Message
                    id="Resolve suggestion"
                    comment="Label for resolving a suggestion."
                  />
                </div>
              </DropdownMenu.Item>
            )}
            {canRejectSuggestion && (
              <DropdownMenu.Item
                key={uniqueId()}
                onSelect={rejectSuggestions}
              >
                <div className={style.MenuItemWrapper}>
                  <NewCross color="#D13D47" />
                  <Message
                    id="Reject suggestion"
                    comment="Label for rejecting a suggestion."
                  />
                </div>
              </DropdownMenu.Item>
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  }

  return (
    <Tooltip
      message={(
        <div className={style.TooltipMessage}>
          {renderTooltipMessage()}
        </div>
      )}
      side="top"
      zIndex="10003"
    >
      <div className={style.ActionWrapper}>
        <Button
          data-testid="annotation-action-button"
          icon={action === 'resolve' ? NewCheck : NewCross}
          disabled={disabled}
          onClick={onActionButtonClick}
          customClass={actionButtonClasses}
          trackable={isMessageSuggestion && {
            name: (action === 'resolve')
              ? 'Approve Suggestion' : 'Reject Suggestion',
            props: {
              location: amplitudeScope,
              'participant type': guestToken ? 'guest' : 'owner',
            },
            groups: {
              'document id': agreementId,
            },
          }}
        />
      </div>
    </Tooltip>
  );
};

export default memo(ActionButton);
