import { useMemo } from 'react';
import clsx from 'clsx';

import {
  COMMENT_STATES,
  EMPTY_STATE_HEADLINES_WITH_SUGGESTIONS,
  EMPTY_STATE_HEADLINES_WITHOUT_SUGGESTIONS,
  EMPTY_STATE_MESSAGE_TYPES,
  EMPTY_STATE_MESSAGES_WITH_SUGGESTIONS,
  EMPTY_STATE_MESSAGES_WITHOUT_SUGGESTIONS,
  ICON_TYPES,
  ICONS,
  MESSAGE_TYPES,
} from 'comments/constants';
import { isSuggestionsAvailable } from 'comments';
import { isTemplate } from 'agreement';
import useCurrentMessages from 'hooks/use-current-messages';
import useMatchMedia, { DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY } from 'hooks/use-match-media';

import style from './empty-state.module.scss';

const { COMMENTS } = MESSAGE_TYPES;
const {
  COMMENT_ACTIVE,
  COMMENT_ALL,
  COMMENT_RESOLVED,
} = COMMENT_STATES;
const {
  CHAT_ICON,
  COMMENTS_ACTIVE_ICON,
  COMMENTS_ALL_RESOLVED_ICON,
  COMMENTS_RESOLVED_ICON,
  TEMPLATE_CHAT_ICON,
  TEMPLATE_COMMENTS_ICON,
} = ICON_TYPES;
const {
  ACTIVE_EMPTY,
  CHAT_EMPTY,
  CHAT_TEMPLATE,
  COMMENTS_EMPTY,
  COMMENTS_TEMPLATE,
  RESOLVED_ALL,
  RESOLVED_EMPTY,
} = EMPTY_STATE_MESSAGE_TYPES;

type Props = {
  agreement: Oneflow.Agreement,
  currentlyActiveCommentsState: string,
  currentlyActiveTab: string,
};

const EmptyState = ({
  agreement,
  currentlyActiveCommentsState,
  currentlyActiveTab,
}: Props) => {
  const {
    activeAnnotations = [],
    resolvedAnnotations = [],
  } = useCurrentMessages();
  const isActiveAnnotationsEmpty = !activeAnnotations.length;
  const isResolvedAnnotationsEmpty = !resolvedAnnotations.length;
  const emptyStateHeadlines = isSuggestionsAvailable(agreement)
    ? EMPTY_STATE_HEADLINES_WITH_SUGGESTIONS
    : EMPTY_STATE_HEADLINES_WITHOUT_SUGGESTIONS;
  const emptyStateMessages = isSuggestionsAvailable(agreement)
    ? EMPTY_STATE_MESSAGES_WITH_SUGGESTIONS
    : EMPTY_STATE_MESSAGES_WITHOUT_SUGGESTIONS;

  const isCollapsedLayout = useMatchMedia(DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY);

  const emptyStateMessage = useMemo(() => {
    if (currentlyActiveTab === COMMENTS) {
      if (isTemplate(agreement)) {
        return (
          <>
            {ICONS[TEMPLATE_COMMENTS_ICON]}
            {emptyStateHeadlines[COMMENTS_TEMPLATE]}
            <span data-testid="empty-state-message">
              {emptyStateMessages[COMMENTS_TEMPLATE]}
            </span>
          </>
        );
      }

      if (currentlyActiveCommentsState === COMMENT_ACTIVE) {
        if (isActiveAnnotationsEmpty && !isResolvedAnnotationsEmpty) {
          return (
            <>
              {ICONS[COMMENTS_ALL_RESOLVED_ICON]}
              {emptyStateHeadlines[RESOLVED_ALL]}
              <span data-testid="empty-state-message">
                {emptyStateMessages[RESOLVED_ALL]}
              </span>
            </>
          );
        }

        return (
          <>
            {ICONS[COMMENTS_ACTIVE_ICON]}
            {emptyStateHeadlines[ACTIVE_EMPTY]}
            <span data-testid="empty-state-message">
              {emptyStateMessages[COMMENTS_EMPTY]}
            </span>
          </>
        );
      }

      if (currentlyActiveCommentsState === COMMENT_RESOLVED && isResolvedAnnotationsEmpty) {
        return (
          <>
            {ICONS[COMMENTS_RESOLVED_ICON]}
            {emptyStateHeadlines[RESOLVED_EMPTY]}
            <span data-testid="empty-state-message">
              {emptyStateMessages[RESOLVED_EMPTY]}
            </span>
          </>
        );
      }

      if (
        currentlyActiveCommentsState === COMMENT_ALL
        && isActiveAnnotationsEmpty
        && isResolvedAnnotationsEmpty
      ) {
        return (
          <>
            {ICONS[COMMENTS_ACTIVE_ICON]}
            {emptyStateHeadlines[COMMENTS_EMPTY]}
            <span data-testid="empty-state-message">
              {emptyStateMessages[COMMENTS_EMPTY]}
            </span>
          </>
        );
      }
    }

    if (isTemplate(agreement)) {
      return (
        <>
          {ICONS[TEMPLATE_CHAT_ICON]}
          {emptyStateHeadlines[CHAT_TEMPLATE]}
          <span data-testid="empty-state-message">
            {emptyStateMessages[CHAT_TEMPLATE]}
          </span>
        </>
      );
    }

    return (
      <>
        {ICONS[CHAT_ICON]}
        {emptyStateHeadlines[CHAT_EMPTY]}
        <span data-testid="empty-state-message">
          {emptyStateMessages[CHAT_EMPTY]}
        </span>
      </>
    );
  }, [
    agreement,
    currentlyActiveCommentsState,
    currentlyActiveTab,
    emptyStateHeadlines,
    emptyStateMessages,
    isActiveAnnotationsEmpty,
    isResolvedAnnotationsEmpty,
  ]);

  const emptyStateClasses = clsx(style.EmptyState, {
    [style.EmptyStateMobile]: isCollapsedLayout,
  });

  return (
    <div className={emptyStateClasses}>
      {emptyStateMessage}
    </div>
  );
};

export default EmptyState;
