import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';
import { Form } from 'react-final-form';
import TooltipInfo from 'components/tooltip-info';
import Field from 'components/field';
import TextArea from 'components/text-area';
import PopoverCloseButton from 'components/buttons/popover-close-button';
import { getAltText, setAltText } from '../alt-text-plugin';

import style from './alt-text-content.module.scss';

export const AltTextContent = () => {
  const editor = useSlate();
  const altText = getAltText(editor);

  const onFormSubmit = (formProps) => {
    setAltText(editor, formProps.values?.altText);
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={{
        altText,
      }}
      render={(formProps) => (
        <form
          onSubmit={formProps.handleSubmit}
          autoComplete="off"
        >
          <>
            <div>
              <Field
                name="altText"
                value={altText}
                component={TextArea}
                label={(
                  <div className={style.Label}>
                    <Message
                      id="Alt Text"
                      comment="Label for editing alt text"
                    />
                    <TooltipInfo
                      message={(
                        <Message
                          id="Alt-text, or alternative text, helps people with impaired vision to understand an image's content and context through screen readers with a concise description."
                          comment="Tooltip hint in the Alt Text popover."
                        />
                      )}
                      side="top"
                      theme="oneflow"
                      zIndex="1031"
                    />
                  </div>
                )}
                maxLength={255}
              />
            </div>
            <div className={style.Actions}>
              <PopoverCloseButton>
                <Message
                  id="Cancel"
                  comment="Action to cancel editing of the alt text"
                />
              </PopoverCloseButton>
              <PopoverCloseButton
                onClick={() => onFormSubmit(formProps)}
                disabled={!formProps.valid}
                type="submit"
                kind="primary"
              >
                <Message
                  id="Save"
                  comment="Action to save the edited attachment name"
                />
              </PopoverCloseButton>
            </div>
          </>
        </form>
      )}
    />
  );
};
