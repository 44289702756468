import {
  createContext,
  useContext,
  useMemo,
  useState,
  useRef,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

type AnnotationProps = {
  acceptedSuggestion: {
    isAcceptedSuggestion: boolean;
    messageId: number | null;
  };
  setAcceptedSuggestion: Dispatch<SetStateAction<object | null>>;
  newAnnotationIdRef: React.MutableRefObject<number | null>;
  highlightedAnnotationId: number | null;
  setHighlightedAnnotationId: Dispatch<SetStateAction<number | null>>;
}

export const AnnotationPropsContext = createContext<AnnotationProps | null>(null);

type Props = {
  children: ReactNode,
};

export function AnnotationPropsProvider({
  children,
}: Props) {
  const newAnnotationIdRef = useRef<number | null>(null);
  const [acceptedSuggestion, setAcceptedSuggestion] = useState<{
    isAcceptedSuggestion: boolean;
    messageId: number | null;
  }>({
    isAcceptedSuggestion: false,
    messageId: null,
  });
  const [openAnnotationId, setOpenAnnotationId] = useState<number | null>(null);
  const [hoveredAnnotationId, setHoveredAnnotationId] = useState<number | null>(null);
  const [highlightedAnnotationId, setHighlightedAnnotationId] = useState<number | null>(null);

  const contextValue = useMemo(() => ({
    acceptedSuggestion,
    hoveredAnnotationId,
    newAnnotationIdRef,
    openAnnotationId,
    highlightedAnnotationId,
    setAcceptedSuggestion,
    setHoveredAnnotationId,
    setOpenAnnotationId,
    setHighlightedAnnotationId,
  }), [
    acceptedSuggestion,
    hoveredAnnotationId,
    newAnnotationIdRef,
    openAnnotationId,
    highlightedAnnotationId,
    setAcceptedSuggestion,
    setHoveredAnnotationId,
    setOpenAnnotationId,
    setHighlightedAnnotationId,
  ]);

  return (
    <AnnotationPropsContext.Provider value={contextValue}>
      {children}
    </AnnotationPropsContext.Provider>
  );
}

export const useAnnotationProps = (): AnnotationProps => {
  const contextValue = useContext(AnnotationPropsContext);

  if (!contextValue) {
    throw new Error('useAnnotationProps should be used inside a AnnotationPropsContext');
  }

  return contextValue;
};
