/* eslint-disable import/named */
import {
  useCallback,
  useState,
} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import type { Moment } from 'moment';

import {
  formatDateString,
  toTimestamp,
} from 'date';
import { useDurationBoxProps } from 'contexts/duration-box-props';

import { DayPickerSingleDateController } from 'components/date-picker';
import {
  Popover,
  PopoverContent,
  PopoverPortal,
} from 'components/popover';
import { SelectItem } from 'components/select-field-radix';
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';
import Select from 'components/select';

import style from './duration-date-picker.module.scss';

type Props = {
  changeDateHandler: (date: number) => void,
  date: string,
  isOutsideRange: (range: Moment) => boolean,
}

/* eslint-disable react/no-unused-prop-types */
type MontElementParams = {
  month: Moment,
  onMonthSelect: (month: Moment, value: number) => void,
  onYearSelect: (year: Moment, value: number) => void,
}

export const DurationDatePicker = ({
  changeDateHandler,
  date,
  isOutsideRange,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    dateFormat,
    isAllowedToEditBoxData,
  } = useDurationBoxProps();
  const formattedDateString = formatDateString(date, dateFormat);

  const renderMonthElement = useCallback(({
    month,
    onMonthSelect,
    onYearSelect,
  }: MontElementParams) => {
    const renderMonths = () => moment.months().map((label, value) => (
      <SelectItem
        key={label}
        value={value}
      >
        {label}
      </SelectItem>
    ));

    const renderYears = () => {
      const yearLimit = moment().add(100, 'y').year();
      const years = [];

      for (let yearValue = 1970; yearValue <= yearLimit; yearValue += 1) {
        years.push(
          <SelectItem
            key={yearValue}
            value={yearValue}
          >
            {yearValue}
          </SelectItem>,
        );
      }

      return years;
    };

    return (
      <div className={style.SelectorContainer}>
        <Select
          value={month.month()}
          label={moment().month(month.month()).format('MMMM')}
          onValueChange={(value: number) => onMonthSelect(month, value)}
          selectClassName={clsx(style.Select, style.MonthSelect)}
        >
          {renderMonths()}
        </Select>
        <Select
          value={month.year()}
          label={month.year()}
          onValueChange={(value: number) => onYearSelect(month, value)}
          selectClassName={style.Select}
        >
          {renderYears()}
        </Select>
      </div>
    );
  }, []);

  if (!isAllowedToEditBoxData) {
    return (
      <span>
        {formattedDateString}
      </span>
    );
  }

  return (
    <Popover open={isOpen}>
      <PopoverTriggerButton
        customClass={style.PickedDate}
        onClick={() => setIsOpen(true)}
      >
        {formattedDateString}
      </PopoverTriggerButton>
      <PopoverPortal>
        <PopoverContent
          className={style.PopoverContent}
          onEscapeKeyDown={() => setIsOpen(false)}
          onInteractOutside={() => setIsOpen(false)}
        >
          <DayPickerSingleDateController
            date={moment(date)}
            focused={isOpen}
            hideKeyboardShortcutsPanel
            isOutsideRange={isOutsideRange}
            numberOfMonths={1}
            onDateChange={(pickedDate: Moment) => {
              changeDateHandler(toTimestamp(pickedDate));
              setIsOpen(false);
            }}
            renderMonthElement={renderMonthElement}
          />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};
