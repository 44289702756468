import { ReactNode } from 'react';

import Button from 'components/button';

import BoxEmptyDraftStateRaw from './box-empty-draft-state-raw';

type Props = {
  icon: ReactNode,
  onClick?: () => void,
  buttonText?: ReactNode,
  isDisabled: boolean,
  description?: ReactNode,
  customClassName?: string,
};

const BoxEmptyDraftState = ({
  icon,
  onClick,
  buttonText,
  isDisabled,
  description,
  customClassName,
}: Props) => {
  const generateButton = () => {
    if (!onClick || !buttonText) {
      return null;
    }

    return (
      <Button
        onClick={onClick}
        disabled={isDisabled}
        kind="secondary"
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <BoxEmptyDraftStateRaw
      icon={icon}
      customClassName={customClassName}
      mainAction={(
        <>
          {generateButton()}
          {description}
        </>
    )}
    />
  );
};

export default BoxEmptyDraftState;
