import {
  useState, useCallback, ReactElement,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';
import sessionStorage from 'utils/session-storage';

import client from 'oneflow-client';
import { aiExtractAgreement } from 'oneflow-client/ai-extract';
// eslint-disable-next-line import/named
import { importContract, fetchAgreement } from 'oneflow-client/agreements';
import agreementsReducer from 'reducers/entities/agreements';
import agreementTemplatesReducer, { getTemplatesWithAgreementsSelector } from 'reducers/entities/agreement-templates';
import { getCurrentWorkspaceIdSelector, getCurrentWorkspaceSelector } from 'reducers/app';
import type { Asset } from 'reducers/current-contract';
import { SYSTEM_TAG_ID } from 'tags/constants';
import { redirectToDocumentOnDocumentCreate, redirectToDocument } from 'agreement/navigation-helpers';
import { FILE_APPLICATION_PDF, FILE_IMAGE_TIFF } from 'utils/file';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import type { Box } from 'data-validators/entity-schemas/document-box';
import { checkAcl } from 'components/acl';

import { CancelButton } from 'components/buttons';
import { BetaLabel } from 'components/beta-label';
import Button from 'components/button';
import Confirmable from 'components/confirmable';
import FileUpload from 'components/file-upload';
import TemplateResults from 'components/template-results';
import TemplateSearch from 'components/template-search';
import Modal from 'components/modal';
import { getPdfBoxContent } from 'agreement/import';
import { StaticSystemImportLabel } from 'components/tags/tag/tag';
import FolderIcon from 'components/icons/folder';
import WorkspaceIcon from 'components/icons/workspace';
import { Checkbox as CheckboxToggle } from 'components/toggle-switch';
import PdfDocument from 'components/icons/pdf-document';
import Delete from 'components/icons/delete';
import CircularSpinner from 'components/icons/circular-spinner';
import { transformExtractedData } from './helpers';

import style from './import-contract.module.scss';

const modalKey = 'import contract modal';

const getWorkspaceQuerySelector = (state, { queryName }) => (
  `workspace-${getCurrentWorkspaceIdSelector(state)}::${queryName}`
);

type ExtractionState = {
  loading: boolean,
  success: boolean,
  error: null | Error,
}

export type Props = {
  children: ReactElement | ReactElement[],
  folderId?: number,
  selectedFolder?: Oneflow.Folder,
  selectedUniqueKey?: number,
  workspace: Oneflow.Workspace,
};

export const ImportContract = ({
  children,
  folderId,
  selectedFolder,
  selectedUniqueKey,
  workspace,
}: Props) => {
  const [
    assetUploadErrorCode,
    setAssetUploadErrorCode,
  ] = useState<string | number | undefined>(undefined);
  const [preparing, setPreparing] = useState<boolean>(false);
  const [extractionState, setExtractionState] = useState<ExtractionState>({
    loading: false,
    success: false,
    error: null,
  });
  const agreement = useRef<Oneflow.Agreement | undefined>(undefined);
  const [importMethod, setImportMethod] = useState<'standard' | 'ai'>('standard');
  const [uploadedPdfName, setUploadedPdfName] = useState<string | null>(null);
  const dispatch = useDispatch();
  const queryName = useSelector((state) => getWorkspaceQuerySelector(state, { queryName: 'active-templates-with-import-tag' }));
  const query = useSelector((state) => (
    agreementTemplatesReducer.getQuerySelector(state, { name: queryName })
  ));
  const createState = useSelector((state) => agreementsReducer.getCreateSelector(state));
  const currentWorkspace = useSelector(getCurrentWorkspaceSelector);
  const isAiImportOptionEnabled = checkAcl(currentWorkspace.acl, 'collection:ai_import:use');
  const templates = useSelector((state) => (
    getTemplatesWithAgreementsSelector(state, { ids: query.result })
  ));

  const requestTemplates = useCallback(() => {
    dispatch(agreementTemplatesReducer.queryAgreementTemplates({
      name: queryName,
      pagination: {
        limit: 10000,
        offset: 0,
      },
      params: {
        visible: 1,
        tags: [SYSTEM_TAG_ID],
      },
    }));
  }, [dispatch, queryName]);

  const extractAndRedirect = useCallback((agreementId: number) => {
    if (!agreementId) {
      return;
    }
    setExtractionState((state: ExtractionState) => ({
      ...state,
      loading: true,
    }));
    aiExtractAgreement(agreementId)
      .then((result: any) => {
        const transformedResult = transformExtractedData(result, agreementId);
        sessionStorage.setItem('extracted_data', JSON.stringify(transformedResult));
      }).then(() => {
        setExtractionState((state: ExtractionState) => ({
          ...state,
          loading: false,
          success: true,
        }));
        redirectToDocument(agreementId);
      })
      .catch((error: Error) => {
        setExtractionState({
          loading: false,
          success: false,
          error,
        });
      });
  }, []);

  const setAttachmentDocument = useCallback(({ agreementId, data, boxes }: {
    agreementId: number,
    data: any[],
    boxes: Box[],
  }) => {
    dispatch(agreementsReducer.updateAttachmentBox({
      id: agreementId,
      data: {
        data,
        boxes,
      },
      pipe: {
        onSuccess: (res) => {
          if (!isAiImportOptionEnabled) {
            redirectToDocumentOnDocumentCreate(res);
          }
          setPreparing(false); // to hide the fake 90% loader
        },
      },
    }));
  }, [dispatch, isAiImportOptionEnabled]);

  const resetCreateState = useCallback(() => {
    dispatch(agreementsReducer.createAgreementReset());
  }, [dispatch]);

  const handleEnter = useCallback(() => undefined, []);

  const handleOpen = useCallback(() => {
    resetCreateState();
    requestTemplates();
  }, [resetCreateState, requestTemplates]);

  const handleClose = useCallback(() => {
    setPreparing(false);
    setUploadedPdfName(null);
    setExtractionState({
      loading: false,
      success: false,
      error: null,
    });
  }, []);

  const prepareDocument = useCallback(() => {
    setPreparing(true);

    const fetchContract = ({ result }) => fetchAgreement({ id: result });

    const setContract = ({ normalized }) => {
      const { result: id, entities } = normalized;
      const contract = entities.agreements[id];
      agreement.current = contract;
    };

    return importContract({
      workspaceId: currentWorkspace.id,
      folderId: selectedUniqueKey === -1 ? null : selectedUniqueKey,
    }).then(fetchContract)
      .then(setContract);
  }, [currentWorkspace.id, selectedUniqueKey]);

  const onAssetPagesDone = useCallback((responseJSON: Asset) => {
    if (isAiImportOptionEnabled) {
      setUploadedPdfName(responseJSON.assetName);
    }

    const { boxes } = agreement.current;
    const boxesContent = getPdfBoxContent(responseJSON, boxes);

    setAttachmentDocument({
      agreementId: agreement.current?.id,
      data: [boxesContent.contentData],
      boxes: boxesContent.boxes,
    });
  }, [isAiImportOptionEnabled, setAttachmentDocument]);

  const uploadDocument = useCallback(({ file, type }) => {
    const contractId = agreement.current?.id;
    return client.uploadDocument({
      file,
      type,
      contractId,
    });
  }, []);

  const onFileUploadError = useCallback((errorCode: string | number) => {
    setAssetUploadErrorCode(errorCode);
  }, []);

  const resetFileUploadState = useCallback(() => {
    setAssetUploadErrorCode(undefined);
    setPreparing(false);
  }, []);

  const isLoading = useCallback(() => get(query, 'loading'), [query]);
  const isSearching = useCallback(() => Boolean(get(query, 'params.q')), [query]);

  const getErrorMessage = useCallback(() => {
    const statusCode = get(createState.error, 'body.status_code');
    const statusCodeExtraction = get(extractionState.error, 'response.status');
    const apiErrorCodeExtraction = get(extractionState.error, 'body.api_error_code');

    if (importMethod === 'ai' && statusCodeExtraction === 504) {
      return {
        headerText: (
          <Message
            id="{featureName} is currently unavailable."
            comment="Error header in the import modal."
            values={{ featureName: 'AI Import' }}
          />
        ),
        bodyText: (
          <Message
            id="Please try again later or add the details manually."
            comment="Error body in the import modal."
          />
        ),
      };
    }

    // Temporary, remove after implementing OF-14748
    if (importMethod === 'ai' && apiErrorCodeExtraction === 6011) {
      return {
        headerText: (
          <Message
            id="While {featureName} is in beta, it can only process files with 20 pages or less."
            comment="Error header in the import modal."
            values={{ featureName: 'AI Import' }}
          />
        ),
        bodyText: (
          <Message
            id="Please use manual import or try again later."
            comment="Error body in the import modal."
          />
        ),
      };
    }

    if (statusCode === undefined) {
      return null;
    }

    return {
      headerText: (
        <Message
          id="A contract could not be created from this template."
          comment="Error header in the template launcher modal."
        />
      ),
      bodyText: (
        <Message
          id="Please contact support at {email}. Error code: {statusCode}"
          values={{
            email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
            statusCode,
          }}
          comment="Error body text when not possible to create a contract."
        />
      ),
    };
  }, [
    createState.error,
    extractionState.error,
    importMethod,
  ]);

  const shouldRenderLoader = useCallback(() => {
    const hasError = Boolean(assetUploadErrorCode);
    return preparing && !hasError;
  }, [assetUploadErrorCode, preparing]);

  const renderToggleSection = useCallback(() => {
    if (!isAiImportOptionEnabled) {
      return null;
    }
    return (
      <Modal.BodySection showWhenLoading>
        <div className={style.ToggleContainer}>
          <CheckboxToggle
            input={{
              checked: importMethod === 'ai',
              onChange: () => {
                setImportMethod(importMethod === 'standard' ? 'ai' : 'standard');
                amplitudeLogEvent('Temp Toggle Import Type');
              },
            }}
            valueLabels={[
              <Message key="manual" id="Manual" comment="Adjective, showed in a modal where user can choose a way to import a contract" />,
              'AI Import',
            ]}
            disabled={importMethod === 'ai' && (extractionState.success || extractionState.loading)}
            rightLabelClass={style.ToggleLabel}
            leftLabelClass={style.ToggleLabel}
            toggleSwitchClass={style.ToggleSwitch}
          />
          {importMethod === 'standard'
            ? (
              <Message
                id="Add duration and participants manually."
                comment="Helper text to make it clear for the user what the import is about."
              />
            ) : (
              <div className={style.AiImportLabel}>
                <BetaLabel />
                <Message
                  id="Duration and participants are extracted using OpenAI."
                  comment="Helper text to make it clear for the user what the import is about."
                />
              </div>
            )}
        </div>
      </Modal.BodySection>
    );
  }, [extractionState, importMethod, isAiImportOptionEnabled]);

  const renderBody = useCallback(() => {
    const hasFolder = folderId !== -1 && selectedFolder !== undefined;
    const icon = hasFolder ? <FolderIcon height="18px" /> : <WorkspaceIcon height="10px" />;

    const name = hasFolder ? selectedFolder.name : workspace.name;

    return (
      <Modal.BodySections>
        <Modal.BodySection showWhenLoading>
          <div className={style.Folder}>
            <Message
              id="Document will be saved to"
              comment="Tells user which folder the document will be imported to"
            />
            <div className={style.SelectedFolder}>
              {icon}
              <span className={style.SelectedFolder}>
                {name}
              </span>
            </div>
          </div>
          {renderToggleSection()}
          <div style={{ marginBottom: '1rem' }} className={style.Message}>
            <Message
              id="Upload a signed document"
              comment="Helper text that shows above the dropzone where the user can upload a signed document file"
            />
          </div>
          {uploadedPdfName ? (
            <div className={style.UploadedPdf}>
              <PdfDocument height="14px" />
              <span>
                {uploadedPdfName}
                .pdf
              </span>
              <Button icon={<Delete height="14px" />} onClick={() => setUploadedPdfName(null)}>
                <Message id="Remove" comment="Button text to remove an uploaded document" />
              </Button>
            </div>
          ) : (
            <FileUpload
              accept={[FILE_APPLICATION_PDF, FILE_IMAGE_TIFF]}
              assetType="document"
              onUploadDone={onAssetPagesDone}
              prepare={prepareDocument}
              loading={shouldRenderLoader()}
              onUpload={uploadDocument}
              onError={onFileUploadError}
              resetUploadState={resetFileUploadState}
              isSmall
            />
          )}
          <Message
            id="Imported documents are for archiving only."
            comment="Helper text to make it clear to the user that imported documents are for archiving only"
          />
        </Modal.BodySection>
        <Modal.BodySection showWhenLoading>
          <div className={clsx(style.TemplateSearchContainer, {
            [style.ShowHeader]: importMethod === 'standard' && !uploadedPdfName && !templates?.length,
            [style.ShowHeaderWithResults]: importMethod === 'standard' && !uploadedPdfName && templates?.length,
            [style.Hide]: importMethod === 'ai' || uploadedPdfName,
          })}
          >
            <div className={style.Message}>
              <Message
                id="Or use a template with the tag {import}"
                comment="Helper text to make it clear to the user what types of templates can be used to import documents"
                values={{
                  import: <StaticSystemImportLabel />,
                }}
              />
            </div>
            <TemplateSearch
              isDisabled={preparing}
              isInImportContractModal
              isLoading={isLoading()}
              isSearching={isSearching()}
            />
          </div>
        </Modal.BodySection>
        <Modal.BodySection>
          <div className={clsx(style.TemplateContainer, {
            [style.Hide]: importMethod === 'ai' || uploadedPdfName,
          })}
          >
            <TemplateResults
              isDisabled={preparing}
              isInImportContractModal
              isSearching={isSearching()}
              selectedUniqueKey={selectedUniqueKey === -1 ? null : selectedUniqueKey}
              isImportModal
            />
          </div>
        </Modal.BodySection>
      </Modal.BodySections>
    );
  }, [
    folderId,
    selectedFolder,
    workspace.name,
    onAssetPagesDone,
    prepareDocument,
    shouldRenderLoader,
    uploadDocument,
    onFileUploadError,
    resetFileUploadState,
    preparing,
    isLoading,
    isSearching,
    selectedUniqueKey,
    importMethod,
    renderToggleSection,
    uploadedPdfName,
    templates,
  ]);

  // eslint-disable-next-line react/no-unused-prop-types
  const renderActions = useCallback(({ closeConfirmation }: { closeConfirmation: () => void }) => (
    <>
      <CancelButton onClick={closeConfirmation} modalKey={modalKey} />
      {isAiImportOptionEnabled && (
      <Button
        customClass={clsx(style.ImportButton, {
          [style.Widen]: importMethod === 'ai',
          [style.Shrink]: importMethod === 'standard',
        })}
        data-testid="confirm-button"
        kind="primary"
        icon={extractionState.loading ? <CircularSpinner height="18px" /> : null}
        onClick={() => {
          if (agreement.current?.id) {
            if (importMethod === 'ai') {
              extractAndRedirect(agreement.current.id);
              return;
            }
            redirectToDocument(agreement.current.id);
          }
        }}
        disabled={!uploadedPdfName || extractionState.loading}
        trackable={{
          name: 'Temp Import Document',
          props: {
            location: 'import contract modal',
            'import type': importMethod === 'ai' ? 'ai import' : 'manual',
          },
        }}
      >
        <span className={clsx(style.Text, {
          [style.Show]: importMethod === 'standard',
          [style.Hide]: importMethod === 'ai',
        })}
        >
          <Message id="Import" comment="Used as the button text in the import contract modal" />
        </span>
        <span className={clsx(style.Text, {
          [style.Show]: importMethod === 'ai',
          [style.Hide]: importMethod === 'standard',
        })}
        >
          <Message id="Import with AI" comment="Used as the button text in the import contract modal" />
        </span>
      </Button>
      )}
    </>
  ), [
    isAiImportOptionEnabled,
    importMethod,
    extractionState.loading,
    uploadedPdfName,
    extractAndRedirect]);

  return (
    <Confirmable
      data-testid="import"
      body={renderBody()}
      error={createState.error || extractionState.error}
      actions={renderActions}
      header={(
        <Message id="Import" comment="Used as the modal title in the template launcher" />
      )}
      modalKey={modalKey}
      onEnter={handleEnter}
      onClose={handleClose}
      onOpen={handleOpen}
      isLoading={isLoading()}
      customErrorMessage={getErrorMessage()}
      portalClassName={style.ImportContractPortal}
    >
      {children}
    </Confirmable>
  );
};
