import { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import useAgreement from 'hooks/use-agreement';
import agreementsReducer from 'reducers/entities/agreements';
import { isFeatureEnabledSelector } from 'reducers/session';

import { checkAcl } from 'components/acl';
import Divider from 'components/divider';
import Toggle from 'components/toggle';
import Message from 'components/message';
import TooltipInfo from 'components/tooltip-info';

import style from './editing-internal-approvers.module.scss';

type Props = {
  agreementId: number,
}

const EditingInternalApproversToggle = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const dispatch = useDispatch();

  const canViewInternalApproverEditToggle = checkAcl(agreement.acl,
    'agreement:config:allow_editing_internal_approvers:view');
  const canUpdateInternalApproverEditToggle = checkAcl(agreement.acl,
    'agreement:config:allow_editing_internal_approvers:update');

  const isDisabled = !canUpdateInternalApproverEditToggle;

  const [isAllowEditingInternalApproversEnabled,
    setIsAllowEditingInternalApproversEnabled] = useState(() => Boolean(
    agreement.config?.allowEditingInternalApprovers,
  ));

  if (!canViewInternalApproverEditToggle) {
    return null;
  }

  const toggleEditInternalApprovers = () => {
    setIsAllowEditingInternalApproversEnabled((isEnabled) => !isEnabled);

    const { allowEditingInternalApprovers } = agreement.config;

    dispatch(agreementsReducer.updateAgreementPreferences({
      id: agreementId,
      data: {
        id: agreementId,
        config: {
          allowEditingInternalApprovers: !allowEditingInternalApprovers,
        },
      },
    }));
  };

  return (
    <>
      <div className={clsx(style.EditInternalApproverContainer, { [style.Disabled]: isDisabled })} data-testid="editing-approvers-wrapper">
        <div className={style.ToggleContainer} data-testid="toggle-container">
          <div className={style.Text}>
            <Message
              id="Manage internal approvers"
              comment="Label for adding, editing or removing internal approver toogle."
            />
            <TooltipInfo
              customClassName={style.Tooltip}
              message={(
                <Message
                  id="Add, edit or remove internal approvers. Can only be changed in the template where the document has been created from."
                  comment="Explanatory text for where the internal approver toggle can be changed."
                />
              )}
              side="top"
              theme="oneflow"
              zIndex="1031"
            />
          </div>
          <Toggle
            version="latest"
            checked={isAllowEditingInternalApproversEnabled}
            onChange={toggleEditInternalApprovers}
            disabled={isDisabled}
          />
        </div>
      </div>
      <Divider solid className={style.Divider} />
    </>
  );
};

export default EditingInternalApproversToggle;
