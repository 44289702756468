import { connect } from 'react-redux';
import get from 'lodash/get';
import { replace } from 'connected-react-router';

import positionsReducer from 'reducers/entities/positions';
import accountsReducer from 'reducers/entities/accounts';
import accountSeatsReducer from 'reducers/entities/account-seats';
import {
  getAccountFromSessionSelector,
  isFeatureEnabledSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';

import { hasAvailableSeats } from 'account';

import Details from './user-details';

export const mapStateToProps = (state, ownProps) => {
  const isPositionLimitedFeatureEnabled = isFeatureEnabledSelector(state, { feature: 'temporaryPositionLimited' });
  const position = positionsReducer.getPositionSelector(state, { id: ownProps.match.params.id });
  const account = getAccountFromSessionSelector(state);
  const accountSeats = accountSeatsReducer.getAccountSeatSelector(state, { id: account.id });
  const myPositionId = getPositionFromSessionSelector(state).id;

  return ({
    isPositionLimitedFeatureEnabled,
    updateState: positionsReducer.getUpdateSelector(state, { id: position.id }),
    account,
    hasAvailableSeats: hasAvailableSeats(accountSeats),
    myPositionId,
    position,
  });
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  resetUpdateState: () => {
    dispatch(positionsReducer.updatePositionReset({
      id: ownProps.match.params.id,
    }));
  },
  updateUser: (data) => {
    dispatch(positionsReducer.updatePosition({
      id: ownProps.match.params.id,
      data: {
        title: null,
        phoneNumber: null,
        fullname: null,
        email: null,
        ...data,
        userRole: get(data, 'userRole.value'),
      },
    }));
  },
  fetchAccount: (id) => {
    dispatch(accountsReducer.fetchAccount({ id }));
  },
  updatePath: (pathname) => {
    dispatch(replace(pathname));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
