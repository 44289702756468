import type { MutableRefObject, CSSProperties } from 'react';
import { DragOverlay, useDndContext } from '@dnd-kit/core';

import { useDocumentDndContext } from 'components/document-layout-container/document-dnd-context';

import { DroppableDragOverlayField } from './droppable-drag-overlay-field';
import IdleDragOverlayField from './idle-drag-overlay-field';

import style from './data-field-drag-overlay.module.scss';

type DataFieldDragOverlayContentProps = {
  dataFieldValueObject: DataFieldValue;
  droppableDragOverlayRef: MutableRefObject<HTMLDivElement | null>,
  overSegmentId: string,
  scale: number,
  isOverNonPdfSections: boolean,
};

const DataFieldDragOverlayContent = ({
  dataFieldValueObject,
  droppableDragOverlayRef,
  overSegmentId,
  scale,
  isOverNonPdfSections,
}: DataFieldDragOverlayContentProps) => {
  if (overSegmentId === 'smart_fields') {
    return (
      <DroppableDragOverlayField
        droppableDragOverlayRef={droppableDragOverlayRef}
        dataFieldValueObject={dataFieldValueObject}
        scale={scale}
      />
    );
  }

  return (
    <IdleDragOverlayField
      dataFieldName={dataFieldValueObject.name}
      isOverNonPdfSections={isOverNonPdfSections}
    />
  );
};

const getDragOverlayStyle = (
  droppableDragOverlayRef: MutableRefObject<HTMLDivElement | null>,
  scale: number,
): CSSProperties => {
  if (!droppableDragOverlayRef?.current) {
    return {
      width: 'auto',
      height: 'auto',
    };
  }

  const { offsetWidth, offsetHeight } = droppableDragOverlayRef?.current;
  return {
    width: `${offsetWidth * scale}px`,
    height: `${offsetHeight * scale}px`,
  };
};

const DataFieldDragOverlay = () => {
  const { active, over } = useDndContext();
  const { activeDndId } = useDocumentDndContext();
  const dataFieldValueObject = active?.data?.current?.data;
  const activeSegmentId = active?.data?.current?.segmentId;
  const overSegmentId = over?.data?.current?.segmentId;
  const droppableDragOverlayRef = active?.data?.current?.droppableDragOverlayRef;
  const scale = over?.data.current?.scale || 1;
  const isDragOverlayActive = !!activeDndId && activeSegmentId === 'smart_fields';
  // Using sortable_box_list allows us to detect if overlay is over nonpdf sections
  const isOverNonPdfSections = !!activeDndId && overSegmentId === 'sortable_box_list';

  return (
    <DragOverlay
      className={style.DataFieldDragOverlay}
      style={getDragOverlayStyle(droppableDragOverlayRef, scale)}
      dropAnimation={null}
    >
      {isDragOverlayActive
        ? (
          <div>
            <DataFieldDragOverlayContent
              dataFieldValueObject={dataFieldValueObject}
              droppableDragOverlayRef={droppableDragOverlayRef}
              overSegmentId={overSegmentId}
              isOverNonPdfSections={isOverNonPdfSections}
              scale={scale}
            />
          </div>
        )
        : null}
    </DragOverlay>
  );
};

export default DataFieldDragOverlay;
