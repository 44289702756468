import { useSelector } from 'react-redux';

import { getAvailableContentInlineSize } from 'reducers/app';

import { getRequiredExpandedViewMinWidth } from 'components/contract-boxes/generic-box-helpers';

const useIsExpandedView = (minWidth: number, minWidthToleranceDeltaForZoom = 0) => {
  const availableContentInlineSize = useSelector(getAvailableContentInlineSize);
  const requiredExpandedViewMinWidth = getRequiredExpandedViewMinWidth(
    availableContentInlineSize,
    minWidth,
    {
      minWidthToleranceDeltaForZoom,
    },
  );

  const isExpandedView = availableContentInlineSize >= requiredExpandedViewMinWidth;

  return isExpandedView;
};

export default useIsExpandedView;
