// @flow

import { has, isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import agreementsReducer from 'reducers/entities/agreements';
import {
  getAccountFromSessionSelector,
  getAllWorkspacesFromSessionSelector,
} from 'reducers/session';

import GetStarted, { type Props } from './get-started';

type StateProps = {|
  canAccessCurrentWorkspace: boolean,
  account: Account,
|};

type OwnProps = $Diff<Props, StateProps>;

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state: State) => {
  const account = getAccountFromSessionSelector(state);

  const currentWorkspace = getCurrentWorkspaceSelector(state);
  const canAccessCurrentWorkspace = has(currentWorkspace, 'id') && currentWorkspace.id !== 0;

  const workspaces = getAllWorkspacesFromSessionSelector(state);
  const hasNoCreatedWorkspaces = workspaces.length === 1 && workspaces[0].id === 0;

  const agreementsQuery = agreementsReducer.getQuerySelector(state, { name: 'search/contracts/all' });
  const agreements = agreementsReducer.getAgreementsSelector(state, {
    ids: agreementsQuery.result,
  });

  const shouldShowWorkspaceModal = (
    hasNoCreatedWorkspaces
    && isEmpty(agreements)
    && !agreementsQuery.loading
    && account.onboardingStage === 'new'
  );

  return {
    canAccessCurrentWorkspace,
    account,
    shouldShowWorkspaceModal,
  };
};

type MapDispatchToProps = () => $Exact<{}>;

export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, OwnProps, StateProps, any, State, any>(
  mapStateToProps,
  mapDispatchToProps,
)(GetStarted);
