import { DeleteMenuItem } from 'components/menu-items/delete';
import { checkAcl } from 'components/acl';
import DeleteTemplateModal from 'components/modals/delete-template';
import { MouseEventHandler } from 'react';

export type Props = {
  agreement: Oneflow.Agreement;
};

const DeleteTemplate = ({
  agreement,
}: Props) => (
  <DeleteTemplateModal agreement={agreement}>
    {(onClick: MouseEventHandler) => (
      <DeleteMenuItem
        onClick={onClick}
        disabled={!checkAcl(agreement.acl, 'agreement:remove')}
      />
    )}
  </DeleteTemplateModal>
);

export default DeleteTemplate;
