// @flow
import get from 'lodash/get';
import { connect } from 'react-redux';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getAccountFromSessionSelector } from 'reducers/session';
import { isMarkedAsSigned, isDraft } from 'agreement';
import { getParticipantParty, getAgreementMyParty } from 'agreement/selectors';
import { getAgreementDateFormat, getDateFormat } from 'date';

import ParticipantStatistics from './participant-statistics';

type OwnProps = {
  agreement: Agreement,
  participant: AgreementParticipant,
  isContractView?: boolean,
};

export const mapStateToProps = (state: State, {
  agreement,
  participant,
  isContractView,
}: OwnProps) => {
  const party = getParticipantParty(agreement, participant.agreementCompany.id);
  const myParty = getAgreementMyParty(agreement);
  const contractPublishTime = agreement.publishTime;

  const workspace = getCurrentWorkspaceSelector(state);
  const account = getAccountFromSessionSelector(state);
  const brandingDateFormat = workspace.brandingDateFormat || account.brandingDateFormat;

  const dateFormat = getDateFormat(brandingDateFormat, true);

  const agreementDateFormat = getAgreementDateFormat(agreement.config.dateFormat);

  return {
    contractPublishTime,
    isOwnerParty: get(party, 'id') === get(myParty, 'id'),
    partyName: party.name,
    isContractDraft: isDraft(agreement),
    isContractMarkedAsSigned: isMarkedAsSigned(agreement),
    dateFormat: isContractView ? `${agreementDateFormat}, HH:mm` : dateFormat,
  };
};

export default connect<typeof ParticipantStatistics, State, any, any, any, any>(
  mapStateToProps,
)(ParticipantStatistics);
