import { useDispatch, useSelector } from 'react-redux';

import { isContractPristine } from 'reducers/current-contract';
import agreements from 'reducers/entities/agreements';
import { isTemplatePublished } from 'agreement';

import { isContractEditable } from 'components/document-layout-container/helpers';
import toast from 'components/toasts';
import Message from 'components/message';
import { DiscardSaveTemplatesActions } from './buttons-call-to-action';
import { PublishButton, UnpublishButton } from './buttons-call-to-action/buttons';

const toastHandler = (isPublished: boolean) => {
  if (isPublished) {
    toast.info({
      id: 'unpublish-template',
      title: <Message
        id="Template unpublished"
        comment="Title to text explaining that unpublishing the template was successful."
      />,
      description: <Message
        id="Template is now only available for those with permission to manage templates."
        comment="Info text explaining that unpublishing the template was successful."
      />,
      duration: 5000,
    });
  } else {
    toast.success({
      id: 'publish-template',
      title: <Message
        id="Template published"
        comment="Title to text explaining that publishing the template was successful."
      />,
      description: <Message
        id="Template is available for everyone in this workspace."
        comment="Info text explaining that publishing the template was successful."
      />,
      duration: 5000,
    });
  }
};

type Props = {
  agreement: Oneflow.Agreement
}

const TemplateActions = ({ agreement }: Props) => {
  const dispatch = useDispatch();

  const isPristine = useSelector(isContractPristine);
  const isEditable = isContractEditable(agreement);

  const isPublished = isTemplatePublished(agreement);
  const handleOnClick = () => {
    dispatch(
      agreements.updateConfig({
        id: agreement.id,
        data: {
          templateActive: !isPublished,
        },
        pipe: {
          onSuccess: () => toastHandler(isPublished),
        },
      }),
    );
  };

  if (!isPristine && isEditable) {
    return (
      <DiscardSaveTemplatesActions agreement={agreement} />
    );
  }

  if (isPublished) {
    return <UnpublishButton disabled={!isPristine} onClick={handleOnClick} />;
  }

  return <PublishButton disabled={!isPristine} onClick={handleOnClick} />;
};

export default TemplateActions;
