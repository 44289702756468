// @flow

import * as React from 'react';

import style from './video.module.scss';

type Props = {
  src: MediaStream,
}

class Video extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.videoRef = React.createRef();
  }

  componentDidMount() {
    const { src } = this.props;

    if (!this.videoRef.current) {
      return;
    }

    if (window.URL) {
      this.videoRef.current.srcObject = src;
    } else {
      this.videoRef.current.src = String(src);
    }
  }

  videoRef: {
    current: HTMLVideoElement | null,
  }

  render() {
    return (
      <video muted className={style.Video} autoPlay ref={this.videoRef} />
    );
  }
}

export default Video;
