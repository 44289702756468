import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import type { Layout } from 'components/document-layout-container/types';
import { COLLAPSED } from 'components/document-layout-container/helpers';
import ReminderIcon from 'components/icons/reminder';

import style from './sing-later-enabled.module.scss';

type Props = {
  layout: Layout;
}

const SignLaterEnabled = ({ layout }: Props) => (
  <div className={clsx(style.Container, { [style.Collapsed]: layout === COLLAPSED })}>
    <ReminderIcon height="16px" />
    <Message
      id="Sign later enabled"
      comment="Text for sign later enabled badge."
    />
  </div>
);

export default SignLaterEnabled;
