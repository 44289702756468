import clsx from 'clsx';
import style from './section-separator.module.scss';

type Props ={
  customClassName?: string,
};

const SectionSeparator = ({ customClassName }: Props) => (
  <div className={clsx(style.SectionSeparator, customClassName)} />
);

export default SectionSeparator;
