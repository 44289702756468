/* eslint-disable no-bitwise */
import * as countries from 'i18n-iso-countries';
import { MessageTranslator } from '@oneflowab/pomes';
import { getAmericanStatesAsOptions, getCanadianProvinceAsOptions } from 'components/countries';
import {
  MISSING,
  CONTRACT_NOT_ANALYSED,
  CONTRACT_ANALYSED_WITH_ISSUES,
  CONTRACT_ANALYSED_NO_ISSUES,
} from './constants';

export const darkenColor = (color: string, percent: number): string => {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);

  let R = (num >> 16) + amt;
  let G = ((num >> 8) & 0x00ff) + amt;
  let B = (num & 0x0000ff) + amt;

  if (R < 1) {
    R = 0;
  } else if (R > 255) {
    R = 255;
  }

  if (G < 1) {
    G = 0;
  } else if (G > 255) {
    G = 255;
  }

  if (B < 1) {
    B = 0;
  } else if (B > 255) {
    B = 255;
  }

  return (
    `#${
      (
        0x1000000
      + R * 0x10000
      + G * 0x100
      + B
      )
        .toString(16)
        .slice(1)}`
  );
};

export const getGoverningLawTranslation = (
  governingLaw: string, lang: string, message?: MessageTranslator,
) => {
  if (message) {
    if (governingLaw === 'EU') {
      return (
        message({ id: 'European Union', comment: 'Label in the governing law chart' })
      );
    }
    if (governingLaw === 'NORD') {
      return (
        message({ id: 'Nordics', comment: 'Label in the governing law chart describing the Nordic countries (Sweden, Finland, Norway and Denmark)' })
      );
    }
    if (governingLaw === 'GB-ENG') {
      return (
        message({ id: 'England', comment: 'Label in the governing law chart' })
      );
    }
    if (governingLaw === 'GB-SCT') {
      return (
        message({ id: 'Scotland', comment: 'Label in the governing law chart' })
      );
    }
    if (governingLaw === 'GB-WLS') {
      return (
        message({ id: 'Wales', comment: 'Label in the governing law chart' })
      );
    }
    if (governingLaw === 'GB-NIR') {
      return (
        message({ id: 'Northern Ireland', comment: 'Label in the governing law chart' })
      );
    }
    if (governingLaw.startsWith('US-')) {
      return getAmericanStatesAsOptions().find((state) => state.value === governingLaw)?.label;
    }
    if (governingLaw.startsWith('CA-')) {
      return getCanadianProvinceAsOptions().find((option) => option.value === governingLaw)?.label;
    }
  }
  return (
    countries.getName(governingLaw, lang || 'en') || governingLaw
  );
};

type Args = {
  governingLaws: string, // "['SE', 'US-CA', 'NO']"
  message?: MessageTranslator,
  lang?: string,
};
export const getTranslatedCountries = ({ governingLaws, lang = 'en', message }: Args) => {
  try {
    const translatedCountries = JSON.parse(governingLaws).map((country: string) => (
      getGoverningLawTranslation(country, lang, message)
    ));
    return translatedCountries.join(', ');
  } catch (error) {
    return null;
  }
};

export const isStaticDataPoint = (key: string) => (
  key === CONTRACT_NOT_ANALYSED
  || key === CONTRACT_ANALYSED_WITH_ISSUES
  || key === CONTRACT_ANALYSED_NO_ISSUES
);

export const isWarning = (breachSeverity: string) => (
  breachSeverity === 'WARNING'
);

export const getColorPerIndex = (colors: string[], index: number, isHovered: boolean) => {
  const color = colors[index] || colors[0];
  if (isHovered) {
    return darkenColor(color, -5);
  }
  return color;
};
