//  @flow

import moment from 'moment';

import { getCounterpartyNames } from 'agreement/selectors';

type MapDelayedEvent = DelayedEvent => LifecycleCalendarEvent;

export const mapDelayedEvent: MapDelayedEvent = (delayedEvent) => {
  if (delayedEvent?.agreement && Object.values(delayedEvent?.agreement).length > 1) {
    return {
      id: delayedEvent.id,
      allDay: true,
      start: moment(delayedEvent.date).toDate(),
      end: moment(delayedEvent.date).toDate(),
      sourceEvent: delayedEvent,
      contractName: delayedEvent.agreement.name,
      counterpartyNames: getCounterpartyNames(delayedEvent.agreement).join(', '),
      ownerPartyName: delayedEvent.agreement.parties.find((party) => party.self).name,
    };
  }

  // user lost access to the contract
  // Either don't have persmission in the workspace
  // Or don't have any associative role in the workspace itself (directly and via group)
  return {
    id: delayedEvent.id,
    allDay: true,
    start: moment(delayedEvent.date).toDate(),
    end: moment(delayedEvent.date).toDate(),
    sourceEvent: delayedEvent,
    isContractNotAvailable: true,
  };
};

type MapDelayedEvents = Array<DelayedEvent> => Array<LifecycleCalendarEvent>;
const mapDelayedEvents: MapDelayedEvents = (delayedEvents) => (
  delayedEvents.map<LifecycleCalendarEvent>(mapDelayedEvent)
);

export default mapDelayedEvents;
