import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { isContractPristine } from 'reducers/current-contract';
import { getAgreementMyParticipant } from 'agreement/selectors';
import useAgreement from 'hooks/use-agreement';
import {
  isSignLaterEnabled,
  isSigned,
  isCancelContractVisible,
  isConcluded,
} from 'agreement';
import isExternalApprover from 'agreement/participant/is-external-approver';
import isInfluencer from 'agreement/participant/is-influencer';
import { DownloadButton, SignButton } from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons';

import { COLLAPSED, EXPANDED, isContractEditable } from 'components/document-layout-container/helpers';
import { checkAcl } from 'components/acl';
import type { Layout } from 'components/document-layout-container/types';

import { isViewer } from 'agreement/participant';
import hasSigned from 'agreement/participant/has-signed';
import { hasPendingStateApprovers } from 'agreement/pending-state-flow';
import { DiscardSaveActions, DiscardSaveSignActions } from './buttons-call-to-action';

import { YouHaveSignedState } from './states';
import { ApprovePendingActions } from './approve-pending-actions';

type Props = {
  agreementId: Oneflow.Agreement['id'],
  layout: Layout
}

const GuestActions = ({ agreementId, layout }: Props) => {
  const agreement = useAgreement(agreementId);
  const participant = getAgreementMyParticipant(agreement);
  const canDownloadPdf = checkAcl(agreement.acl, 'agreement:download:pdf');
  const isSignedLaterEnabled = isSignLaterEnabled(agreement);

  const isPristine = useSelector(isContractPristine);
  const isEditable = isContractEditable(agreement);

  if (isEmpty(participant)) {
    return null;
  }

  if (isSigned(agreement)) {
    if (layout === EXPANDED) {
      if (!canDownloadPdf) {
        return null;
      }

      if (isCancelContractVisible(agreement)) {
        return (
          <>
            <DownloadButton agreement={agreement} />
          </>
        );
      }

      return (
        <DownloadButton agreement={agreement} />
      );
    }

    if (layout === COLLAPSED) {
      return null;
    }
  }

  if (isViewer(participant)) {
    return null;
  }

  if (!isPristine && isEditable) {
    if (isInfluencer(participant) || isSignedLaterEnabled) {
      return (
        <DiscardSaveActions agreementId={agreementId} />
      );
    }

    return (
      <DiscardSaveSignActions agreement={agreement} layout={layout} />
    );
  }

  if (hasPendingStateApprovers(agreement) && isExternalApprover(participant)
    && !isConcluded(agreement)) {
    return (
      <ApprovePendingActions
        layout={layout}
        agreement={agreement}
        participant={participant}
      />
    );
  }

  if (isInfluencer(participant) || isSignedLaterEnabled) {
    return null;
  }

  if (hasSigned(participant) && !isSigned(agreement)) {
    return (
      <YouHaveSignedState layout={layout} />
    );
  }

  return (
    <SignButton agreement={agreement} layout={layout} />
  );
};

export default GuestActions;
