import getFontAssetsDomain from './get-font-asset-domain.js';

const loadIBMPlexSerif = () => {
  const normalIBMPlexSerif = new FontFace(
    'IBM Plex Serif',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-serif/ibm-plex-serif_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicIBMPlexSerif = new FontFace(
    'IBM Plex Serif',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-serif/ibm-plex-serif_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldIBMPlexSerif = new FontFace(
    'IBM Plex Serif',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-serif/ibm-plex-serif_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicIBMPlexSerif = new FontFace(
    'IBM Plex Serif',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-serif/ibm-plex-serif_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalIBMPlexSerif, italicIBMPlexSerif, boldIBMPlexSerif, boldItalicIBMPlexSerif];
};

export default loadIBMPlexSerif;
