// @flow

import * as React from 'react';
import clsx from 'clsx';

import { getStyle } from '../../utils';
import paragraphStyle from './paragraph.module.scss';

const Paragraph = ({ attributes, children, element }: RenderSlateElementProps) => {
  const style = getStyle(element);
  const className = clsx(
    'editor-paragraph',
    paragraphStyle.Paragraph,
    element.className,
  );

  return (
    <div
      {...attributes}
      className={className}
      style={style}
    >
      {children}
    </div>
  );
};

export default Paragraph;
