/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { unescape } from 'lodash';
import { Message } from '@oneflowab/pomes';

import { isChecked } from 'utils/checkbox';
import { getGuestToken } from 'agreement/selectors';

import Checkbox from 'components/checkbox';
import Description from 'components/contract-boxes/form-box/components/description';
import Edit from 'components/icons/edit';
import LinkIcon from 'components/icons/link';
import PropagationBoundary from 'components/propagation-boundary';
import { useBoxItemIsVisible } from 'components/box-list/box-item/box-item-is-visible-context';

import { useFormFieldContext } from '../form-field-context';
import { useFieldValidationContext } from '../field-validation-provider';

import style from './checkbox.module.scss';

const FormCheckboxField = ({
  description,
  inputSize,
  isActive,
  isAllowedToUpdateDataValue,
  isAllowedToUpdateSharedDataValue,
  label,
  required,
}) => {
  const {
    fieldValue,
    onFieldValueChange,
    hasDataField,
  } = useFormFieldContext();
  const { touched } = useFieldValidationContext();
  const guestToken = useSelector(getGuestToken);
  const isGuestView = Boolean(guestToken);
  const { isVisible } = useBoxItemIsVisible();

  const isFieldValueChecked = isChecked(fieldValue);

  const onChange = useCallback((event) => {
    const newFieldValue = isChecked(event.target?.checked) ? '1' : '0';
    onFieldValueChange(newFieldValue);
  }, [onFieldValueChange]);

  const getLabel = () => (
    <>
      <p className={style.Text}>{unescape(label)}</p>
      {required ? <span className={style.Required}> *</span> : null}
      {hasDataField && isAllowedToUpdateSharedDataValue && !isGuestView ? (
        <LinkIcon className={style.DataFieldIcon} height="14px" />
      ) : null}
    </>
  );

  const fieldContainerClasses = clsx(style.FieldContainer, {
    [style.Active]: isActive,
    [style.Uneditable]: !isAllowedToUpdateDataValue || isGuestView,
  });
  const inputWrapperClasses = clsx(style.Wrapper, inputSize);

  const invalid = (
    required
    && isAllowedToUpdateSharedDataValue
    && !isFieldValueChecked
    && touched
    && isVisible
  );

  return (
    <div className={fieldContainerClasses}>
      {isAllowedToUpdateDataValue ? (
        <div className={style.Edit}>
          <Edit height="14px" />
        </div>
      ) : null}
      <div className={inputWrapperClasses}>
        <PropagationBoundary>
          <Checkbox
            customLabelStyle={style.Label}
            disabled={!isAllowedToUpdateSharedDataValue}
            input={{
              checked: isFieldValueChecked,
              onChange,
            }}
            label={getLabel()}
            preserveStyleOnDisable
            required={required}
            invalid={invalid}
          />
        </PropagationBoundary>
      </div>
      {invalid && (
        <div className={style.ValidationMessage}>
          <Message id="This field is required" comment="Input field validation message." />
        </div>
      )}
      <Description description={description} />
    </div>
  );
};

export default FormCheckboxField;
