import { ReactNode } from 'react';

import {
  MultistepModal,
  ModalStep,
  ModalStepRenderProps,
} from 'components/modal';
import { DraftApprovedModal } from 'components/modals/draft-approved/draft-approved';
import { ApproveDraftModal } from 'components/modals/approve-draft';
import { hasOtherPendingDraftApprovals } from 'agreement/participant/is-current-internal-approver';

export type Props = {
  agreement: Oneflow.Agreement;
  internalApproverId: number;
  children: ReactNode;
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
  setShowApproveDraftModal: (value: boolean) => void;
};

const ApproveDraft = ({
  agreement,
  internalApproverId,
  children,
  isOpen,
  setIsOpen,
  setShowApproveDraftModal,
}: Props) => {
  const agreementId = agreement.id;
  const agreementChecksum = agreement.checksum;

  const hasPendingDraftApprovals = hasOtherPendingDraftApprovals(agreement, internalApproverId);
  const hasAutoSendEnabled = agreement.config?.autoPublishAfterDraftApproval;

  const handleClose = () => {
    setIsOpen(false);
    setShowApproveDraftModal(false);
  };

  const handleFinalStep = (onStepComplete: () => void) => () => {
    onStepComplete();
    handleClose();
  };

  const renderApproveDraftModal: ModalStepRenderProps = ({ onStepComplete }) => (
    <ApproveDraftModal
      sendToNextDraftApprover={hasPendingDraftApprovals}
      onStepComplete={onStepComplete}
      agreementId={agreementId}
      internalApproverId={internalApproverId}
      onClose={handleClose}
      setShowApproveDraftModal={setShowApproveDraftModal}
      agreementChecksum={agreementChecksum}
      autoSendEnabled={hasAutoSendEnabled}
    />
  );

  const renderConfirmModal: ModalStepRenderProps = ({ onStepComplete }) => (
    <DraftApprovedModal
      onClose={handleFinalStep(onStepComplete)}
      isOpen
      sendToNextDraftApprover={hasPendingDraftApprovals}
      autoSendEnabled={hasAutoSendEnabled}
    />
  );

  return (
    <>
      <MultistepModal
        isOpen={isOpen}
        onCancel={handleClose}
      >
        <ModalStep>
          {renderApproveDraftModal}
        </ModalStep>
        <ModalStep>
          {renderConfirmModal}
        </ModalStep>
      </MultistepModal>
      {children}
    </>
  );
};

export default ApproveDraft;
