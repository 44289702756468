// @flow
import React from 'react';
import { localize, Message } from '@oneflowab/pomes';

import { getFileSize } from 'utils/file';
import LabelValueSeparator from 'components/label-value-separator';

import style from './file-upload.module.scss';

type Props = {
  maxFileSize?: number,
  acceptedFileTypes?: string,
  showAllowedFileTypes?: boolean,
};

export const FileUploadRequirementsComponent = ({
  maxFileSize,
  acceptedFileTypes,
  showAllowedFileTypes = true,
}: Props) => {
  const displayAllowedFileTypes = acceptedFileTypes && showAllowedFileTypes;

  return (
    <>
      {maxFileSize && (
      <p>
        <Message
          id="Maximum file size"
          comment="Information about the maximum supported file size in the file upload component."
        />
        <LabelValueSeparator />
        <span>
          {getFileSize(maxFileSize)}
          .
        </span>
      </p>
      )}
      {displayAllowedFileTypes && (
      <p>
        <Message
          id="Allowed file types"
          comment="Information about supported file formats accepted by the file upload component."
        />
        <LabelValueSeparator />
        <span className={style.Value}>{acceptedFileTypes}</span>
      </p>
      )}

    </>
  );
};
export default localize<Props>(FileUploadRequirementsComponent);
