import { createContext, useContext } from 'react';

type BoxItemVisibilityContextValue = {
  isVisible: boolean;
};

export const BoxItemIsVisibleContext = createContext<BoxItemVisibilityContextValue>({
  isVisible: true,
});

export const useBoxItemIsVisible = (): BoxItemVisibilityContextValue => {
  const context = useContext(BoxItemIsVisibleContext);

  if (!context) {
    throw new Error('useBoxItemIsVisible must be used within a BoxItemVisibilityProvider');
  }

  return context;
};
