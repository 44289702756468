// @flow

import * as React from 'react';
import Dropzone from 'react-dropzone';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

import { useVideoBoxProps } from 'contexts/video-box-props';
import { FILE_VIDEO_DROPZONE_ACCEPT } from 'utils/file';
import {
  MAX_VIDEO_SIZE,
} from '../../constants';

import style from './video-upload.module.scss';

type Props = {
  customClassName?: string,
  children: React.Node,
  disabled?: boolean,
}

const VideoUpload = ({
  customClassName,
  children,
  disabled,
}: Props) => {
  const {
    myParticipant,
    onVideoDrop,
    onDropRejected,
    onErrorHandler,
    addVideoState,
  } = useVideoBoxProps();

  const getDropzoneClasses = React.useCallback(
    (isDragActive) => clsx(style.Dropzone, customClassName, {
      [style.DashedBorder]: !disabled,
      [style.HiddenDropzone]: isEmpty(myParticipant),
      [style.DropzoneErrors]: addVideoState.uploadingError,
      [style.isDragActive]: isDragActive,
    }), [addVideoState.uploadingError, customClassName, disabled, myParticipant],
  );

  return (
    <Dropzone
      multiple={false}
      maxSize={MAX_VIDEO_SIZE}
      onDropAccepted={onVideoDrop}
      accept={FILE_VIDEO_DROPZONE_ACCEPT}
      onDropRejected={onDropRejected}
      onError={onErrorHandler}
      disabled={disabled}
    >
      {(
        {
          getRootProps, getInputProps, isDragActive,
        },
      ) => (
        <section>
          <div {...getRootProps({ className: getDropzoneClasses(isDragActive) })}>
            <input {...getInputProps()} />
            {children}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default VideoUpload;
