import {
  useCallback,
  useState,
  useEffect,
} from 'react';
import { isEmpty } from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import * as Tabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import Media from 'react-media';

import {
  getAgreementSidebarCommentStateSelector,
  getAgreementSidebarMessageTypeSelector,
  getSeenMessageThreadsSelector,
  setAgreementSidebarMessageType,
} from 'reducers/app';
import useCurrentMessages from 'hooks/use-current-messages';
import { isNewUpdate } from 'agreement/selectors';
import {
  isCommentResolved,
  isCommentsAvailable,
  isParentResolved,
  isSuggestionDeleted,
  isSuggestionResolved,
} from 'comments';
import { COMMENT_STATES, MESSAGE_TYPES } from 'comments/constants';
import useAgreement from 'hooks/use-agreement';

import Message from 'components/message';
import MessagesList from 'components/messages-layout/messages-list';
import Button from 'components/button';
import PostMessage from 'components/document-tabs/messages-tab/post-message';
import EmptyState from 'components/document-tabs/messages-tab/empty-state';
import DisabledState from 'components/document-tabs/messages-tab/disabled-state';
import CommentsStateSelector from 'components/document-tabs/messages-tab/comments-state-selector';
import CountBadge from 'components/document-tabs/count-badge';
import CloseSidebarButton from 'components/expanded-layout-sidebar/close-sidebar-button';

import style from './message-types.module.scss';

const { CHAT, COMMENTS } = MESSAGE_TYPES;
const { COMMENT_ACTIVE, COMMENT_RESOLVED } = COMMENT_STATES;

type Props = {
  agreementId: number,
  onClose: () => void,
};

const hasSeenUpdates = (seenThreads, messages) => (
  messages.map((m) => m.id).some((id) => seenThreads.includes(id))
);

const MessageTypes = ({
  agreementId,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const {
    chatMessages,
    visibleChatMessages,
    visibleAnnotations,
    visibleActiveAnnotations,
    visibleResolvedAnnotations,
    annotations,
    resolvedAnnotations,
    activeAnnotations,
  } = useCurrentMessages();
  const activeCommentState = useSelector(getAgreementSidebarCommentStateSelector);
  const activeMessageType = useSelector(getAgreementSidebarMessageTypeSelector);
  const agreement = useAgreement(agreementId);
  const inlineCommentsAvailable = isCommentsAvailable(agreement);
  const isCommentsTabOpen = activeMessageType === COMMENTS;
  const seenThreads = useSelector(getSeenMessageThreadsSelector);
  const [showHistoryButton, setShowHistoryButton] = useState(false);
  const [showAllMessages, setShowAllMessages] = useState(false);
  const [fadeOutChatBadge, setFadeOutChatBadge] = useState(false);
  const [fadeOutCommentBadge, setFadeOutCommentBadge] = useState(false);

  const isResolved = useCallback((message) => isCommentResolved(message)
    || isSuggestionResolved(message)
    || isParentResolved(message, resolvedAnnotations), [resolvedAnnotations]);

  useEffect(() => {
    let timerChat;
    let timerComment;
    if (activeMessageType === CHAT) {
      timerChat = setTimeout(() => {
        setFadeOutChatBadge(true);
      }, [2000]);
    }
    if (activeMessageType === COMMENTS) {
      timerComment = setTimeout(() => {
        setFadeOutCommentBadge(true);
      }, [2000]);
    }

    return () => {
      if (timerChat) clearTimeout(timerChat);
      if (timerComment) clearTimeout(timerComment);
    };
  }, [activeMessageType]);

  const renderTabContent = useCallback(() => {
    const isCommentsTabOpenAndEmpty = () => {
      if (!isCommentsTabOpen) {
        return false;
      }
      if (activeCommentState === COMMENT_RESOLVED) {
        return !resolvedAnnotations?.filter(
          (annotation: any) => !isSuggestionDeleted(annotation),
        )?.length;
      }
      if (activeCommentState === COMMENT_ACTIVE) {
        return !activeAnnotations?.length;
      }
      return !annotations?.length;
    };
    const isChatTabOpenAndEmpty = () => {
      if (isCommentsTabOpen) {
        return false;
      }
      return !chatMessages.length;
    };

    if (isCommentsTabOpenAndEmpty() || isChatTabOpenAndEmpty()) {
      return (
        <EmptyState
          agreement={agreement}
          currentlyActiveTab={activeMessageType}
          currentlyActiveCommentsState={activeCommentState}
        />
      );
    }

    const handleMessagesVisibility = () => {
      setShowHistoryButton(!showHistoryButton);
      setShowAllMessages(!showAllMessages);
    };

    const renderHistoryButton = (isDesktop) => {
      const hasHiddenMessages = () => {
        if (isCommentsTabOpen) {
          if (activeCommentState === COMMENT_RESOLVED) {
            return resolvedAnnotations?.length > visibleResolvedAnnotations?.length;
          }
          if (activeCommentState === COMMENT_ACTIVE) {
            return activeAnnotations?.length > visibleActiveAnnotations?.length;
          }
          return annotations?.length > visibleAnnotations?.length;
        }
        return chatMessages.length > visibleChatMessages.length;
      };

      const showButton = (
        !isDesktop
        && hasHiddenMessages()
        && !showHistoryButton
      );

      if (showButton) {
        return (
          <div className={style.HistoryButtonContainer}>
            <Button
              onClick={handleMessagesVisibility}
              customClass={style.HistoryButton}
            >
              <Message
                id="Load more"
                comment="The button text to show all messages"
              />
            </Button>
          </div>
        );
      }

      return null;
    };

    const getVisibleMessages = (isDesktop: boolean) => {
      const resolvedAnnotationsNotDeleted = resolvedAnnotations?.filter(
        (suggestion: any) => !isSuggestionDeleted(suggestion),
      );
      const visibleResolvedAnnotationsNotDeleted = visibleResolvedAnnotations?.filter(
        (suggestion: any) => !isSuggestionDeleted(suggestion),
      );

      const showAllMessagesOfType = isDesktop || showAllMessages;
      if (isCommentsTabOpen) {
        if (activeCommentState === COMMENT_ACTIVE) {
          return showAllMessagesOfType ? activeAnnotations : visibleActiveAnnotations;
        }
        if (activeCommentState === COMMENT_RESOLVED) {
          return showAllMessagesOfType
            ? resolvedAnnotationsNotDeleted
            : visibleResolvedAnnotationsNotDeleted;
        }
        return showAllMessagesOfType ? annotations : visibleAnnotations;
      }

      if (showAllMessagesOfType) {
        return chatMessages;
      }

      return visibleChatMessages;
    };

    // eslint-disable-next-line react/display-name
    const renderMessages = (isDesktop: boolean) => () => (
      <>
        {renderHistoryButton(isDesktop)}
        <MessagesList
          messages={getVisibleMessages(isDesktop)}
          agreementId={agreement.id}
          shouldScrollOnNewComment={activeMessageType === CHAT}
          commentsTypeToRender={activeCommentState}
        />
      </>
    );

    const renderMessagesInDesktopView = () => (
      <Media
        key="desktop-view"
        query={{
          minWidth: style.contractviewdesktopmin,
        }}
        render={renderMessages(true)}
      />
    );

    const renderMessagesInMobileView = () => (
      <Media
        key="mobile-view"
        query={{
          maxWidth: style.contractviewmobilemax,
        }}
        render={renderMessages(false)}
      />
    );

    return (
      <>
        {renderMessagesInDesktopView()}
        {renderMessagesInMobileView()}
      </>
    );
  }, [
    agreement,
    chatMessages,
    annotations,
    isCommentsTabOpen,
    resolvedAnnotations,
    showHistoryButton,
    activeAnnotations,
    visibleChatMessages,
    visibleAnnotations,
    visibleActiveAnnotations,
    visibleResolvedAnnotations,
    showAllMessages,
    activeMessageType,
    activeCommentState,
  ]);

  const getNewMessagesCount = useCallback((messages) => (
    messages.reduce((acc, parentMessage) => {
      const newReplies = parentMessage.replies
        ?.filter((reply) => isNewUpdate(agreement, reply) && !isResolved(reply)).length || 0;
      return acc + (isNewUpdate(agreement, parentMessage)
        && !isResolved(parentMessage) ? 1 : 0) + newReplies;
    }, 0)
  ), [agreement, isResolved]);

  const onTabValueChange = useCallback((tab) => {
    dispatch(setAgreementSidebarMessageType(tab));
  }, [dispatch]);

  const renderCommentsStateSelector = useCallback(() => {
    if (!isCommentsTabOpen) {
      return null;
    }

    return (
      <CommentsStateSelector
        activeCommentState={activeCommentState}
      />
    );
  }, [activeCommentState, isCommentsTabOpen]);

  const renderContent = () => {
    if (!inlineCommentsAvailable && isEmpty(annotations)) {
      return renderTabContent();
    }

    return (
      <>
        <Tabs.Content className={style.TabContent} value={CHAT}>
          {renderTabContent()}
        </Tabs.Content>
        <Tabs.Content className={style.TabContent} value={COMMENTS}>
          {renderTabContent()}
        </Tabs.Content>
      </>
    );
  };

  const renderTabsList = () => {
    if (!inlineCommentsAvailable && isEmpty(annotations)) {
      return null;
    }

    return (
      <Tabs.List className={style.TabsList}>
        <div className={style.TriggerWrapper}>
          <div className={style.TriggerItem}>
            <Tabs.Trigger className={clsx(style.Trigger, 'chat-trigger')} value={CHAT}>
              <Message id="Chat" comment="Label of chat tab" />
            </Tabs.Trigger>
            {!hasSeenUpdates(seenThreads, chatMessages) && (
              <CountBadge
                className={style.CountBadge}
                fadeOut={fadeOutChatBadge}
                count={getNewMessagesCount(chatMessages)}
              />
            )}
          </div>
          <div className={style.TriggerItem}>
            <Tabs.Trigger className={clsx(style.Trigger, 'comments-trigger')} value={COMMENTS}>
              <Message id="Comments" comment="Label of comments tab" />
            </Tabs.Trigger>
            {!hasSeenUpdates(seenThreads, annotations) && (
              <CountBadge
                className={style.CountBadge}
                fadeOut={fadeOutCommentBadge}
                count={getNewMessagesCount(annotations)}
              />
            )}
          </div>
        </div>
        {renderCommentsStateSelector()}
      </Tabs.List>
    );
  };

  const renderPostMessage = useCallback(() => {
    if (isCommentsTabOpen) {
      return null;
    }

    return (
      <div className={style.PostMessageContainer}>
        <PostMessage
          agreementId={agreementId}
          isChatMessage
        />
      </div>
    );
  }, [agreementId, isCommentsTabOpen]);

  if (isEmpty(agreement)) {
    return null;
  }

  const renderHeader = () => (
    <div className={style.TabHeader}>
      {onClose && (
        <div className={style.HeaderWrapper}>
          <h2 className={style.Header}>
            <Message
              id="Collaboration"
              comment="Collaboration tab header"
            />
          </h2>
          <CloseSidebarButton onClose={onClose} />
        </div>
      )}
      {renderTabsList()}
    </div>
  );

  return (
    <>
      <Tabs.Root
        className={style.TabsRoot}
        onValueChange={onTabValueChange}
        value={activeMessageType}
      >
        {renderHeader()}
        {renderContent()}
      </Tabs.Root>
      <DisabledState
        agreement={agreement}
        tab={activeMessageType}
      />
      {renderPostMessage()}
    </>
  );
};

export default MessageTypes;
