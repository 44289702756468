import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { partialRight } from 'lodash';

import { Message } from '@oneflowab/pomes';

import { getGuestToken, getMyParticipantWhenSignatory } from 'agreement/selectors';
import useAgreement from 'hooks/use-agreement';

import Button from 'components/button';
import BankIDIcon from 'components/icons/bankid';
import {
  MultistepModal,
  ModalStep,
  ModalStepRenderProps,
} from 'components/modal';

import { isDeviceWithoutHover } from 'utils/browser';

import SignContractConfirm from '../confirm';
import SignContractElectronicIdSign from '../electronic-id-sign';
import { SwedishBankIDFlow } from './components/swedish-bankid-flow';
import ErrorStep from './components/error';

import { SWEDISH_BANKID_SIGNING_DEVICE } from './constants';

import { BankIDSigningDeviceType } from './types';

import style from './swedish-bankid-v2.module.scss';

const SIGN_CONTRACT_INTENT_MODAL = 'sign contract intent modal';
const SIGN_CONTRACT_EID_PENDING_MODAL = 'sign contract eid pending modal';

export type ModalData = {
  ssn: string
};

export type Props = {
  children: ReactNode;
  sendChecksum?: boolean;
  handleClose: () => void;
  handleOnSuccess: () => void;
  agreementId: Oneflow.Agreement['id'];
  renderSuccessModal: ModalStepRenderProps;
};

const isMobileDevice = isDeviceWithoutHover();

const SwedishBankIDV2 = ({
  agreementId,
  handleClose,
  handleOnSuccess,
  sendChecksum,
  children,
  renderSuccessModal,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const myParticipantWhenSignatory = getMyParticipantWhenSignatory(agreement);
  const guestToken = useSelector(getGuestToken);
  const [signingDevice, setSigningDevice] = useState<BankIDSigningDeviceType>(
    SWEDISH_BANKID_SIGNING_DEVICE.SAME,
  );
  const [error, setError] = useState(null);

  const renderActions = ({ isDisabled }: { isDisabled: boolean }, onStepComplete: () => void) => (
    <div className={style.SwedishBankIDButtons}>
      <p className={style.SwedishBankIDLabel}>
        <Message
          id="Sign with"
          comment="Label for signing with Swedish BankID"
        />
      </p>
      <Button
        kind="secondary"
        data-testid="swedish-bankid-other-device"
        disabled={isDisabled}
        onClick={() => {
          if (isMobileDevice) {
            setSigningDevice(SWEDISH_BANKID_SIGNING_DEVICE.SAME);
          } else {
            setSigningDevice(SWEDISH_BANKID_SIGNING_DEVICE.OTHER);
          }
          onStepComplete();
        }}
        customClass={style.SwedishBankIDButton}
        icon={<BankIDIcon width="16px" />}
        hasIconRight
      >
        <Message
          id="Mobile BankID"
          comment="Mobile BankID button label for signing on other device"
        />
      </Button>
      <Button
        kind="linkInline"
        data-testid="swedish-bankid-same-device"
        disabled={isDisabled}
        onClick={() => {
          if (isMobileDevice) {
            setSigningDevice(SWEDISH_BANKID_SIGNING_DEVICE.OTHER);
          } else {
            setSigningDevice(SWEDISH_BANKID_SIGNING_DEVICE.SAME);
          }
          onStepComplete();
        }}
        customClass={style.BankIDSameDeviceButton}
      >
        {isMobileDevice ? (
          <Message id="Or use BankID on other device" comment="button label to switch BankID method" />
        ) : (
          <Message id="Or use BankID on this device" comment="button label to switch BankID method" />
        )}
      </Button>
    </div>
  );

  const renderCancelAction = () => (
    <div className={style.ActionsWrapper}>
      <Button
        data-testid="error-action-cancel"
        onClick={handleClose}
        customClass={style.Cancel}
      >
        <Message
          id="Cancel"
          comment="Label for cancel button that closes the sign-contract modal"
        />
      </Button>
    </div>
  );

  const renderErrorActions = ({ onPreviousStep }: { onPreviousStep: () => void }) => (
    <div className={style.ActionsWrapper}>
      <Button
        data-testid="error-action-cancel"
        onClick={() => {
          handleClose();
          setError(null);
        }}
        customClass={style.Cancel}
      >
        <Message
          id="Cancel"
          comment="Label for cancel button that closes the sign-contract modal"
        />
      </Button>
      <Button
        kind="secondary"
        data-testid="error-action-try-again"
        onClick={() => {
          onPreviousStep();
          setError(null);
        }}
      >
        <Message
          id="Try Again"
          comment="Label for button that takes the user to the previous step to retry the signing process"
        />
      </Button>
    </div>
  );

  const renderFinalStep: ModalStepRenderProps = ({
    onStepComplete,
    onPreviousStep,
  }) => {
    if (error) {
      return (
        <ErrorStep
          handleClose={() => {
            handleClose();
            setError(null);
          }}
          handlePrevious={() => {
            onPreviousStep();
            setError(null);
          }}
        />
      );
    }

    return renderSuccessModal({
      onStepComplete,
      onPreviousStep,
    });
  };

  const renderConfirmModal: ModalStepRenderProps = ({
    onStepComplete,
    onPreviousStep,
  }) => (
    <SignContractConfirm
      agreement={agreement}
      onPreviousStep={onPreviousStep}
      onStepComplete={onStepComplete}
      myParticipantWhenSignatory={myParticipantWhenSignatory}
      onClose={handleClose}
      onSuccess={handleOnSuccess}
      guestToken={guestToken}
      renderCustomActions={partialRight(renderActions, onStepComplete)}
      modalKey={SIGN_CONTRACT_INTENT_MODAL}
    />
  );

  const renderElectronicIdSignModal: ModalStepRenderProps = ({
    onPreviousStep,
    onStepComplete,
    onSyncStepData,
    modalData,
  }) => (
    <SignContractElectronicIdSign
      agreement={agreement}
      title={(
        <Message
          id="Document signing"
          comment="Modal title for waiting to sign with electronic ID."
        />
      )}
      onClose={handleClose}
      onPreviousStep={onPreviousStep}
      onStepComplete={onStepComplete}
      onSuccess={handleOnSuccess}
      onSyncStepData={onSyncStepData}
      guestToken={guestToken}
      modalData={modalData}
      renderCustomActions={renderCancelAction}
      renderCustomErrorActions={() => renderErrorActions({ onPreviousStep })}
      myParticipantWhenSignatory={myParticipantWhenSignatory}
      renderCustomEID={({
        signCodeState,
        changeButtonStatus,
        changeSignErrorStatus,
      }) => (
        <SwedishBankIDFlow
          signingDevice={signingDevice}
          setSigningDevice={setSigningDevice}
          agreementId={agreement.id}
          changeButtonStatus={changeButtonStatus}
          onStepComplete={onStepComplete}
          onSuccess={handleOnSuccess}
          changeSignErrorStatus={changeSignErrorStatus}
          signCodeState={signCodeState}
          onError={setError}
        />
      )}
      modalKey={SIGN_CONTRACT_EID_PENDING_MODAL}
    />
  );

  return (
    <>
      <MultistepModal
        isOpen
        onCancel={handleClose}
      >
        <ModalStep modalKey={SIGN_CONTRACT_INTENT_MODAL}>
          {renderConfirmModal}
        </ModalStep>
        <ModalStep modalKey={SIGN_CONTRACT_EID_PENDING_MODAL}>
          {renderElectronicIdSignModal}
        </ModalStep>
        <ModalStep>
          {renderFinalStep}
        </ModalStep>
      </MultistepModal>
      {children}
    </>
  );
};
export default SwedishBankIDV2;
