import { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { Message } from '@oneflowab/pomes';

import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import { MESSAGES_TAB, AUDIT_TRAIL_TAB } from 'agreement/constants';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import CountBadge from 'components/document-tabs/count-badge';

import * as DropdownMenu from 'components/dropdown-menu-v2';
import BurgerMenuIcon from 'components/icons/burger-menu';
import type { DocumentTabValue } from 'components/document-layout-container/types';
import toast from 'components/toasts';

import style from './burger-menu.module.scss';

export type MenuItem = {
  icon: ReactNode,
  text: ReactNode,
  onClick?: () => void,
  type?: string,
  href?: string,
  visible?: boolean,
  value?: DocumentTabValue,
}

type Props = {
  menuItems: MenuItem[],
  activeTab: DocumentTabValue,
}

const BurgerMenu = ({
  menuItems,
  activeTab,
}: Props) => {
  const isInPreviewMode = useIsInPreviewMode();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [hasActiveTab, setHasActiveTab] = useState<boolean>(
    menuItems?.some((item) => item.value === activeTab),
  );

  const { newMessagesCount, shouldFadeOutCountBadge, newAuditTrailCount } = useDocumentLayout();

  const isMessageIconInBurgerMenu = menuItems?.some((item) => item.value === MESSAGES_TAB);
  const isAuditTrailIconInBurgerMenu = menuItems?.some((item) => item.value === AUDIT_TRAIL_TAB);
  const showCountBadge = isMessageIconInBurgerMenu || isAuditTrailIconInBurgerMenu;
  const totalCount = isMessageIconInBurgerMenu
    ? newAuditTrailCount + newMessagesCount : newAuditTrailCount;

  useEffect(() => {
    setHasActiveTab(menuItems?.some((item) => item.value === activeTab));
  }, [activeTab, menuItems]);

  return (
    <DropdownMenu.Root modal={false} onOpenChange={(open) => setIsMenuOpen(open)}>
      <DropdownMenu.Trigger asChild>
        <button className={clsx(style.Trigger, {
          [style.Active]: isMenuOpen || hasActiveTab,
        })}
        >
          <BurgerMenuIcon height="24px" />
          {
            showCountBadge && !isInPreviewMode && (
              <CountBadge fadeOut={shouldFadeOutCountBadge} count={totalCount} />
            )
          }
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content align="start" sideOffset={2}>
          {menuItems.map((item) => {
            if (item.type === 'download') {
              return (
                <DropdownMenu.Item key={uniqueId()} asChild>
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={style.MenuItem}
                  >
                    {item.icon}
                    {item.text}
                  </a>
                </DropdownMenu.Item>
              );
            }

            const shouldShowToast = isInPreviewMode
            && (item.value === MESSAGES_TAB || item.value === AUDIT_TRAIL_TAB);

            return (
              <DropdownMenu.Item
                key={uniqueId()}
                onSelect={() => {
                  if (shouldShowToast) {
                    return toast.warning({
                      id: 'not-available-in-preview',
                      title: <Message
                        id="Not available in preview"
                        comment="Title for the warning message when signing is not available in preview mode."
                      />,
                      duration: 5000,
                    });
                  }
                  return item.onClick?.();
                }}
                className={clsx(style.MenuItem, {
                  [style.Active]: item.value === activeTab,
                })}
              >
                {item.icon}
                {item.text}
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default BurgerMenu;
