// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { checkAcl } from 'components/acl';

import {
  isFeatureEnabledSelector,
  getAccountFromSessionSelector,
} from 'reducers/session';
import accountsReducer from 'reducers/entities/accounts';

import DataManagementPage, { type Props } from './data-management';

type StateProps = {|
  isAccountExportEnabled: boolean,
  canEdit: boolean,
  currentDpoEmail: string,
  currentEmailProvider: string,
  currentFreshworks: boolean,
  currentCustomerIo: boolean,
  currentOpenAi: boolean,
  currentContractPdfShowContactDetails: boolean,
  currentContractPdfShowSsn: boolean,
  currentContractPdfShowIp: boolean,
  currentContractPdfDownload: boolean,
  addressBook: boolean,
  updateState: UpdateState,
  accountId: number,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);
  const fetchState = accountsReducer.getFetchSelector(state, {
    id: account.id,
  });
  const updateState = accountsReducer.getUpdateAccountDataManagementSelector(state, {
    id: account.id,
  });
  const canEdit = checkAcl(account.acl, 'account:update:data_management');

  return {
    isAccountExportEnabled: !isFeatureEnabledSelector(state, {
      feature: 'outageAccountExport',
    }),
    fetchState,
    canEdit,
    currentDpoEmail: account.dataManagement.dpoEmail,
    currentEmailProvider: account.dataManagement.emailProvider,
    currentFreshworks: account.dataManagement.freshworks,
    currentCustomerIo: account.dataManagement.customerIo,
    currentOpenAi: account.dataManagement.openAi,
    currentContractPdfShowContactDetails: account.dataManagement.contractPdfShowContactDetails,
    currentContractPdfShowSsn: account.dataManagement.contractPdfShowSsn,
    currentContractPdfShowIp: account.dataManagement.contractPdfShowIp,
    currentContractPdfDownload: account.dataManagement.contractPdfDownload,
    currentAddressBook: account.dataManagement.addressBook,
    currentHandwrittenSignatureAllowTyped: account.dataManagement.handwrittenSignatureAllowTyped,
    currentLoginSessionLength: account.dataManagement.loginSessionLength,
    currentLoginTrustedDeviceLength: account.dataManagement.loginTrustedDeviceLength,
    updateState,
    accountId: account.id,
  };
};

type DispatchProps = {|
  resetUpdateState: (accountId: number) => void,
  updateAccountDataManagement: (id: number, data: Object, onSuccess: () => void) => void,
  fetchAccount: ({ id: number }) => void,
|};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  resetUpdateState: (accountId) => {
    dispatch(accountsReducer.updateAccountDataManagementReset({
      id: accountId,
    }));
  },
  updateAccountDataManagement: (accountId, data, onSuccess) => {
    dispatch(accountsReducer.updateAccountDataManagement({
      id: accountId,
      data,
      pipe: {
        onSuccess,
      },
    }));
  },
  fetchAccount: ({ id }) => {
    dispatch(accountsReducer.fetchAccount({
      id,
    }));
  },
});

export default connect<Props, any, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(DataManagementPage);
