import { Message } from '@oneflowab/pomes';

const Pending = ({ date }: { date: string }) => (
  <Message
    id="Document can be signed until {date}."
    values={{
      date,
    }}
    comment="Text for pending contract. Visible in contract."
  />
);

export default Pending;
