// @flow

import { type Dispatch } from 'redux';
import { connect } from 'react-redux';

import extensionsReducer from 'reducers/entities/extensions';
import { KEY_SALESFORCE } from 'extensions';

import RevokeConnection, { type Props, type LocalizeProps } from './salesforce-revoke-connection';

export const QUERY_NAME = '/admin/account/extensions';

type MSProps = {|
  revokeState: RpcState,
|};

type MDProps = {|
  revokeConnection: () => void,
  resetRevokeState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, {| ...$Exact<LocalizeProps>, ...MSProps, ...MDProps |}>;

type MapStateToProps = (State) => MSProps;
export const mapStateToProps: MapStateToProps = (state) => ({
  revokeState: extensionsReducer.getRevokeConnectionSelector(state, {
    id: KEY_SALESFORCE,
  }),
});

type MapDispatchToProps = (Dispatch<*>) => MDProps
export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  revokeConnection: () => {
    dispatch(extensionsReducer.revokeConnection({
      id: KEY_SALESFORCE,
      pipe: {
        action: () => extensionsReducer.queryExtensionsReload({
          name: QUERY_NAME,
        }),
        wait: extensionsReducer.QUERY_EXTENSIONS_SUCCESS,
      },
    }));
  },
  resetRevokeState: () => {
    dispatch(extensionsReducer.revokeConnectionReset({
      id: KEY_SALESFORCE,
    }));
  },
});

export default connect<Props, OwnProps, MSProps, MDProps, State, _>(
  mapStateToProps,
  mapDispatchToProps,
)(RevokeConnection);
