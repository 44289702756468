import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import type { ComponentType } from 'react';

import { boxTypesArray } from 'agreement/box-types';

import { DraggableSection } from './draggable-section';
import style from './content-tab.module.scss';

type Props = {
  message: MessageTranslator,
};

const ContentSectionsComponent = ({
  message,
}: Props) => {
  const items = boxTypesArray(message);

  return (
    <div
      className={style.Sections}
    >
      {items.map(({ type, label }) => (
        <DraggableSection
          key={type}
          type={type}
          label={label}
        />
      ))}
    </div>
  );
};

type ExposedProps = Record<string, never>;

// eslint-disable-next-line max-len
export const ContentSections = localize<Props>(ContentSectionsComponent) as unknown as ComponentType<ExposedProps>;
