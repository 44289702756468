import {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  get,
  isEmpty,
} from 'lodash';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';
import UUID from 'node-uuid';
import type { FileRejection } from 'react-dropzone';

import {
  onUploadPdfFile,
  getAttachedFilesTotalSize,
  getAttachedFilesTotalCount,
  updateBoxConfigAction,
  FILES_DROP,
  FILES_STATUS_BOX_MAXIMIZE,
  FILES_STATUS_BOX_OPEN,
  FILES_UPLOAD_FAIL_BATCH,
  FILES_UPLOAD_FAIL,
  FILES_UPDATE_STATUS,
  FILES_UPLOAD_STATUS,
  silentUpdatePdfBoxAction,
} from 'reducers/current-contract';

import { PdfBoxPropsProvider } from 'contexts/pdf-box-props';
import { useAsyncAssetProps } from 'contexts/async-asset-props';
import { getAgreementMyParticipant, getGuestToken } from 'agreement/selectors';
import useAgreement from 'hooks/use-agreement';
import useCurrentBoxId from 'hooks/use-current-box-id';
import useCurrentBox from 'hooks/use-current-box';
import useCurrentContractId from 'hooks/use-current-contract-id';

import { getAttachmentsCountLimit } from 'agreement';
import { isBoxDataCreateAllowed } from 'agreement/box-data-create-permissions';
import { FILE_APPLICATION_PDF, FILE_IMAGE_TIFF } from 'utils/file';
import { isBoxDataValueUpdateAllowed } from 'agreement/box-data-value-update-permissions';
import type { Box } from 'data-validators/entity-schemas/document-box';

import { CancelButton } from 'components/buttons';
import Confirmable from 'components/confirmable';
import FileUpload from 'components/file-upload';
import { MenuItem } from 'components/menu-item';
import BoxWrapper from 'components/contract-boxes/box-wrapper';
import { EmptyPdfBox } from 'components/contract-boxes/pdf-box/empty-pdf-box';
import PdfFile from 'components/contract-boxes/pdf-box/pdf-file';

import Download from 'components/icons/download';
import ReplaceNew from 'components/icons/replace-new';
import NewCheck from 'components/icons/new-check';

import { fileToJson, generateDocumentOpenURL } from 'components/contract-boxes/generic-box-helpers';
import {
  MAX_TOTAL_ATTACHMENT_SIZE_PER_CONTRACT,
  DROPZONE_EXCEED_FILE_COUNT_ERROR,
  DROPZONE_EXCEED_FILE_SIZE_ERROR,
  DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR,
} from 'components/contract-boxes/constants';

import style from './pdf-box.module.scss';

const modalKey = 'pdf upload modal';

type Props = {
  boxId: number,
  isEditable: boolean,
  onRemoveBox: () => void,
  onAddSectionRules: () => void,
  permissions: {
    update: boolean,
    updateConfig: boolean,
  },
};

const iconClassNames = (showIcon: boolean) => (
  clsx(style.Icon, {
    [style.ShowIcon]: showIcon,
  })
);

export const PdfBox = ({
  boxId,
  isEditable,
  onRemoveBox,
  onAddSectionRules,
  permissions,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [asyncAssetState, setAsyncAssetState] = useState<string | null>(null);
  const currentBoxId = useCurrentBoxId(boxId);
  const box = useCurrentBox(currentBoxId) as Box;
  const pdfData = useMemo(() => {
    const pdfBoxData = box?.content.data?.filter((dataItem: Box) => dataItem.key === 'file' && !dataItem._removed);
    const boxData = pdfBoxData[0];
    const replacedBoxData = pdfBoxData[1];

    if (replacedBoxData) {
      return replacedBoxData;
    }

    return boxData;
  }, [box?.content.data]);
  const hasPages = useMemo(() => Boolean(get(pdfData, 'value.pages')), [pdfData]);
  const dispatch = useDispatch();
  const guestToken = useSelector((state) => getGuestToken(state));
  const contractId: Oneflow.Agreement['id'] = useCurrentContractId();
  const agreement = useAgreement(contractId);
  const {
    succeededAsyncAssetData,
    failedAsyncAssetData,
    resetSucceededAsyncAssetState,
    resetFailedAsyncAssetState,
    fileIdRef,
  } = useAsyncAssetProps();
  const [currentAssetId, setCurrentAssetId] = useState(null);
  const myParticipant = agreement.parties && getAgreementMyParticipant(agreement);
  const isAllowedToAddBoxData = isBoxDataCreateAllowed(box);
  const isAllowedToUpdateDataValue = isBoxDataValueUpdateAllowed(box, pdfData);
  const isFieldsOnPDFEnabled = agreement.availableOptions?.documentOverlayFields;

  const contentData = get(box, 'content.data');
  const hasContentData = !isEmpty(contentData);
  const attachmentsCountLimit = useMemo(() => getAttachmentsCountLimit(agreement), [agreement]);
  const attachedFilesTotalSize = useSelector(getAttachedFilesTotalSize);
  const attachedFilesTotalCount = useSelector(getAttachedFilesTotalCount);

  const { config } = box;
  // counterpartEdit did not exist on pdf-box before
  // for older contracts, adding default value:
  const { counterpartEdit, colleagueEdit, managerLock } = config;

  useEffect(() => {
    if (pdfData?.value?.status === 'processing') {
      setLoadingFile(true);
      setAsyncAssetState('processing');
      setCurrentAssetId(pdfData?.value.assetId);
    }
  }, [pdfData?.value.assetId, pdfData?.value?.status]);

  useEffect(() => {
    if (!isEmpty(succeededAsyncAssetData)) {
      const { pages, status, assetId } = succeededAsyncAssetData;

      if (assetId !== currentAssetId) {
        return;
      }

      if (status === 'ready') {
        dispatch(silentUpdatePdfBoxAction({
          boxId: currentBoxId,
          pages,
          status,
          assetId,
        }));

        dispatch({
          type: FILES_UPDATE_STATUS,
          status: FILES_UPLOAD_STATUS.SUCCESS,
          id: fileIdRef.current,
        });
      }
      resetSucceededAsyncAssetState();
      fileIdRef.current = null;
      setLoadingFile(false);
      setAsyncAssetState(status);
    }

    if (!isEmpty(failedAsyncAssetData)) {
      const { apiCode, status, assetId } = failedAsyncAssetData;

      if (assetId !== currentAssetId) {
        return;
      }

      if (status === 'failed') {
        dispatch(silentUpdatePdfBoxAction({
          boxId: currentBoxId,
          status,
          apiCode,
          assetId,
        }));

        dispatch({
          type: FILES_UPDATE_STATUS,
          status: FILES_UPLOAD_STATUS.FAIL,
          id: fileIdRef.current,
        });
      }
      resetFailedAsyncAssetState();
      fileIdRef.current = null;
      setLoadingFile(false);
      setAsyncAssetState(status);
    }
  }, [
    currentBoxId,
    dispatch,
    resetSucceededAsyncAssetState,
    resetFailedAsyncAssetState,
    succeededAsyncAssetData,
    failedAsyncAssetData,
    currentAssetId,
    fileIdRef,
  ]);

  const toggleCounterpartEdit = useCallback(() => {
    const updatedBoxConfig = {
      counterpartEdit: !counterpartEdit,
    };

    dispatch(updateBoxConfigAction(currentBoxId, updatedBoxConfig));
  }, [counterpartEdit, currentBoxId, dispatch]);

  const toggleColleagueEdit = useCallback(() => {
    const updatedBoxConfig = {
      colleagueEdit: !colleagueEdit,
    };

    dispatch(updateBoxConfigAction(currentBoxId, updatedBoxConfig));
  }, [colleagueEdit, dispatch, currentBoxId]);

  const onUploadSuccess = useCallback(({ status }: { status: string }) => {
    setModalOpen(false);
    setAsyncAssetState(status);
  }, []);

  const onUploadFailure = useCallback((error: any) => {
    setLoadingFile(false);

    const errorCode = error.response.status;
    dispatch({
      type: FILES_STATUS_BOX_OPEN,
    });
    dispatch({
      type: FILES_STATUS_BOX_MAXIMIZE,
    });
    dispatch({
      type: FILES_UPLOAD_FAIL,
      id: fileIdRef.current,
      errors: [{ code: errorCode }],
    });
    fileIdRef.current = null;

    setAsyncAssetState('failed');
  }, [dispatch, fileIdRef]);

  const onUpload = useCallback(async ({ file, type }: { file: File, type: string }) => {
    const fileId = UUID.v4();
    fileIdRef.current = fileId;

    // we don't get this state from the server, so we need to set it manually
    setAsyncAssetState('uploading');

    dispatch({
      type: FILES_DROP,
      files: [{
        id: fileId,
        file: fileToJson(file),
        errors: [],
      }],
    });

    setLoadingFile(true);

    dispatch(onUploadPdfFile({
      boxId: currentBoxId,
      file,
      fileType: type,
      myParticipant,
      onSuccess: onUploadSuccess,
      onFailure: onUploadFailure,
    }));

    dispatch({ type: FILES_STATUS_BOX_OPEN });
    dispatch({ type: FILES_STATUS_BOX_MAXIMIZE });
  }, [
    dispatch,
    onUploadSuccess,
    onUploadFailure,
    currentBoxId,
    myParticipant,
    fileIdRef,
  ]);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const fileRejectionsWithIds = fileRejections.map((fileRejection) => ({
      ...fileRejection,
      id: fileIdRef.current,
    }));

    dispatch({
      type: FILES_DROP,
      files: fileRejectionsWithIds,
    });
    dispatch({
      type: FILES_UPLOAD_FAIL_BATCH,
      files: fileRejectionsWithIds,
    });

    dispatch({ type: FILES_STATUS_BOX_OPEN });
    dispatch({ type: FILES_STATUS_BOX_MAXIMIZE });
    fileIdRef.current = null;
  }, [dispatch, fileIdRef]);

  const maxSizePerContractValidator = useCallback((file: File) => {
    if (file.size > 0) {
      const newTotalSize = attachedFilesTotalSize + file.size;
      const newTotalCount = attachedFilesTotalCount + 1;

      if (attachedFilesTotalCount >= attachmentsCountLimit) {
        return {
          code: DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR,
        };
      }

      if (newTotalCount > attachmentsCountLimit) {
        return {
          code: DROPZONE_EXCEED_FILE_COUNT_ERROR,
        };
      }

      if (newTotalSize > MAX_TOTAL_ATTACHMENT_SIZE_PER_CONTRACT) {
        return {
          code: DROPZONE_EXCEED_FILE_SIZE_ERROR,
        };
      }
    }
    return null;
  }, [attachmentsCountLimit, attachedFilesTotalCount, attachedFilesTotalSize]);

  const fileUploadProps = useMemo(() => {
    const data = {
      boxId: currentBoxId,
      accept: [FILE_APPLICATION_PDF, FILE_IMAGE_TIFF],
      assetType: 'document',
      loading: loadingFile,
      isSmall: true,
      displayFileType: 'PDF',
      maxSizePerContractValidator,
      attachmentsCountLimit,
      onDropRejected,
      asyncAssetState,
      pdfData,
    };

    return data;
  }, [
    currentBoxId,
    loadingFile,
    maxSizePerContractValidator,
    attachmentsCountLimit,
    onDropRejected,
    asyncAssetState,
    pdfData,
  ]);

  const renderBody = useCallback(() => (
    <FileUpload onUpload={onUpload} {...fileUploadProps} />
  ), [fileUploadProps, onUpload]);

  const renderActions = ({ closeConfirmation }: { closeConfirmation: () => void }) => (
    <CancelButton onClick={closeConfirmation} modalKey={modalKey} />
  );

  const getPdfActions = () => {
    if (!hasContentData) {
      return [];
    }

    const { content } = box;
    const { value } = content.data?.find((item: any) => item.key === 'file');

    let pdfActions = [
      <MenuItem
        key="download"
        icon={Download}
        label={(
          <Message
            id="Open document"
            comment="Action to open document."
          />
        )}
        href={generateDocumentOpenURL(contractId, value)}
        external
        bypass
        target="_blank"
      />,
      <MenuItem
        key="replace"
        icon={ReplaceNew}
        label={(
          <Message
            id="Replace document"
            comment="Action to download document."
          />
        )}
        onClick={() => setModalOpen(true)}
        disabled={!isAllowedToUpdateDataValue}
      />,
    ];
    if (isFieldsOnPDFEnabled) {
      pdfActions = [
        ...pdfActions,
        'separator',
        <MenuItem
          key="edit-fied"
          icon={<NewCheck className={iconClassNames(counterpartEdit)} height="14px" />}
          label={(
            <Message
              id="Allow counterparties to edit field values"
              comment="Menu option for allowing counterparties to edit field values"
            />
          )}
          onClick={toggleCounterpartEdit}
          disabled={!permissions.updateConfig}
        />,
      ];
    }
    if (isFieldsOnPDFEnabled && managerLock) {
      pdfActions = [
        ...pdfActions,
        <MenuItem
          key="edit-fied"
          icon={<NewCheck className={iconClassNames(colleagueEdit)} height="14px" />}
          label={(
            <Message
              id="Allow colleagues to edit field values"
              comment="Menu option for allowing colleagues to edit field values"
            />
          )}
          onClick={toggleColleagueEdit}
          disabled={!permissions.updateConfig}
        />,
      ];
    }

    return pdfActions;
  };

  const renderPdf = useCallback(() => {
    if (!hasPages) {
      return null;
    }

    return (
      <PdfFile
        contentData={contentData}
        contractId={contractId}
        guestToken={guestToken}
      />
    );
  }, [
    contentData,
    contractId,
    guestToken,
    hasPages,
  ]);

  const renderEmptyPdfBox = useCallback(() => {
    if (hasPages && !loadingFile) {
      return null;
    }

    return (
      <EmptyPdfBox
        pdfData={pdfData}
        notEditable={!isAllowedToAddBoxData}
        fileUploadProps={{ ...fileUploadProps, onUpload }}
      />
    );
  }, [
    fileUploadProps,
    hasPages,
    loadingFile,
    isAllowedToAddBoxData,
    onUpload,
    pdfData]);

  const pdfWrapperClasses = clsx(style.PdfWrapper, {
    [style.PdfBoxWithFile]: hasPages,
  });

  const getChildren = () => (
    <PdfBoxPropsProvider
      agreementId={contractId}
      box={box}
      boxId={currentBoxId}
    >
      <BoxWrapper
        boxId={currentBoxId}
        isAllowedToEdit={isEditable}
        onRemoveBox={onRemoveBox}
        onAddSectionRules={onAddSectionRules}
        nonFixedActions={getPdfActions()}
      >
        <div className={pdfWrapperClasses}>
          {renderPdf()}
          {renderEmptyPdfBox()}
        </div>
      </BoxWrapper>
    </PdfBoxPropsProvider>
  );

  const resetModalValues = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Confirmable
        body={renderBody()}
        isOpen={modalOpen}
        onClose={resetModalValues}
        actions={renderActions}
        header={(
          <Message id="Upload file" comment="Used as the modal title for pdf document upload" />
        )}
        modalKey={modalKey}
      >
        {getChildren}
      </Confirmable>
    </>
  );
};
