import { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';
import Button from 'components/button';
import Confirmable from 'components/confirmable';
import { CancelButton } from 'components/buttons';
import WarningIcon from 'components/icons/warning';

import style from './approvers-last.module.scss';

const modalKey = 'approvers-last';

type Props = {
  isOpen?: boolean,
  setIsOpen: (value: boolean) => void,
  onContinue: () => void,
  onClose: () => void,
  children?: (onClick: () => void) => ReactNode,
  sendAutomaticallyAfterApproval?: boolean,
  setSendAutomaticallyAfterApproval?: (value: boolean) => void,
};

export const ApproversLastModal = ({
  isOpen,
  setIsOpen,
  onClose,
  onContinue,
  children,
  sendAutomaticallyAfterApproval,
  setSendAutomaticallyAfterApproval,
}: Props) => {
  const handleOpen = () => {
    setIsOpen(true);
  };

  const renderBody = () => (
    <div className={style.BodyContainer}>
      <WarningIcon className={style.WarningIcon} />
      <p className={style.BodyText}>
        <Message
          id="Some approvers are positioned after the last signatory and won't be able to approve the document."
          comment="Text in the modal"
        />
      </p>
      <p className={style.BodyText}>
        <Message
          id="To ensure all approvals, place at least one signatory after each approver."
          comment="Text in the modal"
        />
      </p>
    </div>
  );

  const handleConfirm = () => {
    if (setSendAutomaticallyAfterApproval) {
      setSendAutomaticallyAfterApproval(sendAutomaticallyAfterApproval);
    }

    return onContinue();
  };

  const onCancel = (closeConfirmation: any) => {
    if (setSendAutomaticallyAfterApproval) {
      setSendAutomaticallyAfterApproval(false);
    }

    return closeConfirmation();
  };

  const getActions = ({ closeConfirmation }: { closeConfirmation: () => void }) => (
    <>
      <CancelButton onClick={() => onCancel(closeConfirmation)} modalKey={modalKey} />
      <Button
        color="yellow"
        onClick={handleConfirm}
        kind="primary"
        customClass={style.ContinueButton}
      >
        <Message
          id="Continue to send"
          comment="Button label used to confirm sending the document"
        />
      </Button>
    </>
  );

  return (
    <Confirmable
      header={(
        <Message
          id="Approvers last"
          comment="Used as the title in modal when approver is the last one in the signing order"
        />
    )}
      body={renderBody()}
      actions={getActions}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      onClose={onClose}
      onOpen={handleOpen}
      isOpen={isOpen}
      modalKey={modalKey}
      customBodyClass={style.Body}
    >
      {children}
    </Confirmable>
  );
};
