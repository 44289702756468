// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import {
  getAccountFromSessionSelector,
} from 'reducers/session';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';
import {
  getCurrentWorkspaceSelector,
} from 'reducers/app';

import agreementsReducer from 'reducers/entities/agreements';
import { getPathnameSelector } from 'reducers/router';

import { Acl } from 'components/acl';

import Sidebar from './sidebar';

type StateProps = {|
  acl: Acl,
  pathname: string,
  workspaceId: number,
|};
type MapStateToProps = (state: State) => StateProps;
type OwnProps = $Diff<Props, StateProps>;

export const mapStateToProps: MapStateToProps = (state: State) => {
  const currentWorkspace = getCurrentWorkspaceSelector(state);
  const acl = {
    workspace: currentWorkspace.acl || {},
    account: getAccountFromSessionSelector(state).acl || {},
  };
  const pathname = getPathnameSelector(state);

  return {
    acl,
    pathname,
    currentWorkspace,
  };
};

type DispatchProps = {|
  createContract: number => void,
|};

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  createContract: (workspaceId) => {
    dispatch(agreementsReducer.createAgreement({
      data: {
        workspaceId,
        // If you only have the "Can create blank contracts" permission you can create a contract
        // from the sidebar directly.
        amplitudeScope: 'main navigation',
      },
      pipe: {
        action: redirectToDocumentOnDocumentCreate,
        wait: agreementsReducer.CREATE_AGREEMENT_SUCCESS,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);
