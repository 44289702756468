// @flow
import React, { useCallback } from 'react';
import { components } from 'react-select';
import SelectField from 'components/select-field';
import MagnifyingGlassIcon from 'components/icons/magnifying-glass';
import clsx from 'clsx';

import style from './filter-select-field.module.scss';

type Option = {
  value: number,
  label: ReactNode,
  isFixed: boolean,
};

type Props = {
  onChange: (value: Array<number> | null) => void,
  onInputChange: (value: string) => void,
  options?: Option[],
  selectedValues?: Option[],
  entity?: string,
  name: string,
  ariaLabel?: String,
  menuZIndex?: number,
  ...props
};

type CustomInputProps = {
  children?: Function,
  ...props,
};

type CustomOptionProps = {
  getStyles: (key: string, props: Object) => void,
  className: Function,
  children: Function,
  isFocused: boolean,
  innerProps: Object,
  innerRef: string,
  data: Object,
  ...props,
}

const CustomInputWithIcon = ({ children, ...props }: CustomInputProps) => (
  <components.Control {...props}>
    <span className={style.InputIcon}>
      <MagnifyingGlassIcon width="14px" />
    </span>
    {children}
  </components.Control>
);

const CustomOption = (props: CustomOptionProps) => {
  const {
    innerProps,
    innerRef,
    children,
    data: customOption,
  } = props;

  return (
    <div
      ref={innerRef}
      className={clsx(style.CustomOption, {
        [customOption.customOptionStyle]: !!customOption.customOptionStyle,
      })}
      {...innerProps}
    >
      {customOption?.icon}
      <div>{children}</div>
    </div>
  );
};

const FilterSelectField = ({
  name,
  onChange,
  onInputChange,
  options,
  selectedValues,
  menuZIndex,
  ...props
}: Props) => {
  const handleChange = useCallback(
    (newValue) => {
      onChange(newValue.map((val) => val.value));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  );
  const input = {
    name,
    value: selectedValues,
    onChange: handleChange,
  };

  return (
    <SelectField
      input={input}
      options={options}
      onInputChange={onInputChange}
      isSearchable
      isClearable={false}
      hideErrorElement
      multi
      backspaceRemovesValue={false}
      closeMenuOnSelect={false}
      closeMenuOnScroll={false}
      controlShouldRenderValue={false}
      clearable={selectedValues?.some((value) => !value.isFixed)}
      components={{
        Control: CustomInputWithIcon,
        Option: CustomOption,
        ClearIndicator: null,
      }}
      menuPlacement="bottom"
      menuPosition="absolute"
      zIndex={menuZIndex}
      {...props}
    />
  );
};

export default FilterSelectField;
