import history from 'store/history';

export const getDocumentUrl = (
  documentId: number,
  template?: boolean,
) => {
  if (template) {
    return `/documents/templates/${String(documentId)}`;
  }

  return `/documents/${String(documentId)}`;
};

export const redirectToDocument = (
  documentId: number,
  template?: boolean,
) => {
  const documentUrl = getDocumentUrl(documentId, template);

  return history.push(documentUrl);
};

type DocumentCreateData = {
  result: number,
  entities: {
    agreements: {
      [key: number]: Oneflow.Agreement,
    }
  },
};

export const redirectToDocumentOnDocumentCreate = (
  data: DocumentCreateData,
  template?: boolean,
): void => {
  const { agreements } = data.entities;
  const newAgreement = agreements[data.result];

  if (!newAgreement) {
    return;
  }

  redirectToDocument(
    newAgreement.id,
    template,
  );
};
