import {
  Editor,
  Transforms,
  Element as SlateElement,
} from 'slate';

export const isAltTextActive = (editor: Editor) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image' && n.alt?.length > 0
    ),
    mode: 'lowest',
  });

  return !!match;
};

export const getAltText = (editor: Editor) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image'
    ),
    mode: 'lowest',
  });

  if (match) {
    return match[0].alt;
  }

  return null;
};

export const setAltText = (editor: Editor, text: string) => {
  Transforms.setNodes(editor, {
    alt: text,
  }, {
    match: (n) => (
      n.type === 'image'
    ),
    mode: 'lowest',
  });
};
