// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import PageBreak from 'components/icons/page-break';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { addPageBreak, isPageBreakAllowed } from './page-break-plugin';
import ToolbarButton from '../toolbar-button';

type Props = {
  isMenuItem: boolean,
  disabled: boolean,
};

const PageBreakButton = ({ isMenuItem, disabled }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      isMenuItem={isMenuItem}
      label={(
        <Message
          id="Page break"
          comment="The label of the editor's toolbar plugin that adds a Page break."
        />
      )}
      disabled={!isPageBreakAllowed(editor) || disabled}
      onTrigger={() => {
        focusEditor();
        addPageBreak(editor);
      }}
      icon={<PageBreak />}
    />
  );
};

export default PageBreakButton;
