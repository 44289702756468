import { useSelector } from 'react-redux';
import { get } from 'lodash';
import clsx from 'clsx';

import { getAvailableContentInlineSize } from 'reducers/app';
import { useFormBoxProps } from 'contexts/form-box-props';
import * as formBoxHelpers from 'components/contract-boxes/form-box/helpers';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import DeleteRowButton from 'components/contract-boxes/form-box/components/delete-row-button';
import DragControlItem from 'components/drag-control-item/drag-control-item';
import FormBoxCell from 'components/contract-boxes/form-box/rows/form-box-cell';

import style from './form-box-row.module.scss';

type Props = {
  row: any,
  rowIndex: number,
  setActivePopover: () => void,
  activePopoverIndexMap: void,
  setActiveField: () => void,
  dndActive: false,
  activeField: any,
};

const {
  COLUMN_SIZE_1,
  COLUMN_SIZE_2,
  COLUMN_SIZE_3,
  COLUMN_SIZE_4,
  getFieldData,
} = formBoxHelpers;

const COLUMN_CLASSES = {
  [COLUMN_SIZE_1]: style.ColumnSize1,
  [COLUMN_SIZE_2]: style.ColumnSize2,
  [COLUMN_SIZE_3]: style.ColumnSize3,
  [COLUMN_SIZE_4]: style.ColumnSize4,
};

const getColumnSize = (isMobile, colCount) => {
  if (!COLUMN_CLASSES[colCount] || isMobile) {
    return COLUMN_CLASSES[1];
  }

  return [COLUMN_CLASSES[colCount]];
};

const FormBoxRow = ({
  row,
  rowIndex,
  setActivePopover,
  activePopoverIndexMap,
  setActiveField,
  dndActive,
  activeField,
}: Props) => {
  const {
    config,
    removeRow,
    data,
    isEditable,
  } = useFormBoxProps();
  const availableContentInlineSize = useSelector(getAvailableContentInlineSize);
  const isMobile = availableContentInlineSize < 700;

  const {
    colCount,
  } = config;

  const rowClasses = clsx(
    style.RowContainer,
    getColumnSize(isMobile, colCount),
  );

  const hasSpaceForActiveField = (columnIndex: number) => {
    if (!activeField) {
      return false;
    }
    let hasSpace = true;

    const { field } = activeField;
    const fieldId = getId(field);
    const fieldData = get(getFieldData(data, fieldId), 'value');
    const draggedItemColspan = get(fieldData, 'colspan');
    const limit = columnIndex + draggedItemColspan;
    if (limit > row.length) {
      return false;
    }
    for (let i = columnIndex; i < limit; i += 1) {
      if (row[i] !== null) {
        hasSpace = false;
      }
    }
    return hasSpace;
  };

  const renderCell = (columnIndex: number) => (
    <FormBoxCell
      row={row}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      colCount={!isMobile ? colCount : COLUMN_SIZE_1}
      setActivePopover={setActivePopover}
      activePopoverIndexMap={activePopoverIndexMap}
      setActiveField={setActiveField}
      dndActive={dndActive}
      key={`cell_${rowIndex}_${columnIndex}`}
      hasSpaceForField={hasSpaceForActiveField(columnIndex)}
    />
  );

  const renderCells = () => {
    const cells = [];
    let mergeCellStart = -1;
    let mergeCellEnd = -1;
    for (let i = 0; i < colCount; i += 1) {
      const field = row[i];
      const fieldId = getId(field);
      if (fieldId) {
        const fieldData = get(getFieldData(data, fieldId), 'value');
        const colSpan = get(fieldData, 'colspan');
        if (colSpan > 1) {
          mergeCellStart = i;
          mergeCellEnd = i + colSpan;
        }
      }

      if (i > mergeCellStart && i < mergeCellEnd) {
        cells.push(null);
      } else {
        cells.push(renderCell(i));
      }
    }

    return cells;
  };

  const key = row.id;

  return (
    <DragControlItem
      index={rowIndex}
      item={row}
      key={key}
    >
      <div className={rowClasses} key={key}>
        {renderCells()}
        {isEditable ? (
          <DeleteRowButton
            className={style.DeleteRowButton}
            onClick={removeRow(rowIndex)}
          />
        ) : null}
      </div>
    </DragControlItem>
  );
};

export default FormBoxRow;
