import { createContext, useContext } from 'react';

import type { Params } from './types';

type QueryParamsContextType = {
  replaceParams: (values: Params) => void,
  updateParam: (key: string, value: any) => void,
  updateParams: (values: Params) => void,
  params: Params,
}

export const QueryStringParamsContext = createContext<QueryParamsContextType | null>(null);

export const useQueryStringParams = () => {
  const context = useContext(QueryStringParamsContext);
  if (!context) {
    throw new Error('useQueryStringParams must be used within a QueryStringParamsProvider');
  }
  return context;
};

export const withQueryStringParamsConsumer = (Component: React.ComponentType<any>) => {
  const WithQueryStringParams = (props: any) => (
    <QueryStringParamsContext.Consumer>
      {(context) => <Component {...props} {...context} />}
    </QueryStringParamsContext.Consumer>
  );

  return WithQueryStringParams;
};
