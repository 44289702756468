import NewAdd from 'components/icons/new-add';
import Button from 'components/button';
import style from './box-add-row-button.module.scss';

type Props = {
  onClick: () => void,
};

const BoxAddRowButton = ({
  onClick,
}: Props) => (
  <Button
    icon={<NewAdd height="12px" />}
    kind="round"
    onClick={onClick}
    customClass={style.AddRowButton}
  />
);

export default BoxAddRowButton;
