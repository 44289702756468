/** Returns the total number of pages */
export const getTotalPages = (totalItems, itemsPerPage) => (
  Math.ceil(totalItems / itemsPerPage)
);

/** Returns the page number for the specified offset */
export const getPageNumberForOffset = (offset, itemsPerPage) => (
  Math.floor(offset / itemsPerPage) + 1
);

/** Returns the offset for the specified page */
export const getOffsetForPage = (page, itemsPerPage) => (
  Math.floor((page - 1) * itemsPerPage)
);
