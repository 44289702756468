// @flow

import * as React from 'react';
import { useColleagues } from './hooks';

type Props = {
  children: React.Node,
  setColleaguesOptions?: Function
};

function AsyncFilterBoxWithColleagues(props: Props) {
  const {
    children,
    setColleaguesOptions,
  } = props;

  useColleagues({
    setColleaguesOptions,
  });

  return children;
}

export default AsyncFilterBoxWithColleagues;
