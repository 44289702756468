// @flow
import {
  getRef,
} from 'agreement/event';
import { isViewer } from 'agreement/participant/participant';
import isInfluencer from 'agreement/participant/is-influencer';
import isOrganizer from 'agreement/participant/is-organizer';
import isSignatory from 'agreement/participant/is-signatory';
import { getPartyByParticipantId, isIndividual } from 'agreement/party/party';
import { type MessageTranslator } from '@oneflowab/pomes';
import * as countries from 'i18n-iso-countries';
import isString from 'lodash/isString';
import { formatTimePeriod } from 'agreement/date-helpers';
import {
  auditTrailAttributeCreators,
  auditTrailMfaChannelCreators,
  auditTrailRolesCreators,
  auditTrailSignMethodCreators,
  auditTrailSignMethodShortCreators,
  auditTrailTypesCreators,
  auditTrailDeliveryChannelCreators,
} from './constants';

// eslint-disable-next-line max-len
export const eventHasRef = (event: AgreementEvent, ref: string): boolean => Object.hasOwn(event.refs, ref);

export const getFormattedAttribute = (
  attribute: string,
  type: string,
) => (
  auditTrailAttributeCreators[type] && auditTrailAttributeCreators[type][attribute]
    ? auditTrailAttributeCreators[type][attribute]()
    : attribute
);

export const getParticipantName = (participant: AgreementParticipant) => (
  participant?.fullname || participant?.name || participant?.partyName
);

export const getFullnameList = (
  agreement: Agreement,
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const participantNames = [];
  const { participants } = event;
  const hasParticipantRole = eventHasRef(event, 'participantRoles');
  const participantRolesObj = getRef(event, 'participantRoles') || {};

  participants?.forEach((participant) => {
    const participantId = participant.id;
    const participantName = participant.name;
    const party = getPartyByParticipantId(agreement, participantId);
    if (!party) {
      return;
    }
    if (isIndividual(party)) {
      participantNames.push(participantName);
      return;
    }

    if (isSignatory(participant)) {
      participantNames.push(message({
        id: '{name} as a signatory',
        values: { name: participantName },
        comment: 'Participant role information in audit trail entry',
      }));
      return;
    }

    if (isOrganizer(participant)) {
      participantNames.push(message({
        id: '{name} as an organizer',
        values: { name: participantName },
        comment: 'Participant role information in audit trail entry',
      }));
      return;
    }

    if (isViewer(participant)) {
      participantNames.push(message({
        id: '{name} as a viewer',
        values: { name: participantName },
        comment: 'Participant role information in audit trail entry',
      }));
      return;
    }

    if (isInfluencer(participant)) {
      let isPendingStateApprover = false;
      if (hasParticipantRole) {
        const participantIdKey = String(participantId);
        const rolesArray = participantRolesObj[participantIdKey];
        if (Array.isArray(rolesArray) && rolesArray[0] === 'PENDING_STATE_APPROVER') {
          isPendingStateApprover = true;
        }
      }

      if (isPendingStateApprover) {
        participantNames.push(participantName);
      } else {
        participantNames.push(message({
          id: '{name} as an influencer',
          values: { name: participantName },
          comment: 'Participant role information in audit trail entry',
        }));
      }
      return;
    }

    participantNames.push(participantName);
  });

  return participantNames;
};

export const getFormattedValue = (
  attribute: string, value: string | number, message: MessageTranslator, lang?: EnabledLanguages,
) => {
  const numericValue: number = isString(value)
    ? parseInt(value, 10)
    : value;
  switch (attribute) {
    case 'type':
      return auditTrailTypesCreators(numericValue)();

    case 'roles': // for draft approvers;
    case 'role':
      return auditTrailRolesCreators(numericValue)();

    case 'sign_method':
      return auditTrailSignMethodCreators(numericValue)();

    case 'sign_method_short':
      return auditTrailSignMethodShortCreators(numericValue)();

    case 'mfa_channel':
      return auditTrailMfaChannelCreators(value)();

    case 'country':
    case 'company_country':
      return countries.getName(value, lang || 'en');

    case 'notice_period':
    case 'duration':
    case 'initial_duration':
      return (value === null || value === undefined)
        ? null
        : formatTimePeriod(value, message);

    case 'delivery_channel':
      return auditTrailDeliveryChannelCreators(numericValue)();
    default:
      return value;
  }
};

export const hasNoParent = (event: AgreementEvent) => event.parent === null;
