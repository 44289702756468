/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import {
  isPending,
  isOverdue,
  isConcluded,
  isTemplate,
  getStatus,
} from 'agreement/states';

import { isSmallScreenWidth } from 'ui/config';

import useAgreement from 'hooks/use-agreement';

import { checkAcl } from 'components/acl';
import FailureToast from 'components/failure-toast';

import UnpublishedDocumentExpiryPopover from './unpublished-document-expiry-popover';
import PublishedDocumentExpiryDatePopover from './published-document-expiry-date-popover';

import { getExpiryTypeOptions } from './helpers/builders';

function SigningPeriodExpiryDateSection(props) {
  const { agreementId } = props;

  const [failureReason, setFailureReason] = useState('');
  const agreement = useAgreement(agreementId);
  const expireDate = agreement?.expireDate;
  const expireDateDays = agreement?.config?.expireDateDays;
  const dateFormat = agreement?.config?.dateFormat;
  const updateAllowedProperties = {
    expireDate: checkAcl(agreement.acl, 'agreement:update:expire_date'),
    expireDateDays: checkAcl(agreement.acl, 'agreement:update:config:expire_date_days'),
  };
  const isAgreementTemplate = isTemplate(agreement);
  const readOnly = !updateAllowedProperties.expireDate && !updateAllowedProperties.expireDateDays;
  const expiryOptions = getExpiryTypeOptions({
    updateAllowedProperties,
    isTemplateDocument: isAgreementTemplate,
  });

  let sideOffset = null;
  // Hard coded now (side navigation width / 2)
  // And we have to use fixed positioning and center based on contract content view
  // with sidebar navigation visible in the left not based on viewport
  // Sidebar component uses isMobile (window.innerWidth) to decide switching to mobile view
  const isMobile = isSmallScreenWidth();
  if (document.getElementById('agreement-navigation') && !isMobile) {
    sideOffset = 36;
  }

  let failureToast = (
    <FailureToast.DocumentView
      reason={failureReason}
      close={() => setFailureReason('')}
      portalTarget={document.getElementById('toast')}
      sideOffset={sideOffset}
    />
  );

  if (!failureReason) {
    failureToast = null;
  }

  if (isEmpty(agreement) || isConcluded(agreement)) {
    return null;
  }

  if (isPending(agreement) || isOverdue(agreement)) {
    return (
      <PublishedDocumentExpiryDatePopover
        agreementId={agreementId}
        status={getStatus(agreement)}
        expireDate={expireDate}
        dateFormat={dateFormat}
        readOnly={readOnly}
        failureToast={failureToast}
        setFailureReason={setFailureReason}
      />
    );
  }

  return (
    <UnpublishedDocumentExpiryPopover
      agreementId={agreementId}
      expireDate={expireDate}
      dateFormat={dateFormat}
      expireDateDays={expireDateDays}
      expiryOptions={expiryOptions}
      readOnly={readOnly}
      failureToast={failureToast}
      setFailureReason={setFailureReason}
      placeholder={isAgreementTemplate ? dateFormat : null}
    />
  );
}

export default SigningPeriodExpiryDateSection;
