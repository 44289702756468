// @flow

import React, { useEffect, useCallback, useMemo } from 'react';
import { debounce, isArray } from 'lodash';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import { OPERATOR_AND } from 'oneflow-client/core';
import { SYSTEM_TAG_ID } from 'tags/constants';
import tagsReducer from 'reducers/entities/tags';
import { useSelector, useDispatch } from 'react-redux';
import { getTagName } from 'components/tags/tag-name';
import FilterSelectField from 'components/filter-select-field';
import FilterValues from 'components/filter-values/filter-values';
import CheckboxGroup from 'components/checkbox-group';
import usePreviousUntilValuesChanged from 'hooks/use-previous-until-values-changed';
import { Filter } from 'hocs/search';
import { operatorSanitizer, noTagSanitizer } from 'agreement/sanitizers';
import style from './tag-selector.module.scss';

type Props = {
  onChange: (value: Array<number> | null) => void,
  setOptions: Function,
  value: Array<number> | null,
  message: MessageTranslator,
};

const TAGS_QUERY_NAME = 'tags-selector';
const SELECTED_TAGS_QUERY_NAME = 'selected-tags-selector';
export const LIMIT = 50;

export const TagSelector = ({
  onChange, setOptions, value, message,
}: Props) => {
  const dispatch = useDispatch();

  const defaultSearchMessage = message({
    id: 'Search tags',
    comment: 'The placeholder for the tags filter search textbox',
  });

  const selectedTagIds = usePreviousUntilValuesChanged(value || []);

  const tagsQueryState = useSelector((state) => (
    tagsReducer.getQuerySelector(state, { name: TAGS_QUERY_NAME })));

  const filterableTags = useSelector((state) => (
    tagsReducer.getTagsSelector(state, { ids: tagsQueryState.result })));

  const selectedTags = usePreviousUntilValuesChanged(useSelector((state) => (
    tagsReducer.getTagsSelector(state, { ids: selectedTagIds }))));

  const queryAllTags = useCallback((searchValue) => {
    const params = {};

    if (searchValue) {
      params.q = searchValue;
    }

    dispatch(tagsReducer.queryTags({
      name: TAGS_QUERY_NAME,
      pagination: {
        offset: 0,
        limit: LIMIT,
      },
      params,
    }));
  }, [dispatch]);

  const queryTagsLoadMore = (additionalResults) => (
    dispatch(tagsReducer.queryTagsLoadMore({
      name: TAGS_QUERY_NAME,
      additionalResults,
    }))
  );

  const querySelectedTags = useCallback((ids: Array<number>) => (
    dispatch(tagsReducer.queryTags({
      name: SELECTED_TAGS_QUERY_NAME,
      pagination: {
        offset: 0,
        limit: 10000,
      },
      params: {
        ids,
      },
    }))
  ), [dispatch]);

  const getSelectedTags = useCallback(() => selectedTags, [selectedTags]);

  const getFilterableTags = useCallback(() => filterableTags, [filterableTags]);

  /*
   * On initial render, we have the ids of the selected tags but their values are not loaded
   * In order to be able to display the selected tags as pills, we need to query their values
   * to get the labels and render them on top of the tag selector
  */
  useEffect(() => {
    const notLoadedTagsIds = selectedTagIds.filter(
      (id) => !selectedTags.some((tag) => tag.id === id),
    );

    if (notLoadedTagsIds.length > 0) {
      querySelectedTags(notLoadedTagsIds);
    }

  // we don't need to run this effect every time selectedTagIds changes, because
  // when the user selects a tag, it means that the entity has been loaded and we don't
  // need to fetch it again
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tagsQueryState.status === 'success') {
      const tagsSelected = getSelectedTags();
      setOptions(tagsSelected);
    }
  }, [tagsQueryState.status, getSelectedTags, setOptions]);

  const mapToOption = useCallback((tag: Tag) => {
    const isSystemTag = tag.system === SYSTEM_TAG_ID;

    if (isSystemTag) {
      return {
        value: tag.id,
        label: getTagName(tag, message),
        customValueStyle: style.SystemTagValue,
        customOptionStyle: style.SystemTagOption,
      };
    }

    return {
      value: tag.id,
      label: getTagName(tag, message),
    };
  }, [message]);

  const noTagOnValueChange = (noTag: void | Array<number>, updateParams: any) => {
    let params = {
      noTag,
    };

    if (isArray(noTag) && noTag[0] === 1) {
      params = {
        ...params,
        tagOperator: null,
        tags: null,
      };
    }

    updateParams(params);
  };

  const onSearchValueChange = useMemo(() => debounce((input) => {
    queryAllTags(input);
  }, 300), [queryAllTags]);

  return (
    <div className={style.TagSelectorContainer}>
      <FilterValues
        onChange={onChange}
        selectedValues={getSelectedTags().map(mapToOption)}
      />
      {selectedTags.length >= 1 && (
      <div className={style.CheckboxWrapper}>
        <Filter
          param="tagOperator"
          defaultValue={[]}
          onSanitize={operatorSanitizer}
          options={[{
            value: OPERATOR_AND,
            label: message({
              id: 'Match all tags',
              comment: 'Checkbox allowing user to search the combined results of multiple selected tags.',
            }),
          }]}
          component={CheckboxGroup}
          wrap={false}
          disabled={!selectedTags || selectedTags.length < 2}
          tooltipMessage={message({
            id: 'Select at least 2 tags to show documents containing all selected tags',
            comment: 'Tooltip message explaining what the match all tags checkbox does and how to use it.',
          })}
        />

      </div>
      )}
      <FilterSelectField
        placeholder={defaultSearchMessage}
        loadingMessage={() => (
          <>
            {message({
              id: 'Loading',
              comment: 'The loading message',
            })}
            ...
          </>
        )}
        isLoading={tagsQueryState.loading}
        isSearchable
        onChange={onChange}
        selectedValues={getSelectedTags().map(mapToOption)}
        options={getFilterableTags().map(mapToOption)}
        loadMoreItems={queryTagsLoadMore}
        name="tagSelectField"
        onInputChange={onSearchValueChange}
        ariaLabel={defaultSearchMessage}
        onMenuOpen={queryAllTags}
        totalOptionsCount={tagsQueryState.count}
      />
      <div className={style.CheckboxWrapper}>
        <Filter
          param="noTag"
          defaultValue={[]}
          options={[
            {
              value: 1,
              label: message({
                id: 'No tags',
                comment:
                'Checkbox allowing user to search the combined results of multiple selected tags.',
              }),
            },
          ]}
          onSanitize={noTagSanitizer}
          component={CheckboxGroup}
          onValueChange={noTagOnValueChange}
          wrap={false}
        />
      </div>
    </div>
  );
};

export default localize<Props>(TagSelector);
