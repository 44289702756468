// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getRoles,
  updateRole,
  createRole,
  removeRole,
} from 'oneflow-client/roles';
import type { NormalizedRoles } from 'oneflow-client/roles';

import apiWrapper from 'sagas/api-wrapper';

import roles from 'reducers/entities/roles';
import permissions from 'reducers/entities/permissions';

type MapperArgs = {
  data: NormalizedRoles,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* updateMapper({ data }: MapperArgs): Mapper {
  yield put(roles.setRoles(data.entities.roles));
}

export function* removeMapper({ action }: MapperArgs): Mapper {
  yield put(roles.clearRole({ id: action.id }));
}

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(permissions.setPermissions(data.entities.permissions));
  yield put(roles.setRoles(data.entities.roles));
}

const mappers = {
  query: {
    mapper,
    request: getRoles,
  },
  update: {
    mapper: updateMapper,
    request: updateRole,
  },
  create: {
    mapper: updateMapper,
    request: createRole,
  },
  remove: {
    mapper: removeMapper,
    request: removeRole,
  },
};

const rolesSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: roles,
  mappers,
});

export default rolesSaga;
