import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import ReactCountryFlag from 'react-country-flag';
import clsx from 'clsx';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
} from 'recharts';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import { getCurrentLanguageSelector } from 'reducers/i18n';

import { AiInsightsModal } from 'components/modals/ai-insights';
import {
  getGoverningLawTranslation,
  darkenColor,
  isStaticDataPoint,
  isWarning,
} from '../utils';
import {
  UNTIL_TERMINATED,
  MISSING,
  UNSPECIFIED,
  CONTRACT_NOT_ANALYSED,
  CONTRACT_NOT_ANALYSED_COLOR,
  BREACH_COLOR,
  NON_BREACH_COLOR,
  WARNING_COLOR,
  COUNTRY_OF_SELLER,
} from '../constants';
import type { InsightDataItem } from '../../types';

import style from './vertical-bar.module.scss';

export const getLabel = (label: string, message: MessageTranslator, currentLanguage: string) => {
  if (label === UNTIL_TERMINATED) {
    return message({
      id: 'Until terminated',
      comment: 'Label for the bar chart data',
    });
  }
  if (label === MISSING) {
    return message({
      id: 'Missing',
      comment: 'Label for the bar chart data',
    });
  }
  if (label === UNSPECIFIED) {
    return message({ id: 'Unspecified', comment: 'Label showed in the chart legend for data that could not be classified to any category' });
  }
  if (label === CONTRACT_NOT_ANALYSED) {
    return message({ id: 'Not analysed', comment: 'Label for the total contracts' });
  }

  // Parse a duration string in the ISO8601 format ("P2Y5M2D" => 2 years, 5 months, and 2 days)
  const regex = /^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?$/;
  const matches = label.match(regex);

  if (matches) {
    const parts = [];
    if (matches[1]) {
      parts.push(message({
        id: '{yearCount} year',
        pluralId: '{yearCount} years',
        pluralCondition: 'yearCount',
        values: { yearCount: matches[1] },
        comment: 'Label for the payment term duration',
      }));
    }
    if (matches[2]) {
      parts.push(message({
        id: '{monthCount} month',
        pluralId: '{monthCount} months',
        pluralCondition: 'monthCount',
        values: { monthCount: matches[2] },
        comment: 'Label for the payment term duration',
      }));
    }
    if (matches[3]) {
      parts.push(message({
        id: '{dayCount} day',
        pluralId: '{dayCount} days',
        pluralCondition: 'dayCount',
        values: { dayCount: matches[3] },
        comment: 'Label for the payment term duration',
      }));
    }
    return parts.join(', ');
  }

  // Regions
  if (label === COUNTRY_OF_SELLER) {
    return message({ id: 'Country of seller', comment: 'Label for the governing law country' });
  }

  return getGoverningLawTranslation(label, currentLanguage, message);
};

type Entry = {
  label: string,
  count: number,
  isBreach: boolean,
  breachSeverity: string,
  labelKey: string,
};

const getFillColor = (entry: Entry, hoveredDataPoint: string) => {
  let originalColor = NON_BREACH_COLOR;

  if (entry.isBreach) {
    originalColor = BREACH_COLOR;
  }

  if (isWarning(entry.breachSeverity)) {
    originalColor = WARNING_COLOR;
  }

  if (entry.isBreach === null) {
    return CONTRACT_NOT_ANALYSED_COLOR;
  }

  if (hoveredDataPoint === entry.labelKey) {
    if (isStaticDataPoint(entry.labelKey)) {
      return originalColor;
    }
    return darkenColor(originalColor, -5);
  }

  return originalColor;
};

type YAxisLabelProps = {
  x: number,
  y: number,
  payload: { value: string, index: number },
  chartData: { label: string, count: number, isBreach: boolean, labelKey: string }[],
  message: MessageTranslator,
  onLabelClick: (label: string) => void,
};
const CustomYAxisLabel = ({
  x, y,
  payload,
  chartData,
  message,
  onLabelClick,
}: YAxisLabelProps) => {
  const { value, index } = payload;
  const { count, labelKey } = chartData[index];

  const getFlag = () => {
    const notCountry = [UNSPECIFIED, MISSING, CONTRACT_NOT_ANALYSED, 'NORD', 'EU'];
    if (
      notCountry.includes(labelKey)
      || /\d/.test(labelKey) // has a digit
    ) {
      return null;
    }

    const isAmericanState = labelKey.startsWith('US-');
    const isCanadianProvince = labelKey.startsWith('CA-');
    const flagProps = { svg: true, style: { borderRadius: 4 } };

    if (isAmericanState) {
      return <ReactCountryFlag {...flagProps} countryCode="US" />;
    }
    if (isCanadianProvince) {
      return <ReactCountryFlag {...flagProps} countryCode="CA" />;
    }

    return <ReactCountryFlag {...flagProps} countryCode={labelKey} />;
  };

  return (
    <g transform={`translate(${x - 300},${y})`}>
      <foreignObject x={0} y={-9} width={300} height={30}>
        <button
          onClick={() => onLabelClick(labelKey)}
          className={clsx(style.YAxisLabelButton, {
            [style.Static]: isStaticDataPoint(labelKey),
          })}
        >
          <span className={style.Label}>
            {getFlag()}
            {value}
          </span>
          <span className={style.DocumentCount}>
            {message({
              id: '{count} document',
              pluralId: '{count} documents',
              pluralCondition: 'count',
              comment: 'Label for the bar chart data',
              values: { count: count || 0 },
            })}
          </span>
        </button>
      </foreignObject>
    </g>
  );
};

const barSize = 16;
const barGap = 12;

type Props = {
  data: any,
  message: MessageTranslator,
  filterParams: any,
  title: string,
  chartName: string,
};

const VerticalBarChartComponent = ({
  data,
  message,
  filterParams,
  title,
  chartName,
}: Props) => {
  const [selectedDataPoint, setSelectedDataPoint] = useState<string | null>(null);
  const [hoveredDataPoint, setHoveredDataPoint] = useState<string | null>(null);
  const currentLanguage = useSelector(getCurrentLanguageSelector);

  const getChartData = useCallback((dataArray: InsightDataItem[]) => dataArray
    .map((item) => ({
      label: getLabel(item.label, message, currentLanguage),
      labelKey: item.label,
      count: item.count,
      isBreach: item.isBreach,
      breachSeverity: item.breachSeverity,
    })).sort((a, b) => {
      if (a.labelKey === CONTRACT_NOT_ANALYSED) {
        return 1;
      }
      if (b.labelKey === CONTRACT_NOT_ANALYSED) {
        return -1;
      }
      return b.count - a.count;
    }), [message, currentLanguage]);

  const chartData = getChartData(data.data);

  const handleMouseEnter = useCallback((key: string) => {
    setHoveredDataPoint(key);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredDataPoint(null);
  }, []);

  const handleSegmentClick = useCallback((label: string) => {
    if (isStaticDataPoint(label)) return;

    setSelectedDataPoint(label);
    amplitudeLogEvent('Go To AI Insights Contract List', {
      'chart type': chartName,
      location: 'ai insights tab - chart segment',
    });
  }, [chartName]);

  if (!data) {
    return null;
  }

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height={chartData.length * (barSize + barGap) + 40}
        className={style.Container}
      >
        <BarChart
          data={chartData}
          layout="vertical"
          margin={{
            left: 200,
            right: 200,
          }}
          barGap={barGap}
        >
          <XAxis
            type="number"
            stroke="#587a85"
            axisLine={false}
            tickLine={false}
            tick={false}
          />
          <YAxis
            type="category"
            dataKey="label"
            stroke="#587a85"
            tickLine={false}
            axisLine={false}
            tick={(props) => (
              <CustomYAxisLabel
                {...props}
                chartData={chartData}
                message={message}
                onLabelClick={handleSegmentClick}
              />
            )}
            width={150}
          />
          <Bar
            dataKey="count"
            barSize={barSize}
            radius={2}
          >
            {
                chartData.map((entry) => (
                  <Cell
                    key={`cell-${entry.label}`}
                    fill={getFillColor(entry, hoveredDataPoint)}
                    onMouseEnter={() => handleMouseEnter(entry.labelKey)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      handleSegmentClick(entry.labelKey);
                    }}
                    style={{
                      cursor: isStaticDataPoint(entry.labelKey) ? 'default' : 'pointer',
                    }}
                  />
                ))
              }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {selectedDataPoint && (
        <AiInsightsModal
          onClose={() => setSelectedDataPoint(null)}
          filterParams={filterParams}
          clickedLabel={{
            key: selectedDataPoint, value: getLabel(selectedDataPoint, message, currentLanguage),
          }}
          clickedInsightType={{ key: data.type.toLowerCase(), value: title }}
        />
      )}
    </>
  );
};

export const VerticalBarChart = localize(VerticalBarChartComponent);
