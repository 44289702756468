import { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { getGuestToken } from 'agreement/selectors';
import { isConcluded } from 'agreement';

import useAgreement from 'hooks/use-agreement';
import { AsyncAssetPropsProvider } from 'contexts/async-asset-props';

import { isContractEditable } from 'components/document-layout-container/helpers';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import BoxList from 'components/box-list';
import ContractToolbar from 'components/document-topbar/topbar/contract-toolbar';
import DocumentStateBanner from 'components/document-state-banner';
import AvailableContentInlineSizeDetector from 'components/available-content-inline-size-detector';
import CollapsedDocumentTopBar from 'components/document-topbar/collapsed/collapsed-document-topbar';
import EditWarningModal, { shouldShowEditWarningModal } from 'components/modals/edit-warning';
import { getResetKey, getBannerVisibility } from 'reducers/current-contract';

import style from './document-tab.module.scss';

type Props = {
  agreementId: Oneflow.Document['id']
}

const DocumentTab = ({ agreementId }: Props) => {
  const guestToken = useSelector(getGuestToken);
  const resetKey = useSelector(getResetKey);
  const agreement = useAgreement(agreementId);
  const showBanner = useSelector(getBannerVisibility);
  const { documentScrollContainerRef } = useDocumentLayout();
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const showToolbar = !guestToken
    && isContractEditable(agreement) && !isConcluded(agreement);
  const isBannerAvailableAndVisible = showBanner && isBannerVisible;
  const isToolbarAndBannerVisible = showToolbar && isBannerAvailableAndVisible;
  const documentTopbarClasses = clsx(style.DocumentTopContainer, {
    [style.ShowToolbar]: showToolbar,
    [style.ShowBanner]: isBannerAvailableAndVisible,
    [style.ShowBannerAndToolbar]: isToolbarAndBannerVisible,
  });
  const toolbarClasses = clsx({
    [style.Toolbar]: showToolbar,
  });
  const documentClasses = clsx(style.Document, {
    [style.ShowToolbar]: showToolbar,
    [style.ShowBanner]: isBannerAvailableAndVisible,
    [style.ShowBannerAndToolbar]: isToolbarAndBannerVisible,
  });

  const [showTemplateEditWarningModal, setShowTemplateEditWarningModal] = useState(false);
  const [hasShownTemplateEditWarningModal, setHasShownTemplateEditWarningModal] = useState(false);
  const shouldOpenWarningModal = shouldShowEditWarningModal(agreement);

  const handleOnFocus = () => {
    if (shouldOpenWarningModal && !hasShownTemplateEditWarningModal) {
      setShowTemplateEditWarningModal(true);
      setHasShownTemplateEditWarningModal(true);
    }
  };

  return (
    <div className={style.DocumentTab}>
      <div className={documentTopbarClasses}>
        <div className={style.DocumentTopbar}>
          <CollapsedDocumentTopBar agreementId={agreement.id} />
        </div>
        <div className={toolbarClasses}>
          {!guestToken && (
            <ContractToolbar
              agreement={agreement}
              isContractEditable={isContractEditable(agreement)}
            />
          )}
        </div>
        <div className={style.DocumentStateBanner}>
          <DocumentStateBanner
            ref={documentScrollContainerRef}
            agreement={agreement}
            setIsBannerVisible={setIsBannerVisible}
          />
        </div>
      </div>
      <div ref={documentScrollContainerRef} className={documentClasses}>
        <div className={style.DocumentContentContainer}>
          <AvailableContentInlineSizeDetector>
            <EditWarningModal
              isOpen={showTemplateEditWarningModal}
              onCancel={() => {
                setShowTemplateEditWarningModal(false);
              }}
              shouldReturnFocusAfterClose={false}
            />
            <AsyncAssetPropsProvider>
              <BoxList
                agreementId={agreementId}
                onFocus={handleOnFocus}
                key={resetKey}
              />
            </AsyncAssetPropsProvider>
          </AvailableContentInlineSizeDetector>
        </div>
      </div>
    </div>
  );
};

export default DocumentTab;
