// @flow

import { connect } from 'react-redux';

import AdminComponent from './admin';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminComponent);
