import { Message } from '@oneflowab/pomes';
import { getUndecidedSignatories, getAgreementMyParticipant } from 'agreement/selectors';
import { getUndecidedPendingStateApprovers } from 'agreement/pending-state-flow';

import Confirmable from 'components/confirmable';
import Button from 'components/button';
import ApprovalCheck from './approval-check.gif';

import style from './approved-pending-document.module.scss';

export type Props = {
  waitingForMoreApprovals?: boolean;
  onClose: () => void;
  isOpen: boolean;
  agreement: Oneflow.Agreement;
};

const modalKey = 'approved pending document modal';

export const ApprovedPendingDocumentModal = ({
  waitingForMoreApprovals,
  onClose,
  isOpen,
  agreement,
}: Props) => {
  const myParticipant = getAgreementMyParticipant(agreement);
  const unDecidedApprovers = getUndecidedPendingStateApprovers(agreement);

  const getSignatoriesNames = () => {
    const undecidedSignatories = getUndecidedSignatories(agreement);

    const names = undecidedSignatories
      .map((participant) => participant?.fullname);

    return names;
  };

  const getPendingStateApproversNames = () => {
    if (unDecidedApprovers === null) {
      return null;
    }

    const externalApproversWithoutMe = unDecidedApprovers
      .filter((approver) => approver?.id !== myParticipant?.id);

    const names = externalApproversWithoutMe
      .map((participant) => participant?.fullname);

    return names;
  };

  const signatories = getSignatoriesNames();
  const pendingApprovers = getPendingStateApproversNames();

  const getParticipantNames = (names) => {
    const and = (
      <Message
        id="and"
        comment="Word used to separate two words."
      />
    );

    if (names === null || names.length === 0) {
      return null;
    }

    if (names.length === 1) {
      return (
        <strong>
          {names[0]}
        </strong>
      );
    }

    if (names.length === 2) {
      return (
        <>
          <span className={style.Name}>
            {names[0]}
          </span>
          {and}
          <span className={style.LastParticipant}>
            {names[1]}
          </span>
        </>
      );
    }

    const lastParticipant = <span className={style.LastParticipant}>{names.pop()}</span>;
    const participantNames = names.map((name: string, index: number) => (
      <span className={style.Name} key={name}>
        {name}
        {index !== names.length - 1 && ','}
      </span>
    ));

    return (
      <>
        {participantNames}
        {and}
        {lastParticipant}
      </>
    );
  };

  const renderApproverAndSignatoryText = () => {
    if (signatories.length === 0) {
      return (
        <p className={style.ConfirmText}>
          <Message
            id="Waiting for {approver} to approve."
            comment="The message shown in the success modal about awaiting approvals."
            values={{
              approver: getParticipantNames(pendingApprovers),
              signatory: getParticipantNames(signatories),
            }}
          />
        </p>
      );
    }

    return (
      <p className={style.ConfirmText}>
        <Message
          id="Waiting for {approver} to approve and {signatory} to sign. When all parties have approved and signed the document, you will be sent a link to the signed version."
          comment="The message shown in the success modal about awaiting approvals and signatories."
          values={{
            approver: getParticipantNames(pendingApprovers),
            signatory: getParticipantNames(signatories),
          }}
        />
      </p>
    );
  };

  const renderSignatoryText = () => {
    if (signatories.length === 0) {
      return null;
    }

    return (
      <p className={style.ConfirmText}>
        <Message
          id="Waiting for {signatory} to sign. When all parties have approved and signed the document, you will be sent a link to the signed version."
          comment="The message shown in the success modal about awaiting signatories."
          values={{
            signatory: getParticipantNames(signatories),
          }}
        />
      </p>
    );
  };

  const renderInformationText = () => {
    const successfullyApproved = (
      <h1 className={style.Title}>
        <Message
          id="You have successfully approved"
          comment="Text explaining that the document is approved by the user."
        />
      </h1>
    );

    if (waitingForMoreApprovals) {
      return (
        <>
          {successfullyApproved}
          {renderApproverAndSignatoryText()}
        </>
      );
    }

    return (
      <>
        {successfullyApproved}
        {renderSignatoryText()}
      </>
    );
  };

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    handleClose();
  };

  const renderHeader = () => (
    <Message
      id="Document approval"
      comment="Title for the document approval modal."
    />
  );

  const renderBody = () => (
    <div className={style.BodyContainer}>
      <img
        src={ApprovalCheck}
        height="160px"
        width="160px"
        alt="Approved"
      />
      <div className={style.BodyContainerText} data-testid="information-message">
        {renderInformationText()}
      </div>
    </div>
  );

  const getActions = () => (
    <div className={style.ConfirmButton}>
      <Button
        kind="primary"
        onClick={handleClose}
      >
        <Message
          id="Done"
          comment="Button label for confirming the document has been approved."
        />
      </Button>
    </div>
  );

  return (
    <Confirmable
      header={renderHeader()}
      body={renderBody()}
      actions={getActions}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      onClose={onClose}
      isOpen={isOpen}
      modalKey={modalKey}
      customBodyClass={style.Body}
    />
  );
};
