// @flow

const getEventList = (agreement: Agreement) => {
  if (!agreement || !agreement.events) {
    return [];
  }
  const parentEvents = agreement.events.filter((event) => !event.parent).reverse();
  const childEvents = agreement.events.filter((event) => event.parent !== null);
  childEvents.forEach((child) => {
    let parent = parentEvents.find((parentEvent) => parentEvent.id === child.parent?.id);
    if (!parent) {
      // May be a child of a child event
      parent = childEvents.find((childEvent) => childEvent.id === child.parent?.id);
    }
    if (parent && !Object.hasOwn(parent, 'childEvents')) {
      parent.childEvents = [];
    }
    if (!parent?.childEvents?.some((e) => e.id === child.id)) {
      parent?.childEvents?.push(child);
    }
  });

  return parentEvents;
};

export default getEventList;
