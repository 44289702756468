// @flow

import * as React from 'react';
import Warning from 'components/icons/warning';
import { Message } from '@oneflowab/pomes';
import style from './sidebar-error-message.module.scss';

const SidebarErrorMessage = () => (
  <div data-testid="sidebar-error-message" className={style.Error}>
    <Warning className={style.Icon} />
    <h3 className={style.Header}>
      <Message id="Something went wrong" comment="Error message title" />
    </h3>
    <div data-testid="error-message" className={style.Description}>
      <Message
        id="Due to an internal error, we couldn't load data."
        comment="Error message text"
      />
    </div>
    <div className={style.Contact}>
      <Message
        id="Please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Error message contact info."
      />
    </div>

  </div>
);

export default SidebarErrorMessage;
