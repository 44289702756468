/* eslint-disable import/named */
import * as React from 'react';
import { localize, Message, MessageTranslator } from '@oneflowab/pomes';
import get from 'lodash/get';

import { sendSignCode, SendSignCodeParams } from 'oneflow-client/agreements';
import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';
import Error from 'components/icons/error';
import Mobile from 'components/icons/mobile';
import { ApiError, getErrorMessage, unknownApiError } from 'components/api-error';
import ModalForm from 'hocs/modal-form';
import Field from 'components/field';
import TextField from 'components/text-field';
import {
  composeValidators,
  smsCodeValidator,
} from 'forms/validators';

import style from './sms-sign.module.scss';

export type SignCodeState = {
  error: any,
  success: boolean,
  loading: boolean,
  data: any
};

type State = {
  signCodeState: SignCodeState,
  signError: boolean
};

export type ModalData = {
  didNotReceiveCode: boolean,
  phoneNumber: string,
  signCode: string
};

export type Props = {
  agreement: Agreement,
  guestToken?: string,
  message: MessageTranslator,
  modalData: ModalData,
  myParticipantWhenSignatory: AgreementParticipant,
  onClose: () => void,
  onPreviousStep: () => void,
  onStepComplete: () => void,
  onSyncStepData: (arg1: ModalData) => void,
  onSuccess: () => void,
  rpcState: RpcState,
  resetRpcState: () => void,
  // eslint-disable-next-line react/no-unused-prop-types
  sendChecksum?: boolean,
  signAgreement: any,
  modalKey: string,
};

class SmsSignComponent extends React.PureComponent<Props, State> {
  static defaultProps = {
    sendChecksum: undefined,
    guestToken: undefined,
  };

  state = {
    signError: false,
    signCodeState: {
      error: undefined,
      success: false,
      loading: false,
      data: null,
    },
  };

  validations = ((
    {
      message,
    }: Props,
  ) => ({
    smsCode: composeValidators(
      smsCodeValidator({
        message,
      }),
    ),
  }))(this.props);

  componentDidMount() {
    this.fetchSignCode();
  }

  componentDidUpdate() {
    const { rpcState } = this.props;

    if (rpcState.success) {
      this.handleSuccess();
    }
  }

  changeSignErrorStatus = () => () => {
    const { signError } = this.state;
    this.setState({ signError: !signError });
  }

  getActions = ({
    formProps,
  }: FormRenderProps) => {
    const { signCodeState } = this.state;
    const { rpcState } = this.props;
    const hasError = signCodeState.error || rpcState.error;
    const isDisabled = Boolean(
      formProps.validating
      || formProps.invalid
      || hasError,
    );

    return (
      <div className={style.Buttons}>
        <Button
          onClick={this.handleOnPreviousStep}
          customClass={style.LinkButton}
          disabled={hasError}
        >
          <Message id="Didn't receive a code?" comment="Used as the text of the sign button in modal" />
        </Button>
        <Button
          kind="document-sign"
          data-testid="confirm"
          icon={rpcState.loading ? CircularSpinner : undefined}
          disabled={isDisabled}
          onClick={formProps.handleSubmit}
          customClass={style.SignButton}
        >
          <Message id="Sign" comment="Used as the text of the sign button in modal" />
        </Button>
      </div>
    );
  };

  resetFormState = () => {
    this.setState({
      signCodeState: {
        error: undefined,
        success: false,
        loading: false,
        data: null,
      },
    });
  }

  getErrorMessage = (error: any) => {
    const errorCode = get(error, 'body.api_error_code');
    const errorMessage = getErrorMessage(errorCode);

    if (!errorMessage) {
      return unknownApiError;
    }

    return errorMessage;
  }

  renderApiError = () => {
    const { signCodeState } = this.state;
    if (!signCodeState || !signCodeState.error) {
      return null;
    }

    return (
      <ApiError customMessage={this.getErrorMessage(signCodeState.error)} />
    );
  };

  handleOnPreviousStep = () => {
    const { onPreviousStep, onSyncStepData } = this.props;
    let { modalData } = this.props;

    modalData = {
      ...modalData,
      didNotReceiveCode: true,
    };

    onSyncStepData(modalData);
    onPreviousStep();
  }

  handleSubmit = (formData: {
    didNotReceiveCode: boolean,
    phoneNumber: string,
    signCode: string
  }) => {
    const {
      myParticipantWhenSignatory,
      signAgreement,
      onSyncStepData,
      rpcState,
    } = this.props;

    if (rpcState.loading) {
      return;
    }

    onSyncStepData(formData);
    signAgreement(myParticipantWhenSignatory.id, formData.signCode);
  }

  handleSuccess = () => {
    const { onSuccess, onStepComplete } = this.props;

    onSuccess();
    onStepComplete();
  }

  async fetchSignCode() {
    const { signCodeState } = this.state;
    const {
      agreement,
      myParticipantWhenSignatory,
      modalData: { phoneNumber },
      guestToken,
      sendChecksum,
    } = this.props;

    if (!myParticipantWhenSignatory) {
      return null;
    }

    const params: SendSignCodeParams = {
      agreement,
      participantId: myParticipantWhenSignatory.id,
      phoneNumber,
      ssn: undefined,
      guestToken,
    };

    if (sendChecksum) {
      params.checksum = agreement.checksum;
    }

    try {
      this.setState({
        signCodeState: {
          ...signCodeState,
          loading: true,
        },
      });
      const data = await sendSignCode(params);

      this.setState({
        signCodeState: {
          ...signCodeState,
          loading: false,
          data,
        },
      });
    } catch (error: any) {
      this.setState({
        signCodeState: {
          ...signCodeState,
          loading: false,
          error,
        },
      });
    }

    return null;
  }

  renderBody() {
    const { signCodeState } = this.state;
    const { message, modalData: { phoneNumber }, rpcState } = this.props;

    if (rpcState.error) {
      return (
        <div className={style.SmsSignError}>
          <Error />
        </div>
      );
    }

    if (signCodeState.error) {
      return (
        <div className={style.SmsSignContainer}>
          {this.renderApiError()}
        </div>
      );
    }

    if (!signCodeState.data) {
      return this.renderLoadingIcon();
    }

    return (
      <div className={style.SmsSignContainer}>
        <h3>
          <Message
            id="Fill in the code and click 'Sign' to sign."
            values={{
              phoneNumber,
            }}
            comment="Modal text when waiting for user to input the SMS code that has been sent to their mobile phone."
          />
        </h3>
        <Message
          id="An SMS code has been sent to you on {phoneNumber}."
          values={{
            phoneNumber,
          }}
          comment="Modal text when waiting for user to input the SMS code that has been sent to their mobile phone."
        />
        <div className={style.SignCodeInputContainer}>
          <Field
            name="signCode"
            label={message({ id: 'SMS code', comment: 'Sms code label when signing the contract with sms code.' })}
            icon={<Mobile width="24px" height="24px" />}
            component={TextField}
            validate={this.validations.smsCode}
            hideLabel
            maxLength={50}
            required
          />
        </div>
        {signCodeState.error ? this.renderApiError() : null}
      </div>
    );
  }

  renderLoadingIcon = () => (
    <div className={style.Loading}>
      <CircularSpinner />
    </div>
  );

  render() {
    const {
      onClose,
      rpcState,
      resetRpcState,
      modalKey,
    } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Sign with SMS"
            comment="Modal title for waiting to sign with SMS."
          />
        )}
        body={this.renderBody()}
        onSubmit={this.handleSubmit}
        actions={this.getActions}
        formState={rpcState}
        resetFormState={this.resetFormState}
        isOpen
        onOpen={resetRpcState}
        onClose={onClose}
        modalKey={modalKey}
      />
    );
  }
}

export default localize<Props>(SmsSignComponent);
