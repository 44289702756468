// @flow

import React from 'react';
import { localize, MessageTranslator } from '@oneflowab/pomes';
import { last } from 'lodash';

import { hasAnyFailureStatus } from 'agreement/participant';

import Tooltip from 'components/tooltip';
import Alert from 'components/icons/alert';

import style from './contract-alert.module.scss';

type Props = {
  participants: Array<AgreementParticipant>,
  message: MessageTranslator,
};
type GetParticipantList = () => Array<AgreementParticipant>;

const ContractAlert = ({
  participants,
  message,
}: Props) => {
  const getFailedDeliveryParticipants: GetParticipantList = () => (
    participants.filter((participant) => hasAnyFailureStatus(participant))
  );

  const renderAlertHeader = () => (
    <div className={style.AlertHeader}>
      {message({
        id: 'Delivery failed',
        comment: 'Tooltip header. Shown when hovering a warning icon, to tell the user the contract has problems.',
      })}
    </div>
  );

  const renderAlertText = () => {
    const failedDeliveries = getFailedDeliveryParticipants();
    const numberOfFailures = failedDeliveries.length;

    if (!numberOfFailures) {
      return '';
    }

    const mapToFullname = (participant: AgreementParticipant) => (
      <strong key={participant.id}>
        {participant.fullname}
      </strong>
    );
    const joinByComma = (acc, curr) => (acc === null ? [curr] : [...acc, ', ', curr]);
    const commaSeparatedNames = failedDeliveries
      .slice(0, -1)
      .map(mapToFullname)
      .reduce(joinByComma, null);

    return message({
      id: 'Delivery failure for {name}.',
      pluralId: 'Delivery failure for {commaSeparatedNames} and {name}.',
      pluralCondition: 'numberOfFailures',
      values: {
        name: <strong>{last(failedDeliveries).fullname}</strong>,
        commaSeparatedNames: commaSeparatedNames || '',
        numberOfFailures,
      },
      comment: 'Tooltip message. Shown when hovering a warning icon, to tell the user the contract has problems.',
    });
  };

  if (!getFailedDeliveryParticipants().length) {
    return null;
  }

  return (
    <div className={style.ContractAlert}>
      <Tooltip
        header={renderAlertHeader()}
        message={renderAlertText()}
        side="top"
        sideOffset={1}
      >
        <Alert tabIndex={0} width="20px" height="20px" />
      </Tooltip>
    </div>
  );
};

export default localize<Props>(ContractAlert);
