import { Message } from '@oneflowab/pomes';

type Props = {
  signedDate: string,
  validUntil: string
};

const SignedAndCanceled = ({ signedDate, validUntil }: Props) => (
  <span>
    <Message
      id="Document was signed on {signedDate} and then canceled."
      values={{
        signedDate,
      }}
      comment="Text for a signed and canceled contract. Visible in contract."
    />
    {' '}
    <Message
      id="Document is valid until {validUntil}."
      values={{
        validUntil,
      }}
      comment="Text for the date the contract is valid until. Visible in contract."
    />
  </span>
);

export default SignedAndCanceled;
