// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import extensionSettings from 'hocs/extension-settings';
import { getMoment, addDaysToDate } from 'date';

import WarningIcon from 'components/icons/warning';
import { Table } from 'components/table';
import ActionsMenu from 'components/actions-menu';
import { DeleteMenuItem } from 'components/menu-items/delete';
import Button from 'components/button';
import Add from 'components/icons/add';
import Tooltip from 'components/tooltip';
import AddApiToken from 'components/modals/add-api-token';
import { RevokeApiTokenConfirmation } from 'components/modals/revoke-api-token';
import { ExtensionName } from 'components/extension/extension-name';
import { GeneratedApiToken } from 'components/extension/generated-token';
import { LocalizedDateTime } from 'components/localized-date-time';

import style from './api-tokens.module.scss';

export type Props = {
  queryApiTokens: () => void,
  apiTokens: Array<ApiToken>,
  apiTokensQuery: Query,
  message: MessageTranslator,
  extensionsById: {
    [?number]: Extension,
  },
  createdApiToken: ApiToken | {},
};

export class ApiTokensPage extends React.Component<Props> {
  componentDidMount() {
    const { queryApiTokens } = this.props;

    queryApiTokens();
  }

  getName = (apiToken: ApiToken) => {
    const { message } = this.props;

    // Either 365 days from last used, or 365 days from creation if never used
    const isTokenInactive = (
      (!apiToken.lastUsedTime && getMoment() > addDaysToDate(apiToken.createdTime, 365))
      || getMoment() > addDaysToDate(apiToken.lastUsedTime, 365)
    );

    return (
      <span>
        {apiToken.description}
        {isTokenInactive ? (
          <Tooltip
            message={message({
              id: 'API token has not been used in the past 365 days',
              comment: 'Tooltip warning on inactive tokens.',
            })}
            side="right"
          >
            <WarningIcon className={style.InactiveTokenWarning} width="16px" />
          </Tooltip>
        ) : null}
      </span>
    );
  };

  getActiveState = ({ active }: Webhook) => {
    if (active) {
      return (
        <Message
          id="Active"
          comment="Text explaining that the api token is active."
        />
      );
    }

    return (
      <Message
        id="Inactive"
        comment="Text explaining that the api token is inactive."
      />
    );
  };

  getUsedByLink = (apiToken: ApiToken) => {
    const { extensionsById } = this.props;
    const extensionUsingApiToken = extensionsById[apiToken.usedBy];

    if (!extensionUsingApiToken) {
      return '-';
    }

    return (
      <ExtensionName extension={extensionUsingApiToken} isLinked />
    );
  };

  getLastUsedDate = (apiToken: ApiToken) => {
    if (!apiToken.lastUsedTime) {
      return '-';
    }

    return (
      <LocalizedDateTime datetime={apiToken.lastUsedTime} />
    );
  };

  getCreatedDate = (apiToken: ApiToken) => (
    <LocalizedDateTime datetime={apiToken.createdTime} />
  );

  getDeleteMenuItem = (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
    />
  );

  getActions = (apiToken: ApiToken) => {
    const { message } = this.props;

    if (!apiToken.usedBy) {
      return (
        <ActionsMenu
          actions={[
            <RevokeApiTokenConfirmation apiToken={apiToken}>
              {this.getDeleteMenuItem}
            </RevokeApiTokenConfirmation>,
          ]}
          focusOnCloseDisabled
        />
      );
    }

    return (
      <Tooltip
        message={message({
          id: 'Not allowed to revoke API tokens created from other extensions',
          comment: 'Tooltip showing reason for not being allowed to remove API token.',
        })}
        side="top"
      >
        <ActionsMenu
          actions={[]}
          disabled
          focusOnCloseDisabled
        />
      </Tooltip>
    );
  };

  getTableConfig() {
    const { message, apiTokens } = this.props;

    const nameColumn = {
      name: 'name',
      label: message({
        id: 'Name',
        comment: 'Column label in api token list',
      }),
      type: 'cell',
      value: this.getName,
    };

    const activeColumn = {
      name: 'active',
      label: message({
        id: 'Active',
        comment: 'Column label in api token list',
      }),
      type: 'cell',
      value: this.getActiveState,
    };
    const usedByColumn = {
      name: 'usedBy',
      label: message({
        id: 'Used by',
        comment: 'Column label in api token list',
      }),
      type: 'cell',
      value: this.getUsedByLink,
    };
    const lastUsedColumn = {
      name: 'lastUsedTime',
      label: message({
        id: 'Last used',
        comment: 'Column label in api token list',
      }),
      type: 'cell',
      value: this.getLastUsedDate,
    };
    const createdColumn = {
      name: 'createdTime',
      label: message({
        id: 'Created',
        comment: 'Column label in api token list',
      }),
      type: 'cell',
      value: this.getCreatedDate,
    };
    const actionsColumn = {
      name: 'actions',
      label: message({
        id: 'Actions',
        comment: 'Column label in api token list',
      }),
      type: 'actions',
      value: this.getActions,
    };

    return {
      items: apiTokens,
      itemKey: 'id',
      actions: [],
      columns: [
        nameColumn,
        activeColumn,
        usedByColumn,
        lastUsedColumn,
        createdColumn,
        actionsColumn,
      ],
    };
  }

  getGenerateNewTokenButton: (onClick?: Function) => React.Node = (onClick) => (
    <Button
      data-testid="add-api-token"
      icon={Add}
      kind="primary"
      onClick={onClick}
    >
      <Message
        id="Generate a new token"
        comment="Button text for adding API token on API token list page."
      />
    </Button>
  );

  render() {
    const { apiTokensQuery, createdApiToken } = this.props;
    return (
      <div className={style.PageContainer}>
        <div>
          <AddApiToken>
            {this.getGenerateNewTokenButton}
          </AddApiToken>
        </div>
        <Table
          config={this.getTableConfig()}
          query={apiTokensQuery}
        />
        <GeneratedApiToken tokenEntity={createdApiToken} canRegenerateToken />
      </div>
    );
  }
}

export default extensionSettings(ApiTokensPage);
