import { useLayoutEffect } from 'react';

function useOutsideClick(ref, handler, when = true) {
  useLayoutEffect(() => {
    function isOutsideClick(event) {
      return ref && ref.current && !ref.current.contains(event.target);
    }

    function handleClick(event) {
      if (isOutsideClick(event)) {
        event.preventDefault();
        event.stopPropagation();

        handler(event);
      }
    }

    function handleKeyEvent(event) {
      if (['Esc', 'Escape'].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();

        handler(event);
      }
    }

    if (when) {
      document.addEventListener('click', handleClick);
      document.addEventListener('keydown', handleKeyEvent);
      return () => {
        document.removeEventListener('click', handleClick);
        document.removeEventListener('keydown', handleKeyEvent);
      };
    }

    return () => {};
  }, [ref, handler, when]);
}

export default useOutsideClick;
