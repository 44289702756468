// @flow

import {
  KEY_LIME,
} from 'extensions';

const Lime = {
  key: KEY_LIME,
};

export default Lime;
