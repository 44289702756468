// eslint-disable-next-line import/prefer-default-export
export const AIAssistantContext = {
  AGREEMENT_INLINE: 'agreementInline',
};

export const AIAssistantPrompts = {
  IMPROVE_WRITING: '[IMPROVE_WRITING]',
  MAKE_SHORTER: '[MAKE_SHORTER]',
  MAKE_LONGER: '[MAKE_LONGER]',
  CHANGE_TONE_PROFESSIONAL: '[CHANGE_TONE] professional',
  CHANGE_TONE_CASUAL: '[CHANGE_TONE] casual',
  CHANGE_TONE_STRAIGHT_FORWARD: '[CHANGE_TONE] straightforward',
  CHANGE_TONE_CONFIDENT: '[CHANGE_TONE] confident',
  CHANGE_TONE_FRIENDLY: '[CHANGE_TONE] friendly',
  SIMPLIFY_TEXT: '[SIMPLIFY]',
  SUMMARIZE_TEXT: '[SUMMARIZE]',
  TRANSLATE_TO_SWEDISH: '[TRANSLATE] Swedish',
  TRANSLATE_TO_ENGLISH: '[TRANSLATE] English',
  TRANSLATE_TO_NORWEGIAN: '[TRANSLATE] Norwegian',
  TRANSLATE_TO_DANISH: '[TRANSLATE] Danish',
  TRANSLATE_TO_GERMAN: '[TRANSLATE] German',
  TRANSLATE_TO_FINNISH: '[TRANSLATE] Finnish',
  TRANSLATE_TO_SPANISH: '[TRANSLATE] Spanish',
  TRANSLATE_TO_FRENCH: '[TRANSLATE] French',
  TRANSLATE_TO_DUTCH: '[TRANSLATE] Dutch',
  TRANSLATE_TO_PORTUGUESE: '[TRANSLATE] Portuguese',
  TRANSLATE_TO_ITALIAN: '[TRANSLATE] Italian',
  CONTINUE_WRITING: '[CONTINUE_WRITING]',
  TRY_AGAIN: '[TRY_AGAIN]',
};
