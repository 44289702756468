import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Sector,
} from 'recharts';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import values from 'lodash/values';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import type { ComponentProps } from 'react';

import TooltipInfo from 'components/tooltip-info';

import SignRateLegend from './legend';

import style from './sign-rate.module.scss';

const COLORS = ['#509dd1', '#3cc885', '#d13d47', '#ef9b4e'];

type Rate = {
  [key in keyof Oneflow.Dashboard['contractStat']]: number;
}

const renderActiveShape = (shapeProps: ComponentProps<typeof Sector> & { signRate: number }) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    signRate,
  } = shapeProps;

  return (
    <g>
      <text
        x="56%"
        y="50%"
        dy={10}
        textAnchor="middle"
        fill="#013a4c"
        style={{ fontSize: '2.5em', fontWeight: 500 }}
      >
        {`${signRate}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

type Props = {
  data: Oneflow.Dashboard,
  message: MessageTranslator,
};

const getTooltip = (message: MessageTranslator, rate: Rate) => ({
  state: message({
    id: 'Pending',
    comment: 'Used as a label for the state of the relevant part of rate',
  }) as string,
  percentage: rate.pending,
});

const formatHitRate = (message: MessageTranslator, rate: Rate) => ([
  getTooltip(message, rate),
  {
    state: message({
      id: 'Signed',
      comment: 'Used as a label for the state of the relevant part of rate',
    }) as string,
    percentage: rate.signed,
  },
  {
    state: message({
      id: 'Declined',
      comment: 'Used as a label for the state of the relevant part of rate',
    }) as string,
    percentage: rate.declined,
  },
  {
    state: message({
      id: 'Overdue',
      comment: 'Used as a label for the state of the relevant part of rate',
    }) as string,
    percentage: rate.overdue,
  },
]);

export const SignRateComponent = (props: Props) => {
  const {
    message,
    data,
  } = props;
  const contractStat = get(data, 'contractStat');
  const tooltip = message({
    id: 'Percentage of signed documents out of all sent documents during selected date range.',
    comment: 'Tooltip message',
  });

  const total = sumBy(values(contractStat), 'count');
  const rate = Object.keys(contractStat)
    .map((state) => (
      {
        key: state,
        percentage: Math.round((contractStat[state as keyof Oneflow.Dashboard['contractStat']].count * 100) / total),
      }
    ))
    .reduce((acc, cur) => ({
      ...acc,
      [cur.key]: cur.percentage,
    }), {} as Rate);

  const chartHitRateData = formatHitRate(message, rate);

  return (
    <div className={style.SignRate}>
      <div className={style.SignRateHeader}>
        <Message
          id="Sign rate"
          comment="Widget header on dashboard. 'Sign rate' as in, how many of the contract that we sent have been signed (alternative is 'hit rate')."
        />
        <TooltipInfo
          message={tooltip}
        />
      </div>
      <div className={style.Divider} />
      <ResponsiveContainer width="90%" height="65%">
        <PieChart>
          <Pie
            cx="55%"
            data={chartHitRateData}
            dataKey="percentage"
            nameKey="state"
            outerRadius="80%"
            innerRadius="45%"
            activeIndex={1}
            activeShape={(shapeProps: ComponentProps<typeof Sector>) => renderActiveShape({
              ...shapeProps,
              signRate: rate.signed,
            })}
          >
            {chartHitRateData.map((entry, index) => (
              <Cell key={entry.state} fill={COLORS[index]} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `${value}%`} />
        </PieChart>
      </ResponsiveContainer>
      <SignRateLegend />
    </div>
  );
};

export default localize<Props>(SignRateComponent);
