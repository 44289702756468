// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

export type Props = {|
  agreement: Agreement,
  removeState: RemoveState,
  resetRemoveState: Function,
  deleteTemplate: () => void,

  // eslint-disable-next-line react/no-unused-prop-types
  queryName?: string,
  children: Function,
|};

const DeleteTemplate = ({
  agreement,
  children,
  removeState,
  deleteTemplate,
  resetRemoveState,
}: Props) => (
  <RemoveConfirm
    onConfirm={deleteTemplate}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <>
        <Message
          id="You are about to delete {template}. This can't be undone."
          values={{
            template: <Badge label={agreement.name} kind="name" />,
          }}
          comment="Modal text when deleting a template."
        />
        <Message
          id="Existing drafts and contracts based on this template will not be affected."
          pluralId="Existing drafts and contracts based on these templates will not be affected."
          pluralCondition="count"
          values={{ count: 1 }}
          comment="Modal text when deleting a template."
        />
      </>
    )}
    modalKey="delete template modal"
  >
    {children}
  </RemoveConfirm>
);

DeleteTemplate.defaultProps = {
  queryName: undefined,
};

export default DeleteTemplate;
