import { useMemo, useCallback } from 'react';
import type { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormRenderProps } from 'react-final-form';
import debounce from 'lodash/debounce';
import { Message } from '@oneflowab/pomes';

import rolesReducer from 'reducers/entities/roles';
import userAccountsReducer from 'reducers/entities/user-accounts';
import accountUsersReducer from 'reducers/entities/account-users';
import { getAccountFromSessionSelector } from 'reducers/session';
import ModalForm from 'hocs/modal-form';
import { getAccountUsersQueryName } from 'routes/admin/account-access/account-access';

import Field from 'components/field';
import SelectField from 'components/select-field';
import { ConfirmButton } from 'components/buttons';
import { CancelButton } from 'components/buttons/cancel';
import { USER_ROLE_SCOPE_ACCOUNT } from 'user';

import styles from './edit-user-account-access.module.scss';

export const rolesQueryName = 'admin/user/accounts';
const DEFAULT_PAGINATION = {
  limit: 50,
  offset: 0,
};

type Props = {
  position: Oneflow.Position,
  binding: Oneflow.AccountUserRoleBinding,
  children: ReactNode,
  renderedFromAccountAccessPage: boolean,
};

type QueryRoles = { name: string, pagination?: RequestParams.Pagination };

type FormValues = {
  role: string,
};

const EditUserAccountAccess = ({
  position, binding, children, renderedFromAccountAccessPage,
}: Props) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountFromSessionSelector);

  const rolesQuery = useSelector((state) => (
    rolesReducer.getQuerySelector(state, { name: rolesQueryName })
  ));

  const roles = useSelector((state) => (
    rolesReducer.getRolesSelector(state, { ids: rolesQuery.result })
  ));

  const formState = useSelector((state) => (
    userAccountsReducer.getUpdateSelector(state, { id: position.id })
  ));

  const resetFormState = () => {
    dispatch(userAccountsReducer.updateUserAccountReset({ id: position.id }));
  };

  const queryRoles = useCallback(({ name, pagination = DEFAULT_PAGINATION }: QueryRoles) => {
    dispatch(rolesReducer.queryRoles({
      name: rolesQueryName,
      params: {
        scope: USER_ROLE_SCOPE_ACCOUNT,
        q: name,
      },
      pagination,
      sort: ['-type', 'name'],
    }));
  }, [dispatch]);

  const handleLoadMoreAccountRoles = (additionalResults: number) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: rolesQueryName,
      params: { scope: USER_ROLE_SCOPE_ACCOUNT },
      additionalResults,
    }));
  };

  const handleInputChange = useMemo(() => debounce((name) => {
    queryRoles({ name });
  }, 500), [queryRoles]);

  const renderBody = () => (
    <div>
      <Field
        name="accountRole"
        label={(
          <Message
            id="Account role"
            comment="Input field label"
          />
        )}
        placeholder={(
          <Message
            id="Select account role for this user"
            comment="Input field placeholder"
          />
        )}
        component={SelectField}
        options={roles}
        valueKey="id"
        labelKey="name"
        isLoading={rolesQuery.loading}
        loadMoreItems={handleLoadMoreAccountRoles}
        required
        isSearchable
        onInputChange={handleInputChange}
        initialValue={binding.role}
      />
    </div>
  );

  const editUserAccountAccess = (
    { accountRole }: { accountRole: Oneflow.AccountUserRoleBinding },
  ) => {
    const USER_ACCOUNTS_BINDINGS_QUERY_NAME = `admin/user/${position.id}/account-access`;

    const actionData = {
      id: position.id,
      data: {
        createData: {
          id: position.id,
          positionId: position.id,
          roleId: accountRole.id,
        },
        removeData: {
          id: binding.id,
          positionId: position.id,
          roleId: binding.role.id,
        },
      },
      pipe: {
        action: () => userAccountsReducer.queryUserAccounts({
          name: USER_ACCOUNTS_BINDINGS_QUERY_NAME,
          params: {
            id: position.id,
            actorType: 'position',
          },
          sort: ['-role.type', 'role.name'],
        }),
      },
    };

    if (renderedFromAccountAccessPage) {
      actionData.pipe.action = () => accountUsersReducer.queryAccountUsers({
        name: getAccountUsersQueryName(account.id),
        params: {
          actorType: 'position',
        },
        pagination: DEFAULT_PAGINATION,
        sort: ['fullname'],
      });
    }

    dispatch(userAccountsReducer.updateUserAccount(actionData));
  };

  const renderActions = ({ closeConfirmation, formProps }:
    {closeConfirmation: () => void, formProps: FormRenderProps<FormValues>}) => (
      <div className={styles.ActionButtons}>
        <CancelButton onClick={closeConfirmation} />
        <ConfirmButton
          onClick={formProps.handleSubmit}
          disabled={(formState.loading || formProps.pristine)}
          isLoading={formState.loading}
        />
      </div>
  );

  return (
    // @ts-ignore
    <ModalForm
      title={(
        <Message
          id="Change role"
          comment="Modal title for changing account access"
        />
    )}
      body={renderBody()}
      onSubmit={editUserAccountAccess}
      formState={formState}
      resetFormState={resetFormState}
      onOpen={() => queryRoles({
        params: {
          scope: USER_ROLE_SCOPE_ACCOUNT,
        },
      })}
      actions={renderActions}
      modalKey="remove user account access modal"
    >
      {children}
    </ModalForm>
  );
};

export default EditUserAccountAccess;
