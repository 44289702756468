// @flow

import * as React from 'react';

import Button from 'components/button';

import style from './preset.module.scss';

type Props = {
  children: React.Node,
  onClick: () => void,
};

const Preset = ({ children, onClick, ...buttonProps }: Props) => (
  <Button
    {...buttonProps}
    outline
    customClass={style.Preset}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default Preset;
