import Button from 'components/button';

import type { FlowData, CancelFlowAction } from 'types/cancellation-flow';

type CancelFlowActionSubmit = CancelFlowAction & {
  allowContinue: boolean,
  modalData: FlowData,
  syncData: FlowData,
  onStepComplete: () => void,
  onSubmit: (data: FlowData, onSubmitHandler: () => void) => void,
  onSyncFlowData: (data: FlowData) => void,
  onSyncStepData: (data: FlowData) => void,
};

const Submit = ({
  children,
  kind,
  color,
  onSubmit,
  syncData,
  onSyncFlowData,
  onSyncStepData,
  onStepComplete,
  allowContinue,
  modalData,
}: CancelFlowActionSubmit) => {
  const onClick = () => {
    const allData = {
      ...modalData,
      ...syncData,
    };

    if (syncData) {
      onSyncStepData({ ...allData });
      onSyncFlowData({ ...allData });
    }
    onSubmit(allData, onStepComplete);
  };

  return (
    <Button
      kind={kind}
      color={color}
      onClick={onClick}
      disabled={!allowContinue}
    >
      {children}
    </Button>
  );
};

export default Submit;
