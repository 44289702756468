/* eslint-disable react/prop-types */

import React from 'react';
import { get } from 'lodash';
import { localize } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { getGuestToken } from 'agreement/selectors';
import { isBoxDataSharedValueUpdateAllowed } from 'agreement/box-data-shared-value-update-permissions';
import { isBoxDataValueUpdateAllowed } from 'agreement/box-data-value-update-permissions';
import { useFormBoxProps } from 'contexts/form-box-props';
import useCurrentBox from 'hooks/use-current-box';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import * as formBoxHelpers from 'components/contract-boxes/form-box/helpers';
import AddFieldButton from 'components/contract-boxes/form-box/components/add-field-button';
import FieldInputProvider from 'components/contract-boxes/form-box/fields/field-input-provider';
import FieldValidationProvider from 'components/contract-boxes/form-box/fields/field-validation-provider';
import FormCheckbox from 'components/contract-boxes/form-box/fields/checkbox';
import FormDateField from 'components/contract-boxes/form-box/fields/date-field';
import FormDropdown from 'components/contract-boxes/form-box/fields/dropdown';
import FormPopover from 'components/contract-boxes/form-box/components/popover';
import FormTextArea from 'components/contract-boxes/form-box/fields/text-area';
import FormTextField from 'components/contract-boxes/form-box/fields/text-field';

import style from './field.module.scss';

const {
  CHECKBOX,
  DATE_FIELD,
  DROPDOWN,
  EMPTY,
  FIELD_SIZE_100,
  FIELD_SIZE_20,
  FIELD_SIZE_40,
  FIELD_SIZE_50,
  FIELD_SIZE_60,
  FIELD_SIZE_80,
  getFieldData,
  getInitialFieldData,
  TEXT_AREA,
  TEXT_FIELD,
} = formBoxHelpers;

export const FIELD_CLASSES = {
  [FIELD_SIZE_20]: style.FieldSize20,
  [FIELD_SIZE_40]: style.FieldSize40,
  [FIELD_SIZE_50]: style.FieldSize50,
  [FIELD_SIZE_60]: style.FieldSize60,
  [FIELD_SIZE_80]: style.FieldSize80,
  [FIELD_SIZE_100]: style.FieldSize100,
};

export const getFieldSize = (size) => {
  if (!FIELD_CLASSES[size]) {
    return FIELD_CLASSES[FIELD_SIZE_100];
  }

  return [FIELD_CLASSES[size]];
};

const Field = ({
  activePopoverIndexMap,
  colCount,
  data,
  field,
  fieldIndex,
  message,
  order,
  rowIndex,
  setActivePopover,
}) => {
  const {
    boxId,
    editField,
    isAllowedToAddBoxData,
  } = useFormBoxProps();
  const activeFieldIndex = activePopoverIndexMap?.fieldIndex;
  const activeRowIndex = activePopoverIndexMap?.rowIndex;
  const box = useCurrentBox(boxId);
  const fieldId = getId(field);
  const fieldData = getFieldData(data, fieldId);
  const fieldDataValue = get(fieldData, 'value');
  const guestToken = useSelector(getGuestToken);
  const isActive = (rowIndex === activeRowIndex && fieldIndex === activeFieldIndex);
  const isAllowedToUpdateDataValue = isBoxDataValueUpdateAllowed(box, fieldData);
  const isAllowedToUpdateSharedDataValue = isBoxDataSharedValueUpdateAllowed(box, fieldData);
  const isEmpty = field === EMPTY;
  const isGuestView = Boolean(guestToken);

  const getField = () => {
    if (!fieldDataValue) {
      return null;
    }

    const {
      choices,
      description,
      label,
      placeholder,
      size,
      type,
      value,
      valueDataFieldKey,
    } = fieldDataValue;

    const required = Boolean(fieldDataValue.required);

    switch (type) {
      case CHECKBOX:
        return (
          <FieldValidationProvider>
            <FieldInputProvider
              fieldId={fieldId}
              value={value}
              valueDataFieldKey={valueDataFieldKey}
            >
              <FormCheckbox
                description={description}
                inputSize={getFieldSize(size)}
                isActive={isActive}
                isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
                isAllowedToUpdateSharedDataValue={isAllowedToUpdateSharedDataValue}
                isRequired
                label={label}
                required={required}
              />
            </FieldInputProvider>
          </FieldValidationProvider>
        );
      case DATE_FIELD:
        return (
          <FieldValidationProvider>
            <FieldInputProvider
              fieldId={fieldId}
              value={value}
              valueDataFieldKey={valueDataFieldKey}
            >
              <FormDateField
                description={description}
                inputSize={getFieldSize(size)}
                isActive={isActive}
                isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
                isAllowedToUpdateSharedDataValue={isAllowedToUpdateSharedDataValue}
                label={label}
                placeholder={placeholder}
                required={required}
              />
            </FieldInputProvider>
          </FieldValidationProvider>
        );
      case DROPDOWN:
        return (
          <FieldValidationProvider>
            <FieldInputProvider
              fieldId={fieldId}
              value={value}
              valueDataFieldKey={valueDataFieldKey}
            >
              <FormDropdown
                choices={choices}
                description={description}
                inputSize={getFieldSize(size)}
                isActive={isActive}
                isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
                isAllowedToUpdateSharedDataValue={isAllowedToUpdateSharedDataValue}
                label={label}
                placeholder={placeholder}
                required={required}
              />
            </FieldInputProvider>
          </FieldValidationProvider>
        );
      case TEXT_AREA:
        return (
          <FieldValidationProvider>
            <FieldInputProvider
              fieldId={fieldId}
              value={value}
              valueDataFieldKey={valueDataFieldKey}
            >
              <FormTextArea
                description={description}
                inputSize={getFieldSize(size)}
                isActive={isActive}
                isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
                isAllowedToUpdateSharedDataValue={isAllowedToUpdateSharedDataValue}
                label={label}
                placeholder={placeholder}
                required={required}
              />
            </FieldInputProvider>
          </FieldValidationProvider>
        );
      case TEXT_FIELD:
        return (
          <FieldValidationProvider>
            <FieldInputProvider
              fieldId={fieldId}
              value={value}
              valueDataFieldKey={valueDataFieldKey}
            >
              <FormTextField
                description={description}
                inputSize={getFieldSize(size)}
                isActive={isActive}
                isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
                isAllowedToUpdateSharedDataValue={isAllowedToUpdateSharedDataValue}
                label={label}
                placeholder={placeholder}
                required={required}
              />
            </FieldInputProvider>
          </FieldValidationProvider>
        );
      default:
        return null;
    }
  };

  const currentField = getField();
  const indexMap = {
    fieldIndex,
    rowIndex,
  };
  const onClosePopover = setActivePopover(null);
  const openPopover = setActivePopover(indexMap);

  const handleClick = () => {
    if (!isAllowedToUpdateDataValue) {
      return;
    }

    if (isEmpty) {
      editField(indexMap, getInitialFieldData(message));
    }

    if (openPopover) {
      openPopover();
    }
  };

  const getFieldContent = () => {
    const contentClasses = clsx({
      [style.Flex]: isEmpty,
      [style.Editable]: isAllowedToUpdateDataValue && !isEmpty && !isGuestView,
    });

    return (
      <div className={contentClasses}>
        <FormPopover
          activePopoverIndexMap={activePopoverIndexMap}
          colCount={colCount}
          fieldData={fieldData}
          fieldDataValue={fieldDataValue}
          isActive={isActive}
          onClosePopover={onClosePopover}
          order={order}
        >
          <div
            onClick={handleClick}
            onKeyDown={() => null}
            role="button"
            tabIndex={-1}
            className={style.FieldWrapper}
          >
            {!isEmpty || !isAllowedToAddBoxData ? currentField : <AddFieldButton />}
          </div>
        </FormPopover>
      </div>
    );
  };

  return (
    <div className={style.FieldContainer}>
      {getFieldContent()}
    </div>
  );
};

export default localize(Field);
