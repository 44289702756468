import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import agreementsReducer from 'reducers/entities/agreements';

import SmsSign, { Props, ModalData } from './sms-sign';

type OwnProps = {
  agreement: Agreement,
  guestToken?: string,
  modalData: ModalData,
  myParticipantWhenSignatory: AgreementParticipant,
  sendChecksum?: boolean,
  onPreviousStep: () => void,
  onStepComplete: () => void,
  onSyncStepData: (arg1: ModalData) => void,
  onSuccess: () => void,
  onClose: () => void
};

type StateProps = {
  rpcState: RpcState
};

type DispatchProps = {
  signAgreement: (arg1: number) => void,
  resetRpcState: () => void
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state: State, {
  agreement,
}: OwnProps) => ({
  rpcState: agreementsReducer.getSignAgreementSelector(state, { id: agreement.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<any>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  agreement,
  guestToken,
  sendChecksum,
}) => ({
  signAgreement: (myParticipantIdWhenSignatory, signCode) => {
    let data = {
      signMethod: 1,
      signCode,
      participantId: myParticipantIdWhenSignatory,
    };
    if (guestToken) {
      data = {
        ...data,
        guestToken,
      };
    }
    if (sendChecksum) {
      data = {
        ...data,
        checksum: agreement.checksum,
      };
    }

    dispatch(agreementsReducer.signAgreement({
      id: agreement.id,
      data,
    }));
  },
  resetRpcState: () => {
    dispatch(agreementsReducer.signAgreementReset({
      id: agreement.id,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<any>>(
  mapStateToProps, mapDispatchToProps,
)(SmsSign);
