import type { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';

import { isTemplate } from 'agreement';

import Modal from 'components/modal';
import Button from 'components/button';
import TerminateIcon from 'components/icons/terminate';
import type { Props as ModalProps } from 'components/modal';

import style from './edit-warning.module.scss';

type EditWarningContentProps = ModalProps & {
  header?: ReactNode,
  modalKey?: string,
}

export const EditWarningModal = (props: EditWarningContentProps) => (
  <Modal
    {...props}
    header={(
      <Message
        id="Edit template"
        comment="Modal title for edit template warning modal."
      />
    )}
    modalKey="edit shared template modal"
  >
    <Modal.Body isLoading={false}>
      <div className={style.BodyContainer}>
        <TerminateIcon height="53px" className={style.TerminateIcon} />
        <div className={style.Heading}>
          <Message
            id="You are editing a published or shared template"
            comment="Modal body text for edit template warning modal."
          />
        </div>
        <p>
          <Message
            id="Changes to this template will affect all new documents created from it."
            comment="Modal body text for edit template warning modal."
          />
        </p>
      </div>
    </Modal.Body>
    <Modal.Actions>
      <Button
        onClick={props.onCancel}
        kind="primary"
      >
        <Message
          id="Continue editing"
          comment="Button text in the edit template warning modal."
        />
      </Button>
    </Modal.Actions>
  </Modal>
);

export const shouldShowEditWarningModal = (agreement: Oneflow.Agreement) => isTemplate(agreement)
  && (agreement.isShared || agreement.config?.templateActive);

export type Props = {
  isOpen: boolean,
  onCancel: (value: boolean) => void,
};

export default EditWarningModal;
