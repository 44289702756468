// @flow
import { matchPath } from 'react-router';

export const isUserOnAdminPage = (pathname: string) => matchPath(pathname, {
  path: '/admin/:id',
  exact: false,
});

export const isUserOnGlobalSearchPage = (pathname: string) => matchPath(pathname, {
  path: '/search/all',
  exact: false,
});

export const isUserOnGlobalTrashPage = (pathname: string) => matchPath(pathname, {
  path: '/search/trash',
  exact: false,
});

export const isUserOnTemplatePage = (pathname: string) => matchPath(pathname, {
  path: '/contracts/templates/:id',
  exact: true,
  strict: false,
});

// Not valid as a visitor contract page
export const isUserOnContractPage = (pathname: string) => matchPath(pathname, {
  path: '/contracts/:id',
  exact: true,
  strict: false,
});

export const isUserOnContractsPage = (pathname: string) => matchPath(pathname, {
  path: '/contracts/all',
  exact: true,
  strict: false,
});
