// @flow

import { decamelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';

import {
  get,
  put,
  post,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import log from 'logging';

export const accountSeatsSchema = new schema.Entity('accountSeats', {}, {
  idAttribute: (accountSeats, parentEntity) => parentEntity.id,
});

export const accountSchema = new schema.Entity('accounts', {
  seats: accountSeatsSchema,
});

export type NormalizedAccounts = {
  entities: {
    accounts: {
      [number]: Account,
    },
    accountSeats?: {
      [number]: AccountSeats,
    },
  },
  result: number,
  count: number,
};

type AccountNormalizer = (any) => NormalizedAccounts;

const normalizeAccount: AccountNormalizer = (accountData) => {
  const normalizedData = normalize(accountData, accountSchema);
  if (normalizedData.entities.accounts[normalizedData.result].seats) {
    delete normalizedData.entities.accounts[normalizedData.result].seats;
  }

  return {
    ...normalizedData,
    count: 1,
  };
};

type GetAccount = ({
  id: number,
}) => Promise<NormalizedAccounts>;

export const getAccount: GetAccount = ({ id }) => (
  get({ url: `/accounts/${id}` })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAccount)
);

type UpdateAccount = ({
  id: number,
  name?: string,
  orgnr?: string,
  country?: string,
  brandingDateFormat?: string,
  logoId?: string,
  onboardingStage?: string,
}) => Promise<NormalizedAccounts>;

export const updateAccount: UpdateAccount = ({
  id,
  name,
  orgnr,
  country,
  brandingDateFormat,
  logoId,
  onboardingStage,
}) => (
  put({
    url: `/accounts/${id}`,
    body: decamelizeKeys({
      name,
      orgnr,
      country,
      brandingDateFormat,
      logoId,
      onboardingStage,
    }, {
      separator: '_',
    }),
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAccount)
);

type UpdateAccountDataManagement = ({
  dpoEmail?: string,
  emailProvider?: String,
}) => Promise<any>;

export const updateAccountDataManagement: UpdateAccountDataManagement = ({
  id,
  dpoEmail,
  emailProvider,
  freshworks,
  customerIo,
  openAi,
  contractPdfShowContactDetails,
  contractPdfShowSsn,
  contractPdfShowIp,
  contractPdfDownload,
  addressBook,
  handwrittenSignatureAllowTyped,
  loginSessionLength,
  loginTrustedDeviceLength,
}) => (
  put({
    url: `/accounts/${id}/data_management`,
    body: decamelizeKeys({
      dpoEmail,
      emailProvider,
      customerIo,
      openAi,
      freshworks,
      contractPdfShowContactDetails,
      contractPdfShowSsn,
      contractPdfShowIp,
      contractPdfDownload,
      addressBook,
      handwrittenSignatureAllowTyped,
      loginSessionLength,
      loginTrustedDeviceLength,
    }, {
      separator: '_',
    }),
  })
    .then(extractResponseBodyAsJSON)
);

type BuySeats = ({
  id: number,
  numberOfSeats: number,
  planId?: number,
}) => Promise<NormalizedAccounts>;

export const buySeats: BuySeats = (data) => {
  const {
    id,
    numberOfSeats,
    planId,
    amplitudeScope,
  } = data;

  return post({
    url: `/accounts/${id}/orders/`,
    body: decamelizeKeys({
      seats: Number(numberOfSeats),
      planId,
    }, {
      separator: '_',
    }),
  }, { amplitudeScope })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAccount)
    .catch((error) => {
      log.error('Failed to buy seats', { error });
    });
};

type ExportOptions = {
  export_agreement?: boolean,
  export_position?: boolean,
}
type ExportAccount = ({
  id: number,
  positionId: number,
  options: ExportOptions,
}) => Promise<NormalizedAccounts>;
export const exportAccount: ExportAccount = ({ id, positionId, options }) => (
  post({
    url: `/accounts/${id}/exports/`,
    body: {
      ...options,
      account_id: id,
      position_id: positionId,
    },
  })
    .then(extractResponseBodyAsJSON)
);

type StartTrial = ({
  id: number,
}) => Promise<NormalizedAccounts>;

export const startTrial: StartTrial = ({ id }) => (
  post({
    url: `/accounts/${id}/start_trial`,
  })
    .then(extractResponseBodyAsJSON)
);

type CreateCancellation = ({
  id: number,
}) => Promise<NormalizedAccounts>;

export const createCancellation: CreateCancellation = ({ id, reason, details }) => (
  post({
    url: `/accounts/${id}/cancellations/`,
    body: {
      reason,
      details,
    },
  })
    .then(extractResponseBodyAsJSON)
);
