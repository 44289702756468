import { Message } from '@oneflowab/pomes';
import Button from 'components/button';
import Confirmable from 'components/confirmable';
import { CancelButton } from 'components/buttons';
import WarningIcon from 'components/icons/warning';

import style from './updating-sign-order.module.scss';

const modalKey = 'updating-sign-order-warning';

type Props = {
  setIsUpdatingSignOrderModalOpen: (isOpen: boolean) => void;
  isUpdatingSignOrderModalOpen: boolean;
  cancelChange: () => void;
  confirmChange: () => void;
};

export const UpdatingSignOrderModal = ({
  setIsUpdatingSignOrderModalOpen,
  isUpdatingSignOrderModalOpen,
  cancelChange,
  confirmChange,
}: Props) => {
  const onCancel = () => {
    setIsUpdatingSignOrderModalOpen(false);
    cancelChange();
  };

  const renderBody = () => (
    <div className={style.BodyContainer}>
      <WarningIcon className={style.WarningIcon} />
      <p className={style.BodyText}>
        <b>
          <Message
            id="If you change the signing order of this participant, the last approvers won't be able to approve the document."
            comment="Text in the modal"
          />
        </b>
        {' '}
        <Message
          id="To avoid this, place at least one signatory after the last approver."
          comment="Text in the modal"
        />
      </p>
      <p className={style.BodyText}>
        <Message
          id="Update signing order?"
          comment="Text to ask the user if they want to update sign order"
        />
      </p>
    </div>
  );

  const handleConfirm = () => {
    confirmChange();
  };

  const getActions = () => (
    <>
      <CancelButton onClick={onCancel} modalKey={modalKey} />
      <Button
        color="yellow"
        onClick={handleConfirm}
        kind="primary"
        customClass={style.UpdateButton}
      >
        <Message
          id="Update signing order"
          comment="Button label for confirming the change of the participant sign order"
        />
      </Button>
    </>
  );

  const renderChildren = () => null;

  return (
    <Confirmable
      header={(
        <Message
          id="Updating sign order"
          comment="Used as the title in modal when updating the sign order"
        />
      )}
      body={renderBody()}
      actions={getActions}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      isOpen={isUpdatingSignOrderModalOpen}
      modalKey={modalKey}
      customBodyClass={style.Body}
    >
      {renderChildren}
    </Confirmable>
  );
};
