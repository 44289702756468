// @flow

import * as React from 'react';

import withDatePresets from 'hocs/with-date-presets';

import DatePreset from 'components/date-preset';

export const DashboardDateRangePresets = () => (
  <>
    <DatePreset previous={1} type="year" data-testid="previousYear" />
    <DatePreset previous={1} type="month" data-testid="previousMonth" />
    <DatePreset last={90} type="day" data-testid="lastNinetyDays" />
    <DatePreset last={30} type="day" data-testid="lastMonth" />
    <DatePreset last={7} type="day" data-testid="lastWeek" />
  </>
);

export default withDatePresets<{}>(DashboardDateRangePresets);
