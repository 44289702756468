import { connect } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';

import User from './user';

export const mapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);
  const workspace = getCurrentWorkspaceSelector(state);

  return {
    position: getPositionFromSessionSelector(state),
    account,
    workspace,
  };
};

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(User);
