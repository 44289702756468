import getFontAssetsDomain from './get-font-asset-domain.js';

const loadIBMPlexSans = () => {
  const normalIBMPlexSans = new FontFace(
    'IBM Plex Sans',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-sans/ibm-plex-sans_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicIBMPlexSans = new FontFace(
    'IBM Plex Sans',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-sans/ibm-plex-sans_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldIBMPlexSans = new FontFace(
    'IBM Plex Sans',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-sans/ibm-plex-sans_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicIBMPlexSans = new FontFace(
    'IBM Plex Sans',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-sans/ibm-plex-sans_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalIBMPlexSans, italicIBMPlexSans, boldIBMPlexSans, boldItalicIBMPlexSans];
};

export default loadIBMPlexSans;
