import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import agreements from 'reducers/entities/agreements';
import useAgreement from 'hooks/use-agreement';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';

import Tooltip from 'components/tooltip';
import Toggle from 'components/toggle';
import { checkAcl } from 'components/acl';
import Label from 'components/document-tabs/settings-tab/components/label';
import { areSettingsDisabled } from 'components/document-tabs/settings-tab/helpers';

import style from './attachment-signature.module.scss';

type Props = {
  agreementId: number;
};

const AttachmentSignature = ({ agreementId }: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const { resetRPCStates } = useAPIError(agreementId);

  const settingsAreDisabled = areSettingsDisabled(agreement);

  const onChange = async (checked: boolean) => {
    resetRPCStates();
    dispatch(agreements.updateConfig({
      id: agreementId,
      data: {
        attachment_signatures: Number(checked),
      },
    }));
  };

  const attachmentSignaturesIsDisabled = !checkAcl(agreement.acl, 'agreement:update:config:attachment_signatures') || settingsAreDisabled;
  return (
    <Tooltip
      hideContent={!attachmentSignaturesIsDisabled}
      message={(
        <Message
          id="This option can only be changed in the document template."
          comment="Tooltip message explaining why this option is disabled."
        />
      )}
      zIndex="1031"
      side="top"
    >
      <div className={clsx(style.Wrapper, { [style.Disabled]: attachmentSignaturesIsDisabled })}>
        <Label
          hint={(
            <Message
              id="Signing attachments before signing the document will be required for participants."
              comment="Tooltip for attachment signatures setting"
            />
          )}
        >
          <Message
            id="Signing attachments is required"
            comment="Label for turning attachment signatures setting on or off"
          />
        </Label>
        <Toggle
          version="latest"
          input={{ onChange }}
          checked={Boolean(agreement.config.attachmentSignatures)}
          disabled={attachmentSignaturesIsDisabled}
          containerClassName={style.Toggle}
        />
      </div>
    </Tooltip>
  );
};

export default AttachmentSignature;
