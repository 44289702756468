import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import tagsReducer from 'reducers/entities/tags';
import tagConnectionsReducer from 'reducers/entities/tag-connections';

import Tags from './tags';

const TAGS_SELECTOR_QUERY_NAME = 'tags-selector';

export const mapStateToProps = (state, { targetId, targetType }) => {
  const tagsSelectorQuery = tagsReducer.getQuerySelector(state, { name: TAGS_SELECTOR_QUERY_NAME });
  const tagConnections = tagConnectionsReducer.getAllTagConnectionsSelector(state);

  return {
    tagsSelectorQuery,
    tagConnections: (
      tagConnections
        .filter((tagConnection) => (
          tagConnection.target.id === targetId && tagConnection.targetType === targetType
        ))
        .map((tagConnection) => ({
          ...tagConnection,
          tag: tagsReducer.getTagSelector(state, { id: tagConnection.tag }),
        }))
        .filter((tagConnection) => !isEmpty(tagConnection.tag))
    ),
  };
};

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
