import { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';

import usePremiumBoxesDisabled from 'components/box-menu/hooks/use-premium-box-disabled';
import useMatchMedia, { DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY } from 'hooks/use-match-media';
import * as agreementConstants from 'agreement/constants';

import * as DropdownMenu from 'components/dropdown-menu-v2';
import BoxMenuItem from './box-menu-item';

import style from './box-menu.module.scss';
import CollapsedTrigger from './triggers/collapsed-trigger';
import ExpandedTrigger from './triggers/expanded-trigger';

type Props = {
  onAddBox: () => void,
  isAlwaysVisible?: boolean,
  hasDurationBox: () => boolean,
  shouldOpen?: boolean,
  onClose?: () => void,
  isBoxDndOver: boolean,
};

const BoxMenuComponent = ({
  onAddBox,
  isAlwaysVisible,
  hasDurationBox,
  shouldOpen,
  onClose,
  isBoxDndOver,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean | undefined>(false);
  const [isOver, setIsOver] = useState(false);
  const boxDisabled = usePremiumBoxesDisabled();
  const isCollapsedLayout = useMatchMedia(DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY);

  useEffect(() => {
    setIsDropdownOpen(shouldOpen);

    return () => {
      setIsDropdownOpen(false);
    };
  }, [shouldOpen]);

  const handleMouseOver = () => {
    setIsOver(true);
  };

  const handleMouseLeave = () => {
    setIsOver(false);
  };

  const setDropdownStatus = (status: boolean) => {
    setIsDropdownOpen(status);

    setIsOver(status);

    if (!status) {
      onClose?.();
    }
  };

  const handleAddBox = (type: number) => {
    onAddBox(type);
  };

  const renderItem = ({
    boxType,
    disabled = false,
  }: {
    boxType: number,
    disabled?: boolean,
  }) => (
    <BoxMenuItem
      onSelect={handleAddBox}
      boxType={boxType}
      disabled={disabled}
      key={boxType}
    />
  );

  const showButton = !isOver && !isDropdownOpen;
  const menuButtonHide = !isAlwaysVisible && showButton;
  const menuClasses = useMemo(() => clsx(style.BoxMenu, {
    [style.hideMenu]: menuButtonHide,
    [style.showMenu]: !menuButtonHide,
    [style.CollapsedLayout]: isCollapsedLayout,
    [style.IsBoxDndOver]: isBoxDndOver,
  }), [menuButtonHide, isCollapsedLayout, isBoxDndOver]);

  const items = [
    renderItem({ boxType: agreementConstants.BOX_TEXT_AND_IMAGE, disabled: boxDisabled }),
    renderItem({ boxType: agreementConstants.BOX_VIDEO, disabled: boxDisabled }),
    renderItem({ boxType: agreementConstants.BOX_PRODUCT_TABLE, disabled: boxDisabled }),
    renderItem({ boxType: agreementConstants.BOX_FORM, disabled: boxDisabled }),
    renderItem({
      boxType: agreementConstants.BOX_DURATION,
      disabled: hasDurationBox() || boxDisabled,
    }),
    renderItem({ boxType: agreementConstants.BOX_PDF }),
    renderItem({ boxType: agreementConstants.BOX_ATTACHMENTS, disabled: boxDisabled }),
    renderItem({ boxType: agreementConstants.BOX_PRODUCT_SUMMATION, disabled: boxDisabled }),
  ];

  return (
    <DropdownMenu.Root
      modal
      onOpenChange={setDropdownStatus}
      open={isDropdownOpen}
    >
      {isCollapsedLayout
        ? (
          <CollapsedTrigger
            menuClasses={menuClasses}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            setDropdownStatus={setDropdownStatus}
            isDropdownOpen={isDropdownOpen}
          />
        )
        : (
          <ExpandedTrigger
            menuClasses={menuClasses}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            isBoxDndOver={isBoxDndOver}
          />
        )}
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="center"
          collisionPadding={8}
          onCloseAutoFocus={(event) => {
            event.preventDefault();
          }}
        >
          {items}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default BoxMenuComponent;
