// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { RemoveConfirm } from '../remove-confirm';

export type Props = {
  selectedGroupIds: Array<number>,
  removeState: RemoveState,
  resetRemoveState: Function,
  deleteGroups: () => void,
  children: Function,
}

export const DeleteGroups = ({
  selectedGroupIds,
  removeState,
  resetRemoveState,
  deleteGroups,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={deleteGroups}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <>
        <Message
          id="You are about to delete a group. This can't be undone."
          pluralId="You are about to delete {count} groups. This can't be undone."
          pluralCondition="count"
          values={{
            count: selectedGroupIds.length,
          }}
          comment="Confirm modal body when removing groups."
        />
        <br />
        <Message
          id="Users in this group will not be affected."
          pluralId="Users in these groups will not be affected."
          pluralCondition="count"
          values={{
            count: selectedGroupIds.length,
          }}
          comment="Confirm modal body when removing groups."
        />
      </>
    )}
    modalKey="delete groups in bulk modal"
  >
    {children}
  </RemoveConfirm>
);
