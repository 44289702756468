// @flow

import * as React from 'react';
import Dropzone from 'react-dropzone';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import isFunction from 'lodash/isFunction';
import head from 'lodash/head';
import clsx from 'clsx';

import client from 'oneflow-client';
import type { FieldRenderProps } from 'react-final-form';

import {
  FILE_IMAGE_BMP,
  FILE_IMAGE_JPEG,
  FILE_IMAGE_PNG,
} from 'utils/file';

import Button from 'components/button';
import Delete from 'components/icons/delete';

import FileUploadError, { OFFLINE_ERROR, UNKNOWN_ERROR, API_ERROR_PDF_CONTAINS_TOO_MANY_PAGES } from './file-upload-error';
import FileUploadRequirements from './file-upload-requirements';
import DropzoneUploadSpace from './dropzone-upload-space';

import style from './file-upload.module.scss';

type Props = {
  ...FieldRenderProps,
  fileUrl: string,
  accept?: Array<AssetFileType>,
  assetType?: AssetType,
  message: MessageTranslator,
  maxFileSizeBytes?: number,
  children?: React.Node,
  prepare?: Function,
  loading?: boolean,
  asyncAssetState?: string,
  isSmall?: boolean,
  header?: React.Node,
  isContractBox?: boolean,
  displayFileType?: string,
  onError?: (errorCode: string | number) => void,
  resetUploadState?: () => void,
  maxSizePerContractValidator: (file: File) => void,
  attachmentsCountLimit?: number,
  onDropRejected?: (file: Array<File>) => void,
}

type State = {
  fileUrl: string,
  error?: number | string,
  isMouseInside: boolean,
}

export class FileUpload extends React.Component<Props, State> {
  static defaultProps = {
    accept: [FILE_IMAGE_PNG, FILE_IMAGE_JPEG, FILE_IMAGE_BMP],
    assetType: 'logo',
    maxFileSizeBytes: 50 * 1024 * 1024,
    children: null,
    prepare: null,
    loading: false,
    isSmall: undefined,
    header: undefined,
    isContractBox: false,
  }

  state = {
    fileUrl: this.props.fileUrl,
    error: undefined,
    isMouseInside: false,
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getFilename = (response: Object) => {
    const { assetType } = this.props;
    if (assetType === 'document') {
      return response?.assetIdentifier;
    }

    return response.assetUrl;
  }

  getFileIdentifier = (response: Object) => {
    const { assetType } = this.props;
    if (assetType === 'document') {
      return response.assetIdentifier;
    }

    return response.assetId;
  }

  onFileDropSuccess = (response: Object) => {
    const { input, onUploadDone } = this.props;
    this.setState({
      fileUrl: this.getFilename(response),
      error: undefined,
    }, () => {
      if (input) {
        input.onChange(this.getFileIdentifier(response));
      }
      if (onUploadDone) {
        onUploadDone(response);
      }
    });
  }

  onFileDropFail = (error) => {
    if (!this.mounted) {
      return;
    }

    const { onError } = this.props;

    let uploadError: string | number;

    if (error instanceof TypeError) {
      uploadError = UNKNOWN_ERROR;

      if (navigator.onLine === false) {
        uploadError = OFFLINE_ERROR;
      }
    } else {
      const { response, body } = error;
      uploadError = body?.api_error_code === API_ERROR_PDF_CONTAINS_TOO_MANY_PAGES
        ? body.api_error_code
        : response.status;
    }

    this.setState({ error: uploadError });
    onError?.(uploadError);
  }

  upload = async (file) => {
    const { assetType, onUpload } = this.props;
    if (isFunction(onUpload)) {
      const uploadFile = await onUpload({
        file,
        type: assetType,
      });

      if (!this.mounted) {
        return undefined;
      }

      return uploadFile;
    }

    const uploadAsset = await client.uploadAsset({
      file,
      type: assetType,
    });

    if (!this.mounted) {
      return undefined;
    }

    return uploadAsset;
  }

  startUpload = async (file) => {
    const { prepare } = this.props;
    if (prepare) {
      await prepare();
    }
    return this.upload(file);
  }

  onFileDrop = (acceptedFiles: Array<File>, fileRejections: Array<File>) => {
    const file = head(acceptedFiles);

    if (fileRejections?.length > 0) {
      const { onDropRejected } = this.props;
      onDropRejected?.(fileRejections);
    }

    const rejectedFileError = head(fileRejections)?.errors;

    if (!file) {
      if (rejectedFileError?.length === 1) {
        this.setState({ error: rejectedFileError[0].code });
        return;
      }

      this.setState({ error: fileRejections[0].errors[0].code });
      return;
    }

    this.resetError();

    // eslint-disable-next-line consistent-return
    return this.startUpload(file)
      .then(this.onFileDropSuccess)
      .catch(this.onFileDropFail);
  }

  resetError = () => {
    if (!this.mounted) {
      return;
    }
    this.setState({ error: undefined });
    const { resetUploadState } = this.props;
    resetUploadState?.();
  }

  getExtensionsList = () => {
    const { accept } = this.props;

    return accept.map((type) => type.extensions.join(', ')).join(', ');
  }

  getAcceptedFiles = () => {
    const { accept } = this.props;
    const acceptFormatted = {};
    accept.forEach((type) => {
      acceptFormatted[type.mimeType] = type.extensions.map((extension) => `.${extension}`);
    });
    return acceptFormatted;
  }

  handleDeleteImage = () => {
    const { input } = this.props;

    this.setState({ fileUrl: '' });
    input.onChange(null);
  };

  mouseEnter = () => {
    this.setState({ isMouseInside: true });
  }

  mouseLeave = () => {
    this.setState({ isMouseInside: false });
  }

  handleToggleRemoveButton = () => {
    if (this.state.isMouseInside) {
      return (
        <Button
          icon={<Delete className={style.Icon} />}
          onClick={this.handleDeleteImage}
          customClass={style.Button}
        />
      );
    }

    return null;
  }

  renderUploadedFile() {
    const { assetType } = this.props;
    const { fileUrl } = this.state;

    if (!fileUrl || assetType !== 'logo') {
      return null;
    }

    return (
      <div className={style.ImageArea}>
        <div className={style.Image} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
          <div className={style.Overlay} style={{ opacity: this.state.isMouseInside ? 0.8 : 0 }} />
          <img alt="logo" src={fileUrl} />
          {this.handleToggleRemoveButton()}
        </div>
      </div>
    );
  }

  renderError = () => {
    const { error } = this.state;
    const { maxFileSizeBytes, attachmentsCountLimit } = this.props;

    if (!error) return null;

    return (
      <FileUploadError
        errorCode={error}
        maxFileSize={maxFileSizeBytes}
        acceptedFileTypes={this.getExtensionsList()}
        attachmentsCountLimit={attachmentsCountLimit}
      />
    );
  };

  renderBody = () => (
    <>
      {this.props.children}
      <FileUploadRequirements
        maxFileSize={this.props.maxFileSizeBytes}
        acceptedFileTypes={this.getExtensionsList()}
      />
    </>
  );

  renderDropzone = (isDragReject) => (
    <>
      {!this.props.loading && this.props.header}
      <DropzoneUploadSpace
        errorCode={this.state.error}
        isDragReject={isDragReject}
        body={this.renderBody()}
        loading={this.props.loading}
        isContractBox={this.props.isContractBox}
        acceptedFileTypes={this.getExtensionsList()}
        displayFileType={this.props.displayFileType}
        asyncAssetState={this.props.asyncAssetState}
      />
    </>
  );

  render() {
    const { isSmall, isContractBox, maxSizePerContractValidator } = this.props;
    const { error } = this.state;

    const getDropzoneClasses = (isDragActive, isDragReject) => clsx({
      [style.Small]: isSmall,
      [style.DropzoneInvalid]: error,
      [style.Dropzone]: !error,
      [style.ContractBox]: isContractBox,
      [style.DropzoneActive]: isDragActive,
      [style.DropzoneReject]: isDragReject,
    });

    return (
      <div className={style.FileUpload}>
        {this.renderUploadedFile()}
        <Dropzone
          onDrop={this.onFileDrop}
          activeClassName={style.DropzoneActive}
          rejectClassName={style.DropzoneReject}
          accept={this.getAcceptedFiles()}
          onClick={this.resetError}
          validator={maxSizePerContractValidator}
        >
          {(
            {
              getRootProps, getInputProps, isDragReject, isDragActive,
            },
          ) => (
            <section>
              <div {...getRootProps({ className: getDropzoneClasses(isDragActive, isDragReject) })}>
                <input {...getInputProps()} />
                {this.renderDropzone(isDragReject)}
              </div>
              {this.renderError()}
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default localize <Props>(FileUpload);
