import { Message } from '@oneflowab/pomes';

import { getMyParticipantWhenUpdater, isPartiallySigned } from 'agreement/selectors';

import toast from 'components/toasts';

export const PARTIALLY_SIGNED_TOAST_ID = 'partially-signed';

export const renderPartiallySignedToast = (agreement: Oneflow.Agreement) => {
  const partiallySigned = isPartiallySigned(agreement);
  const canEdit = Boolean(getMyParticipantWhenUpdater(agreement));

  const isSingleSign = Boolean(agreement.config?.singleSign);

  if (partiallySigned && canEdit && !isSingleSign) {
    toast.info({
      id: PARTIALLY_SIGNED_TOAST_ID,
      title: (
        <Message
          id="Document is partially signed"
          comment="Info text explaining that the document has been signed by some parties."
        />
      ),
      description: (
        <Message
          id="Editing the document content or participants may reset existing signatures."
          comment="Info text explaining that if the user makes changes to the document or to the participants, the signatures might be reset."
        />
      ),
      duration: 8000,
    });
  }
};
