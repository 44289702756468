import useAgreement from 'hooks/use-agreement';
import { isBlank, isTemplate } from 'agreement';

import Divider from 'components/divider';
import SigningPeriodExpirySection from 'components/document-tabs/settings-tab/sections/signing-and-security/signing-period-expiry-section';
import SectionSeparator from 'components/document-tabs/settings-tab/components/section-separator/section-separator';
import { SignatureMode } from 'components/document-tabs/settings-tab/sections/signing-and-security/fields/signature-mode';
import { SignRedirect } from 'components/document-tabs/settings-tab/sections/signing-and-security/fields/sign-redirect';

import CounterpartySigningMethod from './fields/counterparty-signing-method';
import DeliveryChannel from './fields/delivery-channel';
import AttachmentSignature from './fields/attachment-signature';
import MFA from './fields/mfa';

import style from './signing-and-security.module.scss';

type Props = {
  agreementId: number
};

const SigningAndSecurity = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const isTemplateOrBlank = isTemplate(agreement) || isBlank(agreement);

  return (
    <div className={style.SigningAndSecurityContainer}>
      <SignatureMode agreementId={agreementId} />
      {isTemplateOrBlank && (
        <>
          <SectionSeparator />
          <CounterpartySigningMethod agreementId={agreementId} />
          <SectionSeparator />
          <DeliveryChannel agreementId={agreementId} />
        </>
      )}
      {isTemplateOrBlank && (
        <>
          <SectionSeparator />
          <MFA agreementId={agreementId} />
        </>
      )}
      <SectionSeparator customClassName={style.SectionSeparatorCustomMargin} />
      <SignRedirect agreementId={agreementId} />
      <SectionSeparator customClassName={style.SectionSeparatorCustomMargin} />
      <SigningPeriodExpirySection
        agreementId={agreementId}
      />
      <SectionSeparator customClassName={style.SectionSeparatorCustomMargin} />
      <AttachmentSignature agreementId={agreementId} />
      <Divider solid className={style.Divider} />
    </div>
  );
};

export default SigningAndSecurity;
