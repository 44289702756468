import { forwardRef } from 'react';
import { Message } from '@oneflowab/pomes';
import { useSlate, useSlateSelection } from 'slate-react';
import { Range } from 'slate';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import useCurrentContractId from 'hooks/use-current-contract-id';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
// eslint-disable-next-line import/named
import { useRichTextProps } from 'contexts/rich-text';
// eslint-disable-next-line import/named
import { useContractProps } from 'contexts/contract-props';

import * as Command from 'components/filterable';
import { useSelectedRectanglePopoverProps } from 'components/rich-text-editor-toolbars/inline-toolbar/use-selected-rectangle-popover-props';

import { AIAssistantPrompts } from '../constants';
import { Chat } from '../context/chat-history';

type Props = {
  onSelect: (prompt: string) => void;
  selectedResponse: Chat & { role: 'assistant' };
}

const CompletionMenu = forwardRef<HTMLDivElement, Props>(({
  onSelect,
  selectedResponse,
}, ref) => {
  const documentId = useCurrentContractId();
  const { resetUIState } = useSelectedRectanglePopoverProps();
  const { setIsAiAssistPopoverOpen } = useContractProps();
  const { resetEditorSelectedRangeInfo } = useRichTextProps();
  const editor = useSlate();
  const editorSelection = useSlateSelection();
  const focusEditor = useFocusEditor();
  const isEditorSelectionCollapsed = !editorSelection || Range.isCollapsed(editorSelection);

  const handleInsertText = () => {
    amplitudeLogEvent('Approve AI Assistance', {
      'context key': selectedResponse.contextParameters?.context_key,
      'context parameters version': selectedResponse.contextParameters?.context_parameters_version,
      location: 'contract view - popover',
      'assistance type': selectedResponse.contextParameters?.context_key,
    }, {
      'document id': documentId,
    });
    focusEditor();
    editor.insertText(selectedResponse.content);
    setIsAiAssistPopoverOpen(false);
    resetUIState();
    resetEditorSelectedRangeInfo();
  };

  const handleDiscard = () => {
    focusEditor();
    setIsAiAssistPopoverOpen(false);
    resetUIState();
  };

  return (
    <Command.List id="result-actions-list" ref={ref}>
      <Command.Group id="selection-group" headingId="selection-group-heading">
        {isEditorSelectionCollapsed ? (
          <Command.Item
            id="insert-text"
            value="Insert text"
            onSelect={handleInsertText}
          >
            <Message
              id="Insert text"
              comment="Menu option to insert the generated text"
            />
            <kbd>&#8629;</kbd>
          </Command.Item>
        ) : (
          <Command.Item
            id="replace-selection"
            value="Replace selection"
            onSelect={handleInsertText}
          >
            <Message
              id="Replace selection"
              comment="Menu option to Replace the selected text with the AI generated text"
            />
            <kbd>&#8629;</kbd>
          </Command.Item>
        )}
        <Command.Item
          id="continue-writing"
          value="Continue writing"
          onSelect={() => onSelect(AIAssistantPrompts.CONTINUE_WRITING)}
        >
          <Message
            id="Continue writing"
            comment="Menu option for the ai model to continue writing after the it has generated text"
          />
          <kbd>&#8629;</kbd>
        </Command.Item>
        <Command.Item
          id="make-longer"
          value="Make longer"
          onSelect={() => onSelect(AIAssistantPrompts.MAKE_LONGER)}
        >
          <Message
            id="Make longer"
            comment="Menu option for the ai model to make the generated text longer"
          />
          <kbd>&#8629;</kbd>
        </Command.Item>
      </Command.Group>
      <Command.Separator />
      <Command.Group id="actions-group" headingId="actions-group-heading">
        <Command.Item
          id="try-again"
          value="Try again"
          onSelect={() => onSelect(AIAssistantPrompts.TRY_AGAIN)}
        >
          <Message
            id="Try again"
            comment="Menu option for the user to try the AI model again"
          />
          <kbd>&#8629;</kbd>
        </Command.Item>
        <Command.Item
          id="discard"
          value="Discard"
          onSelect={handleDiscard}
        >
          <Message
            id="Discard"
            comment="Menu option for the user to discard the AI generated text"
          />
          <kbd>&#8629;</kbd>
        </Command.Item>
      </Command.Group>
    </Command.List>
  );
});

CompletionMenu.displayName = 'CompletionMenu';

export default CompletionMenu;
