// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  createFolder,
  deleteFolder,
  getFolders,
  updateFolder,
} from 'oneflow-client/folders';
import type { NormalizedFolders } from 'oneflow-client/folders';

import apiWrapper from 'sagas/api-wrapper';

import foldersReducer from 'reducers/entities/folders';

type MapperArgs = {
  data: NormalizedFolders,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(foldersReducer.setFolders(data.entities.folders));
}

export function* clearMapper({ action }: MapperArgs): Mapper {
  yield put(foldersReducer.clearFolder({ id: action.id }));
}

export function* updateMapper({ action, data }: MapperArgs): Mapper {
  yield put(foldersReducer.setFolders({
    [action.id]: data,
  }));
}

const mappers = {
  query: {
    mapper,
    request: getFolders,
  },
  create: {
    mapper,
    request: createFolder,
  },
  remove: {
    mapper: clearMapper,
    request: deleteFolder,
  },
  update: {
    mapper: updateMapper,
    request: updateFolder,
  },
};

const foldersSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: foldersReducer,
  mappers,
});

export default foldersSaga;
