// @flow

import { connect } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';

import Templates from './templates';

export const mapStateToProps = (state: any) => ({
  workspaceAcl: getCurrentWorkspaceSelector(state).acl,
});

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
