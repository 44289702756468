/* eslint-disable camelcase */
import React from 'react';

import useIsExpandedView from 'hooks/use-is-expanded-view';

import {
  PRODUCT_TABLE_EXPANDED_VIEW_MIN_WIDTH,
  PRODUCT_TABLE_MIN_WIDTH_TOLERANCE_DELTA,
} from 'components/contract-boxes/product-table-box/constants';

import UpdateProductItemForm from 'components/contract-boxes/product-table-box/product-table/popup-forms/update-product-item';
import type { Props as UpdateProductItemProps } from 'components/contract-boxes/product-table-box/product-table/popup-forms/update-product-item';

type Props = {
  readOnly: boolean;
} & Omit<UpdateProductItemProps, 'popupType' | 'message'> & React.HTMLAttributes<HTMLDivElement>

export default function UpdateProductItemPopup(props: Props): React.JSX.Element | null {
  const {
    readOnly,
    ...restProps
  } = props;
  const isExpandedView = useIsExpandedView(
    PRODUCT_TABLE_EXPANDED_VIEW_MIN_WIDTH,
    PRODUCT_TABLE_MIN_WIDTH_TOLERANCE_DELTA,
  );

  const popupType = isExpandedView ? 'popover' : 'dialog';

  if (!restProps.children) {
    return null;
  }

  if (readOnly) {
    return restProps.children as React.JSX.Element;
  }

  return (
    <UpdateProductItemForm
      popupType={popupType}
      {...restProps}
      isResponsiveView={!isExpandedView}
    />
  );
}
