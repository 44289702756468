import React from 'react';
import PropTypes from 'prop-types';

import style from './table.module.scss';

const TableBody = ({ children }) => (
  <tbody className={style.TableBody}>
    {children}
  </tbody>
);

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableBody;
