import type { MessageTranslator } from '@oneflowab/pomes';

export const draftApprovalFlowStarted = (
  event: Oneflow.AgreementEvent,
  message: MessageTranslator,
) => ({
  text: message({
    id: '{name} started the approval flow.',
    comment: 'Audit trail message',
    values: {
      name: event.ownerParticipant?.name,
    },
  }),
  byLine: false,
});

export const draftFullyApproved = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'All approvers have approved.',
    comment: 'Audit trail message',
  }),
  byLine: false,
});

export const participantApprovedDraft = (
  event: Oneflow.AgreementEvent,
  message: MessageTranslator,
) => ({
  text: message({
    id: '{name} approved.',
    comment: 'Audit trail message when user approves a document.',
    values: {
      name: event.ownerParticipant?.name,
    },
  }),
  byLine: false,
});

export const sendForDraftApproval = (
  event: Oneflow.AgreementEvent,
  message: MessageTranslator,
) => ({
  text: message({
    id: '{name} was invited to approve.',
    comment: 'Audit trail message when document is sent for approval.',
    values: {
      name: event.participants && event.participants[0]?.name,
    },
  }),
  byLine: false,
});

export const resetDraftApprovals = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'All approvals were reset.',
    comment: 'Audit trail message when approvals are reset.',
  }),
  byLine: false,
});
