// @flow

import * as React from 'react';
import get from 'lodash/get';

import { useVideoBoxProps } from 'contexts/video-box-props';
import EmptyVideoBox from './empty-video-box';
import LoadingVideoBox from './loading-video-box';
import VideoPlayer from './video-player';
import VideoUpload from './video-upload';
import {
  uploadingVideoMessage,
  processingVideoMessage,
} from '../constants';

const VideoContainer = () => {
  const {
    box,
    addVideoState,
    updateAddVideoState,
    resetVideoKeyRef,
    isAllowedToAddBoxData,
  } = useVideoBoxProps();
  const data = get(box, 'content.data');
  const hasVideo = Boolean(data?.find((d) => !d._removed));
  const loadingState = addVideoState.processingVideo
    || addVideoState.uploadingVideo;
  const dropzoneDisabled = hasVideo || !isAllowedToAddBoxData || loadingState;

  const onCancelProcessing = React.useCallback(() => {
    updateAddVideoState({
      uploadingVideo: false,
      processingVideo: false,
    });
    resetVideoKeyRef();
  }, [updateAddVideoState, resetVideoKeyRef]);

  if (loadingState) {
    let loadingMessage = uploadingVideoMessage;

    if (addVideoState.processingVideo) {
      loadingMessage = processingVideoMessage;
    }

    return (
      <LoadingVideoBox
        message={loadingMessage}
        onCancelProcessing={onCancelProcessing}
      />
    );
  }

  if (hasVideo) {
    return (
      <VideoPlayer />
    );
  }

  return (
    <VideoUpload disabled={dropzoneDisabled}>
      <EmptyVideoBox />
    </VideoUpload>
  );
};

export default VideoContainer;
