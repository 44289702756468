// @flow
import * as React from 'react';
import MiniContractCard from 'components/mini-contract-card';
import { Message } from '@oneflowab/pomes';

import style from './internal-reminder-body.module.scss';

type Props = {
  contract: Agreement | { id: number },
}

function ViewableContract(props: Props) {
  const { contract } = props;
  const hasViewPermission = Object.keys(contract)?.length > 1;

  if (hasViewPermission) {
    return (
      <div className={style.MiniContractCardLabel}>
        <Message
          id="Contract "
          comment="label for a field displaying a contract"
        />
        <MiniContractCard
          agreement={contract}
          tabIndex="-1"
          showParticipants={false}
        />
      </div>
    );
  }

  return (
    <MiniContractCard.WithoutPermission contractId={contract?.id} />
  );
}

export default ViewableContract;
