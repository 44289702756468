import { Message } from '@oneflowab/pomes';

const Signed = ({ signedDate }: { signedDate: string }) => (
  <Message
    id="Document was signed on {date}."
    values={{
      date: signedDate,
    }}
    comment="Text for signed contract, stating when contract was signed. Visible in contract."
  />
);

export default Signed;
