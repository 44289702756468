// @flow

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import { getDateWithSetHour } from 'date';

import agreementsReducer from 'reducers/entities/agreements';
import { useUserEvents } from 'contexts/user-events';

import ModalForm from 'hocs/modal-form';

import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import CircularSpinner from 'components/icons/circular-spinner';

import InternalReminderCreateSelectedContractBody from './internal-reminder-create-selected-contract-body';
import useInitialValues from './use-initial-values';
import useRecipientsValidation from './use-recipients-validation';
import getRecipientsWithAccessInfo from './get-recipients-with-access-info';

import style from './internal-reminder.module.scss';

const modalKey = 'create custom reminder in contract card modal';
export type FormData = {|
  contractFieldOption: {},
  recipients: [],
  date: string,
  timeFieldOption: string,
  subject: string,
  message: string,
|};

export type Props = {
  contract: Agreement,
  message: MessageTranslator,
  refetchInternalReminders?: () => void,
  children?: React.Element<Message>,
  visualTweak?: 'overrideLegacyStyling',
};

export const InternalReminderCreateSelectedContractComponent = ({
  contract,
  message,
  children,
  refetchInternalReminders,
  visualTweak,
  ...restProps
}: Props) => {
  const [{ validatedRecipients, userConsent }, sendValidationEvent] = useRecipientsValidation();

  const { currentUser } = useUserEvents();
  const dispatch = useDispatch();
  const initialValues = useInitialValues.forNewEventSelectedContract(
    { message, currentUser, contract },
  );

  const rpcState = useSelector((state) => (
    agreementsReducer.getScheduleInternalReminderSelector(state, { id: contract?.id })
  ));

  const resetRpcState = () => {
    dispatch(agreementsReducer.scheduleInternalReminderReset({ id: contract?.id }));
  };

  const handleModalClose = () => {
    resetRpcState();
    sendValidationEvent({ type: 'validation/reset' });
  };

  const confirmSubmit = (formData: FormData) => {
    const dateFromTimestamp = new Date(formData.date * 1000);
    const dateAndTime = getDateWithSetHour(
      dateFromTimestamp,
      formData.timeFieldOption.timeAsInteger,
    );
    const toRecipients = formData.recipients.map((recipient) => recipient.id);

    dispatch(agreementsReducer.scheduleInternalReminder({
      id: contract.id,
      data: {
        agreementId: contract.id,
        recipients: toRecipients,
        date: dateAndTime,
        subject: formData.subject,
        message: formData.message,
      },
      pipe: {
        onSuccess: () => {
          // We could wait for rpcState.status === 'success' in useEffect
          // However it is better to avoid it whenever possible
          // Though it is local state it is synced with formValues in body component
          sendValidationEvent({ type: 'validation/reset' });
          // We should have an onSuccess for the contract list toggle, not otherwise
          refetchInternalReminders?.();
        },
      },
    }));
  };

  const handleSubmit = async (formData: FormData) => {
    if (userConsent === 'given') {
      confirmSubmit(formData);
      return;
    }

    const recipientsWithAccessInfo = await getRecipientsWithAccessInfo(formData);

    if (recipientsWithAccessInfo.some((recipient) => recipient.needsAccess)) {
      sendValidationEvent({
        type: 'recipients/validated',
        validatedRecipients: recipientsWithAccessInfo,
      });
      return;
    }

    confirmSubmit(formData);
  };

  const getErrorActions: GetActions = ({ closeConfirmation }) => (
    <CancelButton onClick={closeConfirmation} modalKey={`${modalKey} - error action`} />
  );

  const getActions = ({ closeConfirmation, formProps }) => {
    if (validatedRecipients.some((recipient) => recipient.needsAccess) && userConsent === 'given') {
      return (
        <div className={style.ActionButtons}>
          <CancelButton onClick={closeConfirmation} modalKey={`${modalKey} - consent given`} />
          <Button
            icon={rpcState.loading && CircularSpinner}
            color="yellow"
            data-testid="confirm"
            onClick={formProps.handleSubmit}
            disabled={!contract?.id || rpcState.loading || !isEmpty(formProps.errors)}
          >
            <Message
              id="Confirm"
              comment="Button label used to confirm sending a contract in a modal"
            />
          </Button>
        </div>
      );
    }

    return (
      <div className={style.ActionButtons}>
        <CancelButton onClick={closeConfirmation} modalKey={modalKey} />
        <Button
          icon={rpcState.loading && CircularSpinner}
          color="yellow"
          data-testid="create"
          onClick={async (...args) => {
            await formProps.handleSubmit(...args);
            sendValidationEvent({ type: 'user/givenConsent' });
          }}
          disabled={!contract?.id || rpcState.loading || !isEmpty(formProps.errors)}
        >
          <Message
            id="Create"
            comment="Button label used to confirm sending a contract in a modal"
          />
        </Button>
      </div>
    );
  };

  const getTitle = () => (
    <Message
      id="Create internal reminder"
      comment="Confirm modal title for sending a contract reminder."
    />
  );

  return (
    <ModalForm
      title={getTitle()}
      body={(
        <InternalReminderCreateSelectedContractBody
          selectedContract={contract}
          validatedRecipients={validatedRecipients}
          sendValidationEvent={sendValidationEvent}
          userConsent={userConsent}
          visualTweak={visualTweak}
        />
      )}
      formState={rpcState}
      resetFormState={resetRpcState}
      actions={getActions}
      errorActions={getErrorActions}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      onClose={handleModalClose}
      modalKey={modalKey}
      {...restProps}
    >
      {children || null}
    </ModalForm>
  );
};

export default localize<Props>(InternalReminderCreateSelectedContractComponent);
