import { connect } from 'react-redux';

import workspaceMessageTemplatesReducer from 'reducers/entities/workspace-message-templates';

import { RemoveMessageTemplate } from './remove-message-template';

const QUERY_NAME = 'templates/messages';

export const mapStateToProps = (state, { template }) => ({
  removeState: workspaceMessageTemplatesReducer.getRemoveSelector(state, {
    id: template.id,
  }),
});

export const mapDispatchToProps = (dispatch, { template }) => ({
  removeTemplate: () => {
    dispatch(workspaceMessageTemplatesReducer.removeWorkspaceMessageTemplate({
      id: template.id,
      data: {
        ids: [template.id],
      },
      pipe: {
        action: () => workspaceMessageTemplatesReducer.queryWorkspaceMessageTemplatesReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(workspaceMessageTemplatesReducer.removeWorkspaceMessageTemplateReset({
      id: template.id,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveMessageTemplate);
