// @flow

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import head from 'lodash/head';
import webSocket from 'web-socket';

import { isBoxDataCreateAllowed } from 'agreement/box-data-create-permissions';
import { updateBoxAction } from 'reducers/current-contract';
import agreements from 'reducers/entities/agreements';
import {
  getPreSignedPost,
  uploadVideoForBox,
  requestVideoProcessing,
} from 'oneflow-client/agreement-video';
import { generateVideoBoxData } from 'components/contract-boxes/video-box/video-box-helpers';

import {
  MAX_VIDEO_SIZE,
  videoSizeErrorMessage,
  videoTypeErrorMessage,
  videoUploadingErrorMessage,
  uploadingVideoSuccessMessage,
} from 'components/contract-boxes/video-box/constants';

export const VideoBoxPropsContext = React.createContext<any>();

type Props = {|
  children: React.Node,
  box: Box,
  myParticipant: AgreementParticipant,
  agreementId: number,
|};

export function VideoBoxPropsProvider({
  children,
  box,
  agreementId,
  myParticipant,
}: Props) {
  const dispatch = useDispatch();
  const isAllowedToAddBoxData = isBoxDataCreateAllowed(box);

  const unsubscribeToProcessingStart = React.useRef(null);
  const unsubscribeToProcessingDone = React.useRef(null);

  const videoKeyRef = React.useRef('');
  const resetVideoKeyRef = React.useCallback(() => {
    videoKeyRef.current = '';
  }, []);

  const [addVideoState, setAddVideoState] = React.useState({
    apiErrors: false,
    successMessage: null,
    uploadingError: null,
    uploadSuccess: false,
    uploadingVideo: false,
    processingVideo: false,
  });

  const updateAddVideoState = React.useCallback((newAddVideoState) => {
    setAddVideoState((prevAddVideoState) => ({
      ...prevAddVideoState,
      ...newAddVideoState,
    }));
  }, []);

  const agreement = useSelector((state) => (
    agreements.getAgreementSelector(state, { id: agreementId })
  ));

  const onProcessingStart = React.useCallback((processingData: any) => {
    const key = `videos/raw/${processingData?.videoIdentifier}`;

    if (key !== videoKeyRef.current) {
      return;
    }

    unsubscribeToProcessingStart.current();

    updateAddVideoState({
      uploadingError: null,
      uploadingVideo: false,
      processingVideo: true,
    });
  }, [updateAddVideoState]);

  const onProcessingDone = React.useCallback((processingData: any) => {
    const { success, errorCode } = processingData;

    if (errorCode) {
      updateAddVideoState({
        apiErrors: errorCode,
      });
      return;
    }

    const key = `videos/raw/${processingData?.videoIdentifier}`;
    if (key !== videoKeyRef.current) {
      return;
    }

    unsubscribeToProcessingDone.current();

    if (success) {
      updateAddVideoState({
        processingVideo: false,
        successMessage: uploadingVideoSuccessMessage,
        uploadSuccess: true,
      });

      const updatedBox = generateVideoBoxData(box, processingData, myParticipant?.id);
      dispatch(updateBoxAction(updatedBox));
    }
  }, [
    dispatch,
    box,
    myParticipant,
    updateAddVideoState,
  ]);

  const handleSnackbarSetMessages = React.useCallback(() => {
    if (addVideoState.uploadingError) {
      return (uploadingError) => {
        updateAddVideoState({ uploadingError });
      };
    }
    return (successMessage) => {
      updateAddVideoState({ successMessage });
    };
  }, [addVideoState, updateAddVideoState]);

  const onErrorHandler = React.useCallback((error) => {
    updateAddVideoState({
      uploadingError: error,
    });
  }, [updateAddVideoState]);

  const startUploading = React.useCallback(async (video) => {
    try {
      updateAddVideoState({
        uploadingVideo: true,
      });
      const { fields, url } = await getPreSignedPost();

      videoKeyRef.current = fields.key;

      await uploadVideoForBox({
        id: agreementId,
        file: video,
        url,
        fields,
      });

      unsubscribeToProcessingStart.current = webSocket.subscribe({
        channelName: `private-agreement-${agreementId}`,
        event: 'video:processing:start',
        eventCallback: onProcessingStart,
      });
      unsubscribeToProcessingDone.current = webSocket.subscribe({
        channelName: `private-agreement-${agreementId}`,
        event: 'video:processing:done',
        eventCallback: onProcessingDone,
      });

      await requestVideoProcessing({
        id: agreementId,
        key: fields.key,
      });
    } catch (error) {
      updateAddVideoState({
        apiErrors: error,
      });
    }
  }, [agreementId, updateAddVideoState, onProcessingStart, onProcessingDone]);

  const onVideoDrop = React.useCallback((acceptedFiles) => {
    const video = head(acceptedFiles);
    if (video) {
      startUploading(video);
    }
  }, [startUploading]);

  const onDropRejected = React.useCallback((fileRejections) => {
    const rejectedFile = head(fileRejections)?.file;
    const videoFileType = rejectedFile?.type;
    const validType = Boolean(videoFileType?.includes('video'));

    if (rejectedFile.size > MAX_VIDEO_SIZE) {
      updateAddVideoState({
        uploadingError: videoSizeErrorMessage,
      });
    }
    if (!validType) {
      updateAddVideoState({
        uploadingError: videoTypeErrorMessage,
      });
    }
    if (rejectedFile && rejectedFile.size < MAX_VIDEO_SIZE && validType) {
      updateAddVideoState({
        uploadingError: videoUploadingErrorMessage,
      });
    }
  }, [updateAddVideoState]);

  const contextValue = React.useMemo(() => ({
    box,
    myParticipant,
    agreementId,
    agreement,
    addVideoState,
    handleSnackbarSetMessages,
    onErrorHandler,
    onVideoDrop,
    onDropRejected,
    updateAddVideoState,
    resetVideoKeyRef,
    isAllowedToAddBoxData,
  }), [
    box,
    myParticipant,
    agreementId,
    agreement,
    addVideoState,
    handleSnackbarSetMessages,
    onErrorHandler,
    onVideoDrop,
    onDropRejected,
    updateAddVideoState,
    resetVideoKeyRef,
    isAllowedToAddBoxData,
  ]);

  return (
    <VideoBoxPropsContext.Provider value={contextValue}>
      {children}
    </VideoBoxPropsContext.Provider>
  );
}

export const useVideoBoxProps = () => {
  const contextValue = React.useContext(VideoBoxPropsContext);

  if (!contextValue) {
    throw new Error('useVideoBoxProps should be used inside a VideoBoxPropsContext');
  }

  return contextValue;
};
