import { useCallback } from 'react';
import { localize } from '@oneflowab/pomes';
import clsx from 'clsx';
import type { MessageTranslator } from '@oneflowab/pomes';

import { useProductTableBoxContext } from 'contexts/product-table-box-context';
import useIsExpandedView from 'hooks/use-is-expanded-view';
import type { ConfigUpdateData } from 'data-validators/entity-schemas/document-box/product-table';

import {
  InlineEditable,
  useInlineEditableProps,
} from 'components/inline-editable';
import {
  PRODUCT_TABLE_EXPANDED_VIEW_MIN_WIDTH,
  PRODUCT_TABLE_MIN_WIDTH_TOLERANCE_DELTA,
} from 'components/contract-boxes/product-table-box/constants';

import style from './product-table-box-name.module.scss';

type Props = {
  message: MessageTranslator,
  updateProductConfig: (configData: ConfigUpdateData) => void,
};

const NAME_MAX_LENGTH = 130;

const ProductTableBoxNameComponent = ({
  message,
  updateProductConfig,
}: Props) => {
  const {
    characterCount,
    isFocused,
  } = useInlineEditableProps();
  const {
    config,
    isAllowedToEditBoxData,
  } = useProductTableBoxContext();
  const isExpandedView = useIsExpandedView(
    PRODUCT_TABLE_EXPANDED_VIEW_MIN_WIDTH,
    PRODUCT_TABLE_MIN_WIDTH_TOLERANCE_DELTA,
  );

  const saveFileName = useCallback((productTableName: string) => {
    const updatedProductTableName = {
      header: {
        ...config.header,
        label: productTableName,
      },
    };

    updateProductConfig(updatedProductTableName);
  }, [config.header, updateProductConfig]);

  const renderCharacterCount = () => {
    if (!isFocused) {
      return null;
    }

    return (
      <span
        className={clsx(style.CharacterCount, {
          [style.Error]: characterCount === NAME_MAX_LENGTH,
        })}
      >
        {`${characterCount} / ${NAME_MAX_LENGTH}`}
      </span>
    );
  };

  return (
    <div
      className={clsx(style.InlineEditableContainer, {
        [style.CollapsedView]: !isExpandedView,
      })}
    >
      <InlineEditable
        characterLimit={NAME_MAX_LENGTH}
        className={style.EditableElement}
        defaultValue={message({
          id: 'Untitled',
          comment: 'Default product table name',
        }) as string}
        editableClassName={clsx(style.EditableElement)}
        onChange={saveFileName}
        readOnly={!isAllowedToEditBoxData}
        value={config.header.label}
      />
      {renderCharacterCount()}
    </div>
  );
};

const ProductTableBoxName = localize<Props>(ProductTableBoxNameComponent);

export {
  ProductTableBoxName,
  ProductTableBoxNameComponent,
};
