/* eslint-disable camelcase */
import { roundBy } from 'utils/math';

import { useProductTableBoxContext } from 'contexts/product-table-box-context';
import type { PriceRoundingMethod } from 'data-validators/entity-schemas/document-box/product-table';

import {
  DEFAULT_DISCOUNT_ROUNDING_PRECISION,
  NO_DISCOUNT,
  PRICE_STEP,
  PRODUCT_NUMERIC_DISCOUNT,
  PRODUCT_PERCENTAGE_DISCOUNT,
} from 'components/contract-boxes/product-table-box/constants';

export const INVALID_PERCENTAGE = 'INVALID PERCENTAGE';
export const VALID_AS_REGULAR_AMOUNT = 'VALID AS REGULAR AMOUNT';
export const HIGHER_PERCENTAGE = 'HIGHER PERCENTAGE';
export const LOWER_PERCENTAGE = 'LOWER PERCENTAGE';
export const VALID_PERCENTAGE = 'VALID PERCENTAGE';

type DiscountPercentageAmountType = number
  | typeof INVALID_PERCENTAGE
  | typeof VALID_AS_REGULAR_AMOUNT;

// Calculate the step size based on the number of decimal places
export const getStep = (precision: number) => 10 ** -precision;
export const isValueSet = (numericalString: unknown) => numericalString !== '';

export const countDecimalPlaces = (number: number | string) => {
  const decimalIndex = number.toString().indexOf('.');
  if (decimalIndex === -1) {
    return 0;
  }
  return number.toString().length - decimalIndex - 1;
};

export const canCastToNumber = (
  numericalString: string | unknown,
) => !Number.isNaN(Number(numericalString));

const isNonZero = (numericalValue: number | string) => canCastToNumber(
  numericalValue,
) && Boolean(Number(numericalValue));

export const canCompareInBusinessContext = (
  firstValue: number | string, secondValue: number | string, condition = 'nonZero',
) => {
  if (condition === 'nonZero') {
    return isNonZero(firstValue) && isNonZero(secondValue);
  }

  // When conditions are not defined here
  return true;
};

export const adjustNumberOnArrowKeyDown = (
  keyDown: string,
  inputValue: string,
  quantityPrecision?: number,
) => {
  const isPercentValue = inputValue.endsWith('%');
  let updatedPriceValue = inputValue;

  if (isPercentValue) {
    // Remove the '%' before parsing
    updatedPriceValue = inputValue.slice(0, -1);
  }

  if (inputValue === '') {
    updatedPriceValue = '0';
  }
  const number = parseFloat(updatedPriceValue);

  if (Number.isNaN(number)) {
    return updatedPriceValue;
  }

  let newNumber;
  let stepSize = 1;
  let decimalPlaces = 0;

  if (quantityPrecision) {
    stepSize = getStep(quantityPrecision);
  }
  // Check if the number has decimal places
  if (updatedPriceValue.includes('.') && !quantityPrecision) {
    decimalPlaces = countDecimalPlaces(updatedPriceValue);
    stepSize = getStep(decimalPlaces);
  }

  if (keyDown === 'ArrowUp') {
    newNumber = number + stepSize;
  } else if (keyDown === 'ArrowDown') {
    newNumber = number - stepSize;
  }

  if (quantityPrecision) {
    newNumber = (newNumber as number).toFixed(quantityPrecision);
  } else {
    newNumber = (newNumber as number).toFixed(decimalPlaces);
  }

  if (isPercentValue) {
    return `${newNumber}%`;
  }
  return newNumber;
};

export const isStepMismatch = (amount: string | undefined) => {
  if (!amount) {
    return false;
  }

  const decimalPlaces = PRICE_STEP.toString().split('.')[1]?.length || 0;

  return amount.includes('.') && amount.split('.')[1].length > decimalPlaces;
};

export const isDiscountHigherThanPrice = (
  discount: number | string, price: number | string,
) => Number(discount) > Number(price);

export const getDiscountPercentageAmount = (
  discountPrice: string | undefined,
): DiscountPercentageAmountType => {
  const percentageAmount = Number(discountPrice?.slice(0, -1));
  if (discountPrice?.endsWith('%') && !Number.isNaN(percentageAmount)) {
    return percentageAmount;
  }

  if (!Number.isNaN(Number(discountPrice))) {
    return VALID_AS_REGULAR_AMOUNT;
  }

  return INVALID_PERCENTAGE;
};

export const isValidPercentage = (
  numericalString: string | undefined,
) => numericalString?.endsWith('%')
  && !Number.isNaN(
    Number(numericalString.slice(0, -1)),
  );

export const checkDiscountPercentage = (discountAmount: string) => {
  const discountPercentageAmount = getDiscountPercentageAmount(discountAmount);

  if (
    discountPercentageAmount === INVALID_PERCENTAGE
    || discountPercentageAmount === VALID_AS_REGULAR_AMOUNT
  ) {
    return discountPercentageAmount;
  }

  if (discountPercentageAmount > 100) {
    return HIGHER_PERCENTAGE;
  }

  if (discountPercentageAmount <= 0) {
    return LOWER_PERCENTAGE;
  }

  return VALID_PERCENTAGE;
};

export const isDiscountPercentageLower = (discountAmount: string) => checkDiscountPercentage(
  discountAmount,
) === LOWER_PERCENTAGE;

export const isDiscountPercentageHigher = (discountAmount: string) => checkDiscountPercentage(
  discountAmount,
) === HIGHER_PERCENTAGE;

/**
 * validate if input can be cast to number: either numerical string
    or numerical string with % at the end
 * @param numericalString
 * @returns boolean
 */
// Possibly higher than 100%
export const isValidDiscountInputValue = (
  numericalString: string,
) => isValidPercentage(numericalString) || canCastToNumber(numericalString);

export const getDiscountAmount = (
  currentPriceRoundingMethod: PriceRoundingMethod,
  pricePrecision: number,
  discountAmount?: string | null | undefined,
): number | null => {
  // Backend default value for discount amount is 0 when property is missing
  // In the request data so we explicitly set null for now to avoid it.
  // And in API it will be fixed as well
  if (!discountAmount) {
    return null;
  }

  const percentageAmount = getDiscountPercentageAmount(discountAmount);

  if (
    percentageAmount !== INVALID_PERCENTAGE && percentageAmount !== VALID_AS_REGULAR_AMOUNT
  ) {
    // Valid percentage amount without % 50% => (50 / 100) => 0.5
    return roundBy(
      currentPriceRoundingMethod,
      percentageAmount / 100,
      DEFAULT_DISCOUNT_ROUNDING_PRECISION,
    );
  }

  const discountValue = Number(discountAmount);
  if (percentageAmount === VALID_AS_REGULAR_AMOUNT && discountValue > 0) {
    return roundBy(currentPriceRoundingMethod, Number(discountAmount), pricePrecision);
  }

  return null;
};

export const createGetPriceDiscounted = (
  currentPriceRoundingMethod: PriceRoundingMethod,
  pricePrecision: number,
) => (
  price?: string | null,
  discountAmount?: string,
) => {
  // TODO: @check Should priceValue be null as well when price is null?
  const priceValue = Number(price) || 0;

  if (!discountAmount && canCastToNumber(price)) {
    return roundBy(
      currentPriceRoundingMethod,
      priceValue,
      pricePrecision,
    ) as number;
  }

  const percentageAmount = getDiscountPercentageAmount(discountAmount);

  if (
    percentageAmount !== INVALID_PERCENTAGE && percentageAmount !== VALID_AS_REGULAR_AMOUNT
  ) {
    const roundedPriceValue = roundBy(
      currentPriceRoundingMethod,
      priceValue,
      pricePrecision,
    ) as number;

    const roundedPercentageAmount = roundBy(
      currentPriceRoundingMethod,
      percentageAmount / 100,
      DEFAULT_DISCOUNT_ROUNDING_PRECISION,
    );

    const priceDiscounted = roundedPriceValue * (1 - roundedPercentageAmount);

    const roundedPriceDiscounted = roundBy(
      currentPriceRoundingMethod,
      priceDiscounted,
      DEFAULT_DISCOUNT_ROUNDING_PRECISION,
    ) as number;

    return roundedPriceDiscounted;
  }

  if (canCastToNumber(discountAmount)) {
    const priceDiscounted = priceValue - Number(discountAmount);
    const roundedPriceDiscounted = roundBy(
      currentPriceRoundingMethod,
      priceDiscounted,
      pricePrecision,
    ) as number;

    return roundedPriceDiscounted;
  }

  return priceValue;
};

export const getDiscountType = (discountAmount: string | undefined) => {
  if (isValidPercentage(discountAmount)) {
    return PRODUCT_PERCENTAGE_DISCOUNT;
  }

  if (discountAmount && canCastToNumber(discountAmount)) {
    return PRODUCT_NUMERIC_DISCOUNT;
  }

  return NO_DISCOUNT;
};

export type EnabledColumnsKeyLabelMapper= ReturnType<
  typeof useProductTableBoxContext
>['enabledColumnsKeyLabelMapper'];

export type FormBody = {
  count_type?: string;
  counterPartyCanOnlyRead?: '1' | '0', // represents readOnly
  name?: string;
  price_1?: string;
  price_1_discount_amount?: string;
  price_2?: string;
  price_2_discount_amount?: string;
};

export type PriceKey = 'price_1' | 'price_2';

export type UpdatedProduct = {
  name?: string;
  price_1?: number;
  price_1_discount_amount?: number;
  price_1_discount_type?: number;
  price_1_discounted?: number;
  price_2?: number;
  price_2_discount_amount?: number;
  price_2_discount_type?: number;
  price_2_discounted?: number;
  readOnly: number;
  count_type: number;
  count?: number | null;
};

export const getPriceRelated = (
  bodyData: FormBody,
  enabledColumnsKeyLabelMapper: EnabledColumnsKeyLabelMapper,
  currentPriceRoundingMethod: PriceRoundingMethod,
  priceKey: PriceKey,
  pricePrecision: number,
) => {
  const getPriceDiscounted = createGetPriceDiscounted(currentPriceRoundingMethod, pricePrecision);
  const price = Number(bodyData[priceKey]);

  let productData = {} as Omit<UpdatedProduct, 'name' | 'readOnly'>;

  // Intending to overwrite previously stored values of then enabled now disabled price
  // TODO: In redux layer we still add those properties and check if it can be avoided
  if (!Object.hasOwn(enabledColumnsKeyLabelMapper, priceKey)) {
    return productData;
  }

  if (
    Object.hasOwn(bodyData, priceKey)
    && isValueSet(bodyData[priceKey])
    && price >= 0
  ) {
    productData[priceKey] = price;
    productData[`${priceKey}_discount_type`] = NO_DISCOUNT;
  }

  const discountAmount = getDiscountAmount(currentPriceRoundingMethod, pricePrecision, bodyData[`${priceKey}_discount_amount`]);
  if (discountAmount || discountAmount === null) {
    const priceRelated = {
      [`${priceKey}_discount_amount`]: discountAmount,
      [`${priceKey}_discounted`]: getPriceDiscounted(
        bodyData[priceKey],
        bodyData[`${priceKey}_discount_amount`],
      ),
      [`${priceKey}_discount_type`]: getDiscountType(bodyData[`${priceKey}_discount_amount`]),
    };

    productData = {
      ...productData,
      [priceKey]: productData?.[priceKey] ?? null,
      ...priceRelated,
    };
  }

  return productData;
};
