import * as React from 'react';
import { take, sumBy, isEmpty } from 'lodash';
import clsx from 'clsx';

import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import * as participantConstants from 'agreement/participant/constants';
import { UserBadge } from 'components/user-badge';

import ViewCountEye from 'components/icons/view-count-eye';
import Tooltip from 'components/tooltip';
import ParticipantStatistics from 'components/contract-card/participant-statistics';
import ParticipantBubble, { SimpleBubble } from 'components/contract-card/participant-bubble';

import {
  isDraft,
  isPending,
  isOverdue,
  isSigned,
  isDeclined,
} from 'agreement';

import style from './sign-order-participants.module.scss';

type Props = {
  counterparties: Array<AgreementParty>,
  participants: Array<AgreementParticipant>,
  agreement: Agreement,
  isContractMarkedAsSigned: boolean,
  disabled?: boolean,
  disableTooltip?: boolean,
  sortBySignedOrder?: boolean,
  disableViewCount?: boolean
};

type RenderParticipantBubbleOptions = {
  size?: 'default' | 'big',
  index?: number,
  hasSigned?: boolean
};

const MAX_VISIBLE_PARTICIPANTS = 4;
const NUMBER_OF_PARTICIPANTS_WHEN_LIMITED = MAX_VISIBLE_PARTICIPANTS - 1;
const DIAMETER_BIG = 66;
const DIAMETER_DEFAULT = 46;

export class SignOrderParticipants extends React.PureComponent<Props> {
  static defaultProps = {
    disableTooltip: false,
    sortBySignedOrder: false,
    disableViewCount: false,
  }

  getBorderColor = (overrideColor: string) => {
    const { agreement } = this.props;

    if (overrideColor) {
      return overrideColor;
    }

    if (isSigned(agreement)) {
      return style.lightgreen;
    }

    if (isDeclined(agreement)) {
      return style.lightred;
    }

    if (isDraft(agreement)) {
      return style.gray;
    }

    if (isPending(agreement)) {
      return style.gray;
    }

    if (isOverdue(agreement)) {
      return style.orange;
    }

    return undefined;
  };

  renderViewCount() {
    const {
      counterparties,
      disabled,
      disableViewCount,
    } = this.props;

    if (isEmpty(counterparties) || disableViewCount) {
      return null;
    }

    const viewCount = sumBy(counterparties, (party: AgreementParty) => (
      sumBy(party.participants, 'visits')
    ));

    return (
      <div className={style.AggregatedInfo}>
        <Tooltip
          message={<Message id="Total number of views from counterparty participants." comment="Tooltip text. Describes how to interpret a number, which represents sum of all views on all counterparty contract participants." />}
          triggerClassName={style.TooltipInfo}
          hideContent={disabled}
          zIndex="10003"
        >
          <span>
            <ViewCountEye className={style.Icon} />
            {viewCount}
          </span>
        </Tooltip>
      </div>
    );
  }

  renderParticipantBubble = (
    participant: AgreementParticipant,
    {
      index,
      size,
      hasSigned,
    }: RenderParticipantBubbleOptions,
  ) => {
    const {
      agreement,
      isContractMarkedAsSigned,
      disableTooltip,
    } = this.props;

    const badgeClasses = clsx(style.Badge, {
      [style[`BadgeI${MAX_VISIBLE_PARTICIPANTS - index}`]]: hasSigned,
    });

    const renderBadge = ({ fillColor, noBorder }) => {
      let borderColor = null;
      if (fillColor === 'green' || hasSigned) {
        borderColor = style.bordergreen;
      }
      if (size === 'big') {
        borderColor = style.darkgray;
      }
      return (
        <div
          className={badgeClasses}
        >
          <UserBadge
            diameter={size === 'big' ? DIAMETER_BIG : DIAMETER_DEFAULT}
            borderSize={2}
            fillColor={fillColor}
            borderColor={this.getBorderColor(borderColor)}
            noBorder={noBorder}
          />
        </div>
      );
    };

    const participantBubbleClasses = clsx({
      [style[`BadgeI${MAX_VISIBLE_PARTICIPANTS - index}`]]: hasSigned,
      [style.MyParticipantBubble]: Boolean(participant.self),
    });

    return (
      <Tooltip
        hideContent={disableTooltip}
        message={(
          <ParticipantStatistics
            agreement={agreement}
            participant={participant}
          />
        )}
        side="top"
        triggerClassName={style.Participant}
        key={participant.id}
        theme="light"
        touchable
      >
        <span>
          <ParticipantBubble
            participant={participant}
            isContractMarkedAsSigned={isContractMarkedAsSigned}
            agreement={agreement}
            renderBadge={renderBadge}
            className={participantBubbleClasses}
          />
        </span>
      </Tooltip>
    );
  }

  renderExtraBubble(participants: Array<AgreementParticipant>, fillColor: 'white' | 'green' = 'white') {
    const { sortBySignedOrder } = this.props;
    if (participants.length <= MAX_VISIBLE_PARTICIPANTS) {
      return null;
    }

    const ExtraBubbleLabelClasses = clsx({
      [style.ExtraBubbleLabelWhite]: fillColor === 'green',
      [style.ExtraBubbleLabelBlack]: fillColor === 'white',
    });

    const renderDefaultBadge = () => (
      <div className={style.SimpleBubbleUserBadge}>
        <UserBadge
          diameter={DIAMETER_DEFAULT}
          borderSize={2}
          fillColor={fillColor === 'green' ? '#3cc182' : undefined}
          borderColor={this.getBorderColor(fillColor === 'green' ? style.bordergreen : null)}
        />
      </div>
    );

    return (
      <div className={style.Participant}>
        <SimpleBubble
          label={(
            <span className={sortBySignedOrder ? ExtraBubbleLabelClasses : null}>
              +
              {participants.length - NUMBER_OF_PARTICIPANTS_WHEN_LIMITED}
            </span>
          )}
          renderBadge={renderDefaultBadge}
        />
      </div>
    );
  }

  renderParticipantsBubbles() {
    const { participants, sortBySignedOrder, agreement } = this.props;
    const isSingleSignEnabled = agreement.config?.singleSign;
    const participantsWithoutMyParticipant = participants
      .filter((participant) => !participant.self);
    const signatories = participantsWithoutMyParticipant
      .filter((participant) => participant.type === participantConstants.TYPE_IS_SIGNATORY);
    const myParticipant = participants.find((participant) => participant.self);
    const signedParticipants = signatories
      .filter((participant) => participant.state === participantConstants.STATE_HAS_SIGNED);
    const unSignedParticipants = signatories
      .filter((participant) => participant.state === participantConstants.STATE_UNDECIDED);

    let limitedParticipants = participants;
    let limitedSignedParticipants = signedParticipants;
    let limitedUnSignedParticipants = unSignedParticipants;

    if (limitedParticipants.length > MAX_VISIBLE_PARTICIPANTS) {
      limitedParticipants = take(limitedParticipants, NUMBER_OF_PARTICIPANTS_WHEN_LIMITED);
    }
    if (limitedSignedParticipants.length > MAX_VISIBLE_PARTICIPANTS) {
      limitedSignedParticipants = take(
        limitedSignedParticipants, NUMBER_OF_PARTICIPANTS_WHEN_LIMITED,
      );
    }
    if (limitedUnSignedParticipants.length > MAX_VISIBLE_PARTICIPANTS) {
      limitedUnSignedParticipants = take(
        limitedUnSignedParticipants, NUMBER_OF_PARTICIPANTS_WHEN_LIMITED,
      );
    }

    if (sortBySignedOrder) {
      return (
        <div className={style.SignedOrderBubbles}>
          <div className={style.Bubbles}>
            {!isSingleSignEnabled && (
              <div className={style.UnSignedParticipants}>
                {this.renderExtraBubble(unSignedParticipants, 'white')}
                {limitedUnSignedParticipants.map((participant, i) => this.renderParticipantBubble(
                  participant,

                  {
                    index: i,
                    hasSigned: false,
                    size: 'default',
                  },
                ))}
              </div>
            )}
            <div className={style.MyParticipant}>
              {this.renderParticipantBubble(myParticipant, { size: 'big' })}
            </div>
            {!isSingleSignEnabled && (
              <div className={style.SignedParticipants}>
                {limitedSignedParticipants.map((participant, i) => this.renderParticipantBubble(
                  participant,

                  {
                    index: i,
                    hasSigned: true,
                    size: 'default',
                  },
                ))}
                {this.renderExtraBubble(signedParticipants, 'green')}
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={style.Bubbles}>
        {limitedParticipants.map(this.renderParticipantBubble)}
        {this.renderExtraBubble(participants, 'white')}
      </div>
    );
  }

  render() {
    return (
      <div className={style.Participants}>
        {this.renderParticipantsBubbles()}
        {this.renderViewCount()}
      </div>
    );
  }
}

SignOrderParticipants.defaultProps = {
  disabled: undefined,
};

export default SignOrderParticipants;
