import {
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isNumber } from 'lodash';

import { getAccountFromSessionSelector } from 'reducers/session';
import useTemplateGroups from 'hooks/use-template-groups';
import useAgreement from 'hooks/use-agreement';
import { hasExtensionAdminPermission, hasActiveTemplateGroups } from 'data-fields';
import { isTemplate } from 'agreement/states';

import { ScrollableArea } from 'components/document-tabs/components/scrollable-area';
import { TabHeader } from 'components/document-tabs/components/tab-header';
import DataFieldsFilter from 'components/document-tabs/data-fields-tab/data-fields-filter';
import DataFieldsList from 'components/document-tabs/data-fields-tab/data-fields-list';
import DataFieldSort from 'components/document-tabs/data-fields-tab/data-field-sort';
import TemplateGroupSection from 'components/document-tabs/data-fields-tab/template-group-section';

import CloseSidebarButton from 'components/expanded-layout-sidebar/close-sidebar-button';
import Message from 'components/message';
import { EmptyStateFilter, EmptyStateDataFields } from 'components/document-tabs/data-fields-tab/empty-state';
import Button from 'components/button';
import style from './data-fields-tab.module.scss';

type Props = {
  agreementId: Oneflow.Agreement['id'],
  setSelectedTemplateGroupIdForContext: Dispatch<SetStateAction<number>>,
  onClose: () => void,
};

const getDataFields = ({ data }: { data: Oneflow.AgreementData[] }) => {
  if (!Array.isArray(data)) {
    return {};
  }

  return data
    .reduce((result: Record<number, Oneflow.AgreementData>, value: Oneflow.AgreementData) => {
      if (value.key === 'data_field') {
        return { ...result, [value.id]: value };
      }
      return result;
    }, {});
};

const DataFieldsTab = ({
  agreementId,
  setSelectedTemplateGroupIdForContext,
  onClose,
}: Props) => {
  const account: Oneflow.Account = useSelector(getAccountFromSessionSelector);
  const agreement: Oneflow.Agreement = useAgreement(agreementId);
  const isAgreementTemplate = isTemplate(agreement);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showEmptyListState, setShowEmptyListState] = useState<boolean>(false);
  const selectedTemplateGroup = agreement?.templateGroup;
  const selectedTemplateGroupId = agreement?.templateGroup?.id || 0;

  const noSelectedTemplateGroup = isNumber(
    selectedTemplateGroupId,
  ) && selectedTemplateGroupId === 0;
  // Using the agreement data instead of allDataFields from data-fields context.
  // This is because loading speed being much quicker.
  const allDataFields = getDataFields({ data: agreement.data });

  const isTemplateGroupSelectedAndDataFieldsEmpty = !noSelectedTemplateGroup
    && isEmpty(allDataFields);
  const templateGroups = useTemplateGroups();

  const activeTemplateGroups = hasActiveTemplateGroups(templateGroups);
  const extensionAdmin = hasExtensionAdminPermission(account);

  const linkTo = isTemplateGroupSelectedAndDataFieldsEmpty
    ? `/marketplace/template-groups/${selectedTemplateGroupId}/data-fields`
    : '/marketplace/template-groups';

  const renderExtensionPageLink = () => (
    <Button
      href={linkTo}
      kind="linkInline"
      customClass={style.ExtensionLink}
      external
    >
      <Message
        id="extension page"
        comment="Link to redirect users to extension page."
      />
    </Button>
  );
  const extensionPageLink = renderExtensionPageLink();

  return (
    <>
      <TabHeader>
        <h2 className={style.DataFieldHeader}>
          <Message id="Data Fields" comment="Header in the data fields tab" />
        </h2>
        {onClose && <CloseSidebarButton onClose={onClose} />}
      </TabHeader>
      <div className={style.TemplateGroupSectionContainer}>
        <TemplateGroupSection
          agreementId={agreementId}
          setSelectedTemplateGroupIdForContext={setSelectedTemplateGroupIdForContext}
          isDocumentLayout
        />
      </div>
      {noSelectedTemplateGroup && (
        activeTemplateGroups ? (
          <div className={style.EmptyStateContainer}>
            <EmptyStateDataFields
              headerMessage={(
                <Message
                  id="No template group selected"
                  comment="Empty state header. Shown in data field tab when no template groups are available."
                />
              )}
              contentMessage={(
                <Message
                  id="Select a template group from the drop down to use data fields."
                  comment="Empty state content. Shown in data field tab when no template group is selected."
                />
              )}
            />
          </div>
        ) : (
          <div className={style.EmptyStateContainer}>
            <EmptyStateDataFields
              headerMessage={(
                <Message
                  id="No template groups yet"
                  comment="Empty state header. Shown in data field tab when no template groups are available."
                />
              )}
              contentMessage={isAgreementTemplate && extensionAdmin ? (
                <Message
                  id="Create a template group and add data fields in the {extensionPage}."
                  values={{ extensionPage: extensionPageLink }}
                  comment="Empty state content. Shown in data field tab when no template groups are available."
                />
              ) : null}
            />
          </div>
        ))}
      {isTemplateGroupSelectedAndDataFieldsEmpty ? (
        <div className={style.EmptyStateContainer}>
          <EmptyStateDataFields
            headerMessage={(
              <Message
                id="Empty template group"
                comment="Empty state header. Shown in data field tab when no data fields in that template group exists."
              />
            )}
            contentMessage={(isAgreementTemplate && extensionAdmin ? (
              <Message
                id="Go to the {extensionPage} to add data fields."
                values={{ extensionPage: extensionPageLink }}
                comment="Empty state content. Shown in data field tab when no data fields in that template group exists."
              />
            ) : null
            )}
          />
        </div>
      ) : (
        <>
          {selectedTemplateGroup && (
            <>
              <div className={style.InputContainer}>
                <DataFieldsFilter
                  setShowEmptyState={setShowEmptyListState}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                />
              </div>
              {!showEmptyListState && (
                <>
                  <div className={style.DataFieldSortContainer}>
                    <DataFieldSort />
                  </div>
                  <ScrollableArea className={style.ScrollAreaTopPadding}>
                    <DataFieldsList />
                  </ScrollableArea>
                </>
              )}
            </>
          )}
          {showEmptyListState ? <EmptyStateFilter searchTerm={searchTerm} /> : null}
        </>
      )}
    </>
  );
};

export default DataFieldsTab;
