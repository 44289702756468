import { Editor, Transforms } from 'slate';

const getEmptyTable = () => ({
  type: 'table',
  columns: [{ width: 100 }, { width: 100 }],
  children: [{
    type: 'table-body',
    children: [{
      type: 'table-row',
      children: [{
        type: 'table-cell',
        children: [{
          type: 'paragraph',
          children: [{
            text: '',
          }],
        }],
      }, {
        type: 'table-cell',
        children: [{
          type: 'paragraph',
          children: [{
            text: '',
          }],
        }],
      }],
    }, {
      type: 'table-row',
      children: [{
        type: 'table-cell',
        children: [{
          type: 'paragraph',
          children: [{
            text: '',
          }],
        }],
      }, {
        type: 'table-cell',
        children: [{
          type: 'paragraph',
          children: [{
            text: '',
          }],
        }],
      }],
    }],
  }],
});

// eslint-disable-next-line import/prefer-default-export
export const addTable = (editor) => {
  Transforms.insertNodes(editor, [getEmptyTable(), {
    type: 'paragraph',
    children: [{ text: '' }],
  }], {
    select: true,
  });

  const [cell] = Editor.nodes(editor, {
    match: (n) => n.type === 'table-cell',
    mode: 'lowest',
    at: Editor.before(editor, editor.selection),
  });

  if (!cell) {
    return;
  }

  const newPath = [...cell[1]];

  newPath[newPath.length - 1] = 0;
  newPath[newPath.length - 2] = 0;
  newPath.push(0);

  Transforms.select(editor, newPath);
};
