// @flow

import { connect } from 'react-redux';

import groupsReducer from 'reducers/entities/groups';
import groupPositions from 'reducers/entities/group-positions';

import { getAccountFromSessionSelector } from 'reducers/session';

import Group from './group';

export const mapStateToProps = (state, ownProps) => {
  const account = getAccountFromSessionSelector(state);
  const group = groupsReducer.getGroupSelector(state, { id: ownProps.match.params.id });

  return {
    group,
    account,
    fetchState: groupsReducer.getFetchSelector(state, { id: ownProps.match.params.id }),
    membersState: groupPositions.getCreateSelector(state),
    removeState: groupPositions.getRemoveSelector(state, { id: group.id }),
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchGroup: () => {
    dispatch(groupsReducer.fetchGroup({
      id: ownProps.match.params.id,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Group);
