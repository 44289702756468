// @flow

import * as React from 'react';
import { Prompt } from 'react-router';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

type Props = {|
  show: boolean,
  message: MessageTranslator,
|};

export class OnBeforeUnloadComponent extends React.Component<Props> {
  componentDidMount() {
    window.addEventListener('beforeunload', this.onBeforeunLoad);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeunLoad);
  }

  onBeforeunLoad = (e: BeforeUnloadEvent) => {
    const { show } = this.props;

    if (show) {
      e.preventDefault();

      e.returnValue = '';

      return '';
    }

    return undefined;
  };

  render() {
    const { show, message } = this.props;

    return (
      <Prompt
        when={show}
        message={message({
          id: 'Changes that you made may not be saved.',
          comment: 'The text message when user wants to leave the page while there are unsaved changes in the page.',
        })}
      />
    );
  }
}

export default localize<Props>(OnBeforeUnloadComponent);
