import { connect } from 'react-redux';

import groupPositionsReducer from 'reducers/entities/group-positions';

import { RemoveMembers } from './remove-members';

export const mapStateToProps = (state, { group }) => ({
  removeState: groupPositionsReducer.getRemoveSelector(state, { id: group.id }),
});

export const mapDispatchToProps = (dispatch, { group }) => ({
  removeMembers: (ids) => {
    dispatch(groupPositionsReducer.removeGroupPosition({
      id: group.id,
      data: {
        positionIds: ids,
        groupId: group.id,
      },
      pipe: {
        action: () => groupPositionsReducer.queryGroupPositionsReload({
          name: `admin/groups/${group.id}/members`,
        }),
        wait: groupPositionsReducer.QUERY_GROUP_POSITIONS_SUCCESS,
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(groupPositionsReducer.removeGroupPositionReset({
      id: group.id,
    }));
  },
});

const connectedRemoveMembers = connect(mapStateToProps, mapDispatchToProps)(RemoveMembers);

export { connectedRemoveMembers as RemoveMembers };
