/* eslint-disable camelcase */
import clsx from 'clsx';
import { uniqueId } from 'lodash';

import { pricePrecision } from 'utils/format-number-with-trailing-zeros';
import type { ProductTableBox, PriceColumn, Product } from 'data-validators/entity-schemas/document-box/product-table';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import {
  PRODUCT_COUNT_TYPE_NUMBER,
  PRODUCT_TABLE_COLUMNS,
} from 'components/contract-boxes/product-table-box/constants';
import ProductPrice from 'components/contract-boxes/product-table-box/product-table/product-price-responsive';
import ProductTablePopup from 'components/contract-boxes/product-table-box/product-table/product-table-popup';
import QuantityInput from 'components/contract-boxes/product-table-box/product-table/quantity-input/quantity-input';
import UpdateProductItemPopup from 'components/contract-boxes/product-table-box/product-table/product-table-popup/update-product-item-popup';
import type { Props as ProductRowProps } from 'components/contract-boxes/product-table-box/product-table/product-table-expanded/product-row/product-row';

import style from '../product-table-responsive.module.scss';

type Props = ProductRowProps & {
  columns: PriceColumn[]
  row: Product,
  updateProductConfig: ProductTableBox['config'],
  isAllowedToUpdateDataValue: boolean,
  isResponsiveView: boolean,
}

const NumberTerms = ({
  chosenProduct,
  columns,
  config,
  isAllowedToUpdateDataValue,
  isGuestView,
  isResponsiveView,
  onClearChosenProduct,
  removeProduct,
  row,
  updateChosenProduct,
  updateProductConfig,
  updateProductData,
}: Props) => {
  const renderNumberItems = () => columns.map((column) => {
    if ([PRODUCT_TABLE_COLUMNS.NAME, PRODUCT_TABLE_COLUMNS.DESCRIPTION].includes(column.key)) {
      return null;
    }

    const columnLabelEnabled = column.key && column.enabled ? column.label : null;
    const hasPrice1KeyInConfig = column.key === PRODUCT_TABLE_COLUMNS.PRICE_1;
    const hasPrice2KeyInConfig = column.key === PRODUCT_TABLE_COLUMNS.PRICE_2;
    const hasProductCountKeyInConfig = column.key === PRODUCT_TABLE_COLUMNS.COUNT;
    const shouldShowPrice1 = hasPrice1KeyInConfig && column.enabled && (
      row.value?.price_1 === 0 || Object.hasOwn(row.value, 'price_1')
    );
    const shouldShowPrice2 = hasPrice2KeyInConfig && column.enabled && (
      row.value?.price_2 === 0 || Object.hasOwn(row.value, 'price_2')
    );
    let cellContent;

    if (hasProductCountKeyInConfig && column.enabled) {
      const { name, count, count_type } = row.value || {};
      cellContent = (
        <>
          <UpdateProductItemPopup
            asChild
            dataPopupId={uniqueId()}
            onClearChosenProduct={onClearChosenProduct}
            productId={getId(row)}
            readOnly={!isAllowedToUpdateDataValue}
            removeProduct={removeProduct}
            updateProductData={updateProductData}
            value={row.value}
          >
            <div />
          </UpdateProductItemPopup>
          <QuantityInput
            chosenProduct={chosenProduct}
            count_type={count_type || PRODUCT_COUNT_TYPE_NUMBER}
            count={count}
            isGuestView={isGuestView}
            isResponsiveView={isResponsiveView}
            productId={getId(row)}
            productName={name}
            updateChosenProduct={updateChosenProduct}
            updateProductData={updateProductData}
          />
        </>
      );
    }

    if (shouldShowPrice1) {
      cellContent = (
        <UpdateProductItemPopup
          dataPopupId={uniqueId()}
          onClearChosenProduct={onClearChosenProduct}
          productId={getId(row)}
          readOnly={!isAllowedToUpdateDataValue}
          removeProduct={removeProduct}
          updateProductData={updateProductData}
          value={row.value}
        >
          <ProductPrice
            price={row.value.price_1}
            discountAmount={row.value.price_1_discount_amount}
            priceDiscounted={row.value.price_1_discounted}
            discountType={row.value.price_1_discount_type}
            prefix={config.affixes.prefix}
            postfix={config.affixes.postfix}
            pricePrecision={pricePrecision(config.pricePrecision)}
          />
        </UpdateProductItemPopup>
      );
    }

    if (shouldShowPrice2) {
      cellContent = (
        <UpdateProductItemPopup
          dataPopupId={uniqueId()}
          onClearChosenProduct={onClearChosenProduct}
          productId={getId(row)}
          readOnly={!isAllowedToUpdateDataValue}
          removeProduct={removeProduct}
          updateProductData={updateProductData}
          value={row.value}
        >
          <ProductPrice
            price={row.value.price_2}
            discountAmount={row.value.price_2_discount_amount}
            priceDiscounted={row.value.price_2_discounted}
            discountType={row.value.price_2_discount_type}
            prefix={config.affixes.prefix}
            postfix={config.affixes.postfix}
            pricePrecision={pricePrecision(config.pricePrecision)}
          />
        </UpdateProductItemPopup>
      );
    }

    if (
      (hasProductCountKeyInConfig || hasPrice1KeyInConfig || hasPrice2KeyInConfig)
      && !column.enabled) {
      return null;
    }

    return (
      <div key={column.key} role="columnheader" className={style.ProductTableItems} data-testid="number-item-header">
        <dt className={style.TableCellLabel} data-testid="description-label">
          <ProductTablePopup
            align="start"
            column={column}
            config={config}
            disabled={!isAllowedToUpdateDataValue}
            side="bottom"
            sideOffset={5}
            updateProductConfig={updateProductConfig}
            updateProductData={updateProductData}
          >
            {columnLabelEnabled}
          </ProductTablePopup>
        </dt>
        <dd
          className={clsx(
            style.TableCellValue,
            { [style.QuantityInputCell]: hasProductCountKeyInConfig && column.enabled },
          )}
          data-testid="description-value"
        >
          {cellContent}
        </dd>
      </div>
    );
  });

  return (
    <dl className={style.DataList} data-testid="number-items-list">
      {renderNumberItems()}
    </dl>
  );
};

export default NumberTerms;
