import { Message } from '@oneflowab/pomes';

import ButtonFC from 'components/button/button-fc';
import CircularSpinner from 'components/icons/circular-spinner';
import Delete from 'components/icons/delete';
import { forwardRef } from 'react';

type Props = {
  onClick: () => void,
  isLoading: boolean,
  disabled?: boolean,
};

export const DeleteButton = forwardRef<HTMLButtonElement, Props>(({
  onClick,
  isLoading,
  disabled,
}, ref) => (
  <ButtonFC
    ref={ref}
    color="red"
    data-testid="remove"
    icon={isLoading ? CircularSpinner : Delete}
    disabled={isLoading || disabled}
    onClick={onClick}
  >
    <Message
      id="Delete"
      comment="Text for button to Delete action."
    />
  </ButtonFC>
));

DeleteButton.displayName = 'DeleteButton';
