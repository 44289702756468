// @flow

import React from 'react';
import { Message, type MessageTranslator } from '@oneflowab/pomes';
import { Form, Field } from 'react-final-form';

import * as EditableForm from 'components/editable-form';
import TextArea from 'components/text-area';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import { checkAcl } from 'components/acl';
import { DetailsFooter } from 'components/details-footer';
import { LocalizedDateTime } from 'components/localized-date-time';
import { getWorkspaceTypesAsOptions, getWorkspaceType } from 'components/workspace-type';
import {
  WorkspaceContractsCount,
  WorkspaceTemplatesCount,
  WorkspaceUserAccess,
} from 'components/workspace-counts';

import { hasAnyWorkspace } from 'oneflow-client/workspaces';

import adminPage from 'hocs/admin-page';

import style from './workspace-details.module.scss';

type Props = AdminPageComponentProps & {
  workspace: Workspace,
  updateWorkspace: Function,
  resetUpdateState: Function,
  updateState: UpdateState,
  message: MessageTranslator,
  location: Location,
  updatePath: (pathname: string) => void,
};

type State = {
  isEditMode: boolean,
};

export type FormData = {
  name: string,
  type: Option | WorkspaceType,
  description?: string,
};

export class WorkspaceDetailsComponent extends React.Component<Props, State> {
  state = {
    isEditMode: false,
  };

  componentDidUpdate(prevProps: Props) {
    const { updateState } = this.props;

    this.enableEditModeIfRedirected();

    if (
      updateState.success !== prevProps.updateState.success
      && updateState.success
      && this.state.isEditMode
    ) {
      this.setIsEditMode(false);
    }
  }

  getInitialValues() {
    const {
      name,
      type,
      description,
    } = this.props.workspace;

    return {
      name,
      description,
      type,
    };
  }

  uniqueWorkspaceName = ({ params }: { params: any }) => {
    const { workspace } = this.props;

    return hasAnyWorkspace({ params, entityId: workspace.id });
  };

  setIsEditMode = (_isEditMode: boolean) => {
    const { updateState, resetUpdateState } = this.props;

    if (updateState.pristine) {
      resetUpdateState();
    }

    this.setState({ isEditMode: _isEditMode });
  };

  enableEditModeIfRedirected() {
    const { updatePath, location } = this.props;

    if (!this.state.isEditMode && location?.query.edit && this.checkEditPermission()) {
      updatePath(location.pathname);

      this.setState({
        isEditMode: true,
      });
    }
  }

  checkEditPermission() {
    const { workspace } = this.props;

    return checkAcl(workspace.acl, [
      'collection:update:type',
      'collection:update:name',
      'collection:update:description',
    ], { match: 'any' });
  }

  getPlaceholder(placeholder: string, value: string) {
    const { isEditMode } = this.state;

    return isEditMode ? placeholder : !value && '-';
  }

  render() {
    const {
      workspace,
      updateState,
      updateWorkspace,
      resetUpdateState,
      message,
    } = this.props;

    const { isEditMode } = this.state;

    if (!workspace || !workspace.acl) {
      return null;
    }

    const containsValue = (
      <div>
        <WorkspaceContractsCount workspace={workspace} />
        <br />
        <WorkspaceTemplatesCount workspace={workspace} />
      </div>
    );

    const infoPairs = [
      {
        label: <Message
          id="Workspace ID"
          comment="ID label for the details footer."
        />,
        value: workspace.id,
      },
      {
        label: <Message
          id="Creation date"
          comment="Creation date label for the details footer"
        />,
        value: <LocalizedDateTime datetime={workspace.createdTime} />,
      },
      {
        label: <Message
          id="Contains"
          comment="Document and template counts for the details footer"
        />,
        value: containsValue,
      },
      {
        label: <Message
          id="Access"
          comment="Access count for the details footer"
        />,
        value: <WorkspaceUserAccess workspace={workspace} />,
      },
    ];

    return (
      <>
        <Form
          initialValues={this.getInitialValues()}
          onSubmit={updateWorkspace}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <EditableForm.Header>
                <EditableForm.Actions
                  resetFormState={resetUpdateState}
                  formState={updateState}
                  isEditMode={isEditMode}
                  setIsEditMode={this.setIsEditMode}
                  disabled={!this.checkEditPermission()}
                />
              </EditableForm.Header>
              <EditableForm.Body>
                <h2 className={style.Header}>
                  <Message
                    id="Workspace details"
                    comment="Used as the title of the Personal Details section on the workspace details page"
                  />
                </h2>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message
                      id="Name"
                      comment="Used as the label of the name field on the workspace details page."
                    />
                    <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                  </EditableForm.Label>
                  <Field
                    name="name"
                    component={TextField}
                    maxLength={50}
                    placeholder={message({
                      id: 'Enter name',
                      comment: 'Placeholder for the name field on the workspace details page',
                    })}
                    unique={{
                      text: message({
                        id: 'A workspace with this name already exists. Try another one.',
                        comment: 'Unqiueness error message on the workspace details page',
                      }),
                      param: 'name',
                      request: this.uniqueWorkspaceName,
                      initialValue: workspace.name,
                    }}
                    required
                    autoFocus
                    value={workspace.name}
                    disabled={!isEditMode || !checkAcl(workspace.acl, 'collection:update:name')}
                  />
                </div>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message
                      id="Type"
                      comment="Used as the label of the type field on the workspace details page."
                    />
                    <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                  </EditableForm.Label>
                  <Field
                    name="type"
                    component={SelectField}
                    options={getWorkspaceTypesAsOptions(message)}
                    placeholder={message({
                      id: 'Select type',
                      comment: 'Placeholder for the type field on the workspace details page',
                    })}
                    clearable={false}
                    searchable={false}
                    required
                    disabled={!isEditMode || !checkAcl(workspace.acl, 'collection:update:type')}
                    value={getWorkspaceType(workspace.type, message)}
                  />
                </div>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message
                      id="Description"
                      comment="Used as the label of the description field on the workspace details page."
                    />
                  </EditableForm.Label>
                  <Field
                    name="description"
                    placeholder={this.getPlaceholder(
                      message({
                        id: 'Enter description',
                        comment: 'Placeholder for the description field on the workspace details page',
                      }),
                      workspace.description,
                    )}
                    value={workspace.description || '-'}
                    disabled={!isEditMode || !checkAcl(workspace.acl, 'collection:update:description')}
                    component={TextArea}
                    maxLength={300}
                  />
                </div>
              </EditableForm.Body>
            </form>
          )}
        />
        <DetailsFooter infoPairs={infoPairs} />
      </>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Details',
    comment: 'Used as the page title of the workspace details page',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(WorkspaceDetailsComponent);
