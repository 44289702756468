import Button from 'components/button';
import Message from 'components/message';
import { COLLAPSED, EXPANDED } from 'components/document-layout-container/helpers';
import type { Layout } from 'components/document-layout-container/types';

import style from './single-participant.module.scss';

type ParticipantActions = {
  addParticipants: () => void;
  proceed: () => void;
  cancel: () => void;
};

type ParticipantActionsProps = {
  layout?: Layout;
  isSignatory: boolean;
  actions: ParticipantActions;
};

export default function Actions({
  layout = EXPANDED,
  isSignatory,
  actions,
}: ParticipantActionsProps) {
  const proceedButtonLabel = isSignatory ? (
    <Message id="Proceed to signing" comment="Button label to proceed to signing" />
  ) : (
    <Message id="Move to pending" comment="Button label to proceed to update the document to pending state" />
  );

  if (layout === EXPANDED) {
    return (
      <>
        <Button kind="linkSeparate" onClick={actions.cancel}>
          <Message id="Cancel" comment="Button label to close/cancel" />
        </Button>
        <Button
          kind="ghost"
          data-testid="single-participant-proceed"
          onClick={actions.proceed}
        >
          {proceedButtonLabel}
        </Button>
        <Button
          kind="send"
          data-testid="add-participants"
          customClass={style.OverrideButton}
          onClick={actions.addParticipants}
        >
          <Message id="Add participants" comment="Button label to add participants" />
        </Button>
      </>
    );
  }

  if (layout === COLLAPSED) {
    return (
      <>
        <Button
          kind="ghost"
          data-testid="single-participant-proceed"
          onClick={actions.proceed}
        >
          {proceedButtonLabel}
        </Button>
        <Button
          kind="send"
          data-testid="add-participants"
          customClass={style.OverrideButton}
          onClick={actions.addParticipants}
        >
          <Message id="Add participants" comment="Button label to add participants" />
        </Button>
        <Button kind="linkSeparate" onClick={actions.cancel}>
          <Message id="Cancel" comment="Button label to close/cancel" />
        </Button>
      </>
    );
  }

  return null;
}
