import { uniqueId } from 'lodash';
import Svg from 'components/svg';

export const DownChevronIcon = Svg((
  <path d="M16 4.99999L12 8.99999L8.00001 4.99999" stroke="currentColor" />
), { viewBox: '0 0 17 10', fill: 'none' });

export const CheckBoxIcon = Svg(
  <g opacity="0.7">
    <rect width="16" height="16" rx="4" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9152 4.91591L7.39387 11.5556C7.25926 11.7319 7.08811 11.879 6.88934 11.9831C6.6843 12.0905 6.45681 12.1485 6.2238 12.1501C5.99077 12.1516 5.7625 12.0966 5.55605 11.9917C5.34991 11.887 5.17258 11.7363 5.03403 11.5546L5.02297 11.5396L3.09277 8.85117L4.14879 8.093L6.07198 10.7717C6.09546 10.801 6.12104 10.8206 6.14472 10.8326C6.16946 10.8452 6.19333 10.8502 6.21528 10.8501C6.23723 10.8499 6.2612 10.8446 6.28605 10.8316C6.31121 10.8184 6.33838 10.7967 6.36277 10.7639C6.36977 10.7545 6.37703 10.7452 6.38454 10.7362L11.9157 4.08472L12.9152 4.91591Z" fill="white" />
  </g>,
  { viewBox: '0 0 16 16', fill: 'none' },
);

export const NumberIcon = Svg(
  <>
    <text dx="35%" dy="75%" fill="currentColor">
      1
    </text>
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="currentColor" />
  </>,
  { viewBox: '0 0 16 16', fill: 'none' },
);

export const RadioButtonIcon = Svg(
  <g opacity="0.7">
    <rect width="16" height="16" rx="8" fill="currentColor" />
    <rect x="5" y="5" width="6" height="6" rx="3" fill="white" />
  </g>,
  { viewBox: '0 0 16 16', fill: 'none' },
);

const Fragment = () => {
  const id = uniqueId('selected_icon_clip_path');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M0.666748 7.32634L2.91003 10.3365C2.98549 10.4376 3.08177 10.5199 3.19176 10.577C3.30175 10.6342 3.42264 10.6648 3.5455 10.6667C3.66837 10.6686 3.79007 10.6417 3.90163 10.5879C4.01318 10.5342 4.11174 10.455 4.19003 10.3561L11.3334 1.3335" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

export const SelectedIcon = Svg(
  Fragment,
  { viewBox: '0 0 12 12', fill: 'none' },
);
