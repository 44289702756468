/* eslint-disable import/named */
import type {
  PDFBox, PdfContentData, PDFOverlayFieldData, PDFOverlayFieldSignatureData,
} from 'data-validators/entity-schemas/document-box/pdf-box';

import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { localize, Message, MessageTranslator } from '@oneflowab/pomes';
import { useDraggable } from '@dnd-kit/core';
import { uniqueId } from 'lodash';

import { useBoxPermissions } from 'hooks/use-permissions';
import useCurrentBox from 'hooks/use-current-box';
import { isConcluded } from 'agreement/states';
import { updateBoxAction, updateBoxDataItemAction } from 'reducers/current-contract';
import useAgreement from 'hooks/use-agreement';
import { usePdfBoxProps } from 'contexts/pdf-box-props';
import { getParticipantById, getAgreementMyParticipant } from 'agreement/selectors';
import { STATE_IS_DISABLED } from 'agreement/participant/constants';
import isSignatory from 'agreement/participant/is-signatory';
import { getAvailableContentInlineSize } from 'reducers/app';
import { isBoxDataCreateAllowed } from 'agreement/box-data-create-permissions';

import SignatureIcon from 'components/icons/signature';
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverPortal,
} from 'components/popover';
import Tooltip from 'components/tooltip';
import { A4_WIDTH, SIGNATURE_FIELD } from 'components/contract-boxes/constants';
import CopyIcon from 'components/icons/copy';
import Button from 'components/button';

import style from './overlay-signature-field.module.scss';
import { Signature } from './signature';
import { PendingSignature } from './pending-signature';
import DeleteButton from '../delete-button';
import ParticipantSelector from './participant-selector';

type Props = {
  overlayFieldContentDataId: PDFOverlayFieldSignatureData['id'],
  overlayFieldValue: PDFOverlayFieldSignatureData['value'],
  boxId: number,
  focused: boolean,
  setFocused: (value: boolean) => void,
  hasSigned: boolean,
  canRemoveOverlayField: boolean,
  message: MessageTranslator,
  boundaries: {
    width: number,
    height: number,
  }
}

const SignatureFieldContentComponent = ({
  hasSigned,
  setFocused,
  focused,
  boxId,
  overlayFieldContentDataId,
  overlayFieldValue,
  canRemoveOverlayField,
  message,
  boundaries,
}: Props) => {
  const dispatch = useDispatch();
  const { agreementId } = usePdfBoxProps();
  const agreement = useAgreement(agreementId);
  const participantId = overlayFieldValue.participant?.id;
  const participant = participantId ? getParticipantById(agreement, participantId) : null;
  const permissions = useBoxPermissions();
  const pdfBox: PDFBox | null = useCurrentBox(boxId);
  const signatureImage = participant?.signatureInfo?.signatureImage;
  const fullName = participant?.fullname ?? message({ id: 'Signature', comment: 'Signature field label' });
  const popoverContentRef = useRef<HTMLDivElement>(null);
  const [selectedParticipantId,
    setSelectedParticipantId] = useState<number | undefined>(participantId);
  const myParticipant = getAgreementMyParticipant(agreement);
  const isMyParticipant = myParticipant?.id === participantId;
  const [showTooltip, setShowTooltip] = useState(false);
  const [focusedPopoverAnchor, setFocusedPopoverAnchor] = useState(false);
  const canCreateOverlayField = isBoxDataCreateAllowed(pdfBox);

  const width = useSelector(getAvailableContentInlineSize);
  const scale = width / A4_WIDTH;

  const participants = useMemo(() => (
    agreement?.parties?.flatMap(
      (party) => party.participants?.filter(
        (each) => each.state !== STATE_IS_DISABLED && isSignatory(each),
      ) ?? [],
    ) ?? []
  ), [agreement]);

  const {
    isDragging,
  } = useDraggable({
    id: `draggable-overlay-field${overlayFieldContentDataId}`,
  });

  const signatureClassName = clsx(style.Signature, {
    [style.HasSigned]: hasSigned,
    [style.PendingSignatureDragging]: isDragging,
  });

  useEffect(() => {
    const participantIds = participants.map((each) => each.id);
    // If a user assigned to the field is removed from the agreement, field should be unlinked
    const unlinkedData = pdfBox?.content?.data?.filter((each) => each.key === SIGNATURE_FIELD
      && each?.value?.participant?.id && !participantIds.includes(each?.value?.participant?.id));
    if ((unlinkedData ?? []).length > 0) {
      const updatedBoxData = pdfBox?.content?.data?.map((dataItem) => {
        if (dataItem.key === SIGNATURE_FIELD
          && unlinkedData?.some((unlinked) => unlinked.id === dataItem.id)
        ) {
          return {
            ...dataItem,
            value: {
              ...dataItem.value,
              participant: null,
            },
          };
        }
        return dataItem;
      });

      const updatedBox: PDFBox = {
        ...pdfBox,
        content: {
          ...pdfBox?.content,
          data: (updatedBoxData ?? pdfBox?.content?.data) as (PDFOverlayFieldSignatureData
            | PdfContentData | PDFOverlayFieldData)[],
        },
      };
      setSelectedParticipantId(undefined);
      dispatch(updateBoxAction(updatedBox));
    }
  }, [dispatch, participants, pdfBox]);

  const onParticipantSelect = (id: Oneflow.Participant['id']) => {
    setSelectedParticipantId(id);
    if (id === 0) {
      dispatch(updateBoxDataItemAction(
        boxId,
        overlayFieldContentDataId,
        {
          value: {
            ...overlayFieldValue,
            participant: null,
          },
        },
      ));
      return;
    }

    dispatch(updateBoxDataItemAction(
      boxId,
      overlayFieldContentDataId,
      {
        value: {
          ...overlayFieldValue,
          participant: {
            model_type: 'participant',
            reference_type: 'direct',
            id: Number(id),
          },
        },
      },
    ));
  };

  const fieldTooltipMessage = (
    isMyParticipant ? (
      <Message
        id="After pressing the Sign button, your signature will appear here."
        comment="Tooltip message for signature field if the current user is the one in the signature field."
      />
    ) : (
      <Message
        id="Participant signature will appear here when they sign the document"
        comment="Tooltip message for signature field when a another participant is expected to sign the signature field."
      />
    )
  );

  const deleteSignatureField = () => {
    const updatedBox: PDFBox = {
      ...pdfBox,
      content: {
        ...pdfBox?.content,
        data: (pdfBox?.content?.data ?? [])
          .reduce<PDFOverlayFieldSignatureData[] | PdfContentData[]>(
            (updatedData, datum) => {
              if ((datum as PdfContentData).key === 'file') {
                (updatedData as PdfContentData[]).push(
                  datum as PdfContentData,
                );
                return updatedData;
              }
              const isNewlyAddedOverlayField = Boolean(datum?._id && !datum.id);
              if (isNewlyAddedOverlayField && datum._id === overlayFieldContentDataId) {
                return updatedData;
              }

              const isExistingOverlayField = (
                !isNewlyAddedOverlayField && datum?.value?.id === overlayFieldValue.id
              );

              if (isExistingOverlayField) {
                (updatedData as PDFOverlayFieldSignatureData[]).push({
                  ...(datum as PDFOverlayFieldSignatureData),
                  _removed: 1,
                });

                return updatedData;
              }

              (updatedData as PDFOverlayFieldSignatureData[]).push(
                datum as PDFOverlayFieldSignatureData,
              );

              return updatedData;
            }, [],
          ),
      },
    } as PDFBox;
    dispatch(updateBoxAction(updatedBox));
  };

  const duplicateSignatureField = () => {
    if (!pdfBox || !pdfBox.content || !pdfBox.content.data) return;

    const isSignatureField = (
      item: PdfContentData | PDFOverlayFieldData | PDFOverlayFieldSignatureData,
    ) => item && item.key === SIGNATURE_FIELD;

    const currentField = pdfBox?.content?.data?.find(
      (item) => isSignatureField(item)
        && (item.id === overlayFieldContentDataId || item._id === overlayFieldContentDataId),
    );

    if (!currentField || !isSignatureField(currentField)) return;

    setFocused(false);
    setFocusedPopoverAnchor(false);
    setShowTooltip(false);

    const currentX = (currentField.value.position.x || 0);
    const currentY = (currentField.value.position.y || 0);

    // Check if adding 20px would exceed boundaries
    const newX = Math.ceil(
      scale * (currentX + (currentField?.value?.size?.width || 0) + 20),
    ) < boundaries.width
      ? currentX + 20
      : currentX;

    const newY = Math.ceil(
      scale * (currentY + (currentField?.value?.size?.height || 0) + 20),
    ) < boundaries.height
      ? currentY + 20
      : currentY;

    const newField = {
      _id: Number(uniqueId()),
      key: SIGNATURE_FIELD,
      value: {
        fillOpacity: currentField.value.fillOpacity,
        page: currentField.value.page,
        size: currentField.value.size,
        position: {
          x: newX,
          y: newY,
        },
        participant: currentField.value.participant,
      },
    };

    const updatedBox = {
      ...pdfBox,
      content: {
        ...pdfBox.content,
        data: [...pdfBox.content.data, newField],
      },
    } as PDFBox;

    dispatch(updateBoxAction(updatedBox));
  };

  if (!permissions?.update || isConcluded(agreement)) {
    return (
      <Tooltip
        message={fieldTooltipMessage}
        side="top"
        sideOffset={8}
        hideContent={focused || hasSigned || isConcluded(agreement)}
      >
        <div className={clsx(style.Popover, { [style.HasSigned]: hasSigned })}>
          {hasSigned && signatureImage ? (
            <Signature
              agreement={agreement}
              participant={participant}
              fullName={fullName}
              signatureImage={signatureImage}
            />
          ) : (
            <>
              <SignatureIcon className={style.SignatureIcon} width="16px" />
              <PendingSignature
                fullName={fullName}
              />
            </>
          )}
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      message={fieldTooltipMessage}
      side="top"
      sideOffset={8}
      open={(showTooltip && !focused) && !focusedPopoverAnchor && !hasSigned}
      onOpenChange={(open: boolean) => {
        setShowTooltip(open);
      }}
    >
      <div className={clsx(style.Popover, { [style.HasSigned]: hasSigned })}>
        <Popover open={focused}>
          <PopoverAnchor asChild>
            <div
              className={signatureClassName}
              role="button"
              tabIndex={0}
              onClick={() => { setFocused(!focused); }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setFocused(!focused);
                }
              }}
              data-testid="signature-field"
            >
              {hasSigned && signatureImage ? (
                <Signature
                  agreement={agreement}
                  participant={participant}
                  fullName={fullName}
                  signatureImage={signatureImage}
                />
              ) : (
                <>
                  <SignatureIcon className={style.SignatureIcon} width="16px" />
                  <PendingSignature
                    fullName={fullName}
                  />
                </>
              )}
            </div>
          </PopoverAnchor>
          <PopoverPortal>
            <PopoverContent
              ref={popoverContentRef}
              side="top"
              sideOffset={10}
              align="start"
              arrowHeight={0}
              onPointerDownOutside={() => { setFocused(false); setFocusedPopoverAnchor(false); }}
              onOpenAutoFocus={(event) => {
                event.preventDefault();
              }}
              tabIndex={-1}
              className={style.OverlayFieldPopover}
              hideWhenDetached
            >
              <div className={style.OverlayToolbar} tabIndex={-1}>
                <ParticipantSelector
                  options={participants}
                  onValueChange={(value: string) => onParticipantSelect(Number(value))}
                  value={selectedParticipantId || 0}
                  onClick={setFocusedPopoverAnchor}
                />
                {canCreateOverlayField && (
                <>
                  <div className={style.VerticalDivider} tabIndex={-1} />
                  <Tooltip
                    message={(
                      <Message
                        id="Duplicate"
                        comment="Tooltip message for duplicate button."
                      />
                    )}
                    side="top"
                    sideOffset={10}
                  >
                    <div>
                      <Button
                        data-testid="data-overlay-field-duplicate-cta"
                        type="button"
                        onClick={duplicateSignatureField}
                        customClass={style.DuplicateButton}
                        icon={(<CopyIcon />)}
                      />
                    </div>
                  </Tooltip>
                </>
                )}
                {canRemoveOverlayField && (
                  <>
                    <DeleteButton onClick={deleteSignatureField} />
                  </>
                )}
              </div>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </div>
    </Tooltip>
  );
};

export const SignatureFieldContent = localize(SignatureFieldContentComponent);
