// @flow

import { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

const getNodes = (nodes) => {
  if (isEmpty(nodes)) {
    return [{ type: 'paragraph', children: [{ text: '' }] }];
  }

  return nodes;
};

type UseEditorValue = (
  nodes: Array<SlateDescendant>,
  onNodesChange: Array<SlateDescendant> => void,
) => {
  editorValue: Array<SlateDescendant>,
  onChange: Array<SlateDescendant> => void,
};

const useEditorValue: UseEditorValue = (nodes, onNodesChange) => {
  const [editorValue, setEditorValue] = useState(() => getNodes(nodes));

  const onChange = useCallback((newValue) => {
    setEditorValue(newValue);

    if (!isEqual(editorValue, newValue)) {
      onNodesChange(newValue);
    }
  }, [onNodesChange, editorValue]);

  return {
    editorValue,
    onChange,
  };
};

export default useEditorValue;
