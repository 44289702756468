import { put } from 'redux-saga/effects';

import generateEntitySagas from 'normalized-redux/sagas';
import apiWrapper from 'sagas/api-wrapper';
import accountUsersReducer from 'reducers/entities/account-users';
import getAccountUserBindings from 'oneflow-client/account-users';

type DataType = {
  data: {
    entities: {
      accountUsersBindings: Oneflow.AccountUserRoleBinding
    }
  },
};

export function* mapper({ data }: DataType) {
  yield put(accountUsersReducer.setAccountUsers(data.entities.accountUsersBindings));
}

const mappers = {
  query: {
    mapper,
    request: getAccountUserBindings,
  },
};

const accountUsersSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: accountUsersReducer,
  mappers,
});

export default accountUsersSagas;
