import { put, select } from 'redux-saga/effects';

import generateEntitySagas from 'normalized-redux/sagas';
import { getAgreementCreators } from 'oneflow-client/agreement-creators';

import apiWrapper from 'sagas/api-wrapper';

import agreementCreators from 'reducers/entities/agreement-creators';
import { getCurrentWorkspaceSelector } from 'reducers/app';

export function* setQueryParams({ fromDate, toDate }) {
  const workspace = yield select(getCurrentWorkspaceSelector);

  return {
    params: {
      workspaceId: workspace.id,
      positionId: 0,
      fromDate,
      toDate,
    },
  };
}

export function* mapper({ data }) {
  yield put(agreementCreators.setAgreementCreators(data.entities.agreementCreators));
}

const mappers = {
  query: {
    prepare: setQueryParams,
    mapper,
    request: getAgreementCreators,
  },
};

const agreementCreatorsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: agreementCreators,
  mappers,
});

export default agreementCreatorsSagas;
