// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import map from 'lodash/map';

import Tooltip from 'components/tooltip';
import {
  USER_ROLE_USER,
  USER_ROLE_LIMITED,
} from 'user';

import {
  licensedUserRole,
  limitedUserRole,
} from './constants';

import style from './user-roles.module.scss';

export const userRoles = (
  isPositionLimitedFeatureEnabled: boolean,
  message: MessageTranslator,
) => {
  if (isPositionLimitedFeatureEnabled) {
    return {
      ...licensedUserRole(message),
      ...limitedUserRole(message),
    };
  }
  return licensedUserRole(message);
};

export const getUserRole = (
  userRole?: UserRoleType,
  isPositionLimitedFeatureEnabled: boolean,
  message: MessageTranslator,
) => {
  const currentUserRole = userRole === USER_ROLE_USER ? userRole : USER_ROLE_LIMITED;
  return userRoles(
    isPositionLimitedFeatureEnabled, message,
  )[currentUserRole];
};

export const getUserRolesAsOptions = (
  isPositionLimitedFeatureEnabled: boolean,
  message: MessageTranslator,
) => (
  map(userRoles(isPositionLimitedFeatureEnabled, message), (item) => ({
    label: item.label,
    value: item.value,
  }))
);
type Props = {
  userRole?: UserRoleType,
  withTooltip?: boolean,
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left',
  message: MessageTranslator,
  isPositionLimitedFeatureEnabled: boolean,
};

export class UserRoleComponent extends React.PureComponent<Props> {
  static defaultProps = {
    userRole: undefined,
    withTooltip: false,
  };

  renderUserRoleWithTooltip() {
    const {
      userRole,
      isPositionLimitedFeatureEnabled,
      message,
      tooltipSide = 'top',
    } = this.props;

    const role = getUserRole(
      userRole,
      isPositionLimitedFeatureEnabled,
      message,
    );

    return (
      <Tooltip
        message={role?.description}
        side={tooltipSide}
        zIndex="1"
        data-testid="user-role-tooltip"
      >
        <div data-align-with-tooltip className={style.LimitedUserContainer}>
          {role?.label}
        </div>
      </Tooltip>
    );
  }

  render() {
    const {
      userRole,
      isPositionLimitedFeatureEnabled,
      message,
      withTooltip,
    } = this.props;

    const role = getUserRole(
      userRole,
      isPositionLimitedFeatureEnabled,
      message,
    );

    if (!role) {
      return null;
    }

    if (withTooltip) {
      return this.renderUserRoleWithTooltip();
    }

    return role.label;
  }
}

export default localize<Props>(UserRoleComponent);
