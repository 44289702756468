// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import workspacesReducer from 'reducers/entities/workspaces';
import { useSelector } from 'react-redux';
import FilterSelectField from 'components/filter-select-field';
import FilterValues from 'components/filter-values/filter-values';
import usePreviousUntilValuesChanged from 'hooks/use-previous-until-values-changed';
import { getAllMyWorkspacesFromSessionSelector } from 'reducers/session';
import style from './workspace-selector.module.scss';

export const LIMIT = 50;

const mapToOption = (workspace: Workspace) => ({
  value: workspace.id,
  label: workspace.name,
});

type Props = {
  onChange: (value: Array<number> | null) => void,
  value: Array<number> | null,
  message: MessageTranslator,
};

export const WorkspaceSelector = ({
  onChange,
  value,
  message,
}: Props) => {
  const selectedWorkspacesIds = usePreviousUntilValuesChanged(value || []);

  const allWorkspaces = useSelector(getAllMyWorkspacesFromSessionSelector);

  const selectedWorkspaces = usePreviousUntilValuesChanged(useSelector((state) => (
    workspacesReducer.getWorkspacesSelector(state, {
      ids: selectedWorkspacesIds,
    })
  )));

  return (
    <div className={style.WorkspacesSelectorContainer}>
      <FilterValues
        onChange={onChange}
        selectedValues={selectedWorkspaces.map(mapToOption)}
      />
      <FilterSelectField
        loadingMessage={() => (
          <>
            {message({
              id: 'Loading',
              comment: 'The loading message',
            })}
            ...
          </>
        )}
        isLoading={false}
        isSearchable
        options={allWorkspaces.map(mapToOption)}
        placeholder={message({
          id: 'Find workspaces',
          comment: 'The placeholder for the workspaces filter search field',
        })}
        name="workspaceSelectField"
        onChange={onChange}
        selectedValues={selectedWorkspaces.map(mapToOption)}
        menuZIndex={1}
      />
    </div>
  );
};

export default localize<Props>(WorkspaceSelector);
