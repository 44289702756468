// @flow

import ApiTokens from './api-tokens';
import Webhooks from './webhooks';
import TemplateGroups from './template-groups';

import Demo from './demo';
import DynamicsCrm from './dynamics-crm';
import Intelliplan from './intelliplan';
import Jobylon from './jobylon';
import Salesforce from './salesforce';
import SCIM from './scim';
import Ssosaml from './ssosaml';
import Lime from './lime';
import Membrain from './membrain';
import Upsales from './upsales';
import Adocka from './adocka';
import Hubspot from './hubspot';
import Teamtailor from './teamtailor';
import SuperOffice from './super-office';
import Neocase from './neocase';
import Pipedrive from './pipedrive';

import ElectronicIds from './electronic-ids';
import Sms from './sms';
import Paxml from './paxml';
import HANDWRITTEN_SIGNATURE from './handwritten_signature';

const mainExtensions = [
  ApiTokens,
  Webhooks,
  TemplateGroups,
];
const integrationExtensions = [
  Demo,
  DynamicsCrm,
  Intelliplan,
  Salesforce,
  Lime,
  Membrain,
  Ssosaml,
  Upsales,
  Adocka,
  Hubspot,
  Teamtailor,
  SuperOffice,
  Jobylon,
  Neocase,
  Pipedrive,
];
const additionalExtensions = [
  ElectronicIds,
  Sms,
  Paxml,
  SCIM,
  HANDWRITTEN_SIGNATURE,
];

const all = ([
  ...mainExtensions,
  ...integrationExtensions,
  ...additionalExtensions,
]);

export default all;
