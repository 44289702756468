import { SyntheticEvent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarTooltip from '../sidebar-tooltip';

type Props = {
  children: ReactNode | ReactNode[];
  isMobile: boolean;
  expanded?: boolean;
  tooltipText: string;
  onClick: (event: SyntheticEvent) => void;
  customClasses: string;
  dataTestId?: string;
  link: string;
  activeClassName: string;
  isActive: boolean;
  isDisabled?: boolean;
  labelElementId: number;
};

export const SidebarOptionLink = ({
  children,
  isMobile,
  expanded,
  tooltipText,
  onClick,
  customClasses,
  dataTestId,
  link,
  activeClassName,
  isActive,
  isDisabled,
  labelElementId,
}: Props) => {
  if (isMobile || expanded) {
    return (
      <NavLink
        onClick={onClick}
        to={link}
        activeClassName={activeClassName}
        className={customClasses}
        data-testid={dataTestId}
        isActive={isActive}
        aria-labelledby={labelElementId}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <SidebarTooltip
      tooltipText={tooltipText}
      isDisabled={isDisabled}
      sideOffset={15}
    >
      <NavLink
        onClick={onClick}
        to={link}
        activeClassName={activeClassName}
        className={customClasses}
        data-testid={dataTestId}
        isActive={isActive}
        aria-label={tooltipText}
      >
        {children}
      </NavLink>
    </SidebarTooltip>
  );
};

export default SidebarOptionLink;
