/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  debounce,
  map,
  some,
} from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import clsx from 'clsx';

import { getGuestToken } from 'agreement/selectors';
import { pricePrecision } from 'utils/format-number-with-trailing-zeros';
import {
  updateBoxAction,
  updateBoxDataItemAction,
  updatePriceColumnsAction,
} from 'reducers/current-contract';
import { useProductTableBoxContext } from 'contexts/product-table-box-context';
import useIsExpandedView from 'hooks/use-is-expanded-view';
import type { ProductTableBox } from 'data-validators/entity-schemas/document-box/product-table';

import { AddProductButton } from 'components/buttons/add-product';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import {
  produceContentDataForRemovedItem,
  produceUpdatedProductData,
} from 'components/contract-boxes/product-table-box/product-table-box-helpers';
import {
  PRODUCT_COUNT_TYPE_NUMBER,
  PRODUCT_COUNT_TYPE_SINGLE_CHOICE,
  PRODUCT_NOT_SELECTED,
  PRODUCT_SELECTED,
  PRODUCT_TABLE_EXPANDED_VIEW_MIN_WIDTH,
  PRODUCT_TABLE_MIN_WIDTH_TOLERANCE_DELTA,
} from 'components/contract-boxes/product-table-box/constants';
import PriceSummation from 'components/contract-boxes/product-table-box/product-table/price-summation';
import ProductTableExpanded from 'components/contract-boxes/product-table-box/product-table/product-table-expanded';
import ProductTableResponsive from 'components/contract-boxes/product-table-box/product-table/product-table-responsive';

import style from './product-table.module.scss';

type Props = {
  addNewProduct: () => void;
  updateProductConfig: (config: ProductTableBox['config']) => void;
}

const ProductTable = ({
  addNewProduct,
  updateProductConfig,
}: Props) => {
  const dispatch = useDispatch();
  const {
    agreementId,
    box,
    boxId,
    columns,
    config,
    data,
    getIsAllowedToUpdateDataValue,
    isAllowedToAddBoxData,
    isAllowedToEditBoxData,
  } = useProductTableBoxContext();

  const guestToken = useSelector((state) => getGuestToken(state));
  const isGuestView = Boolean(guestToken);

  const isExpandedView = useIsExpandedView(
    PRODUCT_TABLE_EXPANDED_VIEW_MIN_WIDTH,
    PRODUCT_TABLE_MIN_WIDTH_TOLERANCE_DELTA,
  );

  const enabledColumns = columns.filter((column) => column.enabled).map((column) => column.key);
  const hasEnabledPriceColumns = enabledColumns.some((column) => column === 'price_1' || column === 'price_2');
  const showProductSum = !config.hideSum && hasEnabledPriceColumns;

  const updatedData = useMemo(() => data.filter((datum) => !datum._removed), [data]);
  const selectedProduct = useMemo(() => updatedData.find(
    (dataItem) => dataItem.value.count_type === PRODUCT_COUNT_TYPE_SINGLE_CHOICE
      && dataItem.value.count === PRODUCT_SELECTED,
  ), [updatedData]);

  const [chosenProduct, setChosenProduct] = useState(getId(selectedProduct));

  const updateChosenProduct = useCallback((productId: string | number, shouldDispatch = true) => {
    const numberProductId = Number(productId);
    const newChosenProduct = data.find((dataItem) => getId(dataItem) === numberProductId);
    setChosenProduct(numberProductId);
    if (selectedProduct) {
      dispatch(updateBoxDataItemAction(boxId, chosenProduct,
        {
          ...selectedProduct,
          value: {
            ...selectedProduct.value,
            count: PRODUCT_NOT_SELECTED,
          },
        }));
    }
    if (!shouldDispatch) {
      return;
    }
    dispatch(updateBoxDataItemAction(boxId, getId(newChosenProduct),
      {
        ...newChosenProduct,
        value: {
          ...newChosenProduct.value,
          count: PRODUCT_SELECTED,
        },
      }));
    dispatch(updatePriceColumnsAction());
  }, [data, selectedProduct, dispatch, boxId, chosenProduct]);

  const updateProductData = useCallback((productId: number, value: Partial<ProductValue>) => {
    const product = data.find((dataItem) => getId(dataItem) === productId);
    let updatedProduct = produceUpdatedProductData(updatedData, productId, value);

    const countType = updatedProduct.value.count_type || product?.value.count_type;
    const productCount = updatedProduct.value.count ?? product?.value?.count;

    const isSingleOrMultipleChoiceQuantityType = countType !== PRODUCT_COUNT_TYPE_NUMBER;
    const isCurrentProductSelected = productCount && productCount >= 1;
    const currentSelectionValue = isCurrentProductSelected
      ? PRODUCT_SELECTED
      : PRODUCT_NOT_SELECTED;

    const updatedCount = isSingleOrMultipleChoiceQuantityType
      ? currentSelectionValue
      : productCount;

    if (isCurrentProductSelected && countType === PRODUCT_COUNT_TYPE_SINGLE_CHOICE) {
      updateChosenProduct(productId, false);
    }

    updatedProduct = {
      ...updatedProduct,
      value: {
        ...updatedProduct.value,
        count: updatedCount,
      },
    };

    dispatch(updateBoxDataItemAction(boxId, productId, updatedProduct));
    const countOrPriceRegex = /^(count|price)/;
    if (some(value, (property, key) => countOrPriceRegex.test(key))) {
      dispatch(updatePriceColumnsAction());
    }
  }, [data, updatedData, dispatch, boxId, updateChosenProduct]);

  const removeProduct = useCallback((productId: number) => {
    const contentData = produceContentDataForRemovedItem(data, productId);
    const orderIds = map(
      contentData.filter((datum) => !datum._removed),
      (item) => {
        if (Object.hasOwn(item, 'id')) {
          return { id: item.id };
        }

        if (Object.hasOwn(item, '_id')) {
          return { _id: item._id };
        }

        return null; // Ideally code should not reach here.
        // Not sure the side-effects of reaching here, we have similar behaviour in other boxes
      },
    );
    // Using updateBoxAction for now as it used in legacy and to keep the syncing simpler
    dispatch(updateBoxAction({
      ...box,
      content: {
        data: contentData,
      },
      config: {
        ...box.config,
        order: orderIds,
      },
    }));

    dispatch(updatePriceColumnsAction());
  }, [data, dispatch, box]);

  const handleDescriptionChange = debounce((productId, nodes) => {
    updateProductData(productId, { description_nodes: nodes });
  }, 200);

  const handleClearChosenProduct = (productId: string | number) => {
    const numberProductId = Number(productId);
    setChosenProduct(undefined);
    updateProductData(numberProductId,
      { count: PRODUCT_NOT_SELECTED });
  };

  const handleProductSelectionChange = (node: HTMLDivElement | null) => {
    if (!node) {
      setChosenProduct(undefined);
    }

    const selectedProductId = getId(selectedProduct);

    if (chosenProduct?.id !== selectedProductId) {
      // Counterintuitive alert!
      // When saving document the chosen product get a id that
      // Does not match the _id created in the client
      setChosenProduct(getId(selectedProduct));
    }
  };

  const summationContainerClassNames = clsx({
    [style.SummationContainerExpanded]: isExpandedView,
    [style.SummationContainerResponsive]: !isExpandedView,
    [style.canAddProduct]: isAllowedToAddBoxData,
  });

  const isResponsiveViewSummation = !isExpandedView && showProductSum;
  const isExpandedViewSummation = isExpandedView && showProductSum;

  const priceSummationClassNames = clsx({
    [style.PriceSummationExpanded]: isExpandedView,
    [style.PriceSummationResponsive]: !isExpandedView,
  });

  const renderTable = () => {
    if (!isExpandedView) {
      return (
        <ProductTableResponsive
          agreementId={agreementId}
          chosenProduct={chosenProduct}
          columns={columns}
          config={config}
          data={updatedData}
          getIsAllowedToUpdateDataValue={getIsAllowedToUpdateDataValue}
          handleDescriptionChange={handleDescriptionChange}
          isAllowedToEditBoxData={isAllowedToEditBoxData}
          isGuestView={isGuestView}
          isResponsiveView
          onClearChosenProduct={handleClearChosenProduct}
          removeProduct={removeProduct}
          updateChosenProduct={updateChosenProduct}
          updateProductConfig={updateProductConfig}
          updateProductData={updateProductData}
        />
      );
    }

    return (
      <ProductTableExpanded
        agreementId={agreementId}
        box={box}
        chosenProduct={chosenProduct}
        columns={columns}
        config={config}
        data={updatedData}
        enabledColumns={enabledColumns}
        handleDescriptionChange={handleDescriptionChange}
        isAllowedToEditBoxData={isAllowedToEditBoxData}
        isGuestView={isGuestView}
        onClearChosenProduct={handleClearChosenProduct}
        removeProduct={removeProduct}
        updateChosenProduct={updateChosenProduct}
        updateProductConfig={updateProductConfig}
        updateProductData={updateProductData}
      />
    );
  };

  return (
    <div ref={handleProductSelectionChange}>
      {renderTable()}
      <div className={summationContainerClassNames} data-testid="summation-container">
        {isAllowedToAddBoxData && <AddProductButton onClick={() => addNewProduct()} />}
        {isResponsiveViewSummation && (
          <div className={priceSummationClassNames}>
            <PriceSummation
              sums={box?.store?.sums}
              columns={columns}
              prefix={config.affixes.prefix}
              postfix={config.affixes.postfix}
              isResponsiveView={!isExpandedView}
              pricePrecision={pricePrecision(config.pricePrecision)}
            />
          </div>
        )}
        {isExpandedViewSummation && (
          <div className={priceSummationClassNames}>
            <PriceSummation
              sums={box?.store?.sums}
              columns={columns}
              prefix={config.affixes.prefix}
              postfix={config.affixes.postfix}
              isResponsiveView={!isExpandedView}
              pricePrecision={pricePrecision(config.pricePrecision)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(ProductTable);
