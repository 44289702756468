// @flow

import { put, select } from 'redux-saga/effects';
import type { SelectEffect, PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getContacts,
  getContact,
  createContact,
  updateContact,
  deleteContacts,
  type NormalizedContacts,
} from 'oneflow-client/contacts';

import apiWrapper from 'sagas/api-wrapper';

import contacts from 'reducers/entities/contacts';
import { getCurrentWorkspaceSelector } from 'reducers/app';

type MapperArgs = {
  data: NormalizedContacts,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

type ExtraQueryParams = {
  params: {
    workspaceId: number,
  },
}

type ExtraCreateParams = {
  workspaceId: number,
};

type SetWorkspaceId = Generator<SelectEffect<any, any>, ExtraQueryParams | ExtraCreateParams, any>;

export function* setCurrentWorkspaceIdForQuery(): SetWorkspaceId {
  const workspace = yield select(getCurrentWorkspaceSelector);

  return {
    params: {
      workspaceId: workspace.id,
    },
  };
}

export function* setCurrentWorkspaceIdForCreate(): SetWorkspaceId {
  const workspace = yield select(getCurrentWorkspaceSelector);

  return {
    workspaceId: workspace.id,
  };
}

export function* clearMapper({ action }: MapperArgs): Mapper {
  yield put(contacts.clearContact({ ids: action.data.ids }));
}

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(contacts.setContacts(data.entities.contacts));
}

const mappers = {
  query: {
    prepare: setCurrentWorkspaceIdForQuery,
    mapper,
    request: getContacts,
  },
  fetch: {
    prepare: setCurrentWorkspaceIdForQuery,
    mapper,
    request: getContact,
  },
  create: {
    prepare: setCurrentWorkspaceIdForCreate,
    mapper,
    request: createContact,
  },
  update: {
    mapper,
    request: updateContact,
  },
  remove: {
    mapper: clearMapper,
    request: deleteContacts,
  },
};

const contactsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: contacts,
  mappers,
});

export default contactsSagas;
