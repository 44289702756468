import {
  escape,
  get,
  isEmpty,
  isObject,
} from 'lodash';

import { getId, isUnsavedData } from 'components/contract-boxes/generic-box-helpers';

export const CHECKBOX = 'checkbox';
export const DATE_FIELD = 'date';
export const DROPDOWN = 'dropdown';
export const EMPTY = null;
export const TEXT_AREA = 'text';
export const TEXT_FIELD = 'field';

export const FIELD_TYPES = {
  CHECKBOX,
  DATE_FIELD,
  DROPDOWN,
  TEXT_AREA,
  TEXT_FIELD,
} as const;

export const INITIAL_INDEX_MAP = {
  fieldIndex: 0,
  rowIndex: 0,
};

export const COLUMN_SIZE_1 = 1;
export const COLUMN_SIZE_2 = 2;
export const COLUMN_SIZE_3 = 3;
export const COLUMN_SIZE_4 = 4;
export const COLUMN_OPTIONS = [
  COLUMN_SIZE_1,
  COLUMN_SIZE_2,
  COLUMN_SIZE_3,
  COLUMN_SIZE_4,
] as const;

export const FIELD_SIZE_20 = 0;
export const FIELD_SIZE_40 = 1;
export const FIELD_SIZE_50 = 2;
export const FIELD_SIZE_60 = 3;
export const FIELD_SIZE_80 = 4;
export const FIELD_SIZE_100 = 5;

export const FIELD_SIZE_OPTIONS = [
  FIELD_SIZE_20,
  FIELD_SIZE_40,
  FIELD_SIZE_50,
  FIELD_SIZE_60,
  FIELD_SIZE_80,
  FIELD_SIZE_100,
] as const;

export const DESCRIPTION_MAX_LENGTH = 256;
export const NAME_MAX_LENGTH = 160;
export const PLACEHOLDER_MAX_LENGTH = 80;

export const addNullFields = (columnCount, row) => {
  const nullArray = Array(columnCount - row.length).fill(null);

  return row.concat(nullArray);
};

export const getAvailableColumnSlots = (
  activeFieldIndex,
  activeRowIndex,
  colCount,
  order,
) => {
  const slots = colCount - (activeFieldIndex + 1);
  let availableSlots = 1;

  for (let i = 0; i < slots; i += 1) {
    const currentPosition = order[activeRowIndex][activeFieldIndex + (i + 1)];

    if (currentPosition !== null) {
      break;
    }

    availableSlots += 1;
  }

  return availableSlots;
};

export const getFieldData = (data, id) => data.find(
  (field) => (field.id && field.id === id) || (field._id && field._id === id),
);

const getFormPropsValue = (prop) => (isObject(prop) ? prop.value : prop);

export const getIsBoxEmpty = (order) => (
  isEmpty(order) || (order.length === 1 && order[0].every((field) => field === null))
);

export const getInitialFieldData = (message) => ({
  colspan: COLUMN_SIZE_1,
  description: '',
  label: message({
    id: 'Untitled field',
    comment: 'Default field label.',
  }),
  placeholder: '',
  required: 0,
  size: FIELD_SIZE_100,
  type: TEXT_FIELD,
  valueDataFieldKey: null,
});

export const getUpdatedBoxData = (
  boxData,
  id,
  newFieldData,
) => {
  if (id) {
    return boxData.map((field) => {
      if ((field.id && field.id === id) || (field._id && field._id === id)) {
        return newFieldData;
      }

      return field;
    });
  }

  return [...boxData, newFieldData];
};

const sanitizeChoices = (choices) => {
  if (!choices) {
    return [];
  }

  return (
    escape(choices).split(/\r?\n/)
      .map((item) => item.trim())
      .filter((item) => item !== '')
  );
};

export const getUpdatedFieldData = (currentField, data, formProps) => {
  const id = getId(currentField);
  const dataItem = getFieldData(data, id);
  const dataItemValue = get(dataItem, 'value', {});

  const defaultFieldValue = formProps?.type?.value === CHECKBOX ? '0' : '';

  const baseFieldData = {
    ...dataItem,
    [isUnsavedData(currentField) ? '_id' : 'id']: id,
    key: 'form_input',
    value: {
      ...dataItemValue,
      choices: sanitizeChoices(formProps.choices),
      colspan: getFormPropsValue(formProps.colspan),
      description: escape(formProps.description),
      label: escape(formProps.label),
      placeholder: escape(formProps.placeholder),
      required: formProps.required,
      size: getFormPropsValue(formProps.size),
      type: getFormPropsValue(formProps.type),
      value: dataItemValue.value ? dataItemValue.value : defaultFieldValue,
      valueDataFieldKey: getFormPropsValue(formProps.valueDataFieldKey),
    },
  };

  const shouldClearFieldValue = (
    [dataItemValue.type, getFormPropsValue(formProps.type)]
      .some((type) => type !== TEXT_AREA && type !== TEXT_FIELD)
  );

  if (
    !getFormPropsValue(formProps.valueDataFieldKey)
    && !isEmpty(dataItemValue)
    && dataItemValue.type !== getFormPropsValue(formProps.type)
    && shouldClearFieldValue
  ) {
    baseFieldData.value.value = defaultFieldValue;
  }

  return baseFieldData;
};

export const trimNullFields = (columnCount, row) => {
  const result = [];
  let nullsToTrim = row.length - columnCount;

  for (let i = row.length - 1; i >= 0; i -= 1) {
    if (row[i] !== null || !nullsToTrim) {
      result.unshift(row[i]);
    } else {
      nullsToTrim -= 1;
    }
  }

  return result;
};
