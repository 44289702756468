// @flow

import React from 'react';
import SmallWorkspaceIcon from 'components/icons/small-workspace';
import style from './workspace-name.module.scss';

type Props = {
  workspace: Workspace,
}

const WorkspaceName = ({ workspace }: Props) => {
  if (!workspace) {
    return null;
  }

  return (
    <div className={style.WorkspaceNameContainer}>
      <SmallWorkspaceIcon height="12px" />
      <span className={style.WorkspaceName}>
        {workspace.name}
      </span>
    </div>
  );
};

export default WorkspaceName;
