/* eslint-disable react/display-name */
import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Media from 'react-media';

import Terminate from 'components/icons/terminate';

import { getAccountFromSessionSelector } from 'reducers/session';
import { showPaymentReminderBanner } from 'account';

import style from './payment-reminder-banner.module.scss';

const renderNoticeBanner = (isMobile: boolean) => {
  const noticeBannerClassnames = clsx(style.NoticeBanner, {
    [style.NoticeBannerMobile]: isMobile,
  });

  return (
    <div className={noticeBannerClassnames}>
      <div className={style.IconContainer}>
        <Terminate height="14px" width="14px" className={style.Icon} />
      </div>
      <Message
        id="Your Oneflow subscription payment is overdue. Please arrange payment at your earliest convenience."
        comment="Text for topbar message when a user hasn't paid"
      />
    </div>
  );
};

const PaymentReminderBanner = () => {
  const account = useSelector(getAccountFromSessionSelector) as Oneflow.Account;

  if (!showPaymentReminderBanner(account)) {
    return null;
  }

  return (
    <>
      <Media
        key="desktop-notice-banner"
        query={{
          minWidth: style.desktopmin,
        }}
      >
        {renderNoticeBanner(false)}
      </Media>
      <Media
        key="mobile-notice-banner"
        query={{
          maxWidth: style.mobilemax,
        }}
      >
        {renderNoticeBanner(true)}
      </Media>
    </>
  );
};

export default PaymentReminderBanner;
