/* eslint-disable camelcase */
/* eslint-disable import/named */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import Message from 'components/message';
import moment from 'moment';

import { formatDateString } from 'date';
import { getTimePeriodObj } from 'agreement/date-helpers';
import {
  TYPE_NO_DURATION,
  TYPE_NOT_SET,
  TYPE_RECURRING_WITH_INITIAL,
  TYPE_RECURRING,
  TYPE_SINGLE_PERIOD,
} from 'agreement/constants';
import { updateDurationBoxAttribute } from 'reducers/current-contract';
import { useDurationBoxProps } from 'contexts/duration-box-props';
import type {
  DurationBox,
  DurationType,
} from 'data-validators/entity-schemas/document-box/duration-box';

import { DurationContainer } from 'components/contract-boxes/duration-box/duration-container';
import { MenuItem } from 'components/menu-item';
import BoxWrapper from 'components/contract-boxes/box-wrapper';
import NewCheck from 'components/icons/new-check';

import style from './duration-box-container.module.scss';

type Props = {
  onRemoveBox: () => void,
}

export const DurationBoxContainer = ({
  onRemoveBox,
}: Props) => {
  const dispatch = useDispatch();
  const {
    boxId,
    duration,
    endDate,
    initialDuration,
    isAllowedToEditBoxData,
    isEditable,
    startDate,
    type,
  }: {
    boxId: number;
    duration: string | null;
    endDate: string | null;
    initialDuration: string | null;
    isAllowedToEditBoxData: boolean;
    isEditable: boolean;
    startDate: string | null;
    type: DurationBox['content']['agreement']['type'];
  } = useDurationBoxProps();

  const durationBoxClasses = clsx(style.DurationBoxContainer, {
    [style.DurationBoxPadding]: type !== TYPE_NOT_SET,
  });

  const onChangeDurationType = useCallback(({ type: durationType }: { type: DurationType }) => {
    const durationData = {
      type: durationType,
    };
    dispatch(updateDurationBoxAttribute(boxId, durationData));
  }, [boxId, dispatch]);

  const onChangeStartDate = useCallback(() => {
    const formattedInitialStartDate = formatDateString(moment().toString(), 'YYYY-MM-DD');

    const newStartDate = startDate ? null : formattedInitialStartDate;
    dispatch(updateDurationBoxAttribute(boxId, { startDate: newStartDate } as DurationBox['content']['agreement']));
  }, [startDate, dispatch, boxId]);

  const onChangeEndDate = useCallback(() => {
    const contractDuration = duration || initialDuration;
    const contractDurationObject = getTimePeriodObj(contractDuration, true);
    const formattedInitialEndDate = formatDateString(moment().add(contractDurationObject.duration)
      .toString(), 'YYYY-MM-DD');

    const durationData = {
      endDate: endDate ? null : formattedInitialEndDate,
      initialDuration: null,
    } as DurationBox['content']['agreement'];
    dispatch(updateDurationBoxAttribute(boxId, durationData));
  }, [boxId, dispatch, duration, endDate, initialDuration]);

  const onChangeInitialDuration = useCallback(() => {
    const durationData = {
      initialDuration: initialDuration ? null : duration,
      endDate: null,
    };
    dispatch(updateDurationBoxAttribute(boxId, durationData));
  }, [boxId, dispatch, duration, initialDuration]);

  const getDurationActions = () => ([
    <MenuItem
      key="duration-box-no-duration"
      icon={type
        === TYPE_NO_DURATION ? NewCheck : <div className={style.EmptyIcon} />}
      label={(
        <Message
          id="No duration"
          comment="Action to select No duration."
        />
      )}
      onClick={() => onChangeDurationType({ type: TYPE_NO_DURATION })}
      disabled={!isAllowedToEditBoxData}
    />,
    <MenuItem
      key="duration-box-single-period"
      icon={type
        === TYPE_SINGLE_PERIOD ? NewCheck : <div className={style.EmptyIcon} />}
      label={(
        <Message
          id="Single period"
          comment="Action to add Single period."
        />
      )}
      onClick={() => onChangeDurationType({ type: TYPE_SINGLE_PERIOD })}
      disabled={!isAllowedToEditBoxData}
    />,
    <MenuItem
      key="duration-box-recurring"
      icon={type
        === TYPE_RECURRING ? NewCheck : <div className={style.EmptyIcon} />}
      label={(
        <Message
          id="Recurring"
          comment="Action to add Recurring."
        />
      )}
      onClick={() => onChangeDurationType({ type: TYPE_RECURRING })}
      disabled={!isAllowedToEditBoxData}
    />,
    <MenuItem
      key="duration-box-recurring-with-initial"
      icon={type === TYPE_RECURRING_WITH_INITIAL
        ? NewCheck : <div className={style.EmptyIcon} />}
      label={(
        <Message
          id="Recurring with two periods"
          comment="Action to add Recurring with two periods."
        />
      )}
      onClick={() => onChangeDurationType({ type: TYPE_RECURRING_WITH_INITIAL })}
      disabled={!isAllowedToEditBoxData}
    />,
    'separator',
    <MenuItem
      key="duration-box-set-start-date"
      icon={startDate ? NewCheck : <div className={style.EmptyIcon} />}
      label={(
        <Message
          id="Set fixed start date"
          comment="Action to add set fixed start date."
        />
      )}
      onClick={onChangeStartDate}
      disabled={!isAllowedToEditBoxData || type === TYPE_NOT_SET}
    />,
    <MenuItem
      key="duration-box-set-end-date"
      icon={endDate ? NewCheck : <div className={style.EmptyIcon} />}
      label={(
        <Message
          id="Set fixed end date"
          comment="Action to add set fixed end date."
        />
      )}
      onClick={onChangeEndDate}
      disabled={!isAllowedToEditBoxData
        || type === TYPE_NOT_SET
        || type === TYPE_NO_DURATION}
    />,
    <MenuItem
      key="duration-box-set-initial-duration"
      icon={initialDuration ? NewCheck : <div className={style.EmptyIcon} />}
      label={(
        <Message
          id="Set initial duration"
          comment="Action to add set initial duration."
        />
      )}
      onClick={onChangeInitialDuration}
      disabled={!isAllowedToEditBoxData || type !== TYPE_RECURRING}
    />,
  ]);

  return (
    <BoxWrapper
      boxId={boxId}
      isAllowedToEdit={isEditable}
      onRemoveBox={onRemoveBox}
      nonFixedActions={getDurationActions()}
    >
      <div className={durationBoxClasses}>
        <DurationContainer
          onChangeDurationType={onChangeDurationType}
        />
      </div>
    </BoxWrapper>
  );
};
