// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import { WithCurrentWorkspaceLink } from 'hocs/with-current-workspace';
import Button from 'components/button';

import style from './template-list-link.module.scss';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  closeOnRedirect: ?() => void,
  // eslint-disable-next-line react/no-unused-prop-types
  isContractView?: boolean,
};

type NamespaceProps = {
  withCurrentWorkspaceNamespace: (path: string) => string,
};

type PropsWithCurrentWorkspace = Props & NamespaceProps

export const TemplateListLinkComponent = ({
  withCurrentWorkspaceNamespace,
  closeOnRedirect,
  isContractView,
}: PropsWithCurrentWorkspace) => (
  <Button
    href={withCurrentWorkspaceNamespace('/templates')}
    kind="linkInline"
    customClass={style.TemplateListLink}
    onClick={closeOnRedirect}
    external={Boolean(isContractView)}
  >
    <Message
      id="template list"
      comment="Link to redirect users to template list."
    />
  </Button>
);

TemplateListLinkComponent.defaultProps = {
  isContractView: undefined,
};

export const renderTemplateListLinkWithNameSpace = (
  props: Props,
) => (namespaceProps: NamespaceProps) => (
  <TemplateListLinkComponent
    {...props}
    withCurrentWorkspaceNamespace={namespaceProps.withCurrentWorkspaceNamespace}
  />
);

export const TemplateListLinkWithCurrentWorkspace = (props: Props) => (
  <WithCurrentWorkspaceLink>
    {renderTemplateListLinkWithNameSpace(props)}
  </WithCurrentWorkspaceLink>
);

TemplateListLinkWithCurrentWorkspace.defaultProps = {
  isContractView: undefined,
};
