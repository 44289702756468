// @flow

import * as React from 'react';
import { get } from 'lodash';

import { checkAcl } from 'components/acl';
import SetContractValueModal from 'components/modals/set-contract-value';

import Button from 'components/button';

import MoneyIcon from 'components/icons/money';
import style from './contract-value.module.scss';

export type Props = {|
  agreement?: Agreement,
  agreementValue?: AgreementValue,
  currentLanguage: string,
  className?: string,
  showPlaceholder?: boolean,
  isReadOnly?: boolean,
  showIcon?: boolean,
|};

export default class ContractValueComponent extends React.PureComponent<Props> {
  static defaultProps = {
    agreement: undefined,
    agreementValue: undefined,
    className: undefined,
    showPlaceholder: undefined,
    isReadOnly: undefined,
    showIcon: true,
  };

  getChildren = (onClick: Function) => {
    const { agreement } = this.props;

    return (
      <Button
        customClass={style.AddButton}
        kind="linkSeparate"
        onClick={onClick}
        disabled={!checkAcl(get(agreement, 'acl'), 'agreement:agreement_value:update:amount')}
      >
        {this.renderContractValueText()}
      </Button>
    );
  }

  parseAgreementAmount(agreementValue: AgreementValue) {
    const { currentLanguage } = this.props;

    return agreementValue.amount.toLocaleString(currentLanguage, {
      style: 'currency',
      currency: agreementValue.currency,
      currencyDisplay: 'code',
      minimumFractionDigits: 0,
    });
  }

  renderContractValueText() {
    const {
      agreementValue,
      showPlaceholder,
      showIcon,
    } = this.props;

    if (agreementValue) {
      return (
        <>
          {showIcon && <MoneyIcon width="14px" />}
          {this.parseAgreementAmount(agreementValue)}
        </>
      );
    }

    if (showPlaceholder) {
      return '-';
    }

    return null;
  }

  render() {
    const {
      agreement,
      className,
      isReadOnly,
      agreementValue,
      showPlaceholder,
    } = this.props;

    if (!agreement || isReadOnly) {
      return (
        <div className={className}>
          {this.renderContractValueText()}
        </div>
      );
    }

    if (!agreementValue && !showPlaceholder) {
      return null;
    }

    return (
      <div className={className}>
        <SetContractValueModal agreement={agreement}>
          {this.getChildren}
        </SetContractValueModal>
      </div>
    );
  }
}
