import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { ReactNode } from 'react';

type InlineEditableContextValue = {
  characterCount: number;
  hasOverflow: boolean;
  isFocused: boolean;
  setHasOverflow: (newHasOverflow: boolean) => void;
  setIsFocused: (newIsFocused: boolean) => void;
  setText: (newContent: string) => void;
  text: string;
};

type Props = {
  children: ReactNode;
}

const InlineEditableContext = createContext<InlineEditableContextValue | undefined>(undefined);

export const InlineEditablePropsProvider = ({ children }: Props) => {
  const [characterCount, setCharacterCount] = useState(0);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    setCharacterCount(text.length);
  }, [text]);

  const contextValue = useMemo(() => ({
    characterCount,
    hasOverflow,
    isFocused,
    setHasOverflow,
    setIsFocused,
    setText,
    text,
  }), [
    characterCount,
    hasOverflow,
    isFocused,
    setHasOverflow,
    setIsFocused,
    setText,
    text,
  ]);

  return (
    <InlineEditableContext.Provider value={contextValue}>
      {children}
    </InlineEditableContext.Provider>
  );
};

export const useInlineEditableProps = () => {
  const context = useContext(InlineEditableContext);

  if (context === undefined) {
    return ({
      characterCount: 0,
      hasOverflow: false,
      isFocused: false,
      setHasOverflow: () => null,
      setIsFocused: () => null,
      setText: () => null,
      text: '',
    });
  }

  return context;
};
