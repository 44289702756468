/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import adminPage from 'hocs/admin-page';
import { getAccountFromSessionSelector } from 'reducers/session';
import positionsReducer from 'reducers/entities/positions';
import userAccountsReducer from 'reducers/entities/user-accounts';

import ActionBar from 'components/action-bar';
import { ActionBarText } from 'components/action-bar-text';
import Button from 'components/button';
import GrantUserAccessIcon from 'components/icons/grant-user-access';
import { GrantUserAccountAccess, GrantUserAccountAccessNonEnterprise } from 'components/modals/grant-user-account-access';
import { checkAcl } from 'components/acl';
import SingleUserActionsAddIcon from 'components/icons/single-user-actions-add';
import EmptyState from 'components/empty-state';
import Pagination from 'components/pagination';
import AlertIcon from 'components/icons/alert';

import { isUserLimited } from 'user';
import style from './user-account-access.module.scss';
import DirectAccessTable from './direct-access-table';
import GroupAccessTable from './group-access-table';

const DEFAULT_PAGINATION = {
  limit: 20,
  offset: 0,
};

const DIRECT_ACCESS = 'direct';
const GROUP_ACCESS = 'group';

export const getUserAccountsQueryName = (id: number) => `admin/user/${id}/account-access`;
export const getUserGroupsAccountsQueryName = (id: number) => `admin/user/${id}/groups/account-access`;

type Params = {
  id: string,
};

type DirectOrGroupType = 'direct' | 'group';

export const UserAccountAccess = ({ message }: { message: MessageTranslator }) => {
  const dispatch = useDispatch();
  const { id } = useParams<Params>();
  const positionId = parseInt(id, 10);
  const account = useSelector(getAccountFromSessionSelector);
  const position = useSelector(
    (state) => positionsReducer.getPositionSelector(state, { id: positionId }),
  );
  const userAccountsBindingsQuery = useSelector((state) => userAccountsReducer.getQuerySelector(
    state, { name: getUserAccountsQueryName(positionId) },
  ));

  const userAccountsBindings = useSelector((state) => userAccountsReducer.getUserAccountsSelector(
    state, { ids: userAccountsBindingsQuery.result },
  ));

  const userGroupsAccountsBindingsQuery = useSelector(
    (state) => userAccountsReducer.getQuerySelector(
      state, { name: getUserGroupsAccountsQueryName(positionId) },
    ),
  );

  const userGroupsAccountsBindings = useSelector(
    (state) => userAccountsReducer.getUserAccountsSelector(
      state, { ids: userGroupsAccountsBindingsQuery.result },
    ),
  );

  const hasGrantSystemRolePermissionPosition = useMemo(() => checkAcl(account.acl, 'account:position:system_role_binding:create'), [account.acl]);
  const hasGrantCustomRolePermissionPosition = useMemo(() => checkAcl(account.acl, 'account:position:custom_role_binding:create'), [account.acl]);
  const hasFullAccountRoleAccessPosition = hasGrantSystemRolePermissionPosition
    && hasGrantCustomRolePermissionPosition;

  const hasViewAccessGroup = useMemo(() => checkAcl(
    account?.acl,
    [
      'account:group:custom_role_binding:view',
      'account:group:system_role_binding:view',
    ],
    { match: 'any' },
  ),
  [account?.acl]);

  useEffect(() => {
    dispatch(userAccountsReducer.queryUserAccounts({
      name: getUserAccountsQueryName(positionId),
      params: {
        id: positionId,
        actorType: 'position',
      },
      pagination: DEFAULT_PAGINATION,
      sort: ['-role.type', 'role.name'],
    }));
  }, [dispatch, positionId]);

  useEffect(() => {
    if (hasViewAccessGroup) {
      dispatch(userAccountsReducer.queryUserAccounts({
        name: getUserGroupsAccountsQueryName(positionId),
        params: {
          id: positionId,
          actorType: 'group',
        },
        pagination: DEFAULT_PAGINATION,
        sort: ['-role.type', 'role.name'],
      }));
    }
  }, [dispatch, hasViewAccessGroup, positionId]);

  const onPaginate = (type: DirectOrGroupType, pagination: RequestParams.Pagination) => {
    if (type === DIRECT_ACCESS) {
      dispatch(userAccountsReducer.queryUserAccount({
        name: getUserAccountsQueryName(positionId),
        params: {
          positionId,
        },
        pagination,
        sort: ['-role.type', 'role.name'],
      }));
    } else if (type === GROUP_ACCESS && hasViewAccessGroup) {
      dispatch(userAccountsReducer.queryUserAccounts({
        name: getUserGroupsAccountsQueryName(positionId),
        params: {
          id: positionId,
          actorType: 'group',
        },
        pagination,
        sort: ['-role.type', 'role.name'],
      }));
    }
  };

  let isQueryResultsEmpty = userAccountsBindingsQuery.count === 0;
  let isQueriesLoading = !userAccountsBindingsQuery.loading;
  if (hasViewAccessGroup) {
    isQueryResultsEmpty = userAccountsBindingsQuery.count === 0
      && userGroupsAccountsBindingsQuery.count === 0;
    isQueriesLoading = !userAccountsBindingsQuery.loading
      && !userGroupsAccountsBindingsQuery.loading;
  }
  const shouldShowEmptyState = isQueryResultsEmpty && isQueriesLoading;

  const renderEmptyState = () => (
    <EmptyState
      icon={<SingleUserActionsAddIcon height="28px" />}
      header={message({
        id: 'Assign this user an account role',
        comment: 'Empty state header for the account access list in the user pages.',
      })}
      content={message({
        id: 'Different roles will grant access to different application areas.',
        comment: 'Empty state content for the account access list in the user pages.',
      })}
      defaultStyle
      className={style.EmptyState}
    />
  );

  const getGrantUserAccountAccessButton = () => (onClick: () => void) => {
    let buttonLabel = (
      <Message
        id="Grant account access"
        comment="Button for opening grant account access modal."
      />
    );
    if (!hasGrantCustomRolePermissionPosition && hasGrantSystemRolePermissionPosition) {
      buttonLabel = (
        <Message
          id="Assign account admin role"
          comment="Button for opening grant account access modal."
        />
      );
    }
    const hasNoRolePermissions = !hasGrantCustomRolePermissionPosition
      && !hasGrantSystemRolePermissionPosition;

    return (
      <Button
        icon={GrantUserAccessIcon}
        kind="primary"
        onClick={onClick}
        disabled={hasNoRolePermissions || isUserLimited(position)}
      >
        {buttonLabel}
      </Button>
    );
  };

  const renderGrantUserAccountModal = () => {
    if (!isEmpty(position)) {
      if (hasFullAccountRoleAccessPosition) {
        return (
          <GrantUserAccountAccess
            shouldRedirectToAccountAccess={false}
            renderedFromAccountAccessPage={false}
            position={position}
          >
            {getGrantUserAccountAccessButton()}
          </GrantUserAccountAccess>
        );
      }

      return (
        <GrantUserAccountAccessNonEnterprise
          renderedFromAccountAccessPage={false}
          shouldRedirectToAccountAccess={false}
          position={position}
        >
          {getGrantUserAccountAccessButton()}
        </GrantUserAccountAccessNonEnterprise>
      );
    }
    return null;
  };

  const renderPagination = (type: DirectOrGroupType) => {
    const query = type === GROUP_ACCESS
      ? userGroupsAccountsBindingsQuery : userAccountsBindingsQuery;
    return (
      !query.loading && query.count > 0 && (
        <Pagination
          totalItems={query.count}
          itemsPerPage={query.pagination.limit}
          currentOffset={query.pagination.offset}
          onChange={(pagination: RequestParams.Pagination) => onPaginate(type, pagination)}
          entityName={message({
            id: 'account roles',
            comment: 'Plural form in pagination of account roles in the user pages.',
          }) as string}
        />
      ));
  };

  const renderActionBarText = () => {
    if (isUserLimited(position)) {
      return (
        <>
          <AlertIcon height="14px" className={style.LimitedAccessIcon} />
          <Message
            id="Limited access users can only view, sign, and comment on documents, regardless of their roles."
            comment="Header text for limited access user in user account access tab"
          />
        </>
      );
    }
    return null;
  };

  return (
    <div className={style.AccountContainer}>
      <ActionBar collapsed>
        <ActionBar.Group>
          {renderGrantUserAccountModal()}
          <ActionBarText
            header={(
              <Message
                id="Manage access to the account for this user"
                comment="Header text for the account access list in the user pages."
              />
            )}
            text={renderActionBarText()}
          />
        </ActionBar.Group>
      </ActionBar>
      {shouldShowEmptyState
        ? renderEmptyState()
        : (
          <>
            <DirectAccessTable
              account={account}
              message={message}
              position={position}
              items={userAccountsBindings}
              query={userAccountsBindingsQuery}
              hasGrantSystemRolePermission={hasGrantSystemRolePermissionPosition}
              hasGrantCustomRolePermission={hasGrantCustomRolePermissionPosition}
            >
              {renderPagination(DIRECT_ACCESS)}
            </DirectAccessTable>
            {hasViewAccessGroup
            && (
            <GroupAccessTable
              message={message}
              items={userGroupsAccountsBindings}
              query={userGroupsAccountsBindingsQuery}
            >
              {renderPagination(GROUP_ACCESS)}
            </GroupAccessTable>
            )}
          </>
        )}
    </div>
  );
};

export default adminPage(({ props: { message } }) => ({
  title: message({ id: 'Account access', comment: 'The page title' }),
  modules: [[]],
}))(UserAccountAccess);
