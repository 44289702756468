// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import { ExtensionName } from 'components/extension/extension-name';
import DynamicLink from 'components/dynamic-link';
import style from './extension-install-application.module.scss';

type Props = {
  extension: Extension,
  appName: string,
  appStoreName: string,
  appStoreUrl: string,
}

const getDescription = ({
  appName,
  extension,
}: {
  appName: string,
  extensionName: string | React.Element<*>,
  isExtensionEnabled: boolean,
}) => {
  if (extension.state) {
    return (
      <Message
        id="Need to re-install? Follow the link below."
        comment="Help text for re-installing the extension from the extension app store."
      />
    );
  }

  return (
    <Message
      id="Before enabling the extension, please install the application {applicationName} in {extensionName}. Follow the link below to install the application."
      comment="Help text for installing the extension from the extension app store."
      values={{
        applicationName: <span className={style.ApplicationName}>{appName}</span>,
        extensionName: <ExtensionName extension={extension} />,
      }}
    />
  );
};

export const ExtensionInstallApplication = ({
  extension,
  appName,
  appStoreName,
  appStoreUrl,
}: Props) => (
  <>
    <div className={style.InstallHeader}>
      <Message
        id="Install application"
        comment="Extension installation header"
      />
    </div>
    <p>
      {getDescription({ appName, extension })}
    </p>
    <p>
      <DynamicLink url={appStoreUrl}>
        <Message
          id="Go to {name}"
          comment="Link to the Oneflow app/module to install from the extensions' app store."
          values={{
            name: appStoreName,
          }}
        />
      </DynamicLink>
    </p>
  </>
);
