import {
  canBeSigned,
  isMyParticipantTurnToSign,
} from 'agreement/selectors';
import { isPending } from 'agreement';
import isUndecided from 'agreement/participant/is-undecided';

import {
  OVERLAY_FIELD_ELEMENTS_ATTRIBUTES_QUERY,
} from 'components/contract-boxes/pdf-box/pdf-file/pdf-viewer/pdf-page/overlay-field/overlay-text-field/keyboard-navigation-helpers';

export const EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID = 'empty-editable-required-fields-toast-id';
export const EMPTY_NON_EDITABLE_REQUIRED_FIELDS_TOAST_ID = 'empty-non-editable-required-fields-toast-id';

type Params = {
  agreement: Oneflow.Agreement,
  myParticipant?: Oneflow.Participant | null,
  hasError: boolean,
}
// eslint-disable-next-line import/prefer-default-export
export const canBeSignedByMyParticipant = ({
  agreement,
  hasError,
  myParticipant,
}: Params): boolean => {
  const agreementCanBeSigned = agreement.parties ? canBeSigned(agreement) : false;
  const isSignLaterEnabled = agreement.config?.signLater || false;

  if (!myParticipant) {
    return false;
  }

  return agreementCanBeSigned
    && isMyParticipantTurnToSign(agreement, myParticipant)
    && isUndecided(myParticipant)
    && isPending(agreement)
    && !isSignLaterEnabled
    && !hasError;
};

const EMPTY_REQUIRED_INPUT_SELECTOR = 'input[required][value=""]';
const EMPTY_REQUIRED_SELECT_SELECTOR = 'input.ReactSelect__input[aria-required][data-selected-option-value=""]';
const EMPTY_REQUIRED_TEXTAREA_SELECTOR = 'textarea[required][data-value=""]';
const EMPTY_REQUIRED_TEXTAREA_SELECTOR_WITHOUT_OVERLAY_FIELDS = `${EMPTY_REQUIRED_TEXTAREA_SELECTOR}:not([name="overlay-text-field"])`;
const EMPTY_REQUIRED_OVERLAY_FIELDS = `${EMPTY_REQUIRED_TEXTAREA_SELECTOR}${OVERLAY_FIELD_ELEMENTS_ATTRIBUTES_QUERY}`;
const EMPTY_REQUIRED_CHECKBOX_SELECTOR = '[aria-required="true"][aria-checked="false"][aria-disabled="false"]';
const EMPTY_REQUIRED_ATTACHMENT_BOX_SELECTOR = '[aria-label="attachment-dropzone"][aria-required="true"]';
const ACTIVE_ELEMENT_ATTRIBUTE = 'data-active-element';

export const focusNextEmptyRequiredElement = () => {
  const boxSelector = '[data-box-id]:not([data-hidden="true"])';

  const emptyFieldsNodeList = document.querySelectorAll<HTMLElement>(`
    ${boxSelector} ${EMPTY_REQUIRED_INPUT_SELECTOR},
    ${boxSelector} ${EMPTY_REQUIRED_TEXTAREA_SELECTOR_WITHOUT_OVERLAY_FIELDS},
    ${boxSelector} ${EMPTY_REQUIRED_OVERLAY_FIELDS},
    ${boxSelector} ${EMPTY_REQUIRED_CHECKBOX_SELECTOR},
    ${boxSelector} ${EMPTY_REQUIRED_SELECT_SELECTOR},
    ${boxSelector} ${EMPTY_REQUIRED_ATTACHMENT_BOX_SELECTOR}
  `);

  if (!emptyFieldsNodeList) {
    return;
  }

  const elements = Array.from(emptyFieldsNodeList)
    .sort((a, b) => {
      const rectA = a.getBoundingClientRect();
      const rectB = b.getBoundingClientRect();
      if (rectA.y !== rectB.y) {
        return rectA.y - rectB.y;
      }

      return 0;
    });

  if (elements.length === 0) {
    return;
  }

  // We keep track of the active element by the `data-active-element` attribute
  const activeElementIndex = elements.findIndex((element) => element.getAttribute(ACTIVE_ELEMENT_ATTRIBUTE) === 'true');

  let nextIndex = activeElementIndex + 1;

  if (nextIndex === elements.length) {
    nextIndex = 0;
  }

  const nextElement = elements[nextIndex];

  // move the active element attribute to the next element
  if (activeElementIndex > -1) {
    elements[activeElementIndex].removeAttribute(ACTIVE_ELEMENT_ATTRIBUTE);
  }

  nextElement.setAttribute(ACTIVE_ELEMENT_ATTRIBUTE, 'true');
  // To make sure that the element is in middle of the screen, we need this! Don't remove it!
  nextElement.scrollIntoView({ block: 'center' });
  nextElement.focus();
};
