// @flow

import * as React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { Acl } from 'components/acl';
import SidebarOption from 'components/sidebar/sidebar-option/sidebar-option';

type Props = {
  acl: {
    account: Acl,
    workspace: Acl,
  },
  isMobile: boolean,
  isContractView: boolean,
  message: MessageTranslator,
  onItemClick: () => void,
};

const SidebarItemsAdmin = ({
  isMobile,
  message,
  onItemClick,
  isContractView,
  acl,
}: Props) => {
  const accountTooltipText = message({
    id: 'Account overview',
    comment: 'Used as a tooltip for the sidebar item',
  });

  return (
    <>
      <Acl acl={acl.account} check="account:admin">
        <SidebarOption
          data-testid="sidebar-admin-account"
          expanded
          isMobile={isMobile}
          link="/admin/account"
          tooltipText={accountTooltipText}
          trackable={{
            name: 'Go To Account Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
      <Acl acl={acl.account} check="account:admin:role">
        <SidebarOption
          data-testid="sidebar-admin-account-access"
          expanded
          isMobile={isMobile}
          link="/admin/account-access"
          tooltipText={message({
            id: 'Account access',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Account Access Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
      <Acl acl={acl.account} check="account:admin:user">
        <SidebarOption
          data-testid="sidebar-admin-users"
          expanded
          isMobile={isMobile}
          link="/admin/users"
          tooltipText={message({
            id: 'Users',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Users Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
      <Acl acl={acl.account} check="account:admin:workspace">
        <SidebarOption
          data-testid="sidebar-admin-workspaces"
          expanded
          isMobile={isMobile}
          link="/admin/workspaces"
          tooltipText={message({
            id: 'Workspaces',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Workspaces Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
      <Acl acl={acl.account} check="account:admin:role">
        <SidebarOption
          data-testid="sidebar-admin-roles"
          expanded
          isMobile={isMobile}
          link="/admin/roles"
          tooltipText={message({
            id: 'Roles',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Roles Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
      <Acl acl={acl.account} check="account:admin:group">
        <SidebarOption
          data-testid="sidebar-admin-groups"
          expanded
          isMobile={isMobile}
          link="/admin/groups"
          tooltipText={message({
            id: 'Groups',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Groups Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
      <Acl acl={acl.account} check="account:admin:tag">
        <SidebarOption
          data-testid="sidebar-admin-tags"
          expanded
          isMobile={isMobile}
          link="/admin/tags"
          tooltipText={message({
            id: 'Tags',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Tags Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
      <Acl acl={acl.account} check="account:admin:billing">
        <SidebarOption
          data-testid="sidebar-admin-billing"
          expanded
          isMobile={isMobile}
          link="/admin/billing"
          tooltipText={message({
            id: 'Subscription and billing',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Billing Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
      <Acl acl={acl.account} check="account:admin:audit_log">
        <SidebarOption
          data-testid="sidebar-admin-audit-log"
          expanded
          isMobile={isMobile}
          link="/admin/audit-log"
          tooltipText={message({
            id: 'Audit log',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Audit Log Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
      <Acl acl={acl.account} check="account:admin:data_management">
        <SidebarOption
          data-testid="sidebar-admin-data-management"
          expanded
          isMobile={isMobile}
          link="/admin/data-management"
          tooltipText={message({
            id: 'Data management',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Data Management Page',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
          isContractView={isContractView}
        />
      </Acl>
    </>
  );
};

export default localize<Props>(SidebarItemsAdmin);
