// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { Message, type MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';

import { checkAcl } from 'components/acl';
import { Table } from 'components/table';
import ActionBar from 'components/action-bar';
import { ActionBarText } from 'components/action-bar-text';
import GrantGroupAccessIcon from 'components/icons/grant-group-access';
import Button from 'components/button';
import Conditional from 'components/conditional';
import GrantGroupWorkspaceAccess from 'components/modals/grant-group-workspace-access';
import NoGroupMemberIcon from 'components/icons/no-groupmember';
import Pagination from 'components/pagination';
import { HelpCenterLink } from 'components/help-center-link';
import ActionsMenu from 'components/actions-menu';
import { EditWorkspaceGroupRole } from 'components/modals/edit-workspace-role';
import RemoveGroupFromWorkspace from 'components/modals/remove-group-from-workspace';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { ChangeRoleMenuItem } from 'components/menu-items/change-role';

import style from './group-workspace-access.module.scss';

const REMOVE_GROUP_PIPE_ACTION = 'queryGroupWorkspaces';
const GROUP_WORKSPACES_ACCESS_QUERY = 'groupWorkspaces/groupAccess';

type Props = {
  group: Group,
  message: MessageTranslator,
  query: Query,
  queryGroupWorkspaces: (args: QueryFuncArgs) => void,
  groupsBindingsRoles: Array<Binding>,
};

const defaultPagination = {
  limit: 50,
  offset: 0,
};

export class GroupWorkspaceAccessComponent extends React.Component<Props> {
  componentDidMount() {
    this.navigateToPage(this.props.query.pagination);
  }

  getTableConfig() {
    const { message, groupsBindingsRoles } = this.props;
    const actions = [];

    const columns = [
      {
        name: 'workspace name',
        label: message({
          id: 'Workspace name',
          comment: 'Label in the group workspace access page.',
        }),
        type: 'cell',
        value: (item) => (
          <Link key={item.resource.id} to={`/admin/workspaces/${item.resource.id}`}>
            {item.resource.name}
          </Link>
        ),
      },
      {
        name: 'workspace role',
        label: message({
          id: 'Workspace role',
          comment: 'Label in the group workspace access page.',
        }),
        type: 'cell',
        value: (item) => (
          <Link key={item.id} to={`/admin/roles/${item.role.id}`}>
            {item.role.name}
          </Link>
        ),
      },
      {
        name: 'permissions',
        label: message({ id: 'Permissions', comment: 'Column label in users page' }),
        type: 'cell',
        value: (item) => (
          <span>
            {`${item.role.permissionCount}/${item.role.maxPermissionCount}`}
          </span>
        ),
      },
      {
        name: 'actions',
        label: message({ id: 'Actions', comment: 'Column label in users page' }),
        type: 'actions',
        value: (item) => this.getActionsForGroupAccess(item),
      },
    ];

    return {
      items: groupsBindingsRoles,
      itemKey: 'id',
      actions,
      columns,
    };
  }

  getEmptyState() {
    const { message } = this.props;

    return {
      header: message({
        id: "This group doesn't have access to any workspaces",
        comment: 'Empty state header for the workspace access list in the group pages.',
      }),
      icon: <NoGroupMemberIcon height="33px" />,
      content: (
        <div>
          <HelpCenterLink url="support/solutions/articles/77000435914-grant-access-to-a-workspace" />
        </div>
      ),
      showEmptyState: this.shouldShowEmptyState(),
    };
  }

  shouldShowEmptyState() {
    return (this.props.query.count === 0 && !this.props.query.loading);
  }

  navigateToPage({ limit, offset }) {
    this.props.queryGroupWorkspaces({
      pagination: {
        limit,
        offset,
      },
    });
  }

  getGrantWorkspaceAccessMenuItem = (group: Group) => (onClick: () => void) => (
    <Button
      disabled={!checkAcl(group.acl, 'group:collection:binding:create')}
      icon={GrantGroupAccessIcon}
      kind="primary"
      onClick={onClick}
    >
      <Message
        id="Grant workspace access"
        comment="label for granting a group access to a workspace"
      />
    </Button>
  );

  getEditMenuItem = (permission: boolean) => (onClick: () => void) => (
    <ChangeRoleMenuItem
      onClick={onClick}
      disabled={!permission}
    />
  );

  getRemoveMenuItem = (permission: boolean) => (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!permission}
    />
  )

  getActionsForGroupAccess = (binding) => {
    const {
      message,
    } = this.props;
    const { group } = binding;

    const hasEditGroupRolePermission = checkAcl(group.acl, 'group:collection:binding:create');
    const hasRemoveGroupAccessPermission = checkAcl(group.acl, 'group:collection:binding:remove');

    return (
      <ActionsMenu
        actions={[
          <EditWorkspaceGroupRole
            title={message({
              id: 'Edit group role',
              comment: 'Modal title for changing workspace access role.',
            })}
            workspaceId={binding.resource.id}
            group={group}
            role={binding.role}
            bindingId={binding.id}
            pipeAction={GROUP_WORKSPACES_ACCESS_QUERY}
          >
            {this.getEditMenuItem(hasEditGroupRolePermission)}
          </EditWorkspaceGroupRole>,
          <RemoveGroupFromWorkspace
            workspace={binding.resource}
            bindingId={binding.id}
            roleId={binding.role.id}
            group={group}
            pipeAction={REMOVE_GROUP_PIPE_ACTION}
          >
            {this.getRemoveMenuItem(hasRemoveGroupAccessPermission)}
          </RemoveGroupFromWorkspace>,
        ]}
        focusOnCloseDisabled
      />
    );
  };

  render() {
    const {
      message, group, queryGroupWorkspaces,
    } = this.props;

    return (
      <div className={style.Workspaces}>
        <ActionBar collapsed>
          <ActionBar.Group>
            <GrantGroupWorkspaceAccess
              group={group}
              onSuccess={() => queryGroupWorkspaces(defaultPagination)}
            >
              {this.getGrantWorkspaceAccessMenuItem(group)}
            </GrantGroupWorkspaceAccess>
            <ActionBarText
              header={(
                <Message
                  id="Manage access to workspaces for this group"
                  comment="Header text for the workspace access list in the user pages."
                />
              )}
            />
          </ActionBar.Group>
        </ActionBar>
        <Conditional ifCondition={!this.shouldShowEmptyState()}>
          <div className={style.ContentHeader}>
            <h3 className={style.EmptyStateHeader}>
              <Message
                id="Workspace access"
                comment="Label in the group workspace access page."
              />
            </h3>
          </div>
        </Conditional>
        <Table
          config={this.getTableConfig()}
          query={this.props.query}
          emptyState={this.getEmptyState()}
        />
        <Conditional ifCondition={!this.shouldShowEmptyState()
        && !this.props.query.loading
        && this.props.query.count > 0}
        >
          <Pagination
            totalItems={this.props.query.count}
            itemsPerPage={this.props.query.pagination.limit}
            currentOffset={this.props.query.pagination.offset}
            onChange={(params) => this.navigateToPage(params)}
            entityName={message({
              id: 'workspaces',
              comment: 'Plural form in pagination of workspaces in the group pages.',
            })}
          />
        </Conditional>
      </div>
    );
  }
}

export default adminPage(({ props: { message } }) => ({
  title: message({
    id: 'Workspace access',
    comment: 'Page title for the workspace access list in the group pages.',
  }),
  modules: [[]],
}))(GroupWorkspaceAccessComponent);
