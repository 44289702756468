import {
  memo,
  useCallback,
  useMemo,
} from 'react';
import { Message } from '@oneflowab/pomes';
import { useDroppable } from '@dnd-kit/core';
import { useSelector } from 'react-redux';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { getCurrentContractId } from 'reducers/current-contract';
import { isBoxDataRemoveAllowed } from 'agreement/box-data-remove-permissions';
import { isMobileIOS } from 'utils/browser';
import { usePdfBoxProps } from 'contexts/pdf-box-props';
import useAgreement from 'hooks/use-agreement';
import useCurrentBox from 'hooks/use-current-box';
import type { DataField } from 'data-validators/entity-schemas/agreement-data';
import type {
  PDFBox,
  PDFOverlayFieldData,
  PDFOverlayFieldSignatureData,
} from 'data-validators/entity-schemas/document-box/pdf-box';

import {
  A4_WIDTH,
  DATA_FIELD,
  SIGNATURE_FIELD,
} from 'components/contract-boxes/constants';
import { LoadingStatusBox } from 'components/contract-boxes/loading-status-box';
import ErrorBoundary from 'components/error-boundary';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import OverlayField from 'components/contract-boxes/pdf-box/pdf-file/pdf-viewer/pdf-page/overlay-field';
import type {
  OverlayFieldPosition,
  Size,
} from 'components/contract-boxes/pdf-box/overlay-field-helpers';

import { OverlaySignatureField } from './overlay-field/overlay-signature-field/overlay-signature-field';
import Page from './react-pdf-page';
import style from './pdf-page.module.scss';
import useCanvasMemoryOptimizer from './use-canvas-memory-optimizer';
import usePdfIntersectionObserver from './use-pdf-intersection-observer';
import usePdfMeasurement from './use-pdf-measurement';

const DEVICE_PIXEL_RATIO = isMobileIOS() ? 1.5 : undefined;

type Props = {
  onLoadSuccess: (values: { page: number, height: number }) => void;
  overlayFields: PDFOverlayFieldData[] | PDFOverlayFieldSignatureData[];
  pageNumber: number;
  scale: number,
};

type OnDropType = typeof SIGNATURE_FIELD | typeof DATA_FIELD;

const PdfPage = ({
  onLoadSuccess,
  overlayFields,
  pageNumber,
  scale,
}: Props) => {
  const { boxId, onDataFieldDrop } = usePdfBoxProps();
  const box = useCurrentBox(boxId) as PDFBox;
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const isLinkDisabled = agreement?.availableOptions?.disableLinkPlugin;

  const dataFields = useMemo(() => agreement?.data?.filter(
    (item) => item?.key === DATA_FIELD,
  ), [agreement?.data]) as DataField[];

  const onDrop = useCallback(
    (type: OnDropType, dataFieldKey: string, position: OverlayFieldPosition, size: Size) => {
      onDataFieldDrop(pageNumber, dataFieldKey, position, size, type);
    }, [onDataFieldDrop, pageNumber],
  );

  const { setNodeRef } = useDroppable({
    id: `droppable-pdf-page-${boxId}-${pageNumber}`,
    data: {
      segmentId: 'smart_fields',
      onDrop,
      scale,
    },
  });

  const {
    containerRef,
    isVisible,
  } = usePdfIntersectionObserver();

  const {
    height,
    inputRef,
    canvasRef,
    onRenderSuccess,
  } = usePdfMeasurement({ page: pageNumber, onSuccess: onLoadSuccess });

  useCanvasMemoryOptimizer(canvasRef, isVisible);

  const loadingMessage = (
    <div className={style.LoadingPdfPage}>
      <LoadingStatusBox
        message={(
          <Message
            id="The page is loading, please wait…"
            comment="This message shows while a page of the PDF is loading"
          />
        )}
        width="100%"
        height={height}
      />
    </div>
  );

  const customError = (
    <div className={style.FailedPdfPage}>
      <Message
        id="Oops! Page load unsuccessful. Please try again later."
        comment="This message shows while loading a pdf page fails"
      />
    </div>
  );

  return (
    <div
      className={style.PdfPage}
      ref={containerRef}
      style={{ height: `${height}px` }}
    >
      {
        isVisible && (
          <ErrorBoundary
            customError={customError}
            errorContext="BoxRenderError#Pdf"
          >
            <div ref={setNodeRef}>
              <Page
                inputRef={inputRef}
                pageNumber={pageNumber}
                renderTextLayer
                renderAnnotationLayer={!isLinkDisabled}
                width={A4_WIDTH}
                onRenderSuccess={onRenderSuccess}
                loading={loadingMessage}
                devicePixelRatio={DEVICE_PIXEL_RATIO}
              />
            </div>
          </ErrorBoundary>
        )
      }
      {overlayFields
        .map((overlayField) => {
          const canRemoveOverlayField = isBoxDataRemoveAllowed(box, overlayField);
          const overlayFieldValue = overlayField.value;
          const isSignature = overlayField?.key === SIGNATURE_FIELD;

          if (isSignature) {
            return (
              <OverlaySignatureField
                key={getId(overlayField)}
                overlayFieldValue={overlayFieldValue}
                id={getId(overlayField)}
                boxId={boxId}
                toBeRemoved={Boolean(overlayField._removed)}
                scale={scale}
                canRemoveOverlayField={canRemoveOverlayField}
                boundaries={{ width: A4_WIDTH * scale, height: height * scale }}
              />
            );
          }
          return (
            <OverlayField
              key={getId(overlayField)}
              overlayField={overlayField}
              id={getId(overlayField)}
              boxId={boxId}
              toBeRemoved={Boolean(overlayField._removed)}
              pageNumber={pageNumber}
              dataFields={dataFields}
              scale={scale}
              canRemoveOverlayField={canRemoveOverlayField}
            />
          );
        })}
    </div>
  );
};

export default memo(PdfPage);
