// @flow

import React from 'react';

import withCurrentWorkspace from 'hocs/with-current-workspace';

import { checkAcl } from 'components/acl';
import { NotFound } from 'components/errors';

import AddressBook from './address-book';

type Props = {
  acl: {
    workspace: Acl,
  },
};

export const AddressBookModuleComponent = (props: Props) => {
  const { acl, ...restProps } = props;

  if (!checkAcl(props.acl.workspace, 'collection:addressbook:view')) {
    return <NotFound redirect to="/dashboard" />;
  }

  return <AddressBook props={props} acl={acl} {...restProps} />;
};

export default withCurrentWorkspace(AddressBookModuleComponent);
