/* eslint-disable import/named */
import { decamelizeKeys } from 'humps';
import { extractResponseBodyAsJSON, post } from 'oneflow-client/core';
import { normalizeAgreement } from 'oneflow-client/agreements';

const startFlow = (flowId: string, params: RequestParams.FlowStart) => (
  post({
    url: `/agreement_flows/${flowId}/flow_start`,
    body: decamelizeKeys({
      ...params,
    }, { separator: '_' }),
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAgreement)
);

export default startFlow;
