// @flow

import { Transforms } from 'slate';
import { getColumnIndex, createBlockMatrix } from 'components/rich-text-editor/editor-plugins/table-utils/block-matrix';
import {
  getActiveTableBody,
  getActiveTableRow,
} from 'components/rich-text-editor/editor-plugins/table-utils';
import addTableRow from 'components/rich-text-editor/editor-plugins/table-utils/add-table-row';
import { isEditorActiveAt } from 'components/rich-text-editor/editor-plugins/plugin-utils';

// eslint-disable-next-line import/prefer-default-export
export const tabKeyInTable = (editor: any, cell: any, mark: string) => {
  if (!cell) {
    return;
  }

  const [activeTableCell, activeTableCellPath] = cell;
  const activeTableRow = getActiveTableRow(editor);
  const blockMatrix = createBlockMatrix(editor);
  const columnIndex = getColumnIndex(blockMatrix, activeTableRow, activeTableCell);
  const activeTableBody = getActiveTableBody(editor);
  const activeRowIndex = activeTableBody?.children.indexOf(activeTableRow);
  const activeRow = blockMatrix[activeRowIndex];
  const activeCell = activeRow[columnIndex];

  const lastRow = blockMatrix.length - 1;
  const lastColumn = activeRow.length - 1;

  if (mark === 'indent') {
    const newPath = [...activeTableCellPath];
    if (activeCell.col === lastColumn && activeCell.row === lastRow) {
      newPath[newPath.length - 1] = 0;
      addTableRow(editor, 'below');
    }
    if (activeCell.col === lastColumn) {
      newPath[newPath.length - 1] = 0;
      newPath[newPath.length - 2] += 1;
    } else {
      newPath[newPath.length - 1] += 1;
    }

    if (isEditorActiveAt(editor, newPath)) {
      Transforms.select(editor, newPath);
    }
  }

  if (mark === 'outdent') {
    const newPath = [...activeTableCellPath];

    if (activeCell.col === 0 && activeCell.row === 0) {
      return;
    }
    if (activeCell.col === 0) {
      newPath[newPath.length - 1] = lastColumn;
      newPath[newPath.length - 2] -= 1;
    } else {
      newPath[newPath.length - 1] -= 1;
    }

    if (isEditorActiveAt(editor, newPath)) {
      Transforms.select(editor, newPath);
    }
  }
};
