import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import accountsReducer from 'reducers/entities/accounts';
import { getAccountFromSessionSelector } from 'reducers/session';

import DontUseEnough from 'components/modals/cancellation/flows/dont-use-enough';
import LostFeaturesModal from 'components/modals/cancellation/lost-features';
import CancellationReasonsModal from 'components/modals/cancellation/cancellation-reasons';
import ConfirmCancel from 'components/modals/cancellation/flows/confirm-cancel';
import LetUsHelp from 'components/modals/cancellation/flows/let-us-help';
import MissingFeatures from 'components/modals/cancellation/flows/missing-features';

import type { FlowData } from 'types/cancellation-flow';
import OfferDiscount from 'components/modals/cancellation/flows/offer-discount';
import FoundAlternative from 'components/modals/cancellation/flows/found-alternative';
import GetFreePlan from 'components/modals/cancellation/flows/get-free-plan';
import OtherAlternative from 'components/modals/cancellation/flows/other-alternative';

type Props = {
  onClose: () => void;
}

const FlowManager = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountFromSessionSelector);
  const [selectedCancellationReason, setSelectedCancellationReason] = useState<string>('');
  const [currentFlow, setCurrentFlow] = useState<string>('');
  const [flowData, setFlowData] = useState<FlowData | null>(null);

  const onSyncFlowDataHandler = useCallback((data: FlowData) => {
    const newData = {
      ...flowData,
      ...data,
    };

    setFlowData(newData);
  }, [flowData]);

  const onSubmitHandler = useCallback((
    data: FlowData,
    submitDone: () => void,
    submitError: () => void,
  ) => {
    const allData = {
      reason: selectedCancellationReason,
      details: {
        ...flowData,
        ...data,
      },
    };

    const cancellationData = {
      id: account.id,
      data: allData,
      pipe: {
        onSuccess: submitDone,
        onFailure: submitError,
      },
    };

    dispatch(accountsReducer.createCancellation(cancellationData));
  }, [flowData, selectedCancellationReason, account.id, dispatch]);

  const onReasonChanged = useCallback((reference: string) => {
    setSelectedCancellationReason(reference);
    setCurrentFlow(reference);
  }, []);

  const handleBackFromConfirm = () => {
    if (selectedCancellationReason === 'too_expensive') {
      setCurrentFlow('too_expensive');
    } else if (selectedCancellationReason === 'found_an_alternative') {
      setCurrentFlow('other_alternative');
    } else {
      setCurrentFlow('let_us_help');
    }
  };

  const handleBackFromLetUsHelp = () => {
    if (selectedCancellationReason === 'dont_use_enough') {
      setCurrentFlow('dont_use_enough');
    } else if ((selectedCancellationReason === 'missing_features_or_integrations')) {
      setCurrentFlow('missing_features_or_integrations');
    } else {
      setCurrentFlow('reasons');
    }
  };

  switch (currentFlow) {
    case 'dont_use_enough':
      return (
        <DontUseEnough
          onClose={onClose}
          onPreviousStep={() => setCurrentFlow('reasons')}
          setCurrentFlow={setCurrentFlow}
          onSyncFlowData={onSyncFlowDataHandler}
        />
      );
    case 'reasons':
      return (
        <CancellationReasonsModal
          modalKey="reasons"
          onPreviousStep={() => setCurrentFlow('')}
          onClose={onClose}
          onChange={onReasonChanged}
        />
      );
    case 'confirm_cancel':
      return (
        <ConfirmCancel
          onPreviousStep={handleBackFromConfirm}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
          variant={
            selectedCancellationReason === 'missing_features_or_integrations'
              ? 'simple' : 'feedback'
          }
        />
      );
    case 'confirm_cancel_decline_free_plan':
      return (
        <ConfirmCancel
          onPreviousStep={() => setCurrentFlow('get_free_plan')}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
          variant="feedback"
        />
      );
    case 'difficult_to_use':
      return (
        <LetUsHelp
          onPreviousStep={() => setCurrentFlow('reasons')}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
        />
      );
    case 'missing_features_or_integrations':
      return (
        <MissingFeatures
          onPreviousStep={() => setCurrentFlow('reasons')}
          onClose={onClose}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
          onSubmit={onSubmitHandler}
        />
      );
    case 'found_an_alternative':
      return (
        <FoundAlternative
          onPreviousStep={() => setCurrentFlow('reasons')}
          onClose={onClose}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
        />
      );
    case 'let_us_help':
      return (
        <LetUsHelp
          onPreviousStep={handleBackFromLetUsHelp}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
        />
      );
    case 'business_is_closing':
      return (
        <ConfirmCancel
          onPreviousStep={() => setCurrentFlow('reasons')}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
          variant="feedbackNotSad"
        />
      );
    case 'other':
      return (
        <ConfirmCancel
          onPreviousStep={() => setCurrentFlow('reasons')}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
          variant="feedback"
        />
      );
    case 'too_expensive':
      return (
        <OfferDiscount
          onPreviousStep={() => setCurrentFlow('reasons')}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
        />
      );
    case 'get_free_plan':
      return (
        <GetFreePlan
          onPreviousStep={() => setCurrentFlow('found_an_alternative')}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
        />
      );
    case 'other_alternative':
      return (
        <OtherAlternative
          onPreviousStep={() => setCurrentFlow('found_an_alternative')}
          onClose={onClose}
          onSyncFlowData={onSyncFlowDataHandler}
          setCurrentFlow={setCurrentFlow}
        />
      );
    case '':
      return (
        <LostFeaturesModal
          modalKey="lost"
          onClose={onClose}
          onStepComplete={() => setCurrentFlow('reasons')}
        />
      );
    default:
      return null;
  }
};

export default FlowManager;
