import { useState } from 'react';
import Message from 'components/message';
import Button from 'components/button';
import CancelSubscriptionFlow from 'components/cancel-subscription/flows/cancel-subscription-flow';

import type { FlowData } from 'types/cancellation-flow';

import DocumentFrequency from 'components/modals/cancellation/document-frequency';
import YourAlternative from 'components/modals/your-alternative';

type Props = {
  onClose: () => void;
  onPreviousStep: () => void;
  setCurrentFlow: (flow: string) => void;
  onSyncFlowData: (data: FlowData) => void;
};

const FoundAlternative = ({
  onClose,
  onPreviousStep,
  setCurrentFlow,
  onSyncFlowData,
}: Props) => {
  const [selectedAlternative, setSelectedAlternative] = useState<string | undefined>();

  const alternativeSelected = (reference: string) => {
    setSelectedAlternative(reference);
    onSyncFlowData({ going_next: reference, switch_to_free_plan: false });
    if (reference === 'pdf_or_non_online_solution') {
      setCurrentFlow('get_free_plan');
    }

    if (reference === 'another_digital_solution') {
      setCurrentFlow('other_alternative');
    }
  };

  return (
    <CancelSubscriptionFlow
      onCancel={onClose}
      setFlow={setCurrentFlow}
    >
      <CancelSubscriptionFlow.Steps>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="Your alternative?"
              comment="Header in the found alternative modal"
            />
          )}
          handlePreviousStep={onPreviousStep}
          // allowContinue={Boolean(howOften)}
        >
          <CancelSubscriptionFlow.StepBody>
            <YourAlternative onChange={alternativeSelected} />
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            <Button
              kind="linkSeparate"
              onClick={onClose}
            >
              <Message
                id="Keep my Subscription Plan"
                comment="Button text for keeping the subscription plan"
              />
            </Button>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
      </CancelSubscriptionFlow.Steps>
    </CancelSubscriptionFlow>
  );
};

export default FoundAlternative;
