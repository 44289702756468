import {
  find,
  uniqueId,
} from 'lodash';

import type { ProductTableBox, PriceColumn, Product } from 'data-validators/entity-schemas/document-box/product-table';

import ProductTablePopup from 'components/contract-boxes/product-table-box/product-table/product-table-popup';
import UpdateProductItemPopup from 'components/contract-boxes/product-table-box/product-table/product-table-popup/update-product-item-popup';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import type { Props as ProductRowProps } from 'components/contract-boxes/product-table-box/product-table/product-table-expanded/product-row/product-row';
import NumberTerms from './number-terms/number-terms';

import style from './product-table-responsive.module.scss';
import DescriptionEditable from '../../description-editable';

type Props = ProductRowProps & {
  columns: PriceColumn[]
  data: ProductTableBox['content']['data'],
  updateProductConfig: ProductTableBox['config'],
  isAllowedToEditBoxData: boolean,
  getIsAllowedToUpdateDataValue: (row: Product) => boolean,
}

const ProductTableResponsive = ({
  agreementId,
  chosenProduct,
  columns,
  config,
  data,
  getIsAllowedToUpdateDataValue,
  handleDescriptionChange,
  isAllowedToEditBoxData,
  isGuestView,
  onClearChosenProduct,
  removeProduct,
  updateChosenProduct,
  updateProductConfig,
  updateProductData,
}: Props) => {
  const enabledColumns = columns.filter((column) => column.enabled);
  const isDescriptionEnabled = enabledColumns.find((column) => column.key === 'description');
  const productNameConfig = enabledColumns.find((column) => column.key === 'name');
  const isProductNameEnabled = Boolean(productNameConfig);
  const tableRowClassNames = isGuestView ? style.TableRowGuestView : style.TableRow;

  const renderProductHeader = () => {
    if (!isProductNameEnabled) {
      return (
        <div role="columnheader" aria-label="Empty product table header" className={style.ColumnDisabled} data-testid="empty-header" />
      );
    }

    const nameColumn = find(enabledColumns, { key: 'name' });
    const label = nameColumn?.label;

    return (
      <div className={style.ProductLabel}>
        <ProductTablePopup
          column={nameColumn}
          config={config}
          data={data}
          disabled={!isAllowedToEditBoxData}
          isResponsiveView
          updateProductConfig={updateProductConfig}
          updateProductData={updateProductData}
        >
          <h3>{label}</h3>
        </ProductTablePopup>
      </div>
    );
  };

  const renderProductName = (row: Product) => {
    const isAllowedToUpdateDataValue = getIsAllowedToUpdateDataValue(row);
    const productId = getId(row);

    if (isProductNameEnabled) {
      return (
        <UpdateProductItemPopup
          dataPopupId={uniqueId()}
          onClearChosenProduct={onClearChosenProduct}
          productId={productId}
          readOnly={!isAllowedToUpdateDataValue}
          removeProduct={removeProduct}
          updateProductData={updateProductData}
          value={row.value}
        >
          <h4 className={style.NameRow}>
            <span>{row.value?.name}</span>
          </h4>
        </UpdateProductItemPopup>
      );
    }

    return null;
  };

  const renderDescriptionEditable = (row: Product) => {
    if (!isDescriptionEnabled) {
      return null;
    }

    const isDescriptionEmpty = !(row.value?.description || row.value?.description_nodes);

    if (isGuestView && isDescriptionEmpty) {
      return null;
    }

    const isAllowedToUpdateDataValue = getIsAllowedToUpdateDataValue(row);

    return (
      <div role="cell" className={style.TableCell} data-testid="description-editor">
        <DescriptionEditable
          contractId={agreementId}
          data={row}
          hasOutline
          isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
          isResponsiveView
          onChange={(nodes) => {
            handleDescriptionChange(getId(row), nodes);
          }}
        />
      </div>
    );
  };

  const renderNumberTerms = (row: Product) => {
    if (!enabledColumns.length) {
      return null;
    }

    const isAllowedToUpdateDataValue = getIsAllowedToUpdateDataValue(row);

    return (
      <NumberTerms
        chosenProduct={chosenProduct}
        columns={columns}
        config={config}
        isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
        isGuestView={isGuestView}
        isResponsiveView
        onClearChosenProduct={onClearChosenProduct}
        removeProduct={removeProduct}
        row={row}
        updateChosenProduct={updateChosenProduct}
        updateProductConfig={updateProductConfig}
        updateProductData={updateProductData}
      />
    );
  };

  return (
    <div role="table" className={style.ProductTableContainer} data-testid="product-container">
      {renderProductHeader()}
      {data.map((row) => (
        <div
          className={tableRowClassNames}
          data-testid="product-row"
          key={getId(row)}
          role="rowgroup"
        >
          {renderProductName(row)}
          {renderDescriptionEditable(row)}
          {renderNumberTerms(row)}
        </div>
      ))}
    </div>
  );
};

export default ProductTableResponsive;
