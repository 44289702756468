// @flow

import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';
import {
  Editor,
  Transforms,
  Text,
} from 'slate';

import DataFieldIcon from 'components/icons/data-field';
import useCurrentActiveMarks from 'hooks/rich-text-editor/use-current-active-marks';
import { useContractProps } from 'contexts/contract-props';
import { ContractDataFieldsModal } from 'components/modals/contract-data-fields';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import ToolbarButton from '../toolbar-button';

type Props = {
  isMenuItem: boolean,
  disabled: boolean,
};

const getCurrentMarks = (editor, currentCursorLocation) => {
  if (isEmpty(currentCursorLocation)) {
    return {};
  }

  const [textNode] = Editor.node(editor, currentCursorLocation);

  if (!Text.isText(textNode)) {
    return {};
  }

  return {
    ...omit(textNode, 'text'),
  };
};

const insertDataField = (
  editor,
  dataFieldObject,
  currentCursorLocation,
  currentActiveMarks,
) => {
  let marks = currentActiveMarks;

  if (!marks) {
    marks = getCurrentMarks(editor, currentCursorLocation);
  }

  Transforms.insertNodes(editor, [{
    type: 'data-field',
    dataFieldKey: dataFieldObject.key,
    children: [{ text: '' }],
    marks,
  }, {
    ...marks,
    text: '',
  }], {
    at: currentCursorLocation,
    select: true,
  });
};

const DataFieldsButton = ({ isMenuItem, disabled }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();
  const currentActiveMarks = useCurrentActiveMarks(editor);
  const { agreement } = useContractProps();

  const onAddDataField = (selectedDataField) => {
    focusEditor();
    insertDataField(editor, selectedDataField, editor.selection, currentActiveMarks);
  };

  const hasDataFields = !isEmpty(agreement.data);

  return (
    <>
      <ContractDataFieldsModal
        agreement={agreement}
        onAddDataField={onAddDataField}
      >
        {(onMouseDown) => (
          <ToolbarButton
            label={(
              <Message
                id="Insert data-field"
                comment="The label of the editor's toolbar plugin that inserts a data-field."
              />
              )}
            isMenuItem={isMenuItem}
            onTrigger={onMouseDown}
            icon={<DataFieldIcon />}
            disabled={!hasDataFields || disabled}
          />
        )}
      </ContractDataFieldsModal>
    </>
  );
};

export default DataFieldsButton;
