// @flow

import React, { useEffect, useState } from 'react';
import { Editor, Text, Transforms } from 'slate';
import { Message } from '@oneflowab/pomes';
import { useSlate, ReactEditor } from 'slate-react';
import get from 'lodash/get';

import { Popover, PopoverContent, PopoverPortal } from 'components/popover';
import TextColorIcon from 'components/icons/text-color';
import TextBackgroundColorIcon from 'components/icons/text-background-color';
import TableCellBackgroundColorIcon from 'components/icons/table-cell-background-color';
import ColorPicker from 'components/color-picker';

import { getActiveImage } from 'components/rich-text-editor/editor-plugins/image-utils';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import { useContractProps } from 'contexts/contract-props';

import { hexToRgb, rgbaToHex } from 'utils/color-type-conversion';
import ToolbarButton from '../toolbar-button';
import toolbarButtonStyle from '../toolbar-button.module.scss';
import style from './apply-color-button.module.scss';

type Props = {
  applyTo: 'text' | 'textBackground' | 'tableCellBackground',
  isMenuItem: boolean
};

const isTableCell = (node: any) => (
  node && node.type === 'table-cell'
);

const hasActiveColor = (node: any, styleToApply: string) => {
  if (node?.marks?.[styleToApply]) {
    return true;
  }

  return (Text.isText(node) || isTableCell(node)) && node[styleToApply];
};

export const hasMultipleColors = (editor: any, styleToApply: string) => {
  const matchedNodes = Editor.nodes(editor, {
    match: (node) => (
      !Editor.isEditor(node) && hasActiveColor(node, styleToApply)
    ),
  });

  const firstNode = matchedNodes.next();

  // Either there is only one node with color or there is no matched nodes
  if (firstNode.done) {
    return false;
  }

  const secondNode = matchedNodes.next();

  // There is another color node
  if (secondNode.value && !secondNode.done) {
    return true;
  }

  return false;
};

function getPathColor(editor: any, selectedColor: string, styleToApply: string) {
  if (hasMultipleColors(editor, styleToApply)) {
    return 'none';
  }

  return selectedColor;
}

export const getActiveColorNode = (
  editor: any,
  applyTo: string,
  styleToApply: string,
) => {
  const isActiveColorNode = (node) => (
    !Editor.isEditor(node) && hasActiveColor(node, styleToApply)
  );
  let matchActiveNode = isActiveColorNode;
  if (applyTo === 'tableCellBackground') {
    matchActiveNode = (node) => (
      !Editor.isEditor(node) && node.type === 'table-cell'
    );
  }
  const [match] = Editor.nodes(editor, {
    match: matchActiveNode,
    mode: 'lowest',
  });

  if (!match) {
    return null;
  }

  if (applyTo === 'tableCellBackground') {
    return Editor.nodes(editor, {
      match: matchActiveNode,
      mode: 'lowest',
    });
  }
  return match[0];
};

const getActiveColor = (editor: any, activeNode, styleToApply: string, applyTo: string) => {
  if (isTableCell(activeNode) && applyTo === 'tableCellBackground') {
    return get(activeNode, styleToApply);
  }

  if (editor?.marks?.[styleToApply]) {
    return editor.marks[styleToApply];
  }

  if (activeNode?.marks?.[styleToApply]) {
    return activeNode?.marks?.[styleToApply];
  }

  return get(activeNode, styleToApply);
};

const getLabel = (applyTo: string) => {
  if (applyTo === 'text') {
    return (
      <Message
        id="Text color"
        comment="The label of the editor's toolbar plugin that applies color to selected text."
      />
    );
  }
  if (applyTo === 'textBackground') {
    return (
      <Message
        id="Highlight color"
        comment="The label of the editor's toolbar plugin that applies background color to selected text."
      />
    );
  }
  return (
    <Message
      id="Cell background color"
      comment="The label of the editor's table toolbar plugin that applies background color to selected cell."
    />
  );
};

const ApplyColorButton = ({ applyTo, isMenuItem }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();
  const { agreement } = useContractProps();
  const [isOpen, setIsOpen] = useState(false);

  const agreementFormattingTextColor = agreement.config?.formatting?.color;
  let styleToApply = 'color';
  let INITIAL_COLOR = agreementFormattingTextColor || '#000000';
  if (applyTo === 'textBackground' || applyTo === 'tableCellBackground') {
    styleToApply = 'backgroundColor';
    INITIAL_COLOR = '#ffffff';
  }

  const activeNode = getActiveColorNode(editor, applyTo, styleToApply);

  const activeImage = getActiveImage(editor);
  const isDisabled = Boolean(activeImage);

  let activeColor = getActiveColor(editor, activeNode, styleToApply, applyTo);

  if (applyTo === 'text' && editor.selection) {
    activeColor = getActiveColor(editor, activeNode, styleToApply, applyTo) || INITIAL_COLOR;
  }

  const [textColor, setTextColor] = React.useState(activeColor);
  const [backgroundColor, setBackgroundColor] = React.useState();

  const [isSubtitleNode: match] = Editor.nodes(editor, {
    match: (node) => node.type === 'subtitle',
    mode: 'lowest',
  });

  useEffect(() => {
    if (applyTo !== 'text') return;

    const isInitialColor = agreementFormattingTextColor === activeColor;

    // When we are on the subtitle node. Then apply new 70% opacity.
    // (Color translates to hex since color picker only takes hex and not rgba nor #RRGGBBAA)
    if (isInitialColor && isSubtitleNode) {
      // In here activeColor is never "relevant" to look at
      // We only want to apply seventy percent to the subtitle when it is initial color.
      // Thus activeColor can never be seventyPercentOfInitialColor
      const { r = 0, g = 0, b = 0 } = hexToRgb(INITIAL_COLOR);
      const seventyPercentOfInitialColor = rgbaToHex(`rgba(${r}, ${g}, ${b}, 0.7)`);

      const isCurrentlyHexColor = textColor === seventyPercentOfInitialColor;

      if (!isCurrentlyHexColor) {
        setTextColor(seventyPercentOfInitialColor);
      }
    } else {
      setTextColor(activeColor);
    }
  }, [
    textColor,
    activeColor,
    isSubtitleNode,
    applyTo,
    agreementFormattingTextColor,
    INITIAL_COLOR,
  ]);

  const applyColor = React.useCallback((color) => {
    focusEditor();

    if (applyTo === 'tableCellBackground') {
      // eslint-disable-next-line no-restricted-syntax
      for (const [node] of activeNode) {
        Transforms.setNodes(editor, {
          backgroundColor: color,
        }, {
          at: ReactEditor.findPath(editor, node),
        });
      }
      setIsOpen(false);
      return;
    }

    Editor.addMark(editor, styleToApply, color);
  }, [activeNode, applyTo, editor, focusEditor, styleToApply]);

  useEffect(() => {
    if (applyTo !== 'textBackground') return;

    setBackgroundColor(activeColor);
  }, [activeColor, applyTo]);

  const removeColor = React.useCallback(() => {
    focusEditor();

    if (applyTo === 'tableCellBackground') {
      // eslint-disable-next-line no-restricted-syntax
      for (const [node] of activeNode) {
        Transforms.unsetNodes(editor, 'backgroundColor', {
          at: ReactEditor.findPath(editor, node),
        });
      }
      setIsOpen(false);
      return;
    }

    Editor.removeMark(editor, styleToApply);
  }, [activeNode, applyTo, editor, focusEditor, styleToApply]);

  const getIcon = React.useCallback(() => {
    if (applyTo === 'text') {
      return <TextColorIcon pathColor={getPathColor(editor, textColor, styleToApply)} />;
    }

    if (applyTo === 'textBackground') {
      return (
        <TextBackgroundColorIcon
          pathColor={getPathColor(editor, backgroundColor, styleToApply)}
        />
      );
    }

    return (
      <TableCellBackgroundColorIcon
        pathColor={getPathColor(editor, backgroundColor, styleToApply)}
      />
    );
  }, [textColor, applyTo, editor, styleToApply, backgroundColor]);

  const pickerColor = applyTo === 'text' ? textColor : backgroundColor;

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <ToolbarButton
        isMenuItem={isMenuItem}
        customClass={toolbarButtonStyle.ToolbarButton}
        customPopoverTriggerClass={style.PopoverTrigger}
        icon={getIcon()}
        asPopoverTrigger
        disabled={isDisabled}
        label={getLabel(applyTo)}
      />
      <PopoverPortal>
        {/* sideOffset is 12 since the height of the toolbar 48px where
      36px is occupied by padding-top(12px) plus plugin button height (24px) */}
        <div className={style.PopoverContentContainer}>
          <PopoverContent
            sideOffset={12}
            className="rich-text-region"
          >
            <ColorPicker
              preSelectedColor={pickerColor}
              applyColor={applyColor}
              removeColor={removeColor}
            />
          </PopoverContent>
        </div>
      </PopoverPortal>
    </Popover>
  );
};

export default ApplyColorButton;
