import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';
import agreements from 'reducers/entities/agreements';
import { getPositionFromSessionSelector } from 'reducers/session';
import { areSettingsDisabled, shouldBeDisabled } from 'components/document-tabs/settings-tab/helpers';

import SelectField, { Props as SelectFieldProps } from 'components/document-tabs/settings-tab/components/select-field';
import Label from 'components/document-tabs/settings-tab/components/label';
import CustomizeOptions from 'components/document-tabs/settings-tab/sections/signing-and-security/customize-options';

import { getAvailableMFAIds, getDefaultMfaChannel } from './helpers';
import style from '../../signing-and-security.module.scss';

const allOptions = [
  { value: 'none', label: <Message id="Off" comment="MFA method" /> },
  { value: 'email', label: <Message id="Email" comment="MFA method" /> },
  { value: 'sms', label: <Message id="SMS" comment="MFA method" /> },
  { value: 'personal_identification', label: <Message id="Personal Identification" comment="MFA method" /> },
];

type Option = typeof allOptions[number];

type Props = {
  agreementId: number;
};

const MFA = ({ agreementId }: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const { resetRPCStates } = useAPIError(agreementId);
  const availableMFAIds = useMemo(() => getAvailableMFAIds(agreement), [agreement]);
  const myPosition = useSelector(getPositionFromSessionSelector);
  const settingsDisabled = areSettingsDisabled(agreement, myPosition);

  const onChange: SelectFieldProps<Option['value']>['onChange'] = (value, options) => {
    resetRPCStates();
    dispatch(agreements.updateConfig({
      id: agreementId,
      data: { defaultMfaChannel: value },
      pipe: {
        onFailure: options?.onFailure,
      },
    }));
  };

  const options = allOptions.filter((option) => availableMFAIds.includes(option.value));

  const label = (
    <Label
      hint={(
        <Message
          id="Choose the default authentication method for your counterparties to access the document securely."
          comment="Tooltip for authentication method dropdown"
        />
      )}
      customClassName={settingsDisabled ? style.Disabled : undefined}
    >
      <Message
        id="Two-factor authentication"
        comment="Label for authentication method dropdown"
      />
    </Label>
  );

  return (
    <>
      <SelectField
        disabled={shouldBeDisabled(agreement) || settingsDisabled}
        value={getDefaultMfaChannel(agreement)}
        label={label}
        options={options}
        name="default-MFA-method"
        onChange={onChange}
      />
      <CustomizeOptions agreementId={agreementId} type="mfaChannels" />
    </>
  );
};

export default MFA;
