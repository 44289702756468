import {
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash/debounce';

import type { RefObject } from 'react';

type PdfIntersectionObserver = {
  isVisible: boolean,
  containerRef: RefObject<HTMLDivElement>,
};

const usePdfIntersectionObserver = (): PdfIntersectionObserver => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const { current } = containerRef;
    const debouncedSetIsVisible = debounce(setIsVisible, 200);

    const observer = new IntersectionObserver(
      ([entry]) => {
        debouncedSetIsVisible(entry.isIntersecting);
      },
      {
        rootMargin: '200px',
      },
    );
    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  return {
    containerRef,
    isVisible,
  };
};

export default usePdfIntersectionObserver;
