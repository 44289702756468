// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import Field from 'components/field';
import SelectField from 'components/select-field';

export type AvailablePosition = {
  id: number,
  fullname: string,
}

export type FormData = {
  id: number,
  positionIds: Array<number>,
}

type QueryAvailable = {
  fullname: string,
  pagination: Pagination
}

export type Props = {
  availablePositions: Array<AvailablePosition>,
  queryAvailablePositions: (args: ?QueryAvailable) => void,
  queryAvailablePositionsLoadMore: ({additionalResults: number}) => void,
  availablePositionsQuery: Query,
  message: MessageTranslator,
  onSubmit: FormData => void,
  formState: RpcState,
  resetFormState: () => void,
  children: React.Node,
}

export class AddMemberComponent extends React.Component<Props> {
  handleInputChange = (fullname: string) => {
    const { queryAvailablePositions, availablePositionsQuery } = this.props;

    queryAvailablePositions({
      fullname,
      pagination: availablePositionsQuery.pagination,
    });
  }

  handleOpen = () => {
    const { queryAvailablePositions } = this.props;

    queryAvailablePositions({});
  }

  handleLoadMoreItems = (additionalResults: number) => {
    const { queryAvailablePositionsLoadMore } = this.props;

    queryAvailablePositionsLoadMore({
      additionalResults,
    });
  }

  renderBody() {
    const {
      message,
      availablePositionsQuery,
      availablePositions,
    } = this.props;

    return (
      <Field
        name="positions"
        label={message({ id: 'Members', comment: 'A label for the relevant field' })}
        placeholder={message({ id: 'Select users', comment: 'A placeholder for the relevant field' })}
        component={SelectField}
        multi
        backspaceRemoves
        valueKey="id"
        labelKey="fullname"
        onInputChange={this.handleInputChange}
        closeOnSelect={false}
        options={availablePositions}
        isLoading={availablePositionsQuery.loading}
        loadMoreItems={this.handleLoadMoreItems}
        required
      />
    );
  }

  render() {
    const {
      onSubmit,
      formState,
      resetFormState,
      children,
    } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Add members"
            comment="Modal title for adding members"
          />
        )}
        body={this.renderBody()}
        onSubmit={onSubmit}
        formState={formState}
        resetFormState={resetFormState}
        onOpen={this.handleOpen}
        modalKey="add user to group modal"
      >
        {children}
      </ModalForm>
    );
  }
}

export default localize<Props>(AddMemberComponent);
