import type { ReactNode } from 'react';

import SimpleInputProvider from './simple-input-provider';
import DataFieldInputProvider from './data-field-input-provider';

type Props = {
  fieldId: string;
  value: string;
  valueDataFieldKey: string;
  children: ReactNode;
};

const FieldInputProvider = ({
  fieldId,
  value,
  valueDataFieldKey,
  children,
}: Props) => {
  if (valueDataFieldKey) {
    return (
      <DataFieldInputProvider
        key={valueDataFieldKey}
        valueDataFieldKey={valueDataFieldKey}
      >
        {children}
      </DataFieldInputProvider>
    );
  }

  return (
    <SimpleInputProvider
      key={fieldId}
      fieldId={fieldId}
      value={value}
    >
      {children}
    </SimpleInputProvider>
  );
};

export default FieldInputProvider;
