// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { runExport } from 'oneflow-client/positions';

import UserGroups from 'components/icons/user-groups';
import ExportButton from 'components/export-button';

export type Props = {|
  email: string,
  positionsQuery: Query,
|};

class ExportUsers extends React.PureComponent<Props> {
  onExport = (onSuccess: Function) => {
    const { positionsQuery } = this.props;

    runExport(positionsQuery).then(onSuccess);
  };

  render() {
    const { email, positionsQuery } = this.props;

    return (
      <ExportButton
        header={(
          <Message
            id="Export users"
            comment="Confirm modal title for exporting users."
          />
        )}
        emptyStateHeader={(
          <Message
            id="No users to export"
            comment="Empty state header. Shown in users export modal when having no users."
          />
        )}
        icon={<UserGroups height="33px" />}
        email={email}
        query={positionsQuery}
        onExport={this.onExport}
      />
    );
  }
}

export default ExportUsers;
