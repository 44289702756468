// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

import { FadeIn } from 'components/transitions';
import { LogoOneflow } from 'components/logo';

import style from './sidebar-logo-expanded.module.scss';

type Props = {
  isContractView: boolean,
  mobileMenuOpen: boolean,
  toggleMobileMenu: () => void,
  toggleExpandedState: () => void,
};

const SidebarLogoExpanded = ({
  isContractView,
  mobileMenuOpen,
  toggleMobileMenu,
  toggleExpandedState,
}: Props) => {
  const handleOnKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    toggleExpandedState();
  };

  if (isContractView) {
    return (
      <a
        href="/"
        onClick={mobileMenuOpen ? toggleMobileMenu : null}
        className={style.Link}
        aria-label="Oneflow"
        onKeyDown={handleOnKeyDown}
      >
        <FadeIn in timeout={0} transitionTime={500}>
          <LogoOneflow width="106px" height="23.32px" className={style.LogoOneflow} />
        </FadeIn>
      </a>
    );
  }

  return (
    <Link
      to="/"
      onClick={mobileMenuOpen ? toggleMobileMenu : null}
      className={style.Link}
      aria-label="Oneflow"
      onKeyDown={handleOnKeyDown}
    >
      <FadeIn in timeout={0} transitionTime={500}>
        <LogoOneflow width="106px" height="23.32px" className={style.LogoOneflow} />
      </FadeIn>
    </Link>
  );
};

export default SidebarLogoExpanded;
