// @flow

import * as React from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { useSlate } from 'slate-react';

import Tooltip from 'components/tooltip';
import DropdownMenu from 'components/dropdown-menu';
import ChevronUpIcon from 'components/icons/chevron-up';
import ChevronDownIcon from 'components/icons/chevron-down';

import { getActiveImage } from 'components/rich-text-editor/editor-plugins/image-utils';
import NormalTextStyleMenuItem from './normal-text-style-menu-item';
import TextStyleMenuItem from './text-style-menu-item';
import { getActiveStyledTextNode } from './text-styles-plugin';
import useTextStyles from './use-text-styles';
import style from './text-styles.module.scss';

const getTextStyleOptions = (editor: any, activeType: string, textStyles: any) => (
  Object.keys(textStyles).map((type) => {
    const textStyle = textStyles[type];

    return (
      <TextStyleMenuItem
        editor={editor}
        type={type}
        label={textStyle.render(textStyle.name)}
        isActive={type === activeType}
      />
    );
  })
);

const getActiveTextStyle = (textStyles, activeType, isSmall) => {
  if (isSmall) {
    return 'Aa';
  }

  if (textStyles[activeType]) {
    return textStyles[activeType].name;
  }

  return (
    <Message
      id="Normal text"
      comment="The text for the menu item when changing the text style to normal text in the editor."
    />
  );
};

type Props = {
  isSmall: boolean,
  message: MessageTranslator,
};

const TextStyles = ({ message, isSmall }: Props) => {
  const textStyles = useTextStyles(message);
  const buttonMenuRef = React.useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const editor = useSlate();
  const activeNode = getActiveStyledTextNode(editor);
  const activeImage = getActiveImage(editor);
  const isDisabled = Boolean(activeImage);
  const activeType = get(activeNode, 'type');
  const className = clsx(style.TextStyles, {
    [style.Placeholder]: Boolean(isDisabled),
    [style.IsSmall]: isSmall,
  });

  const activeTextStyle = getActiveTextStyle(textStyles, activeType, isSmall);

  let dropdownIcon = <ChevronDownIcon width="10px" height="10px" />;
  if (isDropdownOpen) {
    dropdownIcon = <ChevronUpIcon width="10px" height="10px" />;
  }

  const onVisibilityChange = (visible) => {
    setIsDropdownOpen(visible);
  };

  const onMouseDown = (e) => {
    e.preventDefault();
  };

  return (
    <Tooltip
      triggerClassName={style.TextStylesTooltip}
      messageClassName={style.TooltipText}
      message={(
        <Message
          id="Text styles"
          comment="Tooltip text for the text styles dropdown"
        />
      )}
      side="bottom"
      delayShow={750}
      delayHide={0}
      hideContent={isDisabled}
    >
      <span>
        <DropdownMenu
          ref={buttonMenuRef}
          items={[
            <NormalTextStyleMenuItem
              editor={editor}
              activeType={activeType}
            />,
            ...getTextStyleOptions(editor, activeType, textStyles),
          ]}
          anchor={{
            x: 'inner-left',
            y: 'below',
          }}
          className={className}
          dropdownIcon={dropdownIcon}
          focusOnCloseDisabled
          onMouseDown={onMouseDown}
          onVisibilityChange={onVisibilityChange}
        >
          <span className={style.CurrentTextStyle}>
            {activeTextStyle}
          </span>
        </DropdownMenu>
      </span>
    </Tooltip>
  );
};

export default localize<Props>(TextStyles);
