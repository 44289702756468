import Message from 'components/message';
import * as Popover from '@radix-ui/react-popover';
import type { OverlayAccessTypes } from 'types/overlay';
import Toggle from 'components/toggle';
import Checkbox from 'components/checkbox';
import Tooltip from 'components/tooltip';

import { DownChevronIcon } from '../overlay-text-field/icons';
import style from './settings.module.scss';

type Props = {
  valueRequiredForSigning: boolean;
  toggleValueRequiredForSigning: (required: boolean) => void;
  handleAccessChange: (newPermmissions: {
    counterpartEdit?: OverlayAccessTypes,
    colleagueEdit?: OverlayAccessTypes,
  }) => void;
  counterpartyAccess: OverlayAccessTypes;
  colleagueAccess: OverlayAccessTypes;
  boxCounterpartEdit: boolean;
  boxColleagueEdit: boolean;
  managerLock: boolean;
}

const Settings = ({
  handleAccessChange,
  toggleValueRequiredForSigning,
  valueRequiredForSigning,
  counterpartyAccess,
  colleagueAccess,
  boxCounterpartEdit,
  boxColleagueEdit,
  managerLock,
}: Props) => {
  const isInherit = counterpartyAccess === 'inherit' && colleagueAccess === 'inherit';
  const isCounterPartyChecked = counterpartyAccess === 'inherit' ? boxCounterpartEdit : counterpartyAccess === 'allow';
  const isColleagueChecked = colleagueAccess === 'inherit' ? boxColleagueEdit : colleagueAccess === 'allow';

  const isColleagueEditInherited = (colleagueAccess === 'inherit' && counterpartyAccess !== 'inherit');
  const isCounterpartEditInherited = (colleagueAccess !== 'inherit' && counterpartyAccess === 'inherit');
  const hasAccessMismatch = isCounterpartEditInherited || isColleagueEditInherited;

  const handleCounterPartToggle = () => {
    const newCounterPartValue = isCounterPartyChecked ? 'deny' : 'allow';
    const newColleagueValue = boxCounterpartEdit ? 'allow' : 'deny';
    handleAccessChange({
      counterpartEdit: newCounterPartValue,
      colleagueEdit: colleagueAccess === 'inherit' ? newColleagueValue : colleagueAccess,
    });
  };

  const handleColleagueToggle = () => {
    const newColleagueValue = isColleagueChecked ? 'deny' : 'allow';
    const newCounterPartValue = boxColleagueEdit ? 'allow' : 'deny';
    handleAccessChange({
      colleagueEdit: newColleagueValue,
      counterpartEdit: counterpartyAccess === 'inherit' ? newCounterPartValue : counterpartyAccess,
    });
  };

  const handleCheckboxChange = () => {
    if (hasAccessMismatch) {
      handleAccessChange({ counterpartEdit: 'inherit', colleagueEdit: 'inherit' });
      return;
    }
    if (counterpartyAccess === 'inherit' || colleagueAccess === 'inherit') {
      handleAccessChange({
        counterpartEdit: boxCounterpartEdit ? 'allow' : 'deny',
        colleagueEdit: boxColleagueEdit ? 'allow' : 'deny',
      });
    } else {
      handleAccessChange({ counterpartEdit: 'inherit', colleagueEdit: 'inherit' });
    }
  };

  return (
    <Popover.Root>
      <Popover.Trigger className={style.SettingsTrigger}>
        <Message id="Field Options" comment="Button to open edit access menu dropdown" />
        <DownChevronIcon style={{ flexShrink: 0 }} width="10px" height="10px" />
      </Popover.Trigger>
      <Popover.Content
        side="bottom"
        align="start"
        sideOffset={8}
        className={style.Settings}
      >
        <div className={style.ToggleArea}>
          <Message
            id="Make this field required"
            comment="Label for the allow edit toggle switch in the edit access menu."
          />
          <div>
            <Toggle
              id="valueRequiredForSigningToggle"
              version="latest"
              checked={valueRequiredForSigning}
              onChange={toggleValueRequiredForSigning}
              isControlled
            />
          </div>
        </div>
        <div className={style.Divider} />
        <div className={style.ToggleArea}>
          <Message id="Counterparty can edit value" comment="Label for the allow edit toggle switch in the edit access menu." />
          <Tooltip
            messageClassName={style.ToggleTooltipMessage}
            hideContent={!isInherit}
            side="top"
            message={<Message id="Using section's settings. Uncheck above to enable editing." comment="Tooltip message explaining why the toggle switch to enable/disable access to overlay field is disabled." />}
          >
            <div>
              <Toggle
                id="counterPartEditValueToggle"
                version="latest"
                checked={isCounterPartyChecked}
                onChange={handleCounterPartToggle}
                isControlled
                disabled={isInherit}
              />
            </div>
          </Tooltip>
        </div>
        {managerLock && (
          <div className={style.ToggleArea}>
            <Message id="Colleagues can edit value" comment="Label for the allow edit toggle switch in the edit access menu." />
            <Tooltip
              messageClassName={style.ToggleTooltipMessage}
              hideContent={!isInherit}
              side="top"
              message={<Message id="Using section's settings. Uncheck above to enable editing." comment="Tooltip message explaining why the toggle switch to enable/disable access to overlay field is disabled." />}
            >
              <div>
                <Toggle
                  id="colleagueEditValueToggle"
                  checked={isColleagueChecked}
                  onChange={handleColleagueToggle}
                  isControlled
                  disabled={isInherit}
                />
              </div>
            </Tooltip>
          </div>
        )}
        <div className={style.SettingsCheckbox}>
          <Checkbox
            label={<Message id="Use section setting" comment="Label for the inherit section access settings in the edit access menu." />}
            input={{
              value: 'inherit',
              checked: isInherit,
              onChange: handleCheckboxChange,
            }}

          />
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default Settings;
