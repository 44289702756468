// @flow

import React from 'react';
import { Message, type MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';

import { Link } from 'react-router-dom';

import WorkspacePage from 'routes/admin/workspace';

import { Table } from 'components/table';
import Button from 'components/button';
import Add from 'components/icons/add';
import GrantUserAccessIcon from 'components/icons/grant-user-access';
import GrantGroupAccessIcon from 'components/icons/grant-group-access';
import Check from 'components/icons/check';
import Dash from 'components/icons/dash';
import Edit from 'components/icons/edit';
import AiSparkle from 'components/icons/ai-sparkle';
import ActionBar from 'components/action-bar';
import ActionsMenu from 'components/actions-menu';
import { MenuItem } from 'components/menu-item';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { EditMenuItem } from 'components/menu-items/edit';
import { checkAcl } from 'components/acl';
import Pagination from 'components/pagination';
import TableFiltering from 'components/table/table-filtering';
import WorkspaceType from 'components/workspace-type';
import Tooltip from 'components/tooltip';
import Country from 'components/countries';
import { getDaysToRetainText } from 'components/data-retention';
import AddWorkspace from 'components/modals/add-workspace';
import { DeleteWorkspace } from 'components/modals/delete-workspace';
import { DeleteWorkspaces } from 'components/modals/delete-workspaces';
import GrantUserAccess from 'components/modals/grant-user-access';
import GrantGroupAccess from 'components/modals/grant-group-access';
import { DeleteBulkAction } from 'components/bulk-action-items/delete';

import {
  WorkspaceContractsCount,
  WorkspaceTemplatesCount,
  WorkspaceUserAccess,
} from 'components/workspace-counts';

import style from './workspaces.module.scss';

export type Props = {
  language: EnabledLanguages,
  queryWorkspaces: Function,
  workspaces: Array<Workspace>,
  query: Query,
  message: MessageTranslator,
  editLinkPath: (positionId: number) => void,
  aiReviewLinkPath: (workspaceId: number) => void,
  account: Account,
};

type State = {
  selectedWorkspaces: Array<Workspace>,
};

export class WorkspacesComponent extends React.PureComponent<Props, State> {
  state = {
    selectedWorkspaces: [],
  }

  componentDidMount() {
    const { queryWorkspaces } = this.props;

    queryWorkspaces({});
  }

  getWorkspaceName = (workspace: Workspace) => (
    <div className={style.Workspace}>
      <Link to={`/admin/workspaces/${workspace.id}`}>
        {workspace.name}
      </Link>
      <div className={style.Description}>
        {workspace.description}
      </div>
    </div>
  );

  getAiReview = (workspace: Workspace) => {
    if (!workspace.isAiReviewEnabled) {
      return (
        <Message
          id="Not set up"
          comment="label for showing that AI Review feature is not enabled for this workspace"
        />
      );
    }

    return (
      <Message
        id="Active"
        comment="label for showing that AI Review feature is enabled for this workspace"
      />
    );
  };

  getBranding = (workspace: Workspace) => {
    const {
      message,
      language,
    } = this.props;
    const {
      brandingName = '',
      brandingCountry = '',
      brandingOrgnr = '',
      logo,
    } = workspace;

    const hasBrandingAccess = checkAcl(workspace.acl, 'collection:update:branding');

    if (!hasBrandingAccess) {
      return (
        <Tooltip
          side="top"
          zIndex="10003"
          message={this.featureNotAccessibleMessage()}
        >
          <Dash className={style.DisabledIcon} height="20px" />
        </Tooltip>
      );
    }

    if (!brandingCountry) {
      return (
        <Link
          to={`/admin/workspaces/${workspace.id}/branding`}
          className={style.Link}
          aria-label={message({ id: 'Edit', comment: 'Button for editing company branding' })}
        >
          <Dash className={style.DisabledIcon} height="20px" />
          <Edit className={style.DisabledEditIcon} height="20px" />
        </Link>
      );
    }

    const brandingNameLabel = message({
      id: 'Name',
      comment: 'Label for branding property in the workspace list.',
    });
    const brandingCountryLabel = message({
      id: 'Country',
      comment: 'Label for branding property in the workspace list.',
    });
    let registrationNumber;
    if (brandingOrgnr) {
      const registrationNumberLabel = message({
        id: 'Registration number',
        comment: 'Label for branding property in the workspace list.',
      });
      registrationNumber = <li>{`${registrationNumberLabel}: ${brandingOrgnr}`}</li>;
    }

    let enabledBrandingIndicator = brandingName;
    if (logo) {
      enabledBrandingIndicator = <img alt="logo" src={logo.url} className={style.BrandingLogo} />;
    }

    return (
      <Tooltip
        triggerClassName={style.Tooltip}
        header={(
          <div className={style.TooltipHeader}>
            {message({
              id: 'Workspace branding',
              comment: 'Tooltip showing whether workspace branding is enabled in the workspace list.',
            })}
          </div>
        )}
        message={(
          <ul className={style.TooltipList}>
            <li>
              {`${brandingNameLabel}: ${brandingName}`}
            </li>
            <li>
              {`${brandingCountryLabel}: `}
              <Country country={brandingCountry} lang={language} />
            </li>
            {registrationNumber}
          </ul>
        )}
        side="top"
      >
        <span>
          <Link
            to={`/admin/workspaces/${workspace.id}/branding`}
            className={style.Branding}
            aria-label={
              message({
                id: 'Workspace branding logo',
                comment: 'Button. Sending user to the workspace branding settings',
              })
            }
          >
            {enabledBrandingIndicator}
          </Link>
        </span>
      </Tooltip>
    );
  };

  getWorkspaceType = (workspace: Workspace) => (
    <WorkspaceType type={workspace.type} />
  );

  featureNotAccessibleMessage = () => {
    const { message, account } = this.props;

    return (
      message({
        id: 'This feature is not available for the {account} plan. Please upgrade your plan to enable it.',
        comment: "Tooltip text for explaining why the user don't have the feature access.",
        values: { account: account.plan?.name },
      })
    );
  }

  getDataRetention = (workspace: Workspace) => {
    const { message } = this.props;
    const { dataRetentionPolicy = {} } = workspace;
    const {
      agreementDraft,
      agreementDeclined,
      agreementExpired,
      agreementTerminated,
    } = dataRetentionPolicy;

    const hasNoDataRetentionRules = !agreementDraft
      && !agreementDeclined
      && !agreementExpired
      && !agreementTerminated;

    const hasDataRetentionAccess = checkAcl(workspace.acl, 'collection:data_retention_policy:update');

    if (!hasDataRetentionAccess) {
      return (
        <Tooltip
          side="top"
          zIndex="10003"
          message={this.featureNotAccessibleMessage()}
        >
          <Dash className={style.DisabledIcon} height="20px" />
        </Tooltip>
      );
    }

    if (hasNoDataRetentionRules) {
      return (
        <Link
          to={`/admin/workspaces/${workspace.id}/data-retention`}
          className={style.Link}
          aria-label={message({ id: 'Edit', comment: 'Button for editing company branding' })}
        >
          <Dash className={style.DisabledIcon} height="20px" />
          <Edit className={style.DisabledEditIcon} height="20px" />
        </Link>
      );
    }

    const getRetentionDays = getDaysToRetainText({ message });
    const draftDaysLabel = message({
      id: 'Drafts',
      comment: 'Label for data retention property in the workspace list.',
    });
    const overdueDaysLabel = message({
      id: 'Overdue contracts',
      comment: 'Label for data retention property in the workspace list.',
    });
    const declinedDaysLabel = message({
      id: 'Declined contracts',
      comment: 'Label for data retention property in the workspace list.',
    });
    const terminatedDaysLabel = message({
      id: 'Terminated contracts',
      comment: 'Label for data retention property in the workspace list.',
    });

    return (
      <Tooltip
        triggerClassName={style.Tooltip}
        header={(
          <div className={style.TooltipHeader}>
            {message({
              id: 'Data retention policy',
              comment: 'Tooltip showing whether data retention policy is enabled in the workspace list.',
            })}
          </div>
        )}
        message={(
          <ul className={style.TooltipList}>
            <li>
              {`${draftDaysLabel}: `}
              {getRetentionDays(agreementDraft ? String(agreementDraft) : undefined)}
            </li>
            <li>
              {`${overdueDaysLabel}: `}
              {getRetentionDays(agreementExpired ? String(agreementExpired) : undefined)}
            </li>
            <li>
              {`${declinedDaysLabel}: `}
              {getRetentionDays(agreementDeclined ? String(agreementDeclined) : undefined)}
            </li>
            <li>
              {`${terminatedDaysLabel}: `}
              {getRetentionDays(agreementTerminated ? String(agreementTerminated) : undefined)}
            </li>
          </ul>
        )}
        side="top"
      >
        <Link
          to={`/admin/workspaces/${workspace.id}/data-retention`}
          className={style.Link}
          aria-label={message({ id: 'Edit', comment: 'Button for editing company branding' })}
        >
          <Check className={style.Icon} height="20px" />
          <Edit className={style.EditIcon} height="20px" />
        </Link>
      </Tooltip>
    );
  };

  getWorkspaceContents = (workspace: Workspace) => (
    <div className={style.Counts}>
      <WorkspaceContractsCount workspace={workspace} />
      <WorkspaceTemplatesCount workspace={workspace} />
    </div>
  );

  getWorkspaceUserAccess = (workspace: Workspace) => <WorkspaceUserAccess workspace={workspace} />;

  getGrantUserAccessMenuItem = (workspace: Workspace) => (onClick: () => void) => {
    const hasGrantUserAccessPermission = checkAcl(workspace.acl, 'collection:position:binding:create');

    return (
      <MenuItem
        icon={GrantUserAccessIcon}
        onClick={onClick}
        disabled={!hasGrantUserAccessPermission}
        label={(
          <Message
            id="Grant user access"
            comment="label for granting a user access to a workspace"
          />
        )}
      />
    );
  };

  getGrantGroupAccessMenuItem = (workspace: Workspace) => (onClick: () => void) => {
    const hasGrantGroupAccessPermission = checkAcl(workspace.acl, 'collection:group:binding:create');

    if (!hasGrantGroupAccessPermission) {
      return null;
    }

    return (
      <MenuItem
        icon={GrantGroupAccessIcon}
        onClick={onClick}
        label={(
          <Message
            id="Grant group access"
            comment="label for granting a group access to a workspace"
          />
        )}
      />
    );
  };

  getDeleteBulkAction = (onClick: () => void) => {
    const { selectedWorkspaces } = this.state;
    const label = (
      <Message
        id="Delete"
        comment="Label for delete in bulk actions menu"
      />
    );

    const hasDeletePermission = selectedWorkspaces.every((workspace) => (
      checkAcl(workspace.acl, 'collection:remove')
    ));

    return (
      <DeleteBulkAction
        onClick={onClick}
        disabled={!hasDeletePermission}
        label={label}
      />
    );
  };

  handleOnEditClick = (id: number) => () => this.props.editLinkPath(id);

  handleOnAiReviewClick = (id: number) => () => this.props.aiReviewLinkPath(id);

  getDeleteMenuItem = (
    workspace: Workspace,
  ) => (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!checkAcl(workspace.acl, 'collection:remove')}
    />
  );

  getActions = (workspace: Workspace) => {
    /**
     *  We want to have an OR check to make it future-proof
     *  until we can control these fields separately.
     * */
    const hasEditWorkspacePermission = checkAcl(workspace.acl, [
      'collection:update:name',
      'collection:update:description',
      'collection:update:type',
    ], { match: 'any' });
    const editDisabled = !hasEditWorkspacePermission;
    const hasAiReviewPermission = checkAcl(workspace.acl, 'collection:ai_review:admin');

    const actions = [
      <EditMenuItem disabled={editDisabled} onClick={this.handleOnEditClick(workspace.id)} />,
      <GrantUserAccess
        workspace={workspace}
        shouldRedirectToWorkspaceAccess
      >
        {this.getGrantUserAccessMenuItem(workspace)}
      </GrantUserAccess>,
      <GrantGroupAccess
        shouldRedirectToWorkspaceAccess
        workspace={workspace}
      >
        {this.getGrantGroupAccessMenuItem(workspace)}
      </GrantGroupAccess>,
      <DeleteWorkspace workspace={workspace}>
        {this.getDeleteMenuItem(workspace)}
      </DeleteWorkspace>,
    ];

    if (hasAiReviewPermission) {
      const label = (
        <span>
          {workspace.isAiReviewEnabled ? (
            <Message
              id="Edit"
              comment="CTA Button text"
            />
          ) : (
            <Message
              id="Set up"
              comment="CTA Button text"
            />
          )}
          &nbsp;
          AI Review
        </span>
      );
      actions.splice(1, 0, (
        <MenuItem
          icon={AiSparkle}
          onClick={this.handleOnAiReviewClick(workspace.id)}
          label={label}
          isBeta
        />
      ));
    }

    return (
      <ActionsMenu
        actions={actions}
        focusOnCloseDisabled
      />
    );
  };

  onFilter = (params: { q: string }) => {
    const { queryWorkspaces } = this.props;

    queryWorkspaces({ params });
  };

  getTableConfig() {
    const { message, workspaces, account } = this.props;
    const { selectedWorkspaces } = this.state;

    const columnName = {
      name: 'name',
      label: message({
        id: 'Workspace',
        comment: 'Column header in the workspace list.',
      }),
      type: 'cell',
      value: this.getWorkspaceName,
    };
    const columnBranding = {
      name: 'branding',
      label: message({
        id: 'Branding',
        comment: 'Column header in the workspace list.',
      }),
      type: 'cell',
      value: this.getBranding,
    };
    const columnAiReview = {
      name: 'ai-review',
      label: message({
        id: 'AI Review',
        comment: 'Column header in the workspace list.',
      }),
      type: 'cell',
      value: this.getAiReview,
    };
    const columnType = {
      name: 'type',
      label: message({
        id: 'Type',
        comment: 'Column header in the workspace list.',
      }),
      type: 'cell',
      value: this.getWorkspaceType,
    };
    const columnDataRetention = {
      name: 'dataRetention',
      label: message({
        id: 'Data retention',
        comment: 'Column header in the workspace list.',
      }),
      type: 'cell',
      value: this.getDataRetention,
    };
    const columnAccess = {
      name: 'access',
      label: message({
        id: 'Access',
        comment: 'Column header for in the workspace list.',
      }),
      type: 'cell',
      value: this.getWorkspaceUserAccess,
      className: style.Column,
    };
    const columnContains = {
      name: 'contains',
      label: message({
        id: 'Contains',
        comment: 'Column header for in the workspace list.',
      }),
      type: 'cell',
      value: this.getWorkspaceContents,
      className: style.Column,
    };
    const columnActions = {
      name: 'actions',
      label: message({
        id: 'Actions',
        comment: 'Column header in the workspace list.',
      }),
      type: 'actions',
      value: this.getActions,
    };
    const bulkDeleteAction = (
      <DeleteWorkspaces
        selectedWorkspaces={selectedWorkspaces}
        key="remove"
      >
        {this.getDeleteBulkAction}
      </DeleteWorkspaces>
    );

    const isAiReviewEnabled = account.features?.extensionAiReview?.enabled === 1;

    const columns = [
      columnName,
      columnType,
      columnDataRetention,
      columnBranding,
      isAiReviewEnabled && columnAiReview,
      columnContains,
      columnAccess,
      columnActions,
    ].filter(Boolean);

    const table = {
      items: workspaces,
      itemKey: 'id',
      actions: [
        bulkDeleteAction,
      ],
      columns,
    };

    return table;
  }

  selectionChangeHandler = (selectedWorkspaceIds: Array<number>) => {
    const { workspaces } = this.props;

    const selectedWorkspaces = selectedWorkspaceIds.map((id) => (
      workspaces.find((workspace) => workspace.id === id)
    ));

    this.setState({ selectedWorkspaces });
  }

  getAddWorkspaceButton = (onClick: () => void) => {
    const { account } = this.props;
    const hasCreateWorkspaceAccessPermission = checkAcl(account.acl, 'account:collection:create');
    let buttonDisabled = false;

    if (!hasCreateWorkspaceAccessPermission) {
      buttonDisabled = true;
    }

    return (
      <Button
        icon={Add}
        kind="primary"
        onClick={onClick}
        disabled={buttonDisabled}
      >
        <Message
          id="Create workspace"
          comment="Button text for adding workspace in the workspace list."
        />
      </Button>
    );
  };

  changeHandler = ({ offset, limit }: Pagination) => {
    const { queryWorkspaces, query } = this.props;

    queryWorkspaces({
      params: query.params,
      pagination: {
        offset,
        limit,
      },
    });
  };

  renderPagination() {
    const { message, query } = this.props;

    if (query.loading || !query.count) {
      return null;
    }

    return (
      <Pagination
        totalItems={query.count}
        itemsPerPage={query.pagination.limit}
        currentOffset={query.pagination.offset}
        onChange={this.changeHandler}
        entityName={message({
          id: 'workspaces',
          comment: 'Plural form in pagination of workspaces in the admin page.',
        })}
      />
    );
  }

  render() {
    const { query, message } = this.props;

    return (
      <div>
        <ActionBar collapsed>
          <ActionBar.Group>
            <AddWorkspace>
              {this.getAddWorkspaceButton}
            </AddWorkspace>
          </ActionBar.Group>
        </ActionBar>
        <TableFiltering
          filters={[{
            type: 'text',
            name: 'search',
            defaultValue: '',
            queryKey: 'q',
            autoFocus: true,
            placeholder: message({
              id: 'Find workspaces',
              comment: 'Placeholder in search field.',
            }),
          }]}
          onFilterHandler={this.onFilter}
          loading={query.loading}
        />
        <Table
          config={this.getTableConfig()}
          query={query}
          onSelectionChanged={this.selectionChangeHandler}
        />
        {this.renderPagination()}
      </div>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Workspaces',
    comment: 'Page title for the workspace list.',
  }),
  modules: [[{
    path: '/:id(\\d+)',
    title: '',
    component: WorkspacePage,
  }]],
});

export default adminPage(propsMapper)(WorkspacesComponent);
