// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';
import get from 'lodash/get';

import { CancelButton } from 'components/buttons';
import Button from 'components/button';
import SmallAddIcon from 'components/icons/small-add';
import Confirmable from 'components/confirmable';
import { CreateBlankContract } from 'components/create-blank-contract';
import Modal from 'components/modal';
import TemplateResults from 'components/template-results';
import TemplateSearch from 'components/template-search';

import style from './template-launcher.module.scss';

const modalKey = 'new contract modal';

type Props = {
  createState: CreateState,
  hasTemplates: boolean,
  icon?: React.Node,
  isContractListView?: boolean,
  isContractView?: boolean,
  isDesktop: boolean,
  isDisabled?: boolean,
  query?: Query,
  queryName: string,
  queryNameWithImportTag: string,
  requestTemplates: (string, ?any) => void,
  requestTemplatesWithImportTag: (string, ?any) => void,
  resetCreateState: () => void,
  selectedFolder?: Folder,
  workspaceName?: string,
};

type State = {
  isOpen: boolean,
}

export class TemplateLauncher extends React.Component<Props, State> {
  static defaultProps = {
    isContractView: undefined,
    isDisabled: false,
    query: undefined,
  };

  state = {
    isOpen: false,
  };

  handleEnter = () => undefined;

  handleOpen = () => {
    const {
      queryName,
      queryNameWithImportTag,
      requestTemplates,
      requestTemplatesWithImportTag,
      resetCreateState,
    } = this.props;

    const { isOpen } = this.state;

    // This check is necessary to handle that we need both onOpen and isOpen in modal
    // (needed for redirect). Without it, we'll send double requests.
    if (isOpen) {
      return;
    }

    this.setState({ isOpen: true });
    resetCreateState();
    requestTemplatesWithImportTag(queryNameWithImportTag);
    requestTemplates(queryName);
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  isLoading = () => {
    const { query } = this.props;
    return get(query, 'loading');
  };

  isSearching = () => {
    const { query } = this.props;

    return !!get(query, 'params.q');
  };

  getErrorMessage = () => {
    const { createState } = this.props;
    const statusCode = get(createState.error, 'body.status_code');

    if (statusCode === undefined) {
      return null;
    }

    return {
      headerText: (
        <Message
          id="A document could not be created from this template."
          comment="Error header in the template launcher modal."
        />
      ),
      bodyText: (
        <Message
          id="Please contact support at {email}. Error code: {statusCode}"
          values={{
            email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
            statusCode,
          }}
          comment="Error body text when not possible to create a document."
        />
      ),
    };
  };

  renderHeader() {
    const {
      isContractListView,
      selectedFolder,
      workspaceName,
    } = this.props;

    if (isContractListView) {
      let name = '';
      if (selectedFolder) {
        name = selectedFolder.name;
      } else {
        name = workspaceName;
      }

      return (
        <Message
          id="New document in {name}"
          comment="Used as the modal title in the template launcher"
          values={{
            name,
          }}
        />
      );
    }
    return (
      <Message id="Create new document" comment="Used as the modal title in the template launcher" />
    );
  }

  renderBody() {
    const { isContractView, selectedFolder, hasTemplates } = this.props;

    return (
      <Modal.BodySections>
        <Modal.BodySection showWhenLoading>
          <TemplateSearch isSearching={this.isSearching()} isLoading={this.isLoading()} />
          <CreateBlankContract
            selectedFolderId={selectedFolder?.id}
            hasTemplates={hasTemplates}
          />
        </Modal.BodySection>
        <Modal.BodySection>
          <TemplateResults
            isSearching={this.isSearching()}
            closeOnRedirect={this.handleClose}
            isContractView={isContractView}
            folderId={selectedFolder?.id}
          />
        </Modal.BodySection>
      </Modal.BodySections>
    );
  }

  renderActions = ({ closeConfirmation }: Object) => (
    <CancelButton onClick={closeConfirmation} modalKey={modalKey} />
  );

  getChildren() {
    const { isContractListView } = this.props;

    if (isContractListView) {
      return (onClick) => (
        <Button
          icon={<SmallAddIcon height="10px" />}
          onClick={onClick}
          kind="secondary-lite"
          customClass={style.CreateNewButton}
        >
          <Message
            id="Create new document"
            comment="Button text for creating a new document"
          />
        </Button>
      );
    }

    return null;
  }

  render() {
    const {
      createState,
      icon,
      isContractListView,
      isDesktop,
      isDisabled,
    } = this.props;
    const { isOpen } = this.state;

    const launchButtonStyles = clsx(style.LaunchButton, {
      [style.MobileView]: !isDesktop,
    });

    return (
      <Confirmable
        actions={this.renderActions}
        body={this.renderBody()}
        customClass={isContractListView ? style.InlineLaunchButton : launchButtonStyles}
        customErrorMessage={this.getErrorMessage()}
        data-testid="template-launcher"
        disabled={isDisabled}
        error={createState.error}
        header={this.renderHeader()}
        icon={icon}
        isLoading={this.isLoading()}
        modalKey={modalKey}
        onEnter={this.handleEnter}
        onOpen={this.handleOpen}
        isOpen={isOpen}
        trackable={{
          name: 'Go To New Contract',
          props: {
            location: isContractListView ? 'Contract list' : 'Main navigation',
          },
        }}
        onClose={this.handleClose}
      >
        {this.getChildren()}
      </Confirmable>
    );
  }
}
