import type { Dispatch, SetStateAction } from 'react';
import { Message } from '@oneflowab/pomes';
import useAgreement from 'hooks/use-agreement';
import { isTemplate } from 'agreement';

import ChangeTemplateGroup from 'components/modals/change-template-group';

import style from './template-group-section.module.scss';

type Props = {
  agreementId: Oneflow.Agreement['id'],
  setSelectedTemplateGroupIdForContext: Dispatch<SetStateAction<number>>,
};

const TemplateGroupSection = ({
  agreementId,
  setSelectedTemplateGroupIdForContext,
}: Props) => {
  const agreement: Oneflow.Agreement = useAgreement(agreementId);

  const renderTemplateGroupSection = () => {
    if (!isTemplate(agreement) && agreement?.templateGroup?.name) {
      return (
        <>
          <div className={style.TemplateGroupHeader}>
            <Message id="Template group" comment="Label for the template group section." />
          </div>
          <div className={style.TemplateGroupLabel}>
            {agreement.templateGroup.name}
          </div>
        </>
      );
    }

    return (
      <ChangeTemplateGroup
        setSelectedTemplateGroupIdForContext={setSelectedTemplateGroupIdForContext}
      />
    );
  };

  return renderTemplateGroupSection();
};

export default TemplateGroupSection;
