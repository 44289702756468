// @flow

import * as React from 'react';
import { Element } from 'slate';

export const TableRowElementContext = React.createContext<any>();

type Props = {|
  children: React.Node,
  element: Element,
|};

export function TableRowElementProvider({
  children,
  element,
}: Props) {
  const value = React.useMemo(() => ({
    tableRowElement: element,
  }), [element]);

  return (
    <TableRowElementContext.Provider value={value}>
      {children}
    </TableRowElementContext.Provider>
  );
}

const emptyContextValue = Object.freeze({
  tableRowElement: null,
});

const useTableRowElement = () => {
  const contextValue = React.useContext(TableRowElementContext);

  if (!contextValue) {
    return emptyContextValue;
  }

  return contextValue;
};

export default useTableRowElement;
