import {
  omit,
  isNull,
  isUndefined,
  reduce,
  isFunction,
  isInteger,
} from 'lodash';

import type { Params, Sanitizer } from 'hocs/query-string-params/types';
import type { SubscribedFilters } from './types';

const omitSortingAndPaginationValues = (values?: Record<string, any>) => omit(values, 'sort', 'page');

type GetActiveFilters = (
  params: Params,
  availableFilters: SubscribedFilters,
) => SubscribedFilters;

export const getActiveFilters: GetActiveFilters = (params = {}, availableFilters = {}) => {
  const initialParams = omitSortingAndPaginationValues(params);

  return reduce(availableFilters, (acc, sanitizer, key) => {
    const { value } = sanitizer(initialParams[key], params);

    if (!isNull(value) && !isUndefined(value)) {
      return { ...acc, [key]: sanitizer };
    }

    return acc;
  }, {});
};

type SanitizedFilters = {
  urlParams: Params,
  queryParams: Params,
};

export const getFiltersValues = (
  params: Params = {},
  availableFilters: SubscribedFilters = {},
): SanitizedFilters => (
  reduce(availableFilters, (acc, _filter, key) => {
    const sanitizer = availableFilters[key];
    const { value, query } = sanitizer(params[key], params);
    const filtersValue = {
      ...acc,
      urlParams: {
        ...acc.urlParams,
      },
      queryParams: {
        ...acc.queryParams,
        [key]: query,
      },
    };

    if (!isNull(value) && !isUndefined(value)) {
      filtersValue.urlParams[key] = value;
    }

    return filtersValue;
  }, {
    urlParams: {},
    queryParams: {},
  })
);

const mapValueToQuery = (value: any, defaultValue: any, params: Params) => {
  if (isNull(value) || isUndefined(value)) {
    if (isFunction(defaultValue)) {
      return {
        query: defaultValue(params),
        value: null,
      };
    }

    return {
      query: defaultValue,
      value: null,
    };
  }

  return {
    query: value,
    value,
  };
};

type MakeFilterValueGetter = (
  defaultValue: any,
  sanitize?: Sanitizer | null,
) => Sanitizer;

export const makeFilterValueGetter: MakeFilterValueGetter = (defaultValue, sanitize) => (
  (value: any, params: Params) => {
    if (!sanitize) {
      return mapValueToQuery(value, defaultValue, params);
    }

    return mapValueToQuery(sanitize(value, params), defaultValue, params);
  }
);

export const pageSanitizer: Sanitizer = (page) => {
  if (isInteger(page) && page > 1) {
    return page;
  }

  return null;
};
