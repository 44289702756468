import { useCallback } from 'react';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';

import toast from 'components/toasts';
import { isConcluded } from 'agreement';
import { getGuestToken } from 'agreement/selectors';
import Button from 'components/button';

import AddComment from 'components/icons/add-comment';
import Edit from 'components/icons/edit';
import Delete from 'components/icons/delete';

import style from './annotation-toolbar.module.scss';

type MouseDownCallback = (event: MouseEvent) => void;

type Props = {
  agreementId: number,
  commentsEnabled: boolean,
  onCommentMouseDown: (event: MouseEvent) => void,
  onSuggestChange: (event: MouseEvent) => void,
  onSuggestDeletion: (event: MouseEvent) => void,
  state: number,
  suggestionsEnabled: boolean,
};

const AnnotationToolbar = ({
  agreementId,
  commentsEnabled,
  onCommentMouseDown,
  onSuggestChange,
  onSuggestDeletion,
  state,
  suggestionsEnabled,
}: Props) => {
  const guestToken = useSelector(getGuestToken);

  const triggerAndTrackAction = useCallback((
    triggerHandler: MouseDownCallback,
    event: MouseEvent,
    eventType?: string,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (eventType) {
      amplitudeLogEvent(eventType, {
        'participant type': guestToken ? 'guest' : 'owner',
      },
      {
        'document id': agreementId,
      });
    }

    triggerHandler(event);
  }, [agreementId, guestToken]);
  const isInPreviewMode = useIsInPreviewMode();

  const onAddCommentTrigger = useCallback((event: MouseEvent) => {
    triggerAndTrackAction(
      onCommentMouseDown, event, 'Go To Inline Comment', agreementId,
    );
  }, [
    agreementId,
    onCommentMouseDown,
    triggerAndTrackAction,
  ]);

  const onSuggestChangeTrigger = useCallback((event: MouseEvent) => {
    triggerAndTrackAction(
      onSuggestChange, event, 'Go To Suggestion', agreementId,
    );
  }, [
    agreementId,
    onSuggestChange,
    triggerAndTrackAction,
  ]);

  const onSuggestDeletionTrigger = useCallback((event: MouseEvent) => {
    if (isInPreviewMode) {
      return (
        toast.warning({
          id: 'preview-mode-suggest-deletion',
          title: <Message
            id="Your changes won’t be saved."
            comment="Title for the warning message when the suggests delete."
          />,
          duration: 5000,
        })
      );
    }
    return triggerAndTrackAction(onSuggestDeletion, event);
  }, [
    onSuggestDeletion,
    triggerAndTrackAction,
    isInPreviewMode,
  ]);

  return (
    <>
      {commentsEnabled && (
        <Button
          onMouseDown={onAddCommentTrigger}
          customClass={clsx('AppcuesAddCommentButton', style.ToolbarButton)}
          data-testid="add-comment-button"
          icon={<AddComment height="16px" />}
        >
          <Message
            id="Add comment"
            comment="CTA showing in a button that enables user to write a new comment"
          />
        </Button>
      )}
      {suggestionsEnabled && !isConcluded({ state }) && (
        <>
          <Button
            onMouseDown={onSuggestChangeTrigger}
            data-testid="suggest-change-button"
            customClass={style.ToolbarButton}
            icon={<Edit height="16px" />}
          >
            <Message
              id="Suggest change"
              comment="CTA showing in a button that enables user to write a suggestion for text change"
            />
          </Button>
          <Button
            onMouseDown={onSuggestDeletionTrigger}
            data-testid="suggest-deletion-button"
            customClass={style.ToolbarButton}
            icon={<Delete height="16px" />}
          >
            <Message
              id="Suggest delete"
              comment="CTA showing in a button that enables user to suggest a removal of text"
            />
          </Button>
        </>
      )}
    </>
  );
};

export default AnnotationToolbar;
