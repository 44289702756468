// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';

import AlignCenter from 'components/icons/align-center';
import AlignLeft from 'components/icons/align-left';
import AlignRight from 'components/icons/align-right';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import ToolbarButton from '../toolbar-button';
import { isAlignActive, toggleAlign } from './align-plugin';

export const AlignIcon = ({ direction }: { direction: 'left' | 'right' | 'center' }) => {
  if (direction === 'center') {
    return <AlignCenter height="24px" />;
  }

  if (direction === 'right') {
    return <AlignRight height="24px" />;
  }

  return <AlignLeft height="24px" />;
};

type Props = {
  direction: 'left' | 'right' | 'center',
  isMenuItem: boolean,
  label: React.Node,
};

const AlignButton = ({ direction, isMenuItem, label }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      isActive={isAlignActive(editor, direction)}
      isMenuItem={isMenuItem}
      label={label}
      onTrigger={() => {
        focusEditor();
        toggleAlign(editor, direction);
      }}
      icon={<AlignIcon direction={direction} />}
    />
  );
};

export default AlignButton;
