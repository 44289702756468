// @flow

import React from 'react';
import { useSelector } from 'react-redux';
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlate,
} from 'slate-react';
import clsx from 'clsx';

import { useDocumentPermissionHooks } from 'hooks/use-permissions';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { getDataField } from 'reducers/current-contract';
import { useContractProps } from 'contexts/contract-props';
import { useDataFields } from 'contexts/data-fields';
import { useRichTextProps } from 'contexts/rich-text';
import Leaf from 'components/rich-text-editor/leaf';
import Tooltip from 'components/tooltip';

import EditDocumentDataFieldModal from 'components/modals/edit-document-data-field';
import style from './data-field.module.scss';

type EditableDataFieldProps = {
  ...RenderSlateElementProps,
  dataFieldObject: any,
  isPlaceholder: boolean,
  visibleText: any,
};

export const EditableDataField = ({
  attributes,
  children,
  dataFieldObject,
  element,
  isPlaceholder,
  visibleText,
}: EditableDataFieldProps) => {
  const { marks, dataFieldKey } = element;
  const editor = useSlate();
  const focused = useFocused();
  const selected = useSelected();
  const focusEditor = useFocusEditor();

  const className = clsx('widget-datafield-value', style.DataField, {
    [style.DataFieldPlaceholder]: isPlaceholder,
  });

  return (
    <EditDocumentDataFieldModal
      dataFieldKey={dataFieldKey}
      dataField={dataFieldObject.value}
      focusEditor={focusEditor}
    >
      {(onClick) => (
        <span
          {...attributes}
          className={className}
          data-field-key={dataFieldKey}
          style={{
            display: 'inline-block',
            outline: selected && focused ? '1px solid green' : 'none',
          }}
          role="button"
          onClick={onClick}
          onMouseDown={(event) => {
            event.preventDefault();
            ReactEditor.focus(editor);
          }}
          tabIndex={0}
          aria-hidden
        >
          {children}
          <Leaf attributes={{}} leaf={marks || {}}>
            {visibleText}
          </Leaf>
        </span>
      )}
    </EditDocumentDataFieldModal>
  );
};

type DataFieldViewerProps = {
  ...RenderSlateElementProps,
  visibleText: any,
  isPlaceholder: boolean,
};

export const DataFieldViewer = ({
  attributes,
  element,
  children,
  visibleText,
  isPlaceholder,
}: DataFieldViewerProps) => {
  const { marks, dataFieldKey } = element;

  const className = clsx('widget-datafield-value', {
    [style.DataFieldPlaceholder]: isPlaceholder,
  });

  if (!visibleText) {
    return null;
  }

  return (
    <span
      {...attributes}
      className={className}
      data-field-key={dataFieldKey}
      style={{
        display: 'inline-block',
        outline: 'none',
      }}
    >
      {children}
      <Leaf attributes={{}} leaf={marks || {}}>
        {visibleText}
      </Leaf>
    </span>
  );
};

const DataField = ({
  attributes,
  element,
  children,
}: RenderSlateElementProps) => {
  const { isReadOnly } = useRichTextProps();
  const { dataFieldKey } = element;
  const {
    account,
    agreement,
    boxId,
  } = useContractProps();
  const { useCanAlterBoxComposition } = useDocumentPermissionHooks(agreement.id);
  const canAlterBoxComposition = useCanAlterBoxComposition(boxId);
  const { getDataFieldVisibleText } = useDataFields();
  const dataFieldObject = useSelector((state) => getDataField(state, dataFieldKey));
  const visibleText = getDataFieldVisibleText(
    dataFieldObject, agreement, account, boxId, canAlterBoxComposition,
  );

  if (!dataFieldObject) {
    return null;
  }

  const { description, name, value } = dataFieldObject.value;

  if (isReadOnly) {
    return (
      <DataFieldViewer
        attributes={attributes}
        element={element}
        visibleText={visibleText}
        isPlaceholder={!value}
      >
        {children}
      </DataFieldViewer>
    );
  }

  const dataFieldElement = (
    <EditableDataField
      attributes={attributes}
      dataFieldObject={dataFieldObject}
      element={element}
      isPlaceholder={!value}
      visibleText={visibleText}
    >
      {children}
    </EditableDataField>
  );

  if (description || name) {
    return (
      <Tooltip
        messageClassName={style.DataFieldTooltip}
        message={(
          <>
            {name}
            <br />
            {description}
          </>
        )}
        sideOffset={8}
        side="top"
      >
        <span>{dataFieldElement}</span>
      </Tooltip>
    );
  }

  return dataFieldElement;
};

export default DataField;
