// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import groupsReducer from 'reducers/entities/groups';

import { DeleteGroups, type Props } from './delete-groups';

const QUERY_NAME = 'admin/groups';

const requestName = 'bulk';

type StateProps = {|
  removeState: RemoveState,
|};

type OwnProps = $Diff<Props, StateProps>;

type DispatchProps = {|
  deleteGroups: () => void,
  resetRemoveState: Function,
|};

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  removeState: groupsReducer.getRemoveSelector(state, { id: requestName }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { selectedGroupIds }) => ({
  deleteGroups: () => {
    dispatch(groupsReducer.removeGroup({
      id: requestName,
      data: {
        ids: selectedGroupIds,
      },
      pipe: {
        action: () => groupsReducer.queryGroupsReload({
          name: QUERY_NAME,
        }),
        wait: groupsReducer.QUERY_GROUPS_SUCCESS,
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(groupsReducer.removeGroupReset({
      id: requestName,
    }));
  },
});

const connectedDeleteGroups = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteGroups);

export { connectedDeleteGroups as DeleteGroups };
