// @flow
/* eslint-disable react/no-unused-prop-types */

import * as React from 'react';
import { Form } from 'react-final-form';
import { localize } from '@oneflowab/pomes';
import MakeAsyncFunction from 'react-redux-promise-listener';
import promiseListener from 'store/promise-listener';
import type { MessageTranslator } from '@oneflowab/pomes';

import { ConfirmButton } from 'components/buttons';
import { type ButtonKinds } from 'components/button';
import Confirmable from 'components/confirmable';

import style from './modal-form-v2.module.scss';

type Props = {
  actions?: (args: ?Object) => void,
  allowPristine?: boolean,
  body: React.Node,
  children?: ((onClick?: Function) => React.Node) | React.Node,
  formActions?: null | {
    start: string,
    success: string,
    fail: string,
  },
  customConfirmButtonClass?: string,
  disabled?: boolean,
  formState?: CreateState | UpdateState | RemoveState,
  icon?: any,
  initialValues?: {},
  isDirty?: boolean,
  isOpen?: boolean,
  isWideModal?: boolean,
  kind?: ButtonKinds,
  message: MessageTranslator,
  modalKey: string,
  // set to true when you use setCustomValidity API
  // since we don't want to show the native validation pop-up
  noValidate: boolean,
  onClose?: () => void,
  onEnter?: () => void,
  onOpen?: () => void,
  onSubmit: Function,
  outline?: boolean,
  parseSubmitError?: Function,
  portalClassName?: string,
  prepareFormData?: Function,
  preventClose?: boolean,
  resetFormState?: () => void,
  title: React.Node,
  shouldRenderStaticScrollbar?: boolean,
  customModalClass?: string,
  customBodyClass?: string,
  showSuccessMessage?: boolean,
  centeredActions?: boolean,
  actionClasses?: string,
};

type FormProps = { formProps: ?Object };

type CloseConfirmation = { closeConfirmation: () => void };

export class ModalForm extends React.PureComponent<Props> {
  static defaultProps = {
    actions: undefined,
    allowPristine: undefined,
    children: undefined,
    customConfirmButtonClass: undefined,
    customBodyClass: undefined,
    formActions: null,
    icon: undefined,
    initialValues: undefined,
    isOpen: undefined,
    isWideModal: undefined,
    kind: undefined,
    onClose: undefined,
    onEnter: undefined,
    onOpen: undefined,
    outline: undefined,
    parseSubmitError: () => { },
    portalClassName: undefined,
    prepareFormData: () => { },
    preventClose: undefined,
    showSuccessMessage: true,
    centeredActions: undefined,
  };

  getSubmitHandler = (props, asyncFunc) => {
    if (props.formActions) {
      let submitError;
      const submitHandler = () => async (values) => {
        await asyncFunc(values)
          .catch((error) => { submitError = error; });

        return submitError;
      };
      return submitHandler();
    }

    return props.onSubmit;
  }

  parseFormErrors = () => {
    const { formState, parseSubmitError } = this.props;

    if (parseSubmitError && parseSubmitError(formState?.error)) {
      return null;
    }

    return formState?.error;
  }

  handleOpen = (reset: () => void) => {
    const { onOpen, resetFormState } = this.props;

    if (onOpen) {
      onOpen();
    }

    reset();

    if (resetFormState) {
      resetFormState();
    }
  };

  closeConfirmation: () => void;

  handleSubmit = (formProps: FormProps) => () => {
    const {
      formState,
      isDirty,
      onEnter,
    } = this.props;

    if ((
      this.checkPristine(formProps)
      || formProps.validating
      || formProps.hasValidationErrors
      || !!formState?.loading
    ) && !isDirty) {
      return;
    }

    formProps.handleSubmit();

    if (onEnter) {
      onEnter();
    }
  }

  checkPristine(formProps: any) {
    const { allowPristine } = this.props;

    if (allowPristine) {
      return false;
    }

    return formProps.pristine;
  }

  // eslint-disable-next-line react/prop-types
  renderActions = ({ formProps }: FormProps) => ({ closeConfirmation }: CloseConfirmation) => {
    const {
      actions,
      customConfirmButtonClass,
      formState,
    // eslint-disable-next-line react/no-this-in-sfc
    } = this.props;

    if (actions) {
      // eslint-disable-next-line react/no-this-in-sfc
      this.closeConfirmation = closeConfirmation;
      return actions({ formProps, closeConfirmation });
    }

    return (
      <ConfirmButton
        onClick={formProps.handleSubmit}
        disabled={(
          // eslint-disable-next-line react/no-this-in-sfc
          this.checkPristine(formProps)
          || formProps.hasValidationErrors
          || formState.loading
          || formProps.validating
        )}
        customClass={customConfirmButtonClass}
        isLoading={formState.loading}
      />
    );
  };

  renderForm(asyncFunc) {
    const {
      title,
      body,
      formState,
      icon,
      children,
      kind,
      outline,
      onOpen,
      isOpen,
      onClose,
      onEnter,
      isWideModal,
      portalClassName,
      preventClose,
      disabled,
      modalKey,
      shouldRenderStaticScrollbar,
      customModalClass,
      customBodyClass,
      showSuccessMessage,
      centeredActions,
      actionClasses,
      ...restProps
    } = this.props;
    return (
      <Form
        render={(formProps) => (
          <Confirmable
            header={title}
            body={(
              <form className={style.ModalForm} onSubmit={formProps.handleSubmit}>
                {body}
              </form>
            )}
            actions={this.renderActions({ formProps })}
            error={this.parseFormErrors()}
            success={!!formState?.success}
            kind={kind}
            outline={outline}
            icon={icon}
            onOpen={() => this.handleOpen(formProps.form.reset)}
            isOpen={isOpen}
            onClose={onClose}
            onEnter={this.handleSubmit(formProps)}
            isWideModal={isWideModal}
            portalClassName={portalClassName}
            preventClose={preventClose}
            isDirty={formProps.dirty}
            disabled={disabled}
            modalKey={modalKey}
            shouldRenderStaticScrollbar={shouldRenderStaticScrollbar}
            customModalClass={customModalClass}
            customBodyClass={customBodyClass}
            showSuccessMessage={showSuccessMessage}
            centeredActions={centeredActions}
            actionClasses={actionClasses}
          >
            {children}
          </Confirmable>
        )}
        onSubmit={this.getSubmitHandler(restProps, asyncFunc)}
        {...restProps}
      />
    );
  }

  render() {
    const { formActions, prepareFormData, parseSubmitError } = this.props;

    if (formActions) {
      return (
        <MakeAsyncFunction
          listener={promiseListener}
          start={formActions.start}
          resolve={formActions.success}
          reject={formActions.fail}
          setPayload={(action, payload) => ({
            ...action,
            ...prepareFormData(payload),
          })}
          getError={(action) => parseSubmitError(action.error)}
        >
          {(asyncFunc) => this.renderForm(asyncFunc)}
        </MakeAsyncFunction>
      );
    }

    return this.renderForm();
  }
}

const emptyRender = () => null;

// TODO: Use radix
function NativeForm(props: Props) {
  const {
    // basic props
    title,
    body,
    children,
    actions,
    onSubmit,
    onClose,
    // Modal state controller props
    isOpen,
    // Style Tweaks
    icon,
    kind,
    outline,
    isWideModal,
    portalClassName,
    preventClose,
    modalKey,
    noValidate = false,
    customConfirmButtonClass,
  } = props;

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target));
    onSubmit(formData, e.target.elements);
  }, [onSubmit]);

  return (
    <Confirmable
      header={title}
      body={emptyRender}
      form={({ closeConfirmation }) => (
        <form className={style.ModalForm} onSubmit={handleSubmit} noValidate={noValidate}>
          {body}
          {actions({ closeConfirmation })}
        </form>
      )}
      actions={emptyRender}
      kind={kind}
      outline={outline}
      icon={icon}
      isOpen={isOpen}
      onClose={onClose}
      isWideModal={isWideModal}
      portalClassName={portalClassName}
      preventClose={preventClose}
      customConfirmButtonClass={customConfirmButtonClass}
      modalKey={modalKey}
    >
      {children}
    </Confirmable>
  );
}

const LocalizedModalForm = localize<Props>(ModalForm);

LocalizedModalForm.Native = localize<Props>(NativeForm);

export default LocalizedModalForm;
