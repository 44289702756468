/* eslint-disable @typescript-eslint/no-explicit-any */
import Message from 'components/message';
import { uniqueId } from 'lodash';
import {
  getOtherEnabledParticipants,
  getEnabledParticipants,
  getAgreementMyParticipant,
  getUndecidedSignatories,
} from 'agreement/selectors';
import { getDraftApprovalOrder } from 'agreement/draft-approval-flow';

import { getPendingStateParticipants } from 'agreement/pending-state-flow';

import isInternalApprover from 'agreement/participant/is-internal-approver';
import hasDeliveryChannelExternal from 'agreement/participant/has-delivery-channel-external';

import RecipientDetails from './recipient-details';

import style from './recipient-list.module.scss';

export type Props = {
  agreement: Agreement,
  undecidedParticipantsOnly: boolean;
  currentSignOrderBlockOnly?: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
  recipients: Array<AgreementParticipant>;
  isInternalApproverList: boolean,
  draftApprovers?: Array<AgreementParticipant>,
};

type Recipients = (Agreement: any, arg1: boolean) => Array<AgreementParticipant>;

export const recipientsList: Recipients = (
  agreement: any,
  undecidedParticipantsOnly: boolean,
  currentSignOrderBlockOnly: boolean,
) => {
  const hasSignOrder = agreement.config?.signOrder;
  const undecidedSignatories = getUndecidedSignatories(agreement)
    .filter(hasDeliveryChannelExternal);

  if (hasSignOrder) {
    const entries = getPendingStateParticipants(agreement);
    const currentBlock = agreement.pendingStateFlow?.runningBlock?.config.blockIndex || 1;
    const allParticipants = getEnabledParticipants(agreement)
      .filter(hasDeliveryChannelExternal);

    const getFromParticipantList = (item: any) => allParticipants.find(
      (participant) => item.participantId === participant?.id,
    );

    let signOrderEntries = entries;
    if (currentSignOrderBlockOnly) {
      signOrderEntries = entries.filter((item: any) => item.block === currentBlock);
    }

    if (undecidedParticipantsOnly) {
      const getFromUndecidedParticipantList = (item: any) => undecidedSignatories.find(
        (participant) => item.participantId === participant?.id,
      );
      return signOrderEntries.map(
        (signOrderItem: any) => getFromUndecidedParticipantList(signOrderItem),
      ).filter((item: any) => Boolean(item));
    }

    return signOrderEntries.map(
      (signOrderItem: any) => getFromParticipantList(signOrderItem),
    ).filter((item: any) => Boolean(item));
  }

  if (undecidedParticipantsOnly) {
    return undecidedSignatories;
  }

  return getOtherEnabledParticipants(agreement).filter(hasDeliveryChannelExternal);
};

const RecipientList = ({
  agreement,
  undecidedParticipantsOnly,
  currentSignOrderBlockOnly,
  isInternalApproverList,
  draftApprovers,
}: Props) => {
  const recipients = recipientsList(
    agreement,
    undecidedParticipantsOnly,
    currentSignOrderBlockOnly,
  );

  const recipientsWithoutApprovers = recipients.filter(
    (participant) => !isInternalApprover(participant),
  );

  const renderListTitle = () => {
    if (isInternalApproverList) {
      return <Message id="Approvers" comment="Title for approvers list" />;
    }

    return <Message id="Recipients" comment="Title for recipients list" />;
  };

  const renderRecipientDetails = (recipient: AgreementParticipant) => {
    if (!recipient) {
      return null;
    }

    const myParticipant = getAgreementMyParticipant(agreement);

    return (
      <RecipientDetails
        agreement={agreement}
        recipient={recipient}
        key={uniqueId()}
        isMyParticipant={recipient.id === myParticipant?.id}
        isInternalApprover={isInternalApproverList}
      />
    );
  };

  const sortAndAddApprovalOrder = () => {
    let approversWithOrder = draftApprovers?.slice();

    if (!agreement || !approversWithOrder) {
      return [];
    }

    approversWithOrder = approversWithOrder.map((approver) => ({
      ...approver,
      approvalOrder: getDraftApprovalOrder(agreement, approver.id),
    }));

    // Sort the array based on approval order
    approversWithOrder.sort((a, b) => a.approvalOrder - b.approvalOrder);
    return approversWithOrder;
  };

  const renderList = () => {
    if (isInternalApproverList && draftApprovers !== undefined) {
      const approversList = sortAndAddApprovalOrder();
      return approversList?.map(renderRecipientDetails);
    }

    const sortedRecipients = recipientsWithoutApprovers.sort(
      (a, b) => a?.roles[0]?.blockIndex - b?.roles[0]?.blockIndex,
    );

    return sortedRecipients.map(renderRecipientDetails);
  };

  if (!recipients.length) {
    return null;
  }

  return (
    <div className={isInternalApproverList ? undefined : style.RecipientListContainer}>
      <div className={style.ListTitle}>
        {renderListTitle()}
        :
      </div>
      {renderList()}
    </div>
  );
};

export default RecipientList;
