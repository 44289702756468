// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import {
  bulletedListPlugin,
  numberedListPlugin,
  imagePlugin,
  addTablePlugin,
  clearFormattingPlugin,
  pageBreakPlugin,
  horizontalLinePlugin,
  forwardPlugin,
  backwardPlugin,
} from 'components/contract-text-editor/toolbar-plugins';

export const MAX_VIDEO_SIZE = 128 * 1000 * 1000;

export const disabledPlugins = [
  bulletedListPlugin,
  numberedListPlugin,
  imagePlugin,
  addTablePlugin,
  clearFormattingPlugin,
  pageBreakPlugin,
  horizontalLinePlugin,
  forwardPlugin,
  backwardPlugin,
];

export const videoSizeErrorMessage = (
  <Message
    id="The selected file size is too big. Please select another file."
    comment="This message shows video size is bigger than acceptable size"
  />
);

export const videoTypeErrorMessage = (
  <Message
    id="The selected file type is not supported. Please select another file."
    comment="This message shows video type is not valid"
  />
);

export const videoUploadingErrorMessage = (
  <Message
    id="The file couldn't be uploaded. Please try again."
    comment="This message shows there is an error while uploading the video"
  />
);

export const uploadingVideoMessage = (
  <Message
    id="Uploading video... Please wait"
    comment="This message shows video is ready to upload"
  />
);

export const processingVideoMessage = (
  <Message
    id="Processing video... Please wait"
    comment="This message shows video is in processing"
  />
);

export const uploadingVideoSuccessMessage = (
  <Message
    id="Success!"
    comment="This message shows video uploaded successfully"
  />
);
