import type { FormApi } from 'final-form';
import type { FieldRenderProps } from 'react-final-form';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message, MessageTranslator } from '@oneflowab/pomes';
import { Form as ReactFinalForm } from 'react-final-form';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';

import adminPage from 'hocs/admin-page';
import { formatJan312021, getDateFormat, getDateFormatsAsOptions } from 'date';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import client from 'oneflow-client';

import * as EditableForm from 'components/editable-form';
import Field from 'components/field';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import { getCountryListAsOptions } from 'components/countries';
import { checkAcl } from 'components/acl';
import workspacesReducer from 'reducers/entities/workspaces';
import DisableWorkspaceBranding from 'components/modals/disable-workspace-branding';
import ToggleOnIcon from 'components/icons/toggle-on';
import Button from 'components/button';
import EmptyState from 'components/empty-state';
import WorkspaceIcon from 'components/icons/workspace';
import { HelpCenterLink } from 'components/help-center-link';
import FileUpload from 'components/file-upload';
import AccountLogo from 'routes/admin/account/account-page/form/account-logo';
import { CounterPartyPreview, EmailPreview } from 'components/logo-preview';
import { FILE_IMAGE_PNG, FILE_IMAGE_BMP, FILE_IMAGE_JPEG } from 'utils/file';

import style from './workspace-branding.module.scss';

type Params = {
  id: string;
};

type FormData = {
  brandingDateFormat: string;
  country: {
    value: string;
  };
  logoId: number;
  name: string;
  orgnr: string;
};

type FileParameterType = {
  file: File, type: 'document' | 'logo'
};

export const Branding = ({ message }: {message: MessageTranslator}) => {
  const dispatch = useDispatch();
  const { id } = useParams<Params>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const account: Oneflow.Account = useSelector(getAccountFromSessionSelector);
  const workspace = useSelector(
    (state) => workspacesReducer.getWorkspaceSelector(state, { id: Number(id) }),
  );

  const canEdit = checkAcl(workspace.acl, 'collection:update:branding');
  const currentLanguage = useSelector(getCurrentLanguageSelector);
  const dateFormat = getDateFormat(account.brandingDateFormat);

  const formState = useSelector(
    (state) => workspacesReducer.getUpdateWorkspaceBrandingSelector(state, { id: Number(id) }),
  );
  const resetFormState = () => {
    dispatch(workspacesReducer.updateWorkspaceBrandingReset({ id: Number(id) }));
  };

  const [formDataFileUrl, setFormDataFileUrl] = useState(workspace?.logo?.url);

  const renderHeader = (form: FormApi) => {
    const {
      brandingName,
      brandingCountry,
    } = workspace;

    if (isEditMode) {
      const nameFormData = form.getFieldState('name')?.value;
      const countryFormData = form.getFieldState('country')?.value;
      const buttonDisabled = !canEdit || isEmpty(nameFormData) || isEmpty(countryFormData);
      return (
        <EditableForm.Actions
          resetFormState={() => {
            setFormDataFileUrl(workspace.logo?.url);
            resetFormState();
          }}
          formState={formState}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          disabled={buttonDisabled}
        />
      );
    }

    if (brandingName || brandingCountry) {
      return (
        <>
          <EditableForm.Actions
            resetFormState={resetFormState}
            formState={formState}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            disabled={!canEdit}
          />
          <DisableWorkspaceBranding workspace={workspace} />
        </>
      );
    }

    return (
      <Button
        kind="primary"
        disabled={!canEdit}
        icon={ToggleOnIcon}
        onClick={() => {
          resetFormState();
          setIsEditMode(true);
        }}
        data-testid="branding-enable"
      >
        <Message
          id="Enable branding"
          comment="Button text for enabling workspace branding."
        />
      </Button>
    );
  };

  const getInitialValues = () => {
    const {
      brandingName,
      brandingCountry,
      brandingOrgnr,
      brandingDateFormat,
    } = workspace;
    return {
      name: brandingName,
      country: brandingCountry,
      orgnr: brandingOrgnr,
      brandingDateFormat: brandingDateFormat || dateFormat,
    };
  };

  const onSubmit = (formData: FormData) => {
    const {
      brandingCountry,
      brandingDateFormat,
    } = workspace;

    let logo;
    if (formData.logoId !== undefined) {
      logo = formData.logoId !== null ? { id: Number(formData.logoId) } : null;
    }

    dispatch(workspacesReducer.updateWorkspaceBranding({
      id: Number(id),
      data: {
        brandingName: formData.name,
        brandingCountry: formData.country?.value || brandingCountry,
        brandingOrgnr: formData.orgnr,
        brandingDateFormat: formData.brandingDateFormat?.value || brandingDateFormat,
        ...(logo !== undefined && { logo }),
      },
    }));
    setIsEditMode(false);
  };

  const shouldShowEmptyState = !workspace.brandingName && !workspace.brandingCountry && !isEditMode;

  const onUpload = async (file: FileParameterType, form: FormApi) => {
    const uploadAsset = await client.uploadAsset({
      file: file.file,
      type: file.type,
    });

    form.change('logoId', uploadAsset.assetId);
    setFormDataFileUrl(uploadAsset.assetUrl);

    return uploadAsset;
  };

  return (
    <ReactFinalForm
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <EditableForm.Header>
            {renderHeader(form)}
          </EditableForm.Header>
          {!shouldShowEmptyState
            ? (
              <EditableForm.Body>
                <p className={style.HeaderText}>
                  <Message
                    id="Workspace branding"
                    comment="Header of the workspace branding page."
                  />
                </p>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message id="Company name" comment="Label in the account branding page." />
                  </EditableForm.Label>
                  <Field
                    id="name"
                    name="name"
                    component={TextField}
                    value={workspace.brandingName}
                    placeholder={message({
                      comment: 'Placeholder in the account branding page.',
                      id: 'Enter company name',
                    })}
                    autoFocus
                    maxLength={100}
                    className={style.Input}
                    disabled={!isEditMode}
                    required
                    label={(
                      <Message id="Company name" comment="Label in the account branding page." />
                    )}
                    hideLabel
                  />
                </div>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message id="Registration number" comment="Label in the account branding page." />
                  </EditableForm.Label>
                  <Field
                    id="orgnr"
                    name="orgnr"
                    component={TextField}
                    value={workspace.brandingOrgnr}
                    placeholder={message({
                      comment: 'Placeholder in the account branding page.',
                      id: 'Enter registration number',
                    })}
                    maxLength={25}
                    disabled={!isEditMode}
                  />
                </div>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message id="Country" comment="Label in the account branding page." />
                  </EditableForm.Label>
                  <Field
                    id="country"
                    name="country"
                    component={SelectField}
                    value={workspace.brandingCountry}
                    options={getCountryListAsOptions(currentLanguage)}
                    placeholder={message({
                      comment: 'Placeholder in the account branding page.',
                      id: 'Select country',
                    })}
                    maxLength={100}
                    className={style.Input}
                    disabled={!isEditMode}
                    required
                    label={(
                      <Message id="Country" comment="Label in the account branding page." />
                    )}
                    hideLabel
                  />
                </div>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message id="Date format" comment="Label in the account branding page." />
                  </EditableForm.Label>
                  <Field
                    id="brandingDateFormat"
                    name="brandingDateFormat"
                    component={SelectField}
                    value={`${dateFormat} (${formatJan312021(dateFormat)})`}
                    options={getDateFormatsAsOptions({ message })}
                    placeholder={message({
                      comment: 'Placeholder in the account branding page.',
                      id: 'Select date format',
                    })}
                    searchable
                    clearable={false}
                    disabled={!isEditMode}
                  />
                </div>
                <div className={style.LogoRow}>
                  <div>
                    <EditableForm.Label>
                      <Message
                        id="Logo"
                        comment="Used as the label of the relevant field in the account branding page."
                      />
                    </EditableForm.Label>
                    {isEditMode ? (
                      <>
                        <Field name="logoId">
                          {({ input }: { input: FieldRenderProps<HTMLInputElement> }) => (
                            <input type="hidden" {...input} />
                          )}
                        </Field>
                        <div className={style.FileUploadContainer}>
                          <FileUpload
                            onUpload={(file: FileParameterType) => onUpload(file, form)}
                            fileUrl={formDataFileUrl || workspace.logo?.url}
                            input={{
                              onChange: (value: number | null) => {
                                if (!value) {
                                  form.change('logoId', value);
                                  setFormDataFileUrl(null);
                                }
                              },
                            }}
                            accept={[FILE_IMAGE_PNG, FILE_IMAGE_BMP, FILE_IMAGE_JPEG]}
                            displayFileType="image"
                            assetType="logo"
                          />
                        </div>
                      </>
                    ) : <AccountLogo url={workspace?.logo?.url} message={message} />}
                  </div>
                  <div>
                    <EditableForm.Label>
                      <Message
                        id="Counterparty preview"
                        comment="Used as the label of the relevant field in the account branding page."
                      />
                    </EditableForm.Label>
                    <CounterPartyPreview
                      companyName={account.name}
                      logo={formDataFileUrl}
                    />
                  </div>
                  <div>
                    <EditableForm.Label>
                      <Message
                        id="Email preview"
                        comment="Used as the label of the relevant field in the account branding page."
                      />
                    </EditableForm.Label>
                    <EmailPreview
                      companyName={account.name}
                      logo={formDataFileUrl}
                    />
                  </div>
                </div>
              </EditableForm.Body>
            ) : (
              <EmptyState
                icon={(<WorkspaceIcon height="33px" />)}
                header={(
                  <Message
                    id="Workspace branding"
                    comment="Empty state header for workspace branding page when not enabled."
                  />
                )}
                content={(
                  <div>
                    <Message
                      id="By enabling workspace branding, you are able to set organization name, registration number, country and logotype specifically for this workspace."
                      comment="Empty state text for the workspace branding page when not enabled."
                    />
                    <br />
                    <HelpCenterLink path="support/solutions/articles/77000435909-workspace-branding" />
                  </div>
)}
                defaultStyle
                className={style.Empty}
              />
            )}
        </form>
      )}
    />
  );
};

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: MapperProps) => ({
  title: message({
    id: 'Branding',
    comment: 'Used as the page title of the workspace details page',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(Branding);
