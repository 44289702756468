// @flow

import * as React from 'react';
import Button from 'components/button';
import Bell from 'components/icons/bell';
import Edit from 'components/icons/edit';
import ViewCountEye from 'components/icons/view-count-eye';
import { useUserEvents } from 'contexts/user-events';

import style from './internal-reminder.module.scss';

type Props = {
  event: LifecycleCalendarEvent,
};

const InternalReminder = ({
  event,
}: Props) => {
  const { sourceEvent } = event;
  const { subject } = sourceEvent.customReminderData;
  const { setSelectedEvent, openModal } = useUserEvents();

  const renderSubject = () => {
    if (!subject) {
      return null;
    }
    return <span>{subject}</span>;
  };

  return (
    <div id="internal-reminder" className={style.InternalReminderEventInsight}>
      <Bell height="12px" />
      {renderSubject()}
      <div className={style.PermissionIcon}>
        <Button
          icon={sourceEvent?.isEditable
            ? <Edit height="12px" /> : <ViewCountEye height="12px" />}
          kind="linkSeparate"
          onClick={() => {
            setSelectedEvent(sourceEvent);
            openModal();
          }}
        />
      </div>
    </div>
  );
};

export default InternalReminder;
