import { forwardRef } from 'react';
import clsx from 'clsx';
import type { ComponentProps } from 'react';

import { Input as CommandInput } from 'components/filterable';
import CircularSpinner from 'components/icons/circular-spinner';

import style from './input.module.scss';

type InputProps = {
  loading: boolean;
} & ComponentProps<typeof CommandInput>;

const Input = forwardRef<HTMLInputElement, InputProps>(({ loading, ...props }, ref) => (
  <div className={clsx(style.InputContainer)}>
    <CommandInput
      {...props}
      ref={ref}
    />
    {loading && <CircularSpinner width="18px" className={style.LoadingSpinnerIcon} />}
  </div>
));

Input.displayName = 'Input';

export default Input;
