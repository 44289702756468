import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateBoxDataItemAction } from 'reducers/current-contract';

import { getGuestToken, getParticipantById } from 'agreement/selectors';
import { usePdfBoxProps } from 'contexts/pdf-box-props';
import { useDocumentPermissionHooks } from 'hooks/use-permissions';
import type { Position, Size } from 'types/overlay';
import type { PDFOverlayFieldSignatureData } from 'data-validators/entity-schemas/document-box/pdf-box';
import useAgreement from 'hooks/use-agreement';
import { isConcluded } from 'agreement';
import { checkAcl } from 'components/acl';

import OverlayFieldRectangle from '../overlay-field-rectangle';
import { SignatureFieldContent } from './signature-field-content';

type Props = {
  id: number,
  overlayFieldValue: PDFOverlayFieldSignatureData['value'],
  boxId: number,
  toBeRemoved?: boolean,
  scale: number,
  canRemoveOverlayField: boolean,
  boundaries: {
    width: number,
    height: number,
  }
}

export const OverlaySignatureField = ({
  id: overlayFieldContentDataId,
  overlayFieldValue,
  toBeRemoved,
  boxId,
  scale,
  canRemoveOverlayField,
  boundaries,
}: Props) => {
  const dispatch = useDispatch();
  const { agreementId } = usePdfBoxProps();
  const agreement = useAgreement(agreementId);
  const {
    useCanAlterBoxComposition,
  } = useDocumentPermissionHooks(agreementId);
  const [focused, setFocused] = useState(false);
  const canAlterBoxComposition = useCanAlterBoxComposition(boxId);
  const canAlterAgreementLayout = checkAcl(agreement?.acl, 'agreement:layout:update');
  const guestToken = useSelector(getGuestToken);
  const isGuestView = Boolean(guestToken);
  const isUnassigned = overlayFieldValue?.participant === null;
  const isUnassignedAndGuestView = isUnassigned && isGuestView;

  const { size, position } = overlayFieldValue;
  const participantId = overlayFieldValue.participant?.id;

  const participant = participantId ? getParticipantById(agreement, participantId) : null;
  const hasSigned = participant?.signatureInfo?.hasSigned;

  const overlayFieldContainerRef = useRef<HTMLDivElement | null>(null);
  const onResize = useCallback((newSize: Size) => dispatch(updateBoxDataItemAction(
    boxId,
    overlayFieldContentDataId,
    {
      value: {
        ...overlayFieldValue,
        size: newSize,
      },
    },
  )), [boxId, dispatch, overlayFieldContentDataId, overlayFieldValue]);

  const onMove = useCallback((newPosition: Position) => {
    dispatch(updateBoxDataItemAction(
      boxId,
      overlayFieldContentDataId,
      {
        value: {
          ...overlayFieldValue,
          position: newPosition,
        },
      },
    ));
  }, [boxId, dispatch, overlayFieldContentDataId, overlayFieldValue]);

  if (toBeRemoved || isUnassignedAndGuestView) {
    return null;
  }

  if (isUnassigned && isConcluded(agreement)) {
    return null;
  }

  return (
    <OverlayFieldRectangle
      overlayFieldContentDataId={overlayFieldContentDataId}
      overlayFieldContainerRef={overlayFieldContainerRef}
      dataFieldName="NAME"
      position={position}
      size={size}
      scale={scale}
      canAlterBoxComposition={canAlterBoxComposition}
      canAlterAgreementLayout={canAlterAgreementLayout}
      onResize={onResize}
      onMove={onMove}
      focused={focused}
      isValueEmpty={false}
      isRequired={false}
      isBroken={false}
      isConcluded={isConcluded(agreement)}
      type="signature_field"
    >
      <SignatureFieldContent
        hasSigned={Boolean(hasSigned)}
        setFocused={setFocused}
        focused={focused}
        boxId={boxId}
        overlayFieldContentDataId={overlayFieldContentDataId}
        overlayFieldValue={overlayFieldValue}
        canRemoveOverlayField={canRemoveOverlayField}
        boundaries={boundaries}
      />
    </OverlayFieldRectangle>
  );
};
