import { Message } from '@oneflowab/pomes';

import AlertIcon from 'components/icons/alert';

import style from './email-change-notification.module.scss';

type Props = {
  email: string
}

const EmailChangeNotification = ({ email }: Props) => (
  <div className={style.EmailChangeNotificationContainer}>
    <AlertIcon width="11px" />
    <div className={style.EmailChangeNotification}>
      <b>
        <Message
          id="A verification email has been sent to {email}."
          values={{
            email,
          }}
          comment="Text for notifying email change of user."
        />
      </b>
      <Message
        id="Once verified, it will be updated in the field above."
        comment="Text for notifying email change of user."
      />
    </div>
  </div>

);

export default EmailChangeNotification;
