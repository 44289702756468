// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getApiTokens,
  createApiToken,
  removeApiToken,
} from 'oneflow-client/extensions/api-tokens';
import type { NormalizedApiTokens } from 'oneflow-client/extensions/api-tokens';

import apiWrapper from 'sagas/api-wrapper';

import apiTokensReducer from 'reducers/entities/api-tokens';

type MapperArgs = {
  data: NormalizedApiTokens,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(apiTokensReducer.setApiTokens(data.entities.apiTokens));
}

type ClearMapperArgs = {
  data: {||},
  action: {
    id: number,
    type: string,
  },
};
export function* clearMapper({ action }: ClearMapperArgs): Mapper {
  yield put(apiTokensReducer.clearApiToken({ id: action.id }));
}

const mappers = {
  query: {
    mapper,
    request: getApiTokens,
  },
  create: {
    mapper,
    request: createApiToken,
  },
  remove: {
    mapper: clearMapper,
    request: removeApiToken,
  },
};

const apiTokensSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: apiTokensReducer,
  mappers,
});

export default apiTokensSaga;
