import { Message } from '@oneflowab/pomes';
import { useCallback } from 'react';
import { useSlate } from 'slate-react';
import get from 'lodash/get';

import LinkIcon from 'components/icons/link';

// eslint-disable-next-line import/named
import { useContractProps } from 'contexts/contract-props';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import ToolbarButton from '../toolbar-button';
import { getActiveLinkNode } from './plugin';

import style from './link-button.module.scss';

type Props = {
  isMenuItem: boolean,
  disabled: boolean,
};

const LinkButton = ({ isMenuItem, disabled }: Props) => {
  const { setLinkPopoverOpen } = useContractProps();
  const editor = useSlate();
  const focusEditor = useFocusEditor();
  const activeLinkNode = getActiveLinkNode(editor, editor.selection);
  const existingLink = get(activeLinkNode, 'url', '');

  const onInsertLink = useCallback(() => {
    if (existingLink) {
      focusEditor();
    } else {
      setLinkPopoverOpen(true);
    }
  }, [focusEditor, existingLink, setLinkPopoverOpen]);

  return (
    <ToolbarButton
      label={(
        <Message
          id="Insert link"
          comment="The label of the editor's toolbar plugin that inserts a link."
        />
      )}
      isMenuItem={isMenuItem}
      disabled={disabled}
      onTrigger={onInsertLink}
      icon={<LinkIcon className={style.LinkIcon} />}
    />
  );
};

export default LinkButton;
