// @flow

import { type MessageTranslator } from '@oneflowab/pomes';
import { getRef } from 'agreement/event';
import AuditTrailConstants from 'agreement/audit-trail-constants';

const boxDisplayName = (boxType, message) => {
  switch (boxType) {
    case AuditTrailConstants.TYPE_DOCUMENT:
      return message({
        id: 'A document',
        comment: '"A document was updated.", refers to the section and used in the audit trail.',
      });

    case AuditTrailConstants.TYPE_ATTACHMENTS:
      return message({
        id: 'Attachments',
        comment: '"Attachments was added.", refers to the section and used in the audit trail.',
      });

    case AuditTrailConstants.TYPE_DURATION:
      return message({
        id: 'Duration',
        comment: '"Duration was removed.", refers to the section and used in the audit trail.',
      });

    case AuditTrailConstants.TYPE_TEXT:
      return message({
        id: 'A text',
        comment: '"A text was updated.", refers to the section and used in the audit trail.',
      });

    case AuditTrailConstants.TYPE_FORM:
      return message({
        id: 'A form',
        comment: '"A form was added.", refers to the section and used in the audit trail.',
      });

    case AuditTrailConstants.TYPE_PRODUCTS:
      return message({
        id: 'A product table',
        comment: '"A product table was removed.", refers to the section and used in the audit trail.',
      });

    case AuditTrailConstants.TYPE_PRODUCTSUM:
      return message({
        id: 'A summation',
        comment: '"A summation was updated.", refers to the section and used in the audit trail.',
      });

    case AuditTrailConstants.TYPE_VIDEO:
      return message({
        id: 'A video',
        comment: '"A video was updated.", refers to the section and used in the audit trail.',
      });

    default:
      return message({
        id: 'A section',
        comment: '"A section was added.", refers to the section and used in the audit trail.',
      });
  }
};

export const boxUpdate = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const type = getRef(event, 'box_type');

  return {
    text: message({
      id: '{section-name} was updated.',
      values: { 'section-name': boxDisplayName(type, message) },
      comment: 'Audit trail message',
    }),
  };
};

export const boxEnable = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const type = getRef(event, 'box_type');

  return {
    text: message({
      id: '{section-name} was added.',
      values: { 'section-name': boxDisplayName(type, message) },
      comment: 'Audit trail message',
    }),
  };
};

export const boxDisable = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const type = getRef(event, 'box_type');

  return {
    text: message({
      id: '{section-name} was removed.',
      values: { 'section-name': boxDisplayName(type, message) },
      comment: 'Audit trail message',
    }),
  };
};

export const boxUpdateLayout = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'The section layout was updated.',
    comment: 'Audit trail message',
  }),
});
