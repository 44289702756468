// @flow

import {
  Editor,
  Transforms,
} from 'slate';
import { getActiveTable } from './table-utils';

export const isTableBordersHidden = (editor: Editor) => {
  const activeTable = getActiveTable(editor);

  if (!activeTable) {
    return false;
  }

  return activeTable.borders === 'hidden';
};

const toggleTableBorders = (editor: any) => {
  const isHidden = isTableBordersHidden(editor);

  Transforms.setNodes(editor, {
    borders: isHidden ? 'visible' : 'hidden',
  }, {
    match: (n) => n.type === 'table',
    mode: 'lowest',
  });
};

export default toggleTableBorders;
