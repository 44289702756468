import { connect, DefaultRootState } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getPositionFromSessionSelector } from 'reducers/session';

import { KPI } from './kpi';
import type { Props } from './kpi';

type StateProps = {
  currentWorkspace: Oneflow.Workspace,
  currentPositionId: number,
};

type OwnProps = Omit<Props, keyof StateProps>;

export const mapStateToProps = (state: DefaultRootState) => {
  const currentUser = getPositionFromSessionSelector(state);
  return ({
    currentWorkspace: getCurrentWorkspaceSelector(state),
    currentPositionId: currentUser.id,
  });
};

const connectedKPI = connect<any, OwnProps, any, any, State, any>(mapStateToProps)(KPI);

export { connectedKPI as KPI };
