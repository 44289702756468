// @flow

import {
  KEY_WEBHOOKS,
} from 'extensions';

const Webhooks = {
  key: KEY_WEBHOOKS,
};

export default Webhooks;
