import { RadioGroup } from 'components/radio-group';
import { RadioGroupItem } from 'components/radio-group-item';
import { documentFrequencyList } from './frequency-option-list';

import style from './frequency-options.module.scss';

type Props = {
  setHowOften: (value: string) => void;
}

const FrequencyOptions = ({ setHowOften }: Props) => (
  <RadioGroup
    customClassName={style.RadioGroupContainer}
    name="documentFrequency"
    onChange={
        (value) => {
          setHowOften(value);
        }
      }
  >
    {documentFrequencyList.map((option) => (
      <RadioGroupItem
        className={style.Option}
        value={option.name}
        key={option.id}
        label={option.label}
      />
    ))}
  </RadioGroup>
);

export default FrequencyOptions;
