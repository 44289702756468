// @flow

import React, { useState } from 'react';
import { Message, localize } from '@oneflowab/pomes';
import clsx from 'clsx';

import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import Copy from 'components/icons/copy';
import AiSparkle from 'components/icons/ai-sparkle';
import style from './ai-assist.module.scss';

type Props = {
  handleInsertText: any,
  handleClose: any,
  handleAbortRequest: any,
  onSubmit: any,
  shouldGenerateButtonBeDisabled: boolean,
  isGeneratedTextEmpty: boolean,
  isInProgress: boolean,
  insertIntoAField: any,
  error: any,
};

const modalKey = 'generate AIAssist text modal';
const sparkleImageOptions = {
  animation: 'https://oneflow.com/app/themes/akandco/public/images/sparkles-animation.gif',
  empty: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
};

const AiAssistActions = ({
  handleInsertText,
  handleClose,
  handleAbortRequest,
  onSubmit,
  shouldGenerateButtonBeDisabled,
  isGeneratedTextEmpty,
  isInProgress,
  insertIntoAField,
  error,
}: Props) => {
  const [playingAnimation, setPlayingAnimation] = useState(false);
  const [stateSparkleImage, setStateSparkleImage] = useState(sparkleImageOptions.empty);
  const className = clsx(
    shouldGenerateButtonBeDisabled
      ? style.GenerateButtonContainer
      : style.ActiveGenerateButtonContainer,
  );

  const onMouseEnter = () => {
    if (!playingAnimation) {
      setStateSparkleImage(sparkleImageOptions.animation);
      setPlayingAnimation(true);
    }

    setTimeout(() => {
      setStateSparkleImage(sparkleImageOptions.empty);
      setPlayingAnimation(false);
    }, 500);
  };

  const renderHandleTextButton = () => {
    if (isGeneratedTextEmpty) {
      return null;
    }

    if (isInProgress) {
      return (
        <Button
          color="yellow"
          data-testid="abort"
          onClick={handleAbortRequest}
        >
          <Message id="Stop generating" comment="Text for button to stop generating the text." />
        </Button>
      );
    }

    if (insertIntoAField) {
      return (
        <Button
          color="yellow"
          data-testid="confirm"
          onClick={handleInsertText}
        >
          <Message id="Insert" comment="Button label used to confirm inserting generated text into a text field." />
        </Button>
      );
    }

    return (
      <Button
        color="yellow"
        data-testid="confirm"
        icon={Copy}
        onClick={handleInsertText}
        disabled={isGeneratedTextEmpty || isInProgress}
      >
        <Message
          id="Insert into document"
          comment="Button label used to confirm inserting generated text into a text field."
        />
      </Button>
    );
  };

  const renderGenerateTextButton = () => {
    let buttonMessage = <Message id="Generate" comment="Text for button to generate text." />;

    if (isInProgress || !isGeneratedTextEmpty || error) {
      buttonMessage = <Message id="Try again" comment="Text for button to generate text." />;
    }

    return (
      <>
        <Button
          kind={isGeneratedTextEmpty ? 'primary' : 'secondary-lite'}
          disabled={shouldGenerateButtonBeDisabled}
          data-testid="submit-comment"
          icon={AiSparkle}
          onClick={onSubmit}
          customClass={clsx(style.SparkleButton,
            (!isGeneratedTextEmpty && !error) && style.SparkleButtonWhite)}
        >
          {buttonMessage}
        </Button>
        {!shouldGenerateButtonBeDisabled && <img className={style.SparkleButtonSparkles} src={stateSparkleImage} alt="" />}
      </>

    );
  };

  return (
    <>
      <CancelButton onClick={handleClose} modalKey={modalKey} />
      <div
        className={className}
        onMouseEnter={onMouseEnter}
      >
        {renderGenerateTextButton()}
      </div>
      {renderHandleTextButton()}
    </>
  );
};

export default localize<Props>(AiAssistActions);
