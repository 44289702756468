import Svg from 'components/svg';

const SeBankId = Svg((
  <g id="Layer_2" data-name="Layer 2">
    <g id="Lager_1" data-name="Lager 1">
      <path className="cls-1" d="M99.7,155.9l13.2-83.2H98.8c-6.6,0-15.1-3.7-17.6-10.5-.8-2.3-2.7-10.2,8.2-17.9,3.9-2.7,6.4-5.7,6.9-8a6.21,6.21,0,0,0-1.8-6.1c-2.4-2.3-7.1-3.6-13.1-3.6-10.1,0-17.2,5.8-17.9,10-.5,3.1,1.9,5.6,4,7.2,6.3,4.7,7.8,11.5,3.9,17.9-4,6.6-12.7,10.9-22,11H35C33.8,80.8,14.2,205,12.7,214.8H90.5c.7-4.3,4.3-27.8,9.2-58.9Z" />
      <path className="cls-1" d="M8.5,243.6H40.4c13.6,0,16.9,6.9,15.9,13.2-.8,5.1-4.3,8.9-10.3,11.4,7.6,2.9,10.6,7.4,9.5,14.5-1.4,8.9-9.1,15.5-19.2,15.5H0Zm21.1,22.6c6.2,0,9.1-3.3,9.7-7.2.6-4.2-1.3-7.1-7.5-7.1H26.3l-2.2,14.3Zm-3.4,23.5c6.4,0,10.1-2.6,11-7.9.7-4.6-1.9-7.3-8.1-7.3H22.9l-2.4,15.3h5.7Z" />
      <path className="cls-1" d="M100.2,298.6c-8.3.6-12.3-.3-14.3-3.9a27.41,27.41,0,0,1-14.5,4.1c-9.4,0-12.7-4.9-11.8-10.3a12.22,12.22,0,0,1,4.3-7.2c5.2-4.5,18-5.1,23-8.5.4-3.8-1.1-5.2-5.8-5.2-5.5,0-10.1,1.8-18,7.2L65,262.4c6.8-4.9,13.4-7.2,21-7.2,9.7,0,18.3,4,16.7,14.6l-1.9,12c-.7,4.2-.5,5.5,4.2,5.6ZM85.8,279.7c-4.4,2.8-12.6,2.3-13.5,8.1a3.86,3.86,0,0,0,4,4.7,15.75,15.75,0,0,0,8.4-2.9,11,11,0,0,1,.2-3.9Z" />
      <path className="cls-1" d="M115.7,255.9h16.6l-.9,5.5c5.3-4.5,9.3-6.2,14.5-6.2,9.3,0,13.6,5.7,12.1,15l-4.3,27.9H137.1l3.6-23.1c.7-4.2-.6-6.2-3.8-6.2-2.6,0-5,1.4-7.3,4.5L125.8,298H109.2Z" />
      <path className="cls-1" d="M171,243.6h16.6l-4.2,26.8,15.9-14.5h20.5l-20.4,18,16.4,24.2H194.9l-12.6-19.6h-.2l-3,19.5H162.5Z" />
      <path className="cls-1" d="M229.7,243.6h19.1l-8.4,54.5H221.3Z" />
      <path className="cls-1" d="M258.1,243.6h27.3c21.1,0,27.2,15.3,25.2,28-1.9,12.4-11.7,26.5-30.2,26.5H249.6Zm17.7,41.5c9.3,0,14.4-4.6,15.9-14.3,1.1-7.2-1.1-14.3-11.4-14.3h-5.1l-4.4,28.6Z" />
      <path className="cls-1" d="M204.3,0H124.8L114.2,67.3h13.5c7.4,0,14.4-3.4,17.4-8.3a8,8,0,0,0,1.4-4.3c0-2.8-1.9-4.9-3.8-6.3-5.2-3.9-6.3-8-6.3-10.9a8.08,8.08,0,0,1,.1-1.6c1.1-7.1,10.7-14.8,23.4-14.8,7.6,0,13.4,1.8,16.9,5.1a11.76,11.76,0,0,1,3.4,11.3c-1.1,5.1-6.2,9.3-9.1,11.4-7.7,5.4-6.7,10.1-6.2,11.5,1.6,4.2,7.7,6.9,12.4,6.9h20.6v.1c28,.2,43,13.1,38.3,43.1-4.4,27.9-25.8,39.9-51.3,40.1L174.8,215h14.9c62.9,0,114.3-40.4,124.4-104.2C326.6,31.6,276.3,0,204.3,0Z" />
    </g>
  </g>
), {
  viewBox: '0 0 316.01 298.8',
  fill: '#193e4f',
});

SeBankId.displayName = 'SeBankId';

export default SeBankId;
