// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { MessageTranslator } from '@oneflowab/pomes';

import apiTokensReducer from 'reducers/entities/api-tokens';

import AddApiToken, { type Props } from './add-api-token';

export const QUERY_NAME = '/admin/account/extensions/api-tokens';

type StateProps = {|
  createState: CreateState,
|};

type FormData = {
  description: string,
};

type DispatchProps = {|
  resetCreateState: () => void,
  onSubmit: FormData => void,
|};

type MainProps = $Diff<Props, {| message: MessageTranslator |}>;

type OwnProps = $Diff<Props, StateProps & DispatchProps & {| message: MessageTranslator |}>;

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  createState: apiTokensReducer.getCreateSelector(state),
});

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  resetCreateState: () => {
    dispatch(apiTokensReducer.createApiTokenReset());
  },
  onSubmit: ({ description }: FormData) => {
    dispatch(apiTokensReducer.createApiToken({
      data: {
        description,
      },
      pipe: {
        action: () => apiTokensReducer.queryApiTokensReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
});

export default connect<MainProps, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(AddApiToken);
