import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import positionsReducer from 'reducers/entities/positions';
import { getPositionFromSessionSelector } from 'reducers/session';

import type { RootState } from 'reducers';
import ChangePasswordModal from './change-password-modal';
import type { FormData, UpdatePasswordParams } from './types';

type DispatchProps = {
  updatePassword: ({ positionId, password, passwordVerification }: UpdatePasswordParams) => void,
  resetUpdateState: (id: number) => void,
};

export const mapStateToProps = (state: RootState) => {
  const position = getPositionFromSessionSelector(state);

  return {
    position,
    updateState: positionsReducer.getUpdateSelector(state, { id: position.id }),
    formActions: {
      start: positionsReducer.actions.types.updateStart,
      success: positionsReducer.actions.types.updateSuccess,
      fail: positionsReducer.actions.types.updateFail,
    },
    sendData: (formData: FormData) => ({
      id: position.id,
      data: {
        password: formData.password,
        passwordVerification: formData.passwordVerification,
      },
    }),
  };
};

type MapDispatchToProps = (dispatch: Dispatch) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  updatePassword: ({ positionId, password, passwordVerification }) => {
    dispatch(positionsReducer.updatePosition({
      id: positionId,
      data: {
        password,
        passwordVerification,
      },
    }));
  },
  resetUpdateState: (positionId) => {
    dispatch(positionsReducer.updatePositionReset({
      id: positionId,
    }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordModal);
