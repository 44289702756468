// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getDateFormat } from 'date';

import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
  isFeatureEnabledSelector,
} from 'reducers/session';
import tagsReducer from 'reducers/entities/tags';

import Contracts, { type Props } from './contracts';

type OwnProps = {|
  workspace: Workspace,
|};

type StateProps = {|
  currentUser: Position,
  dateFormat: string,
|};

type DispatchProps = {|
  queryTags: QueryFunc,
|};

type MapStateToProps = (State, OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { workspace }) => {
  const account = getAccountFromSessionSelector(state);
  const brandingDateFormat = workspace.brandingDateFormat || account.brandingDateFormat;
  const dateFormat = getDateFormat(brandingDateFormat);
  const isTrashEnabled = isFeatureEnabledSelector(state, {
    feature: 'temporaryDocumentTrashCan',
  });

  return {
    currentUser: getPositionFromSessionSelector(state),
    dateFormat,
    isTrashEnabled,
  };
};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  queryTags: ({ pagination }: any) => {
    dispatch(tagsReducer.queryTags({ pagination }));
  },
});

export default connect<Props, any, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(Contracts);
