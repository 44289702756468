import { Message } from '@oneflowab/pomes';
import { useDispatch, useSelector } from 'react-redux';

import agreementsReducer from 'reducers/entities/agreements';

// eslint-disable-next-line import/named
import { RemoveConfirm } from 'components/modals/remove-confirm';
import toast from 'components/toasts';

type Props = {
  setIsModalOpen: (isOpen: boolean) => void;
  documentData: Oneflow.Agreement | null;
  queryName: string;
}

export const DeleteModal = ({ setIsModalOpen, documentData, queryName }: Props) => {
  const dispatch = useDispatch();

  const removeState = useSelector((
    state,
  ) => agreementsReducer.getPermanentDeleteAgreementSelector(state, { id: documentData?.id }));

  const handlePermanentDelete = () => {
    if (documentData) {
      dispatch(agreementsReducer.permanentDeleteAgreement({
        id: documentData.id,
        pipe: {
          action: () => agreementsReducer.queryAgreementsReload({
            name: queryName,
          }),
          onSuccess: () => {
            setIsModalOpen(false);

            toast.success({
              id: `permanent-delete-trashed-document-${documentData.id}`,
              title: <Message
                id=" Document deleted permanently"
                comment="Title for the info message when the user has permanently deleted a document."
              />,
              duration: 5000,
            });
          },
        },
      }));
    }
  };

  const permanentDeleteAgreementReset = () => {
    if (documentData) {
      dispatch(agreementsReducer.permanentDeleteAgreementReset({ id: documentData.id }));
    }
  };

  return (
    <RemoveConfirm
      onConfirm={handlePermanentDelete}
      confirmState={removeState}
      resetConfirmState={permanentDeleteAgreementReset}
      header={(
        <Message
          id="Delete permanently?"
          comment="The title of the permanent delete modal"
        />
      )}
      confirmMessage={(
        <>
          <Message
            id="This document with ID {id} will be permanently deleted and it won’t be possible to restore it."
            comment="The body of the permanent delete modal"
            values={{
              id: documentData?.id,
            }}
          />
        </>
      )}
      isOpen
      modalKey="delete trashed document modal"
      onClose={() => { setIsModalOpen(false); }}
    />
  );
};
