import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hasDraftApprovalFlow, isDraftApprovalFlowRunning } from 'agreement';
import agreementsReducer from 'reducers/entities/agreements';

import { TYPE_IS_INTERNAL_APPROVER } from 'agreement/participant/constants';
import {
  getRunningInternalApproversBlockIndex,
  getMaxAvailableBlockIndex,
} from 'agreement/draft-approval-flow';
import ChevronDownIcon from 'components/icons/chevron-down';
import SelectField from 'components/select-field';

import style from './participant-approve-order.module.scss';

export type Props = {
  participant: Participant;
  agreement: Agreement;
  isEditable: boolean;
  approveOrder: number;
};

const ParticipantApproveOrderComponent: React.FC<Props> = ({
  participant,
  agreement,
  isEditable,
  approveOrder,
}) => {
  const [selected, setSelected] = useState<number>(approveOrder - 1);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setSelected(approveOrder - 1);
    })();
  }, [approveOrder]);

  if (!isEditable) {
    return null;
  }

  const getCurrentApproverOrder = () => (isDraftApprovalFlowRunning(agreement)
    ? getRunningInternalApproversBlockIndex(agreement) + 1 : 0);

  const getApproverOrderOptions = () => {
    const maxApproveOrderOption = getMaxAvailableBlockIndex(agreement) + 1;
    const currentApproverOrder = getCurrentApproverOrder();

    return Array.from({ length: maxApproveOrderOption }, (_, i) => ({
      label: i + 1,
      value: i,
    })).filter((option) => option.label >= currentApproverOrder);
  };

  const updateParticipant = (params) => dispatch(agreementsReducer.updateParticipant({
    id: participant.id,
    data: {
      ...params,
    },
  }));

  const onApproverOrderChange = (value: number) => {
    const flowId = hasDraftApprovalFlow(agreement) ? agreement.draftApprovalFlow.id : null;
    const blockIndex = value || 0;
    updateParticipant({
      agreement: agreement.id,
      type: TYPE_IS_INTERNAL_APPROVER,
      flowId,
      blockIndex,
    });
  };

  const onChange = (item) => {
    setSelected(item.value);
    onApproverOrderChange(item.value);
  };

  const renderDropdownIndicator = () => <ChevronDownIcon height="10px" />;

  const renderEditable = () => {
    const input = {
      onChange,
      name: 'approveOrder',
      value: selected,
    };
    return (
      <SelectField
        options={getApproverOrderOptions()}
        input={input}
        components={{
          DropdownIndicator: renderDropdownIndicator,
        }}
        searchable={false}
        multi={false}
        hideErrorElement
      />
    );
  };

  return (
    <div className={style.ParticipantApproveOrder}>
      {renderEditable()}
    </div>
  );
};

export default ParticipantApproveOrderComponent;
