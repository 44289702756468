// @flow

import React from 'react';
import clsx from 'clsx';
import isString from 'lodash/isString';
import { uniqueId } from 'lodash';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

import { FadeIn } from 'components/transitions';
import { getLocationSelector } from 'reducers/router';
import { matchPath } from 'react-router';
import { useSelector } from 'react-redux';

import style from './sidebar-option.module.scss';
import { SidebarOptionLink } from './sidebar-option-link';

type Props = {
  dataTestId?: string,
  expanded?: boolean,
  IconComponent?: React.ComponentType<IconProps>,
  isDisabled?: boolean,
  isMobile: boolean,
  link: string,
  onItemClick?: () => void,
  tooltipText: string,
  trackable?: TrackableObject,
};

export const renderIcon = ({ IconComponent }) => {
  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent height="20" />
  );
};

const renderSidebarMenuOptionContent = ({
  expanded,
  IconComponent,
  isMobile,
  tooltipText,
  labelElementId,
}: Props) => {
  const menuItemClassNames = clsx(style.MenuItem, {
    [style.Mobile]: isMobile,
  });

  const textClassNames = clsx(style.MenuItemText, {
    [style.WithIcon]: !!IconComponent,
  });

  return (
    <span className={menuItemClassNames}>
      {renderIcon({ IconComponent })}
      {(isMobile || expanded) && tooltipText ? (
        <FadeIn in timeout={0} transitionTime={500}>
          <span className={textClassNames} id={labelElementId}>{tooltipText}</span>
        </FadeIn>
      ) : null}
    </span>
  );
};

const isActiveLink = (location, link) => {
  if (!location) {
    return false;
  }

  if (!link) {
    const checkPath = '/admin/(users|groups|roles|workspaces|data-management|tags|audit-log|account-access|billing|account)';
    return !!(matchPath(location.pathname,
      { path: checkPath })
    );
  }

  if (link === '/admin/account') {
    const accountPath = '/admin/account';
    return !!(matchPath(location.pathname,
      { path: accountPath, exact: true })
    );
  }

  return !!(matchPath(location.pathname, { path: link }));
};

export const isActive = (link) => (match, location) => (
  isActiveLink(location, link)
);

const SidebarMenuOption = ({
  dataTestId,
  expanded,
  IconComponent,
  isMobile,
  isDisabled,
  link,
  onItemClick,
  tooltipText,
  trackable,
}: Props) => {
  const customClasses = clsx(style.SidebarLink, {
    [style.Disabled]: isDisabled,
  });
  const location = useSelector(getLocationSelector);
  const labelElementId = uniqueId('sidebar-option-label-');

  const content = renderSidebarMenuOptionContent({
    expanded,
    IconComponent,
    isMobile,
    tooltipText,
    labelElementId,
  });

  const onClick = React.useCallback(() => {
    if (onItemClick) {
      onItemClick();
    }
    if (trackable && !isActiveLink(location, link)) {
      if (isString(trackable)) {
        amplitudeLogEvent(trackable);
      } else {
        amplitudeLogEvent(trackable.name, trackable.props);
      }
    }
  }, [onItemClick, trackable, location, link]);

  return (
    <SidebarOptionLink
      onClick={onClick}
      link={link}
      activeClassName={style.Active}
      customClasses={customClasses}
      dataTestId={dataTestId}
      isActive={isActive(link)}
      isMobile={isMobile}
      expanded={expanded}
      tooltipText={tooltipText}
      isDisabled={isDisabled}
      labelElementId={labelElementId}
    >
      {content}
    </SidebarOptionLink>
  );
};

SidebarMenuOption.defaultProps = {
  dataTestId: undefined,
  isDisabled: false,
  trackable: undefined,
};

export default SidebarMenuOption;
