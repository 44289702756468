// @flow

import React from 'react';
import clsx from 'clsx';

import ChevronDown from 'components/icons/chevron-down';
import ChevronUp from 'components/icons/chevron-up';
import style from './expandable.module.scss';

type Props = {
  content: any,
  initiallyHidden: boolean,
  showText: string,
  hideText: string,
  withArrow: boolean,
  buttonClasses: string
};

const Expandable = ({
  content,
  initiallyHidden,
  showText,
  hideText,
  withArrow = true,
  buttonClasses = '',
}: Props) => {
  const [hidden, setHidden] = React.useState(initiallyHidden);

  const toggleVisibility = () => {
    setHidden(!hidden);
  };

  const showOpenCloseArrow = () => {
    if (!withArrow) {
      return null;
    }
    if (!hidden) {
      return <ChevronUp className={style.Icon} />;
    }
    return <ChevronDown className={style.Icon} />;
  };

  const classes = clsx({
    [style.Hidden]: hidden,
    [style.Visible]: !hidden,
  });

  return (
    <div className={style.Expandable}>
      <div className={classes}>
        {content}
      </div>
      <button className={buttonClasses} onClick={toggleVisibility}>
        <span data-testid="button-text">{ hidden ? showText : hideText}</span>
        {showOpenCloseArrow()}
      </button>
    </div>
  );
};
export default Expandable;
