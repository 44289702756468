// @flow

import * as React from 'react';
import { type MessageTranslator } from '@oneflowab/pomes';

import { getCurrentHour } from 'date';

import usePositions from 'hooks/use-positions';

import { camelizeAgreement } from 'oneflow-client/agreements';

import addUserLabels from './add-user-labels';

type Props = {
  internalReminderEvent: InternalReminderEvent,
  currentUser: Position,
  message: MessageTranslator,
}

export function useInitialValuesForSelectedEvent(props: Props) {
  const { internalReminderEvent, currentUser, message } = props;
  const contract = camelizeAgreement(internalReminderEvent?.agreement);
  const queryParams = React.useMemo(() => ({
    ids: internalReminderEvent?.customReminderData?.recipients,
  }), [internalReminderEvent?.customReminderData?.recipients]);

  const { data: positions, status } = usePositions(queryParams);

  return React.useMemo(() => {
    const contractFieldOption = { contract, id: contract?.id };
    const eventDate = new Date(internalReminderEvent?.date);
    const eventHour = eventDate.getHours();
    const eventHourString = `${eventDate.toTimeString().split(':')[0]}:00`;

    const initialValues = {
      contractFieldOption,
      date: Math.floor(eventDate.valueOf() / 1000),
      timeFieldOption: {
        time: eventHourString,
        isDisabled: false,
        timeAsInteger: eventHour,
      },
      subject: internalReminderEvent?.customReminderData?.subject,
      message: internalReminderEvent?.customReminderData?.message,
    };

    if (status !== 'success') {
      return initialValues;
    }

    const recipients = positions?.filter(
      (colleague) => internalReminderEvent?.customReminderData.recipients.find(
        (recipientId) => colleague?.id === recipientId,
      ),
    );

    const isCurrentUserRecipient = recipients
      .map((recipient) => recipient.id !== currentUser.id);

    const currentUserIsNotRecipient = isCurrentUserRecipient.every(((recipient) => recipient));

    if (currentUserIsNotRecipient) {
      return {
        ...initialValues,
        recipients: addUserLabels({
          // Currently for contract list view - if currentUser is not part of recipients
          users: recipients,
          creatorId: internalReminderEvent?.customReminderData?.createdBy,
          message,
        }),
      };
    }

    return {
      ...initialValues,
      recipients: addUserLabels({
        users: [
          // Edit modal/Read -only should always include the currentUser
          // We keep the currentUser first
          recipients.find((recipient) => recipient.id === currentUser.id),
          ...recipients.filter((recipient) => recipient.id !== currentUser.id),
        ],
        currentUserId: currentUser.id,
        creatorId: internalReminderEvent?.customReminderData?.createdBy,
        message,
      }),
    };
  }, [
    contract,
    currentUser.id,
    internalReminderEvent?.customReminderData?.createdBy,
    internalReminderEvent?.customReminderData?.message,
    internalReminderEvent?.customReminderData.recipients,
    internalReminderEvent?.customReminderData?.subject,
    internalReminderEvent?.date,
    message,
    positions,
    status,
  ]);
}

type NewEventProps = {
  message: MessageTranslator,
  currentUser: Position,
}

// `const nextHour = getCurrentHour() + 1` will not work!!!
// Must keep it lazy here since it will take module import time
// And the time will not change.
// Laziness help the consumer component or hook take the then current value
// During runtime/render time
export const getNextHour = () => getCurrentHour() + 1;

export const getInitialHour = () => {
  let initialHour;
  const nextHour = getNextHour();
  // If time before 08:00, 08:00 is inital value
  if (nextHour === 24 || nextHour < 8) {
    initialHour = '08:00';
  } else if (nextHour === 9) {
    initialHour = `0${nextHour}:00`;
  } else {
    initialHour = `${nextHour}:00`;
  }

  return initialHour;
};

export function useInitialValuesForNewEvent(props: NewEventProps) {
  const { message, currentUser } = props;

  // Never wrap in useMemo since these values are not changed by the application
  // And the time is external factor and it should be re-calculated
  const initialHour = getInitialHour();
  const nextHour = getNextHour();

  return React.useMemo(() => {
    const currentDate = Math.floor(new Date().valueOf() / 1000);

    const fullnameWithMe = message({
      id: '{user} (you)',
      values: { user: currentUser.fullname },
      comment: 'Label for the user to identify that the selection represents themself',
    });

    const currentUserWithMe = {
      ...currentUser,
      isFixed: true,
      fullname: fullnameWithMe,
    };

    return {
      contractFieldOption: undefined,
      recipients: [currentUserWithMe],
      date: currentDate,
      timeFieldOption: {
        time: initialHour,
        isDisabled: false,
        timeAsInteger: nextHour,
      },
      subject: '',
      message: '',
    };
  }, [message, currentUser, initialHour, nextHour]);
}

type NewEventPropsSelectedContract = {
  message: MessageTranslator,
  currentUser: Position,
  contract: number,
}

export function useInitialValuesForNewEventSelectedContract(props: NewEventPropsSelectedContract) {
  const { message, currentUser, contract } = props;

  // Never wrap in useMemo since these values are not changed by the application
  // And the time is external factor and it should be re-calculated
  const initialHour = getInitialHour();
  const nextHour = getNextHour();

  return React.useMemo(() => {
    const contractFieldOption = { contract, id: contract?.id };

    const currentDate = Math.floor(new Date().valueOf() / 1000);

    const fullnameWithMe = message({
      id: '{user} (you)',
      values: { user: currentUser.fullname },
      comment: 'Label for the user to identify that the selection represents themself',
    });

    const currentUserWithMe = {
      ...currentUser,
      isFixed: true,
      fullname: fullnameWithMe,
    };

    return {
      contractFieldOption,
      recipients: [currentUserWithMe],
      date: currentDate,
      timeFieldOption: {
        time: initialHour,
        isDisabled: false,
        timeAsInteger: nextHour,
      },
      subject: '',
      message: '',
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, currentUser, initialHour, nextHour]);
}

export default {
  forSelectedEvent: useInitialValuesForSelectedEvent,
  forNewEvent: useInitialValuesForNewEvent,
  forNewEventSelectedContract: useInitialValuesForNewEventSelectedContract,
};
