import zod from 'zod';

import Entity from './entity';

const AgreementSignOrder = Entity.extend({
  currentBlock: zod.number().nullish(),
  entries: zod.array(
    zod.object({
      participantId: zod.number().nullish(),
      block: zod.number().nullish(),
    }),
  ).nullish(),
});

export default AgreementSignOrder;
