// @flow

import { decamelizeKeys } from 'humps';
import omit from 'lodash/omit';
import { normalize } from 'normalizr';

import {
  getMultiple,
  extractResponseBodyAsJSON,
  type SortParams,
} from 'oneflow-client/core';
import { roleSchema } from 'oneflow-client/roles';
import { workspaceSchema } from 'oneflow-client/workspaces';
import { bindingSchema } from 'oneflow-client/schema';

const userWorkspacesSchema = {
  collection: [workspaceSchema],
  included: {
    bindings: [bindingSchema],
    roles: [roleSchema],
  },
};

export type NormalizedUserWorkspaces = {
  entities: {
    roles: {
      [number]: Role,
    },
    bindings: {
      [number]: Binding,
    },
    workspaces: {
      [number]: Workspace,
    },
  },
};

type WorkspacesWithBindingsNormalizer = (any) => NormalizedUserWorkspaces;

const normalizeUserWorkspaces: WorkspacesWithBindingsNormalizer = (workspaceData) => {
  const normalized = normalize(workspaceData, userWorkspacesSchema);

  return {
    ...normalized,
    result: normalized.result.collection,
    count: workspaceData.count,
  };
};

type GetUserWorkspaces = ({
  params: {
    positionId: number,
    [string]: any,
  },
  pagination?: {},
  sorting?: SortParams,
}) => Promise<NormalizedUserWorkspaces>;

// eslint-disable-next-line import/prefer-default-export
export const getUserWorkspaces: GetUserWorkspaces = ({
  pagination,
  params,
  sorting = { attr: 'name', direction: 'asc' },
}) => (
  getMultiple({
    url: `/positions/${params.positionId}/collections/bindings/`,
    pagination,
    params: decamelizeKeys(omit(params, 'positionId'), { separator: '_' }),
    sorting,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeUserWorkspaces)
);
