import { isEmpty } from 'lodash';
import type { MenuItems } from 'components/split-button-with-dropdown/types';
import { SaveChangesParticipantModal } from 'components/document-call-to-actions/actions/modals';
import {
  SaveButton,
  DiscardButton,
} from './buttons';

export type FormData = { message: string; subject: string } | Record<string, never>;

type Props = {
  isSaveDropdown?: boolean,
  menuItems?: MenuItems,
  agreementId: Oneflow.Document['id'],
  notifyParticipants?: {
    setModalOpen: (open: boolean) => void,
    modalOpen: boolean,
    onSaveAndNotifyChanges: (data: FormData) => void,
  },
}

const DiscardSaveActions = ({
  isSaveDropdown = false,
  menuItems,
  agreementId,
  notifyParticipants,
}: Props) => (
  <>
    {!isEmpty(notifyParticipants) && isSaveDropdown && (
      <SaveChangesParticipantModal
        agreementId={agreementId}
        onClose={() => notifyParticipants?.setModalOpen?.(false)}
        onSaveAndNotifyChanges={notifyParticipants?.onSaveAndNotifyChanges ?? (() => null)}
        isOpen={notifyParticipants?.modalOpen ?? false}
      />
    )}
    <DiscardButton />
    <SaveButton agreementId={agreementId} isDropdown={isSaveDropdown} menuItems={menuItems} />
  </>
);

export default DiscardSaveActions;
