// @flow

import React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';
import clsx from 'clsx';

import queryString from 'query-string';
import get from 'lodash/get';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

import { useSelector } from 'react-redux';
import agreementsReducer from 'reducers/entities/agreements';
import {
  getAgreementMyParticipant,
  getParticipantParty,
  getGuestToken,
} from 'agreement/selectors';
import { postSignupGuestConversion } from 'oneflow-client/signup';

import ModalForm from 'hocs/modal-form';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import Field from 'components/field';
import TextField, { PasswordField } from 'components/text-field';
import CircularSpinner from 'components/icons/circular-spinner';
import Documents from 'components/icons/documents';
import DocumentsSigned from 'components/icons/documents-signed';
import TermsLink from 'components/terms-link';

import style from './guest-conversion.module.scss';

type FormData = {
  email: string,
  password: string,
};

type Props = {
  message: MessageTranslator,
  agreementId: number,
  onClose?: () => void,
  isSecondStep?: boolean,
  isOpen?: boolean,
  modalKey: string,
};

export const GuestConversion = ({
  message,
  agreementId,
  onClose,
  isSecondStep,
  isOpen,
  modalKey,
}: Props) => {
  const agreement = useSelector(
    (state) => agreementsReducer.getAgreementSelector(state, { id: agreementId }),
  );
  const myParticipant = getAgreementMyParticipant(agreement);
  const myParticipantCompany = getParticipantParty(agreement, myParticipant.agreementCompany.id);
  const guestToken = useSelector((state) => getGuestToken(state));

  const [modalIsOpen, setModalIsOpen] = React.useState(isOpen || false);

  const handleOpen = () => {
    const parsedSearch = queryString.parse(window.location.search);
    const modalQuery = get(parsedSearch, 'm');

    amplitudeLogEvent(
      'Open Guest Conversion Modal',
      {
        location: modalQuery ? 'immediate' : 'sign-flow',
      },
    );
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setModalIsOpen(false);
  };

  const validateIfChecked = (value: boolean) => {
    if (value !== true) {
      return true;
    }

    return undefined;
  };

  const handleSubmit = async ({ password }: FormData) => {
    const { id } = myParticipant;

    await postSignupGuestConversion({
      agreementId,
      participantId: id,
      password,
      guestToken,
    });

    return setTimeout(() => window.location.replace('/'), 0);
  };

  const renderBody = () => {
    const { fullname, email } = myParticipant;
    const { name } = myParticipantCompany;
    const guestConversionClasses = clsx(style.GuestConversion, {
      [style.MoveContent]: isSecondStep,
      [style.FadeIn]: !isSecondStep,
    });
    const documentsClasses = clsx(style.DocumentsIcon, {
      [style.DelayedFadeOut]: isSecondStep,
      [style.Hidden]: isSecondStep,
    });
    const documentsSignedClasses = clsx({
      [style.DelayedFadein]: isSecondStep,
      [style.Hidden]: !isSecondStep,
    });
    const paragraphClasses = clsx(style.CompanyNameParagraph, {
      [style.FadingParagraph]: isSecondStep,
    });
    const fieldContainerClasses = clsx(style.FieldContainer, {
      [style.DelayedFadein]: isSecondStep,
    });

    return (
      <div className={guestConversionClasses}>
        <Documents
          width="106"
          height="104"
          className={documentsClasses}
        />
        <DocumentsSigned
          width="106"
          height="104"
          className={documentsSignedClasses}
        />
        <h2 className={style.Heading}>
          <Message
            id="Want to make your own smooth, digital contracts?"
            comment="Heading in the first step of the Guest Conversion signup flow."
          />
        </h2>
        <p>
          <Message
            id="Create a free Oneflow account and be on your way to the future of contracts! 💫"
            comment="Explanatory paragraph in the first step of the Guest Conversion signup flow."
          />
        </p>
        <p className={paragraphClasses}>
          <Message
            id="Your account will be created under the name of {name} at {company}"
            comment="Explanatory paragraph in the final step of the Guest Conversion signup flow."
            values={{
              name: <strong>{fullname}</strong>,
              company: <strong>{name}</strong>,
            }}
          />
        </p>
        <div className={fieldContainerClasses}>
          <Field
            name="email"
            label={message({
              id: 'Account email',
              comment: 'Input field for the users email',
            })}
            component={TextField}
            required
            initialValue={email}
            disabled
          />
          <Field
            name="password"
            label={message({
              id: 'Create password',
              comment: 'Input field for the users password',
            })}
            component={PasswordField}
            minLength={12}
            maxLength={72}
            required
          />
          <Field
            name="confirmTerms"
            type="checkbox"
            validate={validateIfChecked}
            label={<TermsLink />}
            component={Checkbox}
            required
          />
        </div>
      </div>
    );
  };

  const getActions = ({ formProps }: FormRenderProps) => {
    const disabled = Boolean(
      formProps.submitting
      || formProps.validating
      || formProps.invalid,
    );

    return (
      <div className={style.Buttons}>
        <Button
          kind="linkSeparate"
          onClick={handleClose}
        >
          <Message
            id="Cancel"
            comment="Button text in the Guest Conversion signup flow"
          />
        </Button>
        <Button
          kind="primary"
          onClick={formProps.handleSubmit}
          icon={formProps.submitting ? CircularSpinner : undefined}
          disabled={disabled}
        >
          <Message
            id="Create"
            comment="Button text in the Guest Conversion signup flow"
          />
        </Button>
      </div>
    );
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Document signing"
          comment="Modal title when the signing has been finished by all parties."
        />
      )}
      body={renderBody()}
      actions={getActions}
      formState={{}}
      resetFormState={() => {}}
      onSubmit={handleSubmit}
      isOpen={modalIsOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      modalKey={modalKey}
    >
      {() => (<></>)}
    </ModalForm>
  );
};

export default localize<Props>(GuestConversion);
