import { useState } from 'react';

import { isDeviceWithoutHover } from 'utils/browser';

import Tooltip from 'components/tooltip';

import style from './overlay-signature-field.module.scss';
import { SignedParticipantTooltip } from './signed-participant-tooltip';

type Props = {
  agreement: Oneflow.Agreement,
  participant: Oneflow.Participant,
  fullName: string,
  signatureImage: {
    data?: string | null;
    mimetype?: 'image/png' | 'image/svg+xml' | null;
    type?: 'HANDWRITTEN_TYPED' | 'HANDWRITTEN_DRAWN' | 'STANDARD' | 'NONE' | null;
  },
}

const Signature = ({
  agreement, participant, fullName, signatureImage,
}: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { data, mimetype } = signatureImage;
  const src = `data:${mimetype};base64,${data}`;
  return (
    <Tooltip
      message={(
        <SignedParticipantTooltip
          agreement={agreement}
          participant={participant}
        />
          )}
      messageClassName={style.ParticipantTooltipMessage}
      key={participant.id}
      theme="light"
      zIndex="10000"
      sideOffset={16}
      side="top"
      open={isDeviceWithoutHover() ? isTooltipOpen : undefined}
      onPointerDownOutside={() => {
        if (isDeviceWithoutHover()) {
          setIsTooltipOpen(false);
        }
      }}
    >
      <img
        src={src}
        className={style.SignatureContent}
        alt={`Signature for ${fullName}`}
      />
    </Tooltip>
  );
};

export { Signature };
