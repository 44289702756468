/* eslint-disable import/named */
/* eslint-disable import/no-named-as-default */
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { MessageTranslator, localize } from '@oneflowab/pomes';
import {
  debounce, truncate,
} from 'lodash';
import clsx from 'clsx';

import { isSmallScreenWidth } from 'ui/config';
import { getGuestToken, getAgreementCounterparties } from 'agreement/selectors';
import useAgreement from 'hooks/use-agreement';
import useCurrentContractId from 'hooks/use-current-contract-id';
import { isDraft } from 'agreement';

import { useCollapsedDocumentLayout } from 'components/document-layout-container/collapsed-document-layout/context';
import Message from 'components/message';
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';
import { Popover, PopoverContent, PopoverPortal } from 'components/popover';
import AtSignIcon from 'components/icons/at-sign';
import CheckIcon from 'components/icons/new-check';
import Button from 'components/button';

import style from './recipient-selector-menu.module.scss';

const renderParticipantGroupLabel = (isGuest: boolean, isPrivate: boolean) => {
  if (isGuest) {
    return null;
  }
  let message = (
    <Message
      id="Internal participants"
      comment="Label showing in the selector of a comment recipient indicating that the comment will be visibile only for colleagues"
    />
  );
  if (!isPrivate) {
    message = (
      <Message
        id="External participants"
        comment="Label showing in the selector of a comment recipient indicating that the comment will be public and visible for all"
      />
    );
  }

  return (
    <p className={style.ParticipantGroupLabel}>{message}</p>
  );
};

type Participant = {
  id: number,
  isPrivate: boolean,
  label: string,
  partyName?: string,
}

const filterParticipants = (participant: Participant, searchValue: string) => {
  if (!searchValue) return false;

  const participantLabel = participant.label.toLowerCase();
  const searchValueLower = searchValue.toLowerCase();
  return participantLabel.includes(searchValueLower);
};

type Props = {
  privateParticipants: Participant[],
  publicParticipants: Participant[],
  onReceiverChanged: (arg0: { value: number, isPrivate: boolean }) => void,
  disabled: boolean,
  recipient: number,
  actionsContainerRef: React.RefObject<HTMLDivElement>,
  message: MessageTranslator,
  setOpen: (arg0: boolean) => void,
  isOpen: boolean,
  handleOnReceiverChanged: () => void,
  isChatMessage: boolean,
  isInteractive: boolean,
}

const RecipientSelectorMenu = ({
  onReceiverChanged,
  disabled,
  privateParticipants,
  publicParticipants,
  recipient,
  actionsContainerRef,
  handleOnReceiverChanged,
  message,
  setOpen,
  isOpen,
  isChatMessage,
  isInteractive,
}: Props) => {
  const guestToken = useSelector(getGuestToken);
  const agreementId = useCurrentContractId();
  const agreement = useAgreement(agreementId);
  const counterparties = getAgreementCounterparties(agreement);
  const isSmallScreen = isSmallScreenWidth();
  const {
    mentionParticipantsSelectorRef,
    setIsRecipientSelectorOpen,
  } = useCollapsedDocumentLayout();
  const [filteredParticipants, setFilteredParticipants] = useState<Participant[]>([]);

  const renderParticipantItem = (participant: Participant, isResult: boolean) => (
    <Button
      onClick={(event) => {
        event.stopPropagation();
        onReceiverChanged({
          value: participant.id,
          isPrivate: participant.isPrivate,
        });
        setOpen(false);
        setIsRecipientSelectorOpen(false);
        handleOnReceiverChanged();
      }}
      customClass={style.ParticipantItem}
      key={participant.id}
    >
      <p>{truncate(participant.label, { length: 35 })}</p>
      {participant.id === recipient && !isResult && <CheckIcon />}
    </Button>
  );

  const renderSelectorContent = () => (
    <>
      <form
        className={style.SearchContainer}
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()}
      >
        <input
          className={clsx(style.SearchField, {
            [style.HasResults]: filteredParticipants.length > 0,
          })}
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
            const debouncedInputHandler = debounce(() => {
              const allParticipants = [...privateParticipants, ...publicParticipants];
              const searchResults = allParticipants.filter((participant) => (
                filterParticipants(participant, event?.target.value)
              ));
              setFilteredParticipants(searchResults);
            }, 200);
            debouncedInputHandler();
          }}
          placeholder={message({
            id: 'Search participants',
            comment: 'Placeholder for the search field in the recipient selector',
          })}
        />
        <div className={style.SearchResults}>
          {filteredParticipants.map((participant) => renderParticipantItem(participant, true))}
        </div>
      </form>
      {renderParticipantGroupLabel(guestToken, true)}
      {privateParticipants.map((participant) => renderParticipantItem(participant, false))}
      {!privateParticipants.length && !guestToken && (
      <p className={style.EmptyStateText}>
        <Message
          id="To mention colleagues, add them to the document"
          comment="Message in the mention selector in the messaging feature, when there are no internal parties added"
        />
      </p>
      )}
      {renderParticipantGroupLabel(guestToken, false)}
      {publicParticipants.map((participant) => renderParticipantItem(participant, false))}
      {!publicParticipants.length && (
      <p className={style.EmptyStateText}>
        {counterparties.length && isDraft(agreement) ? (
          <Message
            id="This document is a draft. To mention counterparties, send the document."
            comment="Message in the mention selector in the messaging feature, when there are no external parties added"
          />
        ) : (
          <Message
            id="To mention counterparties, add them to the document"
            comment="Message in the mention selector in the messaging feature, when there are no external parties added"
          />
        )}
      </p>
      )}
    </>
  );

  const renderSelectorMenu = () => (
    <div
      className={clsx(style.Content, ({
        [style.MobileRecipientSelectorMenu]: isSmallScreen,
      }))}
    >
      {renderSelectorContent()}
    </div>
  );

  if (isSmallScreen && !isChatMessage && !isInteractive) {
    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!isOpen) {
        setFilteredParticipants([]);
      }
      setOpen(!isOpen);
      setIsRecipientSelectorOpen(!isOpen);
    };
    return (
      <>
        <Button
          customClass={clsx(style.MentionButton, {
            [style.ActiveButton]: isOpen,
          })}
          icon={<AtSignIcon height="30px" />}
          disabled={disabled}
          onClick={handleButtonClick}
        />
        {isOpen && mentionParticipantsSelectorRef.current && ReactDOM.createPortal(
          renderSelectorMenu(),
          mentionParticipantsSelectorRef.current,
        )}
      </>
    );
  }

  return (
    <Popover open={isOpen}>
      <PopoverTriggerButton
        icon={<AtSignIcon height="30px" />}
        disabled={disabled}
        onClick={(event) => {
          event.stopPropagation();
          setOpen(true);
        }}
      />
      <PopoverPortal container={actionsContainerRef?.current}>
        <PopoverContent
          side="top"
          align="end"
          arrowHeight={0}
          className={style.Content}
          onInteractOutside={() => setOpen(false)}
          onEscapeKeyDown={() => setOpen(false)}
          onCloseAutoFocus={() => setFilteredParticipants([])}
        >
          {renderSelectorContent()}
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

export default localize(RecipientSelectorMenu);
