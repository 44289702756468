// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import accountsReducer from 'reducers/entities/accounts';
import { getAdjustedCurrentLanguageSelector } from 'reducers/i18n';

import { BuySeatsModal } from './buy-seats';
import type { Props, FormData } from './buy-seats';

type StateProps = {|
  country: string,
  language: EnabledLanguages,
  updateState: RpcState,
|};

type DispatchProps = {|
  onSubmit: FormData => void,
  resetUpdateState: () => void,
|};

export type OwnProps = $Diff<Props, StateProps & DispatchProps> & {
  accountId: number,
};

type MapStateToProps = (State, OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state: State, ownProps: OwnProps) => {
  const { accountId } = ownProps;
  const account = accountsReducer.getAccountSelector(state, { id: accountId });
  const updateState = accountsReducer.getBuySeatsSelector(state, {
    id: accountId,
  });

  return {
    country: account.country,
    language: getAdjustedCurrentLanguageSelector(state),
    updateState,
  };
};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (
  dispatch: Dispatch<*>,
  ownProps: OwnProps,
) => ({
  onSubmit: ({ numberOfSeats }) => {
    dispatch(accountsReducer.buySeats({
      id: ownProps.accountId,
      data: {
        numberOfSeats,
        amplitudeScope: 'upgrade seats modal',
      },
    }));
  },
  resetUpdateState: () => {
    dispatch(accountsReducer.buySeatsReset({
      id: ownProps.accountId,
    }));
  },
});

type ContainerProps = {|
  ...Props,
  accountId: number,
|};

const connectedBySeats = connect<
ContainerProps, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(BuySeatsModal);

export { connectedBySeats as BuySeatsModal };
