// @flow

import {
  KEY_UPSALES2,
} from 'extensions';

const Upsales = {
  key: KEY_UPSALES2,
  typeSpecificConfigKeys: [
    'upsalesCustomerId',
  ],
};

export default Upsales;
