import { Message } from '@oneflowab/pomes';

import Delete from 'components/icons/delete';
import { BulkActionButton } from 'components/buttons';

type Props = {
  disabled?: boolean,
  onClick: () => void,
}

// You probably should not need to customize label or icon,
// all DeleteBulkAction should only use the "Delete" label.
// Rather create a specific menu item if needed, e.g. MoveToTrashBulkAction
export const DeleteBulkAction = ({
  disabled,
  onClick,
}: Props) => (
  <BulkActionButton
    disabled={disabled}
    label={(
      <Message
        id="Delete"
        comment="Label for delete in bulk actions menu"
      />
    )}
    onClick={onClick}
    icon={Delete}
  />
);
