import { useSelector } from 'react-redux';
import { getAnnotationsMap } from 'reducers/editor';

const useAnnotationsMap = () => {
  const annotationsMap = useSelector(getAnnotationsMap);

  return annotationsMap;
};

export default useAnnotationsMap;
