// @flow

import { Editor, Element as SlateElement, Range } from 'slate';
import head from 'lodash/head';

import { isEditorActiveAt } from '../plugin-utils';

export const findActiveTableCell = (editor: any) => {
  try {
    const [match] = Editor.nodes(editor, {
      match: (n) => (
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table-cell'
      ),
      mode: 'lowest',
    });

    return match;
  } catch {
    return null;
  }
};

export const getActiveTableCell = (editor: any) => {
  const match = findActiveTableCell(editor);

  if (!match) {
    return null;
  }

  return match[0];
};

export const findActiveTableRow = (editor: any) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table-row'
    ),
    mode: 'lowest',
  });

  return match;
};

export const getActiveTableRow = (editor: any) => {
  const match = findActiveTableRow(editor);

  if (!match) {
    return null;
  }

  return match[0];
};

export const findActiveTableBody = (editor: any) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table-body'
    ),
    mode: 'lowest',
  });

  return match;
};

export const getActiveTableBody = (editor: any) => {
  const match = findActiveTableBody(editor);

  if (!match) {
    return null;
  }

  return match[0];
};

export const findActiveTableHead = (editor: any, selection?: any) => {
  if (!isEditorActiveAt(editor, selection)) {
    return undefined;
  }

  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table-head'
    ),
    mode: 'lowest',
    at: selection,
  });

  return match;
};

export const getActiveTableHead = (editor: any, selection?: any) => {
  const match = findActiveTableHead(editor, selection);

  if (!match) {
    return null;
  }

  return match[0];
};

export const findActiveTable = (editor: any) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table'
    ),
    mode: 'lowest',
  });

  return match;
};

export const getActiveTable = (editor: any) => {
  const match = findActiveTable(editor);

  if (!match) {
    return null;
  }

  return match[0];
};

export const getNextRow = (editor, path) => {
  const match = Editor.next(editor, { at: path });

  if (!match) {
    return [null, []];
  }

  return match;
};

const findTheClosestTable = (editor, path) => {
  const [parentElement, parentPath] = Editor.parent(editor, path);

  if (parentElement.type === 'table') {
    return [parentElement, parentPath];
  }

  const [grandParentElement, grandParentPath] = Editor.parent(editor, parentPath);

  if (grandParentElement.type === 'table') {
    return [grandParentElement, grandParentPath];
  }

  return [];
};

export const isEndOfTable = (editor, cell) => {
  if (!cell) {
    return null;
  }
  const [, activeTableCellPath] = cell;
  const [, activeTableRowPath] = Editor.parent(editor, activeTableCellPath);

  const isLastRow = head(getNextRow(editor, activeTableRowPath)) === null;
  const isLastCell = Editor.next(editor, { at: activeTableCellPath }) === undefined;

  const [, activeTablePath] = findTheClosestTable(editor, activeTableRowPath);
  const tableHasNoSibling = Editor.next(editor, { at: activeTablePath }) === undefined;

  if (isLastRow && isLastCell
    && Editor.isEnd(editor, editor.selection.focus, activeTableCellPath)
    && Range.isCollapsed(editor.selection)
    && tableHasNoSibling
  ) {
    return activeTablePath;
  }

  return null;
};
