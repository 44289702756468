// @flow

import React from 'react';
import urlJoin from 'url-join';
import { Redirect, type Match } from 'react-router';

import adminPage from 'hocs/admin-page';
import type { PropsMapper } from 'hocs/admin-page/admin-page';

import Details from './details';
import Permissions from './permissions';

type Props = {
  match: Match,
};

export const RoleDetailsComponent = (props: Props) => (
  <Redirect from={props.match.path} to={urlJoin(props.match.url, '/details')} />
);

export const getInitial = (name: string) => {
  if (!name) {
    return '';
  }

  const firstInitial = name.charAt(0).toUpperCase();

  return firstInitial;
};

export const getTitle = (role: Role) => {
  if (!role) {
    return '';
  }

  return role.name;
};

export const propsMapper: PropsMapper = ({
  props: {
    role,
    message,
    queryRoles,
  },
}) => {
  const modules = [
    {
      path: '/details',
      title: message({ id: 'Details', comment: 'Used in the breadcrumb and title on the role page.' }),
      section: message({
        id: 'Roles',
        comment: 'Used as the title for the section.',
      }),
      component: Details,
    },
    {
      path: '/permissions',
      title: message({ id: 'Permissions', comment: 'Used in the breadcrumb and title on the role page.' }),
      section: message({
        id: 'Roles',
        comment: 'Used as the title for the section.',
      }),
      component: Permissions,
    },
  ];

  return ({
    title: getTitle(role),
    hide: getTitle(role) === '',
    showAsLink: false,
    modules: [modules],
    componentDidMount: () => {
      queryRoles({});
    },
  });
};

export default adminPage(propsMapper)(RoleDetailsComponent);
