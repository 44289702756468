import Button from 'components/button';
import Confirmable from 'components/confirmable';
import { Message } from '@oneflowab/pomes';

import BankIDLogoIcon from 'components/icons/bankid-logo';

import style from './error.module.scss';

type Props = {
  handleClose: () => void,
  handlePrevious: () => void,
};

const ErrorStep = ({ handleClose, handlePrevious }: Props) => {
  const renderErrorActions = () => (
    <div className={style.ActionsWrapper}>
      <Button
        data-testid="error-action-cancel"
        onClick={handleClose}
        customClass={style.Cancel}
      >
        <Message
          id="Cancel"
          comment="Label for cancel button that closes the sign-contract modal"
        />
      </Button>
      <Button
        kind="secondary"
        data-testid="error-action-try-again"
        onClick={handlePrevious}
      >
        <Message
          id="Try Again"
          comment="Label for button that takes the user to the previous step to retry the signing process"
        />
      </Button>
    </div>
  );

  const renderBody = () => (
    <div className={style.ErrorWrapper}>
      <div className={style.IconWrapper}>
        <BankIDLogoIcon height="160px" />
      </div>
      <div className={style.ErrorMessageWrapper}>
        <p className={style.ErrorMessageTitle}>
          <Message
            id="The BankID service timed out while waiting."
            comment="Error message header when QR code to scan has expired"
          />
        </p>
        <p className={style.ErrorMessageText}>
          <Message
            id="Please try signing your document again."
            comment="Error message call to action to try signing again"
          />
        </p>
      </div>
    </div>
  );

  return (
    <Confirmable
      header={(
        <Message id="Document signing" comment="Confirm modal title for signing a contract." />
      )}
      body={renderBody()}
      actions={renderErrorActions}
      onEnter={handlePrevious}
      onClose={handleClose}
      isOpen
      modalKey="sign contract intent modal"
    />
  );
};

export default ErrorStep;
