// @flow

import {
  Editor,
  Transforms,
  Element as SlateElement,
  Range,
} from 'slate';
import { ReactEditor } from 'slate-react';
import { isEditorActiveAt } from 'components/rich-text-editor/editor-plugins/plugin-utils';

export const getText = (url) => {
  if (url?.includes('mailto:')) {
    return url.replace('mailto:', '').split('?')[0];
  }
  return url;
};

export const isLinkActive = (editor: any) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });

  return !!link;
};

export const getActiveLinkNode = (editor: any, selection: any) => {
  if (!isEditorActiveAt(editor, selection)) {
    return null;
  }

  const [linkWithPath] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    at: selection,
  });

  if (!linkWithPath) {
    return null;
  }

  return linkWithPath[0];
};

export const unwrapLink = (editor: any) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

export const wrapLink = (editor: any, url: string) => {
  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  if (isLinkActive(editor)) {
    if (isCollapsed) {
      const activeLinkNode = getActiveLinkNode(editor, selection);

      if (activeLinkNode) {
        const updatedProps = { url };
        Transforms.setNodes(editor, updatedProps, {
          at: ReactEditor.findPath(editor, activeLinkNode),
        });
      }
      return;
    }
    unwrapLink(editor);
  }

  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: getText(url) }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const insertLink = (editor: any, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};
