import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';

import { getGuestToken } from 'agreement/selectors';
import { getPositionFromSessionSelector } from 'reducers/session';
import { getPDFContractURL } from 'utils/download-pdf-link';

import DownloadIcon from 'components/icons/new-download';
import Tooltip from 'components/tooltip';
import style from './download-button.module.scss';

type Props = {
  agreement: Oneflow.Agreement
}

const DownloadButton = ({ agreement }: Props) => {
  const guestToken = useSelector(getGuestToken);
  const position = useSelector(getPositionFromSessionSelector);

  const pdfContractURL = guestToken && getPDFContractURL(agreement, position, guestToken);

  return (
    <Tooltip
      message={(
        <Message
          id="Download PDF"
          comment="Tooltip message for button that will download a pdf."
        />
      )}
      theme="oneflow"
      side="top"
      messageClassName={style.TooltipMessage}
      sideOffset={7.5}
    >
      <a
        href={pdfContractURL}
        className={style.DownloadPDF}
        target="_blank"
        rel="noopener noreferrer"
      >
        <DownloadIcon height="16px" />
      </a>
    </Tooltip>
  );
};

export default DownloadButton;
