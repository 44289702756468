import { useSelector } from 'react-redux';

import { getCurrentContractId } from 'reducers/current-contract';
import { getAccountFromSessionSelector } from 'reducers/session';
import { isFreemium } from 'account';
import { isPublished } from 'agreement';

import useAgreement from 'hooks/use-agreement';

const usePremiumBoxesDisabled = () => {
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const account = useSelector(getAccountFromSessionSelector);

  return isFreemium(account) && isPublished(agreement);
};

export default usePremiumBoxesDisabled;
