// @flow

import * as React from 'react';

import { localize, Message } from '@oneflowab/pomes';
import ModalForm from 'hocs/modal-form';

import {
  WebhookUrlField,
  WebhookSignKeyField,
} from 'components/fields';
import WebhookSignature from 'components/webhook-signature';
import WebhookUrlResponse from 'components/webhook-url-response';

export type CreateWebhook = {
  url: string,
  signKey?: string,
}

export type Props = {
  createWebhook: CreateWebhook => void,
  createState: CreateState,
  resetCreateState: () => void,
  children: React.Node,
  verifyWebhookUrl: string => void,
};

export const AddWebhookFields = ({
  createWebhook,
  createState,
  resetCreateState,
  children,
  verifyWebhookUrl,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Create webhook"
        comment="Modal title for creating a new webhook"
      />
    )}
    body={(
      <div>
        <WebhookUrlField verifyWebhookUrl={verifyWebhookUrl} autoFocus />
        <WebhookUrlResponse />
        <WebhookSignature />
        <WebhookSignKeyField />
      </div>
    )}
    onSubmit={createWebhook}
    resetFormState={resetCreateState}
    formState={createState}
    modalKey="create webhook modal"
  >
    {children}
  </ModalForm>
);

export default localize<Props>(AddWebhookFields);
