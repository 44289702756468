import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import SetContractValueModal from 'components/modals/set-contract-value';
import { MenuItem } from 'components/menu-item';
import Money from 'components/icons/money';

export type Props = {
  agreement: Oneflow.Agreement,
  isAllowedToEdit: boolean,
};

export type FormData = {
  contractValue?: number,
};

export default class SetContractValue extends React.PureComponent<Props> {
  getChildren = (onClick: React.MouseEventHandler) => {
    const { isAllowedToEdit } = this.props;

    return (
      <MenuItem
        icon={Money}
        label={(
          <Message
            id="Set value"
            comment="Label for action to set contract value on a contract."
          />
        )}
        disabled={!isAllowedToEdit}
        onClick={onClick}
      />
    );
  };

  render() {
    const { agreement } = this.props;

    return (
      <SetContractValueModal agreement={agreement}>
        {this.getChildren}
      </SetContractValueModal>
    );
  }
}
