import 'normalize.css';

import React from 'react';
import { Provider } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import { ConnectedRouter } from 'connected-react-router';
import I18n from '@oneflowab/pomes';

import store from 'store';
import history from 'store/history';
import getCSSPropertyValue from 'utils/get-css-property-value';

import AppTitle from 'components/app-title';
import SessionPing from 'components/session-ping';
import App from 'components/legacy/app';
import ErrorBoundary from 'components/error-boundary';
import IphoneMetaTags from 'components/iphone-meta-tags/iphone-meta-tags';
import FaviconOneflow from 'components/favicon';
import translations from 'translations';

const helmetContext = {};

const Root = () => (
  <Provider store={store}>
    <I18n translations={translations}>
      <ErrorBoundary>
        <HelmetProvider context={helmetContext}>
          <FaviconOneflow />
          <IphoneMetaTags />
          <Helmet>
            <meta name="theme-color" content={getCSSPropertyValue('--of-forest-green-01')} data-rh="true" />
          </Helmet>
          <AppTitle />
          <SessionPing />
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </HelmetProvider>
      </ErrorBoundary>
    </I18n>
  </Provider>
);

export default Root;
