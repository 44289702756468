// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

const listItemsWithAnd = (list: Array<string>) => {
  if (!Array.isArray(list)) {
    return null;
  }

  const lastItem = list[list.length - 1];
  const withoutLast = list.slice(0, -1);

  if (withoutLast.length === 0) {
    return lastItem;
  }

  return (
    <Message
      id="{comma-list} and {final-item}"
      comment="A list of names separated by and and comma."
      values={{
        'comma-list': withoutLast.join(', '),
        'final-item': lastItem,
      }}
    />
  );
};

export default listItemsWithAnd;
