// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { MenuItem } from 'components/menu-item';
import Download from 'components/icons/download';

import style from './download-pdf.module.scss';

export type Props = {|
  disabled?: boolean,
  onClick: Function,
  href: string,
|};

export const DownloadPdfMenuItem = ({
  disabled,
  onClick,
  href,
}: Props) => (
  <MenuItem
    icon={Download}
    label={(
      <Message
        id="Download as PDF"
        comment="Label for download as pdf action in menu."
      />
    )}
    disabled={disabled}
    onClick={onClick}
    download
    href={href}
    className={style.DownloadButton}
  />
);

DownloadPdfMenuItem.defaultProps = {
  disabled: undefined,
};
