// @flow

import * as React from 'react';
import moment from 'moment';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import Modal from 'components/modal';
import TimeReset from 'components/icons/time-reset';
import sessionStorage from 'utils/session-storage';

import style from './session-expiration-warning.module.scss';

const SESSION_EXPIRE_WARNING_TIME = 300;

type Props = {
  expire?: Object,
  successState: any,
  refresh: () => null,
  logout: () => null,
  setRefreshSuccess: () => null,
};

type State = {
  isOpen: boolean,
  timeRemaining: number | typeof undefined,
  hasClosedSessionExpirationWarning: boolean,
};

export class SessionExpirationWarning extends React.Component<Props, State> {
  static defaultProps = {
    expire: undefined,
  }

  state = {
    isOpen: false,
    timeRemaining: undefined,
    hasClosedSessionExpirationWarning: false,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isOpen, hasClosedSessionExpirationWarning } = this.state;
    const { expire, successState, setRefreshSuccess } = this.props;

    if (successState && successState !== prevProps.successState) {
      setRefreshSuccess();
      this.handleRefreshSuccess();
      return;
    }

    if (!expire || hasClosedSessionExpirationWarning) {
      return;
    }

    const { expires, server } = expire;
    const timeRemaining = moment(expires).diff(server, 'seconds');

    if (
      !prevState.isOpen
      && !isOpen
      && !hasClosedSessionExpirationWarning
      && timeRemaining <= SESSION_EXPIRE_WARNING_TIME
    ) {
      this.handleOpen(timeRemaining);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.setSessionTimeout);
  }

  handleRefresh = () => {
    const { refresh } = this.props;

    clearInterval(this.interval);

    this.setState({
      isOpen: false,
      timeRemaining: undefined,
    });
    refresh();
  }

  handleRefreshSuccess = () => {
    clearTimeout(this.setSessionTimeout);
    sessionStorage.removeItem('session_timedout');

    this.setState({
      isOpen: false,
      timeRemaining: undefined,
    });
  }

  handleOpen = (timeRemaining: number) => {
    this.setState({
      isOpen: true,
      timeRemaining,
    }, () => {
      this.interval = setInterval(() => {
        this.setState((prevState) => ({
          timeRemaining: prevState.timeRemaining - 1,
        }));
      }, 1000);
      this.setSessionTimeout = setTimeout(() => {
        sessionStorage.setItem('session_timedout', true);
      }, (timeRemaining) * 1000);
    });
  };

  getTimeRemaining = () => {
    const { timeRemaining } = this.state;
    const { logout } = this.props;

    if (timeRemaining <= 0) {
      this.handleClose();
      logout();
    }

    const duration = moment.duration(timeRemaining, 'seconds');
    return moment.utc(duration.as('milliseconds')).format('mm:ss', { trim: false });
  };

  handleClose = () => {
    clearInterval(this.interval);
    this.setState({
      isOpen: false,
      timeRemaining: undefined,
      hasClosedSessionExpirationWarning: true,
    });
  }

  render() {
    const { isOpen } = this.state;

    return (
      <Modal
        header={(
          <Message
            id="Are you still there?"
            comment="Modal title for session expiration warning modal."
          />
        )}
        isOpen={isOpen}
        onCancel={this.handleClose}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        modalKey="session expiration warning modal"
      >
        <Modal.Body>
          <div className={style.SessionExpiration}>
            <TimeReset height="64px" className={style.Icon} />
            <p>
              <Message
                id="Your session is about to expire."
                comment="Modal body text for session expiration warning modal."
              />
            </p>
            <p>
              <Message
                id="You will be logged out in {timeRemaining}"
                comment="Modal body text for session expiration warning modal."
                values={{
                  timeRemaining: this.getTimeRemaining(),
                }}
              />
            </p>
          </div>
        </Modal.Body>
        <Modal.Actions>
          <Button
            kind="primary"
            onClick={this.handleRefresh}
          >
            <Message
              id="Stay logged in"
              comment="Button text in the session expiration warning modal."
            />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
