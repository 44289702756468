// @flow
import * as React from 'react';
import { localize, Message } from '@oneflowab/pomes';

import Delete from 'components/icons/delete';
import Button from 'components/button';

import style from './remove-participant.module.scss';

type Props = {
  onEnterConfirmationState: () => void,
  isConfirmationState: boolean,
}

export const RemoveParticipant = ({
  onEnterConfirmationState,
  isConfirmationState,
}: Props) => {
  const enterConfirmationState = () => {
    onEnterConfirmationState();
  };

  if (isConfirmationState) {
    return (
      <div className={style.ConfirmationMessage}>
        <Message
          id="Are you sure you want to remove the participant?"
          comment="Warning to remove a participant in the participant modal"
        />
      </div>
    );
  }

  return (
    <div className={style.RemoveParticipant}>
      <Button
        icon={Delete}
        onClick={enterConfirmationState}
      >
        <Message
          id="Remove participant"
          comment="Button label to remove a participant in the participant modal"
        />
      </Button>
    </div>
  );
};

export default localize<Props>(RemoveParticipant);
