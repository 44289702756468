import Message from 'components/message';

import { SetDate } from 'components/contract-boxes/duration-box/set-date';
import { SetTimeSpan } from 'components/contract-boxes/duration-box/set-time-span';
// eslint-disable-next-line import/named
import { useDurationBoxProps } from 'contexts/duration-box-props';

export const RecurringWithTwoPeriods = () => {
  const {
    endDate,
    startDate,
  } = useDurationBoxProps();

  const renderStartDateMessage = () => {
    if (!startDate) {
      return null;
    }

    return (
      <>
        <br />
        <Message
          id="The start date for the contract is {setStartDateComponent}."
          comment="Text to show the start date of contract."
          values={{
            setStartDateComponent: <SetDate dateType="startDate" />,
          }}
        />
      </>
    );
  };

  const renderDuration = () => {
    if (!endDate) {
      return (
        <Message
          id="The contract is valid for an initial period of {SetDurationComponent}. The contract applies thereafter until further notice with a notice period of {SetNoticePeriodComponent}."
          comment="Text to show the contract is valid for specific initial period. validityPeriod can be, for instance, '3 months'"
          values={{
            SetDurationComponent: <SetTimeSpan toSet="duration" />,
            SetNoticePeriodComponent: <SetTimeSpan toSet="noticePeriod" />,
          }}
        />

      );
    }

    return (
      <Message
        id="The initial contract period lasts until {setEndDateComponent}. The contract applies thereafter until further notice. {breakLine} The notice period for canceling the contract is {SetNoticePeriodComponent}."
        comment="Text to show contract applies thereafter until further notice."
        values={{
          setEndDateComponent: <SetDate dateType="endDate" />,
          SetNoticePeriodComponent: <SetTimeSpan toSet="noticePeriod" />,
          breakLine: <br />,
        }}
      />
    );
  };

  return (
    <>
      <div>
        {renderDuration()}
      </div>
      {renderStartDateMessage()}
    </>
  );
};
