import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import webSocket from 'web-socket';
import { isUndefined } from 'lodash';

import useCurrentContractId from 'hooks/use-current-contract-id';
import type { Asset } from 'reducers/current-contract';

type contextValueProps = {
  subscribeToWebsocket: () => () => void,
  succeededAsyncAssetData: {
    pages: Asset['pages'],
    status: Asset['status'],
    assetId: Asset['assetId'],
  },
  failedAsyncAssetData: {
    apiCode: number,
    status: Asset['status'],
    assetId: Asset['assetId'],
  },
  resetFailedAsyncAssetState: () => void,
  resetSucceededAsyncAssetState: () => void,
}

export const AsyncAssetContext = createContext<contextValueProps | null>(null);

export function AsyncAssetPropsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const agreementId = useCurrentContractId();
  const [succeededAsyncAssetData, setSucceededAsyncAssetData] = useState({});
  const [failedAsyncAssetData, setFailedAsyncAssetData] = useState({});
  const fileIdRef = useRef<number | null>(null);

  const resetSucceededAsyncAssetState = useCallback(() => {
    setSucceededAsyncAssetData({});
  }, []);

  const resetFailedAsyncAssetState = useCallback(() => {
    setFailedAsyncAssetData({});
  }, []);

  const subscribeToWebsocket = useCallback(() => {
    const unsubscribeToReadyWebsocket = webSocket.subscribe({
      channelName: `private-agreement-${agreementId}`,
      event: 'agreement:pdf:status:ready',
      eventCallback: (res: any) => {
        setSucceededAsyncAssetData({
          pages: res.pageCount,
          status: res.status,
          assetId: res.assetId,
        });
      },
    });

    const unsubscribeToFailWebsocket = webSocket.subscribe({
      channelName: `private-agreement-${agreementId}`,
      event: 'agreement:pdf:status:failed',
      eventCallback: (res: any) => {
        setFailedAsyncAssetData({
          apiCode: res.apiCode,
          status: res.status,
          assetId: res.assetId,
        });
      },
    });

    return {
      unsubscribeToReadyWebsocket,
      unsubscribeToFailWebsocket,
    };
  }, [agreementId]);

  useEffect(() => {
    if (isUndefined(agreementId) || Number.isNaN(agreementId)) {
      return () => null;
    }

    const {
      unsubscribeToReadyWebsocket,
      unsubscribeToFailWebsocket,
    } = subscribeToWebsocket();

    return () => {
      unsubscribeToReadyWebsocket();
      unsubscribeToFailWebsocket();
    };
  }, [
    subscribeToWebsocket,
    agreementId,
  ]);

  const contextValue = useMemo(() => ({
    subscribeToWebsocket,
    succeededAsyncAssetData,
    failedAsyncAssetData,
    resetFailedAsyncAssetState,
    resetSucceededAsyncAssetState,
    fileIdRef,
  }), [
    subscribeToWebsocket,
    succeededAsyncAssetData,
    failedAsyncAssetData,
    resetFailedAsyncAssetState,
    resetSucceededAsyncAssetState,
  ]);

  return (
    <AsyncAssetContext.Provider
      value={contextValue}
    >
      {children}
    </AsyncAssetContext.Provider>
  );
}

export const useAsyncAssetProps = (): contextValueProps => {
  const contextValue = useContext(AsyncAssetContext);

  if (!contextValue) {
    throw new Error('useAsyncAssetProps should be used inside a AsyncAssetPropsProvider');
  }

  return contextValue;
};
