/* eslint-disable jsx-a11y/no-static-element-interactions */
// @flow

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSlate } from 'slate-react';

import { updateAnnotationsMap } from 'reducers/editor';
import { removeAcceptedSuggestionAction, removeRejectedSuggestionAction } from 'reducers/current-contract';
import useCurrentMessages from 'hooks/use-current-messages';
import { useContractProps } from 'contexts/contract-props';
import { useRichTextProps } from 'contexts/rich-text';

import AnnotationEditable from './annotation-editable';
import AnnotationReadOnly from './annotation-read-only';

const Annotation = ({ children, element }: RenderSlateElementProps) => {
  const editor = useSlate();
  const dispatch = useDispatch();
  const { isReadOnly } = useRichTextProps();

  const { mappedMessages } = useCurrentMessages();
  const annotation = mappedMessages[element.annotation.id];
  const { boxId } = useContractProps();
  const annotationId = annotation?.id;

  useEffect(() => {
    if (annotation) {
      dispatch(updateAnnotationsMap({
        [annotationId]: {
          boxId,
          editor,
        },
      }));
    }
  }, [
    dispatch,
    annotation,
    annotationId,
    boxId,
    editor,
  ]);

  useEffect(() => {
    dispatch(removeAcceptedSuggestionAction(annotationId));
    dispatch(removeRejectedSuggestionAction(annotationId));
  }, [
    dispatch,
    annotationId,
  ]);

  if (!annotation || annotation.config.status === 'resolved') {
    return children;
  }

  if (isReadOnly) {
    return (
      <AnnotationReadOnly
        annotation={annotation || element.annotation}
      >
        {children}
      </AnnotationReadOnly>
    );
  }

  return (
    <AnnotationEditable
      element={element}
      annotation={annotation || element.annotation}
    >
      {children}
    </AnnotationEditable>
  );
};

export default Annotation;
