import { connect } from 'react-redux';
import type { DefaultRootState } from 'react-redux';
import type { Dispatch } from 'redux';

import { timestampToDateString, DATE_FORMAT } from 'date';

import {
  getPositionFromSessionSelector,
} from 'reducers/session';

import agreementsReducer from 'reducers/entities/agreements';

import { checkAcl } from 'components/acl';

import SetExpiryDate from './set-expiry-date';
import type { Props, FormData } from './set-expiry-date';

type StateProps = {
  canSetExpiryDate: boolean,
  currentExpireDate: string | null,
  updateState: ReturnType<typeof agreementsReducer.getUpdateExpiryDateSelector>,
  position: Oneflow.Position,
};

type DispatchProps = {
  setExpiryDate: (formData: FormData) => void,
  resetUpdateState: () => void,
};

type OwnProps = Omit<Props, keyof StateProps | keyof DispatchProps> & {
  agreement: Oneflow.Agreement,
};

export const mapStateToProps = (state: DefaultRootState, { agreement }: OwnProps) => ({
  canSetExpiryDate: checkAcl(agreement.acl, 'agreement:update:expire_date'),
  currentExpireDate: agreement.expireDate,
  updateState: agreementsReducer.getUpdateExpiryDateSelector(state, {
    id: agreement.id,
  }),
  position: getPositionFromSessionSelector(state) as Oneflow.Position,
});

export const mapDispatchToProps = (dispatch: Dispatch, { agreement }: OwnProps) => ({
  setExpiryDate: ({ expireDate }: FormData) => {
    const formattedExpireDate = expireDate ? timestampToDateString(expireDate, DATE_FORMAT) : null;
    dispatch(agreementsReducer.updateExpiryDate({
      id: agreement.id,
      data: {
        expireDate: formattedExpireDate,
      },
    }));
  },
  resetUpdateState: () => {
    dispatch(agreementsReducer.updateExpiryDateReset({
      id: agreement.id,
    }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetExpiryDate);
