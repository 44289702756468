/* eslint-disable import/named */
import {
  useRef,
  useState,
  useMemo,
  useCallback,
  ChangeEvent,
  useEffect,
} from 'react';
import clsx from 'clsx';
import {
  Message, localize, MessageTranslator,
} from '@oneflowab/pomes';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { getGuestToken } from 'agreement/selectors';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';

import Button from 'components/button';
import PrivacyIndicator, { PrivacyDescription } from 'components/messages-layout/privacy-indicator';
import Tooltip from 'components/tooltip';
import SendIcon from 'components/icons/send';
import { BetaLabel } from 'components/beta-label';

import style from './post-suggestion.module.scss';

type Props = {
  isPrivate: boolean,
  addSuggestion: (suggestedText: string) => void,
  message: MessageTranslator,
  shouldRenderBetaBadge?: boolean,
};

const PostSuggestion = ({
  addSuggestion,
  isPrivate,
  message,
  shouldRenderBetaBadge,
}: Props) => {
  const [isTextMultiLine, setTextMultiLine] = useState(false);
  const [suggestion, setSuggestion] = useState('');
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const guestToken = useSelector(getGuestToken);
  const isInPreviewMode = useIsInPreviewMode();

  useEffect(() => {
    // Editor crashes when <TextareaAutosize autofocus>, so we need to focus it manually
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleOnSuggestionChange = useCallback((
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    const isMultiLine = value.includes('\n') || value.length > 32;
    setTextMultiLine(isMultiLine);
    setSuggestion(value);
  }, []);

  const handleOnSuggestClick = useCallback(() => {
    addSuggestion(suggestion);
    setSuggestion('');
  }, [addSuggestion, suggestion]);

  const placeholder = useMemo((): string => {
    if (isPrivate) {
      return message({
        id: 'Internal suggestion',
        comment: 'Placeholder in the input for suggestion when the suggestion will only be seen by users from my party',
      }) as string;
    }
    return message({
      id: 'Public suggestion',
      comment: 'Placeholder in the input for suggestion when the suggestion will be seen by everyone',
    }) as string;
  }, [message, isPrivate]);

  const renderFormInput = () => (
    <div
      className={clsx(style.FormInputContainer, {
        [style.PreviewModeFormInputContainer]: isInPreviewMode,
      })}
      role="button"
      tabIndex={-1}
      onClick={() => inputRef.current?.focus()}
      onKeyDown={noop}
    >
      <TextareaAutosize
        className={clsx(style.TextArea, {
          [style.PreviewModeTextArea]: isInPreviewMode,
          [style.IsTextMultiLine]: isTextMultiLine,
        })}
        placeholder={`${placeholder}...`}
        onChange={handleOnSuggestionChange}
        value={suggestion}
        ref={inputRef}
        disabled={isInPreviewMode}
      />
      <div className={style.PostSuggestionActions}>
        {isInPreviewMode ? (
          <Tooltip
            data-testid="form-submit-tooltip"
            message={<Message id="Not available in preview" comment="Tooltip title for the actions" />}
            side="top"
            sideOffset={4}
          >
            <Button
              kind="primary"
              onClick={handleOnSuggestClick}
              disabled={isInPreviewMode}
            >
              <Message
                id="Suggest"
                comment="CTA showing in a button that adds a suggestion"
              />
            </Button>
          </Tooltip>
        ) : (
          <Button
            kind="primary"
            onClick={handleOnSuggestClick}
            icon={SendIcon}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className={style.PopoverContainer}>
      {isInPreviewMode ? (
        <Tooltip
          data-testid="form-input-tooltip"
          message={(
            <Message
              id="Not available in preview"
              comment="Tooltip title for the actions"
            />
        )}
          side="top"
          sideOffset={4}
        >
          {renderFormInput()}
        </Tooltip>
      ) : renderFormInput()}
      <div className={style.PostSuggestionRowContainer}>
        <div className={clsx(style.PostSuggestionRow, {
          [style.Guest]: Boolean(guestToken),
        })}
        >
          <PrivacyIndicator
            isPrivate={isPrivate}
          />
          {!guestToken && <PrivacyDescription isPrivate={isPrivate} />}
        </div>
        {shouldRenderBetaBadge && (
          <BetaLabel />
        )}
      </div>
    </div>
  );
};

export default localize(PostSuggestion);
