// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

const smsCodePattern = /^[0-9]{6}$/;

type SmsCodeValidator = ({
  message: MessageTranslator,
  decimals?: number,
}) => Validator;

const smsCodeValidator: SmsCodeValidator = ({ message }) => (value: string) => {
  if (smsCodePattern.test(value)) {
    return undefined;
  }

  return message({
    id: 'Enter a valid SMS code.',
    comment: 'Validation message for sms code validator.',
  });
};

export default smsCodeValidator;
