import {
  useCallback,
  useContext,
  useRef,
} from 'react';
import type { Context } from 'react';

import {
  ITEM_SELECTOR,
  MENU_SELECT_EVENT,
  SELECTION_KEYS,
  UNSELECT_KEYS,
} from './constants';
import type { MenuStateContextValue } from './menu-state';
import type { MenuItem, OnKeyDown } from './types';

const useMenuStateContext = (scope: Context<MenuStateContextValue | null>) => {
  const stateContext = useContext(scope);
  if (!stateContext) {
    throw new Error('useMenuStateContext must be used within a StateProvider');
  }
  const { listRef, state, setState } = stateContext;
  const items = useRef<MenuItem[]>([]);

  const first = useCallback(() => {
    const firstItem = items.current[0];
    setState((prevState) => ({
      ...prevState,
      activeItemId: firstItem.id,
    }));
  }, [setState]);

  const registerItem = useCallback((value: MenuItem) => {
    items.current.push(value);
    if (items.current.length === 1) {
      first();
    }
  }, [first]);

  const unregisterItem = useCallback(({ id }: Omit<MenuItem, 'subMenuId'>) => {
    items.current.splice(items.current.findIndex((item) => item.id === id), 1);
    if (items.current.length === 0) {
      setState((prevState) => ({
        ...prevState,
        activeItemId: null,
      }));
    }
  }, [setState]);

  const next = useCallback(() => {
    const activeItemIndex = items.current.findIndex((item) => item.id === state.activeItemId);

    if (activeItemIndex === -1) {
      const newActiveItem = items.current[0];
      setState((prevState) => ({
        ...prevState,
        activeItemId: newActiveItem.id,
      }));
      return;
    }
    const nextItem = items.current[(activeItemIndex + 1) % items.current.length];

    setState((prevState) => ({
      ...prevState,
      activeItemId: nextItem.id,
    }));
  }, [setState, state.activeItemId]);

  const previous = useCallback(() => {
    const activeItem = items.current.findIndex((item) => item.id === state.activeItemId);

    if (activeItem === -1) {
      setState((prevState) => ({
        ...prevState,
        activeItemId: items.current[items.current.length - 1].id,
      }));
      return;
    }
    const previousItem = items.current[
      (activeItem - 1 + items.current.length) % items.current.length
    ];

    setState((prevState) => ({
      ...prevState,
      activeItemId: previousItem.id,
    }));
  }, [setState, state.activeItemId]);

  const select = useCallback(() => {
    const item = listRef.current?.querySelector(`${ITEM_SELECTOR}[data-selected="true"]`);
    if (item) {
      const event = new Event(MENU_SELECT_EVENT);
      item.dispatchEvent(event);
    }
  }, [listRef]);

  const handleKeyDown: OnKeyDown = useCallback((event) => {
    if (event.defaultPrevented || items.current.length === 0) {
      return;
    }
    if (event.key === 'ArrowDown') {
      next();
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      previous();
      event.preventDefault();
    } else if (SELECTION_KEYS.includes(event.key) && state.activeItemId) {
      select();
      event.preventDefault();
    } else if (UNSELECT_KEYS.includes(event.key)) {
      setState((prevState) => {
        const subMenus = { ...prevState.subMenus };
        Object.keys(subMenus).forEach((key) => {
          if (subMenus[key]) {
            subMenus[key] = false;
            event.preventDefault();
          }
        });
        return {
          ...prevState,
          subMenus,
        };
      });
    }
  }, [next, previous, select, setState, state.activeItemId]);

  return {
    registerItem,
    unregisterItem,
    handleKeyDown,
  };
};

export default useMenuStateContext;
