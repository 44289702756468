// @flow

import { connect } from 'react-redux';

import positions from 'reducers/entities/positions';

import type { Dispatch } from 'redux';

import RevokeTrustedDevices from './revoke-trusted-devices';
import type { Props } from './revoke-trusted-devices';

type StateProps = {|
  revokeState: RpcState,
|};

type DispatchProps = {|
  revokeTrustedDevices: () => void,
  resetRevokeState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  position: Position,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, ownProps) => ({
  revokeState: positions.getRevokeTrustedDevicesSelector(state, {
    id: ownProps.position.id,
  }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { position }) => ({
  revokeTrustedDevices: () => {
    dispatch(positions.revokeTrustedDevices({
      id: position.id,
    }));
  },
  resetRevokeState: () => {
    dispatch(positions.revokeTrustedDevicesReset({
      id: position.id,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(RevokeTrustedDevices);
