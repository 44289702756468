// @flow

import * as React from 'react';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';

import ProfileThinIcon from 'components/icons/profile-thin';
import { UserBadge } from 'components/user-badge';

import style from './inner-bubble.module.scss';

type Props = {
  label: string,
  borderColor?: string,
  fillColor?: string,
  labelColor?: string,
  renderBadge?: Function,
  labelClasses?: string,
  userBadgeBorderSize?: number,
  userBadgeDiameter?: number,
};

const InnerBubble = ({
  label,
  borderColor,
  fillColor,
  labelColor,
  renderBadge,
  labelClasses,
  userBadgeBorderSize = 2,
  userBadgeDiameter = 46,
}: Props) => {
  const showIcon = label.length === 0;

  const labelClassNames = clsx(style.Label, labelClasses, {
    [style.WhiteLabel]: labelColor,
    [style.EmptyLabelIcon]: showIcon && fillColor !== '#fff',
  });

  let badge = (
    <UserBadge
      diameter={userBadgeDiameter}
      borderSize={userBadgeBorderSize}
      fillColor={fillColor}
      borderColor={borderColor}
    />
  );

  if (isFunction(renderBadge)) {
    badge = renderBadge({ fillColor, noBorder: showIcon });
  }

  const iconBorder = userBadgeDiameter / 5;

  return (
    <>
      {badge}
      <span
        className={labelClassNames}
      >
        {showIcon ? <ProfileThinIcon height={userBadgeDiameter - iconBorder} /> : label}
      </span>
    </>
  );
};

InnerBubble.defaultProps = {
  borderColor: undefined,
  fillColor: undefined,
  labelColor: undefined,
  renderBadge: undefined,
  labelClasses: undefined,
};

export default InnerBubble;
