// @flow

import { put, select } from 'redux-saga/effects';
import type { PutEffect, SelectEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getWorkspaces,
  getWorkspace,
  getAiInsights,
  createWorkspace,
  removeWorkspaces,
  updateWorkspace,
  updateWorkspaceBranding,
  updateDataRetention,
  getDataRetention,
  createWorkspacePlaybook,
  getWorkspacePlaybook,
  updateWorkspacePlaybook,
  getAiInsightsContractList,
  type NormalizedWorkspaces,
} from 'oneflow-client/workspaces';

import apiWrapper from 'sagas/api-wrapper';

import {
  getCurrentWorkspaceSelector,
  changeCurrentWorkspace as changeCurrentWorkspaceAction,
} from 'reducers/app';
import workspacesReducer from 'reducers/entities/workspaces';

type MapperArgs = {
  data: NormalizedWorkspaces,
  action: any,
};
type Mapper = Generator<PutEffect<any, null> | SelectEffect<any, any>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(workspacesReducer.setWorkspaces(data.entities.workspaces));
}

type ClearMapperArgs = {
  data: {||},
  action: {
    id: string,
    type: string,
    data: {
      ids: Array<number>,
    },
  },
};
export function* clearMapper({ action }: ClearMapperArgs): Mapper {
  const currentWorkspace = yield select(getCurrentWorkspaceSelector);
  const workspaceIds = action.data.ids;

  yield put(workspacesReducer.clearWorkspace({ ids: workspaceIds }));

  if (workspaceIds.some((id) => id === currentWorkspace.id)) {
    yield put(changeCurrentWorkspaceAction({ workspaceId: null }));
  }
}

const mappers = {
  query: {
    mapper,
    request: getWorkspaces,
  },
  fetch: {
    mapper,
    request: getWorkspace,
  },
  create: {
    mapper,
    request: createWorkspace,
  },
  update: {
    mapper,
    request: updateWorkspace,
  },
  remove: {
    mapper: clearMapper,
    request: removeWorkspaces,
  },
  rpcs: {
    updateWorkspaceBranding: {
      name: 'updateWorkspaceBranding',
      request: updateWorkspaceBranding,
      mapper,
    },
    updateDataRetention: {
      name: 'updateDataRetention',
      request: updateDataRetention,
      mapper,
    },
    getDataRetention: {
      name: 'getDataRetention',
      request: getDataRetention,
      mapper,
    },
    getAiInsights: {
      name: 'getAiInsights',
      request: getAiInsights,
    },
    getAiInsightsContractList: {
      name: 'getAiInsightsContractList',
      request: getAiInsightsContractList,
    },
    createWorkspacePlaybook: {
      name: 'createWorkspacePlaybook',
      request: createWorkspacePlaybook,
    },
    getWorkspacePlaybook: {
      name: 'getWorkspacePlaybook',
      request: getWorkspacePlaybook,
    },
    updateWorkspacePlaybook: {
      name: 'updateWorkspacePlaybook',
      request: updateWorkspacePlaybook,
    },
  },
};

const workspacesSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: workspacesReducer,
  mappers,
});

export default workspacesSagas;
