import { Message } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import Label from 'components/document-tabs/settings-tab/components/label';
import { SignRedirectOptions } from 'components/document-tabs/settings-tab/sections/signing-and-security/sign-redirect-options';
import { checkAcl } from 'components/acl';

import style from './sign-redirect.module.scss';

type Props = {
  agreementId: number;
};

export const SignRedirect = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const hasSignRedirectUpdatePermission = checkAcl(agreement.acl, 'agreement:update:config:sign_redirect');
  const getSignRedirectUrl = agreement.config?.signRedirect || null;

  // For now, we hide the entire thing if you don't have permission to update it
  // and there is no value set, to save on space and to avoid empty settings
  // This means the extension must be on and you must be in a template
  if (!getSignRedirectUrl && !hasSignRedirectUpdatePermission) {
    return null;
  }

  const label = (
    <Label
      hint={(
        <Message
          id="URL where counterparties will be redirected after signing the document."
          comment="Tooltip for post sign redirect"
        />
      )}
    >
      <Message
        id="Redirect after sign"
        comment="Label for post sign redirect"
      />
    </Label>
  );

  return (
    <>
      <div>
        {label}
      </div>
      <div className={style.SignRedirectUrl}>
        {getSignRedirectUrl}
      </div>
      <SignRedirectOptions agreementId={agreementId} />
    </>
  );
};
