const sortSanitizer = (value, params) => {
  const { q } = params;

  if (!q) {
    if (value && (value.includes('score') || value.includes('-score'))) {
      return null;
    }
  }

  return value;
};

export default sortSanitizer;
