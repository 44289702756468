import type { Layout } from 'components/document-layout-container/types';
import { EXPANDED } from 'components/document-layout-container/helpers';
import type { MenuItems } from 'components/split-button-with-dropdown/types';
import { SaveChangesParticipantModal } from 'components/document-call-to-actions/actions/modals';
import {
  SaveButton,
  DiscardButton,
  SignButton,
} from './buttons';

export type FormData = { message: string; subject: string } | Record<string, never>;

type Props = {
  isSaveDropdown?: boolean,
  menuItems?: MenuItems,
  layout?: Layout,
  agreement: Oneflow.Document,
  notifyParticipants?: {
    setModalOpen: (open: boolean) => void,
    modalOpen: boolean,
    onSaveAndNotifyChanges: (data: FormData) => void,
  },
}

const DiscardSaveSignActions = ({
  isSaveDropdown = false,
  menuItems,
  layout = EXPANDED,
  agreement,
  notifyParticipants,
}: Props) => (
  <>
    {isSaveDropdown && (
      <SaveChangesParticipantModal
        agreementId={agreement.id}
        onClose={() => notifyParticipants?.setModalOpen?.(false)}
        onSaveAndNotifyChanges={notifyParticipants?.onSaveAndNotifyChanges ?? (() => null)}
        isOpen={notifyParticipants?.modalOpen ?? false}
      />
    )}
    <DiscardButton />
    <SaveButton
      agreementId={agreement.id}
      isDropdown={isSaveDropdown}
      menuItems={menuItems}
    />
    {layout === EXPANDED && <SignButton agreement={agreement} layout={layout} disabled />}
  </>
);

export default DiscardSaveSignActions;
