// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { RemoveConfirm } from '../remove-confirm';

export type Props = {|
  selectedAgreementTemplates: Array<AgreementTemplate>,
  removeState: RemoveState,
  resetRemoveState: () => void,
  deleteTemplates: () => void,
  children: Function,
|};

export class DeleteTemplates extends React.PureComponent<Props> {
  render() {
    const {
      children,
      removeState,
      deleteTemplates,
      resetRemoveState,
      selectedAgreementTemplates,
    } = this.props;

    return (
      <RemoveConfirm
        onConfirm={deleteTemplates}
        confirmState={removeState}
        resetConfirmState={resetRemoveState}
        confirmMessage={(
          <>
            <Message
              id="You are about to delete a template. This can't be undone."
              pluralId="You are about to delete {count} templates. This can't be undone."
              pluralCondition="count"
              values={{
                count: selectedAgreementTemplates.length,
              }}
              comment="Modal text when deleting one or more templates."
            />
            <br />
            <Message
              id="Existing drafts and contracts based on this template will not be affected."
              pluralId="Existing drafts and contracts based on these templates will not be affected."
              pluralCondition="count"
              values={{
                count: selectedAgreementTemplates.length,
              }}
              comment="Modal text when deleting a template."
            />
          </>
        )}
        modalKey="delete templates in bulk modal"
      >
        {children}
      </RemoveConfirm>
    );
  }
}
