// @flow

import { connect } from 'react-redux';
import get from 'lodash/get';

import agreements from 'reducers/entities/agreements';
import { getLocationSelector } from 'reducers/router';
import { getAccountFromSessionSelector } from 'reducers/session';
import {
  getCurrentBoxes,
  getDataFieldExternalKeyValueMap,
  getDataFieldExternalKeyMap,
  isContractPristine,
} from 'reducers/current-contract';
import workspacesReducer from 'reducers/entities/workspaces';
import { getMyParticipantWhenUpdater } from 'agreement/selectors';

import { checkAcl } from 'components/acl';

import ContractOptions, { type Props } from './contract-options';

type MSProps = {|
  agreement: Agreement,
  workspace: Workspace,
  account: Account,
  location: Location,
  isPristine: boolean,
  showReplaceContractMenuItem: boolean,
  boxes: Array<Box>,
  dataFieldExternalKeyMap: any,
  dataFieldExternalKeyValueMap: any,
|};

type OwnProps = $Diff<Props, MSProps>;

type MapStateToProps = (State, OwnProps) => MSProps;
export const mapStateToProps: MapStateToProps = (state, ownProps) => {
  const location = getLocationSelector(state);
  const agreement = agreements.getAgreementSelector(state, { id: ownProps.agreementId });
  const account = getAccountFromSessionSelector(state);
  const isPristine = isContractPristine(state);
  const boxes = getCurrentBoxes(state);
  const dataFieldExternalKeyMap = getDataFieldExternalKeyMap(state);
  const dataFieldExternalKeyValueMap = getDataFieldExternalKeyValueMap(state);
  const workspace = workspacesReducer.getWorkspaceSelector(state, {
    id: get(agreement, 'collection.id'),
  });
  const showReplaceContractMenuItem = checkAcl(agreement.acl, 'agreement:replace_contract');
  const myParticipant = getMyParticipantWhenUpdater(agreement);
  const agreementAllowsDelegate = checkAcl(agreement.acl, 'agreement:participant:pending_state_approver:create');
  const canParticipantDelegate = checkAcl(myParticipant?.acl, 'participant:delegate:from');

  return {
    agreement,
    workspace,
    account,
    location,
    isPristine,
    showReplaceContractMenuItem,
    boxes,
    dataFieldExternalKeyMap,
    dataFieldExternalKeyValueMap,
    agreementAllowsDelegate,
    canParticipantDelegate,
  };
};

export default connect<Props, OwnProps, MSProps, {||}, State, _>(
  mapStateToProps,
)(ContractOptions);
