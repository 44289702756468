// @flow

import * as React from 'react';

import { Message } from '@oneflowab/pomes';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from 'components/button';
import Check from 'components/icons/check';
import Copy from 'components/icons/copy';

import { InfoBox } from 'components/error-box';

import style from './info-box-clipboard.module.scss';

type Props = {
  value: string,
};

type State = {
  hasCopied: boolean,
};

class InfoBoxClipboard extends React.PureComponent<Props, State> {
  state = {
    hasCopied: false,
  }

  componentWillUnmount() {
    if (this.copiedTextTimeout) {
      clearTimeout(this.copiedTextTimeout);
    }
  }

  handleCopy = () => {
    this.setState({
      hasCopied: true,
    });

    this.copiedTextTimeout = setTimeout(() => {
      this.setState({
        hasCopied: false,
      });
      this.copiedTextTimeout = undefined;
    }, 2500);
  };

  copiedTextTimeout: ?TimeoutID

  renderCopyToClipboard() {
    const { hasCopied } = this.state;

    if (!hasCopied) {
      return <Button icon={Copy} customClass={style.CopyButton} />;
    }

    return (
      <>
        <Check className={style.CopyCheck} />
        <Message
          id="Copied"
          comment="Help text explaning that the text has been copied."
        />
      </>
    );
  }

  render() {
    const { value } = this.props;

    return (
      <InfoBox
        bodyText={(
          <p className={style.InfoBoxClipboard}>
            <span className={style.InfoBoxValue}>
              {value}
            </span>
            <CopyToClipboard text={value} onCopy={this.handleCopy}>
              {this.renderCopyToClipboard()}
            </CopyToClipboard>
          </p>
        )}
      />
    );
  }
}

export default InfoBoxClipboard;
