import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { useState, useMemo, useCallback } from 'react';
import { range } from 'lodash';
import type { ComponentProps } from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import { AiInsightsModal } from 'components/modals/ai-insights';
import type { InsightDataItem } from '../../types';
import {
  getColorPerIndex,
  isStaticDataPoint,
  isWarning,
} from '../utils';
import {
  MISSING, FOUND, UNSPECIFIED, UNTIL_TERMINATED, forceMajeureTypes,
  CONTRACT_NOT_ANALYSED_COLOR,
  CONTRACT_NOT_ANALYSED,
  WARNING_COLORS,
  BREACH_COLORS,
  NON_BREACH_COLORS,
} from '../constants';
import { CustomLegend } from '../legend';

import style from './stacked-bar-chart.module.scss';

export const getLabel = (label: string, message: MessageTranslator) => {
  if (label === UNTIL_TERMINATED) {
    return message({
      id: 'Until terminated',
      comment: 'Label for the bar chart data',
    });
  }
  if (label === MISSING) {
    return message({
      id: 'Missing',
      comment: 'Label for the bar chart data',
    });
  }
  if (label === UNSPECIFIED) {
    return message({ id: 'Unspecified', comment: 'Label showed in the chart legend for data that could not be classified to any category' });
  }
  if (label === FOUND) {
    return message({ id: 'Found', comment: 'Label for the bar chart data' });
  }
  if (label === forceMajeureTypes.PANDEMIC) {
    return message({ id: 'Pandemic', comment: 'Label for the force majeure' });
  }
  if (label === CONTRACT_NOT_ANALYSED) {
    return message({ id: 'Not analysed', comment: 'Label for the total contracts' });
  }

  return label;
};

const CustomTooltip = ({
  active, payload, hoveredData,
}: ComponentProps<typeof Tooltip> & { hoveredData: string }) => {
  if (active && payload && payload.length) {
    const hoveredDataPoint = payload.find((entry: { name: string }) => entry.name === hoveredData);
    if (!hoveredDataPoint) return null;
    return (
      <div className={style.Tooltip}>
        <Message
          id="{label}: {count} documents"
          values={{ label: hoveredDataPoint.name, count: hoveredDataPoint.value }}
          comment="Tooltip text showing the label and count of documents."
        />
      </div>
    );
  }

  return null;
};

// Parse ISO duration and convert to total days, e.g. "P2M4D" => 64
export const parseDurationToDays = (duration: string) => {
  const regex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?/;
  const matches = duration.match(regex);

  if (!matches) return null;

  const years = matches[1] ? parseInt(matches[1], 10) : 0;
  const months = matches[2] ? parseInt(matches[2], 10) : 0;
  const days = matches[3] ? parseInt(matches[3], 10) : 0;

  return years * 365 + months * 30 + days;
};

export const getChartData = (data: InsightDataItem[], message: MessageTranslator) => {
  const transformedData: { [key: string]: number } = {};

  data.forEach((item) => {
    const label = getLabel(item.label, message);
    transformedData[label] = item.count;
  });

  return {
    chartData: [transformedData],
    breaches: data.filter((item) => item.isBreach === true),
    nonBreaches: data.filter((item) => item.isBreach === false && !isWarning(item.breachSeverity)),
    warnings: data.filter((item) => isWarning(item.breachSeverity)),
  };
};

export const getRadius = (index: number, length: number):
  [number, number, number, number] => {
  if (index === 0 && length === 1) {
    return [8, 8, 8, 8];
  }
  if (index === 0) {
    return [8, 0, 0, 8];
  }
  if (index === length - 1) {
    return [0, 8, 8, 0];
  }
  return [0, 0, 0, 0];
};

type Data = {
  data: Array<InsightDataItem>,
};

type Props = {
  data: Data,
  message: MessageTranslator,
  filterParams: any,
  title: string,
  chartName: string,
};

const StackedBarChartComponent = ({
  data,
  message,
  filterParams,
  title,
  chartName,
}: Props) => {
  const [hoveredData, setHoveredData] = useState<string>('');
  const [selectedDataPoint, setSelectedDataPoint] = useState<string | null>(null);

  const {
    chartData, breaches, nonBreaches, warnings,
  } = useMemo(() => (
    getChartData(data.data, message)
  ), [message, data.data]);

  const getCursorStyle = useCallback((key: string) => {
    const dataPoint = data.data.find(
      (item) => getLabel(item.label, message) === key,
    );

    if (isStaticDataPoint(dataPoint?.label)) {
      return 'default';
    }
    return 'pointer';
  }, [data.data, message]);

  const getFillColor = useCallback((entry: string) => {
    const dataPoint = data.data.find(
      (item) => getLabel(item.label, message) === entry,
    );

    const { isBreach, breachSeverity } = dataPoint || {};
    if (isBreach === null) {
      return CONTRACT_NOT_ANALYSED_COLOR;
    }

    const isHovered = hoveredData === entry;
    const getIndex = (array: any[]) => (
      array.findIndex(({ label }) => getLabel(label, message) === entry)
    );

    if (isWarning(breachSeverity)) {
      const index = getIndex(warnings);
      return getColorPerIndex(WARNING_COLORS, index, isHovered);
    } if (isBreach) {
      const index = getIndex(breaches);
      return getColorPerIndex(BREACH_COLORS, index, isHovered);
    }
    const index = getIndex(nonBreaches);
    return getColorPerIndex(NON_BREACH_COLORS, index, isHovered);
  }, [breaches, nonBreaches, warnings, hoveredData, data.data, message]);

  const handleMouseEnter = useCallback((key: string) => {
    setHoveredData(key);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredData('');
  }, []);

  const handleLabelClick = useCallback(({ label, target }: { label: string, target: 'chart' | 'legend' }) => {
    const dataPoint = data.data.find(
      (item) => getLabel(item.label, message) === label,
    ) || { label: '' };
    if (isStaticDataPoint(dataPoint?.label)) return;
    setSelectedDataPoint(dataPoint.label);
    amplitudeLogEvent('Go To AI Insights Contract List', {
      'chart type': chartName,
      location: target === 'chart' ? 'ai insights tab - chart segment' : 'ai insights tab - chart label',
    });
  }, [data.data, chartName, message]);

  const xAxisTicks = useMemo(() => {
    // Last tick will be total number of documents
    const maxDataValue = Math.max(...[data.data.reduce((acc, item) => acc + item.count, 0)]);
    const tickInterval = Math.ceil(maxDataValue / 10);
    const ticks = range(0, maxDataValue, tickInterval).concat(maxDataValue);
    return ticks;
  }, [data.data]);

  if (!data) {
    return null;
  }

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height={300}
        className={style.BarChartContainer}
      >
        <BarChart
          data={chartData}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="4 4" stroke="#D6DFE2" />
          <XAxis
            type="number"
            stroke="#587a85"
            strokeWidth={0.5}
            tickLine={false}
            tickFormatter={(tick) => Math.floor(tick).toString()}
            domain={[0, 'dataMax']}
            ticks={xAxisTicks}
            label={{
              value: message({
                id: 'Documents count',
                comment: 'Label for the X-axis in the stacked bar chart',
              }),
              position: 'insideBottom',
              offset: -10,
              style: {
                textAnchor: 'middle', fill: '#587a85', fontSize: 16, fontWeight: 600,
              },
            }}
          />
          <YAxis
            type="category"
            dataKey="name"
            hide
          />
          <Tooltip content={(props) => <CustomTooltip {...props} hoveredData={hoveredData} />} />
          <Legend content={(
            <CustomLegend
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={(label) => handleLabelClick({ label, target: 'legend' })}
              getLegendLabel={getLabel}
              wrapperClassName={style.LegendWrapper}
            />
        )}
          />
          {Object.keys(chartData[0]).map((key, index, arr) => (
            <Bar
              key={key}
              dataKey={key}
              stackId="a"
              fill={getFillColor(key)}
              barSize={44}
              radius={getRadius(index, arr.length)}
              onMouseEnter={() => handleMouseEnter(key)}
              onMouseLeave={handleMouseLeave}
              onClick={(event) => handleLabelClick({ label: event.tooltipPayload[0].dataKey, target: 'chart' })}
              style={{ cursor: getCursorStyle(key) }}
              strokeWidth={1}
              stroke="rgba(255, 255, 255, 0.4)"
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      {selectedDataPoint && (
        <AiInsightsModal
          onClose={() => setSelectedDataPoint(null)}
          filterParams={filterParams}
          clickedLabel={{ key: selectedDataPoint, value: getLabel(selectedDataPoint, message) }}
          clickedInsightType={{ key: data.type.toLowerCase(), value: title }}
        />
      )}
    </>
  );
};

export const StackedBarChart = localize(StackedBarChartComponent);
