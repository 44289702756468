import type { HTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './action-button.module.scss';

type Props = {
  children: ReactNode;
  description: ReactNode;
  icon: ReactNode;
  'data-testid'?: string;
} & HTMLAttributes<HTMLButtonElement>;

const ActionButton = ({
  children,
  description,
  icon,
  className,
  onClick,
  ...props
}: Props) => (
  <button
    onClick={onClick}
    className={clsx(styles.Action, className)}
    data-testid={props['data-testid']}
  >
    <div className={styles.Icon}>
      {icon}
    </div>
    <div className={styles.Text}>
      <h2>
        {children}
      </h2>
      <p className={styles.Body}>
        {description}
      </p>
    </div>
  </button>
);

export default ActionButton;
