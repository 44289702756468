import Message from 'components/message';

export const setDurationConstants = {
  header: <Message
    id="Set period duration"
    comment="Header for the form where you can edit the duration of the contract."
  />,
  label: <Message
    id="Set period duration to:"
    comment="Label to show the duration of the contract."
  />,
};

export const setNoticePeriodConstants = {
  header: <Message
    id="Set notice period"
    comment="Header for the form where you can edit the notice period of the contract."
  />,
  label: <Message
    id="Set notice period to:"
    comment="Label to show the notice period of the contract."
  />,
};

export const setInitialDurationConstants = {
  header: <Message
    id="Set initial duration"
    comment="Header for the form where you can edit the initial duration of the contract."
  />,
  label: <Message
    id="Set initial duration to:"
    comment="Label to show the initial duration of the contract."
  />,
};
