// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import { TagNameField } from 'components/fields';

type Props = {|
  onSubmit: Tag => void,
  formState: UpdateState,
  resetFormState: () => void,
  tag: Tag,
  children: Function,
|};

const EditTagModal = ({
  formState,
  tag,
  resetFormState,
  onSubmit,
  children,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Edit tag name"
        comment="Modal title for editing tag name."
      />
    )}
    body={(
      <div>
        <TagNameField tagId={tag.id} autoFocus />
        <p>
          <em>
            <Message
              id="All contracts using this tag will be updated with the new tag name"
              comment="Warning message in edit tag modal."
            />
          </em>
        </p>
      </div>
    )}
    onSubmit={onSubmit}
    resetFormState={resetFormState}
    formState={formState}
    initialValues={{
      name: tag.name,
    }}
    modalKey="edit tag modal"
  >
    {children}
  </ModalForm>
);

export default EditTagModal;
