// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const GroupDeleteEvent = ({ auditLogEvent }: Props) => (
  <Message
    id="The role {roleName} was {jsx-start}deleted{jsx-end}"
    comment="Audit log event message for deleted role"
    component="span"
    className={style.Red}
    values={{
      roleName: auditLogEvent.information.name.from,
    }}
  />
);

export default GroupDeleteEvent;
