import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import { BOX_PDF } from 'agreement/constants';
import { isFreemium } from 'account';
import { getAccountFromSessionSelector } from 'reducers/session';
import { insertBox } from 'reducers/current-contract';

import AddIcon from 'components/icons/add';
import PdfIcon from 'components/icons/pdf-document';
import BoxDivider from 'components/box-list/box-divider';
import FreemiumMessage from './freemium-message';
import ActionButton from './action-button';
import styles from './blank-contract.module.scss';

const BlankContract = () => {
  const dispatch = useDispatch();
  const [isBoxMenuOpen, setIsBoxMenuOpen] = useState(false);
  const account = useSelector(getAccountFromSessionSelector);

  const isUserFreemium = isFreemium(account);

  const handleUploadPdf = () => {
    dispatch(insertBox({ boxType: BOX_PDF }));
  };

  useEffect(() => () => {
    setIsBoxMenuOpen(false);
  }, []);

  return (
    <>
      <BoxDivider
        isAlwaysVisible
        shouldOpen={isBoxMenuOpen}
        onClose={() => setIsBoxMenuOpen(false)}
      />
      <div className={styles.BlankContract}>
        <h1>
          <Message
            id="Get started"
            comment="This message is visible when the contract is empty"
          />
        </h1>
        <p className={styles.Description}>
          <Message
            id="Several sections can be added by clicking on the options below."
            comment="This message is visible when the contract is empty"
          />
        </p>
        {isUserFreemium && <FreemiumMessage />}
        <div className={styles.Actions}>
          <ActionButton
            onClick={() => setIsBoxMenuOpen(true)}
            icon={<AddIcon height="24px" />}
            data-testid="add-content"
            description={(
              <Message
                id="Customize by adding a section."
                comment="This message is visible when the contract is empty"
              />
            )}
          >
            <Message
              id="Add content"
              comment="This message is visible when the contract is empty"
            />
          </ActionButton>
          <ActionButton
            onClick={handleUploadPdf}
            icon={<PdfIcon height="24px" />}
            data-testid="upload-pdf"
            description={(
              <Message
                id="Upload your own PDF document."
                comment="This message is visible when the contract is empty"
              />
            )}
          >
            <Message
              id="Upload PDF"
              comment="This message is visible when the contract is empty"
            />
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default BlankContract;
