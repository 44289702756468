// @flow
import React from 'react';
import { Message, localize } from '@oneflowab/pomes';

import { getFileSize } from 'utils/file';

import {
  getMaxNumberOfFilesErrorMessage,
  maxSizePerContractErrorMessage,
  getMaxNumberOfFilesAlreadyInDocumentErrorMessage,
  DROPZONE_EXCEED_FILE_SIZE_ERROR,
  DROPZONE_EXCEED_FILE_COUNT_ERROR,
  DROPZONE_INVALID_FILE_ERROR,
  DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR,
  DROPZONE_TOO_LARGE_FILE_ERROR,
} from 'components/contract-boxes/constants';

import clsx from 'clsx';
import style from './file-upload-error.module.scss';

type Props = {
  errorCode?: number | string,
  maxFileSize: number,
  acceptedFileTypes: string,
  attachmentsCountLimit: number,
  pdfUploadError?: number,
};

const API_ERROR_FILE_FORMAT_NOT_ALLOWED = 400;
const SERVER_ERROR_TOO_LARGE_REQUEST = 413;
const SERVER_ERROR_FILE_NOT_SAVED = 500;
export const OFFLINE_ERROR = 'OFFLINE_ERROR';
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
export const API_ERROR_PDF_CONTAINS_TOO_MANY_PAGES = 4505;

export const getErrorMessage = ({
  errorCode,
  maxFileSize,
  acceptedFileTypes,
  attachmentsCountLimit,
}: Props) => {
  switch (errorCode) {
    case DROPZONE_INVALID_FILE_ERROR:
    case API_ERROR_FILE_FORMAT_NOT_ALLOWED:
      return (
        <Message
          id="The file format is not supported. Allowed file types: {acceptedFileTypes}"
          comment="API error message when unsupported file is uploaded"
          values={{
            acceptedFileTypes,
          }}
        />
      );
    case DROPZONE_TOO_LARGE_FILE_ERROR:
    case SERVER_ERROR_TOO_LARGE_REQUEST:
      return (
        <Message
          id="The file is too large. The maximum file size is {maxSize}."
          comment="Server error message when too large file is uploaded."
          values={{
            maxSize: getFileSize(maxFileSize),
          }}
        />
      );
    case SERVER_ERROR_FILE_NOT_SAVED:
      return (
        <Message
          id="The file could not be saved. Please reload the page and try again."
          comment="Server error message when uploaded file could not be saved"
        />
      );
    case OFFLINE_ERROR:
      return null;
    case API_ERROR_PDF_CONTAINS_TOO_MANY_PAGES:
      return null;
    case DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR:
      return getMaxNumberOfFilesAlreadyInDocumentErrorMessage(attachmentsCountLimit);
    case DROPZONE_EXCEED_FILE_SIZE_ERROR:
      return maxSizePerContractErrorMessage;
    case DROPZONE_EXCEED_FILE_COUNT_ERROR:
      return getMaxNumberOfFilesErrorMessage(attachmentsCountLimit);
    default:
      return (
        <Message
          id="Something went wrong"
          comment="Generic error message"
        />
      );
  }
};

export const FileUploadErrorComponent = ({
  errorCode,
  maxFileSize,
  acceptedFileTypes,
  attachmentsCountLimit,
  pdfUploadError,
}: Props) => {
  const errorCompomentStyle = clsx(style.UploadError, {
    [style.UploadError]: pdfUploadError,
    [style.UploadErrorMessage]: !pdfUploadError,
  });

  return (
    <div className={errorCompomentStyle}>
      {getErrorMessage({
        errorCode,
        maxFileSize,
        acceptedFileTypes,
        attachmentsCountLimit,
      })}
    </div>
  );
};

export default localize<Props>(FileUploadErrorComponent);
