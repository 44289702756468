import {
  createStore,
  compose,
  applyMiddleware,
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleWare from 'redux-saga';

import reducers from 'reducers';
import { createRootSaga } from 'sagas';

import { isEnvironment } from 'utils/environment';

import history from './history';
import promiseListener from './promise-listener';
import dynamicMiddlewares from './dynamic-middlewares';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => any;
  }
}

const setupReduxDevTools = () => {
  if (isEnvironment('production')) {
    return (f: any) => f;
  }

  const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

  if (!__REDUX_DEVTOOLS_EXTENSION__) {
    return (f: any) => f;
  }

  return __REDUX_DEVTOOLS_EXTENSION__();
};

const configureStore = ({ isRTLEnv = false }: { isRTLEnv?: boolean } = {}) => {
  const sagaMiddleware = createSagaMiddleWare();

  const store = createStore(
    reducers,
    compose(
      applyMiddleware(
        sagaMiddleware,
        promiseListener.middleware,
        routerMiddleware(history),
        dynamicMiddlewares,
      ),
      setupReduxDevTools(),
    ),
  );

  const sagas = createRootSaga({ isRTLEnv });

  const sagaTask = sagaMiddleware.run(sagas);

  return {
    sagaTask,
    store,
  };
};

export default configureStore;
