import head from 'lodash/head';
import {
  ACTION_GROUP_APPROVAL,
  ACTION_STATUS_NOT_STARTED,
  ACTION_STATUS_RUNNING,
  ACTION_STATUS_SUCCEEDED,
} from 'agreement/actions/constants';
import { hasDraftApprovalFlow } from './states';

export const getDraftApprovers = (agreement) => {
  if (!hasDraftApprovalFlow(agreement)) {
    return null;
  }

  const approvalBlocks = agreement.draftApprovalFlow.childNodes.filter((node) => node.key === 'BLOCK_APPROVAL');

  const agreementApprovers = approvalBlocks.map(
    (block) => block.childNodes.filter((node) => node.key === ACTION_GROUP_APPROVAL),
  ).flat();

  return agreementApprovers;
};

export const getDraftApprovalOrder = (agreement, participantId) => {
  if (!hasDraftApprovalFlow(agreement) || !participantId) {
    return null;
  }

  const approvalBlocks = agreement.draftApprovalFlow.childNodes.filter((node) => node.key === 'BLOCK_APPROVAL').flat();

  const blockWithParticipant = approvalBlocks.find(
    (block) => block.childNodes.some((node) => node.config.actor.id === participantId),
  );

  return blockWithParticipant ? blockWithParticipant.config.blockIndex + 1
    : approvalBlocks.length + 1;
};

export const getRunningInternalApproversBlockIndex = (agreement) => {
  if (!hasDraftApprovalFlow(agreement)) {
    return 0;
  }

  const runningApprovalBlock = agreement.draftApprovalFlow.childNodes.find(
    (node) => node.key === 'BLOCK_APPROVAL' && node.status === ACTION_STATUS_RUNNING,
  );

  const blockIndex = runningApprovalBlock?.config?.blockIndex ?? -1;

  return blockIndex;
};

export const getLastBlockIndex = (agreement) => {
  if (!hasDraftApprovalFlow(agreement)) {
    return 0;
  }
  const approvalBlocks = agreement.draftApprovalFlow.childNodes.filter(
    (node) => node.key === 'BLOCK_APPROVAL',
  );

  const lastBlockIndex = approvalBlocks[approvalBlocks.length - 1]?.config?.blockIndex;

  return lastBlockIndex || 0;
};

export const getNewInternalApproverBlockIndex = (agreement) => {
  if (!hasDraftApprovalFlow(agreement)) {
    return 0;
  }
  const lastBlockIndex = getLastBlockIndex(agreement);

  return (lastBlockIndex ?? -1) + 1;
};

export const getMaxAvailableBlockIndex = (agreement) => {
  if (!hasDraftApprovalFlow(agreement)) {
    return 0;
  }
  const pendingApprovalBlocks = agreement.draftApprovalFlow.childNodes.filter(
    (node) => node.key === 'BLOCK_APPROVAL' && node.status === ACTION_STATUS_NOT_STARTED,
  );

  const pendingApprovers = pendingApprovalBlocks.map(
    (block) => block.childNodes.filter((node) => node.key === ACTION_GROUP_APPROVAL),
  ).flat();

  if (agreement.draftApprovalFlow.status === ACTION_STATUS_NOT_STARTED) {
    return Math.max(pendingApprovers.length - 1, getLastBlockIndex(agreement));
  }

  return Math.max(pendingApprovers.length, getLastBlockIndex(agreement));
};

export const isDraftPartiallyApproved = (agreement) => {
  if (!hasDraftApprovalFlow(agreement)
    || agreement.draftApprovalFlow.status !== ACTION_STATUS_RUNNING) {
    return false;
  }

  const approvers = getDraftApprovers(agreement);
  const hasNotApproved = approvers.some((approver) => approver.status === ACTION_STATUS_RUNNING);
  const hasApproved = approvers.some((approver) => approver.status === ACTION_STATUS_SUCCEEDED);

  return hasNotApproved && hasApproved;
};

const hasParticipantApprovedDraft = (agreement, participant) => {
  if (!hasDraftApprovalFlow(agreement)) {
    return false;
  }

  if (!participant) {
    return false;
  }

  const actionGroupsApproval = getDraftApprovers(agreement);

  const participantNodes = actionGroupsApproval.filter(
    (node) => {
      const { config } = node;
      return config?.actor?.id === participant.id && config.actor.type === 'AGREEMENT_PARTICIPANT';
    },
  );

  const action = head(participantNodes);

  return action?.status === ACTION_STATUS_SUCCEEDED;
};

export default hasParticipantApprovedDraft;
