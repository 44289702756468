// @flow

import * as React from 'react';
import { localize, Message } from '@oneflowab/pomes';
import moment from 'moment';
import type { MessageTranslator } from '@oneflowab/pomes';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';

import { checkAcl } from 'components/acl';
import { timestampToDateString, getEndOfTodayTimestamp } from 'date';
import { withQueryStringParamsProvider, withQueryStringParamsConsumer } from 'hocs/query-string-params';
import sessionStorage from 'utils/session-storage';
import type { Params } from 'hocs/search/search';

import AppTitle from 'components/app-title';
import CircularSpinner from 'components/icons/circular-spinner';
import DashboardDatePicker from 'components/dashboard-date-picker';
import { NoActivity } from 'components/dashboard-no-activity';
import EmptyStatsWidget from 'components/empty-stats-widget';
import MostActiveUsers from 'components/most-active-users/most-active-users-container';
import SignRate from 'components/charts/agreements/sign-rate/sign-rate';
import TimeToSign from 'components/charts/agreements/time-to-sign/time-to-sign';
import { UserDropdown } from 'components/user-dropdown/user-dropdown';
import WorkspaceIcon from 'components/icons/workspace';

import {
  KPIDeclined,
  KPIOverdue,
  KPIPending,
  KPISigned,
} from 'components/kpis';

import style from './dashboard.module.scss';

export type DateRange = {
  fromDate?: string,
  toDate?: string,
};

export type QueryStatisticsDateParams = {
  fromDate?: string,
  toDate?: string,
  user?: string | () => string,
};

export type Props = {
  currentWorkspace: Workspace,
  data: any,
  dateFormat: string,
  dateRange: Object,
  loading: boolean,
  message: MessageTranslator,
  params?: Params,
  queryDashboard: ?QueryStatisticsDateParams => void,
  setDashboardDateRange: (Object) => void,
  setDashboardUser: (any) => void,
  user: string,
};

export const getFilterableUsers = (currentWorkspace: Workspace) => {
  let users = [];

  const hasAccessToOwnDashboard = checkAcl(currentWorkspace.acl, 'collection:dashboard:view:own');
  const hasAccessToColleagueDashboard = checkAcl(currentWorkspace.acl, 'collection:dashboard:view:colleague');

  if (hasAccessToColleagueDashboard) {
    users = [
      ...users,
      {
        label: (
          <Message
            id="All users"
            comment="Used in the user selection dropdown in the dashboard, to show dashboard data of all users in the workspace."
          />
        ),
        id: 'all',
      },
    ];
  }

  if (hasAccessToOwnDashboard) {
    users = [
      ...users,
      {
        label: (
          <Message
            id="My user"
            comment="Used in the user selection dropdown in the dashboard, to show dashboard data of the current user."
          />
        ),
        id: 'me',
      },
    ];
  }

  return users;
};

export class Dashboard extends React.PureComponent<Props> {
  componentDidMount() {
    this.queryDashboard();
  }

  componentDidUpdate(prevProps: Props) {
    const paramsChanged = this.workspaceHasChanged(prevProps)
      || this.hasInitialValuesChanged(prevProps);

    if (paramsChanged && !this.props.loading) {
      this.queryDashboard();
    }
  }

  onDateRangeChange = ({ fromDate, toDate }: DateRange) => {
    const { setDashboardDateRange } = this.props;
    const dateRange = {
      from: fromDate,
      to: toDate,
    };

    sessionStorage.setItem('dashboard_daterange', JSON.stringify(dateRange));
    setDashboardDateRange(dateRange);
  }

  onUserChange = (user: string) => {
    const { setDashboardUser } = this.props;

    sessionStorage.setItem('dashboard_user', user);
    setDashboardUser(user);
  }

  queryDashboard() {
    const {
      currentWorkspace,
      dateRange,
      queryDashboard,
      setDashboardDateRange,
      setDashboardUser,
    } = this.props;
    let { user } = this.props;

    const users = getFilterableUsers(currentWorkspace);
    user = users.find((availableUser) => availableUser.id === user);

    const defaultUser = users.find((availableUser) => availableUser.id === 'all') || users.find((availableUser) => availableUser.id === 'me');
    const sessionDateRange = JSON.parse(sessionStorage.getItem('dashboard_daterange'));
    const sessionUser = users.find(
      (availableUser) => availableUser.id === sessionStorage.getItem('dashboard_user'),
    );

    const defaultDateRange = {
      from: moment()
        .subtract(30, 'days')
        .add(1, 'day')
        .startOf('day')
        .unix(),
      to: getEndOfTodayTimestamp(),
    };

    const customUser = sessionUser?.id || user?.id || defaultUser?.id;
    const customDateRange = sessionDateRange || dateRange || defaultDateRange;

    setDashboardUser(customUser);
    setDashboardDateRange(customDateRange);

    queryDashboard({
      fromDate: timestampToDateString(customDateRange.from, 'YYYY-MM-DDT00:00:00+0000'),
      toDate: timestampToDateString(customDateRange.to, 'YYYY-MM-DDT23:59:59+0000'),
      user: customUser,
    });
  }

  workspaceHasChanged(prevProps: Props) {
    const { currentWorkspace } = this.props;

    return prevProps.currentWorkspace.id !== currentWorkspace.id;
  }

  hasInitialValuesChanged(prevProps: Props) {
    return !isEqual(this.props.params, prevProps.params)
      || !isEqual(this.props.dateRange, prevProps.dateRange)
      || !isEqual(this.props.user, prevProps.user);
  }

  renderMostActiveUsers() {
    const { dateFormat, dateRange, user } = this.props;
    if (user !== 'all') {
      return null;
    }

    return (
      <div>
        <MostActiveUsers
          from={timestampToDateString(dateRange?.from, dateFormat)}
          to={timestampToDateString(dateRange?.to, dateFormat)}
        />
      </div>
    );
  }

  renderSignStatistics() {
    const {
      data,
      loading,
      dateFormat,
      dateRange,
    } = this.props;

    if (loading) {
      return (
        <EmptyStatsWidget>
          <CircularSpinner />
        </EmptyStatsWidget>
      );
    }

    const contractStats = get(data, 'contractStat', {});
    const contractStatsCount = mapValues(contractStats, 'count');
    const totalCount = reduce(contractStatsCount, (total, count) => total + count, 0);

    if (totalCount === 0) {
      return (
        <EmptyStatsWidget className={style.EmptyState}>
          <NoActivity
            fromDate={dateRange?.from}
            toDate={dateRange?.to}
            dateFormat={dateFormat}
          />
        </EmptyStatsWidget>
      );
    }

    return (
      <>
        <TimeToSign data={data} loading={loading} />
        <SignRate data={data} loading={loading} />
      </>
    );
  }

  renderUserDropdown() {
    const { currentWorkspace, user } = this.props;
    const users = getFilterableUsers(currentWorkspace);

    const userDropdownAcls = [
      'collection:dashboard:view:own',
      'collection:dashboard:view:colleague',
    ];

    if (!checkAcl(currentWorkspace.acl, userDropdownAcls, { match: 'any' })) {
      return null;
    }

    return (
      <UserDropdown
        changeCurrentUser={this.onUserChange}
        currentUser={user}
        users={users}
      />
    );
  }

  render() {
    const {
      currentWorkspace,
      data,
      dateFormat,
      dateRange,
      message,
      user,
    } = this.props;
    const filters = {
      date: dateRange,
      user,
    };

    const emptyKPI = { count: 0, agreementValue: {} };
    const { contractStat } = data || {};
    const declined = get(contractStat, 'declined', emptyKPI);
    const pending = get(contractStat, 'pending', emptyKPI);
    const signed = get(contractStat, 'signed', emptyKPI);
    const overdue = get(contractStat, 'overdue', emptyKPI);

    return (
      <div className={style.Dashboard}>
        <AppTitle title="Dashboard" />
        <div className={style.Header}>
          <span className={style.Title}>
            <Message
              id="Dashboard"
              comment="Header for the dashboard."
            />
          </span>
          <div className={style.Filters}>
            {this.renderUserDropdown()}
            <DashboardDatePicker
              onChange={this.onDateRangeChange}
              from={dateRange?.from}
              to={dateRange?.to}
              dateFormat={dateFormat}
            />
          </div>
        </div>
        <div
          className={style.WorkspaceContainer}
        >
          <WorkspaceIcon height="10px" />
          <span>{currentWorkspace.name}</span>
        </div>
        <div className={style.AgreementKPIs}>
          <KPIPending
            filters={filters}
            count={pending.count}
            value={pending.agreementValue.amount}
          />
          <KPISigned filters={filters} count={signed.count} value={signed.agreementValue.amount} />
          <KPIDeclined
            filters={filters}
            count={declined.count}
            value={declined.agreementValue.amount}
          />
          <KPIOverdue
            count={overdue.count}
            value={overdue.agreementValue.amount}
            tooltip={message({
              id: 'The number of overdue documents sent during the selected date range.',
              comment: 'Dashboard KPI tooltip message',
            })}
            filters={filters}
          />
        </div>
        <div className={style.SignStatistics}>
          {this.renderSignStatistics()}
        </div>
        {this.renderMostActiveUsers()}
      </div>
    );
  }
}

export default localize<Props>(
  withQueryStringParamsProvider(withQueryStringParamsConsumer(Dashboard)),
);
