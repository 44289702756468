import { DATA_FIELD } from 'components/contract-boxes/constants';
import {
  get, last, split, reduce, isString,
} from 'lodash';

type ContractData = {
  key: string;
  value: {
    name?: string;
    externalKey?: string;
  };
};

type Option = {
  label: string;
  value: string;
};

export const getDataFields = ({ data }: { data: ContractData[] }) => (
  reduce<ContractData, Partial<Option>[]>(data, (result, { key, value }) => {
    const item = {
      label: value.name,
      value: value.externalKey,
    };

    return key === DATA_FIELD ? [...result, item] : result;
  }, [])
);

export const getInitialValues = ({ config }: { config: ContractView.BoxConfig}) => {
  const { visibility } = config;

  if (!visibility) {
    return {};
  }

  const { rule } = visibility;
  const [condition] = Object.keys(rule);
  const value = isString(last(rule[condition])) ? last(rule[condition]) : '';
  const dataFieldPath = get(rule, [condition, 0, 'var']);
  const DATA_FIELD_REGEX = /data\.data_fields\./g;
  const dataField = last(split(dataFieldPath, DATA_FIELD_REGEX));

  return { condition, value, dataField };
};
