// @flow

import {
  getDateWithSetHour,
  isTimeSameOrBefore,
  getCurrentHour,
  toMoment,
  isToday,
} from 'date';

function disableHoursBeforeCurrentHourOfToday(
  timeValues: Array<string>,
  selectedDateTimestamp: number,
) {
  return (timeValues: any).map(({ time }) => {
    const timeAsInteger = Number(time.split(':')[0]);

    if (selectedDateTimestamp && isToday(toMoment(selectedDateTimestamp))) {
      const nextHour = getCurrentHour() + 1;
      const todayDate = getDateWithSetHour(new Date(Date.now()), nextHour);
      const timeToCompare = getDateWithSetHour(
        new Date(selectedDateTimestamp * 1000),
        timeAsInteger,
      );
      const beforeCurrentHourOfToday = !isTimeSameOrBefore(todayDate, timeToCompare);

      return {
        time,
        isDisabled: beforeCurrentHourOfToday,
        timeAsInteger,
      };
    }

    return {
      time,
      isDisabled: false,
      timeAsInteger,
    };
  });
}

export default disableHoursBeforeCurrentHourOfToday;
