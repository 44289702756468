import React from 'react';

import {
  USER_ROLE_LIMITED,
  USER_ROLE_USER,
} from 'user';

export const licensedUserRole = (message) => ({
  [USER_ROLE_USER]: {
    label: message({
      id: 'Licensed user',
      comment: 'The role of the user',
    }),
    value: USER_ROLE_USER,
    description: message({
      id: '{user}: Has access to advanced functionality based on the account plan, account role, and workspace role.',
      comment: 'Tooltip help text explaining the administrative access of a certain user role. The text is used in the tooltip of the user role field.',
      values: {
        user: (
          <strong>
            {message({
              id: 'Licensed user',
              comment: 'The text is used in the tooltip of the user role field',
            })}
          </strong>
        ),
      },
    }),
  },
});

export const limitedUserRole = (message) => ({
  [USER_ROLE_LIMITED]: {
    label: message({
      id: 'Limited access',
      comment: 'The role of the limited access user',
    }),
    value: USER_ROLE_LIMITED,
    description: message({
      id: '{limited}: Can only sign, chat, and have read-only access to workspaces.',
      comment: 'Tooltip help text explaining the administrative access of a certain user role. The text is used in the tooltip of the user role field.',
      values: {
        limited: (
          <strong>
            {message({
              id: 'Limited access',
              comment: 'The text is used in the tooltip of the user role field.',
            })}
          </strong>
        ),
      },
    }),
  },
});
