import getFontAssetsDomain from './get-font-asset-domain.js';

const loadRoboto = () => {
  const normalRoboto = new FontFace(
    'Roboto',
    `url(${getFontAssetsDomain()}/resources/fonts/roboto/roboto_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const boldRoboto = new FontFace(
    'Roboto',
    `url(${getFontAssetsDomain()}/resources/fonts/roboto/roboto_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const italicRoboto = new FontFace(
    'Roboto',
    `url(${getFontAssetsDomain()}/resources/fonts/roboto/roboto_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldItalicRoboto = new FontFace(
    'Roboto',
    `url(${getFontAssetsDomain()}/resources/fonts/roboto/roboto_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalRoboto, italicRoboto, boldRoboto, boldItalicRoboto];
};

export default loadRoboto;
