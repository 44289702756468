type Props = {
  diameter: number,
  borderSize?: number,
  fillColor?: string,
  borderColor?: string,
  noBorder?: boolean
};

export const UserBadge = ({
  diameter,
  borderSize,
  fillColor,
  borderColor,
  noBorder,
}: Props) => {
  const radius = diameter / 2;

  const renderBorder = () => {
    if (!borderSize || noBorder) {
      return null;
    }

    return <circle id="Oval-border" fill={borderColor} cx={radius} cy={radius} r={radius} />;
  };

  return (
    <svg
      fill="currentColor"
      width={diameter}
      height={diameter}
      xmlns="http://www.w3.org/2000/svg"
    >
      {renderBorder()}
      <circle id="Oval-inner" fill={fillColor} cx={radius} cy={radius} r={radius - (borderSize ?? 0)} />
    </svg>
  );
};
