import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty,
  values,
} from 'lodash';
import type { ReactNode } from 'react';
import { Message, MessageTranslator, localize } from '@oneflowab/pomes';

import {
  discardChanges,
  getAcceptedSuggestions,
  getDataFieldExternalKeyValueMap,
  getDataFieldExternalKeyMap,
  getPristineState,
  getPristineContractData,
  getRejectedSuggestions,
} from 'reducers/current-contract';
import useSaveChangesButton from 'components/document-call-to-actions/actions/buttons-call-to-action/hooks/use-save-changes-button';
import useAgreement from 'hooks/use-agreement';
import useCurrentBoxes from 'hooks/use-current-boxes';
import useCurrentData from 'hooks/use-current-data';
import useCurrentBoxOrder from 'hooks/use-current-box-order';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import { getAccountFromSessionSelector } from 'reducers/session';
import agreements from 'reducers/entities/agreements';
import { getNotifyParticipant } from 'reducers/editor';
import {
  getAgreementMyParticipant,
  getGuestToken,
  getParticipantParty,
  isAgreementOwner,
  isPartiallySigned,
} from 'agreement/selectors';
import { getEmptyRequiredElementsCount } from 'reducers/helpers/current-contract';

import Button from 'components/button';
import SendContract from 'components/modals/send-contract';
import ResetDraftApprovalsWarning from 'components/modals/reset-draft-approvals/reset-draft-approvals';
import ResetApprovalsWarning from 'components/modals/reset-approvals';
import ResetApprovalsSignaturesWarning from 'components/modals/reset-approvals-signatures';
import ResetSignaturesWarning from 'components/modals/reset-signatures';
import Snackbar from 'components/snackbar';
import { SEVERITY_TYPES } from 'components/alert';
// eslint-disable-next-line import/named
import { ApiError } from 'components/api-error';
import toast from 'components/toasts';
import { EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID } from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons/sign/helpers';
import { PARTIALLY_SIGNED_TOAST_ID } from 'components/agreement-loader/partially-signed-toast';

import isDraftApproved from 'agreement/actions/is-draft-approved';
import { isDraftPartiallyApproved } from 'agreement/draft-approval-flow';
import { hasAnyPendingApproverApproved } from 'agreement/pending-state-flow';
import { isDraft, isTemplate } from 'agreement';

import {
  getDefaultNotificationData,
  getDataFieldChangedValues,
  getError,
  getNonPristineBoxesMap,
  getNonPristineDataFieldsMap,
  hasValidContractChanges,
} from './helpers';

export type Props = {
  agreementId: number;
  onSaveAgreement?: () => void;
  message?: MessageTranslator;
  children: ReactNode;
  modalClassName?: string;
  buttonClassName?: string;
  buttonKind?: string;
  shouldShowSaveSuccessToast?: boolean;
};

const showSaveSuccessToast = (agreement: Oneflow.Agreement) => {
  const isInTemplate = isTemplate(agreement);

  return (
    toast.info({
      id: isInTemplate ? 'saved-template' : 'saved-document',
      title: <Message
        id="Saved"
        comment="Title for the info message when the user has saved the document."
      />,
      description: isInTemplate ? (
        <Message
          id="Template was successfully saved"
          comment="Description for the info message when the user has saved."
        />
      ) : (
        <Message
          id="Document was successfully saved"
          comment="Description for the info message when the user has saved."
        />
      ),
      duration: 5000,
    })
  );
};

const showSignaturesResetToast = () => toast.warning({
  id: 'signatures-reset',
  title: (
    <Message
      id="Signatures reset"
      comment="Title for the info message when the signatures have been reset."
    />
  ),
  description: (
    <Message
      id="The document needs to be signed again."
      comment="Description text for the info message when the signatures have been reset."
    />
  ),
  duration: 5000,
});

const showApprovalsResetToast = () => toast.warning({
  id: 'approvals-reset',
  title: (
    <Message
      id="Approvals reset"
      comment="Title for the info message when the approvals have been reset."
    />
  ),
  description: (
    <Message
      id="The document needs to be approved again."
      comment="Description text for the info message when the approvals have been reset."
    />
  ),
  duration: 5000,
});

const showApprovalsSignaturesResetToast = () => toast.warning({
  id: 'approvals-signatures-reset',
  title: (
    <Message
      id="Approvals and signatures reset"
      comment="Title for the info message when the approvals and signatures have been reset."
    />
  ),
  description: (
    <Message
      id="The document needs to be approved and signed again."
      comment="Description text for the info message when the approvals and signatures have been reset."
    />
  ),
  duration: 5000,
});

export const SaveChangesButton = ({
  agreementId,
  onSaveAgreement,
  message,
  children,
  modalClassName,
  buttonClassName,
  buttonKind,
  shouldShowSaveSuccessToast = true,
}: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const boxMap = useCurrentBoxes();
  const boxOrder = useCurrentBoxOrder();
  const pristineState = useSelector(getPristineState);
  const data = useCurrentData();

  const isNotifyParticipant = useSelector(getNotifyParticipant);

  const [isSendModalVisible, setIsSendModalVisible] = useState(false);
  const [isResetDraftApprovalModalVisible, setIsResetDraftApprovalModalVisible] = useState(false);
  const [isResetApprovalModalVisible, setIsResetApprovalModalVisible] = useState(false);
  const [isResetSignatureModalVisible, setIsResetSignatureModalVisible] = useState(false);
  const [
    isResetApprovalSignatureModalVisible,
    setIsResetApprovalSignatureModalVisible,
  ] = useState(false);

  const acceptedSuggestions = useSelector(getAcceptedSuggestions);
  const rejectedSuggestions = useSelector(getRejectedSuggestions);
  const nonPristineBoxes = getNonPristineBoxesMap(boxMap, pristineState);
  const pristineContract = useSelector(getPristineContractData);
  const pristineData = pristineContract?.data;
  const nonPristineData = getNonPristineDataFieldsMap(data, pristineState);
  const hasValidChanges = hasValidContractChanges(
    rejectedSuggestions, acceptedSuggestions, pristineState, boxMap, pristineContract,
  );
  const checked = isNotifyParticipant && hasValidChanges;

  const { disabled } = useSaveChangesButton();

  const account = useSelector(getAccountFromSessionSelector);
  const saveRpcState = useSelector((state) => (
    agreements.getSaveAgreementSelector(state, { id: agreementId })
  ));
  const resetSaveRpcState = () => {
    dispatch(agreements.saveAgreementReset({ id: agreementId }));
  };
  const guestToken = useSelector(getGuestToken);
  const isGuest = Boolean(guestToken);
  const isOwner = isAgreementOwner(account, agreement);
  const myParticipant = getAgreementMyParticipant(agreement);
  const partiallySigned = isPartiallySigned(agreement);
  const dataFieldExternalKeyValueMap = useSelector(getDataFieldExternalKeyValueMap);
  const dataFieldExternalKeyMap = useSelector(getDataFieldExternalKeyMap);
  const isInPreviewMode = useIsInPreviewMode();

  const party = getParticipantParty(agreement, myParticipant?.agreementCompany.id) as Oneflow.Party;

  type FormData = { message: string; subject: string } | Record<string, never>;
  const onSaveChanges = (formData: FormData = {}) => {
    const agreementData = {
      boxOrder,
      boxes: values(nonPristineBoxes),
      data: getDataFieldChangedValues(nonPristineData, pristineData),
      guestToken,
      // notificationTempFlag - will be removed soon (OF-12389)
      // * false if we want to enforce the notification
      // * true if we want to skip the notification
      notificationTempFlag: !hasValidChanges,
      suggestionsToBeAccepted: [],
      suggestionsToBeRejected: [],
      ...formData,
    };

    if (!isEmpty(acceptedSuggestions)) {
      agreementData.suggestionsToBeAccepted = acceptedSuggestions.map(({ id, nodes }) => ({
        suggestionId: id,
        annotation: nodes,
      }));
    }

    if (!isEmpty(rejectedSuggestions)) {
      agreementData.suggestionsToBeRejected = rejectedSuggestions.map(({ id, nodes }) => ({
        suggestionId: id,
        annotation: nodes,
      }));
    }

    toast.remove({
      toastId: PARTIALLY_SIGNED_TOAST_ID,
    });

    dispatch(agreements.saveAgreement({
      id: agreementId,
      data: agreementData,
      pipe: {
        onSuccess: (props) => {
          onSaveAgreement?.(props);
          if (
            isPartiallySigned(agreement)
            && hasValidChanges
            && hasAnyPendingApproverApproved(agreement)
          ) {
            showApprovalsSignaturesResetToast(agreement, hasValidChanges);
          } else if (hasAnyPendingApproverApproved(agreement)) {
            showApprovalsResetToast(agreement);
          } else if (isPartiallySigned(agreement) && hasValidChanges) {
            showSignaturesResetToast(agreement, hasValidChanges);
          }

          if (shouldShowSaveSuccessToast) {
            showSaveSuccessToast(agreement);
          }

          const {
            editableEmptyRequiredElementsCount,
          } = getEmptyRequiredElementsCount({
            isGuest,
            boxMap,
            data,
            dataFieldExternalKeyMap,
            dataFieldExternalKeyValueMap,
          });
          if (editableEmptyRequiredElementsCount <= 0) {
            toast.remove({
              toastId: EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
            });
          } else if (editableEmptyRequiredElementsCount > 0) {
            toast.update({
              id: EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
              description: (
                <Message
                  id="Fill in {count} required fields to proceed"
                  comment="Notification message for missing required fields"
                  values={{ count: editableEmptyRequiredElementsCount }}
                />
              ),
            });
          }
        },
      },
    }));
  };

  const handleSaveChanges = () => {
    if (isInPreviewMode) {
      toast.warning({
        id: 'preview-mode-save',
        title: <Message
          id="Your changes won’t be saved in preview"
          comment="Title for the info message when the user clicks save in preview mode."
        />,
        duration: 5000,
      });
      dispatch(discardChanges(agreement));
      return;
    }

    if (partiallySigned && hasAnyPendingApproverApproved(agreement) && hasValidChanges
      && modalClassName !== 'rich-text-region'
    ) {
      setIsResetApprovalSignatureModalVisible(true);
      return;
    }

    if (partiallySigned && hasValidChanges && modalClassName !== 'rich-text-region') {
      setIsResetSignatureModalVisible(true);
      return;
    }

    if (!isOwner) {
      onSaveChanges(getDefaultNotificationData(party, myParticipant, message) as FormData);
      return;
    }

    if (hasAnyPendingApproverApproved(agreement) && hasValidChanges) {
      setIsResetApprovalModalVisible(true);
      return;
    }

    if (
      (isDraftApproved(agreement) || isDraftPartiallyApproved(agreement))
      && isDraft(agreement) && hasValidChanges
    ) {
      setIsResetDraftApprovalModalVisible(true);
      return;
    }

    if (checked) {
      setIsSendModalVisible(true);
    } else {
      onSaveChanges();
    }
  };

  const apiError = getError(saveRpcState.error);

  return (
    <>
      <ResetDraftApprovalsWarning
        agreementId={agreementId}
        isOpen={isResetDraftApprovalModalVisible}
        onCancel={() => {
          setIsResetDraftApprovalModalVisible(false);
        }}
        onConfirm={() => {
          onSaveChanges();
          setIsResetDraftApprovalModalVisible(false);
        }}
      />
      <ResetApprovalsWarning
        agreementId={agreementId}
        isOpen={isResetApprovalModalVisible}
        onCancel={() => {
          setIsResetApprovalModalVisible(false);
        }}
        onConfirm={() => {
          onSaveChanges();
          setIsResetApprovalModalVisible(false);
        }}
      />
      <ResetSignaturesWarning
        agreementId={agreementId}
        isOpen={isResetSignatureModalVisible}
        onCancel={() => {
          setIsResetSignatureModalVisible(false);
        }}
        onConfirm={() => {
          setIsResetSignatureModalVisible(false);
          if (checked && isOwner) {
            setIsSendModalVisible(true);
          } else {
            onSaveChanges(getDefaultNotificationData(party, myParticipant, message) as FormData);
          }
        }}
      />
      <ResetApprovalsSignaturesWarning
        agreementId={agreementId}
        isOpen={isResetApprovalSignatureModalVisible}
        onCancel={() => {
          setIsResetApprovalSignatureModalVisible(false);
        }}
        onConfirm={() => {
          setIsResetApprovalSignatureModalVisible(false);
          if (checked && isOwner) {
            setIsSendModalVisible(true);
          } else {
            onSaveChanges(getDefaultNotificationData(party, myParticipant, message) as FormData);
          }
        }}
      />
      <SendContract
        agreementId={agreementId}
        onSaveAndNotifyChanges={onSaveChanges}
        isOpen={isSendModalVisible}
        onModalClose={() => {
          setIsSendModalVisible(false);
        }}
        isNotifyParticipants
        isSubjectFieldRequired={false}
        isMessageFieldRequired={false}
        messageTemplatesDisabled
        context="agreementOnesave"
        modalClassName={modalClassName}
      >
        {() => (
          <Button
            id={isInPreviewMode ? 'preview-save' : undefined}
            customClass={buttonClassName}
            kind={buttonKind ?? 'primary'}
            data-testid="confirm"
            disabled={disabled}
            onClick={handleSaveChanges}
          >
            {children}
          </Button>
        )}
      </SendContract>
      {apiError && (
        <Snackbar
          message={<ApiError customMessage={apiError} />}
          setMessage={resetSaveRpcState}
          severity={SEVERITY_TYPES.WARNING}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />
      )}
    </>
  );
};

export default localize<Props>(SaveChangesButton);
