// @flow

import {
  Editor,
  Transforms,
} from 'slate';
import times from 'lodash/times';
import { ReactEditor } from 'slate-react';

import {
  findActiveTableRow,
  getActiveTableCell,
} from './table-utils';
import { createBlockMatrix } from './block-matrix';

const insertRow = (editor: any, length: number, at: any) => {
  Transforms.insertNodes(editor, {
    type: 'table-row',
    children: times(length, () => ({
      type: 'table-cell',
      children: [{
        type: 'paragraph',
        children: [{
          text: '',
        }],
      }],
    })),
  }, {
    at,
  });

  const point = Editor.start(editor, at);

  if (point) {
    Transforms.select(editor, point);
  }
};

const insertAbove = (editor: any) => {
  const blockMatrix = createBlockMatrix(editor);
  if (!blockMatrix) {
    return;
  }
  const [, path] = findActiveTableRow(editor);
  const newPath = [...path];
  const rowIndex = newPath[newPath.length - 1];

  if (rowIndex === 0) {
    insertRow(editor, blockMatrix[rowIndex].length, newPath);
    return;
  }

  let len = 0;
  blockMatrix[rowIndex].forEach((cell) => {
    if (cell.rowSpan > 1 && !cell.isTopOfMergedCell) {
      Transforms.setNodes(editor, {
        rowSpan: cell.node.rowSpan + 1,
      }, {
        at: ReactEditor.findPath(editor, cell.node),
      });
    } else {
      len += 1;
    }
  });

  insertRow(editor, len, newPath);
};

const insertBelow = (editor: any) => {
  const blockMatrix = createBlockMatrix(editor);
  if (!blockMatrix) {
    return;
  }
  const [, path] = findActiveTableRow(editor);
  const activeTableCell = getActiveTableCell(editor);
  const newPath = [...path];
  let rowIndex = newPath[newPath.length - 1];

  const activeRow = blockMatrix[rowIndex];
  const activeCellIndex = activeRow.findIndex((cell) => cell.node === activeTableCell);
  const activeCell = activeRow[activeCellIndex];

  if (activeCell.isTopOfMergedCell) {
    rowIndex += activeCell.rowSpan - 1;
    newPath[newPath.length - 1] = rowIndex;
  }

  newPath[newPath.length - 1] = newPath[newPath.length - 1] + 1;

  if (rowIndex + 1 === blockMatrix.length) {
    insertRow(editor, blockMatrix[0].length, newPath);
    return;
  }

  blockMatrix[rowIndex + 1]
    .filter((cell) => cell.rowSpan > 1 && !cell.isTopOfMergedCell && !cell.isBottomOfMergedCell)
    .forEach((cell) => {
      Transforms.setNodes(editor, {
        rowSpan: cell.node.rowSpan + 1,
      }, {
        at: ReactEditor.findPath(editor, cell.node),
      });
    });

  const newRowLength = blockMatrix[rowIndex + 1].filter((cell) => (
    cell.rowSpan === 1 || cell.isTopOfMergedCell || cell.isBottomOfMergedCell
  )).length;

  insertRow(editor, newRowLength, newPath);
};

const addTableRow = (editor: any, direction: 'above' | 'below') => {
  if (direction === 'above') {
    insertAbove(editor);
    return;
  }

  insertBelow(editor);
};

export default addTableRow;
