/**
 * Type constants sent from backend
 *
 * IMPORTANT: make sure these stay up to date with the backend
 */
const AuditTrailConstants = {
  STATE_INVISIBLE: 0,
  STATE_PUBLIC: 1,
  STATE_PRIVATE: 2,

  TYPE_AGREEMENT_CREATE: 65,
  TYPE_AGREEMENT_PUBLISH: 1,
  TYPE_AGREEMENT_SIGN: 2,
  TYPE_AGREEMENT_DECLINE: 3,
  TYPE_AGREEMENT_UPDATE: 11,
  TYPE_AGREEMENT_EXPIRE: 17,
  TYPE_AGREEMENT_REMINDER_EXPIRE: 20,
  TYPE_AGREEMENT_CANCEL: 21,
  TYPE_AGREEMENT_REVIVE: 51,
  TYPE_AGREEMENT_SIGNATURE_RESET: 52,
  TYPE_AGREEMENT_CONTENT_UPDATE: 53,
  TYPE_PDF_DOWNLOAD: 58,

  TYPE_LIFECYCLE_END: 14,
  TYPE_LIFECYCLE_NEW: 15,
  TYPE_LIFECYCLE_START: 47,
  TYPE_LIFECYCLE_TERMINATE: 22,

  TYPE_LIFECYCLE_REMINDER_END: 18,
  TYPE_LIFECYCLE_REMINDER_NEW: 19,

  TYPE_PARTICIPANT_PUBLISH: 48,
  TYPE_PARTICIPANT_SIGN: 4,
  TYPE_PARTICIPANT_SIGN_ATTACHMENT: 50,
  TYPE_PARTICIPANT_DECLINE: 5,
  TYPE_PARTICIPANT_ADD: 6,
  TYPE_PARTICIPANT_DELEGATE: 38,
  TYPE_PARTICIPANT_DISABLE: 7,
  TYPE_PARTICIPANT_EMAIL_FEEDBACK: 24,
  TYPE_PARTICIPANT_FIRST_VISIT: 25,
  TYPE_PARTICIPANT_UPDATE: 26,
  TYPE_PARTICIPANT_UPDATE_ITEM: 31,

  TYPE_PARTY_ADD: 32,
  TYPE_PARTY_UPDATE: 33,
  TYPE_PARTY_UPDATE_ITEM: 34,
  TYPE_PARTY_DISABLE: 36,

  TYPE_MESSAGE_ADD: 27,

  // Suggestion Types
  TYPE_SUGGESTION_ACCEPTED: 62,

  TYPE_OPEN_WITH_2FA: 57,

  TYPE_BOX_UPDATE: 39,
  TYPE_BOX_ENABLE: 40,
  TYPE_BOX_DISABLE: 41,
  TYPE_BOX_UPDATE_LAYOUT: 45,

  TYPE_DATA_CREATE: 42,
  TYPE_DATA_UPDATE: 43,
  TYPE_DATA_REMOVE: 44,

  // Input element types
  TYPE_CHECKBOX: 1,
  TYPE_FIELD: 2,
  TYPE_RADIO_BUTTON: 3,

  // Message types
  TYPE_MESSAGE: 0,
  TYPE_INVITE: 1,
  TYPE_REMINDER: 2,
  TYPE_CONTACT_ME: 3, // Deprecated but there might still exist old events with this type

  // Box types
  TYPE_DURATION: 2,
  TYPE_DOCUMENT: 3,
  TYPE_ATTACHMENTS: 4,

  TYPE_TEXT: 10,
  TYPE_FORM: 12,
  TYPE_PRODUCTS: 13,
  TYPE_PRODUCTSUM: 14,
  TYPE_VIDEO: 15,

  // Approval flow types
  TYPE_PARTICIPANT_INVITED_TO_APPROVE_DRAFT: 59,
  TYPE_APPROVAL_FLOW_STARTED: 60,
  TYPE_PARTICIPANT_APPROVE: 66,
  TYPE_AGREEMENT_FLOW_COMPLETE: 67,
  TYPE_AGREEMENT_FLOW_RESET: 68,
  TYPE_AGREEMENT_APPROVAL_RESET: 71,
};

export default AuditTrailConstants;
