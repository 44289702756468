import { connect } from 'react-redux';

import tagsReducer from 'reducers/entities/tags';

import EditTag from './edit-tag';

export const mapStateToProps = (state, { tag }) => ({
  formState: tagsReducer.getUpdateSelector(state, { id: tag.id }),
});

export const mapDispatchToProps = (dispatch, { tag }) => ({
  resetFormState: () => {
    dispatch(
      tagsReducer.updateTagReset({
        id: tag.id,
      }),
    );
  },
  onSubmit: (formData) => {
    dispatch(
      tagsReducer.updateTag({
        id: tag.id,
        data: formData,
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditTag);
