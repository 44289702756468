import { forwardRef } from 'react';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button/button-fc';
import CircularSpinnerIcon from 'components/icons/circular-spinner';
import DeleteIcon from 'components/icons/delete';

type Props = {
  onClick: () => void,
  isLoading: boolean,
  disabled?: boolean,
};

export const MoveToTrashButton = forwardRef<HTMLButtonElement, Props>(({
  onClick,
  isLoading,
  disabled = undefined,
}, ref) => (
  <Button
    ref={ref}
    kind="primary"
    color="red"
    data-testid="move-to-trash"
    icon={isLoading ? CircularSpinnerIcon : DeleteIcon}
    disabled={isLoading || disabled}
    onClick={onClick}
  >
    <Message
      id="Move to trash"
      comment="Text for button to move to trash action."
    />
  </Button>
));

MoveToTrashButton.displayName = 'MoveToTrashButton';
