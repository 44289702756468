// @flow

import { connect } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import agreementsReducer from 'reducers/entities/agreements';
import { SHARED_WITH_ME_ID } from 'reducers/entities/workspaces';
import { getFeatureSelector, getPositionFromSessionSelector } from 'reducers/session';

import { checkAcl } from 'components/acl';

import ExportContracts, { type Props } from './export-contracts';

type StateProps = {|
  agreementsQuery: Query,
  email: string,
  isEmptyState: boolean,
  showExportButton: boolean,
  workspaceId: number,
|};

export type OwnProps = $Diff<Props, StateProps> & {
  queryName: string,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state: State, { queryName }: OwnProps) => {
  const agreementExportFeature = getFeatureSelector(state, { feature: 'agreementExport' });
  const agreementsQuery = agreementsReducer.getQuerySelector(state, { name: queryName });
  const currentWorkspace = getCurrentWorkspaceSelector(state);
  const isAllowedToExport = checkAcl(currentWorkspace.acl, 'collection:agreement:export');
  const isSharedWithMeWorkspace = currentWorkspace.id === SHARED_WITH_ME_ID;
  const isWithinExportLimit = agreementsQuery.count <= agreementExportFeature.limit;
  const isExportAvailable = (
    Boolean(agreementExportFeature.enabled)
    && isWithinExportLimit
    && isAllowedToExport
    && !isSharedWithMeWorkspace
  );

  return {
    agreementsQuery,
    email: getPositionFromSessionSelector(state).email,
    showExportButton: isExportAvailable,
    workspaceId: currentWorkspace.id,
  };
};

type DispatchProps = $Exact<{}>;

type MapDispatchToProps = () => DispatchProps;
export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, OwnProps, StateProps, $Exact<DispatchProps>, State, _>(
  mapStateToProps,
  mapDispatchToProps,
)(ExportContracts);
