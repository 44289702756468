// @flow

import {
  Editor,
  Transforms,
  Node,
  Path,
  Element as SlateElement,
} from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import {
  isEmpty, last, reverse, uniqueId,
} from 'lodash';

import { getTemporaryEditorInstance } from 'components/rich-text-editor/slate-editor';
import { isEditorActiveAt } from 'components/rich-text-editor/editor-plugins/plugin-utils';

export const unwrapAnnotations = (fragment) => {
  const editor = getTemporaryEditorInstance();
  editor.children = fragment;
  Transforms.select(editor, {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  });
  Transforms.unwrapNodes(editor, {
    match: (n) => n.type === 'annotation',
  });
  Transforms.removeNodes(editor, {
    match: (n) => n.type === 'suggestion',
  });
  return editor.children;
};

export const hasAnnotationInSelection = (editor: any, selection: any) => {
  if (!isEditorActiveAt(editor, selection)) {
    return false;
  }

  const [annotation] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'annotation',
    at: selection,
  });

  return Boolean(annotation);
};

const findSuggestionMatch = (editor, path) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === 'suggestion',
    mode: 'lowest',
    at: path,
  });

  return match;
};

export const getAnnotationElementPath = (editor: any, annotationId: number) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n)
      && SlateElement.isElement(n)
      && n.type === 'annotation'
      && n.annotation?.id === annotationId
    ),
    mode: 'lowest',
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });

  if (!match) {
    return null;
  }

  const [, path] = match;
  const suggestionMatch = findSuggestionMatch(editor, path);

  if (suggestionMatch) {
    const [, suggestionPath] = suggestionMatch;

    return suggestionPath;
  }

  return path;
};

export const focusNodeAfterAnnotation = (
  editor: any,
  annotationId: number,
  isSuggestion: boolean,
) => {
  const firstAnnotationPath = getAnnotationElementPath(editor, annotationId);

  if (!firstAnnotationPath) {
    return;
  }

  const annotationEdge = Editor.end(editor, firstAnnotationPath);

  if (!annotationEdge) {
    return;
  }

  const [, parentElementPath] = Editor.parent(editor, annotationEdge);
  let nextElement;

  if (isSuggestion) {
    const [, grandParentElementPath] = Editor.parent(editor, parentElementPath);
    nextElement = Editor.next(editor, { at: grandParentElementPath });
  } else {
    nextElement = Editor.next(editor, { at: parentElementPath });
  }

  if (!nextElement) {
    return;
  }

  const [, nextElementPath] = nextElement;
  const [startEdge] = Editor.edges(editor, nextElementPath);

  Transforms.select(editor, startEdge);
  ReactEditor.focus(editor);
};

export const wrapComment = (editor: any, selection: any, annotation: any) => {
  Transforms.wrapNodes(editor, {
    type: 'annotation',
    annotation,
    children: [],
  }, {
    at: editor.selection || selection,
    split: true,
  });
};

const isAnnotation = (node) => (
  !Editor.isEditor(node)
  && SlateElement.isElement(node)
  && node.type === 'annotation'
);

export const insertSuggestion = (
  editor: any,
  selection: any,
  suggestedText: string,
  readOnly: boolean,
) => {
  const _id = Number(uniqueId());
  const annotation = { _id, annotationType: 'suggestion' };
  wrapComment(editor, selection, annotation);

  const getEditorSelection = () => {
    if (!readOnly && editor.selection) {
      return editor.selection;
    }

    return {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    };
  };

  const match = last(Array.from(Editor.nodes(editor, {
    match: (n) => (
      isAnnotation(n)
      && (n.annotation._id === annotation?._id
      || n.annotation.id === annotation?.id)
    ),
    mode: 'lowest',
    at: getEditorSelection(),
  })));

  if (isEmpty(match)) {
    return;
  }

  const [, lastAnnotationPath] = match;

  const [lastLeafNode, lastLeafPath] = Editor.last(editor, lastAnnotationPath);

  const suggestionNode = {
    type: 'suggestion',
    children: [{
      ...lastLeafNode,
      text: suggestedText,
    }],
  };

  Transforms.insertNodes(
    editor,
    suggestionNode,
    { at: Path.next(lastLeafPath) },
  );
};

export const unwrapComment = (editor: any, annotationId: number) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => (
      isAnnotation(n) && n.annotation?.id === annotationId
    ),
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });
};

export const findAnnotationMatch = (editor, annotationId) => Array.from(Editor.nodes(editor, {
  match: (n) => isAnnotation(n) && n.annotation.annotationType === 'suggestion' && n.annotation.id === annotationId,
  mode: 'lowest',
  at: {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  },
}));

export const removeEmptyNodes = (editor, annotationPath) => {
  const parentPath = Path.parent(annotationPath);
  const [parentNode] = Editor.node(editor, parentPath);

  const parentHasNoSibling = (
    Editor.next(editor, { at: parentPath }) === undefined
    && Editor.previous(editor, { at: parentPath }) === undefined
  );

  if (parentHasNoSibling) {
    return;
  }

  if (Editor.isEmpty(editor, parentNode)) {
    Transforms.removeNodes(editor, {
      at: Path.parent(annotationPath),
    });
  }
};

export const rejectSuggestion = (
  editor,
  annotationId,
) => {
  const annotations = findAnnotationMatch(editor, annotationId);
  annotations.forEach(([, annotationPath]) => {
    const suggestionMatch = findSuggestionMatch(editor, annotationPath);

    if (suggestionMatch) {
      const [, suggestionPath] = suggestionMatch;

      Transforms.removeNodes(editor, {
        at: suggestionPath,
      });

      removeEmptyNodes(editor, annotationPath);
    }

    Transforms.unwrapNodes(editor, {
      at: annotationPath,
    });
  });
  return editor.children;
};

export const unwrapSuggestions = (fragment: any, suggestionIds: number[]) => {
  const editor = getTemporaryEditorInstance();
  editor.children = fragment;

  suggestionIds.forEach((id) => {
    rejectSuggestion(editor, id);
  });
  return editor.children;
};

export const acceptSuggestion = (
  editor,
  annotationId,
) => {
  const annotations = findAnnotationMatch(editor, annotationId);
  reverse(annotations).forEach(([, annotationPath]) => {
    const suggestionMatch = findSuggestionMatch(editor, annotationPath);

    if (!suggestionMatch) {
      Transforms.removeNodes(editor, {
        mode: 'lowest',
        at: annotationPath,
      });

      removeEmptyNodes(editor, annotationPath);
      return;
    }

    const [, suggestionPath] = suggestionMatch;

    Transforms.removeNodes(editor, {
      match: (node, path) => Path.isSibling(path, suggestionPath),
      mode: 'lowest',
      at: annotationPath,
    });
    Transforms.unwrapNodes(editor, {
      match: (node) => node.type === 'suggestion',
      mode: 'lowest',
      at: annotationPath,
    });
    Transforms.unwrapNodes(editor, {
      at: annotationPath,
    });
  });
};

export const findActiveAnnotationElement = (editor: any) => {
  if (!isEditorActiveAt(editor, editor.selection)) {
    return null;
  }

  const [match] = Editor.nodes(editor, {
    match: isAnnotation,
    mode: 'lowest',
  });

  return match;
};

export const getActiveAnnotationElement = (editor: any) => {
  if (!editor) {
    return null;
  }

  const match = findActiveAnnotationElement(editor);

  if (!match) {
    return null;
  }

  return match[0];
};

export const showAnnotation = (
  editor: any,
  annotationId: number,
  hasTopbar: boolean,
  isSmallScreen,
) => {
  const path = getAnnotationElementPath(editor, annotationId);

  if (!path || isSmallScreen) {
    return;
  }

  const annotationEdge = Editor.end(editor, path);

  const domPoint = ReactEditor.toDOMPoint(editor, annotationEdge);

  const node = domPoint[0];
  if (!node) {
    return;
  }
  const { parentElement } = node;

  const yOffset = hasTopbar ? 112 : 4;
  const top = parentElement.getBoundingClientRect().top + window.scrollY - yOffset;

  if (window.location.pathname.includes('documents')) {
    parentElement.scrollIntoView();
  } else {
    window.scrollTo({ top });
  }
};

export const openAnnotation = (annotationId: number) => {
  document.getElementById(`annotation-${annotationId}`)?.click();
};

export const changeAnnotationColor = (annotationId: number, color: string) => {
  const element = document.getElementById(`annotation-${annotationId}`);
  if (element) {
    element.style.backgroundColor = color;
  }
};

export const showAndFocusAnnotation = (
  editor: any,
  message: any,
  isGuest: boolean,
  isSmallScreen: boolean,
) => {
  const hasTopbar = !isGuest;
  showAnnotation(
    editor,
    message.id,
    hasTopbar,
    isSmallScreen,
  );

  setTimeout(() => {
    openAnnotation(message.id);
  }, 100);
};

export const isOrphanAnnotation = (annotationId: number, annotation: any, boxesMap: any) => {
  if (isEmpty(annotation)) {
    return true;
  }
  if (!boxesMap[annotation.boxId] || boxesMap[annotation.boxId]._removed) {
    return true;
  }
  if (!getAnnotationElementPath(annotation.editor, annotationId)) {
    return true;
  }
  return false;
};

export const resetNodes = (
  editor: Editor,
  options: {
    nodes?: Node | Node[],
  } = {},
) => {
  if (!options.nodes) {
    return;
  }

  const children = [...editor.children];

  children.forEach((node) => editor.apply({ type: 'remove_node', path: [0], node }));

  const nodes = Node.isNode(options.nodes) ? [options.nodes] : options.nodes;

  nodes.forEach((node, i) => editor.apply({ type: 'insert_node', path: [i], node }));
};

export const updateNodesWithoutApplying = (editor, fn) => {
  const originalChildren = editor.children;

  HistoryEditor.withoutSaving(editor, fn);
  const updatedNodes = editor.children;

  resetNodes(editor, { nodes: originalChildren });

  return updatedNodes;
};
