import { isEmpty } from 'lodash';
import { TYPE_IS_INFLUENCER } from './constants';

export const isPendingStateApprover = (participant) => {
  if (isEmpty(participant) || isEmpty(participant.roles)) {
    return false;
  }

  return (
    participant.type === TYPE_IS_INFLUENCER && participant.roles[0].role === 'PENDING_STATE_APPROVER'
  );
};
