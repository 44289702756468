import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Message } from '@oneflowab/pomes';

import Confirmable from 'components/confirmable';
import { ApproveButton } from 'components/buttons/approve';
import DocumentActionErrorBody from 'components/document-action-error';
import { TryAgainButton } from 'components/buttons';

import agreementsReducer from 'reducers/entities/agreements';

import { ApproveBubbles } from './approve-bubbles/approve-bubbles';
import style from './approve-draft.module.scss';

export type Props = {
  sendToNextDraftApprover?: boolean;
  agreementId: number;
  internalApproverId: number;
  onStepComplete: () => void;
  onClose: () => void;
  setShowApproveDraftModal: (value: boolean) => void;
  agreementChecksum?: string;
  autoSendEnabled?: boolean;
  modalKey: string;
};

export const ApproveDraftModal = ({
  sendToNextDraftApprover,
  agreementId,
  internalApproverId,
  onStepComplete,
  onClose,
  setShowApproveDraftModal,
  agreementChecksum,
  autoSendEnabled,
  modalKey,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const renderInformationText = () => {
    if (sendToNextDraftApprover) {
      return (
        <Message
          id="You are about to approve this document.{breakLine} The document can be modified, but
            approvals would be reset and you will need to approve again."
          values={{
            breakLine: <br />,
          }}
          comment="Tells the user that after approving the document it will be sent for the next approval."
        />
      );
    }

    if (autoSendEnabled) {
      return (
        <Message
          id="The document will be {sentForSigning} after you approve."
          values={{
            sentForSigning:
  <span className={style.DocumentState}>
    <Message
      id="sent for signing"
      comment="The document will be sent for signing after you approve."
    />
  </span>,
          }}
          comment="Tells the user that the document will be sent to the participants after approval."
        />
      );
    }

    return (
      <Message
        id="After you approve this document, it will be {readyForSending}."
        values={{
          readyForSending:
  <span className={style.DocumentState}>
    <Message
      id="ready for sending"
      comment="The document will be ready for sending after you approve."
    />
  </span>,
        }}
        comment="Tells the user that the document will be ready for sending after approval."
      />
    );
  };

  const onSuccess = () => {
    setIsLoading(false);
    onStepComplete();
  };

  const onFailure = (submitError) => {
    setError(submitError);
    setIsLoading(false);
  };

  const approveDraft = () => {
    setIsLoading(true);
    setShowApproveDraftModal(true);
    dispatch(agreementsReducer.approveDraft({
      id: agreementId,
      data: {
        agreementId,
        participantId: internalApproverId,
        checksum: agreementChecksum,
      },
      pipe: {
        onSuccess,
        onFailure,
      },
    }));
  };

  const handleConfirm = () => {
    approveDraft();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const renderBody = () => {
    if (error) {
      return <DocumentActionErrorBody error={error} />;
    }

    return (
      <div className={style.BodyContainer}>
        <ApproveBubbles
          agreementId={agreementId}
        />
        <p>
          {renderInformationText()}
        </p>
      </div>
    );
  };

  const getActions = ({ closeConfirmation }) => {
    if (error) {
      return (
        <div className={style.TryAgainButtonContainer}>
          <TryAgainButton onClick={closeConfirmation} />
        </div>
      );
    }

    return (
      <div className={style.Confirm}>
        <ApproveButton
          onClick={() => handleConfirm()}
          smallSize
          disabled={isLoading}
        />
      </div>
    );
  };

  return (
    <Confirmable
      header={(
        <Message
          id="Approve document?"
          comment="Used as the title in modal"
        />
    )}
      body={renderBody()}
      actions={getActions}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      onClose={onClose}
      onOpen={handleOpen}
      isOpen={isOpen}
      modalKey={modalKey}
      customBodyClass={style.Body}
    />
  );
};
