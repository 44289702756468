// @flow

import * as React from 'react';

export type Props = {
  children: React.Node,
  onEnter?: () => void,
};

export const handleKeyEvent = (event: KeyboardEvent, onEnter: () => void) => {
  if (event.key !== 'Enter') {
    return;
  }

  if (event.target && (
    event.target.tagName === 'TEXTAREA' || event.target.tagName === 'INPUT')
  ) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();
  onEnter();
};

export default class WithOnEnter extends React.PureComponent<Props> {
  static defaultProps = {
    onEnter: undefined,
  };

  componentDidMount() {
    const { onEnter } = this.props;
    if (onEnter) {
      document.addEventListener('keydown', this.eventHandler);
    }
  }

  componentWillUnmount() {
    const { onEnter } = this.props;
    if (onEnter) {
      document.removeEventListener('keydown', this.eventHandler);
    }
  }

  eventHandler = (event: KeyboardEvent) => {
    const {
      onEnter,
    } = this.props;

    return handleKeyEvent(event, onEnter);
  };

  render() {
    const { children } = this.props;
    return children;
  }
}
