import { ReactEditor } from 'slate-react';
import isEmpty from 'lodash/isEmpty';

const isGreaterThanOrDifferenceWithin = (x, y, delta) => (
  x > y || Math.abs(x - y) <= delta
);

const isRectangleCoveringBy = (targetRectangle, suspectedRectangle) => (
  isGreaterThanOrDifferenceWithin(targetRectangle.top, suspectedRectangle.top, 1)
  && isGreaterThanOrDifferenceWithin(targetRectangle.left, suspectedRectangle.left, 1)
  && isGreaterThanOrDifferenceWithin(suspectedRectangle.bottom, targetRectangle.bottom, 1)
  && isGreaterThanOrDifferenceWithin(suspectedRectangle.right, targetRectangle.right, 1)
  && isGreaterThanOrDifferenceWithin(suspectedRectangle.width, targetRectangle.width, 1)
  && isGreaterThanOrDifferenceWithin(suspectedRectangle.height, targetRectangle.height, 1)
);

const removeCoveredRectangles = (rectangles) => {
  const coveredRectangles = {};
  const result = [rectangles[0]];

  for (let i = 1; i < rectangles.length; i += 1) {
    const targetRectangle = rectangles[i];
    let isTargetCovered;

    for (let j = i - 1; j > 0; j -= 1) {
      if (coveredRectangles[j]) {
        // eslint-disable-next-line no-continue
        continue;
      }

      isTargetCovered = isRectangleCoveringBy(targetRectangle, rectangles[j]);

      if (isTargetCovered) {
        coveredRectangles[i] = true;
        break;
      }
    }

    if (!isTargetCovered) {
      result.push(targetRectangle);
    }
  }

  return result;
};

export const filterEmptyRectangles = (clientRects) => (
  clientRects.filter((rect) => rect.width > 0 && rect.height > 0)
);

export const getRelativeRectangles = (rangeRectangles, relativeParent = null) => {
  if (!relativeParent) {
    return rangeRectangles;
  }

  return rangeRectangles.map((rectangle) => {
    const relativeParentRect = relativeParent.getBoundingClientRect();

    return {
      top: rectangle.top - relativeParentRect.top,
      y: rectangle.top - relativeParentRect.top,

      left: rectangle.left - relativeParentRect.left,
      x: rectangle.left - relativeParentRect.left,

      bottom: rectangle.bottom - relativeParentRect.bottom,
      right: rectangle.right - relativeParentRect.right,

      width: rectangle.width,
      height: rectangle.height,
    };
  });
};

export const getRangeRectangles = (range, relativeParent = null) => {
  const clientRects = filterEmptyRectangles(Array.from(range.getClientRects()));
  if (isEmpty(clientRects)) {
    return [];
  }

  if (clientRects.length === 1) {
    return getRelativeRectangles(clientRects, relativeParent);
  }

  return getRelativeRectangles(removeCoveredRectangles(clientRects), relativeParent);
};

export const getSelectedRectangles = (editor, selection, relativeParent = null) => {
  try {
    const range = ReactEditor.toDOMRange(editor, selection);
    return getRangeRectangles(range, relativeParent);
  } catch {
    return [];
  }
};
