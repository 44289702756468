import * as React from 'react';
import { localize, Message, MessageTranslator } from '@oneflowab/pomes';
import { FormRenderProps } from 'react-final-form';

import ModalForm from 'hocs/modal-form';
import {
  NextButton,
  PreviousButton,
} from 'components/buttons';

import Field from 'components/field';
import PhoneField from 'components/phone-field';

import style from './sms.module.scss';

type ModalData = {
  phoneNumber: string,
  didNotReceiveCode: boolean
};

export type Props = {
  modalData: ModalData,
  message: MessageTranslator,
  myParticipantWhenSignatory: AgreementParticipant,
  onSyncStepData: (arg1: ModalData) => void,
  onStepComplete: () => void,
  onPreviousStep: () => void,
  onClose: () => void,
  modalKey: string,
};

export class SmsComponent extends React.PureComponent<Props> {
  getActions = ({
    formProps,
  }: FormRenderProps) => {
    const { onPreviousStep } = this.props;
    const disabled = Boolean(
      formProps.validating
      || formProps.invalid,
    );

    return (
      <div className={style.Buttons}>
        <PreviousButton onClick={onPreviousStep} />
        <NextButton
          onClick={formProps.handleSubmit}
          disabled={disabled}
        />
      </div>
    );
  };

  getValidParticipantPhoneNumber() {
    const { myParticipantWhenSignatory } = this.props;

    if (myParticipantWhenSignatory.phoneNumber) {
      return myParticipantWhenSignatory.phoneNumber;
    }

    return null;
  }

  getLabel = () => (
    <div className={style.SmsLabel}>
      <Message
        id="Your mobile number"
        comment="Phone number placeholder when signing the contract with sms code."
      />
    </div>
  );

  getDidNotReceiveCodeInfo = () => {
    const { modalData: { didNotReceiveCode } } = this.props;

    if (didNotReceiveCode) {
      return (
        <div className={style.CodeInfo}>
          <h3>
            <Message
              id="Didn't receive a code?"
              comment="Button label that redirects to previous modal step, if the user did not receive SMS code."
            />
          </h3>
          <Message
            id="Please make sure that you have entered the correct mobile number and that you have a working cell phone reception."
            comment="Text shown when user clicked that they did not receive SMS code to sign with."
          />
        </div>
      );
    }

    return null;
  }

  handleSubmit = (formData: {
    phoneNumber: string
  }) => {
    const {
      onSyncStepData,
      onStepComplete,
    } = this.props;

    onSyncStepData(formData);
    onStepComplete();
  }

  renderBody() {
    const { message } = this.props;

    return (
      <div className={style.SmsContainer}>
        <Message
          id="Fill in your mobile number and click 'Next' to receive your sign code."
          comment="Modal information shown when user should input mobile number in order to sign with sms code."
        />
        {this.getLabel()}
        <div className={style.PhoneNumberContainer}>
          <Field
            name="phoneNumber"
            label={message({
              id: 'Mobile number',
              comment: 'Phone number label when signing the contract with sms code.',
            })}
            placeholder={message({
              id: 'Enter phone number',
              comment: 'Phone number placeholder when signing the contract with sms code.',
            })}
            component={PhoneField}
            maxLength={50}
            phone
            required
          />
        </div>
        {this.getDidNotReceiveCodeInfo()}
      </div>
    );
  }

  render() {
    const {
      onClose,
      modalKey,
    } = this.props;
    const formState: Record<string, any> = {};

    return (
      <ModalForm
        title={(
          <Message
            id="Sign with SMS"
            comment="Modal title for signing with electronic ID."
          />
        )}
        body={this.renderBody()}
        onSubmit={this.handleSubmit}
        actions={this.getActions}
        formState={formState}
        initialValues={{
          phoneNumber: this.getValidParticipantPhoneNumber(),
        }}
        onClose={onClose}
        modalKey={modalKey}
      />
    );
  }
}

export default localize<Props>(SmsComponent);
