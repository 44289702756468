import React from 'react';
import { useDispatch } from 'react-redux';
import type { MouseEventHandler, ReactNode } from 'react';

import { checkAcl } from 'components/acl';
import { localize, Message } from '@oneflowab/pomes';
import {
  LINK_TYPE_EXTENSION,
  LINK_TYPE_RELATION,
  LINK_TYPE_REPLACEMENT,
  LINK_TYPE_SUBCONTRACT,
  LINK_TYPE_EXTERNAL_URL,
} from 'agreement/constants';
// eslint-disable-next-line import/named
import { addContractLink } from 'oneflow-client/agreement-links';
// eslint-disable-next-line import/named
import { isUrlValid } from 'forms/validators/url';
import type { MessageTranslator } from '@oneflowab/pomes';

import Confirmable from 'components/confirmable';
import { MenuItem } from 'components/menu-item';

import Link from 'components/icons/link';
import NewCross from 'components/icons/new-cross';
import CircularSpinner from 'components/icons/circular-spinner';
import SmallAdd from 'components/icons/small-add';

import Button from 'components/button';
import SelectField from 'components/select-field';
// eslint-disable-next-line import/named
import { ContractSelector } from 'components/contract-selector';
import ContractLinkExternalSelector from 'components/contract-link-external-selector';
import MiniContractCard from 'components/mini-contract-card';
// eslint-disable-next-line import/named
import { ContractCard } from 'components/contract-card';
import { HelpCenterLink } from 'components/help-center-link';

import agreements from 'reducers/entities/agreements';

import style from './link-to-contract.module.scss';

// TODO: replace this with `Oneflow.Agreement` when contract entity has `links` in its types
type AgreementWithLinks = Oneflow.Agreement & {
  links: Oneflow.AgreementLink[],
};

export type Props = {
  agreement: AgreementWithLinks,
  message: MessageTranslator,
  isRenderedInLinkList?: boolean,
};

type LinkType = {
  value: number,
  type: (
    typeof LINK_TYPE_RELATION |
    typeof LINK_TYPE_SUBCONTRACT |
    typeof LINK_TYPE_REPLACEMENT |
    typeof LINK_TYPE_EXTENSION |
    typeof LINK_TYPE_EXTERNAL_URL
  ),
  label: string,
  isSelectedTheTarget: boolean,
}

const isExternalLink = (linkType: LinkType) => linkType?.type === LINK_TYPE_EXTERNAL_URL;

const linkAlreadyExists = (
  links: Oneflow.AgreementLink[] = [],
  selectedContract: Oneflow.Agreement | null,
  linkType: LinkType,
) => {
  if (!selectedContract) {
    return false;
  }
  const sameContract = (link: Oneflow.AgreementLink) => (
    link.targetAgreement?.id === selectedContract.id || link.agreement?.id === selectedContract.id
  );
  const sameLinkType = (link: Oneflow.AgreementLink) => link.linkType === linkType?.type;
  return Boolean(links.find((link) => sameContract(link) && sameLinkType(link)));
};

export const LinkToContract = ({ agreement, message, isRenderedInLinkList }: Props) => {
  const dispatch = useDispatch();
  const [selectedContract, setSelectedContract] = React.useState<Oneflow.Agreement | null>(null);
  const [targetExternalUrl, setTargetExternalUrl] = React.useState<string>('');
  const [targetExternalUrlTitle, setTargetExternalUrlTitle] = React.useState<string>('');
  const [validationError, setValidationError] = React.useState<ReactNode>('');
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const searchContainerRef = React.useRef(null);
  const isMobile = window.innerWidth < 760;
  const typeOptions = [
    {
      label: message({ id: 'Relates to', comment: 'Dropdown label for choosing a type of a contract link' }),
      value: 1,
      type: LINK_TYPE_RELATION,
      isSelectedTheTarget: true,
    },
    {
      label: message({ id: 'Is the main contract to', comment: 'Dropdown label for choosing a type of a contract link' }),
      value: 2,
      type: LINK_TYPE_SUBCONTRACT,
      isSelectedTheTarget: false,
    },
    {
      label: message({ id: 'Is a sub-contract to', comment: 'Dropdown label for choosing a type of a contract link' }),
      value: 3,
      type: LINK_TYPE_SUBCONTRACT,
      isSelectedTheTarget: true,
    },
    {
      label: message({ id: 'Replaces', comment: 'Dropdown label for choosing a type of a contract link' }),
      value: 4,
      type: LINK_TYPE_REPLACEMENT,
      isSelectedTheTarget: true,
    },
    {
      label: message({ id: 'Is replaced by', comment: 'Dropdown label for choosing a type of a contract link' }),
      value: 5,
      type: LINK_TYPE_REPLACEMENT,
      isSelectedTheTarget: false,
    },
    {
      label: message({ id: 'Amends', comment: 'Dropdown label for choosing a type of a contract link' }),
      value: 6,
      type: LINK_TYPE_EXTENSION,
      isSelectedTheTarget: true,
    },
    {
      label: message({ id: 'Is amended by', comment: 'Dropdown label for choosing a type of a contract link' }),
      value: 7,
      type: LINK_TYPE_EXTENSION,
      isSelectedTheTarget: false,
    },
    {
      label: message({ id: 'External', comment: 'Dropdown label for choosing a type of a contract link' }),
      value: 8,
      type: LINK_TYPE_EXTERNAL_URL,
      isSelectedTheTarget: true,
    },
  ];
  const defaultOption = typeOptions.find(
    (option) => option.type === LINK_TYPE_RELATION,
  ) as LinkType;
  const [linkType, setLinkType] = React.useState<LinkType>(defaultOption);

  const resetState = () => {
    setSelectedContract(null);
    setLinkType(defaultOption);
    setSuccess(false);
    setLoading(false);
    setError(false);
    setValidationError('');
    setTargetExternalUrl('');
    setTargetExternalUrlTitle('');
  };

  const isSelectedLinkValid = React.useCallback(() => {
    if (isExternalLink(linkType) && !isUrlValid(targetExternalUrl, ['http', 'https'])) {
      return false;
    }
    if (!selectedContract && !isExternalLink(linkType)) {
      return false;
    }
    if (agreement.id === selectedContract?.id) {
      return false;
    }
    if (linkAlreadyExists((agreement).links, selectedContract, linkType)) {
      return false;
    }
    return true;
  }, [agreement, linkType, selectedContract, targetExternalUrl]);

  const formIsDisabled = !isSelectedLinkValid() || (selectedContract && !checkAcl(selectedContract.acl, 'agreement:link:create')) || loading;

  React.useEffect(() => {
    if (!selectedContract) {
      setValidationError('');
      return;
    }

    let errorMessage: ReactNode = '';

    if (!checkAcl(selectedContract.acl, 'agreement:link:create')) {
      errorMessage = message({
        id: 'You do not have permission to add this contract as a link.',
        comment: 'Error message to show you do not have permission to add this contract as a link.”',
      });
    }

    if (agreement.id === selectedContract.id) {
      errorMessage = message({
        id: 'You can not link a contract to itself. Try linking another contract.',
        comment: 'Error message saying that it is impossible to connect a contract to itself.',
      });
    } else if (linkAlreadyExists(agreement.links, selectedContract, linkType)) {
      errorMessage = message({
        id: 'This contract is already linked with this link type.',
        comment: 'Error message saying that the selected contract is already linked to the clicked contract with the same type of linking.',
      });
    }

    setValidationError(errorMessage);
  }, [linkType, selectedContract]);

  const fetchLinks = React.useCallback(() => {
    dispatch(agreements.fetchContractLinks({
      id: agreement.id,
    }));
  }, [agreement, dispatch]);

  const addLink = React.useCallback(async () => {
    const childAgreementId = linkType?.isSelectedTheTarget ? agreement.id : selectedContract?.id;
    const parentContractId = linkType?.isSelectedTheTarget ? selectedContract?.id : agreement.id;

    const linkingData = {
      agreementId: childAgreementId,
      targetAgreementId: parentContractId,
      targetExternalUrl: isExternalLink(linkType) ? targetExternalUrl : undefined,
      targetExternalUrlTitle: isExternalLink(linkType) ? targetExternalUrlTitle : undefined,
      linkType: linkType?.type,
    };

    try {
      setSuccess(false);
      setError(false);
      setLoading(true);
      await addContractLink(linkingData);

      setLoading(false);
      setSelectedContract(null);
      setSuccess(true);

      fetchLinks();
    } catch (err) {
      setError(err as any);
      setLoading(false);
    }
  }, [
    agreement,
    fetchLinks,
    linkType,
    selectedContract,
    targetExternalUrl,
    targetExternalUrlTitle,
  ]);

  const linkToLabel = (
    <>
      <Message
        id="Link to"
        comment="Action to connect the contract to another contract."
      />
      ...
    </>
  );

  const getChildren = (onClick: MouseEventHandler) => {
    const hasAccessToLinkContract = checkAcl(agreement.acl, 'agreement:link:create');

    if (!hasAccessToLinkContract) {
      return null;
    }

    return (
      <>
        <MenuItem
          icon={isRenderedInLinkList ? <SmallAdd height="10px" /> : Link}
          label={linkToLabel}
          className={isRenderedInLinkList ? style.LinkToButton : ''}
          onClick={onClick}
          disabled={!checkAcl(agreement.acl, 'agreement:link:create')}
        />
      </>
    );
  };

  const onFilterChange = (input: LinkType) => {
    if (isExternalLink(input)) {
      setSelectedContract(null);
    }

    setLinkType(input);
  };

  const input = {
    onChange: onFilterChange,
    value: linkType || defaultOption,
  };

  const getActions = React.useCallback((onActionClick: { closeConfirmation: () => void }) => (
    <div className={style.ActionButtonContainer}>
      <Button
        onClick={onActionClick.closeConfirmation}
        kind="transparent"
      >
        <Message
          id="Cancel"
          comment="Text for button to close the modal for adding link to contract."
        />
      </Button>
      <Button
        disabled={formIsDisabled}
        onClick={addLink}
        kind="primary"
        icon={loading ? CircularSpinner : undefined}
      >
        <Message
          id="Add link"
          comment="Text for button to add the contract link."
        />
      </Button>
    </div>
  ), [addLink, isSelectedLinkValid, loading, selectedContract]);

  const unselectContract = () => {
    setSelectedContract(null);
  };

  const renderChosenContract = React.useCallback(() => {
    if (!selectedContract) {
      return null;
    }

    return (
      <div className={style.ContractItemContainer}>
        <Button icon={NewCross} onClick={unselectContract} customClass={style.UnselectContract} />
        <React.Suspense fallback={<CircularSpinner />}>
          <ContractCard
            dataTestId="contract-card"
            agreement={selectedContract}
            isReadOnly
            disabled={Boolean(validationError)}
            disableTooltip
          />
        </React.Suspense>
        {validationError && (
          <p className={style.ErrorMessage}>{validationError}</p>
        )}
      </div>
    );
  }, [selectedContract, validationError]);

  const renderContractSelector = () => {
    const disabled = isExternalLink(linkType);
    return (
      <ContractSelector
        onSearchItemClicked={setSelectedContract}
        searchContainerRef={searchContainerRef}
        disabled={disabled}
      />
    );
  };

  const renderContractExternalLinkSelector = () => {
    if (!isExternalLink(linkType)) {
      return null;
    }

    return (
      <ContractLinkExternalSelector
        targetExternalUrl={targetExternalUrl}
        targetExternalUrlTitle={targetExternalUrlTitle}
        setTargetExternalUrl={setTargetExternalUrl}
        setTargetExternalUrlTitle={setTargetExternalUrlTitle}
      />
    );
  };

  return (
    <Confirmable
      onClose={resetState}
      disabled={formIsDisabled}
      header={(
        <>
          {message({
            id: 'Link to',
            comment: 'Title for modal where you can choose to connect the contract to another contract.',
          })}
          ...
        </>
      )}
      modalKey="link contract modal"
      onOpen={fetchLinks}
      body={(
        <div className={style.Body}>
          <p>
            <Message
              id="Contracts can be linked to each other. You can have multiple contracts and types of contract relationships linked to the same contract."
              comment="Text explaining how the linking of contracts works."
            />
          </p>
          <span>
            <HelpCenterLink path="support/solutions/articles/77000523269-contract-links" />
          </span>
          <div className={style.SelectedContractContainer}>
            <MiniContractCard agreement={agreement} shouldRenderTitleAsLink />
          </div>
          <div className={style.LinkContractLabel}>
            <Message id="Link" comment="Label for contract link selector" />
          </div>
          <div className={style.InputContainer} ref={searchContainerRef}>
            <div className={style.SelectContainer}>
              <SelectField
                input={input}
                options={typeOptions}
                onChange={onFilterChange}
                menuPlacement={isMobile ? 'top' : 'auto'}
                placeholder={message({
                  id: 'Type',
                  comment: 'Placeholder for type of the link selection.',
                })}
              />
            </div>
            {renderContractSelector()}
          </div>
          {renderContractExternalLinkSelector()}
          {renderChosenContract()}
        </div>
      )}
      onEnter={addLink}
      actions={getActions}
      success={success}
      error={error}
    >
      {getChildren}
    </Confirmable>
  );
};

LinkToContract.defaultProps = {
  isRenderedInLinkList: false,
};

export default localize<Props>(LinkToContract);
