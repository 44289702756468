// @flow

import * as React from 'react';
import { get, reduce } from 'lodash';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import TooltipInfo from 'components/tooltip-info';

import style from '../audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
  message: MessageTranslator,
  queryPermissions: (args: ?QueryFuncArgs) => void,
  permissions: any,
};

export class RoleUpdateEventComponent extends React.PureComponent<Props> {
  componentDidMount() {
    const { queryPermissions } = this.props;

    queryPermissions({});
  }

  getStringVariables = () => {
    const { auditLogEvent } = this.props;

    return {
      roleName: auditLogEvent.target.name,
      newRoleName: get(auditLogEvent, 'information.name.to'),
      prevRoleName: get(auditLogEvent, 'information.name.from'),
    };
  };

  renderNameUpdateText = () => (
    <Message
      id="The role name {newRoleName} was {jsx-start}updated{jsx-end} from {prevRoleName}"
      comment="Audit log event message for updated role name"
      component="span"
      className={style.Green}
      values={this.getStringVariables()}
    />
  )

  getPermissionById = (id) => {
    const { permissions } = this.props;

    const allPermissions = permissions?.map((category) => category.permissions);
    const flattenedPermissions = Object.values(allPermissions).flat();

    return reduce(
      flattenedPermissions.filter((permission) => permission.id === id),
    );
  };

  renderChangedPermissionsList(types, indicator) {
    const changedPermissions = types?.map(
      (permissionId) => this.getPermissionById(permissionId),
    ).filter(Boolean);

    return changedPermissions?.map((permission) => (
      <div key={permission.id} className={style.ChangedPermission}>
        <>{indicator}</>
        <div className={style.ChangedPermissionLabel}>
          {permission.label}
        </div>
      </div>
    ));
  }

  renderPermissionUpdateText = () => {
    const { message, auditLogEvent } = this.props;
    const addedPermissionIds = get(auditLogEvent, 'information.permissions.added');
    const removedPermissionIds = get(auditLogEvent, 'information.permissions.removed');
    const addedIndicator = (<span className={style.Green}>(+)</span>);
    const removedIndicator = (<span className={style.Red}>(-)</span>);
    const tooltipText = (
      <div className={style.Text}>
        <div>
          {this.renderChangedPermissionsList(addedPermissionIds, addedIndicator)}
        </div>
        <div>
          {this.renderChangedPermissionsList(removedPermissionIds, removedIndicator)}
        </div>
      </div>
    );

    return (
      <div className={style.PermissionUpdate}>
        <Message
          id="The permissions of {roleName} were {jsx-start}updated{jsx-end}"
          comment="Audit log event message for updated role name"
          component="span"
          className={style.Green}
          values={this.getStringVariables()}
        />
        <TooltipInfo
          header={(
            <div className={style.TooltipHeader}>
              {message({
                id: 'Modified permissions',
                comment: 'tooltip title',
              })}
            </div>
          )}
          message={tooltipText}
          messageClassName={style.TooltipText}
        />
      </div>
    );
  }

  render() {
    const { auditLogEvent } = this.props;

    if (get(auditLogEvent, 'information.name.from') === undefined) {
      return this.renderPermissionUpdateText();
    }

    return this.renderNameUpdateText();
  }
}

export default localize<Props>(RoleUpdateEventComponent);
