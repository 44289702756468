// @flow

import {
  put,
} from 'redux-saga/effects';
import type {
  PutEffect,
} from 'redux-saga';

import * as agreementConstants from 'agreement/constants';
import {
  uploadVideo,
  removeVideo,
} from 'oneflow-client/agreement-video';
import type { NormalizedAgreements } from 'oneflow-client/agreements';
import generateEntitySagas from 'normalized-redux/sagas';
import apiWrapper from 'sagas/api-wrapper';

import agreements from 'reducers/entities/agreements';
import agreementVideo from 'reducers/entities/agreement-video';

type MapperArgs = {
  data: NormalizedAgreements,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* createMapper({ action }: MapperArgs): Mapper {
  yield put(agreements.setAgreements({
    [action.data.id]: {
      welcomeVideo: agreementConstants.AGREEMENT_VIDEO_PROCESSING,
    },
  }));
}

export function* removeMapper({ action }: MapperArgs): Mapper {
  yield put(agreements.setAgreements({
    [action.id]: {
      welcomeVideo: agreementConstants.AGREEMENT_WITHOUT_VIDEO,
    },
  }));
}

const mappers = {
  create: {
    mapper: createMapper,
    request: uploadVideo,
  },
  remove: {
    mapper: removeMapper,
    request: removeVideo,
  },
};

const agreementVideoSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: agreementVideo,
  mappers,
});

export default agreementVideoSagas;
