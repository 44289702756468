// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  formatDateString,
  getDaysUntil,
} from 'date';

import Renew from 'components/icons/renew';

import Insight from './insight';

import style from './insight.module.scss';

type Props = {
  periodEndTime?: string,
  dateFormat: string,
};

const Renews = ({ periodEndTime, dateFormat }: Props) => {
  const renderInsight = () => {
    if (!periodEndTime) {
      return <Message id="Recurring" comment="Insight for recurring signed agreement. Visible in contract card." />;
    }

    const daysUntil = getDaysUntil(periodEndTime);
    const dateString = formatDateString(periodEndTime, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    if (daysUntil > 7) {
      return (
        <Message
          id="Renews {date}"
          values={{
            date,
          }}
          comment="Insight for recurring signed agreement, stating when agreement renews. Visible in contract card."
        />
      );
    }

    if (daysUntil > 0) {
      return (
        <Message
          id="Renews tomorrow ({date})"
          pluralId="Renews in {days} days ({date})"
          pluralCondition="days"
          values={{
            days: daysUntil,
            date,
          }}
          comment="Insight for recurring signed agreement, stating when agreement renews. Visible in contract card."
        />
      );
    }

    return null;
  };
  return (
    <Insight
      IconComponent={Renew}
      insight={renderInsight()}
    />
  );
};

export default Renews;
