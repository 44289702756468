import { useCallback } from 'react';
import { Message } from '@oneflowab/pomes';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
// eslint-disable-next-line import/named
import { useContractProps } from 'contexts/contract-props';
import useCurrentContractId from 'hooks/use-current-contract-id';

import AiSparkleIcon from 'components/icons/ai-sparkle';
import Button from 'components/button';
import { useSelectedRectanglePopoverProps } from 'components/rich-text-editor-toolbars/inline-toolbar/use-selected-rectangle-popover-props';

import style from './ai-assist-toolbar-button.module.scss';

const AIAssistToolbarButton = () => {
  const { setIsAiAssistPopoverOpen } = useContractProps();
  const { triggerInlineToolbarAction } = useSelectedRectanglePopoverProps();
  const documentId = useCurrentContractId();

  const onAiAssistTrigger = useCallback((e: MouseEvent) => {
    const callback = () => {
      setIsAiAssistPopoverOpen(true);
    };

    triggerInlineToolbarAction(callback, e);
    amplitudeLogEvent(
      'Go To AI Assist',
      { location: 'contract view - popover' },
      { 'document id': documentId },
    );
  }, [documentId, setIsAiAssistPopoverOpen, triggerInlineToolbarAction]);

  return (
    <Button
      icon={<AiSparkleIcon />}
      onMouseDown={onAiAssistTrigger}
      data-testid="ask-ai"
      customClass={style.ToolbarButton}
    >
      <Message
        id="Ask AI"
        comment="CTA showing in a button that enables user to write ask AI"
      />
    </Button>
  );
};

export default AIAssistToolbarButton;
