// @flow

import * as React from 'react';

import { NotFound } from 'components/errors';

import {
  EXTENSION_CLASS_ADOCKA,
  EXTENSION_CLASS_API_TOKENS,
  EXTENSION_CLASS_ELECTRONIC_IDS,
  EXTENSION_CLASS_DEMO,
  EXTENSION_CLASS_DYNAMICS_CRM,
  EXTENSION_CLASS_HANDWRITTEN_SIGNATURE,
  EXTENSION_CLASS_HUBSPOT,
  EXTENSION_CLASS_INTELLIPLAN,
  EXTENSION_CLASS_JOBYLON,
  EXTENSION_CLASS_LIME,
  EXTENSION_CLASS_MEMBRAIN,
  EXTENSION_CLASS_NEOCASE,
  EXTENSION_CLASS_PAXML,
  EXTENSION_CLASS_PIPEDRIVE,
  EXTENSION_CLASS_SALESFORCE,
  EXTENSION_CLASS_SCIM,
  EXTENSION_CLASS_SMS,
  EXTENSION_CLASS_SSO_SAML,
  EXTENSION_CLASS_SUPER_OFFICE,
  EXTENSION_CLASS_TEAMTAILOR,
  EXTENSION_CLASS_TEMPLATE_GROUPS,
  EXTENSION_CLASS_UPSALES2,
  EXTENSION_CLASS_WEBHOOKS,
  EXTENSION_CLASS_GENERIC,
} from 'extensions';

import AdockaSettings from './adocka';
import ApiTokensSettings from './api-tokens';
import ElectronicIdsSettings from './electronic-ids';
import GenericExtensionPage from './generic';
import DemoSettings from './demo';
import DynamicsCrmSettings from './dynamics-crm';
import HandwrittenSignatureSettings from './handwritten_signature';
import HubspotSettings from './hubspot';
import IntelliplanSettings from './intelliplan';
import JobylonSettings from './jobylon';
import LimeSettings from './lime';
import MembrainSettings from './membrain';
import NeocaseSettings from './neocase';
import PaxmlSettings from './paxml';
import PipedriveSettings from './pipedrive';
import SalesforceSettings from './salesforce';
import SCIMSettings from './scim';
import SmsSettings from './sms';
import SsoSettings from './sso';
import SuperOfficeSettings from './super-office';
import TeamtailorSettings from './teamtailor';
import TemplateGroupsSettings from './template-groups';
import UpsalesSettings from './upsales';
import WebhooksSettings from './webhooks';

const extensionSettings = {
  [EXTENSION_CLASS_ADOCKA]: AdockaSettings,
  [EXTENSION_CLASS_API_TOKENS]: ApiTokensSettings,
  [EXTENSION_CLASS_ELECTRONIC_IDS]: ElectronicIdsSettings,
  [EXTENSION_CLASS_DEMO]: DemoSettings,
  [EXTENSION_CLASS_DYNAMICS_CRM]: DynamicsCrmSettings,
  [EXTENSION_CLASS_HANDWRITTEN_SIGNATURE]: HandwrittenSignatureSettings,
  [EXTENSION_CLASS_HUBSPOT]: HubspotSettings,
  [EXTENSION_CLASS_INTELLIPLAN]: IntelliplanSettings,
  [EXTENSION_CLASS_JOBYLON]: JobylonSettings,
  [EXTENSION_CLASS_LIME]: LimeSettings,
  [EXTENSION_CLASS_MEMBRAIN]: MembrainSettings,
  [EXTENSION_CLASS_NEOCASE]: NeocaseSettings,
  [EXTENSION_CLASS_PAXML]: PaxmlSettings,
  [EXTENSION_CLASS_PIPEDRIVE]: PipedriveSettings,
  [EXTENSION_CLASS_SALESFORCE]: SalesforceSettings,
  [EXTENSION_CLASS_SCIM]: SCIMSettings,
  [EXTENSION_CLASS_SMS]: SmsSettings,
  [EXTENSION_CLASS_SSO_SAML]: SsoSettings,
  [EXTENSION_CLASS_SUPER_OFFICE]: SuperOfficeSettings,
  [EXTENSION_CLASS_TEMPLATE_GROUPS]: TemplateGroupsSettings,
  [EXTENSION_CLASS_TEAMTAILOR]: TeamtailorSettings,
  [EXTENSION_CLASS_UPSALES2]: UpsalesSettings,
  [EXTENSION_CLASS_WEBHOOKS]: WebhooksSettings,
  [EXTENSION_CLASS_GENERIC]: GenericExtensionPage,
};

const getEmptySettingsPage = (hasLoadedExtensions: boolean) => () => {
  if (!hasLoadedExtensions) {
    return null;
  }

  return <NotFound redirect to="/marketplace" />;
};

export const getSettingsComponent = (extension: Extension | void, hasLoadedExtensions: boolean) => {
  if (!extension || !extensionSettings[extension.extensionClass]) {
    return getEmptySettingsPage(hasLoadedExtensions);
  }

  return extensionSettings[extension.extensionClass];
};

type GetExtensionFromPath = (Array<Extension>, string) => Extension | void;

export const getExtensionFromPath: GetExtensionFromPath = (
  extensions, pathname,
) => extensions.find((extension) => {
  const { slug } = extension;
  const path = `/marketplace/${slug}`;
  if (pathname.startsWith(path)) {
    const nextChar = pathname[path.length];
    return !nextChar || nextChar === '/' || nextChar === '?';
  }
  return false;
});
