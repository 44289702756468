import { ReactNode } from 'react';
import clsx from 'clsx';

import Tooltip from 'components/tooltip';
import InfoIcon from 'components/icons/info';

import style from './label.module.scss';

type Props = {
  children: ReactNode,
  hint: ReactNode,
  customClassName?: string
}

const Label = ({ children, hint, customClassName }: Props) => (
  <div className={clsx(style.Label, customClassName)}>
    {children}
    <Tooltip
      side="top"
      zIndex="1031"
      message={hint}
      theme="oneflow"
      messageClassName={style.TooltipMessage}
    >
      <InfoIcon className={style.InfoIcon} width="14px" />
    </Tooltip>
  </div>
);

export default Label;
