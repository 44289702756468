// @flow

import React from 'react';
import isNumber from 'lodash/isNumber';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { Form } from 'react-final-form';

import * as EditableForm from 'components/editable-form';
import Field from 'components/field';
import TooltipInfo from 'components/tooltip-info';
import TextField from 'components/text-field';
import { checkAcl } from 'components/acl';
import Modal from 'components/modal';
import {
  ConfirmButton,
} from 'components/buttons';
import { getDaysToRetainText } from 'components/data-retention';
import { InfoBox } from 'components/error-box';

import adminPage from 'hocs/admin-page';

import {
  composeValidators,
  numberValidator,
  minValue,
  maxValue,
} from 'forms/validators';

import style from './data-retention.module.scss';

type Props = AdminPageComponentProps & {
  workspace: Workspace,
  updateDataRetention: DataRetentionPolicy => void,
  checkNewDataRetention: DataRetentionPolicy => void,
  clearDataRetentionSuggestion: number => void,
  resetRpcState: () => void,
  rpcState: UpdateState,
  message: MessageTranslator,
};

type FormData = {
  draftDays?: string,
  overdueDays?: string,
  declinedDays?: string,
  terminatedDays?: string,
};

type State = {
  isEditMode: boolean,
};

const oneYearInDays = 365;
const MIN_DAYS = 1;
const MAX_DAYS = 10 * oneYearInDays;

export class DataRetentionComponent extends React.Component<Props, State> {
  state = {
    isEditMode: false,
  };

  validations = (({ message }) => ({
    draftDays: composeValidators(
      numberValidator({
        message,
        text: message({
          id: 'This is not a valid number',
          comment: 'Generic validation message for number validator',
        }),
      }),
      minValue({
        message,
        field: message({
          id: 'Draft days',
          comment: 'Field name used in validation of data retention setting.',
        }),
        limit: MIN_DAYS,
      }),
      maxValue({
        message,
        field: message({
          id: 'Draft days',
          comment: 'Field name used in validation of data retention setting.',
        }),
        limit: MAX_DAYS,
      }),
    ),
    overdueDays: composeValidators(
      numberValidator({
        message,
        text: message({
          id: 'This is not a valid number',
          comment: 'Generic validation message for number validator',
        }),
      }),
      minValue({
        message,
        field: message({
          id: 'Overdue days',
          comment: 'Field name used in validation of data retention setting.',
        }),
        limit: MIN_DAYS,
      }),
      maxValue({
        message,
        field: message({
          id: 'Overdue days',
          comment: 'Field name used in validation of data retention setting.',
        }),
        limit: MAX_DAYS,
      }),
    ),
    declinedDays: composeValidators(
      numberValidator({
        message,
        text: message({
          id: 'This is not a valid number',
          comment: 'Generic validation message for number validator',
        }),
      }),
      minValue({
        message,
        field: message({
          id: 'Declined days',
          comment: 'Field name used in validation of data retention setting.',
        }),
        limit: MIN_DAYS,
      }),
      maxValue({
        message,
        field: message({
          id: 'Declined days',
          comment: 'Field name used in validation of data retention setting.',
        }),
        limit: MAX_DAYS,
      }),
    ),
    terminatedDays: composeValidators(
      numberValidator({
        message,
        text: message({
          id: 'This is not a valid number',
          comment: 'Generic validation message for number validator',
        }),
      }),
      minValue({
        message,
        field: message({
          id: 'Terminated days',
          comment: 'Field name used in validation of data retention setting.',
        }),
        limit: MIN_DAYS,
      }),
      maxValue({
        message,
        field: message({
          id: 'Terminated days',
          comment: 'Field name used in validation of data retention setting.',
        }),
        limit: MAX_DAYS,
      }),
    ),
  }))(this.props);

  componentDidUpdate() {
    const { isEditMode } = this.state;
    const { rpcState } = this.props;

    if (rpcState.success && isEditMode) {
      this.setState({ isEditMode: false });
    }
  }

  getInitialValues() {
    const { dataRetentionPolicy = {} } = this.props.workspace;
    const {
      agreementDraft,
      agreementDeclined,
      agreementExpired,
      agreementTerminated,
    } = dataRetentionPolicy;

    return {
      draftDays: agreementDraft ? String(agreementDraft) : undefined,
      overdueDays: agreementExpired ? String(agreementExpired) : undefined,
      declinedDays: agreementDeclined ? String(agreementDeclined) : undefined,
      terminatedDays: agreementTerminated ? String(agreementTerminated) : undefined,
    };
  }

  handleSubmit = ({
    draftDays,
    overdueDays,
    declinedDays,
    terminatedDays,
  }: FormData) => {
    const { updateDataRetention, checkNewDataRetention } = this.props;

    if (!draftDays && !overdueDays && !declinedDays && !terminatedDays) {
      updateDataRetention({
        agreementDraft: undefined,
        agreementExpired: undefined,
        agreementDeclined: undefined,
        agreementTerminated: undefined,
      });
      return;
    }

    checkNewDataRetention({
      agreementDraft: Number(draftDays) || undefined,
      agreementExpired: Number(overdueDays) || undefined,
      agreementDeclined: Number(declinedDays) || undefined,
      agreementTerminated: Number(terminatedDays) || undefined,
    });
  };

  handleModalConfirm = () => {
    const { updateDataRetention, workspace } = this.props;
    const { dataRetentionPolicy = {} } = workspace;
    const {
      suggestedDraftDays,
      suggestedOverdueDays,
      suggestedDeclinedDays,
      suggestedTerminatedDays,
    } = dataRetentionPolicy;

    updateDataRetention({
      agreementDraft: suggestedDraftDays,
      agreementExpired: suggestedOverdueDays,
      agreementDeclined: suggestedDeclinedDays,
      agreementTerminated: suggestedTerminatedDays,
    });
  };

  handleClear = () => {
    const { workspace, clearDataRetentionSuggestion } = this.props;

    clearDataRetentionSuggestion(workspace.id);
  };

  setIsEditMode = (_isEditMode: boolean) => {
    const { rpcState, resetRpcState } = this.props;

    if (!rpcState.pristine) {
      resetRpcState();
    }

    this.setState({ isEditMode: _isEditMode });
  };

  renderAffectedDraftText() {
    const { dataRetentionPolicy = {} } = this.props.workspace;

    if (!dataRetentionPolicy.affectedAgreementDraft) {
      return null;
    }

    return (
      <li className={style.ListItem}>
        <Message
          id="{count} draft"
          pluralId="{count} drafts"
          pluralCondition="count"
          values={{
            count: dataRetentionPolicy.affectedAgreementDraft,
          }}
          comment="Label for number of contracts when confirming data retention policy."
        />
      </li>
    );
  }

  renderAffectedOverdueText() {
    const { dataRetentionPolicy = {} } = this.props.workspace;

    if (!dataRetentionPolicy.affectedAgreementExpired) {
      return null;
    }

    return (
      <li className={style.ListItem}>
        <Message
          id="{count} overdue contract"
          pluralId="{count} overdue contracts"
          pluralCondition="count"
          values={{
            count: dataRetentionPolicy.affectedAgreementExpired,
          }}
          comment="Label for number of contracts when confirming data retention policy."
        />
      </li>
    );
  }

  renderAffectedDeclinedText() {
    const { dataRetentionPolicy = {} } = this.props.workspace;

    if (!dataRetentionPolicy.affectedAgreementDeclined) {
      return null;
    }

    return (
      <li className={style.ListItem}>
        <Message
          id="{count} declined contract"
          pluralId="{count} declined contracts"
          pluralCondition="count"
          values={{
            count: dataRetentionPolicy.affectedAgreementDeclined,
          }}
          comment="Label for number of contracts when confirming data retention policy."
        />
      </li>
    );
  }

  renderAffectedTerminatedText() {
    const { dataRetentionPolicy = {} } = this.props.workspace;

    if (!dataRetentionPolicy.affectedAgreementTerminated) {
      return null;
    }

    return (
      <li className={style.ListItem}>
        <Message
          id="{count} terminated contract"
          pluralId="{count} terminated contracts"
          pluralCondition="count"
          values={{
            count: dataRetentionPolicy.affectedAgreementTerminated,
          }}
          comment="Label for number of contracts when confirming data retention policy."
        />
      </li>
    );
  }

  renderConfirmationWarning() {
    const { dataRetentionPolicy = {} } = this.props.workspace;
    const {
      affectedAgreementDraft,
      affectedAgreementExpired,
      affectedAgreementDeclined,
      affectedAgreementTerminated,
    } = dataRetentionPolicy;
    const hasNoAffectedContracts = !affectedAgreementDraft
      && !affectedAgreementDeclined
      && !affectedAgreementExpired
      && !affectedAgreementTerminated;

    if (hasNoAffectedContracts) {
      return null;
    }

    return (
      <InfoBox
        headerText={(
          <Message
            id="Some contracts will be affected by the new data retention policy and scheduled for removal"
            comment="Warning message in modal when updating data retention policy."
          />
        )}
        bodyText={(
          <ul>
            {this.renderAffectedDraftText()}
            {this.renderAffectedOverdueText()}
            {this.renderAffectedDeclinedText()}
            {this.renderAffectedTerminatedText()}
          </ul>
        )}
      />
    );
  }

  renderConfirmationModal() {
    const {
      workspace,
      message,
      rpcState,
    } = this.props;
    const { dataRetentionPolicy = {} } = workspace;
    const {
      affectedAgreementDraft,
      affectedAgreementExpired,
      affectedAgreementDeclined,
      affectedAgreementTerminated,
    } = dataRetentionPolicy;
    const hasNoAffectedContracts = !isNumber(affectedAgreementDraft)
      && !isNumber(affectedAgreementExpired)
      && !isNumber(affectedAgreementDeclined)
      && !isNumber(affectedAgreementTerminated);

    if (hasNoAffectedContracts) {
      return null;
    }

    return (
      <Modal
        header={message({
          id: 'Confirm changes',
          comment: 'Modal header for confirming changes to data retention policy.',
        })}
        isOpen
        onCancel={this.handleClear}
        error={undefined}
        modalKey="edit data retention modal"
      >
        <Modal.Body>
          <p>
            <Message
              id="Are you sure you want to update your data retention policy?"
              comment="Warning message in modal when updating data retention policy."
            />
          </p>
          {this.renderConfirmationWarning()}
        </Modal.Body>
        <Modal.Actions>
          <ConfirmButton
            isLoading={rpcState.loading}
            onClick={this.handleModalConfirm}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const { isEditMode } = this.state;
    const {
      workspace,
      rpcState,
      resetRpcState,
      message,
    } = this.props;
    const {
      draftDays,
      declinedDays,
      overdueDays,
      terminatedDays,
    } = this.getInitialValues();

    if (!workspace.acl) {
      return null;
    }

    return (
      <Form
        initialValues={this.getInitialValues()}
        onSubmit={this.handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <EditableForm.Header>
              <EditableForm.Actions
                resetFormState={resetRpcState}
                formState={rpcState}
                isEditMode={isEditMode}
                setIsEditMode={this.setIsEditMode}
                disabled={!checkAcl(workspace.acl, 'collection:data_retention_policy:update')}
              />
            </EditableForm.Header>
            <EditableForm.Body>
              <h2 className={style.Header}>
                <Message
                  id="Data retention policy"
                  comment="Used as the title of the data retention policy section on the data retention page."
                />
              </h2>
              <p className={style.Description}>
                <Message
                  id="For GDPR purposes or company policies, you can set the retention period for contracts in different states below. As part of a daily routine, contracts that have been in these states beyond the defined retention period will be removed from {workspaceName}."
                  values={{
                    workspaceName: <strong>{workspace.name}</strong>,
                  }}
                  comment="Help text for the settings on the data retention page."
                />
              </p>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Drafts"
                    comment="Used as the label of the draft field on the data retention page."
                  />
                  <TooltipInfo
                    message={(
                      <Message
                        id="Days to retain drafts"
                        comment="Tooltip message for the data retention settings."
                      />
                    )}
                    side="top"
                  />
                  <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                </EditableForm.Label>
                <Field
                  name="draftDays"
                  component={TextField}
                  maxLength={50}
                  placeholder={message({
                    id: 'Days to retain drafts',
                    comment: 'Placeholder for the drafts on the data retention page.',
                  })}
                  validate={this.validations.draftDays}
                  autoFocus
                  value={getDaysToRetainText({ message })(draftDays)}
                  disabled={!isEditMode}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Overdue contracts"
                    comment="Used as the label of the overdue field on the data retention page."
                  />
                  <TooltipInfo
                    message={message({
                      id: 'Days to retain overdue contracts',
                      comment: 'Tooltip message for the data retention settings.',
                    })}
                    side="top"
                  />
                </EditableForm.Label>
                <Field
                  name="overdueDays"
                  component={TextField}
                  placeholder={message({
                    id: 'Days to retain overdue contracts',
                    comment: 'Placeholder for the overdue field on the data retention page.',
                  })}
                  validate={this.validations.overdueDays}
                  value={getDaysToRetainText({ message })(overdueDays)}
                  disabled={!isEditMode}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Declined contracts"
                    comment="Used as the label of the declined field on the data retention page."
                  />
                  <TooltipInfo
                    message={message({
                      id: 'Days to retain declined contracts',
                      comment: 'Tooltip message for the data retention settings.',
                    })}
                    side="top"
                  />
                </EditableForm.Label>
                <Field
                  name="declinedDays"
                  component={TextField}
                  placeholder={message({
                    id: 'Days to retain declined contracts',
                    comment: 'Placeholder for the declined field on the data retention page.',
                  })}
                  validate={this.validations.declinedDays}
                  value={getDaysToRetainText({ message })(declinedDays)}
                  disabled={!isEditMode}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Terminated contracts"
                    comment="Used as the label of the terminated field on the data retention page."
                  />
                  <TooltipInfo
                    message={message({
                      id: 'Days to retain terminated contracts',
                      comment: 'Tooltip message for the data retention settings.',
                    })}
                    side="top"
                  />
                </EditableForm.Label>
                <Field
                  name="terminatedDays"
                  component={TextField}
                  placeholder={message({
                    id: 'Days to retain terminated contracts',
                    comment: 'Placeholder for the terminated field on the data retention page.',
                  })}
                  validate={this.validations.terminatedDays}
                  value={getDaysToRetainText({ message })(terminatedDays)}
                  disabled={!isEditMode}
                />
              </div>
              <div className={style.HelpText}>
                <Message
                  id="Note: Be aware that changing your data retention policy will remove contracts beyond the number of days set."
                  comment="Help text for the settings on the data retention page."
                />
                {this.renderConfirmationModal()}
              </div>
            </EditableForm.Body>
          </form>
        )}
      />
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Data retention',
    comment: 'Used as the page title of the data retention page.',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(DataRetentionComponent);
