// @flow

import {
  getMultiple,
  post,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import type { SortParams } from 'oneflow-client/core';

import { normalizePositions } from 'oneflow-client/positions';
import type { NormalizedPositions } from 'oneflow-client/positions';

type AddPositionsToGroup = ({
  id: number,
  positionIds: Array<number>,
}) => Promise<{}>;

export const addPositionsToGroup: AddPositionsToGroup = ({ id, positionIds }) => (
  post({
    url: `/groups/${id}/positions/`,
    body: { position_ids: positionIds },
  })
    .then(extractResponseBodyAsJSON)
);

type GetGroupPositions = ({
  pagination?: {},
  params: {
    groupId: number,
    [string]: any,
  },
  sorting?: SortParams,
}) => Promise<NormalizedPositions>;

export const getGroupPositions: GetGroupPositions = ({
  pagination,
  params: {
    groupId,
    ...params
  },
  sorting = {
    attr: 'fullname',
    direction: 'asc',
  },
}) => (
  getMultiple({
    url: `/groups/${groupId}/positions/`,
    params,
    pagination,
    sorting,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizePositions)
);

type RemoveGroupPositions = ({
  groupId: number,
  positionIds: Array<number>,
}) => Promise<{}>;

export const removeGroupPositions: RemoveGroupPositions = ({ groupId, positionIds }) => (
  remove({
    url: `/groups/${groupId}/positions/`,
    body: { position_ids: positionIds },
  })
    .then(extractResponseBodyAsJSON)
);
