// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

type Props = {
  position: Position,
  workspace: Workspace,
  removeState: RemoveState,
  removeUserFromWorkspace: Function,
  resetRemoveState: Function,
  children: Function,
};

export const handleConfirm = (removeUserFromWorkspace: Function, isLoading: boolean) => () => {
  if (isLoading) {
    return;
  }

  removeUserFromWorkspace();
};

export const RemoveUserFromWorkspace = ({
  position,
  workspace,
  removeState,
  removeUserFromWorkspace,
  resetRemoveState,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={removeUserFromWorkspace}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <Message
        id="You are about to remove access to {workspace} for {actorName}."
        values={{
          actorName: <Badge label={position.fullname} kind="name" />,
          workspace: <Badge label={workspace.name || ''} kind="name" />,
        }}
        comment="Modal text when removing workspace access for a user."
      />
    )}
    modalKey="remove workspace access user modal"
  >
    {children}
  </RemoveConfirm>
);
