/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import groupsReducer from 'reducers/entities/groups';
import groupAccountsReducer from 'reducers/entities/group-accounts';
import { getAccountFromSessionSelector } from 'reducers/session';
import adminPage from 'hocs/admin-page';

import { checkAcl } from 'components/acl';
import Button from 'components/button';
// eslint-disable-next-line import/named
import { Table } from 'components/table';
import ActionBar from 'components/action-bar';
import { ActionBarText } from 'components/action-bar-text';
import GrantGroupAccessIcon from 'components/icons/grant-group-access';
import GrantGroupAccountAccess from 'components/modals/grant-group-account-access';
import Pagination from 'components/pagination';
import ActionsMenu from 'components/actions-menu';
import AddMembersIcon from 'components/icons/add-members';
import { DeleteMenuItem } from 'components/menu-items/delete';
import RemoveGroupAccountAccess from 'components/modals/remove-group-account-access';
import EditGroupAccountAccess from 'components/modals/edit-group-account-access';
import { ChangeRoleMenuItem } from 'components/menu-items/change-role';

import style from './group-account-access.module.scss';

export const getGroupAccountsQueryName = (id: number) => `admin/groups/${id}/account-access`;

const defaultPagination = {
  limit: 50,
  offset: 0,
};

type Props = {
  message: MessageTranslator,
};

type Params = {
  id: string;
};

export const GroupAccountAccessComponent = ({ message }: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams<Params>();
  const groupId = parseInt(id, 10);
  const account: Oneflow.Account = useSelector(getAccountFromSessionSelector);

  const groupAccountBindingsQuery = useSelector(
    (state) => groupAccountsReducer.getQuerySelector(state, {
      name: getGroupAccountsQueryName(groupId),
    }),
  );
  const groupAccountBindings: Array<Oneflow.AccountGroupRoleBinding> = useSelector(
    (state) => groupAccountsReducer.getGroupAccountsSelector(state, {
      ids: groupAccountBindingsQuery.result,
    }),
  );

  const group: Oneflow.Group = useSelector(
    (state) => groupsReducer.getGroupSelector(state, { id: groupId }),
  );

  useEffect(() => {
    dispatch(groupAccountsReducer.queryGroupAccounts({
      name: getGroupAccountsQueryName(groupId),
      pagination: defaultPagination,
      params: { groupId },
    }));
  }, [dispatch, groupId]);

  const renderRoleName = useCallback((item: Oneflow.AccountGroupRoleBinding) => (
    <>
      {item.role?.name}
    </>
  ), []);

  const renderPermissionCount = useCallback((item: Oneflow.AccountGroupRoleBinding) => (
    `${item.role?.permissionCount}/${item.role?.maxPermissionCount}`
  ), []);

  const getGrantAccountAccessButton = useCallback(() => (onClick: () => void) => {
    const hasAccountRoleBindingPermission: boolean = checkAcl(
      account?.acl,
      [
        'account:group:custom_role_binding:create',
        'account:group:system_role_binding:create',
      ],
      { match: 'any' },
    );

    return (
      <Button
        disabled={!hasAccountRoleBindingPermission}
        icon={GrantGroupAccessIcon}
        kind="primary"
        onClick={onClick}
      >
        <Message
          id="Grant account access"
          comment="Button for granting a group access to an account role"
        />
      </Button>
    );
  }, [account?.acl]);

  const getRemoveMenuItem = useCallback(() => (onClick: () => void) => {
    const hasRemoveGroupBindingPermission = checkAcl(
      account?.acl,
      [
        'account:group:system_role_binding:remove',
        'account:group:custom_role_binding:remove',
      ],
      { match: 'any' },
    );
    return (
      <DeleteMenuItem
        onClick={onClick}
        disabled={!hasRemoveGroupBindingPermission}
      />
    );
  }, [account?.acl]);

  const getEditMenuItem = useCallback(() => (onClick: () => void) => {
    const hasSystemRoleEditBindingPermission: boolean = checkAcl(
      account?.acl, [
        'account:group:system_role_binding:remove',
        'account:group:system_role_binding:create',
      ],
    );
    const hasCustomRoleEditBindingPermission: boolean = checkAcl(
      account?.acl, [
        'account:group:custom_role_binding:remove',
        'account:group:custom_role_binding:create',
      ],
    );
    const hasEditGroupBindingPermission: boolean = hasSystemRoleEditBindingPermission
    && hasCustomRoleEditBindingPermission;

    return (
      <ChangeRoleMenuItem
        onClick={onClick}
        disabled={!hasEditGroupBindingPermission}
      />
    );
  }, [account?.acl]);

  const getActionsForGroupAccess = useCallback((item: Oneflow.AccountGroupRoleBinding) => (
    <ActionsMenu
      actions={[
        <EditGroupAccountAccess
          binding={item}
          key="edit"
          renderedFromAccountAccessPage={false}
        >
          {getEditMenuItem()}
        </EditGroupAccountAccess>,
        <RemoveGroupAccountAccess
          binding={item}
          key="remove"
          renderedFromAccountAccessPage={false}
        >
          {getRemoveMenuItem()}
        </RemoveGroupAccountAccess>,
      ]}
      focusOnCloseDisabled
    />
  ), [getEditMenuItem, getRemoveMenuItem]);

  const getTableConfig = () => {
    const actions: JSX.Element[] = [];

    const columns = [
      {
        name: 'account role',
        label: message({
          id: 'Account role',
          comment: 'Label in the group account access page.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountGroupRoleBinding) => (
          <Link key={`${uniqueId()}_${item.role?.id}`} to={`/admin/roles/${item.role?.id}`}>
            {renderRoleName(item)}
          </Link>
        ),
      },
      {
        name: 'permissions',
        label: message({ id: 'Permissions', comment: 'Column label in groups page' }),
        type: 'cell',
        value: (item: Oneflow.AccountGroupRoleBinding) => renderPermissionCount(item),
      },
      {
        name: 'actions',
        label: message({ id: 'Actions', comment: 'Column label in groups page' }),
        type: 'actions',
        value: (item: Oneflow.AccountGroupRoleBinding) => getActionsForGroupAccess(item),
      },
    ];

    return {
      items: groupAccountBindings,
      itemKey: 'id',
      actions,
      columns,
    };
  };

  const shouldShowEmptyState = useMemo(
    () => groupAccountBindings.length === 0,
    [groupAccountBindings.length],
  );
  const getEmptyState = () => ({
    header: message({
      id: 'Assign this group an account role',
      comment: 'Empty state header for the account roles access list in the group pages.',
    }),
    icon: <AddMembersIcon height="33px" />,
    content: (
      <Message
        id="Different roles will grant access to different application areas"
        comment="Empty state message for the account roles access list in group pages"
      />
    ),
    showEmptyState: shouldShowEmptyState,
  });

  const navigateToPage = ({ limit, offset }: RequestParams.Pagination) => {
    dispatch(groupAccountsReducer.queryGroupAccounts({
      name: getGroupAccountsQueryName(groupId),
      pagination: { limit, offset },
      params: { groupId },
    }));
  };

  const renderPagination = () => {
    if (shouldShowEmptyState
      || groupAccountBindingsQuery.loading
      || !(groupAccountBindingsQuery.count > 0)) {
      return null;
    }

    return (
      <Pagination
        totalItems={groupAccountBindings.length}
        itemsPerPage={groupAccountBindingsQuery.pagination.limit}
        currentOffset={groupAccountBindingsQuery.pagination.offset}
        onChange={navigateToPage}
      />
    );
  };

  return (
    <div>
      <ActionBar collapsed>
        <ActionBar.Group>
          <GrantGroupAccountAccess
            group={group}
            renderedFromAccountAccessPage={false}
            shouldRedirectToAccountAccess={false}
          >
            {getGrantAccountAccessButton()}
          </GrantGroupAccountAccess>
          <ActionBarText
            header={(
              <Message
                id="Manage access to the account for this group"
                comment="Header text for the account access list in the group pages."
              />
            )}
          />
        </ActionBar.Group>
      </ActionBar>
      {!shouldShowEmptyState && (
        <div className={style.ContentHeader}>
          <h3 className={style.Header}>
            <Message
              id="Account access"
              comment="Label in the group account access page."
            />
          </h3>
        </div>
      )}
      <Table
        config={getTableConfig()}
        query={groupAccountBindingsQuery}
        emptyState={getEmptyState()}
      />
      {renderPagination()}
    </div>
  );
};

export default adminPage(({ props: { message } }) => ({
  title: message({
    id: 'Account access',
    comment: 'Page title for the account access list in the group pages.',
  }),
  modules: [[]],
}))(GroupAccountAccessComponent);
