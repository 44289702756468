// @flow

import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { getCurrentLanguageSelector } from 'reducers/i18n';
import workspacesReducer from 'reducers/entities/workspaces';
import { getAccountFromSessionSelector } from 'reducers/session';

import Workspaces from './workspaces';

export const QUERY_NAME = 'admin/workspaces';

export const mapStateToProps = (state: State) => {
  const query = workspacesReducer.getQuerySelector(state, { name: QUERY_NAME });
  const account = getAccountFromSessionSelector(state);

  return {
    query,
    workspaces: workspacesReducer.getWorkspacesSelector(state, { ids: query.result }),
    language: getCurrentLanguageSelector(state),
    account,
  };
};

export const mapDispatchToProps = (dispatch: Function) => ({
  queryWorkspaces: ({ pagination, params }: Query) => {
    dispatch(workspacesReducer.queryWorkspaces({
      name: QUERY_NAME,
      pagination: {
        ...pagination,
        limit: 50,
      },
      params: {
        ...params,
        accessStats: 1,
        includeAgreementStats: 1,
        workspaceAdmin: 1,
      },
    }));
  },
  editLinkPath: (workspaceId: number) => {
    const editLink = `/admin/workspaces/${workspaceId}/details/?edit=true`;

    dispatch(push(editLink));
  },
  aiReviewLinkPath: (workspaceId: number) => {
    const aiReviewOnboardingLink = `/admin/workspaces/${workspaceId}/ai-review`;

    dispatch(push(aiReviewOnboardingLink));
  },
});

export default connect<any, State, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Workspaces);
