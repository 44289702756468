import { Message } from '@oneflowab/pomes';

const PartialSigned = ({ date }: { date: string }) => (
  <span>
    <b>
      <Message
        id="Awaiting signatures."
        comment="Document state for partially signed contract. Visible in contract."
      />
    </b>
    <Message
      id=" The document can be signed until {date}."
      values={{
        date,
      }}
      comment="Document state for partially signed contract. Visible in contract."
    />
  </span>
);

export default PartialSigned;
