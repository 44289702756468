import type { Modifier } from '@dnd-kit/core';
import restrictToBoundingRect from './restrict-to-bounding-rect';

const restrictToParentElement: Modifier = (args) => {
  const {
    containerNodeRect,
    draggingNodeRect,
    transform,
    active,
  } = args;

  const scale = active?.data?.current?.scale || 1;
  const size = active?.data?.current?.size;

  if (!draggingNodeRect || !containerNodeRect) {
    return transform;
  }

  return restrictToBoundingRect(transform, draggingNodeRect, containerNodeRect, size, scale);
};

export default restrictToParentElement;
