// @flow

import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import * as participantConstants from 'agreement/participant/constants';
import * as userConstants from 'user/constants';
import { getSignedParticipants, isSignOrderEnabled } from 'agreement/selectors';
import { checkAcl } from 'components/acl';
import {
  getParticipantRolesWithApproverAsOptions,
} from 'components/participant-roles/participant-roles';
import { shouldDisableField } from 'agreement/agreement-participants';

import {
  getAvailableSignMethods,
  isDraft,
  isImport,
  isConcluded,
  isSigningInProgress,
  isTemplate,
  isAnySignedState,
  getFirstAvailableSignMethod,
  isPending,
} from 'agreement';
import Colleague from 'components/fields/colleague';
import { SignMethodField } from 'components/fields';
import Tooltip from 'components/tooltip';
import MessageTemplatePicker from 'components/message-template-picker';
import ParticipantRoleField from 'components/fields/participant-role';

import NotifyParticipantsCheckbox from '../notify-participants-checkbox';
import style from './add-colleague-body.module.scss';

export type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  account: Account,
  agreement: Agreement,
  contractMessageTemplates: Array<MessageTemplate>,
  getMessageBody: Function,
  message: MessageTranslator,
  position: Position,
  updateSelectedColleague: Function,
  updateSelectedMessageTemplate: Function,
  updateSelectedRole: Function,
  updateSelectedSignMethod: Function,
  selectedColleague: any,
  selectedMessageTemplate: MessageTemplate,
  selectedRole: any,
  values?: {
    signMethod?: number,
  },
  participantAcl?: Acl,
};

export const AddColleagueBody = ({
  agreement,
  account,
  contractMessageTemplates,
  getMessageBody,
  message,
  position,
  values,
  updateSelectedColleague,
  updateSelectedMessageTemplate,
  updateSelectedRole,
  updateSelectedSignMethod,
  selectedColleague,
  selectedMessageTemplate,
  selectedRole,
  participantAcl,
}: Props) => {
  let hasSomeoneSigned = false;
  const [checked, setChecked] = React.useState(false);

  const hasAccessToInternalApproval = agreement
    ? checkAcl(agreement.acl, 'agreement:draft_approval_flow:update') : false;

  const hasAccessToPendingStateApprover = agreement
    ? checkAcl(agreement.acl, 'agreement:participant:pending_state_approver:create') : false;

  if (!isEmpty(agreement)) {
    hasSomeoneSigned = Boolean(getSignedParticipants(agreement).length);
  }

  React.useEffect(() => {
    if (hasSomeoneSigned || !isDraft(agreement)) {
      setChecked(true);
    }
  }, [hasSomeoneSigned, agreement]);

  const handleChange = () => {
    if (hasSomeoneSigned) {
      return;
    }
    setChecked(!checked);
  };

  const renderSignMethodField = () => {
    if (isConcluded(agreement)
      || isSigningInProgress(agreement)
      || (
        selectedRole?.value
          ? selectedRole.value !== participantConstants.TYPE_IS_SIGNATORY
          : selectedRole !== participantConstants.TYPE_IS_SIGNATORY
      )
    ) {
      return null;
    }

    const firstAvailable = getFirstAvailableSignMethod(
      agreement,
      values?.signMethod,
      account,
    );

    const description = (
      message({
        id: 'The sign method decides how this person will identify themselves when signing.',
        comment: 'Tooltip text for signing method field in the Add Colleague modal.',
      })
    );

    return (
      <SignMethodField
        availableSignMethods={
        getAvailableSignMethods(
          agreement,
          account,
        )
      }
        onChange={updateSelectedSignMethod}
        initialValue={firstAvailable}
        disabled={shouldDisableField(participantAcl, 'participant:update:sign_method')}
        signMethodsDescription={description}
      />
    );
  };

  const renderMessageTemplateSection = () => {
    if (!checked || isTemplate(agreement)
      || (isPending(agreement) && isSignOrderEnabled(agreement))) {
      return null;
    }

    return (
      <>
        <div className={style.MessageContainer}>
          <h1>
            <Message
              id="Message"
              comment="Header before message template picker section and notification subject and body."
            />
          </h1>
          <div>
            <Tooltip
              messageClassName={style.TooltipMessage}
              backgroundColor="#013a4c"
              side="top"
              message={message({
                id: 'Private comments are only visible to participants in your own organization.',
                comment: 'Field label in add/edit colleague modal.',
              })}
            >
              <span className={style.PrivateLabel}>
                <Message
                  id="Private comment"
                  comment="Label on a comment saying that the comment is private"
                />
              </span>
            </Tooltip>
          </div>
        </div>
        <MessageTemplatePicker
          contractMessageTemplates={contractMessageTemplates}
          selectedMessageTemplate={selectedMessageTemplate}
          updateSelectedMessageTemplate={updateSelectedMessageTemplate}
          isSubjectFieldRequired
          isMessageFieldRequired
          getMessageBody={getMessageBody}
          isReminder
          context="agreementParticipantCreate"
        />
      </>
    );
  };

  const renderNotificationSection = () => {
    if ((selectedColleague && (position?.id === selectedColleague.value))
      || isConcluded(agreement) || isAnySignedState(agreement)) {
      return null;
    }

    return (
      <>
        {
          isImport(agreement) || !isDraft(agreement)
            ? null : (
              <NotifyParticipantsCheckbox
                checked={checked}
                onChange={handleChange}
                disabled={hasSomeoneSigned}
              />
            )
        }
        <div className={style.Divider} />
        {renderMessageTemplateSection()}
      </>
    );
  };

  const getHiddenRoles = () => {
    if (hasAccessToInternalApproval && selectedColleague
      && selectedColleague.userRole === userConstants.USER_ROLE_LIMITED) {
      const hideInternalApprover = getParticipantRolesWithApproverAsOptions(
        message, hasAccessToInternalApproval, hasAccessToPendingStateApprover,
      ).filter((role) => role.value === participantConstants.TYPE_IS_INTERNAL_APPROVER);

      return hideInternalApprover.map((role) => role.value);
    }

    if (isConcluded(agreement) || isAnySignedState(agreement)) {
      const listWithoutOrganizer = getParticipantRolesWithApproverAsOptions(
        message, hasAccessToInternalApproval, hasAccessToPendingStateApprover,
      ).filter((role) => role.value !== participantConstants.TYPE_IS_ORGANIZER);

      return listWithoutOrganizer?.map((role) => role.value);
    }

    return undefined;
  };

  const renderUserRoleField = () => (
    <ParticipantRoleField
      agreement={agreement}
      onChange={updateSelectedRole}
      initialValue={selectedRole?.value}
      fieldInfoRightPosition
      hiddenRoles={getHiddenRoles()}
      hasSignOrder={isSignOrderEnabled(agreement)}
      isColleague
    />
  );

  return (
    <div>
      <div className={style.ColleagueContainer}>
        <Colleague onChange={updateSelectedColleague} />
      </div>
      <div className={style.Row}>
        {renderUserRoleField()}
        {renderSignMethodField()}
      </div>
      {renderNotificationSection()}
    </div>
  );
};

export default localize<Props>(AddColleagueBody);
