import clsx from 'clsx';

import Message from 'components/message/message';
import NewCheckIcon from 'components/icons/new-check';
import { COLLAPSED } from 'components/document-layout-container/helpers';
import type { Layout } from 'components/document-layout-container/types';

import style from './approved.module.scss';

type Props = {
  layout: Layout;
}

const ApprovedState = ({ layout }: Props) => (
  <div className={clsx(style.Container, { [style.Collapsed]: layout === COLLAPSED })}>
    <NewCheckIcon height="14px" />
    <span>
      <Message
        id="You have approved"
        comment="Text for badge when the user has approved the document"
      />
    </span>
  </div>
);

export default ApprovedState;
