import Message from 'components/message';
import Info from 'components/icons/info';

import style from './additional-information.module.scss';

const AdditionalInformation = () => (
  <div className={style.AdditionalInformationContainer}>
    <div className={style.InfoText}>
      <Info height="12px" width="12px" />
      <span className={style.AdditionalInformation}>
        <Message
          id="Link to document will be automatically added to your signing request."
          comment="Information in a Send modal explaining that the recipient will receive link to this document together with the message."
        />
      </span>
    </div>
    <a href="/user/templates" target="_blank" className={style.Link}>
      <Message
        id="Manage your message templates"
        comment="A label of link to window where the user can manage their message templates."
      />
    </a>
  </div>
);

export default AdditionalInformation;
