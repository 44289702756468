// @flow

import React from 'react';
import isEqual from 'lodash/isEqual';

// Use this when normaized redux selectors return new object/list instances
// With the same values that causes unwanted re-rendering or infinite loops
// When used as dependencies in hooks
// Eg:  filter, map, and all other immutable APIs returns new list everytime
// Even when called with same predicate and same list
export default function usePreviousUntilValuesChanged<T>(newValue: T) {
  const valueRef = React.useRef(newValue);

  if (!isEqual(newValue, valueRef.current)) {
    valueRef.current = newValue;
  }

  return valueRef.current;
}
