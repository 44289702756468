// @flow

import { post, extractResponseBodyAsJSON } from 'oneflow-client/core';

// eslint-disable-next-line import/prefer-default-export
export const authorizeWithPipedrive = () => (
  post({
    url: '/ext/pipedrive/',
  }).then(extractResponseBodyAsJSON)
);
