import { connect } from 'react-redux';
import get from 'lodash/get';
import type { DefaultRootState } from 'react-redux';

// eslint-disable-next-line import/named
import { allowContractMove, allowTemplateMove } from 'workspace/permissions';
import { isContractPristine } from 'reducers/current-contract';
import { isTemplate } from 'agreement/states';
import workspacesReducer, { getAllMoveToWorkspaces } from 'reducers/entities/workspaces';

import MoveContract from './move-contract';
import type { Props } from './move-contract';

type StateProps = {
  canPerformAction: boolean,
};

type OwnProps = Omit<Props, keyof StateProps> & {
  agreement: Oneflow.Agreement,
};

export const mapStateToProps = (
  state: DefaultRootState,
  { agreement }: OwnProps,
) => {
  const workspace = workspacesReducer.getWorkspaceSelector(state, {
    id: get(agreement, 'collection.id') as unknown as number,
  });
  const availableWorkspaces = getAllMoveToWorkspaces(state, workspace.id);
  const isPristine = isContractPristine(state);

  const canPerformAction = () => {
    if (!isPristine) {
      return false;
    }

    if (isTemplate(agreement)) {
      return allowTemplateMove(workspace) && Boolean(availableWorkspaces.length);
    }

    return allowContractMove(workspace);
  };
  return {
    canPerformAction: canPerformAction(),
  };
};

export default connect(
  mapStateToProps,
)(MoveContract);
