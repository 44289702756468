// @flow

import {
  KEY_DYNAMICS_CRM,
} from 'extensions';

const DynamicsCrm = {
  key: KEY_DYNAMICS_CRM,
};

export default DynamicsCrm;
