import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import ArrowLeft from 'components/icons/arrow-left';

import style from './previous-with-arrow.module.scss';

type Props = {
  onClick: () => void,
  disabled?: boolean,
};

export const PreviousButtonWithArrow = ({ onClick, disabled = undefined }: Props) => (
  <Button
    data-testid="previous"
    disabled={disabled}
    onClick={onClick}
    kind="linkSeparate"
  >
    <span className={style.Arrow}>
      <ArrowLeft />
    </span>
    <Message
      id="Previous"
      comment="Button that goes to the previous step in a multistep process."
    />
  </Button>
);
