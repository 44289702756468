import type { ReactNode } from 'react';
import BoxEmptyDraftState from 'components/box-empty-draft-state';
import FileUploadRequirements from 'components/file-upload/file-upload-requirements';
import DropzoneUploadSpace from 'components/file-upload/dropzone-upload-space';

import style from './empty-upload-box.module.scss';

type Props = {
  editable: boolean,
  icon: ReactNode,
  title?: ReactNode,
  maxFileSizeBytes?: number,
  required?: boolean,
};

export const EmptyUploadBox = ({
  editable,
  maxFileSizeBytes,
  icon,
  title,
  required,
}: Props) => {
  if (!editable) {
    return (
      <div className={style.ReadOnlyNoFile}>
        <div className={style.ReadOnlyNoFileIcon}>
          {icon}
        </div>
        {title}
      </div>
    );
  }

  return (
    <div className={style.BoxEmptyContainer}>
      <BoxEmptyDraftState
        icon={icon}
        isDisabled={!editable}
        customClassName={style.BoxEmptyDraftState}
        description={(
          <DropzoneUploadSpace
            displayFileType="attachment"
            isContractBox
            body={(
              <FileUploadRequirements
                maxFileSize={maxFileSizeBytes}
              />
            )}
            showRejectMessage={false}
            required={required}
          />
        )}
      />
    </div>
  );
};
