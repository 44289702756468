// @flow

import React from 'react';
import moment from 'moment';
import { getDaysUntil } from 'date';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import {
  isUserInvited,
} from 'user';
import Alert from 'components/icons/alert';
import Tooltip from 'components/tooltip';
import { LocalizedDateTime } from 'components/localized-date-time';

import style from './get-last-active.module.scss';

export type Props = {
  position: Position,
  message: MessageTranslator,
}
export const getInvitationMessage = ({
  inviteExpirationDate,
  message,
}: { inviteExpirationDate: string, message: MessageTranslator }) => {
  const daysUntil = getDaysUntil(inviteExpirationDate);
  const isInvitationExpired = moment(inviteExpirationDate).isBefore(moment());
  const tooltipText = (
    <>
      {message({
        id: 'Invitation will expire in {count} day',
        pluralId: 'Invitation will expire in {count} days',
        pluralCondition: 'count',
        values: { count: daysUntil },
        comment: 'label to show  when the invitation expires',
      })}
    </>
  );

  if (isInvitationExpired) {
    return (
      <div className={style.Contain}>
        <Message
          id="Invitation expired"
          comment="label to show that an invitation has expired"
        />
        <Alert className={style.Alert} />
      </div>
    );
  }

  if (inviteExpirationDate === null) {
    return (
      <Message
        id="Invited"
        comment="label to show a user has been invited"
      />
    );
  }

  if (daysUntil === 0) {
    return (
      <Tooltip
        side="top"
        messageClassName={style.TooltipText}
        message={message({
          id: 'Invitation expires today',
          comment: 'label to show that invitation expires today',
        })}
      >
        <span>
          <Message
            id="Invited"
            comment="label to show a user has been invited"
          />
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      side="top"
      messageClassName={style.TooltipText}
      message={tooltipText}
    >
      <span>
        <Message
          id="Invited"
          comment="label to show a user has been invited"
        />
      </span>
    </Tooltip>
  );
};

export const GetLastActiveDate = ({ position, message }: Props) => {
  const inviteExpirationDate = (position.inviteExpireTimestamp);
  if (isUserInvited(position)) {
    return getInvitationMessage({ inviteExpirationDate, message });
  }
  if (!position.lastVisitTimestamp) {
    return '-';
  }

  return (
    <LocalizedDateTime datetime={position.lastVisitTime} />
  );
};
export default localize<Props>(GetLastActiveDate);
