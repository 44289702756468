import { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { AsyncAssetPropsProvider } from 'contexts/async-asset-props';
import { getGuestToken } from 'agreement/selectors';
import useAgreement from 'hooks/use-agreement';
import useResetFocusedEditor from 'hooks/rich-text-editor/use-reset-focused-editor';

import Sidebar from 'components/sidebar';
import BoxList from 'components/box-list';
import { ExpandedLayoutSidebar } from 'components/expanded-layout-sidebar';

import DocumentTopBar from 'components/document-topbar';
import DocumentStateBanner from 'components/document-state-banner';
import ContractToolbar from 'components/document-topbar/topbar/contract-toolbar';
import AvailableContentInlineSizeDetector from 'components/available-content-inline-size-detector';
import { ToastProvider } from 'components/toasts';
import EditWarningModal, { shouldShowEditWarningModal } from 'components/modals/edit-warning';
import { DocumentDndContextProvider } from 'components/document-layout-container/document-dnd-context';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import FilesUploadStatusBox from 'components/files-upload-status-box/files-upload-status-box';
import PaymentReminderBanner from 'components/payment-reminder-banner';
import { getResetKey } from 'reducers/current-contract';

import { FreemiumNoticeBanner } from 'components/freemium-notice-banner';
import style from './expanded-document-layout.module.scss';
import { isContractEditable } from '../helpers';

type Props = {
  agreementId: Oneflow.Document['id'],
}

const ExpandedDocumentLayout = ({ agreementId }: Props) => {
  const guestToken = useSelector(getGuestToken);
  const isGuest = Boolean(guestToken);
  const resetFocusedEditor = useResetFocusedEditor();

  const {
    documentContentRef,
    documentContentWrapperCallback,
    documentLayoutRef,
    documentScrollContainerRef,
  } = useDocumentLayout();

  const agreement = useAgreement(agreementId);
  const resetKey = useSelector(getResetKey);
  const [showTemplateEditWarningModal, setShowTemplateEditWarningModal] = useState(false);
  const [hasShownTemplateEditWarningModal, setHasShownTemplateEditWarningModal] = useState(false);

  const handleOnFocus = () => {
    if (shouldShowEditWarningModal(agreement) && !hasShownTemplateEditWarningModal) {
      setShowTemplateEditWarningModal(true);
      setHasShownTemplateEditWarningModal(true);
    }
  };

  return (
    <div
      className={style.ExpandedLayoutContainer}
      onPointerDown={resetFocusedEditor}
    >
      {!isGuest
        && (
          <div data-testid="admin-sidebar" className={style.AdminSidebar}>
            <Sidebar
              isBackdropEnabled={false}
              isPositionFixed={false}
              isContractView
            />
          </div>
        )}
      <div
        className={clsx(style.DocumentLayout, {
          [style.isGuest]: isGuest,
        })}
        ref={documentLayoutRef}
      >
        <FreemiumNoticeBanner />
        <PaymentReminderBanner />
        <DocumentTopBar agreementId={agreementId} />
        <div className={style.ContentAndSidebarContainer}>
          <DocumentDndContextProvider>
            <div className={style.ToolbarAndContent}>
              {!isGuest && (
                <ContractToolbar
                  agreement={agreement}
                  isContractEditable={isContractEditable(agreement)}
                />
              )}
              <DocumentStateBanner
                ref={documentScrollContainerRef}
                agreement={agreement}
              />
              <div
                className={style.ContentWrapper}
                ref={documentContentWrapperCallback}
              >
                <div
                  className={style.Scrollable}
                  ref={documentScrollContainerRef}
                >
                  <div className={style.ContentContainer}>
                    <div
                      ref={documentContentRef}
                      className={style.Content}
                    >
                      <AvailableContentInlineSizeDetector>
                        <EditWarningModal
                          isOpen={showTemplateEditWarningModal}
                          onCancel={() => {
                            setShowTemplateEditWarningModal(false);
                          }}
                          shouldReturnFocusAfterClose={false}
                        />
                        <AsyncAssetPropsProvider>
                          <BoxList
                            agreementId={agreementId}
                            onFocus={handleOnFocus}
                            key={resetKey}
                          />
                        </AsyncAssetPropsProvider>
                      </AvailableContentInlineSizeDetector>
                    </div>
                  </div>
                </div>
                <ToastProvider position="bottom-center" viewportClassName={style.BottomCenter} />
                {/*
                  we're rendering this separately for expanded and collapsed layout because its
                  order in the DOM should be different
                */}
                <FilesUploadStatusBox />
              </div>
            </div>
            <ExpandedLayoutSidebar />
          </DocumentDndContextProvider>
        </div>
      </div>
    </div>
  );
};

export default ExpandedDocumentLayout;
