import { localize } from '@oneflowab/pomes';
import Message from 'components/message';
import type { MessageTranslator } from '@oneflowab/pomes';
import type { FunctionComponent } from 'react';

import { formatTimePeriod } from 'agreement/date-helpers';

import { SetDate } from 'components/contract-boxes/duration-box/set-date';
import { SetTimeSpan } from 'components/contract-boxes/duration-box/set-time-span';
// eslint-disable-next-line import/named
import { useDurationBoxProps } from 'contexts/duration-box-props';

import style from '../duration-box-container.module.scss';

type Props = {
  message: MessageTranslator,
};

const RecurringComponent = ({
  message,
}: Props) => {
  const {
    endDate,
    noticePeriod,
    startDate,
    initialDuration,
  } = useDurationBoxProps();

  const renderStartDateMessage = () => {
    if (!startDate) {
      return null;
    }

    return (
      <>
        <br />
        <Message
          id="The start date for the contract is {setStartDateComponent}."
          comment="Text to show the start date of contract."
          values={{
            setStartDateComponent: <SetDate dateType="startDate" />,
          }}
        />
      </>
    );
  };

  const renderDuration = () => {
    if (initialDuration) {
      return (
        <div>
          <Message
            id="The first contract period is {SetInitialDurationComponent}. Thereafter the contract is automatically renewed with subsequent periods of {jsx-start}{SetDurationComponent}.{jsx-end} {breakLine}The notice period for canceling the contract is {noticePeriod} before the end of each contract period."
            comment="Text to describe initial duration of a contract and how it will renew"
            values={{
              SetInitialDurationComponent: <SetTimeSpan toSet="initialDuration" />,
              SetDurationComponent: <SetTimeSpan toSet="duration" />,
              noticePeriod: <SetTimeSpan toSet="noticePeriod" />,
              breakLine: <br />,
            }}
            component="span"
            className={style.RecurringWithInitialDuration}
          />
        </div>
      );
    }

    if (!endDate) {
      return (
        <div>
          <Message
            id="The contract period is {SetDurationComponent} and the notice period is {SetNoticePeriodComponent}.{breakLine}The contract is automatically renewed for another contract period unless canceled a minimum of {noticePeriod} before the end of each period."
            comment="Text to describe duration of a contract and the notice period"
            values={{
              SetDurationComponent: <SetTimeSpan toSet="duration" />,
              SetNoticePeriodComponent: <SetTimeSpan toSet="noticePeriod" />,
              noticePeriod: formatTimePeriod(noticePeriod, message),
              breakLine: <br />,
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <Message
          id="The first contract period lasts until {setEndDateComponent}. Thereafter the contract is automatically renewed with subsequent periods of {SetDurationComponent}. {breakLine}The notice period for terminating the contract is {noticePeriod} before the end of each contract period."
          comment="Text describing that a contract is automatically renewed"
          values={{
            setEndDateComponent: <SetDate dateType="endDate" />,
            SetDurationComponent: <SetTimeSpan toSet="duration" />,
            noticePeriod: <SetTimeSpan toSet="noticePeriod" />,
            breakLine: <br />,
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div>
        {renderDuration()}
      </div>
      {renderStartDateMessage()}
    </>
  );
};

export const Recurring = localize<Props>(RecurringComponent) as unknown as FunctionComponent;
