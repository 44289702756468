// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';

import IndentIncrease from 'components/icons/indent-increase';
import IndentDecrease from 'components/icons/indent-decrease';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import { insertIndentation, insertShiftIndentation } from './indent-plugin';
import ToolbarButton from '../toolbar-button';
import style from '../toolbar-button.module.scss';

type Props = {
  isMenuItem: boolean,
  disabled: boolean,
  label: React.Node,
  direction: 'forward' | 'backward',
};

const IndentButton = ({
  isMenuItem,
  direction,
  label,
  disabled,
}: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      isMenuItem={isMenuItem}
      label={label}
      disabled={disabled}
      customClass={style.ToolbarButton}
      onTrigger={() => {
        focusEditor();
        if (direction === 'forward') {
          insertIndentation(editor);
        } else {
          insertShiftIndentation(editor);
        }
      }}
      icon={direction === 'forward' ? <IndentIncrease /> : <IndentDecrease />}
    />
  );
};

export default IndentButton;
