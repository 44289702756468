// @flow

import * as React from 'react';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';
import useRouterHistory from 'hooks/use-router-history';
import { isTemplate } from 'agreement/states';

import { ContractNameField, TemplateNameField } from 'components/fields';

export type FormData = {
  name?: string,
};

export type Props = {|
  message: MessageTranslator,
  formState: CreateState,
  copyContract: FormData => void,
  isCreatingTemplate: boolean,
  resetFormState: () => void,
  children: ((onClick: Function) => React.Node) | React.Node,
  agreement: Agreement,
|};

export const CopyContractComponent = ({
  agreement,
  isCreatingTemplate,
  message,
  formState,
  copyContract,
  resetFormState,
  children,
}: Props) => {
  // TODO: Replace useRouterHistory with useHistory after the migrating away from backbone
  const history = useRouterHistory();
  const getDefaultContractName = () => {
    if (!agreement.name) {
      return '';
    }

    if (isCreatingTemplate) {
      return agreement.name;
    }

    return message({
      id: '{name} copy',
      values: {
        name: agreement.name,
      },
      comment: 'The message used as the default value for contract name when copying contracts.',
    });
  };

  const getTitle = () => {
    if (isCreatingTemplate) {
      return (
        <Message
          id="Create template"
          comment="Modal title for creating a template from contract."
        />
      );
    }

    return (
      <Message
        id="Copy"
        comment="Modal title for copying a contract or a template."
      />
    );
  };

  const renderNameField = () => {
    if (isTemplate(agreement) || isCreatingTemplate) {
      return <TemplateNameField autoFocus />;
    }

    return <ContractNameField autoFocus />;
  };

  const onSubmit = React.useCallback((formData) => {
    copyContract(formData, history);
  }, [copyContract, history]);

  return (
    <ModalForm
      title={getTitle()}
      body={<div>{renderNameField()}</div>}
      onSubmit={onSubmit}
      resetFormState={resetFormState}
      formState={formState}
      initialValues={{
        name: getDefaultContractName(),
      }}
      allowPristine
      modalKey="copy contract/template modal"
    >
      {children}
    </ModalForm>
  );
};

export default localize<Props>(CopyContractComponent);
