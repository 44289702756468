import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

import { getSignAttempted } from 'reducers/current-contract';

type FieldValidationContextType = {
  touched: boolean;
  setTouched: Dispatch<SetStateAction<boolean>>;
};

const FieldValidationContext = createContext<FieldValidationContextType | undefined>(undefined);

const FieldValidationProvider = ({ children }: { children: ReactNode }) => {
  const signAttempted = useSelector(getSignAttempted);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (signAttempted) {
      setTouched(true);
    }
  }, [signAttempted]);

  return (
    <FieldValidationContext.Provider value={{ touched, setTouched }}>
      {children}
    </FieldValidationContext.Provider>
  );
};

export default FieldValidationProvider;

export const useFieldValidationContext = () => {
  const contextValue = useContext(FieldValidationContext);

  if (!contextValue) {
    throw new Error('useFieldValidationContext must be used within a FieldValidationProvider');
  }

  return contextValue;
};
