import type { MessageTranslator } from '@oneflowab/pomes';

// eslint-disable-next-line import/prefer-default-export
export const suggestionAccepted = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'Suggestions accepted',
    comment: 'Audit trail message',
  }),
  showEditIcon: true,
  byLine: false,
});
