// @flow

import { withQueryStringParamsProvider, withQueryStringParamsConsumer } from 'hocs/query-string-params';
import { withFilterContextProvider, withFilterConsumer } from 'hocs/search/filter/context';
import { Search } from 'hocs/search';

const SearchWithQueryString = withQueryStringParamsProvider(
  withQueryStringParamsConsumer(
    withFilterContextProvider(withFilterConsumer(Search)),
  ),
);

export default SearchWithQueryString;
