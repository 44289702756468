import { normalize, schema } from 'normalizr';

import { get, extractResponseBodyAsJSON } from 'oneflow-client/core';

const agreementCreatorsSchema = new schema.Entity('agreementCreators', {}, {
  idAttribute: 'id',
});

export const normalizeAgreementCreators = (data) => ({
  ...normalize(data, [agreementCreatorsSchema]),
  count: data.length,
  fromDate: data.fromDate,
  toDate: data.toDate,
});

export const getAgreementCreators = ({
  params: {
    workspaceId,
    positionId,
    fromDate,
    toDate,
  },
}) => (
  get({
    url: '/dashboard/agreement_creators/',
    params: {
      collection_id: workspaceId,
      position_id: positionId,
      from_date: fromDate,
      to_date: toDate,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAgreementCreators)
);
