import { connect } from 'react-redux';

import accountsReducer from 'reducers/entities/accounts';
import positionsReducer from 'reducers/entities/positions';
import { getAccountFromSessionSelector } from 'reducers/session';

import { UpdateUserStatus } from './update-user-status';

export const mapStateToProps = (state, { position }) => ({
  updateState: positionsReducer.getUpdateSelector(state, { id: position.id }),
  accountId: getAccountFromSessionSelector(state).id,
});

export const mapDispatchToProps = (dispatch, { position }) => ({
  updatePosition: (active, accountId) => {
    dispatch(positionsReducer.updatePosition({
      id: position.id,
      data: {
        active,
      },
      pipe: {
        action: () => accountsReducer.fetchAccount({
          id: accountId,
        }),
      },
    }));
  },
  resetUpdateState: () => {
    dispatch(positionsReducer.updatePositionReset({
      id: position.id,
    }));
  },
});

const connectedUpdateUserStatus = connect(mapStateToProps, mapDispatchToProps)(UpdateUserStatus);

export { connectedUpdateUserStatus as UpdateUserStatus };
