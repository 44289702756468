// @flow

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import { useFormState } from 'react-final-form';
import debounce from 'debounce-promise';

import { getPositions } from 'oneflow-client/positions';
import getCSSPropertyValue from 'utils/get-css-property-value';
import { USER_NOT_INVITED, USER_ACTIVE } from 'user';

import Field from 'components/field';
import SelectField from 'components/select-field';

import RecipientsValidation from './recipients-validation';

import style from './internal-reminder-body.module.scss';

type Props = {
  contract: Agreement,
  currentUser: Position,
  message: MessageTranslator,
  disabled: boolean,
  onChange: () => void,
}

export function RecipientsSelectField(props: Props) {
  const {
    contract, currentUser, message, disabled, onChange,
  } = props;

  const { values } = useFormState();

  const customStyles = useMemo(() => {
    const multiValueRemove = (providedStyles, state) => {
      if (state.data.isFixed) {
        return { ...providedStyles, visibility: 'hidden', pointerEvents: 'none' };
      }
      return providedStyles;
    };

    if (contract?.id) {
      return {
        multiValue: (providedStyles, state) => {
          if (state.data.needsAccess) {
            return {
              ...providedStyles,
              backgroundColor: `${getCSSPropertyValue('--of-edit-hover')} !important`,
            };
          }

          return providedStyles;
        },
        multiValueRemove,
      };
    }
    return {
      multiValue: (providedStyles) => providedStyles,
      multiValueRemove,
    };
  }, [contract?.id]);

  const searchPositions = useMemo(() => debounce(async (value) => {
    const request = {
      params: {
        invited: USER_NOT_INVITED,
        active: USER_ACTIVE,
      },
      pagination: {
        offset: 0,
        limit: 100,
      },
      normalizeOutput: false,
    };

    if (value) {
      request.params.q = value;
    }

    try {
      const data = await getPositions(request);
      return data.collection.map((position) => ({
        ...position,
        isFixed: position.id === currentUser.id,
      }));
    } catch {
      /** NOTE:
       * This endpoint is meant to be used both on initial dropdown click and on searching.
       * It is ok to show the API error on initial dropdown click but not during search
       * (on each keystroke) since it will give the user junky experience.
       * As the react-select (AsyncSelect) takes care of both the scenarios, it might be tricky
       * to set error even on initial dropdown click.
       * Not having catch block will lead to error bubbling up and may crash the component
       */
      return [];
    }
  }, 500), [currentUser.id]);

  return (
    <div className={clsx(style.Row, style.RecipientsField, {
      [style.Disabled]: disabled,
    })}
    >
      <div className={style.DisabledBackground}>
        <Field
          label={message({
            id: 'To',
            comment: 'Label for the recipients-field in custom reminder modal',
          })}
          name="recipients"
          placeholder={message({
            id: 'Select colleague',
            comment: 'Placeholder for selecting colleagues in custom reminder modal',
          })}
          customStyles={customStyles}
          component={SelectField}
          labelKey="fullname"
          valueKey="id"
          hideErrorElement
          required
          multi
          clearable={values?.recipients?.some((recipient) => !recipient.isFixed)}
          backspaceRemovesValue
          isSearchable
          async
          loadOptions={searchPositions}
          defaultOptions
          disabled={disabled}
          onChange={onChange}
        />
        <RecipientsValidation
          contract={contract}
          recipients={values?.recipients}
        />
      </div>
    </div>
  );
}
const LocalizedRecipientsSelectField = localize<Props>(RecipientsSelectField);

type ReadOnlyProps = {
  message: MessageTranslator,
}

LocalizedRecipientsSelectField.ReadOnly = localize<Props>(({ message }: ReadOnlyProps) => (
  <div className={clsx(style.Row, style.RecipientsField)}>
    <div>
      <Field
        label={message({
          id: 'To',
          comment: 'Label for the recipients-field in custom reminder modal',
        })}
        name="recipients"
        placeholder={message({
          id: 'Select colleague',
          comment: 'Placeholder for selecting colleagues in custom reminder modal',
        })}
        component={SelectField}
        noDropDownIndicator
        labelKey="fullname"
        valueKey="id"
        hideErrorElement
        multi
        disabled
      />
    </div>
  </div>
));

export default LocalizedRecipientsSelectField;
