import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { MessageTranslator } from '@oneflowab/pomes';
import { closestCenter } from '@dnd-kit/core';
import clsx from 'clsx';

import { BOX_TYPES } from 'agreement/constants';
import { isBoxReorderAllowed } from 'agreement/box-reorder-permissions';
import { isBoxCreateAllowed } from 'agreement/add-box-permissions';
import { useGetBoxPermissions, BoxPermissionsProvider } from 'hooks/use-permissions';

import useBoxItem from 'hooks/use-box-item';
import useCurrentBox from 'hooks/use-current-box';
import useAgreement from 'hooks/use-agreement';
import useMatchMedia, { DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY } from 'hooks/use-match-media';
import { ejectBox, getCollapsedBoxIds, updateCollapsedBoxIds } from 'reducers/current-contract';

import { DurationBox } from 'components/contract-boxes/duration-box';
import AttachmentBox from 'components/contract-boxes/attachment-box';
import TextImageBox from 'components/contract-boxes/text-image-box';
import FormBox from 'components/contract-boxes/form-box';
import ProductTableBox from 'components/contract-boxes/product-table-box';
import { ProductSumBox } from 'components/contract-boxes/product-sum-box';
import VideoBox from 'components/contract-boxes/video-box';
import { PdfBox } from 'components/contract-boxes/pdf-box';
import BoxDivider from 'components/box-list/box-divider';
import RulesModal from './rules-modal';
import DragControls from './drag-controls';
import styles from './box-item.module.scss';

const BOXES = {
  [BOX_TYPES.BOX_DURATION]: DurationBox,
  [BOX_TYPES.BOX_PDF]: PdfBox,
  [BOX_TYPES.BOX_ATTACHMENTS]: AttachmentBox,
  [BOX_TYPES.BOX_TEXT_AND_IMAGE]: TextImageBox,
  [BOX_TYPES.BOX_FORM]: FormBox,
  [BOX_TYPES.BOX_PRODUCT_TABLE]: ProductTableBox,
  [BOX_TYPES.BOX_PRODUCT_SUMMATION]: ProductSumBox,
  [BOX_TYPES.BOX_VIDEO]: VideoBox,
};

type Props = {
  index: number;
  boxId: number;
  agreementId: number;
  message: MessageTranslator;
  hasBrokenOrHiddenSectionRule: boolean;
};

const BoxItem = ({
  index,
  boxId,
  agreementId,
  message,
  hasBrokenOrHiddenSectionRule,
}: Props) => {
  const dispatch = useDispatch();
  const box = useCurrentBox(boxId);
  const collapsedBoxIds = useSelector(getCollapsedBoxIds);
  const agreement = useAgreement(agreementId);
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);
  const isCollapsedLayout = useMatchMedia(DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY);

  const permissions = useGetBoxPermissions(agreementId, boxId);

  const {
    attributes,
    listeners,
    transform,
    transition,
    active,
    over,
    isDragging,
    setNodeRef,
    setActivatorNodeRef,
  } = useSortable({
    id: boxId,
    data: {
      segmentId: 'sortable_box_list',
      collisionDetection: closestCenter,
      type: box.type,
    },
    animateLayoutChanges: () => false,
  });

  const activeSegmentId = active?.data?.current?.segmentId;
  const isDropBlocked = over?.id === boxId && activeSegmentId !== 'sortable_box_list';
  const isDimmerVisible = isDragging || isDropBlocked;

  const { isFirst } = useBoxItem(boxId);

  const isReorderAllowed = isBoxReorderAllowed(agreement);
  const isAllowedToAddBox = isBoxCreateAllowed(agreement);

  const handleOpenRulesModal = () => {
    setIsRulesModalOpen(true);
  };

  const handleCloseRulesModal = () => {
    setIsRulesModalOpen(false);
  };

  const handleRemoveBox = () => {
    dispatch(ejectBox({ boxId, boxType: box.type }));
    if (collapsedBoxIds.includes(boxId)) {
      dispatch(updateCollapsedBoxIds(collapsedBoxIds.filter((id) => id !== boxId)));
    }
  };

  const Component = BOXES[box.type];
  const isAllowedToDrag = !isDragging && isReorderAllowed && !isCollapsedLayout;

  return (
    <div
      ref={setNodeRef}
      className={clsx(styles.BoxItem,
        {
          [styles.CollapsedDocument]: isCollapsedLayout,
          [styles.ReadOnly]: !isReorderAllowed && isCollapsedLayout,
        })}
      style={{
        transition,
        transform: CSS.Transform.toString(transform),
      }}
      data-hidden={hasBrokenOrHiddenSectionRule}
      data-box-id={boxId}
    >
      {isAllowedToDrag && (
        <DragControls
          index={index}
          boxId={boxId}
          ref={setActivatorNodeRef}
          message={message}
          className={styles.DragControls}
          {...attributes}
          {...listeners}
        />
      )}
      {isFirst && isAllowedToAddBox && (
        <BoxDivider
          index={index}
          boxId={boxId}
          className={styles.BoxDividerFirst}
          isAlwaysVisible={isCollapsedLayout}
        />
      )}
      <BoxPermissionsProvider agreementId={agreementId} boxId={boxId}>
        <Component
          boxId={boxId}
          isEditable={permissions.update}
          // TODO: use permissions.[action], say, permission.update instead of isEditable
          permissions={permissions}
          agreementId={agreementId}
          onRemoveBox={handleRemoveBox}
          onAddSectionRules={handleOpenRulesModal}
        />
      </BoxPermissionsProvider>
      {isAllowedToAddBox && (
        <BoxDivider
          index={index + 1}
          boxId={boxId}
          className={clsx(styles.BoxDivider, {
            [styles.CollapsedDocumentBoxDivider]: isCollapsedLayout,
          })}
          isAlwaysVisible={isCollapsedLayout}
        />
      )}
      {isDimmerVisible ? <div className={styles.Dimmer} /> : null}
      {isRulesModalOpen && (
        <RulesModal
          box={box}
          agreementId={agreementId}
          onClose={handleCloseRulesModal}
        />
      )}
    </div>
  );
};

export default BoxItem;
