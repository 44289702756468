/* eslint-disable react/prop-types */
import React from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import Alert from 'components/alert';
import Message from 'components/message';
import WarningIcon from 'components/icons/warning';

import useDocumentViewLayoutInfo from 'hooks/use-document-view-layout-info';

import style from './failure-toast.module.scss';

function getStyle(sideOffset, columnLayout) {
  const toastStyle = {};

  // In double coulmn layout relative/absolute positioning is used
  if (!sideOffset || columnLayout === 'double') {
    return toastStyle;
  }

  // Patching up fixed positioning which is based on viewport
  toastStyle.transform = `translateX(calc(-50% + ${sideOffset}px))`;

  return toastStyle;
}

// The component should not to be used outside document/contract view
// Positioning is calculated speicifically for contract view
// Use FailureToast (default export) directly if possible
// and take control of the positioning (fixed, absolute) or
// wrap inside Snackbar
function DocumentViewFailureToast(props) {
  const {
    portalTarget,
    className,
    sideOffset,
    ...toastProps
  } = props;
  const { columnLayout } = useDocumentViewLayoutInfo();

  if (!portalTarget) {
    return null;
  }

  const toastClassName = clsx(className, style.DocumentViewPositioning, {
    [style.SingleColumn]: columnLayout === 'single',
  });

  // Keep style, className props as it is in the last position
  return createPortal(
    <FailureToast
      {...toastProps}
      style={getStyle(sideOffset, columnLayout)}
      className={toastClassName}
    />,
    portalTarget,
  );
}

function ContactSupport({ children }) {
  return (
    <a className={style.SupportEmail} href="mailto:support@oneflow.com">{children}</a>
  );
}

function FailureToast(props) {
  const {
    reason,
    style: toastStyle,
    className,
    close,
  } = props;

  if (!reason) {
    return null;
  }

  const actionableUserHint = (
    <Alert
      onClose={close}
      severity="error"
      icon={<WarningIcon height="15px" width="15px" />}
    >
      <Message
        id="Something went wrong. Please check your request and try again. If the problem persists, please {jsx-start}contact support{jsx-end}."
        comment="User hint to notify that request is failed"
        component={ContactSupport}
      />
    </Alert>
  );

  // TODO: Re-assign actionableUserHint based on api_error_code mapper

  return (
    <div
      style={toastStyle}
      className={clsx(style.Failure, className)}
    >
      {actionableUserHint}
    </div>
  );
}

FailureToast.DocumentView = DocumentViewFailureToast;

export default FailureToast;
