import { useCallback } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import { DATA_FIELD, SIGNATURE_FIELD } from 'components/contract-boxes/constants';

const calculateDragPosition = (over: NonNullable<DragEndEvent['over']>, droppableDragOverlay: HTMLElement, scale: number) => {
  const combinedSideBordersWidth = 2;
  const width = droppableDragOverlay.offsetWidth - combinedSideBordersWidth;
  let height = droppableDragOverlay.offsetHeight - combinedSideBordersWidth;
  const maxX = (over.rect.width / scale) - width - combinedSideBordersWidth;
  const maxY = (over.rect.height / scale) - height - combinedSideBordersWidth;

  const droppableRect = droppableDragOverlay.getBoundingClientRect();
  const scaledX = (droppableRect.left - over.rect.left) / scale;
  const scaledY = (droppableRect.top - over.rect.top) / scale;

  const calculatedX = Math.min(scaledX, maxX);
  const calculatedY = Math.min(scaledY, maxY);

  const x = Math.max(0, calculatedX);
  const y = Math.max(0, calculatedY);

  const availableHeight = (over.rect.height / scale);
  if (y === 0 && height > availableHeight) {
    height = availableHeight;
  }

  return {
    x, y, width, height,
  };
};

export const useSmartFieldOnDragEnd = () => {
  const handleDragEnd = useCallback((active: DragEndEvent['active'], over: DragEndEvent['over'], type: string, dataFieldKey: string | null) => {
    const droppableDragOverlay = active?.data.current?.droppableDragOverlayRef.current;
    if (!droppableDragOverlay) {
      return;
    }

    const onDrop = over?.data.current?.onDrop;
    const scale = over?.data.current?.scale;

    if (!onDrop || !scale || !over) {
      return;
    }

    const {
      x, y, width, height,
    } = calculateDragPosition(over, droppableDragOverlay, scale);

    onDrop(type, dataFieldKey, { x, y }, { width, height });
  }, []);

  const onSmartFieldOnDragEnd = ({ active, over }: DragEndEvent) => {
    const activeType = active?.data?.current?.type;
    if (!activeType) {
      return;
    }

    if (activeType === DATA_FIELD) {
      handleDragEnd(active, over, DATA_FIELD, active?.data?.current?.data?.key);
    } else if (activeType === SIGNATURE_FIELD) {
      handleDragEnd(active, over, SIGNATURE_FIELD, null);
    } else {
      throw new Error(`Unsupported type: ${activeType}`);
    }
  };

  return onSmartFieldOnDragEnd;
};
