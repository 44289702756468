// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { MessageTranslator } from '@oneflowab/pomes';

import apiTokensReducer from 'reducers/entities/api-tokens';

import GeneratedToken from './generated-token';
import type { Props, TokenEntity } from './generated-token';

type StateProps = {||};

type DispatchProps = {|
  clearToken: TokenEntity => () => void,
|};

type MainProps = $Diff<Props, {| message: MessageTranslator |}>;

type OwnProps = $Diff<Props, DispatchProps & {| message: MessageTranslator |}>;

type MapStateToProps = () => StateProps;
export const mapStateToProps: MapStateToProps = () => Object.freeze({});

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  clearToken: (tokenEntity: TokenEntity) => () => {
    dispatch(apiTokensReducer.setApiTokens({
      [tokenEntity.id]: {
        token: null,
      },
    }));
  },
});

export default connect<MainProps, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(GeneratedToken);
