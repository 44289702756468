import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import Modal from 'components/modal';
import { FeatureList } from 'components/modals/cancellation/lost-features/feature-list';
import bunny from './bunny.png';

import style from './lost-features.module.scss';

export type Props = {
  modalKey: string;
  onClose: () => void;
  onStepComplete: () => void;
}

const LostFeaturesModal = ({
  modalKey,
  onClose,
  onStepComplete,
}: Props) => {
  const onClickHandler = () => {
    onStepComplete();
  };

  const getActions = () => (
    <div className={style.Buttons}>
      <Button
        onClick={onClose}
      >
        <Message
          id="Keep my Subscription Plan"
          comment="Text for button to keep the subscription."
        />
      </Button>
      <Button
        kind="primary"
        onClick={onClickHandler}
      >
        <Message
          id="Continue to cancel"
          comment="Text for button to confirm action."
        />
      </Button>
    </div>
  );

  const renderBody = () => (
    <>
      <div className={style.Container}>
        <img src={bunny} alt="bunny" width="240px" height="150px" className={style.Img} />
        <Message
          id="You will continue to have access to features until the end of the Subscription Plan period."
          comment="Message shown in cancellation modal"
        />
      </div>
      <FeatureList />
    </>
  );

  return (
    <Modal
      header={(
        <Message
          id="We are sad to see you go"
          comment="Message shown in change plan modal"
        />
      )}
      onCancel={onClose}
      isOpen
      modalKey={modalKey}
    >
      <Modal.Body isLoading={false}>
        {renderBody()}
      </Modal.Body>
      <Modal.Actions>
        {getActions()}
      </Modal.Actions>
    </Modal>
  );
};

export default LostFeaturesModal;
