import clsx from 'clsx';
import {
  useRef, ReactElement,
} from 'react';
import {
  Root, Item, Trigger, Content,
} from '@radix-ui/react-accordion';
import { debounce } from 'lodash';

import getCSSPropertyValue from 'utils/get-css-property-value';
import { sectionNames } from 'components/document-tabs/settings-tab/helpers';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';

import ChevronDownIcon from 'components/icons/chevron-down';
import style from './accordion.module.scss';

type Props = {
  children: React.ReactNode,
  className?: string,
  contentClassName?: string,
  name: typeof sectionNames[number],
  title: ReactElement,
}

const Accordion = ({
  children,
  className,
  contentClassName,
  name,
  title,
}: Props) => {
  const documentContext = useDocumentLayout();

  const contentRef = useRef<HTMLDivElement>(null);
  const animationMsValue = Number(getCSSPropertyValue('--animation-ms-value').replace(/\D/g, ''));

  const handleValueChange = (value: string) => {
    const hasExpanded = Boolean(value);
    documentContext.updateAccordionStates({ name, state: hasExpanded });

    if (hasExpanded) {
      debounce(() => contentRef.current?.scrollIntoView({ behavior: 'smooth' }), animationMsValue)();
    }
  };

  const getValue = () => (documentContext.accordionStates[name] ? name : '');

  return (
    <Root
      className={clsx(style.Accordion, className)}
      collapsible
      onValueChange={handleValueChange}
      type="single"
      value={getValue()}
    >
      <Item value={name}>
        <Trigger className={style.Trigger}>
          <h3 className={style.Title} data-testid="accordion-title">
            {title}
          </h3>
          <ChevronDownIcon height="10px" className={style.Icon} />
        </Trigger>
        <Content className={clsx(style.Content, contentClassName)} ref={contentRef}>
          {children}
        </Content>
      </Item>
    </Root>
  );
};

export default Accordion;
