import { Message } from '@oneflowab/pomes';

import Modal from 'components/modal';
import Button from 'components/button';
// eslint-disable-next-line import/named
import { CancelButton } from 'components/buttons';
import WarningIcon from 'components/icons/warning';

import style from './reset-draft-approvals.module.scss';

export type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const ResetDraftApprovalsWarning = ({ isOpen, onCancel, onConfirm }: Props) => (
  <Modal
    header={(
      <Message
        id="Save and reset"
        comment="Modal title for save and reset approvals warning modal."
      />
          )}
    isOpen={isOpen}
    onCancel={onCancel}
    shouldCloseOnOverlayClick={false}
    shouldCloseOnEsc
    modalKey="reset approvals modal"
  >
    <Modal.Body>
      <div className={style.BodyContainer}>
        <WarningIcon className={style.WarningIcon} />
        <div className={style.BodyContainerText}>
          <b>
            <Message
              id="Saving the changes will reset all existing approvals."
              comment="Modal body text for save and reset approvals warning modal."
            />
          </b>
          <p>
            <Message
              id="The document will need  to be approved again."
              comment="Modal body text for save and reset approvals warning modal."
            />
          </p>
        </div>
      </div>
    </Modal.Body>
    <Modal.Actions>
      <div className={style.CancelButton}>
        <CancelButton
          onClick={onCancel}
          modalKey="reset approvals modal"
        />
      </div>
      <Button
        onClick={onConfirm}
        kind="primary"
      >
        <Message
          id="Save and reset"
          comment="Button text in the save and reset approvals warning modal."
        />
      </Button>
    </Modal.Actions>
  </Modal>
);

export default ResetDraftApprovalsWarning;
