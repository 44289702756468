import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { KPI } from 'components/kpis/kpi/kpi-container';
import type { DashboardFilters } from 'components/kpis/kpi';

type Props = {
  message: MessageTranslator,
  count: number,
  value: number,
  tooltip: string,
  filters: DashboardFilters,
}

export const KPIOverdueComponent = ({
  message,
  count,
  value,
  tooltip,
  filters,
}: Props) => (
  <KPI
    label={message({
      id: 'Overdue',
      comment: 'Dashboard KPI for overdue contracts',
    })}
    type="Overdue"
    count={count}
    tooltip={tooltip}
    agreementValueAmount={value}
    filters={filters}
  />
);

export default localize<Props>(KPIOverdueComponent);
