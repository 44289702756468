// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import InfoBoxClipboard from 'components/info-box-clipboard';

import style from './webhook-signature.module.scss';

const WebhookSignature = () => (
  <>
    <div className={style.Header}>
      <Message
        id="Sign key and signature"
        comment="Help text for webhook signatures."
      />
    </div>
    <p>
      <Message
        id="You may set a sign key as an optional measure of security. If a sign key has been specified, the webhook request will include a signature allowing you to verify Oneflow as the request origin."
        comment="Help text for webhook signatures."
      />
    </p>
    <p>
      <Message
        id="The signature is a SHA1 hash of the concatenated values of the request callback_id and your sign key in the format:"
        comment="Help text for webhook signatures."
      />
    </p>
    <InfoBoxClipboard value="signature = sha1( callback_id + <Sign key> )" />
  </>
);

export default WebhookSignature;
