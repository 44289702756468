// @flow

import * as React from 'react';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import {
  getStartOfDayTimestamp,
  getEndOfDayTimestamp,
  toMoment,
} from 'date';

import { DatePicker } from 'components/date-picker';
import SelectField from 'components/select-field';

import DatePresets from './date-presets';

type Value = 'created' | 'publish_timestamp' | 'close_timestamp' | 'start_timestamp' | 'upcoming' | 'removed_time';

type Props = {
  onChange: ({| field?: string, from?: number, to?: number |}) => void,
  value?: {
    field: string,
    from?: number,
    to?: number,
  },
  message: MessageTranslator,
  enabledOptions?: Array<Value>,
  defaultOption: Value,
  dateFormat: string,
};

type State = {
  field?: string,
  from?: moment$Moment,
  to?: moment$Moment,
  focusedInput?: 'startDate' | 'endDate',
  propagateChange: boolean,
};

type OnDatesChange = ({
  startDate?: moment$Moment,
  endDate?: moment$Moment,
}) => void;

type Option = {|
  value: Value,
  label: React.Node,
|};

export class DateFilterComponent extends React.PureComponent<Props, State> {
  static defaultProps = {
    value: undefined,
    enabledOptions: undefined,
  };

  options: Array<Option> = ((message) => ([
    {
      value: 'created',
      label: message({ id: 'Created', comment: 'Dropdown option label. Filter contracts by creation date range' }),
    },
    {
      value: 'publish_timestamp',
      label: message({ id: 'Sent', comment: 'Dropdown option label. Filter contracts by sent date range' }),
    },
    {
      value: 'close_timestamp',
      label: message({ id: 'Signed/Declined', comment: 'Dropdown option label. Filter contracts by signature date range' }),
    },
    {
      value: 'start_timestamp',
      label: message({ id: 'Started', comment: 'Dropdown option label. Filter contracts by starting date range' }),
    },
    {
      value: 'upcoming',
      label: message({ id: 'Upcoming events', comment: 'Dropdown option label. Filter contracts by when they have their next state or life cycle event.' }),
    },
    {
      value: 'removed_time',
      label: message({ id: 'Trashed date', comment: 'Dropdown option label. Filter contracts by document trashed range.' }),
    },
  ]))(this.props.message);

  constructor(props: Props) {
    super(props);

    this.state = this.getCleanState();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { onChange } = this.props;
    const { field, from, to } = this.state;

    if (this.valueHasChanged(prevProps)) {
      this.resetState();

      return;
    }

    if (!this.state.propagateChange) {
      return;
    }

    if (this.hasChanged(prevState) && this.isComplete()) {
      onChange({
        field,
        from: from && getStartOfDayTimestamp(from),
        to: to && getEndOfDayTimestamp(to),
      });

      return;
    }

    if (this.wasCleared(prevState)) {
      onChange({
        field: undefined,
        from: undefined,
        to: undefined,
      });
    }
  }

  getCleanState(propagateChange?: boolean = true) {
    const { value } = this.props;
    const { defaultOption } = this.props;

    if (!value) {
      return {
        field: defaultOption,
        from: undefined,
        to: undefined,
        propagateChange,
      };
    }

    let from;
    let to;

    if (value.from) {
      from = toMoment(value.from);
    }

    if (value.to) {
      to = toMoment(value.to);
    }

    return {
      from,
      to,
      field: value.field || defaultOption,
      propagateChange,
    };
  }

  onFilterFieldChange = (selectedOption?: Option) => {
    if (selectedOption) {
      this.setState({
        field: selectedOption.value,
        propagateChange: true,
      });
    }
  }

  onDatesChange: OnDatesChange = ({ startDate, endDate }) => {
    const clearFilter = !this.state.focusedInput;

    if (!startDate && !endDate) {
      this.setState({
        from: undefined,
        to: undefined,
        propagateChange: true,
      });

      if (clearFilter) {
        this.setState({
          field: undefined,
        });
      }
    } else {
      this.setState({
        from: startDate,
        to: endDate,
        propagateChange: true,
      });
    }
  }

  onPresetSelect: OnDatesChange = ({ startDate, endDate }) => {
    this.onDatesChange({ startDate, endDate });

    this.applySelectedRange();
  }

  onFocusChange = (focusedInput: 'startDate' | 'endDate') => {
    this.setState({ focusedInput, propagateChange: true });
  }

  getOptions() {
    const { enabledOptions } = this.props;

    if (!enabledOptions) {
      return this.options;
    }

    return filter<Option>(this.options, (option: Option) => enabledOptions.includes(option.value));
  }

  applySelectedRange = () => {
    this.setState({
      focusedInput: undefined,
    });
  }

  canApplyRange = () => {
    const {
      focusedInput,
      field,
      from,
      to,
    } = this.state;

    return Boolean((
      focusedInput
      && field
      && (from || to)
    ));
  }

  resetState() {
    this.setState(this.getCleanState(false));
  }

  hasChanged(prevState: State) {
    return (
      (prevState.focusedInput && !this.state.focusedInput)
      || prevState.field !== this.state.field
    );
  }

  isComplete() {
    const {
      focusedInput,
      field,
      from,
      to,
    } = this.state;

    return (
      !focusedInput
      && field
      && (from || to)
    );
  }

  valueHasChanged(prevProps: Props) {
    const { value } = this.props;

    return !isEqual(prevProps.value, value);
  }

  wasCleared(prevState: State) {
    return prevState.field && !this.state.field;
  }

  renderPresets = () => (
    <DatePresets onDatesChange={this.onPresetSelect} />
  );

  render() {
    const { dateFormat } = this.props;
    const {
      focusedInput,
      field,
      from,
      to,
    } = this.state;

    const input = {
      onChange: this.onFilterFieldChange,
      value: field,
      name: 'dateFilterField',
    };

    return (
      <div>
        <SelectField
          input={input}
          options={this.getOptions()}
          onChange={this.onFilterFieldChange}
          labelKey="label"
          multi={false}
          searchable={false}
          clearable={false}
          noScroll
          small
        />
        <DatePicker
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={from}
          endDate={to}
          openDirection="up"
          showClearDates
          onApply={this.applySelectedRange}
          canApply={this.canApplyRange()}
          renderPresets={this.renderPresets}
          dateFormat={dateFormat}
          minimumNights={0}
        />
      </div>
    );
  }
}

export default localize<Props>(DateFilterComponent);
