// @flow

import {
  KEY_SUPER_OFFICE,
} from 'extensions';

const SuperOffice = {
  key: KEY_SUPER_OFFICE,
};

export default SuperOffice;
