import { forwardRef } from 'react';

// eslint-disable-next-line import/named
import { PopoverTrigger } from 'components/popover';
import Button from 'components/button';
import type { ButtonProps } from 'components/button';

export const ButtonWithForwardRef = forwardRef<ButtonProps, ButtonProps>(
  (props, ref) => (
    <Button {...props} buttonRef={ref} />
  ),
);

ButtonWithForwardRef.displayName = 'ButtonWithForwardRef';

export default function PopoverTriggerButton(props: ButtonProps) {
  return (
    <PopoverTrigger asChild>
      <ButtonWithForwardRef {...props} />
    </PopoverTrigger>
  );
}
