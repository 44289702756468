import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import { isContractPristine } from 'reducers/current-contract';
import { hasPendingStateParticipantApproved } from 'agreement/pending-state-flow';

import type { Layout } from 'components/document-layout-container/types';
import { ApproveButton, ButtonSize } from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons/approve-button/approve';
import { ApprovePendingActionsModal } from './approve-pending-actions-modal';
import { ApprovedState } from '../states';

type Props = {
  agreement: Oneflow.Agreement;
  layout?: Layout;
  size?: ButtonSize
  participant: Oneflow.Participant;
};

export const ApprovePendingActions = ({
  agreement,
  layout,
  size,
  participant,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isPristine = useSelector(isContractPristine);
  const hasApproved = hasPendingStateParticipantApproved(agreement, participant);

  const handleClick = () => setIsOpen(true);

  const getChildren = () => {
    if (hasApproved) {
      return (
        <ApprovedState layout={layout} />
      );
    }

    return (
      <ApproveButton
        onClick={handleClick}
        layout={layout}
        size={size}
        disabled={!isPristine}
      >
        <Message
          id="Approve"
          comment="Button label for approving the document"
        />
      </ApproveButton>
    );
  };

  return (
    <ApprovePendingActionsModal
      agreement={agreement}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      participant={participant}
    >
      {getChildren()}
    </ApprovePendingActionsModal>
  );
};
