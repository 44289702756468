import { connect } from 'react-redux';

import workspaceUsersReducer from 'reducers/entities/workspace-users';
import userWorkspacesReducer from 'reducers/entities/user-workspaces';
import workspacesReducer from 'reducers/entities/workspaces';
import { RemoveUserFromWorkspace } from './remove-user-from-workspace';

const DIRECT_ACCESS_QUERY = 'userWorkspaces/directAccess';

export const mapStateToProps = (state, { position }) => ({
  removeState: workspaceUsersReducer.getRemoveSelector(state, { id: position.id }),
});

export const mapDispatchToProps = (
  dispatch,
  {
    position,
    roleId,
    bindingId,
    workspace,
    pipeAction,
  },
) => ({
  removeUserFromWorkspace: () => {
    dispatch(workspaceUsersReducer.removeWorkspaceUser({
      id: bindingId,
      data: {
        id: bindingId,
        resourceType: 'collection',
        resourceId: workspace.id,
        actorType: 'position',
        actorId: position.id,
        roleId,
      },
      pipe: {
        onSuccess: () => {
          if (pipeAction === DIRECT_ACCESS_QUERY) {
            dispatch(userWorkspacesReducer.queryUserWorkspaces({
              name: DIRECT_ACCESS_QUERY,
              params: {
                positionId: position.id,
                queryTypes: ['position'],
              },
            }));
          } else {
            dispatch(workspaceUsersReducer.queryWorkspaceUsersReload({
              name: `workspace/${workspace.id}/relationships`,
            }));
            dispatch(workspacesReducer.fetchWorkspace({
              id: workspace.id,
              params: {
                accessStats: 1,
                includeAgreementStats: 1,
              },
            }));
          }
        },
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(workspaceUsersReducer.removeWorkspaceUserReset({
      id: bindingId,
    }));
  },
});

const connectedRemoveUserFromWorkspace = connect(
  mapStateToProps, mapDispatchToProps,
)(RemoveUserFromWorkspace);

export { connectedRemoveUserFromWorkspace as RemoveUserFromWorkspace };
