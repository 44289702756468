import clsx from 'clsx';
import type { ReactNode } from 'react';

import style from './aspect-ratio.module.scss';

export type Props = {
  ratio?: '16:9' | '9:16' | '3:4' | '4:3',
  className?: string,
  children?: ReactNode,
};

const AspectRatio = ({
  ratio = '16:9',
  className,
  children,
}: Props) => {
  const ratioClassName = style[`AR${ratio.replace(':', '-')}`];
  const canvasClassName = clsx(
    style.AspectRatioCanvas,
    className,
    ratioClassName,
  );

  return (
    <div className={clsx(style.AspectRatio, ratioClassName)}>
      <div className={canvasClassName}>
        {children}
      </div>
    </div>
  );
};

export default AspectRatio;
