import { connect } from 'react-redux';

import tagConnections from 'reducers/entities/tag-connections';

import Tag from './tag';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch, { tagConnection, amplitudeScope }) => ({
  removeTagConnection: () => {
    dispatch(tagConnections.removeTagConnection({
      id: tagConnection.id,
      data: { amplitudeScope },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Tag);
