// @flow
import React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

type Props = {
  tag: Tag,
  removeState: RemoveState,
  resetRemoveState: Function,
  removeTag: Function,
  children: Function,
};

export const RemoveTagConfirmation = ({
  tag,
  removeState,
  resetRemoveState,
  removeTag,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={removeTag}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <Message
        id="The tag {tag} will be removed from all contracts and contract templates where it is used. Are you sure you want to remove the tag?"
        values={{
          tag: <Badge label={tag.name} kind="name" />,
        }}
        comment="Warning message in modal when attempting to remove a account tag"
      />
    )}
    modalKey="delete tag modal"
  >
    {children}
  </RemoveConfirm>
);
