import mapDelayedEvents from 'components/lifecycle-calendar/map-delayed-events';
import isPastEvent from './is-past-event';

function getInternalReminders(
  events,
  currentUser,
  agreement = {},
) {
  return mapDelayedEvents(events).map((event) => {
    const currentAgreement = event?.sourceEvent?.agreement || agreement;
    const eventCreator = event?.sourceEvent?.customReminderData?.createdBy;
    const eventCreatedByCurrentUser = eventCreator === currentUser.id;

    return {
      ...event,
      sourceEvent: {
        ...event.sourceEvent,
        isEditable: eventCreatedByCurrentUser
        && !isPastEvent(event?.sourceEvent?.date)
        && Object.keys(currentAgreement).length > 1,
      },
    };
  });
}

export default getInternalReminders;
