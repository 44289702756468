import { createContext, useContext } from 'react';

export type FilterableContextValue = {
  value: (id: string, value: string, keywords?: string[]) => void
  // register item
  item: (id: string, groupId?: string) => () => void
  // register group
  group: (id: string) => () => void
  filter: () => boolean
  label: string
  disablePointerSelection: boolean
  // Ids
  listId: string
  labelId: string
  inputId: string
  // Refs
  listInnerRef: React.RefObject<HTMLDivElement | null>
}

export const FilterableContext = createContext<FilterableContextValue | null>(null);
export const useFilterableContext = () => {
  const context = useContext(FilterableContext);
  if (!context) {
    throw new Error('FilterableContext must be used within a Filterable component');
  }
  return context;
};
