import { Dispatch, SetStateAction, forwardRef } from 'react';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import * as Command from 'components/filterable';
import useFilterableState from 'components/filterable/hooks/use-filterable-state';
import ChevronRightIcon from 'components/icons/chevron-right';
import AiSparkleIcon from 'components/icons/ai-sparkle';
import ArrowUpIcon from 'components/icons/arrow-up';
import StopIcon from 'components/icons/stop';

import { useChatHistory } from '../../context/chat-history';
import usePagination from '../../hooks/use-pagination';
import style from './input.module.scss';

type Props = {
  loading: boolean,
  inputValue: string,
  placeholder: string,
  setInputValue: Dispatch<SetStateAction<string>>,
  onSubmit: (prompt: string) => void,
  pagination: ReturnType<typeof usePagination>,
  stop: () => void,
};

const Input = forwardRef<HTMLTextAreaElement, Props>(({
  loading,
  placeholder,
  inputValue,
  setInputValue,
  onSubmit,
  pagination,
  stop,
}, ref) => {
  const selectedItemValue = useFilterableState((v) => v.value);
  const chatHistory = useChatHistory();
  const aiMessagesHistory = chatHistory.history.filter((chat) => chat.role === 'assistant');

  const handleSubmit = () => {
    onSubmit(inputValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!selectedItemValue && e.key === 'Enter' && !e.shiftKey && inputValue.length > 0) {
      e.preventDefault();
      e.stopPropagation();
      handleSubmit();
    }
  };

  return (
    <div className={style.InputContainer}>
      <div className={style.IconContainer}>
        <AiSparkleIcon className={style.Icon} width="22px" />
      </div>
      {loading ? (
        <div className={style.Input} data-testid="loading">
          <Message
            id="AI is thinking..."
            comment="A message displayed to the user when the AI is generating a response."
          />
        </div>
      ) : (
        <TextareaAutosize
          className={style.Input}
          value={inputValue}
          onChange={(e) => setInputValue(e.currentTarget.value)}
          onFocus={(e) => {
            // set selection to the end of the input
            e.currentTarget.selectionStart = e.currentTarget.value.length;
            e.currentTarget.selectionEnd = e.currentTarget.value.length;
          }}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          minRows={1}
          ref={ref}
          autoFocus
        />
      )}
      <Command.Input className={style.HiddenInput} value={inputValue} />
      <div className={style.ButtonContainer}>
        {!loading && (
          <Button
            onClick={handleSubmit}
            customClass={clsx(style.SubmitButton)}
            disabled={inputValue.length <= 1}
            icon={<ArrowUpIcon />}
            data-testid="submit-button"
            kind="primary"
          />
        )}
        {loading && (
          <button
            className={style.ActionButton}
            onClick={stop}
            data-testid="stop-button"
          >
            <StopIcon height="18px" />
          </button>
        )}
        {aiMessagesHistory.length > 1 && !loading && (
          <>
            <div className={style.SeparatorContainer}>
              <div className={style.Separator} />
            </div>
            <div className={style.PaginationContainer}>
              <div className={style.Pagination}>
                <button
                  className={style.PaginationButton}
                  disabled={!pagination.canGoPrev}
                  onClick={pagination.handlePrevPage}
                  data-testid="pagination-prev"
                >
                  <ChevronRightIcon className={style.Rotate} height="8px" />
                </button>
                <span className={style.PaginationText}>
                  {`${pagination.currentPage + 1} `}
                  of
                  {` ${pagination.totalPages}`}
                </span>
                <button
                  className={style.PaginationButton}
                  disabled={!pagination.canGoNext}
                  onClick={pagination.handleNextPage}
                  data-testid="pagination-next"
                >
                  <ChevronRightIcon height="8px" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
