import * as zod from 'zod';

import {
  LINK_TYPE_EXTENSION,
  LINK_TYPE_EXTERNAL_URL,
  LINK_TYPE_RELATION,
  LINK_TYPE_REPLACEMENT,
  LINK_TYPE_SUBCONTRACT,
} from 'agreement/constants';

import Entity, { NumberIdSchema } from './entity';

import Party from './party';
import AgreementSignOrder from './agreement-sign-order';
import agreementData from './agreement-data';
import Message from './message';
import {
  mfaChannelValue, aclValue,
} from './common';
import workspace from './workspace';

import {
  dateSchema,
  timestampSchema,
  integerBoolean,
} from '../utils';

export const agreementValue = zod.object({
  amount: zod.number().nullable().optional(),
  currency: zod.string().nullable().optional(),
});

export const contractConfigSchema = zod.object({
  collaboration: zod.boolean().optional(),
  comments: zod.boolean().optional(),
  counterpartSelfUpdateAndRemove: zod.boolean().optional(),
  counterpartCommentsResolve: zod.boolean().optional(),
  dateFormat: zod.string().optional(),
  defaultCreatorRole: zod.number().optional(),
  defaultDeliveryChannel: zod.number().optional(),
  defaultMfaChannel: zod.string().nullable().optional(),
  defaultPartyType: zod.number().optional(),
  defaultSignMethod: zod.number().optional(),
  enabledDeliveryChannels: zod.array(zod.number()).nullable().optional(),
  enabledMfaChannels: zod.array(zod.string()).nullable().optional(),
  enabledSignMethods: zod.array(zod.number()).nullable().optional(),
  expireDateDays: zod.number().optional(),
  id: zod.string().optional(),
  inlineComments: zod.boolean().optional(),
  suggestions: zod.boolean().optional(),
  templateActive: zod.boolean().nullable().optional(),
  signLater: zod.boolean().nullable().optional(),
  autoPublishAfterDraftApproval: zod.boolean().nullable().optional(),
  signatureMode: zod.string().nullable().optional(),
  singleSign: zod.boolean().nullable().optional(),
  signRedirect: zod.string().nullable().optional(),
});

export const contractAclSchema = zod.object({
  'agreement:base_edit': aclValue.optional(),
  'agreement:agreement_value:update:amount': aclValue.optional(),
  'agreement:agreement_value:view': aclValue.optional(),
  'agreement:ai_review:view': aclValue.optional(),
  'agreement:box:reorder': aclValue.optional(),
  'agreement:box:update:locked': aclValue.optional(),
  'agreement:box:update:open': aclValue.optional(),
  'agreement:box:update:shared': aclValue.optional(),
  'agreement:box:section_rule_management': aclValue.optional(),
  'agreement:cancel': aclValue.optional(),
  'agreement:comment:create': aclValue.optional(),
  'agreement:create': aclValue.optional(),
  'agreement:download:pdf': aclValue.optional(),
  'agreement:layout:update': aclValue.optional(),
  'agreement:box:create': aclValue.optional(),
  'agreement:link:create': aclValue.optional(),
  'agreement:link:remove': aclValue.optional(),
  'agreement:move': aclValue.optional(),
  'agreement:participant:colleague:create': aclValue.optional(),
  'agreement:participant:colleague:remove': aclValue.optional(),
  'agreement:participant:mfa:create': aclValue.optional(),
  'agreement:participant:mfa:update': aclValue.optional(),
  'agreement:participant:other_party:create': aclValue.optional(),
  'agreement:participant:other_party:remove': aclValue.optional(),
  'agreement:participant:own_party:create': aclValue.optional(),
  'agreement:participant:own_party:remove': aclValue.optional(),
  'agreement:permanently_delete': aclValue.optional(),
  'agreement:publish': aclValue.optional(),
  'agreement:decline': aclValue.optional(),
  'agreement:remove': aclValue.optional(),
  'agreement:restore': aclValue.optional(),
  'agreement:tag:use': aclValue.optional(),
  'agreement:update:config:attachment_signatures': aclValue.optional(),
  'agreement:update:config:comments': aclValue.optional(),
  'agreement:update:config:expire_date_days': aclValue.optional(),
  'agreement:update:config:sign_redirect': aclValue.optional(),
  'agreement:update:counterpart_decline': aclValue.optional(),
  'agreement:update:expire_date': aclValue.optional(),
  'agreement:update:language': aclValue.optional(),
  'agreement:update:name': aclValue.optional(),
  'agreement:update:sign_later': aclValue.optional(),
  'agreement:update:sign_order': aclValue.optional(),
  'agreement:update:single_sign': aclValue.optional(),
  'agreement:update:template_group_id': aclValue.optional(),
  'agreement:update:visible': aclValue.optional(),
  'agreement:video:add': aclValue.optional(),
  'agreement:video:remove': aclValue.optional(),
  'agreement:video:view': aclValue.optional(),
  'agreement:view': aclValue.optional(),
  'agreement:ai_assist': aclValue.optional(),
  'agreement:internal_reminder:create': aclValue.optional(),
});

export const agreementValueSchema = zod.object({ amount: zod.number(), currency: zod.string() });

export const availableOptionsSchema = zod.object({
  deliveryChannels: zod.array(zod.number()).nullable().optional(),
  guestConversion: zod.boolean().nullable().optional(),
  hasDefaultAttachmentsBox: zod.number().nullable().optional(),
  hasDefaultPdfBox: zod.number().nullable().optional(),
  hasDefaultProductsBox: zod.number().nullable().optional(),
  mfaChannels: zod.array(mfaChannelValue).nullable().optional(),
  signLater: zod.boolean().nullable().optional(),
  signMethods: zod.array(zod.number()).nullable().optional(),
  pdfViewer: zod.boolean().nullable().optional(),
  disableLinkPlugin: zod.boolean().nullable().optional(),
  newDesign: zod.boolean().nullable().optional(),
  documentOverlayFields: zod.boolean().nullable().optional(),
});

export const boxOrderSchema = zod.array(NumberIdSchema).describe('boxOrder');
export const boxSchema = zod.object({
  id: zod.number(),
  agreement: zod.object({
    id: zod.number().nullable().optional(),
  }).nullable().optional(),
  config: zod.object({
    id: zod.string().nullable().optional(),
  }).nullable().optional(),
  content: zod.object({
    agreement: zod.object({
      duration: zod.string().nullable().optional(),
      endDate: zod.string().nullable().optional(),
      id: zod.number().nullable().optional(),
      initialDuration: zod.string().nullable().optional(),
      noticePeriod: zod.string().nullable().optional(),
      startDate: zod.string().nullable().optional(),
      type: zod.number().nullable().optional(),
    }).nullable().optional(),
    data: zod.array(agreementData.optional()).optional(),
  }).nullable().optional(),
  created: zod.string().nullable().optional(),
  createdTime: zod.string().nullable().optional(),
  createdTs: zod.number().nullable().optional(),
  updated: zod.string().nullable().optional(),
  updatedTime: zod.string().nullable().optional(),
  updatedTs: zod.number().nullable().optional(),
  store: zod.object({
    id: zod.string().nullable().optional(),
  }).nullable().optional(),
  type: zod.number().nullable().optional(),
});

const singOrderSchema = zod.object({
  entries: zod.array(
    zod.object({ participantId: zod.number(), block: zod.number() }),
  ),
  id: zod.string(),
});

export const InjectedTemplate = NumberIdSchema.describe('injectedTemplate');

export const contractCreatedBy = zod.object({
  id: zod.number(), name: zod.string().nullable().optional(),
});

export const contractUpdatedBy = zod.object({
  actorName: zod.string().nullable().optional(),
  actorId: zod.number().nullable().optional(),
  updatedTime: zod.string().nullable().optional(),
});

export const contractOwner = NumberIdSchema.describe('contractOwner');
export const agreementAccount = NumberIdSchema.describe('agreementAccount');

const CreatedBy = zod.object({
  id: zod.number().nullable().optional(),
  name: zod.string().nullable().optional(),
});

const folderSchema = zod.object(
  {
    created: zod.string().nullable().optional(),
    createdTime: zod.string().nullable().optional(),
    createdTs: zod.number().nullable().optional(),
    updated: dateSchema.nullable().optional(),
    updatedTime: dateSchema.nullable().optional(),
    updatedTs: timestampSchema.nullable().optional(),
    id: zod.number().nullable().optional(),
    name: zod.string().nullable().optional(),
    parentId: zod.number().nullable().optional(),
    parent: zod.object({
      id: zod.number().nullable().optional(),
      path: zod.string().nullable().optional(),
    }).nullable().optional(),
    parentPath: zod.string().nullable().optional(),
    resolvedPath: zod.array(zod.object({
      created: dateSchema.nullable().optional(),
      createdTime: dateSchema.nullable().optional(),
      createdTs: timestampSchema.nullable().optional(),
      updated: dateSchema.nullable().optional(),
      updatedTime: dateSchema.nullable().optional(),
      updatedTs: timestampSchema.nullable().optional(),
      id: zod.number().nullable().optional(),
      name: zod.string().nullable().optional(),
      parentId: zod.number().nullable().optional(),
      parent: zod.object({
        id: zod.number().nullable().optional(),
        path: zod.string().nullable().optional(),
      }).nullable().optional(),
      parentPath: zod.string().nullable().optional(),
      workspaceId: zod.number().nullable().optional(),
      workspace: zod.object({
        id: zod.number().nullable().optional(),
      }).nullable().optional(),
    })).nullable().optional(),
    workspaceId: zod.number().nullable().optional(),
    workspace: zod.object({
      id: zod.number().nullable().optional(),
    }).nullable().optional(),
  },
);

const Contract = Entity.extend({
  account: agreementAccount.nullable().optional(),
  agreementValue: agreementValue.nullable().optional(),
  acl: contractAclSchema.nullable().optional(),
  availableOptions: availableOptionsSchema.nullable().optional(),
  boxes: zod.array(boxSchema).nullable().optional(),
  boxOrder: boxOrderSchema.nullable().optional(),
  data: zod.array(agreementData.optional()).optional(),
  isInternational: zod.boolean().nullable().optional(),
  state: zod.number().nullable().optional(),
  name: zod.string().nullable().optional(),
  cancelTime: dateSchema.nullable().optional(),
  cancelTimestamp: dateSchema.nullable().optional(),
  cancelTimestampTs: timestampSchema.nullable().optional(),
  checksum: zod.string().nullable().optional(),
  config: contractConfigSchema.nullable().optional(),
  collection: workspace.nullable().optional(),
  created: zod.string().nullable().optional(),
  expireDate: dateSchema.nullable().optional(),
  isShared: zod.boolean().nullable().optional(),
  lifecycle: zod.number().nullable().optional(),
  periodEndTime: dateSchema.nullable().optional(),
  publishTime: dateSchema.nullable().optional(),
  signPlatform: zod.string().nullable().optional(),
  startTime: dateSchema.nullable().optional(),
  stateTime: dateSchema.nullable().optional(),
  singOrder: singOrderSchema.nullable().optional(),
  type: zod.number().nullable().optional(),
  updated: dateSchema.nullable().optional(),
  updatedBy: contractUpdatedBy.nullable().optional(),
  counterpartDecline: zod.number().nullable().optional(),
  createdTs: timestampSchema.nullable().optional(),
  duration: zod.string().nullable().optional(),
  endDate: dateSchema.nullable().optional(),
  expirationCount: zod.number().nullable().optional(),
  folder: folderSchema.nullable().optional(),
  hasSignatures: zod.boolean().nullable().optional(),
  import: zod.number().nullable().optional(),
  initialDuration: zod.string().nullable().optional(),
  language: zod.string().nullable().optional(),
  lifecycleTime: dateSchema.nullable().optional(),
  lifecycleTimestamp: dateSchema.nullable().optional(),
  lifecycleTimestampTs: timestampSchema.nullable().optional(),
  locked: zod.optional(integerBoolean),
  logoUrl: zod.string().url().nullable().optional(),
  noticePeriod: zod.string().nullable().optional(),
  owner: zod.object({
    id: zod.number(),
  }).nullable().optional(),
  parties: zod.array(Party).nullable().optional(),
  periodCount: zod.number().nullable().optional(), // default is 0
  periodEndTimestamp: dateSchema.nullable().optional(),
  periodEndTimestampTs: timestampSchema.nullable().optional(),
  periodNoticePeriodStartTime: dateSchema.nullable().optional(),
  periodStartTime: dateSchema.nullable().optional(),
  periodStartTimestamp: dateSchema.nullable().optional(),
  periodStartTimestampTs: timestampSchema.nullable().optional(),
  // refine api used in integerBoolean and hence not possible to use nullish
  private: zod.optional(integerBoolean),
  publishTimestamp: dateSchema.nullable().optional(),
  publishTimestampTs: timestampSchema.nullable().optional(),
  // refine api used in integerBoolean and hence not possible to use nullish
  removed: zod.optional(integerBoolean),
  revision: zod.number().nullable().optional(),
  review: zod.object({
    breaches: zod.number().nullable().optional(),
    lastReviewDate: zod.string().nullable().optional(),
    highlightIndexForUrl: zod.number().nullable().optional(),
    warnings: zod.number().nullable().optional(),
  }).optional(),
  signingPeriodExpiryTime: dateSchema.nullable().optional(),
  startDate: dateSchema.nullable().optional(),
  startTimestamp: dateSchema.nullable().optional(),
  startTimestampTs: timestampSchema.nullable().optional(),
  stateTimestamp: dateSchema.nullable().optional(),
  stateTimestampTs: timestampSchema.nullable().optional(),
  templateGroup: zod.object({
    id: zod.number().optional(),
    name: zod.string().optional(),
  }).nullish(),
  terminateTime: dateSchema.nullable().optional(),
  terminateTimestamp: dateSchema.nullable().optional(),
  terminateTimestampTs: timestampSchema.nullable().optional(),
  updatedTs: timestampSchema.nullable().optional(),
  welcomeVideo: zod.number().nullable().optional(),
  signOrder: AgreementSignOrder.nullable().optional(),
  messages: zod.array(Message).nullable().optional(),
  createdBy: CreatedBy.nullable().optional(),
  createdTime: dateSchema.nullable().optional(),
  removedBy: CreatedBy.nullable().optional(),
  removedTime: dateSchema.nullable().optional(),
  // TODO: figure out how to have this as it is a circular dependency
  // links: zod.array(AgreementLink).nullable().optional(),
});

const linkAgreement = NumberIdSchema.describe('linkAgreement');

export const agreementLink = Entity.extend({
  agreement: linkAgreement.nullable().optional(),
  linkType: zod.enum([
    LINK_TYPE_EXTENSION,
    LINK_TYPE_EXTERNAL_URL,
    LINK_TYPE_RELATION,
    LINK_TYPE_REPLACEMENT,
    LINK_TYPE_SUBCONTRACT,
  ]).nullable().optional(),
  targetAgreement: Contract.nullable().optional(),
  targetExternalUrl: zod.string().nullable().optional(),
  targetExternalUrlTitle: zod.string().nullable().optional(),
  updated: zod.string().nullable().optional(),
  updatedTime: zod.string().nullable().optional(),
  updatedTs: timestampSchema.nullable().optional(),
});

export default Contract;
