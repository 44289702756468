import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import style from './table.module.scss';

const TableHeader = ({ actions, children, className }) => {
  const tableHeaderClasses = clsx(style.TableHeader, className, {
    [style.Actions]: actions,
  });

  return (
    <th className={tableHeaderClasses}>
      {children}
    </th>
  );
};

TableHeader.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.bool,
  className: PropTypes.string,
};

TableHeader.defaultProps = {
  children: null,
  actions: false,
  className: undefined,
};

export default TableHeader;
