import type { ChangeEvent } from 'react';
import isFunction from 'lodash/isFunction';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import MagnifyingGlass from 'components/icons/magnifying-glass';
import { ControlledTextField } from 'components/text-field';
import style from './search.module.scss';

export type SearchProps = {
  onChange: (value: string) => void,
  loading?: boolean,
  value?: string,
  placeholder?: string,
  lengthLimit?: number,
  autoFocus?: boolean,
  onKeyDown?: (event: KeyboardEvent) => void,
  inputRef?: any,
  disabled?: boolean,
};

type Props = SearchProps & {
  message: MessageTranslator,
};

const DEFAULT_SEARCH_MAX_LENGTH = 130;

export const SearchComponent = ({
  value,
  onChange,
  loading,
  message,
  placeholder,
  lengthLimit = DEFAULT_SEARCH_MAX_LENGTH,
  autoFocus,
  onKeyDown,
  inputRef,
  disabled,
}: Props) => {
  const input = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      if (!loading) {
        onChange(event.target.value);
      }
    },
    name: 'name',
    value,
    onKeyDown: (event: Event) => {
      if (loading) {
        event.preventDefault();
      }
      if (isFunction(onKeyDown)) {
        onKeyDown(event);
      }
    },
  };

  const searchPlaceholder = placeholder || message({
    id: 'Search',
    comment: 'Default placeholder text used in search fields.',
  });

  return (
    <ControlledTextField
      autoComplete="off"
      autoFocus={autoFocus}
      clearable
      ellipsis
      icon={<MagnifyingGlass responsive className={style.SearchFieldIcon} />}
      input={input}
      inputRef={inputRef}
      lengthLimit={lengthLimit}
      loading={loading}
      name="search"
      placeholder={searchPlaceholder}
      disabled={disabled}
      responsive
    />
  );
};

SearchComponent.defaultProps = {
  value: undefined,
  lengthLimit: undefined,
  loading: false,
  placeholder: '',
  autoFocus: undefined,
  onKeyDown: undefined,
  inputRef: undefined,
  disabled: undefined,
};

export default localize<Props>(SearchComponent);
