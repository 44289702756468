import { components } from 'react-select';
import clsx from 'clsx';

import NewCheck from 'components/icons/new-check';
import style from './colleague-option.module.scss';

export const getSelected = (isSelected) => {
  if (!isSelected) {
    return null;
  }

  return <NewCheck height="12px" />;
};

const ColleagueOption = ({ isDisabled, ...restProps }: any) => {
  const { email, fullname, title } = restProps.data;
  const getColleagueLabel = () => (title ? `${fullname} - ${title}` : fullname);

  return (
    <components.Option
      className={clsx(
        { [style.DisabledOption]: isDisabled },
      )}
      {...restProps}
    >
      <div>
        <div className={style.OptionLabel}>{getColleagueLabel()}</div>
        <div className={style.OptionEmail}>{email}</div>
      </div>
      <div className={style.OptionIcon}>{getSelected(isDisabled)}</div>
    </components.Option>
  );
};

export default ColleagueOption;
