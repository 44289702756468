import type { ReactNode } from 'react';
import { isEqual } from 'lodash';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Tooltip from 'components/tooltip';
import Button from 'components/button';
import SelectField from 'components/select-field';
import SortAscending from 'components/icons/sort-ascending';
import SortDescending from 'components/icons/sort-descending';

import style from './agreement-sort.module.scss';

const DESCENDING = '-';

type Option = {
  label: ReactNode;
  value: string;
  inverted: boolean;
};

type Props = {
  onChange: (value: string[]) => void;
  value: string[] | null;
  defaultValue?: string[];
  sortables: string[];
  message: MessageTranslator;
};

type SortType =
  | 'score'
  | 'created'
  | 'publish_time'
  | 'expire_date'
  | 'close_time'
  | 'start_time'
  | 'upcoming_time'
  | 'agreement_value'
  | 'name'
  | 'removed_time'

export const AgreementSort = ({
  onChange,
  value,
  defaultValue,
  sortables,
  message,
}: Props) => {
  const sortingOptions: Record<SortType, Option> = {
    score: {
      value: 'score',
      label: message({
        id: 'Relevance',
        comment: 'Dropdown option label. "Sort documents by relevance"',
      }),
      inverted: false,
    },
    created: {
      value: 'created',
      label: message({
        id: 'Creation date',
        comment: 'Dropdown option label. "Sort documents by when they were created"',
      }),
      inverted: false,
    },
    publish_time: {
      value: 'publish_time',
      label: message({
        id: 'Sent date',
        comment: 'Dropdown option label. "Sort documents by when they were sent"',
      }),
      inverted: false,
    },
    expire_date: {
      value: 'expire_date',
      label: message({
        id: 'Signing period expiry date',
        comment: 'Dropdown option label. "Sort documents by when they will become overdue."',
      }),
      inverted: true,
    },
    close_time: {
      value: 'close_time',
      label: message({
        id: 'Signed/Declined date',
        comment: 'Dropdown option label. "Sort documents by when they become closed (signed or declined)"',
      }),
      inverted: false,
    },
    start_time: {
      value: 'start_time',
      label: message({
        id: 'Start date',
        comment: 'Dropdown option label. "Sort signed documents by when they will start, become active."',
      }),
      inverted: true,
    },
    upcoming_time: {
      value: 'upcoming_time',
      label: message({
        id: 'Upcoming events',
        comment: 'Dropdown option label. "Sort documents by when they have their next state or life cycle event."',
      }),
      inverted: true,
    },
    agreement_value: {
      value: 'agreement_value',
      label: message({
        id: 'Value',
        comment: 'Dropdown option label. "Sort documents by their document value."',
      }),
      inverted: false,
    },
    name: {
      value: 'name',
      label: message({
        id: 'Name',
        comment: 'Dropdown option label. "Sort documents by their document name."',
      }),
      inverted: true,
    },
    removed_time: {
      value: 'removed_time',
      label: message({
        id: 'Trashed date',
        comment: 'Dropdown option label. "Sort documents by their trashed date."',
      }),
      inverted: false,
    },
  };

  const getField = (): string | null => {
    if (value) {
      const [field] = value;
      return field;
    }

    if (defaultValue) {
      const [field] = defaultValue;
      return field;
    }

    return null;
  };

  const isDescending = () => {
    const field = getField();
    return field && field.startsWith(DESCENDING);
  };

  const getOption = (): string | null => {
    const field = getField();

    if (!field) {
      return null;
    }

    return isDescending() ? field.replace(DESCENDING, '') : field;
  };

  const selectOption = (selectedValue: string) => {
    onChange([selectedValue]);
  };

  const toggleOrder = () => {
    const field = getField();

    if (!field) {
      return;
    }

    if (isDescending()) {
      selectOption(field.replace(DESCENDING, ''));
    } else {
      selectOption(`${DESCENDING}${field}`);
    }
  };

  const getSortingOptions = () => {
    const options = sortables.map((option) => sortingOptions[option]);

    if (isEqual(defaultValue, ['-score'])) {
      return [sortingOptions.score, ...options];
    }

    return options;
  };

  const isSortAscending = () => {
    const option = getOption();

    if (!option) {
      return true;
    }

    const descending = isDescending();
    const { inverted } = sortingOptions[option];

    return (!descending && !inverted) || (descending && inverted);
  };

  const renderIcon = () => (isSortAscending() ? <SortAscending /> : <SortDescending />);

  const onInputChange = (option: Option | null) => {
    if (!option) return;

    const optValue = option.value;
    const { inverted } = sortingOptions[optValue];

    if (inverted) {
      selectOption(optValue);
    } else {
      selectOption(`${DESCENDING}${optValue}`);
    }
  };

  const input = {
    onChange: onInputChange,
    name: 'sort',
    value: getOption(),
  };

  return (
    <div className={style.AgreementSort} data-testid="sorting">
      <span className={style.SortByText}>
        <Message
          id="Sort"
          comment="Text to understand the agreement sorting option list"
        />
      </span>
      <SelectField
        input={input}
        options={getSortingOptions()}
        labelKey="label"
        multi={false}
        searchable={false}
        clearable={false}
        ariaLabel={message({
          id: 'Sort by',
          comment: 'Description for sorting option list',
        })}
        noScroll
      />
      <Tooltip
        message={(
          <Message
            id="Sort order"
            comment="Tooltip text for the sort direction button"
          />
        )}
        side="top"
        theme="oneflow"
      >
        <div>
          <Button
            outline
            customClass={style.Toggle}
            onClick={toggleOrder}
            aria-label={message({
              id: 'Sort documents',
              comment: 'Description for the sort button',
            })}
          >
            {renderIcon()}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

export default localize(AgreementSort);
