import flowRight from 'lodash/flowRight';
import { createEditor as createSlateEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import withImages from './editor-plugins/with-images';
import withIndent from './editor-plugins/with-indent';
import withLinks from './editor-plugins/with-links';
import withPageBreak from './editor-plugins/with-page-break';
import withHorizontalLine from './editor-plugins/with-horizontal-line';
import withTable from './editor-plugins/with-table';

export const makeWithPlugins = (plugins) => flowRight(
  ...plugins,
  withImages,
  withIndent,
  withLinks,
  withPageBreak,
  withHorizontalLine,
  withTable,
  withReact,
  withHistory,
);

export const createEditor = (plugins) => (
  makeWithPlugins(plugins)(createSlateEditor())
);

let temporaryEditorInstance = null;

export const getTemporaryEditorInstance = () => {
  if (!temporaryEditorInstance) {
    const withAnnotationsAndSuggestions = (editor) => {
      const { isInline } = editor;

      // eslint-disable-next-line no-param-reassign
      editor.isInline = (element) => (
        element.type === 'annotation' || element.type === 'suggestion' || isInline(element)
      );

      return editor;
    };

    temporaryEditorInstance = createEditor([withAnnotationsAndSuggestions]);
  }

  return temporaryEditorInstance;
};
