/* eslint-disable import/prefer-default-export */
import { normalize, schema } from 'normalizr';
import omit from 'lodash/omit';
import { decamelizeKeys } from 'humps';

import {
  extractResponseBodyAsJSON,
  post,
  remove,
  getMultiple,
} from 'oneflow-client/core';

const userAccountBindingsSchema = new schema.Entity('userAccountBindings');

const normalizeUserAccounts = (bindingsData) => {
  if (bindingsData.collection) {
    return ({
      ...normalize(bindingsData.collection, [userAccountBindingsSchema]),
      count: bindingsData.count,
    });
  }
  return ({
    ...normalize(bindingsData, userAccountBindingsSchema),
    count: bindingsData.count,
  });
};

export const getUserAccountBindings = ({
  pagination,
  params,
  sort,
}) => (
  getMultiple({
    url: `/positions/${params.id}/accounts/bindings/`,
    params: decamelizeKeys(omit(params, 'id'), { separator: '_' }),
    pagination,
    sort,
  }).then(extractResponseBodyAsJSON)
    .then(normalizeUserAccounts)
);

export const getUsersAccountBindings = ({
  pagination,
  params,
}) => getMultiple({
  url: '/accounts/bindings/',
  params: decamelizeKeys(omit(params, 'id'), { separator: '_' }),
  pagination,
}).then(extractResponseBodyAsJSON)
  .then(normalizeUserAccounts);

export const grantUserAccountAccess = ({
  positionId,
  roleId,
}) => post({
  url: `/accounts/positions/${positionId}/bindings/`,
  body: {
    role_id: roleId,
  },
})
  .then(extractResponseBodyAsJSON)
  .then(normalizeUserAccounts);

export const removeUserAccountAccess = ({
  positionId,
  roleId,
}) => remove({
  url: `/accounts/positions/${positionId}/bindings/`,
  body: {
    role_id: roleId,
  },
})
  .then(extractResponseBodyAsJSON)
  .then(normalizeUserAccounts);
