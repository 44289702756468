import { useEffect } from 'react';
import type { ComponentProps } from 'react';
import { PopoverContent, PopoverPortal } from '@radix-ui/react-popover';

import { useMenuContext } from './root-context';
import { useSubContext } from './sub-context';
import { useRootMenuStateContext } from './root';

type SubContentProps = ComponentProps<typeof PopoverContent>;

const SubContent = ({ children, ...props }: SubContentProps) => {
  const { isSubmenuOpen, handleKeyDown } = useSubContext();
  const { setOnKeyDown } = useMenuContext();
  const { listRef } = useRootMenuStateContext();

  const preventDefault = (e: Event) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (isSubmenuOpen) {
      setOnKeyDown(handleKeyDown);
    }

    return () => {
      setOnKeyDown(null);
    };
  }, [handleKeyDown, isSubmenuOpen, setOnKeyDown]);

  return (
    <PopoverPortal container={listRef.current}>
      <PopoverContent
        {...props}
        side="right"
        align="center"
        // we don't want the popover to trap focus since we are navingating the menu with
        // the keyboard while the focus is still on the input
        onOpenAutoFocus={preventDefault}
        onCloseAutoFocus={preventDefault}
        collisionPadding={8}
      >
        {children}
      </PopoverContent>
    </PopoverPortal>
  );
};

export default SubContent;
