import { put, call } from 'redux-saga/effects';

import generateEntitySagas from 'normalized-redux/sagas';
import client from 'oneflow-client';
import { getWorkspaceGroups, assignGroupWorkspaces } from 'oneflow-client/workspace-groups';

import apiWrapper from 'sagas/api-wrapper';

import workspaceGroupsReducer from 'reducers/entities/workspace-groups';
import groupWorkspacesReducer from 'reducers/entities/group-workspaces';

import roles from 'reducers/entities/roles';
import bindings from 'reducers/entities/bindings';

export function* mapper({ data }) {
  yield put(workspaceGroupsReducer.setWorkspaceGroups(data.entities.workspaceGroups));
}

export function* createMapper({ data }) {
  yield put(roles.setRoles(data.entities.roles));
  yield put(bindings.setBindings(data.entities.bindings));
}

export function* clearMapper({ action }) {
  yield put(groupWorkspacesReducer.clearGroupWorkspace({ id: action.id }));
  yield put(workspaceGroupsReducer.clearWorkspaceGroup({ id: action.id }));
}

export function* updateMapper({ action }) {
  yield put(groupWorkspacesReducer.clearGroupWorkspace({ id: action.data.removeData.id }));
  yield put(workspaceGroupsReducer.clearWorkspaceGroup({ id: action.data.removeData.id }));
}

export function* updateRequest({ createData, removeData }) {
  yield call(client.removeBindingByResource, removeData);
  return yield call(client.createBindingByResource, createData);
}

export function* assignGroupWorkspacesMapper({ data }) {
  yield put(bindings.setBindings(data.entities.bindings));
}

const mappers = {
  query: {
    mapper,
    request: getWorkspaceGroups,
  },
  create: {
    mapper: createMapper,
    request: client.createBindingByResource,
  },
  update: {
    mapper: updateMapper,
    request: updateRequest,
  },
  remove: {
    mapper: clearMapper,
    request: client.removeBindingByResource,
  },
  rpcs: {
    assignGroupWorkspaces: {
      name: 'assignGroupWorkspaces',
      mapper: assignGroupWorkspacesMapper,
      request: assignGroupWorkspaces,
    },
  },
};

const workspaceGroupsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: workspaceGroupsReducer,
  mappers,
});

export default workspaceGroupsSagas;
