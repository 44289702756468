import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { cloneDeep } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import type { DropzoneProps } from 'react-dropzone';
import type { ReactSortableProps } from 'react-sortablejs';

import { Message } from '@oneflowab/pomes';
import Button from 'components/button';

import { updateBoxConfigAction } from 'reducers/current-contract';
import { useFileUploadBoxProps } from 'contexts/file-upload-box-props';
import { useAsyncAssetProps } from 'contexts/async-asset-props';
import { AttachmentBox } from 'data-validators/entity-schemas/document-box/attachment-box';

import BoxFileUpload from 'components/contract-boxes/box-file-upload';
import { EmptyUploadBox } from 'components/contract-boxes/empty-upload-box';
import { LoadingStatusBox } from 'components/contract-boxes/loading-status-box';
import AttachmentFile from 'components/contract-boxes/attachment-box/attachment-file';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import { MAX_ATTACHMENT_SIZE, uploadingFileMessage } from 'components/contract-boxes/constants';
import { getAttachmentOrderObject } from 'components/contract-boxes/attachment-box/attachment-box-helpers';
import { useBoxItemIsVisible } from 'components/box-list/box-item/box-item-is-visible-context';

import Attachment from 'components/icons/attachment';
import CircularSpinner from 'components/icons/circular-spinner';
import SmallAdd from 'components/icons/small-add';

import style from './attachment-container.module.scss';
import { useAttachmentBoxValidationContext } from '../attachment-box-validation-provider';

type FileUploadBoxProps = {
  onError: NonNullable<DropzoneProps['onError']>,
  acceptedExtensions: Record<string, string[]>,
  uploadingError: boolean | undefined,
  maxSize: NonNullable<DropzoneProps['maxSize']>,
  multiple: boolean,
  maxFiles: NonNullable<DropzoneProps['maxFiles']>,
  maxSizePerContractValidator: NonNullable<DropzoneProps['validator']>,
  onDrop: NonNullable<DropzoneProps['onDrop']>
  isAllowedToAddBoxData: boolean,
};

type Props = {
  fileUploadProps: FileUploadBoxProps,
};

const AttachmentContainer = ({
  fileUploadProps,
}: Props) => {
  const {
    box,
    loading,
    attachmentData,
    isAllowedToReorder,
    requiredForSignatures,
    isDragOver,
    onDragEnter,
    onDragLeave,
    uploadedFileStatus,
  } = useFileUploadBoxProps();
  const { failedAsyncAssetData } = useAsyncAssetProps();
  const dispatch = useDispatch();
  const boxId = getId(box);
  const hasAttachment = !isEmpty(attachmentData);
  const [activeAttachmentId, setActiveAttachmentId] = useState(null);
  const { isAllowedToAddBoxData } = fileUploadProps;
  const disabled = !isAllowedToAddBoxData || loading;
  const { touched, setTouched } = useAttachmentBoxValidationContext();
  const { isVisible } = useBoxItemIsVisible();
  const invalid = requiredForSignatures && touched && isVisible;

  const onUpdateAttachmentsOrder = useCallback((
    updatedOrder: ReturnType<typeof getAttachmentOrderObject>[],
  ) => {
    dispatch(
      updateBoxConfigAction(boxId, { order: updatedOrder } as Partial<AttachmentBox['config']>),
    );
  }, [boxId, dispatch]);

  const setList = useCallback<ReactSortableProps<AttachmentBox['content']['data'][number]>['setList']>((list) => {
    const previousOrder = attachmentData.map(getId);
    const newOrder = list.map(getId);

    if (!isEqual(previousOrder, newOrder)) {
      onUpdateAttachmentsOrder(list.map(getAttachmentOrderObject));
    }
  }, [attachmentData, onUpdateAttachmentsOrder]);

  const hasFailedAttachment = useMemo(() => attachmentData.some((attachment) => {
    const assetId = attachment.value?.assetId;
    const status = uploadedFileStatus[assetId];

    return (
      status === 'failed'
      || attachment.value?.status === 'failed'
      || (failedAsyncAssetData
        && failedAsyncAssetData.assetId === assetId
        && failedAsyncAssetData.status === 'failed'));
  }), [
    attachmentData,
    failedAsyncAssetData,
    uploadedFileStatus,
  ]);

  if (loading && !hasAttachment) {
    return (
      <LoadingStatusBox
        message={uploadingFileMessage}
      />
    );
  }

  const addButtonClasses = clsx(style.AddButton, {
    [style.SortableDrag]: isDragOver,
  });

  if (hasAttachment) {
    const AttachmentWrapperClasses = clsx({
      [style.FailedAttachmentsWrapper]: hasFailedAttachment,
    });

    return (
      <div className={AttachmentWrapperClasses}>
        <ReactSortable
          list={cloneDeep(attachmentData)}
          setList={setList}
          handle=".attachment-drag-handler"
          easing="cubic-bezier(1, 0, 0, 1)"
          animation={250}
          forceFallback
          fallbackTolerance={20}
          dragClass={style.SortableDrag}
          dataIdAttr="data-id"
          touchStartThreshold={100}
          sort={isAllowedToReorder}
        >
          {attachmentData?.map((attachment) => (
            <AttachmentFile
              activeAttachmentId={activeAttachmentId}
              setActiveAttachmentId={setActiveAttachmentId}
              attachmentData={attachment}
              key={getId(attachment)}
            />
          ))}
        </ReactSortable>
        {
          isAllowedToAddBoxData && (
            <div className={addButtonClasses}>
              <BoxFileUpload
                {...fileUploadProps}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                disabled={disabled}
              >
                <Button
                  icon={loading ? CircularSpinner : <SmallAdd height="8px" />}
                  disabled={disabled}
                >
                  {loading ? (
                    <Message
                      id="Uploading"
                      comment="Text indicating file is currently being uploaded"
                    />
                  ) : (
                    <Message
                      id="Add more"
                      comment="Text button shows that it is possible to upload more attachments"
                    />
                  )}
                </Button>
              </BoxFileUpload>
            </div>
          )
        }
      </div>
    );
  }

  return (
    <div className={style.EmptyBoxWrapper}>
      <BoxFileUpload
        {...fileUploadProps}
        disabled={!isAllowedToAddBoxData}
        ariaLabel="attachment-dropzone"
        required={requiredForSignatures}
        invalid={invalid}
        onFileDialogCancel={() => setTouched(true)}
      >
        <EmptyUploadBox
          required={requiredForSignatures}
          icon={isAllowedToAddBoxData ? Attachment : <Attachment />}
          title={(
            <Message
              id="No Attachment"
              comment="This text shows there is no attachment file here"
            />
          )}
          editable={isAllowedToAddBoxData}
          maxFileSizeBytes={MAX_ATTACHMENT_SIZE}
        />
      </BoxFileUpload>
      {invalid && (
        <div className={style.InvalidMessage}>
          <Message
            id="Attachment is required"
            comment="This text shows that attachment is required"
          />
        </div>
      )}
    </div>
  );
};

export default AttachmentContainer;
