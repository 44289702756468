import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-media';
import queryString from 'query-string';
import { get } from 'lodash';
import { Helmet } from 'react-helmet-async';

import { AnnotationPropsProvider } from 'contexts/annotation-props';

import { isContractPristine } from 'reducers/current-contract';
import { isImportInProgress } from 'agreement';
import { getGuestToken, getAgreementMyParticipant } from 'agreement/selectors';
import useDetachedModalRenderer from 'hooks/use-detached-modal-renderer';
import useAgreement from 'hooks/use-agreement';
import useVideoPreview from 'hooks/use-video-preview';
import { isSigned, isSigningInProgress } from 'agreement/states';

import sessionStorage from 'utils/session-storage';
import { GUEST_CONVERSION_QUERY } from 'agreement/constants';

import { CollapsedDocumentLayoutProvider } from 'components/document-layout-container/collapsed-document-layout/context';
import { DocumentLayoutProvider } from 'components/document-layout-container/document-layout-context';
import { ExpandableSidebarProvider } from 'components/document-layout-container/expanded-document-layout/context';
import ExpandedDocumentLayout from 'components/document-layout-container/expanded-document-layout';
import CollapsedDocumentLayout from 'components/document-layout-container/collapsed-document-layout';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import OnBeforeUnload from 'components/on-before-unload/on-before-unload';
import VideoPreview from 'components/agreement-video/video-preview';
import { getVideoSourceUrl, getVideoThumbnailUrl } from 'components/agreement-video/agreement-video';
// eslint-disable-next-line import/named
import { SigningCompleted } from 'components/modals/signing-completed';
import GuestConversion from 'components/modals/guest-conversion';

import { DOCUMENT_COLLAPSED_LAYOUT_SIZE } from './helpers';
import './document-layout-variables.scss';

const renderExpandedDocumentLayout = (agreementId: Oneflow.Document['id']) => (
  <ExpandableSidebarProvider>
    <ExpandedDocumentLayout agreementId={agreementId} />
  </ExpandableSidebarProvider>
);

const renderCollapsedDocumentLayout = (agreementId: Oneflow.Document['id']) => (
  <CollapsedDocumentLayoutProvider>
    <CollapsedDocumentLayout agreementId={agreementId} />
  </CollapsedDocumentLayoutProvider>
);

type RouteParams = {
  id: string;
}

const DocumentLayoutContainer = () => {
  const { id } = useParams<RouteParams>();
  const agreementId = Number.parseInt(id, 10);
  const agreement = useAgreement(agreementId);

  const isPristine = useSelector(isContractPristine);
  const showModal = (!isPristine || isImportInProgress(agreement)) && sessionStorage.getItem('session_timedout') !== 'true';
  const guestToken = useSelector(getGuestToken);
  const isGuest = Boolean(guestToken);
  const parsedSearch = queryString.parse(window.location.search);
  const modalQuery = get(parsedSearch, 'm');
  const myParticipant = getAgreementMyParticipant(agreement);
  const isSignedOrSigningInProgress = isSigned(agreement) || isSigningInProgress(agreement);

  // Chose 320px because issues with the collapsed layout starts.
  const deviceSmallHeight = useMedia({ query: '(max-height: 320px)' });
  const deviceSmallWidth = useMedia({ query: `(max-width: ${DOCUMENT_COLLAPSED_LAYOUT_SIZE}px)` });

  // if we have m = gc in the url, we should open the guest conversion modal
  const shouldOpenGuestConversionModal = isGuest && modalQuery === GUEST_CONVERSION_QUERY;

  const hasStateUpdatedAfterLastVisit = (
    agreement?.stateTimestampTs
    && myParticipant?.lastVisitTs
    && agreement.stateTimestampTs > myParticipant.lastVisitTs
  );

  const hasAgreementBeenFullySignedAfterGuestLastVisit = hasStateUpdatedAfterLastVisit
    && isSignedOrSigningInProgress && isGuest;

  const renderSigningCompleteModal = useCallback(() => (
    <SigningCompleted agreementId={agreementId} isOpen />
  ), [agreementId]);

  const renderGuestConversionModal = useCallback(() => (
    <GuestConversion agreementId={agreementId} isOpen />
  ), [agreementId]);

  const {
    shouldMountVideoPreview,
    isVideoModalOpen,
    handleModalClose,
  } = useVideoPreview(agreement);

  const renderVideoPreview = () => (
    <VideoPreview
      isVideoModalOpen={isVideoModalOpen}
      autoplay={false}
      source={getVideoSourceUrl({ agreementId, guestToken })}
      thumbnail={getVideoThumbnailUrl({ agreementId, guestToken })}
      handleClose={handleModalClose}
    />
  );

  const mountVideoPreview = useDetachedModalRenderer(renderVideoPreview);
  /**
    * TODO: refactor this code after the whole agreement page is migrated to react (OF-9115)
    * This will unmount the current content of the detached modal container which
    * may be the sign contract modal (if the user is a guest and is currently signing the agreement)
    */
  const mountSingingCompleteModal = useDetachedModalRenderer(renderSigningCompleteModal);
  const mountGuestConversionModal = useDetachedModalRenderer(renderGuestConversionModal);

  useEffect(() => {
    if (shouldMountVideoPreview) {
      mountVideoPreview();
    } else if (shouldOpenGuestConversionModal) {
      mountGuestConversionModal();
    } else if (hasAgreementBeenFullySignedAfterGuestLastVisit) {
      mountSingingCompleteModal();
    }
  }, [mountVideoPreview,
    shouldMountVideoPreview,
    hasAgreementBeenFullySignedAfterGuestLastVisit,
    mountGuestConversionModal,
    mountSingingCompleteModal,
    shouldOpenGuestConversionModal,
  ]);

  const isExpandedLayout = !deviceSmallHeight && !deviceSmallWidth;
  const isCollapsedLayout = deviceSmallHeight || deviceSmallWidth;

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#fff" data-rh="true" />
      </Helmet>
      <OnBeforeUnload show={showModal} />
      <DocumentLayoutProvider agreementId={agreementId}>
        <AnnotationPropsProvider>
          {isExpandedLayout && renderExpandedDocumentLayout(agreementId)}
          {isCollapsedLayout && renderCollapsedDocumentLayout(agreementId)}
        </AnnotationPropsProvider>
      </DocumentLayoutProvider>
    </>
  );
};

export default DocumentLayoutContainer;
