// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import Confirmable from 'components/confirmable';

export type Props = {
  children: () => React.Node,
  revokeState: RpcState,
  revokeTrustedDevices: () => void,
  resetRevokeState: () => void,
};

export const handleConfirm = ({ revokeTrustedDevices, isLoading }: Props) => () => {
  if (isLoading) {
    return;
  }

  revokeTrustedDevices();
};

const RevokeTrustedDevices = ({
  children,
  revokeState,
  revokeTrustedDevices,
  resetRevokeState,
}: Props) => (
  <Confirmable
    header={(
      <Message
        id="Confirm revoking trusted devices"
        comment="Modal title, confirmation for revoking trusted devices action."
      />
    )}
    body={(
      <>
        <p>
          <Message
            id="You are about to revoke all your trusted devices."
            comment="Modal text, explanation for revoking the trusted devices action."
          />
        </p>
        <p>
          <Message
            id="The next time you login, you will be prompted to authorize the device."
            comment="Modal text, explanation for revoking the trusted devices action."
          />
        </p>
      </>
    )}
    success={revokeState.success}
    error={revokeState.error}
    onOpen={resetRevokeState}
    onEnter={handleConfirm({ revokeTrustedDevices, isLoading: revokeState.loading })}
    isConfirmLoading={revokeState.loading}
    onConfirm={revokeTrustedDevices}
    modalKey="revoke trusted devices modal"
  >
    {children}
  </Confirmable>
);

export default RevokeTrustedDevices;
