import { Message } from '@oneflowab/pomes';

import Button from 'components/button';

type Props = {
  onClick: () => void,
};

export const TryAgainButton = ({ onClick }: Props) => (
  <Button
    kind="primary"
    data-testid="cancel"
    onClick={onClick}
  >
    <Message
      id="Try again"
      comment="Text for button to try again action."
    />
  </Button>
);
