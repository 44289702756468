/* eslint-disable react/prop-types */

import clsx from 'clsx';
import type { MessageTranslator } from '@oneflowab/pomes';
import { localize, Message } from '@oneflowab/pomes';
import { useCallback, useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAccountFromSessionSelector } from 'reducers/session';
import plansReducer from 'reducers/entities/plans';
import { getAdjustedCurrentLanguageSelector } from 'reducers/i18n';
import { getFormatter, formatPrice } from 'account';
import { RootState } from 'reducers';

import ExternalLink from 'components/icons/external-link';
import Modal from 'components/modal';
import Button from 'components/button';
import NewCheck from 'components/icons/new-check';

import style from './plans.module.scss';

type ModalData = {
  planName: string;
  pricePerSeat: number;
  planId: number;
  pricePerSeatMonthlySubscription: number;
  currency: string;
};

interface Plan {
  name: string;
  id: number;
  pricePerSeat: number;
  pricePerSeatMonthlySubscription: number;
  currency: string;
}

export type Props = {
  onClose: () => void;
  message: MessageTranslator;
  modalData: Record<string, never> | ModalData;
  onStepComplete: () => void;
  onSyncStepData: (modalData: ModalData) => void;
  modalKey: string;
}

const PlansModal: React.FC<Props> = ({
  onClose,
  message,
  onSyncStepData,
  onStepComplete,
  modalData,
  modalKey,
}: Props) => {
  const account = useSelector(
    (state: RootState) => getAccountFromSessionSelector(state),
  ) as Oneflow.Account;
  const dispatch = useDispatch();

  const queryPlans = useCallback(() => {
    dispatch(plansReducer.queryPlans());
  }, [dispatch]);

  useEffect(() => {
    queryPlans();
  }, [queryPlans]);

  const plans = useSelector((state) => plansReducer.getAllPlansSelector(state)) as Oneflow.Account['plan'][];
  const languageCode = useSelector((state: RootState) => getAdjustedCurrentLanguageSelector(state));
  const handleClick = useCallback((selectedPlan: ModalData) => {
    onSyncStepData({ ...modalData, ...selectedPlan });
    onStepComplete();
  }, [onSyncStepData, onStepComplete, modalData]);

  const getPlan = useCallback(
    (name: string) => plans.find((plan) => plan?.name === name),
    [plans],
  );
  const accountPlan = account?.plan?.name;

  const getPlans = () => [
    {
      planId: getPlan('essentials').id,
      heading: accountPlan === 'essentials' ? message({
        id: 'Current Subscription Plan',
        comment: 'Heading for most popular subscription',
      }) : null,
      planName: 'Essentials',
      bulletPoints: [
        message({
          id: 'Digital documents',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
        message({
          id: 'Templates',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
        message({
          id: 'Workspaces',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
      ],
      pricePerSeat: getPlan('essentials').pricePerSeat,
      pricePerSeatMonthlySubscription: getPlan('essentials').pricePerSeatMonthlySubscription,
      currency: getPlan('essentials').currency,
    },
    {
      planId: getPlan('business').id,
      heading: accountPlan === 'business' ? message({
        id: 'Current Subscription Plan',
        comment: 'Heading for most popular subscription',
      }) : null,
      planName: 'Business',
      description: message({
        id: 'Includes Essentials plus',
        comment: 'Explaining what a certain price plan includes.',
      }),
      bulletPoints: [
        message({
          id: 'Electronic IDs (EIDs)',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
        message({
          id: 'Integrations',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
        message({
          id: 'Data fields',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
        message({
          id: 'Signing order',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
        message({
          id: 'Workspace branding',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
      ],
      pricePerSeat: getPlan('business').pricePerSeat,
      pricePerSeatMonthlySubscription: getPlan('business').pricePerSeatMonthlySubscription,
      currency: getPlan('business').currency,
    },
    {
      planId: getPlan('enterprise').id,
      heading: accountPlan === 'enterprise' ? message({
        id: 'Current Subscription Plan',
        comment: 'Heading for most popular subscription',
      }) : null,
      planName: 'Enterprise',
      description: message({
        id: 'Includes Business plus',
        comment: 'Explaining what a certain price plan includes.',
      }),
      bulletPoints: [
        message({
          id: 'Shared templates',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
        message({
          id: 'Groups',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
        'Single sign-on (SSO)',
        message({
          id: 'SCIM',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
        message({
          id: 'Replace contract',
          comment: 'Bullet point of what is included in a specific price plan.',
        }),
      ],
      pricePerSeat: getPlan('enterprise').pricePerSeat,
      pricePerSeatMonthlySubscription: getPlan('enterprise').pricePerSeatMonthlySubscription,
      currency: getPlan('enterprise').currency,
    },
  ];

  const renderHeading = (heading?: ReactNode) => {
    if (!heading) {
      return null;
    }

    return <div className={style.Heading}>{heading}</div>;
  };

  const renderPriceInformation = (pricePerSeat: number, currency: string) => {
    if (pricePerSeat === null) {
      return (
        <>
          <h1>
            <Message
              id="Let's talk"
              comment="Placeholder when there is no price information for the selected plan"
            />
          </h1>
          <p>&nbsp;</p>
        </>
      );
    }

    if (pricePerSeat > 0) {
      const formattedPrice = formatPrice(
        getFormatter(languageCode, currency),
        pricePerSeat,
      );

      return (
        <>
          <h1>{formattedPrice}</h1>
          <p>
            <Message
              id="/ month per user"
              comment="Price information for the selected plan"
            />
          </p>
        </>
      );
    }

    return (
      <>
        <h1>
          <Message
            id="Free"
            comment="Placeholder when a user is on a free plan."
          />
        </h1>
        <p>
          <Message
            id="forever"
            comment="Duration of the free pricing plan."
          />
        </p>
      </>
    );
  };

  const renderButton = (
    planName: string,
    pricePerSeat: number,
    pricePerSeatMonthlySubscription: number,
    planId: number,
    currency: string,
  ) => {
    const planSection = planName.toLowerCase();

    const isCurrentPlan = planSection === accountPlan;
    const businessSectionOnEssentialsAccount = planSection === 'business' && accountPlan === 'essentials';
    const isEssentialsPlan = planSection === 'essentials';
    const businessSectionOnEnterpriseAccount = planSection === 'business' && accountPlan === 'enterprise';

    if (isCurrentPlan) {
      return (
        <Button
          kind="primary"
          disabled
        >
          <Message
            id="Current Subscription Plan"
            comment="Text explaining which price plan the user is on."
          />
        </Button>
      );
    }

    if (businessSectionOnEssentialsAccount) {
      return (
        <Button
          kind="primary"
          onClick={() => handleClick({
            planName,
            pricePerSeat,
            pricePerSeatMonthlySubscription,
            planId,
            currency,
          })}
          trackable={{
            name: 'Choose Account Plan',
            props: {
              'plan name': planName,
            },
          }}
          customClass={style.Button}
        >
          <Message
            id="Select Subscription Plan"
            comment="Text on the button used to select a subscription"
          />
        </Button>
      );
    }

    if (isEssentialsPlan || businessSectionOnEnterpriseAccount) {
      return (
        <Button
          kind="ghost"
          href="mailto:support@oneflow.com"
          external
          target="_blank"
          rel="noreferrer"
          customClass={style.Button}
        >
          <Message
            id="Contact us"
            comment="Text on the button used to select a subscription"
          />
        </Button>
      );
    }

    return (
      <Button
        kind="primary"
        href="https://oneflow.com/contact/"
        external
        target="_blank"
        rel="noreferrer"
        trackable="Go To Manual Sales"
        customClass={style.Button}
      >
        <Message
          id="Contact us"
          comment="Text on the button used to select a subscription"
        />
      </Button>
    );
  };

  const renderBulletPoints = (
    bulletPoints: Array<React.ReactNode>,
    planName: string,
  ) => {
    const planSection = planName.toLowerCase();
    const bulletPointsClassNames = clsx(style.BulletPoints, {
      [style.EssentialsBulletPoints]: planSection === 'essentials',
    });

    return (
      <div className={bulletPointsClassNames}>
        {bulletPoints.map((item) => (
          <div key={item} className={style.BulletPoint}>
            <span>
              <NewCheck height="12px" className={style.CheckMark} />
            </span>
            {item}
          </div>
        ))}
      </div>
    );
  };

  const renderPlans = () => {
    if (!plans.length) {
      return null;
    }

    const mappedPlans = getPlans();
    return mappedPlans.map(({
      heading,
      planName,
      description,
      bulletPoints,
      pricePerSeat,
      pricePerSeatMonthlySubscription,
      currency,
      planId,
    }) => (
      <div className={style.Selection} key={planId}>
        {renderHeading(heading)}
        <div className={style.PlanName}>
          <h2>{planName}</h2>
        </div>
        <div className={style.Wrap}>
          {description ? (<p className={style.Bold}>{description}</p>) : null}
        </div>
        {renderBulletPoints(bulletPoints, planName)}
        <div className={style.BottomContainer}>
          {renderPriceInformation(pricePerSeat, currency)}
          {renderButton(
            planName,
            pricePerSeat,
            pricePerSeatMonthlySubscription,
            planId,
            currency,
          )}
        </div>
      </div>
    ));
  };

  const getFeatureGuideLink = () => (
    <>
      <a
        className={style.Link}
        target="_blank"
        rel="noreferrer"
        href="https://oneflow.com/pricing/#plan-comparison"
      >
        <Message
          id="complete feature guide"
          comment="Used as the text of help center button"
        />
      </a>
      <ExternalLink height="14px" className={style.ExternalIcon} />
    </>
  );

  return (
    <Modal
      header={(
        <Message
          id="Change Subscription Plan"
          comment="Message shown in change plan modal"
        />
      )}
      onCancel={onClose}
      hideFooter
      isOpen
      modalKey={modalKey}
    >
      <Modal.Body>
        <div className={style.Plans}>
          <div className={style.Container}>
            {renderPlans()}
          </div>
          <div className={style.LinkContainer}>
            <Message
              id="Compare plans in {featureGuideLink}"
              comment="Link to the feature guide in the pricing page"
              values={{
                featureGuideLink: getFeatureGuideLink(),
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default localize<Props>(PlansModal);
