import { useCallback } from 'react';
import { localize } from '@oneflowab/pomes';
import {
  filter,
  findIndex,
  isEmpty,
  map,
  get,
} from 'lodash';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';

import restrictToParentElement from 'dnd/restrict-to-parent-element';
import { BOX_ATTACHMENTS } from 'agreement/constants';
import type { DragEndEvent } from '@dnd-kit/core';
import type { MessageTranslator } from '@oneflowab/pomes';

import { getGuestToken } from 'agreement/selectors';
import {
  getCurrentContractData,
  getDataFieldExternalKeyMap,
  getDataFieldExternalKeyValueMap,
  updateBoxOrderAction,
  getContractMetaData,
} from 'reducers/current-contract';
import { useSmartFieldOnDragEnd } from 'hooks/use-smart-field-on-drag-end';
import useMatchMedia, { DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY } from 'hooks/use-match-media';

import {
  getHasBrokenSectionRule,
  getIsBoxRestricted,
  getSectionRule,
  getVisibility,
} from 'components/contract-boxes/box-wrapper/helpers';
import { DocumentDndSegment } from 'components/document-layout-container/document-dnd-segment';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import { useDocumentDndContext } from 'components/document-layout-container/document-dnd-context';

import getBoxTypeName from 'utils/get-box-type-name';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import BoxItem from './box-item';
import { BoxItemIsVisibleContext } from './box-item/box-item-is-visible-context';
import ContractID from './contract-id';
import DragOverlay from './drag-overlay';
import EmptyList from './empty-list';
import styles from './box-list.module.scss';

type Props = {
  agreementId: number,
  message: MessageTranslator,
  onFocus?: () => void
}

const BoxList = ({
  agreementId,
  message,
  onFocus,
}: Props) => {
  const { boxes, boxOrder } = useSelector(getCurrentContractData);
  const { emptyBoxes } = useSelector(getContractMetaData);
  const dataFieldExternalKeyMap = useSelector(getDataFieldExternalKeyMap);
  const dataFieldExternalKeyValueMap = useSelector(getDataFieldExternalKeyValueMap);
  const dispatch = useDispatch();
  const guestToken = useSelector(getGuestToken);
  const isGuest = Boolean(guestToken);
  const onSmartFieldDragEnd = useSmartFieldOnDragEnd();

  const items = map(boxOrder, (item) => ({ id: getId(item) }));

  const { activeDndId } = useDocumentDndContext();

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const activeIndex = findIndex(boxOrder, (item) => getId(item) === active.id);
      const overIndex = findIndex(boxOrder, (item) => getId(item) === over.id);

      dispatch(updateBoxOrderAction(arrayMove(boxOrder, activeIndex, overIndex)));
    }
  }, [boxOrder, dispatch]);

  const onDataObjectMove = useCallback((event: DragEndEvent) => {
    if (event.active?.data?.current?.onMove) {
      event.active?.data?.current?.onMove(event);
    }
  }, []);

  const onBoxDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    const segmentId = over?.data.current?.segmentId;
    const onDrop = over?.data.current?.onDrop;
    const boxType = active?.data.current?.boxType;
    if (segmentId !== 'box_dnd' || !onDrop || !boxType) {
      return;
    }

    amplitudeLogEvent(
      'Add Content Section',
      {
        location: 'content tab',
        'content section type': getBoxTypeName(boxType),
      },
    );
    onDrop(boxType);
  }, []);

  if (isEmpty(filter(boxes, (box) => !box._removed))) {
    return (
      <>
        <DocumentDndSegment
          segmentId="box_dnd"
          onDragEnd={onBoxDragEnd}
          autoScroll
        />
        <EmptyList agreementId={agreementId} />
      </>
    );
  }

  return (
    <>
      <div
        className={styles.BoxList}
        onFocus={onFocus}
      >
        <DocumentDndSegment
          segmentId="movable_data_object"
          onDragEnd={onDataObjectMove}
          modifiers={[restrictToParentElement]}
        />
        <DocumentDndSegment
          segmentId="box_dnd"
          onDragEnd={onBoxDragEnd}
          autoScroll
        />
        <DocumentDndSegment
          segmentId="smart_fields"
          onDragEnd={onSmartFieldDragEnd}
        />
        <DocumentDndSegment
          segmentId="sortable_box_list"
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={items}
            strategy={verticalListSortingStrategy}
          >
            {map(items, ({ id }, index) => {
              const box = boxes[id];
              const rule = getSectionRule(box);
              const hasBrokenSectionRule = getHasBrokenSectionRule(rule, dataFieldExternalKeyMap);
              const isVisible = getVisibility(rule, dataFieldExternalKeyValueMap);
              const hasBrokenOrHiddenSectionRule = hasBrokenSectionRule || !isVisible;
              const isBoxRestricted = getIsBoxRestricted(isGuest, hasBrokenOrHiddenSectionRule);
              const isBoxEmpty = emptyBoxes.includes(getId(box));
              const isEmptyAttachmentsBoxEditable = isBoxEmpty && box.type === BOX_ATTACHMENTS
                && Boolean(get(box, 'config.counterpartEdit'));
              const isBoxEmptyInGuestView = isBoxEmpty && isGuest;
              const isBoxUnavailable = !box || box._removed;
              if (isBoxRestricted
                || isBoxUnavailable
                || (isBoxEmptyInGuestView && !isEmptyAttachmentsBoxEditable)) {
                return null;
              }

              return (
                <BoxItemIsVisibleContext.Provider
                  key={id}
                  value={{ isVisible: isVisible && !hasBrokenSectionRule }}
                >
                  <BoxItem
                    boxId={id}
                    index={index}
                    message={message}
                    agreementId={agreementId}
                    hasBrokenOrHiddenSectionRule={hasBrokenOrHiddenSectionRule}
                  />
                </BoxItemIsVisibleContext.Provider>
              );
            })}
          </SortableContext>
          {activeDndId && (
            <DragOverlay
              activeId={activeDndId}
              message={message}
            />
          )}
        </DocumentDndSegment>
      </div>
      <ContractID agreementId={agreementId} />
    </>
  );
};

export default localize(BoxList);
