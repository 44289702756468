// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getCurrentLanguageSelector } from 'reducers/i18n';

import ContractValue from './contract-value';
import type { Props } from './contract-value';

type StateProps = {|
  currentLanguage: string,
|};

type OwnProps = $Diff<Props, StateProps>;

type MapStateToProps = (State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  currentLanguage: getCurrentLanguageSelector(state),
});

type DispatchProps = $Exact<{}>;
type MapDispatchToProps = Dispatch<*> => DispatchProps;
export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, _>(
  mapStateToProps,
  mapDispatchToProps,
)(ContractValue);
