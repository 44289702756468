// @flow

import values from 'lodash/values';
import { put, select } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getAuditLogs,
} from 'oneflow-client/audit-log';
import type { NormalizedAuditLogs } from 'oneflow-client/audit-log';

import apiWrapper from 'sagas/api-wrapper';

import auditLogs from 'reducers/entities/audit-logs';
import positions from 'reducers/entities/positions';

type MapperArgs = {
  data: NormalizedAuditLogs,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;
type AuditLogEvents = Array<AuditLogEvent>

export const getAuditLogsWithPositions = (state: State, auditLogEvents: AuditLogEvents) => {
  const newLogs = {};

  auditLogEvents.forEach((auditLogEvent) => {
    const position = positions.getPositionSelector(state, { id: auditLogEvent.actorPosition });
    const logWithPosition = {
      ...auditLogEvent,
      position,
    };
    newLogs[auditLogEvent.id] = logWithPosition;
  });

  return newLogs;
};

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(positions.setPositions(data.entities.positions));
  const newPositions = yield select(getAuditLogsWithPositions, values(data.entities.auditLogs));
  yield put(auditLogs.setAuditLogs(newPositions));
}

const mappers = {
  query: {
    mapper,
    request: getAuditLogs,
  },
};

const auditLogsSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: auditLogs,
  mappers,
});

export default auditLogsSaga;
