import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import DeleteIcon from 'components/icons/delete';
import { MenuItem } from 'components/menu-item';

import style from './delete.module.scss';

type Props = {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

// You probably should not need to customize label or icon,
// all DeleteMenuItem should only use the "Delete" label.
// Rather create a specific menu item if needed, e.g. MoveToTrashMenuItem
export const DeleteMenuItem = ({
  disabled,
  onClick,
}: Props) => (
  <MenuItem
    disabled={disabled}
    label={(
      <Message id="Delete" comment="Label for delete action in menu." />
    )}
    onClick={onClick}
    icon={DeleteIcon}
    className={!disabled ? style.Danger : undefined}
  />
);
