// @flow

import * as React from 'react';
import moment from 'moment';
import type Moment from 'moment';
import { isFunction, uniqueId } from 'lodash';
import type { FieldRenderProps } from 'react-final-form';
import { getEndOfDayTimestamp, toMoment } from 'date';
import clsx from 'clsx';

import { SingleDatePicker } from 'components/date-picker';
import Button from 'components/button';
import NewCross from 'components/icons/new-cross';
import NewArrowLeft from 'components/icons/new-arrow-left';
import Calendar from 'components/icons/calendar';
import NewArrowRight from 'components/icons/new-arrow-right';

import style from './single-date-picker.module.scss';

type Props = FieldRenderProps & {
  autoFocus?: boolean,
  label?: string | React.Element,
  customClassName?: string,
  displayFormat?: string,
  showClearDate?: boolean,
  block?: boolean,
  /* Datepicker props */
  // input related props
  disabled?: boolean,
  placeholder?: string,
  customCloseIcon?: React.Node | any,
  // calendar presentation and interaction related props
  initialVisibleMonth?: Function | null,
  disabled: boolean,
  customCloseIcon: React.Node,
  noCloseIcon?: boolean,
  defaultDate: Object,
  onChangeDate: Function,
  isOutsideRange?: Moment => boolean,
  renderMonthElement?: Function,
  reversedIcon?: boolean,
};

const getDateValue = (date) => {
  if (moment.isMoment(date)) {
    return getEndOfDayTimestamp(date);
  }

  return null;
};

const getNavigationButton = (next: boolean) => {
  if (next) {
    return (
      <Button
        kind="link"
        customClass={style.NavigationButtonNext}
        icon={<NewArrowRight height="11px" />}
      />
    );
  }

  return (
    <Button
      kind="link"
      customClass={style.NavigationButtonPrev}
      icon={<NewArrowLeft height="11px" />}
    />
  );
};

export const SingleDatePickerComponent = (props: Props) => {
  const {
    autoFocus,
    block,
    customClassName,
    defaultDate,
    disabled,
    displayFormat,
    initialVisibleMonth,
    input,
    isOutsideRange,
    label,
    noCloseIcon = false,
    onChangeDate,
    placeholder,
    readOnly,
    renderMonthElement,
    reversedIcon,
  } = props;
  const [focus, setFocus] = React.useState(autoFocus);

  const uId = uniqueId();
  let date = defaultDate ? toMoment(defaultDate) : null;
  if (input && input.value) {
    date = toMoment(input.value);
  }

  const handleDateChange = (newDate) => {
    if (input && isFunction(input.onChange)) {
      input.onChange(getDateValue(newDate));
    }

    if (!input) {
      onChangeDate(getDateValue(newDate));
    }
  };

  const handleFocusChange = ({ focused }) => {
    setFocus(focused);
  };

  const iconProps = {
    inputIconPosition: !reversedIcon ? 'after' : 'before',
    showClearDate: !disabled || Boolean(date),
    customInputIcon: !noCloseIcon && <Calendar height="10px" />,
    customCloseIcon: !noCloseIcon && <NewCross height="10px" />,
  };

  function renderDatePicker() {
    return (
      <SingleDatePicker
        {...iconProps}
        block={block}
        date={date}
        daySize={31}
        disabled={disabled}
        displayFormat={displayFormat}
        focused={focus}
        hideKeyboardShortcutsPanel
        id={uId}
        initialVisibleMonth={initialVisibleMonth}
        isOutsideRange={isOutsideRange}
        navNext={getNavigationButton(true)}
        navPrev={getNavigationButton()}
        numberOfMonths={1}
        onDateChange={handleDateChange}
        onFocusChange={handleFocusChange}
        placeholder={placeholder}
        readOnly={readOnly}
        renderMonthElement={renderMonthElement}
      />
    );
  }

  const hasIconsAndIsFocused = iconProps?.customCloseIcon && iconProps?.customInputIcon && focus;

  const labelClasses = clsx(style.SingleDatePicker, customClassName, {
    [style.hasNoDate]: !date,
    [style.hasDateFocused]: !disabled && date && focus,
    [style.isEmptyFocused]: hasIconsAndIsFocused && !date && !disabled,
  });

  if (label) {
    return (
      <label htmlFor={uId} className={labelClasses}>
        <span className={style.Label}>
          {label}
        </span>
        {renderDatePicker()}
      </label>
    );
  }

  return renderDatePicker();
};

SingleDatePickerComponent.defaultProps = {
  autoFocus: false,
  label: undefined,
  customClassName: undefined,
  placeholder: undefined,
  initialVisibleMonth: undefined,
  displayFormat: undefined,
  showClearDate: true,
  block: true,
};
