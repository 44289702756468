// @flow

import * as React from 'react';

import style from './insight.module.scss';

type Props = {
  IconComponent: React.ComponentType<IconProps>,
  insight: React.Node,
};

const Insight = ({ IconComponent, insight }: Props) => {
  if (!insight) {
    return null;
  }

  return (
    <div className={style.Insight}>
      <IconComponent className={style.Icon} height="12px" />
      {insight}
    </div>
  );
};

export default Insight;
