// @flow

import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import positionMfaReducer from 'reducers/entities/position-mfa';
import { getAccountFromSessionSelector, getPositionFromSessionSelector } from 'reducers/session';
import { MFA_EMAIL } from 'components/mfa-channel-type';

import Security from './security';
import type { Props, CreatePositionMfaArgs, FormData } from './security';

type StateProps = {|
  position: Position,
  positionMfa: PositionMfa,
  formState: CreateState,
  fetchState: FetchState,
  accountCountry: string | void,
  actions: {
    start: string,
    success: string,
    fail: string,
  },
  sendData: FormData => void,
|};

type PositionArgs = {
  positionId: number,
};

type DispatchProps = {|
  fetchPositionMfa: PositionArgs => void,
  resetCreateState: PositionArgs => void,
  createPositionMfa: CreatePositionMfaArgs => void,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;
type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);
  const accountCountry = account.country;
  const position = getPositionFromSessionSelector(state);
  const positionMfa = positionMfaReducer.getPositionMfaSelector(state, { id: position.id });
  const fetchState = positionMfaReducer.getFetchSelector(state, { id: position.id });
  const createState = positionMfaReducer.getCreateSelector(state, { id: position.id });

  return {
    accountCountry,
    positionMfa,
    position,
    formState: createState,
    fetchState,
    actions: {
      start: positionMfaReducer.actions.types.createStart,
      success: positionMfaReducer.actions.types.createSuccess,
      fail: positionMfaReducer.actions.types.createFail,
    },
    sendData: (formData) => {
      const selectedChannel = get(formData, 'channel.value') || formData.channel;
      return ({
        id: position.id,
        data: {
          positionId: position.id,
          channel: selectedChannel,
          channelData: selectedChannel === MFA_EMAIL ? position.email : formData.phoneNumber,
          password: formData.password,
        },
      });
    },
  };
};

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  fetchPositionMfa: ({ positionId }: PositionArgs) => {
    dispatch(positionMfaReducer.fetchPositionMfa({ id: positionId }));
  },
  resetCreateState: ({ positionId }: PositionArgs) => {
    dispatch(positionMfaReducer.createPositionMfaReset({ id: positionId }));
  },
});

export default connect<Props, any, StateProps, DispatchProps, State, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Security);
