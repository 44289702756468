// @flow

import React, { useEffect, useRef, useState } from 'react';
import { Message } from '@oneflowab/pomes';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getLocationSelector } from 'reducers/router';
import {
  folderNameAlreadyExists,
  generateDuplicateName,
  getFoldersByParentId,
  getRedirectPath,
} from 'workspace/folders';
import foldersReducer from 'reducers/entities/folders';
import useOverflow from 'hooks/use-overflow';

import { checkAcl } from 'components/acl';
import Button from 'components/button';
import ChevronRightIcon from 'components/icons/chevron-right';
import Conditional from 'components/conditional';
import FolderActions from 'components/folder-actions';
import FolderIcon from 'components/icons/folder';
import InlineEdit from 'components/inline-edit';
import SmallAdd from 'components/icons/small-add';
import Tooltip from 'components/tooltip';

import style from './folder-branch.module.scss';

const FOLDERS_QUERY = 'folders';

type BranchType = {
  acl: Acl,
  allFolders: any,
  childFolders?: Array<BranchType>,
  contractFolderId?: number,
  customClass?: string,
  id: number,
  indentationLevel: number,
  isAddMode: boolean,
  isModalVersion?: boolean,
  isSaveFolderDestinationModal?: boolean,
  name: string,
  onAddFolder: (string) => void,
  onFolderAddHandler: () => void,
  onFolderSelectionHandler?: (number) => void,
  parentId?: null | number,
  parents: Array<number>,
  selectedUniqueKey?: any,
  isReadOnly?: boolean,
}

const FolderBranch = ({
  acl,
  allFolders,
  childFolders,
  contractFolderId,
  customClass,
  id,
  indentationLevel,
  isAddMode,
  isModalVersion,
  isSaveFolderDestinationModal,
  name,
  onAddFolder,
  onFolderAddHandler,
  onFolderSelectionHandler,
  parentId,
  parents,
  selectedUniqueKey,
  isReadOnly,
}: BranchType) => {
  const isWorkspace = id === -1;
  const [isEditMode, setIsEditMode] = useState(false);
  const hasChildren = childFolders?.length;
  const [showChildren, setShowChildren] = useState(
    isWorkspace
    || hasChildren
    || (isModalVersion && id === contractFolderId),
  );
  const currentWorkspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  const nameRef = useRef(null);
  const hasOverflow = useOverflow(nameRef);
  const workspaceCreatePermission = checkAcl(currentWorkspace.acl, 'collection:folder:create');

  const dispatch = useDispatch();
  const location = useSelector((state) => getLocationSelector(state));

  const saveFolderName = (folderName) => {
    dispatch(foldersReducer.updateFolder({
      id,
      data: {
        name: folderName,
      },
      pipe: {
        action: () => foldersReducer.queryFoldersReload({
          name: FOLDERS_QUERY,
        }),
      },
    }));
  };

  const onEditFolderName = (newName) => {
    if (newName === name) {
      setIsEditMode(false);
      return;
    }
    if (newName !== '') {
      if (!folderNameAlreadyExists(newName, parentId, allFolders)) {
        saveFolderName(newName);
      } else {
        const tempName = generateDuplicateName(newName, parentId, allFolders);
        saveFolderName(tempName);
      }
    }

    setIsEditMode(false);
  };

  useEffect(() => {
    if (parents.includes(id)) {
      setShowChildren(true);
    }

    if (isWorkspace && isAddMode) {
      setShowChildren(true);
    }
  }, [id, isAddMode, parents]);

  const getBranchContent = (content: React.Node) => {
    if (contractFolderId === id && !isSaveFolderDestinationModal) {
      return (
        <Tooltip
          triggerClassName={style.FolderName}
          message={(
            <Message
              id="This contract already exists in this folder."
              comment="Tooltip message when contract already exists in folder."
            />
          )}
          side="top"
          theme="oneflow"
          zIndex={10002}
        >
          {content}
        </Tooltip>
      );
    }

    return content;
  };

  const getBranchContentStyle = (addMode) => {
    const base = isModalVersion ? 0 : 32;

    switch (indentationLevel) {
      case 1:
        return {
          maxWidth: addMode ? '100%' : `calc(100% - ${base}px)`,
          paddingInlineStart: addMode ? '18px' : '0',
        };
      case 2:
        return {
          maxWidth: addMode ? '100%' : `calc(100% - ${base + 18}px)`,
          paddingInlineStart: addMode ? '36px' : '18px',
        };
      case 3:
        return {
          maxWidth: addMode ? '100%' : `calc(100% - ${base + 36}px)`,
          paddingInlineStart: '36px',
        };
      default:
        return null;
    }
  };

  const toggleShowChildren = () => {
    setShowChildren(!showChildren);
  };

  const onKeyDownHandler = () => undefined;

  const getChevron = () => {
    if (isWorkspace || hasChildren) {
      return (
        <div
          className={style.Chevron}
          onClick={toggleShowChildren}
          onKeyDown={onKeyDownHandler}
          role="button"
          tabIndex={0}
        >
          <ChevronRightIcon
            height="10px"
            className={!showChildren ? style.Closed : style.Open}
          />
        </div>
      );
    }

    return <div className={style.Placeholder} />;
  };

  const getChildren = () => (showChildren && childFolders?.map((branch) => (
    <ul className={style.List} key={branch.id}>
      <FolderBranch
        acl={branch.acl}
        allFolders={allFolders}
        childFolders={getFoldersByParentId(allFolders, branch.id)}
        contractFolderId={contractFolderId}
        id={branch.id}
        indentationLevel={indentationLevel + 1}
        isAddMode={isAddMode}
        isEditMode={isEditMode}
        isModalVersion={isModalVersion}
        isSaveFolderDestinationModal={isSaveFolderDestinationModal}
        name={branch.name}
        onAddFolder={onAddFolder}
        onFolderAddHandler={onFolderAddHandler}
        onFolderSelectionHandler={onFolderSelectionHandler}
        parentId={id}
        parents={parents}
        selectedUniqueKey={selectedUniqueKey}
        setIsEditMode={setIsEditMode}
        isReadOnly={isReadOnly}
      />
    </ul>
  )));

  const didClickRename = () => {
    setIsEditMode(true);
  };

  const setActiveFolder = () => {
    if (!isModalVersion && selectedUniqueKey !== id) {
      dispatch(push(getRedirectPath(location, [id])));
    }

    if (isModalVersion && onFolderSelectionHandler) {
      onFolderSelectionHandler(id, name);
    }
  };

  const getFolderName = () => {
    if (isEditMode) {
      return (
        <InlineEdit
          onEditFolderHandler={onEditFolderName}
          value={name}
        />
      );
    }

    const folderNameElement = (
      <span
        className={
          contractFolderId !== id || isSaveFolderDestinationModal
            ? style.FolderName : style.FolderNameDisabled
        }
        onClick={setActiveFolder}
        onKeyDown={onKeyDownHandler}
        ref={nameRef}
        role="button"
        tabIndex={0}
      >
        {name}
      </span>
    );

    if (hasOverflow && contractFolderId !== id) {
      return (
        <Tooltip
          triggerClassName={style.FolderName}
          message={name}
          messageClassName={style.FolderNameTooltip}
          side="bottom"
          theme="oneflow"
          zIndex={10002}
        >
          {folderNameElement}
        </Tooltip>
      );
    }

    return folderNameElement;
  };

  const handleClick = (folderId) => {
    if (selectedUniqueKey !== folderId) {
      dispatch(push(getRedirectPath(location, [folderId])));
    }

    onFolderAddHandler();
  };

  const branchClasses = clsx(style.Branch, customClass);
  const containerClasses = clsx(style.Container, {
    [style.Active]: id === selectedUniqueKey,
  });

  return getBranchContent(
    <li className={branchClasses} key={id}>
      <div className={containerClasses}>
        <div className={style.LeftSide} style={getBranchContentStyle()}>
          {getChevron()}
          <Conditional ifCondition={!isWorkspace}>
            <div className={style.Icon}>
              <FolderIcon height="18px" />
            </div>
          </Conditional>
          {getFolderName()}
        </div>
        <Conditional ifCondition={!isModalVersion && !isWorkspace && !isReadOnly}>
          <FolderActions
            allFolders={allFolders}
            acl={acl}
            didClickRename={didClickRename}
            folderId={id}
            folderName={name}
            onFolderAddHandler={handleClick}
            showAddFolderAction
          />
        </Conditional>
      </div>
      <Conditional ifCondition={isAddMode && id === selectedUniqueKey}>
        <div style={getBranchContentStyle(true)}>
          <div className={style.Edit}>
            <div className={style.Placeholder} />
            <div className={style.Icon}>
              <FolderIcon height="18px" />
            </div>
            <InlineEdit
              onEditFolderHandler={onAddFolder}
              value=""
            />
          </div>
        </div>
      </Conditional>
      {getChildren()}
      <Conditional
        ifCondition={
          isWorkspace
          && !hasChildren
          && showChildren
          && !isAddMode
        }
      >
        <div className={style.EmptyState}>
          <Message
            id="No folders inside"
            comment="Folder empty state message."
          />
          {!isReadOnly && (
            <Button
              customClass={style.AddFolderButton}
              disabled={!workspaceCreatePermission}
              icon={<SmallAdd height="8px" />}
              kind="linkInline"
              onClick={() => handleClick(-1)}
            >
              <Message
                id="Add folder"
                comment="Folder empty state add button."
              />
            </Button>
          )}
        </div>
      </Conditional>
    </li>,
  );
};

export default FolderBranch;
