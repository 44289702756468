import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import { isDeviceWithoutHover } from 'utils/browser';
import { getGuestToken } from 'agreement/selectors';
// eslint-disable-next-line import/named
import { getSEBankIDQRCode } from 'oneflow-client/agreements';

import QRCodeIcon from 'components/icons/qr-code';
import QRCode from 'components/qr-code/qr-code';
import Button from 'components/button';
import BankIdScreenshot from './bankid-screenshot.png';

import { SignCodeState } from '../types';

import style from './swedish-bankid-flow.module.scss';
import PendingSignature from './pending-signature';

export type Props = {
  switchToSameDevice: () => void,
  signCodeState: SignCodeState
  handleError: (errorCode: string) => void,
  signingInProgress: boolean,
  hasParticipantSigned: boolean,
}

const REFRESH_QR_CODE_CONTENT_INTERVAL = 5000;
const isMobileDevice = isDeviceWithoutHover();

const BankIDOtherDevice = ({
  switchToSameDevice,
  signCodeState,
  handleError,
  signingInProgress,
  hasParticipantSigned,
}: Props) => {
  const [QRValue, setQRValue] = useState('');
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);
  const guestToken = useSelector(getGuestToken);
  const signingInProgressRef = useRef(signingInProgress);

  useEffect(() => {
    if (hasParticipantSigned) {
      signingInProgressRef.current = false;
    } else {
      signingInProgressRef.current = signingInProgress;
    }
  }, [signingInProgress, hasParticipantSigned]);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        if (
          signCodeState?.data?.orderRef
          && !signingInProgressRef.current
          && !hasParticipantSigned
        ) {
          const params = {
            guestToken,
            orderRef: signCodeState?.data?.orderRef,
          };

          const { qrcodeContent } = await getSEBankIDQRCode(params);
          setQRValue(qrcodeContent);
        }
      } catch (err) {
        handleError('QR_CODE_EXPIRED');
        setQRValue('');
      }
    };

    fetchQRCode();

    intervalIdRef.current = setInterval(fetchQRCode, REFRESH_QR_CODE_CONTENT_INTERVAL);

    return () => {
      if (intervalIdRef.current !== null) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [
    guestToken, handleError, hasParticipantSigned, signCodeState?.data?.orderRef,
  ]);

  const renderQRCode = () => (
    <>
      <QRCode
        level="L"
        wrapperClassName={style.IconWrapper}
        value={QRValue}
      />
      <div className={style.BankIDInformation}>
        <div className={style.BankIDInstructionsOtherDevice}>
          {!isMobileDevice && (
            <div className={style.PhoneOuterFrame}>
              <img
                className={style.PhoneScreenshot}
                src={BankIdScreenshot}
                alt="Screenshot of the BankID app on a mobile phone"
              />
            </div>
          )}
          <div className={style.Instructions}>
            <p className={style.InstructionsTitle}>
              <Message id="Instructions" comment="Instructions headline for BankID signing" />
            </p>
            <ol className={style.InstructionsList} type="1">
              <li>
                <Message id="Open the BankID app on your mobile device" comment="1st step instruction for signing with BankID" />
              </li>
              <li>
                <Message id="Press the QR icon in the BankID app" comment="2nd step instruction for signing with BankID" />
                <QRCodeIcon height="16px" className={style.QRCodeIcon} />
              </li>
              <li>
                <Message id="Point the camera towards the QR code" comment="3rd step instruction for signing with BankID" />
              </li>
            </ol>
            <Button
              kind="linkInline"
              data-testid="switch-to-same-device"
              onClick={switchToSameDevice}
              customClass={style.BankIDDeviceToggle}
            >
              <Message id="Or use BankID on this device" comment="button label to switch BankID method" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  return signingInProgress ? <PendingSignature device="other" autoStartToken={signCodeState?.data?.autoStartToken} /> : renderQRCode();
};

export default BankIDOtherDevice;
