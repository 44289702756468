import { Message } from '@oneflowab/pomes';

import { useDataFieldsTabProps } from 'contexts/data-fields-tab-props';

import Button from 'components/button';
import SortAscending from 'components/icons/sort-ascending';
import SortDescending from 'components/icons/sort-descending';

import style from './data-field-sort.module.scss';

const DataFieldSort = () => {
  const { sortOrder, setSortOrder } = useDataFieldsTabProps();

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  const renderIcon = () => {
    if (sortOrder === 'desc') {
      return <SortDescending height="16px" />;
    }
    return <SortAscending height="16px" />;
  };

  const renderLabel = () => {
    if (sortOrder === 'desc') {
      return (
        <Message
          id="A-Z"
          comment="Label for sorting order, showing ascending."
        />
      );
    }
    return (
      <Message
        id="Z-A"
        comment="Label for sorting order, showing descending."
      />
    );
  };

  return (
    <Button
      kind="linkInline"
      customClass={style.DataFieldSort}
      onClick={toggleSortOrder}
      icon={renderIcon()}
    >
      {renderLabel()}
    </Button>
  );
};

export default DataFieldSort;
