// @flow

import isEmpty from 'lodash/isEmpty';
import {
  Editor,
  Range,
} from 'slate';

const getEditorMarks = (editor) => {
  try {
    return Editor.marks(editor);
  } catch {
    return null;
  }
};

export const isMarkActive = (editor: any, format: any, currentActiveMarks: any) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const [node] = Editor.parent(editor, selection.anchor.path);

    if (node.type === 'image') {
      return false;
    }
    if (node.type === 'data-field') {
      if (isEmpty(node.marks)) {
        return false;
      }
      return node.marks[format];
    }
  }

  const marks = getEditorMarks(editor) || currentActiveMarks;
  return marks ? marks[format] === true : false;
};

const isSubscriptOrSuperscriptActive = (currentActiveMarks) => (
  Boolean(currentActiveMarks?.subscript) || Boolean(currentActiveMarks?.superscript)
);

const isFormatSubscriptOrSuperscript = (format) => (
  format === 'subscript' || format === 'superscript'
);

export const toggleMark = (editor: any, format: any, currentActiveMarks: any) => {
  const isActive = isMarkActive(editor, format, currentActiveMarks);
  if (isActive) {
    Editor.removeMark(editor, format);
    return;
  }

  if (
    isFormatSubscriptOrSuperscript(format) && isSubscriptOrSuperscriptActive(currentActiveMarks)
  ) {
    const oppositeFormat = format === 'superscript' ? 'subscript' : 'superscript';
    Editor.removeMark(editor, oppositeFormat);
  }
  Editor.addMark(editor, format, true);
};
