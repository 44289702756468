import { connect } from 'react-redux';

import workspaceMessageTemplatesReducer from 'reducers/entities/workspace-message-templates';

import AddMessageTemplate from './add-message-template';

const queryName = () => 'templates/messages';

export const mapStateToProps = (state) => ({
  formState: workspaceMessageTemplatesReducer.getCreateSelector(state),
});

export const mapDispatchToProps = (dispatch, { id }) => ({
  resetFormState: () => {
    dispatch(workspaceMessageTemplatesReducer.createWorkspaceMessageTemplateReset());
  },
  onSubmit: ({
    body,
    name,
    subject,
    type,
  }) => {
    dispatch(workspaceMessageTemplatesReducer.createWorkspaceMessageTemplate({
      data: {
        workspaceId: id,
        body,
        name,
        subject,
        type: type.value || 'publish',
      },
      pipe: {
        action: () => workspaceMessageTemplatesReducer.queryWorkspaceMessageTemplatesReload({
          name: queryName(),
        }),
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMessageTemplate);
