import * as zod from 'zod';

import { mfaChannelValue, aclValue } from './common';

import Entity, { NullableNumberIdSchema, NumberIdSchema } from './entity';

import {
  dateSchema,
  timestampSchema,
  integerBoolean,
} from '../utils';

export const participantAclSchema = zod.object({
  'participant:update:country': aclValue.optional(),
  'participant:update:delivery_channel': aclValue.optional(),
  'participant:update:email': aclValue.optional(),
  'participant:update:fullname': aclValue.optional(),
  'participant:update:mfa_channel': aclValue.optional(),
  'participant:update:phone_number': aclValue.optional(),
  'participant:update:sign_method': aclValue.optional(),
  'participant:update:ssn': aclValue.optional(),
  'participant:update:title': aclValue.optional(),
  'participant:update:type': aclValue.optional(),
  'participant:role:set:draft_approver': aclValue.optional(),
});

export const contractParticipantCompanySchema = NullableNumberIdSchema.describe('contractParticipantCompany');

export const participantPositionSchema = NullableNumberIdSchema.describe('participantPosition');
export const participantAgreementCompanySchema = NullableNumberIdSchema.describe('participantAgreementCompany');
export const participantAccountSchema = NumberIdSchema.describe('participantAccount');

const Participant = Entity.extend({
  account: participantAccountSchema.nullable().optional(),
  acl: participantAclSchema.nullable().optional(),
  name: zod.string().nullable().optional(),
  fullname: zod.string().nullable().optional(),
  type: zod.number().nullable().optional(),
  deliveryChannel: zod.number().nullable().optional(),
  signMethod: zod.number().nullable().optional(),
  // refine api used in integerBoolean and hence not possible to use nullish
  invite: zod.optional(integerBoolean),
  country: zod.string().nullable().optional(),
  created: dateSchema.nullable().optional(),
  createdTs: timestampSchema.nullable().optional(),
  // refine api used in integerBoolean and hence not possible to use nullish
  deliveryChannelStatus: zod.number().nullable().optional(),
  deliveryChannelStatusTimestamp: dateSchema.nullable().optional(),
  deliveryChannelStatusTimestampTs: timestampSchema.nullable().optional(),
  deliveryChannelVerified: zod.literal(0).nullable().optional(),
  email: zod.string().nullable().optional(),
  firstVisit: dateSchema.nullable().optional(),
  firstVisitTs: timestampSchema.nullable().optional(),
  hasAccess: zod.boolean().nullable().optional(),
  isPublished: zod.boolean().nullable().optional(),
  lastVisit: dateSchema.nullable().optional(),
  lastVisitTs: timestampSchema.nullable().optional(),
  mfaChannel: mfaChannelValue.nullable().optional(),
  agreementCompany: participantAgreementCompanySchema.nullable().optional(),
  phoneNumber: zod.string().nullable().optional(),
  publishedAt: dateSchema.nullable().optional(),
  publishedAtTs: timestampSchema.nullable().optional(),
  self: zod.optional(integerBoolean),
  signatureInfo: zod.object({
    hasSigned: zod.boolean().nullable().optional(),
    signatureImage: zod.object({
      data: zod.string().nullable().optional(),
      mimetype: zod.union([zod.literal('image/png'), zod.literal('image/svg+xml')]).nullable().optional(),
      type: zod.union([zod.literal('HANDWRITTEN_TYPED'), zod.literal('HANDWRITTEN_DRAWN'), zod.literal('NONE'), zod.literal('STANDARD')]).nullable().optional(),
    }).nullable().optional(),
  }).nullable().optional(),
  ssn: zod.string().nullable().optional(),
  state: zod.number().nullable().optional(),
  stateTimestamp: dateSchema.nullable().optional(),
  stateTimestampTs: timestampSchema.nullable().optional(),
  title: zod.string().nullable().optional(),
  typeTimestamp: dateSchema.nullable().optional(),
  typeTimestampTs: timestampSchema.nullable().optional(),
  updated: dateSchema.nullable().optional(),
  updatedTs: timestampSchema.nullable().optional(),
  visits: zod.number().nullable().optional(), // default 0
  position: participantPositionSchema.nullable().optional(),
}).describe('participant');

export default Participant;
