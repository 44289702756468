// @flow

import * as React from 'react';

import { localize, Message } from '@oneflowab/pomes';
import ModalForm from 'hocs/modal-form';

import { TemplateGroupDescriptionField, TemplateGroupNameField } from 'components/fields';

type Props = {
  createTemplateGroup: ({name: string, description: string}) => void,
  createState: RpcState,
  resetCreateState: () => void,
  children: React.Node,
};

export const AddTemplateGroupFields = ({
  createTemplateGroup,
  createState,
  resetCreateState,
  children,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Create template group"
        comment="Modal title for creating a new template group"
      />
    )}
    body={(
      <div>
        <TemplateGroupNameField autoFocus />
        <TemplateGroupDescriptionField />
      </div>
    )}
    onSubmit={createTemplateGroup}
    resetFormState={resetCreateState}
    formState={createState}
    modalKey="create template group modal"
  >
    {children}
  </ModalForm>
);

export default localize<Props>(AddTemplateGroupFields);
