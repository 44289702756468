/* eslint-disable import/named */
import { ReactNode, PureComponent } from 'react';
import { Message } from '@oneflowab/pomes';
import { get } from 'lodash';

import { sendSignCode, SendSignCodeParams } from 'oneflow-client/agreements';
import CircularSpinner from 'components/icons/circular-spinner';
import { ApiError, getErrorMessage, unknownApiError } from 'components/api-error';

import ModalForm from 'hocs/modal-form';
import {
  PreviousButton,
} from 'components/buttons';
import GenericBankId from '../generic-bankid';

import style from './electronic-id-sign.module.scss';

export type SignCodeState = {
  error: any,
  success: boolean,
  loading: boolean,
  data: any
};

type State = {
  signCodeState: SignCodeState,
  signError: boolean,
  buttonDisabled: boolean
};

type ModalData = {
  ssn?: string,
  signMethodName?: string,
};

type RenderCustomEIDProps = {
  signCodeState: SignCodeState,
  changeButtonStatus: (status: boolean) => void,
  changeSignErrorStatus: () => void,
};

export type Props = {
  agreement: Oneflow.Agreement,
  guestToken?: string,
  myParticipantWhenSignatory: Oneflow.Participant,
  modalData: {
    ssn: string,
    signMethodName: string,
  } | ModalData,
  onPreviousStep: () => void,
  onStepComplete: () => void,
  onSuccess: () => void,
  onClose: () => void,
  renderCustomEID?: (props: RenderCustomEIDProps) => ReactNode,
  renderCustomErrorActions?: () => ReactNode,
  renderCustomActions?: () => ReactNode,
  title: string | ReactNode,
  modalKey: string,
};

export default class ElectronicIdSign extends PureComponent<Props, State> {
  static defaultProps = {
    guestToken: undefined,
  };

  state = {
    buttonDisabled: false,
    signError: false,
    signCodeState: {
      error: undefined,
      success: false,
      loading: false,
      data: null,
    },
  }

  componentDidMount() {
    this.fetchSignCode();
  }

  changeButtonStatus = () => (prop: boolean) => {
    this.setState({ buttonDisabled: prop });
  }

  changeSignErrorStatus = () => () => {
    const { signError } = this.state;
    this.setState({ signError: !signError });
  }

  getErrorActions = () => {
    const { renderCustomErrorActions, onPreviousStep } = this.props;
    const { buttonDisabled } = this.state;

    if (renderCustomErrorActions) {
      return renderCustomErrorActions();
    }

    return (
      <div className={style.Buttons}>
        <PreviousButton
          onClick={onPreviousStep}
          disabled={buttonDisabled}
        />
      </div>
    );
  }

  getActions = () => {
    const { signError, signCodeState: { error } } = this.state;
    const { renderCustomActions } = this.props;

    if (renderCustomActions) {
      return renderCustomActions();
    }

    if (error || signError) {
      return this.getErrorActions();
    }

    return null;
  };

  resetFormState = () => () => {
    this.setState({
      signCodeState: {
        error: undefined,
        success: false,
        loading: false,
        data: null,
      },
    });
  }

  getErrorMessage = (error: any) => {
    const errorCode = get(error, 'body.api_error_code');
    const errorMessage = getErrorMessage(errorCode);

    if (!errorMessage) {
      return unknownApiError;
    }

    return errorMessage;
  }

  renderApiError = () => {
    const { signCodeState } = this.state;
    if (!signCodeState || !signCodeState.error) {
      return null;
    }

    return (
      <ApiError customMessage={this.getErrorMessage(signCodeState.error)} />
    );
  };

  async fetchSignCode() {
    const { signCodeState } = this.state;
    const {
      agreement,
      myParticipantWhenSignatory,
      modalData: { ssn, signMethodName },
      guestToken,
    } = this.props;

    if (!myParticipantWhenSignatory) {
      return null;
    }

    const participantPhoneNumber = myParticipantWhenSignatory.phoneNumber;

    const params: SendSignCodeParams = {
      agreement,
      participantId: myParticipantWhenSignatory.id,
      phoneNumber: participantPhoneNumber || null,
      checksum: agreement.checksum,
      ssn,
      guestToken,
      signMethodName,
    };

    try {
      this.setState({
        signCodeState: {
          ...signCodeState,
          loading: true,
        },
      });
      const data = await sendSignCode(params);

      this.setState({
        signCodeState: {
          ...signCodeState,
          loading: false,
          data,
          // success: true,
        },
      });
    } catch (error: any) {
      this.setState({
        signCodeState: {
          ...signCodeState,
          loading: false,
          error,
        },
      });
    }

    return null;
  }

  renderBody() {
    const { signCodeState } = this.state;
    const {
      agreement,
      myParticipantWhenSignatory,
      onStepComplete,
      onSuccess,
      renderCustomEID,
    } = this.props;

    if (!signCodeState.data && !signCodeState.error) {
      return this.renderLoadingIcon();
    }

    if (renderCustomEID) {
      return (
        <>
          {signCodeState.error ? this.renderApiError() : renderCustomEID({
            signCodeState,
            changeButtonStatus: this.changeButtonStatus(),
            changeSignErrorStatus: this.changeSignErrorStatus(),
          })}
        </>
      );
    }

    return (
      <>
        <GenericBankId
          agreementId={agreement.id}
          changeButtonStatus={this.changeButtonStatus()}
          myParticipantWhenSignatory={myParticipantWhenSignatory}
          signCodeState={signCodeState}
          onStepComplete={onStepComplete}
          onSuccess={onSuccess}
          changeSignErrorStatus={this.changeSignErrorStatus()}
        />
        {signCodeState.error ? this.renderApiError() : null}
      </>
    );
  }

  renderLoadingIcon = () => (
    <div className={style.Loading}>
      <CircularSpinner />
    </div>
  );

  render() {
    const {
      myParticipantWhenSignatory, onClose, title, modalKey,
    } = this.props;

    if (!myParticipantWhenSignatory) {
      return null;
    }

    return (
      <ModalForm
        title={title || (
          <Message
            id="Sign with electronic ID"
            comment="Modal title for waiting to sign with electronic ID."
          />
        )}
        body={this.renderBody()}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => { }}
        actions={this.getActions}
        formState={{}}
        resetFormState={this.resetFormState}
        isOpen
        onClose={onClose}
        modalKey={modalKey}
      />
    );
  }
}
