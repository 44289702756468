import { createContext, useContext } from 'react';
import type { Dispatch, MutableRefObject, SetStateAction } from 'react';

export type MenuState = {
  id: string;
  isOpen?: boolean;
  activeItemId: string | null;
  subMenus: {
    [id: string]: boolean;
  }
}

export type MenuStateContextValue = {
  state: MenuState;
  listRef: MutableRefObject<HTMLDivElement | null>;
  setState: Dispatch<SetStateAction<MenuState>>;
}

export const createMenuStateContext = () => {
  const context = createContext<MenuStateContextValue | null>(null);
  const { Provider } = context;
  const useMenuStateContext = () => {
    const state = useContext(context);
    if (!state) {
      throw new Error('useMenuStateContext must be used within a MenuStateProvider');
    }
    return state;
  };
  return [Provider, context, useMenuStateContext] as const;
};
