// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';
import { getFormatter, formatPrice } from 'account';

import Modal from 'components/modal';
import Button from 'components/button';
import NewCheck from 'components/icons/new-check';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import style from './freemium-self-service-plans.module.scss';

type ModalData = {
  planName: string,
  pricePerSeat: number,
  planId: number
};

export type Props = {
  onClose: () => void,
  onStepComplete: () => void,
  onSyncStepData: ModalData => void,
  message: MessageTranslator,
  modalData: {} | ModalData,
  plans: Array<Plan>,
  languageCode: string,
  country: string,
  modalKey: string,
};

export const demoLinks = {
  default: 'https://oneflow.com/book-a-demo/',
  fr: 'https://oneflow.com/fr/obtenez-une-demo/',
};

export class FreemiumSelfServicePlansComponent extends React.Component<Props> {
  handleClick = (selectedPlan: ModalData) => () => {
    const { onSyncStepData, onStepComplete, modalData } = this.props;

    onSyncStepData({ ...modalData, ...selectedPlan });
    onStepComplete();
  }

  getPlan = (name: string) => this.props.plans.find((plan) => plan.name === name);

  getPlans = () => {
    const { message } = this.props;

    return [
      {
        planId: null,
        heading: null,
        planName: 'Free',
        description: null,
        bulletPoints: [
          message({
            id: 'Static documents (PDF)',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          message({
            id: 'E-signing',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
        ],
        pricePerSeat: 0,
        pricePerSeatMonthlySubscription: 0,
        currency: null,
      },
      {
        planId: this.getPlan('essentials').id,
        heading: null,
        planName: 'Essentials',
        description: message({
          id: 'Includes Free plan plus:',
          comment: 'Explaining what a certain price plan includes.',
        }),
        bulletPoints: [
          message({
            id: 'Digital documents',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          message({
            id: 'Templates',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          message({
            id: 'Workspaces',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
        ],
        pricePerSeat: this.getPlan('essentials').pricePerSeat,
        pricePerSeatMonthlySubscription: this.getPlan('essentials').pricePerSeatMonthlySubscription,
        currency: this.getPlan('essentials').currency,
      },
      {
        planId: this.getPlan('business').id,
        heading: message({
          id: 'Most popular',
          comment: 'Heading for most popular subscription',
        }),
        planName: 'Business',
        description: message({
          id: 'Includes Essentials plus:',
          comment: 'Explaining what a certain price plan includes.',
        }),
        bulletPoints: [
          message({
            id: 'Electronic IDs (EIDs)',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          message({
            id: 'Integrations',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          message({
            id: 'Data fields',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          message({
            id: 'Signing order',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          message({
            id: 'Workspace branding',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
        ],
        pricePerSeat: this.getPlan('business').pricePerSeat,
        pricePerSeatMonthlySubscription: this.getPlan('business').pricePerSeatMonthlySubscription,
        currency: this.getPlan('business').currency,
      },
      {
        planId: this.getPlan('enterprise').id,
        heading: null,
        planName: 'Enterprise',
        description: message({
          id: 'Includes Business plus:',
          comment: 'Explaining what a certain price plan includes.',
        }),
        bulletPoints: [
          message({
            id: 'Shared templates',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          message({
            id: 'Groups',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          'Single sign-on (SSO)',
          message({
            id: 'SCIM',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
          message({
            id: 'Replace contract',
            comment: 'Bullet point of what is included in a specific price plan.',
          }),
        ],
        pricePerSeat: this.getPlan('enterprise').pricePerSeat,
        pricePerSeatMonthlySubscription: this.getPlan('enterprise').pricePerSeatMonthlySubscription,
        currency: this.getPlan('enterprise').currency,
      },
    ];
  };

  renderHeading = (heading: ?MessageTranslator) => {
    if (!heading) {
      return null;
    }

    return <div className={style.Heading}>{heading}</div>;
  };

  renderPriceInformation = (pricePerSeat: number, currency: string) => {
    const { languageCode } = this.props;

    if (pricePerSeat === null) {
      return (
        <>
          <h1>
            <Message
              id="Let's talk"
              comment="Placeholder when there is no price information for the selected plan"
            />
          </h1>
          <p>&nbsp;</p>
        </>
      );
    }

    if (pricePerSeat > 0) {
      const formattedPrice = formatPrice(
        getFormatter(languageCode, currency),
        pricePerSeat,
      );

      return (
        <>
          <h1>{formattedPrice}</h1>
          <p>
            <Message
              id="/ month per user"
              comment="Price information for the selected plan"
            />
          </p>
        </>
      );
    }

    return (
      <>
        <h1>
          <Message
            id="Free"
            comment="Placeholder when a user is on a free plan."
          />
        </h1>
        <p>
          <Message
            id="forever"
            comment="Duration of the free pricing plan."
          />
        </p>
      </>
    );
  };

  renderButton = (
    planName: string,
    pricePerSeat: number,
    pricePerSeatMonthlySubscription: number,
    planId: number,
    currency: String,
  ) => {
    if (pricePerSeat === null) {
      return (
        <Button
          kind="primary"
          href="https://oneflow.com/contact/"
          external
          target="_blank"
          trackable="Go To Manual Sales"
        >
          <Message
            id="Contact us"
            comment="Text on the button used to select a subscription"
          />
        </Button>
      );
    }

    if (pricePerSeat > 0) {
      return (
        <Button
          kind="primary"
          onClick={this.handleClick({
            planName,
            pricePerSeat,
            pricePerSeatMonthlySubscription,
            planId,
            currency,
          })}
          trackable={{
            name: 'Choose Account Plan',
            props: {
              'plan name': planName,
            },
          }}
        >
          <Message
            id="Select Subscription Plan"
            comment="Text on the button used to select a subscription"
          />
        </Button>
      );
    }

    return (
      <Button
        kind="primary"
        disabled
      >
        <Message
          id="Current Subscription Plan"
          comment="Text explaining which price plan the user is on."
        />
      </Button>
    );
  };

  renderPlans = () => {
    const { plans } = this.props;

    if (!plans.length) {
      return null;
    }

    const mappedPlans = this.getPlans();

    return mappedPlans.map(({
      heading,
      planName,
      description,
      bulletPoints,
      pricePerSeat,
      pricePerSeatMonthlySubscription,
      currency,
      planId,
    }) => (
      <div className={style.Selection} key={planId}>
        {this.renderHeading(heading)}
        <h2>{planName}</h2>
        <div className={style.BulletPoints}>
          {description ? (<p>{description}</p>) : null}
          {bulletPoints.map((item) => (
            <div key={item} className={style.BulletPoint}>
              <span>
                <NewCheck height="12px" className={style.CheckMark} />
              </span>
              {item}
            </div>
          ))}
        </div>
        <div className={style.BottomContainer}>
          {this.renderPriceInformation(pricePerSeat, currency)}
          {this.renderButton(
            planName,
            pricePerSeat,
            pricePerSeatMonthlySubscription,
            planId,
            currency,
          )}
        </div>
      </div>
    ));
  };

  getFeatureGuideLink = () => (
    <a
      className={style.FooterLinks}
      target="_blank"
      rel="noreferrer"
      href="https://oneflow.com/pricing/#plan-comparison"
    >
      <Message
        id="complete feature guide"
        comment="Used as the text of help center button"
      />
    </a>
  );

  getBookMeetingLink = () => {
    const { languageCode, country } = this.props;
    const frenchCode = 'fr';

    let languageForDemoLink = languageCode;

    if (languageCode === frenchCode || country === frenchCode) {
      languageForDemoLink = frenchCode;
    }

    const selectedLink = demoLinks[languageForDemoLink] || demoLinks.default;

    return (
      <a
        className={style.FooterLinks}
        target="_blank"
        rel="noreferrer"
        href={selectedLink}
        onClick={() => {
          amplitudeLogEvent('Go To Book a Demo',
            { location: 'self purchase choose plan modal' });
        }}
      >
        <Message
          id="book a meeting with sales"
          comment="Link text for booking a meeting with sales"
        />
      </a>
    );
  }

  render() {
    const { onClose, modalKey } = this.props;

    return (
      <Modal
        header={(
          <Message
            id="Activate your Subscription Plan"
            comment="Message shown in upgrade account modal"
          />
        )}
        onCancel={onClose}
        hideFooter
        modalKey={modalKey}
      >
        <Modal.Body>
          <div className={style.Plans}>
            <h3>
              <Message
                id="Choose the Subscription Plan that's right for your team"
                comment="Modal text for upgrading account."
              />
            </h3>
            <div className={style.Container}>
              {this.renderPlans()}
            </div>
            <div className={style.FooterLinksContainer}>
              <Message
                id="Compare plans in {featureGuideLink} or {bookMeetingLink}."
                comment="Information links in the upgrade modal. The whole sentence will be read as: Compare plans in complete feature guide or book a meeting with sales."
                values={{
                  featureGuideLink: this.getFeatureGuideLink(),
                  bookMeetingLink: this.getBookMeetingLink(),
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default localize < Props >(FreemiumSelfServicePlansComponent);
