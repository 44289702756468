// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';
import extensionSettings from 'hocs/extension-settings';
import {
  composeValidators,
  numberValidator,
  minValue,
  maxValue,
} from 'forms/validators';

import Field from 'components/field';
import GeneratedToken from 'components/extension/generated-token';
import { ExtensionGenericInstructions } from 'components/extension/extension-generic-instructions';
import { ExtensionConfigurationHeader } from 'components/extension/extension-configuration-header';
import Button from 'components/button';
import TextField from 'components/text-field';
import Edit from 'components/icons/edit';

import style from './upsales.module.scss';

type Props = {
  extension: Extension,
  updateExtension: Extension => void,
  updateState: UpdateState,
  resetUpdateState: () => void,
  message: MessageTranslator,
};

type FormData = {
  customerId: string | null,
};

const CUSTOMER_ID_MIN = 1;
const CUSTOMER_ID_MAX = 4294967295;

export class UpsalesPage extends React.Component<Props> {
  validations = (({ message }) => ({
    customerId: composeValidators(
      numberValidator({
        message,
        field: message({
          id: 'Customer ID',
          comment: 'Used in validation of the customer ID field in Upsales settings.',
        }),
      }),
      minValue({
        message,
        field: message({
          id: 'Customer ID',
          comment: 'Used in validation of the customer ID field in Upsales settings.',
        }),
        limit: CUSTOMER_ID_MIN,
      }),
      maxValue({
        message,
        field: message({
          id: 'Customer ID',
          comment: 'Used in validation of the customer ID field in Upsales settings.',
        }),
        limit: CUSTOMER_ID_MAX,
      }),
    ),
  }))(this.props);

  handleSubmit = ({ customerId }: FormData) => {
    const { updateExtension, extension } = this.props;

    updateExtension({
      id: extension.extensionId,
      config: {
        ...extension.config,
        upsalesCustomerId: customerId,
      },
    });
  };

  renderChildren = (onClick: Function) => (
    <Button
      icon={Edit}
      kind="round"
      outline
      onClick={onClick}
    />
  );

  renderCustomerId() {
    const { upsalesCustomerId } = this.props.extension.config;

    if (!upsalesCustomerId) {
      return (
        <Message
          id="Not set"
          comment="Placeholder for setting customer ID for the Upsales extension."
        />
      );
    }

    return upsalesCustomerId;
  }

  renderBody() {
    const { message } = this.props;

    return (
      <div>
        <Field
          name="customerId"
          label={message({
            id: 'Customer ID',
            comment: 'Label for customer ID for the Upsales extension.',
          })}
          autoFocus
          component={TextField}
          placeholder={message({
            id: 'Enter number',
            comment: 'Placeholder for setting customer ID for the Upsales extension.',
          })}
          validate={this.validations.customerId}
        />
      </div>
    );
  }

  renderEditModal() {
    const {
      message,
      extension,
      updateState,
      resetUpdateState,
    } = this.props;

    return (
      <ModalForm
        title={message({
          id: 'Set Upsales customer ID',
          comment: 'Modal title for customer ID for Upsales.',
        })}
        body={this.renderBody()}
        onSubmit={this.handleSubmit}
        resetFormState={resetUpdateState}
        formState={updateState}
        initialValues={{
          customerId: extension.config.upsalesCustomerId,
        }}
        modalKey="set upsales customer id modal"
      >
        {this.renderChildren}
      </ModalForm>
    );
  }

  render() {
    const { extension } = this.props;

    return (
      <>
        <GeneratedToken extension={extension} />

        <ExtensionGenericInstructions extension={extension} />

        <ExtensionConfigurationHeader />

        <div className={style.CustomerId}>
          <span className={style.Label}>
            <Message
              id="Customer ID"
              comment="Customer ID label on the Upsales settings page."
            />
            :
          </span>
          {this.renderCustomerId()}
          {this.renderEditModal()}
        </div>
      </>
    );
  }
}

export default extensionSettings(UpsalesPage);
