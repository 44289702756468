import { connect } from 'react-redux';

import tagsReducer from 'reducers/entities/tags';
import tagConnectionsReducer from 'reducers/entities/tag-connections';
import { getAccountFromSessionSelector } from 'reducers/session';
import AddTag from './add-tag';

const QUERY_NAME = 'add-tags';
const TAGS_SELECTOR_QUERY_NAME = 'tags-selector';

export const mapStateToProps = (state, { blacklist }) => {
  const query = tagsReducer.getQuerySelector(state, { name: QUERY_NAME });
  const tags = tagsReducer.getTagsSelector(state, { ids: query.result });

  return {
    tags: tags.filter((tag) => !blacklist.includes(tag.id)),
    tagsBlacklisted: tags.filter((tag) => blacklist.includes(tag.id)),
    queryState: query,
    createState: tagConnectionsReducer.getCreateSelector(state),
    createTagState: tagsReducer.getCreateSelector(state),
    acl: {
      account: getAccountFromSessionSelector(state).acl || {},
    },
  };
};

const getCreateTagConnectionAction = (ownProps, tagId, pipe) => (
  tagConnectionsReducer.createTagConnection({
    data: {
      tagId,
      targetId: ownProps.targetId,
      targetType: ownProps.targetType,
      amplitudeScope: ownProps.amplitudeScope,
    },
    pipe: {
      ...pipe,
    },
  })
);

export const mapDispatchToProps = (dispatch, ownProps) => ({
  createTagAndTagConnection: (data) => dispatch(tagsReducer.createTag({
    data,
    pipe: {
      action: ({ data: { result: tagId } }) => {
        const { tagsSelectorQuery } = ownProps;

        if (tagsSelectorQuery.status === 'success') {
          return getCreateTagConnectionAction(ownProps, tagId, {
            action: () => tagsReducer.queryTagsReload({
              name: TAGS_SELECTOR_QUERY_NAME,
            }),
          });
        }

        return getCreateTagConnectionAction(ownProps, tagId);
      },
    },
  })),
  createTagConnection: (tag) => dispatch(getCreateTagConnectionAction(ownProps, tag.id)),
  queryTags: (name) => {
    dispatch(tagsReducer.queryTags({
      name: QUERY_NAME,
      params: {
        q: name,
      },
      pagination: {
        offset: 0,
        limit: 100,
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTag);
