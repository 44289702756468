import { useState } from 'react';
import {
  isEmpty,
  trim,
  truncate,
} from 'lodash';
import clsx from 'clsx';

import { isSmallScreenWidth } from 'ui/config';

import Message from 'components/message';

import {
  isContentTooLong,
  CONTENT_TRUNCATE_LENGTH,
  CONTENT_TRUNCATE_NEWLINE_COUNT,
  replaceNewLinesWithBreaks,
  showMoreLabel,
  showLessLabel,
} from './utils';

import style from './single-message-content.module.scss';

type AnnotationContent = {
  originalContent: string,
  suggestedContent: string,
}

const SuggestionContent = ({
  annotationContent,
  isInteractive,
}: {
  annotationContent: AnnotationContent, isInteractive: boolean,
}) => {
  const isSmallScreen = isSmallScreenWidth();
  const { originalContent, suggestedContent } = annotationContent;
  const [originalContentExpanded, setOriginalContentExpanded] = useState(false);
  const [suggestedContentExpanded, setSuggestedContentExpanded] = useState(false);

  let originalContentLabel;
  if (isEmpty(suggestedContent)) {
    originalContentLabel = <Message id="Delete" comment="Label of the text that has to be deleted" />;
  } else {
    originalContentLabel = <Message id="Change" comment="Label of the text that has to be changed" />;
  }

  const suggestedContentLabel = <Message id="To" comment="Label of the new text that has to be replacing deleted text" />;

  const isOriginalContentTooLong = isContentTooLong(originalContent);
  const isSuggestedContentTooLong = isContentTooLong(suggestedContent);

  let truncatedOriginalContent = truncate(originalContent, {
    length: CONTENT_TRUNCATE_LENGTH,
  });
  truncatedOriginalContent = trim(truncatedOriginalContent.split('\n', CONTENT_TRUNCATE_NEWLINE_COUNT).join('\n'));

  let truncatedSuggestedContent = truncate(suggestedContent, {
    length: CONTENT_TRUNCATE_LENGTH,
  });
  truncatedSuggestedContent = trim(truncatedSuggestedContent.split('\n', CONTENT_TRUNCATE_NEWLINE_COUNT).join('\n'));

  const renderShowMoreOriginalContent = () => (
    <button
      className={style.ExpandButton}
      onClick={(e) => {
        e.stopPropagation();
        setOriginalContentExpanded(!originalContentExpanded);
      }}
    >
      {originalContentExpanded ? showLessLabel : showMoreLabel}
    </button>
  );

  const renderShowMoreSuggestionContent = () => (
    <button
      className={style.ExpandButton}
      onClick={(e) => {
        e.stopPropagation();
        setSuggestedContentExpanded(!suggestedContentExpanded);
      }}
    >
      {suggestedContentExpanded ? showLessLabel : showMoreLabel}
    </button>
  );

  const renderOriginalContent = () => {
    if (isOriginalContentTooLong) {
      return (
        <>
          <div className={style.Italic}>
            {replaceNewLinesWithBreaks(
              originalContentExpanded ? originalContent : truncatedOriginalContent,
            )}
            {' '}
          </div>
          <div className={style.Button}>
            {isInteractive && renderShowMoreOriginalContent()}
          </div>
        </>
      );
    }

    return <span className={style.Italic}>{replaceNewLinesWithBreaks(originalContent)}</span>;
  };

  const renderSuggestedContent = () => {
    if (isSuggestedContentTooLong) {
      return (
        <>
          <div className={style.Italic}>
            {replaceNewLinesWithBreaks(
              suggestedContentExpanded ? suggestedContent : truncatedSuggestedContent,
            )}
            {' '}
          </div>
          <div className={style.Button}>
            {isInteractive && renderShowMoreSuggestionContent()}
          </div>
        </>
      );
    }

    return <span className={style.Italic}>{replaceNewLinesWithBreaks(suggestedContent)}</span>;
  };

  return (
    <div className={style.SuggestionContent}>
      <div className={style.OriginalContent}>
        <strong>
          {originalContentLabel}
          :
        </strong>
        &nbsp;
        {renderOriginalContent()}
      </div>
      {!isEmpty(suggestedContent) && (
      <div className={clsx(style.SuggestedContent, {
        [style.MobileSuggestedContent]: isSmallScreen && !isInteractive,
      })}
      >
        <strong>
          {suggestedContentLabel}
          :
        </strong>
        &nbsp;
        {renderSuggestedContent()}
      </div>
      )}
    </div>
  );
};

export default SuggestionContent;
