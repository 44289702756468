import * as RadixToast from '@radix-ui/react-toast';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';

import type React from 'react';

import ToastObserver from 'components/toasts/toast-observer';
import { useDrawerStore } from 'store/zustand-store/use-drawer-store';
import ToastItem from 'components/toasts/toast-item';
import { DEFAULT_OBSERVER_ID } from 'components/toasts/constants';
import type { Height, Toast, ToastPosition } from 'components/toasts/types';

import styles from './toast.module.scss';

const VIEWPORT_OFFSET = '1rem';
const TOAST_WIDTH = 496;
const GAP = 14;

type Props = {
  id?: string;
  position?: ToastPosition;
  // in case you want to offset the toasts from the corners
  viewportClassName?: string;
};

const ToastProvider = ({
  id = DEFAULT_OBSERVER_ID,
  position = 'bottom-left',
  viewportClassName,
}: Props) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [heights, setHeights] = useState<Height[]>([]);
  const drawerStates = useDrawerStore((state) => state.drawerStates);

  const removeToast = useCallback((toastId: Toast['id']) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== toastId));
  }, []);

  const [y, x] = position.split('-');

  useEffect(() => {
    ToastObserver.createInstance(id, setToasts);
    ToastObserver.flushQueuedToasts(id);

    if (Object.values(drawerStates).some(Boolean)) {
      setToasts([]);
    }

    return () => {
      ToastObserver.clearInstance(id);
    };
  }, [id, drawerStates]);

  return (
    <RadixToast.Provider duration={Infinity}>
      {toasts.map(({
        id: toastId,
        content,
        title,
        description,
        action,
        duration,
        reoccurred,
        remove,
        type,
      }) => (
        <ToastItem
          key={toastId}
          id={toastId}
          content={content}
          title={title}
          description={description}
          action={action}
          duration={duration}
          heights={heights}
          setHeights={setHeights}
          reoccurred={reoccurred}
          observerId={id}
          remove={remove}
          removeToast={removeToast}
          type={type}
        />
      ))}
      <RadixToast.Viewport
        tabIndex={-1}
        className={clsx(styles.ToastList, viewportClassName)}
        data-x-position={x}
        data-y-position={y}
        style={{
          '--offset': VIEWPORT_OFFSET,
          '--width': `${TOAST_WIDTH}px`,
          '--gap': `${GAP}px`,
        } as React.CSSProperties}
      />
    </RadixToast.Provider>
  );
};

export default ToastProvider;
