// @flow

import React from 'react';
import clsx from 'clsx';

import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import Cancel from 'components/icons/cancel';
import { RemoveConfirm } from 'components/modals/remove-confirm';
import CircularSpinner from 'components/icons/circular-spinner';
import WebIcon from 'components/icons/web';

import style from './contract-link-external-url.module.scss';

type Props = {|
  externalUrl: string,
  externalUrlTitle: string,
  onClick?: () => void,
  tabIndex?: string,
  canBeRemoved?: boolean,
  onRemove?: Function,
  isRemoving?: boolean,
  confirmState?: Function,
  resetRemoveState?: Function,
|};

const ContractLinkExternalUrl = ({
  externalUrl,
  externalUrlTitle,
  onClick,
  canBeRemoved,
  onRemove,
  resetRemoveState,
  confirmState,
  isRemoving,
  tabIndex = '0',
}: Props) => {
  const renderTitle = () => (
    <a
      href={externalUrl}
      target="_blank"
      rel="noopener noreferrer"
      title={externalUrl}
      className={style.ExternalLinkTitle}
    >
      {externalUrlTitle}
    </a>
  );

  const getChildren = (onClickRemove: Function) => {
    let icon = Cancel;
    if (isRemoving) {
      icon = CircularSpinner;
    }

    return (
      <Button
        icon={icon}
        onClick={onClickRemove}
        disabled={!canBeRemoved}
      />
    );
  };

  const renderRemoveButton = () => {
    if (!onRemove) {
      return null;
    }

    return (
      <RemoveConfirm
        onConfirm={onRemove}
        confirmState={confirmState}
        resetConfirmState={resetRemoveState}
        confirmMessage={(
          <>
            <span>
              <Message
                id="Are you sure you want to remove this link?"
                comment="Warning message in modal when attempting to remove a link from contract"
              />
            </span>
          </>
        )}
        modalKey="remove link from contract modal"
      >
        {getChildren}
      </RemoveConfirm>
    );
  };

  const externalUrlContainerClasses = clsx(style.ExternalLinkContainer, {
    [style.NonClickable]: !onClick,
  });

  return (
    <div
      className={externalUrlContainerClasses}
      onClick={onClick}
      role="button"
      onKeyDown={onClick}
      tabIndex={tabIndex}
    >
      <div className={style.ExternalLinkTitleContainer}>
        <WebIcon className={style.ExternalLinkIcon} height="16px" width="16px" />
        {renderTitle()}
      </div>
      {renderRemoveButton()}
    </div>
  );
};

export default ContractLinkExternalUrl;
