// @flow

/* eslint-disable no-param-reassign */
const withHorizontalLine = (editor: any) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => (
    element.type === 'horizontal-line' ? true : isVoid(element)
  );

  return editor;
};

export default withHorizontalLine;
