import { Message } from '@oneflowab/pomes';

import InviteUserIcon from 'components/icons/invite-user';
import Tags from 'components/tags';
import MoneyIcon from 'components/icons/money';
import State from 'components/document-information/state';

import style from './details.module.scss';

type Props = {
  document: Oneflow.Agreement,
}

const parseDocumentValue = (agreementValue: Oneflow.Agreement['agreementValue'], language?: string | null) => {
  if (!agreementValue?.amount || !agreementValue?.currency) {
    return '';
  }

  return agreementValue.amount.toLocaleString(language || 'en', {
    style: 'currency',
    currency: agreementValue.currency,
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
  });
};

export const Details = ({ document }: Props) => {
  const renderDocumentName = () => {
    if (document.name) {
      return document.name;
    }

    return <Message id="Untitled" comment="Placeholder title for untitled documents" />;
  };

  const flattenedParticipants: Oneflow.Participant[] = (document?.parties?.map(
    (party) => party?.participants?.map((participant) => participant),
  ).flat() as Oneflow.Participant[]) || [];

  return (
    <div>
      <div className={style.CardBody}>
        <div className={style.Header}>
          <span className={style.DocumentName}>
            {renderDocumentName()}
          </span>
        </div>
        <div className={style.PartyName}>
          <State agreement={document} isClickable={false} />
        </div>
        <hr className={style.VisualLine} />
        <div className={style.TitleIconContainer}>
          <InviteUserIcon height="10" className={style.InviteUserIcon} />
          <span className={style.SectionTitle}>
            <Message id="Participants" comment="Title of participants section" />
          </span>
        </div>
        {flattenedParticipants.slice(0, 5)?.map((participant) => (
          <div className={style.FullNameEmailContainer} key={participant.id}>
            <span className={style.FullName}>{participant?.fullname}</span>
            <span className={style.Email}>{participant?.email}</span>
          </div>
        ))}
        {flattenedParticipants.length > 5 && (
          <span className={style.MoreParticipants}>
            <Message
              id="+{count} more participants"
              comment="Number of participants that are not shown"
              values={{ count: flattenedParticipants.length - 5 }}
            />
          </span>
        )}
        {document.agreementValue && (
          <div className={style.ValueContainer}>
            <div className={style.TitleIconContainer}>
              <MoneyIcon height="10" className={style.MoneyIcon} />
              <span className={style.SectionTitle}>
                <Message id="Value" comment="Title of participants section" />
              </span>
            </div>
            <span className={style.Value}>
              {parseDocumentValue(document.agreementValue, document?.language)}
            </span>
          </div>
        )}
        {document.tags && document.tags.length > 0 && (
          <div className={style.TagsContainer}>
            <Tags
              targetId={document.id}
              targetType="agreement"
              allowTagChange={false}
              allowSearch={false}
              amplitudeScope="contract list"
            />
          </div>
        )}
        <div className={style.IdContainer}>
          <span className={style.Id}>
            {`ID ${document.id}`}
          </span>
        </div>
      </div>
    </div>
  );
};
