import { ReactNode, useState } from 'react';
import { Message } from '@oneflowab/pomes';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import positionsReducer from 'reducers/entities/positions';
import rolesReducer from 'reducers/entities/roles';
import workspaceUsersReducer from 'reducers/entities/workspace-users';
import workspacesReducer from 'reducers/entities/workspaces';
import { isUserLimited, USER_ROLE_SCOPE_WORKSPACE, USER_WORKSPACE_ROLE_READ_ONLY } from 'user';

import ModalForm from 'hocs/modal-form';
import Field from 'components/field';
import SelectField from 'components/select-field';

const defaultPagination = {
  limit: 10000,
  offset: 0,
};

export type AvailablePosition = {
  id: number,
  fullname: string,
}
export type FormData = {
  position: AvailablePosition,
  role: Oneflow.Role,
}

export type QueryAvailablePositionsFuncArgs = {
  fullname?: string,
  pagination?: typeof defaultPagination
}

export type LoadMoreArgs = {
  additionalResults: number
}

export type QueryRolesArgs = {
  name?: string
}

export type Props = {
  children: ReactNode,
  shouldRedirectToWorkspaceAccess: boolean,
  workspace: Oneflow.Workspace,
}

const USERS_QUERY_NAME = 'admin/users';

export function GrantUserAccess({
  workspace,
  children,
  shouldRedirectToWorkspaceAccess,
}: Props) {
  const dispatch = useDispatch();
  const [selectedPosition, setSelectedPosition] = useState<Oneflow.Position | null>(null);

  const availablePositionsQuery = useSelector((state) => positionsReducer.getQuerySelector(
    state,
    { name: USERS_QUERY_NAME },
  ));
  const queryName = `grant-access/${workspace.id}/user`;
  const rolesQuery = useSelector(
    (state) => rolesReducer.getQuerySelector(state, { name: queryName }),
  );
  const roles = useSelector(
    (state) => rolesReducer.getRolesSelector(state, { ids: rolesQuery.result }),
  );
  const formState = useSelector(workspaceUsersReducer.getCreateSelector);

  const availablePositions = useSelector((state) => positionsReducer.getPositionsSelector(state, {
    ids: availablePositionsQuery.result,
  }));

  const resetFormState = () => {
    dispatch(workspaceUsersReducer.createWorkspaceUserReset());
  };

  const onSubmit = ({ position, role }: FormData) => {
    const actionData = {
      data: {
        resourceType: 'collection',
        resourceId: workspace.id,
        actorType: 'position',
        actorId: position.id,
        roleId: role.id,
      },
      pipe: {
        onSuccess: () => {
          dispatch(workspaceUsersReducer.queryWorkspaceUsersReload({
            name: `workspace/${workspace.id}/relationships`,
          }));
          dispatch(workspacesReducer.fetchWorkspace({
            id: workspace.id,
            params: {
              accessStats: 1,
              includeAgreementStats: 1,
            },
          }));
        },
      },
    };

    if (shouldRedirectToWorkspaceAccess) {
      actionData.pipe.onSuccess = undefined;
      actionData.pipe.action = () => push(`/admin/workspaces/${workspace.id}/access`);
    }

    dispatch(workspaceUsersReducer.createWorkspaceUser(actionData));
  };

  const queryAvailablePositions = (
    { fullname, pagination = defaultPagination }: QueryAvailablePositionsFuncArgs,
  ) => {
    dispatch(positionsReducer.queryPositions({
      name: USERS_QUERY_NAME,
      params: {
        q: fullname,
        active: 1,
      },
      pagination,
    }));
  };

  const queryRoles = (
    { name }: QueryRolesArgs,
  ) => {
    dispatch(rolesReducer.queryRoles({
      name: queryName,
      params: {
        q: name,
        scope: USER_ROLE_SCOPE_WORKSPACE,
      },
      sort: ['-type', 'name'],
    }));
  };

  const runInitialQueries = () => {
    queryAvailablePositions({});
    queryRoles({});
  };

  const handleLoadMoreUsers = (additionalResults: number) => {
    dispatch(positionsReducer.queryPositionsLoadMore({
      name: USERS_QUERY_NAME,
      additionalResults,
    }));
  };

  const handleLoadMoreRoles = (additionalResults: number) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: queryName,
      additionalResults,
    }));
  };

  const handleUserInputChange = (fullname: string) => {
    queryAvailablePositions({
      fullname,
      pagination: availablePositionsQuery.pagination,
    });
  };

  const handleRoleInputChange = (name: string) => {
    queryRoles({
      name,
    });
  };

  const renderBody = () => {
    const roleFieldDisabledState = selectedPosition ? isUserLimited(selectedPosition) : false;
    const roleFieldDefaultValue = selectedPosition && isUserLimited(selectedPosition)
      ? roles.find((role) => role.id === USER_WORKSPACE_ROLE_READ_ONLY)
      : undefined;

    return (
      <>
        <Field
          component={SelectField}
          isLoading={availablePositionsQuery.loading}
          label={<Message id="User" comment="Input label" />}
          labelKey="fullname"
          loadMoreItems={handleLoadMoreUsers}
          name="position"
          onChange={setSelectedPosition}
          onInputChange={handleUserInputChange}
          options={availablePositions}
          placeholder={<Message id="Select user" comment="Input field placeholder" />}
          required
          valueKey="id"
        />
        <Field
          backspaceRemoves
          component={SelectField}
          defaultValue={roleFieldDefaultValue}
          disabled={roleFieldDisabledState}
          isLoading={rolesQuery.loading}
          label={<Message id="Role" comment="The label of the relevant field" />}
          labelKey="name"
          loadMoreItems={handleLoadMoreRoles}
          name="role"
          onInputChange={handleRoleInputChange}
          options={roles}
          placeholder={<Message id="Select role" comment="The placeholder for the relevant field" />}
          required
          valueKey="id"
        />
      </>
    );
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Grant user access"
          comment="Modal title for granting user access"
        />
      )}
      body={renderBody()}
      onSubmit={onSubmit}
      resetFormState={resetFormState}
      formState={formState}
      onOpen={runInitialQueries}
      modalKey="grant user access modal"
    >
      {children}
    </ModalForm>
  );
}

export default GrantUserAccess;
