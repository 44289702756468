// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import AddFolderOutlineIcon from 'components/icons/add-folder-outline';

import { MenuItem } from 'components/menu-item';

type Props = {
  disabled?: boolean,
  label?: React.Node,
  onClick: () => void,
}

const defaultLabel = (
  <Message
    id="Add subfolder"
    comment="Label for add folder action in menu."
  />
);

export const AddFolderMenuItem = ({
  disabled,
  label = defaultLabel,
  onClick,
}: Props) => (
  <MenuItem
    disabled={disabled}
    icon={AddFolderOutlineIcon}
    label={label}
    onClick={onClick}
  />
);
