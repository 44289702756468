// @flow

import React, { useEffect, useState } from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { isEqual, isEmpty } from 'lodash';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import agreementsReducer from 'reducers/entities/agreements';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';
import useFeatureFlag from 'hooks/use-feature-flag';
import usePrevious from 'hooks/use-previous';
import { useQueryStringParams } from 'hocs/query-string-params';
import { useFilterContext } from 'hocs/search/filter/context';
import { getFiltersValues } from 'hocs/search';

import { getSubFolders } from 'workspace/folders';

import { Acl } from 'components/acl';
import CircularSpinner from 'components/icons/circular-spinner';
import CreateContractIcon from 'components/icons/create-contract';
import NoContractsIcon from 'components/icons/no-contracts';
import FolderEmptyIcon from 'components/icons/folder-empty';
import { ContractCard } from 'components/contract-card';
import CreateContractButton from 'components/create-contract-button';
import EmptyState from 'components/empty-state';
import ExportContracts from 'components/export-contracts';
import Pagination from 'components/pagination';

import { SelectionContext } from './selection-context';
import { BulkActionsBar } from './bulk-actions-bar/bulk-actions-bar';
import style from './contract-list.module.scss';

type Props = {
  folders: Array<Folder>,
  message: MessageTranslator,
  onPaginate: (pagination: Pagination) => void,
  query: Query,
  queryName: string,
  result: Array<Agreement>,
  selectedFolderId: number,
  showWorkspaceName: boolean,
  withoutWorkspaceId: boolean,
  workspace: Workspace,
};

export const ContractListComponent = ({
  folders,
  message,
  onPaginate,
  query,
  queryName,
  result,
  selectedFolderId,
  showWorkspaceName,
  withoutWorkspaceId,
  workspace,
}: Props) => {
  const selectedFolder = folders.find((folder) => folder.id === selectedFolderId);
  const subFolders = getSubFolders(folders, selectedFolderId);
  const hasSubFolders = !isEmpty(subFolders);
  const isBulkActionsBarEnabled = useFeatureFlag('temporaryBulkMoveDelete');
  const [selectedDocuments, setSelectedDocuments] = useState({});
  const [lastSelectedDocument, setLastSelectedDocument] = useState(null);
  const { params } = useQueryStringParams();
  const { availableFilters } = useFilterContext();
  const previousWorkspace = usePrevious(workspace);

  const previousParams = usePrevious(params);

  useEffect(() => {
    const { queryParams: currentQueryParams } = getFiltersValues(params, availableFilters.current);
    const { queryParams: previousQueryParams } = getFiltersValues(
      previousParams || {},
      availableFilters.current,
    );

    if (
      !isEqual(previousQueryParams, currentQueryParams)
      || (!isEqual(previousWorkspace?.id, workspace?.id))
    ) {
      setSelectedDocuments({});
    }
  }, [availableFilters, params, previousParams, previousWorkspace?.id, workspace?.id]);

  const EmptySearchResultState = () => (
    <EmptyState
      withPadding
      icon={<NoContractsIcon height="33px" />}
      header={message({
        id: 'No documents found',
        comment: 'Empty state header. Shown in document list when no documents are found.',
      })}
      content={message({
        id: 'Please adjust your search criteria and try again.',
        comment: 'Empty state content. Shown in document list when no documents are found.',
      })}
      defaultStyle
    />
  );

  const dispatch = useDispatch();

  const createContract = (workspaceId) => {
    dispatch(agreementsReducer.createAgreement({
      data: {
        workspaceId,
        folderId: selectedFolderId,
      },
      pipe: {
        action: redirectToDocumentOnDocumentCreate,
        wait: agreementsReducer.CREATE_AGREEMENT_SUCCESS,
      },
    }));
  };

  const handlePaginationChange = (...args) => {
    onPaginate(...args);
    setSelectedDocuments({});
  };

  const renderEmptyState = () => {
    if (
      isEmpty(folders)
      && !query?.params?.q
      && !workspace.virtual
      && isEmpty(result)
      && selectedFolderId === -1
    ) {
      return (
        <EmptyState
          defaultStyle
          withPadding
          icon={<CreateContractIcon height="33px" />}
          header={message({
            id: 'No documents in this workspace',
            comment: 'Empty state header. Used in document list when there are no documents in the workspace.',
          })}
          content={(
            <div className={style.EmptyState}>
              <p className={style.Paragraph}>
                <Message
                  id="You can move documents from other workspaces or create a new one right here."
                  comment="Empty state text. Used in document list when there are no documents in the workspace."
                />
              </p>
              <CreateContractButton
                acl={Acl}
                createContract={createContract}
                isContractListView
                workspaceId={workspace.id}
                workspaceName={workspace.name}
              />
            </div>
          )}
        />
      );
    }

    if (query?.params?.q && query?.params?.q !== '') {
      return EmptySearchResultState();
    }

    return null;
  };

  const renderEmptyFolderState = () => {
    if (query?.params?.q && query?.params?.q !== '') {
      return EmptySearchResultState();
    }

    return (
      <EmptyState
        defaultStyle
        withPadding
        icon={<FolderEmptyIcon height="56px" />}
        header={message({
          id: 'Empty folder, endless possibilities!',
          comment: 'Empty state header. Used in document list when there is no documents in the folder.',
        })}
        content={(
          <div className={style.EmptyState}>
            <p className={style.Paragraph}>
              <Message
                id="You can move documents from other folders or create a new one right here."
                comment="Empty state text. Used in document list when there are no documents in the folder."
              />
            </p>
            <CreateContractButton
              acl={Acl}
              createContract={createContract}
              isContractListView
              selectedFolder={selectedFolder}
              workspaceId={workspace.id}
              workspaceName={workspace.name}
            />
          </div>
        )}
      />
    );
  };

  const renderSpinner = () => {
    if (!query.loading) {
      return null;
    }

    return <CircularSpinner className={style.LoadingSpinner} />;
  };

  const renderResult = () => {
    const containerClasses = clsx(style.ContractList, {
      [style.Loading]: query.loading,
    });

    if (
      !query.loading
      && isEmpty(result)
      && selectedFolderId !== -1
      && !hasSubFolders
    ) {
      return renderEmptyFolderState();
    }

    if (isEmpty(result) && !query.loading) {
      return renderEmptyState();
    }

    return (
      <div className={containerClasses}>
        {renderSpinner()}
        {result.map((agreement) => (
          <ContractCard
            agreement={agreement}
            key={agreement.id}
            queryName={queryName}
            showWorkspaceName={showWorkspaceName}
            withoutWorkspaceId={withoutWorkspaceId}
            showFolderPath={query?.params?.q}
            onClick={() => {
              amplitudeLogEvent('Open Document', {
                location: 'contract List',
                workspaceId: workspace.id,
              });
            }}
            selected={selectedDocuments[agreement.id]}
            onSelect={(id, event) => {
              if (event.shiftKey && lastSelectedDocument) {
                event.preventDefault();
                event.stopPropagation();

                const lastSelectedDocumentIndex = result.findIndex(
                  (_agreement) => _agreement.id === lastSelectedDocument,
                );
                const currentDocumentIndex = result.findIndex(
                  (_agreement) => _agreement.id === id,
                );

                const currentDocumentSelected = selectedDocuments[id];

                const start = Math.min(lastSelectedDocumentIndex, currentDocumentIndex);
                const end = Math.max(lastSelectedDocumentIndex, currentDocumentIndex);

                setSelectedDocuments((prevSelectedDocuments) => {
                  const newSelectedDocuments = { ...prevSelectedDocuments };
                  for (let i = start; i <= end; i += 1) {
                    newSelectedDocuments[result[i].id] = !currentDocumentSelected;
                  }

                  return newSelectedDocuments;
                });
              } else {
                setSelectedDocuments((prevSelectedDocuments) => ({
                  ...prevSelectedDocuments,
                  [id]: prevSelectedDocuments[id] === undefined ? true : !prevSelectedDocuments[id],
                }));
              }

              setLastSelectedDocument(id);
            }}
          />
        ))}
      </div>
    );
  };

  const renderTopPagination = () => {
    if (isEmpty(result) || query.loading) {
      return null;
    }

    return (
      <div className={style.TopPagination}>
        <span>
          <Message
            id="Documents"
            comment="Title for the document list"
          />
        </span>
        <Pagination
          currentOffset={query.pagination.offset}
          entityName={message({
            id: 'documents',
            comment: 'Document entity, to be used in a pagination info: Showing N of M documents',
          })}
          itemsPerPage={query.pagination.limit}
          onChange={handlePaginationChange}
          totalItems={query.count}
          renderWithoutPages
        />
      </div>
    );
  };

  const renderBulkActionsBar = () => {
    if (isEmpty(result) || !isBulkActionsBarEnabled) {
      return null;
    }

    return (
      <BulkActionsBar
        query={query}
        queryName={queryName}
        onSelectAllCheckboxChange={(event) => {
          setSelectedDocuments(result.reduce((acc, { id: agreementId }) => {
            acc[agreementId] = event.target.checked;
            return acc;
          }, {}));
          setLastSelectedDocument(null);
        }}
      />
    );
  };

  const renderBottomPagination = () => {
    if (isEmpty(result) || query.loading) {
      return null;
    }

    return (
      <Pagination
        currentOffset={query.pagination.offset}
        entityName={message({
          id: 'documents',
          comment: 'Document entity, to be used in a pagination info: Showing N of M documents',
        })}
        itemsPerPage={query.pagination.limit}
        onChange={handlePaginationChange}
        totalItems={query.count}
      />
    );
  };

  const renderExport = () => {
    if (query.loading) {
      return null;
    }

    return (
      <ExportContracts
        query={query}
        folderId={selectedFolderId}
        queryName={queryName}
        selectedFolder={selectedFolder}
        workspace={workspace}
      />
    );
  };

  return (
    <SelectionContext.Provider
      value={{
        selectedDocuments,
        setSelectedDocuments,
      }}
    >
      <div className={style.ContractListContainer}>
        {renderTopPagination()}
        {renderBulkActionsBar()}
        {renderResult()}
        <div className={style.PaginationContainer}>
          {renderBottomPagination()}
          <div className={style.ExportButton}>
            {renderExport()}
          </div>
        </div>
      </div>
    </SelectionContext.Provider>
  );
};

export default localize<Props>(ContractListComponent);
