import { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import TextField from 'components/text-field';

import style from './your-alternative.module.scss';

type Props = {
  setChosenAlternative: (value: string) => void;
  chosenAlternative: string;
  setHasTextFieldValue: (value: boolean) => void;
};

const alternatives = [
  {
    value: 'contractBook',
    label: 'Contractbook',
    id: 1,
  },
  {
    value: 'docuSign',
    label: 'Docusign',
    id: 2,
  },
  {
    value: 'getAccept',
    label: 'GetAccept',
    id: 3,
  },
  {
    value: 'simpleSign',
    label: 'SimpleSign',
    id: 4,
  },
  {
    value: 'scrive',
    label: 'Scrive',
    id: 5,
  },
  {
    value: 'youSign',
    label: 'Yousign',
    id: 6,
  },
  {
    value: 'pandaDoc',
    label: 'PandaDoc',
    id: 7,
  },
  {
    value: 'other',
    label:
  <Message
    id="Other"
    comment="Label for radio button"
  />,
    id: 8,
  },
];

const YourAlternative = ({
  setChosenAlternative,
  chosenAlternative,
  setHasTextFieldValue,
}: Props) => {
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const [otherRadioButtonChecked, setOtherRadioButtonChecked] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (otherRadioButtonChecked) {
      inputRef.current?.focus();
    }
  }, [otherRadioButtonChecked]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasTextFieldValue(true);
    setTextFieldValue(e.target.value);
    setChosenAlternative(e.target.value);
  };

  const onFocus = () => {
    setChosenAlternative(textFieldValue);
    setOtherRadioButtonChecked(true);
  };

  const textFieldClassNames = clsx({
    [style.Visible]: otherRadioButtonChecked,
    [style.Hidden]: textFieldValue === '',
  });

  const renderOtherAlternativeField = () => (
    <div className={textFieldClassNames}>
      <TextField
        input={{
          name: 'other',
          value: textFieldValue,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e),
        }}
        lengthLimit={50}
        customClass={style.TextField}
        labelCustomClass={style.Label}
        name="other"
        inputRef={inputRef}
        onFocus={onFocus}
      />
    </div>
  );

  const radioButtonOnChange = (value: string) => {
    if (value !== 'other') {
      setOtherRadioButtonChecked(false);
    }

    if (value === 'other') {
      setOtherRadioButtonChecked(true);
      return setChosenAlternative(textFieldValue);
    }

    return setChosenAlternative(value);
  };

  const checkedRadioButton = (value: string) => {
    if (value === 'other' && otherRadioButtonChecked) {
      return true;
    }

    return value === chosenAlternative;
  };

  return (
    <div>
      <p>
        <Message
          id="What will you be using instead?"
          comment="Label for the radio buttons asking the user what they will be using instead of Oneflow."
        />
      </p>
      <div className={style.RadioGroup}>
        {alternatives.map((alternative) => (
          <div key={alternative.id} className={style.RadioButton}>
            <label key={alternative.id} htmlFor={alternative.value}>
              <input
                type="radio"
                value={alternative.value}
                key={alternative.id}
                checked={checkedRadioButton(alternative.value)}
                onChange={(e) => radioButtonOnChange(e.target.value)}
              />
              {alternative.label}
            </label>
          </div>
        ))}
      </div>
      {renderOtherAlternativeField()}
    </div>
  );
};

export default YourAlternative;
