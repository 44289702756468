// @flow

import {
  Editor,
  Range,
  Transforms,
} from 'slate';
import isEmpty from 'lodash/isEmpty';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

function handleOperationOnEmptyListItem(params) {
  const {
    editor,
    childrenLength,
    listItem,
    parentPath,
  } = params;

  if (childrenLength !== 1) {
    Transforms.removeNodes(editor, {
      match: (node) => node === listItem,
    });
  }

  if (isEmpty(parentPath)) {
    return;
  }

  const [containerBlock] = Editor.parent(editor, parentPath);
  let type = 'paragraph';
  if (containerBlock && LIST_TYPES.includes(containerBlock.type)) {
    type = 'list-item';
  }

  const newParagraphPath = [...parentPath];
  newParagraphPath[newParagraphPath.length - 1] += 1;

  Transforms.insertNodes(editor, {
    type,
    children: [{ text: '' }],
  }, {
    at: newParagraphPath,
    select: true,
  });

  if (childrenLength === 1) {
    Transforms.removeNodes(editor, {
      at: parentPath,
    });
  }
}

/* eslint-disable no-param-reassign */
const withIndent = (editor: Editor) => {
  const { insertBreak, deleteBackward } = editor;

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.type === 'list-item',
        mode: 'lowest',
      });

      if (!match) {
        insertBreak();
        return;
      }

      const [listItem, listItemPath] = match;
      const [parentList, parentPath] = Editor.parent(editor, listItemPath);
      const childrenLength = parentList?.children?.length || 0;

      if (listItem
        && listItemPath[listItemPath.length - 1] === childrenLength - 1
        && Editor.isEmpty(editor, listItem)) {
        handleOperationOnEmptyListItem({
          editor,
          childrenLength,
          listItem,
          parentPath,
        });
        return;
      }

      if (Editor.isEnd(editor, selection.focus, listItemPath)) {
        const newListItemPath = [...listItemPath];
        newListItemPath[newListItemPath.length - 1] += 1;

        Transforms.insertNodes(editor, {
          ...listItem,
          type: 'list-item',
          children: [{ text: '' }],
        }, {
          at: newListItemPath,
          select: true,
        });
        return;
      }
    }

    insertBreak();
  };

  editor.deleteBackward = (unit) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === 'list-item',
      mode: 'lowest',
    });

    if (!match) {
      deleteBackward(unit);
      return;
    }

    const [listItem, listItemPath] = match;
    const [parentList, parentPath] = Editor.parent(editor, listItemPath);
    const childrenLength = parentList?.children?.length || 0;

    if (listItem
      && listItemPath[listItemPath.length - 1] === childrenLength - 1
      && Editor.isEmpty(editor, listItem)) {
      handleOperationOnEmptyListItem({
        editor,
        childrenLength,
        listItem,
        parentPath,
      });
      return;
    }

    deleteBackward(unit);
  };

  return editor;
};

export default withIndent;
