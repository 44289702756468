import clsx from 'clsx';
import { isEmpty } from 'lodash';

import {
  isAnySignedState,
  isSignedAndEndedAndTerminated,
  isCanceled,
  isActive,
  hasSinglePeriod,
  isSignedAndEnded,
  isAnyDeclinedState,
  isDraftApprovalFlowRunning,
  isDraft,
  isImport,
  isImportInProgress,
  isMarkedAsDeclined,
  isMarkedAsSigned,
  isOverdue,
  isPending,
  isTemplate,
} from 'agreement';
import ChevronRightIcon from 'components/icons/chevron-right';
import Message from 'components/message';
import isDraftApproved from 'agreement/actions/is-draft-approved';
import { AUDIT_TRAIL_TAB } from 'agreement/constants';

import { useExpandableSidebarProps } from 'components/document-layout-container/expanded-document-layout/context';
import { useCollapsedDocumentLayout } from 'components/document-layout-container/collapsed-document-layout/context';
import { useDispatch } from 'react-redux';
import { setAgreementSidebarActiveTabName } from 'reducers/app';
import style from './state.module.scss';
import TemplateState from './template-state';
import StatePill from './helper';

type Props = {
  agreement: Oneflow.Document,
  isClickable?: boolean,
}

const State = ({ agreement, isClickable = true }: Props) => {
  const stateClasses = clsx(style.State, {
    [style.StateFullWidth]: isEmpty(agreement.agreementValue),
  });

  const dispatch = useDispatch();

  const expandedLayout = useExpandableSidebarProps();
  const collapsedLayout = useCollapsedDocumentLayout();

  const onClick = () => {
    if (expandedLayout !== null) {
      dispatch(setAgreementSidebarActiveTabName(AUDIT_TRAIL_TAB));
      expandedLayout.setExpandedSidebar(true);
    }

    if (collapsedLayout !== null) {
      collapsedLayout.setActiveTab(AUDIT_TRAIL_TAB);
    }
  };

  const renderFirstState = () => {
    if (isMarkedAsSigned(agreement)) {
      return (
        <StatePill
          className={clsx(stateClasses, style.MarkedAsSigned)}
          onClick={onClick}
          isClickable={isClickable}
        >
          <Message
            id="Marked as signed"
            comment="Current document state"
          />
        </StatePill>
      );
    }

    if (isAnySignedState(agreement)) {
      return (
        <StatePill
          className={clsx(stateClasses, style.Signed)}
          onClick={onClick}
          isClickable={isClickable}
        >
          <Message
            id="Signed"
            comment="Current document state"
          />
        </StatePill>
      );
    }

    if (isMarkedAsDeclined(agreement)) {
      return (
        <StatePill
          className={clsx(stateClasses, style.MarkedAsDeclined)}
          onClick={onClick}
          isClickable={isClickable}
        >
          <Message
            id="Marked as declined"
            comment="Current document state"
          />
        </StatePill>
      );
    }

    if (isAnyDeclinedState(agreement)) {
      return (
        <StatePill
          className={clsx(stateClasses, style.Declined)}
          onClick={onClick}
          isClickable={isClickable}
        >
          <Message
            id="Declined"
            comment="Current document state"
          />
        </StatePill>
      );
    }

    if (
      (isPending(agreement) && !isImport(agreement))
      || (isDraftApproved(agreement) && isPending(agreement) && !isImport(agreement))
    ) {
      return (
        <StatePill
          className={clsx(stateClasses, style.Pending)}
          onClick={onClick}
          isClickable={isClickable}
        >
          <Message
            id="Pending"
            comment="Current document state"
          />
        </StatePill>
      );
    }

    if (isOverdue(agreement)) {
      return (
        <StatePill
          className={clsx(stateClasses, style.Overdue)}
          onClick={onClick}
          isClickable={isClickable}
        >
          <Message
            id="Overdue"
            comment="Current document state"
          />
        </StatePill>
      );
    }

    if (isDraft(agreement) && !isImport(agreement)) {
      return (
        <StatePill
          className={clsx(stateClasses, style.Draft)}
          onClick={onClick}
          isClickable={isClickable}
        >
          <Message
            id="Draft"
            comment="Current document state"
          />
        </StatePill>
      );
    }

    if (isImportInProgress(agreement)) {
      return (
        <StatePill
          className={clsx(stateClasses, style.ImportedDraft)}
          onClick={onClick}
          isClickable={isClickable}
        >
          <Message
            id="Imported draft"
            comment="Current document state"
          />
        </StatePill>
      );
    }

    if (isTemplate(agreement)) {
      return (
        <StatePill
          className={clsx(stateClasses, style.Template)}
          onClick={onClick}
          isClickable={isClickable}
        >
          <Message
            id="Template"
            comment="Current document state"
          />
        </StatePill>
      );
    }

    return null;
  };

  const renderSecondState = () => {
    if (isDraftApprovalFlowRunning(agreement) && !isDraftApproved(agreement)) {
      return (
        <>
          <ChevronRightIcon height="12px" className={style.RightArrowIconStyle} />
          <StatePill
            className={clsx(stateClasses, style.ApprovalPending)}
            onClick={onClick}
            isClickable={isClickable}
          >
            <Message
              id="Approval pending"
              comment="Current document state"
            />
          </StatePill>
        </>
      );
    }

    if (isDraftApproved(agreement) && isDraft(agreement)) {
      return (
        <>
          <ChevronRightIcon height="12px" className={style.RightArrowIconStyle} />
          <StatePill
            className={clsx(stateClasses, style.Approved)}
            onClick={onClick}
            isClickable={isClickable}
          >
            <Message
              id="Approved"
              comment="Current document state"
            />
          </StatePill>
        </>
      );
    }

    if (isTemplate(agreement)) {
      return (
        <TemplateState
          published={Boolean(agreement.config?.templateActive)}
          className={stateClasses}
          onClick={onClick}
          isClickable={isClickable}
        />
      );
    }

    // terminated
    if (isSignedAndEndedAndTerminated(agreement)) {
      return (
        <>
          <ChevronRightIcon height="12px" className={style.RightArrowIconStyle} />
          <StatePill
            className={clsx(stateClasses, style.Terminated)}
            onClick={onClick}
            isClickable={isClickable}
          >
            <Message
              id="Terminated"
              comment="Current document state"
            />
          </StatePill>
        </>
      );
    }

    // canceled
    if (isAnySignedState(agreement) && isCanceled(agreement)) {
      return (
        <>
          <ChevronRightIcon height="12px" className={style.RightArrowIconStyle} />
          <StatePill
            className={clsx(stateClasses, style.Canceled)}
            onClick={onClick}
            isClickable={isClickable}
          >
            <Message
              id="Canceled"
              comment="Current document state"
            />
          </StatePill>
        </>
      );
    }

    // active
    if (isAnySignedState(agreement) && isActive(agreement)) {
      return (
        <>
          <ChevronRightIcon height="12px" className={style.RightArrowIconStyle} />
          <StatePill
            className={clsx(stateClasses, style.Active)}
            onClick={onClick}
            isClickable={isClickable}
          >
            <Message
              id="Active"
              comment="Current document state"
            />
          </StatePill>
        </>
      );
    }

    // ended
    if (isSignedAndEnded(agreement) && hasSinglePeriod(agreement)) {
      return (
        <>
          <ChevronRightIcon height="12px" className={style.RightArrowIcon} />
          <StatePill
            className={clsx(stateClasses, style.Ended)}
            onClick={onClick}
            isClickable={isClickable}
          >
            <Message
              id="Ended"
              comment="Current document state"
            />
          </StatePill>
        </>
      );
    }

    return null;
  };

  return (
    <span className={style.StateContainer}>
      {renderFirstState()}
      {renderSecondState()}
    </span>
  );
};

export default State;
