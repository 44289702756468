import { Message } from '@oneflowab/pomes';

import CounterpartyPreferences from 'components/document-tabs/settings-tab/sections/counterparty-preferences';
import Accordion from '../accordion';
import style from './accordion.module.scss';

type Props = { agreementId: number };

const CounterpartyPreferencesSection = ({ agreementId }: Props) => {
  const title = (
    <Message
      id="Counterparty preferences"
      comment="Header for formatting section, where you choose font size, style and color."
    />
  );

  return (
    <Accordion title={title} name="counterpartyPreferences" contentClassName={style.Content}>
      <CounterpartyPreferences agreementId={agreementId} />
    </Accordion>
  );
};

export default CounterpartyPreferencesSection;
