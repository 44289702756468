import type { MessageTranslator } from '@oneflowab/pomes';
import type { ReactNode } from 'react';

import {
  BOX_ATTACHMENTS,
  BOX_AUDIT_TRAIL,
  BOX_COMMENTS,
  BOX_DURATION,
  BOX_FORM,
  BOX_PDF,
  BOX_PRODUCT_SUMMATION,
  BOX_PRODUCT_TABLE,
  BOX_TEXT_AND_IMAGE,
  BOX_VIDEO,
} from 'agreement/constants';

type BoxTypes = {
  [key: number]: ReactNode;
};

const boxTypes = (message: MessageTranslator): BoxTypes => ({
  [BOX_COMMENTS]: message({
    id: 'Comments',
    comment: 'Used as the name of the comments box.',
  }),
  [BOX_DURATION]: message({
    id: 'Duration',
    comment: 'Used as the name of the duration box.',
  }),
  [BOX_PDF]: message({
    id: 'PDF document',
    comment: 'Used as the name of the Pdf document box.',
  }),
  [BOX_ATTACHMENTS]: message({
    id: 'Attachments',
    comment: 'Used as the name of the attachments box.',
  }),
  [BOX_AUDIT_TRAIL]: message({
    id: 'Audit trail',
    comment: 'Used as the label of the Audit trail box.',
  }),
  [BOX_TEXT_AND_IMAGE]: message({
    id: 'Text and image',
    comment: 'Used as the label of the Text and image box.',
  }),
  [BOX_FORM]: message({
    id: 'Form',
    comment: 'Used as the label of the Form boc.',
  }),
  [BOX_PRODUCT_TABLE]: message({
    id: 'Product table',
    comment: 'Used as the label of the Product table box.',
  }),
  [BOX_PRODUCT_SUMMATION]: message({
    id: 'Product summation',
    comment: 'Used as the label of the Product summation box.',
  }),
  [BOX_VIDEO]: message({
    id: 'Video',
    comment: 'Used as the label of the Video box.',
  }),
});

const allTypes: ContractView.BoxType[] = [
  BOX_TEXT_AND_IMAGE,
  BOX_VIDEO,
  BOX_PRODUCT_TABLE,
  BOX_FORM,
  BOX_DURATION,
  BOX_PDF,
  BOX_ATTACHMENTS,
  BOX_PRODUCT_SUMMATION,
];

export const boxTypesArray = (message: MessageTranslator) => {
  const typeLabels = boxTypes(message);

  return allTypes.map((type: ContractView.BoxType) => ({
    type,
    label: typeLabels[type],
  }));
};

export default boxTypes;
