import { isEmpty } from 'lodash';

import type { Box } from 'data-validators/entity-schemas/document-box';

import { checkAcl } from 'components/acl';

import {
  isNewBox,
  isNewBoxData,
} from './selectors';
import type { BoxData } from './permission-types';

export const isBoxDataSharedValueUpdateAllowed = (
  box: Box,
  data?: BoxData,
) => {
  if (isNewBox(box) || isEmpty(data) || isNewBoxData(data)) {
    return true;
  }

  return checkAcl(data.acl, 'agreementdata:shared_value:update');
};
