import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import { checkAcl } from 'components/acl';
import useAgreement from 'hooks/use-agreement';
import {
  getAccountFromSessionSelector,
} from 'reducers/session';
import agreementsReducer from 'reducers/entities/agreements';
import {
  isAgreementOwner,
  getAgreementMyParticipant,
  getSignedParticipants,
  getMyParticipantWhenSignatory,
} from 'agreement/selectors';

import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';
import NewCross from 'components/icons/new-cross';
// eslint-disable-next-line import/named
import { RemoveConfirm } from 'components/modals/remove-confirm';
import { MenuItem } from 'components/menu-item';
import { InfoBox } from 'components/error-box';

import style from './mark-as-declined.module.scss';

export type Props = {
  message: MessageTranslator,
  onSuccess?: () => void,
  agreementId: number,
  guestToken?: string,
  disabled?: boolean,
  children?: (onClick: React.MouseEventHandler) => React.ReactNode,
};

const MarkAsDeclinedComponent = ({
  message,
  children,
  disabled,
  onSuccess,
  guestToken,
  agreementId,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const hasAccessToDecline = checkAcl(agreement.acl, 'agreement:decline');
  const myParticipant = getAgreementMyParticipant(agreement);
  const isMyParticipantSignatory = Boolean(getMyParticipantWhenSignatory(agreement));
  const accountFromSession = useSelector(getAccountFromSessionSelector);
  const rpcState = useSelector(
    (state) => agreementsReducer.getDeclineAgreementSelector(state, { id: agreement.id }),
  );
  const isOwner = isAgreementOwner(accountFromSession, agreement);
  const hasSomeoneSigned = Boolean(getSignedParticipants(agreement).length);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!onSuccess) {
      return;
    }

    if (rpcState.success) {
      onSuccess();
    }
  }, [rpcState.success, onSuccess]);

  const declineAgreement = (myParticipantIdWhenUpdate: number) => {
    dispatch(agreementsReducer.declineAgreement({
      id: agreementId,
      data: {
        participantId: myParticipant?.id || myParticipantIdWhenUpdate,
        checksum: agreement.checksum || undefined,
        guestToken: guestToken || undefined,
      },
    }));
  };

  const resetRpcState = () => {
    dispatch(agreementsReducer.declineAgreementReset({
      id: agreementId,
    }));
  };

  const handleConfirm = () => {
    if (!myParticipant?.id || rpcState.loading) {
      return;
    }

    declineAgreement(myParticipant?.id);
  };

  const getDeclineActions = () => (
    <Button
      icon={rpcState.loading ? CircularSpinner : null}
      disabled={rpcState.loading}
      onClick={handleConfirm}
      color="red"
    >
      {!isOwner
        ? (
          <Message
            id="Decline"
            comment="Text for button to confirm action."
          />
        ) : (
          <Message
            id="Mark as declined"
            comment="Text for button to confirm action."
          />
        )}
    </Button>
  );

  const getChildren = (onClick: React.MouseEventHandler) => {
    if (children) {
      return children(onClick);
    }

    const isDisabled = disabled || !hasAccessToDecline;

    return (
      <MenuItem
        icon={NewCross}
        label={message({
          id: 'Mark as declined',
          comment: 'Action to mark a contract as declined.',
        })}
        disabled={isDisabled}
        onClick={onClick}
        className={!isDisabled ? style.MarkAsDeclinedMenuItem : undefined}
      />
    );
  };

  const renderNotSignatoryInfoBox = () => {
    if (isMyParticipantSignatory) {
      return null;
    }

    return (
      <InfoBox
        headerText={(
          <Message
            id="You are not a signatory"
            comment="Info box header in mark as declined modal."
          />
        )}
        bodyText={(
          <Message
            id="Performing this action means that you are marking this contract as declined on behalf of those that actually declined it."
            comment="Info box text in mark as declined modal."
          />
        )}
      />
    );
  };

  const renderPartlySignedInfoBox = () => {
    if (!hasSomeoneSigned) {
      return null;
    }

    return (
      <InfoBox
        headerText={(
          <Message
            id="This contract is partly signed"
            comment="Info box header in mark as declined modal."
          />
        )}
        bodyText={(
          <Message
            id="One or more participants have already signed the contract."
            comment="Info box text in mark as declined modal."
          />
        )}
      />
    );
  };

  const renderBody = () => {
    if (!isOwner) {
      return (
        <>
          <p>
            <Message
              id="Please confirm that you want to decline the contract."
              comment="Explaning text in confirmation modal for declining a contract from guest view."
            />
          </p>
          <p>
            <Message
              id="Declining will affect the contract as a whole, preventing any further changes or signatures by any party. This action can't be undone."
              comment="Explaning text in confirmation modal when attempting to decline a contract from guest view."
            />
          </p>
        </>
      );
    }

    return (
      <>
        <p>
          <Message
            id="A declined contract can't be updated or signed. No notifications will be sent to your counterparties about this action."
            comment="Explaning text in confirmation modal for declining a contract."
          />
        </p>
        {renderNotSignatoryInfoBox()}
        {renderPartlySignedInfoBox()}
        <p className={style.ConfirmText}>
          <Message
            id="Are you sure you want to mark this contract as declined?"
            comment="Warning message in confirmation modal when attempting to decline a contract."
          />
        </p>
      </>
    );
  };

  return (
    <RemoveConfirm
      onConfirm={handleConfirm}
      confirmState={rpcState}
      resetConfirmState={resetRpcState}
      confirmMessage={renderBody()}
      renderButton={getDeclineActions}
      modalKey="mark contract as declined modal"
    >
      {getChildren}
    </RemoveConfirm>
  );
};

export const MarkAsDeclined = localize(MarkAsDeclinedComponent);
