import hasDeliveryChannelEmail from './has-delivery-channel-email';
import hasDeliveryChannelSms from './has-delivery-channel-sms';
import hasDeliveryChannelEmailAndSms from './has-delivery-channel-email-and-sms';

// This one is scary with the addition of hasDeliveryChannelEmailAndSms
const hasDeliveryChannelExternal = (participant) => (
  hasDeliveryChannelEmail(participant)
  || hasDeliveryChannelSms(participant)
  || hasDeliveryChannelEmailAndSms(participant)
);

export default hasDeliveryChannelExternal;
