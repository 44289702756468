import { join } from 'lodash';

import styles from './contract-id.module.scss';

type Props = {
  agreementId: number;
};

const ContractID = ({ agreementId }: Props) => (
  <small className={styles.ContractId}>
    {join(['ID', agreementId], ' ')}
  </small>
);

export default ContractID;
