// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { MenuItem } from 'components/menu-item';
import ShareIcon from 'components/icons/share';

export type Props = {|
  disabled?: boolean,
  onClick: Function,
|};

export const ShareMenuItem = ({
  disabled,
  onClick,
}: Props) => (
  <MenuItem
    icon={ShareIcon}
    label={(
      <Message
        id="Share"
        comment="Label for share action in menu."
      />
    )}
    disabled={disabled}
    onClick={onClick}
  />
);

ShareMenuItem.defaultProps = {
  disabled: undefined,
};
