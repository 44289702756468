import { useState } from 'react';
import Message from 'components/message';
import Button from 'components/button';
import CancelSubscriptionFlow from 'components/cancel-subscription/flows/cancel-subscription-flow';
import CancelSubscriptionWithFeedback from 'components/modals/cancel-subscription/cancel-subscription-with-feedback';
import CancelSubscriptionSimple from 'components/modals/cancel-subscription/cancel-subscription-simple';
import SubscriptionCancelled from 'components/modals/cancellation/subscription-cancelled';

import type { FlowData } from 'types/cancellation-flow';

type Props = {
  onClose: () => void;
  onPreviousStep: () => void;
  onSubmit: (
    data: FlowData,
    submitHandler: () => void,
    submitErrorHandler: () => void,
  ) => void;
  onSyncFlowData: (data: FlowData) => void;
  setCurrentFlow: (flow: string) => void;
  variant: string;
};

const ConfirmCancel = ({
  onClose,
  onPreviousStep,
  onSubmit,
  onSyncFlowData,
  setCurrentFlow,
  variant,
}: Props) => {
  const [currentComment, setCurrentComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const renderBody = () => {
    if (variant === 'feedbackNotSad') {
      return (
        <CancelSubscriptionWithFeedback
          setCurrentComment={setCurrentComment}
        />
      );
    }
    if (variant === 'feedback') {
      return (
        <CancelSubscriptionWithFeedback
          setCurrentComment={setCurrentComment}
          weAreSad
        />
      );
    }

    return (
      <CancelSubscriptionSimple />
    );
  };

  const renderError = () => {
    if (!hasError) {
      return null;
    }

    return (
      <CancelSubscriptionFlow.SubmitError />
    );
  };

  return (
    <CancelSubscriptionFlow
      onCancel={onClose}
      setFlow={setCurrentFlow}
      modalKey="confirm-cancel"
    >
      <CancelSubscriptionFlow.Steps>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="Cancel Subscription Plan"
              comment="Header in the cancel subscription modal"
            />
          )}
          handlePreviousStep={onPreviousStep}
          allowContinue={!isLoading}
        >
          <CancelSubscriptionFlow.StepBody>
            {renderBody()}
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            {renderError()}
            <Button
              kind="linkSeparate"
              onClick={onClose}
            >
              <Message
                id="Keep my Subscription Plan"
                comment="Button text for keeping the subscription plan"
              />
            </Button>
            <CancelSubscriptionFlow.Submit
              kind="primary"
              color="red"
              syncData={{
                message: currentComment,
                book_time: false,
                accepted_help: false,
                chosen_alternative: '',
                offer_discount: false,
              }}
              onSubmit={
                (modalData: FlowData, stepCompleteHandler: () => void) => {
                  setIsLoading(true);
                  setHasError(false);
                  const submitDoneHandler = () => {
                    setIsLoading(false);
                    stepCompleteHandler();
                  };
                  const submitErrorHandler = () => {
                    setIsLoading(false);
                    setHasError(true);
                  };
                  onSubmit(modalData, submitDoneHandler, submitErrorHandler);
                }
              }
              onSyncFlowData={onSyncFlowData}
            >
              <Message
                id="Confirm cancellation"
                comment="Button text to confirm cancellation"
              />
            </CancelSubscriptionFlow.Submit>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="Subscription Plan canceled"
              comment="Header in the cancellation reasons modal"
            />
          )}
          allowContinue
          hidePrevious
        >
          <CancelSubscriptionFlow.StepBody>
            <SubscriptionCancelled />
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            <CancelSubscriptionFlow.Close
              kind="primary"
            >
              <Message
                id="Close"
                comment="Header in the cancellation reasons modal"
              />
            </CancelSubscriptionFlow.Close>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
      </CancelSubscriptionFlow.Steps>
    </CancelSubscriptionFlow>
  );
};

export default ConfirmCancel;
