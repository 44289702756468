import style from './guest-topbar.module.scss';

const GuestTopBar = ({ logoUrl, companyName }: {logoUrl?: string | null, companyName: string}) => {
  if (logoUrl) {
    return (
      <img
        className={style.Logo}
        src={logoUrl}
        alt="Company logo"
        height={48}
        data-testid="company-logo"
      />
    );
  }

  return (
    <p
      className={style.CompanyName}
      data-testid="company-name"
    >
      {companyName}
    </p>
  );
};

export default GuestTopBar;
