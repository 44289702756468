import * as zod from 'zod';

import Entity, { NullableNumberIdSchema, NullableStringIdSchema } from './entity';

import { aclValue } from './common';

import {
  dateSchema,
  timestampSchema,
  integerBoolean,
} from '../utils';

export const accountAclSchema = zod.object({
  'account:admin': aclValue.optional(),
  'account:admin:audit_log': aclValue.optional(),
  'account:admin:billing': aclValue.optional(),
  'account:admin:branding': aclValue.optional(),
  'account:admin:data_management': aclValue.optional(),
  'account:admin:extension': aclValue.optional(),
  'account:admin:group': aclValue.optional(),
  'account:admin:role': aclValue.optional(),
  'account:admin:tag': aclValue.optional(),
  'account:admin:user': aclValue.optional(),
  'account:admin:workspace': aclValue.optional(),
  'account:cancellation:create': aclValue.optional(),
  'account:marketplace': aclValue.optional(),
  'account:audit_log:view': aclValue.optional(),
  'account:billing:update': aclValue.optional(),
  'account:billing:view': aclValue.optional(),
  'account:collection:create': aclValue.optional(),
  'account:collection:view': aclValue.optional(),
  'account:export': aclValue.optional(),
  'account:group:create': aclValue.optional(),
  'account:group:view': aclValue.optional(),
  'account:order:create': aclValue.optional(),
  'account:permission:view': aclValue.optional(),
  'account:position:create': aclValue.optional(),
  'account:position:export': aclValue.optional(),
  'account:position:view': aclValue.optional(),
  'account:account_role:create': aclValue.optional(),
  'account:workspace_role:create': aclValue.optional(),
  'account:role:view': aclValue.optional(),
  'account:update:branding': aclValue.optional(),
  'account:update:data_management': aclValue.optional(),
});

export const accountFeatureStatus = zod.object({ enabled: integerBoolean });

export const agreementExportFeatureStatus = accountFeatureStatus.extend({
  limit: zod.number().optional(),
});

export const accountFeaturesSchema = zod.object({
  accountColleagueAgreementWins: accountFeatureStatus.optional(),
  agreementCancelRecurring: accountFeatureStatus.optional(),
  agreementExport: agreementExportFeatureStatus.optional(),
  agreementImport: accountFeatureStatus.optional(),
  agreementOneflowAd: accountFeatureStatus.optional(),
  analytics: accountFeatureStatus.optional(),
  contractReadHistory: accountFeatureStatus.optional(),
  extensionBankid: accountFeatureStatus.optional(),
  extensionDemo: accountFeatureStatus.optional(),
  extensionLime: accountFeatureStatus.optional(),
  extensionNeocase: accountFeatureStatus.optional(),
  extensionPaxml: accountFeatureStatus.optional(),
  replaceContract: accountFeatureStatus.optional(),
  signLater: accountFeatureStatus.optional(),
  temporaryFreshchat: accountFeatureStatus.optional(),
  temporaryPositionLimited: accountFeatureStatus.optional(),
  temporaryPositionNoUser: accountFeatureStatus.optional(),
  temporarySingleSign: accountFeatureStatus.optional(),
  temporaryDocumentPreview: accountFeatureStatus.optional(),
  workspaceFolder: accountFeatureStatus.optional(),
});

export const accountConfig = NullableStringIdSchema.describe('accountConfig');
const accountPlan = zod.object({
  name: zod.enum(['freemium', 'essentials', 'enterprise', 'business']).nullable().optional(),
});

export const accountIntegrationSchema = Entity.extend({
  // account: null, account: { id: null }, account: undefined | missing are all validated true
  account: NullableNumberIdSchema.nullable().optional(),
  config: accountConfig.optional(),
  created: dateSchema.nullable().optional(),
  createdTs: timestampSchema.nullable().optional(),
  description: zod.string().nullable().optional(),
  name: zod.string().nullable().optional(),
  extensionClass: zod.string().nullable().optional(),
  key: zod.string().optional(),
  setupUrl: zod.string().url().nullable().optional(),
  state: zod.number().nullable().optional(),
  type: zod.number().optional(),
  updated: dateSchema.nullable().optional(),
  updatedTs: timestampSchema.nullable().optional(),
});

const Account = Entity.extend({
  acl: accountAclSchema.optional(),
  brandingDateFormat: zod.string(),
  country: zod.string().nullable().optional(),
  created: dateSchema.nullable().optional(),
  createdTs: timestampSchema.nullable().optional(),
  isCustomer: zod.boolean().nullable().optional(),
  isDemo: zod.boolean().nullable().optional(),
  isFreemium: zod.boolean().nullable().optional(),
  logoUrl: zod.string().url().nullable().optional(),
  name: zod.string().nullable().optional(),
  onboardingStage: zod.string().nullable().optional(),
  orgnr: zod.string().nullable().optional(),
  phoneNumber: zod.string().nullable().optional(),
  trial: zod.boolean().nullable().optional(),
  trialEndTimestamp: dateSchema.nullable().optional(),
  trialEndTimestampTs: timestampSchema.nullable().optional(),
  updated: dateSchema.nullable().optional(),
  updatedTs: timestampSchema.nullable().optional(),
  plan: accountPlan.nullable().optional(),
  showPaymentReminderBanner: zod.boolean().nullable().optional(),
  // Extentable sub schema props
  /*
  features: accountFeaturesSchema.optional(),
  integrations: zod.array(accountIntegrationSchema).optional(),
  */
}).describe('account');

export default Account;
