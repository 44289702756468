// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  formatDateString,
  getDaysUntil,
} from 'date';

import CalendarUnderscore from 'components/icons/calendar-underscore';

import Insight from './insight';

import style from './insight.module.scss';

type Props = {
  startTime?: string,
  dateFormat: string,
};

const Awaiting = ({ startTime, dateFormat }: Props) => {
  const renderInsight = () => {
    if (!startTime) {
      return <Message id="Awaiting" comment="Insight for signed agreement. Visible in contract card." />;
    }

    const daysUntil = getDaysUntil(startTime);
    const dateString = formatDateString(startTime, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    if (daysUntil > 7) {
      return (
        <Message
          id="Starts {date}"
          values={{
            date,
          }}
          comment="Insight for signed agreement, stating when agreement starts. Visible in contract card."
        />
      );
    }

    if (daysUntil > 0) {
      return (
        <Message
          id="Starts tomorrow ({date})"
          pluralId="Starts in {days} days ({date})"
          pluralCondition="days"
          values={{
            days: daysUntil,
            date,
          }}
          comment="Insight for signed agreement, stating when agreement starts. Visible in contract card."
        />
      );
    }

    return null;
  };

  return (
    <Insight
      IconComponent={CalendarUnderscore}
      insight={renderInsight()}
    />
  );
};

export default Awaiting;
