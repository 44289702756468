import { useState } from 'react';
import { Message } from '@oneflowab/pomes';
import Button from 'components/button';

import FlowManager from 'components/cancel-subscription/flow-manager';

import style from './cancellation.module.scss';

// modalKeys for amplitude tracking on Exit Modal events
const LOST_FEATURES_MODAL = 'lost features modal';
const CANCELLATION_REASON_MODAL = 'cancellation reason modal';

const CancellationFlowComponent = () => {
  const [showCancellationModal, setShowCancellationModal] = useState<boolean>(false);

  const handleCloseClick = () => {
    setShowCancellationModal(false);
  };

  const handleOpenClick = () => {
    setShowCancellationModal(true);
    // Add amplitudeLogEvent
  };

  return (
    <>
      {showCancellationModal && (
        <FlowManager
          onClose={handleCloseClick}
        />
      )}
      <Button
        kind="link"
        color="forest-green"
        onClick={handleOpenClick}
        customClass={style.CancelButton}
      >
        <Message
          id="Cancel Subscription Plan"
          comment="Button to cancel subscription plan"
        />
      </Button>
    </>
  );
};

export default CancellationFlowComponent;
