// @flow

import * as React from 'react';

import withDatePresets from 'hocs/with-date-presets';

import DatePreset, { TodayPreset } from 'components/date-preset';

export const DateRangePresetsComponent = () => (
  <>
    <DatePreset last={30} type="day" data-testid="lastMonth" />
    <DatePreset last={7} type="day" data-testid="lastWeek" />
    <TodayPreset data-testid="today" />
    <DatePreset next={7} type="day" data-testid="nextWeek" />
    <DatePreset next={30} type="day" data-testid="nextMonth" />
  </>
);

export default withDatePresets<{}>(DateRangePresetsComponent);
