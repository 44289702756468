import { useMemo } from 'react';
import { noop } from 'lodash';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import GlobeIcon from 'components/icons/public';
import SelectField from 'components/select-field';
import { getCountryListAsOptions } from 'components/countries';
import { getUniqueCountries } from '../../routes/marketplace/marketplace-settings/electronic-ids/electronic-ids-eid-hub-items';

import SeFlag from './flags/se';
import BeFlag from './flags/be';
import UaFlag from './flags/ua';
import NoFlag from './flags/no';
import NlFlag from './flags/nl';
import GbFlag from './flags/gb';
import LtFlag from './flags/lt';
import LvFlag from './flags/lv';
import EeFlag from './flags/ee';
import DkFlag from './flags/dk';
import FiFlag from './flags/fi';

import style from './eid-country-picker.module.scss';

const countryFlagMap: Record<string, JSX.Element> = {
  SE: <SeFlag />,
  BE: <BeFlag />,
  UA: <UaFlag />,
  NO: <NoFlag />,
  NL: <NlFlag />,
  GB: <GbFlag />,
  LT: <LtFlag />,
  LV: <LvFlag />,
  EE: <EeFlag />,
  DK: <DkFlag />,
  FI: <FiFlag />,
};

type Props = {
  currentLanguage: string,
  message: MessageTranslator,
  selectedCountry: string,
  onCountryChange: (country: string) => void,
  disabled?: boolean,
  availableCountries?: string[],
};

const CountryPicker = ({
  currentLanguage,
  message,
  selectedCountry,
  onCountryChange,
  disabled = false,
  availableCountries,
}: Props) => {
  const countryList = useMemo(() => {
    const list = getCountryListAsOptions(currentLanguage);

    const euOption = {
      label: (
        <div className={style.Option}>
          <span><GlobeIcon /></span>
          {message({ id: 'EU/EEA', comment: 'Country select field option' })}
        </div>
      ),
      value: 'EU',
    };

    list.unshift(euOption);

    const filteredCountriesList = availableCountries || getUniqueCountries();

    return list
      .filter((country) => filteredCountriesList.includes(country.value))
      .map((country) => ({
        value: country.value,
        label: (
          <div className={style.Option}>
            {countryFlagMap[country.value]}
            <span>{country.label}</span>
          </div>
        ),
      }));
  }, [availableCountries, currentLanguage, message]);

  const placeholder = useMemo(() => message({
    id: 'Select country',
    comment: 'Country field placeholder',
  })?.toString(), [message]);

  return (
    <SelectField
      multi={false}
      onInputChange={noop}
      meta={{}}
      additionalItemsPerLoad={0}
      includeNativeSelect={false}
      minOptionsCount={0}
      placeholder={placeholder}
      options={countryList}
      clearable
      disabled={disabled}
      input={{
        name: 'country',
        onChange: (country) => onCountryChange(country?.value),
        value: selectedCountry,
        onBlur: noop,
        onFocus: noop,
      }}
    />
  );
};

export default localize(CountryPicker);
