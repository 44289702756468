// @flow

import { normalize, schema } from 'normalizr';
import omit from 'lodash/omit';

import { STATE_TEMPLATE } from 'agreement/constants';

import {
  getMultiple,
  post,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import { agreementSchema } from './agreements';
import type { NormalizedAgreement } from './agreements';
import { normalizeAgreementWithTemplate } from './agreement-templates';

const libraryTemplateSchema = new schema.Entity('libraryTemplates', {
  agreement: agreementSchema,
});

export type NormalizedLibraryTemplates = {
  entities: {
    libraryTemplates: {
      [number]: LibraryTemplate,
    },
  },
  result: number | Array<number>,
  count: number,
}

type LibraryTemplateNormalizer = (any) => NormalizedLibraryTemplates;

export const normalizeLibraryTemplates: LibraryTemplateNormalizer = (templates) => ({
  ...normalize(templates.collection, [libraryTemplateSchema]),
  count: templates.count,
});

type GetLibraryTemplates = () => Promise<NormalizedLibraryTemplates>;

export const getLibraryTemplates: GetLibraryTemplates = () => (
  getMultiple({
    url: '/templates/library/',
    // 100 is large enough but arbitrary.
    // Might be hard to debug if there are more than 100 templates
    pagination: { limit: 100, offset: 0 },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeLibraryTemplates)
);

type CreateTemplateFromLibrary = ({
  workspaceId: number,
  sourceId: number,
  name: string,
}) => Promise<NormalizedAgreement>;

export const createTemplateFromLibrary: CreateTemplateFromLibrary = ({
  workspaceId,
  sourceId,
  name,
}) => (
  post({
    url: '/agreements/',
    body: {
      source_id: sourceId,
      collection_id: workspaceId,
      state: STATE_TEMPLATE,
      source: 'oneflow_client',
      name,
    },
  }, { amplitudeScope: 'template library modal' })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAgreementWithTemplate)
    .then((data) => omit(data, `entities.agreements[${data.result}].Template`))
);
