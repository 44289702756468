import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import agreements from 'reducers/entities/agreements';
import { setCurrentContractError } from 'reducers/current-contract';

import ErrorBoundary from 'components/error-boundary';
import SidebarErrorMessage from 'components/sidebar/sidebar-error-message';
import MessageTypes from 'components/document-tabs/messages-tab/message-types';

import style from './messages-tab.module.scss';

type Props = {
  agreementId: number,
  onClose: () => void,
};

const MessagesTab = ({ agreementId, onClose }: Props) => {
  const dispatch = useDispatch();

  const agreement = useSelector((state) => (
    agreements.getAgreementSelector(state, { id: agreementId })
  ));
  const onError = () => dispatch(setCurrentContractError(true));

  if (isEmpty(agreement)) {
    return null;
  }

  return (
    <ErrorBoundary onError={onError} customError={<SidebarErrorMessage />}>
      <div className={style.MessagesTab}>
        <MessageTypes
          agreementId={agreementId}
          onClose={onClose}
        />
      </div>
    </ErrorBoundary>
  );
};

export default MessagesTab;
