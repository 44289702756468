// @flow

import * as React from 'react';
import clsx from 'clsx';
import take from 'lodash/take';
import sumBy from 'lodash/sumBy';
import { Message } from '@oneflowab/pomes';
import { isEmpty } from 'lodash';

import ViewCountEye from 'components/icons/view-count-eye';
import Tooltip from 'components/tooltip';
import ParticipantStatistics from 'components/contract-card/participant-statistics';

import {
  isDraft,
  isPending,
  isOverdue,
  isSigned,
  isDeclined,
} from 'agreement';

import ParticipantBubble, { SimpleBubble } from '../participant-bubble';
import style from './participants.module.scss';

type Props = {
  counterparties: Array<AgreementParty>,
  participants: Array<AgreementParticipant>,
  agreement: Agreement,
  isContractMarkedAsSigned: boolean,
  disabled?: boolean,
  isViewCountHidden?: boolean,
  bubbleClassName?: string,
  participantClassName?: string,
  renderBadge?: Function,
};

const MAX_VISIBLE_PARTICIPANTS = 5;
const NUMBER_OF_PARTICIPANTS_WHEN_LIMITED = MAX_VISIBLE_PARTICIPANTS - 1;

const Participants = ({
  counterparties,
  participants,
  agreement,
  isContractMarkedAsSigned,
  disabled,
  isViewCountHidden,
  bubbleClassName,
  participantClassName,
  renderBadge,
}: Props) => {
  const getBorderColor = () => {
    if (isSigned(agreement)) {
      return style.lightgreen;
    }

    if (isDeclined(agreement)) {
      return style.lightred;
    }

    if (isDraft(agreement)) {
      return style.gray;
    }

    if (isPending(agreement)) {
      return style.lightblue;
    }

    if (isOverdue(agreement)) {
      return style.orange;
    }

    return undefined;
  };

  const renderViewCount = () => {
    if (isViewCountHidden || isEmpty(counterparties)) {
      return null;
    }

    const viewCount = sumBy(counterparties, (party: AgreementParty) => (
      sumBy(party.participants, 'visits')
    ));

    const renderTooltipMessage = () => (
      <div className={style.TooltipText}>
        <Message
          id="Total number of views from counterparty participants."
          comment="Tooltip text. Describes how to interpret a number, which represents sum of all views on all counterparty contract participants."
        />
      </div>
    );

    return (
      <>
        <Tooltip
          message={renderTooltipMessage()}
          triggerClassName={style.TooltipInfo}
          disabled={disabled}
          sideOffset={1}
          side="top"
        >
          <ViewCountEye tabIndex={0} className={style.Icon} />
        </Tooltip>
        <span>{viewCount}</span>
      </>
    );
  };

  const renderParticipantBubble = (participant: AgreementParticipant) => (
    <Tooltip
      message={(
        <ParticipantStatistics
          agreement={agreement}
          participant={participant}
        />
      )}
      side="top"
      triggerClassName={style.Participant}
      messageClassName={style.ParticipantTooltipMessage}
      key={participant.id}
      disabled={disabled}
      theme="light"
      zIndex={10003}
    >
      <span role="button" tabIndex={0}>
        <ParticipantBubble
          className={bubbleClassName}
          participant={participant}
          isContractMarkedAsSigned={isContractMarkedAsSigned}
          agreement={agreement}
          borderColor={getBorderColor()}
          renderBadge={renderBadge}
        />
      </span>
    </Tooltip>
  );

  const renderExtraBubble = (participantList) => {
    if (participantList.length <= MAX_VISIBLE_PARTICIPANTS) {
      return null;
    }

    return (
      <div className={style.Participant}>
        <SimpleBubble
          label={`+${participantList.length - NUMBER_OF_PARTICIPANTS_WHEN_LIMITED}`}
          borderColor={getBorderColor()}
          diameter={46}
          renderBadge={renderBadge}
          className={bubbleClassName}
        />
      </div>
    );
  };

  const renderParticipantsBubbles = () => {
    let visibleParticipants = participants;

    if (participants.length > MAX_VISIBLE_PARTICIPANTS) {
      visibleParticipants = take(participants, NUMBER_OF_PARTICIPANTS_WHEN_LIMITED);
    }

    const sortedParticipants = visibleParticipants
      .sort((a, b) => (a.stateTimestampTs - b.stateTimestampTs))
      .sort((a, b) => a.state - b.state);

    return (
      <div className={style.Bubbles}>
        {sortedParticipants.map(renderParticipantBubble)}
        {renderExtraBubble(participants)}
      </div>
    );
  };

  return (
    <div className={clsx(style.Participants, participantClassName)}>
      {renderParticipantsBubbles()}
      <div className={style.AggregatedInfo}>
        {renderViewCount()}
      </div>
    </div>
  );
};

export default Participants;
