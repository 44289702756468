/* eslint-disable react/display-name */
import { getDaysUntilTimestamp } from 'date';
import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Media from 'react-media';

import { getAccountFromSessionSelector } from 'reducers/session';

import Button from 'components/button';
import { checkAcl } from 'components/acl';
import TimeResetIcon from 'components/icons/time-reset-3-clock';
// eslint-disable-next-line import/named
import { FreemiumSelfService } from 'components/modals/freemium-self-service';
import style from './freemium-notice-banner.module.scss';

export const showFreemiumNoticeBanner = (account: Oneflow.Account) => Boolean(
  (account.trial && account.trialEndTimestampTs)
  || account.isFreemium,
);

export const FreemiumNoticeBanner = () => {
  const account = useSelector(getAccountFromSessionSelector);
  const hasBillingAcl = checkAcl(account.acl, 'account:order:create');

  const renderLicenseText = (isMobile: boolean) => {
    // Trial
    if (account.trial && account.trialEndTimestampTs) {
      if (isMobile) {
        return (
          <span>
            <Message
              id="Your trial is ending in {days} day."
              pluralId="Your trial is ending in {days} days."
              pluralCondition="days"
              values={{
                days: getDaysUntilTimestamp(account.trialEndTimestampTs),
              }}
              comment="Text for topbar message to upgrade if trial account."
            />
          </span>
        );
      }

      return (
        <span>
          <Message
            id="Trial ends in {days} day. After this, your access will be limited to the free version of Oneflow."
            pluralId="Trial ends in {days} days. After this, your access will be limited to the free version of Oneflow."
            pluralCondition="days"
            values={{
              days: getDaysUntilTimestamp(account.trialEndTimestampTs),
            }}
            comment="Text for topbar message to upgrade if trial account."
          />
        </span>
      );
    }

    // Freemium
    return (
      <>
        <span className={style.FreePlan}>
          <Message
            id="Free plan."
            comment="Free plan text for freemium banner."
          />
        </span>
        {' '}
        <Message
          id="You can send and sign PDFs only."
          comment="Text for freemium banner."
        />
      </>
    );
  };

  const renderLicenseActions = () => {
    if (!hasBillingAcl) {
      return null;
    }

    return (
      <FreemiumSelfService>
        {(onClick: () => void) => (
          <Button
            data-testid="upgrade-account"
            kind="link"
            onClick={onClick}
            customClass={style.UpgradeButton}
            trackable="Go To Purchase Plans"
          >
            <Message
              id="Upgrade your plan."
              comment="Button text for upgrade account button"
            />
          </Button>
        )}
      </FreemiumSelfService>
    );
  };

  const renderNoticeBanner = (isMobile: boolean) => {
    const noticeBannerMobileClassnames = clsx(style.NoticeBanner, {
      [style.NoticeBannerMobile]: isMobile,
    });

    return (
      <div className={noticeBannerMobileClassnames}>
        <div className={style.IconContainer}>
          <TimeResetIcon height="12px" width="12px" className={style.Icon} />
        </div>
        <span className={style.NoticeBannerContent}>
          {renderLicenseText(isMobile)}
          {' '}
          {renderLicenseActions()}
        </span>
      </div>
    );
  };

  if (!showFreemiumNoticeBanner(account)) {
    return null;
  }

  return (
    <>
      <Media
        key="desktop-notice-banner"
        query={{
          minWidth: style.desktopmin,
        }}
      >
        {renderNoticeBanner(false)}
      </Media>
      <Media
        key="mobile-notice-banner"
        query={{
          maxWidth: style.mobilemax,
        }}
      >
        {renderNoticeBanner(true)}
      </Media>
    </>
  );
};
