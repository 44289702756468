// @flow

import React from 'react';

import withCurrentWorkspace from 'hocs/with-current-workspace';
import { checkAcl } from 'components/acl';
import { NotFound } from 'components/errors';

import Dashboard from 'components/dashboard';

export type Props = {
  acl: {
    workspace: Acl,
  },
  workspace: Workspace,
  location: Location,
};

export const DashboardModule = (props: Props) => {
  if (!checkAcl(props.acl.workspace, 'collection:module:dashboard:use')) {
    return <NotFound redirect to="/contracts" />;
  }

  return (
    <Dashboard {...props} />
  );
};

export default withCurrentWorkspace(DashboardModule);
