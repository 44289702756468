// @flow

import React from 'react';
import { Form } from 'react-final-form';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { type ContextRouter } from 'react-router-dom';
import type { FormRenderProps } from 'react-final-form';

import Button from 'components/button';
import Field from 'components/field';
import TextField from 'components/text-field';
import CircularSpinner from 'components/icons/circular-spinner';
import CheckCircle from 'components/icons/check-circle';
import Error from 'components/icons/error';
import SecurityLock from 'components/icons/security-lock';

import style from './dynamics-authorization.module.scss';

export type LocalizeProps = {|
  message: MessageTranslator,
|};
export type Props = {
  ...LocalizeProps,
  ...ContextRouter,
  oauthLink: string | void,
  authorizationState: RpcState,
  authorize: ({ dynamicsInstanceUrl: string }) => void,
};

export class AuthorizationComponent extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { oauthLink } = this.props;
    const { oauthLink: previousOauthLink } = prevProps;

    if (oauthLink && oauthLink !== previousOauthLink) {
      window.open(oauthLink, '_self');
    }
  }

  isAuthorizeButtonDisabled(formProps: FormRenderProps<any>) {
    const { authorizationState } = this.props;

    return (
      authorizationState.loading
      || !isEmpty(formProps.errors)
      || formProps.validating
      || formProps.pristine
    );
  }

  isConnected() {
    const { location } = this.props;
    const { status } = queryString.parse(location.search);

    return status === 'ok';
  }

  isConnectedToAnotherDynamicsInstance() {
    const { authorizationState: { error } } = this.props;

    return get(error, 'body.status_code') === 409;
  }

  renderAuthStatus() {
    if (this.isConnectedToAnotherDynamicsInstance()) {
      return <Error className={style.AuthFailed} />;
    }

    if (this.isConnected()) {
      return <CheckCircle className={style.AuthSuccess} />;
    }

    return null;
  }

  renderForm = (formProps: FormRenderProps<any>) => {
    const { message, authorizationState } = this.props;

    return (
      <form
        className={style.AuthorizationForm}
        onSubmit={formProps.handleSubmit}
        data-testid="authorize-with-dynamics-form"
      >
        <Field
          name="dynamicsInstanceUrl"
          label={message({
            id: 'Dynamics CRM instance url',
            comment: 'instanceUrl label for Dynamics CRM.',
          })}
          placeholder={message({
            id: 'Enter Dynamics CRM instance url',
            comment: 'instanceUrl placeholder for Dynamics CRM.',
          })}
          component={TextField}
          maxLength={1000}
        />
        <Button
          type="submit"
          color="thunder"
          customClass={style.AuthorizeButton}
          icon={authorizationState.loading ? CircularSpinner : SecurityLock}
          disabled={this.isAuthorizeButtonDisabled(formProps)}
          data-testid="authorize-with-dynamics-button"
        >
          <Message
            id="Authenticate with Dynamics CRM"
            comment="Action button in dynamics crm settings page"
          />
        </Button>
      </form>
    );
  }

  render() {
    const { authorize } = this.props;

    return (
      <div className={style.Authorization}>
        <h3 className={style.ExtensionConfigurationHeader}>
          <Message
            id="Authentication"
            comment="Section header for the Dynamics CRM extension."
          />
          {this.renderAuthStatus()}
        </h3>
        <Form
          onSubmit={authorize}
          render={this.renderForm}
        />
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="This step is needed to set up real-time synchronization of contracts from Oneflow to your Dynamics organization. The user who grants consent should also be a global administrator in your Microsoft organization."
            comment="Help text for the Dynamics CRM extension."
          />
        </div>
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="When you authorize your Dynamics instance in your Oneflow account, the instance associated with your account cannot be changed. Please make sure to set up a test account in Oneflow in case you want to test the integration before installing the solution to your production environment."
            comment="Help text for the Dynamics CRM extension."
          />
        </div>
      </div>
    );
  }
}

export default localize<Props>(AuthorizationComponent);
