import { DEFAULT_ROUNDING_PRECISION } from 'utils/math';

export const formatNumberWithTrailingZeros = (price: string, pricePrecision: number) => {
  const sanitizedString = price.toString().replace(/\s/g, '');
  const parsedNumber = parseFloat(sanitizedString);

  const formatter = new Intl.NumberFormat('fr-CA', { minimumFractionDigits: pricePrecision });

  const formattedNumber = formatter.format(parsedNumber).replace(/,/g, '.');
  return formattedNumber;
};

export const pricePrecision = (precision: number) => (Number(precision) === 0 ? 0 : precision
|| DEFAULT_ROUNDING_PRECISION);
