import React from 'react';
import PropTypes from 'prop-types';

import style from './table.module.scss';

const TableRow = ({ children }) => (
  <tr className={style.TableRow}>
    {children}
  </tr>
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableRow;
