import { useCallback, useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Message } from '@oneflowab/pomes';
import { Location } from 'history';
import { replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import type { match as Match } from 'react-router';
import type { MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';
import contactsReducer from 'reducers/entities/contacts';
import type { EnabledLanguageCodes } from 'utils/languages';

import * as EditableForm from 'components/editable-form';
import { checkAcl } from 'components/acl';
import Country, { getCountryListAsOptions } from 'components/countries';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import TextArea from 'components/text-area';
import { DetailsFooter } from 'components/details-footer';
import { LocalizedDateTime } from 'components/localized-date-time';

import style from './contact-details.module.scss';

type FormData = Partial<Oneflow.Participant> & {
  fullname: Oneflow.Participant['fullname'],
  companyName: Oneflow.Account['name'],
  companyOrgnr: Oneflow.Account['orgnr'],
  notes: string,
}

export type Props = {
  lang: EnabledLanguageCodes,
  location: Location,
  match: Match,
  country: string,
  message: MessageTranslator
};

export const ContactDetailsComponent = ({
  message,
  country,
  lang,
  location,
  match,
}: Props) => {
  const dispatch = useDispatch();

  const [formVisible, setFormVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const contact = useSelector(
    (state) => contactsReducer.getContactSelector(state, { id: match.params.id }),
  );

  const fetchState = useSelector(
    (state) => contactsReducer.getFetchSelector(state, { id: match.params.id }),
  );
  const updateState = useSelector(
    (state) => contactsReducer.getUpdateSelector(state, { id: contact?.id }),
  );

  const canEdit = useCallback(() => {
    if (!contact) return false;

    return checkAcl(
      contact.acl,
      [
        'addressbook:update:fullname',
        'addressbook:update:title',
        'addressbook:update:email',
        'addressbook:update:ssn',
        'addressbook:update:phone_number',
        'addressbook:update:company_name',
        'addressbook:update:company_orgnr',
        'addressbook:update:country',
        'addressbook:update:notes',
      ],
      { match: 'any' },
    );
  }, [contact]);

  const hideForm = () => {
    setFormVisible(false);
  };

  const getInitialValues = () => ({
    ...contact,
  });

  const updateContact = (formData: FormData, onSuccess: () => void) => {
    const {
      fullname,
      title,
      email,
      ssn,
      phoneNumber,
      companyName,
      companyOrgnr,
      notes,
    } = formData;
    dispatch(contactsReducer.updateContact({
      id: match.params.id,
      data: {
        fullname,
        title: title || null,
        email,
        ssn: ssn || null,
        phoneNumber: phoneNumber || null,
        companyName: companyName || null,
        companyOrgnr: companyOrgnr || null,
        country: get(formData, 'country.value') || country || null,
        notes: notes || null,
      },
      pipe: {
        onSuccess: () => { onSuccess(); hideForm(); },
      },
    }));
  };

  useEffect(() => {
    if (!formVisible && location?.search?.includes('edit') && canEdit()) {
      dispatch(replace(location.pathname));
      setFormVisible(true);
    }
  }, [location, dispatch, canEdit, formVisible]);

  useEffect(() => {
    if (fetchState.error) {
      dispatch(replace('/address-book/contacts'));
    }
  }, [fetchState.error, dispatch]);

  if (fetchState.loading) {
    return null;
  }

  const infoPairs = [
    {
      label: <Message
        id="Contact ID"
        comment="ID label for the details footer."
      />,
      value: contact.id,
    },
    {
      label: <Message
        id="Creation date"
        comment="Creation date label for the details footer"
      />,
      value: <LocalizedDateTime datetime={contact.createdTime} />,
    },
  ];

  return (
    <>
      <Form
        initialValues={getInitialValues()}
        onSubmit={(formData: FormData) => updateContact(formData, () => setIsEditMode(false))}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <EditableForm.Header>
              <EditableForm.Actions
                resetFormState={() => dispatch(contactsReducer.updateContactReset({
                  id: match.params.id,
                }))}
                formState={updateState}
                isEditMode={isEditMode}
                setIsEditMode={(_isEditMode) => setIsEditMode(_isEditMode)}
                disabled={!canEdit()}
              />
            </EditableForm.Header>
            <EditableForm.Body>
              <h2 className={style.Header}>
                <Message
                  id="Details"
                  comment="Used as the header on the address book contact details page."
                />
              </h2>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Name"
                    comment="Used as a label for the address book contacts details page."
                  />
                </EditableForm.Label>
                <Field
                  id="fullname"
                  name="fullname"
                  component={TextField}
                  value={contact.fullname}
                  placeholder={message({
                    id: 'Enter name',
                    comment: 'Used as a placeholder a field in for the address book contacts details page.',
                  })}
                  maxLength={100}
                  required
                  autoFocus
                  disabled={!isEditMode || !checkAcl(contact.acl, 'addressbook:update:fullname')}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Email"
                    comment="Used as a label for the address book contacts details page."
                  />
                </EditableForm.Label>
                <Field
                  id="email"
                  name="email"
                  component={TextField}
                  value={contact.email}
                  placeholder={message({
                    id: 'Enter email',
                    comment: 'Used as a placeholder a field in for the address book contacts details page.',
                  })}
                  maxLength={70}
                  displayErrorEarly
                  email
                  required
                  disabled={!isEditMode || !checkAcl(contact.acl, 'addressbook:update:email')}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Title"
                    comment="Used as a label for the address book contacts details page."
                  />
                </EditableForm.Label>
                <Field
                  id="title"
                  name="title"
                  component={TextField}
                  value={contact.title}
                  placeholder={message({
                    id: 'Enter title',
                    comment: 'Used as a placeholder a field in for the address book contacts details page.',
                  })}
                  maxLength={100}
                  disabled={!isEditMode || !checkAcl(contact.acl, 'addressbook:update:title')}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Company name"
                    comment="Used as a label for the address book contacts details page."
                  />
                </EditableForm.Label>
                <Field
                  id="companyName"
                  name="companyName"
                  component={TextField}
                  value={contact.companyName}
                  placeholder={message({
                    id: 'Enter company name',
                    comment: 'Used as a placeholder a field in for the address book contacts details page.',
                  })}
                  maxLength={100}
                  disabled={!isEditMode || !checkAcl(contact.acl, 'addressbook:update:company_name')}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Company registration number"
                    comment="Used as a label for the address book contacts details page."
                  />
                </EditableForm.Label>
                <Field
                  id="companyOrgnr"
                  name="companyOrgnr"
                  component={TextField}
                  value={contact.companyOrgnr}
                  placeholder={message({
                    id: 'Enter company registration number',
                    comment: 'Used as a placeholder a field in for the address book contacts details page.',
                  })}
                  maxLength={25}
                  disabled={!isEditMode || !checkAcl(contact.acl, 'addressbook:update:company_orgnr')}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Date of birth"
                    comment="Used as a label for the address book contacts details page."
                  />
                </EditableForm.Label>
                <Field
                  id="ssn"
                  name="ssn"
                  component={TextField}
                  value={contact.ssn}
                  placeholder={message({
                    id: 'Enter birth date',
                    comment: 'Used as a placeholder a field in for the address book contacts details page.',
                  })}
                  maxLength={25}
                  disabled={!isEditMode || !checkAcl(contact.acl, 'addressbook:update:ssn')}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Phone number"
                    comment="Used as a label for the address book contacts details page."
                  />
                </EditableForm.Label>
                <Field
                  id="phoneNumber"
                  name="phoneNumber"
                  component={TextField}
                  value={contact.phoneNumber}
                  placeholder={message({
                    id: 'Enter phone number',
                    comment: 'Used as a placeholder a field in for the address book contacts details page.',
                  })}
                  maxLength={50}
                  disabled={!isEditMode || !checkAcl(contact.acl, 'addressbook:update:phone_number')}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Country"
                    comment="Used as a label for the address book contacts details page."
                  />
                </EditableForm.Label>
                <Field
                  id="country"
                  name="country"
                  component={SelectField}
                  value={(
                    <Country
                      country={contact.country}
                      lang={lang}
                    />
                  )}
                  options={getCountryListAsOptions()}
                  clearable
                  searchable
                  required={false}
                  disabled={!isEditMode || !checkAcl(contact.acl, 'addressbook:update:country')}
                  placeholder={message({
                    id: 'Select country',
                    comment: 'Used as a placeholder a field in for the address book contacts details page.',
                  })}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Notes"
                    comment="Used as a label for the address book contacts details page."
                  />
                </EditableForm.Label>
                <Field
                  id="notes"
                  name="notes"
                  component={TextArea}
                  value={contact.notes}
                  placeholder={message({
                    id: 'Enter notes',
                    comment: 'Used as a placeholder a field in for the address book contacts details page.',
                  })}
                  maxLength={1000}
                  disabled={!isEditMode || !checkAcl(contact.acl, 'addressbook:update:notes')}
                />
              </div>
            </EditableForm.Body>
          </form>
        )}
      />
      <DetailsFooter infoPairs={infoPairs} />
    </>
  );
};

export const propsMapper = () => ({
  title: <Message
    id="Details"
    comment="Used in the breadcrumb on the address book contact details page."
  />,
  showAsLink: false,
  hideBreadCrumb: true,
  isMainPage: true,
  modules: [],
});

export default adminPage(propsMapper)(ContactDetailsComponent);
