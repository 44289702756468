/* eslint-disable react/prop-types */

import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  chunk,
  cloneDeep,
  get,
  isBoolean,
  isObject,
  pickBy,
  uniqueId,
} from 'lodash';

import {
  updateBoxAction,
  updateBoxConfigAction,
  updateBoxDataItemAction,
  updateDataFieldAction,
} from 'reducers/current-contract';
import { isBoxDataCreateAllowed } from 'agreement/box-data-create-permissions';

import useCurrentBox from 'hooks/use-current-box';
import useCurrentBoxId from 'hooks/use-current-box-id';

import {
  getId,
  removeContentDataItems,
} from 'components/contract-boxes/generic-box-helpers';
import {
  addNullFields,
  getAvailableColumnSlots,
  getFieldData,
  getIsBoxEmpty,
  getUpdatedBoxData,
  getUpdatedFieldData,
  trimNullFields,
} from 'components/contract-boxes/form-box/helpers';

export const FormBoxPropsContext = React.createContext();

export function FormBoxPropsProvider({
  boxId,
  children,
  isEditable,
  isValuesEditable,
}) {
  const dispatch = useDispatch();
  const currentBoxId = useCurrentBoxId(boxId);
  const box = useCurrentBox(currentBoxId);
  const isAllowedToAddBoxData = isBoxDataCreateAllowed(box);

  const { config, content } = box;
  const {
    colCount,
    colleagueEdit,
    counterpartEdit,
  } = config;

  const data = get(box, 'content.data');
  const order = useMemo(() => (
    get(box, 'config.order')
      .map((row, index) => {
        const withId = [...row];
        withId.id = getId(row) || `key-${currentBoxId}${index}`;

        return withId;
      })
  ), [box, currentBoxId]);

  const addRow = useCallback(() => {
    const newRow = addNullFields(colCount, []);

    const updatedBoxConfig = {
      order: [
        ...order,
        newRow,
      ],
    };

    dispatch(updateBoxConfigAction(currentBoxId, updatedBoxConfig));
  }, [colCount, order, currentBoxId, dispatch]);

  const removeRow = useCallback((index) => () => {
    const newOrder = [
      ...order.slice(0, index),
      ...order.slice(index + 1),
    ];
    const updatedBoxOrder = !getIsBoxEmpty(newOrder) ? newOrder : [addNullFields(colCount, [])];
    const removedDataItemIds = Object.values(pickBy(order[index], isObject))
      .map((item) => getId(item));

    const updatedBox = {
      ...box,
      config: {
        ...config,
        order: updatedBoxOrder,
      },
      content: {
        ...content,
        data: removeContentDataItems(box, removedDataItemIds),
      },
    };

    dispatch(updateBoxAction(updatedBox));
  }, [box, colCount, config, content, dispatch, order]);

  const editField = useCallback((indexMap, formProps) => {
    const { fieldIndex, rowIndex } = indexMap;
    const updatedBoxOrder = cloneDeep(order);
    const currentField = updatedBoxOrder[rowIndex][fieldIndex];
    const updatedFieldData = getUpdatedFieldData(currentField, data, formProps);

    if (!currentField) {
      const newFieldId = Number(uniqueId());
      const newField = {
        _id: newFieldId,
      };

      updatedBoxOrder[rowIndex][fieldIndex] = newField;
      updatedFieldData._id = newFieldId;
    }

    const updatedBox = {
      ...box,
      config: {
        ...config,
        order: updatedBoxOrder,
      },
      content: {
        ...content,
        data: getUpdatedBoxData(
          data,
          getId(currentField),
          updatedFieldData,
        ),
      },
    };

    dispatch(updateBoxAction(updatedBox));
  }, [box, config, content, data, dispatch, order]);

  const removeField = useCallback((indexMap) => {
    const { fieldIndex, rowIndex } = indexMap;
    const updatedBoxOrder = cloneDeep(order);

    const currentField = updatedBoxOrder[rowIndex][fieldIndex];
    const id = getId(currentField);

    updatedBoxOrder[rowIndex][fieldIndex] = null;

    const updatedBox = {
      ...box,
      config: {
        ...config,
        order: updatedBoxOrder,
      },
      content: {
        ...content,
        data: removeContentDataItems(box, [id]),
      },
    };

    dispatch(updateBoxAction(updatedBox));
  }, [box, config, content, dispatch, order]);

  const setColumnCount = useCallback((columnCount) => () => {
    const updatedBoxOrder = order.reduce((acc, curr) => {
      if (columnCount < curr.length) {
        const chunks = chunk(trimNullFields(columnCount, curr), columnCount).map((row) => (
          addNullFields(columnCount, row)
        ));

        return [...acc, ...chunks];
      }

      return [...acc, addNullFields(columnCount, curr)];
    }, []);
    const updatedBoxData = [...data];

    updatedBoxOrder.forEach((row, rowIndex) => (
      row.forEach((field, fieldIndex) => {
        const dataItemId = getId(field);
        const dataItem = getFieldData(data, dataItemId);

        if (!dataItem) {
          return;
        }

        const availableSlots = getAvailableColumnSlots(
          fieldIndex,
          rowIndex,
          columnCount,
          updatedBoxOrder,
        );

        if (availableSlots >= dataItem.value.colspan) {
          return;
        }

        const dataItemIndex = updatedBoxData.findIndex((el) => getId(el) === dataItemId);

        updatedBoxData[dataItemIndex].value.colspan = availableSlots;
      })
    ));

    const updatedBox = {
      ...box,
      config: {
        ...config,
        colCount: columnCount,
        order: updatedBoxOrder,
      },
      content: {
        ...content,
        data: updatedBoxData,
      },
    };

    dispatch(updateBoxAction(updatedBox));
  }, [box, config, content, data, dispatch, order]);

  const toggleColleagueEdit = useCallback((optionalBoolean) => {
    const updatedBoxConfig = {
      colleagueEdit: isBoolean(optionalBoolean) ? optionalBoolean : !colleagueEdit,
    };

    dispatch(updateBoxConfigAction(currentBoxId, updatedBoxConfig));
  }, [colleagueEdit, currentBoxId, dispatch]);

  const toggleCounterpartyEdit = useCallback(() => {
    const updatedBoxConfig = {
      counterpartEdit: !counterpartEdit,
    };

    dispatch(updateBoxConfigAction(currentBoxId, updatedBoxConfig));
  }, [counterpartEdit, currentBoxId, dispatch]);

  const updateDataFieldObject = useCallback((dataFieldObject, value) => {
    const updatedDataFieldValue = {
      ...dataFieldObject,
      value: {
        ...dataFieldObject.value,
        value: value || '',
      },
    };

    dispatch(updateDataFieldAction(updatedDataFieldValue));
  }, [dispatch]);

  const updateFieldValue = useCallback((fieldId, value) => {
    const updatedFieldValue = { value: { value } };
    dispatch(updateBoxDataItemAction(currentBoxId, fieldId, updatedFieldValue));
  }, [currentBoxId, dispatch]);

  const updateOrder = useCallback((updatedBoxOrder) => {
    const updatedBoxConfig = {
      order: updatedBoxOrder,
    };

    dispatch(updateBoxConfigAction(currentBoxId, updatedBoxConfig));
  }, [currentBoxId, dispatch]);

  const contextValue = React.useMemo(
    () => ({
      addRow,
      boxId: currentBoxId,
      config,
      data,
      editField,
      isEditable,
      isAllowedToAddBoxData,
      isValuesEditable,
      order,
      removeField,
      removeRow,
      setColumnCount,
      toggleColleagueEdit,
      toggleCounterpartyEdit,
      updateDataFieldObject,
      updateFieldValue,
      updateOrder,
    }), [
      addRow,
      config,
      currentBoxId,
      data,
      editField,
      isEditable,
      isAllowedToAddBoxData,
      isValuesEditable,
      order,
      removeField,
      removeRow,
      setColumnCount,
      toggleColleagueEdit,
      toggleCounterpartyEdit,
      updateDataFieldObject,
      updateFieldValue,
      updateOrder,
    ],
  );

  return (
    <FormBoxPropsContext.Provider value={contextValue}>
      {children}
    </FormBoxPropsContext.Provider>
  );
}

export const useFormBoxProps = () => {
  const contextValue = React.useContext(FormBoxPropsContext);

  if (!contextValue) {
    throw new Error('useFormBoxProps should be used inside a FormBoxPropsContext');
  }

  return contextValue;
};
