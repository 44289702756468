// @flow

import React from 'react';
import clsx from 'clsx';

import { getUserInitials } from 'user';

import hasParticipantApprovedDraft from 'agreement/draft-approval-flow';
import {
  hasPendingStateParticipantApproved,
  hasPendingStateApprovers,
} from 'agreement/pending-state-flow';
import {
  hasDraftApprovalFlow,
} from 'agreement';

import hasSigned from 'agreement/participant/has-signed';
import isInternalApprover from 'agreement/participant/is-internal-approver';
import hasDeclined from 'agreement/participant/has-declined';
import isSignatory from 'agreement/participant/is-signatory';
import isExternalApprover from 'agreement/participant/is-external-approver';

import NewCheck from 'components/icons/new-check';
import NewCross from 'components/icons/new-cross';
import InnerBubble from './inner-bubble';

import style from './participant-bubble.module.scss';

type Props = {
  isContractMarkedAsSigned?: boolean,
  participant: AgreementParticipant,
  borderColor?: string,
  renderBadge?: Function,
  className?: string,
  userBadgeDiameter?: number,
  userBadgeBorderSize?: number,
  withoutClosedIcons?: boolean,
  agreement?: Agreement,
};

const ParticipantBubble = ({
  isContractMarkedAsSigned,
  participant,
  borderColor,
  renderBadge,
  className,
  userBadgeDiameter,
  userBadgeBorderSize,
  withoutClosedIcons,
  agreement,
}: Props) => {
  const hasApprovedDocument = () => {
    if (!hasDraftApprovalFlow(agreement) && !hasPendingStateApprovers(agreement)) {
      return false;
    }

    const hasApproved = hasParticipantApprovedDraft(agreement, participant)
      || hasPendingStateParticipantApproved(agreement, participant);

    return hasApproved;
  };

  const isAnApprover = () => isInternalApprover(participant) || isExternalApprover(participant);

  const showApprovedIcon = isAnApprover() && hasApprovedDocument();

  const showSignedIcon = () => {
    if (!isSignatory(participant)) {
      return false;
    }

    return isContractMarkedAsSigned || hasSigned(participant);
  };

  const showDeclinedIcon = () => hasDeclined(participant);

  const getFillColor = () => {
    if (showApprovedIcon) {
      return style.purple;
    }

    if (showSignedIcon()) {
      return style.green;
    }

    if (showDeclinedIcon()) {
      return style.red;
    }

    return style.white;
  };

  const getInitialsColor = () => {
    if (showSignedIcon() || showApprovedIcon || showDeclinedIcon()) {
      return style.white;
    }

    return undefined;
  };

  const renderClosedIcons = () => {
    if (withoutClosedIcons) {
      return null;
    }

    if (showSignedIcon() || showApprovedIcon) {
      return (
        <span
          className={clsx('participant-action', style.Action, style.white, className)}
        >
          <NewCheck height="10px" />
        </span>
      );
    }

    if (showDeclinedIcon()) {
      return (
        <span
          className={clsx('participant-action', style.Action, style.white, className)}
        >
          <NewCross height="10px" />
        </span>
      );
    }

    return null;
  };

  const bubbleClassNames = clsx(style.Bubble, className);
  const name = participant.fullname
    || participant.name
    || participant.partyName;

  return (
    <div className={bubbleClassNames}>
      <InnerBubble
        label={getUserInitials(name)}
        fillColor={getFillColor()}
        borderColor={borderColor}
        labelColor={getInitialsColor()}
        renderBadge={renderBadge}
        labelClasses={className}
        userBadgeDiameter={userBadgeDiameter}
        userBadgeBorderSize={userBadgeBorderSize}
      />
      {renderClosedIcons()}
    </div>
  );
};

export default ParticipantBubble;
