import { useSelector } from 'react-redux';
import { getAccountFromSessionSelector } from 'reducers/session';
import { Message } from '@oneflowab/pomes';
import { Feature } from 'components/modals/cancellation/lost-features/feature';

import {
  essentialsChurnFeatures,
  businessChurnFeatures,
} from 'components/modals/cancellation/lost-features/feature/features';

import style from './feature-list.module.scss';

export const FeatureList = () => {
  const account = useSelector(getAccountFromSessionSelector);

  const renderFeatures = () => {
    if (account.planName === 'essentials') {
      return (
        essentialsChurnFeatures.map(({ id, icon, label }) => (
          <Feature
            key={id}
            icon={icon}
            label={label}
          />
        ))
      );
    }

    if (account.planName === 'business') {
      return (
        businessChurnFeatures.map(({ id, icon, label }) => (
          <Feature
            key={id}
            icon={icon}
            label={label}
          />
        ))
      );
    }

    return null;
  };

  return (
    <div className={style.FeatureListContainer}>
      <h1 className={style.Header}>
        <Message
          id="Features you will lose:"
          comment="Header for the feature list section"
        />
      </h1>
      <div className={style.FeatureList}>
        {renderFeatures()}
      </div>
    </div>
  );
};
