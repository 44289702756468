import clsx from 'clsx';

import style from './count-badge.module.scss';

type Props = {
  fadeOut?: boolean;
  count: number;
  className?: string,
};

const CountBadge = ({ fadeOut, count, className }: Props) => {
  const messagesCountClassName = clsx(style.CountBadge, className, {
    [style.FadeOut]: fadeOut,
  });

  if (count <= 0) {
    return null;
  }

  return <span className={messagesCountClassName}>{count > 99 ? '99+' : count}</span>;
};

export default CountBadge;
