import { ReactNode, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Message } from '@oneflowab/pomes';

import {
  getAccountFromSessionSelector,
} from 'reducers/session';
import { getHasNonPdfSections, isContractPristine } from 'reducers/current-contract';
import {
  getAgreementMyParticipant,
  getEnabledParticipants,
} from 'agreement/selectors';
import isSignatory from 'agreement/participant/is-signatory';
import {
  isDraftApprovalFlowNotStarted,
} from 'agreement';
import { isFreemium } from 'account';
import useAgreement from 'hooks/use-agreement';
import useCurrentMessages from 'hooks/use-current-messages';
import toast from 'components/toasts';

import { checkAcl } from 'components/acl';
import SendIcon from 'components/icons/send';
import { SendButton } from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons';
import SendContractModal from 'components/modals/send-contract';
import ResolveAnnotationsModal from 'components/modals/resolve-annotations';
import { SingleParticipantModal } from 'components/document-call-to-actions/actions/modals/single-participant';
import type { Layout } from 'components/document-layout-container/types';

export type Props = {
  agreementId: number,
  children?: ReactNode,
  customClassName?: string,
  canPublish: boolean,
  layout?: Layout,
};

const PublishContractAction = ({
  agreementId,
  layout,
  children,
  customClassName,
  canPublish,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const myParticipant = getAgreementMyParticipant(agreement);
  const participants = getEnabledParticipants(agreement);
  const account = useSelector(getAccountFromSessionSelector);
  const hasNonPdfSections = useSelector(getHasNonPdfSections);
  const isFreemiumWithNonPdfSections = isFreemium(account) && hasNonPdfSections;
  const hasPermissionToStartDraftApprovalFlow = checkAcl(
    agreement.draftApprovalFlow?.acl, 'flow:start',
  );
  const hasPermissionToPublishSigningOrderDocument = checkAcl(
    agreement.acl, 'agreement:signing_order',
  ) && checkAcl(
    agreement.acl, 'agreement:publish',
  );

  const isPristine = useSelector(isContractPristine);

  const showDraftApproverWarningToast = isDraftApprovalFlowNotStarted(agreement)
    && !hasPermissionToStartDraftApprovalFlow;

  const showPublishSigningOrderErrorToast = agreement.config?.signOrder
    && !hasPermissionToPublishSigningOrderDocument;

  const {
    activeAnnotations,
  } = useCurrentMessages();

  const getButtonLabel = () => {
    if (children) {
      return children;
    }

    return (
      <Message
        id="Send"
        comment="Label for Send document button in document view."
      />
    );
  };

  const draftApprovalErrorToast = () => (
    toast.error({
      id: 'cant-send-warning',
      title: <Message
        id="Send not possible"
        comment="Title for the warning message when the user can't send the document."
      />,
      description: <Message
        id="To send the document, remove internal approvers."
        comment="Description text for the warning message when the user can't send the document."
      />,
    })
  );

  const sendBlockedDisableSignOrderToast = () => (
    toast.error({
      id: 'send-not-possible-error',
      title: <Message
        id="Send not possible"
        comment="Title for the error message when the user can't send the document."
      />,
      description: <Message
        id="Disable signing order to send the document."
        comment="Description text for the error message when the user can't send the document."

      />,
    })
  );

  const handleOnPublish = (event: SyntheticEvent, onClick: (e: SyntheticEvent) => void) => {
    if (showDraftApproverWarningToast) {
      return draftApprovalErrorToast();
    }

    if (showPublishSigningOrderErrorToast) {
      return sendBlockedDisableSignOrderToast();
    }

    if (!canPublish) {
      if (isFreemiumWithNonPdfSections) {
        toast.warning({
          id: 'freemium-sections-warning',
          title: <Message id="Remove extra sections to send" comment="Title to warning that there are sections not included in freemium plan" />,
          description: <Message id="Only PDFs allowed in free plan." comment="Description to warning that there are sections not included in freemium plan" />,
          duration: 5000,
        });

        return undefined;
      }
    }

    return onClick(event);
  };

  const isButtonDisabled = () => {
    if (!isPristine) {
      return true;
    }

    if (isFreemiumWithNonPdfSections) {
      return false;
    }

    if (showPublishSigningOrderErrorToast) {
      return false;
    }

    return !canPublish;
  };

  const renderSendButton = (onClick: (event: SyntheticEvent) => void) => (
    <SendButton
      customClassName={customClassName}
      onClick={(event: SyntheticEvent) => handleOnPublish(event, onClick)}
      disabled={isButtonDisabled()}
      icon={children ? undefined : SendIcon}
    >
      {getButtonLabel()}
    </SendButton>
  );

  if (!isEmpty(activeAnnotations)) {
    return (
      <ResolveAnnotationsModal agreementId={agreementId}>
        {renderSendButton}
      </ResolveAnnotationsModal>
    );
  }

  if (children && participants.length <= 1) {
    return (
      <SingleParticipantModal
        agreementId={agreementId}
        layout={layout}
        isSignatory={myParticipant ? isSignatory(myParticipant) : false}
      >
        {renderSendButton}
      </SingleParticipantModal>
    );
  }

  return (
    <SendContractModal
      isOpen={false}
      agreementId={agreementId}
      context="agreementPublish"
    >
      {renderSendButton}
    </SendContractModal>
  );
};

export default PublishContractAction;
