// @flow

import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import accounts from 'reducers/entities/accounts';
import {
  isFeatureEnabledSelector,
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';

import ExportAccountComponent from './export-account';
import type { Props, ExportOptions } from './export-account';

type StateProps = {|
  requestAccountExportState: RpcState,
  position: Position,
  currentAccount: Account,
  isAccountExportEnabled: boolean,
|};

type DispatchProps = {|
  requestAccountExport: ({
    exportOptions: ExportOptions,
    accountId: number,
    positionId: number,
  }) => void,
  resetAccountExport: ({ accountId: number }) => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  currentAccount: getAccountFromSessionSelector(state),
  position: getPositionFromSessionSelector(state),
  requestAccountExportState: accounts.getExportAccountSelector(state, {
    id: getAccountFromSessionSelector(state).id,
  }),
  isAccountExportEnabled: !isFeatureEnabledSelector(state, {
    feature: 'outageAccountExport',
  }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  requestAccountExport: ({ exportOptions, accountId, positionId }) => {
    dispatch(accounts.exportAccount({
      id: accountId,
      data: {
        options: exportOptions,
        positionId,
      },
    }));
  },
  resetAccountExport: ({ accountId }) => {
    dispatch(accounts.exportAccountReset({ id: accountId }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(ExportAccountComponent);
