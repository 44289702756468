// @flow

import * as React from 'react';

import { isConcluded } from 'agreement';
import { ContractToolbarPlaceholder } from 'components/contract-text-editor/contract-toolbar-placeholder';
import useIsElementRenderedInside from 'hooks/use-is-element-rendered-inside';
import style from './contract-toolbar.module.scss';

type Props = {
  agreement: Agreement,
  isContractEditable: boolean,
};

const ContractToolbar = ({
  agreement,
  isContractEditable,
}: Props) => {
  const isAgreementConcluded = isConcluded(agreement);
  const toolbarRef = React.useRef(null);
  const isEditableToolbarVisible = useIsElementRenderedInside(toolbarRef, '.rich-text-toolbar:not(.rich-text-toolbar-placeholder)');

  if (isAgreementConcluded || !isContractEditable) {
    return null;
  }

  return (
    <div className={style.TopbarEditorToolbarGridBlock}>
      <div
        id="contract-editor-toolbar"
        className={style.TopbarEditorToolbar}
        ref={toolbarRef}
      >
        <ContractToolbarPlaceholder
          agreement={agreement}
          isEditableToolbarVisible={isEditableToolbarVisible}
        />
      </div>
    </div>
  );
};

export default ContractToolbar;
