import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isContractPristine } from 'reducers/current-contract';
import useAgreement from 'hooks/use-agreement';

import { setNotifyParticipant } from 'reducers/editor';

import { isContractEditable } from 'components/document-layout-container/helpers';
import type { Layout } from 'components/document-layout-container/types';

import YouHaveSignedState from './states/you-have-signed';
import { DiscardSaveActions } from './buttons-call-to-action';

export type FormData = { message: string; subject: string } | Record<string, never>;

type Props = {
  agreementId: Oneflow.Agreement['id'],
  layout: Layout;
}

const HasSignedActions = ({ agreementId, layout }: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);

  const isPristine = useSelector(isContractPristine);
  const isEditable = isContractEditable(agreement);

  useEffect(() => {
    if (!isPristine && isEditable) {
      dispatch(setNotifyParticipant(true));
    } else {
      dispatch(setNotifyParticipant(false));
    }
  }, [isPristine, isEditable, dispatch]);

  if (!isPristine && isEditable) {
    return (
      <DiscardSaveActions
        agreementId={agreementId}
      />
    );
  }

  return (
    <YouHaveSignedState layout={layout} />
  );
};

export default HasSignedActions;
