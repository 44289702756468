// @flow

import { connect } from 'react-redux';
import permissionsReducer from 'reducers/entities/permissions';

import RoleUpdateEvent from './role-update-event';

const QUERY_NAME_PERMISSIONS = 'admin/permissions';

export const mapStateToProps = (state: State) => {
  const query = permissionsReducer.getQuerySelector(state, { name: QUERY_NAME_PERMISSIONS });
  const permissions = permissionsReducer.getPermissionsSelector(state,
    { ids: query.result });

  return {
    permissions,
    permissionsQuery: query,
  };
};

let cachedQuery;

export const mapDispatchToProps = (dispatch: Function) => ({
  queryPermissions: ({ params }: Query) => {
    // Use the cached query if it exists
    if (cachedQuery) return cachedQuery;

    // Otherwise create a new query
    const query = permissionsReducer?.queryPermissions({
      name: QUERY_NAME_PERMISSIONS,
      params,
    });

    dispatch(query);
    cachedQuery = query;
    return query;
  },
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(RoleUpdateEvent);
