/* eslint-disable import/named */
import { get, isUndefined } from 'lodash';

import { isSigningInProgress, isDecliningInProgress } from 'agreement';
import { getAgreementMyParticipant } from 'agreement/selectors';
import { isBlank, isTemplate, isConcluded } from 'agreement/states';
import { isViewer } from 'agreement/participant/participant';
import { setItem, getItem } from 'utils/local-storage';

import { getErrorMessage, unknownApiError } from 'components/api-error';
import { isUserLimited } from 'user';

export const areSettingsDisabled = (agreement: Oneflow.Agreement, position?: Oneflow.Position) => {
  const myParticipant = getAgreementMyParticipant(agreement);
  const template = isTemplate(agreement);
  const isDisabledState = isConcluded(agreement)
    || isSigningInProgress(agreement)
    || isDecliningInProgress(agreement)
    || !myParticipant
    || isViewer(myParticipant);

  if (isDisabledState && !template) {
    return true;
  }

  if (position && isUserLimited(position)) {
    return true;
  }

  if (isBlank(agreement) || template) {
    return false;
  }

  return true;
};

export const getError = (failure: Error) => {
  const errorCode = get(failure, 'body.api_error_code');
  const errorMessage = getErrorMessage(errorCode);

  if (!errorMessage) {
    return unknownApiError;
  }
  return errorMessage;
};

export const toggles = {
  comments: 'comments',
  counterpartCommentsResolve: 'counterpartCommentsResolve',
} as const;

export const shouldBeDisabled = (agreement: Oneflow.Agreement) => (
  isConcluded(agreement)
    || isSigningInProgress(agreement)
    || isDecliningInProgress(agreement)
    || (!isTemplate(agreement) && !getAgreementMyParticipant(agreement))
);

export const LS_ACCORDION_KEY = 'expanded_settings_sections';
export const sectionNames = [
  'general',
  'counterpartyPreferences',
  'formattingOptions',
  'signingAndSecurity',
  'contentSections',
];

export type States = {
  [key: typeof sectionNames[number]]: boolean,
};

export const getInitialAccordionState = (name: typeof sectionNames[number]) => {
  const states = JSON.parse(getItem(LS_ACCORDION_KEY) || '{}') as States;
  const currentAccordion = states[name];

  if (isUndefined(currentAccordion)) {
    return true;
  }
  return currentAccordion;
};

export const getAccordionStates = () => JSON.parse(getItem(LS_ACCORDION_KEY) || '{}');

export const persistAccordionStates = (newStates: States) => setItem(
  LS_ACCORDION_KEY,
  JSON.stringify(newStates),
);
