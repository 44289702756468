// @flow

import * as React from 'react';
import clsx from 'clsx';
import Field from 'components/field';
import { uniqueId } from 'lodash';

import style from './toggle-switch.module.scss';

type Props = {
  name: string,
  disabled?: boolean,
  valueLabels: Array<string>,
  defaultValue?: boolean,
};

type Input = {
  checked: boolean,
  name: string,
  onBlur: () => void,
  onChange: () => void,
  onFocus: () => void,
};

type CheckboxProps = {
  input: Input,
  valueLabels: Array<string | React.ReactElement>,
  disabled: boolean,
  toggleSwitchClass?: string,
  leftLabelClass?: string,
  rightLabelClass?: string,
  sliderClass?: string,
};

export const Checkbox = ({
  input,
  valueLabels,
  disabled,
  toggleSwitchClass,
  leftLabelClass,
  rightLabelClass,
  sliderClass,
}: CheckboxProps) => {
  const {
    checked,
    name,
    onBlur,
    onChange,
    onFocus,
  } = input;
  const [leftLabel, rightLabel] = valueLabels;
  const id = uniqueId();

  const toggleSwitchClasses = clsx(style.ToggleSwitch, {
    [style.Disabled]: disabled,
  }, toggleSwitchClass);
  const leftLabelClasses = clsx(style.Label, leftLabelClass, {
    [style.Toggled]: !checked,
    [style.Disabled]: disabled,
  });
  const rightLabelClasses = clsx(style.Label, rightLabelClass, {
    [style.Toggled]: checked,
    [style.Disabled]: disabled,
  });
  const sliderClasses = clsx(style.Slider, sliderClass, {
    [style.Toggled]: checked,
    [style.Disabled]: disabled,
  });

  return (
    <div className={toggleSwitchClasses}>
      <div className={sliderClasses} />
      <p className={leftLabelClasses}>{leftLabel}</p>
      <p className={rightLabelClasses}>{rightLabel}</p>
      <input
        type="checkbox"
        name={name}
        id={`checkbox-${id}`}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  );
};

const ToggleSwitch = ({
  name,
  disabled,
  valueLabels,
  defaultValue,
  ...props
}: Props) => (
  <Field
    name={name}
    type="checkbox"
    component={Checkbox}
    valueLabels={valueLabels}
    disabled={disabled}
    defaultValue={defaultValue || false}
    {...props}
  />
);

export default ToggleSwitch;
