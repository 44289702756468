// @flow

import * as React from 'react';
import { isEmpty, isFunction, partial } from 'lodash';

import {
  isDraft,
  isOverdue,
  isPending,
  isAnySignedState,
  hasCanceledTimestamp,
  isCancelContractVisible,
  isActive,
  isDelegateVisible,
} from 'agreement';
import joinListWithSeparator from 'utils/join-list-with-separator';
import { checkAcl } from 'components/acl';

import { isImportInProgress } from 'agreement/import';
import { isMessageParticipantsVisible, isSharedFromAnotherWorkspace, getMyParticipantWhenUpdater } from 'agreement/selectors';
import { isTemplate } from 'agreement/states';
import * as permissions from 'agreement/permissions';
import * as workspacePermissions from 'workspace/permissions';
import { isPendingStateApprover } from 'agreement/participant/is-pending-state-approver';
import ChevronDownIcon from 'components/icons/chevron-down';
import ChevronUpIcon from 'components/icons/chevron-up';
import UseTemplateIcon from 'components/icons/use-template';
import CopyContract from 'components/contract-actions/copy-contract';
import { CreateDocumentFromTemplate } from 'components/create-document-from-template';
import CreateInternalReminder from 'components/contract-actions/create-internal-reminder';
import CreateTemplateFromContract from 'components/contract-actions/create-template-from-contract';
import { getHiddenSections } from 'components/contract-actions/set-all-hidden-sections-collapsed/set-all-hidden-sections-collapsed';
import DelegateSigning from 'components/contract-actions/delegate-signing';
import { DeleteContract } from 'components/contract-actions/delete-contract';
import DeleteTemplate from 'components/contract-actions/delete-template';
import DownloadPdf from 'components/contract-actions/download-pdf';
import DropdownMenu from 'components/dropdown-menu';
import LinkToContract from 'components/contract-actions/link-to-contract';
import { MarkAsDeclined } from 'components/contract-actions/mark-as-declined';
import { MarkAsSigned } from 'components/contract-actions/mark-as-signed';
import { DelegatePendingApproval } from 'components/contract-actions/delegate-pending-approval';
import { MarkAsTerminated } from 'components/contract-actions/mark-as-terminated';
import { MenuItem } from 'components/menu-item';
import Message from 'components/message';
import { MessageParticipants } from 'components/contract-actions/message-participants';
import MoveContract from 'components/contract-actions/move-contract';
import { ReplaceContractItem } from 'components/contract-actions/replace-contract';
import SetAllHiddenSectionsCollapsed from 'components/contract-actions/set-all-hidden-sections-collapsed';
import SetContractName from 'components/contract-actions/set-contract-name';
import SetContractValue from 'components/contract-actions/set-contract-value';
import AddTag from 'components/contract-actions/add-tag';
import AiReview from 'components/contract-actions/ai-review';
import CancelDocument from 'components/contract-actions/cancel-document';

type ContractOptionAvailable = {
  agreement: Agreement,
  location: Location,
  visibleInContractOnly: boolean,
  visibleInImportInProgress: boolean,
}

export const isContractOptionAvailable = ({
  agreement,
  location,
  visibleInImportInProgress,
  visibleInContractOnly,
}: ContractOptionAvailable): boolean => {
  if (permissions.isContractOnly(location) && !visibleInContractOnly) {
    return false;
  }

  if (isImportInProgress(agreement) && !visibleInImportInProgress) {
    return false;
  }

  return true;
};

export type Props = {|
  isDropUp: boolean,
  agreement: Agreement,
  workspace: Workspace,
  account: Account,
  location: Location,
  isPristine: boolean,
  onContractUpdate: any => void,
  children: React.Node,
  menuClassName: string,
  disableDropdownIcon?: boolean,
  portal?: boolean,
  onMenuItemOpen: () => void,
  onChange?: (visible) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  agreementId: number,
  showReplaceContractMenuItem?: boolean,
  boxes: Array<Box>,
  dataFieldExternalKeyMap: any,
  dataFieldExternalKeyValueMap: any,
  agreementAllowsDelegate: boolean,
  canParticipantDelegate: boolean,
|};

type ActionOption = {
  menuItem: React.Node,
  visibleInImportInProgress: boolean,
  visibleInContractOnly: boolean,
  isActionVisible?: (agreement: Agreement) => boolean,
}

export default class ContractOptions extends React.PureComponent<Props> {
  static defaultProps = {
    disableDropdownIcon: undefined,
    portal: undefined,
  };

  getActions = (): Array<React.Node> => {
    const {
      agreement,
    } = this.props;

    if (isEmpty(agreement)) {
      return [];
    }

    if (isTemplate(agreement)) {
      return this.getTemplateActions();
    }

    return this.getContractActions();
  };

  getAvailableActions = (actionsList: Array<ActionOption>) => {
    const {
      agreement,
      location,
    } = this.props;

    return (actionsList
      // eslint-disable-next-line no-unused-vars
      .filter(({ isActionVisible = (...args) => true }) => isActionVisible(agreement))
      .filter(({ visibleInContractOnly, visibleInImportInProgress }) => isContractOptionAvailable({
        agreement,
        location,
        visibleInContractOnly,
        visibleInImportInProgress,
      })).map(({ menuItem }) => menuItem): Array<React.Node>);
  }

  getTemplateActions = (): Array<React.Node> => {
    const {
      agreement,
      workspace,
      onContractUpdate,
      boxes,
      dataFieldExternalKeyMap,
      dataFieldExternalKeyValueMap,
    } = this.props;

    let availableTemplateActions = [];

    const hiddenSections = getHiddenSections(
      boxes,
      dataFieldExternalKeyValueMap,
      dataFieldExternalKeyMap,
    );

    const setActions = [
      {
        menuItem: (
          <SetContractName agreement={agreement} />
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      },
      {
        menuItem: (
          <SetAllHiddenSectionsCollapsed
            agreement={agreement}
          />
        ),
        isActionVisible: () => !isEmpty(hiddenSections),
        visibleInImportInProgress: false,
        visibleInContractOnly: false,
      },
      {
        menuItem: (
          <AddTag agreement={agreement} />
        ),
        isActionVisible: () => (
          checkAcl(agreement.acl, 'agreement:tag:use')
          && !isSharedFromAnotherWorkspace(agreement, workspace.id)
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      },
    ];

    const editActions = [
      {
        menuItem: (
          <MoveContract
            agreement={agreement}
            disabled={!workspacePermissions.allowTemplateMove(workspace)}
            onSuccess={partial(onContractUpdate, agreement)}
          />
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      }, {
        menuItem: (
          <CopyContract
            agreement={agreement}
            amplitudeScope="contract view - copy template modal"
          />
        ),
        visibleInImportInProgress: false,
        visibleInContractOnly: false,
      },
      {
        menuItem: (
          <DownloadPdf
            agreement={agreement}
            amplitudeScope="contract view"
          />
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      },
    ];

    const createActions = [
      {
        menuItem: (
          <CreateDocumentFromTemplate agreement={agreement}>
            {(handleCreateContractClick) => (
              <MenuItem
                label={(
                  <Message
                    id="Use template"
                    comment="The activate template in template options."
                  />
                )}
                icon={UseTemplateIcon}
                disabled={!(checkAcl(workspace.acl, 'collection:agreement:create'))}
                onClick={handleCreateContractClick}
              />
            )}
          </CreateDocumentFromTemplate>
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: false,
      },
    ];

    const deleteActions = [{
      menuItem: (
        <DeleteTemplate
          agreement={agreement}
        />
      ),
      visibleInImportInProgress: true,
      visibleInContractOnly: false,
    }];

    availableTemplateActions = joinListWithSeparator([
      this.getAvailableActions(createActions),
      this.getAvailableActions(setActions),
      this.getAvailableActions(editActions),
      this.getAvailableActions(deleteActions),
    ], 'separator').flat();

    return availableTemplateActions;
  };

  getContractActions = (): Array<React.Node> => {
    const {
      agreement,
      account,
      isPristine,
      onContractUpdate,
      workspace,
      showReplaceContractMenuItem,
      boxes,
      dataFieldExternalKeyMap,
      dataFieldExternalKeyValueMap,
      agreementAllowsDelegate,
      canParticipantDelegate,
    } = this.props;

    let availableContractOptions = [];
    const myParticipant = getMyParticipantWhenUpdater(agreement);

    const hiddenSections = getHiddenSections(
      boxes,
      dataFieldExternalKeyValueMap,
      dataFieldExternalKeyMap,
    );

    const isCancelMenuItemVisible = isAnySignedState(agreement) && !hasCanceledTimestamp(agreement)
      && isCancelContractVisible(agreement) && isActive(agreement);

    const setActions = [
      {
        menuItem: (
          <SetContractName agreement={agreement} />
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: false,
      },
      {
        menuItem: (
          <SetAllHiddenSectionsCollapsed
            agreement={agreement}
          />
        ),
        isActionVisible: () => !isEmpty(hiddenSections),
        visibleInImportInProgress: false,
        visibleInContractOnly: true,
      }, {
        menuItem: (
          <SetContractValue agreement={agreement} />
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      }, {
        menuItem: (
          <AddTag agreement={agreement} />
        ),
        isActionVisible: () => checkAcl(agreement.acl, 'agreement:tag:use'),
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      }, {
        menuItem: (
          <AiReview
            agreement={agreement}
            visible={checkAcl(agreement.acl, 'agreement:ai_review:view')}
            location="contract view - action menu"
          />
        ),
        visibleInImportInProgress: false,
        visibleInContractOnly: true,
      },
    ];

    const editActions = [{
      menuItem: (
        <MoveContract
          agreement={agreement}
          disabled={!workspacePermissions.allowContractMove(workspace)}
          onSuccess={partial(onContractUpdate, agreement)}
        />
      ),
      visibleInImportInProgress: false,
      visibleInContractOnly: false,
    }, {
      menuItem: (
        <CreateTemplateFromContract agreement={agreement} />
      ),
      visibleInImportInProgress: false,
      visibleInContractOnly: false,
    }, {
      menuItem: (
        <CopyContract
          agreement={agreement}
          amplitudeScope="contract view - copy contract modal"
        />
      ),
      visibleInImportInProgress: false,
      visibleInContractOnly: false,
    }, {
      menuItem: (
        <LinkToContract agreement={agreement} />
      ),
      visibleInImportInProgress: false,
      visibleInContractOnly: false,
    }, {
      menuItem: (
        <ReplaceContractItem
          agreement={agreement}
        />
      ),
      visibleInImportInProgress: false,
      visibleInContractOnly: true,
      isActionVisible: () => showReplaceContractMenuItem,
    }, {
      menuItem: (
        <DownloadPdf
          agreement={agreement}
          amplitudeScope="contract view"
        />
      ),
      visibleInImportInProgress: true,
      visibleInContractOnly: true,
    },
    ];

    const concludeActions = [
      {
        menuItem: (
          <CancelDocument
            agreement={agreement}
            isDisabled={isEmpty(myParticipant)}
          />
        ),
        isActionVisible: () => isCancelMenuItemVisible,
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      },
      {
        menuItem: (
          <DelegateSigning
            agreement={agreement}
          />
        ),
        isActionVisible: () => (
          isDelegateVisible(agreement)
          && permissions.isSignContractVisible(agreement)
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      },
      {
        menuItem: (
          <DelegatePendingApproval
            agreement={agreement}
          />
        ),
        isActionVisible: () => (
          agreementAllowsDelegate && canParticipantDelegate && isPendingStateApprover(myParticipant)
        ),
        visibleInImportInProgress: false,
        visibleInContractOnly: true,
      },
      {
        menuItem: (
          <MarkAsTerminated
            agreement={agreement}
            onSuccess={partial(onContractUpdate, agreement)}
          />
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      }, {
        menuItem: (
          <MarkAsDeclined
            agreementId={agreement.id}
            disabled={!isPristine || !(
              isDraft(agreement) || isPending(agreement) || isOverdue(agreement)
            )}
            onSuccess={partial(onContractUpdate, agreement)}
          />
        ),
        isActionVisible: () => !isCancelMenuItemVisible,
        visibleInImportInProgress: true,
        visibleInContractOnly: false,
      },
      {
        menuItem: (
          <MarkAsSigned
            agreementId={agreement.id}
            disabled={!isPristine}
          />
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: true,
      },
    ];

    const dateActions = [
      {
        menuItem: (
          <CreateInternalReminder
            agreement={agreement}
            visualTweak="overrideLegacyStyling"
          />
        ),
        visibleInImportInProgress: false,
        visibleInContractOnly: true,
      },
      {
        menuItem: (
          <MessageParticipants
            agreement={agreement}
            account={account}
          />
        ),
        visibleInImportInProgress: false,
        visibleInContractOnly: true,
        isActionVisible: isMessageParticipantsVisible,
      },
    ];

    const deleteActions = [
      {
        menuItem: (
          <DeleteContract
            agreement={agreement}
          />
        ),
        visibleInImportInProgress: true,
        visibleInContractOnly: false,
      },
    ];

    availableContractOptions = joinListWithSeparator([
      this.getAvailableActions(setActions),
      this.getAvailableActions(editActions),
      this.getAvailableActions(concludeActions),
      this.getAvailableActions(dateActions),
      this.getAvailableActions(deleteActions),
    ], 'separator').flat();

    return availableContractOptions;
  }

  handleOnVisibilityChange = (visible: boolean) => {
    const { onMenuItemOpen, onChange } = this.props;
    onChange?.(visible);

    if (!visible || !isFunction(onMenuItemOpen)) {
      return;
    }

    onMenuItemOpen();
  };

  renderDropdownIcon() {
    const { isDropUp } = this.props;

    if (isDropUp) {
      return <ChevronUpIcon />;
    }

    return <ChevronDownIcon />;
  }

  render() {
    const {
      children,
      disableDropdownIcon,
      menuClassName,
      portal,
    } = this.props;
    const actions = this.getActions();

    if (actions.length === 0) {
      return null;
    }

    return (
      <DropdownMenu
        items={actions}
        anchor={{
          x: 'inner-right',
          y: 'below',
        }}
        className={menuClassName}
        dropdownIcon={this.renderDropdownIcon()}
        hideMenuOnExit
        portal={portal}
        disableDropdownIcon={disableDropdownIcon}
        onVisibilityChange={this.handleOnVisibilityChange}
        focusOnCloseDisabled
      >
        {children}
      </DropdownMenu>
    );
  }
}
