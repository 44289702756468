import { useState } from 'react';
import Message from 'components/message';
import CancelSubscriptionFlow from 'components/cancel-subscription/flows/cancel-subscription-flow';
import type { FlowData } from 'types/cancellation-flow';
import GetInTouchConfirm from '../../get-in-touch/get-in-touch';

type Props = {
  onClose: () => void;
  onSyncFlowData: (data: FlowData) => void;
  onPreviousStep: () => void;
  setCurrentFlow: (reference: string) => void;
  onSubmit: (
    data: FlowData,
    submitHandler: () => void,
    submitErrorHandler: () => void,
  ) => void;
};

const LetUsHelp = ({
  onClose,
  onSyncFlowData,
  onPreviousStep,
  setCurrentFlow,
  onSubmit,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const renderError = () => {
    if (!hasError) {
      return null;
    }

    return (
      <CancelSubscriptionFlow.SubmitError />
    );
  };

  return (
    <CancelSubscriptionFlow
      onCancel={onClose}
      setFlow={setCurrentFlow}
      modalKey="let-us-help"
    >
      <CancelSubscriptionFlow.Steps>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="Let us help"
              comment="Header in the get in touch modal"
            />
          )}
          handlePreviousStep={onPreviousStep}
          allowContinue={!isLoading}
        >
          <CancelSubscriptionFlow.StepBody>
            <Message
              id="We want to offer a ✨ free consultation ✨ with a Oneflow specialist, to tackle some of your
              challenges. Before you decide to cancel your Subscription Plan, let us reach out to book a session with our specialist."
              comment="Text in a cancellation flow get in touch modal"
            />
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            {renderError()}
            <CancelSubscriptionFlow.ShowFlow
              kind="linkSeparate"
              flow="confirm_cancel"
            >
              <Message
                id="No, cancel Subscription Plan"
                comment="Header in the cancellation reasons modal"
              />
            </CancelSubscriptionFlow.ShowFlow>
            <CancelSubscriptionFlow.Submit
              kind="primary"
              onSubmit={
                (modalData, stepCompleteHandler: () => void) => {
                  setIsLoading(true);
                  setHasError(false);
                  const submitDoneHandler = () => {
                    setIsLoading(false);
                    stepCompleteHandler();
                  };
                  const submitErrorHandler = () => {
                    setIsLoading(false);
                    setHasError(true);
                  };
                  onSubmit(modalData, submitDoneHandler, submitErrorHandler);
                }
              }
              syncData={{ accepted_help: true, book_time: true, message: '' }}
              onSyncFlowData={onSyncFlowData}
            >
              <Message
                id="Reach out to me"
                comment="Button text to get in touch in cancallation flow"
              />
            </CancelSubscriptionFlow.Submit>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="We'll be in touch"
              comment="Header in the get in touch modal"
            />
          )}
          hidePrevious
        >
          <CancelSubscriptionFlow.StepBody>
            <GetInTouchConfirm />
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            <CancelSubscriptionFlow.Close
              kind="primary"
            >
              <Message
                id="Close"
                comment="Button text to close get in touch confirmation modal"
              />
            </CancelSubscriptionFlow.Close>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
      </CancelSubscriptionFlow.Steps>
    </CancelSubscriptionFlow>
  );
};

export default LetUsHelp;
