// @flow

import {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import clsx from 'clsx';
// eslint-disable-next-line import/no-unresolved
import type { TemplateGroup } from 'types/entities';
import { useDispatch, useSelector } from 'react-redux';
import useAgreement from 'hooks/use-agreement';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';
import { isTemplate } from 'agreement/states';
import { isContractPristine, getCurrentContractId } from 'reducers/current-contract';
import templateGroupsReducer from 'reducers/entities/template-groups';
import extensionsReducer from 'reducers/entities/extensions';
import agreementsReducer from 'reducers/entities/agreements';
import { checkAcl } from 'components/acl';
import Button from 'components/button';
import Confirmable from 'components/confirmable';
import SelectField from 'components/select-field/select-field';
import WarningIcon from 'components/icons/warning';
import CircularSpinnerIcon from 'components/icons/circular-spinner';
import ChangeTemplateGroupIcon from 'components/icons/change-template-group';
import { KEY_TEMPLATE_GROUPS } from 'extensions';

import style from './change-template-group.module.scss';

export type Props = {
  setSelectedTemplateGroupIdForContext?: (id: number) => void,
  message: MessageTranslator,
};

type ChangeTemplateGroupProps = Props & {
  message: MessageTranslator,
}

type ResponseState = {
  success: boolean,
  error: null | string,
  loading: boolean,
};

export type State = {
  modalOpen: boolean,
  selectedGroupId: number,
  responseState: ResponseState,
};

type Option = {
  label: string,
  value: string
}

const initResponseState = {
  success: false,
  loading: false,
  error: null,
};

const getOptionsLabel = (templateGroup: TemplateGroup, message: MessageTranslator) => {
  if (!templateGroup.active) {
    return `${message({ id: '[Inactive]', comment: 'Label for inactive template group in template group selector' })} ${templateGroup.name}`;
  }
  return templateGroup.name;
};

export const ChangeTemplateGroup = ({
  setSelectedTemplateGroupIdForContext,
  message,
  // check if we still need comment below
  // TODO_PDF_FIELD: Update component so that there's only one state
  // (not one here and one in context)
}: ChangeTemplateGroupProps) => {
  const agreementId = useSelector(getCurrentContractId);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [responseState, setResponseState] = useState(initResponseState);
  const agreement = useAgreement(agreementId);
  const isTemplateGroupAssigned = Boolean(agreement?.templateGroup);
  const isPristine = useSelector((state) => isContractPristine(state));
  const originalGroupId = useRef(0);
  const { resetRPCStates, updateAgreementState } = useAPIError(agreementId);

  useEffect(() => {
    if (agreement) {
      const groupId = agreement.templateGroup?.id || 0;
      setSelectedGroupId(groupId);
      setSelectedTemplateGroupIdForContext?.(groupId);
      originalGroupId.current = groupId;
    }
  }, [agreement, setSelectedTemplateGroupIdForContext]);

  const TEMPLATE_GROUPS_QUERY = '/admin/account/extensions/template-groups';
  const dispatch = useDispatch();
  const extension = useSelector((state) => (
    extensionsReducer.getExtensionSelector(state, { id: KEY_TEMPLATE_GROUPS })
  ));
  const isExtensionActive = extension.state;
  const templateGroupsQuery = useSelector((state) => (
    templateGroupsReducer.getQuerySelector(state, { name: TEMPLATE_GROUPS_QUERY })
  ));
  const templateGroups = useSelector((state) => (
    templateGroupsReducer.getTemplateGroupsSelector(state, { ids: templateGroupsQuery.result })
  ));

  const currentTemplateGroupId = agreement?.templateGroup?.id;

  useEffect(() => {
    if (!isExtensionActive) {
      return;
    }

    dispatch(templateGroupsReducer.queryTemplateGroups({ name: TEMPLATE_GROUPS_QUERY }));
  }, [dispatch, isExtensionActive]);

  const getTemplateGroupOptions = useCallback(() => {
    const activeGroups = templateGroups.filter((templateGroup) => (
      templateGroup.active || (templateGroup.id === currentTemplateGroupId)
    ));

    let options = activeGroups.map((templateGroup) => ({
      value: templateGroup.id,
      label: getOptionsLabel(templateGroup, message),
      isDisabled: !templateGroup.active,
    }));

    options.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });

    const getNoGroupMessage = () => {
      if (templateGroupsQuery.loading) {
        return (
          <Message
            id="Loading template groups"
            comment="Text showing when template groups are loading"
          />
        );
      }
      if (activeGroups.length) {
        return (
          <Message
            id="No template group"
            comment="Text showing when there is no template group selected"
          />
        );
      }
      return (
        <Message
          id="No active template groups"
          comment="Text showing when there are no active template groups"
        />
      );
    };

    options = [
      { value: 0, label: getNoGroupMessage() },
      ...options,
    ];

    return options;
  }, [templateGroups, currentTemplateGroupId, message, templateGroupsQuery.loading]);

  const changeTemplateGroup = useCallback(async (groupId: number) => {
    resetRPCStates();
    setResponseState({ ...initResponseState, loading: modalOpen });
    dispatch(agreementsReducer.changeTemplateGroup({
      id: agreementId,
      data: {
        templateGroup: parseInt(groupId, 10) || null,
      },
      pipe: {
        onSuccess: () => {
          setResponseState({ ...initResponseState, success: modalOpen });
        },
        onFailure: (error) => {
          setResponseState({ ...initResponseState, error });
        },
      },
    }));
  }, [resetRPCStates, dispatch, agreementId, modalOpen]);

  const renderBody = () => (
    <div className={style.ModalContainer}>
      <ChangeTemplateGroupIcon width="75px" height="75px" />
      <h5 className={style.Title}>
        <WarningIcon height="14px" />
        {' '}
        <Message
          id="Changing template group will"
          comment="Message before list of conseuqences of changing template group"
        />
      </h5>
      <ul className={style.ConsequenceList}>
        <li>
          <Message
            id="Unlink all data fields from this template."
            comment="Message explaining data fields will be unlinked from template if template group is changed."
          />
        </li>
        <li>
          <Message
            id="You will also lose any custom changes made to data fields in this template."
            comment="Message explaining custom changes to data fields will be lost if template group is changed."
          />
        </li>
      </ul>
      <p><Message id="Are you sure you want to continue?" comment="Asking user if they are sure they want to proceed with their chosen course of action." /></p>
    </div>
  );

  const onClose = useCallback(() => {
    // make sure to get the real value, if closing modal without saving
    const groupId = agreement.templateGroup?.id || 0;
    setSelectedGroupId(groupId);

    setModalOpen(false);
    setResponseState(initResponseState);
  }, [agreement]);

  const onChange = useCallback((option: Option) => {
    const groupId = parseInt(option.value, 10);

    if (groupId !== originalGroupId.current) {
      setSelectedGroupId(groupId);
      if (isTemplateGroupAssigned) {
        setModalOpen(true);
        return;
      }

      changeTemplateGroup(groupId);
    }
  }, [changeTemplateGroup, isTemplateGroupAssigned]);

  const renderActions = useCallback((
    actions: { closeConfirmation: () => void },
  ) => (
    <>
      <Button
        onClick={actions.closeConfirmation}
        disabled={responseState.loading}
      >
        <Message
          id="Cancel"
          comment="Text for button to cancel changing template group."
        />
      </Button>
      <Button
        kind="primary"
        data-testid="confirm"
        onClick={async () => await changeTemplateGroup(selectedGroupId)}
        disabled={responseState.loading}
        icon={responseState.loading ? CircularSpinnerIcon : undefined}
      >
        <Message
          id="Change template group"
          comment="Text for button to confirm changing template group."
        />
      </Button>
    </>
  ), [responseState.loading, changeTemplateGroup, selectedGroupId]);

  const getChildren = useCallback(() => {
    const input = { onChange, value: selectedGroupId, name: 'template-group' };

    const label = () => (
      <div>
        <Message
          id="Select template group"
          comment="Label for the template's template group"
        />
      </div>
    );

    const disabled = !isPristine
      || !checkAcl(agreement.acl, 'agreement:update:template_group_id')
      || !templateGroups.length
      || templateGroupsQuery.loading
      || !isTemplate(agreement);

    const classes = clsx(style.TemplateGroupSelector, {
      [style.Disabled]: disabled,
    });

    return (
      <div className={classes}>
        <SelectField
          disabled={disabled}
          input={input}
          selectedGroupId={selectedGroupId}
          label={label()}
          options={getTemplateGroupOptions()}
          labelKey="label"
          multi={false}
          searchable={false}
          clearable={false}
          noScroll
          hideErrorElement
        />
      </div>
    );
  }, [
    agreement,
    onChange,
    selectedGroupId,
    isPristine,
    templateGroups.length,
    templateGroupsQuery.loading,
    getTemplateGroupOptions,
  ]);

  // TODO_DATA_FIELDS: This might not be necessary if we decide not to show the tab at all
  if (
    !agreement || !isExtensionActive
  ) {
    return null;
  }

  return (
    <Confirmable
      header={(
        <Message
          id="Change template group?"
          comment="Modal title when the user wants to change the template group."
        />
      )}
      customModalClass={style.ChangeTemplateModal}
      body={renderBody()}
      customBodyClass={style.ModalBody}
      actions={renderActions}
      onEnter={() => changeTemplateGroup(selectedGroupId)}
      isOpen={modalOpen}
      success={responseState.success}
      error={modalOpen ? responseState.error : updateAgreementState?.error}
      onClose={onClose}
      modalKey="change template group modal"
    >
      {getChildren}
    </Confirmable>
  );
};

export default localize<ChangeTemplateGroupProps>(ChangeTemplateGroup);
