import Message from 'components/message';

import style from './empty-state-filter.module.scss';

type Props = {
  searchTerm: string,
};

const EmptyStateFilter = ({ searchTerm }: Props) => (
  <div className={style.EmptyState}>
    <div className={style.NoMatchFound} data-testid="no-match-found-message">
      <Message
        id="No match found for "
        comment="Message explaining that no match was found for the search term in the data fields tab."
      />
      {searchTerm && (
      <span className={style.SearchTerm}>
        {`"${searchTerm}"`}
      </span>
      )}
    </div>
    <ul className={style.UserSuggestion} data-testid="user-suggestion">
      <li><Message id="Double-check for typos" comment="Suggestion to check for typos in the search term." /></li>
      <li><Message id="Try different keywords" comment="Suggestion to try different keywords in the search term." /></li>
    </ul>
  </div>
);

export default EmptyStateFilter;
