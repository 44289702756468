// @flow

import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { getDateFormat } from 'date';
import { getAccountFromSessionSelector } from 'reducers/session';
import {
  getCurrentWorkspaceSelector,
  setDashboardDateRange,
  getDashboardDateRangeSelector,
  setDashboardUser,
  getDashboardUserSelector,
} from 'reducers/app';
import dashboardReducer from 'reducers/entities/dashboard';

import DashboardComponent from './dashboard';
import type { Props, QueryStatisticsDateParams } from './dashboard';

const DASHBOARD_QUERY_NAME = 'dashboard';

type OwnProps = {|
  position: Position,
|};

type StateProps = {|
  currentWorkspace: Workspace,
  data: any,
  dateFormat: string,
  dateRange: Object,
  loading: boolean,
  user: string,
|};

type DispatchProps = {|
  setDashboardDateRange: (Object) => void,
  setDashboardUser: (string) => void,
  queryDashboard: QueryStatisticsDateParams => void,
|};

type MapStateToProps = (state: State) => StateProps;

type MapDispatchToProps = (dispatch: Dispatch<*>, OwnProps: OwnProps) => DispatchProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);
  const currentWorkspace = getCurrentWorkspaceSelector(state);
  const brandingDateFormat = currentWorkspace.brandingDateFormat || account.brandingDateFormat;
  const query = dashboardReducer.getQuerySelector(state, { name: DASHBOARD_QUERY_NAME });
  const dashboardData = dashboardReducer.getDashboardsSelector(state, { ids: query.result });

  return {
    currentWorkspace: getCurrentWorkspaceSelector(state),
    data: dashboardData[0],
    dateFormat: getDateFormat(brandingDateFormat),
    dateRange: getDashboardDateRangeSelector(state),
    loading: query.loading,
    user: getDashboardUserSelector(state),
  };
};

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { position }) => ({
  setDashboardDateRange: (dateRange) => (
    dispatch(
      setDashboardDateRange({ dateRange }),
    )
  ),
  setDashboardUser: (user) => (
    dispatch(
      setDashboardUser({ user }),
    )
  ),
  queryDashboard: (params: QueryStatisticsDateParams) => {
    dispatch(
      dashboardReducer.queryDashboards({
        name: DASHBOARD_QUERY_NAME,
        params: {
          ...params,
          positions: params.user === 'me' ? position.id : undefined,
        },
      }),
    );
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardComponent);
