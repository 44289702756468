/* eslint-disable react/prop-types */
// @flow
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setLanguage } from 'reducers/i18n';
import useAgreement from 'hooks/use-agreement';

import { isAgreementOwner } from 'agreement/selectors';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getContractMetaData } from 'reducers/current-contract';
import { isTemplate } from 'agreement/states';
import usePreviousUntilValuesChanged from 'hooks/use-previous-until-values-changed';
import useCurrentBoxes from 'hooks/use-current-boxes';

import CloseSidebarButton from 'components/expanded-layout-sidebar/close-sidebar-button';
import { ScrollableArea } from 'components/document-tabs/components/scrollable-area';
import { TabHeader } from 'components/document-tabs/components/tab-header';
import { getCurrentDurationData, isRemindMeVisible } from 'components/document-tabs/settings-tab/sections/duration-reminders/remind-me/helper';
import Message from 'components/message';

import GeneralSection from 'components/document-tabs/settings-tab/sections/general-section';
import FormattingOptionsSection from './sections/formatting-options-section';
import DurationRemindersSection from './sections/duration-reminders-section';
import CounterpartyPreferencesSection from './sections/counterparty-preferences-section';
import SigningAndSecuritySection from './sections/signing-and-security-section';
import APIError from './api-error';

import style from './settings-tab.module.scss';

type Props = {
  agreementId: Number,
  onClose?: () => void,
};

const SettingsTab = ({
  agreementId,
  onClose,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const account = useSelector(getAccountFromSessionSelector);
  const dispatch = useDispatch();

  const boxes = useCurrentBoxes();
  const { durationBoxId } = useSelector(getContractMetaData);
  const currentDurationData = usePreviousUntilValuesChanged(
    getCurrentDurationData(boxes, durationBoxId),
  );

  const isOwner = isAgreementOwner(account, agreement);

  const onAgreementLanguageUpdate = useCallback((language: string) => {
    dispatch(setLanguage(language));
  }, [dispatch]);

  const renderSettings = useCallback(() => {
    const onUpdate = {
      onAgreementLanguageUpdate,
    };

    return (
      <>
        <GeneralSection
          agreementId={agreementId}
          onUpdate={onUpdate}
        />
        <APIError agreementId={agreementId} />
      </>
    );
  }, [
    agreementId, onAgreementLanguageUpdate,
  ]);

  const renderHeader = () => {
    const showTemplateHeader = isTemplate(agreement);
    const showDocumentHeader = !showTemplateHeader;

    if (showDocumentHeader) {
      return (
        <Message
          id="Document settings"
          comment="Settings tab header"
        />
      );
    }

    if (showTemplateHeader) {
      return (
        <Message
          id="Template settings"
          comment="Settings tab header"
        />
      );
    }

    return null;
  };

  const renderOwnerSettings = useCallback(() => {
    if (!isOwner) {
      return null;
    }

    const showDurationSection = isRemindMeVisible(currentDurationData);
    return (
      <>
        <FormattingOptionsSection agreementId={agreementId} />
        <SigningAndSecuritySection
          agreementId={agreementId}
        />
        <CounterpartyPreferencesSection agreementId={agreementId} />
        {showDurationSection && <DurationRemindersSection agreementId={agreementId} />}
      </>
    );
  }, [
    isOwner,
    agreementId,
    currentDurationData,
  ]);

  return (
    <>
      <TabHeader>
        <h2
          data-testid="settings-tab-header"
          className={style.SettingsHeader}
        >
          {renderHeader()}
        </h2>
        {onClose && <CloseSidebarButton onClose={onClose} />}
      </TabHeader>

      <ScrollableArea className={style.SettingsTabContainer}>
        {renderSettings()}
        {renderOwnerSettings()}
      </ScrollableArea>
    </>
  );
};

export default SettingsTab;
