import {
  getUsedDeliveryChannels,
  getUsedMfas,
  getUsedSignMethods,
} from 'agreement/selectors';

import { modalData } from './constants';

// Keys are predefined from agreement.availableOptions<TYPE>
export const signMethodsMapper = {
  0: 'standardESignature',
  1: 'SMSVerification',
  2: 'swedishBankID',
  4: 'norwegianBankID',
  5: 'danishMitID',
  6: 'finnishFTN',
  10: 'handwrittenSignature',
  13: 'electronicID',
} as const;

export const deliveryChannelsMapper = {
  0: 'email',
  1: 'SMS',
  2: 'sameDevice',
  5: 'emailAndSMS',
} as const;

export const MfaDeliveryChannelsMapper = {
  none: 'none',
  email: 'email',
  sms: 'sms',
  personal_identification: 'personalIdentification',
} as const;

export type ComponentTypes = keyof typeof modalData;
// Types for keys of the mappers
type SignMethodsTypes = keyof typeof signMethodsMapper;
type DeliveryChannelTypes = keyof typeof deliveryChannelsMapper;
type MfaDeliveryChannelsTypes = keyof typeof MfaDeliveryChannelsMapper;

// Types for values of the mappers
type SignMethodType = typeof signMethodsMapper[SignMethodsTypes]
type DeliveryChannel = typeof deliveryChannelsMapper[DeliveryChannelTypes]
type MfaChannel = typeof MfaDeliveryChannelsMapper[MfaDeliveryChannelsTypes]

// Types for getLabel
type SignMethodsProps = { type: 'signMethods', option: keyof typeof signMethodsMapper }
type DeliveryChannelsProps = { type: 'deliveryChannels', option: keyof typeof deliveryChannelsMapper }
type MfaDeliveryChannelsProps = { type: 'mfaChannels', option: keyof typeof MfaDeliveryChannelsMapper }
type GetLabelProps =
  SignMethodsProps |
  DeliveryChannelsProps |
  MfaDeliveryChannelsProps

export const getLabel = ({ type, option }: GetLabelProps) => {
  if (type === 'signMethods') {
    const signMethod: SignMethodType = signMethodsMapper[option];
    return modalData[type][signMethod];
  }

  if (type === 'deliveryChannels') {
    const deliveryChannel: DeliveryChannel = deliveryChannelsMapper[option];
    return modalData[type][deliveryChannel];
  }

  if (type === 'mfaChannels') {
    const mfaChannel: MfaChannel = MfaDeliveryChannelsMapper[option];

    return modalData[type][mfaChannel];
  }

  return null;
};

// types for isDisabled
type IsDisabled = (
  SignMethodsProps
  | DeliveryChannelsProps
  | MfaDeliveryChannelsProps
) & { agreement: Oneflow.Agreement }

export const isDisabled = ({ type, option, agreement }: IsDisabled) => {
  if (type === 'signMethods') {
    const usedSignMethod = getUsedSignMethods(agreement);
    return usedSignMethod.includes(option);
  }

  if (type === 'deliveryChannels') {
    const usedDeliveryChannels = getUsedDeliveryChannels(agreement);
    return usedDeliveryChannels.includes(option);
  }

  if (type === 'mfaChannels') {
    const usedMfaChannel = getUsedMfas(agreement);
    return usedMfaChannel.includes(option);
  }

  return false;
};

// Config types
export const configTypes = {
  deliveryChannels: 'enabledDeliveryChannels',
  signMethods: 'enabledSignMethods',
  mfaChannels: 'enabledMfaChannels',
};

export const defaultConfigTypes = {
  deliveryChannels: 'defaultDeliveryChannel',
  signMethods: 'defaultSignMethod',
  mfaChannels: 'defaultMfaChannel',
} as const;

export type InitialSelectedOptionsState = {
  deliveryChannels?: number[] | null | undefined;
  signMethods?: number[] | null | undefined;
  mfaChannels?: string[];
}
export const getInitialSelectedOptionsState = (
  agreement: Oneflow.Agreement,
): InitialSelectedOptionsState => {
  const { config } = agreement;

  return ({
    deliveryChannels: config?.enabledDeliveryChannels
  || agreement.availableOptions?.deliveryChannels,
    signMethods: config?.enabledSignMethods
  || agreement.availableOptions?.signMethods,
    mfaChannels: config?.enabledMfaChannels
  || [...agreement.availableOptions?.mfaChannels ?? [], 'none'],
  });
};
