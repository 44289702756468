import { ReactNode, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduce, xor } from 'lodash';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import type { FormRenderProps } from 'react-final-form';

import agreementTemplateReducer from 'reducers/entities/agreement-templates';
// eslint-disable-next-line import/named
import { agreementTemplateListShares } from 'oneflow-client/agreement-templates';
import { getAllShareToWorkspaces } from 'reducers/entities/workspaces';

import ModalForm from 'hocs/modal-form';

import Button from 'components/button';
import Field from 'components/field';
import Checkbox from 'components/checkbox';
import EmptyState from 'components/empty-state';
import Template from 'components/icons/template';
import CircularSpinner from 'components/icons/circular-spinner';
import { InfoBox } from 'components/error-box';

import style from './share-template.module.scss';

export type AgreementTemplate = {
  agreement: Oneflow.Contract,
  id: number,
}

export type Props = {
  message: MessageTranslator;
  children: ReactNode | ReactNode[];
  template: AgreementTemplate;
};

export type SharedWorkspace = {
  id: number,
  name: string,
}

const QUERY_NAME = 'templates/contracts';

export const ShareContractTemplateModal = ({ template, children, message }: Props) => {
  const dispatch = useDispatch();
  const [currentSharedWorkspaces, setCurrentSharedWorkspaces] = useState({});
  const [initialSharedWorkspaces, setInitialSharedWorkspaces] = useState({});
  const [sharedWith, setSharedWith] = useState(null);

  const {
    shareAgreementTemplate,
    shareAgreementTemplateReset,
    getShareAgreementTemplateSelector,
    queryAgreementTemplatesReload,
  } = agreementTemplateReducer;

  const availableWorkspaces = useSelector(getAllShareToWorkspaces);

  const updateState = useSelector(
    (state) => getShareAgreementTemplateSelector(state, { id: template.id }),
  );

  const getValueForWorkspace = (id: number) => {
    const key = `c-${id}` as keyof typeof currentSharedWorkspaces;
    const value = currentSharedWorkspaces[key];
    return value;
  };

  const renderWorkspace = (workspace: Oneflow.Workspace) => (
    <div
      key={workspace.id}
      className={style.SelectWorkspace}
    >
      <div>{workspace.name}</div>
      <Field
        name={`c-${workspace.id}`}
        component={Checkbox}
        type="checkbox"
        value={getValueForWorkspace(workspace.id)}
        input={{
          name: `c-${workspace.id}`,
          checked: currentSharedWorkspaces && !!getValueForWorkspace(workspace.id),
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = event.target.checked;
            const newWorkspaces = {
              ...currentSharedWorkspaces,
              [`c-${workspace.id}`]: !!isChecked,
            };
            setCurrentSharedWorkspaces(newWorkspaces);
          },
        }}
      />
    </div>
  );

  const loadSharedWorkspaces = useCallback(async () => {
    try {
      const { collection } = await agreementTemplateListShares({
        agreementId: template.agreement.id,
      });

      const sharedWorkspaces = collection.reduce(
        (acc: object, workspace: SharedWorkspace) => ({
          ...acc,
          [`c-${workspace.id}`]: true,
        }), {},
      );
      setSharedWith(collection);
      setCurrentSharedWorkspaces(sharedWorkspaces);
      setInitialSharedWorkspaces(sharedWorkspaces);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error: ', error);
    }
  }, [template.agreement.id]);

  const onOpen = () => {
    loadSharedWorkspaces();
  };

  const selectAll = () => {
    const selectedWorkspaces = availableWorkspaces.reduce((acc, workspace) => ({
      ...acc,
      [`c-${workspace.id}`]: true,
    }), {});

    setCurrentSharedWorkspaces(selectedWorkspaces);
  };

  const clearAll = () => {
    setCurrentSharedWorkspaces({});
  };

  const getSelectedIds = () => reduce(currentSharedWorkspaces, (acc, selected, workspace) => {
    if (selected) {
      return [...acc, Number(workspace.split('-')[1])];
    }
    return acc;
  }, []);

  const isEverythingSelected = () => {
    const selectedWorkspaces = getSelectedIds();
    return selectedWorkspaces.length === availableWorkspaces.length;
  };

  const isNothingSelected = () => {
    const selectedWorkspaces = getSelectedIds();
    return selectedWorkspaces.length === 0;
  };

  const getWorkspacesPatch = () => {
    if (!sharedWith) {
      return [];
    }

    const sharedWithIds = sharedWith.map((workspace: SharedWorkspace) => workspace.id);
    const selectedWorkspaces = getSelectedIds();
    const changedWorkspaceIds = xor(sharedWithIds, selectedWorkspaces);
    return changedWorkspaceIds.map<{ id: number, enabled: boolean }>((id) => ({
      id,
      enabled: selectedWorkspaces.includes(id),
    }));
  };

  const hasChangedValues = () => {
    if (sharedWith) {
      return !!getWorkspacesPatch().length;
    }

    return false;
  };

  const onReset = () => {
    dispatch(shareAgreementTemplateReset({
      id: template.id,
    }));
  };

  const onClose = () => {
    if (!updateState.pristine) {
      dispatch(queryAgreementTemplatesReload({ name: QUERY_NAME }));
    }
  };

  const onSubmit = () => {
    const workspaces = getWorkspacesPatch();
    dispatch(shareAgreementTemplate({
      id: template.id,
      data: {
        agreementId: template.agreement.id,
        workspaces,
      },
    }));
  };

  const renderBody = () => {
    if (availableWorkspaces.length === 0) {
      return (
        <EmptyState
          icon={<Template height="33px" />}
          header={(
            <div className={style.EmptyStateHeader}>
              <Message
                id="No available workspaces"
                comment="Empty state header in the share template modal when there are no workspaces to share the template to."
              />
            </div>
          )}
          content={(
            <>
              <div className={style.EmptyStateContent}>
                <Message
                  id="You don't have permission to share this template with other workspaces."
                  comment="Empty state text in the share template modal when there are no workspaces to share the template to."
                />
              </div>
              <div className={style.EmptyStateContent}>
                <Message
                  id="Please contact your administrator if you need help."
                  comment="Empty state text in the share template modal when there are no workspaces to share the template to."
                />
              </div>
            </>
          )}
        />
      );
    }

    return (
      <div>
        <InfoBox
          bodyText={(
            <Message
              id="Shared contract templates are available as a read only. They can be used to create new contracts, but cannot be edited."
              comment="Help text. Explains how shared contract templates work."
            />
          )}
        />
        <div>
          <h4 className={style.Headers}>
            <span>
              <Message
                id="Workspace"
                comment="Column header in the share template modal."
              />
            </span>
            <span className={style.SelectActionsContainer}>
              <Button
                onClick={selectAll}
                kind="linkInline"
                customClass={style.SelectActions}
                disabled={isEverythingSelected()}
              >
                <Message
                  id="Select all"
                  comment="Column header in the share template modal."
                />
              </Button>
              <Button
                onClick={clearAll}
                kind="linkInline"
                customClass={style.SelectActions}
                disabled={isNothingSelected()}
              >
                <Message
                  id="Clear all"
                  comment="Column header in the share template modal."
                />
              </Button>
            </span>
          </h4>
        </div>
        <div className={style.AvailableWorkspaces}>
          {availableWorkspaces.map(renderWorkspace)}
        </div>
      </div>
    );
  };

  const renderActions = ({ formProps }: { formProps: FormRenderProps }) => {
    const disabled = Boolean(
      (formProps.pristine && !hasChangedValues())
      || formProps.validating
      || formProps.hasValidationErrors
      || updateState.loading,
    );

    return (
      <Button
        onClick={formProps.handleSubmit}
        disabled={disabled}
        kind="primary"
        icon={updateState.loading ? CircularSpinner : null}
        data-testid="confirm"
      >
        <Message
          id="Confirm"
          comment="Button text for create workspace modal"
        />
      </Button>
    );
  };

  return (
    <ModalForm
      title={message({
        id: 'Share template with other workspaces',
        comment: 'Modal title for sharing a template with other workspaces.',
      })}
      body={renderBody()}
      onSubmit={onSubmit}
      resetFormState={onReset}
      initialValues={initialSharedWorkspaces}
      formState={updateState}
      modalKey="share template modal"
      onOpen={onOpen}
      onClose={onClose}
      actions={renderActions}
    >
      {children}
    </ModalForm>
  );
};

export default localize(ShareContractTemplateModal);
