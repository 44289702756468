// @flow

/* eslint-disable no-param-reassign */
const withPageBreak = (editor: any) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => (
    element.type === 'page-break' ? true : isVoid(element)
  );

  return editor;
};

export default withPageBreak;
