import { connect } from 'react-redux';

import logCompanyInfo from 'logging/company-info-logger';

import {
  getAccountFromSessionSelector,
  getGhostFromSessionSelector,
} from 'reducers/session';

import {
  getLoadingStateSelector,
  getErrorStateSelector,
  getCurrentWorkspaceSelector,
} from 'reducers/app';

import App from './app';

logCompanyInfo();

export const mapStateToProps = (state) => ({
  isLoading: getLoadingStateSelector(state),
  hasError: getErrorStateSelector(state),
  acl: {
    account: getAccountFromSessionSelector(state).acl || {},
    workspace: getCurrentWorkspaceSelector(state).acl || {},
  },
  ghost: getGhostFromSessionSelector(state),
});

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
