// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import ModalForm from 'hocs/modal-form';
import { GroupNameField } from 'components/fields';

type Props = {
  onSubmit: (name: string) => void,
  formState: RpcState,
  resetFormState: () => void,
  children: React.Node,
};

export const AddGroup = ({
  onSubmit,
  resetFormState,
  formState,
  children,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Create group"
        comment="Modal title for creating group"
      />
    )}
    body={(
      <div>
        <GroupNameField autoFocus />
      </div>
    )}
    onSubmit={onSubmit}
    resetFormState={resetFormState}
    formState={formState}
    modalKey="create group modal"
  >
    {children}
  </ModalForm>
);
