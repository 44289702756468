// @flow

import React from 'react';
import { FormSpy } from 'react-final-form';
import { Message } from '@oneflowab/pomes';
import {
  PERIOD_MONTHLY,
  PERIOD_YEARLY,
} from 'account/billing';
import {
  getFormatter,
  getMonthlySeatPrice,
  formatPrice,
} from 'account';

import ModalForm from 'hocs/modal-form';
import TermsOfUseLink from 'components/terms-of-use-link';
import { AccountSeatsField } from 'components/fields';
import { AccountPurchaseConfirmField } from 'components/fields/account-purchase-confirm';
import Button from 'components/button';

import style from './buy-seats.module.scss';

export type FormData = {
  numberOfSeats?: string,
  confirmBuyingSeats?: boolean,
};

export type Props = {|
  billing?: Billing,
  language: EnabledLanguages,
  currentSeats: AccountSeats,
  updateState: RpcState,
  resetUpdateState: () => void,
  onSubmit: FormData => void,
  isAutoOpen?: boolean,
|};

export class BuySeatsModal extends React.Component<Props> {
  getMinimumAmountOfSeats(): number {
    const { currentSeats: { taken, total } } = this.props;

    if (!this.hasTakenTooMany()) {
      return 1;
    }

    return taken - total;
  }

  getInitialValues(): { numberOfSeats?: number } {
    const { currentSeats: { taken, total } } = this.props;

    if (!this.hasTakenTooMany()) {
      return {};
    }

    return {
      numberOfSeats: taken - total,
    };
  }

  billingPeriod = () => {
    const { billing } = this.props;

    switch (billing.period) {
      case PERIOD_MONTHLY:
        return (
          <Message
            id="month"
            comment="Label for monthly billing"
          />
        );
      case PERIOD_YEARLY:
        return (
          <Message
            id="year"
            comment="Label for yearly billing"
          />
        );
      default:
        return null;
    }
  };

  billingPeriodNumber = () => {
    const { billing } = this.props;
    if (billing.period === PERIOD_YEARLY) {
      return <span> x 12</span>;
    }
    return null;
  };

  getChildren = (onClick: Function) => {
    const { taken, total } = this.props.currentSeats;
    const seatUtilization = (taken / total) * 100;

    return (
      <Button
        data-testid="buy-seats"
        onClick={onClick}
        kind="secondary"
        trackable={{
          name: 'Go To Upgrade Seats',
          props: {
            'health score seat utilization': `${seatUtilization}%`,
          },
        }}
      >
        <Message id="Buy seats" comment="Used as the text of the buy button seats button" />
      </Button>
    );
  };

  hasTakenTooMany() {
    const { currentSeats: { taken, total } } = this.props;

    return taken > total;
  }

  renderPriceInfo = () => {
    const { language, currentSeats: { currency, price } } = this.props;
    const formatter = getFormatter(language, currency);

    return (
      <>
        <Message
          id="Additional seats"
          comment="Seat pricing information"
        />
        <br />
        <Message
          id="{price} per seat / month"
          comment="Seat pricing information"
          values={{
            price: formatPrice(formatter, price),
          }}
        />
      </>
    );
  }

  renderCalculation = ({ values }: { values: FormData }) => {
    const { language, currentSeats: { currency, price } } = this.props;
    const numberOfSeats = Number(values.numberOfSeats);
    const formatter = getFormatter(language, currency);

    return (
      <div className={style.CalculationContainer}>
        <p>
          {formatPrice(formatter, price)}
          <span> x </span>
          {numberOfSeats}
          {this.billingPeriodNumber()}
        </p>
        <p className={style.InvoiceText}>
          <Message
            id="You will receive a separate prorated invoice for this upgrade covering the current Subscription Plan period until the next renewal date of your Subscription Plan."
            comment="A helper text to show in the buy seats modal body"
          />
        </p>
      </div>
    );
  };

  renderTotalPrice = ({ values }: { values: FormData }) => {
    const { language, currentSeats: { currency, price }, billing } = this.props;
    const total = getMonthlySeatPrice(values.numberOfSeats, price) * billing.period;
    const formatter = getFormatter(language, currency);

    return (
      <>
        {formatPrice(formatter, total)}
        {' / ' }
        {this.billingPeriod()}
      </>
    );
  }

  renderMessage = () => {
    const { currentSeats: { taken, total } } = this.props;
    const seatsLimit = this.getMinimumAmountOfSeats();

    if (this.hasTakenTooMany()) {
      return (
        <>
          <Message
            id="Your account is using too many seats ({taken} of {total} seat used)."
            pluralId="Your account is using too many seats ({taken} of {total} seats used)."
            pluralCondition="totalCount"
            comment="A helper text to show in the buy seat modal body"
            values={{
              total: <strong>{total}</strong>,
              taken: <strong>{taken}</strong>,
              totalCount: total,
            }}
          />
          {' '}
          <Message
            id="This means you have to buy at least {seatsLimit} seat."
            pluralId="This means you have to buy at least {seatsLimit} seats."
            comment="A helper text to show in the buy seat modal body"
            pluralCondition="seatsLimitCount"
            values={{
              seatsLimit: <strong>{seatsLimit}</strong>,
              seatsLimitCount: seatsLimit,
            }}
          />
        </>
      );
    }

    return null;
  };

  renderNextInvoiceText = () => {
    const { billing } = this.props;
    if (billing.period === PERIOD_MONTHLY) {
      return (
        <Message
          id="Your next monthly invoice will reflect the total scope of your Subscription Plan."
          comment="A helper text to show in the buy seats modal body"
        />
      );
    }

    return (
      <Message
        id="Your next yearly invoice will reflect the total scope of your Subscription Plan."
        comment="A helper text to show in the buy seats modal body"
      />
    );
  }

  renderBody = ({ values }: { values: FormData }) => {
    const {
      billing: { invoiceEmail, name },
      currentSeats: { total },
    } = this.props;

    return (
      <div className={style.Content}>
        <div className={style.FieldContainer}>
          <p>
            <Message
              id="You have {total} seat included in the current Subscription Plan"
              pluralId="You have {total} seats included in the current Subscription Plan"
              pluralCondition="totalCount"
              comment="A helper text to show in the buy seat modal body"
              values={{
                total: <strong>{total}</strong>,
                totalCount: total,
              }}
            />
          </p>
          <div className={style.Container}>
            <div className={style.LineContainer}>
              <div className={style.SeatsContainer}>
                <AccountSeatsField min={this.getMinimumAmountOfSeats()} autoFocus />
              </div>
              <div className={style.PriceInfo}>
                {this.renderPriceInfo()}
              </div>
            </div>
            <FormSpy subscription={{ values: true }}>
              {this.renderMessage}
            </FormSpy>
          </div>
          <div className={style.FieldContainer}>
            <div className={style.Total}>
              <strong>
                <Message
                  id="Added to your Subscription Plan:"
                  comment="Label, precedes the total price relative to the seats that are being bought"
                />
              </strong>
              <strong>
                <FormSpy subscription={{ values: true }}>
                  {this.renderTotalPrice}
                </FormSpy>
              </strong>
            </div>
            <div className={style.Calculation}>
              <FormSpy subscription={{ values: true }}>
                {() => {
                  const numberOfSeats = Number(values.numberOfSeats);
                  const seatsLimit = this.getMinimumAmountOfSeats();
                  return numberOfSeats >= seatsLimit ? this.renderCalculation({ values }) : null;
                }}
              </FormSpy>
            </div>
          </div>
          <div className={style.InvoiceContainer}>
            <p>
              {this.renderNextInvoiceText()}
              {' '}
              <Message
                id="Invoice will be sent to {invoiceEmail}."
                comment="A helper text to show in the buy seats modal body"
                values={{
                  invoiceEmail: <strong>{invoiceEmail}</strong>,
                }}
              />
            </p>
          </div>
        </div>
        <div className={style.BottomContainer}>
          <TermsOfUseLink />
          <div className={style.CheckContainer}>
            <AccountPurchaseConfirmField billingCompanyName={name} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      onSubmit,
      updateState,
      resetUpdateState,
      isAutoOpen,
    } = this.props;

    return (
      <ModalForm
        customModalClass={style.Modal}
        isOpen={isAutoOpen}
        title={(
          <Message
            id="Buy additional seats"
            comment="The title of the buy seats modal"
          />
        )}
        body={(
          <FormSpy subscription={{ values: true }}>
            {({ values }: { values: any }) => this.renderBody({ values })}
          </FormSpy>
        )}
        onSubmit={onSubmit}
        resetFormState={resetUpdateState}
        formState={updateState}
        initialValues={this.getInitialValues()}
        modalKey="upgrade seats modal"
      >
        {this.getChildren}
      </ModalForm>
    );
  }
}
