// @flow

import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Form } from 'react-final-form';
import {
  Message, localize, type MessageTranslator,
} from '@oneflowab/pomes';
import { debounce, noop } from 'lodash';

import agreements from 'reducers/entities/agreements';
import { isDraft } from 'agreement';
import {
  getAgreementMyParticipant,
  getAgreementOwnerParticipantsWithoutSelf,
  getCounterpartyParticipants,
  getGuestToken,
  getParticipantById,
} from 'agreement/selectors';
import useOnScreen from 'hooks/use-on-screen';
import useAgreement from 'hooks/use-agreement';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import { isSmallScreenWidth } from 'ui/config';

import {
  filterParticipants,
  getMessageFromSessionStorage,
  getChatMessageFromSessionStorage,
  isAnnotation,
  isChatEnabled,
  isCommentsEnabled,
  resetMessageInSessionStorage,
  resetChatMessageInSessionStorage,
  saveMessageToSessionStorage,
  saveChatMessageToSessionStorage,
  sortReceivers,
} from 'comments';
import {
  ALL_PARTICIPANTS,
  MAX_MESSAGE_LENGTH,
  MESSAGE_SCOPE,
} from 'comments/constants';

import { useCollapsedDocumentLayout } from 'components/document-layout-container/collapsed-document-layout/context';
import {
  getApiErrorMessage,
  getMaxLengthError,
  validationErrors,
} from 'components/document-tabs/messages-tab/post-message/error-messages';
import Button from 'components/button';
import RecipientSelectorMenu from 'components/recipient-selector-menu';
import SendIcon from 'components/icons/send';
import SendTo from 'components/document-tabs/messages-tab/send-to';
import toast from 'components/toasts';
import Tooltip from 'components/tooltip';
import { BetaLabel } from 'components/beta-label';
import PrivacySelector from 'components/messages-layout/single-message/privacy-selector';
import PrivacyIndicator, { PrivacyDescription } from 'components/messages-layout/privacy-indicator';

import { scrollToElement } from './auto-scroll';

import style from './post-message.module.scss';

type Props = {
  agreementId: number,
  autoFocus?: boolean,
  hidden?: boolean,
  isComment?: boolean,
  isChatMessage?: boolean,
  message: MessageTranslator,
  onBlur?: Function,
  onPostMessage?: Function,
  parentCanBeRepliedTo?: boolean,
  parentMessage?: Message,
  shouldRenderBetaBadge?: boolean,
  amplitudeScope?: string,
  isInteractive?: boolean,
};

const isMultiLine = (text) => text.includes('\n') || text.length > 32;

const PostMessage = ({
  agreementId,
  autoFocus = false,
  hidden,
  isComment = false,
  isChatMessage = false,
  message,
  onBlur,
  onPostMessage,
  parentCanBeRepliedTo = false,
  parentMessage,
  shouldRenderBetaBadge,
  amplitudeScope,
  isInteractive,
}: Props) => {
  const isSmallScreen = isSmallScreenWidth();
  const [isTextMultiLine, setTextMultiLine] = useState(false);
  const [isRecipientSelectorOpen, setRecipientSelectorOpen] = useState(false);
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const myParticipant = getAgreementMyParticipant(agreement);
  const publicParticipants = useMemo(() => filterParticipants(
    agreement,
    getCounterpartyParticipants(agreement),
    false,
  ), [agreement]);
  const privateParticipants = useMemo(() => filterParticipants(
    agreement,
    getAgreementOwnerParticipantsWithoutSelf(agreement),
    true,
  ), [agreement]);
  const initialRecipient = ALL_PARTICIPANTS;
  const allParticipants = useMemo(() => (
    [...privateParticipants, ...publicParticipants]
  ), [privateParticipants, publicParticipants]);
  const isReply = Boolean(parentMessage);
  const isInPreviewMode = useIsInPreviewMode();
  const {
    mentionParticipantsSelectorRef,
  } = useCollapsedDocumentLayout();

  let parentId = null;
  let parentIsPrivate = null;
  let parentAuthor = null;

  if (isReply) {
    parentId = parentMessage.id;
    parentIsPrivate = Boolean(parentMessage.private);
    parentAuthor = getParticipantById(agreement, parentMessage.ownerParticipant.id);
  }

  const [messageIsPrivate, setMessageIsPrivate] = useState(
    isDraft(agreement) || Boolean(parentIsPrivate),
  );
  const validRecipients = useMemo(() => (
    messageIsPrivate ? privateParticipants.map((p) => p.id) : allParticipants.map((p) => p.id)
  ), [
    messageIsPrivate,
    privateParticipants,
    allParticipants,
  ]);

  const [selectedRecipient, setSelectedRecipient] = useState(initialRecipient);
  const noRecipientIsSelected = selectedRecipient === ALL_PARTICIPANTS;

  const [messageText, setMessageText] = useState('');
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if (isChatMessage) {
      const chatMessageNewString = getChatMessageFromSessionStorage(agreementId);
      setMessageText(chatMessageNewString || '');
      return;
    }

    const messageFromSessionStorage = getMessageFromSessionStorage(agreementId, parentId);
    if (hidden || !parentId || !messageFromSessionStorage) {
      setMessageText('');
      return;
    }

    setMessageText(messageFromSessionStorage);
  }, [agreementId, hidden, isChatMessage, parentId]);

  const sendMessageQueryId = useRef(null);
  const disabled = (isComment || isAnnotation(parentMessage))
    ? !(parentCanBeRepliedTo || isCommentsEnabled(agreement))
    : !isChatEnabled(agreement, myParticipant);
  const guestToken = useSelector((state) => getGuestToken(state));
  const postMessageQuery = useSelector(
    (state) => agreements.getPostMessageSelector(state, { id: sendMessageQueryId.current }),
  );

  const sendDisabled = Boolean(
    disabled
    || !messageText.trim()
    || validationError
    || postMessageQuery.loading
    || isInPreviewMode,
  );
  const inputRef = useRef(null);
  const isReplyFormOnScreen = useOnScreen(inputRef);
  const actionsContainerRef = useRef(null);

  const onReceiverChanged = (newRecipient) => {
    if (newRecipient.value) {
      setSelectedRecipient(newRecipient.value);
      inputRef.current?.focus();
    } else {
      setSelectedRecipient(initialRecipient);
    }
  };

  const handleMentioning = (text: string) => {
    if (/\s@/.test(text) || text === '@') { // ' @' or '@'
      setTimeout(() => {
        setRecipientSelectorOpen(true);
      }, 200);
    }
  };

  const onMessageChanged = (ev) => {
    const { value } = ev.target;
    setMessageText(value);

    const isValueMultiLine = isMultiLine(value);
    setTextMultiLine(isValueMultiLine);

    if (isChatMessage) {
      debounce(() => saveChatMessageToSessionStorage(agreementId, value), 300)();
    }

    handleMentioning(ev.target.value);

    if (parentId) {
      debounce(() => saveMessageToSessionStorage(agreementId, parentId, value), 300)();
    }
  };

  const handleOnReceiverChanged = () => {
    if (messageText.endsWith('@')) {
      setMessageText(messageText.slice(0, -1));
    }
  };

  const onPostSuccess = (newMessage) => {
    setSelectedRecipient(initialRecipient);
    if (parentId) {
      resetMessageInSessionStorage(agreementId, parentId);
    }

    if (isChatMessage) {
      resetChatMessageInSessionStorage(agreementId);
    }

    if (onPostMessage) {
      onPostMessage(newMessage);
    }
    dispatch(agreements.postMessageReset({ id: agreement.id }));

    const elementToScroll = document.querySelector(`div[data-id="${newMessage.id}"][class^="_Message"]`);
    if (elementToScroll && !isComment && !isReply) {
      scrollToElement(elementToScroll);
    }
  };

  const onPostFailure = (error) => {
    const errorCode = error.response?.status;
    const errorMessage = getApiErrorMessage(errorCode);
    toast.error({
      id: 'post-message',
      title: <Message
        id="Couldn't perform this action"
        comment="Title for the warning message when the user can't post a message"
      />,
      description: errorMessage,
    });
    dispatch(agreements.postMessageReset({ id: agreement.id }));
  };

  const onSubmit = (ev: Event) => {
    ev?.preventDefault();
    ev?.stopPropagation();

    let recipients = [selectedRecipient];
    let mentionedParticipantIds = [];
    if (noRecipientIsSelected) {
      recipients = validRecipients;
    } else {
      mentionedParticipantIds = [selectedRecipient];
    }

    const data = {
      recipients,
      isPrivate: messageIsPrivate,
      message: messageText,
      guestToken,
      mentionedParticipantIds,
      amplitudeScope,
    };

    // Sends a public message
    if (noRecipientIsSelected && !parentId && !messageIsPrivate) {
      data.messageScope = MESSAGE_SCOPE.PUBLIC_ALL;
    }

    // when PostMessage is rendered as a reply
    if (parentId) {
      data.parentId = parentId;
      data.isPrivate = parentIsPrivate;
      data.messageScope = MESSAGE_SCOPE.THREAD_ALL;
      if (noRecipientIsSelected) {
        data.recipients = [parentAuthor.id];
      } else data.recipients = [selectedRecipient];
    }

    setMessageText('');
    setTextMultiLine(false);

    if (isComment) {
      onPostMessage(data);
    } else {
      const dataToDispatch = {
        id: agreementId,
        data,
        pipe: {
          onSuccess: onPostSuccess,
          onFailure: onPostFailure,
        },
      };
      const sendMessageAction = dispatch(agreements.postMessage(dataToDispatch));
      sendMessageQueryId.current = sendMessageAction.id;
    }
  };

  const handleBlur = (event) => {
    event.preventDefault();

    if (!onBlur || event.currentTarget.contains(event.relatedTarget)) {
      return;
    }

    onBlur();
  };

  useEffect(() => {
    const isMessageTextMultiLine = isMultiLine(messageText);
    setTextMultiLine(isMessageTextMultiLine);

    if (disabled) {
      setValidationError('');
      return;
    }
    const selectedRecipientInvalid = (
      allParticipants.length && !validRecipients.find((id) => selectedRecipient === id)
    );
    const isPrivateMessageToExternal = (
      selectedRecipient !== initialRecipient
      && messageIsPrivate
      && allParticipants.length
      && selectedRecipientInvalid
    );
    if (messageText.length > MAX_MESSAGE_LENGTH) {
      setValidationError(getMaxLengthError(messageText.length));
      return;
    }
    if (isPrivateMessageToExternal) {
      setValidationError(validationErrors.private);
      return;
    }
    if (!messageIsPrivate && isDraft(agreement)) {
      setValidationError(validationErrors.public);
      return;
    }
    setValidationError('');
  }, [
    agreement,
    allParticipants.length,
    disabled,
    initialRecipient,
    messageIsPrivate,
    messageText,
    selectedRecipient,
    validRecipients,
  ]);

  useEffect(() => {
    // Editor crashes when <TextareaAutosize autofocus>, so we need to focus it manually
    if (autoFocus && isReplyFormOnScreen) {
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(messageText.length, messageText.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReplyFormOnScreen, autoFocus]);

  const onDeleteMention = (event) => {
    event.stopPropagation();

    setSelectedRecipient(initialRecipient);
    inputRef.current?.focus();
  };

  const sendButtonTooltip = useMemo(() => {
    if (isComment || isAnnotation(parentMessage)) {
      return (
        <Message
          id="Post comment"
          comment="Text showing as a tooltip for the button responsible for sending a comment on a contract"
        />
      );
    }

    return (
      <Message
        id="Send message"
        comment="Text showing as a tooltip for the button responsible for sending a message on a contract"
      />
    );
  }, [isComment, parentMessage]);

  const placeholder = useMemo(() => {
    if (isSmallScreen && isReply) {
      return message({
        id: 'Reply here',
        comment: 'Placeholder in the input for reply to message',
      });
    }

    if (isComment || isAnnotation(parentMessage)) {
      if (messageIsPrivate) {
        return message({
          id: 'Internal comment',
          comment: 'Placeholder in the input for comment when the message will only be seen by users from my party',
        });
      }
      return message({
        id: 'Public comment',
        comment: 'Placeholder in the input for comment when the message will be seen by everyone',
      });
    }

    if (messageIsPrivate) {
      return message({
        id: 'Internal message',
        comment: 'Placeholder in the input for message when the message will only be seen by users from my party',
      });
    }

    return message({
      id: 'Public message',
      comment: 'Placeholder in the input for message when the message will be seen by everyone',
    });
  }, [
    isComment,
    isReply,
    isSmallScreen,
    message,
    messageIsPrivate,
    parentMessage,
  ]);

  const renderActionButtons = () => {
    const actionButtons = (
      <div className={style.ActionButtons}>
        <RecipientSelectorMenu
          onReceiverChanged={onReceiverChanged}
          disabled={disabled || isInPreviewMode}
          privateParticipants={privateParticipants.sort(sortReceivers)}
          publicParticipants={publicParticipants.sort(sortReceivers)}
          recipient={selectedRecipient}
          actionsContainerRef={actionsContainerRef}
          setOpen={setRecipientSelectorOpen}
          isOpen={isRecipientSelectorOpen}
          handleOnReceiverChanged={handleOnReceiverChanged}
          isChatMessage={isChatMessage}
          isInteractive={isInteractive}
        />
        <Tooltip
          message={sendButtonTooltip}
          messageClassName={style.SendMessageTooltip}
          side="top"
          hideContent={sendDisabled}
          zIndex={9999}
        >
          <div>
            <Button
              kind="primary"
              disabled={sendDisabled}
              data-testid="submit-message"
              icon={SendIcon}
              onClick={onSubmit}
            />
          </div>
        </Tooltip>
      </div>
    );
    if (isInPreviewMode) {
      return (
        <Tooltip
          data-testid="form-actions-tooltip"
          message={<Message id="Not available in preview" comment="Tooltip title for the actions" />}
          side="top"
          sideOffset={4}
          zIndex="10001"
        >
          {actionButtons}
        </Tooltip>
      );
    }
    return actionButtons;
  };

  const renderFormInput = () => {
    const formInput = (
      <div
        className={clsx(style.FormInputContainer, {
          [style.ValidationError]: messageText.length > MAX_MESSAGE_LENGTH,
          [style.PreviewMode]: isInPreviewMode,
        })}
        role="button"
        tabIndex={-1}
        onClick={() => !isSmallScreen && inputRef.current?.focus()}
        onKeyDown={noop}
      >
        <div className={style.TextAreaContainer}>
          <div className={style.MentionContainer}>
            <SendTo
              recipientLabel={allParticipants.find((p) => p.id === selectedRecipient)?.label}
              onDelete={onDeleteMention}
              error={validationError === validationErrors.private}
              maxLength={35}
            />
          </div>
          <div className={style.TextAreaWrapper}>
            <TextareaAutosize
              maxRows={isSmallScreen ? 2 : 5}
              className={clsx(style.TextArea, {
                [style.Empty]: !messageText.length,
                [style.PreviewModeInput]: isInPreviewMode,
                [style.IsTextMultiLine]: isTextMultiLine,
              })}
              name="body"
              placeholder={`${placeholder}...`}
              onChange={onMessageChanged}
              value={messageText}
              disabled={disabled || isInPreviewMode}
              data-testid="message-input"
              ref={inputRef}
            />
            {renderActionButtons()}
          </div>
        </div>
      </div>
    );
    if (isInPreviewMode) {
      return (
        <Tooltip
          data-testid="form-input-tooltip"
          message={<Message id="Not available in preview" comment="Tooltip title for the actions" />}
          side="top"
          sideOffset={4}
          zIndex="10001"
        >
          {formInput}
        </Tooltip>
      );
    }
    return formInput;
  };

  const renderForm = () => (
    <form
      autoComplete="off"
      className={style.Form}
      data-testid="input-form"
    >
      {renderFormInput()}
      <div className={style.ActionsContainer}>
        <p
          className={clsx(style.ValidationError, {
            [style.NoError]: !validationError,
          })}
        >
          {validationError}
        </p>
        <div className={style.ActionsRowContainer}>
          <div
            ref={actionsContainerRef}
            className={clsx(style.ActionsRow, {
              [style.SpaceBetween]: !isComment && !isReply,
              [style.Right]: Boolean(guestToken) || isReply,
            })}
          >
            {(!isComment && !isReply) && (
              <PrivacySelector
                messageIsPrivate={messageIsPrivate}
                disabled={disabled}
                onChange={(value) => {
                  setMessageIsPrivate(value);
                  inputRef.current?.focus();
                }}
                actionsContainerRef={actionsContainerRef}
                agreementId={agreementId}
              />
            )}
            {isComment && (
            <PrivacyIndicator
              isPrivate={messageIsPrivate}
              isReply={isReply}
            />
            )}
            {isComment && !isReply && (
              <PrivacyDescription isPrivate={messageIsPrivate} />
            )}
          </div>
          {shouldRenderBetaBadge && (
            <BetaLabel />
          )}
        </div>
      </div>
    </form>
  );

  const renderFormSection = () => {
    if (disabled) {
      return null;
    }

    return (
      <>
        <div
          className={clsx({
            [style.MentionParticipantsSelector]: (
              !isReply
              && isRecipientSelectorOpen
            ),
          })}
          ref={mentionParticipantsSelectorRef}
        />
        <div
          className={clsx(style.FormContainer, {
            [style.HiddenPostMessage]: hidden,
            [style.ReplyForm]: isReply,
          })}
          // tabIndex is needed in order to make onBlur work on div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
          onBlur={handleBlur}
        >
          <Form
            onSubmit={onSubmit}
            initialValues={{ body: '', messageIsPrivate }}
            render={renderForm}
            data-testid="form-wrapper"
          />
        </div>
      </>
    );
  };

  return (
    renderFormSection()
  );
};

export default localize<Props>(PostMessage);
