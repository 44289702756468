import Svg from 'components/svg';

const ChangeTemplateGroup = Svg(
  () => (
    <>
      <mask id="mask0_10208_7785" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="17" y="7" width="41" height="40">
        <path d="M57.5 7H17.5V47H57.5V7Z" fill="white" />
      </mask>
      <g mask="url(#mask0_10208_7785)">
        <path
          d="M19.5015 19.0342C19.5015 19.0342 33.9712 11.4305 37.5009 9.85742C40.5878 11.2329 55.5005 19.0327 55.5005 19.0327C55.5005 19.0327 41.0276 26.6356 37.498 28.2081C33.969 26.6355 19.5015 19.0342 19.5015 19.0342Z"
          stroke="currentColor"
          strokeWidth="3.71429"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M55.4071 27.502L37.5002 35.5716L19.5 27.502M55.4071 35.9657L37.455 44.143L19.5 35.9657"
          stroke="currentColor"
          strokeWidth="3.71429"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <mask id="mask1_10208_7785" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="11" y="44" width="55" height="27">
        <path d="M65.5 44H11.5V71H65.5V44Z" fill="white" />
      </mask>
      <g mask="url(#mask1_10208_7785)">
        <path
          d="M20.8791 70.2609C21.5373 69.6028 21.5373 68.5329 20.8791 67.8748L17.2544 64.25H46.9375C47.869 64.25 48.625 63.494 48.625 62.5625C48.625 61.631 47.869 60.875 46.9375 60.875H17.2544L21.1255 57.0039C21.7836 56.3458 21.7836 55.2759 21.1255 54.6178C20.4674 53.9596 19.3975 53.9596 18.7394 54.6178L11.9927 61.3644C11.662 61.6951 11.5 62.1271 11.5 62.5625C11.5 62.9979 11.662 63.4299 11.9927 63.7606L18.493 70.2609C19.1545 70.9224 20.221 70.9224 20.8791 70.2609ZM65.0073 51.2731L58.507 44.7729C57.8489 44.1148 56.779 44.1148 56.1209 44.7729C55.4627 45.431 55.4627 46.5009 56.1209 47.159L59.7456 50.7838H30.0625C29.131 50.7838 28.375 51.5398 28.375 52.4713C28.375 53.4028 29.131 54.1588 30.0625 54.1588H59.7456L55.8745 58.0299C55.2164 58.688 55.2164 59.7579 55.8745 60.416C56.5326 61.0741 57.6025 61.0741 58.2606 60.416L65.0073 53.6694C65.338 53.3386 65.5 52.9066 65.5 52.4713C65.5 52.0359 65.338 51.6039 65.0073 51.2731Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
  { viewBox: '0 0 76 75', fill: 'none' },
);

ChangeTemplateGroup.displayName = 'ChangeTemplateGroup';

export default ChangeTemplateGroup;
