// @flow

import React from 'react';
import { type ContextRouter } from 'react-router-dom';

import extensionSettings from 'hocs/extension-settings';
import Conditional from 'components/conditional';
import GeneratedToken from 'components/extension/generated-token';

import ExtensionCustomConfiguration from './extension-custom-configuration';
import ExtensionSetup from './extension-setup';

type Props = {
  extension: Extension,
  updateExtension: Extension => void,
  updateState: UpdateState,
  resetUpdateState: () => void,
  ...ContextRouter,
};

export const GenericExtensionPage = ({
  extension,
  location,
  updateState,
  updateExtension,
  resetUpdateState,
}: Props) => {
  const { webhookUrlPlaceholderValue } = extension.config;
  const shouldShowSetupButton = [
    'manual_redirect',
    'manual_redirect_with_token',
  ].includes(extension.setupMethod)
    && extension.setupUrl;

  return (
    <>
      <Conditional ifCondition={extension.setupMethod}>
        <GeneratedToken extension={extension} />
      </Conditional>

      <Conditional ifCondition={typeof webhookUrlPlaceholderValue !== 'undefined'}>
        <ExtensionCustomConfiguration
          extension={extension}
          extensionName={extension.name}
          updateExtension={updateExtension}
          updateState={updateState}
          resetUpdateState={resetUpdateState}
        />
      </Conditional>

      <Conditional ifCondition={shouldShowSetupButton}>
        <ExtensionSetup location={location} extension={extension} />
      </Conditional>
    </>
  );
};

export default extensionSettings(GenericExtensionPage);
