// @flow

import { SHARED_WITH_ME_ID } from 'reducers/entities/workspaces';

import { colleaguesSanitizer } from 'agreement/sanitizers';

type GetColleaguesFilterProps = (workspace: Workspace, currentUser: Position) => {
  onSanitize: Function,
  isSharedWithMeWorkspace: boolean,
};

const getColleaguesFilterProps: GetColleaguesFilterProps = (workspace, currentUser) => {
  const isSharedWithMeWorkspace = workspace && workspace.id === SHARED_WITH_ME_ID;
  const onSanitize = colleaguesSanitizer(isSharedWithMeWorkspace, currentUser);
  return {
    onSanitize,
    isSharedWithMeWorkspace,
  };
};

export default getColleaguesFilterProps;
