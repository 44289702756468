import { useEffect } from 'react';
import { Editable } from 'slate-react';
import { useResizeDetector } from 'react-resize-detector';
import clsx from 'clsx';

import { useInlineEditableProps } from './inline-editable-context';

import style from './editable-element.module.scss';

type Props = {
  className?: string;
  onBeforeInput: (event: InputEvent) => void;
  onBlur: () => void;
  onFocus: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onPaste: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  readOnly?: boolean;
}

export const EditableElement = ({
  className,
  onBeforeInput,
  onBlur,
  onFocus,
  onKeyDown,
  onPaste,
  readOnly,
}: Props) => {
  const {
    isFocused,
    setHasOverflow,
  } = useInlineEditableProps();
  const { ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 16,
  });

  const textContent = ref.current?.querySelector('[data-slate-string="true"]');
  const hasOverflow = (textContent?.offsetWidth ?? 0) < (textContent?.scrollWidth ?? 0);

  useEffect(() => {
    setHasOverflow(hasOverflow);
  }, [hasOverflow, setHasOverflow]);

  const editableClassNames = clsx({
    notranslate: !readOnly,
  });

  return (
    <span
      className={clsx(style.EditableElement, className, {
        [style.Focused]: isFocused,
      })}
      ref={ref}
    >
      <Editable
        className={editableClassNames}
        onBlur={onBlur}
        onDOMBeforeInput={onBeforeInput}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        readOnly={readOnly}
        spellCheck={false}
      />
    </span>
  );
};
