import Button from 'components/button';

import type { FlowData, CancelFlowAction } from 'types/cancellation-flow';

export type CancelFlowActionNextStep = CancelFlowAction & {
  onStepComplete: () => void;
  modalData: FlowData,
  syncData: FlowData;
  onSyncFlowData: (data: FlowData) => void;
  onSyncStepData: (data: FlowData) => void;
}

const NextStep = ({
  children,
  kind,
  color,
  onStepComplete,
  modalData,
  syncData,
  onSyncFlowData,
  onSyncStepData,
}: CancelFlowActionNextStep) => {
  const onClick = () => {
    const allData = {
      ...modalData,
      ...syncData,
    };

    if (syncData) {
      onSyncStepData({ ...allData });
      onSyncFlowData({ ...allData });
    }
    onStepComplete();
  };
  return (
    <Button
      kind={kind}
      color={color}
      onClick={onClick}
      disabled={false}
    >
      {children}
    </Button>
  );
};

export default NextStep;
