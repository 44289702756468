import getFontAssetsDomain from './get-font-asset-domain.js';

const loadTinos = () => {
  const normalTinos = new FontFace(
    'Tinos',
    `url(${getFontAssetsDomain()}/resources/fonts/tinos/tinos_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicTinos = new FontFace(
    'Tinos',
    `url(${getFontAssetsDomain()}/resources/fonts/tinos/tinos_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldTinos = new FontFace(
    'Tinos',
    `url(${getFontAssetsDomain()}/resources/fonts/tinos/tinos_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicTinos = new FontFace(
    'Tinos',
    `url(${getFontAssetsDomain()}/resources/fonts/tinos/tinos_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalTinos, italicTinos, boldTinos, boldItalicTinos];
};

export default loadTinos;
