// @flow

import React from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';
import {
  Editor,
  Text,
} from 'slate';
import { SCALE_CLASSNAMES } from '@react-md/transition/lib/constants';
import { useContractProps } from 'contexts/contract-props';

import Tooltip from 'components/tooltip';
import { getActiveImage } from 'components/rich-text-editor/editor-plugins/image-utils';
import DropdownMenu from 'components/dropdown-menu';
import ChevronUpIcon from 'components/icons/chevron-up';
import ChevronDownIcon from 'components/icons/chevron-down';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import FontSizeMenuItem from './font-size-menu-item';
import style from './font-size-selector.module.scss';

const hasActiveFontSize = (node: any) => {
  if (node?.marks?.fontSize) {
    return true;
  }

  return Text.isText(node) && node.fontSize;
};

export const getActiveFontSizeNode = (editor: any) => {
  try {
    const [match] = Editor.nodes(editor, {
      match: (n) => (
        !Editor.isEditor(n) && hasActiveFontSize(n)
      ),
    });

    if (!match) {
      return null;
    }

    return match[0];
  } catch {
    return null;
  }
};

export const scaleClassNames = {
  ...SCALE_CLASSNAMES,
  exitDone: clsx('rmd-menu-hiden', style.MenuHidden),
};

const predefinedFontSizes = [
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '24px',
  '30px',
  '36px',
  '48px',
  '60px',
  '72px',
];

const getFontSizeOptions = (activeFontSize: string, changeFontSize: Function) => (
  predefinedFontSizes.map((fontSize) => (
    <FontSizeMenuItem
      fontSize={fontSize}
      isActive={fontSize === activeFontSize}
      changeFontSize={changeFontSize}
      key={`item-${fontSize}`}
    />
  ))
);

const INITIAL_FONT_SIZE = '14px';

const getActiveFontSize = (editor, activeNode) => {
  if (editor.marks && editor.marks.fontSize) {
    return editor.marks.fontSize;
  }

  if (activeNode?.marks?.fontSize) {
    return activeNode?.marks?.fontSize;
  }

  return get(activeNode, 'fontSize');
};

const FontSizeSelector = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const editor = useSlate();
  const focusEditor = useFocusEditor();
  const { agreement } = useContractProps();

  const activeNode = getActiveFontSizeNode(editor);
  const activeImage = getActiveImage(editor);
  const isDisabled = Boolean(activeImage);
  const activeFontSize = getActiveFontSize(editor, activeNode);
  const agreementFormattingFontSize = agreement.config?.formatting?.size || INITIAL_FONT_SIZE;
  const currentFontSize = activeFontSize || `${agreementFormattingFontSize}px`;
  const className = clsx(style.FontSizeSelector, {
    [style.Placeholder]: Boolean(isDisabled),
  });
  let dropdownIcon = <ChevronDownIcon width="10px" height="10px" />;
  if (isDropdownOpen) {
    dropdownIcon = <ChevronUpIcon width="10px" height="10px" />;
  }

  const changeFontSize = (fontSize) => {
    setIsDropdownOpen(false);
    focusEditor();
    Editor.addMark(editor, 'fontSize', fontSize);
  };

  const onVisibilityChange = (visible) => {
    setIsDropdownOpen(visible);
  };

  const onMouseDown = (e) => {
    e.preventDefault();
  };

  return (
    <Tooltip
      triggerClassName={style.FontSizeTooltip}
      messageClassName={style.TooltipText}
      message={(
        <Message
          id="Font size"
          comment="Tooltip text for the font size dropdown"
        />
      )}
      side="bottom"
      delayShow={750}
      delayHide={0}
      hideContent={isDisabled}
    >
      <span>
        <DropdownMenu
          items={getFontSizeOptions(currentFontSize, changeFontSize)}
          anchor={{
            x: 'inner-left',
            y: 'below',
          }}
          className={className}
          dropdownIcon={dropdownIcon}
          focusOnCloseDisabled
          onMouseDown={onMouseDown}
          onVisibilityChange={onVisibilityChange}
        >
          <span className={style.CurrentFontSize}>
            {Number.parseInt(currentFontSize, 10)}
          </span>
        </DropdownMenu>
      </span>
    </Tooltip>
  );
};

export default FontSizeSelector;
