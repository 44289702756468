export type FlattenedAiData = {
  participants: Record<number, { id: number }>;
  parties: Record<number, { id: number; participants: number[] }>;
  boxes: Record<number, { id: number }>;
  closeTime: string;
};

export type ExtractedData = {
  [agreementId: number]: FlattenedAiData;
};

export const transformExtractedData = (
  data: {
    parties: Array<{ id: number; participants: Array<{ id: number }> }>;
    boxes: Array<{ id: number }>;
    closeTime: string;
  },
  agreementId: number,
): ExtractedData => ({
  [agreementId]: {
    participants: data.parties
      .flatMap((party) => party.participants)
      .reduce(
        (acc, participant) => ({ ...acc, [participant.id]: participant }),
        {} as Record<number, { id: number }>,
      ),
    parties: data.parties.reduce(
      (acc, party) => ({
        ...acc,
        [party.id]: {
          id: party.id,
          participants: party.participants.map((p) => p.id),
        },
      }),
      {} as Record<number, { id: number; participants: number[] }>,
    ),
    boxes: data.boxes.reduce(
      (acc, box) => ({ ...acc, [box.id]: box }),
      {} as Record<number, { id: number }>,
    ),
    closeTime: data.closeTime,
  },
});
