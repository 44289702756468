/* eslint-disable react/prop-types */

import React, { useCallback, useMemo } from 'react';
import { isEmpty, unescape, escape } from 'lodash';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import Description from 'components/contract-boxes/form-box/components/description';
import Edit from 'components/icons/edit';
import Label from 'components/contract-boxes/form-box/components/label';
import PropagationBoundary from 'components/propagation-boundary';
import ReadOnly from 'components/contract-boxes/form-box/components/read-only';
import SelectField from 'components/select-field';
import { useBoxItemIsVisible } from 'components/box-list/box-item/box-item-is-visible-context';

import { useFormFieldContext } from '../form-field-context';
import style from './dropdown.module.scss';
import { useFieldValidationContext } from '../field-validation-provider';

const dropdownOptionMapper = (option) => ({
  label: unescape(option),
  value: unescape(option),
});

const FormDropdownField = ({
  choices,
  description,
  inputSize,
  isActive,
  isAllowedToUpdateDataValue,
  isAllowedToUpdateSharedDataValue,
  label,
  placeholder,
  required,
}) => {
  const {
    fieldValue,
    onFieldValueChange,
    hasDataField,
  } = useFormFieldContext();
  const { touched, setTouched } = useFieldValidationContext();
  const { isVisible } = useBoxItemIsVisible();
  const { options, selectedOption } = useMemo(() => {
    const validChoices = choices.filter((choice) => !isEmpty(choice));
    const trimmedValue = fieldValue && fieldValue.trim();
    const allChoices = (trimmedValue && !validChoices.includes(trimmedValue))
      ? [trimmedValue, ...validChoices]
      : validChoices;
    const displayValue = unescape(trimmedValue);
    const mappedOptions = allChoices.map(dropdownOptionMapper);

    return {
      options: mappedOptions,
      selectedOption: mappedOptions.find((option) => option.value === displayValue),
    };
  }, [choices, fieldValue]);

  const customStyles = useMemo(() => {
    const control = (styles) => ({
      ...styles,
      borderColor: '#d6dfe2',
      fontSize: 'inherit !important',
      lineHeight: 'normal !important',
      minHeight: '0 !important',
    });
    const singleValue = (styles) => ({
      ...styles,
      color: 'inherit !important',
    });
    const valueContainer = (styles) => ({
      ...styles,
      padding: '1px 8px !important',
    });
    return {
      control,
      singleValue,
      valueContainer,
    };
  }, []);

  const onChange = useCallback((option) => {
    const newFieldValue = option ? option.value : '';
    onFieldValueChange(escape(newFieldValue));
  }, [onFieldValueChange]);

  const onBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const invalid = (
    required
    && isAllowedToUpdateSharedDataValue
    && !selectedOption?.value
    && touched
    && isVisible
  );

  const fieldContainerClasses = clsx(style.FieldContainer, {
    [style.Active]: isActive,
    [style.Uneditable]: !isAllowedToUpdateDataValue,
    [style.Invalid]: invalid,
  });

  return (
    <div className={fieldContainerClasses}>
      <div className={style.Wrapper}>
        <Label
          hasDataField={hasDataField}
          isAllowedToUpdateSharedDataValue={isAllowedToUpdateSharedDataValue}
          label={label}
          required={required}
        />
        <div className={style.Edit}>
          <Edit height="14px" />
        </div>
      </div>
      {isAllowedToUpdateSharedDataValue ? (
        <div className={inputSize}>
          <PropagationBoundary>
            <SelectField
              clearable
              customStyles={customStyles}
              hideErrorElement
              inheritFontStyles
              input={{
                name: 'form-box-dropdown',
                onChange,
                value: selectedOption,
              }}
              onBlur={onBlur}
              menuPosition="absolute"
              multi={false}
              options={options}
              placeholder={placeholder}
              zIndex={10}
              required={required}
            />
            {invalid && (
              <div className={style.ValidationMessage}>
                <Message id="This field is required" comment="Input field validation message." />
              </div>
            )}
          </PropagationBoundary>
        </div>
      ) : (
        <ReadOnly value={selectedOption ? selectedOption.value : ''} />
      )}
      <Description description={description} />
    </div>
  );
};

export default FormDropdownField;
