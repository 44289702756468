import {
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { aiAssistStreamResponsePost } from 'oneflow-client/ai-assist';
import { getCurrentContractId } from 'reducers/current-contract';
import { getPositionFromSessionSelector } from 'reducers/session';

import { Chat } from '../context/chat-history';
import { AIAssistPromptContextParameters } from '../types';

const fetchResults = async ({
  contractId,
  positionId,
  setCompletion,
  setError,
  setSuccess,
  setLoading,
  setAbortController,
  context,
  chat,
  selectedText,
  onChunkReceived,
}: {
  contractId: Oneflow.Contract['id'];
  positionId: Oneflow.Position['id'];
  setCompletion: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<Error | null>>;
  setSuccess: Dispatch<SetStateAction<boolean>>;
  setAbortController: (controller: AbortController) => void;
  context?: string;
  chat: Chat[];
  selectedText: string | undefined;
  onChunkReceived?: () => void;
}) => {
  try {
    const abortController = new AbortController();
    setAbortController(abortController);

    setLoading(true);
    setSuccess(false);
    setError(null);

    setCompletion('');

    const res = await aiAssistStreamResponsePost({
      targetType: contractId ? 'agreement' : 'position',
      targetId: contractId || positionId,
      signal: abortController.signal,
      context: context || 'agreementEditor',
      chat,
      selectedText,
    });
    // TODO: implement request limit handling
    if (!res.body) {
      throw new Error('Response body is empty!');
    }

    const reader = res.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let result = '';
    let contextParameters: AIAssistPromptContextParameters | null = null;
    let chunk = '';

    const processNextChunk = async () => {
      const { done, value } = await reader.read();

      if (done) return;

      chunk += decoder.decode(value, { stream: true });

      let startIndex = 0;
      let index;
      while (chunk.indexOf('\n\n', startIndex) !== -1) {
        index = chunk.indexOf('\n\n', startIndex);
        const messageText = chunk.slice(startIndex, index);

        const rawData = JSON.parse(messageText.substring('data: '.length));

        if (rawData.context_key) {
          contextParameters = rawData;
          startIndex = index + 2;
          // eslint-disable-next-line no-continue
          continue;
        }

        // Client only supports a single choice at the moment,
        // but change this if we wish to support multiple
        const [data] = rawData.choices;

        if (data.finish_reason === 'stop') return;

        // data.delta.content is the next batch of text to be appended to the response.
        if (data.delta.content) {
          result += data.delta.content;
          setCompletion((content) => content + data.delta.content);
          onChunkReceived?.();
        }
        startIndex = index + 2;
      }
      chunk = chunk.slice(startIndex);

      // Recursively process the next chunk
      await processNextChunk();
    };

    // Start processing the chunks
    await processNextChunk();
    setSuccess(true);
    return { result, contextParameters };
  } catch (e) {
    setError(e as unknown as Error);
    setSuccess(false);
    return { result: '', contextParameters: null };
  } finally {
    setLoading(false);
  }
};

const useCompletion = () => {
  const contractId = useSelector(getCurrentContractId);
  const { id: positionId } = useSelector(getPositionFromSessionSelector);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [loading, setLoading] = useState(false);
  const [completion, setCompletion] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const setAbortController = (controller: AbortController | null) => {
    abortControllerRef.current = controller;
  };

  const stop = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setAbortController(null);
    }
  };

  type CompleteParams = {
    chat: Chat[];
    selectedText: string | undefined;
    context: string;
    onChunkReceived?: () => void;
  };
  const complete = async ({
    chat,
    selectedText,
    context,
    onChunkReceived,
  }: CompleteParams) => (
    fetchResults({
      contractId,
      positionId,
      setCompletion,
      setError,
      setSuccess,
      setLoading,
      setAbortController,
      context,
      chat,
      selectedText,
      onChunkReceived,
    })
  );

  return {
    complete,
    stop,
    success,
    loading,
    completion,
    error,
  };
};

export default useCompletion;
