// update the path to the correct location of the file
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import { isContractPristine } from 'reducers/current-contract';
import { checkAcl } from 'components/acl';
import toast from 'components/toasts';
import type { Layout } from 'components/document-layout-container/types';
import { ApproveButton, ButtonSize } from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons/approve-button/approve';
import ApproveDraft from './approve-draft-modal';

type Props = {
  agreement: Oneflow.Agreement;
  participant: Oneflow.Participant;
  isCurrentInternalApprover: boolean;
  setShowApproveDraftModal: (value: boolean) => void;
  layout?: Layout;
  size?: ButtonSize
};

export const ApproveDraftAction = ({
  agreement,
  participant,
  isCurrentInternalApprover,
  setShowApproveDraftModal,
  layout,
  size,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const hasApprovePermission = checkAcl(participant.acl, 'participant:role:set:draft_approver');
  const isPristine = useSelector(isContractPristine);

  const approvalNotPossibleToast = () => (
    toast.warning({
      id: 'approval-not-possible-warning',
      title: <Message
        id="Approval not possible"
        comment="Title for the warning message when the user can't approve the document."
      />,
      description: <Message
        id="Your user type has limited access and cannot approve this document. {breakLine} Please reach out to your administrator to get the access you need to move forward."
        comment="Description text for the warning message when the user can't approve the document."
        values={{
          breakLine: <br />,
        }}
      />,
      duration: 5000,
    })
  );

  const waitForApprovalToast = () => (
    toast.info({
      id: 'wait-for-approval',
      title: <Message
        id="Waiting for approvals"
        comment="Title for the info message when the user can't approve the document."
      />,
      description: <Message
        id="Please wait for your turn to approve the document."
        comment="Description text for the info message when the user can't approve the document."
      />,
      duration: 5000,
    })
  );

  const handleClick = () => {
    if (!isCurrentInternalApprover) {
      return waitForApprovalToast();
    }

    if (!hasApprovePermission) {
      return approvalNotPossibleToast();
    }

    return setIsOpen(true);
  };

  return (
    <ApproveDraft
      agreement={agreement}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      internalApproverId={participant.id}
      setShowApproveDraftModal={setShowApproveDraftModal}
    >
      <ApproveButton
        onClick={handleClick}
        layout={layout}
        size={size}
        disabled={!isPristine}
      >
        <Message
          id="Approve"
          comment="Button label for signing the document"
        />
      </ApproveButton>
    </ApproveDraft>
  );
};

export default ApproveDraftAction;
