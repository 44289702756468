import {
  useCallback,
  useMemo,
} from 'react';
import {
  debounce,
  get,
  head,
  isEmpty,
} from 'lodash';
import { useDispatch } from 'react-redux';
import type { Descendant } from 'slate';

import { isBoxDataCreateAllowed } from 'agreement/box-data-create-permissions';
import { isBoxDataValueUpdateAllowed } from 'agreement/box-data-value-update-permissions';
import {
  updateBoxAction,
  updateBoxDataItemAction,
} from 'reducers/current-contract';
import useCurrentBox from 'hooks/use-current-box';
import useCurrentBoxId from 'hooks/use-current-box-id';
import type { TextBox } from 'data-validators/entity-schemas/document-box/text-box.ts';

import { generateTextBoxData } from 'components/contract-boxes/text-image-box/text-image-box-helpers';
import {
  getId,
  isUnsavedData,
} from 'components/contract-boxes/generic-box-helpers';
import BoxWrapper from 'components/contract-boxes/box-wrapper';
import ContractTextEditor from 'components/contract-text-editor';

import style from './text-image-box.module.scss';

type TextImageBoxContentProps = {
  agreementId: number;
  boxId: number;
  isAllowedToEdit: boolean;
}

export function TextImageBoxContent({
  agreementId,
  boxId,
  isAllowedToEdit,
}: TextImageBoxContentProps) {
  const box = useCurrentBox(boxId) as TextBox;
  const data = get(box, 'content.data');
  const legacyMarkup = head(data)?.value?.text;
  const textNodes = head(data)?.value?.nodes;
  const dispatch = useDispatch();
  const isNew = isUnsavedData(box);

  const setNodes = useCallback((nodes: Descendant[]) => {
    if (isEmpty(data)) {
      const updatedBox = generateTextBoxData(box, nodes);
      dispatch(updateBoxAction(updatedBox));
      return;
    }

    const boxDataToUpdate = head(data);
    if (boxDataToUpdate.value.nodes === nodes) {
      return;
    }

    const updatedData = {
      value: {
        ...boxDataToUpdate.value,
        nodes,
      },
    };

    dispatch(
      updateBoxDataItemAction(getId(box), getId(boxDataToUpdate), updatedData),
    );
  }, [box, data, dispatch]);

  const onNodesChange = useMemo(() => debounce(setNodes, 200), [setNodes]);

  return (
    <ContractTextEditor
      boxId={boxId}
      contractId={agreementId}
      legacyMarkup={legacyMarkup}
      nodes={textNodes}
      onNodesChange={onNodesChange}
      autoFocus={isNew && isAllowedToEdit}
      readOnly={!isAllowedToEdit}
    />
  );
}

type TextImageBoxProps = {
  boxId: number;
  agreementId: number;
  isEditable: boolean;
  onRemoveBox: () => void;
  onAddSectionRules: () => void;
}

function TextImageBox({
  agreementId,
  boxId,
  isEditable,
  onAddSectionRules,
  onRemoveBox,
}: TextImageBoxProps) {
  const currentBoxId = useCurrentBoxId(boxId);
  const box = useCurrentBox(currentBoxId) as TextBox;
  const data = get(box, 'content.data');
  const isAllowedToAddBoxData = isBoxDataCreateAllowed(box);
  const isAllowedToUpdateDataValue = isBoxDataValueUpdateAllowed(box, head(data));

  const isBoxDataEditable = (): boolean => {
    if (isEmpty(data)) {
      return isAllowedToAddBoxData;
    }

    return isAllowedToUpdateDataValue;
  };

  return (
    <BoxWrapper
      boxId={currentBoxId}
      isAllowedToEdit={isEditable}
      onAddSectionRules={onAddSectionRules}
      onRemoveBox={onRemoveBox}
    >
      <div id="TextImageSection" className={style.TextBoxWrapper}>
        <TextImageBoxContent
          agreementId={agreementId}
          boxId={currentBoxId}
          isAllowedToEdit={isBoxDataEditable()}
        />
      </div>
    </BoxWrapper>
  );
}

export default TextImageBox;
