/* eslint-disable import/named */
import { ReactNode, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormRenderProps } from 'react-final-form';
import { debounce } from 'lodash';

import { Message } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';
import rolesReducer from 'reducers/entities/roles';
import groupAccountsReducer from 'reducers/entities/group-accounts';
import accountGroupsReducer from 'reducers/entities/account-groups';
import { getAccountFromSessionSelector } from 'reducers/session';
import { USER_ROLE_SCOPE_ACCOUNT } from 'user';

import Field from 'components/field';
import SelectField from 'components/select-field';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import { getAccountGroupsQueryName } from 'routes/admin/account-access/account-access';
import CircularSpinnerIcon from 'components/icons/circular-spinner';

import style from './edit-group-account-access.module.scss';

const modalKey = 'edit group account access modal';
export const rolesQueryName = 'admin/group/accounts';
const defaultPagination = {
  limit: 50,
  offset: 0,
};

type Props = {
  children: ReactNode | ((onClick: () => void) => JSX.Element),
  binding: Oneflow.GroupAccountRoleBinding,
  renderedFromAccountAccessPage: boolean,
};

type QueryRoles = {name: string, pagination?: typeof defaultPagination};

type FormValues = {
  role: string,
};

const EditGroupAccountAccess = ({
  children,
  binding,
  renderedFromAccountAccessPage,
}: Props) => {
  const { group, role, id } = binding;
  const dispatch = useDispatch();
  const account = useSelector(getAccountFromSessionSelector);

  const rolesQuery = useSelector((state) => (
    rolesReducer.getQuerySelector(state, { name: rolesQueryName })
  ));

  const roles = useSelector((state) => (
    rolesReducer.getRolesSelector(state, { ids: rolesQuery.result })
  ));

  const formState = useSelector((state) => (
    groupAccountsReducer.getUpdateSelector(state, { id: group.id })
  ));

  const resetFormState = () => {
    dispatch(groupAccountsReducer.updateGroupAccountReset({ id: group.id }));
  };

  const queryRoles = useCallback(({ name, pagination = defaultPagination }: QueryRoles) => {
    dispatch(rolesReducer.queryRoles({
      name: rolesQueryName,
      params: {
        scope: USER_ROLE_SCOPE_ACCOUNT,
        q: name,
      },
      pagination,
      sort: ['-type', 'name'],
    }));
  }, [dispatch]);

  const handleLoadMoreAccountRoles = (additionalResults: number) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: rolesQueryName,
      params: { scope: USER_ROLE_SCOPE_ACCOUNT },
      additionalResults,
    }));
  };

  const handleInputChange = useMemo(() => debounce((name: string) => {
    queryRoles({ name });
  }, 500), [queryRoles]);

  const onSubmit = ({ role: selectedRole }: { role: Oneflow.GroupAccountRoleBinding['role'] }) => {
    const GROUP_ACCOUNT_BINDINGS_QUERY_NAME = `admin/groups/${group.id}/account-access`;
    const actionData = {
      id,
      data: {
        createData: {
          groupId: group.id,
          roleId: selectedRole.id,
        },
        removeData: {
          groupId: group.id,
          roleId: role.id,
        },
      },
      pipe: {
        action: () => groupAccountsReducer.queryGroupAccounts({
          name: GROUP_ACCOUNT_BINDINGS_QUERY_NAME,
          params: {
            groupId: group.id,
            actorType: 'group',
          },
          sort: ['-role.type', 'role.name'],
        }),
      },
    };

    if (renderedFromAccountAccessPage) {
      actionData.pipe.action = () => accountGroupsReducer.queryAccountGroups({
        name: getAccountGroupsQueryName(account.id),
        params: {
          actorType: 'group',
        },
        sort: ['name'],
      });
    }

    dispatch(groupAccountsReducer.updateGroupAccount(actionData));
  };

  const renderActions = ({ closeConfirmation, formProps }:
    {closeConfirmation: () => void, formProps: FormRenderProps<FormValues>}) => (
      <div className={style.ActionButtons}>
        <CancelButton
          onClick={closeConfirmation}
          modalKey={modalKey}
        />
        <Button
          icon={formState.loading ? CircularSpinnerIcon : null}
          kind="primary"
          onClick={formProps.handleSubmit}
          disabled={(formState.hasValidationErrors || formState.loading)}
        >
          <Message id="Confirm" comment="Used to confirm changes in a modal" />
        </Button>
      </div>
  );

  return (
    <ModalForm
      title={(
        <Message
          id="Change role"
          comment="Modal title for changing group account access role."
        />
      )}
      body={(
        <div>
          <Field
            name="role"
            label={(
              <Message
                id="Account role"
                comment="Label for edit role selection to be used for group account access."
              />
            )}
            placeholder={(
              <Message
                id="Select role"
                comment="Placeholder for role selection to be used for group account access."
              />
            )}
            component={SelectField}
            options={roles}
            valueKey="id"
            labelKey="name"
            backspaceRemoves
            isLoading={rolesQuery.loading}
            required
            initialValue={binding.role}
            onInputChange={handleInputChange}
            loadMoreItems={handleLoadMoreAccountRoles}
          />
        </div>
      )}
      onSubmit={onSubmit}
      resetFormState={resetFormState}
      formState={formState}
      onOpen={() => queryRoles({})}
      actions={renderActions}
      modalKey={modalKey}
    >
      {children}
    </ModalForm>
  );
};

export default EditGroupAccountAccess;
