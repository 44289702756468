import getFontAssetsDomain from './get-font-asset-domain.js';

const loadArimo = () => {
  const normalArimo = new FontFace(
    'Arimo',
    `url(${getFontAssetsDomain()}/resources/fonts/arimo/arimo_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicArimo = new FontFace(
    'Arimo',
    `url(${getFontAssetsDomain()}/resources/fonts/arimo/arimo_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldArimo = new FontFace(
    'Arimo',
    `url(${getFontAssetsDomain()}/resources/fonts/arimo/arimo_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicArimo = new FontFace(
    'Arimo',
    `url(${getFontAssetsDomain()}/resources/fonts/arimo/arimo_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalArimo, italicArimo, boldArimo, boldItalicArimo];
};

export default loadArimo;
