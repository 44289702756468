// @flow

import { Transforms } from 'slate';

import deserializeMarkup from '../deserializer/deserialize-markup';

const isCopiedFromExcel = (html) => (
  html.includes('="urn:schemas-microsoft-com:office:excel"')
);

const isCopiedFromWord = (html) => (
  html.includes('="urn:schemas-microsoft-com:office:word"')
);

const isCopiedFromGoogleSheets = (html) => (
  html.includes('google-sheets-html-origin')
);

/* eslint-disable no-param-reassign */
const makeWithHtml = (availableOptions: any) => (editor: any) => {
  const { insertData } = editor;

  editor.insertData = (data) => {
    const slateFragment = data.getData('application/x-slate-fragment');
    if (slateFragment) {
      insertData(data);
      return;
    }

    let html = data.getData('text/html');

    if (html) {
      if (isCopiedFromWord(html) || isCopiedFromExcel(html)) {
        html = html
          .replace(/<!--(.*?)-->/g, '')
          .replace(/>\s+</g, '><')
          .replace(/\r\nstyle=/g, ' style=')
          .replace(/ dir=LTR/g, '')
          .replace(/\r\nlang=(\w+)-(\w+)/g, '')
          .replace(/ lang=(\w+)-(\w+)/g, '');
      }

      const fragment = deserializeMarkup(html, availableOptions);

      if (isCopiedFromExcel(html) || isCopiedFromGoogleSheets(html)) {
        Transforms.insertNodes(editor, fragment);
        return;
      }

      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};

export default makeWithHtml;
