// @flow

import React from 'react';

import ContractList from './contract-list';

type Props = {
  acl: {
    workspace: Acl,
  },
};

export const GlobalSearchModule = (props: Props) => {
  const { acl, ...restProps } = props;
  return <ContractList props={props} acl={acl} {...restProps} />;
};
