import { Message } from '@oneflowab/pomes';

type WorkspaceType = {
  collectionAccessStats : Oneflow.Group['collectionAccessStats']
}

export const Workspaces = ({ collectionAccessStats }: WorkspaceType) => (
  <Message
    id="{count} workspace"
    pluralId="{count} workspaces"
    pluralCondition="count"
    values={{
      count: collectionAccessStats || 0,
    }}
    comment="Showing number of workspaces a group has access to"
  />
);
