// @flow

/* eslint-disable no-param-reassign */
const withImages = (editor: any) => {
  const {
    isVoid,
    isInline,
  } = editor;

  editor.isVoid = (element) => (
    element.type === 'image' ? true : isVoid(element)
  );

  editor.isInline = (element) => (
    element.type === 'image' ? true : isInline(element)
  );

  return editor;
};

export default withImages;
