// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

import { LogoSymbol } from 'components/logo';

import style from './sidebar-logo-symbol.module.scss';

type Props = {
  isContractView: boolean,
  toggleExpandedState: () => void,
};

const SidebarLogoSymbol = ({
  isContractView,
  toggleExpandedState,
}: Props) => {
  const handleOnKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    toggleExpandedState();
  };

  if (isContractView) {
    return (
      <a
        href="/"
        aria-label="Oneflow"
        onKeyDown={handleOnKeyDown}
        className={style.Link}
      >
        <LogoSymbol height="34px" />
      </a>
    );
  }

  return (
    <Link
      to="/"
      aria-label="Oneflow"
      onKeyDown={handleOnKeyDown}
      className={style.Link}
    >
      <LogoSymbol height="34px" />
    </Link>
  );
};

export default SidebarLogoSymbol;
