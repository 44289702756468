import { connect } from 'react-redux';

import positionGroups from 'reducers/entities/position-groups';

import RemoveUserFromGroup from './remove-user-from-group';

export const mapStateToProps = (state, { group }) => ({
  removeState: positionGroups.getRemoveSelector(state, { id: group.id }),
});

export const mapDispatchToProps = (dispatch, { group, position }) => ({
  removeUserFromGroup: () => {
    dispatch(positionGroups.removePositionGroup({
      id: position.id,
      data: {
        positionId: position.id,
        groupIds: [group.id],
      },
      pipe: {
        action: () => positionGroups.queryPositionGroupsReload({
          name: `admin/users/${position.id}/groups`,
        }),
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveUserFromGroup);
