import { useEffect, useCallback, useMemo } from 'react';
import { debounce, uniqBy, orderBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { localize } from '@oneflowab/pomes';
import { orderBy as naturalOrderBy } from 'natural-orderby';
// eslint-disable-next-line import/named
import { OPERATOR_AND } from 'oneflow-client/core';
import type { MessageTranslator } from '@oneflowab/pomes';

import usePreviousUntilValuesChanged from 'hooks/use-previous-until-values-changed';
import positionsReducer from 'reducers/entities/positions';
import { getPositionFromSessionSelector } from 'reducers/session';
import { USER_NOT_INVITED } from 'user';
import { operatorSanitizer } from 'agreement/sanitizers';

import FilterSelectField from 'components/filter-select-field';
import FilterValues from 'components/filter-values/filter-values';
import CheckboxGroup from 'components/checkbox-group';
import { Filter } from 'hocs/search';
import type { Option } from 'components/filter-values/filter-values';

import style from './colleague-selector.module.scss';

const COLLEAGUES_QUERY_NAME = 'colleagues-selector';
const SELECTED_COLLEAGUES_QUERY_NAME = 'selected-colleagues-selector';

export const LIMIT = 50;
type Props = {
  onChange: (value: number[] | null | undefined) => void,
  setOptions: (value: any) => any,
  value: number[] | null,
  message: MessageTranslator,
  isSharedWithMeWorkspace: boolean,
};

export const ColleagueSelector = ({
  onChange,
  setOptions,
  value,
  message,
  isSharedWithMeWorkspace,
}: Props) => {
  const dispatch = useDispatch();

  const defaultSearchMessage = message({
    id: 'Select colleague',
    comment: 'Placeholder for selecting colleagues in colleague selector',
  });

  const selectedColleaguesIds = usePreviousUntilValuesChanged(value || []);

  const colleagueQueryState = useSelector((state) => (
    positionsReducer.getQuerySelector(state, { name: COLLEAGUES_QUERY_NAME })
  ));
  const currentUser = usePreviousUntilValuesChanged(
    useSelector(getPositionFromSessionSelector),
  ) as Oneflow.Position;

  const initialSelectedColleaguesIds = selectedColleaguesIds === null ? [] : selectedColleaguesIds;

  const filterableColleagues = useSelector((state) => (
    positionsReducer.getPositionsSelector(state, {
      ids: colleagueQueryState.result,
    })
  ));

  const selectedColleagues = usePreviousUntilValuesChanged(useSelector((state) => (
    positionsReducer.getPositionsSelector(state, {
      ids: initialSelectedColleaguesIds,
    })
  )));

  const queryAllColleagues = useCallback((searchValue: string) => {
    const params: { invited: number, q?: string } = {
      invited: USER_NOT_INVITED,
    };

    if (searchValue) {
      params.q = searchValue;
    }

    dispatch(positionsReducer.queryPositions({
      name: COLLEAGUES_QUERY_NAME,
      pagination: {
        offset: 0,
        limit: LIMIT,
      },
      params,
      sort: ['active'],
    }));
  }, [dispatch]);

  const queryColleaguesLoadMore = (additionalResults: number) => {
    dispatch(positionsReducer.queryPositionsLoadMore({
      name: COLLEAGUES_QUERY_NAME,
      additionalResults,
    }));
  };

  const querySelectedColleagues = useCallback((ids: Array<number>) => (
    dispatch(positionsReducer.queryPositions({
      name: SELECTED_COLLEAGUES_QUERY_NAME,
      pagination: {
        offset: 0,
        limit: LIMIT,
      },
      params: {
        ids,
      },
    }))), [dispatch]);

  const isMyUserSelected = useCallback(() => (
    selectedColleagues.some((colleague) => colleague.id === currentUser.id)
  ), [currentUser.id, selectedColleagues]);

  const getSortedColleagues = () => {
    const naturalOrderedColleagues = naturalOrderBy(
      filterableColleagues as Oneflow.Position[],
      [(v) => v.fullname],
      ['asc'],
    );
    const unique = uniqBy([currentUser, ...naturalOrderedColleagues], (colleague) => colleague.id);
    const sorted = orderBy(unique, (colleague) => colleague.active, 'desc');

    return (
      sorted
    );
  };

  const getSelectedColleagues = useCallback(() => {
    if (isSharedWithMeWorkspace) {
      return uniqBy([currentUser, ...selectedColleagues], (colleague) => colleague.id);
    }
    if (isMyUserSelected()) {
      return (
        uniqBy([currentUser, ...selectedColleagues], (colleague) => colleague.id)
      );
    }
    return selectedColleagues;
  }, [isSharedWithMeWorkspace, isMyUserSelected, selectedColleagues, currentUser]);

  /*
   * On initial render, we have the ids of the selected colleagues but their values are not loaded
   * In order to be able to display the selected colleagues names as pills, we need to query
   * their values to get the labels and render them on top of the colleague selector
  */
  useEffect(() => {
    const notLoadedColleaguesIds = selectedColleaguesIds.filter(
      (id) => !selectedColleagues.some((colleague) => colleague.id === id),
    );

    if (notLoadedColleaguesIds.length > 0) {
      querySelectedColleagues(notLoadedColleaguesIds);
    }

  // we don't need to run this effect every time selectedColleaguesIds changes, because
  // when the user selects a colleague, it means that the entity has been loaded and we don't
  // need to fetch it again
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (colleagueQueryState.status === 'success') {
      const colleaguesSelected = getSelectedColleagues();
      setOptions(colleaguesSelected);
    }
  }, [colleagueQueryState.status, getSelectedColleagues, setOptions]);

  const mapToOption = useCallback((colleague: Oneflow.Position): Option => {
    const inactiveTooltip = message({
      id: 'Inactive user',
      comment: 'Used as a tooltip in the colleagues list in the document list on colleagues that are inactive',
    });

    if (colleague.id === currentUser.id) {
      return {
        value: colleague.id,
        label: message({
          id: '{user} (you)',
          values: { user: colleague.fullname },
          comment: 'Search checkbox allowing the users to filter their own documents.',
        }),
        isFixed: isSharedWithMeWorkspace,
      };
    }

    return {
      value: colleague.id,
      label: colleague.fullname,
      customValueStyle: colleague.active === 0 ? style.InactiveColleague : undefined,
      tooltipMessage: colleague.active === 0 ? inactiveTooltip : undefined,
      isFixed: false,
    };
  }, [currentUser.id, isSharedWithMeWorkspace, message]);

  const onSearchValueChange = useMemo(() => debounce((input: string) => {
    queryAllColleagues(input);
  }, 300), [queryAllColleagues]);

  return (
    <div className={style.ColleagueSelectorContainer}>
      <FilterValues
        onChange={onChange}
        selectedValues={getSelectedColleagues().map(mapToOption)}
      />
      { selectedColleagues.length >= 1 && (
      <div className={style.MatchAllWrapper}>
        <Filter
          param="positionOperator"
          defaultValue={[]}
          onSanitize={operatorSanitizer}
          options={[{
            value: OPERATOR_AND,
            label: message({
              id: 'Match all colleagues',
              comment: 'Checkbox allowing user to search the combined results of multiple selected colleagues.',
            }),
          }]}
          component={CheckboxGroup}
          wrap={false}
          disabled={!selectedColleagues || selectedColleagues.length < 2}
          tooltipMessage={message({
            id: 'Select at least 2 colleagues to show documents in which they take part together',
            comment: 'Tooltip message explaining what the match all colleagues checkbox does and how to use it.',
          })}
        />
      </div>
      )}
      <FilterSelectField
        loadingMessage={() => message({
          id: 'Loading...',
          comment: 'Loading message when searching for a document in custom reminder modal',
        })}
        isLoading={colleagueQueryState.loading}
        isSearchable
        options={getSortedColleagues().map(mapToOption)}
        totalOptionsCount={colleagueQueryState.count}
        placeholder={defaultSearchMessage}
        name="colleagueSelectField"
        loadMoreItems={queryColleaguesLoadMore}
        onInputChange={onSearchValueChange}
        onChange={onChange}
        selectedValues={getSelectedColleagues().map(mapToOption)}
        aria-label={defaultSearchMessage}
        onMenuOpen={queryAllColleagues}
      />
    </div>
  );
};

export default localize<Props>(ColleagueSelector);
