import clsx from 'clsx';

import Message from 'components/message';
import { COLLAPSED, EXPANDED } from 'components/document-layout-container/helpers';
import EmailWithCheck from 'components/icons/email-with-check';
import type { Layout } from 'components/document-layout-container/types';
import DraftApprovalActions from
  'components/document-call-to-actions/actions/buttons-call-to-action/actions/draft-approval';

import style from './send-draft-for-approval-button.module.scss';

type Props = {
  canPublish?: boolean,
  layout?: Layout,
  agreementId: Oneflow.Agreement['id'],
  showApproveDraftModal: boolean,
  setShowApproveDraftModal: (value: boolean) => void,
}

const SendDraftForApprovalButton = ({
  agreementId,
  layout = EXPANDED,
  canPublish,
  showApproveDraftModal,
  setShowApproveDraftModal,
}: Props) => {
  if (layout === COLLAPSED) {
    return (
      <DraftApprovalActions
        agreementId={agreementId}
        customClassName={clsx(style.SendDraftForApprovalButton, style.Collapsed)}
        canPublish={canPublish}
        data-testid="collapsed-send-for-approval-button"
        layout={layout}
        showApproveDraftModal={showApproveDraftModal}
        setShowApproveDraftModal={setShowApproveDraftModal}
      >
        <EmailWithCheck height="20px" />
      </DraftApprovalActions>
    );
  }

  return (
    <DraftApprovalActions
      agreementId={agreementId}
      customClassName={clsx(style.SendDraftForApprovalButton, style.Expanded)}
      canPublish={canPublish}
      data-testid="collapsed-send-for-approval-button"
      layout={layout}
      showApproveDraftModal={showApproveDraftModal}
      setShowApproveDraftModal={setShowApproveDraftModal}
    >
      <EmailWithCheck height="16px" />
      <Message
        id="Send for approval"
        comment="Button label for sending a document for approval."
      />
    </DraftApprovalActions>
  );
};

export default SendDraftForApprovalButton;
