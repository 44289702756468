import { ComponentPropsWithoutRef, ReactNode } from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import clsx from 'clsx';

import Alert, { SEVERITY_TYPES } from 'components/alert';
import TerminateSnackbar from 'components/icons/terminate-snackbar';
import NewCheck from 'components/icons/new-check';

import style from './snackbar.module.scss';

const DEFAULT_ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

type Props = {
  message: ReactNode,
  setMessage: (message?: ReactNode) => void,
  severity: ComponentPropsWithoutRef<typeof Alert>['severity'],
  autoHideDuration?: number,
  anchorOrigin?: SnackbarOrigin,
  containerClassName?: string,
  open?: boolean
}

const SnackbarComponent = ({
  message,
  setMessage,
  autoHideDuration,
  severity,
  anchorOrigin,
  containerClassName,
  open = true,
}: Props) => {
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(null);
  };

  const isSuccess = severity === SEVERITY_TYPES.SUCCESS;

  const snackbarClasses = clsx(
    style.SnackbarContainer,
    containerClassName,
    isSuccess ? style.Success : style.Alert,
  );

  const renderSnackbar = () => (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleSnackbarClose}
      anchorOrigin={anchorOrigin || DEFAULT_ANCHOR_ORIGIN}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={isSuccess ? SEVERITY_TYPES.SUCCESS : SEVERITY_TYPES.WARNING}
        sx={{ width: '100%' }}
        icon={isSuccess ? <NewCheck /> : <TerminateSnackbar />}
      >
        {message}
      </Alert>
    </Snackbar>
  );

  if (!message) {
    return null;
  }

  return (
    <div className={snackbarClasses}>
      {renderSnackbar()}
    </div>
  );
};

export default SnackbarComponent;
