// @flow

// eslint-disable-next-line import/prefer-default-export
export const getStyle = (element: SlateElement): {} => {
  const style = {
    ...element.style,
  };

  if (element.textAlign) {
    style.textAlign = element.textAlign;
  }

  if (element.marginLeft) {
    style.marginLeft = element.marginLeft;
  }

  if (element.fontSize) {
    style.fontSize = element.fontSize;
  }

  if (element.color) {
    style.color = element.color;
  }

  if (element.backgroundColor) {
    style.backgroundColor = element.backgroundColor;
  }

  return style;
};
