// @flow

import { connect } from 'react-redux';

import auditLogsReducer from 'reducers/entities/audit-logs';
import positionsReducer from 'reducers/entities/positions';
import { isFeatureEnabledSelector } from 'reducers/session';

import type { Dispatch } from 'redux';

import AuditLog from './audit-log';
import type { Props } from './audit-log';

const QUERY_NAME = '/admin/audit-log';

type StateProps = {|
  query: Query,
  auditLogs: Array<AuditLogEvent>,
  positions: Array<Position>,
|};

type DispatchProps = {|
  queryAuditLogs(QueryFuncArgs): void,
    queryPositions(QueryFuncArgs): void,
      queryPositionsLoadMore(additionalResults: number): void,
|};

export type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps>;

type MapStateToProps = (state: State, ownProps?: OwnProps) => StateProps;

type MapDispatchToProps = (Dispatch<*>, ownProps?: OwnProps) => DispatchProps;

export const mapStateToProps: MapStateToProps = (state: State) => {
  const query = auditLogsReducer.getQuerySelector(state, { name: QUERY_NAME });
  const positionsQuery = positionsReducer.getQuerySelector(state, { name: QUERY_NAME });
  const trashCanFeatureFlagEnabled = isFeatureEnabledSelector(state, {
    feature: 'temporaryDocumentTrashCan',
  });

  return {
    query,
    trashCanFeatureFlagEnabled,
    auditLogs: auditLogsReducer.getAuditLogsSelector(state, { ids: query.result }),
    positions: positionsReducer.getPositionsSelector(state, { ids: positionsQuery.result }),
  };
};

const defaultPagination = {
  limit: 6,
  offset: 0,
};

export const mapDispatchToProps: MapDispatchToProps = (dispatch: Dispatch<*>) => ({
  queryAuditLogs: ({ pagination, params }) => {
    dispatch(auditLogsReducer.queryAuditLogs({
      name: QUERY_NAME,
      pagination: {
        ...pagination,
        limit: 50,
      },
      params,
    }));
  },
  queryPositions: ({ params, pagination = defaultPagination }) => {
    dispatch(positionsReducer.queryPositions({
      name: QUERY_NAME,
      params,
      pagination,
      sort: ['fullname'],
    }));
  },
  queryPositionsLoadMore: (additionalResults) => {
    dispatch(positionsReducer.queryPositionsLoadMore({
      name: QUERY_NAME,
      additionalResults,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, $Exact<DispatchProps>, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(AuditLog);
