import EmptyState from 'components/empty-state';
import NoContactsIcon from 'components/icons/no-contacts';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

type Props = {
  message: MessageTranslator,
};

export const AddressBookSearchNoResults = () => (
  <EmptyState
    icon={<NoContactsIcon height="25px" />}
    header={(
      <Message
        id="No contacts found"
        comment="Empty state header. Shown in address book search when no contacts are found."
      />
    )}
    content={(
      <Message
        id="Please adjust your search criteria and try again."
        comment="Empty state content. Shown in address book search when no contacts are found."
      />
    )}
    defaultStyle
  />
);

export default localize<Props>(AddressBookSearchNoResults);
