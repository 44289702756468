// @flow

import React from 'react';
import clsx from 'clsx';

import InnerBubble from './inner-bubble';
import style from './participant-bubble.module.scss';

type Props = {
  label: string | React.Element,
  borderColor?: string,
  renderBadge?: Function,
  className?: string,
  diameter?: number,
  borderSize?: number,
};

const SimpleBubble = ({
  label,
  className,
  borderColor,
  renderBadge,
  diameter,
  borderSize,
}: Props) => (
  <div className={clsx(style.Bubble, className)}>
    <InnerBubble
      label={label}
      shouldFadeBubble={false}
      borderColor={borderColor}
      renderBadge={renderBadge}
      userBadgeDiameter={diameter}
      userBadgeBorderSize={borderSize}
    />
  </div>
);

SimpleBubble.defaultProps = {
  borderColor: undefined,
  renderBadge: undefined,
  className: undefined,
};

export default SimpleBubble;
