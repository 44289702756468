import {
  get,
  has,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isFinite,
  isNull,
  isNumber,
  isPlainObject,
  isString,
  isUndefined,
} from 'lodash';

const isValidJsonPrimitive = (value: unknown) => {
  const isValidNumber = isNumber(value) && isFinite(value as number);

  return isNull(value) || isValidNumber || isBoolean(value) || isString(value);
};

const isValidJsonObjectValue = (value: unknown) => isPlainObject(value) || isArray(value);

const createIdMap = (array: []) => (
  array.reduce((acc: { [key: string]: unknown }, item: unknown) => {
    if (has(item, 'id')) {
      acc[get(item, 'id') as unknown as string] = item;
    }
    return acc;
  }, {})
);

const isIdObjectArray = (data: unknown) => (
  isArray(data) && (data as []).every((item: unknown) => has(item, 'id'))
);

type DataRecord =
  | Record<string | number, unknown>
  | Array<Record<string | number, unknown>>
  | Record<string, never>;

export function getDiff(updatedData: DataRecord, originalData: DataRecord): unknown {
  if (isArray(updatedData)) {
    if (isIdObjectArray(originalData)) {
      // eslint-disable-next-line no-use-before-define
      return getIdObjectArrayDiff(updatedData as [], originalData as []);
    }

    if (isEqual(updatedData, originalData)) {
      return null;
    }

    return updatedData;
  }

  if (isPlainObject(updatedData)) {
    // eslint-disable-next-line no-use-before-define
    return getPlainObjectDiff(
      updatedData as Record<string | number, unknown>,
      originalData as Record<string | number, unknown>,
    );
  }

  return null;
}

function getIdObjectArrayDiff(
  updatedData: Array<Record<string | number, unknown>>,
  originalData: Array<Record<string | number, unknown>>,
): unknown[] | null {
  const originalDataIdMap = createIdMap(originalData as []);
  const diffArray = updatedData.reduce((
    acc: unknown[],
    item: Record<string | number, unknown>,
  ) => {
    if (has(item, 'id')) {
      const originalItem = originalDataIdMap[get(item, 'id') as unknown as string];
      const diff = getDiff(item as DataRecord, originalItem as DataRecord);
      if (!isEmpty(diff)) {
        acc.push(diff);
      }

      return acc;
    }

    acc.push(item);

    return acc;
  }, []);

  return !isEmpty(diffArray) ? diffArray : null;
}

function getPlainObjectDiff(
  updatedData: Record<string | number, unknown>,
  originalData: Record<string | number, unknown>,
): unknown {
  const diff = Object.keys(updatedData).reduce((acc: { [key: string]: unknown }, key: string) => {
    const newChildValue = isUndefined(updatedData[key]) ? null : updatedData[key];
    const oldChildValue = get(originalData, key);

    if (isValidJsonPrimitive(newChildValue)) {
      if (newChildValue !== oldChildValue) {
        acc[key] = newChildValue;
      }
      return acc;
    }

    if (isValidJsonObjectValue(newChildValue)) {
      const nestedDiff = getDiff(newChildValue as DataRecord, oldChildValue as DataRecord);
      if (!isEmpty(nestedDiff)) {
        acc[key] = nestedDiff;
      }
    }

    return acc;
  }, {});

  if (isEmpty(diff)) {
    return null;
  }

  if (has(originalData, 'id')) {
    diff.id = get(originalData, 'id');
  }

  return diff;
}
