import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { COMMENT_STATES } from 'comments/constants';
import { setAgreementSidebarCommentState } from 'reducers/app';

import Message from 'components/message';
import Select from 'components/select';

import style from './comments-state-selector.module.scss';

const {
  COMMENT_ACTIVE,
  COMMENT_ALL,
  COMMENT_RESOLVED,
} = COMMENT_STATES;

type Props = {
  activeCommentState: string,
};

type ActiveStateType = 'active' | 'resolved';

const selectorStates = [
  {
    label: <Message id="Active" comment="Label of an option in a select menu" />,
    value: COMMENT_ACTIVE,
  },
  {
    label: <Message id="Resolved" comment="Label of an option in a select menu" />,
    value: COMMENT_RESOLVED,
  },
  {
    label: <Message id="All" comment="Label of an option in a select menu" />,
    value: COMMENT_ALL,
  },
];

const CommentsStateSelector = ({
  activeCommentState,
}: Props) => {
  const dispatch = useDispatch();

  const isResolvedStateActive = activeCommentState === COMMENT_RESOLVED;

  const setCurrentlyActiveCommentsState = useCallback((activeState: ActiveStateType) => {
    dispatch(setAgreementSidebarCommentState(activeState));
  }, [dispatch]);

  const stateSelectorClasses = clsx(style.StateSelector, {
    [style.StateSelectorResolved]: isResolvedStateActive,
  });

  const itemsClassName = clsx({
    [style.SelectItemResolved]: isResolvedStateActive,
  });

  const selectorLabel = selectorStates.find(
    ({ value }) => value === activeCommentState,
  )?.label;

  return (
    <Select
      selectClassName={stateSelectorClasses}
      value={activeCommentState}
      itemClassName={style.SelectItem}
      itemsClassName={itemsClassName}
      labelKey="label"
      valueKey="value"
      label={selectorLabel}
      onValueChange={setCurrentlyActiveCommentsState}
      items={selectorStates}
    />
  );
};

export default CommentsStateSelector;
