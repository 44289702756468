// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import plansReducer from 'reducers/entities/plans';
import { getAdjustedCurrentLanguageSelector } from 'reducers/i18n';
import { getAccountFromSessionSelector } from 'reducers/session';

import FreemiumSelfServicePlans, { type Props } from './freemium-self-service-plans';

type StateProps = {|
  country: string,
  plans: Array<Plan>,
  languageCode: string,
|};

type DispatchProps = $Exact<{}>;

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps>;

type MapStateToProps = (State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const accountFromSession = getAccountFromSessionSelector(state);

  return {
    country: accountFromSession.country.toLowerCase(),
    plans: plansReducer.getAllPlansSelector(state),
    languageCode: getAdjustedCurrentLanguageSelector(state).toLowerCase(),
  };
};

type MapDispatchToProps = () => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(FreemiumSelfServicePlans);
