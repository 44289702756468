import {
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react';
import type { MutableRefObject, ReactNode } from 'react';

import { MenuItem, OnKeyDown } from './types';
import { subMenuStateContext, useSubMenuStateContext } from './sub-root';
import useMenuStateContext from './use-menu-state';

export type MenuSubContextValue = {
  id: string;
  isSubmenuOpen: boolean;
  registerItem: (value: MenuItem) => void;
  unregisterItem: (value: MenuItem) => void;
  handleKeyDown: OnKeyDown;
  triggerRefId: MutableRefObject<string | null>;
};
const MenuSubContext = createContext<MenuSubContextValue | null>(null);

export const useSubContext = () => {
  const context = useContext(MenuSubContext);

  if (!context) {
    throw new Error('useMenuSubContext must be used within a MenuSubProvider');
  }

  return context;
};

const SubProvider = ({ children }: { children: ReactNode }) => {
  const { state } = useSubMenuStateContext();
  const { id } = state;
  const triggerRefId = useRef<string | null>(null);
  const {
    registerItem,
    unregisterItem,
    handleKeyDown,
  } = useMenuStateContext(subMenuStateContext);

  const contextValue = useMemo(() => ({
    id,
    isSubmenuOpen: state.isOpen || false,
    registerItem,
    unregisterItem,
    handleKeyDown,
    triggerRefId,
  }), [id, state.isOpen, registerItem, unregisterItem, handleKeyDown]);

  return (
    <MenuSubContext.Provider value={contextValue}>
      <div style={{ position: 'relative' }}>
        {children}
      </div>
    </MenuSubContext.Provider>
  );
};

export default SubProvider;
