import { PureComponent, createRef } from 'react';
import type { ReactNode, RefObject } from 'react';
import 'element-closest-polyfill';
import { uniqueId } from 'lodash';
import clsx from 'clsx';
import { DropdownMenu, Menu } from '@react-md/menu';
import type { DropdownMenuProps, InjectedMenuProps } from '@react-md/menu';
import { SCALE_CLASSNAMES, SCALE_Y_CLASSNAMES } from '@react-md/transition/lib/constants';
import { List } from '@react-md/list';

import 'utils/node-contains-polyfill';

import 'styles/external/react-md.scss';
import style from './dropdown-menu.module.scss';

type Props = Omit<DropdownMenuProps, 'id'> & {
  anchor: any,
  className?: string,
  items: ReactNode[],
  children?: ReactNode,
  onVisibilityChange?: (visible?: boolean) => void,
  hideMenuOnExit?: boolean,
  portal?: boolean,
  slideIn?: boolean,
  focusOnCloseDisabled?: boolean,
  dropdownRef?: RefObject<HTMLButtonElement>,
};

export const scaleClassNames = {
  ...SCALE_CLASSNAMES,
  exitDone: clsx('rmd-menu-hiden', style.MenuHidden),
};

export const mountedMenuRenderer = ({
  horizontal,
  children,
  className,
  ...props
}: InjectedMenuProps): ReactNode => (
  <Menu
    {...props}
    horizontal={horizontal}
    unmountOnExit={false}
    classNames={scaleClassNames}
    data-testid="mounted-menu"
  >
    <List horizontal={horizontal}>{children}</List>
  </Menu>
);

export const slideInRenderer = ({
  horizontal,
  children,
  className,
  ...props
}: InjectedMenuProps): ReactNode => (
  <Menu
    {...props}
    horizontal={horizontal}
    classNames={SCALE_Y_CLASSNAMES}
    data-testid="slide-in-menu"
  >
    <List horizontal={horizontal}>{children}</List>
  </Menu>
);

export default class DropdownMenuComponent extends PureComponent<Props> {
  static defaultProps = {
    className: undefined,
    hideMenuOnExit: undefined,
    portal: undefined,
    slideIn: undefined,
    focusOnCloseDisabled: undefined,
  };

  static onMenuCloseTimer: NodeJS.Timer | null = null;

  static onMenuOpenTimer: NodeJS.Timer | null = null;

  constructor(props: Props) {
    super(props);

    this.dropdownId = uniqueId();
    this.dropdownRef = this.props.dropdownRef || createRef();
  }

  handleOnMenuClose = () => {
    const { focusOnCloseDisabled } = this.props;

    DropdownMenuComponent.onMenuCloseTimer = setTimeout(() => {
      if (DropdownMenuComponent.onMenuOpenTimer) {
        clearTimeout(DropdownMenuComponent.onMenuOpenTimer);
        DropdownMenuComponent.onMenuOpenTimer = null;
      }

      if (focusOnCloseDisabled) {
        return;
      }

      if (this.dropdownRef.current) {
        this.dropdownRef.current.focus();
      }
    }, 0);
  };

  handleOnMenuOpen = () => {
    DropdownMenuComponent.onMenuOpenTimer = setTimeout(() => {
      if (DropdownMenuComponent.onMenuCloseTimer) {
        clearTimeout(DropdownMenuComponent.onMenuCloseTimer);
        DropdownMenuComponent.onMenuCloseTimer = null;
      }

      const emptyFocusDividerSelector = `.rmd-menu:not(.rmd-menu-hiden) .${style.EmptyFocusDivider}`;
      const emptyFocusDivider = document.querySelector(emptyFocusDividerSelector) as HTMLElement;
      if (emptyFocusDivider) {
        emptyFocusDivider.focus();
      }
    }, 0);
  };

  handleOnVisibilityChange = (visible: boolean) => {
    const { onVisibilityChange } = this.props;

    if (onVisibilityChange) {
      setTimeout(() => {
        onVisibilityChange(visible);
      }, 0);
    }

    if (!visible) {
      this.handleOnMenuClose();

      return;
    }

    this.handleOnMenuOpen();
  };

  dropdownId: string;

  dropdownRef: {
    current: null | HTMLButtonElement,
  };

  render() {
    const {
      anchor,
      className,
      items,
      children,
      onVisibilityChange,
      hideMenuOnExit,
      portal,
      slideIn,
      focusOnCloseDisabled,
      dropdownRef,
      ...props
    } = this.props;
    let menuRenderer;
    if (hideMenuOnExit) {
      menuRenderer = mountedMenuRenderer;
    }
    if (slideIn) {
      menuRenderer = slideInRenderer;
    }

    return (
      <DropdownMenu
        {...props}
        ref={this.dropdownRef}
        id={this.dropdownId}
        items={[
          <li key={uniqueId()} className={style.EmptyFocusDivider} tabIndex={-1} />, ...items,
        ]}
        anchor={anchor}
        className={className}
        onVisibilityChange={this.handleOnVisibilityChange}
        menuRenderer={menuRenderer}
        closeOnScroll
        closeOnResize
        portal={Boolean(portal)}
      >
        {children}
      </DropdownMenu>
    );
  }
}
