// @flow

import * as React from 'react';
import { localize, Message } from '@oneflowab/pomes';
import { FadeIn } from 'components/transitions';
import CreateContractIcon from 'components/icons/new-create-contract';

import style from './create-contract-icon-area.module.scss';

type Props = {
  expanded: boolean,
  isMobile: boolean,
};

const CreateContractIconArea = ({
  expanded,
  isMobile,
}: Props) => {
  if (expanded || isMobile) {
    return (
      <FadeIn in timeout={100} transitionTime={500}>
        <CreateContractIcon height="30px" className={style.CreateContractIconArea} />
        <span>
          <Message
            id="New document"
            comment="Used for button label in sidebar."
          />
        </span>
      </FadeIn>
    );
  }

  return (
    <div>
      <FadeIn in timeout={0} transitionTime={500}>
        <CreateContractIcon height="30px" />
        <span />
      </FadeIn>
    </div>
  );
};

export default localize<Props>(CreateContractIconArea);
