import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';

import { useComposedRefs } from 'hooks/compose-refs';

import Item from './item';
import { useSubContext } from './sub-context';
import { subMenuStateContext } from './sub-root';
import type { ItemProps } from './item';
import type { DivProps } from './types';

const SubItem = forwardRef<HTMLDivElement, ItemProps & DivProps>(({
  children,
  ...props
}, forwardedRef) => {
  const { registerItem, unregisterItem } = useSubContext();

  const ref = useRef<HTMLDivElement>(null);

  const composedRef = useComposedRefs(forwardedRef, ref);

  useLayoutEffect(() => {
    registerItem({ id: props.id, type: 'item' });
  }, [props.id, registerItem]);

  useEffect(() => () => {
    unregisterItem({ id: props.id, type: 'item' });
  }, [props.id, unregisterItem]);

  return (
    <Item {...props} scope={subMenuStateContext} ref={composedRef}>
      {children}
    </Item>
  );
});

SubItem.displayName = 'SubMenuItem';

export default SubItem;
