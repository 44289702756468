import { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { getAgreementDateFormat } from 'date';

import agreementsReducer from 'reducers/entities/agreements';
import {
  getPeriodEndDate,
  getNoticeEndDate,
  getNextPeriodEndDate,
} from 'agreement/date-helpers';
import { getAgreementMyParty, getMyParticipantWhenUpdater, getGuestToken } from 'agreement/selectors';

import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';
import Cancel from 'components/icons/cancel';

import { InfoBox } from 'components/error-box';
import {
  isAwaiting,
  isCancelContractVisible,
  inNoticePeriod,
} from 'agreement';
// eslint-disable-next-line import/named
import { RemoveConfirm } from 'components/modals/remove-confirm';
import DateWithTooltip from './date-with-tooltip';
import TimePeriodFormatter from './helpers/time-period-formatter';

import style from './cancel-contract.module.scss';

export type Props = {
  agreement: Oneflow.Agreement,
  children?: (
    onClick: () => void,
    disabled: boolean
  ) => ReactNode
};

export const handleConfirm = (
  cancelAgreement: (guestToken: string) => void,
  isLoading: boolean,
  guestToken: string,
) => () => {
  if (isLoading) {
    return;
  }

  cancelAgreement(guestToken);
};

export const CancelContract = ({
  agreement,
  children,
}: Props) => {
  const dispatch = useDispatch();
  const rpcState = useSelector(
    (state) => agreementsReducer.getCancelAgreementSelector(state, { id: agreement?.id }),
  );
  const guestToken = useSelector(getGuestToken);

  if (isEmpty(agreement)) {
    return null;
  }

  const myParty = getAgreementMyParty(agreement);
  const myParticipant = getMyParticipantWhenUpdater(agreement);
  const dateFormat = getAgreementDateFormat(agreement.config?.dateFormat);

  const noticeEndDate = getNoticeEndDate(agreement.periodEndTimestamp, agreement.noticePeriod);
  const nextPeriodEndDate = getNextPeriodEndDate(
    agreement.periodEndTimestamp, agreement.duration,
  );

  const withInitialStyle = !inNoticePeriod(agreement) ? style.NoticePeriod : undefined;
  const withInitialDurationInfoBoxStyle = inNoticePeriod(agreement)
    ? style.withInitialDurationInfoBox : null;

  const cancelAgreement = (token: string) => {
    dispatch(agreementsReducer.cancelAgreement({
      id: agreement.id,
      data: {
        manual: 0,
        guestToken: token ?? guestToken,
      },
    }));
  };

  const resetRpcState = () => {
    dispatch(agreementsReducer.cancelAgreementReset({
      id: agreement.id,
    }));
  };

  const getActions = () => (
    <Button
      icon={rpcState.loading ? CircularSpinner : null}
      disabled={rpcState.loading}
      onClick={handleConfirm(cancelAgreement, rpcState.loading, guestToken)}
      color="red"
    >
      <Message
        id="Cancel contract"
        comment="Text for button to cancel contract."
      />
    </Button>
  );

  const renderMessageWhenIsNotAwaiting = () => {
    if (!isAwaiting(agreement)) {
      return (
        <>
          <Message
            id="current period started {startTime} and ends {endTime}"
            values={{
              startTime: (
                <DateWithTooltip
                  dateString={String(agreement.periodStartTimestamp)}
                  dateFormat={dateFormat}
                  className={style.DateStyle}
                />
              ),
              endTime: (
                <DateWithTooltip
                  dateString={getPeriodEndDate(agreement.periodEndTimestamp)}
                  dateFormat={dateFormat}
                  className={style.DateStyle}
                />
              ),
            }}
            comment="Explaining text in cancel contract modal."
          />
        </>
      );
    }
    return null;
  };

  const renderInitialPeriod = () => {
    if (!agreement.initialDuration) {
      return (
        <tr data-testId="not-initial-period">
          <td className={style.SemiBold}>
            <Message
              id="Contract period:"
              comment="Text to show contract period title."
            />
          </td>
          <td>
            <TimePeriodFormatter
              timePeriod={String(agreement.duration)}
            />
          </td>
          <td>
            {renderMessageWhenIsNotAwaiting()}
          </td>
        </tr>
      );
    }

    return (
      <>
        <tr data-testId="initial-period">
          <td className={style.SemiBold}>
            <Message
              id="Initial period:"
              comment="Text to show initial period title."
            />
          </td>
          <td colSpan={2}>
            <TimePeriodFormatter
              timePeriod={agreement.initialDuration}
            />
          </td>
        </tr>
        <tr>
          <td className={style.SemiBold}>
            <Message
              id="Following periods:"
              comment="Text to show following period title."
            />
          </td>
          <td>
            <TimePeriodFormatter
              timePeriod={String(agreement.duration)}
            />
          </td>
          <td>
            {renderMessageWhenIsNotAwaiting()}
          </td>
        </tr>
      </>
    );
  };

  const renderNoticeBox = () => {
    if (!inNoticePeriod(agreement)) {
      return (
        <div className={style.NoticeBox}>
          <Message
            id="The notice period started on {noticeEndDate}, and the contract will be renewed."
            values={{
              noticeEndDate: (
                <DateWithTooltip
                  dateString={noticeEndDate}
                  dateFormat={dateFormat}
                  className={style.DateStyle}
                />
              ),
            }}
            comment="Explaining text for notice period start."
          />
          <br />
          <Message
            id="If canceled now, the contract will end on {nextPeriodEndDate}."
            values={{
              nextPeriodEndDate: (
                <DateWithTooltip
                  dateString={nextPeriodEndDate}
                  dateFormat={dateFormat}
                  className={style.DateStyle}
                />
              ),
            }}
            comment="Explaining text for notice period end."
          />
        </div>
      );
    }
    return null;
  };

  const renderNoticePeriodMessage = () => {
    if (inNoticePeriod(agreement)) {
      return (
        <>
          <Message
            id="cancel before {noticeEndDate}"
            values={{
              noticeEndDate: (
                <DateWithTooltip
                  dateString={noticeEndDate}
                  dateFormat={dateFormat}
                  className={style.DateStyle}
                />
              ),
            }}
            comment="Text that shows to the user that they can cancel a contract before this date."
          />
        </>
      );
    }

    return (
      <>
        <Message
          id="was passed {noticeEndDate}"
          values={{
            noticeEndDate: (
              <DateWithTooltip
                dateString={noticeEndDate}
                dateFormat={dateFormat}
                className={style.DateStyle}
              />
            ),
          }}
          comment="Text to show notice period has passed"
        />
      </>
    );
  };

  const renderNoticePeriod = () => (
    <tr className={withInitialStyle}>
      <td className={style.SemiBold}>
        <Message
          id="Notice period:"
          comment="Text to show notice period title."
        />
      </td>
      <td>
        <TimePeriodFormatter
          timePeriod={String(agreement.noticePeriod)}
        />
      </td>
      <td>
        {renderNoticePeriodMessage()}
      </td>
    </tr>
  );

  const renderInfoBoxMessages = () => {
    if (myParty?.individual) {
      if (myParticipant?.ssn) {
        return (
          <li>
            <Message
              id="You are {name} with date of birth {ssn}."
              values={{
                name: myParticipant?.fullname,
                ssn: myParticipant?.ssn,
              }}
              comment="Participant information text in cancel contract modal."
            />
          </li>
        );
      }

      return (
        <li>
          <Message
            id="You are {name}."
            values={{
              name: myParticipant?.fullname,
            }}
            comment="Participant information text in cancel contract modal."
          />
        </li>
      );
    }

    return (
      <>
        <li>
          <Message
            id="You are {name} {title} from {party}."
            values={{
              name: myParticipant?.fullname,
              title: myParticipant?.title ? myParticipant.title : '',
              party: myParty?.name,
            }}
            comment="Participant information text in cancel contract modal."
          />
        </li>
        <li>
          <Message
            id="You are authorized to cancel the contract on behalf of {party}."
            values={{
              party: myParty?.name,
            }}
            comment="Text explaining that you as a participant are authorized to cancel the contract on behalf of your party."
          />
        </li>
      </>
    );
  };

  const renderInfoBox = () => (
    <div className={clsx(style.BulletList, withInitialDurationInfoBoxStyle)}>
      <InfoBox
        headerText={(
          <>
            <Message
              id="By clicking {jsx-start}Cancel contract{jsx-end}, you confirm the following:"
              comment="Info box header in cancel contract modal."
              component="span"
              className={style.SemiBold}
            />
          </>
        )}
        bodyText={(
          <ul>
            {renderInfoBoxMessages()}
            <li>
              <Message
                id="You have the intention to cancel the contract."
                comment="Info box text in cancel contract modal."
              />
            </li>
          </ul>
        )}
      />
    </div>
  );

  const renderBody = () => (
    <>
      <p className={style.Header}>
        <Message
          id="Please confirm that you want to cancel this contract. It will be marked for termination immediately, but will remain active until the end of its term."
          comment="Explaning text in cancel contract modal."
        />
      </p>
      <div className={style.BodyMessage}>
        <table>
          <tbody>
            <tr>
              <td className={style.SemiBold}>
                <Message
                  id="Start date:"
                  comment="Text to show start date title."
                />
              </td>
              <td colSpan={2}>
                <DateWithTooltip
                  dateString={String(agreement.startTimestamp)}
                  dateFormat={dateFormat}
                  className={style.DateStyle}
                />
              </td>
            </tr>
            {renderInitialPeriod()}
            {renderNoticePeriod()}
          </tbody>
        </table>
        {renderNoticeBox()}
        {renderInfoBox()}
      </div>
    </>
  );

  const getChildren = (onClick: () => void) => {
    if (!isCancelContractVisible(agreement)) {
      return null;
    }

    if (children) {
      return children(onClick, isEmpty(myParticipant));
    }

    return (
      <Button
        customClass={style.OpenModalButton}
        onClick={onClick}
        icon={Cancel}
        disabled={isEmpty(myParticipant)}
      >
        <Message
          id="Cancel contract"
          comment="Label for Cancel contract."
        />
      </Button>
    );
  };

  return (
    <RemoveConfirm
      onConfirm={cancelAgreement}
      confirmState={rpcState}
      resetConfirmState={resetRpcState}
      renderButton={getActions}
      confirmMessage={renderBody()}
      modalKey="cancel contract modal"
    >
      {getChildren}
    </RemoveConfirm>
  );
};
