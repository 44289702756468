import getFontAssetsDomain from './get-font-asset-domain.js';

const loadIBMPlexMono = () => {
  const normalIBMPlexMono = new FontFace(
    'IBM Plex Mono',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-mono/ibm-plex-mono_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicIBMPlexMono = new FontFace(
    'IBM Plex Mono',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-mono/ibm-plex-mono_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldIBMPlexMono = new FontFace(
    'IBM Plex Mono',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-mono/ibm-plex-mono_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicIBMPlexMono = new FontFace(
    'IBM Plex Mono',
    `url(${getFontAssetsDomain()}/resources/fonts/ibm-plex-mono/ibm-plex-mono_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalIBMPlexMono, italicIBMPlexMono, boldIBMPlexMono, boldItalicIBMPlexMono];
};

export default loadIBMPlexMono;
