import { normalize, schema } from 'normalizr';

import { get, extractResponseBodyAsJSON } from 'oneflow-client/core';

const agreementStatisticsSchema = new schema.Entity('agreementStatistics', {}, {
  idAttribute: 'agreementId',
});

export const normalizeAgreementsStatistics = (data) => ({
  ...normalize(data.stats.agreements, [agreementStatisticsSchema]),
  count: data.stats.agreements.length,
  fromDate: data.stats.fromDate,
  toDate: data.stats.toDate,
});

export const getStatistics = ({
  params: {
    workspaceId,
    positionId,
    fromDate,
    toDate,
  },
}) => (
  get({
    url: '/dashboard/',
    params: {
      collection_id: workspaceId,
      position_id: positionId,
      from_date: fromDate,
      to_date: toDate,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAgreementsStatistics)
);
