// @flow

import Pusher from 'pusher-js';

import type { Subscription } from 'web-socket';

import pusher from './pusher';

export default {
  get isConnected() {
    return pusher.isConnected();
  },
  initialize(pusherInstance?: Pusher) {
    pusher.initialize(pusherInstance);
  },
  setGuestToken(guestToken: string) {
    pusher.setGuestToken(guestToken);
  },
  subscribe(subscription: Subscription): () => void {
    pusher.bindCallback(subscription);

    return () => {
      pusher.unbindCallback(subscription);
    };
  },
  onConnect(callback: () => void) {
    pusher.onConnect(callback);
  },
  onDisconnect(callback: () => void) {
    pusher.onDisconnect(callback);
  },
};
