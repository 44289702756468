// @flow

import * as React from 'react';
import { get, map, omit } from 'lodash';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { Form, FormSpy } from 'react-final-form';
import MakeAsyncFunction from 'react-redux-promise-listener';

import promiseListener from 'store/promise-listener';

import * as EditableForm from 'components/editable-form';
import Field from 'components/field';
import Divider from 'components/divider';
import EmptyState from 'components/empty-state';
import adminPage from 'hocs/admin-page';
import { isPaidAccount } from 'account';
import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';
import Alert from 'components/icons/alert';
import CheckCircle from 'components/icons/check-circle';
import SecurityLock from 'components/icons/security-lock';
import SecurityUnlock from 'components/icons/security-unlock';
import DesktopMonitorRemove from 'components/icons/desktop-monitor-remove';
import { HelpCenterLink } from 'components/help-center-link';
import { checkAcl } from 'components/acl';
import SelectField from 'components/select-field';
import TextField, { PasswordField } from 'components/text-field';
import PhoneField from 'components/phone-field';
import MfaChannel, { mfaChannelTypes, MFA_EMAIL, MFA_SMS } from 'components/mfa-channel-type';
import Tooltip from 'components/tooltip';
import { getErrorMessage } from 'components/api-error';

import RevokeTrustedDevices from './confirmations/revoke-trusted-devices';
import VerifyDevice from './confirmations/verify-device';
import DisableMfa from './confirmations/disable-mfa';
import style from './security.module.scss';

export type CreatePositionMfaArgs = {
  positionId: number,
  password: string,
  channelData: string,
  channel: MfaChannelType,
};

type FormData = {
  password: string,
  phoneNumber: string,
  channel: MfaChannelType,
};

export type Props = {
  accountCountry?: string,
  message: MessageTranslator,
  position: Position,
  formState: CreateState,
  fetchState: FetchState,
  positionMfa: PositionMfa,
  resetCreateState: ({ positionId: number }) => void,
  fetchPositionMfa: ({ positionId: number }) => void,
  actions: {
    start: string,
    success: string,
    fail: string,
  },
  sendData: FormData => void,
  account: Account,
};

type State = {
  isEditMode: boolean,
  isVerifyDeviceModalOpen: boolean,
};

type ChannelInfo = {
  label: string,
  aclCheck: string,
};

export class SecurityPage extends React.Component<Props, State> {
  static defaultProps = {
    accountCountry: undefined,
  }

  state = {
    isEditMode: false,
    isVerifyDeviceModalOpen: false,
  };

  componentDidMount() {
    this.fetchPositionMfa();
  }

  componentDidUpdate(prevProps: Props) {
    const { formState, positionMfa } = this.props;

    if (!prevProps.formState.success && formState.success) {
      this.hideForm();

      if (positionMfa.channel === MFA_SMS) {
        this.openVerifyDeviceModal();
      }
    }
  }

  getAvailableChannelsAsOptions = () => {
    const { message, position, account } = this.props;
    const channelTypes = mfaChannelTypes(message);
    const availableChannelTypes = isPaidAccount(account) ? channelTypes : omit(channelTypes, 'sms');

    return map<ChannelInfo, any, any>(availableChannelTypes, (channelInfo, value) => ({
      label: channelInfo.label,
      value,
      disabled: !checkAcl(position.acl, channelInfo.aclCheck),
    }));
  };

  getEnableButton() {
    return (
      <Button
        data-testid="enable-user-mfa"
        icon={SecurityLock}
        onClick={() => this.setIsEditMode(true)}
        kind="primary"
      >
        <Message
          id="Enable two-step authentication"
          comment="Button label for enabling two-step authentication"
        />
      </Button>
    );
  }

  getDisableButton = (onClick: () => void) => (
    <Button
      data-testid="disable-user-mfa"
      icon={SecurityUnlock}
      kind="primary"
      onClick={onClick}
    >
      <Message
        id="Disable two-step authentication"
        comment="Button label for disabling two-step authentication"
      />
    </Button>
  );

  renderDisableMfa = () => {
    const { message, position } = this.props;

    return (
      <DisableMfa
        message={message}
        position={position}
      >
        {this.getDisableButton}
      </DisableMfa>
    );
  }

  getInitialValues = () => {
    const { position, positionMfa } = this.props;

    return {
      channel: positionMfa.channel || MFA_EMAIL,
      phoneNumber: positionMfa.channel === MFA_SMS ? positionMfa.channelData : undefined,
      email: position.email,
    };
  };

  openVerifyDeviceModal = () => {
    this.setState({
      isVerifyDeviceModalOpen: true,
    });
  };

  closeVerifyDeviceModal = () => {
    this.setState({
      isVerifyDeviceModalOpen: false,
    });
  };

  isChannelSelected = (mfaChannel: MfaChannelType) => ({ values }: { values: FormData }) => {
    const selectedChannel = get(values.channel, 'value') || values.channel;

    return selectedChannel === mfaChannel;
  };

  setIsEditMode = (_isEditMode: boolean) => {
    const { formState } = this.props;

    if (!formState.pristine) {
      this.handleCreateStateReset();
    }

    if (!_isEditMode) {
      this.fetchPositionMfa();
    }

    this.setState({ isEditMode: _isEditMode });
  };

  hideForm = () => {
    this.setState({
      isEditMode: false,
    });
  };

  fetchPositionMfa = () => {
    const { position, fetchPositionMfa } = this.props;

    fetchPositionMfa({ positionId: position.id });
  };

  handleCreateStateReset = () => {
    const { resetCreateState, position } = this.props;

    resetCreateState({ positionId: position.id });
  };

  getRevokeTrustedDevicesButton = (onClick: () => void) => (
    <Button
      data-testid="create-message-template"
      icon={DesktopMonitorRemove}
      kind="secondary"
      onClick={onClick}
    >
      <Message
        id="Revoke your trusted devices"
        comment="Button label for forgetting all trusted devices"
      />
    </Button>
  );

  parseSubmitError = (error) => {
    const errorCode = get(error, 'body.api_error_code');
    const wrongPasswordErrorCode = 1013;
    const errorMessage = getErrorMessage(wrongPasswordErrorCode);
    if (errorMessage && errorCode === wrongPasswordErrorCode) {
      return {
        password: errorMessage.headerText,
      };
    }

    return null;
  }

  shouldShowEmptyState() {
    const { positionMfa } = this.props;
    const { isEditMode } = this.state;

    return !positionMfa.enabled && !isEditMode;
  }

  renderPhoneNumberTooltip() {
    const { message, positionMfa } = this.props;

    if (positionMfa.channelStatus === 'unverified') {
      return (
        <Tooltip
          message={message({
            id: 'The phone number is not verified. To use two-step authentication, you need to verify your phone number.',
            comment: 'Tooltip message for unverified phone number on the two-factor authentication page.',
          })}
        >
          <span className={style.PhoneUnverified}>
            <Alert height="16px" />
          </span>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        message={message({
          id: 'Verified number',
          comment: 'Tooltip message for verified phone number on the two-factor authentication page.',
        })}
      >
        <span className={style.PhoneVerified}>
          <CheckCircle height="16px" />
        </span>
      </Tooltip>
    );
  }

  renderPhoneNumber() {
    const { channelData: phoneNumber } = this.props.positionMfa;

    return (
      <>
        {phoneNumber}
        {this.renderPhoneNumberTooltip()}
        {this.renderVerifyDeviceButton()}
      </>
    );
  }

  renderRevokeTrustedDevices() {
    const { position, positionMfa } = this.props;

    if (!positionMfa.enabled) {
      return null;
    }

    return (
      <>
        <p>
          <Message
            id="After revoking your trusted devices, you will need to enter a new security code the next time you log in."
            comment="Help text for revoking trusted devices on the security page."
          />
        </p>
        <RevokeTrustedDevices position={position}>
          {this.getRevokeTrustedDevicesButton}
        </RevokeTrustedDevices>
      </>
    );
  }

  renderVerifyDeviceButton() {
    const { position, positionMfa, message } = this.props;
    const { isVerifyDeviceModalOpen } = this.state;

    if (positionMfa.channelStatus !== 'unverified') {
      return null;
    }

    return (
      <div className={style.VerifyButton}>
        <VerifyDevice
          message={message}
          position={position}
          fetchPositionMfa={this.fetchPositionMfa}
          channelData={positionMfa.channelData}
          hasCode={positionMfa.hasCode}
          isModalOpen={isVerifyDeviceModalOpen}
          onModalClose={this.closeVerifyDeviceModal}
        />
      </div>
    );
  }

  getSubmitHandler = (handleSubmitAsync) => {
    const submitHandler = async (values) => {
      try {
        await handleSubmitAsync(values);
      } catch (error) {
        return error;
      }
      return null;
    };
    return submitHandler;
  }

  renderHeader(formProps) {
    const { isEditMode } = this.state;

    if (isEditMode) {
      return (
        <EditableForm.Actions
          resetFormState={this.handleCreateStateReset}
          formState={formProps}
          isEditMode
          setIsEditMode={this.setIsEditMode}
        />
      );
    }

    if (this.shouldShowEmptyState()) {
      return this.getEnableButton();
    }

    return this.renderDisableMfa();
  }

  render() {
    const {
      actions,
      fetchState,
      positionMfa,
      accountCountry,
      sendData,
      position,
      message,
    } = this.props;

    const { isEditMode } = this.state;

    if (fetchState.loading) {
      return (
        <div className={style.Loading}>
          <CircularSpinner />
        </div>
      );
    }

    return (
      <MakeAsyncFunction
        listener={promiseListener}
        start={actions.start}
        resolve={actions.success}
        reject={actions.fail}
        setPayload={(action, payload) => ({
          ...action,
          ...sendData(payload),
        })}
        getError={(action) => this.parseSubmitError(action.error)}
      >
        {(handleSubmitAsync) => (
          <Form
            initialValues={this.getInitialValues()}
            onSubmit={this.getSubmitHandler(handleSubmitAsync)}
            render={({ handleSubmit, ...formProps }) => (
              <form onSubmit={handleSubmit}>
                <EditableForm.Header>
                  {this.renderHeader(formProps)}
                </EditableForm.Header>
                <EditableForm.Body>
                  {this.shouldShowEmptyState() ? (
                    <EmptyState
                      icon={<SecurityLock height="33px" />}
                      header={message({
                        id: 'Two-step authentication',
                        comment: 'Used as the header text for the security tab when the two-step authentication for the position is not enabled',
                      })}
                      content={(
                        <div>
                          <Message
                            id="By enabling two-step authentication your user will be protected by an extra layer of security."
                            comment="Used as the text before the support link when the two-step authentication for the position is not enabled"
                          />
                          <br />
                          <HelpCenterLink path="support/solutions/articles/77000435924-two-step-authentication-for-login" />
                        </div>

                      )}
                      defaultStyle
                      className={style.Empty}
                    />
                  ) : (
                    <>
                      <h2 className={style.Header}>
                        <Message
                          id="Two-step authentication"
                          comment="Used as the header title of the settings section in the two-step authentication page"
                        />
                      </h2>
                      <p>
                        <div className={style.Description}>
                          <Message
                            id="By enabling two-step authentication your user will be protected by an extra layer of security. A security code will be sent using the selected method and required at login."
                            comment="Used as the description of the settings section in the two-step authentication page"
                          />
                        </div>
                      </p>

                      <div className={style.Row}>
                        <EditableForm.Label>
                          <Message
                            id="Method"
                            comment="Used as the label of the method field in the two-step authentication settings page"
                          />
                        </EditableForm.Label>
                        <Field
                          name="channel"
                          component={SelectField}
                          options={this.getAvailableChannelsAsOptions()}
                          placeholder={message({
                            id: 'Select two-step authentication method',
                            comment: 'Used as the placeholder of the method field in the two-step authentication settings page',
                          })}
                          // Using the label only for the required validation
                          hideLabel
                          label={message({
                            id: 'Method',
                            comment: 'Used as the label of the method field in the two-step authentication settings page',
                          })}
                          clearable={false}
                          searchable={false}
                          required
                          value={<MfaChannel channel={positionMfa.channel} />}
                          disabled={!isEditMode}
                        />
                      </div>

                      <FormSpy subscription={{ values: true }}>
                        {(props) => {
                          if (this.isChannelSelected(MFA_SMS)(props)) {
                            if (isEditMode) {
                              return (
                                <div className={style.Row}>
                                  <EditableForm.Label>
                                    <Message
                                      id="Phone number"
                                      comment="Used as the label of the channel data field in the two-step authentication settings page when SMS is selected as channel type."
                                    />
                                  </EditableForm.Label>
                                  <Field
                                    name="phoneNumber"
                                    component={PhoneField}
                                    // Using the label only for the required validation
                                    hideLabel
                                    label={message({
                                      id: 'Phone number',
                                      comment: 'Used as the label of the channel data field in the two-step authentication settings page when SMS is selected as channel type.',
                                    })}
                                    required
                                    phone
                                    defaultCountry={(
                                      accountCountry ? accountCountry.toLowerCase() : undefined
                                    )}
                                  />
                                </div>
                              );
                            }
                            return this.renderPhoneNumber();
                          }
                          return null;
                        }}
                      </FormSpy>

                      <FormSpy subscription={{ values: true }}>
                        {(props) => {
                          if (this.isChannelSelected(MFA_EMAIL)(props)) {
                            return (
                              <div className={style.Row}>
                                <EditableForm.Label>
                                  <Message
                                    id="Email address"
                                    comment="Used as the label of the channel data field in the two-step authentication settings page when email is selected as channel type."
                                  />
                                </EditableForm.Label>
                                <Field
                                  name="email"
                                  component={TextField}
                                  placeholder=""
                                  value={position.email}
                                  disabled
                                />
                              </div>
                            );
                          }
                          return null;
                        }}
                      </FormSpy>

                      <Divider />

                      {isEditMode && (
                        <>
                          <h2 className={style.Header}>
                            <Message
                              id="You need to enter your current password to confirm"
                              comment="Used as the title for the password section of the two-step authentication settings page"
                            />
                          </h2>

                          <div className={style.Row}>
                            <EditableForm.Label>
                              <Message
                                id="Password"
                                comment="Used as the label of the relevant field in the two-step authentication settings page"
                              />
                            </EditableForm.Label>
                            <Field
                              name="password"
                              component={PasswordField}
                              value=""
                              placeholder={message({
                                id: 'Enter current password',
                                comment: 'Used as the placeholder of the password field in the two-step authentication settings page',
                              })}
                              // Using the label only for the required validation
                              hideLabel
                              label={message({
                                id: 'Password',
                                comment: 'Used as the label of the relevant field in the two-step authentication settings page',
                              })}
                              required
                              maxLength={72}
                              autoFocus
                            />
                          </div>
                        </>
                      )}

                      {this.renderRevokeTrustedDevices()}
                    </>
                  )}
                </EditableForm.Body>
              </form>
            )}
          />
        )}
      </MakeAsyncFunction>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Security',
    comment: 'Used as the title of the security page',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(SecurityPage);
