// @flow

import { connect } from 'react-redux';
import type { Match } from 'react-router';

import workspacesReducer from 'reducers/entities/workspaces';
import workspaceUsersReducer from 'reducers/entities/workspace-users';
import workspaceGroupsReducer from 'reducers/entities/workspace-groups';

import Workspace from './workspace';

type OwnProps = {
  match: Match,
};

export const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const workspace = workspacesReducer.getWorkspaceSelector(state, { id: ownProps.match.params.id });
  const queryUsersState = workspaceUsersReducer.getQuerySelector(state, { name: `workspace/${ownProps.match.params.id}/relationships` });
  const queryGroupsState = workspaceGroupsReducer.getQuerySelector(state, { name: `workspace/${ownProps.match.params.id}/relationships` });

  return {
    workspace,
    queryUsersState,
    queryGroupsState,
  };
};

export const mapDispatchToProps = (dispatch: Function, ownProps: OwnProps) => ({
  fetchWorkspace: () => {
    dispatch(workspacesReducer.fetchWorkspace({
      id: ownProps.match.params.id,
      params: {
        accessStats: 1,
        includeAgreementStats: 1,
      },
    }));
  },
});

export default connect<any, OwnProps, State, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Workspace);
