import { ReactNode } from 'react';

import style from './action-bar-text.module.scss';

type Props = {
  header: ReactNode,
  text?: ReactNode,
}

export const ActionBarText = ({
  header,
  text,
}: Props) => {
  const renderHeader = () => (
    <h2 className={style.Header}>
      {header}
    </h2>
  );

  const renderBody = () => {
    if (!text) {
      return null;
    }

    return (
      <div className={style.Content}>
        {text}
      </div>
    );
  };

  return (
    <div className={style.ActionBarText}>
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
