import { useSelector } from 'react-redux';

import useAgreement from 'hooks/use-agreement';
import {
  getAgreementMyParticipant,
  getGuestToken,
  usesSameDeviceSome,
  getParticipantsSameDevice,
  usesSameDeviceAll,
} from 'agreement/selectors';
import {
  isDraft,
  isPending,
  isSigned,
  isConcluded,
  isTemplate,
  isOverdue,
  hasDraftApprovalFlow,
  isImportInProgress,
} from 'agreement';
import { getAccountFromSessionSelector } from 'reducers/session';
import hasSigned from 'agreement/participant/has-signed';
// eslint-disable-next-line import/named
import { isViewer } from 'agreement/participant';
import * as participantConstants from 'agreement/participant/constants';
import type { Layout } from 'components/document-layout-container/types';
import { isEmpty } from 'lodash';
import {
  DraftActions,
  DraftApprovalFlowActions,
  GuestActions,
  HasSignedActions,
  OverdueActions,
  PendingActions,
  TemplateActions,
  ImportActions,
} from './actions';
import { SignOnSameDeviceActions } from './actions/buttons-call-to-action';

type Props = {
  agreementId: Oneflow.Agreement['id'],
  layout: Layout
}

const DocumentCallToActions = ({ agreementId, layout }: Props) => {
  const guestToken = useSelector((state) => getGuestToken(state));

  const agreement = useAgreement(agreementId);

  const participant = getAgreementMyParticipant(agreement) ?? {};

  const isGuest = Boolean(guestToken);
  const isAgreementConcluded = isConcluded(agreement);
  const account = useSelector(getAccountFromSessionSelector);
  const someUsingSameDevice = usesSameDeviceSome(agreement, account);
  const undecidedSameDeviceParticipants = getParticipantsSameDevice(agreement)
    .filter((each) => each?.state === participantConstants.STATE_UNDECIDED);
  const isViewerOrNotPartOfContract = (
    isViewer(participant) || Object.keys(participant)?.length === 0
  );
  const allUsingSameDevice = usesSameDeviceAll(agreement, account);

  if (isEmpty(agreement)) {
    return null;
  }

  // Imported document
  if (isImportInProgress(agreement) && !isViewerOrNotPartOfContract) {
    return (<ImportActions agreement={agreement} />);
  }

  // Guest actions
  if (isGuest) {
    return (
      <GuestActions agreementId={agreementId} layout={layout} />
    );
  }

  if (isAgreementConcluded || isGuest) {
    return null;
  }

  if (isTemplate(agreement)) {
    return (
      <TemplateActions agreement={agreement} />
    );
  }

  if (isViewerOrNotPartOfContract) {
    return null;
  }

  const isSameDeviceSignInProgress = someUsingSameDevice
    && (undecidedSameDeviceParticipants.length > 0);
  // User has signed but agreement is not fully signed
  if (hasSigned(participant) && !isSigned(agreement) && !isSameDeviceSignInProgress) {
    return (
      <HasSignedActions agreementId={agreementId} layout={layout} />
    );
  }

  if (hasDraftApprovalFlow(agreement) && isDraft(agreement)) {
    return (
      <DraftApprovalFlowActions
        agreementId={agreementId}
        layout={layout}
      />
    );
  }

  if (isDraft(agreement) && allUsingSameDevice) {
    return <SignOnSameDeviceActions agreement={agreement} layout={layout} />;
  }

  if (isDraft(agreement)) {
    return (
      <DraftActions agreementId={agreementId} layout={layout} />
    );
  }

  if (isPending(agreement)) {
    return (
      <PendingActions agreementId={agreementId} layout={layout} />
    );
  }

  if (isOverdue(agreement)) {
    return (
      <OverdueActions agreementId={agreementId} />
    );
  }

  return null;
};

export default DocumentCallToActions;
