import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';
import type { HTMLAttributes, ReactNode } from 'react';

import Error from 'components/icons/error';

import style from './editable-form.module.scss';

type FormLabelProps = {
  children: ReactNode;
  className?: string;
  htmlFor?: string;
}

export const Label = ({ children, className, htmlFor }: FormLabelProps) => (
  <label htmlFor={htmlFor} className={clsx(style.Label, className)}>
    {children}
  </label>
);

export const Header = ({ children }: { children: ReactNode }) => (
  <div className={style.Header}>{children}</div>
);

export const Body = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={clsx(style.Body, className)} {...props}>{children}</div>
);

export const ErrorMessage = ({
  errorMessage,
  className,
  ...props
}: Omit<HTMLAttributes<HTMLDivElement>, 'children'> & { errorMessage?: ReactNode }) => (
  <div className={clsx(style.ErrorMessage, className)} {...props}>
    <Error className={style.ErrorIcon} />
    {errorMessage || (
      <Message
        id="An error has occurred. Please reload the page and try again."
        comment="Used to show the error message after confirmation."
      />
    )}
  </div>
);

export const RequiredAsterisk = ({ isEditMode }: { isEditMode: boolean }) => {
  if (!isEditMode) {
    return null;
  }

  return <span className={style.RequiredAsterisk}>*</span>;
};
