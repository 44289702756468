import { takeEvery } from 'redux-saga/effects';
import { pageLoadTime, reloadMarker } from 'oneflow-client/core/reload-marker';
import { LOCATION_CHANGE } from 'reducers/router';

export function checkClientReloadMarker() {
  if (reloadMarker > pageLoadTime) {
    window.location.reload();
  }
}

export default function* updateApp() {
  yield takeEvery(LOCATION_CHANGE, checkClientReloadMarker);
}
