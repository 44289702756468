// @flow

import {
  KEY_PAXML,
} from 'extensions';

const Paxml = {
  key: KEY_PAXML,
  typeSpecificConfigKeys: [
    'beloppObErsattning',
  ],
};

export default Paxml;
