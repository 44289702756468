import {
  call,
  select,
  put,
  takeEvery,
  fork,
} from 'redux-saga/effects';
import scriptjs from 'scriptjs';

import sessionStorage from 'utils/session-storage';
import { LOAD_SUPPORT, setSupportLoaded, setSupportIsLoading } from 'reducers/freshworks';
import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import { checkAcl } from 'components/acl';

import 'styles/external/freshchat.scss';

const freshchatToken = 'e1963ae4-ae80-4d4a-a68f-50c4781bb177';

export const loadFreshchatScript = (
  currentLanguage, position, account,
) => new Promise((resolve, reject) => {
  scriptjs('https://wchat.freshchat.com/js/widget.js', () => {
    if (typeof window.FreshworksWidget !== 'function') {
      const n = (...args) => {
        n.q.push(args);
      };
      n.q = [];
      window.FreshworksWidget = n;
    }

    if (window.FreshworksWidget && window.fcWidget) {
      let storageKey = 'freshchatRestoreId';
      if (position && position.id) {
        storageKey = `freshchatRestoreId:${position.id}`;
      }
      const restoreId = sessionStorage.getItem(storageKey);
      const locale = currentLanguage === 'no' ? 'nb' : currentLanguage;
      const hasChatEntitlement = checkAcl(account.acl, 'account:support_chat');
      const hasPhoneEntitlement = checkAcl(account.acl, 'account:support_phone');

      const tags = [];
      if (hasChatEntitlement) {
        tags.push('chat_entitlement');
      }
      if (hasPhoneEntitlement) {
        tags.push('phone_entitlement');
      }

      window.fcWidget.init({
        token: freshchatToken,
        host: 'https://wchat.eu.freshchat.com',
        config: {
          disableNotifications: true,
          headerProperty: {
            hideChatButton: true,
            foregroundColor: '#FFFFFF',
          },
          cssNames: {
            widget: 'FreshchatWidget',
          },
          showFAQOnOpen: false,
        },
        locale,
        externalId: position.id,
        firstName: position.fullname,
        email: position.email,
        tags,
        restoreId,
      });
      window.fcWidget.user.setProperties({
        plan: account?.plan?.name,
        language: currentLanguage,
      });
      window.fcWidget.on('user:created', (resp) => {
        const status = resp && resp.status;
        const data = resp && resp.data;
        if (status === 200) {
          if (data.restoreId) {
            sessionStorage.setItem(storageKey, data.restoreId);
          }
        }
      });
      window.fcWidget.on('widget:loaded', () => resolve(true));
    } else {
      reject();
    }
  });
}).catch(() => {
  throw Error('Freshdesk was not loaded');
});

export function* loadFreshchat() {
  const position = yield select(getPositionFromSessionSelector);
  if (!position) {
    return;
  }

  const currentLanguage = yield select(getCurrentLanguageSelector);
  const account = yield select(getAccountFromSessionSelector);

  yield put(setSupportIsLoading(true));

  try {
    yield call(loadFreshchatScript, currentLanguage, position, account);
    yield put(setSupportLoaded(true));
    yield put(setSupportIsLoading(false));
  } catch (e) {
    // even if the widget is not available, we set it to loaded to open the help center
    yield put(setSupportLoaded(true));
    yield put(setSupportIsLoading(false));
  }
}

export function* loadFreshchatResources() {
  const account = yield select(getAccountFromSessionSelector);
  const isFreshworksAllowed = account?.dataManagement?.freshworks;

  if (!isFreshworksAllowed) return;

  yield fork(loadFreshchat);
}

export default function* freshworks() {
  yield takeEvery(LOAD_SUPPORT, loadFreshchatResources);
}
