// @flow

import * as React from 'react';
import { localize, Message } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import AddMessageTemplateBody from './add-message-template-body';

type FormData = {
  body: string,
  name: string,
  subject: string,
  type: {
    label: string,
    value: string,
  }
};

type Props = {
  onSubmit: FormData => void,
  resetFormState: () => void,
  formState: RpcState,
  children: React.Node,
};

export const AddMessageTemplateFields = ({
  onSubmit,
  resetFormState,
  formState,
  children,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Add message template"
        comment="Modal title for adding a message template"
      />
    )}
    body={(<AddMessageTemplateBody />)}
    onSubmit={onSubmit}
    resetFormState={resetFormState}
    formState={formState}
    modalKey="create message template modal"
  >
    {children}
  </ModalForm>
);

export default localize<Props>(AddMessageTemplateFields);
