// @flow

import { normalize, schema } from 'normalizr';

import {
  get,
  getMultiple,
  post,
  put,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

const messageTemplateSchema = new schema.Entity('messageTemplates');

export type NormalizedMessageTemplates = {
  entities: {
    messageTemplates: {
      [number]: MessageTemplate,
    },
  },
  result: number | Array<number>,
  count: number,
};

type MessageTemplateNormalizer = (any) => NormalizedMessageTemplates;

const normalizeMessageTemplates: MessageTemplateNormalizer = (messageTemplates) => ({
  ...normalize(messageTemplates.collection, [messageTemplateSchema]),
  count: messageTemplates.count,
});

const normalizeMessageTemplate: MessageTemplateNormalizer = (messageTemplate) => ({
  ...normalize(messageTemplate, messageTemplateSchema),
  count: 1,
});

type GetContractMessageTemplates = ({
  contractId: number,
  type: string,
}) => Promise<{
  collection: Array<MessageTemplate>,
}>;

export const getContractMessageTemplates: GetContractMessageTemplates = ({
  contractId,
  type,
}) => (
  get({
    url: `/agreements/${contractId}/message_templates/`,
    params: {
      type,
    },
  })
    .then(extractResponseBodyAsJSON)
);

type GetMessageTemplates = ({
  params: {
    workspaceId?: number,
    positionId?: number,
    type?: string,
  },
  pagination?: {},
}) => Promise<NormalizedMessageTemplates>;

export const getWorkspaceMessageTemplates: GetMessageTemplates = ({
  pagination,
  params: {
    workspaceId,
    type,
  },
}) => (
  getMultiple({
    url: '/message_templates/collections/',
    pagination,
    params: {
      collection_id: workspaceId,
      type,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeMessageTemplates)
);

type CreateMessageTemplate = ({
  workspaceId?: number,
  positionId?: number,
  name: string,
  type: string,
  subject?: string,
  body?: string,
}) => Promise<NormalizedMessageTemplates>;

export const createWorkspaceMessageTemplate: CreateMessageTemplate = ({
  workspaceId,
  name,
  type,
  subject,
  body,
}) => (
  post({
    url: '/message_templates/collections/',
    body: {
      collection_id: workspaceId,
      name,
      type,
      subject,
      body,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeMessageTemplate)
);

type UpdateMessageTemplate = ({
  id: number,
  name?: string,
  type?: string,
  subject?: string,
  body?: string,
}) => Promise<NormalizedMessageTemplates>;

export const updateWorkspaceMessageTemplate: UpdateMessageTemplate = ({
  id,
  name,
  type,
  subject,
  body,
}) => (
  put({
    url: `/message_templates/collections/${id}`,
    body: {
      name,
      type,
      subject,
      body,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeMessageTemplate)
);

type DeleteMessageTemplates = ({
  ids: Array<number>,
}) => Promise<{}>;

export const deleteWorkspaceMessageTemplates: DeleteMessageTemplates = ({ ids }) => (
  remove({
    url: '/message_templates/collections/',
    body: { ids },
  })
    .then(extractResponseBodyAsJSON)
);

export const getPositionMessageTemplates: GetMessageTemplates = ({
  pagination,
  params: {
    positionId,
    type,
  },
}) => (
  getMultiple({
    url: '/message_templates/positions/',
    pagination,
    params: {
      position_id: positionId,
      type,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeMessageTemplates)
);

export const createPositionMessageTemplate: CreateMessageTemplate = ({
  positionId,
  name,
  type,
  subject,
  body,
}) => (
  post({
    url: '/message_templates/positions/',
    body: {
      position_id: positionId,
      name,
      type,
      subject,
      body,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeMessageTemplate)
);

export const updatePositionMessageTemplate: UpdateMessageTemplate = ({
  id,
  name,
  type,
  subject,
  body,
}) => (
  put({
    url: `/message_templates/positions/${id}`,
    body: {
      name,
      type,
      subject,
      body,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeMessageTemplate)
);

export const deletePositionMessageTemplates: DeleteMessageTemplates = ({ ids }) => (
  remove({
    url: '/message_templates/positions/',
    body: { ids },
  })
    .then(extractResponseBodyAsJSON)
);
