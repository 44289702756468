import { fork, all } from 'redux-saga/effects';

import app from 'sagas/app';
import bootup from 'sagas/bootup';
import { refreshAppcues } from 'sagas/third-parties/appcues';
import session from 'sagas/session';
import me from 'sagas/me';
import i18n from 'sagas/i18n';
import router from 'sagas/router';
import currentWorkspace from 'sagas/current-workspace';

import accounts from 'sagas/accounts';
import accountGroups from 'sagas/account-groups';
import accountUsers from 'sagas/account-users';
import agreementCreators from 'sagas/agreement-creators';
import agreements from 'sagas/agreements';
import agreementTemplates from 'sagas/agreement-templates';
import agreementVideo from 'sagas/agreement-video';
import apiTokens from 'sagas/extensions/api-tokens';
import auditLogs from 'sagas/audit-logs';
import billings from 'sagas/billings';
import bulkOperations from 'sagas/bulk-operations';
import contacts from 'sagas/contacts';
import currentContract from 'sagas/current-contract';
import dashboard from 'sagas/dashboard';
import dataFields from 'sagas/data-fields';
import extensions from 'sagas/extensions';
import folders from 'sagas/folders';
import freshworks from 'sagas/third-parties/freshworks';
import groups from 'sagas/groups';
import groupAccounts from 'sagas/group-accounts';
import groupPositions from 'sagas/group-positions';
import groupWorkspaces from 'sagas/group-workspaces';
import libraryTemplates from 'sagas/library-templates';
import permissions from 'sagas/permissions';
import plans from 'sagas/plans';
import positionGroups from 'sagas/position-groups';
import positionMessageTemplates from 'sagas/position-message-templates';
import positionMfa from 'sagas/position-mfa';
import positions from 'sagas/positions';
import roles from 'sagas/roles';
import statistics from 'sagas/statistics';
import tagConnections from 'sagas/tag-connections';
import tags from 'sagas/tags';
import templateGroups from 'sagas/extensions/template-groups';
import updateApp from 'sagas/update-app';
import userAccounts from 'sagas/user-accounts';
import userWorkspaces from 'sagas/user-workspaces';
import webhooks from 'sagas/extensions/webhooks';
import workspaceGroups from 'sagas/workspace-groups';
import workspaceMessageTemplates from 'sagas/workspace-message-templates';
import workspaces from 'sagas/workspaces';
import workspaceUsers from 'sagas/workspace-users';

export function createRootSaga({ isRTLEnv = false } = {}) {
  return function* root() {
    yield all([
      // app
      fork(app),
      fork(i18n),
      fork(router),
      fork(refreshAppcues),
      fork(session),
      fork(me),
      fork(currentWorkspace),
      fork(updateApp),

      // entities
      fork(accounts),
      fork(accountGroups),
      fork(accountUsers),
      fork(agreementCreators),
      fork(agreements),
      fork(agreementTemplates),
      fork(agreementVideo),
      fork(apiTokens),
      fork(auditLogs),
      fork(billings),
      fork(bulkOperations),
      fork(contacts),
      fork(dashboard),
      fork(dataFields),
      fork(extensions),
      fork(folders),
      fork(groups),
      fork(groupAccounts),
      fork(groupPositions),
      fork(groupWorkspaces),
      fork(libraryTemplates),
      fork(permissions),
      fork(plans),
      fork(positionGroups),
      fork(positionMessageTemplates),
      fork(positionMfa),
      fork(positions),
      fork(roles),
      fork(statistics),
      fork(tagConnections),
      fork(tags),
      fork(templateGroups),
      fork(userAccounts),
      fork(userWorkspaces),
      fork(webhooks),
      fork(workspaceGroups),
      fork(workspaceMessageTemplates),
      fork(workspaces),
      fork(workspaceUsers),

      fork(freshworks),

      // contract view sagas
      fork(currentContract),
    ]);

    if (!isRTLEnv) {
      yield fork(bootup);
    }
  };
}
