import { useSelector } from 'react-redux';
import useAgreement from 'hooks/use-agreement';

import { getPartyByParticipantId, isIndividual } from 'agreement/party';
import { getCurrentContractId } from 'reducers/current-contract';
import { getAgreementMyParticipant } from 'agreement/selectors';
import hasSigned from 'agreement/participant/has-signed';

import Button from 'components/button';
import Message from 'components/message';
import { DelegatePendingApprovalRightsModal } from 'components/modals/delegate-pending-approval-rights';

import style from './more-options-tab.module.scss';

export const DelegatePendingApprovalRights = () => {
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const participant = getAgreementMyParticipant(agreement);
  const participantId = participant?.id;
  const party = getPartyByParticipantId(agreement, participantId);

  if (isIndividual(party)) {
    return null;
  }

  const getDelegateApprovalButton = (onClick: React.MouseEventHandler<HTMLButtonElement>) => (
    <Button
      kind="wide"
      color="grey"
      onClick={onClick}
      disabled={hasSigned(participant)}
      customClass={style.ButtonMargin}
    >
      <Message
        id="Delegate approval"
        comment="Button text for counterparties who want to delegate the approval of a document to someone else."
      />
    </Button>
  );

  return (
    <DelegatePendingApprovalRightsModal agreement={agreement}>
      {getDelegateApprovalButton}
    </DelegatePendingApprovalRightsModal>
  );
};
