import { useEffect, useState } from 'react';

const usePagination = (totalPages: number) => {
  const [currentPage, setCurrentPage] = useState(0);

  const lastPage = totalPages - 1;

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, lastPage));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const canGoNext = currentPage < lastPage;
  const canGoPrev = currentPage > 0;

  // Maybe it would be better to use a callback instead of useEffect
  useEffect(() => {
    setCurrentPage(lastPage);
  }, [lastPage]);

  return {
    currentPage,
    handleNextPage,
    handlePrevPage,
    canGoNext,
    canGoPrev,
    totalPages,
  };
};

export default usePagination;
