// @flow

import {
  KEY_HUBSPOT,
} from 'extensions';

const Hubspot = {
  key: KEY_HUBSPOT,
};

export default Hubspot;
