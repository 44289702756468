import { useHistory, useRouteMatch } from 'react-router';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button/button-fc';
import EyeOutlineIcon from 'components/icons/eye-outline';
import Tooltip from 'components/tooltip';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import useAgreement from 'hooks/use-agreement';
import { isDraft, isPending, isTemplate } from 'agreement';
import styles from './preview-button.module.scss';

type Props = {
  agreementId: Oneflow.Document['id'];
};

const getId = (document: Oneflow.Document) => {
  if (isTemplate(document)) {
    return 'preview-template';
  } if (isDraft(document)) {
    return 'preview-draft';
  } if (isPending(document)) {
    return 'preview-pending';
  }

  return null;
};

const PreviewButton = ({ agreementId }: Props) => {
  const document = useAgreement(agreementId);
  const history = useHistory();
  const isInPreviewMode = useRouteMatch([`/documents/${agreementId}/preview`, `/documents/templates/${agreementId}/preview`]);

  const onClickHandler = () => {
    if (isInPreviewMode) {
      const path = history.location.pathname.split('/preview')[0];
      history.push(path);
    } else {
      amplitudeLogEvent(
        'Go To Preview',
        {},
        { 'document id': agreementId },
      );
      history.push(`${history.location.pathname}/preview`);
    }
  };

  if (isInPreviewMode) {
    return (
      <Button
        customClass={styles.PreviewButton}
        outline
        onClick={onClickHandler}
      >
        <Message id="Exit preview" comment="Button label for preview" />
      </Button>
    );
  }

  return (
    <Tooltip
      message={(
        <Message
          id="View as counterparty"
          comment="Tool tip message for preview button"
        />
      )}
      theme="oneflow"
      side="bottom"
      sideOffset={4}
    >
      <Button
        customClass={styles.PreviewButton}
        outline
        onClick={onClickHandler}
        icon={<EyeOutlineIcon height={20} />}
        id={getId(document)}
      >

        <Message id="Preview" comment="Button label for preview" />
      </Button>
    </Tooltip>
  );
};

export default PreviewButton;
