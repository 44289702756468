/* eslint-disable import/named */
import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { useSlate } from 'slate-react';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { hasAnnotationInSelection } from 'components/contract-text-editor/editor-plugins/annotation-plugin';
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from 'components/popover';
import LinkEditForm from 'components/rich-text-editor-toolbars/toolbar-buttons/link-button/link-edit-form';
import LinkInformation from 'components/rich-text-editor-toolbars/toolbar-buttons/link-button/link-popover/link-information';

import style from './link-popover.module.scss';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  element: any,
  linkType: 'url' | 'email';
  setIsOpen: (isOpen: boolean) => void;
};

const LinkPopover = ({
  children,
  isOpen,
  setIsOpen,
  element,
  linkType,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const focusEditor = useFocusEditor();
  const editor = useSlate();

  const onClosePopover = () => {
    focusEditor();
    setIsOpen(false);
    setIsEditing(false);
  };

  return (
    <Popover open={isOpen}>
      <PopoverTrigger asChild>
        {children}
      </PopoverTrigger>
      <PopoverPortal container={document.body}>
        <div className={style.PopoverContainer}>
          <PopoverContent
            side={hasAnnotationInSelection(editor, editor.selection) ? 'top' : 'bottom'}
            sideOffset={6}
            onOpenAutoFocus={(event) => {
              event.preventDefault();
            }}
            onEscapeKeyDown={onClosePopover}
            onInteractOutside={onClosePopover}
            className={clsx(style.Popover, 'rich-text-region')}
            collisionBoundary={document.getElementById('agreement-body')}
            arrowHeight={0}
          >
            <LinkInformation
              visible={!isEditing}
              existingLink={element.url}
              linkType={linkType}
              onEditClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
              }}
            />
            <LinkEditForm
              type={linkType}
              visible={isEditing}
              elementUrl={element.url}
              onClose={() => setIsEditing(false)}
            />
          </PopoverContent>
        </div>
      </PopoverPortal>
    </Popover>
  );
};

export default LinkPopover;
