import getFontAssetsDomain from './get-font-asset-domain.js';

// All proxima-nova files are in .otf-format!
const loadProximaNova = () => {
  const normalProximaNova = new FontFace(
    'Proxima Nova',
    `url(${getFontAssetsDomain()}/resources/fonts/proxima-nova/proxima-nova_400.otf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicProximaNova = new FontFace(
    'Proxima Nova',
    `url(${getFontAssetsDomain()}/resources/fonts/proxima-nova/proxima-nova_400_italic.otf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldProximaNova = new FontFace(
    'Proxima Nova',
    `url(${getFontAssetsDomain()}/resources/fonts/proxima-nova/proxima-nova_700.otf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicProximaNova = new FontFace(
    'Proxima Nova',
    `url(${getFontAssetsDomain()}/resources/fonts/proxima-nova/proxima-nova_700_italic.otf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalProximaNova, italicProximaNova, boldProximaNova, boldItalicProximaNova];
};

export default loadProximaNova;
