/* eslint-disable import/named */
import type { MessageTranslator } from '@oneflowab/pomes';
import { getRef, getEventOwner } from 'agreement/event';
import { getParticipantName } from '../helpers';

const validate2FA = (
  event: Oneflow.AgreementEvent,
  agreement: Oneflow.Agreement,
  message: MessageTranslator,
) => {
  const owner = getEventOwner(agreement, event);
  const mfaType = getRef(event, 'mfaType');

  const mfaTypes = {
    personal_identification: message({
      id: 'Personal Identification',
      comment: 'Personal Identification MFA type used to validate the participant identity.',
    }),
    sms: 'SMS',
    email: message({
      id: 'Email',
      comment: 'Email MFA type used to validate the participant identity via e-mail.',
    }),
  };

  return {
    text: message({
      id: '{name} verified their identity using {mfa}.',
      values: { name: getParticipantName(owner), mfa: mfaTypes[mfaType] },
      comment: 'Audit trail message',
    }),
    icon: 'line-icon message',
    byLine: false,
  };
};

export default validate2FA;
