// @flow
import { type Node } from 'react';
import { useSlate, useFocused } from 'slate-react';

import useFocusedEditor from 'hooks/rich-text-editor/use-focused-editor';

type Props = {
  renderToolbar: any => Node,
};

const EditorToolbar = ({ renderToolbar }: Props) => {
  const focused = useFocused();
  const editor = useSlate();
  const focusedEditor = useFocusedEditor();

  if (!focused && editor !== focusedEditor) {
    return null;
  }

  return renderToolbar();
};

export default EditorToolbar;
