import { useMemo } from 'react';

import { getPDFContractURL } from 'utils/download-pdf-link';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import { useSelector } from 'react-redux';
import { checkAcl } from 'components/acl';
import { isInProgress } from 'agreement';
import { getPositionFromSessionSelector } from 'reducers/session';
import { getGuestToken } from 'agreement/selectors';

// eslint-disable-next-line import/named
import { DownloadPdfMenuItem } from 'components/menu-items/download-pdf';

type Props = {
  agreement: Oneflow.Agreement,
  amplitudeScope: string,
};

const DownloadPdfComponent = ({
  agreement,
  amplitudeScope,
}: Props) => {
  const guestToken = useSelector(getGuestToken);
  const position = useSelector(getPositionFromSessionSelector);
  const { id } = position as Oneflow.Position;
  const isOwner = id === agreement.owner?.id;

  const link = useMemo(
    () => getPDFContractURL(agreement, position, guestToken),
    [agreement, position, guestToken],
  );
  const disabled = isInProgress(agreement) || !checkAcl(agreement.acl, 'agreement:download:pdf');

  const handleClick = () => {
    let participantType = 'guest';

    if (!guestToken) {
      participantType = isOwner ? 'owner' : 'colleague';
    }

    amplitudeLogEvent(
      'Download pdf',
      {
        location: amplitudeScope,
        'participant type': participantType,
      },
      { 'document id': agreement.id },
    );
  };

  return (<DownloadPdfMenuItem href={link} disabled={disabled} onClick={handleClick} />);
};

export default DownloadPdfComponent;
