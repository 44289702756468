import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import { getCurrentContractId } from 'reducers/current-contract';
import { isAgreementOwner } from 'agreement/selectors';
import { getAccountFromSessionSelector } from 'reducers/session';

import { isImport as isAgreementImport } from 'agreement';

import { ScrollableArea } from 'components/document-tabs/components/scrollable-area';
import { TabHeader } from 'components/document-tabs/components/tab-header';
import ParticipantList from 'components/participant-list';
import { AdditionalOptions } from 'components/additional-options';
import AgreementVideo from 'components/agreement-video';
import CloseSidebarButton from 'components/expanded-layout-sidebar/close-sidebar-button';
import style from './parties-tab.module.scss';

type Props = {
  onClose: () => void;
};

const PartiesTab = ({ onClose }: Props) => {
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const account = useSelector(getAccountFromSessionSelector);
  const isOwner = isAgreementOwner(account, agreement);
  const isImport = isAgreementImport(agreement);

  const renderAdditionalOptions = () => {
    if (!isOwner || isImport) {
      return null;
    }

    return (
      <AdditionalOptions agreementId={agreementId} />
    );
  };

  const renderAgreementVideo = () => {
    if (isImport) {
      return null;
    }
    return <AgreementVideo agreementId={agreementId} />;
  };

  const renderGuestViewActions = () => {
    if (isOwner) {
      return null;
    }
    return (
      <>
        {renderAgreementVideo()}
      </>
    );
  };

  return (
    <>
      <TabHeader>
        <h2 className={style.ParticipantsHeader}>
          <Message
            id="Participants"
            comment="Label for participants title under participants tab in contract sidebar"
          />
        </h2>
        {onClose && <CloseSidebarButton onClose={onClose} />}
      </TabHeader>
      <ScrollableArea>
        <ParticipantList agreementId={agreementId} />
        {renderAdditionalOptions()}
        {renderGuestViewActions()}
      </ScrollableArea>
    </>
  );
};

export default PartiesTab;
