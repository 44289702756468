import clsx from 'clsx';
import { forwardRef } from 'react';
import { Item as RadixItem, Content as RadixContent } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuContentProps, DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import style from './dropdown-menu.module.scss';

export const Item = ({
  children, className, onSelect, ...props
}: DropdownMenuItemProps) => (
  <RadixItem className={clsx(style.DropdownItem, className)} onSelect={onSelect} {...props}>
    {children}
  </RadixItem>
);

type ContentProps = DropdownMenuContentProps

export const Content = forwardRef<HTMLDivElement, ContentProps>(({
  children, className, ...props
}, ref) => (
  <RadixContent
    className={clsx(style.DropdownContent, className)}
    {...props}
    ref={ref}
  >
    {children}
  </RadixContent>
));

Content.displayName = 'Content';

export * from '@radix-ui/react-dropdown-menu';
