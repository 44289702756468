// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useSlate } from 'slate-react';
import { useRichTextProps } from 'contexts/rich-text';
import { getActiveTableCell } from 'components/rich-text-editor/editor-plugins/table-utils';

import TableColumnResizer from './table-column-resizer';
import { getStyle } from '../../utils';
import tableCellStyle from './table-cell.module.scss';

const EditableTableCell = ({ element, attributes, children }: RenderSlateElementProps) => {
  const style = getStyle(element);
  const editor = useSlate();
  const activeTableCell = getActiveTableCell(editor);

  const className = clsx(tableCellStyle.EditableTableCell, {
    [tableCellStyle.FocusedTableCell]: activeTableCell === element,
  });

  return (
    <td
      {...attributes}
      className={className}
      style={style}
      colSpan={element.colSpan}
      rowSpan={element.rowSpan}
    >
      <TableColumnResizer tableCellElement={element} />
      {children}
    </td>
  );
};

const TableCell = ({ attributes, children, element }: RenderSlateElementProps) => {
  const { isReadOnly } = useRichTextProps();
  const style = getStyle(element);

  if (!isReadOnly) {
    return (
      <EditableTableCell
        attributes={attributes}
        element={element}
      >
        {children}
      </EditableTableCell>
    );
  }

  return (
    <td
      {...attributes}
      style={style}
      colSpan={element.colSpan}
      rowSpan={element.rowSpan}
    >
      {children}
    </td>
  );
};

export default TableCell;
