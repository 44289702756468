// @flow

import React from 'react';
import {
  isEmpty,
  omit,
} from 'lodash';

import { getAgreementDateFormat } from 'date';
import { getMyParticipantWhenUpdater } from 'agreement/selectors';
import { isBoxDataEditAllowed } from 'agreement/box-data-edit-permissions';
import { isTemplate } from 'agreement';
import {
  TYPE_NO_DURATION,
  TYPE_NOT_SET,
  TYPE_RECURRING,
} from 'agreement/constants';
import useAgreement from 'hooks/use-agreement';
import useCurrentBox from 'hooks/use-current-box';
import useCurrentBoxId from 'hooks/use-current-box-id';

export const DurationBoxPropsContext = React.createContext<any>();

type Props = {|
  agreementId: number,
  boxId: number,
  children: React.Node,
  isEditable: boolean,
|};

const mapDurationContentAgreement = (contentAgreement) => {
  if (!contentAgreement) {
    return {};
  }

  let durationData = { ...contentAgreement };

  if (durationData.type === TYPE_NOT_SET) {
    durationData = omit(durationData, ['startDate', 'endDate']);
  }

  if (durationData.type === TYPE_NO_DURATION) {
    durationData = omit(durationData, ['endDate']);
  }

  if (durationData.type !== TYPE_RECURRING) {
    durationData = omit(durationData, ['initialDuration']);
  }

  return durationData;
};

export function DurationBoxPropsProvider({
  agreementId,
  boxId,
  children,
  isEditable,
}: Props) {
  const agreement = useAgreement(agreementId);
  const currentBoxId = useCurrentBoxId(boxId);
  const box = useCurrentBox(currentBoxId);
  const dateFormat = !isEmpty(agreement) ? getAgreementDateFormat(agreement.config.dateFormat) : '';
  const isAllowedToEditBoxData = isBoxDataEditAllowed(box);
  const myParticipant = !isEmpty(agreement) ? getMyParticipantWhenUpdater(agreement) : {};
  const isViewer = (isEmpty(myParticipant) && !isTemplate(agreement)) || !isEditable;

  const {
    type,
    duration,
    startDate,
    endDate,
    noticePeriod,
    initialDuration,
  } = mapDurationContentAgreement(box?.content?.agreement);

  const contextValue = React.useMemo(() => ({
    box,
    boxId: currentBoxId,
    dateFormat,
    duration,
    endDate,
    initialDuration,
    isAllowedToEditBoxData,
    isEditable,
    isViewer,
    noticePeriod,
    startDate,
    type,
  }), [
    box,
    currentBoxId,
    dateFormat,
    duration,
    endDate,
    initialDuration,
    isAllowedToEditBoxData,
    isEditable,
    isViewer,
    noticePeriod,
    startDate,
    type,
  ]);

  return (
    <DurationBoxPropsContext.Provider value={contextValue}>
      {children}
    </DurationBoxPropsContext.Provider>
  );
}

export const useDurationBoxProps = () => {
  const contextValue = React.useContext(DurationBoxPropsContext);

  if (!contextValue) {
    throw new Error('useDurationBoxProps should be used inside a DurationBoxPropsContext');
  }

  return contextValue;
};
