// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';
import Dropzone from 'react-dropzone';

import VideoFileUpload from 'components/icons/video-file-upload';
import Button from 'components/button';
import head from 'lodash/head';
import { FILE_VIDEO_DROPZONE_ACCEPT } from 'utils/file';

import style from './recorder-controls.module.scss';
import { MAX_VIDEO_SIZE } from '../../contract-boxes/video-box/constants';

type Props = {|
  onReady: (Blob) => void,
|};

class MobileRecorderControls extends React.Component<Props> {
  onVideoSelect = (acceptedFiles: Array<File>) => {
    const { onReady } = this.props;
    const video = head(acceptedFiles);

    if (video) {
      onReady(video);
    }

    return null;
  };

  renderUploadVideoButton = () => (
    <Button
      customClass={style.RecorderControl}
      icon={VideoFileUpload}
    >
      <Message
        id="Upload a video"
        comment="Select a video file from the user's computer"
      />
    </Button>
  )

  getDropzoneClasses = (isDragActive, isDragReject) => clsx(style.Dropzone, {
    [style.DropzoneActive]: isDragActive,
    [style.DropzoneReject]: isDragReject,
  });

  render() {
    return (
      <div className={clsx(style.RecorderControls, style.MobileControls)}>
        <Dropzone
          multiple={false}
          maxSize={MAX_VIDEO_SIZE}
          onDrop={this.onVideoSelect}
          accept={FILE_VIDEO_DROPZONE_ACCEPT}
        >
          {(
            {
              getRootProps, getInputProps, isDragReject, isDragActive,
            },
          ) => (
            <section>
              <div {
                ...getRootProps({ className: this.getDropzoneClasses(isDragActive, isDragReject) })
              }
              >
                <input {...getInputProps()} />
                {this.renderUploadVideoButton()}
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default MobileRecorderControls;
