import { Message } from '@oneflowab/pomes';

import { canBeMarkedAsSignedByMyParticipant } from 'agreement';
import useAgreement from 'hooks/use-agreement';

import Button from 'components/button';
import { MarkAsSigned } from 'components/contract-actions/mark-as-signed';
import toast from 'components/toasts';

import style from './mark-as-signed-action.module.scss';

export type Props = {
  agreementId: Oneflow.Document['id'],
  disabled?: boolean,
};

const MarkAsSignedAction = ({
  agreementId,
  disabled = false,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const canMarkAsSigned = canBeMarkedAsSignedByMyParticipant(agreement);

  const renderButton = (onClick: () => void) => {
    const onClickHandler = () => {
      if (!canMarkAsSigned) {
        toast.warning({
          id: 'imported-document-mark-as-signed-warning',
          title: (
            <Message id="Missing signatory" comment="Title of toast explaining why document cannot be marked as signed" />
          ),
          description: (
            <Message
              id="To mark this contract as signed, at least one participant must be a signatory."
              comment="Description of toast explaining why document cannot be marked as signed"
            />
          ),
          duration: 5000,
        });

        return;
      }

      onClick();
    };

    return (
      <Button
        customClass={style.MarkAsSignedButton}
        onClick={onClickHandler}
        kind="document-sign"
        disabled={disabled}
      >
        <Message
          id="Mark as signed"
          comment="Label for Mark as signed contract button in contract view."
        />
      </Button>
    );
  };

  return (
    <MarkAsSigned
      agreementId={agreementId}
    >
      {renderButton}
    </MarkAsSigned>
  );
};

export default MarkAsSignedAction;
