/* eslint-disable no-param-reassign */
/* eslint-disable no-cond-assign */
const applyPolyfill = () => {
  if (!Node.prototype.contains) {
    // eslint-disable-next-line func-names
    const nodeContains = function (node) {
      if (!node) {
        throw new TypeError('1 argument is required');
      }

      // eslint-disable-next-line no-cond-assign
      do {
        if (this === node) {
          return true;
        }
      }
      while ((node = node && node.parentNode));

      return false;
    };

    Object.defineProperty(Node.prototype, 'contains', {
      configurable: true,
      enumerable: true,
      value: nodeContains,
      writable: true,
    });

    return nodeContains;
  }

  return undefined;
};

applyPolyfill();

export default applyPolyfill;
