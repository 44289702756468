import Svg from 'components/svg';

const TimeReset3Clock = Svg((
  <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_13294_13255)" style={{ color: 'transparent' }}>
    <path d="M7 3.76923V7H9.30769" stroke="#013A4C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5401 4.69231C11.636 2.52405 9.49595 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3138 3.68629 13 7 13C9.83717 13 12.2144 11.0308 12.8395 8.38462" stroke="#013A4C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 2.38464V4.69234H10.6923" stroke="#013A4C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </g>
), { viewBox: '0 0 14 14' });

TimeReset3Clock.displayName = 'TimeReset3Clock';

export default TimeReset3Clock;
