import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import type { ChangeEventHandler } from 'react';
import { escape, unescape } from 'lodash';

type ValueElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

function useControlledEscapedInput<T extends ValueElement>(
  escapedValue: string,
  handleEscapedValueChange: (newFieldValue: string) => void,
): {
  displayValue: string;
  onChange: ChangeEventHandler<T>,
} {
  const [displayValue, setDisplayValue] = useState<string>(unescape(escapedValue));
  const onChange: ChangeEventHandler<T> = useCallback((event) => {
    setDisplayValue(event.target.value);

    // Escape the data only when it is saved in redux
    handleEscapedValueChange(escape(event.target.value));
  }, [handleEscapedValueChange]);

  // Update the visible data when the value is updated outside the component
  useEffect(() => {
    setDisplayValue(unescape(escapedValue));
  }, [escapedValue]);

  return {
    displayValue,
    onChange,
  };
}

export default useControlledEscapedInput;
