// @flow

import * as React from 'react';

import ControlCameraOff from 'components/icons/control-camera-off';

import Video from './video';

import style from './video-feedback.module.scss';

type Props = {
  src: MediaStream,
};

type State = {
  muted: boolean,
  ended: boolean,
};

const isMuted = (mediaStream: MediaStream) => (
  mediaStream.getVideoTracks().every((track) => track.muted)
);

const isEnabled = (mediaStream: MediaStream) => (
  mediaStream.getVideoTracks().every((track) => track.enabled)
);

const isEnded = (mediaStream: MediaStream) => (
  mediaStream.getVideoTracks().every((track) => track.readyState === 'ended')
);

class VideoFeedback extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { src } = this.props;

    this.state = {
      muted: isMuted(src),
      ended: isEnded(src),
    };
  }

  componentDidMount() {
    const { src } = this.props;

    src.getVideoTracks().forEach((track) => {
      track.addEventListener('mute', this.setMuted);
      track.addEventListener('unmute', this.setUnmuted);
      track.addEventListener('ended', this.setEnded);
    });
  }

  componentWillUnmount() {
    const { src } = this.props;

    src.getVideoTracks().forEach((track) => {
      track.removeEventListener('mute', this.setMuted);
      track.removeEventListener('unmute', this.setUnmuted);
      track.removeEventListener('ended', this.setEnded);
    });
  }

  setMuted = () => {
    this.setState({
      muted: true,
    });
  }

  setUnmuted = () => {
    this.setState({
      muted: false,
    });
  }

  setEnded = () => {
    this.setState({
      ended: true,
    });
  }

  render() {
    const { src } = this.props;
    const { muted, ended } = this.state;

    if (!isEnabled(src) || muted || ended) {
      return (
        <div className={style.VideoFeedback}>
          <ControlCameraOff className={style.CameraOffIcon} />
        </div>
      );
    }

    return <Video src={src} />;
  }
}

export default VideoFeedback;
