import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { getAgreementMyParticipant, getGuestToken } from 'agreement/selectors';
import {
  isChatEnabled,
  isCommentsAvailable,
  isCommentsEnabled,
} from 'comments';
import { isTemplate } from 'agreement';
import useCurrentMessages from 'hooks/use-current-messages';

import { MESSAGE_TYPES } from 'comments/constants';
import Message from 'components/message';

import style from './disabled-state.module.scss';

export const getMessagesDisabledText = (chatEnabled: boolean, myParticipant: object) => {
  if (isEmpty(myParticipant)) {
    return (
      <Message
        id="To add a message, you need to be a participant."
        comment="Message in the sidebar comments tab when adding comments is unavailable"
      />
    );
  }

  if (!chatEnabled) {
    return (
      <Message
        id="Chat is disabled. Existing messages won't be visible to your counterparties."
        comment="Message in the sidebar comments tab when comments are turned off"
      />
    );
  }

  return (
    <Message
      id="Adding messages is disabled."
      comment="Message in the sidebar comments tab when comments are turned off"
    />
  );
};

export const getCommentsDisabledText = (inlineCommentsEnabled: boolean, myParticipant: object) => {
  if (!inlineCommentsEnabled) {
    return (
      <Message
        id="Comments are disabled. Existing messages won't be visible to your counterparties."
        comment="Message in the sidebar comments tab when comments are turned off"
      />
    );
  }

  if (isEmpty(myParticipant)) {
    return (
      <Message
        id="To add a comment, you need to be a participant."
        comment="Message in the sidebar comments tab when adding comments is unavailable"
      />
    );
  }

  return (
    <Message
      id="Adding comments is disabled."
      comment="Message in the sidebar comments tab when comments are turned off"
    />
  );
};

export const commentsGatedText = (
  <Message
    id="Adding new comments is not available for your current plan. Existing comments won't be visible to your counterparties."
    comment="Message in the sidebar comments tab when comments are available but gating doesn't allow users to use this feature based on their current plan."
  />
);

export const commentsGatedGuestText = (
  <Message
    id="Adding new comments is no longer available for this document."
    comment="Message in the comments tab when comments are available but gating doesn't allow gusts to use this feature."
  />
);

type Props = {
  agreement: Oneflow.Agreement,
  tab: string,
}

const DisabledState = ({
  agreement,
  tab,
}: Props) => {
  const { comments } = useCurrentMessages();
  const myParticipant = getAgreementMyParticipant(agreement);
  const chatEnabled = isChatEnabled(agreement, myParticipant);
  const guestToken = useSelector(getGuestToken);
  const inlineCommentsEnabled = isCommentsEnabled(agreement);
  const isGuest = Boolean(guestToken);
  const isTabEnabled = (tab === MESSAGE_TYPES.CHAT && chatEnabled)
    || (tab === MESSAGE_TYPES.COMMENTS && inlineCommentsEnabled);
  const isCommentsGated = !isEmpty(comments)
    && !isCommentsAvailable(agreement)
    && !isTemplate(agreement)
    && !isEmpty(myParticipant);

  if (tab === MESSAGE_TYPES.COMMENTS && isCommentsGated) {
    if (isGuest) {
      return (
        <span className={style.DisabledState}>
          {commentsGatedGuestText}
        </span>
      );
    }

    return (
      <span className={style.DisabledState}>
        {commentsGatedText}
      </span>
    );
  }

  if (isTabEnabled || isTemplate(agreement)) {
    return null;
  }

  if (tab === MESSAGE_TYPES.CHAT) {
    return (
      <span className={style.DisabledState}>
        {getMessagesDisabledText(chatEnabled, myParticipant || {})}
      </span>
    );
  }

  return (
    <span className={style.DisabledState}>
      {getCommentsDisabledText(inlineCommentsEnabled, myParticipant || {})}
    </span>
  );
};

export default DisabledState;
