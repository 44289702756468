import {
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import { composeRefs } from 'hooks/compose-refs';

import { useFilterableContext } from './contexts/filterable';
import { SlottableWithNestedChildren } from './helpers';
import type { Children, DivProps } from './types';

type Props = Children &
  DivProps & {
    /**
     * Accessible label for this List of suggestions. Not shown visibly.
     */
    label?: string
  }

/**
 * Contains `Item`, `Group`, and `Separator`.
 * Use the `--cmdk-list-height` CSS variable to animate height based on the number of results.
 */
const List = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children: _, label = 'Suggestions', ...etc } = props;
  const ref = useRef<HTMLDivElement>(null);
  const height = useRef<HTMLDivElement>(null);
  const context = useFilterableContext();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (height.current && ref.current) {
      const el = height.current;
      const wrapper = ref.current;
      let animationFrame: number | null = null;
      const observer = new ResizeObserver(() => {
        animationFrame = requestAnimationFrame(() => {
          const _height = el.offsetHeight;
          wrapper.style.setProperty('--cmdk-list-height', `${_height.toFixed(1)}px`);
        });
      });
      observer.observe(el);
      return () => {
        if (animationFrame) {
          cancelAnimationFrame(animationFrame);
          observer.unobserve(el);
        }
      };
    }
  }, []);

  return (
    <div
      ref={composeRefs(ref, forwardedRef)}
      {...etc}
      // eslint-disable-next-line react/no-unknown-property
      cmdk-list=""
      role="listbox"
      aria-label={label}
      id={context.listId}
    >
      {SlottableWithNestedChildren(props, (child) => (
        // eslint-disable-next-line react/no-unknown-property
        <div ref={composeRefs(height, context.listInnerRef)} cmdk-list-sizer="">
          {child}
        </div>
      ))}
    </div>
  );
});

List.displayName = 'List';

export default List;
