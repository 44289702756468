// Account and account related
import Account from 'data-validators/entity-schemas/account';
import Workspace from 'data-validators/entity-schemas/workspace';

// Contract and contract related
import Contract, { contractConfigSchema } from 'data-validators/entity-schemas/contract';
import Party from 'data-validators/entity-schemas/party';
import Participant from 'data-validators/entity-schemas/participant';

import { getDataSchema } from 'data-validators/utils';

import requiredSchemaSpecification from './required-schema-specification';

const contractSchemasMapper = {
  contract: {
    schema: Contract,
    type: 'model',
    //  In isolation subSchemas doesn't mean anything and give
    // More info about the schema (eg., contract.config)
    subSchemas: {
      config: {
        schema: contractConfigSchema,
        type: 'object',
      },
    },
    relations: {
      account: {
        schema: Account,
        type: 'model',
      },
      // Oneflow workspace
      collection: {
        schema: Workspace,
        type: 'model',
      },
      parties: {
        schema: Party,
        type: 'collection',
        relations: {
          participants: {
            type: 'collection',
            schema: Participant,
            relations: {
              account: {
                type: 'model',
                schema: Account,
              },
            },
          },
        },
      },
    },
  },
};

const contractValidationSchema = getDataSchema(requiredSchemaSpecification, contractSchemasMapper);

export default contractValidationSchema;
