// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import ShowTableBordersIcon from 'components/icons/show-table-borders';
import HideTableBordersIcon from 'components/icons/hide-table-borders';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import toggleTableBorders, { isTableBordersHidden } from 'components/rich-text-editor/editor-plugins/table-utils/toggle-table-borders';
import ToolbarButton from '../toolbar-button';

const ToggleTableBorders = () => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();
  const isBordersHidden = isTableBordersHidden(editor);

  return (
    <ToolbarButton
      label={isBordersHidden ? (
        <Message
          id="Show table borders"
          comment="The label of the editor's toolbar plugin that shows table borders."
        />
      ) : (
        <Message
          id="Hide table borders"
          comment="The label of the editor's toolbar plugin that hides table borders."
        />
      )}
      isMenuItem={false}
      onTrigger={() => {
        focusEditor();
        toggleTableBorders(editor);
      }}
      icon={isBordersHidden ? <HideTableBordersIcon /> : <ShowTableBordersIcon />}
    />
  );
};

export default ToggleTableBorders;
