// @flow

import { post, extractResponseBodyAsJSON } from 'oneflow-client/core';
import { decamelizeKeys } from 'humps';

type SetupWithGeneric = ({ extensionId: number }) => Promise<{}>;

// eslint-disable-next-line import/prefer-default-export
export const setupWithGeneric: SetupWithGeneric = ({ extensionId }) => (
  post({
    url: '/ext/generic/setup_url',
    body: decamelizeKeys({
      extensionId,
    }, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
);
