import { useMemo } from 'react';
import type { CSSProperties } from 'react';
import clsx from 'clsx';

import Button from 'components/button';

import style from './color-bubble.module.scss';

type Props = {
  color: string,
  onClick?: () => void,
  disabled?: boolean,
};

const ColorBubble = ({
  color,
  onClick,
  disabled,
}: Props) => {
  const border = useMemo(() => (
    color === '#ffffff' ? `0.5px solid ${style.bordergreen}` : 'none'
  ), [color]);

  const bubbleStyle = useMemo<CSSProperties>(() => ({
    backgroundColor: color,
    border,
  }), [border, color]);

  if (onClick) {
    return (
      <Button
        type="submit"
        className={style.ColorBubble}
        style={bubbleStyle}
        onClick={onClick}
      />
    );
  }

  return (
    <div
      className={clsx(style.ColorBubble, {
        [style.Disabled]: disabled,
      })}
      style={bubbleStyle}
    />
  );
};

export default ColorBubble;
