/* eslint-disable import/named */
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { localize } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import Message from 'components/message';
import type { MessageTranslator } from '@oneflowab/pomes';

import {
  composeValidators,
  minValue,
  required,
} from 'forms/validators';
import { formatTimePeriod } from 'agreement/date-helpers';
import { updateDurationBoxAttribute } from 'reducers/current-contract';
import { useDurationBoxProps } from 'contexts/duration-box-props';

import { NumberField } from 'components/text-field';
import {
  Popover,
  PopoverContent,
} from 'components/popover';
import {
  setDurationConstants,
  setInitialDurationConstants,
  setNoticePeriodConstants,
} from 'components/contract-boxes/duration-box/constants';
import Field from 'components/field';
import PopoverCloseButton from 'components/buttons/popover-close-button';
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';
import Select from 'components/select';

import {
  attributeScalarValidator,
  attributeValidator,
  getAttributeScalar,
  getAttributeUnit,
} from './validator';
import type { AttributeUnit } from './validator';

import style from './set-time-span.module.scss';

const getAvailableAttributeUnits = (message: MessageTranslator) => ([
  {
    value: 'd',
    text: message({
      id: 'days',
      comment: 'Used as a option in a select field',
    }),
  },
  {
    value: 'w',
    text: message({
      id: 'weeks',
      comment: 'Used as a option in a select field',
    }),
  },
  {
    value: 'm',
    text: message({
      id: 'months',
      comment: 'Used as a option in a select field',
    }),
  },
  {
    value: 'y',
    text: message({
      id: 'years',
      comment: 'Used as a option in a select field',
    }),
  },
]);

type Props = {
  message: MessageTranslator,
  toSet: 'duration' | 'noticePeriod' | 'initialDuration',
};

export const SetTimeSpanComponent = ({ message, toSet }: Props) => {
  const {
    boxId,
    duration,
    initialDuration,
    isAllowedToEditBoxData,
    noticePeriod,
    type,
  } = useDurationBoxProps();
  const availableAttributeUnits = useMemo(() => getAvailableAttributeUnits(message), [message]);

  const dispatch = useDispatch();

  let attributeName = 'Duration';
  let attribute = duration;
  let attributePopoverHeader = setDurationConstants.header;
  let attributePopoverLabel = setDurationConstants.label;

  if (toSet === 'noticePeriod') {
    attributeName = 'Notice period';
    attribute = noticePeriod;
    attributePopoverHeader = setNoticePeriodConstants.header;
    attributePopoverLabel = setNoticePeriodConstants.label;
  }
  if (toSet === 'initialDuration') {
    attributeName = 'Initial duration';
    attribute = initialDuration;
    attributePopoverHeader = setInitialDurationConstants.header;
    attributePopoverLabel = setInitialDurationConstants.label;
  }

  const formattedAttribute = formatTimePeriod(attribute, message);
  const attributeScalar = getAttributeScalar(attribute);
  const attributeUnit = getAttributeUnit(attribute);

  const [
    periodAttributeScalar,
    setPeriodAttributeScalar,
  ] = useState(attributeScalar);

  const [periodAttributeUnit, setPeriodAttributeUnit] = useState(attributeUnit);
  const attributeUnitFullname = useMemo(() => availableAttributeUnits
    .find((item) => item.value === periodAttributeUnit)?.text,
  [availableAttributeUnits, periodAttributeUnit]);

  const validations = useMemo(() => composeValidators(
    required({
      field: 'attributeScalar',
      text: (
        <Message
          id="{attributeName} must be a valid time period."
          comment="Validation message for contract duration period popover"
          values={{
            attributeName,
          }}
        />
      ),
    }),
    minValue({
      field: 'attributeScalar',
      text: (
        <Message
          id="{attributeName} must be a valid time period."
          comment="Used in min value validation of Period duration"
          values={{
            attributeName,
          }}
        />
      ),
      limit: 1,
    }),
    attributeScalarValidator,
    attributeValidator,
  ), [attributeName]);

  const onFormSubmit = () => {
    const attributeScalarPristine = isEqual(attributeScalar, periodAttributeScalar);
    const attributeUnitPristine = isEqual(attributeUnit, periodAttributeUnit);
    const formPristine = attributeScalarPristine && attributeUnitPristine;

    if (formPristine) {
      return;
    }

    const newPeriodAttribute = periodAttributeScalar + periodAttributeUnit[0];
    dispatch(updateDurationBoxAttribute(boxId, { [toSet]: newPeriodAttribute }));
  };

  const handleUnitChange = useCallback((value: AttributeUnit) => {
    setPeriodAttributeUnit(value);
  }, []);

  const handleScalarChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPeriodAttributeScalar(event.target.value as unknown as number);
  }, []);

  const handleClose = useCallback(() => {
    setPeriodAttributeScalar(attributeScalar);
    setPeriodAttributeUnit(attributeUnit);
  }, [attributeScalar, attributeUnit]);

  if (!isAllowedToEditBoxData) {
    return (
      <span>
        {formattedAttribute}
      </span>
    );
  }

  return (
    <span className={style.PeriodDurationContainer}>
      <Popover>
        <PopoverTriggerButton className={style.TextWrapper}>
          <span>{formattedAttribute}</span>
        </PopoverTriggerButton>
        <PopoverContent
          side="bottom"
          className={style.PopoverContent}
          onEscapeKeyDown={handleClose}
          onInteractOutside={handleClose}
        >
          <Form
            onSubmit={onFormSubmit}
            initialValues={{
              attributeScalar: periodAttributeScalar,
              attributeUnit: periodAttributeUnit,
              attributeName,
              initialDuration,
              duration,
              noticePeriod,
              attributeToValidate: toSet,
              type,
            }}
            render={(formProps) => (
              <form onSubmit={formProps.handleSubmit} autoComplete="off">
                <div className={style.Header}>
                  {attributePopoverHeader}
                </div>
                <div className={style.PeriodAttributeLabel}>
                  {attributePopoverLabel}
                </div>
                <div className={style.FormInfoContainer}>
                  <div className={style.NumberField}>
                    <Field
                      name="attributeScalar"
                      onChange={handleScalarChange}
                      component={NumberField}
                      displayErrorEarly
                      validate={validations}
                      step={1}
                    />
                  </div>
                  <div className={style.PeriodAttributeUnit}>
                    <Select
                      name="attributeUnit"
                      value={periodAttributeUnit}
                      onValueChange={handleUnitChange}
                      label={attributeUnitFullname}
                      items={availableAttributeUnits}
                      labelKey="text"
                      valueKey="value"
                      selectClassName={style.Select}
                    />
                  </div>
                </div>
                <div className={style.ButtonsWrapper}>
                  <PopoverCloseButton
                    customClass={style.ButtonCancel}
                    onClick={handleClose}
                  >
                    <Message
                      id="Cancel"
                      comment="Action to cancel the editing period duration"
                    />
                  </PopoverCloseButton>
                  <PopoverCloseButton
                    customClass={style.ButtonSave}
                    onClick={onFormSubmit}
                    disabled={!formProps.valid}
                    type="submit"
                  >
                    <Message
                      id="Save"
                      comment="Action to cancel the editing period duration"
                    />
                  </PopoverCloseButton>
                </div>
              </form>
            )}
          />
        </PopoverContent>
      </Popover>
    </span>
  );
};

export const SetTimeSpan = localize<Props>(SetTimeSpanComponent);
