// @flow

import React, {
  useCallback, useState, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNumber, isEmpty } from 'lodash';
import { orderBy } from 'natural-orderby';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import Message from 'components/message';
import useApi from 'hooks/use-api';
import { getPositionFromSessionSelector, getAccountFromSessionSelector } from 'reducers/session';
import agreements from 'reducers/entities/agreements';
import { fetchColleagues } from 'oneflow-client/positions';
import { getContractMessageTemplates } from 'oneflow-client/message-templates';

import ModalForm from 'hocs/modal-form';
import { getParticipantSignOrderBlockIndex } from 'agreement/pending-state-flow';

import * as participantConstants from 'agreement/participant/constants';
import { getAgreementMyParty, getGuestToken, getAgreementMyParticipant } from 'agreement/selectors';

import { getParticipantRolesAsOptions } from 'components/participant-roles/participant-roles';
import { getSignMethod } from 'components/sign-methods/sign-methods';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import CircularSpinnerIcon from 'components/icons/circular-spinner';
import DelegateSigningRightsBody from './delegate-signing-rights-body';

import style from './delegate-signing-rights.module.scss';

export type FormData = {|
  userRole?: number,
  signMethod?: number,
  subject?: string,
  body?: string,
  email?: string,
  fullname?: string,
  jobTitle?: string,
  phoneNumber?: string,
  personalIdentification?: string,
  twoStepAuthenticationMethod?: string,
|};

export type Props = {
  agreement: Agreement,
  message: MessageTranslator,
  children?: () => React.Node,
};

type State = {
  colleagues: Array<any>,
  contractMessageTemplates: Array<MessageTemplate>,
  selectedMessageTemplate: any,
  defaultColleague: any,
  defaultMessageTemplate: any,
  defaultSignMethod: any,
  defaultRole: any,
  defaultSignMethod: any,
  error: any,
}

export const DelegateSigningRightsComponent = ({
  agreement,
  message,
  children,
}: Props) => {
  const dispatch = useDispatch();
  const { availableOptions, collection } = agreement;
  const position = useSelector(getPositionFromSessionSelector);
  const accountFromSession = useSelector(getAccountFromSessionSelector);

  const rpcState = useSelector((state) => (
    agreements.getAddParticipantSelector(state, { id: agreement.id })
  ));

  const guestToken = useSelector(getGuestToken);
  const isGuestView = Boolean(guestToken);

  const resetRpcState = () => dispatch(agreements.addParticipantReset({
    id: agreement.id,
  }));

  const { runAll, status, reset } = useApi();

  const myParty = useMemo(() => getAgreementMyParty(agreement), [agreement]);
  const myParticipant = useMemo(() => getAgreementMyParticipant(agreement), [agreement]);

  const isSignOrderEnabled = agreement.config?.signOrder;
  const pendingStateFlowId = isSignOrderEnabled ? agreement.pendingStateFlow.id : null;
  const signOrderBlockIndex = isSignOrderEnabled
    ? getParticipantSignOrderBlockIndex(agreement, myParticipant?.id) : 0;

  const modalKey = 'delegate signing rights modal';
  const getSignMethods = useCallback(() => (
    availableOptions?.signMethods?.map<any>(
      (signMethod) => ({
        value: signMethod,
        label: getSignMethod(signMethod, message)?.label,
      }),
    )
  ), [availableOptions?.signMethods, message]);

  const defaultCountryValue = useMemo(() => myParty?.country
  || collection?.brandingCountry
  || accountFromSession?.country, [
    accountFromSession?.country,
    collection?.brandingCountry,
    myParty?.country,
  ]);

  const getDefaultParticipantRole = useCallback(() => (
    getParticipantRolesAsOptions(message).find(
      (role) => role.value === participantConstants.TYPE_IS_SIGNATORY,
    )
  ), [message]);

  const getDefaultSignMethod = useCallback(() => {
    if (isEmpty(agreement)) {
      return null;
    }

    return (
      getSignMethods()?.[0]
    );
  }, [agreement, getSignMethods]);

  const initialState: State = {
    colleagues: [],
    contractMessageTemplates: [],
    defaultColleague: undefined,
    defaultRole: getDefaultParticipantRole(),
    defaultSignMethod: getDefaultSignMethod(),
    // we are not using the error response object from the api in this component
    // could be used for showing error message
    error: null,
    hasSucceeded: false,
    defaultDelegateeValues: {
      fullname: null,
      email: null,
      phoneNumber: defaultCountryValue.toLowerCase(),
      personalIdentification: null,
      role: getDefaultParticipantRole(),
      jobTitle: null,
    },
  };

  const [state, setState] = useState({ ...initialState });
  const {
    colleagues,
    contractMessageTemplates,
    defaultColleague,
    defaultMessageTemplate,
    defaultRole,
    defaultSignMethod,
    selectedMessageTemplate,
  } = state;

  const getErrorActions: GetActions = ({ closeConfirmation }) => (
    <CancelButton onClick={closeConfirmation} modalKey={`${modalKey} - error action`} />
  );

  const getActions = ({ formProps, closeConfirmation }) => {
    const isGuestDisabled = isGuestView
      ? rpcState.loading
        || !isEmpty(formProps.errors)
      : undefined;

    const isColleagueDisabled = !isGuestView
      ? rpcState.loading
          || status === 'error'
          || !isEmpty(formProps.errors)
          || status === 'loading'
          || (colleagues.length === 0)
          || formProps.values.colleague === undefined
      : undefined;

    return (
      <div className={style.ActionButtons}>
        <CancelButton onClick={closeConfirmation} modalKey={modalKey} />
        <Button
          color="yellow"
          data-testid="delegate"
          onClick={formProps.handleSubmit}
          disabled={isGuestView ? isGuestDisabled : isColleagueDisabled}
        >
          <Message
            id="Delegate"
            comment="Button label used to confirm delegating signing rights in a modal."
          />
        </Button>
      </div>
    );
  };

  const delegateToColleague = (formData: FormData) => {
    const shouldInvite = formData.subject && formData.body ? 1 : 0;
    dispatch(agreements.addParticipant({
      id: agreement.id,
      data: {
        account: position?.id ?? null,
        id: agreement.id,
        agreementCompany: myParty?.id,
        isDelegate: 1,
        deliveryChannel: 0,
        invite: shouldInvite,
        message: formData.body ?? '',
        subject: formData.subject ?? '',
        fullname: formData.colleague?.label,
        positionId: formData.colleague?.value,
        signMethod: isNumber(formData.signMethod?.value)
          ? formData.signMethod?.value : formData.signMethod,
        type: isNumber(formData.userRole) ? formData.userRole : formData.userRole?.value,
        pendingStateFlowId,
        signOrderBlockIndex,
      },
    }));
  };

  const delegateToGuest = (formData: FormData) => {
    const shouldInvite = formData.subject && formData.body ? 1 : 0;
    dispatch(agreements.addParticipant({
      id: agreement.id,
      data: {
        account: myParticipant?.account ?? null,
        id: agreement.id,
        agreementCompany: myParty?.id,
        isDelegate: 1,
        deliveryChannel: 0,
        invite: shouldInvite,
        message: formData.body ?? '',
        subject: formData.subject ?? '',
        fullname: formData.fullname,
        email: formData.email,
        jobTitle: formData.jobTitle,
        phoneNumber: formData.phoneNumber,
        personalIdentification: formData.personalIdentification,
        signMethod: myParticipant?.signMethod ?? participantConstants.SIGN_METHOD_ESIGN,
        token: guestToken,
        twoStepAuthenticationMethod: myParticipant?.mfaChannel,
        positionId: myParticipant?.position?.id,
        type: isNumber(formData.userRole?.value) ? formData.userRole?.value : formData.userRole,
        pendingStateFlowId,
        signOrderBlockIndex,
      },
    }));
  };

  const handleSubmit = (formData: FormData) => {
    if (!formData || isEmpty(formData)) {
      return;
    }

    if (isGuestView) {
      delegateToGuest(formData);
    } else {
      delegateToColleague(formData);
    }
  };

  const updateSelectedMessageTemplate = useCallback(
    (selectedMessageTemplateOption?: MessageTemplate) => {
      setState((prevState) => ({
        ...prevState,
        selectedMessageTemplate: selectedMessageTemplateOption,
      }));
    }, [],
  );

  const getMessageBody = (messageTemplate: MessageTemplate) => {
    if (isEmpty(agreement)) {
      return null;
    }

    if (messageTemplate?.body) {
      return messageTemplate.body;
    }

    return undefined;
  };

  const getModalTitle = () => {
    if (isEmpty(agreement)) {
      return null;
    }

    return (
      <Message
        id="Delegate signing rights"
        comment="Modal title for delegating signing rights."
      />
    );
  };

  const fetchData = async () => {
    try {
      const [
        { collection: colleaguesData },
        { collection: contractMessageTemplatesData },
      ] = await runAll(
        fetchColleagues({
          active: 1,
          invited: 0,
        }),
        getContractMessageTemplates({
          contractId: agreement.id,
          type: 'publish',
        }),
      );
      const filteredAgreementColleagues = myParty?.participants.filter(
        (participant) => participant.state !== participantConstants.STATE_IS_DISABLED,
      );
      const agreementActiveColleaguesIds = filteredAgreementColleagues?.map(
        (participant) => participant.position?.id,
      );
      const filteredColleaguesData = colleaguesData.filter(
        (colleague) => (!agreementActiveColleaguesIds?.includes(colleague.id)),
      );
      filteredColleaguesData.sort((a, b) => a.fullname.localeCompare(b.fullname));

      const orderedContractMessageTemplates = orderBy(
        contractMessageTemplatesData,
        [(messageTemplate) => messageTemplate.name],
        ['asc'],
      );
      setState((prevState) => ({
        ...prevState,
        colleagues: filteredColleaguesData,
        contractMessageTemplates: orderedContractMessageTemplates,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        contractMessageTemplates: [],
        colleagues: [],
        error,
      }));
    }
  };

  const handleOpen = () => {
    if (isGuestView) {
      setState((prevState) => ({
        ...prevState,
        error: null,
      }));
    } else {
      fetchData();
    }
  };

  const resetDelegateForm = () => {
    resetRpcState();

    if (isGuestView) {
      setState((prevState) => ({
        ...prevState,
        defaultDelegateeValues: initialState.defaultDelegateeValues,
        error: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        colleagues: [],
        contractMessageTemplates: [],
        error: null,
      }));
      reset();
    }
  };

  const renderDelegateBody = () => {
    if (isEmpty(agreement)) {
      return null;
    }

    if (isGuestView) {
      return (
        <DelegateSigningRightsBody
          agreement={agreement}
          myParticipant={myParticipant}
        />
      );
    }

    if (status === 'loading') {
      return (
        <div className={style.LoadingSpinner}>
          <CircularSpinnerIcon />
        </div>
      );
    }

    return (
      <DelegateSigningRightsBody
        agreement={agreement}
        contractMessageTemplates={contractMessageTemplates}
        colleagues={colleagues}
        getMessageBody={getMessageBody}
        getSignMethods={getSignMethods}
        position={position}
        selectedMessageTemplate={selectedMessageTemplate}
        updateSelectedMessageTemplate={updateSelectedMessageTemplate}
      />
    );
  };

  const renderChildren = (onClick) => {
    if (children) {
      return children(onClick);
    }

    return (
      <Button
        onClick={onClick}
        data-testid="delegate-button"
      >
        <Message
          id="Delegate my signing rights"
          comment="Label for a button responsible for giving my right to sign to another user/colleague."
        />
      </Button>
    );
  };

  const getInitialValues = () => {
    if (!isGuestView) {
      return ({
        body: getMessageBody(defaultMessageTemplate),
        colleague: defaultColleague?.value,
        subject: defaultMessageTemplate?.subject,
        signMethod: defaultSignMethod?.value,
        userRole: defaultRole?.value,
      });
    }
    return ({ userRole: defaultRole?.value });
  };

  return (
    <ModalForm
      actions={getActions}
      errorActions={getErrorActions}
      body={renderDelegateBody()}
      title={getModalTitle()}
      onOpen={handleOpen}
      formState={rpcState}
      resetFormState={resetRpcState}
      onSubmit={handleSubmit}
      onClose={resetDelegateForm}
      initialValues={getInitialValues()}
      modalKey={modalKey}
    >
      {renderChildren}
    </ModalForm>
  );
};

export default localize<Props>(DelegateSigningRightsComponent);
