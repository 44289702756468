// @flow

import React, { useState, useRef, useEffect } from 'react';
import Divider from 'components/divider';
import { ExpandableOptionsHeader } from 'components/expandable-options-header';
import { ExpandableOptionsItem } from 'components/expandable-options-item';
import style from './expandable-options.module.scss';

type Option = {
  title: string,
  description: string,
  isOn: boolean,
  onToggle: Function,
  disabled?: boolean,
  hideMenuItem: boolean,
  additionalInformation?: boolean,
  value?: string,
}

type Props = {
  title: string,
  options: Array<Option>,
  isExpandable: boolean,
  showDivider?: boolean,
  isAdditionalOptions?: boolean,
};

export const ExpandableOptions = ({
  title,
  options,
  isExpandable = options.length > 1,
  showDivider,
  isAdditionalOptions,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const optionsContainerRef = useRef(null);
  const renderOptions = () => options.map(
    (option, index) => (
      <ExpandableOptionsItem
        title={option.title}
        description={option.description}
        key={`item_${index + 1}`}
        isOn={option.isOn}
        onToggle={option.onToggle}
        disabled={option.disabled}
        disabledTooltipMessage={option.disabledTooltipMessage}
        hideMenuItem={option.hideMenuItem}
        showDivider={showDivider}
        additionalInformation={option.additionalInformation}
        isAdditionalOptions={isAdditionalOptions}
        value={option.value}
      />
    ),
  );

  const toggleOptions = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      optionsContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  if (!options.length) {
    return null;
  }

  const renderLine = () => {
    if (!showDivider) {
      return null;
    }
    return <Divider solid className={style.Divider} />;
  };

  const optionsClassName = isExpandable && !isExpanded ? style.Hidden : null;

  return (
    <div className={style.ExpandableOptions}>
      {renderLine()}
      <ExpandableOptionsHeader
        title={title}
        onClick={toggleOptions}
        isExpanded={isExpanded}
        isExpandable={isExpandable}
        isAdditionalOptions={isAdditionalOptions}
      />
      <div className={optionsClassName} ref={optionsContainerRef}>
        {renderOptions()}
      </div>
    </div>
  );
};
