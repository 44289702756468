// @flow

import * as React from 'react';

import { SearchResult } from './search';

const getDisplayName = (Component) => {
  const displayName = Component.displayName || Component.name || 'Component';

  return `SearchResultConsumer(${displayName})`;
};

const withSearchResult = (Component: React.ComponentType<any>) => {
  const WithSearchResult = (props: {}) => (
    <SearchResult.Consumer>
      {(context) => <Component {...props} {...context} />}
    </SearchResult.Consumer>
  );

  withSearchResult.displayName = getDisplayName(Component);

  return WithSearchResult;
};

export default withSearchResult;
