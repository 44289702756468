import getFontAssetsDomain from './get-font-asset-domain.js';

const loadQuestrial = () => {
  const normalQuestrial = new FontFace(
    'Questrial',
    `url(${getFontAssetsDomain()}/resources/fonts/questrial/questrial_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );

  return [normalQuestrial];
};

export default loadQuestrial;
