/* eslint-disable react/no-this-in-sfc */
// @flow

import React from 'react';

import { Link } from 'react-router-dom';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';
import type { MessageTranslator } from '@oneflowab/pomes';

import { checkAcl } from 'components/acl';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { EditWorkspaceGroupRole, EditWorkspaceUserRole } from 'components/modals/edit-workspace-role';
import { ChangeRoleMenuItem } from 'components/menu-items/change-role';
import { isUserLimited } from 'user';
import { Table } from 'components/table';
import ActionBar from 'components/action-bar';
import ActionsMenu from 'components/actions-menu';
import adminPage from 'hocs/admin-page';
import Button from 'components/button';
import Conditional from 'components/conditional';
import Divider from 'components/divider';
import GrantGroupAccess from 'components/modals/grant-group-access';
import GrantUserAccess from 'components/modals/grant-user-access';
import GrantUserAccessIcon from 'components/icons/grant-user-access';
import GrantGroupAccessIcon from 'components/icons/grant-group-access';
import Pagination from 'components/pagination';
import RemoveGroupFromWorkspace from 'components/modals/remove-group-from-workspace';
import { RemoveUserFromWorkspace } from 'components/modals/remove-user-from-workspace';
import { HelpCenterLink } from 'components/help-center-link';
import WorkspaceIcon from 'components/icons/workspace';
import { BadgeInactive } from 'components/badges/badge-inactive';

import style from './workspace-access.module.scss';

const WORKSPACE_USERS_ACCESS_QUERY = 'workspaceUsers/directAccess';
const WORKSPACE_GROUPS_ACCESS_QUERY = 'workspaceGroups/groupAccess';

export type Props = {
  allGroupsQuery: Query,
  allGroups: Array<Group>,
  workspace: Workspace,
  queryGroupBindings: QueryFunc,
  groups: Array<Group>,
  groupsQuery: Query,
  queryGroups: QueryFunc,
  queryPositionBindings: QueryFunc,
  positions: Array<Position>,
  positionsQuery: Query,
  message: MessageTranslator,
  hasGroupPermission: boolean,
};

export class WorkspaceAccessComponent extends React.Component<Props> {
  componentDidMount() {
    const { queryPositionBindings, queryGroups, hasGroupPermission } = this.props;

    queryPositionBindings();
    if (hasGroupPermission) {
      queryGroups();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      allGroups, allGroupsQuery, hasGroupPermission, queryGroups,
    } = this.props;

    if (prevProps.hasGroupPermission !== hasGroupPermission) {
      queryGroups();
    }

    if (prevProps.allGroupsQuery.status !== 'success' && allGroupsQuery.status === 'success') {
      if (allGroups.length) {
        this.props.queryGroupBindings();
      }
    }
  }

  getGroupName = (binding) => (
    <Link to={`/admin/groups/${binding.group.id}`}>
      {binding.group.name}
    </Link>
  );

  getGroupActions = (binding) => {
    const { message, workspace } = this.props;
    const { group } = binding;
    const hasGroupnBindingEditAcl = checkAcl(group.acl, ['group:collection:binding:create', 'group:collection:binding:remove']);
    const hasGroupBindingRemoveAcl = checkAcl(group.acl, 'group:collection:binding:remove');

    const hasWorkspaceBindingEditAcl = checkAcl(workspace.acl, ['collection:group:binding:create', 'collection:group:binding:remove']);
    const hasWorkspaceBindingRemoveAcl = checkAcl(workspace.acl, 'collection:group:binding:remove');

    return (
      <ActionsMenu
        actions={[
          <EditWorkspaceGroupRole
            title={message({
              id: 'Change role',
              comment: 'Modal title for changing workspace access role.',
            })}
            workspaceId={workspace.id}
            group={group}
            role={binding.role}
            bindingId={binding.id}
            pipeAction={WORKSPACE_GROUPS_ACCESS_QUERY}
          >
            {this.getEditMenuItem(hasGroupnBindingEditAcl && hasWorkspaceBindingEditAcl)}
          </EditWorkspaceGroupRole>,
          <RemoveGroupFromWorkspace
            workspace={workspace}
            group={group}
            bindingId={binding.id}
            roleId={binding.role.id}
          >
            {this.getRemoveMenuItem(hasGroupBindingRemoveAcl && hasWorkspaceBindingRemoveAcl)}
          </RemoveGroupFromWorkspace>,
        ]}
        focusOnCloseDisabled
      />
    );
  };

  getRoles = (item: Group | Position) => (
    <Link key={item.role.id} to={`/admin/roles/${item.role.id}`}>
      {item.role.name}
    </Link>
  );

  getPositionName = (binding) => (
    <>
      <Link to={`/admin/users/${binding.position.id}`} className={style.Name}>
        {binding.position.fullname}
      </Link>
      {!binding.position.active && <BadgeInactive />}
    </>
  );

  getEditMenuItem = (permission: boolean) => (onClick: () => void) => (
    <ChangeRoleMenuItem
      onClick={onClick}
      disabled={!permission}
    />
  );

  getRemoveMenuItem = (permission: boolean) => (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!permission}
    />
  );

  getPositionActions = (binding) => {
    const { message, workspace } = this.props;
    const { position, role } = binding;
    const hasPositionBindingEditAcl = checkAcl(position.acl, ['position:collection:binding:create', 'position:collection:binding:remove']) && !isUserLimited(position);
    const hasPositionBindingRemoveAcl = checkAcl(position.acl, 'position:collection:binding:remove');

    const hasWorkspaceBindingEditAcl = checkAcl(workspace.acl, ['collection:position:binding:create', 'collection:position:binding:remove']);
    const hasWorkspaceBindingRemoveAcl = checkAcl(workspace.acl, 'collection:position:binding:remove');

    return (
      <ActionsMenu
        actions={[
          <EditWorkspaceUserRole
            title={message({
              id: 'Change role',
              comment: 'Modal title for changing workspace access role.',
            })}
            workspaceId={workspace.id}
            position={position}
            role={role}
            bindingId={binding.id}
            pipeAction={WORKSPACE_USERS_ACCESS_QUERY}
          >
            {this.getEditMenuItem(hasPositionBindingEditAcl && hasWorkspaceBindingEditAcl)}
          </EditWorkspaceUserRole>,
          <RemoveUserFromWorkspace
            workspace={workspace}
            position={position}
            bindingId={binding.id}
            roleId={role.id}
          >
            {this.getRemoveMenuItem(hasPositionBindingRemoveAcl && hasWorkspaceBindingRemoveAcl)}
          </RemoveUserFromWorkspace>,
        ]}
        focusOnCloseDisabled
      />
    );
  };

  getGroupTableConfig() {
    const { message, groups } = this.props;
    const actions = [];

    return {
      items: groups,
      itemKey: 'id',
      actions,
      columns: [
        {
          name: 'name',
          label: message({
            id: 'Group name',
            comment: 'Used as the label of the relevant field in group table of access page',
          }),
          type: 'cell',
          value: this.getGroupName,
        },
        {
          name: 'role',
          label: message({
            id: 'Role',
            comment: 'Used as the label of the relevant field in group table of access page',
          }),
          type: 'cell',
          value: this.getRoles,
        },
        {
          name: 'permissions',
          label: message({ id: 'Permissions', comment: 'Column header for the permissions count in that role in the user pages.' }),
          type: 'cell',
          value: (item) => (
            <span>
              {`${item.role.permissionCount}/${item.role.maxPermissionCount}`}
            </span>
          ),
        },
        {
          name: 'actions',
          label: message({
            id: 'Actions',
            comment: 'Used as the label of the relevant field in group table of access page',
          }),
          type: 'actions',
          value: this.getGroupActions,
        },
      ],
    };
  }

  getUserTableConfig() {
    const { message, positions } = this.props;
    const actions = [];

    return {
      items: positions,
      itemKey: 'id',
      actions,
      columns: [
        {
          name: 'name',
          label: message({
            id: 'User name',
            comment: 'Used as the label of the relevant field in positions section of access page',
          }),
          type: 'cell',
          value: this.getPositionName,
        },
        {
          name: 'role',
          label: message({
            id: 'Role',
            comment: 'Used as the label of the relevant field in positions section of access page',
          }),
          type: 'cell',
          value: this.getRoles,
        },
        {
          name: 'permissions',
          label: message({ id: 'Permissions', comment: 'Column header for the permissions count in that role in the user pages.' }),
          type: 'cell',
          value: (item) => (
            <span>
              {`${item.role.permissionCount}/${item.role.maxPermissionCount}`}
            </span>
          ),
        },
        {
          name: 'actions',
          label: message({
            id: 'Actions',
            comment: 'Used as the label of the relevant field in positions section of access page',
          }),
          type: 'actions',
          value: this.getPositionActions,
        },
      ],
    };
  }

  getGrantUserAccessButtonForEmptyState = (onClick: Function) => {
    const { workspace } = this.props;
    const hasGrantUserAccessPermission = checkAcl(workspace.acl, 'collection:position:binding:create');

    return (
      <Button
        kind="linkInline"
        customClass={clsx(style.GrantAccessButton, style.ActionLink)}
        onClick={onClick}
        disabled={!hasGrantUserAccessPermission}
        icon={<GrantUserAccessIcon height="10px" className={style.Icon} />}
      >
        <Message
          id="Grant user access"
          comment="Used as the caption of the Grant User Access button"
        />
      </Button>
    );
  };

  getGrantUserAccessButton = (onClick: Function) => {
    const { workspace } = this.props;
    const hasGrantUserAccessPermission = checkAcl(workspace.acl, 'collection:position:binding:create');

    return (
      <Button
        icon={GrantUserAccessIcon}
        kind="primary"
        onClick={onClick}
        customClass={style.ActionButton}
        disabled={!hasGrantUserAccessPermission}
      >
        <Message
          id="Grant user access"
          comment="Button label."
        />
      </Button>
    );
  };

  getGrantGroupAccessButtonForEmptyState = (onClick: Function) => {
    const { workspace } = this.props;
    const hasGrantGroupAccessPermission = checkAcl(workspace.acl, 'collection:group:binding:create');

    return (
      <Button
        kind="linkInline"
        customClass={clsx(style.GrantAccessButton, style.ActionLink)}
        onClick={onClick}
        disabled={!hasGrantGroupAccessPermission}
        icon={GrantGroupAccessIcon}
      >
        <Message
          id="Grant group access"
          comment="Used as the caption of the Grant Group Access button"
        />
      </Button>
    );
  };

  getGrantGroupAccessButton = (onClick: Function) => {
    const { allGroups, workspace } = this.props;
    const hasGrantGroupAccessPermission = checkAcl(workspace.acl, 'collection:group:binding:create');

    // This also hides the button if you're not entitled to groups, as the list is empty
    if (!allGroups.length) {
      return null;
    }

    return (
      <Button
        icon={GrantGroupAccessIcon}
        kind="primary"
        onClick={onClick}
        disabled={!hasGrantGroupAccessPermission}
      >
        <Message
          id="Grant group access"
          comment="Button label."
        />
      </Button>
    );
  }

  getEmptyContent() {
    const { allGroups, workspace } = this.props;
    const grantGroupAccessButton = (
      <GrantGroupAccess workspace={workspace}>
        {this.getGrantGroupAccessButtonForEmptyState}
      </GrantGroupAccess>
    );
    const grantUserAccessButton = (
      <GrantUserAccess workspace={workspace}>
        {this.getGrantUserAccessButtonForEmptyState}
      </GrantUserAccess>
    );

    if (!allGroups.length) {
      return (
        <div>
          <Message
            id="Click {grantUserAccessButton} to grant user access."
            values={{
              grantUserAccessButton,
            }}
            comment="Help text for granting user access to a workspace."
          />
          <br />
          <HelpCenterLink path="support/solutions/articles/77000435914-grant-access-to-a-workspace" />
        </div>
      );
    }

    return (
      <div>
        <Message
          id="Click {grantUserAccessButton} or {grantGroupAccessButton} to grant user or group access."
          values={{
            grantGroupAccessButton,
            grantUserAccessButton,
          }}
          comment="Help text for granting user or group access to a workspace."
        />
        <br />
        <HelpCenterLink path="support/solutions/articles/77000435914-grant-access-to-a-workspace" />
      </div>
    );
  }

  getEmptyState() {
    const { message } = this.props;

    return {
      header: message({
        id: 'No user can access this workspace',
        comment: 'Empty state header for the access list in the workspace pages.',
      }),
      icon: <WorkspaceIcon height="33px" />,
      content: this.getEmptyContent(),
      showEmptyState: this.shouldShowEmptyState(),
    };
  }

  handlePageChange = (type: string) => (pagination: Pagination) => {
    const { queryGroupBindings, queryPositionBindings } = this.props;

    if (type === 'group') {
      queryGroupBindings({ pagination });
    } else {
      queryPositionBindings({ pagination });
    }
  };

  shouldShowEmptyState() {
    const {
      groupsQuery: { loading: isLoadingGroups },
      positionsQuery: { loading: isLoadingPositions },
      positions,
      groups,
    } = this.props;

    const positionCount = positions.length;
    const groupCount = groups.length;

    return !groupCount && !positionCount && !isLoadingGroups && !isLoadingPositions;
  }

  renderPagination(type: string) {
    const { groupsQuery, positionsQuery } = this.props;
    const query = type === 'group' ? groupsQuery : positionsQuery;

    if (query.loading || !query.count) {
      return null;
    }

    return (
      <Pagination
        totalItems={query.count}
        itemsPerPage={query.pagination.limit}
        currentOffset={query.pagination.offset}
        onChange={this.handlePageChange(type)}
      />
    );
  }

  renderGroupAccess() {
    const { allGroups, workspace } = this.props;
    const hasGroupAccess = checkAcl(workspace.acl, 'collection:group:binding:view');

    if (!allGroups.length || !hasGroupAccess) {
      return null;
    }

    return (
      <>
        <Divider />
        <div className={style.ContentHeader}>
          <h2 className={style.Header}>
            <Message
              id="Group access"
              comment="Section title on the access page in the workspace pages."
            />
          </h2>
          <p>
            <Message
              id="Grant access to all members of a group."
              comment="Help text on the access page in the workspace pages."
            />
          </p>
        </div>
        <Table
          config={this.getGroupTableConfig()}
          query={this.props.groupsQuery}
        />
        {this.renderPagination('group')}
      </>
    );
  }

  render() {
    const { workspace, positionsQuery } = this.props;

    return (
      <div className={style.Access}>
        <ActionBar collapsed>
          <ActionBar.Group>
            <div className={style.Buttons}>
              <GrantUserAccess workspace={workspace}>
                {this.getGrantUserAccessButton}
              </GrantUserAccess>
              <GrantGroupAccess workspace={workspace}>
                {this.getGrantGroupAccessButton}
              </GrantGroupAccess>
            </div>
          </ActionBar.Group>
        </ActionBar>
        <div>
          <Conditional ifCondition={!this.shouldShowEmptyState()}>
            <div className={style.ContentHeader}>
              <h2 className={style.Header}>
                <Message
                  id="User access"
                  comment="Section title on the access page in the workspace pages."
                />
              </h2>
              <p>
                <Message
                  id="Grant access to individual users."
                  comment="Help text on the access page in the workspace pages."
                />
              </p>
            </div>
          </Conditional>
          <Table
            config={this.getUserTableConfig()}
            query={positionsQuery}
            emptyState={this.getEmptyState()}
          />
          <Conditional ifCondition={!this.shouldShowEmptyState()}>
            {this.renderPagination('position')}
            {this.renderGroupAccess()}
          </Conditional>
        </div>
      </div>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Access',
    comment: 'Used as the title of the Workspace Access page',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(WorkspaceAccessComponent);
