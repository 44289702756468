import { identity } from 'lodash';
import { useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

export const SKIP_UPDATE = Symbol('SKIP_UPDATE');

type DependentStateOptions<S, T> = {
  dependency: T,
  recalculate?: (newDependencyValue?: T, currentValue?: S) => S | typeof SKIP_UPDATE
};

type DependentState<S> = [S | undefined, Dispatch<SetStateAction<S | undefined>>];

type InitialValue<S> = (S | undefined) | (() => S | undefined);

function useDependentState<S, T>(
  initialValue: InitialValue<S>,
  options: DependentStateOptions<S, T>,
): DependentState<S> {
  const {
    dependency,
    recalculate = identity,
  } = options;
  const [value, setValue] = useState<S | undefined>(initialValue);
  const [previousDependencyValue, setPreviousDependencyValue] = useState(dependency);

  if (dependency !== previousDependencyValue) {
    setPreviousDependencyValue(dependency);
    const newValue = recalculate(dependency, value);

    if (newValue !== SKIP_UPDATE) {
      setValue(newValue);
    }
  }

  return [value, setValue];
}

export default useDependentState;
