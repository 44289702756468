// @flow

import * as React from 'react';
import { Message, type MessageTranslator } from '@oneflowab/pomes';
import { Form, Field } from 'react-final-form';

import adminPage from 'hocs/admin-page';

import * as EditableForm from 'components/editable-form';
import TextField from 'components/text-field';
import { DetailsFooter } from 'components/details-footer';
import { LocalizedDateTime } from 'components/localized-date-time';
import Toggle from 'components/toggle';
import { ExtensionName } from 'components/extension/extension-name';

import style from './template-group-details.module.scss';

export type FormData = {
  name: string,
  description?: string,
  active: boolean,
};

export type Props = {
  templateGroup: TemplateGroup,
  updateState: UpdateState,
  resetUpdateState: () => void,
  updateTemplateGroup: FormData => void,
  message: MessageTranslator,
  extensionsById: {
    [key: number]: Extension,
  },
};

type State = {
  isEditMode: boolean,
};

export class TemplateGroupDetails extends React.PureComponent<Props, State> {
  state = {
    isEditMode: false,
  };

  componentDidUpdate(prevProps: Props) {
    const { isEditMode } = this.state;
    const { updateState } = this.props;
    const isUpdateSuccessChanged = updateState.success !== prevProps.updateState.success;

    if (isUpdateSuccessChanged && updateState.success && isEditMode) {
      this.setState({ isEditMode: false });
    }
  }

  getActiveToggle = (activeStatus: boolean) => (
    <Toggle
      className={style.Toggle}
      checked={activeStatus}
      disabled
    />
  );

  handleSubmit = ({ name, description, active }: FormData) => {
    const { updateTemplateGroup } = this.props;

    updateTemplateGroup({ name, description, active });
  };

  setIsEditMode = (isEditMode: boolean) => {
    const { updateState, resetUpdateState } = this.props;

    if (!updateState.pristine) {
      resetUpdateState();
    }

    this.setState({ isEditMode });
  };

  getInitialValues = () => {
    const { templateGroup } = this.props;

    return {
      name: templateGroup.name,
      description: templateGroup.description,
      active: templateGroup.active,
    };
  };

  getActiveStatus = (templateGroup: TemplateGroup) => {
    if (templateGroup.active) {
      return (
        <Message id="Active" comment="Used to inform that the template group is active" />
      );
    }

    return (
      <Message id="Inactive" comment="Used to inform that the template group is inactive" />
    );
  }

  getUsedByLink = (templateGroup: TemplateGroup, extensionsById) => {
    const extensionUsingTemplateGroup = extensionsById[templateGroup.usedBy];

    if (!extensionUsingTemplateGroup) {
      return '-';
    }

    return (
      <ExtensionName extension={extensionUsingTemplateGroup} isLinked />
    );
  }

  render() {
    const {
      resetUpdateState,
      updateState,
      templateGroup,
      extensionsById,
      message,
    } = this.props;
    const { isEditMode } = this.state;

    const infoPairs = [
      {
        label: <Message
          id="Template group ID"
          comment="ID label for the details footer."
        />,
        value: templateGroup.id,
      },
      {
        label: <Message
          id="Creation date"
          comment="Creation date label for the details footer"
        />,
        value: <LocalizedDateTime datetime={templateGroup.createdTime} />,
      },
      {
        label: <Message
          id="Status"
          comment="Status label for the details footer"
        />,
        value: this.getActiveStatus(templateGroup),
      },
      {
        label: <Message
          id="Used by"
          comment="Used by label for the details footer"
        />,
        value: this.getUsedByLink(templateGroup, extensionsById),
      },
    ];

    return (
      <>
        <Form
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <EditableForm.Header>
                <EditableForm.Actions
                  resetFormState={resetUpdateState}
                  formState={updateState}
                  isEditMode={isEditMode}
                  setIsEditMode={this.setIsEditMode}
                  disabled={templateGroup.usedBy}
                />
              </EditableForm.Header>
              <EditableForm.Body>
                <h2 className={style.Header}>
                  <Message
                    id="Template group details"
                    comment="Section header for the template group details page."
                  />
                </h2>
                <p>
                  <Message
                    id="This extension allows you to group templates and set custom data fields. These data fields can then be used in integrations and other areas."
                    comment="Help text for the template group details page."
                  />
                </p>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message
                      id="Name"
                      comment="Used as the label of the name field on the template group details page."
                    />
                  </EditableForm.Label>
                  <Field
                    name="name"
                    component={TextField}
                    value={templateGroup.name}
                    placeholder={message({
                      id: 'Enter name',
                      comment: 'Name field placeholder on the template group details page.',
                    })}
                    maxLength={100}
                    required
                    autoFocus
                    disabled={!isEditMode}
                  />
                </div>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message
                      id="Description"
                      comment="Used as the label of the description field on the template group details page."
                    />
                  </EditableForm.Label>
                  <Field
                    name="description"
                    component={TextField}
                    value={templateGroup.description}
                    placeholder={message({
                      id: 'Enter description',
                      comment: 'Description field placeholder on the template group details page.',
                    })}
                    maxLength={300}
                    disabled={!isEditMode}
                  />
                </div>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message
                      id="Active"
                      comment="Used as the label of the active field on the template group details page."
                    />
                  </EditableForm.Label>
                  <Field
                    name="active"
                    component={Toggle}
                    value={templateGroup.active}
                    disabled={!isEditMode}
                    version="latest"
                  />
                </div>
              </EditableForm.Body>
            </form>
          )}
        />
        <DetailsFooter infoPairs={infoPairs} />
      </>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Details',
    comment: 'Used as the page title of the template group details page.',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(TemplateGroupDetails);
