// @flow

import {
  Editor,
  Transforms,
  Element as SlateElement,
} from 'slate';

export const isBreakTextActive = (editor: Editor, direction: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.breakText === direction && n.type === 'image'
    ),
    mode: 'lowest',
  });

  return !!match;
};

export const toggleBreakText = (editor: Editor, direction: string) => {
  const isActive = isBreakTextActive(editor, direction);

  Transforms.setNodes(editor, {
    breakText: isActive ? 'inherit' : direction,
    wrapText: null,
  }, {
    match: (n) => (
      n.type === 'image'
    ),
    mode: 'lowest',
  });
};
