import normalizeEntity from 'normalized-redux/entity-normalizer';

const rpcs = [
  'upsertBilling',
] as const;

const normalizedBillings = normalizeEntity({
  entity: 'billing',
  rpcs,
});

export default normalizedBillings;
