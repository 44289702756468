// @flow

import React from 'react';
import { localize, Message } from '@oneflowab/pomes';

import Delete from 'components/icons/delete';
import Button from 'components/button';

import style from './remove-party.module.scss';

type Props = {
  onEnterConfirmationState: () => void,
  isConfirmationState: boolean,
}

export const RemoveParty = ({
  onEnterConfirmationState,
  isConfirmationState,
}: Props) => {
  const enterConfirmationState = () => {
    onEnterConfirmationState();
  };

  if (isConfirmationState) {
    return (
      <div className={style.ConfirmationMessage}>
        <Message
          id="Are you sure you want to remove the company?"
          comment="Warning to remove a company in the party modal"
        />
      </div>
    );
  }

  return (
    <div className={style.RemoveParty}>
      <Button
        icon={Delete}
        className={style.Button}
        onClick={enterConfirmationState}
      >
        <Message
          id="Remove company"
          comment="Button label to remove a company in the party modal"
        />
      </Button>
    </div>
  );
};

export default localize<Props>(RemoveParty);
