import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

type Annotation = {
  id?: number,
  _id?: number,
} | null;

type Value = {
  annotation: Annotation,
  isFocused: boolean,
} | null;

export const AnnotationContext = createContext<Value>(null);

type Props = {
  annotation: Annotation,
  children: ReactNode,
  isFocused: boolean,
};

export function AnnotationContextProvider({
  annotation,
  children,
  isFocused,
}: Props) {
  return (
    <AnnotationContext.Provider
      value={{
        annotation,
        isFocused,
      }}
    >
      {children}
    </AnnotationContext.Provider>
  );
}

export const useParentAnnotation = (): Value => {
  const contextValue = useContext(AnnotationContext);

  if (!contextValue) {
    return null;
  }

  return contextValue;
};
