import React, {
  useRef,
  useEffect,
} from 'react';
import type { MutableRefObject, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import {
  setAvailableContentInlineSize,
  getAvailableContentInlineSize,
} from 'reducers/app';

export const getComputedWidth = (element: HTMLDivElement): number => {
  if (!element) {
    return 0;
  }
  const { width } = window.getComputedStyle(element);
  if (!width) {
    return 0;
  }
  const parsedWidth = Number.parseInt(width, 10);

  if (Number.isNaN(parsedWidth)) {
    return 0;
  }

  return parsedWidth;
};

export const useAvailableContentInlineSizeDetector = (
  targetRef: MutableRefObject<HTMLDivElement | null>,
): void => {
  const dispatch = useDispatch();
  const availableContentInlineSize = useSelector(getAvailableContentInlineSize);

  const { width = 0 } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 10,
    refreshOptions: {
      leading: false,
      trailing: true,
    },
    targetRef,
  });
  const roundedWidth = Math.floor(width);
  const previousAvailableContentInlineSize = useRef(0);

  // Set availableContentInlineSize on mount
  useEffect(() => {
    if (!targetRef.current) {
      return;
    }
    const computedWidth = Math.floor(getComputedWidth(targetRef.current));
    previousAvailableContentInlineSize.current = Math.floor(getComputedWidth(targetRef.current));
    dispatch(setAvailableContentInlineSize(computedWidth));
  }, [dispatch, targetRef]);

  // Set availableContentInlineSize when the width changes
  useEffect(() => {
    if (!roundedWidth || roundedWidth === availableContentInlineSize) {
      return;
    }

    dispatch(setAvailableContentInlineSize(roundedWidth));

    // Removed useEffect dependency for availableContentInlineSize.
    // It was causing an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, roundedWidth]);
};

const AvailableContentInlineSizeDetector = ({ children }: { children: ReactNode }) => {
  const targetRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  useAvailableContentInlineSizeDetector(targetRef);

  return (
    <div ref={targetRef}>
      {children}
    </div>
  );
};

export default React.memo(AvailableContentInlineSizeDetector);
