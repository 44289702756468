// @flow

import * as React from 'react';
import { localize, Message } from '@oneflowab/pomes';
import ModalForm from 'hocs/modal-form';

import { ExtensionConfigurationHeader } from 'components/extension/extension-configuration-header';
import Field from 'components/field';
import TextField from 'components/text-field';
import Button from 'components/button';
import Edit from 'components/icons/edit';
import { webhookPlaceholderValidator } from 'forms/validators';

import style from './extension-custom-configuration.module.scss';

type FormData = {
  webhookUrlPlaceholder: string | null,
};

export class ExtensionCustomConfigurationComponent extends React.PureComponent<Props> {
  validations = (({ message }) => ({
    webhookUrlPlaceholder: webhookPlaceholderValidator({
      message,
    }),
  }))(this.props);

  handleSubmit = ({ webhookUrlPlaceholder }: FormData) => {
    const { updateExtension, extension } = this.props;

    updateExtension({
      id: extension.extensionId,
      config: {
        ...extension.config,
        webhookUrlPlaceholderValue: webhookUrlPlaceholder,
      },
    });
  };

  renderCustomConfiguration() {
    const { webhookUrlPlaceholderValue } = this.props.extension.config;

    if (webhookUrlPlaceholderValue === null) {
      return (
        <Message
          id="Not set"
          comment="Placeholder for setting custom extension configuration."
        />
      );
    }

    return webhookUrlPlaceholderValue;
  }

  renderChildren = (onClick: Function) => (
    <Button
      icon={Edit}
      kind="round"
      outline
      onClick={onClick}
      customClass={style.EditButton}
    />
  );

  renderEditModal() {
    const {
      message,
      extension,
      updateState,
      resetUpdateState,
    } = this.props;

    return (
      <ModalForm
        title={message({
          id: 'Set configuration value',
          comment: 'Modal title for setting extension configuration value.',
        })}
        body={this.renderBody()}
        onSubmit={this.handleSubmit}
        resetFormState={resetUpdateState}
        formState={updateState}
        initialValues={{
          webhookUrlPlaceholder: extension.config.webhookUrlPlaceholderValue,
        }}
        modalKey="set extension configuration modal"
      >
        {this.renderChildren}
      </ModalForm>
    );
  }

  renderBody() {
    const { message } = this.props;

    return (
      <div>
        <div className={style.ConfigurationValueField}>
          <Field
            name="webhookUrlPlaceholder"
            placeholder={message({
              id: 'Enter configuration value',
              comment: 'A configuration setting for an extension.',
            })}
            autoFocus
            component={TextField}
            validate={this.validations.webhookUrlPlaceholder}
          />
        </div>
      </div>
    );
  }

  render() {
    const { extensionName } = this.props;
    return (
      <>
        <ExtensionConfigurationHeader />
        <p>
          <Message
            id="Enter the customer specific configuration value for {extensionName}. If you are uncertain about what to enter here please consult the documentation above or contact {extensionName} support to get help."
            comment="Configuration help text for an extension."
            values={{
              extensionName,
            }}
          />
        </p>
        <div className={style.CustomConfiguration}>
          <span className={style.Label}>
            <Message
              id="Configuration value"
              comment="Configuration value label on an extension settings page."
            />
            :
          </span>
          {this.renderCustomConfiguration()}
          {this.renderEditModal()}
        </div>
      </>
    );
  }
}

export default localize<Props>(ExtensionCustomConfigurationComponent);
