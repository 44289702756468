/* eslint-disable import/prefer-default-export */
import {
  Editor,
  Path,
  Point,
  Range,
  Span,
} from 'slate';
import type { Location } from 'slate';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

/**
 *
 * @param editor Editor
 * @param at Undefined, Location | Span
 * In case of at being null/undefined slatejs falls back on editor.selection which is always valid
 * at can be a Path like [1, 8, 0, 1]
 * at can be a Point like { path: [1, 8, 0, 1], offset: 5 }
 * at can be a Range like { anchor: {path: [1, 0], offset:5}, focus: {path:[1, 0], offset: 5 } }
 * at can be a Span like [[1, 8, 0, 1], [1, 9, 0, 1]]
 * @returns boolean
 */
export const isEditorActiveAt = (
  editor: Editor,
  at?: Location | Span | null,
): boolean => {
  if (isNull(at) || isUndefined(at)) {
    return true;
  }

  if (Path.isPath(at)) {
    return Editor.hasPath(editor, at);
  }

  if (Point.isPoint(at)) {
    return Editor.hasPath(editor, at.path);
  }

  if (Range.isRange(at)) {
    return (
      Editor.hasPath(editor, at.anchor.path)
      && Editor.hasPath(editor, at.focus.path)
    );
  }

  if (Span.isSpan(at)) {
    return (
      Editor.hasPath(editor, at[0])
      && Editor.hasPath(editor, at[1])
    );
  }

  return false;
};
