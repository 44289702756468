// @flow

import {
  Editor,
  Element as SlateElement,
} from 'slate';

import { isEditorActiveAt } from 'components/rich-text-editor/editor-plugins/plugin-utils';
import { getActiveImage } from 'components/rich-text-editor/editor-plugins';

import { getActiveTableHead } from 'components/rich-text-editor/editor-plugins/table-utils/table-utils';

const getActiveTable = (editor: any, selection: any) => {
  if (!isEditorActiveAt(editor, selection)) {
    return null;
  }

  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table'
    ),
    mode: 'lowest',
    at: selection,
  });

  if (!match) {
    return null;
  }

  return match[0];
};

const useActiveTable = (editor) => {
  const { selection } = editor;

  const activeTableHead = getActiveTableHead(editor, selection);

  if (activeTableHead) {
    return null;
  }

  const activeImage = getActiveImage(editor);
  if (activeImage) {
    return null;
  }

  const [startEdge, endEdge] = Editor.edges(editor, selection);
  const activeTable = getActiveTable(editor, startEdge);
  const endActiveTable = getActiveTable(editor, endEdge);

  if (activeTable !== endActiveTable) {
    return null;
  }

  return activeTable;
};

export default useActiveTable;
