import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';

const VideoPopupInBody = ({ children }: { children: ReactNode }) => (
  createPortal(
    <div className="videoPopupInBody" data-testid="video-popup-in-body">{children}</div>,
    document.body,
  )
);

export default VideoPopupInBody;
