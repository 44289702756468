// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import accountsReducer from 'reducers/entities/accounts';
import { getAccountFromSessionSelector } from 'reducers/session';

import { StartTrial, type Props } from './start-trial';

type StateProps = {|
  updateState: RpcState,
  accountId: number,
|};

type DispatchProps = {|
  resetUpdateState: (id: number) => void,
  startTrial: (id: number) => void,
|};

type MapStateToProps = (State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);

  return {
    updateState: accountsReducer.getStartTrialSelector(state, {
      id: account.id,
    }),
    accountId: account.id,
  };
};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  resetUpdateState: (id: number) => {
    dispatch(accountsReducer.startTrialReset({
      id,
    }));
  },
  startTrial: (id: number) => {
    dispatch(accountsReducer.startTrial({
      id,
    }));
  },
});

const connectedStartTrial = connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(StartTrial);

export { connectedStartTrial as StartTrial };
