import getFontAssetsDomain from './get-font-asset-domain.js';

const loadLibreBaskerville = () => {
  const normalLibreBaskerville = new FontFace(
    'Libre Baskerville',
    `url(${getFontAssetsDomain()}/resources/fonts/libre-baskerville/libre-baskerville_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicLibreBaskerville = new FontFace(
    'Libre Baskerville',
    `url(${getFontAssetsDomain()}/resources/fonts/libre-baskerville/libre-baskerville_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldLibreBaskerville = new FontFace(
    'Libre Baskerville',
    `url(${getFontAssetsDomain()}/resources/fonts/libre-baskerville/libre-baskerville_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );

  return [
    normalLibreBaskerville,
    italicLibreBaskerville,
    boldLibreBaskerville,
  ];
};

export default loadLibreBaskerville;
