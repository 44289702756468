import { AiReviewMenuItem } from 'components/menu-items/ai-review';

export type Props = {
  agreement: Oneflow.Agreement,
  location: string,
  visible?: boolean,
};

const AiReviewComponent = ({
  location,
  agreement,
  visible,
}: Props) => {
  if (!visible) {
    return null;
  }

  const link = `/api/ext/ai_review/redirect/agreement/${agreement.id}?highlight_index_for_url=1`;

  return (<AiReviewMenuItem href={link} location={location} />);
};

export default AiReviewComponent;
