// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import debounce from 'lodash/debounce';
import type { MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';

import { toMoment } from 'date';
import { AuditLogTypes } from 'audit-log/constants';

import { Tag } from 'components/tags';
import Pagination from 'components/pagination';
import EmptyState from 'components/empty-state';
import { Table, TableFiltering } from 'components/table';
import SearchRemove from 'components/icons/search-remove';
import { getUserInitials } from 'user';
import { UserBadge } from 'components/user-badge';
import { LogoSymbol } from 'components/logo';
import { LocalizedDateTime } from 'components/localized-date-time';

import {
  ContractDeleteEvent,
  ContractRestoreEvent,
  DataRetentionPolicyUpdateEvent,
  WorkspaceAccessUpdateEvent,
  WorkspaceUpdateEvent,
  WorkspaceBrandingUpdateEvent,
  GroupCreateEvent,
  GroupDeleteEvent,
  GroupMembershipAddEvent,
  GroupMembershipRemoveEvent,
  GroupUpdateNameEvent,
  RoleCreateEvent,
  RoleDeleteEvent,
  ExtensionEnableEvent,
  ExtensionDisableEvent,
  AgreementPermanentDeleteEvent,
} from './events';

import RoleUpdateEvent from './events/role-update-event';

import style from './audit-log.module.scss';
import AgreementTemplateMoveEvent from './events/agreement-template-move-event';
import AccountExportEvent from './events/account-export-event';
import { AccountPlanUpdateEvent } from './events/account-plan-update-event';
import { AutomationRuleUpdateEvent } from './events/automation-rule-update-event';
import { AutomationRuleCreateEvent } from './events/automation-rule-create-event';
import { AutomationRuleRemoveEvent } from './events/automation-rule-remove-event';

const SESSION_EVENT = 'session';
const SYSTEM_EVENT = 'system';
const API_CALL = 'api';

export type Props = {
  auditLogs: Array<Tag>,
  positions: Array<Position>,
  query: Query,
  queryAuditLogs: QueryFunc,
  queryPositions: QueryFunc,
  queryPositionsLoadMore: (number) => void,
  message: MessageTranslator,
  trashCanFeatureFlagEnabled: boolean
}

export class AuditLogComponent extends React.Component<Props> {
  debouncedExecuteQuery = debounce((q: string, executeQuery: QueryFunc) => {
    executeQuery({
      params: {
        q,
      },
    });
  }, 300);

  componentDidMount() {
    const {
      queryAuditLogs,
      queryPositions,
    } = this.props;

    queryAuditLogs({
      params: {
        types: Object.values(AuditLogTypes),
      },
    });
    queryPositions({});
  }

  getUserFilterOption = (position: Position) => ({
    label: position.fullname,
    value: position.id,
    query: {
      actorPositionId: position.id,
    },
  });

  getUserFilterOptions() {
    const {
      message,
      positions,
    } = this.props;

    const allUsersOption = {
      query: {},
      label: message({
        id: 'All users',
        comment: 'Dropdown label for filtering the audit log by users',
      }),
      value: 0,
      default: true,
    };
    const staffEventsOption = {
      query: {
        isSystem: 1,
      },
      label: 'Oneflow',
      value: -1,
    };

    return [
      allUsersOption,
      staffEventsOption,
      ...positions.map(this.getUserFilterOption),
    ];
  }

  getEventTypeOptions() {
    const { message } = this.props;

    const allEventsOption = {
      query: {
        types: Object.values(AuditLogTypes),
      },
      label: message({
        id: 'All events',
        comment: 'Dropdown label for event types filter in the audit log page',
      }),
      value: 0,
      default: true,
    };
    const userLoginOption = {
      label: message({
        id: 'Login',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'user-login',
      query: {
        types: [
          AuditLogTypes.POSITION_LOGIN_SUCCESS,
        ],
      },
    };
    const billingUpdateOption = {
      label: message({
        id: 'Updated subscription and billing',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'billing-update',
      query: {
        types: [
          AuditLogTypes.ACCOUNT_PLAN_UPDATE,
          AuditLogTypes.ACCOUNT_SEAT_UPDATE,
        ],
      },
    };
    const TrashedDeletedRestoredOption = {
      label: message({
        id: 'Moved to trash, permanently deleted and restored events',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'trashed-deleted-restored',
      query: {
        types: [
          AuditLogTypes.AGREEMENT_PERMANENTLY_DELETE,
          AuditLogTypes.CONTRACT_DELETE,
          AuditLogTypes.CONTRACT_RESTORE,
        ],
      },
    };
    const contractDeleteOption = {
      label: message({
        id: 'Deleted documents and templates',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'contract-delete',
      query: {
        types: [
          AuditLogTypes.CONTRACT_DELETE,
        ],
      },
    };
    const contractRestoreOption = {
      label: message({
        id: 'Restored documents and templates',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'contract-restore',
      query: {
        types: [
          AuditLogTypes.CONTRACT_RESTORE,
        ],
      },
    };
    const agreementMoveOption = {
      label: message({
        id: 'Moved documents',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'agreement-move',
      query: {
        types: [
          AuditLogTypes.AGREEMENT_MOVE,
        ],
      },
    };
    const templateMoveOption = {
      label: message({
        id: 'Moved templates',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'template-move',
      query: {
        types: [
          AuditLogTypes.TEMPLATE_MOVE,
        ],
      },
    };
    const userInviteOption = {
      label: message({
        id: 'Invited users',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'user-invite',
      query: {
        types: [
          AuditLogTypes.POSITION_CREATE,
        ],
      },
    };
    const userReactivateOption = {
      label: message({
        id: 'Reactivated users',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'user-reactivate',
      query: {
        types: [
          AuditLogTypes.POSITION_ACTIVATE,
        ],
      },
    };
    const userDeactivateOption = {
      label: message({
        id: 'Deactivated users',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'user-deactivate',
      query: {
        types: [
          AuditLogTypes.POSITION_DEACTIVATE,
        ],
      },
    };
    const workspaceAccessUpdateOption = {
      label: message({
        id: 'Updated workspace access',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'workspace-access-update',
      query: {
        types: [
          AuditLogTypes.WORKSPACE_ACCESS_UPDATE,
        ],
      },
    };
    const workspaceUpdateOption = {
      label: message({
        id: 'Updated workspaces',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'workspace-update',
      query: {
        types: [
          AuditLogTypes.WORKSPACE_UPDATE,
        ],
      },
    };
    const workspaceBrandingUpdateOption = {
      label: message({
        id: 'Updated workspace brandings',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'workspace-branding-update',
      query: {
        types: [
          AuditLogTypes.WORKSPACE_BRANDING_UPDATE,
        ],
      },
    };
    const dataRetentionPolicyUpdateOption = {
      label: message({
        id: 'Updated data retention policies',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'data-retention-policy-update',
      query: {
        types: [
          AuditLogTypes.DATA_RETENTION_POLICY_UPDATE,
          AuditLogTypes.AUTOMATION_RULES_CREATE,
          AuditLogTypes.AUTOMATION_RULES_UPDATE,
          AuditLogTypes.AUTOMATION_RULES_REMOVE,
        ],
      },
    };
    const groupCreateOption = {
      label: message({
        id: 'Created groups',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'group-create',
      query: {
        types: [
          AuditLogTypes.GROUP_CREATE,
        ],
      },
    };
    const groupDeleteOption = {
      label: message({
        id: 'Deleted groups',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'group-delete',
      query: {
        types: [
          AuditLogTypes.GROUP_DELETE,
        ],
      },
    };
    const groupMembershipAddOption = {
      label: message({
        id: 'Added member to groups',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'group-membership-add',
      query: {
        types: [
          AuditLogTypes.GROUP_MEMBERSHIP_ADD,
        ],
      },
    };
    const groupMembershipRemoveOption = {
      label: message({
        id: 'Removed member from groups',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'group-membership-remove',
      query: {
        types: [
          AuditLogTypes.GROUP_MEMBERSHIP_REMOVE,
        ],
      },
    };
    const groupUpdateNameOption = {
      label: message({
        id: 'Updated group names',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'group-update-name',
      query: {
        types: [
          AuditLogTypes.GROUP_UPDATE_NAME,
        ],
      },
    };

    const roleCreatedOption = {
      label: message({
        id: 'Created roles',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'role-created',
      query: {
        types: [
          AuditLogTypes.ROLE_CREATE,
        ],
      },
    };

    const roleDeletedOption = {
      label: message({
        id: 'Deleted roles',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'role-deleted',
      query: {
        types: [
          AuditLogTypes.ROLE_REMOVE,
        ],
      },
    };

    const roleUpdateOption = {
      label: message({
        id: 'Updated roles',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'role-update',
      query: {
        types: [
          AuditLogTypes.ROLE_UPDATE,
        ],
      },
    };

    const marketplaceOption = {
      label: message({
        id: 'Marketplace events',
        comment: 'Dropdown label for filtering events in the audit log page.',
      }),
      value: 'marketplace-changes',
      query: {
        types: [
          AuditLogTypes.EXTENSION_ENABLE,
          AuditLogTypes.EXTENSION_DISABLE,
        ],
      },
    };

    const showTrashcanRelatedOption = this.props.trashCanFeatureFlagEnabled;
    const options = [
      allEventsOption,
      userLoginOption,
      billingUpdateOption,
      agreementMoveOption,
      templateMoveOption,
      userInviteOption,
      userReactivateOption,
      userDeactivateOption,
      workspaceAccessUpdateOption,
      workspaceUpdateOption,
      workspaceBrandingUpdateOption,
      dataRetentionPolicyUpdateOption,
      groupCreateOption,
      groupDeleteOption,
      groupMembershipAddOption,
      groupMembershipRemoveOption,
      groupUpdateNameOption,
      roleCreatedOption,
      roleDeletedOption,
      roleUpdateOption,
      marketplaceOption,
    ];

    if (showTrashcanRelatedOption) {
      return [
        ...options.slice(0, 3),
        TrashedDeletedRestoredOption,
        ...options.slice(3),
      ];
    }

    return [
      ...options.slice(0, 3),
      contractDeleteOption,
      contractRestoreOption,
      ...options.slice(3),
    ];
  }

  onFilter = (params: { types?: Array<number> }) => {
    const { queryAuditLogs } = this.props;

    queryAuditLogs({
      params,
    });
  };

  handlePageChange = ({ offset, limit }: Pagination) => {
    const { queryAuditLogs, query } = this.props;

    queryAuditLogs({
      params: query.params,
      pagination: {
        offset,
        limit,
      },
    });
  };

  getActorName = (actor: Position | {||}) => {
    if (actor.name) {
      return actor.name;
    }

    if (!actor.fullname) {
      return undefined;
    }

    return actor.fullname;
  };

renderUserBadge = (auditLogEvent: AuditLogEvent) => {
  // login events store data in target, not position
  const actorName = this.getActorName(auditLogEvent.position)
    || this.getActorName(auditLogEvent.target);

  if (auditLogEvent.isStaff || auditLogEvent.actorSource === SYSTEM_EVENT) {
    return (
      <div className={style.UserBadge}>
        <LogoSymbol
          isDark
          height="44px"
        />
      </div>
    );
  }

  if (!actorName && auditLogEvent.actorSource === API_CALL) {
    return (
      <div className={style.UserBadge}>
        <UserBadge diameter={44} borderSize={2} fillColor="#3e4658" />
        <span className={style.Initials}>
          API
        </span>
      </div>
    );
  }

  if (!actorName) {
    return null;
  }

  return (
    <div className={style.UserBadge}>
      <UserBadge diameter={44} borderSize={2} fillColor="#3e4658" />
      <span className={style.Initials}>
        {getUserInitials(actorName)}
      </span>
    </div>
  );
};

renderActor = (auditLogEvent: AuditLogEvent) => {
  // login events store data in target, not position
  const actorName = this.getActorName(auditLogEvent.position)
    || this.getActorName(auditLogEvent.target);

  switch (auditLogEvent.actorSource) {
    case API_CALL:
      if (!actorName) {
        return (
          <Message
            id="API access"
            comment="Used when an event in the audit log was performed using an API token without an actor"
          />
        );
      }

      return (
        <Message
          id="{actorName} through API access"
          comment="Used when an event in the audit log was performed using an API token"
          values={{
            actorName,
          }}
        />
      );
    case SYSTEM_EVENT:
      return (
        <Message
          id="{actorName} (system)"
          comment="Used when an event in the audit log was performed by the system"
          values={{
            actorName: 'Oneflow',
          }}
        />
      );
    case SESSION_EVENT:
      if (auditLogEvent.isStaff) {
        return (
          <Message
            id="{actorName} (support)"
            comment="Used when an event in the audit log was performed by the oneflow staff"
            values={{
              actorName: 'Oneflow',
            }}
          />
        );
      }

      return actorName;
    default:
      return null;
  }
};

renderActorInfo = (auditLogEvent: AuditLogEvent) => (
  <div className={style.ActorInfo}>
    {this.renderUserBadge(auditLogEvent)}
    {this.renderActor(auditLogEvent)}
  </div>
);

renderSeatChanges = (auditLogEvent: AuditLogEvent) => {
  const { seats } = auditLogEvent.information;
  const isDowngrade = seats < 0;

  if (isDowngrade) {
    return (
      <Message
        id="The number of seats was {jsx-start}downgraded{jsx-end} by {numberOfSeats}"
        comment="Audit log event message for downgrading seats/licenses"
        component="span"
        className={style.Red}
        values={{
          numberOfSeats: Math.abs(seats),
        }}
      />
    );
  }

  return (
    <Message
      id="The number of seats was {jsx-start}upgraded{jsx-end} by {numberOfSeats}"
      comment="Audit log event message for upgrading seats/licenses"
      component="span"
      className={style.Green}
      values={{
        numberOfSeats: seats,
      }}
    />
  );
};

renderMessage = (auditLogEvent: AuditLogEvent) => {
  switch (auditLogEvent.type.id) {
    case AuditLogTypes.POSITION_CREATE:
      return (
        <span className={style.Message}>
          <Message
            id="The user {invitedUserName} was {jsx-start}invited{jsx-end}"
            comment="Audit log event message for newly invited users"
            component="span"
            className={style.Green}
            values={{
              invitedUserName: auditLogEvent.target.name,
            }}
          />
        </span>
      );
    case AuditLogTypes.POSITION_LOGIN_SUCCESS:
      return (
        <span className={style.Message}>
          <Message
            id="The user has logged in"
            comment="Audit log event message for when a user logged in"
            component="span"
          />
        </span>
      );
    case AuditLogTypes.POSITION_ACTIVATE:
      return (
        <span className={style.Message}>
          <Message
            id="The user {reactivatedUserName} was {jsx-start}reactivated{jsx-end}"
            comment="Audit log event message for reactivated users"
            component="span"
            className={style.Green}
            values={{
              reactivatedUserName: auditLogEvent.target.name,
            }}
          />
        </span>
      );
    case AuditLogTypes.POSITION_DEACTIVATE:
      return (
        <span className={style.Message}>
          <Message
            id="The user {deactivatedUserName} was {jsx-start}deactivated{jsx-end}"
            comment="Audit log event message for deactivated users"
            component="span"
            className={style.Red}
            values={{
              deactivatedUserName: auditLogEvent.target.name,
            }}
          />
        </span>
      );
    case AuditLogTypes.ROLE_CREATE:
      return (
        <span className={style.Message}>
          <RoleCreateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.ROLE_REMOVE:
      return (
        <span className={style.Message}>
          <RoleDeleteEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.ROLE_UPDATE:
      return (
        <span className={style.Message}>
          <RoleUpdateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.WORKSPACE_ACCESS_UPDATE:
      return (
        <span className={style.Message}>
          <WorkspaceAccessUpdateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.WORKSPACE_UPDATE:
      return (
        <span className={style.Message}>
          <WorkspaceUpdateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.WORKSPACE_BRANDING_UPDATE:
      return (
        <span className={style.Message}>
          <WorkspaceBrandingUpdateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.AUTOMATION_RULES_CREATE:
      return (
        <span className={style.Message}>
          <AutomationRuleCreateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.AUTOMATION_RULES_UPDATE:
      return (
        <span className={style.Message}>
          <AutomationRuleUpdateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.AUTOMATION_RULES_REMOVE:
      return (
        <span className={style.Message}>
          <AutomationRuleRemoveEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.DATA_RETENTION_POLICY_UPDATE:
      return (
        <span className={style.Message}>
          <DataRetentionPolicyUpdateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.CONTRACT_DELETE:
      return (
        <span className={style.Message}>
          <ContractDeleteEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.CONTRACT_RESTORE:
      return (
        <span className={style.Message}>
          <ContractRestoreEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.ACCOUNT_SEAT_UPDATE:
      return (
        <span className={style.Message}>
          {this.renderSeatChanges(auditLogEvent)}
        </span>
      );
    case AuditLogTypes.GROUP_CREATE:
      return (
        <span className={style.Message}>
          <GroupCreateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.GROUP_DELETE:
      return (
        <span className={style.Message}>
          <GroupDeleteEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.GROUP_MEMBERSHIP_ADD:
      return (
        <span className={style.Message}>
          <GroupMembershipAddEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.GROUP_MEMBERSHIP_REMOVE:
      return (
        <span className={style.Message}>
          <GroupMembershipRemoveEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.GROUP_UPDATE_NAME:
      return (
        <span className={style.Message}>
          <GroupUpdateNameEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.AGREEMENT_MOVE:
      return (
        <span className={style.Message}>
          <AgreementTemplateMoveEvent auditLogEvent={auditLogEvent} type="agreement" />
        </span>
      );
    case AuditLogTypes.TEMPLATE_MOVE:
      return (
        <span className={style.Message}>
          <AgreementTemplateMoveEvent auditLogEvent={auditLogEvent} type="template" />
        </span>
      );
    case AuditLogTypes.ACCOUNT_EXPORT:
      return (
        <span className={style.Message}>
          <AccountExportEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.ACCOUNT_PLAN_UPDATE:
      return (
        <span className={style.Message}>
          <AccountPlanUpdateEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.EXTENSION_ENABLE:
      return (
        <span className={style.Message}>
          <ExtensionEnableEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.EXTENSION_DISABLE:
      return (
        <span className={style.Message}>
          <ExtensionDisableEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    case AuditLogTypes.AGREEMENT_PERMANENTLY_DELETE:
      return (
        <span className={style.Message}>
          <AgreementPermanentDeleteEvent auditLogEvent={auditLogEvent} />
        </span>
      );
    default:
      console.log('Unknown event type', auditLogEvent.type.id);
      return null;
  }
};

renderIpAddress = (auditLogEvent: AuditLogEvent) => {
  if (auditLogEvent.isStaff || !auditLogEvent.ipAddress) {
    return null;
  }

  return (
    <span className={style.Metadata}>
      {auditLogEvent.ipAddress}
    </span>
  );
};

renderDate = (auditLogEvent: AuditLogEvent) => {
  const createdMoment = toMoment(auditLogEvent.createdTs);

  return (
    <span className={style.Metadata}>
      <div className={style.Date}>
        <div>
          <LocalizedDateTime datetime={createdMoment} />
        </div>
      </div>
    </span>
  );
};

getTableConfig = () => {
  const { auditLogs, message } = this.props;

  return {
    items: auditLogs,
    itemKey: 'id',
    actions: [],
    columns: [
      {
        name: 'user',
        label: message({
          id: 'User',
          comment: 'Column cell, company name in the address book',
        }),
        type: 'cell',
        value: this.renderActorInfo,
      },
      {
        name: 'event',
        label: message({
          id: 'Event',
          comment: 'Column cell, company name in the address book',
        }),
        type: 'cell',
        value: this.renderMessage,
      },
      {
        name: 'ip address',
        label: message({
          id: 'IP address',
          comment: 'Column cell, company name in the address book',
        }),
        type: 'cell',
        value: this.renderIpAddress,
      },
      {
        name: 'date',
        label: message({
          id: 'Date',
          comment: 'Column cell, company name in the address book',
        }),
        type: 'cell',
        value: this.renderDate,
      },
    ],
  };
};

renderEmptyState = () => {
  const { message } = this.props;

  return (
    <EmptyState
      icon={<SearchRemove height="33px" />}
      header={message({
        id: 'No events found',
        comment: 'Empty state header. Shown in audit log list when no events are found.',
      })}
      content={message({
        id: 'Please adjust your search criteria and try again.',
        comment: 'Empty state content. Shown in audit log list when no events are found.',
      })}
      defaultStyle
      className={style.Empty}
    />
  );
};

render() {
  const {
    message,
    query,
    queryPositions,
    queryPositionsLoadMore,
  } = this.props;

  return (
    <div className={style.AuditLog}>
      <div className={style.DataRetentionPolicy}>
        <h2>
          <Message
            id="Data retention policy"
            comment="Heading for data retention policy in audit log component."
          />
        </h2>
        <p>
          <Message
            id="Audit log entries will be stored for 18 months and then automatically removed."
            comment="Paragraph right above the actual audit log entries."
          />
        </p>
      </div>
      <TableFiltering
        filters={[
          {
            type: 'dropdown',
            name: 'actorPositionId',
            searchable: true,
            options: this.getUserFilterOptions(),
            onInputChange: (q) => {
              this.debouncedExecuteQuery(q, queryPositions);
            },
            loadMoreItems: queryPositionsLoadMore,
          },
          {
            type: 'dropdown',
            name: 'type',
            searchable: false,
            options: this.getEventTypeOptions(),
          },
        ]}
        onFilterHandler={this.onFilter}
        loading={query.loading}
      />
      <Table
        config={this.getTableConfig()}
        query={query}
        emptyState={this.renderEmptyState()}
      />
      <Pagination
        totalItems={query.count}
        itemsPerPage={query.pagination.limit}
        currentOffset={query.pagination.offset}
        onChange={this.handlePageChange}
        entityName={message({
          id: 'events',
          comment: 'Plural form in pagination of audit log events.',
        })}
      />
    </div>
  );
}
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Audit log',
    comment: 'The page title',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(AuditLogComponent);
