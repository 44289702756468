// @flow

import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import times from 'lodash/times';
import omit from 'lodash/omit';
import { useSlate } from 'slate-react';

import clsx from 'clsx';
import { useRichTextProps } from 'contexts/rich-text';
import { useContractProps } from 'contexts/contract-props';
import TableToolbar from 'components/rich-text-editor-toolbars/table-toolbar';
import useFocusedEditor from 'hooks/rich-text-editor/use-focused-editor';

import { getStyle } from '../../utils';
import tableStyle from './table.module.scss';
import { getTableColumnCount } from '../../../editor-plugins/table-utils/block-matrix';
import { TableElementProvider } from './use-table-element';
import useActiveTable from './use-active-table';

const IGNORED_STYLES = ['width'];

const getTableColumns = (element: Element) => (
  times(getTableColumnCount(element))
    .map((index) => {
      if (isEmpty(element.columns)) {
        return {};
      }

      if (element.columns[index]) {
        return element.columns[index];
      }

      return {};
    })
);

const TableColGroup = ({
  tableElement,
  colgroupRef,
}: { tableElement: any, colgroupRef?: any }) => {
  const columns = getTableColumns(tableElement);

  return (
    <colgroup ref={colgroupRef}>
      {columns.map((columnProps, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <col key={index} {...columnProps} />
      ))}
    </colgroup>
  );
};

const EditableTable = ({ attributes, children, element }: RenderSlateElementProps) => {
  const style = omit(getStyle(element), IGNORED_STYLES);
  const editor = useSlate();
  const focusedEditor = useFocusedEditor();
  const activeTable = useActiveTable(editor);
  const { setTableToolbarOpen } = useContractProps();
  const colgroupRef = React.useRef(null);

  const hiddenTableBordersClassName = clsx(tableStyle.EditableTable, {
    [tableStyle.HiddenBordersTable]: element.borders === 'hidden',
    [tableStyle.ShowBordersTable]: element.borders === 'visible',
  });

  let toolbar = null;
  if (editor === focusedEditor && activeTable === element) {
    setTableToolbarOpen(true);
    toolbar = (
      <TableToolbar />
    );
  } else {
    setTableToolbarOpen(false);
  }

  return (
    <div
      className={tableStyle.TableContainer}
      {...attributes}
    >
      {toolbar}
      <table
        className={hiddenTableBordersClassName}
        style={style}
      >
        <TableColGroup tableElement={element} colgroupRef={colgroupRef} />
        <TableElementProvider
          element={element}
          colgroupRef={colgroupRef}
        >
          {children}
        </TableElementProvider>
      </table>
    </div>
  );
};

const Table = ({ attributes, children, element }: RenderSlateElementProps) => {
  const { isReadOnly } = useRichTextProps();
  const style = omit(getStyle(element), IGNORED_STYLES);
  const hiddenTableBordersClassName = clsx({
    [tableStyle.HiddenBordersTable]: element.borders === 'hidden',
    [tableStyle.ShowBordersTable]: element.borders === 'visible',
  });

  if (!isReadOnly) {
    return (
      <EditableTable
        attributes={attributes}
        element={element}
      >
        {children}
      </EditableTable>
    );
  }

  return (
    <table
      {...attributes}
      className={hiddenTableBordersClassName}
      style={style}
    >
      <TableColGroup tableElement={element} />
      {children}
    </table>
  );
};

export default Table;
