import {
  useCallback,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as Tabs from '@radix-ui/react-tabs';
import clsx from 'clsx';

import {
  CONTENT_TAB,
  PARTICIPANTS_TAB,
  MESSAGES_TAB,
  AUDIT_TRAIL_TAB,
  MORE_OPTIONS_TAB,
  SETTINGS_TAB,
  DATA_FIELDS_TAB,
} from 'agreement/constants';
import { getGuestToken } from 'agreement/selectors';
import {
  getAgreementSidebarActiveTabNameSelector,
  setAgreementSidebarActiveTabName,
} from 'reducers/app';
import extensionsReducer from 'reducers/entities/extensions';
import useCurrentContractId from 'hooks/use-current-contract-id';
import { useFetchTemplateGroups } from 'hooks/use-template-groups';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import { KEY_TEMPLATE_GROUPS } from 'extensions';

import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import { useExpandableSidebarProps } from 'components/document-layout-container/expanded-document-layout/context';
import AuditTrailIcon from 'components/icons/audit-trail';
import ContentTabIcon from 'components/icons/content-tab';
import CogIcon from 'components/icons/cog';
import MoreOptionsIcons from 'components/icons/new-more-options';
import ParticipantsIcon from 'components/icons/new-participants';
import TypeCursorIcon from 'components/icons/type-cursor';
import CollaborationsIcon from 'components/icons/collaborations';

import { ContentTab } from 'components/document-tabs/content-tab/content-tab';
import MoreOptionsTab from 'components/document-tabs/more-options-tab';
import PartiesTab from 'components/document-tabs/parties-tab/parties-tab';
import AuditTrailTab from 'components/document-tabs/audit-trail-tab/audit-trail-tab';
import MessagesTab from 'components/document-tabs/messages-tab/messages-tab';
import DataFieldsTabContainer from 'components/document-tabs/data-fields-tab';
import SettingsTab from 'components/document-tabs/settings-tab';
import CountBadge from 'components/document-tabs/count-badge';
import Tooltip from 'components/tooltip';
import Message from 'components/message';

import { useDocumentSidebarPermissions } from './use-document-sidebar-permissions';

import style from './expanded-layout-sidebar.module.scss';

export const ExpandedLayoutSidebar = () => {
  const {
    expandedSidebar,
    setExpandedSidebar,
  } = useExpandableSidebarProps();
  const {
    newMessagesCount,
    shouldFadeOutCountBadge,
    newAuditTrailCount,
    documentContentWrapperNode,
  } = useDocumentLayout();
  const { canSeeContentTab, canSeeDataFieldsTab } = useDocumentSidebarPermissions();
  const dispatch = useDispatch();
  const lastClickedTab = useRef<string | null>(null);
  const deActivateTabTimeout = useRef<NodeJS.Timer | null>(null);
  const activeTabName = useSelector(getAgreementSidebarActiveTabNameSelector);
  const guestToken = useSelector(getGuestToken);
  const agreementId = useCurrentContractId() as number;
  const extension = useSelector((state) => (
    extensionsReducer.getExtensionSelector(state, { id: KEY_TEMPLATE_GROUPS })
  ));

  const isGuest = Boolean(guestToken);
  const ownerTabValue = isGuest ? MORE_OPTIONS_TAB : SETTINGS_TAB;
  const isInPreviewMode = useIsInPreviewMode();

  const setActiveTabName = (tabName: Parameters<typeof setAgreementSidebarActiveTabName>[0]) => {
    if (deActivateTabTimeout.current) {
      clearTimeout(deActivateTabTimeout.current);
    }
    dispatch(setAgreementSidebarActiveTabName(tabName));
  };

  const resetActiveTab = useCallback(() => {
    deActivateTabTimeout.current = setTimeout(() => {
      dispatch(setAgreementSidebarActiveTabName(''));
    }, 400);
  }, [dispatch]);

  useFetchTemplateGroups(extension);

  const handleClick = useCallback((clickedTab: string) => {
    const shouldExpandSidebar = lastClickedTab.current !== activeTabName || !expandedSidebar;
    setExpandedSidebar(shouldExpandSidebar);

    lastClickedTab.current = clickedTab;

    if (!shouldExpandSidebar) {
      resetActiveTab();
    }
  }, [activeTabName, expandedSidebar, resetActiveTab, setExpandedSidebar]);

  const handleCloseTab = useCallback(() => {
    setExpandedSidebar(false);
    resetActiveTab();
  }, [resetActiveTab, setExpandedSidebar]);

  const getTabsTriggerClasses = (tabName: string) => {
    const isActive = activeTabName === tabName;
    return clsx(style.ListIcon, style.TriggerContainer, {
      [style.ActiveTabTrigger]: isActive && expandedSidebar,
    });
  };

  const contentContainerClasses = clsx(style.TabContentContainer, {
    [style.SidebarExpanded]: expandedSidebar,
    [style.SidebarHidden]: !expandedSidebar,
  });

  return (
    <div className={style.SidebarContainer} data-testid="sidebar-container" data-is-expanded={expandedSidebar}>
      <Tabs.Root value={activeTabName} onValueChange={setActiveTabName as (value: string) => void} orientation="vertical" className={style.TabsRoot}>
        <>
          {documentContentWrapperNode && createPortal((
            <div className={style.TabsToolbar} data-testid="tabs-toolbar-wrapper">
              <Tabs.List className={style.TabsList}>
                <Tooltip
                  message={(
                    <Message id="Participants" comment="Tab title" />
                  )}
                  theme="oneflow"
                  side="left"
                  sideOffset={11.5}
                  contentClassName={style.TabsTriggerTooltip}
                  zIndex="10001"
                >
                  <Tabs.Trigger
                    value={PARTICIPANTS_TAB}
                    className={getTabsTriggerClasses(PARTICIPANTS_TAB)}
                    onClick={() => handleClick(PARTICIPANTS_TAB)}
                    aria-label="participants tab trigger"
                  >
                    <ParticipantsIcon height="24px" className={style.Icon} />
                  </Tabs.Trigger>
                </Tooltip>
                {canSeeContentTab && (
                  <Tooltip
                    message={(
                      <Message id="Content" comment="Tab title" />
                    )}
                    theme="oneflow"
                    side="left"
                    sideOffset={11.5}
                    contentClassName={style.TabsTriggerTooltip}
                    zIndex="10001"
                  >
                    <Tabs.Trigger
                      value={CONTENT_TAB}
                      className={getTabsTriggerClasses(CONTENT_TAB)}
                      onClick={() => handleClick(CONTENT_TAB)}
                      aria-label="content tab trigger"
                    >
                      <ContentTabIcon height="24px" className={style.Icon} />
                    </Tabs.Trigger>
                  </Tooltip>
                )}
                <Tooltip
                  triggerClassName="collaboration-trigger"
                  contentClassName={style.TabsTriggerTooltip}
                  message={(
                    isInPreviewMode ? (
                      <Message
                        id="Not available in preview"
                        comment="Title for the warning message when signing is not available in preview mode."
                      />
                    ) : <Message id="Collaboration" comment="Tab title" />
                  )}
                  side="left"
                  sideOffset={11.5}
                  theme="oneflow"
                  zIndex="10001"
                >
                  <Tabs.Trigger
                    value={MESSAGES_TAB}
                    className={getTabsTriggerClasses(MESSAGES_TAB)}
                    onClick={() => handleClick(MESSAGES_TAB)}
                    disabled={isInPreviewMode}
                    aria-label="collaboration tab trigger"
                  >
                    <CollaborationsIcon height="24px" className={style.Icon} />
                    {!isInPreviewMode && (
                      <CountBadge fadeOut={shouldFadeOutCountBadge} count={newMessagesCount} />
                    )}
                  </Tabs.Trigger>
                </Tooltip>
                <Tooltip
                  message={(
                    isInPreviewMode ? (
                      <Message
                        id="Not available in preview"
                        comment="Title for the warning message when signing is not available in preview mode."
                      />
                    ) : <Message id="Audit trail" comment="Tab title" />
                  )}
                  theme="oneflow"
                  side="left"
                  sideOffset={11.5}
                  contentClassName={style.TabsTriggerTooltip}
                  zIndex="10001"
                >
                  <Tabs.Trigger
                    value={AUDIT_TRAIL_TAB}
                    className={getTabsTriggerClasses(AUDIT_TRAIL_TAB)}
                    onClick={() => handleClick(AUDIT_TRAIL_TAB)}
                    disabled={isInPreviewMode}
                    aria-label="audit trail tab trigger"
                  >
                    <AuditTrailIcon height="24px" className={style.Icon} />
                    {!isInPreviewMode && (
                      <CountBadge fadeOut={shouldFadeOutCountBadge} count={newAuditTrailCount} />
                    )}
                  </Tabs.Trigger>
                </Tooltip>
                {canSeeDataFieldsTab && (
                  <Tooltip
                    message={(
                      <Message id="Data Fields" comment="Tab title" />
                    )}
                    theme="oneflow"
                    side="left"
                    sideOffset={11.5}
                    contentClassName={style.TabsTriggerTooltip}
                  >
                    <Tabs.Trigger
                      value={DATA_FIELDS_TAB}
                      className={getTabsTriggerClasses(DATA_FIELDS_TAB)}
                      onClick={() => handleClick(DATA_FIELDS_TAB)}
                      aria-label="data fields tab trigger"
                    >
                      <TypeCursorIcon height="24px" className={style.Icon} />
                    </Tabs.Trigger>
                  </Tooltip>
                )}
                <Tooltip
                  triggerClassName={!isGuest ? 'settings-trigger' : undefined}
                  contentClassName={style.TabsTriggerTooltip}
                  message={isGuest ? (
                    <Message
                      id="More options"
                      comment="Tab title"
                    />
                  ) : (
                    <Message
                      id="Settings"
                      comment="Tab title"
                    />
                  )}
                  side="left"
                  sideOffset={11.5}
                  theme="oneflow"
                  zIndex="10001"
                >
                  <Tabs.Trigger
                    value={ownerTabValue}
                    className={getTabsTriggerClasses(ownerTabValue)}
                    onClick={() => handleClick(ownerTabValue)}
                    aria-label={isGuest ? 'more options tab trigger' : 'settings tab trigger'}
                  >
                    {isGuest ? <MoreOptionsIcons height="24px" className={style.Icon} /> : <CogIcon height="20px" className={style.Icon} />}
                  </Tabs.Trigger>
                </Tooltip>
              </Tabs.List>
            </div>
          ), documentContentWrapperNode)}
        </>
        <div className={contentContainerClasses}>
          <div className={style.Content}>
            <Tabs.Content
              value={PARTICIPANTS_TAB}
              className={style.TabContainer}
              tabIndex={-1}
            >
              <PartiesTab onClose={handleCloseTab} />
            </Tabs.Content>
            {canSeeContentTab && (
              <Tabs.Content
                value={CONTENT_TAB}
                className={style.TabContainer}
              >
                <ContentTab onClose={handleCloseTab} />
              </Tabs.Content>
            )}
            <Tabs.Content
              value={MESSAGES_TAB}
              className={clsx(style.TabContainer, style.MessagesTabContainer)}
              tabIndex={-1}
            >
              <MessagesTab agreementId={agreementId} onClose={handleCloseTab} />
            </Tabs.Content>
            <Tabs.Content
              value={AUDIT_TRAIL_TAB}
              className={style.TabContainer}
              tabIndex={-1}
            >
              <AuditTrailTab agreementId={agreementId} onClose={handleCloseTab} />
            </Tabs.Content>
            {canSeeDataFieldsTab && (
              <Tabs.Content
                value={DATA_FIELDS_TAB}
                className={style.TabContainer}
                tabIndex={-1}
              >
                <DataFieldsTabContainer
                  agreementId={agreementId}
                  onClose={handleCloseTab}
                />
              </Tabs.Content>
            )}
            <Tabs.Content
              value={SETTINGS_TAB}
              className={style.TabContainer}
              tabIndex={-1}
            >
              <SettingsTab agreementId={agreementId} onClose={handleCloseTab} />
            </Tabs.Content>
            <Tabs.Content
              value={MORE_OPTIONS_TAB}
              className={style.TabContainer}
              tabIndex={-1}
            >
              <MoreOptionsTab agreementId={agreementId} onClose={handleCloseTab} />
            </Tabs.Content>
          </div>
        </div>
      </Tabs.Root>
    </div>
  );
};
