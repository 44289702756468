// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import VideoSizeSmallIcon from 'components/icons/video-size-small';
import VideoSizeFullWidthIcon from 'components/icons/video-size-full-width';
import VideoSizeLargeIcon from 'components/icons/video-size-large';
import ToolbarButton from 'components/rich-text-editor-toolbars/toolbar-buttons/toolbar-button.jsx';

type Props = {
  onTrigger: Function,
  disabled: boolean,
  isActive: boolean,
  size: 'sm' | 'lg' | 'full',
}

export const getAttributes = (size) => {
  if (size === 'sm') {
    return {
      label: (
        <Message
          id="Small"
          comment="The label of the video's toolbar that changes the width of video to small width."
        />
      ),
      icon: VideoSizeSmallIcon,
    };
  }
  if (size === 'full') {
    return {
      label: (
        <Message
          id="Full width"
          comment="The label of the video's toolbar that change the width of video to full width."
        />
      ),
      icon: VideoSizeFullWidthIcon,
    };
  }
  return {
    label: (
      <Message
        id="Large"
        comment="The label of the video's toolbar that change the width of video to large width."
      />
    ),
    icon: VideoSizeLargeIcon,
  };
};

const VideoSizeButton = ({
  size,
  disabled,
  onTrigger,
  isActive,
}: Props) => (
  <ToolbarButton
    label={getAttributes(size).label}
    isMenuItem={false}
    onTrigger={() => onTrigger(size)}
    icon={getAttributes(size).icon}
    disabled={disabled}
    isActive={isActive}
  />
);

export default VideoSizeButton;
