import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import agreementsReducer from 'reducers/entities/agreements';

import { ACTION_STATUS_RUNNING } from 'agreement/actions/constants';
import {
  changeImpliesApproverIsMissingSignatory,
  maxAvailableBlockIndex,
  getParticipantType,
  hasPendingStateApprovers,
} from 'agreement/pending-state-flow';

import ChevronDownIcon from 'components/icons/chevron-down';
import SelectField from 'components/select-field';
import { UpdatingSignOrderModal } from 'components/modals/updating-sign-order';

import style from './participant-sign-order.module.scss';

type Props = {
  participant: Oneflow.Participant,
  agreement: Oneflow.Agreement,
  isEditable: boolean,
  signOrder: number,
};

const ParticipantSignOrderComponent = ({
  participant,
  agreement,
  isEditable,
  signOrder,
}: Props) => {
  const [selected, setSelected] = useState(signOrder);
  const [currentValue, setCurrentValue] = useState(signOrder);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelected(signOrder);
  }, [signOrder]);

  const getSignOrderOptions = () => {
    const maxSignOrderOption = maxAvailableBlockIndex(agreement);
    const currentRunningOrder = agreement.pendingStateFlow?.runningBlock?.config.blockIndex || 1;

    const options = Array.from({ length: maxSignOrderOption }, (_, i) => ({
      label: i + 1,
      value: i + 1,
    }));

    if (agreement.pendingStateFlow?.status === ACTION_STATUS_RUNNING) {
      return options.filter((option) => option.label >= currentRunningOrder);
    }

    return options;
  };

  const updateParticipant = (params) => dispatch(
    agreementsReducer.updateParticipant({
      id: participant.id,
      data: {
        ...params,
      },
    }),
  );

  const onPendingStateSignOrderChange = (value: number) => {
    const pendingStateFlowId = agreement.config?.signOrder
      ? agreement.pendingStateFlow.id
      : null;
    const signOrderBlockIndex = value || 1;
    const participantType = getParticipantType(participant);

    updateParticipant({
      agreement: agreement.id,
      type: participantType,
      pendingStateFlowId,
      signOrderBlockIndex,
    });
  };

  const onChange = (item) => {
    const noSignatoryAfterApprover = changeImpliesApproverIsMissingSignatory(
      agreement, participant, item.value,
    );

    const { value } = item;

    if (hasPendingStateApprovers(agreement)
      && noSignatoryAfterApprover) {
      setSelected(value);
      setIsWarningModalOpen(true);
    } else {
      setSelected(value);
      setCurrentValue(value);
      onPendingStateSignOrderChange(value);
    }
  };

  const cancelChange = () => {
    setSelected(currentValue);
  };

  const confirmChange = () => {
    setCurrentValue(selected);
    setIsWarningModalOpen(false);
    onPendingStateSignOrderChange(selected);
  };

  const renderDropdownIndicator = () => <ChevronDownIcon height="10px" />;

  const renderEditable = () => {
    const input = {
      onChange,
      name: 'signorder',
      value: selected,
    };
    return (
      <SelectField
        options={getSignOrderOptions()}
        input={input}
        components={{
          DropdownIndicator: renderDropdownIndicator,
        }}
        searchable={false}
        multi={false}
        hideErrorElement
      />
    );
  };

  if (!isEditable) {
    return null;
  }

  return (
    <>
      <div className={style.ParticipantSignOrder}>
        {renderEditable()}
      </div>
      <UpdatingSignOrderModal
        isUpdatingSignOrderModalOpen={isWarningModalOpen}
        setIsUpdatingSignOrderModalOpen={setIsWarningModalOpen}
        cancelChange={cancelChange}
        confirmChange={confirmChange}
      />
    </>
  );
};

export default ParticipantSignOrderComponent;
