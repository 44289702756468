// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import positionMessageTemplates from 'reducers/entities/position-message-templates';

import { DeleteMessageTemplates, type Props } from './delete-message-templates';

const QUERY_NAME = 'user/templates';

export const requestName = 'bulk';

type StateProps = {|
  removeState: RemoveState,
|};

type OwnProps = $Diff<Props, StateProps>;

type DispatchProps = {|
  resetRemoveState: () => void,
  deleteMessageTemplates: () => void,
|};

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  removeState: positionMessageTemplates.getRemoveSelector(state, { id: requestName }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  selectedMessageTemplates,
}) => ({
  resetRemoveState: () => {
    dispatch(positionMessageTemplates.removePositionMessageTemplateReset({
      id: requestName,
    }));
  },
  deleteMessageTemplates: () => {
    const templateIds = selectedMessageTemplates.map((messageTemplate) => messageTemplate.id);

    dispatch(positionMessageTemplates.removePositionMessageTemplate({
      id: requestName,
      data: {
        ids: templateIds,
      },
      pipe: {
        action: () => positionMessageTemplates.queryPositionMessageTemplatesReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteMessageTemplates);
