// @flow

import React from 'react';

import extensionSettings from 'hocs/extension-settings';
import GeneratedToken from 'components/extension/generated-token';
import { ExtensionGenericInstructions } from 'components/extension/extension-generic-instructions';
import ExtensionSubdomain from 'components/extension/extension-subdomain';
import { ExtensionConfigurationHeader } from 'components/extension/extension-configuration-header';

type Props = {
  extension: Extension,
  updateExtension: Extension => void,
  updateState: UpdateState,
  resetUpdateState: () => void,
};

const url = {
  prefix: 'https://',
  suffix: '.membrain.com/Integration/Oneflow/Webhook/',
};

export const MembrainPage = ({
  extension,
  updateState,
  updateExtension,
  resetUpdateState,
}: Props) => (
  <>
    <GeneratedToken extension={extension} />

    <ExtensionGenericInstructions extension={extension} />

    <ExtensionConfigurationHeader />

    <ExtensionSubdomain
      extension={extension}
      url={url}
      updateExtension={updateExtension}
      updateState={updateState}
      resetUpdateState={resetUpdateState}
      configAttribute="customerInstanceSubdomain"
    />
  </>
);

export default extensionSettings(MembrainPage);
