// @flow

import React from 'react';

type Props = {
  ping: (positionId: number) => void,
  positionId: number,
  isGhost: boolean,
};

type State = {
  intervalId: IntervalID | null
};

class SessionPing extends React.PureComponent<Props, State> {
  state = {
    intervalId: null,
  };

  componentDidMount() {
    const { positionId } = this.props;

    if (positionId) {
      this.initPing(positionId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { positionId } = this.props;

    if (positionId !== prevProps.positionId) {
      this.clearPing();
      this.initPing(positionId);
    }
  }

  componentWillUnmount() {
    this.clearPing();
  }

  clearPing() {
    const { intervalId } = this.state;

    if (intervalId) {
      clearInterval(intervalId);
    }
  }

  initPing(positionId: number) {
    const interval = this.props.isGhost ? 5000 : 60000;
    const intervalId = setInterval(() => {
      this.props.ping(positionId);
    }, interval);

    this.setState({ intervalId });
  }

  render() {
    return null;
  }
}

export default SessionPing;
