// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { Message } from '@oneflowab/pomes';

import {
  getPositionFromSessionSelector,
} from 'reducers/session';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import agreementsReducer from 'reducers/entities/agreements';

import toast from 'components/toasts';
import SendContract, { type Props, type FormData } from './send-contract';

type StateProps = {|
  agreement: Agreement,
  position: Position,
  workspace: Workspace,
  rpcState: RpcState,
|}

type DispatchProps = {|
  resetRpcState: () => void,
  publishAgreement: FormData => void,
|};

type OwnProps = {|
  agreementId: number,
  onSuccess: Function,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (
  state: State,
  { agreementId }: OwnProps,
) => {
  const rpcState = agreementsReducer.getPublishAgreementSelector(state, { id: agreementId });
  const agreement = agreementsReducer.getAgreementSelector(state, { id: agreementId });

  return {
    agreement,
    rpcState,
    position: getPositionFromSessionSelector(state),
    workspace: getCurrentWorkspaceSelector(state),
  };
};

export const onSuccessToast = () => (
  toast.success({
    id: 'send-contract-success',
    title: (
      <Message
        id="Successfully sent"
        comment="Title for the toast that appears when a contract is successfully sent."
      />
    ),
    description: (
      <Message
        id="The document was sent to participants"
        comment="Description for the toast that appears when a contract is successfully sent."
      />
    ),
    duration: 5000,
  })
);

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  agreementId,
}) => ({
  resetRpcState: () => {
    dispatch(agreementsReducer.publishAgreementReset({ id: agreementId }));
  },
  publishAgreement: (formData: FormData) => {
    dispatch(agreementsReducer.publishAgreement({
      id: agreementId,
      data: {
        subject: formData.subject,
        message: formData.body,
      },
      pipe: {
        onSuccess: onSuccessToast,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(SendContract);
