// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import type { Match } from 'react-router';
import urlJoin from 'url-join';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import clsx from 'clsx';

import adminPage from 'hocs/admin-page';

import { DeleteBulkAction } from 'components/bulk-action-items/delete';
import { Acl, checkAcl } from 'components/acl';
import Button from 'components/button';
import ActionBar from 'components/action-bar';
import ActionsMenu from 'components/actions-menu';
import Country from 'components/countries';
import { DeleteMenuItem } from 'components/menu-items/delete';
import Pagination from 'components/pagination';
import { Table, TableFiltering } from 'components/table';
import Add from 'components/icons/add';
import NoContactsIcon from 'components/icons/no-contacts';
import SmallAdd from 'components/icons/small-add';
import { HelpCenterLink } from 'components/help-center-link';
import { EditMenuItem } from 'components/menu-items/edit';

import ContactPage from 'routes/address-book/contacts/contact';
import { RemoveContact } from 'routes/address-book/contacts/actions/remove-contact';
import AddContact from 'components/modals/add-contact';
import { DeleteContacts } from 'components/modals/delete-contacts';

import style from './contacts.module.scss';

type Props = {
  contacts: Array<Contact>,
  contactsQuery: Query,
  queryName: string,
  queryContacts: QueryFunc,
  match: Match,
  message: MessageTranslator,
  currentWorkspace: Workspace,
  lang: EnabledLanguages,
  queryContactsReload: () => void,
  editLinkPath: (url: string, contactId: number) => void,
};

type State = {
  selectedContacts: Array<Contact>,
}

export class ContactsList extends React.Component<Props, State> {
  state = {
    selectedContacts: [],
  };

  componentDidMount() {
    const { queryContacts } = this.props;

    queryContacts({});
  }

  componentDidUpdate(prevProps: Props) {
    const { currentWorkspace } = this.props;
    if (prevProps.currentWorkspace.id !== currentWorkspace.id) {
      this.props.queryContactsReload();
    }
  }

  onPaginate = ({ offset, limit }: Pagination) => {
    const { queryContacts, contactsQuery } = this.props;

    queryContacts({
      params: contactsQuery.params,
      pagination: {
        offset,
        limit,
      },
    });
  }

  onFilter = (params: QueryFuncArgs) => {
    const { queryContacts } = this.props;

    queryContacts({
      params: {
        ...params,
      },
    });
  };

  getAddContactTextButton = (onClick: () => void) => (
    <Button
      kind="linkInline"
      customClass={clsx(style.AddButton, style.ActionLink)}
      onClick={onClick}
      icon={<SmallAdd height="10px" />}
    >
      <Message
        id="Add contact"
        comment="Action link label for adding a contact in the address book"
      />
    </Button>
  );

  getAddContactButton = (onClick: () => void) => (
    <Button
      data-testid="add-address-book-contact"
      icon={Add}
      kind="primary"
      onClick={onClick}
    >
      <Message
        id="Add contact"
        comment="Used in the main add contact to address book button"
      />
    </Button>
  );

  getEmptyState = () => ({
    header: (
      <Message
        id="There are no contacts in the address book."
        comment="Table empty state for empty address book."
      />
    ),
    icon: <NoContactsIcon height="33px" />,
    content: (
      <div>
        <Message
          id="Click {addContactButton} to add a contact to the address book in this workspace."
          comment="Empty state text in the contact list in the address book."
          values={{
            addContactButton: (
              <AddContact>
                {this.getAddContactTextButton}
              </AddContact>
            ),
          }}
        />
        <br />
        <HelpCenterLink path="support/solutions/articles/77000436003-address-book" />
      </div>
    ),
    showEmptyState: this.shouldShowEmptyState(),
  });

  getContact = (contact: Contact) => {
    const { match } = this.props;

    return (
      <div className={style.MainRow}>
        <Link
          to={urlJoin(match.url, String(contact.id))}
          data-testid={`address_book_contact_${contact.id}`}
        >
          {contact.fullname}
        </Link>
        <div className={style.SubRow}>
          {contact.email}
        </div>
      </div>
    );
  };

  getTitle = (contact: Contact) => (
    <div className={style.MainRow}>
      {contact.title}
    </div>
  );

  getCompany = (contact: Contact) => (
    <div className={style.MainRow}>
      {contact.companyName}
      <div className={style.SubRow}>
        {contact.companyOrgnr}
      </div>
    </div>
  );

  getCountry = (contact: Contact) => {
    const { lang } = this.props;

    return (
      <Country
        country={contact.country}
        lang={lang}
      />
    );
  };

  getDeleteMenuItem = (contact: Contact) => (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!checkAcl(contact.acl, 'addressbook:remove')}
    />
  );

  handleOnClick = (id: number) => () => {
    const { match } = this.props;
    return this.props.editLinkPath(match.url, id);
  }

  getActions = (contact: Contact) => {
    const { queryName } = this.props;
    const canEdit = checkAcl(
      contact.acl,
      [
        'addressbook:update:fullname',
        'addressbook:update:title',
        'addressbook:update:email',
        'addressbook:update:ssn',
        'addressbook:update:phone_number',
        'addressbook:update:company_name',
        'addressbook:update:company_orgnr',
        'addressbook:update:country',
        'addressbook:update:notes',
      ],
      { match: 'any' },
    );

    return (
      <ActionsMenu
        actions={[
          <EditMenuItem
            disabled={!canEdit}
            onClick={this.handleOnClick(contact.id)}
          />,
          <RemoveContact contact={contact} queryName={queryName}>
            {this.getDeleteMenuItem(contact)}
          </RemoveContact>,
        ]}
      />
    );
  };

  getDeleteBulkAction = (onClick: () => void) => {
    const { selectedContacts } = this.state;

    const hasRemovePermission = selectedContacts.every((item) => (
      checkAcl(item.acl, 'addressbook:remove')
    ));

    return (
      <DeleteBulkAction
        disabled={!hasRemovePermission}
        onClick={onClick}
      />
    );
  }

  getTableConfig() {
    const { message, contacts, queryName } = this.props;
    const { selectedContacts } = this.state;

    const selectedContactIds = selectedContacts.map((contact) => contact.id);

    const actions = [
      <DeleteContacts
        queryName={queryName}
        ids={selectedContactIds}
        key="remove"
      >
        {this.getDeleteBulkAction}
      </DeleteContacts>,
    ];

    return {
      items: contacts,
      itemKey: 'id',
      actions,
      columns: [
        {
          name: 'contact',
          label: message({ id: 'Contact', comment: 'Column label' }),
          type: 'cell',
          value: this.getContact,
        },
        {
          name: 'title',
          label: message({ id: 'Title', comment: 'Column label' }),
          value: this.getTitle,
        },
        {
          name: 'company',
          label: message({ id: 'Company', comment: 'Column label' }),
          type: 'cell',
          value: this.getCompany,
        },
        {
          name: 'country',
          label: message({ id: 'Country', comment: 'Column label' }),
          value: this.getCountry,
        },
        {
          name: 'actions',
          label: message({ id: 'Actions', comment: 'Column label' }),
          type: 'actions',
          value: this.getActions,
        },
      ],
    };
  }

  selectionChangeHandler = (ids: Array<number>) => {
    const { contacts } = this.props;

    const selectedContacts = ids.map((id) => (
      contacts.find((contact) => contact.id === id)
    ));

    this.setState({ selectedContacts });
  }

  shouldShowEmptyState() {
    const { contactsQuery } = this.props;

    return contactsQuery.count === 0
      && !contactsQuery.loading
      && !contactsQuery.params.q;
  }

  renderFilters() {
    const { contactsQuery, message } = this.props;

    if (this.shouldShowEmptyState()) {
      return null;
    }

    return (
      <TableFiltering
        filters={[
          {
            type: 'text',
            name: 'search',
            defaultValue: '',
            queryKey: 'q',
            autoFocus: true,
            placeholder: message({
              id: 'Find contacts',
              comment: 'Placeholder in search field.',
            }),
          },
        ]}
        onFilterHandler={this.onFilter}
        loading={contactsQuery.loading}
      />
    );
  }

  renderPagination() {
    const { message, contactsQuery } = this.props;

    if (contactsQuery.loading || !contactsQuery.count) {
      return null;
    }

    return (
      <Pagination
        totalItems={contactsQuery.count}
        itemsPerPage={contactsQuery.pagination.limit}
        currentOffset={contactsQuery.pagination.offset}
        onChange={this.onPaginate}
        entityName={message({
          id: 'contacts',
          comment: 'Used as the entity name in pagination of contacts',
        })}
      />
    );
  }

  render() {
    const {
      currentWorkspace,
      contactsQuery,
    } = this.props;

    return (
      <div>
        <ActionBar collapsed>
          <ActionBar.Group>
            <Acl acl={currentWorkspace.acl} check="collection:addressbook:create">
              <AddContact>
                {this.getAddContactButton}
              </AddContact>
            </Acl>
          </ActionBar.Group>
        </ActionBar>
        {this.renderFilters()}
        <Table
          config={this.getTableConfig()}
          query={contactsQuery}
          onSelectionChanged={this.selectionChangeHandler}
          emptyState={this.getEmptyState()}
        />
        {this.renderPagination()}
      </div>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({ id: 'Contacts', comment: 'Used in the breadcrumb and title on the address book contacts page.' }),
  showAsLink: true,
  modules: [[{
    path: '/:id(\\d+)',
    title: message({ id: 'Details', comment: 'Used in the breadcrumb and title on the address book contacts page.' }),
    component: ContactPage,
  }]],
});

export default adminPage(propsMapper)(ContactsList);
