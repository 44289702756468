// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { checkAcl } from 'components/acl';
import Button from 'components/button';
import CreateContractIconArea from 'components/create-contract-icon-area';
import SmallAdd from 'components/icons/small-add';
import { TemplateLauncher } from 'components/template-launcher';

import style from './create-contract-button.module.scss';

type Props = {
  acl: Acl,
  expanded?: boolean,
  isButtonDisabled?: boolean,
  isContractListView?: boolean,
  createContract: (workspaceId) => void,
  isContractView?: boolean,
  isMobile?: boolean,
  selectedFolder?: Folder,
  workspaceId: number,
  workspaceName: string,
};

const CreateContractButton = ({
  acl,
  createContract,
  expanded,
  isButtonDisabled,
  isContractListView,
  isContractView,
  isMobile,
  selectedFolder,
  workspaceId,
  workspaceName,
}: Props) => {
  const canCreateBlank = checkAcl(acl.workspace, 'collection:agreement:create_blank');
  const canCreateFromTemplates = checkAcl(acl.workspace, 'collection:agreement:create');

  if (isContractListView) {
    if (canCreateBlank && !canCreateFromTemplates) {
      return (
        <Button
          customClass={style.CreateNewDocument}
          icon={<SmallAdd className={style.AddIcon} height="8px" />}
          kind="linkInline"
          onClick={() => createContract(workspaceId)}
        >
          <Message
            id="create new"
            comment="Workspace empty state add button."
          />
        </Button>
      );
    }

    return (
      <TemplateLauncher
        isContractListView
        selectedFolder={selectedFolder}
        workspaceName={workspaceName}
      />
    );
  }

  if (canCreateBlank && !canCreateFromTemplates) {
    return (
      <Button
        customClass={style.LaunchButton}
        data-testid="launch-button"
        icon={(
          <CreateContractIconArea
            expanded={expanded}
            isMobile={isMobile}
          />
        )}
        onClick={() => createContract(workspaceId)}
      />
    );
  }

  return (
    <TemplateLauncher
      icon={(
        <CreateContractIconArea
          expanded={expanded}
          isMobile={isMobile}
        />
      )}
      isContractView={isContractView}
      isMobile={isMobile}
      isDisabled={isButtonDisabled}
    />
  );
};

export default CreateContractButton;
