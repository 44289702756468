// @flow

import * as React from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';

import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import useWindowSize from 'hooks/use-window-size';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import { isTestEnv, isDevEnv } from 'utils/environment';
import { isDeviceWithoutHover } from 'utils/browser';

import {
  getParticipantRole,
  canSendSms,
  hasAnyDeliverySuccess,
  isDeliveryChannelVerified,
  isParticipantInOwnerParty,
  hasEmailDeliveryFailed,
  hasSMSDeliveryFailed,
  hasBounced,
  getParticipantSigningMethod,
} from 'agreement/participant';
import isExternalApprover from 'agreement/participant/is-external-approver';
import isCurrentInternalApprover from 'agreement/participant/is-current-internal-approver';
import hasParticipantApprovedDraft, { getDraftApprovalOrder } from 'agreement/draft-approval-flow';
import {
  hasPendingStateParticipantApproved,
  canEditSignOrderBlockIndex,
  hasNoExternalApproverAfterSignatory,
  hasPendingStateApprovers,
  getParticipantSignOrderBlockIndex,
} from 'agreement/pending-state-flow';
import { ACTION_GROUP_APPROVAL, ACTION_GROUP_PENDING_STATE } from 'agreement/actions/constants';
import getActionsByAgreementFlowId from 'agreement/participant/actions';
import isSignatory from 'agreement/participant/is-signatory';
import hasSigned from 'agreement/participant/has-signed';
import hasDeclined from 'agreement/participant/has-declined';
import isInternalApprover from 'agreement/participant/is-internal-approver';
import hasDeliveryChannelSms from 'agreement/participant/has-delivery-channel-sms';
import hasDeliveryChannelEmailAndSms from 'agreement/participant/has-delivery-channel-email-and-sms';

import {
  isDraft,
  isTemplate,
  isPending,
  isImport,
  hasDraftApprovalFlow,
  isDraftApprovalFlowNotStarted,
  isDraftApprovalFlowRunning,
  isDraftApprovalFlowSucceeded,
} from 'agreement';
import {
  getAgreementMyParticipant,
  getMarkedAsDeclinedParticipant,
  getMarkedAsSignedParticipant,
  isAgreementOwner,
  isSignOrderEnabled,
  isPartiallySigned,
} from 'agreement/selectors';
import { isUserLimited } from 'user';
import { ParticipantState } from 'components/participant-state';
import PencilIcon from 'components/icons/pencil';
import { DOCUMENT_COLLAPSED_LAYOUT_SIZE } from 'components/document-layout-container/helpers';

import ParticipantStatistics from 'components/contract-card/participant-statistics';
import { formatDateString, timestampToDateString } from 'date/date';
import NewCheck from 'components/icons/new-check';
import SelfLabel from 'components/self-label';
import Tooltip from 'components/tooltip';

import LabelValueSeparator from 'components/label-value-separator';
import { isMarkedAsDeclined, isMarkedAsSigned } from 'agreement/agreement';
import EditParticipant from 'components/modals/edit-participant';
import ResetApprovalsWarning from 'components/modals/reset-approvals-participant';
import ResetSignaturesWarning from 'components/modals/reset-signatures-participant';
import ResetApprovalsSignaturesWarning from 'components/modals/reset-approvals-signatures-participant';
import ParticipantSignOrder from 'components/participant-sign-order';
import ParticipantApproveOrder from 'components/participant-approve-order';
import { ParticipantInfoBox } from 'components/participant/participant-info-box';
import toast from 'components/toasts';
import { ImportSparkle } from 'components/import-sparkle/import-sparkle';

import style from './participant.module.scss';

export type Props = {|
  account: Account,
  position: Position,
  agreement: Agreement,
  participant: AgreementParticipant,
  message: MessageTranslator,
  isIndividual: boolean,
  isEditable: boolean,
  dateFormat: string,
  onSignOrderChanged: () => void,
  updatingSignOrder: boolean,
  guestToken ?: string,
  party ?: Party,
|};

const WithWindowSize = ({ render }: React.Element) => {
  const windowSize = useWindowSize();
  return render(windowSize);
};

const isGuestLinkEnabled = () => isDevEnv() || isTestEnv();

export const ParticipantComponent = ({
  account,
  agreement,
  participant,
  message,
  isIndividual,
  isEditable,
  dateFormat,
  updatingSignOrder,
  position,
  guestToken,
  party,
}: Props) => {
  const hasApprovedDraft = hasParticipantApprovedDraft(agreement, participant);
  const hasApprovedPendingState = hasPendingStateParticipantApproved(agreement, participant);

  const [resetApprovalsWarning, setResetApprovalsWarning] = React.useState(null);
  const [resetSignaturesWarning, setResetSignaturesWarning] = React.useState(null);
  const [
    resetApprovalsSignaturesWarning,
    setResetApprovalsSignaturesWarning,
  ] = React.useState(null);
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  const isInPreviewMode = useIsInPreviewMode();
  const myParticipant = getAgreementMyParticipant(agreement);
  const isMyParticipantCounterpart = myParticipant?.account === null;

  const hasDeliveryError = () => {
    const hasError = hasBounced(participant)
      || hasEmailDeliveryFailed(participant)
      || hasSMSDeliveryFailed(participant);

    return hasError;
  };

  const getDeliveryMessage = () => {
    if (!hasDeliveryError()) {
      return null;
    }

    return (
      <Message
        id="Delivery failed"
        comment="Text for a participant with a delivery error"
      />
    );
  };

  const shouldShowNoAccessMessage = () => {
    const { hasAccess } = participant;
    return hasAccess === false && isAgreementOwner(account, agreement);
  };

  const shouldShowSmsDisabledMessage = () => {
    const participantIsUsingSms = (
      hasDeliveryChannelSms(participant) || hasDeliveryChannelEmailAndSms(participant)
    );
    const participantCanSendSms = canSendSms(account, agreement);
    return isAgreementOwner(account, agreement)
      && participantIsUsingSms && !participantCanSendSms;
  };

  const shouldShowDeliveryMessage = () => (
    !hasAnyDeliverySuccess(participant) && !isDeliveryChannelVerified(participant)
  );

  const getDeliveryDate = () => {
    const date = participant.deliveryChannelStatusTimestampTs;
    const dateFormatWithTime = `${dateFormat}, HH:mm`;
    const dateString = timestampToDateString(date, dateFormat);
    const dateTimeString = timestampToDateString(date, dateFormatWithTime);

    return (
      <Tooltip
        message={dateTimeString}
        messageClassName={style.TooltipText}
        side="top"
        theme="oneflow"
      >
        <span className={style.Date}>{dateString}</span>
      </Tooltip>
    );
  };

  const renderDeliveryMessage = () => (
    <span className={style.TitleRedesign}>
      {getDeliveryMessage()}
      <LabelValueSeparator />
      {getDeliveryDate()}
    </span>
  );

  const getErrorMessage = () => {
    const messageClasses = clsx(style.TitleRedesign, {
      [style.IsImport]: isImport(agreement),
    });

    if (shouldShowNoAccessMessage()) {
      return (
        <span className={messageClasses}>
          <Message
            id="No access to document"
            comment="Text for a participant that has no access to a document in the participant list"
          />
        </span>
      );
    }

    if (shouldShowSmsDisabledMessage()) {
      return (
        <span className={messageClasses}>
          <Message
            id="SMS has been disabled"
            comment="Text for a participant that depends on SMS features and the owner account has turned off SMS for some reason"
          />
        </span>
      );
    }

    if (shouldShowDeliveryMessage()) {
      return renderDeliveryMessage();
    }

    return null;
  };

  const hasError = () => {
    const errorMessage = getErrorMessage();
    return !!errorMessage;
  };

  const participantHasMarkedAsDeclined = () => {
    const participantThatMarkedAsDeclined = getMarkedAsDeclinedParticipant(agreement);
    return isMarkedAsDeclined(agreement) && participantThatMarkedAsDeclined?.id === participant?.id;
  };

  const renderErrorMessage = () => {
    if (hasError()) {
      return getErrorMessage();
    }

    return null;
  };

  const renderDecisionMessage = () => {
    const participantStateTimestampTs = participant.stateTimestampTs;

    let actionStatusDate;
    if (hasDraftApprovalFlow(agreement)) {
      const flowId = agreement.draftApprovalFlow?.id;
      const action = head(getActionsByAgreementFlowId(participant, flowId, ACTION_GROUP_APPROVAL));
      if (action) {
        actionStatusDate = formatDateString(action.statusTime, dateFormat);
      }
    }

    if (hasPendingStateApprovers(agreement) && isExternalApprover(participant)) {
      const flowId = agreement.pendingStateFlow?.id;
      const action = head(
        getActionsByAgreementFlowId(participant, flowId, ACTION_GROUP_PENDING_STATE),
      );
      if (action) {
        actionStatusDate = formatDateString(action.statusTime, dateFormat);
      }
    }

    const agreementStateTimestampTs = agreement.stateTimestampTs;
    const participantStateDate = timestampToDateString(participantStateTimestampTs, dateFormat);
    const agreementStateDate = timestampToDateString(agreementStateTimestampTs, dateFormat);

    if (hasSigned(participant) || isMarkedAsSigned(agreement)) {
      const markedAsSignedByParticipant = getMarkedAsSignedParticipant(agreement);
      if (isMarkedAsSigned(agreement) && markedAsSignedByParticipant) {
        return (
          <Message
            id="Marked as signed by {participant} on {date}"
            comment="Participant list text displaying who marked a contract as signed and when."
            values={{
              participant: markedAsSignedByParticipant.fullname,
              date: agreementStateDate,
            }}
          />
        );
      }

      const signMethod = getParticipantSigningMethod(message, participant);
      const signMethodText = get(signMethod, 'short');
      return (
        <Message
          id="Signed with {method} {date}"
          comment="Participant list text displaying date and signing method a participant used."
          values={{
            method: signMethodText,
            date: participantStateDate,
          }}
        />
      );
    }

    if (participantHasMarkedAsDeclined()) {
      const participantThatMarkedAsDeclined = getMarkedAsDeclinedParticipant(agreement);
      return (
        <Message
          id="Marked as declined by {participant} on {date}"
          comment="Participant list text displaying who marked a contract as signed and when."
          values={{
            participant: participantThatMarkedAsDeclined.fullname,
            date: agreementStateDate,
          }}
        />
      );
    }

    if (hasDeclined(participant)) {
      return (
        <Message
          id="Declined {date}"
          comment="Participant list text displaying date when participant declined."
          values={{
            date: participantStateDate,
          }}
        />
      );
    }

    if (hasApprovedDraft || hasApprovedPendingState) {
      return (
        <Message
          id="Approved {date}"
          comment="Participant list text displaying date when participant approved."
          values={{
            date: actionStatusDate,
          }}
        />
      );
    }

    return null;
  };

  const renderRole = () => {
    const isOwner = isAgreementOwner(account, agreement);

    if (hasError()) {
      return null;
    }

    if (
      hasSigned(participant)
      || hasDeclined(participant)
      || (isMarkedAsSigned(agreement) && isSignatory(participant))
      || participantHasMarkedAsDeclined()
      || hasApprovedDraft
      || (hasApprovedPendingState)
    ) {
      return (
        <span className={style.DecisionMessage}>
          {renderDecisionMessage()}
        </span>
      );
    }

    if (isInternalApprover(participant) && isOwner) {
      return (
        <div className={style.TypeInfo}>
          <Message
            id="Internal approver"
            comment="Internal approver user role."
          />
        </div>
      );
    }

    return (
      <div className={style.TypeInfo}>
        {getParticipantRole(message, participant)}
      </div>
    );
  };

  const renderExternalApproverRoleInfo = () => {
    if (hasNoExternalApproverAfterSignatory(agreement, participant)
      || hasPendingStateParticipantApproved(agreement, participant)) {
      return null;
    }

    return (
      <ParticipantInfoBox
        agreement={agreement}
      />
    );
  };

  const renderDraftApproverOrder = () => {
    if (hasDeclined(participant)) {
      return null;
    }

    const isApprovalOrderEditable = (isEditable
      && (isDraftApprovalFlowNotStarted(agreement)
        || (isDraftApprovalFlowRunning(agreement) && !hasApprovedDraft
          && !isCurrentInternalApprover(agreement, participant)))
      && !isUserLimited(position));

    const approvalOrderClasses = clsx({
      [style.ApproveOrder]: isApprovalOrderEditable,
    });

    const approveOrder = getDraftApprovalOrder(agreement, participant.id);

    return (
      <div className={approvalOrderClasses}>
        <ParticipantApproveOrder
          participant={participant}
          agreement={agreement}
          isEditable={isApprovalOrderEditable}
          approveOrder={approveOrder || 1}
        />
      </div>
    );
  };

  const renderSignOrder = () => {
    if (!isSignOrderEnabled(agreement)) {
      return null;
    }

    const isSignOrderEditable = isEditable
      && ((isDraft(agreement) || isTemplate(agreement))
        || (isPending(agreement) && canEditSignOrderBlockIndex(agreement, participant.id)))
      && !isUserLimited(position) && !isMyParticipantCounterpart;

    const signOrderClasses = clsx({
      [style.SignOrder]: isSignOrderEditable,
      [style.Signed]: hasSigned(participant)
        || (isMarkedAsSigned(agreement) && isSignatory(participant)),
    });

    return (
      <div className={signOrderClasses}>
        <ParticipantSignOrder
          participant={participant}
          agreement={agreement}
          isEditable={isSignOrderEditable}
          signOrder={getParticipantSignOrderBlockIndex(agreement, participant.id)}
          updatingSignOrder={updatingSignOrder}
        />
      </div>
    );
  };

  const renderOrder = () => {
    if (isInternalApprover(participant)) {
      return renderDraftApproverOrder();
    }
    return renderSignOrder();
  };

  const renderApprovedBadge = () => (
    <div className={style.ApprovedBadge}>
      <NewCheck />
    </div>
  );

  const renderDraftApproverState = () => {
    if (!isDraftApprovalFlowRunning(agreement) && !isDraftApprovalFlowSucceeded(agreement)) {
      return null;
    }

    const participantDeliveryFailed = hasDeliveryError() || shouldShowDeliveryMessage();
    const participantHasNoAccess = shouldShowNoAccessMessage();
    const approveOrder = !isEditable || !isDraftApprovalFlowNotStarted(agreement)
      ? getDraftApprovalOrder(agreement, participant.id) : null;

    if (hasApprovedDraft && !hasDeclined(participant)) {
      return renderApprovedBadge();
    }

    if (isDraftApprovalFlowRunning(agreement) && !hasApprovedDraft
      && !isCurrentInternalApprover(agreement, participant)) {
      return null;
    }

    return (
      <ParticipantState
        participant={participant}
        agreement={agreement}
        renderEmpty={hasError()}
        isSignOrderEditable={false}
        hasNoAccess={participantHasNoAccess}
        approveOrder={approveOrder}
        hasDeliveryError={participantDeliveryFailed}
      />
    );
  };

  const renderState = () => {
    const isSignOrderEditable = isEditable
      && ((isDraft(agreement) || isTemplate(agreement))
        || (isPending(agreement) && canEditSignOrderBlockIndex(agreement, participant.id)))
      && !isUserLimited(position) && !isMyParticipantCounterpart;

    const participantHasNoAccess = shouldShowNoAccessMessage();
    const participantDeliveryFailed = hasDeliveryError() || shouldShowDeliveryMessage();

    if (isExternalApprover(participant)
      && hasApprovedPendingState
      && !hasDeclined(participant)) {
      return renderApprovedBadge();
    }

    if (isInternalApprover(participant)) {
      return renderDraftApproverState();
    }

    if (
      (!hasSigned(participant)
        && !hasDeclined(participant)
        && (!isSignatory(participant) && isSignOrderEditable)
        && !isMarkedAsSigned(agreement)
        && !isMarkedAsDeclined(agreement)
        && !isImport(agreement))
      || (!isSignatory(participant) && !participantDeliveryFailed && !participantHasNoAccess
        && !isSignOrderEnabled(agreement))
    ) {
      return null;
    }

    return (
      <ParticipantState
        participant={participant}
        agreement={agreement}
        renderEmpty={hasError()}
        isSignOrderEditable={isSignOrderEditable}
        hasNoAccess={participantHasNoAccess}
        hasDeliveryError={participantDeliveryFailed}
      />
    );
  };

  const renderEditIcon = () => {
    if (!isEditable) {
      return null;
    }
    return (
      <div className={style.Edit}>
        <PencilIcon />
      </div>
    );
  };

  const renderFullname = () => {
    const { fullname } = participant;

    const isCounterParticipant = participant.account === null;
    const canViewAsGuest = isGuestLinkEnabled() && isCounterParticipant && !guestToken;
    const nameClasses = clsx(style.Name, {
      [style.NameWithSignOrder]: isSignOrderEnabled(agreement),
      [style.EnabledPointerEvent]: canViewAsGuest,
      [style.DeclinedOrError]: !isImport(agreement)
        && (hasDeclined(participant)
          || participantHasMarkedAsDeclined()
          || hasError()),
    });

    if (canViewAsGuest) {
      /* For debugging purpose */
      return (
        <a
          className={nameClasses}
          href={`/api/internal/${agreement.id}/participants/${participant.id}/guest_login`}
        >
          {fullname}
        </a>
      );
    }

    if (participant.self === 1) {
      return (
        <span>
          <span className={nameClasses}>{fullname}</span>
          <SelfLabel />
        </span>
      );
    }
    return <span className={nameClasses}>{fullname}</span>;
  };

  const renderModalButton = (openAction) => {
    const isSignOrderEditable = (
      isEditable
      && isSignOrderEnabled(agreement)
      && isDraft(agreement)
      && !isUserLimited(position)
      && !isInternalApprover(participant)
    );

    const isApproveOrderEditable = (
      isEditable
      && isInternalApprover(participant)
      && isDraft(agreement)
      && !isUserLimited(position)
      && isDraftApprovalFlowNotStarted(agreement)
    );

    const participantClasses = clsx(style.Participant, {
      [style.HasDeclined]: !isImport(agreement)
        && (hasDeclined(participant)
          || participantHasMarkedAsDeclined()
          || hasError()),
      [style.NotEditable]: !isEditable,
      [style.NotLimitedUser]: isEditable,
      [style.SignOrderEditable]: isSignOrderEditable,
      [style.ApproveOrderEditable]: isApproveOrderEditable,
    });

    const containerClasses = clsx(style.ParticipantContainer, {
      [style.IsEditable]: isEditable,
    });

    const handleClick = (event) => {
      if (!isEditable) {
        return;
      }

      openAction(event);
    };

    return (
      <div className={containerClasses}>
        <ImportSparkle
          agreementId={agreement.id}
          type="participants"
          matchId={participant.id}
          className={style.ParticipantSparkle}
        />
        <div className={style.UpperSection}>
          <div
            className={participantClasses}
            onClick={(event) => handleClick(event)}
            role="button"
            tabIndex={0}
            onKeyDown={() => undefined}
            id="participant"
          >
            <div className={style.Info}>
              {renderFullname()}
              {renderErrorMessage()}
              {renderRole()}
            </div>
            <div className={style.RightSide} id="rightSide">
              <div className={style.ParticipantState}>
                <div className={style.State}>
                  {renderState()}
                </div>
                {renderEditIcon()}
              </div>
            </div>
          </div>
          {renderOrder()}
        </div>
        {renderExternalApproverRoleInfo()}
      </div>
    );
  };

  const callbackRef = React.useRef(null);

  const showResetToast = (resetType: 'signatures' | 'approvals' | 'approvals-signatures') => {
    if (!agreement || !party) return null;

    if ((resetType === 'signatures' || resetType === 'approvals-signatures') && !isPartiallySigned(agreement)) {
      return null;
    }

    if (resetType === 'signatures') {
      return toast.warning({
        id: 'signatures-reset',
        title: (
          <Message
            id="Signatures reset"
            comment="Title for the info message when the signatures have been reset."
          />
        ),
        description: (
          <Message
            id="The document needs to be signed again."
            comment="Description text for the info message when the signatures have been reset."
          />
        ),
        duration: 5000,
      });
    }

    if (resetType === 'approvals-signatures') {
      return toast.warning({
        id: 'approvals-reset',
        title: (
          <Message
            id="Approvals and signatures reset"
            comment="Title for the info message when the approvals have been reset."
          />
        ),
        description: (
          <Message
            id="The document needs to be approved and signed again."
            comment="Description text for the info message when the approvals have been reset."
          />
        ),
        duration: 5000,
      });
    }

    if (resetType === 'approvals') {
      return toast.warning({
        id: 'approvals-reset-only',
        title: (
          <Message
            id="Approvals reset"
            comment="Title for the info message when the approvals have been reset."
          />
        ),
        description: (
          <Message
            id="The document needs to be approved again."
            comment="Description text for the info message when the approvals have been reset."
          />
        ),
        duration: 5000,
      });
    }

    return null;
  };

  const renderParticipant = () => {
    const isColleague = account && isParticipantInOwnerParty(account, participant);

    const handleSetTooltipOpen = () => {
      // If user is on mobile device and document is not editable.
      // Show tooltip on click.
      if (isDeviceWithoutHover() && !isEditable) {
        setIsTooltipOpen((_isTooltipOpen) => !_isTooltipOpen);
      }
    };

    return (
      <div
        role="button"
        tabIndex={0}
        onKeyPress={handleSetTooltipOpen}
        onClick={handleSetTooltipOpen}
        data-testid="participant-tooltip-trigger"
      >
        <EditParticipant
          agreement={agreement}
          participant={participant}
          isIndividual={isIndividual}
          isColleague={isColleague}
          party={party}
          handleSignatureResetOnEdit={(cb, type) => {
            setResetSignaturesWarning(type);
            callbackRef.current = cb;
          }}
          handleApprovalsResetOnEdit={(cb, type) => {
            setResetApprovalsWarning(type);
            callbackRef.current = cb;
          }}
          handleApprovalsSignaturesResetOnEdit={(cb, type) => {
            setResetApprovalsSignaturesWarning(type);
            callbackRef.current = cb;
          }}
        >
          {renderModalButton}
        </EditParticipant>
        <ResetSignaturesWarning
          agreementId={agreement.id}
          isOpen={Boolean(resetSignaturesWarning)}
          onConfirm={() => {
            callbackRef.current();
            setResetSignaturesWarning(null);
            showResetToast('signatures');
          }}
          onCancel={() => setResetSignaturesWarning(null)}
          type={resetSignaturesWarning}
        />
        <ResetApprovalsWarning
          agreementId={agreement.id}
          isOpen={Boolean(resetApprovalsWarning)}
          onConfirm={() => {
            callbackRef.current();
            setResetApprovalsWarning(null);
            showResetToast('approvals');
          }}
          onCancel={() => setResetApprovalsWarning(null)}
          type={resetApprovalsWarning}
        />
        <ResetApprovalsSignaturesWarning
          agreementId={agreement.id}
          isOpen={Boolean(resetApprovalsSignaturesWarning)}
          onConfirm={() => {
            callbackRef.current();
            setResetApprovalsSignaturesWarning(null);
            showResetToast('approvals-signatures');
          }}
          onCancel={() => setResetApprovalsSignaturesWarning(null)}
          type={resetApprovalsSignaturesWarning}
        />
      </div>
    );
  };

  const render = () => {
    const hideEventLog = isEmpty(position) || isTemplate(agreement);

    if (isInPreviewMode && participant.self === 1) {
      return (
        <div className={style.PreviewParticipant}>
          {renderParticipant()}
        </div>
      );
    }

    return (
      <WithWindowSize render={(windowSize) => (
        <Tooltip
          message={(
            <ParticipantStatistics
              agreement={agreement}
              participant={participant}
              hideEventLog={hideEventLog}
              isContractView
            />
          )}
          messageClassName={style.ParticipantTooltipMessage}
          key={participant.id}
          theme="light"
          zIndex="10000"
          sideOffset={6}
          side={windowSize.width > DOCUMENT_COLLAPSED_LAYOUT_SIZE ? 'left' : 'top'}
          open={isDeviceWithoutHover() ? isTooltipOpen : undefined}
          onPointerDownOutside={() => {
            if (isDeviceWithoutHover()) {
              setIsTooltipOpen(false);
            }
          }}
        >
          {renderParticipant()}
        </Tooltip>
      )}
      />
    );
  };

  return render();
};

export default localize<Props>(ParticipantComponent);
