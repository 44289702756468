// @flow
import React, {
  useMemo, useRef, useEffect,
} from 'react';

import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import * as ScrollArea from '@radix-ui/react-scroll-area';

import {
  getMessageIdFromSessionStorage,
} from 'comments';
import useOnScreen from 'hooks/use-on-screen';
import agreements from 'reducers/entities/agreements';

import { scrollToBottom } from 'components/document-tabs/messages-tab/post-message/auto-scroll';
import SingleMessageBox from '../single-message-box';

import style from './messages-list.module.scss';

type Props = {
  agreementId: number,
  messages: Array,
  commentsTypeToRender: string,
};

const MessagesList = ({
  messages,
  agreementId,
  commentsTypeToRender,
}: Props) => {
  const agreement = useSelector((state) => (
    agreements.getAgreementSelector(state, { id: agreementId })
  ));

  const messagesListRef = useRef(null);
  const messageFromUrlRef = useRef(null);

  const isMessageSectionOnScreen = useOnScreen(messagesListRef);
  const messageId = Number(getMessageIdFromSessionStorage());

  useEffect(() => {
    if (isMessageSectionOnScreen) {
      queueMicrotask(() => {
        scrollToBottom(messagesListRef.current);
      });
    }
  }, [isMessageSectionOnScreen, commentsTypeToRender]);

  const sortedMessages = useMemo(() => {
    if (!isEmpty(agreement)) {
      const sorted = sortBy(messages, (message) => new Date(message.created));
      return sorted;
    }

    return [];
  }, [agreement, messages]);

  return (
    <ScrollArea.Root className={style.ScrollArea}>
      <ScrollArea.Viewport className={style.ScrollArea}>
        <div className={style.MessagesContainer} ref={messagesListRef}>
          {sortedMessages.map((message) => {
            const isMessageFromUrl = messageId === message.id || (
              Boolean(message.replies?.find(({ id }) => id === messageId))
            );
            return (
              <SingleMessageBox
                message={message}
                agreementId={agreementId}
                key={message.id}
                messageFromUrlRef={isMessageFromUrl ? messageFromUrlRef : null}
                isInteractive
                amplitudeData={{ location: 'collaboration menu' }}
              />
            );
          })}
        </div>
      </ScrollArea.Viewport>
    </ScrollArea.Root>
  );
};

export default MessagesList;
