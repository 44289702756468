import Svg from 'components/svg';

const SmartId = Svg((
  <>
    <defs>
      <polygon id="path-1" points="0 144.442927 185.940903 144.442927 185.940903 0 0 0" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Home" transform="translate(-95.000000, -92.000000)">
        <g id="Page-1" transform="translate(95.000000, 92.000000)">
          <path d="M0,136.418293 L4.44471572,136.418293 C4.75637458,138.907459 5.76288963,140.983314 9.96499666,140.983314 C12.7077191,140.983314 14.6523211,139.461434 14.6523211,137.177994 C14.6523211,134.896412 13.5070836,134.031989 9.47915719,133.375152 C3.54084281,132.577652 0.728448161,130.780024 0.728448161,126.144981 C0.728448161,122.098613 4.20148495,119.055472 9.37464883,119.055472 C14.7226154,119.055472 17.9163411,121.477096 18.4034247,126.215002 L14.1664816,126.215002 C13.7148562,123.620494 12.2225017,122.515024 9.37464883,122.515024 C6.56225418,122.515024 5.10411371,123.864019 5.10411371,125.763891 C5.10411371,127.772203 5.93707023,128.77481 10.2430635,129.396327 C16.0768696,130.190729 19.0964147,131.853271 19.0964147,136.799382 C19.0964147,141.086177 15.4510635,144.442865 9.96499666,144.442865 C3.19372575,144.442865 0.347117057,141.086177 0,136.418293 L0,136.418293 Z" id="Fill-1" fill="#00AFAA" />
          <polygon id="Fill-3" fill="#00AFAA" points="27.749396 119.366231 33.9303191 119.366231 40.4571151 136.245098 46.8470548 119.366231 52.9576836 119.366231 52.9576836 144.096786 48.3394094 144.096786 48.3394094 125.417812 40.9441987 144.096786 39.4506 144.096786 31.9160448 125.417812 31.9160448 144.096786 27.749396 144.096786" />
          <polygon id="Fill-5" fill="#00AFAA" points="65.2587532 144.096972 60.9173017 144.096972 69.2163853 119.366417 75.258586 119.366417 83.4531612 144.096972 78.6613284 144.096972 71.9591077 123.136417" />
          <polygon id="Fill-7" fill="#00AFAA" points="122.770824 122.998791 115.861453 122.998791 115.861453 119.366355 134.333928 119.366355 134.333928 122.998791 127.423934 122.998791 127.423934 144.09691 122.770824 144.09691" />
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="Clip-10" />
          <polygon id="Fill-9" fill="#00AFAA" mask="url(#mask-2)" points="135.241284 134.586026 144.546883 134.586026 144.546883 131.023611 135.241284 131.023611" />
          <polygon id="Fill-11" fill="#00AFAA" mask="url(#mask-2)" points="72.3684943 133.791314 78.7279525 133.791314 78.7279525 137.353729 71.2294776 137.353729" />
          <polygon id="Fill-12" fill="#00AFAA" mask="url(#mask-2)" points="150.868395 144.096538 155.521505 144.096538 155.521505 119.365983 150.868395 119.365983" />
          <path d="M105.874684,27.8591474 C105.874684,29.63075 104.432717,31.0671175 102.654209,31.0671175 C100.8757,31.0671175 99.4337338,29.63075 99.4337338,27.8591474 C99.4337338,26.0875449 100.8757,24.6511774 102.654209,24.6511774 C104.432717,24.6511774 105.874684,26.0875449 105.874684,27.8591474" id="Fill-13" fill="#00AFAA" mask="url(#mask-2)" />
          <path d="M119.245098,80.7117094 C118.459419,80.7117094 117.684937,80.3523077 117.184168,79.6737821 C116.348723,78.5422863 116.591332,76.9491453 117.728482,76.1163248 C127.816649,68.7238034 133.840188,56.8728419 133.840188,44.4158547 C133.840188,22.7309188 116.128509,5.08925214 94.3596662,5.08925214 C92.4399472,5.08925214 90.5177398,5.22805556 88.6210375,5.50380342 L88.6222816,61.1162607 C88.6222816,62.5216453 87.4782883,63.6611966 86.0674254,63.6611966 C84.6565625,63.6611966 83.5131913,62.5216453 83.5131913,61.1162607 L83.5119472,3.36226496 C83.5119472,2.14897436 84.3704087,1.10423077 85.5641679,0.866901709 C88.4487231,0.290619658 91.407305,-0.00061965812 94.3596662,-0.00061965812 C118.946502,-0.00061965812 138.949278,19.9238675 138.949278,44.4158547 C138.949278,58.4851923 132.148148,71.8685684 120.756736,80.2159829 C120.300757,80.5505983 119.770128,80.7117094 119.245098,80.7117094" id="Fill-14" fill="#00AFAA" mask="url(#mask-2)" />
          <path d="M94.3596662,88.8309658 C69.7740742,88.8309658 49.7712983,68.9064786 49.7712983,44.4157308 C49.7712983,30.3501111 56.5774054,16.968594 67.9775258,8.61932051 C69.1134321,7.78835897 70.7140274,8.03126496 71.5488502,9.16276068 C72.384295,10.2954957 72.1398201,11.8880171 71.0045358,12.7208376 C60.9082816,20.1145983 54.8803886,31.9630812 54.8803886,44.4157308 C54.8803886,66.0994274 72.5902013,83.7417137 94.3596662,83.7417137 C96.2849839,83.7417137 98.2071913,83.6022906 100.099539,83.329641 L100.098295,46.8460299 C100.098295,45.4406453 101.242288,44.3017137 102.653151,44.3017137 C104.064014,44.3017137 105.207385,45.4406453 105.207385,46.8460299 L105.209252,85.4711795 C105.209252,86.6838504 104.350168,87.7279744 103.156409,87.9671624 C100.281807,88.5403462 97.3213585,88.8309658 94.3596662,88.8309658" id="Fill-15" fill="#00AFAA" mask="url(#mask-2)" />
          <path d="M173.094897,119.366231 L165.55972,119.366231 L165.55972,144.096786 L170.178616,144.096786 L170.177994,140.46435 L170.177994,140.46435 L170.177994,122.998667 L172.852288,122.998667 C178.684228,122.998667 181.115292,126.215312 181.115292,131.539415 L181.115292,131.818261 C181.115292,136.416124 179.196817,139.369415 175.287706,140.199756 L175.287706,143.957363 C182.460215,143.130739 185.941338,138.320953 185.941338,131.78294 L185.941338,131.471872 C185.941338,124.277021 181.741098,119.366231 173.094897,119.366231" id="Fill-16" fill="#00AFAA" mask="url(#mask-2)" />
          <path d="M103.493884,133.410038 C106.584345,132.612538 109.014165,130.642645 109.014165,126.769162 L109.014165,126.6285 C109.014165,121.579526 105.195255,119.366107 99.7440241,119.366107 L91.4101043,119.366107 L91.4101043,144.096662 L96.0283786,144.096662 L96.0283786,122.928521 L99.7440241,122.928521 C102.694519,122.928521 104.466807,123.896427 104.466807,126.733842 L104.466807,126.872026 C104.466807,129.499376 102.834486,130.81553 99.7440241,130.81553 L98.9801177,130.81553 L98.9801177,134.099098 L105.473322,144.096662 L110.472305,144.096662 L103.493884,133.410038 Z" id="Fill-17" fill="#00AFAA" mask="url(#mask-2)" />
        </g>
      </g>
    </g>
  </>
), {
  viewBox: '0 0 186 145',
});

SmartId.displayName = 'SmartId';

export default SmartId;
