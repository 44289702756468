import { useDroppable } from '@dnd-kit/core';
import { MessageTranslator, localize } from '@oneflowab/pomes';
import { useDispatch, useSelector } from 'react-redux';
import { some } from 'lodash';

import { useDocumentDndContext } from 'components/document-layout-container/document-dnd-context';
import { BOX_TYPES } from 'agreement/constants';
import { getCurrentBoxes, insertBox } from 'reducers/current-contract';
import useBoxItem from 'hooks/use-box-item';
import BoxMenu from 'components/box-menu';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import getBoxTypeName from 'utils/get-box-type-name';

type Props = {
  index?: number,
  boxId?: number,
  isAlwaysVisible?: boolean,
  className?: string,
  message: MessageTranslator,
  shouldOpen?: boolean,
  onClose?: () => void,
};

const getProductColumnLabels = (message: MessageTranslator) => ({
  name: message({ id: 'Product', comment: 'Label for product name' }) as string,
  description: message({ id: 'Description', comment: 'Label for product description' }) as string,
  price_1: message({ id: 'Price 1', comment: 'Label for product price 1' }) as string,
  price_2: message({ id: 'Price 2', comment: 'Label for product price 2' }) as string,
  count: message({ id: 'Quantity', comment: 'Label for product quantity' }) as string,
});

export const BoxDivider = ({
  boxId,
  index,
  className,
  isAlwaysVisible,
  message,
  shouldOpen,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const boxes = useSelector(getCurrentBoxes);
  const { isSingle, isLast } = useBoxItem(boxId);

  const { activeDndSegmentId } = useDocumentDndContext();

  const handleAddBox = (boxType: ContractView.BoxType) => {
    if (BOX_TYPES.BOX_PRODUCT_TABLE === boxType) {
      const productColumnLabels = getProductColumnLabels(message);
      dispatch(insertBox({ index, boxType, productColumnLabels }));
    } else {
      dispatch(insertBox({ index, boxType }));
    }

    amplitudeLogEvent(
      'Add Content Section',
      {
        location: 'add content button',
        'content section type': getBoxTypeName(boxType),
      },
    );

    if (!isSingle && isLast) {
      queueMicrotask(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      });
    }
  };

  const hasDurationBox = () => (
    some(boxes, (box) => box.type === BOX_TYPES.BOX_DURATION && !box._removed)
  );

  const dropIndex = index || 0;
  const droppableId = `droppable-box-divider-${dropIndex}`;
  const {
    setNodeRef,
    over,
  } = useDroppable({
    disabled: activeDndSegmentId !== 'box_dnd',
    id: droppableId,
    data: {
      segmentId: 'box_dnd',
      dividerIndex: dropIndex,
      onDrop: handleAddBox,
    },
  });
  const isBoxDndOver = over?.id === droppableId && activeDndSegmentId === 'box_dnd';

  return (
    <div className={className} ref={setNodeRef}>
      <BoxMenu
        onAddBox={handleAddBox}
        hasDurationBox={hasDurationBox}
        isAlwaysVisible={isAlwaysVisible}
        shouldOpen={shouldOpen}
        onClose={onClose}
        isBoxDndOver={isBoxDndOver}
      />
    </div>
  );
};

BoxDivider.displayName = 'BoxDivider';

export default localize(BoxDivider);
