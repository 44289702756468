// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import libraryTemplates from 'reducers/entities/library-templates';

import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';

import TemplateLibrary, { type Props } from './template-library';

type StateProps = {|
  currentWorkspaceId: ?number,
  createState: CreateState,
  account: Account,
  position: Position,
  templates: Array<Template>,
|};

type DispatchProps = {|
  requestPublicTemplates: () => void,
  copyTemplate: (number, number, string) => void,
  resetCreateState: () => void,
|};

type OwnProps = {|
  queryName: string,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);
  const query = libraryTemplates.getQuerySelector(state, { name: 'publicTemplates' });
  const allTemplates = libraryTemplates.getLibraryTemplatesSelector(state, { ids: query.result });

  return {
    currentWorkspaceId: getCurrentWorkspaceSelector(state).id,
    createState: libraryTemplates.getCreateSelector(state),
    position: getPositionFromSessionSelector(state),
    account,
    templates: allTemplates,
  };
};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  copyTemplate: (currentWorkspaceId: number, sourceId: number, title: string) => {
    dispatch(libraryTemplates.createLibraryTemplate({
      data: {
        workspaceId: currentWorkspaceId,
        sourceId,
        name: title,
      },
      pipe: {
        onSuccess: (data) => redirectToDocumentOnDocumentCreate(data, true),
      },
    }));
  },
  requestPublicTemplates: () => {
    dispatch(libraryTemplates.queryLibraryTemplates({
      name: 'publicTemplates',
    }));
  },
  resetCreateState: () => {
    dispatch(libraryTemplates.createLibraryTemplateReset());
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateLibrary);
