import RemindMe from 'components/document-tabs/settings-tab/sections/duration-reminders/remind-me';

import style from './duration-reminders.module.scss';

type Props = { agreementId: number };

const DurationReminders = ({ agreementId }: Props) => (
  <div className={style.NormalizeMarginTopDifference}>
    <RemindMe agreementId={agreementId} />
  </div>
);

export default DurationReminders;
