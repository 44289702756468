// @flow

import { normalize, schema } from 'normalizr';

import {
  get,
  getMultiple,
  post,
  put,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

export const templateGroupSchema = new schema.Entity('templateGroups');

export type NormalizedTemplateGroups = {
  entities: {
    templateGroups: {
      [number]: TemplateGroup,
    },
  },
  result: number | Array<number>,
  count: number,
};

type TemplateGroupNormalizer = (any) => NormalizedTemplateGroups;

const mapWithBooleanState = (templateGroup: TemplateGroup) => ({
  ...templateGroup,
  active: Boolean(templateGroup.active),
});

const normalizeTemplateGroups: TemplateGroupNormalizer = (templateGroups) => ({
  ...normalize(templateGroups.collection.map(mapWithBooleanState), [templateGroupSchema]),
  count: templateGroups.count,
});

const normalizeTemplateGroup: TemplateGroupNormalizer = (templateGroup) => ({
  ...normalize(mapWithBooleanState(templateGroup), templateGroupSchema),
  count: 1,
});

type GetTemplateGroup = ({
  id: number,
}) => Promise<NormalizedTemplateGroups>;

export const getTemplateGroup: GetTemplateGroup = ({ id }) => (
  get({
    url: `/ext/templategroups/${id}`,
  }).then(extractResponseBodyAsJSON)
    .then(normalizeTemplateGroup)
);

type GetTemplateGroups = ({
  params?: {},
}) => Promise<NormalizedTemplateGroups>;

export const getTemplateGroups: GetTemplateGroups = ({ params }) => (
  getMultiple({
    url: '/ext/templategroups/',
    params,
    pagination: {
      limit: undefined,
      offset: undefined,
    },
  }).then(extractResponseBodyAsJSON)
    .then(normalizeTemplateGroups)
);

type CreateTemplateGroup = ({
  name: string,
  description?: string,
}) => Promise<NormalizedTemplateGroups>;

export const createTemplateGroup: CreateTemplateGroup = ({
  name,
  description,
}) => (
  post({
    url: '/ext/templategroups/',
    body: {
      name,
      description,
    },
  }).then(extractResponseBodyAsJSON)
    .then(normalizeTemplateGroup)
);

type UpdateTemplateGroup = ({
  id: number,
  active: boolean,
  name?: string,
  description?: string,
}) => Promise<NormalizedTemplateGroups>;

export const updateTemplateGroup: UpdateTemplateGroup = ({
  id,
  active,
  name,
  description,
}) => (
  put({
    url: `/ext/templategroups/${id}`,
    body: {
      active,
      name,
      description,
    },
  }).then(extractResponseBodyAsJSON)
    .then(normalizeTemplateGroup)
);
