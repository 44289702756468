import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import { getPositionFromSessionSelector } from 'reducers/session';
import agreements from 'reducers/entities/agreements';
import * as PARTICIPANT_CONSTANTS from 'agreement/participant/constants';

import { shouldBeDisabled, areSettingsDisabled } from 'components/document-tabs/settings-tab/helpers';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';
import SelectField, { Props as SelectFieldProps } from 'components/document-tabs/settings-tab/components/select-field';
import Label from 'components/document-tabs/settings-tab/components/label';
import CustomizeOptions from 'components/document-tabs/settings-tab/sections/signing-and-security/customize-options';

import { getAvailableSigningMethodIds, getDefaultSignMethod } from './helpers';
import style from '../../signing-and-security.module.scss';

type Props = {
  agreementId: number;
};

const allOptions = [
  { value: 0, label: <Message id="Standard e-signature" comment="Sign method" /> },
  { value: 1, label: <Message id="SMS verification" comment="Sign method" /> },
  { value: 2, label: <Message id="Swedish BankID" comment="Sign method" /> },
  { value: 4, label: <Message id="Norwegian BankID" comment="Sign method" /> },
  { value: 5, label: <Message id="Danish MitID" comment="Sign method" /> },
  { value: 6, label: <Message id="Finnish FTN" comment="Sign method" /> },
  { value: 10, label: <Message id="Handwritten Signature" comment="Sign method" /> },
  { value: 13, label: <Message id="Electronic ID Hub" comment="Sign method" /> },
];

type Option = typeof allOptions[number];

const CounterpartySigningMethod = ({ agreementId }: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const myPosition = useSelector(getPositionFromSessionSelector);
  const settingsDisabled = areSettingsDisabled(agreement, myPosition);
  const isSignatureModeQualified = agreement.config?.signatureMode === 'qualified';
  const { resetRPCStates } = useAPIError(agreementId);

  const availableSigningMethodIds = useMemo(() => (
    getAvailableSigningMethodIds(agreement)
  ), [agreement]);

  const onChange: SelectFieldProps<Option['value']>['onChange'] = (value, options) => {
    resetRPCStates();
    dispatch(agreements.updateConfig({
      id: agreementId,
      data: {
        defaultSignMethod: value,
      },
      pipe: {
        onFailure: options?.onFailure,
      },
    }));
  };

  const options = allOptions.filter((option) => (
    isSignatureModeQualified
      ? option.value === PARTICIPANT_CONSTANTS.SIGN_METHOD_EID_SIGN
      : availableSigningMethodIds.includes(option.value)));

  const label = (
    <Label
      hint={(
        <Message
          id="Select the default way your counterparties will be verified. The selected method can be changed when adding participants."
          comment="Tooltip for sign method dropdown"
        />
      )}
      customClassName={settingsDisabled ? style.Disabled : undefined}
    >
      <Message
        id="Signing method for counterparties"
        comment="Label for counterparty signing methods dropdown"
      />
    </Label>
  );

  return (
    <>
      <SelectField
        name="counterparty-signing-method"
        disabled={shouldBeDisabled(agreement) || settingsDisabled}
        value={getDefaultSignMethod(agreement)}
        onChange={onChange}
        label={label}
        options={options}
      />
      {!isSignatureModeQualified
        && <CustomizeOptions agreementId={agreementId} type="signMethods" />}
    </>
  );
};

export default CounterpartySigningMethod;
