import { pipe } from 'lodash/fp';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MessageTranslator } from '@oneflowab/pomes';
import extensionsReducer from 'reducers/entities/extensions';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getCategoryMapper } from 'routes/marketplace/marketplace';

import { checkAcl } from 'components/acl';
import { ExtensionLogo } from 'components/extension/extension-logo';
import * as Command from 'components/filterable';
import CommandItem from '../item';
import style from './search-marketplace.module.scss';
import { ACLs } from '../constants';

type Props = {
  message: MessageTranslator;
  closeCmdk: () => void;
};

export const SearchMarketplace = ({ message, closeCmdk }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector(getAccountFromSessionSelector) as Oneflow.Account;

  useEffect(() => {
    dispatch(extensionsReducer.queryExtensions({
      name: 'find-in-marketplace',
    }));
  }, []);

  const query = useSelector((state) => extensionsReducer.getQuerySelector(state, {
    name: 'find-in-marketplace',
  }));

  const extensions = useSelector((state) => extensionsReducer.getExtensionsSelector(state, {
    ids: query.result,
  }));

  type ExtensionsByCategory = {
    categories: Record<string, Oneflow.Extension[]>,
    extensionsWithoutCategory: Oneflow.Extension[],
  };

  const extensionsByCategory = useMemo(() => (
    extensions.reduce((acc: any, extension) => {
      const { category } = extension;
      if (!category) {
        acc.extensionsWithoutCategory.push(extension);
        return acc;
      }
      if (!acc.categories[category]) {
        acc.categories[category] = [];
      }
      acc.categories[category].push(extension);
      return acc;
    }, { categories: {}, extensionsWithoutCategory: [] }) as unknown as ExtensionsByCategory
  ), [extensions]);

  const categoryMapper = useMemo(() => getCategoryMapper(message), [message]);

  const navigateTo = (path: string) => {
    if (history.location.pathname !== path) {
      history.push(path);
    }
  };

  if (!checkAcl(account.acl, ACLs.page.FIND_IN_MARKETPLACE)) {
    return null;
  }

  return (
    <>
      {extensionsByCategory.extensionsWithoutCategory.map((extension) => (
        <CommandItem
          key={extension.id}
          id={`find-in-marketplace-${extension.id}`}
          value={extension.name}
          onSelect={pipe(closeCmdk, () => {
            navigateTo(`/marketplace/${extension.slug}`);
          })}
        >
          <ExtensionLogo extension={extension} className={style.Logo} />
          {extension.name}
        </CommandItem>
      ))}
      {Object.entries(extensionsByCategory.categories).map(([category, _extensions]) => (
        <Command.Group
          key={category}
          id={`find-in-marketplace-${category}`}
          heading={categoryMapper[category as keyof typeof categoryMapper]}
          headingId={`find-in-marketplace-${category}`}
        >
          {_extensions.map((extension) => (
            <CommandItem
              key={extension.id}
              id={`find-in-marketplace-${extension.id}`}
              keywords={[categoryMapper[category as keyof typeof categoryMapper]]}
              value={extension.name}
              onSelect={pipe(closeCmdk, () => {
                navigateTo(`/marketplace/${extension.slug}`);
              })}
            >
              <ExtensionLogo extension={extension} className={style.Logo} />
              {extension.name}
            </CommandItem>
          ))}
        </Command.Group>
      ))}
    </>
  );
};
