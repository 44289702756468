import useBoxIdMap from 'hooks/use-box-id-map';

const useCurrentBoxId = (boxId: number): number => {
  // TODO: remove type casting when useBoxIdMap is converted to TS
  const idMap = useBoxIdMap() as Record<number, number>;

  return idMap[boxId] || boxId;
};

export default useCurrentBoxId;
