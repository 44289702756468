import type { ReactNode } from 'react';
import clsx from 'clsx';

import DropdownMenu from 'components/dropdown-menu';
import ActionsDots from 'components/icons/actions-dots';

import style from './actions-menu.module.scss';

type Props = {
  actions: ReactNode[],
  disabled?: boolean,
  customIcon?: ReactNode,
  customClassName?: string,
  focusOnCloseDisabled?: boolean,
  onVisibilityChange?: () => void,
};

const ActionsMenu = ({
  actions,
  disabled,
  customIcon,
  customClassName,
  focusOnCloseDisabled,
  onVisibilityChange,
}: Props) => {
  const menuClassNames = clsx(style.ActionsMenu, customClassName, {
    [style.EnabledMenu]: !disabled,
  });

  let dropdownIcon = customIcon;
  if (!dropdownIcon) {
    dropdownIcon = <ActionsDots />;
  }

  return (
    <DropdownMenu
      items={actions}
      anchor={{
        x: 'inner-right',
        y: 'below',
      }}
      className={menuClassNames}
      dropdownIcon={dropdownIcon}
      hideMenuOnExit
      disabled={disabled}
      focusOnCloseDisabled={focusOnCloseDisabled}
      onVisibilityChange={onVisibilityChange}
    />
  );
};

ActionsMenu.defaultProps = {
  disabled: undefined,
};

export default ActionsMenu;
