// @flow

import * as React from 'react';
import clsx from 'clsx';

import { Message } from '@oneflowab/pomes';

import Checkbox from 'components/checkbox';

import style from './notify-participants-checkbox.module.scss';

export type Props = {
  checked: boolean,
  onChange: () => void,
  disabled: boolean,
};

const NotifyParticipantsCheckbox = ({
  checked,
  onChange,
  disabled,
}: Props) => {
  const linkClass = clsx(style.Label, {
    [style.Disabled]: disabled,
  });

  return (
    <Checkbox
      input={{
        checked,
        onChange,
      }}
      disabled={disabled}
      customLabelStyle={linkClass}
      tooltipType="oneflow"
      label={(
        <Message
          id="Send notification email"
          comment="Checking this option will open up a section to input a custom notification email message to the participant that is being added."
        />
      )}
    />
  );
};

export default NotifyParticipantsCheckbox;
