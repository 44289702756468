// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const WorkspaceBrandingUpdateEvent = ({ auditLogEvent }: Props) => (
  <Message
    id="The branding for workspace {workspace} was {jsx-start}updated{jsx-end}"
    comment="Audit log event message for updated workspace"
    component="span"
    className={style.Green}
    values={{
      workspace: auditLogEvent.target.name,
    }}
  />
);

export default WorkspaceBrandingUpdateEvent;
