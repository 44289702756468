// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import agreementsReducer from 'reducers/entities/agreements';

import { SetContractNameModal, type Props, type FormData } from './set-contract-name';

type StateProps = {|
  updateState: UpdateState,
  currentContractName?: string,
|};

type DispatchProps = {|
  setContractName: FormData => void,
  resetUpdateState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  agreement: Agreement,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { agreement }) => ({
  updateState: agreementsReducer.getUpdateContractNameSelector(state, { id: agreement.id }),
  currentContractName: agreement.name,
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { agreement }) => ({
  setContractName: ({ name }: FormData) => {
    dispatch(agreementsReducer.updateContractName({
      id: agreement.id,
      data: {
        name: name || null,
      },
    }));
  },
  resetUpdateState: () => {
    dispatch(agreementsReducer.updateContractNameReset({
      id: agreement.id,
    }));
  },
});

const connectedSetContractName = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(SetContractNameModal);

export { connectedSetContractName as SetContractNameModal };
