import {
  ReactNode,
  FC,
  useEffect,
  ComponentProps,
  useCallback,
} from 'react';

import { Drawer as VaulDrawer } from 'vaul';
import { useDrawerStore } from 'store/zustand-store/use-drawer-store';

import style from './drawer.module.scss';

interface CustomDrawerProps {
  id: string;
  isOverlayEnabled?: boolean;
  isDragHandleVisible?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  renderTrigger?: () => ReactNode;
  renderTitle?: () => ReactNode;
  renderDescription?: () => ReactNode;
  children: ReactNode;
}

type DrawerProps = CustomDrawerProps & ComponentProps<typeof VaulDrawer.Root>;

const usedIds = new Set<string>();

export const Drawer: FC<DrawerProps> = ({
  id,
  isOverlayEnabled,
  isDragHandleVisible,
  onOpenChange,
  renderTrigger,
  renderTitle,
  renderDescription,
  children,
  ...restProps
}) => {
  useEffect(() => {
    if (usedIds.has(id)) {
      console.error(`Drawer component with id "${id}" is used in multiple places.`);
    } else {
      usedIds.add(id);
    }

    return () => {
      usedIds.delete(id);
    };
  }, [id]);

  const openDrawer = useDrawerStore((state) => state.openDrawer);
  const closeDrawer = useDrawerStore((state) => state.closeDrawer);
  const isDrawerOpen = useDrawerStore((state) => state.getDrawerState(id));

  const handleOpenChange = useCallback((updatedOpenState: boolean) => {
    if (onOpenChange) {
      onOpenChange(updatedOpenState);
    }

    if (updatedOpenState) {
      openDrawer(id);
    } else {
      closeDrawer(id);
    }
  }, [closeDrawer, id, onOpenChange, openDrawer]);

  return (
    <VaulDrawer.Root
      open={isDrawerOpen}
      onOpenChange={handleOpenChange}
      repositionInputs={false}
      {...restProps}
    >
      {isOverlayEnabled && (
        <VaulDrawer.Portal>
          <VaulDrawer.Overlay className={style.Overlay} />
        </VaulDrawer.Portal>
      )}
      {renderTrigger && (
        <VaulDrawer.Trigger asChild className={style.Trigger}>
          {renderTrigger()}
        </VaulDrawer.Trigger>
      )}
      <VaulDrawer.Portal>
        <VaulDrawer.Content
          className={style.Content}
        >
          {isDragHandleVisible && <div className={style.DragHandle} />}
          {renderTitle
            ? <VaulDrawer.Title className={style.Title}>{renderTitle()}</VaulDrawer.Title>
            : <VaulDrawer.Title style={{ display: 'none' }}><span>&nbsp;</span></VaulDrawer.Title>}
          {renderDescription
            ? <VaulDrawer.Description>{renderDescription()}</VaulDrawer.Description>
            : <VaulDrawer.Description style={{ display: 'none' }}><span>&nbsp;</span></VaulDrawer.Description>}
          {children}
        </VaulDrawer.Content>
      </VaulDrawer.Portal>
    </VaulDrawer.Root>
  );
};
