// @flow

import React from 'react';
import BrokenImage from 'components/icons/broken-image';
import { useContractProps } from 'contexts/contract-props';

import style from '../image.module.scss';

type ContractImageProps = {
  draggable: boolean,
  element: any,
  imageRef?: any,
}

function getImageSize({ width, height }) {
  const size = {};
  if (Number(width)) {
    size.width = Number(width);
  }

  if (Number(height)) {
    size.height = Number(height);
  }

  return size;
}

const isBroken = (src: string, contractId: number) => (
  src.endsWith('/images/broken_image.png') || !src.startsWith(`/api/agreements/${contractId}/images/`)
);

const ContractImage = ({
  draggable,
  element,
  imageRef,
}: ContractImageProps) => {
  const { height, width, src } = element;
  const { contractId } = useContractProps();
  const altText = element.alt;

  if (!src || isBroken(src, contractId)) {
    return (
      <BrokenImage
        iconRef={imageRef}
        alt={altText}
        {...getImageSize({ width, height })}
        className={style.BrokenImageIcon}
        draggable={draggable}
      />
    );
  }

  return (
    <img
      ref={imageRef}
      src={element.src}
      alt={altText}
      {...getImageSize({ width, height })}
      draggable={draggable}
      className={style.ContractImage}
    />
  );
};

export default ContractImage;
