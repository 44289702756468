import Svg from 'components/svg';

export const EeEsteIdMobile = Svg((
  <>
    <defs>
      <filter
        id="filter0_d_518_1150"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-3" dy="6" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_518_1150" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_518_1150"
          result="shape"
        />
      </filter>
    </defs>
    <g filter="url(#filter0_d_518_1150)" fill="none">
      <rect x="11" y="2" width="267" height="267" rx="41" fill="#" />
      <path
        d="M144.982 239C201.858 239 247.965 192.885 247.965 136C247.965 79.1147 201.858 33 144.982 33C88.1068 33 42 79.1147 42 136C42 192.885 88.1068 239 144.982 239Z"
        fill="#"
      />
      <path
        d="M151.603 198.726C185.965 197.983 212.791 171.046 213.582 140.629C214.239 115.492 197.021 91.4374 170.859 82.5049"
        stroke="#003168"
        strokeWidth="9"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M150.669 146.378C153.626 146.378 156.023 143.981 156.023 141.023C156.023 138.065 153.626 135.668 150.669 135.668C147.712 135.668 145.315 138.065 145.315 141.023C145.315 143.981 147.712 146.378 150.669 146.378Z"
        fill="#003168"
        stroke="#003168"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M95.5137 82.5381C100.775 90.5872 122.826 118.401 122.826 118.401C122.826 118.401 147.619 88.0702 150.626 82.5381"
        stroke="#003168"
        strokeWidth="9"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M95.5137 174.187V82.5381"
        stroke="#003168"
        strokeWidth="9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.291 125.706V82.5381"
        stroke="#003168"
        strokeWidth="9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.626 198.76V155.592"
        stroke="#003168"
        strokeWidth="9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </>
), {
  viewBox: '0 0 283 283',
});

EeEsteIdMobile.displayName = 'EeEsteIdMobile';
