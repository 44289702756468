/* eslint-disable react/no-this-in-sfc */
// @flow
/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import { Link } from 'react-router-dom';
import { Message, type MessageTranslator } from '@oneflowab/pomes';
import clsx from 'clsx';
import {
  isEmpty,
  map,
  get,
  uniqueId,
} from 'lodash';

import adminPage from 'hocs/admin-page';

import {
  isUserLimited,
  isUserInvited,
  isUserActive,
  USER_ACCOUNT_ROLE_ADMIN_ID,
  USER_ROLE_LIMITED,
} from 'user';
import { hasAvailableSeats } from 'account';

import User from 'routes/admin/user';
import ActionsMenu from 'components/actions-menu';
import { MenuItem } from 'components/menu-item';
import AssignUserToGroups from 'components/modals/assign-user-to-groups';
import AssignUserToWorkspaces from 'components/modals/assign-user-to-workspaces';
import { EditMenuItem } from 'components/menu-items/edit';
import InviteUser from 'components/modals/invite-user';
import { ResendUserInvitation } from 'components/modals/resend-user-invitation';
import { UpdateUserStatus } from 'components/modals/update-user-status';
import { checkAcl } from 'components/acl';
import Tooltip from 'components/tooltip';
import {
  Table,
  TableFiltering,
} from 'components/table';
import Pagination from 'components/pagination';
import { BadgeInactive } from 'components/badges/badge-inactive';
import ActionBar from 'components/action-bar';
import ExportUsers from 'components/export-users';
import { MfaTooltipMessage } from 'components/mfa-tooltip-message';
import AddMembersIcon from 'components/icons/add-members';
import GrantUserAccessIcon from 'components/icons/grant-user-access';
import CheckShieldIcon from 'components/icons/check-shield';
import ReplayIcon from 'components/icons/replay';
import SingleUserActionsAddIcon from 'components/icons/single-user-actions-add';
import SingleUserActionsBlockIcon from 'components/icons/single-user-actions-block';
import UserRole, { getUserRolesAsOptions } from 'components/user-roles';
import LabelValueSeparator from 'components/label-value-separator';
import GetLastActiveDate from 'components/get-last-active-date';
import { GrantUserAccountAccess, GrantUserAccountAccessNonEnterprise } from 'components/modals/grant-user-account-access';
import AdministratorIcon from 'components/icons/administrator';
import BuySeatsButton from 'components/billing-header/buy-seats-button';
import SeatsInfo from 'components/billing-header/seats-info';

import style from './users.module.scss';

type Props = {
  myPositionId: number,
  account: Account,
  positions: Array<Position>,
  positionsQuery: Query,
  queryPositions: QueryFunc,
  fetchAccount: ({ id: number }) => void,
  message: MessageTranslator,
  isPositionLimitedFeatureEnabled: boolean,
  accountSeats: AccountSeats,
  editLinkPath: (positionId: number) => void,
  getUsersAccountBindings: ({
    params: {
      actorIds: number[],
      actorType: 'position'
    }
  }) => void
};

export class UsersComponent extends React.Component<Props> {
  componentDidMount() {
    this.fetchAccount();
    this.fetchPositions({
      active: 1,
    });
  }

  onFilter = (params: QueryFuncArgs) => {
    this.fetchPositions(params);
  };

  fetchPositions = (params) => {
    const { queryPositions, getUsersAccountBindings } = this.props;

    queryPositions({
      params,
      pipe: {
        onSuccess: (data) => {
          const positionIds = data.result;
          const hasPositions = positionIds.length > 0;
          if (hasPositions) {
            getUsersAccountBindings({
              params: {
                actorIds: positionIds,
                actorType: 'position',
              },
            });
          }
        },
      },
    });
  };

  getName = (position: Position) => {
    let badges = [];

    if (!position.active) {
      badges = [
        ...badges,
        <BadgeInactive
          key={uniqueId()}
        />,
      ];
    }
    const isAdministrator = !isEmpty(position.userAccountBindings?.find(
      (binding) => binding.role.id === USER_ACCOUNT_ROLE_ADMIN_ID,
    ));

    return (
      <div className={style.User}>
        <Link to={`/admin/users/${position.id}`}>
          {position.fullname}
          {(isAdministrator)
            && <AdministratorIcon className={style.AdministratorIcon} />}
        </Link>
        {badges}
        {this.getEmail(position)}
      </div>
    );
  };

  getEmail = (position: Position) => (
    <div title={position.email} className={style.Email}>
      {position.email}
    </div>
  );

  getSecurity = (position: Position) => {
    const { message } = this.props;
    if (!position.mfaChannel) {
      return <div className={style.AuthenticationContainer} />;
    }

    return (
      <div className={style.AuthenticationContainer}>
        <Tooltip
          side="top"
          message={<MfaTooltipMessage position={position} message={message} />}
        >
          <span>
            <CheckShieldIcon width="24px" height="24px" />
          </span>
        </Tooltip>
      </div>
    );
  };

  getAccountRole = (position: Position) => {
    const { message, account } = this.props;

    const directAccess = get(position.accountAccessStats, 'direct');
    const groupAccess = get(position.accountAccessStats, 'group');
    const totalAccess = get(position.accountAccessStats, 'total');

    const hasGroupCustomRolePermissions = checkAcl(account.acl, 'account:group:custom_role_binding:view');
    const hasGroupSystemRolePermissions = checkAcl(account.acl, 'account:group:system_role_binding:view');

    if (hasGroupCustomRolePermissions || hasGroupSystemRolePermissions) {
      const hasAccessToGroups = groupAccess !== undefined;

      const getGroupToolTipMessage = () => {
        if (!hasAccessToGroups) {
          return null;
        }

        return (
          <p>
            <b>
              {message({
                id: 'Group access',
                comment: 'label for displaying number of roles a user has',
              })}
              <LabelValueSeparator />
            </b>
            {message({
              id: '{count} roles',
              pluralId: '{count} roles',
              pluralCondition: 'count',
              values: { count: groupAccess },
              comment: 'displays number of roles a user has',
            })}
          </p>
        );
      };

      const tooltipMessage = (
        <>
          <p>
            <b>
              {message({
                id: 'Direct access',
                comment: 'label for displaying number of roles a user has',
              })}
              <LabelValueSeparator />
            </b>
            {message({
              id: '{count} roles',
              pluralId: '{count} roles',
              pluralCondition: 'count',
              values: { count: directAccess },
              comment: 'displays number of roles a user has',
            })}
          </p>
          {getGroupToolTipMessage()}
        </>
      );

      return (
        <Tooltip
          side="top"
          message={tooltipMessage}
        >
          <Link to={`/admin/users/${position.id}/account-access`}>
            <Message
              id="{count} role"
              pluralId="{count} roles"
              pluralCondition="count"
              values={{ count: totalAccess }}
              comment="displays number of roles a user has"
            />
          </Link>
        </Tooltip>
      );
    }

    const roleName = get(position?.userAccountBindings?.[0], 'role')?.name;

    if (roleName) {
      return (
        <div>
          <Link to={`/admin/users/${position.id}/account-access`}>
            <p data-testid="role-name" className={style.AccountRoleName}>{roleName}</p>
          </Link>
        </div>
      );
    }

    return (
      <p data-testid="role-name" className={style.AccountRoleName}>-</p>
    );
  };

  getWorkspaceAccess = (position: Position) => {
    const { message } = this.props;

    const directAccess = get(position.collectionAccessStats, 'direct');
    const groupAccess = get(position.collectionAccessStats, 'group');
    const totalAccess = get(position.collectionAccessStats, 'total');
    const hasAccessToGroups = groupAccess !== undefined;

    const getGroupToolTipMessage = () => {
      if (!hasAccessToGroups) {
        return null;
      }

      return (
        <p>
          <strong>
            {message({
              id: 'Group access',
              comment: 'label for displaying number of workspaces a user has access to',
            })}
            <LabelValueSeparator />
          </strong>
          {message({
            id: '{count} workspace',
            pluralId: '{count} workspaces',
            pluralCondition: 'count',
            values: { count: groupAccess },
            comment: 'displays number of workspaces a user has access to',
          })}
        </p>
      );
    };

    const tooltipMessage = (
      <>
        <p>
          <strong>
            {message({
              id: 'Direct access',
              comment: 'label for displaying number of workspaces a user has access to',
            })}
            <LabelValueSeparator />
          </strong>
          {message({
            id: '{count} workspace',
            pluralId: '{count} workspaces',
            pluralCondition: 'count',
            values: { count: directAccess },
            comment: 'displays number of workspaces a user has access to',
          })}
        </p>
        {getGroupToolTipMessage()}
      </>
    );

    return (
      <Tooltip
        side="top"
        message={tooltipMessage}
      >
        <Link to={`/admin/users/${position.id}/workspace-access`}>
          <Message
            id="{count} workspace"
            pluralId="{count} workspaces"
            pluralCondition="count"
            values={{ count: totalAccess }}
            comment="displays number of workspaces a user has access to"
          />
        </Link>
      </Tooltip>
    );
  }

  getLastActiveDate = (position: Position) => (
    <GetLastActiveDate position={position} />
  );

  getAddToGroupsMenuItem = (position: Position) => (onClick: () => void) => {
    const hasGroupAccess = checkAcl(position.acl, 'position:group:create');

    if (!hasGroupAccess) {
      return null;
    }

    return (
      <MenuItem
        icon={AddMembersIcon}
        onClick={onClick}
        label={(
          <Message
            id="Add to groups"
            comment="label for assigning user to groups"
          />
        )}
      />
    );
  };

  getGrantAccountAccessMenuItem = (
    position: Position, hasCustomRolePermission: boolean, hasSystemRolePermission: boolean,
  ) => (onClick: () => void) => {
    const { account } = this.props;
    const hasRolePermission = checkAcl(account.acl, 'account:admin:role');
    const isLimitedUser = position.userRole === USER_ROLE_LIMITED;

    if (!hasRolePermission) {
      return null;
    }

    let label = (
      <Message
        id="Grant account access"
        comment="label for granting a user access to an account role"
      />
    );

    // If account is below enterprise system role binding should be allow custom role should be deny
    // using these ACLs, this sets a different label for the below enterprise
    if (!hasCustomRolePermission && hasSystemRolePermission) {
      label = (
        <Message
          id="Assign account admin role"
          comment="label for granting a user access to an account admin role"
        />
      );
    }

    const isMenuItemDisabled = (!hasSystemRolePermission && !hasCustomRolePermission)
      || isLimitedUser;

    return (
      <MenuItem
        icon={GrantUserAccessIcon}
        onClick={onClick}
        disabled={isMenuItemDisabled}
        label={label}
      />
    );
  };

  getGrantWorkspaceAccessMenuItem = (position: Position) => (onClick: () => void) => {
    const { account } = this.props;
    const hasPermission = checkAcl(account.acl, 'account:admin:workspace');
    const hasBindingPermission = checkAcl(position.acl, 'position:collection:binding:create');

    if (!hasPermission) {
      return null;
    }

    return (
      <MenuItem
        icon={GrantUserAccessIcon}
        onClick={onClick}
        disabled={!hasBindingPermission}
        label={(
          <Message
            id="Grant workspace access"
            comment="label for granting a user access to a workspace"
          />
        )}
      />
    );
  };

  getResendInvitationMenuItem = (position: Position) => (onClick: () => void) => {
    const { account } = this.props;
    const hasPermission = checkAcl(account.acl, 'account:position:create');
    const isDisabled = !position.active || !isUserInvited(position) || !hasPermission;

    return (
      <MenuItem
        disabled={isDisabled}
        icon={ReplayIcon}
        onClick={onClick}
        label={(
          <Message
            id="Resend invitation"
            comment="label for resending invitation"
          />
        )}
      />
    );
  };

  getUpdateUserStatusMenuItem = (position: Position) => (onClick: () => void) => {
    let icon = SingleUserActionsAddIcon;
    let label = (
      <Message
        id="Activate"
        comment="label for activating user"
      />
    );

    if (isUserActive(position)) {
      icon = SingleUserActionsBlockIcon;
      label = (
        <Message
          id="Deactivate"
          comment="label for deactivating user"
        />
      );
    }

    return (
      <MenuItem
        disabled={!this.canUpdateUserStatus(position)}
        icon={icon}
        onClick={onClick}
        label={label}
        className={clsx({
          [style.Danger]: isUserActive(position) && this.canUpdateUserStatus(position),
        })}
      />
    );
  };

  handleOnClick = (id: number) => () => this.props.editLinkPath(id);

  renderGrantUserAccountModal = (position) => {
    const { account } = this.props;

    const hasCustomRolePermission = checkAcl(account.acl, 'account:position:custom_role_binding:create');
    const hasSystemRolePermission = checkAcl(account.acl, 'account:position:system_role_binding:create');

    const hasFullAccountRoleAccess = hasCustomRolePermission && hasSystemRolePermission;

    if (hasFullAccountRoleAccess) {
      return (
        <GrantUserAccountAccess
          position={position}
          shouldRedirectToAccountAccess
          renderedFromAccountAccessPage={false}
        >
          {this.getGrantAccountAccessMenuItem(
            position, hasCustomRolePermission, hasSystemRolePermission,
          )}
        </GrantUserAccountAccess>
      );
    }

    return (
      <GrantUserAccountAccessNonEnterprise
        position={position}
        shouldRedirectToAccountAccess
        renderedFromAccountAccessPage={false}
      >
        {this.getGrantAccountAccessMenuItem(
          position, hasCustomRolePermission, hasSystemRolePermission,
        )}
      </GrantUserAccountAccessNonEnterprise>
    );
  }

  getActions = (position: Position) => {
    const hasEditPermission = checkAcl(position.acl, 'position:update:fullname')
      || checkAcl(position.acl, 'position:update:email')
      || checkAcl(position.acl, 'position:update:title')
      || checkAcl(position.acl, 'position:update:phone_number');

    const commonActions = [
      <EditMenuItem
        onClick={this.handleOnClick(position.id)}
        disabled={!hasEditPermission}
      />,
      <AssignUserToGroups position={position} shouldRedirectToUserGroups>
        {this.getAddToGroupsMenuItem(position)}
      </AssignUserToGroups>,
      this.renderGrantUserAccountModal(position),
      <AssignUserToWorkspaces position={position} shouldRedirectToWorkspaceAccess>
        {this.getGrantWorkspaceAccessMenuItem(position)}
      </AssignUserToWorkspaces>,
    ];

    const updateStatusAction = (
      <UpdateUserStatus position={position}>
        {this.getUpdateUserStatusMenuItem(position)}
      </UpdateUserStatus>
    );

    const resendInvitationAction = isUserInvited(position) ? (
      <ResendUserInvitation position={position}>
        {this.getResendInvitationMenuItem(position)}
      </ResendUserInvitation>
    ) : null;

    let actions = [];
    if (!isUserActive(position)) {
      // Inactive user - UpdateStatus on top
      actions = [updateStatusAction, ...commonActions];
    } else {
      // Active user - UpdateStatus on bottom
      actions = [...commonActions, updateStatusAction];
    }

    // Add resend invitation action at the top if user is invited
    if (isUserInvited(position)) {
      actions.unshift(resendInvitationAction);
    }

    return (
      <ActionsMenu
        actions={actions}
        focusOnCloseDisabled
      />
    );
  }

  getTableColumns() {
    const { message } = this.props;

    const columns = [
      {
        name: 'user name',
        label: message({ id: 'User name', comment: 'Column label in users page' }),
        type: 'cell',
        value: this.getName,
      },
      {
        name: 'user type',
        label: message({ id: 'User type', comment: 'Column label in users page' }),
        type: 'cell',
        value: this.getUserRole,
      },
      {
        name: 'account role',
        label: message({ id: 'Account role', comment: 'Column label in users page' }),
        type: 'cell',
        value: this.getAccountRole,
      },
      {
        name: 'workspace access',
        label: message({ id: 'Workspace access', comment: 'Column label in users page' }),
        type: 'cell',
        value: this.getWorkspaceAccess,
      },
      {
        name: 'last active',
        label: message({ id: 'Last active', comment: 'Column label in users page' }),
        type: 'cell',
        value: this.getLastActiveDate,
        className: style.LastActiveColumn,
      },
      {
        name: 'authentication',
        label: message({ id: 'Authentication', comment: 'Column label in users page' }),
        type: 'cell',
        className: style.Authentication,
        value: this.getSecurity,
      },
      {
        name: 'actions',
        label: message({ id: 'Actions', comment: 'Column label in users page' }),
        type: 'actions',
        value: this.getActions,
      },
    ];

    return columns;
  }

  getTableConfig() {
    const { positions } = this.props;
    return {
      items: positions,
      itemKey: 'id',
      actions: [],
      columns: this.getTableColumns(),
    };
  }

  canUpdateUserStatus = (position: Position) => {
    const { myPositionId, accountSeats } = this.props;

    const canActivate = hasAvailableSeats(accountSeats) || isUserLimited(position);
    const isAllowed = myPositionId !== position.id && checkAcl(position.acl, 'position:update:active');

    if (isUserActive(position)) {
      return isAllowed;
    }

    return isAllowed && canActivate;
  }

  fetchAccount = () => {
    const {
      fetchAccount,
      account,
    } = this.props;

    fetchAccount({ id: account.id });
  };

  handlePageChange = ({ offset, limit }: Pagination) => {
    const { queryPositions, positionsQuery } = this.props;

    queryPositions({
      params: positionsQuery.params,
      pagination: {
        offset,
        limit,
      },
    });
  };

  getUserRole = (position: Position) => (<UserRole userRole={position.userRole} withTooltip />);

  canInviteNewUser() {
    const {
      account,
      isPositionLimitedFeatureEnabled,
      accountSeats,
    } = this.props;
    const hasInviteUsersAccess = checkAcl(account.acl, 'account:position:create');

    if (!isPositionLimitedFeatureEnabled) {
      return hasInviteUsersAccess && hasAvailableSeats(accountSeats);
    }

    return hasInviteUsersAccess;
  }

  tableFilters() {
    const {
      message,
      isPositionLimitedFeatureEnabled,
    } = this.props;

    const iteratee = (item, index) => ({
      query: { user_role: item.value === 'limited' ? 'limited' : 'user' },
      label: item.label,
      value: index + 1,
    });

    const userRolesOptions = map(
      getUserRolesAsOptions(
        isPositionLimitedFeatureEnabled,
        message,
      ),
      iteratee,
    );

    return [
      {
        type: 'text',
        name: 'search',
        defaultValue: '',
        queryKey: 'q',
        autoFocus: true,
        placeholder: message({
          id: 'Find users',
          comment: 'Placeholder in search field.',
        }),
      },
      {
        type: 'dropdown',
        name: 'status',
        options: [
          {
            query: { active: 1 },
            label: message({ id: 'Active users', comment: 'Dropdown label for table filters in users page' }),
            value: 0,
            default: true,
          },
          {
            query: { active: 0 },
            label: message({ id: 'Inactive users', comment: 'Dropdown label for table filters in users page' }),
            value: 1,
          },
          {
            query: { invited: 1 },
            label: message({ id: 'Invited users', comment: 'Dropdown label for table filters in users page' }),
            value: 2,
          },
          {
            query: {},
            label: message({ id: 'All users', comment: 'Dropdown label for table filters in users page' }),
            value: 3,
          },
        ],
      },
      {
        type: 'dropdown',
        name: 'user_role',
        options: [
          {
            query: {},
            label: message({ id: 'All user types', comment: 'Dropdown label for table filters in users page' }),
            value: 0,
            default: true,
          },
          ...userRolesOptions,
        ],
      },
    ];
  }

  renderExport() {
    const { positionsQuery } = this.props;

    if (positionsQuery.loading) {
      return null;
    }

    return (
      <ExportUsers positionsQuery={positionsQuery} />
    );
  }

  renderPagination() {
    const { positionsQuery, message } = this.props;

    if (positionsQuery.loading) {
      return null;
    }

    return (
      <Pagination
        totalItems={positionsQuery.count}
        itemsPerPage={positionsQuery.pagination.limit}
        currentOffset={positionsQuery.pagination.offset}
        onChange={this.handlePageChange}
        entityName={message({
          id: 'users',
          comment: 'Plural form in pagination of users.',
        })}
      />
    );
  }

  render() {
    const {
      positionsQuery,
      account,
      accountSeats,
    } = this.props;

    const hasViewUsersAccess = checkAcl(account.acl, 'account:position:view');

    let inviteUserModal = (
      <InviteUser
        isDisabled={!this.canInviteNewUser()}
        inviteSuccessHandler={this.fetchAccount}
      />
    );

    if (!hasViewUsersAccess) {
      inviteUserModal = null;
    }

    return (
      <div className={style.Users}>
        <ActionBar collapsed>
          <ActionBar.Group>
            <div className={style.Buttons}>
              {inviteUserModal}
              <BuySeatsButton
                accountSeats={accountSeats}
                account={account}
                redirectToBilling
              />
            </div>
            <SeatsInfo
              account={account}
              accountSeats={accountSeats}
            />
          </ActionBar.Group>
        </ActionBar>
        <TableFiltering
          filters={this.tableFilters()}
          onFilterHandler={this.onFilter}
          loading={positionsQuery.loading}
        />
        <Table
          config={this.getTableConfig()}
          query={positionsQuery}
        />
        <div className={style.PaginationContainer}>
          {this.renderPagination()}
          {this.renderExport()}
        </div>
      </div>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({ id: 'Users', comment: 'Users page title' }),
  modules: [[{
    path: '/:id(\\d+)',
    component: User,
    title: message({
      id: 'Users',
      comment: 'Title for the users page.',
    }),
  }]],
});

export default adminPage(propsMapper)(UsersComponent);
