import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Message } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import useDependentState from 'hooks/use-dependent-state';
import agreements from 'reducers/entities/agreements';
// eslint-disable-next-line import/no-named-as-default
import workspacesReducer from 'reducers/entities/workspaces';
import { isTemplate } from 'agreement/states';

import { checkAcl } from 'components/acl';
import Confirmable from 'components/confirmable';
import CheckboxGroup, { FilterOption } from 'components/checkbox-group';
import CogIcon from 'components/icons/cog';
import { CancelButton, ConfirmButton } from 'components/buttons';
import Button from 'components/button';

import {
  getLabel,
  isDisabled,
  ComponentTypes,
  configTypes,
  defaultConfigTypes,
  getInitialSelectedOptionsState,
} from './helper.ts';
import { titles } from './constants';

import style from './customize-options.module.scss';

type Props = {
  agreementId: number,
  type: ComponentTypes
}

const CustomizeOptions = ({ agreementId, type }: Props) => {
  const agreement = useAgreement(agreementId);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const availableOptions = useMemo(() => agreement.availableOptions ?? {}, [agreement]);
  const mfaChannels = useMemo(() => availableOptions?.mfaChannels ?? [], [availableOptions]);

  const options: typeof availableOptions = { ...availableOptions, mfaChannels: [...mfaChannels, 'none'] };

  const initialSelectedOptionsState = useMemo(
    () => getInitialSelectedOptionsState(agreement), [agreement],
  );

  const [selectedOptions, setSelectedOptions] = useDependentState(
    initialSelectedOptionsState, { dependency: initialSelectedOptionsState },
  );

  const workspace = useSelector((state) => workspacesReducer.getWorkspaceSelector(state, {
    id: get(agreement, 'collection.id'),
  }));

  const handleClose = useCallback(() => {
    setSelectedOptions(initialSelectedOptionsState);
    dispatch(agreements.updateConfigReset({ id: agreementId }));
  }, [agreementId, dispatch, initialSelectedOptionsState, setSelectedOptions]);

  const updateConfigState = useSelector((state) => (
    agreements.getUpdateConfigSelector(state, { id: agreementId })
  ));

  const resetUpdateConfigState = useCallback(() => {
    dispatch(agreements.updateConfigReset({ id: agreementId }));
  }, [agreementId, dispatch]);

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
    resetUpdateConfigState();
  }, [setIsOpen, resetUpdateConfigState]);

  const handleChange = useCallback((
    value: number[] | null,
  ) => {
    setSelectedOptions({ ...selectedOptions, [type]: value });
  }, [selectedOptions, type, setSelectedOptions]);

  const handleConfirm = useCallback(() => {
    const dataType = configTypes[type];
    const defaultConfig = defaultConfigTypes[type];
    const selectedData = selectedOptions?.[type];
    const agreementConfigDefault = agreement.config?.[defaultConfig] ?? '';

    const hasDefaultSelection = selectedOptions?.[type]?.some(
      (value) => agreementConfigDefault === value,
    );

    type Payload = {
      id: number,
      data: {
        [key: string]: string | number | number[] | string[] | null | undefined
      }
    }

    let payload: Payload = {
      id: agreementId,
      data: {
        [dataType]: selectedData,
      },
    };

    if (!hasDefaultSelection) {
      payload = {
        ...payload,
        data: {
          ...payload.data,
          [defaultConfig]: selectedOptions?.[type]?.[0],
        },
      };
    }

    dispatch(agreements.updateConfig(payload));
  }, [agreementId, dispatch, selectedOptions, type, agreement.config]);

  const hasNoSelectedOption = useMemo(() => (
    isEmpty(selectedOptions) || isEmpty(selectedOptions?.[type])
  ), [selectedOptions, type]);

  if (!isTemplate(agreement)
    || !checkAcl(workspace.acl, 'collection:agreement:template:create')
    || !agreement
  ) {
    return null;
  }

  const renderActions = ({ closeConfirmation }: { closeConfirmation: () => void }) => (
    <>
      <CancelButton onClick={closeConfirmation} data-testid="cancel-button" />
      <ConfirmButton
        disabled={updateConfigState.loading || hasNoSelectedOption}
        isLoading={updateConfigState.loading}
        onClick={handleConfirm}
      />
    </>
  );

  const renderContent = () => {
    const tooltipMessage = (
      <Message
        id="This option is used by a participant"
        comment="Tooltip explaining why an option is disabled in lockdown options."
      />
    );

    const checkboxOptions: FilterOption[] = options[type]?.map((option) => {
      const label = getLabel({ type, option });
      const isOptionDisabled = isDisabled({ type, option, agreement });

      return ({
        label,
        value: option,
        disabled: isOptionDisabled,
        tooltipMessage: isOptionDisabled ? tooltipMessage : null,
      });
    }) || [];

    const title = titles[type];

    return (
      <>
        <h1 className={style.ModalTitle}>{title}</h1>
        <div className={style.CheckboxGroup}>
          <CheckboxGroup
            options={checkboxOptions}
            onChange={handleChange}
            value={selectedOptions?.[type]}
            disabled={
              updateConfigState.loading
              || updateConfigState.success
              || updateConfigState.error
            }
          />
        </div>
      </>
    );
  };

  const renderBody = () => (
    <>
      <div className={style.Description}>
        <Message
          id="The selected options will be available for document creators to pick from. Add more options in the {marketplacePageLink}."
          comment="Modal description for customizable options"
          values={{
            marketplacePageLink: (
              <a target="_blank" href="/marketplace" rel="noreferrer">
                <Message
                  id="marketplace"
                  comment="Link to extensions page"
                />
              </a>
            ),
          }}
        />
      </div>
      <div>
        {renderContent()}
      </div>
    </>
  );

  const getChildren = (onClick: () => void) => (
    <Button onClick={onClick} className={style.Button}>
      <div className={style.OpenModalButton}>
        <CogIcon height="12px" />
        <Message
          id="Customize options"
          comment="button for opening customize options modal"
        />
      </div>
    </Button>
  );

  return (
    <Confirmable
      actions={renderActions}
      header={(
        <Message
          id="Customize options"
          comment="Modal title for customizing options for fields in signing and security section."
        />
      )}
      body={renderBody()}
      disabled={updateConfigState.error}
      onConfirm={handleConfirm}
      onEnter={handleConfirm}
      onOpen={handleOpenModal}
      isOpen={isOpen}
      error={updateConfigState.error}
      success={updateConfigState.success}
      isConfirmLoading={updateConfigState.loading}
      onClose={handleClose}
      onCancel={handleClose}
      modalKey={`Customize options for ${type}`}
      customBodyClass={style.ModalBody}
    >
      {getChildren}
    </Confirmable>
  );
};

export default CustomizeOptions;
