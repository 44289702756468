// @flow

import React from 'react';
import urlJoin from 'url-join';
import { Redirect, type Match } from 'react-router';

import adminPage from 'hocs/admin-page';
import type { PropsMapper } from 'hocs/admin-page/admin-page';

import UserDetails from './user-details';
import UserGroups from './user-groups';
import UserWorkspaceAccess from './workspace-access';
import UserAccountAccess from './account-access';

type Props = {
  match: Match,
};

export const User = (props: Props) => (
  <Redirect from={props.match.path} to={urlJoin(props.match.url, '/details')} />
);

export const userModule: PropsMapper = ({
  props: {
    groups,
    queryGroups,
    message,
    fetchPosition,
    match,
  },
}) => {
  const modules = [
    {
      path: '/details',
      title: message({ id: 'Details', comment: 'The sidebar title' }),
      section: message({
        id: 'Users',
        comment: 'Used as the title for the section.',
      }),
      component: UserDetails,
    },
    {
      path: '/groups',
      title: message({
        id: 'Groups',
        comment: 'The sidebar title',
      }),
      hide: !groups.length,
      section: message({
        id: 'Users',
        comment: 'Used as the title for the section.',
      }),
      component: UserGroups,
    },
    {
      path: '/workspace-access',
      title: message({
        id: 'Workspace access',
        comment: 'Tab title for the workspace access tab in the user pages.',
      }),
      section: message({
        id: 'Users',
        comment: 'Used as the title for the section.',
      }),
      component: UserWorkspaceAccess,
      groups,
    },
    {
      path: '/account-access',
      title: message({
        id: 'Account access',
        comment: 'Tab title for the account access tab in the user pages.',
      }),
      section: message({
        id: 'Users',
        comment: 'Used as the title for the section.',
      }),
      component: UserAccountAccess,
    },
  ];

  return ({
    title: message({
      id: 'User',
      comment: 'Tab title for the workspace access tab in the user pages.',
    }),
    showAsLink: false,
    modules: [modules],
    componentDidMount: () => {
      // This query is responsible to decide whether to show the groups tab for a user or not
      queryGroups();
      fetchPosition(match.params.id);
    },
  });
};

export default adminPage(userModule)(User);
