import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  entries,
  uniq,
} from 'lodash';
import { Message } from '@oneflowab/pomes';

import workspacesReducer, { getAllWorkspaces } from 'reducers/entities/workspaces';
import foldersReducer from 'reducers/entities/folders';
import agreementsReducer from 'reducers/entities/agreements';
import bulkOperations, { ACTION_TYPE_MOVE } from 'reducers/entities/bulk-operations';
import { isTemplate } from 'agreement/states';
import ModalForm from 'hocs/modal-form';

import { checkAcl } from 'components/acl';
import Button from 'components/button/button-fc';
import CircularSpinner from 'components/icons/circular-spinner';
import Field from 'components/field';
import FolderTree from 'components/folder-tree';
import { useSelectionContext } from 'components/contract-list/selection-context';
import toast from 'components/toasts';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'components/select-field-radix';
import ChevronDownIcon from 'components/icons/chevron-down';

import { DataRetentionWarning } from './data-retention-warning/data-retention-warning';
import {
  canDocumentBeMoved,
  getDefaultFolderId,
  hasDocumentMovePermission,
  isDocumentAlreadyInTargetDestination,
} from './helpers';
import { CurrencyWarning } from './currency-warning/currency-warning';
import type { SkippedDocumentData } from './types';

import style from './move-contract-step.module.scss';

type FormData = {
  targetWorkspaceId: string,
  targetFolderId: number,
};

type Props = {
  queryName: string,
  onClose: () => void,
  setSkippedDocuments: (items: SkippedDocumentData[]) => void,
  onStepComplete: () => void,
};

type SelectFieldRootProps = {
  onChange?: (value: string) => void,
  onBlur?: () => void,
  disabled?: boolean,
  children: React.ReactNode,
  value: string,
};

const SelectFieldRoot = ({
  children,
  disabled,
  onChange,
  onBlur,
  value,
}: SelectFieldRootProps) => (
  <div className={style.SelectFieldRoot}>
    <Select
      disabled={disabled}
      value={value}
      onValueChange={(_value) => {
        onChange?.(_value);
        onBlur?.();
      }}
    >
      {children}
    </Select>
  </div>
);

SelectFieldRoot.displayName = 'SelectFieldRoot';

const FOLDERS_QUERY = 'folders';

export const MoveContractStep = ({
  queryName,
  onClose,
  setSkippedDocuments,
  onStepComplete,
}: Props) => {
  const dispatch = useDispatch();
  const { selectedDocuments, setSelectedDocuments } = useSelectionContext();
  const allAvailableWorkspaces = useSelector(getAllWorkspaces);
  const addFolderState = useSelector(foldersReducer.getCreateSelector);
  const formState = useSelector(bulkOperations.getCreateSelector);
  const selectedDocumentsData = useSelector((state) => (
    agreementsReducer.getAgreementsSelector(state, {
      ids: entries(selectedDocuments)
        .filter(([_, value]) => value)
        .map(([key]) => Number(key)),
    }) as Oneflow.Agreement[]
  ));

  const foldersQuery = useSelector((state) => (
    foldersReducer.getQuerySelector(state, { name: FOLDERS_QUERY })
  ));
  const folders = useSelector((state) => (
    foldersReducer.getFoldersSelector(state, { ids: foldersQuery.result })
  ));

  const getDefaultWorkspace = () => {
    const selectedDocumentsWorkspaceIds = uniq(
      selectedDocumentsData.map(({ collection }) => collection?.id),
    );

    const allDocumentsAreInTheSameWorkspace = selectedDocumentsWorkspaceIds.length === 1;
    if (allDocumentsAreInTheSameWorkspace) {
      return allAvailableWorkspaces.find(
        (workspaceOption) => workspaceOption.id === selectedDocumentsWorkspaceIds[0],
      );
    }

    return null;
  };

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<number | undefined>(
    () => getDefaultWorkspace()?.id,
  );

  const selectedWorkspace = useSelector((state) => workspacesReducer.getWorkspaceSelector(state, {
    id: selectedWorkspaceId,
  }));

  const [targetFolderId, setTargetFolderId] = useState<number | undefined>(
    () => getDefaultFolderId(selectedDocumentsData),
  );

  const handleSelect = (workspaceId: string) => {
    dispatch(workspacesReducer.fetchWorkspace({
      id: Number(workspaceId),
    }));
    setSelectedWorkspaceId(Number(workspaceId));
    setTargetFolderId(-1);
  };

  const handleSubmit = (formData: FormData) => {
    let action;

    if (queryName) {
      action = () => agreementsReducer.queryAgreementsReload({
        name: queryName,
      });
    }

    const documentsThatCanBeMoved = selectedDocumentsData.filter((document) => (
      canDocumentBeMoved({
        targetFolderId,
        document,
        targetWorkspace: selectedWorkspace,
      })
    ));

    const skippedDocumentData: SkippedDocumentData[] = (
      selectedDocumentsData as Oneflow.Agreement[]
    ).reduce((acc, document) => {
      if (isDocumentAlreadyInTargetDestination({
        targetFolderId,
        document,
        targetWorkspace: selectedWorkspace,
      })) {
        acc.push({
          type: 'alreadyInTargetFolder',
          documentId: document.id,
        } as SkippedDocumentData);
      }

      if (!hasDocumentMovePermission(document)) {
        acc.push({
          type: 'withoutMovePermission',
          documentId: document.id,
        } as SkippedDocumentData);
      }

      return acc;
    }, [] as SkippedDocumentData[]);

    setSkippedDocuments(skippedDocumentData);

    // If the target folder id is -1, it means that no folder was selected and we should't send the
    // folderId in the request
    const selectedFolderId = targetFolderId === -1 ? undefined : targetFolderId;

    const actions: Parameters<typeof bulkOperations.createBulkOperation>[0]['data']['actions'] = documentsThatCanBeMoved
      .map(({ id }) => ({
        actionType: ACTION_TYPE_MOVE,
        actionParams: {
          collectionId: Number(formData.targetWorkspaceId),
          agreementId: id,
          folderId: selectedFolderId,
        },
      }));

    if (documentsThatCanBeMoved.length === 0) {
      onStepComplete();
      return;
    }

    dispatch(bulkOperations.createBulkOperation({
      // TODO: add data
      data: {
        async: false,
        actions,
      },
      pipe: {
        action,
        onSuccess: () => {
          // Unselect the documents that were moved
          setSelectedDocuments((_selectedDocuments) => (
            Object
              .entries(_selectedDocuments)
              .filter(([_, value]) => value)
              .reduce(
                (acc, [documentId]) => {
                  acc[Number(documentId)] = !documentsThatCanBeMoved.some(
                    (doc) => doc.id === Number(documentId),
                  );
                  return acc;
                }, {} as Record<number, boolean>,
              )));

          if (documentsThatCanBeMoved.length === selectedDocumentsData.length) {
            dispatch(agreementsReducer.queryAgreementsReload({
              name: queryName,
            }));
            onClose();
            toast.success({
              id: 'contracts-moved',
              title: (
                <Message
                  id="{count} document moved"
                  pluralId="{count} documents moved"
                  comment="Message for the contracts moved toast."
                  pluralCondition="count"
                  values={{
                    count: documentsThatCanBeMoved.length,
                  }}
                />
              ),
              description: (
                <Message
                  id="To {workspace}{folder}"
                  comment="Description for the notification when documents are moved, showing their destination"
                  values={{
                    workspace: selectedWorkspace?.name || '',
                    folder: targetFolderId && targetFolderId !== -1
                      ? ` / ${folders.find(f => f.id === targetFolderId)?.name || ''}`
                      : '',
                  }}
                />
              ),
              duration: 8000,
            });
          } else {
            onStepComplete();
          }
        },
      },
    }));
  };

  const selectFolderId = (folderId: number) => {
    setTargetFolderId(folderId);
  };

  const renderFolders = () => {
    const hasFolderPermissionForSelectedWorkspace = checkAcl(
      selectedWorkspace?.acl,
      'collection:folder:view',
    );

    const agreeementsAreTemplates = selectedDocumentsData.some(
      (agreement) => isTemplate(agreement),
    );

    if (
      agreeementsAreTemplates
      || selectedWorkspace?.virtual
      || !hasFolderPermissionForSelectedWorkspace
    ) {
      return null;
    }

    return (
      <Field
        name="targetFolderId"
        value={targetFolderId}
        render={({ input: { onChange } }) => (
          <FolderTree
            isModalVersion
            onFolderSelectionHandler={(folderId) => {
              selectFolderId(folderId);
              onChange(folderId);
            }}
            selectedUniqueKey={targetFolderId}
            selectedWorkspaceId={selectedWorkspaceId}
            selectedWorkspaceName={selectedWorkspace?.name}
            selectedWorkspace={selectedWorkspace}
            branchContainerClassName={style.FolderTreeBranchContainer}
          />
        )}
      />
    );
  };

  const workspaceHasMovePermission = (collection: Oneflow.Workspace) => (
    checkAcl(collection.acl, 'collection:agreement:move')
  );

  const getOptions = () => (
    allAvailableWorkspaces.filter(workspaceHasMovePermission)
  );

  const renderBody = () => (
    <div className={style.Body}>
      <Field
        label={(
          <Message
            id="Target workspace"
            comment="Label in the move to... modal."
          />
        )}
        hideLabel
        name="targetWorkspaceId"
        required
        defaultValue={getDefaultWorkspace()?.id.toString()}
        render={({
          input: {
            onChange,
            value,
            onBlur,
            name,
            onFocus,
          },
        }) => (
          <div className={style.FieldItem}>
            <label
              htmlFor={name}
              className={style.Label}
            >
              <Message
                id="Target workspace"
                comment="Label in the move to... modal."
              />
            </label>
            <SelectFieldRoot
              value={value}
              onBlur={onBlur}
              onChange={(_value) => {
                onChange(_value);
                handleSelect(_value);
              }}
            >
              <SelectTrigger
                className={style.SelectTrigger}
                id={name}
                onFocus={onFocus}
              >
                <SelectValue
                  placeholder={(
                    <Message
                      id="Select workspace"
                      comment="Placeholder in the move to... modal."
                    />
                  )}
                />
                <ChevronDownIcon height="10px" />
              </SelectTrigger>
              <SelectContent
                position="popper" // for Firefox
                className={style.SelectContent}
              >
                <SelectViewport>
                  {getOptions().map((workspace) => (
                    <SelectItem
                      key={workspace.id}
                      value={workspace.id.toString()}
                      className={style.SelectItem}
                    >
                      {workspace.name}
                    </SelectItem>
                  ))}
                </SelectViewport>
              </SelectContent>
            </SelectFieldRoot>
          </div>
        )}
      />
      {selectedWorkspaceId && (
        <DataRetentionWarning selectedWorkspaceId={selectedWorkspaceId} />
      )}
      <CurrencyWarning
        selectedDocumentsData={selectedDocumentsData}
        selectedWorkspace={selectedWorkspace}
      />
      {renderFolders()}
    </div>
  );

  const renderActions = ({ formProps }: any) => {
    const isLoading = formState.loading;
    const isFolderLoading = addFolderState.loading;

    // We don't have to check the move permission because:
    // 1. For the workspace of the selected documents, if the user doesn't have move permission for
    //    any of them, they won't be able to open the modal
    // 2. For the target workspace, if the user doesn't have move permission, they won't see it
    //    in the options

    const getIsDisabled = () => {
      if (
        formProps.hasValidationErrors
        || formProps.validating
      ) {
        return true;
      }

      if (isFolderLoading) {
        return true;
      }

      return false;
    };

    return (
      <Button
        data-testid="confirm"
        disabled={getIsDisabled()}
        icon={isLoading || isFolderLoading ? CircularSpinner : null}
        kind="primary"
        onClick={formProps.handleSubmit}
      >
        <Message
          id="Move"
          comment="Text for button to confirm action."
        />
      </Button>
    );
  };

  return (
    <ModalForm
      modalKey="move contract modal"
      title={(
        <Message
          id="Move to..."
          comment="Modal title in the move to... modal."
        />
      )}
      isOpen
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={{
        targetWorkspaceId: getDefaultWorkspace()?.id.toString(),
        targetFolderId: getDefaultFolderId(selectedDocumentsData),
      }}
      body={renderBody()}
      actions={renderActions}
    />
  );
};
