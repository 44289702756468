// @flow

import isEmpty from 'lodash/isEmpty';
import {
  Editor,
  Element,
  Range,
} from 'slate';

export const getActiveImage = (editor: any) => {
  if (isEmpty(editor?.selection) || Range.isExpanded(editor?.selection)) {
    return null;
  }

  try {
    const [match] = Editor.nodes(editor, {
      match: (n) => (
        !Editor.isEditor(n) && Element.isElement(n) && n.type === 'image'
      ),
      mode: 'lowest',
    });

    if (!match) {
      return null;
    }

    return match[0];
  } catch {
    return null;
  }
};
