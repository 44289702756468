// @flow

import { connect } from 'react-redux';
import permissionsReducer from 'reducers/entities/permissions';

import { PermissionsComponent } from './permissions-component';

const QUERY_NAME_PERMISSIONS = 'admin/permissions';

export const mapStateToProps = (state: State) => {
  const query = permissionsReducer.getQuerySelector(state, { name: QUERY_NAME_PERMISSIONS });
  const permissions = permissionsReducer.getPermissionsSelector(state,
    { ids: query.result });

  return {
    permissions,
    permissionsQuery: query,
  };
};

export const mapDispatchToProps = (dispatch: Function) => ({
  queryPermissions: ({ params }: Query) => {
    dispatch(permissionsReducer.queryPermissions({
      name: QUERY_NAME_PERMISSIONS,
      params,
    }));
  },
});

const connectedPermissionsComponent = connect<Permissions, OwnProps, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(PermissionsComponent);

export { connectedPermissionsComponent as PermissionsComponent };
