// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import get from 'lodash/get';

import { getCurrentWorkspaceIdSelector, getCurrentWorkspaceSelector } from 'reducers/app';
import agreementTemplatesReducer, { getTemplatesWithAgreementsSelector } from 'reducers/entities/agreement-templates';
import agreementsReducer from 'reducers/entities/agreements';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';

import { isSharedFromAnotherWorkspace } from 'agreement/selectors';

import TemplateResults from './template-results';

type StateProps = {|
  templates: Array<AgreementTemplate>,
  createState: CreateState,
  workspace: Workspace,
|};

type DispatchProps = {|
  createContract: (Agreement, number, ?number) => void
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  isInImportContractModal: boolean,
  isSearching: boolean,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

const getWorkspaceQuerySelector = (state, { queryName }) => (
  `workspace-${getCurrentWorkspaceIdSelector(state)}::${queryName}`
);

export const mapStateToProps: MapStateToProps = (state, ownProps) => {
  let templates;
  const queryName = getWorkspaceQuerySelector(state, { queryName: 'active-templates' });
  const queryNameForTemplatesWithImportTag = getWorkspaceQuerySelector(state, {
    queryName: 'active-templates-with-import-tag',
  });

  const query = agreementTemplatesReducer.getQuerySelector(state, {
    name: queryName,
  });

  const queryForTemplatesWithImportTag = agreementTemplatesReducer.getQuerySelector(state, {
    name: queryNameForTemplatesWithImportTag,
  });

  const allTemplates = getTemplatesWithAgreementsSelector(state, { ids: query.result });
  const templatesWithImportTag = getTemplatesWithAgreementsSelector(state, {
    ids: queryForTemplatesWithImportTag.result,
  });

  const templateIdsWithImportTag = templatesWithImportTag.map((template) => template.id);

  const templatesWithoutImportTag = allTemplates
    .filter((template) => !templateIdsWithImportTag
      .includes(template.id));

  if (ownProps?.isInImportContractModal) {
    templates = templatesWithImportTag;
  } else {
    templates = templatesWithoutImportTag;
  }

  const createState = agreementsReducer.getCreateSelector(state);

  return {
    templates,
    createState,
    workspace: getCurrentWorkspaceSelector(state),
  };
};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { isInImportContractModal }) => ({
  createContract: (
    agreement: Agreement,
    currentWorkspaceId: number,
    folderId: ?number,
  ) => {
    let data = {
      sourceId: get(agreement, 'id'),
      amplitudeScope: 'main navigation - new contract modal',
      folderId,
    };

    if (isInImportContractModal) {
      data = {
        ...data,
        isImported: 1,
      };
    }

    if (!data.sourceId || isSharedFromAnotherWorkspace(agreement, currentWorkspaceId)) {
      data = {
        ...data,
        workspaceId: currentWorkspaceId,
      };
    }

    dispatch(agreementsReducer.createAgreement({
      data,
      pipe: {
        onSuccess: redirectToDocumentOnDocumentCreate,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateResults);
