import { uniqueId, cloneDeep } from 'lodash';
import { isMobileIOS } from 'utils/browser';
import { getPreviousContentData } from 'components/contract-boxes/generic-box-helpers';
import { PREVIEW } from 'hooks/use-is-in-preview-mode';

export const isVideo = (data) => data.key === 'file';
export const isText = (data) => data.key === 'text';
export const getTextData = (data) => data?.find((d) => isText(d) && !d._removed);
export const getVideoData = (data) => data?.find(((d) => isVideo(d) && !d._removed));

export const getVideoUrl = (agreementId, file, guestToken) => {
  let sourceUrl = `/api/agreements/${agreementId}/video?video_identifier=${file.value.assetIdentifier}`;

  if (guestToken && guestToken !== PREVIEW) {
    sourceUrl = `${sourceUrl}&at=${guestToken}`;
  }

  // Hack to display a thumbnail on mobile iOS device
  if (isMobileIOS()) {
    sourceUrl = `${sourceUrl}#t=0.001`;
  }

  return sourceUrl;
};

export const getVideoPoster = (agreementId, file, guestToken) => {
  const posterUrl = `/api/agreements/${agreementId}/video/thumbnail?video_identifier=${file.value.assetIdentifier}`;

  if (guestToken && guestToken !== PREVIEW) {
    return `${posterUrl}&at=${guestToken}`;
  }

  return posterUrl;
};

const getNewTextData = () => ({
  key: 'text',
  _id: Number(uniqueId()),
  value: {
    nodes: [],
  },
});

const getNewVideoData = (processingData, ownerParticipantId) => ({
  key: 'file',
  _id: Number(uniqueId()),
  value: {
    ownerParticipantId,
    hasFile: 1,
    assetIdentifier: processingData.videoIdentifier,
    assetExtension: 'mp4',
    name: processingData.videoIdentifier,
    assetVideoDimensions: processingData.dimensions,
    assetVideoThumbnailIdentifier: processingData.thumbnailIdentifier,
    size: processingData.fileSize,
  },
});

export const generateVideoBoxData = (
  box,
  processingData,
  ownerParticipantId,
) => {
  const updatedBox = cloneDeep(box);
  const { data } = updatedBox.content;
  const previousContentData = getPreviousContentData(data);
  const newVideoData = getNewVideoData(processingData, ownerParticipantId);

  const getData = () => {
    const isReplacingVideo = (
      Boolean(data.find((d) => isVideo(d))) && Boolean(data.find((d) => isText(d) && !d._removed))
    );
    if (isReplacingVideo) {
      return [
        ...previousContentData,
        newVideoData,
      ];
    }
    return [
      ...previousContentData,
      newVideoData,
      getNewTextData(),
    ];
  };

  updatedBox.content = {
    ...updatedBox.content,
    data: getData(),
  };

  return updatedBox;
};
