import { useCallback } from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import { useAsyncAssetProps } from 'contexts/async-asset-props';
import type { AttachmentBox } from 'data-validators/entity-schemas/document-box/attachment-box';
import type { Asset } from 'reducers/current-contract';

import { generateDocumentOpenURL } from 'components/contract-boxes/generic-box-helpers';
import Button from 'components/button';
import OpenFile from 'components/icons/open-file';
import Remove from 'components/icons/delete';

import style from './attachment-file.module.scss';

type Props = {
  contractId: Oneflow.Agreement['id'];
  guestToken: string;
  hidden?: boolean;
  isAllowedToRemoveData: boolean;
  onAttachmentRemove: () => void;
  uploadingStatus: Asset['status'] | undefined;
  value: AttachmentBox['content']['data'][number]['value'];
};

const AttachmentActionsContainer = ({
  contractId,
  guestToken,
  hidden,
  isAllowedToRemoveData,
  onAttachmentRemove,
  uploadingStatus,
  value,
}: Props) => {
  const { succeededAsyncAssetData } = useAsyncAssetProps();
  const showOpenButton = (!value.status
    || uploadingStatus === 'ready'
    || value.status === 'ready'
    || (
      succeededAsyncAssetData
      && succeededAsyncAssetData.assetId === value.assetId
      && succeededAsyncAssetData.status === 'ready'
    ));

  const className = clsx(style.ActionsContainer, {
    [style.Hidden]: hidden,
  });

  const renderOpenButton = useCallback(() => {
    if (showOpenButton) {
      return (
        <Button
          icon={OpenFile}
          href={generateDocumentOpenURL(contractId, value, guestToken)}
          download
          customClass={style.Hover}
        >
          <Message
            id="Open"
            comment="This text shows that you can download the file"
          />
        </Button>
      );
    }

    return null;
  }, [
    contractId,
    guestToken,
    showOpenButton,
    value,
  ]);

  return (
    <div className={className}>
      {renderOpenButton()}
      {isAllowedToRemoveData && (
        <>
          <Button
            icon={Remove}
            onClick={onAttachmentRemove}
            customClass={style.Remove}
          >
            <Message
              id="Remove"
              comment="This text shows that you can remove the file from here"
            />
          </Button>
        </>
      )}
    </div>
  );
};

export default AttachmentActionsContainer;
