import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import useCurrentMessages from 'hooks/use-current-messages';
import { getGuestToken, getMyParticipantWhenSignatory } from 'agreement/selectors';
import {
  getDataFieldExternalKeyMap,
  getDataFieldExternalKeyValueMap,
  hasContractError,
  setSignAttempted,
} from 'reducers/current-contract';
import { DOCUMENT_TAB, MESSAGES_TAB } from 'agreement/constants';
import useDetachedModalRenderer, { unmountDetachedModal } from 'hooks/use-detached-modal-renderer';
import useCurrentBoxes from 'hooks/use-current-boxes';
import { DELIVERY_CHANNEL_SAME_DEVICE, DELIVERY_CHANNEL_SMS } from 'agreement/participant/constants';
import useCurrentData from 'hooks/use-current-data';

import Tooltip from 'components/tooltip';
import SignContractModal from 'components/modals/sign-contract';
import ResolveAnnotationsModal from 'components/modals/resolve-annotations';
import { EXPANDED } from 'components/document-layout-container/helpers';
import { useCollapsedDocumentLayout } from 'components/document-layout-container/collapsed-document-layout/context';
import { useExpandableSidebarProps } from 'components/document-layout-container/expanded-document-layout/context';
import type { Layout } from 'components/document-layout-container/types';
import FillGuestInformationModal from 'components/document-call-to-actions/actions/modals/fill-guest-information';
import toast from 'components/toasts';

import { getEmptyRequiredElementsCount } from 'reducers/helpers/current-contract';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import SignButtonComponent from './sign-button';
import { handleEmptyRequiredFields } from './handle-empty-required-fields';
import { canBeSignedByMyParticipant } from './helpers';

type Props = {
  agreement: Oneflow.Agreement;
  disabled?: boolean;
  layout?: Layout;
  size?: 'sm';
}

const SignButton = ({
  agreement, disabled = false, layout = EXPANDED, size,
}: Props) => {
  const dispatch = useDispatch();
  const { activeSuggestions = [] } = useCurrentMessages();
  const boxMap = useCurrentBoxes();
  const data = useCurrentData();
  const expandedContext = useExpandableSidebarProps();
  const collapsedContext = useCollapsedDocumentLayout();
  const hasError = useSelector(hasContractError);
  const isSignLaterEnabled = agreement.config?.signLater;
  const myParticipantWhenSignatory = agreement.parties
    ? getMyParticipantWhenSignatory(agreement) : undefined;
  const guestToken = useSelector(getGuestToken);
  const isGuest = Boolean(guestToken);
  const dataFieldExternalKeyValueMap = useSelector(getDataFieldExternalKeyValueMap);
  const dataFieldExternalKeyMap = useSelector(getDataFieldExternalKeyMap);
  const isInPreviewMode = useIsInPreviewMode();

  const hasUnresolvedSuggestions = activeSuggestions.length > 0;

  const canSign = canBeSignedByMyParticipant({
    agreement,
    myParticipant: myParticipantWhenSignatory,
    hasError,
  });

  const openCommentsTabCb = () => {
    if (expandedContext !== null) {
      expandedContext.setExpandedSidebar(true);
    }

    if (collapsedContext !== null) {
      collapsedContext.setActiveTab(MESSAGES_TAB);
    }
  };

  const onClick = (openModal: () => void) => {
    dispatch(setSignAttempted(true));

    if (collapsedContext !== null) {
      const {
        editableEmptyRequiredElementsCount,
        nonEditableEmptyRequiredElementsCount,
      } = getEmptyRequiredElementsCount({
        isGuest,
        boxMap,
        data,
        dataFieldExternalKeyValueMap,
        dataFieldExternalKeyMap,
      });
      const hasMissingRequiredFields = (
        editableEmptyRequiredElementsCount > 0 || nonEditableEmptyRequiredElementsCount > 0
      );

      if (hasMissingRequiredFields) {
        collapsedContext.setActiveTab(DOCUMENT_TAB);
      }
    }

    setTimeout(() => {
      const { hasMissingRequiredFields } = handleEmptyRequiredFields({
        isGuest,
        boxMap,
        data,
        dataFieldExternalKeyValueMap,
        dataFieldExternalKeyMap,
      });

      if (!hasMissingRequiredFields) {
        openModal();
      }
    }, 0);
  };

  const signButtonClickHandler = (openModal: () => void) => {
    if (!isInPreviewMode) {
      return onClick(openModal);
    }

    return toast.warning({
      id: 'sign-not-available-in-preview',
      title: <Message
        id="Not available in preview"
        comment="Title for the warning message when signing is not available in preview mode."
      />,
      duration: 5000,
    });
  };

  const renderButton = (openModal: () => void) => (
    <Tooltip
      side="top"
      message={(
        <Message
          id="Sign later is enabled."
          comment="Tooltip text for sign later is enabled."
        />
      )}
      theme="oneflow"
      hideContent={!isSignLaterEnabled}
    >
      <SignButtonComponent
        onClick={() => signButtonClickHandler(openModal)}
        disabled={disabled || !canSign || Boolean(isSignLaterEnabled)}
        layout={layout}
        size={size}
      >
        <Message
          id="Sign"
          comment="Button label for signing the document"
        />
      </SignButtonComponent>
    </Tooltip>
  );

  const renderSignModal = () => (
    <SignContractModal
      agreementId={agreement.id}
      sendChecksum
      onClose={unmountDetachedModal}
      isOpen
    />
  );
  const handleSignModalTriggerClick = useDetachedModalRenderer(renderSignModal);

  const renderGuestInformationModal = () => (
    <FillGuestInformationModal
      agreement={agreement}
      participant={myParticipantWhenSignatory}
      isOpen
      onClose={unmountDetachedModal}
      openSignModal={handleSignModalTriggerClick}
      guestToken={guestToken}
    />
  );

  const handleGuestInformationModalTrigger = useDetachedModalRenderer(renderGuestInformationModal);

  if (hasUnresolvedSuggestions) {
    return (
      <ResolveAnnotationsModal agreementId={agreement.id} openCommentsTabCb={openCommentsTabCb}>
        {renderButton}
      </ResolveAnnotationsModal>
    );
  }

  const sameDeviceOrSmsFillInformationFlowActive = myParticipantWhenSignatory
    && isGuest
    && !myParticipantWhenSignatory.email
    && (
      myParticipantWhenSignatory.deliveryChannel === DELIVERY_CHANNEL_SAME_DEVICE
      || (myParticipantWhenSignatory.deliveryChannel === DELIVERY_CHANNEL_SMS
          && agreement?.config?.counterpartSelfUpdateAndRemove)
    );

  if (sameDeviceOrSmsFillInformationFlowActive) {
    return renderButton(handleGuestInformationModalTrigger);
  }

  return renderButton(handleSignModalTriggerClick);
};

export default SignButton;
