import { forwardRef } from 'react';
import type { Ref, HTMLAttributes } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { arrayMove } from '@dnd-kit/sortable';
import type { MessageTranslator } from '@oneflowab/pomes';

import { getCurrentBoxOrder, updateBoxOrderAction } from 'reducers/current-contract';
import useBoxItem from 'hooks/use-box-item';
import ChevronUpIcon from 'components/icons/chevron-up';
import ChevronDownIcon from 'components/icons/chevron-down';
import DragHandlerIcon from 'components/icons/drag-handler';
import styles from './drag-controls.module.scss';

type Props = {
  index: number;
  boxId: number;
  className?: string;
  message: MessageTranslator;
} & HTMLAttributes<HTMLDivElement>;

const DragControls = forwardRef(({
  index,
  boxId,
  className,
  message,
  ...props
}: Props, ref: Ref<HTMLDivElement>) => {
  const dispatch = useDispatch();
  const boxOrder = useSelector(getCurrentBoxOrder);
  const { isSingle, isFirst, isLast } = useBoxItem(boxId);

  const handleMoveUp = () => {
    dispatch(updateBoxOrderAction(arrayMove(boxOrder, index, index - 1)));
  };

  const handleMoveDown = () => {
    dispatch(updateBoxOrderAction(arrayMove(boxOrder, index, index + 1)));
  };

  if (isSingle) {
    return null;
  }

  return (
    <div className={clsx(styles.DragControls, className)}>
      {!isFirst && (
        <button
          type="button"
          className={styles.Chevron}
          onClick={handleMoveUp}
          data-testid="move-up"
          aria-label={message({
            id: 'Move up',
            comment: 'Button that moves list item one position up',
          }) as string}
        >
          <ChevronUpIcon height="10px" />
        </button>
      )}
      <div
        {...props}
        ref={ref}
        className={styles.DragHandler}
      >
        <DragHandlerIcon height="15px" />
      </div>
      {!isLast && (
        <button
          type="button"
          className={styles.Chevron}
          onClick={handleMoveDown}
          data-testid="move-down"
          aria-label={message({
            id: 'Move down',
            comment: 'Button that moves list item one position down',
          }) as string}
        >
          <ChevronDownIcon height="10px" />
        </button>
      )}
    </div>
  );
});

DragControls.displayName = 'DragControls';

export default DragControls;
