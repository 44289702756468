// @flow

import { connect } from 'react-redux';
import get from 'lodash/get';
import type { Dispatch } from 'redux';

import { isAgreementOwner, getGuestToken } from 'agreement/selectors';
import agreementsReducer from 'reducers/entities/agreements';
import workspacesReducer from 'reducers/entities/workspaces';
import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';
import { getAgreementDateFormat } from 'date';

import ParticipantList, { type Props } from './participant-list';

type StateProps = {|
  agreement: Agreement,
  position: Position,
  account: Account,
  dateFormat: string,
  isOwner?: boolean,
  workspace: Workspace,
  updateState: UpdateState,
  guestToken?: string,
  |};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  agreementId: number,
};

type MapStateToProps = (State, OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state: State, { agreementId }: OwnProps) => {
  const accountFromSession = getAccountFromSessionSelector(state)
    ? getAccountFromSessionSelector(state) : undefined;
  const position = getPositionFromSessionSelector(state);
  const agreement = agreementsReducer.getAgreementSelector(state, { id: agreementId });
  const agreementDateFormat = agreement.config
    ? getAgreementDateFormat(agreement.config.dateFormat) : undefined;
  const updateState = agreementsReducer.getUpdateSignOrderSelector(state, {
    id: agreementId,
  });
  const guestToken = getGuestToken(state);
  const isOwner = accountFromSession && agreement.account
    ? isAgreementOwner(accountFromSession, agreement) : undefined;

  return {
    guestToken,
    agreement,
    position,
    account: getAccountFromSessionSelector(state),
    dateFormat: agreementDateFormat,
    isOwner,
    workspace: workspacesReducer.getWorkspaceSelector(state, {
      id: get(agreement, 'collection.id'),
    }),
    updateState,
  };
};

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
)(ParticipantList);
