import { memo } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';
import { Message, MessageTranslator, localize } from '@oneflowab/pomes';
import clsx from 'clsx';

import { getCurrentContractId } from 'reducers/current-contract';
import useAgreement from 'hooks/use-agreement';
import { getSignedParticipants } from 'agreement/selectors';

import Button from 'components/button';
import Confirmable from 'components/confirmable';
import SaveChangesButton from 'components/document-call-to-actions/actions/buttons-call-to-action/actions/save';

import ModalBody from './modal-parts/body';

import style from './save-changes-modal.module.scss';

type Props = {
  onSaveChanges: () => void;
  isOpen: boolean;
  onCancel: () => void;
  message: MessageTranslator;
  isSuggestion: boolean,
  shouldShowSaveSuccessToast: boolean;
}

export const SaveChangesModal = ({
  onSaveChanges,
  isOpen,
  onCancel,
  message,
  isSuggestion,
  shouldShowSaveSuccessToast,
}: Props) => {
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const willResetSignatures = Boolean(getSignedParticipants(agreement).length);

  const renderActions = () => (
    <div className={style.ActionsContainer}>
      <Button
        onClick={onCancel}
        kind="transparent"
      >
        <Message
          id="Cancel"
          comment="Text for button to close the modal for adding link to contract."
        />
      </Button>
      <SaveChangesButton
        agreementId={agreementId}
        onSaveAgreement={onSaveChanges}
        message={message}
        buttonClassName={style.SaveButton}
        modalClassName="rich-text-region"
        shouldShowSaveSuccessToast={shouldShowSaveSuccessToast}
      >
        {willResetSignatures ? (
          <Message
            id="Save and reset"
            comment="Text for button to save the changes done to the contract and reset signatures."
          />
        ) : (
          <Message
            id="Save changes"
            comment="Text for button to save the changes done to the contract."
          />
        )}
      </SaveChangesButton>
    </div>
  );

  const getHeader = () => {
    if (willResetSignatures) {
      return (
        <Message
          id="Save changes and reset signatures?"
          comment="Title for modal where you are prompted to save changes."
        />
      );
    }

    return (
      <Message
        id="Save changes"
        comment="Title for modal where you are prompted to save changes."
      />
    );
  };

  return (
    <Confirmable
      customModalClass={clsx(style.Modal, 'rich-text-region', { [style.ModalSmall]: !willResetSignatures })}
      customBodyClass={style.ModalBody}
      header={getHeader()}
      modalKey="save changes modal"
      isOpen={isOpen}
      onCancel={onCancel}
      body={<ModalBody isSuggestion={isSuggestion} />}
      actions={renderActions}
    >
      {noop}
    </Confirmable>

  );
};

export default memo(localize(SaveChangesModal));
