// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getCurrentLanguageSelector } from 'reducers/i18n';
import { getAccountFromSessionSelector } from 'reducers/session';

import Sms from './sms';
import type { Props } from './sms';

type StateProps = {|
  accountCountry: string,
    currentLanguage: string,
|};
type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  accountCountry: getAccountFromSessionSelector(state).country,
  currentLanguage: getCurrentLanguageSelector(state),
});

type DispatchProps = $Exact<{}>;
type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect < Props, any, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(Sms);
