import clsx from 'clsx';

import DragHandlerIcon from 'components/icons/drag-handler';

import { OverNonPdfSectionsTooltip } from 'components/document-tabs/components/over-non-pdf-sections-tooltip';
import style from './idle-drag-overlay-field.module.scss';

type Props = {
  dataFieldName?: string | undefined,
  state?: string | undefined,
  isOverNonPdfSections: boolean,
}

const IdleDragOverlayField = ({
  dataFieldName,
  state,
  isOverNonPdfSections,
}: Props) => {
  if (!dataFieldName) {
    return null;
  }

  return (
    <div className={clsx(style.IdleDragOverlayField, {
      [style.Error]: state === 'error',
      [style.IsOverNonPdfSections]: isOverNonPdfSections,
    })}
    >
      {isOverNonPdfSections ? <OverNonPdfSectionsTooltip /> : null}
      <div className={style.DragHandler}>
        <DragHandlerIcon width="8px" height="13px" />
      </div>
      <div className={style.DataField}>
        <span className={style.DataFieldName} data-testid="data-field-item-name">
          {dataFieldName}
        </span>
      </div>
    </div>
  );
};

export default IdleDragOverlayField;
