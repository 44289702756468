import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { checkAcl } from 'components/acl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import type { MessageTranslator } from '@oneflowab/pomes';

import rolesReducer from 'reducers/entities/roles';
import { getAccountFromSessionSelector } from 'reducers/session';

import LockIcon from 'components/icons/lock';
import AddIcon from 'components/icons/add';
import ActionBar from 'components/action-bar';
import ActionsMenu from 'components/actions-menu';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { EditMenuItem } from 'components/menu-items/edit';
import { EditPermissionsMenuItem } from 'components/menu-items/edit-permissions';
import AddRole from 'components/modals/add-role';
// eslint-disable-next-line import/named
import { RemoveRole } from 'components/modals/remove-role';
import Conditional from 'components/conditional';
import Pagination from 'components/pagination';
// eslint-disable-next-line import/named
import { Table } from 'components/table';
import Button from 'components/button';
import Message from 'components/message/message';
import Tooltip from 'components/tooltip/tooltip';

import adminPage from 'hocs/admin-page';
import { isRoleEditable } from 'role';
import RoleDetails from './role-details';

import style from './roles.module.scss';

type Props = {
  message: MessageTranslator,
};

type RoleAclKeys = keyof NonNullable<Oneflow.Role['acl']>

const QUERY_NAME = 'admin/roles';

export const RolesComponent = ({
  message,
}: Props) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountFromSessionSelector) as Oneflow.Account;
  const rolesQuery = useSelector(
    (state) => rolesReducer.getQuerySelector(state, { name: QUERY_NAME }),
  );
  const roles = useSelector(
    (state) => rolesReducer.getRolesSelector(state, { ids: rolesQuery.result }),
  );

  const queryRoles = useCallback(({ pagination, params }:
    { pagination?: RequestParams.Pagination, params?: typeof rolesQuery.params }) => {
    dispatch(rolesReducer.queryRoles({
      name: QUERY_NAME,
      pagination: {
        ...pagination,
        // should be same as query limit in add role modal and remove role button for consistency
        limit: 50,
      },
      sort: ['-type', 'name'],
      params,
    }));
  }, [dispatch, rolesQuery]);

  const editLinkPath = useCallback((roleId: number) => {
    const editLink = `/admin/roles/${roleId}/details/?edit=true`;

    dispatch(push(editLink));
  }, [dispatch]);

  const editPermissionsLinkPath = useCallback((roleId: number) => {
    const editPermissionsLink = `/admin/roles/${roleId}/permissions/?edit=true`;

    dispatch(push(editPermissionsLink));
  }, [dispatch]);

  useEffect(() => {
    queryRoles({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canEditWithAclCheck = useCallback((role: Oneflow.Role, acl: RoleAclKeys) => {
    if (!isRoleEditable(role)) {
      return false;
    }

    return checkAcl(role.acl, acl);
  }, []);

  // eslint-disable-next-line react/display-name
  const getDeleteMenuItem = useCallback((role: Oneflow.Role) => (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!canEditWithAclCheck(role, 'role:remove')}
    />
  ), [canEditWithAclCheck]);

  const renderRole = useCallback((role: Oneflow.Role) => {
    if (!isRoleEditable(role)) {
      return (
        <span className={style.IconContainer}>
          <Link key={role.id} to={`/admin/roles/${role.id}`}>
            <div>{role.name}</div>
          </Link>
          <LockIcon height="14px" />
        </span>
      );
    }

    return (
      <span className={style.IconContainer}>
        <Link key={role.id} to={`/admin/roles/${role.id}`}>
          <div>{role.name}</div>
        </Link>
      </span>
    );
  }, []);

  const renderPermissionsCount = useCallback((role: Oneflow.Role) => `${role.permissions?.length} / ${role.maxPermissionCount}`, []);

  const renderAccessArea = useCallback((role: Oneflow.Role) => {
    const roleScope = `${role.scope}`.charAt(0).toUpperCase() + `${role.scope}`.slice(1);

    if (!roleScope) {
      return null;
    }

    switch (roleScope) {
      case 'Account':
        return message({
          id: 'Account',
          comment: 'Access area for the role in the scope columns',
        });

      case 'Workspace':
        return message({
          id: 'Workspace',
          comment: 'Access area for the role in the scope column',
        });
      default:
        return roleScope;
    }
  }, [message]);

  const getActions = useCallback((role: Oneflow.Role) => {
    const handleEditOnClick = () => editLinkPath(role.id);
    const handleEditPermissionsOnClick = () => editPermissionsLinkPath(role.id);

    const ActionsMenuComponent = (
      <ActionsMenu
        actions={[
          <EditMenuItem
            disabled={!canEditWithAclCheck(role, 'role:update:name')}
            onClick={handleEditOnClick}
            key="edit-role"
          />,
          <EditPermissionsMenuItem
            disabled={!canEditWithAclCheck(role, 'role:update:permissions')}
            onClick={handleEditPermissionsOnClick}
            key="edit-permissions"
          />,
          <RemoveRole role={role} key="remove-role">
            {getDeleteMenuItem(role)}
          </RemoveRole>,
        ]}
        customClassName={style.ActionMenu}
        disabled={!isRoleEditable(role)}
        focusOnCloseDisabled
      />
    );

    if (!isRoleEditable(role)) {
      return (
        <Tooltip
          messageClassName={style.TooltipText}
          side="top"
          message={(
            <Message
              id="Predefined system role cannot be edited."
              comment="Tooltip for system role's actions column."
            />
          )}
          theme="oneflow"
          zIndex="10003"
        >
          {ActionsMenuComponent}
        </Tooltip>
      );
    }

    return ActionsMenuComponent;
  }, [editLinkPath, editPermissionsLinkPath, canEditWithAclCheck, getDeleteMenuItem]);

  const getAddRoleButton = useCallback((onClick: () => void) => {
    const hasCreateWorkspaceRolePermission = checkAcl(account.acl, 'account:workspace_role:create');
    const hasCreateAccountRolePermission = checkAcl(account.acl, 'account:account_role:create');

    const hasWorkspaceOrAccountRolePermission = (
      hasCreateWorkspaceRolePermission || hasCreateAccountRolePermission
    );

    return (
      <Button
        key="create-role-modal"
        icon={AddIcon}
        kind="primary"
        data-testid="create-user-role"
        onClick={onClick}
        disabled={!hasWorkspaceOrAccountRolePermission}
      >
        <Message
          id="Create role"
          comment="The text shown in the button for creating a new role"
        />
      </Button>
    );
  }, [account.acl]);

  const getTableConfig = useCallback(() => {
    const actions = [];

    const columns = [
      {
        name: 'role',
        label: message({
          id: 'Role',
          comment: 'Label in roles list for the role column',
        }),
        type: 'cell',
        value: renderRole,
      },
      {
        name: 'permissions',
        label: message({
          id: 'Permissions',
          comment: 'Label in roles list for the permissions column',
        }),
        type: 'cell',
        value: renderPermissionsCount,
      },
      {
        name: 'access area',
        label: message({
          id: 'Access area',
          comment: 'Label in roles list for the access area column',
        }),
        type: 'cell',
        value: renderAccessArea,
      },
      {
        name: 'actions',
        label: message({ id: 'Actions', comment: 'Column label in users page' }),
        type: 'actions',
        value: getActions,
      },
    ];

    return {
      items: roles,
      itemKey: 'id',
      actions,
      columns,
    };
  }, [message, roles, renderRole, renderPermissionsCount, renderAccessArea, getActions]);

  const handlePageChange = useCallback(({ offset, limit }: RequestParams.Pagination) => {
    queryRoles({
      params: rolesQuery.params,
      pagination: {
        offset,
        limit,
      },
    });
  }, [queryRoles, rolesQuery.params]);

  return (
    <div className={style.RoleList}>
      <ActionBar>
        <ActionBar.Group>
          <AddRole>
            {getAddRoleButton}
          </AddRole>
        </ActionBar.Group>
      </ActionBar>
      <Table
        config={getTableConfig()}
        query={rolesQuery}
      />
      <Conditional ifCondition={!rolesQuery.loading && rolesQuery.count > 0}>
        <Pagination
          totalItems={rolesQuery.count}
          itemsPerPage={rolesQuery.pagination.limit}
          currentOffset={rolesQuery.pagination.offset}
          onChange={handlePageChange}
          entityName={message({
            id: 'roles',
            comment: 'Plural form in pagination of roles.',
          })}
        />
      </Conditional>
    </div>
  );
};

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Roles',
    comment: 'The page title',
  }),
  modules: [[{
    path: '/:id(\\d+)',
    title: '',
    component: RoleDetails,
  }]],
});

export default adminPage(propsMapper)(RolesComponent);
