// @flow

import * as React from 'react';
import clsx from 'clsx';

import { Message } from '@oneflowab/pomes';

import WarningIcon from 'components/icons/warning';
import style from './internal-reminder-body.module.scss';

type Props = {
  contract: Agreement,
  recipients: Array<any>,
}

function RecipientsValidation(props: Props) {
  const { contract, recipients } = props;
  const hasAnyWithoutAccess = React.useMemo(
    () => contract?.id && recipients?.some((recipient) => recipient.needsAccess),
    [contract?.id, recipients],
  );

  return (
    <div
      className={clsx(style.WarningContainer, {
        [style.RecipientsNoAccessWarning]: hasAnyWithoutAccess,
      })}
    >
      {hasAnyWithoutAccess && (
        <>
          <WarningIcon className={style.WarningIcon} height="14px" />
          <span className={style.WarningText}>
            <Message
              id="Some of your colleagues don't have access to the contract. They will still receive this reminder but will not be able to open the contract."
              comment="Warning message if a colleague dont have access to a specific contract"
            />
          </span>
        </>
      )}
    </div>
  );
}

export default RecipientsValidation;
