import getFontAssetsDomain from './get-font-asset-domain.js';

const loadGelasio = () => {
  const normalGelasio = new FontFace(
    'Gelasio',
    `url(${getFontAssetsDomain()}/resources/fonts/gelasio/gelasio_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicGelasio = new FontFace(
    'Gelasio',
    `url(${getFontAssetsDomain()}/resources/fonts/gelasio/gelasio_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldGelasio = new FontFace(
    'Gelasio',
    `url(${getFontAssetsDomain()}/resources/fonts/gelasio/gelasio_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicGelasio = new FontFace(
    'Gelasio',
    `url(${getFontAssetsDomain()}/resources/fonts/gelasio/gelasio_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalGelasio, italicGelasio, boldGelasio, boldItalicGelasio];
};

export default loadGelasio;
