/* eslint-disable no-param-reassign */
// @flow

import React, { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';

import BoldIcon from 'components/icons/bold';
import BulletedListIcon from 'components/icons/bulleted-list';
import ItalicIcon from 'components/icons/italic';
import NumberedListIcon from 'components/icons/numbered-list';
import UnderlineIcon from 'components/icons/underline';
import StrikethroughIcon from 'components/icons/strikethrough';
import SuperscriptIcon from 'components/icons/superscript';
import SubscriptIcon from 'components/icons/subscript';

import ApplyColorButton from 'components/rich-text-editor-toolbars/toolbar-buttons/apply-color-button';
import {
  AddTableButton,
  AlignButton,
  BlockButton,
  ClearFormattingButton,
  DataFieldsButton,
  HorizontalLineButton,
  ImageButton,
  IndentButton,
  LinkButton,
  MarkButton,
  PageBreakButton,
  TextAlignDropdownMenu,
} from 'components/rich-text-editor-toolbars/toolbar-buttons';
import AIAssistButton from 'components/rich-text-editor-toolbars/toolbar-buttons/ai-assist-button';

type ResponsiveToolbarPlugin = 'separator' | ({ isMenuItem?: boolean, disabled?: boolean }) => ReactNode;

export const boldPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <MarkButton
    key="bold"
    label={(
      <Message
        id="Bold"
        comment="The label of the editor's toolbar plugin that makes a text Bold."
      />
    )}
    isMenuItem={isMenuItem}
    disabled={disabled}
    format="bold"
    icon={<BoldIcon />}
  />
);

export const italicPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <MarkButton
    key="italic"
    label={(
      <Message
        id="Italic"
        comment="The label of the editor's toolbar plugin that makes a text Italic."
      />
    )}
    isMenuItem={isMenuItem}
    disabled={disabled}
    format="italic"
    icon={<ItalicIcon />}
  />
);

export const underlinePlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <MarkButton
    key="underline"
    label={(
      <Message
        id="Underline"
        comment="The label of the editor's toolbar plugin that adds an Underline to a text."
      />
    )}
    isMenuItem={isMenuItem}
    disabled={disabled}
    format="underline"
    icon={<UnderlineIcon />}
  />
);

export const strikethroughPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <MarkButton
    key="strikethrough"
    label={(
      <Message
        id="Strikethrough"
        comment="The label of the editor's toolbar plugin that adds an Strikethrough to a text."
      />
    )}
    isMenuItem={isMenuItem}
    disabled={disabled}
    format="strikethrough"
    icon={<StrikethroughIcon />}
  />
);

export const superscriptPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <MarkButton
    key="superscript"
    label={(
      <Message
        id="Superscript"
        comment="The label of the editor's toolbar plugin that creates a Superscript."
      />
    )}
    isMenuItem={isMenuItem}
    disabled={disabled}
    format="superscript"
    icon={<SuperscriptIcon />}
  />
);

export const subscriptPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <MarkButton
    key="subscript"
    label={(
      <Message
        id="Subscript"
        comment="The label of the editor's toolbar plugin that creates a Subscript."
      />
    )}
    isMenuItem={isMenuItem}
    disabled={disabled}
    format="subscript"
    icon={<SubscriptIcon />}
  />
);

export const forwardPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <IndentButton
    key="forward"
    label={(
      <Message
        id="Increase indent"
        comment="The label of the editor's toolbar plugin that increase the indent."
      />
    )}
    isMenuItem={isMenuItem}
    direction="forward"
    disabled={disabled}
  />
);

export const backwardPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <IndentButton
    key="backward"
    label={(
      <Message
        id="Decrease indent"
        comment="The label of the editor's toolbar plugin that decrease the indent."
      />
    )}
    isMenuItem={isMenuItem}
    direction="backward"
    disabled={disabled}
  />
);

export const alignToLeftPlugin: ResponsiveToolbarPlugin = ({ isMenuItem }) => (
  <AlignButton
    key="left"
    label={(
      <Message
        id="Align to left"
        comment="The label of the editor's toolbar plugin that alignes a text to the left."
      />
    )}
    isMenuItem={isMenuItem}
    direction="left"
  />
);

export const alignToCenterPlugin: ResponsiveToolbarPlugin = ({ isMenuItem }) => (
  <AlignButton
    key="center"
    label={(
      <Message
        id="Align to center"
        comment="The label of the editor's toolbar plugin that alignes a text to the center."
      />
    )}
    isMenuItem={isMenuItem}
    direction="center"
  />
);

export const alignToRightPlugin: ResponsiveToolbarPlugin = ({ isMenuItem }) => (
  <AlignButton
    key="right"
    label={(
      <Message
        id="Align to right"
        comment="The label of the editor's toolbar plugin that alignes a text to the right."
      />
    )}
    isMenuItem={isMenuItem}
    direction="right"
  />
);

export const alignTextPlugin: ResponsiveToolbarPlugin = ({ isMenuItem }) => {
  if (isMenuItem) {
    return (
      <>
        {alignToLeftPlugin({ isMenuItem })}
        {alignToCenterPlugin({ isMenuItem })}
        {alignToRightPlugin({ isMenuItem })}
      </>
    );
  }
  return <TextAlignDropdownMenu key="align-button" />;
};

alignTextPlugin.width = 72;

export const bulletedListPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <BlockButton
    key="bulleted-list"
    label={(
      <Message
        id="Bulleted list"
        comment="The label of the editor's toolbar plugin that adds a Bulleted list."
      />
    )}
    isMenuItem={isMenuItem}
    disabled={disabled}
    type="bulleted-list"
    icon={<BulletedListIcon />}
  />
);

export const numberedListPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <BlockButton
    key="numbered-list"
    label={(
      <Message
        id="Numbered list"
        comment="The label of the editor's toolbar plugin that adds a Numbered list."
      />
    )}
    isMenuItem={isMenuItem}
    disabled={disabled}
    type="numbered-list"
    icon={<NumberedListIcon />}
  />
);

export const clearFormattingPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <ClearFormattingButton
    key="clear-formatting"
    isMenuItem={isMenuItem}
    disabled={disabled}
  />
);

export const pageBreakPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <PageBreakButton
    key="page-break"
    isMenuItem={isMenuItem}
    disabled={disabled}
  />
);

export const horizontalLinePlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <HorizontalLineButton
    key="horizontal-line"
    isMenuItem={isMenuItem}
    disabled={disabled}
  />
);

export const linkPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <LinkButton
    key="link"
    isMenuItem={isMenuItem}
    disabled={disabled}
  />
);

export const dataFieldsPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <DataFieldsButton
    key="data-field"
    isMenuItem={isMenuItem}
    disabled={disabled}
  />
);

export const addTablePlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <AddTableButton
    key="add-table"
    isMenuItem={isMenuItem}
    disabled={disabled}
  />
);

export const imagePlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <ImageButton
    key="image"
    isMenuItem={isMenuItem}
    disabled={disabled}
  />
);

export const applyTextColorPlugin: ResponsiveToolbarPlugin = ({ isMenuItem }) => (
  <ApplyColorButton applyTo="text" key="apply-text-color" isMenuItem={isMenuItem} />
);

export const applyTextBackgroundColorPlugin: ResponsiveToolbarPlugin = ({ isMenuItem }) => (
  <ApplyColorButton applyTo="textBackground" key="apply-text-background-color" isMenuItem={isMenuItem} />
);

export const aiPlugin: ResponsiveToolbarPlugin = ({ isMenuItem, disabled }) => (
  <AIAssistButton key="AI-assist" disabled={disabled} isMenuItem={isMenuItem} />
);

aiPlugin.width = 72;

export const responsiveToolbarPlugins: Array<ResponsiveToolbarPlugin> = [
  underlinePlugin,
  strikethroughPlugin,
  applyTextColorPlugin,
  applyTextBackgroundColorPlugin,
  'separator',
  imagePlugin,
  addTablePlugin,
  dataFieldsPlugin,
  linkPlugin,
  aiPlugin,
  'separator',
  alignTextPlugin,
  forwardPlugin,
  backwardPlugin,
  bulletedListPlugin,
  numberedListPlugin,
  'separator',
  clearFormattingPlugin,
  pageBreakPlugin,
  horizontalLinePlugin,
  'separator',
  superscriptPlugin,
  subscriptPlugin,
];
