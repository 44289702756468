/* eslint-disable import/named */
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  clone,
  get,
  remove,
} from 'lodash';
import {
  Form,
  FormProps,
} from 'react-final-form';
import {
  Message,
} from '@oneflowab/pomes';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import clsx from 'clsx';
import Media from 'react-media';

import { isBoxDataRemoveAllowed } from 'agreement/box-data-remove-permissions';
import { isBoxDataSharedValueUpdateAllowed } from 'agreement/box-data-shared-value-update-permissions';
import {
  isConcluded,
  isTemplate,
} from 'agreement/states';
import {
  getAttachedFilesTotalCount,
  getAttachedFilesTotalSize,
  updateBoxAction,
  updateBoxDataItemAction,
  updateTotalFileCountAndSize,
} from 'reducers/current-contract';
import { useAsyncAssetProps } from 'contexts/async-asset-props';
import { useFileUploadBoxProps } from 'contexts/file-upload-box-props';
import type { AttachmentBox } from 'data-validators/entity-schemas/document-box/attachment-box';

import {
  getId,
  removeContentDataItems,
} from 'components/contract-boxes/generic-box-helpers';
import { getIcons } from 'components/contract-boxes/attachment-box/attachment-box-helpers';
import {
  Popover,
  PopoverContent,
} from 'components/popover';
import { showAssetFailedToast } from 'components/asset-error-toasts';
import Button from 'components/button';
import DragHandlerIcon from 'components/icons/drag-handler';
import Field from 'components/field';
import PopoverCloseButton from 'components/buttons/popover-close-button';
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';
import TextField from 'components/text-field';

import AttachmentActionsContainer from './attachment-actions-container';
import style from './attachment-file.module.scss';

const NAME_MAX_LENGTH = 130;

type Props = {
  activeAttachmentId: number | null,
  attachmentData: AttachmentBox['content']['data'][number],
  setActiveAttachmentId: (id: number | null) => void,
}

const AttachmentFile = ({
  activeAttachmentId,
  attachmentData,
  setActiveAttachmentId,
}: Props) => {
  const {
    agreement,
    box,
    contractId,
    guestToken,
    isAllowedToReorder,
    isOwner,
    setUploadedFileStatus,
    uploadedFileStatus,
  } = useFileUploadBoxProps();
  const [attachmentHovered, setAttachmentHovered] = useState(false);
  const { failedAsyncAssetData } = useAsyncAssetProps();
  const dispatch = useDispatch();
  const totalFileCount = useSelector(getAttachedFilesTotalCount);
  const totalFileSize = useSelector(getAttachedFilesTotalSize);

  const value = get(attachmentData, 'value') as AttachmentBox['content']['data'][number]['value'];
  const assetId = get(value, 'assetId');
  const boxId = getId(box);
  const dataId = getId(attachmentData);
  const savedFileStatus = get(value, 'status');
  const uploadingStatus = assetId ? uploadedFileStatus[assetId] : undefined;

  const isAllowedToEditName = isBoxDataSharedValueUpdateAllowed(box, attachmentData);
  const isAllowedToRemoveData = isBoxDataRemoveAllowed(box, attachmentData);
  const nameEditableOrFileRemovable = isAllowedToEditName || isAllowedToRemoveData;
  const showFailedStatus = uploadingStatus === 'failed'
    || savedFileStatus === 'failed'
    || (failedAsyncAssetData
      && failedAsyncAssetData.assetId === assetId
      && failedAsyncAssetData.status === 'failed');

  useEffect(() => {
    if (savedFileStatus === 'failed') {
      setUploadedFileStatus((prev) => ({
        ...prev,
        [assetId]: savedFileStatus,
      }));
    }
  }, [
    assetId,
    savedFileStatus,
    setUploadedFileStatus,
    value,
  ]);

  const saveFileName = useCallback((attachmentName: string) => {
    const updatedAttachmentValue: Partial<AttachmentBox['content']['data'][number]> = {
      value: {
        ...value,
        name: attachmentName,
      },
    };

    dispatch(updateBoxDataItemAction(boxId, dataId, updatedAttachmentValue));
  }, [boxId, dataId, dispatch, value]);

  const onFormSubmit = (formProps: FormProps<{ name: string }>) => {
    const formPristine = Boolean(formProps?.pristine);

    if (formPristine) {
      return;
    }

    saveFileName(formProps.values?.name);
  };

  useEffect(() => {
    if (value.status === 'failed') {
      showAssetFailedToast();
    }
  }, [
    dataId,
    value.status,
  ]);

  const handleOnKeyDown = () => undefined;
  const handleClick = () => {
    setActiveAttachmentId(dataId);
  };

  const onAttachmentRemove = useCallback(() => {
    const order = clone(box.config.order);
    remove(order, (orderObject) => getId(orderObject) === getId(attachmentData));
    const newData = removeContentDataItems(box, [getId(attachmentData)]);

    dispatch(updateBoxAction({
      ...box,
      content: {
        ...box?.content,
        data: newData,
      },
      config: {
        ...box?.config,
        order,
      },
    }));

    dispatch(updateTotalFileCountAndSize(totalFileCount - 1, totalFileSize - (value.size || 0)));
  }, [attachmentData, box, dispatch, totalFileCount, totalFileSize, value.size]);

  const renderAttachmentDragHandler = (isDesktop: boolean) => {
    const showDragHandler = !isConcluded(agreement)
    && (isTemplate(agreement) || (isDesktop && isOwner));

    if (!showDragHandler) {
      return <span className={style.NoDragHandler} />;
    }

    return (
      <Button
        customClass={clsx(style.DragHandler, 'attachment-drag-handler')}
        icon={<DragHandlerIcon height="16px" />}
        disabled={!isAllowedToReorder}
      />
    );
  };

  const renderAttachmentInfoContainer = () => {
    const fileNameWrapperClasses = clsx(style.FileNameWrapper, {
      [style.NameFailed]: showFailedStatus,
    });
    return (
      <div className={style.AttachmentInfoContainer}>
        <div className={style.Icons}>
          {getIcons(value?.assetExtension as string)}
        </div>
        <div>
          {isAllowedToEditName
            ? (
              <Popover>
                <PopoverTriggerButton
                  customClass={clsx(style.PopoverNameButton, {
                  })}
                >
                  <span className={fileNameWrapperClasses}>
                    {`${value?.name}.${value?.assetExtension}`}
                  </span>
                </PopoverTriggerButton>
                <div className={style.PopoverContentContainer}>
                  <PopoverContent
                    side="top"
                    className={style.PopoverContent}
                    hideWhenDetached
                  >
                    <Form
                      onSubmit={onFormSubmit}
                      initialValues={{
                        name: value?.name,
                      }}
                      render={(formProps) => (
                        <form
                          onSubmit={formProps.handleSubmit}
                          autoComplete="off"
                        >
                          <div className={style.PopupHeader}>
                            <Message
                              id="Edit name"
                              comment="Header for the form where you can edit the name of attachment box."
                            />
                          </div>
                          <div className={style.NameContainer}>
                            <Field
                              name="name"
                              value={value?.name}
                              component={TextField}
                              label={(
                                <Message
                                  id="Name"
                                  comment="Label for selecting expiry date for the contract"
                                />
                            )}
                              maxLength={NAME_MAX_LENGTH}
                              required
                            />
                          </div>
                          <div className={style.Row}>
                            <PopoverCloseButton
                              customClass={style.ButtonCancel}
                            >
                              <Message
                                id="Cancel"
                                comment="Action to cancel the editing of attachment name"
                              />
                            </PopoverCloseButton>
                            <PopoverCloseButton
                              customClass={style.ButtonSave}
                              onClick={() => onFormSubmit(formProps)}
                              disabled={!formProps.valid}
                              type="submit"
                            >
                              <Message
                                id="Save"
                                comment="Action to save the edited attachment name"
                              />
                            </PopoverCloseButton>
                          </div>
                        </form>
                      )}
                    />
                  </PopoverContent>
                </div>
              </Popover>
            )
            : (
              <span className={fileNameWrapperClasses}>
                {`${value?.name}.${value?.assetExtension}`}
              </span>
            )}
        </div>
      </div>
    );
  };

  // eslint-disable-next-line react/display-name
  const renderAttachmentRow = (isDesktop: boolean) => () => {
    const actionsContainerProps = {
      contractId,
      guestToken,
      isAllowedToRemoveData,
      onAttachmentRemove,
      uploadingStatus,
      value,
    };

    if (isDesktop) {
      return (
        <div
          className={clsx(style.AttachmentFileRow, {
            [style.Hovered]: !showFailedStatus && attachmentHovered && nameEditableOrFileRemovable,
            [style.Disabled]: !nameEditableOrFileRemovable,
            [style.FailedAttachment]: showFailedStatus,
            FailedAsset: showFailedStatus,
            [style.HoveredFailed]: (
              showFailedStatus
              && attachmentHovered
              && nameEditableOrFileRemovable
            ),
          })}
          onMouseEnter={() => setAttachmentHovered(true)}
          onMouseLeave={() => setAttachmentHovered(false)}
          data-id={dataId}
        >
          {renderAttachmentDragHandler(isDesktop)}
          {renderAttachmentInfoContainer()}
          <AttachmentActionsContainer
            {...actionsContainerProps}
          />
        </div>
      );
    }

    return (
      <div
        role="button"
        onClick={handleClick}
        className={clsx(style.AttachmentFileRowInMobile, {
          [style.Hovered]: activeAttachmentId === dataId && !showFailedStatus,
          [style.Disabled]: !nameEditableOrFileRemovable,
          [style.FailedAttachment]: showFailedStatus,
          FailedAsset: showFailedStatus,
          [style.HoveredFailed]: activeAttachmentId === dataId && showFailedStatus,
        })}
        tabIndex={0}
        onKeyDown={handleOnKeyDown}
      >
        {renderAttachmentInfoContainer()}
        <div className={style.ActionsContainerInMobile}>
          <AttachmentActionsContainer
            {...actionsContainerProps}
            hidden={!activeAttachmentId}
          />
        </div>
      </div>
    );
  };

  const renderAttachmentRowInDesktopView = () => (
    <Media
      key="desktop-view"
      query={{
        minWidth: style.contractviewdesktopmin,
      }}
      render={renderAttachmentRow(true)}
    />
  );

  const renderAttachmentRowInMobileView = () => (
    <Media
      key="mobile-view"
      query={{
        maxWidth: style.contractviewmobilemax,
      }}
      render={renderAttachmentRow(false)}
    />
  );

  return (
    <>
      {renderAttachmentRowInDesktopView()}
      {renderAttachmentRowInMobileView()}
    </>
  );
};

export default AttachmentFile;
