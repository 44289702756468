// @flow

import React from 'react';

import { Redirect } from 'react-router';
import type { Match } from 'react-router';
import urlJoin from 'url-join';
import { filter } from 'lodash';
import adminPage from 'hocs/admin-page';
import { checkAcl } from 'components/acl';

import AccountAccess from 'routes/admin/account-access';
import Workspaces from 'routes/admin/workspaces';
import Users from 'routes/admin/users';
import Groups from 'routes/admin/groups';
import Account from 'routes/admin/account';
import Roles from 'routes/admin/roles';
import AuditLog from 'routes/admin/audit-log';
import DataManagement from 'routes/admin/data-management';
import AccountTags from 'routes/admin/tags';
import Billing from 'routes/admin/billing';

type AdminProps = AdminPageComponentProps & {
  match: Match,
};

export const Admin = (props: AdminProps) => <Redirect from={props.match.path} to={urlJoin(props.match.url, '/account')} />;

type Props = {
  [any]: any,
};

export function getAdminModules(
  {
    acl,
    message,
  }: Props,
) {
  const hasAccountAccessPermission = checkAcl(acl.account, 'account:admin:role');
  const hasGroupsPermission = checkAcl(acl.account, 'account:admin:group');
  const hasUsersPermission = checkAcl(acl.account, 'account:admin:user');
  const hasRolesPermission = checkAcl(acl.account, 'account:admin:role');
  const hasWorkspacesAccessPermission = checkAcl(acl.account, 'account:admin:workspace_access');
  const hasWorkspacesPermission = checkAcl(acl.account, 'account:admin:workspace');
  const hasBillingPermission = checkAcl(acl.account, 'account:admin:billing');
  const hasAuditLogPermission = checkAcl(acl.account, 'account:admin:audit_log');
  const hasDataManagementPermission = checkAcl(acl.account, 'account:admin:data_management');
  const hasTagsPermission = checkAcl(acl.account, 'account:admin:tag');

  const accountAccessModule = {
    component: AccountAccess,
    path: '/account-access',
    section: message({ id: 'Account access', comment: 'Used as the title for the section.' }),
    title: message({ id: 'Account access', comment: 'Tab title for account access page.' }),
    isMainPage: true,
  };

  const usersModule = {
    component: Users,
    id: 'users-tab',
    path: '/users',
    section: message({ id: 'Users', comment: 'Used as the title for the section.' }),
    title: message({ id: 'Users', comment: 'The title of the user tab' }),
    isMainPage: true,
  };

  const groupsModule = {
    component: Groups,
    id: 'group-tab',
    path: '/groups',
    section: message({ id: 'Groups', comment: 'Used as the title for the section.' }),
    title: message({ id: 'Groups', comment: 'The title of the groups tab' }),
    isMainPage: true,
  };

  const rolesModule = {
    component: Roles,
    id: 'roles-tab',
    path: '/roles',
    section: message({ id: 'Roles', comment: 'Used as the title for the section.' }),
    title: message({ id: 'Roles', comment: 'The title of the roles tab' }),
    isMainPage: true,
  };

  const workspacesModule = {
    component: Workspaces,
    path: '/workspaces',
    section: message({ id: 'Workspaces', comment: 'Used as the title for the section.' }),
    title: message({ id: 'Workspaces', comment: 'Tab title for the workspaces tab in the admin page.' }),
    isMainPage: true,
  };

  const auditLogModule = {
    component: AuditLog,
    path: '/audit-log',
    section: message({ id: 'Audit log', comment: 'Used as the title for the section.' }),
    title: message({ id: 'Audit log', comment: 'Tab title for audit log page.' }),
    isMainPage: true,
  };

  const dataManagementModule = {
    component: DataManagement,
    path: '/data-management',
    section: message({ id: 'Data management', comment: 'Used as the title for the section.' }),
    title: message({ id: 'Data management', comment: 'Tab title for the workspaces tab in the admin page.' }),
    isMainPage: true,
  };

  const tagsModule = {
    component: AccountTags,
    path: '/tags',
    section: message({ id: 'Tags', comment: 'Used as the title for the section.' }),
    title: message({ id: 'Tags', comment: 'Tab title for account tags.' }),
    isMainPage: true,
  };

  const billingModule = {
    component: Billing,
    path: '/billing',
    section: message({ id: 'Subscription and billing', comment: 'Used as the title for the section' }),
    title: message({ id: 'Billing', comment: 'Tab title for billing page.' }),
    isMainPage: true,
  };

  const accountModule = {
    acl,
    component: Account,
    path: '/account',
    section: message({ id: 'Account', comment: 'Used as the title for the section.' }),
    title: message({ id: 'Account', comment: 'The title of the account administration page' }),
    isMainPage: true,
  };

  const permissions = {
    accountModule: true,
    accountAccessModule: hasAccountAccessPermission,
    usersModule: hasUsersPermission
      || hasGroupsPermission
      || hasWorkspacesAccessPermission
      || hasRolesPermission
      || hasBillingPermission,
    groupsModule: hasGroupsPermission
      || hasWorkspacesAccessPermission
      || hasRolesPermission,
    rolesModule: hasRolesPermission,
    workspacesModule: hasWorkspacesPermission
      || hasWorkspacesAccessPermission
      || hasRolesPermission,
    dataManagementModule: hasDataManagementPermission,
    tagsModule: hasTagsPermission,
    auditLogModule: hasAuditLogPermission,
    billingModule: hasBillingPermission,
  };

  const moduleMapping = {
    accountModule,
    accountAccessModule,
    usersModule,
    groupsModule,
    rolesModule,
    workspacesModule,
    dataManagementModule,
    tagsModule,
    auditLogModule,
    billingModule,
  };

  const modules = filter(moduleMapping, (_, key) => permissions[key]);

  return ([
    modules,
  ]);
}

export const propsMapper = ({
  props: {
    acl,
    message,
  },
}: any) => ({
  modules: getAdminModules({
    acl,
    message,
  }),
  path: '/admin/users',
  title: message({ id: 'Admin', comment: 'The title of the page' }),
});

export default adminPage(propsMapper)(Admin);
