import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@oneflowab/pomes';

import TableCell from 'components/table/table-cell';
import TableRow from 'components/table/table-row';
import CircularSpinner from 'components/icons/circular-spinner';

import style from './table.module.scss';

const TableLoading = ({ children, numberOfColumns }) => (
  <TableRow>
    <TableCell colSpan={numberOfColumns} className={style.TableLoading}>
      <div className={style.LoadingContent}>
        {children}
        <CircularSpinner />
        <div className={style.LoadingText}>
          <Message id="Loading" comment="The loading message" />
        </div>
      </div>
    </TableCell>
  </TableRow>
);

TableLoading.propTypes = {
  children: PropTypes.node,
  numberOfColumns: PropTypes.number.isRequired,
};

TableLoading.defaultProps = {
  children: undefined,
};

export default TableLoading;
