import type { VideoJsPlayer } from 'video.js';

const controls = {
  play: 'playToggle',
  volume: 'volumePanel',
  seekbar: 'progressControl',
  timer: 'remainingTimeDisplay',
  playbackrates: 'playbackRateMenuButton',
  fullscreen: 'fullscreenToggle',
};

type PlayerOptions = {
  hideControls: Array<string>,
  source: string,
  autoplay: boolean,
  id: string,
  controls: boolean,
  responsive: boolean,
  fluid: boolean,
  fill: boolean,
  preload: 'auto' | 'none' | 'metadata',
  width: string | number,
  height: string | number,
  bigPlayButton: boolean,
  bigPlayButtonCentered: boolean,
  aspectRatio: string;
  playbackRates: Array<number>,
  hidePlaybackRates: boolean,
  className: string,
}

const defaultPlayerOptions: PlayerOptions = {
  source: '',
  responsive: false,
  fluid: true,
  fill: false,
  controls: true,
  autoplay: true,
  preload: 'auto',
  playbackRates: [0.5, 1, 1.5, 2],
  hidePlaybackRates: false,
  className: '',
  hideControls: [],
  bigPlayButton: false,
  bigPlayButtonCentered: true,
  height: 0,
  width: 0,
  id: 'video-player',
  aspectRatio: '16:9',
};

export const setControlVisibility = (player: VideoJsPlayer, hiddenControls: Array<string>) => {
  Object.values(controls).map((controlSelector) => player.controlBar?.[controlSelector].show());

  hiddenControls.map((controlKey) => player.controlBar?.[controls[controlKey]].hide());
};

type Props = {
  hidePlaybackRates?: boolean,
  hideControls: Array<string>,
  playbackRates?: boolean,
  source: string,
  autoplay: boolean,
  fluid: boolean,
};

export const generatePlayerOptions = (incomingPlayerOptions: Props): PlayerOptions => {
  const hidePlaybackRates = incomingPlayerOptions.hidePlaybackRates || incomingPlayerOptions.hideControls?.includes('playbackrates');
  const playerOptions = {
    ...defaultPlayerOptions,
    ...incomingPlayerOptions,
    bigPlayButton: !incomingPlayerOptions.autoplay,
    playbackRates: !hidePlaybackRates ? incomingPlayerOptions.playbackRates : null,
  };

  return playerOptions;
};
