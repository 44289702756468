import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import { checkAcl } from 'components/acl';

import { isFeatureEnabledSelector } from 'reducers/session';
import { isTemplate, isConcluded, isPending } from 'agreement/states';
import agreementsReducer from 'reducers/entities/agreements';

import useAgreement from 'hooks/use-agreement';
import {
  isImport,
  isSignLaterEnabled as isSignLaterFeatureEnabled,
  isSignOrderEnabled as isSignOrderFeatureEnabled,
} from 'agreement';
import {
  getAgreementMyParticipant,
  getEnabledParticipants,
  usesSameDeviceAll,
} from 'agreement/selectors';
import { hasPendingStateApprovers } from 'agreement/pending-state-flow';
import isInternalApprover from 'agreement/participant/is-internal-approver';

import { ExpandableOptions } from 'components/expandable-options';
import SignLater from 'components/modals/sign-later';
import ReadyToSign from 'components/modals/ready-to-sign';
import SingleSign from 'components/modals/single-sign';

type Props = {
  agreementId: number,
};

export const AdditionalOptions = ({
  agreementId,
}: Props) => {
  const agreement = useAgreement(agreementId);

  const [isSignLaterModalOpen, setIsSignLaterModalOpen] = useState(false);
  const [isReadyToSignModalOpen, setIsReadyToSignModalOpen] = useState(false);
  const [isSingleSignModalOpen, setIsSingleSignModalOpen] = useState(false);
  const [isSignLaterEnabled, setIsSignLaterEnabled] = useState(
    () => isSignLaterFeatureEnabled(agreement),
  );

  const [isSignOrderEnabled, setIsSignOrderEnabled] = useState(
    () => isSignOrderFeatureEnabled(agreement),
  );

  const [isSingleSignEnabled, setIsSingleSignEnabled] = useState(
    () => Boolean(agreement.config?.singleSign),
  );

  const singleSignFeatureEnabled = useSelector((state) => isFeatureEnabledSelector(state, {
    feature: 'temporarySingleSign',
  }));
  const dispatch = useDispatch();

  const hasPendingStateApproversInDocument = hasPendingStateApprovers(agreement);
  const hasSignOrderFeature = checkAcl(agreement.acl, 'agreement:signing_order');
  const hasSignOrderUpdatePermission = checkAcl(agreement.acl, 'agreement:update:sign_order');
  const hasSingleSignUpdatePermission = checkAcl(agreement.acl, 'agreement:update:single_sign');

  const signOrderAvailable = !isConcluded(agreement) && !isImport(agreement);
  const signLaterAvailable = !isConcluded(agreement) && !isImport(agreement);
  const isSingleSignAvailable = !isConcluded(agreement);

  const isSingleSignLocked = !hasSingleSignUpdatePermission || isSignOrderEnabled;

  const enableSignOrder = () => {
    const enabledParticipants = getEnabledParticipants(agreement);
    const nonApproverParticipants = enabledParticipants.filter(
      (participant) => !isInternalApprover(participant),
    );

    setIsSignOrderEnabled(true);

    const blocks = nonApproverParticipants.map((participant, index) => (
      {
        participant_id: participant?.id,
        block: index + 1,
      }
    ));

    dispatch(agreementsReducer.updateSignOrder({
      id: agreementId,
      data: {
        signOrder: blocks,
        config: {
          signOrder: true,
        },
      },
    }));
  };

  const disableSignOrder = () => {
    setIsSignOrderEnabled(false);

    dispatch(agreementsReducer.updateSignOrder({
      id: agreementId,
      data: {
        signOrder: [],
        config: {
          signOrder: false,
        },
      },
    }));
  };

  const isSignOrderLocked = () => {
    const usesSameDevice = usesSameDeviceAll(agreement);
    return !hasSignOrderUpdatePermission
      || usesSameDevice
      || isSingleSignEnabled
      || isSignLaterEnabled
      || hasPendingStateApproversInDocument;
  };

  const showDisabledSignOrderWarningMessage = () => {
    if (!isSignOrderLocked() || !hasPendingStateApproversInDocument || isPending(agreement)) {
      return false;
    }

    return true;
  };

  const isSignLaterLocked = () => {
    const myParticipant = getAgreementMyParticipant(agreement);
    const canUpdateSignLater = myParticipant && checkAcl(agreement.acl, 'agreement:update:sign_later');
    return (!canUpdateSignLater && !isTemplate(agreement)) || isSignOrderEnabled;
  };

  const syncState = () => {
    setIsSignOrderEnabled(isSignOrderFeatureEnabled(agreement));
    setIsSignLaterEnabled(isSignLaterFeatureEnabled(agreement));
    setIsSingleSignEnabled(Boolean(agreement.config?.singleSign));
  };

  const toggleSignOrder = (enabled) => {
    if (enabled) {
      enableSignOrder();
    } else {
      disableSignOrder();
    }
  };

  const toggleSignLater = (value) => {
    setIsSignLaterEnabled((isEnabled) => !isEnabled);

    if (isPending(agreement)) {
      if (value) {
        setIsSignLaterModalOpen(true);
      } else {
        setIsReadyToSignModalOpen(true);
      }
      return;
    }
    const { signLater } = agreement.config;

    dispatch(agreementsReducer.updateAgreementPreferences({
      id: agreementId,
      data: {
        id: agreementId,
        config: {
          signLater: !signLater,
        },
      },
    }));
  };

  const toggleSingleSign = () => {
    setIsSingleSignEnabled((isEnabled) => !isEnabled);

    setIsSingleSignModalOpen(true);
  };

  const renderSingleSignTooltip = () => {
    if (!isSignOrderEnabled && !hasSingleSignUpdatePermission) {
      return null;
    }

    return (
      <Message
        id="Turn off signing order to allow single signature"
        comment="label explaining why single sign option is disabled"
      />
    );
  };

  const renderSignLaterTooltip = () => (
    <Message
      id="Turn off signing order to allow sign later"
      comment="label explaining why sign later option is disabled"
    />
  );

  const signOrder = {
    title: <Message id="Signing order" comment="Label for toggling 'signing order'" />,
    value: 'signOrder',
    description: <Message id="Decide in which order participants will receive the invitation." comment="Description to explain what toggling 'signing order' does" />,
    isOn: isSignOrderEnabled,
    onToggle: toggleSignOrder,
    disabled: isSignOrderLocked(),
    hideMenuItem: !hasSignOrderFeature,
    additionalInformation: showDisabledSignOrderWarningMessage(),
  };

  const signLater = {
    title: <Message id="Sign later" comment="Label for toggling 'sign later'" />,
    description: <Message id="Postpone signing until the document is ready to be signed." comment="Description to explain what toggling 'sign later' does" />,
    isOn: isSignLaterEnabled,
    onToggle: toggleSignLater,
    disabled: isSignLaterLocked(),
    disabledTooltipMessage: renderSignLaterTooltip(),
    hideMenuItem: false,
  };

  const singleSign = {
    title: <Message id="Single signature" comment="Label for toggling 'single signature" />,
    description: <Message id="Require only one signature to sign the document." comment="Description to explain what toggling 'single signature' does" />,
    isOn: isSingleSignEnabled,
    onToggle: toggleSingleSign,
    disabled: isSingleSignLocked,
    disabledTooltipMessage: renderSingleSignTooltip(),
    hideMenuItem: false,
  };

  const options = [];
  if (signOrderAvailable) {
    options.push(signOrder);
  }
  if (signLaterAvailable) {
    options.push(signLater);
  }
  if (singleSignFeatureEnabled && isSingleSignAvailable) {
    options.push(singleSign);
  }

  return (
    <>
      <SignLater
        agreementId={agreementId}
        isModalOpen={isSignLaterModalOpen}
        setIsModalOpen={setIsSignLaterModalOpen}
        isSignLaterEnabled={isSignLaterEnabled}
        syncState={syncState}
      />
      <ReadyToSign
        agreementId={agreementId}
        isModalOpen={isReadyToSignModalOpen}
        setIsModalOpen={setIsReadyToSignModalOpen}
        syncState={syncState}
        isSingLaterEnabled={isSignLaterEnabled}
      />
      <SingleSign
        agreementId={agreementId}
        isModalOpen={isSingleSignModalOpen}
        setIsModalOpen={setIsSingleSignModalOpen}
        singleSignToggleStatus={isSingleSignEnabled}
        syncState={syncState}
      />
      <ExpandableOptions
        title={<Message id="Additional options" comment="Label for additional options menu" />}
        options={options}
        isExpandable
        showDivider
        isAdditionalOptions
      />
    </>
  );
};
