import {
  forwardRef,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import type { ReactNode } from 'react';

import { composeRefs } from 'hooks/compose-refs';

import { GroupContext } from './contexts/group';
import { useFilterableContext } from './contexts/filterable';
import useFilterableState from './hooks/use-filterable-state';
import useValue from './hooks/use-value';
import { SlottableWithNestedChildren } from './helpers';
import type { Children, DivProps } from './types';

type Props = Children &
  Omit<DivProps, 'heading' | 'value'> & {
    id: string;
    headingId: string;
    /** Optional heading to render for this group. */
    heading?: ReactNode
    /** If no heading is provided, you must provide a value that is unique for this group. */
    value?: string
    /** Whether this group is forcibly rendered regardless of filtering. */
    forceMount?: boolean
  }

const Group = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const {
    heading,
    children,
    forceMount,
    id,
    headingId,
    ...etc
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLDivElement>(null);
  const context = useFilterableContext();
  const render = useFilterableState((state) => {
    if (forceMount || context.filter() === false || !state.search) {
      return true;
    }

    return state.filtered.groups.has(id);
  });

  useLayoutEffect(() => context.group(id), []);

  useValue(id, ref, [props.value, props.heading, headingRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({ id, forceMount }), [forceMount]);

  return (
    <div
      ref={composeRefs(ref, forwardedRef)}
      {...etc}
      // eslint-disable-next-line react/no-unknown-property
      cmdk-group=""
      role="presentation"
      hidden={render ? undefined : true}
    >
      {heading && (
        // eslint-disable-next-line react/no-unknown-property
        <div ref={headingRef} cmdk-group-heading="" aria-hidden id={headingId}>
          {heading}
        </div>
      )}
      {SlottableWithNestedChildren(props, (child) => (
        // eslint-disable-next-line react/no-unknown-property
        <div cmdk-group-items="" role="group" aria-labelledby={heading ? headingId : undefined}>
          <GroupContext.Provider value={contextValue}>{children}</GroupContext.Provider>
        </div>
      ))}
    </div>
  );
});

Group.displayName = 'Filterable.Group';

export default Group;
