import { Message } from '@oneflowab/pomes';

import { getAgreementDateFormat, formatDateString } from 'date';

import CalendarIcon from 'components/icons/calendar';
import style from './style.module.scss';

type Props = {
  agreement: Oneflow.Agreement;
};

const ActiveUntil = ({ agreement }: Props) => {
  const dateFormat = getAgreementDateFormat(agreement?.config?.dateFormat);
  const dateString = formatDateString(agreement?.periodEndTime?.toString(), dateFormat);

  return (
    <div className={style.Insight} data-testid="active-until">
      <CalendarIcon width="10px" />
      <span className={style.SemiBold}>
        <Message
          id="Valid until:"
          comment="Agreement is valid until."
        />
      </span>
      <span data-testid="active-until-date">
        {' '}
        {dateString}
      </span>
    </div>
  );
};

export default ActiveUntil;
