import { useDispatch, useSelector } from 'react-redux';

import billingsReducer from 'reducers/entities/billings';

type CountryOption = {
  label: string;
  value: string;
};

function useEditBillingDetails(
  accountId: number,
) {
  const dispatch = useDispatch();
  const formState = useSelector((state) => billingsReducer.getUpdateSelector(state, {
    id: accountId,
  }));

  const resetFormState = () => {
    dispatch(billingsReducer.updateBillingReset({ id: accountId }));
  };

  const onSubmit = ({
    name,
    vatNumber,
    address,
    zipCode,
    city,
    country,
  }: {
    name: string,
    vatNumber:string,
    address: string,
    zipCode: string,
    city: string,
    country: CountryOption
    }) => {
    dispatch(billingsReducer.updateBilling({
      id: accountId,
      data: {
        name,
        vatNumber,
        address,
        zipCode,
        city,
        country: country.value,
      },
      pipe: {
        action: () => billingsReducer.fetchBilling({ id: accountId }),
        wait: billingsReducer.FETCH_BILLING_SUCCESS,
      },
    }));
  };
  return {
    formState,
    resetFormState,
    onSubmit,
  };
}

export default useEditBillingDetails;
