/* eslint-disable jsx-a11y/label-has-associated-control */
import * as Select from '@radix-ui/react-select';
import { Message } from '@oneflowab/pomes';

import {
  PRODUCT_COUNT_TYPE_CHECKBOX,
  PRODUCT_COUNT_TYPE_NUMBER,
  PRODUCT_COUNT_TYPE_SINGLE_CHOICE,
} from 'components/contract-boxes/product-table-box/constants';

import {
  CheckBoxIcon,
  NumberIcon,
  RadioButtonIcon,
  DownChevronIcon,
  SelectedIcon,
} from './icons';

import style from './quantity-type-select.module.scss';

type Props = {
  name: string;
  defaultValue: number;
  triggerRef: React.Ref<HTMLButtonElement>
};

const options = [
  {
    value: PRODUCT_COUNT_TYPE_NUMBER,
    label: <Message id="Number" comment="Product quantity type option" />,
    description: (
      <Message
        id="Only numbers allowed. Used for adding quantity"
        comment="Product quantity type option description"
      />
    ),
    Icon: NumberIcon,
    id: 'abcde',
  },
  {
    value: PRODUCT_COUNT_TYPE_CHECKBOX,
    label: <Message id="Multiple selection" comment="Product quantity type option" />,
    description: (
      <Message
        id="Used to select one or more options from the list"
        comment="Product quantity type option description"
      />
    ),
    Icon: CheckBoxIcon,
    id: 'fghij',
  },
  {
    value: PRODUCT_COUNT_TYPE_SINGLE_CHOICE,
    label: <Message id="Single selection" comment="Product quantity type option" />,
    description: (
      <Message
        id="Used to select one option from the list"
        comment="Product quantity type option description"
      />
    ),
    Icon: RadioButtonIcon,
    id: 'klmno',
  },
];

function QuantityTypeSelect(props: Props) {
  const { name, defaultValue, triggerRef } = props;
  return (
    <Select.Root name={name} defaultValue={String(defaultValue)}>
      <label htmlFor="quantityTypeSelect" className={style.SelectFieldLabel}>
        <Message id="Quantity input type" comment="Quantity type selector label" />
      </label>
      <Select.Trigger
        id="quantityTypeSelect"
        className={style.SelectFieldTrigger}
        ref={triggerRef}
      >
        <Select.Value />
        <Select.Icon asChild>
          <DownChevronIcon className={style.DownChevronIcon} width="16px" height="16px" />
        </Select.Icon>
      </Select.Trigger>
      <Select.Content
        className={style.SelectContent}
        position="popper"
        // This ties with the useEffect in update-product-item
        data-scrollable
      >
        <Select.Viewport className={style.SelectViewPort}>
          {options.map((option) => (
            <Select.Item
              key={option.id}
              value={String(option.value)}
              className={style.SelectItem}
            >
              <Select.ItemText>
                {/*
                  Same selected item will be rendered in <Select.Value />,
                  css will hide unnecessary part
                */}
                <div className={style.SelectOption}>
                  <option.Icon className={style.SelectOptionIcon} width="16px" height="16px" />
                  <div className={style.SelectOptionText}>
                    <div className={style.SelectOptionLabel}>{option.label}</div>
                    <div className={style.SelectOptionDescription}>{option.description}</div>
                  </div>
                  <SelectedIcon className={style.SelectedIcon} width="12px" height="12px" />
                </div>
              </Select.ItemText>
              <Select.ItemIndicator />
            </Select.Item>
          ))}
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  );
}

export default QuantityTypeSelect;
