import {
  useCallback,
  useRef,
  useState,
} from 'react';

import type { Ref, MutableRefObject } from 'react';

import { A4_HEIGHT } from 'components/contract-boxes/constants';

type PdfMeasurement = {
  height: number;
  onRenderSuccess: () => void;
  inputRef: Ref<HTMLDivElement>;
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
};

const usePdfMeasurement = ({
  page,
  onSuccess,
}: {
  page: number;
  onSuccess: (values: { page: number, height: number }) => void;
}): PdfMeasurement => {
  const inputRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [height, setHeight] = useState(A4_HEIGHT);

  const onRenderSuccess = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    if (!canvasRef.current) {
      canvasRef.current = inputRef.current.querySelector('canvas');
    }

    const { clientHeight } = inputRef.current;

    setHeight(clientHeight);
    onSuccess({ page, height: clientHeight });
  }, [page, onSuccess]);

  return {
    height,
    inputRef,
    canvasRef,
    onRenderSuccess,
  };
};

export default usePdfMeasurement;
