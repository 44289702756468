// @flow

import React from 'react';
import type { Location } from 'react-router';
import queryString from 'query-string';
import Message from 'components/message';

import extensionSettings from 'hocs/extension-settings';

import Button from 'components/button';
import CheckCircleIcon from 'components/icons/check-circle';

import style from './pipedrive.module.scss';

type Props = {
  location: Location,
};

const PIPEDRIVE_OAUTH_LINK = '/api/ext/pipedrive/oauth_proxy';

export class PipedrivePage extends React.Component<Props> {
  isConnected() {
    const { location } = this.props;
    const { status } = queryString.parse(location.search);

    return status === 'ok';
  }

  renderConnectionIcon() {
    if (!this.isConnected()) {
      return null;
    }

    return <CheckCircleIcon height="20px" className={style.ConnectionIcon} />;
  }

  renderAuthButtonText() {
    if (!this.isConnected()) {
      return (
        <Message
          id="Authenticate to Pipedrive"
          comment="Button text in Pipedrive settings page"
        />
      );
    }

    return (
      <Message
        id="Re-authenticate to Pipedrive"
        comment="Button text in Pipedrive settings page"
      />
    );
  }

  render() {
    return (
      <>
        <h3 className={style.ExtensionConfigurationHeader}>
          <Message
            id="Authentication"
            comment="Section header for the authentication section in an extension."
          />
          {this.renderConnectionIcon()}
        </h3>
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="Please authenticate with your Pipedrive login credentials to activate the extension. You will only need to enter your credentials once, unless the credentials are revoked from Pipedrive."
            comment="Help text for the authentication section in an extension"
          />
        </div>

        <Button
          external
          href={PIPEDRIVE_OAUTH_LINK}
          customClass={style.AuthButton}
          kind="primary"
        >
          {this.renderAuthButtonText()}
        </Button>
      </>
    );
  }
}

export default extensionSettings(PipedrivePage);
