// @flow

/**
 * const reducer = (state, action) => {
 *   switch(action.type) {
 *     case 'type_x': retun the changed state for the type
 *     case 'type_y;: retun the changed state for the type
 *     default:
 *       return initialState;
 *   }
 * }
 *
 * =>
 *
 * Here you have the `inversion of control`, the consumer can decide how
 * The state should be transformed/updated
 * createReducer({
 *   'type_x': (state, action) => retun the changed state for the type,
 *   'type_y': (state, action) => retun the changed state for the type,
 * })
 *
 */

// Here we don't assign initialState to the state in the reducer argument
// That's one of the difference between redux reducer and react reducer
// It will be hanlded by react since it takes initialState as second argument
const createReducer = (caseHandlers) => function reducer(state, action) {
  // case ${action.type}: caseHandlers[action.type](state, action)
  // $FlowFixMe: Now all the browser has `hasOwn` support
  if (Object.hasOwn(caseHandlers, action.type)) {
    return caseHandlers[action.type](state, action);
  }

  return state;
};

export default createReducer;
