import { useState } from 'react';
import type { ReactNode } from 'react';

import { MultistepModal, ModalStep } from 'components/modal';

import { MoveContractStep } from './steps/move-contract-step/move-contract-step';
import { MoveContractWarningsStep } from './steps/move-contract-warnings-step/move-contract-warnings-step';
import type { SkippedDocumentData } from './steps/move-contract-step/types';

export type Props = {
  children: (openModal: () => void) => ReactNode,
  queryName: string,
};

export const MoveContractBulk = ({
  children,
  queryName,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [skippedDocuments, setSkippedDocuments] = useState<SkippedDocumentData[]>([]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <MultistepModal isOpen={isOpen} onCancel={handleClose}>
        <ModalStep>
          {({ onStepComplete }) => (
            <MoveContractStep
              onClose={handleClose}
              queryName={queryName}
              setSkippedDocuments={setSkippedDocuments}
              onStepComplete={onStepComplete}
            />
          )}
        </ModalStep>
        <ModalStep>
          {({ onPreviousStep }) => (
            <MoveContractWarningsStep
              skippedDocuments={skippedDocuments}
              onClose={() => {
                handleClose();
                onPreviousStep();
              }}
            />
          )}
        </ModalStep>
      </MultistepModal>
      {children(handleOpen)}
    </>
  );
};
