// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getBilling,
  updateBilling,
  upsertBilling,
  type NormalizedBillings,
} from 'oneflow-client/billings';

import apiWrapper from 'sagas/api-wrapper';

import accountSeatsReducer from 'reducers/entities/account-seats';
import billings from 'reducers/entities/billings';

type MapperArgs = {
  data: NormalizedBillings,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ action, data }: MapperArgs): Mapper {
  yield put(billings.setBillings({
    [action.id]: data.entities.billings[data.result],
  }));
  yield put(accountSeatsReducer.setAccountSeats({
    [action.id]: data.entities.accountSeats[data.result],
  }));
}

export function* updateMapper({ action, data }: MapperArgs): Mapper {
  yield put(billings.setBillings({
    [action.id]: data.entities.billings[data.result],
  }));
}

const mappers = {
  fetch: {
    mapper,
    request: getBilling,
  },
  update: {
    mapper: updateMapper,
    request: updateBilling,
  },
  rpcs: {
    upsertBilling: {
      name: 'upsertBilling',
      mapper: updateMapper,
      request: upsertBilling,
    },
  },
};

const billingsSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: billings,
  mappers,
});

export default billingsSaga;
