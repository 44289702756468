/* eslint-disable camelcase */
import {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { keyBy } from 'lodash';
import clsx from 'clsx';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import { useProductTableBoxContext } from 'contexts/product-table-box-context';

import {
  adjustNumberOnArrowKeyDown,
  canCastToNumber,
  countDecimalPlaces,
  getStep,
} from 'components/contract-boxes/product-table-box/product-table/popup-forms/update-product-helpers';
import {
  BAD_INPUT,
  INVALID_PATTERN,
  INVALID_RANGE,
  INVALID_VALUE,
  MAX_PRODUCT_COUNT,
  PRISTINE,
  PRODUCT_COUNT_TYPE_CHECKBOX,
  PRODUCT_COUNT_TYPE_NUMBER,
  PRODUCT_COUNT_TYPE_SINGLE_CHOICE,
  PRODUCT_NOT_SELECTED,
  PRODUCT_SELECTED,
  VALID,
} from 'components/contract-boxes/product-table-box/constants';
import { RadioGroupItemInternal as SingleRadioItem } from 'components/radio-group-item/radio-group-item';
import Checkbox from 'components/checkbox';
import LockIcon from 'components/icons/lock';
import Message from 'components/message';
import Tooltip from 'components/tooltip';

import style from './quantity-input.module.scss';

type UpdateProductDataCountType = typeof PRODUCT_NOT_SELECTED | typeof PRODUCT_SELECTED;

type CountType = typeof PRODUCT_COUNT_TYPE_NUMBER |
  typeof PRODUCT_COUNT_TYPE_CHECKBOX | typeof PRODUCT_COUNT_TYPE_SINGLE_CHOICE

type Props = {
  productId: number,
  productName: string,
  count: number,
  count_type: CountType
  updateProductData: (productId: number, updatedProduct: {
    count: UpdateProductDataCountType | number
    count_type?: CountType
  }) => void,
  chosenProduct: number,
  updateChosenProduct: (productId: number) => void,
  isGuestView: boolean,
  isResponsiveView: boolean,
};

const QuantityInput = ({
  chosenProduct,
  count_type,
  count,
  isGuestView,
  isResponsiveView,
  productId,
  productName,
  updateChosenProduct,
  updateProductData,
}: Props) => {
  const [quantityDecimalNewValue, setQuantityDecimalNewValue] = useState(0);
  const [validityState, setValidityState] = useState(PRISTINE);
  const {
    config,
    data,
    getIsAllowedToUpdateSharedDataValue,
  } = useProductTableBoxContext();
  const { quantityPrecision } = config;
  const products = keyBy(data, getId);
  const product = products[productId];
  const isAllowedToUpdateSharedDataValue = getIsAllowedToUpdateSharedDataValue(product);
  const invalid = [
    BAD_INPUT,
    INVALID_PATTERN,
    INVALID_RANGE,
    INVALID_VALUE,
  ].includes(validityState);
  const lockedForCounterPartiesHintIconVisible = !isAllowedToUpdateSharedDataValue && !isGuestView;
  const numberCountInputRef = useRef(null);

  const getInvalidTooltipMessage = useCallback(() => {
    if (validityState === BAD_INPUT) {
      return (
        <Message
          id="Enter a numeric value."
          comment="Invalid input message for product quantity column"
        />
      );
    }
    if (validityState === INVALID_RANGE) {
      return (
        <Message
          id="Value must be between 0 and {maxProductCount}"
          comment="Out of range invalid message for product quantity column"
          values={{ maxProductCount: MAX_PRODUCT_COUNT }}
        />
      );
    }
    if (validityState === INVALID_VALUE) {
      return (
        <Message
          id="The amount of decimals is set to {quantityPrecision}"
          comment="Invalid input message for product quantity column"
          values={{ quantityPrecision }}
        />
      );
    }
    if (validityState === INVALID_PATTERN) {
      return (
        <Message
          id="Use a dot for decimals, e.g. 1.25."
          comment="Invalid product quantity input message."
        />
      );
    }
    return null;
  }, [quantityPrecision, validityState]);

  const isChosen = useCallback(() => (
    [PRODUCT_COUNT_TYPE_CHECKBOX, PRODUCT_COUNT_TYPE_SINGLE_CHOICE].includes(count_type)
    && count === PRODUCT_SELECTED
  ), [count, count_type]);

  const handleCheckBoxChange = useCallback(() => {
    const newValue = isChosen() ? PRODUCT_NOT_SELECTED : PRODUCT_SELECTED;
    updateProductData(productId, { count: newValue });
  }, [isChosen, productId, updateProductData]);

  const handleNumberChange = useCallback((event) => {
    const quantityDecimal = countDecimalPlaces(event.target.value);
    const stepDecimal = countDecimalPlaces(getStep(quantityPrecision).toFixed(quantityPrecision));
    const newValue = Number(event.target.value);
    setQuantityDecimalNewValue(quantityDecimal);

    if (event.target.value.includes(',')) {
      setValidityState(INVALID_PATTERN);
      return;
    }
    if (!canCastToNumber(event.target.value)) {
      setValidityState(BAD_INPUT);
      return;
    }
    if (newValue > MAX_PRODUCT_COUNT) {
      setValidityState(INVALID_RANGE);
      return;
    }
    if (newValue < 0) {
      setValidityState(INVALID_RANGE);
      return;
    }
    if (quantityDecimal > stepDecimal) {
      setValidityState(INVALID_VALUE);
      return;
    }
    setValidityState(VALID);
    updateProductData(
      productId,
      { count_type: PRODUCT_COUNT_TYPE_NUMBER, count: newValue },
    );
  }, [productId, quantityPrecision, updateProductData]);

  const numberQuantityInputClassNames = clsx({
    [style.NumberQuantityInputExpanded]: !isResponsiveView,
    [style.NumberQuantityInputResponsive]: isResponsiveView,
    [style.Error]: invalid,
  });

  const numberQuantityInputWrapperClassNames = clsx(
    style.QuantityInput,
    style.NumberQuantityInputWrapper,
    {
      [style.NumberQuantityInputWrapperResponsive]: isResponsiveView,
    },
  );

  const numberQuantityDisabledClassNames = clsx({
    [style.NumberQuantityDisabledExpanded]: !isResponsiveView,
    [style.NumberQuantityDisabledResponsive]: isResponsiveView,
  });

  const productTableCheckboxWrapperClassNames = clsx(
    style.QuantityInput,
    style.ProductTableCheckBoxWrapper,
    {
      [style.ProductTableCheckBoxWrapperResponsive]: isResponsiveView,
      [style.UnCheckedProductCheckbox]: !isChosen(),
    },
  );

  const productTableRadioItemClassNames = clsx(style.QuantityInput, style.ProductTableRadioItem, {
    [style.ProductTableRadioItemResponsive]: isResponsiveView,
    [style.ReadOnly]: !isAllowedToUpdateSharedDataValue,
  });

  const hintIcon = (
    <div
      className={clsx(style.ReadOnlyLockIcon, {
        [style.HintVisible]: lockedForCounterPartiesHintIconVisible,
      })}
    >
      <LockIcon width="14px" height="14px" />
    </div>
  );
  let quantityInputValidationTooltip: null | ReactNode = null;

  if (invalid) {
    quantityInputValidationTooltip = (
      <Tooltip
        messageClassName={style.InvalidTooltipMessage}
        zIndex={30}
        side="bottom"
        open={invalid}
        message={getInvalidTooltipMessage()}
      >
        <div />
      </Tooltip>
    );
  }

  useEffect(() => {
    if (quantityDecimalNewValue > quantityPrecision) {
      setValidityState(INVALID_VALUE);
    } else {
      setValidityState(VALID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityPrecision]);

  useEffect(() => {
    const handleWheel = (e) => {
      if ((e.target).closest('[data-scrollable]')) return;
      e.stopPropagation();
    };

    const handleTouchMove = (e) => {
      if ((e.target).closest('[data-scrollable]')) return;
      e.stopPropagation();
    };

    const handleKeyDown = (e) => {
      if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') {
        return;
      }
      const inputElement = e.target;
      if (Number(e.target.id) !== productId) {
        return;
      }
      e.preventDefault();

      const newInputValue = `${adjustNumberOnArrowKeyDown(e.key, inputElement.value, quantityPrecision)}`;
      inputElement.value = newInputValue;
      const newCount = Number(newInputValue);
      if (newCount > MAX_PRODUCT_COUNT) {
        setValidityState(INVALID_RANGE);
        return;
      }
      if (newCount < 0) {
        setValidityState(INVALID_RANGE);
        return;
      }
      setValidityState(VALID);
      updateProductData(
        productId,
        {
          count_type: PRODUCT_COUNT_TYPE_NUMBER,
          count: newCount as UpdateProductDataCountType,
        },
      );
    };

    document.addEventListener('wheel', handleWheel, true);
    document.addEventListener('touchmove', handleTouchMove, true);
    document.addEventListener('keydown', handleKeyDown, true);

    return () => {
      document.removeEventListener('wheel', handleWheel, true);
      document.removeEventListener('touchmove', handleTouchMove, true);
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [quantityPrecision, productId, updateProductData]);

  const renderNumberInput = () => {
    if (!isAllowedToUpdateSharedDataValue) {
      return (
        <div
          data-testid="disabled-number-input"
          className={numberQuantityDisabledClassNames}
        >
          {Number(count)}
        </div>
      );
    }

    return (
      <div className={numberQuantityInputWrapperClassNames}>
        <input
          data-testid="number-quantity-input"
          className={numberQuantityInputClassNames}
          ref={numberCountInputRef}
          name="quantityInput"
          type="text"
          id={`${productId}`}
          defaultValue={count}
          onInput={handleNumberChange}
          autoComplete="off"
        />
        {quantityInputValidationTooltip}
        {hintIcon}
      </div>
    );
  };

  if (count_type === PRODUCT_COUNT_TYPE_CHECKBOX) {
    return (
      <div className={productTableCheckboxWrapperClassNames}>
        <Checkbox
          disabled={!isAllowedToUpdateSharedDataValue}
          aria-label={`${productName} Multiple Selection`} // TODO: Translate?
          label={null}
          input={{
            value: 1,
            checked: isChosen(),
            onChange: handleCheckBoxChange,
          }}
        />
        {hintIcon}
      </div>
    );
  }

  if (count_type === PRODUCT_COUNT_TYPE_SINGLE_CHOICE) {
    return (
      <div className={style.QuantityInput}>
        <SingleRadioItem
          className={productTableRadioItemClassNames}
          disabled={!isAllowedToUpdateSharedDataValue}
          aria-label={`${productName} Single Choice`} // TODO: Translate?
          groupName={productId}
          value={productId}
          checked={productId === chosenProduct}
          onChange={updateChosenProduct}
        />
        {hintIcon}
      </div>
    );
  }

  return renderNumberInput();
};

export default QuantityInput;
