import { useState } from 'react';
import {
  trim,
  truncate,
} from 'lodash';
import { isSmallScreenWidth } from 'ui/config';

import {
  isContentTooLong,
  CONTENT_TRUNCATE_LENGTH,
  CONTENT_TRUNCATE_NEWLINE_COUNT,
  CONTENT_TRUNCATE_NEWLINE_COUNT_MOBILE,
  replaceNewLinesWithBreaks,
  showLessLabel,
  showMoreLabel,
} from './utils';

import style from './single-message-content.module.scss';

const CommentOrChatContent = ({ content }: { content: string }) => {
  const isSmallScreen = isSmallScreenWidth();
  const newLineCount = isSmallScreen
    ? CONTENT_TRUNCATE_NEWLINE_COUNT_MOBILE
    : CONTENT_TRUNCATE_NEWLINE_COUNT;

  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const isCommentContentTooLong = isContentTooLong(content);

  const onShowMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsTextExpanded(!isTextExpanded);
  };

  const renderShowMoreButton = () => (
    <button className={style.ExpandButton} onClick={onShowMore}>
      { isTextExpanded ? showLessLabel : showMoreLabel }
    </button>
  );

  let truncatedContent = truncate(content, {
    length: CONTENT_TRUNCATE_LENGTH,
  });

  truncatedContent = trim(truncatedContent.split('\n', newLineCount).join('\n'));

  if (isCommentContentTooLong) {
    return (
      <>
        <div>
          {replaceNewLinesWithBreaks(isTextExpanded ? content : truncatedContent)}
          {' '}
        </div>
        <div className={style.Button}>
          {renderShowMoreButton()}
        </div>
      </>
    );
  }

  return <span>{replaceNewLinesWithBreaks(content)}</span>;
};

export default CommentOrChatContent;
