// @flow

import React from 'react';

import style from './contract-id.module.scss';

type Props = {
  id: number,
  clickableContractId?: boolean,
};

const ContractId = ({ id, clickableContractId = false }: Props) => (
  <div className={style.Id}>
    {clickableContractId ? (
      <a
        href={`/search/all?q=${id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {`ID ${id}`}
      </a>
    ) : `ID ${id}`}
  </div>
);

export default ContractId;
