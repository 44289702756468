// @flow

import { connect } from 'react-redux';

import {
  getPositionFromSessionSelector,
  getGhostFromSessionSelector,
  ping,
} from 'reducers/session';

import SessionPing from './session-ping';

export const mapStateToProps = (state: State) => ({
  positionId: getPositionFromSessionSelector(state).id,
  isGhost: getGhostFromSessionSelector(state),
});

export const mapDispatchToProps = (dispatch: any) => ({
  ping: (positionId: number) => dispatch(ping({ positionId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionPing);
