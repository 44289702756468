// eslint-disable-next-line import/named
import { FileUploadBoxPropsProvider } from 'contexts/file-upload-box-props';
import useCurrentBoxId from 'hooks/use-current-box-id';
import AttachmentBoxContainer from 'components/contract-boxes/attachment-box/attachment-box-container';
import AttachmentBoxValidationProvider from 'components/contract-boxes/attachment-box/attachment-box-validation-provider';

type Props = {
  agreementId: number,
  boxId: number,
  isEditable: boolean,
  onAddSectionRules: () => void,
  onRemoveBox: () => void,
  permissions: {
    update: boolean,
    updateConfig: boolean,
  },
}

const AttachmentBox = ({
  agreementId,
  boxId,
  isEditable,
  onAddSectionRules,
  onRemoveBox,
  permissions,
}: Props) => {
  const currentBoxId = useCurrentBoxId(boxId);

  return (
    <FileUploadBoxPropsProvider
      agreementId={agreementId}
      boxId={currentBoxId}
      editable={isEditable}
    >
      <AttachmentBoxValidationProvider>
        <AttachmentBoxContainer
          boxId={currentBoxId}
          editable={isEditable}
          onAddSectionRules={onAddSectionRules}
          onRemoveBox={onRemoveBox}
          permissions={permissions}
        />
      </AttachmentBoxValidationProvider>
    </FileUploadBoxPropsProvider>
  );
};

export default AttachmentBox;
