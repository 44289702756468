import React from 'react';
import { Message } from '@oneflowab/pomes';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import flatten from 'lodash/flatten';
import mapValues from 'lodash/mapValues';

import adminPage from 'hocs/admin-page';
import Field from 'components/field';
import Toggle from 'components/toggle';
import CircularSpinner from 'components/icons/circular-spinner';
import TooltipInfo from 'components/tooltip-info';

import NotificationsActionBar from './notifications-action-bar';
import getEventGroups from './events';
import style from './notifications.module.scss';

export class NotificationsPage extends React.Component {
  static propTypes = {
    settings: PropTypes.shape().isRequired,
    updateSettingsState: PropTypes.shape().isRequired,
    updatePositionSettings: PropTypes.func.isRequired,
    requestMe: PropTypes.func.isRequired,
    positionId: PropTypes.number.isRequired,
    sessionLoading: PropTypes.bool.isRequired,
    message: PropTypes.func.isRequired,
  };

  state = {
    editModeEnabled: false,
  };

  componentDidMount() {
    this.props.requestMe();
  }

  handleSubmit(data) {
    this.props.updatePositionSettings({
      ...this.transformData(data),
      positionId: this.props.positionId,
    });
  }

  onCancel(formProps) {
    if (formProps.dirty && !formProps.submitSucceeded) {
      formProps.form.reset();
    }

    this.disableEditMode();
  }

  getAllEventNames = () => {
    const { message } = this.props;
    const allEvents = flatten(getEventGroups(message).map((eventGroup) => eventGroup.events));

    return allEvents.map((event) => event.name);
  };

  hasAnyUncheckedEvent = (values) => (
    this.getAllEventNames()
      .some((eventName) => !values[eventName])
  );

  getInitialValues = () => {
    const initialValues = mapValues(this.props.settings, (s) => Boolean(Number(s)));

    return {
      positionNotificationsToggleAll: !this.hasAnyUncheckedEvent(initialValues),
      ...initialValues,
    };
  };

  transformData = (data) => mapValues(data, (d) => String(Number(d)));

  toggleAll = (args, state, { changeValue }) => {
    const [isToggleAllChecked] = args;

    this.getAllEventNames().forEach((eventName) => {
      changeValue(state, eventName, () => isToggleAllChecked);
    });
  };

  updateToggleAll = (args, state, { changeValue }) => {
    const [values] = args;
    changeValue(state, 'positionNotificationsToggleAll', () => !this.hasAnyUncheckedEvent(values));
  };

  handleEventToggle = (formProps) => (e) => {
    formProps.form.mutators.toggleAll(e.target.checked);
  }

  handleAllEventsToggle = (formProps, event) => (e) => {
    formProps.form.mutators.updateToggleAll({
      ...formProps.values,
      [event.name]: e.target.checked,
    });
  }

  enableEditMode() {
    this.setState({ editModeEnabled: true });
  }

  disableEditMode() {
    this.setState({ editModeEnabled: false });
  }

  renderAllEventsToggle(formProps) {
    const { editModeEnabled } = this.state;

    return (
      <div className={style.ToggleAll}>
        <div>
          <Field
            onChange={this.handleEventToggle(formProps)}
            name="positionNotificationsToggleAll"
            component={Toggle}
            disabled={!editModeEnabled}
            type="checkbox"
          />
        </div>
        <span className={style.Label}>
          <h3 className={style.GroupHeader}>
            <Message
              id="Toggle notifications for all events"
              comment="Used as the label for the toggle all events switch on the notifications page"
            />
          </h3>
        </span>
      </div>
    );
  }

  renderEvents(eventGroup, formProps) {
    const { editModeEnabled } = this.state;
    return (
      eventGroup.events.map((event) => (
        <div key={event.name} className={style.Event}>
          <div>
            <Field
              onChange={this.handleAllEventsToggle(formProps, event)}
              name={event.name}
              component={Toggle}
              disabled={!editModeEnabled}
              type="checkbox"
            />
          </div>
          <span className={style.Label}>
            {event.label}
            {!!event.tooltip && (
              <span className={style.TooltipSpan}>
                <TooltipInfo message={event.tooltip} />
              </span>
            )}
          </span>
        </div>
      ))
    );
  }

  render() {
    if (this.props.sessionLoading) {
      return (
        <div className={style.Loading}>
          <CircularSpinner />
        </div>
      );
    }

    const { message } = this.props;

    return (
      <div>
        <Form
          initialValues={this.getInitialValues()}
          mutators={{
            toggleAll: this.toggleAll,
            updateToggleAll: this.updateToggleAll,
          }}
          onSubmit={(formData) => this.handleSubmit(formData)}
          render={(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <NotificationsActionBar
                updateState={this.props.updateSettingsState}
                onClick={() => this.enableEditMode()}
                onSuccess={() => this.disableEditMode()}
                onCancel={() => this.onCancel(formProps)}
              />
              <ul className={style.Notifications}>
                {this.renderAllEventsToggle(formProps)}
                {getEventGroups(message).map((eventGroup) => (
                  <li key={eventGroup.header} className={style.EventGroup}>
                    <h3 className={style.GroupHeader}>
                      {eventGroup.header}
                    </h3>
                    {this.renderEvents(eventGroup, formProps)}
                  </li>
                ))}
              </ul>
            </form>
          )}
        />
      </div>
    );
  }
}

export default adminPage(({ props: { message } }) => ({
  title: message({
    id: 'Notifications',
    comment: 'Used as the title of the Notifications page',
  }),
  modules: [[]],
}))(NotificationsPage);
