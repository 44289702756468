import { Message } from '@oneflowab/pomes';

import ChatSupport from 'components/icons/chat-support';
import DocumentStar from 'components/icons/document-with-star';
import DocumentTemplates from 'components/icons/document-templates';
import DynamicTemplates from 'components/icons/dynamic-templates';
import EidSignatures from 'components/icons/eid-signatures';
import LiveEditing from 'components/icons/live-editing';
import TwoStepAuth from 'components/icons/two-step-auth';

/* eslint-disable import/prefer-default-export */
export const essentialsChurnFeatures = [
  {
    name: 'digitalDocuments',
    label: <Message id="Digital documents" comment="Feature description" />,
    icon: <DocumentStar />,
    id: 1,
  },
  {
    name: 'documentTemplates',
    label: <Message id="Document templates" comment="Feature description" />,
    icon: <DocumentTemplates />,
    id: 2,
  },
  {
    name: 'chatSupport',
    label: <Message id="Chat support" comment="Feature description" />,
    icon: <ChatSupport />,
    id: 3,
  },
  {
    name: 'twoStepAuth',
    label: <Message id="Two-step authentication" comment="Feature description" />,
    icon: <TwoStepAuth />,
    id: 4,
  },
];

export const businessChurnFeatures = [
  {
    name: 'eIdSignatures',
    label: <Message id="eID signatures" comment="Feature description" />,
    icon: <EidSignatures />,
    id: 1,
  },
  {
    name: 'liveEditing',
    label: <Message id="Live editing" comment="Feature description" />,
    icon: <LiveEditing />,
    id: 2,
  },
  {
    name: 'dynamicTemplates',
    label: <Message id="Dynamic templates" comment="Feature description" />,
    icon: <DynamicTemplates />,
    id: 3,
  },
  {
    name: 'smartDocumentLinking',
    label: <Message id="Smart document linking" comment="Feature description" />,
    icon: <DocumentTemplates />,
    id: 4,
  },
];
