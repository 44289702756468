import { closestCorners } from '@dnd-kit/core';

import type { CollisionDetection } from '@dnd-kit/core';
import type { Coordinates } from '@dnd-kit/utilities';

function isPointWithinRect(point: Coordinates, rect: DOMRect): boolean {
  const {
    top,
    left,
    bottom,
    right,
  } = rect;

  const isWithinVerticalBounds = top <= point.y && point.y <= bottom;
  const isWithinHorizontalBounds = left <= point.x && point.x <= right;

  return isWithinVerticalBounds && isWithinHorizontalBounds;
}

/**
 * Returns the closest rectangles from an array of rectangles to the corners of
 * another rectangle within a specific element
 */
export const closestCornersWithin: CollisionDetection = (args) => {
  const {
    pointerCoordinates,
    active,
  } = args;

  const widthInContainer = active?.data.current?.widthInRef?.current as HTMLDivElement;

  if (!pointerCoordinates || !widthInContainer) {
    return [];
  }

  const widthInRect = widthInContainer.getBoundingClientRect();

  if (!isPointWithinRect(pointerCoordinates, widthInRect)) {
    return [];
  }

  return closestCorners(args);
};
