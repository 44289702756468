// @flow

import React, {
  useCallback,
  useState,
} from 'react';
import { isString } from 'lodash';

import { isUrlValid } from 'forms/validators/url';
import { useRichTextProps } from 'contexts/rich-text';

import LinkPopover from 'components/rich-text-editor-toolbars/toolbar-buttons/link-button/link-popover';

import { getStyle } from '../../utils';

const validProtocols = ['http', 'https'];

const getLinkUrl = (url) => {
  if (!url || !isString(url)) {
    return '';
  }

  if (!url.startsWith('mailto:') && !isUrlValid(url, validProtocols)) {
    return '';
  }

  return url;
};

const Link = ({ attributes, children, element }: RenderSlateElementProps) => {
  const isMailTarget = element.url && element.url.startsWith('mailto:');
  const { isReadOnly } = useRichTextProps();
  const style = getStyle(element);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const openPopover = useCallback((event) => {
    event.preventDefault();

    if (isPopoverOpen) {
      return;
    }

    setIsPopoverOpen(true);
  }, [isPopoverOpen]);

  const aTag = (
    <a
      {...attributes}
      style={style}
      href={getLinkUrl(element.url)}
      target={isMailTarget ? undefined : '_blank'} // To avoid flashing when trying to open email app
      rel="nofollow noreferrer"
      onClick={isReadOnly ? undefined : openPopover}
    >
      {children}
    </a>
  );

  if (isReadOnly) {
    return aTag;
  }

  return (
    <LinkPopover
      onInteractOutside={() => setIsPopoverOpen(false)}
      onEscapeKeyDown={() => setIsPopoverOpen(false)}
      isOpen={isPopoverOpen}
      setIsOpen={setIsPopoverOpen}
      element={element}
      linkType={isMailTarget ? 'email' : 'url'}
    >
      {aTag}
    </LinkPopover>
  );
};

export default Link;
