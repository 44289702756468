import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  DndContext,
  closestCenter,
  MeasuringStrategy,
  DragEndEvent,
} from '@dnd-kit/core';
import { useDispatch } from 'react-redux';
import {
  findIndex,
  includes,
  isEmpty,
  keyBy,
  map,
} from 'lodash';
import clsx from 'clsx';

import { updateBoxAction } from 'reducers/current-contract';
import type { Props as ProductRowProps } from 'components/contract-boxes/product-table-box/product-table/product-table-expanded/product-row/product-row';
import type { ProductTableBox, Column } from 'data-validators/entity-schemas/document-box/product-table';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import { PRODUCT_TABLE_COLUMNS } from 'components/contract-boxes/product-table-box/constants';
import ColumnCellContent from 'components/contract-boxes/product-table-box/product-table/product-table-expanded/column-cell-content';
import ProductTablePopup from 'components/contract-boxes/product-table-box/product-table/product-table-popup';
import SortableProductRow from 'components/contract-boxes/product-table-box/product-table/product-table-expanded/sortable-product-row';

import style from './product-table-expanded.module.scss';

// It will be used in css data attribute selector.
// Hence this map, otherwise changes in PRODUCT_TABLE_COLUMNS will
// Break the css data attribute selector.
const dataColumnsAttributeMap = {
  [PRODUCT_TABLE_COLUMNS.NAME]: 'name',
  [PRODUCT_TABLE_COLUMNS.DESCRIPTION]: 'description',
  [PRODUCT_TABLE_COLUMNS.PRICE_1]: 'price_1',
  [PRODUCT_TABLE_COLUMNS.PRICE_2]: 'price_2',
  [PRODUCT_TABLE_COLUMNS.COUNT]: 'count',
};

type Props = ProductRowProps & {
  box: ProductTableBox,
  data: ProductTableBox['content']['data'],
  columns: Column[]
  updateProductConfig: ProductTableBox['config'],
  config: ProductTableBox['config'],
}

const ProductTableExpanded = ({
  agreementId,
  box,
  chosenProduct,
  columns,
  config,
  data,
  enabledColumns,
  handleDescriptionChange,
  isAllowedToEditBoxData,
  isGuestView,
  onClearChosenProduct,
  removeProduct,
  updateChosenProduct,
  updateProductConfig,
  updateProductData,
}: Props) => {
  const dispatch = useDispatch();
  const {
    DESCRIPTION,
    PRICE_1,
    PRICE_2,
    COUNT,
  } = PRODUCT_TABLE_COLUMNS;
  const { order } = config;
  const dataColumnsAttribute = enabledColumns.map((enabledColumn) => dataColumnsAttributeMap[enabledColumn]).join(' ');
  const productOrder = isEmpty(order) ? data.map((product) => ({ id: getId(product) })) : order;
  const products = keyBy(data, getId);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const activeIndex = findIndex(productOrder, (item) => getId(item) === active.id);
      const overIndex = findIndex(productOrder, (item) => getId(item) === over.id);

      const updatedData = arrayMove(box.content.data, activeIndex, overIndex);
      const updatedOrder = arrayMove(productOrder, activeIndex, overIndex);

      const updatedBox = {
        ...box,
        content: {
          ...box.content,
          data: updatedData,
        },
        config: {
          ...box.config,
          order: updatedOrder,
        },
      };
      dispatch(updateBoxAction(updatedBox));
    }
  };

  return (
    <div
      role="table"
      className={clsx(style.ExpandedTable, {
        [style.ReadOnly]: !isAllowedToEditBoxData,
      })}
      data-columns={dataColumnsAttribute}
      data-total-columns-count={enabledColumns?.length || 0}
    >
      <div className={clsx(style.TableRow, style.HeaderRow)}>
        {columns.filter((column) => column.enabled).map((column) => (
          <div
            role="columnheader"
            key={column.key}
            className={clsx(style.TableCell, style.TableHead, {
              [style.Description]: column.key === DESCRIPTION,
              [style.IsEditable]: !isAllowedToEditBoxData,
              [style.ProductPriceCell]: includes([PRICE_1, PRICE_2], column.key),
              [style.ProductQuantity]: column.key === COUNT,
            })}
          >
            <ProductTablePopup
              align="start"
              avoidCollisions
              column={column}
              config={config}
              data={data}
              disabled={!isAllowedToEditBoxData}
              sideOffset={5}
              updateProductConfig={updateProductConfig}
              updateProductData={updateProductData}
            >
              <ColumnCellContent
                columnKey={column.key}
                label={column.label}
                readOnly={!isAllowedToEditBoxData}
              />
            </ProductTablePopup>
          </div>
        ))}
      </div>
      <DndContext
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
      >
        <SortableContext
          items={productOrder.map((o) => getId(o))}
          strategy={verticalListSortingStrategy}
        >
          {map(productOrder, (item, index) => {
            const id = getId(item);
            const product = products[id];

            return (
              <SortableProductRow
                agreementId={agreementId}
                box={box}
                chosenProduct={chosenProduct}
                config={config}
                enabledColumns={enabledColumns}
                handleDescriptionChange={handleDescriptionChange}
                index={index}
                isGuestView={isGuestView}
                key={id}
                onClearChosenProduct={onClearChosenProduct}
                product={product}
                removeProduct={removeProduct}
                updateChosenProduct={updateChosenProduct}
                updateProductData={updateProductData}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};
export default ProductTableExpanded;
