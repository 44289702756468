// @flow

import * as React from 'react';
import { isEmpty, keys } from 'lodash';
import { Message } from '@oneflowab/pomes';

import { getAgreements } from 'oneflow-client/agreements';

import { withFilterConsumer } from 'hocs/search/filter/context';
import { CancelButton } from 'components/buttons';
import Conditional from 'components/conditional';
import Badge from 'components/badge';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import Confirmable from 'components/confirmable';
import Download from 'components/icons/download';
import Email from 'components/icons/email';
import EmptyState from 'components/empty-state';
import Tooltip from 'components/tooltip';
import CircularSpinner from 'components/icons/circular-spinner';
import type { SubscribedFilters } from 'hocs/search/filter/types';

import style from './export-button.module.scss';

export type Props = {
  activeFilters: SubscribedFilters,
  changeCheckboxState?: () => void,
  email: string,
  emptyStateHeader?: React.Node,
  folderId?: number,
  header: React.Node,
  icon: React.Node,
  includeSubfolders?: boolean,
  onExport: (Function, Function) => void;
  query?: Query,
  selectedFolder?: any,
  workspace?: Workspace,
  folders: Array<Folder>,
  isExportAgreements?: boolean,
};

type State = {
  success: boolean,
  failureCode: ?number,
  agreements: Array<Agreement>,
  hasLoadedAgreements: boolean,
  hasError: boolean,
};

type GetActions = () => React.Node;

const initialState = {
  success: false,
  failureCode: undefined,
  agreements: null,
  hasError: false,
};

const ignoredOperators = ['positionOperator', 'tagOperator', 'folderIds'];

export class ExportButtonComponent extends React.Component<Props, State> {
  static defaultProps = {
    emptyStateHeader: undefined,
  };

  state = initialState;

  getWorkspaceAgreements = async (request) => {
    try {
      const agreementsQuery = await getAgreements(request);
      this.setState({ agreements: agreementsQuery.result });
    } catch (error) {
      this.setState({ agreements: null });
      this.setState({ hasError: true });
    }
    return null;
  };

  fetchAgreements = () => {
    const { query, workspace } = this.props;

    const params = {
      ...query.params,
      collectionIds: [workspace?.id],
      includeFolderSubtree: true,
      isExport: true,
    };

    const request = {
      params,
      pagination: {
        limit: 100,
      },
      normalizeOutput: true,
    };
    this.getWorkspaceAgreements(request);
  }

  getEmptyStateHeader() {
    const { emptyStateHeader } = this.props;

    if (!emptyStateHeader) {
      return (
        <Message
          id="Nothing to export"
          comment="Empty state header. Shown in export modal when having no data."
        />
      );
    }

    return emptyStateHeader;
  }

  handleSuccess = () => {
    this.setState({
      success: true,
    });
  }

  handleOpen = () => {
    const { isExportAgreements } = this.props;

    if (isExportAgreements) {
      this.fetchAgreements();
    }

    this.setState({
      success: false,
    });
  }

  handleFailure = (statusCode: number) => {
    this.setState({
      failureCode: statusCode,
    });
  }

  handleExport = (onSuccess: Function, onFailure: Function) => () => {
    const { onExport } = this.props;

    onExport(onSuccess, onFailure);
  };

  handleConfirm = () => undefined

  resetState = () => {
    this.setState(initialState);
  }

  getActions: GetActions = ({ closeConfirmation }) => (
    <>
      <CancelButton onClick={closeConfirmation} />
      <Button
        customClass={style.RequestButton}
        data-testid="confirm"
        disabled={!this.getNumberOfResults()}
        icon={Email}
        kind="primary"
        onClick={this.handleExport(this.handleSuccess, this.handleFailure)}
      >
        <Message id="Request export" comment="Confirm button text in the export modal" />
      </Button>
    </>
  );

  renderTooManyRequests() {
    const { failureCode } = this.state;

    if (failureCode !== 429) {
      return null;
    }

    return (
      <div className={style.ErrorMessage}>
        <p className={style.ErrorHeader}>
          <Message
            id="An export is already ongoing."
            comment="Error header in the agreement export modal."
          />
        </p>
        <p>
          <Message
            id="Please try again later."
            comment="Empty state content. Shown in export modal when having no data."
          />
        </p>
      </div>
    );
  }

  renderExportHelpText() {
    const {
      activeFilters,
      folderId,
      selectedFolder,
      workspace,
    } = this.props;

    const filters = keys(activeFilters).filter((key) => !ignoredOperators.includes(key));
    const hasFilters = !isEmpty(filters);

    const name = (folderId !== -1
      && selectedFolder !== undefined ? selectedFolder.name : workspace?.name) || '';

    if (name !== '') {
      if (hasFilters) {
        return (
          <Message
            id="The export package will include only filtered documents from {name}."
            values={{
              name: <Badge label={name} kind="name" />,
            }}
            comment="Help text explaining what documents and folders will be exported."
          />
        );
      }

      return (
        <Message
          id="The export package will include all documents from {name}."
          values={{
            name: <Badge label={name} kind="name" />,
          }}
          comment="Help text explaining what documents and folders will be exported."
        />
      );
    }
    if (hasFilters) {
      return (
        <Message
          id="The export package will include only filtered documents."
          comment="Help text explaining what documents and folders will be exported."
        />
      );
    }

    return (
      <Message
        id="The export package will include all documents from all workspaces."
        comment="Help text explaining what documents and folders will be exported."
      />
    );
  }

  renderContent() {
    const {
      changeCheckboxState,
      email,
      includeSubfolders,
      folders,
      isExportAgreements,
      workspace,
    } = this.props;

    if (isExportAgreements) {
      return (
        <>
          <p>
            {this.renderExportHelpText()}
          </p>
          <Conditional ifCondition={folders.length > 0 && workspace}>
            <div className={style.CheckboxContainer}>
              <Checkbox
                customClass={style.Checkbox}
                input={{
                  checked: includeSubfolders,
                  onChange: changeCheckboxState,
                }}
                label={(
                  <Message
                    id="Include subfolders"
                    comment="Used as the label for a checkbox if the user wants to include subfolders"
                  />
                )}
              />
            </div>
          </Conditional>
          <p>
            <Message
              id="We will send a link to the export package to {userEmail} within an hour."
              values={{
                userEmail: <Badge label={email} kind="name" />,
              }}
              comment="Help text explaining where the email will be sent in the export modal."
            />
          </p>
        </>
      );
    }

    return (
      <>
        <p>
          <Message
            id="Your export will be sent to {userEmail}."
            values={{
              userEmail: <Badge label={email} kind="name" />,
            }}
            comment="Help text explaining where the email will be sent in the export modal."
          />
        </p>
        <p>
          <Message
            id="You will receive a link to download the export within an hour."
            comment="Help text explaining when the email will be sent in the export modal."
          />
        </p>
      </>
    );
  }

  getNumberOfResults = () => {
    const {
      isExportAgreements,
      query,
    } = this.props;

    const { agreements } = this.state;

    if (isExportAgreements) {
      return agreements?.length;
    }

    return query.count;
  }

  renderBody() {
    const {
      icon,
      isExportAgreements,
    } = this.props;
    const { agreements } = this.state;

    if (isExportAgreements && agreements === null) {
      return (
        <div>
          <div className={style.LoadingSpinner}>
            <CircularSpinner />
          </div>
        </div>
      );
    }

    if (!this.getNumberOfResults()) {
      return (
        <EmptyState
          icon={icon}
          header={this.getEmptyStateHeader()}
          content={(
            <Message
              id="Please adjust your search criteria and try again."
              comment="Empty state content. Shown in export modal when having no data."
            />
          )}
          defaultStyle
          className={style.NoData}
        />
      );
    }

    return (
      <>
        {this.renderContent()}
        {this.renderTooManyRequests()}
      </>
    );
  }

  render() {
    const { header } = this.props;
    const { success, hasError } = this.state;

    return (
      <Tooltip
        messageClassName={style.TooltipText}
        message={(
          <Message
            id="Export"
            comment="Tooltip text explaining what the export button is for."
          />
        )}
        sideOffset={8}
        side="top"
      >
        <span>
          <Confirmable
            header={header}
            customClass={style.ExportButton}
            success={success}
            body={this.renderBody()}
            actions={this.getActions}
            error={hasError}
            onEnter={this.handleConfirm}
            onOpen={this.handleOpen}
            onClose={this.resetState}
          >
            <Download height="15px" />
          </Confirmable>
        </span>
      </Tooltip>
    );
  }
}

export default (withFilterConsumer(ExportButtonComponent));
