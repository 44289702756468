import { isEmpty } from 'lodash';

import Message from 'components/message/message';
import { hasTrialExpired } from 'account';
// eslint-disable-next-line import/named
import { StartTrial } from 'components/modals/start-trial';
import { ActionBarText } from 'components/action-bar-text';
import Button from 'components/button';

import style from './seats-info.module.scss';

type Props = {
  account: Oneflow.Account,
  accountSeats?: Oneflow.AccountSeats,
}

const SeatsInfo = ({ accountSeats, account }: Props) => {
  if (isEmpty(accountSeats)) {
    return null;
  }

  const renderSeatsUsageMessage = () => {
    const {
      isDemo,
      trial,
      isFreemium,
    } = account;

    if (isDemo) {
      return (
        <Message
          id="You have a demo account, using {taken} seat"
          pluralId="You have a demo account, using {taken} seats"
          pluralCondition="taken"
          values={{
            taken: accountSeats.taken,
          }}
          comment="Header. Seat usage information for demo accounts"
        />
      );
    }

    if (trial) {
      return (
        <Message
          id="You have a trial account, using {taken} seat"
          pluralId="You have a trial account, using {taken} seats"
          pluralCondition="taken"
          values={{
            taken: accountSeats.taken,
          }}
          comment="Header. Seat usage information for trial accounts"
        />
      );
    }

    if (isFreemium) {
      return (
        <Message
          id="You are on a free plan, using {taken} seat"
          pluralId="You are on a free plan, using {taken} seats"
          pluralCondition="taken"
          values={{
            taken: accountSeats.taken,
          }}
          comment="Header. Seat usage information for freemium accounts"
        />
      );
    }

    if (accountSeats.taken > accountSeats.total) {
      return (
        <Message
          id="Your account is using too many seats - {taken} of {total} seats used"
          values={{
            total: accountSeats.total,
            taken: accountSeats.taken,
          }}
          comment="Header. Seat usage information when there are no seats available"
        />
      );
    }

    return (
      <Message
        id="{available} seat available - {taken} of {total} seats used"
        pluralId="{available} seats available - {taken} of {total} seats used"
        pluralCondition="available"
        values={{
          total: accountSeats.total,
          available: accountSeats.available,
          taken: accountSeats.taken,
        }}
        comment="Header to inform on seats availability"
      />
    );
  };

  const renderStartTrialText = (onClick: () => void) => (
    <Message
      id="Try our {jsx-start}free 14-day trial{jsx-end}, or click on upgrade account to become a customer."
      comment="Explanation text for how to become a customer."
      component={Button}
      kind="linkInline"
      customClass={style.StartTrialButton}
      onClick={onClick}
    />
  );

  const renderSeatsHelpText = () => {
    const { isDemo, trial, isFreemium } = account;

    if (isFreemium && !trial && !hasTrialExpired(account.trialEndTimestampTs)) {
      return (
        <StartTrial>
          {renderStartTrialText}
        </StartTrial>
      );
    }

    if (trial) {
      return (
        <Message
          id="To become a customer, click on the upgrade account button."
          comment="Explanation text for how to become a customer."
        />
      );
    }

    if (isDemo) {
      return (
        <Message
          id="Each active user requires one seat. Deactivating a user will free up a seat."
          comment="Explanation text for seats count"
        />
      );
    }

    return (
      <Message
        id="Each active user requires one seat. Deactivating a user will free up a seat. To reduce seats, please contact {email}."
        values={{
          email: <a className={style.SupportLink} href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Explanation text for seats count"
      />
    );
  };

  return (
    <ActionBarText
      header={renderSeatsUsageMessage()}
      text={renderSeatsHelpText()}
    />
  );
};

export default SeatsInfo;
