import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import SelectField from 'components/select-field';

import { getDurationTypesAsOptions } from 'agreement/duration';

import BoxEmptyDraftStateRaw from '../box-empty-draft-state-raw';
import style from './box-empty-draft-state-duration.module.scss';

type Props = {
  icon: any,
  onChange: () => void,
  isDisabled: boolean,
  message: MessageTranslator,
};

export const BoxEmptyDraftStateDuration = ({
  message,
  icon,
  onChange,
  isDisabled,
}: Props) => (
  <BoxEmptyDraftStateRaw
    icon={icon}
    mainAction={(
      <div className={style.Dropdown}>
        <SelectField
          input={{
            onChange,
          }}
          placeholder={message({
            id: 'Select duration type',
            comment: 'Placeholder for duration type selection.',
          })}
          options={getDurationTypesAsOptions(message)}
          searchable={false}
          hideErrorElement
          disabled={isDisabled}
          small
        />
      </div>
    )}
  />
);

export default localize<Props>(BoxEmptyDraftStateDuration);
