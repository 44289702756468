import type { ReactNode, RefObject } from 'react';

import clsx from 'clsx';
import {
  useDndContext,
  useDraggable,
  DragOverlay,
  CollisionDetection,
  rectIntersection,
} from '@dnd-kit/core';
import { useRef } from 'react';
import { Message } from '@oneflowab/pomes';

import useCurrentContractId from 'hooks/use-current-contract-id';
import useAgreement from 'hooks/use-agreement';

import { checkAcl } from 'components/acl';
import DragHandlerIcon from 'components/icons/drag-handler';
import { useDocumentDndContext } from 'components/document-layout-container/document-dnd-context';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import SignatureIcon from 'components/icons/signature';
import { PendingSignature } from 'components/contract-boxes/pdf-box/pdf-file/pdf-viewer/pdf-page/overlay-field/overlay-signature-field/pending-signature';
import Tooltip from 'components/tooltip';
import { OverNonPdfSectionsTooltip } from 'components/document-tabs/components/over-non-pdf-sections-tooltip';
import { SIGNATURE_FIELD } from 'components/contract-boxes/constants';

import style from './draggable-field.module.scss';

type Props = {
  type: string;
  label: ReactNode;
};

type DraggableData = {
  segmentId: 'smart_fields';
  droppableDragOverlayRef: RefObject<HTMLElement>;
  boxType: 'signature';
  collisionDetection: CollisionDetection;
  widthInRef: RefObject<HTMLElement>;
};

export const DraggableField = ({
  type,
  label,
}: Props) => {
  const agreementId = useCurrentContractId() as number;
  const agreement = useAgreement(agreementId);
  const isAllowedToUpdateLayout = checkAcl(agreement.acl, 'agreement:layout:update');

  const { documentContentRef } = useDocumentLayout();
  const droppableDragOverlayRef = useRef(null);
  const { activeDndId } = useDocumentDndContext();
  const { active, over } = useDndContext();
  const activeSegmentId = active?.data?.current?.segmentId;
  const overSegmentId = over?.data?.current?.segmentId;
  const scale = over?.data.current?.scale || 1;
  const isOverNonPdfSections = !!activeDndId && overSegmentId !== 'smart_fields';

  const draggableId = `draggable_field_${type}`;
  const {
    attributes,
    listeners,
    setNodeRef,
  } = useDraggable({
    id: draggableId,
    disabled: !isAllowedToUpdateLayout,
    data: {
      type: SIGNATURE_FIELD,
      segmentId: 'smart_fields',
      droppableDragOverlayRef,
      boxType: type,
      collisionDetection: rectIntersection,
      widthInRef: documentContentRef,
    } as DraggableData,
  });

  const isDragOverlayActive = activeDndId === draggableId && activeSegmentId === 'smart_fields';

  const className = clsx(style.DraggableSection, {
    [style.IsDragOverlayActive]: isDragOverlayActive,
    [style.Disabled]: !isAllowedToUpdateLayout,
  });

  return (
    <>
      <Tooltip
        message={(
          <Message
            id="You don’t have permissions to use this"
            comment="Tool tip message for why sections is disabled"
          />
        )}
        theme="oneflow"
        side="top"
        sideOffset={4}
        hideContent={isAllowedToUpdateLayout}
      >
        <div>
          <div
            ref={setNodeRef}
            className={className}
            {...listeners}
            {...attributes}
            data-testid="draggable-field"
          >
            <div className={style.DragHandle}>
              <DragHandlerIcon height="13px" width="8" />
            </div>
            <div className={style.SectionType}>
              <div className={style.SectionLabel}>{label}</div>
              <SignatureIcon />
            </div>
          </div>
          <DragOverlay dropAnimation={null} className={style.DragOverlay}>
            {isDragOverlayActive ? (
              <>
                {isOverNonPdfSections && <OverNonPdfSectionsTooltip />}
                <div data-testid="section-drag-overlay" style={{ transform: `translate3d(0, 0, 0) scale3d(${scale}, ${scale}, 1)` }} className={clsx(style.DraggableSection, style.Overlay)} ref={droppableDragOverlayRef}>
                  <PendingSignature />
                  <SignatureIcon className={style.SignatureIcon} width="16px" />
                </div>
              </>
            ) : null}
          </DragOverlay>
        </div>
      </Tooltip>
    </>
  );
};
