/* eslint-disable react/prop-types */
// @flow

import * as React from 'react';
import { matchPath } from 'react-router';
import { Message } from '@oneflowab/pomes';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

// eslint-disable-next-line import/no-cycle
import Filter from 'hocs/search/filter/filter';
import { getFiltersValues } from 'hocs/search/filter/utils';
import type { Params } from 'hocs/query-string-params/types';
import type { UpdateQueryStringParams } from 'hocs/query-string-params/query-string-params';
import webSocket from 'web-socket';
import { REMOVED } from 'agreement/constants';

import Tooltip from 'components/tooltip';
import { checkAcl } from 'components/acl';
import { getPageNumberForOffset } from 'components/pagination/helpers';
import Button from 'components/button';
import { FolderBreadCrumb } from 'components/folder-bread-crumb';
import FolderTree from 'components/folder-tree';
import { ImportContract } from 'components/modals/import-contract';
import ImportContractIcon from 'components/icons/import-contract';
import { SearchFiltersInformation } from 'components/search-filters-information';
import { EmptyTrashModal } from 'components/modals/empty-trash';
import PermanentDeleteIcon from 'components/icons/permanent-delete';

import type { SubscribedFilters } from './filter/types';

import style from './search.module.scss';

const getSelectedUniqueKey = (params = {}) => {
  if (!isEmpty(params) && params.folderIds) {
    return params.folderIds[0] || -1;
  }

  return -1;
};

export type Props<T> = {
  children: React.Node,
  executeQuery: (
    pageNumber?: number,
    queryParams: {},
    sort?: Array<string>,
  ) => void,
  filters: React.Node,
  folders: Array<Folder>,
  folderId?: any,
  keyword: React.Node,
  params?: Params,
  pathname: string,
  query: Query,
  queryName: string,
  replaceParams: UpdateQueryStringParams,
  result: Array<T>,
  sorting: React.Node,
  workspace: {
    acl?: any,
    id: number,
    virtual?: Boolean,
  },
  availableFilters: React.MutableRefObject<SubscribedFilters>
};

type SearchResultType<T> = {
  query?: Query,
  result: Array<T>,
}

export const SearchResult = React.createContext<SearchResultType<any>>({ result: [] });

export class Search<T> extends React.PureComponent<Props<T>, State> {
  static defaultProps = {
    params: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      emptyingTrashcan: false,
    };
  }

  trashEmptyPageUnsubscribe: any;

  componentDidMount() {
    const {
      replaceParams, params, availableFilters, position,
      queryAgreementsReload,
    } = this.props;
    const { urlParams } = getFiltersValues(
      params,
      availableFilters.current,
    );

    if (!isEqual(params, urlParams)) {
      replaceParams(urlParams);
    } else {
      this.executeQuery();
    }

    const isOnTrashPage = this.isOnTrashSearchPage();

    if (isOnTrashPage) {
      this.trashEmptyPageUnsubscribe = webSocket.subscribe({
        channelName: `private-position-${position.id}`,
        event: 'workspace:empty_trash',
        eventCallback: () => {
          queryAgreementsReload();
          this.setState({ emptyingTrashcan: false });
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.isOnTrashSearchPage()) {
      this.trashEmptyPageUnsubscribe();
      this.setState({ emptyingTrashcan: false });
    }
  }

  componentDidUpdate(prevProps: Props<T>) {
    const { replaceParams, params, availableFilters } = this.props;
    const { queryParams, urlParams } = getFiltersValues(
      params,
      availableFilters.current,
    );

    if (this.hasCurrentWorkspaceChanged(prevProps)
      || this.hasInitialValuesChanged(prevProps)
      || this.hasFilterValuesChanged(prevProps, queryParams)) {
      if (!isEqual(params, urlParams)) {
        replaceParams(urlParams, { navigate: false });
      } else {
        this.executeQuery();
      }
    }
  }

  onPaginate = (pagination: {}) => {
    const { replaceParams, params, availableFilters } = this.props;
    const { urlParams } = getFiltersValues(
      params,
      availableFilters.current,
    );
    const page = getPageNumberForOffset(pagination.offset, pagination.limit);

    replaceParams({
      ...urlParams,
      page,
    }, { navigate: true, hasPagination: true });
  };

  getImportContract() {
    const {
      workspace,
      children,
      folders,
      folderId,
      params,
    } = this.props;
    const { hideImport } = get(children, 'props');
    const selectedFolder = folders.find((folder) => folder.id === getSelectedUniqueKey(params));

    if (!checkAcl(workspace.acl, 'collection:agreement:import') || hideImport) {
      return null;
    }

    return (
      <>
        <div className={style.ActionBarDivider} />
        <ImportContract
          folderId={folderId}
          selectedFolder={selectedFolder}
          workspace={workspace}
          selectedUniqueKey={getSelectedUniqueKey(params)}
        >
          {this.renderImportButton}
        </ImportContract>
      </>
    );
  }

  hasFoldersViewPermission = () => {
    const { workspace } = this.props;
    return checkAcl(workspace.acl, 'collection:folder:view');
  }

  renderEmptyTrash() {
    const { workspace, result } = this.props;
    const hasTrashAccess = checkAcl(workspace.acl, 'collection:agreement:trash:view');
    const isAllowedToEmptyTrash = checkAcl(workspace.acl, 'collection:agreement:template:trash:permanently_delete');
    const filteredResult = result.filter((agreement) => agreement.removed === REMOVED);

    const isGlobalSearchTrash = this.isOnGlobalSearchPage() && this.isOnTrashSearchPage();
    if (!hasTrashAccess || isGlobalSearchTrash) {
      return null;
    }

    return (
      <EmptyTrashModal
        setLoadingState={(loading: boolean) => this.setState({ emptyingTrashcan: loading })}
      >
        {(onClick) => (
          <>
            <div className={style.ActionBarDivider} />
            <Button
              customClass={style.ImportButton}
              onClick={onClick}
              icon={PermanentDeleteIcon}
              kind="secondary-lite"
              disabled={
                !isAllowedToEmptyTrash
                || this.state.emptyingTrashcan
                || filteredResult.length === 0
              }
            >
              <Message
                id="Empty trash"
                comment="Button text for the empty trash button."
              />
            </Button>
          </>
        )}
      </EmptyTrashModal>
    );
  }

  renderImportButton = (onClick: Function) => (
    <div className={style.ImportButtonContainer}>
      <Tooltip
        message={(
          <Message
            id="Import signed document"
            comment="Tooltip text for the import button."
          />
        )}
        side="top"
        theme="oneflow"
      >
        <div>
          <Button
            customClass={style.ImportButton}
            icon={ImportContractIcon}
            kind="secondary-lite"
            onClick={onClick}
            trackable={{
              name: 'Go To Import Document',
              props: {
                location: 'contract list',
                'temp ai import modal': checkAcl(this.props.workspace.acl, 'collection:ai_import:use'),
              },
            }}
          >
            <Message
              id="Import"
              comment="To be used as the label for the button to import a document in the document list page"
            />
          </Button>
        </div>
      </Tooltip>
    </div>
  );

  hasCurrentWorkspaceChanged(prevProps: Props<T>) {
    const { workspace: { id: workspaceId } } = this.props;
    const { workspace: { id: prevWorkspaceId } } = prevProps;

    return workspaceId !== prevWorkspaceId;
  }

  hasInitialValuesChanged(prevProps: Props<T>) {
    return !isEqual(this.props.params, prevProps.params);
  }

  hasFilterValuesChanged(prevProps: Props<T>, queryParams: any) {
    const { availableFilters } = this.props;
    const {
      queryParams: previousQueryParams,
    } = getFiltersValues(prevProps.params, availableFilters.current);

    return !isEqual(queryParams, previousQueryParams);
  }

  executeQuery() {
    const {
      children,
      executeQuery,
      params,
      availableFilters,
    } = this.props;
    const { queryParams } = getFiltersValues(params, availableFilters.current);
    const { withoutWorkspaceId, sendWorkspaceIds } = children.props;

    const finalParams = {
      ...omit(queryParams, 'page'),
      isGlobalSearch: this.isOnGlobalSearchPage(),
      sendWorkspaceIds,
      withoutWorkspaceId,
      isTrashSearch: this.isOnTrashSearchPage(),
    };

    executeQuery(
      finalParams,
      queryParams.sort,
      queryParams.page,
    );
  }

  isOnGlobalSearchPage() {
    const { pathname } = this.props;

    return Boolean(matchPath(pathname, {
      path: '/search',
      exact: false,
    }));
  }

  isOnTrashSearchPage() {
    const { pathname } = this.props;

    return Boolean(matchPath(pathname, {
      path: '*/trash',
      exact: true,
    }));
  }

  renderFoldersListComponent() {
    const {
      params,
      workspace,
    } = this.props;
    if (
      !workspace.virtual
      && !this.isOnGlobalSearchPage()
      && !this.isOnTrashSearchPage()
      && this.hasFoldersViewPermission()
    ) {
      return (
        <FolderBreadCrumb
          selectedUniqueKey={getSelectedUniqueKey(params)}
        />
      );
    }

    return null;
  }

  renderFolders() {
    const { workspace, params } = this.props;
    if (
      !workspace.virtual
      && !this.isOnGlobalSearchPage()
      && !this.isOnTrashSearchPage()
      && this.hasFoldersViewPermission()
    ) {
      return (
        <Filter
          component={FolderTree}
          defaultValue={[]}
          onSanitize={(value) => value}
          param="folderIds"
          selectedUniqueKey={getSelectedUniqueKey(params)}
          wrap={false}
        />
      );
    }
    return null;
  }

  render() {
    const {
      children,
      filters,
      folders,
      keyword,
      params,
      query,
      queryName,
      result,
      sorting,
      workspace,
    } = this.props;

    return (
      <div className={style.Search}>
        <div className={style.SearchBody}>
          <div className={style.FilterSidebarContainer}>
            {this.renderFolders()}
            <SearchFiltersInformation />
            {filters}
          </div>
          <div className={style.ResultContainer}>
            <div className={style.ActionBar}>
              {keyword}
              <div className={style.ActionBarDivider} />
              {sorting}
              <div className={style.RightSide}>
                {this.isOnTrashSearchPage() && this.renderEmptyTrash()}
                {this.getImportContract()}
              </div>
            </div>
            {this.renderFoldersListComponent()}
            <SearchResult.Provider
              value={{
                folders,
                onPaginate: this.onPaginate,
                query,
                queryName,
                result,
                selectedFolderId: getSelectedUniqueKey(params),
                workspace,
                emptyingTrashcan: this.state.emptyingTrashcan,
              }}
            >
              {children}
            </SearchResult.Provider>
          </div>
        </div>
      </div>

    );
  }
}
