import { Message, MessageTranslator } from '@oneflowab/pomes';
import { getTranslatedCountries } from './charts/utils';

export const getTexts = (message?: MessageTranslator, lang?: string) => ({
  insightsTexts: {
    CUSTOM: {
      PaymentTerm: {
        insightTitle: (
          <Message
            future
            id="Payment terms"
            comment="Title of the payment term clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A payment term outlines the time frame in which a party is required to pay the invoice."
            comment="Description of the payment term clause."
          />
        ),
        getPlaybookText: ({ paymentTerm }: { paymentTerm: string }) => (
          <>
            <Message
              future
              id="The playbook prefers payment terms of up to {paymentTerm} days. This period is typically calculated from the invoice date or a specific reference point, such as the start or end of a month."
              values={{ paymentTerm: <b>{paymentTerm || 0}</b> }}
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
            <br />
            <Message
              future
              id="'Unspecified' refers to a document with multiple or non-specific payment term provisions. 'Missing' indicates that no payment term clause was found in the document."
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
          </>
        ),
      },
    },
    SALES: {
      DisputeResolution: {
        insightTitle: (
          <Message
            future
            id="Dispute resolution"
            comment="Title of the Dispute resolution chart."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A dispute resolution clause outlines the process for resolving disputes between parties."
            comment="Description of the Dispute resolution chart."
          />
        ),
        getPlaybookText: ({ arbitration }: { arbitration: 'true' | 'false' }) => (
          <>
            <Message
              future
              id="Arbitration is a private dispute resolution process where parties present their case to a neutral third party. It offers advantages like speed and confidentiality but may also involve significant costs."
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
            <br />
            {arbitration === 'true' ? (
              <Message
                future
                id="The playbook supports arbitration as the preferred dispute resolution method."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
            ) : (
              <Message
                future
                id="The playbook does not support arbitration as the preferred dispute resolution method."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
            )}
          </>
        ),
      },
      Indemnities: {
        insightTitle: (
          <Message
            future
            id="Indemnities"
            comment="Title of the indemnities chart."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="Confirm the presence of indemnity clauses that specify compensation for losses or damages."
            comment="Description of the price adjustment clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Indemnity clauses protect a party by requiring the other to compensate for losses, damages, or liabilities arising from specific situations, such as breaches or third-party claims. These clauses are vital for managing risk. Ensure the scope, limitations, and claims handling processes are aligned with your business needs."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      ExclusionConsequentialDamages: {
        insightTitle: (
          <Message
            future
            id="Exclusion of consequential damages"
            comment="Title of the Exclusion of consequential damages chart."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="Check for the presence or absence of clauses that exclude consequential losses."
            comment="Description of the price adjustment clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="The exclusion of indirect and consequential damages clause limits liability to direct damages. Ensure it’s included when needed and protects your business by reducing risks while allowing fair recovery if something goes wrong."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      PriceAdjustment: {
        insightTitle: (
          <Message
            future
            id="Price adjustment"
            comment="Title of the price adjustment clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A price adjustment clause allows for changes in the contract price based on specific factors, such as fluctuations in material costs or market conditions."
            comment="Description of the price adjustment clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="A price adjustment clause provides predefined terms for when and how prices can be adjusted, which is particularly important for long-term contracts. For longer agreements involving selling, licensing, or similar transactions that lack a price adjustment clause, consider adding one to ensure flexibility and protection against cost fluctuations."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      LimitationOfLiability: {
        insightTitle: (
          <Message
            future
            id="Limitation of liability"
            comment="Title of the limitation of liability clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A limitation of liability clause caps the amount one party can claim from the other in case of a contract breach or other issues. This cap can, for example, be defined as a fixed amount or a percentage of the contract value or fees paid in a certain period."
            comment="Description of the limitation of liability clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="A clear liability cap should be included in agreements for selling, licensing, or similar transactions. "
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      AutomaticRenewal: {
        insightTitle: (
          <Message
            future
            id="Automatic renewal"
            comment="Title of the automatic renewal clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="An automatic renewal clause states that the contract will automatically renew without requiring any action from either party."
            comment="Description of the automatic renewal clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="An automatic renewal clause means the contract will renew without action from either party, which can be both a risk and an opportunity. Ensure the clause aligns with your business needs to avoid unintended extensions and allow flexibility, and if you prefer automatic renewals, ensure your business meets any renewal requirements."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      LiquidatedDamages: {
        insightTitle: (
          <Message
            future
            id="Liquidated damages"
            comment="Title of the liquidated damages clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A liquidated damages clause sets a predetermined amount one party must pay in case of a contract breach."
            comment="Description of the liquidated damages clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Liquidated Damages clauses specify a pre-agreed compensation for specific breaches. However, be careful when agreeing to it, ensuring the amount matches the potential losses and protects your interests without causing overpayments."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      GoverningLaw: {
        insightTitle: (
          <Message
            future
            id="Governing law"
            comment="Title of the governing law clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A governing law clause explains which law will govern the contract, which is crucial for dispute interpretation."
            comment="Description of the governing law clause."
          />
        ),
        getPlaybookText: ({ governingLaws }: { governingLaws: string }) => {
          const translatedCountries = getTranslatedCountries({ governingLaws, lang, message });
          return (
            <>
              <Message
                future
                id="Ideally, the Governing law should be a location where the parties have legal representation or a neutral jurisdiction to avoid giving either party an advantage."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
              {translatedCountries && (
                <Message
                  future
                  id="The playbook prefers the laws of {governingLaws}"
                  values={{ governingLaws: <b>{translatedCountries}</b> }}
                  comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
                />
              )}
              <br />
              <Message
                future
                id="'Unspecified' refers to a document with non-specific governing law provisions. 'Missing' indicates that no governing law clause was found in the document."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
            </>
          );
        },
      },
      Termination: {
        insightTitle: (
          <Message
            future
            id="Termination"
            comment="Title of the termination clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A termination clause defines the conditions under which a contract can be ended, outlining the rights of a party to terminate the agreement when specific circumstances arise."
            comment="Description of the termination clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Ensure the grounds and consequences of termination align with your business needs, offering protection and enabling a smooth transition when necessary."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      PaymentTerm: {
        insightTitle: (
          <Message
            future
            id="Payment terms"
            comment="Title of the payment term clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A payment term outlines the time frame in which a party is required to pay the invoice."
            comment="Description of the payment term clause."
          />
        ),
        getPlaybookText: ({ paymentTerm }: { paymentTerm: string }) => (
          <>
            <Message
              future
              id="The playbook prefers payment terms of up to {paymentTerm} days. This period is typically calculated from the invoice date or a specific reference point, such as the start or end of a month."
              values={{ paymentTerm: <b>{paymentTerm || 0}</b> }}
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
            <br />
            <Message
              future
              id="'Unspecified' refers to a document with multiple or non-specific payment term provisions. 'Missing' indicates that no payment term clause was found in the document."
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
          </>
        ),
      },
      NoticePeriod: {
        insightTitle: (
          <Message
            future
            id="Notice period"
            comment="Title of the notice period clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A notice period clause specifies when one party must notify the other before terminating the contract."
            comment="Description of the notice period clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Ensure the notice period provides enough time for both parties to prepare without causing unnecessary delays. If a shorter notice period is preferable for faster termination, ensure it’s clearly defined and enforceable to maintain flexibility."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      ForceMajeure: {
        insightTitle: (
          <Message
            future
            id="Force majeure"
            comment="Title of the force majeure clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A force majeure clause excuses parties from their contractual obligations if unexpected events beyond their control, such as natural disasters, hinder their ability to perform."
            comment="Description of the force majeure clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="A force majeure clause is essential for many agreements involving selling, licensing, or similar transactions, so be especially cautious with contracts that lack it."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      AgreementTerm: {
        insightTitle: (
          <Message
            future
            id="Agreement terms"
            comment="Title of the agreement term clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="An agreement term clause defines a period or end date for the initial duration of the contract until it is terminated or renewed."
            comment="Description of the agreement term clause."
          />
        ),
        getPlaybookText: ({ agreementTerm }: { agreementTerm: string }) => (
          <Message
            future
            id="The playbook prefers agreement terms with an initial duration of {agreementTerm} months."
            values={{ agreementTerm: <b>{agreementTerm || 0}</b> }}
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      Confidentiality: {
        insightTitle: (
          <Message
            future
            id="Confidentiality"
            comment="Title of the confidentiality clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A confidentiality clause requires parties to safeguard information and prevent its sharing or misuse beyond what has been authorized by the disclosing party."
            comment="Description of the confidentiality clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="When reviewing confidentiality clauses, ensure they clearly define confidential information, who is authorized to access it, and how it must be protected."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
    },
    SOURCING: {
      DisputeResolution: {
        insightTitle: (
          <Message
            future
            id="Dispute resolution"
            comment="Title of the Dispute resolution chart."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A dispute resolution clause outlines the process for resolving disputes between parties."
            comment="Description of the Dispute resolution chart."
          />
        ),
        getPlaybookText: ({ arbitration }: { arbitration: 'true' | 'false' }) => (
          <>
            <Message
              future
              id="Arbitration is a private dispute resolution process where parties present their case to a neutral third party. It offers advantages like speed and confidentiality but may also involve significant costs."
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
            <br />
            {arbitration === 'true' ? (
              <Message
                future
                id="The playbook supports arbitration as the preferred dispute resolution method."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
            ) : (
              <Message
                future
                id="The playbook does not support arbitration as the preferred dispute resolution method."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
            )}
          </>
        ),
      },
      Indemnities: {
        insightTitle: (
          <Message
            future
            id="Indemnities"
            comment="Title of the indemnities chart."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="Confirm the presence of indemnity clauses that specify compensation for losses or damages."
            comment="Description of the price adjustment clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Indemnity clauses protect a party by requiring the other to compensate for losses, damages, or liabilities arising from specific situations, such as breaches or third-party claims. These clauses are vital for managing risk. Ensure the scope, limitations, and claims handling processes are aligned with your business needs."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      ExclusionConsequentialDamages: {
        insightTitle: (
          <Message
            future
            id="Exclusion of consequential damages"
            comment="Title of the Exclusion of consequential damages chart."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="Check for the presence or absence of clauses that exclude consequential losses."
            comment="Description of the price adjustment clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="The exclusion of indirect and consequential damages clause limits liability to direct damages. Ensure it’s included when needed while giving you enough coverage to avoid gaps in recovery in case of a breach."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      PriceAdjustment: {
        insightTitle: (
          <Message
            future
            id="Price adjustment"
            comment="Title of the price adjustment clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A price adjustment clause allows for changes in the contract price based on specific factors, such as fluctuations in material costs or market conditions."
            comment="Description of the price adjustment clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Price adjustments should be tied to specific factors, such as changes in material costs, inflation, or market conditions. Ensure any price changes are capped or limited to avoid unexpected cost increases. This ensures transparency and allows your business to plan for potential pricing changes."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      LimitationOfLiability: {
        insightTitle: (
          <Message
            future
            id="Limitation of liability"
            comment="Title of the limitation of liability clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A limitation of liability clause caps the amount one party can claim from the other in case of a contract breach or other issues. This cap can, for example, be defined as a fixed amount or a percentage of the contract value or fees paid in a certain period."
            comment="Description of the limitation of liability clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Ensure the clause is balanced, protecting against excessive liability while allowing for reasonable recovery in critical situations."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      AutomaticRenewal: {
        insightTitle: (
          <Message
            future
            id="Automatic renewal"
            comment="Title of the automatic renewal clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="An automatic renewal clause states that the contract will automatically renew without requiring any action from either party."
            comment="Description of the automatic renewal clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Ensure automatic renewal clauses provide flexibility to review performance, terminate the contract, or renegotiate terms, preventing unintended extensions and avoiding being locked into unfavorable conditions."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      LiquidatedDamages: {
        insightTitle: (
          <Message
            future
            id="Liquidated damages"
            comment="Title of the liquidated damages clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A liquidated damages clause sets a predetermined amount one party must pay in case of a contract breach."
            comment="Description of the liquidated damages clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Ensure the amount of the liquidated damage reflects anticipated losses, protects your interests, and avoids underpayment, ensuring fair recovery in case of significant breaches."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      GoverningLaw: {
        insightTitle: (
          <Message
            future
            id="Governing law"
            comment="Title of the governing law clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A governing law clause explains which law will govern the contract, which is crucial for dispute interpretation."
            comment="Description of the governing law clause."
          />
        ),
        getPlaybookText: ({ governingLaws }: { governingLaws: string }) => {
          const translatedCountries = getTranslatedCountries({ governingLaws, lang, message });
          return (
            <>
              <Message
                future
                id="Ideally, the governing law should be a location where the parties have legal representation or a neutral jurisdiction to avoid giving either party an advantage."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
              {translatedCountries && (
                <Message
                  future
                  id="The playbook prefers the laws of {governingLaws}."
                  values={{ governingLaws: <b>{translatedCountries}</b> }}
                  comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
                />
              )}
              <br />
              <Message
                future
                id="'Unspecified' refers to a document with non-specific governing law provisions."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
              <br />
              <Message
                future
                id="'Missing' indicates that no governing law clause was found in the document."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
            </>
          );
        },
      },
      Termination: {
        insightTitle: (
          <Message
            future
            id="Termination"
            comment="Title of the termination clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A termination clause defines the conditions under which a contract can be ended, outlining the rights of a party to terminate the agreement when specific circumstances arise."
            comment="Description of the termination clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Ensure the grounds and consequences of termination align with your business needs, offering protection and enabling a smooth transition when necessary."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      PaymentTerm: {
        insightTitle: (
          <Message
            future
            id="Payment terms"
            comment="Title of the payment term clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A payment term outlines the time frame in which a party is required to pay the invoice."
            comment="Description of the payment term clause."
          />
        ),
        getPlaybookText: ({ paymentTerm } : { paymentTerm: string }) => (
          <>
            <Message
              future
              id="The playbook prefers payment terms of at least {paymentTerm} days. This period is typically calculated from the invoice date or a specific reference point, such as the start or end of a month."
              values={{ paymentTerm: <b>{paymentTerm || 0}</b> }}
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
            <Message
              future
              id="'Unspecified' refers to a document with multiple or non-specific payment term provisions."
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
            <Message
              future
              id="'Missing' indicates that no payment term clause was found in the document."
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
          </>
        ),
      },
      NoticePeriod: {
        insightTitle: (
          <Message
            future
            id="Notice period"
            comment="Title of the notice period clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A notice period clause specifies when one party must notify the other before terminating the contract. "
            comment="Description of the notice period clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Ensure the notice period provides enough time for both parties to prepare without causing unnecessary delays. If a shorter notice period is preferable for faster termination, ensure it’s clearly defined and enforceable to maintain flexibility."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      ForceMajeure: {
        insightTitle: (
          <Message
            future
            id="Force majeure"
            comment="Title of the force majeure clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A force majeure clause excuses parties from their contractual obligations if unexpected events beyond their control, such as natural disasters, hinder their ability to perform."
            comment="Description of the force majeure clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="While force majeure clauses aim to balance risk in unpredictable situations, they should not be overly protective of the supplier. Review them to ensure they cover your business needs, including relevant events, and entitlement to compensation will resume once the disruption ends."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      AgreementTerm: {
        insightTitle: (
          <Message
            future
            id="Agreement terms"
            comment="Title of the agreement term clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="An agreement term clause defines a period or end date for the initial duration of the contract until it is terminated or renewed."
            comment="Description of the agreement term clause."
          />
        ),
        getPlaybookText: ({ agreementTerm }: { agreementTerm: string }) => (
          <Message
            future
            id="The playbook prefers agreement terms with an initial duration of {agreementTerm} months."
            values={{ agreementTerm: <b>{agreementTerm || 0}</b> }}
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      Confidentiality: {
        insightTitle: (
          <Message
            future
            id="Confidentiality"
            comment="Title of the confidentiality clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A confidentiality clause requires parties to safeguard information and prevent its sharing or misuse beyond what has been authorized by the disclosing party."
            comment="Description of the confidentiality clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="When reviewing confidentiality clauses, ensure they clearly define confidential information, who is authorized to access it, and how it must be protected."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
    },
    NDA: {
      DisputeResolution: {
        insightTitle: (
          <Message
            future
            id="Dispute resolution"
            comment="Title of the Dispute resolution chart."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A dispute resolution clause outlines the process for resolving disputes between parties."
            comment="Description of the Dispute resolution chart."
          />
        ),
        getPlaybookText: ({ arbitration }: { arbitration: 'true' | 'false' }) => (
          <>
            <Message
              future
              id="Arbitration is a private dispute resolution process where parties present their case to a neutral third party. It offers advantages like speed and confidentiality but may also involve significant costs."
              comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
            />
            <br />
            {arbitration === 'true' ? (
              <Message
                future
                id="The playbook supports arbitration as the preferred dispute resolution method."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
            ) : (
              <Message
                future
                id="The playbook does not support arbitration as the preferred dispute resolution method."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
            )}
          </>
        ),
      },
      Indemnities: {
        insightTitle: (
          <Message
            future
            id="Indemnities"
            comment="Title of the indemnities chart."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="Confirm the presence of indemnity clauses that specify compensation for losses or damages."
            comment="Description of the price adjustment clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Indemnity clauses protect a party by requiring the other to compensate for losses, damages, or liabilities arising from specific situations, such as breaches or third-party claims. These clauses are vital for managing risk. Ensure the scope, limitations, and claims handling processes are aligned with your business needs."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      LiquidatedDamages: {
        insightTitle: (
          <Message
            future
            id="Liquidated damages"
            comment="Title of the liquidated damages clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A liquidated damages clause sets a predetermined amount one party must pay in case of a contract breach."
            comment="Description of the liquidated damages clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Ensure the amount reflects anticipated losses, protects your interests ensuring fair recovery as a fixed amount may not always align with actual losses."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      GoverningLaw: {
        insightTitle: (
          <Message
            future
            id="Governing law"
            comment="Title of the governing law clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A governing law clause explains which law will govern the contract, which is crucial for dispute interpretation."
            comment="Description of the governing law clause."
          />
        ),
        getPlaybookText: ({ governingLaws }: { governingLaws: string }) => {
          const translatedCountries = getTranslatedCountries({ governingLaws, lang, message });
          return (
            <>
              <Message
                future
                id="Ideally, the Governing law should be a location where the parties have legal representation or a neutral jurisdiction to avoid giving either party an advantage."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
              {translatedCountries && (
                <Message
                  future
                  id="The playbook prefers the laws of {governingLaws}."
                  values={{ governingLaws: <b>{translatedCountries}</b> }}
                  comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
                />
              )}
              <br />
              <Message
                future
                id="'Unspecified' refers to a document with non-specific governing law provisions."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
              <br />
              <Message
                future
                id="'Missing' indicates that no governing law clause was found in the document."
                comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
              />
            </>
          );
        },
      },
      AgreementTerm: {
        insightTitle: (
          <Message
            future
            id="Agreement terms"
            comment="Title of the agreement term clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="An agreement term clause defines a period or end date for the initial duration of the contract until it is terminated or renewed."
            comment="Description of the agreement term clause."
          />
        ),
        getPlaybookText: ({ agreementTerm }: { agreementTerm: string }) => (
          <Message
            future
            id="The playbook prefers agreement terms with an initial duration of {agreementTerm} months."
            values={{ agreementTerm: <b>{agreementTerm || 0}</b> }}
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      Confidentiality: {
        insightTitle: (
          <Message
            future
            id="Confidentiality"
            comment="Title of the confidentiality clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="A confidentiality clause requires parties to safeguard information and prevent its sharing or misuse beyond what has been authorized by the disclosing party."
            comment="Description of the confidentiality clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="When reviewing confidentiality clauses, ensure they clearly define confidential information, who is authorized to access it, and how it must be protected."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
      MultiConfidentiality: {
        insightTitle: (
          <Message
            future
            id="Confidentiality"
            comment="Title of the multi-confidentiality clause."
          />
        ),
        insightTooltip: (
          <Message
            future
            id="These provisions help minimize risk, prevent misuse, and maintain the confidentiality and integrity of shared information and materials."
            comment="Description of the multi-confidentiality clause."
          />
        ),
        getPlaybookText: () => (
          <Message
            future
            id="Confidentiality should clearly define who can access the information, ensure only those who need it have access, and clarify how the data should be handled when no longer needed to maintain protection."
            comment="Text that will be visible under the chart showing the amount of documents that breach the clause."
          />
        ),
      },
    },
  },
});
