// @flow

import {
  KEY_NEOCASE,
} from 'extensions';

const Neocase = {
  key: KEY_NEOCASE,
};

export default Neocase;
