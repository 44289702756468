import { FormBoxPropsProvider } from 'contexts/form-box-props';

import FormBox from 'components/contract-boxes/form-box/form-box';

type Props = {
  agreementId: number;
  isValuesEditable: boolean;
} & ContractView.BoxItemProps;

const FormBoxContainer = ({
  agreementId,
  boxId,
  isEditable,
  isValuesEditable,
  onAddSectionRules,
  onRemoveBox,
  permissions,
}: Props) => (
  <FormBoxPropsProvider
    agreementId={agreementId}
    boxId={boxId}
    isEditable={isEditable}
    isValuesEditable={isValuesEditable}
  >
    <FormBox
      agreementId={agreementId}
      onAddSectionRules={onAddSectionRules}
      onRemoveBox={onRemoveBox}
      permissions={permissions}
    />
  </FormBoxPropsProvider>
);

export default FormBoxContainer;
