// @flow

import * as React from 'react';

import find from 'lodash/find';
import reject from 'lodash/reject';
import { Message } from '@oneflowab/pomes';

import UserGroups from 'components/icons/user-groups';
import Home from 'components/icons/home';
import SelectDropdown from 'components/select-dropdown';
import Tooltip from 'components/tooltip';
import Conditional from 'components/conditional';

import style from './user-dropdown.module.scss';

export type DashboardUser = {
  id: string,
  label: React.Node,
};

export type Props = {
  changeCurrentUser: (user: string) => void,
  currentUser: string,
  users: Array<DashboardUser>,
};

export class UserDropdown extends React.PureComponent<Props, State> {
  getUserIcon = (userId): React.Node => {
    if (userId === 'all') {
      return UserGroups;
    }

    return Home;
  };

  getAvailableUsers = () => {
    const { users, currentUser } = this.props;

    return reject<DashboardUser>(users, { id: currentUser })
      .map<DashboardUser>(
        (user) => ({
          ...user,
          IconComponent: this.getUserIcon(user.id),
        }),
      );
  };

  getUser = (selectedItemKey: any): ?DashboardUser => {
    const { users } = this.props;

    return find(users, { id: selectedItemKey });
  };

  getUserLabel = (): React.Node => {
    const { currentUser } = this.props;
    const user = this.getUser(currentUser);

    if (!user) {
      return '-';
    }

    return user.label;
  };

  renderSelectedItem() {
    const { currentUser } = this.props;
    const Icon = this.getUserIcon(currentUser);
    return (
      <>
        <Icon className={style.UserButtonIcon} />
        {this.getUserLabel()}
      </>
    );
  }

  renderTooltipMessage = () => {
    const { users } = this.props;
    const shouldShowUsersMessage = !(users[0].id && users.length === 1);

    return (
      <>
        <p>
          <Message
            id="{myUser} Documents where I am a participant"
            values={{
              myUser: (
                <strong>
                  <Message
                    id="My user"
                    comment="Used in the user selection dropdown in the dashboard, to show dashboard data of the current user."
                  />
                  :
                </strong>
              ),
            }}
            comment="Tooltip help text explaining what a certain statistics filter does"
          />
        </p>
        <Conditional ifCondition={shouldShowUsersMessage}>
          <p>
            <Message
              id="{allUsers} Documents where me or my colleagues are participants"
              values={{
                allUsers: (
                  <strong>
                    <Message
                      id="All users"
                      comment="Used in the user selection dropdown in the dashboard, to show dashboard data of all users."
                    />
                    :
                  </strong>
                ),
              }}
              comment="Tooltip help text explaining what a certain statistics filter does"
            />
          </p>
        </Conditional>
      </>
    );
  };

  render() {
    const { users, changeCurrentUser } = this.props;

    return (
      <Tooltip
        message={this.renderTooltipMessage()}
        side="left"
        triggerClassName={style.Tooltip}
        sideOffset={1}
      >
        <SelectDropdown
          selectedItem={this.renderSelectedItem()}
          options={this.getAvailableUsers()}
          onChange={changeCurrentUser}
          customDropdownClass={style.UserDropdown}
          customButtonClass={style.UserButton}
          isDisabled={users.length < 2}
          onDropdownToggle={this.handleTooltipDisabled}
        />
      </Tooltip>
    );
  }
}
