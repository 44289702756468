// @flow

import * as React from 'react';
import {
  Message,
  localize,
} from '@oneflowab/pomes';

import FilterBox from 'components/filter-box';
import style from './internal-reminder-filter.module.scss';

type Props = {
  currentWorkspaceId: number,
  getVisibleMonthAndYear: { month: number, year: number },
  queryLifecycleEvents: ({ month: number, year: number }, filter: string) => void,
  title: React.Node,
  updateActiveFilter: (filter: string) => void,
};

export const InternalReminderFilter = ({
  currentWorkspaceId,
  getVisibleMonthAndYear,
  queryLifecycleEvents,
  title,
  updateActiveFilter,
}: Props) => {
  const [filter, setFilter] = React.useState(['all']);

  const handleOptionChange = (changeEvent) => {
    queryLifecycleEvents(getVisibleMonthAndYear, changeEvent.target.value);
    updateActiveFilter(changeEvent.target.value);
    setFilter([changeEvent.target.value]);
  };

  return (
    <FilterBox
      title={title}
      workspaceId={currentWorkspaceId}
    >
      <div className={style.Filters}>
        <label htmlFor="CR-filter-all">
          <input
            type="radio"
            value="all"
            name="CR-filter"
            id="CR-filter-all"
            checked={filter[0] === 'all'}
            onChange={handleOptionChange}
            className={style.Radiobutton}
          />
          <Message
            id="All reminders"
            comment="Radio button label for custom reminder filter. Showing all reminders"
          />
        </label>
        <label htmlFor="CR-filter-me">
          <input
            type="radio"
            value="me"
            name="CR-filter"
            id="CR-filter-me"
            checked={filter[0] === 'me'}
            onChange={handleOptionChange}
            className={style.Radiobutton}
          />
          <Message
            id="Created by me"
            comment="Radio button label for custom reminder filter. Showing reminders created by myself"
          />
        </label>
        <label htmlFor="CR-filter-others">
          <input
            type="radio"
            value="others"
            name="CR-filter"
            id="CR-filter-others"
            checked={filter[0] === 'others'}
            onChange={handleOptionChange}
            className={style.Radiobutton}
          />
          <Message
            id="Created by others"
            comment="Radio button label for custom reminder filter. Showing reminders created by others"
          />
        </label>
      </div>
    </FilterBox>

  );
};
export default localize<Props>(InternalReminderFilter);
