import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import type { ReactNode } from 'react';

import useMenuStateContext from './use-menu-state';
import { rootMenuStateContext } from './root';
import type { MenuItem, OnKeyDown } from './types';

type MenuContextValue = {
  registerItem: (value: MenuItem) => void;
  unregisterItem: (value: Omit<MenuItem, 'subMenuId'>) => void;
  handleKeyDown: OnKeyDown;
  setOnKeyDown: (onKeyDown: OnKeyDown | null) => void;
};

export const MenuContext = createContext<MenuContextValue | null>(null);

export const useMenuContext = () => {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenuContext must be used within a MenuProvider');
  }

  return context;
};

const Provider = ({ children }: { children: ReactNode }) => {
  const subMenuKeyDown = useRef<OnKeyDown | null>(null);

  const {
    handleKeyDown,
    registerItem,
    unregisterItem,
  } = useMenuStateContext(rootMenuStateContext);

  const setOnKeyDown = useCallback((onKeyDown: OnKeyDown | null) => {
    subMenuKeyDown.current = onKeyDown;
  }, []);

  const _handleKeyDown: OnKeyDown = useCallback((event) => {
    if (subMenuKeyDown.current) {
      subMenuKeyDown.current(event);
    }
    handleKeyDown(event);
  }, [handleKeyDown]);

  const contextValue = useMemo(() => ({
    registerItem,
    unregisterItem,
    handleKeyDown: _handleKeyDown,
    setOnKeyDown,
  }), [_handleKeyDown, registerItem, setOnKeyDown, unregisterItem]);

  return (
    <MenuContext.Provider value={contextValue}>
      {children}
    </MenuContext.Provider>
  );
};

export default Provider;
