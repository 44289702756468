import React, { useCallback } from 'react';
import clsx from 'clsx';
import type { ReactNode } from 'react';

import Tooltip from 'components/tooltip';
import NewCross from 'components/icons/new-cross';

import style from './filter-values.module.scss';

type BaseOption = {
  value: number,
  label: ReactNode,
  isFixed: boolean,
  icon?: ReactNode,
};

export type Option = BaseOption & {
  value: number,
  label: ReactNode,
  isFixed: boolean,
  customValueStyle?: string,
  tooltipMessage?: ReactNode,
  icon?: ReactNode,
};

type Props = {
  onChange: (value: number[] | null | undefined) => void,
  selectedValues: Option[],
};

const FilterValues = ({ onChange, selectedValues }: Props) => {
  const getKey = useCallback((value: Option['value'], index: number) => `${value}-${index}`, []);
  const removeValue = useCallback(
    (option: Option) => {
      onChange(selectedValues?.filter((val) => val.value !== option.value).map((val) => val.value));
    }, [onChange, selectedValues],
  );
  const toFilterValue = (opt: Option, index: number) => (
    <div
      className={clsx(style.SelectedValue, {
        [opt.customValueStyle as string]: !!opt.customValueStyle,
      })}
      value={opt.value}
      key={getKey(opt.value, index)}
    >
      <>
        {opt.tooltipMessage ? (
          <Tooltip message={opt?.tooltipMessage} side="top" sideOffset={6}>
            <div className={style.SelectedValueLabel}>
              {opt?.icon}
              {opt.label}
            </div>
          </Tooltip>
        ) : (
          <div className={style.SelectedValueLabel}>
            {opt?.icon}
            {opt.label}
          </div>
        )}
      </>
      <button
        disabled={opt.isFixed}
        className={clsx(style.RemoveValue, { [style.IsFixed]: opt.isFixed })}
        onClick={() => removeValue(opt)}
        onKeyDown={() => removeValue(opt)}
        onTouchEnd={() => removeValue(opt)}
      >
        <NewCross height="8px" />
      </button>
    </div>
  );

  return (
    <div className={clsx({ [style.SelectedValuesContainer]: !!selectedValues.length })}>
      {selectedValues.map(toFilterValue)}
    </div>
  );
};

export default FilterValues;
