// @flow

import {
  get, isNumber, isEmpty, pull,
} from 'lodash';
import moment from 'moment';
import {
  getDecliningParticipant,
  getSignedParticipants,
  getAgreementMyParticipant,
  isMyParticipantTurnToSign,
  usesSameDeviceSome,
  getAgreementSignatories,
  getMyParticipantWhenSignatory,
  canBeSigned,
  isAgreementOwner,
  getParticipants,
  getParticipantParty,
} from 'agreement/selectors';

import { checkAcl } from 'components/acl';

import { isPaidAccount } from 'account';
import { getTimePeriodObj } from 'agreement/date-helpers';
import { getDraftApprovers } from 'agreement/draft-approval-flow';
import * as participantConstants from 'agreement/participant/constants';
import * as partyConstants from 'agreement/party/constants';
import { MAX_ATTACHMENTS_COUNT_PER_CONTRACT_DEFAULT } from 'components/contract-boxes/constants';
import { isFormerColleague } from 'agreement/participant';
import isSignatory from 'agreement/participant/is-signatory';
import isUndecided from 'agreement/participant/is-undecided';
import isOrganizer from 'agreement/participant/is-organizer';
import isInternalApprover from 'agreement/participant/is-internal-approver';
import hasDeliveryChannelSameDevice from 'agreement/participant/has-delivery-channel-same-device';
import { IS_INDIVIDUAL } from 'agreement/party/constants';
import * as agreementConstants from './constants';
import {
  isSigned,
  isAnySignedState,
  isPending,
  isOverdue,
  isPublished,
  isConcluded,
  isSigningInProgress,
  isDraft,
  isTemplate,
} from './states';

export type AgreementHelper = (agreement: Agreement) => boolean;

/* import */
export const isImportInProgress: AgreementHelper = (agreement) => (
  agreement.import === agreementConstants.IMPORT_IN_PROGRESS
);
export const isImportFinished: AgreementHelper = (agreement) => (
  agreement.import === agreementConstants.IMPORT_FINISHED
);
export const isImport: AgreementHelper = (agreement) => (
  isImportInProgress(agreement) || isImportFinished(agreement)
);

/* lifecycle */
export const isActive: AgreementHelper = (agreement) => (
  agreement.lifecycle === agreementConstants.LIFECYCLE_ACTIVE
);
export const isEnded: AgreementHelper = (agreement) => (
  agreement.lifecycle === agreementConstants.LIFECYCLE_ENDED
);
export const isAwaiting: AgreementHelper = (agreement) => (
  agreement.lifecycle === agreementConstants.LIFECYCLE_AWAITING
);
export const isWithoutLifecycle: AgreementHelper = (agreement) => (
  agreement.lifecycle === agreementConstants.LIFECYCLE_NONE
);
export const isSignedAndAwaiting: AgreementHelper = (agreement) => (
  isAnySignedState(agreement) && isAwaiting(agreement)
);
export const isSignedAndEnded: AgreementHelper = (agreement) => (
  isAnySignedState(agreement) && isEnded(agreement)
);
export const isSignedAndActive: AgreementHelper = (agreement) => (
  isAnySignedState(agreement) && isActive(agreement)
);
export const isSignedWithoutLifecycle: AgreementHelper = (agreement) => (
  isAnySignedState(agreement) && isWithoutLifecycle(agreement)
);

/* type */
export const hasNoDuration: AgreementHelper = (agreement) => (
  agreement.type === agreementConstants.TYPE_NO_DURATION
);
export const hasSinglePeriod: AgreementHelper = (agreement) => (
  agreement.type === agreementConstants.TYPE_SINGLE_PERIOD
);
export const isRecurring: AgreementHelper = (agreement) => (
  agreement.type === agreementConstants.TYPE_RECURRING
);
export const isRecurringWithInitial: AgreementHelper = (agreement) => (
  agreement.type === agreementConstants.TYPE_RECURRING_WITH_INITIAL
);
export const isAnyRecurringType: AgreementHelper = (agreement) => (
  isRecurring(agreement) || isRecurringWithInitial(agreement)
);

/* sign type */
export const isMarkedAsSigned: AgreementHelper = (agreement) => (
  isAnySignedState(agreement) && agreement.signPlatform === agreementConstants.SIGN_PLATFORM_MANUAL
);
export const isMarkedAsDeclined: AgreementHelper = (agreement) => {
  const decliningParticipant = getDecliningParticipant(agreement);
  const decliningParticipantAccountId = isNumber(get(decliningParticipant, 'account'))
    ? get(decliningParticipant, 'account')
    : get(decliningParticipant, 'account.id');
  return decliningParticipantAccountId === agreement.account?.id;
};

/* timestamps */
export const hasCanceledTimestamp: AgreementHelper = (agreement) => (
  !!agreement.cancelTimestampTs
);

export const hasCanceledTime: AgreementHelper = (agreement) => (
  !!agreement.cancelTime
);

/* insights */
export const isSignedAndEndedAndTerminated: AgreementHelper = (agreement) => (
  isSignedAndEnded(agreement) && !!(agreement.terminateTimestampTs || agreement.terminateTime)
);
export const isSignedAndEndedWithNoDurationType: AgreementHelper = (agreement) => (
  isSignedAndEnded(agreement) && hasNoDuration(agreement)
);
export const isSignedAndActiveWithRecurringType: AgreementHelper = (agreement) => (
  isSignedAndActive(agreement) && isAnyRecurringType(agreement)
);
export const isSignedWithRecurringTypeAndCanceled: AgreementHelper = (agreement) => (
  isAnySignedState(agreement) && isAnyRecurringType(agreement)
  && (hasCanceledTimestamp(agreement) || hasCanceledTime(agreement))
);

/* attachments */
export const hasAttachmentSignatures: AgreementHelper = (agreement) => {
  const { config, attachmentSignatures } = agreement;

  return Boolean(config?.attachmentSignatures && attachmentSignatures?.length);
};

/* expire date */
export const hasExpireDatePassed: AgreementHelper = (agreement) => (
  moment().isAfter(moment(agreement.expireDate).add(1, 'days'))
);

export const hasSignOrder: AgreementHelper = (agreement) => agreement.config?.signOrder;

/* NoticePeriod */
export const hasPassedNoticePeriod: AgreementHelper = (agreement) => {
  const noticePeriodObj = getTimePeriodObj(agreement.noticePeriod, true);
  const noticePeriodStart = moment(agreement.periodEndTimestamp).subtract(
    noticePeriodObj.duration,
  );

  return moment().isAfter(noticePeriodStart);
};

export const inNoticePeriod: AgreementHelper = (agreement) => (
  isSigned(agreement)
  && (agreement.type === agreementConstants.TYPE_RECURRING
    || agreement.type !== agreementConstants.TYPE_RECURRING_WITH_INITIAL)
  && !hasPassedNoticePeriod(agreement)
);

/* isCancelContractVisible */
export const isCancelContractVisible: AgreementHelper = (agreement) => checkAcl(agreement.acl, 'agreement:cancel');

export const isSignLaterEnabled: AgreementHelper = (agreement) => (
  agreement.config?.signLater || false
);

export const isSignOrderEnabled: AgreementHelper = (agreement) => (
  agreement.config?.signOrder || false
);

export const hasPermissionToAddAnyParticipants: AgreementHelper = (agreement) => {
  const hasAddAnotherPartyPermission = checkAcl(agreement.acl, 'agreement:participant:other_party:create');
  const hasAddOwnPartyPermission = checkAcl(agreement.acl, 'agreement:participant:own_party:create');
  const hasAddColleaguePermission = checkAcl(agreement.acl, 'agreement:participant:colleague:create');

  const result = hasAddAnotherPartyPermission
    || hasAddOwnPartyPermission
    || hasAddColleaguePermission;

  return result;
};

export const getDefaultPartyType: AgreementHelper = (agreement) => (
  agreement?.config?.defaultPartyType === 1 ? partyConstants.INDIVIDUAL : partyConstants.COMPANY
);

export const getDefaultDeliveryChannel: AgreementHelper = (agreement) => (
  agreement?.config?.defaultDeliveryChannel || participantConstants.DELIVERY_CHANNEL_EMAIL
);

export const getDefaultTwoStepAuthenticationMethod: AgreementHelper = (agreement) => (
  agreement?.config?.defaultMfaChannel || participantConstants.MFA_CHANNEL_NONE
);

export const getDefaultParticipantRole: AgreementHelper = () => (
  participantConstants.TYPE_IS_SIGNATORY
);

export const getOwnerParticipants = (agreement) => {
  const ownerAccountId = agreement.account?.id;

  const { parties } = agreement;

  const participants = [];
  parties.forEach((party) => participants.push(party.participants));

  return participants.flat().filter((participant) => (
    participant.state !== participantConstants.STATE_IS_DISABLED
    && participant.account
    && participant.account.id === ownerAccountId
  ));
};

export const getOwnerPartyId = (agreement) => {
  const [ownerParticipant] = getOwnerParticipants(agreement);
  return ownerParticipant && ownerParticipant.agreementCompany?.id;
};

export const allowOrganizer = (agreement, party, participant) => {
  const ownerPartyId = getOwnerPartyId(agreement);

  let isOwner = false;

  if (ownerPartyId && party) {
    isOwner = ownerPartyId === party.id;
  }

  if (participant) {
    if (isImportInProgress(agreement) && isFormerColleague(participant)) {
      return false;
    }

    if (isImportInProgress(agreement) && isOwner && isOrganizer(participant)) {
      return true;
    }
  }

  return (
    isOwner
    // if participant is already an organizer, we allow it but disable the dropdown
    && (!isPublished(agreement) || isOrganizer(participant))
  );
};

export const allowViewer = (agreement, party, participant) => {
  if (isConcluded(agreement)) {
    return false;
  }

  const ownerPartyId = getOwnerPartyId(agreement);

  let isOwner = false;

  if (ownerPartyId && party) {
    isOwner = ownerPartyId === party.id;
  }

  if (isOwner && participant?.self === 1) {
    return false;
  }

  /*
  // Will be relevant when/if we use this for the add colleague modal,
  // since the owner side must have at least one non-viewer participant
  const ownerSideParticipants = getOwnerParticipants(agreement);
  const ownerSideNonViewers = ownerSideParticipants && ownerSideParticipants.filter(
    (ownerSideParticipant) => ownerSideParticipant.type !== participantConstants.TYPE_IS_VIEWER
  );
  */

  return true;
};

export const getHiddenRoles = (agreement, party, participant) => {
  const hiddenRoles = [];

  const hasApprovePermission = checkAcl(participant?.acl, 'participant:role:set:draft_approver');

  if (!hasApprovePermission) {
    hiddenRoles.push(participantConstants.TYPE_IS_INTERNAL_APPROVER);
  }

  if (isConcluded(agreement)) {
    hiddenRoles.push(participantConstants.TYPE_IS_SIGNATORY);
    hiddenRoles.push(participantConstants.TYPE_IS_INFLUENCER);
  }

  if (!allowOrganizer(agreement, party, participant)) {
    hiddenRoles.push(participantConstants.TYPE_IS_ORGANIZER);
  }

  if (!allowViewer(agreement, party, participant)) {
    hiddenRoles.push(participantConstants.TYPE_IS_VIEWER);
  }

  return hiddenRoles;
};

export const isNotMyTurnToSign = (agreement) => {
  const myParticipant = getAgreementMyParticipant(agreement);
  const notMyTurnToSign = (
    isSignatory(myParticipant)
    && !isMyParticipantTurnToSign(agreement, myParticipant)
    && isUndecided(myParticipant)
  );
  return isPublished(agreement) && notMyTurnToSign;
};

export const isWaitingForSignatures = (agreement) => {
  const signatures = getSignedParticipants(agreement);
  return (
    isPending(agreement)
    && (signatures.length > 0 || isNotMyTurnToSign(agreement))
  );
};

export const hasAnySignatory = (agreement) => (
  Boolean(getAgreementSignatories(agreement).length)
);

export const canBePublishedByMyParticipant = (
  agreement: Agreement,
  myParticipant: AgreementParticipant,
  participants: Array<AgreementParticipant>,
  isFreemiumWithNonPdfSections: boolean,
  hasPermissionToPublish: boolean,
) => {
  const canPublish = (
    myParticipant
    && !hasExpireDatePassed(agreement)
    && agreement.welcomeVideo !== agreementConstants.AGREEMENT_VIDEO_PROCESSING
    && !isFreemiumWithNonPdfSections
    && hasPermissionToPublish
  );

  return canPublish;
};

export const canBeSignedByMyParticipant = (
  agreement: Agreement,
  myParticipant: ?AgreementParticipant,
) => {
  const agreementCanBeSigned = agreement.parties && canBeSigned(agreement);

  return agreementCanBeSigned
    && myParticipant
    && isUndecided(myParticipant)
    && !isSignLaterEnabled(agreement);
};

export const canBeMarkedAsSignedByMyParticipant = (agreement: Agreement) => {
  const agreementCanBeSigned = agreement.parties && canBeSigned(agreement);

  return agreementCanBeSigned
    && !isSigningInProgress(agreement)
    && !isConcluded(agreement);
};

export const isSignContractActionVisible = (agreement: Agreement, accountFromSession) => {
  if (isEmpty(agreement)) {
    return false;
  }
  const myParticipantWhenSignatory = getMyParticipantWhenSignatory(agreement);

  return (
    (
      (isPending(agreement) || isOverdue(agreement))
      && canBeSignedByMyParticipant(agreement, myParticipantWhenSignatory)
    )
    || usesSameDeviceSome(agreement, accountFromSession)
  );
};

export const isMarkAsSignedActionVisible = (
  agreement: Agreement,
  accountFromSession: Account,
) => {
  if (isEmpty(agreement)) {
    return false;
  }

  return isImportInProgress(agreement)
    && isDraft(agreement)
    && isAgreementOwner(accountFromSession, agreement);
};

export const isSmsDeliveryChannelEnabled: AgreementHelper = (agreement) => {
  const availableDeliveryChannels = agreement.availableOptions?.deliveryChannels;
  return (
    availableDeliveryChannels
    && availableDeliveryChannels.includes(participantConstants.DELIVERY_CHANNEL_SMS)
  );
};

export const isEmailAndSmsDeliveryChannelEnabled: AgreementHelper = (agreement) => {
  const availableDeliveryChannels = agreement.availableOptions?.deliveryChannels;
  return (
    availableDeliveryChannels
    && availableDeliveryChannels.includes(participantConstants.DELIVERY_CHANNEL_EMAIL_AND_SMS)
  );
};

export const isSameDeviceDeliveryChannelEnabled: AgreementHelper = (agreement) => {
  const availableDeliveryChannels = agreement.availableOptions?.deliveryChannels;
  return (
    availableDeliveryChannels
    && availableDeliveryChannels.includes(participantConstants.DELIVERY_CHANNEL_SAME_DEVICE)
  );
};

export const getAvailableDeliveryChannels = (
  agreement: Agreement,
  participant: Participant,
  account: Account,
) => {
  let deliveryChannels = [];

  deliveryChannels.push(participantConstants.DELIVERY_CHANNEL_EMAIL);
  if (isSmsDeliveryChannelEnabled(agreement) && isPaidAccount(account)) {
    deliveryChannels.push(participantConstants.DELIVERY_CHANNEL_SMS);
  }
  if (isEmailAndSmsDeliveryChannelEnabled(agreement)) {
    deliveryChannels.push(participantConstants.DELIVERY_CHANNEL_EMAIL_AND_SMS);
  }
  if (isSameDeviceDeliveryChannelEnabled(agreement) && !hasSignOrder(agreement)) {
    deliveryChannels.push(participantConstants.DELIVERY_CHANNEL_SAME_DEVICE);
  }

  if (participant) {
    const currentDeliveryChannel = participant.deliveryChannel;
    if (!deliveryChannels.includes(currentDeliveryChannel)) {
      deliveryChannels.push(currentDeliveryChannel);
    }
  }

  let filteredDeliveryChannels = [];
  const { enabledDeliveryChannels } = agreement.config;
  if (enabledDeliveryChannels) {
    filteredDeliveryChannels = deliveryChannels.filter(
      (deliveryChannel) => enabledDeliveryChannels.includes(deliveryChannel),
    );
  }

  if (filteredDeliveryChannels.length > 0) {
    deliveryChannels = [...filteredDeliveryChannels];
  }

  return deliveryChannels;
};

export const isMfaSmsChannelEnabled: AgreementHelper = (agreement) => {
  const availableMfaChannels = agreement.availableOptions?.mfaChannels;
  return (
    availableMfaChannels && availableMfaChannels.includes(participantConstants.MFA_CHANNEL_SMS)
  );
};

export const getAvailableTwoStepAuthenticationChannels = (
  agreement,
  currentMfaChannel,
  isPersonalIdEnabled,
  account,
) => {
  let mfaChannels = [
    participantConstants.MFA_CHANNEL_NONE,
    participantConstants.MFA_CHANNEL_EMAIL,
  ];

  if (isPersonalIdEnabled) {
    mfaChannels.push(participantConstants.MFA_CHANNEL_PERSONAL_IDENTIFICATION);
  }

  if (!agreement.config) {
    return mfaChannels;
  }

  if (
    !agreement.config.enabledMfaChannels
    && isMfaSmsChannelEnabled(agreement)
    && isPaidAccount(account)
  ) {
    mfaChannels.push(participantConstants.MFA_CHANNEL_SMS);
  }

  if (currentMfaChannel !== undefined && !mfaChannels.includes(currentMfaChannel)) {
    mfaChannels.push(currentMfaChannel);
  }

  const { enabledMfaChannels } = agreement.config;
  if (enabledMfaChannels) {
    const filteredConfigMfaChannels = [];

    enabledMfaChannels.forEach((mfaChannel) => {
      if (mfaChannel === participantConstants.MFA_CHANNEL_SMS) {
        if (isMfaSmsChannelEnabled(agreement)) {
          filteredConfigMfaChannels.push(mfaChannel);
        }
      } else {
        filteredConfigMfaChannels.push(mfaChannel);
      }
    });
    mfaChannels = [...filteredConfigMfaChannels];
  }

  return mfaChannels;
};

export const getAvailableSignMethods = (
  agreement,
  currentDeliveryChannel,
  includeNonEnabledMethods = false,
  account,
) => {
  let signMethods = [];
  if (!agreement.config) {
    return signMethods;
  }

  const availableSignMethods = agreement.availableOptions?.signMethods;

  availableSignMethods.forEach((method) => {
    if (method === participantConstants.SIGN_METHOD_ESIGN) {
      if (currentDeliveryChannel !== participantConstants.DELIVERY_CHANNEL_SAME_DEVICE) {
        signMethods.push(method);
      }
    } else {
      signMethods.push(method);
    }
  });

  if (!isPaidAccount(account)) {
    pull(signMethods, participantConstants.SIGN_METHOD_SMS);
  }

  if (includeNonEnabledMethods) {
    return signMethods;
  }

  let filteredSignMethods = [];
  const enabledSignMethods = agreement.config?.enabledSignMethods;
  if (enabledSignMethods) {
    filteredSignMethods = signMethods.filter((signMethod) => enabledSignMethods
      .includes(signMethod));
    signMethods = [...filteredSignMethods];
  }

  return signMethods;
};

export const getFirstAvailableSignMethod = (
  agreement,
  preferred,
  currentDeliveryChannel,
  isColleague,
  account,
) => {
  const available = getAvailableSignMethods(
    agreement,
    currentDeliveryChannel,
    isColleague,
    account,
  );

  if (available.indexOf(preferred) > -1) {
    return preferred;
  }

  if (available.length) {
    return available[0];
  }
  return null;
};

export const getDefaultSignMethod = (agreement, currentDeliveryChannel, account) => (
  getFirstAvailableSignMethod(
    agreement,
    agreement?.config?.defaultSignMethod,
    currentDeliveryChannel,
    null,
    account,
  )
);

export const getAttachmentsCountLimit = (agreement: Agreement) => (
  agreement?.availableOptions?.attachmentLimit || MAX_ATTACHMENTS_COUNT_PER_CONTRACT_DEFAULT
);

export const getParticipantInternalApprovers = (agreement: Agreement) => {
  const participants = getParticipants(agreement);
  const approvers = participants.filter((participant) => isInternalApprover(participant));
  return approvers;
};

export const hasDraftApprovers = (agreement: Agreement) => {
  const approvers = getDraftApprovers(agreement);

  if (!approvers) {
    return false;
  }

  return Boolean(approvers.length);
};

export const isTemplatePublished = (agreement: Agreement) => (
  isTemplate(agreement) && Boolean(agreement.config?.templateActive)
);

export const isDelegateVisible = (agreement) => {
  if (isEmpty(agreement) || agreement.config?.signOrder
    || hasExpireDatePassed(agreement)) {
    return false;
  }

  const myParticipant = getAgreementMyParticipant(agreement);

  if (!myParticipant || hasDeliveryChannelSameDevice(myParticipant)) {
    return false;
  }

  const party = getParticipantParty(agreement, myParticipant.agreementCompany.id);

  return party && party.individual !== IS_INDIVIDUAL;
};
