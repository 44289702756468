// @flow
import * as React from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';

import * as agreementConstants from 'agreement/constants';

const options = (message) => ([
  {
    value: agreementConstants.LIFECYCLE_AWAITING,
    label: message({
      id: 'Upcoming',
      comment: 'Search filter label. Used when searching contracts, as in "find all contract that are in the lifecycle step of upcoming"',
    }),
  },
  {
    value: agreementConstants.LIFECYCLE_ACTIVE,
    label: message({
      id: 'Active',
      comment: 'Search filter label. Used when searching contracts, as in "find all contract that are in the lifecycle step of active"',
    }),
  },
  {
    value: agreementConstants.LIFECYCLE_ENDED,
    label: message({
      id: 'Ended',
      comment: 'Search filter label. Used when searching contracts, as in "find all contract that are in the lifecycle step of ended"',
    }),
  },
  {
    value: agreementConstants.LIFECYCLE_NOT_SET,
    label: message({
      id: 'Not set',
      comment: 'Search filter label. Used when searching contracts, as in "find all contract that have no lifecycle"',
    }),
  },
]);

type FilterOption = {
  value: number,
  label: React.Node,
};

type GetOptions = (message: MessageTranslator, filterables: Array<number>) => Array<FilterOption>;

const lifecycleFilterOptions: GetOptions = (message, filterables) => options(message)
  .filter((option) => filterables.includes(option.value));

export default lifecycleFilterOptions;
