import { FormRenderProps } from 'react-final-form';

import type { Billing } from 'types/billing';

import ModalForm from 'hocs/modal-form';
import Message from 'components/message';
import TextField from 'components/text-field';
import Field from 'components/field';
import Button from 'components/button';

import useEditInvoiceDetails from './use-edit-invoice-details';

type Props = {
  billing: Billing,
  accountId: number,
  isOpen?: boolean,
  closeModal: () => void,
}
const EditInvoiceDetails = ({
  billing,
  isOpen,
  accountId,
  closeModal,
}:Props) => {
  const {
    formState,
    resetFormState,
    onSubmit,
  } = useEditInvoiceDetails(accountId, billing);

  const renderModalBody = ({
    invoiceDeliveryChannel,
    invoiceEmail,
    invoiceReference,
  }:
  Billing) => (
    <>
      <Field
        name="invoiceDeliveryChannel"
        label={(
          <Message
            id="Delivered via"
            comment="Label for delivery channel in the edit invoice details modal"
          />
)}
        component={TextField}
        disabled
        initialValue={invoiceDeliveryChannel}
      />
      <Field
        name="invoiceEmail"
        label={(
          <Message
            id="Email"
            comment="Label for email input in the edit invoice details modal"
          />
)}
        component={TextField}
        placeholder={(
          <Message
            id="Email"
            comment="Placeholder for email input in the edit invoice details modal"
          />
)}
        required
        maxLength={70}
        initialValue={invoiceEmail}
        email
      />
      <Field
        name="invoiceReference"
        label={(
          <Message
            id="Invoice reference"
            comment="Label for reference input in the edit invoice details modal"
          />
)}
        component={TextField}
        placeholder={(
          <Message
            id="Enter reference"
            comment="Placeholder for reference input in the edit invoice details modal"
          />
)}
        initialValue={invoiceReference}
        required
        maxLength={100}
      />
    </>
  );

  const renderActions = ({ formProps }: { formProps: FormRenderProps }) => {
    const disabled = Boolean(
      formProps.pristine
          || formProps.validating
          || formProps.hasValidationErrors
          || formState.loading,
    );

    return (
      <>
        <Button
          onClick={closeModal}
        >
          <Message
            id="Cancel"
            comment="Button text"
          />
        </Button>
        <Button
          onClick={formProps.handleSubmit}
          disabled={disabled}
          kind="primary"
        >
          <Message
            id="Save"
            comment="Button text"
          />
        </Button>
      </>
    );
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Edit invoice details"
          comment="Header for the edit invoice details modal"
        />
      )}
      isOpen={isOpen}
      onClose={closeModal}
      modalKey="edit-invoice-details"
      body={renderModalBody(billing)}
      resetFormState={resetFormState}
      formState={formState}
      onSubmit={onSubmit}
      actions={renderActions}
    />
  );
};

export default EditInvoiceDetails;
