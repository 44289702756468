import { Message } from '@oneflowab/pomes';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import style from './missing-features-details.module.scss';

export type Props = {
  onChange: (value: string) => void;
}

const MissingFeaturesModal = ({
  onChange,
}: Props) => (
  <div className={style.MissingFeaturesContainer}>
    <p>
      <Message
        id="Could you go into more detail on what you're missing from Oneflow?"
        comment="Header for missing features section"
      />
    </p>
    <TextareaAutosize
      minRows={4}
      className={style.TextArea}
      onChange={(event) => onChange(event.target.value)}
    />
  </div>
);

export default MissingFeaturesModal;
