// @flow

import * as React from 'react';
import clsx from 'clsx';

import { getParticipantSignOrderBlockIndex } from 'agreement/pending-state-flow';

import hasSigned from 'agreement/participant/has-signed';
import hasDeclined from 'agreement/participant/has-declined';

import isInternalApprover from 'agreement/participant/is-internal-approver';
import isSignatory from 'agreement/participant/is-signatory';
import { isImport } from 'agreement';
import { isSignOrderEnabled } from 'agreement/selectors';
import { isMarkedAsSigned } from 'agreement/agreement';
import SingleUserActionsBlock from 'components/icons/single-user-actions-block';
import NewCheck from 'components/icons/new-check';
import NewCross from 'components/icons/new-cross';
import FailedDelivery from 'components/icons/failed-delivery';

import style from './participant-state.module.scss';

export type Props = {|
  participant: Participant;
  renderEmpty: boolean;
  agreement: Agreement;
  isSignOrderEditable?: boolean;
  hasNoAccess?: boolean;
  hasDeliveryError?: boolean;
  approveOrder?: number;
|};

export class ParticipantState extends React.Component<Props> {
  renderIcon() {
    const {
      agreement,
      participant,
      renderEmpty,
      isSignOrderEditable,
      hasNoAccess,
      hasDeliveryError,
      approveOrder,
    } = this.props;

    const iconClasses = clsx(style.HasNoAccess, {
      [style.IsImport]: isImport(agreement),
    });

    if (hasDeliveryError) {
      return <FailedDelivery height="18px" width="18px" />;
    }

    if (hasNoAccess) {
      return <SingleUserActionsBlock className={iconClasses} />;
    }

    if (approveOrder && !hasDeclined(participant)) {
      return approveOrder;
    }

    if (approveOrder && hasDeclined(participant)) {
      return <NewCross />;
    }

    const order = getParticipantSignOrderBlockIndex(agreement, participant.id);
    if (!isSignatory(participant) && (!isSignOrderEnabled(agreement) || order === 0)) {
      return null;
    }

    if (renderEmpty || (isSignOrderEnabled(agreement) && isSignOrderEditable)) {
      return null;
    }

    if (
      isSignOrderEnabled(agreement)
      && !isSignOrderEditable
      && !hasSigned(participant)
      && !hasDeclined(participant)
    ) {
      return order;
    }

    if (hasDeclined(participant)) {
      return <NewCross />;
    }

    if (hasSigned(participant) || isMarkedAsSigned(agreement)) {
      return <NewCheck />;
    }

    return null;
  }

  render() {
    const {
      agreement,
      participant,
      renderEmpty,
      isSignOrderEditable,
      approveOrder,
    } = this.props;
    const showSignOrder = isSignOrderEnabled(agreement) && !isSignOrderEditable
      && !isInternalApprover(participant);
    const showApproveOrder = approveOrder || false;
    const showBadge = hasSigned(participant)
      || hasDeclined(participant)
      || isMarkedAsSigned(agreement)
      || showSignOrder
      || showApproveOrder;
    const stateClasses = clsx(style.ParticipantState, {
      [style.Signed]: (hasSigned(participant) || isMarkedAsSigned(agreement))
        && !renderEmpty && isSignatory(participant),
      [style.Declined]: hasDeclined(participant) && !renderEmpty,
      [style.WithBadge]: showBadge && !renderEmpty,
      [style.SignOrder]: showSignOrder,
      [style.ApproveOrder]: showApproveOrder && !hasDeclined(participant),
    });

    return (
      <div className={stateClasses}>
        {this.renderIcon()}
      </div>
    );
  }
}
