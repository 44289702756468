/* eslint-disable react/prop-types */
import React, { useState, useRef, useCallback } from 'react';
import Message from 'components/message';
import clsx from 'clsx';

import { useDispatch } from 'react-redux';

import log from 'logging';

import { isSignLaterEnabled } from 'agreement';
import useAgreement from 'hooks/use-agreement';
import agreements from 'reducers/entities/agreements';

import {
  getMoment,
  formatDateString,
  hasDatePassed,
} from 'date';

import useSetDOMAttributes from 'hooks/use-set-dom-attributes';
import useDocumentViewLayoutInfo from 'hooks/use-document-view-layout-info';
import usePopupDialog from 'hooks/popup/use-popup-dialog';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';

import Button from 'components/button';
import AddIcon from 'components/icons/new-add';
import { CancelButton } from 'components/buttons/cancel';

import ExpiryDatePicker from './expiry-date-picker';
import style from './published-document-expiry-date-popover.module.scss';

function PublishedDocumentExpiryDatePopover(props) {
  const {
    agreementId,
    status,
    expireDate,
    dateFormat,
    readOnly,
    failureToast,
    setFailureReason,
  } = props;

  const agreement = useAgreement(agreementId);
  const overdue = status === 'overdue';
  const hasExpireDatePassed = overdue || hasDatePassed(expireDate);

  const [expireDateValue, setExpireDateValue] = useState(
    () => getMoment(expireDate),
  );

  const signingPeriodHeader = (
    <h3 className={style.SigningPeriodHeader} id="signing-period">
      <Message id="Signing period" comment="header text for signing period info" />
    </h3>
  );

  let dateElement = (
    <ExpiryDatePopover
      display="inline"
      agreementId={agreementId}
      expireDate={expireDate}
      expireDateValue={expireDateValue}
      setExpireDateValue={setExpireDateValue}
      dateFormat={dateFormat}
      failureToast={failureToast}
      setFailureReason={setFailureReason}
      hasExpireDatePassed={hasExpireDatePassed}
    />
  );

  const getDocumentPeriodMessage = () => {
    if (isSignLaterEnabled(agreement)) {
      return (
        <Message
          id="The document will be expired {date}"
          values={{
            date: dateElement,
          }}
          comment="Shows at what date the document will be expired"
        />
      );
    }

    return (
      <Message
        id="The document can be signed until {date}"
        values={{
          date: dateElement,
        }}
        comment="Shows the number of days signing is possible"
      />
    );
  };

  if (readOnly && expireDate) {
    dateElement = (
      <span
        className={clsx(style.Bold, {
          [style.DatePassed]: hasExpireDatePassed,
        })}
      >
        {formatDateString(expireDate, dateFormat)}
      </span>
    );
  }

  if (readOnly && !expireDate) {
    dateElement = null;
  }

  if (expireDate) {
    return (
      <div
        className={style.ExpiryInfo}
        data-testid="read-only-expiry-info"
      >
        {signingPeriodHeader}
        {getDocumentPeriodMessage()}
      </div>
    );
  }

  return (
    <div className={style.NoExpiryInfoContainer} data-testid="read-only-expiry-info">
      {signingPeriodHeader}
      <div>
        <Message
          id="The document can be signed at any time"
          comment="Text explains that the signing period doesn't expire"
        />
      </div>
      {dateElement}
    </div>
  );
}

const buttonDatasets = {
  testid: 'publishedExpiryDateSaveButton',
};

const popoverContentProps = {
  side: 'left',
  sideOffset: 4,
  arrowHeight: 0,
};

export function ExpiryDatePopover(props) {
  const {
    agreementId,
    hasExpireDatePassed,
    expireDate,
    expireDateValue,
    setExpireDateValue,
    dateFormat,
    display,
    failureToast,
    setFailureReason,
  } = props;

  const formRef = useRef();
  const closeButtonRef = useRef();
  const saveButtonInternalRef = useRef();
  // DOM attributes could be passed to Button
  // And it's restProps pass it to DOM node
  // We sometime pass restProps in nested React Components
  // Hence the hook
  const saveButtonRef = useSetDOMAttributes({
    ref: saveButtonInternalRef,
    datasets: buttonDatasets,
    refName: 'publishedExpiryDateSaveButton',
  });
  const { columnsCount } = useDocumentViewLayoutInfo();
  const { resetRPCStates } = useAPIError(agreementId);
  const isTwoColumnLayout = columnsCount === 2;
  const popupType = isTwoColumnLayout ? 'popover' : 'dialog';

  const { Popup, PopupContent } = usePopupDialog(popupType);

  const dispatch = useDispatch();

  let triggerElement = (
    <div
      className={clsx(
        style.TriggerElement, style.Bold,
        {
          [style.inline]: display === 'inline',
          [style.DatePassed]: hasExpireDatePassed,
        },
      )}
      data-testid="dateAsDatePickerTrigger"
    >
      {formatDateString(expireDate, dateFormat)}
    </div>
  );

  if (!expireDate) {
    triggerElement = (
      <div
        className={style.AddExpiry}
        data-testid="addExpiryDateTrigger"
      >
        <div className={style.AddIconContainer}><AddIcon height="10px" /></div>
        <div className={clsx(style.TriggerElement, style.NoExpiryDate)}>
          <Message
            id="Add expiry date"
            comment="Text explains that the signing period doesn't expire"
          />
        </div>
      </div>
    );
  }

  const reset = () => setExpireDateValue(getMoment(expireDate));
  // It will also reset local state
  const triggerClose = () => closeButtonRef.current?.click();

  const updateExpireDate = useCallback((event) => {
    event.preventDefault();
    const bodyData = Object.fromEntries(new FormData(event.target));

    resetRPCStates();

    dispatch(agreements.updateExpiryDate({
      id: agreementId,
      data: bodyData,
      pipe: {
        onSuccess: () => {
          // Click Popover close button programmatically
          triggerClose();
        },
        onFailure: (reason) => {
          // TO-DO: Toast needed to be replaced here OF-13200
          setFailureReason(reason);
          // Hide it after 10 seconds
          setTimeout(() => {
            setFailureReason('');
          }, 10000);
          log.error(reason, { errorContext: 'Attempt to update signing period as fixed date in contract view' });

          // Click Popover close button programmatically
          triggerClose();
        },
      },
    }));
  }, [
    agreementId,
    dispatch,
    setFailureReason,
    resetRPCStates,
  ]);

  return (
    <Popup.Root>
      {failureToast}
      <Popup.Trigger asChild>
        {triggerElement}
      </Popup.Trigger>
      <PopupContent
        style={{ zIndex: 99999 }}
        popoverContentProps={popoverContentProps}
        onKeyDown={(event) => {
          // Handle When user click outside the form area and press enter
          if (event.key !== 'Enter' || formRef.current?.contains(event.target)) {
            return;
          }

          formRef.current?.requestSubmit(saveButtonInternalRef.current);
        }}
        onEscapeKeyDown={triggerClose}
        onInteractOutside={triggerClose}
      >
        <form ref={formRef} onSubmit={updateExpireDate}>
          <div className={style.ExpiryContainer}>
            <fieldset>
              {/* Has hidden input and onSubmit will receive the post ready formatted value */}
              <ExpiryDatePicker
                id="datePickerDialog"
                name="expireDate"
                expireDateValue={expireDateValue}
                setExpireDateValue={(date) => {
                  setExpireDateValue(date);
                  // For yet to be known reason the focus is set back to body element
                  // After brief focus at selected date when clicking.
                  if (saveButtonInternalRef.current) {
                    saveButtonInternalRef.current?.focus();
                  }
                }}
                onAfterFocus={(event) => {
                  if (event.relatedTarget?.type === 'submit' && saveButtonInternalRef.current) {
                    saveButtonInternalRef.current.focus();
                  }
                }}
              />
            </fieldset>
            <div className={style.ActionButtonsContainer}>
              <Popup.Close asChild>
                <CancelButton onClick={reset} />
              </Popup.Close>
              <Button
                buttonRef={saveButtonRef}
                type="submit"
                kind="primary"
              >
                <Message id="Save" comment="Button text for cancel button" />
              </Button>
              <Popup.Close asChild>
                {/* No translation needed and not part of accessibility */}
                <Button
                  hidden
                  buttonRef={closeButtonRef}
                  type="button"
                  onClick={reset}
                >
                  Programmatic hidden close button
                </Button>
              </Popup.Close>
            </div>
          </div>
        </form>
      </PopupContent>
    </Popup.Root>
  );
}

export default PublishedDocumentExpiryDatePopover;
