/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useState } from 'react';
import { Resizable } from 're-resizable';
import { rectIntersection, useDraggable } from '@dnd-kit/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import type { ResizeCallback, ResizeStartCallback } from 're-resizable';
import type { ReactNode, MutableRefObject } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import type { Size, Position } from 'types/overlay';
import Message from 'components/message';
import { useExpandableSidebarProps } from 'components/document-layout-container/expanded-document-layout/context';
import DragHandlerIcon from 'components/icons/drag-handler';
import { DATA_FIELD, SIGNATURE_FIELD } from 'components/contract-boxes/constants';

import style from './overlay-field-rectangle.module.scss';
import {
  DEFAULT_OVERLAY_FIELD_CONTAINER_Z_INDEX,
} from '../values';

type FieldType = typeof DATA_FIELD | typeof SIGNATURE_FIELD;

export const ResizeCornerHandler = ({ type }: {type: FieldType}) => (
  <div className={style.ResizeCornerHandlerWrapper}>
    <div className={clsx(style.ResizeCornerHandler,
      { [style.ResizeCornerSignatureFieldHandler]: type === SIGNATURE_FIELD })}
    />
  </div>
);

type Props = {
  overlayFieldContentDataId: number,
  size: Size,
  position: Position,
  overlayFieldContainerRef: MutableRefObject<HTMLDivElement | null>,
  dataFieldName: string,
  children: ReactNode,
  scale: number,
  onResize: (size: Size) => void,
  onMove: (position: Position) => void,
  canAlterBoxComposition: boolean,
  canAlterAgreementLayout: boolean,
  focused: boolean,
  isValueEmpty: boolean,
  isRequired: boolean,
  isBroken: boolean,
  isConcluded: boolean,
  type: FieldType,
};

const OverlayFieldRectangle = ({
  overlayFieldContentDataId,
  size,
  position,
  overlayFieldContainerRef,
  dataFieldName,
  children,
  scale,
  onResize,
  onMove,
  canAlterBoxComposition,
  canAlterAgreementLayout,
  focused,
  isValueEmpty,
  isRequired,
  isBroken,
  isConcluded,
  type,
}: Props) => {
  const expandedContext = useExpandableSidebarProps();
  const canResize = canAlterBoxComposition && canAlterAgreementLayout && !isBroken;
  const isDragHandlerVisible = canResize && !isEmpty(expandedContext);
  const [isResizing, setIsResizing] = useState(false);

  const onOverlayFieldMove = useCallback((event: DragEndEvent) => {
    const { delta } = event;

    if (!overlayFieldContainerRef.current?.parentElement) {
      return;
    }

    const pageRect = overlayFieldContainerRef.current.parentElement.getBoundingClientRect();
    const combinedSideBordersWidth = 2;
    const maxX = (pageRect.width / scale) - size.width - combinedSideBordersWidth;
    const maxY = (pageRect.height / scale) - size.height - combinedSideBordersWidth;

    const calculatedX = position.x + (delta.x / scale);
    const calculatedY = position.y + (delta.y / scale);

    const x = Math.min(calculatedX, maxX);
    const y = Math.min(calculatedY, maxY);

    onMove({
      x: Math.max(0, x),
      y: Math.max(0, y),
    });
  }, [overlayFieldContainerRef, scale, size.width, size.height, position.x, position.y, onMove]);

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
  } = useDraggable({
    id: `draggable-overlay-field${overlayFieldContentDataId}`,
    data: {
      segmentId: 'movable_data_object',
      onMove: onOverlayFieldMove,
      size,
      scale,
      collisionDetection: rectIntersection,
    },
  });

  const shouldShowNamePill = canAlterBoxComposition
  && !focused
  && !isDragging
  && !isResizing
  && type !== SIGNATURE_FIELD;
  const onResizeStart = useCallback<ResizeStartCallback>(() => {
    setIsResizing(true);
  }, []);

  const onResizeStop = useCallback<ResizeCallback>((_e, _direction, _ref, delta) => {
    setIsResizing(false);
    const newWidth = size.width + Number(delta.width);
    const newHeight = size.height + Number(delta.height);

    if (newWidth <= 0 || newHeight <= 0) {
      return;
    }

    onResize({
      width: newWidth,
      height: newHeight,
    });
  }, [
    size.width,
    size.height,
    onResize,
  ]);

  const transformStyle = isDragging
    ? `translate3d(${transform?.x ? (transform.x / scale) : 0}px, ${transform?.y ? (transform?.y / scale) : 0}px, 0) scale(1)`
    : undefined;

  const className = clsx(style.OverlayFieldRectangle, {
    [style.IsReadOnly]: isConcluded,
    [style.IsDragging]: isDragging,
    [style.IsFocused]: focused,
    [style.IsValueEmpty]: isValueEmpty,
    [style.IsRequired]: isRequired,
    [style.IsBroken]: isBroken,
    [style.SignatureField]: type === SIGNATURE_FIELD,
  });

  return (
    <Resizable
      lockAspectRatio={false}
      enable={{
        top: false,
        left: false,
        topRight: false,
        bottomLeft: false,
        topLeft: false,
        bottom: canResize,
        right: canResize,
        bottomRight: canResize,
      }}
      minHeight={type === 'data_field' ? '18px' : '24px'}
      style={{
        lineHeight: 0,
        display: 'inline-block',
        top: position.y,
        left: position.x,
        zIndex: DEFAULT_OVERLAY_FIELD_CONTAINER_Z_INDEX,
        position: 'absolute',
        transform: transformStyle,
      }}
      size={size}
      className={className}
      ref={(resizableInstance) => {
        if (!resizableInstance) {
          return;
        }
        // eslint-disable-next-line no-param-reassign
        overlayFieldContainerRef.current = resizableInstance?.resizable as HTMLDivElement;
        setNodeRef(resizableInstance?.resizable as HTMLDivElement);
      }}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      scale={scale}
      bounds="parent"
      handleComponent={{
        bottomRight: <ResizeCornerHandler type={type} />,
      }}
      data-testid="overlay-field-container"
    >
      {shouldShowNamePill && (
      <div
        className={clsx(style.DataFieldNamePill, {
          [style.IsBroken]: isBroken,
        })}
        role="status"
      >
        {isBroken ? (
          <Message id="Unlinked field" comment="Label for broken overlay field" />
        ) : (
          <span>{dataFieldName}</span>
        )}
      </div>
      )}
      {children}
      {isDragHandlerVisible ? (
        <div
          className={style.DragHandle}
          {...listeners}
          {...attributes}
          tabIndex={-1}
        >
          <DragHandlerIcon
            className={clsx(style.DragHandleIcon,
              { [style.SignatureFieldDragHandleIcon]: type === SIGNATURE_FIELD })}
            width="8px"
            height="13px"
          />
        </div>
      ) : null}
    </Resizable>
  );
};

export default OverlayFieldRectangle;
