import {
  ReactNode, useState, useMemo, SyntheticEvent,
} from 'react';
import { isEmpty, noop } from 'lodash';
import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import {
  isDraftApprovalFlowNotStarted,
  isDraftApprovalFlowRunning,
  isDraftApprovalFlowSucceeded,
} from 'agreement';

import hasParticipantApprovedDraft from 'agreement/draft-approval-flow';
import isInternalApprover from 'agreement/participant/is-internal-approver';
import isCurrentInternalApprover from 'agreement/participant/is-current-internal-approver';
import ApproveDraftAction from 'components/document-actions/approve-draft-action';
import useAgreement from 'hooks/use-agreement';
import useCurrentMessages from 'hooks/use-current-messages';
import { getAgreementMyParticipant } from 'agreement/selectors';
import type { Layout } from 'components/document-layout-container/types';
import { COLLAPSED } from 'components/document-layout-container/helpers';
import ResolveAnnotationsModal from 'components/modals/resolve-annotations';
import SendDraftForApproval from 'components/modals/send-draft-for-approval';
import toast from 'components/toasts';
import EmailIcon from 'components/icons/email';
import { SendButton } from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons';
import { checkAcl } from 'components/acl';
import { isContractPristine } from 'reducers/current-contract';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import SendContractModal from 'components/modals/send-contract/send-contract-container';

type Props = {
  agreementId: Oneflow.Agreement['id'],
  onSendContractSuccess: () => void,
  canPublish?: boolean,
  children: ReactNode,
  customClassName?: string,
  layout: Layout,
  showApproveDraftModal: boolean,
  setShowApproveDraftModal: (value: boolean) => void,
};

const DraftApprovalActions = ({
  agreementId,
  onSendContractSuccess,
  children,
  customClassName,
  layout,
  canPublish,
  showApproveDraftModal,
  setShowApproveDraftModal,
}: Props) => {
  const { activeAnnotations } = useCurrentMessages();
  const agreement = useAgreement(agreementId);
  const myParticipant = useMemo(() => getAgreementMyParticipant(agreement), [agreement]);
  const isPristine = useSelector(isContractPristine);
  const hasApproved = hasParticipantApprovedDraft(agreement, myParticipant);
  const [showSentForApprovalModal, setShowSentForApprovalModal] = useState(false);

  const hasPermissionToStartDraftApprovalFlow = checkAcl(
    agreement.draftApprovalFlow?.acl, 'flow:start',
  );

  const getButtonLayout = () => {
    if (layout === COLLAPSED) {
      return <EmailIcon height="20px" />;
    }

    return (
      <>
        <EmailIcon height="16px" />
        <Message
          id="Send"
          comment="Button label for sending a document for signature."
        />
      </>
    );
  };

  const getButtonLabelAndIcon = () => {
    if (isDraftApprovalFlowRunning(agreement) || isDraftApprovalFlowSucceeded(agreement)) {
      return getButtonLayout();
    }

    return children;
  };

  const isButtonDisabled = () => {
    if (canPublish && !children) {
      return !canPublish;
    }

    if (!canPublish && children) {
      if (!isPristine) {
        return true;
      }

      // The button should be enabled even though there is no participants added.
      return false;
    }

    return !canPublish;
  };

  const draftApprovalErrorToast = () => (
    toast.error({
      id: 'cant-send-warning',
      title: <Message
        id="Send not possible"
        comment="Title for the warning message when the user can't send the document."
      />,
      description: <Message
        id="To send the document, remove internal approvers."
        comment="Description text for the warning message when the user can't send the document."
      />,
    })
  );

  const showInfoToast = () => (
    toast.info({
      id: 'sending-not-possible',
      title: <Message
        id="Sending not possible"
        comment="Info text explaining that sending the document is not possible."
      />,
      description: <Message
        id="Wait for the document to be approved."
        comment="Info text explaining that the user must wait for the document to be approved."
      />,
      duration: 5000,
    })
  );

  const handleOnPublish = (event: SyntheticEvent, onClick: (e: SyntheticEvent) => void) => {
    if (isDraftApprovalFlowRunning(agreement)) {
      return showInfoToast();
    }

    if (isDraftApprovalFlowNotStarted(agreement) && !hasPermissionToStartDraftApprovalFlow) {
      return draftApprovalErrorToast();
    }

    return onClick(event);
  };

  const renderSendButton = (onClick: (event: SyntheticEvent) => void) => (
    <SendButton
      customClassName={customClassName}
      onClick={(event: SyntheticEvent) => handleOnPublish(event, onClick)}
      disabled={isButtonDisabled()}
    >
      {getButtonLabelAndIcon()}
    </SendButton>
  );

  if (isDraftApprovalFlowNotStarted(agreement) || showSentForApprovalModal) {
    return (
      <SendDraftForApproval
        agreementId={agreementId}
        setShowSentForApprovalModal={setShowSentForApprovalModal}
      >
        {renderSendButton}
      </SendDraftForApproval>
    );
  }

  if (
    (
      (isDraftApprovalFlowRunning(agreement) && (!hasApproved || showApproveDraftModal))
      || (isDraftApprovalFlowSucceeded(agreement) && hasApproved)
    ) && isInternalApprover(myParticipant) && (!hasApproved || showApproveDraftModal)
  ) {
    return (
      <ApproveDraftAction
        agreement={agreement}
        participant={myParticipant}
        isCurrentInternalApprover={isCurrentInternalApprover(agreement, myParticipant)}
        setShowApproveDraftModal={setShowApproveDraftModal}
        layout={layout}
      />
    );
  }

  if (isDraftApprovalFlowRunning(agreement)) {
    return renderSendButton(noop);
  }

  if (!isEmpty(activeAnnotations)) {
    return (
      <ResolveAnnotationsModal agreementId={agreementId}>
        {renderSendButton}
      </ResolveAnnotationsModal>
    );
  }

  if (isDraftApprovalFlowSucceeded(agreement)) {
    return (
      <SendContractModal
        onSuccess={onSendContractSuccess}
        isOpen={false}
        agreementId={agreementId}
        context="agreementPublish"
      >
        {renderSendButton}
      </SendContractModal>
    );
  }

  return null;
};

export default DraftApprovalActions;
