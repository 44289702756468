// @flow

import * as React from 'react';
import { getPositions } from 'oneflow-client/positions';

import useApi from './use-api';

type Params = {
  ids: Array<number>,
}

const usePositions = (params: Params) => {
  const { run, ...responseState } = useApi();

  React.useEffect(() => {
    if (responseState.status === 'pristine') {
      run(getPositions({ params, normalizeOutput: false }));
    }
  }, [run, params, responseState.status]);

  // Do the data transformation here
  return {
    ...responseState,
    data: responseState?.data?.collection,
  };
};

export default usePositions;
