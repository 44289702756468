// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './extension-content-key-takeaways.module.scss';

type Props = {
  extension: Extension,
};

export const ExtensionContentKeyTakeaways = ({ extension }: Props) => {
  const boldLabelText = (text) => {
    // Long dash
    const splitter = '—';
    const [label, ...rest] = text.split(splitter);
    const restOfText = rest.join(splitter).trim();
    return (
      <>
        <strong>{label}</strong>
        {` ${splitter} `}
        {restOfText}
      </>
    );
  };

  if (!extension.keyTakeaways) {
    return null;
  }

  return (
    <>
      <h3 className={style.ExtensionContentHeader}>
        <Message
          id="Key takeaways"
          comment="Section header for an extension details page."
        />
      </h3>
      <div className={style.ExtensionContentBody}>
        <ul>
          {extension.keyTakeaways.map((keyTakeaway) => (
            <li key={keyTakeaway} className={style.KeyTakeaway}>
              {boldLabelText(keyTakeaway)}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
