// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import agreements from 'reducers/entities/agreements';
import agreementVideo from 'reducers/entities/agreement-video';

import VideoPage from './video';
import type { ConfirmType, Props } from './video';

type MSProps = {|
  removeVideoState: RemoveState,
  uploadVideoState: CreateState,
|};

type MDProps = {|
  setWelcomeVideoStatus: (status: number) => void,
  uploadVideo: (file: Blob, type: ConfirmType) => void,
  resetUploadVideoState: () => void,
  removeVideo: () => void,
|};

export type OwnProps = $Diff<$Exact<Props>, MSProps & MDProps>;

type MapStateToProps = (State, OwnProps) => MSProps;
export const mapStateToProps: MapStateToProps = (state, { agreement }) => ({
  removeVideoState: agreementVideo.getRemoveSelector(state, { id: agreement.id }),
  uploadVideoState: agreementVideo.getCreateSelector(state),
});

type MapDispatchToProps = (Dispatch<*>, OwnProps) => MDProps;
export const mapDispatchToProps: MapDispatchToProps = (dispatch, { agreement }) => ({
  setWelcomeVideoStatus: (status: number) => {
    dispatch(agreements.setAgreements({
      [agreement.id]: {
        welcomeVideo: status,
      },
    }));
  },
  uploadVideo: (file: Blob, type: ConfirmType) => {
    dispatch(agreementVideo.createAgreementVideo({
      data: {
        id: agreement.id,
        file,
        type,
      },
    }));
  },
  resetUploadVideoState: () => {
    dispatch(agreementVideo.createAgreementVideoReset());
  },
  removeVideo: () => {
    dispatch(agreementVideo.removeAgreementVideo({
      id: agreement.id,
    }));
  },
  resetRemoveState: () => {
    dispatch(agreementVideo.removeAgreementVideoReset({
      id: agreement.id,
    }));
  },
});

export default connect<Props, OwnProps, MSProps, MDProps, _, _>(
  mapStateToProps,
  mapDispatchToProps,
)(VideoPage);
