/* eslint-disable quote-props */
import caveat from './caveat';
import arimo from './arimo';
import anton from './anton';
import questrial from './questrial';
import poppins from './poppins';
import roboto from './roboto';
import raleway from './raleway';
import jost from './jost';
import gelasio from './gelasio';
import garamond from './garamond';
import inter from './inter';
import tinos from './tinos';
import sacramento from './sacramento';
import ibmPlexSans from './ibm-plex-sans';
import ibmPlexSerif from './ibm-plex-serif';
import ibmPlexMono from './ibm-plex-mono';
import sourceSansPro from './source-sans-pro';
import libreBaskerville from './libre-baskerville';
import courierPrime from './courier-prime';
import comicNeue from './comic-neue';
import proximaNova from './proxima-nova';
import workSans from './work-sans';
import whisper from './whisper';

const fontUrlMap = {
  'Arimo': arimo,
  'Questrial': questrial,
  'Anton': anton,
  'Poppins': poppins,
  'Proxima Nova': proximaNova,
  'Roboto': roboto,
  'Raleway': raleway,
  'Jost': jost,
  'Inter': inter,
  'IBM Plex Sans': ibmPlexSans,
  'Source Sans Pro': sourceSansPro,
  'Work Sans': workSans,
  'IBM Plex Serif': ibmPlexSerif,
  'Gelasio': gelasio,
  'Garamond': garamond,
  'Tinos': tinos,
  'Libre Baskerville': libreBaskerville,
  'Courier Prime': courierPrime,
  'IBM Plex Mono': ibmPlexMono,
  'Comic Neue': comicNeue,
  'Caveat': caveat,
  'Sacramento': sacramento,
  'Whisper': whisper,
};

export default fontUrlMap;
