// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { MenuItem } from 'components/menu-item';
import Replace from 'components/icons/replace';

export type Props = {|
  disabled?: boolean,
  onClick: Function,
|};

export const ReplaceMenuItem = ({
  disabled,
  onClick,
}: Props) => (
  <MenuItem
    icon={Replace}
    label={(
      <Message
        id="Replace"
        comment="Label for replace contract action in menu."
      />
    )}
    disabled={disabled}
    onClick={onClick}
  />
);

ReplaceMenuItem.defaultProps = {
  disabled: undefined,
};
