import useAgreement from 'hooks/use-agreement';
import ContractID from 'components/box-list/contract-id';
import { isBoxCreateAllowed } from 'agreement/add-box-permissions';

import BlankContract from './blank-contract';
import styles from './empty-list.module.scss';
import NoPermissionMessage from './no-permission-message';

type Props = {
  agreementId: number;
}

const EmptyList = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);

  const isAllowedToAddBox = isBoxCreateAllowed(agreement);

  return (
    <>
      <section className={styles.EmptyList}>
        {isAllowedToAddBox ? <BlankContract /> : <NoPermissionMessage agreementId={agreementId} />}
      </section>
      <ContractID agreementId={agreementId} />
    </>
  );
};

export default EmptyList;
