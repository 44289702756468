import React from 'react';
import PropTypes from 'prop-types';

import style from './table.module.scss';

const TableHead = ({ children }) => (
  <thead className={style.TableHead}>
    {children}
  </thead>
);

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableHead;
