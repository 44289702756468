import Message from 'components/message';

import { SetDate } from 'components/contract-boxes/duration-box/set-date';
import { SetTimeSpan } from 'components/contract-boxes/duration-box/set-time-span';
// eslint-disable-next-line import/named
import { useDurationBoxProps } from 'contexts/duration-box-props';

export const SinglePeriod = () => {
  const {
    startDate,
    endDate,
  } = useDurationBoxProps();

  const renderStartDateMessage = () => {
    if (!startDate) {
      return null;
    }

    return (
      <>
        <br />
        <Message
          id="The start date for the contract is {setStartDateComponent}."
          comment="Text to show the start date of contract."
          values={{
            setStartDateComponent: <SetDate dateType="startDate" />,
          }}
        />
      </>
    );
  };

  const renderDuration = () => {
    if (!endDate) {
      return (
        <div>
          <Message
            id="The contract period is {SetDurationComponent}.{breakLine}After the contract period, the contract automatically ends."
            comment="Text to describe single period duration."
            values={{
              SetDurationComponent: <SetTimeSpan toSet="duration" />,
              breakLine: <br />,
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <Message
          id="The contract period lasts until {setEndDateComponent}.{breakLine}After this date the contract automatically ends."
          comment="Text to describe single period duration end date."
          values={{
            setEndDateComponent: <SetDate dateType="endDate" />,
            breakLine: <br />,
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div>
        {renderDuration()}
      </div>
      {renderStartDateMessage()}
    </>
  );
};
