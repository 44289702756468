import {
  useCallback,
  useMemo,
} from 'react';
import { Message } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import type { DropzoneProps } from 'react-dropzone';

import { isBoxDataCreateAllowed } from 'agreement/box-data-create-permissions';
import { updateBoxConfigAction } from 'reducers/current-contract';
import { useFileUploadBoxProps } from 'contexts/file-upload-box-props';
import useCurrentBox from 'hooks/use-current-box';
import type { AttachmentBox } from 'data-validators/entity-schemas/document-box/attachment-box';

import { acceptedExtensions } from 'components/contract-boxes/attachment-box/attachment-box-helpers';
import { MAX_ATTACHMENT_SIZE } from 'components/contract-boxes/constants';
import { MenuItem } from 'components/menu-item';
import Add from 'components/icons/add';
import AttachmentContainer from 'components/contract-boxes/attachment-box/attachment-container';
import BoxFileUpload from 'components/contract-boxes/box-file-upload';
import BoxWrapper from 'components/contract-boxes/box-wrapper';
import NewCheck from 'components/icons/new-check';

import { useAttachmentBoxValidationContext } from './attachment-box-validation-provider';
import style from './attachment-box-container.module.scss';

type props = {
  boxId: number,
  editable: boolean,
  onAddSectionRules: () => void,
  onRemoveBox: () => void,
  permissions: {
    update: boolean,
    updateConfig: boolean,
  },
}
const AttachmentBoxContainer = ({
  boxId,
  editable,
  onAddSectionRules,
  onRemoveBox,
  permissions,
}: props) => {
  const dispatch = useDispatch();
  const {
    attachmentsCountLimit,
    colleagueEdit,
    counterpartEdit,
    errorMessage,
    loading,
    managerLock,
    maxSizePerContractValidator,
    onDrop,
    onErrorHandler,
    requiredForSignatures,
  } = useFileUploadBoxProps();

  const box = useCurrentBox(boxId) as AttachmentBox;
  const isAllowedToAddBoxData = isBoxDataCreateAllowed(box);
  const { setTouched } = useAttachmentBoxValidationContext();

  const toggleCounterpartEdit = useCallback(() => {
    const newBoxConfig: Partial<AttachmentBox['config']> = {
      counterpartEdit: !counterpartEdit,
    };
    if (!newBoxConfig.counterpartEdit) {
      newBoxConfig.requiredForSignatures = false;
    }

    dispatch(updateBoxConfigAction(boxId, newBoxConfig));
  }, [dispatch, boxId, counterpartEdit]);

  const toggleRequiredForSignatures = useCallback(() => {
    const newBoxConfig: Partial<AttachmentBox['config']> = {
      requiredForSignatures: !requiredForSignatures,
    };
    if (newBoxConfig.requiredForSignatures) {
      newBoxConfig.counterpartEdit = true;
    }

    dispatch(updateBoxConfigAction(boxId, newBoxConfig));
  }, [boxId, dispatch, requiredForSignatures]);

  const toggleColleagueEdit = useCallback(() => {
    dispatch(updateBoxConfigAction(boxId, { colleagueEdit: !colleagueEdit }));
  }, [boxId, dispatch, colleagueEdit]);

  const fileUploadProps = useMemo(() => ({
    onError: onErrorHandler,
    acceptedExtensions,
    uploadingError: errorMessage,
    maxSize: MAX_ATTACHMENT_SIZE,
    multiple: true,
    maxFiles: attachmentsCountLimit,
    maxSizePerContractValidator,
    isAllowedToAddBoxData,
    onDrop: ((...args) => {
      setTouched(true);
      onDrop(...args);
    }) as NonNullable<DropzoneProps['onDrop']>,
  }), [
    attachmentsCountLimit,
    errorMessage,
    maxSizePerContractValidator,
    onErrorHandler,
    onDrop,
    isAllowedToAddBoxData,
    setTouched,
  ]);

  const getAttachmentActions = () => ([
    <BoxFileUpload
      key="box-file-upload"
      {...fileUploadProps}
      customClassName={style.DropzoneMenuItem}
      disabled={!isAllowedToAddBoxData || loading}
    >
      <MenuItem
        icon={Add}
        label={(
          <Message
            id="Add attachment"
            comment="Action to add attachment."
          />
        )}
        disabled={!isAllowedToAddBoxData || loading}
      />
    </BoxFileUpload>,
    <MenuItem
      key="counterpart-edit"
      icon={counterpartEdit ? NewCheck : <div className={style.EmptyIcon} />}
      label={(
        <Message
          id="Allow counterparties to add attachments"
          comment="Action to allow counterparties to add attachments."
        />
      )}
      onClick={toggleCounterpartEdit}
      disabled={!permissions.updateConfig}
    />,
    <MenuItem
      key="required-for-signatures"
      icon={requiredForSignatures ? NewCheck : <div className={style.EmptyIcon} />}
      label={(
        <Message
          id="Require counterparty attachment"
          comment="Action for require counterparty attachment."
        />
      )}
      onClick={toggleRequiredForSignatures}
      disabled={!permissions.updateConfig}
    />,
    Boolean(managerLock)
    && (
      <MenuItem
        key="colleague-edit"
        icon={colleagueEdit ? NewCheck : <div className={style.EmptyIcon} />}
        label={(
          <Message
            id="Allow colleagues to add attachments"
            comment="Action to allow colleagues to add attachments."
          />
        )}
        onClick={toggleColleagueEdit}
        disabled={!permissions.updateConfig}
      />
    ),
  ]);

  return (
    <BoxWrapper
      boxId={boxId}
      isAllowedToEdit={editable}
      onRemoveBox={onRemoveBox}
      onAddSectionRules={onAddSectionRules}
      nonFixedActions={getAttachmentActions()}
    >
      <div className={style.AttachmentBoxContainer}>
        <AttachmentContainer fileUploadProps={fileUploadProps} />
      </div>
    </BoxWrapper>
  );
};

export default AttachmentBoxContainer;
