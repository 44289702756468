import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import Modal from 'components/modal';
import Button from 'components/button';

import { HelpCenterLink } from 'components/help-center-link';

import style from './change-plan-success.module.scss';

export type Props = {
  onClose: () => void,
  modalKey: string,
};

export const FreemiumSelfServiceSuccess = ({
  onClose,
  modalKey,
}: Props) => {
  const successCheckAnimationClasses = clsx('success_check_animation', style.SuccessCheckAnimation);

  return (
    <Modal
      header={(
        <Message
          id="Upgrade completed"
          comment="Message shown in change plan modal"
        />
      )}
      onCancel={onClose}
      modalKey={modalKey}
      centeredActions
    >
      <Modal.Body>
        <div className={successCheckAnimationClasses} />
        <div className={style.Confirmation}>
          <h2 className={style.Heading}>
            <Message
              id="Upgrade completed"
              comment="Heading shown when customer successfully upgrades their plan"
            />
          </h2>
          <div className={style.TextBox}>
            <p className={style.Paragraph}>
              <Message
                id="Your new Subscription Plan is now active and you can start using the new features."
                comment="Paragraph shown when customer successfully upgrades their plan"
              />
            </p>
          </div>
          <h2 className={style.SubHeading}>
            <Message
              id="Need help getting started?"
              comment="Heading shown when customer successfully upgrades their plan"
            />
          </h2>
          <div className={style.TextBox}>
            <p className={style.Paragraph}>
              <Message
                id="Our help center is filled with great articles on our features and how to make the most out of your Oneflow experience."
                comment="shown when customer successfully upgrades their plan"
              />
              {' '}
              <HelpCenterLink />
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <div className={style.ButtonContainer}>
          <Button
            kind="primary"
            data-testid="confirm"
            onClick={onClose}
          >
            <Message
              id="Done"
              comment="Text for button to confirm action."
            />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
