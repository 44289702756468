import { combineReducers } from 'redux';
import type { Reducer } from 'redux';
import type { IreduxI18nState } from '@oneflowab/pomes';

import app from 'reducers/app';
import i18nState from 'reducers/i18n';
import session from 'reducers/session';
import router from 'reducers/router';

import currentContract from 'reducers/current-contract';
import entities from 'reducers/entities';
import editor from 'reducers/editor';
import freshworks from 'reducers/freshworks';

const rootReducer = combineReducers({
  app,
  i18nState: i18nState as Reducer<IreduxI18nState, any>,
  session,
  router,

  entities,
  editor,
  currentContract,
  freshworks,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
