// @flow

import * as React from 'react';

import Tooltip from 'components/tooltip';

import style from './sidebar-tooltip.module.scss';

type Props = {
  children: React.Node,
  tooltipText: string,
  isDisabled?: boolean,
  sideOffset: number,
  zIndex?: number,
};

const SidebarMenuTooltip = ({
  children,
  tooltipText,
  isDisabled,
  sideOffset,
  zIndex,
}: Props) => (
  <Tooltip
    disabled={isDisabled}
    message={tooltipText}
    messageClassName={style.TooltipOverlay}
    sideOffset={sideOffset}
    side="right"
    zIndex={zIndex ?? 3}
  >
    {children}
  </Tooltip>
);

SidebarMenuTooltip.defaultProps = {
  isDisabled: undefined,
};

export default SidebarMenuTooltip;
