import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import extensionsReducer from 'reducers/entities/extensions';
import useAgreement from 'hooks/use-agreement';
import useFeatureFlag from 'hooks/use-feature-flag';
import useCurrentContractId from 'hooks/use-current-contract-id';
import { isUserLimited } from 'user/user';
import { isViewer } from 'agreement/participant';
import { getPositionFromSessionSelector } from 'reducers/session';
import { getAgreementMyParticipant, getGuestToken } from 'agreement/selectors';
import { KEY_TEMPLATE_GROUPS } from 'extensions';

export function useDocumentSidebarPermissions() {
  const agreementId = useCurrentContractId();
  const agreement = useAgreement(agreementId as number);
  const isContentTabFeatureEnabled = useFeatureFlag('temporaryContentTab');
  const isGuest = Boolean(useSelector(getGuestToken));
  const myPosition = useSelector(getPositionFromSessionSelector);
  const myParticipant = getAgreementMyParticipant(agreement);
  const isUserLimitedParticipant = isUserLimited(myPosition);
  const isParticipantViewer = !isEmpty(myParticipant) && isViewer(myParticipant);
  const isViewerGuestOrLimited = isGuest || isParticipantViewer || isUserLimitedParticipant;
  const extension = useSelector((state) => (
    extensionsReducer.getExtensionSelector(state, { id: KEY_TEMPLATE_GROUPS })
  ));

  const agreementHasDataFields = agreement?.data?.some((data) => data?.key === 'data_field') ?? false;
  const hasFieldsOrExtension = agreementHasDataFields || (extension?.state ?? false);

  const canSeeContentTab = isContentTabFeatureEnabled && !isGuest;
  const canSeeDataFieldsTab = !isViewerGuestOrLimited && hasFieldsOrExtension;

  return {
    canSeeContentTab,
    canSeeDataFieldsTab,
  };
}
