import React from 'react';
import clsx from 'clsx';

import { localize } from '@oneflowab/pomes';
import useOutsideClick from 'hooks/use-outside-click';

import style from './sidebar-submenu-desktop.module.scss';

type Props = {
  visible: boolean,
  expanded: boolean,
  items?: Node,
  hideSubmenu: () => void,
  sidebarRef: object,
};

const SidebarSubmenu = ({
  expanded,
  items,
  visible,
  hideSubmenu,
  sidebarRef,
}: Props) => {
  const subMenuClasses = clsx(style.SidebarSubmenu, style.Desktop, {
    [style.Visible]: visible,
    [style.Expanded]: expanded,
  });

  const onClickOutside = React.useCallback(() => {
    hideSubmenu();
  }, [hideSubmenu]);

  useOutsideClick(sidebarRef, onClickOutside, visible);

  return (
    <div className={subMenuClasses}>
      <div className={style.ItemsWrapper}>
        {items}
      </div>
    </div>
  );
};

export default localize<Props>(SidebarSubmenu);
