import { useDispatch, useSelector } from 'react-redux';
import billingsReducer from 'reducers/entities/billings';

import type { Billing } from 'types/billing';

function useEditInvoiceDetails(
  accountId: number,
  billing: Billing,
) {
  const dispatch = useDispatch();
  const formState = useSelector((state) => billingsReducer.getUpdateSelector(state, {
    id: accountId,
  }));

  const resetFormState = () => {
    dispatch(billingsReducer.updateBillingReset({ id: accountId }));
  };

  const onSubmit = ({
    invoiceReference,
    invoiceEmail,
  }: { invoiceReference: string, invoiceEmail: string }) => {
    dispatch(billingsReducer.updateBilling({
      id: accountId,
      data: {
        invoiceReference,
        invoiceEmail,
      },
      pipe: {
        action: () => billingsReducer.fetchBilling({ id: accountId }),
        wait: billingsReducer.FETCH_BILLING_SUCCESS,
      },
    }));
  };

  return {
    formState,
    resetFormState,
    onSubmit,
  };
}

export default useEditInvoiceDetails;
