import {
  useEffect,
} from 'react';

import type { MutableRefObject } from 'react';

import usePrevious from 'hooks/use-previous';

const useCanvasMemoryOptimizer = (
  canvasRef: MutableRefObject<HTMLCanvasElement | null>,
  isVisible: boolean,
): void => {
  const previousIsVisible = usePrevious(isVisible);

  useEffect(() => {
    const { current: canvas } = canvasRef;
    if (!canvas) {
      return;
    }

    if (previousIsVisible && !isVisible) {
      canvas.width = 0;
      canvas.height = 0;
      canvas.remove();
      // eslint-disable-next-line no-param-reassign
      canvasRef.current = null;
    }
  }, [canvasRef, isVisible, previousIsVisible]);
};

export default useCanvasMemoryOptimizer;
