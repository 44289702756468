// eslint-disable-next-line import/named
import { DurationBoxPropsProvider } from 'contexts/duration-box-props';
import { DurationBoxContainer } from 'components/contract-boxes/duration-box/duration-box-container';

type Props = {
  boxId: number,
  isEditable: boolean,
  agreementId: number,
  onRemoveBox: () => void,
}

export const DurationBox = ({
  boxId,
  isEditable,
  agreementId,
  onRemoveBox,
}: Props) => (
  <DurationBoxPropsProvider
    boxId={boxId}
    isEditable={isEditable}
    agreementId={agreementId}
  >
    <DurationBoxContainer onRemoveBox={onRemoveBox} />
  </DurationBoxPropsProvider>
);
