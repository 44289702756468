// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import AddRole, { type Props, type FormData } from 'components/modals/add-role/add-role';

import rolesReducer from 'reducers/entities/roles';
import { getAccountFromSessionSelector } from 'reducers/session';

type OwnProps = {|
  children: React.Node,
|};

type StateProps = {|
  createState: RpcState,
  account: Account,
|};

type DispatchProps = {|
  createRole: FormData => void,
  resetCreateState: () => void,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  createState: rolesReducer.getCreateSelector(state),
  account: getAccountFromSessionSelector(state),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, OwnProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  createRole: ({ name, scope }) => {
    dispatch(rolesReducer.createRole({
      data: {
        name,
        scope: scope?.value || 'workspace',
      },
      pipe: {
        action: () => rolesReducer.queryRoles({
          name: 'admin/roles',
          pagination: {
            // should be same as the limit for the roles list original query to keep consistency
            limit: 50,
            offset: 0,
          },
          sort: ['-type', 'name'],
        }),
      },
    }));
  },
  resetCreateState: () => {
    dispatch(rolesReducer.createRoleReset());
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(AddRole);
