import React from 'react';

import Message from 'components/message';

export const CONTENT_TRUNCATE_LENGTH = 100;
export const CONTENT_TRUNCATE_NEWLINE_COUNT = 5;
export const CONTENT_TRUNCATE_NEWLINE_COUNT_MOBILE = 2;

export const showMoreLabel = (
  <Message
    id="Show more"
    comment="show more content of text', used as button text."
  />
);

export const showLessLabel = (
  <Message
    id="Show less"
    comment="show less content of text', used as button text."
  />
);

export const isContentTooLong = (content: string) => {
  if (!content) {
    return false;
  }

  const newlines = content.match(/\n/g);

  return (
    content.length > CONTENT_TRUNCATE_LENGTH + 50
    || (newlines && newlines.length > CONTENT_TRUNCATE_NEWLINE_COUNT)
  );
};

export const replaceNewLinesWithBreaks = (content: string) => {
  if (content.includes('\n')) {
    return content.split('\n').map((item) => (
      <React.Fragment key={item}>
        {item}
        <br />
      </React.Fragment>
    ));
  }

  return content;
};
