// @flow

import { compact } from 'lodash';
import * as agreementConstants from './constants';

// eslint-disable-next-line import/prefer-default-export
export const isImportInProgress = (agreement) => (
  agreement.import === agreementConstants.IMPORT_IN_PROGRESS
);

export const getPdfBoxContent = (responseJSON, boxes, _id = 2) => {
  const contentData = {
    key: 'file',
    value: {
      asset_identifier: responseJSON.assetIdentifier,
      name: responseJSON.assetName,
      has_file: 1,
      assetId: responseJSON.assetId,
      status: responseJSON.status,
    },
    _id,
  };

  const pdfBoxIndex = boxes.findIndex((box) => box.type === agreementConstants.BOX_PDF);

  if (pdfBoxIndex !== -1) {
    const _boxes = [...boxes];
    _boxes[pdfBoxIndex].content.data = [{
      ...contentData,
    }];
  }

  const newBoxes = compact(boxes);

  return {
    contentData,
    boxes: newBoxes,
  };
};
