import { Message } from '@oneflowab/pomes';
import { ButtonFC as Button } from 'components/button';

import Skeleton from 'components/logo-preview/skeleton';
import PreviewContainer from './preview-container';
import styles from './counterparty-preview.module.scss';

type Props = {
  companyName?: string | null;
  logo?: string | null;
}

const CounterPartyPreview = ({ companyName, logo }: Props) => (
  <PreviewContainer>
    <div className={styles.Container}>
      <div className={styles.Header}>
        {logo ? (
          <img src={logo} alt="Company logo" className={styles.Logo} />
        ) : (
          <span className={styles.CompanyName}>{companyName}</span>
        )}
        <Button
          customClass={styles.SignButton}
        >
          <Message id="Sign" comment="Sign on the counterparty preview" />
        </Button>
      </div>

      <div className={styles.Scroll} />
      <div className={styles.Body}>
        <div className={styles.CenterContent}>
          <Skeleton className={styles.ShortSkeleton} />
        </div>
        <Skeleton className={styles.LongSkeleton} />
        <Skeleton className={styles.LongSkeleton} />
        <Skeleton className={styles.LongSkeleton} />
        <Skeleton className={styles.MediumSkeleton} />
        <Skeleton className={styles.LongSkeleton} />
        <Skeleton className={styles.MediumSkeleton} />
        <Skeleton className={styles.LongSkeleton} />
        <Skeleton className={styles.LongSkeleton} />
      </div>
    </div>
  </PreviewContainer>
);

export default CounterPartyPreview;
