/* eslint-disable camelcase */
import type { FormEvent } from 'react';

import type { MessageTranslator } from '@oneflowab/pomes';
import type { FieldState } from 'final-form';

import * as validators from 'forms/validators';

type Validator<T = string | FormEvent<HTMLInputElement>, I = { [k: string]: unknown }> = (
  value: T,
  allValues?: unknown,
  meta?: FieldState<I>,
) => React.ReactNode;

export type ValidatorReturnType = ReturnType<Validator>;

type MaxLengthValidatorParams = {
  message: MessageTranslator;
  field: React.ReactNode;
  maxLength: number;
}
type MakeMaxLengthValidator = (params: MaxLengthValidatorParams) => Validator<string>

// overwriting flowType inference
export const makeMaxLengthValidator = validators.maxLength as unknown as MakeMaxLengthValidator;

export const createInvalidationMessage = (
  fieldsToValidate: string[],
  fieldValidators: any[],
) => (
  event: FormEvent<HTMLInputElement>,
) => {
  const inputElement = event.target as HTMLInputElement;

  if (!fieldsToValidate.includes(inputElement.id)) {
    return '';
  }

  const price = inputElement.value;
  if (!price) {
    return '';
  }

  const validation = fieldValidators.find(
    (validator) => validator.validate(price),
  );

  if (validation) {
    return validation?.validationMessage();
  }

  return '';
};
