// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import useCurrentContractId from 'hooks/use-current-contract-id';
import { useContractProps } from 'contexts/contract-props';

import AIAssistIcon from 'components/icons/ai-assist';

import ToolbarButton from '../toolbar-button';
import styles from './ai-assist.module.scss';

type Props = {
  disabled: boolean,
  isMenuItem: boolean
};

const AIAssistButton = ({ disabled, isMenuItem }: Props) => {
  const documentId = useCurrentContractId();

  const { setIsAiAssistPopoverOpen } = useContractProps();

  const handleTrigger = () => {
    setIsAiAssistPopoverOpen(true);
    amplitudeLogEvent(
      'Go To AI Assist',
      { location: 'contract view - toolbar' },
      { 'document id': documentId },
    );
  };

  return (
    <ToolbarButton
      isAiAssist
      label={(
        <Message
          id="AI Assist (Beta)"
          comment="The label of the editor's toolbar plugin that uses AI Assist."
        />
        )}
      isMenuItem={isMenuItem}
      disabled={disabled}
      onTrigger={handleTrigger}
      icon={AIAssistIcon}
      customButtonClass={styles.AIAssistButton}
    />
  );
};

export default AIAssistButton;
