// @flow

import { useSelector } from 'react-redux';
import {
  useSlate,
  useFocused,
} from 'slate-react';

import { getFocusedEditor } from 'reducers/editor';

const useIsEditorSelectionMemoized = () => {
  const editor = useSlate();
  const focused = useFocused();
  const focusedEditor = useSelector(getFocusedEditor);

  return focusedEditor === editor && !focused;
};

export default useIsEditorSelectionMemoized;
