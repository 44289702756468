import { isEmpty, isNull, isEqual } from 'lodash';
import {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { Message, localize } from '@oneflowab/pomes';
import { useSelector, useDispatch } from 'react-redux';
import type { MessageTranslator } from '@oneflowab/pomes';

import { getCurrentWorkspaceIdSelector, getCurrentWorkspaceSelector } from 'reducers/app';
import workspacesReducer from 'reducers/entities/workspaces';
import agreementsReducer from 'reducers/entities/agreements';
import * as agreementConstants from 'agreement/constants';
import usePrevious from 'hooks/use-previous';
import { isEnvironment } from 'utils/environment';
import { getValidValue } from 'agreement/sanitizers';

import { useQueryStringParams } from 'hocs/query-string-params';
import { useFilterContext } from 'hocs/search/filter/context';
import { getFiltersValues } from 'hocs/search/filter/utils';
import Filter from 'hocs/search/filter/filter';
import FolderTree from 'components/folder-tree';
import CheckboxGroup from 'components/checkbox-group';
import CircularSpinner from 'components/icons/circular-spinner';
/* eslint-disable import/named */
import { ApiError, unknownApiError } from 'components/api-error';
import { ChartCard } from 'components/chart-card';
import { lifecycleFilterOptions } from 'components/filter-options';
import { checkAcl } from 'components/acl';
import type { Params } from 'hocs/query-string-params/types';

import { stateFilterOptions } from './filter-options/state-filter-options';
import {
  PieInsightChart,
  StackedBarChart,
  VerticalBarChart,
} from './charts';
import { CONTRACT_NOT_ANALYSED, CONTRACT_ANALYSED_NO_ISSUES, CONTRACT_ANALYSED_WITH_ISSUES } from './charts/constants';
import { getTexts } from './texts';
import { InsightsEmptyState } from './insights-empty-state';

import style from './ai-insights.module.scss';

const charts: {
  [key: string]: {
    type: string;
    dataKey: string;
    nameKey?: string;
    chartName: string;
  }
} = {
  Confidentiality: {
    type: 'pie',
    dataKey: 'count',
    nameKey: 'confidentiality',
    chartName: 'confidentiality',
  },
  PriceAdjustment: {
    type: 'pie',
    dataKey: 'count',
    nameKey: 'priceAdjustment',
    chartName: 'price adjustment',
  },
  LimitationOfLiability: {
    type: 'pie',
    dataKey: 'count',
    nameKey: 'limitationOfLiability',
    chartName: 'limitation of liability',
  },
  AutomaticRenewal: {
    type: 'pie',
    dataKey: 'count',
    nameKey: 'automaticRenewal',
    chartName: 'automatic renewal',
  },
  LiquidatedDamages: {
    type: 'pie',
    dataKey: 'count',
    nameKey: 'liquidatedDamages',
    chartName: 'liquidated damages',
  },
  GoverningLaw: {
    type: 'vertical',
    dataKey: 'count',
    chartName: 'governing law',
  },
  Termination: {
    type: 'pie',
    dataKey: 'count',
    nameKey: 'termination',
    chartName: 'termination',
  },
  PaymentTerm: {
    type: 'vertical',
    dataKey: 'count',
    chartName: 'payment terms',
  },
  NoticePeriod: {
    type: 'bar',
    dataKey: 'count',
    chartName: 'notice period',
  },
  ForceMajeure: {
    type: 'bar',
    dataKey: 'count',
    chartName: 'force majeure',
  },
  AgreementTerm: {
    type: 'bar',
    dataKey: 'count',
    chartName: 'agreement terms',
  },
  DisputeResolution: {
    type: 'pie',
    dataKey: 'count',
    chartName: 'dispute resolution',
  },
  Indemnities: {
    type: 'pie',
    dataKey: 'count',
    chartName: 'indemnities',
  },
  ExclusionConsequentialDamages: {
    type: 'pie',
    dataKey: 'count',
    chartName: 'exclusion of consequential damages',
  },
};

type Insight = {
  type: string;
  count: number;
  data: Array<{ [key: string]: number }>;
  reviewText?: { values: { [key: string]: string } };
};

type InsightsData = {
  type: string;
  reviewText?: string;
  description?: string;
  insights: { [key: string]: Insight };
};

type Data = {
  reviewSeverity: string;
  insights: InsightsData[];
  playbookTemplateType: 'SALES' | 'NDA' | 'SOURCING' | 'CUSTOM';
  filteredDocumentCount: number;
  filteredDocumentWithIssuesCount: number;
};

const getTotalData = (insightsData: Data, totalDocumentsInQuery: number) => {
  const { filteredDocumentCount, filteredDocumentWithIssuesCount } = insightsData || {};
  const noIssuesCount = filteredDocumentCount - filteredDocumentWithIssuesCount;
  return {
    data: [
      {
        count: isEnvironment('development') ? 30 : filteredDocumentWithIssuesCount,
        isBreach: true,
        label: CONTRACT_ANALYSED_WITH_ISSUES,
      },
      {
        count: isEnvironment('development') ? 10 : noIssuesCount,
        isBreach: false,
        label: CONTRACT_ANALYSED_NO_ISSUES,
      },
      {
        count: isEnvironment('development') ? 5 : (totalDocumentsInQuery - filteredDocumentCount),
        isBreach: null,
        label: CONTRACT_NOT_ANALYSED,
      },
    ].filter((dataPoint) => dataPoint.count > 0),
    label: 'All',
    type: 'all',
  };
};

const getSelectedUniqueKey = (params: { folderIds?: number[] } = {}) => {
  if (params?.folderIds) {
    return params.folderIds[0] || -1;
  }

  return -1;
};

const filterableStates: { [key: number]: string } = {
  [agreementConstants.STATE_PENDING]: 'pending',
  [agreementConstants.STATE_OVERDUE]: 'overdue',
  [agreementConstants.STATE_SIGNED]: 'signed',
  [agreementConstants.STATE_DECLINED]: 'declined',
};

const filterableLifecycles: { [key: number]: string } = {
  [agreementConstants.LIFECYCLE_AWAITING]: 'awaiting',
  [agreementConstants.LIFECYCLE_ACTIVE]: 'active',
  [agreementConstants.LIFECYCLE_ENDED]: 'ended',
  [agreementConstants.LIFECYCLE_NOT_SET]: 'notset',
};

const statesFilterSanitizer = (filterableValues: string[]) => (values: string[]) => {
  if (!Array.isArray(values)) {
    return null;
  }
  const filteredValues = values
    .filter((value: string) => Object.values(filterableValues).includes(value));

  return getValidValue(filteredValues);
};

const AI_INSIGHTS_QUERY_AGREEMENTS = 'aiInsights';

const AiInsights = ({ message }: { message: MessageTranslator }) => {
  const { insightsTexts } = getTexts(message);
  const { subscribe, availableFilters, activeFilters } = useFilterContext();
  const { params, replaceParams } = useQueryStringParams();
  const [insightsData, setInsightsData] = useState<Data | any>({});
  const workspaceId = useSelector(getCurrentWorkspaceIdSelector) || 0;
  const dispatch = useDispatch();
  const previousParams = usePrevious(params);
  const workspace = useSelector(getCurrentWorkspaceSelector);
  const previousWorkspace = usePrevious(workspace);
  const { error, loading, success } = useSelector((state) => (
    workspacesReducer.getGetAiInsightsSelector(state, { id: workspaceId })
  ));
  const { count: documentCount, loading: documentsLoading } = useSelector((state) => (
    agreementsReducer.getQuerySelector(state, { name: AI_INSIGHTS_QUERY_AGREEMENTS })
  ));
  const { filteredDocumentCount } = insightsData;
  const hasChangedWorkspace = useRef(false);

  const hasFoldersViewPermission = checkAcl(workspace.acl, 'collection:folder:view');

  const renderFoldersFilter = () => {
    if (
      !workspace.virtual
      && hasFoldersViewPermission
    ) {
      return (
        <Filter
          component={FolderTree}
          defaultValue={[]}
          onSanitize={(value: number[]) => value}
          param="folderIds"
          selectedUniqueKey={getSelectedUniqueKey(params)}
          wrap={false}
          subscribe={subscribe}
          isReadOnly
        />
      );
    }
    return null;
  };

  const executeQuery = useCallback((_params: Params) => {
    const { queryParams } = getFiltersValues(_params, availableFilters.current);
    dispatch(workspacesReducer.getAiInsights({
      id: workspaceId,
      data: {
        params: queryParams,
      },
      pipe: {
        onSuccess: (data) => {
          setInsightsData(data);
        },
      },
    }));

    const { state, lifecycle, folderIds } = queryParams;
    const capiParams = {
      folderIds,
      state: state?.map((_state: string) => Number(Object.keys(filterableStates)
        .find(
          (key: string) => filterableStates[Number(key)] === _state,
        ))),
      lifecycle: lifecycle?.map((_lifecycle: string) => Number(Object.keys(filterableLifecycles)
        .find(
          (key: string) => filterableLifecycles[Number(key)] === _lifecycle,
        ))),
    };
    dispatch(agreementsReducer.queryAgreements({
      params: {
        ...capiParams,
        collectionIds: [workspaceId],
        includeFolderSubtree: isEmpty(folderIds) ? 1 : 0,
      },
      name: AI_INSIGHTS_QUERY_AGREEMENTS,
    }));
  }, [availableFilters, dispatch, workspaceId]);

  useEffect(() => {
    // The first time the component is rendered
    if (isNull(previousParams)) {
      executeQuery(params);
      return;
    }

    // If the current workspace has changed
    if (!isEqual(previousWorkspace, workspace)) {
      // Reset the query params (i.e. deselect folders) when changing workspace
      replaceParams({});
      // Execute the query with empty params, if we pass params, it will be the previous ones since
      // the changes by replaceParams hasn't been applied yet
      executeQuery({});
      hasChangedWorkspace.current = true;
      return;
    }

    // When changing workspaces above, we execute the query, but we also replace the params, which
    // triggers this effect again, so we need to make sure we don't execute the query twice
    if (hasChangedWorkspace.current) {
      hasChangedWorkspace.current = false;
      return;
    }

    const { queryParams: currentQueryParams } = getFiltersValues(params, availableFilters.current);
    const { queryParams: previousQueryParams } = getFiltersValues(
      previousParams || {},
      availableFilters.current,
    );
    // If the query params have changed
    if (!isEqual(previousQueryParams, currentQueryParams)) {
      executeQuery(params);
    }
  }, [
    previousParams,
    params,
    executeQuery,
    availableFilters,
    previousWorkspace,
    workspace,
    replaceParams,
  ]);

  const getChartData = useCallback((data: Insight) => {
    const hasUnprocessedDocuments = documentCount - filteredDocumentCount > 0;

    if (hasUnprocessedDocuments) {
      return {
        ...data,
        data: [
          ...data.data,
          {
            label: CONTRACT_NOT_ANALYSED,
            count: documentCount - filteredDocumentCount,
            isBreach: null,
          },
        ],
      };
    }
    return data;
  }, [documentCount, filteredDocumentCount]);

  return (
    <div className={style.AiInsights}>
      <div className={style.FilterSidebarContainer}>
        {renderFoldersFilter()}
        <h3 className={style.Title}>
          <Message
            id="Filters"
            comment="Header in the filters section."
          />
        </h3>
        <Filter
          param="state"
          title="Status"
          onSanitize={statesFilterSanitizer(Object.values(filterableStates))}
          defaultValue={Object.values(filterableStates)}
          options={
            stateFilterOptions(
              [...Object.keys(filterableStates).map(Number), agreementConstants.STATE_DRAFT],
            ).map((option) => ({ ...option, value: filterableStates[option.value] }))
          }
          component={CheckboxGroup}
          subscribe={subscribe}
          workspace={workspace}
        />
        <Filter
          param="lifecycle"
          type="array"
          title="Lifecycle"
          onSanitize={statesFilterSanitizer(Object.values(filterableLifecycles))}
          defaultValue={[]}
          options={lifecycleFilterOptions(({ id }) => id, Object.keys(filterableLifecycles)
            .map(Number))
            .map((option) => ({ ...option, value: filterableLifecycles[option.value] }))}
          component={CheckboxGroup}
          subscribe={subscribe}
          workspace={workspace}
        />
      </div>
      {loading && <div className={style.LoaderContainer}><CircularSpinner /></div>}
      {error && <ApiError customMessage={unknownApiError} />}
      <InsightsEmptyState
        success={success}
        documentsLoading={documentsLoading}
        documentCount={documentCount}
        activeFilters={activeFilters}
        insightsLength={insightsData.insights?.length}
      />
      {insightsData.insights?.length > 0 && (
        <div className={style.InsightsContainer}>
          <ChartCard
            title={(
              <Message
                id="Total issues"
                comment="Title for the overall total issues chart"
              />
              )}
            tooltipText={(
              <Message
                id="Overall number of contracts with issues found in the workspace"
                comment="Tooltip for the overall total issues chart"
              />
              )}
            filterParams={getFiltersValues(params, availableFilters.current).queryParams}
            insightType="all"
            hasIssues={insightsData.filteredDocumentWithIssuesCount > 0}
            chartName="total issues"
          >
            <PieInsightChart
              data={getTotalData(insightsData, documentCount)}
              dataKey="count"
              nameKey="all"
              chartName="total issues"
              title={(
                <Message
                  id="Total issues"
                  comment="Title for the overall total issues chart"
                />
                )}
              filterParams={getFiltersValues(params, availableFilters.current)
                .queryParams}
            />
          </ChartCard>
          {insightsData.insights?.map((data: Insight) => {
            const { playbookTemplateType = 'SALES' } = insightsData;
            const { type, reviewText } = data;
            if (charts[type]) {
              return (
                <ChartCard
                  key={type}
                  title={insightsTexts[playbookTemplateType][type]?.insightTitle}
                  chartName={charts[data.type].chartName}
                  playbook={reviewText}
                  tooltipText={insightsTexts[playbookTemplateType][type]?.insightTooltip}
                  insightType={type}
                  playbookTemplateType={playbookTemplateType}
                  filterParams={getFiltersValues(params, availableFilters.current).queryParams}
                  hasIssues={data.data.some((d) => d.isBreach)}
                >
                  {charts[type].type === 'pie' && (
                    <PieInsightChart
                      data={getChartData(data)}
                      dataKey={charts[data.type].dataKey}
                      nameKey={charts[data.type].nameKey}
                      title={insightsTexts[playbookTemplateType][type]?.insightTitle}
                      chartName={charts[data.type].chartName}
                      filterParams={getFiltersValues(params, availableFilters.current)
                        .queryParams}
                    />
                  )}
                  {charts[type].type === 'bar' && (
                    <StackedBarChart
                      data={getChartData(data)}
                      title={insightsTexts[playbookTemplateType][type]?.insightTitle}
                      chartName={charts[data.type].chartName}
                      filterParams={getFiltersValues(params, availableFilters.current)
                        .queryParams}
                    />
                  )}

                  {charts[type].type === 'vertical' && (
                    <VerticalBarChart
                      data={getChartData(data)}
                      title={insightsTexts[playbookTemplateType][type]?.insightTitle}
                      chartName={charts[data.type].chartName}
                      filterParams={getFiltersValues(params, availableFilters.current)
                        .queryParams}
                    />
                  )}
                </ChartCard>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default localize(AiInsights);
