// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getTemplateGroup,
  getTemplateGroups,
  createTemplateGroup,
  updateTemplateGroup,
} from 'oneflow-client/extensions/template-groups';
import type { NormalizedTemplateGroups } from 'oneflow-client/extensions/template-groups';

import apiWrapper from 'sagas/api-wrapper';

import templateGroupsReducer from 'reducers/entities/template-groups';

type MapperArgs = {
  data: NormalizedTemplateGroups,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(templateGroupsReducer.setTemplateGroups(data.entities.templateGroups));
}

type ClearMapperArgs = {
  data: {||},
  action: {
    id: number,
    type: string,
  },
};
export function* clearMapper({ action }: ClearMapperArgs): Mapper {
  yield put(templateGroupsReducer.clearTemplateGroup({ id: action.id }));
}

const mappers = {
  fetch: {
    mapper,
    request: getTemplateGroup,
  },
  query: {
    mapper,
    request: getTemplateGroups,
  },
  create: {
    mapper,
    request: createTemplateGroup,
  },
  update: {
    mapper,
    request: updateTemplateGroup,
  },
};

const templateGroupsSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: templateGroupsReducer,
  mappers,
});

export default templateGroupsSaga;
