// @flow

import * as React from 'react';
import clsx from 'clsx';

import Button from 'components/button';
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';
import { MenuItem } from 'components/menu-item';
import Tooltip from 'components/tooltip';

import style from './toolbar-button.module.scss';

type Props = {
  disabled?: boolean,
  isActive?: boolean,
  icon: any,
  isMenuItem: boolean,
  label: React.Node,
  onTrigger?: Function,
  // eslint-disable-next-line react/require-default-props
  asPopoverTrigger?: boolean,
  customButtonClass?: string,
  customPopoverTriggerClass: string,
  hideTooltip?: Boolean,
};

const ToolbarButton = ({
  disabled,
  isActive,
  icon,
  isMenuItem,
  label,
  onTrigger,
  asPopoverTrigger,
  customButtonClass,
  customPopoverTriggerClass,
  hideTooltip,
}: Props) => {
  const customClass = clsx(style.ToolbarButton, customButtonClass, {
    [style.Active]: isActive,
    [style.Disabled]: disabled,
  });

  if (isMenuItem) {
    const className = clsx(style.ToolbarMenuItem, {
      [style.Active]: isActive,
      [style.Disabled]: disabled,
    });

    if (asPopoverTrigger) {
      return (
        <MenuItem
          className={className}
        >
          <PopoverTriggerButton
            onClick={onTrigger}
            icon={icon}
            disabled={disabled}
            customClass={customPopoverTriggerClass}
          >
            {label}
          </PopoverTriggerButton>
        </MenuItem>
      );
    }

    return (
      <MenuItem
        label={label}
        disabled={disabled}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        onClick={(event) => {
          event.preventDefault();
          onTrigger();
        }}
        icon={icon}
        className={className}
      />
    );
  }

  if (asPopoverTrigger) {
    return (
      <div className={style.ToolbarButtonPopoverTrigger}>
        <Tooltip
          triggerClassName={style.ToolbarButtonTooltip}
          messageClassName={style.TooltipText}
          message={label}
          side="bottom"
          delayShow={750}
          delayHide={0}
          disabled={disabled}
          hideContent={hideTooltip}
        >
          <div className={style.ToolbarButtonTooltipTrigger}>
            <PopoverTriggerButton
              customClass={customClass}
              onClick={onTrigger}
              icon={icon}
              disabled={disabled}
            />
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <Tooltip
      triggerClassName={style.ToolbarButtonTooltip}
      messageClassName={style.TooltipText}
      message={label}
      side="bottom"
      delayShow={750}
      delayHide={0}
      disabled={disabled}
    >
      <div className={style.ToolbarButtonTooltipTrigger}>
        <Button
          customClass={customClass}
          onMouseDown={(event) => {
            event.preventDefault();
          }}
          onClick={onTrigger}
          icon={icon}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
};

ToolbarButton.defaultProps = {
  disabled: undefined,
  isActive: undefined,
};

export default ToolbarButton;
