import {
  ReactNode,
  useState,
  SyntheticEvent,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { FormRenderProps } from 'react-final-form';
import { Message } from '@oneflowab/pomes';
import isEmpty from 'lodash/isEmpty';

import { getPositionFromSessionSelector } from 'reducers/session';
import agreements from 'reducers/entities/agreements';
// eslint-disable-next-line import/named
import { getContractMessageTemplates } from 'oneflow-client/message-templates';
import startFlow from 'oneflow-client/flows';
import ModalForm from 'hocs/modal-form';
import useAgreement from 'hooks/use-agreement';

import Button from 'components/button';
// eslint-disable-next-line import/named
import { CancelButton } from 'components/buttons';
import CircularSpinner from 'components/icons/circular-spinner';
import EmailWithCheck from 'components/icons/email-with-check';
import Error from 'components/icons/error';
import SendContractBody from 'components/modals/send-contract/send-contract-body';
import DocumentSentBody from 'components/modals/document-sent-body';

import type { MessageTemplate, MessageTemplates } from 'types/message-templates';

import style from './send-draft-for-approval.module.scss';

type Props = {
  agreementId: number,
  children: (onClick: (event: SyntheticEvent) => void) => ReactNode;
  setShowSentForApprovalModal: (show: boolean) => void,
}

const MODAL_KEY = 'send for approval modal';

const SendDraftForApprovalComponent = (
  {
    agreementId,
    children,
    setShowSentForApprovalModal,
  }: Props,
) => {
  const agreement = useAgreement(agreementId);
  const position: Oneflow.Position = useSelector(getPositionFromSessionSelector);
  const [
    contractMessageTemplates,
    setContractMessageTemplates,
  ] = useState<MessageTemplates>([]);
  const [
    selectedMessageTemplate,
    setSelectedMessageTemplate,
  ] = useState<MessageTemplate | null>(null);
  const [isLoadingMessageTemplates, setIsLoadingMessageTemplates] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [
    sendAutomaticallyAfterApproval,
    setSendAutomaticallyAfterApproval,
  ] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getButtonLabel = () => {
    if (sendAutomaticallyAfterApproval) {
      return (
        <Message
          id="Save and send for approval"
          comment="Button label used to confirm saving and sending for approval."
        />
      );
    }

    return (
      <Message
        id="Send for approval"
        comment="Button label used to confirm sending the document for approval."
      />
    );
  };

  const getIcon = () => <EmailWithCheck height="14px" />;

  const getActions = ({ formProps, closeConfirmation }: FormRenderProps) => {
    if (isSuccess) {
      return (
        <div className={style.Confirm}>
          <Button
            kind="primary"
            onClick={closeConfirmation}
          >
            <Message
              id="Done"
              comment="Button label for confirming the document sent."
            />
          </Button>
        </div>
      );
    }

    return (
      <div className={style.SendButton}>
        <Button
          color="yellow"
          data-testid="confirm"
          icon={isLoading ? CircularSpinner : getIcon()}
          onClick={formProps.handleSubmit}
          disabled={
            isLoadingMessageTemplates
            || error
            || !isEmpty(formProps.errors)
            || isLoading
          }
        >
          {getButtonLabel()}
        </Button>
      </div>
    );
  };

  const getErrorActions: GetActions = ({ closeConfirmation }) => (
    <CancelButton onClick={closeConfirmation} modalKey={MODAL_KEY} />
  );

  const resetForm = () => {
    setContractMessageTemplates([]);
    setSelectedMessageTemplate(null);
    setIsLoadingMessageTemplates(false);
    setError(null);
    setIsSuccess(false);
    setShowSentForApprovalModal(false);
    setSendAutomaticallyAfterApproval(false);
  };

  const requestStartFlow = async (
    flowId: string,
    formData: {
      autoPublishAfterDraftApproval: boolean,
      subject: string,
      body: string,
    },
  ) => {
    try {
      const params: RequestParams.FlowStart = {};
      if (sendAutomaticallyAfterApproval) {
        params.autoPublishAfterDraftApproval = true;
        params.subject = formData.subject;
        params.message = formData.body;
      }
      setShowSentForApprovalModal(true);
      const data = await startFlow(flowId, params);
      const updatedAgreement = data.entities.agreements[agreementId];
      dispatch(agreements.setAgreements({
        [agreementId]: {
          draftApprovalFlow: updatedAgreement.draftApprovalFlow,
          config: updatedAgreement.config,
          events: updatedAgreement.events,
        },
      }));
      setIsSuccess(true);
    } catch (requestError) {
      setError(requestError);
      setIsLoading(false);
    }
  };

  const handleSubmit = (formData: FormData) => {
    setIsLoading(true);
    requestStartFlow(agreement.draftApprovalFlow.id, formData);
  };

  const getMessageBody = () => {
    if (position.signature && !isEmpty(selectedMessageTemplate) && selectedMessageTemplate.body) {
      return `${selectedMessageTemplate.body}\n\n${position.signature}`;
    }

    if (position.signature) {
      return position.signature;
    }

    if (selectedMessageTemplate && selectedMessageTemplate.body) {
      return selectedMessageTemplate.body;
    }

    return undefined;
  };

  const fetchMessageTemplates = async () => {
    try {
      setIsLoadingMessageTemplates(true);
      setError(null);

      const { collection: messageTemplates } = await getContractMessageTemplates({
        contractId: agreement.id,
        type: 'publish',
      });

      setContractMessageTemplates(messageTemplates);
      setSelectedMessageTemplate(null);
      setIsLoadingMessageTemplates(false);
    } catch (requestError) {
      setContractMessageTemplates([]);
      setIsLoadingMessageTemplates(false);
      setError(requestError);
    }
  };

  const renderBody = () => {
    if (isLoadingMessageTemplates) {
      return (
        <div className={style.SpinnerContainer}>
          <CircularSpinner />
        </div>
      );
    }

    if (error) {
      return (
        <span className={style.ErrorMessage}>
          <Error className={style.ErrorIcon} />
          <Message
            id="An error has occurred. Please reload the page and try again."
            comment="Used to show the error message after confirmation."
          />
        </span>
      );
    }

    if (isSuccess) {
      return (
        <DocumentSentBody
          isManualSend={!sendAutomaticallyAfterApproval}
        />
      );
    }

    return (
      <SendContractBody
        agreement={agreement}
        contractMessageTemplates={contractMessageTemplates}
        selectedMessageTemplate={selectedMessageTemplate}
        updateSelectedMessageTemplate={setSelectedMessageTemplate}
        signature={position.signature}
        setSendAutomaticallyAfterApproval={setSendAutomaticallyAfterApproval}
        sendAutomaticallyAfterApproval={sendAutomaticallyAfterApproval}
        isSubjectFieldRequired={sendAutomaticallyAfterApproval}
        isMessageFieldRequired={sendAutomaticallyAfterApproval}
        isApprovalFlow
      />
    );
  };

  const renderTitle = () => {
    if (isSuccess) {
      return (
        <Message
          id="Document sent"
          comment="Used as the title in modal"
        />
      );
    }

    return (
      <Message
        id="Send document"
        comment="Confirm modal title for sending a document for approval."
      />
    );
  };

  return (
    <ModalForm
      title={renderTitle()}
      body={renderBody()}
      actions={getActions}
      errorActions={getErrorActions}
      onSubmit={(formData) => handleSubmit(formData)}
      onClose={resetForm}
      onOpen={fetchMessageTemplates}
      initialValues={{
        subject: selectedMessageTemplate ? selectedMessageTemplate.subject : undefined,
        body: getMessageBody(),
      }}
      modalKey={MODAL_KEY}
      customModalClass={style.ModalBody}
    >
      {children}
    </ModalForm>
  );
};

export default SendDraftForApprovalComponent;
