import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { KPI } from 'components/kpis/kpi/kpi-container';

type Props = {
  message: MessageTranslator,
  count: number,
  value: number,
}

export const KPISentComponent = ({ message, count, value }: Props) => (
  <KPI
    label={message({
      id: 'Sent',
      comment: 'Dashboard KPI for sent contracts',
    })}
    type="Sent"
    count={count}
    tooltip={message({
      id: 'The number of contracts that were sent during the selected date range.',
      comment: 'Dashboard KPI tooltip message',
    })}
    agreementValueAmount={value}
  />
);

export default localize<Props>(KPISentComponent);
