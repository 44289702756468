import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'lodash';

import { DOCUMENT_TAB } from 'agreement/constants';

import { getCollapseLayoutActiveTabNameSelector, setCollapsedLayoutActiveTabName } from 'reducers/app';

import { DocumentTabValue } from '../types';

type CollapsedLayoutContextType = {
  activeTab: DocumentTabValue;
  setActiveTab: (tabName: DocumentTabValue) => void;
  bottomFloatingContainerRef: React.RefObject<HTMLDivElement | null>;
  mentionParticipantsSelectorRef: React.RefObject<HTMLDivElement>;
  isRecipientSelectorOpen: boolean;
  setIsRecipientSelectorOpen: (isOpen: boolean) => void;
};

const defaultContextValue: CollapsedLayoutContextType = {
  activeTab: DOCUMENT_TAB,
  setActiveTab: noop,
  bottomFloatingContainerRef: { current: null },
  mentionParticipantsSelectorRef: { current: null },
  isRecipientSelectorOpen: false,
  setIsRecipientSelectorOpen: noop,
};

const CollapsedLayoutContext = createContext<CollapsedLayoutContextType | null>(null);

type Props = {
  children: ReactNode;
};

export const CollapsedDocumentLayoutProvider = ({
  children,
}: Props) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(getCollapseLayoutActiveTabNameSelector);
  const bottomFloatingContainerRef = useRef<HTMLDivElement | null>(null);
  const mentionParticipantsSelectorRef = useRef<HTMLDivElement>(null);

  const [isRecipientSelectorOpen, setIsRecipientSelectorOpen] = useState<boolean>(false);

  const setActiveTab = useCallback((tabName: DocumentTabValue) => {
    dispatch(setCollapsedLayoutActiveTabName(tabName));
  }, [dispatch]);

  const contextValue = useMemo(() => ({
    activeTab,
    setActiveTab,
    bottomFloatingContainerRef,
    mentionParticipantsSelectorRef,
    isRecipientSelectorOpen,
    setIsRecipientSelectorOpen,
  }), [
    activeTab,
    setActiveTab,
    isRecipientSelectorOpen,
  ]);

  return (
    <CollapsedLayoutContext.Provider value={contextValue}>
      {children}
    </CollapsedLayoutContext.Provider>
  );
};

export const useCollapsedDocumentLayout = (): CollapsedLayoutContextType => {
  const contextValue = useContext(CollapsedLayoutContext);

  if (!contextValue) {
    return defaultContextValue;
  }

  return contextValue;
};
