import { ReactNode } from 'react';

import useDetachedModalRenderer, { unmountDetachedModal } from 'hooks/use-detached-modal-renderer';

import SendContractModal from 'components/modals/send-contract';
import { ApproversLastModal } from 'components/modals/approvers-last';

export type Props = {
  agreement: Oneflow.Agreement;
  children: () => ReactNode,
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
};

export const PendingApprovalModal = ({
  agreement,
  children,
  isOpen,
  setIsOpen,
}: Props) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const renderSendModal = () => (
    <SendContractModal
      isOpen
      agreementId={agreement.id}
      context="agreementPublish"
      onModalClose={unmountDetachedModal}
    />
  );

  const triggerSendModal = useDetachedModalRenderer(renderSendModal);

  const onContinue = () => {
    triggerSendModal();
    setTimeout(handleClose, 0);
  };

  return (
    <ApproversLastModal
      onClose={handleClose}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onContinue={onContinue}
    >
      {typeof children === 'function' ? () => children() : () => null}
    </ApproversLastModal>
  );
};
