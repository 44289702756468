import * as React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import Button from 'components/button';
import FolderIcon from 'components/icons/folder';
import FolderPathIcon from 'components/icons/folder-path';
import SmallWorkspaceIcon from 'components/icons/small-workspace';

import style from './bread-crumb.module.scss';

export type Props = {
  id: number;
  isBreadCrumbInFolderCard: boolean;
  isFolderBreadCrumb?: boolean;
  isLastBreadCrumbLink?: boolean;
  isSearchedFolderBreadcrumb?: boolean;
  label: React.ReactNode;
  onClickHandler?: () => void;
  showAsLink?: boolean;
  to: string;
};

const BreadCrumbLink = ({
  id,
  isBreadCrumbInFolderCard,
  isFolderBreadCrumb,
  isLastBreadCrumbLink,
  isSearchedFolderBreadcrumb,
  label,
  onClickHandler,
  showAsLink = true,
  to,
}: Props) => {
  const renderLabel = () => <span className={style.FolderName}>{label}</span>;

  const renderLink = () => {
    if (onClickHandler) {
      return (
        <Button className={style.LinkButton} onClick={onClickHandler}>
          {renderLabel()}
        </Button>
      );
    }

    if (showAsLink && to) {
      return (
        <Link className={style.Link} to={to}>
          {label}
        </Link>
      );
    }

    const textClasses = clsx(style.Text, {
      [style.Last]: isLastBreadCrumbLink,
    });

    return <span className={textClasses}>{label}</span>;
  };

  const renderFolderIcon = () => {
    if (isSearchedFolderBreadcrumb && id === -1 && !isBreadCrumbInFolderCard) {
      return (
        <SmallWorkspaceIcon
          height="12px"
          width="12px"
          className={style.WorkspaceIcon}
        />
      );
    }
    if (isSearchedFolderBreadcrumb && id !== -1) {
      return (
        <FolderPathIcon
          height="12px"
          width="12px"
          className={style.FolderPathIcon}
        />
      );
    }
    if (isFolderBreadCrumb && id !== -1) {
      return <FolderIcon className={style.BreadCrumbFolderIcon} />;
    }

    return null;
  };

  const renderLinkDivider = () => {
    const dividerClasses = clsx(style.Divider, {
      [style.FolderBreadCrumbDivider]: isFolderBreadCrumb,
      [style.RemoveDivider]: isLastBreadCrumbLink,
    });

    return <span className={dividerClasses}>/</span>;
  };

  return (
    <>
      {renderFolderIcon()}
      {renderLink()}
      {renderLinkDivider()}
    </>
  );
};

export default BreadCrumbLink;
