// @flow

import { connect } from 'react-redux';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';

import {
  getAgreementCounterparties,
  getEnabledParticipants,
} from 'agreement/selectors';
import { sortByType } from 'agreement/participant';
import agreements from 'reducers/entities/agreements';
import { getDateFormat } from 'date';

import { ContractCard } from './contract-card';

type OwnProps = {
  agreement: Agreement,
};

export const mapStateToProps = (state: State, { agreement }: OwnProps) => {
  const account = getAccountFromSessionSelector(state);
  const workspace = getCurrentWorkspaceSelector(state);
  const position = getPositionFromSessionSelector(state);

  const brandingDateFormat = workspace.brandingDateFormat || account.brandingDateFormat;
  return {
    workspaceId: workspace.id,
    position,
    counterparties: getAgreementCounterparties(agreement),
    participants: sortByType(getEnabledParticipants(agreement)),
    dateFormat: getDateFormat(brandingDateFormat),
    fetchLinksRpcState: agreements.getFetchContractLinksSelector(state, { id: agreement.id }),
    fetchInternalRemindersRpcState: agreements.getFetchContractInternalRemindersSelector(state, {
      id: agreement.id,
    }),
  };
};

type DispatchProps = {|
  fetchLinks: () => void,
  fetchInternalReminders: () => void,
|};

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, ownProps) => ({
  fetchInternalReminders: () => {
    dispatch(agreements.fetchContractInternalReminders({
      id: ownProps.agreement.id,
      data: {
        workspaceId: ownProps?.agreement?.collection?.id,
        createdBy: ['me', 'others'],
        eventTypes: ['user'],
        agreementId: ownProps.agreement.id,
      },
    }));
  },
  fetchLinks: () => {
    dispatch(agreements.fetchContractLinks({
      id: ownProps.agreement.id,
    }));
  },
});

const ConnectedContractCard = connect<any, State, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ContractCard);

export { ConnectedContractCard as ContractCard };
