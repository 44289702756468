// @flow

import { connect } from 'react-redux';

import {
  getPositionFromSessionSelector,
  logout,
} from 'reducers/session';

import { ForgetPassword } from './forget-password';

export const mapStateToProps = (state: State) => {
  const position = getPositionFromSessionSelector(state);

  return {
    position,
  };
};

export const mapDispatchToProps = (dispatch: any) => ({
  logout: () => {
    dispatch(logout('/recover-account'));
  },
});

const connectedForgetPassword = connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);

export { connectedForgetPassword as ForgetPassword };
