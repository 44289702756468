// @flow

import React from 'react';
import { Message, type MessageTranslator } from '@oneflowab/pomes';

import { Link } from 'react-router-dom';

import adminPage from 'hocs/admin-page';

import ActionsMenu from 'components/actions-menu';
import { DeleteBulkAction } from 'components/bulk-action-items/delete';
import { checkAcl } from 'components/acl';
import Button from 'components/button';
import ActionBar from 'components/action-bar';
import { ActionBarText } from 'components/action-bar-text';
import AddMembersIcon from 'components/icons/add-members';
import NoGroupMemberIcon from 'components/icons/no-groupmember';
import Conditional from 'components/conditional';
import Pagination from 'components/pagination';

import RemoveUserFromGroup from 'components/modals/remove-user-from-group';
import { RemoveUserFromGroups } from 'components/modals/remove-user-from-groups';
import AssignUserToGroups from 'components/modals/assign-user-to-groups';
import { DeleteMenuItem } from 'components/menu-items/delete';

import { Table } from 'components/table';

import style from './user-groups.module.scss';

type Props = {
  groups: Array<Group>,
  queryPositionGroups: (args: QueryFuncArgs) => void,
  query: Query,
  acl: Object,
  position: Position,
  message: MessageTranslator,
}

type State = {
  selectedGroups: Array<Group>,
}

export class UserGroupsComponent extends React.Component<Props, State> {
  state = {
    selectedGroups: [],
  };

  componentDidMount() {
    this.props.queryPositionGroups({ pagination: this.props.query.pagination });
  }

  getRemoveMenuItem = (position: Position) => (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!checkAcl(position.acl, 'position:group:remove')}
    />
  );

  getDeleteBulkAction = (onClick: () => void) => {
    const { position } = this.props;

    return (
      <DeleteBulkAction
        disabled={!checkAcl(position.acl, 'position:group:remove')}
        onClick={onClick}
      />
    );
  }

  getTableConfig() {
    const {
      groups,
      position,
      message,
    } = this.props;
    const { selectedGroups } = this.state;

    const actions = [
      <RemoveUserFromGroups
        position={position}
        selectedGroups={selectedGroups}
        key="remove"
      >
        {this.getDeleteBulkAction}
      </RemoveUserFromGroups>,
    ];

    return {
      items: groups,
      itemKey: 'id',
      actions,
      columns: [
        {
          name: 'name',
          label: message({ id: 'Group name', comment: 'A label for the relevant field in users-group page' }),
          type: 'cell',
          value: (item) => (
            <Link to={`/admin/groups/${item.id}`} className={style.Name}>
              {item.name}
            </Link>
          ),
        },
        {
          name: 'actions',
          label: message({ id: 'Actions', comment: 'A label for the relevant field in users-group page' }),
          type: 'actions',
          value: (item) => (
            <ActionsMenu
              actions={[
                <RemoveUserFromGroup position={position} group={item}>
                  {this.getRemoveMenuItem(position)}
                </RemoveUserFromGroup>,
              ]}
              focusOnCloseDisabled
            />
          ),
        },
      ],
    };
  }

  getEmptyState() {
    const { message } = this.props;
    const Icon = NoGroupMemberIcon;
    const emptyStateContent = (
      <div>
        <Message
          id="Add the user to a group to grant access."
          comment="Empty state information, shown for users without access to any groups"
        />
      </div>
    );

    return {
      header: message({
        id: 'This user is not a member of any group',
        comment: 'Empty state header for the group membership list in the user pages.',
      }),
      icon: <Icon height="33px" />,
      content: emptyStateContent,
      showEmptyState: this.shouldShowEmptyState(),
    };
  }

  selectionChangeHandler = (selectedGroupIds: Array<number>) => {
    const { groups } = this.props;

    const selectedGroups = selectedGroupIds.map((id) => (
      groups.find((group) => group.id === id)
    ));

    this.setState({ selectedGroups });
  }

  getAssignUserToGroupsButton = (position, acl) => (onClick: () => void) => {
    const hasCreateGroupsPermission = !checkAcl(acl, 'position:group:create');

    return (
      <Button
        data-testid="add-user-to-groups"
        icon={AddMembersIcon}
        kind="primary"
        onClick={onClick}
        disabled={hasCreateGroupsPermission}
      >
        <Message
          id="Add to groups"
          comment="Button text for adding user to groups."
        />
      </Button>
    );
  };

  shouldShowEmptyState() {
    const {
      query,
    } = this.props;

    return query.count === 0 && !query.loading;
  }

  changeHandler({ offset, limit }) {
    this.props.queryPositionGroups({
      pagination: {
        offset,
        limit,
      },
    });
  }

  render() {
    const {
      query,
      position,
      message,
      acl,
    } = this.props;

    return (
      <div className={style.Groups}>
        <ActionBar>
          <ActionBar.Group>
            <AssignUserToGroups position={position}>
              {this.getAssignUserToGroupsButton(position, acl?.position)}
            </AssignUserToGroups>
            <ActionBarText
              header={(
                <Message
                  id="Organize access to your contracts and templates"
                  comment="Header"
                />
              )}
              text={(
                <Message
                  id="By adding users into a group, you can easily manage access to workspaces for all users in the group."
                  comment="Help text for the groups tab in the user pages."
                />
              )}
            />
          </ActionBar.Group>
        </ActionBar>
        <Table
          config={this.getTableConfig()}
          query={query}
          onSelectionChanged={this.selectionChangeHandler}
          emptyState={this.getEmptyState()}
          bulkActionsSpacing
        />
        <Conditional ifCondition={!this.shouldShowEmptyState()}>
          <Pagination
            totalItems={query.count}
            itemsPerPage={query.pagination.limit}
            currentOffset={query.pagination.offset}
            onChange={(params) => this.changeHandler(params)}
            entityName={message({
              id: 'groups',
              comment: 'Used in the pagination to show the name of the list being shown in the list',
            })}
          />
        </Conditional>
      </div>
    );
  }
}

export default adminPage(({ props: { message } }) => ({
  title: message({ id: 'Groups', comment: 'The page title' }),
  modules: [[]],
}))(UserGroupsComponent);
