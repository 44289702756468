// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Reminder from 'components/icons/reminder';

const RenewalReminder = () => (
  <>
    <Reminder height="12px" />
    <span>
      <Message
        id="Renewal reminder"
        comment="An insight for lifecycle events that reminds users that a contract is renewed or will renew."
      />
    </span>
  </>
);

export default RenewalReminder;
