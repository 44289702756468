// @flow

import * as React from 'react';
import { useFormState, useForm } from 'react-final-form';

import createReducer from 'utils/create-react-reducer';

type InitialState = {
  validatedRecipients: Array<Position>,
  userConsent: 'waiting' | 'given' | 'reverted'
}

export const initialState: InitialState = {
  validatedRecipients: [],
  userConsent: 'waiting',
};

// Missing state machine here (xstate) that prevents unsafe transition between states
const validationReducer = createReducer({
  'user/givenConsent': (state) => ({
    ...state,
    userConsent: 'given',
  }),
  'recipients/validated': (state, action) => ({
    ...state,
    validatedRecipients: action.validatedRecipients,
  }),
  'validation/revert': (state) => ({
    ...state,
    validatedRecipients: [],
    userConsent: 'reverted',
  }),
  'validation/reset': () => initialState,
});

function useRecipientsValidation() {
  // $FlowFixMe
  return React.useReducer(validationReducer, {
    validatedRecipients: [],
    userConsent: 'waiting',
  });
}

export const useResetFormValuesRecipientsValidation = () => {
  const { change: formValueChange } = useForm();
  const { values } = useFormState();

  return React.useCallback((recipients: Array<Position> = values?.recipients) => {
    if (recipients.every((recipient) => !recipient.needsAccess)) {
      return;
    }

    formValueChange(
      'recipients',
      recipients.map((recipient) => ({
        ...recipient,
        needsAccess: false,
      })),
    );
  }, [formValueChange, values?.recipients]);
};

export default useRecipientsValidation;
