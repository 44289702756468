import { Message } from '@oneflowab/pomes';

import { checkAcl } from 'components/acl';
import Button from 'components/button';
import Tooltip from 'components/tooltip';
import Confirmable from 'components/confirmable';
import { CancelButton } from 'components/buttons';
import WarningIcon from 'components/icons/warning';

import style from './extension-sidebar-main-action.module.scss';

export type Props = {
  extension: Oneflow.Extension;
  onStateChange: () => void;
  updateState: { loading: boolean; success: boolean; error: string };
  resetUpdateState: (arg0: number) => void;
};

const ExtensionSidebarMainActionComponent = ({
  extension, onStateChange, updateState, resetUpdateState,
}: Props) => {
  const renderDisableButton = () => {
    const isEnabled = extension.state === true;
    const canDisableAcl = checkAcl(extension.acl, 'extension:disable');

    if (!isEnabled) {
      return <></>;
    }

    // Happy path, can disable
    if (canDisableAcl) {
      const getActions = ({ closeConfirmation }: { closeConfirmation: () => void }) => (
        <>
          <CancelButton onClick={closeConfirmation} />
          <Button
            data-testid="confirm"
            kind="primary"
            color="red"
            onClick={onStateChange}
            trackable={{
              name: 'Disable Marketplace Card',
              props: {
                location: 'marketplace',
                key: extension.key,
              },
            }}
          >
            <Message id="Disable" comment="Confirm button text in the disable extension modal" />
          </Button>
        </>
      );

      const getChildren = (onClick: () => void) => (
        <Button
          kind="cancel"
          customClass={style.DisableAction}
          onClick={onClick}
          disabled={updateState.loading}
        >
          <Message
            id="Disable"
            comment="Button text to disable an extension"
          />
        </Button>
      );
      return (
        <Confirmable
          header={(
            <Message
              id="Disable {extensionName}"
              comment="Modal title for disabling an extension."
              values={{ extensionName: extension.name }}
            />
        )}
          success={updateState.success}
          body={(
            <div className={style.ConfirmableBody}>
              <WarningIcon />
              {extension.disableText && (
                <p>{extension.disableText}</p>
              )}
              <p>
                <Message
                  id="Are you sure?"
                  comment="Text in a modal for confirming disabling an extension."
                />
              </p>
              <p>
                <Message
                  id="You can re-enable it at any time."
                  comment="Text in a modal for confirming disabling an extension."
                />
              </p>
            </div>
          )}
          actions={getActions}
          error={updateState.error}
          onEnter={onStateChange}
          onClose={resetUpdateState}
          onOpen={resetUpdateState}
        >
          {getChildren}
        </Confirmable>
      );
    }

    // This simply means for whatever reason, you can't disable.
    // - User (acl is false) or can_disable is true
    // - Admin where can_disable is true
    // regardless, we simply say can not be disabled,
    // since contacting your admin to disable is strange.
    return (
      <Tooltip
        message={(
          <Message
            id="Can not be disabled"
            comment="Tooltip message for disabling an extension when something blocks the action"
          />
        )}
        side="top"
      >
        <Button
          kind="cancel"
          customClass={style.DisableAction}
          disabled
        >
          <Message
            id="Disable"
            comment="Button text to disable an extension"
          />
        </Button>
      </Tooltip>
    );
  };

  const renderEnableButton = () => {
    const { state, canEnable } = extension;
    const isEnabled = state === true;
    const canEnableAcl = checkAcl(extension.acl, 'extension:enable');
    const canPurchaseAcl = checkAcl(extension.acl, 'extension:purchase');

    const contactSalesHref = 'https://oneflow.com/oneflow-contact';

    if (isEnabled) {
      return <></>;
    }

    // Happy path, can enable
    if (canEnableAcl) {
      if (extension.enableText) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const textWithLinks = extension.enableText.split(urlRegex).map((part) => {
          if (urlRegex.test(part)) {
            return (
              <a
                key={part}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                className={style.Link}
              >
                {part}
              </a>
            );
          }
          return part;
        });
        const getActions = ({ closeConfirmation }: { closeConfirmation: () => void }) => (
          <>
            <CancelButton onClick={closeConfirmation} />
            <Button
              data-testid="confirm"
              kind="primary"
              onClick={onStateChange}
              trackable={{
                name: 'Enable Marketplace Card',
                props: {
                  location: 'marketplace',
                  key: extension.key,
                },
              }}
            >
              <Message
                id="Enable"
                comment="Button text to enable an extension"
              />
            </Button>
          </>
        );

        const getChildren = (onClick: () => void) => (
          <Button
            kind="send"
            customClass={style.EnableAction}
            onClick={onClick}
            disabled={updateState.loading}
          >
            <Message
              id="Enable"
              comment="Button text to disable an extension"
            />
          </Button>
        );
        return (
          <Confirmable
            header={(
              <Message
                id="Enable {extensionName}"
                comment="Modal title for enabling an extension."
                values={{ extensionName: extension.name }}
              />
        )}
            success={updateState.success}
            body={(
              <div className={style.ConfirmableBody}>
                <p>{textWithLinks}</p>
                <p>
                  <Message
                    id="Are you sure?"
                    comment="Text in a modal for confirming disabling an extension."
                  />
                </p>
              </div>
          )}
            actions={getActions}
            error={updateState.error}
            onEnter={onStateChange}
            onClose={resetUpdateState}
            onOpen={resetUpdateState}
          >
            {getChildren}
          </Confirmable>
        );
      }
      return (
        <Button
          kind="send"
          customClass={style.EnableAction}
          onClick={onStateChange}
          disabled={updateState.loading}
          trackable={{
            name: 'Enable Marketplace Card',
            props: {
              location: 'marketplace',
              key: extension.key,
            },
          }}
        >
          <Message
            id="Enable"
            comment="Button text to enable an extension"
          />
        </Button>
      );
    }

    // An admin who can enable but is not entitled will see this message
    if (canPurchaseAcl) {
      return (
        <Button
          kind="send"
          href={contactSalesHref}
          external
          target="_blank"
          rel="noopener"
          trackable={{
            name: 'Contact Sales',
            props: {
              location: 'marketplace',
              key: extension.key,
            },
          }}
        >
          <Message
            id="Contact sales"
            comment="Button text to purchase an extension"
          />
        </Button>
      );
    }

    // Anyone who does not have permissions but extension can be enabled will see the same message
    // This means a user will see this message regardless if the account is entitled or not
    // as both paths goes through an admin
    if (canEnable && !canEnableAcl) {
      return (
        <Tooltip
          message={(
            <Message
              id="Contact your administrator to enable"
              comment="Tooltip message for enabling an extension when something blocks the action"
            />
          )}
          side="left"
        >
          <Button
            kind="send"
            customClass={style.EnableAction}
            disabled
          >
            <Message
              id="Enable"
              comment="Button text to purchase an extension"
            />
          </Button>
        </Tooltip>
      );
    }

    // Can not enable due to the configuration value, rarely used so generic error message
    if (!canEnable) {
      return (
        <Tooltip
          message={(
            <Message
              id="Can not be enabled"
              comment="Tooltip message for enabling an extension when something blocks the action"
            />
          )}
          side="left"
        >
          <Button
            kind="send"
            customClass={style.EnableAction}
            disabled
          >
            <Message
              id="Enable"
              comment="Button text to disable an extension"
            />
          </Button>
        </Tooltip>
      );
    }

    return <></>;
  };

  const renderTermsInformation = () => {
    const { terms } = extension;

    if (!terms) {
      return null;
    }

    return (
      <div className={style.TermsContainer}>
        <a
          href={terms.url}
          target="_blank"
          rel="noopener noreferrer"
          className={style.Link}
        >
          {terms.title}
        </a>
      </div>
    );
  };

  return (
    <>
      {renderEnableButton()}
      {renderDisableButton()}
      {renderTermsInformation()}
    </>
  );
};

export default ExtensionSidebarMainActionComponent;
