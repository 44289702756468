import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { isEmpty } from 'lodash';
import { isPending } from 'agreement';
import {
  getMyParticipantWhenSignatory,
  canBeSigned,
  isMyParticipantTurnToSign,
  getGuestToken,
} from 'agreement/selectors';
import isUndecided from 'agreement/participant/is-undecided';
import { getClientFeatureFlagSelector } from 'reducers/app';

import agreementsReducer from 'reducers/entities/agreements';
import SignContract, { Props } from './sign-contract';

type StateProps = {
  agreement: Oneflow.Agreement | Record<any, any>,
  myParticipantWhenSignatory: Oneflow.Participant | null | undefined,
  guestToken?: string,
  canSign: boolean | null | undefined
};

export const canBeSignedByMyParticipant = (
  agreement: Oneflow.Agreement,
  myParticipant?: Oneflow.Participant | null,
) => {
  const agreementCanBeSigned = canBeSigned(agreement);

  return agreementCanBeSigned
    && myParticipant
    && isMyParticipantTurnToSign(agreement, myParticipant)
    && isUndecided(myParticipant)
    && isPending(agreement);
};

type OwnProps = Omit<Props, keyof StateProps> & {
  agreementId: number
};

type DispatchProps = {
  fetchAgreement: (arg1: any) => void
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state: State, {
  agreementId,
}: OwnProps) => {
  const agreement = agreementsReducer.getAgreementSelector(state, { id: agreementId }) || {};
  const guestToken = getGuestToken(state);
  const myParticipantWhenSignatory = !isEmpty(agreement)
    ? getMyParticipantWhenSignatory(agreement) : {};

  return {
    agreement,
    guestToken,
    canSign: !isEmpty(agreement)
      && canBeSignedByMyParticipant(agreement, myParticipantWhenSignatory),
    myParticipantWhenSignatory,
  };
};
type MapDispatchToProps = (dispatch: Dispatch<any>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  agreementId,
}) => ({
  fetchAgreement: (guestToken) => {
    dispatch(agreementsReducer.fetchAgreement({
      id: agreementId,
      params: { guestToken },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<any>>(
  mapStateToProps, mapDispatchToProps,
)(SignContract);
