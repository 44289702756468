// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  formatDateString,
  getDaysUntil,
} from 'date';

import CalendarSimple from 'components/icons/calendar-simple';

import Insight from './insight';

import style from './insight.module.scss';

type Props = {
  expireDate: string | null,
  dateFormat: string,
};

const Pending = ({ expireDate, dateFormat }: Props) => {
  const renderInsight = () => {
    if (!expireDate) {
      return (
        <Message
          id="Signing period doesn't expire"
          comment="Insight for pending contract with signing period without expiry date. Visible in contract card."
        />
      );
    }

    const daysUntil = getDaysUntil(expireDate);
    const dateString = formatDateString(expireDate, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    if (daysUntil === 0) {
      return (
        <Message
          id="Signing is possible until today ({date})"
          values={{
            date,
          }}
          comment="Insight for pending contract, stating when it will become overdue. Visible in contract card."
        />
      );
    }

    if (daysUntil > 7) {
      return (
        <Message
          id="Signing is possible until {date}"
          values={{
            date,
          }}
          comment="Insight for pending contract, stating when it will become overdue. Visible in contract card."
        />
      );
    }

    if (daysUntil > 0) {
      return (
        <Message
          id="Signing is possible until tomorrow ({date})"
          pluralId="Signing is possible for {days} more days ({date})"
          pluralCondition="days"
          values={{
            days: daysUntil,
            date,
          }}
          comment="Insight for pending contract, stating when it will become overdue. Visible in contract card."
        />
      );
    }

    return null;
  };

  return (
    <Insight
      IconComponent={CalendarSimple}
      insight={renderInsight()}
    />
  );
};

export default Pending;
