import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import times from 'lodash/times';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { getTableColumnCount } from './block-matrix';

const minimumColumnWidth = 20;

export const setTableColumns = ({
  editor,
  tableElement,
  columns,
}) => {
  Transforms.setNodes(editor, {
    columns,
  }, {
    at: ReactEditor.findPath(editor, tableElement),
  });
};

export const getResizedColumns = ({
  tableElement,
  columnIndex,
  colgroupRef,
}) => {
  if (columnIndex === -1) {
    return null;
  }
  const colElement = colgroupRef?.current?.childNodes[columnIndex];
  const nextColElement = colgroupRef?.current?.childNodes[columnIndex + 1];

  if (!colElement || !nextColElement) {
    return null;
  }

  const columnCount = getTableColumnCount(tableElement);
  return times(columnCount).map((index) => {
    const currentColumn = get(tableElement?.columns, index) || {};

    if (index === columnIndex) {
      return {
        ...currentColumn,
        width: Number(colElement.width),
      };
    }

    if (index === columnIndex + 1) {
      return {
        ...currentColumn,
        width: Number(nextColElement.width),
      };
    }

    return {
      ...currentColumn,
    };
  });
};

export const resizeTableColumns = ({
  editor,
  tableElement,
  columnIndex,
  colgroupRef,
}) => {
  const columns = getResizedColumns({
    tableElement,
    columnIndex,
    colgroupRef,
  });

  if (!columns) {
    return;
  }

  setTableColumns({
    editor,
    tableElement,
    columns,
  });
};

export const resizeTableColumnRefs = ({
  colgroupRef,
  columnIndex,
  width,
  nextColumnWidth,
}) => {
  const colElement = colgroupRef?.current?.childNodes[columnIndex];
  const nextColElement = colgroupRef?.current?.childNodes[columnIndex + 1];

  if (
    !colElement
    || !nextColElement
    || width < minimumColumnWidth
    || nextColumnWidth < minimumColumnWidth
  ) {
    return;
  }

  colElement.width = width;
  nextColElement.width = nextColumnWidth;
};

export const addEmptyColumn = ({
  editor,
  tableElement,
  columnIndex,
}) => {
  const columns = Array.isArray(tableElement.columns) && [...tableElement.columns];
  if (isEmpty(columns)) {
    return;
  }

  columns.splice(columnIndex, 0, { width: 100 });

  setTableColumns({
    editor,
    tableElement,
    columns,
  });
};

export const removeColumn = ({
  editor,
  tableElement,
  columnIndex,
}) => {
  const columns = Array.isArray(tableElement.columns) && [...tableElement.columns];
  if (isEmpty(columns)) {
    return;
  }

  columns.splice(columnIndex, 1);

  setTableColumns({
    editor,
    tableElement,
    columns,
  });
};
