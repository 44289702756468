import { Message } from '@oneflowab/pomes';

/* eslint-disable import/prefer-default-export */
const CancellationReasonList = [
  {
    reference: 'dont_use_enough',
    label:
  <Message
    id="We don't use Oneflow enough"
    comment="Cancellation reason"
  />,
    id: 1,
    enabled: true,
  },
  {
    reference: 'difficult_to_use',
    label:
  <Message
    id="Oneflow is too difficult to use"
    comment="Cancellation reason"
  />,
    id: 2,
    enabled: true,
  },
  {
    reference: 'missing_features_or_integrations',
    label:
  <Message
    id="There are missing features or integrations"
    comment="Cancellation reason"
  />,
    id: 3,
    enabled: true,
  },
  {
    reference: 'found_an_alternative',
    label:
  <Message
    id="We have found an alternative"
    comment="Cancellation reason"
  />,
    id: 4,
    enabled: true,
  },
  {
    reference: 'too_expensive',
    label:
  <Message
    id="It's too expensive"
    comment="Cancellation reason"
  />,
    id: 5,
    enabled: true,
  },
  {
    reference: 'business_is_closing',
    label:
  <Message
    id="Our business is closing"
    comment="Cancellation reason"
  />,
    id: 6,
    enabled: true,
  },
  {
    reference: 'other',
    label:
  <Message
    id="Other"
    comment="Cancellation reason"
  />,
    id: 7,
    enabled: true,
  },
];

export default CancellationReasonList;
