// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './extension-content-faqs.module.scss';

type Props = {
  extension: Extension,
};

export const ExtensionContentFaqs = ({ extension }: Props) => {
  if (!extension.faqs) {
    return null;
  }

  return (
    <>
      <h3 className={style.ExtensionContentHeader}>
        <Message
          id="FAQ"
          comment="Section header for an extension details page."
        />
      </h3>
      <div className={style.ExtensionContentBody}>
        {extension.faqs.map((faq) => (
          <div key={faq.question} className={style.FaqContainer}>
            <div className={style.FaqQuestion}>
              {faq.question}
            </div>
            <div className={style.FaqAnswer}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
