// @flow

import { connect } from 'react-redux';

import { getPositionFromSessionSelector } from 'reducers/session';

import Ghosting from './ghosting';

type StateProps = {|
  email: string,
|};

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  email: getPositionFromSessionSelector(state).email,
});

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Ghosting);
