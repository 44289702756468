import style from './affixed-price.module.scss';

type Props = {
  prefix?: string | undefined;
  formattedPrice: string | undefined;
  postfix?: string | undefined;
};

const AffixedPrice = ({ prefix, formattedPrice, postfix }: Props) => (
  <>
    {prefix && (
      <span data-testid="price-prefix" className={style.Prefix}>
        {prefix}
      </span>
    )}
    <span data-testid="price-formatted" className={style.FormattedPrice}>
      {formattedPrice}
    </span>
    {postfix && (
      <span data-testid="price-postfix" className={style.Postfix}>
        {postfix}
      </span>
    )}
  </>
);

export default AffixedPrice;
