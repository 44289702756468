import { useParams } from 'react-router-dom';
import { Route } from 'react-router';

import DocumentLoader from 'components/agreement-loader';
import Switch from 'components/switch';
import DocumentLayoutContainer from 'components/document-layout-container/document-layout-container';
import PreviewDocument from 'components/document-layout-container/preview-document/preview-document';
import useFeatureFlag from 'hooks/use-feature-flag';

type RouteParams = {
  id: string;
}

const DocumentRoutes = () => {
  const { id } = useParams<RouteParams>();
  const agreementId = Number.parseInt(id, 10);
  const isDocumentPreviewEnabled = useFeatureFlag('temporaryDocumentPreview');

  return (
    <DocumentLoader
      agreementId={agreementId}
    >
      <Switch>
        {isDocumentPreviewEnabled && <Route path={['/documents/:id/preview', '/documents/templates/:id/preview']} component={PreviewDocument} />}
        <Route component={DocumentLayoutContainer} />
      </Switch>
    </DocumentLoader>
  );
};

export default DocumentRoutes;
