import { useDispatch } from 'react-redux';
import { compact } from 'lodash';

import useAgreement from 'hooks/use-agreement';
import { updateBoxConfigAction, updatePriceColumnsAction } from 'reducers/current-contract';
import type { Box } from 'data-validators/entity-schemas/document-box';

import Rules from 'components/modals/rules';
import { getId } from 'components/contract-boxes/generic-box-helpers';

import { getDataFields, getInitialValues } from './helpers';

type Props = {
  agreementId: number;
  box: Box;
  onClose: () => void;
};

const RulesModal = ({ box, agreementId, onClose }: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);

  const dataFields = getDataFields(agreement);
  const initialValues = getInitialValues(box);

  const handleSaveRule = ({
    condition,
    dataField,
    value,
  }: {
    condition: string;
    dataField: string;
    value: string;
  }) => {
    const fieldName = `data.data_fields.${dataField}`;
    const values = compact([{ var: fieldName }, value]);

    const config = {
      visibility: {
        default: false,
        rule: {
          [condition]: values,
        },
        consistencyRule: {
          '!=': [fieldName, null],
        },
      },
    };

    dispatch(updateBoxConfigAction(getId(box), config));
    dispatch(updatePriceColumnsAction());
    onClose();
  };

  const handleResetRule = () => {
    const { visibility } = box.config;

    if (visibility) {
      dispatch(updateBoxConfigAction(getId(box), { visibility: null }));
      dispatch(updatePriceColumnsAction());
    }

    onClose();
  };

  return (
    <Rules
      onClose={onClose}
      dataFields={dataFields}
      onSubmit={handleSaveRule}
      resetRule={handleResetRule}
      initialValues={initialValues}
      box={box}
      agreement={agreement}
    />
  );
};

export default RulesModal;
