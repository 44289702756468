// @flow

import * as React from 'react';
import { Route } from 'react-router';
import type { Match } from 'react-router';
import urlJoin from 'url-join';

import type {
  ComponentProps, Parent,
} from './admin-page';

type Props = {
  path: string,
  pageTitle: Array<string>,
  tabs: Array<AdminPageTab>,
  route: AdminPageModule,
  match: Match,
  parents?: Array<Parent> | void,
};

const PageRoute = (props: Props) => {
  const {
    path,
    parents,
    pageTitle,
    tabs,
    match,
    route,
  } = props;
  const { component, ...restRouteProps } = route;

  if (route.isExternal) {
    return null;
  }

  const Component: React.ComponentType<ComponentProps> = route.component;

  return (
    <Route path={path}>
      {(routeProps) => (
        <Component
          {...restRouteProps}
          parents={parents}
          pageTitle={pageTitle}
          tabs={tabs}
          path={urlJoin(match.url, route.path)}
          {...routeProps}
        />
      )}
    </Route>
  );
};

PageRoute.defaultProps = {
  parents: [],
};

export default PageRoute;
