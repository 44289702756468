import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import BankIDLogoIcon from 'components/icons/bankid-logo';
import BankIDIcon from 'components/icons/bankid';
import CircularSpinnerIcon from 'components/icons/circular-spinner';

import { BankIDSigningDeviceType } from '../types';
import { SWEDISH_BANKID_SIGNING_DEVICE } from '../constants';

import style from './swedish-bankid-flow.module.scss';

type Props = {
  device: BankIDSigningDeviceType
  autoStartToken: string
}

const PendingSignature = ({ device, autoStartToken }: Props) => (
  <>
    <div className={style.IconWrapper}>
      <BankIDLogoIcon height="160px" />
    </div>
    <div className={style.BankIDInformation}>
      <div className={style.BankIDPendingSignature}>
        <CircularSpinnerIcon height="34px" />
        <div>
          {device === SWEDISH_BANKID_SIGNING_DEVICE.OTHER && (
            <p>
              <Message
                id="Waiting for response from BankID."
                comment="Pending signature information waiting for user to sign with BankID"
              />
            </p>
          )}
          {device === SWEDISH_BANKID_SIGNING_DEVICE.SAME && (
            <p>
              <Message
                id="If the BankID app doesn't start automatically, you can start it by pressing the button below:"
                comment="Pending signature instruction to manually start BankID app"
              />
            </p>
          )}
        </div>
      </div>
      {device === SWEDISH_BANKID_SIGNING_DEVICE.SAME && autoStartToken ? (
        <div className={style.PendingSignatureManualStart}>
          <Button
            kind="secondary"
            onClick={() => window.open(`bankid:///?autostarttoken=${autoStartToken}&redirect=${encodeURIComponent(`${window.location.href}?refresh=${new Date().getTime()}`)}`, '_self')}
            data-testid="open-bankid-btn"
            icon={<BankIDIcon width="16px" />}
            hasIconRight
          >
            <Message
              id="Open BankID"
              comment="Pending signature button to start BankID app manually if it doesn't start automatically"
            />
          </Button>
        </div>
      ) : null}
      <div className={style.BankIDInstructionsPendingSignature}>
        <p>
          <Message
            id="If you close this window signing will be cancelled."
            comment="Pending signature information about closing the window/modal"
          />
        </p>
      </div>
    </div>
  </>
);

export default PendingSignature;
