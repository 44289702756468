// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import UploadContractImageModal from 'components/modals/upload-contract-image';
import ImageIcon from 'components/icons/image';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { insertImage } from './image-plugin';
import ToolbarButton from '../toolbar-button';

type Props = {
  isMenuItem: boolean,
  disabled: boolean,
};

const ImageButton = ({ isMenuItem, disabled }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  const onInsertImage = (src) => {
    insertImage(editor, src, editor.selection);
  };

  return (
    <UploadContractImageModal
      onInsertImage={onInsertImage}
      onModalClose={focusEditor}
    >
      {(onMouseDown) => (
        <ToolbarButton
          label={(
            <Message
              id="Insert image"
              comment="The label of the editor's toolbar plugin that inserts an image."
            />
          )}
          isMenuItem={isMenuItem}
          disabled={disabled}
          onTrigger={onMouseDown}
          icon={<ImageIcon />}
        />
      )}
    </UploadContractImageModal>
  );
};

export default ImageButton;
