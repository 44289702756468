// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const GroupCreateEvent = ({ auditLogEvent }: Props) => (
  <Message
    id="The group {groupName} was {jsx-start}created{jsx-end}"
    comment="Audit log event message for added group"
    component="span"
    className={style.Green}
    values={{
      groupName: auditLogEvent.target.name,
    }}
  />
);

export default GroupCreateEvent;
