import Svg from 'components/svg';

export const DTrustSignMe = Svg((
  <g>
    <g fillRule="evenodd">
      <path
        fill="#008bd2"
        d="M480.41 162.34h-13.27v-57.78h12.51v8.2l.48.13c.43-.52.87-1 1.29-1.57 4.77-6 11-9.16 18.72-9a35.28 35.28 0 016.66.63 15.68 15.68 0 0110.59 8l1.39 2.45c.73-.8 1.31-1.42 1.87-2.06 4-4.49 8.73-7.79 14.79-8.68 5-.74 10.06-.69 14.79 1.63a14.82 14.82 0 018.77 12.8c.15 1.75.23 3.52.23 5.27v38.15c0 .55 0 1.11-.09 1.81H545.8v-2.68c0-10.72 0-21.44-.17-32.15a32.68 32.68 0 00-1-6.87c-1-4.33-3.39-6.37-7.87-6.79a24.3 24.3 0 00-6.2.2 10.57 10.57 0 00-8.54 7.21 36.08 36.08 0 00-1.95 12.49c0 8.64 0 17.28-.06 25.91v2.71h-13.4c0-.82-.11-1.66-.11-2.49 0-8.32.07-16.64 0-25a87.78 87.78 0 00-.74-11.47c-1.09-7.54-5.38-10.61-12.94-9.63a13.4 13.4 0 00-12 12.39 93.65 93.65 0 00-.41 8.38c-.05 8.32 0 16.64 0 25z"
      />
      <path fill="#004f82" d="M194 0l17.82 9.47q-31.36 59-62.69 117.9l-17.82-9.48z" />
      <path
        fill="#b2b2b2"
        d="M11.46 186.76h37.85c.16 3.92.06 7.62 0 11.5H0V51c1.65-.52 45.55-.69 49.2-.18v11.32H11.46z"
      />
      <path
        fill="#004f82"
        d="M352.71 115.5v-9h9.46c.05.58.15 1.19.15 1.8v55.89c0 2.23-.23 4.47-.43 6.69-.51 5.61-3.32 9.91-7.67 13.3a26.45 26.45 0 01-13.18 5.3c-6.81.87-13.57.76-20.11-1.66-8.32-3.08-13.51-10.09-13.86-18.65 0-.47 0-.94.07-1.48h10.67c.07.71.16 1.33.2 2 .34 4.87 2.85 8.18 7.26 10.13a23.66 23.66 0 0011 1.58 28.05 28.05 0 004.74-.68c6.5-1.54 10.77-6 11.45-12.56.49-4.75.28-9.58.36-14.37 0-.11-.16-.22-.4-.54-.39.52-.75 1-1.1 1.47-5.52 7.57-13.06 10.41-22.2 9.47-12.82-1.33-21.1-11-23-23.13a40 40 0 011.43-19.42c4.33-12.49 15.12-18.51 28.05-17a21 21 0 0116 10.18c.19.31.42.59.64.89zm-37 18.43h.25c.07 1.35.09 2.71.22 4.06 1 10.11 7.07 16.23 16.87 16.92 10.29.72 17.51-4.7 19.33-14.82a34.67 34.67 0 00.27-8.58c-.85-11.52-9.52-18.71-21-17.58-7.34.73-12.65 5.06-14.61 12.21a76.76 76.76 0 00-1.3 7.79z"
      />
      <path
        fill="#008bd2"
        d="M618.66 141.41l11.05 6.09c-.32.65-.53 1.23-.84 1.74-6.39 10.38-15.59 15.76-27.87 15.66a37 37 0 01-11.12-1.44 26.29 26.29 0 01-17.18-15.58 36.18 36.18 0 01-.69-26.26 28.77 28.77 0 0126.66-19.28 41.65 41.65 0 0111 .88c10.9 2.68 17.37 9.66 19.87 20.44a41.45 41.45 0 01.92 11.74c-2.58.12-5.11 0-7.63 0h-38.58c-.81 7.83 5 15.71 12.46 16.78a30 30 0 008.57-.12 16 16 0 0010.81-6.93c.84-1.13 1.61-2.3 2.57-3.72zm-33.8-15.09h31.4a12.64 12.64 0 00-9-11.83 21 21 0 00-12.26-.06c-5.83 1.78-8.62 6.17-10.14 11.89z"
      />
      <path
        fill="#b2b2b2"
        d="M187 68.88v129.17c-1.86.48-46.49.58-49.27.1v-11.21c6.24-.33 12.52-.09 18.79-.13s12.46 0 19 0c.05-1.07.13-2 .13-2.91v-71.74-20.87a6.24 6.24 0 01.5-2.77c3.45-6.59 7-13.13 10.48-19.68z"
      />
      <path
        fill="#004f82"
        d="M427.76 162.32a6.32 6.32 0 01-1 .2h-8.92v-2.67c0-10.08.05-20.15 0-30.23a33.15 33.15 0 00-.81-6.87c-1.19-5.4-4.59-8.67-10.1-9.51-6.24-.95-11.95.14-16.55 4.85a12.62 12.62 0 00-3.61 7.66 67.31 67.31 0 00-.42 6.94v29.66h-9.48v-56h9.33v8.21a13.2 13.2 0 001.09-1c5.86-7.6 13.82-9.92 23-8.91a24.68 24.68 0 016.92 1.79c6 2.58 9.29 7.35 10.32 13.72a28.63 28.63 0 01.36 4.53v35.74c-.04.57-.09 1.21-.13 1.89zM227.54 153l8.13-5.42 1.41 2.13c4.19 6.16 10.51 7.59 16.61 6.26a10.42 10.42 0 004.54-2.49 7 7 0 00-.9-11.15 41.64 41.64 0 00-6.27-2.92c-3.52-1.51-7.14-2.81-10.57-4.49a32.28 32.28 0 01-6-4c-7-5.65-5.57-14.06-2.07-18.94a17.16 17.16 0 019.49-6.57 27.37 27.37 0 0118.1.89 16.71 16.71 0 019.56 9.29l-7.77 4.73c-.43-.68-.75-1.21-1.09-1.73a13.74 13.74 0 00-15.28-5.59 8 8 0 00-5.77 5.86 5.12 5.12 0 001.6 5.66 24.4 24.4 0 005.25 3.27c2.24 1 4.68 1.61 7 2.48 2.76 1 5.53 2.09 8.21 3.31 11.81 5.36 9.9 20.36 3.38 26.06a20.29 20.29 0 01-10.92 5.07 33.14 33.14 0 01-13.79-.9A20.82 20.82 0 01227.54 153z"
      />
      <path fill="#008bd2" d="M126.87 153.83v8.83h-66.1v-8.83z" />
      <path
        fill="#004f82"
        d="M282.69 106.42h9.68v56c-.62 0-1.17.13-1.71.13h-8zM461.5 139.36h-28.56V130h28.56z"
      />
      <path
        fill="#b2b2b2"
        d="M137.66 62.13V50.69h22.62c-.64 1.28-1.16 2.33-1.71 3.37-1.19 2.26-2.36 4.53-3.64 6.75-.32.55-1 1.26-1.5 1.27-5.18.09-10.37.05-15.77.05z"
      />
      <path
        fill="#004f82"
        d="M129.6 142.37c-.25-6.94-.47-13.43-.72-20.39l18.05 9.58zM287.42 86.52a7.15 7.15 0 117.13-7.1 7 7 0 01-7.13 7.1z"
      />
    </g>
  </g>
), {
  viewBox: '0 0 630.5 198.46',
});

DTrustSignMe.displayName = 'DTrustSignMe';
