// @flow

import { connect } from 'react-redux';

import positionGroups from 'reducers/entities/position-groups';

import { RemoveUserFromGroups } from './remove-user-from-groups';

export const requestID = 'bulk';

type StateProps = {|
  removeState: RemoveState,
|};

type OwnProps = $Diff<Props, StateProps>;

type DispatchProps = {|
  removeUserFromGroups: () => void,
|};

type MapStateToProps = (state: State) => StateProps;

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  removeState: positionGroups.getRemoveSelector(state, { id: requestID }),
});

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  position,
  selectedGroups,
}) => ({
  removeUserFromGroups: () => {
    const selectedGroupIds = selectedGroups.map((group) => group.id);

    dispatch(positionGroups.removePositionGroup({
      id: requestID,
      data: {
        positionId: position.id,
        groupIds: selectedGroupIds,
      },
      pipe: {
        action: () => positionGroups.queryPositionGroupsReload({
          name: `admin/users/${position.id}/groups`,
        }),
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(positionGroups.removePositionGroupReset({
      id: requestID,
    }));
  },
});

const connectedRemoveUserFromGroups = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveUserFromGroups);

export { connectedRemoveUserFromGroups as RemoveUserFromGroups };
