// @flow

import React from 'react';
import { type MessageTranslator } from '@oneflowab/pomes';
import { MFA_SMS } from 'components/mfa-channel-type';

type Props = {
  position: Position,
  message: MessageTranslator,
};

export const MfaTooltipMessage = ({ position, message }: Props) => {
  const mfaAuthMessage = message({
    id: 'Two-step authentication',
    comment: 'Tooltip message for two-step authentication.',
  });

  let mfaType = message({
    id: 'Email',
    comment: 'Two-step authentication type shown in tooltip.',
  });

  if (position.mfaChannel === MFA_SMS) {
    mfaType = message({
      id: 'SMS',
      comment: 'Two-step authentication type shown in tooltip.',
    });
  }

  return (
    <>
      {`${mfaAuthMessage}: `}
      <strong>{mfaType}</strong>
    </>
  );
};
