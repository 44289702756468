// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import NewCross from 'components/icons/new-cross';

const Ended = () => (
  <>
    <NewCross height="12px" />
    <span>
      <Message
        id="Ended"
        comment="An insight for lifecycle events that indicates that a contract is ended."
      />
    </span>
  </>
);

export default Ended;
