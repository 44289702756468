// @flow

import React from 'react';
import clsx from 'clsx';
import { difference } from 'lodash';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import { useUserEvents } from 'contexts/user-events';
import { checkAcl } from 'components/acl';
import InternalReminderCreateSelectedContract from 'components/modals/lifecycle-events/internal-reminder/internal-reminder-create-selected-contract';

import getInternalReminders from 'utils/get-internal-reminders';
import isPastEvent from 'utils/is-past-event';

import SmallAddIcon from 'components/icons/small-add';
import MiniInternalReminderCard from './mini-internal-reminder-card/mini-internal-reminder-card';

import style from './contract-internal-reminders-list.module.scss';

type Props = {
  contractInternalReminderEvents: Array<any>,
  message: MessageTranslator,
  agreement: Agreement,
};

const ContractInternalRemindersList = ({
  contractInternalReminderEvents,
  message,
  agreement,
}: Props) => {
  const hasAccessToInternalRemindersButton = checkAcl(agreement.acl, 'agreement:internal_reminder:create');
  const { currentUser, refetchInternalReminders } = useUserEvents();
  const userEvents = getInternalReminders(contractInternalReminderEvents, currentUser, agreement);
  const pastInternalReminders = userEvents.filter((reminderEvent) => isPastEvent(
    reminderEvent.sourceEvent.date,
  ));

  const upcomingInternalReminders = difference(userEvents, pastInternalReminders);

  const renderMiniInternalReminderCards = (internalRemindersList) => internalRemindersList
    .map((internalReminderEvent) => (
      <MiniInternalReminderCard
        key={internalReminderEvent.id}
        contractInternalReminderEvent={internalReminderEvent.sourceEvent}
        message={message}
        agreement={agreement}
      />
    ));

  const groupedInternalReminderCards = [
    {
      label: message({
        id: 'Upcoming',
        comment: 'Label for upcoming contract internal reminder events',
      }),
      condition: upcomingInternalReminders.length > 0,
      render: () => (
        <>
          <p data-testid="upcoming" className={style.InternalReminderTenseLabel}>
            <Message
              id="Upcoming"
              comment="Label for upcoming internal reminder events for contract"
            />
          </p>
          {renderMiniInternalReminderCards(upcomingInternalReminders)}
        </>
      ),
    },
    {
      label: message({
        id: 'Past',
        comment: 'Label for past contract internal reminder events',
      }),
      condition: pastInternalReminders.length > 0,
      render: () => (
        <>
          <p
            data-testid="past"
            className={clsx(
              style.InternalReminderTenseLabel,
              style.PastInternalRemindersLabel,
            )}
          >
            <Message
              id="Past"
              comment="Label for past contract internal reminder events"
            />
          </p>
          {renderMiniInternalReminderCards(pastInternalReminders)}
        </>
      ),
    },
  ];

  const renderCreateInternalReminderButton = () => {
    if (!hasAccessToInternalRemindersButton) {
      return null;
    }

    return (
      <div className={style.CreateInternalReminderFromContractCard}>
        <InternalReminderCreateSelectedContract
          contract={agreement}
          icon={<SmallAddIcon height="10px" />}
          refetchInternalReminders={refetchInternalReminders}
        >
          <Message
            id="Create internal reminder"
            comment="Confirm modal title for creating a contract reminder."
          />
        </InternalReminderCreateSelectedContract>
      </div>
    );
  };

  return (
    <div className={style.ContractInternalReminderEventsListContainer}>
      {groupedInternalReminderCards.length > 0 && (
        <article className={style.ContractReminderEventsListHeader}>REMINDERS</article>
      )}
      <div className={style.ContractInternalReminderCards}>
        {groupedInternalReminderCards.map((internalReminderCard) => internalReminderCard.condition
          && (
            <React.Fragment key={`${String(internalReminderCard.label)}`}>
              {internalReminderCard.render()}
            </React.Fragment>
          ))}
      </div>
      {renderCreateInternalReminderButton()}
    </div>
  );
};

export default localize<Props>(ContractInternalRemindersList);
