// @flow

import React from 'react';

import extensionSettings from 'hocs/extension-settings';

import GeneratedToken from 'components/extension/generated-token';
import { ExtensionGenericInstructions } from 'components/extension/extension-generic-instructions';

type Props = {
  extension: Extension,
};

export const AdockaPage = ({ extension }: Props) => (
  <>
    <GeneratedToken extension={extension} />

    <ExtensionGenericInstructions extension={extension} />

  </>
);

export default extensionSettings(AdockaPage);
