import zod from 'zod';
import { integerBoolean } from 'data-validators/utils';
import Entity, { NumberIdSchema } from './entity';

const messageOwnerParticipant = NumberIdSchema.describe('MessageOwnerParticipant');
const messageParent = NumberIdSchema.describe('MessageParent');

const Message = Entity.extend({
  body: zod.string().nullish(),
  type: zod.number().nullish(),
  justPosted: zod.boolean().nullish(),
  toParticipantIds: zod.array(zod.unknown()).nullish(),
  agreement: zod.object({
    id: zod.number().nullish(),
    checksum: zod.string().nullish(),
  }).nullish(),
  created: zod.string().nullish(),
  createdTs: zod.number().nullish(),
  isResolvable: zod.boolean().nullish(),
  ownerParticipant: messageOwnerParticipant.nullish(),
  parent: messageParent.nullish(),
  private: integerBoolean.nullish(),
  subject: zod.string().nullish(),
  toParticipants: zod.array(zod.unknown()).nullish(),
  updated: zod.string().nullish(),
  updatedTs: zod.number().nullish(),
});

export default Message;
