// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';
import type { FormRenderProps } from 'react-final-form';
import { getCountryListAsOptions } from 'components/countries';
import clsx from 'clsx';

import ModalForm from 'hocs/modal-form';
import {
  NextButton,
  PreviousButton,
} from 'components/buttons';

import Field from 'components/field';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';

import style from './freemium-self-service-billing.module.scss';

export type FormData = {|
  address: string,
  city: string,
  country: Option,
  invoiceEmail: string,
  name: string,
  invoiceReference: string,
  vatNumber: string,
  zipCode: string,
  period?: number,
|};

type ModalData = {|
  ...FormData,
  numberOfSeats: number,
  monthlyBilling: boolean,
|};

export type Props = {
  message: MessageTranslator,
  languageCode: EnabledLanguages,
  onStepComplete: () => void,
  onSyncStepData: Object => void,
  modalData: {| numberOfSeats: number |} | ModalData,
  updateState: UpdateState,
  resetUpdateState: (id: number) => void,
  onSubmit: (id: number, formData: FormData) => void;
  onPreviousStep: () => void,
  onClose: () => void,
  account: Account,
  position: Position,
  modalKey: string,
};

export class FreemiumSelfServiceBillingComponent extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { updateState, onStepComplete } = this.props;

    if (!prevProps.updateState.success && updateState.success) {
      onStepComplete();
    }
  }

  handleResetUpdateState = () => {
    const { account, resetUpdateState } = this.props;

    resetUpdateState(account.id);
  }

  handleSubmit = (formData: FormData) => {
    const {
      modalData,
      onSyncStepData,
      onSubmit,
      account,
    } = this.props;

    if (formData) {
      const period = modalData.monthlyBilling ? 1 : 12;

      onSyncStepData({ ...modalData, ...formData });
      onSubmit(account.id, { ...formData, period });
    }
  };

  getActions = ({ formProps }: FormRenderProps) => {
    const { updateState, onPreviousStep } = this.props;

    const disabled = Boolean(
      updateState.loading
      || updateState.error
      || formProps.validating
      || formProps.invalid,
    );

    return (
      <div className={style.Buttons}>
        <PreviousButton onClick={onPreviousStep} />
        <NextButton
          onClick={formProps.handleSubmit}
          disabled={disabled}
          isLoading={updateState.loading}
        />
      </div>
    );
  };

  renderInvoiceInformation() {
    const { message, position } = this.props;

    return (
      <>
        <h2>
          <Message
            id="Invoice details"
            comment="Heading for fields where user inputs invoice information."
          />
        </h2>
        <div className={style.Row}>
          <Field
            name="invoiceEmail"
            autoFocus
            label={message({
              id: 'Email',
              comment: 'Label for invoice email field.',
            })}
            component={TextField}
            placeholder={message({
              id: 'Enter email',
              comment: 'Placeholder for invoice email field.',
            })}
            maxLength={70}
            initialValue={position?.email}
            email
            required
          />
          <Field
            name="invoiceReference"
            label={message({
              id: 'Reference',
              comment: 'Label for invoice reference field.',
            })}
            component={TextField}
            placeholder={message({
              id: 'Enter reference',
              comment: 'Label for invoice reference field.',
            })}
            maxLength={100}
            initialValue={position?.fullname}
            required
          />
        </div>
        <div className={style.Row}>
          <i>
            <p className={style.Note}>
              <Message
                id="Note: If you need e-invoicing, please contact us after activating your Subscription Plan."
                comment="Instruction for users who dont want to be billed by regular mail."
              />
            </p>
          </i>
        </div>
      </>
    );
  }

  renderBillingInformation() {
    const { message, languageCode, account } = this.props;

    const countries = getCountryListAsOptions(languageCode);
    const currentCountry = countries.find((country) => country.value === account.country);

    return (
      <>
        <h2>
          <Message
            id="Billing details"
            comment="Heading for fields where user inputs billing information."
          />
        </h2>
        <div className={style.Row}>
          <Field
            name="name"
            label={message({
              id: 'Company name',
              comment: 'Label for billing company name field.',
            })}
            component={TextField}
            placeholder={message({
              id: 'Enter name',
              comment: 'Placeholder for billing company name field.',
            })}
            initialValue={account?.name}
            maxLength={100}
            required
          />
          <Field
            name="vatNumber"
            label={message({
              id: 'VAT number',
              comment: 'Label for billing vat number field.',
            })}
            component={TextField}
            placeholder={message({
              id: 'Enter number',
              comment: 'Placeholder for billing vat number field.',
            })}
            maxLength={50}
            initialValue={account?.orgnr}
            required
          />
        </div>
        <div className={style.Row}>
          <Field
            name="address"
            label={message({
              id: 'Address',
              comment: 'Label for billing address field.',
            })}
            component={TextField}
            placeholder={message({
              id: 'Enter address',
              comment: 'Placeholder for billing address field.',
            })}
            maxLength={100}
            required
          />
          <Field
            name="zipCode"
            label={message({
              id: 'ZIP code',
              comment: 'Label for zip code field.',
            })}
            component={TextField}
            placeholder={message({
              id: 'Enter number',
              comment: 'Placeholder for zip code field.',
            })}
            maxLength={12}
            required
          />
        </div>
        <div className={clsx(style.Row, style.FirstChildFixedHeight)}>
          <Field
            name="city"
            label={message({
              id: 'City',
              comment: 'Label for billing city field.',
            })}
            component={TextField}
            placeholder={message({
              id: 'Enter name',
              comment: 'Placeholder for billing city field.',
            })}
            maxLength={100}
            required
          />
          <Field
            name="country"
            label={message({
              id: 'Country',
              comment: 'Label for billing country field.',
            })}
            component={SelectField}
            placeholder={message({
              id: 'Select country',
              comment: 'Placeholder for billing country field.',
            })}
            initialValue={currentCountry}
            options={countries}
            searchable
            required
          />
        </div>
      </>
    );
  }

  render() {
    const {
      updateState, modalData, onClose, modalKey,
    } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Activate your Subscription Plan"
            comment="Message shown in upgrade account modal."
          />
        )}
        body={(
          <div>
            {this.renderInvoiceInformation()}
            {this.renderBillingInformation()}
          </div>
        )}
        actions={this.getActions}
        formState={updateState}
        resetFormState={this.handleResetUpdateState}
        onSubmit={this.handleSubmit}
        initialValues={modalData}
        isOpen
        onClose={onClose}
        modalKey={modalKey}
      />
    );
  }
}

export default localize<Props>(FreemiumSelfServiceBillingComponent);
