/* eslint-disable react/display-name */
/* eslint-disable import/named */
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Message from 'components/message';
import type { MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';
import groupsReducer from 'reducers/entities/groups';
import { getAccountFromSessionSelector } from 'reducers/session';

import GroupPage from 'routes/admin/group';
import { Workspaces } from 'routes/admin/group/columns/workspaces';
import { Roles } from 'routes/admin/group/columns/roles';
import { Members } from 'routes/admin/group/columns/members';

import ActionBar from 'components/action-bar';
import { ActionBarText } from 'components/action-bar-text';
import ActionsMenu from 'components/actions-menu';
import { checkAcl } from 'components/acl';
import AddMembersIcon from 'components/icons/add-members';
import GrantGroupAccessIcon from 'components/icons/grant-group-access';
import Button from 'components/button';
import Conditional from 'components/conditional';
import Pagination from 'components/pagination';
import {
  Table,
  TableFiltering,
} from 'components/table';
import { DeleteBulkAction } from 'components/bulk-action-items/delete';
import { MenuItem } from 'components/menu-item';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { EditMenuItem } from 'components/menu-items/edit';
import { AddGroup } from 'components/modals/add-group';
import AddMember from 'components/modals/add-member';
import Add from 'components/icons/add';
import { RemoveGroup } from 'components/modals/remove-group';
import { DeleteGroups } from 'components/modals/delete-groups';
import GrantGroupWorkspaceAccess from 'components/modals/grant-group-workspace-access';
import GrantGroupAccountAccess from 'components/modals/grant-group-account-access';

import { push } from 'connected-react-router';
import style from './groups.module.scss';

type Props = {
  message: MessageTranslator,
};

type Params = {
  q?: string,
  accessStats?: number,
};

const QUERY_NAME = 'admin/groups';

export const GroupsComponent = ({
  message,
}: Props) => {
  const dispatch = useDispatch();
  const [selectedGroups, setSelectedGroups] = useState<Oneflow.Group[] | []>([]);

  const groupsQuery = useSelector(
    (state) => groupsReducer.getQuerySelector(state, { name: QUERY_NAME }),
  );
  const groups = useSelector(
    (state) => groupsReducer.getGroupsSelector(state, { ids: groupsQuery.result }),
  );
  const account = useSelector(getAccountFromSessionSelector);

  const queryGroups = useCallback(
    ({ pagination, params }: { pagination?: RequestParams.Pagination, params?: Params }) => {
      dispatch(groupsReducer.queryGroups({
        name: QUERY_NAME,
        pagination: {
          ...pagination,
          limit: 50,
        },
        params,
      }));
    }, [dispatch],
  );
  const editLinkPath = (groupId: number) => {
    const editLink = `/admin/groups/${groupId}/details/?edit=true`;

    dispatch(push(editLink));
  };

  useEffect(() => {
    queryGroups({ params: { accessStats: 1 } });
  }, [queryGroups]);

  const onFilter = (params: { q: string }) => {
    queryGroups({
      params: {
        ...params,
        accessStats: 1,
      },
    });
  };

  const getAddMembersToGroupMenuItem = (group: Oneflow.Group) => (onClick: () => void) => (
    <MenuItem
      disabled={!checkAcl(group.acl, 'group:position:create')}
      icon={AddMembersIcon}
      onClick={onClick}
      label={(
        <Message
          id="Add members"
          comment="label for adding members to a group"
        />
      )}
    />
  );

  const getDeleteMenuItem = (group: Oneflow.Group) => (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!checkAcl(group.acl, 'group:remove')}
    />
  );

  const getGrantWorkspaceAccessMenuItem = (
    group: Oneflow.Group,
  ) => (onClick: () => void) => {
    const hasWorkspacePermission = checkAcl(account?.acl, 'account:admin:workspace');

    if (!hasWorkspacePermission) {
      return null;
    }

    return (
      <MenuItem
        icon={GrantGroupAccessIcon}
        onClick={onClick}
        disabled={!checkAcl(group.acl, 'group:collection:binding:create')}
        label={(
          <Message
            id="Grant workspace access"
            comment="label for granting a group access to a workspace"
          />
        )}
      />
    );
  };

  const getGrantAccountAccessMenuItem = (onClick: () => void) => {
    const hasAccountRoleBindingPermission = checkAcl(
      account?.acl,
      [
        'account:group:custom_role_binding:create',
        'account:group:system_role_binding:create',
      ],
      { match: 'any' },
    );

    if (!hasAccountRoleBindingPermission) {
      return null;
    }

    return (
      <MenuItem
        icon={GrantGroupAccessIcon}
        onClick={onClick}
        label={(
          <Message
            id="Grant account access"
            comment="label for granting a group access to an account"
          />
        )}
      />
    );
  };

  const handleOnClick = (id: number) => () => editLinkPath(id);

  const getActions = (group: Oneflow.Group) => {
    let isEditDisabled = false;
    let isRemoveDisabled = false;

    const hasEditPermission = checkAcl(group.acl, 'group:update:name');
    isEditDisabled = !hasEditPermission;

    const hasRemovePermission = checkAcl(group.acl, 'group:remove');
    isRemoveDisabled = !hasRemovePermission;

    return (
      <ActionsMenu
        actions={[
          <EditMenuItem
            disabled={isEditDisabled}
            onClick={handleOnClick(group.id)}
            key="EditMenuItem"
          />,
          <AddMember shouldRedirectToMembers group={group} key="AddMember">
            {getAddMembersToGroupMenuItem(group)}
          </AddMember>,
          <GrantGroupAccountAccess
            group={group}
            shouldRedirectToAccountAccess
            renderedFromAccountAccessPage={false}
            key="GrantGroupAccountAccess"
          >
            {getGrantAccountAccessMenuItem}
          </GrantGroupAccountAccess>,
          <GrantGroupWorkspaceAccess
            group={group}
            onSuccess={() => {
              /*
              workaround to prevent modal error,
              to be removed once modal component has been replaced
              */
              setTimeout(() => {
                queryGroups({ params: { accessStats: 1 }, pagination: groupsQuery.pagination });
              }, 250);
            }}
            shouldRedirectToWorkspaceAccess
            key="GrantGroupWorkspaceAccess"
          >
            {getGrantWorkspaceAccessMenuItem(group)}
          </GrantGroupWorkspaceAccess>,
          <RemoveGroup
            disabled={isRemoveDisabled}
            group={group}
            key="RemoveGroup"
          >
            {getDeleteMenuItem(group)}
          </RemoveGroup>,
        ]}
        focusOnCloseDisabled
      />
    );
  };

  const getCreateGroupButton = (onClick: () => void) => {
    const hasCreatePermissions = checkAcl(account?.acl, 'account:group:create');
    const isDisabled = !hasCreatePermissions;

    return (
      <Button
        icon={Add}
        kind="primary"
        data-testid="create-group"
        onClick={onClick}
        disabled={isDisabled}
      >
        <Message
          id="Create group"
          comment="The text shown in the button for creating a group"
        />
      </Button>
    );
  };

  const getDeleteBulkAction = (onClick: () => void) => {
    const hasDeletePermission = selectedGroups.every((item) => (
      checkAcl(item.acl, 'group:remove')
    ));

    return (
      <DeleteBulkAction
        disabled={!hasDeletePermission}
        onClick={onClick}
      />
    );
  };

  const getTableConfig = () => {
    const selectedGroupIds = selectedGroups.map((group) => group.id);

    const actions = [
      <DeleteGroups
        selectedGroupIds={selectedGroupIds}
        key="remove"
      >
        {getDeleteBulkAction}
      </DeleteGroups>,
    ];

    return {
      items: groups,
      itemKey: 'id',
      actions,
      columns: [
        {
          name: 'group',
          autoComplete: false,
          label: message({
            id: 'Group name',
            comment: 'Column for group in groups table.',
          }),
          type: 'cell',
          value: (group: Oneflow.Group) => (
            <Link to={`/admin/groups/${group.id}`} className={style.MainRow}>
              {group.name}
            </Link>
          ),
        },
        {
          name: 'members',
          label: message({
            id: 'Members',
            comment: 'Column for members in groups table.',
          }),
          type: 'cell',
          value: (group: Oneflow.Group) => (
            <Link to={`/admin/groups/${group.id}/members`} className={style.MainRow}>
              <Members memberCount={group.memberCount} />
            </Link>
          ),
        },
        {
          name: 'roles',
          label: message({
            id: 'Account roles',
            comment: 'Column for account roles in groups table.',
          }),
          type: 'cell',
          value: (group: Oneflow.Group) => (
            <Link to={`/admin/groups/${group.id}/account-access`} className={style.MainRow}>
              <Roles accountAccessStats={group.accountAccessStats} />
            </Link>
          ),
        },
        {
          name: 'access',
          label: message({
            id: 'Workspace Access',
            comment: 'Column for workspace access in groups table.',
          }),
          type: 'cell',
          value: (group: Oneflow.Group) => (
            <Link to={`/admin/groups/${group.id}/workspace-access`} className={style.MainRow}>
              <Workspaces collectionAccessStats={group.collectionAccessStats} />
            </Link>
          ),
        },
        {
          name: 'actions',
          label: message({
            id: 'Actions',
            comment: 'Column for action buttons in groups table.',
          }),
          type: 'actions',
          value: getActions,
        },
      ],
    };
  };

  const handlePageChange = ({ offset, limit }: RequestParams.Pagination) => {
    queryGroups({
      params: groupsQuery.params,
      pagination: {
        offset,
        limit,
      },
    });
  };

  const selectionChangeHandler = (selectedGroupIds: Array<number>) => {
    const selectedItems = selectedGroupIds.map((id) => (
      groups.find((group) => group.id === id)
    )) as Oneflow.Group[];

    setSelectedGroups(selectedItems);
  };

  return (
    <div>
      <ActionBar collapsed>
        <ActionBar.Group>
          <AddGroup>
            {getCreateGroupButton}
          </AddGroup>
          <ActionBarText
            header={(
              <Message
                id="Manage group access to workspaces and the account"
                comment="Header"
              />
            )}
          />
        </ActionBar.Group>
      </ActionBar>
      <TableFiltering
        filters={[{
          type: 'text',
          name: 'search',
          defaultValue: '',
          queryKey: 'q',
          autoFocus: true,
          placeholder: message({
            id: 'Find groups',
            comment: 'Placeholder in search field.',
          }),
        }]}
        onFilterHandler={onFilter}
        loading={groupsQuery.loading}
      />
      <Table
        config={getTableConfig()}
        query={groupsQuery}
        onSelectionChanged={selectionChangeHandler}
      />
      <Conditional ifCondition={!groupsQuery.loading && groupsQuery.count > 0}>
        <Pagination
          totalItems={groupsQuery.count}
          itemsPerPage={groupsQuery.pagination.limit}
          currentOffset={groupsQuery.pagination.offset}
          onChange={handlePageChange}
          entityName={(
            <Message
              id="groups"
              comment="Plural form in pagination of groups."
            />
          )}
        />
      </Conditional>
    </div>
  );
};

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Groups',
    comment: 'Page title for the groups page.',
  }),
  modules: [[{
    path: '/:id(\\d+)',
    title: '',
    component: GroupPage,
  }]],
});

export default adminPage(propsMapper)(GroupsComponent);
