// @flow

import type { MessageTranslator } from '@oneflowab/pomes';

import {
  TYPE_NO_DURATION,
  TYPE_SINGLE_PERIOD,
  TYPE_RECURRING,
  TYPE_RECURRING_WITH_INITIAL,
  TYPE_NOT_SET,
} from 'agreement/constants';

const availableDurationTypes = [
  TYPE_NO_DURATION,
  TYPE_SINGLE_PERIOD,
  TYPE_RECURRING,
  TYPE_RECURRING_WITH_INITIAL,
];

export const durationTexts = (message: MessageTranslator) => ({
  [TYPE_NO_DURATION]: message({
    id: 'No duration',
    comment: 'Text for a duration type.',
  }),
  [TYPE_SINGLE_PERIOD]: message({
    id: 'Single period',
    comment: 'Text for a duration type.',
  }),
  [TYPE_RECURRING]: message({
    id: 'Recurring',
    comment: 'Text for a duration type.',
  }),
  [TYPE_RECURRING_WITH_INITIAL]: message({
    id: 'Recurring with two periods',
    comment: 'Text for a duration type.',
  }),
  [TYPE_NOT_SET]: message({
    id: 'Not set',
    comment: 'Text for a duration type.',
  }),
});

type GetDurationTypesAsOptions = (message: MessageTranslator) => Array<{
  label: string,
  value: number,
}>

export const getDurationTypesAsOptions: GetDurationTypesAsOptions = (message) => (
  availableDurationTypes.map((type) => ({
    label: durationTexts(message)[type],
    value: type,
  }))
);
