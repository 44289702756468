// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

export type Props = {
  dataField: DataField,
  removeState: RemoveState,
  resetRemoveState: Function,
  removeDataField: () => void,
  children: Function,
};

export const handleConfirm = (removeDataField: Function, isLoading: boolean) => () => {
  if (isLoading) {
    return;
  }

  removeDataField();
};

export const RemoveDataFieldConfirmation = ({
  removeState,
  removeDataField,
  dataField,
  children,
  resetRemoveState,
}: Props) => (
  <RemoveConfirm
    onConfirm={removeDataField}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <Message
        id="You are about to delete the {dataFieldName} data field. This action can't be undone."
        values={{
          dataFieldName: <Badge label={dataField.name} kind="name" />,
        }}
        comment="Modal text for deleting data field."
      />
      )}
    modalKey="delete data field modal"
  >
    {children}
  </RemoveConfirm>
);
