import clsx from 'clsx';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Select from '@radix-ui/react-select';
import { isEmpty } from 'lodash';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import Message from 'components/message';
import Tooltip from 'components/tooltip';
import WarningIcon from 'components/icons/alert';

import { UpChevronIcon, DownChevronIcon, SelectedIcon } from './icons';

import style from './data-field-selector.module.scss';

type Props = {
  name: string,
  value: string,
  dataFieldName: string,
  onValueChange: (value: string) => void,
  options: Array<DataFieldValue>
  isBroken: boolean
}

const sortOptionsByName = (
  options: Array<DataFieldValue>,
  currentLanguage: string,
) => {
  const collator = new Intl.Collator(currentLanguage, {
    numeric: true,
    sensitivity: 'variant',
  });

  const compare = (a: DataFieldValue, b: DataFieldValue) => {
    const nameA = a?.name || '';
    const nameB = b?.name || '';
    return collator.compare(nameA, nameB);
  };

  return options.sort(compare);
};

function DataFieldSelector({
  name,
  dataFieldName,
  value,
  onValueChange,
  options,
  isBroken,
}: Props) {
  const currentLanguage = useSelector(getCurrentLanguageSelector);
  const isBrokenAndEmpty = isBroken && isEmpty(options);
  const sortedOptions = useMemo(
    () => sortOptionsByName(options, currentLanguage),
    [options, currentLanguage],
  );
  const hideTooltip = (dataFieldName ?? '').length < 31;
  return (
    <Select.Root
      name={name}
      value={String(value)}
      onValueChange={onValueChange}
    >
      <Tooltip
        messageClassName={style.TooltipMessage}
        hideContent={hideTooltip}
        side="top"
        message={dataFieldName}
      >
        <Select.Trigger
          id="dataFieldSelect"
          className={style.SelectFieldTrigger}
          tabIndex={isBrokenAndEmpty ? -1 : 0}
          data-overlay-field-popover-cta
          disabled={isBrokenAndEmpty}
        >
          {isBroken ? (
            <div className={clsx(style.BrokenFieldTriggerLabel, style.SelectOption)}>
              <WarningIcon style={{ flexShrink: 0 }} height="14px" />
              <div className={style.OptionName}><Message id="Unlinked field" comment="Label for data field selector when overlay field does not have a valid data field link" /></div>
            </div>
          ) : (
            <Select.Value />
          )}
          {!isBrokenAndEmpty && (
          <Select.Icon asChild>
            <DownChevronIcon style={{ flexShrink: 0 }} width="10px" height="10px" />
          </Select.Icon>
          )}
        </Select.Trigger>
      </Tooltip>
      <Select.Content
        className={style.SelectContent}
        position="popper"
        sideOffset={10}
      >
        <Select.ScrollUpButton>
          <div className={style.ScrollButton}>
            <UpChevronIcon
              style={{ flexShrink: 0 }}
              width="10px"
              height="10px"
            />
          </div>
        </Select.ScrollUpButton>
        <Select.Viewport className={style.SelectViewPort}>
          {sortedOptions.map((option) => (
            <Select.Item
              key={option.id}
              value={String(option.key)}
              className={style.SelectItem}
            >
              <Select.ItemText>
                {/*
                  Same selected item will be rendered in <Select.Value />,
                  css will hide unnecessary part
                */}
                <div className={style.SelectOption}>
                  <Tooltip
                    messageClassName={style.TooltipMessage}
                    hideContent={hideTooltip}
                    side="top"
                    message={option.name}
                  >
                    <div className={style.OptionName}>{option.name}</div>
                  </Tooltip>
                  <SelectedIcon
                    className={style.SelectedIcon}
                    width="10px"
                    height="10px"
                  />
                </div>
              </Select.ItemText>
              <Select.ItemIndicator />
            </Select.Item>
          ))}
        </Select.Viewport>
        <Select.ScrollDownButton>
          <div className={style.ScrollButton}>
            <DownChevronIcon
              style={{ flexShrink: 0 }}
              width="10px"
              height="10px"
            />
          </div>
        </Select.ScrollDownButton>
      </Select.Content>
    </Select.Root>
  );
}

export default DataFieldSelector;
