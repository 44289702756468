import type { ReactNode } from 'react';

import Conditional from 'components/conditional';
import type { Acl as AclType } from 'types/acl';

import checkAcl from './check-acl';
import mapAclToPermissions from './map-acl-to-permissions';

type Props = {
  acl: AclType,
  check: string | string[],
  children: ReactNode,
  match?: 'all' | 'any',
};

const Acl = ({
  acl,
  check,
  children,
  match = 'all',
  ...restProps
}: Props) => (
  <Conditional
    ifCondition={checkAcl(mapAclToPermissions(acl), check, { match })}
    {...restProps}
  >
    {children}
  </Conditional>
);

export default Acl;
