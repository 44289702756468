import React from 'react';

import usePopupDialog from 'hooks/popup/use-popup-dialog';

import type { PopoverContentProps } from '@radix-ui/react-popover';
import type { DialogContentProps } from '@radix-ui/react-dialog';

type Props = {
  trigger: React.ReactNode,
  asChild?: boolean,
  disabled?: boolean,
  triggerClassName?: string,
  className?: string,
  content: React.ReactNode,
  popupType: 'popover' | 'dialog',
  isPortal: boolean,
  popoverContentProps?: PopoverContentProps;
  dialogContentProps?: DialogContentProps;
  restProps?: React.HTMLAttributes<HTMLDivElement>,
};

const Popup = ({
  trigger,
  asChild,
  disabled,
  content,
  triggerClassName,
  className,
  popoverContentProps,
  dialogContentProps,
  popupType = 'popover',
  isPortal,
  ...restProps
}: Props) => {
  const { Popup: PopupPrimitive, PopupContent } = usePopupDialog(popupType);

  return (
    <PopupPrimitive.Root>
      <PopupPrimitive.Trigger
        className={triggerClassName}
        disabled={disabled}
        asChild={asChild}
      >
        {trigger}
      </PopupPrimitive.Trigger>
      <PopupContent
        isPortal={isPortal}
        className={className}
        // PopupContent will only use one of the props (popover or dialog)
        popoverContentProps={popoverContentProps}
        dialogContentProps={dialogContentProps}
        {...restProps}
      >
        {content}
      </PopupContent>
    </PopupPrimitive.Root>
  );
};

export default Popup;
