// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getDataFields,
  createDataField,
  removeDataField,
  updateDataField,
} from 'oneflow-client/data-fields';
import type { NormalizedDataFields } from 'oneflow-client/data-fields';

import apiWrapper from 'sagas/api-wrapper';

import dataFieldsReducer from 'reducers/entities/data-fields';

type MapperArgs = {
  data: NormalizedDataFields,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(dataFieldsReducer.setDataFields(data.entities.dataFields));
}

type ClearMapperArgs = {
  data: {||},
  action: {
    id: number,
    type: string,
  },
};
export function* clearMapper({ action }: ClearMapperArgs): Mapper {
  yield put(dataFieldsReducer.clearDataField({ id: action.id }));
}

const mappers = {
  query: {
    mapper,
    request: getDataFields,
  },
  create: {
    mapper,
    request: createDataField,
  },
  remove: {
    mapper: clearMapper,
    request: removeDataField,
  },
  update: {
    mapper,
    request: updateDataField,
  },
};

const dataFieldsSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: dataFieldsReducer,
  mappers,
});

export default dataFieldsSaga;
