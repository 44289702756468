// @flow

import * as React from 'react';

import Button from 'components/button/button';

import style from './select-dropdown-option.module.scss';

type Props = {
  onSelect: Function,
  label: React.Node,
  IconComponent: React.ComponentType<IconProps>,
  id: Number,
};

const SelectDropdownOption = ({
  onSelect,
  label,
  IconComponent,
  id,
}: Props) => (
  <Button
    customClass={style.DropdownItem}
    icon={IconComponent}
    onClick={() => onSelect(id)}
  >
    {label}
  </Button>
);

export default SelectDropdownOption;
