import type { ReactNode } from 'react';

import styles from './preview-container.module.scss';

const PreviewContainer = ({ children }: {children: ReactNode}) => (
  <div className={styles.PreviewContainer}>
    {children}
  </div>
);

export default PreviewContainer;
