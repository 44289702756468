import React from 'react';
import clsx from 'clsx';

import style from './rich-text-editor-toolbar-divider.module.scss';

function RichTextEditorToolbarDivider() {
  return (
    <div
      className={clsx(
        'rich-text-toolbar-divider',
        style.RichTextEditorToolbarDivider,
      )}
    />
  );
}

export default RichTextEditorToolbarDivider;
