// @flow
import { Transforms } from 'slate';
import head from 'lodash/head';
import client from 'oneflow-client';
import {
  FILE_IMAGE_JPEG,
  FILE_IMAGE_PNG,
  FILE_IMAGE_BMP,
  FILE_IMAGE_GIF,
} from 'utils/file';

const acceptedFiles = [FILE_IMAGE_JPEG, FILE_IMAGE_PNG, FILE_IMAGE_BMP, FILE_IMAGE_GIF];

const upload = async (editor, contractId, file) => {
  const { url } = await client.uploadContractImage({ file, contractId });

  if (!editor.selection) {
    return;
  }

  Transforms.insertNodes(editor, {
    type: 'image',
    src: url,
    children: [{ text: '' }],
  }, { at: editor.selection });
};

/* eslint-disable no-param-reassign */
const makeWithContractImages = (contractId: number) => (editor: any) => {
  const { insertData } = editor;

  editor.insertData = (data) => {
    const { files } = data;

    if (files && files.length > 0) {
      const file = head(files);
      if (!acceptedFiles.map((acceptedFile) => acceptedFile.mimeType).includes(file.type)) {
        return;
      }

      upload(editor, contractId, file);
      return;
    }
    insertData(data);
  };

  return editor;
};

export default makeWithContractImages;
