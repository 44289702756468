import { DragOverlay } from '@dnd-kit/core';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { get } from 'lodash';
import type { MessageTranslator } from '@oneflowab/pomes';

import boxIcons from 'agreement/box-icons';
import boxTypes from 'agreement/box-types';
import useCurrentBox from 'hooks/use-current-box';

import DragHandlerIcon from 'components/icons/drag-handler';

import style from './drag-overlay.module.scss';

type Props = {
  activeId: UniqueIdentifier;
  message: MessageTranslator;
};

const DragOverlayComponent = ({ activeId, message }: Props) => {
  const box = useCurrentBox(activeId as number);

  if (!box) {
    return null;
  }

  const Icon = boxIcons[box.type];
  const text = get(boxTypes(message), box.type);

  return (
    <DragOverlay>
      <div className={style.DragOverlay}>
        <DragHandlerIcon height="15px" />
        <p className={style.Text}>
          {text}
        </p>
        <Icon height="20px" />
      </div>
    </DragOverlay>
  );
};

export default DragOverlayComponent;
