import * as React from 'react';
import isFunction from 'lodash/isFunction';
import partialRight from 'lodash/partialRight';

import FilterBox from 'components/filter-box';

import { withQueryStringParamsConsumer } from 'hocs/query-string-params';
import type {
  Params,
  Options,
  UpdateQueryStringParam,
  UpdateQueryStringParams,
  ReplaceQueryStringParams,
} from 'hocs/query-string-params/types';

import { withFilterConsumer } from './context';

import type { Subscribe } from './types';

type Props = {
  clearFiltersOnSearch?: boolean,
  component?: React.Component,
  defaultValue?: any,
  disabled?: boolean,
  // FIXME: type
  onSanitize?: any,
  // FIXME: type
  onValueChange?: any,
  optionsCount?: number,
  param: string,
  replaceParams: ReplaceQueryStringParams,
  reverseDirection?: boolean,
  // FIXME: type
  setOptions?: any,
  subscribe: Subscribe,
  title: React.ReactNode,
  tooltipMessage?: React.ReactNode,
  updateParam: UpdateQueryStringParam,
  updateParams: UpdateQueryStringParams,
  params?: Params,
  workspace?: Oneflow.Workspace,
  wrap?: boolean,
};

export const getValue = (props: Props) => {
  const { param, params: values = {} } = props;

  return values[param] || null;
};

export class FilterComponent extends React.Component<Props> {
  static defaultProps = {
    defaultValue: undefined,
    onSanitize: (value: any) => value,
    setOptions: (value: any) => value,
    optionsCount: undefined,
    params: {},
    component: undefined,
    wrap: true,
    workspace: undefined,
    reverseDirection: undefined,
    disabled: undefined,
    tooltipMessage: undefined,
    onValueChange: undefined,
  };

  unsubscribe: () => any;

  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => null;
  }

  componentDidMount() {
    this.subscribeAsFilter();
  }

  componentDidUpdate(prevProps: Props) {
    const { workspace } = this.props;
    const { workspace: prevWorkspace } = prevProps;

    if (!workspace || !prevWorkspace) {
      return;
    }

    if (workspace.id !== prevWorkspace.id) {
      this.subscribeAsFilter();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onValueChange = (value: any, options: Options) => {
    const {
      clearFiltersOnSearch,
      onSanitize,
      onValueChange,
      param,
      replaceParams,
      updateParam,
      updateParams,
      params: values = {},
    } = this.props;

    let updatedValue = value;

    if (onSanitize) {
      updatedValue = onSanitize(value, values);
    }

    if (onValueChange) {
      onValueChange(updatedValue, partialRight(updateParams, options));
      return;
    }

    if (clearFiltersOnSearch) {
      replaceParams({
        folderIds: values.folderIds,
        q: updatedValue,
      });
      return;
    }

    updateParam(param, updatedValue, options);
  };

  getDefaultValue() {
    const { params: values, defaultValue } = this.props;

    if (isFunction(defaultValue)) {
      return defaultValue(values);
    }

    return defaultValue;
  }

  subscribeAsFilter = () => {
    const {
      param,
      onSanitize,
      subscribe,
      defaultValue,
    } = this.props;

    this.unsubscribe = subscribe(param, defaultValue, onSanitize);
  }

  getWorkspaceId = () => {
    const { workspace } = this.props;

    if (!workspace) {
      return undefined;
    }

    return workspace.id;
  }

  render() {
    const {
      component: Component,
      title,
      param,
      // Don't remove defaultValue, onSanitize, subscribe, workspace
      // We are extracting them from props to not pass them to the component
      defaultValue,
      onSanitize,
      subscribe,
      workspace,
      wrap,
      params: values,
      optionsCount,
      setOptions,
      ...props
    } = this.props;

    if (!Component) {
      return null;
    }

    const filter = (
      <Component
        value={getValue(this.props)}
        defaultValue={this.getDefaultValue()}
        onChange={this.onValueChange}
        setOptions={setOptions}
        params={values}
        {...props}
      />
    );

    if (!wrap) {
      return filter;
    }

    let filterCount;

    // To cover for date which returns an object instead of an array
    if (values[param] && values[param].length) {
      filterCount = values[param].length;
    } else if (values[param] && values[param].field) {
      filterCount = 1;
    }

    return (
      <FilterBox
        title={title}
        count={filterCount}
        optionsCount={optionsCount}
        workspaceId={this.getWorkspaceId()}
        setOptions={setOptions}
        param={param}
      >
        {filter}
      </FilterBox>
    );
  }
}

export default withQueryStringParamsConsumer(withFilterConsumer(FilterComponent));
