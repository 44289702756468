// @flow

import * as React from 'react';

import RichTextEditorToolbarDivider from 'components/rich-text-editor-toolbar-divider';

import BreakText from '../toolbar-buttons/break-text';
import WrapText from '../toolbar-buttons/wrap-text';
import style from './image-toolbar.module.scss';
import { AltText } from '../toolbar-buttons/alt-text';

const ImageToolbar = () => (
  <div
    className={style.ImageToolbar}
    contentEditable={false}
  >
    <BreakText direction="left" />
    <BreakText direction="center" />
    <BreakText direction="right" />
    <WrapText direction="left" />
    <WrapText direction="right" />
    <RichTextEditorToolbarDivider />
    <AltText />
  </div>
);

export default ImageToolbar;
