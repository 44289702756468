import { Message } from '@oneflowab/pomes';

import { ButtonFC as Button } from 'components/button';
import EmailIcon from 'components/icons/email';

import PreviewContainer from './preview-container';
import styles from './email-preview.module.scss';
import Skeleton from './skeleton/skeleton';

type Props = {
  companyName?: string | null;
  logo?: string | null;
}

const EmailPreview = ({ companyName, logo }: Props) => (
  <PreviewContainer>
    <div className={styles.Container}>
      <EmailIcon height="20px" className={styles.EnvelopeLogo} />
      {logo ? (
        <img src={logo} alt="Company logo" className={styles.Logo} />
      ) : (
        <span className={styles.CompanyName}>{companyName}</span>
      )}
      <Skeleton className={styles.LongSkeleton} />
      <div className={styles.DividerWrapper}>
        <div className={styles.Divider} />
      </div>
      <Skeleton className={styles.LongSkeleton} />
      <Skeleton className={styles.MediumSkeleton} />
      <Skeleton className={styles.LongSkeleton} />
      <Button
        customClass={styles.PrimaryButton}
      >
        <Message id="Go to document" comment="Button on the email preview" />
      </Button>
    </div>
  </PreviewContainer>
);

export default EmailPreview;
