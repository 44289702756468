import {
  forwardRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import type { Context } from 'react';

import { useComposedRefs } from 'hooks/compose-refs';

import { MenuStateContextValue } from './menu-state';
import { DivProps } from './types';
import { rootMenuStateContext } from './root';
import { useMenuContext } from './root-context';
import { MENU_SELECT_EVENT } from './constants';

export type ItemProps = {
  id: string;
  onSelect?: () => void;
  type?: 'item' | 'trigger';
  subMenuId?: string | null;
  selected?: boolean;
  scope?: Context<MenuStateContextValue | null>;
};

const Item = forwardRef<HTMLDivElement, ItemProps & DivProps>(({
  id,
  subMenuId = null,
  type = 'item',
  children,
  onSelect,
  selected,
  scope = rootMenuStateContext,
  // eslint-disable-next-line react/prop-types
  onMouseEnter,
  ...props
}, forwardedRef) => {
  const { registerItem, unregisterItem } = useMenuContext();
  const stateContextValue = useContext(scope);

  const ref = useRef<HTMLDivElement>(null);

  const composedRef = useComposedRefs(forwardedRef, ref);

  useLayoutEffect(() => {
    registerItem({ id, type, subMenuId });
  }, [id, registerItem, subMenuId, type]);

  useEffect(() => () => {
    unregisterItem({ id, type });
  }, [id, type, unregisterItem]);

  useEffect(() => {
    const element = ref.current;
    if (!element || !onSelect) {
      return;
    }
    element.addEventListener(MENU_SELECT_EVENT, onSelect);
    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener(MENU_SELECT_EVENT, onSelect);
    };
  }, [onSelect]);

  useEffect(() => {
    if (stateContextValue?.state.activeItemId === id) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [id, stateContextValue?.state.activeItemId]);

  if (!stateContextValue) {
    throw new Error('Item should be rendered within a state provider');
  }

  const { state, setState } = stateContextValue;

  return (
    <div
      ref={composedRef}
      {...props}
      tabIndex={-1}
      role="menuitem"
      onMouseEnter={(event) => {
        onMouseEnter?.(event);
        if (event.defaultPrevented) {
          return;
        }
        setState((prevState) => {
          const subMenus = { ...prevState.subMenus };
          Object.keys(subMenus).forEach((key) => {
            subMenus[key] = false;
          });
          return ({
            ...prevState,
            activeItemId: id,
            subMenus,
          });
        });
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          onSelect?.();
        }
      }}
      // eslint-disable-next-line react/no-unknown-property
      menu-item=""
      data-selected={selected || state.activeItemId === id}
      onClick={onSelect}
    >
      {children}
    </div>
  );
});

Item.displayName = 'MenuItem';

export default Item;
