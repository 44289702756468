// @flow

import { connect } from 'react-redux';

import positions from 'reducers/entities/positions';
import groupsReducer from 'reducers/entities/groups';

import UserComponent from './user';

const ALL_GROUPS_QUERY_NAME = 'admin/groups';

export const mapStateToProps = (state, { match }) => {
  const allGroupsQuery = groupsReducer.getQuerySelector(state, {
    name: ALL_GROUPS_QUERY_NAME,
  });

  return {
    groups: groupsReducer.getGroupsSelector(state, { ids: allGroupsQuery.result }),
    fetchState: positions.getFetchSelector(state, { id: match.params.id }),
  };
};

export const mapDispatchToProps = (dispatch, { match }) => ({
  fetchPosition: () => {
    dispatch(positions.fetchPosition({ id: match.params.id }));
  },
  queryGroups: ({ pagination } = {}) => {
    dispatch(groupsReducer.queryGroups({
      name: ALL_GROUPS_QUERY_NAME,
      params: {},
      pagination,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);
