import { call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';

import generateEntitySagas from 'normalized-redux/sagas';
import { getDashboard } from 'oneflow-client/dashboard';

import apiWrapper from 'sagas/api-wrapper';

import dashboard from 'reducers/entities/dashboard';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getPositionFromSessionSelector } from 'reducers/session';

export function* getPositionsFromAction(query) {
  const user = get(query, 'params.user');
  const positions = get(query, 'params.positions');

  if (!user || user === 'me') {
    const position = yield select(getPositionFromSessionSelector);

    return [position.id];
  }

  return positions;
}

export function* setQueryParams({ action } = {}) {
  const workspace = yield select(getCurrentWorkspaceSelector);
  const positions = yield call(getPositionsFromAction, action.query);

  return {
    params: {
      workspaceId: workspace.id,
      positions,
    },
  };
}

export function* mapper({ data }) {
  yield put(dashboard.setDashboards(data));
}

const mappers = {
  query: {
    prepare: setQueryParams,
    mapper,
    request: getDashboard,
  },
};

const dashboardSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: dashboard,
  mappers,
});

export default dashboardSagas;
