/* eslint-disable no-param-reassign */
import {
  Editor,
  Element as SlateElement,
  Element,
  Transforms,
} from 'slate';

interface CustomElement extends SlateElement {
  type: string;
}

const withSuggestions = (editor: Editor) => {
  const { isInline, normalizeNode } = editor;

  editor.isInline = (element: CustomElement | SlateElement) => {
    if ((element as CustomElement).type === 'suggestion') {
      return true;
    }
    return isInline(element as SlateElement);
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (
      Element.isElement(node)
      && (node as CustomElement).type === 'suggestion'
      && Editor.isEmpty(editor, node)
    ) {
      Transforms.unwrapNodes(editor, { at: path });
      return;
    }

    normalizeNode(entry);
  };

  return editor;
};

export default withSuggestions;
