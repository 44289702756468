import clsx from 'clsx';
import { MessageTranslator } from '@oneflowab/pomes';
import Message from 'components/message';
import Field from 'components/field';
import TextField from 'components/text-field';
import style from '../price.module.scss';

type Props = {
  message: MessageTranslator,
  affixValidations: () => string;
};

const AffixFields = ({ message, affixValidations }: Props) => (
  <div className={clsx(style.PopupFormElement, style.AffixesContainer)}>
    <h4 className={style.PriceDisplay}>
      <Message id="Price display" comment="Title for price affixes portion of form" />
    </h4>
    <p className={style.PriceDisplayHelperText}>
      <Message id="Add a currency or similar before or after the price. To separate from the price, add a space." comment="Helper text to explain price affixes." />
    </p>
    <div className={style.AffixesInputContainer}>
      <Field
        data-testid="price-prefix"
        label={<Message id="Price prefix" comment="Label for field to add a prefix to a product's price" />}
        validate={affixValidations}
        placeholder={message({ id: 'e.g., € 1', comment: 'Placeholder for price prefix.' })}
        name="prefix"
        component={TextField}
        labelCustomClass={style.AffixTextFieldLabel}
        customClass={clsx(style.PopupTextField, style.AffixTextField)}
        autoComplete="off"
      />
      <Field
        data-testid="price-suffix"
        label={<Message id="Price suffix" comment="Label to add a suffix to a product's price" />}
        validate={affixValidations}
        placeholder={message({ id: 'e.g., 1 €', comment: 'Placeholder for price suffix. ' })}
        name="postfix"
        component={TextField}
        labelCustomClass={style.AffixTextFieldLabel}
        customClass={clsx(style.PopupTextField, style.AffixTextField)}
        autoComplete="off"
      />
    </div>
  </div>
);

export default AffixFields;
