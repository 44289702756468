// @flow

import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Message } from '@oneflowab/pomes';

import Party from 'components/party';
import { isEditable, canEditAnyPartyDetails } from 'agreement/party';
import {
  getAgreementMyParty,
  getAgreementEnabledParties,
} from 'agreement/selectors';
import AddColleague from 'components/modals/add-colleague';
import AddCounterparty from 'components/modals/add-counterparty';
import { InfoBox } from 'components/error-box';

import { allowAddParticipants, allowAddParties } from 'agreement/permissions';
import { isTemplate } from 'agreement/states';

import style from './participant-list.module.scss';

export type Props = {|
  // eslint-disable-next-line react/no-unused-prop-types
  agreementId: number,
  agreement: Agreement,
  dateFormat: string,
  isOwner: boolean,
  account: Account,
  workspace: Workspace,
  position: Position,
  updateState: UpdateState,
  guestToken ?: string,
|};

class ParticipantList extends React.Component<Props> {
  renderParties = () => {
    const {
      agreement,
      account,
      dateFormat,
      isOwner,
      position,
      updateState,
      guestToken,
    } = this.props;
    const enabledParties = getAgreementEnabledParties(agreement);
    const ownerParty = enabledParties.find((party) => party.isOwner === true);
    // Place owner at the top of the list, if owner exists and is not the first party
    if (ownerParty) {
      const ownerPartyIndex = enabledParties.findIndex((party) => party.isOwner === true);

      if (ownerPartyIndex !== 0) {
        enabledParties.splice(ownerPartyIndex, 1);
        enabledParties.unshift(ownerParty);
      }
    }

    return enabledParties.map < React.Node >((party, index) => {
      const isPartyEditable = isEditable(account, agreement, party)
        && canEditAnyPartyDetails(party);

      const isOwnerInEnabledParties = !isEmpty(ownerParty);
      const isFirstCounterParty = isOwnerInEnabledParties ? index === 1 : index === 0;

      return (
        <Party
          key={party.id}
          party={party}
          agreement={agreement}
          lang={agreement.language}
          isEditable={isPartyEditable}
          isOwner={isOwner}
          account={account}
          dateFormat={dateFormat}
          position={position}
          updatingSignOrder={updateState.loading}
          guestToken={guestToken}
          isFirstCounterParty={isFirstCounterParty}
        />
      );
    });
  };

  renderAddCounterpartyButton() {
    const {
      agreement,
      account,
      workspace,
    } = this.props;

    const allowParties = allowAddParties(account, agreement);
    const allowParticipants = allowAddParticipants(account, agreement, workspace);

    if (isTemplate(agreement) || (allowParties && allowParticipants)) {
      return (
        <AddCounterparty
          agreement={agreement}
        />
      );
    }

    return null;
  }

  renderAddColleagueButton() {
    const { agreement } = this.props;
    const myParty = getAgreementMyParty(agreement);

    if (!myParty && isTemplate(agreement)) {
      return (
        <div className={style.AddButton}>
          <AddColleague agreement={agreement} />
        </div>
      );
    }

    return null;
  }

  renderAddParticipantsInfo() {
    const { agreement } = this.props;
    const enabledParties = getAgreementEnabledParties(agreement);
    if (isTemplate(agreement) && !enabledParties.length) {
      return (
        <InfoBox
          headerText={(
            <Message
              id="No participants have been added"
              comment="Info box header in template when there are no participants."
            />
          )}
          bodyText={(
            <Message
              id="You can add colleagues and counterparties to this template as default. These participants will automatically be included in all contracts using this template."
              comment="Info box text in template when there are no participants."
            />
          )}
        />
      );
    }

    return null;
  }

  render() {
    const { agreement } = this.props;
    if (isEmpty(agreement)) {
      return null;
    }

    return (
      <div className={style.ParticipantList}>
        {this.renderParties()}
        {this.renderAddParticipantsInfo()}
        {this.renderAddColleagueButton()}
        {this.renderAddCounterpartyButton()}
      </div>
    );
  }
}

export default ParticipantList;
