import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import {
  ACTION_STATUS_NOT_STARTED,
  ACTION_STATUS_RUNNING,
  ACTION_STATUS_SUCCEEDED,
} from 'agreement/actions/constants';

const options = [
  {
    value: ACTION_STATUS_NOT_STARTED,
    label: (
      <div>
        <Message
          id="Not started"
          comment='Search filter label. Used when searching documents, as in "find all documents that have not started approval flow"'
        />
      </div>
    ),
  },
  {
    value: ACTION_STATUS_RUNNING,
    label: (
      <div>
        <Message
          id="Approval pending"
          comment='Search filter label. Used when searching documents, as in "find all documents that are have pending approvals"'
        />
      </div>
    ),
  },
  {
    value: ACTION_STATUS_SUCCEEDED,
    label: (
      <div>
        <Message
          id="Approved"
          comment='Search filter label. Used when searching documents, as in "find all documents that are approved"'
        />
      </div>
    ),
  },
  {
    value: 'NONE',
    label: (
      <div>
        <Message
          id="No internal approvers"
          comment='Search filter label. Used when searching documents, as in "find all documents have no approval flow"'
        />
      </div>
    ),
  },
];

interface FilterOption {
  value: string;
  label: React.ReactNode;
}

type GetOptions = (filterables: string[]) => FilterOption[];

const approvalFilterOptions: GetOptions = (filterables) => options
  .filter((option) => filterables.includes(option.value));

export default approvalFilterOptions;
