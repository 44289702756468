/* eslint-disable import/named */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import agreements from 'reducers/entities/agreements';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';
import * as AGREEMENT_CONSTANTS from 'agreement/participant/constants';

import { shouldBeDisabled } from 'components/document-tabs/settings-tab/helpers';
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'components/select-field-radix';
import Label from 'components/document-tabs/settings-tab/components/label';
import ChevronDownIcon from 'components/icons/chevron-down';
import { HelpCenterLink } from 'components/help-center-link';
import { getAvailableCreatorRules } from 'components/document-tabs/settings-tab/sections/default-settings/helpers';

import selectStyle from 'components/select-field-radix/select-field-radix.module.scss';

import style from './creator-role.module.scss';

type Props = {
  agreementId: number,
}

const allOptions = [
  { value: 1, label: <Message id="Signatory" comment="Role type" /> },
  { value: 0, label: <Message id="Influencer" comment="Role type" /> },
  { value: 2, label: <Message id="Organizer" comment="Role type" /> },
  { value: 3, label: <Message id="Viewer" comment="Role type" /> },
];

type Option = typeof allOptions[number];

const CreatorRole = ({
  agreementId,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const dispatch = useDispatch();
  const { resetRPCStates } = useAPIError(agreementId);

  const availableOptions = getAvailableCreatorRules(agreement);

  const [selectedValue, setSelectedValue] = useState(agreement.config?.defaultCreatorRole
    ?? AGREEMENT_CONSTANTS.TYPE_IS_SIGNATORY);

  const tooltipMessage = (
    <div className={style.HeaderWithTooltip}>
      <span>
        <Message
          id="Influencer: views and edits"
          comment="Tooltip description for what an influencer role can do"
        />
      </span>
      <span>
        <Message
          id="Signatory: views, edits, and signs"
          comment="Tooltip description for what an signatory role can do"
        />
      </span>
      <span>
        <Message
          id="Organizer: views and edits, invisible to counterparties"
          comment="Tooltip description for what an organizer role can do"
        />
      </span>
      <span>
        <Message
          id="Viewer: views and comments"
          comment="Tooltip description for what an viewer role can do"
        />
      </span>
    </div>
  );

  const handleChange: SelectFieldProps<Option['value']>['onChange'] = (value, options) => {
    setSelectedValue(value);
    resetRPCStates();
    dispatch(agreements.updateConfig({
      id: agreementId,
      data: {
        defaultCreatorRole: value,
      },
      pipe: {
        onFailure: options?.onFailure,
      },
    }));
  };

  const options = allOptions.filter((option) => availableOptions.includes(option.value));

  const label = (
    <Label
      hint={tooltipMessage}
    >
      <Message
        id="Default role in document"
        comment="Label for creator role dropdown"
      />
    </Label>
  );

  return (
    <div className={style.CreatorRoleContainer} data-testid="creator-role">
      {label}
      <Select
        disabled={shouldBeDisabled(agreement)}
        defaultValue={selectedValue}
        onValueChange={handleChange}
      >
        <SelectTrigger
          className={selectStyle.SelectTrigger}
        >
          <SelectValue />
          <ChevronDownIcon height="10px" />
        </SelectTrigger>
        <SelectContent
          position="popper" // for Firefox
          className={selectStyle.SelectContent}
        >
          <SelectViewport>
            {options.map((option) => (
              <SelectItem
                key={option.value}
                value={option.value}
                className={selectStyle.SelectItem}
              >
                {option.label}
              </SelectItem>
            ))}
          </SelectViewport>
        </SelectContent>
      </Select>
      <p className={style.SelectFieldDescription} data-testid="field-description">
        <Message
          id="Choose the default role for you and your colleagues."
          comment="Description for role select field"
        />
        {' '}
        <HelpCenterLink path="support/solutions/articles/77000436007-roles-in-a-document" />
      </p>
    </div>
  );
};

export default CreatorRole;
