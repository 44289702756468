// @flow

import normalizeEntity from 'normalized-redux/entity-normalizer';

const normalizedWebhook = normalizeEntity({
  entity: 'webhook',
  rpcs: [
    'urlCheck',
  ],
});

export default normalizedWebhook;
