// @flow

// eslint-disable-next-line import/prefer-default-export
export const AuditLogTypes = {
  POSITION_LOGIN_SUCCESS: 4,
  POSITION_CREATE: 6,
  POSITION_ACTIVATE: 7,
  POSITION_DEACTIVATE: 8,
  WORKSPACE_ACCESS_UPDATE: 33,
  WORKSPACE_UPDATE: 21,
  WORKSPACE_BRANDING_UPDATE: 23,
  DATA_RETENTION_POLICY_UPDATE: 24,
  CONTRACT_DELETE: 26,
  CONTRACT_RESTORE: 44,
  ACCOUNT_SEAT_UPDATE: 29,
  GROUP_CREATE: 11,
  GROUP_DELETE: 12,
  GROUP_MEMBERSHIP_ADD: 9,
  GROUP_MEMBERSHIP_REMOVE: 10,
  GROUP_UPDATE_NAME: 13,
  ROLE_CREATE: 36,
  ROLE_REMOVE: 37,
  ROLE_UPDATE: 34,
  AGREEMENT_MOVE: 39,
  TEMPLATE_MOVE: 40,
  ACCOUNT_PLAN_UPDATE: 42,
  ACCOUNT_EXPORT: 43,
  EXTENSION_ENABLE: 45,
  EXTENSION_DISABLE: 46,
  AGREEMENT_PERMANENTLY_DELETE: 47,
  AUTOMATION_RULES_CREATE: 48,
  AUTOMATION_RULES_UPDATE: 49,
  AUTOMATION_RULES_REMOVE: 50,
};
