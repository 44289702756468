import Tooltip from 'components/tooltip';
import { ButtonFC as Button } from 'components/button';
import Message from 'components/message';

import style from './publish.module.scss';

type Props = {
  disabled?: boolean,
  onClick?: () => void,
}

const PublishButton = ({ disabled = false, onClick }: Props) => (
  <Tooltip
    message={disabled ? (
      <Message
        id="You need to save first."
        comment="Tooltip for the publish button."
      />
    ) : (
      <Message
        id="Make template available for everyone in this workspace."
        comment="Tooltip for the publish button."
      />
    )}
    theme="oneflow"
    side="top"
    sideOffset={7.5}
    messageClassName={style.TooltipMessage}
  >
    <Button
      kind="publish"
      disabled={disabled}
      customClass={style.PublishButton}
      onClick={onClick}
    >
      <Message
        id="Publish"
        comment="Button label for publishing a template."
      />
    </Button>
  </Tooltip>
);

export default PublishButton;
