// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import type { Match } from 'react-router';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import urlJoin from 'url-join';

import adminPage from 'hocs/admin-page';
import extensionSettings from 'hocs/extension-settings';

import { Table } from 'components/table';
import Button from 'components/button';
import Add from 'components/icons/add';
import Toggle from 'components/toggle';
import Tooltip from 'components/tooltip';
import { ExtensionName } from 'components/extension/extension-name';
import AddTemplateGroup from 'components/modals/add-template-group';
import { LocalizedDateTime } from 'components/localized-date-time';

import TemplateGroupPage from './template-group';

import style from './template-groups.module.scss';

export type Props = {
  queryTemplateGroups: () => void,
  templateGroups: Array<TemplateGroup>,
  templateGroupsQuery: Query,
  message: MessageTranslator,
  extensionsById: {
    [?number]: Extension,
  },
  updateTemplateGroupState: ({ id: number, active: boolean }) => void,
  match: Match,
};

export class TemplateGroupsPage extends React.Component<Props> {
  componentDidMount() {
    const { queryTemplateGroups } = this.props;

    queryTemplateGroups();
  }

  getNameLink = (templateGroup: TemplateGroup) => {
    const { match } = this.props;

    return (
      <Link to={urlJoin(match.url, String(templateGroup.id))}>
        {templateGroup.name}
      </Link>
    );
  };

  getTemplateGroupInfo = (templateGroup: TemplateGroup) => (
    <div className={style.TemplateGroup}>
      {this.getNameLink(templateGroup)}
      <div className={style.Description}>
        {templateGroup.description}
      </div>
    </div>
  );

  getState = (templateGroup: TemplateGroup) => {
    const { message } = this.props;

    if (templateGroup.usedBy) {
      return (
        <Tooltip
          message={message({
            id: 'Not allowed to modify template groups created from other extensions',
            comment: 'Tooltip showing reason for not being allowed to enable/disable template groups.',
          })}
          side="top"
        >
          <span>
            <Toggle
              checked={templateGroup.active}
              disabled
            />
          </span>
        </Tooltip>
      );
    }

    return (
      <Toggle
        checked={templateGroup.active}
        onChange={this.handleStateChange(templateGroup)}
      />
    );
  };

  getUsedByLink = (templateGroup: TemplateGroup) => {
    const { extensionsById } = this.props;
    const extensionUsingTemplateGroup = extensionsById[templateGroup.usedBy];

    if (!extensionUsingTemplateGroup) {
      return '-';
    }

    return (
      <ExtensionName extension={extensionUsingTemplateGroup} isLinked />
    );
  };

  getCreatedDate = (templateGroup: TemplateGroup) => (
    <LocalizedDateTime datetime={templateGroup.createdTime} />
  );

  getTableConfig() {
    const { message, templateGroups } = this.props;

    const infoColumn = {
      name: 'info',
      label: message({
        id: 'Template group',
        comment: 'Column label in template group list',
      }),
      type: 'cell',
      value: this.getTemplateGroupInfo,
    };
    const stateColumn = {
      name: 'state',
      label: message({
        id: 'Active',
        comment: 'Column label in template group list',
      }),
      type: 'cell',
      value: this.getState,
    };
    const usedByColumn = {
      name: 'usedBy',
      label: message({
        id: 'Used by',
        comment: 'Column label in template group list',
      }),
      type: 'cell',
      value: this.getUsedByLink,
    };

    const createdColumn = {
      name: 'createdTime',
      label: message({
        id: 'Created',
        comment: 'Column label in template group list',
      }),
      type: 'cell',
      value: this.getCreatedDate,
    };

    return {
      items: templateGroups,
      itemKey: 'id',
      actions: [],
      columns: [
        infoColumn,
        stateColumn,
        usedByColumn,
        createdColumn,
      ],
    };
  }

  handleStateChange = (templateGroup: TemplateGroup) => () => {
    const { updateTemplateGroupState } = this.props;
    const newTemplateGroupState = !templateGroup.active;

    updateTemplateGroupState({
      id: templateGroup.id,
      active: newTemplateGroupState,
    });
  };

  getCreateTemplateGroupButton = (onClick: () => void) => (
    <Button
      data-testid="add-template-group"
      icon={Add}
      kind="primary"
      onClick={onClick}
    >
      <Message
        id="Add a template group"
        comment="Button text for adding template group on template group list page."
      />
    </Button>
  );

  renderActionBar() {
    return (
      <div>
        <AddTemplateGroup>
          {this.getCreateTemplateGroupButton}
        </AddTemplateGroup>
      </div>
    );
  }

  render() {
    const { templateGroupsQuery } = this.props;

    return (
      <div className={style.PageContainer}>
        {this.renderActionBar()}
        <Table
          config={this.getTableConfig()}
          query={templateGroupsQuery}
        />
      </div>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Template groups',
    comment: 'Used as the page title of the template groups page.',
  }),
  modules: [[{
    path: '/:id(\\d+)',
    title: '',
    component: TemplateGroupPage,
  }]],
  hideLayout: true,
});

export default adminPage(propsMapper)(extensionSettings(TemplateGroupsPage));
