import {
  forwardRef,
  ReactPortal,
  RefObject,
  useMemo,
} from 'react';
import ReactDOM from 'react-dom';

import { composeRefs } from 'hooks/compose-refs';
// eslint-disable-next-line import/named
import { useRichTextProps } from 'contexts/rich-text';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';

import style from './selected-rectangle-anchor.module.scss';

type AnchorProps = {
  selectedLocation?: DOMRect;
  documentScrollContainerRef: RefObject<HTMLElement | null>;
  left?: number;
  top?: number;
};

const Anchor = forwardRef<HTMLDivElement, AnchorProps>(({
  selectedLocation,
  left,
  top,
  documentScrollContainerRef,
}, ref) => {
  // We're memoizing the anchor location because if scroll location changes after the popover is
  // rendered, it'll cause the popover to move to an incorrect location.
  const anchorStyle = useMemo(() => {
    // If no selectedLocation is available, we'll use the left and top props
    if (!selectedLocation) {
      return { left, top };
    }
    // But even if selectedLocation is available, the left and top props take precedence
    return {
      left: left || `${selectedLocation.left + selectedLocation.width / 2}px`,
      top: top || `${selectedLocation.top + (selectedLocation.height / 2)
        + Number(documentScrollContainerRef.current?.scrollTop)}px`,
    };
  }, [selectedLocation, left, top, documentScrollContainerRef]);

  return (
    <div
      className={style.Anchor}
      style={anchorStyle}
      ref={ref}
    />
  );
});

Anchor.displayName = 'Anchor';

type SelectedRectangleAnchorProps = {
  left?: number;
  top?: number;
  // custom anchor means that its shouldn't be registered and override the
  // selectedRectangleAnchorRef in the rich text context
  isCustomAnchor?: boolean;
};

const SelectedRectangleAnchor = forwardRef<HTMLDivElement, SelectedRectangleAnchorProps>(({
  left,
  top,
  isCustomAnchor,
}, ref) => {
  const {
    selectedRectangle,
    cursorPosition,
    selectedRectangleAnchorRef,
  } = useRichTextProps();

  const selectedLocation = selectedRectangle || cursorPosition;

  const { documentScrollContainerRef } = useDocumentLayout();

  // If user wants to override the position of the anchor, we'll use the left and top props
  // it shouldn't rely on if the selectedLocation is available or not
  // This is only used for ai assistant popover at the moment
  // otherwise, if selectedLocation is not available, we'll return null
  if ((!selectedLocation && (!left || !top)) || !documentScrollContainerRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    <Anchor
      ref={composeRefs(ref, isCustomAnchor ? undefined : selectedRectangleAnchorRef)}
      selectedLocation={selectedLocation}
      left={left}
      top={top}
      documentScrollContainerRef={documentScrollContainerRef}
    />,
    documentScrollContainerRef.current,
  ) as ReactPortal;
});

SelectedRectangleAnchor.displayName = 'SelectedRectangleAnchor';

export default SelectedRectangleAnchor;
