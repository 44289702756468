import { ReactNode, useState } from 'react';
import { Message } from '@oneflowab/pomes';

import { amplitudeLogEvent } from 'client-analytics/amplitude';

import TooltipInfo from 'components/tooltip-info';
import Button from 'components/button';
import Modal from 'components/modal';
import { Book } from 'components/icons/book';
import { AiInsightsModal } from 'components/modals/ai-insights';
import { Playbook } from './playbook';

import style from './chart-card.module.scss';

type Props = {
  children: ReactNode,
  title: ReactNode,
  playbook: { values: any },
  tooltipText: ReactNode,
  insightType: string,
  playbookTemplateType: 'SALES' | 'NDA' | 'SOURCING',
  filterParams: any,
  hasIssues: boolean,
  chartName: string,
};

export const ChartCard = ({
  children,
  title,
  playbook,
  tooltipText,
  insightType,
  playbookTemplateType,
  filterParams,
  hasIssues,
  chartName,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [visiblePlaybook, setVisiblePlaybook] = useState<ReactNode | null>(null);

  const handleShowIssueListClick = () => {
    setModalVisible(true);
    amplitudeLogEvent('Go To AI Insights Contract List', {
      'chart type': chartName,
      location: 'ai insights tab - show all button',
    });
  };

  return (
    <section className={style.ChartCard}>
      <div className={style.TopRow}>
        <div className={style.TitleWrapper}>
          <h2 className={style.Title}>
            {title}
          </h2>
          <TooltipInfo
            message={tooltipText}
          />
        </div>
        {playbook && (
          <Button
            onClick={() => setVisiblePlaybook(visiblePlaybook ? null : title)}
            icon={Book}
            customClass={style.ChartButton}
          >
            <Message
              id="Guidelines"
              comment="Text for button that will open a modal with guidelines for a given chart type"
            />
          </Button>
        )}
      </div>
      {children}
      {visiblePlaybook && (
        <Modal
          header={(
            <Message
              id="{title} guidelines"
              comment="Header in a modal showing guidelines for a given chart type"
              values={{ title }}
            />
        )}
          isOpen
          onCancel={() => setVisiblePlaybook(null)}
          hideFooter
        >
          <Modal.Body>
            <Playbook
              playbook={playbook}
              insightType={insightType}
              playbookTemplateType={playbookTemplateType}
            />
          </Modal.Body>
        </Modal>
      )}
      {modalVisible && (
        <AiInsightsModal
          clickedInsightType={{ key: insightType.toLowerCase(), value: title }}
          onClose={() => setModalVisible(false)}
          filterParams={filterParams}
          issuesOnly
        />
      )}
      <div className={style.BottomRow}>
        <Button
          customClass={style.ChartButton}
          onClick={handleShowIssueListClick}
          disabled={!hasIssues}
        >
          {hasIssues ? (
            <Message
              id="Show documents with issue"
              comment="Text in the button to show the issue list"
            />
          ) : (
            <Message
              id="No issues found"
              comment="Text under a chart if there were no issues found"
            />
          )}
        </Button>
      </div>
    </section>
  );
};
