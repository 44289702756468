// @flow

import { connect } from 'react-redux';

import type { Dispatch } from 'redux';

import groupsReducer from 'reducers/entities/groups';

import { AddGroup } from './add-group';

const QUERY_NAME = 'admin/groups';

type StateProps = {|
  formState: RpcState
|}

type DispatchProps = {|
  resetFormState: () => void,
  onSubmit: (name: string) => void
|};

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  formState: groupsReducer.getCreateSelector(state),
});

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  resetFormState: () => {
    dispatch(groupsReducer.createGroupReset());
  },
  onSubmit: (groupData) => {
    dispatch(groupsReducer.createGroup({
      data: groupData,
      pipe: {
        action: () => groupsReducer.queryGroupsReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
});

const connectedAddGroup = connect<StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(AddGroup);

export { connectedAddGroup as AddGroup };
