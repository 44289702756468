import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { ReactEditor, useSlate } from 'slate-react';
import { head, debounce } from 'lodash';

import { getAvailableContentInlineSize } from 'reducers/app';

import useFocusedEditor from 'hooks/rich-text-editor/use-focused-editor';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import { getRelativeRectangles } from 'components/rich-text-editor/range-rectangles';

import { Rectangle } from '../dom-range-highlighter/dom-range-highlighter';

const getClientRect = (range: any, relativeParent: HTMLDivElement) => {
  const clientRects = Array.from(range.getClientRects());

  return head(getRelativeRectangles(clientRects, relativeParent));
};

export const getCursorLocation = (editor: any, relativeParent) => {
  try {
    const windowSelection = window.getSelection();

    const domRange = Number(windowSelection?.rangeCount) > 0 && windowSelection?.getRangeAt(0);
    if (!domRange
      || !ReactEditor.hasDOMNode(editor, domRange.startContainer)
      || !ReactEditor.hasDOMNode(editor, domRange.endContainer)) {
      return null;
    }
    const cursorLocation = getClientRect(domRange, relativeParent) as Rectangle;
    return cursorLocation;
  } catch {
    return null;
  }
};

const useCursorPosition = () => {
  const editor = useSlate();
  const { documentScrollContainerRef } = useDocumentLayout();
  const focusedEditor = useFocusedEditor();
  const availableContentInlineSize = useSelector(getAvailableContentInlineSize);
  const [cursorPosition, setCursorPosition] = useState<Rectangle | null>(null);

  const resetCursorPosition = useCallback(() => {
    setCursorPosition(null);
  }, []);

  useEffect(() => {
    if (editor !== focusedEditor) {
      resetCursorPosition();
    }
  }, [editor, focusedEditor, resetCursorPosition]);

  const onMouseDown = useCallback(() => {
    setCursorPosition(null);

    const onDocumentMouseReleaseHandler = debounce(() => {
      const cursorLocation = getCursorLocation(editor, documentScrollContainerRef.current);
      setCursorPosition(cursorLocation);

      document.removeEventListener('mouseup', onDocumentMouseReleaseHandler);
      document.removeEventListener('dragend', onDocumentMouseReleaseHandler);
    }, 100);

    document.addEventListener('mouseup', onDocumentMouseReleaseHandler);
    document.addEventListener('dragend', onDocumentMouseReleaseHandler);
  }, [documentScrollContainerRef, editor]);

  const rangeListener = useCallback(() => {
    if (!cursorPosition) {
      return;
    }

    const cursorLocation = getCursorLocation(editor, documentScrollContainerRef.current);
    setCursorPosition(cursorLocation);
  }, [cursorPosition, editor, documentScrollContainerRef]);
  const debouncedRangeListener = useMemo(() => debounce(rangeListener, 100), [rangeListener]);

  useEffect(() => {
    if (!cursorPosition) {
      window.addEventListener('scroll', debouncedRangeListener);
    }

    return () => {
      window.removeEventListener('scroll', debouncedRangeListener);
    };
  }, [debouncedRangeListener, editor, cursorPosition]);

  useEffect(() => {
    debouncedRangeListener();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableContentInlineSize]);

  return {
    onMouseDown,
    resetCursorPosition,
    cursorPosition,
  };
};

export default useCursorPosition;
