// @flow

import * as React from 'react';

import RichTextEditorToolbarDivider from 'components/rich-text-editor-toolbar-divider';

import AddTableRow from '../toolbar-buttons/add-table-row';
import AddTableColumn from '../toolbar-buttons/add-table-column';
import RemoveTableRow from '../toolbar-buttons/remove-table-row';
import RemoveTableColumn from '../toolbar-buttons/remove-table-column';
import RemoveTable from '../toolbar-buttons/remove-table';
import ToggleTableBorders from '../toolbar-buttons/toggle-table-borders';
import ApplyColorButton from '../toolbar-buttons/apply-color-button';

import style from './table-toolbar.module.scss';

const TableToolbar = () => (
  <div
    className={style.TableToolbar}
    contentEditable={false}
  >
    <AddTableRow direction="above" />
    <AddTableRow direction="below" />
    <AddTableColumn direction="before" />
    <AddTableColumn direction="after" />
    <RichTextEditorToolbarDivider />
    <RemoveTableRow />
    <RemoveTableColumn />
    <RichTextEditorToolbarDivider />
    <ToggleTableBorders />
    <ApplyColorButton applyTo="tableCellBackground" />
    <RichTextEditorToolbarDivider />
    <RemoveTable />
  </div>
);

export default TableToolbar;
