// @flow

import * as React from 'react';
import map from 'lodash/map';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

type AvailableWorkspaceTypesMap = {
  sales: React.Node,
  hr: React.Node,
  legal: React.Node,
  procurement: React.Node,
  other: React.Node,
}

type GetWorkspaceTypes = (message: MessageTranslator) => AvailableWorkspaceTypesMap;

export const workspaceTypes: GetWorkspaceTypes = (message) => ({
  sales: message({
    id: 'Sales',
    comment: 'Workspace type',
  }),
  hr: message({
    id: 'HR',
    comment: 'Workspace type',
  }),
  legal: message({
    id: 'Legal',
    comment: 'Workspace type',
  }),
  procurement: message({
    id: 'Procurement',
    comment: 'Workspace type',
  }),
  other: message({
    id: 'Other',
    comment: 'Workspace type',
  }),
});

export const getWorkspaceType = (type: WorkspaceType, message: MessageTranslator) => (
  workspaceTypes(message)[type]
);

type GetWorkspaceTypesAsOptions = (message: MessageTranslator) => Array<{
  label: typeof WorkspaceType,
  value: string,
}>

export const getWorkspaceTypesAsOptions: GetWorkspaceTypesAsOptions = (message) => (
  map<string, any, any>(workspaceTypes(message), (type, value) => ({
    label: type,
    value,
  }))
);

type Props = {
  type: WorkspaceType,
  message: MessageTranslator,
}

export const WorkspaceTypeComponent = (props: Props) => (
  getWorkspaceType(props.type, props.message)
);

export default localize<Props>(WorkspaceTypeComponent);
