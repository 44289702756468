// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import { FormSpy, type FormRenderProps } from 'react-final-form';
import {
  getFormatter,
  getMonthlySeatPrice,
  getYearlySeatPrice,
  formatPrice,
} from 'account';

import ModalForm from 'hocs/modal-form';
import { PreviousButton } from 'components/buttons';
import Button from 'components/button';
import ToggleSwitch from 'components/toggle-switch';

import { AccountSeatsField } from 'components/fields';

import style from './freemium-self-service-seats.module.scss';

type ModalData = {
  monthlyBilling: boolean,
  planName: string,
  pricePerSeat: number,
  pricePerSeatMonthlySubscription: number,
  currency: string,
  planId: number,
  numberOfSeats: string,
};

export type Props = {
  onStepComplete: () => void,
  onSyncStepData: ModalData => void,
  languageCode: EnabledLanguages,
  accountSeats: AccountSeats,
  modalData: {} | ModalData,
  onClose: () => void,
  onPreviousStep: () => void,
  modalKey: string,
};

export class FreemiumSelfServiceSeats extends React.Component<Props> {
  handleSubmit = (formData: { numberOfSeats: string }) => {
    const { onSyncStepData, onStepComplete } = this.props;

    onSyncStepData(formData);
    onStepComplete();
  };

  formatter = () => {
    const { languageCode, modalData: { currency } } = this.props;

    return getFormatter(languageCode, currency);
  };

  getActions = ({ formProps }: FormRenderProps) => {
    const { onPreviousStep } = this.props;
    const disabled = Boolean(
      formProps.validating
      || formProps.invalid,
    );

    return (
      <div className={style.Buttons}>
        <PreviousButton onClick={onPreviousStep} />
        <Button
          kind="primary"
          disabled={disabled}
          onClick={formProps.handleSubmit}
          trackable={{
            name: 'Choose Number Of Seats',
            props: {
              'seat count': formProps.values.numberOfSeats,
            },
          }}
        >
          <Message
            id="Add billing details"
            comment="Text for button to confirm action."
          />
        </Button>
      </div>
    );
  };

  resetFormState = () => undefined;

  renderPlanInfo = () => (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const { planName, pricePerSeat, pricePerSeatMonthlySubscription } = this.props.modalData;
        const { monthlyBilling } = values;

        const formattedPrice = formatPrice(
          this.formatter(),
          monthlyBilling ? pricePerSeatMonthlySubscription : pricePerSeat,
        );

        let billingPeriodText = (
          <>
            <Message
              id="/ month per user"
              comment="Price information of the selected price plan"
            />
            {' - '}
            <strong>
              <Message
                id="billed yearly"
                comment="Yearly payment interval information"
              />
            </strong>
          </>
        );

        if (monthlyBilling) {
          billingPeriodText = (
            <>
              <Message
                id="/ month per user"
                comment="Price information of the selected price plan"
              />
              {' - '}
              <strong>
                <Message
                  id="billed monthly"
                  comment="Monthly payment interval information"
                />
              </strong>
            </>
          );
        }

        return (
          <div className={style.TextBox}>
            <h2>
              <Message
                id="Payment interval"
                comment="Heading for the selected subscription"
              />
            </h2>
            <div className={style.BillingPeriod}>
              <ToggleSwitch
                name="monthlyBilling"
                valueLabels={['Yearly', 'Monthly']}
              />
              <span>
                {planName}
              </span>
              <span>
                {formattedPrice}
              </span>
              <span>
                {billingPeriodText}
              </span>
              {this.renderDiscountBubble()}
            </div>
          </div>
        );
      }}
    </FormSpy>
  );

  renderSeatsInfo = () => {
    const { accountSeats: { taken } } = this.props;

    return (
      <>
        <Message
          id="You currently have {seatsLimit} active user."
          pluralId="You currently have {seatsLimit} active users."
          pluralCondition="seatsLimitCount"
          comment="A helper text to show in the freemium self service modal body"
          values={{
            seatsLimit: <strong>{taken}</strong>,
            seatsLimitCount: taken,
          }}
        />
        {' '}
        <Message
          id="This means you have to buy at least {seatsLimit} seat."
          pluralId="This means you have to buy at least {seatsLimit} seats."
          comment="A helper text to show in the freemium self service modal body"
          pluralCondition="seatsLimitCount"
          values={{
            seatsLimit: <strong>{taken}</strong>,
            seatsLimitCount: taken,
          }}
        />
      </>
    );
  };

  renderPriceInfo = () => (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const { pricePerSeat, pricePerSeatMonthlySubscription } = this.props.modalData;
        const { monthlyBilling } = values;

        const formattedPrice = formatPrice(
          this.formatter(),
          monthlyBilling ? pricePerSeatMonthlySubscription : pricePerSeat,
        );

        return (
          <Message
            id="Price: {price} per seat / month"
            comment="Seat pricing information"
            values={{
              price: formattedPrice,
            }}
          />
        );
      }}
    </FormSpy>
  );

  renderMonthlyTotalPrice = () => (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const { pricePerSeat, pricePerSeatMonthlySubscription } = this.props.modalData;
        const { numberOfSeats, monthlyBilling } = values;

        const formattedPrice = formatPrice(
          this.formatter(),
          getMonthlySeatPrice(
            numberOfSeats,
            monthlyBilling ? pricePerSeatMonthlySubscription : pricePerSeat,
          ),
        );

        return (
          <p className={style.PriceAmount}>
            <Message
              id="{total} / month"
              comment="Total monthly price for seats that are being bought"
              values={{
                total: formattedPrice,
              }}
            />
          </p>
        );
      }}
    </FormSpy>
  );

  renderTotalPrice = () => (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const { pricePerSeat, pricePerSeatMonthlySubscription } = this.props.modalData;
        const { numberOfSeats, monthlyBilling } = values;

        const formattedPrice = formatPrice(
          this.formatter(),
          getYearlySeatPrice(
            numberOfSeats,
            monthlyBilling ? pricePerSeatMonthlySubscription : pricePerSeat,
          ),
        );

        return (
          <strong>
            <p className={style.PriceAmount}>
              <Message
                id="{total} / year"
                comment="Total yearly price for seats that are being bought"
                values={{
                  total: formattedPrice,
                }}
              />
            </p>
          </strong>
        );
      }}
    </FormSpy>
  );

  renderDiscountBubble = () => (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const { pricePerSeat, pricePerSeatMonthlySubscription } = this.props.modalData;
        const { monthlyBilling } = values;
        const discount = Math.round(
          ((pricePerSeatMonthlySubscription - pricePerSeat)
          / pricePerSeatMonthlySubscription) * 100,
        );

        if (monthlyBilling || discount === 0) {
          return null;
        }

        return (
          <span className={style.DiscountBubble}>
            <Message
              id="{discount}% off*"
              comment="Discounted monthly price with an asterisk."
              values={{
                discount,
              }}
            />
          </span>
        );
      }}
    </FormSpy>
  );

  renderDiscountExplanation = () => (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const { monthlyBilling } = values;

        if (monthlyBilling) {
          return <p className={style.DiscountExplanation} />;
        }

        return (
          <p className={style.DiscountExplanation}>
            <Message
              id="*compared to when billed monthly"
              comment="Asterisk explaining how a discount is calculated."
            />
          </p>
        );
      }}
    </FormSpy>
  );

  renderFieldContainer = () => {
    const { accountSeats: { taken } } = this.props;

    return (
      <>
        <div className={style.PriceContainer}>
          <div className={style.AccountSeatsField}>
            <AccountSeatsField
              min={taken}
              autoFocus
              noPlaceholder
              initialValue={taken}
            />
          </div>
          <div className={style.PriceInfo}>
            {this.renderPriceInfo()}
          </div>
        </div>
        <div className={style.Container}>
          <p className={style.PriceTitle}>
            <Message
              id="Monthly"
              comment="Heading for total monthly price of seats/licenses."
            />
          </p>
          <div className={style.Amount}>
            {this.renderDiscountBubble()}
            {this.renderMonthlyTotalPrice()}
          </div>
        </div>
        <div className={style.Container}>
          <strong>
            <p className={style.PriceTitle}>
              <Message
                id="Total"
                comment="Heading for total yearly price of seats/licenses."
              />
            </p>
          </strong>
          <div className={style.Amount}>
            {this.renderDiscountBubble()}
            {this.renderTotalPrice()}
          </div>
        </div>
      </>
    );
  };

  renderBody = () => (
    <div className={style.Seats}>
      {this.renderPlanInfo()}
      <div className={style.TextBox}>
        <h2>
          <Message
            id="Seats"
            comment="Heading for the number of seats the user wants to purchase"
          />
        </h2>
        <p>
          <Message
            id="Each active user requires one seat. Deactivating a user will free up a seat."
            comment="Help text explaning how seats work."
          />
        </p>
        <p>
          {this.renderSeatsInfo()}
        </p>
        <p>
          <Message
            id="Please enter how many seats you would like to purchase."
            comment="Help text before the user decides the amount of seats to purchase"
          />
        </p>
      </div>
      {this.renderFieldContainer()}
      {this.renderDiscountExplanation()}
    </div>
  );

  render() {
    const { modalData, onClose, modalKey } = this.props;
    const formState = {};

    return (
      <ModalForm
        title={(
          <Message
            id="Activate your Subscription Plan"
            comment="Message shown in upgrade account modal"
          />
        )}
        body={this.renderBody()}
        actions={this.getActions}
        formState={formState}
        resetFormState={this.resetFormState}
        onSubmit={this.handleSubmit}
        initialValues={modalData}
        onClose={onClose}
        modalKey={modalKey}
      />
    );
  }
}
