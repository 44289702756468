// @flow

import {
  KEY_TEMPLATE_GROUPS,
} from 'extensions';

const TemplateGroups = {
  key: KEY_TEMPLATE_GROUPS,
};

export default TemplateGroups;
