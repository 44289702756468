import { MouseEvent } from 'react';
import { Message } from '@oneflowab/pomes';

import ReplayIcon from 'components/icons/replay';
import { MenuItem } from 'components/menu-item';

type Props = {
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
};

export const RestoreMenuItem = ({
  disabled,
  onClick,
}: Props) => (
  <MenuItem
    disabled={disabled}
    label={
      <Message id="Restore" comment="Menu item to restore a trashed document" />
    }
    onClick={onClick}
    icon={ReplayIcon}
  />
);
