/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// @flow

import React, { forwardRef } from 'react';
import * as ReactDialog from '@radix-ui/react-dialog';
import clsx from 'clsx';

import style from './dialog.module.scss';

export const Dialog = ReactDialog.Root;
export const DialogTrigger = ReactDialog.Trigger;
export const DialogPortal = ReactDialog.Portal;
export const DialogClose = ReactDialog.Close;
export const DialogOverlay = ReactDialog.Overlay;

/**
 * @typedef {ReactDialog.DialogContentProps
 *   & React.HTMLAttributes<HTMLDivElement>
 *   & {
  *      children: React.ReactNode;
  *      className?: string;
 *     }
 *  } Props

 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<Props> & React.RefAttributes<HTMLElement>>} */
export const DialogContent = forwardRef<ReactDialog.Content, any>(
  ({
    children,
    className,
    ...props
  }, forwardedRef) => (
    <ReactDialog.Content
      className={clsx('legacy-compatible-dialog-content', className, style.DialogContent)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </ReactDialog.Content>
  ),
);
