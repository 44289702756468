// @flow

import { connect } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';

import AddressBook from './address-book';

export const mapStateToProps = (state: any) => ({
  workspace: getCurrentWorkspaceSelector(state),
});

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddressBook);
