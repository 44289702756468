/* eslint-disable max-len */
// @flow

import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import positions from 'reducers/entities/positions';
import accounts from 'reducers/entities/accounts';
import accountSeatsReducer from 'reducers/entities/account-seats';
import userAccounts, { getUserAccountBindings } from 'reducers/entities/user-accounts';
import {
  getPositionFromSessionSelector,
  getAccountFromSessionSelector,
  isFeatureEnabledSelector,
} from 'reducers/session';

import { isEmpty } from 'lodash';
import Users from './users';

const USERS_QUERY_NAME = 'admin/users';
const ACCOUNT_BINDINGS_QUERY_NAME = 'admin/account/users';
const DEFAULT_PAGINATION_LIMIT = 50;

export const mapStateToProps = (state: State) => {
  const usersQuery = positions.getQuerySelector(state, { name: USERS_QUERY_NAME });
  const account = getAccountFromSessionSelector(state);
  const accountSeats = accountSeatsReducer.getAccountSeatSelector(state, { id: account.id });
  let positionsResult = positions.getPositionsSelector(state, { ids: usersQuery.result });

  if (usersQuery.result.length > 0) {
    const userAccountBindings = getUserAccountBindings(state, { ids: usersQuery.result, account });

    if (!isEmpty(userAccountBindings)) {
      positionsResult = positionsResult.map((position) => {
        const bindings = userAccountBindings.filter((binding) => binding.position.id === position.id);
        return {
          ...position,
          userAccountBindings: bindings,
        };
      });
    }
  }

  return {
    isPositionLimitedFeatureEnabled: isFeatureEnabledSelector(state, {
      feature: 'temporaryPositionLimited',
    }),
    myPositionId: getPositionFromSessionSelector(state).id,
    account,
    fetchAccountState: accounts.getFetchSelector(state, { id: account.id }),
    positions: positionsResult,
    positionsQuery: usersQuery,
    accountSeats,
  };
};

export const mapDispatchToProps = (dispatch: Function) => ({
  queryPositions: ({ pagination, params, pipe }: Query) => {
    dispatch(positions.queryPositions({
      name: USERS_QUERY_NAME,
      pagination: {
        ...pagination,
        limit: DEFAULT_PAGINATION_LIMIT,
      },
      params: {
        ...params,
        accessStats: 1,
      },
      pipe,
    }));
  },
  fetchAccount: ({ id }) => {
    dispatch(accounts.fetchAccount({
      id,
    }));
  },
  editLinkPath: (positionId: number) => {
    const editLink = `/admin/users/${positionId}/details/?edit=true`;

    dispatch(push(editLink));
  },
  getUsersAccountBindings: ({ params }) => {
    dispatch(
      userAccounts.getUsersAccountBindings({
        id: ACCOUNT_BINDINGS_QUERY_NAME,
        data: {
          pagination: {
            limit: DEFAULT_PAGINATION_LIMIT,
          },
          params,
        },
      }),
    );
  },
});

export default connect<Users, State, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
