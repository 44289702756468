// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { getDateFormat } from 'date';

import extensionsReducer from 'reducers/entities/extensions';
import { getAccountFromSessionSelector } from 'reducers/session';

import MarketplaceComponent from './marketplace';

export const QUERY_NAME = '/admin/account/extensions';

type StateProps = {|
  query: Query,
  extensions: Array<Extension>,
  getUpdateState: number => UpdateState,
  dateFormat: string,
|};

type DispatchProps = {|
  resetUpdateState: number => void,
  queryExtensions: () => void,
  toggleExtensionState: Extension => void,
  updateExtension: Extension => void,
|};

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const query = extensionsReducer.getQuerySelector(state, { name: QUERY_NAME });
  const account = getAccountFromSessionSelector(state);

  return {
    query,
    extensions: extensionsReducer.getExtensionsSelector(state, { ids: query.result }),
    getUpdateState: (extensionId: number) => extensionsReducer.getUpdateSelector(state, {
      id: extensionId,
    }),
    dateFormat: getDateFormat(account.brandingDateFormat),
  };
};

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  resetUpdateState: (extensionId: number) => {
    dispatch(extensionsReducer.updateExtensionReset({
      id: extensionId,
    }));
  },
  queryExtensions: () => {
    dispatch(extensionsReducer.queryExtensions({
      name: QUERY_NAME,
    }));
  },
  toggleExtensionState: (extension: Extension) => {
    if (!extension.extensionId) {
      dispatch(extensionsReducer.createExtension({
        data: {
          key: extension.key,
        },
      }));
      return;
    }

    const newExtensionState = !extension.state;

    dispatch(extensionsReducer.updateExtension({
      id: extension.extensionId,
      data: {
        state: newExtensionState,
      },
    }));
  },
  updateExtension: ({
    id,
    state,
    config,
  }: Extension) => {
    dispatch(extensionsReducer.updateExtension({
      id,
      data: {
        state,
        config,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(MarketplaceComponent);
