// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import style from './extension-configuration-header.module.scss';

export const ExtensionConfigurationHeader = () => (
  <div className={style.ExtensionConfigurationHeader}>
    <Message
      id="Configuration"
      comment="Extension configuration header"
    />
  </div>
);
