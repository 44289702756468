import { useRef } from 'react';

const useLazyRef = <T>(fn: () => T) => {
  const ref = useRef<T>();

  if (ref.current === undefined) {
    ref.current = fn();
  }

  return ref as React.MutableRefObject<T>;
};

export default useLazyRef;
