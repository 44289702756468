import get from 'lodash/get';

import { Message } from '@oneflowab/pomes';

// eslint-disable-next-line import/named
import {
  ApiError, getErrorMessage, getErrorCode,
} from 'components/api-error';
import Failed from 'components/icons/failed';

import style from './document-action-error-body.module.scss';

type Props = {
  error: any
};

const DocumentActionErrorBody = ({
  error,
}: Props) => {
  const getApiError = () => {
    const errorCode = get(error, 'body.api_error_code');

    return getErrorMessage(errorCode);
  };

  const getApiErrorMessage = () => {
    const apiError = getApiError();
    const errorCode = getErrorCode(error);

    if (errorCode) {
      return <ApiError errorCode={errorCode} />;
    }

    if (apiError) {
      return <ApiError customMessage={apiError} />;
    }

    return (
      <Message
        id="Something went wrong, please try again, and if not resolved {contactSupport}"
        comment="Used to show the error message when in confirmation dialog the main action fails."
        values={{
          contactSupport: <a href="mailto:support@oneflow.com">contact support</a>,
        }}
      />
    );
  };

  return (
    <div className={style.ErrorBody}>
      <div className={style.FailedIconContainer}>
        <Failed className={style.FailedIcon} />
      </div>
      <div className={style.ErrorBodyHeader}>
        <Message
          id="Failed"
          comment="Message in modal indicating that an action in a contract has failed"
        />
      </div>
      <div className={style.ErrorBodyInfo}>
        {getApiErrorMessage()}
      </div>

    </div>
  );
};

export default DocumentActionErrorBody;
