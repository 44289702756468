import {
  select, takeEvery, take, put, call,
} from 'redux-saga/effects';

import {
  ORIGINAL_LOCATION_CHANGE,
  LOCATION_CHANGE,
  locationChange,
  searchChange,
} from 'reducers/router';

import { getCurrentWorkspaceIdSelector } from 'reducers/app';
import { setCurrentWorkspace } from 'sagas/bootup';
import sessionStorage from 'utils/session-storage';
import { readWorkspaceIdFromLocation } from 'hocs/with-current-workspace';

export function* scrollToTop() {
  yield call(window.scrollTo, 0, 0);
}

export function* onLocationChange(action) {
  const { location } = action;
  const workspaceIdFromLocation = readWorkspaceIdFromLocation(location);
  const currentWorkspaceId = yield select(getCurrentWorkspaceIdSelector);

  if (currentWorkspaceId
    && workspaceIdFromLocation
    && workspaceIdFromLocation !== currentWorkspaceId) {
    yield call(setCurrentWorkspace);
  }
}

export default function* router() {
  const initialAction = yield take(ORIGINAL_LOCATION_CHANGE);
  let previousLocation = initialAction.payload.location;

  yield takeEvery(LOCATION_CHANGE, onLocationChange);

  yield put(locationChange({
    action: initialAction.payload.action,
    location: previousLocation,
    previousLocation: null,
  }));

  yield takeEvery(LOCATION_CHANGE, scrollToTop);

  while (true) {
    const { payload: { location, action } } = yield take(ORIGINAL_LOCATION_CHANGE);

    if (location.pathname !== previousLocation.pathname) {
      yield put(locationChange({
        action,
        location,
        previousLocation,
      }));
    } else if (location.search !== previousLocation.search) {
      yield put(searchChange({
        action,
        location,
        previousLocation,
      }));
    }

    if (/\/contracts\/all/.test(location.pathname)) {
      yield call(sessionStorage.setItem, 'last-contract-search', location.pathname + location.search);
    }

    previousLocation = location;
  }
}
