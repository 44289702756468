import { Message } from '@oneflowab/pomes';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import type { ReactNode } from 'react';

import ModalForm from 'hocs/modal-form';
import positionGroupsReducer from 'reducers/entities/position-groups';
import groupsReducer from 'reducers/entities/groups';

import Field from 'components/field';
import SelectField from 'components/select-field';

export type LoadMoreArgs = {
  additionalResults: number
}

type Props = {
  children: ReactNode,
  shouldRedirectToUserGroups: boolean,
  position: Oneflow.Position,
}

const queryName = ({ id }: { id: string }) => `admin/users/${id}/groups`;
const formQueryName = ({ id }: { id: string }) => `forms/users/${id}/groups/available`;

export const AssignUserToGroupsComponent = ({
  children,
  shouldRedirectToUserGroups,
  position,
}: Props) => {
  const dispatch = useDispatch();

  const availableGroupsQuery = useSelector(
    (state) => positionGroupsReducer.getQuerySelector(state, {
      name: formQueryName({ id: position.id }),
    }),
  );
  const availableGroups = useSelector((state) => groupsReducer.getGroupsSelector(state, {
    ids: availableGroupsQuery.result,
  }));

  const formState = useSelector((state) => positionGroupsReducer.getCreateSelector(state));
  const resetFormState = () => {
    dispatch(positionGroupsReducer.createPositionGroupReset());
  };

  const queryPositionAvailableGroupsLoadMore = (
    { additionalResults }: { additionalResults: number },
  ) => (
    dispatch(positionGroupsReducer.queryPositionGroupsLoadMore({
      name: formQueryName(position),
      additionalResults,
    }))
  );

  const queryPositionAvailableGroups = ({ name }: { name?: string } = {}) => {
    dispatch(positionGroupsReducer.queryPositionGroups({
      name: formQueryName({ id: position.id }),
      pagination: {
        limit: 6,
        offset: 0,
      },
      params: {
        q: name,
        positionId: position.id,
        available: 1,
      },
    }));
  };

  const handleOpen = () => {
    queryPositionAvailableGroups({});
  };

  const handleInputChange = (name: string) => {
    queryPositionAvailableGroups({ name });
  };

  const handleLoadMoreItems = (additionalResults: number) => {
    queryPositionAvailableGroupsLoadMore({ additionalResults });
  };

  const handleSubmit = (formData: FormData) => {
    const actionData = {
      data: {
        id: position.id,
        groupIds: formData.group.map(({ id }) => id),
      },
      pipe: {
        action: () => positionGroupsReducer.queryPositionGroupsReload({
          name: queryName(position),
        }),
      },
    };

    if (shouldRedirectToUserGroups) {
      actionData.pipe.action = () => (push(`/admin/users/${position.id}/groups`));
    }

    dispatch(positionGroupsReducer.createPositionGroup(actionData));
  };

  const renderBody = () => (
    <div>
      <Field
        name="group"
        label={(
          <Message
            id="Groups"
            comment="The label for the relevant field in assign user to group section"
          />
        )}
        placeholder={(
          <Message
            id="Select groups"
            comment="The placeholder for the relevant field in assign user to group section"
          />
        )}
        component={SelectField}
        multi
        closeOnSelect={false}
        backspaceRemoves
        valueKey="id"
        labelKey="name"
        options={availableGroups}
        isLoading={availableGroupsQuery.loading}
        onInputChange={handleInputChange}
        loadMoreItems={handleLoadMoreItems}
        required
      />
    </div>
  );

  return (
    <ModalForm
      title={(
        <Message
          id="Add to groups"
          comment="Modal title for adding user to groups"
        />
      )}
      body={renderBody()}
      onSubmit={handleSubmit}
      formState={formState}
      resetFormState={resetFormState}
      onOpen={handleOpen}
      modalKey="add user to groups modal"
    >
      {children}
    </ModalForm>
  );
};

export default AssignUserToGroupsComponent;
