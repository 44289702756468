// @flow

import * as React from 'react';

import { Message } from '@oneflowab/pomes';

import InfoBoxClipboard from 'components/info-box-clipboard';

type Props = {
  token: string,
  canRegenerateToken?: boolean,
};

class TokenInfo extends React.PureComponent<Props> {
  static defaultProps = {
    canRegenerateToken: undefined,
  };

  renderLostTokenGuide() {
    const { canRegenerateToken } = this.props;

    if (!canRegenerateToken) {
      return (
        <>
          <Message
            id="If you lose your token, you need to disable the extension and enable it again to get a new one."
            comment="Help text explaining how to generate a new API token."
          />
        </>
      );
    }

    return (
      <Message
        id="If you lose your token, you need to generate a new one."
        comment="Help text explaining how to generate a new API token."
      />
    );
  }

  render() {
    const { token } = this.props;

    return (
      <>
        <p>
          <Message
            id="An API token has been generated for you. You will not be able to see this token again later, so we suggest you write it down immediately."
            comment="Help text suggesting to save API token for a recently enabled extension."
          />
        </p>
        <InfoBoxClipboard value={token} />
        <p>
          {this.renderLostTokenGuide()}
        </p>
      </>
    );
  }
}

export default TokenInfo;
