// @flow

import { useSelector } from 'react-redux';

import { isFeatureEnabledSelector } from 'reducers/session';

const useFeatureFlag = (featureFlag: string) => (
  useSelector((state) => (
    isFeatureEnabledSelector(state, {
      feature: featureFlag,
    })
  ))
);

export default useFeatureFlag;
