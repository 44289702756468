// @flow

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import { getDateWithSetHour } from 'date';
import clsx from 'clsx';

import agreementsReducer from 'reducers/entities/agreements';
import { useUserEvents } from 'contexts/user-events';

import ModalForm from 'hocs/modal-form';
import useWindowSize from 'hooks/use-window-size';

import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import Delete from 'components/icons/delete';
import CircularSpinner from 'components/icons/circular-spinner';

import InternalReminderEditBody from './internal-reminder-edit-body';
import useInitialValues from './use-initial-values';
import useRecipientsValidation from './use-recipients-validation';
import getRecipientsWithAccessInfo from './get-recipients-with-access-info';

import style from './internal-reminder.module.scss';

const modalKey = 'edit custom reminder modal';

export type FormData = {|
  contractFieldOption: {},
  recipients: [],
  date: string,
  timeFieldOption: string,
  subject: string,
  message: string,
|};
export type Props = {
  currentWorkspaceId?: number,
  message: MessageTranslator,
  children?: React.Element<Message>,
  internalReminderEvent?: InternalReminderEvent,
};

export const InternalReminderEditComponent = ({
  message,
  currentWorkspaceId,
  children,
  internalReminderEvent,
  ...restProps
}: Props) => {
  const [activeDeleteAction, setActiveDeleteAction] = React.useState<boolean>(false);
  const [{ validatedRecipients, userConsent }, sendValidationEvent] = useRecipientsValidation();

  const {
    refetchInternalReminders,
    currentUser,
  } = useUserEvents();
  const initialValues = useInitialValues.forSelectedEvent({
    message,
    currentUser,
    internalReminderEvent,
  });

  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  const rpcState = useSelector((state) => (
    agreementsReducer.getReScheduleInternalReminderSelector(state,
      { id: internalReminderEvent?.agreement.id })
  ));

  const resetRpcState = () => {
    dispatch(agreementsReducer.reScheduleInternalReminderReset({
      id: internalReminderEvent?.agreement.id,
    }));
  };

  const handleModalClose = () => {
    resetRpcState();
    sendValidationEvent({ type: 'validation/reset' });
  };

  const confirmSubmit = (formData: FormData) => {
    const contractId = formData.contractFieldOption.contract.id;
    const dateFromTimestamp = new Date(formData.date * 1000);
    const dateAndTime = getDateWithSetHour(
      dateFromTimestamp,
      formData.timeFieldOption.timeAsInteger,
    );
    const toRecipients = formData.recipients.map((recipient) => recipient.id);

    dispatch(agreementsReducer.reScheduleInternalReminder({
      id: contractId,
      data: {
        recipients: toRecipients,
        date: dateAndTime,
        subject: formData.subject,
        message: formData.message,
        internalReminderId: internalReminderEvent?.id,
      },
      pipe: {
        onSuccess: () => {
          // We could wait for rpcState.status === 'success' in useEffect
          // However it is better to avoid it whenever possible
          // Though it is local state it is synced with formValues in body component
          sendValidationEvent({ type: 'validation/reset' });
          refetchInternalReminders();
        },
      },
    }));
  };

  const handleSubmit = async (formData: FormData) => {
    if (userConsent === 'given') {
      confirmSubmit(formData);
      return;
    }

    const recipientsWithAccessInfo = await getRecipientsWithAccessInfo(formData);

    if (recipientsWithAccessInfo.some((recipient) => recipient.needsAccess)) {
      sendValidationEvent({
        type: 'recipients/validated',
        validatedRecipients: recipientsWithAccessInfo,
      });
      return;
    }

    confirmSubmit(formData);
  };

  const handleDeleteSubmit = () => {
    setActiveDeleteAction(false);

    dispatch(agreementsReducer.removeInternalReminder({
      id: internalReminderEvent.agreement.id,
      data: {
        agreementId: internalReminderEvent.agreement.id,
        internalReminderId: internalReminderEvent?.id,
      },
      pipe: {
        onSuccess: refetchInternalReminders,
      },
    }));
  };

  const getActions = ({ closeConfirmation, formProps }) => {
    const windowSizeWidth = windowSize.width;
    const deleteButtonElement = (
      <Button
        icon={Delete}
        kind="linkInline"
        data-testid="delete-link"
        onClick={() => setActiveDeleteAction(true)}
      >
        {windowSizeWidth > 500
          ? (
            <Message
              id="Delete reminder"
              comment="Button label used to confirm sending a contract in a modal"
            />
          ) : null}
      </Button>
    );

    if (activeDeleteAction) {
      return (
        <>
          <span className={clsx({
            [style.DeleteWarning]: windowSizeWidth >= 500,
            [style.DeleteWarningMobileLarge]: (windowSizeWidth < 500 && windowSizeWidth > 350),
            [style.DeleteWarningMobileSmall]: windowSizeWidth <= 350,
          })}
          >
            {windowSizeWidth > 500
              ? (
                <Message
                  id="Are you sure you want to delete this reminder?"
                  comment="Warning to delete reminder"
                />
              ) : (
                <Message
                  id="Delete reminder?"
                  comment="Warning to delete reminder"
                />
              )}

          </span>
          <div className={style.ActionButtons}>
            <CancelButton
              onClick={() => setActiveDeleteAction(false)}
              className={style.CancelButtonDelete}
              modalKey={`${modalKey} - error action`}
            />
            <Button
              color="red"
              data-testid="delete"
              icon={Delete}
              onClick={() => {
                handleDeleteSubmit();
                closeConfirmation();
              }}
            >
              <Message
                id="Delete"
                comment="Button label used to delete a reminder in a modal"
              />
            </Button>
          </div>
        </>
      );
    }

    if (validatedRecipients.some((recipient) => recipient.needsAccess) && userConsent === 'given') {
      return (
        <>
          <div
            className={clsx(style.DeleteLink, {
              [style.DeleteLinkMobile]: windowSizeWidth < 500,
            })}
          >
            {deleteButtonElement}
          </div>
          <div className={style.ActionButtons}>
            <CancelButton
              onClick={closeConfirmation}
              className={style.CancelButtonConfirm}
              modalKey={`${modalKey} - consent given`}
            />
            <Button
              icon={rpcState.loading && CircularSpinner}
              color="yellow"
              data-testid="confirm"
              onClick={formProps.handleSubmit}
              disabled={rpcState.loading || !isEmpty(formProps.errors)}
            >
              <Message
                id="Confirm"
                comment="Button label used to confirm sending a contract in a modal"
              />
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={clsx(style.DeleteLink, {
          [style.DeleteLinkMobile]: windowSizeWidth < 500,
        })}
        >
          {deleteButtonElement}
        </div>
        <div className={style.ActionButtons}>
          <CancelButton
            onClick={closeConfirmation}
            className={style.CancelButtonConfirm}
            modalKey={modalKey}
          />
          <Button
            icon={rpcState.loading && CircularSpinner}
            color="yellow"
            data-testid="saveChanges"
            onClick={async (...args) => {
              await formProps.handleSubmit(...args);
              sendValidationEvent({ type: 'user/givenConsent' });
            }}
            disabled={
              rpcState.loading
              || !isEmpty(formProps.errors)
            }
          >
            <Message
              id="Save changes"
              comment="Button label used to confirm sending a contract in a modal"
            />
          </Button>
        </div>
      </>
    );
  };

  const getTitle = () => (
    <Message
      id="Edit reminder"
      comment="Modal title for viewing a reminder in edit mode."
    />
  );

  return (
    <ModalForm
      title={getTitle()}
      body={(
        <InternalReminderEditBody
          currentWorkspaceId={currentWorkspaceId}
          internalReminderEvent={internalReminderEvent}
          validatedRecipients={validatedRecipients}
          sendValidationEvent={sendValidationEvent}
          userConsent={userConsent}
        />
      )}
      formState={rpcState}
      resetFormState={resetRpcState}
      actions={getActions}
      onSubmit={handleSubmit}
      onClose={handleModalClose}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      modalKey={modalKey}
      {...restProps}
    >
      {children || null}
    </ModalForm>
  );
};

export default localize<Props>(InternalReminderEditComponent);
