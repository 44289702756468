import { uniq } from 'lodash';

import agreementsReducer from 'reducers/entities/agreements';

import { checkAcl } from 'components/acl';

type SelectedDocumentData = ReturnType<typeof agreementsReducer.getAgreementsSelector>;

export const hasDocumentMovePermission = (document: Oneflow.Agreement) => (
  checkAcl(document.acl, 'agreement:move')
);

export const isDocumentAlreadyInTargetDestination = ({
  targetFolderId,
  document,
  targetWorkspace,
}: {
  targetFolderId: number | null | undefined,
  document: Oneflow.Agreement,
  targetWorkspace: Oneflow.Workspace,
}) => {
  // Since the folder id is a unique identifier, if they are the same, we don't need to check the
  // workspace.
  if (targetFolderId && targetFolderId !== -1) {
    return document.folder?.id === targetFolderId;
  }

  // At this point, the `targetFolderId` is undefined, so if the document is in a folder, it
  // is not in the target destination.
  if (document.folder?.id) {
    return false;
  }

  // If the document is not in a folder, we need to check if it is in the same workspace as the
  // target workspace.
  return targetWorkspace.id === document.collection?.id;
};

export const canDocumentBeMoved = ({
  targetFolderId,
  document,
  targetWorkspace,
}: {
  targetFolderId: number | null | undefined,
  document: Oneflow.Agreement,
  targetWorkspace: Oneflow.Workspace,
}) => (
  hasDocumentMovePermission(document)
  && !isDocumentAlreadyInTargetDestination({
    targetFolderId,
    document,
    targetWorkspace,
  })
);

export const getDefaultFolderId = (
  selectedDocumentData: SelectedDocumentData,
) => {
  const uniqueFolderIds = uniq(
    selectedDocumentData.map(({ folder }) => folder?.id),
  );
  const allDocumentsAreInTheSameFolder = uniqueFolderIds.length === 1;
  if (uniqueFolderIds.length === 0) {
    return -1;
  }

  if (allDocumentsAreInTheSameFolder) {
    if (uniqueFolderIds[0] === undefined || uniqueFolderIds[0] === null) {
      return -1;
    }

    return uniqueFolderIds[0];
  }

  return -1;
};
