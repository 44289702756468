/* eslint-disable import/named */
import { normalize, schema } from 'normalizr';
import { decamelizeKeys } from 'humps';

import {
  extractResponseBodyAsJSON,
  getMultiple,
} from 'oneflow-client/core';

type BindingsData = {
  collection: Oneflow.AccountUserRoleBinding[],
  count: number,
};

type GetAccountUserBindings = {
  pagination: {
    limit: number,
    offset: number,
  },
  params: {
    actorType: string,
  }
  sort: string[],
};

const accountUsersBindingsSchema = new schema.Entity('accountUsersBindings');

const normalizeAccountUsers = (bindingsData: BindingsData) => ({
  ...normalize(bindingsData.collection, [accountUsersBindingsSchema]),
  count: bindingsData.count,
});

const getAccountUserBindings = ({
  pagination,
  params,
  sort,
}: GetAccountUserBindings) => (
  getMultiple({
    url: '/accounts/bindings/',
    params: decamelizeKeys(params, { separator: '_' }),
    pagination,
    sort,
  }).then(extractResponseBodyAsJSON)
    .then(normalizeAccountUsers)
);

export default getAccountUserBindings;
