// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import { hasAnyWorkspace } from 'oneflow-client/workspaces';

import ModalForm from 'hocs/modal-form';
import Field from 'components/field';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import TextArea from 'components/text-area';
import Checkbox from 'components/checkbox';
import { getWorkspaceTypesAsOptions } from 'components/workspace-type';
import { USER_WORKSPACE_ROLE_MANAGER_ID, USER_ACTOR_TYPE } from 'user';
import { checkAcl } from 'components/acl';

import style from './add-workspace.module.scss';

export type FormData = {
  name: string,
  type: Option,
  description: string,
  isAddMyselfChecked: boolean,
  positionId: number,
};

export type Props = {
  message: MessageTranslator,
  formState: CreateState,
  onSubmit: FormData => void,
  resetFormState: () => void,
  children: Function => React.Node,
  position: Position,
  addMyselfToWorkspace: () => void,
};

export type State = {
  isAddMyselfChecked: boolean
}

export class AddWorkspaceComponent extends React.Component<Props, State> {
  state = {
    isAddMyselfChecked: true,
  }

  componentDidUpdate(prevProps: Props) {
    const { isAddMyselfChecked } = this.state;
    const { formState, position } = this.props;

    if (!prevProps.formState.success && formState.success) {
      const { result: createdWorkspaceId } = formState;
      if (isAddMyselfChecked) {
        const hasGrantUserAccessPermission = checkAcl(position.acl, 'position:collection:binding:create');
        if (hasGrantUserAccessPermission) {
          this.handleAddMyselfToWorkspace(createdWorkspaceId);
        }
      }
    }
  }

  handleAddMyselfToWorkspace = (createdWorkspaceId) => {
    const { addMyselfToWorkspace, position } = this.props;

    const data = {
      roleId: USER_WORKSPACE_ROLE_MANAGER_ID,
      actorId: position.id,
      actorType: USER_ACTOR_TYPE,
      resourceId: createdWorkspaceId,
      resourceType: 'collection',
    };

    addMyselfToWorkspace(data);
  }

  handleSubmit = (formData: FormData) => {
    const { onSubmit } = this.props;
    return onSubmit(formData);
  };

  renderBody() {
    const { message, position } = this.props;
    const { isAddMyselfChecked } = this.state;
    const hasGrantUserAccessPermission = checkAcl(position.acl, 'position:collection:binding:create');

    return (
      <div>
        <Field
          name="name"
          label={message({
            id: 'Name',
            comment: 'Label in the add workspace modal.',
          })}
          placeholder={message({
            id: 'Enter name',
            comment: 'Placeholder in the add workspace modal.',
          })}
          component={TextField}
          maxLength={50}
          unique={{
            text: message({
              id: 'A workspace with this name already exists. Try another one.',
              comment: 'Form validation error message.',
            }),
            param: 'name',
            request: hasAnyWorkspace,
          }}
          required
          autoFocus
          autoComplete="off"
        />
        <Field
          name="type"
          label={message({
            id: 'Type',
            comment: 'Label in the add workspace modal.',
          })}
          placeholder={message({
            id: 'Select type',
            comment: 'Placeholder in the add workspace modal.',
          })}
          component={SelectField}
          options={getWorkspaceTypesAsOptions(message)}
          clearable={false}
          searchable={false}
          required
        />
        <Field
          name="description"
          label={message({
            id: 'Description',
            comment: 'Label in the add workspace modal.',
          })}
          placeholder={message({
            id: 'Enter description',
            comment: 'Placeholder in the add workspace modal.',
          })}
          component={TextArea}
          maxLength={300}
        />
        {(hasGrantUserAccessPermission)
        && (
        <div className={style.CheckboxTextWrapper}>
          <Checkbox
            input={{
              checked: isAddMyselfChecked,
              onChange: () => this.setState({ isAddMyselfChecked: !isAddMyselfChecked }),
            }}
            label={message({
              id: 'Add myself to this workspace',
              comment: 'Used as the label for a checkbox that adds the current user to the workspace when they are creating a workspace',
            })}
          />
        </div>
        )}
      </div>
    );
  }

  render() {
    const {
      formState,
      resetFormState,
      children,
    } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Create workspace"
            comment="Modal title for creating workspace."
          />
        )}
        body={this.renderBody()}
        onSubmit={this.handleSubmit}
        formState={formState}
        resetFormState={() => {
          resetFormState();
          this.setState({ isAddMyselfChecked: true });
        }}
        modalKey="create workspace modal"
      >
        {children}
      </ModalForm>
    );
  }
}

export default localize<Props>(AddWorkspaceComponent);
