// @flow

import type { ContextRouter } from 'react-router';

import * as React from 'react';
import { Redirect } from 'react-router';
import urlJoin from 'url-join';

import { checkAcl } from 'components/acl';
import adminPage from 'hocs/admin-page';
import { NotFound } from 'components/errors';

import ContractTemplates from 'routes/templates/contract-templates';
import MessageTemplates from 'routes/templates/message-templates';

type Props = {
  workspace: Workspace,
  workspaceAcl: Acl,
  ...ContextRouter,
};

export const TemplatesComponent = (props: Props) => {
  const {
    workspaceAcl,
    ...routeProps
  } = props;

  if (checkAcl(workspaceAcl, 'collection:agreement:template:view')) {
    return (
      <Redirect from={props.match.path} to={urlJoin(props.match.url, '/contracts')} />
    );
  }

  if (checkAcl(workspaceAcl, 'collection:message:template:view')) {
    return (
      <Redirect from={props.match.path} to={urlJoin(props.match.url, '/messages')} />
    );
  }

  return (
    <NotFound
      {...routeProps}
      redirect
      to="/dashboard"
    />
  );
};

const getTemplatesModules = ({
  acl,
  message,
}) => {
  let modules = [];

  if (checkAcl(acl, 'collection:agreement:template:view')) {
    modules = [
      ...modules,
      {
        component: ContractTemplates,
        hideBreadCrumb: true,
        isMainPage: true,
        path: '/contracts',
        section: message({ id: 'Templates', comment: 'Used as the title for the section.' }),
        showWorkspace: true,
        title: message({ id: 'Contract templates', comment: 'Page title for the contract templates page.' }),
      },
    ];
  }

  if (checkAcl(acl, 'collection:message:template:view')) {
    modules = [
      ...modules,
      {
        component: MessageTemplates,
        hideBreadCrumb: true,
        isMainPage: true,
        path: '/messages',
        section: message({ id: 'Templates', comment: 'Used as the title for the section.' }),
        showWorkspace: true,
        title: message({ id: 'Message templates', comment: 'Page title for the message templates page.' }),
      },
    ];
  }

  return modules;
};

export default adminPage((
  {
    props: {
      message,
      workspaceAcl,
    },
  },
) => ({
  title: message({
    id: 'Templates',
    comment: 'Page title for the templates page.',
  }),
  showAsLink: false,
  modules: [
    getTemplatesModules({
      acl: workspaceAcl,
      message,
    }),
  ],
}))(TemplatesComponent);
