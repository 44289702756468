import { localize, Message, MessageTranslator } from '@oneflowab/pomes';
import { get } from 'lodash';

import { getParticipantParty } from 'agreement/selectors';
import { getParticipantRole, getParticipantSigningMethod } from 'agreement/participant';
import {
  SIGN_METHOD_DK_MITID_NEMID_SIGN,
  SIGN_METHOD_FI_FTN_SIGN,
  SIGN_METHOD_NO_BANKID_SIGN,
  SIGN_METHOD_SE_BANKID,
} from 'agreement/participant/constants';

import PersonalInfo from 'components/contract-card/participant-statistics/personal-info';
import PenIcon from 'components/icons/pen';
import SeBankIdLogo from 'components/modals/sign-contract/electronic-id-options/logos/se_bankid';
import NoBankIdLogo from 'components/modals/sign-contract/electronic-id-options/logos/no_bankid';
import DkMitIdLogo from 'components/modals/sign-contract/electronic-id-options/logos/dk_mitid';
import FiFtnLogo from 'components/modals/sign-contract/electronic-id-options/logos/fi_ftn';
import { EventLog } from 'components/contract-card/participant-statistics/event-log';
import style from './signed-participant-tooltip.module.scss';

type Props = {
  message: MessageTranslator,
  agreement: Oneflow.Agreement,
  participant: Oneflow.Participant
}

const getSignMethodLogo = (signMethod?: number | null) => {
  switch (signMethod) {
    case SIGN_METHOD_SE_BANKID:
      return <SeBankIdLogo height="48" />;
    case SIGN_METHOD_NO_BANKID_SIGN:
      return <NoBankIdLogo height="48" />;
    case SIGN_METHOD_DK_MITID_NEMID_SIGN:
      return <DkMitIdLogo height="48" />;
    case SIGN_METHOD_FI_FTN_SIGN:
      return <FiFtnLogo height="48" />;
    default:
      return <></>;
  }
};

const SignedParticipantTooltipComponent = ({ message, agreement, participant }: Props) => {
  const party = getParticipantParty(agreement, participant?.agreementCompany?.id);
  const signMethod = getParticipantSigningMethod(message, participant);
  const signMethodText = get(signMethod, 'short');

  return (
    <div className={style.Container}>
      <div className={style.ParticipantInfo}>
        <div className={style.ParticipantNameBadgeContainer}>
          <span className={style.ParticipantName}>{participant.fullname}</span>
          <span className={style.Badge}>{getParticipantRole(message, participant)}</span>
        </div>
        <span className={style.Company}>{party.name}</span>
        <span className={style.ParticipantTitle}>{participant.title}</span>
      </div>
      <hr className={style.Divider} />
      <PersonalInfo
        email={participant.email}
        phoneNumber={participant.phoneNumber}
      />
      <div className={style.SignMethodTextImageContainer}>
        <div>
          <div className={style.SignMethodLabelIconContainer}>
            <PenIcon className={style.PenIcon} height="11" />
            <span className={style.SignMethodLabel}>
              <Message id="Signed with" comment="Label for sign method" />
            </span>
          </div>
          <span className={style.SignMethodText}>{signMethodText}</span>
        </div>
        {getSignMethodLogo(participant.signMethod)}
      </div>
      <hr className={style.Divider} />
      <EventLog
        label={(
          <Message
            id="Signed"
            comment="Participant decision on the agreement. Seen in a tooltip"
          />
        )}
        timestamp={participant?.stateTimestampTs}
        dateFormat={`${agreement?.config?.dateFormat}, HH:mm`}
      />
    </div>
  );
};

export const SignedParticipantTooltip = localize(SignedParticipantTooltipComponent);
