import {
  useState, useCallback, useMemo, ReactNode,
} from 'react';
import clsx from 'clsx';
// eslint-disable-next-line import/named
import { remove } from 'oneflow-client/core';
import { useSelector } from 'react-redux';

import useAgreement from 'hooks/use-agreement';
import { isPublished } from 'agreement';
import { isContractPristine } from 'reducers/current-contract';
import { isAgreementOwner } from 'agreement/selectors';
import { getAccountFromSessionSelector } from 'reducers/session';

// eslint-disable-next-line import/named
import { RemoveConfirm } from 'components/modals/remove-confirm';
import { AGREEMENT_VIDEO_PROCESSING, AGREEMENT_WITH_VIDEO } from 'agreement/constants';
import CircularSpinnerIcon from 'components/icons/circular-spinner';
import Button from 'components/button';
import ReplaceNewIcon from 'components/icons/replace-new';
import DeleteIcon from 'components/icons/delete';
import { checkAcl } from 'components/acl';
import Message from 'components/message';
import getHref from '../helpers';
import style from './video-preview-actions.module.scss';

type Props = {
  agreementId: number,
  children?: ReactNode
}

type RemoveVideoState = {
  success?: boolean,
  loading?: boolean,
  error?: any,
  data?: any,
  id?: any,
}

const VideoPreviewActions = ({ agreementId, children }: Props) => {
  const agreement = useAgreement(agreementId);
  const isPristine = useSelector(isContractPristine);
  const account = useSelector(getAccountFromSessionSelector);
  const isOwner = isAgreementOwner(account, agreement);

  const [removeVideoState, setRemoveVideoState] = useState<RemoveVideoState>({
    id: undefined,
    data: undefined,
    success: false,
    loading: false,
    error: null,
  });
  const isAgreementPublished = isPublished(agreement);
  const href = getHref(isPristine, agreementId.toString());

  const hasVideo = useMemo(() => [
    AGREEMENT_VIDEO_PROCESSING,
    AGREEMENT_WITH_VIDEO,
  ].includes(agreement.welcomeVideo), [agreement.welcomeVideo]);

  const shouldComponentRender = useMemo(() => {
    if (isAgreementPublished) {
      if (!hasVideo) {
        return false;
      }
      return true;
    }
    return true;
  }, [hasVideo, isAgreementPublished]);

  const canRemoveVideo = useMemo(() => (
    checkAcl(agreement.acl, 'agreement:video:remove')
  ), [agreement.acl]);

  const canReplaceVideo = useMemo(() => (
    checkAcl(agreement.acl, 'agreement:video:add')
  ), [agreement.acl]);

  const updateRemoveVideoState = useCallback((newRemoveVideoState: RemoveVideoState) => {
    setRemoveVideoState((prevRemoveVideoState) => ({
      ...prevRemoveVideoState,
      ...newRemoveVideoState,
    }));
  }, []);

  const resetRemoveState = useCallback(() => {
    setRemoveVideoState({
      id: undefined,
      data: undefined,
      success: false,
      loading: false,
      error: null,
    });
  }, []);

  const removeVideo = useCallback(async () => {
    updateRemoveVideoState({
      id: agreement.id,
      loading: true,
    });
    try {
      await remove({ url: `/agreements/${agreement.id}/video` });
      updateRemoveVideoState({
        success: true,
        loading: false,
      });
    } catch (error) {
      updateRemoveVideoState({
        loading: false,
        error,
      });
    }
  }, [agreement.id, updateRemoveVideoState]);

  const deleteClasses = clsx(style.VideoActionButton, style.DeleteModalButton);

  const renderDeleteConfirm = useCallback(() => (
    <RemoveConfirm
      header={(
        <Message
          id="Delete video"
          comment="Modal title when deleting agreement video"
        />
      )}
      onConfirm={removeVideo}
      confirmState={removeVideoState}
      confirmMessage={(
        <>
          <Message
            id="The welcome video will be deleted."
            comment="Warning message in modal when attempting to delete a welcome video."
          />
          <p className={style.DeleteInfoText}>
            <Message
              id="This action cannot be undone."
              comment="Warning message in modal when attempting to delete a welcome video"
            />
          </p>
          <p>
            <Message
              id="Delete the video?"
              comment="Warning emssage in modal when attempting to delete a welcome video "
            />
          </p>
        </>
      )}
      outline
      resetConfirmState={resetRemoveState}
      modalKey="delete contract content video modal from contract view"
    >
      {(onClick: () => void) => (
        <Button
          customClass={deleteClasses}
          onClick={onClick}
          kind="linkInline"
          icon={removeVideoState.loading ? CircularSpinnerIcon : DeleteIcon}
          disabled={removeVideoState.loading}
        >
          <Message
            id="Delete"
            comment="Button text for removing the welcome video of a contract."
          />
        </Button>
      )}
    </RemoveConfirm>
  ), [
    removeVideo,
    removeVideoState,
    resetRemoveState,
    deleteClasses,
  ]);

  const renderActions = useCallback(() => (
    <div
      data-testid="video-action-buttons"
      className={clsx(style.VideoActionButtons, {
        [style.AgreementIsPublished]: isAgreementPublished,
      })}
    >
      {!isAgreementPublished && canReplaceVideo && (
        <Button
          customClass={style.VideoActionButton}
          href={href}
          target="_self"
          rel="noopener"
          external
          kind="linkInline"
          icon={ReplaceNewIcon}
          disabled={removeVideoState.loading || !isPristine}
          data-testid="replace-video-button"
        >
          <Message
            id="Replace video"
            comment="Button to replace the current welcome video"
          />
        </Button>
      )}
      {canRemoveVideo && renderDeleteConfirm()}
    </div>
  ), [
    href,
    isAgreementPublished,
    isPristine,
    removeVideoState.loading,
    renderDeleteConfirm,
    canRemoveVideo,
    canReplaceVideo,
  ]);

  if (!shouldComponentRender) {
    return null;
  }

  return (
    <div className={style.VideoPreviewActions}>
      <p className={style.Label}>
        <Message
          data-testid="title"
          id="Welcome video"
          comment="Name for the functionality that uploads a welcome video for users"
        />
      </p>
      {isOwner && (
        <div
          data-testid="agreement-video-helper-text"
          className={style.AgreementVideoHelperText}
        >
          <Message
            data-testid="description"
            id="Will be played when the counterparty opens the document for the first time."
            comment="Helper text to explain the purpose of the welcome video."
          />
        </div>
      )}
      {children}
      {hasVideo && renderActions()}
    </div>

  );
};

export default VideoPreviewActions;
