// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import accountsReducer from 'reducers/entities/accounts';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getAdjustedCurrentLanguageSelector } from 'reducers/i18n';
import { FREEMIUM_SELF_SERVICE_HEADER } from 'account/billing';

import { FreemiumSelfServiceConfirm, type Props, type ModalData } from './freemium-self-service-confirm';

type StateProps = {|
  accountId: number,
  updateState: UpdateState,
  languageCode: EnabledLanguages,
|};

type DispatchProps = {|
  onConfirm: (id: number) => void,
  resetUpdateState: (id: number) => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {|
  modalData: ModalData,
|};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

type MapStateToProps = (State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);

  return {
    accountId: account.id,
    updateState: accountsReducer.getBuySeatsSelector(state, { id: account.id }),
    languageCode: getAdjustedCurrentLanguageSelector(state),
  };
};

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { modalData }) => ({
  onConfirm: (id) => {
    dispatch(accountsReducer.buySeats({
      id,
      data: {
        numberOfSeats: modalData.numberOfSeats,
        planId: modalData.planId,
        amplitudeScope: FREEMIUM_SELF_SERVICE_HEADER,
      },
    }));
  },
  resetUpdateState: (id) => {
    dispatch(accountsReducer.buySeatsReset({ id }));
  },
});

const connectedFreemiumSelfServiceConfirm = connect<
Props, OwnProps, StateProps, DispatchProps, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(FreemiumSelfServiceConfirm);

export { connectedFreemiumSelfServiceConfirm as FreemiumSelfServiceConfirm };
