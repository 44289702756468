// @flow

import normalizeEntity from 'normalized-redux/entity-normalizer';

const normalizedFolders = normalizeEntity({ entity: 'folder' });

export const getFoldersSelector = (state: State) => (
  state.entities.folders.entities
);

export default normalizedFolders;
