import Message from 'components/message';
import Tooltip from 'components/tooltip';

type Props = {
  children: React.ReactNode;
  hideContent?: boolean;
};
const FieldTooltip = ({ children, hideContent }: Props) => (
  <Tooltip
    hideContent={hideContent}
    message={(
      <Message
        id="Formatting can be applied only to templates or draft documents created from blank."
        comment="Tooltip message explaining why this option is disabled."
      />
    )}
    zIndex="1031"
    side="top"
    sideOffset={-12}
  >
    {children}
  </Tooltip>
);

export default FieldTooltip;
