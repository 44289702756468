import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { useState, useMemo, useCallback } from 'react';
import { localize, Message, MessageTranslator } from '@oneflowab/pomes';

import { amplitudeLogEvent } from 'client-analytics/amplitude';

import { AiInsightsModal } from 'components/modals/ai-insights';
import {
  isStaticDataPoint,
  isWarning,
  getColorPerIndex,
} from '../utils';
import { CustomLegend } from '../legend';
import {
  MISSING, FOUND, UNSPECIFIED, terminationTypes,
  CONTRACT_NOT_ANALYSED_COLOR,
  CONTRACT_ANALYSED_WITH_ISSUES,
  CONTRACT_ANALYSED_NO_ISSUES,
  CONTRACT_NOT_ANALYSED,
  WARNING_COLORS,
  BREACH_COLORS,
  NON_BREACH_COLORS,
} from '../constants';
import type { InsightDataItem } from '../../types';

import style from './pie-chart.module.scss';

const getLabel = (label: string, message: MessageTranslator) => {
  // Generic
  if (label === MISSING) {
    return message({ id: 'Missing', comment: 'Label for the chart' });
  }
  if (label === FOUND) {
    return message({ id: 'Found', comment: 'Label for the chart' });
  }
  if (label === UNSPECIFIED) {
    return message({ id: 'Unspecified', comment: 'Label showed in the chart legend for data that could not be classified to any category' });
  }

  // Total contracts
  if (label === CONTRACT_ANALYSED_WITH_ISSUES) {
    return message({ id: 'Issues', comment: 'Label for the total contracts' });
  }
  if (label === CONTRACT_ANALYSED_NO_ISSUES) {
    return message({ id: 'No issues', comment: 'Label for the total contracts' });
  }
  if (label === CONTRACT_NOT_ANALYSED) {
    return message({ id: 'Not analysed', comment: 'Label for the total contracts' });
  }

  // Termination
  if (label === terminationTypes.CONVENIENCE) {
    return message({ id: 'Convenience', comment: 'Label for the termination' });
  }
  if (label === terminationTypes.BREACH) {
    return message({ id: 'Breach', comment: 'Label for the termination' });
  }
  if (label === terminationTypes.CONTROL) {
    return message({ id: 'Change of control', comment: 'Label for the termination' });
  }
  if (label === terminationTypes.INSOLVENCY) {
    return message({ id: 'Insolvency', comment: 'Label for the termination' });
  }

  return label;
};

const CustomTooltip = ({ active = false, payload }: ComponentProps<typeof Tooltip>) => {
  if (active && payload && payload.length) {
    return (
      <div className={style.Tooltip}>
        <Message
          id="{label}: {count} documents"
          values={{ label: payload[0].payload.label, count: payload[0].value }}
          comment="Tooltip text showing the label and count of documents."
        />
      </div>
    );
  }

  return null;
};

type Data = {
  data: Array<InsightDataItem>,
};

type Props = {
  data: Data,
  dataKey: string,
  nameKey: string,
  message: MessageTranslator,
  filterParams: any,
  title: string,
  chartName: string,
};

type ChartDataEntry = {
  label: string,
  labelKey: string,
  count: number,
  isBreach: boolean | null,
  breachSeverity: 'INFO' | 'CRITICAL' | 'WARNING',
}

const PieInsightChartComponent = ({
  data,
  dataKey,
  nameKey,
  filterParams,
  title,
  message,
  chartName,
}: Props) => {
  const [hoveredLegend, setHoveredLegend] = useState<string | null>(null);
  const [selectedDataPoint, setSelectedDataPoint] = useState<string | null>(null);

  const getChartData = useCallback((dataArray: Array<InsightDataItem>) => {
    const { breaches, nonBreaches, warnings } = dataArray.reduce(
      (acc, item) => {
        const formattedItem = {
          label: getLabel(item.label, message),
          count: item.count,
          isBreach: item.isBreach,
          labelKey: item.label,
          breachSeverity: item.breachSeverity,
        };
        if (isWarning(item.breachSeverity)) {
          acc.warnings.push(formattedItem);
        } else if (item.isBreach) {
          acc.breaches.push(formattedItem);
        } else {
          acc.nonBreaches.push(formattedItem);
        }

        return acc;
      },
      { breaches: [], nonBreaches: [], warnings: [] },
    );

    const chartData = [...breaches, ...nonBreaches, ...warnings];

    return {
      chartData,
      breaches,
      nonBreaches,
      warnings,
    };
  }, [message]);

  const {
    chartData, warnings, breaches, nonBreaches,
  } = useMemo(() => getChartData(data.data), [data.data, getChartData]);

  const handleMouseEnter = useCallback((key: string) => {
    setHoveredLegend(key);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredLegend(null);
  }, []);

  const getFillColor = useCallback((entry: ChartDataEntry) => {
    if (entry.isBreach === null) {
      return CONTRACT_NOT_ANALYSED_COLOR;
    }

    const isHovered = isStaticDataPoint(entry.labelKey) ? false : hoveredLegend === entry.label;
    const getIndex = (array: ChartDataEntry[]) => (
      array.findIndex(({ label }) => label === entry.label)
    );

    if (isWarning(entry.breachSeverity)) {
      const index = getIndex(warnings);
      return getColorPerIndex(WARNING_COLORS, index, isHovered);
    } if (entry.isBreach) {
      const index = getIndex(breaches);
      return getColorPerIndex(BREACH_COLORS, index, isHovered);
    }
    const index = getIndex(nonBreaches);
    return getColorPerIndex(NON_BREACH_COLORS, index, isHovered);
  }, [
    breaches, nonBreaches, warnings, hoveredLegend,
  ]);

  const handleLegendClick = useCallback((label: string) => {
    const dataPoint = chartData.find(
      (item: ChartDataEntry) => getLabel(item.label, message) === label,
    ) || { labelKey: '' };

    if (isStaticDataPoint(dataPoint.labelKey)) return;

    setSelectedDataPoint(dataPoint.labelKey);

    amplitudeLogEvent('Go To AI Insights Contract List', {
      'chart type': chartName,
      location: 'ai insights tab - chart label',
    });
  }, [chartData, chartName, message]);

  const handleSegmentClick = useCallback((label: string) => {
    setSelectedDataPoint(label);
    amplitudeLogEvent('Go To AI Insights Contract List', {
      'chart type': chartName,
      location: 'ai insights tab - chart segment',
    });
  }, [chartName]);

  if (!data) {
    return null;
  }

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height={300}
      >
        <PieChart>
          <Pie
            data={chartData}
            dataKey={dataKey}
            nameKey={nameKey}
            innerRadius="55%"
          >
            {chartData.map((entry: ChartDataEntry) => (
              <Cell
                key={`cell-${entry.label}`}
                fill={getFillColor(entry)}
                onMouseEnter={() => handleMouseEnter(entry.label)}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  if (isStaticDataPoint(entry.labelKey)) return;
                  handleSegmentClick(entry.labelKey);
                }}
                style={{
                  cursor: isStaticDataPoint(entry.labelKey) ? 'default' : 'pointer',
                  outline: 'none',
                }}
              />
            ))}
          </Pie>
          <Tooltip content={({ active, payload }) => (
            <CustomTooltip active={active} payload={payload} />
          )}
          />
          <Legend content={(
            <CustomLegend
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleLegendClick}
              getLegendLabel={getLabel}
            />
        )}
          />
        </PieChart>
      </ResponsiveContainer>
      {selectedDataPoint && (
      <AiInsightsModal
        onClose={() => setSelectedDataPoint(null)}
        filterParams={filterParams}
        clickedLabel={{
          key: selectedDataPoint, value: getLabel(selectedDataPoint, message),
        }}
        clickedInsightType={{ key: data.type.toLowerCase(), value: title }}
      />
      )}
    </>
  );
};

export const PieInsightChart = localize(PieInsightChartComponent);
