import { ElementType, ReactNode } from 'react';
import clsx from 'clsx';

import EmptyState from 'components/empty-state';

import style from './box-empty-draft-state-raw.module.scss';

type Props = {
  icon: ElementType,
  mainAction: ReactNode,
  customClassName?: string,
};

const BoxEmptyDraftStateRaw = ({
  icon: IconComponent,
  mainAction,
  customClassName,
}: Props) => (
  <EmptyState
    icon={(
      <div className={style.Icon}>
        <IconComponent height="24px" />
      </div>
    )}
    header={<div className={style.EmptySpace} />}
    content={mainAction}
    className={clsx(style.BoxEmptyDraftState, customClassName)}
  />
);

export default BoxEmptyDraftStateRaw;
