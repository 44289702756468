import { Message } from '@oneflowab/pomes';
import { isMarkedAsSigned } from 'agreement';

import style from './style.module.scss';

type Props = {
  agreement: Oneflow.Agreement;
};

const MarkedAsSignMessage = ({ agreement }: Props) => {
  if (isMarkedAsSigned(agreement)) {
    return (
      <span className={style.SemiBold}>
        <Message
          id="The document has no legally binding e-signature."
          comment="Message in the audit trail when a document is marked as signed"
        />
      </span>
    );
  }

  return null;
};

export default MarkedAsSignMessage;
