export const getEidStandAloneList = (message) => ([
  {
    key: 'seBankid',
    title: message({ id: 'Swedish BankID', comment: 'Title for an electronic ID' }),
    isEnabled: true,
    isVisible: true,
    countries: [
      'SE',
    ],
  },
  {
    key: 'noBankid',
    title: message({ id: 'Norwegian BankID', comment: 'Title for an electronic ID' }),
    isEnabled: true,
    isVisible: true,
    countries: [
      'NO',
    ],
  },
  {
    key: 'dkNemid',
    title: 'MitID',
    isEnabled: true,
    isVisible: true,
    countries: [
      'DK',
    ],
  },
  {
    key: 'fiFtn',
    title: 'Finnish Trust Network',
    isEnabled: true,
    isVisible: true,
    countries: [
      'FI',
    ],
  },
]);
