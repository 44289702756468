// @flow

import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import agreementsReducer from 'reducers/entities/agreements';
import agreementTemplatesReducer from 'reducers/entities/agreement-templates';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';

import ModalForm from 'hocs/modal-form';
import TooltipInfo from 'components/tooltip-info';
import Field from 'components/field';
import SelectField from 'components/select-field';
import EmptyState from 'components/empty-state';
import Template from 'components/icons/template';
import MiniContractCard from 'components/mini-contract-card';

import style from './replace-contract.module.scss';

export const QUERY_NAME = 'templates/contracts';

export type Props = {
  agreement: Agreement,
  message: MessageTranslator,
  children: () => React.Node,
};

const memoizedAgreementTemplates = new Map();

export const getMemoizedAgreementTemplatesSelector = (state, ids) => {
  if (memoizedAgreementTemplates.has(ids)) {
    return memoizedAgreementTemplates.get(ids);
  }
  const templates = agreementTemplatesReducer.getAgreementTemplatesSelector(state, { ids });

  const agreementTemplates = templates.map((template) => (
    {
      ...template,
      agreement: agreementsReducer.getAgreementSelector(state, { id: template.agreement }),
    }
  ));

  memoizedAgreementTemplates.set(ids, agreementTemplates);

  return agreementTemplates;
};

export const ReplaceContractModal = ({
  agreement,
  message,
  children,
}: Props) => {
  const workspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  const rpcState = useSelector((state) => (
    agreementsReducer.getReplaceAgreementSelector(state, { id: agreement.id })
  ));
  const query = useSelector((state) => (
    agreementTemplatesReducer.getQuerySelector(state, { name: QUERY_NAME })
  ));
  const templates = useSelector((state) => (
    getMemoizedAgreementTemplatesSelector(state, query.result)
  )).map((template) => template.agreement);

  const dispatch = useDispatch();

  const templateGroupId = agreement.templateGroup?.id;

  const replaceAgreement = ({ template }: { template: Option }) => {
    dispatch(agreementsReducer.replaceAgreement({
      id: agreement.id,
      data: {
        agreementId: agreement.id,
        workspaceId: workspace.id,
        sourceId: template.id,
        amplitudeScope: 'replace contract modal',
      },
      pipe: {
        onSuccess: redirectToDocumentOnDocumentCreate,
      },
    }));
  };

  const resetRpcState = () => {
    dispatch(agreementsReducer.replaceAgreementReset({
      id: agreement.id,
    }));
  };

  React.useEffect(() => {
    dispatch(agreementTemplatesReducer.queryAgreementTemplates({
      name: QUERY_NAME,
      pagination: {
        limit: 100,
      },
      params: {
        visible: 1,
        templateGroupId,
      },
    }));
  }, [dispatch, templateGroupId]);

  const tooltipMessage = () => (
    <div className={style.TooltipMessage}>
      <p>
        <Message
          id="The following data will be transfered:"
          comment="Explanatory tooltip for Replace contract menu item."
        />
      </p>
      <ul>
        <li>
          <Message
            id="Data fields"
            comment="Example of data that will be transfered when taking the Replace contract action."
          />
        </li>
        <li>
          <Message
            id="Participants"
            comment="Example of data that will be transfered when taking the Replace contract action."
          />
        </li>
        <li>
          <Message
            id="Tags"
            comment="Example of data that will be transfered when taking the Replace contract action."
          />
        </li>
      </ul>
    </div>
  );

  const renderTemplateDropdown = () => {
    if (isEmpty(templates)) {
      return (
        <EmptyState
          icon={<Template height="33px" />}
          header="No matching templates"
          content={(
            <Message
              id="None of your active templates share the same template group as your contract"
              comment="Empty state text when no templates match the contract that is to be replaced."
            />
          )}
          defaultStyle
        />
      );
    }

    return (
      <>
        <Field
          name="template"
          label={message({
            id: 'Use template',
            comment: 'Label for dropdwon menu.',
          })}
          placeholder={message({
            id: 'Select template',
            comment: 'Placeholder text for dropdwon menu.',
          })}
          component={SelectField}
          options={templates}
          clearable={false}
          searchable
          required
          valueKey="id"
          labelKey="name"
        />
        <p className={style.Paragraph}>
          <Message
            id="Data from the contract will be used together with the template to create a new contract."
            comment="Paragraph explaining what the replace contract feature creates."
          />
        </p>
      </>
    );
  };

  const renderBody = () => (
    <div className={style.ReplaceContract}>
      <span>
        <Message
          id="Transfer data from:"
          comment="Subheading explaining what the replace contract feature does."
        />
        <TooltipInfo
          message={tooltipMessage()}
          zIndex="10003"
        />
      </span>
      <MiniContractCard agreement={agreement} hideInsight />
      {renderTemplateDropdown()}
    </div>
  );

  const isActionVisible = (
    templateGroupId
  );

  if (!isActionVisible) {
    return null;
  }

  return (
    <ModalForm
      title={(
        <Message
          id="Replace contract"
          comment="Modal title for Replace contract action."
        />
      )}
      body={renderBody()}
      formState={rpcState}
      resetFormState={resetRpcState}
      onSubmit={replaceAgreement}
      modalKey="replace contract modal"
    >
      {children}
    </ModalForm>
  );
};

export default localize<Props>(ReplaceContractModal);
