export const DEFAULT_ROUNDING_PRECISION = 2;

const roundingThreshold = 1e-8;

const isRemainingDecimalsInTheMiddle = (remainingDecimals) => (
  remainingDecimals > 0.5 - roundingThreshold && remainingDecimals < 0.5 + roundingThreshold
);

const getNearestEvenNumberToFloor = (floorValue) => {
  if (floorValue % 2 === 0) {
    return floorValue;
  }

  return floorValue + 1;
};

const bankersRound = (value, precision) => {
  const precisionPowerOfTen = 10 ** precision;
  const multipliedValue = Number((value * precisionPowerOfTen).toFixed(8));
  const multipliedValueFloor = Math.floor(multipliedValue);
  const remainingDecimals = multipliedValue - multipliedValueFloor;

  if (!isRemainingDecimalsInTheMiddle(remainingDecimals)) {
    return Math.round(multipliedValue) / precisionPowerOfTen;
  }

  return getNearestEvenNumberToFloor(multipliedValueFloor) / precisionPowerOfTen;
};

const legacyRound = (value, precision) => (
  Number(value.toFixed(precision))
);

const roundingMethods = {
  legacy: legacyRound,
  bankers: bankersRound,
};

const roundBy = (method, value, precision = DEFAULT_ROUNDING_PRECISION) => {
  const roundingMethod = roundingMethods[method];

  if (!roundingMethod) {
    throw Error('Rounding method not supported');
  }

  return roundingMethod(value, precision);
};

export {
  legacyRound,
  bankersRound,
  roundBy,
};
