// @flow

import * as React from 'react';

import ContractParties from 'components/contract-parties';

import style from './contract-name.module.scss';

type Props = {
  agreement: Agreement,
};

class ContractName extends React.PureComponent<Props> {
  render() {
    const { agreement } = this.props;

    if (agreement.name) {
      return (
        <div className={style.ContractName}>
          {agreement.name}
        </div>
      );
    }

    return <ContractParties agreement={agreement} />;
  }
}

export default ContractName;
