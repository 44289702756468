import { useState } from 'react';
import { isEmpty, values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import {
  getAcceptedSuggestions,
  getPristineContractData,
  getPristineState,
  isContractPristine,
} from 'reducers/current-contract';
import useAgreement from 'hooks/use-agreement';
import useCurrentBoxOrder from 'hooks/use-current-box-order';
import useCurrentBoxes from 'hooks/use-current-boxes';
import useCurrentData from 'hooks/use-current-data';
import { getGuestToken } from 'agreement/selectors';
import agreements from 'reducers/entities/agreements';

import toast from 'components/toasts';
import { isContractEditable } from 'components/document-layout-container/helpers';
import {
  getDataFieldChangedValues,
  getNonPristineBoxesMap,
  getNonPristineDataFieldsMap,
} from 'components/document-call-to-actions/actions/buttons-call-to-action/actions/save/helpers';
import { DiscardSaveActions } from './buttons-call-to-action';

type Props = {
  agreementId: Oneflow.Document['id']
}

export type FormData = { message: string; subject: string } | Record<string, never>;

const showToast = () => (
  toast.info({
    id: 'saved-and-notified',
    title: <Message
      id="Saved and notified"
      comment="Title for the info message when the user has saved the document."
    />,
    description: <Message
      id="Document successfully saved and participants have been notified"
      comment="Description text for the info message when the user has saved."
    />,
    duration: 5000,
  })
);

const OverdueActions = ({ agreementId }: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const [showNotifyParticipantsModal, setShowNotifyParticipantsModal] = useState(false);

  const isPristine = useSelector(isContractPristine);
  const isEditable = isContractEditable(agreement);

  const boxOrder = useCurrentBoxOrder();
  const boxes = useCurrentBoxes();
  const pristineState = useSelector(getPristineState);
  const pristineContract = useSelector(getPristineContractData);
  const nonPristineBoxes = getNonPristineBoxesMap(boxes, pristineState);

  const data = useCurrentData();
  const pristineData = pristineContract?.data;
  const nonPristineData = getNonPristineDataFieldsMap(data, pristineState);
  const guestToken = useSelector(getGuestToken);
  const acceptedSuggestions = useSelector(getAcceptedSuggestions);

  const onSaveAndNotifyChanges = (formData: FormData) => {
    const agreementData = {
      boxOrder,
      boxes: values(nonPristineBoxes),
      data: getDataFieldChangedValues(nonPristineData, pristineData),
      guestToken,
      notificationTempFlag: false,
      ...formData,
    };

    if (!isEmpty(acceptedSuggestions)) {
      agreementData.suggestionsToBeAccepted = acceptedSuggestions.map((id) => ({
        suggestionId: id,
      }));
    }

    dispatch(agreements.saveAgreement({
      id: agreementId,
      data: agreementData,
      pipe: {
        onSuccess: showToast,
      },
    }));
  };

  const menuItems = [
    {
      content: (
        <Message id="Save and notify" comment="Menu item for saving and notifying participants" />
      ),
      onSelect: () => {
        setShowNotifyParticipantsModal(true);
      },
    },
  ];

  if (!isPristine && isEditable) {
    return (
      <DiscardSaveActions
        agreementId={agreementId}
        isSaveDropdown
        menuItems={menuItems}
        notifyParticipants={{
          setModalOpen: setShowNotifyParticipantsModal,
          modalOpen: showNotifyParticipantsModal,
          onSaveAndNotifyChanges,
        }}
      />
    );
  }

  return null;
};

export default OverdueActions;
