// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';
import { Editor, Range } from 'slate';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import { MenuItem } from 'components/menu-item';
import { toggleBlock } from '../block-button/block-plugin';
import style from './text-styles.module.scss';

type Props = {
  editor: any,
  activeType?: string,
};

const NormalTextStyleMenuItem = ({
  editor,
  activeType,
}: Props) => {
  const focusEditor = useFocusEditor();
  const menuItemClassNames = clsx(style.TextStyleMenuItem, {
    [style.ActiveTextStyle]: !activeType,
  });

  const toggleActiveBlock = () => {
    if (!activeType) {
      return;
    }

    focusEditor();
    toggleBlock(editor, activeType);
    if (editor.selection && Range.isExpanded(editor.selection)) {
      Editor.removeMark(editor, 'fontSize');
    }
  };

  return (
    <MenuItem
      label={(
        <Message
          id="Normal text"
          comment="The text for the menu item when changing the text style to normal text in the editor."
        />
      )}
      onClick={toggleActiveBlock}
      className={menuItemClassNames}
    />
  );
};

NormalTextStyleMenuItem.defaultProps = {
  activeType: undefined,
};

export default NormalTextStyleMenuItem;
