import * as React from 'react';
import moment from 'moment';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import agreementsReducer from 'reducers/entities/agreements';

import ModalForm from 'hocs/modal-form';

import { getEndOfDayTimestamp } from 'date';
import Field from 'components/field';
import { MenuItem } from 'components/menu-item';
// eslint-disable-next-line import/named
import { SingleDatePickerComponent } from 'components/single-date-picker';
import Calendar from 'components/icons/calendar';

import style from './set-expiry-date.module.scss';

export type FormData = {
  expireDate: number | null,
};

export type Props = {
  canSetExpiryDate: boolean,
  currentExpireDate: string | null,
  setExpiryDate: (formData: FormData) => void,
  message: MessageTranslator,
  updateState: ReturnType<typeof agreementsReducer.getUpdateExpiryDateSelector>,
  resetUpdateState: () => void,
};

export const setInitialVisibleMonth = () => moment();

export class SetExpiryDateComponent extends React.Component<Props> {
  getExpiryTimestamp() {
    const { currentExpireDate } = this.props;

    if (currentExpireDate) {
      return getEndOfDayTimestamp(currentExpireDate);
    }

    return currentExpireDate;
  }

  getInitialVisibleMonth() {
    const { currentExpireDate } = this.props;

    const isExpiryDateInPast = moment(currentExpireDate).isBefore(moment());

    if (isExpiryDateInPast) {
      return setInitialVisibleMonth;
    }

    return null;
  }

  renderBody() {
    const { message } = this.props;

    return (
      <div className={style.SetExpiryDateField}>
        <Field
          name="expireDate"
          autoFocus
          label={message({
            id: 'Expiry date',
            comment: 'Label for selecting expiry date for the contract',
          })}
          placeholder={message({
            id: 'Select date',
            comment: 'Placeholder text for date picker input',
          })}
          component={SingleDatePickerComponent}
          block={false}
          initialVisibleMonth={this.getInitialVisibleMonth()}
        />
        <p>
          <em>
            <Message
              id="Your counterparties will be notified of this change"
              comment="Warning text in the modal when trying to update expiry date of the contract"
            />
          </em>
        </p>
      </div>
    );
  }

  renderChildren = (onClick: React.MouseEventHandler) => {
    const { message, canSetExpiryDate } = this.props;

    return (
      <MenuItem
        icon={Calendar}
        label={message({
          id: 'Signing period',
          comment: 'Action to set expiry date on the contract',
        })}
        disabled={!canSetExpiryDate}
        onClick={onClick}
      />
    );
  };

  render() {
    const {
      message,
      updateState,
      setExpiryDate,
      resetUpdateState,
    } = this.props;

    return (
      <ModalForm
        title={message({
          id: 'Signing period',
          comment: 'Modal title for updating signing period for a contract.',
        })}
        body={this.renderBody()}
        onSubmit={setExpiryDate}
        resetFormState={resetUpdateState}
        formState={updateState}
        initialValues={{
          expireDate: this.getExpiryTimestamp(),
        }}
        modalKey="contract sign period modal"
      >
        {this.renderChildren}
      </ModalForm>
    );
  }
}

export default localize<Props>(SetExpiryDateComponent);
