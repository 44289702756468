// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { EventLog } from './event-log';

type Props = {
  isOwnerParty: boolean,
  participant: AgreementParticipant,
  dateFormat: string,
};

const VisitCount = ({ participant, isOwnerParty, dateFormat }: Props) => {
  if (isOwnerParty || !participant.visits) {
    return null;
  }

  return (
    <EventLog
      label={(
        <Message
          id="Viewed"
          comment="Visit count label in participant visit card in the contract list"
        />
      )}
      eventValue={(
        <Message
          id="{count} time"
          pluralId="{count} times"
          comment="Visit count in participant visit card in the contract list"
          values={{
            count: participant.visits,
          }}
        />
      )}
      dateFormat={dateFormat}
    />
  );
};

export default VisitCount;
