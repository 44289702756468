import clsx from 'clsx';
import {
  useDndContext,
  useDraggable,
  DragOverlay,
} from '@dnd-kit/core';
import { isValidElementType } from 'react-is';
import type { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';

import useCurrentContractId from 'hooks/use-current-contract-id';
import useAgreement from 'hooks/use-agreement';
import { checkAcl } from 'components/acl';

import DragHandlerIcon from 'components/icons/drag-handler';
import boxIcons from 'agreement/box-icons';
import { useDocumentDndContext } from 'components/document-layout-container/document-dnd-context';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import { closestCornersWithin } from 'dnd/closest-corners-within';
import Tooltip from 'components/tooltip';

import { useBoxTypeIsDisabled } from './use-box-type-is-disabled';
import style from './draggable-section.module.scss';

type Props = {
  type: ContractView.BoxType;
  label: ReactNode;
};

export const DraggableSection = ({
  type,
  label,
}: Props) => {
  const agreementId = useCurrentContractId() as number;
  const agreement = useAgreement(agreementId);
  const isAllowedToUpdateLayout = checkAcl(agreement.acl, 'agreement:layout:update');

  const disabled = useBoxTypeIsDisabled(type) || !isAllowedToUpdateLayout;
  const { documentContentRef } = useDocumentLayout();

  const { activeDndId } = useDocumentDndContext();
  const { active } = useDndContext();
  const activeSegmentId = active?.data?.current?.segmentId;

  const draggableId = `draggable_section_${type}`;
  const {
    attributes,
    listeners,
    setNodeRef,
  } = useDraggable({
    id: draggableId,
    disabled,
    data: {
      segmentId: 'box_dnd',
      boxType: type,
      collisionDetection: closestCornersWithin,
      widthInRef: documentContentRef,
    },
  });
  const icon = boxIcons[type];
  const IconComponent = icon;
  let iconElement: ReactNode = null;
  if (isValidElementType(IconComponent)) {
    iconElement = <IconComponent />;
  }

  const isDragOverlayActive = activeDndId === draggableId && activeSegmentId === 'box_dnd';

  const className = clsx(style.DraggableSection, {
    [style.Disabled]: disabled,
    [style.NotAllowedToUpdateLayout]: !isAllowedToUpdateLayout,
    [style.IsDragOverlayActive]: isDragOverlayActive,
  });
  const draggableOverlayClassName = clsx(style.DraggableSection, style.Overlay);

  return (
    <>
      <Tooltip
        message={(
          <Message
            id="You don’t have permissions to use this"
            comment="Tool tip message for why sections is disabled"
          />
        )}
        theme="oneflow"
        side="top"
        sideOffset={4}
        hideContent={isAllowedToUpdateLayout}
      >
        <div>
          <div
            ref={setNodeRef}
            className={className}
            {...listeners}
            {...attributes}
            data-testid="draggable-section"
          >
            <div className={style.DragHandle}>
              <DragHandlerIcon height="13px" width="8" />
            </div>
            <div className={style.SectionType}>
              <div className={style.SectionLabel}>{label}</div>
              {iconElement}
            </div>
          </div>
          <DragOverlay dropAnimation={null} className={style.DragOverlay}>
            {isDragOverlayActive ? (
              <div data-testid="section-drag-overlay" className={draggableOverlayClassName}>
                <div className={style.DragHandle}>
                  <DragHandlerIcon height="13px" width="8" />
                </div>
                <div className={style.SectionType}>
                  <div className={style.SectionLabel}>{label}</div>
                  {iconElement}
                </div>
              </div>
            ) : null}
          </DragOverlay>
        </div>

      </Tooltip>
    </>
  );
};
