// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import positionMessageTemplatesReducer from 'reducers/entities/position-message-templates';
import { getPositionFromSessionSelector } from 'reducers/session';

import MessageTemplates from './user-message-templates';
import type { Props } from './user-message-templates';

const QUERY_NAME = 'user/templates';

type StateProps = {|
  messageTemplates: Array<AgreementTemplate>,
  messageTemplatesQuery: Query,
  positionAcl: Acl,
  positionId: number,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const query = positionMessageTemplatesReducer.getQuerySelector(state, { name: QUERY_NAME });
  const position = getPositionFromSessionSelector(state);

  return {
    messageTemplates: positionMessageTemplatesReducer.getPositionMessageTemplatesSelector(state, {
      ids: query.result,
    }),
    messageTemplatesQuery: query,
    positionAcl: position.acl,
    positionId: position.id,
  };
};

type DispatchProps = {|
  queryMessageTemplates: QueryFuncArgs => void,
|};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  queryMessageTemplates: ({ pagination, params }: QueryFuncArgs) => {
    dispatch(positionMessageTemplatesReducer.queryPositionMessageTemplates({
      name: QUERY_NAME,
      pagination: {
        ...pagination,
        limit: 50,
      },
      params,
    }));
  },
});

export default connect<Props, {||}, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(MessageTemplates);
