import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { reduce, indexOf } from 'lodash';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import { getCurrentContractData } from 'reducers/current-contract';

const useBoxItem = (boxId?: number) => {
  const { boxes, boxOrder } = useSelector(getCurrentContractData);

  const items = useMemo(() => (
    reduce(boxOrder, (result: number[], item: number) => {
      const id = getId(item);
      const box = boxes[id];

      return box && !box._removed ? [...result, id] : result;
    }, [])
  ), [boxes, boxOrder]);

  const index = indexOf(items, boxId);

  return {
    isFirst: index === 0,
    isLast: index >= 0 && index === items.length - 1,
    isSingle: items.length === 1,
  };
};

export default useBoxItem;
