import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import { checkAcl } from 'components/acl';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import agreements from 'reducers/entities/agreements';

import CreateInternalReminderIcon from 'components/icons/create-internal-reminder';
import { MenuItem } from 'components/menu-item';
// eslint-disable-next-line import/named
import { UserEventsProvider } from 'contexts/user-events';
// eslint-disable-next-line import/no-named-as-default
import InternalReminderCreateSelectedContract from 'components/modals/lifecycle-events/internal-reminder/internal-reminder-create-selected-contract';

export type State = {
  isOpen: boolean,
};

export type Props = {
  agreement: Oneflow.Agreement,
  visualTweak?: 'overrideLegacyStyling',
};

const CreateInternalReminder = ({
  agreement,
  visualTweak,
}: Props) => {
  const hasAccessToInternalRemindersButton = checkAcl(agreement.acl, 'agreement:internal_reminder:create');
  const currentWorkspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  const currentWorkspaceId = currentWorkspace.id;
  const dispatch = useDispatch();

  const fetchInternalReminders = () => {
    dispatch(agreements.fetchContractInternalReminders({
      id: agreement.id,
      data: {
        workspaceId: agreement?.collection?.id,
        createdBy: ['me', 'others'],
        eventTypes: ['user'],
        agreementId: agreement.id,
      },
    }));
  };

  const getChildren = (onClick: (event: MouseEvent) => void) => {
    if (!hasAccessToInternalRemindersButton) {
      return null;
    }

    return (
      <MenuItem
        icon={CreateInternalReminderIcon}
        label={(
          <Message
            id="Create internal reminder"
            comment="Action to create an internal reminder."
          />
      )}
        onClick={onClick}
      />
    );
  };

  return (
    <UserEventsProvider
      currentWorkspaceId={currentWorkspaceId}
      modal={InternalReminderCreateSelectedContract}
    >
      <InternalReminderCreateSelectedContract
        contract={agreement}
        visualTweak={visualTweak}
        refetchInternalReminders={() => fetchInternalReminders()}
      >
        {getChildren}
      </InternalReminderCreateSelectedContract>
    </UserEventsProvider>
  );
};

export default CreateInternalReminder;
