import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
} from 'recharts';

import TooltipInfo from 'components/tooltip-info';

import getTimeToSignData from './calculations';

import style from './time-to-sign.module.scss';

type Props = {
  data: Oneflow.Dashboard,
  message: MessageTranslator,
};

export const TimeToSignComponent = (props: Props) => {
  const { data, message } = props;

  return (
    <div className={style.TimeToSign}>
      <div className={style.TimeToSignHeader}>
        <Message id="Time to sign" comment="Widget header" />
        <TooltipInfo
          message={message({
            id: 'The time spent on fully signing a document sent during the selected date range',
            comment: 'Tooltip message',
          })}
        />
      </div>
      <div className={style.Divider} />
      <ResponsiveContainer width="100%" height="75%">
        <BarChart
          data={getTimeToSignData(data, message)}
          margin={{ top: 40, right: 30, left: 30 }}
          fill={style['forest-green']}
        >
          <CartesianGrid strokeDasharray="1 1" stroke={style['dark-green-04']} />
          <XAxis dataKey="timeToSign" stroke={style['forest-green']} />
          <YAxis
            allowDecimals={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax + (dataMax * 0.1))]}
            stroke={style['forest-green']}
          >
            <Label
              value={message({
                id: '# of Contracts',
                comment: 'Time to sign an agreement, chart y axis label',
              }) as string}
              angle={-90}
              position="insideLeft"
              offset={10}
            />
          </YAxis>
          <Tooltip />
          <Bar dataKey="count" fill={style['forest-green']} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default localize<Props>(TimeToSignComponent);
