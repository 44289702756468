import { useState, useEffect } from 'react';
import { Message } from '@oneflowab/pomes';
import { useSelector, useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import client from 'oneflow-client';
import { head, uniqueId } from 'lodash';
import clsx from 'clsx';

import agreementsReducer from 'reducers/entities/agreements';
import { MAX_ATTACHMENT_SIZE } from 'components/contract-boxes/constants';

// eslint-disable-next-line import/named
import { createAgreement } from 'oneflow-client/agreements';
import { getCurrentWorkspaceIdSelector } from 'reducers/app';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';
import { getPdfBoxContent } from 'agreement/import';

import PdfDocument from 'components/icons/pdf-document';
import ArrowDown from 'components/icons/arrow-down';
import CircularSpinner from 'components/icons/circular-spinner';

import style from './pdf-dropzone.module.scss';

type UploadError = {
  errorCode: string,
  fileSize: string,
  fileName: string,
}

type Props = {
  onLoaded?: (error: UploadError | null) => void,
};

type File = {
  path: string,
  lastModified: number,
  lastModifiedDate: string,
  name: string
  size: number
  type: string,
  webkitRelativePath: string,
}

type FileError = {
  errors?: any[],
  file: File
}

export const PdfDropzoneButton = ({ onLoaded }: Props) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingError, setUploadingError] = useState(null);
  const [dragOver, setDragOver] = useState(false);
  const workspaceId = useSelector(getCurrentWorkspaceIdSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (onLoaded) {
      onLoaded(uploadingError);
    }
  }, [onLoaded, uploadingError]);

  const prepareAgreement = async () => {
    // The location is hardcoded since this is only called from one location atm.
    // If you add another location please make the location parameter generic.
    const { result: agreementId, entities } = await createAgreement({ workspaceId, fromPdf: 1, amplitudeScope: 'welcome modal - create pdf' });

    return {
      agreementId,
      boxes: entities.agreements[agreementId].boxes,
    };
  };

  const handleDropAccepted = async (files: File[]) => {
    const file = head(files);
    setUploadingFile(true);
    setUploadingError(null);
    const { agreementId, boxes } = await prepareAgreement();

    const asset = await client.uploadDocument({
      file,
      assetType: 'document',
      contractId: agreementId,
    });

    const _id = Number(uniqueId());
    const boxesContent = getPdfBoxContent(asset, boxes, _id);

    dispatch(agreementsReducer.updateAttachmentBox({
      id: agreementId,
      data: {
        data: [boxesContent.contentData],
        boxes: boxesContent.boxes,
      },
      pipe: {
        onSuccess: redirectToDocumentOnDocumentCreate,
      },
    }));
  };

  const handleErrors = async (files: File[]) => {
    const fileUploadResult = head(files) as unknown as FileError;
    const errorCode = fileUploadResult.errors?.[0].code;
    const fileSize = fileUploadResult.file.size;
    const uploadError = { errorCode, fileSize, fileName: fileUploadResult.file.name };

    setUploadingError(uploadError);
    setUploadingFile(false);
  };

  const getIcon = () => {
    if (uploadingFile) {
      return <CircularSpinner height="25px" />;
    }
    return <PdfDocument height="25px" />;
  };

  const dropzoneClasses = clsx(style.PdfDropzone, {
    [style.Loading]: uploadingFile || dragOver,
    [style.UploadingError]: uploadingError && !uploadingFile,
  });

  return (
    <Dropzone
      multiple={false}
      maxSize={MAX_ATTACHMENT_SIZE}
      onDragEnter={() => setDragOver(true)}
      onDragLeave={() => setDragOver(false)}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleErrors}
      onError={handleErrors}
      accept={{
        'application/pdf': ['.pdf'],
        'image/tiff': ['.tiff'],
      }}
    >
      {(
        {
          getRootProps, getInputProps,
        },
      ) => (
        <section>
          <div {...getRootProps({
            className: dropzoneClasses,
          })}
          >
            <input {...getInputProps()} />
            {getIcon()}
            <span className={style.Heading}>
              <ArrowDown height="13px" />
              <Message
                id="Drop file here"
                comment="Text for button to finalize action."
              />
            </span>
            <span>
              <Message
                id="or click to upload"
                comment="Help text for a file upload dropzone."
              />
            </span>
          </div>
        </section>
      )}
    </Dropzone>
  );
};
