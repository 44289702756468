// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import CalendarAlert from 'components/icons/calendar-alert';

const BecomesOverdue = () => (
  <>
    <CalendarAlert height="12px" />
    <span>
      <Message
        id="Becomes overdue"
        comment="An insight for lifecycle events that indicates that a contract becomes overdue."
      />
    </span>
  </>
);

export default BecomesOverdue;
