import { connect } from 'react-redux';

import groups from 'reducers/entities/groups';
import positions from 'reducers/entities/positions';
import groupPositions from 'reducers/entities/group-positions';

import Members from './members';

const queryName = (id) => `admin/groups/${id}/members`;

export const mapStateToProps = (state, ownProps) => {
  const query = groupPositions.getQuerySelector(state, {
    name: queryName(ownProps.match.params.id),
  });

  return {
    group: groups.getGroupSelector(state, { id: ownProps.match.params.id }),
    positions: positions.getPositionsSelector(state, { ids: query.result }),
    query,
    createState: groupPositions.getCreateSelector(state),
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  queryGroupPositions: ({ pagination, params }) => {
    dispatch(groupPositions.queryGroupPositions({
      name: queryName(ownProps.match.params.id),
      params: {
        ...params,
        groupId: ownProps.match.params.id,
      },
      pagination,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Members);
