// @flow

import React, {
  useCallback,
  useMemo,
} from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { isConcluded } from 'agreement/states';
import agreements from 'reducers/entities/agreements';
import RichTextEditor from 'components/rich-text-editor';
import { ContractPropsProvider } from 'contexts/contract-props';
import { deserializeMarkup } from './deserializer';

import ContractLegacyTextEditable from './contract-text-editable/contract-legacy-text-editable';
import makeWithContractImages from './editor-plugins/with-contract-images';
import withDataFields from './editor-plugins/with-data-fields';
import TextEditable from './contract-text-editable/text-editable';
import ContractTextEditorToolbar from './contract-text-editor-toolbar';
import { pageBreakPlugin, addTablePlugin, dataFieldsPlugin } from './toolbar-plugins';

type Props = {
  boxId: number,
  contractId: number,
  legacyMarkup: string,
  nodes: Array<SlateDescendant>,
  onNodesChange: Array<SlateDescendant> => void,
  placeholder?: string,
  pluginsToDisable?: Array<Function>,
  readOnly?: boolean,
  type?: string,
  customClassName?: any,
  hasOutline?: boolean,
  isResponsiveView?: boolean,
};

type GetNodes = (
  nodes: Array<SlateDescendant>,
  legacyMarkup: string,
  readOnly: boolean,
) => Array<SlateDescendant>;

export const getNodes: GetNodes = (nodes, legacyMarkup, readOnly) => {
  if (isEmpty(nodes) && !legacyMarkup) {
    if (readOnly) {
      return null;
    }

    return [{ type: 'paragraph', children: [{ text: '' }] }];
  }

  if (!isEmpty(nodes)) {
    return nodes;
  }

  return deserializeMarkup(legacyMarkup);
};

export const ContractTextEditorToolbarPortal = () => {
  const contractEditorToolbarElement = document.getElementById('contract-editor-toolbar');

  if (contractEditorToolbarElement) {
    return ReactDOM.createPortal(
      <ContractTextEditorToolbar />,
      contractEditorToolbarElement,
    );
  }

  return null;
};

const TextEditor = ({
  boxId,
  contractId,
  legacyMarkup,
  nodes,
  onNodesChange,
  placeholder,
  pluginsToDisable = [],
  readOnly,
  type,
  customClassName,
  hasOutline,
  isResponsiveView,
}: Props) => {
  const plugins = useMemo(() => ([
    makeWithContractImages(contractId),
    withDataFields,
  ]), [contractId]);
  const agreement = useSelector((state) => (
    agreements.getAgreementSelector(state, { id: contractId })
  ));
  const agreementIsConcluded = isConcluded(agreement);
  const renderToolbar = useCallback(() => (
    <ContractTextEditorToolbarPortal />
  ), []);
  const renderEditable = useCallback((editableProps) => (
    <TextEditable
      {...editableProps}
      placeholder={placeholder}
      type={type}
      readOnly={readOnly}
      customClassName={customClassName}
      hasOutline={hasOutline}
      isResponsiveView={isResponsiveView}
    />
  ), [placeholder, type, readOnly, customClassName, hasOutline, isResponsiveView]);

  const dataFieldsEnabled = agreement.templateGroup;
  const isProductDescription = type === 'product-description';

  const onChange = useCallback((updatedNodes) => {
    if (readOnly) {
      return;
    }

    onNodesChange(updatedNodes);
  }, [onNodesChange, readOnly]);

  const textNodes = getNodes(nodes, legacyMarkup, readOnly);

  if (isEmpty(nodes) && agreementIsConcluded) {
    return (
      <ContractLegacyTextEditable legacyMarkup={legacyMarkup} />
    );
  }

  if (!textNodes) {
    return null;
  }

  let disabledPlugins = [];
  if (!dataFieldsEnabled) {
    disabledPlugins.push(dataFieldsPlugin);
  }

  if (isProductDescription) {
    disabledPlugins = [
      ...disabledPlugins,
      pageBreakPlugin,
      addTablePlugin,
    ];
  }

  if (pluginsToDisable) {
    disabledPlugins = [
      ...disabledPlugins,
      ...pluginsToDisable,
    ];
  }

  return (
    <ContractPropsProvider
      boxId={boxId}
      contractId={contractId}
      disabledPlugins={disabledPlugins}
      isProductDescription={isProductDescription}
    >
      <RichTextEditor
        nodes={textNodes}
        onNodesChange={onChange}
        renderToolbar={renderToolbar}
        renderEditable={renderEditable}
        plugins={plugins}
        isTabDisabled={isProductDescription}
        readOnly={readOnly}
      />
    </ContractPropsProvider>
  );
};

export default TextEditor;
