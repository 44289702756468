// @flow
import * as React from 'react';

import InternalReminderReadModalButton from 'components/modals/lifecycle-events/internal-reminder/internal-reminder-read';
import InternalReminderEditModalButton from 'components/modals/lifecycle-events/internal-reminder/internal-reminder-edit';

type Props = {
  isEditable: boolean,
  onOpen: () => void,
  isOpen: boolean,
  internalReminderEvent: InternalReminderEvent,
  refetchInternalReminders: () => void,
  onClose: () => void,
};

export default function InternalReminderModal({
  isEditable,
  onOpen,
  isOpen,
  internalReminderEvent,
  refetchInternalReminders,
  onClose,
}: Props) {
  if (isEditable) {
    return (
      <InternalReminderEditModalButton
        onOpen={onOpen}
        isOpen={isOpen}
        internalReminderEvent={internalReminderEvent}
        refetchInternalReminders={refetchInternalReminders}
        onClose={onClose}
      />
    );
  }

  return (
    <InternalReminderReadModalButton
      isReadOnly
      onOpen={onOpen}
      isOpen={isOpen}
      internalReminderEvent={internalReminderEvent}
      onClose={onClose}
    />
  );
}
