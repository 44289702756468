import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { getGuestToken } from 'agreement/selectors';
import { hasOtherPendingStateApprovals } from 'agreement/participant/has-other-pending-state-approvals';

import {
  MultistepModal,
  ModalStep,
  ModalStepRenderProps,
} from 'components/modal';
import { ApprovePendingDocumentModal } from 'components/modals/approve-pending-document/approve-pending-document';
import { ApprovedPendingDocumentModal } from 'components/modals/approved-pending-document/approved-pending-document';

export type Props = {
  agreement: Oneflow.Agreement;
  children: ReactNode;
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
  participant: Oneflow.Participant;
};

export const ApprovePendingActionsModal = ({
  agreement,
  children,
  isOpen,
  setIsOpen,
  participant,
}: Props) => {
  const guestToken = useSelector(getGuestToken);
  const agreementChecksum = agreement.checksum;
  const agreementId = agreement.id;
  const waitingForMoreApprovals = hasOtherPendingStateApprovals(agreement, participant);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleFinalStep = (onStepComplete: () => void) => () => {
    onStepComplete();
    handleClose();
  };

  const renderApprovePendingModal: ModalStepRenderProps = ({ onStepComplete }) => (
    <ApprovePendingDocumentModal
      onStepComplete={onStepComplete}
      agreementId={agreementId}
      onClose={handleClose}
      agreementChecksum={agreementChecksum}
      approverId={participant.id}
      guestToken={guestToken}
    />
  );

  const renderConfirmModal: ModalStepRenderProps = ({ onStepComplete }) => (
    <ApprovedPendingDocumentModal
      agreement={agreement}
      waitingForMoreApprovals={waitingForMoreApprovals}
      onClose={handleFinalStep(onStepComplete)}
      isOpen
    />
  );

  return (
    <>
      <MultistepModal
        isOpen={isOpen}
        onCancel={handleClose}
      >
        <ModalStep>
          {renderApprovePendingModal}
        </ModalStep>
        <ModalStep>
          {renderConfirmModal}
        </ModalStep>
      </MultistepModal>
      {children}
    </>
  );
};
