import moment from 'moment';

function isValid(value) {
  let ssn = value;

  if (ssn.length !== 10 || Number.isNaN(Number(ssn))) {
    return false;
  }

  // In 2007 the available sequence numbers under the this system for males born on
  // 1 January 1965 ran out, and since October 2007 personal identification numbers
  // do not always validate using the check digit.
  // This had been predicted and announced several years in advance.
  // Thus, most IT systems are presumed updated to accept numbers that fail
  // the check-digit validation
  // https://en.wikipedia.org/wiki/Personal_identification_number_(Denmark)
  ssn = `19${ssn.slice(4, 6)}-${ssn.slice(2, 4)}-${ssn.slice(0, 2)}`;

  return moment(ssn).isValid();
}

export default { isValid };
