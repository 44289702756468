import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import accountSeatsReducer from 'reducers/entities/account-seats';
import accountsReducer from 'reducers/entities/accounts';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getAdjustedCurrentLanguageSelector } from 'reducers/i18n';

import { Message } from '@oneflowab/pomes';
import type { FormRenderProps } from 'react-final-form';
import ModalForm from 'hocs/modal-form';

import {
  PERIOD_MONTHLY,
  PERIOD_YEARLY,
} from 'account/billing';

import {
  getFormatter,
  getMonthlySeatPrice,
  getYearlySeatPrice,
  formatPrice,
} from 'account';

import TermsOfUseLink from 'components/terms-of-use-link';
import { ConfirmButton } from 'components/buttons';
import { AccountPurchaseConfirmField } from 'components/fields/account-purchase-confirm';

import type { Billing } from 'types/billing';

import style from './change-plan-confirm.module.scss';

export type ModalData = {
  planName: string,
  pricePerSeat: number,
  pricePerSeatMonthlySubscription: number,
  currency: string,
  planId: number,
  monthlyBilling: boolean,
};

export type Props = {
  onStepComplete: () => void,
  modalData: Record<string, never> | ModalData;
  accountId: number,
  onClose: () => void,
  billing: Billing,
  modalKey: string,
};

const ChangePlanConfirm: React.FC<Props> = ({
  onStepComplete,
  modalData,
  accountId,
  onClose,
  billing,
  modalKey,
}: Props) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountFromSessionSelector);
  const accountSeats = useSelector((state) => accountSeatsReducer.getAccountSeatSelector(state,
    { id: account.id }));
  const updateState = useSelector((state) => accountsReducer.getBuySeatsSelector(state,
    { id: account.id }));
  const languageCode = useSelector(getAdjustedCurrentLanguageSelector);

  const onConfirm = (id) => {
    dispatch(accountsReducer.buySeats({
      id,
      data: {
        numberOfSeats: modalData.numberOfSeats,
        planId: modalData.planId,
      },
    }));
  };

  const resetUpdateState = (id) => {
    dispatch(accountsReducer.buySeatsReset({ id }));
  };

  useEffect(() => {
    const { success } = updateState;
    if (success) {
      onStepComplete();
    }
  }, [updateState, onStepComplete]);

  const getActions = ({ formProps }: FormRenderProps) => {
    const disabled = Boolean(
      updateState.loading
      || updateState.error
      || formProps.validating
      || formProps.invalid,
    );

    return (
      <div className={style.Button}>
        <ConfirmButton
          onClick={formProps.handleSubmit}
          disabled={disabled}
          isLoading={updateState.loading}
        />
      </div>
    );
  };

  const renderBillingAndInvoiceInformation = () => (
    <div className={style.BillingAndInvoice}>
      <p>
        <Message
          id="Your next yearly invoice will reflect the total scope of your Oneflow Subscription Plan."
          comment="Billing information which the customer gets before confirming purchase."
        />
      </p>
      <p>
        <Message
          id="Invoice will be sent to {invoiceEmail}."
          values={{
            invoiceEmail: <strong>{billing.invoiceEmail}</strong>,
          }}
          comment="Information about where the invoice will be sent."
        />
      </p>
    </div>
  );

  const renderTotalPrice = (monthlyPrice: string, yearlyPrice: string) => {
    const paymentInterval = billing.period;

    if (paymentInterval === 1) {
      return (
        <Message
          id="{total} / month"
          values={{
            total: monthlyPrice,
          }}
          comment="The total monthly price of the entire subscription."
        />
      );
    }

    return (
      <Message
        id="{total} / year"
        values={{
          total: yearlyPrice,
        }}
        comment="The total yearly price of the entire subscription."
      />
    );
  };

  const formatter = () => {
    const { currency } = modalData;
    return getFormatter(languageCode, currency);
  };

  const handleResetUpdateState = () => {
    resetUpdateState(accountId);
  };

  const handleSubmit = () => {
    onConfirm(accountId);
  };

  const billingPeriod = () => {
    switch (billing.period) {
      case PERIOD_MONTHLY:
        return (
          <Message
            id="Monthly"
            comment="Label for monthly billing"
          />
        );
      case PERIOD_YEARLY:
        return (
          <Message
            id="Yearly"
            comment="Label for yearly billing"
          />
        );
      default:
        return null;
    }
  };

  const billingPeriodNumber = () => {
    switch (billing.period) {
      case PERIOD_MONTHLY:
        return (
          <span> x 1</span>
        );
      case PERIOD_YEARLY:
        return (
          <span> x 12</span>
        );
      default:
        return null;
    }
  };

  const renderBody = () => {
    const {
      pricePerSeat,
      pricePerSeatMonthlySubscription,
      planName,
      monthlyBilling,
    } = modalData;

    const { total } = accountSeats;
    const selectedPrice = monthlyBilling ? pricePerSeatMonthlySubscription : pricePerSeat;

    const selectedPlanPrice = formatPrice(formatter(), selectedPrice);
    const monthlyPrice = formatPrice(formatter(), getMonthlySeatPrice(total, selectedPrice));
    const yearlyPrice = formatPrice(formatter(), getYearlySeatPrice(total, selectedPrice));

    return (
      <div>
        <h2 className={style.Title}>
          <Message
            id="Summary"
            comment="Heading for a summary showing what plan the user is changing to."
          />
        </h2>
        <div className={style.PlanSummation}>
          <div className={style.PlanName}>
            <Message
              id="{planName} plan ({billingPeriod})"
              values={{
                planName,
                billingPeriod: billingPeriod(),
              }}
              comment="Plan name with yearly subscription."
            />
            <p>
              <Message
                id="{total} seats"
                values={{ total }}
                comment="Number of seats a user is purchasing."
              />
            </p>
          </div>
          <p>
            {selectedPlanPrice}
            <span> x </span>
            {total}
            {billingPeriodNumber()}
          </p>
        </div>
        <div className={style.TotalPriceContainer}>
          <div>
            <strong>
              <p>
                <Message
                  id="Total"
                  comment="The total yearly price of the entire subscription."
                />
              </p>
            </strong>
          </div>
          <div className={style.TotalPrice}>
            <p className={style.Price}>
              {renderTotalPrice(monthlyPrice, yearlyPrice)}
            </p>
            <p className={style.InformationText}>
              <Message
                id="You will receive a separate prorated invoice for this upgrade covering the current Subscription Plan period until the next renewal date of your Subscription Plan."
                comment="Text informing the user that they will receive a separate prorated invoice for the upgrade."
              />
            </p>
          </div>
        </div>
        {renderBillingAndInvoiceInformation()}
        <TermsOfUseLink />
        <div className={style.CheckboxContainer}>
          <AccountPurchaseConfirmField billingCompanyName={billing.name} />
        </div>
      </div>
    );
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Confirm Subscription Plan change"
          comment="Title for the confirm plan change modal."
        />
      )}
      body={renderBody()}
      actions={getActions}
      formState={updateState}
      resetFormState={handleResetUpdateState}
      onSubmit={handleSubmit}
      isOpen
      onClose={onClose}
      modalKey={modalKey}
    />
  );
};

export default ChangePlanConfirm;
