/* eslint-disable import/named */
import { forwardRef } from 'react';
import {
  last,
  noop,
  uniqueId,
} from 'lodash';
import clsx from 'clsx';

import type { Product } from 'data-validators/entity-schemas/document-box/product-table';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import { PopoverAnchor } from 'components/popover';
import { PRODUCT_TABLE_COLUMNS } from 'components/contract-boxes/product-table-box/constants';
import { UpdatedProduct } from 'components/contract-boxes/product-table-box/product-table/popup-forms/update-product-helpers';
import EditIcon from 'components/icons/edit';
import UpdateProductItemPopup from 'components/contract-boxes/product-table-box/product-table/product-table-popup/update-product-item-popup';

import style from '../product-table-expanded.module.scss';

type Props = {
  children: React.ReactNode,
  columnKey: string,
  enabledColumns: string[],
  isAllowedToUpdateDataValue: boolean,
  onClearChosenProduct: (productId: number) => void,
  onClick: () => void,
  product: Product,
  removeProduct: (productId: number) => void,
  updateProductData: (productId: number, updatedProduct: UpdatedProduct) => void,
}

const ProductCell = forwardRef<HTMLDivElement, Props>(({
  children,
  columnKey,
  enabledColumns,
  isAllowedToUpdateDataValue,
  onClearChosenProduct,
  onClick,
  product,
  removeProduct,
  updateProductData,
}, ref) => {
  const {
    NAME,
    DESCRIPTION,
    PRICE_1,
    PRICE_2,
    COUNT,
  } = PRODUCT_TABLE_COLUMNS;
  const isProductNameCell = columnKey === NAME;
  const isDescriptionCell = columnKey === DESCRIPTION;
  const isPriceCell = columnKey === PRICE_1 || columnKey === PRICE_2;
  const isQuantityCell = columnKey === COUNT;
  const tableCellClasses = clsx(style.TableCell, {
    [style.Description]: isDescriptionCell,
    [style.IsEditable]: !isAllowedToUpdateDataValue,
    [style.ProductNameCell]: isProductNameCell,
    [style.ProductPriceCell]: isPriceCell,
    [style.ProductQuantity]: isQuantityCell,
  });

  if (last(enabledColumns) !== columnKey || !isAllowedToUpdateDataValue) {
    return (
      <div
        data-row-id={getId(product)}
        role="button"
        className={tableCellClasses}
        onClick={onClick}
        tabIndex={0}
        onKeyDown={noop}
      >
        {children}
      </div>
    );
  }

  return (
    <UpdateProductItemPopup
      asChild
      dataPopupId={uniqueId()}
      key={getId(product)}
      onClearChosenProduct={onClearChosenProduct}
      popoverContentProps={{
        align: 'end',
        alignOffset: -16,
        sideOffset: -20,
        sticky: 'always',
      }}
      productId={getId(product)}
      readOnly={!isAllowedToUpdateDataValue}
      removeProduct={removeProduct}
      updateProductData={updateProductData}
      value={product.value}
    >
      <div
        data-testid="popup-trigger"
        role="cell"
        className={tableCellClasses}
        ref={ref}
      >
        {children}
        <PopoverAnchor asChild>
          <div className={style.EditIcon}>
            <EditIcon width="14px" height="14px" />
          </div>
        </PopoverAnchor>
      </div>
    </UpdateProductItemPopup>
  );
});

ProductCell.displayName = 'ProductCell';

export default ProductCell;
