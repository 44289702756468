// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import DynamicLink from 'components/dynamic-link';

import style from './extension-content-help-topics.module.scss';

type Props = {
  extension: Extension,
};

export const ExtensionContentHelpTopics = ({ extension }: Props) => {
  if (!extension.helpTopics) {
    return null;
  }

  return (
    <>
      <h3 className={style.ExtensionContentHeader}>
        <Message
          id="Help topics"
          comment="Section header for an extension details page."
        />
      </h3>
      <div className={style.ExtensionContentBody}>
        <ul>
          {extension.helpTopics.map((helpTopic) => (
            <li key={helpTopic.url} className={style.HelpGuide}>
              <DynamicLink
                url={helpTopic.url}
                target="_blank"
                rel="noopener noreferrer"
                hideExternalIcon
                trackable={{
                  name: 'Go To Help Topic',
                  props: {
                    location: 'marketplace',
                    url: helpTopic.url,
                    key: extension.key,
                  },
                }}
              >
                {helpTopic.title}
              </DynamicLink>

            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
