import Snackbar from '@material-ui/core/Snackbar';

import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';

import { getError } from 'components/document-tabs/settings-tab/helpers';
import TerminateSnackbarIcon from 'components/icons/terminate-snackbar';
import Alert from 'components/alert';

type Props = {
  agreementId: number;
}
const APIError = ({ agreementId }: Props) => {
  const {
    resetRPCStates,
    updateAgreementState,
    updateConfigState,
    updateExpiryDateDaysState,
    updateExpiryDateState,
  } = useAPIError(agreementId);

  if (
    !updateConfigState?.error
    && !updateAgreementState?.error
    && !updateExpiryDateState?.error
    && !updateExpiryDateDaysState?.error
  ) {
    return null;
  }

  const errorMessage = getError(
    updateConfigState.error
    || updateAgreementState.error
    || updateExpiryDateState.error
    || updateExpiryDateDaysState.error,
  );

  return (
    <Snackbar
      open
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        onClose={resetRPCStates}
        severity="error"
        icon={<TerminateSnackbarIcon />}
      >
        <div data-testid="error-header">{errorMessage.headerText}</div>
        <div data-testid="error-message">{errorMessage.bodyText}</div>
      </Alert>
    </Snackbar>
  );
};

export default APIError;
