// @flow

import * as React from 'react';
import { Route } from 'react-router';
import { useState } from 'react';
import clsx from 'clsx';

import Routes from 'routes';
import DocumentRoutes from 'routes/agreements/document-routes';

import GetStarted from 'components/get-started';
import { ErrorPage } from 'components/legacy/error';
import Agreement from 'routes/agreements/agreement';
import Conditional from 'components/conditional';
import Ghosting from 'components/ghosting';
import Loading from 'components/legacy/loading';
import { FreemiumNoticeBanner } from 'components/freemium-notice-banner';
import { SessionExpirationWarning } from 'components/modals/session-expiration-warning';
import Sidebar from 'components/sidebar';
import Switch from 'components/switch';
import CommandMenu from 'components/command-menu';
import PaymentReminderBanner from 'components/payment-reminder-banner';
import { ToastProvider } from 'components/toasts';

import { AppLocalization } from './app-localization';
import style from './app.module.scss';

type Props = {
  isLoading: boolean,
  hasError: boolean,
  acl: Object,
  ghost?: boolean,
};

export const getAgreementPageRenderer = () => (
  (routeProps) => (
    <Agreement {...routeProps} />
  )
);

const renderSidebar = (onMenuToggle) => (
  <Sidebar
    onMobileMenuToggle={onMenuToggle}
    isBackdropEnabled={false}
    isPositionFixed
  />
);

const App = ({
  isLoading,
  hasError,
  acl,
  ghost,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  const onMenuToggle = (isMenuOpen) => {
    setIsMobileMenuOpen(isMenuOpen);
  };

  if (hasError) {
    return <ErrorPage />;
  }

  const contentClassNames = clsx(style.Content, {
    [style.MobileMenuOpen]: isMobileMenuOpen,
  });

  const appClassNames = clsx(style.App, {
    [style.MobileMenuOpen]: isMobileMenuOpen,
  });

  return (
    <AppLocalization>
      <Conditional ifCondition={ghost}>
        <Ghosting />
      </Conditional>
      <CommandMenu />
      <Switch>
        <Route path={['/contracts/:id/video', '/documents/:id/video']}>
          {getAgreementPageRenderer()}
        </Route>
        <Route path="/documents/templates/:id" component={DocumentRoutes} />
        <Route path="/documents/:id" component={DocumentRoutes} />
        <Route>
          <div className={appClassNames}>
            <GetStarted acl={acl} />
            <SessionExpirationWarning />
            <div className={style.Body}>
              {renderSidebar(onMenuToggle)}
              <div className={contentClassNames}>
                <FreemiumNoticeBanner />
                <PaymentReminderBanner />
                <div className={style.InnerContent}>
                  <Routes acl={acl} />
                </div>
              </div>
            </div>
          </div>
          <ToastProvider position="bottom-center" viewportClassName={style.BottomCenter} />
        </Route>
      </Switch>
    </AppLocalization>
  );
};

App.defaultProps = {
  ghost: false,
};

export default App;
