import { Message } from '@oneflowab/pomes';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import AltText from 'components/icons/alt-text';
import ToolbarButton from 'components/rich-text-editor-toolbars/toolbar-buttons/toolbar-button';

type Props = {
  isActive: boolean,
  hideTooltip?: boolean,
  setHideTooltip: (hide: boolean) => null,
};

export const AltTextButton = ({ isActive, hideTooltip, setHideTooltip }: Props) => {
  const focusEditor = useFocusEditor();
  return (
    <ToolbarButton
      asPopoverTrigger
      isActive={isActive}
      label={(
        <Message
          id="Alt Text"
          comment="Alt Text Button Label, shown in the editor toolbar"
        />
      )}
      onTrigger={() => {
        setHideTooltip(true);
        focusEditor();
      }}
      icon={<AltText />}
      hideTooltip={hideTooltip}
    />
  );
};
