import { useState } from 'react';
import type { ReactNode } from 'react';

import { MultistepModal, ModalStep } from 'components/modal';

import { DeleteContractStep } from './steps/delete-contract-step/delete-contract-step';
import type { SkippedDocument } from './steps/delete-contract-step/types';
import { DeleteContractWarningsStep } from './steps/delete-contract-warnings-step/delete-contract-warnings-step';

export type SubmitData = {
  isGlobalSearch: boolean,
  targetFolderId?: null | number,
  targetWorkspaceId: number,
};

export type Props = {
  children: (openModal: () => void) => ReactNode,
  queryName: string,
};

export const DeleteContractBulk = ({
  children,
  queryName,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [skippedDocuments, setSkippedDocuments] = useState<SkippedDocument[]>([]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <MultistepModal isOpen={isOpen} onCancel={handleClose}>
        <ModalStep>
          {({ onStepComplete }) => (
            <DeleteContractStep
              setSkippedDocuments={setSkippedDocuments}
              onClose={handleClose}
              queryName={queryName}
              onStepComplete={onStepComplete}
            />
          )}
        </ModalStep>
        <ModalStep>
          {() => (
            <DeleteContractWarningsStep
              onClose={handleClose}
              skippedDocuments={skippedDocuments}
            />
          )}
        </ModalStep>
      </MultistepModal>
      {children(handleOpen)}
    </>
  );
};
