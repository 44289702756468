// @flow

import AuditTrailConstants from 'agreement/audit-trail-constants';
import { type MessageTranslator } from '@oneflowab/pomes';
import {
  agreementCancel,
  agreementContentUpdate,
  agreementDecline,
  agreementDurationEnd,
  agreementExpire,
  agreementNewRecurringPeriod,
  agreementPublish,
  agreementCreate,
  agreementRevive,
  agreementSign,
  agreementSignatureReset,
  agreementPendingStateApprovalReset,
  agreementStartLifeCycle,
  agreementTerminate,
  agreementUpdate,
  pdfDownload,
} from './agreement';
import {
  participantAdd,
  participantDecline,
  participantDelegate,
  participantDisable,
  participantFirstVisit,
  participantPublish,
  participantSign,
  participantSignAttachment,
  participantUpdate,
  participantUpdateItem,
} from './participant';
import {
  companyAdd,
  companyDisable,
  companyUpdate,
  companyUpdateItem,
} from './company';
import {
  dataCreate,
  dataRemove,
  dataUpdate,
} from './data';
import { messageAdd } from './message';
import { suggestionAccepted } from './suggestion';
import validate2FA from './2fa';
import {
  boxDisable,
  boxEnable,
  boxUpdate,
  boxUpdateLayout,
} from './box';
import {
  draftApprovalFlowStarted,
  draftFullyApproved,
  participantApprovedDraft,
  resetDraftApprovals,
  sendForDraftApproval,
} from './draft-approval-flow';

export type EventData = {
  text: string,
  byLine: boolean,
  showEditIcon: boolean,
  key: string
}

const handleUnknownEvent = (event, message, error = null) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.error('Could not format event:', event, error);
  } else {
    // eslint-disable-next-line no-console
    console.error('Unhandled event:', event);
  }
  return {
    text: message({
      id: 'Unknown event',
      comment: 'Audit trail message when the event is not handled',
    }),
    byLine: true,
    key: event.id,
  };
};

export const getFormattedEvent = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const formattedEvent = {
    text: '',
    showEditIcon: false,
    key: event.id,
    byLine: true,
  };

  switch (event.type) {
    // agreement events
    case AuditTrailConstants.TYPE_AGREEMENT_CREATE:
      return { ...formattedEvent, ...agreementCreate(event, agreement, message) };
    case AuditTrailConstants.TYPE_AGREEMENT_PUBLISH:
      return { ...formattedEvent, ...agreementPublish(event, agreement, message) };
    case AuditTrailConstants.TYPE_AGREEMENT_SIGN:
      return { ...formattedEvent, ...agreementSign(event, agreement, message) };
    case AuditTrailConstants.TYPE_AGREEMENT_DECLINE:
      return { ...formattedEvent, ...agreementDecline(event, message) };
    case AuditTrailConstants.TYPE_AGREEMENT_UPDATE:
      return { ...formattedEvent, ...agreementUpdate(event, agreement, message) };
    case AuditTrailConstants.TYPE_AGREEMENT_REVIVE:
      return { ...formattedEvent, ...agreementRevive(message) };
    case AuditTrailConstants.TYPE_LIFECYCLE_END:
      return { ...formattedEvent, ...agreementDurationEnd(message) };
    case AuditTrailConstants.TYPE_LIFECYCLE_NEW:
      return { ...formattedEvent, ...agreementNewRecurringPeriod(agreement, event, message) };
    case AuditTrailConstants.TYPE_LIFECYCLE_START:
      return { ...formattedEvent, ...agreementStartLifeCycle(message) };
    case AuditTrailConstants.TYPE_AGREEMENT_EXPIRE:
      return { ...formattedEvent, ...agreementExpire(message) };
    case AuditTrailConstants.TYPE_AGREEMENT_CANCEL:
      return { ...formattedEvent, ...agreementCancel(message) };
    case AuditTrailConstants.TYPE_LIFECYCLE_TERMINATE:
      return { ...formattedEvent, ...agreementTerminate(message) };
    case AuditTrailConstants.TYPE_AGREEMENT_SIGNATURE_RESET:
      return { ...formattedEvent, ...agreementSignatureReset(message) };
    case AuditTrailConstants.TYPE_AGREEMENT_APPROVAL_RESET:
      return { ...formattedEvent, ...agreementPendingStateApprovalReset(message) };
    case AuditTrailConstants.TYPE_AGREEMENT_CONTENT_UPDATE:
      return { ...formattedEvent, ...agreementContentUpdate(message) };
    case AuditTrailConstants.TYPE_PDF_DOWNLOAD:
      return { ...formattedEvent, ...pdfDownload(event, agreement, message) };

    // participant events
    case AuditTrailConstants.TYPE_PARTICIPANT_PUBLISH:
      return { ...formattedEvent, ...participantPublish(event, agreement, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_SIGN:
      return { ...formattedEvent, ...participantSign(event, agreement, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_SIGN_ATTACHMENT:
      return { ...formattedEvent, ...participantSignAttachment(event, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_DECLINE:
      return { ...formattedEvent, ...participantDecline(event, agreement, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_ADD:
      return { ...formattedEvent, ...participantAdd(event, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_DELEGATE:
      return { ...formattedEvent, ...participantDelegate(event, agreement, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_DISABLE:
      return { ...formattedEvent, ...participantDisable(event, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_FIRST_VISIT:
      return { ...formattedEvent, ...participantFirstVisit(event, agreement, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_UPDATE:
      return { ...formattedEvent, ...participantUpdate(event, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_UPDATE_ITEM:
      return { ...formattedEvent, ...participantUpdateItem(event, agreement, message) };

    // company events
    case AuditTrailConstants.TYPE_PARTY_ADD:
      return { ...formattedEvent, ...companyAdd(event, message) };
    case AuditTrailConstants.TYPE_PARTY_UPDATE:
      return { ...formattedEvent, ...companyUpdate(event, message) };
    case AuditTrailConstants.TYPE_PARTY_UPDATE_ITEM:
      return { ...formattedEvent, ...companyUpdateItem(event, message) };
    case AuditTrailConstants.TYPE_PARTY_DISABLE:
      return { ...formattedEvent, ...companyDisable(event, message) };

    // data events
    case AuditTrailConstants.TYPE_DATA_CREATE:
      return { ...formattedEvent, ...dataCreate(event, message) };
    case AuditTrailConstants.TYPE_DATA_UPDATE:
      return { ...formattedEvent, ...dataUpdate(event, message) };
    case AuditTrailConstants.TYPE_DATA_REMOVE:
      return { ...formattedEvent, ...dataRemove(event, message) };

    // suggestion events
    case AuditTrailConstants.TYPE_SUGGESTION_ACCEPTED:
      return { ...formattedEvent, ...suggestionAccepted(message) };

    // message events
    case AuditTrailConstants.TYPE_MESSAGE_ADD:
      return { ...formattedEvent, ...messageAdd(event, agreement, message) };

    // 2FA events
    case AuditTrailConstants.TYPE_OPEN_WITH_2FA:
      return { ...formattedEvent, ...validate2FA(event, agreement, message) };

    // box events
    case AuditTrailConstants.TYPE_BOX_UPDATE:
      return { ...formattedEvent, ...boxUpdate(event, message) };
    case AuditTrailConstants.TYPE_BOX_ENABLE:
      return { ...formattedEvent, ...boxEnable(event, message) };
    case AuditTrailConstants.TYPE_BOX_DISABLE:
      return { ...formattedEvent, ...boxDisable(event, message) };
    case AuditTrailConstants.TYPE_BOX_UPDATE_LAYOUT:
      return { ...formattedEvent, ...boxUpdateLayout(message) };

      // approval flow events
    case AuditTrailConstants.TYPE_APPROVAL_FLOW_STARTED:
      return { ...formattedEvent, ...draftApprovalFlowStarted(event, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_APPROVE:
      return { ...formattedEvent, ...participantApprovedDraft(event, message) };
    case AuditTrailConstants.TYPE_PARTICIPANT_INVITED_TO_APPROVE_DRAFT:
      return { ...formattedEvent, ...sendForDraftApproval(event, message) };
    case AuditTrailConstants.TYPE_AGREEMENT_FLOW_COMPLETE:
      return { ...formattedEvent, ...draftFullyApproved(message) };
    case AuditTrailConstants.TYPE_AGREEMENT_FLOW_RESET:
      return { ...formattedEvent, ...resetDraftApprovals(message) };

    default:
      return handleUnknownEvent(event, message);
  }
};
