// @flow

import React, { useState, useCallback, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Message from 'components/message';
import Button from 'components/button';
import LoadPreviousReplies from 'components/icons/load-previous-replies';
import { scrollToElement } from 'components/document-tabs/messages-tab/post-message/auto-scroll';
import SingleMessage from '../single-message';

import style from './message-replies.module.scss';

type Props = {
  replies: Array<Message>,
  agreementId: number,
  guestToken: number,
};

const MessageReplies = ({
  replies,
  agreementId,
  guestToken,
}: Props) => {
  const [showAllReplies, setShowAllReplies] = useState(false);
  const lastReplyRef = useRef(null);

  const onLoadMoreClick = useCallback((event) => {
    event.stopPropagation();
    setShowAllReplies(true);

    setTimeout(() => {
      scrollToElement(lastReplyRef.current);
    }, 200);
  }, []);

  const visibleReplies = showAllReplies ? replies : [replies[replies.length - 1]];

  if (replies.length === 0) {
    return null;
  }

  return (
    <>
      <div className={style.RepliesContainer}>
        {
          !showAllReplies && replies.length > 1 && (
            <Button
              onClick={onLoadMoreClick}
              icon={<LoadPreviousReplies width="24px" />}
              customClass={style.LoadPreviousRepliesButton}
            >
              <Message
                id="Load all replies"
                comment="A message for the button that loads more replies and shows them in the list."
              />
              <span>
                {' '}
                (
                {replies.length}
                )
              </span>
            </Button>
          )
        }
        <TransitionGroup className={style.Replies}>
          {visibleReplies.map((reply, i, array) => (
            <CSSTransition
              key={reply.id}
              timeout={500}
              classNames={{
                enter: style.MessageReplyEnter,
                enterActive: style.MessageReplyEnterActive,
                exit: style.MessageReplyExit,
                exitActive: style.MessageReplyExitActive,
              }}
            >
              <div ref={(i + 1 === array.length) ? lastReplyRef : null}>
                <SingleMessage
                  message={reply}
                  guestToken={guestToken}
                  agreementId={agreementId}
                />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </>
  );
};

export default MessageReplies;
