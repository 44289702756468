// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { getCurrentWorkspaceSelector } from 'reducers/app';

import Redirect from 'components/redirect';
import Switch from 'components/switch';

import { withWorkspaceNamespace } from './with-workspace-namespace';

const mapStateToProps = (state: State) => ({
  workspace: getCurrentWorkspaceSelector(state),
});

type Props = {
  workspace: Workspace,
  path: [string, string],
};

type WrappedProps = {
  [any]: any,
};

type DecoratedComponent = Class<React.Component<WrappedProps>> | (WrappedProps) => React.Node;

const getDisplayName: (WrappedComponent: DecoratedComponent) => string = (WrappedComponent) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return `WithWorkspaceRoute(${displayName})`;
};

const withWorkspaceRoute = (WrappedComponent: DecoratedComponent) => {
  const WithWorkspaceRouteWrapper = (props: Props) => {
    const {
      workspace,
      path: [namespacedPath, path],
      ...wrappedProps
    } = props;

    return (
      <Switch>
        <Route path={namespacedPath}>
          {(routeProps) => (
            <WrappedComponent
              {...wrappedProps}
              {...routeProps}
              workspace={workspace}
            />
          )}
        </Route>
        <Route path={path}>
          {
            (routeProps) => (
              <Redirect
                to={withWorkspaceNamespace(workspace)(routeProps.location.pathname)}
              />
            )
          }
        </Route>
      </Switch>
    );
  };

  WithWorkspaceRouteWrapper.displayName = getDisplayName(WrappedComponent);

  return WithWorkspaceRouteWrapper;
};

export default (WrappedComponent: DecoratedComponent) => (
  connect(mapStateToProps)(withWorkspaceRoute(WrappedComponent))
);
