import { isTemplatePublished } from 'agreement';
import {
  SaveButton,
  DiscardButton,
  PublishButton,
  UnpublishButton,
} from './buttons';

type Props = {
  agreement: Oneflow.Document
}

const DiscardSaveActions = ({ agreement }: Props) => {
  const isPublished = isTemplatePublished(agreement);

  return (
    <>
      <DiscardButton />
      <SaveButton agreementId={agreement.id} />
      {
        isPublished
          ? <UnpublishButton disabled />
          : <PublishButton disabled />
      }
    </>
  );
};

export default DiscardSaveActions;
