/* eslint-disable react/jsx-no-bind */
// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import client from 'oneflow-client';
import Confirmable from 'components/confirmable';
import FileUpload from 'components/file-upload';
import { CancelButton } from 'components/buttons';

import { useContractProps } from 'contexts/contract-props';

import {
  FILE_IMAGE_JPEG,
  FILE_IMAGE_PNG,
  FILE_IMAGE_BMP,
  FILE_IMAGE_GIF,
} from 'utils/file';

const modalKey = 'add contract image modal';
type Props = {
  onInsertImage: (src: string) => void,
  onModalClose: () => void,
  children: (onMouseDown: Function) => React.Node,
};

function UploadContractImage(props: Props) {
  const { children, onInsertImage, onModalClose } = props;
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { contractId } = useContractProps();

  function upload({ file }) {
    setLoading(true);

    return client
      .uploadContractImage({ file, contractId })
      .finally(() => setLoading(false));
  }

  function uploadDone(response: { url: string, uploaded: number }) {
    onInsertImage(response.url);
    setSuccess(true);
  }

  function renderBody() {
    return (
      <FileUpload
        accept={[FILE_IMAGE_JPEG, FILE_IMAGE_PNG, FILE_IMAGE_BMP, FILE_IMAGE_GIF]}
        assetType="image"
        onUploadDone={uploadDone}
        onUpload={upload}
        loading={loading}
        isSmall
      />
    );
  }

  // eslint-disable-next-line react/no-unused-prop-types
  function getActions({ closeConfirmation }: { closeConfirmation: Function }) {
    return (
      <CancelButton onClick={closeConfirmation} modalKey={modalKey} />
    );
  }

  return (
    <Confirmable
      data-testid="import"
      body={renderBody()}
      actions={getActions}
      header={(
        <Message id="Upload" comment="Used as the title in html editor upload image modal" />
      )}
      success={success}
      onClose={() => {
        setSuccess(false);
        onModalClose();
      }}
      modalKey={modalKey}
      portalClassName="rich-text-region"
    >
      {children}
    </Confirmable>
  );
}

export default UploadContractImage;
