import { connect } from 'react-redux';

import tagsReducer from 'reducers/entities/tags';

import AddTag from './add-tag';

const QUERY_NAME = '/admin/tags';

export const mapStateToProps = (state) => ({
  formState: tagsReducer.getCreateSelector(state),
});

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (tagData) => {
    dispatch(tagsReducer.createTag({
      data: tagData,
      pipe: {
        action: () => tagsReducer.queryTagsReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
  resetFormState: () => {
    dispatch(tagsReducer.createTagReset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTag);
