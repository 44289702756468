import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import type { MessageTranslator } from '@oneflowab/pomes';
import { Descendant } from 'slate';

import { localize } from '@oneflowab/pomes';

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import TextEditor from 'components/contract-text-editor/text-editor';

import type { Product } from 'data-validators/entity-schemas/document-box/product-table';
import style from './description-editable.module.scss';

type Props = {
  isEditable: boolean,
  contractId: Oneflow.Agreement['id'],
  data: Product,
  onChange: (nodes: Descendant[]) => void,
  isResponsiveView: boolean,
  customClassName?: string,
  hasOutline?: boolean,
  message: MessageTranslator,
  isAllowedToUpdateDataValue: boolean,
}

export const DescriptionEditable = ({
  contractId,
  customClassName,
  data,
  hasOutline,
  isAllowedToUpdateDataValue,
  isResponsiveView,
  message,
  onChange,
}: Props) => {
  const descriptionTextNodes = data?.value?.description_nodes;
  const legacyDescription = data?.value?.description;
  const placeholderText = isResponsiveView ? message({
    id: 'Add description here...',
    comment: 'Placeholder text for empty product description field',
  }) : '';

  const descriptionEditableClassNames = clsx(customClassName, style.ProductDescription, {
    [style.Description]: isResponsiveView,
    [style.EditableExpanded]: isAllowedToUpdateDataValue && !isResponsiveView,
    [style.EditableResponsive]: isResponsiveView,
    [style.ReadOnly]: !isAllowedToUpdateDataValue,
  });

  const renderDescriptionTextNodes = () => {
    if (!isEmpty(descriptionTextNodes)) {
      return descriptionTextNodes;
    }

    return undefined;
  };

  return (
    <TextEditor
      contractId={contractId}
      customClassName={descriptionEditableClassNames}
      hasOutline={hasOutline}
      isResponsiveView={isResponsiveView}
      legacyMarkup={legacyDescription}
      nodes={renderDescriptionTextNodes()}
      onNodesChange={onChange}
      placeholder={placeholderText}
      readOnly={!isAllowedToUpdateDataValue}
      type="product-description"
    />
  );
};

export default localize(DescriptionEditable);
