// @flow
import React, { useCallback, useMemo } from 'react';
import { components } from 'react-select';

import debounce from 'debounce-promise';

import { localize, type MessageTranslator } from '@oneflowab/pomes';
import { getAgreements } from 'oneflow-client/agreements';
import * as agreementConstants from 'agreement/constants';
import { getOffsetForPage } from 'components/pagination/helpers';

import MiniContractCard from 'components/mini-contract-card';

import SelectField from 'components/select-field';
import Field from 'components/field';
import MagnifyingGlass from 'components/icons/magnifying-glass';

import style from './contract-select-field.module.scss';

type Props = {|
  workspaceId?: number,
  onChange: Function,
  message: MessageTranslator,
|};

type CustomOptionProps = {
  isFocused: boolean,
  innerProps: Object,
  innerRef: string,
  data: Object,
};

type CustomInputProps = {
  children?: Function,
  ...Props,
};

export const CustomInputWithIcon = ({ children, ...props }: CustomInputProps) => (
  <components.Control {...props}>
    <span className={style.CustomInputWithIcon}>
      <MagnifyingGlass width="18px" />
    </span>
    {children}
  </components.Control>
);

// The NonClickable-class is added to MiniContractCard depending on if onClick-prop is provided
const CustomOption = ({
  isFocused,
  innerProps,
  innerRef,
  data: agreementOption,
}: CustomOptionProps) => (
  <div
    ref={innerRef}
    className={style.CustomOption}
    {...innerProps}
    style={{
      backgroundColor: isFocused ? '#e6ebed' : '#fff',
    }}
  >
    <div
      className={style.CustomOptionInner}
    >
      <MiniContractCard
        onClick={() => {}}
        agreement={agreementOption.contract}
      />
    </div>
  </div>
);

export const ContractSelectField = ({ workspaceId, onChange, message }: Props) => {
  const getParams = useCallback((value) => {
    const params = {
      state: [
        agreementConstants.STATE_DRAFT,
        agreementConstants.STATE_PENDING,
        agreementConstants.STATE_OVERDUE,
        agreementConstants.STATE_SIGNED,
        agreementConstants.STATE_DECLINED,
      ],
      q: value,
      offset: 0,
      limit: 100,
    };

    if (workspaceId === 0) {
      return {
        sharedWithMe: 1,
        ...params,
      };
    }

    if (workspaceId) {
      return {
        ...params,
        collectionIds: [workspaceId],
      };
    }

    return params;
  }, [workspaceId]);

  const fetchContractsFromServer = useMemo(() => debounce(async (value) => {
    const request = {
      params: getParams(value),
      pagination: {
        limit: 20,
        offset: getOffsetForPage(1, 20),
      },
      normalizeOutput: false,
    };

    if (value) {
      request.sort = ['-score'];
    }

    try {
      const data = await getAgreements(request);

      // not possible to use Id as key, since object gets sorted in ascending numerical order then
      return Object.entries(data.collection).map(([, contract]) => ({
        [`contractId-${contract.id}`]: contract.id,
        contract,
      }));
    } catch {
      /** NOTE:
       * This endpoint is meant to be used both on initial dropdown click and on searching.
       * It is ok to show the API error on initial dropdown click but not during search
       * (on each keystroke) since it will give the user junky experience.
       * As the react-select (AsyncSelect) takes care of both the scenarios, it might be tricky
       * to set error even on initial dropdown click.
       * Not having catch block will lead to error bubbling up and may crash the component
       */
      return [];
    }
  }, 500), [getParams]);

  return (
    <Field
      component={SelectField}
      async
      loadingMessage={() => message({
        id: 'Loading...',
        comment: 'Loading message when searching for a contract in custom reminder modal',
      })}
      autoFocus
      isSearchable
      loadOptions={fetchContractsFromServer}
      components={{
        Control: CustomInputWithIcon,
        Option: CustomOption,
      }}
      label={message({
        id: 'Document',
        comment: 'Label for the contract field in custom reminder modal',
      })}
      name="contractFieldOption"
      labelKey="id"
      valueKey="contract"
      placeholder={message({
        id: 'Find contract',
        comment: 'Placeholder for searching for a contract in custom reminder modal',
      })}
      required
      defaultOptions
      hideErrorElement
      onChange={(contractFieldOption) => onChange(contractFieldOption.contract)}
    />
  );
};

export default localize<Props>(ContractSelectField);
