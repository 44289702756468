// @flow
import { decamelize } from 'humps';

import {
  get,
  post,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

export const aiAssistQuery: any = (params) => post({
  url: '/ai_assist/query',
  body: {
    target_type: params.targetType,
    target_id: params.targetId,
    prompt: params.prompt,
  },
}).then(extractResponseBodyAsJSON);

export const aiAssistResponse: any = (params) => get({
  url: `/ai_assist/${params.aiAssistId}`,
}).then(extractResponseBodyAsJSON);

export const aiAssistStreamResponse: any = ({
  targetId,
  targetType,
  prompt,
  context = 'agreementEditor',
  signal,
}) => get({
  url: '/ai_assist/stream',
  params: {
    target_id: targetId,
    target_type: targetType,
    prompt,
    context: decamelize(context),
  },
}, { signal });

export const aiAssistStreamResponsePost: any = ({
  targetId,
  targetType,
  context = 'agreementEditor',
  signal,
  prompt,
  chat,
  selectedText,
}) => post({
  url: '/ai_assist/stream',
  body: {
    target_id: targetId,
    target_type: targetType,
    chat,
    prompt,
    selected_text: selectedText,
    context: decamelize(context),
  },
}, { signal });
