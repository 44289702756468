// @flow

import * as React from 'react';
import Button from 'components/button';
import ChevronDownIcon from 'components/icons/chevron-down';
import ChevronUpIcon from 'components/icons/chevron-up';
import clsx from 'clsx';

import style from './expandable-options-header.module.scss';

type Props = {
  title: string,
  isExpandable: boolean,
  isExpanded: boolean,
  onClick: Function,
  customClass?: string,
  isAdditionalOptions?: boolean,
};

export const ExpandableOptionsHeader = ({
  title,
  isExpandable,
  isExpanded,
  onClick,
  customClass,
  isAdditionalOptions,
}: Props) => {
  let icon = null;
  if (isExpandable) {
    icon = isExpanded
      ? <ChevronUpIcon height="8px" className={style.ExpandableOptionsHeaderIcon} />
      : <ChevronDownIcon height="8px" className={style.ExpandableOptionsHeaderIcon} />;
  }

  const classNames = clsx(style.ExpandableOptionsHeader, {
    [style.Expandable]: isExpandable,
    [style.AdditionalOptions]: isAdditionalOptions,
  }, customClass);

  return (
    <Button
      icon={icon}
      className={classNames}
      onClick={isExpandable ? onClick : null}
      hasIconRight
    >
      {title}
    </Button>
  );
};
