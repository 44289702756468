// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

const getSuggestionMessages = (type: string, context?: string) => {
  const examples = {
    agreementEditor: [
      <Message
        id="“Create a sales pitch or presentation to help a salesperson close a deal.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Create a template for a sales agreement that can be easily customized for different clients.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    agreementMessageParticipants: [
      <Message
        id="“Remind participants about this document.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Write a reminder about signing this document.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    agreementPublish: [
      <Message
        id="“Write a short invitation message to sign this document.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Craft a concise request for a digital signature in my contract.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    agreementOnesave: [
      <Message
        id="“Write a short comment that the document has been updated.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Notify my counterparty that there are new changes.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    agreementParticipantCreate: [
      <Message
        id="“Write a short invitation message to sign this document.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Craft a concise request for a digital signature in my contract.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    agreementInternalReminder: [
      <Message
        id="“Write a short reminder for myself to read this document 6 months.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Remind me to review this document.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    messageTemplate: [
      <Message
        id="“Write a reusable message template for my documents.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Write a short introduction message that I can use later.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    sales: [
      <Message
        id="“Create a sales pitch or presentation to help a salesperson close a deal.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Create a template for a sales agreement that can be easily customized for different clients.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Generate a confidentiality clause in a sales contract.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    hr: [
      <Message
        id="“Write a job description for a new position.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Give me an offer letter for a new hire.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Create a policy for remote work.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    legal: [
      <Message
        id="“Give an example of a payment terms clause.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Generate a GDPR compliant DPA.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Create an extension to a sales contract.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    procurement: [
      <Message
        id="“List best practices for creating a Request for Proposal (RFP) document.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“List common pitfalls to avoid when conducting supplier negotiations.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Generate the most important clauses to include in a supply contract.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
    other: [
      <Message
        id="“Create a travel policy.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Create a template for minutes of meetings.”"
        comment="Example to generate AI assisted contracts"
      />,
      <Message
        id="“Create a partnership agreement.”"
        comment="Example to generate AI assisted contracts"
      />,
    ],
  };

  return context ? examples?.[context] : (examples?.[type] || examples.other);
};

export default getSuggestionMessages;
