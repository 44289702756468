import { Message } from '@oneflowab/pomes';

type Props = {
  onClick: (() => void) | undefined,
  aTagClassName: string
}

const OverdueOwner = ({ onClick, aTagClassName }: Props) => (
  <span>
    <b>
      <Message
        id="Document is overdue."
        comment="Text for overdue contract from owner view. Visible in contract."
      />
    </b>
    <Message
      id=" To enable signing, go to {jsx-start}Document setting{jsx-end} and extend the signing period."
      component="a"
      comment="Text for overdue contract from owner view. Visible in contract."
      onClick={onClick}
      className={aTagClassName}
    />
  </span>
);

export default OverdueOwner;
