// @flow

import React from 'react';

import merge from 'lodash/merge';
import map from 'lodash/map';
import partial from 'lodash/partial';

import adminPage from 'hocs/admin-page';
import Conditional from 'components/conditional';
import { checkAcl } from 'components/acl';
import { isRoleEditable } from 'role';
import { PermissionsComponent } from './permissions-component';
import ActionBar from './action-bar';

import style from './permissions.module.scss';

type UpdateRole = {
  id: number,
  data: Role
};

type Props = {
  role: Role,
  updateRole: UpdateRole => void,
  updateState: UpdateState,
  updatePath: (pathname: string) => void,
  location: Location,
};

type PermissionChange = {
  id: number,
  enabled: boolean,
};

type State = {
  editingEnabled: boolean,
  changedPermissions: Object,
};

export class PermissionsWrapperComponent extends React.Component<Props, State> {
  state = {
    editingEnabled: false,
    changedPermissions: {},
  };

  componentDidUpdate(prevProps: Props) {
    const { updateState } = this.props;

    this.enableEditModeIfRedirected();

    if (updateState?.success && !prevProps.updateState.success
      && updateState?.success
      && this.state.editingEnabled
    ) {
      this.resetEditing();
    }
  }

  cancel = () => {
    this.resetEditing();
    this.resetChangedPermissions();
  };

  enableEditing = () => {
    this.setState({ editingEnabled: true });
  };

  resetEditing = () => {
    this.setState({ editingEnabled: false });
  };

  resetChangedPermissions = () => {
    this.setState({ changedPermissions: {} });
  };

  handlePermissionChange = ({ id, enabled }: PermissionChange) => {
    if (!this.state.editingEnabled) {
      return;
    }

    this.setState((prevState) => ({
      changedPermissions: merge({}, prevState.changedPermissions, { [id]: enabled }),
    }));
  };

  updateRole = (role: Role) => {
    const { updateRole } = this.props;
    const { changedPermissions } = this.state;

    const permissions = map(changedPermissions, (enabled, id: string) => ({
      id: parseInt(id, 10),
      enabled,
    }));

    updateRole({
      id: role.id,
      data: {
        ...role,
        permissions,
      },
    });
  };

  isEditingEnabled = () => {
    const { editingEnabled } = this.state;
    const { updateState } = this.props;

    return editingEnabled && !updateState.loading;
  };

  checkEditPermission = () => {
    const { role } = this.props;

    return checkAcl(role?.acl, 'role:update:permissions');
  };

  enableEditModeIfRedirected() {
    const { updatePath, location } = this.props;

    if (!this.state.editingEnabled && location?.query.edit && this.checkEditPermission()) {
      updatePath(location.pathname);

      this.setState({ editingEnabled: true });
    }
  }

  renderRolePage = () => {
    const { role, updateState } = this.props;
    const { changedPermissions, editingEnabled } = this.state;
    const noPermissionChanged = Object.keys(changedPermissions).length === 0;

    if (!role) {
      return null;
    }

    return (
      <>
        <Conditional ifCondition={isRoleEditable(role)}>
          <ActionBar
            role={role}
            canEdit={this.checkEditPermission()}
            isEditingEnabled={editingEnabled}
            isConfirmDisabled={editingEnabled && noPermissionChanged}
            onClick={partial(this.enableEditing, role.name)}
            onConfirm={partial(this.updateRole, role)}
            onCancel={this.cancel}
            onSuccess={this.cancel}
            updateState={updateState}
          />
        </Conditional>
        <PermissionsComponent
          role={role}
          changedPermissions={changedPermissions}
          editingEnabled={this.isEditingEnabled()}
          handlePermissionChange={this.handlePermissionChange}
        />
      </>
    );
  }

  render() {
    return (
      <div className={style.RolePage}>
        {this.renderRolePage()}
      </div>
    );
  }
}

export default adminPage(({ props: { message } }) => ({
  title: message({ id: 'Permissions', comment: 'Used in the breadcrumb and title on the role page.' }),
  showAsLink: false,
  modules: [[]],
}))(PermissionsWrapperComponent);
