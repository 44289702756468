import {
  post,
  extractResponseBodyAsJSON,
  camelize,
} from 'oneflow-client/core';

export const aiExtractAgreement = (agreementId: number) => (
  post({
    url: `/ext/ai_import/agreements/${agreementId}/enrich`,
  })
    .then(extractResponseBodyAsJSON)
    .then(camelize));
