import Svg from 'components/svg';

const Freja = Svg((
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path className="cls-1" d="M260.59,29.1a11,11,0,0,1,2.15,7.76H246.58a6.44,6.44,0,0,0,2.37,5,7.2,7.2,0,0,0,5,1.94,9.75,9.75,0,0,0,3.44-.65,8.17,8.17,0,0,0,2.8-1.94l1.51,1.29a10.73,10.73,0,0,1-7.75,3.24,9.93,9.93,0,0,1-5-1.3A8.85,8.85,0,0,1,245.5,41a10.59,10.59,0,0,1,0-10.13A8.8,8.8,0,0,1,249,27.38a10,10,0,0,1,5-1.29A7.83,7.83,0,0,1,260.59,29.1Zm0,5.82a7.82,7.82,0,0,0-1.94-4.74,5.71,5.71,0,0,0-4.74-1.72,8.11,8.11,0,0,0-5,1.72,8.33,8.33,0,0,0-2.37,4.74Z" />
      <path className="cls-1" d="M268.34,19.84h2.59V45.7h-2.59Z" />
      <path className="cls-1" d="M293.34,21.35a13,13,0,0,1,5.17,17.45A13.24,13.24,0,0,1,293.34,44a15.56,15.56,0,0,1-6.9,1.73H276.1V19.84h10.34A12.89,12.89,0,0,1,293.34,21.35ZM292,41.82a10.38,10.38,0,0,0,3.88-14.44A9.6,9.6,0,0,0,292,23.5,11.89,11.89,0,0,0,286.66,22H278.9V43.33h8A9.48,9.48,0,0,0,292,41.82Z" />
      <polygon className="cls-1" points="95.32 45.48 95.32 18.98 113.63 18.98 113.63 23.93 100.7 23.93 100.7 30.18 110.4 30.18 110.4 35.14 100.7 35.14 100.7 45.48 95.32 45.48" />
      <path className="cls-1" d="M138.84,45.48l-4.74-7.76h-6.46v7.76h-5.39V19H133c6.9,0,10.78,3.45,10.78,9.26a8.59,8.59,0,0,1-5,8.41l6,8.83ZM133.24,33c3.88,0,5.6-1.5,5.6-4.52s-1.94-4.53-5.6-4.53h-5.6v9Z" />
      <polygon className="cls-1" points="152.85 45.48 152.85 18.98 172.03 18.98 172.03 23.93 158.24 23.93 158.24 29.75 169.87 29.75 169.87 34.71 158.24 34.71 158.24 40.74 172.46 40.74 172.46 45.48 152.85 45.48" />
      <path className="cls-1" d="M188.19,45.7a9.12,9.12,0,0,1-7.76-3.45l-.22-.22,2.59-4.09.43.43a7,7,0,0,0,5,2.16c2.58,0,3.23-1.94,3.23-3.45V23.93h-8V19H196.8v18.1a7.94,7.94,0,0,1-7.11,8.62Z" />
      <path className="cls-1" d="M223.74,45.48l-2.37-5.82H209.09l-2.37,5.82h-5.61L212.75,19h5.17l11.64,26.5Zm-4.53-10.56-4.09-9.48L211,34.92Z" />
      <path className="cls-1" d="M41.39,36.23h-14a6.11,6.11,0,0,1-6-5.93V15.77A3.74,3.74,0,0,1,25.14,12h0a3.74,3.74,0,0,1,3.73,3.73h0V27.24a1.51,1.51,0,0,0,1.53,1.53h11a10,10,0,0,0,10-10h0A11.3,11.3,0,0,0,40.15,7.46h-3a3.73,3.73,0,0,1-3.72-3.73h0A3.73,3.73,0,0,1,37.18,0h3A18.76,18.76,0,0,1,58.88,18.74h0A17.5,17.5,0,0,1,41.39,36.23Z" />
      <circle className="cls-1" cx="25.14" cy="3.73" r="3.73" />
      <circle className="cls-1" cx="3.54" cy="24.85" r="3.54" />
      <circle className="cls-1" cx="3.54" cy="40.82" r="3.44" />
      <circle className="cls-1" cx="12.04" cy="11.38" r="3.73" />
      <circle className="cls-1" cx="11.66" cy="54.39" r="3.35" />
      <circle className="cls-1" cx="56.01" cy="54.39" r="2.58" />
      <circle className="cls-1" cx="25.33" cy="61.94" r="3.06" />
      <circle className="cls-1" cx="41.87" cy="61.75" r="2.87" />
    </g>
  </g>
), {
  viewBox: '0 0 300 65',
  fill: '#3e3c94',
});

Freja.displayName = 'Freja';

export default Freja;
