// TODO ESLINT: fix exports from validators to avoid disabling the following rules.
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Message, MessageTranslator } from '@oneflowab/pomes';
import type { FieldRenderProps } from 'react-final-form';
import { Form as ReactFinalForm, Field as FinalFormField } from 'react-final-form';
import { useParams } from 'react-router';

import adminPage from 'hocs/admin-page';
import * as EditableForm from 'components/editable-form';
import workspacesReducer from 'reducers/entities/workspaces';
import { getCurrentLanguageSelector } from 'reducers/i18n';

import composeValidators from 'forms/validators/compose-validators';
import numberValidator from 'forms/validators/number';
import minValue from 'forms/validators/min-value';
import required from 'forms/validators/required';

import { getCountryListAsOptions, getAmericanStatesAsOptions } from 'components/countries';
import { getGoverningLawTranslation } from 'components/pages/ai-insights/charts/utils';
import { NumberField } from 'components/text-field';
import Sparkles from 'components/icons/ai-sparkle';
import ToggleOnIcon from 'components/icons/toggle-on';
import CircularSpinner from 'components/icons/circular-spinner';
import Edit from 'components/icons/edit';
import Button from 'components/button';
import { DisableAiReview } from 'components/modals/disable-ai-review';
import SelectField from 'components/select-field';
import { ApiError, unknownApiError } from 'components/api-error';
import EmptyState from 'components/empty-state';
import Tooltip from 'components/tooltip';

import style from './ai-review.module.scss';

type Params = {
  id: string;
};

type FormData = {
  paymentTerm: number;
  playbookTemplateType: string;
  governingLaws: string[];
  arbitration: 'true' | 'false';
};

const contactSalesHref = 'https://oneflow.com/oneflow-contact';

const isCustomPlaybook = (type: string) => type === 'CUSTOM';

const playbookParams = {
  paymentTerm: {
    id: 8,
    label: <Message future id="Payment term *" comment="Label" />,
    description: <Message
      future
      id="A payment term outlines the time frame in which a party is required to pay the invoice."
      comment="Description of the payment term clause."
    />,
    playbookTemplateTypes: ['SALES', 'SOURCING'],
  },
  governingLaws: {
    id: 9,
    label: <Message future id="Governing laws *" comment="Label" />,
    description: (
      <>
        <Message
          future
          id="A governing law clause explains which law will govern the contract, which is crucial for dispute interpretation."
          comment="Description of the governing law clause."
        />
        <p style={{ margin: '8px 0 20px 0' }}>
          <Message
            future
            id="Select countries and regions that you accept in your documents."
            comment="Description of the governing law clause."
          />
        </p>
      </>
    ),
    playbookTemplateTypes: ['SALES', 'SOURCING', 'NDA'],
  },
  disputeResolution: {
    id: 10,
    label: <Message future id="Dispute resolution *" comment="Label" />,
    description: (
      <Message
        future
        id="A dispute resolution clause outlines the process for resolving disputes between parties."
        comment="Description of the dispute resolution clause."
      />
    ),
    playbookTemplateTypes: ['SALES', 'SOURCING', 'NDA'],
  },
  confidentiality: {
    id: 1,
    label: <Message future id="Confidentiality" comment="Label" />,
    description: <Message
      future
      id="Check for the presence or absence of confidentiality clauses to protect sensitive information."
      comment="Description of the confidentiality clause."
    />,
    playbookTemplateTypes: ['SALES', 'SOURCING', 'NDA'],
  },
  // "Return and destruction" and "Need to know" should be directly under "Confidentiality"
  // because they are related from a legal perspective
  returnAndDestruction: {
    id: 7,
    label: <Message future id="Return and Destruction" comment="Label" />,
    description: <Message
      future
      id="Check for the presence or absence of return and destruction clauses to protect sensitive information."
      comment="Description of the return and destruction clause."
    />,
    playbookTemplateTypes: ['NDA'],
  },
  needToKnow: {
    id: 11,
    label: <Message future id="Need to Know" comment="Label" />,
    description: <Message
      future
      id="Check for the presence or absence of need-to-know clauses specifying who can access sensitive information and when."
      comment="Description of the need-to-know clause."
    />,
    playbookTemplateTypes: ['NDA'],
  },
  exclusionOfConsequentialLoss: {
    id: 2,
    label: <Message future id="Exclusion of Consequential Damages" comment="Label" />,
    description: <Message
      future
      id="Check for the presence or absence of clauses that exclude consequential losses."
      comment="Description of the exclusion of consequential damages clause."
    />,
    playbookTemplateTypes: ['SALES', 'SOURCING'],
  },
  forceMajeure: {
    id: 3,
    label: <Message future id="Force Majeure" comment="Label" />,
    description: <Message
      future
      id="Check for the presence or absence of force majeure clauses that cover unforeseen events."
      comment="Description of the force majeure clause."
    />,
    playbookTemplateTypes: ['SALES', 'SOURCING'],
  },
  indemnities: {
    id: 4,
    label: <Message future id="Indemnities" comment="Label" />,
    description: <Message
      future
      id="Check for the presence or absence of indemnity clauses that specify compensation for losses or damages."
      comment="Description of the indemnities clause."
    />,
    playbookTemplateTypes: ['SALES', 'SOURCING', 'NDA'],
  },
  limitationOfLiability: {
    id: 5,
    label: <Message future id="Limitation of Liability" comment="Label" />,
    description: <Message
      future
      id="Check for the presence or absence of clauses that limit liability."
      comment="Description of the limitation of liability clause."
    />,
    playbookTemplateTypes: ['SALES', 'SOURCING'],
  },
  liquidatedDamages: {
    id: 6,
    label: <Message future id="Liquidated Damages" comment="Label" />,
    description: <Message
      future
      id="Check for the presence or absence of liquidated damages clauses that predefine compensation for breaches."
      comment="Description of the liquidated damages clause."
    />,
    playbookTemplateTypes: ['SALES', 'SOURCING', 'NDA'],
  },
};

const playbookTemplates = {
  SALES: {
    name: 'SALES',
    label: <Message
      future
      id="Sales"
      comment="Label for the Sales playbook."
    />,
    description: <Message
      future
      id="Use the Sales playbook if your workspace primarily focuses on customer contracts or similar documents."
      comment="Description of the Sales playbook."
    />,
    icon: <Sparkles />,
    recommendations: {
      paymentTerm: (
        <Message
          future
          id="Since you chose Sales, we recommend setting the payment terms to 30 days or below."
          comment="Description of the payment term setting."
        />
      ),
    },
  },
  SOURCING: {
    name: 'SOURCING',
    label: <Message
      future
      id="Sourcing"
      comment="Label for the Sourcing playbook."
    />,
    description: <Message
      future
      id="Use the Sourcing playbook if your workspace primarily focuses on purchase agreements or similar documents."
      comment="Description of the Sourcing playbook."
    />,
    icon: <Sparkles />,
    recommendations: {
      paymentTerm: (
        <Message
          future
          id="Since you chose Sourcing, we recommend setting the payment terms above 30 days."
          comment="Description of the payment term setting."
        />
      ),
    },
  },
  NDA: {
    name: 'NDA',
    label: <Message
      future
      id="NDA"
      comment="Label for the Non-Disclosure Agreement playbook."
    />,
    description: <Message
      future
      id="Use the NDA playbook if your workspace primarily focuses on non-disclosure agreements or similar documents."
      comment="Description of the Non-Disclosure Agreement playbook."
    />,
    icon: <Sparkles />,
  },
  CUSTOM: {
    name: 'CUSTOM',
    label: <Message
      future
      id="Custom"
      comment="Label for the Custom playbook."
    />,
    description: <Message
      future
      id="Your custom playbook adjusted to your needs."
      comment="Description of the Custom playbook."
    />,
    icon: <Sparkles />,
  },
};

const parseISODuration = (duration: string): number => {
  const match = duration.match(/P(\d+)D/);
  if (match && match[1]) {
    return parseInt(match[1], 10);
  }
  return 0;
};

type DisputeResolution = {
  arbitration: boolean;
};

export const AiReview = ({ message }: { message: MessageTranslator }) => {
  const dispatch = useDispatch();
  const { id } = useParams<Params>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedPlaybook, setSelectedPlaybook] = useState<string>('');
  const [paymentTerm, setPaymentTerm] = useState<number | null>(null);
  const [disputeResolution, setDisputeResolution] = useState<DisputeResolution | null>(null);
  const [governingLaws, setGoverningLaws] = useState<string[]>([]);
  const [isCreatingNewPlaybook, setIsCreatingNewPlaybook] = useState<boolean>(false);
  const [isPlaybookDisabled, setIsPlaybookDisabled] = useState<boolean>(false);
  const [previousDisabledPlaybook, setPreviousDisabledPlaybook] = useState<string | null>(null);

  const enablingPreviousPlaybook = Boolean(previousDisabledPlaybook) && isPlaybookDisabled;

  const currentLanguage = useSelector(getCurrentLanguageSelector);
  const workspace = useSelector(
    (state) => workspacesReducer.getWorkspaceSelector(state, { id: Number(id) }),
  );
  const { error, loading, success } = useSelector((state) => (
    workspacesReducer.getGetWorkspacePlaybookSelector(state, { id: Number(id) })
  ));
  const updateState = useSelector((state) => (
    workspacesReducer.getUpdateWorkspacePlaybookSelector(state, { id: Number(id) })
  ));
  const createState = useSelector((state) => (
    workspacesReducer.getCreateWorkspacePlaybookSelector(state, { id: Number(id) })
  ));

  const processPlaybookValues = useCallback((values: {
    paymentTerm: string;
    disputeResolution: DisputeResolution;
    governingLaw: string[];
  }) => {
    const paymentTermValue = parseISODuration(values.paymentTerm || '');
    setPaymentTerm(paymentTermValue);
    setDisputeResolution(values.disputeResolution);

    const governingLawsWithLabels = values.governingLaw
      .map((country: string) => ({
        value: country, label: getGoverningLawTranslation(country, currentLanguage, message),
      }));
    setGoverningLaws(governingLawsWithLabels);
  }, [currentLanguage, message]);

  useEffect(() => {
    dispatch(workspacesReducer.getWorkspacePlaybook({
      id: Number(id),
      pipe: {
        onSuccess: (data) => {
          if (!data.enabled) {
            setIsPlaybookDisabled(true);
            setPreviousDisabledPlaybook(data.playbookTemplateType);
          }
          if (!data.playbookTemplateType) {
            setIsCreatingNewPlaybook(true);
            return;
          }
          setSelectedPlaybook(data.playbookTemplateType);

          if (!isCustomPlaybook(data.playbookTemplateType)) {
            processPlaybookValues(data.playbookValues);
          }
        },
      },
    }));
  }, [dispatch, id, processPlaybookValues]);

  const arbitrationValidator = (value: string) => {
    if (value === 'undefined') {
      return true;
    }
    return undefined;
  };

  const paymentTermValidations = useMemo(() => (
    composeValidators(
      numberValidator({
        message,
        field: 'paymentTerm',
        text: message({
          future: true,
          id: 'Please enter a whole number greater than 1.',
          comment: 'Validation message',
        }),
      }),
      minValue({
        message,
        field: 'paymentTerm',
        text: message({
          future: true,
          id: 'Please enter a whole number greater than 1.',
          comment: 'Validation message',
        }),
        limit: 1,
      }),
      required({
        message,
        field: 'paymentTerm',
        text: message({
          future: true,
          id: 'Please enter a whole number greater than 1.',
          comment: 'Validation message',
        }),
      }),
    )
  ), [message]);

  const disableAiReview = () => {
    dispatch(workspacesReducer.updateWorkspacePlaybook({
      id: Number(id),
      data: {
        enabled: false,
      },
      pipe: {
        onSuccess: () => {
          setIsEditMode(false);
          setIsPlaybookDisabled(true);
          setPreviousDisabledPlaybook(selectedPlaybook);
        },
      },
    }));
  };

  const enableAiReview = () => {
    dispatch(workspacesReducer.updateWorkspacePlaybook({
      id: Number(id),
      data: {
        enabled: true,
      },
      pipe: {
        onSuccess: () => {
          setIsEditMode(false);
          setIsPlaybookDisabled(false);
        },
      },
    }));
  };

  const onSubmit = async (formData: FormData) => {
    const editedValues = {
      paymentTerm: playbookParams.paymentTerm.playbookTemplateTypes.includes(formData.playbookTemplateType) ? `P${formData.paymentTerm}D` : undefined,
      disputeResolution: {
        arbitration: JSON.parse(formData.arbitration),
      },
      governingLaw: formData.governingLaws.map((item: any) => item.value),
    };
    const payload = {
      id,
      playbookTemplateType: formData.playbookTemplateType,
      enabled: true,
      playbookValues: editedValues,
    };

    if (isCreatingNewPlaybook) {
      dispatch(workspacesReducer.createWorkspacePlaybook({
        id: Number(id),
        data: { ...payload, accountId: workspace.account },
        pipe: {
          onSuccess: () => {
            setIsEditMode(false);
            setIsPlaybookDisabled(false);
            setPreviousDisabledPlaybook(null);
          },
        },
      }));
    } else {
      dispatch(workspacesReducer.updateWorkspacePlaybook({
        id: Number(id),
        data: payload,
        pipe: {
          onSuccess: () => {
            setIsEditMode(false);
            setIsPlaybookDisabled(false);
            setPreviousDisabledPlaybook(null);
          },
        },
      }));
    }
  };

  const resetFormState = () => {
    if (isCreatingNewPlaybook) {
      dispatch(workspacesReducer.createWorkspacePlaybookReset({ id: Number(id) }));
      return;
    }
    dispatch(workspacesReducer.updateWorkspacePlaybookReset({ id: Number(id) }));
  };

  const startEditMode = () => {
    resetFormState();
    setIsEditMode(true);
  };

  const renderHeader = () => {
    if (isPlaybookDisabled && !isEditMode) {
      return (
        <Button
          kind="primary"
          icon={ToggleOnIcon}
          onClick={isCustomPlaybook(selectedPlaybook) ? enableAiReview : startEditMode}
        >
          <Message
            future
            id="Enable"
            comment="Button text for enabling the AI Review feature."
          />
        </Button>
      );
    }

    if (isCustomPlaybook(selectedPlaybook)) {
      return (
        <DisableAiReview
          disableAiReview={disableAiReview}
          workspace={workspace}
          updateState={updateState}
        />
      );
    }

    return (
      <>
        <EditableForm.Actions
          resetFormState={resetFormState}
          formState={isCreatingNewPlaybook ? createState : updateState}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          ignorePristine={enablingPreviousPlaybook}
        />
        {!isEditMode && (
          <DisableAiReview
            disableAiReview={disableAiReview}
            workspace={workspace}
            updateState={updateState}
          />
        )}
      </>
    );
  };

  const governingLawOptions = useMemo(() => (
    [
      { value: 'EU', label: message({ future: true, id: 'European Union', comment: 'Option in the governing law selector' }) },
      { value: 'NORD', label: message({ future: true, id: 'Nordic countries', comment: 'Option in the governing law selector' }) },
      { value: 'GB-ENG', label: 'England' },
      { value: 'GB-SCT', label: 'Scotland' },
      { value: 'GB-WLS', label: 'Wales' },
      { value: 'GB-NIR', label: 'Northern Ireland' },
      ...getAmericanStatesAsOptions(),
      ...getCountryListAsOptions(currentLanguage),
    ]
  ), [currentLanguage, message]);

  const shouldDisableTemplateButton = (
    buttonPlaybookName: string,
  ) => {
    if (isEditMode) {
      return isCustomPlaybook(selectedPlaybook) && !isCustomPlaybook(buttonPlaybookName);
    }
    return selectedPlaybook !== buttonPlaybookName;
  };

  if (loading) {
    return <div className={style.Loading}><CircularSpinner /></div>;
  }

  const getRadioButton = (playbookName: string, values: any) => {
    if (isCustomPlaybook(playbookName)) {
      return isCustomPlaybook(selectedPlaybook) ? <input type="radio" checked className={style.RadioButton} /> : null;
    }
    return (
      <FinalFormField
        type="radio"
        value={playbookName}
        name="playbookTemplateType"
        id={`playbook-${playbookName}`}
        checked={values.playbookTemplateType === playbookName}
        className={style.RadioButton}
        component="input"
        disabled={!isEditMode}
      />
    );
  };

  return (
    <ReactFinalForm
      initialValues={{
        playbookTemplateType: selectedPlaybook,
        paymentTerm,
        governingLaws,
        arbitration: String(disputeResolution?.arbitration) || null,
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form className={style.AiReviewForm} onSubmit={handleSubmit}>
          <EditableForm.Header>
            {renderHeader()}
          </EditableForm.Header>
          {(isPlaybookDisabled && !isEditMode) ? (
            <EmptyState
              icon={(<Sparkles height="33px" />)}
              header="AI Review"
              content={(
                <div>
                  <Message
                    future
                    id="By enabling this feature, you are able to set guidelines such as payment terms or governing laws specifically for this workspace."
                    comment="Empty state text for the AI Review page when not enabled."
                  />
                </div>
              )}
              defaultStyle
              className={style.Empty}
            />
          ) : (
            <EditableForm.Body>
              <p className={style.HeaderText}>
                AI Review
                &nbsp;
                <Message
                  id="playbook"
                  comment="Header of the AI Review page."
                />
              </p>
              {success && (
                <>
                  <EditableForm.Label>
                    <Message id="Select playbook type" comment="Label in the AI Review page." />
                  </EditableForm.Label>
                  <div className={style.PlaybookTypesContainer}>
                    {Object.values(playbookTemplates).map((playbook) => {
                      const { icon, label } = playbook;
                      return (
                        <Tooltip
                          key={playbook.name}
                          messageClassName={style.DisabledButtonTooltip}
                          message={(
                            <Message
                              id="Not available while Custom playbook is active"
                              comment="Tooltip message for disabled AI Review playbook buttons."
                            />
                          )}
                          hideContent={(
                            !shouldDisableTemplateButton(playbook.name)
                            || !isCustomPlaybook(selectedPlaybook)
                          )}
                          side="right"
                          sideOffset={-200}
                        >
                          <label
                            htmlFor={`playbook-${playbook.name}`}
                          >
                            <div
                              className={clsx(style.TemplateButton, {
                                [style.Disabled]: shouldDisableTemplateButton(playbook.name),
                                [style.Selected]: (
                                  values.playbookTemplateType === playbook.name
                                  && !isCustomPlaybook(playbook.name)
                                ),
                                [style.CustomTemplate]: isCustomPlaybook(playbook.name),
                              })}
                            >
                              <div className={style.TemplateTopRow}>
                                <div className={style.AssetsContainer}>
                                  {icon}
                                  {label}
                                </div>
                                {getRadioButton(playbook.name, values)}
                              </div>
                              <br />
                              <div>{playbook.description}</div>
                              {isCustomPlaybook(playbook.name) && (
                              <Button
                                customClass={style.ContactSalesButton}
                                href={contactSalesHref}
                                external
                                target="_blank"
                                rel="noopener"
                                icon={<Edit height="14px" />}
                              >
                                {isCustomPlaybook(selectedPlaybook) ? (
                                  <div className={style.ActionText}>
                                    <Message
                                      future
                                      id="Talk to our team to edit"
                                      comment="Button text for contacting customer service"
                                    />
                                  </div>
                                ) : (
                                  <Message
                                    future
                                    id="Request access"
                                    comment="Button text for contacting customer service"
                                  />
                                )}
                              </Button>
                              )}
                            </div>
                          </label>
                        </Tooltip>
                      );
                    })}
                  </div>
                  {values.playbookTemplateType && !isCustomPlaybook(selectedPlaybook) && (
                    <>
                      <h1 className={style.PlaybookParamsHeader}>
                        <Message
                          id="To meet the {templateLabel} playbook guidelines, {featureName} will scan your legal documents for the following clauses:"
                          comment="Description of the playbook guidelines."
                          values={{
                            templateLabel: playbookTemplates[values.playbookTemplateType].label,
                            featureName: 'AI Review',
                          }}
                        />
                      </h1>
                      <ul>
                        {Object.values(playbookParams)
                          .filter((param) => (
                            param.playbookTemplateTypes.includes(values.playbookTemplateType)))
                          .map((param) => (
                            <li key={param.id}>
                              <p className={style.PlaybookParamLabel}>{param.label}</p>
                              <p>{param.description}</p>
                              {param.id === playbookParams.paymentTerm.id && (
                                <>
                                  <p>
                                    {
                                      playbookTemplates[values.playbookTemplateType]
                                        ?.recommendations
                                        ?.paymentTerm
                                    }
                                  </p>
                                  <div className={style.PaymentTermContainer}>
                                    <FinalFormField
                                      name="paymentTerm"
                                      disabled={!isEditMode}
                                      component={NumberField}
                                      value={Number(paymentTerm)}
                                      validate={paymentTermValidations}
                                      customClass={style.PaymentTermInput}
                                      min="1"
                                    />
                                    &nbsp;
                                    <Message
                                      future
                                      id="days"
                                      comment="Label for the payment term input."
                                    />
                                  </div>
                                </>
                              )}
                              {param.id === playbookParams.governingLaws.id && (
                                <FinalFormField
                                  name="governingLaws"
                                  value={governingLaws}
                                  component={SelectField}
                                  options={governingLawOptions}
                                  placeholder={message({
                                    future: true,
                                    comment: 'Placeholder in the AI Review page.',
                                    id: 'Select countries and regions',
                                  })}
                                  className={style.Input}
                                  disabled={!isEditMode}
                                  multi
                                  hideLabel
                                  closeOnSelect={false}
                                  validate={composeValidators(
                                    required({
                                      message,
                                      field: 'governingLaws',
                                      text: message({
                                        future: true,
                                        id: 'Please select at least one governing law.',
                                        comment: 'Validation message for governing law selection.',
                                      }),
                                    }),
                                  )}
                                />
                              )}
                              {param.id === playbookParams.disputeResolution.id && (
                              <>
                                <div className={style.ArbitrationInputContainer}>
                                  <FinalFormField
                                    type="radio"
                                    value="true"
                                    name="arbitration"
                                    checked={values.arbitration === 'true'}
                                    component={(props: FieldRenderProps<string, HTMLElement>) => (
                                      <>
                                        <input id="arbitration-accept" className={style.RadioButton} disabled={!isEditMode} {...props.input} />
                                        <EditableForm.Label htmlFor="arbitration-accept">
                                          <Message
                                            future
                                            id="Yes, I accept arbitration as a dispute resolution"
                                            comment="Checkbox label in the AI Review setup page."
                                          />
                                        </EditableForm.Label>
                                      </>
                                    )}
                                  />
                                </div>
                                <div className={style.ArbitrationInputContainer}>
                                  <FinalFormField
                                    type="radio"
                                    value="false"
                                    name="arbitration"
                                    checked={values.arbitration === 'false'}
                                    component={(props: FieldRenderProps<string, HTMLElement>) => (
                                      <>
                                        <input id="arbitration-decline" className={style.RadioButton} disabled={!isEditMode} {...props.input} />
                                        <EditableForm.Label htmlFor="arbitration-decline">
                                          <Message
                                            future
                                            id="No, I do not accept arbitration as a dispute resolution"
                                            comment="Checkbox label in the AI Review setup page."
                                          />
                                        </EditableForm.Label>
                                      </>
                                    )}
                                    validate={composeValidators(
                                      arbitrationValidator,
                                    )}
                                  />
                                </div>
                              </>
                              )}
                            </li>
                          ))}
                      </ul>
                    </>
                  )}
                </>
              )}
            </EditableForm.Body>
          )}
          <div className={style.ErrorContainer}>
            {(updateState.error || createState.error || error) && (
            <ApiError customMessage={unknownApiError} />
            )}
          </div>
        </form>
      )}
    />
  );
};

export const propsMapper = () => ({
  title: 'AI Review',
  modules: [[]],
});

export default adminPage(propsMapper)(AiReview);
