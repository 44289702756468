import userflow from 'userflow.js';
import { getDaysSinceTimestamp } from 'date';

import { checkAcl } from 'components/acl';

import type { Acl as AclType } from 'types/acl';
import { USER_ROLE_USER } from 'user';

const mapLanguageCode = (langCode: string | null | undefined) => {
  switch (langCode) {
    case 'da': return 'da-DK';
    case 'nl': return 'nl-NL';
    case 'en': return 'en-US';
    case 'fi': return 'fi-FI';
    case 'fr': return 'fr-FR';
    case 'de': return 'de-DE';
    case 'it': return 'it-IT';
    case 'no': return 'nb-NO';
    case 'pt': return 'pt-PT';
    case 'es': return 'es-ES';
    case 'sv': return 'sv-SE';
    default: return 'en-US';
  }
};

type PositionConfig = {
  account: Oneflow.Account,
  position: Oneflow.Position,
  workspaceAcl: AclType,
}

/*
type GuestConfig = {
  inlineComments: boolean,
  suggestions: boolean,
  language?: string | null | undefined,
}
*/
export const initialiseUserflowWithPosition = ({
  account,
  position,
  workspaceAcl,
}: PositionConfig) => {
  const daysSinceSignup = getDaysSinceTimestamp(position.registerTimestampTs);
  let newSignup = 'No';

  if (daysSinceSignup <= 14) {
    newSignup = 'Yes';
  }

  const canCreateBlankContract = checkAcl(workspaceAcl, 'collection:agreement:create_blank');
  const canUseTemplates = Boolean(
    checkAcl(workspaceAcl, 'collection:agreement:template:view')
    && checkAcl(workspaceAcl, 'collection:agreement:template:create'),
  );
  const canCreateUser = checkAcl(account.acl, 'account:position:create');

  // Can access
  const canAccessMarketplace = checkAcl(account.acl, 'account:marketplace');

  // Can administrate
  const canAdministrateUsers = checkAcl(account.acl, 'account:admin:user');
  const canAdministrateGroups = checkAcl(account.acl, 'account:admin:group');
  const canAdministrateRoles = checkAcl(account.acl, 'account:admin:role');
  const canAdministrateWorkpsaces = checkAcl(account.acl, 'account:admin:workspace');
  const canAdministrateAccount = checkAcl(account.acl, 'account:admin:branding');
  const canAdministrateTags = checkAcl(account.acl, 'account:admin:tag');
  const canAdministrateMarketplace = checkAcl(account.acl, 'account:admin:extension');
  const canAdministrateAuditLog = checkAcl(account.acl, 'account:admin:audit_log');
  const canAdministrateDataManagement = checkAcl(account.acl, 'account:admin:data_management');
  const canAdministrateBilling = checkAcl(account.acl, 'account:admin:billing');
  const enabledEntitlements = account.enabledEntitlements?.map((entitlement) => entitlement.key);
  const enabledExtensions = account.enabledExtensions?.map((extension) => extension.key);

  userflow.init(window.USERFLOW_TOKEN);
  userflow.identify(position.id, {
    // agreement attributes: reset
    positionId: position.id,
    creationOrigin: account.creationOrigin,
    suggestionsEnabled: false,
    hasLogo: account.logoUrl !== null,
    inlineCommentsEnabled: false,
    canUseInlineComments: false,
    canUseSuggestions: false,
    // user/account attributes
    accountId: account.id,
    signupISO: position.registerTime,
    lastLoggedInISO: position.loginTime,
    isTrial: account.trial ? 'Yes' : 'No',
    language: position.language,
    locale_code: mapLanguageCode(position.language),
    device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
    newSignup,
    canCreateBlankContract,
    canUseTemplates,
    canCreateUser,
    hasCreatedWorkspace: !!account.onboardingStage,
    plan: account.planName,
    isDemo: account.isDemo,
    isLicensedUser: position.userRole === USER_ROLE_USER,

    canAccessMarketplace,

    canAdministrateUsers,
    canAdministrateGroups,
    canAdministrateRoles,
    canAdministrateWorkpsaces,
    canAdministrateAccount,
    canAdministrateTags,
    canAdministrateMarketplace,
    canAdministrateAuditLog,
    canAdministrateDataManagement,
    canAdministrateBilling,

    enabledEntitlements,
    enabledExtensions,
  });
};

/*
export const initialiseUserflowForGuests = ({
  inlineComments,
  suggestions,
  language,
}: GuestConfig) => {
  userflow.init(window.USERFLOW_TOKEN);
  userflow.identifyAnonymous({
    inlineCommentsEnabled: inlineComments,
    suggestionsEnabled: suggestions,
    locale_code: mapLanguageCode(language),
    device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
    isGuest: true,
  });
};
*/
