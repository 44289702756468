import { checkAcl } from 'components/acl';

export const hasActiveTemplateGroups = (templateGroups: Oneflow.TemplateGroup[]) => (
  templateGroups.some((templateGroup) => templateGroup?.active)
);

export const isExtensionActive = (extension: Oneflow.Extension) => extension.state;

export const hasExtensionAdminPermission = (account: Oneflow.Account) => checkAcl(account?.acl, 'account:admin:extension');

export const TEMPLATE_GROUPS_QUERY = '/admin/account/extensions/template-groups';

export const hasNoActiveTemplateGroupsAdmin = (
  templateGroups: Oneflow.TemplateGroup[],
  extension: Oneflow.Extension,
  account: Oneflow.Account,
) => {
  const activeTemplateGroups = hasActiveTemplateGroups(templateGroups);
  const activeExtension = isExtensionActive(extension);
  const extensionAdminPermission = hasExtensionAdminPermission(account);
  const noActiveTemplateGroups = activeTemplateGroups === false;

  return activeExtension && noActiveTemplateGroups && extensionAdminPermission;
};
