import { ZodError } from 'zod';

class DataInvalidError extends ZodError {
  message = '';

  constructor(zodErrorData, rollbarTitle) {
    super(zodErrorData.error.issues);

    const validationInfo = zodErrorData.error.flatten((issue) => ({
      ...issue,
      errorCode: issue.code,
    }));

    this.name = 'DataInvalidError';
    this.message = rollbarTitle;
    this.validationInfo = validationInfo;
  }

  toString() {
    return this.message;
  }
}

export default DataInvalidError;
