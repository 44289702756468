// @flow

import * as React from 'react';
import clsx from 'clsx';

import { Message } from '@oneflowab/pomes';
import Toggle from 'components/toggle';
import Country from 'components/countries';
import Tooltip from 'components/tooltip';

import rainbow from 'styles/rainbow-shadow.module.scss';

import FiFtnLogo from 'components/modals/sign-contract/electronic-id-options/logos/fi_ftn';
import DkMitIdLogo from 'components/modals/sign-contract/electronic-id-options/logos/dk_mitid';
import NoBankIdLogo from 'components/modals/sign-contract/electronic-id-options/logos/no_bankid';
import SeBankIdLogo from 'components/modals/sign-contract/electronic-id-options/logos/se_bankid';
import BeItsMeLogo from 'components/modals/sign-contract/electronic-id-options/logos/be_itsme';
import FrejaLogo from 'components/modals/sign-contract/electronic-id-options/logos/freja';
import NlIdinLogo from 'components/modals/sign-contract/electronic-id-options/logos/nl_idin';
import SmartIdLogo from 'components/modals/sign-contract/electronic-id-options/logos/smartid';
import EeEstEidLogo from 'components/modals/sign-contract/electronic-id-options/logos/ee_esteid';
import { EeEsteIdMobile } from 'components/modals/sign-contract/electronic-id-options/logos/ee_esteid_mobile';
import { BeBeId } from 'components/modals/sign-contract/electronic-id-options/logos/be_beid';
import DeNpaLogo from 'components/modals/sign-contract/electronic-id-options/logos/de_npa';
import ZealIdLogo from 'components/modals/sign-contract/electronic-id-options/logos/zealid';
import EvrotrustLogo from 'components/modals/sign-contract/electronic-id-options/logos/evrotrust';
import { DTrustSignMe } from 'components/modals/sign-contract/electronic-id-options/logos/d_trust_sign_me';
import { SimplySign } from 'components/modals/sign-contract/electronic-id-options/logos/simply_sign';

import style from './electronic-id.module.scss';

export type Props = {
  isChecked: boolean,
  isEnabled: Boolean,
  isVisible: Boolean,
  canUpdate: Boolean,
  onEidToggle: () => void,
  title: React.Node,
  description: React.Node,
  itemKey: string,
  countries: React.Node,
  currentLanguage: EnabledLanguages,
};

export const ElectronicId = ({
  isChecked,
  isEnabled,
  isVisible,
  canUpdate,
  onEidToggle,
  title,
  description,
  itemKey,
  countries,
  currentLanguage,
}: Props) => {
  const renderCountries = () => {
    const translatedCountries = countries.map((country, index) => {
      let countryFormatted;

      if (country === 'EU') {
        countryFormatted = (
          <Message
            id="EU/EEA"
            comment="Used as a country equivalent in a country list"
          />
        );
      } else {
        countryFormatted = <Country country={country} lang={currentLanguage} />;
      }

      return (
        <React.Fragment key={country}>
          {countryFormatted}
          {index < countries.length - 1 && ', '}
        </React.Fragment>
      );
    });

    return (
      <p>
        <Message
          id="Countries: {countries}"
          values={{
            countries: <span>{translatedCountries}</span>,
          }}
          comment="Help text for the electronic IDs extension."
        />
      </p>
    );
  };

  const logoMap = {
    eidHubSeBankidSign: { component: SeBankIdLogo, className: style.LogoSquare },
    seBankid: { component: SeBankIdLogo, className: style.LogoSquare },
    eidHubNoBankidSign: { component: NoBankIdLogo, className: style.Logo },
    noBankid: { component: NoBankIdLogo, className: style.Logo },
    eidHubDkMitidSign: { component: DkMitIdLogo, className: style.Logo },
    dkNemid: { component: DkMitIdLogo, className: style.Logo },
    eidHubFiFtnSign: { component: FiFtnLogo, className: style.Logo },
    fiFtn: { component: FiFtnLogo, className: style.Logo },
    eidHubBeItsmeSign: { component: BeItsMeLogo, className: style.LogoSquare },
    eidHubFrejaSign: { component: FrejaLogo, className: style.Logo },
    eidHubNlIdinSign: { component: NlIdinLogo, className: style.LogoSquare },
    eidHubSmartidSign: { component: SmartIdLogo, className: style.LogoSquare },
    eidHubEeEsteidSign: { component: EeEstEidLogo, className: style.LogoSquare },
    eidHubEeEsteidMobileSign: { component: EeEsteIdMobile, className: style.LogoSquare },
    eidHubBeBeidSign: { component: BeBeId, className: style.LogoSquare },
    eidHubDeNpaSign: { component: DeNpaLogo, className: style.LogoSquare },
    eidHubZealidSign: { component: ZealIdLogo, className: style.Logo },
    eidHubEvrotrustSign: { component: EvrotrustLogo, className: style.Logo },
    eidHubDTrustSignMeSign: { component: DTrustSignMe, className: style.Logo },
    eidHubSimplySignSign: { component: SimplySign, className: style.Logo },
  };

  const renderLogo = () => {
    const logo = logoMap[itemKey];
    if (logo) {
      const { component: LogoComponent, className } = logo;
      return <LogoComponent className={className} />;
    }
    return null;
  };

  const renderToggle = () => {
    // For EIDs not enabled and when user can't enable, show a tooltip
    if (!isChecked && !canUpdate) {
      return (
        <Tooltip
          message={(
            <Message
              id="Contact your administrator to enable"
              comment="Tooltip message for enabling an extension when something blocks the action"
            />
          )}
          side="right"
        >
          <Toggle
            checked={Boolean(isChecked)}
            className={style.Toggle}
            onChange={onEidToggle}
            disabled
            version="latest"
          />
        </Tooltip>
      );
    }

    // For other cases, simply default the implementation and disable the toggle accordingly
    return (
      <Toggle
        checked={Boolean(isChecked)}
        className={style.Toggle}
        onChange={onEidToggle}
        disabled={!isEnabled || !canUpdate}
        version="latest"
      />
    );
  };

  const renderToggleInfo = () => {
    if (description) {
      return description;
    }

    return '';
  };

  if (!isVisible) {
    return '';
  }

  const innerContainerclasses = clsx(style.InnerContainer, rainbow.ShadowOnHover);

  return (
    <div className={style.SectionContainer}>
      <div className={innerContainerclasses}>
        <div className={style.LogoContainer}>
          {renderLogo()}
        </div>

        <div className={style.ContentContainer}>
          <div className={style.Title}>
            {title}
          </div>
          <div className={style.SubTitle}>
            {renderCountries()}
          </div>
        </div>

        <div className={style.ToggleContainer}>
          <div className={style.ToggleWrapper}>
            {renderToggle()}
          </div>
          <span className={style.ToggleInfo}>
            {renderToggleInfo()}
          </span>
        </div>
      </div>
    </div>
  );
};
