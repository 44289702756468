// @flow

import { put, call } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import client from 'oneflow-client';
import {
  assignUserWorkspaces,
  getWorkspaceUsers,
} from 'oneflow-client/workspace-users';

import apiWrapper from 'sagas/api-wrapper';

import workspaceUsersReducer from 'reducers/entities/workspace-users';
import bindings from 'reducers/entities/bindings';
import groups from 'reducers/entities/groups';

type MapperArgs = {
  data: any,
  action: {
    id: number,
    type: string,
  },
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(workspaceUsersReducer.setWorkspaceUsers(data.entities.workspaceUsers));
}

export function* createMapper({ data }) {
  yield put(groups.setGroups(data.groups));
}

export function* updateMapper({ action }) {
  yield put(workspaceUsersReducer.clearWorkspaceUser({ id: action.data.removeData.id }));
}

export function* clearMapper({ action }) {
  yield put(workspaceUsersReducer.clearWorkspaceUser({ id: action.id }));
}

export function* updateRequest({ createData, removeData }) {
  yield call(client.removeBindingByResource, removeData);
  return yield call(client.createBindingByResource, createData);
}

export function* assignUserWorkspacesMapper({ data }) {
  yield put(bindings.setBindings(data.entities.bindings));
}

const mappers = {
  query: {
    mapper,
    request: getWorkspaceUsers,
  },
  create: {
    mapper: createMapper,
    request: client.createBindingByResource,
  },
  update: {
    mapper: updateMapper,
    request: updateRequest,
  },
  remove: {
    mapper: clearMapper,
    request: client.removeBindingByResource,
  },
  rpcs: {
    assignUserWorkspaces: {
      name: 'assignUserWorkspaces',
      mapper: assignUserWorkspacesMapper,
      request: assignUserWorkspaces,
    },
  },
};

const workspaceUsersSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: workspaceUsersReducer,
  mappers,
});

export default workspaceUsersSagas;
