import { useSelector } from 'react-redux';

import useAgreement from 'hooks/use-agreement';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';

import { getPartyByParticipantId, isIndividual } from 'agreement/party';
import { getCurrentContractId } from 'reducers/current-contract';
import { getAgreementMyParticipant } from 'agreement/selectors';
import hasSigned from 'agreement/participant/has-signed';

import Button from 'components/button';
import Message from 'components/message';
import DelegateSigningRightsModal from 'components/modals/delegate-signing-rights';

import style from './more-options-tab.module.scss';

const DelegateSigningRights = () => {
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const participant = getAgreementMyParticipant(agreement);
  const participantId = participant?.id;
  const party = getPartyByParticipantId(agreement, participantId);
  const isInPreviewMode = useIsInPreviewMode();

  if (isIndividual(party)) {
    return null;
  }

  const getDelegateSigningButton = (onClick) => (
    <Button
      kind="wide"
      color="grey"
      onClick={onClick}
      disabled={hasSigned(participant) || isInPreviewMode}
      customClass={style.ButtonMargin}
    >
      <Message
        id="Delegate signing"
        comment="Button text for counterparties who want to delegate the signing of a contract to someone else."
      />
    </Button>
  );

  return (
    <DelegateSigningRightsModal agreement={agreement}>
      {getDelegateSigningButton}
    </DelegateSigningRightsModal>
  );
};

export default DelegateSigningRights;
