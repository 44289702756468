import {
  useEffect, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message, localize, MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';
import Button from 'components/button';
import agreementsReducer from 'reducers/entities/agreements';
import { TryAgainButton } from 'components/buttons';
import DocumentActionErrorBody from 'components/document-action-error';
import {
  SIGN_METHOD_HANDWRITTEN_SIGN,
} from 'agreement/participant/constants';
import hasDeliveryChannelSameDevice from 'agreement/participant/has-delivery-channel-same-device';

import { loadHandwrittenFonts } from 'font-manager';
import TypedSignature from './typed-signature';
import DrawSignature from './draw-signature';
import HandwrittenContext from './handwritten-context';

import style from './handwritten.module.scss';

type ModalData = {
  ssn: string
};

export type Props = {
  onClose: () => void,
  onStepComplete: () => void,
  onSyncStepData: (arg1: ModalData) => void,
  agreement: Agreement,
  myParticipantWhenSignatory: AgreementParticipant,
  guestToken?: string,
  onSuccess: () => void,
  message: MessageTranslator,
  modalKey: string,
};

function Handwritten(props: Props) {
  const [maxCanvasWidth, setMaxCanvasWidth] = useState(0);
  const [handwrittenType, setHandwrittenType] = useState('drawn');
  const {
    agreement,
    myParticipantWhenSignatory,
    guestToken,
    onSyncStepData,
    onStepComplete,
    onSuccess,
    message,
  } = props;
  const dispatch = useDispatch();
  const responseState = useSelector(
    (state) => agreementsReducer.getSignAgreementSelector(state, { id: agreement.id }),
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const isNotUsingSameDevice = !hasDeliveryChannelSameDevice(myParticipantWhenSignatory);
  const isTypedInAvailableOptions = agreement?.availableOptions?.handwrittenSignatureAllowTyped?.includes('typed');
  const isTypedAvailable = isNotUsingSameDevice && isTypedInAvailableOptions;

  useEffect(() => {
    if (responseState.success) {
      onSuccess();
      onStepComplete();
    }
  }, [responseState.status, onStepComplete, onSuccess, responseState.success]);

  const updateCanvasWidth = useCallback((modalBodyNode) => {
    if (!modalBodyNode?.offsetWidth) {
      return;
    }
    setMaxCanvasWidth(Math.ceil(modalBodyNode.offsetWidth));
  }, []);

  useEffect(() => {
    loadHandwrittenFonts();
  }, []);

  const selectHandwrittenType = (e: any) => {
    setHandwrittenType(e.target.value);
  };

  const handleSubmit = useCallback((formData, formFields) => {
    if (!formData.signature) {
      return;
    }

    if (formData.handwrittenType === 'typed' && !formFields?.signatureText?.validity?.valid) {
      return;
    }

    if (!isTypedAvailable && formData.handwrittenType === 'typed') {
      return;
    }

    const [, base64SignatureStringWithoutMIMEType] = formData.signature.split(',');

    const handwrittenSignatureType = formData.handwrittenType || (
      !isTypedAvailable ? 'drawn' : null);

    const data = {
      signMethod: SIGN_METHOD_HANDWRITTEN_SIGN,
      handwrittenSignature: base64SignatureStringWithoutMIMEType,
      checksum: agreement.checksum,
      participantId: myParticipantWhenSignatory.id,
      handwrittenSignatureType,
    } as const;

    if (guestToken) {
      data.guestToken = guestToken;
    }

    onSyncStepData(data);
    dispatch(agreementsReducer.signAgreement({
      id: agreement.id,
      data,
      checksum: agreement.checksum,
    }));
  }, [
    agreement.checksum,
    agreement.id,
    dispatch,
    guestToken,
    myParticipantWhenSignatory,
    onSyncStepData,
    isTypedAvailable,
  ]);
  const getActions = ({ closeConfirmation }) => {
    if (responseState.error) {
      return (
        <div className={style.TryAgainButtonContainer}>
          <TryAgainButton onClick={closeConfirmation} />
        </div>
      );
    }
    return (
      <div className={style.ActionContainer}>
        <p className={style.DisclaimerText}>
          <Message
            id="By pressing Sign, I confirm that the above represents my signature."
            comment="Disclaimer on the signature modal component"
          />
        </p>
        <div className={style.SignButtonContainer}>
          <Button
            customClass={style.SignButton}
            type="submit"
            kind="document-sign"
            disabled={responseState.loading || buttonDisabled}
          >
            <Message
              id="Sign"
              comment="Label for Sign contract button in contract view."
            />
          </Button>
        </div>
      </div>
    );
  };

  const renderSignatureTypeRadioButtons = () => (
    <div className={style.RadiobuttonContainer}>
      <label htmlFor="drawn">
        <input
          name="handwrittenType"
          type="radio"
          value="drawn"
          id="drawn"
          checked={handwrittenType === 'drawn'}
          onChange={selectHandwrittenType}
          className={style.Radiobutton}
        />
        <Message
          id="Draw signature"
          comment="Radio button label for draw signature"
        />
      </label>
      <label htmlFor="typed">
        <input
          type="radio"
          name="handwrittenType"
          value="typed"
          id="typed"
          checked={handwrittenType === 'typed'}
          onChange={selectHandwrittenType}
          className={style.Radiobutton}
        />
        <Message
          id="Type signature"
          comment="Radio button label for typed signature"
        />
      </label>
    </div>
  );

  const renderBody = () => {
    if (responseState.error) {
      return (
        <DocumentActionErrorBody error={responseState.error} />
      );
    }

    return (
      <div ref={updateCanvasWidth}>
        {isTypedAvailable
          ? renderSignatureTypeRadioButtons()
          : null}
        <div>
          {handwrittenType === 'typed' ? (
            <TypedSignature
              maxCanvasWidth={maxCanvasWidth}
              initialSignatureText={myParticipantWhenSignatory?.fullname}
            />
          ) : (
            <DrawSignature
              maxCanvasWidth={maxCanvasWidth}
              placeholderText={message({
                id: 'Draw your signature here',
                comment: 'Placeholder of draw signature area',
              })}
            />
          )}
        </div>
      </div>
    );
  };

  const {
    onClose,
    modalKey,
  } = props;

  return (
    <HandwrittenContext.Provider value={{ buttonDisabled, setButtonDisabled }}>
      <ModalForm.Native
        title={(
          <Message
            id="Document signing"
            comment="Modal title for signing with handwritten"
          />
        )}
        body={renderBody()}
        onSubmit={handleSubmit}
        actions={getActions}
        onClose={onClose}
        noValidate
        modalKey={modalKey}
      />
    </HandwrittenContext.Provider>
  );
}

export default localize<Props>(Handwritten);
