import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Range, BaseSelection } from 'slate';
import { useSlateSelection, useSlateStatic } from 'slate-react';
import { debounce } from 'lodash';

import useFocusedEditor from 'hooks/rich-text-editor/use-focused-editor';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';

import { getDocumentSelectedEditorRangeInfo } from '../utils';

const useSelectedRectangle = () => {
  const editor = useSlateStatic();
  const focusedEditor = useFocusedEditor();
  const selection = useSlateSelection();

  /* when PostComment/PostSuggestion is open on readOnly editor,
  selection from `useSlateSelection` goes to null. So we need to keep it in editorSelection */
  const [editorSelection, setEditorSelection] = useState<BaseSelection | null>(null);

  const [selectedRectangle, setSelectedRectangle] = useState(null);

  const { documentScrollContainerRef } = useDocumentLayout();

  const resetEditorSelectedRangeInfo = useCallback(() => {
    setSelectedRectangle(null);
  }, []);

  useEffect(() => {
    if (editor !== focusedEditor) {
      resetEditorSelectedRangeInfo();
    }
  }, [editor, focusedEditor, resetEditorSelectedRangeInfo]);

  useEffect(() => {
    if (selection) {
      setEditorSelection(selection);
    }
  }, [selection]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSelectedRectangle = useCallback(
    debounce((rectangle) => {
      setSelectedRectangle(rectangle);
    }, 200),
    [],
  );

  useEffect(() => {
    if (!editorSelection || Range.isCollapsed(editorSelection)) {
      resetEditorSelectedRangeInfo();
      return;
    }

    const selectedEditorRectangle = getDocumentSelectedEditorRangeInfo(
      editor,
      editorSelection,
      documentScrollContainerRef.current,
    );

    debouncedSetSelectedRectangle(selectedEditorRectangle);
  }, [
    debouncedSetSelectedRectangle,
    documentScrollContainerRef,
    editor,
    editorSelection,
    resetEditorSelectedRangeInfo,
  ]);

  return {
    editorSelection,
    resetEditorSelectedRangeInfo,
    selectedRectangle,
  };
};

export default useSelectedRectangle;
