/* eslint-disable react/display-name */
import { useMemo, useEffect } from 'react';
import type { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';
import type { PropsMapper } from 'hocs/admin-page/admin-page';
import { getAccountFromSessionSelector } from 'reducers/session';
import accountUsersReducer from 'reducers/entities/account-users';
import accountGroupsReducer from 'reducers/entities/account-groups';

import { checkAcl } from 'components/acl';
import ActionBar from 'components/action-bar';
import Button from 'components/button/button';
import EmptyState from 'components/empty-state';
import Pagination from 'components/pagination';
import { GrantUserAccountAccess, GrantUserAccountAccessNonEnterprise } from 'components/modals/grant-user-account-access';
import GrantGroupAccountAccess from 'components/modals/grant-group-account-access/grant-group-account-access';
import GrantUserAccessIcon from 'components/icons/grant-user-access';
import GrantGroupAccessIcon from 'components/icons/grant-group-access';

import DirectAccessTable from './direct-access-table';
import GroupAccessTable from './group-access-table';
import styles from './account-access.module.scss';

type Props = {
  message: MessageTranslator,
};

export type Query = {
  count: number,
  error?: unknown,
  loading: boolean,
  pagination: { limit: number, offset: number },
  params: object,
  result: number[],
  sort: string[],
  status: string,
};

type DirectOrGroupType = 'direct' | 'group';

const DEFAULT_PAGINATION = {
  limit: 20,
  offset: 0,
};

export const getAccountUsersQueryName = (id: number) => `admin/accounts/${id}/users`;
export const getAccountGroupsQueryName = (id: number) => `admin/accounts/${id}/groups`;

export const AccountAccessPage = ({ message }: Props) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountFromSessionSelector);
  const hasGrantSystemRolePermissionPosition: boolean = useMemo(() => checkAcl(account.acl, 'account:position:system_role_binding:create'), [account.acl]);
  const hasGrantCustomRolePermissionPosition: boolean = useMemo(() => checkAcl(account.acl, 'account:position:custom_role_binding:create'), [account.acl]);
  const hasGrantSystemRolePermissionGroup: boolean = useMemo(() => checkAcl(account.acl, 'account:group:system_role_binding:create'), [account.acl]);
  const hasGrantCustomRolePermissionGroup: boolean = useMemo(() => checkAcl(account.acl, 'account:group:custom_role_binding:create'), [account.acl]);
  const hasGrantGroupAccess = hasGrantSystemRolePermissionGroup
    || hasGrantCustomRolePermissionGroup;
  const hasViewAccessGroup: boolean = useMemo(() => checkAcl(
    account?.acl,
    [
      'account:group:custom_role_binding:view',
      'account:group:system_role_binding:view',
    ],
    { match: 'any' },
  ),
  [account?.acl]);

  const accountUsersBindingsQuery = useSelector((state) => accountUsersReducer.getQuerySelector(
    state, { name: getAccountUsersQueryName(account.id) },
  ));

  const accountUsersBindings = useSelector((state) => accountUsersReducer.getAccountUsersSelector(
    state, { ids: accountUsersBindingsQuery.result },
  ));

  const accountGroupsBindingsQuery = useSelector((state) => accountGroupsReducer.getQuerySelector(
    state, { name: getAccountGroupsQueryName(account.id) },
  ));

  const accountGroupsBindings = useSelector((state) => accountGroupsReducer
    .getAccountGroupsSelector(state, { ids: accountGroupsBindingsQuery.result }));

  useEffect(() => {
    dispatch(accountUsersReducer.queryAccountUsers({
      name: getAccountUsersQueryName(account.id),
      params: {
        actorType: 'position',
      },
      pagination: DEFAULT_PAGINATION,
      sort: ['fullname'],
    }));

    if (hasViewAccessGroup) {
      dispatch(accountGroupsReducer.queryAccountGroups({
        name: getAccountGroupsQueryName(account.id),
        params: {
          actorType: 'group',
        },
        pagination: DEFAULT_PAGINATION,
        sort: ['name'],
      }));
    }
  }, [account.id, dispatch, hasViewAccessGroup]);

  const getGrantUserAccountAccessButton = () => (onClick: () => void) => {
    const GrantUserAccountAccessButton = (
      <Button
        icon={GrantUserAccessIcon}
        kind="primary"
        disabled={!hasGrantSystemRolePermissionPosition && !hasGrantCustomRolePermissionPosition}
        onClick={onClick}
      >
        <Message
          id="Grant user access"
          comment="Button for opening grant account access modal."
        />
      </Button>
    );
    return GrantUserAccountAccessButton;
  };

  const getGrantGroupAccountAccessButton = () => (onClick: () => void) => {
    const GrantGroupAccountAccessButton = (
      <Button
        icon={GrantGroupAccessIcon}
        kind="primary"
        disabled={!hasGrantSystemRolePermissionGroup
          && !hasGrantCustomRolePermissionGroup}
        onClick={onClick}
      >
        <Message
          id="Grant group access"
          comment="Button for opening grant account access modal."
        />
      </Button>
    );
    return GrantGroupAccountAccessButton;
  };

  const onPaginate = (type: DirectOrGroupType, pagination: RequestParams.Pagination) => {
    if (type === 'direct') {
      dispatch(accountUsersReducer.queryAccountUsers({
        name: getAccountUsersQueryName(account.id),
        params: {
          actorType: 'position',
        },
        pagination,
        sort: ['fullname'],
      }));
    } else if (type === 'group') {
      dispatch(accountGroupsReducer.queryAccountGroups({
        name: getAccountGroupsQueryName(account.id),
        params: {
          actorType: 'group',
        },
        pagination,
        sort: ['name'],
      }));
    }
  };

  const renderPagination = (type: DirectOrGroupType): ReactNode => {
    const query = type === 'direct' ? accountUsersBindingsQuery : accountGroupsBindingsQuery;

    return (
      !query.loading && query.count > 0 && (
      <Pagination
        totalItems={query.count}
        itemsPerPage={query.pagination.limit}
        currentOffset={query.pagination.offset}
        onChange={(pagination: PaginationType) => onPaginate(type, pagination)}
        entityName={message({
          id: 'account roles',
          comment: 'Plural form in pagination of account roles in the user pages.',
        }) as string}
      />
      ));
  };

  const isQueryResultsEmpty = accountUsersBindingsQuery.count === 0
    && accountGroupsBindingsQuery.count === 0;
  const isQueriesLoading = accountUsersBindingsQuery.loading
    || accountGroupsBindingsQuery.loading;
  const shouldShowEmptyState = isQueryResultsEmpty && !isQueriesLoading;

  const renderEmptyState = () => (
    <EmptyState
      icon={<GrantGroupAccessIcon height="28px" />}
      header={message({
        id: 'Assign an user or a group an account role',
        comment: 'Empty state header for the account access list in the account access page.',
      })}
      content={message({
        id: 'Different roles will grant access to different application areas.',
        comment: 'Different roles will grant access to different application areas.',
      })}
      defaultStyle
      className={styles.EmptyState}
    />
  );

  const renderGrantUserAccountModal = () => {
    const hasCustomRoleCreatePermission = checkAcl(account.acl, 'account:position:custom_role_binding:create');
    const hasSystemRoleCreatePermission = checkAcl(account.acl, 'account:position:system_role_binding:create');

    const hasFullAccountRoleCreateAccess = hasCustomRoleCreatePermission
      && hasSystemRoleCreatePermission;

    if (hasFullAccountRoleCreateAccess) {
      return (
        <GrantUserAccountAccess
          shouldRedirectToAccountAccess={false}
          renderedFromAccountAccessPage
        >
          {getGrantUserAccountAccessButton()}
        </GrantUserAccountAccess>
      );
    }

    return (
      <GrantUserAccountAccessNonEnterprise
        renderedFromAccountAccessPage
        shouldRedirectToAccountAccess
      >
        {getGrantUserAccountAccessButton()}
      </GrantUserAccountAccessNonEnterprise>
    );
  };

  const renderGrantGroupAccountModal = () => (
    <GrantGroupAccountAccess
      renderedFromAccountAccessPage
      shouldRedirectToAccountAccess={false}
    >
      {getGrantGroupAccountAccessButton()}
    </GrantGroupAccountAccess>
  );

  return (
    <div className={styles.AccountContainer}>
      <ActionBar collapsed>
        <ActionBar.Group>
          <div className={styles.ButtonsContainer}>
            {renderGrantUserAccountModal()}
            {hasGrantGroupAccess
            && renderGrantGroupAccountModal()}
          </div>
        </ActionBar.Group>
      </ActionBar>
      {shouldShowEmptyState
        ? renderEmptyState() : (
          <>
            <DirectAccessTable
              account={account}
              message={message}
              items={accountUsersBindings}
              query={accountUsersBindingsQuery}
              hasGrantSystemRolePermission={hasGrantSystemRolePermissionPosition}
              hasGrantCustomRolePermission={hasGrantCustomRolePermissionPosition}
            >
              {renderPagination('direct')}
            </DirectAccessTable>
            {hasViewAccessGroup
              && (
              <GroupAccessTable
                account={account}
                query={accountGroupsBindingsQuery}
                message={message}
                items={accountGroupsBindings}
                hasGrantSystemRolePermission={hasGrantSystemRolePermissionGroup}
                hasGrantCustomRolePermission={hasGrantCustomRolePermissionGroup}
              >
                {renderPagination('group')}
              </GroupAccessTable>
              )}
          </>
        )}
    </div>
  );
};

type PropsMapper = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: PropsMapper }) => ({
  title: message({
    id: 'Account access',
    comment: 'The page title',
  }),
  modules: [[]],

});
export default adminPage(propsMapper)(AccountAccessPage);
