import getFontAssetsDomain from './get-font-asset-domain.js';

const loadComicNeue = () => {
  const normalComicNeue = new FontFace(
    'Comic Neue',
    `url(${getFontAssetsDomain()}/resources/fonts/comic-neue/comic-neue_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicComicNeue = new FontFace(
    'Comic Neue',
    `url(${getFontAssetsDomain()}/resources/fonts/comic-neue/comic-neue_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldComicNeue = new FontFace(
    'Comic Neue',
    `url(${getFontAssetsDomain()}/resources/fonts/comic-neue/comic-neue_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicComicNeue = new FontFace(
    'Comic Neue',
    `url(${getFontAssetsDomain()}/resources/fonts/comic-neue/comic-neue_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [
    normalComicNeue,
    italicComicNeue,
    boldComicNeue,
    boldItalicComicNeue,
  ];
};

export default loadComicNeue;
