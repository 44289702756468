import Button from 'components/button';

import type { FlowData, CancelFlowAction } from 'types/cancellation-flow';

type CancelFlowActionShowFlow = CancelFlowAction & {
  allowContinue: boolean,
  flow: string,
  modalData: FlowData,
  syncData: FlowData,
  onSyncFlowData: (data: FlowData) => void,
  onSyncStepData: (data: FlowData) => void,
  setShowModal: (flow: string) => void,
};

const ShowFlow = ({
  children,
  kind,
  color,
  syncData,
  onSyncFlowData,
  flow,
  allowContinue,
  modalData,
  onSyncStepData,
  setShowModal,
}: CancelFlowActionShowFlow) => {
  const onClick = () => {
    const allData = {
      ...modalData,
      ...syncData,
    };

    if (syncData) {
      onSyncStepData({ ...allData });
      onSyncFlowData({ ...allData });
    }
    setShowModal(flow);
  };

  return (
    <Button
      kind={kind}
      color={color}
      onClick={onClick}
      disabled={!allowContinue}
    >
      {children}
    </Button>
  );
};

export default ShowFlow;
