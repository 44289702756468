// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const GroupMembershipAddEvent = ({ auditLogEvent }: Props) => (
  <Message
    id="{userName} was {jsx-start}added{jsx-end} in the group {groupName}"
    comment="Audit log event message for added group"
    component="span"
    className={style.Green}
    values={{
      userName: auditLogEvent.target.name,
      groupName: auditLogEvent.secondaryTarget.name,
    }}
  />
);

export default GroupMembershipAddEvent;
