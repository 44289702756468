// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import isString from 'lodash/isString';
import clsx from 'clsx';

import ExternalLink from 'components/icons/external-link';

import style from './dynamic-link.module.scss';

const isExternal = (url) => /^https?:\/\//.test(url);

type Props = {
  children: React.Node,
  className?: string,
  icon?: React.Node,
  onClick?: () => void,
  textClass?: string,
  url: string,
  trackable?: string | TrackableObject,
  hideExternalIcon?: boolean,
};

class DynamicLink extends React.Component<Props> {
  getOnClick() {
    const { onClick, trackable } = this.props;

    if (!onClick && !trackable) {
      return undefined;
    }

    if (!trackable) {
      return onClick;
    }

    return (e) => {
      if (onClick) {
        onClick(e);
      }

      setTimeout(() => {
        if (isString(trackable)) {
          amplitudeLogEvent(trackable);
        } else {
          amplitudeLogEvent(trackable.name, trackable.props, trackable.groups);
        }
      }, 0);
    };
  }

  renderExternalLink() {
    const {
      url,
      children,
      icon,
      className,
      textClass,
      hideExternalIcon,
    } = this.props;

    let externalLink = null;
    if (!hideExternalIcon) {
      externalLink = <ExternalLink className={style.ExternalLink} height="14px" />;
    }

    return (
      <a href={url} className={className} target="_blank" rel="noopener noreferrer" onClick={this.getOnClick()}>
        {icon}
        <span className={clsx(style.ExternalLinkBody, { [textClass]: Boolean(textClass) })}>
          {children}
          {externalLink}
        </span>
      </a>
    );
  }

  renderInternalLink() {
    const {
      children,
      className,
      icon,
      textClass,
      url,
    } = this.props;

    return (
      <Link to={url} className={className} onClick={this.getOnClick()}>
        {icon}
        <span className={textClass}>
          {children}
        </span>
      </Link>
    );
  }

  render() {
    const { url } = this.props;

    if (!isExternal(url)) {
      return this.renderInternalLink();
    }

    return this.renderExternalLink();
  }
}

export default DynamicLink;
