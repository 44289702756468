import { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';
import { Message } from '@oneflowab/pomes';

import agreementsReducer from 'reducers/entities/agreements';
import { setRestoreToastList, removeIdInRestoreToastList } from 'reducers/app';

import ActionsMenu from 'components/actions-menu-v2/actions-menu';
import ActionsDotsIcon from 'components/icons/actions-dots';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { RestoreMenuItem } from 'components/menu-items/restore';
import toast from 'components/toasts';
import { checkAcl } from 'components/acl';

import ToastButton from 'components/toasts/button';
import { isUserOnGlobalTrashPage } from 'utils/isOnPage';
import style from './actions.module.scss';

type Props = {
  document: Oneflow.Agreement,
  queryName: string,
  setIsDeleteModalOpen: (isOpen: boolean) => void,
  setDocumentData: (documentData: Oneflow.Agreement | null) => void,
}

const ActionsDots = ({ isActive }: { isActive: boolean }) => (
  <div className={clsx(style.Actions, { [style.Active]: isActive })}>
    <ActionsDotsIcon height="14px" />
  </div>
);

export const Actions = ({
  document, queryName, setIsDeleteModalOpen, setDocumentData,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const isAllowedToPermanentDelete = checkAcl(document.acl, 'agreement:permanently_delete');
  const isAllowedToRestore = checkAcl(document.acl, 'agreement:restore');

  const restoreDocument = () => {
    dispatch(agreementsReducer.restoreTrashedAgreement({
      id: document.id,
      pipe: {
        action: () => agreementsReducer.queryAgreementsReload({
          name: queryName,
        }),
      },
    }));

    const toastId = `restored-trashed-document-${document.id}`;

    toast.success({
      id: toastId,
      title: <Message
        id="Document restored"
        comment="Title for the info message when the user has saved the document."
      />,
      action: (
        <ToastButton
          onClick={() => {
            let path = location.pathname.replace('/trash', '/all?');

            if (document.folder) {
              path = location.pathname.replace('/trash', `/all?folderIds%5B%5D=${document.folder.id}&`);
            }

            if (isUserOnGlobalTrashPage(location.pathname) && document.collection) {
              path = location.pathname.replace('/search/trash', `/c/${document.collection.id}/contracts/all?`);

              if (document.folder) {
                path = location.pathname.replace('/search/trash', `/c/${document.collection.id}/contracts/all?folderIds%5B%5D=${document.folder.id}&`);
              }
            }

            history.push(`${path}q=${document.id}`);

            toast.remove({ toastId });
            dispatch(removeIdInRestoreToastList(toastId));
          }}
        >
          <Message
            id="Show location"
            comment="Button that will take you to the origin location of the deleted document"
          />
        </ToastButton>
      ),
    });
    dispatch(setRestoreToastList(toastId));
  };

  const permanentDeleteDocument = () => {
    setIsDeleteModalOpen(true);
    setDocumentData(document);
  };

  return (
    <ActionsMenu
      customIcon={<ActionsDots isActive={isDropDownOpen} />}
      setVisibility={setIsDropDownOpen}
      actions={[
        <RestoreMenuItem
          key="restore"
          onClick={restoreDocument}
          disabled={!isAllowedToRestore}
        />,
        <DeleteMenuItem
          key="delete"
          onClick={permanentDeleteDocument}
          disabled={!isAllowedToPermanentDelete}
        />,
      ]}
    />
  );
};
