// @flow

import { type Dispatch } from 'redux';
import { connect } from 'react-redux';

import extensionsReducer from 'reducers/entities/extensions';

import { Authentication, type AuthenticationProps } from './salesforce-authentication';

export const QUERY_NAME = '/admin/account/extensions';

type MSProps = {|
  instanceUrl: string | void,
  oauthState: string | void,
  revokeState: RpcState,
|};

type MDProps = {|
  refreshOauth: () => void,
|};

type OwnProps = {|
  ...$Diff<$Exact<AuthenticationProps>, {|
    ...MSProps,
    ...MDProps
  |}>,
  extension: Extension,
|};

type MapStateToProps = (State, OwnProps) => MSProps;
export const mapStateToProps: MapStateToProps = (state, { extension }) => ({
  instanceUrl: extension.config.instanceUrl,
  oauthState: extension.oauthState,
  revokeState: extensionsReducer.getRevokeConnectionSelector(state, {
    id: extension.key,
  }),
});

type MapDispatchToProps = (Dispatch<*>, OwnProps) => MDProps
export const mapDispatchToProps: MapDispatchToProps = (dispatch, { extension }) => ({
  refreshOauth: () => {
    dispatch(extensionsReducer.refreshOauth({
      id: extension.key,
    }));
  },
});

const connectedAuthentication = connect<AuthenticationProps, OwnProps, MSProps, MDProps, State, _>(
  mapStateToProps,
  mapDispatchToProps,
)(Authentication);

export { connectedAuthentication as Authentication };
