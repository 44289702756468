import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import { isBoxDataCreateAllowed } from 'agreement/box-data-create-permissions';
import useCurrentBox from 'hooks/use-current-box';
import useCurrentBoxId from 'hooks/use-current-box-id';
import { updateBoxAction } from 'reducers/current-contract';
import type { ProductSumBox as BoxType } from 'data-validators/entity-schemas/document-box/product-sum-box';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import {
  generateProductSumBoxData,
} from 'components/contract-boxes/product-sum-box/product-sum-box-helpers';

import BoxAddRowButton from 'components/box-add-row-button';
import BoxWrapper from 'components/contract-boxes/box-wrapper';
import { MenuItem } from 'components/menu-item';
import NewAdd from 'components/icons/new-add';
import EmptyProductSumBox from 'components/contract-boxes/product-sum-box/empty-product-sum-box';
import ProductSumRow from 'components/contract-boxes/product-sum-box/product-sum-row';

import style from './product-sum-box.module.scss';

type Props = {
  boxId: number,
  isEditable: boolean,
  agreementId: number,
  onRemoveBox: () => void,
  onAddSectionRules: () => void,
};

export const ProductSumBox = ({
  boxId,
  isEditable,
  agreementId,
  onRemoveBox,
  onAddSectionRules,
}: Props) => {
  const dispatch = useDispatch();
  const currentBoxId = useCurrentBoxId(boxId);
  const box = useCurrentBox<BoxType>(currentBoxId) as BoxType;
  const isAllowedToAddBoxData = isBoxDataCreateAllowed(box);
  const summations = get(box, 'content.data')?.filter((d) => !d._removed) || [];
  const [openRowId, setOpenRowId] = useState<null | number>(null);

  const addNewRow = () => {
    const { updatedBox, newDataItem } = generateProductSumBoxData(box);
    dispatch(updateBoxAction(updatedBox));
    setOpenRowId(newDataItem._id);
  };

  const renderSummations = () => {
    if (isEmpty(summations)) {
      return (
        <EmptyProductSumBox
          agreementId={agreementId}
          isAllowedToEdit={isAllowedToAddBoxData}
          onClick={addNewRow}
        />
      );
    }

    return summations.map((summation) => (
      <ProductSumRow
        key={getId(summation)}
        summation={summation}
        box={box}
        openRowId={openRowId}
        setOpenRowId={setOpenRowId}
      />
    ));
  };

  const renderAddRowButton = () => {
    if (!isAllowedToAddBoxData) {
      return null;
    }
    return (
      <div className={style.AddRowButtonContainer}>
        <BoxAddRowButton onClick={addNewRow} />
      </div>
    );
  };

  const addSummationAction = (
    <MenuItem
      icon={NewAdd}
      label={(
        <Message
          id="Add product summation"
          comment="Action to create a new product summation."
        />
      )}
      onClick={addNewRow}
      disabled={!isAllowedToAddBoxData}
    />
  );

  if (!box) {
    return null;
  }

  return (
    <BoxWrapper
      customClasses={clsx({
        [style.ProductSumBoxContainer]: !isEmpty(summations),
      })}
      boxId={currentBoxId}
      isAllowedToEdit={isEditable}
      onRemoveBox={onRemoveBox}
      onAddSectionRules={onAddSectionRules}
      nonFixedActions={[addSummationAction]}
    >
      <div className={style.ProductSummation}>
        {renderSummations()}
        {renderAddRowButton()}
      </div>
    </BoxWrapper>
  );
};
