// @flow

import { getAgreementViewablePositions } from 'oneflow-client/agreements';

const getRecipientsWithAccessInfo = async (formData: any) => {
  const contractId = formData.contractFieldOption.contract.id;

  const toRecipients = formData.recipients.map((recipient) => recipient.id);

  let validRecipients = { collection: formData.recipients };
  try {
    validRecipients = await getAgreementViewablePositions({
      contractId,
      positionIds: toRecipients,
    });
  } catch {
    // There is possiblity that limited user or normal user don't have access
    // To this endpoint (403)
    // Update: No need to worry about this scenario.
    // In create modal the normal user/limited users will get the contracts that they has access to
    // As soon as they loose access, the event becomes read-only where we don't use this endpint
  }

  const validRecipientIds = validRecipients.collection.map((recipient) => recipient.id);
  const recipientsWithAccessInfo = formData.recipients.map(
    (recipient) => ({
      ...recipient,
      needsAccess: !validRecipientIds.includes(recipient.id),
    }),
  );

  return recipientsWithAccessInfo;
};

export default getRecipientsWithAccessInfo;
