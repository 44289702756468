/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import map from 'lodash/map';

export const FIVE_MINS_IN_SECONDS = 5 * 60;
export const YEAR_IN_SECONDS = 60 * 60 * 24 * 365;

export const durationUnits = (message) => ({
  s: message({
    id: 'seconds',
    comment: 'Duration unit value',
  }),
  i: message({
    id: 'minutes',
    comment: 'Duration unit value',
  }),
  h: message({
    id: 'hours',
    comment: 'Duration unit value',
  }),
  d: message({
    id: 'days',
    comment: 'Duration unit value',
  }),
  w: message({
    id: 'weeks',
    comment: 'Duration unit value',
  }),
  m: message({
    id: 'months',
    comment: 'Duration unit value',
  }),
  y: message({
    id: 'years',
    comment: 'Duration unit value',
  }),
});

export const getDurationUnitsAsOptions = ({ message }) => (
  map(durationUnits(message), (provider, key) => ({
    label: provider,
    value: key,
  }))
);

export const getDurationScalar = (value) => value?.slice(0, -1);
export const getDurationUnit = (value) => value?.slice(-1);

export const parseValueToSeconds = (value, unit) => {
  const scalarUnit = unit.value || unit;
  switch (scalarUnit) {
    case 's': return value;
    case 'i': return value * 60;
    case 'h': return value * 60 * 60;
    case 'd': return value * 60 * 60 * 24;
    case 'w': return value * 60 * 60 * 24 * 7;
    case 'm': return value * 60 * 60 * 24 * 30;
    case 'y': return value * 60 * 60 * 24 * 365;
    default:
      return console.log('Wrong unit:', scalarUnit);
  }
};
