import {
  forwardRef,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import type { KeyboardEvent } from 'react';

import { StoreContext, Store, State } from './contexts/store';
import { FilterableContext } from './contexts/filterable';
import useLazyRef from './hooks/use-lazy-ref';
import useAsRef from './hooks/use-as-ref';
import useScheduleLayoutEffect from './hooks/use-schedule-layout-effect';
import { commandScore } from './command-score';
import {
  GROUP_HEADING_SELECTOR,
  GROUP_ITEMS_SELECTOR,
  GROUP_SELECTOR,
  ITEM_SELECTOR,
  SELECT_EVENT,
  VALID_ITEM_SELECTOR,
  VALUE_ATTR,
} from './constants';
import { SlottableWithNestedChildren, findNextSibling, findPreviousSibling } from './helpers';
import style from './root.module.scss';

import type { FilterableContextValue } from './contexts/filterable';
import type { Children, DivProps } from './types';

type Props = Children &
  DivProps & {
    listId: string;
    inputId: string;
    labelId: string;
    /**
     * Accessible label for this command menu. Not shown visibly.
     */
    label?: string;
    /**
     * Optionally set to `false` to turn off the automatic filtering and sorting.
     * If `false`, you must conditionally render valid items based on the search query yourself.
     */
    shouldFilter?: boolean;
    /**
     * Custom filter function for whether each command menu item matches the given search query.
     * It should return a number between 0 and 1, with 1 being the best match and 0 being
     * hidden entirely.
     * By default, uses the `command-score` library.
     */
    filter?: (value: string, search: string, keywords?: string[]) => number;
    /**
     * Optional default item value when it is initially rendered.
     */
    defaultValue?: string;
    /**
     * Optional controlled state of the selected command menu item.
     */
    value?: string;
    /**
     * Event handler called when the selected item of the menu changes.
     */
    onValueChange?: (value: string) => void;
    /**
     * Optionally set to `true` to turn on looping around when using the arrow keys.
     */
    loop?: boolean
    /**
     * Optionally set to `true` to disable selection via pointer events.
     */
    disablePointerSelection?: boolean
    /**
     * Set to `false` to disable ctrl+n/j/p/k shortcuts. Defaults to `true`.
     */
    vimBindings?: boolean
  }

const defaultFilter: Props['filter'] = (value, search, keywords) => commandScore(value, search, keywords as any);

const Root = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const state = useLazyRef<State>(() => ({
    /** Value of the search query. */
    search: '',
    /** Currently selected item value. */
    value: props.value ?? props.defaultValue ?? '',
    filtered: {
      /** The count of all visible items. */
      count: 0,
      /** Map from visible item id to its search score. */
      items: new Map(),
      /** Set of groups with at least one visible item. */
      groups: new Set(),
    },
  }));
  const allItems = useLazyRef<Set<string>>(() => new Set()); // [...itemIds]
  const allGroups = useLazyRef<Map<string, Set<string>>>(() => new Map()); // groupId → [...itemIds]
  const ids = useLazyRef<Map<string, { value: string; keywords?: string[] }>>(
    () => new Map(),
  ); // id → { value, keywords }
  const listeners = useLazyRef<Set<() => void>>(() => new Set()); // [...rerenders]
  const propsRef = useAsRef(props);
  const {
    listId,
    labelId,
    inputId,
    label,
    children,
    value,
    onValueChange,
    filter,
    shouldFilter,
    loop,
    disablePointerSelection = false,
    vimBindings = true,
    ...etc
  } = props;

  const listInnerRef = useRef<HTMLDivElement>(null);

  const schedule = useScheduleLayoutEffect();

  /** Getters */

  const getSelectedItem = () => (
    listInnerRef.current?.querySelector(`${ITEM_SELECTOR}[aria-selected="true"]`)
  );

  const getValidItems = () => (
    Array.from(listInnerRef.current?.querySelectorAll(VALID_ITEM_SELECTOR) ?? [])
  );

  function scrollSelectedIntoView() {
    const item = getSelectedItem();

    if (item) {
      if (item.parentElement?.firstChild === item) {
        // First item in Group, ensure heading is in view
        item.closest(GROUP_SELECTOR)?.querySelector(GROUP_HEADING_SELECTOR)?.scrollIntoView({ block: 'nearest' });
      }

      // Ensure the item is always in view
      item.scrollIntoView({ block: 'nearest' });
    }
  }

  const score = (_value: string, keywords?: string[]) => {
    const _filter = propsRef.current?.filter ?? defaultFilter;
    return _value ? _filter?.(_value, state.current.search, keywords) : 0;
  };

  /** Filters the current items. */
  function filterItems() {
    if (
      !state.current.search
      // Explicitly false, because true | undefined is the default
      || propsRef.current.shouldFilter === false
    ) {
      state.current.filtered.count = allItems.current.size;
      // Do nothing, each item will know to show itself because search is empty
      return;
    }

    // Reset the groups
    state.current.filtered.groups = new Set();
    let itemCount = 0;

    // Check which items should be included
    // eslint-disable-next-line no-restricted-syntax
    for (const id of allItems.current) {
      const _value = ids.current.get(id)?.value ?? '';
      const keywords = ids.current.get(id)?.keywords ?? [];
      const rank = score(_value, keywords);
      state.current.filtered.items.set(id, rank);
      if (rank > 0) itemCount += 1;
    }

    // Check which groups have at least 1 item shown
    // eslint-disable-next-line no-restricted-syntax
    for (const [groupId, group] of allGroups.current) {
      // eslint-disable-next-line no-restricted-syntax
      for (const itemId of group) {
        if ((state.current.filtered.items.get(itemId) || 0) > 0) {
          state.current.filtered.groups.add(groupId);
          break;
        }
      }
    }

    state.current.filtered.count = itemCount;
  }

  /** Sorts items by score, and groups by highest item score. */
  const sort = () => {
    if (
      !state.current.search
      // Explicitly false, because true | undefined is the default
      || propsRef.current.shouldFilter === false
    ) {
      return;
    }

    const scores = state.current.filtered.items;

    // Sort the groups
    const groups: [string, number][] = [];
    state.current.filtered.groups.forEach((_value) => {
      const items = allGroups.current.get(_value);

      // Get the maximum score of the group's items
      let max = 0;
      items?.forEach((item) => {
        const _score = scores.get(item) as number;
        max = Math.max(_score, max);
      });

      groups.push([_value, max]);
    });

    // Sort items within groups to bottom
    // Sort items outside of groups
    // Sort groups to bottom (pushes all non-grouped items to the top)
    const listInsertionElement = listInnerRef.current;

    // Sort the items
    getValidItems()
      .sort((a, b) => {
        const valueA = a.getAttribute('id') as string;
        const valueB = b.getAttribute('id') as string;
        return (scores.get(valueB) ?? 0) - (scores.get(valueA) ?? 0);
      })
      .forEach((item) => {
        const group = item.closest(GROUP_ITEMS_SELECTOR);

        if (group) {
          group.appendChild(item.parentElement === group ? item : item.closest(`${GROUP_ITEMS_SELECTOR} > *`)!);
        } else {
          listInsertionElement?.appendChild(
            item.parentElement === listInsertionElement ? item : item.closest(`${GROUP_ITEMS_SELECTOR} > *`)!,
          );
        }
      });

    groups
      .sort((a, b) => b[1] - a[1])
      .forEach((group) => {
        const element = listInnerRef.current?.querySelector(
          `${GROUP_SELECTOR}[${VALUE_ATTR}="${encodeURIComponent(group[0])}"]`,
        );
        element?.parentElement?.appendChild(element);
      });
  };

  const store: Store = useMemo(() => ({
    subscribe: (cb) => {
      listeners.current.add(cb);
      return () => listeners.current.delete(cb);
    },
    snapshot: () => state.current,
    setState: (key, _value, opts) => {
      if (Object.is(state.current[key], _value)) return;
      state.current[key] = _value;

      if (key === 'search') {
        // Filter synchronously before emitting back to children
        filterItems();
        sort();
        // eslint-disable-next-line no-use-before-define
        schedule(1, selectFirstItem);
      } else if (key === 'value') {
        // opts is a boolean referring to whether it should NOT be scrolled into view
        if (!opts) {
          // Scroll the selected item into view
          schedule(5, scrollSelectedIntoView);
        }
        if (propsRef.current?.value !== undefined) {
          // If controlled, just call the callback instead of updating state internally
          const newValue = (_value ?? '') as string;
          propsRef.current.onValueChange?.(newValue);
          return;
        }
      }

      // Notify subscribers that state has changed
      store.emit();
    },
    emit: () => {
      listeners.current.forEach((l) => l());
    },
  }), []);

  // We have to use function since we are using this in store before it is defined
  // otherwise we'll get not defined error
  // And we can't move this to the top since we are using store in it
  function selectFirstItem() {
    const item = getValidItems().find((validItem) => validItem.getAttribute('aria-disabled') !== 'true');
    const _value = item?.getAttribute(VALUE_ATTR);
    store.setState('value', _value || null);
  }

  /** Controlled mode `value` handling. */
  useLayoutEffect(() => {
    if (value !== undefined) {
      const v = value.trim();
      state.current.value = v;
      store.emit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useLayoutEffect(() => {
    schedule(6, scrollSelectedIntoView);
    // to scroll selected item into view on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context: FilterableContextValue = useMemo(
    () => ({
      // Keep id → {value, keywords} mapping up-to-date
      value: (id, _value, keywords) => {
        if (_value !== ids.current.get(id)?.value) {
          ids.current.set(id, { value: _value, keywords });
          state.current.filtered.items.set(id, score(_value, keywords));
          schedule(2, () => {
            sort();
            store.emit();
          });
        }
      },
      // Track item lifecycle (mount, unmount)
      item: (id, groupId) => {
        allItems.current.add(id);

        // Track this item within the group
        if (groupId) {
          if (!allGroups.current.has(groupId)) {
            allGroups.current.set(groupId, new Set([id]));
          } else {
            allGroups.current.get(groupId)?.add(id);
          }
        }

        // Batch this, multiple items can mount in one pass
        // and we should not be filtering/sorting/emitting each time
        schedule(3, () => {
          filterItems();
          sort();

          // Could be initial mount, select the first item if none already selected
          if (!state.current.value) {
            selectFirstItem();
          }

          store.emit();
        });

        return () => {
          ids.current.delete(id);
          allItems.current.delete(id);
          state.current.filtered.items.delete(id);
          const selectedItem = getSelectedItem();

          // Batch this, multiple items could be removed in one pass
          schedule(4, () => {
            filterItems();

            // The item removed have been the selected one,
            // so selection should be moved to the first
            if (selectedItem?.getAttribute('id') === id) selectFirstItem();

            store.emit();
          });
        };
      },
      // Track group lifecycle (mount, unmount)
      group: (id) => {
        if (!allGroups.current.has(id)) {
          allGroups.current.set(id, new Set());
        }

        return () => {
          ids.current.delete(id);
          allGroups.current.delete(id);
        };
      },
      // don't wrap this with a boolean, we need to know if it's explicitly set to false
      filter: () => propsRef.current.shouldFilter,
      label: label || props['aria-label'] || '',
      disablePointerSelection,
      listId,
      inputId,
      labelId,
      listInnerRef,
    }),
    [],
  );

  /** Setters */

  const updateSelectedToIndex = (index: number) => {
    const items = getValidItems();
    const item = items[index];
    if (item) store.setState('value', item.getAttribute(VALUE_ATTR));
  };

  const updateSelectedByItem = (change: 1 | -1) => {
    const selected = getSelectedItem();
    const items = getValidItems();
    const index = items.findIndex((item) => item === selected);

    // Get item at this index
    let newSelected = items[index + change];

    if (propsRef.current?.loop) {
      if (index + change < 0) {
        newSelected = items[items.length - 1];
      }
      if (index + change === items.length) {
        // eslint-disable-next-line prefer-destructuring
        newSelected = items[0];
      }
      newSelected = items[index + change];
    }

    if (newSelected) store.setState('value', newSelected.getAttribute(VALUE_ATTR));
  };

  function updateSelectedByGroup(change: 1 | -1) {
    const selected = getSelectedItem();
    let group = selected?.closest(GROUP_SELECTOR);
    let item: HTMLElement | null | undefined = null;

    while (group && !item) {
      group = change > 0
        ? findNextSibling(group, GROUP_SELECTOR)
        : findPreviousSibling(group, GROUP_SELECTOR);
      item = group?.querySelector(VALID_ITEM_SELECTOR);
    }

    if (item) {
      store.setState('value', item.getAttribute(VALUE_ATTR));
    } else {
      updateSelectedByItem(change);
    }
  }

  const last = () => updateSelectedToIndex(getValidItems().length - 1);

  const next = (e: KeyboardEvent) => {
    e.preventDefault();

    if (e.metaKey) {
      // Last item
      last();
    } else if (e.altKey) {
      // Next group
      updateSelectedByGroup(1);
    } else {
      // Next item
      updateSelectedByItem(1);
    }
  };

  const prev = (e: KeyboardEvent) => {
    e.preventDefault();

    if (e.metaKey) {
      // First item
      updateSelectedToIndex(0);
    } else if (e.altKey) {
      // Previous group
      updateSelectedByGroup(-1);
    } else {
      // Previous item
      updateSelectedByItem(-1);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={forwardedRef}
      tabIndex={-1}
      {...etc}
      // eslint-disable-next-line react/no-unknown-property
      cmdk-root=""
      onKeyDown={(e) => {
        etc.onKeyDown?.(e);

        if (!e.defaultPrevented) {
          // eslint-disable-next-line default-case
          switch (e.key) {
            case 'n':
            case 'j': {
              // vim keybind down
              if (vimBindings && e.ctrlKey) {
                next(e);
              }
              break;
            }
            case 'ArrowDown': {
              next(e);
              break;
            }
            case 'p':
            case 'k': {
              // vim keybind up
              if (vimBindings && e.ctrlKey) {
                prev(e);
              }
              break;
            }
            case 'ArrowUp': {
              prev(e);
              break;
            }
            case 'Home': {
              // First item
              e.preventDefault();
              updateSelectedToIndex(0);
              break;
            }
            case 'End': {
              // Last item
              e.preventDefault();
              last();
              break;
            }
            case 'Enter': {
              // Check if IME composition is finished before triggering onSelect
              // This prevents unwanted triggering while user is still inputting text with IME
              // e.keyCode === 229 is for the Japanese IME and Safari.
              // isComposing does not work with Japanese IME and Safari combination.
              if (!e.nativeEvent.isComposing && e.keyCode !== 229) {
                // Trigger item onSelect
                e.preventDefault();
                const item = getSelectedItem();
                if (item) {
                  const event = new Event(SELECT_EVENT);
                  item.dispatchEvent(event);
                }
              }
            }
          }
        }
      }}
    >
      <label
        // eslint-disable-next-line react/no-unknown-property
        cmdk-label=""
        htmlFor={context.inputId}
        id={context.labelId}
        // Screen reader only
        className={style.Label}
      >
        {label}
      </label>
      {SlottableWithNestedChildren(props, (child) => (
        <StoreContext.Provider value={store}>
          <FilterableContext.Provider value={context}>{child}</FilterableContext.Provider>
        </StoreContext.Provider>
      ))}
    </div>
  );
});

Root.displayName = 'Filterable.Root';

export default Root;
