import { Message } from '@oneflowab/pomes';

type Props = {
  signedDate: string,
  terminatedDate: string
};

const SignedAndTerminated = ({ signedDate, terminatedDate }: Props) => (
  <span>
    <Message
      id="Document was signed on {signedDate} and terminated on {terminatedDate}."
      values={{
        signedDate,
        terminatedDate,
      }}
      comment="Text for a signed and terminated contract. Visible in contract."
    />
  </span>
);

export default SignedAndTerminated;
