// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import positionMessageTemplates from 'reducers/entities/position-message-templates';
import { EditMessageTemplate } from './edit-message-template';

import type { MessageTemplateData, Props } from './edit-message-template';

type StateProps = {|
  updateState: UpdateState,
|};

type DispatchProps = {|
  updateMessageTemplate: MessageTemplateData => void,
  resetUpdateState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  messageTemplate: MessageTemplate,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { messageTemplate }) => ({
  updateState: positionMessageTemplates.getUpdateSelector(state, { id: messageTemplate.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, ownProps) => ({
  resetUpdateState: () => {
    dispatch(positionMessageTemplates.updatePositionMessageTemplateReset({
      id: ownProps.messageTemplate.id,
    }));
  },
  updateMessageTemplate: (formData) => {
    dispatch(positionMessageTemplates.updatePositionMessageTemplate({
      id: ownProps.messageTemplate.id,
      data: {
        ...formData,
        type: formData.type.value,
        body: formData.body || null,
        subject: formData.subject || null,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(EditMessageTemplate);
