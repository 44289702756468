// @flow

import { decamelizeKeys } from 'humps';

import { post, extractResponseBodyAsJSON } from 'oneflow-client/core';

type AuthorizeWithDynamics = ({ dynamicsInstanceUrl: string }) => Promise<{}>

// eslint-disable-next-line import/prefer-default-export
export const authorizeWithDynamics: AuthorizeWithDynamics = ({ dynamicsInstanceUrl }) => (
  post({
    url: '/ext/dynamics/oauth_proxy',
    body: decamelizeKeys({
      dynamicsInstanceUrl,
    }, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
);
