import clsx from 'clsx';
import { forwardRef } from 'react';
import { Message } from '@oneflowab/pomes';

import ChevronRightIcon from 'components/icons/chevron-right';

import Menu from '../components/menu';

import style from '../ai-assist-menu.module.scss';
import { AIAssistantPrompts } from '../../constants';

type Props = {
  onSelect: (prompt: string) => void;
}

const SelectionMenuDefault = forwardRef<HTMLDivElement, Props>(({ onSelect }, ref) => (
  <Menu.List className={style.List} ref={ref}>
    <div className={style.Group}>
      <div className={style.GroupHeading} data-testid="group-heading">
        <Message
          id="Edit or review selection"
          comment="Section header in dropdown that describes the actions to edit or review the selected text."
        />
      </div>
      <Menu.Item onSelect={() => onSelect(AIAssistantPrompts.IMPROVE_WRITING)} id="improve-writing" className={style.Item}>
        <Message
          id="Improve writing"
          comment="An option in dropdown describing the action to improve the writing of the selected text."
        />
        <kbd>&#8629;</kbd>
      </Menu.Item>
      <Menu.Item onSelect={() => onSelect(AIAssistantPrompts.MAKE_SHORTER)} id="make-shorter" className={style.Item}>
        <Message
          id="Make shorter"
          comment="An option in dropdown describing the action to make the selected text shorter."
        />
        <kbd>&#8629;</kbd>
      </Menu.Item>
      <Menu.Item onSelect={() => onSelect(AIAssistantPrompts.MAKE_LONGER)} id="make-longer" className={style.Item}>
        <Message
          id="Make longer"
          comment="An option in dropdown describing the action to make the selected text longer."
        />
        <kbd>&#8629;</kbd>
      </Menu.Item>
      <Menu.SubRoot id="change-tone">
        <Menu.SubProvider>
          <Menu.SubTrigger id="change-tone" className={`${style.Item} lets`}>
            <Message
              id="Change tone"
              comment="An option in dropdown describing the action to change the tone of the selected text."
            />
            <ChevronRightIcon className={style.Icon} width="9px" />
          </Menu.SubTrigger>
          <Menu.SubContent id="change-tone">
            <Menu.SubList className={clsx(style.List, style.SubList)}>
              <div className={style.Group}>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_PROFESSIONAL)} id="professional" className={style.Item}>
                  <Message
                    id="Professional"
                    comment="An option in dropdown describing the action to change the tone of the selected text to professional."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_CASUAL)} id="casual" className={style.Item}>
                  <Message
                    id="Casual"
                    comment="An option in dropdown describing the action to change the tone of the selected text to casual."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_STRAIGHT_FORWARD)} id="straight-forward" className={style.Item}>
                  <Message
                    id="StraightForward"
                    comment="An option in dropdown describing the action to change the tone of the selected text to straight forward."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_CONFIDENT)} id="confident" className={style.Item}>
                  <Message
                    id="Confident"
                    comment="An option in dropdown describing the action to change the tone of the selected text to confident."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.CHANGE_TONE_FRIENDLY)} id="friendly" className={style.Item}>
                  <Message
                    id="Friendly"
                    comment="An option in dropdown describing the action to change the tone of the selected text to friendly."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
              </div>
            </Menu.SubList>
          </Menu.SubContent>
        </Menu.SubProvider>
      </Menu.SubRoot>
      <Menu.Item onSelect={() => onSelect(AIAssistantPrompts.SIMPLIFY_TEXT)} id="simplify" className={style.Item}>
        <Message
          id="Simplify"
          comment="An option in dropdown describing the action to simplify the selected text."
        />
        <kbd>&#8629;</kbd>
      </Menu.Item>
    </div>
    <div className={style.Separator} />
    <div className={style.Group}>
      <div className={style.GroupHeading}>
        <Message
          id="Generate from selection"
          comment="A dropdown section header that describes the actions to generate text from the selected text."
        />
      </div>
      <Menu.Item onSelect={() => onSelect(AIAssistantPrompts.SUMMARIZE_TEXT)} id="summarize" className={style.Item}>
        <Message
          id="Summarize"
          comment="An option in dropdown describing the action to summarize the selected text."
        />
        <kbd>&#8629;</kbd>
      </Menu.Item>
      <Menu.SubRoot id="translate">
        <Menu.SubProvider>
          <Menu.SubTrigger id="translate" className={`${style.Item} lets`}>
            <Message
              id="Translate"
              comment="An option in dropdown describing the action to translate the selected text."
            />
            <ChevronRightIcon className={style.Icon} width="9px" />
          </Menu.SubTrigger>
          <Menu.SubContent id="translate">
            <Menu.SubList className={clsx(style.List, style.SubList)}>
              <div className={style.Group}>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_SWEDISH)} id="translate-to-swedish" className={style.Item}>
                  <Message
                    id="Swedish"
                    comment="An option in dropdown describing the action to translate the selected text to Swedish."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_ENGLISH)} id="translate-to-english" className={style.Item}>
                  <Message
                    id="English"
                    comment="An option in dropdown describing the action to translate the selected text to English."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_NORWEGIAN)} id="translate-to-norwegian" className={style.Item}>
                  <Message
                    id="Norwegian"
                    comment="An option in dropdown describing the action to translate the selected text to Norwegian."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_DANISH)} id="translate-to-danish" className={style.Item}>
                  <Message
                    id="Danish"
                    comment="An option in dropdown describing the action to translate the selected text to Danish."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_FINNISH)} id="translate-to-finnish" className={style.Item}>
                  <Message
                    id="Finnish"
                    comment="An option in dropdown describing the action to translate the selected text to Finnish."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_GERMAN)} id="translate-to-german" className={style.Item}>
                  <Message
                    id="German"
                    comment="An option in dropdown describing the action to translate the selected text to German."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_SPANISH)} id="translate-to-spanish" className={style.Item}>
                  <Message
                    id="Spanish"
                    comment="An option in dropdown describing the action to translate the selected text to Spanish."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_FRENCH)} id="translate-to-french" className={style.Item}>
                  <Message
                    id="French"
                    comment="An option in dropdown describing the action to translate the selected text to French."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_DUTCH)} id="translate-to-dutch" className={style.Item}>
                  <Message
                    id="Dutch"
                    comment="An option in dropdown describing the action to translate the selected text to Dutch."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_PORTUGUESE)} id="translate-to-portuguese" className={style.Item}>
                  <Message
                    id="Portuguese"
                    comment="An option in dropdown describing the action to translate the selected text to Portuguese."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
                <Menu.SubItem onSelect={() => onSelect(AIAssistantPrompts.TRANSLATE_TO_ITALIAN)} id="translate-to-italian" className={style.Item}>
                  <Message
                    id="Italian"
                    comment="An option in dropdown describing the action to translate the selected text to Italian."
                  />
                  <kbd>&#8629;</kbd>
                </Menu.SubItem>
              </div>
            </Menu.SubList>
          </Menu.SubContent>
        </Menu.SubProvider>
      </Menu.SubRoot>
    </div>
  </Menu.List>
));

SelectionMenuDefault.displayName = 'SelectionMenuDefault';

export default SelectionMenuDefault;
