import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { AGREEMENT_WITH_VIDEO } from 'agreement/constants';
import { getAgreementMyParticipant, isAgreementOwner } from 'agreement/selectors';
import { getAccountFromSessionSelector } from 'reducers/session';

type HookReturnType = {
  isVideoModalOpen: boolean;
  shouldMountVideoPreview: boolean;
  handleModalClose: () => void;
};

const useVideoPreview = (agreement: Oneflow.Document): HookReturnType => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(true);
  const myParticipant = getAgreementMyParticipant(agreement);
  const accountFromSession = useSelector(getAccountFromSessionSelector);
  const isOwner = isAgreementOwner(accountFromSession, agreement);

  const handleModalClose = () => {
    setIsVideoModalOpen(false);
  };

  const shouldMountVideoPreview = useMemo(() => (
    agreement.welcomeVideo === AGREEMENT_WITH_VIDEO
    && myParticipant
    && !isOwner
    && myParticipant.visits === 0
  ), [agreement.welcomeVideo, isOwner, myParticipant]) || false;

  return {
    isVideoModalOpen,
    shouldMountVideoPreview,
    handleModalClose,
  };
};

export default useVideoPreview;
