// @flow

import { connect } from 'react-redux';

import { getPositionFromSessionSelector } from 'reducers/session';

import ExportUsers from './export-users';
import type { Props } from './export-users';

type StateProps = {|
  email: string,
|};

export type OwnProps = $Diff<Props, StateProps>;

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state: State) => ({
  email: getPositionFromSessionSelector(state).email,
});

type DispatchProps = $Exact<{}>;

type MapDispatchToProps = () => DispatchProps;
export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, OwnProps, StateProps, $Exact<DispatchProps>, State, _>(
  mapStateToProps,
  mapDispatchToProps,
)(ExportUsers);
