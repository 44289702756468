import { some } from 'lodash';
import { useSelector } from 'react-redux';

import { getCurrentBoxes } from 'reducers/current-contract';
import { BOX_TYPES } from 'agreement/constants';

import usePremiumBoxesDisabled from 'components/box-menu/hooks/use-premium-box-disabled';

import type { Box } from 'data-validators/entity-schemas/document-box';

const isDurationBox = (box: Box): boolean => (
  box.type === BOX_TYPES.BOX_DURATION && !box._removed
);

export const useBoxTypeIsDisabled = (boxType: ContractView.BoxType): boolean => {
  const boxes = useSelector(getCurrentBoxes);
  const isFremiumBoxesDisabled = usePremiumBoxesDisabled();
  const isPDFBoxType = boxType === BOX_TYPES.BOX_PDF;
  const isDurationBoxType = boxType === BOX_TYPES.BOX_DURATION;

  if (isPDFBoxType) {
    return false;
  }

  if (isFremiumBoxesDisabled) {
    return true;
  }

  if (isDurationBoxType) {
    return some(boxes, isDurationBox);
  }

  return false;
};
