// eslint-disable-next-line import/named
import { CopyMenuItem } from 'components/menu-items/copy';
import { checkAcl } from 'components/acl';
import CopyContractModal from 'components/modals/copy-contract';
import { isTemplate } from 'agreement/states';

export type Props = {
  workspace: Oneflow.Workspace,
  agreement: Oneflow.Agreement,
  disabled?: boolean,
  amplitudeScope?: string,
};

const CopyContract = ({
  workspace,
  agreement,
  disabled,
  amplitudeScope,
}: Props) => {
  let aclName = 'collection:agreement:create';

  if (isTemplate(agreement)) {
    aclName = 'collection:agreement:template:create';
  }

  return (
    <CopyContractModal agreement={agreement} amplitudeScope={amplitudeScope}>
      {(onClick) => (
        <CopyMenuItem
          // TODO: add acle to workspace schema, remove comment if acl is already added
          disabled={!checkAcl(workspace.acl, aclName) || Boolean(disabled)}
          onClick={onClick}
        />
      )}
    </CopyContractModal>
  );
};

CopyContract.defaultProps = {
  disabled: undefined,
};

export default CopyContract;
