// @flow

import { Transforms } from 'slate';

const removeTable = (editor: any) => {
  Transforms.removeNodes(editor, {
    match: (n) => n.type === 'table',
    mode: 'lowest',
  });
};

export default removeTable;
