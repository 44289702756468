import { put, call } from 'redux-saga/effects';

import { keyBy } from 'lodash';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  grantGroupAccountAccess,
  getGroupAccountBindings,
  removeGroupAccountAccess,
} from 'oneflow-client/group-accounts';

import apiWrapper from 'sagas/api-wrapper';

import groupAccountsReducer from 'reducers/entities/group-accounts';

export function* mapper({ data }) {
  yield put(groupAccountsReducer.setGroupAccounts(keyBy(data.entities.groupAccountBindings, 'id')));
}

export function* grantGroupAccountAccessMapper({ data }) {
  yield put(groupAccountsReducer.setGroupAccounts(keyBy(data.entities.groupAccountBindings, 'id')));
}

export function* removeGroupAccountAccessMapper({ action }) {
  yield put(groupAccountsReducer.clearGroupAccount({ id: action.id }));
}

export function* updateMapper({ action }) {
  yield put(groupAccountsReducer.clearGroupAccount({ id: action.data.removeData.id }));
}

export function* updateRequest({ removeData, createData }) {
  yield call(grantGroupAccountAccess, createData);
  yield call(removeGroupAccountAccess, removeData);
}

const mappers = {
  query: {
    mapper,
    request: getGroupAccountBindings,
  },
  update: {
    mapper: updateMapper,
    request: updateRequest,
  },
  rpcs: {
    grantGroupAccountAccess: {
      name: 'grantGroupAccountAccess',
      mapper: grantGroupAccountAccessMapper,
      request: grantGroupAccountAccess,
    },
    removeGroupAccountAccess: {
      name: 'removeGroupAccountAccess',
      mapper: removeGroupAccountAccessMapper,
      request: removeGroupAccountAccess,
    },
  },
};

const groupAccountsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: groupAccountsReducer,
  mappers,
});

export default groupAccountsSagas;
