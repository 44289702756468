// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import get from 'lodash/get';
import workspacesReducer, { getAllMoveToWorkspaces } from 'reducers/entities/workspaces';
import { isContractPristine } from 'reducers/current-contract';
import { allowContractMove } from 'workspace/permissions';
import TopbarWorkspace, { type Props } from './topbar-workspace';

type StateProps = {|
  canPerformAction: boolean,
|};

type DispatchProps = {|

|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  agreement: Agreement,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (
  state: State,
  { agreement }: OwnProps,
) => {
  const workspace = workspacesReducer.getWorkspaceSelector(state, {
    id: get(agreement, 'collection.id'),
  });
  const availableWorkspaces = getAllMoveToWorkspaces(state, workspace.id);
  const isPristine = isContractPristine(state);
  const canPerformAction = () => {
    if (!isPristine) {
      return false;
    }

    return allowContractMove(workspace) && Boolean(availableWorkspaces.length);
  };
  return ({
    canPerformAction: canPerformAction(),
  });
};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = () => ({});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(TopbarWorkspace);
