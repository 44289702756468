// @flow

import * as React from 'react';
import clsx from 'clsx';
import { localize, type MessageTranslator, Message } from '@oneflowab/pomes';

import { WithCurrentWorkspaceLink } from 'hocs/with-current-workspace';
import { SYSTEM_TAG_ID } from 'tags/constants';

import Button from 'components/button';
import Cross from 'components/icons/cross';
import { getTagName } from 'components/tags/tag-name';

import style from './tag.module.scss';

type Props = {
  tagConnection: TagConnection,
  handleOverflow: boolean,
  allowTagChange: boolean,
  allowSearch?: boolean,
  message: MessageTranslator,
  withCurrentWorkspaceNamespace: (path: string) => string,
  removeTagConnection: () => void,
  onClick?: () => void,
  searchGlobally?: boolean,
  renderAsNativeAnchorElement?: boolean,
};

type CurrentWorkspaceProps = Props;
type WithCurrentWorkspaceNamespace = (path: string) => string;
type NamespaceProps = {
  withCurrentWorkspaceNamespace: WithCurrentWorkspaceNamespace,
};

export class TagComponent extends React.Component<Props> {
  static defaultProps = {
    onClick: undefined,
  };

  getTagSearchLink() {
    const {
      withCurrentWorkspaceNamespace,
      tagConnection,
      searchGlobally,
    } = this.props;

    if (searchGlobally) {
      return `/search/all?tags%5B%5D=${tagConnection.tag.id}`;
    }

    return withCurrentWorkspaceNamespace(`/contracts/all?tags%5B%5D=${tagConnection.tag.id}`);
  }

  renderTagName() {
    const {
      tagConnection,
      allowSearch,
      renderAsNativeAnchorElement,
      onClick,
      message,
      allowTagChange,
    } = this.props;

    const tagNameClasses = clsx(
      style.TagName, {
        [style.IsNotClickable]: !onClick && !allowSearch,
        [style.IsNotRemovable]: !allowTagChange,
      },
    );

    const nativeAnchorButtonProps = {
      external: true,
      target: '_blank',
    };

    if (!allowSearch) {
      return (
        <Button onClick={onClick} customClass={tagNameClasses}>
          {getTagName(tagConnection.tag, message)}
        </Button>
      );
    }

    return (
      <div className={style.Link}>
        <Button
          onClick={onClick}
          href={this.getTagSearchLink()}
          customClass={tagNameClasses}
          {...(renderAsNativeAnchorElement ? nativeAnchorButtonProps : {})}
        >
          {getTagName(tagConnection.tag, message)}
        </Button>
      </div>
    );
  }

  renderTagRemoval() {
    const { allowTagChange, removeTagConnection } = this.props;

    if (allowTagChange) {
      return (
        <Button
          customClass={style.RemoveTag}
          onClick={removeTagConnection}
          icon={this.renderRemovalIcon}
        />
      );
    }
    return null;
  }

  renderRemovalIcon = () => <Cross height="8px" />;

  render() {
    const {
      message,
      tagConnection,
      handleOverflow,
    } = this.props;

    const tagClasses = clsx(style.Tag, {
      [style.System]: tagConnection.tag.system === SYSTEM_TAG_ID,
      [style.WithOverflow]: handleOverflow,
    });

    return (
      <div
        title={getTagName(tagConnection.tag, message)}
        className={tagClasses}
      >
        {this.renderTagRemoval()}
        {this.renderTagName()}
      </div>
    );
  }
}

export const TagWithNamespace = (props: Props) => (namespaceProps: NamespaceProps) => (
  <TagComponent
    {...props}
    withCurrentWorkspaceNamespace={namespaceProps.withCurrentWorkspaceNamespace}
  />
);

export const TagWithCurrentWorkspace = (props: CurrentWorkspaceProps) => (
  <WithCurrentWorkspaceLink>
    {TagWithNamespace(props)}
  </WithCurrentWorkspaceLink>
);

export const TagWrapper = (props: Props) => {
  if (props.searchGlobally) {
    return (
      <TagComponent {...props} withCurrentWorkspaceNamespace={(path) => path} />
    );
  }
  return (
    <TagWithCurrentWorkspace {...props} />
  );
};

export const StaticSystemImportLabel = () => {
  const tagClasses = clsx(
    style.Tag,
    style.System,
    style.WithOverflow,
    style.LabelSpace,
  );

  const tagNameClasses = clsx(
    style.TagName,
    style.IsNotClickable,
    style.IsNotRemovable,
  );

  return (
    <div
      title="import"
      className={tagClasses}
    >
      <div className={style.TagLabel}>
        <Button
          customClass={tagNameClasses}
        >
          <Message
            id="Import"
            comment="Name for import tag label"
          />
        </Button>
      </div>
    </div>
  );
};

export default localize<Props>(TagWrapper);
