import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import { get, last, uniqueId } from 'lodash';

import { getEnabledParticipants } from 'agreement/selectors';
import { hasAttachmentSignatures, isMarkedAsSigned } from 'agreement';
import { IS_INDIVIDUAL } from 'agreement/party/constants';
// eslint-disable-next-line import/named
import { sortByType } from 'agreement/participant';

import { InfoBox } from 'components/error-box';
import Check from 'components/icons/check';

import { SignOrderParticipants } from './sign-order-participants/sign-order-participants';
import style from './sign-contract-confirm-body.module.scss';

export type Props = {
  agreement: Agreement,
  attachmentSignaturesCheckbox: React.ReactNode,
  counterpartyNames: Array<string>,
  isSoleSignatory?: boolean,
  lastToSign?: boolean,
  participant: AgreementParticipant,
  party: AgreementParty
};

class SignContractBody extends React.Component<Props> {
  static defaultProps = {
    lastToSign: false,
    isSoleSignatory: false,
  };

  getAttachment = (attachment: AgreementAttachment) => (
    <li key={attachment.name}>
      <span className={style.AttachmentName}>
        {attachment.name}
      </span>
      <span>
        <span className={style.AttachmentExtension}>
          {attachment.extension}
        </span>
        {this.getNumberOfPages(attachment)}
      </span>
    </li>
  );

  getNumberOfPages = (attachment: AgreementAttachment) => {
    if (!attachment.pages) {
      return null;
    }

    return (
      <>
        (
        <Message
          id="{count} page"
          pluralId="{count} pages"
          pluralCondition="count"
          comment="Sign contract confirmation, number of pages per attachment"
          values={{
            count: attachment.pages,
          }}
        />
        )
      </>
    );
  };

  renderCounterpartyNames() {
    const { counterpartyNames } = this.props;

    if (!counterpartyNames.length) {
      return null;
    }

    const mapToCounterpartyName = (name: string) => (
      <span key={uniqueId()} className={style.CounterpartyName}>
        {name}
      </span>
    );
    const joinByComma = (acc: null | Array<React.ReactElement<React.ComponentProps<'span'>>> | Array<React.ReactElement<React.ComponentProps<'span'>> | string>, curr: React.ReactElement<React.ComponentProps<'span'>>) => (acc === null ? [curr] : [...acc, ', ', curr]);
    const commaSeparatedNames = counterpartyNames
      .slice(0, -1)
      .map(mapToCounterpartyName)
      .reduce(joinByComma, null);

    return (
      <p className={style.CounterpartyNames}>
        <Message
          id="Sign with {name}."
          pluralId="Sign with {commaSeparatedNames} and {name}."
          pluralCondition="counterpartyCount"
          values={{
            commaSeparatedNames,
            name: <strong>{last(counterpartyNames)}</strong>,
            counterpartyCount: counterpartyNames.length,
          }}
          comment="This text is LEGALESE! Shown in the contract sign confirmation modal along with the contract title which is a combination of contract counterparties."
        />
      </p>
    );
  }

  renderDisclaimerMessage() {
    const { lastToSign, isSoleSignatory, agreement } = this.props;
    const isSingleSignEnabled = agreement.config?.singleSign;

    let disclaimerMessage = (
      <Message
        id="The document can be modified until all signatories have signed. If modified, you will need to sign again."
        comment="This text is LEGALESE! Shown in the contract sign confirmation modal as a description of the process and legal disclaimer."
      />
    );

    if (lastToSign) {
      disclaimerMessage = (
        <Message
          id="Once you sign this document, you won’t be able to modify it."
          comment="This text is LEGALESE! Shown in the contract sign confirmation modal as a description of the process and legal disclaimer."
        />
      );
    }

    if (isSoleSignatory || isSingleSignEnabled) {
      disclaimerMessage = (
        <Message
          id="Once you sign this document, you won’t be able to modify it."
          comment="This text is LEGALESE! Shown in the contract sign confirmation modal as a description of the process and legal disclaimer."
        />
      );
    }

    return (
      <div className={style.DisclaimerMessage}>
        {disclaimerMessage}
      </div>
    );
  }

  renderAttachmentSignatureList() {
    const { agreement, agreement: { attachmentSignatures } } = this.props;

    if (!hasAttachmentSignatures(agreement)) {
      return null;
    }

    return (
      <div className={style.AttachmentSignatureList}>
        <ul>
          {attachmentSignatures ? attachmentSignatures.map(this.getAttachment) : null}
        </ul>
      </div>
    );
  }

  renderParticipantTitle() {
    const { participant } = this.props;

    if (!participant.title) {
      return '';
    }

    return <strong>{`(${participant.title})`}</strong>;
  }

  renderContentForIndividual() {
    const { party } = this.props;

    if (party.orgnr) {
      return (
        <li className={style.LegalItem}>
          <Check />
          <Message
            id="You are {name} with date of birth {ssn}"
            comment="Sign contract confirmation, user authorization information"
            values={{
              name: <strong>{party.name}</strong>,
              ssn: this.renderOrgnr(),
            }}
          />
        </li>
      );
    }

    return (
      <li className={style.LegalItem}>
        <Check />
        <Message
          id="You are {name}"
          comment="Sign contract confirmation, user authorization information"
          values={{
            name: <strong>{party.name}</strong>,
          }}
        />
      </li>
    );
  }

  renderPartyRelatedContent() {
    const { party, participant } = this.props;
    const isIndividual = get(party, 'individual') === IS_INDIVIDUAL;

    if (isIndividual) {
      return this.renderContentForIndividual();
    }

    return (
      <>
        <li className={style.LegalItem}>
          <Check />
          <Message
            id="You are {participantName} {title} from {partyName} {orgnr}"
            comment="Sign contract confirmation, participant information"
            values={{
              participantName: <strong>{participant.fullname}</strong>,
              title: this.renderParticipantTitle(),
              partyName: <strong>{party.name}</strong>,
              orgnr: this.renderOrgnr(),
            }}
          />
        </li>
        <li className={style.LegalItem}>
          <Check />
          <Message
            id="You are authorized to sign on behalf of {partyName}"
            comment="Sign contract confirmation, user authorization information"
            values={{
              partyName: <strong>{party.name}</strong>,
            }}
          />
        </li>
      </>
    );
  }

  renderAttachmentSignaturesCheckbox() {
    const {
      agreement,
      attachmentSignaturesCheckbox,
    } = this.props;

    if (!hasAttachmentSignatures(agreement)) {
      return null;
    }

    return attachmentSignaturesCheckbox;
  }

  renderOrgnr() {
    const { party } = this.props;

    if (!party.orgnr) {
      return '';
    }

    return <strong>{`(${party.orgnr})`}</strong>;
  }

  renderParticipantsBubbles() {
    const { agreement } = this.props;

    return (
      <SignOrderParticipants
        agreement={agreement}
        participants={sortByType(getEnabledParticipants(agreement))}
        isContractMarkedAsSigned={isMarkedAsSigned(agreement)}
        disableTooltip
        sortBySignedOrder
        disableViewCount
      />
    );
  }

  render() {
    return (
      <div className={style.SignContractBody}>
        {this.renderParticipantsBubbles()}
        {this.renderDisclaimerMessage()}
        <div className={style.InfoBox}>
          <InfoBox
            bodyText={(
              <ul className={style.LegalInformtion}>
                {this.renderPartyRelatedContent()}
                <li className={style.LegalItem}>
                  <Check />
                  <Message
                    id="You have read and understood this document and intend to sign it."
                    comment="Sign contract confirmation, confirming that the user understands the terms of the contract and has the intention to sign"
                  />
                </li>
              </ul>
            )}
          />
        </div>
        {this.renderAttachmentSignatureList()}
        {this.renderAttachmentSignaturesCheckbox()}
      </div>
    );
  }
}

export default SignContractBody;
