import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { isFreemium } from 'account';
// eslint-disable-next-line import/named
import { BuySeatsModal } from 'components/modals/buy-seats';
// eslint-disable-next-line import/named
import { FreemiumSelfService } from 'components/modals/freemium-self-service';
import { checkAcl } from 'components/acl';
import Message from 'components/message/message';
import Button from 'components/button';
import LicenseIcon from 'components/icons/license';

import type { Billing } from 'types/billing';

type Props = {
  account: Oneflow.Account,
  accountSeats?: Oneflow.AccountSeats,
  redirectToBilling?: boolean,
  isAutoOpen?: boolean,
  billing?: Billing,
};

const BuySeatsButton = ({
  accountSeats,
  account,
  redirectToBilling = false,
  isAutoOpen = false,
  billing,
}: Props) => {
  const history = useHistory();
  const hasPermission = checkAcl(account.acl, 'account:order:create');

  const getRedirectButton = () => {
    const seatUtilization = accountSeats ? (accountSeats.taken / accountSeats.total) * 100 : 0;

    return (
      <Button
        data-testid="buy-seats"
        kind="secondary"
        onClick={() => history.push('/admin/billing', { openBuySeatsModal: true })}
        trackable={{
          name: 'Go To Upgrade Seats',
          props: {
            'health score seat utilization': `${seatUtilization}%`,
          },
        }}
        disabled={!hasPermission}
      >
        <Message
          id="Buy seats"
          comment="Button for buying seats (redirects to billing)"
        />
      </Button>
    );
  };

  const getUpgradeAccountButton = (onClick: () => void) => (
    <Button
      data-testid="upgrade-account"
      kind="special"
      icon={LicenseIcon}
      outline
      onClick={onClick}
      trackable="Go To Purchase Plans"
      disabled={!hasPermission}
    >
      <Message
        id="Upgrade account"
        comment="Text for button that opens modal to become a customer in the users list."
      />
    </Button>
  );

  if (!accountSeats?.selfService) {
    return null;
  }

  if (account.trial || isFreemium(account)) {
    return (
      <FreemiumSelfService>
        {getUpgradeAccountButton}
      </FreemiumSelfService>
    );
  }

  if (isEmpty(accountSeats) || account.isDemo) {
    return null;
  }

  if (!hasPermission) {
    return null;
  }

  if (redirectToBilling) {
    return getRedirectButton();
  }

  if (!billing) {
    return null;
  }

  return (
    <BuySeatsModal
      accountId={account.id}
      billing={billing}
      currentSeats={accountSeats}
      isAutoOpen={isAutoOpen}
      redirectToBilling={redirectToBilling}
    />
  );
};

export default BuySeatsButton;
