// @flow

import { put, select } from 'redux-saga/effects';
import type { SelectEffect, PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';

import {
  getWorkspaceMessageTemplates,
  createWorkspaceMessageTemplate,
  updateWorkspaceMessageTemplate,
  deleteWorkspaceMessageTemplates,
} from 'oneflow-client/message-templates';
import type { NormalizedMessageTemplates } from 'oneflow-client/message-templates';

import apiWrapper from 'sagas/api-wrapper';

import workspaceMessageTemplatesReducer from 'reducers/entities/workspace-message-templates';
import { getCurrentWorkspaceSelector } from 'reducers/app';

type ExtraArguments = {
  workspaceId: number,
}

type ExtraQueryParams = {
  params: ExtraArguments,
}

type SetWorkspaceId = Generator<SelectEffect<any, any>, ExtraQueryParams, any>;

export function* setCurrentWorkspace(): SetWorkspaceId {
  const workspace = yield select(getCurrentWorkspaceSelector);

  return {
    params: {
      workspaceId: workspace.id,
    },
  };
}

type MapperArgs = {
  data: NormalizedMessageTemplates,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  const { messageTemplates } = data.entities;

  yield put(workspaceMessageTemplatesReducer.setWorkspaceMessageTemplates(messageTemplates));
}

export function* removeMapper({ action }: MapperArgs): Mapper {
  yield put(workspaceMessageTemplatesReducer.clearWorkspaceMessageTemplate({
    ids: action.data.ids,
  }));
}

const mappers = {
  query: {
    prepare: setCurrentWorkspace,
    mapper,
    request: getWorkspaceMessageTemplates,
  },
  create: {
    mapper,
    request: createWorkspaceMessageTemplate,
  },
  update: {
    mapper,
    request: updateWorkspaceMessageTemplate,
  },
  remove: {
    mapper: removeMapper,
    request: deleteWorkspaceMessageTemplates,
  },
};

const workspaceMessageTemplatesSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: workspaceMessageTemplatesReducer,
  mappers,
});

export default workspaceMessageTemplatesSagas;
