import Message from 'components/message';
import { unknownApiError } from 'components/api-error';

export const ERROR_404 = {
  header: {
    primary: (
      <Message
        id="Oh no!"
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
    secondary: (
      <Message
        id="The document is not here"
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
  },
  body: {
    primary: (
      <Message
        id="The page may no longer exist, or the link you've clicked has an invalid URL."
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
    secondary: (
      <Message
        id="Please try another document or contact us for assistance."
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
    tertiary: (
      <Message
        id="Let's get back to flow!"
        comment="Text showing next to button that redirects back to the main page"
      />
    ),
  },
};

export const ERROR_404_GUEST = {
  header: ERROR_404.header,
  body: {
    ...ERROR_404.body,
    secondary: (
      <Message
        id="Please double-check the URL or contact the document owner."
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
  },
};

export const ERROR_403 = {
  header: {
    primary: (
      <Message
        id="Sorry!"
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
    secondary: (
      <Message
        id="Access denied"
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
  },
  body: {
    primary: (
      <Message
        id="It seems you're not authorised to view this document."
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
    secondary: (
      <Message
        id="Please try another document or contact us for assistance."
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
    tertiary: (
      <Message
        id="Let's get back to flow!"
        comment="Text showing next to button that redirects back to the main page"
      />
    ),
  },
};

export const ERROR_LOCKED = {
  header: {
    primary: (
      <Message
        id="It should be flawless, but it's not..."
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
  },
  body: {
    primary: (
      <Message
        id="Due to an internal error, the document has been locked."
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
    secondary: (
      <Message
        id="Please contact us on {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Text showing on error page when the document cannot be accessed"
      />
    ),
  },
};

export const ERROR_502 = {
  header: {
    primary: (
      <Message
        id="Oops..."
        comment="Text to show error when there is problem for accessing contract in auto login"
      />
    ),
    secondary: (
      <Message
        id="Login failed"
        comment="Text to show error when there is problem for accessing contract in auto login"
      />
    ),
  },
  body: {
    primary: (
      <Message
        id="Please check your network connection and try again."
        comment="Text to show error when there is problem for accessing contract in auto login"
      />
    ),
    secondary: (
      <>
        <Message
          id="If the problem persists, please contact us at"
          comment="Text to show error when there is problem for accessing contract in auto login"
        />
        &nbsp;
        <a
          href="mailto:support@oneflow.com"
        >
          support@oneflow.com.
        </a>
      </>
    ),
  },
};

export const ERROR_UNKNOWN = {
  header: {
    primary: unknownApiError.headerText,
  },
  body: {
    primary: unknownApiError.bodyText,
    secondary: (
      <>
        <Message
          id="If the problem persists, please contact us at"
          comment="Text to show error when there is problem for accessing contract in auto login"
        />
        &nbsp;
        <a
          href="mailto:support@oneflow.com"
        >
          support@oneflow.com.
        </a>
      </>
    ),
  },
};
