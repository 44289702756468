/* eslint-disable react/display-name */

import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { Link } from 'react-router-dom';

import { USER_ACCOUNT_ROLE_ADMIN_ID } from 'user';
import AdministratorIcon from 'components/icons/administrator';
import { checkAcl } from 'components/acl';
import ActionsMenu from 'components/actions-menu';
// eslint-disable-next-line import/named
import { Table } from 'components/table';
import EditUserAccountAccess from 'components/modals/edit-user-account-access';
import RemoveUserAccountAccess from 'components/modals/remove-user-account-access';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { renderPermissionCount, renderRoleName } from 'routes/admin/user/account-access/helpers';
import { ChangeRoleMenuItem } from 'components/menu-items/change-role';
import { BadgeInactive } from 'components/badges/badge-inactive';

import { Query } from './account-access';
import styles from './account-access.module.scss';

type Props = {
  account: Oneflow.Account,
  query: Query,
  children: ReactNode,
  items: Oneflow.AccountUserRoleBinding[],
  message: MessageTranslator,
  hasGrantSystemRolePermission: boolean,
  hasGrantCustomRolePermission: boolean,
};

type OnClick = () => void;

const DirectAccessTable = ({
  account,
  query,
  children,
  items,
  message,
  hasGrantSystemRolePermission,
  hasGrantCustomRolePermission,
}: Props) => {
  const hasRemoveSystemRolePermission = useMemo(
    () => checkAcl(account.acl, 'account:position:system_role_binding:remove'),
    [account.acl],
  );
  const hasRemoveCustomRolePermission = useMemo(
    () => checkAcl(account.acl, 'account:position:custom_role_binding:remove'),
    [account.acl],
  );

  const getEditMenuItem = () => (onClick: OnClick): JSX.Element | null => {
    const hasEditSystemRolePermission = hasRemoveSystemRolePermission
      && hasGrantSystemRolePermission;
    const hasEditCustomRolePermission = hasRemoveCustomRolePermission
      && hasGrantCustomRolePermission;

    // Since below enterprise accounts have only one account role edit option will be hidden.
    if (hasGrantSystemRolePermission && hasGrantCustomRolePermission) {
      const EditMenuItemComponent = (
        <ChangeRoleMenuItem
          onClick={onClick}
          disabled={!hasEditSystemRolePermission && !hasEditCustomRolePermission}
        />
      );

      return EditMenuItemComponent;
    }

    return null;
  };

  const getRemoveMenuItem = () => (onClick: OnClick): JSX.Element => {
    const RemoveMenuItem = (
      <DeleteMenuItem
        onClick={onClick}
        disabled={!hasRemoveSystemRolePermission && !hasRemoveCustomRolePermission}
      />
    );

    return RemoveMenuItem;
  };

  const getActionsForUserAccess = (item: Oneflow.AccountUserRoleBinding) => (
    <ActionsMenu
      actions={[
        <EditUserAccountAccess
          key={`edit-${item.id}`}
          position={item.position}
          binding={item}
          renderedFromAccountAccessPage
        >
          {getEditMenuItem()}
        </EditUserAccountAccess>,
        <RemoveUserAccountAccess
          key={`remove-${item.id}`}
          position={item.position}
          binding={item}
          renderedFromAccountAccessPage
        >
          {getRemoveMenuItem()}
        </RemoveUserAccountAccess>,
      ]}
      focusOnCloseDisabled
    />
  );

  const getPositionName = (binding: Oneflow.AccountUserRoleBinding) => (
    <>
      <Link to={`/admin/users/${binding.position.id}`} className={styles.Name}>
        {binding.position.fullname}
      </Link>
      {(binding.role.id === USER_ACCOUNT_ROLE_ADMIN_ID)
        && <AdministratorIcon className={styles.AdministratorIcon} />}
      {(!binding.position.active) && <BadgeInactive />}
    </>
  );

  const getDirectAccessTableConfig = () => {
    const actions: JSX.Element[] = [];
    const columns = [
      {
        name: 'username',
        label: message({
          id: 'User name',
          comment: 'Column header for the account access list.',
        }),
        type: 'cell',
        value: getPositionName,
      },
      {
        name: 'account role',
        label: message({
          id: 'Account role',
          comment: 'Column header for the account access list in the user pages.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountUserRoleBinding) => (
          <Link to={`/admin/roles/${item.role.id}`} className={styles.AccountRole}>
            {renderRoleName(item.role)}
          </Link>
        ),
      },
      {
        name: 'actions',
        label: message({ id: 'Actions', comment: 'Column label in users page' }),
        type: 'actions',
        value: (item: Oneflow.AccountUserRoleBinding) => getActionsForUserAccess(item),
      },
    ];

    if (hasGrantSystemRolePermission && hasGrantCustomRolePermission) {
      const permissionsColumn = {
        name: 'permissions',
        label: message({
          id: 'Permissions',
          comment: 'Column header for the account access list in the user pages.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountUserRoleBinding) => <p>{renderPermissionCount(item.role)}</p>,
      };

      columns.splice(columns.length - 1, 0, permissionsColumn);
    }

    return {
      items,
      itemKey: 'id',
      actions,
      columns,
    };
  };

  return (
    <>
      <div className={styles.ContentHeader}>
        <h2 className={styles.Header}>
          <Message id="Direct access" comment="Title, above the table." />
        </h2>
        <p>
          <Message
            id="Account role assigned directly to the user."
            comment="Help text for the account access list in the user pages."
          />
        </p>
      </div>
      <Table config={getDirectAccessTableConfig()} query={query} />
      {children}
    </>
  );
};

export default DirectAccessTable;
