import { useDispatch } from 'react-redux';
import agreementsReducer from 'reducers/entities/agreements';
import { Message } from '@oneflowab/pomes';
import Button from 'components/button';
import Confirmable from 'components/confirmable';
import { CancelButton } from 'components/buttons';
import WarningIcon from 'components/icons/warning';

import {
  isPartiallySigned,
} from 'agreement/selectors';
import toast from 'components/toasts';
import { willTriggerSignatureReset } from 'components/modals/edit-participant/utils';

import style from './removing-participant-from-document.module.scss';

const modalKey = 'removing-participant-from-document';

type Props = {
  setIsRemoveParticipantModalOpen: (isRemoveParticipantModalOpen: boolean) => void;
  isRemoveParticipantModalOpen: boolean;
  children: React.ReactNode;
  agreement: Oneflow.Agreement;
  participant: Oneflow.Participant;
  guestToken: string;
  handleSignatureResetOnEdit: (onConfirm: () => void, action: string) => void;
  setRemoveParticipantConfirmation: (removeParticipantConfirmation: boolean) => void;
};

export const RemovingParticipantFromDocument = ({
  setIsRemoveParticipantModalOpen,
  guestToken,
  isRemoveParticipantModalOpen,
  children,
  agreement,
  participant,
  handleSignatureResetOnEdit,
  setRemoveParticipantConfirmation,
}: Props) => {
  const dispatch = useDispatch();

  const onClose = () => {
    setRemoveParticipantConfirmation(false);
    setIsRemoveParticipantModalOpen(false);
  };

  const handleOpen = () => {
    setIsRemoveParticipantModalOpen(true);
  };

  const renderBody = () => (
    <div className={style.BodyContainer}>
      <WarningIcon className={style.WarningIcon} />
      <p className={style.BodyText}>
        <b>
          <Message
            id="If you remove this participant, the last approvers won't be able to approve the document."
            comment="Text explaining that removing a participant may cause the document to not be approvable by an approver."
          />
        </b>
        {' '}
        <Message
          id="To avoid this, place at least one signatory after the last approver."
          comment="Text explaining that removing a participant may cause the document to not be approvable by an approver."
        />
      </p>
      <p className={style.BodyText}>
        <Message
          id="Remove participant?"
          comment="Text in the remove participant warning modal"
        />
      </p>
    </div>
  );

  const showSignatureResetToast = () => {
    const isAgreementPartiallySigned = isPartiallySigned(agreement);

    if (!isAgreementPartiallySigned) {
      return null;
    }

    return toast.warning({
      id: 'signatures-reset',
      title: <Message
        id="Signatures reset"
        comment="Title for the info message when the signatures have been reset."
      />,
      description: <Message
        id="The document needs to be signed again."
        comment="Description text for the info message when the signatures have been reset."
      />,
      duration: 5000,
    });
  };

  const checkForSignatureReset = (data) => {
    const { agreements } = data.entities;
    const newAgreement = agreements[data.result];

    const hasSignatureReset = isPartiallySigned(newAgreement) !== isPartiallySigned(agreement);

    if (hasSignatureReset) {
      showSignatureResetToast();
    }
  };

  const removeParticipant = (params) => dispatch(agreementsReducer.removeParticipant({
    id: participant.id,
    data: {
      ...params,
    },
    pipe: {
      onSuccess: checkForSignatureReset,
    },
  }));

  const handleRemoveParticipant = () => {
    removeParticipant({
      agreement: agreement.id,
      token: guestToken,
    });
  };

  const handleConfirm = () => {
    const onConfirmRemoveParticipant = () => {
      handleRemoveParticipant();
    };
    if (willTriggerSignatureReset({ formData: {}, agreement, participant })) {
      handleSignatureResetOnEdit(onConfirmRemoveParticipant, 'participantRemoved');
    } else {
      onConfirmRemoveParticipant();
    }

    setIsRemoveParticipantModalOpen(false);
  };

  const getActions = () => (
    <>
      <CancelButton onClick={onClose} modalKey={modalKey} />
      <Button
        color="yellow"
        onClick={handleConfirm}
        customClass={style.RemoveButton}
      >
        <Message
          id="Remove participant"
          comment="Button label for confirming removing a participant from the document"
        />
      </Button>
    </>
  );

  return (
    <Confirmable
      header={(
        <Message
          id="Removing participant"
          comment="Used as the title in modal when trying to remove a participant from the document"
        />
    )}
      body={renderBody()}
      actions={getActions}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      onClose={onClose}
      onOpen={handleOpen}
      isOpen={isRemoveParticipantModalOpen}
      modalKey={modalKey}
      customBodyClass={style.Body}
    >
      {children}
    </Confirmable>
  );
};
