import { useState } from 'react';
import {
  values, uniqueId, truncate,
} from 'lodash';
import { Message } from '@oneflowab/pomes';

import { getRef } from 'agreement/event';
// eslint-disable-next-line import/no-unresolved
import { AgreementEvent } from 'types/entities';

import {
  showMoreLabel,
  showLessLabel,
} from 'components/messages-layout/single-message/single-message-content/utils';

import style from './suggestion-accepted.module.scss';

const INITIAL_CONTENT_TRUNCATE_LENGTH = 100;
const CONTENT_TRUNCATE_LENGTH = 400;

const isContentTooLong = (content: string) => {
  if (!content) {
    return false;
  }

  return content.length > INITIAL_CONTENT_TRUNCATE_LENGTH;
};

type Props = {
  event: AgreementEvent,
};

const SuggestionAccepted = ({
  event,
}: Props) => {
  const acceptedSuggestions = getRef(event, 'content');

  const [
    originalContentCounters, setOriginalContentCounters,
  ] = useState<Record<string, number>>({});
  const [
    suggestedContentCounters, setSuggestedContentCounters,
  ] = useState<Record<string, number>>({});

  return values(acceptedSuggestions).map((suggestion, index) => {
    const { originalContent, suggestedContent } = suggestion;
    const isOriginalContentTooLong = isContentTooLong(originalContent);
    const isSuggestedContentTooLong = isContentTooLong(suggestedContent);

    const originalContentCounter = originalContentCounters[index] || 0;
    const suggestedContentCounter = suggestedContentCounters[index] || 0;

    const originalContentMaxCount = Math.ceil(
      originalContent.length / CONTENT_TRUNCATE_LENGTH,
    );

    const suggestedContentMaxCount = Math.ceil(
      suggestedContent.length / CONTENT_TRUNCATE_LENGTH,
    );

    const showOriginalContent = () => {
      if (originalContentCounter === originalContentMaxCount) {
        return originalContent;
      }

      if (originalContentCounter === 0) {
        return truncate(originalContent, {
          length: INITIAL_CONTENT_TRUNCATE_LENGTH,
        });
      }

      return truncate(originalContent, {
        length: CONTENT_TRUNCATE_LENGTH * originalContentCounter,
      });
    };

    const showSuggestedContent = () => {
      if (suggestedContentCounter === suggestedContentMaxCount) {
        return suggestedContent;
      }

      if (suggestedContentCounter === 0) {
        return truncate(suggestedContent, {
          length: INITIAL_CONTENT_TRUNCATE_LENGTH,
        });
      }

      return truncate(suggestedContent, {
        length: CONTENT_TRUNCATE_LENGTH * suggestedContentCounter,
      });
    };

    const renderShowMoreOriginalContent = () => (
      <button
        className={style.ExpandButton}
        onClick={(e) => {
          e.stopPropagation();
          setOriginalContentCounters({
            ...originalContentCounters,
            [index]: originalContentCounter === originalContentMaxCount
              ? 0
              : originalContentCounter + 1,
          });
        }}
      >
        {originalContentCounter === originalContentMaxCount ? showLessLabel : showMoreLabel}
      </button>
    );

    const renderShowMoreSuggestionContent = () => (
      <button
        className={style.ExpandButton}
        onClick={(e) => {
          e.stopPropagation();
          setSuggestedContentCounters({
            ...suggestedContentCounters,
            [index]: suggestedContentCounter === suggestedContentMaxCount
              ? 0
              : suggestedContentCounter + 1,
          });
        }}
      >
        {suggestedContentCounter === suggestedContentMaxCount ? showLessLabel : showMoreLabel}
      </button>
    );

    const renderOriginalContent = () => {
      if (isOriginalContentTooLong) {
        return (
          <span>
            {showOriginalContent()}
            <div className={style.ExpandButton}>
              {renderShowMoreOriginalContent()}
            </div>
          </span>
        );
      }

      return <span>{originalContent}</span>;
    };

    const renderSuggestedContent = () => {
      if (isSuggestedContentTooLong) {
        return (
          <span>
            {showSuggestedContent()}
            <div className={style.ExpandButton}>
              {renderShowMoreSuggestionContent()}
            </div>
          </span>
        );
      }

      return <span>{suggestedContent}</span>;
    };

    if (suggestedContent === '') {
      return (
        <div key={uniqueId()} className={style.SuggestionAccepted}>
          <span className={style.SemiBold}>
            <Message
              id="Delete:"
              comment="Audit trail message"
            />
          </span>
          <span>
            {' '}
            {renderOriginalContent()}
          </span>
        </div>
      );
    }

    return (
      <div key={uniqueId()}>
        <div className={style.SuggestionAccepted}>
          <div>
            <div className={style.TextContainer}>
              <span className={style.SemiBold}>
                <Message
                  id="Change:"
                  comment="Audit trail message"
                />
              </span>
              <span>
                {' '}
                {renderOriginalContent()}
              </span>
            </div>
            <div>
              <span className={style.SemiBold}>
                <Message
                  id="To:"
                  comment="Audit trail message"
                />
              </span>
              <span>
                {' '}
                {renderSuggestedContent()}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default SuggestionAccepted;
