// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';

import {
  getTags, createTag, deleteTags, updateTag,
} from 'oneflow-client/tags';
import type { NormalizedTags } from 'oneflow-client/tags';

import apiWrapper from 'sagas/api-wrapper';

import tags from 'reducers/entities/tags';

type MapperArgs = {
  data: NormalizedTags,
  action: any,
};

type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(tags.setTags(data.entities.tags));
}

export function* clearMapper({ action }: MapperArgs): Mapper {
  yield put(tags.clearTag({ ids: action.data.ids }));
}

const mappers = {
  query: {
    mapper,
    request: getTags,
  },
  create: {
    mapper,
    request: createTag,
  },
  update: {
    mapper,
    request: updateTag,
  },
  remove: {
    mapper: clearMapper,
    request: deleteTags,
  },
};

const tagsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: tags,
  mappers,
});

export default tagsSagas;
