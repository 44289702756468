// @flow

import {
  Editor,
  Element as SlateElement,
  Transforms,
} from 'slate';
import { isTextStyleElement } from 'components/rich-text-editor-toolbars/toolbar-buttons/text-styles/text-styles-plugin';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const getCurrentList = (editor: Editor) => { // 43,60
  const [...foundListElements] = Editor.nodes(editor, {
    match: (n) => (
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type,
      )
    ),
    mode: 'lowest',
  });

  if (foundListElements.length > 1) {
    return null;
  }

  return foundListElements[0];
};

export const getCurrentBlock = (editor: Editor) => {
  const match = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === 'paragraph' || LIST_TYPES.includes(n.type) || isTextStyleElement(n))
    ),
    mode: 'lowest',
  });

  return match;
};

export const indentActiveParagraph = (editor: Editor) => {
  const [...allBlocks] = getCurrentBlock(editor);

  if (allBlocks.length === 0) {
    return;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [block, path] of allBlocks) {
    const marginLeft = parseInt(block.marginLeft, 10);
    Transforms.setNodes(editor, {
      marginLeft: `${(marginLeft || 0) + 20}px`,
    }, {
      at: path,
    });
  }
};

export const shiftIndentActiveParagraph = (editor: Editor) => {
  const [...allBlocks] = getCurrentBlock(editor);

  if (allBlocks.length === 0) {
    return;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [block, path] of allBlocks) {
    const marginLeft = parseInt(block.marginLeft, 10);
    if (marginLeft) {
      Transforms.setNodes(editor, {
        marginLeft: `${Math.max(marginLeft - 20, 0)}px`,
      }, {
        at: path,
      });
    }
  }
};

export const insertIndentation = (editor: any) => {
  const list = getCurrentList(editor);

  if (list) {
    const [currentList] = list;
    if (currentList) {
      Transforms.wrapNodes(editor, {
        type: currentList.type,
      });
    }
    return;
  }

  indentActiveParagraph(editor);
};

export const insertShiftIndentation = (editor: any) => {
  const list = getCurrentList(editor);

  if (list) {
    const [currentList, path] = list;
    if (currentList) {
      const [parent] = Editor.parent(editor, path);
      if (!LIST_TYPES.includes(parent.type)) {
        return;
      }
      Transforms.unwrapNodes(editor, {
        match: (n) => n === currentList,
      });
    }
    return;
  }

  shiftIndentActiveParagraph(editor);
};
