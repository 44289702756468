// @flow
import React, {
  useState, useCallback, useRef, useMemo,
} from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Form, type FormRenderProps } from 'react-final-form';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import useCurrentBoxes from 'hooks/use-current-boxes';
import usePreviousUntilValuesChanged from 'hooks/use-previous-until-values-changed';
import agreements from 'reducers/entities/agreements';
import {
  getContractMetaData,
} from 'reducers/current-contract';
import { updateAgreementConfig } from 'oneflow-client/agreements';
import { getAgreementMyParticipant } from 'agreement/selectors';
import * as agreementUtils from 'agreement';
import { isViewer as isPartyViewer } from 'agreement/participant';
import {
  composeValidators,
  numberValidator,
  minValue,
  maxValue,
  required,
} from 'forms/validators';
import getDocumentPeriod from 'utils/get-document-period';
import useDocumentViewLayoutInfo from 'hooks/use-document-view-layout-info';
import usePopupDialog from 'hooks/popup/use-popup-dialog';
import { DEFAULT_NOTICE_PERIOD_REMINDER } from 'agreement/constants';
import { getPositionFromSessionSelector } from 'reducers/session';
import { areSettingsDisabled } from 'components/document-tabs/settings-tab/helpers';

import { CancelButton } from 'components/buttons';
import { NumberField } from 'components/text-field';
import PencilIcon from 'components/icons/pencil';
import Field from 'components/field';
import Button from 'components/button';
import { ApiError, getErrorMessage, unknownApiError } from 'components/api-error';
import { getSendReminderDate, getCurrentDurationData, isRemindMeVisible } from 'components/document-tabs/settings-tab/sections/duration-reminders/remind-me/helper';

import style from './remind-me.module.scss';

type Props = {
  agreementId: number,
  message: MessageTranslator,
}

const getPeriodText = (recurring) => {
  if (recurring) {
    return (
      <Message
        id="days before notice period"
        comment="Text describing when users will receive reminder for recurring documents."
      />
    );
  }

  return (
    <Message
      id="days before document period ends"
      comment="Text describing when users will be reminded of the end of a single period document"
    />
  );
};

const getHelperText = (formProps) => {
  if (formProps.hasValidationErrors) {
    return (
      <section id="remind-me-invalid" className={style.RemindMeInvalid}>
        {formProps.errors?.numberOfDays}
      </section>
    );
  }

  return (
    <section className={style.RemindMeHelperText}>
      <Message
        id="The reminder should be set within the duration period."
        comment="Helper text for reminder notification popover"
      />
    </section>

  );
};

const renderApiError = (error) => {
  if (!error) {
    return null;
  }

  let customMessage = unknownApiError;
  const errorCode = get(error, 'body.api_error_code');
  if (getErrorMessage(errorCode)) {
    customMessage = getErrorMessage(errorCode);
  }

  return (
    <ApiError customMessage={customMessage} />
  );
};

export const RemindMe = ({ agreementId, message }: Props) => {
  const dispatch = useDispatch();
  const initialAgreement = useAgreement(agreementId);
  const boxes = useCurrentBoxes();
  const { durationBoxId } = useSelector(getContractMetaData);
  const currentDurationData = usePreviousUntilValuesChanged(
    getCurrentDurationData(boxes, durationBoxId),
  );
  const { columnsCount } = useDocumentViewLayoutInfo();
  const isTwoColumnLayout = columnsCount === 2;
  const myParticipant = getAgreementMyParticipant(initialAgreement);
  const popupType = isTwoColumnLayout ? 'popover' : 'dialog';
  const { Popup, PopupContent } = usePopupDialog(popupType);
  const myPosition = useSelector(getPositionFromSessionSelector);
  const settingsDisabled = areSettingsDisabled(initialAgreement, myPosition);

  const initialNoticePeriodReminder = initialAgreement.config?.noticePeriodReminder
    || DEFAULT_NOTICE_PERIOD_REMINDER;
  const [error, setError] = useState(null);
  const signedAndActive = agreementUtils.isSignedAndActive(initialAgreement);
  const isAnySigned = agreementUtils.isAnySignedState(initialAgreement);
  const recurring = agreementUtils.isAnyRecurringType(currentDurationData);
  const documentPeriod = getDocumentPeriod(currentDurationData);
  const isViewer = myParticipant ? isPartyViewer(myParticipant) : false;
  const closeButtonRef = useRef(null);
  const closePopup = () => closeButtonRef.current?.click();

  const dateFormat = initialAgreement?.config?.dateFormat;

  const sendReminderDate = useMemo(() => getSendReminderDate(
    { agreement: initialAgreement, initialNoticePeriodReminder, dateFormat },
  ), [initialAgreement, dateFormat, initialNoticePeriodReminder]);

  const validations = useMemo(() => (
    composeValidators(
      numberValidator({
        message,
        field: 'numberOfDays',
        text: message({
          id: 'Please enter a whole number greater than 1.',
          comment: 'Validation message for contract duration reminder popover',
        }),
      }),
      minValue({
        message,
        field: 'numberOfDays',
        text: message({
          id: 'Please enter a whole number greater than 1.',
          comment: 'Validation message for contract duration reminder popover',
        }),
        limit: 1,
      }),
      maxValue({
        message,
        field: 'numberOfDays',
        text: message({
          id: 'The reminder should be set within the duration period.',
          comment: 'Helper text for reminder notification popover',
        }),
        limit: documentPeriod,
      }),
      required({
        message,
        field: 'numberOfDays',
        text: message({
          id: 'Please enter a whole number greater than 1.',
          comment: 'Validation message for contract duration reminder popover',
        }),
      }),
    )
  ), [documentPeriod, message]);

  const updateTheDate = useCallback(async (value) => {
    const configToUpdate = {
      notice_period_reminder: value,
    };
    setError(null);
    try {
      const updatedConfig = await updateAgreementConfig({
        id: agreementId,
        ...configToUpdate,
      });

      dispatch(agreements.setAgreements({
        [agreementId]: { config: updatedConfig },
      }));

      closePopup();
    } catch (e) {
      setError(e);
    }
  }, [agreementId, dispatch]);

  const onSubmit = ({ numberOfDays }) => {
    updateTheDate(numberOfDays);
  };

  if (!isRemindMeVisible(currentDurationData)) {
    return null;
  }

  const renderForm = (formProps: FormRenderProps<any>) => {
    const isDisabled = formProps.invalid;
    return (
      <form
        className={style.RemindMeForm}
        onSubmit={formProps.handleSubmit}
      >
        <div className={style.PopupHeader}>
          <Message id="Set duration reminder" comment="Title of remind me popover" />
        </div>
        <div className={style.RemindMeContainer}>
          <div className={style.RemindMeInputContainer}>
            <Field
              name="numberOfDays"
              validate={validations}
              component={NumberField}
              customClass={style.DaysField}
              hideLabel
              noErrorMessage
              aria-errormessage="remind-me-invalid"
              aria-labelledby="remindmelabel"
            />
            <span data-id="remindMeLabel">
              {getPeriodText(recurring)}
            </span>
          </div>
          {getHelperText(formProps)}
          {error && renderApiError(error)}
          <div className={style.ActionButtonsContainer}>
            <Popup.Close asChild>
              <CancelButton />
            </Popup.Close>
            <Button
              data-testid="save-button"
              type="submit"
              kind="primary"
              disabled={isDisabled}
            >
              <Message
                id="Save"
                comment="Action to save when the document period reminder should be set."
              />
            </Button>
            <Popup.Close asChild>
              {/* No translation needed and not part of accessibility */}
              <Button
                hidden
                customClass={style.HiddenButton}
                buttonRef={closeButtonRef}
                type="button"
              >
                Programmatic hidden close button
              </Button>
            </Popup.Close>
          </div>
        </div>
      </form>
    );
  };

  if (signedAndActive || isViewer || settingsDisabled) {
    return (
      <div className={style.Readonly} data-testid="Readonly">
        <h3 className={style.RemindMeHeader}>
          <Message id="Duration reminder" comment="Document period reminder" />
        </h3>
        <div className={style.RemindMeInfo}>
          <div className={style.Inactive}>{initialNoticePeriodReminder}</div>
          <span>{getPeriodText(recurring)}</span>
        </div>
        {isAnySigned && (
          <div className={style.ExpiryInfo} data-testid="expiry-info">
            <Message
              id="Reminder will be sent on {date}"
              values={{
                date: sendReminderDate,
              }}
              comment="Information about which date the reminder will be sent"
            />
          </div>
        )}
      </div>
    );
  }

  const renderPopupTrigger = () => (
    <div
      className={style.RemindMe}
      role="button"
      tabIndex={0}
    >
      <h3 className={style.RemindMeHeader}>
        <Message id="Duration reminder" comment="Document period reminder" />
      </h3>
      <div className={style.RemindMeInfo}>
        <div className={style.DaysCount}>
          {initialNoticePeriodReminder}
        </div>
        <span>{getPeriodText(recurring)}</span>
      </div>
      <PencilIcon className={style.EditIcon} width="16px" />
    </div>
  );

  return (
    <Popup.Root>
      <Popup.Trigger asChild>
        {renderPopupTrigger()}
      </Popup.Trigger>
      <PopupContent
        align="start"
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{ numberOfDays: initialNoticePeriodReminder }}
          render={renderForm}
        />
      </PopupContent>
    </Popup.Root>
  );
};

export default localize<Props>(RemindMe);
