import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import * as permissions from 'agreement/permissions';
import { getAccountFromSessionSelector } from 'reducers/session';
import agreementsReducer from 'reducers/entities/agreements';
import { isAgreementOwner } from 'agreement/selectors';

import Confirmable from 'components/confirmable';
import { MenuItem } from 'components/menu-item';
import Terminate from 'components/icons/terminate';

import style from './mark-as-terminated.module.scss';

export type Props = {
  message: MessageTranslator,
  onSuccess?: () => void,
  agreement: Oneflow.Agreement,
};

const MarkAsTerminatedComponent = ({
  message,
  onSuccess,
  agreement,
}: Props) => {
  const accountFromSession = useSelector(getAccountFromSessionSelector);
  const disabled = !permissions.allowContractTerminate(agreement, accountFromSession);
  const rpcState = useSelector(
    (state) => agreementsReducer.getTerminateAgreementSelector(state, { id: agreement.id }),
  );
  const isOwner = isAgreementOwner(accountFromSession, agreement);
  const dispatch = useDispatch();

  const terminateAgreement = () => {
    dispatch(agreementsReducer.terminateAgreement({
      id: agreement.id,
    }));
  };

  const resetRpcState = () => {
    dispatch(agreementsReducer.terminateAgreementReset({
      id: agreement.id,
    }));
  };

  useEffect(() => {
    if (!onSuccess) {
      return;
    }

    if (rpcState.success) {
      onSuccess();
    }
  }, [rpcState.success, onSuccess]);

  const handleConfirm = () => {
    if (!isOwner || rpcState.loading) {
      return;
    }

    terminateAgreement();
  };

  const getChildren = (onClick: React.MouseEventHandler) => {
    const isDisabled = disabled || !isOwner;

    return (
      <MenuItem
        icon={Terminate}
        label={message({
          id: 'Mark as terminated',
          comment: 'Action to mark a contract as terminated.',
        })}
        disabled={isDisabled}
        onClick={onClick}
        className={!isDisabled ? style.MarkAsTerminatedMenuItem : undefined}
      />
    );
  };

  return (
    <Confirmable
      header={message({
        id: 'Mark as terminated',
        comment: 'Confirm modal title for terminating a contract.',
      })}
      modalKey="terminate contract modal"
      body={(
        <>
          <p>
            <Message
              id="This will immediately terminate this contract regardless of its lifecycle settings and will not send notifications to contract participants."
              comment="Explaning text in confirmation modal for terminating a contract."
            />
          </p>
          <p>
            <Message
              id="Please confirm that you want to mark it as terminated."
              comment="Explaning text in confirmation modal for terminating a contract."
            />
          </p>
        </>
      )}
      error={rpcState.error}
      success={rpcState.success}
      onEnter={handleConfirm}
      onOpen={resetRpcState}
      isConfirmLoading={rpcState.loading}
      onConfirm={handleConfirm}
    >
      {getChildren}
    </Confirmable>
  );
};

export const MarkAsTerminated = localize(MarkAsTerminatedComponent);
