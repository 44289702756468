import clsx from 'clsx';
import type { ReactNode } from 'react';

import ExclamationCircleIcon from 'components/icons/exclamation-circle';
import InfoFilledIcon from 'components/icons/info-filled';
import CheckIcon from 'components/icons/check';
import CrossIcon from 'components/toasts/toast-icon/cross-icon';
import { TOAST_TYPES } from 'components/toasts/constants';
import type { ToastType } from 'components/toasts/types';

import style from './toast-icon.module.scss';

const Icons: Record<ToastType, ReactNode> = {
  info: <InfoFilledIcon height="24px" />,
  warning: <ExclamationCircleIcon height="24px" />,
  error: <CrossIcon width="8px" />,
  success: <CheckIcon width="10px" />,
};

const ToastIcon = ({ type }: { type: ToastType }) => {
  const containerClassName = clsx(style.Container, {
    [style.Info]: type === TOAST_TYPES.INFO,
    [style.Warning]: type === TOAST_TYPES.WARNING,
    [style.Error]: type === TOAST_TYPES.ERROR,
    [style.Success]: type === TOAST_TYPES.SUCCESS,
  });

  return (
    <div className={containerClassName}>
      {Icons[type]}
    </div>
  );
};

export default ToastIcon;
