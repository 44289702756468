// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import Delete from 'components/icons/delete';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import removeTable from 'components/rich-text-editor/editor-plugins/table-utils/remove-table';
import ToolbarButton from '../toolbar-button';
import style from './remove-table.module.scss';

const RemoveTable = () => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      label={(
        <Message
          id="Remove table"
          comment="The label of the editor's toolbar plugin that removes a table."
        />
      )}
      isMenuItem={false}
      onTrigger={() => {
        focusEditor();
        removeTable(editor);
      }}
      customButtonClass={style.RemoveTable}
      icon={<Delete />}
    />
  );
};

export default RemoveTable;
