import { Message } from '@oneflowab/pomes';

import Modal from 'components/modal';
import Button from 'components/button';
import WarningIcon from 'components/icons/warning';

import type { SkippedDocument } from '../delete-contract-step/types';
import style from './delete-contract-warnings-step.module.scss';

type Props = {
  onClose: () => void,
  skippedDocuments: SkippedDocument[],
};

export const DeleteContractWarningsStep = ({
  onClose,
  skippedDocuments,
}: Props) => (
  <Modal
    header={(
      <Message
        id="Some documents couldn't be deleted"
        comment="Header for the delete contract modal"
      />
    )}
    modalKey="delete-contract-warnings"
    onCancel={onClose}
  >
    <Modal.Body>
      <div className={style.Content}>
        <WarningIcon width="74px" height="74px" className={style.WarningIcon} />
        <h2 className={style.Title}>
          <Message
            id="{count} document couldn't be deleted"
            pluralId="{count} documents couldn't be deleted"
            pluralCondition="count"
            comment="Header for the delete contract modal"
            values={{ count: skippedDocuments.length }}
          />
        </h2>
        <p>
          <Message
            id="Contact admin for permissions to delete these documents"
            comment="Header for the delete contract modal"
          />
        </p>
      </div>
    </Modal.Body>
    <Modal.Actions>
      <Button
        kind="primary"
        onClick={onClose}
      >
        <Message id="Ok" comment="Button label to close the modal" />
      </Button>
    </Modal.Actions>
  </Modal>
);
