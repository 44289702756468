// Few reasons why calculated devicePixelRatio could be unreliable
// Inconsistencies in window.devicePixelRatio and window.screen.availWidth implementation
// And browser screen dimensions due to shrunk window
export function isDevicePixelRatioUnreliable() {
  const devicePixelRelativeRatio = window.screen.availWidth / window.innerWidth;
  // Invariant:
  // baseDevicePixelRatio = window.devicePixelRatio of the device screen at 100% at any zoom level
  // Given the browser do not have inconsistencies
  const baseDevicePixelRatio = Math.round(window.devicePixelRatio / devicePixelRelativeRatio);

  if (devicePixelRelativeRatio === window.devicePixelRatio) {
    return false;
  }

  // Browser inconsistency:
  //   window.screen.availWidth is dynamic and same as window.innerWidth when zoomed
  // Firefox has inconsistency in devicePixelRelativeRatio always returning 1
  if (window.devicePixelRatio > 2 && devicePixelRelativeRatio === 1) {
    return true;
  }

  const roundedDevicePixelRatio = Number(window.devicePixelRatio.toFixed(3));
  const roundedDevicePixelRelativeRatio = Number(devicePixelRelativeRatio.toFixed(3));
  const inferredDevicePixelRelativeRatio = roundedDevicePixelRelativeRatio * baseDevicePixelRatio;

  // Browser inconsistency: window.devicePixelRatio being fixed in window when zoomed
  // In Safari, window.devicePixelRatio is fixed at 2 even when zoomed
  if (inferredDevicePixelRelativeRatio !== roundedDevicePixelRatio) {
    return true;
  }

  return false;
}

// Not using window.devicePixelRatio since different devices has different values
// And finding the initial base devicePixelRatio is tricky
// When the page is loaded in different zoom levels
// This relative ratio scale from base value 1.
// For simplicity, ignoring actual decimal ratio value with floating point errors
// Which can be rounded to some decimal places
// if devicePixelRatio is 2 at zoom level 100% (default)
// then
//   the relative ratio is 1 at 100%
//   the relative ratio is 1.10 at 110% (devicePixelRatio is 1.1 * 2 = 2.2)
//   the relative ratio is 1.25 at 125% (devicePixelRatio is 1.25 * 2 = 2.5)
export const getDevicePixelRelativeRatio = () => window.screen.availWidth / window.innerWidth;
