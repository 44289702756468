// @flow

import React from 'react';
import { useFocused } from 'slate-react';

import style from './page-break.module.scss';

const PageBreak = ({ children }: RenderSlateElementProps) => {
  const isFocused = useFocused();

  if (!isFocused) {
    return null;
  }

  return (
    <div
      contentEditable={false}
      className={style.PageBreak}
      title="Page break"
    >
      <span
        style={{ display: 'none' }}
      >
        &nbsp;
      </span>
      {children}
    </div>
  );
};

export default PageBreak;
