// @flow

import * as React from 'react';
import { getExportLink } from 'oneflow-client/export';
import type { Match } from 'react-router';

import { Message } from '@oneflowab/pomes';
import Download from 'components/icons/download';
import Alert from 'components/icons/alert';
import ExternalLink from 'components/icons/external-link';

import style from './download.module.scss';

export type Props = {|
  match: Match,
|};

type State = {|
  link: string,
  error: boolean,
|};

export class DownloadComponent extends React.Component<Props, State> {
  state = {
    link: '',
    error: false,
  }

  componentDidMount() {
    const { accountId, assetId, extension } = this.props.match.params;

    if (!accountId || !assetId || !extension) {
      return;
    }

    getExportLink({
      assetId,
      accountId,
      extension,
    }).then(this.onSuccess)
      .catch(this.onError);
  }

  onSuccess = ({ link }: { link: string }) => {
    window.location.replace(link);
    this.setState({ link });
  }

  onError = () => this.setState({ error: true });

  renderSuccess = () => {
    const { link } = this.state;

    if (!link) {
      return null;
    }

    return (
      <>
        <Download height="100px" className={style.Icon} />
        <div className={style.TextBox}>
          <p className={style.Heading}>
            <Message
              id="The download will start automatically"
              comment="Welcome message when a user is redirected to the download landing page."
            />
          </p>
          <p>
            <Message
              id="If the download does not start immediately {clickHere}"
              values={{
                clickHere: (
                  <a href={link}>
                    <Message
                      id="click here"
                      comment="Part of string: If the download does not start immediately CLICK HERE"
                    />
                  </a>
                ),
              }}
              comment="Link to manually start the download of a requested file."
            />
            <ExternalLink height="14px" className={style.Link} />
          </p>
        </div>
        <div className={style.TextBox}>
          <p>
            <Message
              id="If you’re having trouble with your download, here are some possible reasons why"
              comment="Heading of a list of possible reasons why a download is failing."
            />
          </p>
          <ul>
            <li>
              <Message
                id="You have used an old link"
                comment="Part of a list of possible reasons why a download is failing."
              />
            </li>
            <li>
              <Message
                id="Wrong user is logged in"
                comment="Part of a list of possible reasons why a download is failing."
              />
            </li>
            <li>
              <Message
                id="Connection issues"
                comment="Part of a list of possible reasons why a download is failing."
              />
            </li>
          </ul>
        </div>
      </>
    );
  };

  renderError = () => {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <>
        <Alert height="100px" className={style.Alert} />
        <div className={style.TextBox}>
          <p className={style.Heading}>
            <Message
              id="Download failed"
              comment="Main alert message when a download has failed."
            />
          </p>
          <p>
            <Message
              id="Here are some possible reasons why"
              comment="Heading of a list of possible reasons why a download is failing."
            />
          </p>
          <ul>
            <li>
              <Message
                id="You have used an old link"
                comment="Part of a list of possible reasons why a download is failing."
              />
            </li>
            <li>
              <Message
                id="Wrong user is logged in"
                comment="Part of a list of possible reasons why a download is failing."
              />
            </li>
          </ul>
        </div>
      </>
    );
  };

  render() {
    return (
      <div className={style.Download}>
        <div className={style.Container}>
          {this.renderSuccess()}
          {this.renderError()}
          <div className={style.TextBox}>
            <p>
              <Message
                id="If the problem persists, please contact {email}"
                values={{
                  email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
                }}
                comment="Contact information for users who have trouble downloading a requested file."
              />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
