import { useRef, useState } from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import Tooltip from 'components/tooltip';
import DropdownMenu from 'components/dropdown-menu';
import { isAlignActive, toggleAlign } from 'components/rich-text-editor-toolbars/toolbar-buttons/align-button/align-plugin';
import { AlignIcon } from 'components/rich-text-editor-toolbars/toolbar-buttons/align-button/align-button';

import AlignCenterIcon from 'components/icons/align-center';
import AlignLeftIcon from 'components/icons/align-left';
import AlignRightIcon from 'components/icons/align-right';
import ChevronUpIcon from 'components/icons/chevron-up';
import ChevronDownIcon from 'components/icons/chevron-down';
import ToolbarButton from '../toolbar-button';

import style from './align-dropdown.module.scss';

const DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
} as const;

type Direction = keyof typeof DIRECTIONS;
type Directions = typeof DIRECTIONS[Direction];

const directions: Directions[] = ['left', 'right', 'center'];

const TextAlignDropdownMenu = () => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isAnyAlignActive = directions.some(
    (direction) => isAlignActive(editor, direction) === true,
  );

  const currentAlignDirection: Directions = directions.find(
    (direction) => isAlignActive(editor, direction),
  ) ?? DIRECTIONS.LEFT;

  const alignButtonRef = useRef(null);

  let dropdownIcon = <ChevronDownIcon width="10px" />;
  if (isDropdownOpen) {
    dropdownIcon = <ChevronUpIcon width="10px" />;
  }

  const onVisibilityChange = (visible: boolean) => {
    setIsDropdownOpen(visible);
  };

  const dropdownItems = [
    <ToolbarButton
      key="left"
      isActive={isAlignActive(editor, DIRECTIONS.LEFT)}
      isMenuItem
      label={(
        <Message
          id="Left"
          comment="Menu item for aligning text to the left"
        />
      )}
      onTrigger={() => {
        focusEditor();
        toggleAlign(editor, DIRECTIONS.LEFT);
      }}
      icon={<AlignLeftIcon />}
    />,
    <ToolbarButton
      key="center"
      isActive={isAlignActive(editor, DIRECTIONS.CENTER)}
      isMenuItem
      label={(
        <Message
          id="Center"
          comment="Menu item for aligning text to center"
        />
      )}
      onTrigger={() => {
        focusEditor();
        toggleAlign(editor, DIRECTIONS.CENTER);
      }}
      icon={<AlignCenterIcon />}
    />,
    <ToolbarButton
      key="right"
      isActive={isAlignActive(editor, DIRECTIONS.RIGHT)}
      isMenuItem
      label={(
        <Message
          id="Right"
          comment="Menu item for aligning text to the right"
        />
      )}
      onTrigger={() => {
        focusEditor();
        toggleAlign(editor, DIRECTIONS.RIGHT);
      }}
      icon={<AlignRightIcon />}
    />,
  ];

  const dropdownClasses = clsx(style.Dropdown, {
    [style.SelectedAlignment]: isAnyAlignActive,
  });

  return (
    <Tooltip
      messageClassName={style.TooltipText}
      message={(
        <Message
          id="Align"
          comment="Tooltip message explaining dropdown for different types of ways to align text"
        />
      )}
      side="bottom"
    >
      <span>
        <DropdownMenu
          items={dropdownItems}
          ref={alignButtonRef}
          anchor={{
            x: 'inner-left',
            y: 'below',
          }}
          hideMenuOnExit
          focusOnCloseDisabled
          dropdownIcon={dropdownIcon}
          onVisibilityChange={onVisibilityChange}
          className={dropdownClasses}
        >
          <AlignIcon direction={currentAlignDirection} />
        </DropdownMenu>
      </span>
    </Tooltip>
  );
};

export default TextAlignDropdownMenu;
