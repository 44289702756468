// @flow

import * as React from 'react';
import { Message, type MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';

import ActionBar from 'components/action-bar';
import Button from 'components/button';
import Add from 'components/icons/add';
import { Table } from 'components/table';
import Tooltip from 'components/tooltip';
import AddDataField from 'components/modals/add-data-field';
import EditDataField from 'components/modals/edit-data-field';
import { RemoveDataFieldConfirmation } from 'components/modals/remove-data-field';

import ActionsMenu from 'components/actions-menu';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { EditMenuItem } from 'components/menu-items/edit';
import InfoIcon from 'components/icons/info';
import style from './data-fields.module.scss';

export type Props = {
  message: MessageTranslator,
  queryDataFields: number => void,
  dataFields: Array<DataField>,
  dataFieldsQuery: Query,
  dataFieldSetId: number,
  isUsedByIntegration: boolean,
};
export class DataFields extends React.Component<Props> {
  componentDidMount() {
    const { queryDataFields, dataFieldSetId } = this.props;

    if (dataFieldSetId) {
      queryDataFields(dataFieldSetId);
    }
  }

  getDataFieldInfo = (dataField: DataField) => (
    <div className={style.DataField}>
      {dataField.name}
      <div className={style.Description}>
        {dataField.description}
      </div>
    </div>
  );

  getExternalKey = (dataField: DataField) => (
    dataField.externalKey
  );

  getKey = (dataField: DataField) => (
    dataField.key
  );

  getEditMenuItem = (onClick: () => void) => (
    <EditMenuItem
      onClick={onClick}
    />
  );

  getDeleteMenuItem = (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
    />
  );

  getActions = (dataField: DataField) => {
    const {
      message,
      isUsedByIntegration,
      dataFieldSetId,
    } = this.props;

    if (!isUsedByIntegration) {
      return (
        <ActionsMenu
          actions={[
            <EditDataField
              dataField={dataField}
              dataFieldSetId={dataFieldSetId}
              usedExternalKeys={this.getUsedExternalKeys(dataField.id)}
            >
              {this.getEditMenuItem}
            </EditDataField>,
            <RemoveDataFieldConfirmation
              dataField={dataField}
              dataFieldSetId={dataFieldSetId}
              isUsedByIntegration={isUsedByIntegration}
            >
              {this.getDeleteMenuItem}
            </RemoveDataFieldConfirmation>,
          ]}
          focusOnCloseDisabled
        />
      );
    }

    return (
      <Tooltip
        message={message({
          id: 'Not allowed to modify data fields used by other extensions',
          comment: 'Tooltip showing reason for not being allowed to edit data field.',
        })}
        side="top"
      >
        <ActionsMenu
          actions={[]}
          disabled
          focusOnCloseDisabled
        />
      </Tooltip>
    );
  };

  getTableConfig() {
    const { dataFields, message } = this.props;

    const infoColumn = {
      name: 'info',
      label: message({
        id: 'Data field',
        comment: 'Label in data field list for the info column',
      }),
      type: 'cell',
      value: this.getDataFieldInfo,
    };
    const keyColumn = {
      name: 'key',
      label: (
        <div className={style.Label}>
          <Message
            id="Key"
            comment="Label in data field list for the key column"
          />
          <Tooltip
            side="top"
            zIndex="1031"
            message={message({
              id: 'This is used by Oneflow to identify the data field.',
              comment: 'Tooltip for the key column',
            })}
            theme="oneflow"
          >
            <InfoIcon className={style.InfoIcon} width="14px" />
          </Tooltip>
        </div>
      ),
      type: 'cell',
      value: this.getKey,
    };
    const externalKeyColumn = {
      name: 'externalKey',
      label: (
        <div className={style.Label}>
          <Message
            id="External key"
            comment="Label in data field list for the external key column"
          />
          <Tooltip
            side="top"
            zIndex="1031"
            message={message({
              id: 'This is used by integrations to identify the data field.',
              comment: 'Tooltip for the external key column',
            })}
            theme="oneflow"
          >
            <InfoIcon className={style.InfoIcon} width="14px" />
          </Tooltip>
        </div>
      ),
      type: 'cell',
      value: this.getExternalKey,
    };
    const actionsColumn = {
      name: 'actions',
      label: message({
        id: 'Actions',
        comment: 'Label in data field list for the actions column',
      }),
      type: 'actions',
      value: this.getActions,
    };

    return {
      items: dataFields,
      itemKey: 'id',
      actions: [],
      columns: [
        infoColumn,
        keyColumn,
        externalKeyColumn,
        actionsColumn,
      ],
    };
  }

  getUsedExternalKeys = (currentDataFieldId?: number) => {
    const { dataFields } = this.props;

    return dataFields
      .filter((dataField) => dataField.id !== currentDataFieldId && dataField.externalKey)
      .map<?string>((dataField) => dataField.externalKey);
  };

  getCreateDataFieldButton = (onClick: () => void) => (
    <Button
      data-testid="add-data-field"
      icon={Add}
      kind="primary"
      onClick={onClick}
      disabled={this.props.isUsedByIntegration}
    >
      <Message
        id="Add a data field"
        comment="Button text for adding data field on the data field list page."
      />
    </Button>
  );

  renderActionBar() {
    const { dataFieldSetId } = this.props;

    return (
      <ActionBar collapsed>
        <ActionBar.Group>
          <AddDataField
            dataFieldSetId={dataFieldSetId}
            usedExternalKeys={this.getUsedExternalKeys()}
          >
            {this.getCreateDataFieldButton}
          </AddDataField>
        </ActionBar.Group>
      </ActionBar>
    );
  }

  render() {
    const { dataFieldsQuery } = this.props;

    return (
      <div className={style.PageContainer}>
        {this.renderActionBar()}
        <Table
          config={this.getTableConfig()}
          query={dataFieldsQuery}
        />
      </div>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Data fields',
    comment: 'Used as the page title of the data field list.',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(DataFields);
