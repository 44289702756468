import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import videojs from 'video.js';

import { generatePlayerOptions, setControlVisibility } from 'components/agreement-video/video-preview/video-modal/video-modal-content/video-js-helpers';

import style from './video-modal-content.module.scss';

type Props = {
  hideControls: Array<string>,
  source: string,
  autoplay: boolean,
  thumbnail: string,
  fluid?: boolean,
  id?: string,
}

const VideoModalContent = ({
  hideControls,
  source,
  autoplay,
  thumbnail,
  fluid = true,
  id = 'video-player',
}: Props) => {
  const playerRef = useRef(null);
  const videoRef = useRef(null);

  const initPlayer = (args: Props) => {
    const playerOptions = generatePlayerOptions({
      hideControls,
      source,
      autoplay,
      fluid,
    });

    playerRef.current = videojs(videoRef.current, playerOptions);
    setControlVisibility(playerRef.current, args.hideControls);

    playerRef.current?.src({
      src: source,
      type: 'video/mp4',
      poster: thumbnail,
    });
  };

  useEffect(() => {
    initPlayer({
      hideControls,
      source,
      autoplay,
      thumbnail,
      fluid,
    });

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const videoClassNames = clsx('video-js', style.SizeAuto, {
    [style.FluidHeight]: fluid,
    'vjs-big-play-centered': !autoplay,
  });

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video ref={videoRef} id={id} className={videoClassNames} />;
};

export default VideoModalContent;
