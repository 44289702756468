import * as Select from '@radix-ui/react-select';
import { UpChevronIcon, DownChevronIcon, SelectedIcon } from '../../icons';
import style from './text-size-selector.module.scss';

const predefinedFontSizes = [
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '18',
  '24',
];

type Props = {
  fontSize: number,
  onTextSizeChange: (fontSize: string) => void;
}

export const TextSizeSelector = ({ fontSize, onTextSizeChange }: Props) => (
  <Select.Root value={String(fontSize)} onValueChange={onTextSizeChange} name="text-size-change-field">
    <Select.Trigger className={style.SelectTrigger}>
      <Select.Value placeholder={fontSize} />
      <Select.Icon className={style.SelectIcon}>
        <DownChevronIcon width="10px" height="10px" />
      </Select.Icon>
    </Select.Trigger>
    <Select.Portal>
      <Select.Content
        className={style.SelectContent}
        position="popper"
        sideOffset={10}
      >
        <Select.ScrollUpButton className={style.SelectScrollButton}>
          <UpChevronIcon width="10px" height="10px" />
        </Select.ScrollUpButton>
        <Select.Viewport className={style.SelectViewPort}>
          {predefinedFontSizes.map((sizeOption: string) => (
            <Select.Item
              key={sizeOption}
              value={String(sizeOption)}
              className={style.SelectItem}
            >
              <Select.ItemText>
                <div className={style.SelectOption}>
                  <div className={style.OptionName}>{sizeOption}</div>
                  <SelectedIcon
                    width="10px"
                    height="10px"
                    className={style.SelectedIcon}
                  />
                </div>
              </Select.ItemText>
              <Select.ItemIndicator />
            </Select.Item>
          ))}
        </Select.Viewport>
        <Select.ScrollDownButton className={style.SelectScrollButton}>
          <DownChevronIcon width="10px" height="10px" />
        </Select.ScrollDownButton>
      </Select.Content>
    </Select.Portal>
  </Select.Root>
);
