import Svg from 'components/svg';

const EeEsteId = Svg((
  <g
    transform="translate(0.000000,728.000000) scale(0.100000,-0.100000)"
  >
    <path
      d="M830 7273 c-152 -17 -358 -103 -481 -201 -157 -124 -253 -266 -316
-468 l-28 -89 0 -2875 0 -2875 28 -89 c63 -202 159 -344 316 -468 90 -72 247
-149 366 -180 l90 -23 4105 0 4105 0 90 23 c119 31 276 108 366 180 157 124
253 266 316 468 l28 89 0 2875 0 2875 -28 89 c-63 202 -159 344 -316 468 -87
70 -244 148 -361 180 l-85 23 -4080 1 c-2244 0 -4096 -1 -4115 -3z m8255 -295
c177 -61 321 -181 395 -330 75 -154 70 90 70 -3008 0 -2407 -2 -2794 -15
-2855 -46 -220 -217 -403 -450 -483 l-80 -27 -4095 0 -4095 0 -80 27 c-177 61
-321 181 -395 330 -75 154 -70 -90 -70 3008 0 3095 -5 2855 70 3005 88 178
255 304 465 350 49 11 780 13 4130 12 l4070 -2 80 -27z"
      style={{
        stroke: 'none', fillRule: 'nonzero', fill: '#003167', fillOpacity: 1,
      }}
    />
    <path
      d="M1497 6186 c-54 -15 -100 -53 -128 -108 -18 -36 -19 -70 -19 -873 0
-459 3 -835 8 -835 4 0 365 143 802 318 l795 318 3 509 c2 448 1 515 -14 552
-21 57 -83 109 -146 122 -76 17 -1239 13 -1301 -3z"
      style={{
        stroke: 'none', fillRule: 'nonzero', fill: '#f04e22', fillOpacity: 1,
      }}
    />
    <path
      d="M3706 6190 c-77 -24 -130 -86 -146 -171 -7 -36 -9 -852 -8 -2414 l3
-2360 22 -40 c24 -46 54 -75 103 -101 34 -18 86 -19 1365 -19 1419 0 1459 1
1751 50 443 75 822 235 1124 475 424 337 695 848 797 1500 27 170 26 899 0
1075 -80 527 -255 935 -540 1255 -307 345 -750 588 -1267 694 -307 63 -222 60
-1765 62 -787 2 -1423 -1 -1439 -6z m2510 -1415 c330 -58 584 -237 720 -507
85 -170 124 -358 124 -603 0 -240 -34 -411 -115 -584 -124 -260 -331 -437
-626 -535 -183 -61 -242 -66 -716 -66 l-423 0 0 1155 0 1155 474 0 c381 0 491
-3 562 -15z"
      style={{
        stroke: 'none', fillRule: 'nonzero', fill: '#003167', fillOpacity: 1,
      }}
    />
    <path
      d="M2145 4087 l-800 -322 -3 -1240 c-2 -1397 -7 -1310 72 -1383 32 -30
57 -43 98 -51 39 -8 239 -11 674 -9 582 3 621 4 654 22 49 26 79 55 101 98 18
36 19 84 19 1623 0 872 -3 1585 -7 1584 -5 0 -368 -146 -808 -322z"
      style={{
        stroke: 'none', fillRule: 'nonzero', fill: '#003167', fillOpacity: 1,
      }}
    />
  </g>
), {
  viewBox: '0 0 982.000000 728.000000',
});

EeEsteId.displayName = 'EeEsteId';

export default EeEsteId;
