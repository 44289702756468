import { ReactNode } from 'react';
import clsx from 'clsx';

import style from './empty-stats-widget.module.scss';

type Props = {
  className?: string,
  children: ReactNode,
};

const EmptyStatsWidget = ({ className, children }: Props) => (
  <div className={clsx(style.EmptyStatsWidget, className)}>
    {children}
  </div>
);

export default EmptyStatsWidget;
