/* eslint-disable react/prop-types */

import React from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import style from './text.module.scss';

const TextInput = ({
  disabled,
  onChange,
  onBlur,
  placeholder,
  value,
  required,
  invalid,
}) => (
  <div
    className={clsx(style.InputContainer, {
      [style.Invalid]: invalid,
    })}
  >
    <input
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      value={value}
      required={required}
    />
    {invalid && (
      <div className={style.ValidationMessage}>
        <Message id="This field is required" comment="Input field validation message." />
      </div>
    )}
  </div>
);

export default TextInput;
