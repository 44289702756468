// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import BadgeWithIcon from 'components/badge-with-icon';
import {
  BADGE_SIGN_LATER_ENABLED,
} from 'agreement/badge-icons';

import style from './sign-later-enabled.module.scss';

export const SignLaterEnabledInfo = () => (
  <div data-testid="sign-later" className={style.Container}>
    <BadgeWithIcon type={BADGE_SIGN_LATER_ENABLED} />
    <p>
      <Message
        id="The {sign} button will be hidden for counterparties. To enable signing, turn off {signLater}."
        values={{
          sign: <span className={style.SignLater}><Message id="Sign" comment="comment" /></span>,
          signLater: <span className={style.SignLater}><Message id="Sign later" comment="comment" /></span>,
        }}
        comment="Text explaining that the 'Sign' button will be hidden for counterparties and that they would need to turn off the 'Sign Later' feature in order to be able to sign the document."
      />
    </p>
  </div>
);
