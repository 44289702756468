// @flow

import { connect } from 'react-redux';

import rolesReducer from 'reducers/entities/roles';

import RoleDetails from './role-details';

const QUERY_NAME = 'admin/roles';

export const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.match.params.id, 10);
  const rolesQuery = rolesReducer.getQuerySelector(state, { name: QUERY_NAME });
  const roles = rolesReducer.getRolesSelector(state, { ids: rolesQuery.result });
  const role = roles.find((x) => x.id === id);

  return {
    role,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  queryRoles: ({ pagination, params }: Query) => {
    dispatch(rolesReducer.queryRoles({
      name: QUERY_NAME,
      pagination: {
        ...pagination,
        limit: 10000,
      },
      params,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleDetails);
