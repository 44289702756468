// @flow

import * as React from 'react';
import { getStyle } from '../../utils';
import style from './horizontal-line.module.scss';

const HorizontalLine = ({ attributes, element, children }: RenderSlateElementProps) => {
  const { className } = element;

  return (
    <div contentEditable={false} className={style.HorizontalLine}>
      <hr
        {...attributes}
        className={className}
        style={getStyle(element)}
      />
      {children}
    </div>
  );
};

export default HorizontalLine;
