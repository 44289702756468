// @flow

import * as React from 'react';

import { localize, Message } from '@oneflowab/pomes';
import createDecorator from 'final-form-calculate';

import ModalForm from 'hocs/modal-form';

import {
  DataFieldDescription,
  DataFieldExternalKey,
  DataFieldName,
  DataFieldPlaceholder,
  DataFieldValue,
} from 'components/fields';

import style from './add-data-field.module.scss';

export type CreateDataField = {
  name: string,
  description?: string,
  placeholder?: string,
  value?: string,
  externalKey?: Array<string>
}

type Props = {
  createDataField: (args: CreateDataField) => void,
  createState: CreateState,
  resetCreateState: () => void,
  usedExternalKeys: Array<string>,
  children: React.Node,
};

export const onNameUpdates = (currentName?: string) => {
  if (!currentName) {
    return {};
  }

  return {
    externalKey: currentName.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^a-zA-Z0-9-_]/g, ''),
  };
};

const decorator = createDecorator({
  field: 'name',
  updates: onNameUpdates,
});

export const AddDataFieldFields = ({

  createDataField,
  createState,
  resetCreateState,
  usedExternalKeys,
  children,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Create data field"
        comment="Modal title for creating a new data field"
      />
    )}
    body={(
      <>
        <div className={style.Row}>
          <DataFieldName autoFocus />
          <DataFieldDescription />
        </div>
        <div className={style.Row}>
          <DataFieldPlaceholder />
          <DataFieldValue />
        </div>
        <div className={style.Row}>
          <DataFieldExternalKey usedExternalKeys={usedExternalKeys} />
          <div className={style.Filler} />
        </div>
        <div className={style.Content}>
          <Message
            id="The external key is used when reading, setting or updating the value of the data field via the API."
            comment="Help text for external key when adding data field."
          />
        </div>
      </>
    )}
    onSubmit={createDataField}
    resetFormState={resetCreateState}
    formState={createState}
    isWideModal
    decorators={[
      decorator,
    ]}
    modalKey="extension create data field modal"
  >
    {children}
  </ModalForm>
);

export default localize<Props>(AddDataFieldFields);
