// @flow
/* eslint-disable import/no-named-as-default-member */

import * as React from 'react';
import { FadeIn } from 'components/transitions';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import clsx from 'clsx';

import { isSmallScreenWidth } from 'ui/config';

import localStorage from 'utils/local-storage';
import { isUserOnAdminPage, isUserOnGlobalSearchPage } from 'utils/isOnPage';

import { Acl, checkAcl } from 'components/acl';
import { WithCurrentWorkspaceLink } from 'hocs/with-current-workspace';
import Button from 'components/button';
import Conditional from 'components/conditional';
import CreateContractButton from 'components/create-contract-button';
import Cross from 'components/icons/cross';
import MenuIcon from 'components/icons/menu';
import Search from 'components/icons/search';
import SidebarHeader from 'components/sidebar-header';
import WorkspaceDropdown from 'components/workspace-dropdown';
import SidebarItemsBottom from 'components/sidebar-items-bottom';
import SidebarItemsTop from 'components/sidebar-items-top';
import SidebarSubmenuDesktop from 'components/sidebar-submenu-desktop';

import SidebarTooltip from './sidebar-tooltip';
import SidebarOption from './sidebar-option';
import style from './sidebar.module.scss';

type Props = {
  acl: {
    account: Acl,
    workspace: Acl,
  },
  createContract: number => void,
  isContractView?: boolean,
  lastContractSearch?: string,
  message: MessageTranslator,
  onMobileMenuToggle: (mobileMenuOpen: boolean) => void,
  pathname: string,
  updatesCount: number,
  currentWorkspace: Workspace,
  isPositionFixed: boolean,
  isBackdropEnabled: boolean,
};

type WithCurrentWorkspaceNamespace = (path: string) => string;

type NamespaceProps = {
  withCurrentWorkspaceNamespace: WithCurrentWorkspaceNamespace,
};

type PropsWithCurrentWorkspace = Props & NamespaceProps;

type State = {
  expanded: boolean,
  isMobile: boolean,
  mobileMenuOpen: boolean,
  showExpandSymbol: boolean,
  visible: boolean,
}

export class SidebarComponent extends React.PureComponent<PropsWithCurrentWorkspace, State> {
  static defaultProps = {
    isContractView: undefined,
    lastContractSearch: undefined,
  };

  state = {
    expanded: false,
    isMobile: false,
    mobileMenuOpen: false,
    showExpandSymbol: false,
    prevScrollPos: window.scrollY,
    visible: true,
    submenuItems: null,
    isSubmenuVisible: false,
  };

  sidebarRef = React.createRef();

  componentDidMount() {
    const { isContractView } = this.props;

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.handleScroll);

    if (localStorage.getItem('expanded') === null) {
      localStorage.setItem('expanded', 'false');
    }

    if (localStorage.getItem('has_expanded') === null) {
      localStorage.setItem('has_expanded', 'false');
    }

    const expandedState = localStorage.getItem('expanded') !== 'false';

    this.setState({
      expanded: !isContractView ? expandedState : false,
    });
  }

  componentDidUpdate() {
    const { isMobile, expanded } = this.state;

    if (isMobile && expanded) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        expanded: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    if (document.body.classList.contains('mobileMenuOpen')) {
      document.body.classList.remove('mobileMenuOpen');
    }

    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  handleScroll = () => {
    const { prevScrollPos } = this.state;

    const currentScrollPos = window.scrollY;
    const visible = prevScrollPos > currentScrollPos || currentScrollPos <= 72;

    this.setState({
      prevScrollPos: currentScrollPos,
      visible,
    });
  };

  updateWindowDimensions = () => {
    this.setState({ isMobile: isSmallScreenWidth() });
  }

  getContractsListLink() {
    const { withCurrentWorkspaceNamespace, lastContractSearch } = this.props;

    if (lastContractSearch) {
      return lastContractSearch;
    }

    return withCurrentWorkspaceNamespace('/contracts');
  }

  getWorkspaceName = (workspace: Workspace) => {
    const { message } = this.props;

    if (workspace.virtual) {
      return message({ id: 'Shared with me', comment: 'The virtual workspace name' });
    }

    return workspace.name;
  };

  toggleMobileMenu = () => {
    const { onMobileMenuToggle } = this.props;
    const { mobileMenuOpen, isMobile } = this.state;

    if (!isMobile) {
      return;
    }

    if (onMobileMenuToggle) {
      onMobileMenuToggle(!mobileMenuOpen);
      this.sidebarRef.current.scrollTo(0, 0);
    }

    if (!document.body.classList.contains('mobileMenuOpen')) {
      document.body.classList.add('mobileMenuOpen');
    }

    if (mobileMenuOpen) {
      document.body.classList.remove('mobileMenuOpen');
    }

    this.setState({
      mobileMenuOpen: !mobileMenuOpen,
    });
  }

  closeMobileMenu = () => {
    const { onMobileMenuToggle } = this.props;
    const { mobileMenuOpen, isMobile } = this.state;

    if (!isMobile || !mobileMenuOpen) {
      return;
    }

    if (onMobileMenuToggle) {
      onMobileMenuToggle(!mobileMenuOpen);
      this.sidebarRef.current.scrollTo(0, 0);
    }
  }

  renderCreateButton() {
    const {
      acl,
      message,
      createContract,
      isContractView,
      currentWorkspace,
    } = this.props;
    const { expanded, isMobile } = this.state;

    const isButtonDisabled = !checkAcl(acl.workspace, [
      'collection:agreement:create',
      'collection:agreement:create_blank',
    ], { match: 'any' });

    if (expanded || isMobile) {
      return (
        <div className={style.CreateButtonWrapper}>
          <CreateContractButton
            acl={acl}
            createContract={createContract}
            workspaceId={currentWorkspace.id}
            isContractView={isContractView}
            isMobile={isMobile}
            isButtonDisabled={isButtonDisabled}
            expanded={expanded}
          />
        </div>
      );
    }

    return (
      <SidebarTooltip
        isDisabled={isButtonDisabled}
        tooltipText={message({
          id: 'New document',
          comment: 'Used as a tooltip for the sidebar item',
        })}
        sideOffset={15}
      >
        <div className={style.CreateButtonWrapper}>
          <CreateContractButton
            acl={acl}
            createContract={createContract}
            workspaceId={currentWorkspace.id}
            isContractView={isContractView}
            isMobile={isMobile}
            isButtonDisabled={isButtonDisabled}
            expanded={expanded}
          />
        </div>
      </SidebarTooltip>
    );
  }

  toggleExpandedState = () => {
    const { isContractView } = this.props;

    if (isContractView) {
      localStorage.setItem(
        'has_expanded',
        localStorage.getItem('has_expanded') === 'false' ? 'true' : 'false',
      );
    }

    if (!isContractView) {
      localStorage.setItem(
        'expanded',
        localStorage.getItem('expanded') === 'false' ? 'true' : 'false',
      );
    }

    this.setState((prevState) => ({
      ...prevState,
      expanded: !prevState.expanded,
    }));
  };

  renderMobileSearchSymbol = () => {
    const { isMobile } = this.state;

    if (!isMobile) {
      return null;
    }

    return (
      <div style={{ maxWidth: '42px' }}>
        <SidebarOption
          className={style.Search}
          data-testid="global-search-toggle"
          IconComponent={Search}
          isMobile={isMobile}
          link="/search"
          trackable={{
            name: 'Go To Global Search',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={this.closeMobileMenu}
        />
      </div>
    );
  };

  renderMobileMenuSymbol = () => {
    const { isMobile, mobileMenuOpen } = this.state;

    if (!isMobile) {
      return null;
    }

    if (!mobileMenuOpen) {
      return (
        <FadeIn in transitionTime={500} className={style.FullHeight}>
          <Button
            customClass={style.MobileMenuToggle}
            icon={<MenuIcon height="24px" />}
            onClick={this.toggleMobileMenu}
          />
        </FadeIn>
      );
    }

    return (
      <FadeIn in transitionTime={500} className={style.FullHeight}>
        <Button
          customClass={style.MobileMenuToggle}
          icon={<Cross height="24px" />}
          onClick={this.toggleMobileMenu}
        />
      </FadeIn>
    );
  };

  getBackdrop = () => {
    const { isBackdropEnabled } = this.props;
    const { expanded, isMobile } = this.state;

    if (!isBackdropEnabled || isMobile) {
      return null;
    }

    const backdropStyles = clsx(style.Backdrop, {
      [style.Visible]: this.state.expanded,
    });

    return (
      <>
        <div className={backdropStyles} />
        <Conditional ifCondition={expanded}>
          <div
            className={style.HideBackdrop}
            onMouseEnter={() => {
              this.setState({ expanded: false });
              this.hideSubmenu();
            }}
          />
        </Conditional>
      </>
    );
  };

  hideSubmenu = () => {
    this.setState({
      submenuItems: null,
      isSubmenuVisible: false,
    });
  }

  onMenuItemClick = (subItems) => {
    this.hideSubmenu();

    if (subItems) {
      this.toggleSubMenu(subItems);
    } else {
      this.toggleMobileMenu();
    }
  };

  toggleSubMenu = (submenuItems) => {
    if (this.state.isSubmenuVisible) {
      this.setState({
        submenuItems: null,
        isSubmenuVisible: false,
      });
      return;
    }
    this.setState({
      submenuItems,
      isSubmenuVisible: true,
    });
  };

  renderSidebarSubmenu = (sidebarWrapperRef) => {
    const {
      isMobile,
      submenuItems,
      isSubmenuVisible,
      expanded,
    } = this.state;

    if (isMobile) {
      return null;
    }

    return (
      <SidebarSubmenuDesktop
        items={submenuItems}
        visible={isSubmenuVisible}
        expanded={expanded}
        hideSubmenu={this.hideSubmenu}
        sidebarRef={sidebarWrapperRef}
      />
    );
  };

  render() {
    const {
      acl,
      isContractView,
      pathname,
      withCurrentWorkspaceNamespace,
      currentWorkspace,
      updatesCount,
      lastContractSearch,
      message,
      isPositionFixed,
    } = this.props;
    const {
      expanded,
      isMobile,
      mobileMenuOpen,
      showExpandSymbol,
      submenuItems,
      isSubmenuVisible,
    } = this.state;

    const isOnAdminPage = isUserOnAdminPage(pathname);
    const isOnGlobalSearchPage = isUserOnGlobalSearchPage(pathname);
    const isOnAdminOrGlobalSearchPage = isOnAdminPage || isOnGlobalSearchPage;

    const responsiveSidebarClasses = clsx(style.Sidebar, {
      [style.Expanded]: expanded,
    });

    const responsiveSidebarContentClasses = clsx(style.SidebarContent, {
      [style.Expanded]: expanded,
      [style.MenuOpen]: mobileMenuOpen,
      [style.Mobile]: isMobile,
      [style.SidebarContentHidden]: isContractView && isMobile && !this.state.visible,
      [style.IsPositionFixed]: isPositionFixed && !isMobile,
    });

    const getMouseEnterEvent = () => (
      () => this.setState({
        showExpandSymbol: true,
      })
    );

    const getMouseLeaveEvent = () => (
      () => this.setState({
        showExpandSymbol: false,
      })
    );

    const sidebarWrapperRef = React.createRef();
    return (
      <div
        ref={sidebarWrapperRef}
        onMouseEnter={getMouseEnterEvent()}
        onMouseLeave={getMouseLeaveEvent()}
        className={style.SidebarWrapper}
      >
        <div className={responsiveSidebarClasses}>
          <div className={responsiveSidebarContentClasses} ref={this.sidebarRef}>
            <div className={style.LogoSection}>
              <SidebarHeader
                expanded={expanded}
                isMobile={isMobile}
                showExpandSymbol={showExpandSymbol}
                toggleExpandedState={this.toggleExpandedState}
                isContractView={isContractView}
                mobileMenuOpen={mobileMenuOpen}
                toggleMobileMenu={this.toggleMobileMenu}
              />
              <div className={style.MobileMenuItems}>
                {this.renderMobileSearchSymbol()}
                {this.renderMobileMenuSymbol()}
              </div>
            </div>
            <div className={style.WorkspaceSection} data-testid="sidebar-workspace-picker">
              <WorkspaceDropdown
                pathname={pathname}
                expanded={expanded}
                isMobile={isMobile}
                tooltipText={isOnAdminOrGlobalSearchPage
                  ? message({
                    id: 'Workspace',
                    comment: 'Workspace placeholder name for dropdown selector',
                  }) : this.getWorkspaceName(currentWorkspace)}
                toggleMobileMenu={this.toggleMobileMenu}
              />
            </div>
            <div className={style.ResponsiveSection} data-testid="sidebar-create-agreement">
              {this.renderCreateButton()}
            </div>
            <SidebarItemsTop
              acl={acl}
              expanded={expanded}
              isMobile={isMobile}
              isContractView={isContractView}
              onItemClick={this.onMenuItemClick}
              withCurrentWorkspaceNamespace={withCurrentWorkspaceNamespace}
              lastContractSearch={lastContractSearch}
            />
            <div>
              <SidebarItemsBottom
                acl={acl}
                expanded={expanded}
                isMobile={isMobile}
                isContractView={isContractView}
                mobileMenuOpen={mobileMenuOpen}
                onItemClick={this.onMenuItemClick}
                updatesCount={updatesCount}
                submenuItems={submenuItems}
                isSubmenuVisible={isSubmenuVisible}
                hideSubmenu={this.hideSubmenu}
                sidebarWrapperRef={this.sidebarRef}
              />
            </div>
          </div>
          {this.getBackdrop()}
        </div>
        {this.renderSidebarSubmenu(sidebarWrapperRef)}
      </div>
    );
  }
}

export const SidebarWithNamespace = (props: Props) => (namespaceProps: NamespaceProps) => (
  <SidebarComponent
    {...props}
    withCurrentWorkspaceNamespace={namespaceProps.withCurrentWorkspaceNamespace}
  />
);

export const SidebarWithCurrentWorkspace = (props: Object) => (
  <WithCurrentWorkspaceLink>
    {SidebarWithNamespace(props)}
  </WithCurrentWorkspaceLink>
);

export default localize<Props>(SidebarWithCurrentWorkspace);
