// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import map from 'lodash/map';
import without from 'lodash/without';
import concat from 'lodash/concat';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import clsx from 'clsx';

import BulkActions from 'components/bulk-actions';
import Checkbox from 'components/checkbox';
import EmptyState from 'components/empty-state';

import style from './table.module.scss';
import TableHead from './table-head';
import TableCell from './table-cell';
import TableRow from './table-row';
import TableHeader from './table-header';
import TableBody from './table-body';
import TableLoading from './table-loading';

type EmptyStateType = {
  header: string,
  icon: React.Element<*> | () => void,
  content: React.Node | () => void,
  showEmptyState: boolean,
};

type Props = {
  onSelectionChanged: Object => void,
  config: Object,
  clearSelection?: boolean,
  emptyState?: EmptyStateType,
  query: Query,
  bulkActionsSpacing?: boolean,
  TableClassName?: string,
};

type State = {
  selectedItems: Array<number | string>,
};

class Table extends React.Component<Props, State> {
  static defaultProps = {
    clearSelection: false,
    emptyState: undefined,
    bulkActionsSpacing: undefined,
  };

  state = {
    selectedItems: [],
  };

  componentDidUpdate(prevProps: Props) {
    const { clearSelection, config } = this.props;

    if (clearSelection !== prevProps.clearSelection) {
      if (clearSelection) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ selectedItems: [] });
      }
    }

    if (prevProps.config.items !== config.items) {
      if (prevProps.config && prevProps.config.items !== config.items) {
        this.refreshSelectedItems(config.items, config.itemKey);
      }
    }
  }

  onSelectChange(event: InputEvent, id: number) {
    const { onSelectionChanged } = this.props;
    const { selectedItems } = this.state;
    let selectedValues = [];

    if (!event.target.checked) {
      selectedValues = without(selectedItems, id);
    } else {
      selectedValues = concat(selectedItems, id);
    }

    this.setState({ selectedItems: selectedValues });
    onSelectionChanged(selectedValues);
  }

  onToggleAll(event: Object) {
    const { config, onSelectionChanged } = this.props;
    let items = [];

    if (event.target.checked && !event.target.disabled) {
      items = this.getSelectableItems().map((item) => get(item, config.itemKey));
    }

    this.setState({ selectedItems: items });
    onSelectionChanged(items);
  }

  getSelectableItems() {
    const { config } = this.props;

    return config.items;
  }

  refreshSelectedItems(items: Array<*>, itemKey = 'id') {
    this.setState((prevState) => {
      const newItems = prevState.selectedItems.filter((item) => (
        findIndex(items, (cfg) => get(cfg, itemKey) === item) > -1
      ));

      return {
        selectedItems: newItems,
      };
    });
  }

  isSelected(id: number) {
    const { selectedItems } = this.state;

    return (selectedItems.indexOf(id) > -1);
  }

  showSelectables() {
    const { config } = this.props;
    return config?.actions?.length > 0;
  }

  shouldRenderEmptyState() {
    const { emptyState } = this.props;

    return (emptyState && emptyState.showEmptyState);
  }

  calculateNumberOfColumns() {
    const { config } = this.props;

    if (this.showSelectables()) {
      return config.columns.length + 1;
    }

    return config.columns.length;
  }

  renderContent() {
    const { query, config } = this.props;

    if (query.loading) {
      return <TableLoading numberOfColumns={this.calculateNumberOfColumns()} />;
    }

    if (config.items.length === 0) {
      return this.renderNoItems();
    }

    return this.renderRows();
  }

  renderNoItems() {
    return (
      <TableRow>
        <TableCell colSpan={this.calculateNumberOfColumns()} className={style.TableEmpty}>
          <div className={style.TableEmptyContent}>
            <Message id="No items to display" comment="The default message when the list is empty" />
          </div>
        </TableCell>
      </TableRow>
    );
  }

  renderSelectionCell(item: Object) {
    const { config } = this.props;

    if (!this.showSelectables()) {
      return null;
    }

    return (
      <TableCell className={style.SelectionColumn}>
        <Checkbox
          className={style.SelectBox}
          input={{
            checked: this.isSelected(get(item, config.itemKey)),
            onChange: (event) => this.onSelectChange(event, get(item, config.itemKey)),
          }}
        />
      </TableCell>
    );
  }

  renderRows() {
    const { config } = this.props;

    return (
      map(config.items, (item) => (
        <TableRow key={get(item, config.itemKey)}>
          {this.renderSelectionCell(item)}
          {
            map(config.columns, (column) => {
              const { type, className } = column;
              return (
                <TableCell
                  actions={type === 'actions'}
                  key={`${column.name}_${get(item, config.itemKey)}`}
                  className={className}
                >
                  {column.value(item)}
                </TableCell>
              );
            })
          }
        </TableRow>
      ))
    );
  }

  renderSelectionHeader() {
    const { selectedItems } = this.state;
    const { items } = this.props.config;

    if (!this.showSelectables()) {
      return null;
    }

    return (
      <TableHeader className={style.SelectionColumn}>
        <Checkbox
          className={style.SelectBox}
          input={{
            onChange: (event) => this.onToggleAll(event),
            checked: selectedItems.length > 0 && selectedItems.length === items.length,
          }}
        />
      </TableHeader>
    );
  }

  renderTable() {
    const { config, TableClassName } = this.props;

    return (
      <table className={clsx(style.Table, TableClassName)}>
        <TableHead>
          <TableRow>
            {this.renderSelectionHeader()}
            {
              map(config.columns, (column) => {
                const { type } = column;
                return (
                  <TableHeader
                    flex={type === 'flex'}
                    actions={type === 'actions'}
                    key={column.name}
                    className={column.className}
                  >
                    {column.label}
                  </TableHeader>
                );
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {this.renderContent()}
        </TableBody>
      </table>
    );
  }

  renderEmpty() {
    const { emptyState } = this.props;

    if (this.shouldRenderEmptyState()) {
      return (
        <EmptyState
          icon={emptyState.icon}
          header={emptyState.header}
          content={emptyState.content}
          defaultStyle
          withPadding
        />
      );
    }
    return null;
  }

  render() {
    const { config, bulkActionsSpacing } = this.props;
    const { selectedItems } = this.state;

    if (this.shouldRenderEmptyState()) {
      return this.renderEmpty();
    }

    return (
      <>
        <BulkActions
          visible={this.showSelectables()}
          count={selectedItems.length}
          actions={config.actions}
          topSpacing={bulkActionsSpacing}
        />
        {this.renderTable()}
      </>
    );
  }
}

export default Table;
