import useSyncExternalStore from './use-sync-external-store';
import { useStore, State } from '../contexts/store';

const useFilterableState = <T = any>(selector: (state: State) => T) => {
  const store = useStore();
  if (!store) {
    throw new Error('useFilterableState must be used within a <StoreProvider />');
  }
  const callback = () => selector(store.snapshot());
  return useSyncExternalStore(store.subscribe, callback);
};

export default useFilterableState;
