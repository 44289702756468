import type { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';

type Props = {
  onClick?: () => void,
  isLoading?: boolean,
  disabled?: boolean,
  customClass?: string,
  children?: ReactNode,
};

export const ConfirmButton = ({
  onClick,
  isLoading,
  disabled,
  customClass,
  children = (
    <Message
      id="Confirm"
      comment="Text for button to confirm action."
    />
  ),
}: Props) => (
  <Button
    kind="primary"
    data-testid="confirm"
    icon={isLoading ? CircularSpinner : null}
    disabled={isLoading || disabled}
    onClick={onClick}
    customClass={customClass}
  >
    {children}
  </Button>
);

ConfirmButton.defaultProps = {
  disabled: undefined,
  customClass: undefined,
};
