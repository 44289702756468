import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';

import { composeRefs } from 'hooks/compose-refs';

import { useFilterableContext } from './contexts/filterable';
import { useGroup } from './contexts/group';
import useFilterableState from './hooks/use-filterable-state';
import useAsRef from './hooks/use-as-ref';
import useValue from './hooks/use-value';
import { useStore } from './contexts/store';
import { SELECT_EVENT } from './constants';
import type { DivProps, Children } from './types';

type Props = Children &
  Omit<DivProps, 'disabled' | 'onSelect' | 'value'> & {
    id: string;
    /** Whether this item is currently disabled. */
    disabled?: boolean
    /** Event handler for when this item is selected, either via click or keyboard selection. */
    onSelect?: (value: string) => void
    /**
     * A unique value for this item.
     * If no value is provided, it will be inferred from `children` or the rendered `textContent`.
     * If your `textContent` changes between renders, you _must_ provide a stable, unique `value`.
     */
    value?: string
    /** Optional keywords to match against when filtering. */
    keywords?: string[]
    /** Whether this item is forcibly rendered regardless of filtering. */
    forceMount?: boolean
  }

/**
 * Command menu item. Becomes active on pointer enter or through keyboard navigation.
 * Preferably pass a `value`, otherwise the value will be inferred from `children` or
 * the rendered item's `textContent`.
 */
const Item = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const ref = useRef<HTMLDivElement>(null);
  const groupContext = useGroup();
  const context = useFilterableContext();
  const propsRef = useAsRef(props);
  const forceMount = propsRef.current?.forceMount ?? groupContext?.forceMount;

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (!forceMount) {
      return context.item(props.id, groupContext?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceMount]);

  const value = useValue(props.id, ref, [props.value, props.children, ref], props.keywords);

  const store = useStore();
  const selected = useFilterableState((state) => state.value && state.value === value.current);
  const render = useFilterableState((state) => {
    if (forceMount || context.filter() === false || !state.search) {
      return true;
    }

    return (state.filtered.items.get(props.id) || 0) > 0;
  });

  const select = () => {
    store.setState('value', value.current, true);
  };

  const onSelect = () => {
    select();
    propsRef.current.onSelect?.(value.current);
  };

  useEffect(() => {
    const element = ref.current;
    if (!element || props.disabled) return;
    element.addEventListener(SELECT_EVENT, onSelect);
    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener(SELECT_EVENT, onSelect);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render, props.onSelect, props.disabled]);

  if (!render) return null;

  const {
    disabled,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: _,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelect: __,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forceMount: ___,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    keywords: ____,
    id,
    ...etc
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      ref={composeRefs(ref, forwardedRef)}
      {...etc}
      id={id}
      // eslint-disable-next-line react/no-unknown-property
      cmdk-item=""
      role="option"
      aria-disabled={Boolean(disabled)}
      aria-selected={Boolean(selected)}
      data-disabled={Boolean(disabled)}
      data-selected={Boolean(selected)}
      onPointerMove={disabled || context.disablePointerSelection ? undefined : select}
      onClick={disabled ? undefined : onSelect}
      tabIndex={0}
    >
      {props.children}
    </div>
  );
});

Item.displayName = 'CommandItem';

export default Item;
