/* eslint-disable import/prefer-default-export */
import SendContract from 'components/modals/send-contract';

export type FormData = { message: string; subject: string } | Record<string, never>;

type Props = {
  agreementId: Oneflow.Agreement['id'],
  onClose: () => void,
  onSaveAndNotifyChanges: (data: FormData) => void
  isOpen: boolean
}

export const SaveChangesParticipantModal = ({
  agreementId,
  onClose,
  onSaveAndNotifyChanges,
  isOpen,
}: Props) => (
  <SendContract
    agreementId={agreementId}
    isSubjectFieldRequired={false}
    isMessageFieldRequired={false}
    messageTemplatesDisabled
    context="agreementOnesave"
    isNotifyParticipants
    onModalClose={onClose}
    isOpen={isOpen}
    onSaveAndNotifyChanges={onSaveAndNotifyChanges}
  />
);
