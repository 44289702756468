import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import workspacesReducer from 'reducers/entities/workspaces';

type Props = {
  selectedWorkspaceId: number;
};

const hasDataRetentionPolicy = (dataRetentionPolicy: Oneflow.Workspace['dataRetentionPolicy'] = {}) => (
  Boolean(
    dataRetentionPolicy?.agreementDeclined
    || dataRetentionPolicy?.agreementDraft
    || dataRetentionPolicy?.agreementExpired
    || dataRetentionPolicy?.agreementTerminated,
  )
);

export const DataRetentionWarning = ({ selectedWorkspaceId }: Props) => {
  const selectedWorkspace = useSelector((state) => (
    workspacesReducer.getWorkspaceSelector(state, { id: selectedWorkspaceId })
  ));

  if (hasDataRetentionPolicy(selectedWorkspace?.dataRetentionPolicy)) {
    return (
      <div>
        <Message
          id="Note: The selected workspace has a data retention policy enabled, which means that this contract could end up being removed if it doesn't match the workspace's retention policy."
          comment="Warning text for moving contract to a workspace with data retention policy."
        />
      </div>
    );
  }

  return null;
};
