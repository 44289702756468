// @flow

import * as React from 'react';
import type { Match, Location } from 'react-router';
import { localize, Message } from '@oneflowab/pomes';
import { Route } from 'react-router';
import urlJoin from 'url-join';
import clsx from 'clsx';
import makeSearchCache from 'utils/search-cache';

import Switch from 'components/switch';
import BreadCrumb from 'components/bread-crumb';
import AppTitle from 'components/app-title';
import WorkspaceIcon from 'components/icons/workspace';
import { NotFound } from 'components/errors';

import PageRoute from './page-route';
import Tab from './tab';

import style from './admin-page.module.scss';

export type ComponentProps = AdminPageComponentProps & {
  [any]: any,
};

export type Parent = {
  path: string,
  showAsLink?: boolean,
  title: string,
};

type BaseModule = {
  id?: string,
  path: string,
  title: string,
  hasSearchCache?: boolean,
  getSearchFromCache?: Function,
  hide?: boolean,
};

type InternalModule = BaseModule & {
  isExternal?: false,
  component: React.ComponentType<ComponentProps>,
};

type ExternalModule = BaseModule & {
  isExternal: true,
  component?: React.ComponentType<ComponentProps>,
};

export type Module = InternalModule | ExternalModule;

type MapToTab = (url: string, hasSearchCache?: boolean) => Module => AdminPageTab;

type WrapperProps = AdminPageComponentProps & {
  children?: {} | React.Node,
  location: Location,
  match: Match,
  modules: Array<Array<Module>>,
  title: string,
};

type MappedProps = {
  componentDidMount?: Function,
  componentDidUpdate?: Function,
  exact?: boolean,
  hide?: boolean,
  hideLayout?: boolean,
  modules: Array<Array<Module>>,
  showAsLink?: boolean,
  title: string,
};

type InputProps = {
  props: {
    [any]: any,
  },
};

export type PropsMapper = (args?: InputProps) => MappedProps;

export const getDisplayName = (WrappedPage: React.ComponentType<ComponentProps>) => {
  const displayName = WrappedPage.displayName || WrappedPage.name || 'Component';

  return `AdminPage(${displayName})`;
};

export const mapToTab: MapToTab = (url: string, hasSearchCache?: boolean) => ({
  id,
  path,
  title,
  isExternal = false,
  getSearchFromCache,
  hide,
  event,
  isBeta = false,
}: Module) => {
  const tabPath = urlJoin(url, path);
  const tab: AdminPageTab = {
    id,
    label: title,
    path: tabPath,
    isExternal,
    hide,
    event,
    isBeta,
  };
  if (getSearchFromCache) {
    tab.getSearchFromCache = getSearchFromCache;
  }
  if (hasSearchCache) {
    tab.getSearchFromCache = makeSearchCache(tabPath);
  }
  return tab;
};

export const mapToLink = (parent: Parent) => ({
  to: parent.path,
  label: parent.title,
  showAsLink: parent.showAsLink,
});

export const getAdminPageComponent = (
  propsMapper: PropsMapper,
  WrappedPage: React.ComponentType<ComponentProps>,
) => {
  class WrapperComponent extends React.PureComponent<WrapperProps> {
    static displayName = getDisplayName(WrappedPage);

    static defaultProps = {
      tabs: [],
      path: undefined,
      parents: [],
      pageTitle: [],
      children: undefined,
    };

    mappedProps = propsMapper({
      props: this.props,
    });

    componentDidMount() {
      this.mappedProps = propsMapper({
        props: this.props,
      });

      if (this.mappedProps.componentDidMount) {
        this.mappedProps.componentDidMount();
      }
    }

    // TODO: convert this component to functional component
    // eslint-disable-next-line react/no-deprecated
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps: WrapperProps) {
      this.mappedProps = propsMapper({
        props: nextProps,
      });
    }

    componentDidUpdate(prevProps: WrapperProps) {
      const { componentDidUpdate } = this.mappedProps;

      if (componentDidUpdate) {
        componentDidUpdate(prevProps);
      }
    }

    getWorkspaceName = (workspace: Workspace) => {
      if (workspace.virtual) {
        return (
          <Message
            id="Shared with me"
            comment="The virtual workspace name."
          />
        );
      }

      return workspace.name;
    }

    isExact() {
      return this.mappedProps.exact === undefined ? true : this.mappedProps.exact;
    }

    pageTitle() {
      const { pageTitle = [] } = this.props;

      return [...pageTitle, this.mappedProps.title].join(' | ');
    }

    parentInfo() {
      const { isMainPage } = this.props;
      let { parents = [] } = this.props;

      if (isMainPage && parents[0]?.path.includes('admin')) {
        parents = parents.slice(1);
      }

      if (this.mappedProps.hide) {
        return parents;
      }

      return [
        ...parents,
        {
          path: this.mappedProps.path || this.props.match.url,
          title: this.mappedProps.title,
          showAsLink: this.mappedProps.showAsLink,
        },
      ];
    }

    renderSection() {
      const {
        section,
      } = this.props;

      if (!section) {
        return null;
      }

      return (
        <span className={style.Title}>
          {section}
        </span>
      );
    }

    renderWorkspace() {
      const {
        showWorkspace,
        workspace,
      } = this.props;

      if (!(showWorkspace && workspace)) {
        return null;
      }

      return (
        <div
          className={style.WorkspaceContainer}
        >
          <WorkspaceIcon height="10px" />
          <span>{this.getWorkspaceName(workspace)}</span>
        </div>
      );
    }

    renderTabs() {
      const { tabs = [], location, isStandalone } = this.props;

      if (isStandalone) {
        return null;
      }

      return (
        <div className={style.TabsContainer}>
          {
            tabs.map<React.Node>((tab: AdminPageTab) => (
              <Tab key={tab.path} {...tab} location={location} />
            ))
          }
        </div>
      );
    }

    renderBreadCrumb() {
      const {
        hideBreadCrumb,
        isMainPage,
        isStandalone,
        parents = [],
      } = this.props;
      const label = this.mappedProps.title;

      if (isMainPage) {
        return null;
      }

      if (isStandalone || hideBreadCrumb) {
        return null;
      }

      return (
        <BreadCrumb
          label={label}
          links={parents.map(mapToLink)}
        />
      );
    }

    renderInnerContent() {
      const {
        isMainPage,
        location,
      } = this.props;
      const isCalendarPage = location.pathname.includes('calendar');
      const isOnAdminPage = location.pathname.includes('admin') || location.pathname.includes('marketplace');
      const isOnAddressBookPage = location.pathname.includes('address-book');

      return (
        <>
          {this.renderSection()}
          {this.renderWorkspace()}
          {this.renderBreadCrumb()}
          <div className={style.MainPage}>
            <div className={clsx(style.Content, { [style.CalendarPage]: isCalendarPage })}>
              {
                (
                  (!isMainPage && (isOnAdminPage || isOnAddressBookPage))
                  || (isMainPage && !isOnAdminPage))
                  ? this.renderTabs() : null
              }
              <div className={style.Container}>
                <WrappedPage {...this.props} />
              </div>
            </div>
          </div>
        </>
      );
    }

    renderPage() {
      const { isStandalone } = this.props;
      if (this.mappedProps.hideLayout) {
        return <WrappedPage {...this.props} />;
      }

      if (isStandalone) {
        return this.renderInnerContent();
      }

      return (
        <div className={style.AdminPage}>
          {this.renderInnerContent()}
        </div>
      );
    }

    renderContent() {
      const { match } = this.props;

      return (
        <Route exact={this.isExact()} path={match.path}>
          {this.renderPage()}
        </Route>
      );
    }

    renderRoutes() {
      const { pageTitle = [], match } = this.props;

      return (
        this.mappedProps.modules.map<Array<React.Node>>(
          (module: Array<Module>) => module.map<React.Node>((route: Module) => (
            <PageRoute
              path={urlJoin(match.path, route.path)}
              route={route}
              parents={this.parentInfo()}
              pageTitle={[...pageTitle, this.mappedProps.title]}
              tabs={module.map(mapToTab(match.url, route.hasSearchCache))}
              match={match}
            />
          )),
        )
      );
    }

    render() {
      const { forceRender } = this.props;

      if (forceRender) {
        return (
          <>
            <AppTitle title={this.pageTitle()} />
            {this.renderInnerContent()}
          </>
        );
      }

      if (this.isExact()) {
        return (
          <>
            <AppTitle title={this.pageTitle()} />
            <Switch>
              {this.renderContent()}
              {this.renderRoutes()}
              <NotFound redirect to="/dashboard" />
            </Switch>
          </>
        );
      }

      return (
        <>
          <AppTitle title={this.pageTitle()} />
          {this.renderContent()}
          {this.renderRoutes()}
        </>
      );
    }
  }

  return WrapperComponent;
};

const adminPage = (propsMapper: PropsMapper) => (
  WrappedPage: React.ComponentType<ComponentProps>,
) => {
  /* istanbul ignore next line */
  const AdminPageComponent = getAdminPageComponent(propsMapper, WrappedPage);
  /* istanbul ignore next line */
  return localize<WrapperProps>(AdminPageComponent);
};

export default adminPage;
