import { localize, MessageTranslator } from '@oneflowab/pomes';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import type { FormProps, FormRenderProps } from 'react-final-form';

import agreementsReducer from 'reducers/entities/agreements';
import ModalForm from 'hocs/modal-form';
import { hasParticipantEmail } from 'agreement/agreement-participants';
import { DELIVERY_CHANNEL_SAME_DEVICE } from 'agreement/participant/constants';

import Message from 'components/message';
import { EmailField } from 'components/fields';
import Button from 'components/button';
// eslint-disable-next-line import/named
import { CancelButton } from 'components/buttons';
import CircularSpinnerIcon from 'components/icons/circular-spinner';

import style from './fill-guest-information.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  message: MessageTranslator
  agreement: Oneflow.Agreement;
  participant: Oneflow.Participant;
  openSignModal: () => void;
  guestToken: string;
};

type FormValues = {
  email: string;
  countryData: string;
};

const FillGuestInformation = ({
  isOpen,
  onClose,
  message,
  agreement,
  participant,
  openSignModal,
  guestToken,
}: Props) => {
  const updateParticipantState = useSelector(
    (state) => agreementsReducer.getUpdateParticipantSelector(
      state, { id: participant.id },
    ),
  );
  const dispatch = useDispatch();

  const uniqueEmailValidation = ({ params }: { params: { email: string }}) => hasParticipantEmail(
    { params, agreementId: agreement.id },
  );

  const renderBody = () => (
    <div>
      <p className={style.Description}>
        <Message
          id="Enter contact details to receive a copy of the signed document once all parties have signed."
          comment="Description for sign on same device modal"
        />
      </p>
      <EmailField
        unique={{
          text: message({
            id: 'A person with that email address already exists in the contract.',
            comment: 'Form validation error message.',
          }),
          param: 'email',
          request: uniqueEmailValidation,
          initialValue: '',
        }}
        required={false}
      />
    </div>
  );

  const renderButtonText = (formProps: FormRenderProps<FormValues>) => {
    if (isEmpty(formProps.errors) && (formProps.values.email)) {
      return (
        <Message id="Proceed to sign" comment="Button label for proceeding to the sign step" />
      );
    }
    return <Message id="Skip" comment="Button label for skipping the step" />;
  };

  const onSubmit = (formProps: FormProps<FormValues>) => {
    if (isEmpty(formProps.errors) && formProps.email) {
      dispatch(agreementsReducer.updateParticipant({
        id: participant.id,
        data: {
          agreement: agreement.id,
          email: formProps.email,
          token: participant.deliveryChannel !== DELIVERY_CHANNEL_SAME_DEVICE
            ? guestToken : undefined,
        },
        pipe: {
          onSuccess: () => {
            dispatch(agreementsReducer.fetchAgreement(({
              id: agreement.id,
              params: { guestToken },
              pipe: {
                onSuccess: () => {
                  onClose();
                  openSignModal();
                },
              },
            })));
          },
        },
      }));
    } else {
      onClose();
      openSignModal();
    }
  };

  const renderActions = ({ closeConfirmation, formProps }:
    {closeConfirmation: () => void, formProps: FormRenderProps<FormValues>}) => {
    const buttonDisabled = Boolean(formProps?.errors?.email);
    const buttonClasses = clsx(style.SkipButton, {
      [style.Disabled]: buttonDisabled,
    });
    return (
      <div className={style.ActionsContainer}>
        <CancelButton onClick={closeConfirmation} />
        <Button
          customClass={buttonClasses}
          disabled={buttonDisabled || updateParticipantState?.loading}
          icon={updateParticipantState?.loading ? CircularSpinnerIcon : null}
          onClick={formProps.handleSubmit}
        >
          {renderButtonText(formProps)}
        </Button>
      </div>
    );
  };

  return (
    <ModalForm
      title={<Message id="Provide contact details" comment="The title of the modal" />}
      body={renderBody()}
      actions={renderActions}
      isOpen={isOpen}
      modalKey="sign-on-same-device"
      onClose={onClose}
      onSubmit={onSubmit}
      formState={updateParticipantState}
    />
  );
};

export default localize<Props>(FillGuestInformation);
