// @flow

import * as React from 'react';
import { uniqueId } from 'lodash';

import createAudioContext from 'utils/audio-context';
import MicrophoneOff from 'components/icons/microphone-off';
import Button from 'components/button';

import style from './microphone-control.module.scss';

type Props = {
  src: MediaStream,
}

type State = {
  volume: number,
  enabled: boolean,
  muted: boolean,
  ended: boolean,
}

const isMuted = (mediaStream: MediaStream) => (
  mediaStream.getAudioTracks().every((track) => track.muted)
);

const isEnabled = (mediaStream: MediaStream) => (
  mediaStream.getAudioTracks().every((track) => track.enabled)
);

const isEnded = (mediaStream: MediaStream) => (
  mediaStream.getAudioTracks().every((track) => track.readyState === 'ended')
);

class MicrophoneControl extends React.Component<Props, State> {
  audioContext: AudioContext;

  constructor(props: Props) {
    super(props);

    const { src } = this.props;

    this.state = {
      volume: 0,
      enabled: isEnabled(src),
      muted: isMuted(src),
      ended: isEnded(src),
    };
  }

  componentDidMount() {
    const { src } = this.props;

    this.audioContext = createAudioContext();
    this.scriptProcessor = this.audioContext.createScriptProcessor(4096, 1, 1);
    this.mediaStreamAudioSource = this.audioContext.createMediaStreamSource(src);

    this.mediaStreamAudioSource.connect(this.scriptProcessor);
    this.scriptProcessor.connect(this.audioContext.destination);
    this.scriptProcessor.addEventListener('audioprocess', this.onAudioProcess);

    src.getAudioTracks().forEach((track) => {
      track.addEventListener('mute', this.setMuted);
      track.addEventListener('unmute', this.setUnmuted);
      track.addEventListener('ended', this.setEnded);
    });
  }

  componentWillUnmount() {
    const { src } = this.props;

    this.mediaStreamAudioSource.disconnect(this.scriptProcessor);
    this.scriptProcessor.disconnect(this.audioContext.destination);
    this.scriptProcessor.removeEventListener('audioprocess', this.onAudioProcess);

    src.getAudioTracks().forEach((track) => {
      track.removeEventListener('mute', this.setMuted);
      track.removeEventListener('unmute', this.setUnmuted);
      track.removeEventListener('ended', this.setEnded);
    });
  }

  onAudioProcess = (event: any) => {
    const { enabled, muted, ended } = this.state;

    if (!enabled || muted || ended) {
      return;
    }

    const { volume } = this.state;
    const averaging = 0.90;
    const buffer = event.inputBuffer.getChannelData(0);
    const sum = buffer.reduce((acc, cur) => acc + (cur * cur));
    const rms = Math.sqrt(Math.abs(sum) / buffer.length);
    const newVolume = Math.max(rms, volume * averaging);

    this.setVolume(newVolume);
  }

  setVolume = (volume: number) => {
    this.setState({
      volume,
    });
  }

  getNormalizedVolume = () => {
    const { volume } = this.state;

    const MIN_VOLUME = 0;
    const MAX_VOLUME = 12;

    return Math.min(MAX_VOLUME, Math.max(MIN_VOLUME, volume * 100 * 1.4));
  }

  setEnded = () => {
    this.setState({
      ended: true,
    });
  }

  setMuted = () => {
    this.setState({
      muted: true,
    });
  }

  setUnmuted = () => {
    this.setState({
      muted: false,
    });
  }

  toggleEnabled = () => {
    this.setState((prevState: State) => ({
      enabled: !prevState.enabled,
    }), () => {
      const { src } = this.props;

      src.getAudioTracks().forEach((track) => {
        // We need to reassign the `enabled` property,
        // that's how its API works
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/enabled
        // eslint-disable-next-line no-param-reassign
        track.enabled = this.state.enabled;
      });
    });
  }

  scriptProcessor: ScriptProcessorNode;

  mediaStreamAudioSource: MediaStreamAudioSourceNode;

  renderVolumeMeter() {
    const normalizedVolume = this.getNormalizedVolume();

    if (normalizedVolume <= 1.5) {
      return null;
    }

    const MAX_Y_POSITION = 12.5;
    const y = (MAX_Y_POSITION - normalizedVolume).toFixed(2);

    return (
      <rect
        className={style.VolumeMeter}
        fill="currentColor"
        stroke="none"
        x="5.5"
        y={y}
        width="7"
        height={normalizedVolume.toFixed(2)}
        rx="3"
        ry="3"
      />
    );
  }

  renderIcon = () => {
    const { enabled, muted, ended } = this.state;
    const id = uniqueId('clip0_2837_19904');

    if (!enabled || muted || ended) {
      return <MicrophoneOff className={style.MicrophoneIcon} />;
    }

    return (
      <svg className={style.MicrophoneIcon} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px" stroke="currentColor" viewBox="0 0 18 18" fill="none">
        {this.renderVolumeMeter()}
        <g clipPath={`url(#${id})`}>
          <path
            d="M9 14.75V17.5M9 12.55V12.55C10.933 12.55 12.5 10.983 12.5 9.05V4.5C12.5 2.567 10.933 1 9 1V1C7.067 1 5.5 2.56701 5.5 4.5V9.05C5.5 10.983 7.067 12.55 9 12.55Z"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M15 7.79413V9.25001C15 10.7087 14.3679 12.1077 13.2426 13.1391C12.1174 14.1706 10.5913 14.75 9 14.75C7.4087 14.75 5.88258 14.1706 4.75736 13.1391C3.63214 12.1077 3 10.7087 3 9.25001V7.79413"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  render() {
    const { muted, ended } = this.state;

    return (
      <div className={style.MicrophoneControl}>
        <Button
          onClick={this.toggleEnabled}
          customClass={style.Button}
          icon={this.renderIcon}
          disabled={muted || ended}
        />
      </div>
    );
  }
}

export default MicrophoneControl;
