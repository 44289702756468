import clsx from 'clsx';
import { localize, MessageTranslator } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import { isStaticDataPoint } from '../utils';
import { CONTRACT_NOT_ANALYSED_COLOR } from '../constants';

import style from './legend.module.scss';

type Entry = {
  payload: {
    label: string,
    labelKey: string,
    dataKey?: string,
  },
  color: string,
};

const getCursor = (entry: Entry) => {
  if (isStaticDataPoint(entry.payload.labelKey) || entry.color === CONTRACT_NOT_ANALYSED_COLOR) {
    return 'default';
  }
  return 'pointer';
};

type Props = {
  payload: Entry[],
  onMouseEnter: (key: string) => void,
  onMouseLeave: () => void,
  message: MessageTranslator,
  onClick: (labelKey: string) => void,
  getLegendLabel: (label: string, message: MessageTranslator, currentLanguage: string) => string,
  wrapperClassName?: string,
};

const LegendComponent = ({
  payload,
  onMouseEnter,
  onMouseLeave,
  message,
  onClick,
  getLegendLabel,
  wrapperClassName,
}: Props) => {
  const currentLanguage = useSelector(getCurrentLanguageSelector);

  return (
    <ul
      className={clsx(style.LegendWrapper, wrapperClassName)}
    >
      {payload.map((entry: Entry) => {
        const { payload: { label, dataKey }, color } = entry;
        const key = label || dataKey || '';

        return (
          <li key={`item-${label || dataKey}`}>
            <button
              onMouseEnter={() => onMouseEnter(key)}
              onMouseLeave={onMouseLeave}
              onClick={() => onClick(key)}
              style={{ cursor: getCursor(entry) }}
            >
              <span
                className={style.LegendColor}
                style={{ backgroundColor: color }}
              />
              {getLegendLabel(key, message, currentLanguage)}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export const CustomLegend = localize(LegendComponent);
