import { head } from 'lodash';

import * as PARTICIPANT_CONSTANTS from 'agreement/participant/constants';

export const getAvailableSignMethodIds = (agreement: Oneflow.Agreement): number[] => (
  agreement.config?.enabledDeliveryChannels || agreement.availableOptions?.deliveryChannels
);

export const getDefaultDeliveryChannel = (agreement: Oneflow.Agreement): number => (
  agreement.config?.defaultDeliveryChannel || agreement.availableOptions?.deliveryChannels[0]
);

export const getDeliveryChannelPayload = (
  agreement: Oneflow.Agreement,
  selectedDefaultDeliveryChannel: number,
) => {
  type Payload = {
    defaultDeliveryChannel: number;
    defaultSignMethod?: number;
  }
  const payload: Payload = {
    defaultDeliveryChannel: selectedDefaultDeliveryChannel,
  };
  const sameDeviceDeliveryChannelSelected = (
    selectedDefaultDeliveryChannel === PARTICIPANT_CONSTANTS.DELIVERY_CHANNEL_SAME_DEVICE
  );
  const esignSignMethodSelected = (
    agreement.config?.defaultSignMethod === PARTICIPANT_CONSTANTS.SIGN_METHOD_ESIGN
  );

  if (sameDeviceDeliveryChannelSelected && esignSignMethodSelected) {
    // We have to change the sign method to SMS or another
    const availableSignMethods: number[] = agreement.config?.enabledSignMethods
      || agreement.availableOptions?.signMethods;
    if (availableSignMethods.includes(PARTICIPANT_CONSTANTS.SIGN_METHOD_SMS)) {
      payload.defaultSignMethod = PARTICIPANT_CONSTANTS.SIGN_METHOD_SMS;
    } else {
      const nextSignMethod = head(
        availableSignMethods.filter((signMethod) => signMethod !== 0),
      );
      payload.defaultSignMethod = nextSignMethod;
    }
  }

  return payload;
};
