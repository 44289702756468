import * as zod from 'zod';

import {
  dateSchema,
  timestampSchema,
} from '../utils';

import { aclValue } from './common';
import Entity, { NumberIdSchema } from './entity';

export const workspaceAclSchema = zod.object({
  'collection:update:type': aclValue.optional(),
  'collection:update:name': aclValue.optional(),
  'collection:update:description': aclValue.optional(),
  'collection:update:branding': aclValue.optional(),
  'collection:view': aclValue.optional(),
  'collection:remove': aclValue.optional(),
  'collection:module:dashboard:use': aclValue.optional(),
  'collection:module:agreement:use': aclValue.optional(),
  'collection:module:template:use': aclValue.optional(),
  'collection:module:addressbook:use': aclValue.optional(),
  'collection:agreement:message:template:view': aclValue.optional(),
  'collection:agreement:template:create': aclValue.optional(),
  'collection:agreement:template:view': aclValue.optional(),
  'collection:agreement:template:move': aclValue.optional(),
  'collection:agreement:template:share': aclValue.optional(),
  'collection:agreement:export': aclValue.optional(),
  'collection:agreement:trash:permanently_delete': aclValue.optional(),
  'collection:agreement:trash:view': aclValue.optional(),
  'collection:message:template:create': aclValue.optional(),
  'collection:message:template:view': aclValue.optional(),
  'collection:agreement:create': aclValue.optional(),
  'collection:agreement:create_blank': aclValue.optional(),
  'collection:agreement:import': aclValue.optional(),
  'collection:agreement:view': aclValue.optional(),
  'collection:agreement:move': aclValue.optional(),
  'collection:addressbook:create': aclValue.optional(),
  'collection:addressbook:view': aclValue.optional(),
  'collection:dashboard:view:own': aclValue.optional(),
  'collection:dashboard:view:colleague': aclValue.optional(),
  'collection:data_retention_policy:update': aclValue.optional(),
  'collection:position:binding:create': aclValue.optional(),
  'collection:position:binding:remove': aclValue.optional(),
  'collection:position:binding:view': aclValue.optional(),
  'collection:group:binding:create': aclValue.optional(),
  'collection:group:binding:remove': aclValue.optional(),
  'collection:group:binding:view': aclValue.optional(),
  'collection:folder:view': aclValue.optional(),
  'collection:folder:create': aclValue.optional(),
  'collection:folder:update': aclValue.optional(),
  'collection:folder:remove': aclValue.optional(),
  'collection:internal_reminder:create': aclValue.optional(),
  'collection:ai_review:view': aclValue.optional(),
  'collection:ai_import:use': aclValue.optional(),
});

const Workspace = Entity.extend({
  acl: workspaceAclSchema.optional(),
  name: zod.string().nullable().optional(),
  account: NumberIdSchema.describe('agreementAccount').nullable().optional(),
  brandingDateFormat: zod.string().nullable().optional(),
  brandingCountry: zod.string().nullable().optional(),
  brandingName: zod.string().nullable().optional(),
  brandingOrgnr: zod.string().nullable().optional(),
  created: dateSchema.nullable().optional(),
  createdTs: timestampSchema.nullable().optional(),
  currency: zod.string().nullable().optional(),
  description: zod.string().nullable().optional(),
  logo: zod.object({
    id: zod.number().nullable().optional(),
    url: zod.string().nullable().optional(),
  }).nullable().optional(),
  type: zod.string().nullable().optional(),
  updated: dateSchema.nullable().optional(),
  updatedTs: timestampSchema.nullable().optional(),
  virtual: zod.boolean().nullable().optional(),
  dataRetentionPolicy: zod.object({
    agreementDeclined: zod.boolean().nullable().optional(),
    agreementDraft: zod.boolean().nullable().optional(),
    agreementExpired: zod.boolean().nullable().optional(),
    agreementTerminated: zod.boolean().nullable().optional(),
  }).nullable().optional(),
}).describe('workspace');

export default Workspace;
