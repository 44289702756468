import type { ReactNode } from 'react';
import BoxEmptyDraftState from 'components/box-empty-draft-state';
import ShoppingCartIcon from 'components/icons/shopping-cart';

type Props = {
  isAllowedToEdit: boolean,
  onClick: () => void,
  buttonText: ReactNode,
}

const EmptyProductTableBox = ({
  isAllowedToEdit,
  onClick,
  buttonText,
}: Props) => (
  <div>
    <BoxEmptyDraftState
      icon={ShoppingCartIcon}
      onClick={onClick}
      isDisabled={!isAllowedToEdit}
      buttonText={buttonText}
    />
  </div>
);

export default EmptyProductTableBox;
