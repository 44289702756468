import style from './feature.module.scss';

type Props = {
  icon: React.ReactNode;
  label: React.ReactNode
}

export const Feature = ({ icon, label }: Props) => (
  <div className={style.FeatureContainer}>
    <div className={style.Icon}>
      {icon}
    </div>
    <span className={style.Label}>{label}</span>
  </div>
);
