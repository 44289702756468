// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Transition } from 'react-transition-group';

import styles from './slide-in.module.scss';

type TimeoutProps = {|
  enter?: number,
  exit?: number,
|};

type TransitionState = 'entering' | 'entered' | 'exiting' | 'exited';

type Props = {
  children: React.Node,
  in: boolean,
  unmountOnExit?: boolean,
  timeout?: number | TimeoutProps,
};

class SlideIn extends React.PureComponent<Props> {
  static defaultProps = {
    timeout: {
      enter: 0,
      exit: 100,
    },
    unmountOnExit: true,
  }

  renderChildren = (transitionState: TransitionState) => {
    const { children } = this.props;

    return (
      <div className={clsx(
        styles.SlideIn,
        {
          [styles.Enter]: transitionState === 'entering',
          [styles.EnterActive]: transitionState === 'entered',
          [styles.Exit]: transitionState === 'exiting',
          [styles.ExitActive]: transitionState === 'exited',
        },
      )}
      >
        {children}
      </div>
    );
  }

  render() {
    const { children, ...transitionProps } = this.props;

    return (
      <Transition {...transitionProps}>
        {this.renderChildren}
      </Transition>
    );
  }
}

export default SlideIn;
