import { put, call } from 'redux-saga/effects';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  grantUserAccountAccess,
  removeUserAccountAccess,
  getUserAccountBindings,
  getUsersAccountBindings,
} from 'oneflow-client/user-accounts';

import apiWrapper from 'sagas/api-wrapper';

import userAccountsReducer from 'reducers/entities/user-accounts';

export function* mapper({ data }) {
  yield put(userAccountsReducer.setUserAccounts(data.entities.userAccountBindings));
}
export function* grantUserAccountAccessMapper({ data }) {
  yield put(userAccountsReducer.setUserAccounts(data.entities.userAccountBindings));
}

export function* removeUserAccountAccessMapper({ action }) {
  yield put(userAccountsReducer.clearUserAccount({ id: action.id }));
}

export function* updateMapper({ action }) {
  yield put(userAccountsReducer.clearUserAccount({ id: action.data.removeData.id }));
}

export function* updateRequest({ removeData, createData }) {
  yield call(grantUserAccountAccess, createData);
  yield call(removeUserAccountAccess, removeData);
}

const mappers = {
  query: {
    mapper,
    request: getUserAccountBindings,
  },
  update: {
    mapper: updateMapper,
    request: updateRequest,
  },
  rpcs: {
    grantUserAccountAccess: {
      name: 'grantUserAccountAccess',
      mapper: grantUserAccountAccessMapper,
      request: grantUserAccountAccess,
    },
    removeUserAccountAccess: {
      name: 'removeUserAccountAccess',
      mapper: removeUserAccountAccessMapper,
      request: removeUserAccountAccess,
    },
    getUsersAccountBindings: {
      name: 'getUsersAccountBindings',
      mapper: grantUserAccountAccessMapper,
      request: getUsersAccountBindings,
    },
  },
};

const userAccountsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: userAccountsReducer,
  mappers,
});

export default userAccountsSagas;
