/* eslint-disable no-param-reassign */
import {
  Transforms,
  Element,
  Editor,
  Node,
} from 'slate';

import { unwrapAnnotations } from './annotation-plugin';

const withAnnotations = (editor) => {
  const { normalizeNode, isInline } = editor;

  editor.isInline = (element) => element.type === 'annotation' || isInline(element);

  editor.getFragment = () => {
    const { selection } = editor;

    if (selection) {
      return unwrapAnnotations(Node.fragment(editor, selection));
    }
    return [];
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === 'annotation' && Editor.isEmpty(editor, node)) {
      Transforms.unwrapNodes(editor, { at: path });
      return;
    }

    normalizeNode(entry);
  };

  return editor;
};

export default withAnnotations;
