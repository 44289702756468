import type { ReactNode, CSSProperties, ChangeEvent } from 'react';
import clsx from 'clsx';
import { uniq } from 'lodash';

import Checkbox from 'components/checkbox';

import style from './checkbox-group.module.scss';

export type FilterOption = {
  value: number,
  label: ReactNode,
  disabled?: boolean,
  tooltipMessage?: ReactNode,
  tooltipOnLabel?: boolean,
  customLabelStyle?: CSSProperties,
};

type Props = {
  onChange: (value: number[] | null, checkboxGroupName?: string) => void,
  value?: number[] | null,
  options: FilterOption[],
  disabled?: boolean,
  reverseDirection?: boolean,
  tooltipMessage?: ReactNode,
  customClass?: string,
  checkboxGroupName?: string,
};

const CheckboxGroupComponent = ({
  onChange,
  value: selectedValues,
  options,
  disabled,
  reverseDirection,
  tooltipMessage,
  customClass,
  checkboxGroupName,
}: Props) => {
  const isValueSelected = (value: number) => !!selectedValues && selectedValues.includes(value);

  const onValueChange = (option: FilterOption) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = option;
    const { checked } = event.target;

    let changedValues = [...(selectedValues || [])];

    if (checked) {
      changedValues.push(value);
    } else {
      changedValues = changedValues.filter((item) => item !== value);
    }

    onChange(uniq(changedValues), checkboxGroupName);
  };

  const renderLabel = (
    label: string | ReactNode,
    customLabelStyle?: CSSProperties,
  ) => {
    const className = clsx(style.Label, {
      [style.DisabledLabel]: disabled,
    });

    return <span className={className} style={customLabelStyle}>{label}</span>;
  };

  const renderOption = (option: FilterOption) => {
    const {
      value,
      label,
      tooltipMessage: optionTooltipMessage,
      tooltipOnLabel,
      customLabelStyle,
    } = option;

    return (
      <div className={style.FilterRow} key={value}>
        <Checkbox
          input={{
            value,
            checked: isValueSelected(value),
            onChange: onValueChange(option),
          }}
          label={renderLabel(label, customLabelStyle)}
          handleTextOverflow
          disabled={disabled || option.disabled}
          reverseDirection={reverseDirection}
          tooltipMessage={tooltipMessage || optionTooltipMessage}
          tooltipOnLabel={tooltipOnLabel}
          customClass={customClass}
        />
      </div>
    );
  };

  return <>{options.map(renderOption)}</>;
};

export default CheckboxGroupComponent;
