import MarkAsSignedAction from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons/mark-as-signed';
import {
  SaveButton,
  DiscardButton,
} from './buttons';

type Props = {
  agreementId: Oneflow.Document['id']
}

const DiscardSaveImportActions = ({ agreementId }: Props) => (
  <>
    <DiscardButton />
    <SaveButton agreementId={agreementId} />
    <MarkAsSignedAction
      agreementId={agreementId}
      disabled
    />
  </>
);

export default DiscardSaveImportActions;
