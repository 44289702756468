import { localize, MessageTranslator } from '@oneflowab/pomes';

import getCSSPropertyValue from 'utils/get-css-property-value';

import style from './overlay-signature-field.module.scss';

type Props = {
  fullName?: string,
  message: MessageTranslator,
}

const PendingSignatureComponent = ({
  fullName,
  message,
}: Props) => {
  const fontSize = 14;
  const charWidth = fontSize * 0.6;
  const padding = 10;

  const text:string = fullName ?? String(message({ id: 'Signature', comment: 'Default signature name' }));

  const textWidth = text?.length * charWidth;
  const textHeight = fontSize;
  const viewBoxWidth = textWidth + 2 * padding;
  const viewBoxHeight = textHeight * 2 + 2 * padding;
  const fillColor = `${getCSSPropertyValue('--of-green-13')}`;

  return (
    <svg
      className={style.SignatureContent}
      fill="none"
      height="100%"
      width="100%"
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform={`translate(${viewBoxWidth / 2}, ${viewBoxHeight / 2})`}
      >
        <text
          className={style.ParticipantName}
          fill={fillColor}
          fontSize={fontSize}
          x="0"
          y="0"
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight={500}
        >
          {text}
        </text>
      </g>
    </svg>
  );
};

export const PendingSignature = localize<Props>(PendingSignatureComponent);
