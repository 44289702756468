// @flow

import React, { useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import { getAgreementMyParticipant } from 'agreement/selectors';
import {
  isChatEnabled,
  isCommentsEnabled,
  isSuggestionsEnabled,
} from 'comments';
import { SUGGESTION_MESSAGE, WRITTEN_MESSAGE, INLINE_COMMENT_MESSAGE } from 'comments/constants';
import useAgreement from 'hooks/use-agreement';
import { isSmallScreenWidth } from 'ui/config';

import type { AmplitudeData } from 'components/messages-layout/single-message-box/single-message-box';
import { scrollToElement } from 'components/document-tabs/messages-tab/post-message/auto-scroll';
import Button from 'components/button';
import PostMessage from 'components/document-tabs/messages-tab/post-message';

import style from './message-reply.module.scss';

type Props = {
  agreementId: number,
  parentMessage: Object,
  containerRef: any,
  amplitudeData?: AmplitudeData,
  isInteractive?: boolean,
  setIsRecipientSelectorOpen: () => void,
};

const MessageReply = ({
  agreementId,
  parentMessage,
  containerRef,
  amplitudeData,
  isInteractive,
  setIsRecipientSelectorOpen,
}: Props) => {
  const isSmallScreen = isSmallScreenWidth();
  const showReplyForm = isSmallScreen && !isEmpty(parentMessage) && !isInteractive;
  const amplitudeScope = amplitudeData?.location;
  const agreement = useAgreement(agreementId);
  const [isReplyFormOpen, setIsReplyFormOpen] = useState(false);
  const myParticipant = getAgreementMyParticipant(agreement);
  const parentCanBeRepliedTo = (
    (parentMessage.type === WRITTEN_MESSAGE && isChatEnabled(agreement, myParticipant))
    || (parentMessage.type === INLINE_COMMENT_MESSAGE && isCommentsEnabled(agreement))
    || (parentMessage.type === SUGGESTION_MESSAGE && isSuggestionsEnabled(agreement))
  );
  const hideReplyButton = (
    isEmpty(myParticipant)
    || !parentCanBeRepliedTo
  );

  const replyFormClasses = clsx(style.ReplyForm, {
    [style.ReplyFormExpanded]: isReplyFormOpen,
    [style.ReplyFormCollapsed]: !isReplyFormOpen,
  });

  const replyButtonClasses = clsx(style.ReplyButton, {
    [style.HiddenReply]: isReplyFormOpen,
  });

  const onPostMessage = () => {
    setIsReplyFormOpen(false);
    setTimeout(() => {
      scrollToElement(containerRef?.current, {
        block: 'end',
      });
    }, 200);
  };

  const onReply = (event) => {
    event.stopPropagation();
    setIsReplyFormOpen(true);

    setTimeout(() => {
      scrollToElement(containerRef?.current, {
        block: 'end',
      });
    }, 100);
  };

  if (hideReplyButton) {
    return null;
  }

  if (showReplyForm) {
    return (
      <PostMessage
        agreementId={agreementId}
        onPostMessage={onPostMessage}
        parentMessage={parentMessage}
        setIsRecipientSelectorOpen={setIsRecipientSelectorOpen}
        isInteractive={isInteractive}
      />
    );
  }

  return (
    <div className={replyFormClasses}>
      <PostMessage
        agreementId={agreementId}
        autoFocus
        hidden={!isReplyFormOpen}
        onBlur={debounce(() => setIsReplyFormOpen(false), 100)}
        onPostMessage={onPostMessage}
        parentCanBeRepliedTo={parentCanBeRepliedTo}
        parentMessage={parentMessage}
        amplitudeScope={amplitudeScope}
        isInteractive={isInteractive}
      />
      <Button
        onClick={onReply}
        customClass={replyButtonClasses}
      >
        <Message id="Reply" comment="Used as a button label for replying to a comment" />
      </Button>
    </div>
  );
};

export default MessageReply;
