// @flow

import * as React from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { useVideoBoxProps } from 'contexts/video-box-props';

import CircularSpinner from 'components/icons/circular-spinner';
import Button from 'components/button';
import Remove from 'components/icons/remove';

import style from './loading-video-box.module.scss';

type Props = {
  message: React.Node;
  onCancelProcessing: Function,
}

const LoadingVideoBox = ({
  message,
  onCancelProcessing,
}: Props) => {
  const { box } = useVideoBoxProps();
  const width = get(box, 'config.width');

  return (
    <div
      className={clsx(style.LoadingVideo, {
        [style.Small]: width === 'sm',
      })}
    >
      <div className={style.CircularSpinner}>
        <CircularSpinner width="45px" height="45px" />
      </div>
      <div className={style.Messages}>
        {message}
        <div className={style.Actions}>
          <Button
            onClick={onCancelProcessing}
            icon={Remove}
            data-testid="cancel-processing"
            kind="special"
            customClass={style.Rounded}
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingVideoBox;
