import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import webSocket from 'web-socket';
// eslint-disable-next-line import/named
import { ApiError, getErrorMessage, unknownApiError } from 'components/api-error';
import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';
import { SignCodeState } from 'components/modals/sign-contract/electronic-id-sign/electronic-id-sign';
import style from './generic-bankid.module.scss';

type SignState = {
  error: any,
  success: boolean,
  loading: boolean
};

type State = {
  signState: SignState
};

export type Props = {
  agreementId: number,
  myParticipantWhenSignatory: AgreementParticipant,
  signCodeState: SignCodeState,
  changeButtonStatus: (prop: boolean) => void,
  changeSignErrorStatus: () => void,
  onStepComplete: () => void,
  onSuccess: () => void
};

export default class GenericBankId extends React.PureComponent<Props, State> {
  state = {
    signState: {
      error: undefined,
      loading: false,
      success: false,
    },
  }

  componentDidMount() {
    const { signCodeState: { data } } = this.props;

    if (!data) {
      return;
    }

    this.subscribeToBankIdPending();
    this.subscribeToBankIdError();
    this.subscribeToSignState();
    window.open(data.authorizationUrl, '_blank');
  }

  componentWillUnmount() {
    this.clearSubscriptions();
  }

  onSignBankIdPending = (responseData: any) => {
    const {
      changeButtonStatus,
      changeSignErrorStatus,
    } = this.props;

    if (!this.state.signState.loading) {
      changeButtonStatus(true);
      changeSignErrorStatus();
    }

    this.setState({
      signState: {
        error: undefined,
        loading: true,
        success: false,
        response: responseData.response,
      },
    });
  }

  onSignBankIdError = (responseData: any) => {
    const {
      changeButtonStatus,
      changeSignErrorStatus,
    } = this.props;

    if (responseData.response) {
      changeButtonStatus(false);
      changeSignErrorStatus();
    }

    this.setState({
      signState: {
        error: responseData.response,
        loading: false,
        success: false,
        response: '',
      },
    });
  }

  clearSubscriptions = () => {
    this.clearBankIdPendingSubscription();
    this.clearBankIdErrorSubscription();
    this.clearSignStateSubscription();
  }

  subscribeToBankIdPending = () => {
    const {
      myParticipantWhenSignatory: { id: participantId },
    } = this.props;

    this.unsubscribeToBankIdPending = webSocket.subscribe({
      channelName: `private-agreement-participant-${participantId}`,
      event: 'sign:eid:pending',
      eventCallback: this.onSignBankIdPending,
    });
  };

  subscribeToBankIdError = () => {
    const {
      myParticipantWhenSignatory: { id: participantId },
    } = this.props;

    this.unsubscribeToBankIdError = webSocket.subscribe({
      channelName: `private-agreement-participant-${participantId}`,
      event: 'sign:eid:error',
      eventCallback: this.onSignBankIdError,
    });
  };

  subscribeToSignState = () => {
    const { agreementId } = this.props;

    this.unsubscribeToSignState = webSocket.subscribe({
      channelName: `private-agreement-${agreementId}`,
      event: 'agreement:participant:sign',
      eventCallback: this.handleSuccess,
    });
  };

  handleContinueClick = () => {
    this.clearSubscriptions();
    this.resetSignState();
    this.subscribeToBankIdPending();
    this.subscribeToBankIdError();
    this.subscribeToSignState();
  }

  handleSuccess = () => {
    this.props.onSuccess();
    this.props.onStepComplete();
  }

  resetSignState = () => {
    this.setState({
      signState: {
        error: undefined,
        loading: false,
        success: false,
        response: '',
      },
    });
  }

  getErrorMessage = (error: any) => {
    const errorMessage = getErrorMessage(error);

    if (!errorMessage) {
      return unknownApiError;
    }

    return errorMessage;
  }

  renderApiError = () => {
    const { signState: { error } } = this.state;
    if (!error) {
      return null;
    }

    return (
      <div className={style.ErrorWrapper}>
        <ApiError customMessage={this.getErrorMessage(error)} />
      </div>
    );
  };

  clearBankIdPendingSubscription() {
    if (this.unsubscribeToBankIdPending) {
      this.unsubscribeToBankIdPending();
    }
  }

  clearBankIdErrorSubscription() {
    if (this.unsubscribeToBankIdError) {
      this.unsubscribeToBankIdError();
    }
  }

  clearSignStateSubscription() {
    if (this.unsubscribeToSignState) {
      this.unsubscribeToSignState();
    }
  }

  unsubscribeToBankIdPending: any;

  unsubscribeToBankIdError: any;

  unsubscribeToSignState: any;

  renderLoading = () => (
    <div className={style.Loading}>
      <CircularSpinner />
    </div>
  );

  renderSignIsLoading = () => {
    const { signState } = this.state;

    if (!signState.loading) {
      return null;
    }

    return this.renderLoading();
  };

  render() {
    const { signCodeState } = this.props;
    const { signState } = this.state;

    if (signState.error) {
      return this.renderApiError();
    }

    if (signCodeState.loading || !signCodeState.data) {
      return this.renderLoading();
    }

    const { authorizationUrl } = signCodeState.data;

    return (
      <div className={style.BankIdContainer}>
        {this.renderSignIsLoading()}
        <Message
          id="A new window has been opened to complete the signing. If a new window didn't open, please click the button below."
          comment="Used to "
        />
        <Button
          color="thunder"
          data-dismiss="modal"
          onClick={this.handleContinueClick}
          href={authorizationUrl}
          external
          target="_blank"
          customClass={style.ContinueButton}
        >
          <Message
            id="Continue"
            comment="A text for a button that is used to show the link for signing using electronic IDs"
          />
        </Button>
      </div>
    );
  }
}
