// @flow

import { normalize, schema } from 'normalizr';

import {
  get,
  getMultiple,
  post,
  put,
  remove,
  hasAnyEntity,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

const contactSchema = new schema.Entity('contacts', {
});

export type NormalizedContacts = {
  entities: {
    contacts: {
      [number]: Contact,
    },
  },
  result: number | Array<number>,
  count: number,
};

type ContactsNormalizer = (any) => NormalizedContacts;

export const normalizeContacts: ContactsNormalizer = (contacts) => ({
  ...normalize(contacts.collection, [contactSchema]),
  count: contacts.count,
});

export const normalizeContact: ContactsNormalizer = (contact) => ({
  ...normalize(contact, contactSchema),
  count: 1,
});

type GetContacts = ({
  params: {
    workspaceId: number,
  },
  pagination?: {},
}) => Promise<NormalizedContacts>;

export const getContacts: GetContacts = ({
  pagination,
  params: {
    workspaceId,
    q,
    country,
  },
}) => (
  getMultiple({
    url: '/address_book/persons/',
    pagination,
    params: {
      collection_id: workspaceId,
      q,
      country,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeContacts)
);

type GetContact = ({
  id: number,
}) => Promise<NormalizedContacts>;

export const getContact: GetContact = ({ id }) => (
  get({ url: `/address_book/persons/${id}` })
    .then(extractResponseBodyAsJSON)
    .then(normalizeContact)
);

type CreateContact = ({
  fullname: string,
  title?: string,
  email: string,
  ssn?: string,
  country?: string,
  phoneNumber?: string,
  companyName?: string,
  companyOrgnr?: string,
  workspaceId: number,
}) => Promise<NormalizedContacts>;

export const createContact: CreateContact = ({
  fullname,
  title,
  email,
  ssn,
  phoneNumber,
  companyName,
  companyOrgnr,
  country,
  notes,
  workspaceId,
}) => (
  post({
    url: '/address_book/persons/',
    body: {
      fullname,
      title,
      email,
      ssn,
      phone_number: phoneNumber,
      company_name: companyName,
      company_orgnr: companyOrgnr,
      country,
      notes,
      collection_id: workspaceId,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeContact)
);

type UpdateContact = ({
  id: number,
  fullname?: string,
  title?: string,
  email: string,
  ssn?: string,
  phoneNumber?: string,
  companyName?: string,
  companyOrgnr?: string,
  country?: string,
  notes?: string,
}) => Promise<NormalizedContacts>;

export const updateContact: UpdateContact = ({
  id,
  fullname,
  title,
  email,
  ssn,
  phoneNumber,
  companyName,
  companyOrgnr,
  country,
  notes,
}) => (
  put({
    url: `/address_book/persons/${id}`,
    body: {
      fullname,
      title,
      email,
      ssn,
      phone_number: phoneNumber,
      company_name: companyName,
      company_orgnr: companyOrgnr,
      country,
      notes,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeContact)
);

type DeleteContacts = ({
  ids: Array<number>,
}) => Promise<{}>;

export const deleteContacts: DeleteContacts = ({ ids }) => (
  remove({
    url: '/address_book/persons/',
    body: { ids },
  })
    .then(extractResponseBodyAsJSON)
);

export const hasAnyContact = hasAnyEntity({ url: '/address_book/persons/' });
