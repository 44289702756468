// @flow

type GetTypeSpecificConfig = Extension => {};

// eslint-disable-next-line import/prefer-default-export
export const getTypeSpecificConfig: GetTypeSpecificConfig = (extension) => {
  const { typeSpecificConfigKeys } = extension;
  if (!typeSpecificConfigKeys) {
    return {};
  }

  const config = {};
  typeSpecificConfigKeys.forEach((key) => {
    config[key] = null;
  });

  return config;
};
