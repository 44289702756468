/* eslint-disable import/named */
import { PureComponent } from 'react';

import {
  MultistepModal,
  ModalStep,
  ModalStepRenderProps,
} from 'components/modal';
import { SigningCompleted } from 'components/modals/signing-completed';

import {
  hasSignMethodEidSign,
  hasSignMethodElectronicIdExceptSwedishBankId,
  hasSignMethodHandwritten,
  hasSignMethodSMS,
  hasSignMethodSwedishBankId,
} from 'agreement/participant';

import SignContractConfirm from './confirm';
import SignContractElectronicIdOptions from './electronic-id-options';
import SignContractElectronicIdSign from './electronic-id-sign';
import SignContractSms from './sms';
import SignContractSmsSign from './sms-sign';
import Handwritten from './handwritten';
import SwedishBankIDV2 from './swedish-bankid/swedish-bankid-v2';

export type ModalData = {
  ssn: string
};

export type Props = {
  agreement: Oneflow.Agreement,
  canSign: boolean,
  children?: any,
  fetchAgreement: (arg1: any) => void,
  guestToken?: string,
  isOpen?: boolean,
  myParticipantWhenSignatory: Oneflow.Participant,
  sendChecksum?: boolean,
  onClose?: any,
};

type State = {
  isOpen: boolean
};

const SIGN_CONTRACT_INTENT_MODAL = 'sign contract intent modal';
const SIGN_CONTRACT_SMS_MODAL = 'sign contract sms modal';
const SIGN_CONTRACT_SMS_PENDING_MODAL = 'sign contract sms pending modal';
const SIGN_CONTRACT_COMPLETED_MODAL = 'sign contract completed modal';
const SIGN_CONTRACT_EID_PENDING_MODAL = 'sign contract eid pending modal';
const SIGN_CONTRACT_HANDWRITTEN_MODAL = 'sign contract handwritten modal';

class SignContractComponent extends PureComponent<Props, State> {
  static defaultProps = {
    guestToken: undefined,
    sendChecksum: undefined,
    isOpen: undefined,
    children: undefined,
    onClose: undefined,
  };

  state = {
    isOpen: this.props.isOpen || false,
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ isOpen: false });

    if (onClose) {
      onClose();
    }
  };

  handleFinalStep = (onStepComplete: () => void) => () => {
    onStepComplete();
    this.handleClose();
  };

  getAgreement = () => {
    const { agreement } = this.props;
    return agreement;
  }

  getChildren = () => {
    const { canSign, children } = this.props;
    const onClick = this.handleOpen;

    if (!children) {
      return null;
    }

    return children({ onClick, canSign });
  };

  handleOnSuccess = () => {
    const {
      fetchAgreement,
      guestToken,
    } = this.props;

    fetchAgreement(guestToken);
  }

  getGuestToken = () => {
    const { guestToken } = this.props;
    return guestToken;
  }

  getSendChecksum = () => {
    const { sendChecksum } = this.props;
    return sendChecksum;
  }

  getParticipant = () => this.props.myParticipantWhenSignatory;

  getSignMethod = () => this.props.myParticipantWhenSignatory.signMethod;

  renderElectronicIdOptionsModal: ModalStepRenderProps = ({
    onStepComplete,
    onSyncStepData,
  }) => (
    <SignContractElectronicIdOptions
      agreement={this.getAgreement()}
      onClose={this.handleClose}
      onStepComplete={onStepComplete}
      onSyncStepData={onSyncStepData}
      myParticipantWhenSignatory={this.getParticipant()}
    />
  );

  renderElectronicIdSignModal: ModalStepRenderProps = ({
    onPreviousStep,
    onStepComplete,
    onSyncStepData,
    modalData,
  }) => (
    <SignContractElectronicIdSign
      agreement={this.getAgreement()}
      onClose={this.handleClose}
      onPreviousStep={onPreviousStep}
      onStepComplete={onStepComplete}
      onSuccess={this.handleOnSuccess}
      onSyncStepData={onSyncStepData}
      guestToken={this.getGuestToken()}
      modalData={modalData}
      myParticipantWhenSignatory={this.getParticipant()}
      modalKey={SIGN_CONTRACT_EID_PENDING_MODAL}
    />
  );

  renderSmsModal: ModalStepRenderProps = ({
    onStepComplete,
    onSyncStepData,
    modalData,
    onPreviousStep,
  }) => (
    <SignContractSms
      onClose={this.handleClose}
      onPreviousStep={onPreviousStep}
      onStepComplete={onStepComplete}
      onSyncStepData={onSyncStepData}
      modalData={modalData}
      myParticipantWhenSignatory={this.getParticipant()}
      modalKey={SIGN_CONTRACT_INTENT_MODAL}
    />
  );

  renderSmsSignModal: ModalStepRenderProps = ({
    onStepComplete,
    onSyncStepData,
    modalData,
    onPreviousStep,
  }) => (
    <SignContractSmsSign
      agreement={this.getAgreement()}
      onPreviousStep={onPreviousStep}
      onClose={this.handleClose}
      onSuccess={this.handleOnSuccess}
      onStepComplete={onStepComplete}
      onSyncStepData={onSyncStepData}
      modalData={modalData}
      myParticipantWhenSignatory={this.getParticipant()}
      guestToken={this.getGuestToken()}
      sendChecksum={this.getSendChecksum()}
      modalKey={SIGN_CONTRACT_SMS_PENDING_MODAL}
    />
  );

  renderConfirmModal: ModalStepRenderProps = ({
    onStepComplete,
    onPreviousStep,
  }) => (
    <SignContractConfirm
      sendChecksum={this.getSendChecksum()}
      agreement={this.getAgreement()}
      onPreviousStep={onPreviousStep}
      onStepComplete={onStepComplete}
      myParticipantWhenSignatory={this.getParticipant()}
      onClose={this.handleClose}
      onSuccess={this.handleOnSuccess}
      guestToken={this.getGuestToken()}
      buttonKind={this.getButtonKind()}
      modalKey={SIGN_CONTRACT_INTENT_MODAL}
    />
  );

  renderHandwrittenModal: ModalStepRenderProps = ({
    onStepComplete,
    onPreviousStep,
    onSyncStepData,
    modalData,
  }) => (
    <Handwritten
      agreement={this.getAgreement()}
      onClose={this.handleClose}
      onPreviousStep={onPreviousStep}
      onStepComplete={onStepComplete}
      onSuccess={this.handleOnSuccess}
      onSyncStepData={onSyncStepData}
      guestToken={this.getGuestToken()}
      modalData={modalData}
      myParticipantWhenSignatory={this.props.myParticipantWhenSignatory}
      sendChecksum={this.getSendChecksum()}
      modalKey={SIGN_CONTRACT_HANDWRITTEN_MODAL}
    />
  );

  renderSuccessModal: ModalStepRenderProps = ({ onStepComplete }) => (
    <SigningCompleted
      agreementId={this.getAgreement().id}
      isOpen
      onClose={this.handleFinalStep(onStepComplete)}
      guestToken={this.getGuestToken()}
      modalKey={SIGN_CONTRACT_COMPLETED_MODAL}
    />
  );

  getButtonKind = () => {
    if (
      hasSignMethodHandwritten(this.getParticipant())
      || hasSignMethodEidSign(this.getParticipant())
    ) {
      return 'primary';
    }

    return 'document-sign';
  };

  render() {
    const { isOpen } = this.state;
    const { myParticipantWhenSignatory } = this.props;

    if (isOpen && myParticipantWhenSignatory) {
      if (hasSignMethodSMS(myParticipantWhenSignatory)) {
        return (
          <>
            <MultistepModal
              isOpen
              onCancel={this.handleClose}
            >
              <ModalStep modalKey={SIGN_CONTRACT_INTENT_MODAL}>
                {this.renderConfirmModal}
              </ModalStep>
              <ModalStep modalKey={SIGN_CONTRACT_SMS_MODAL}>
                {this.renderSmsModal}
              </ModalStep>
              <ModalStep modalKey={SIGN_CONTRACT_SMS_PENDING_MODAL}>
                {this.renderSmsSignModal}
              </ModalStep>
              <ModalStep modalKey={SIGN_CONTRACT_COMPLETED_MODAL}>
                {this.renderSuccessModal}
              </ModalStep>
            </MultistepModal>
            {this.getChildren()}
          </>
        );
      }

      if (hasSignMethodSwedishBankId(myParticipantWhenSignatory)) {
        return (
          <SwedishBankIDV2
            agreementId={this.getAgreement().id}
            sendChecksum={this.getSendChecksum()}
            handleClose={this.handleClose}
            handleOnSuccess={this.handleOnSuccess}
            renderSuccessModal={this.renderSuccessModal}
          >
            {this.getChildren()}
          </SwedishBankIDV2>
        );
      }

      if (hasSignMethodEidSign(myParticipantWhenSignatory)) {
        return (
          <>
            <MultistepModal
              isOpen
              onCancel={this.handleClose}
            >
              <ModalStep>
                {this.renderConfirmModal}
              </ModalStep>
              <ModalStep>
                {this.renderElectronicIdOptionsModal}
              </ModalStep>
              <ModalStep>
                {this.renderElectronicIdSignModal}
              </ModalStep>
              <ModalStep>
                {this.renderSuccessModal}
              </ModalStep>
            </MultistepModal>
            {this.getChildren()}
          </>
        );
      }

      if (hasSignMethodElectronicIdExceptSwedishBankId(myParticipantWhenSignatory)) {
        return (
          <>
            <MultistepModal
              isOpen
              onCancel={this.handleClose}
            >
              <ModalStep modalKey={SIGN_CONTRACT_INTENT_MODAL}>
                {this.renderConfirmModal}
              </ModalStep>
              <ModalStep modalKey={SIGN_CONTRACT_EID_PENDING_MODAL}>
                {this.renderElectronicIdSignModal}
              </ModalStep>
              <ModalStep modalKey={SIGN_CONTRACT_COMPLETED_MODAL}>
                {this.renderSuccessModal}
              </ModalStep>
            </MultistepModal>
            {this.getChildren()}
          </>
        );
      }

      if (hasSignMethodHandwritten(myParticipantWhenSignatory)) {
        return (
          <>
            <MultistepModal
              isOpen
              onCancel={this.handleClose}
              preventClose
            >
              <ModalStep modalKey={SIGN_CONTRACT_INTENT_MODAL}>
                {this.renderConfirmModal}
              </ModalStep>
              <ModalStep modalKey={SIGN_CONTRACT_HANDWRITTEN_MODAL}>
                {this.renderHandwrittenModal}
              </ModalStep>
              <ModalStep modalKey={SIGN_CONTRACT_COMPLETED_MODAL}>
                {this.renderSuccessModal}
              </ModalStep>
            </MultistepModal>
            {this.getChildren()}
          </>
        );
      }

      return (
        <>
          <MultistepModal
            isOpen
            onCancel={this.handleClose}
          >
            <ModalStep modalKey={SIGN_CONTRACT_INTENT_MODAL}>
              {this.renderConfirmModal}
            </ModalStep>
            <ModalStep modalKey={SIGN_CONTRACT_COMPLETED_MODAL}>
              {this.renderSuccessModal}
            </ModalStep>
          </MultistepModal>
          {this.getChildren()}
        </>
      );
    }

    return this.getChildren();
  }
}

export default SignContractComponent;
