import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import type { ChangeEventHandler } from 'react';
import { get } from 'lodash';

import { usePdfBoxProps } from 'contexts/pdf-box-props';
import useDebouncedCallback from 'hooks/use-debounced-callback';

const sanitizeValue = (value: string): string => value.replace(/[\r\n]+/g, '');

function useControlledField(
  dataFieldObject: DataField,
): {
  displayValue: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>,
} {
  const dataFieldValue = get(dataFieldObject?.value, 'value', '');

  const [displayValue, setDisplayValue] = useState<string>(() => sanitizeValue(dataFieldValue));
  const { updateDataFieldObject } = usePdfBoxProps();

  const onFieldValueChangeDebounced = useDebouncedCallback((newFieldValue) => {
    updateDataFieldObject(dataFieldObject, newFieldValue as string);
  }, [
    dataFieldObject,
    updateDataFieldObject,
  ]);

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((event) => {
    const updateValue = sanitizeValue(event.target.value);
    setDisplayValue(updateValue);

    onFieldValueChangeDebounced(updateValue);
  }, [onFieldValueChangeDebounced]);

  // Update the visible data when the value is updated outside the component
  useEffect(() => {
    setDisplayValue(sanitizeValue(dataFieldValue));
  }, [dataFieldValue]);

  return {
    displayValue,
    onChange,
  };
}

export default useControlledField;
