// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import RemoveIcon from 'components/icons/remove';
import ControlRecord from 'components/icons/control-record';
import ControlStop from 'components/icons/control-stop';
import ControlPause from 'components/icons/control-pause';

import style from './camera-controls.module.scss';

type Props = {|
  isRecording: boolean,
  isPaused: boolean,
  disabled: boolean,
  onStart: () => void,
  onPause: () => void,
  onResume: () => void,
  onStop: () => void,
  onCancel: () => void,
|};

class CameraControls extends React.Component<Props> {
  renderStart() {
    const {
      disabled,
      onStart,
    } = this.props;

    return (
      <Button
        disabled={disabled}
        onClick={onStart}
        customClass={style.Record}
        icon={ControlRecord}
        data-testid="start-recorder"
      />
    );
  }

  renderStop() {
    const {
      disabled,
      onStop,
    } = this.props;

    return (
      <Button
        disabled={disabled}
        onClick={onStop}
        customClass={style.Stop}
        icon={ControlStop}
        data-testid="stop-recorder"
      />
    );
  }

  renderCancel() {
    const {
      disabled,
      onCancel,
    } = this.props;

    return (
      <Button
        disabled={disabled}
        onClick={onCancel}
        icon={RemoveIcon}
        data-testid="cancel-recorder"
        kind="special"
        customClass={style.Rounded}
      />
    );
  }

  renderResume() {
    const {
      disabled,
      onResume,
    } = this.props;

    return (
      <Button
        disabled={disabled}
        onClick={onResume}
        icon={ControlRecord}
        data-testid="resume-recorder"
        kind="special"
      />
    );
  }

  renderPause() {
    const {
      disabled,
      onPause,
    } = this.props;

    return (
      <Button
        disabled={disabled}
        onClick={onPause}
        icon={ControlPause}
        data-testid="pause-recorder"
        kind="special"
        customClass={style.Rounded}
      />
    );
  }

  renderControls() {
    const {
      isRecording,
      isPaused,
    } = this.props;

    if (!isRecording) {
      return (
        <React.Fragment
          key="inactive"
        >
          <div className={style.Placeholder} />
          {this.renderStart()}
          {this.renderCancel()}
        </React.Fragment>
      );
    }

    if (isPaused) {
      return (
        <React.Fragment
          key="paused"
        >
          {this.renderResume()}
          {this.renderStop()}
          {this.renderCancel()}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment
        key="recording"
      >
        {this.renderPause()}
        {this.renderStop()}
        {this.renderCancel()}
      </React.Fragment>
    );
  }

  render() {
    const { isRecording } = this.props;

    return (
      <div className={style.CameraControls}>
        <div className={clsx(style.StartRecordingText, { [style.Recording]: isRecording })}>
          <Message
            id="Press to start recording"
            comment="The text allow the user to record a video they should click on the button."
          />
        </div>
        <div className={style.CameraControlsContainer}>
          {this.renderControls()}
        </div>
      </div>
    );
  }
}

export default CameraControls;
