import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { localize, Message } from '@oneflowab/pomes';
import { get, isEmpty } from 'lodash';
import { Location } from 'history';
import { FormProps } from 'react-final-form';

import {
  INVITE_USER_QUERY, USER_ACCOUNT_ROLE_ADMIN_ID, USER_ROLE_SCOPE_WORKSPACE, USER_ROLE_SCOPE_ACCOUNT,
} from 'user';
import { getAccountFromSessionSelector } from 'reducers/session';
import accountSeatsReducer from 'reducers/entities/account-seats';
// eslint-disable-next-line import/no-named-as-default
import workspacesReducer from 'reducers/entities/workspaces';
import rolesReducer from 'reducers/entities/roles';
import groupsReducer from 'reducers/entities/groups';
import positionsReducer from 'reducers/entities/positions';
import { getLocationSelector } from 'reducers/router';
import { hasAvailableSeats } from 'account';

import useFeatureFlag from 'hooks/use-feature-flag';
import usePrevious from 'hooks/use-previous';

import ModalForm from 'hocs/modal-form';

import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import SingleUserActionsAddIcon from 'components/icons/single-user-actions-add';
import { checkAcl } from 'components/acl';
import InviteUserBody from './invite-user-body';

import style from './invite-user.module.scss';

type Props = {
  isDisabled: boolean,
  inviteSuccessHandler: () => void,
};

type SubmitData = {
  fullname: string,
  email: string,
  workspaces: Oneflow.Workspace[] | []
  workspaceRole: Oneflow.Role,
  workspaceRoleReadOnly: Oneflow.Role,
  accountRole: Oneflow.Role,
  accountRoleCheckbox?: boolean,
  language: { label: string, value: string },
  userRole: { label: 'Licensed user' | 'Limited access user', value: 'licensed' | 'limited' },
  groups: Oneflow.Group[],
};

type FormState = {
  data: object,
  error: object | undefined,
  loading: boolean,
  pristine: boolean,
  result: number[] | undefined,
  success: boolean,
};

type GetActionsType = {
  closeConfirmation: () => void,
  formProps: FormProps<SubmitData>,
};

export const QUERY_NAME = 'admin/users';
export const WORKSPACES_QUERY_NAME = 'InviteUser/workspaces';
export const WORKSPACE_ROLES_QUERY_NAME = 'InviteUser/roles';
export const ACCOUNT_ROLES_QUERY_NAME = 'InviteUser/accountRoles';
export const GROUPS_QUERY_NAME = 'InviteUser/groups';

const defaultPagination: RequestParams.Pagination = {
  limit: 6,
  offset: 0,
};

export const getGroupIds = (groups: Oneflow.Group[] | Oneflow.Group | undefined) => {
  if (!groups) {
    return [];
  }

  if (!Array.isArray(groups)) {
    return [groups.id];
  }

  return groups.map(({ id }) => id);
};

export const InviteUserComponent = ({
  isDisabled,
  inviteSuccessHandler,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isPositionLimitedFeatureEnabled = useFeatureFlag('temporaryPositionLimited');
  const [modalConfig, setModalConfig] = useState({
    isLimitedSelected: false,
    isWorkspaceSelected: false,
    readOnlyWorkspaceRoleId: null,
  });

  const account = useSelector(getAccountFromSessionSelector);
  const formState: FormState = useSelector(positionsReducer.getCreateSelector);

  const location: Location = useSelector(getLocationSelector);
  const accountSeats = useSelector((state) => (
    accountSeatsReducer.getAccountSeatSelector(state, { id: account.id })
  ));
  const hasAvailableSeatsCheck = hasAvailableSeats(accountSeats);

  const dispatch = useDispatch();
  const resetFormState = () => dispatch(positionsReducer.createPositionReset());
  const queryWorkspaces = () => {
    dispatch(workspacesReducer.queryWorkspaces({
      name: WORKSPACES_QUERY_NAME,
      pagination: {
        limit: 50,
      },
      params: {
        workspaceAdmin: 1,
        canBind: 1,
      },
    }));
  };
  const queryWorkspaceRoles = () => {
    dispatch(rolesReducer.queryRoles({
      name: WORKSPACE_ROLES_QUERY_NAME,
      params: {
        scope: USER_ROLE_SCOPE_WORKSPACE,
      },
      sort: ['-type', 'name'],
    }));
  };
  const queryAccountRoles = () => {
    dispatch(rolesReducer.queryRoles({
      name: ACCOUNT_ROLES_QUERY_NAME,
      params: {
        scope: USER_ROLE_SCOPE_ACCOUNT,
      },
      sort: ['-type', 'name'],
    }));
  };
  const queryGroups = ({ pagination = defaultPagination }) => {
    dispatch(groupsReducer.queryGroups({
      name: GROUPS_QUERY_NAME,
      pagination,
    }));
  };

  const onSubmit = ({
    fullname,
    email,
    workspaces = [],
    workspaceRole,
    accountRole,
    accountRoleCheckbox,
    language,
    userRole,
    groups,
  }: SubmitData) => {
    const allWorkspaces = workspaces.map((workspace) => workspace.id);
    const { isLimitedSelected, readOnlyWorkspaceRoleId } = modalConfig;
    // if account role checkbox is ticked, we want accountRole to be admin(id=1)
    let accountRoleId: number | undefined = accountRole?.id;
    if (accountRoleCheckbox) {
      accountRoleId = USER_ACCOUNT_ROLE_ADMIN_ID;
    }

    let workspaceRoleId: number | null = workspaceRole?.id;
    if (isLimitedSelected) {
      workspaceRoleId = readOnlyWorkspaceRoleId;
      accountRoleId = undefined;
    }

    dispatch(positionsReducer.createPosition({
      data: {
        fullname,
        email,
        workspaceIds: allWorkspaces,
        ...(allWorkspaces.length) && { workspaceRoleId },
        accountRoleId,
        groupIds: isLimitedSelected ? [] : getGroupIds(groups),
        language: language?.value || language,
        userRole: get(userRole, 'value') || userRole,
      },
      pipe: {
        action: () => positionsReducer.queryPositionsReload({
          name: QUERY_NAME,
        }),
      },
    }));
  };

  const openModalBasedOnLocation = useCallback((prevLocation: Location | null) => {
    const modalQuery = get(location, 'query.m');

    if (modalQuery === get(prevLocation, 'query.m')) {
      return;
    }

    setIsOpen(modalQuery === INVITE_USER_QUERY);
  }, [location]);

  const prevFormStateSuccess = usePrevious(formState)?.success;
  const formStateSuccess = formState?.success;
  const prevLocation: Location | null = usePrevious(location);

  useEffect(() => {
    openModalBasedOnLocation(prevLocation);

    if (!prevFormStateSuccess && formStateSuccess && inviteSuccessHandler) {
      inviteSuccessHandler();
    }
  }, [
    prevFormStateSuccess,
    inviteSuccessHandler,
    formStateSuccess,
    openModalBasedOnLocation,
    prevLocation,
  ]);

  const hasAccessToGroupField = checkAcl(account.acl, 'account:admin:group');

  const runInitialQueries = () => {
    queryWorkspaces();
    queryWorkspaceRoles();
    queryAccountRoles();

    if (hasAccessToGroupField) {
      queryGroups({});
    }
    const isLimitedSelectedValue = !hasAvailableSeatsCheck && isPositionLimitedFeatureEnabled;
    setModalConfig({ ...modalConfig, isLimitedSelected: isLimitedSelectedValue });
  };

  const updateQueryString = (
    queryString: string,
  ) => {
    const newLocation = `${location.pathname}?${queryString}`;

    dispatch(push(newLocation));
  };

  const resetState = () => {
    updateQueryString('');
    setModalConfig({ ...modalConfig, isWorkspaceSelected: false, readOnlyWorkspaceRoleId: null });
  };

  const getChildren = (onClick: () => void) => (
    <Button
      data-testid="invite-user"
      icon={SingleUserActionsAddIcon}
      kind="primary"
      onClick={onClick}
      disabled={isDisabled}
      trackable="Go To Invite New User"
    >
      <Message
        id="Invite user"
        comment="Used as the text of the button to invite a new user"
      />
    </Button>
  );

  const getActions = ({ closeConfirmation, formProps }: GetActionsType) => {
    const customConfirmButtonClass = 'AppcuesExploreOnYourOwn';

    return (
      <div>
        <div className={style.ActionButtons}>
          <CancelButton
            onClick={closeConfirmation}
            className={style.CancelButtonDelete}
          />
          <Button
            color="yellow"
            data-testid="confirm"
            onClick={formProps.handleSubmit}
            disabled={formState.loading || !isEmpty(formProps.errors)}
            customClass={customConfirmButtonClass}
          >
            <Message
              id="Send invitation"
              comment="Button label used to confirm sending invite to user in a modal"
            />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Invite user"
          comment="Modal title for inviting user"
        />
      )}
      body={(
        <InviteUserBody
          account={account}
          defaultPagination={defaultPagination}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
      onSubmit={onSubmit}
      resetFormState={resetFormState}
      formState={formState}
      isWideModal
      onOpen={runInitialQueries}
      onClose={resetState}
      isOpen={isOpen}
      modalKey="invite user modal"
      actions={getActions}
      shouldRenderStaticScrollbar
    >
      {getChildren}
    </ModalForm>
  );
};

export default localize(InviteUserComponent);
