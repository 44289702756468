// @flow

import React from 'react';
import Button from 'components/button';
import Bulb from 'components/icons/bulb';
import style from './ai-assist.module.scss';

type Props = {
  suggestion: JSX.Element,
  setText: () => void,
};

const AIAssistSuggestion = ({ suggestion, setText }: Props) => {
  const handleOnClick = () => {
    // after we've decided the format of the examples refactor this function
    const text = suggestion.props.id;
    const formattedText = text.substring(1, text.length - 1);
    setText(formattedText);
  };

  return (
    <Button className={style.SuggestionBox} onClick={handleOnClick}>
      <Bulb width="32px" height="32px" className={style.BulbIcon} />
      <p className={style.SuggestionText}>
        {suggestion}
      </p>
    </Button>
  );
};

export default AIAssistSuggestion;
