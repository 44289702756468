// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getExtensionsById } from 'reducers/entities/extensions';
import webhooksReducer from 'reducers/entities/webhooks';

import ExtensionsWebhooks from './webhooks';
import type { Props } from './webhooks';

export const QUERY_NAME = '/admin/account/extensions/webhooks';

type StateProps = {|
  webhooks: Array<Webhook>,
  webhooksQuery: Query,
  extensionsById: {
    [number]: Extension,
  },
|};

type DispatchProps = {|
  queryWebhooks: () => void,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const webhooksQuery = webhooksReducer.getQuerySelector(state, { name: QUERY_NAME });

  return {
    webhooksQuery,
    webhooks: webhooksReducer.getWebhooksSelector(state, { ids: webhooksQuery.result }),
    extensionsById: getExtensionsById(state),
  };
};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  queryWebhooks: () => {
    dispatch(webhooksReducer.queryWebhooks({
      name: QUERY_NAME,
    }));
  },
});

export default connect<Props, {||}, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(ExtensionsWebhooks);
