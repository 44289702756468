import * as zod from 'zod';

import Entity from './entity';
import Participant from './participant';
import { aclValue } from './common';

import {
  integerBoolean,
} from '../utils';

export const partyAclSchema = zod.object({
  'party:update:country': aclValue.optional(),
  'party:update:name': aclValue.optional(),
  'party:update:orgnr': aclValue.optional(),
});

const Party = Entity.extend({
  acl: partyAclSchema.nullish().optional(),
  name: zod.string().nullable().optional(),
  state: zod.number().nullable().optional(),
  // refine api used in integerBoolean and hence not possible to use nullish
  self: zod.optional(integerBoolean),
  consumer: zod.number().nullable().optional(),
  country: zod.string().nullable().optional(),
  created: zod.string().nullable().optional(),
  createdTs: zod.number().nullable().optional(),
  email: zod.string().nullable().optional(),
  // refine api used in integerBoolean and hence not possible to use nullish
  participants: zod.array(Participant).nullable().optional(),
  individual: zod.optional(integerBoolean),
  orgnr: zod.string().nullable().optional(),
  phoneNumber: zod.string().nullable().optional(),
  stateTimestamp: zod.string().nullable().optional(),
  stateTimestampTs: zod.number().nullable().optional(),
  updated: zod.string().nullable().optional(),
  updatedTs: zod.number().nullable().optional(),
}).describe('party');

export default Party;
