import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import agreements from 'reducers/entities/agreements';

import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';

import { isDraft, isTemplate } from 'agreement';
import { Message } from '@oneflowab/pomes';

import { TabHeader } from 'components/document-tabs/components/tab-header';
import { ScrollableArea } from 'components/document-tabs/components/scrollable-area';
import ErrorBoundary from 'components/error-boundary';
import CloseSidebarButton from 'components/expanded-layout-sidebar/close-sidebar-button';
import SidebarErrorMessage from 'components/sidebar/sidebar-error-message';

import getEventList from './event-list';
import Event from './event';
import style from './audit-trail-tab.module.scss';
import Insight from './insight';

const showDraftAndTemplateMessage = (agreement: Oneflow.Agreement, showEmptyMessage: boolean) => {
  if (isDraft(agreement) && showEmptyMessage) {
    return (
      <div className={style.InfoMessage} data-testid="audit-trail-not-yet-available">
        <Message
          id="No events to display yet."
          comment="Message in sidebar audit trail tab when in a draft and there are no events to show."
        />
      </div>
    );
  }
  if (isTemplate(agreement)) {
    return (
      <div className={style.InfoMessage} data-testid="audit-trail-not-yet-available">
        <Message
          id="No events to display yet. Events will appear here after a document has been created from this template."
          comment="Message in sidebar audit trail tab when in a template and there are no events to show."
        />
      </div>
    );
  }

  return null;
};

type Props = {
  agreementId: number,
  onClose: () => void,
};

const AuditTrailTab = ({ agreementId, onClose }: Props) => {
  const { setLastSeenAudit } = useDocumentLayout();
  const agreement = useSelector((state) => (
    agreements.getAgreementSelector(state, { id: agreementId })
  ));

  const eventList = useMemo(() => getEventList(agreement), [agreement]);
  const showEmptyMessage = !eventList.length;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLastSeenAudit(eventList?.[0]?.created);
    }, 2000);
    return () => { clearTimeout(timeout); };
  }, [eventList, setLastSeenAudit]);

  return (
    <ErrorBoundary customError={<SidebarErrorMessage />}>
      <TabHeader>
        {onClose && (
          <>
            <h2 className={style.Header} data-testid="audit-trail-header">
              <Message
                id="Audit Trail"
                comment="Header for the audit trail tab in the sidebar."
              />
            </h2>
            <CloseSidebarButton onClose={onClose} />
          </>
        )}
      </TabHeader>
      <div className={style.HorizontalPadding}>
        {showDraftAndTemplateMessage(agreement, showEmptyMessage)}
        <Insight agreement={agreement} />
      </div>
      <ScrollableArea>
        {eventList.map((event) => (
          <Event
            event={event}
            agreementId={agreementId}
            key={event.id}
          />
        ))}
      </ScrollableArea>
    </ErrorBoundary>
  );
};
export default AuditTrailTab;
