import {
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ReactNode } from 'react';

import { getAgreementSidebarOpenSelector, setAgreementSidebarOpen } from 'reducers/app';

type ExpandableSidebarProps = {
  expandedSidebar: boolean,
  setExpandedSidebar: (value: boolean) => void,
}

export const ExpandableSidebarPropsContext = createContext<ExpandableSidebarProps | null>(null);

type Props = {
  children: ReactNode,
};

export function ExpandableSidebarProvider({
  children,
}: Props) {
  const dispatch = useDispatch();
  const expandedSidebar = useSelector(getAgreementSidebarOpenSelector);
  const setExpandedSidebar = useCallback((value: boolean) => {
    dispatch(setAgreementSidebarOpen(value));
  }, [dispatch]);
  const contextValue = useMemo(() => ({
    expandedSidebar,
    setExpandedSidebar,
  }), [
    expandedSidebar,
    setExpandedSidebar,
  ]);

  return (
    <ExpandableSidebarPropsContext.Provider value={contextValue}>
      {children}
    </ExpandableSidebarPropsContext.Provider>
  );
}

export const useExpandableSidebarProps = (): ExpandableSidebarProps | null => {
  const contextValue = useContext(ExpandableSidebarPropsContext);

  return contextValue;
};
