import { isFunction } from 'lodash';
import {
  useMemo, useCallback, useEffect, useState,
} from 'react';

import { DOCUMENT_COLLAPSED_LAYOUT_SIZE } from 'components/document-layout-container/helpers';

export const DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY = `(max-width: ${DOCUMENT_COLLAPSED_LAYOUT_SIZE}px)`;

const useMatchMedia = (mediaQueryString) => {
  const mediaQueryList = useMemo(() => window.matchMedia(mediaQueryString), [mediaQueryString]);
  const [matches, setMatches] = useState(mediaQueryList.matches);
  const handleMediaQueries = useCallback((mediaQueries) => {
    if (mediaQueries.matches) {
      setMatches(true);
      return;
    }
    setMatches(false);
  }, []);

  useEffect(() => {
    if (isFunction(mediaQueryList.addEventListener)) {
      mediaQueryList.addEventListener('change', handleMediaQueries);
    } else if (isFunction(mediaQueryList.addListener)) {
      mediaQueryList.addListener(handleMediaQueries);
    }

    return () => {
      if (isFunction(mediaQueryList.removeEventListener)) {
        mediaQueryList.removeEventListener('change', handleMediaQueries);
      } else if (isFunction(mediaQueryList.removeListener)) {
        mediaQueryList.removeListener(handleMediaQueries);
      }
    };
  }, [handleMediaQueries, mediaQueryList]);

  return matches;
};

export default useMatchMedia;
