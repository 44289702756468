/* eslint-disable no-continue */
// @flow

import { useState, useEffect, useMemo } from 'react';

import { useRichTextProps } from 'contexts/rich-text';

import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import useIsEditorSelectionMemoized from 'components/rich-text-editor/hooks/use-is-editor-selection-memoized';

import { getSelectedRectangles } from '../range-rectangles';

const useDOMRangeRectangles = (editor) => {
  const { documentScrollContainerRef } = useDocumentLayout();
  const isEditorSelectionMemoized = useIsEditorSelectionMemoized();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const { editorSelection } = useRichTextProps();

  useEffect(() => {
    const onWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    if (isEditorSelectionMemoized) {
      window.addEventListener('resize', onWindowResize);
    } else {
      window.removeEventListener('resize', onWindowResize);
    }

    return () => window.removeEventListener('resize', onWindowResize);
  }, [isEditorSelectionMemoized]);

  const rectangles = useMemo(() => {
    const selection = editor.selection || editorSelection;

    if (!isEditorSelectionMemoized) {
      return [];
    }

    return getSelectedRectangles(
      editor,
      selection,
      documentScrollContainerRef.current,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, editor.selection, isEditorSelectionMemoized, windowWidth, windowHeight]);

  return rectangles;
};

export default useDOMRangeRectangles;
