// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import workspaceMessageTemplatesReducer from 'reducers/entities/workspace-message-templates';

import { EditMessageTemplate, type MessageTemplateData, type Props } from './edit-message-template';

type StateProps = {|
  updateState: UpdateState,
|};

type DispatchProps = {|
  updateMessageTemplate: MessageTemplateData => void,
  resetUpdateState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  messageTemplate: MessageTemplate,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { messageTemplate }) => ({
  updateState: workspaceMessageTemplatesReducer.getUpdateSelector(state, {
    id: messageTemplate.id,
  }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { messageTemplate }) => ({
  resetUpdateState: () => {
    dispatch(workspaceMessageTemplatesReducer.updateWorkspaceMessageTemplateReset({
      id: messageTemplate.id,
    }));
  },
  updateMessageTemplate: (formData) => {
    dispatch(workspaceMessageTemplatesReducer.updateWorkspaceMessageTemplate({
      id: messageTemplate.id,
      data: {
        ...formData,
        type: formData.type.value,
        body: formData.body || null,
        subject: formData.subject || null,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(EditMessageTemplate);
