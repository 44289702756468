import { useState } from 'react';
import { DataFieldsTabProvider } from 'contexts/data-fields-tab-props';

import DataFieldsTab from './data-fields-tab';

type Props = {
  agreementId: Oneflow.Agreement['id'],
  onClose: () => void,
}
const DataFieldsTabContainer = ({
  agreementId,
  onClose,
}: Props) => {
  const [
    selectedTemplateGroupIdForContext,
    setSelectedTemplateGroupIdForContext,
  ] = useState<number>(0);

  return (
    <DataFieldsTabProvider key={selectedTemplateGroupIdForContext}>
      <DataFieldsTab
        agreementId={agreementId}
        onClose={onClose}
        setSelectedTemplateGroupIdForContext={setSelectedTemplateGroupIdForContext}
      />
    </DataFieldsTabProvider>
  );
};

export default DataFieldsTabContainer;
