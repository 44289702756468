import { uniqueId } from 'lodash';
import { getAccountFromSessionSelector } from 'reducers/session';
import { useSelector } from 'react-redux';
import {
  isAgreementOwner,
} from 'agreement/selectors';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

// eslint-disable-next-line import/named
import { getParticipantRole } from 'agreement/participant';
import { getParticipantSignOrderBlockIndex } from 'agreement/pending-state-flow';

import hasDeliveryChannelEmail from 'agreement/participant/has-delivery-channel-email';
import SelfLabel from 'components/self-label';

import style from './recipient-details.module.scss';

type Props = {
  agreement: Agreement;
  recipient: AgreementParticipant;
  isMyParticipant?: boolean;
  message: MessageTranslator;
  isInternalApprover: boolean,
};

const RecipientDetails = ({
  agreement,
  recipient,
  isMyParticipant,
  message,
  isInternalApprover,
}: Props) => {
  const account = useSelector(getAccountFromSessionSelector);
  const isOwner = isAgreementOwner(account, agreement);

  const getRecipientContactDetails = () => {
    if (hasDeliveryChannelEmail(recipient)) {
      return recipient.email;
    }

    return recipient.phoneNumber;
  };

  const renderApproveOrder = () => {
    if (!isInternalApprover) {
      return null;
    }
    return (
      <span className={style.RecipientOrder}>
        {recipient.approvalOrder}
      </span>
    );
  };

  const renderSignOrder = () => {
    if (!agreement.config?.signOrder || isInternalApprover) {
      return null;
    }
    return (
      <span className={style.RecipientOrder}>
        {getParticipantSignOrderBlockIndex(agreement, recipient.id)}
      </span>
    );
  };

  const renderSelfLabel = () => {
    if (!isMyParticipant) {
      return null;
    }

    return <SelfLabel />;
  };

  const getRoleName = () => {
    if (isInternalApprover && isOwner) {
      return (
        <Message
          id="Internal approver"
          comment="Internal approver role"
        />
      );
    }

    return getParticipantRole(message, recipient);
  };

  if (!recipient) {
    return null;
  }

  return (
    <div
      key={uniqueId()}
      className={style.RecipientDetailsContainer}
    >
      {renderApproveOrder()}
      {renderSignOrder()}
      <div className={style.RecipientInfo}>
        <div className={style.RecipientName}>
          <span>{recipient.fullname}</span>
          {renderSelfLabel()}
        </div>
        <div className={style.RecipientContactDetails}>
          {getRecipientContactDetails()}
        </div>
      </div>
      <div className={style.RecipientRole}>
        {getRoleName()}
      </div>
    </div>
  );
};

export default localize(RecipientDetails);
