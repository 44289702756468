import { Message } from '@oneflowab/pomes';
import Button from 'components/button';
import Confirmable from 'components/confirmable';
import { CancelButton } from 'components/buttons';
import WarningIcon from 'components/icons/warning';

import style from './changing-role-warning.module.scss';

const modalKey = 'changing-role-warning';

type Props = {
  setIsChangingRoleWarningModalOpen: (isOpen: boolean) => void;
  isChangingRoleWarningModalOpen: boolean;
  confirmChange: () => void;
};

export const ChangingRoleWarningModal = ({
  setIsChangingRoleWarningModalOpen,
  isChangingRoleWarningModalOpen,
  confirmChange,
}: Props) => {
  const onCancel = () => {
    setIsChangingRoleWarningModalOpen(false);
  };

  const renderBody = () => (
    <div className={style.BodyContainer}>
      <WarningIcon className={style.WarningIcon} />
      <p className={style.BodyText}>
        <b>
          <Message
            id="If you change the role of this participant, the last approvers won't be able to approve the document."
            comment="Text in the modal"
          />
        </b>
        {' '}
        <Message
          id="To avoid this, place at least one signatory after the last approver."
          comment="Text in the modal"
        />
      </p>
      <p className={style.BodyText}>
        <Message
          id="Change role?"
          comment="Text to ask the user if they want to change the participant role"
        />
      </p>
    </div>
  );

  const handleConfirm = () => {
    confirmChange();
  };

  const getActions = () => (
    <>
      <CancelButton onClick={onCancel} modalKey={modalKey} />
      <Button
        color="yellow"
        onClick={handleConfirm}
        customClass={style.ConfirmButton}
      >
        <Message
          id="Confirm role change"
          comment="Button label for confirming role change"
        />
      </Button>
    </>
  );

  return (
    <Confirmable
      header={(
        <Message
          id="Changing role"
          comment="Used as the title in modal when changing the last signatory role"
        />
    )}
      body={renderBody()}
      actions={getActions}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      isOpen={isChangingRoleWarningModalOpen}
      modalKey={modalKey}
      customBodyClass={style.Body}
    />
  );
};
