// @flow

import React from 'react';

import { Message } from '@oneflowab/pomes';

export const auditTrailAttributeCreators = {
  agreement: {
    type: () => (
      <Message
        id="duration type"
        comment="Audit trail agreement duration type"
      />
    ),
    duration: () => (
      <Message
        id="duration"
        comment="Audit trail agreement duration"
      />
    ),
    initial_duration: () => (
      <Message
        id="initial duration"
        comment="Audit trail agreement initial duration"
      />
    ),
    notice_period: () => (
      <Message
        id="notice period"
        comment="Audit trail agreement notice period"
      />
    ),
    expire_date: () => (
      <Message
        id="Signing period"
        comment="Audit trail agreement signing period"
      />
    ),
    start_date: () => (
      <Message
        id="start date"
        comment="Audit trail agreement start date"
      />
    ),
    end_date: () => (
      <Message
        id="end date"
        comment="Audit trail agreement end date"
      />
    ),
    default_party_type: () => (
      <Message
        id="contract type"
        comment="Audit trail agreement contract type"
      />
    ),
    language: () => (
      <Message
        id="language"
        comment="Audit trail agreement language"
      />
    ),
    private: () => (
      <Message
        id="private"
        comment="Audit trail agreement is private"
      />
    ),
    sign_order: () => (
      <Message
        id="signing order"
        comment="Audit trail agreement signing order"
      />
    ),
    single_sign: () => (
      <Message
        id="Single sign"
        comment="Audit trail agreement single sign"
      />
    ),
    sign_later: () => (
      <Message
        id="sign later"
        comment="Audit trail agreement sign later"
      />
    ),
    delivery_channel: () => (
      <Message
        id="Delivery channel"
        comment="Audit trail delivery channel (ex. email)"
      />
    ),
    sign_method: () => (
      <Message
        id="Sign method"
        comment="Audit trail participant sign method"
      />
    ),
    phone_number: () => (
      <Message
        id="Mobile number"
        comment="Audit trail cell phone number"
      />
    ),
  },
  data: {
    duration_value: () => (
      <Message
        id="duration"
        comment="Audit trail data duration"
      />
    ),
    initial_duration_value: () => (
      <Message
        id="initial duration"
        comment="Audit trail data initial duration"
      />
    ),
    duration_notice_period: () => (
      <Message
        id="notice period"
        comment="Audit trail data notice period"
      />
    ),
    expire_date: () => (
      <Message
        id="signing period"
        comment="Audit trail data signing period"
      />
    ),
    text: () => (
      <Message
        id="Text body"
        comment="Audit trail data text body"
      />
    ),
    form_input: () => (
      <Message
        id="Form field"
        comment="Audit trail data form field"
      />
    ),
    product: () => (
      <Message
        id="Product"
        comment="Audit trail data product"
      />
    ),
    file: () => (
      <Message
        id="File"
        comment="Audit trail data file"
      />
    ),
  },
  participant: {
    email: () => (
      <Message
        id="Email"
        comment="Audit trail participant email"
      />
    ),
    fullname: () => (
      <Message
        id="Name"
        comment="Audit trail participant name"
      />
    ),
    title: () => (
      <Message
        id="Title"
        comment="Audit trail participant title"
      />
    ),
    phone_number: () => (
      <Message
        id="Mobile number"
        comment="Audit trail participant cell phone number"
      />
    ),
    type: () => (
      <Message
        id="Role"
        comment="Audit trail participant role"
      />
    ),
    roles: () => ( // for approval flow fake type
      <Message
        id="Role"
        comment="Audit trail participant role"
      />
    ),
    ssn: () => (
      <Message
        id="Date of birth"
        comment="Audit trail participant social security number"
      />
    ),
    country: () => (
      <Message
        id="Country"
        comment="Audit trail participant country"
      />
    ),
    sign_method: () => (
      <Message
        id="Sign method"
        comment="Audit trail participant sign method"
      />
    ),
    delivery_channel: () => (
      <Message
        id="Delivery channel"
        comment="Audit trail delivery channel (ex. email)"
      />
    ),
    mfa_channel: () => (
      <Message
        id="Authentication method"
        comment="Audit trail participant authentification method"
      />
    ),
  },
  company: {
    company_name: () => (
      <Message
        id="Company name"
        comment="Audit trail company name"
      />
    ),
    company_orgnr: () => (
      <Message
        id="Registration number"
        comment="Audit trail company registration number"
      />
    ),
    company_country: () => (
      <Message
        id="Country"
        comment="Audit trail company country"
      />
    ),
    phone_number: () => (
      <Message
        id="Mobile number"
        comment="Audit trail cell phone number"
      />
    ),
    email: () => (
      <Message
        id="Email address"
        comment="Audit trail company email"
      />
    ),
  },
};

export const auditTrailTypesCreators = (typeId: number) => {
  switch (typeId) {
    case 0:
      return () => (
        <Message
          id="No duration"
          comment="Agreement type"
        />
      );
    case 1:
      return () => (
        <Message
          id="Single period"
          comment="Agreement type"
        />
      );
    case 2:
      return () => (
        <Message
          id="Recurring"
          comment="Agreement type"
        />
      );
    case 3:
      return () => (
        <Message
          id="Recurring with two periods"
          comment="Agreement type"
        />
      );
    case 4:
      return () => (
        <Message
          id="Not set"
          comment="Agreement type"
        />
      );
    default:
      return () => null;
  }
};

export const auditTrailRolesCreators = (roleId: number) => {
  switch (roleId) {
    case 0:
      return () => (
        <Message
          id="influencer"
          comment="Role type"
        />
      );
    case 1:
      return () => (
        <Message
          id="signatory"
          comment="Role type"
        />
      );
    case 2:
      return () => (
        <Message
          id="organizer"
          comment="Role type"
        />
      );
    case 3:
      return () => (
        <Message
          id="viewer"
          comment="Role type"
        />
      );
    case 1000: // fake role type, client only
      return () => (
        <Message
          id="internal approver"
          comment="Role type"
        />
      );
    case 1001: // fake role type, client only
      return () => (
        <Message
          id="approver"
          comment="Role type"
        />
      );
    default:
      return () => null;
  }
};

export const auditTrailSignMethodCreators = (signMethodId: number) => {
  switch (signMethodId) {
    case 0:
      return () => (
        <Message
          id="Standard e-signature"
          comment="Signing method"
        />
      );
    case 1:
      return () => (
        <Message
          id="SMS verification"
          comment="Signing method"
        />
      );
    case 2:
      return () => (
        <Message
          id="Swedish BankID"
          comment="Signing method"
        />
      );
    case 3:
      return () => (
        <Message
          id="Manual"
          comment="Signing method"
        />
      );
    case 4:
      return () => (
        <Message
          id="Norwegian BankID"
          comment="Signing method"
        />
      );
    case 5:
      return () => (
        <Message
          id="Danish MitID"
          comment="Signing method"
        />
      );
    case 6:
      return () => (
        <Message
          id="Finnish FTN"
          comment="Signing method"
        />
      );
    case 10:
      return () => (
        <Message
          id="Handwritten signature"
          comment="Signing method"
        />
      );
    case 13:
      return () => (
        <Message
          id="Electronic ID"
          comment="Signing method"
        />
      );
    default:
      return () => null;
  }
};

export const auditTrailSignMethodShortCreators = (signMethodId: number) => {
  switch (signMethodId) {
    case 0:
      return () => (
        <Message
          id="E-sign"
          comment="Signing method"
        />
      );
    case 1:
      return () => (
        <Message
          id="SMS"
          comment="Signing method"
        />
      );
    case 2:
      return () => (
        <Message
          id="Swedish BankID"
          comment="Signing method"
        />
      );
    case 3:
      return () => (
        <Message
          id="Manual"
          comment="Signing method"
        />
      );
    case 4:
      return () => (
        <Message
          id="Norwegian BankID"
          comment="Signing method"
        />
      );
    case 5:
      return () => (
        <Message
          id="Danish MitID"
          comment="Signing method"
        />
      );
    case 6:
      return () => (
        <Message
          id="Finnish FTN"
          comment="Signing method"
        />
      );
    case 10:
      return () => (
        <Message
          id="Handwritten signature"
          comment="Signing method"
        />
      );
    case 13:
      return () => (
        <Message
          id="Electronic ID"
          comment="Signing method"
        />
      );
    default:
      return () => null;
  }
};

export const auditTrailMfaChannelCreators = (type: string) => {
  switch (type) {
    case 'none':
      return () => (
        <Message
          id="None"
          comment="Mfa channel"
        />
      );
    case 'email':
      return () => (
        <Message
          id="Email verification"
          comment="Mfa channel"
        />
      );
    case 'sms':
      return () => (
        <Message
          id="SMS verification"
          comment="Mfa channel"
        />
      );
    default:
      return () => null;
  }
};

export const auditTrailDeliveryChannelCreators = (channelId: number) => {
  switch (channelId) {
    case 0:
      return () => (
        <Message
          id="Email"
          comment="Delivery type"
        />
      );
    case 1:
      return () => (
        <Message
          id="SMS"
          comment="Delivery type"
        />
      );
    case 2:
      return () => (
        <Message
          id="Same device"
          comment="Delivery type"
        />
      );
    case 4:
      return () => (
        <Message
          id="Not delivered"
          comment="Delivery type"
        />
      );
    case 5:
      return () => (
        <Message
          id="Email and SMS"
          comment="Delivery type"
        />
      );
    default:
      return () => null;
  }
};
