import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { isNull, isNumber } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { getAvailableContentInlineSize } from 'reducers/app';
import { roundBy } from 'utils/math';
import { updateBoxDataItemAction, getContractMetaData } from 'reducers/current-contract';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import useCurrentPriceRoundingMethod from 'hooks/use-current-price-rounding-method';
import usePopupDialog from 'hooks/popup/use-popup-dialog';
import { isBoxDataValueUpdateAllowed } from 'agreement/box-data-value-update-permissions';
import { ProductSumBox, ProductSumBoxData } from 'data-validators/entity-schemas/document-box/product-sum-box';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import {
  getProductSumString,
  calculateSumsFromProductTables,
  hasValidDiscount,
} from 'components/contract-boxes/product-sum-box/product-sum-box-helpers';
import ProductSumEdit from 'components/contract-boxes/product-sum-box/product-sum-edit';

import style from './product-sum-row.module.scss';

type Props = {
  box: ProductSumBox,
  summation: ProductSumBoxData,
  openRowId: number,
  setOpenRowId: (id: number) => void,
};

const ProductSumRow = ({
  box,
  summation,
  openRowId,
  setOpenRowId,
}: Props) => {
  const { documentScrollContainerRef } = useDocumentLayout();
  const availableContentInlineSize = useSelector(getAvailableContentInlineSize);
  const isExpandedView = availableContentInlineSize > 700;
  const isAllowedToUpdateDataValue = isBoxDataValueUpdateAllowed(box, summation);
  const popupType = isExpandedView ? 'popover' : 'dialog';
  const { Popup, PopupContent } = usePopupDialog(popupType);
  const { priceColumnsGroups } = useSelector(getContractMetaData);
  const currentPriceRoundingMethod = useCurrentPriceRoundingMethod();
  const dispatch = useDispatch();

  const summationId = getId(summation);
  const { value } = summation;
  const {
    columnUuids,
    sum,
    sumDiscounted,
    sumPrecision,
  } = useMemo(() => {
    const roundedSum = isNumber(value.sum) ? roundBy(
      currentPriceRoundingMethod,
      value.sum,
      value.sumPrecision,
    ) : value.sum;
    const roundedSumDiscounted = isNumber(value.sumDiscounted) ? roundBy(
      currentPriceRoundingMethod,
      value.sumDiscounted,
      value.sumPrecision,
    ) : value.sumDiscounted;

    return {
      sum: roundedSum,
      sumDiscounted: roundedSumDiscounted,
      sumPrecision: value.sumPrecision,
      columnUuids: value.columnUuids,
    };
  }, [
    value.sum,
    value.sumDiscounted,
    value.sumPrecision,
    value.columnUuids,
    currentPriceRoundingMethod,
  ]);

  const currentSums = useMemo(() => {
    const checkedColumnUuids = columnUuids?.map((item) => item.uuid || item) || [];
    return calculateSumsFromProductTables(
      currentPriceRoundingMethod,
      checkedColumnUuids,
      priceColumnsGroups,
      sumPrecision,
    );
  }, [
    currentPriceRoundingMethod,
    columnUuids,
    priceColumnsGroups,
    sumPrecision,
  ]);

  const updateSummation = useCallback(() => {
    const updatedSum = {
      value: {
        ...value,
        sum: currentSums.sum,
        sumDiscounted: hasValidDiscount(currentSums.sum, currentSums.sumDiscounted) ? (
          currentSums.sumDiscounted
        ) : null,
      },
    };
    dispatch(updateBoxDataItemAction(getId(box), summationId, updatedSum));
  }, [
    value,
    currentSums.sum,
    currentSums.sumDiscounted,
    dispatch,
    box,
    summationId,
  ]);

  useEffect(() => {
    const noDiscount = isNull(sumDiscounted) && (
      !hasValidDiscount(currentSums.sum, currentSums.sumDiscounted)
    );

    if (sum === currentSums.sum && (sumDiscounted === currentSums.sumDiscounted || noDiscount)) {
      return;
    }

    updateSummation();
  }, [updateSummation, sumDiscounted, sum, currentSums]);

  const getPopupTrigger = (summationRowContent: ReactNode) => (
    <div
      className={clsx(style.ProductSumButton, {
        [style.GreenBackground]: openRowId === summationId,
      })}
      data-testid="product-sum-row-trigger"
      onClick={() => setOpenRowId(summationId)}
      onKeyDown={() => null}
      role="button"
      tabIndex={0}
    >
      {summationRowContent}
    </div>
  );

  const renderFullPrice = () => {
    if (!hasValidDiscount(sum, sumDiscounted)) {
      return null;
    }
    return (
      <p className={style.ProductSumFullPrice}>
        {getProductSumString(currentPriceRoundingMethod, value, 'full')}
      </p>
    );
  };

  const summationRowContent = (
    <div className={style.ProductSumContent}>
      <p className={style.Name}>{value.name}</p>
      <div className={style.Sum}>
        {renderFullPrice()}
        <p>{getProductSumString(currentPriceRoundingMethod, value, 'discounted')}</p>
      </div>
    </div>
  );

  if (!isAllowedToUpdateDataValue) {
    return summationRowContent;
  }

  return (
    <Popup.Root open={openRowId === summationId}>
      <Popup.Trigger asChild>
        {getPopupTrigger(summationRowContent)}
      </Popup.Trigger>
      <Popup.Portal container={documentScrollContainerRef.current}>
        <PopupContent
          className={style.PopoverContent}
          onEscapeKeyDown={() => setOpenRowId(null)}
          onInteractOutside={() => setOpenRowId(null)}
          side="center"
        >
          <ProductSumEdit
            box={box}
            summation={summation}
            close={() => setOpenRowId(null)}
            sums={currentSums}
          />
        </PopupContent>
      </Popup.Portal>
    </Popup.Root>
  );
};

export default ProductSumRow;
