import { useRef } from 'react';
import {
  Form,
  FormRenderProps,
} from 'react-final-form';
import {
  isEmpty,
  some,
} from 'lodash';
import { localize } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import type { MessageTranslator } from '@oneflowab/pomes';

import {
  composeValidators,
  // eslint-disable-next-line import/named
  maxLength,
} from 'forms/validators';
import { updatePriceColumnsAction } from 'reducers/current-contract';
import usePopupDialog from 'hooks/popup/use-popup-dialog';
import type {
  Column,
  ConfigUpdateData,
} from 'data-validators/entity-schemas/document-box/product-table';

import { CancelButton } from 'components/buttons';
import {
  COL_NAME_MAX_LENGTH,
  PRODUCT_TABLE_COLUMNS,
} from 'components/contract-boxes/product-table-box/constants';
import Button from 'components/button';
import Field from 'components/field';
import Message from 'components/message';
import TextField from 'components/text-field';

import style from './popup-forms.module.scss';

type Props = {
  column: Column,
  updateProductConfig: (configData: ConfigUpdateData) => void,
  popupType: 'popover' | 'dialog',
  message: MessageTranslator,
};

type FormValues = {
  columnName: string,
}

export const NameAndDescriptionPopupForm = ({
  column, updateProductConfig, popupType, message,
}: Props) => {
  const dispatch = useDispatch();
  const closeButtonRef = useRef<HTMLInputElement>(null);
  const closePopup = () => closeButtonRef.current?.click();
  const { Popup } = usePopupDialog(popupType);
  const { label } = column;

  const columnNameValidator = composeValidators(
    maxLength({
      message,
      field: 'columnName',
      customMessage: message({
        id: 'Maximum {maxLength} characters allowed.',
        comment: 'Validation message for price price affix validation.',
        values: {
          maxLength: COL_NAME_MAX_LENGTH,
        },
      }),
      maxLength: COL_NAME_MAX_LENGTH,
    }),
  );

  const handleSubmit = ({ columnName }: FormValues) => {
    const configData = {
      column: {
        ...column,
        label: columnName || '',
      },
    };
    updateProductConfig(configData);

    if (column.key === PRODUCT_TABLE_COLUMNS.NAME) {
      dispatch(updatePriceColumnsAction());
    }

    closePopup();
  };

  const renderForm = (formProps: FormRenderProps<FormValues>) => {
    const disabled = some([
      formProps.invalid,
      formProps.submitting,
      !isEmpty(formProps.errors),
    ]);

    return (
      <form
        className={style.ProductTablePopupForm}
        onSubmit={formProps.handleSubmit}
      >
        <div className={style.PopupHeader}>
          <Message id="Edit Column" comment="Header for edit product table column form" />
        </div>
        <div className={style.PopupFormContainer}>
          <div className={clsx(style.PopupFormElement, style.ColumnNameInputContainer)}>
            <Field
              label={<Message id="Column name" comment="Label for field to edit column name" />}
              validate={columnNameValidator}
              name="columnName"
              component={TextField}
              customClass={style.PopupTextField}
              labelCustomClass={style.PopupTextFieldLabel}
              autoComplete="off"
            />
          </div>
          <div className={style.ActionButtonsContainer}>
            <Popup.Close asChild>
              <CancelButton />
            </Popup.Close>
            <Button
              data-testid="save-button"
              type="submit"
              kind="secondary"
              disabled={disabled}
            >
              <Message
                id="Save"
                comment="Action to save when the document period reminder should be set."
              />
            </Button>
            <Popup.Close asChild>
              {/* No translation needed and not part of accessibility */}
              <Button
                aria-hidden="true"
                customClass={style.HiddenButton}
                buttonRef={closeButtonRef}
                type="button"
              >
                Programmatic hidden close button
              </Button>
            </Popup.Close>
          </div>
        </div>
      </form>
    );
  };
  return (
    <Form
      render={renderForm}
      initialValues={{ columnName: label }}
      onSubmit={handleSubmit}
    />
  );
};

export default localize(NameAndDescriptionPopupForm);
