// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getGroup,
  getGroups,
  createGroup,
  updateGroup,
  deleteGroups,
} from 'oneflow-client/groups';
import type { NormalizedGroups } from 'oneflow-client/groups';

import apiWrapper from 'sagas/api-wrapper';

import groups from 'reducers/entities/groups';

type MapperArgs = {
  data: NormalizedGroups,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* createMapper({ data }: MapperArgs): Mapper {
  yield put(groups.setGroups(data.entities.groups));
}

export function* clearMapper({ action }: MapperArgs): Mapper {
  yield put(groups.clearGroup({ ids: action.data.ids }));
}

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(groups.setGroups(data.entities.groups));
}

const mappers = {
  query: {
    mapper,
    request: getGroups,
  },
  fetch: {
    mapper,
    request: getGroup,
  },
  create: {
    mapper: createMapper,
    request: createGroup,
  },
  update: {
    mapper,
    request: updateGroup,
  },
  remove: {
    mapper: clearMapper,
    request: deleteGroups,
  },
};

const groupsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: groups,
  mappers,
});

export default groupsSagas;
