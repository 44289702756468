// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import { checkAcl } from 'components/acl';

import Button from 'components/button';
import CreateContract from 'components/icons/create-contract';
import useRouterHistory from 'hooks/use-router-history';

import style from './create-blank-contract.module.scss';

type Props = {
  createContract: number => void,
  currentWorkspace: Workspace,
  hasTemplates: boolean,
  selectedFolderId?: number,
};

export const CreateBlankContract = ({
  createContract,
  currentWorkspace,
  hasTemplates,
  selectedFolderId,
}: Props) => {
  // TODO: Replace useRouterHistory with useHistory after the migrating away from backbone
  const history = useRouterHistory();
  const hasCreatePermission = checkAcl(currentWorkspace.acl, 'collection:agreement:create_blank')
    && hasTemplates;

  if (!hasCreatePermission) {
    return null;
  }
  return (
    <div className={style.CreateBlankContract}>
      <Button
        kind="linkSeparate"
        icon={CreateContract}
        onClick={() => createContract(currentWorkspace.id, selectedFolderId, history)}
        customClass={style.Button}
        data-testid="create-document-button"
      >
        <Message
          id="Create blank"
          comment="Button that lets the user create a blank contract."
        />
      </Button>
      <div className={style.Divider} />
    </div>
  );
};
