// @flow

import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import ReactToggle from 'react-toggle';
import { isUndefined, isFunction } from 'lodash';

import ToggleCheckIcon from 'components/icons/toggle-check';

import { RadixToggle } from './radix-toggle';

import 'react-toggle/style.css';
import 'styles/external/react-toggle.scss';
import style from './toggle.module.scss';

type CommonProps = {
  containerClassName?: string,
  disabled?: boolean,
  input?: Input,
  label?: string | JSX.Element,
  meta?: InputMeta,
  onChange?: Function,
};

type Props = {
  id?: string,
  checked?: boolean,
  isControlled?: boolean,
  version?: 'latest' | 'outdated',
} & CommonProps

export const OldToggle = ({
  input = {},
  meta,
  label,
  onChange,
  containerClassName,
  ...restProps
}: CommonProps) => {
  const [focusOnTab, setFocusOnTab] = useState(false);
  const labelRef = useRef(null);

  const onChangeEvent = (event: SyntheticInputEvent<*>) => {
    if (isFunction(input.onChange)) {
      input.onChange(event);
    }

    if (isFunction(onChange)) {
      onChange(event);
    }
  };

  useEffect(() => {
    const handleTabSpaceEvent = (event) => {
      if (labelRef.current?.contains(event.target)) {
        if (event.key === 'Tab' || event.code === 'Space') {
          setFocusOnTab(true);
        }
      }
    };

    window.addEventListener('keyup', handleTabSpaceEvent);
    return () => {
      window.removeEventListener('keyup', handleTabSpaceEvent);
    };
  }, []);

  const renderLabel = () => {
    if (!label) {
      return null;
    }

    return (
      <span className={style.Label}>
        {label}
      </span>
    );
  };

  const renderIcons = () => ({
    checked: <ToggleCheckIcon height="10px" width="10px" />,
    unchecked: null,
  });

  return (
    <label
      className={clsx(containerClassName, {
        [style.ToggleFocusTabOnly]: focusOnTab,
      })}
      data-testid="Toggle"
      htmlFor={input.name}
      ref={labelRef}
    >
      {renderLabel()}
      <ReactToggle
        className={style.ToggleField}
        data-testid="ToggleField"
        aria-label={label || 'No label tag'}
        {...input}
        value={isUndefined(input.value) ? undefined : String(input.value)}
        {...restProps}
        onChange={onChangeEvent}
        icons={renderIcons()}
        onFocus={() => setFocusOnTab(false)}
      />
    </label>
  );
};

const Toggle = ({ version = 'outdated', ...props }: Props) => {
  if (version === 'latest') {
    return (<RadixToggle {...props} />);
  }

  return (<OldToggle {...props} />);
};

export default Toggle;
