// @flow

import * as React from 'react';
import clsx from 'clsx';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { Acl } from 'components/acl';
import CogIcon from 'components/icons/cog';
import MyProfileButton from 'components/my-profile-button';
import Search from 'components/icons/search';
import Marketplace from 'components/icons/marketplace';
import SidebarItemsAdmin from 'components/sidebar-items-admin';
import SidebarOption from 'components/sidebar/sidebar-option/sidebar-option';
import SidebarSubmenuMobile from 'components/sidebar-submenu-mobile';
import SupportButton from 'components/support-button';

import style from './sidebar-items-bottom.module.scss';

type Props = {
  acl: {
    account: Acl,
    workspace: Acl,
  },
  expanded: boolean,
  isMobile: boolean,
  isContractView: boolean,
  mobileMenuOpen: boolean,
  onItemClick: () => void,
  updatesCount: number,
  message: MessageTranslator,
  submenuItems: React.Node,
  isSubmenuVisible: boolean,
  hideSubmenu: () => void,
};

const SidebarItemsBottom = ({
  acl,
  expanded,
  isMobile,
  isContractView,
  mobileMenuOpen,
  onItemClick,
  updatesCount,
  message,
  submenuItems,
  isSubmenuVisible,
  hideSubmenu,
}: Props) => {
  const sideMenuClasses = clsx(style.SideMenu, {
    [style.Mobile]: isMobile,
  });

  const wrapperDivClasses = clsx(style.WrapperDiv, {
    [style.Mobile]: isMobile,
  });

  const renderSidebarSubmenu = React.useCallback(() => {
    if (!isMobile) {
      return null;
    }

    return (
      <SidebarSubmenuMobile
        items={submenuItems}
        visible={isSubmenuVisible}
        hideSubmenu={hideSubmenu}
      />
    );
  }, [hideSubmenu, isMobile, isSubmenuVisible, submenuItems]);

  return (
    <>
      <div className={wrapperDivClasses}>
        <SupportButton
          expanded={expanded}
          isMobile={isMobile}
          onItemClick={onItemClick}
          tooltipText={message({
            id: 'Help',
            comment: 'Used as a tooltip for the sidebar item',
          })}
        />
      </div>
      <div className={sideMenuClasses}>
        <SidebarOption
          data-testid="global-search-toggle"
          expanded={expanded}
          IconComponent={Search}
          isContractView={isContractView}
          isMobile={isMobile}
          link="/search"
          tooltipText={message({
            id: 'Search',
            comment: 'Used as a tooltip for the sidebar item',
          })}
          trackable={{
            name: 'Go To Global Search',
            props: {
              location: 'Main navigation',
            },
          }}
          onItemClick={onItemClick}
        />
      </div>

      <Acl acl={acl.account} check="account:marketplace">
        <div className={sideMenuClasses}>
          <SidebarOption
            data-testid="marketplace"
            expanded={expanded}
            IconComponent={Marketplace}
            isContractView={isContractView}
            isMobile={isMobile}
            link="/marketplace"
            tooltipText={message({
              id: 'Marketplace',
              comment: 'Used as a tooltip for the sidebar item',
            })}
            trackable={{
              name: 'Go To Marketplace',
              props: {
                location: 'Main navigation',
              },
            }}
            onItemClick={onItemClick}
          />
        </div>
      </Acl>
      <div className={sideMenuClasses}>
        <Acl acl={acl.account} check="account:admin">
          <SidebarOption
            data-testid="sidebar-admin-users"
            expanded={expanded}
            IconComponent={CogIcon}
            isContractView={isContractView}
            isMobile={isMobile}
            link="#"
            tooltipText={message({
              id: 'Admin',
              comment: 'Used as a tooltip for the sidebar admin item',
            })}
            onItemClick={() => onItemClick(
              <SidebarItemsAdmin
                isContractView={isContractView}
                onItemClick={onItemClick}
                acl={acl}
              />,
            )}
          />
        </Acl>
      </div>
      {renderSidebarSubmenu()}
      <div className={wrapperDivClasses}>
        <MyProfileButton
          isContractView={isContractView}
          updatesCount={updatesCount}
          expanded={expanded}
          isMobile={isMobile}
          mobileMenuOpen={mobileMenuOpen}
          toggleMobileMenu={() => onItemClick()}
        />
      </div>
    </>
  );
};

export default localize<Props>(SidebarItemsBottom);
