// @flow

import { getAgreementParties } from 'oneflow-client/agreement-parties';

import * as participantConstants from 'agreement/participant/constants';
import { checkAcl } from 'components/acl';

const fetchAgreementParties = async (agreementId) => {
  if (!agreementId) {
    return null;
  }

  try {
    const params = {
      id: agreementId,
    };
    const parties = await getAgreementParties({ params });
    return Object.values(parties.entities.agreementParties);
  } catch (e) {
    return false;
  }
};

const hasParticipantAttribute = ({ params, agreementId }, attribute) => {
  if (!params || !params[attribute] || !agreementId) {
    return null;
  }

  return fetchAgreementParties(agreementId).then((parties) => {
    if (!parties) {
      return false;
    }

    const participants = parties.reduce(
      (accumulator, party) => [...accumulator, ...party.participants],
      [],
    );
    return Boolean(
      participants.filter(
        (participant) => (
          participant[attribute] === params[attribute]
            && participant.state !== participantConstants.STATE_IS_DISABLED
        ),
      ).length,
    );
  });
};

export const hasParticipantEmail = ({ params, agreementId }) => (
  hasParticipantAttribute({ params, agreementId }, 'email')
);

export const hasParticipantPhone = ({ params, agreementId }) => (
  hasParticipantAttribute({ params, agreementId }, 'phoneNumber')
);

export const shouldDisableField = (participantAcl, acl) => {
  if (participantAcl && !checkAcl(participantAcl, acl)) {
    return true;
  }

  return false;
};
