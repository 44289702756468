// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import rolesReducer from 'reducers/entities/roles';

import { RemoveRole, type Props } from './remove-role';

type OwnProps = {|
  role: Role,
  children: (() => void) => React.Node,
|};

type StateProps = {|
  removeState: RpcState,
|};

type DispatchProps = {|
  removeRole: () => void,
  resetRemoveState: Function,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { role }) => ({
  removeState: rolesReducer.getRemoveSelector(state, {
    id: role.id,
  }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, OwnProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { role }) => ({
  removeRole: () => {
    dispatch(rolesReducer.removeRole({
      id: role.id,
      pipe: {
        action: () => rolesReducer.queryRoles({
          name: 'admin/roles',
          pagination: {
            // should be same as the limit for the roles list original query to keep consistency
            limit: 50,
            offset: 0,
          },
          sort: ['-type', 'name'],
        }),
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(rolesReducer.removeRoleReset({
      id: role.id,
    }));
  },
});

const connectedRemoveRole = connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveRole);

export { connectedRemoveRole as RemoveRole };
