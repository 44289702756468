// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';
import generateEntitySagas from 'normalized-redux/sagas';
import {
  addGroupsToPosition,
  removeGroupsFromPosition,
  getPositionGroups,
} from 'oneflow-client/position-groups';
import type { NormalizedGroups } from 'oneflow-client/groups';

import apiWrapper from 'sagas/api-wrapper';

import groups from 'reducers/entities/groups';
import positionGroups from 'reducers/entities/position-groups';

type MapperArgs = {
  data: NormalizedGroups,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(groups.setGroups(data.entities.groups));
}

const mappers = {
  create: {
    request: addGroupsToPosition,
  },
  query: {
    mapper,
    request: getPositionGroups,
  },
  remove: {
    request: removeGroupsFromPosition,
  },
};

const positionGroupsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: positionGroups,
  mappers,
});

export default positionGroupsSagas;
