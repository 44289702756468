// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

type Props = {
  group: Group,
  removeState: RemoveState,
  removeMembers: Array<number> => void,
  resetRemoveState: Function,
  ids: Array<number>,
  children: Function,
}

export class RemoveMembers extends React.Component<Props> {
  handleConfirm = () => {
    const {
      ids,
      removeMembers,
      removeState,
    } = this.props;

    if (removeState.loading) {
      return;
    }

    removeMembers(ids);
  };

  render() {
    const {
      removeState,
      resetRemoveState,
      group,
      children,
      ids,
    } = this.props;

    return (
      <RemoveConfirm
        onConfirm={this.handleConfirm}
        confirmState={removeState}
        resetConfirmState={resetRemoveState}
        confirmMessage={(
          <Message
            id="You are about to remove a user from {group}."
            pluralId="You are about to remove {count} users from {group}."
            pluralCondition="count"
            values={{
              group: <Badge label={group.name} kind="name" />,
              count: ids.length,
            }}
            comment="Modal dialog asking for confirmation whether multiple users should be removed from a group or not"
          />
        )}
        modalKey="remove users from group modal"
      >
        {children}
      </RemoveConfirm>
    );
  }
}
