// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import type { FormRenderProps } from 'react-final-form';

import {
  getFormatter,
  getMonthlySeatPrice,
  getYearlySeatPrice,
  formatPrice,
} from 'account';

import ModalForm from 'hocs/modal-form';
import { PreviousButton, ConfirmButton } from 'components/buttons';
import CircularSpinner from 'components/icons/circular-spinner';
import TermsOfUseLink from 'components/terms-of-use-link';

import { AccountPurchaseConfirmField } from 'components/fields/account-purchase-confirm';

import style from './freemium-self-service-confirm.module.scss';

export type ModalData = {|
  planName: string,
  pricePerSeat: number,
  pricePerSeatMonthlySubscription: number,
  currency: string,
  planId: number,
  address: string,
  city: string,
  country: string,
  invoiceEmail: string,
  name: string,
  numberOfSeats: string,
  invoiceReference: string,
  vatNumber: string,
  zipCode: string,
  monthlyBilling: boolean,
|};

export type Props = {
  onStepComplete: () => void,
  modalData: ModalData,
  languageCode: EnabledLanguages,
  updateState: UpdateState,
  onConfirm: (id: number) => void,
  accountId: number,
  resetUpdateState: (id: number) => void,
  onPreviousStep: () => void,
  onClose: () => void,
  modalKey: string,
};

export class FreemiumSelfServiceConfirm extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const {
      updateState,
      onStepComplete,
    } = this.props;

    if (!prevProps.updateState.success && updateState.success) {
      onStepComplete();
    }
  }

  getIcon = () => {
    const { updateState } = this.props;

    if (!updateState.loading) {
      return null;
    }

    return CircularSpinner;
  }

  getActions = ({ formProps }: FormRenderProps) => {
    const { updateState, onPreviousStep } = this.props;

    const disabled = Boolean(
      updateState.loading
      || updateState.error
      || formProps.validating
      || formProps.invalid,
    );

    return (
      <div className={style.Buttons}>
        <PreviousButton onClick={onPreviousStep} />
        <ConfirmButton
          onClick={formProps.handleSubmit}
          disabled={disabled}
          isLoading={updateState.loading}
        />
      </div>
    );
  }

  getPaymentInterval = () => {
    const { monthlyBilling } = this.props.modalData;

    if (monthlyBilling) {
      return (
        <p>
          <Message
            id="You will be billed monthly."
            comment="Billing information which the customer gets before confirming purchase."
          />
        </p>
      );
    }

    return (
      <p>
        <Message
          id="You will be billed annually."
          comment="Billing information which the customer gets before confirming purchase."
        />
      </p>
    );
  }

  renderBillingIntervalParagraph = (monthlyPrice: string, yearlyPrice: string) => {
    const { invoiceEmail, monthlyBilling } = this.props.modalData;

    if (monthlyBilling) {
      return (
        <Message
          id="You will receive a monthly invoice of {monthlyPrice} to {email}."
          values={{
            email: <strong>{invoiceEmail}</strong>,
            monthlyPrice: <strong>{monthlyPrice}</strong>,
          }}
          comment="Billing information which the customer gets before confirming purchase."
        />
      );
    }

    return (
      <Message
        id="You will receive an invoice of {yearlyPrice} to {email}."
        values={{
          email: <strong>{invoiceEmail}</strong>,
          yearlyPrice: <strong>{yearlyPrice}</strong>,
        }}
        comment="Billing information which the customer gets before confirming purchase."
      />
    );
  }

  renderTotalPrice = (monthlyPrice: string, yearlyPrice: string) => {
    const { monthlyBilling } = this.props.modalData;

    if (monthlyBilling) {
      return (
        <strong>
          <p>
            <Message
              id="{total} / month"
              values={{
                total: monthlyPrice,
              }}
              comment="The total monthly price of the entire subscription."
            />
          </p>
        </strong>
      );
    }

    return (
      <strong>
        <p>
          <Message
            id="{total} / year"
            values={{
              total: yearlyPrice,
            }}
            comment="The total yearly price of the entire subscription."
          />
        </p>
      </strong>
    );
  }

  formatter = () => {
    const { languageCode, modalData: { currency } } = this.props;

    return getFormatter(languageCode, currency);
  };

  handleResetUpdateState = () => {
    const { accountId, resetUpdateState } = this.props;

    resetUpdateState(accountId);
  }

  handleSubmit = () => {
    const { onConfirm, accountId } = this.props;

    onConfirm(accountId);
  }

  renderBody() {
    const {
      pricePerSeat,
      pricePerSeatMonthlySubscription,
      numberOfSeats,
      planName,
      monthlyBilling,
      name,
    } = this.props.modalData;
    const selectedPrice = monthlyBilling ? pricePerSeatMonthlySubscription : pricePerSeat;

    const selectedPlanPrice = formatPrice(
      this.formatter(),
      selectedPrice,
    );
    const monthlyPrice = formatPrice(
      this.formatter(),
      getMonthlySeatPrice(numberOfSeats, selectedPrice),
    );
    const yearlyPrice = formatPrice(
      this.formatter(),
      getYearlySeatPrice(numberOfSeats, selectedPrice),
    );

    return (
      <div>
        <h2>
          <Message
            id="Summation"
            comment="Heading for a summation showing what the user is purchasing."
          />
        </h2>
        <div className={style.PlanSummation}>
          <p>{planName}</p>
          <p>
            {selectedPlanPrice}
            {' '}
            <Message
              id="/ month per seat"
              comment="Price information of the selected price plan"
            />
          </p>
        </div>
        <div className={style.PlanSummation}>
          <p>
            <Message
              id="{numberOfSeats} seats"
              values={{ numberOfSeats }}
              comment="Number of seats a user is purchasing."
            />
          </p>
          <p>
            {numberOfSeats}
            &nbsp;
            x
            &nbsp;
            {selectedPlanPrice}
          </p>
        </div>
        <div className={style.PlanSummation}>
          <strong>
            <p>
              <Message
                id="Total"
                comment="The total yearly price of the entire subscription."
              />
            </p>
          </strong>
          {this.renderTotalPrice(monthlyPrice, yearlyPrice)}
        </div>
        <h2>
          <Message
            id="Payment details"
            comment="Heading of billing instructions before confirming purchase."
          />
        </h2>
        <p>
          {this.renderBillingIntervalParagraph(monthlyPrice, yearlyPrice)}
        </p>
        {this.getPaymentInterval()}
        <div className={style.BottomContainer}>
          <TermsOfUseLink />
          <div className={style.CheckboxContainer}>
            <AccountPurchaseConfirmField billingCompanyName={name} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { updateState, onClose, modalKey } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Activate your Subscription Plan"
            comment="Message shown in upgrade account modal"
          />
        )}
        body={this.renderBody()}
        actions={this.getActions}
        formState={updateState}
        resetFormState={this.handleResetUpdateState}
        onSubmit={this.handleSubmit}
        isOpen
        onClose={onClose}
        modalKey={modalKey}
      />
    );
  }
}
