// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Renew from 'components/icons/renew';

const WillRenew = () => (
  <>
    <Renew height="12px" />
    <span>
      <Message
        id="Will renew"
        comment="An insight for lifecycle events that indicates that a contract will be renewed."
      />
    </span>
  </>
);

export default WillRenew;
