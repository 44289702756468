import { Message } from '@oneflowab/pomes';

import { getEmptyRequiredElementsCount } from 'reducers/helpers/current-contract';
import type { DataField } from 'data-validators/entity-schemas/agreement-data';

import toast from 'components/toasts';
import {
  EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
  EMPTY_NON_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
  focusNextEmptyRequiredElement,
} from './helpers';

import NextEmptyInputButton from './components/next-empty-input-button';

type HandleEmptyRequiredFieldsToastsProps = {
  editableEmptyRequiredElementsCount: number,
  nonEditableEmptyRequiredElementsCount: number,
};

const handleEmptyRequiredFieldsToasts = ({
  editableEmptyRequiredElementsCount,
  nonEditableEmptyRequiredElementsCount,
}: HandleEmptyRequiredFieldsToastsProps) => {
  if (editableEmptyRequiredElementsCount > 1) {
    toast.warning({
      id: EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
      title: <Message id="Missing fields" comment="Notification message for missing required fields" />,
      description: (
        <Message
          id="Fill in {count} required fields to proceed"
          comment="Notification message for missing required fields"
          values={{ count: editableEmptyRequiredElementsCount }}
        />
      ),
      action: <NextEmptyInputButton />,
    });
    return;
  }
  // THIS should go away when saving
  if (editableEmptyRequiredElementsCount === 1) {
    toast.warning({
      id: EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
      title: <Message id="Missing fields" comment="Notification message for missing required fields" />,
      description: (
        <Message
          id="Fill in 1 required field to proceed"
          comment="Notification message for missing required fields"
        />
      ),
    });
    return;
  }

  if (editableEmptyRequiredElementsCount <= 0) {
    toast.remove({
      toastId: EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
    });
  }

  if (nonEditableEmptyRequiredElementsCount > 0) {
    toast.warning({
      id: EMPTY_NON_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
      title: <Message id="Missing fields" comment="Notification message for missing required fields" />,
      description: (
        <Message
          id="Contact the document owner to fill in the missing fields before signing"
          comment="Notification message for missing required fields"
        />
      ),
    });
  } else {
    toast.remove({
      toastId: EMPTY_NON_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
    });
  }
};

// eslint-disable-next-line import/prefer-default-export
export const handleEmptyRequiredFields = ({
  isGuest,
  boxMap,
  data,
  dataFieldExternalKeyValueMap,
  dataFieldExternalKeyMap,
}: {
  isGuest: boolean,
  boxMap: ContractView.Boxes,
  data: Record<DataField['id'], DataField>,
  dataFieldExternalKeyValueMap: Record<string, DataField['value']['value']>,
  dataFieldExternalKeyMap: Record<string, number>,
}) => {
  const {
    editableEmptyRequiredElementsCount,
    nonEditableEmptyRequiredElementsCount,
  } = getEmptyRequiredElementsCount({
    isGuest,
    boxMap,
    data,
    dataFieldExternalKeyValueMap,
    dataFieldExternalKeyMap,
  });

  const hasMissingRequiredFields = (
    editableEmptyRequiredElementsCount > 0 || nonEditableEmptyRequiredElementsCount > 0
  );

  handleEmptyRequiredFieldsToasts({
    editableEmptyRequiredElementsCount,
    nonEditableEmptyRequiredElementsCount,
  });

  if (editableEmptyRequiredElementsCount > 0) {
    focusNextEmptyRequiredElement();
  }

  return { hasMissingRequiredFields };
};
