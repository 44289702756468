import { memo } from 'react';

import PendingSignature from './pending-signature';

export type Props = {
  autoStartToken: string,
}

const BankIDSameDevice = ({ autoStartToken }: Props) => (
  <div>
    <PendingSignature device="same" autoStartToken={autoStartToken} />
  </div>
);

export default memo(BankIDSameDevice);
