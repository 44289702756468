import { forwardRef, useEffect, useMemo } from 'react';
import type { DetailedHTMLProps } from 'react';

import { ITEM_SELECTOR, VALUE_ATTR } from './constants';
import { useFilterableContext } from './contexts/filterable';
import useFilterableState from './hooks/use-filterable-state';
import { useStore } from './contexts/store';

type Props = Omit<
  DetailedHTMLProps<
    React.HTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  , 'value' | 'onChange' | 'type'> & {
    /**
     * Optional controlled state for the value of the search input.
     */
    value?: string
    /**
     * Event handler called when the search value changes.
     */
    onValueChange?: (search: string) => void
  }

/**
 * Command menu input.
 * All props are forwarded to the underlying `input` element.
 */
const Input = forwardRef<HTMLInputElement, Props>((props, forwardedRef) => {
  const { onValueChange, ...etc } = props;
  const isControlled = props.value !== null && props.value !== undefined;
  const store = useStore();
  const search = useFilterableState((state) => state.search);
  const value = useFilterableState((state) => state.value);
  const context = useFilterableContext();

  const selectedItemId = useMemo(() => {
    const item = context.listInnerRef.current?.querySelector(
      `${ITEM_SELECTOR}[${VALUE_ATTR}="${encodeURIComponent(value || '')}"]`,
    );
    return item?.getAttribute('id');
  }, []);

  useEffect(() => {
    if (props.value !== null && props.value !== undefined) {
      store.setState('search', props.value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <input
      ref={forwardedRef}
      {...etc}
      // eslint-disable-next-line react/no-unknown-property
      cmdk-input=""
      autoComplete="off"
      autoCorrect="off"
      spellCheck={false}
      aria-autocomplete="list"
      role="combobox"
      aria-expanded
      aria-controls={context.listId}
      aria-labelledby={context.labelId}
      aria-activedescendant={selectedItemId || undefined}
      id={context.inputId}
      type="text"
      value={isControlled ? props.value : search}
      onChange={(e) => {
        if (!isControlled) {
          store.setState('search', e.target.value);
        }

        onValueChange?.(e.target.value);
      }}
    />
  );
});

Input.displayName = 'Input';

export default Input;
