import type { Match } from 'react-router';

import urlJoin from 'url-join';
import { Redirect } from 'react-router';

import adminPage from 'hocs/admin-page';

import ContactDetails from 'routes/address-book/contacts/contact/details';

type Props = {
  match: Match,
};

export const ContactComponent = (props: Props) => (
  <Redirect from={props.match.path} to={urlJoin(props.match.url, '/details')} />
);

export default adminPage(({
  props: {
    message,
    workspace,
    match,
    fetchContact,
    navigateToContactList,
  },
}) => ({
  title: message({
    id: 'Contact',
    comment: 'Tab title for the details tab in the address book pages.',
  }),
  showAsLink: false,
  modules: [
    [
      {
        path: '/details',
        title: message({
          id: 'Details',
          comment: 'Used as the title for the details tab on the address book contact details page.',
        }),
        section: message({ id: 'Address book', comment: 'Used in the breadcrumb and title on the address book contacts page.' }),
        showWorkspace: true,
        component: ContactDetails,
      },
    ],
  ],
  componentDidMount: () => {
    fetchContact(match.params.id);
  },
  componentDidUpdate: (prevProps: any) => {
    if (prevProps.workspace.id !== workspace.id) {
      navigateToContactList();
    }
  },
}))(ContactComponent);
