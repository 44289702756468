// @flow

export type DuplicateParams = {
  allValues: { [string]: any },
  fieldKey: string,
  otherFieldKey: string,
  text: string,
};

type DuplicateValidator = DuplicateParams => {};

const duplicate: DuplicateValidator = ({
  allValues,
  fieldKey,
  otherFieldKey,
  text,
}) => {
  const errors = {};

  if (allValues[fieldKey] && allValues[fieldKey] === allValues[otherFieldKey]) {
    errors[fieldKey] = text;
  }

  return errors;
};

export default duplicate;
