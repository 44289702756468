import { cloneDeep, uniqueId } from 'lodash';
import type { TextBox } from 'data-validators/entity-schemas/document-box/text-box';

export const generateTextBoxData = (box: TextBox, nodes: []): TextBox => {
  const updatedBox = cloneDeep(box);

  updatedBox.content = {
    data: [
      {
        key: 'text',
        _id: Number(uniqueId()),
        value: { nodes },
      },
    ],
  };

  return updatedBox;
};
