import type { ReactElement } from 'react';
import type { FileRejection as DropzoneFileRejection } from 'react-dropzone';

import { AttachmentBox } from 'data-validators/entity-schemas/document-box/attachment-box';

import ImageFile from 'components/icons/image-file';
import Attachment from 'components/icons/attachment';
import PdfDocument from 'components/icons/pdf-document';
import ZipIcon from 'components/icons/zip';

import {
  fileSizeErrorMessage,
  fileTypeErrorMessage,
  someFilesCouldNotBeUploadedErrorMessage,
  fileUploadingRetryErrorMessage,
  getMaxNumberOfFilesErrorMessage,
  maxSizePerContractErrorMessage,
  getMaxNumberOfFilesAlreadyInDocumentErrorMessage,
  DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR,
  DROPZONE_EXCEED_FILE_SIZE_ERROR,
  DROPZONE_EXCEED_FILE_COUNT_ERROR,
  DROPZONE_INVALID_FILE_ERROR,
  MAX_ATTACHMENT_SIZE,
} from 'components/contract-boxes/constants';

import { FileRejectionWithId } from './types';

export const getAttachmentData = (data: AttachmentBox['content']['data']) => data?.filter((d) => !d._removed);

export const getAttachmentOrderObject = (attachment: AttachmentBox['content']['data'][number]) => {
  if (attachment.id) {
    return { id: attachment.id };
  }

  if (attachment._id) {
    return { _id: attachment._id };
  }

  return null;
};

export const getIcons = (assetExtension: string) => {
  switch (assetExtension) {
    case 'pdf':
      return <PdfDocument />;

    case 'png':
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'bmp':
    case 'tiff':
    case 'tif':
      return <ImageFile />;

    case 'doc':
    case 'docx':
    case 'rtf':
    case 'dotx':
    case 'txt':
    case 'ppt':
    case 'pptx':
    case 'ppsx':
    case 'key':
    case 'keynote':
    case 'numbers':
    case 'csv':
    case 'pages':
    case 'xls':
    case 'xlsx':
    case 'ods':
    case 'xlsb':
    case 'xlsm':
    case 'xml':
      return <Attachment />;

    case 'asice':
      return <ZipIcon />;

    default:
      return null;
  }
};

export const validAttachmentExtensions = 'pdf' || 'asice' || 'csv' || 'bmp' || 'doc' || 'docx' || 'dotx' || 'gif' || 'jpeg' || 'jpg' || 'key' || 'numbers' || 'ods' || 'pages' || 'pdf' || 'png' || 'ppsx' || 'ppt' || 'pptx' || 'rtf' || 'tif' || 'tiff' || 'txt' || 'xls' || 'xlsb' || 'xlsm' || 'xlsx' || 'xml';

export const acceptedExtensions = {
  'text/csv': ['.csv'],
  'text/plain': ['.txt'],
  'application/pdf': ['.pdf', '.asice', '.doc', '.docx', '.dotx', '.key', '.numbers', '.ods', '.pages', '.ppsx', '.ppt', '.pptx', '.rtf', '.xls', '.xlsb', '.xlsm', '.xlsx', '.xml'],
  'image/jpeg': [],
  'image/jpg': [],
  'image/gif': [],
  'image/png': [],
  'image/tiff': [],
  'image/tif': [],
  'image/bmp': [],
};

export const hasErrorCodeInRejections = (
  fileRejections: DropzoneFileRejection[],
  errorCode: string,
) => fileRejections.some((fileRejection) => (
  fileRejection.errors.some((error) => error.code === errorCode)
));

const getPrioritizedErrorMessageFromErrorsList = (
  errors: Array<{code: string}>,
  limitations: { attachmentsCountLimit: number },
): ReactElement | null => {
  if (errors.some((error) => error.code === DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR)) {
    return getMaxNumberOfFilesAlreadyInDocumentErrorMessage(limitations.attachmentsCountLimit);
  }

  if (errors.some((error) => error.code === DROPZONE_EXCEED_FILE_SIZE_ERROR)) {
    return maxSizePerContractErrorMessage;
  }

  if (errors.some((error) => error.code === DROPZONE_EXCEED_FILE_COUNT_ERROR)) {
    return getMaxNumberOfFilesErrorMessage(limitations.attachmentsCountLimit);
  }

  return null;
};

const getSingleFileRejectionSnackbarErrorMessage = (
  rejectedFile: DropzoneFileRejection,
  haveAcceptedFiles: boolean,
  limitations: { attachmentsCountLimit: number },
): ReactElement => {
  const prioritizedErrorMessage = getPrioritizedErrorMessageFromErrorsList(
    rejectedFile.errors,
    limitations,
  );
  if (prioritizedErrorMessage !== null) {
    return prioritizedErrorMessage;
  }

  if (haveAcceptedFiles) {
    return someFilesCouldNotBeUploadedErrorMessage;
  }

  if (rejectedFile.file.size > MAX_ATTACHMENT_SIZE) {
    return fileSizeErrorMessage;
  }

  if (rejectedFile.errors[0].code === DROPZONE_INVALID_FILE_ERROR) {
    return fileTypeErrorMessage;
  }

  return fileUploadingRetryErrorMessage;
};

export const getFileRejectionsSnackbarErrorMessage = (
  fileRejections: FileRejectionWithId[],
  haveAcceptedFiles: boolean,
  limitations: { attachmentsCountLimit: number },
): ReactElement | null => {
  if (fileRejections.length === 0) {
    return null;
  }
  if (fileRejections.length === 1) {
    return getSingleFileRejectionSnackbarErrorMessage(
      fileRejections[0],
      haveAcceptedFiles,
      limitations,
    );
  }

  if (
    hasErrorCodeInRejections(fileRejections, DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR)
  ) {
    return getMaxNumberOfFilesAlreadyInDocumentErrorMessage(limitations.attachmentsCountLimit);
  }

  if (hasErrorCodeInRejections(fileRejections, DROPZONE_EXCEED_FILE_SIZE_ERROR)) {
    return maxSizePerContractErrorMessage;
  }

  if (hasErrorCodeInRejections(fileRejections, DROPZONE_EXCEED_FILE_COUNT_ERROR)) {
    return getMaxNumberOfFilesErrorMessage(limitations.attachmentsCountLimit);
  }

  if (haveAcceptedFiles) {
    return someFilesCouldNotBeUploadedErrorMessage;
  }

  if (hasErrorCodeInRejections(fileRejections, DROPZONE_INVALID_FILE_ERROR)) {
    return someFilesCouldNotBeUploadedErrorMessage;
  }

  return fileUploadingRetryErrorMessage;
};
