// @flow

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import { useVideoBoxProps } from 'contexts/video-box-props';
import { updateBoxAction } from 'reducers/current-contract';
import Delete from 'components/icons/delete';
import ToolbarButton from 'components/rich-text-editor-toolbars/toolbar-buttons/toolbar-button.jsx';
import { RemoveConfirm } from 'components/modals/remove-confirm';

import style from './remove-video.module.scss';

const modalKey = 'delete video modal';
type Props = {
  disabled: boolean,
};

const RemoveVideo = ({ disabled }: Props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { box } = useVideoBoxProps();
  const { data } = box.content;

  const removedData = data.map((d) => {
    if (d.id) {
      return {
        ...d, _removed: true,
      };
    }
    return null;
  }).filter(Boolean);

  const onRemove = () => {
    dispatch(updateBoxAction({
      ...box,
      config: {
        ...box.config,
        width: 'lg',
        title: false,
      },
      content: {
        ...box?.content,
        data: removedData,
      },
    }));
    setModalOpen(false);
  };

  const getChildren = () => (
    <ToolbarButton
      label={(
        <Message
          id="Remove video"
          comment="The label of the video's toolbar that removes a video from box."
        />
      )}
      isMenuItem={false}
      onTrigger={() => setModalOpen(true)}
      icon={Delete}
      customButtonClass={style.RemoveVideoButton}
      disabled={disabled}
    />
  );

  return (
    <RemoveConfirm
      onConfirm={onRemove}
      onClose={() => setModalOpen(false)}
      isOpen={modalOpen}
      confirmMessage={(
        <Message
          id="Are you sure you want to delete this video?"
          comment="Used as the modal title for video remove confirmation"
        />
      )}
      modalKey={modalKey}
    >
      {getChildren}
    </RemoveConfirm>
  );
};

export default RemoveVideo;
