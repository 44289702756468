/* eslint-disable camelcase */
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { loadContractFont } from 'font-manager';
import log from 'logging';
import userflow from 'userflow.js';

// eslint-disable-next-line import/named
import { trackAgreement } from 'oneflow-client/agreements';
import tagConnectionsReducer from 'reducers/entities/tag-connections';
import agreementsReducer from 'reducers/entities/agreements';
import { resetCurrentContract } from 'reducers/current-contract';
import { setLanguage } from 'reducers/i18n';

import { checkAcl } from 'components/acl';
import { isTemplate } from 'agreement/states';
import { getGuestToken } from 'agreement/selectors';
import LoadingScreen from 'components/agreement-loader/loading-screen';
import ErrorHandler from 'components/agreement-loader/error-handler';

// import { initialiseUserflowForGuests } from 'utils/userflow';

import useDocumentParticipantsListener from './event-handlers/use-document-participants-listener';
import useDocumentStateListener from './event-handlers/use-document-state-listener';
import useAgreementVideoListener from './event-handlers/use-agreement-video-listener';
import { renderPartiallySignedToast } from './partially-signed-toast';

type Props = {
  children: ReactNode,
  agreementId: number,
};

type OnSuccessArgs = {
  normalized: {
    count: number,
    entities: {
      agreements: Record<Oneflow.Agreement['id'], Oneflow.Agreement>
    },
    result: number
  },
}

const DocumentLoader = ({
  children,
  agreementId,
}: Props) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const guestToken = useSelector(getGuestToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const fetchState = useSelector((state) => (
    agreementsReducer.getFetchSelector(state, { id: agreementId })
  ));
  const { error } = fetchState;

  // Agreement state updates
  useDocumentParticipantsListener(agreementId, guestToken);
  useDocumentStateListener(agreementId, guestToken);
  useAgreementVideoListener(agreementId);

  useEffect(() => {
    setLoading(true);
    dispatch(agreementsReducer.fetchAgreement(({
      id: agreementId,
      params: { guestToken },
      pipe: {
        onSuccess: ({ normalized }: OnSuccessArgs) => {
          const agreement = normalized.entities.agreements[normalized.result];
          const { inlineComments = false, suggestions = false } = agreement.config || {};
          const { language } = agreement;

          if (isTemplate(agreement) && path !== '/documents/templates/:id') {
            history.replace(`/documents/templates/${agreementId}`);
            return;
          }
          if (language) {
            dispatch(setLanguage(language));
          }

          loadContractFont(agreement)
            .catch(() => log.error('Typography: Contract font could not be loaded!'))
            .finally(() => {
              setLoading(false);
            });

          // Userflow
          if (userflow.isIdentified()) {
            // NOTE: any attributes set here need to be reset on user identification (userflow.ts)
            // These are temporary until we figure out a long-term solution for sending
            // entitlements in general
            const canUseInlineComments = checkAcl(agreement.acl, 'agreement:comment:create');
            const canUseSuggestions = checkAcl(agreement.acl, 'agreement:suggestion:create');
            userflow.updateUser({
              suggestionsEnabled: suggestions,
              inlineCommentsEnabled: inlineComments,
              canUseInlineComments,
              canUseSuggestions,
            });
          }

          /*
          // Temporarily remove userflow for guests
          if (guestToken) {
            initialiseUserflowForGuests({ inlineComments, suggestions, language });
          }
          */
          renderPartiallySignedToast(agreement);

          trackAgreement(agreement, guestToken);
        },
        onFailure: () => {
          setLoading(false);
        },
      },
    })));

    if (!guestToken) {
      dispatch(tagConnectionsReducer.queryTagConnections({
        name: '/agreements/tagConnections',
        params: { targetId: agreementId },
      }));
    }

    return () => {
      dispatch(resetCurrentContract());
    };
  }, [
    path,
    history,
    agreementId,
    dispatch,
    guestToken,
  ]);

  if ((loading && !error)) {
    return <LoadingScreen isLegacy={false} />;
  }

  if (error) {
    return (
      <ErrorHandler
        agreementId={agreementId}
        error={error}
        guestToken={guestToken}
        isLegacy={false}
      />
    );
  }

  return children;
};

export default DocumentLoader;
