import { Message } from '@oneflowab/pomes';

import { getAgreementDateFormat, formatDateString } from 'date';

import Dot from '../dot';
import style from './style.module.scss';

type Props = {
  agreement: Oneflow.Agreement;
};

const Canceled = ({ agreement }: Props) => {
  const dateFormat = getAgreementDateFormat(agreement?.config?.dateFormat);
  const dateString = formatDateString(agreement?.cancelTime?.toString(), dateFormat);

  return (
    <div className={style.Insight} data-testid="canceled">
      <Dot color="red" />
      <span className={style.SemiBold}>
        <Message
          id="Canceled:"
          comment="Agreement status when the agreement is canceled."
        />
      </span>
      <span data-testid="canceled-date">
        {' '}
        {dateString}
      </span>
    </div>
  );
};

export default Canceled;
