import { Message } from '@oneflowab/pomes';

import { getAgreementDateFormat, formatDateString } from 'date';

import CalendarIcon from 'components/icons/calendar';

import style from './style.module.scss';

type Props = {
  agreement: Oneflow.Agreement;
};

const Awaiting = ({ agreement }: Props) => {
  const dateFormat = getAgreementDateFormat(agreement?.config?.dateFormat);
  const dateString = formatDateString(agreement?.startTime?.toString(), dateFormat);

  return (
    <div className={style.Insight} data-testid="start">
      <CalendarIcon width="10px" />
      <span className={style.SemiBold}>
        <Message
          id="Start:"
          comment="Agreement status when the agreement has not started yet."
        />
      </span>
      <span data-testid="start-date">
        {' '}
        {dateString}
      </span>
    </div>
  );
};

export default Awaiting;
