import NewCrossIcon from 'components/icons/new-cross';

import style from './close-sidebar-button.module.scss';

type Props = {
  onClose: () => void;
};

const CloseSidebarButton = ({ onClose }: Props) => (
  <button type="button" className={style.CloseSidebarButton} onClick={onClose}>
    <NewCrossIcon width={14} />
  </button>
);

export default CloseSidebarButton;
