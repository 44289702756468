// @flow

import { normalize, schema } from 'normalizr';

import { get, extractResponseBodyAsJSON } from 'oneflow-client/core';

const plansSchema = new schema.Entity('plans');

export type NormalizedPlans = {
  entities: {
    plans: Array<Plan>,
    count: number,
  },
};

type PlansNormaliser = (any) => NormalizedPlans;

export const normalizePlans: PlansNormaliser = (plans) => ({
  ...normalize(plans.collection, [plansSchema]),
  count: plans.count,
});

type GetPlans = () => Promise<NormalizedPlans>;

export const getPlans: GetPlans = () => (
  get({ url: '/accounts/plans/' })
    .then(extractResponseBodyAsJSON)
    .then(normalizePlans)
);
