import getFontAssetsDomain from './get-font-asset-domain.js';

const loadWorkSans = () => {
  const normalWorkSans = new FontFace(
    'Work Sans',
    `url(${getFontAssetsDomain()}/resources/fonts/work-sans/work-sans_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicWorkSans = new FontFace(
    'Work Sans',
    `url(${getFontAssetsDomain()}/resources/fonts/work-sans/work-sans_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldWorkSans = new FontFace(
    'Work Sans',
    `url(${getFontAssetsDomain()}/resources/fonts/work-sans/work-sans_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicWorkSans = new FontFace(
    'Work Sans',
    `url(${getFontAssetsDomain()}/resources/fonts/work-sans/work-sans_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalWorkSans, italicWorkSans, boldWorkSans, boldItalicWorkSans];
};

export default loadWorkSans;
