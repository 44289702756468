// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';
import extensionSettings from 'hocs/extension-settings';

import Field from 'components/field';
import Badge from 'components/badge';
import Button from 'components/button';
import TextField from 'components/text-field';
import Edit from 'components/icons/edit';
import { ExtensionConfigurationHeader } from 'components/extension/extension-configuration-header';

import style from './paxml.module.scss';
import PaxmlExportArea from './paxml-export-area';

type Props = {
  extension: Extension,
  updateExtension: Extension => void,
  updateState: UpdateState,
  resetUpdateState: () => void,
  message: MessageTranslator,
};

type FormData = {
  amountType: string | null,
};

const staticTexts = {
  amountObCompensation: 'Belopp: OB-ersättning',
};

export class PaxmlPage extends React.Component<Props> {
  handleSubmit = ({ amountType }: FormData) => {
    const { updateExtension, extension } = this.props;

    updateExtension({
      id: extension.extensionId,
      config: {
        ...extension.config,
        beloppObErsattning: amountType,
      },
    });
  };

  renderModalChildren = (onClick: Function) => (
    <Button
      icon={Edit}
      kind="round"
      outline
      onClick={onClick}
      customClass={style.ModalButton}
    />
  );

  renderAmountType() {
    const { beloppObErsattning } = this.props.extension.config;

    if (!beloppObErsattning) {
      return (
        <Message
          id="Not set"
          comment="Placeholder for setting amount type for the Paxml extension."
        />
      );
    }

    return beloppObErsattning;
  }

  renderModalBody() {
    const { message } = this.props;

    return (
      <div>
        <Field
          name="amountType"
          label={staticTexts.amountObCompensation}
          autoFocus
          component={TextField}
          placeholder={message({
            id: 'Enter amount type',
            comment: 'Placeholder for setting amount type for the Paxml extension.',
          })}
          maxLength={50}
        />
      </div>
    );
  }

  renderEditModal() {
    const {
      message,
      extension,
      updateState,
      resetUpdateState,
    } = this.props;

    return (
      <ModalForm
        title={message({
          id: 'Set amount type',
          comment: 'Modal title for setting amount type for Paxml.',
        })}
        body={this.renderModalBody()}
        onSubmit={this.handleSubmit}
        resetFormState={resetUpdateState}
        formState={updateState}
        initialValues={{
          amountType: extension.config.beloppObErsattning,
        }}
        modalKey="set paxml amount type modal"
      >
        {this.renderModalChildren}
      </ModalForm>
    );
  }

  render() {
    return (
      <>
        <h3 className={style.ExtensionConfigurationHeader}>
          <Message
            id="Validation"
            comment="Section header for the Paxml extension."
          />
        </h3>
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="Please note that the data used in the export is taken directly from the contracts, without any manipulation or validation. This mean you are responsible for ensuring a correct format and that it might not follow the format specified by PAXml."
            comment="Help text for the Paxml extension."
          />
        </div>
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="To validate your PAXml export, please visit the {jsx-start}PAXml website{jsx-end}."
            comment="Help text for the Paxml extension."
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="http://paxml.se/#validera"
          />
        </div>

        <ExtensionConfigurationHeader />

        <div className={style.ExtensionConfigurationBody}>
          <Message
            id='Set the type to be used for each amount data field ("belopp" in Swedish). If the type is left empty, then the type will be omitted for that amount.'
            comment="Help text for the Paxml extension."
          />
        </div>
        <div className={style.AmountType}>
          <span className={style.Label}>
            <Badge label={staticTexts.amountObCompensation} kind="name" />
          </span>
          {this.renderAmountType()}
          {this.renderEditModal()}
        </div>
        <h3 className={style.ExtensionConfigurationHeader}>
          <Message
            id="Export contract data"
            comment="Section header for the Paxml extension."
          />
        </h3>
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="Select which signed contracts to export data from, based on the date the contract becomes active."
            comment="Help text for the Paxml extension."
          />
        </div>
        <PaxmlExportArea />
      </>
    );
  }
}

export default extensionSettings(PaxmlPage);
