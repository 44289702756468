// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';

import useCurrentActiveMarks from 'hooks/rich-text-editor/use-current-active-marks';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { isMarkActive, toggleMark } from './mark-plugin';
import ToolbarButton from '../toolbar-button';

type Props = {
  format: any,
  icon: React.Node,
  isMenuItem: boolean,
  label: React.Node,
  disabled: boolean,
};

const MarkButton = ({
  format,
  icon,
  isMenuItem,
  label,
  disabled,
}: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();
  const currentActiveMarks = useCurrentActiveMarks(editor);

  return (
    <ToolbarButton
      isMenuItem={isMenuItem}
      label={label}
      isActive={isMarkActive(editor, format, currentActiveMarks)}
      onTrigger={() => {
        focusEditor();
        toggleMark(editor, format, currentActiveMarks);
      }}
      icon={icon}
      disabled={disabled}
    />
  );
};

export default MarkButton;
