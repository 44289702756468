import type { ReactNode } from 'react';
import sessionStorage from 'utils/session-storage';
import { useSelector } from 'react-redux';
import { checkAcl } from 'components/acl';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { BOX_DURATION } from 'agreement/constants';

import type { FlattenedAiData } from 'components/modals/import-contract/helpers';
import { ImportSparkle } from 'components/import-sparkle/import-sparkle';
import style from './import-wrapper.module.scss';

type Props = {
  agreementId: number;
  boxId: number;
  boxType: number;
  children: ReactNode;
};

export const ImportWrapper = ({
  agreementId, boxId, boxType, children,
}: Props) => {
  const currentWorkspace = useSelector(getCurrentWorkspaceSelector);
  const isAiImportOptionEnabled = checkAcl(currentWorkspace.acl, 'collection:ai_import:use');
  const storedData = sessionStorage.getItem('extracted_data');
  const extractedData = storedData ? JSON.parse(storedData)[agreementId] as FlattenedAiData : null;
  const isAiExtracted = extractedData ? Boolean(extractedData.boxes[boxId]) : false;

  if (boxType !== BOX_DURATION || !isAiImportOptionEnabled || !isAiExtracted) {
    return <>{children}</>;
  }

  return (
    <div className={style.ImportWrapper}>
      <div className={style.ImportSparkleContainer} data-testid="import-sparkle-container">
        <ImportSparkle agreementId={agreementId} type="boxes" matchId={boxId} />
      </div>
      {children}
    </div>
  );
};
