import { useEffect, useState, useMemo } from 'react';
import clsx from 'clsx';

import Media from 'react-media';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import { LogoSymbol, LogoOneflow } from 'components/logo';
import NewArrowRight from 'components/icons/new-arrow-right';
import ChevronDownIcon from 'components/icons/chevron-down';
import {
  getErrorMessages,
  getErrorStatusCode,
  loadWorkSansFont,
} from 'components/agreement-loader/error-handler/error-helpers';

import style from './error-screen-template.module.scss';

const getLogo = (isMobile: boolean) => {
  if (isMobile) {
    return <LogoOneflow width="90px" height="20px" isDark />;
  }
  return <LogoSymbol isDark height="70px" />;
};

export const renderOneflowButton = (isGuest: boolean) => {
  const oneflowButtonClasses = clsx(
    style.ButtonWithIcon,
    style.ButtonWithIconYellow,
  );

  if (isGuest) {
    return (
      <a
        href="https://app.oneflow.com/signup/?origin=website"
        className={oneflowButtonClasses}
      >
        <Message
          id="Try Oneflow free"
          comment="Link button text for going to the dashboard from the change email page"
        />
        <NewArrowRight />
      </a>
    );
  }

  return (
    <a
      href="/contracts"
      className={oneflowButtonClasses}
    >
      <Message
        id="Go to Oneflow"
        comment="Link button text for going to the dashboard from the change email page"
      />
      <NewArrowRight />
    </a>
  );
};

type Props = {
  error: any,
  isGuest: boolean,
};

const ErrorScreenTemplate = ({
  error,
  isGuest,
}: Props) => {
  const [isContactInfoVisible, setIsContactInfoVisible] = useState(false);
  const oneflowContactsClasses = clsx(style.OneflowContacts, {
    [style.OneflowContactsVisible]: isContactInfoVisible,
    [style.OneflowContactsHidden]: !isContactInfoVisible,
  });
  const chevronIconClasses = clsx(style.ChevronIcon, {
    [style.Inverted]: isContactInfoVisible,
  });
  const statusCode = useMemo(() => getErrorStatusCode(error), [error]);
  const errorMessages = getErrorMessages(error, isGuest);
  const showStatusCode = Boolean(statusCode) && statusCode !== 502;
  const contentButtonsClasses = clsx(style.ContentButtons, {
    [style.BadGateway]: statusCode === 502,
  });
  const { header, body } = errorMessages;

  useEffect(() => {
    loadWorkSansFont();
  }, []);

  return (
    <div className={style.ErrorScreen}>
      <div className={style.Logo}>
        <Media query={{ maxWidth: '1024px' }}>
          {(isMobile) => getLogo(isMobile)}
        </Media>
      </div>
      <div className={style.Content}>
        <div className={style.ContentHeaders}>
          {showStatusCode && <h1>{statusCode}</h1>}
          <h1 className={style.HeaderPrimary}>
            {header.primary}
          </h1>
          {header.secondary && <h1>{header.secondary}</h1>}
          {header.tertiary && <h1>{header.tertiary}</h1>}
        </div>
        <div className={style.ContentInfo}>
          <p>{body.primary}</p>
          {body.secondary && <p>{body.secondary}</p>}
          {body.tertiary && <p>{body.tertiary}</p>}
        </div>
        <div className={contentButtonsClasses}>
          {renderOneflowButton(isGuest)}
          <div className={style.HelpButton}>
            <Button
              kind="special"
              onClick={() => setIsContactInfoVisible(!isContactInfoVisible)}
            >
              <span className={style.ButtonWithIcon}>
                <Message
                  id="Get help"
                  comment="Text showing on error page"
                />
                <ChevronDownIcon className={chevronIconClasses} />
              </span>
            </Button>
            <div className={oneflowContactsClasses}>
              <a href="tel:+46851729770">
                <Message
                  id="All countries"
                  comment="Text showing on error page"
                />
                : +46 8 517 297 70
              </a>
              <a href="mailto:support@oneflow.com">
                <Message
                  id="Email"
                  comment="Text showing on error page"
                />
                : support@oneflow.com
              </a>
            </div>
            <div className="ErrorScreenDust" />
          </div>
        </div>
      </div>
      <div className={style.ColumnsWrapper}>
        <div className={style.LeftColumn} />
        <div className="ErrorScreenRight" />
      </div>
      <div className="ErrorScreenGradient" />
    </div>
  );
};

export default ErrorScreenTemplate;
