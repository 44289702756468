import type { ComponentProps } from 'react';

import Button from 'components/button';

import style from './button.module.scss';

type Props = {
  children: React.ReactNode;
  onClick?: ComponentProps<typeof Button>['onClick'];
};

const ToastButton = ({ children, onClick }: Props) => (
  <Button
    outline
    customClass={style.Button}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default ToastButton;
