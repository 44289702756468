import { useEffect, useState } from 'react';
import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import clsx from 'clsx';

import { getGuestToken } from 'agreement/selectors';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import { fetchParticipantEidSignMethods } from 'oneflow-client/agreement-participants';

import Confirmable from 'components/confirmable';
// eslint-disable-next-line import/named
import { ApiError, unknownApiError, getErrorMessage } from 'components/api-error';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import EidCountryPicker from 'components/eid-country-picker';
import SignMethodsSelector from './sign-methods-select';

import style from './electronic-id-options.module.scss';

type SignatureLevel = 'AdES' | 'QES';
type SignMethod = {
  countries: string[],
  name: string,
  // TO-DO signatureLevels:
  // A list of signature levels the signing method can be used for
  // If the signature_mode = basic only AdES methods should be shown
  // If the signature_mode = qualified, only QES methods should be shown
  signatureLevels: SignatureLevel[],
};

const getBrowserLanguages = () => {
  const { navigator } = window;

  const languages = navigator?.languages;

  if (!navigator || !languages || languages.length === 0) {
    return ['en'];
  }

  return languages;
};

const getFirstMatchingCountryCode = (
  browserLanguages: string[],
  countryCodes: string[],
): string | undefined => (
  browserLanguages.find((lang) => countryCodes.includes(lang))
);

const fetchEidSignMethods = async (
  data: { agreementId: number; participantId: number; guestToken: string },
) => {
  const response = await fetchParticipantEidSignMethods(data);
  return response.collection;
};

const processSignMethods = (
  collection: SignMethod[],
  setAvailableSignMethods: (methods: SignMethod[]) => void,
  setSelectedCountry: (country: string) => void,
  setFilteredSignMethods: (methods: SignMethod[]) => void,
) => {
  setAvailableSignMethods(collection);

  const countryCodes = collection.flatMap((method: SignMethod) => method.countries);
  const browserLanguages = getBrowserLanguages()?.map((lang: string) => lang.toUpperCase()) || [];
  const matchCountry = getFirstMatchingCountryCode(browserLanguages, countryCodes);

  if (matchCountry) {
    setSelectedCountry(matchCountry);
    const filteredMethods = collection.filter((method) => (
      method.countries.includes(matchCountry)
    ));
    setFilteredSignMethods(filteredMethods);
  }
};

type Props = {
  myParticipantWhenSignatory: Oneflow.Participant,
  onClose: () => void,
  onStepComplete: () => void,
  onSyncStepData: ({ signMethodName }: { signMethodName: string }) => void,
  agreement: Oneflow.Agreement,
};

const ElectronicIdOptions = ({
  myParticipantWhenSignatory,
  onClose,
  onStepComplete,
  onSyncStepData,
  agreement,
}: Props) => {
  const [apiError, setApiError] = useState<Error | null>(null);
  const [availableSignMethods, setAvailableSignMethods] = useState<SignMethod[]>([]);
  const [filteredSignMethods, setFilteredSignMethods] = useState<SignMethod[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedMethod, setSelectedMethod] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const guestToken = useSelector(getGuestToken);
  const currentLanguage = useSelector(getCurrentLanguageSelector);
  const methodsToShow = filteredSignMethods.length ? filteredSignMethods : availableSignMethods;

  const updateSelectedMethod = (signMethodName: string) => {
    setSelectedMethod(signMethodName);
  };

  const onContinueClick = () => {
    onSyncStepData({ signMethodName: selectedMethod });
    onStepComplete();
  };

  const onCountryChange = (value: string) => {
    setSelectedCountry(value);
    setSelectedMethod('');

    const filteredMethods = availableSignMethods.filter((method) => (
      method.countries.includes(value)
    ));
    setFilteredSignMethods(filteredMethods);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = {
          agreementId: agreement.id,
          participantId: myParticipantWhenSignatory.id,
          guestToken,
        };
        const collection = await fetchEidSignMethods(data);
        processSignMethods(
          collection,
          setAvailableSignMethods,
          setSelectedCountry,
          setFilteredSignMethods,
        );
        setLoading(false);
      } catch (error) {
        setApiError(error as Error);
        setLoading(false);
      }
    };

    fetchData();
  }, [agreement, guestToken, myParticipantWhenSignatory]);

  const getActions = ({ closeConfirmation }: { closeConfirmation: () => void }) => (
    <>
      <CancelButton
        onClick={closeConfirmation}
        modalKey="sign contract eid sign method options modal"
      />
      <Button
        onClick={onContinueClick}
        kind="secondary"
        disabled={!selectedMethod}
      >
        <Message
          id="Continue"
          comment="Button text in a modal to go to the next step."
        />
      </Button>
    </>
  );

  return (
    <Confirmable
      isLoading={loading}
      customBodyClass={style.ModalBody}
      header={(
        <Message
          id="Document signing"
          comment="Modal title for choosing sign method"
        />
      )}
      actions={getActions}
      body={(
        <>
          <div className={style.CountryPickerContainer}>
            <p className={style.CountryPickerLabel}>
              <Message id="Country" comment="Label for the country filter" />
            </p>
            <div className={style.CountryPicker}>
              <EidCountryPicker
                currentLanguage={currentLanguage}
                selectedCountry={selectedCountry}
                onCountryChange={onCountryChange}
                disabled={availableSignMethods.length <= 1}
                availableCountries={
                  availableSignMethods
                    .map((method) => method.countries)
                    .flat()
                }
              />
            </div>
          </div>
          {apiError && (
            <ApiError
              customMessage={getErrorMessage(get(apiError, 'body.api_error_code') || '') || unknownApiError}
            />
          )}
          <div className={style.SignMethodsContainer}>
            <p className={style.SignMethodsTitle}>
              <Message
                id="Choose signing method"
                comment="CTA in a modal where user can select signing method"
              />
            </p>
            <div className={clsx(style.SignMethods, {
              [style.Centered]: methodsToShow.length <= 1,
            })}
            >
              <SignMethodsSelector
                methods={isEmpty(selectedCountry) ? availableSignMethods : methodsToShow}
                updateSelectedMethod={updateSelectedMethod}
              />
            </div>
          </div>
        </>
      )}
      onClose={onClose}
      modalKey="sign contract eid sign method options modal"
    />
  );
};

export default ElectronicIdOptions;
