// @flow

import * as React from 'react';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Button from 'components/button';
import Confirmable from 'components/confirmable';
import CircularSpinner from 'components/icons/circular-spinner';
import Delete from 'components/icons/delete';
import LogoutIcon from 'components/icons/logout';

import style from './salesforce-revoke-connection.module.scss';

export type LocalizeProps = {
  message: MessageTranslator,
}
export type Props = LocalizeProps & {
  revokeState: RpcState,
  resetRevokeState: () => void,
  revokeConnection: () => void,
};

export const handleConfirm = ({ revokeConnection, isLoading }: Props) => () => {
  if (isLoading) {
    return;
  }

  revokeConnection();
};

export const RevokeConnectionComponent = ({
  message,
  revokeState,
  revokeConnection,
  resetRevokeState,
}: Props) => {
  const renderBody = () => (
    <>
      <p>
        <Message
          id="Are you sure you want to remove the connection to your current Salesforce organisation?"
          comment="Warning message in modal when attempting to remove a contract"
        />
      </p>
      <p>
        <Message
          id="Until you re-authenticate, no contract updates will be sent from Oneflow to Saleforce"
          comment="Warning message in modal when attempting to remove a contract"
        />
      </p>
    </>
  );

  const getActions = () => (
    <Button
      color="thunder"
      icon={revokeState.loading ? CircularSpinner : LogoutIcon}
      disabled={revokeState.loading}
      onClick={revokeConnection}
      data-testid="confirm"
    >
      <Message id="Confirm" comment="Used to confirm changes in a modal" />
    </Button>
  );

  const getChildren = (onClick: () => void) => (
    <Button
      customClass={style.DeleteButton}
      kind="round"
      outline
      danger
      onClick={onClick}
      icon={<Delete />}
      data-testid="revoke-connection-confirmable"
    />
  );

  return (
    <Confirmable
      header={message({
        id: 'Confirm removing connection',
        comment: 'Confirm modal title.',
      })}
      body={renderBody()}
      actions={getActions}
      error={revokeState.error}
      success={revokeState.success}
      onEnter={handleConfirm({ revokeConnection, isLoading: revokeState.loading })}
      onOpen={resetRevokeState}
      modalKey="remove salesforce connection modal"
    >
      {getChildren}
    </Confirmable>
  );
};

export default localize<Props>(RevokeConnectionComponent);
