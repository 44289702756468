// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import { Message } from '@oneflowab/pomes';

import { withWorkspaceNamespace } from 'hocs/with-current-workspace';

import { checkAcl } from 'components/acl';

export type Props = {
  workspace: Workspace,
};

export const getContractsCount = (count?: number) => (
  <Message
    id="{count} contract"
    pluralId="{count} contracts"
    pluralCondition="count"
    values={{
      count: count || 0,
    }}
    comment="Showing number of contracts a workspace contains"
  />
);

export const WorkspaceContractsCount = ({ workspace }: Props) => {
  if (!checkAcl(workspace.acl, 'collection:module:agreement:use')) {
    return <span>{getContractsCount(workspace.agreementCount)}</span>;
  }

  return (
    <Link to={withWorkspaceNamespace(workspace)('contracts')}>
      {getContractsCount(workspace.agreementCount)}
    </Link>
  );
};
