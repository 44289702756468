// @flow

import * as React from 'react';
import omit from 'lodash/omit';
import type Moment from 'moment';

import style from './date-presets.module.scss';

export type DateRange = {
  startDate?: Moment,
  endDate?: Moment,
};

export type OnDatesChange = DateRange => void;

export type DatePresetsContextType = {
  onDatesChange: OnDatesChange,
};

export type DatePresetsProps<T> = DatePresetsContextType & {
  ...T,
};

export const DatePresetsContext = React.createContext<DatePresetsContextType>({});

const getDisplayName = (Component) => {
  const displayName = Component.displayName || Component.name || 'Component';

  return `DatePresetsContext(${displayName})`;
};

function withDatePresets<T>(
  Component: React.ComponentType<T>,
): React.ComponentType<DatePresetsProps<T>> {
  const WithDatePresets = (props: DatePresetsProps<T>) => {
    const { onDatesChange } = props;
    const componentProps: T = omit(props, 'onDatesChange');

    return (
      <DatePresetsContext.Provider
        value={{
          onDatesChange,
        }}
      >
        <div className={style.Presets}>
          <Component {...componentProps} />
        </div>
      </DatePresetsContext.Provider>
    );
  };

  WithDatePresets.displayName = getDisplayName(Component);

  return WithDatePresets;
}

export default withDatePresets;
