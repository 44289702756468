import style from './read-only.module.scss';

type Props = {
  value: string,
}

const ReadOnly = (({ value }: Props) => (
  <span className={style.ReadOnly}>
    {value}
  </span>
));

export default ReadOnly;
