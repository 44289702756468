import { Message } from '@oneflowab/pomes';

import Accordion from 'components/document-tabs/settings-tab/accordion';
import SigningAndSecurity from 'components/document-tabs/settings-tab/sections/signing-and-security';
import style from './accordion.module.scss';

type Props = {
  agreementId: number
};

const SigningAndSecuritySection = ({ agreementId }: Props) => {
  const title = (
    <Message
      id="Signing and security"
      comment="Header for signing and security section where you can change signing and security settings for the agreement."
    />
  );

  return (
    <Accordion title={title} name="signingAndSecurity" contentClassName={style.Content}>
      <SigningAndSecurity agreementId={agreementId} />
    </Accordion>
  );
};

export default SigningAndSecuritySection;
