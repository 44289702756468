import { filter, flatMap, uniq } from 'lodash';

export const getEidHubList = (message, selectedCountry) => {
  let eidList = [
    {
      key: 'eidHubBeBeidSign',
      title: '.beID',
      isEnabled: true,
      isVisible: true,
      countries: [
        'BE',
      ],
    },
    {
      key: 'eidHubEeEsteidSign',
      title: 'Estonian ID card',
      isEnabled: true,
      isVisible: true,
      countries: [
        'EE',
      ],
    },
    {
      key: 'eidHubEeEsteidMobileSign',
      title: 'Estonian Mobile-ID',
      isEnabled: true,
      isVisible: true,
      countries: [
        'EE',
      ],
    },
    {
      key: 'eidHubFrejaSign',
      title: 'Freja eID',
      isEnabled: true,
      isVisible: true,
      countries: [
        'DK', 'NO', 'SE', 'UA', 'GB', 'EU',
      ],
    },
    {
      key: 'eidHubFiFtnSign',
      title: 'Finnish Trust Network',
      isEnabled: true,
      isVisible: true,
      countries: [
        'FI',
      ],
    },
    {
      key: 'eidHubNlIdinSign',
      title: 'iDIN',
      isEnabled: true,
      isVisible: true,
      countries: [
        'NL',
      ],
    },
    {
      key: 'eidHubBeItsmeSign',
      title: 'itsme',
      isEnabled: true,
      isVisible: true,
      countries: [
        'BE',
      ],
    },
    {
      key: 'eidHubDkMitidSign',
      title: 'MitID',
      isEnabled: true,
      isVisible: true,
      countries: [
        'DK',
      ],
    },
    {
      key: 'eidHubNoBankidSign',
      title: message({ id: 'Norwegian BankID', comment: 'Title for an electronic ID' }),
      isEnabled: true,
      isVisible: true,
      countries: [
        'NO',
      ],
    },
    {
      key: 'eidHubDeNpaSign',
      title: 'Personalausweis',
      description: message({ id: 'coming soon', comment: 'Description for an electronic ID' }),
      isEnabled: false,
      isVisible: false,
      countries: [
        'DE',
      ],
    },
    {
      key: 'eidHubSmartidSign',
      title: 'Smart-ID',
      isEnabled: true,
      isVisible: true,
      countries: [
        'EE',
        'LV',
        'LT',
      ],
    },
    {
      key: 'eidHubSeBankidSign',
      title: message({ id: 'Swedish BankID', comment: 'Title for an electronic ID' }),
      description: message({ id: 'coming soon', comment: 'Description for an electronic ID' }),
      isEnabled: false,
      isVisible: false,
      countries: [
        'SE',
      ],
    },
    {
      key: 'eidHubSimplySignSign',
      title: 'SimplySign',
      description: message({ id: 'contact us', comment: 'Description for an electronic ID' }),
      isEnabled: false,
      isVisible: true,
      countries: [
        'PL',
      ],
    },
    {
      key: 'eidHubZealidSign',
      title: 'ZealID',
      description: message({ id: 'contact us', comment: 'Description for an electronic ID' }),
      isEnabled: false,
      isVisible: true,
      countries: [
        'DK',
        'FI',
        'NO',
        'SE',
      ],
    },
    {
      key: 'eidHubEvrotrustSign',
      title: 'Evrotrust',
      description: message({ id: 'contact us', comment: 'Description for an electronic ID' }),
      isEnabled: false,
      isVisible: true,
      countries: [
        'DK',
        'FI',
        'NO',
        'SE',
      ],
    },
    {
      key: 'eidHubDTrustSignMeSign',
      title: 'sign-me',
      description: message({ id: 'contact us', comment: 'Description for an electronic ID' }),
      isEnabled: false,
      isVisible: true,
      countries: [
        'DE',
        'DK',
        'FI',
        'NO',
        'SE',
      ],
    },
  ];

  if (selectedCountry) {
    eidList = eidList.filter((item) => item.countries.includes(selectedCountry));
  }

  return eidList;
};

export const getUniqueCountries = () => {
  const message = ({ id }) => id;

  const items = getEidHubList(message);

  // Filter items based on isVisible conditions
  const filteredItems = filter(items, { isVisible: true });

  // Then, extract unique countries from the filtered items
  const countries = flatMap(filteredItems, (item) => item.countries);
  const uniqueCountries = uniq(countries);

  return uniqueCountries;
};
