import {
  Message,
} from '@oneflowab/pomes';
import LockIcon from 'components/icons/lock';
import Tooltip from 'components/tooltip';

import style from './private-event-indicator.module.scss';

const PrivateEventIndicator = () => (
  <div className={style.PrivateEventIndicator}>
    <Tooltip
      message={(
        <Message
          id="Only colleagues who have access to this document can see this event."
          comment="Tooltip text for the internal event indicator"
        />
      )}
      side="top"
      zIndex="10004"
    >
      <span>
        <LockIcon width="14px" height="14px" />
        <Message id="Internal" comment="Label to indicate that an event is only shown to colleagues in your own party" />
      </span>
    </Tooltip>
  </div>
);

export default PrivateEventIndicator;
