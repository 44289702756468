import { Message } from '@oneflowab/pomes';
import get from 'lodash/get';

type MemberType = {
  memberCount : Oneflow.Group['memberCount']
}

export const Members = ({ memberCount }: MemberType) => (
  <Message
    id="{count} member"
    pluralId="{count} members"
    pluralCondition="count"
    values={{
      count: get(memberCount, 'active', 0),
    }}
    comment="Showing number of members in a group"
  />
);
