import { connect } from 'react-redux';

import positionsReducer from 'reducers/entities/positions';
import groupsReducer from 'reducers/entities/groups';
import positionGroupsReducer from 'reducers/entities/position-groups';
import { getPositionFromSessionSelector } from 'reducers/session';

import UserGroups from './user-groups';

const queryName = ({ id }) => `admin/users/${id}/groups`;

export const mapStateToProps = (state, ownProps) => {
  const position = positionsReducer.getPositionSelector(state, { id: ownProps.match.params.id });
  const positionGroupIds = positionGroupsReducer.getQuerySelector(state, {
    name: queryName({ id: ownProps.match.params.id }),
  });
  const groups = groupsReducer.getGroupsSelector(state, { ids: positionGroupIds.result });

  const acl = {
    position: getPositionFromSessionSelector(state).acl,
  };

  return {
    position,
    groups,
    query: positionGroupIds,
    acl,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  queryPositionGroups: ({ pagination } = {}) => {
    dispatch(positionGroupsReducer.queryPositionGroups({
      name: queryName({ id: ownProps.match.params.id }),
      pagination,
      params: {
        positionId: ownProps.match.params.id,
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
