// @flow

import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';

import style from './inline-edit.module.scss';

type Props = {
  customClassName?: any,
  onEditFolderHandler: (string) => void,
  value: string,
}

const InlineEdit = ({
  customClassName,
  onEditFolderHandler,
  value,
}: Props) => {
  const [editingValue, setEditingValue] = useState(value);
  const maxChars = 130;
  const currentChars = maxChars - editingValue.length;
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [inputRef]);

  useEffect(() => {
    const input = inputRef?.current;

    if (inputRef?.current) {
      input.style.width = 'auto';
      input.style.width = `${input.scrollWidth}px`;
    }
  }, [editingValue]);

  const handleFocus = (event) => event.target.select();

  const onBlur = (event) => {
    const e = event;
    e.target.value = e.target.value.trim();

    if (e.target.value.length > 130) {
      return null;
    }

    return onEditFolderHandler(e.target.value);
  };

  const onChange = (event) => {
    setEditingValue(event.target.value);
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.stopPropagation();
      event.target.blur();
    }
  };

  const inputContainerClasses = clsx(style.InputContainer, customClassName, {
    [style.Error]: editingValue.length > maxChars,
  });

  return (
    <div className={inputContainerClasses}>
      <input
        aria-label="folder name"
        name="folder"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        ref={inputRef}
        type="text"
        value={editingValue}
      />
      <div>
        <span className={style.Chars}>{currentChars}</span>
      </div>
    </div>
  );
};

export default InlineEdit;
