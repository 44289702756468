// @flow

import React from 'react';

import urlJoin from 'url-join';
import { Redirect } from 'react-router';
import type { Match } from 'react-router';

import { checkAcl } from 'components/acl';
import { type MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';

import WorkspaceDetails from './details';
import WorkspaceAccess from './access';
import WorkspaceDataRetention from './data-retention';
import WorkspaceBranding from './branding';
import WorkspaceAiReview from './ai-review';

export type Props = AdminPageComponentProps & {
  match: Match,
};

export const WorkspaceComponent = (props: Props) => (
  <Redirect from={props.match.path} to={urlJoin(props.match.url, '/details')} />
);

type PropsWithMessage = {
  message: MessageTranslator,
  workspace: Workspace,
  fetchWorkspace: () => void,
  queryUsersState: Query,
  queryGroupsState: Query,
};

export const propsMapper = ({
  props: {
    workspace,
    fetchWorkspace,
    message,
  },
}: { props: PropsWithMessage }) => {
  const hasBrandingAccess = checkAcl(workspace.acl, 'collection:update:branding');
  const hasDataRetentionAccess = checkAcl(workspace.acl, 'collection:data_retention_policy:update');
  const hasAiReviewAccess = checkAcl(workspace.acl, 'collection:ai_review:admin');

  const getWorkspaceModules = () => {
    const workspaceModules = [
      {
        path: '/details',
        title: message({
          id: 'Details',
          comment: 'Tab title for the workspace details page.',
        }),
        section: message({
          id: 'Workspaces',
          comment: 'Used as the title for the section.',
        }),
        component: WorkspaceDetails,
      },
      {
        path: '/access',
        title: message({
          id: 'Access',
          comment: 'Tab title for the workspace access page.',
        }),
        section: message({
          id: 'Workspaces',
          comment: 'Used as the title for the section.',
        }),
        component: WorkspaceAccess,
      },
    ];

    if (hasBrandingAccess) {
      workspaceModules.push({
        path: '/branding',
        title: message({
          id: 'Branding',
          comment: 'Tab title for the workspace branding page.',
        }),
        section: message({
          id: 'Workspaces',
          comment: 'Used as the title for the section.',
        }),
        component: WorkspaceBranding,
      });
    }

    if (hasDataRetentionAccess) {
      workspaceModules.push({
        path: '/data-retention',
        title: message({
          id: 'Data retention',
          comment: 'Tab title for the workspace data retention page.',
        }),
        section: message({
          id: 'Workspaces',
          comment: 'Used as the title for the section.',
        }),
        component: WorkspaceDataRetention,
      });
    }

    if (hasAiReviewAccess) {
      workspaceModules.push({
        path: '/ai-review',
        title: 'AI Review',
        section: message({
          id: 'Workspaces',
          comment: 'Used as the title for the section.',
        }),
        component: WorkspaceAiReview,
        isBeta: true,
      });
    }

    return workspaceModules;
  };

  return ({
    title: workspace.name || '',
    showAsLink: false,
    modules: [
      getWorkspaceModules(),
    ],
    componentDidMount: () => {
      fetchWorkspace();
    },
  });
};

export default adminPage(propsMapper)(WorkspaceComponent);
