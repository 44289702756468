// @flow

import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import { Message, localize } from '@oneflowab/pomes';
import AiAssistTypewriterContainer from './ai-assist-typewriter-container';
import OneflowAvatar from './oneflow-chat-logo.png';
import style from './ai-assist.module.scss';

type Props = {
  text: any,
  error: string | Error,
  insertIntoAField?: boolean,
};

const EXCEED_LIMIT_ERROR_CODE = 429;

const renderErrorMessage = (error) => {
  if (error?.response?.status === EXCEED_LIMIT_ERROR_CODE) {
    return (
      <Message
        id="You've exceeded your usage limit."
        comment="Error message for when a user has gone over their usage limit"
      />
    );
  }
  return (
    <Message
      id="Oops! Something went wrong. Please try again."
      comment="Generic error message for when something went wrong"
    />
  );
};

const AiAssistTypewriter = ({
  text,
  error,
  insertIntoAField,
}: Props) => {
  const textContainerRef = useRef(null);
  const [followScroll, setFollowScroll] = useState(true);

  useEffect(() => {
    if (textContainerRef.current && followScroll) {
      textContainerRef.current.scrollTop = textContainerRef.current.scrollHeight;
    }
  }, [text, followScroll]);

  const debouncedHandleScroll = debounce((event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const atBottom = scrollHeight - scrollTop <= clientHeight + 5;

    if (!atBottom) {
      setFollowScroll(false);
    }
    if (atBottom && !followScroll) {
      setFollowScroll(true);
    }
  }, 100);

  const handleScroll = useCallback((event) => {
    debouncedHandleScroll(event);
  }, [debouncedHandleScroll]);

  const className = clsx(
    insertIntoAField
      ? [style.LogoTextContainer, style.SmallerMargins]
      : style.LogoTextContainer,
  );

  return (
    <div className={style.TypewriterContainer} ref={textContainerRef} onScroll={handleScroll}>
      <div className={className}>
        <div className={style.LogoContainer}>
          <img src={OneflowAvatar} height="32px" width="32px" alt="oneflow avatar" />
        </div>
        <AiAssistTypewriterContainer
          error={error}
          text={text}
          renderErrorMessage={renderErrorMessage}
        />
      </div>
    </div>
  );
};

export default localize<Props>(AiAssistTypewriter);
