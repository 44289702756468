import { Message } from '@oneflowab/pomes';

type User = {
  active: 0 | 1,
}

const emailValidationText = (user: User) => {
  const { active } = user;

  if (active) {
    return (
      <Message
        id="This email address is already taken by an active user in your user list."
        comment="Form validation error message."
      />
    );
  }

  return (
    <Message
      id="This email is taken by an inactive user, you can activate this user from your user list."
      comment="Form validation error message."
    />
  );
};

export default emailValidationText;
