/* eslint-disable react/no-unused-prop-types */
// @flow

import React from 'react';
import { localize } from '@oneflowab/pomes';

import FileUploadError from 'components/file-upload/file-upload-error';
import { getFileSize } from 'utils/file';

import Terminate from 'components/icons/terminate.jsx';
import style from './upload-error-alert.module.scss';

export const UPLOAD_FAILURE_ALERT_ID = 'upload-failure-alert';

type Props = {
  errorCode?: number | string,
  maxFileSize: number,
  acceptedFileTypes: [string],
  attachmentsCountLimit: number,
  fileName?: string,
  fileSize?: string,
};

export const GetFileData = ({
  fileName,
  fileSize,
}: Props) => (
  <div className={style.FileMetaData}>
    <div className={style.FileName}>
      {fileName}
    </div>
    <div className={style.FileSize}>
      {fileSize}
    </div>
  </div>
);

// TODO: shall we choose a better name?
export const FileUploadFailureAlertComponent = ({
  errorCode,
  maxFileSize,
  acceptedFileTypes,
  attachmentsCountLimit,
  fileName,
  fileSize,
}: Props) => (
  <div>
    <div className={style.AlertZone} id={UPLOAD_FAILURE_ALERT_ID}>
      <div className={style.UploadingFailure}>
        <Terminate className={style.UploadFailureIcon} />
        <div className={style.UploadFailureAlert}>
          <div className={style.UploadFailureText}>
            <FileUploadError
              errorCode={errorCode}
              maxFileSize={maxFileSize}
              acceptedFileTypes={acceptedFileTypes.join(', ')}
              attachmentsCountLimit={attachmentsCountLimit}
              pdfUploadError
            />
          </div>
          <div>
            <GetFileData
              fileName={fileName}
              fileSize={getFileSize(fileSize, true)}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default localize<Props>(FileUploadFailureAlertComponent);
