// @flow

import isUrl from 'is-url';
import { wrapLink } from 'components/rich-text-editor-toolbars/toolbar-buttons/link-button/plugin';

/* eslint-disable no-param-reassign */
const withLinks = (editor: any) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => element.type === 'link' || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withLinks;
