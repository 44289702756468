import getFontAssetsDomain from './get-font-asset-domain.js';

const loadPoppins = () => {
  const normalPoppins = new FontFace(
    'Poppins',
    `url(${getFontAssetsDomain()}/resources/fonts/poppins/poppins_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicPoppins = new FontFace(
    'Poppins',
    `url(${getFontAssetsDomain()}/resources/fonts/poppins/poppins_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldPoppins = new FontFace(
    'Poppins',
    `url(${getFontAssetsDomain()}/resources/fonts/poppins/poppins_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicPoppins = new FontFace(
    'Poppins',
    `url(${getFontAssetsDomain()}/resources/fonts/poppins/poppins_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalPoppins, italicPoppins, boldPoppins, boldItalicPoppins];
};

export default loadPoppins;
