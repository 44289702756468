import * as React from 'react';

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false);

  React.useLayoutEffect(() => {
    mounted.current = true;

    return () => {
      // is it better to use AbortSignal here and cancel the request?
      mounted.current = false;
    };
  }, []);

  return React.useCallback(
    (...args) => {
      if (mounted.current) {
        dispatch(...args);
      }
    },
    [dispatch],
  );
}

// Usage:
// const {data, error, status, run} = useApi()
// React.useEffect(() => {
//   run(getAgreement(id))
// }, [id, run])

const defaultInitialState = { status: 'pristine', data: null, error: null };
function useApi(initialState) {
  // Prevents object re-creation on every render and the initialState is not
  // subject to change from the parent -> no need to use React.useMemo
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });

  const [{ status, data, error }, dispatch] = React.useReducer(
    (state, action) => ({ ...state, ...action }),
    initialStateRef.current,
  );

  // Is it better to extend the fetch wrappers with AbortSignal later?
  const safeDispatch = useSafeDispatch(dispatch);

  const reset = React.useCallback(
    () => dispatch(initialStateRef.current),
    [],
  );

  const run = React.useCallback(
    async (promise) => {
      if (!promise || !promise.then) {
        throw new Error(
          'The argument passed to useApi().run must be a promise.',
        );
      }
      safeDispatch({ status: 'loading' });

      try {
        const responseData = await promise;

        safeDispatch({ data: responseData, status: 'success' });
        return responseData;
      } catch (responseError) {
        safeDispatch({ error: responseError, status: 'error' });
        return Promise.reject(responseError);
      }
    },
    [safeDispatch],
  );

  const runAll = React.useCallback(
    async (...promises) => {
      if (promises.some((promise) => !promise || !promise.then)) {
        throw new Error(
          'All arguments passed to useApi().runAll must be promises.',
        );
      }
      safeDispatch({ status: 'loading' });

      try {
        // returns array of results
        const responseData = await Promise.all(promises);
        safeDispatch({ data: responseData, status: 'success' });
        return responseData;
      } catch (responseError) {
        safeDispatch({ error: responseError, status: 'error' });
        return Promise.reject(responseError);
      }
    },
    [safeDispatch],
  );

  return React.useMemo(() => ({
    // These booleans are derived from single `status` state variable
    // Thus more safer than usual dedicated state variables where
    // We have to maintian mutual exclusivity explicitly
    isPristine: status === 'pristine',
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',

    error,
    status,
    data,
    run,
    runAll,
    reset,
  }), [data, error, reset, run, runAll, status]);
}

export default useApi;
