// @flow

import * as React from 'react';
import { Redirect } from 'react-router';
import type { ContextRouter } from 'react-router';
import urlJoin from 'url-join';
import { type MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';

import { NotFound } from 'components/errors';

import TemplateGroupDetails from './details';
import DataFields from './data-fields';

export const goToComponentIfSuccess = (component: any, fetchState: FetchState) => {
  if (fetchState.loading) {
    return () => null;
  }

  if (fetchState.error) {
    return () => <NotFound redirect to="/admin/account/extensions/templategroups" />;
  }

  return component;
};

export type Props = {
  ...ContextRouter,
};

export const TemplateGroupComponent = (props: Props) => {
  const { match } = props;

  return (
    <Redirect
      from={match.path}
      to={urlJoin(match.url, '/details')}
    />
  );
};

type PropsWithMessage = {
  fetchState: FetchState,
  message: MessageTranslator,
  fetchTemplateGroup: () => void,
  templateGroup: TemplateGroup,
};

export const propsMapper = ({
  props: {
    fetchState,
    templateGroup,
    fetchTemplateGroup,
    message,
  },
}: { props: PropsWithMessage }) => {
  const templateGroupModules = [{
    path: '/details',
    section: message({
      id: 'Marketplace',
      comment: 'Used as the title for the section.',
    }),
    title: message({
      id: 'Details',
      comment: 'Tab title for the template group details page.',
    }),
    component: goToComponentIfSuccess(TemplateGroupDetails, fetchState),
  }, {
    path: '/data-fields',
    section: message({
      id: 'Marketplace',
      comment: 'Used as the title for the section.',
    }),
    title: message({
      id: 'Data fields',
      comment: 'Tab title for the template group data fields page.',
    }),
    component: goToComponentIfSuccess(DataFields, fetchState),
  }];

  return {
    title: templateGroup.name || '',
    showAsLink: false,
    modules: [
      templateGroupModules,
    ],
    componentDidMount: () => {
      fetchTemplateGroup();
    },
  };
};

export default adminPage(propsMapper)(TemplateGroupComponent);
