import { Message } from '@oneflowab/pomes';

import useClientFeatureFlag from 'hooks/use-client-feature-flag';

import useSaveChangesButton from 'components/document-call-to-actions/actions/buttons-call-to-action/hooks/use-save-changes-button';
import UndoIcon from 'components/icons/undo';
import Tooltip from 'components/tooltip';

import { useState } from 'react';
import style from './discard.module.scss';
import { DiscardModal } from './modal';

const DiscardButton = () => {
  const { disabled } = useSaveChangesButton();
  const isDiscardEnabled = useClientFeatureFlag('discard-changes-without-saving');

  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const discardChanges = (
    <Tooltip
      message={(
        <Message
          id="Discard changes"
          comment="Tooltip message for button that will discard changes made to the document"
        />
      )}
      theme="oneflow"
      side="top"
      messageClassName={style.TooltipMessage}
      sideOffset={7.5}
    >
      <button
        className={style.DiscardButton}
        onClick={() => {
          if (isDiscardEnabled) {
            setIsDiscardModalOpen(true);
            return;
          }

          window.location.reload();
        }}
        disabled={disabled}
      >
        <UndoIcon height="24px" />
      </button>
    </Tooltip>
  );

  if (isDiscardEnabled) {
    return (
      <DiscardModal onClose={() => setIsDiscardModalOpen(false)} isOpen={isDiscardModalOpen}>
        {() => discardChanges}
      </DiscardModal>
    );
  }

  return (discardChanges);
};

export default DiscardButton;
