// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import { getPermissions } from 'oneflow-client/roles';
import type { NormalizedPermissions } from 'oneflow-client/roles';

import apiWrapper from 'sagas/api-wrapper';

import permissions from 'reducers/entities/permissions';

type MapperArgs = {
  data: NormalizedPermissions,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(permissions.setPermissions(data.entities.categories));
}

const mappers = {
  query: {
    mapper,
    request: getPermissions,
  },
};

const permissionsSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: permissions,
  mappers,
});

export default permissionsSaga;
