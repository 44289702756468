import { connect } from 'react-redux';

import positions from 'reducers/entities/positions';
import { requestMe, getLoadingStateSelector, getPositionFromSessionSelector } from 'reducers/session';

import Notifications from './notifications';

export const mapStateToProps = (state) => {
  const { id: positionId, settings } = getPositionFromSessionSelector(state);

  return {
    settings,
    positionId,
    updateSettingsState: positions.getUpdateSettingsSelector(state, { id: positionId }),
    sessionLoading: getLoadingStateSelector(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  updatePositionSettings: ({ positionId, ...data }) => {
    dispatch(positions.updateSettings({
      id: positionId,
      data,
    }));
  },
  requestMe: () => {
    dispatch(requestMe());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
