/* eslint-disable react/no-this-in-sfc */
// @flow

import React from 'react';
import { Message, type MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';
import ActionsMenu from 'components/actions-menu';
import { DeleteBulkAction } from 'components/bulk-action-items/delete';
import { Acl, checkAcl } from 'components/acl';
import { Table } from 'components/table';
import Button from 'components/button';
import Add from 'components/icons/add';
import ActionBar from 'components/action-bar';
import { Tag } from 'components/tags';
import Pagination from 'components/pagination';
import TableFiltering from 'components/table/table-filtering';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { EditMenuItem } from 'components/menu-items/edit';
import ExportTags from 'components/export-tags';
import { LocalizedDateTime } from 'components/localized-date-time';

import AddTag from 'components/modals/add-tag';
import EditTag from 'components/modals/edit-tag';
import { RemoveTagConfirmation } from 'components/modals/remove-tag';
import { DeleteTagsConfirmation } from 'components/modals/delete-tags';

import {
  getContractsCount,
  getTemplatesCount,
} from 'components/workspace-counts';

import style from './tags.module.scss';

export const getTag = (tag: Tag) => (
  <div className={style.TagContainer}>
    <Tag
      handleOverflow
      tagConnection={{ tag }}
      allowSearch
      allowTagChange={false}
      searchGlobally
    />
  </div>
);

type Props = {
  tags: Array<Tag>,
  query: Query,
  account: Account,
  queryTags: QueryFunc,
  message: MessageTranslator,
}

type State = {
  selectedTags: Array<Tag>,
}

export class Tags extends React.Component<Props, State> {
  state = {
    selectedTags: [],
  };

  componentDidMount() {
    const { queryTags } = this.props;

    queryTags({});
  }

  onFilter = (params: QueryFuncArgs) => {
    const { queryTags } = this.props;

    queryTags({ params });
  };

  getTagContractsCount = (tag: Tag) => getContractsCount(tag.agreementCount);

  getTagTemplatesCount = (tag: Tag) => getTemplatesCount(tag.templateCount);

  getDeleteBulkAction = (onClick: () => void) => {
    const { account } = this.props;
    const { selectedTags } = this.state;

    const hasRemovePermission = checkAcl(account.acl, 'account:admin:tag')
      && selectedTags.every((tag) => (
        !tag.system
      ));

    return (
      <DeleteBulkAction
        disabled={!hasRemovePermission}
        onClick={onClick}
      />
    );
  }

  getTableConfig() {
    const { message, tags } = this.props;
    const { selectedTags } = this.state;

    const selectedTagIds = selectedTags.map((tag) => tag.id);

    const actions = [
      <DeleteTagsConfirmation
        ids={selectedTagIds}
        key="remove"
      >
        {this.getDeleteBulkAction}
      </DeleteTagsConfirmation>,
    ];

    return {
      items: tags,
      itemKey: 'id',
      actions,
      columns: [
        {
          name: 'name',
          label: message({
            id: 'Tag',
            comment: 'Table column header in account tags page for the tag name field',
          }),
          type: 'cell',
          value: getTag,
          className: style.TagColumn,
        },
        {
          name: 'contracts',
          label: message({
            id: 'Contracts',
            comment: 'Table column header in account tags page for the contracts column',
          }),
          type: 'cell',
          value: this.getTagContractsCount,
        },
        {
          name: 'templates',
          label: message({
            id: 'Templates',
            comment: 'Table column header in account tags page for the templates column',
          }),
          type: 'cell',
          value: this.getTagTemplatesCount,
        },
        {
          name: 'created',
          label: message({
            id: 'Created',
            comment: 'Table column header in account tags page for the created column',
          }),
          type: 'cell',
          value: this.getCreatedDate,
        },
        {
          name: 'actions',
          label: <Message id="Actions" comment="Table header" />,
          type: 'actions',
          value: this.getActions,
        },
      ],
    };
  }

  selectionChangeHandler = (ids: Array<number>) => {
    const { tags } = this.props;

    const selectedTags = ids.map((id) => (
      tags.find((tag) => tag.id === id)
    ));

    this.setState({ selectedTags });
  }

  getCreateTagButton = (onClick: () => void) => (
    <Button
      data-testid="add-tag"
      icon={Add}
      kind="primary"
      onClick={onClick}
    >
      <Message
        id="Create tag"
        comment="Used in the main create tag button"
      />
    </Button>
  );

  getCreatedDate = (tag: Tag) => (
    <LocalizedDateTime datetime={tag.createdTime} />
  );

  getEditTagMenuItem = (tag: Tag) => (onClick: () => void) => {
    const { account } = this.props;

    return (
      <EditMenuItem
        onClick={onClick}
        disabled={!checkAcl(account.acl, 'account:admin:tag') && !tag.system}
      />
    );
  };

  getRemoveTagMenuItem = (tag: Tag) => (onClick: () => void) => {
    const { account } = this.props;

    return (
      <DeleteMenuItem
        onClick={onClick}
        disabled={!checkAcl(account.acl, 'account:admin:tag') && !tag.system}
      />
    );
  };

  getActions = (tag: Tag) => (
    <ActionsMenu
      actions={[
        <EditTag tag={tag}>
          {this.getEditTagMenuItem(tag)}
        </EditTag>,
        <RemoveTagConfirmation tag={tag}>
          {this.getRemoveTagMenuItem(tag)}
        </RemoveTagConfirmation>,
      ]}
      focusOnCloseDisabled
    />
  );

  handlePageChange = ({ offset, limit }: Pagination) => {
    const { queryTags, query } = this.props;

    queryTags({
      params: query.params,
      pagination: {
        offset,
        limit,
      },
    });
  };

  renderPagination() {
    const { message, query } = this.props;

    if (query.loading) {
      return null;
    }

    return (
      <Pagination
        totalItems={query.count}
        itemsPerPage={query.pagination.limit}
        currentOffset={query.pagination.offset}
        onChange={this.handlePageChange}
        entityName={message({
          id: 'tags',
          comment: 'Plural form in pagination of tags.',
        })}
      />
    );
  }

  renderExport() {
    const { query } = this.props;

    if (query.loading) {
      return null;
    }

    return (
      <ExportTags query={query} />
    );
  }

  render() {
    const { message, account, query } = this.props;

    return (
      <div>
        <ActionBar collapsed>
          <ActionBar.Group>
            <Acl acl={account.acl} check="account:admin:tag">
              <AddTag>
                {this.getCreateTagButton}
              </AddTag>
            </Acl>
          </ActionBar.Group>
        </ActionBar>
        <TableFiltering
          filters={[
            {
              type: 'text',
              name: 'search',
              defaultValue: '',
              queryKey: 'q',
              autoFocus: true,
              placeholder: message({
                id: 'Find tags',
                comment: 'Placeholder in search field.',
              }),
            },
          ]}
          onFilterHandler={this.onFilter}
          loading={query.loading}
        />
        <Table
          config={this.getTableConfig()}
          query={query}
          onSelectionChanged={this.selectionChangeHandler}
        />
        <div className={style.PaginationContainer}>
          {this.renderPagination()}
          {this.renderExport()}
        </div>
      </div>
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({ id: 'Tags', comment: 'The page title' }),
  modules: [[]],
});

export default adminPage(propsMapper)(Tags);
