import normalizeEntity from 'normalized-redux/entity-normalizer';

export const ACTION_TYPE_MOVE = 'agreement_move' as const;
export const ACTION_TYPE_REMOVE = 'agreement_remove' as const;

const rpcs = [] as const;

type RemoveAction = {
  actionType: typeof ACTION_TYPE_REMOVE,
  actionParams: {
    agreementId: number,
  },
};

type MoveAction = {
  actionType: typeof ACTION_TYPE_MOVE,
  actionParams: {
    agreementId: number,
    collectionId: number,
    folderId?: number,
  },
};

type BulkOperation = {
  id: number;
  async: boolean;
  actions: (RemoveAction | MoveAction)[]
};

export default normalizeEntity<BulkOperation, 'bulkOperation', typeof rpcs>({
  entity: 'bulkOperation',
});
