import isNumber from 'lodash/isNumber';

import {
  DEFAULT_ROUNDING_PRECISION,
  roundBy,
} from 'utils/math';
import type {
  DiscountType,
  PriceRoundingMethod,
} from 'data-validators/entity-schemas/document-box/product-table';

import {
  NO_DISCOUNT,
  PRICE_ROUNDING_METHOD,
  PRODUCT_NUMERIC_DISCOUNT,
  PRODUCT_PERCENTAGE_DISCOUNT,
} from 'components/contract-boxes/product-table-box/constants';

export const CURRENT_PRICE_ROUNDING_METHOD = PRICE_ROUNDING_METHOD.BANKERS;

export const isNoDiscount = (discountType: DiscountType) => discountType === NO_DISCOUNT;

export const isDiscountPercentage = (
  discountType: DiscountType,
) => discountType === PRODUCT_PERCENTAGE_DISCOUNT;

export const isDiscountAbsolute = (
  discountType: DiscountType,
) => discountType === PRODUCT_NUMERIC_DISCOUNT;

export const shouldDisplayDiscount = (sum: unknown, sumDiscounted: unknown) => (
  isNumber(sumDiscounted) && isNumber(sum) && sumDiscounted !== sum
);

export type PriceInfo = number

export type Options = {
  roundPrice? : boolean;
  roundingMethod?: PriceRoundingMethod;
}

// or const number = Math.trunc(0.03333 * 100 * Math.pow(10, 14)) / Math.pow(10, 14)
// TODO: Edge case: numbers with more preceding zeros in the decimal part
// will get scientific notation
// Eg.,: "0.0000003333" -> "3.333e-7". use regex and convert
export const getDisplayDiscountPercentage = (
  discountAmount?: number,
  preferredPrecisions = 10,
) => discountAmount && String(
  Number(
    (discountAmount * 100).toExponential(preferredPrecisions),
  ),
);

export const getFormattedPrice = (
  priceInfo: number,
  pricePrecision = DEFAULT_ROUNDING_PRECISION,
  options: Options = {},
): string => {
  if (!priceInfo && typeof priceInfo !== 'number') {
    return '';
  }

  const {
    // When roundPrice is forgotten and roundingMethod is specified
    // roundPrice is inferred to be true implicitly
    roundPrice = false || Boolean(options.roundingMethod),
    roundingMethod = CURRENT_PRICE_ROUNDING_METHOD,
  } = options;

  let displayPrice = priceInfo;

  if (roundPrice) {
    displayPrice = roundBy(roundingMethod, priceInfo, pricePrecision);
  }

  return String(displayPrice);
};
