// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { locationAssign } from 'reducers/router';
import agreementTemplatesReducer from 'reducers/entities/agreement-templates';
import { withWorkspaceNamespace } from 'hocs/with-current-workspace';
import DeleteTemplate, { type Props } from './delete-template';

type StateProps = {|
  removeState: RemoveState,
|};

type OwnProps = $Diff<Props, StateProps>;

type DispatchProps = {|
  deleteTemplate: () => void,
  resetRemoveState: Function,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { agreement }) => ({
  removeState: agreementTemplatesReducer.getRemoveSelector(state, { id: agreement.id }),
});

export const getWorkspaceDependingPath = (workspace?: Workspace, path: string) => {
  if (!workspace) {
    return path;
  }

  return withWorkspaceNamespace(workspace)(path);
};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { agreement, queryName }) => ({
  deleteTemplate: () => {
    const actionData = {
      id: agreement.id,
      data: {
        ids: [agreement.id],
      },
      pipe: {
        action: () => locationAssign({
          path: getWorkspaceDependingPath(agreement.collection, '/templates'),
        }),
        wait: agreementTemplatesReducer.REMOVE_AGREEMENT_TEMPLATE_SUCCESS,
      },
    };

    if (queryName) {
      actionData.pipe.action = () => (
        agreementTemplatesReducer.queryAgreementTemplatesReload({
          name: queryName,
        })
      );
    }

    return dispatch(agreementTemplatesReducer.removeAgreementTemplate(actionData));
  },
  resetRemoveState: () => {
    dispatch(agreementTemplatesReducer.removeAgreementTemplateReset({
      id: agreement.id,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteTemplate);
