import { Fragment, ReactNode } from 'react';

import style from './billing-section.module.scss';

type Props = {
title?: ReactNode,
items: {
  label: ReactNode,
  value: ReactNode,
  id: string,
}[]
}

const BillingSection = ({
  items,
  title,
}: Props) => (
  <div className={style.SectionWrapper}>
    <h2 className={style.Headline}>
      {title}
    </h2>
    <div className={style.SectionDetails}>
      {items.map((item) => (
        <Fragment key={item.id}>
          <div>{item.label}</div>
          <div className={style.SemiBold}>{item.value}</div>
        </Fragment>
      ))}
    </div>
  </div>
);

export default BillingSection;
