import { Message } from '@oneflowab/pomes';

import CrossIcon from 'components/icons/new-cross';
import { MenuItem } from 'components/menu-item';
import { CancelContract } from 'components/document-call-to-actions/actions/modals/cancel-modal/cancel-contract';

type Props = {
  agreement: Oneflow.Agreement,
  isDisabled: boolean
};

const CancelDocument = ({ agreement, isDisabled }: Props) => (
  <CancelContract agreement={agreement}>
    {(onClick: () => void) => (
      <MenuItem
        disabled={isDisabled}
        icon={CrossIcon}
        onClick={onClick}
        label={(
          <Message
            id="Cancel document"
            comment="Label for action to cancel document."
          />
        )}
      />
    )}
  </CancelContract>
);

export default CancelDocument;
