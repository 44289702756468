// @flow

import { getDaysUntilTimestamp } from 'date';
import isEmpty from 'lodash/isEmpty';
import {
  UNLIMITED_AVAILABLE_SEATS,
} from './constants';

type AccountSeatsHelper = (accountSeats: AccountSeats) => boolean;

export const hasAvailableSeats: AccountSeatsHelper = (accountSeats) => {
  if (!accountSeats) {
    return true;
  }

  const { available } = accountSeats;

  return available === UNLIMITED_AVAILABLE_SEATS || available > 0;
};

export const getFormatter = (languageCode: EnabledLanguages, currency: string) => {
  if (currency) {
    return new Intl.NumberFormat(languageCode, { style: 'currency', currency });
  }
  return new Intl.NumberFormat(languageCode);
};

export const getMonthlySeatPrice = (numberOfSeats: string, price: number) => (
  Number(numberOfSeats || 0) * price
);

export const getYearlySeatPrice = (numberOfSeats: string, price: number) => (
  getMonthlySeatPrice(numberOfSeats, price) * 12
);

export const formatPrice = (formatter: Intl$NumberFormat, price: number) => formatter.format(price);

export const hasTrialExpired = (trialEndTimestampTs: ?number) => {
  if (!trialEndTimestampTs) {
    return false;
  }

  return getDaysUntilTimestamp(trialEndTimestampTs) <= 0;
};

export const isFreemium = (account: Account) => {
  if (isEmpty(account)) {
    return false;
  }

  return account.isFreemium;
};

export const isPaidAccount = (account: Account) => !account?.trial && !isFreemium(account);

export const showPaymentReminderBanner = (
  account: Oneflow.Account,
) => Boolean(account?.showPaymentReminderBanner);
