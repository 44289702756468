// @flow

import * as React from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';
import style from './text-styles.module.scss';

export const getTextStyles = (message: MessageTranslator) => ({
  // eslint-disable-next-line quote-props
  'title': {
    name: message({ id: 'Title', comment: 'The text for creating title elements in the editor' }),
    render: (name: string) => <h1 className={style.Title}>{name}</h1>,
  },
  // eslint-disable-next-line quote-props
  'subtitle': {
    name: message({ id: 'Subtitle', comment: 'The text for creating subtitle elements in the editor' }),
    render: (name: string) => <h1 className={style.Subtitle}>{name}</h1>,
  },
  'block-quote': {
    name: message({ id: 'Quotation', comment: 'The text for creating blockquote elements in the editor' }),
    render: (name: string) => <blockquote>{name}</blockquote>,
  },
  'heading-one': {
    name: message({ id: 'Heading 1', comment: 'The text for creating heading 1 elements in the editor' }),
    render: (name: string) => <h1>{name}</h1>,
  },
  'heading-two': {
    name: message({ id: 'Heading 2', comment: 'The text for creating heading 2 elements in the editor' }),
    render: (name: string) => <h2>{name}</h2>,
  },
  'heading-three': {
    name: message({ id: 'Heading 3', comment: 'The text for creating heading 3 elements in the editor' }),
    render: (name: string) => <h3>{name}</h3>,
  },
  'heading-four': {
    name: message({ id: 'Heading 4', comment: 'The text for creating heading 4 elements in the editor' }),
    render: (name: string) => <h4>{name}</h4>,
  },
  'heading-five': {
    name: message({ id: 'Heading 5', comment: 'The text for creating heading 5 elements in the editor' }),
    render: (name: string) => <h5>{name}</h5>,
  },
  'heading-six': {
    name: message({ id: 'Heading 6', comment: 'The text for creating heading 6 elements in the editor' }),
    render: (name: string) => <h6>{name}</h6>,
  },
});

const useTextStyles = (message: MessageTranslator) => (
  React.useMemo(() => getTextStyles(message), [message])
);

export default useTextStyles;
