/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';

import { pricePrecision } from 'utils/format-number-with-trailing-zeros';
import { useProductTableBoxContext } from 'contexts/product-table-box-context';
import type {
  Config,
  Product,
} from 'data-validators/entity-schemas/document-box/product-table';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import {
  PRODUCT_COUNT_TYPE_NUMBER,
  PRODUCT_TABLE_COLUMNS,
} from 'components/contract-boxes/product-table-box/constants';
import { UpdatedProduct } from 'components/contract-boxes/product-table-box/product-table/popup-forms/update-product-helpers';
import DescriptionEditable from 'components/contract-boxes/product-table-box/description-editable';
import ExpandedProductPrice from 'components/contract-boxes/product-table-box/product-table/expanded-product-price';
import ProductCell from 'components/contract-boxes/product-table-box/product-table/product-table-expanded/product-cell';
import QuantityInput from 'components/contract-boxes/product-table-box/product-table/quantity-input';

import style from '../product-table-expanded.module.scss';

export type Props = {
  agreementId: number,
  chosenProduct: Product,
  config: Config,
  enabledColumns: string[],
  handleDescriptionChange: (rowId: number, nodes: Element[] | Text[]) => void,
  isGuestView: boolean,
  onClearChosenProduct: (productId: number) => void,
  product: Product,
  removeProduct: (productId: number) => void,
  updateChosenProduct: (productId: number) => void,
  updateProductData: (productId: number, updatedProduct: UpdatedProduct) => void,
}

const ProductRow = ({
  agreementId,
  chosenProduct,
  config,
  enabledColumns,
  handleDescriptionChange,
  isGuestView,
  onClearChosenProduct,
  product,
  removeProduct,
  updateChosenProduct,
  updateProductData,
}: Props) => {
  const {
    NAME,
    DESCRIPTION,
    PRICE_1,
    PRICE_2,
    COUNT,
  } = PRODUCT_TABLE_COLUMNS;
  const { getIsAllowedToUpdateDataValue } = useProductTableBoxContext();
  const isAllowedToUpdateDataValue = getIsAllowedToUpdateDataValue(product);
  const stopPopupTriggerPropagation = (event: React.SyntheticEvent) => {
    event.stopPropagation();
  };
  const updateProductTriggerRef = useRef<HTMLDivElement>(null);
  const triggerUpdateProductPopup = () => updateProductTriggerRef.current?.click();

  return (
    <div className={style.TableRow}>
      {enabledColumns.includes(NAME) && (
        <ProductCell
          columnKey={NAME}
          enabledColumns={enabledColumns}
          isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
          onClearChosenProduct={onClearChosenProduct}
          onClick={triggerUpdateProductPopup}
          product={product}
          ref={updateProductTriggerRef}
          removeProduct={removeProduct}
          updateProductData={updateProductData}
        >
          <h4>{product.value.name}</h4>
        </ProductCell>
      )}

      {(enabledColumns.includes(DESCRIPTION)) && (
        <ProductCell
          columnKey={DESCRIPTION}
          enabledColumns={enabledColumns}
          isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
          onClearChosenProduct={onClearChosenProduct}
          onClick={triggerUpdateProductPopup}
          product={product}
          ref={updateProductTriggerRef}
          removeProduct={removeProduct}
          updateProductData={updateProductData}
        >
          <div
            data-testid="description-wrapper"
            onClick={stopPopupTriggerPropagation}
            role="button"
          >
            <DescriptionEditable
              contractId={agreementId}
              data={product}
              isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
              onChange={(nodes: Element[] | Text[]) => {
                handleDescriptionChange(getId(product), nodes);
              }}
            />
          </div>
        </ProductCell>
      )}

      {enabledColumns.includes(PRICE_1) && (
        <ProductCell
          columnKey={PRICE_1}
          enabledColumns={enabledColumns}
          isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
          onClearChosenProduct={onClearChosenProduct}
          onClick={triggerUpdateProductPopup}
          product={product}
          ref={updateProductTriggerRef}
          removeProduct={removeProduct}
          updateProductData={updateProductData}
        >
          <ExpandedProductPrice
            price={product.value.price_1}
            discountAmount={product.value.price_1_discount_amount}
            priceDiscounted={product.value.price_1_discounted}
            discountType={product.value.price_1_discount_type}
            prefix={config.affixes.prefix}
            postfix={config.affixes.postfix}
            pricePrecision={pricePrecision(config.pricePrecision)}
          />
        </ProductCell>
      )}

      {enabledColumns.includes(PRICE_2) && (
        <ProductCell
          columnKey={PRICE_2}
          enabledColumns={enabledColumns}
          isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
          onClearChosenProduct={onClearChosenProduct}
          onClick={triggerUpdateProductPopup}
          product={product}
          ref={updateProductTriggerRef}
          removeProduct={removeProduct}
          updateProductData={updateProductData}
        >
          <ExpandedProductPrice
            price={product.value.price_2}
            discountAmount={product.value.price_2_discount_amount}
            priceDiscounted={product.value.price_2_discounted}
            discountType={product.value.price_2_discount_type}
            prefix={config.affixes.prefix}
            postfix={config.affixes.postfix}
            pricePrecision={pricePrecision(config.pricePrecision)}
          />
        </ProductCell>
      )}

      {enabledColumns.includes(COUNT) && (
        <ProductCell
          columnKey={COUNT}
          enabledColumns={enabledColumns}
          isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
          onClearChosenProduct={onClearChosenProduct}
          onClick={triggerUpdateProductPopup}
          product={product}
          ref={updateProductTriggerRef}
          removeProduct={removeProduct}
          updateProductData={updateProductData}
        >
          <div
            data-testid="quantity-input-wrapper"
            role="button"
            onClick={stopPopupTriggerPropagation}
            className={style.QuantityInputWrapper}
          >
            <QuantityInput
              chosenProduct={chosenProduct}
              count_type={product.value.count_type || PRODUCT_COUNT_TYPE_NUMBER}
              count={product.value.count}
              isGuestView={isGuestView}
              productId={getId(product)}
              productName={product.value.name}
              updateChosenProduct={updateChosenProduct}
              updateProductData={updateProductData}
            />
          </div>
        </ProductCell>
      )}
    </div>
  );
};

export default ProductRow;
