// @flow

import React from 'react';

import style from './ghosting.module.scss';

type Props = {
  email: string,
};

const Ghosting = ({ email }: Props) => (
  <span className={style.Ghosting}>
    {`GHOSTING - ${email}`}
  </span>
);

export default Ghosting;
