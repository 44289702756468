// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { RemoveConfirm } from '../remove-confirm';

type Props = {
  ids: Array<number>,
  removeState: RemoveState,
  resetRemoveState: Function,
  deleteContacts: () => void,
  children: Function,
}

export const DeleteContacts = ({
  ids,
  removeState,
  deleteContacts,
  resetRemoveState,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={deleteContacts}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <Message
        id="You are about to delete a contact. This can't be undone."
        pluralId="You are about to delete {count} contacts. This can't be undone."
        pluralCondition="count"
        values={{
          count: ids.length,
        }}
        comment="Modal dialog asking for confirmation whether to remove one or multiple contacts."
      />
    )}
    modalKey="delete address book contacts modal"
  >
    {children}
  </RemoveConfirm>
);
