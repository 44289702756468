// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { Message } from '@oneflowab/pomes';
import { FieldProps } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  folderNameAlreadyExists,
  generateDuplicateName,
  getFoldersByParentId,
} from 'workspace/folders';
import { getCurrentWorkspaceSelector, getCurrentWorkspaceIdSelector } from 'reducers/app';
import { getFolders } from 'oneflow-client/folders.js';
import foldersReducer from 'reducers/entities/folders';

import { checkAcl } from 'components/acl';
import AddFolderIcon from 'components/icons/add-folder';
import Button from 'components/button';
import Conditional from 'components/conditional';
import Divider from 'components/divider';
import Tooltip from 'components/tooltip';

import Branch from './folder-branch';
import style from './folder-tree.module.scss';

export const getCurrentWorkspaceName = (currentWorkspace: Workspace) => {
  if (!currentWorkspace) {
    return '';
  }

  return currentWorkspace.name;
};

const FOLDERS_QUERY = 'folders';

type FolderTreeType = {
  contractFolderId?: number,
  isModalVersion?: boolean,
  isSaveFolderDestinationModal?: boolean,
  onFolderSelectionHandler?: (number, ?string) => void,
  selectedUniqueKey?: any,
  selectedWorkspaceId?: number,
  selectedWorkspaceName?: string,
  selectedWorkspace?: Workspace,
  isReadOnly?: boolean,
  branchContainerClassName?: string,
} & FieldProps

const FolderTree = ({
  contractFolderId,
  isModalVersion,
  isSaveFolderDestinationModal,
  onFolderSelectionHandler,
  selectedUniqueKey,
  selectedWorkspaceId,
  selectedWorkspaceName,
  selectedWorkspace,
  isReadOnly = false,
  branchContainerClassName,
  ...props
}: FolderTreeType) => {
  const foldersQuery = useSelector((state) => (
    foldersReducer.getQuerySelector(state, { name: FOLDERS_QUERY })
  ));
  const folders = useSelector((state) => (
    foldersReducer.getFoldersSelector(state, { ids: foldersQuery.result })
  ));
  const [selectedWorkspaceFolders, setSelectedWorkspaceFolders] = React.useState(null);
  const getFoldersArr = useCallback(() => {
    if (selectedWorkspaceFolders != null) {
      return Object.values(selectedWorkspaceFolders);
    }
    return Object.values(folders);
  }, [folders, selectedWorkspaceFolders]);
  const sortedFolders = getFoldersArr().sort((a, b) => a.name.localeCompare(b.name));
  const currentWorkspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  const workspaceAcl = selectedWorkspace ? selectedWorkspace.acl : currentWorkspace.acl;
  const workspaceCreatePermission = checkAcl(workspaceAcl, 'collection:folder:create');

  const root = {
    acl: {
      'folder:sub_folder:create': workspaceCreatePermission ? 'allow' : 'deny',
    },
    childFolders: getFoldersByParentId(sortedFolders, null),
    id: -1,
    name: getCurrentWorkspaceName(currentWorkspace),
  };
  const [isAddMode, setIsAddMode] = useState(false);
  const [parents, setParents] = React.useState([]);
  const getSelectedFolderAcl = () => {
    if (selectedUniqueKey === -1) {
      return root.acl;
    }

    return sortedFolders.find((folder) => folder.id === selectedUniqueKey)?.acl;
  };

  const addFolderPermission = checkAcl(getSelectedFolderAcl(), 'folder:sub_folder:create');
  const currentWorkspaceId = useSelector((state) => getCurrentWorkspaceIdSelector(state));

  const dispatch = useDispatch();

  const getSelectedWorkspaceFolders = useCallback(async () => {
    try {
      const data = await getFolders({
        params: {
          workspaceId: selectedWorkspaceId || currentWorkspace.id,
        },
      });
      setSelectedWorkspaceFolders(data.entities.folders || {});
    } catch {
      setSelectedWorkspaceFolders({});
    }
  }, [currentWorkspace.id, selectedWorkspaceId]);

  useEffect(() => {
    if (isModalVersion) {
      getSelectedWorkspaceFolders();
      return;
    }

    dispatch(
      foldersReducer.queryFolders({
        name: FOLDERS_QUERY,
        params: {
          workspaceId: currentWorkspace.id,
        },
      }),
    );
  }, [
    currentWorkspace.id,
    dispatch,
    getSelectedWorkspaceFolders,
    isModalVersion,
    selectedWorkspaceId,
  ]);

  useEffect(() => {
    const newParents = [];

    const getFolder = (id) => sortedFolders.find((folder) => folder.id === id);
    let folder = getFolder(selectedUniqueKey);

    while (folder != null) {
      newParents.push(folder.id);
      folder = getFolder(folder.parentId);
    }

    setParents(newParents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUniqueKey]);

  const addFolderHandler = () => {
    if (isModalVersion) {
      getSelectedWorkspaceFolders();
    }
  };

  const addFolder = (name, workspaceId, parentId) => {
    dispatch(foldersReducer.createFolder({
      data: {
        name,
        workspaceId: selectedWorkspaceId || currentWorkspace.id,
        parentId: parentId !== -1 ? parentId : undefined,
      },
      name: FOLDERS_QUERY,
      pipe: {
        action: () => foldersReducer.queryFoldersReload({
          name: FOLDERS_QUERY,
        }),
        onSuccess: addFolderHandler,
      },
    }));
  };

  const onFolderAddHandler = () => {
    setIsAddMode(true);
  };

  const onAddFolder = (name) => {
    if (name !== '') {
      if (!folderNameAlreadyExists(name, selectedUniqueKey, getFoldersArr())) {
        addFolder(name, currentWorkspaceId, selectedUniqueKey);
      } else {
        const tempName = generateDuplicateName(name, selectedUniqueKey, getFoldersArr());
        addFolder(tempName, currentWorkspaceId, selectedUniqueKey);
      }
    }

    setIsAddMode(false);
  };

  const titleClasses = clsx(style.Title, {
    [style.Small]: isModalVersion,
  });

  const folderIconToolTipMessage = () => {
    const maximumFolderLimit = sortedFolders.length === 100;

    if (maximumFolderLimit) {
      return (
        <Message
          id="Your workspace has already reached the maximum of 100 allowed folders."
          comment="Tooltip for when the add folder button is disabled"
        />
      );
    }

    if (!workspaceCreatePermission) {
      return (
        <Message
          id="You don't have permission to add folders. Contact your admin to set the required permissions."
          comment="Tooltip for when the add folder button is disabled"
        />
      );
    }

    if (!addFolderPermission) {
      return (
        <Message
          id="Folders support a maximum of 2 nested levels of subfolders."
          comment="Tooltip for when the add folder button is disabled"
        />
      );
    }

    return (
      <Message
        id="Add folder"
        comment="Tooltip for add folder button."
      />
    );
  };

  return (
    <div className={style.Tree}>
      <div className={style.Header}>
        <h3 className={titleClasses}>
          <Message
            id="Folders"
            comment="Header in the filters section."
          />
        </h3>
        <Conditional ifCondition={!isReadOnly}>
          <Tooltip
            message={folderIconToolTipMessage()}
            side="top"
            theme="oneflow"
            // Modal overlay has z-index 10001
            // so tooltip-z-index=modal-overlay-z-index + 1
            zIndex="10002"
          >
            <div>
              <Button
                customClass={style.AddFolder}
                disabled={!workspaceCreatePermission || !addFolderPermission}
                onClick={onFolderAddHandler}
              >
                <AddFolderIcon height="18px" />
                <Conditional ifCondition={isModalVersion}>
                  <span>
                    <Message
                      id="New folder"
                      comment="Button label in the Move to... modal."
                    />
                  </span>
                </Conditional>
              </Button>
            </div>
          </Tooltip>
        </Conditional>
      </div>
      <Divider />
      <div className={clsx(style.BranchContainer, branchContainerClassName)}>
        <Branch
          acl={root.acl}
          allFolders={sortedFolders}
          childFolders={getFoldersByParentId(sortedFolders, null)}
          contractFolderId={contractFolderId}
          customClass={style.Root}
          id={root.id}
          indentationLevel={0}
          isAddMode={isAddMode}
          isModalVersion={isModalVersion}
          name={selectedWorkspaceName || getCurrentWorkspaceName(currentWorkspace)}
          onAddFolder={onAddFolder}
          onFolderAddHandler={onFolderAddHandler}
          onFolderSelectionHandler={(folderId) => {
            onFolderSelectionHandler(folderId);
            props?.input?.onChange(folderId);
          }}
          parentId={null}
          parents={parents}
          selectedUniqueKey={selectedUniqueKey}
          isSaveFolderDestinationModal={isSaveFolderDestinationModal}
          isReadOnly={isReadOnly}
        />
      </div>
    </div>
  );
};

export default FolderTree;
