import * as React from 'react';
import { useSelector } from 'react-redux';
import isArray from 'lodash/isArray';
import queryString from 'query-string';
import type { MessageTranslator } from '@oneflowab/pomes';

import { getLocationSelector } from 'reducers/router';
import * as agreementConstants from 'agreement/constants';
import {
  ACTION_STATUS_NOT_STARTED,
  ACTION_STATUS_RUNNING,
  ACTION_STATUS_SUCCEEDED,
} from 'agreement/actions/constants';
import adminPage from 'hocs/admin-page';
import foldersReducer from 'reducers/entities/folders';
import { getAccountFromSessionSelector } from 'reducers/session';
import {
  defaultSortWithScore,
  Filter,
  SearchWithQueryString,
  sortSanitizer,
} from 'hocs/search';

import { checkAcl } from 'components/acl';
import { ContractListWithSearchResult } from 'components/contract-list';
import { ControlledSearch } from 'components/search';
import AgreementSort from 'components/agreement-sort';
import CheckboxGroup from 'components/checkbox-group';
import ColleagueSelector from 'components/colleague-selector';
import DateFilter from 'components/date-filter';
import QuickFilters from 'components/quick-filters';
import TagSelector from 'components/tag-selector';
import WorkspaceSelector from 'components/workspace-selector';
import getColleaguesFilterProps from 'agreement/colleagues-filter-props';
import getWorkspacesFilterProps from 'agreement/workspaces-filter-props';
import {
  stateFilterOptions,
  signOrderFilterOptions,
  lifecycleFilterOptions,
  insightFilterOptions,
  approvalFilterOptions,
} from 'components/filter-options';

import {
  lifecycleFilterSanitizer,
  tagsFilterSanitizer,
  signOrderFilterSanitizer,
  insightsFilterSanitizer,
  freeTextSanitizer,
  approvalFilterSanitizer,
} from 'agreement/sanitizers';

const filterableStates = [
  agreementConstants.STATE_DRAFT,
  agreementConstants.STATE_PENDING,
  agreementConstants.STATE_OVERDUE,
  agreementConstants.STATE_SIGNED,
  agreementConstants.STATE_DECLINED,
];

const filterableStatesApproval = [
  ACTION_STATUS_NOT_STARTED,
  ACTION_STATUS_RUNNING,
  ACTION_STATUS_SUCCEEDED,
  'NONE',
];

const filterableLifecycles = [
  agreementConstants.LIFECYCLE_AWAITING,
  agreementConstants.LIFECYCLE_ACTIVE,
  agreementConstants.LIFECYCLE_ENDED,
  agreementConstants.LIFECYCLE_NOT_SET,
];

const minFreeTextSearchLength = 2;

const filterableInsights = [
  // some counterparties has opened the contract
  'opened',
  // no counterparty has opened the contract
  'not_opened',
  // some counterparties has not received the contract
  'delivery_failed',
];

const filterableSignOrder = [
  // For contracts that it's my turn to sign (with sign order) or
  // I'm the only signatory left to sign (without sign order)
  'last',

  // For contracts that it's my turn to sign (with sign order) or
  // I'm one of the signatories left to sign (without sign order)
  'all',
];

const FOLDERS_QUERY = 'folders';

type Params = {
  tags: string[],
  noTag?: null,
}

type Props = {
  message: MessageTranslator,
  workspace: Oneflow.Workspace,
  path: string,
  currentUser: Oneflow.Position,
  dateFormat: string,
  withoutWorkspaceId: boolean,
  showWorkspaceName: boolean,
  hideImport: boolean,
  shouldShowWorkspacesFilter: boolean,
  sendWorkspaceIds: boolean,
  setWorkspacesOptions: (options: []) => void,
  workspacesOptions: []
};

const renderWorkspacesFilter = ({
  message,
  currentUser,
  shouldShowWorkspacesFilter,
  setWorkspacesOptions,
  workspacesOptions,
}: Props) => {
  if (!shouldShowWorkspacesFilter) {
    return null;
  }

  return (
    <Filter
      data-testid="workspaces-filter"
      param="collectionIds"
      title={message({
        id: 'Workspaces',
        comment: "Section title. Search filter category title, as in 'search by workspaces'.",
      })}
      options={workspacesOptions}
      optionsCount={workspacesOptions.length}
      setOptions={setWorkspacesOptions}
      defaultValue={[]}
      component={WorkspaceSelector}
      {...getWorkspacesFilterProps()}
      positionId={currentUser?.id}
    />
  );
};

export const tagsOnValueChange = (tags: string[], updateParams: (params: Params) => void) => {
  let params: Params = {
    tags,
  };

  if (isArray(tags) && tags.length) {
    params = {
      ...params,
      noTag: null,
    };
  }

  updateParams(params);
};

export const AllContracts = ({
  currentUser,
  dateFormat,
  hideImport,
  message,
  path,
  sendWorkspaceIds,
  shouldShowWorkspacesFilter,
  showWorkspaceName,
  withoutWorkspaceId,
  workspace,
}: Props) => {
  const account = useSelector(getAccountFromSessionSelector);
  const showApprovalFilter = checkAcl(account?.acl, 'account:agreement:draft_approval_flow:filter');

  const [colleaguesOptions, setColleaguesOptions] = React.useState([]);
  const [tagsOptions, setTagsOptions] = React.useState([]);
  const [workspacesOptions, setWorkspacesOptions] = React.useState([]);
  const foldersQuery = useSelector((state) => (
    foldersReducer.getQuerySelector(state, { name: FOLDERS_QUERY })
  ));
  const folders = useSelector((state) => (
    // eslint-disable-next-line import/no-named-as-default-member
    foldersReducer.getFoldersSelector(state, { ids: foldersQuery.result })
  ));
  const foldersArr: Oneflow.Folder[] = Object.values(folders);
  const location = useSelector((state) => getLocationSelector(state));

  const searchParams = location.search.split('&');
  searchParams[0] = searchParams[0].substring(1);
  const folderParam = Object.values(queryString.parse(searchParams.find((param: string) => param.includes('folderIds'))))[0];

  const getPlaceHolder = () => {
    if (!workspace || workspace?.virtual) {
      return message({
        id: 'Search documents',
        comment: 'Placeholder text used in search field when trying to find documents.',
      });
    }

    if (folderParam) {
      const folder = foldersArr.find(
        (folderEntry) => folderEntry.id === Number(folderParam),
      );

      if (folder) {
        return message({
          id: 'Search in {folder}',
          values: { folder: folder.name },
          comment: 'Placeholder text used in search field when trying to find documents.',
        });
      }

      return message({
        id: 'Search in folder',
        comment: 'Placeholder text used in search field when trying to find documents.',
      });
    }

    return message({
      id: 'Search in {workspace}',
      values: { workspace: workspace.name },
      comment: 'Placeholder text used in search field when trying to find documents.',
    });
  };

  const renderApprovalFilter = () => {
    if (!showApprovalFilter) {
      return null;
    }

    return (
      <Filter
        param="draftApprovalFlowStatus"
        title={message({
          id: 'Internal approvals',
          comment: "Section title. Search filter category title, as in 'search by document status'",
        })}
        onSanitize={approvalFilterSanitizer(filterableStatesApproval)}
        defaultValue={[]}
        options={approvalFilterOptions(filterableStatesApproval)}
        component={CheckboxGroup}
        workspace={workspace}
      />
    );
  };

  return (
    <SearchWithQueryString
      data-testid="search-with-query-string"
      queryName="search/contracts/all"
      keyword={(
        <Filter
          clearFiltersOnSearch
          component={ControlledSearch}
          title={message({
            id: 'Keyword',
            comment: 'Full text filter name',
          })}
          onSanitize={(value) => freeTextSanitizer(value, minFreeTextSearchLength)}
          placeholder={getPlaceHolder()}
          param="q"
          defaultValue=""
          wrap={false}
          minSearchLength={minFreeTextSearchLength}
          autoFocus
        />
      )}
      filters={(
        <>
          <QuickFilters
            location={location}
            path={path}
            workspace={workspace}
          />
          {renderWorkspacesFilter({
            message,
            currentUser,
            shouldShowWorkspacesFilter,
            setWorkspacesOptions,
            workspacesOptions,
          })}
          <Filter
            param="positions"
            title={message({
              id: 'Colleagues',
              comment: "Section title. Search filter category title, as in 'search by colleagues'.",
            })}
            defaultValue={[]}
            component={ColleagueSelector}
            options={colleaguesOptions}
            optionsCount={colleaguesOptions.length}
            setOptions={setColleaguesOptions}
            workspace={workspace}
            {...getColleaguesFilterProps(workspace, currentUser)}
          />
          <Filter
            param="state"
            title={message({
              id: 'Status',
              comment: "Section title. Search filter category title, as in 'search by document status'",
            })}
            onSanitize={lifecycleFilterSanitizer(filterableStates)}
            defaultValue={filterableStates}
            options={stateFilterOptions(filterableStates)}
            component={CheckboxGroup}
            workspace={workspace}
          />
          {renderApprovalFilter()}
          <Filter
            param="insight"
            title={message({
              id: 'Insights',
              comment: "Section title. Search filter category title, as in 'search by document insights'",
            })}
            onSanitize={insightsFilterSanitizer(filterableInsights)}
            defaultValue={[]}
            options={insightFilterOptions(message, filterableInsights)}
            component={CheckboxGroup}
            workspace={workspace}
          />
          <Filter
            param="lifecycle"
            type="array"
            title={message({
              id: 'Lifecycle',
              comment: "Section title. Search filter category title, as in 'search by document status'",
            })}
            onSanitize={lifecycleFilterSanitizer(filterableLifecycles)}
            defaultValue={[]}
            options={lifecycleFilterOptions(message, filterableLifecycles)}
            component={CheckboxGroup}
            workspace={workspace}
          />
          <Filter
            param="signOrder"
            title={message({
              id: 'Sign order',
              comment: "Section title. Search filter category title, as in 'search by document sign order'",
            })}
            defaultValue={[]}
            onSanitize={signOrderFilterSanitizer(filterableSignOrder)}
            options={signOrderFilterOptions(message, filterableSignOrder)}
            component={CheckboxGroup}
            workspace={workspace}
          />
          <Filter
            param="date"
            title={message({
              id: 'Date',
              comment: 'Section title. Search filter category',
            })}
            component={DateFilter}
            defaultOption="created"
            optionsCount={1}
            dateFormat={dateFormat}
            workspace={workspace}
          />
          <Filter
            param="tags"
            title={message({
              id: 'Tags',
              comment: "Section title. Search filter category title, as in 'search by document tags'.",
            })}
            defaultValue={[]}
            onSanitize={tagsFilterSanitizer}
            component={TagSelector}
            options={tagsOptions}
            optionsCount={tagsOptions.length}
            setOptions={setTagsOptions}
            workspace={workspace}
            onValueChange={tagsOnValueChange}
          />
        </>
      )}
      sorting={(
        <Filter
          param="sort"
          defaultValue={defaultSortWithScore(['-created'])}
          wrap={false}
          component={AgreementSort}
          onSanitize={sortSanitizer}
          sortables={[
            'name',
            'agreement_value',
            'created',
            'publish_time',
            'close_time',
            'upcoming_time',
          ]}
        />
      )}
    >
      <ContractListWithSearchResult
        hideImport={hideImport}
        sendWorkspaceIds={sendWorkspaceIds}
        showWorkspaceName={showWorkspaceName}
        withoutWorkspaceId={withoutWorkspaceId}
      />
    </SearchWithQueryString>
  );
};

type MapperProps = {
  props: {
    [key: string]: any,
  },
};

export const allContractsModule = (
  { props: { message } }: MapperProps,
) => ({
  modules: [[]],
  section: message({ id: 'Search', comment: 'Used as the title for the section.' }),
  showAsLink: false,
  title: message({ id: 'Documents', comment: 'The page title for the document list when showing all documents' }),
});

export default adminPage(allContractsModule)(AllContracts);
