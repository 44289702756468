import { Message, localize } from '@oneflowab/pomes';
import type { FunctionComponent, ReactNode } from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';

import boxTypes from 'agreement/box-types';
import WarningIcon from 'components/icons/warning';
import BoxActionsMenu from 'components/contract-boxes/box-actions-menu';
import { Box } from 'data-validators/entity-schemas/document-box';

import style from './error.module.scss';

type Props = {
  box: Box,
  message: MessageTranslator,
  onRemoveBox: () => void,
  isEditable: boolean,
  isViewer: boolean,
  customMessage?: ReactNode,
};

// Must either pass down customMessage OR message and box.
const ContractBoxErrorComponent = ({
  box,
  message,
  onRemoveBox,
  isEditable,
  isViewer,
  customMessage,
}: Props) => {
  const boxType = boxTypes(message)[box?.type as number];

  return (
    <div className={style.ErrorContainer} data-testid="error-container">
      <div className={style.BoxMenu}>
        <BoxActionsMenu
          box={box}
          onRemoveBox={onRemoveBox}
          isEditable={isEditable}
          isViewer={isViewer}
        />
      </div>
      <WarningIcon height="40px" />
      <h1 className={style.Header}>
        <Message id="Something went wrong" comment="Error message title" />
      </h1>
      <p data-testid="error-details">
        {customMessage ?? (
          <Message
            id="Due to an internal error, we couldn't load the {type} section."
            comment="Error message text"
            values={{
              type: boxType || '',
            }}
          />
        )}
      </p>
      <p className={style.Contact}>
        <Message
          id="Please contact support at {email}."
          values={{
            email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
          }}
          comment="Error message contact info."
        />
      </p>
    </div>
  );
};

export const ContractBoxError = localize<Props>(ContractBoxErrorComponent) as unknown as FunctionComponent<Omit<Props, 'message'>>;
