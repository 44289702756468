import isArray from 'lodash/isArray';

const getActionsByAgreementFlowId = (participant, flowId, actionKey) => {
  if (participant && isArray(participant.actions) && participant.actions.length > 0) {
    return participant.actions
      .filter((action) => action.agreementFlow.id === flowId)
      .filter((action) => action.key === actionKey);
  }

  return null;
};

export default getActionsByAgreementFlowId;
