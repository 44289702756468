/* eslint-disable class-methods-use-this */
// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import * as agreementConstants from 'agreement/constants';
import { isTemplate } from 'agreement/states';
import webSocket from 'web-socket';

import Button from 'components/button';
import { getVideoErrorMessage } from 'components/api-error';
import RemoveIcon from 'components/icons/remove';
import CheckCircle from 'components/icons/check-circle';
import CircularSpinner from 'components/icons/circular-spinner';
import Error from 'components/icons/error';
import { VideoUpload } from 'components/video-upload';
import VideoRecorder from 'components/video-recorder';
import VideoPlayer from 'components/video-player';

import { isMobileBrowser } from 'utils/browser';
import navigator from 'utils/navigator';

import RecorderControls, { MobileRecorderControls } from './recorder-controls';
import ProcessingStatus from './processing-status';

import style from './video.module.scss';

export type Props = {|
  agreement: Agreement,
  recorderEnabled: boolean,
  recordedVideo?: Blob | File,
  clearRecordedVideo: () => void,
  onRecordFinished: (Blob) => void,
  onUpload: (Blob) => void,
  onCancelProcessing: () => void,
  setWelcomeVideoStatus: (number) => void,
  uploadVideoState: CreateState,
  resetUploadVideoState: () => void,
|};

type ProcessData = {
  errorCode: number,
  success: boolean,
};

type State = {
  processingErrorCode?: number,
  displaySuccessMessage: boolean,
  stream?: MediaStream,
  willUploadVideo: boolean,
  backToHref: string,
};

class Video extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      processingErrorCode: undefined,
      displaySuccessMessage: false,
      stream: undefined,
      willUploadVideo: false,
      backToHref: '/documents',
    };
  }

  static defaultProps = {
    recordedVideo: undefined,
  };

  unsubscribeToProcessingStart: Function;

  unsubscribeToProcessingDone: Function;

  unsubscribeToVideoRemove: Function;

  componentDidMount() {
    this.subscribeToProcessingStart();
    this.subscribeToProcessingDone();
    this.subscribeToVideoRemove();
  }

  componentDidUpdate(prevProps: Props) {
    const { recorderEnabled } = this.props;

    if (prevProps.recorderEnabled && !recorderEnabled) {
      this.turnOffStream();
    }

    if (this.shouldResetState(prevProps)) {
      this.onResetState();
    }
  }

  componentWillUnmount() {
    this.unsubscribeToProcessingStart();
    this.unsubscribeToProcessingDone();
    this.unsubscribeToVideoRemove();

    this.turnOffStream();
  }

  getError() {
    const { uploadVideoState } = this.props;
    const { processingErrorCode } = this.state;

    if (uploadVideoState.error) {
      return {
        headerText: (
          <Message
            id="Uploading failed"
            comment="Notification message header about failure while uploading a video"
          />
        ),
        bodyText: (
          <Message
            id="Please reload and try again."
            comment="Notification message about failure while uploading a video"
          />
        ),
      };
    }

    if (processingErrorCode) {
      return getVideoErrorMessage(processingErrorCode);
    }

    return null;
  }

  requestUpload = () => {
    this.setState({
      willUploadVideo: true,
    });
  };

  requestCamera = () => {
    navigator.requestCamera()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  requestScreen = () => {
    navigator.requestScreen()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  handleSuccess = (stream: MediaStream) => {
    this.setState({
      stream,
    });
  }

  handleError = () => {
    if (this.state.stream) {
      this.turnOffStream();
    }
  }

  turnOffStream = () => {
    this.turnOffStreamTracks();

    this.setState({
      stream: undefined,
    });
  }

  onClearRecording = () => {
    const { clearRecordedVideo } = this.props;

    clearRecordedVideo();
  }

  onCancelRecording = () => {
    this.onClearRecording();
    this.turnOffStream();
  }

  onProcessingStart = () => {
    const { setWelcomeVideoStatus } = this.props;

    setWelcomeVideoStatus(agreementConstants.AGREEMENT_VIDEO_PROCESSING);
  };

  subscribeToProcessingStart = () => {
    const { agreement: { id: agreementId } } = this.props;

    this.unsubscribeToProcessingStart = webSocket.subscribe({
      channelName: `private-agreement-${agreementId}`,
      event: 'agreement:video:processing:start',
      eventCallback: this.onProcessingStart,
    });
  };

  onProcessingDone = ({ success, errorCode }: ProcessData) => {
    const {
      setWelcomeVideoStatus,
      uploadVideoState,
      clearRecordedVideo,
    } = this.props;

    if (success) {
      setWelcomeVideoStatus(agreementConstants.AGREEMENT_WITH_VIDEO);

      if (uploadVideoState.success) {
        this.setState({
          displaySuccessMessage: true,
        }, clearRecordedVideo);
      }

      return;
    }

    if (uploadVideoState.success) {
      this.setState({
        processingErrorCode: errorCode,
      });
    }

    setWelcomeVideoStatus(agreementConstants.AGREEMENT_WITHOUT_VIDEO);
  };

  subscribeToProcessingDone = () => {
    const { agreement: { id: agreementId } } = this.props;

    this.unsubscribeToProcessingDone = webSocket.subscribe({
      channelName: `private-agreement-${agreementId}`,
      event: 'agreement:video:processing:done',
      eventCallback: this.onProcessingDone,
    });
  };

  onVideoRemove = () => {
    const { setWelcomeVideoStatus } = this.props;

    setWelcomeVideoStatus(agreementConstants.AGREEMENT_WITHOUT_VIDEO);
  };

  subscribeToVideoRemove = () => {
    const { agreement: { id: agreementId } } = this.props;

    this.unsubscribeToVideoRemove = webSocket.subscribe({
      channelName: `private-agreement-${agreementId}`,
      event: 'agreement:video:remove',
      eventCallback: this.onVideoRemove,
    });
  };

  onResetState = () => {
    const { resetUploadVideoState, clearRecordedVideo } = this.props;

    this.turnOffStreamTracks();

    this.setState({
      processingErrorCode: undefined,
      displaySuccessMessage: false,
      willUploadVideo: false,
      stream: undefined,
    }, () => {
      resetUploadVideoState();
      clearRecordedVideo();
    });
  };

  shouldResetState(prevProps: Props) {
    const { recorderEnabled, agreement } = this.props;
    const { processingErrorCode } = this.state;

    if (processingErrorCode) {
      return false;
    }

    const recorderHasBeenEnabled = !prevProps.recorderEnabled && recorderEnabled;
    const videoHasBeenRemoved = (
      prevProps.agreement.welcomeVideo !== agreementConstants.AGREEMENT_WITHOUT_VIDEO
      && agreement.welcomeVideo === agreementConstants.AGREEMENT_WITHOUT_VIDEO
    );

    return videoHasBeenRemoved || recorderHasBeenEnabled;
  }

  turnOffStreamTracks() {
    const { stream } = this.state;

    if (stream && stream.active) {
      stream.getTracks().forEach((track) => track.stop());
    }
  }

  renderError(error: ErrorMessage) {
    return (
      <ProcessingStatus
        data-testid="processing-error-state"
        statusMessage={error.headerText}
        icon={<Error height="48px" />}
        actions={(
          <Button
            onClick={this.onResetState}
            icon={RemoveIcon}
            data-testid="reset-button"
            kind="special"
            customClass={style.Rounded}
          />
        )}
        subtext={(
          <span className={style.ErrorMessage}>
            {error.bodyText}
          </span>
        )}
      />
    );
  }

  renderProcessing() {
    return (
      <ProcessingStatus
        statusMessage={(
          <Message
            id="Processing video... Please wait"
            comment="Step of the video encoding pipeline"
          />
        )}
        icon={<CircularSpinner height="32px" />}
        actions={(
          <Button
            onClick={this.props.onCancelProcessing}
            icon={RemoveIcon}
            data-testid="cancel-processing"
            kind="special"
            customClass={style.Rounded}
          />
        )}
      />
    );
  }

  renderSuccess() {
    const { agreement } = this.props;
    return (
      <ProcessingStatus
        data-testid="processing-successful-state"
        statusMessage={(
          <Message
            id="Your video was added successfully"
            comment="Notification after successfully encoding a video"
          />
        )}
        icon={(
          <div className={style.Success}>
            <CheckCircle className={style.SuccessIcon} height="100px" />
          </div>
        )}
        actions={(
          <>
            <Button
              href={`${this.state.backToHref}/${agreement.id}`}
              kind="special"
              external
            >
              {isTemplate(agreement)
                ? (
                  <Message
                    id="Back to template"
                    comment="Button label. Navigates back to the template after a video is successfully added"
                  />
                ) : (
                  <Message
                    id="Back to contract"
                    comment="Button label. Navigates back to the contract after a video is successfully added"
                  />
                )}
            </Button>
            <Button
              kind="linkInline"
              onClick={this.onResetState}
              data-testid="dismiss-success"
            >
              <Message
                id="Dismiss"
                comment="The button text for dismissing the success message after adding a video"
              />
            </Button>
          </>
        )}
      />
    );
  }

  renderUploading() {
    return (
      <ProcessingStatus
        statusMessage={(
          <Message
            id="Uploading video... Please wait"
            comment="Step of the video encoding pipeline"
          />
        )}
        icon={<CircularSpinner height="32px" />}
        actions={(
          <div className={style.Placeholder} />
        )}
      />
    );
  }

  renderPendingState() {
    const {
      agreement,
      uploadVideoState,
      recorderEnabled,
    } = this.props;
    const { displaySuccessMessage } = this.state;
    const error = this.getError();

    if (error) {
      return this.renderError(error);
    }

    if (displaySuccessMessage) {
      return this.renderSuccess();
    }

    if (uploadVideoState.loading) {
      return this.renderUploading();
    }

    if (agreement.welcomeVideo === agreementConstants.AGREEMENT_VIDEO_PROCESSING
      && !recorderEnabled) {
      return this.renderProcessing();
    }

    return null;
  }

  render() {
    const {
      recordedVideo,
      agreement,
      recorderEnabled,
    } = this.props;
    const { stream, willUploadVideo } = this.state;
    const pendingState = this.renderPendingState();

    if (pendingState) {
      return pendingState;
    }

    if (agreement.welcomeVideo === agreementConstants.AGREEMENT_WITH_VIDEO
      && !recorderEnabled) {
      return (
        <VideoPlayer videoUrl={`/api/agreements/${agreement.id}/video`} />
      );
    }

    if (isMobileBrowser()) {
      return (
        <div className={style.ControlsCanvas}>
          <div className={style.Canvas}>
            <div className={style.RecorderControlsContainer}>
              <MobileRecorderControls onReady={this.props.onUpload} />
            </div>
          </div>
        </div>
      );
    }

    if (willUploadVideo) {
      return (
        <div className={style.ControlsCanvas}>
          <VideoUpload onUpload={this.props.onUpload} />
          <Button
            onClick={this.onResetState}
            icon={RemoveIcon}
            data-testid="reset-button"
            kind="special"
            customClass={style.Rounded}
          />
        </div>
      );
    }

    if (stream) {
      return (
        <VideoRecorder
          recordedVideo={recordedVideo}
          stream={stream}
          onStop={this.props.onRecordFinished}
          onCancel={this.onCancelRecording}
          onClear={this.onClearRecording}
        />
      );
    }

    return (
      <div className={style.ControlsCanvas}>
        <div className={style.Canvas}>
          <div className={style.RecorderControlsContainer}>
            <RecorderControls
              requestCamera={this.requestCamera}
              requestScreen={this.requestScreen}
              requestUpload={this.requestUpload}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
