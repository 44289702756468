// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import NewEmailIcon from 'components/icons/new-email';
import PersonIcon from 'components/icons/person';
import PhoneIcon from 'components/icons/phone';
import SignMethodIcon from 'components/icons/sign-method';
import SendIcon from 'components/icons/send';
import CheckShieldIcon from 'components/icons/check-shield';

import style from './personal-info.module.scss';

type Props = {
  ssn?: string,
  email?: string,
  phoneNumber?: string,
  signMethod?: string,
  deliveryChannel?: string,
  twostepAuth?: string,
};

type InfoProps = {
  property?: string,
  icon: React.ComponentType<IconProps>,
  title: string | React.Element,
  height: string,
  customIconClass?: string,
};

export const Info = ({
  property,
  icon: Icon,
  title,
  height,
  customIconClass,
}: InfoProps) => {
  if (!property) {
    return null;
  }

  return (
    <div className={style.Info}>
      <div className={style.Label}>
        <Icon height={height} className={customIconClass || style.Icon} />
        <span className={style.Title}>{title}</span>
      </div>
      <div className={style.Property}>
        {property}
      </div>
    </div>
  );
};

Info.defaultProps = {
  property: undefined,
};

const PersonalInfo = ({
  ssn,
  email,
  phoneNumber,
  signMethod,
  deliveryChannel,
  twostepAuth,
}: Props) => (
  <div className={style.PersonalInfo}>
    <Info
      property={ssn}
      icon={PersonIcon}
      title={(
        <Message id="Personal identification" comment="Social security number label" />
      )}
      height="10px"
    />
    <Info
      property={email}
      icon={NewEmailIcon}
      title={(
        <Message id="Email" comment="Email address label" />
      )}
      height="11px"
      customIconClass={style.EmailIcon}
    />
    <Info
      property={phoneNumber}
      icon={PhoneIcon}
      title={(
        <Message id="Mobile" comment="Mobile number label" />
      )}
      height="10px"
    />
    <Info
      property={signMethod}
      icon={SignMethodIcon}
      title={(
        <Message id="Sign method" comment="Sign method label" />
      )}
      height="10px"
    />
    <Info
      property={deliveryChannel}
      icon={SendIcon}
      title={(
        <Message id="Delivery channel" comment="Delivery channel label" />
      )}
      height="10px"
    />
    <Info
      property={twostepAuth}
      icon={CheckShieldIcon}
      title={(
        <Message id="Two-factor authentication" comment="Two-factor authentication label" />
      )}
      height="10px"
    />
  </div>
);

PersonalInfo.defaultProps = {
  ssn: undefined,
  email: undefined,
  phoneNumber: undefined,
};

export default PersonalInfo;
