import { useSelector } from 'react-redux';
import { getGuestToken } from 'agreement/selectors';

export const PREVIEW = 'preview';

const useIsInPreviewMode = () => {
  const guestToken = useSelector(getGuestToken);

  return guestToken === PREVIEW;
};

export default useIsInPreviewMode;
