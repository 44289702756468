import { useSelector } from 'react-redux';

import { isMarkAsSignedActionVisible } from 'agreement';
import { getAccountFromSessionSelector } from 'reducers/session';
import { isContractPristine } from 'reducers/current-contract';

import MarkAsSignedAction from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons/mark-as-signed';
import { isContractEditable } from 'components/document-layout-container/helpers';
import { DiscardSaveImportActions } from './buttons-call-to-action';

type Props = {
  agreement: Oneflow.Agreement

}
const ImportActions = ({ agreement }: Props) => {
  const accountFromSession = useSelector(getAccountFromSessionSelector);
  const showMarkedAsSigned = isMarkAsSignedActionVisible(agreement, accountFromSession);
  const isPristine = useSelector(isContractPristine);
  const isEditable = isContractEditable(agreement);

  if (showMarkedAsSigned) {
    if (!isPristine && isEditable) {
      return <DiscardSaveImportActions agreementId={agreement.id} />;
    }

    return (
      <MarkAsSignedAction
        agreementId={agreement.id}
      />
    );
  }

  return null;
};

export default ImportActions;
