// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getAccountFromSessionSelector } from 'reducers/session';
import accounts from 'reducers/entities/accounts';

import Account, { type Props } from './account';

type AccountArgs = {
  accountId: number,
};

export const mapStateToProps = (state: State) => {
  const account = getAccountFromSessionSelector(state);

  return {
    account,
  };
};

type DispatchProps = {|
  fetchBilling(AccountArgs): void,
|};
type MapDispatchToProps = (Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch: Dispatch<*>) => ({
  fetchAccount: ({ id }) => {
    dispatch(accounts.fetchAccount({
      id,
    }));
  },
});

export default connect<Props, any, any, $Exact<DispatchProps>, State, Dispatch<*>>(
  mapStateToProps, mapDispatchToProps,
)(Account);
