// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  formatDateString,
  getDaysSince,
} from 'date';

import CalendarPlus from 'components/icons/calendar-plus';

import Insight from './insight';

import style from './insight.module.scss';

type Props = {
  created: string,
  dateFormat: string,
};

const Created = ({ created, dateFormat }: Props) => {
  const renderInsight = () => {
    const daysSince = getDaysSince(created);
    const dateString = formatDateString(created, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    if (daysSince === 0) {
      return (
        <Message
          id="Created today ({date})"
          values={{
            date,
          }}
          comment="Insight for draft agreement, stating when agreement was created. Visible in contract card."
        />
      );
    }

    if (daysSince > 7) {
      return (
        <Message
          id="Created {date}"
          values={{
            date,
          }}
          comment="Insight for draft agreement, stating when agreement was created. Visible in contract card."
        />
      );
    }

    if (daysSince > 0) {
      return (
        <Message
          id="Created yesterday ({date})"
          pluralId="Created {days} days ago ({date})"
          pluralCondition="days"
          values={{
            days: daysSince,
            date,
          }}
          comment="Insight for draft agreement, stating when agreement was created. Visible in contract card."
        />
      );
    }

    return null;
  };

  return (
    <Insight
      IconComponent={CalendarPlus}
      insight={renderInsight()}
    />
  );
};

export default Created;
