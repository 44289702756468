import { useState } from 'react';
import { Message } from '@oneflowab/pomes';
import { SendButton } from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons';
import EmailIcon from 'components/icons/email';
import { PendingApprovalModal } from './pending-approval-modal';

import style from './approver-last-action.module.scss';

type Props = {
  agreement: Oneflow.Agreement;
  hasPermissionToPublish: boolean;
};

export const ApproverLastAction = ({
  agreement,
  hasPermissionToPublish,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => setIsOpen(true);

  const renderSendButton = () => (
    <SendButton
      onClick={handleClick}
      agreementId={agreement.id}
      hasPermissionToPublish={hasPermissionToPublish}
      customClassName={style.SendButton}
      isAgreementDraft
    >
      <EmailIcon height="16px" />
      <Message
        id="Send"
        comment="Button label used to confirm sending a contract in a modal"
      />
    </SendButton>
  );

  return (
    <PendingApprovalModal
      agreement={agreement}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {renderSendButton}
    </PendingApprovalModal>
  );
};
