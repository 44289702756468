// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

type Props = {
  group: Group,
  removeState: RemoveState,
  resetRemoveState: Function,
  removeGroup: () => void,
  children: Function,
}

export const RemoveGroup = ({
  group,
  removeState,
  removeGroup,
  resetRemoveState,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={removeGroup}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <>
        <Message
          id="You are about to delete the default group {group}. This can't be undone."
          values={{
            group: <Badge label={group.name} kind="name" />,
          }}
          comment="Confirm modal body when removing a group."
        />
        <Message
          id="Users in this group will not be affected."
          pluralId="Users in these groups will not be affected."
          pluralCondition="count"
          values={{
            count: 1,
          }}
          comment="Confirm modal body when removing groups."
        />
      </>
    )}
    modalKey="delete group modal"
  >
    {children}
  </RemoveConfirm>
);
