import type { Column } from 'data-validators/entity-schemas/document-box/product-table';

// Count types
export const PRODUCT_COUNT_TYPE_CHECKBOX = 1;
export const PRODUCT_COUNT_TYPE_NUMBER = 2;
export const PRODUCT_COUNT_TYPE_SINGLE_CHOICE = 3;

// Discount types
export const NO_DISCOUNT = 0;
export const PRODUCT_NUMERIC_DISCOUNT = 2;
export const PRODUCT_PERCENTAGE_DISCOUNT = 1;

// Product selection states
export const PRODUCT_NOT_SELECTED = 0;
export const PRODUCT_SELECTED = 1;

// Input validation states
export const BAD_INPUT = 'badInput';
export const INVALID_PATTERN = 'invalidPattern';
export const INVALID_RANGE = 'invalidRange';
export const INVALID_VALUE = 'invalidValue';
export const PRISTINE = 'pristine';
export const VALID = 'valid';

// Length and value limits
export const AFFIX_MAX_LENGTH = 40;
export const COL_NAME_MAX_LENGTH = 130;
export const MAX_PRICE_VALUE = 999999999;
export const MAX_PRODUCT_COUNT = 99999999;
export const PRICE_STEP = 0.0001;

// Precision and rounding
export const DEFAULT_DISCOUNT_ROUNDING_PRECISION = 4;
export const DEFAULT_DISPLAY_DISCOUNT_ROUNDING_PRECISION = 2;
export const DEFAULT_DISPLAY_PRICE_ROUNDING_PRECISION = 2;
export const DEFAULT_QUANTITY_PRECISION = 0;

// Layout dimensions
export const PRODUCT_TABLE_EXPANDED_VIEW_MIN_WIDTH = 820;
export const PRODUCT_TABLE_MIN_WIDTH_TOLERANCE_DELTA = 60;

// Table column keys
export const PRODUCT_TABLE_COLUMNS: {
  NAME: Extract<Column['key'], 'name'>,
  DESCRIPTION: Extract<Column['key'], 'description'>,
  PRICE_1: Extract<Column['key'], 'price_1'>,
  PRICE_2: Extract<Column['key'], 'price_2'>,
  COUNT: Extract<Column['key'], 'count'>,
} = {
  NAME: 'name',
  DESCRIPTION: 'description',
  PRICE_1: 'price_1',
  PRICE_2: 'price_2',
  COUNT: 'count',
};

// Table metadata
export const PRODUCT_TABLE_META_DATA = {
  PRICE_SUM: 'price_sum',
};

// Table name
export const PRODUCT_TABLE_NAME = {
  NAME: 'Product table name',
};

// Rounding methods
export const PRICE_ROUNDING_METHOD = {
  BANKERS: 'bankers',
  LEGACY: 'legacy',
};
