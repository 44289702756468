// @flow

import { connect } from 'react-redux';
import { getDateFormat } from 'date';

import { getAccountFromSessionSelector } from 'reducers/session';

import { PaxmlExportArea } from './paxml-export-area';

type StateProps = {|
  dateFormat: string,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);

  return {
    dateFormat: getDateFormat(account.brandingDateFormat),
  };
};

type MapDispatchToProps = () => {||};

export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<any, {||}, StateProps, any, State, any>(
  mapStateToProps,
  mapDispatchToProps,
)(PaxmlExportArea);
