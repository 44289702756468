export const ACLs = {
  page: {
    FIND_IN_MARKETPLACE: 'account:marketplace',
  },
  workspace: {
    NAVIGATE_TO_DASHBOARD: 'collection:module:dashboard:use',
    NAVIGATE_TO_DOCUMENTS: 'collection:module:agreement:use',
    NAVIGATE_TO_TEMPLATES: 'collection:module:template:use',
    NAVIGATE_TO_ADDRESS_BOOK: 'collection:module:addressbook:use',
  },
  account: {
    NAVIGATE_TO_ADMIN_ACCOUNT_OVERVIEW: 'account:admin',
    NAVIGATE_TO_ADMIN_ACCOUNT_ACCESS: 'account:admin:role',
    NAVIGATE_TO_ADMIN_USERS: 'account:admin:user',
    NAVIGATE_TO_ADMIN_WORKSPACE: 'account:admin:workspace',
    NAVIGATE_TO_ADMIN_GROUPS: 'account:admin:group',
    NAVIGATE_TO_ADMIN_ROLES: 'account:admin:role',
    NAVIGATE_TO_MARKETPLACE: 'account:marketplace',
    NAVIGATE_TO_ADMIN_TAG: 'account:admin:tag',
    NAVIGATE_TO_ADMIN_BILLING: 'account:admin:billing',
    NAVIGATE_TO_ADMIN_AUDIT_LOG: 'account:admin:audit_log',
    NAVIGATE_TO_ADMIN_DATA_MANAGEMENT: 'account:admin:data_management',
  },
} as const;
