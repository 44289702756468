// @flow

import { isDraft } from 'agreement';
import * as participantConstants from 'agreement/participant/constants';

const getSameDeviceLink = (agreement: Agreement, participant: AgreementParticipant) => {
  if (participant.deliveryChannel === participantConstants.DELIVERY_CHANNEL_SAME_DEVICE) {
    const pathPart = isDraft(agreement) ? 'sd' : 'at';
    const path = `/api/agreements/${agreement.id}/participants/${participant.id}/token?redirect=${pathPart}`;

    return path;
  }

  return null;
};

export default getSameDeviceLink;
