// @flow

import React from 'react';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Confirmable from 'components/confirmable';
import ToggleOff from 'components/icons/toggle-off';

import style from './disable-workspace-branding.module.scss';

export type Props = {
  updateState: RpcState,
  disableWorkspaceBranding: () => void,
  message: MessageTranslator,
};

export const handleConfirm = ({ disableWorkspaceBranding, isLoading }: Props) => () => {
  if (isLoading) {
    return;
  }

  disableWorkspaceBranding();
};

export const DisableWorkspaceBrandingComponent = ({
  updateState,
  disableWorkspaceBranding,
  message,
}: Props) => (
  <Confirmable
    data-testid="branding-disable"
    header={message({
      id: 'Disable branding',
      comment: 'Used as the title of the confirmation dialog',
    })}
    modalKey="disable branding modal"
    body={(
      <p>
        <Message
          id="You are about to disable the branding for this workspace. The account branding will instead be applied."
          comment="Used as warning text for deactivating workspace branding."
        />
      </p>
    )}
    error={updateState.error}
    kind="secondary"
    icon={ToggleOff}
    customClass={style.Button}
    onEnter={handleConfirm({ disableWorkspaceBranding, isLoading: updateState.loading })}
    isConfirmLoading={updateState.loading}
    onConfirm={disableWorkspaceBranding}
  >
    <Message
      id="Disable branding"
      comment="Used as the text of the relevant button"
    />
  </Confirmable>
);

export default localize<Props>(DisableWorkspaceBrandingComponent);
