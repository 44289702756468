// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Edit from 'components/icons/edit';
import { MenuItem } from 'components/menu-item';

type Props = {
  disabled?: boolean,
  onClick: () => void,
}

export const RenameMenuItem = ({
  disabled,
  onClick,
}: Props) => (
  <MenuItem
    disabled={disabled}
    label={(
      <Message
        id="Rename"
        comment="Label for editing a name action in menu."
      />
    )}
    onClick={onClick}
    icon={Edit}
    buttonId="rename"
  />
);

RenameMenuItem.defaultProps = {
  disabled: undefined,
};
