// @flow

// The cjs module uses `exports.default = isHotkey; and exports.isHotkey = isHotkey;`
// Not treated or converted by webpack as esm module
import { isHotkey } from 'is-hotkey';
import { indentPlugin, markPlugin } from 'components/rich-text-editor-toolbars/toolbar-buttons';
import {
  handleOnEnter,
  handleOnBackspace,
  handleOnTableKeyDown,
} from 'components/rich-text-editor/utils';

const HOTKEYS_BASE = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const HOTKEYS = {
  ...HOTKEYS_BASE,
  tab: 'indent',
  'shift+tab': 'outdent',
};

const createOnKeyDown = (editor: any, isTabDisabled: boolean) => (
  (event: any) => {
    handleOnBackspace(editor, event);
    handleOnEnter(editor, event);
    handleOnTableKeyDown(editor, event);

    if (event.defaultPrevented) {
      return;
    }

    const hotkeys = isTabDisabled ? HOTKEYS_BASE : HOTKEYS;
    Object.entries(hotkeys).forEach(([hotkey, mark]) => {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        if (mark === 'indent') {
          indentPlugin.insertIndentation(editor);
          return;
        }
        if (mark === 'outdent') {
          indentPlugin.insertShiftIndentation(editor);
          return;
        }
        markPlugin.toggleMark(editor, mark);
      }
    });
  }
);

export default createOnKeyDown;
