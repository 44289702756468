// @flow

import {
  Editor,
  Element as SlateElement,
} from 'slate';

export const isTextStyleElement = (element) => (
  element?.type?.startsWith('heading-')
  || element.type === 'title'
  || element.type === 'subtitle'
  || element.type === 'block-quote'
);

// eslint-disable-next-line import/prefer-default-export
export const findActiveStyledTextElement = (editor: any) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && isTextStyleElement(n)
    ),
  });

  if (!match) {
    return null;
  }

  return match;
};

export const getActiveStyledTextNode = (editor: any) => {
  const match = findActiveStyledTextElement(editor);
  if (!match) {
    return null;
  }
  return match[0];
};
