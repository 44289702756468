// @flow
import * as React from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';

const options = (message) => ([
  {
    value: 'opened',
    label: message({
      id: 'Opened',
      comment: 'Search filter label. Used when searching documents, as in "find all documents that are opened by at least one counterparty"',
    }),
    tooltipMessage: message({
      id: 'Documents opened by at least 1 counterparty',
      comment: 'Search filter tooltip.',
    }),
  },
  {
    value: 'not_opened',
    label: message({
      id: 'Not opened',
      comment: 'Search filter label. Used when searching documents, as in "find all documents that are not opened by any of its counterparty"',
    }),
  },
  {
    value: 'delivery_failed',
    label: message({
      id: 'Delivery failed',
      comment: 'Search filter label. Used when searching documents, as in "find all documents where at least one counterparty has not received the document"',
    }),
    tooltipMessage: message({
      id: 'Delivery failed for at least 1 counterparty',
      comment: 'Search filter tooltip',
    }),
  },
]);

type FilterOption = {
  value: string,
  label: React.Node,
};

type GetOptions = (message: MessageTranslator, filterables: Array<string>) => Array<FilterOption>;

const insightFilterOptions: GetOptions = (message, filterables) => options(message)
  .filter((option) => filterables.includes(option.value));

export default insightFilterOptions;
