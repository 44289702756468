// @flow

import { connect } from 'react-redux';

import { getPathnameSelector } from 'reducers/router';

import {
  getPositionFromSessionSelector,
  logout,
} from 'reducers/session';

import MyProfile from './my-profile';

export const mapStateToProps = (state: State) => {
  const position = getPositionFromSessionSelector(state);
  const pathname = getPathnameSelector(state);

  return {
    position,
    pathname,
  };
};

export const mapDispatchToProps = (dispatch: any) => ({
  logout: () => {
    dispatch(logout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
