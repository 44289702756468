import SelectionMenuDefault from './selection-menu-default';
import SelectionMenuFilterable from './selection-menu-filterable';

type Props = {
  onSelect: (prompt: string) => void;
  inputValue: string;
}

const SelectionMenu = ({ onSelect, inputValue }: Props) => (
  inputValue.trim().length === 0
    ? <SelectionMenuDefault onSelect={onSelect} />
    : <SelectionMenuFilterable onSelect={onSelect} />
);

SelectionMenu.displayName = 'SelectionMenu';

export default SelectionMenu;
