// @flow

import * as React from 'react';
import moment from 'moment';

import ControlPause from 'components/icons/control-pause';
import ControlRecord from 'components/icons/control-record';
import ControlStop from 'components/icons/control-stop';

import style from './record-timer.module.scss';

type Props = {|
  isRecording: boolean,
  isPaused: boolean,
  duration: number,
|};

const RecordTimer = (props: Props) => {
  const { isRecording, isPaused, duration } = props;

  let Icon = ControlStop;
  if (isRecording) {
    Icon = ControlRecord;
  }

  if (isPaused) {
    Icon = ControlPause;
  }

  let className = style.Recording;
  if (isPaused || !isRecording) {
    className = style.Paused;
  }

  return (
    <div className={style.RecordTimer}>
      <div className={style.Container}>
        <Icon className={className} height="24px" />
        <div className={style.Duration}>
          {moment.utc(duration).format('mm:ss')}
        </div>
      </div>
    </div>
  );
};

export default RecordTimer;
