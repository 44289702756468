import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Message, MessageTranslator } from '@oneflowab/pomes';
import { Form as ReactFinalForm } from 'react-final-form';
import type { FieldRenderProps } from 'react-final-form';
import type { FormApi } from 'final-form';

import { formatJan312021, getDateFormat, getDateFormatsAsOptions } from 'date';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import { getLocationSelector } from 'reducers/router';
import accountsReducer from 'reducers/entities/accounts';
import client from 'oneflow-client';

import * as EditableForm from 'components/editable-form';
import FileUpload from 'components/file-upload';
import Field from 'components/field';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import { getCountryListAsOptions } from 'components/countries';
import { checkAcl } from 'components/acl';

import { CounterPartyPreview, EmailPreview } from 'components/logo-preview';
import { FILE_IMAGE_PNG, FILE_IMAGE_BMP, FILE_IMAGE_JPEG } from 'utils/file';
import AccountLogo from './account-logo';
import style from './form.module.scss';

export type FormData = {
  brandingDateFormat: string;
  country: string;
  logoId: number;
  name: string;
  orgnr: string;
};

type Props = {
  message: MessageTranslator;
};

type FileParameterType = {
  file: File, type: 'document' | 'logo'
}

const AccountFormContent = ({ message }: Props) => {
  const dispatch = useDispatch();
  const accountId: Oneflow.Account['id'] = useSelector(getAccountFromSessionSelector)?.id;
  const account: Oneflow.Account = useSelector(getAccountFromSessionSelector);
  const canEdit = checkAcl(account.acl, 'account:update:branding');

  const currentLanguage = useSelector(getCurrentLanguageSelector);
  const location = useSelector(getLocationSelector);
  const dateFormat = getDateFormat(account.brandingDateFormat);
  const formState = useSelector((state) => accountsReducer.getUpdateSelector(state, {
    id: account.id,
  }));
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const resetFormState = () => {
    dispatch(accountsReducer.updateAccountReset({ id: account.id }));
  };
  const [formDataFileUrl, setFormDataFileUrl] = useState<null | string>(null);

  const editParams = location.query?.edit;

  // we need to refetch account data to get the latest logoUrl, otherwise logoUrl access expires
  useEffect(() => {
    dispatch(accountsReducer.fetchAccount({
      id: accountId,
    }));
  }, [accountId, dispatch]);

  useEffect(() => {
    if (account?.logoUrl) {
      setFormDataFileUrl(account.logoUrl);
    }
  }, [account?.logoUrl]);

  const getInitialValues = () => {
    const {
      brandingDateFormat,
      country,
      name,
      orgnr,
    } = account;

    return {
      brandingDateFormat,
      country,
      name,
      orgnr,
    };
  };

  const onSubmit = (formData: ReturnType<typeof getInitialValues> & {logoId: number | null}) => {
    const {
      brandingDateFormat,
      country,
      name,
      orgnr = null,
      logoId,
    } = formData;

    dispatch(accountsReducer.updateAccount({
      id: account.id,
      data: {
        brandingDateFormat: brandingDateFormat?.value,
        country: country?.value,
        name,
        orgnr,
        logoId,
      },
      pipe: {
        onSuccess: () => {
          setIsEditMode(false);

          if (editParams) {
            const browserUrl = window.location.href;
            window.history.replaceState(null, '', browserUrl.split('?')[0]);
          }
        },
      },
    }));
  };

  // enter edit mode if url contains ?edit=true
  useEffect(() => {
    if (!formState.success && editParams) {
      setIsEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpload = async (file: FileParameterType, form: FormApi) => {
    const uploadAsset = await client.uploadAsset({
      file: file.file,
      type: file.type,
    });

    form.change('logoId', uploadAsset.assetId);
    setFormDataFileUrl(uploadAsset.assetUrl);

    return uploadAsset;
  };

  return (
    <ReactFinalForm
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <EditableForm.Header>
            <EditableForm.Actions
              resetFormState={() => {
                setFormDataFileUrl(account.logoUrl);
                resetFormState();
              }}
              formState={formState}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              disabled={!canEdit}
            />
          </EditableForm.Header>
          <EditableForm.Body>
            <div className={style.LogoRow}>
              <div>
                <EditableForm.Label>
                  <Message
                    id="Logo"
                    comment="Used as the label of the relevant field in the account branding page."
                  />
                </EditableForm.Label>
                {isEditMode ? (
                  <>
                    <Field name="logoId">
                      {({ input }: { input: FieldRenderProps<HTMLInputElement> }) => (
                        <input type="hidden" {...input} />
                      )}
                    </Field>
                    <div className={style.FileUploadContainer}>
                      <FileUpload
                        onUpload={(file: FileParameterType) => onUpload(file, form)}
                        fileUrl={formDataFileUrl || account.logoUrl}
                        input={{
                          onChange: (value: number | null) => {
                            if (!value) {
                              form.change('logoId', value);
                              setFormDataFileUrl(null);
                            }
                          },
                        }}
                        accept={[FILE_IMAGE_PNG, FILE_IMAGE_BMP, FILE_IMAGE_JPEG]}
                        displayFileType="image"
                        assetType="logo"
                      />
                    </div>
                  </>
                ) : <AccountLogo url={account.logoUrl} message={message} />}
              </div>
              <div>
                <EditableForm.Label>
                  <Message
                    id="Counterparty preview"
                    comment="Used as the label of the relevant field in the account branding page."
                  />
                </EditableForm.Label>
                <CounterPartyPreview
                  companyName={account.name}
                  logo={formDataFileUrl}
                />
              </div>
              <div>
                <EditableForm.Label>
                  <Message
                    id="Email preview"
                    comment="Used as the label of the relevant field in the account branding page."
                  />
                </EditableForm.Label>
                <EmailPreview
                  companyName={account.name}
                  logo={formDataFileUrl}
                />
              </div>
            </div>
            <div className={style.Row}>
              <EditableForm.Label>
                <Message id="Company name" comment="Label in the account branding page." />
              </EditableForm.Label>
              <Field
                id="name"
                name="name"
                component={TextField}
                value={account.name}
                placeholder={message({
                  comment: 'Placeholder in the account branding page.',
                  id: 'Enter company name',
                })}
                autoFocus
                maxLength={100}
                className={style.Input}
                disabled={!isEditMode}
              />
            </div>
            <div className={clsx(style.Row, style.Grid)}>
              <div>
                <EditableForm.Label>
                  <Message id="Country" comment="Label in the account branding page." />
                </EditableForm.Label>
                <Field
                  id="country"
                  name="country"
                  component={SelectField}
                  value={account.country}
                  options={getCountryListAsOptions(currentLanguage)}
                  placeholder={message({
                    comment: 'Placeholder in the account branding page.',
                    id: 'Select country',
                  })}
                  maxLength={100}
                  className={style.Input}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <EditableForm.Label>
                  <Message id="Registration number" comment="Label in the account branding page." />
                </EditableForm.Label>
                <Field
                  id="orgnr"
                  name="orgnr"
                  component={TextField}
                  value={account.orgnr || '-'}
                  placeholder={message({
                    comment: 'Placeholder in the account branding page.',
                    id: 'Enter registration number',
                  })}
                  maxLength={25}
                  disabled={!isEditMode}
                />
              </div>
            </div>
            <div className={clsx(style.Row, style.Grid)}>
              <div>
                <EditableForm.Label>
                  <Message id="Date format" comment="Label in the account branding page." />
                </EditableForm.Label>
                <Field
                  id="brandingDateFormat"
                  name="brandingDateFormat"
                  component={SelectField}
                  value={`${dateFormat} (${formatJan312021(dateFormat)})`}
                  options={getDateFormatsAsOptions({ message })}
                  placeholder={message({
                    comment: 'Placeholder in the account branding page.',
                    id: 'Select date format',
                  })}
                  searchable
                  clearable={false}
                  disabled={!isEditMode}
                />
              </div>
            </div>

          </EditableForm.Body>
        </form>
      )}
    />
  );
};

export default AccountFormContent;
