// @flow

import * as React from 'react';
import { matchPath } from 'react-router';
import { NavLink } from 'react-router-dom';
import urlJoin from 'url-join';

import { amplitudeLogEvent } from 'client-analytics/amplitude';

import ExternalLink from 'components/icons/external-link';
import { BetaLabel } from 'components/beta-label';

import type { Location } from 'react-router';

import style from './admin-page.module.scss';

type Props = AdminPageTab & {
  location: Location,
};

const Tab = (tab: Props) => {
  if (tab.hide) {
    return null;
  }

  if (tab.isExternal) {
    return (
      <a
        className={style.Tab}
        href={tab.path}
        id={tab.id}
        rel="noopener noreferrer"
        target="_blank"
      >
        {tab.label}
        <ExternalLink className={style.Icon} height="14px" />
      </a>
    );
  }

  let to = tab.path;
  if (tab.getSearchFromCache) {
    to = urlJoin(tab.path, tab.getSearchFromCache(tab.location));
  }
  const isActive = (match, location) => !!(matchPath(location.pathname, { path: tab.path }));

  const onClick = () => {
    if (tab.event) {
      amplitudeLogEvent(tab.event.event, tab.event.props);
    }
  };

  return (
    <NavLink
      className={style.Tab}
      activeClassName={style.Active}
      isActive={isActive}
      to={to}
      id={tab.id}
      onClick={onClick}
    >
      {tab.label}
      {tab.isBeta && <BetaLabel />}
    </NavLink>
  );
};

export default Tab;
