import { localize, Message, MessageTranslator } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';

import { getCurrentLanguageSelector } from 'reducers/i18n';
import { getTexts } from 'components/pages/ai-insights/texts';

type Props = {
  playbook: {
    key: string, values: { agreementTerm?: string, paymentTerm?: string, governingLaws?: string },
  },
  insightType: string,
  playbookTemplateType: 'SALES' | 'NDA' | 'SOURCING',
  message: MessageTranslator,
};

type InsightsTexts = {
  [templateType in 'SALES' | 'NDA' | 'SOURCING']: {
    [insightKey in string]: {
      insightTitle: React.ReactElement;
      insightTooltip: React.ReactElement;
      getPlaybookText: (values: any) => React.ReactElement;
    };
  };
};

const PlaybookComponent: React.FC<Props> = ({
  playbook, insightType, playbookTemplateType, message,
}: Props): React.ReactElement => {
  const currentLanguage = useSelector(getCurrentLanguageSelector) || 'en';
  const { values } = playbook || {};
  const { insightsTexts } = getTexts(message, currentLanguage) as unknown as {
    insightsTexts: InsightsTexts,
  };

  if (insightsTexts[playbookTemplateType][insightType]) {
    return insightsTexts[playbookTemplateType][insightType].getPlaybookText(values);
  }

  return (
    <Message
      id="Missing"
      comment="Fallback text"
    />
  );
};

export const Playbook = localize(PlaybookComponent);
