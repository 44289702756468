import { DefaultRootState, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import { isEmpty } from 'lodash';

import tagConnectionsReducer from 'reducers/entities/tag-connections';
import tagsReducer from 'reducers/entities/tags';

import TagIcon from 'components/icons/tag';
import { MenuItem } from 'components/menu-item';
import AddTagModal from 'components/tags/add-tag';

const TAGS_SELECTOR_QUERY_NAME = 'tags-selector';

type Props = {
  agreement: Oneflow.Agreement,
  visible?: boolean,
}

const AddTag = ({ agreement, visible }: Props) => {
  const isAgreementSpecificTag = (tagConnection: Oneflow.Tag) => (
    tagConnection.target.id === agreement.id && tagConnection.targetType === 'agreement'
  );

  const getTagDetails = (state: DefaultRootState) => (tagConnection: Oneflow.Tag) => ({
    ...tagConnection,
    tag: tagsReducer.getTagSelector(state, { id: tagConnection.tag }),
  });

  const isEmptyTag = (tagConnection: Oneflow.Tag) => !isEmpty(tagConnection.tag);

  const tagConnections: Oneflow.Tag[] = useSelector((state) => (
    tagConnectionsReducer.getAllTagConnectionsSelector(state)
      .filter(isAgreementSpecificTag)
      .map(getTagDetails(state))
      .filter(isEmptyTag)
  ));

  const tagsSelectorQuery = useSelector((state) => tagsReducer.getQuerySelector(
    state, { name: TAGS_SELECTOR_QUERY_NAME },
  ));

  if (visible === false) {
    return null;
  }

  const getChildren = (onClick: () => void) => (
    <MenuItem
      icon={TagIcon}
      label={(
        <Message
          id="Add tag"
          comment="Label for action to add tag to a contract."
        />
      )}
      onClick={onClick}
    />
  );

  return (
    <AddTagModal
      tagConnections={tagConnections}
      tagsSelectorQuery={tagsSelectorQuery}
      preferPlace="right"
      blacklist={tagConnections?.map((tagConnection) => tagConnection.tag.id)}
      isModal
      targetId={agreement.id}
      targetType="agreement"
      amplitudeScope="contract view"
    >
      {getChildren}
    </AddTagModal>
  );
};

export default AddTag;
