import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import InfoIcon from 'components/icons/info';
import PreviewButton from 'components/buttons/preview';
import Mobile2Icon from 'components/icons/mobile-2';
import MonitorIcon from 'components/icons/monitor';

import style from './preview-header.module.scss';

type PreviewDevice = 'desktop' | 'mobile';

type PreviewDeviceProps = {
  previewDevice: PreviewDevice,
  setPreviewDevice: (device: PreviewDevice) => void,
};

type IconContainerProps = {
  children: React.ReactNode,
  device: PreviewDevice,
} & PreviewDeviceProps;

type PreviewHeaderProps = {
  agreementId: Oneflow.Document['id'],
} & PreviewDeviceProps;

const IconContainer = (
  {
    device, previewDevice, setPreviewDevice, children,
  }: IconContainerProps,
) => (
  <div
    className={clsx(style.IconContainer, {
      [style.Active]: device === previewDevice,
    })}
    onClick={() => setPreviewDevice(device)}
    role="button"
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        setPreviewDevice(device);
      }
    }}
  >
    {children}
  </div>
);

const PreviewHeader = ({
  agreementId, previewDevice, setPreviewDevice,
}: PreviewHeaderProps) => (
  <div className={style.Container}>
    <div className={style.Banner}>
      <InfoIcon height="12px" />
      <span className={style.BannerText}>
        <Message
          id="You are previewing as a counterparty. Changes you make won’t be saved."
          comment="Preview header banner text"
        />
      </span>
    </div>
    <div className={style.RightSideContainer}>
      <div className={style.PreviewControlsContainer}>
        <div className={style.PreviewIconsContainer}>
          <IconContainer
            device="desktop"
            previewDevice={previewDevice}
            setPreviewDevice={setPreviewDevice}
          >
            <MonitorIcon height="16px" />
          </IconContainer>
          <IconContainer
            device="mobile"
            previewDevice={previewDevice}
            setPreviewDevice={setPreviewDevice}
          >
            <Mobile2Icon height="16px" />
          </IconContainer>
        </div>
        <PreviewButton agreementId={agreementId} />
      </div>
    </div>
  </div>
);

export default PreviewHeader;
