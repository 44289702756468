import React, { ReactElement, ReactNode } from 'react';
import { uniqueId } from 'lodash';

import Modal, {
  MultistepModal,
  ModalStep,
} from 'components/modal';

import CancellationNavigation from 'components/cancel-subscription/navigation/cancellation-navigation';
import FlowAction from 'components/cancel-subscription/actions/flow-action';
import ShowFlow from 'components/cancel-subscription/actions/show-flow';
import Submit from 'components/cancel-subscription/actions/submit';
import Close from 'components/cancel-subscription/actions/close';
import NextStep from 'components/cancel-subscription/actions/next-step';
import { SubmitError } from 'components/cancel-subscription/actions/submit-error';

import type { FlowData } from 'types/cancellation-flow';

import style from './cancel-subscription.module.scss';

type Props = {
  modalKey?: string;
  children: ReactNode | ReactNode[];
  onCancel: () => void;
  setFlow?: (flow: string) => void;
}

type StepProps = {
  children: ReactElement;
  setShowModal: (flow: string) => void;
  onCancel: () => void;
  header: ReactElement;
  modalKey: string;
  allowContinue: boolean;
  hidePrevious: boolean;
  handlePreviousStep?: () => void;
  onSyncStepData: () => void;
  modalData: FlowData;
  onStepComplete: () => void;
  onPreviousStep: () => void;
}

type StepsProps = {
  children: ReactNode | ReactNode[];
  onCancel: () => void;
  setShowModal?: (flow: string) => void;
};

const getChildren = (
  { element, type }: {
    element: ReactNode,
    type: typeof ShowFlow | typeof Close
  },
) => React.Children.toArray(element?.props.children).filter((child) => child.type === type);

const Steps = ({ children }: { children: ReactNode | ReactNode[]}) => children;

const StepBody = ({ children }: { children: ReactNode | ReactNode[]}) => children;

const StepActions = ({ children }: { children: ReactNode | ReactNode[]}) => children;

const Step = ({
  children,
  setShowModal,
  onCancel,
  ...renderProps
}: StepProps) => {
  const actions = getChildren({ element: children, type: StepActions });
  const {
    header,
    modalKey,
    allowContinue,
    hidePrevious,
    handlePreviousStep,
    onSyncStepData,
    modalData,
    onStepComplete,
    onPreviousStep,
  } = renderProps;

  const previousStepClickHandler = () => {
    if (handlePreviousStep) {
      handlePreviousStep();
      return;
    }

    onPreviousStep();
  };

  return (
    <Modal
      header={header}
      isOpen
      modalKey={modalKey}
      onCancel={onCancel}
      customBodyClass={style.Body}
    >
      <Modal.Body isLoading={false}>
        <div className={style.BodyContainer}>
          {!hidePrevious && <CancellationNavigation onPreviousStep={previousStepClickHandler} />}
          {getChildren({ element: children, type: StepBody })}
        </div>
      </Modal.Body>
      <Modal.Actions>
        {
          React.Children.toArray(actions[0].props.children).map(
            (child) => (
              <FlowAction
                key={uniqueId()}
                child={child}
                allowContinue={allowContinue}
                onSyncStepData={onSyncStepData}
                modalData={modalData}
                setShowModal={setShowModal}
                onStepComplete={onStepComplete}
                onClose={onCancel}
              />
            ),
          )
        }
      </Modal.Actions>
    </Modal>
  );
};

const renderModalSteps = ({
  children,
  onCancel,
  setShowModal,
}: StepsProps) => {
  const steps = getChildren({ element: children, type: Step });
  return steps.map((step) => (
    <ModalStep modalKey={step.key} key={step.key}>
      {(renderProps) => (
        <Step {...renderProps} {...step.props} onCancel={onCancel} setShowModal={setShowModal}>
          {step}
        </Step>
      )}
    </ModalStep>
  ));
};

const CancelSubscriptionFlow = ({
  modalKey,
  children,
  onCancel,
  setFlow,
}: Props) => (
  <MultistepModal
    isOpen
    onCancel={onCancel}
    modalKey={modalKey}
    customModalClass={style.Modal}
  >
    {renderModalSteps({
      children,
      onCancel,
      setShowModal: setFlow,
    })}
  </MultistepModal>
);

CancelSubscriptionFlow.Steps = Steps;
CancelSubscriptionFlow.Step = Step;
CancelSubscriptionFlow.StepBody = StepBody;
CancelSubscriptionFlow.StepActions = StepActions;
CancelSubscriptionFlow.ShowFlow = ShowFlow;
CancelSubscriptionFlow.Submit = Submit;
CancelSubscriptionFlow.Close = Close;
CancelSubscriptionFlow.NextStep = NextStep;
CancelSubscriptionFlow.SubmitError = SubmitError;

export default CancelSubscriptionFlow;
