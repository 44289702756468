import NewEmailIcon from 'components/icons/new-email';
import PersonFrame from 'components/icons/person-frame';
import type { Contact } from 'types/address-book';
import style from './address-book-search-result.module.scss';

export type AddressBookResult = {
  person: Contact,
}

type Props = {
  contact: AddressBookResult,
  onResultClick: (param: AddressBookResult) => void,
  tabIndex?: number,
  key: string,
};

export const AddressBookSearchResult = ({
  contact,
  onResultClick,
  tabIndex = 0,
  key,
}: Props) => {
  const resultClickHandler = () => onResultClick(contact);
  return (
    <div
      className={style.AddressBookSearchResult}
      onClick={resultClickHandler}
      onKeyDown={resultClickHandler}
      role="button"
      tabIndex={tabIndex}
      key={key}
    >
      <div>
        <span className={style.Company}>{contact.person.companyName}</span>
      </div>
      <div className={style.Label}>
        <PersonFrame height="10px" className={style.Icon} />
        <span className={style.Title}>{contact.person.fullname}</span>
      </div>
      <div className={style.Label}>
        <NewEmailIcon height="11px" className={style.Icon} />
        <span className={style.Title}>{contact.person.email}</span>
      </div>
    </div>
  );
};
