import type { ReactNode } from 'react';
import { useCallback } from 'react';
import clsx from 'clsx';

import NewCrossIcon from 'components/icons/new-cross';

import style from './video-modal.module.scss';

type Props = {
  fade?: boolean,
  noOuterClose: boolean,
  fluid: boolean,
  isOpen: boolean,
  handleClose?: () => void,
  modalContentClass?: string,
  modalCloseButtonClass?: string,
  modalBackdropClass?: string,
  children: ReactNode,
};

const Modal = ({
  handleClose,
  isOpen,
  children,
  modalBackdropClass,
  modalContentClass,
  modalCloseButtonClass,
  fade,
  noOuterClose = false,
  fluid = true,
}: Props) => {
  const onBackdropClick = useCallback(() => {
    if (noOuterClose) {
      return;
    }

    handleClose();
  }, [handleClose, noOuterClose]);

  const modalContainerClassName = clsx(style.VideoModal, {
    [style.DisplayBlock]: isOpen,
    [style.DisplayNone]: !isOpen,
  });

  const modalContentClassName = clsx(style.ModalContent, modalContentClass, {
    [style.ContentFluid]: fluid,
    [style.Fade]: fade,
  });

  return (
    <div className={modalContainerClassName}>
      <button
        onClick={onBackdropClick}
        className={clsx(style.Backdrop, modalBackdropClass)}
        tabIndex={0}
        label="backdrop"
      />
      <section className={modalContentClassName}>
        <div className={style.RelativeWrapper}>
          {children}
        </div>
        <button
          className={clsx(style.CloseButton, modalCloseButtonClass)}
          onClick={handleClose}
          label="close"
        >
          <NewCrossIcon width="10px" />
        </button>
      </section>
    </div>
  );
};

export default Modal;
