import { MouseEvent } from 'react';
import { Message } from '@oneflowab/pomes';

import EditIcon from 'components/icons/edit';
import { MenuItem } from 'components/menu-item';

type Props = {
  disabled?: boolean,
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
}

// You probably should not need to customize label or icon,
// all EditMenuItem should only use the "Edit" label.
// Rather create a specific menu item if needed, e.g. ChangeRoleMenuItem
export const EditMenuItem = ({
  disabled,
  onClick,
}: Props) => (
  <MenuItem
    disabled={disabled}
    label={(
      <Message
        id="Edit"
        comment="Label for edit action in menu."
      />
    )}
    onClick={onClick}
    icon={EditIcon}
  />
);
