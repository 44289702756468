// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import agreementsReducer from 'reducers/entities/agreements';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';

import { CreateBlankContract } from './create-blank-contract';

type StateProps = {|
  currentWorkspace: Workspace,
|};

type DispatchProps = {|
  createContract: number => void,
|};

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  currentWorkspace: getCurrentWorkspaceSelector(state),
});

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  createContract: (workspaceId, selectedFolderId) => {
    dispatch(agreementsReducer.createAgreement({
      data: {
        workspaceId,
        folderId: selectedFolderId,
        amplitudeScope: 'main navigation - new contract modal',
      },
      pipe: {
        onSuccess: redirectToDocumentOnDocumentCreate,
      },
    }));
  },
});

const ConnectedCreateBlankContract = connect<StateProps, DispatchProps, any, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateBlankContract);

export { ConnectedCreateBlankContract as CreateBlankContract };
