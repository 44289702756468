import { ReactNode } from 'react';

import useDependentState from 'hooks/use-dependent-state';

import SelectField from 'components/select-field';

type Option<T extends string | number> = {
  value: T;
  label: ReactNode;
};

export type Props<T extends string | number> = {
  name: string;
  onChange: (value: T, options?: { onFailure: (err?: Error) => void }) => void;
  label: ReactNode;
  options: Option<T>[];
  value: T;
  disabled?: boolean;
};

function SelectFieldComponent<T extends string | number>({
  onChange,
  name,
  label,
  options,
  disabled,
  value,
}: Props<T>) {
  const [selectedValue, setSelectedValue] = useDependentState(
    value, { dependency: value },
  );

  const handleChange = (option : { value : T }) => {
    setSelectedValue(option.value);
    onChange(option.value, {
      onFailure: () => {
        setSelectedValue(value);
      },
    });
  };

  return (
    <>
      <SelectField
        disabled={disabled}
        label={label}
        options={options}
        input={{
          value: selectedValue,
          name,
          onChange: handleChange,
        }}
        labelKey="label"
        noScroll
        hideErrorElement
      />
    </>
  );
}

export default SelectFieldComponent;
