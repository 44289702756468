import { useSelector, useDispatch } from 'react-redux';

import agreementsReducer from 'reducers/entities/agreements';

const useAPIError = (agreementId: number) => {
  const dispatch = useDispatch();

  const updateConfigState = useSelector((state) => (
    agreementsReducer.getUpdateConfigSelector(state, { id: agreementId })
  ));
  const updateAgreementState = useSelector((state) => (
    agreementsReducer.getUpdateAgreementPreferencesSelector(state, { id: agreementId })
  ));

  const updateExpiryDateState = useSelector((state) => (
    agreementsReducer.getUpdateExpiryDateSelector(state, { id: agreementId })
  ));

  const resetRPCStates = () => {
    dispatch(agreementsReducer.updateConfigReset({ id: agreementId }));
    dispatch(agreementsReducer.updateAgreementPreferencesReset({ id: agreementId }));
    dispatch(agreementsReducer.updateExpiryDateReset({ id: agreementId }));
  };

  return {
    resetRPCStates,
    updateConfigState,
    updateAgreementState,
    updateExpiryDateState,
  };
};

export default useAPIError;
