// @flow

import React from 'react';

import AlertIcon from 'components/icons/alert';
import style from './ai-assist.module.scss';

type Props = {
  text: any,
  error: string | Error,
  renderErrorMessage: any,
};

const AiAssistTypewriterContainer = ({
  text,
  error,
  renderErrorMessage,
}: Props) => {
  if (error) {
    return (
      <div className={style.ErrorMessageContainer}>
        <AlertIcon
          width="20px"
          height="20px"
          className={style.AlertIcon}
        />
        <p className={style.ErrorMessage}>{renderErrorMessage(error)}</p>
      </div>
    );
  }

  return (
    <div className={style.TextContainer}>
      <span>{text}</span>
    </div>
  );
};

export default AiAssistTypewriterContainer;
