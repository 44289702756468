// @flow

import React, { useState } from 'react';
import { Message } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import agreementsReducer from 'reducers/entities/agreements';

import { CancelButton, ConfirmButton } from 'components/buttons';
import Button from 'components/button';
import Cancel from 'components/icons/cancel';
import Conditional from 'components/conditional';
import Confirmable from 'components/confirmable';
import FolderTree from 'components/folder-tree';
import WorkspaceIcon from 'components/icons/workspace';

import style from './save-folder-destination.module.scss';

type Props = {
  agreement: Agreement,
  children: any,
  currentWorkspaceId: number,
  currentWorkspaceName: string,
  hasADefaultFolder?: boolean,
};

const modalKey = 'Save destination';

export const SaveFolderDestinationModal = ({
  agreement,
  children,
  currentWorkspaceId,
  currentWorkspaceName,
  hasADefaultFolder,
}: Props) => {
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [hasClearedDefaultFolder, setHasClearedDefaultFolder] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const selectedUniqueKey = currentFolderId == null ? -1 : currentFolderId;
  const dispatch = useDispatch();

  const handleClose = () => {
    setHasClearedDefaultFolder(false);
    setIsOpen(false);
  };

  const handleConfirm = () => {
    dispatch(agreementsReducer.moveAgreement({
      id: agreement.id,
      data: {
        targetFolderId: currentFolderId,
        targetWorkspaceId: currentWorkspaceId,
      },
    }));

    handleClose();
  };

  const handleOpen = () => {
    const currentFolder = agreement.folder?.id;

    setCurrentFolderId(currentFolder);
    setIsOpen(true);
  };

  const clearFolderSelection = () => {
    setCurrentFolderId(null);
    setHasClearedDefaultFolder(true);
  };

  const actionSectionClasses = clsx(style.ActionsSection, {
    [style.WithClearSection]: hasADefaultFolder && !hasClearedDefaultFolder,
  });

  const getIsDirtyState = () => {
    if (agreement.folder?.id === undefined && currentFolderId) {
      return false;
    }

    if (agreement.folder?.id && agreement.folder?.id !== currentFolderId) {
      return false;
    }

    if (hasClearedDefaultFolder) {
      return false;
    }

    return true;
  };

  const getActions = ({ closeConfirmation }) => (
    <div className={actionSectionClasses}>
      <Conditional ifCondition={hasADefaultFolder && !hasClearedDefaultFolder}>
        <Button
          customClass={style.ClearSectionButton}
          icon={Cancel}
          kind="linkInline"
          onClick={clearFolderSelection}
        >
          <Message
            id="Clear selection"
            comment="Button text for clearing folder selection."
          />
        </Button>
      </Conditional>
      <div className={style.RightSideActions}>
        <div className={style.CancelButton}>
          <CancelButton
            modalKey={modalKey}
            onClick={closeConfirmation}
          />
        </div>
        <ConfirmButton
          disabled={getIsDirtyState()}
          isLoading={false}
          onClick={() => {
            handleConfirm();
          }}
        />
      </div>
    </div>
  );

  const selectFolderId = (folderId: any) => {
    const currentFolder = folderId === -1 ? null : folderId;

    setCurrentFolderId(currentFolder);
  };

  return (
    <Confirmable
      actions={getActions}
      body={(
        <div>
          <div className={style.WorkspaceSection}>
            <p>
              Workspace
            </p>
            <span className={style.WorkspaceName}>
              <WorkspaceIcon className={style.WorkspaceIcon} height="18px" />
              {currentWorkspaceName}
            </span>
          </div>
          <FolderTree
            contractFolderId={hasClearedDefaultFolder ? undefined : agreement.folder?.id}
            isModalVersion
            isSaveFolderDestinationModal
            onFolderSelectionHandler={selectFolderId}
            selectedUniqueKey={selectedUniqueKey}
            selectedWorkspaceId={currentWorkspaceId}
            branchContainerClassName={style.FolderTreeBranchContainer}
          />
        </div>
      )}
      header={(
        <Message
          id="Save destination"
          comment="Title for modal where you can choose which folder to save the template in."
        />
      )}
      isOpen={isOpen}
      modalKey={modalKey}
      onClose={handleClose}
      onEnter={handleConfirm}
      onOpen={handleOpen}
    >
      {children}
    </Confirmable>
  );
};
