// @flow

import { connect } from 'react-redux';

import foldersReducer from 'reducers/entities/folders';

import ExportButton from './export-button';

const FOLDERS_QUERY = 'folders';

export const mapStateToProps = (state: State) => {
  const foldersQuery = foldersReducer.getQuerySelector(state, { name: FOLDERS_QUERY });
  const folders = foldersReducer.getFoldersSelector(state, { ids: foldersQuery.result });

  return {
    folders,
  };
};

export default connect<any, State, any, any, any, any>(mapStateToProps)(ExportButton);
