import Svg from 'components/svg';

const Fi = Svg((
  <>
    <path d="M503.172 335.724H8.828A8.829 8.829 0 0 1 0 326.896V9.103A8.829 8.829 0 0 1 8.828.275h494.345a8.829 8.829 0 0 1 8.828 8.828v317.793a8.83 8.83 0 0 1-8.829 8.828z" fill="#F5F5F5" />
    <path fill="#41479B" d="M512 141.517H211.862V.276h-52.965v141.241H0v52.966h158.897v141.241h52.965V194.483H512z" />
  </>
), { viewBox: '0 0 512 336', fill: 'none' });

Fi.displayName = 'Fi';

export default Fi;
