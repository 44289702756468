import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import { isClosed } from 'agreement/states';
import { getGuestToken } from 'agreement/selectors';
import useAgreement from 'hooks/use-agreement';
import Tooltip from 'components/tooltip';

import styles from './no-permission-message.module.scss';

type Props = {
  agreementId: number;
}

const NoPermissionMessage = ({ agreementId }: Props) => {
  const guestToken = useSelector(getGuestToken);
  const agreement = useAgreement(agreementId);

  if (Boolean(guestToken) || isClosed(agreement)) {
    return null;
  }

  return (
    <Tooltip
      side="top"
      messageClassName={styles.TooltipMessage}
      message={(
        <Message
          id="This is only visible for your side"
          comment="Tool tip message explaining that some text is visible only for current user"
        />
      )}
      touchable
      triggerClassName={styles.EmptyContractMessage}
    >
      <div>
        <h1>
          <Message
            id="This contract is empty"
            comment="This message is visible when the contract is empty"
          />
        </h1>
        <p>
          <Message
            id="You do not have permissions to edit this contract."
            comment="This message is visible when the contract is empty"
          />
        </p>
      </div>
    </Tooltip>
  );
};

export default NoPermissionMessage;
