import React from 'react';
import * as ReactIs from 'react-is';
import { isFunction } from 'lodash';
import type { ReactNode, ComponentProps } from 'react';

import Modal from 'components/modal';
import Button from 'components/button';
import { ConfirmButton } from 'components/buttons';

import type { ErrorMessage } from 'components/error-box';

type OpenConfirmation = () => void;
type CloseConfirmation = () => void;

type Props = {
  header: ReactNode,
  body: ReactNode,
  form?: (args: { closeConfirmation: CloseConfirmation }) => void | ReactNode,
  actions?: (args: { closeConfirmation: CloseConfirmation }) => void | ReactNode,
  errorActions?: (args: { closeConfirmation: CloseConfirmation }) => void | ReactNode,
  success?: boolean,
  // TODO: Figure out what type this should be
  error?: any,
  customErrorMessage?: ErrorMessage,
  isLoading?: boolean,
  tooltip?: string,
  onOpen?: () => void,
  isOpen?: boolean,
  onClose?: () => void,
  onCancel?: () => void,
  children?: ((onClick?: OpenConfirmation) => ReactNode) | ReactNode,
  onEnter: (closeConfirmation: CloseConfirmation) => void,
  isWideModal?: boolean,
  portalClassName?: string,
  preventClose?: boolean,
  isConfirmLoading?: boolean,
  onConfirm?: () => void,
  trackable?: string | ComponentProps<typeof Button>['trackable'],
  isDirty?: boolean,
  disabled?: boolean,
  hideFooter?: boolean,
  customConfirmButtonClass?: string,
  modalKey: string,
  customModalClass?: string,
  customBodyClass?: string,
  footer?: ReactNode,
  shouldRenderStaticScrollbar?: boolean,
  showSuccessMessage?: boolean,
  centeredActions?: boolean,
  actionClasses?: string,
};

type State = {
  confirmationOpen: boolean,
};

class Confirmable extends React.Component<Props, State> {
  static defaultProps = {
    actions: undefined,
    success: false,
    error: undefined,
    tooltip: undefined,
    onOpen: undefined,
    isOpen: undefined,
    onClose: undefined,
    isLoading: undefined,
    children: undefined,
    errorActions: undefined,
    customErrorMessage: undefined,
    isWideModal: undefined,
    portalClassName: undefined,
    preventClose: undefined,
    isConfirmLoading: undefined,
    onConfirm: undefined,
    trackable: undefined,
    isDirty: false,
    disabled: undefined,
    hideFooter: undefined,
    customConfirmButtonClass: undefined,
    customModalClass: undefined,
    customBodyClass: undefined,
    showSuccessMessage: true,
  }

  state = {
    confirmationOpen: false,
  }

  successTimeout: NodeJS.Timer | undefined;

  modalRef: HTMLDivElement | undefined;

  componentDidMount() {
    if (this.props.isOpen) {
      setTimeout(() => {
        this.openConfirmation();
      }, 0);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isOpen } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.openConfirmation();
      return;
    }

    if (!isOpen && prevProps.isOpen) {
      this.closeConfirmation();
      return;
    }

    if (this.shouldCloseOnSuccess(prevProps)) {
      this.closeConfirmationWithTimeout();
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  shouldCloseOnSuccess = (prevProps: Props) => {
    const { success } = this.props;
    const { confirmationOpen } = this.state;

    if (!confirmationOpen) {
      return false;
    }

    return success && !prevProps.success;
  };

  handleEnter = () => {
    const { onEnter, disabled } = this.props;
    if (disabled) return;
    onEnter(this.closeConfirmationWithTimeout);
  }

  closeConfirmation = () => {
    const { onClose } = this.props;

    this.setState({
      confirmationOpen: false,
    });

    this.clearTimeout();

    if (onClose) {
      onClose();
    }
  }

  cancelConfirmation = () => {
    const { onCancel } = this.props;

    this.closeConfirmation();

    if (onCancel) {
      onCancel();
    }
  }

  closeConfirmationWithTimeout = () => {
    const { success, showSuccessMessage } = this.props;

    if (success && showSuccessMessage) {
      this.successTimeout = setTimeout(() => {
        this.closeConfirmation();
      }, 1500);

      setTimeout(() => {
        if (this.modalRef) {
          this.modalRef.focus();
        }
      }, 0);

      return this.successTimeout;
    }

    return this.closeConfirmation();
  }

  openConfirmation = () => {
    const { onOpen } = this.props;

    this.setState({
      confirmationOpen: true,
    });

    if (onOpen) {
      onOpen();
    }
  };

  clearTimeout = () => {
    if (this.successTimeout) {
      clearTimeout(this.successTimeout);
      this.successTimeout = undefined;
    }
  };

  handleRef = (ref: HTMLDivElement) => {
    this.modalRef = ref;
  };

  renderActions = () => {
    const {
      error,
      actions,
      errorActions,
      isConfirmLoading,
      onConfirm,
      disabled,
      customConfirmButtonClass,
    } = this.props;

    if (error && errorActions) {
      return errorActions({ closeConfirmation: this.closeConfirmationWithTimeout });
    }

    if (actions) {
      return actions({ closeConfirmation: this.closeConfirmationWithTimeout });
    }

    return (
      <ConfirmButton
        isLoading={isConfirmLoading}
        onClick={onConfirm}
        disabled={disabled}
        customClass={customConfirmButtonClass}
      />
    );
  };

  renderChildren() {
    const {
      header,
      body,
      form,
      actions,
      errorActions,
      tooltip,
      isLoading,
      success,
      isDirty,
      error,
      customErrorMessage,
      onOpen,
      children,
      isOpen,
      onClose,
      onEnter,
      isWideModal,
      portalClassName,
      preventClose,
      isConfirmLoading,
      onConfirm,
      trackable,
      hideFooter,
      customConfirmButtonClass,
      modalKey,
      customBodyClass,
      customModalClass,
      shouldRenderStaticScrollbar,
      showSuccessMessage,
      centeredActions,
      actionClasses,
      ...buttonProps
    } = this.props;

    if (isFunction(children)) {
      return children(this.openConfirmation);
    }

    return (
      <Button
        title={tooltip}
        {...buttonProps}
        onClick={this.openConfirmation}
        trackable={trackable}
      >
        {ReactIs.isElement(children) ? children : null}
      </Button>
    );
  }

  render() {
    const { confirmationOpen } = this.state;
    const {
      header,
      body,
      form,
      error,
      customErrorMessage,
      isLoading,
      isWideModal,
      portalClassName,
      preventClose,
      success,
      isDirty,
      hideFooter,
      modalKey,
      footer,
      customModalClass,
      customBodyClass,
      shouldRenderStaticScrollbar,
      showSuccessMessage,
      centeredActions,
      actionClasses,
    } = this.props;

    // Modal component's children filters doesn't allow us to extraxt this as a
    // render method
    if (form) {
      return (
        <>
          <Modal
            header={header}
            isOpen={confirmationOpen}
            onCancel={this.cancelConfirmation}
            error={error}
            customErrorMessage={customErrorMessage}
            success={success}
            onEnter={this.handleEnter}
            isWideModal={isWideModal}
            customModalClass={customModalClass}
            customBodyClass={customBodyClass}
            portalClassName={portalClassName}
            preventClose={preventClose}
            shouldCloseOnOverlayClick={!preventClose && !isDirty}
            shouldCloseOnEsc={!preventClose}
            onAfterClose={this.clearTimeout}
            contentRef={this.handleRef}
            modalKey={modalKey}
            hideFooter
            footer={footer}
            shouldRenderStaticScrollbar={shouldRenderStaticScrollbar}
            showSuccessMessage={showSuccessMessage}
            centeredActions={centeredActions}
            actionClasses={actionClasses}
          >
            <Modal.Body isLoading={isLoading}>
              {form({ closeConfirmation: this.closeConfirmationWithTimeout })}
            </Modal.Body>
          </Modal>
          {this.renderChildren()}
        </>
      );
    }

    return (
      <>
        <Modal
          header={header}
          isOpen={confirmationOpen}
          onCancel={this.cancelConfirmation}
          error={error}
          customErrorMessage={customErrorMessage}
          success={success}
          onEnter={this.handleEnter}
          isWideModal={isWideModal}
          customModalClass={customModalClass}
          customBodyClass={customBodyClass}
          portalClassName={portalClassName}
          preventClose={preventClose}
          shouldCloseOnOverlayClick={!preventClose && !isDirty}
          shouldCloseOnEsc={!preventClose}
          onAfterClose={this.clearTimeout}
          contentRef={this.handleRef}
          hideFooter={hideFooter}
          modalKey={modalKey}
          footer={footer}
          shouldRenderStaticScrollbar={shouldRenderStaticScrollbar}
          showSuccessMessage={showSuccessMessage}
          centeredActions={centeredActions}
          actionClasses={actionClasses}
        >
          <Modal.Body isLoading={isLoading}>
            {body}
          </Modal.Body>
          <Modal.Actions>
            {this.renderActions()}
          </Modal.Actions>
        </Modal>
        {this.renderChildren()}
      </>
    );
  }
}

export default Confirmable;
