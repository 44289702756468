// @flow

import { decamelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';

import {
  getMultiple,
  post,
  put,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

export const webhookSchema = new schema.Entity('webhooks');

export type NormalizedWebhooks = {
  entities: {
    webhooks: {
      [number]: Webhook,
    },
  },
  result: number | Array<number>,
  count: number,
};

type WebhookNormalizer = (any) => NormalizedWebhooks;

const normalizeWebhooks: WebhookNormalizer = (webhooks) => ({
  ...normalize(webhooks.collection, [webhookSchema]),
  count: webhooks.count,
});

const normalizeWebhook: WebhookNormalizer = (webhook) => ({
  ...normalize(webhook, webhookSchema),
  count: 1,
});

type GetWebhooks = ({
  params?: {},
}) => Promise<NormalizedWebhooks>;

export const getWebhooks: GetWebhooks = ({ params }) => (
  getMultiple({
    url: '/ext/webhooks/',
    params,
    pagination: {
      limit: undefined,
      offset: undefined,
    },
  }).then(extractResponseBodyAsJSON)
    .then(normalizeWebhooks)
);

type CreateWebhook = ({
  signKey?: string,
  imwProxy: boolean,
  url: string,
}) => Promise<NormalizedWebhooks>;

export const createWebhook: CreateWebhook = ({
  signKey,
  imwProxy,
  url,
}) => (
  post({
    url: '/ext/webhooks/',
    body: decamelizeKeys({
      url,
      imwProxy,
      signKey,
    }, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
    .then(normalizeWebhook)
);

type RemoveWebhook = ({
  id: number,
}) => Promise<{}>;

export const removeWebhook: RemoveWebhook = ({ id }) => (
  remove({
    url: `/ext/webhooks/${id}`,
  }).then(extractResponseBodyAsJSON)
);

type UpdateWebhook = ({
  id: number,
  signKey?: string,
  url: string,
  imwProxy: boolean,
}) => Promise<NormalizedWebhooks>;

export const updateWebhook: UpdateWebhook = ({
  id,
  signKey = null,
  url,
  imwProxy,
}) => (
  put({
    url: `/ext/webhooks/${id}`,
    body: decamelizeKeys({
      url,
      signKey,
      imwProxy,
    }, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
    .then(normalizeWebhook)
);

type UrlCheck = ({
  url: string,
}) => Promise<{
  responseStatusCode: number,
}>;

export const urlCheck: UrlCheck = ({ url }) => (
  post({
    url: '/ext/webhooks/url_check',
    body: {
      url,
    },
  }).then(extractResponseBodyAsJSON)
);
