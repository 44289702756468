// @flow

import {
  KEY_SALESFORCE,
} from 'extensions';

const Salesforce = {
  key: KEY_SALESFORCE,
  typeSpecificConfigKeys: [
    'instanceUrl',
  ],
};

export default Salesforce;
