import { useSelector } from 'react-redux';

import { isContractPristine, getHasNonPdfSections } from 'reducers/current-contract';
import { getAccountFromSessionSelector, getPositionFromSessionSelector } from 'reducers/session';
import { canBePublishedByMyParticipant } from 'agreement';
import { getAgreementMyParticipant, getEnabledParticipants } from 'agreement/selectors';
import useAgreement from 'hooks/use-agreement';
import {
  anApproverIsWithoutSignatoryAfter,
  hasSignatoriesInDocument,
  hasPendingStateApprovers,
} from 'agreement/pending-state-flow';

import type { Layout } from 'components/document-layout-container/types';
import { checkAcl } from 'components/acl';
import { isContractEditable } from 'components/document-layout-container/helpers';
import { isUserLimited } from 'user';
import { ApproverLastAction } from 'components/document-call-to-actions/actions/buttons-call-to-action/actions/approver-last-action';
import { DiscardSaveSendActions } from './buttons-call-to-action';
import { SendDocumentButton } from './buttons-call-to-action/buttons';

type Props = {
  agreementId: Oneflow.Agreement['id'],
  layout: Layout
}

const DraftActions = ({ agreementId, layout }: Props) => {
  const agreement = useAgreement(agreementId);
  const myParticipant = getAgreementMyParticipant(agreement);
  const participants = getEnabledParticipants(agreement);
  const account: Oneflow.Account = useSelector(getAccountFromSessionSelector);
  const myPosition = useSelector(getPositionFromSessionSelector);
  const userIsLimited = isUserLimited(myPosition);

  const hasNonPdfSections = useSelector(getHasNonPdfSections) ?? false;
  const hasPermissionToPublish = checkAcl(agreement.acl, 'agreement:publish');
  const hasPermissionToPublishSigningOrderDocument = checkAcl(
    agreement.acl, 'agreement:signing_order',
  ) && checkAcl(
    agreement.acl, 'agreement:publish',
  );

  const isFreemiumWithNonPdfSections = account.isFreemium && hasNonPdfSections;
  const isPristine = useSelector(isContractPristine);
  const isEditable = isContractEditable(agreement);

  const canPublish = canBePublishedByMyParticipant(
    agreement,
    myParticipant,
    participants,
    isFreemiumWithNonPdfSections,
    hasPermissionToPublish,
  );

  const showPublishSigningOrderErrorToast = agreement.config?.signOrder
    && !hasPermissionToPublishSigningOrderDocument;

  if (userIsLimited) {
    return null;
  }

  if (!isPristine && isEditable) {
    return (
      <DiscardSaveSendActions agreementId={agreementId} layout={layout} />
    );
  }

  if (hasPendingStateApprovers(agreement)
    && anApproverIsWithoutSignatoryAfter(agreement)
    && hasSignatoriesInDocument(agreement)
    && !showPublishSigningOrderErrorToast
    && participants.length > 1) {
    return (
      <ApproverLastAction
        agreement={agreement}
        hasPermissionToPublish={hasPermissionToPublish}
      />
    );
  }

  return (
    <SendDocumentButton
      agreementId={agreementId}
      canPublish={canPublish}
      layout={layout}
    />
  );
};

export default DraftActions;
