// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getWebhooks,
  createWebhook,
  updateWebhook,
  removeWebhook,
  urlCheck,
} from 'oneflow-client/extensions/webhooks';
import type { NormalizedWebhooks } from 'oneflow-client/extensions/webhooks';

import apiWrapper from 'sagas/api-wrapper';

import webhooks from 'reducers/entities/webhooks';

type MapperArgs = {
  data: NormalizedWebhooks,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(webhooks.setWebhooks(data.entities.webhooks));
}

type ClearMapperArgs = {
  data: {||},
  action: {
    id: number,
    type: string,
  },
};
export function* clearMapper({ action }: ClearMapperArgs): Mapper {
  yield put(webhooks.clearWebhook({ id: action.id }));
}

type UrlCheckMapperArgs = {
  data: {
    responseStatusCode: number,
  },
  action: {
    id: number,
    type: string,
  },
};
export function* urlCheckMapper({ action, data }: UrlCheckMapperArgs): Mapper {
  yield put(webhooks.setWebhooks({
    [action.id]: {
      responseStatusCode: data.responseStatusCode,
    },
  }));
}

const mappers = {
  query: {
    mapper,
    request: getWebhooks,
  },
  create: {
    mapper,
    request: createWebhook,
  },
  update: {
    mapper,
    request: updateWebhook,
  },
  remove: {
    mapper: clearMapper,
    request: removeWebhook,
  },
  rpcs: {
    urlCheck: {
      mapper: urlCheckMapper,
      name: 'urlCheck',
      request: urlCheck,
    },
  },
};

const webhooksSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: webhooks,
  mappers,
});

export default webhooksSaga;
