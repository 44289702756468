// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import type { PresetType } from './date-preset';

export const getNextMessage = (next: number, presetType: PresetType) => {
  switch (presetType) {
    case 'day':
      return (
        <Message
          id="Today"
          pluralId="Next {days} days"
          pluralCondition="days"
          values={{
            days: next,
          }}
          comment="Date range preset for the next days in date filter where the range includes today"
          context="Upcoming days including today"
        />
      );

    case 'week':
      return (
        <Message
          id="Next week"
          pluralId="Next {weeks} weeks"
          pluralCondition="weeks"
          values={{
            weeks: next,
          }}
          comment="Date range preset for the next weeks in date filter where the range includes today"
        />
      );

    case 'month':
      return (
        <Message
          id="Next month"
          pluralId="Next {months} months"
          pluralCondition="months"
          values={{
            months: next,
          }}
          comment="Date range preset for the next months in date filter where the range includes today"
        />
      );

    case 'year':
      return (
        <Message
          id="Next year"
          pluralId="Next {years} years"
          pluralCondition="years"
          values={{
            years: next,
          }}
          comment="Date range preset for the next years in date filter where the range includes today"
        />
      );

    default:
      return null;
  }
};

export const getLastMessage = (last: number, presetType: PresetType) => {
  switch (presetType) {
    case 'day':
      return (
        <Message
          id="Today"
          pluralId="Last {days} days"
          pluralCondition="days"
          values={{
            days: last,
          }}
          comment="Date range preset for the last days in date filter where the range includes today"
          context="Past days including today"
        />
      );

    case 'week':
      return (
        <Message
          id="Last week"
          pluralId="Last {weeks} weeks"
          pluralCondition="weeks"
          values={{
            weeks: last,
          }}
          comment="Date range preset for the last weeks in date filter where the range includes today"
        />
      );

    case 'month':
      return (
        <Message
          id="Last month"
          pluralId="Last {months} months"
          pluralCondition="months"
          values={{
            months: last,
          }}
          comment="Date range preset for the last months in date filter where the range includes today"
        />
      );

    case 'year':
      return (
        <Message
          id="Last year"
          pluralId="Last {years} years"
          pluralCondition="years"
          values={{
            years: last,
          }}
          comment="Date range preset for the last years in date filter where the range includes today"
        />
      );

    default:
      return null;
  }
};

export const getPreviousMessage = (previous: number, presetType: PresetType) => {
  switch (presetType) {
    case 'day':
      return (
        <Message
          id="Yesterday"
          pluralId="Previous {days} days"
          pluralCondition="days"
          values={{
            days: previous,
          }}
          comment="Date range preset for the previous days in date filter"
        />
      );

    case 'week':
      return (
        <Message
          id="Previous week"
          pluralId="Previous {weeks} weeks"
          pluralCondition="weeks"
          values={{
            weeks: previous,
          }}
          comment="Date range preset for the previous weeks in date filter"
        />
      );

    case 'month':
      return (
        <Message
          id="Previous month"
          pluralId="Previous {months} months"
          pluralCondition="months"
          values={{
            months: previous,
          }}
          comment="Date range preset for the previous months in date filter"
        />
      );

    case 'year':
      return (
        <Message
          id="Previous year"
          pluralId="Previous {years} years"
          pluralCondition="years"
          values={{
            years: previous,
          }}
          comment="Date range preset for the previous years in date filter"
        />
      );

    default:
      return null;
  }
};
