// @flow

import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { type MessageTranslator } from '@oneflowab/pomes';
import { take } from 'lodash';
import { formatDateString } from 'date';
import positionsReducer from 'reducers/entities/positions';

import { useUserEvents } from 'contexts/user-events';

import Button from 'components/button';
import ParticipantBubble, { SimpleBubble } from 'components/contract-card/participant-bubble';
import Tooltip from 'components/tooltip';

import EditIcon from 'components/icons/edit';
import ViewCountEyeIcon from 'components/icons/view-count-eye';
import EmailIcon from 'components/icons/email';
import PhoneIcon from 'components/icons/phone';
import BellIcon from 'components/icons/bell';

import getCSSPropertyValue from 'utils/get-css-property-value';
import isPastEvent from 'utils/is-past-event';

import style from './mini-internal-reminder-card.module.scss';

type MiniInternalReminderCardProps = {
  agreement: Agreement,
  contractInternalReminderEvent: {
    id: number,
    agreement?: Agreement,
    created: string,
    createdTs: number,
    customReminderData: {
      createdBy: number,
      recipients: Array<number>,
      message: string,
      subject: string,
    },
    date: string,
    executed: number,
    isEditable: boolean,
    updated?: string,
    updatedTs?: number,
    type?: string,
  },
  message: MessageTranslator,
};

const MiniInternalReminderCard = ({
  contractInternalReminderEvent: reminderEvent,
  message,
  agreement,
}: MiniInternalReminderCardProps) => {
  const MAX_VISIBLE_RECIPIENTS = 5;
  const NUMBER_OF_RECIPIENTS_WHEN_LIMITED = MAX_VISIBLE_RECIPIENTS - 1;

  let contractInternalReminderEvent = reminderEvent;

  // delayed events for a specific agreement does not include the agreement object in response
  if (!contractInternalReminderEvent?.agreement && agreement) {
    contractInternalReminderEvent = {
      ...reminderEvent,
      agreement,
    };
  }

  const allRecipients = useSelector(
    (state) => positionsReducer.getPositionsSelector(state, {
      ids: contractInternalReminderEvent.customReminderData.recipients,
    }),
  );

  const hasPassed = isPastEvent(contractInternalReminderEvent.date);
  const userBadgeBorderColor = hasPassed
    ? getCSSPropertyValue('--badge-border-color-past')
    : getCSSPropertyValue('--badge-border-color-upcoming');

  const { dateFormat, openModal, setSelectedEvent } = useUserEvents();

  const formattedDate = formatDateString(contractInternalReminderEvent.date, dateFormat);

  const reminderTense = hasPassed ? message({
    id: 'Sent',
    comment: 'Label for notifying a contract reminder event was sent on a date',
  }) : message({
    id: 'Will be sent on',
    comment: 'Label for notifying a contract reminder event will be sent for upcoming date',
  });

  const insightTextWithDate = `${reminderTense} ${formattedDate}`;

  const recipientBubbles = allRecipients.length > MAX_VISIBLE_RECIPIENTS
    ? take(allRecipients, NUMBER_OF_RECIPIENTS_WHEN_LIMITED)
    : take(allRecipients, MAX_VISIBLE_RECIPIENTS);

  const reminderInformationClasses = clsx(style.MiniInternalReminderCardInformation, {
    [style.HasPassed]: hasPassed,
  });

  const recipientBubbleClasses = clsx(style.MiniInternalReminderCardRecipientBubble, {
    [style.HasPassed]: hasPassed,
  });

  // eslint-disable-next-line no-nested-ternary
  const actionIcon = hasPassed
    ? <ViewCountEyeIcon height="16px" />
    : contractInternalReminderEvent?.isEditable
      ? <EditIcon height="16px" />
      : <ViewCountEyeIcon height="16px" />;

  const renderTooltipMessage = (participant) => (
    <>
      <div className={style.TooltipMessageParticipantName}>
        {participant.fullname || participant.name}
      </div>
      {participant.email && (
        <div className={style.TooltipMessageParticipantEmail}>
          <EmailIcon height="14px" />
          <span>
            {participant.email}
          </span>
        </div>
      )}
      {participant.phoneNumber && (
        <div className={style.TooltipMessageParticipantPhoneNumber}>
          <PhoneIcon height="14px" />
          <span>
            {participant.phoneNumber}
          </span>
        </div>
      )}
    </>
  );

  return (
    <div className={style.MiniInternalReminderCard}>
      <div className={reminderInformationClasses}>
        <div className={style.MiniInternalReminderCardSubjectRow}>
          {contractInternalReminderEvent.customReminderData.subject}
        </div>
        <div className={style.MiniInternalReminderCardInsight}>
          <BellIcon height="12px" />
          {insightTextWithDate}
        </div>
      </div>
      <div className={style.MiniInternalReminderCardRecipients}>
        {recipientBubbles.map((participant) => (
          <Tooltip
            message={renderTooltipMessage(participant)}
            side="top"
            key={participant.id}
            triggerClassName={style.MiniInternalReminderCardBubbleTooltip}
            messageClassName={style.MiniInternalReminderCardBubbleTooltipMessage}
          >
            <span>
              <ParticipantBubble
                key={participant.id}
                className={recipientBubbleClasses}
                isContractMarkedAsSigned={false}
                participant={participant}
                borderColor={userBadgeBorderColor}
                userBadgeDiameter={32}
                userBadgeBorderSize={1}
              />
            </span>
          </Tooltip>
        ))}
        {Boolean(allRecipients.length - recipientBubbles.length) && (
          <div className={style.MiniInternalReminderCardBubbleTooltip}>
            <SimpleBubble
              label={`+${allRecipients.length - recipientBubbles.length}`}
              borderColor={userBadgeBorderColor}
              diameter={32}
              borderSize={1}
              className={recipientBubbleClasses}
            />
          </div>
        )}
      </div>
      <div className={style.MiniInternalReminderCardActions}>
        <Button
          icon={actionIcon}
          onClick={() => {
            setSelectedEvent(contractInternalReminderEvent);
            openModal();
          }}
        />
      </div>
    </div>
  );
};

export default MiniInternalReminderCard;
