// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

const subdomainPattern = /^[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?$/i;

type SubdomainValidator = ({
  message: MessageTranslator,
}) => Validator;

const subdomainValidator: SubdomainValidator = ({ message }) => (value: string) => {
  if (value === undefined || subdomainPattern.test(value)) {
    return undefined;
  }

  return message({
    id: 'Invalid subdomain',
    comment: 'Validation message for subdomain validator.',
  });
};

export default subdomainValidator;
