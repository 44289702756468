// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';
import { FormSpy, type FormRenderProps } from 'react-final-form';
import { isEmpty, get } from 'lodash';
import { decamelize } from 'humps';

import Field from 'components/field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import ModalForm from 'hocs/modal-form';
import Eye from 'components/icons/eye';
import Button from 'components/button';
import { checkAcl } from 'components/acl';

import style from './rules.module.scss';

const CHECKED_VALUE = 'is_checked';
const UNCHECKED_VALUE = 'is_unchecked';

export type FormData = {
  dataField: Option | string,
  condition: Option | string,
  value: ?string,
};

type InitialValues = {
  condition: string,
  dataField: string,
  value: string,
};
export type Props = {
  message: MessageTranslator,
  onSubmit: FormData => void,
  children?: Function => React.Node,
  dataFields: Array<Option>,
  initialValues: InitialValues,
  resetRule: () => void,
  onClose: () => void,
  box: Box,
  agreement: Agreement,
};

type State = {
  isOpen: boolean,
};

const conditions = (message) => [
  {
    label: message({
      id: 'Is equal to',
      comment: 'Label for option in condition dropdown when configuring section rules',
    }),
    value: '==',
  },
  {
    label: message({
      id: 'Is not equal to',
      comment: 'Label for option in condition dropdown when configuring section rules',
    }),
    value: '!=',
  },
  {
    label: message({
      id: 'Is checked',
      comment: 'Label for option in condition dropdown when configuring section rules',
    }),
    value: CHECKED_VALUE,
  },
  {
    label: message({
      id: 'Is unchecked',
      comment: 'Label for option in condition dropdown when configuring section rules',
    }),
    value: UNCHECKED_VALUE,
  },
];

export class RulesComponent extends React.Component<Props, State> {
  static defaultProps = {
    children: undefined,
  };

  state = {
    isOpen: true,
  };

  hasCheckboxCondition = (condition: string) => Boolean(
    condition?.value === CHECKED_VALUE
    || condition?.value === UNCHECKED_VALUE
    || condition === CHECKED_VALUE
    || condition === UNCHECKED_VALUE,
  );

  handleOnClick = ({ handleSubmit, values }) => () => {
    handleSubmit(values);

    this.setState({ isOpen: false });
  }

  handleResetRule = () => {
    const { resetRule } = this.props;

    resetRule();
    this.setState({ isOpen: false });
  };

  handleOnSubmit = (values: FormData) => {
    const { onSubmit } = this.props;
    const condition = get(values.condition, 'value') || values.condition;
    const dataField = get(values.dataField, 'value') || values.dataField;
    let { value } = values;

    if (this.hasCheckboxCondition(condition)) {
      value = undefined;
    }

    onSubmit({
      condition,
      dataField,
      value,
    });
  };

  getActions = ({ formProps }: FormRenderProps<*>) => {
    const { box, agreement } = this.props;
    const isDisabled = box.id ? !checkAcl(box.acl, 'agreementbox:config:section_rules:update') : !checkAcl(agreement.acl, 'agreement:box:section_rule_management');
    return (
      <div className={style.Actions}>
        {this.renderResetButton()}
        <Button
          kind="primary"
          onClick={this.handleOnClick(formProps)}
          disabled={isDisabled || formProps.pristine || !formProps.valid}
        >
          <Message
            id="Save rule"
            comment="Button text for saving a rule"
          />
        </Button>
      </div>
    );
  };

  renderResetButton = () => {
    const { initialValues, box, agreement } = this.props;
    const isDisabled = box.id ? !checkAcl(box.acl, 'agreementbox:config:section_rules:remove') : !checkAcl(agreement.acl, 'agreement:box:section_rule_management');

    if (isEmpty(initialValues)) {
      return null;
    }

    return (
      <Button
        kind="linkSeparate"
        onClick={this.handleResetRule}
        disabled={isDisabled}
      >
        <Message
          id="Reset rule"
          comment="Button text for resetting a rule"
        />
      </Button>
    );
  };

  renderValue = ({ values }) => {
    const { message } = this.props;
    const { condition } = values;

    if (this.hasCheckboxCondition(condition)) {
      return null;
    }

    return (
      <Field
        name="value"
        component={TextField}
        placeholder={message({
          id: 'Value',
          comment: 'Placeholder',
        })}
        required
        label="Value"
        hideLabel
      />
    );
  };

  renderBody() {
    const { message, dataFields } = this.props;

    return (
      <div className={style.Container}>
        <p className={style.Heading}>
          <Message
            id="Hide or show sections for counterparties"
            comment="Header text explaining what a user can do"
          />
        </p>
        <p>
          <Message
            id="You can set rules for this section by choosing parameters in the fields below. A section can either be shown or hidden depending on the rules set."
            comment="Field label for data field"
          />
        </p>
        <div className={style.Label}>
          <Eye className={style.EyeIcon} />
          <p>
            <Message
              id="Only show this section if:"
              comment="Label for choosing parameters in data fields"
            />
          </p>
        </div>
        <div className={style.Row}>
          <div className={style.Field}>
            <Field
              name="dataField"
              component={SelectField}
              placeholder={message({
                id: 'Field',
                comment: 'Placeholder',
              })}
              options={dataFields}
              searchable
              required
              label="Data field"
              hideLabel
            />
          </div>
          <div className={style.Condition}>
            <Field
              name="condition"
              component={SelectField}
              placeholder={message({
                id: 'Condition',
                comment: 'Placeholder',
              })}
              options={conditions(message)}
              searchable={false}
              required
              label="Condition"
              hideLabel
            />
          </div>
          <FormSpy subscription={{ values: true }}>
            {this.renderValue}
          </FormSpy>
        </div>
      </div>
    );
  }

  render() {
    const { children, initialValues, onClose } = this.props;
    const decamelizedInitialValues = {
      ...initialValues,
      condition: initialValues.condition
        ? decamelize(initialValues.condition)
        : undefined,
    };
    const { isOpen } = this.state;

    return (
      <ModalForm
        title={(
          <Message
            id="Rules"
            comment="The title of the rules modal"
          />
        )}
        body={this.renderBody()}
        onSubmit={this.handleOnSubmit}
        resetFormState={() => {}}
        formState={{}}
        actions={this.getActions}
        isOpen={isOpen}
        initialValues={decamelizedInitialValues}
        modalKey="add section rules modal"
        onClose={onClose}
      >
        {children}
      </ModalForm>
    );
  }
}

export default localize <Props>(RulesComponent);
