/* eslint-disable import/named */
import { normalize, schema } from 'normalizr';
import { decamelizeKeys } from 'humps';

import {
  extractResponseBodyAsJSON,
  getMultiple,
} from 'oneflow-client/core';

type BindingsData = {
  collection: Oneflow.AccountGroupRoleBinding[],
  count: number,
};

type GetAccountGroupBindings = {
  pagination: {
    limit: number,
    offset: number,
  }
  params: {
    actorType: string,
  }
  sort: string[],
};

const accountGroupsBindingsSchema = new schema.Entity('accountGroupsBindings');

const normalizeAccountGroups = (bindingsData: BindingsData) => ({
  ...normalize(bindingsData.collection, [accountGroupsBindingsSchema]),
  count: bindingsData.count,
});

const getAccountGroupBindings = ({
  pagination,
  params,
  sort,
}: GetAccountGroupBindings) => (
  getMultiple({
    url: '/accounts/bindings/',
    params: decamelizeKeys(params, { separator: '_' }),
    pagination,
    sort,
  }).then(extractResponseBodyAsJSON)
    .then(normalizeAccountGroups)
);

export default getAccountGroupBindings;
