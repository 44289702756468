// @flow

import { type MessageTranslator } from '@oneflowab/pomes';
import { getRef } from 'agreement/event';
import { getFormattedAttribute, getFormattedValue } from '../helpers';

export const companyAdd = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const name = getRef(event, 'company_name');
  return {
    text: message({
      id: '{company-name} was added.',
      values: { 'company-name': name },
      comment: 'Audit trail message',
    }),
  };
};

export const companyUpdate = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const name = getRef(event, 'company_name_old') || getRef(event, 'company_name');
  return {
    text: message({
      id: '{company-name} was updated.',
      values: { 'company-name': name },
      comment: 'Audit trail message',
    }),
  };
};

export const companyUpdateItem = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const key = getRef(event, 'key');
  const attribute = getFormattedAttribute(key, 'company');
  let to = getRef(event, 'to');
  let from = getRef(event, 'from');

  if (key === 'company_country') {
    to = getFormattedValue('country', to, message);
    from = getFormattedValue('country', from, message);
  }
  if (from) {
    if (!to) {
      to = `<i>${message({
        id: 'empty',
        comment: 'Audit trail partial message',
      })}</i>`;
    }
    return {
      text: message({
        id: '{attribute-name} was changed from {from-value} to {to-value}.',
        values: {
          'attribute-name': attribute,
          'from-value': from,
          'to-value': to,
        },
        comment: 'Audit trail message',
      }),
    };
  }
  return {
    text: message({
      id: '{attribute-name} was set to {to-value}.',
      values: {
        'attribute-name': attribute,
        'to-value': to,
      },
      comment: 'Audit trail message',
    }),
  };
};

export const companyDisable = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const name = getRef(event, 'company_name');
  return {
    text: message({
      id: '{company-name} was removed.',
      values: { 'company-name': name },
      comment: 'Audit trail message',
    }),

  };
};
