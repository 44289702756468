// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import useWindowSize from 'hooks/use-window-size';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';

import { hasPermissionToAddAnyParticipants, hasSignOrder } from 'agreement/agreement';
import { isAgreementOwner, getAgreementMyParticipant, isPartiallySigned } from 'agreement/selectors';

import { allowAddColleague, allowAddParticipant } from 'agreement/party';
import {
  isEditable as isEditableParticipant,
  canEditAnyParticipantDetail,
} from 'agreement/participant';
import isOrganizer from 'agreement/participant/is-organizer';
import isDisabled from 'agreement/participant/is-disabled';
import isInternalApprover from 'agreement/participant/is-internal-approver';
import { isImportInProgress, isTemplate } from 'agreement';
import { getParticipantSignOrderBlockIndex } from 'agreement/pending-state-flow';
import sessionStorage from 'utils/session-storage';

import { DOCUMENT_COLLAPSED_LAYOUT_SIZE } from 'components/document-layout-container/helpers';
import Participant from 'components/participant';
import EditCompany from 'components/modals/edit-counterparty';
import { AddParticipantButton } from 'components/buttons';
import Country from 'components/countries';
import Pencil from 'components/icons/pencil';
import Lock from 'components/icons/lock';
import OrganizationIcon from 'components/icons/organization';
import NetworkPinIcon from 'components/icons/network-pin';
import Tooltip from 'components/tooltip';
import { checkAcl } from 'components/acl';
import AddCounterparty from 'components/modals/add-counterparty';
import AddColleague from 'components/modals/add-colleague';
import AddFormerColleague from 'components/modals/add-colleague/add-former-colleague';
import ResetApprovalsWarning from 'components/modals/reset-approvals-participant';
import ResetSignaturesWarning from 'components/modals/reset-signatures-participant';
import ResetApprovalsSignaturesWarning from 'components/modals/reset-approvals-signatures-participant';
import InfoIcon from 'components/icons/info';
import toast from 'components/toasts';
import { ImportSparkle } from 'components/import-sparkle/import-sparkle';
import style from './party.module.scss';

export type Props = {|
  agreement: Agreement,
  position: Position,
  party: AgreementParty,
  lang: EnabledLanguages,
  isEditable: boolean,
  isOwner: boolean,
  dateFormat: string,
  account: Account,
  updatingSignOrder: Boolean,
  guestToken?: string,
  isFirstCounterParty?: boolean,
|};

const Party = ({
  agreement,
  position,
  party,
  lang,
  isEditable,
  isOwner,
  dateFormat,
  account,
  updatingSignOrder,
  guestToken,
  isFirstCounterParty = false,
}: Props) => {
  const [resetApprovalsWarning, setResetApprovalsWarning] = React.useState(null);
  const [resetSignaturesWarning, setResetSignaturesWarning] = React.useState(null);
  const [
    resetApprovalsSignaturesWarning,
    setResetApprovalsSignaturesWarning,
  ] = React.useState(null);
  const windowSize = useWindowSize();
  const isInPreviewMode = useIsInPreviewMode();
  const { participants } = party;
  const approversList = participants.filter(
    (participant) => !isDisabled(participant) && isInternalApprover(participant) && (
      !isOrganizer(participant) || isAgreementOwner(account, agreement)
    ),
  );
  const myParticipant = getAgreementMyParticipant(agreement);
  const myBlockIndex = getParticipantSignOrderBlockIndex(agreement, myParticipant?.id);
  const guestsCanAddParticipants = hasSignOrder(agreement) ? !isOwner
    && myBlockIndex === agreement.pendingStateFlow?.runningBlock?.config.blockIndex : true;

  const hasAccessToInternalApproval = agreement
    ? checkAcl(agreement.acl, 'agreement:draft_approval_flow:update') : false;

  const isOwnerParty = isOwner && party.self;

  const colleaguesList = participants.filter(
    (participant) => !isDisabled(participant) && !isInternalApprover(participant) && (
      !isOrganizer(participant) || isAgreementOwner(account, agreement)
    ),
  );

  const enabledParticipantsWithoutApprovers = participants.filter(
    (participant) => !isDisabled(participant) && (
      !isOrganizer(participant) || isAgreementOwner(account, agreement)
    ),
  );

  const PartyClasses = clsx(style.Party, {
    [style.BottomMargin]: !isOwner,
  });

  const hasAddButton = (allowAddParticipant(account, agreement, party)
    && !party.self)
    || (allowAddColleague(account, agreement, party) && party.self
      && guestsCanAddParticipants);

  const PartyContainerClasses = clsx(style.Container, {
    [style.BorderTopRounded]: !party.individual && hasAddButton,
    [style.BottomMargin]: party.individual,
  });

  const canCounterpartSelfUpdateAndRemoveInformation = () => {
    const { config } = agreement;
    const canCounterpartSelfUpdateAndRemove = config?.counterpartSelfUpdateAndRemove
      ?? true;
    const isGuest = Boolean(guestToken);
    const isAllowedToUpdateAndRemove = (canCounterpartSelfUpdateAndRemove && isGuest) || isOwner;

    return isAllowedToUpdateAndRemove;
  };

  const handleOnKeyDown = () => undefined;

  const renderEditIcon = () => {
    const isAllowedToUpdateAndRemove = canCounterpartSelfUpdateAndRemoveInformation();

    if (!isAllowedToUpdateAndRemove) {
      return null;
    }

    if (!isEditable) {
      return null;
    }

    return (
      <div className={style.Edit}>
        <Pencil />
      </div>
    );
  };

  const renderModalButton = (openAction) => {
    const { name } = party;
    const isAllowedToUpdateAndRemove = canCounterpartSelfUpdateAndRemoveInformation();
    const isPreviewParty = isInPreviewMode && party.self === 1;

    const storedData = sessionStorage.getItem('extracted_data');
    const extractedData = (
      storedData && JSON.parse(storedData)[agreement.id] ? JSON.parse(storedData)[agreement.id] : {}
    );
    const isAiExtracted = Boolean(extractedData.parties?.[party.id]);

    const headerClasses = clsx(style.Header, {
      [style.NotEditable]: !isEditable || !isAllowedToUpdateAndRemove || isInPreviewMode,
      [style.Preview]: isPreviewParty,
      [style.IsAiExtracted]: isAiExtracted,
    });

    const handleClick = (event) => {
      if (!isEditable || !isAllowedToUpdateAndRemove || isInPreviewMode) {
        return;
      }

      openAction(event);
    };

    return (
      <div
        className={headerClasses}
        onClick={(event) => handleClick(event)}
        role="button"
        tabIndex={0}
        onKeyDown={handleOnKeyDown}
        key={party.id}
        id="party"
      >
        {isAiExtracted
          && (
          <div className={style.ImportSparkle}>
            <ImportSparkle
              agreementId={agreement.id}
              type="parties"
              matchId={party.id}
              className={style.PartySparkle}
            />
          </div>
          )}
        <span className={style.Heading}>
          {name}
        </span>
        {renderEditIcon()}
        {isPreviewParty && (
          <div className={style.PreviewMessage} data-testid="preview-message">
            <InfoIcon height="12px" />
            <Message
              id="This participant is only shown in preview."
              comment="Message for participants that are only shown in preview mode."
            />
          </div>
        )}
      </div>
    );
  };

  const getParticipants = (participantList) => {
    const isAllowedToUpdateAndRemove = canCounterpartSelfUpdateAndRemoveInformation();

    return participantList.map<React.Node>((participant) => {
      const isInternalApproverEditable = isInternalApprover(participant)
      && hasAccessToInternalApproval
      && isEditableParticipant(account, agreement, party, participant)
      && canEditAnyParticipantDetail(participant);
      const isParticipantEditable = isEditableParticipant(account, agreement, party, participant)
        && !isInternalApprover(participant)
        && canEditAnyParticipantDetail(participant)
        && !isInPreviewMode;

      return (
        <Participant
          position={position}
          participant={participant}
          key={participant.id}
          agreement={agreement}
          isIndividual={party.individual}
          isEditable={isInternalApproverEditable || (isAllowedToUpdateAndRemove
            && isParticipantEditable)}
          account={account}
          dateFormat={dateFormat}
          updatingSignOrder={updatingSignOrder}
          guestToken={guestToken}
          party={party}
        />
      );
    });
  };

  const renderAddButton = () => {
    const { self } = party;

    if (allowAddParticipant(account, agreement, party) && !self) {
      if (!hasPermissionToAddAnyParticipants(agreement)) {
        return (
          <div className={style.AddButton}>
            <AddParticipantButton
              type="counterparty_participant"
              onClick={() => { }}
              hasParticipantList
              disabled
            />
          </div>
        );
      }

      return (
        <div className={style.AddButton}>
          <AddCounterparty
            agreement={agreement}
            party={party}
            hasParticipantList
          />
        </div>
      );
    }

    if (allowAddColleague(account, agreement, party)
      && self && !isOwner && guestsCanAddParticipants) {
      return (
        <div className={style.AddButton}>
          <AddCounterparty
            agreement={agreement}
            party={party}
            hasParticipantList
          />
        </div>
      );
    }

    if (allowAddColleague(account, agreement, party)
      && self && isOwner) {
      return (
        <>
          <div className={style.Row}>
            <div className={style.AddButton}>
              <AddColleague agreement={agreement} hasParticipantList />
            </div>
          </div>
          <div className={style.Row}>
            {isImportInProgress(agreement) ? (
              <div className={style.AddButton}>
                <AddFormerColleague agreement={agreement} />
              </div>
            ) : null}
          </div>
        </>
      );
    }

    return null;
  };

  const callbackRef = React.useRef(null);

  const showResetToast = (resetType: 'signatures' | 'approvals' | 'approvals-signatures') => {
    if (!agreement || !party) return null;

    if ((resetType === 'signatures' || resetType === 'approvals-signatures') && !isPartiallySigned(agreement)) {
      return null;
    }

    if (resetType === 'signatures') {
      return toast.warning({
        id: 'signatures-reset',
        title: (
          <Message
            id="Signatures reset"
            comment="Title for the info message when the signatures have been reset."
          />
        ),
        description: (
          <Message
            id="The document needs to be signed again."
            comment="Description text for the info message when the signatures have been reset."
          />
        ),
        duration: 5000,
      });
    }

    if (resetType === 'approvals-signatures') {
      return toast.warning({
        id: 'approvals-reset',
        title: (
          <Message
            id="Approvals and signatures reset"
            comment="Title for the info message when the approvals have been reset."
          />
        ),
        description: (
          <Message
            id="The document needs to be approved and signed again."
            comment="Description text for the info message when the approvals have been reset."
          />
        ),
        duration: 5000,
      });
    }

    if (resetType === 'approvals') {
      return toast.warning({
        id: 'approvals-reset-only',
        title: (
          <Message
            id="Approvals reset"
            comment="Title for the info message when the approvals have been reset."
          />
        ),
        description: (
          <Message
            id="The document needs to be approved again."
            comment="Description text for the info message when the approvals have been reset."
          />
        ),
        duration: 5000,
      });
    }

    return null;
  };

  const renderParty = () => {
    if (party.individual) {
      return null;
    }

    return (
      <div>
        <EditCompany
          agreement={agreement}
          party={party}
          handleSignatureResetOnEdit={(cb, type) => {
            setResetSignaturesWarning(type);
            callbackRef.current = cb;
          }}
          handleApprovalsResetOnEdit={(cb, type) => {
            setResetApprovalsWarning(type);
            callbackRef.current = cb;
          }}
          handleApprovalsSignaturesResetOnEdit={(cb, type) => {
            setResetApprovalsSignaturesWarning(type);
            callbackRef.current = cb;
          }}
        >
          {renderModalButton}
        </EditCompany>
        <ResetSignaturesWarning
          agreementId={agreement.id}
          isOpen={Boolean(resetSignaturesWarning)}
          onConfirm={() => {
            callbackRef.current();
            setResetSignaturesWarning(null);
            showResetToast('signatures');
          }}
          onCancel={() => setResetSignaturesWarning(null)}
          type={resetSignaturesWarning}
        />
        <ResetApprovalsWarning
          agreementId={agreement.id}
          isOpen={Boolean(resetApprovalsWarning)}
          onConfirm={() => {
            callbackRef.current();
            setResetApprovalsWarning(null);
            showResetToast('approvals');
          }}
          onCancel={() => setResetApprovalsWarning(null)}
          type={resetApprovalsWarning}
        />
        <ResetApprovalsSignaturesWarning
          agreementId={agreement.id}
          isOpen={Boolean(resetApprovalsSignaturesWarning)}
          onConfirm={() => {
            callbackRef.current();
            setResetApprovalsSignaturesWarning(null);
            showResetToast('approvals-signatures');
          }}
          onCancel={() => setResetApprovalsSignaturesWarning(null)}
          type={resetApprovalsSignaturesWarning}
        />
      </div>
    );
  };

  const renderCounterpartiesTitle = () => {
    if (!isFirstCounterParty) {
      return null;
    }
    return (
      <h2 className={style.CounterpartiesTitle}>
        <Message
          id="Counterparties"
          comment="Label for counterparties title under participants tab in contract sidebar"
        />
      </h2>
    );
  };

  const getColleaguesHeader = () => {
    if (colleaguesList.length === 0) {
      return null;
    }

    return (
      <h2 className={style.ColleaguesHeader}>
        <Message
          id="Colleagues"
          comment="Label for colleagues section in participant list."
        />
      </h2>
    );
  };

  const getApproversHeader = () => {
    const renderLockedIcon = () => {
      const isLocked = !hasAccessToInternalApproval && !isTemplate(agreement);

      if (!isLocked) {
        return null;
      }

      return (
        <Lock height="16px" width="13px" />
      );
    };

    return (
      <div className={style.ApproverHeader}>
        <h2>
          <Message
            id="Internal approvers"
            comment="Label for internal approvers section in participant list."
          />
        </h2>
        {renderLockedIcon()}
      </div>
    );
  };

  const renderApproversAndColleaguesList = () => (
    <div className={style.InternalApprovers}>
      {getApproversHeader()}
      <div className={style.List}>
        {getParticipants(approversList)}
      </div>
      {getColleaguesHeader()}
      <div className={style.List}>
        {getParticipants(colleaguesList)}
      </div>
    </div>
  );

  const renderParticipantsList = () => {
    if (approversList?.length > 0 && isOwnerParty) {
      return renderApproversAndColleaguesList();
    }

    return getParticipants(enabledParticipantsWithoutApprovers);
  };

  const renderTooltipMessage = () => (
    <div className={style.TooltipContainer}>
      <span className={style.CompanyName}>{party.name}</span>
      <hr className={style.Divider} />
      <div className={style.TooltipBody}>
        {party?.orgnr ? (
          <div className={style.IconTextWrapper}>
            <OrganizationIcon className={style.Icon} />
            <div className={style.TextContainer}>
              <span className={style.IconText}>
                <Message
                  id="Registration number"
                  comment="Tooltip message for company registration number"
                />
              </span>
              <span className={style.Text}>{party.orgnr}</span>
            </div>
          </div>
        ) : null}
        <div className={style.IconTextWrapper}>
          <NetworkPinIcon className={style.Icon} />
          <div className={style.TextContainer}>
            <span className={style.IconText}>
              <Message
                id="Country"
                comment="Tooltip message for country"
              />
            </span>
            <span className={style.Text}>
              <Country country={party.country} lang={lang} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const shouldHidePreviewCompanyTooltip = isInPreviewMode && party.self;

  return (
    <div className={PartyClasses}>
      {renderCounterpartiesTitle()}
      <div className={PartyContainerClasses}>
        {shouldHidePreviewCompanyTooltip ? renderParty() : (
          <Tooltip
            message={renderTooltipMessage()}
            theme="light"
            zIndex="10000"
            sideOffset={6}
            side={windowSize.width > DOCUMENT_COLLAPSED_LAYOUT_SIZE ? 'left' : 'top'}
            messageClassName={style.Tooltip}
          >
            {renderParty()}
          </Tooltip>
        )}
        {renderParticipantsList()}
      </div>
      {renderAddButton()}
    </div>
  );
};

export default Party;
