/* eslint-disable react/display-name */
import clsx from 'clsx';
import badgeIcons, {
  BADGE_APPROVAL_REQUIRED,
  BADGE_SIGN_LATER_ENABLED,
  BADGE_READY_TO_SIGN,
  BADGE_SIGNING_ORDER_ENABLED,
  BADGE_SINGLE_SIGNATURE,
  BADGE_SIGN_ON_SAME_DEVICE,
} from 'agreement/badge-icons';
import Message from 'components/message';

import style from './badge-with-icon.module.scss';

interface BadgeClassesMap {
  [type: string]: string
}

const BADGE_CLASSES: BadgeClassesMap = {
  [BADGE_APPROVAL_REQUIRED]: style.ApprovalRequired,
  [BADGE_SIGN_LATER_ENABLED]: style.SignLaterEnabled,
  [BADGE_READY_TO_SIGN]: style.ReadyToSign,
  [BADGE_SIGNING_ORDER_ENABLED]: style.SigningOrderEnabled,
  [BADGE_SINGLE_SIGNATURE]: style.SingleSignature,
  [BADGE_SIGN_ON_SAME_DEVICE]: style.SignOnSameDeviceEnabled,
};

type Props = {
  type: string,
  className?: string,
}

const getBadgeLabel = (type: string) => {
  switch (type) {
    case BADGE_APPROVAL_REQUIRED:
      return (
        <Message
          id="Approval required"
          comment="Text for approval required badge."
        />
      );
    case BADGE_SIGN_LATER_ENABLED:
      return (
        <Message
          id="Sign later enabled"
          comment="Text for sign later enabled badge."
        />
      );
    case BADGE_READY_TO_SIGN:
      return (
        <Message
          id="Ready to sign"
          comment="Text for sign later enabled badge."
        />
      );
    case BADGE_SIGNING_ORDER_ENABLED:
      return (
        <Message
          id="Signing order enabled"
          comment="Text for signing order enabled badge."
        />
      );
    case BADGE_SINGLE_SIGNATURE:
      return (
        <Message
          id="Single signature"
          comment="Text for single signature badge."
        />
      );
    case BADGE_SIGN_ON_SAME_DEVICE:
      return (
        <Message
          id="Signing on this device enabled"
          comment="Text for sign on same device badge."
        />
      );

    default:
      return null;
  }
};

const BadgeWithIcon = ({
  type,
  className,
}: Props) => {
  const Icon = badgeIcons[type];

  const badgeClasses = clsx(style.Badge, className, {
    [BADGE_CLASSES[type]]: type,
  });

  return (
    <span className={badgeClasses}>
      <div className={style.BadgeIcon}>
        <Icon />
      </div>
      <span className={style.BadgeText}>
        {getBadgeLabel(type)}
      </span>
    </span>
  );
};

export default BadgeWithIcon;
