// @flow

import React, { useState } from 'react';

import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import NewCheck from 'components/icons/new-check';
import SelectField from 'components/select-field';

import { loadRemainingFonts } from 'font-manager';
import style from './font-select-field.module.scss';

type FontFamily = { label: string, value: string }

type Props = {
  selectedFontFamily: {label: string, value: string},
  initialFontFamily: {label: string, value: string},
  fontFamilyOptions: FontFamily[],
  setSelectedFontFamily: (FontFamily) => void,
  disabled?: boolean,
}

type CustomOptionProps = {
  isFocused: boolean,
  innerProps: Object,
  innerRef: string,
  data: Object,
};

const getFontFamilyOptionsComponent = (selectedFontFamily) => ({
  innerProps,
  data: fontSelection,
}: CustomOptionProps) => {
  const isSelected = selectedFontFamily.value === fontSelection.value;
  const classes = clsx(style.CustomOption,
    {
      [style.SelectedFontFamilyDropdown]: isSelected,
    });

  return (
    <div
      className={classes}
      {...innerProps}
    >
      <p className={style.CustomOptionText} style={{ fontFamily: fontSelection.value }}>
        {fontSelection.label}
      </p>
      {isSelected && <NewCheck className={style.FontFamilyCheckMark} />}
    </div>
  );
};

export const FontSelectField = ({
  selectedFontFamily,
  fontFamilyOptions,
  setSelectedFontFamily,
  disabled = false,
  initialFontFamily,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  let alphabeticallySortedOptions = [...fontFamilyOptions].sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }

    return 0;
  });

  if (isLoading) {
    alphabeticallySortedOptions = [];
  }

  const loadAllFontOptions = async () => {
    setIsLoading(true);
    try {
      await loadRemainingFonts(initialFontFamily.label);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const label = (
    <span className={style.Label}>
      <Message
        id="Font"
        comment="Label for field where you select your preferred font"
      />
    </span>
  );

  return (
    <div
      className={style.FontSelectField}
    >
      <SelectField
        label={label}
        components={{ Option: getFontFamilyOptionsComponent(selectedFontFamily) }}
        options={alphabeticallySortedOptions}
        clearable={false}
        searchable={false}
        hideErrorElement
        onMenuOpen={loadAllFontOptions}
        isLoading={isLoading}
        input={{
          value: selectedFontFamily,
          name: 'font-family',
          onChange: (fontFamily) => setSelectedFontFamily(fontFamily),
          initialValue: initialFontFamily,
        }}
        disabled={disabled}
      />
    </div>
  );
};
