// @flow

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  useSlate,
  useFocused,
} from 'slate-react';
import { Range } from 'slate';

import { AnnotationContextProvider } from 'contexts/parent-annotation';
import { getFocusedEditor } from 'reducers/editor';
import { isComment, isSuggestion } from 'comments';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import useIsEditorSelectionMemoized from 'components/rich-text-editor/hooks/use-is-editor-selection-memoized';
import { getActiveAnnotationElement } from 'components/contract-text-editor/editor-plugins/annotation-plugin';

import AnnotationWrapper from './annotation-wrapper';
import style from './annotation.module.scss';

const isAnnotationSelected = (selectedElement, annotationElement) => {
  if (annotationElement?.annotation?._id) {
    return selectedElement?.annotation?._id === annotationElement?.annotation?._id;
  }

  return selectedElement?.annotation?.id === annotationElement?.annotation?.id;
};

const AnnotationEditable = ({
  children,
  element,
  annotation,
}: RenderSlateElementProps) => {
  const editor = useSlate();
  const focused = useFocused();
  const focusEditor = useFocusEditor();
  const focusedEditor = useSelector(getFocusedEditor);
  const isEditorSelectionMemoized = useIsEditorSelectionMemoized();

  const { selection } = editor;
  const selectedElement = (editor === focusedEditor)
    && getActiveAnnotationElement(editor);

  const isFocused = useMemo(() => {
    if (!selection) {
      return false;
    }

    const isSelectionCollapsed = selection && Range.isCollapsed(selection);
    const focusedOrMemoized = focused || isEditorSelectionMemoized;

    return (
      focusedEditor === editor
      && focusedOrMemoized
      && isSelectionCollapsed
      && isAnnotationSelected(selectedElement, element)
    );
  }, [
    selection,
    focused,
    isEditorSelectionMemoized,
    focusedEditor,
    editor,
    selectedElement,
    element,
  ]);

  const isOpen = useMemo(() => {
    if (!isFocused) {
      return false;
    }

    return selectedElement === element;
  }, [
    isFocused,
    selectedElement,
    element,
  ]);

  const annotationClasses = clsx({
    'focused-comment': isComment(annotation) && isFocused,
    'focused-suggestion': isSuggestion(annotation) && isFocused,
    [style.CommentAnnotation]: isComment(annotation),
  });

  return (
    <AnnotationContextProvider annotation={annotation} isFocused={isFocused}>
      <AnnotationWrapper
        isOpen={isOpen}
        annotation={annotation}
        className={annotationClasses}
        onClosePopover={focusEditor}
      >
        {children}
      </AnnotationWrapper>
    </AnnotationContextProvider>
  );
};

export default AnnotationEditable;
