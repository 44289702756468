export const getAvailableMFAIds = (agreement: Oneflow.Agreement): string[] => (
  agreement.config?.enabledMfaChannels || agreement.availableOptions?.mfaChannels?.concat(['none'])
);

export const getDefaultMfaChannel = (agreement: Oneflow.Agreement): string => {
  if (
    agreement.config
    && Object.hasOwn(agreement.config, 'defaultMfaChannel')
    && agreement.config.defaultMfaChannel === null
  ) {
    return 'none';
  }

  return agreement.config?.defaultMfaChannel || agreement.availableOptions.mfaChannels[0];
};
