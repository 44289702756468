// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';
import { matchPath } from 'react-router';
import { type FormRenderProps } from 'react-final-form';

import { allowContractMove } from 'workspace/permissions';
import { isTemplate } from 'agreement/states';
import ModalForm from 'hocs/modal-form';

import { isDocumentAlreadyInTargetDestination } from 'components/modals/move-contract-bulk/steps/move-contract-step/helpers';
import { checkAcl } from 'components/acl';
import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';
import Field from 'components/field';
import FolderTree from 'components/folder-tree';
import SelectField from 'components/select-field';

import style from './move-contract.module.scss';
import { CurrencyWarning } from '../move-contract-bulk/steps/move-contract-step/currency-warning/currency-warning';

type FormData = {
  targetWorkspace: Workspace,
};

export type SubmitData = {
  isGlobalSearch: boolean,
  targetFolder?: null | number,
  targetWorkspace: number,
};

export type Props = {|
  addFolderState: RpcState,
  agreement: Agreement,
  allAvailableWorkspaces: Array<Workspace>,
  availableWorkspaces: Array<Workspace>,
  children: React.Node,
  formState: RpcState,
  message: MessageTranslator,
  onSubmit: SubmitData => void,
  onSuccess?: () => void,
  pathname: string,
  queryFoldersReload: () => void,
  resetFormState: () => void,
  workspace: Workspace,
  onClose: () => void,
  onStepComplete: () => void,
  setSkippedDocuments: (skippedDocuments: SkippedDocumentData[]) => void,
  folders: Array<Folder>,
|};

type State = {
  currentFolderId: typeof(undefined) | null | number,
  selectedWorkspace: Workspace,
};

export class MoveContractComponent extends React.Component<Props, State> {
  static defaultProps = {
    onSuccess: undefined,
  };

  state = {
    currentFolderId: undefined,
    selectedWorkspace: this.props.workspace,
  };

  isOnGlobalSearchPage = matchPath(this.props.pathname, {
    path: '/search/all',
    exact: false,
  })

  getSelectedWorkspace = () => {
    const { selectedWorkspace } = this.state;
    if (selectedWorkspace) {
      return selectedWorkspace;
    }

    return this.getDefaultValue();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      formState,
      onSuccess,
      queryFoldersReload,
      workspace,
    } = this.props;
    const hasFolderPermission = checkAcl(workspace.acl, 'collection:folder:view');

    if (!prevProps.formState.success && formState.success && hasFolderPermission) {
      queryFoldersReload();
    }

    if (!onSuccess) {
      return;
    }

    if (!prevProps.formState.success && formState.success) {
      onSuccess();
    }
  }

  componentDidMount() {
    const { agreement } = this.props;

    this.setState({
      currentFolderId: agreement.folder?.id,
    });
  }

  handleSelect = (selectedWorkspace: Workspace) => {
    const { agreement } = this.props;

    const callBack = () => {
      const selectedWorkspaceId = this.getSelectedWorkspace().id;
      const defaultWorkspaceId = this.getDefaultValue()?.id;

      this.setState({
        currentFolderId: selectedWorkspaceId === defaultWorkspaceId ? agreement.folder?.id : null,
        selectedWorkspace,
      });
    };

    this.setState({
      selectedWorkspace,
    }, callBack);
  }

  handleSubmit = (formData: FormData) => {
    const { onSubmit, folders = [] } = this.props;
    const { currentFolderId } = this.state;

    const documentCanBeMoved = !isDocumentAlreadyInTargetDestination({
      targetFolderId: currentFolderId,
      document: this.props.agreement,
      targetWorkspace: formData.targetWorkspace,
    });

    if (!documentCanBeMoved) {
      this.props.setSkippedDocuments([{
        type: 'alreadyInTargetFolder',
        documentId: this.props.agreement.id,
      }]);
      this.props.onStepComplete();
      return;
    }

    const targetFolder = folders.find((folder) => folder.id === currentFolderId);
    const targetWorkspace = this.getSelectedWorkspace();
    onSubmit({
      isGlobalSearch: this.isOnGlobalSearchPage,
      targetFolder,
      targetWorkspace,
    });
  }

  onClose = () => {
    this.setState({
      selectedWorkspace: undefined,
    });

    this.props.onClose();
  }

  hasDataRetentionPolicy = ({
    agreementDeclined,
    agreementDraft,
    agreementExpired,
    agreementTerminated,
  }: DataRetentionPolicy = {}) => (
    Boolean(agreementDeclined || agreementDraft || agreementExpired || agreementTerminated)
  );

  renderDataRetentionWarning() {
    const { agreement } = this.props;

    const selectedWorkspace = this.getSelectedWorkspace();
    const { dataRetentionPolicy } = selectedWorkspace || {};

    const hasDataRetentionPolicy = this.hasDataRetentionPolicy(dataRetentionPolicy);

    if (!hasDataRetentionPolicy || isTemplate(agreement)) {
      return null;
    }

    return (
      <Message
        id="Note: The selected workspace has a data retention policy enabled, which means that this contract could end up being removed if it doesn't match the workspace's retention policy."
        comment="Warning text for moving contract to a workspace with data retention policy."
      />
    );
  }

  renderCurrencyWarning() {
    const selectedWorkspace = this.getSelectedWorkspace();
    const { workspace, agreement } = this.props;

    if (!agreement.agreementValue) {
      return null;
    }

    if (!selectedWorkspace.currency || selectedWorkspace.currency === workspace.currency) {
      return null;
    }

    return (
      <CurrencyWarning selectedWorkspace={selectedWorkspace} selectedDocumentsData={[agreement]} />

    );
  }

  selectFolderId = (folderId: any) => {
    const currentFolderId = folderId === -1 ? null : folderId;

    this.setState({
      currentFolderId,
    });
  }

  getDefaultValue = () => {
    const {
      allAvailableWorkspaces,
      workspace,
    } = this.props;

    return allAvailableWorkspaces.find((workspaceOption) => workspaceOption.id === workspace.id);
  }

  renderFolders() {
    const { agreement, workspace } = this.props;
    const {
      currentFolderId,
    } = this.state;

    const selectedWorkspace = this.getSelectedWorkspace();

    const selectedUniqueKey = currentFolderId == null ? -1 : currentFolderId;

    const targetHasFolderPermission = checkAcl(
      selectedWorkspace?.acl,
      'collection:folder:view',
    );
    if (
      !isTemplate(agreement)
      && !workspace.virtual
      && checkAcl(workspace.acl, 'collection:folder:view')
      && targetHasFolderPermission
    ) {
      return (
        <FolderTree
          isModalVersion
          onFolderSelectionHandler={this.selectFolderId}
          selectedUniqueKey={selectedUniqueKey}
          selectedWorkspaceId={selectedWorkspace.id}
          selectedWorkspaceName={selectedWorkspace.name}
          selectedWorkspace={selectedWorkspace}
          branchContainerClassName={style.FolderTreeBranchContainer}
        />
      );
    }

    return null;
  }

  renderBody() {
    const {
      allAvailableWorkspaces,
      availableWorkspaces,
      message,
      workspace,
    } = this.props;

    const allowAgreementMove = allowContractMove(workspace) && Boolean(availableWorkspaces.length);
    const moveAcl = 'collection:agreement:move';
    const hasPermission = (permission: string) => (
      (collection) => checkAcl(collection.acl, permission)
    );

    const getOptions = () => {
      if (!checkAcl(workspace.acl, moveAcl)) {
        return allAvailableWorkspaces.filter((collection) => collection.id === workspace.id);
      }

      return allAvailableWorkspaces.filter(hasPermission(moveAcl));
    };

    return (
      <div>
        <Field
          component={SelectField}
          defaultValue={this.getDefaultValue()}
          disabled={!allowAgreementMove}
          label={message({
            id: 'Target workspace',
            comment: 'Label in the move to... modal.',
          })}
          labelKey="name"
          name="targetWorkspace"
          onChange={this.handleSelect}
          options={getOptions()}
          valueKey="id"
        />
        <div className={style.Warnings}>
          {this.renderDataRetentionWarning()}
          {this.renderCurrencyWarning()}
        </div>
        {this.renderFolders()}
      </div>
    );
  }

  getIsDirtyState = () => {
    const { agreement } = this.props;
    const { currentFolderId } = this.state;

    if (agreement.folder?.id === undefined && currentFolderId) {
      return true;
    }

    if (agreement.folder?.id && agreement.folder?.id !== currentFolderId) {
      return true;
    }

    return false;
  }

  renderActions = ({ formProps }: FormRenderProps<*>) => {
    const { addFolderState, formState } = this.props;

    const isLoading = formState.loading;
    const isFolderLoading = addFolderState.loading;

    const isDisabled = (
      formProps.hasValidationErrors
      || formProps.validating
      || isLoading)
      && isFolderLoading;

    return (
      <Button
        data-testid="confirm"
        disabled={isDisabled}
        icon={isLoading || isFolderLoading ? CircularSpinner : null}
        kind="primary"
        onClick={formProps.handleSubmit}
      >
        <Message
          id="Move"
          comment="Text for button to confirm action."
        />
      </Button>
    );
  }

  render() {
    const {
      children,
      formState,
      message,
      resetFormState,
    } = this.props;

    return (
      <ModalForm
        actions={this.renderActions}
        body={this.renderBody()}
        formState={formState}
        initialValues={{
          targetWorkspace: this.getDefaultValue(),
        }}
        isOpen
        isDirty={this.getIsDirtyState()}
        modalKey="move contract modal"
        onClose={this.onClose}
        onSubmit={this.handleSubmit}
        resetFormState={resetFormState}
        title={message({
          id: 'Move to...',
          comment: 'Modal title in the move to... modal.',
        })}
      >
        {children}
      </ModalForm>
    );
  }
}

export default localize<Props>(MoveContractComponent);
