// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import ClearFormatting from 'components/icons/clear-formatting';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import { clearFormatting } from './clear-formatting-plugin';
import ToolbarButton from '../toolbar-button';

type Props = {
  isMenuItem: boolean,
  disabled?: boolean,
};

const ClearFormattingButton = ({ isMenuItem, disabled }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      isMenuItem={isMenuItem}
      disabled={disabled}
      label={(
        <Message
          id="Clear formatting"
          comment="The label of the editor's toolbar plugin that clears the formatting of a selected text."
        />
      )}
      onTrigger={() => {
        focusEditor();
        clearFormatting(editor);
      }}
      icon={<ClearFormatting />}
    />
  );
};

export default ClearFormattingButton;
