import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import type { Moment } from 'moment';

import { timestampToDateString } from 'date';
import { updateDurationBoxAttribute } from 'reducers/current-contract';
// eslint-disable-next-line import/named
import { useDurationBoxProps } from 'contexts/duration-box-props';
import { DurationDatePicker } from 'components/contract-boxes/duration-box/duration-date-picker';

type Props = {
  dateType: string,
};

export const SetDate = ({
  dateType,
}: Props) => {
  const {
    boxId,
    endDate,
    startDate,
  } = useDurationBoxProps();

  const date = dateType === 'startDate' ? startDate : endDate;

  const dispatch = useDispatch();

  const changeDateHandler = useCallback((pickedDate: number) => {
    const newPickedDate = timestampToDateString(pickedDate, 'YYYY-MM-DD');
    dispatch(updateDurationBoxAttribute(boxId, { [dateType]: newPickedDate }));
  }, [boxId, dateType, dispatch]);

  const isOutsideRange = useCallback((day: Moment) => {
    let noBefore = startDate ? moment(startDate) : moment();
    noBefore = noBefore.add(25, 'hours');
    if (dateType === 'endDate') {
      if (startDate) {
        return day.isBefore(noBefore.toDate()) || undefined;
      }
      return day.isBefore(moment().toDate());
    }

    if (dateType === 'startDate') {
      if (endDate) {
        return day.isAfter(moment(endDate).toDate());
      }
    }

    return day.isBefore(moment('1970-01-01').toDate());
  }, [dateType, endDate, startDate]);

  if (!date) {
    return null;
  }

  return (
    <DurationDatePicker
      date={date}
      changeDateHandler={changeDateHandler}
      isOutsideRange={isOutsideRange}
    />
  );
};
