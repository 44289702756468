// @flow

import React from 'react';

import log from 'logging';
import { isEnvironment } from 'utils/environment';

import DataInvalidError from './data-invalid-error';
import DataParseError from './data-parse-error';

type Params = {
  schema: any, // After migration typesctipt z.ZodObject,
  entity: any,
  conditionalValidations: Array<Function>,
  devHint: boolean,
  logOnly?: boolean,
  logLevel?: 'debug' | 'info' | 'log' | 'warning' | 'error' | 'critical',
  // If it is meant to be used only in rollbar use english text
  // Otherwise translated string (eg., form validations)
  validationMessage: string,
};

// Make sure you have error boundary (app/components/error-boundary/error-boundary.jsx)
// Wrapped somewhere in the derired ancestor component, we have one wrapped
// Whole Root component and it will be logged there
const useDataValidator = (params: Params) => {
  const {
    schema: Schema,
    entity,
    conditionalValidations,
    devHint = false,
    logOnly,
    logLevel = 'info',
    validationMessage,
  } = params;

  React.useMemo(() => {
    // TODO: consider removing this later
    if (isEnvironment('development') && devHint) {
      // eslint-disable-next-line no-console
      console.warn('[DevOnly]: Do you have ErrorBoundary(components/error-boundary/) wrapped in right ancestor/parent component?');
    }

    // passthrough will allow extra/additional keys
    const validatedData = Schema.passthrough().safeParse(entity);

    if (!validatedData.success) {
      if (logOnly) {
        log[logLevel]?.(validationMessage, { validatedData });
        return;
      }

      const dataError = new DataInvalidError(validatedData, validationMessage);
      throw dataError;
    }
  }, [Schema, devHint, entity, logLevel, logOnly, validationMessage]);

  React.useMemo(() => {
    // Will throw early on first conditional error
    conditionalValidations.forEach((validation, index) => {
      let validator;
      try {
        validator = validation(entity);
      } catch (error) {
        const parseError = new DataParseError(error.message, index);
        throw parseError;
      }

      let conditioalValidatedData;
      if (validator) {
        conditioalValidatedData = validator.schema.passthrough().safeParse(validator.data);
      }

      if (conditioalValidatedData && !conditioalValidatedData.success) {
        if (logOnly) {
          log[logLevel]?.(validator.validationMessage, { conditioalValidatedData });
          return;
        }

        const dataError = new DataInvalidError(
          conditioalValidatedData, validator.validationMessage,
        );

        throw dataError;
      }
    });
  }, [conditionalValidations, entity, logLevel, logOnly]);
};

export default useDataValidator;
