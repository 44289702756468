// @flow
import type { MessageTranslator } from '@oneflowab/pomes';
import { isEmpty } from 'lodash';
import type { Validator } from './index';

type ParticipantPhoneNumberValidator = ({
  message: MessageTranslator,
}) => Validator;

type Params = {
  value: string,
  participantCountry?: string,
  selectedCountry?: { countryCode: string, country: string },
  inputFieldCurrentValue?: string
}

const participantPhoneNumber: ParticipantPhoneNumberValidator = ({
  message,
}) => ({
  value: fullValue,
  participantCountry,
  // Country that has been selected in the field
  selectedCountry,
  // In some cases fullValue is not updated, but this value is. Vice versa.
  inputFieldCurrentValue: currentInput,
}: Params) => {
  if (!fullValue) {
    return undefined;
  }
  let value = fullValue;

  if (value.startsWith('+')) {
    value = value.replace('+', '');
  }

  if (!value) {
    return undefined;
  }

  const valueContainsCountryCode = value.includes(selectedCountry?.countryCode);
  const countryCodeContainsValue = selectedCountry?.countryCode?.includes(value);
  const currentInputContainsCountryCode = currentInput?.includes(selectedCountry?.countryCode);
  const hasSelectedCountryField = !isEmpty(selectedCountry);
  const hasSelectedParticipantCountry = !isEmpty(participantCountry);
  const hasSelectedParticipantAndFieldCountry = hasSelectedCountryField
  && hasSelectedParticipantCountry;

  const isValueCountryCode = value === selectedCountry?.countryCode;
  const isParticipantCountrySelectedCountry = participantCountry?.toString().toLowerCase()
  === selectedCountry?.country?.toLowerCase();
  const isValueMoreThanCountryCode = value?.length > selectedCountry?.countryCode?.length;
  const isValueLengthLessThanSix = fullValue.length < 6;
  const userHasTypedInvalidPhoneNumber = valueContainsCountryCode
  && isValueMoreThanCountryCode
  && isValueLengthLessThanSix;

  const invalidErrorMessage = message({
    id: 'Phone number is invalid',
    comment: 'Validation message for participant phone number validator.',
  });

  if (hasSelectedCountryField && isValueCountryCode) {
    return undefined;
  }

  // Is used when the user has chosen both a company country and a specific country in field.
  // The input value is never updated on change of participant country select.
  if (hasSelectedParticipantAndFieldCountry && isParticipantCountrySelectedCountry) {
    if (
      countryCodeContainsValue
      || userHasTypedInvalidPhoneNumber
    ) {
      return invalidErrorMessage;
    }

    return undefined;
  }

  if (hasSelectedParticipantAndFieldCountry && !isParticipantCountrySelectedCountry) {
    if (
      countryCodeContainsValue
      || !currentInputContainsCountryCode
      || userHasTypedInvalidPhoneNumber
    ) {
      return invalidErrorMessage;
    }

    return undefined;
  }

  // Edit individual
  if (hasSelectedParticipantCountry && !hasSelectedCountryField) {
    if (isValueLengthLessThanSix) {
      return invalidErrorMessage;
    }

    return undefined;
  }

  if (value && isValueLengthLessThanSix) {
    return invalidErrorMessage;
  }

  return undefined;
};

export default participantPhoneNumber;
