import { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { localize, MessageTranslator } from '@oneflowab/pomes';
import { orderBy } from 'natural-orderby';
import Message from 'components/message';
import { getAccountFromSessionSelector } from 'reducers/session';

import { isSmallScreenWidth } from 'ui/config';
import getCSSPropertyValue from 'utils/get-css-property-value';

import agreements from 'reducers/entities/agreements';
import { isTemplate } from 'agreement';
import { getGuestToken, getAgreementMyParty } from 'agreement/selectors';
import { hasMfaChannelSms, hasMfaChannelPersonalIdentification } from 'agreement/participant';
import { hasParticipantEmail, hasParticipantPhone } from 'agreement/agreement-participants';
import * as participantConstants from 'agreement/participant/constants';

import Field from 'components/field';
import TextField from 'components/text-field';
import InfoIcon from 'components/icons/info';
import SelectField from 'components/select-field';
import MessageTemplatePicker from 'components/message-template-picker';
import { getParticipantRolesAsOptions, participantRoles } from 'components/participant-roles/participant-roles';
import { EmailField, PhoneNumberField } from 'components/fields';

import style from './delegate-pending-approval-rights-body.module.scss';

export type Props = {
  agreement: Oneflow.Agreement;
  message: MessageTranslator;
  myParticipant?: AgreementParticipant;
  contractMessageTemplates?: Array<MessageTemplate>;
  colleagues: Array<AgreementParticipant>;
  getMessageBody?: () => void;
  updateSelectedMessageTemplate?: () => void;
  selectedMessageTemplate?: MessageTemplate;
};

export const isPhoneNumberRequired = (signingDetails: { twoStepAuthenticationMethod?: string }) => (
  hasMfaChannelSms({
    mfaChannel: signingDetails?.twoStepAuthenticationMethod,
  })
);

export const isPersonalIdRequired = (signingDetails: { twoStepAuthenticationMethod?: string }) => (
  hasMfaChannelPersonalIdentification({
    mfaChannel: signingDetails?.twoStepAuthenticationMethod,
  })
);

export const DelegatePendingApprovalRightsBodyComponent = ({
  agreement,
  message,
  myParticipant,
  contractMessageTemplates,
  getMessageBody,
  updateSelectedMessageTemplate,
  selectedMessageTemplate,
  colleagues,
}: Props) => {
  const dispatch = useDispatch();
  const guestToken = useSelector(getGuestToken);
  const isGuestView = Boolean(guestToken);

  // This is needed to be fetched when in contract list
  useEffect(() => {
    if (isEmpty(agreement.availableOptions)) {
      dispatch(agreements.fetchAgreement(({
        id: agreement.id,
      })));
    }
  }, [agreement.id, dispatch, agreement.availableOptions]);

  const customStyles = useMemo(() => {
    const control = (styles, state) => {
      if (state.isDisabled) {
        return {
          ...styles,
          backgroundColor: `${getCSSPropertyValue('--of-inactive')} !important`,
          cursor: 'not-allowed',
          border: `1px ${getCSSPropertyValue('--of-inactive')} !important`,
        };
      }
      return styles;
    };

    const singleValue = (styles, state) => {
      if (state.isDisabled) {
        return {
          ...styles,
          color: `${getCSSPropertyValue('--of-forest-green-03')} !important`,
        };
      }
      return styles;
    };
    return {
      control,
      singleValue,
    };
  }, []);

  const { collection } = agreement;
  const accountFromSession = useSelector(getAccountFromSessionSelector);
  const isMobile = isSmallScreenWidth();
  const side = isMobile ? 'top' : 'right';

  const myParty = useMemo(() => getAgreementMyParty(agreement), [agreement]);

  const getSigningDetailsValues = useCallback(() => ({
    signMethod: myParticipant?.signMethod,
    deliveryChannel: myParticipant?.deliveryChannel,
    twoStepAuthenticationMethod: myParticipant?.mfaChannel,
  }), [
    myParticipant?.deliveryChannel,
    myParticipant?.mfaChannel,
    myParticipant?.signMethod,
  ]);

  const defaultCountryValue = useMemo(() => myParty?.country
  || collection?.brandingCountry
  || accountFromSession?.country, [
    accountFromSession?.country,
    collection?.brandingCountry,
    myParty?.country,
  ]);

  const getColleaguesOptions = useCallback((colleaguesList) => (
    colleaguesList.map(
      (colleague) => ({
        value: colleague?.id,
        label: colleague.title ? `${colleague.fullname} - ${colleague.title}` : colleague.fullname,
      }),
    )
  ), []);

  const renderInfoField = () => (
    <div className={style.InfoTextContainer}>
      <InfoIcon width="12px" className={style.InfoIcon} />
      <p className={style.InfoText}>
        <Message
          id="When you delegate your approval rights, the new participant will be added as an approver and your role will be changed to influencer."
          comment="Informative text in the Delegate approval rights modal telling the user that their role will change and that the role of the new person will be as an approver."
        />
      </p>
    </div>
  );

  const renderDelegateeTitle = () => (
    <div className={style.DelegateeInfo}>
      <Message
        id="Delegatee info"
        comment="Delegatee info in the delegate approval rights modal."
      />
    </div>
  );

  const renderColleagueField = () => {
    if (isGuestView) {
      return null;
    }

    const orderedColleagues = orderBy(
      colleagues,
      [(colleague) => colleague.fullname],
      ['asc'],
    );

    return (
      <Field
        name="colleague"
        label={message({
          id: 'Colleague',
          comment: 'Field label in delegate approval rights modal.',
        })}
        placeholder={(
          <Message
            id="Select colleague"
            comment="Label for the colleague field in delegate approval rights modal."
          />
        )}
        component={SelectField}
        options={getColleaguesOptions(orderedColleagues)}
        clearable={false}
        searchable
        required
        autoFocus
      />
    );
  };

  const renderFullNameField = () => {
    if (!isGuestView) {
      return null;
    }

    return (
      <>
        <Field
          name="fullname"
          label={message({
            id: 'Full name',
            comment: 'Field label in the delegate approval rights modal.',
          })}
          placeholder={message({
            id: 'Enter full name',
            comment: 'Field placeholder in the delegate approval rights modal.',
          })}
          component={TextField}
          required
          maxLength={100}
          autoFocus
          responsive
        />
      </>
    );
  };

  const renderJobTitleField = () => {
    if (!isGuestView) {
      return null;
    }

    return (
      <>
        <Field
          name="jobTitle"
          label={message({
            id: 'Job title',
            comment: 'Field label in delegate approval rights modal.',
          })}
          placeholder={message({
            id: 'Enter job title',
            comment: 'Field placeholder in delegate approval rights modal.',
          })}
          component={TextField}
        />
      </>
    );
  };

  const renderPersonalIdentificationInfo = () => {
    if (!isGuestView) {
      return null;
    }

    return (
      <>
        <p>
          <Message
            id="Personal ID number or equivalent that identifies the participant."
            comment="Tooltip help text explaining the personal identification field."
          />
        </p>
        <p>
          <Message
            id="This information will be displayed in signed documents."
            comment="Tooltip help text explaining the personal identification field."
          />
        </p>
      </>
    );
  };

  const renderPersonalIdentificationField = () => (
    <Field
      name="personalIdentification"
      label={message({
        id: 'Personal identification',
        comment: 'Field label in delegate approval rights modal.',
      })}
      placeholder={message({
        id: 'Enter personal identification number',
        comment: 'Field placeholder in delegate approval rights modal.',
      })}
      component={TextField}
      fieldinfo={{
        message: renderPersonalIdentificationInfo(),
        icon: <InfoIcon width="12px" />,
        side,
        zIndex: '10004',
        nextToLabel: true,
      }}
      required={isPersonalIdRequired(getSigningDetailsValues())}
    />
  );

  const renderMessageTemplateSection = () => {
    if (isTemplate(agreement)) {
      return null;
    }

    return (
      <>
        <div className={style.MessageContainer}>
          <h1>
            <Message
              id="Message"
              comment="Header before message template picker section and notification subject and body."
            />
          </h1>
        </div>
        {isGuestView ? (
          <MessageTemplatePicker
            isSubjectFieldRequired
            isMessageFieldRequired
          />
        ) : (
          <MessageTemplatePicker
            contractMessageTemplates={contractMessageTemplates}
            selectedMessageTemplate={selectedMessageTemplate}
            updateSelectedMessageTemplate={updateSelectedMessageTemplate}
            isSubjectFieldRequired
            isMessageFieldRequired
            getMessageBody={getMessageBody}
          />
        )}
      </>
    );
  };

  const getParticipantRoles = () => (
    getParticipantRolesAsOptions(message, true).filter(
      (role) => role.value === participantConstants.TYPE_IS_EXTERNAL_APPROVER,
    )
  );

  const renderAvailableUserRolesDescription = () => {
    const allAvailableParticipantRoles = participantRoles(message, true);
    const visibleParticipantRoleDescription = (
      <ul>
        {Object.values(allAvailableParticipantRoles)?.map((role) => (
          <li key={role?.value}>
            {role?.description}
          </li>
        )).filter(Boolean)}
      </ul>
    );
    return visibleParticipantRoleDescription;
  };

  const renderRoleField = () => {
    const input = {
      value: getParticipantRoles()[0],
      name: 'userRole',
    };

    return (
      <Field
        defaultValue={getParticipantRoles()[0]}
        name="userRole"
        label={message({
          id: 'Role',
          comment: 'Field label in delegate approval rights modal.',
        })}
        component={SelectField}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        options={getParticipantRoles()}
        customStyles={customStyles}
        clearable={false}
        searchable={false}
        input={input}
        required
        disabled
        fieldinfo={{
          message: renderAvailableUserRolesDescription(),
          icon: <InfoIcon width="12px" />,
          side,
          zIndex: '10003',
          nextToLabel: true,
        }}
      />
    );
  };

  const validateUniqueEmail = useCallback(
    ({ params }) => hasParticipantEmail({ params, agreementId: agreement.id }), [agreement.id],
  );

  const validateUniquePhone = useCallback(
    ({ params }) => hasParticipantPhone({ params, agreementId: agreement.id }), [agreement.id],
  );

  const renderDelegateToColleagueBody = () => {
    if (!colleagues || isEmpty(colleagues)) {
      return (
        <div>
          <Message
            id="Everyone in your account is already in this document."
            comment="Text shown in the delegate approval rights modal when there is no more colleagues in that account to delegate approval rights to."
          />
        </div>
      );
    }

    return (
      <div>
        {renderInfoField()}
        {renderDelegateeTitle()}
        {renderColleagueField()}
        <div className={style.Row}>
          {renderRoleField()}
        </div>
        {renderMessageTemplateSection()}
      </div>
    );
  };

  const renderDelegateForGuestBody = () => (
    <div>
      {renderInfoField()}
      {renderDelegateeTitle()}
      <div className={style.Row}>
        {renderFullNameField()}
        <EmailField
          required
          unique={{
            text: message({
              id: 'A person with that email address already exists in the document.',
              comment: 'Form  validation error message.',
            }),
            param: 'email',
            request: validateUniqueEmail,
          }}
          responsive
        />
      </div>
      <div className={style.Row}>
        {renderJobTitleField()}
        <PhoneNumberField
          defaultCountry={defaultCountryValue.toLowerCase()}
          unique={{
            text: message({
              id: 'A person with that phone number already exists in the document.',
              comment: 'Form validation error message.',
            }),
            param: 'phoneNumber',
            request: validateUniquePhone,
          }}
          required={isPhoneNumberRequired(getSigningDetailsValues())}
          responsive
        />
      </div>
      <div className={style.Row}>
        {renderPersonalIdentificationField()}
        {renderRoleField()}
      </div>
      {renderMessageTemplateSection()}
    </div>
  );

  if (isGuestView) {
    return renderDelegateForGuestBody();
  }

  return renderDelegateToColleagueBody();
};

export const
  DelegatePendingApprovalRightsBody = localize(DelegatePendingApprovalRightsBodyComponent);
