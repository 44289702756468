// @flow

import React from 'react';
import clsx from 'clsx';
import { localize, Message } from '@oneflowab/pomes';
import {
  getAgreementMyParticipant,
  getParticipants,
} from 'agreement/selectors';
import isInternalApprover from 'agreement/participant/is-internal-approver';
import { getParticipantInternalApprovers } from 'agreement';
import {
  anApproverIsWithoutSignatoryAfter,
  hasSignatoriesInDocument,
} from 'agreement/pending-state-flow';

import { ApproversLastModal } from 'components/modals/approvers-last';
import AdditionalInformation from 'components/additional-information';
import InvitationHeader from 'components/invitation-header';
import MessageTemplatePicker from 'components/message-template-picker';
import RecipientList from 'components/recipient-list';
import { SignLaterEnabledInfo } from 'components/sign-later-enabled-info';
import Toggle from 'components/toggle';
import BadgeWithIcon from 'components/badge-with-icon';
import {
  BADGE_SIGNING_ORDER_ENABLED,
  BADGE_SINGLE_SIGNATURE,
  BADGE_SIGN_LATER_ENABLED,
  BADGE_APPROVAL_REQUIRED,
} from 'agreement/badge-icons';

import style from './send-contract-body.module.scss';

export type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  agreement: Agreement,
  contractMessageTemplates: Array<MessageTemplate>,
  selectedMessageTemplate: MessageTemplate,
  updateSelectedMessageTemplate: Function,
  isNotifyParticipants?: boolean,
  isSubjectFieldRequired: boolean,
  isMessageFieldRequired: boolean,
  signature?: string,
  context?: string,
  sendAutomaticallyAfterApproval?: boolean,
  setSendAutomaticallyAfterApproval?: () => void,
  isApprovalFlow?: boolean,
};

export const SendContractBody = ({
  agreement,
  contractMessageTemplates,
  selectedMessageTemplate,
  updateSelectedMessageTemplate,
  isNotifyParticipants,
  isSubjectFieldRequired,
  isMessageFieldRequired,
  signature,
  context,
  sendAutomaticallyAfterApproval,
  setSendAutomaticallyAfterApproval,
  isApprovalFlow,
}: Props) => {
  const [isApproverLastModalOpen, setIsApproverLastModalOpen] = React.useState(false);
  const participants = getParticipants(agreement);
  const draftApprovers = getParticipantInternalApprovers(agreement);
  const nonApproverParticipants = participants.filter(
    (participant) => !isInternalApprover(participant),
  );
  const myParticipant = getAgreementMyParticipant(agreement);
  const participantsWithoutMe = nonApproverParticipants
    .filter((participant) => participant.id !== myParticipant.id);

  const renderSignOrderInfo = () => {
    if (!agreement.config?.signOrder) {
      return null;
    }

    return (
      <div>
        <BadgeWithIcon type={BADGE_SIGNING_ORDER_ENABLED} />
        <p>
          <Message
            id="Participants will receive the document with the following message based on their signing order."
            comment="Text explaining the section in the modal of when the participants will recieve the document."
          />
        </p>
      </div>
    );
  };

  const renderDraftApproversList = () => (
    <RecipientList
      agreement={agreement}
      draftApprovers={draftApprovers}
      isInternalApproverList
    />
  );

  const renderRecipientsList = () => (
    <RecipientList
      agreement={agreement}
      isInternalApproverList={false}
    />
  );

  const renderAdditionalInformation = () => {
    if (isNotifyParticipants) {
      return (
        <span className={style.AdditionalInformation}>
          <i>
            <Message
              id="Saving without a message will send an automated notification to all participants."
              comment="Information in a Send modal explaining that the recipient will receive an automated notification."
            />
          </i>
        </span>
      );
    }

    return <AdditionalInformation />;
  };

  const renderSingleSignature = () => {
    if (!agreement.config?.singleSign) {
      return null;
    }

    if (agreement.config?.singleSign && agreement.config?.signLater) {
      return (
        <div>
          <div className={style.MultipleBadges}>
            <BadgeWithIcon type={BADGE_SIGN_LATER_ENABLED} />
            <BadgeWithIcon type={BADGE_SINGLE_SIGNATURE} />
          </div>
          <p>
            <Message
              id="The {sign} button will be hidden for counterparties. To enable signing, turn off {signLater}."
              values={{
                sign: <span className={style.SignLater}>Sign</span>,
                signLater: <span className={style.SignLater}>Sign later</span>,
              }}
              comment="Text explaining to turn off the sign later setting so the participants can sign."
            />
          </p>
          <p>
            <Message
              id="The contract will only require one signature to become legally binding."
              comment="Text explaining that one signature is needed for the document to be legally binding."
            />
          </p>
        </div>
      );
    }

    return (
      <div>
        <BadgeWithIcon type={BADGE_SINGLE_SIGNATURE} />
        <p>
          <Message
            id="The contract will only require one signature to become legally binding."
            comment="Text explaining that one signature is needed for the document to be legally binding."
          />
        </p>
      </div>
    );
  };

  const onToggleChange = () => {
    if (!sendAutomaticallyAfterApproval
      && anApproverIsWithoutSignatoryAfter(agreement)
      && hasSignatoriesInDocument(agreement)) {
      setIsApproverLastModalOpen(true);
    }

    return setSendAutomaticallyAfterApproval
      && setSendAutomaticallyAfterApproval(!sendAutomaticallyAfterApproval);
  };

  const renderInviteAutoSignToggle = () => {
    if (draftApprovers.length === 0
      || participantsWithoutMe.length === 0) {
      return null;
    }

    return (
      <div className={style.RenderInviteAutoSignToggle}>
        <Toggle
          checked={sendAutomaticallyAfterApproval}
          onChange={() => onToggleChange()}
          label={(
            <Message
              id="Send invitation to sign automatically after approval"
              comment="Text explaining that an invitation to sign automatically after approval will be sent."
            />
          )}
        />
      </div>
    );
  };

  const renderSignLaterEnabledInfo = () => {
    if (!agreement.config?.signLater || agreement.config?.singleSign) {
      return null;
    }

    return (
      <SignLaterEnabledInfo
        agreement={agreement}
      />
    );
  };

  const renderDraftApprovalHeader = () => (
    <h2 className={style.ApprovalHeader}>
      <Message
        id="Approval"
        comment="Header for the approval section in the modal."
      />
    </h2>
  );

  const renderDraftApprovalBadge = () => (
    <>
      <BadgeWithIcon type={BADGE_APPROVAL_REQUIRED} />
      <Message
        id="The document will be sent to internal approvers first."
        comment="Text explaining that the document will be sent to approvers before it's sent to the recipients."
      />
    </>
  );

  const renderDraftApproversSection = () => {
    if (!isApprovalFlow) {
      return null;
    }

    return (
      <>
        {renderDraftApprovalHeader()}
        <div className={style.ApproversList}>
          {renderDraftApprovalBadge()}
          {renderDraftApproversList()}
        </div>
        {renderInviteAutoSignToggle()}
      </>
    );
  };

  const renderRecipientsSection = () => {
    if (isApprovalFlow && !sendAutomaticallyAfterApproval) {
      return null;
    }

    return (
      <>
        <InvitationHeader />
        <div className={style.RecipientListList}>
          {renderSignLaterEnabledInfo()}
          {renderSignOrderInfo()}
          {renderSingleSignature()}
          {renderRecipientsList()}
          <MessageTemplatePicker
            contractMessageTemplates={contractMessageTemplates}
            selectedMessageTemplate={selectedMessageTemplate}
            updateSelectedMessageTemplate={updateSelectedMessageTemplate}
            isSubjectFieldRequired={isSubjectFieldRequired}
            isMessageFieldRequired={isMessageFieldRequired}
            signature={signature}
            context={context}
          />
          {renderAdditionalInformation()}
        </div>
      </>
    );
  };

  const onContinue = () => {
    setIsApproverLastModalOpen(false);
    return setSendAutomaticallyAfterApproval(true);
  };

  const onClose = () => {
    setIsApproverLastModalOpen(false);
  };

  const renderApproverLastModal = () => (
    <ApproversLastModal
      onClose={onClose}
      isOpen={isApproverLastModalOpen}
      setIsOpen={setIsApproverLastModalOpen}
      sendAutomaticallyAfterApproval={sendAutomaticallyAfterApproval}
      onContinue={onContinue}
      setSendAutomaticallyAfterApproval={setSendAutomaticallyAfterApproval}
    />
  );

  const containerClasses = clsx(style.BodyContainer, {
    [style.Mobile]: isApprovalFlow,
  });

  return (
    <div className={containerClasses}>
      {renderDraftApproversSection()}
      {renderRecipientsSection()}
      {renderApproverLastModal()}
    </div>
  );
};

export default localize<Props>(SendContractBody);
