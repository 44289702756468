import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, values } from 'lodash';
import { Message } from '@oneflowab/pomes';

import {
  isPending,
  isWaitingForSignatures,
  isSignLaterEnabled,
  isConcluded,
} from 'agreement';
import {
  getCounterpartyParticipants,
  getAgreementMyParticipant,
  getGuestToken,
  getSignedParticipants,
  usesSameDeviceSome,
  isAgreementOwner,
} from 'agreement/selectors';
import { hasPendingStateApprovers } from 'agreement/pending-state-flow';
import isInfluencer from 'agreement/participant/is-influencer';
import useAgreement from 'hooks/use-agreement';
import useCurrentBoxOrder from 'hooks/use-current-box-order';
import useCurrentBoxes from 'hooks/use-current-boxes';
import useCurrentData from 'hooks/use-current-data';
import agreements from 'reducers/entities/agreements';
import {
  getAcceptedSuggestions,
  getPristineContractData,
  getPristineState,
  isContractPristine,
} from 'reducers/current-contract';
import { setNotifyParticipant } from 'reducers/editor';
import { getAccountFromSessionSelector } from 'reducers/session';
import { isViewer } from 'agreement/participant';

import { isContractEditable } from 'components/document-layout-container/helpers';
import type { Layout } from 'components/document-layout-container/types';
import toast from 'components/toasts';

import isExternalApprover from 'agreement/participant/is-external-approver';
import isOrganizer from 'agreement/participant/is-organizer';
import {
  getDataFieldChangedValues,
  getNonPristineBoxesMap,
  getNonPristineDataFieldsMap,
} from 'components/document-call-to-actions/actions/buttons-call-to-action/actions/save/helpers';
import { ApprovePendingActions } from './approve-pending-actions';
import { SignButton } from './buttons-call-to-action/buttons';
import { DiscardSaveActions, DiscardSaveSignActions, SignOnSameDeviceActions } from './buttons-call-to-action';
import SignLaterEnabled from './states/sign-later-enabled';

export type FormData = { message: string; subject: string } | Record<string, never>;

type Props = {
  agreementId: Oneflow.Agreement['id'],
  layout: Layout
}

const showToast = () => (
  toast.info({
    id: 'saved-and-notified',
    title: <Message
      id="Saved and notified"
      comment="Title for the info message when the user has saved the document."
    />,
    description: <Message
      id="Document successfully saved and participants have been notified"
      comment="Description text for the info message when the user has saved."
    />,
    duration: 5000,
  })
);

const PendingActions = ({ agreementId, layout }: Props) => {
  const agreement = useAgreement(agreementId);
  const participant = getAgreementMyParticipant(agreement);
  const dispatch = useDispatch();

  const isPristine = useSelector(isContractPristine);
  const isEditable = isContractEditable(agreement);

  // We only want to notify participants if there are other parties than the owner party
  const shouldNotifyOtherParties = getCounterpartyParticipants(agreement).length > 0;

  const boxOrder = useCurrentBoxOrder();
  const boxes = useCurrentBoxes();
  const pristineState = useSelector(getPristineState);
  const pristineContract = useSelector(getPristineContractData);
  const nonPristineBoxes = getNonPristineBoxesMap(boxes, pristineState);

  const data = useCurrentData();
  const pristineData = pristineContract?.data;
  const nonPristineData = getNonPristineDataFieldsMap(data, pristineState);
  const guestToken = useSelector(getGuestToken);
  const acceptedSuggestions = useSelector(getAcceptedSuggestions);
  const account = useSelector(getAccountFromSessionSelector);
  const someUsingSameDevice = usesSameDeviceSome(agreement, account);
  const canSeeSignLater = isAgreementOwner(account, agreement) && !isViewer(participant);

  const [showModal, setShowModal] = useState(false);

  const menuItems = [
    {
      content: (
        <Message id="Save and notify" comment="Menu item for saving and notifying participants" />
      ),
      onSelect: () => {
        setShowModal(true);
      },
    },
  ];

  useEffect(() => {
    const isPartialSigned = getSignedParticipants(agreement).length > 0;
    if (!isPristine && isEditable && shouldNotifyOtherParties && isPartialSigned) {
      dispatch(setNotifyParticipant(true));
    }

    if (!isPartialSigned) {
      dispatch(setNotifyParticipant(false));
    }
  }, [isPristine, isEditable, dispatch, shouldNotifyOtherParties, agreement]);

  const onSaveAndNotifyChanges = (formData: FormData) => {
    const agreementData = {
      boxOrder,
      boxes: values(nonPristineBoxes),
      data: getDataFieldChangedValues(nonPristineData, pristineData),
      guestToken,
      notificationTempFlag: false,
      ...formData,
    };

    if (!isEmpty(acceptedSuggestions)) {
      agreementData.suggestionsToBeAccepted = acceptedSuggestions.map((id) => ({
        suggestionId: id,
      }));
    }

    dispatch(agreements.saveAgreement({
      id: agreementId,
      data: agreementData,
      pipe: {
        onSuccess: showToast,
      },
    }));
  };

  if (isEmpty(participant)) {
    return null;
  }

  if (!isPristine && isEditable) {
    // We only want to notify participants if there are other parties than the owner party
    if (shouldNotifyOtherParties) {
      if (someUsingSameDevice) {
        return (
          <SignOnSameDeviceActions agreement={agreement} layout={layout} />
        );
      }

      if (isInfluencer(participant) || isOrganizer(participant)) {
        return (
          <DiscardSaveActions
            agreementId={agreementId}
            isSaveDropdown
            menuItems={menuItems}
            notifyParticipants={{
              setModalOpen: setShowModal,
              modalOpen: showModal,
              onSaveAndNotifyChanges,
            }}
          />
        );
      }

      if (isWaitingForSignatures(agreement)) {
        return (
          <DiscardSaveSignActions agreement={agreement} layout={layout} />
        );
      }

      return (
        <DiscardSaveSignActions
          agreement={agreement}
          isSaveDropdown
          menuItems={menuItems}
          layout={layout}
          notifyParticipants={{
            setModalOpen: setShowModal,
            modalOpen: showModal,
            onSaveAndNotifyChanges,
          }}
        />
      );
    }

    if (isInfluencer(participant) || isOrganizer(participant)) {
      return (
        <DiscardSaveActions agreementId={agreementId} />
      );
    }

    if (isWaitingForSignatures(agreement) && someUsingSameDevice) {
      return (
        <SignOnSameDeviceActions agreement={agreement} layout={layout} />
      );
    }

    return (
      <DiscardSaveSignActions agreement={agreement} layout={layout} />
    );
  }

  if (hasPendingStateApprovers(agreement) && isExternalApprover(participant)
    && !isConcluded(agreement)) {
    return (
      <ApprovePendingActions
        layout={layout}
        agreement={agreement}
        participant={participant}
      />
    );
  }

  if ((isInfluencer(participant) || isOrganizer(participant)) && !someUsingSameDevice) {
    if (isSignLaterEnabled(agreement) && canSeeSignLater) {
      return <SignLaterEnabled layout={layout} />;
    }

    return null;
  }

  if (someUsingSameDevice && isPending(agreement)) {
    return <SignOnSameDeviceActions agreement={agreement} layout={layout} />;
  }

  return (
    <>
      <SignButton agreement={agreement} layout={layout} />
    </>
  );
};

export default PendingActions;
