import { useForm, useFormState } from 'react-final-form';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import EditIcon from 'components/icons/edit';

type Props = {
  resetFormState: () => void;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  disabled?: boolean;
  ignorePristine?: boolean;
  formState?: {
    loading: boolean;
    dirtySinceLastSubmit: boolean;
  };
};

const Actions = ({
  resetFormState,
  formState,
  isEditMode,
  setIsEditMode,
  disabled,
  ignorePristine,

}: Props) => {
  const form = useForm();
  const { pristine, submitting, invalid } = useFormState();

  const handleCancel = () => {
    form.reset();
    resetFormState();
    setIsEditMode(false);
  };

  const shouldUsePristine = ignorePristine ? false : pristine;

  const isSubmitDisabled = disabled
    || shouldUsePristine
    || submitting
    || invalid
    || (formState?.loading ?? false);

  return (
    <>
      {isEditMode ? (
        <>
          <Button kind="primary" type="submit" disabled={isSubmitDisabled && !formState?.dirtySinceLastSubmit}>
            <Message
              id="Confirm"
              comment="Used as the text of the relevant button in details page"
            />
          </Button>
          <CancelButton onClick={handleCancel} />
        </>
      ) : (
        <Button kind="primary" disabled={disabled} onClick={() => setIsEditMode(true)} icon={EditIcon}>
          <Message
            id="Edit"
            comment="Used as the text of the relevant button in details page"
          />
        </Button>
      )}
    </>
  );
};

export default Actions;
