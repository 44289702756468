// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import extensionSettings from 'hocs/extension-settings';

export const NeocasePage = () => (
  <>
    <p>
      <Message
        id="By enabling this extension you will send specific mailhooks when a contract gets signed."
        comment="Help text for the Neocase extension."
      />
    </p>
  </>
);

export default extensionSettings(NeocasePage);
