import * as Select from '@radix-ui/react-select';
import { useSelector } from 'react-redux';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { useSpring, animated } from 'react-spring';
import { useState, useLayoutEffect, useRef } from 'react';

import { getCurrentLanguageSelector } from 'reducers/i18n';

import Message from 'components/message';
import SingleUserActionsAddIcon from 'components/icons/single-user-actions-add';
import SelfLabel from 'components/self-label';

import { DownChevronIcon, SelectedIcon } from '../overlay-text-field/icons';
import style from './participant-selector.module.scss';

type Props = {
  value: number | undefined,
  onValueChange: (value: string) => void,
  options: Oneflow.Participant[],
  message: MessageTranslator,
  onClick?: (open?: boolean) => void,
}

const sortOptionsByName = (
  options: Oneflow.Participant[],
  currentLanguage: string,
  message: MessageTranslator,
) => {
  const collator = new Intl.Collator(currentLanguage, {
    numeric: true,
    sensitivity: 'variant',
  });

  const compare = (a: Oneflow.Participant, b: Oneflow.Participant) => {
    const nameA = a?.fullname || '';
    const nameB = b?.fullname || '';
    return collator.compare(nameA, nameB);
  };

  const unassignedOption = {
    id: 0,
    fullname: message({ id: 'Unassigned', comment: 'Unassigned option of dropdown' }),
    self: 0,
  };
  return [unassignedOption, ...options.sort(compare)];
};

const initialSpringConfig = {
  tension: 1500,
  friction: 100,
  precision: 0.001,
};

const State = {
  Open: 'open',
  Closed: 'closed',
  Closing: 'closing',
};

const ParticipantSelector = ({
  value,
  onValueChange,
  options,
  message,
  onClick,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const currentLanguage = useSelector(getCurrentLanguageSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(State.Closed);

  const springStyles = useSpring({
    config: initialSpringConfig,
    from: { opacity: 0, scale: 0.98 },
    to: state === State.Open
      ? { opacity: 1, scale: 1 }
      : { opacity: -1, scale: 0.98 },
    onRest: () => {
      if (state === State.Closing) {
        setState(State.Closed);
        setIsOpen(false);
      }
    },
  });

  useLayoutEffect(() => {
    if (!state) {
      return;
    }
    if (isOpen) {
      setState(State.Open);
      onClick?.(true);
      return;
    }
    if (!isOpen && state === State.Open) {
      setState(State.Closing);
      onClick?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Select.Root
      name="participant-selector"
      value={String(value)}
      onValueChange={onValueChange}
      onOpenChange={setIsOpen}
      open={state !== State.Closed}
    >
      <Select.Trigger
        id="dataFieldSelect"
        className={style.SelectFieldTrigger}
        tabIndex={0}
        data-overlay-field-popover-cta
      >
        <SingleUserActionsAddIcon strokeWidth={1.5} height="14" />
        <span className={style.ParticipantText}>
          <Message id="Participant" comment="Used as the text of the button to invite a new user" />
        </span>
        <DownChevronIcon height="10" />
      </Select.Trigger>
      <Select.Content
        className={style.SelectContent}
        position="popper"
        sideOffset={10}
        asChild
        ref={ref}
      >
        <animated.div style={springStyles}>
          <Select.Viewport className={style.SelectViewPort}>
            {sortOptionsByName(options, currentLanguage, message).map((option) => (
              <Select.Item
                key={option.id}
                value={String(option.id)}
                className={style.SelectItem}
              >
                <div className={style.NameIconContainer}>
                  <Select.ItemText className={style.OptionName}>
                    {option.fullname}
                  </Select.ItemText>
                  {option.self === 1 && <SelfLabel />}
                </div>
                <Select.ItemIndicator>
                  <SelectedIcon
                    className={style.SelectItemIndicatorIcon}
                    width="10px"
                    height="10px"
                  />
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Viewport>
        </animated.div>
      </Select.Content>
    </Select.Root>
  );
};

export default localize(ParticipantSelector);
