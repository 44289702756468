import { Message } from '@oneflowab/pomes';

type RoleType = {
  accountAccessStats : Oneflow.Group['accountAccessStats']
}

export const Roles = ({ accountAccessStats }: RoleType) => (
  <Message
    id="{count} role"
    pluralId="{count} roles"
    pluralCondition="count"
    values={{
      count: accountAccessStats || 0,
    }}
    comment="Showing number of roles a group has"
  />
);
