import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  isEmpty,
  isEqual,
} from 'lodash';
import {
  createEditor,
  Descendant,
  Editor,
  Node,
  Range,
  Transforms,
} from 'slate';
import {
  ReactEditor,
  Slate,
  withReact,
} from 'slate-react';
import { withHistory } from 'slate-history';
import clsx from 'clsx';

import { useInlineEditableProps } from './inline-editable-context';
import { EditableElement } from './editable-element';
import { getNode } from './helpers';
import style from './inline-editable.module.scss';

type Props = {
  className?: string;
  defaultValue?: string;
  editableClassName?: string;
  characterLimit?: number;
  onChange: (newValue: string) => void;
  readOnly?: boolean;
  value?: string,
};

export const InlineEditable = ({
  className,
  defaultValue = '',
  editableClassName,
  characterLimit = 130,
  onChange,
  readOnly,
  value,
}: Props) => {
  const [editor] = useState(() => withReact(withHistory(createEditor())));
  const {
    isFocused,
    setIsFocused,
    setText,
  } = useInlineEditableProps();

  const currentValue = value || defaultValue;
  const [editorValue, setEditorValue] = useState(getNode(currentValue));

  const currentText = Node.string(editorValue[0]);
  const isKeyEscape = useRef<boolean>(false);

  useEffect(() => {
    setText(currentText);
  }, [currentText, setText]);

  const onSlateChange = (newValue: Descendant[]) => {
    const isAstChange = editor.operations.some(
      (op) => op.type !== 'set_selection',
    );

    if (!isAstChange) {
      return;
    }

    setEditorValue(newValue);
  };

  const handleSetContent = (targetValue: string) => {
    const newNode = getNode(targetValue);

    Transforms.delete(editor, { at: [0] });
    Transforms.insertNodes(editor, newNode, { at: [0] });

    setEditorValue(newNode);
  };

  const handleValueChange = () => {
    if (!isEqual(currentText, currentValue)) {
      onChange(currentText);
    }
  };

  const getCurrentTextLength = () => {
    const { selection } = editor;
    const selectedTextLength = selection && Range.isExpanded(selection)
      ? Editor.string(editor, selection).length
      : 0;

    return currentText.length - selectedTextLength;
  };

  const onBeforeInput = (event: InputEvent) => {
    const text = event.data || '';
    const newTextLength = getCurrentTextLength() + text.length;

    if (newTextLength > characterLimit) {
      event.preventDefault();
    }
  };

  const onBlur = () => {
    setIsFocused(false);
    Transforms.select(editor, Editor.start(editor, []));

    if (isKeyEscape.current) {
      handleSetContent(currentValue);
      isKeyEscape.current = false;

      return;
    }

    if (isEmpty(currentText.trim())) {
      handleSetContent(defaultValue);
    }

    handleValueChange();
  };

  const onFocus = () => {
    const start = Editor.start(editor, []);
    const end = Editor.end(editor, []);

    if (!value) {
      Transforms.select(editor, {
        anchor: start,
        focus: end,
      });
    }

    setIsFocused(true);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      ReactEditor.blur(editor);
      return;
    }

    if (event.key === 'Escape') {
      isKeyEscape.current = true;

      ReactEditor.blur(editor);
    }
  };

  const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    const { clipboardData } = event;
    const plainText = clipboardData.getData('text/plain');
    const remainingAllowedCharacters = characterLimit - getCurrentTextLength();

    if (remainingAllowedCharacters > 0) {
      const allowedText = plainText.slice(0, remainingAllowedCharacters);
      const sanitizedText = allowedText.replace(/[\r\n]+/g, '');

      Transforms.insertText(editor, sanitizedText);
    }
  };

  return (
    <Slate
      editor={editor}
      initialValue={editorValue}
      onChange={onSlateChange}
    >
      <div
        className={clsx(style.InlineEditable, className, {
          [style.Focused]: isFocused,
        })}
      >
        <EditableElement
          className={editableClassName}
          onBeforeInput={onBeforeInput}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          readOnly={readOnly}
        />
      </div>
    </Slate>
  );
};
