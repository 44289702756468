// @flow

import * as React from 'react';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import get from 'lodash/get';

import Button from 'components/button';
import Confirmable from 'components/confirmable';
import CircularSpinner from 'components/icons/circular-spinner';
import Email from 'components/icons/email';
import Badge from 'components/badge';
import CheckboxGroup from 'components/checkbox-group';

import style from './export-account.module.scss';

type GetActions = () => React.Node;

export type ExportOptions = {
  export_agreement?: boolean,
  export_position?: boolean,
  export_tag?: boolean,
};

export type Props = {
  children: () => React.Node,
  isAccountExportEnabled: boolean,
  requestAccountExportState: RpcState,
  requestAccountExport: ({
    exportOptions: ExportOptions,
    accountId: number,
    positionId: number
  }) => void,
  resetAccountExport: ({ accountId: number }) => void,
  message: MessageTranslator,
  currentAccount: Account,
  position: Position,
};

type State = {
  selectedOptions: Array<string>,
};

export class ExportAccount extends React.PureComponent<Props, State> {
  state = {
    selectedOptions: ['contracts', 'users', 'tags'],
  };

  getOptions() {
    const { message } = this.props;

    return [
      {
        value: 'contracts',
        label: message({
          id: 'Contracts',
          comment: 'Contracts option in the export account modal',
        }),
        isSelected: true,
      },
      {
        value: 'users',
        label: message({
          id: 'Users',
          comment: 'Users option in the account export modal',
        }),
      },
      {
        value: 'tags',
        label: message({
          id: 'Tags',
          comment: 'Tags option in the account export modal',
        }),
      },
    ];
  }

  isOptionSelected = (option: string) => {
    const { selectedOptions } = this.state;

    return Boolean(selectedOptions) && selectedOptions.includes(option);
  };

  onChange = (selectedOptions: Array<string>) => {
    this.setState({ selectedOptions });
  };

  handleConfirm = () => {
    const {
      currentAccount,
      position,
      requestAccountExport,
      requestAccountExportState,
    } = this.props;
    const { selectedOptions } = this.state;
    const accountId = currentAccount.id;
    const positionId = position.id;

    if (requestAccountExportState.loading || selectedOptions.length === 0) {
      return;
    }

    const exportOptions = {
      export_agreement: this.isOptionSelected('contracts') || undefined,
      export_position: this.isOptionSelected('users') || undefined,
      export_tag: this.isOptionSelected('tags') || undefined,
    };

    requestAccountExport({ exportOptions, accountId, positionId });
  };

  getActions: GetActions = () => {
    const { requestAccountExportState } = this.props;
    const { selectedOptions } = this.state;

    return (
      <Button
        kind="primary"
        icon={requestAccountExportState.loading ? CircularSpinner : Email}
        disabled={requestAccountExportState.loading || selectedOptions.length === 0}
        onClick={this.handleConfirm}
      >
        <Message
          id="Request export"
          comment="Button text, to confirm account export request"
        />
      </Button>
    );
  };

  handleOpen = () => {
    const { resetAccountExport, currentAccount } = this.props;

    resetAccountExport({ accountId: currentAccount.id });
  };

  getErrorMessage = () => {
    const { requestAccountExportState } = this.props;
    const statusCode = get(requestAccountExportState.error, 'body.status_code');

    if (statusCode !== 429) {
      return undefined;
    }

    return {
      headerText: (
        <Message
          id="An export is already ongoing."
          comment="Error header in the account export modal."
        />
      ),
      bodyText: (
        <Message
          id="Please try again later."
          comment="Error body in the account export modal."
        />
      ),
    };
  };

  renderBody() {
    const { position } = this.props;
    const { selectedOptions } = this.state;
    const options = this.getOptions();

    return (
      <div className={style.ExportAccount}>
        <p>
          <Message
            id="Select what you would like to export: "
            comment="Header text in the account export modal."
          />
        </p>
        <CheckboxGroup
          options={options}
          onChange={this.onChange}
          value={selectedOptions}
        />
        <p>
          <Message
            id="The export will be sent to {userEmail} within an hour."
            values={{
              userEmail: <Badge label={position.email} kind="name" />,
            }}
            comment="Help text explaining where the email will be sent in the account export modal."
          />
        </p>
      </div>
    );
  }

  render() {
    const {
      children,
      requestAccountExportState,
      isAccountExportEnabled,
    } = this.props;

    return (
      <Confirmable
        header={(
          <Message
            id="Export account"
            comment="Confirm modal title for exporting the account."
          />
        )}
        body={this.renderBody()}
        actions={this.getActions}
        onOpen={this.handleOpen}
        success={requestAccountExportState.success}
        error={requestAccountExportState.error}
        customErrorMessage={this.getErrorMessage()}
        onEnter={this.handleConfirm}
        disabled={!isAccountExportEnabled}
        modalKey="export account modal"
      >
        {children}
      </Confirmable>
    );
  }
}

export default localize<Props>(ExportAccount);
