import { isEqual } from 'lodash';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import toast from 'components/toasts';
import { getErrorCode } from 'components/api-error';

import style from './asset-error-toasts.module.scss';

export const isAssetFailed = (error: any) => isEqual(getErrorCode(error), 'ASSET_PROCESSING_FAILED');
export const isAssetProcessing = (error: any) => isEqual(getErrorCode(error), 'ASSET_PROCESSING');

export const showProcessingToast = () => toast.error({
  id: 'create-document-from-template-failed',
  title: (
    <Message
      id="Processing in progress"
      comment="Message to show action failed due to file processing."
    />
  ),
  description: (
    <Message
      id="Please try again shortly."
      comment="Message to show template needs a fix before using it."
    />
  ),
});

export const showFailureToast = () => toast.error({
  id: 'create-document-from-template-failed',
  title: (
    <Message
      id="Action failed due to file error"
      comment="Message to show action failed due to file error."
    />
  ),
  description: (
    <Message
      id="The template needs to be adjusted."
      comment="Message to show template needs a fix before using it."
    />
  ),
});

export const showAssetFailedToast = () => toast.error({
  id: 'asset-failed-toast',
  description: (
    <div className={style.ToastDescriptionWrapper}>
      <div className={style.InfoTextWrapper}>
        <div className={style.Title}>
          <Message
            id="File error"
            comment="Notification message to show there is failed file in the attachment box"
          />
        </div>
        <Message
          id="Remove or replace the file."
          comment="Description message to show fix or remove the file to be able to proceed"
        />
      </div>
      <Button
        className={style.Button}
        onClick={() => {
          const element = document.getElementsByClassName('FailedAsset')[0];
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            toast.remove({
              toastId: 'asset-failed-toast',
            });
          }
        }}
      >
        <Message
          id="Show"
          comment="Button to show the box that has failed attachment"
        />
      </Button>
    </div>
  ),
});
