// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  getAgreementCounterparties,
  getAgreementMyParty,
} from 'agreement/selectors';
import { isDraft } from 'agreement';

import Tooltip from 'components/tooltip';

import style from './contract-parties.module.scss';

type Props = {
  agreement: Agreement,
  hideIfNoContractName?: boolean,
};

const MAX_VISIBLE_PARTIES = 3;
class ContractParties extends React.Component<Props> {
  static defaultProps = {
    hideIfNoContractName: undefined,
  };

  getCounterparties() {
    const { agreement } = this.props;

    return getAgreementCounterparties(agreement);
  }

  renderWithoutCounterparties() {
    const { agreement } = this.props;

    if (isDraft(agreement)) {
      return (
        <span className={style.NoCounterparties}>
          <Message
            id="Untitled"
            comment="Shown in contract list for drafts without counterparties."
          />
        </span>
      );
    }

    const myParty = getAgreementMyParty(agreement) || {};

    return (
      <span className={style.CounterpartyName}>
        {myParty.name}
      </span>
    );
  }

  renderPartyNames() {
    const counterparties = this.getCounterparties();

    if (!counterparties.length) {
      return this.renderWithoutCounterparties();
    }

    const visibleParties = counterparties.slice(0, MAX_VISIBLE_PARTIES);

    const mapToPartyElements = (party: AgreementParty) => (
      <span key={party.id} className={style.CounterpartyName}>
        {party.name}
      </span>
    );
    const joinByComma = (acc, curr) => (acc === null ? [curr] : [...acc, ',', curr]);

    return visibleParties.map(mapToPartyElements).reduce(joinByComma, null);
  }

  renderMoreParties() {
    const counterparties = this.getCounterparties();

    if (counterparties.length <= MAX_VISIBLE_PARTIES) {
      return null;
    }

    const hiddenParties = counterparties.slice(MAX_VISIBLE_PARTIES);
    const joinedPartyNames = hiddenParties.map((party) => party.name).join(', ');

    return (
      <Tooltip
        message={joinedPartyNames}
        side="top"
        sideOffset={1}
      >
        <span className={style.MoreParties}>
          {`+${hiddenParties.length}`}
        </span>
      </Tooltip>
    );
  }

  render() {
    const { hideIfNoContractName, agreement } = this.props;

    if (hideIfNoContractName && !agreement.name) {
      return null;
    }

    return (
      <div className={style.ContractParties}>
        {this.renderPartyNames()}
        {this.renderMoreParties()}
      </div>
    );
  }
}

export default ContractParties;
