import { Message } from '@oneflowab/pomes';

import DurationReminders from 'components/document-tabs/settings-tab/sections/duration-reminders';
import Accordion from '../accordion';
import style from './accordion.module.scss';

type Props = { agreementId: number };

const DurationRemindersSection = ({ agreementId }: Props) => {
  const title = (
    <Message
      id="Duration and reminders"
      comment="Header for duration and reminders section, where you choose how many days you should be reminded before document period ends."
    />
  );

  return (
    <Accordion title={title} name="durationReminders" contentClassName={style.Content}>
      <DurationReminders agreementId={agreementId} />
    </Accordion>
  );
};

export default DurationRemindersSection;
