import { Message } from '@oneflowab/pomes';
import style from './beta-label.module.scss';

export const BetaLabel = () => (
  <div className={style.BetaLabel}>
    <Message
      id="Beta"
      comment="A tag to show the feature is a beta version"
    />
  </div>
);
