import { includes } from 'lodash';

import { PRODUCT_TABLE_COLUMNS } from 'components/contract-boxes/product-table-box/constants';
import ActionsDotsIcon from 'components/icons/actions-dots';

type Props = {
  columnKey: string,
  label: string,
  readOnly: boolean,
}

const ColumnCellContent = ({
  columnKey,
  label,
  readOnly = false,
}: Props) => {
  const {
    COUNT,
    DESCRIPTION,
    NAME,
  } = PRODUCT_TABLE_COLUMNS;

  const isNameDescOrCount = includes([NAME, DESCRIPTION, COUNT], columnKey);
  const shouldAlignLeft = isNameDescOrCount;

  if (shouldAlignLeft) {
    return (
      <>
        <h4>{label}</h4>
        {!readOnly && <ActionsDotsIcon width="12px" />}
      </>
    );
  }

  return (
    <>
      {!readOnly && <ActionsDotsIcon width="12px" />}
      <h4>{label}</h4>
    </>
  );
};

export default ColumnCellContent;
