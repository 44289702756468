// @flow

import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Message } from '@oneflowab/pomes';

import { useVideoBoxProps } from 'contexts/video-box-props';
import { isTemplate } from 'agreement';
import { getMyParticipantWhenUpdater } from 'agreement/selectors';

import BoxEmptyDraftState from 'components/box-empty-draft-state';
import Video from 'components/icons/video';

import FileUploadRequirements from 'components/file-upload/file-upload-requirements';
import DropzoneUploadSpace from 'components/file-upload/dropzone-upload-space';
import style from './empty-video-box.module.scss';

const EmptyVideoBox = () => {
  const {
    agreement,
    isAllowedToAddBoxData,
  } = useVideoBoxProps();
  const maxFileSizeBytes = 120 * 1024 * 1024;
  const myParticipant = !isEmpty(agreement) ? getMyParticipantWhenUpdater(agreement) : {};

  if ((isEmpty(myParticipant) && !isTemplate(agreement)) || !isAllowedToAddBoxData) {
    return (
      <div className={style.ReadOnlyNoVideo}>
        <div className={style.ReadOnlyNoVideoIcon}>
          <Video />
        </div>
        <div>
          <Message
            id="No video"
            comment="Text to show there is no video."
          />
        </div>
      </div>
    );
  }

  return (
    <div className={style.BoxEmptyContainer}>
      <BoxEmptyDraftState
        icon={Video}
        isDisabled={!isAllowedToAddBoxData}
        customClassName={style.BoxEmptyDraftState}
        description={(
          <div className={style.UploadLink}>
            <DropzoneUploadSpace
              displayFileType="video"
              isContractBox
              body={(
                <FileUploadRequirements
                  maxFileSize={maxFileSizeBytes}
                />
                )}
              showRejectMessage={false}
            />
          </div>
        )}
      />
    </div>
  );
};

export default EmptyVideoBox;
