import React, { useMemo, useCallback } from 'react';

import type { FormEvent } from 'react';

import style from './update-input-item.module.scss';

type DefaultValidations = {
  [key: string]: React.ReactNode
}

type ValidationProps<Validations extends DefaultValidations = DefaultValidations> = {
  validations: Validations; // formValidations?
  setValidations?: React.Dispatch<React.SetStateAction<Validations>>;
  validate?: (value: FormEvent<HTMLInputElement>) => React.ReactNode;
}

type InputProps<InputName = string> = {
  type: 'text' | 'number'
  name: InputName;
  label: React.ReactNode,
  defaultValue?: string | number;
};

export type InputComponentProps<InputName = string> = InputProps<InputName> & {
  defaultValue?: string | number;
  onInput?: (value: FormEvent<HTMLInputElement>) => void,
  dataPopupId?: string,
}

type InputElementProps = React.ComponentProps<'input'>;
type InputProviderProps<Validations extends DefaultValidations = DefaultValidations> = {
  label: InputProps<Validations>['label'],
  htmlFor: string,
  validate?: ValidationProps<Validations>['validate'], // use it for individual field level
  setValidations?: ValidationProps<Validations>['setValidations'],
  children: React.ReactElement<InputElementProps, 'input'>,
  validations: ValidationProps<Validations>['validations'],
  tooltipInfo?: React.ReactNode,
}

const emptyValidate = () => '';
const voidSetValidations = () => undefined;

function InputProvider<
  InputName extends string = string,
  Validations extends DefaultValidations = DefaultValidations
>(props: InputProviderProps<Validations>) {
  const {
    label,
    htmlFor,
    validate = emptyValidate,
    setValidations = voidSetValidations,
    validations,
    children: input,
    tooltipInfo,
  } = props;

  const inputName = input.props.name as InputProps<InputName>['name'];
  const inputEventHandler = input.props?.onInput;

  const handleInput = useCallback((event: FormEvent<HTMLInputElement>) => {
    inputEventHandler?.(event);

    const validationText = validate(event);

    // When we use final form validators on regular inputs
    // We need to set invalid on corresponding inputs
    if (validationText && event.currentTarget.validity.valid) {
      event.currentTarget.setCustomValidity('Invalidated by custom validation');
    } else {
      event.currentTarget.setCustomValidity('');
    }

    setValidations({
      ...validations,
      [inputName]: validationText,
    });
  }, [inputEventHandler, inputName, setValidations, validate, validations]);

  const inputProps = useMemo(() => ({ onInput: handleInput }), [handleInput]);

  return (
    <div className={style.FieldLayout}>
      <label htmlFor={htmlFor} data-id="textfieldlabel" className={style.InputFieldLabel}>
        <span className={style.LabelText}>{label}</span>
        {tooltipInfo}
      </label>
      <div className={style.InputField}>
        {React.cloneElement(input, inputProps)}
      </div>
      <div data-testid="validationMessage" className={style.ValidationMessage}>
        {validations[inputName] && <span>{validations[inputName]}</span>}
      </div>
    </div>
  );
}

const MISSING_INPUT_VALUE = '';

function Input<
  InputName extends string = string,
  Validations extends DefaultValidations = DefaultValidations
>(
  props: Omit<InputElementProps, 'name'>
    & InputComponentProps<InputName>
    & Omit<InputProviderProps<Validations>, 'children' | 'htmlFor'>,
) {
  const {
    type = 'text',
    name,
    label,
    id = name,
    defaultValue = MISSING_INPUT_VALUE,
    validations,
    setValidations,
    validate,
    onInput,
    tooltipInfo,
    placeholder,
    dataPopupId,
  } = props;

  return (
    <InputProvider<InputName, Validations>
      label={label}
      validations={validations}
      validate={validate}
      setValidations={setValidations}
      tooltipInfo={tooltipInfo}
      htmlFor={id}
    >
      <input
        autoComplete="off"
        data-1p-ignore
        data-lpignore
        data-bwignore
        data-form-type="other"
        data-popup-id={dataPopupId}
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        onInput={onInput}
        defaultValue={defaultValue}
        className={style.Input}
        aria-invalid={Boolean(validations?.[name])}
      />
    </InputProvider>
  );
}

export default Input;
