import { Message } from '@oneflowab/pomes';
import { useDispatch, useSelector } from 'react-redux';
import type { ReactNode, SyntheticEvent } from 'react';

import agreementsReducer from 'reducers/entities/agreements';
import { getCurrentWorkspaceIdSelector } from 'reducers/app';

// eslint-disable-next-line import/named
import { RemoveConfirm } from 'components/modals/remove-confirm';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import PermanentDeleteIcon from 'components/icons/permanent-delete';

import style from './empty-trash.module.scss';

type Props = {
  children: (onClick: (event: SyntheticEvent) => void) => ReactNode,
  setLoadingState: (isLoading: boolean) => void,
}

type ActionProps = {
  closeConfirmation: () => void,
}

export const EmptyTrashModal = ({ children, setLoadingState }: Props) => {
  const dispatch = useDispatch();
  const workspaceId = useSelector(getCurrentWorkspaceIdSelector) as number;

  const removeState = useSelector((
    state,
  ) => agreementsReducer.getEmptyAgreementTrashSelector(state, { id: workspaceId }));

  const resetFormState = () => {
    dispatch(agreementsReducer.emptyAgreementTrashReset({ id: workspaceId }));
  };

  const handleEmptyTrash = () => {
    if (workspaceId) {
      dispatch(agreementsReducer.emptyAgreementTrash({
        id: workspaceId,
        pipe: {
          onSuccess: () => {
            setLoadingState(true);
          },
        },
      }));
    }
  };

  const renderActions = ({ closeConfirmation }: ActionProps) => (
    <div className={style.Actions}>
      <CancelButton onClick={closeConfirmation} />
      <Button
        color="red"
        icon={PermanentDeleteIcon}
        onClick={() => {
          handleEmptyTrash();
        }}
      >
        <Message id="Empty trash" comment="Empty trash button" />
      </Button>
    </div>
  );

  return (
    <RemoveConfirm
      onConfirm={handleEmptyTrash}
      confirmState={removeState}
      resetConfirmState={resetFormState}
      header={(
        <Message
          id="Empty trash?"
          comment="The title of the permanent delete modal"
        />
      )}
      confirmMessage={(
        <>
          <Message
            id="All documents will be permanently deleted and It won’t be possible to restore them."
            comment="The body of the permanent delete modal"
          />
        </>
      )}
      renderButton={renderActions}
      modalKey="delete trashed document modal"
    >
      {children}
    </RemoveConfirm>
  );
};
