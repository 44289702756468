// @flow

import React, {
  useCallback,
  useMemo,
} from 'react';
import ReactDOM from 'react-dom';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { isConcluded } from 'agreement/states';
import agreements from 'reducers/entities/agreements';
import RichTextEditor from 'components/rich-text-editor';
import { ContractPropsProvider } from 'contexts/contract-props';

import ContractLegacyTextEditable from './contract-text-editable/contract-legacy-text-editable';
import { deserializeMarkup } from './deserializer';

import makeWithContractImages from './editor-plugins/with-contract-images';
import withDataFields from './editor-plugins/with-data-fields';
import withAnnotations from './editor-plugins/with-annotations';
import withSuggestions from './editor-plugins/with-suggestions';
import makeWithHtml from './editor-plugins/with-html';
import ContractTextEditable from './contract-text-editable';
import ContractTextEditorToolbar from './contract-text-editor-toolbar';
import { dataFieldsPlugin } from './toolbar-plugins';

type Props = {
  autoFocus?: boolean,
  boxId: number,
  contractId: number,
  legacyMarkup: string,
  nodes: Array<SlateDescendant>,
  onNodesChange: Array<SlateDescendant> => void,
  readOnly: boolean,
};

type GetNodes = (
  nodes: Array<SlateDescendant>,
  legacyMarkup: string,
  readOnly: boolean
) => Array<SlateDescendant>;

export const getNodes: GetNodes = (nodes, legacyMarkup, readOnly) => {
  if (isEmpty(nodes) && !legacyMarkup) {
    if (readOnly) {
      return null;
    }

    return [{ type: 'paragraph', children: [{ text: '' }] }];
  }

  if (!isEmpty(nodes)) {
    return nodes;
  }

  return deserializeMarkup(legacyMarkup);
};

export const ContractTextEditorToolbarPortal = () => {
  const contractEditorToolbarElement = document.getElementById('contract-editor-toolbar');

  if (contractEditorToolbarElement) {
    return ReactDOM.createPortal(
      <ContractTextEditorToolbar />,
      contractEditorToolbarElement,
    );
  }

  return null;
};

const ContractTextEditor = ({
  autoFocus,
  boxId,
  contractId,
  legacyMarkup,
  nodes,
  onNodesChange,
  readOnly,
}: Props) => {
  const agreement = useSelector((state) => (
    agreements.getAgreementSelector(state, { id: contractId })
  ));
  const agreementIsConcluded = isConcluded(agreement);
  const disableLinkPlugin = agreement?.availableOptions?.disableLinkPlugin;
  const plugins = useMemo(() => [
    makeWithHtml({ disableLinkPlugin }),
    makeWithContractImages(contractId),
    withDataFields,
    withAnnotations,
    withSuggestions,
  ], [contractId, disableLinkPlugin]);
  const renderToolbar = useCallback(() => (
    <ContractTextEditorToolbarPortal />
  ), []);
  const renderEditable = useCallback((editableProps) => (
    <ContractTextEditable
      {...editableProps}
      autoFocus={autoFocus}
      contractId={contractId}
      readOnly={readOnly}
    />
  ), [autoFocus, contractId, readOnly]);

  const dataFieldsEnabled = agreement.templateGroup;

  const onChange = useCallback((updatedNodes) => {
    onNodesChange(updatedNodes);
  }, [onNodesChange]);

  const textNodes = getNodes(nodes, legacyMarkup, readOnly);

  if (isEmpty(nodes) && agreementIsConcluded) {
    return (
      <ContractLegacyTextEditable legacyMarkup={legacyMarkup} />
    );
  }

  if (!textNodes) {
    return null;
  }

  const disabledPlugins = [];
  if (!dataFieldsEnabled) {
    disabledPlugins.push(dataFieldsPlugin);
  }

  return (
    <ContractPropsProvider
      boxId={boxId}
      boxType="text-and-image"
      contractId={contractId}
      disabledPlugins={disabledPlugins}
    >
      <RichTextEditor
        nodes={textNodes}
        onNodesChange={onChange}
        plugins={plugins}
        readOnly={readOnly}
        renderEditable={renderEditable}
        renderToolbar={renderToolbar}
      />
    </ContractPropsProvider>
  );
};

ContractTextEditor.defaultProps = {
  autoFocus: undefined,
};

export default ContractTextEditor;
