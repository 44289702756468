// @flow

import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

export const getFoldersByParentId = (
  allFolders,
  parentId,
  includeSubFolders,
) => {
  if (!includeSubFolders) {
    return (
      allFolders.filter((folder) => folder.parentId === parentId)
    );
  }

  if (parentId === null) {
    return allFolders;
  }

  return (
    allFolders.filter((folder) => {
      const parentPath = folder.parentPath.split('/').slice(0, -1).map((path) => Number(path));
      return parentPath.includes(parentId);
    })
  );
};

export const folderNameAlreadyExists = (newFolderName, selectedFolderId, foldersArr) => {
  const parentFolderId = (selectedFolderId !== -1) ? selectedFolderId : null;
  const array = getFoldersByParentId(foldersArr, parentFolderId);

  return array.some((folder) => folder.name.toLowerCase() === newFolderName.toLowerCase());
};

export const generateDuplicateName = (name, selectedFolderId, foldersArr) => {
  if (!foldersArr.length) {
    return name;
  }

  let counter = 2;
  let newName;

  const reg = new RegExp('.*(?:\\D|^)(\\d+)');
  const matchNumber = name.match(reg);

  if (matchNumber) {
    const foundNumber = matchNumber[1];
    const indexFromEnd = matchNumber.index + foundNumber.length;
    const indexFromStart = name.length - indexFromEnd;
    counter = Number.parseInt(foundNumber, 10) + 1;

    newName = name.substring(0, indexFromStart) + counter;
  } else {
    newName = `${name} ${counter}`;
  }

  if (folderNameAlreadyExists(newName, selectedFolderId, foldersArr)) {
    newName = generateDuplicateName(newName, selectedFolderId, foldersArr);
  }

  return newName;
};

export const getRedirectPath = (location: any, contractStates: Array<number>) => {
  let searchParams = location.search.split('&');
  searchParams[0] = searchParams[0].substring(1);
  const folderParamIndex = searchParams.findIndex((param) => param.includes('folderIds'));
  const paginationParamIndex = searchParams.findIndex((param) => param.includes('page'));

  if (contractStates[0] === -1 && folderParamIndex > -1) {
    searchParams.splice(folderParamIndex, 1);
  }

  if (contractStates[0] > -1) {
    if (folderParamIndex > -1) {
      searchParams.splice(
        folderParamIndex,
        1,
        `${queryString.stringify({
          'folderIds[]': contractStates.join(),
        })}`,
      );
    }

    if (searchParams[0] !== '' && folderParamIndex === -1) {
      searchParams.push(`${queryString.stringify({
        'folderIds[]': contractStates.join(),
      })}`);
    }

    if (searchParams[0] === '') {
      searchParams = [`${queryString.stringify({
        'folderIds[]': contractStates.join(),
      })}`];
    }
  }

  if (paginationParamIndex > -1) {
    searchParams.splice(paginationParamIndex, 1);
  }

  return `?${searchParams.join('&')}`;
};

export const getRemoveRedirectPath = (location: any, id: number) => {
  const searchParams = location.search.split('&');
  searchParams[0] = searchParams[0].substring(1);
  const folderParam = searchParams.find((param) => param.includes('folderIds'));
  const folderParamIncludesId = folderParam?.includes(id.toString());

  if (folderParamIncludesId) {
    const folderParamIndex = searchParams.findIndex((param) => param.includes('folderIds'));
    searchParams.splice(folderParamIndex, 1);
  }

  return `?${searchParams.join('&')}`;
};

export const getSubFolders = (folders: Folder[], folderId: number): Folder[] => {
  if (!folderId || isEmpty(folders)) {
    return [];
  }
  return folders.filter((folder) => folder.parentId === folderId);
};
