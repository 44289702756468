// @flow

import * as React from 'react';
import clsx from 'clsx';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import Checkbox from 'components/checkbox';
import Template from 'components/icons/template';
import Search from 'components/icons/search';
import TooltipInfo from 'components/tooltip-info';

import style from './template-item.module.scss';

export type Props = {
  id: number,
  agreementId: number,
  title: string,
  isSelected: boolean,
  onClick: (item: any, state: boolean) => void,
  message: MessageTranslator,
  isFreemiumTooltipVisible?: boolean,
};

export class TemplateItem extends React.Component<Props> {
  static defaultProps = {
    isFreemiumTooltipVisible: undefined,
  }

  getHandleClick = (item: any) => (event: Event) => {
    const { onClick, isSelected } = this.props;

    event.preventDefault();

    const newState = !isSelected;
    onClick(item, newState);
  };

  handleOnKeyDown = () => undefined;

  renderFreemiumTooltip = () => {
    const { message, isFreemiumTooltipVisible, isSelected } = this.props;

    if (!isFreemiumTooltipVisible) {
      return null;
    }

    const infoClasses = clsx(style.Info, {
      [style.InfoSelected]: isSelected,
    });

    return (
      <TooltipInfo
        customClassName={infoClasses}
        side="top"
        message={message({
          id: 'You are on a free plan. Contracts based on this template can be edited but not sent. Upgrade your account to unlock more features.',
          comment: 'Explanatory text for templates that cannot be fully utilized',
        })}
        zIndex="10003"
      />
    );
  };

  render() {
    const {
      title,
      agreementId,
      id,
      isSelected,
    } = this.props;
    const cbConfig = {
      id,
      agreementId,
      title,
    };

    const boxClasses = clsx(style.TemplateItemBox, {
      [style.Selected]: isSelected,
    });

    const titleClasses = clsx(style.TemplateItemTitle, {
      [style.Selected]: isSelected,
    });

    const handleClick = this.getHandleClick(cbConfig);

    return (
      <div className={style.TemplateItem}>
        <div
          className={boxClasses}
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={this.handleOnKeyDown}
        >
          <div className={style.Header}>
            <div className={style.Checkbox}>
              <Checkbox
                input={{
                  checked: isSelected,
                  onChange: handleClick,
                }}
              />
            </div>
            {this.renderFreemiumTooltip()}
          </div>
          <div className={titleClasses}>
            <Template className={style.Icon} height="20px" />
            <span>{title}</span>
          </div>
        </div>
        <div className={style.Preview}>
          <Search className={style.PreviewIcon} height="14px" />
          <span>
            <Message id="Preview" comment="Label for the template preview button on the getting started modal " />
          </span>
        </div>
      </div>
    );
  }
}

export default localize<Props>(TemplateItem);
