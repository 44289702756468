// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { Match } from 'react-router';

import templateGroupsReducer from 'reducers/entities/template-groups';
import { getExtensionsById } from 'reducers/entities/extensions';

import TemplateGroupDetailsComponent, { type Props, type FormData } from './template-group-details';

export const QUERY_NAME = '/admin/account/extensions/template-groups';

type StateProps = {|
  templateGroup: TemplateGroup,
  updateState: UpdateState,
  dateFormat: string,
  extensionsById: {
    [key: number]: Extension,
  },
|};

type DispatchProps = {|
  resetUpdateState: () => void,
  updateTemplateGroup: FormData => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {|
  match: Match,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { match }) => {
  const templateGroup = templateGroupsReducer.getTemplateGroupSelector(state, {
    id: match.params.id,
  });
  const updateState = templateGroupsReducer.getUpdateSelector(state, { id: match.params.id });
  const extensionsById = getExtensionsById(state);

  return ({
    templateGroup,
    updateState,
    extensionsById,
  });
};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { match }) => ({
  resetUpdateState: () => {
    dispatch(templateGroupsReducer.updateTemplateGroupReset({ id: match.params.id }));
  },
  updateTemplateGroup: ({ name, description, active }: FormData) => {
    dispatch(templateGroupsReducer.updateTemplateGroup({
      id: match.params.id,
      data: {
        name,
        description: description || null,
        active,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateGroupDetailsComponent);
