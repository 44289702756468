import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import agreements from 'reducers/entities/agreements';

import { checkAcl } from 'components/acl';
import Confirmable from 'components/confirmable';
import CogIcon from 'components/icons/cog';
import { CancelButton, ConfirmButton } from 'components/buttons';
import Button from 'components/button';
import TextField from 'components/text-field';

import style from './sign-redirect-options.module.scss';

type Props = {
  agreementId: number,
}

export const SignRedirectOptions = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [signRedirectUrl, setSignRedirectUrl] = useState(agreement.config?.signRedirect || '');

  const handleClose = useCallback(() => {
    setSignRedirectUrl(agreement.config?.signRedirect || '');
    dispatch(agreements.updateConfigReset({ id: agreementId }));
  }, [agreementId, dispatch, agreement.config?.signRedirect]);

  const updateConfigState = useSelector((state) => (
    agreements.getUpdateConfigSelector(state, { id: agreementId })
  ));

  const resetUpdateConfigState = useCallback(() => {
    dispatch(agreements.updateConfigReset({ id: agreementId }));
  }, [agreementId, dispatch]);

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
    resetUpdateConfigState();
  }, [setIsOpen, resetUpdateConfigState]);

  const handleConfirm = useCallback(() => {
    dispatch(agreements.updateConfig({
      id: agreementId,
      data: {
        signRedirect: signRedirectUrl || null,
      },
    }));
  }, [agreementId, dispatch, signRedirectUrl]);

  if (!checkAcl(agreement.acl, 'agreement:update:config:sign_redirect')) {
    return null;
  }

  const renderActions = ({ closeConfirmation }: { closeConfirmation: () => void }) => (
    <>
      <CancelButton onClick={closeConfirmation} data-testid="cancel-button" />
      <ConfirmButton
        disabled={updateConfigState.loading}
        isLoading={updateConfigState.loading}
        onClick={handleConfirm}
      />
    </>
  );

  const renderBody = () => (
    <>
      <div className={style.Description}>
        <Message
          id="URL where counterparties will be redirected after signing the document."
          comment="Modal description for sign redirect URL"
        />
      </div>
      <div>
        <TextField
          input={{
            value: signRedirectUrl,
            onChange: (
              e: React.ChangeEvent<HTMLInputElement>,
            ) => setSignRedirectUrl(e.target.value),
          }}
          placeholder="https://example.com/"
          disabled={updateConfigState.loading}
        />
      </div>
    </>
  );

  const getChildren = (onClick: () => void) => (
    <Button onClick={onClick} className={style.Button}>
      <div className={style.OpenModalButton}>
        <CogIcon height="12px" />
        <Message
          id="Customize"
          comment="button for opening sign redirect URL modal"
        />
      </div>
    </Button>
  );

  return (
    <Confirmable
      actions={renderActions}
      header={(
        <Message
          id="Redirect after sign"
          comment="Modal title for setting sign redirect URL"
        />
      )}
      body={renderBody()}
      disabled={updateConfigState.error}
      onConfirm={handleConfirm}
      onEnter={handleConfirm}
      onOpen={handleOpenModal}
      isOpen={isOpen}
      error={updateConfigState.error}
      success={updateConfigState.success}
      isConfirmLoading={updateConfigState.loading}
      onClose={handleClose}
      onCancel={handleClose}
      modalKey="Set sign redirect URL"
      customBodyClass={style.ModalBody}
    >
      {getChildren}
    </Confirmable>
  );
};
