import getFontAssetsDomain from './get-font-asset-domain.js';

const loadCourierPrime = () => {
  const normalCourierPrime = new FontFace(
    'Courier Prime',
    `url(${getFontAssetsDomain()}/resources/fonts/courier-prime/courier-prime_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicCourierPrime = new FontFace(
    'Courier Prime',
    `url(${getFontAssetsDomain()}/resources/fonts/courier-prime/courier-prime_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldCourierPrime = new FontFace(
    'Courier Prime',
    `url(${getFontAssetsDomain()}/resources/fonts/courier-prime/courier-prime_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicCourierPrime = new FontFace(
    'Courier Prime',
    `url(${getFontAssetsDomain()}/resources/fonts/courier-prime/courier-prime_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalCourierPrime, italicCourierPrime, boldCourierPrime, boldItalicCourierPrime];
};

export default loadCourierPrime;
