// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const GroupUpdateNameEvent = ({ auditLogEvent }: Props) => (
  <Message
    id="The group name {newName} was {jsx-start}updated{jsx-end} from {previousName}"
    comment="Audit log event message for added group"
    component="span"
    className={style.Green}
    values={{
      newName: auditLogEvent.information.name.to,
      previousName: auditLogEvent.information.name.from,
    }}
  />
);

export default GroupUpdateNameEvent;
