import isEmpty from 'lodash/isEmpty';

import isDisabled from 'agreement/participant/is-disabled';
import isOrganizer from 'agreement/participant/is-organizer';
import {
  getParticipantParty,
  isPartiallySigned,
  getAgreementSignatories,
  getSignedParticipants,
} from 'agreement/selectors';
import {
  SIGN_METHOD_EID_SIGN,
  SIGN_METHOD_SE_BANKID,
  SIGN_METHOD_NO_BANKID_SIGN,
  SIGN_METHOD_DK_MITID_NEMID_SIGN,
  SIGN_METHOD_FI_FTN_SIGN,
  SIGN_METHOD_SMS,
} from 'agreement/participant/constants';
import hasSigned from 'agreement/participant/has-signed';
import isSignatory from 'agreement/participant/is-signatory';
import { isIndividual } from 'agreement/party';

const editableKeys = ['fullname', 'email', 'phoneNumber', 'ssn', 'type', 'country', 'title', 'deliveryChannel', 'signMethod'];
const noResetKeys = {
  signed: ['email', 'phoneNumber'],
  B2B: ['email', 'phoneNumber', 'fullname', 'title', 'ssn', 'type', 'signMethod', 'deliveryChannel'],
  B2C: ['email', 'phoneNumber', 'type', 'signMethod', 'deliveryChannel'],
};

const bankIdSignMethods = [
  SIGN_METHOD_EID_SIGN,
  SIGN_METHOD_SE_BANKID,
  SIGN_METHOD_NO_BANKID_SIGN,
  SIGN_METHOD_DK_MITID_NEMID_SIGN,
  SIGN_METHOD_FI_FTN_SIGN,
];

type FormData = {
  jobTitle?: string;
  personalIdentification?: string;
  role?: number;
  [key: string]: string | number | undefined;
};

export const hasPropChanged = (
  key: string,
  formData: FormData,
  participant: Oneflow.Participant,
) => {
  if (key === 'title') {
    return (
      formData.jobTitle
      && participant[key as keyof typeof participant] !== formData.jobTitle
    );
  }
  if (key === 'ssn') {
    return (
      formData.personalIdentification
      && participant[key as keyof typeof participant] !== formData.personalIdentification
    );
  }
  if (key === 'type') {
    return participant[key as keyof typeof participant] !== formData.role;
  }
  return (
    participant[key as keyof typeof participant]
    && formData[key]
    && participant[key as keyof typeof participant] !== formData[key]
  );
};

type Args = {
  formData?: FormData;
  agreement: Oneflow.Agreement;
  participant: Oneflow.Participant;
};

export const willTriggerSignatureReset = ({
  formData,
  agreement,
  participant,
}: Args): boolean => {
  if (!isPartiallySigned(agreement)) {
    return false;
  }
  const party = getParticipantParty(agreement, participant.agreementCompany?.id ?? 0);

  const oneSignatureMissing = (
    getAgreementSignatories(agreement).length - getSignedParticipants(agreement).length === 1
  );
  const isLastUndecidedSignatory = isSignatory(participant) && oneSignatureMissing;

  const isRemoveAction = isEmpty(formData);
  if (isRemoveAction) {
    if (hasSigned(participant)) {
      return true;
    }

    if (isIndividual(party)) {
      return true;
    }

    // If all other signatories have signed and we delete the last signatory,
    // the document would get fully signed
    if (isLastUndecidedSignatory) {
      return true;
    }

    // Means that we will remove the whole party
    const hasPartyOneParticipant = party.participants
      ?.filter((_participant) => !isDisabled(_participant))
      .length === 1;

    if (hasPartyOneParticipant) {
      return true;
    }

    return false;
  }

  if (isOrganizer(participant)) {
    return false;
  }

  // If all other signatories have signed and we change role of the last signatory,
  // the document would get fully signed
  if (hasPropChanged('type', formData, participant) && isLastUndecidedSignatory) {
    return true;
  }

  const shouldResetSignatures = (noResetKey: string) => (
    editableKeys.some((key) => {
      const propChanged = hasPropChanged(key, formData, participant);
      const shouldResetKey = (
        propChanged && !noResetKeys[noResetKey as keyof typeof noResetKeys].includes(key)
      );
      return shouldResetKey;
    })
  );

  if (hasSigned(participant)) {
    // If they already signed using SSN, but we update SSN
    if (hasPropChanged('ssn', formData, participant)
      && bankIdSignMethods.includes(participant.signMethod ?? 0)
    ) {
      return true;
    }

    // If they already signed using SMS, but we update phone number
    if (
      hasPropChanged('phoneNumber', formData, participant)
      && participant.signMethod === SIGN_METHOD_SMS
    ) {
      return true;
    }
    return shouldResetSignatures('signed');
  }

  if (isIndividual(party)) {
    return shouldResetSignatures('B2C');
  }

  return shouldResetSignatures('B2B');
};
