import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import omit from 'lodash/omit';
import queryString from 'query-string';
import { Message } from '@oneflowab/pomes';
import { push } from 'connected-react-router';

import usePrevious from 'hooks/use-previous';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import { getAccountFromSessionSelector } from 'reducers/session';
import accountsReducer from 'reducers/entities/accounts';
import plansReducer from 'reducers/entities/plans';
import { getLocationSelector } from 'reducers/router';
import Button from 'components/button';

import type { Billing } from 'types/billing';

import {
  MultistepModal,
  ModalStep,
  ModalStepRenderProps,
} from 'components/modal';

import PlansModal from './plans';
import ChangePlanConfirm from './change-plan-confirm';
import { FreemiumSelfServiceSuccess } from './change-plan-success';

// modalKeys for ampltitude tracking on Exit Modal events
const CHANGE_PLAN_MODAL = 'change plan modal';
const CONFIRM_PLAN_CHANGE_MODAL = 'confirm plan change modal';
const SELF_UPGRADE_COMPLETE_MODAL = 'self upgrade complete modal';

export type Props = {
  billing: Billing;
};

export const UPGRADE_PLAN_QUERY = 'up';

const ChangePlanComponent = ({
  billing,
}: Props) => {
  const [showChangePlan, setShowChangePlan] = useState<boolean>(false);
  const dispatch = useDispatch();
  const accountId = useSelector((state) => getAccountFromSessionSelector(state).id);
  const updateSeatsState = useSelector((state) => accountsReducer.getBuySeatsSelector(state,
    { id: accountId }));
  const location: Location = useSelector(getLocationSelector);
  const prevLocation: Location | null = usePrevious(location);

  const openModalBasedOnLocation = useCallback((previousLocation: Location | null) => {
    const modalQuery = get(location, 'query.m');

    if (modalQuery === get(previousLocation, 'query.m')) {
      return;
    }

    setShowChangePlan(modalQuery === UPGRADE_PLAN_QUERY);
  }, [location]);

  const handleOpenClick = () => {
    setShowChangePlan(true);
    amplitudeLogEvent(
      'Go To Change Plan', {
        location: 'subscription and billing page',
      },
    );
  };

  const updateQueryString = (updatedLocation: Location, updatedQueryString: string) => {
    const newLocation = `${updatedLocation.pathname}?${updatedQueryString}`;
    dispatch(push(newLocation));
  };

  useEffect(() => {
    openModalBasedOnLocation(prevLocation);
  }, [
    openModalBasedOnLocation,
    prevLocation,
  ]);

  const fetchAccount = (id) => {
    dispatch(accountsReducer.fetchAccount({ id }));
  };

  const queryPlans = () => {
    dispatch(plansReducer.queryPlans());
  };

  const handleUpdateQueryString = () => {
    if (get(location, 'query.m') !== UPGRADE_PLAN_QUERY) {
      return;
    }

    updateQueryString(
      location,
      queryString.stringify({
        ...omit(location.query, 'm'),
      }),
    );
  };

  const handleCloseClick = () => {
    setShowChangePlan(false);
    handleUpdateQueryString();
  };

  const handleAfterClose = () => {
    if (updateSeatsState.success) {
      fetchAccount(accountId);
    }
  };

  const handleFinalStep = (onStepComplete: () => void) => () => {
    onStepComplete();
    setShowChangePlan(false);
    handleUpdateQueryString();
  };

  const onOpen = () => {
    queryPlans();
    fetchAccount(accountId);
  };

  const renderPlansModal: ModalStepRenderProps = ({
    onStepComplete,
    onSyncStepData,
    modalData,
  }) => (
    <PlansModal
      onClose={handleCloseClick}
      onStepComplete={onStepComplete}
      onSyncStepData={onSyncStepData}
      modalData={modalData}
      modalKey={CHANGE_PLAN_MODAL}
    />
  );

  const renderConfirmModal: ModalStepRenderProps = ({
    onStepComplete,
    modalData,
  }) => (
    <ChangePlanConfirm
      onClose={handleCloseClick}
      onStepComplete={onStepComplete}
      modalData={modalData}
      accountId={accountId}
      billing={billing}
      modalKey={CONFIRM_PLAN_CHANGE_MODAL}
    />
  );

  const renderSuccessModal: ModalStepRenderProps = ({
    onStepComplete,
    modalData,
  }) => (
    <FreemiumSelfServiceSuccess
      onClose={handleFinalStep(onStepComplete)}
      modalData={modalData}
      modalKey={SELF_UPGRADE_COMPLETE_MODAL}
    />
  );

  return (
    <>
      {showChangePlan && (
        <MultistepModal
          isOpen={showChangePlan}
          onCancel={handleCloseClick}
          onAfterClose={handleAfterClose}
          onOpen={onOpen}
          isMediumWideModal
        >
          <ModalStep modalKey={CHANGE_PLAN_MODAL}>
            {renderPlansModal}
          </ModalStep>
          <ModalStep modalKey={CONFIRM_PLAN_CHANGE_MODAL}>
            {renderConfirmModal}
          </ModalStep>
          <ModalStep modalKey={SELF_UPGRADE_COMPLETE_MODAL}>
            {renderSuccessModal}
          </ModalStep>
        </MultistepModal>
      )}
      <Button
        kind="secondary"
        color="forest-green"
        onClick={handleOpenClick}
      >
        <Message
          id="Change plan"
          comment="Button to change the subscription plan"
        />
      </Button>
    </>
  );
};

export default ChangePlanComponent;
