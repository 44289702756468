import { Message } from '@oneflowab/pomes';
import FrequencyOptions from './frequency-options';

export type Props = {
  setHowOften: (value: string) => void;
};

const DocumentFrequency = ({
  setHowOften,
}: Props) => (
  <>
    <p>
      <Message
        id="How often do you use Oneflow?"
        comment="Question to ask user how often they use Oneflow"
      />
    </p>
    <FrequencyOptions
      setHowOften={setHowOften}
    />
  </>
);

export default DocumentFrequency;
