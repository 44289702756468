import {
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { localize } from '@oneflowab/pomes';
import { debounce } from 'lodash';
import type { ChangeEvent } from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';

import { useDataFieldsTabProps } from 'contexts/data-fields-tab-props';
import type { DataField } from 'data-validators/entity-schemas/agreement-data';

import TextField from 'components/text-field';
import SearchIcon from 'components/icons/search';

import style from './data-fields-filter.module.scss';

const isStringMatchingSearchTerm = (value: string | undefined, searchTerm: string) => value
  && value.toString().toLowerCase().includes(searchTerm);

const isDataFieldMatchingSearchTerm = (
  item: DataField,
  searchTerm: string,
) => (
  isStringMatchingSearchTerm(item?.value?.value, searchTerm)
  || isStringMatchingSearchTerm(item?.value?.name, searchTerm)
);

const filterDataFields = (data: { [id: string]: DataField }, searchTerm: string): string[] => {
  const lowerCasedSearchTerm = searchTerm.toLowerCase();

  return Object.keys(data)
    .filter((id) => isDataFieldMatchingSearchTerm(data[id], lowerCasedSearchTerm));
};

type Props = {
  message: MessageTranslator,
  setShowEmptyState: Dispatch<SetStateAction<boolean>>,
  setSearchTerm: Dispatch<SetStateAction<string>>,
  searchTerm: string,
};

const DataFieldsFilter = ({
  message,
  setShowEmptyState,
  setSearchTerm,
  searchTerm,
}: Props) => {
  const {
    allDataFields,
    setVisibleDataFieldIds,
  } = useDataFieldsTabProps();
  const debouncedSetDataFields = useMemo(
    () => debounce(setVisibleDataFieldIds, 100), [setVisibleDataFieldIds],
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    setSearchTerm(searchQuery);

    const filteredDataFields = filterDataFields(allDataFields, searchQuery);
    debouncedSetDataFields(() => filteredDataFields);

    setShowEmptyState(searchQuery ? filteredDataFields.length === 0 : false);
  }, [allDataFields, debouncedSetDataFields, setShowEmptyState, setSearchTerm]);

  const inputProps = useMemo(() => ({
    value: searchTerm,
    onChange: handleSearch,
  }), [searchTerm, handleSearch]);

  return (
    <TextField
      customClass={style.SearchField}
      icon={<SearchIcon width="18px" className={style.SearchFieldIcon} />}
      input={inputProps}
      placeholder={message({ id: 'Search', comment: 'Placeholder for search bar' })}
      name="search"
      noErrorMessage
      clearable
      lengthLimit={1024}
    />
  );
};

// To overwrite incorrect pomes type hints
const LocalizedDataFieldsFilter = localize<Props>(
  DataFieldsFilter,
) as unknown as React.FunctionComponent<Omit<Props, 'message'>>;

export default LocalizedDataFieldsFilter;
