// @flow

import { normalize, schema } from 'normalizr';

import {
  get,
  remove,
  post,
  extractResponseBodyAsJSON,
  put,
} from 'oneflow-client/core';

const foldersSchema = new schema.Entity('folders');

export type NormalizedFolders = {
  entities: {
    folders: Array<Folder>,
  },
  count: number,
};

type FoldersNormaliser = (any) => NormalizedFolders;

export const normalizeFolders: FoldersNormaliser = (folders) => ({
  ...normalize(folders.collection, [foldersSchema]),
  count: folders.count,
});

const normalizeFolder = (folder) => ({
  ...normalize(folder, foldersSchema),
  count: 1,
});

type GetFolders = ({ params: Object }) => Promise<NormalizedFolders>;

export const getFolders: GetFolders = ({ params }) => (
  get({
    url: '/folders/',
    params: {
      workspace_ids: [params.workspaceId],
      q: params.q,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeFolders)
);

type CreateFolder = ({
  name: string,
  workspaceId: number,
  parentId: number,
}) => Promise<NormalizedFolders>;

export const createFolder: CreateFolder = ({
  name, parentId, workspaceId,
}) => (
  post({
    url: '/folders/',
    body: {
      name,
      workspace_id: workspaceId,
      parent_id: parentId,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeFolder)
);

type DeleteFolder = ({
  id: number,
}) => Promise<{}>;

export const deleteFolder: DeleteFolder = ({ id }) => (
  remove({ url: `/folders/${id}` })
    .then(extractResponseBodyAsJSON)
);

type UpdateFolder = ({
  id: number,
  name: string,
}) => Promise<{}>;

export const updateFolder: UpdateFolder = ({
  id, name,
}) => (
  put({
    url: `/folders/${id}`,
    body: {
      name,
    },
  })
    .then(extractResponseBodyAsJSON)
);
