// @flow

import * as React from 'react';

import { localize, Message } from '@oneflowab/pomes';
import ModalForm from 'hocs/modal-form';

import { TagNameField } from 'components/fields';

type Props = {
  onSubmit: ({name: string}) => void,
  formState: RpcState,
  resetFormState: () => void,
  children: React.Node,
};

export const AddTagFields = ({
  onSubmit,
  formState,
  resetFormState,
  children,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Create tag"
        comment="Modal title for creating a new tag"
      />
    )}
    body={(
      <div>
        <TagNameField autoFocus />
      </div>
    )}
    onSubmit={onSubmit}
    resetFormState={resetFormState}
    formState={formState}
    modalKey="create tag modal"
  >
    {children}
  </ModalForm>
);

export default localize<Props>(AddTagFields);
