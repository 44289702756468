import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Message } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import rolesReducer from 'reducers/entities/roles';
import workspaceGroupsReducer from 'reducers/entities/workspace-groups';
import workspacesReducer from 'reducers/entities/workspaces';
import { USER_ROLE_SCOPE_WORKSPACE } from 'user';

import SelectField from 'components/select-field';
import Field from 'components/field';

const WORKSPACES_QUERY_NAME = 'admin/workspaces';
const rolesQueryName = 'grant-group-workspace-access/roles';
const defaultPagination = {
  limit: 1000,
  offset: 0,
};

export type AvailableWorkspace = {
  id: number,
  name: string,
}

export type FormData = {
  workspaces: Oneflow.Workspace[],
  role: Oneflow.Role,
}

export type QueryFuncArgs = {
  name: string,
  pagination: typeof defaultPagination,
}

export type LoadMoreArgs = {
  additionalResults: number
}

export type Props = {
  children: React.ReactNode,
  onSuccess: () => void,
  group: Oneflow.Group,
  shouldRedirectToWorkspaceAccess?: boolean,
}

export const GrantGroupWorkspaceAccess = ({
  children,
  onSuccess,
  group,
  shouldRedirectToWorkspaceAccess,
}: Props) => {
  const dispatch = useDispatch();

  const workspacesQuery = useSelector((state) => workspacesReducer.getQuerySelector(state, {
    name: WORKSPACES_QUERY_NAME,
  }));
  const workspaces = useSelector((state) => workspacesReducer.getWorkspacesSelector(state, {
    ids: workspacesQuery.result,
  }));
  const rolesQuery = useSelector(
    (state) => rolesReducer.getQuerySelector(state, { name: rolesQueryName }),
  );
  const roles = useSelector(
    (state) => rolesReducer.getRolesSelector(state, { ids: rolesQuery.result }),
  );
  const formState = useSelector(
    (state) => workspaceGroupsReducer.getAssignGroupWorkspacesSelector(state, { id: group.id }),
  );

  const resetFormState = () => {
    dispatch(workspaceGroupsReducer.assignGroupWorkspacesReset({ id: group.id }));
  };

  const onSubmit = ({ workspaces: selectedWorkspaces, role }: FormData) => {
    const workspaceIds = selectedWorkspaces.map((workspace) => workspace.id);

    dispatch(workspaceGroupsReducer.assignGroupWorkspaces({
      data: {
        actorId: group.id,
        roleId: role.id,
        workspaceIds,
      },
      id: group.id,
      pipe: {
        action: (shouldRedirectToWorkspaceAccess && workspaces.length === 1)
          ? () => push(`/admin/groups/${group.id}/workspace-access`)
          : undefined,
        onSuccess,
      },
    }));
  };

  const queryRoles = ({ name, pagination = defaultPagination }: QueryFuncArgs) => {
    dispatch(rolesReducer.queryRoles({
      name: rolesQueryName,
      params: {
        q: name,
        scope: USER_ROLE_SCOPE_WORKSPACE,
      },
      pagination,
      sort: ['-type', 'name'],
    }));
  };
  const queryRolesLoadMore = (additionalResults: number) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: rolesQueryName,
      additionalResults,
    }));
  };
  const queryWorkspaces = ({ pagination = defaultPagination }) => {
    dispatch(workspacesReducer.queryWorkspaces({
      name: WORKSPACES_QUERY_NAME,
      pagination,
      params: {
        workspaceAdmin: 1,
        canBind: 1,
      },
    }));
  };
  const queryWorkspacesLoadMore = (additionalResults: number) => {
    dispatch(workspacesReducer.queryWorkspacesLoadMore({
      name: WORKSPACES_QUERY_NAME,
      additionalResults,
    }));
  };

  const runInitialQueries = () => {
    queryWorkspaces({});
    queryRoles({});
  };

  const renderBody = () => (
    <div>
      <Field
        name="workspaces"
        label={(
          <Message
            id="Workspace"
            comment="Input label"
          />
        )}
        placeholder={(
          <Message
            id="Select workspace"
            comment="Input field placeholder"
          />
        )}
        component={SelectField}
        options={workspaces}
        valueKey="id"
        labelKey="name"
        isLoading={workspacesQuery.loading}
        loadMoreItems={queryWorkspacesLoadMore}
        required
        multi
      />
      <Field
        name="role"
        label={(
          <Message
            id="Role"
            comment="Input label of the role field"
          />
        )}
        placeholder={(
          <Message
            id="Select role"
            comment="Input field placeholder of the role field"
          />
        )}
        component={SelectField}
        options={roles}
        valueKey="id"
        labelKey="name"
        backspaceRemoves
        isLoading={rolesQuery.loading}
        loadMoreItems={queryRolesLoadMore}
        required
      />
    </div>
  );

  return (
    <ModalForm
      title={(
        <Message
          id="Grant workspace access"
          comment="Modal title for granting workspace access"
        />
      )}
      body={renderBody()}
      onSubmit={onSubmit}
      resetFormState={resetFormState}
      formState={formState}
      onOpen={runInitialQueries}
      modalKey="grant workspace access group modal"
    >
      {children}
    </ModalForm>
  );
};

export default GrantGroupWorkspaceAccess;
