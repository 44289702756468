// @flow

import omit from 'lodash/omit';
import { normalize, schema } from 'normalizr';
import { decamelizeKeys } from 'humps';

import {
  extractResponseBodyAsJSON,
  getMultiple,
  post,
  type SortParams,
} from 'oneflow-client/core';
import { bindingSchema } from 'oneflow-client/schema';

export type NormalizedBindings = {
  bindings: {
    [number]: Binding,
  },
};

export const workspaceUsersSchema = new schema.Entity('workspaceUsers');

const normalizeWorkspaceUsers = (userData) => ({
  ...normalize(userData.collection, [workspaceUsersSchema]),
  count: userData.count,
});

type BindingsNormalizer = (any) => NormalizedBindings;

const normalizeBindings: BindingsNormalizer = (data) => (
  normalize(data.bindings, [bindingSchema])
);

type GetWorkspaceUsers = ({
  params: {
    workspaceId: number,
    [string]: any,
  },
  pagination?: {},
  sorting?: SortParams,
}) => Promise<any>;

// eslint-disable-next-line import/prefer-default-export
export const getWorkspaceUsers: GetWorkspaceUsers = ({
  pagination,
  params,
  sorting = { attr: 'fullname', direction: 'asc' },
}) => (
  getMultiple({
    url: `/collections/${params.workspaceId}/bindings/`,
    params: decamelizeKeys(omit(params, 'workspaceId'), { separator: '_' }),
    pagination,
    sorting,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeWorkspaceUsers)
);

type AssignUserWorkspaces = ({
  positionId: number,
  roleId: number,
  workspaceIds: Array<number>
}) => Promise<NormalizedBindings>;

// eslint-disable-next-line import/prefer-default-export
export const assignUserWorkspaces: AssignUserWorkspaces = ({
  positionId,
  roleId,
  workspaceIds,
}) => post({
  url: `/collections/positions/${positionId}/bindings/`,
  body: {
    role_id: roleId,
    workspace_ids: workspaceIds,
  },
})
  .then(extractResponseBodyAsJSON)
  .then(normalizeBindings);
