import Svg from 'components/svg';

const NlIdin = Svg((
  <>
    <defs>
      <clipPath id="clip1">
        <path d="M 0 0 L 604.398438 0 L 604.398438 526.980469 L 0 526.980469 Z M 0 0 " />
      </clipPath>
    </defs>
    <g id="surface1">
      <path
        style={{
          stroke: 'none', fillRule: 'nonzero', fill: 'rgb(0.799561%,0.799561%,0.799561%)', fillOpacity: 1,
        }}
        d="M 164.03125 457.445313 L 67.179688 457.445313 L 67.179688 296.628906 L 164.03125 296.628906 L 164.03125 457.445313 "
      />
      <path
        style={{
          stroke: 'none', fillRule: 'nonzero', fill: 'rgb(0.799561%,0.799561%,0.799561%)', fillOpacity: 1,
        }}
        d="M 171.34375 216.222656 C 171.34375 246.5 146.796875 271.058594 116.523438 271.058594 C 86.246094 271.058594 61.699219 246.5 61.699219 216.222656 C 61.699219 185.945313 86.246094 161.398438 116.523438 161.398438 C 146.796875 161.398438 171.34375 185.945313 171.34375 216.222656 "
      />
      <g clipPath="url(#clip1)" clipRule="nonzero">
        <path
          style={{
            stroke: 'none', fillRule: 'nonzero', fill: 'rgb(0.799561%,0.799561%,0.799561%)', fillOpacity: 1,
          }}
          d="M 340.910156 35.003906 C 408.253906 35.003906 464.378906 53.25 503.222656 87.769531 C 547.132813 126.792969 569.394531 185.914063 569.394531 263.492188 C 569.394531 417.238281 494.65625 491.980469 340.910156 491.980469 C 328.960938 491.980469 65.726563 491.980469 35 491.980469 C 35 460.625 35 66.355469 35 35.003906 C 65.726563 35.003906 328.960938 35.003906 340.910156 35.003906 Z M 347.484375 0.00390625 L 0 0.00390625 L 0 526.980469 L 347.484375 526.980469 L 347.484375 526.878906 C 423.386719 525.847656 483.515625 506.988281 526.1875 470.6875 C 578.082031 426.535156 604.394531 356.824219 604.394531 263.492188 C 604.394531 218.878906 597.476563 178.859375 583.824219 144.550781 C 570.769531 111.730469 551.472656 83.824219 526.472656 61.605469 C 482.445313 22.480469 420.640625 1.320313 347.484375 0.121094 C 347.484375 0.117188 347.484375 0.00390625 347.484375 0.00390625 "
        />
      </g>
      <path
        style={{
          stroke: 'none', fillRule: 'nonzero', fill: 'rgb(14.898682%,68.199158%,89.399719%)', fillOpacity: 1,
        }}
        d="M 331.347656 457.625 L 208.5 457.625 L 208.5 77.613281 L 331.347656 77.613281 L 326.390625 77.613281 C 428.84375 77.613281 537.894531 118.046875 537.894531 268.117188 C 537.894531 426.769531 428.84375 457.625 326.390625 457.625 L 331.347656 457.625 "
      />
      <path
        style={{
          stroke: 'none', fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: 1,
        }}
        d="M 205.648438 194.117188 L 205.648438 250.46875 L 213.519531 250.46875 C 220.230469 250.46875 225.003906 249.730469 227.839844 248.25 C 230.675781 246.773438 232.894531 244.195313 234.503906 240.511719 C 236.109375 236.832031 236.914063 230.863281 236.914063 222.609375 C 236.914063 211.683594 235.117188 204.203125 231.527344 200.167969 C 227.933594 196.136719 221.976563 194.117188 213.660156 194.117188 Z M 173.535156 170.765625 L 221.246094 170.765625 C 230.648438 170.765625 238.25 172.03125 244.039063 174.5625 C 249.828125 177.09375 254.613281 180.730469 258.394531 185.464844 C 262.175781 190.203125 264.917969 195.714844 266.617188 201.996094 C 268.320313 208.28125 269.171875 214.941406 269.171875 221.976563 C 269.171875 232.996094 267.90625 241.546875 265.378906 247.617188 C 262.847656 253.691406 259.339844 258.78125 254.851563 262.882813 C 250.359375 266.988281 245.539063 269.71875 240.386719 271.078125 C 233.34375 272.953125 226.964844 273.894531 221.246094 273.894531 L 173.535156 273.894531 L 173.535156 170.765625 "
      />
      <path
        style={{
          stroke: 'none', fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: 1,
        }}
        d="M 287.320313 170.765625 L 319.503906 170.765625 L 319.503906 273.894531 L 287.320313 273.894531 L 287.320313 170.765625 "
      />
      <path
        style={{
          stroke: 'none', fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: 1,
        }}
        d="M 342.757813 170.765625 L 372.746094 170.765625 L 411.878906 227.820313 L 411.878906 170.765625 L 442.152344 170.765625 L 442.152344 273.894531 L 411.878906 273.894531 L 372.960938 217.269531 L 372.960938 273.894531 L 342.757813 273.894531 L 342.757813 170.765625 "
      />
    </g>
  </>
), {
  viewBox: '0 0 604.4 526.98',
  fill: '#0060e6',
});

NlIdin.displayName = 'NlIdin';

export default NlIdin;
