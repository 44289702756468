import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';

import { getCurrentContractData } from 'reducers/current-contract';
import { getCurrentLanguageSelector } from 'reducers/i18n';

type CurrentContractDataFields = {
  [id: string]: DataField,
};
type SortOrder = 'asc' | 'desc';

type DataFieldsTabProps = {
  allDataFields: CurrentContractDataFields,
  visibleDataFieldIds: string[],
  setVisibleDataFieldIds: Dispatch<SetStateAction<string[]>>,
  sortOrder: SortOrder,
  setSortOrder: Dispatch<SetStateAction<SortOrder>>,
};

export const DataFieldsTabPropsContext = createContext<DataFieldsTabProps | null>(null);

type Props = {
  children: ReactNode,
};

const sortVisibleDataFieldByName = (
  allDataFields: CurrentContractDataFields,
  visibleDataFieldIds: string[],
  currentLanguage: string,
) => {
  const collator = new Intl.Collator(currentLanguage, {
    numeric: true,
    sensitivity: 'variant',
  });

  const compare = (a: string, b: string) => {
    const nameA = allDataFields[a]?.value?.name || '';
    const nameB = allDataFields[b]?.value?.name || '';

    return collator.compare(nameA, nameB);
  };

  const sortedIds = visibleDataFieldIds.sort(compare);

  return sortedIds;
};

export function DataFieldsTabProvider({
  children,
}: Props) {
  const { data: allDataFields } = useSelector(getCurrentContractData);
  const currentLanguage = useSelector(getCurrentLanguageSelector);
  const [visibleDataFieldIds, setVisibleDataFieldIds] = useState<string[]>([]);

  useEffect(() => {
    if (!allDataFields) {
      return;
    }

    setVisibleDataFieldIds(Object.keys(allDataFields));
  }, [allDataFields]);

  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const sortedVisibleDataFieldIds = useMemo(() => {
    if (!allDataFields) {
      return [];
    }

    const sortedIds = sortVisibleDataFieldByName(
      allDataFields,
      visibleDataFieldIds,
      currentLanguage,
    );

    if (sortOrder === 'asc') {
      sortedIds.reverse();
    }

    return sortedIds;
  }, [allDataFields, currentLanguage, sortOrder, visibleDataFieldIds]);

  const contextValue = useMemo(() => ({
    allDataFields,
    visibleDataFieldIds: sortedVisibleDataFieldIds,
    setVisibleDataFieldIds,
    sortOrder,
    setSortOrder,
  }), [
    allDataFields,
    sortedVisibleDataFieldIds,
    sortOrder,
  ]);

  return (
    <DataFieldsTabPropsContext.Provider value={contextValue}>
      {children}
    </DataFieldsTabPropsContext.Provider>
  );
}

export const useDataFieldsTabProps = (): DataFieldsTabProps => {
  const contextValue = useContext(DataFieldsTabPropsContext);

  if (!contextValue) {
    throw new Error('useDataFieldsTabProps should be used inside a DataFieldsTabPropsContext');
  }

  return contextValue;
};
