import { useState } from 'react';
import Message from 'components/message';
import CancelSubscriptionFlow from 'components/cancel-subscription/flows/cancel-subscription-flow';
import SubscriptionCancelled from 'components/modals/cancellation/subscription-cancelled';

import type { FlowData } from 'types/cancellation-flow';

import FreePlan from 'components/modals/cancellation/free-plan';

type Props = {
  onClose: () => void;
  onPreviousStep: () => void;
  setCurrentFlow: (flow: string) => void;
  onSyncFlowData: (data: FlowData) => void;
  onSubmit: (
    data: FlowData,
    submitHandler: () => void,
    submitErrorHandler: () => void,
  ) => void;
};

const GetFreePlan = ({
  onClose,
  onPreviousStep,
  setCurrentFlow,
  onSyncFlowData,
  onSubmit,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const renderError = () => {
    if (!hasError) {
      return null;
    }

    return (
      <CancelSubscriptionFlow.SubmitError />
    );
  };

  return (
    <CancelSubscriptionFlow
      onCancel={onClose}
      setFlow={setCurrentFlow}
    >
      <CancelSubscriptionFlow.Steps>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="Oneflow's Free plan"
              comment="Header in the free plan modal"
            />
          )}
          handlePreviousStep={onPreviousStep}
          allowContinue={!isLoading}
        >
          <CancelSubscriptionFlow.StepBody>
            <FreePlan />
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            {renderError()}
            <CancelSubscriptionFlow.ShowFlow
              kind="linkSeparate"
              flow="confirm_cancel_decline_free_plan"
            >
              <Message
                id="No thanks, continue to cancel"
                comment="Button text to continue with the cancellation"
              />
            </CancelSubscriptionFlow.ShowFlow>
            <CancelSubscriptionFlow.Submit
              kind="primary"
              onSubmit={
                (modalData, stepCompleteHandler: () => void) => {
                  setIsLoading(true);
                  setHasError(false);
                  const submitDoneHandler = () => {
                    setIsLoading(false);
                    stepCompleteHandler();
                  };
                  const submitErrorHandler = () => {
                    setIsLoading(false);
                    setHasError(true);
                  };
                  onSubmit(modalData, submitDoneHandler, submitErrorHandler);
                }
              }
              syncData={{ switch_to_free_plan: true, message: '', chosen_alternative: '' }}
              onSyncFlowData={onSyncFlowData}
            >
              <Message
                id="Get Oneflow's Free Plan"
                comment="Button text getting the free plan"
              />
            </CancelSubscriptionFlow.Submit>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="Oneflow's Free plan"
              comment="Header in the free plan modal"
            />
          )}
          hidePrevious
        >
          <CancelSubscriptionFlow.StepBody>
            <SubscriptionCancelled freePlan />
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            <CancelSubscriptionFlow.Close
              kind="primary"
            >
              <Message
                id="Close"
                comment="Label in the free plan modal"
              />
            </CancelSubscriptionFlow.Close>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
      </CancelSubscriptionFlow.Steps>
    </CancelSubscriptionFlow>
  );
};

export default GetFreePlan;
