import { connect } from 'react-redux';

import positionMessageTemplates from 'reducers/entities/position-message-templates';
import { RemoveMessageTemplate } from './remove-message-template';

const QUERY_NAME = 'user/templates';

export const mapStateToProps = (state, { template }) => ({
  removeState: positionMessageTemplates.getRemoveSelector(state, { id: template.id }),
});

export const mapDispatchToProps = (dispatch, { template }) => ({
  removeTemplate: () => {
    dispatch(positionMessageTemplates.removePositionMessageTemplate({
      id: template.id,
      data: {
        ids: [template.id],
      },
      pipe: {
        action: () => positionMessageTemplates.queryPositionMessageTemplatesReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveMessageTemplate);
