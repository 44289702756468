// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import {
  WebhookUrlField,
  WebhookSignKeyField,
  WebhookFormat,
} from 'components/fields';
import WebhookSignature from 'components/webhook-signature';
import WebhookUrlResponse from 'components/webhook-url-response';

export type Props = {
  webhook: Webhook,
  updateState: UpdateState,
  updateWebhook: ({ url: string, imwProxy: boolean, signKey?: string }) => void,
  resetUpdateState: () => void,
  verifyWebhookUrl: string => void,
  children: () => void,
};

export const EditWebhookModal = ({
  webhook,
  updateState,
  updateWebhook,
  resetUpdateState,
  verifyWebhookUrl,
  children,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Edit webhook"
        comment="Modal title for editing webhook."
      />
    )}
    body={(
      <div>
        <WebhookUrlField autoFocus verifyWebhookUrl={verifyWebhookUrl} />
        <WebhookUrlResponse webhookId={webhook.id} />
        <WebhookSignature />
        <WebhookSignKeyField />
        <WebhookFormat />
      </div>
    )}
    initialValues={{
      url: webhook.url,
      imwProxy: webhook.imwProxy,
      signKey: webhook.signKey,
    }}
    onSubmit={updateWebhook}
    resetFormState={resetUpdateState}
    formState={updateState}
    modalKey="edit webhook modal"
  >
    {children}
  </ModalForm>
);
