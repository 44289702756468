// @flow
import React from 'react';
import clsx from 'clsx';

import { FadeIn } from 'components/transitions';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Button from 'components/button/button';
import GiftIcon from 'components/icons/gift';
import LogoutIcon from 'components/icons/logout';
import QuestionCircleIcon from 'components/icons/question-circle';
import GraduationCapIcon from 'components/icons/graduation-cap';
import WebIcon from 'components/icons/web';
import ProfileIcon from 'components/icons/profile-thin';
import SidebarTooltip from 'components/sidebar/sidebar-tooltip';
import UserCircleSingleIcon from 'components/icons/user-circle-single';
import { getUserInitials } from 'user';
import { getHelpCenterBaseUrl } from 'utils/help-center';

import useOutsideClick from 'hooks/use-outside-click';

import MyProfileItem from './my-profile-item';
import style from './my-profile.module.scss';

type Props = {
  expanded?: boolean,
  isContractView?: boolean,
  isMobile?: boolean,
  mobileMenuOpen?: boolean,
  logout: () => void,
  message: MessageTranslator,
  position: Position,
  toggleMobileMenu: () => void,
  updatesCount: number,
};

export function MyProfileComponent(props: Props) {
  const {
    expanded,
    isContractView,
    isMobile,
    logout,
    message,
    mobileMenuOpen,
    position,
    toggleMobileMenu,
    updatesCount,
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const dropdownTriggerRef = React.useRef();

  const closeDropdown = React.useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useOutsideClick(dropdownTriggerRef, closeDropdown, isDropdownOpen);

  const renderInitials = () => {
    const { fullname } = position;
    const initials = getUserInitials(fullname);

    return initials.length > 0 ? initials : <ProfileIcon height="44px" />;
  };

  const renderUpdatesCount = () => {
    if (updatesCount > 0) {
      if (isMobile && !mobileMenuOpen) {
        return null;
      }

      if (isMobile && mobileMenuOpen) {
        return (
          <FadeIn in timeout={0} unmountOnExit={false} transitionTime={500}>
            <div className={style.EmptyCircle}>
              {updatesCount}
            </div>
          </FadeIn>
        );
      }

      return (
        <div className={style.EmptyCircle}>
          {updatesCount}
        </div>
      );
    }
    return null;
  };

  const renderDropdown = () => {
    if (!isDropdownOpen) {
      return null;
    }

    const dropDownClasses = clsx(style.DropdownResponsive, {
      [style.Expanded]: expanded,
      [style.Mobile]: isMobile,
    });

    const arrowClasses = clsx(style.ArrowResponsive, {
      [style.ArrowMobile]: isMobile,
    });

    return (
      <div
        className={dropDownClasses}
        onClick={closeDropdown}
        aria-hidden="true"
      >
        <span className={arrowClasses} />
        <div className={style.Header}>
          <div className={style.UserAreaResponsive}>
            <span className={style.User}>
              {position.fullname}
            </span>
            <span className={style.UserEmail}>
              {position.email}
            </span>
          </div>
        </div>
        <div className={style.Menu}>
          <MyProfileItem
            link="/user/profile"
            label={message({
              id: 'My profile',
              comment: 'Option of my profile popover',
            })}
            IconComponent={UserCircleSingleIcon}
            data-testid="my-profile"
            isContractView={isContractView}
            onClick={toggleMobileMenu}
            trackable={{
              name: 'Go To My Profile',
              props: {
                location: 'Main navigation',
              },
            }}
          />
          <MyProfileItem
            link={getHelpCenterBaseUrl()}
            label={message({
              id: 'Help center',
              comment: 'Option of my profile popover',
            })}
            IconComponent={QuestionCircleIcon}
            data-testid="help-center"
            isContractView={isContractView}
            isExternal
            onClick={toggleMobileMenu}
            trackable={{
              name: 'Open Help center',
              props: {
                location: 'Main navigation',
              },
            }}
          />
          <MyProfileItem
            link="https://oneflow.com/product-updates/"
            label={message({
              id: 'Product updates',
              comment: 'Option of my profile popover',
            })}
            IconComponent={GiftIcon}
            data-testid="updates"
            isContractView={isContractView}
            isExternal
            onClick={toggleMobileMenu}
            trackable={{
              name: 'Open Product Updates',
              props: {
                location: 'Main navigation',
              },
            }}
          />
          <MyProfileItem
            link="https://oneflow.com/academy/"
            label="Oneflow Academy"
            IconComponent={GraduationCapIcon}
            data-testid="blog"
            isContractView={isContractView}
            isExternal
            onClick={toggleMobileMenu}
            trackable={{
              name: 'Open Academy',
              props: {
                location: 'Main navigation',
              },
            }}
          />
          <MyProfileItem
            link="https://status.oneflow.com"
            label={message({
              id: 'Oneflow status',
              comment: 'Option of my profile popover',
            })}
            IconComponent={WebIcon}
            data-testid="status"
            isContractView={isContractView}
            isExternal
            onClick={toggleMobileMenu}
            trackable={{
              name: 'Open Oneflow Status',
              props: {
                location: 'Main navigation',
              },
            }}
          />
          <div className={style.Divider} />
          <MyProfileItem
            onClick={logout}
            label={message({
              id: 'Log out',
              comment: 'Option of my profile popover',
            })}
            IconComponent={LogoutIcon}
          />
        </div>
      </div>
    );
  };

  const responsiveMyProfileClasses = clsx(style.MyProfileResponsive, {
    [style.Mobile]: isMobile,
  });

  if (isMobile || expanded) {
    return (
      <div
        aria-hidden="true"
        className={responsiveMyProfileClasses}
        onClick={toggleDropdown}
        onKeyDown={closeDropdown}
        ref={dropdownTriggerRef}
      >
        <div className={style.MyProfileButton} aria-labelledby="sidebar-option-label-my-profile">
          {renderUpdatesCount()}
          <Button
            customClass={style.ButtonResponsive}
            kind="round"
            color="transparent"
          >
            {renderInitials()}
          </Button>
          <FadeIn in timeout={0} transitionTime={500}>
            <div className={style.TextWrapper}>
              <span className={style.Fullname}>{position.fullname}</span>
              <span className={style.MyProfileButtonText} id="sidebar-option-label-my-profile">
                {message({
                  id: 'My profile',
                  comment: 'Option of my profile dropdown, navigates to the current user profile page',
                })}
              </span>
            </div>
          </FadeIn>
        </div>
        {renderDropdown()}
      </div>
    );
  }

  return (
    <div
      className={style.MyProfileResponsive}
      ref={dropdownTriggerRef}
    >
      <SidebarTooltip
        sideOffset={15}
        tooltipText={message({
          id: 'My profile',
          comment: 'Tooltip for sidebar My profile link',
        })}
      >
        <div>
          {renderUpdatesCount()}
          <Button
            aria-label={message({
              id: 'My profile',
              comment: 'Tooltip for sidebar My profile link',
            })}
            customClass={style.ButtonResponsive}
            onClick={toggleDropdown}
            kind="round"
            color="transparent"
          >
            {renderInitials()}
          </Button>
        </div>
      </SidebarTooltip>
      {renderDropdown()}
    </div>
  );
}

export default localize<Props>(MyProfileComponent);
