// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import { noop, isEmpty } from 'lodash';
import type { FormRenderProps } from 'react-final-form';

import { getContractMessageTemplates } from 'oneflow-client/message-templates';
import {
  getAgreementMyParticipant,
  getParticipantParty,
} from 'agreement/selectors';
import ModalForm from 'hocs/modal-form';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import CircularSpinner from 'components/icons/circular-spinner';
import SendIcon from 'components/icons/send';
import Error from 'components/icons/error';
import SendContractBody from './send-contract-body';

import style from './send-contract.module.scss';

export type FormData = {|
  subject: string,
    body: string,
|};

export type PreparedFormData = {|
  subject: string,
    message: string,
|};

export type Props = {
  agreement: Agreement,
  publishAgreement: FormData => void,
  rpcState: RpcState,
  resetRpcState: () => void,
  message: MessageTranslator,
  position: Position,
  isOpen?: boolean,
  setIsOpen?: boolean => void,
  isNotifyParticipants?: boolean,
  children?: Function,
  onSaveAndNotifyChanges: PreparedFormData => void,
  onModalClose: () => void,
  isSubjectFieldRequired?: boolean,
  isMessageFieldRequired?: boolean,
  messageTemplatesDisabled?: boolean,
  context?: string,
  modalClassName?: string,
};

type State = {
  contractMessageTemplates: Array<MessageTemplate>,
  selectedMessageTemplate: any,
  isLoadingMessageTemplates: boolean,
  error: any,
}

export class SendContractComponent extends React.Component<Props, State> {
  static defaultProps = {
    isNotifyParticipants: undefined,
    isOpen: true,
    children: undefined,
    setIsOpen: undefined,
    isSubjectFieldRequired: true,
    isMessageFieldRequired: true,
    messageTemplatesDisabled: false,
  }

  state = {
    contractMessageTemplates: [],
    selectedMessageTemplate: undefined,
    isLoadingMessageTemplates: false,
    error: undefined,
  }

  getActions = ({ formProps }: FormRenderProps) => {
    const { rpcState, isNotifyParticipants } = this.props;
    const { isLoadingMessageTemplates, error } = this.state;
    const customConfirmButtonClass = 'AppcuesConfirmSendDocument';

    if (isNotifyParticipants) {
      return (
        <Button
          color="yellow"
          data-testid="confirm"
          onClick={formProps.handleSubmit}
        >
          <Message
            id="Save and notify"
            comment="Button label used to save and notify about contract changes in a modal"
          />
        </Button>
      );
    }

    return (
      <Button
        color="yellow"
        data-testid="confirm"
        icon={rpcState.loading ? CircularSpinner : SendIcon}
        onClick={formProps.handleSubmit}
        disabled={
          isLoadingMessageTemplates
          || rpcState.loading
          || error
          || !isEmpty(formProps.errors)
        }
        customClass={customConfirmButtonClass}
      >
        <Message
          id="Send"
          comment="Button label used to confirm sending a contract in a modal"
        />
      </Button>
    );
  };

  getErrorActions: GetActions = ({ closeConfirmation }) => (
    <CancelButton onClick={closeConfirmation} modalKey={this.getModalKey()} />
  );

  resetForm = () => {
    const { resetRpcState, setIsOpen, onModalClose } = this.props;

    this.setState({
      contractMessageTemplates: [],
      selectedMessageTemplate: undefined,
      isLoadingMessageTemplates: false,
      error: null,
    });
    resetRpcState();
    if (setIsOpen) {
      setIsOpen(false);
    }
    if (onModalClose) {
      onModalClose();
    }
  }

  prepareFormData = (formData: FormData) => {
    const { message, agreement } = this.props;
    const myParticipant: AgreementParticipant = getAgreementMyParticipant(agreement);
    const party = getParticipantParty(agreement, myParticipant.agreementCompany.id);
    let { subject, body: formDataMessage } = formData;

    if (!subject) {
      subject = message({
        id: 'New updates - {company}',
        comment: 'will be used as a subject for the notification email',
        values: {
          company: party.name,
        },
      });
    }

    if (!formDataMessage) {
      formDataMessage = message({
        id: '{user} at {company} made an update.',
        comment: 'will be used as the body of notification email',
        values: {
          user: myParticipant.fullname,
          company: party.name,
        },
      });
    }

    return {
      subject,
      message: formDataMessage,
    };
  };

  handleSubmit = (formData: FormData) => {
    const {
      isNotifyParticipants,
      publishAgreement,
      onSaveAndNotifyChanges,
      onModalClose,
    } = this.props;

    if (isNotifyParticipants) {
      onSaveAndNotifyChanges(this.prepareFormData(formData));
      onModalClose();
      return;
    }

    if (!formData) {
      return;
    }

    publishAgreement(formData);
  };

  updateSelectedMessageTemplate = (selectedMessageTemplate?: MessageTemplate) => {
    this.setState({ selectedMessageTemplate });
  }

  getModalKey = () => {
    const { isNotifyParticipants } = this.props;
    if (isNotifyParticipants) {
      return 'notify participants on update modal';
    }

    return 'send contract modal';
  }

  getModalTitle = () => {
    const { isNotifyParticipants } = this.props;

    if (isNotifyParticipants) {
      return (
        <Message
          id="Notify participants"
          comment="Confirm modal title for notifying participants."
        />
      );
    }

    return (
      <Message
        id="Send document"
        comment="Confirm modal title for sending a document."
      />
    );
  }

  getMessageBody = () => {
    const {
      selectedMessageTemplate,
    } = this.state;
    const { position, messageTemplatesDisabled } = this.props;

    if (messageTemplatesDisabled) {
      return undefined;
    }

    if (position.signature && !isEmpty(selectedMessageTemplate) && selectedMessageTemplate.body) {
      return `${selectedMessageTemplate.body}\n\n${position.signature}`;
    }

    if (position.signature) {
      return position.signature;
    }

    if (selectedMessageTemplate && selectedMessageTemplate.body) {
      return selectedMessageTemplate.body;
    }

    return undefined;
  }

  fetchMessageTemplates = async () => {
    const { agreement, messageTemplatesDisabled } = this.props;

    if (messageTemplatesDisabled) {
      return;
    }

    try {
      this.setState((prevState) => ({
        ...prevState,
        isLoadingMessageTemplates: true,
        error: null,
      }));

      const { collection: contractMessageTemplates } = await getContractMessageTemplates({
        contractId: agreement.id,
        type: 'publish',
      });

      this.setState((prevState) => ({
        ...prevState,
        contractMessageTemplates,
        selectedMessageTemplate: undefined,
        isLoadingMessageTemplates: false,
      }));
    } catch (error) {
      this.setState({
        contractMessageTemplates: [],
        isLoadingMessageTemplates: false,
        error,
      });
    }
  }

  renderBody() {
    const {
      agreement,
      isNotifyParticipants,
      isSubjectFieldRequired,
      isMessageFieldRequired,
      position,
      context,
    } = this.props;
    const {
      error,
      isLoadingMessageTemplates,
      contractMessageTemplates,
      selectedMessageTemplate,
    } = this.state;

    if (isLoadingMessageTemplates) {
      return (
        <div className={style.SpinnerContainer}>
          <CircularSpinner />
        </div>
      );
    }

    if (error) {
      return (
        <span className={style.ErrorMessage}>
          <Error className={style.ErrorIcon} />
          <Message
            id="An error has occurred. Please reload the page and try again."
            comment="Used to show the error message after confirmation."
          />
        </span>
      );
    }

    return (
      <SendContractBody
        isNotifyParticipants={isNotifyParticipants}
        agreement={agreement}
        contractMessageTemplates={contractMessageTemplates}
        selectedMessageTemplate={selectedMessageTemplate}
        updateSelectedMessageTemplate={this.updateSelectedMessageTemplate}
        isSubjectFieldRequired={isSubjectFieldRequired}
        isMessageFieldRequired={isMessageFieldRequired}
        signature={position.signature}
        context={context}
      />
    );
  }

  render() {
    const {
      rpcState,
      children,
      isOpen,
      resetRpcState,
      modalClassName,
    } = this.props;
    const { selectedMessageTemplate } = this.state;

    const modalFormClasses = clsx(style.ModalBody, modalClassName);

    return (
      <ModalForm
        title={this.getModalTitle()}
        body={this.renderBody()}
        actions={this.getActions}
        errorActions={this.getErrorActions}
        resetFormState={resetRpcState}
        formState={rpcState}
        isOpen={isOpen}
        onSubmit={this.handleSubmit}
        onClose={this.resetForm}
        onOpen={this.fetchMessageTemplates}
        initialValues={{
          subject: selectedMessageTemplate ? selectedMessageTemplate.subject : undefined,
          body: this.getMessageBody(),
        }}
        modalKey={this.getModalKey()}
        customModalClass={modalFormClasses}
      >
        {typeof children === 'undefined' ? noop : children}
      </ModalForm>
    );
  }
}

export default localize<Props>(SendContractComponent);
