import { useState, useCallback, memo } from 'react';

import { useSelector } from 'react-redux';
import {
  times, sumBy, constant, set, slice,
} from 'lodash';
import { Message } from '@oneflowab/pomes';

import log from 'logging';
import { getAvailableContentInlineSize } from 'reducers/app';

import { A4_HEIGHT, A4_WIDTH } from 'components/contract-boxes/constants';
import { LoadingStatusBox } from 'components/contract-boxes/loading-status-box';
import { PdfViewerError } from 'components/contract-boxes/error';

import Document, { cMapUrl } from './react-pdf-document';
import PdfPage from './pdf-page';
import style from './pdf-viewer.module.scss';

const defaultDocumentOptions = {
  cMapUrl,
  cMapPacked: true,
  disableAutoFetch: true,
  disableStream: true,
};

type Props = {
  documentURL: string,
  overlayFieldsPerPage: ContractView.OverlayFieldsGroupedByPdfPage,
  pages: number,
};

const PdfViewer = ({
  documentURL,
  overlayFieldsPerPage,
  pages,
}: Props) => {
  const width = useSelector(getAvailableContentInlineSize);
  const scale = width / A4_WIDTH;
  const [pageHeights, setPageHeights] = useState(times(pages, constant(A4_HEIGHT)));
  const documentHeight = sumBy(pageHeights, (height) => height * scale);

  const handlePageLoadSuccess = useCallback((values: { page: number, height: number }) => {
    setPageHeights((heights) => slice(set(heights, values.page - 1, values.height)));
  }, [setPageHeights]);

  const onLoadError = useCallback((error: Error) => {
    log.error(error, {
      errorContext: 'BoxRenderError#Pdf',
    });
  }, []);

  const loadingDocumentMessage = (
    <Message
      id="Preparing your PDF document, almost there..."
      comment="This message shows when PDF file is loading in"
    />
  );

  return (
    <div
      className={style.PdfViewerContainer}
      style={{ height: `${documentHeight}px` }}
    >
      <div
        className={style.ScaleWrapper}
        style={{ transform: `translate3d(0, 0, 0) scale3d(${scale}, ${scale}, 1)` }}
      >
        <Document
          file={documentURL}
          options={defaultDocumentOptions}
          onLoadError={onLoadError}
          loading={(
            <div className={style.LoadingDocument}>
              <LoadingStatusBox
                message={loadingDocumentMessage}
                width="100%"
                height={documentHeight}
                className={style.LoadingStatusBox}
              />
            </div>
          )}
          // TODO: Find out how to fix this error
          error={<PdfViewerError />}
          externalLinkRel="_blank"
          externalLinkTarget="_blank"
        >
          {times(pages, (index) => (
            <PdfPage
              key={index}
              pageNumber={index + 1}
              overlayFields={overlayFieldsPerPage[index + 1] || []}
              onLoadSuccess={handlePageLoadSuccess}
              scale={scale}
            />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default memo(PdfViewer);
