// @flow

import { connect } from 'react-redux';

import tagsReducer from 'reducers/entities/tags';
import { getAccountFromSessionSelector } from 'reducers/session';

import AccountTags from './tags';

const QUERY_NAME = '/admin/tags';

export const mapStateToProps = (state: State) => {
  const query = tagsReducer.getQuerySelector(state, { name: QUERY_NAME });
  const account = getAccountFromSessionSelector(state);
  const tags = tagsReducer.getTagsSelector(state, { ids: query.result });

  return {
    account,
    query,
    tags,
  };
};

export const mapDispatchToProps = (dispatch: Function) => ({
  queryTags: ({ pagination, params }: Query) => {
    dispatch(tagsReducer.queryTags({
      name: QUERY_NAME,
      pagination: {
        ...pagination,
        limit: 50,
      },
      params: {
        ...params,
        system: 0,
        usageStats: 1,
      },
    }));
  },
});

export default connect<AccountTags, State, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(AccountTags);
