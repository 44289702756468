import { Message } from '@oneflowab/pomes';

type Props = {
  signedDate: string,
  startTime: string
}

const SignedAndAwaiting = ({ signedDate, startTime }: Props) => (
  <span>
    <Message
      id="Document was signed on {signedDate} and will start on {startTime}."
      values={{
        signedDate,
        startTime,
      }}
      comment="Text for signed and awaiting contract, stating when contract was signed. Visible in contract."
    />
  </span>
);

export default SignedAndAwaiting;
