// @flow

import {
  getMultiple,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

import { normalize, schema } from 'normalizr';

export const agreementPartiesSchema = new schema.Entity('agreementParties');

export type NormalizedAgreementParties = {
  entities: {
    agreementParties: {
      [number]: Object,
    },
  },
  result: number,
  count: number,
}

export type AgreementPartiesNormalizer = (any) => NormalizedAgreementParties;

const normalizeAgreementParties: AgreementPartiesNormalizer = (parties) => ({
  ...normalize(parties.collection, [agreementPartiesSchema]),
  count: parties.count,
});

type GetAgreementParties = Query => Promise<*>;

export const getAgreementParties: GetAgreementParties = ({ params }) => (
  getMultiple({ url: `/agreements/${params.id}/parties/` })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAgreementParties)
);
