import { ReactNode, ReactElement } from 'react';
import clsx from 'clsx';

import style from './action-bar.module.scss';

type BaseProps = {
  children: ReactNode | ReactNode[];
};
type GroupProps = BaseProps

type BaseWithActive = BaseProps & {
  active: boolean;
};

const Group = ({ children }: GroupProps) => <>{children}</>;
const Content = ({ children }: BaseWithActive | BaseProps) => <>{children}</>;

type ActionBarProps = {
  children: ReactElement | ReactElement[],
  collapsed?: boolean,
}

/**
 * The action bar is a multi-purpose space. Not only it provides a way to edit, save or cancel,
 * but it also expands to reveal a form, confirmation of an action.
 * Finally, an information message can be displayed alone or next to the buttons.
 */

const ActionBar = ({ children, collapsed = true }: ActionBarProps) => {
  const getFilter = () => {
    if (collapsed) {
      return (child: ReactElement) => child?.type === Group;
    }

    return (child: ReactElement) => child.type === Content && child.props.active;
  };

  const getChildren = () => {
    const childArray = Array.isArray(children) ? children : [children];
    return childArray.filter(getFilter());
  };

  const actionBarClasses = clsx(style.ActionBar, {
    [style.Collapsed]: collapsed,
  });

  return (
    <div className={actionBarClasses}>
      {getChildren()}
    </div>
  );
};

ActionBar.Group = Group;
ActionBar.Content = Content;

export default ActionBar;
