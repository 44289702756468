// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import apiTokensReducer from 'reducers/entities/api-tokens';

import { RevokeApiTokenConfirmation } from './revoke-api-token';
import type { Props } from './revoke-api-token';

type StateProps = {|
  removeState: RemoveState,
|};

type DispatchProps = {|
  revokeApiToken: () => void,
  resetRemoveState: Function,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  apiToken: ApiToken,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { apiToken }) => ({
  removeState: apiTokensReducer.getRemoveSelector(state, { id: apiToken.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;
export const mapDispatchToProps: MapDispatchToProps = (dispatch, { apiToken }) => ({
  revokeApiToken: () => {
    dispatch(apiTokensReducer.removeApiToken({
      id: apiToken.id,
    }));
  },
  resetRemoveState: () => {
    dispatch(apiTokensReducer.removeApiTokenReset({
      id: apiToken.id,
    }));
  },
});

const connectedRevokeApiTokenConfirmation = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(RevokeApiTokenConfirmation);

export { connectedRevokeApiTokenConfirmation as RevokeApiTokenConfirmation };
