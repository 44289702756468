import { useEffect } from 'react';
import webSocket from 'web-socket';
import { useDispatch } from 'react-redux';

import agreementsReducer from 'reducers/entities/agreements';
import {
  isDecliningInProgress,
  isPending,
  isSigningInProgress,
  isStateDraft,
} from 'agreement/states';
import useAgreement from 'hooks/use-agreement';

type SubscribeToEventsParams = {
  agreementId: Oneflow.Document['id'];
  agreementState: Oneflow.Document['state'];
  fetchAgreement: () => void;
}

export const getPusherEvents = (state: number) => [
  {
    event: 'agreement:publish',
    condition: isStateDraft(state),
  },
  {
    event: 'agreement:expire',
    condition: isPending({ state }),
  },
  {
    event: 'agreement:declining:done',
    condition: isStateDraft(state)
      || isPending({ state })
      || isDecliningInProgress({ state }),
  },
  {
    event: 'agreement:signing:done',
    condition: isSigningInProgress({ state }),
  },
];

const subscribeToEvents = ({
  agreementId,
  agreementState,
  fetchAgreement,
}: SubscribeToEventsParams) => {
  const subscriptions: (() => void)[] = [];
  if (typeof agreementState !== 'number') return subscriptions;

  const events = getPusherEvents(agreementState);
  events.forEach(({ event, condition }) => {
    if (condition) {
      const unsubscribe = webSocket.subscribe({
        channelName: `private-agreement-${agreementId}`,
        event,
        eventCallback: fetchAgreement,
      });
      if (unsubscribe) {
        subscriptions.push(unsubscribe);
      }
    }
  });

  return subscriptions;
};

const useDocumentStateListener = (
  agreementId: Oneflow.Agreement['id'],
  guestToken?: string,
) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);

  useEffect(() => {
    const subscriptions = subscribeToEvents({
      agreementId,
      agreementState: agreement.state,
      fetchAgreement: () => {
        dispatch(agreementsReducer.fetchAgreement(({
          id: agreementId,
          params: { guestToken },
        })));
      },
    });

    return () => {
      subscriptions?.forEach((unsubscribe) => unsubscribe?.());
    };
  }, [
    dispatch,
    agreementId,
    guestToken,
    agreement.state,
  ]);
};

export default useDocumentStateListener;
