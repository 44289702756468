/* eslint-disable react/display-name */
import { useMemo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import { USER_ACCOUNT_ROLE_ADMIN_ID } from 'user';
import AdministratorIcon from 'components/icons/administrator';
import ActionsMenu from 'components/actions-menu';
import { checkAcl } from 'components/acl';
// eslint-disable-next-line import/named
import { Table } from 'components/table';
import EditGroupAccountAccess from 'components/modals/edit-group-account-access';
import RemoveGroupAccountAccess from 'components/modals/remove-group-account-access';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { ChangeRoleMenuItem } from 'components/menu-items/change-role';
import { renderPermissionCount, renderRoleName } from 'routes/admin/user/account-access/helpers';

import { Query } from './account-access';
import styles from './account-access.module.scss';

export type Props = {
  account: Oneflow.Account,
  query: Query,
  children: ReactNode,
  items: Oneflow.AccountGroupRoleBinding[],
  message: MessageTranslator,
  hasGrantSystemRolePermission: boolean,
  hasGrantCustomRolePermission: boolean,
};

type OnClick = () => void;

const GroupAccessTable = ({
  account,
  query,
  message,
  items,
  children,
  hasGrantSystemRolePermission,
  hasGrantCustomRolePermission,
}: Props) => {
  const hasRemoveSystemRolePermission = useMemo(() => checkAcl(account.acl, 'account:group:system_role_binding:remove'), [account.acl]);
  const hasRemoveCustomRolePermission = useMemo(() => checkAcl(account.acl, 'account:group:custom_role_binding:remove'), [account.acl]);
  const hasEditSystemRolePermission = hasRemoveSystemRolePermission
    && hasGrantSystemRolePermission;
  const hasEditCustomRolePermission = hasRemoveCustomRolePermission
    && hasGrantCustomRolePermission;

  const getEditMenuItem = () => (onClick: OnClick): JSX.Element => {
    const EditMenuItemComponent = (
      <ChangeRoleMenuItem
        onClick={onClick}
        disabled={!hasEditSystemRolePermission && !hasEditCustomRolePermission}
      />
    );

    return EditMenuItemComponent;
  };

  const getRemoveMenuItem = () => (onClick: OnClick): JSX.Element => {
    const RemoveMenuItem = (
      <DeleteMenuItem
        onClick={onClick}
        disabled={!hasRemoveSystemRolePermission && !hasRemoveCustomRolePermission}
      />
    );

    return RemoveMenuItem;
  };

  const getActionsForGroupAccess = (item: Oneflow.AccountGroupRoleBinding) => (
    <ActionsMenu
      actions={[
        <EditGroupAccountAccess
          key={`edit-${item.id}`}
          binding={item}
          renderedFromAccountAccessPage
        >
          {getEditMenuItem()}
        </EditGroupAccountAccess>,
        <RemoveGroupAccountAccess
          key={`remove-${item.id}`}
          binding={item}
          renderedFromAccountAccessPage
        >
          {getRemoveMenuItem()}
        </RemoveGroupAccountAccess>,
      ]}
      focusOnCloseDisabled
    />
  );

  const getGroupAccessTableConfig = () => {
    const actions: JSX.Element[] = [];
    const columns = [
      {
        name: 'group-name',
        label: message({
          id: 'Group name',
          comment: 'Column header for the account access list.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountGroupRoleBinding) => (
          <Link to={`/admin/groups/${item.group.id}`} className={styles.Name}>
            {item.group.name}
            {item.role.id === USER_ACCOUNT_ROLE_ADMIN_ID
                && <AdministratorIcon className={styles.AdministratorIcon} />}
          </Link>
        ),
      },
      {
        name: 'account role',
        label: message({
          id: 'Account role',
          comment: 'Column header for the account access list in the user pages.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountGroupRoleBinding) => (
          <Link to={`/admin/roles/${item.role.id}`} className={styles.AccountRole}>
            {renderRoleName(item.role)}
          </Link>
        ),
      },
      {
        name: 'permissions',
        label: message({
          id: 'Permissions',
          comment: 'Column header for the account access list in the user pages.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountGroupRoleBinding) => renderPermissionCount(item.role),
      },
      {
        name: 'actions',
        label: message({ id: 'Actions', comment: 'Column label in users page' }),
        type: 'actions',
        value: (item: Oneflow.AccountGroupRoleBinding) => getActionsForGroupAccess(item),
      },
    ];

    return {
      items,
      itemKey: 'id',
      actions,
      columns,
    };
  };

  return (
    <>
      <div className={styles.ContentHeader}>
        <h2 className={styles.Header}>
          <Message id="Group access" comment="Title, above the table." />
        </h2>
        <p>
          <Message
            id="Account roles assigned to the user via group membership."
            comment="Help text for the account access list in the user pages, groups table."
          />
        </p>
      </div>
      <Table
        config={getGroupAccessTableConfig()}
        query={query}
      />
      {children}
    </>
  );
};

export default GroupAccessTable;
