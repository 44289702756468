import { forwardRef } from 'react';
import { Message } from '@oneflowab/pomes';
import type { ElementRef, MouseEventHandler } from 'react';

import Button from 'components/button/button-fc';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

type Props = {
  onClick?: (event?: MouseEventHandler<HTMLButtonElement>) => void,
  modalKey?: string,
};

export const CancelButton = forwardRef<ElementRef<typeof Button>, Props>(
  ({ onClick, modalKey }, ref) => {
    const handleClick = (event: MouseEventHandler<HTMLButtonElement>) => {
      onClick?.(event);
      if (modalKey) {
        amplitudeLogEvent('Exit Modal', { location: modalKey, 'exit action': 'click cancel button' });
      }
    };

    return (
      <Button
        kind="linkSeparate"
        underlineLink
        data-testid="cancel"
        onClick={handleClick}
        ref={ref}
        color="forest-green"
      >
        <Message
          id="Cancel"
          comment="Text for button to cancel action."
        />
      </Button>
    );
  },
);

CancelButton.displayName = 'CancelButton';
