import * as PARTICIPANT_CONSTANTS from 'agreement/participant/constants';

export const getAvailableSigningMethodIds = (agreement: Oneflow.Agreement): number[] => {
  // TODO: remove the type casting when agreement.config.enabledSignMethods is typed
  const availableSigningMethodIds: number[] = agreement.config?.enabledSignMethods
  || agreement.availableOptions?.signMethods;
  if (agreement.config?.defaultDeliveryChannel
      === PARTICIPANT_CONSTANTS.DELIVERY_CHANNEL_SAME_DEVICE) {
    // Do not show the Standard e-sign option if the delivery channel is Same device
    const indexOfStandardESign = availableSigningMethodIds.indexOf(
      PARTICIPANT_CONSTANTS.SIGN_METHOD_ESIGN,
    );
    if (indexOfStandardESign > -1) {
      availableSigningMethodIds.splice(indexOfStandardESign, 1);
    }
  }

  return availableSigningMethodIds;
};

// TODO: remove the type casting when agreement.config?.defaultSignMethod is typed
export const getDefaultSignMethod = (agreement: Oneflow.Agreement): number => {
  if (agreement.config?.signatureMode === 'qualified') {
    return PARTICIPANT_CONSTANTS.SIGN_METHOD_EID_SIGN;
  }

  return (
    agreement.config?.defaultSignMethod || agreement.availableOptions.signMethods[0]
  );
};
