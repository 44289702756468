// @flow

import React from 'react';

import style from './contract-text-editable.module.scss';

type Props = {
  legacyMarkup: string,
};

const ContractLegacyTextEditable = ({
  legacyMarkup,
}: Props) => (
  <div className={style.EditableReadOnlyContent}>
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: legacyMarkup,
      }}
    />
  </div>
);

export default ContractLegacyTextEditable;
