import * as React from 'react';
import queryString from 'query-string';
import isNaN from 'lodash/isNaN';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';

import {
  modalRoutesQueryStringKeys,
  getModalRoutesQuery,
} from 'modal-routes';
import type {
  Params,
  ParamValue,
  UpdateQueryStringParam,
  UpdateQueryStringParams,
  ReplaceQueryStringParams,
} from 'hocs/query-string-params/types';

import { QueryStringParamsContext } from './context';

const tryToConvertToNumber = (value: string, key: string) => {
  if (!isNaN(Number(value)) && key !== 'q') {
    return Number(value);
  }

  return value;
};

const parseQueryStringValue = (key: string, value: string) => {
  if (modalRoutesQueryStringKeys.includes(key) || value === '' || isNull(value) || isUndefined(value)) {
    return undefined;
  }

  if (key.indexOf('_') !== -1) {
    const [newKey, ...remaining] = key.split('_');

    return {
      [newKey]: parseQueryStringValue(remaining.join('_'), value),
    };
  }

  if (key.indexOf('[]') !== -1) {
    const newKey = key.replace('[]', '');

    if (value === ',') {
      return {
        [newKey]: [],
      };
    }

    return {
      [newKey]: value.split(',').filter((element: string) => element !== '').map(tryToConvertToNumber),
    };
  }

  return {
    [key]: tryToConvertToNumber(value, key),
  };
};

export const serializeParamToQueryString = (param: string, value: ParamValue) => {
  if (isNull(value) || isUndefined(value) || value === '') {
    return {};
  }

  if (isArray(value)) {
    if (isEmpty(value)) {
      return {
        [`${param}[]`]: ',',
      };
    }

    return {
      [`${param}[]`]: value.join(','),
    };
  }

  if (typeof value === 'object') {
    return reduce(value, (acc, element, key) => ({
      ...acc,
      ...serializeParamToQueryString(`${param}_${key}`, element),
    }), {});
  }

  return {
    [param]: String(value),
  };
};

type Props = {
  location: Location,
  children: React.ReactNode,
  updateSearch: (location: Location, search: string, navigate: boolean) => void,
};

function sanitizeQueryStringParams(queryStringParams: Params) {
  // Remove page param when any filter is applied
  if (queryStringParams.page && !isEmpty(omit(queryStringParams, 'page'))) {
    return omit(queryStringParams, 'page');
  }

  return queryStringParams;
}

export default class QueryStringParams extends React.PureComponent<Props> {
  updateQueryStringParam: UpdateQueryStringParam = (param, value, options = { navigate: true }) => {
    this.replaceQueryStringParams({
      ...this.queryStringParams(),
      [param]: value,
    }, options);
  }

  updateQueryStringParams: UpdateQueryStringParams = (values, options = { navigate: true }) => {
    const params = this.queryStringParams();
    this.replaceQueryStringParams({
      ...params,
      ...values,
    }, options);
  }

  replaceQueryStringParams: ReplaceQueryStringParams = (
    params = {},
    options = { navigate: false, hasPagination: false },
  ) => {
    const { location, updateSearch } = this.props;
    let queryStringParams = params;

    if (!options.hasPagination) {
      queryStringParams = sanitizeQueryStringParams(params);
    }

    const newParams = reduce(queryStringParams, (acc, value, key: any) => ({
      ...acc,
      ...serializeParamToQueryString(key, value),
      ...getModalRoutesQuery(location),
    }), {});

    updateSearch(location, queryString.stringify(newParams), options.navigate);
  }

  queryStringParams() {
    const { location } = this.props;

    return reduce(queryString.parse(location.search), (
      acc: Params,
      value: string,
      key: string,
    ) => (
      merge(
        {},
        acc,
        parseQueryStringValue(key, value),
      )
    ), {});
  }

  render() {
    const { children } = this.props;

    return (
      <QueryStringParamsContext.Provider
        value={{
          params: this.queryStringParams(),
          updateParam: this.updateQueryStringParam,
          replaceParams: this.replaceQueryStringParams,
          updateParams: this.updateQueryStringParams,
        }}
      >
        {children}
      </QueryStringParamsContext.Provider>
    );
  }
}
