export const OVERLAY_FIELD_ELEMENTS_ATTRIBUTES_QUERY = '[name="overlay-text-field"][tabindex]:not([tabindex="-1"])';
export function getOverlayFieldFields({ filterBy = '' } = {}) {
  const filterByQuery = filterBy.replace('textarea', '');
  const overlayFieldElementsQuery = `textarea${OVERLAY_FIELD_ELEMENTS_ATTRIBUTES_QUERY}${filterByQuery}`;
  // Sorting by rect.y distance for keyboard navigation feels natural
  // In visible order rather than dropped order
  const overlayFieldNodeList = document.querySelectorAll(overlayFieldElementsQuery);

  if (!overlayFieldNodeList) {
    return [];
  }

  const overlayFields = Array.from(overlayFieldNodeList)
    .sort((a, b) => {
      const rectA = a.getBoundingClientRect();
      const rectB = b.getBoundingClientRect();
      if (rectA.y !== rectB.y) {
        return rectA.y - rectB.y;
      }

      return 0;
    });

  return overlayFields as HTMLElement[];
}

export function getNextOverlayField(currentElement: HTMLTextAreaElement | null) {
  if (!currentElement) {
    return null;
  }

  const focusables = getOverlayFieldFields();

  const currentIndex = focusables.indexOf(currentElement);
  const nextIndex = currentIndex + 1;

  return focusables?.[nextIndex];
}

export function getPreviousOverlayField(currentElement: HTMLTextAreaElement | null) {
  if (!currentElement) {
    return null;
  }

  const focusables = getOverlayFieldFields();

  const currentIndex = focusables.indexOf(currentElement);
  const previousIndex = currentIndex - 1;

  return focusables?.[previousIndex];
}

export function getFocusableElementsWithinPopover() {
  const toolbarActionElements = '[data-overlay-field-popover-cta][tabindex]:not([tabindex="-1"])';
  const focusables = Array.from(document.querySelectorAll(toolbarActionElements));
  return focusables;
}
