// reset-approvals-signatures-participant.tsx
import { Message } from '@oneflowab/pomes';
import useAgreement from 'hooks/use-agreement';
import Modal from 'components/modal';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import WarningIcon from 'components/icons/warning';
import { hasDraftApprovers } from 'agreement';
import style from './reset-approvals-signatures-participant.scss';

export type Props = {
  agreementId: Oneflow.Agreement['id'];
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  type: 'participantRemoved' | 'partyUpdated' | 'participantUpdated' | 'partyRemoved';
};

const ResetApprovalsSignatures = ({
  agreementId,
  isOpen,
  onCancel,
  onConfirm,
  type,
}: Props) => {
  const agreement = useAgreement(agreementId);

  const getButtonText = () => {
    if (type === 'participantRemoved' || type === 'partyRemoved') {
      return (
        <Message
          id="Remove and reset"
          comment="Button text for remove and reset."
        />
      );
    }
    return (
      <Message
        id="Confirm and reset"
        comment="Button text for update and reset."
      />
    );
  };

  const getMainText = () => {
    if (type === 'partyRemoved') {
      return (
        <Message
          id="Removing company from the document will reset all existing approvals and signatures."
          comment="Main text for removing company and resetting approvals & signatures."
        />
      );
    }
    if (type === 'participantRemoved') {
      return (
        <Message
          id="Removing participant from the document will reset all existing approvals and signatures."
          comment="Main text for removing participant and resetting approvals & signatures."
        />
      );
    }
    if (type === 'partyUpdated') {
      return (
        <Message
          id="Changing company details will reset all existing approvals and signatures."
          comment="Main text for updating company details and resetting approvals & signatures."
        />
      );
    }
    return (
      <Message
        id="Changing participant details will reset all existing approvals and signatures."
        comment="Main text for updating participant details and resetting approvals & signatures."
      />
    );
  };

  const getAdditionalText = () => (
    <>
      <p>
        <Message
          id="The document will need to be approved again and signed again."
          comment="Additional text for reset approvals & signatures modal."
        />
      </p>
      {hasDraftApprovers(agreement) && (
        <p>
          <Message
            id="(Internal approvals will not be reset)"
            comment="Additional note for reset approvals & signatures modal."
          />
        </p>
      )}
    </>
  );

  return (
    <Modal
      header={(
        <Message
          id="Save and reset"
          comment="Modal title for save and reset approvals warning modal."
        />
      )}
      isOpen={isOpen}
      onCancel={onCancel}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc
      modalKey="reset approvals and signatures modal"
    >
      <Modal.Body>
        <div className={style.BodyContainer}>
          <WarningIcon className={style.WarningIcon} />
          <div className={style.BodyContainerText}>
            {getMainText()}
            {getAdditionalText()}
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <div>
          <CancelButton onClick={onCancel} modalKey="reset approvals and signatures modal" />
        </div>
        <Button onClick={onConfirm} kind="primary">
          {getButtonText()}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ResetApprovalsSignatures;
