// @flow

import React from 'react';
import Dropzone from 'react-dropzone';
import head from 'lodash/head';
import clsx from 'clsx';

import { FILE_VIDEO_DROPZONE_ACCEPT } from 'utils/file';
import VideoDropzoneUploadSpace from './video-dropzone-upload-space';

import style from './video-upload.module.scss';
import { MAX_VIDEO_SIZE } from '../contract-boxes/video-box/constants';

type Props = {
  onUpload: Function,
};

export class VideoUpload extends React.Component<Props> {
  onVideoDrop = (acceptedFiles: Array<File>) => {
    const { onUpload } = this.props;
    const video = head(acceptedFiles);

    if (video) {
      onUpload(video);
    }
  };

  renderVideoDropzone = (fileRejections: Array<File> | {}) => (
    <VideoDropzoneUploadSpace fileRejections={fileRejections} />
  );

  getDropzoneClasses = (isDragActive, isDragReject) => clsx(style.Dropzone, {
    [style.DropzoneActive]: isDragActive,
    [style.DropzoneReject]: isDragReject,
  });

  render() {
    return (
      <div className={style.VideoUpload}>
        <Dropzone
          multiple={false}
          maxSize={MAX_VIDEO_SIZE}
          onDropAccepted={this.onVideoDrop}
          accept={FILE_VIDEO_DROPZONE_ACCEPT}
        >
          {(
            {
              getRootProps, getInputProps, fileRejections, isDragActive, isDragReject,
            },
          ) => (
            <section>
              <div {...getRootProps({
                className: this.getDropzoneClasses(isDragActive, isDragReject),
              })}
              >
                <input {...getInputProps()} />
                {this.renderVideoDropzone(fileRejections)}
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}
