// @flow

export const BLOCK_QUOTE = 'block-quote';
export const BULLETED_LIST = 'bulleted-list';
export const CAPTION = 'caption';
export const HEADING_ONE = 'heading-one';
export const HEADING_TWO = 'heading-two';
export const HEADING_THREE = 'heading-three';
export const HEADING_FOUR = 'heading-four';
export const HEADING_FIVE = 'heading-five';
export const HEADING_SIX = 'heading-six';
export const LIST_ITEM = 'list-item';
export const NUMBERED_LIST = 'numbered-list';
export const LINK = 'link';
export const TABLE = 'table';
export const TABLE_HEAD = 'table-head';
export const TABLE_HEADER = 'table-header';
export const TABLE_BODY = 'table-body';
export const TABLE_ROW = 'table-row';
export const TABLE_CELL = 'table-cell';
export const TITLE = 'title';
export const SUBTITLE = 'subtitle';
export const IMAGE = 'image';
export const DATA_FIELD = 'data-field';
export const PAGE_BREAK = 'page-break';
export const PARAGRAPH = 'paragraph';
export const HORIZONTAL_LINE = 'horizontal-line';
export const NEW_LINE = 'new-line';
export const ANNOTATION = 'annotation';
export const SUGGESTION = 'suggestion';
