// @flow

import * as React from 'react';
import isNumber from 'lodash/isNumber';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import { hasAnyPosition } from 'oneflow-client/positions';
import isEmpty from 'lodash/isEmpty';
import Field from 'components/field';
import ParticipantRoleField from 'components/fields/participant-role';

import {
  getParticipantParty,
  getAgreementMyParty,
  isSignOrderEnabled,
} from 'agreement/selectors';
import agreements from 'reducers/entities/agreements';
import ModalForm from 'hocs/modal-form';
import Button from 'components/button';
import { AddParticipantButton, CancelButton } from 'components/buttons';
import emailValidationText from 'components/email-validation-text';
import { EmailField } from 'components/fields';
import TextField from 'components/text-field';
import type { FormRenderProps } from 'react-final-form';

import AddMembers from 'components/icons/add-members';
import CircularSpinner from 'components/icons/circular-spinner';
import { getParticipantRolesAsOptions } from 'components/participant-roles/participant-roles';
import * as participantConstants from 'agreement/participant/constants';

import style from './add-former-colleague.module.scss';

const modalKey = 'add contract former colleague modal';

export type FormData = {|
  subject: string,
  body: string,
|};

export type Props = {
  agreement: number,
  message: MessageTranslator,
};

type State = {
  error: any,
}

export const AddFormerColleague = ({
  agreement,
  message,
}: Props) => {
  const rpcState = useSelector((state) => (
    agreements.getAddParticipantSelector(state, { id: agreement.id })
  ));
  const dispatch = useDispatch();
  const resetRpcState = () => {
    dispatch(agreements.addParticipantReset({ id: agreement.id }));
  };
  const myParty = getAgreementMyParty(agreement);
  const [isOpen, setIsOpen] = React.useState(false);

  const initialState: State = {
    colleagues: [],
    error: null,
    hasSucceeded: false,
    isOpen: true,
  };

  const [state, setState] = useState({ ...initialState });

  const getActions = ({ formProps }: FormRenderProps) => (
    <Button
      color="yellow"
      data-testid="confirm"
      icon={rpcState.loading ? CircularSpinner : AddMembers}
      onClick={formProps.handleSubmit}
      disabled={
        state.error
        || !isEmpty(formProps.errors)
      }
    >
      <Message
        id="Add"
        comment="Button label used to confirm adding a participant in a modal"
      />
    </Button>
  );

  const uniqueEmailName = ({ params }: { params: any }) => (
    hasAnyPosition({
      params,
      props: {
        returnEntity: true,
      },
    })
  );

  const addColleague = (formData: FormData) => {
    dispatch(agreements.addParticipant({
      id: agreement.id,
      data: {
        id: agreement.id,
        agreementCompany: myParty.id,
        email: formData.email,
        fullname: formData.fullname,
        isFormerColleague: true,
        signMethod: 0,
        private: 1,
        invite: 0,
        subject: '',
        message: '',
        type: isNumber(formData.role.value) ? formData.role.value : formData.role,
      },
    }));
  };

  const getErrorActions: GetActions = ({ closeConfirmation }) => (
    <CancelButton onClick={closeConfirmation} modalKey={modalKey} />
  );

  const resetForm = () => {
    setState((prevState) => ({
      ...prevState,
      error: null,
    }));

    setIsOpen(false);
    resetRpcState();
  };

  const handleSubmit = (formData: FormData) => {
    if (!formData) {
      return;
    }

    addColleague(formData);
  };

  const getModalTitle = () => {
    const party = getParticipantParty(agreement, myParty.id);

    return (
      <Message
        id="Add a former colleague to {company}"
        comment="Confirm modal text."
        values={{
          company: party.name,
        }}
      />
    );
  };

  const getDefaultParticipantRole = () => getParticipantRolesAsOptions(message)[0].value;

  const renderBody = () => (
    <>
      <div className={style.Row}>
        <Field
          name="fullname"
          label={message({
            id: 'Full name',
            comment: 'Field label in invite user modal.',
          })}
          placeholder={message({
            id: 'Enter full name',
            comment: 'Field placeholder in invite user modal.',
          })}
          component={TextField}
          maxLength={100}
          autoFocus
          required
          responsive
        />
        <EmailField
          unique={{
            text: emailValidationText,
            param: 'email',
            request: uniqueEmailName,
          }}
          required={false}
        />
      </div>
      <div className={style.Row}>
        <ParticipantRoleField
          fieldInfoRightPosition
          hiddenRoles={[
            participantConstants.TYPE_IS_ORGANIZER,
            participantConstants.TYPE_IS_EXTERNAL_APPROVER,
          ]}
          hasSignOrder={isSignOrderEnabled(agreement)}
        />
      </div>
    </>
  );

  const handleOpen = () => {
    setIsOpen(true);
  };

  const renderChildren = () => (
    <AddParticipantButton
      type="formerColleague"
      onClick={handleOpen}
    />
  );

  return (
    <ModalForm
      title={getModalTitle()}
      body={renderBody()}
      actions={getActions}
      errorActions={getErrorActions}
      resetFormState={resetRpcState}
      formState={rpcState}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onClose={resetForm}
      initialValues={{
        role: getDefaultParticipantRole(),
      }}
      modalKey={modalKey}
    >
      {renderChildren}
    </ModalForm>
  );
};

export default localize<Props>(AddFormerColleague);
