import { ReactNode } from 'react';
import clsx from 'clsx';

import style from './empty-state.module.scss';

type Props = {
  className?: string,
  content: ReactNode,
  defaultStyle?: boolean,
  header?: ReactNode,
  icon?: ReactNode,
  withPadding?: boolean,
};

const EmptyState = ({
  className,
  content,
  defaultStyle,
  header,
  icon,
  withPadding,
}: Props) => {
  const emptyStateClasses = clsx(style.EmptyState, className, {
    [style.WithPadding]: withPadding,
  });

  if (defaultStyle) {
    return (
      <div className={emptyStateClasses}>
        { icon ? <div className={style.IconContainer}>{icon}</div> : null }
        { header ? <div className={style.Header}>{header}</div> : null }
        <div className={style.Content}>
          {content}
        </div>
      </div>
    );
  }

  return (
    <div className={emptyStateClasses}>
      {icon}
      {header}
      {content}
    </div>
  );
};

export default EmptyState;
