// @flow

import { isEmpty, head, debounce } from 'lodash';
import {
  Editor,
  Range,
  Path,
  Transforms,
} from 'slate';
import { ReactEditor } from 'slate-react';
import {
  findActiveStyledTextElement,
} from 'components/rich-text-editor-toolbars/toolbar-buttons/text-styles/text-styles-plugin';
import {
  findActiveAnnotationElement,
} from 'components/contract-text-editor/editor-plugins/annotation-plugin';
import { indentPlugin } from 'components/rich-text-editor-toolbars/toolbar-buttons/indent-button';
import { isEndOfTable } from 'components/rich-text-editor/editor-plugins/table-utils';
import { tabKeyInTable } from 'components/rich-text-editor/tab-key-in-table';

import { getRangeRectangles } from './range-rectangles';

export const isSelectionEmpty = (selection: any): boolean => (
  isEmpty(selection?.anchor) || isEmpty(selection?.focus)
);

export const hasMarginLeft = (node: any) => (
  Boolean(parseInt(node.marginLeft, 10))
);

const handleOnBackspaceInIndentedNode = (editor: any, event: any) => {
  if (
    !editor.selection
    || !Range.isCollapsed(editor.selection)
    || editor.selection.focus.offset !== 0
  ) {
    return;
  }

  const [parentNode, path] = Editor.parent(editor, editor.selection.focus);
  const isStart = Editor.isStart(editor, editor.selection.focus, path);

  if (isStart && hasMarginLeft(parentNode)) {
    indentPlugin.insertShiftIndentation(editor);
    event.preventDefault();
  }
};

export const handleOnBackspace = (editor: any, event: any) => {
  if (event.key !== 'Backspace') {
    return;
  }

  handleOnBackspaceInIndentedNode(editor, event);
};

const handleOnStyledElementEnter = (
  editor: any,
  event: any,
) => {
  const styledTextElement = findActiveStyledTextElement(editor);
  if (!styledTextElement) {
    return;
  }

  const [, path] = styledTextElement;
  const isEnd = editor.selection && Editor.isEnd(editor, editor.selection.focus, path);

  if (isEnd && Range.isCollapsed(editor.selection)) {
    editor.insertNode({
      type: 'paragraph',
      children: [{ text: '' }],
    });
    event.preventDefault();
  }
};

const handleOnAnnotationEnter = (
  editor: any,
  event: any,
) => {
  const annotationElement = findActiveAnnotationElement(editor);
  if (!annotationElement) {
    return;
  }

  const [, path] = annotationElement;
  const isEnd = editor.selection && Editor.isEnd(editor, editor.selection.focus, path);

  if (isEnd && Range.isCollapsed(editor.selection)) {
    const nextElement = Editor.next(editor);
    if (!nextElement) {
      return;
    }
    const [, nextElementPath] = nextElement;
    const [startEdge] = Editor.edges(editor, nextElementPath);

    Transforms.select(editor, startEdge);
    event.preventDefault();
  }
};

export const handleOnEnter = (editor: any, event: any) => {
  if (event.key !== 'Enter') {
    return;
  }

  if (event.shiftKey) {
    editor.insertText('\n');
    event.preventDefault();
    return;
  }

  handleOnStyledElementEnter(editor, event);

  if (event.defaultPrevented) {
    return;
  }

  handleOnAnnotationEnter(editor, event);
};

const emptySelectedEditorRangeInfo = Object.freeze({
  editorSelection: null,
  selectedRectangle: null,
});

export const getSelectedEditorRangeInfo = (editor, relativeParent) => {
  try {
    const domRange = ReactEditor.toDOMRange(editor, editor.selection);
    const editorSelection = editor.selection;

    if (!domRange || (editorSelection && Range.isCollapsed(editorSelection))) {
      return emptySelectedEditorRangeInfo;
    }

    const rectangles = getRangeRectangles(domRange, relativeParent);

    return {
      editorSelection,
      selectedRectangle: head(rectangles),
    };
  } catch {
    return emptySelectedEditorRangeInfo;
  }
};

export const getDocumentSelectedEditorRangeInfo = (editor, editorSelection, relativeParent) => {
  try {
    const domRange = ReactEditor.toDOMRange(editor, editorSelection);

    if (!domRange) {
      return null;
    }

    const rectangles = getRangeRectangles(domRange, relativeParent);

    return head(rectangles);
  } catch {
    return null;
  }
};

export const onDocumentMouseReleaseHandler = (
  mouseDownEvent: MouseEvent,
  setEditorSelection: Function,
  setSelectedRectangle: Function,
  editor: any,
  documentScrollContainer: HTMLElement | null = null,
) => {
  const mouseDownPageX = mouseDownEvent.pageX;
  const mouseDownPageY = mouseDownEvent.pageY;

  const documentMouseReleaseHandler = debounce((event: MouseEvent) => {
    const isSelectionUpdated = (
      event.pageX !== mouseDownPageX || event.pageY !== mouseDownPageY
    );

    if (event.detail > 1 || isSelectionUpdated) {
      const editorRangeInfo = getSelectedEditorRangeInfo(editor, documentScrollContainer);
      setEditorSelection(editorRangeInfo.editorSelection);
      setSelectedRectangle(editorRangeInfo.selectedRectangle);
    }

    document.removeEventListener('mouseup', documentMouseReleaseHandler);
    document.removeEventListener('dragend', documentMouseReleaseHandler);
  }, 100);

  document.addEventListener('mouseup', documentMouseReleaseHandler);
  document.addEventListener('dragend', documentMouseReleaseHandler);
};

export const isArrowDownOrRight = (event: any) => (
  event.key === 'ArrowDown' || event.key === 'ArrowRight'
);

const insetNewParagraph = (editor, activeTablePath) => (
  Transforms.insertNodes(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  }, {
    at: Path.next(activeTablePath),
    select: true,
  })
);

export const handleOnArrowDownOrRight = (editor: any, event: any, cell: any) => {
  if (!isArrowDownOrRight(event)) {
    return;
  }

  const activeTablePath = isEndOfTable(editor, cell);
  if (!activeTablePath) {
    return;
  }

  insetNewParagraph(editor, activeTablePath);
  event.preventDefault();
};

export const handleTabKeyInTable = (editor: any, event: any, cell: any) => {
  if (event.key !== 'Tab') {
    return;
  }

  const mark = event.shiftKey ? 'outdent' : 'indent';
  tabKeyInTable(editor, cell, mark);
  event.preventDefault();
};

export const handleOnTableKeyDown = (editor: any, event: any) => {
  const [cell] = Editor.nodes(editor, {
    match: (n) => n.type === 'table-cell',
    mode: 'lowest',
  });

  if (!cell) {
    return;
  }

  handleTabKeyInTable(editor, event, cell);

  if (event.defaultPrevented) {
    return;
  }

  handleOnArrowDownOrRight(editor, event, cell);
};

export const handleUndo = (editor: any, event: any) => {
  if (event.inputType !== 'historyUndo') {
    return;
  }

  editor.undo();
};

export const handleRedo = (editor: any, event: any) => {
  if (event.inputType !== 'historyRedo') {
    return;
  }

  editor.redo();
};

const regHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
export const isValidColor = (color: string) => regHex.test(color);

export const isRangeBackward = (selection) => Range.isBackward(selection);
