import { useMemo } from 'react';
import { Message } from '@oneflowab/pomes';
import TermsOfUseLink from 'components/terms-of-use-link';

import style from './subscription-cancelled.module.scss';

type Props = {
  freePlan?: boolean;
}

const SubscriptionCancelledModal = ({ freePlan = false }: Props) => {
  const renderContent = useMemo(() => {
    if (freePlan) {
      return (
        <>
          <p className={style.BodyTitle}>
            <Message
              id="Welcome to the Oneflow Free plan 💫"
              comment="Welcome text in the free plan modal"
            />
          </p>
        </>
      );
    }

    return (
      <>
        <p className={style.BodyTitle}>
          <Message
            id="Your Subscription Plan will be canceled"
            comment="Text for subscription plan canceled section"
          />
        </p>
        <p>
          <Message
            id="You will continue to have access to features until the end of the Subscription Plan period."
            comment="Text for subscription canceled section"
          />
        </p>
      </>
    );
  }, [freePlan]);

  return (
    <div className={style.SubscriptionCancelledModal}>
      <div className={style.SubscriptionCancelledContainer}>
        {renderContent}
        <p>
          <Message
            id="We will send you an email with the exact end date of your Subscription Plan."
            comment="Text in the free plan modal"
          />
        </p>
        <p>
          <Message
            id="If you have any questions, please email us on {email}"
            values={{
              email: <a className={style.Link} href="mailto:support@oneflow.com">support@oneflow.com</a>,
            }}
            comment="Text providing email address for questions"
          />
        </p>
        <p>
          <TermsOfUseLink />
        </p>
      </div>
    </div>
  );
};

export default SubscriptionCancelledModal;
