import Button from 'components/button';

import type { CancelFlowAction } from 'types/cancellation-flow';

export type CancelFlowActionClose = CancelFlowAction & {
  onStepComplete: () => void,
  onClose: () => void,
}

const Close = ({
  children,
  kind,
  color,
  onStepComplete,
  onClose,
}: CancelFlowActionClose) => {
  const onClick = () => {
    onStepComplete();
    onClose();
  };
  return (
    <Button
      kind={kind}
      color={color}
      onClick={onClick}
      disabled={false}
    >
      {children}
    </Button>
  );
};

export default Close;
