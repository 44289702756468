// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import workspacesReducer from 'reducers/entities/workspaces';

import { DeleteWorkspace, type Props } from './delete-workspace';

export const QUERY_NAME = 'admin/workspaces';

type StateProps = {|
  removeState: RemoveState,
|};

type DispatchProps = {|
  removeWorkspace: () => void,
  resetRemoveState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  workspace: Workspace,
  isLastWorkspace: boolean,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { workspace }) => ({
  removeState: workspacesReducer.getRemoveSelector(state, {
    id: workspace.id,
  }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { workspace }) => ({
  removeWorkspace: () => {
    dispatch(workspacesReducer.removeWorkspace({
      id: workspace.id,
      data: {
        ids: [workspace.id],
      },
      pipe: {
        action: () => workspacesReducer.queryWorkspacesReload({
          name: QUERY_NAME,
        }),
        wait: workspacesReducer.QUERY_WORKSPACES_SUCCESS,
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(workspacesReducer.removeWorkspaceReset({
      id: workspace.id,
    }));
  },
});

const connectedDeleteWorkspace = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteWorkspace);

export { connectedDeleteWorkspace as DeleteWorkspace };
