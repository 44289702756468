import { useCallback, useEffect } from 'react';

import ErrorScreenTemplate from 'components/agreement-loader/error-handler/error-screen-template';
import {
  redirectToMFA,
  agreementHasMFA,
} from 'components/agreement-loader/error-handler/error-helpers';
import LoadingScreen from 'components/agreement-loader/loading-screen';

type Props = {
  agreementId: Oneflow.Agreement['id'],
  error: any,
  guestToken: string,
  isLegacy: boolean,
};

const ErrorHandler = ({
  agreementId,
  error,
  guestToken,
  isLegacy,
}: Props) => {
  const triggerErrors = useCallback(() => {
    if (agreementHasMFA(error, guestToken)) {
      redirectToMFA(agreementId, guestToken, !isLegacy);
    }
    // eslint-disable-next-line no-console
    console.error(error);
  }, [
    agreementId,
    isLegacy,
    error,
    guestToken,
  ]);

  useEffect(() => {
    triggerErrors();
  }, [triggerErrors]);

  if (agreementHasMFA(error, guestToken)) {
    return <LoadingScreen isLegacy={isLegacy} />;
  }

  return (
    <ErrorScreenTemplate
      error={error}
      isGuest={Boolean(guestToken)}
    />
  );
};

export default ErrorHandler;
