import { Message } from '@oneflowab/pomes';

import Confirmable from 'components/confirmable';
import Button from 'components/button';
import ApprovalCheck from './approval-check.gif';
import style from './draft-approved.module.scss';

export type Props = {
  sendToNextDraftApprover: boolean;
  onClose: () => void;
  isOpen: boolean;
  autoSendEnabled?: boolean;
  modalKey: string;
};

export const DraftApprovedModal = ({
  sendToNextDraftApprover,
  onClose,
  isOpen,
  autoSendEnabled,
  modalKey,
}: Props) => {
  const renderInformationText = () => {
    if (sendToNextDraftApprover) {
      if (autoSendEnabled) {
        return (
          <>
            <Message
              id="You have approved"
              comment="Text explaining that the document is approved by the participant."
            />
            <div className={style.ConfirmText}>
              <Message
                id="When all approvers approve the document, it will be sent for signing."
                comment="The message shown in the success modal about awaiting approvals."
              />
            </div>
          </>
        );
      }
      return (
        <>
          <Message
            id="You have approved"
            comment="Text explaining that the document is approved by the participant."
          />
          <div className={style.ConfirmText}>
            <Message
              id="When all approvers approve the document, it will be ready for signing."
              comment="The message shown in the success modal about awaiting approvals."
            />
          </div>
        </>
      );
    }
    if (autoSendEnabled) {
      return (
        <Message
          id="Approved and sent for signing"
          comment="Text explaining that the document is approved and sent for signing."
        />
      );
    }

    return (
      <Message
        id="Approved and ready for sending"
        comment="Text explaining that the document is approved and ready to be sent."
      />
    );
  };

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    handleClose();
  };

  const renderHeader = () => {
    if (sendToNextDraftApprover) {
      return (
        <Message
          id="Document approval"
          comment="Title for the document approval modal."
        />
      );
    }
    return (
      <Message
        id="Document approved"
        comment="Title for the document approved modal."
      />
    );
  };

  const renderBody = () => (
    <div className={style.BodyContainer}>
      <img
        src={ApprovalCheck}
        height="160px"
        width="160px"
        alt="Approved"
      />
      <div className={style.BodyContainerText} data-testid="information-message">
        {renderInformationText()}
      </div>
    </div>
  );
  const getActions = () => (
    <div className={style.Confirm}>
      <Button
        kind="primary"
        onClick={handleClose}
      >
        <Message
          id="Done"
          comment="Button label for confirming the document has been approved."
        />
      </Button>
    </div>
  );

  return (
    <Confirmable
      header={renderHeader()}
      body={renderBody()}
      actions={getActions}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      onClose={onClose}
      isOpen={isOpen}
      modalKey={modalKey}
      customBodyClass={style.Body}
    />
  );
};
