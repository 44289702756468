/* eslint-disable import/named */
import {
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  get,
  isEmpty,
} from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  getDataField,
  updateBoxDataItemAction,
} from 'reducers/current-contract';
import { getGuestToken } from 'agreement/selectors';
import { isBoxDataSharedValueUpdateAllowed } from 'agreement/box-data-shared-value-update-permissions';
import { isConcluded } from 'agreement/states';
import { useDocumentPermissionHooks } from 'hooks/use-permissions';
import { usePdfBoxProps } from 'contexts/pdf-box-props';
import useAgreement from 'hooks/use-agreement';
import useCurrentBox from 'hooks/use-current-box';

import type { DataField } from 'data-validators/entity-schemas/agreement-data';
import type { PDFOverlayFieldData } from 'data-validators/entity-schemas/document-box/pdf-box';
import type {
  Position,
  Size,
} from 'types/overlay';

import { checkAcl } from 'components/acl';

import OverlayTextField from './overlay-text-field';
import OverlayFieldRectangle from './overlay-field-rectangle';

type Props = {
  boxId: number,
  canRemoveOverlayField: boolean,
  dataFields: Array<DataField>,
  id: number,
  overlayField: PDFOverlayFieldData,
  pageNumber: number,
  scale: number,
  toBeRemoved?: boolean,
}

const OverlayField = ({
  boxId,
  canRemoveOverlayField,
  dataFields,
  id: overlayFieldContentDataId,
  overlayField,
  pageNumber,
  scale,
  toBeRemoved,
}: Props) => {
  const dispatch = useDispatch();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState(false);
  const { agreementId } = usePdfBoxProps();
  const agreement = useAgreement(agreementId);
  const { useCanAlterBoxComposition } = useDocumentPermissionHooks(agreementId);
  const guestToken = useSelector(getGuestToken);
  const isGuestView = Boolean(guestToken);
  const overlayFieldValue = get(overlayField, 'value');
  const {
    size,
    position,
    valueDataFieldKey,
  } = overlayFieldValue;
  const box = useCurrentBox(boxId) as Box;
  // TODO: determine if we will keep this check here long term.
  const dataFieldObject = useSelector((state) => getDataField(state, valueDataFieldKey));
  const isAllowedToUpdateSharedDataValue = isBoxDataSharedValueUpdateAllowed(box, overlayField);
  const readOnly = !isAllowedToUpdateSharedDataValue;

  const canAlterBoxComposition = useCanAlterBoxComposition(boxId);
  // TODO: Data model don't have readOnly prop yet
  // `readOnly` is individual field level lock flag
  // Meant for counter parties
  const canAlterAgreementLayout = checkAcl(agreement?.acl, 'agreement:layout:update');
  const isBroken = isEmpty(dataFieldObject);
  const shouldHideBrokenField = isBroken && (isGuestView || isConcluded(agreement));
  const isValueEmpty = !dataFieldObject?.value?.value;
  const isRequired = overlayFieldValue?.required;
  const isAgreementConcluded = isConcluded(agreement);

  const overlayFieldContainerRef = useRef<HTMLDivElement | null>(null);
  const onResize = useCallback((newSize: Size) => {
    dispatch(updateBoxDataItemAction(
      boxId,
      overlayFieldContentDataId,
      {
        value: {
          ...overlayFieldValue,
          size: newSize,
        },
      },
    ));

    textAreaRef.current?.focus();
  }, [boxId, dispatch, overlayFieldContentDataId, overlayFieldValue]);

  const onMove = useCallback((newPosition: Position) => {
    dispatch(updateBoxDataItemAction(
      boxId,
      overlayFieldContentDataId,
      {
        value: {
          ...overlayFieldValue,
          position: newPosition,
        },
      },
    ));

    textAreaRef.current?.focus();
  }, [boxId, dispatch, overlayFieldContentDataId, overlayFieldValue]);

  const handleOnFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setFocused(false);
  }, []);

  // We could add value in the API response and make it readonly
  // That is product decision
  if (toBeRemoved || shouldHideBrokenField) {
    return null;
  }

  return (
    <OverlayFieldRectangle
      overlayFieldContentDataId={overlayFieldContentDataId}
      overlayFieldContainerRef={overlayFieldContainerRef}
      dataFieldName={dataFieldObject?.value.name}
      position={position}
      size={size}
      scale={scale}
      canAlterBoxComposition={canAlterBoxComposition}
      canAlterAgreementLayout={canAlterAgreementLayout}
      onResize={onResize}
      onMove={onMove}
      focused={focused}
      isValueEmpty={isValueEmpty}
      isRequired={isRequired}
      isBroken={isBroken}
      isConcluded={isAgreementConcluded}
      type="data_field"
    >
      <OverlayTextField
        agreement={agreement}
        key={valueDataFieldKey}
        textAreaRef={textAreaRef}
        overlayFieldContainerRef={overlayFieldContainerRef}
        focused={focused}
        boxId={boxId}
        dataFieldObject={dataFieldObject}
        overlayFieldContentDataId={overlayFieldContentDataId}
        overlayFieldValueId={overlayFieldValue?.id}
        pageNumber={pageNumber}
        topPosition={position.y}
        readOnly={readOnly}
        canRemoveOverlayField={canRemoveOverlayField}
        isRequired={isRequired}
        isConcluded={isAgreementConcluded}
        dataFields={dataFields}
        overlayFieldValue={overlayFieldValue}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    </OverlayFieldRectangle>
  );
};

export default OverlayField;
