// @flow

import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import agreements from 'reducers/entities/agreements';
import useAgreement from 'hooks/use-agreement';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';

import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'components/select-field-radix';
import ChevronDownIcon from 'components/icons/chevron-down';
import { checkAcl } from 'components/acl';
import { getEnabledLanguagesAsOptions } from 'components/languages';
import Message from 'components/message';
import Label from 'components/document-tabs/settings-tab/components/label';

import selectStyle from 'components/select-field-radix/select-field-radix.module.scss';
import style from './language-selector.module.scss';

type Props = {
  agreementId: number,
  onAgreementAttributeUpdate: (language: string) => void,
}
const LanguageSelector = ({
  agreementId,
  onAgreementAttributeUpdate,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const dispatch = useDispatch();
  const { resetRPCStates } = useAPIError(agreementId);
  const originalValue = agreement?.language;
  const [
    selectedLanguage,
    setSelectedLanguage,
  ] = useState<string | null>(agreement?.language);

  const languages = getEnabledLanguagesAsOptions();

  const disabled = !checkAcl(agreement?.acl, 'agreement:update:language');

  const handleFailure = useCallback(() => {
    setSelectedLanguage(originalValue);
  }, [originalValue]);

  const updateLanguageSettings = (language: string) => {
    resetRPCStates();
    dispatch(agreements.updateAgreementPreferences({
      id: agreementId,
      data: { id: agreementId, language },
      pipe: {
        onSuccess: (data) => {
          const updatedAgreement = data.entities.agreements[data.result];
          onAgreementAttributeUpdate(updatedAgreement.language);
        },
        onFailure: handleFailure,
      },
    }));
  };

  const onChange = (value) => {
    if (value !== selectedLanguage && !disabled) {
      setSelectedLanguage(value);
      updateLanguageSettings(value);
    }
  };

  const labelMessage = <Message id="Display language" comment="Language selector header" data-testid="settings-label" />;

  const tooltipMessage = (
    <Message
      id="Show buttons, headings, and notifications in the selected language for all participants."
      comment="Tooltip description for display language"
    />
  );

  const label = (
    <div
      className={style.Label}
    >
      <Label
        hint={tooltipMessage}
      >
        {labelMessage}
      </Label>
    </div>
  );

  if (isEmpty(agreement)) {
    return null;
  }

  return (
    <div className={style.LanguageSelector}>
      {label}
      <Select
        disabled={disabled}
        defaultValue={originalValue}
        onValueChange={onChange}
      >
        <SelectTrigger
          className={selectStyle.SelectTrigger}
        >
          <SelectValue />
          <ChevronDownIcon height="10px" />
        </SelectTrigger>
        <SelectContent
          position="popper" // for Firefox
          className={selectStyle.SelectContent}
        >
          <SelectViewport>
            {languages.map((language) => (
              <SelectItem
                key={language.value}
                value={language.value}
                className={selectStyle.SelectItem}
              >
                {language.label}
              </SelectItem>
            ))}
          </SelectViewport>
        </SelectContent>
      </Select>
      <div className={style.FieldDescription} data-testid="settings-field-description">
        <Message
          id="Choose the language for the interface and notifications."
          comment="Language selector field description"
        />
      </div>
    </div>
  );
};

export default LanguageSelector;
