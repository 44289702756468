import type { ReactNode } from 'react';
import clsx from 'clsx';

import style from './state-pill.module.scss';

type Props = {
  className?: string,
  onClick?: () => void,
  isClickable?: boolean,
  children: ReactNode,
}

const StatePill = ({
  className, onClick, isClickable = true, children,
}: Props) => {
  if (!isClickable) {
    return (
      <div className={clsx(style.StatusPill, className)}>
        {children}
      </div>
    );
  }

  return (
    <button
      onClick={onClick}
      className={clsx(style.StatusPillButton, className)}
    >
      {children}
    </button>
  );
};

export default StatePill;
