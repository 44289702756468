// @flow

import { matchPath } from 'react-router';

import type { Match, Location } from 'react-router';

class PathStorage {
  value: string;

  path: string;

  constructor(path: string) {
    this.path = path;
    this.value = '';
  }

  cache(location: Location) {
    const match: null | Match = matchPath(location.pathname, {
      path: this.path,
      exact: true,
    });

    if (match) {
      this.value = location.search || '';
    }

    if (this.value) {
      return this.value;
    }

    return '';
  }
}

type Storage = {
  [string]: PathStorage,
};

export const storage: Storage = {};

export const clearSearchCacheStorage = (path: string) => {
  if (storage[path]) {
    delete storage[path];
  }
};

export const getPathSearch = (path: string) => {
  if (storage[path] && storage[path].value) {
    return storage[path].value;
  }
  return '';
};

type MakeSearchCache = (path: string) => (location: Location) => string;

const makeSearchCache: MakeSearchCache = (path) => {
  if (!storage[path]) {
    storage[path] = new PathStorage(path);
  }

  return (location: Location) => {
    if (storage[path]) {
      return storage[path].cache(location);
    }
    return '';
  };
};

export default makeSearchCache;
