// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  getEndOfDay,
  formatDateString,
  getDaysUntil,
} from 'date';

import CalendarUnderscore from 'components/icons/calendar-underscore';

import Insight from './insight';

import style from './insight.module.scss';

type Props = {
  periodEndTime?: string,
  dateFormat: string,
};

const ActiveUntil = ({ periodEndTime, dateFormat }: Props) => {
  const renderInsight = () => {
    if (!periodEndTime) {
      return <Message id="Active" comment="Insight for signed agreement. Visible in contract card." />;
    }

    const activeUntil = getEndOfDay(periodEndTime).subtract(1, 'days').format();
    const daysUntil = getDaysUntil(activeUntil);
    const dateString = formatDateString(activeUntil, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    if (daysUntil === 0) {
      return (
        <Message
          id="Active until today ({date})"
          values={{
            date,
          }}
          comment="Insight for signed agreement, stating when agreement ends. Visible in contract card."
        />
      );
    }

    if (daysUntil > 7) {
      return (
        <Message
          id="Active until {date}"
          values={{
            date,
          }}
          comment="Insight for signed agreement, stating when agreement ends. Visible in contract card."
        />
      );
    }

    if (daysUntil > 0) {
      return (
        <Message
          id="Active until tomorrow ({date})"
          pluralId="Active {days} more days ({date})"
          pluralCondition="days"
          values={{
            days: daysUntil,
            date,
          }}
          comment="Insight for signed agreement, stating when agreement ends. Visible in contract card."
        />
      );
    }

    return null;
  };

  return (
    <Insight
      IconComponent={CalendarUnderscore}
      insight={renderInsight()}
    />
  );
};

export default ActiveUntil;
