import { put } from 'redux-saga/effects';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getPosition,
  getPositions,
  createPosition,
  updatePosition,
  resendUserInvitation,
  updatePositionSettings,
  revokeTrustedDevices,
} from 'oneflow-client/positions';

import apiWrapper from 'sagas/api-wrapper';

import positions from 'reducers/entities/positions';
import groups from 'reducers/entities/groups';

export function* createMapper({ data }) {
  yield put(positions.setPositions(data.entities.positions));
}

export function* mapper({ data }) {
  yield put(groups.setGroups(data.entities.groups));
  yield put(positions.setPositions(data.entities.positions));
}

const mappers = {
  query: {
    mapper,
    request: getPositions,
  },
  fetch: {
    mapper,
    request: getPosition,
  },
  create: {
    mapper: createMapper,
    request: createPosition,
  },
  update: {
    mapper,
    request: updatePosition,
  },
  rpcs: {
    resendInvitation: {
      name: 'resendInvitation',
      request: resendUserInvitation,
    },
    updateSettings: {
      name: 'updateSettings',
      request: updatePositionSettings,
    },
    revokeTrustedDevices: {
      name: 'revokeTrustedDevices',
      request: revokeTrustedDevices,
    },
  },
};

const positionsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: positions,
  mappers,
});

export default positionsSagas;
