// @flow

import * as React from 'react';

import style from './processing-status.module.scss';

type Props = {
  statusMessage: React.Node,
  actions?: React.Node,
  subtext?: React.Node,
  icon?: React.Node,
};

const ProcessingStatus = ({
  actions,
  statusMessage,
  subtext,
  icon,
}: Props) => {
  let actionsContainer = null;

  if (actions) {
    actionsContainer = (
      <div className={style.Actions}>{actions}</div>
    );
  }

  return (
    <div className={style.ProcessingStatus}>
      <div className={style.ProcessingStatusContainer}>
        {icon}
        <div className={style.ProcessingStatusMessage}>{statusMessage}</div>
        {subtext}
        {actionsContainer}
      </div>
    </div>
  );
};

ProcessingStatus.defaultProps = {
  icon: undefined,
  subtext: undefined,
  actions: undefined,
};

export default ProcessingStatus;
