// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  extension: Extension,
  isLinked: boolean,
};

export class ExtensionName extends React.PureComponent<Props> {
  render() {
    const { extension, isLinked } = this.props;
    const path = extension.settingsPath;

    if (!isLinked) {
      return extension.name;
    }

    return (
      <Link to={path}>
        {extension.name}
      </Link>
    );
  }
}
