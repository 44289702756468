// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { MenuItem } from 'components/menu-item';
import Copy from 'components/icons/copy';

export type Props = {|
  disabled?: boolean,
  onClick: Function,
|};

export const CopyMenuItem = ({
  disabled,
  onClick,
}: Props) => (
  <MenuItem
    icon={Copy}
    label={(
      <Message
        id="Copy"
        comment="Label for copy action in menu."
      />
    )}
    disabled={disabled}
    onClick={onClick}
  />
);

CopyMenuItem.defaultProps = {
  disabled: undefined,
};
