// @flow

import * as React from 'react';

import {
  isSignedAndEndedAndTerminated,
  isSignedAndEndedWithNoDurationType,
  isSignedAndEnded,
  isSignedWithRecurringTypeAndCanceled,
  isSignedAndAwaiting,
  isSignedAndActiveWithRecurringType,
  isSignedAndActive,
  isSignedWithoutLifecycle,
  isPending,
  isDraft,
  isOverdue,
  isAnyDeclinedState,
} from 'agreement';

import WorkspaceName from 'components/workspace-name';
import { AiReviewButton } from 'components/contract-card/ai-review-button';
import BreadCrumb from 'components/bread-crumb';

import {
  ActiveUntil,
  Awaiting,
  Canceled,
  Created,
  Declined,
  Ended,
  Overdue,
  Pending,
  Renews,
  Sent,
  Signed,
  Updated,
  Terminated,
  WithoutDuration,
} from './insights';

import style from './contract-insight.module.scss';

type Props = {
  showFolderPath: boolean,
  agreement: Agreement,
  dateFormat: string,
  showWorkspaceName: boolean,
  isOnGlobalSearchPage: boolean,
};

type GetInsight = (agreement: Agreement, dateFormat: string) => React.Node;

type Insight = {|
  condition: (agreement: Agreement) => boolean,
  primary: GetInsight,
  secondary: GetInsight,
|};

type InsightList = Array<Insight>;

const insights = {
  activeUntil: (agreement, dateFormat) => (
    <ActiveUntil periodEndTime={agreement.periodEndTime} dateFormat={dateFormat} />
  ),
  awaiting: (agreement, dateFormat) => (
    <Awaiting startTime={agreement.startTime} dateFormat={dateFormat} />
  ),
  canceled: (agreement, dateFormat) => (
    <Canceled cancelTime={agreement.cancelTime} dateFormat={dateFormat} />
  ),
  created: (agreement, dateFormat) => (
    <Created created={agreement.created} dateFormat={dateFormat} />
  ),
  declined: (agreement, dateFormat) => (
    <Declined agreement={agreement} dateFormat={dateFormat} />
  ),
  ended: (agreement, dateFormat) => (
    <Ended periodEndTime={agreement.periodEndTime} dateFormat={dateFormat} />
  ),
  overdue: (agreement, dateFormat) => (
    <Overdue signingPeriodExpiryTime={agreement.signingPeriodExpiryTime} dateFormat={dateFormat} />
  ),
  pending: (agreement, dateFormat) => (
    <Pending expireDate={agreement.expireDate} dateFormat={dateFormat} />
  ),
  renews: (agreement, dateFormat) => (
    <Renews periodEndTime={agreement.periodEndTime} dateFormat={dateFormat} />
  ),
  sent: (agreement, dateFormat) => (
    <Sent publishTime={agreement.publishTime} dateFormat={dateFormat} />
  ),
  signed: (agreement, dateFormat) => (
    <Signed agreement={agreement} dateFormat={dateFormat} />
  ),
  updated: (agreement, dateFormat) => (
    <Updated updated={agreement.updated} dateFormat={dateFormat} />
  ),
  terminated: (agreement, dateFormat) => (
    <Terminated terminateTime={agreement.terminateTime} dateFormat={dateFormat} />
  ),
  withoutDuration: () => <WithoutDuration />,
};

const insightlist: InsightList = [{
  condition: isSignedAndEndedAndTerminated,
  primary: insights.terminated,
  secondary: insights.signed,
}, {
  condition: isSignedAndEndedWithNoDurationType,
  primary: insights.signed,
  secondary: insights.withoutDuration,
}, {
  condition: isSignedAndEnded,
  primary: insights.ended,
  secondary: insights.signed,
}, {
  condition: isSignedWithRecurringTypeAndCanceled,
  primary: insights.canceled,
  secondary: insights.activeUntil,
}, {
  condition: isSignedAndAwaiting,
  primary: insights.signed,
  secondary: insights.awaiting,
}, {
  condition: isSignedAndActiveWithRecurringType,
  primary: insights.signed,
  secondary: insights.renews,
}, {
  condition: isSignedAndActive,
  primary: insights.signed,
  secondary: insights.activeUntil,
}, {
  condition: isSignedWithoutLifecycle,
  primary: insights.signed,
  secondary: insights.withoutDuration,
}, {
  condition: isPending,
  primary: insights.pending,
  secondary: insights.sent,
}, {
  condition: isDraft,
  primary: insights.updated,
  secondary: insights.created,
}, {
  condition: isOverdue,
  primary: insights.overdue,
  secondary: insights.sent,
}, {
  condition: isAnyDeclinedState,
  primary: insights.declined,
  secondary: insights.sent,
}];

export const mapToLink = (folderPath) => ({
  id: folderPath.id,
  label: folderPath.name,
  to: '',
});

const ContractInsight = ({
  showFolderPath,
  agreement,
  dateFormat,
  showWorkspaceName,
  isOnGlobalSearchPage,
}: Props) => {
  const renderInsight = (getInsight: GetInsight) => getInsight(agreement, dateFormat);

  const renderWorkspace = () => {
    let workspace;

    if (showWorkspaceName) {
      workspace = agreement.collection;

      if (!workspace) {
        return null;
      }

      if (agreement.folder === null) {
        return (
          <WorkspaceName workspace={workspace} />
        );
      }

      const folders = agreement.folder.resolvedPath;

      const workspaceName = {
        id: -1,
        name: agreement.collection.name,
        to: '',
      };

      const folderPath = [...folders];
      folderPath.unshift(workspaceName);

      return (
        <BreadCrumb
          isSearchedFolderBreadcrumb
          isFolderBreadCrumb
          links={folderPath.map(mapToLink)}
        />
      );
    }

    return null;
  };

  const renderFolderBreadCrumb = () => {
    if (
      isOnGlobalSearchPage
      || !showFolderPath
      || !agreement.collection
    ) {
      return null;
    }

    if (!agreement.collection) {
      return null;
    }

    const folderPath = agreement.folder?.resolvedPath || [];

    const workspaceName = {
      id: -1,
      name: agreement.collection.name,
      to: '',
    };

    if (!folderPath.some((folder) => folder.id === workspaceName.id)) {
      folderPath.unshift(workspaceName);
    }

    return (
      <BreadCrumb
        isSearchedFolderBreadcrumb
        isFolderBreadCrumb
        links={folderPath.map(mapToLink)}
      />
    );
  };

  const contractInsight = insightlist.find((insight) => insight.condition(agreement));

  if (!contractInsight) {
    return null;
  }

  const { primary, secondary } = contractInsight;

  return (
    <div className={style.ContractInsight}>
      <div className={style.InsightsPrimary}>
        {renderInsight(primary)}
        {renderInsight(secondary)}
      </div>
      <div className={style.InsightsSecondary}>
        <AiReviewButton
          agreement={agreement}
        />
        {renderFolderBreadCrumb()}
        {renderWorkspace()}
      </div>
    </div>
  );
};

export default ContractInsight;
