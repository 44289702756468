import { useRef } from 'react';
import clsx from 'clsx';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import type { ReactNode } from 'react';

import style from './draggable.module.scss';

type Props = {
  children: ReactNode,
  id: string | number,
  data: unknown,
  draggableType: string,
  customClassName?: string,
  segmentId: string,
}

const Draggable = ({
  children,
  id,
  data,
  draggableType,
  customClassName,
  segmentId,
}: Props) => {
  const droppableDragOverlayRef = useRef(null);
  const {
    attributes,
    listeners,
    transform,
    setNodeRef,
    isDragging,
  } = useDraggable({
    id,
    data: {
      data,
      type: draggableType,
      segmentId,
      droppableDragOverlayRef,
    },
  });

  if (!children) {
    return null;
  }

  const className = clsx(customClassName, {
    [style.IsDragging]: isDragging,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
      }}
      {...listeners}
      {...attributes}
      className={className}
    >
      {children}
    </div>
  );
};

export default Draggable;
