// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import DeleteTableRowIcon from 'components/icons/delete-table-row';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import removeTableRow from 'components/rich-text-editor/editor-plugins/table-utils/remove-table-row';
import ToolbarButton from '../toolbar-button';

const RemoveTableRow = () => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      label={(
        <Message
          id="Remove table row"
          comment="The label of the editor's toolbar plugin that removes a table row."
        />
      )}
      isMenuItem={false}
      onTrigger={() => {
        focusEditor();
        removeTableRow(editor);
      }}
      icon={<DeleteTableRowIcon />}
    />
  );
};

export default RemoveTableRow;
