import getFontAssetsDomain from './get-font-asset-domain.js';

const loadSourceSansPro = () => {
  const normalSourceSansPro = new FontFace(
    'Source Sans Pro',
    `url(${getFontAssetsDomain()}/resources/fonts/source-sans-pro/source-sans-pro_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicSourceSansPro = new FontFace(
    'Source Sans Pro',
    `url(${getFontAssetsDomain()}/resources/fonts/source-sans-pro/source-sans-pro_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldSourceSansPro = new FontFace(
    'Source Sans Pro',
    `url(${getFontAssetsDomain()}/resources/fonts/source-sans-pro/source-sans-pro_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicSourceSansPro = new FontFace(
    'Source Sans Pro',
    `url(${getFontAssetsDomain()}/resources/fonts/source-sans-pro/source-sans-pro_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalSourceSansPro, italicSourceSansPro, boldSourceSansPro, boldItalicSourceSansPro];
};

export default loadSourceSansPro;
