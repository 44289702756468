import {
  call,
  select,
} from 'redux-saga/effects';

import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';
import {
  getCurrentWorkspaceSelector,
} from 'reducers/app';

import { initialiseUserflowWithPosition } from 'utils/userflow';

export function* identifyUserflow() {
  const position = yield select(getPositionFromSessionSelector);
  const workspace = yield select(getCurrentWorkspaceSelector);

  const account = yield select(getAccountFromSessionSelector);

  yield call(initialiseUserflowWithPosition, {
    account,
    position,
    workspaceAcl: workspace.acl,
  });
}

export function* loadUserflow() {
  const position = yield select(getPositionFromSessionSelector);

  if (position && position.id && !position.ghost) {
    yield call(identifyUserflow);
  }
}
