import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import groupPositions from 'reducers/entities/group-positions';
import positionsReducer from 'reducers/entities/positions';

import AddMember from './add-member';

const QUERY_NAME = 'forms/AddMember';

export const mapStateToProps = (state, ownProps) => {
  const availablePositionsQuery = groupPositions.getQuerySelector(state, { name: QUERY_NAME });

  return ({
    availablePositions: positionsReducer.getPositionsSelector(state, {
      ids: availablePositionsQuery.result,
    }),
    availablePositionsQuery,
    formState: groupPositions.getCreateSelector(state),
    shouldRedirectToMembers: ownProps.shouldRedirectToMembers,
  });
};

const defaultPagination = {
  limit: 6,
  offset: 0,
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  resetFormState: () => {
    dispatch(groupPositions.createGroupPositionReset());
  },
  onSubmit: ({ positions }) => {
    const actionData = {
      data: {
        id: ownProps.group.id,
        positionIds: positions.map(({ id }) => id),
      },
      pipe: {
        action: () => groupPositions.queryGroupPositionsReload({
          name: `admin/groups/${ownProps.group.id}/members`,
        }),
      },
    };

    if (ownProps.shouldRedirectToMembers) {
      actionData.pipe.action = () => push(`/admin/groups/${ownProps.group.id}/members`);
    }

    dispatch(groupPositions.createGroupPosition(actionData));
  },
  queryAvailablePositions: ({ fullname, pagination = defaultPagination }) => {
    dispatch(groupPositions.queryGroupPositions({
      name: QUERY_NAME,
      params: {
        groupId: ownProps.group.id,
        q: fullname,
        available: 1,
      },
      pagination,
    }));
  },
  queryAvailablePositionsLoadMore: ({ additionalResults }) => {
    dispatch(groupPositions.queryGroupPositionsLoadMore({
      name: QUERY_NAME,
      additionalResults,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);
