// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getCurrentWorkspaceIdSelector, getCurrentWorkspaceSelector } from 'reducers/app';
import agreementTemplatesReducer, {
  getTemplatesWithAgreementsSelector,
} from 'reducers/entities/agreement-templates';
import { SYSTEM_TAG_ID } from 'tags/constants';

import TemplateSearch from './template-search';

type StateProps = {|
  templates: Array<AgreementTemplate>,
  queryName: string,
  workspace: Workspace,
|};

type DispatchProps = {|
  requestTemplates: (string, ?any) => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  isInImportContractModal: boolean,
  isSearching: boolean,
};

const getWorkspaceQuerySelector = (state, { queryName }) => (
  `workspace-${getCurrentWorkspaceIdSelector(state)}::${queryName}`
);

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const queryName = getWorkspaceQuerySelector(state, { queryName: 'active-templates' });
  const queryNameWithImportTag = getWorkspaceQuerySelector(state, {
    queryName: 'active-templates-with-import-tag',
  });
  const query = agreementTemplatesReducer.getQuerySelector(state, { name: queryName });
  const templates = getTemplatesWithAgreementsSelector(state, { ids: query.result });

  return {
    templates,
    queryName,
    queryNameWithImportTag,
    workspace: getCurrentWorkspaceSelector(state),
  };
};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { isInImportContractModal }) => ({
  requestTemplates: (queryName, value) => {
    let params = {
      visible: 1,
      q: value,
    };

    if (isInImportContractModal) {
      params = {
        ...params,
        tags: [SYSTEM_TAG_ID],
      };
    }

    dispatch(agreementTemplatesReducer.queryAgreementTemplates({
      name: queryName,
      pagination: {
        limit: 10000,
        offset: 0,
      },
      params,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateSearch);
