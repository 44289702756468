// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import keys from 'lodash/keys';

import Button from 'components/button';
import Divider from 'components/divider';
import { useFilterContext } from 'hocs/search/filter/context';
import { useQueryStringParams } from 'hocs/query-string-params';

import style from './search-filters-information.module.scss';

const ignoredOperators = ['positionOperator', 'tagOperator', 'folderIds', 'q'];

export const SearchFiltersInformation = () => {
  const { activeFilters } = useFilterContext();
  const { params, replaceParams } = useQueryStringParams();
  const filters = keys(activeFilters).filter((key) => !ignoredOperators.includes(key));
  const filtersCount = filters ? filters.length : undefined;

  const renderClearAll = (count) => {
    if (count === 0) {
      return null;
    }

    return (
      <Button
        kind="link"
        className={style.ResetAll}
        onClick={() => replaceParams({
          folderIds: params?.folderIds || [],
          q: params?.q,
        })}
      >
        <Message
          id="Reset"
          comment="Option to reset all active filters to their default state in a search"
        />
      </Button>
    );
  };

  const renderBody = () => (
    <div className={style.SearchFilterContainer}>
      <div className={style.SearchFilter}>
        <div className={style.FilterHeader}>
          <h3 className={style.Title}>
            <Message
              id="Filters"
              comment="Header in the filters section."
            />
          </h3>
        </div>
        <div>
          {renderClearAll(filtersCount)}
        </div>
      </div>
      <Divider />
    </div>
  );

  return renderBody();
};
