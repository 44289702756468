// @flow

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { getContractMessageTemplates } from 'oneflow-client/message-templates';
import { sortParticipantsByFullname } from 'agreement/participant';
import { getMessageRecipients } from 'agreement/selectors';
import agreementsReducer from 'reducers/entities/agreements';
import { getPositionFromSessionSelector } from 'reducers/session';

import ModalForm from 'hocs/modal-form';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import Error from 'components/icons/error';
import SendIcon from 'components/icons/send';
import CircularSpinner from 'components/icons/circular-spinner';
import MessageParticipantsBody from './message-participants-body';

import style from './message-participants.module.scss';

const modalKey = 'remind participants modal';
export type FormData = {|
  subject: string,
  body: string,
|};

export type Props = {
  agreementId: number,
  message: MessageTranslator,
  children?: Function,
  isSubjectFieldRequired?: boolean,
  isMessageFieldRequired?: boolean,
  messageTemplatesDisabled?: boolean,
};

export const MessageParticipantsComponent = ({
  agreementId,
  message,
  children,
  isSubjectFieldRequired,
  isMessageFieldRequired,
  messageTemplatesDisabled,
}: Props) => {
  const [contractMessageTemplates, setContractMessageTemplates] = useState([]);
  const [isLoadingMessageTemplates, setIsLoadingMessageTemplates] = useState(false);
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState(undefined);
  const [defaultMessageTemplate, setDefaultMessageTemplate] = useState(undefined);
  const [error, setError] = useState(undefined);

  const agreement = useSelector((state) => (
    agreementsReducer.getAgreementSelector(state, { id: agreementId })
  ));
  const position = useSelector((state) => (getPositionFromSessionSelector(state)));

  const rpcState = useSelector((state) => (
    agreementsReducer.getMessageParticipantsSelector(state, { id: agreementId })
  ));

  const dispatch = useDispatch();
  const resetRpcState = () => {
    dispatch(agreementsReducer.messageParticipantsReset({ id: agreementId }));
  };

  const messageParticipantsDispatch = (
    formData: FormData,
    toParticipantIds: Array<number>,
    isMessagePrivate: number,
  ) => {
    dispatch(agreementsReducer.messageParticipants({
      id: agreementId,
      data: {
        subject: formData.subject,
        message: formData.body,
        toParticipantIds,
        isPrivate: isMessagePrivate,
      },
    }));
  };

  const getActions = ({ closeConfirmation, formProps }) => (
    <div className={style.ActionButtons}>
      <CancelButton onClick={closeConfirmation} modalKey={modalKey} />
      <Button
        color="yellow"
        data-testid="confirm"
        icon={rpcState.loading ? CircularSpinner : SendIcon}
        onClick={formProps.handleSubmit}
        disabled={
          isLoadingMessageTemplates
          || rpcState.loading
          || error
          || !isEmpty(formProps.errors)
        }
      >
        <Message
          id="Send"
          comment="Button label used to confirm sending a contract in a modal"
        />
      </Button>
    </div>
  );

  const getErrorActions: GetActions = ({ closeConfirmation }) => (
    <CancelButton onClick={closeConfirmation} modalKey={`${modalKey} - error action`} />
  );

  const getMessageBody = (messageTemplate: MessageTemplate) => {
    if (messageTemplatesDisabled) {
      return undefined;
    }

    if (position.signature && !isEmpty(messageTemplate) && messageTemplate.body) {
      return `${messageTemplate.body}\n\n${position.signature}`;
    }

    if (position.signature) {
      return position.signature;
    }

    if (messageTemplate && messageTemplate.body) {
      return messageTemplate.body;
    }

    return undefined;
  };

  function getDefaultRecipientsCategory() {
    const unsignedRecipients = getMessageRecipients(agreement, 'unsignedParticipants');
    if (unsignedRecipients.length === 0) {
      return 'allParticipants';
    }

    return 'unsignedParticipants';
  }

  function getInitialValues() {
    const defaultRecipientsCategory = getDefaultRecipientsCategory();
    const initialRecipients = getMessageRecipients(agreement, defaultRecipientsCategory);

    return {
      subject: defaultMessageTemplate?.subject,
      body: getMessageBody(defaultMessageTemplate),
      recipients: sortParticipantsByFullname(initialRecipients),
      recipientsCategory: defaultRecipientsCategory,
    };
  }

  const handleSubmit = (formData: FormData) => {
    if (!formData) {
      return;
    }

    const toParticipantIds = formData.recipients
      .map((recipient) => recipient.id);

    const isRecipientsColleagues = formData.recipients.map((recipient) => recipient.account?.id);

    let isMessagePrivate = 0;
    if (isRecipientsColleagues.every((recipientAccount) => recipientAccount === position.account)) {
      isMessagePrivate = 1;
    }

    messageParticipantsDispatch(formData, toParticipantIds, isMessagePrivate);
  };

  const getModalTitle = () => (
    <Message
      id="Message Participants"
      comment="Confirm modal title for sending a contract message."
    />
  );

  const fetchMessageTemplates = async () => {
    if (messageTemplatesDisabled) {
      return;
    }

    try {
      setIsLoadingMessageTemplates(true);
      setError(null);

      const { collection } = await getContractMessageTemplates({
        contractId: agreement.id,
        type: 'reminder',
      });

      setContractMessageTemplates(collection);
      setDefaultMessageTemplate();
      setSelectedMessageTemplate();
      setIsLoadingMessageTemplates(false);
    } catch (e) {
      setContractMessageTemplates([]);
      setIsLoadingMessageTemplates(false);
      setError(e);
    }
  };

  function renderBody() {
    if (isLoadingMessageTemplates) {
      return (
        <div className={style.SpinnerContainer}>
          <CircularSpinner />
        </div>
      );
    }

    if (error) {
      return (
        <span className={style.ErrorMessage}>
          <Error className={style.ErrorIcon} />
          <Message
            id="An error has occurred. Please reload the page and try again."
            comment="Used to show the error message after confirmation."
          />
        </span>
      );
    }

    return (
      <MessageParticipantsBody
        agreement={agreement}
        contractMessageTemplates={contractMessageTemplates}
        defaultRecipientsCategory={getDefaultRecipientsCategory()}
        getMessageBody={getMessageBody}
        isMessageFieldRequired={isMessageFieldRequired}
        isSubjectFieldRequired={isSubjectFieldRequired}
        message={message}
        selectedMessageTemplate={selectedMessageTemplate}
        updateSelectedMessageTemplate={setSelectedMessageTemplate}
        signature={position.signature}
      />
    );
  }

  return (
    <ModalForm
      title={getModalTitle()}
      body={renderBody()}
      actions={getActions}
      errorActions={getErrorActions}
      resetFormState={resetRpcState}
      formState={rpcState}
      onSubmit={handleSubmit}
      onOpen={fetchMessageTemplates}
      initialValues={getInitialValues()}
      initialValuesEqual={isEqual}
      modalKey={modalKey}
    >
      {children || null}
    </ModalForm>
  );
};

MessageParticipantsComponent.defaultProps = {
  children: null,
  isSubjectFieldRequired: true,
  isMessageFieldRequired: true,
  messageTemplatesDisabled: false,
};

export default localize<Props>(MessageParticipantsComponent);
