import { useMemo } from 'react';

import { useFormBoxProps } from 'contexts/form-box-props';

import Message from 'components/message';
import EmptyFormBox from 'components/icons/empty-form-box';
import BoxEmptyDraftState from 'components/box-empty-draft-state';
import AddRowButton from 'components/contract-boxes/form-box/components/add-row-button';
import FormBoxRows from 'components/contract-boxes/form-box/rows/form-box-rows';

import style from './form-box-layout.module.scss';

type Props = {
  isEmpty: false,
  onAddFieldFromEmpty: () => void,
  onAddRow: () => void,
  setActivePopover: () => void,
  activePopoverIndexMap: void,
  activeId: '',
  dndActive: false,
  activeField: any,
  setActiveField: () => void,
};
const FormBoxLayout = ({
  isEmpty,
  onAddFieldFromEmpty,
  onAddRow,
  setActivePopover,
  activePopoverIndexMap,
  activeId,
  dndActive,
  activeField,
  setActiveField,
}: Props) => {
  const {
    isAllowedToAddBoxData,
  } = useFormBoxProps();

  const renderContent = useMemo(() => {
    if (isEmpty) {
      return (
        <BoxEmptyDraftState
          buttonText={(
            <Message
              id="Add field"
              comment="Form box empty state button label."
            />
          )}
          icon={EmptyFormBox}
          isDisabled={!isAllowedToAddBoxData}
          onClick={onAddFieldFromEmpty}
        />
      );
    }

    return (
      <FormBoxRows
        setActivePopover={setActivePopover}
        activePopoverIndexMap={activePopoverIndexMap}
        activeId={activeId}
        dndActive={dndActive}
        setActiveField={setActiveField}
        activeField={activeField}
      />
    );
  }, [
    isEmpty,
    onAddFieldFromEmpty,
    isAllowedToAddBoxData,
    setActivePopover,
    activePopoverIndexMap,
    activeId,
    dndActive,
    setActiveField,
    activeField,
  ]);

  const renderAddRowButton = useMemo(() => {
    if (!isEmpty && isAllowedToAddBoxData) {
      return (
        <div className={style.ButtonContainer}>
          <AddRowButton onClick={onAddRow} />
        </div>
      );
    }

    return null;
  }, [isEmpty, isAllowedToAddBoxData, onAddRow]);

  return (
    <div className={style.Container}>
      {renderContent}
      {renderAddRowButton}
    </div>
  );
};

export default FormBoxLayout;
