import React from 'react';
import { Message } from '@oneflowab/pomes';
import { Form as ReactFinalForm } from 'react-final-form';
import type { MessageTranslator } from '@oneflowab/pomes';

// eslint-disable-next-line import/named
import { hasAnyPosition } from 'oneflow-client/positions';
import positionsReducer from 'reducers/entities/positions';

import adminPage from 'hocs/admin-page';
import Field from 'components/field';
import * as EditableForm from 'components/editable-form';
import { DetailsFooter } from 'components/details-footer';
import { LocalizedDateTime } from 'components/localized-date-time';
import { checkAcl } from 'components/acl';
import TextField from 'components/text-field';
import Toggle from 'components/toggle';
import SelectField from 'components/select-field';
import UserRole, {
  getUserRolesAsOptions,
  getUserRole,
} from 'components/user-roles';
import emailValidationText from 'components/email-validation-text';
import UserSecurityStatus from 'components/user-security-status';

import {
  USER_ROLE_USER,
  USER_ROLE_LIMITED,
  isUserActive,
} from 'user';

import EmailChangeNotification from 'components/details/notification/email-change-notification';
import ChangePending from 'components/details/status-pill/change-pending';
import style from './user-details.module.scss';

type UpdateUserData = {
  id: number,
  data: Oneflow.Position
};

export type Props = {
  updateUser: (userData: UpdateUserData) => void,
  resetUpdateState: () => void,
  updateState: ReturnType<typeof positionsReducer.getUpdateSelector>,
  message: MessageTranslator,
  account: Oneflow.Account,
  fetchAccount: (id: number) => void,
  hasAvailableSeats: boolean,
  isPositionLimitedFeatureEnabled: boolean,
  location: Location,
  updatePath: (pathname: string) => void,
  position: Oneflow.Position,
};

type State = {
  pendingEmail: string,
  isEditMode: boolean,
};

export class UserDetails extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.updateState.success) {
      if (state.isEditMode) {
        return { isEditMode: false };
      }
    }

    return null;
  }

  state: State = {
    pendingEmail: '',
    isEditMode: false,
  };

  setIsEditMode = (isEditMode: boolean) => {
    const { resetUpdateState } = this.props;

    // In case user submits, and then clicks on edit, since the if statement in
    // getDerivedStateFromProps becomes true, then isEditMode is set to false always.
    // So we need to reset the form state here.
    resetUpdateState();

    this.setState({ isEditMode });
  };

  componentDidMount() {
    const {
      fetchAccount,
      account,
    } = this.props;

    fetchAccount(account.id);
  }

  componentDidUpdate() {
    this.enableEditModeIfRedirected();
  }

  getAvailableUserRoles = () => {
    const {
      position,
      message,
      isPositionLimitedFeatureEnabled,
      hasAvailableSeats,
    } = this.props;
    const isUserRoleLimited = position.userRole === USER_ROLE_LIMITED;

    if (isUserRoleLimited && !hasAvailableSeats && isPositionLimitedFeatureEnabled) {
      return [getUserRole(
        USER_ROLE_LIMITED,
        isPositionLimitedFeatureEnabled,
        message,
      )];
    }

    return getUserRolesAsOptions(
      isPositionLimitedFeatureEnabled,
      message,
    );
  };

  getInitialValues() {
    const { position } = this.props;
    const {
      fullname,
      email,
      phoneNumber,
      title,
    } = position;
    let {
      userRole,
    } = position;

    const isRoleUser = userRole === USER_ROLE_USER;
    userRole = isRoleUser ? USER_ROLE_USER : USER_ROLE_LIMITED;

    return {
      active: isUserActive(position),
      fullname,
      phoneNumber,
      title,
      email,
      userRole,
    };
  }

  uniquePositionEmail = ({ params }: { params: any }) => {
    const { position } = this.props;

    return hasAnyPosition({
      params,
      entityId: position.id,
      props: {
        returnEntity: true,
      },
    });
  };

  enableEditModeIfRedirected() {
    const { updatePath, location } = this.props;

    if (!this.state.isEditMode && location?.query.edit && this.checkEditPermission()) {
      updatePath(location.pathname);

      this.setIsEditMode(true);
    }
  }

  checkEditPermission() {
    const { position } = this.props;

    return (checkAcl(position.acl, [
      'position:update:active',
      'position:update:fullname',
      'position:update:email',
      'position:update:phone_number',
      'position:update:title',
    ], { match: 'any' }));
  }

  hasError() {
    const { updateState } = this.props;
    return updateState.error;
  }

  getErrorMessage() {
    const { updateState } = this.props;
    // backend throws 409 error when you want to delete/change the last account administrator
    if (updateState.error && updateState.error.body?.status_code === 409) {
      return (
        <Message
          id="You cannot deactivate this user or change the user type. At least one active admin must remain."
          comment="Used to show the error message if the last administrator user wanted to change"
        />
      );
    }

    return null;
  }

  render() {
    const {
      resetUpdateState,
      updateState,
      updateUser,
      position,
      message,
      hasAvailableSeats,
    } = this.props;

    const infoPairs = [
      {
        label: <Message
          id="User ID"
          comment="ID label for the details footer."
        />,
        value: position.id,
      },
      {
        label: <Message
          id="Creation date"
          comment="Creation date label for the details footer"
        />,
        value: <LocalizedDateTime datetime={position.createdTime} />,
      },
      {
        label: <Message
          id="Two-step authentication"
          comment="Two-step authentication label for the details footer"
        />,
        value: <UserSecurityStatus position={position} /> as number,
      },
    ];

    const checkChangedEmail = (newEmail: string) => {
      const emailChanged = position.email !== newEmail;

      if (newEmail && emailChanged) {
        this.setState({
          pendingEmail: newEmail,
          isEditMode: false,
        });
      }
    };

    return (
      <ReactFinalForm
        initialValues={this.getInitialValues()}
        onSubmit={updateUser}
        render={({ handleSubmit, ...formProps }) => (
          <form
            onSubmit={(values) => {
              handleSubmit(values);
              checkChangedEmail(formProps.values.email);
              formProps.form.change('email', position.email);
            }}
          >
            <EditableForm.Header>
              <EditableForm.Actions
                resetFormState={resetUpdateState}
                formState={updateState}
                isEditMode={this.state.isEditMode}
                setIsEditMode={this.setIsEditMode}
                disabled={!this.checkEditPermission()}
              />
              {this.hasError() && (
                <EditableForm.ErrorMessage errorMessage={this.getErrorMessage()} />
              )}
            </EditableForm.Header>
            <EditableForm.Body>
              <div className={style.FormBody}>
                <h2 className={style.Title}>
                  <Message
                    id="User details"
                    comment="Title for the user details form."
                  />
                </h2>
                <Field
                  id="fullname"
                  name="fullname"
                  component={TextField}
                  value={position.fullname}
                  placeholder={message({
                    id: 'Enter full name',
                    comment: 'Placeholder for the name field on the user details page.',
                  })}
                  autoFocus
                  maxLength={100}
                  required
                  label={message({
                    id: 'Full name',
                    comment: 'Label for the name field on the user details page.',
                  })}
                  disabled={!this.state.isEditMode || !checkAcl(position.acl, 'position:update:fullname')}
                  labelCustomClass={style.Label}
                />
                <div className={style.EmailContainer}>
                  <Field
                    id="email"
                    name="email"
                    component={TextField}
                    value={position.email}
                    placeholder={message({
                      id: 'Enter email',
                      comment: 'Used as the placeholder of the relevant field in the details form of the profile page',
                    })}
                    maxLength={70}
                    unique={{
                      text: emailValidationText,
                      param: 'email',
                      request: this.uniquePositionEmail,
                      initialValue: position.email,
                    }}
                    email
                    required
                    label={message({
                      id: 'Email address',
                      comment: 'Used as the label of the relevant field in the details form of the profile page',
                    })}
                    disabled={!this.state.isEditMode || !checkAcl(position.acl, 'position:update:email')}
                    labelCustomClass={style.Label}
                  />
                  {this.state.pendingEmail && (
                    <ChangePending />
                  )}
                </div>
                {this.state.pendingEmail && (
                  <EmailChangeNotification email={this.state.pendingEmail} />
                )}
                <Field
                  name="title"
                  component={TextField}
                  value={position.title || '-'}
                  placeholder={message({
                    id: 'Enter title',
                    comment: 'Placeholder for the title field on the user details page.',
                  })}
                  maxLength={100}
                  label={message({
                    id: 'Title',
                    comment: 'Label for the title field on the user details page.',
                  })}
                  disabled={!this.state.isEditMode || !checkAcl(position.acl, 'position:update:title')}
                  labelCustomClass={style.Label}
                />
                <Field
                  name="phoneNumber"
                  component={TextField}
                  value={position.phoneNumber || '-'}
                  placeholder={message({
                    id: 'Enter phone number',
                    comment: 'Placeholder for the phone number field on the user details page.',
                  })}
                  maxLength={50}
                  disabled={!this.state.isEditMode || !checkAcl(position.acl, 'position:update:phone_number')}
                  label={message({
                    id: 'Phone number',
                    comment: 'Label for the phone number field on the user details page.',
                  })}
                  labelCustomClass={style.Label}
                />
                <Field
                  name="userRole"
                  component={SelectField}
                  options={this.getAvailableUserRoles()}
                  value={<UserRole userRole={position.userRole} withTooltip />}
                  clearable={false}
                  searchable={false}
                  placeholder={message({
                    id: 'Select user type',
                    comment: 'Placeholder for the type field on the user details page.',
                  })}
                  required
                  label={message({
                    id: 'User type',
                    comment: 'Label for the type field on the user details page.',
                  })}
                  disabled={!this.state.isEditMode || !checkAcl(position.acl, 'position:update:user_role')}
                  labelCustomClass={style.Label}
                />
                <div className={style.Row}>
                  <div className={style.LabelContainer}>
                    <label className={style.Label} htmlFor="active">
                      <Message
                        id="Status"
                        comment="Label for the status field on the user details page."
                      />
                    </label>
                  </div>
                  <Field
                    name="active"
                    component={Toggle}
                    version="latest"
                    disabled={!this.state.isEditMode || !hasAvailableSeats || !checkAcl(position.acl, 'position:update:active')}
                    containerClassName={style.Label}
                  />
                </div>
              </div>
            </EditableForm.Body>
            <DetailsFooter infoPairs={infoPairs} />
          </form>
        )}
      />
    );
  }
}

export default adminPage(({ props: { message } }) => ({
  title: message({ id: 'Details', comment: 'The page title' }),
  modules: [[]],
}))(UserDetails);
