// @flow

import * as React from 'react';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import InfoBox from 'components/error-box/info-box';
import MessageTemplatePicker from 'components/message-template-picker';
import RecipientsCategoryPicker from '../recipients-category-picker';

import style from './message-participants-body.module.scss';

export type Props = {
  agreement: Agreement,
  contractMessageTemplates: Array<MessageTemplate>,
  selectedMessageTemplate: MessageTemplate,
  updateSelectedMessageTemplate: Function,
  defaultRecipientsCategory: any,
  isSubjectFieldRequired: boolean,
  isMessageFieldRequired: boolean,
  message: MessageTranslator,
  getMessageBody: Function,
  signature: string,
}

export const MessageParticipantsBody = ({
  agreement,
  contractMessageTemplates,
  selectedMessageTemplate,
  updateSelectedMessageTemplate,
  defaultRecipientsCategory,
  isSubjectFieldRequired,
  isMessageFieldRequired,
  getMessageBody,
  message,
  signature,
}: Props) => {
  const renderSignOrderInfo = () => {
    if (!agreement.config?.signOrder) {
      return null;
    }

    return (
      <InfoBox
        headerText={(
          <strong>
            <Message
              id="Signing order is enabled for this contract"
              comment="Text header in a Send modal explaining that the sign order is enabled."
            />
          </strong>
        )}
        bodyText={(
          <Message
            id="Participants will receive the contract invitation based on the signing order."
            comment="Text body in a Send modal explaining that the sign order is enabled."
          />
        )}
      />
    );
  };

  const renderAdditionalInformation = () => (
    <>
      <span className={style.AdditionalInformation}>
        <i>
          <Message
            id="A link to the document will be automatically included with your message."
            comment="Information in a Send modal explaining that the receipent will receive link to this document together will the message."
          />
        </i>
      </span>
      <br />
      <a href="/user/templates" target="_blank">
        <Message
          id="Manage your message templates"
          comment="A label of link to window where the user can manage their message templates."
        />
      </a>
    </>
  );

  return (
    <div>
      {renderSignOrderInfo()}
      <RecipientsCategoryPicker
        agreement={agreement}
        defaultRecipientsCategory={defaultRecipientsCategory}
      />
      <MessageTemplatePicker
        contractMessageTemplates={contractMessageTemplates}
        selectedMessageTemplate={selectedMessageTemplate}
        updateSelectedMessageTemplate={updateSelectedMessageTemplate}
        isSubjectFieldRequired={isSubjectFieldRequired}
        isMessageFieldRequired={isMessageFieldRequired}
        getMessageBody={getMessageBody}
        message={message}
        isReminder // figure this out later
        signature={signature}
        context="agreementMessageParticipants"
      />
      {renderAdditionalInformation()}
    </div>
  );
};

export default localize <Props>(MessageParticipantsBody);
