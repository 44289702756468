// @flow
import { useSelector } from 'react-redux';

import { getClientFeatureFlagSelector } from 'reducers/app';

const useClientFeatureFlag = (featureFlagName: string) => (
  useSelector((state) => (
    getClientFeatureFlagSelector(state, featureFlagName)
  ))
);

export default useClientFeatureFlag;
