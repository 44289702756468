import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';

import type { AIAssistPromptContextParameters } from '../types';

export type Chat = {
  role: 'user';
  content: string;
} | {
  role: 'assistant';
  content: string;
  contextParameters: AIAssistPromptContextParameters | null;
};

export type ChatHistoryContextValue = {
  history: Chat[];
  push: (chatMessage: Chat) => Chat[];
}

const ChatHistoryContext = createContext<ChatHistoryContextValue | null>(null);

const ChatHistoryProvider = ({ children }: { children: ReactNode }) => {
  const [history, setHistory] = useState<Chat[]>([]);

  const push: ChatHistoryContextValue['push'] = (chatMessage) => {
    let updatedHistory: Chat[] = [];
    setHistory((_history) => {
      updatedHistory = [..._history, chatMessage];
      return updatedHistory;
    });
    return updatedHistory;
  };

  return (
    <ChatHistoryContext.Provider value={{
      history,
      push,
    }}
    >
      {children}
    </ChatHistoryContext.Provider>
  );
};

export const useChatHistory = () => {
  const context = useContext(ChatHistoryContext);
  if (!context) {
    throw new Error('useChatHistory must be used within a ChatHistoryProvider');
  }
  return context;
};

export default ChatHistoryProvider;
