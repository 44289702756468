import { useMemo } from 'react';
import { Message } from '@oneflowab/pomes';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import style from './cancel-subscription-with-feedback.module.scss';

export type Props = {
  setCurrentComment: (text: string) => void;
  weAreSad?: boolean;
};

const CancelSubscriptionWithFeedbackModal = ({
  setCurrentComment,
  weAreSad,
}: Props) => {
  const renderContent = useMemo(() => {
    if (weAreSad) {
      return (
        <>
          <p className={style.Strong}>
            <Message
              id="We are sad to see you leave"
              comment="Title for cancel subscription modal"
            />
          </p>
          <p>
            <Message
              id="Oneflow will be here if you change your mind and want to get back to seamless document creation. ✨"
              comment="Text in the cancel subscription modal"
            />
          </p>
        </>
      );
    }

    return (
      <p className={style.Strong}>
        <Message
          id="We'll be here if anything changes 💫"
          comment="Title for cancel subscription modal"
        />
      </p>
    );
  }, [weAreSad]);

  return (
    <div className={style.CancelSubscriptionContainer}>
      {renderContent}
      <p className={style.Strong}>
        <Message
          id="We value your input and would appreciate any feedback you have on how we can improve Oneflow"
          comment="Title for cancel subscription feedback section"
        />
      </p>
      <TextareaAutosize
        minRows={4}
        className={style.TextArea}
        onChange={(event) => setCurrentComment(event.target.value)}
      />
    </div>
  );
};

export default CancelSubscriptionWithFeedbackModal;
