// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { RemoveConfirm } from '../remove-confirm';

export type Props = {|
  selectedMessageTemplates: Array<MessageTemplate>,
  removeState: RemoveState,
  resetRemoveState: () => void,
  deleteMessageTemplates: () => void,
  children: Function,
|};

export const DeleteMessageTemplates = ({
  children,
  removeState,
  resetRemoveState,
  selectedMessageTemplates,
  deleteMessageTemplates,
}: Props) => (
  <RemoveConfirm
    onConfirm={deleteMessageTemplates}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <Message
        id="You are about to delete a message template. This can't be undone."
        pluralId="You are about to delete {count} message templates. This can't be undone."
        pluralCondition="count"
        values={{
          count: selectedMessageTemplates.length,
        }}
        comment="Modal text when deleting one or more message templates."
      />
      )}
    modalKey="delete message templates modal"
  >
    {children}
  </RemoveConfirm>
);
