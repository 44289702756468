// @flow

import * as React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import { hasAnyRole } from 'oneflow-client/roles';

import ModalForm from 'hocs/modal-form';

import Field from 'components/field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import { checkAcl } from 'components/acl';
import Message from 'components/message/message';

import styles from './add-role.module.scss';

export type FormData = {
  name: string,
  scope?: { label: string, value: string },
};

export type Props = {
  message: MessageTranslator,
  createRole: FormData => void,
  createState: RpcState,
  resetCreateState: () => void,
  children: React.Node,
  account: Account,
};

export class AddRoleComponent extends React.PureComponent<Props> {
  state = {
    selectFieldValue: { label: '', value: '' },
  }

  renderBody() {
    const { message, account } = this.props;
    const accessAreas = [
      {
        label: message({
          id: 'Account',
          comment: 'Label for the account role selection inside of the Access areas dropdown.',
        }),
        value: 'account',
      },
      {
        label: message({
          id: 'Workspace',
          comment: 'Label for the workspace role selection inside of the Access areas dropdown.',
        }),
        value: 'workspace',
      },
    ];
    const fieldNameLabel = message({
      id: 'Role name',
      comment: 'The field label where the name of a new role is entered',
    });
    const fieldNamePlaceholder = message({
      id: 'Enter role name',
      comment: 'The placeholder text of the name field',
    });

    const showAccountRoleRelatedElements = checkAcl(account.acl, 'account:account_role:create');
    const showWarning = showAccountRoleRelatedElements && this.state.selectFieldValue.value === 'account';
    return (
      <div>
        {showAccountRoleRelatedElements
          && (
            <Field
              name="scope"
              label={message({
                id: 'Access areas',
                comment: 'Label for the Workspace type field on the create workspace modal.',
              })}
              placeholder={message({
                id: 'Select access area for this role',
                comment: 'Workspace type label on the create workspace modal.',
              })}
              component={SelectField}
              options={accessAreas}
              clearable={false}
              searchable={false}
              required
              onChange={(scope) => this.setState({ selectFieldValue: scope })}
            />
          )}
        <Field
          name="name"
          label={fieldNameLabel}
          placeholder={fieldNamePlaceholder}
          component={TextField}
          maxLength={50}
          autoComplete={false}
          unique={{
            text: message({
              id: 'Sorry, this role already exists. Please try another name.',
              comment: 'Form validation error message.',
            }),
            param: 'name',
            request: hasAnyRole,
          }}
          responsive
          required
          autoFocus={!showAccountRoleRelatedElements}
        />
        {showWarning && (
          <p data-testid="warning-text" className={styles.WarningText}>
            <Message
              id="New account roles have no permissions and must be configured separately in role permissions."
              comment="Description in the create role modal"
            />
          </p>
        )}
      </div>
    );
  }

  render() {
    const {
      createRole,
      createState,
      resetCreateState,
      children,
    } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Create role"
            comment="The text shown in the button for creating a new role"
          />
        )}
        body={this.renderBody()}
        onSubmit={createRole}
        resetFormState={resetCreateState}
        formState={createState}
        modalKey="create role modal"
      >
        {children}
      </ModalForm>
    );
  }
}

export default localize<Props>(AddRoleComponent);
