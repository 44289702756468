// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Editor, Range } from 'slate';

import { MenuItem } from 'components/menu-item';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import { toggleBlock } from '../block-button/block-plugin';
import style from './text-styles.module.scss';

type Props = {
  editor: any,
  type: string,
  label: React.Node,
  isActive: boolean,
};

const TextStyleMenuItem = ({
  editor,
  type,
  label,
  isActive,
}: Props) => {
  const focusEditor = useFocusEditor();
  const menuItemClassNames = clsx(style.TextStyleMenuItem, {
    [style.ActiveTextStyle]: isActive,
  });

  return (
    <MenuItem
      label={label}
      onClick={() => {
        focusEditor();
        toggleBlock(editor, type);
        if (editor.selection && Range.isExpanded(editor.selection)) {
          Editor.removeMark(editor, 'fontSize');
        }
      }}
      className={menuItemClassNames}
    />
  );
};

export default TextStyleMenuItem;
