// @flow

import React, {
  type Node,
  useRef,
} from 'react';
import clsx from 'clsx';

import useCurrentContractId from 'hooks/use-current-contract-id';

import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from 'components/popover';
import SingleMessageBox from 'components/messages-layout/single-message-box';

import style from './single-comment.module.scss';

type Props = {
  children: Node,
  annotation: any,
  isOpen: boolean,
  onEscapeKeyDown: () => void,
  onInteractOutside: () => void,
};

const SingleComment = ({
  children,
  annotation,
  isOpen,
  onInteractOutside,
  onEscapeKeyDown,
}: Props) => {
  const contractId = useCurrentContractId();
  const popoverContentRef = useRef(null);

  return (
    <Popover open={isOpen}>
      <PopoverTrigger asChild>
        {children}
      </PopoverTrigger>
      <PopoverPortal container={document.body}>
        <div className={style.PopoverContentContainer}>
          <PopoverContent
            side="bottom"
            sideOffset={6}
            onOpenAutoFocus={(event) => {
              event.preventDefault();
            }}
            onEscapeKeyDown={onEscapeKeyDown}
            onInteractOutside={onInteractOutside}
            collisionPadding={{ top: 50 }}
            className={clsx(style.PopoverContent, 'rich-text-region')}
            collisionBoundary={document.getElementById('agreement-body')}
            arrowHeight={0}
            ref={popoverContentRef}
            hideWhenDetached
          >
            <div className={style.SingleMessageBoxContainer}>
              <SingleMessageBox
                agreementId={contractId}
                message={annotation}
                amplitudeData={{ location: 'contract view - popover' }}
              />
            </div>
          </PopoverContent>
        </div>
      </PopoverPortal>
    </Popover>
  );
};

export default SingleComment;
