import { PureComponent } from 'react';
import { Message } from '@oneflowab/pomes';
import type { ReactNode } from 'react';

import log from 'logging';

type Props = {
  children: ReactNode,
  errorContext?: string,
  customError?: ReactNode,
  onError?: () => void,
};

type State = {
  hasError: boolean,
};

type Info = {
  componentStack: string,
};

class ErrorBoundary extends PureComponent<Props, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, info: Info) {
    const { onError, errorContext } = this.props;

    if (error.validationInfo) {
      log.error(error, {
        validationInfo: error.validationInfo,
        info,
      });
      return;
    }
    if (onError) {
      onError();
    }

    if (errorContext) {
      log.error(error, {
        errorContext,
        info,
      });
      return;
    }

    log.error(error, info);
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handleUnhandledPromise);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleUnhandledPromise);
  }

  // Due to https://github.com/facebook/jest/issues/5620 unhandledrejection is not testable at the moment
  /* istanbul ignore next */
  handleUnhandledPromise = (event: PromiseRejectionEvent) => {
    log.warning(`Unhandled promise with reason ${event.reason}`, {
      promise: event.promise,
    });
    event.preventDefault(); // Prevent logging as error in console
  };

  render() {
    if (this.state.hasError) {
      if (this.props.customError) {
        return this.props.customError;
      }
      return (
        <h1 data-testid="error-message">
          <Message id="Something went wrong" comment="General error message" />
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
