import { useRef, useState } from 'react';
import { useSlate, useSlateSelection } from 'slate-react';
import { Range } from 'slate';
import { pipe } from 'lodash/fp';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import useCurrentContractId from 'hooks/use-current-contract-id';
// eslint-disable-next-line import/named

// eslint-disable-next-line import/named
import * as Command from 'components/filterable';

import usePagination from './hooks/use-pagination';
import useCompletion from './hooks/use-completion';
import { AIAssistantContext } from './constants';
import { convertEditorSelectionToString } from './helpers';
import { Chat, useChatHistory } from './context/chat-history';
import { useMenuContext } from './ai-assist-menu/components/menu/root-context';
import CompletionMenu from './completion-menu/completion-menu';
import Input from './components/input/input';
import Footer from './components/footer';
import style from './ai-assist-popover.module.scss';
import SelectionMenu from './ai-assist-menu/selection-menu';
import KeepInView from './components/keep-in-view/keep-in-view';

type Props = {
  message: MessageTranslator
};

const Content = ({
  message,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const editor = useSlate();
  const editorSelection = useSlateSelection();
  const documentId = useCurrentContractId();

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const trimmedInputLength = inputValue.trim().length;
  const responseContainerRef = useRef<HTMLDivElement>(null);

  const chatHistory = useChatHistory();
  const aiChatHistory = chatHistory.history.filter((chat) => chat.role === 'assistant');
  const pagination = usePagination(aiChatHistory.length);
  const isEditorSelectionCollapsed = !editorSelection || Range.isCollapsed(editorSelection);

  const {
    complete,
    completion,
    success,
    loading,
    error,
    stop,
  } = useCompletion();

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || !e.shiftKey) return e;
    setInputValue(v => `${v}\n`);
    e.preventDefault();
    return e;
  };
  const { handleKeyDown: menuKeyDownHandler } = useMenuContext();

  const handleSubmit = (prompt: string) => {
    const newChat: Chat = {
      role: 'user',
      // Add selected text to prompt only to the first chat message
      content: prompt,
    };
    const newChatHistory = chatHistory.push(newChat);

    const selectedText = editorSelection && !Range.isCollapsed(editorSelection)
      ? convertEditorSelectionToString(editor, editorSelection)
      : undefined;

    const promise = complete({
      selectedText,
      chat: newChatHistory,
      context: AIAssistantContext.AGREEMENT_INLINE,
      onChunkReceived: () => {
        responseContainerRef.current?.scrollTo(0, responseContainerRef.current?.scrollHeight);
      },
    }).then((res) => {
      chatHistory.push({
        role: 'assistant',
        content: res.result,
        contextParameters: res.contextParameters,
      });
      setInputValue('');
      responseContainerRef.current?.scrollTo(0, responseContainerRef.current?.scrollHeight);
    });
    return promise;
  };

  const handleSelect = (prompt: string) => {
    amplitudeLogEvent(
      'Generate AI Assistance',
      { 'assistance type': prompt, location: 'contract view - popover' },
      { 'document id': documentId },
    );
    handleSubmit(prompt);
  };

  const handleInputSubmit = (prompt: string) => {
    // we shouldn't log the user custom prompt, that's why this is a separate function
    amplitudeLogEvent(
      'Generate AI Assistance',
      { 'assistance type': '[CUSTOM_PROMPT]', location: 'contract view - popover' },
      { 'document id': documentId },
    );
    handleSubmit(prompt);
  };

  const placeholder = isEditorSelectionCollapsed ? message({
    id: 'Ask AI to write anything',
    comment: 'A placeholder text for the input field where the user can ask the AI to edit the text.',
  }) as string : message({
    id: 'Ask AI to edit or generate',
    comment: 'A placeholder text for the input field where the user can ask the AI to edit the text.',
  }) as string;

  return (
    <Command.Root
      listId="ai-assist-list"
      inputId="ai-assist-input"
      labelId="ai-assist-label"
      onKeyDown={
        (trimmedInputLength > 0) || (completion.length > 0)
          ? handleEnter
          : pipe(handleEnter, menuKeyDownHandler)
      }
      ref={contentRef}
      className={style.Root}
    >
      <div className={style.Container}>
        <div className={style.Top}>
          {/*
            * `completion ||` because we want to render the streaming response while the response
            * is still being generated and success is not `true yet
            */}
          {(completion || success) && (
            <div className={style.ResponseContainer} data-testid="response" ref={responseContainerRef}>
              {loading ? completion : aiChatHistory[pagination.currentPage]?.content}
            </div>
          )}
          <Input
            inputValue={inputValue}
            setInputValue={setInputValue}
            onSubmit={handleInputSubmit}
            pagination={pagination}
            loading={loading}
            placeholder={`${placeholder}...`}
            stop={stop}
            ref={inputRef}
          />
        </div>
        <Footer error={error} />
      </div>
      <div className={style.MenuContainer}>
        <KeepInView>
          {(!success && !loading) && (
            // if there is no editor selection or selection is collapsed don't render the menu
            !isEditorSelectionCollapsed && (
              <SelectionMenu onSelect={handleSelect} inputValue={inputValue} />
            )
          )}
          {(success) && (
            <CompletionMenu
              onSelect={handleSelect}
              selectedResponse={aiChatHistory[pagination.currentPage]}
            />
          )}
        </KeepInView>
      </div>

    </Command.Root>
  );
};

export default localize(Content);
