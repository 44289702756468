// @flow

import { connect } from 'react-redux';

import get from 'lodash/get';

import agreementCreators from 'reducers/entities/agreement-creators';
import dashboardReducer from 'reducers/entities/dashboard';

import MostActiveUsers from './most-active-users';

const QUERY_NAME = 'dashboard/creators';

export const mapStateToProps = (state: State) => {
  const dashboardQuery = dashboardReducer.getQuerySelector(state, { name: 'dashboard' });
  const dashboardData = dashboardReducer.getDashboardsSelector(state, {
    ids: dashboardQuery.result,
  });

  const orderedActiveUsers = get(dashboardData[0], 'userStats', []);

  return ({
    mostActiveUsers: orderedActiveUsers,
    activeUsers: ({ offset, limit }: Pagination) => (
      orderedActiveUsers.slice(offset, offset + limit)
    ),
  });
};

export const mapDispatchToProps = (dispatch: Function) => ({
  queryMostActiveUsers: ({ params }: Query) => {
    dispatch(agreementCreators.queryAgreementCreators({ name: QUERY_NAME, params }));
  },
});

export default connect<MostActiveUsers, State, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(MostActiveUsers);
