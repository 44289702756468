import * as SelectPrimitive from '@radix-ui/react-select';
import clsx from 'clsx';
import {
  createContext,
  forwardRef,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';

import ChevronDownIcon from 'components/icons/chevron-down';
import CheckIcon from 'components/icons/new-check';
import { composeRefs } from 'hooks/compose-refs';

import style from './select-compound.module.scss';

type SelectPrimitiveContentElement = React.ElementRef<typeof SelectPrimitive.Content>;
type SelectContextType = {
  side: 'top' | 'bottom';
  setSide: (side: 'top' | 'bottom') => void;
  contentRef: React.RefObject<SelectPrimitiveContentElement>;
};

const InternalSelectContext = createContext<SelectContextType | null>(null);
const useInternalSelectContext = () => {
  const context = useContext(InternalSelectContext);
  if (!context) {
    throw new Error('useInternalSelectContext must be used within a Select');
  }
  return context;
};

const Select = (props: ComponentPropsWithoutRef<typeof SelectPrimitive.Root>) => {
  const [side, setSide] = useState<SelectContextType['side']>('bottom');
  const contentRef = useRef<SelectPrimitiveContentElement | null>(null);

  // Radix doesn't expose the value of the side where the content is positioned relative
  // to the trigger, this is a workaround to get the side directly from the content element
  useLayoutEffect(() => {
    queueMicrotask(() => {
      if (contentRef.current) {
        const dataSide = contentRef.current.getAttribute('data-side') as SelectContextType['side'];
        if (dataSide) {
          setSide(dataSide);
        }
      }
    });
  }, [contentRef.current]);

  return (
    <InternalSelectContext.Provider value={{ side, setSide, contentRef }}>
      <SelectPrimitive.Root {...props} />
    </InternalSelectContext.Provider>
  );
};
Select.displayName = SelectPrimitive.Root.displayName;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
  const { side } = useInternalSelectContext();

  return (
    <SelectPrimitive.Trigger
      ref={ref}
      className={clsx(
        style.SelectTrigger,
        className,
      )}
      data-popper-placement={side}
      {...props}
    >
      {children}
    </SelectPrimitive.Trigger>
  );
});
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectIcon = forwardRef<
  ElementRef<typeof SelectPrimitive.Icon>,
  Omit<ComponentPropsWithoutRef<typeof SelectPrimitive.Icon>, 'asChild' | 'children'>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Icon ref={ref} className={clsx(style.SelectIcon, className)} {...props}>
    <ChevronDownIcon height="10px" />
  </SelectPrimitive.Icon>
));
SelectIcon.displayName = SelectPrimitive.Icon.displayName;

const SelectContent = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  Omit<ComponentPropsWithoutRef<typeof SelectPrimitive.Content>, 'position'>
>(({ className, children, ...props }, ref) => {
  const { contentRef } = useInternalSelectContext();

  const composedRefs = composeRefs(ref, contentRef);

  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={composedRefs}
        position="popper"
        className={clsx(
          style.SelectContent,
          className,
        )}
        {...props}
      >
        <SelectPrimitive.Viewport>
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});
SelectContent.displayName = SelectPrimitive.Content.displayName;

type SelectItemProps = Omit<ComponentPropsWithoutRef<typeof SelectPrimitive.Item>, 'children'> & {
  // The children prop must be a string because it's used directly as the select item text.
  // This is an intentional design constraint to maintain consistent UX across the application.
  // If you need to pass non-string content, please review the UX requirements as this may indicate
  // a need for a different component.
  children: string;
};

const SelectItem = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  SelectItemProps
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={clsx(style.SelectItem, className)}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    <SelectPrimitive.ItemIndicator>
      <CheckIcon height="11px" />
    </SelectPrimitive.ItemIndicator>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

export {
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectIcon,
};
