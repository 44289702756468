import {
  ReactElement,
  useState,
} from 'react';
import { Message, MessageTranslator, localize } from '@oneflowab/pomes';
import moment from 'moment';
import type { Moment } from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { isUserLimited } from 'user';
import useAgreement from 'hooks/use-agreement';
import { isDraft, isPending, isOverdue } from 'agreement';
import { getDateFormat, getTranslationForDateFormat } from 'date';
import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import {
  getMyParticipantWhenUpdater,
} from 'agreement/selectors';
import agreementsReducer from 'reducers/entities/agreements';
import sessionStorage from 'utils/session-storage';
import { removeAiProperty } from 'utils/remove-ai-property';

import { MenuItem } from 'components/menu-item';
import MarkAsSignedIcon from 'components/icons/mark-as-signed';
import FolderIcon from 'components/icons/folder';
import WorkspaceIcon from 'components/icons/workspace';
import CircularSpinner from 'components/icons/circular-spinner';

// eslint-disable-next-line import/named
import { SingleDatePickerComponent } from 'components/single-date-picker';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import { ImportSparkle } from 'components/import-sparkle/import-sparkle';
import Confirmable from 'components/confirmable';

import * as permissions from 'agreement/permissions';
import style from './mark-as-signed.module.scss';

const SIGN_PLATFORM = 'manual';

const momentUTC = moment.utc;

const isOutsideRange = (day: Moment) => (
  day > momentUTC().endOf('day') || day < momentUTC('1970-01-01', 'YYYY-MM-DD').endOf('day')
);

export type Props = {
  agreementId: Oneflow.Agreement['id'],
  isOpen?: boolean,
  disabled?: boolean,
  children: (onClick: () => void) => ReactElement,
  message: MessageTranslator,
};

const MarkAsSignedComponent = ({
  agreementId,
  isOpen,
  disabled,
  message,
  children,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const isActionVisible = Boolean(
    isDraft(agreement) || isPending(agreement) || isOverdue(agreement),
  );
  const account = useSelector(getAccountFromSessionSelector) as Oneflow.Account;
  const workspace = useSelector(getCurrentWorkspaceSelector);
  const brandingDateFormat = workspace.brandingDateFormat || account.brandingDateFormat;
  const dateFormat = getDateFormat(brandingDateFormat);
  const rpcState = useSelector(
    (state) => agreementsReducer.getSignAgreementSelector(state, { id: agreement.id }),
  );
  const myParticipantIdWhenUpdater = getMyParticipantWhenUpdater(agreement)?.id;
  const position = useSelector(getPositionFromSessionSelector);
  const dispatch = useDispatch();
  const storedData = sessionStorage.getItem('extracted_data');
  const extractedData = storedData ? JSON.parse(storedData)[agreementId] : null;
  const [markAsSignedDate, setMarkAsSignedDate] = useState<number | null>(
    extractedData?.closeTime ? moment(extractedData.closeTime).unix() : null,
  );

  const signAgreement = () => {
    if (!markAsSignedDate) {
      return;
    }

    const date = moment.unix(markAsSignedDate).format('YYYY-MM-DDT00:00:00+0000');

    dispatch(agreementsReducer.signAgreement({
      id: agreementId,
      data: {
        participantId: myParticipantIdWhenUpdater,
        markAsSignedDate: date,
      },
      pipe: {
        onSuccess: () => {
          dispatch(agreementsReducer.setAgreements({
            [agreementId]: {
              signPlatform: SIGN_PLATFORM,
            },
          }));
        },
      },
    }));
  };

  const resetRpcState = () => {
    dispatch(agreementsReducer.signAgreementReset({
      id: agreementId,
    }));
  };

  const renderBody = () => {
    const hasFolder = Boolean(agreement.folder);
    const icon = hasFolder ? <FolderIcon height="18px" /> : <WorkspaceIcon height="10px" />;

    const name = hasFolder ? agreement.folder?.name : workspace.name;

    return (
      <>
        <div className={style.Folder}>
          <Message
            id="Document will be saved to"
            comment="Tells user which folder the document will be imported to"
          />
          <div className={style.SelectedFolder}>
            {icon}
            <span className={style.SelectedFolder}>
              {name}
            </span>
          </div>
        </div>
        <SingleDatePickerComponent
          label={(
            <span className={style.Label}>
              <Message
                id="Select the sign date"
                comment="Info box text next to a date which the contract will be marked as signed with."
              />
              <ImportSparkle
                agreementId={agreementId}
                type="closeTime"
                className={style.AiImportSparkle}
              />
            </span>
          )}
          placeholder={getTranslationForDateFormat({ message, dateFormat })}
          displayFormat={dateFormat}
          customClassName={style.OverrideSingleDatePicker}
          block={false}
          isOutsideRange={isOutsideRange}
          input={{
            value: markAsSignedDate,
            onChange: (value) => {
              setMarkAsSignedDate(value);
              removeAiProperty(agreementId, 0, 'closeTime');
            },
          }}
        />
        <p style={{ marginTop: 12 }}>
          <Message
            id="Ensure this document is fully signed before marking it as signed."
            comment="Info text in mark as signed modal."
          />
        </p>
      </>
    );
  };

  const canPerformAction = () => permissions.allowMarkAsSigned(agreement, account)
  && !isUserLimited(position);

  const handleConfirm = () => {
    if (!markAsSignedDate || !myParticipantIdWhenUpdater || rpcState.loading) {
      return;
    }
    signAgreement();
  };

  const getChildren = (onClick: () => void) => {
    if (children) {
      return children(onClick);
    }

    const isDisabled = disabled || !canPerformAction();

    return (
      <MenuItem
        icon={MarkAsSignedIcon}
        label={(
          <Message
            id="Mark as signed"
            comment="Action to mark a contract as signed."
          />
        )}
        disabled={isDisabled}
        onClick={onClick}
        className={!isDisabled ? style.MarkAsSignedMenuItem : undefined}
      />
    );
  };

  const getActions = (
    { closeConfirmation }: { closeConfirmation: () => void },
  ) => (
    <>
      <CancelButton
        onClick={closeConfirmation}
      />
      <Button
        disabled={!markAsSignedDate || rpcState.loading}
        onClick={signAgreement}
        kind="primary"
        icon={rpcState.loading ? CircularSpinner : undefined}
      >
        <Message
          id="Mark as signed"
          comment="Text for button to mark a contract as signed."
        />
      </Button>
    </>
  );

  if (!isActionVisible) {
    return null;
  }

  return (
    <Confirmable
      header={(
        <Message
          id="Mark as signed"
          comment="Confirm modal title for signing a contract."
        />
      )}
      modalKey="mark as signed modal"
      body={renderBody()}
      isOpen={isOpen}
      onOpen={resetRpcState}
      error={rpcState.error}
      success={rpcState.success}
      isConfirmLoading={rpcState.loading}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      actions={getActions}
      customBodyClass={style.MarkAsSignedBody}
    >
      {getChildren}
    </Confirmable>
  );
};

export const MarkAsSigned = localize(MarkAsSignedComponent);
