// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import extensionsReducer from 'reducers/entities/extensions';

import GeneratedToken from './generated-token';
import type { Props } from './generated-token';

type StateProps = {|
  tokenEntity: Extension,
|};

type DispatchProps = {|
  clearToken: Extension => () => void,
|};

type OwnProps = $Diff<Props, StateProps & DispatchProps> & {|
  extension: Extension,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { extension }) => ({
  tokenEntity: extension,
});

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  clearToken: (extension: Extension) => () => {
    dispatch(extensionsReducer.setExtensions({
      [extension.key]: {
        token: null,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(GeneratedToken);
