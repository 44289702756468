import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageTranslator, localize } from '@oneflowab/pomes';
import { isEmpty } from 'lodash';
import { OnChange } from 'react-final-form-listeners';
import { useFormState } from 'react-final-form';

// eslint-disable-next-line import/named
import { hasAnyPosition } from 'oneflow-client/positions';
import {
  USER_ROLE_USER,
  USER_ROLE_LIMITED,
  USER_WORKSPACE_ROLE_READ_ONLY,
} from 'user';

import accountSeatsReducer from 'reducers/entities/account-seats';
import groupsReducer from 'reducers/entities/groups';
import { getPositionFromSessionSelector } from 'reducers/session';
import { getCurrentLanguageSelector } from 'reducers/i18n';

import useFeatureFlag from 'hooks/use-feature-flag';

import Field from 'components/field';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import { EmailField } from 'components/fields';
// eslint-disable-next-line import/named
import { getEnabledLanguagesAsOptions, getLanguageOption } from 'components/languages';
import { getUserRolesAsOptions, getUserRole, userRoles } from 'components/user-roles';
import emailValidationText from 'components/email-validation-text';
import InfoIcon from 'components/icons/info';
import { checkAcl } from 'components/acl';
import Message from 'components/message';

import { hasAvailableSeats } from 'account';

import WorkspaceSection from './sections/workspace-section';
import AccountAccessSection from './sections/account-access-section';

import style from './invite-user.module.scss';

type Params = {
  q: string,
};

type SelectedFieldValue = {
  label: string,
  value: string,
};

export type ModalConfig = {
  isLimitedSelected: boolean,
  isWorkspaceSelected: boolean,
  readOnlyWorkspaceRoleId: number | null,
};

type Props = {
  message: MessageTranslator,
  account: Oneflow.Account,
  defaultPagination: RequestParams.Pagination,
  modalConfig: ModalConfig
  setModalConfig: (modalConfig: ModalConfig) => void,
};

export const QUERY_NAME = 'admin/users';
export const GROUPS_QUERY_NAME = 'InviteUser/groups';

export const InviteUserBody = ({
  message,
  account,
  defaultPagination,
  modalConfig,
  setModalConfig,
}: Props) => {
  const { isLimitedSelected } = modalConfig;
  const isPositionLimitedFeatureEnabled = useFeatureFlag('temporaryPositionLimited');

  const position: Oneflow.Position = useSelector(getPositionFromSessionSelector);

  const accountSeats = useSelector((state) => (
    accountSeatsReducer.getAccountSeatSelector(state, { id: account.id })
  ));

  const groupsQuery = useSelector((state) => (
    groupsReducer.getQuerySelector(state, { name: GROUPS_QUERY_NAME })
  ));

  const groupsList = useSelector((state) => (
    groupsReducer.getGroupsSelector(state, { ids: groupsQuery.result })
  ));

  const defaultLanguage = useSelector((state) => (
    getLanguageOption(getCurrentLanguageSelector(state))
  ));

  const hasAvailableSeatsCheck = hasAvailableSeats(accountSeats);

  const hasAccessToGroupField = useMemo(() => {
    const hasGroupAccess = checkAcl(account.acl, 'account:admin:group');
    const hasCreateUserGroupBindingAccess = checkAcl(position.acl, 'position:group:create');

    if (hasGroupAccess && hasCreateUserGroupBindingAccess) {
      return true;
    }
    return false;
  }, [account.acl, position.acl]);

  const hasAccessToSystemAccountRoles = useMemo(() => (
    checkAcl(account.acl, 'account:position:system_role_binding:create')
  ), [account.acl]);

  const hasAccessToCustomAccountRoles = useMemo(() => (
    checkAcl(account.acl, 'account:position:custom_role_binding:create')
  ), [account.acl]);

  const hasAccessToAccountSection = useMemo(() => {
    const hasRoleViewPermission = checkAcl(account.acl, 'account:role:view');

    if (hasRoleViewPermission
      && (hasAccessToSystemAccountRoles || hasAccessToCustomAccountRoles)
    ) {
      return true;
    }

    return false;
  }, [account.acl, hasAccessToCustomAccountRoles, hasAccessToSystemAccountRoles]);

  const hasAccessToWorkspacesSection = useMemo(() => {
    const hasUserWorkspaceBindingAccess = checkAcl(position.acl, 'position:collection:binding:create');
    if (hasUserWorkspaceBindingAccess) {
      return true;
    }

    return false;
  }, [position.acl]);

  const initialUserRole = useMemo(() => {
    if (!hasAvailableSeatsCheck && isPositionLimitedFeatureEnabled) {
      return getUserRole(USER_ROLE_LIMITED, true, message);
    }
    return getUserRole(USER_ROLE_USER, isPositionLimitedFeatureEnabled, message);
  }, [hasAvailableSeatsCheck, isPositionLimitedFeatureEnabled, message]);

  // All dispatch functions
  const dispatch = useDispatch();
  const { values: { workspaces } } = useFormState();
  const queryGroups = ({ params, pagination = defaultPagination }
    : { params: Params, pagination: RequestParams.Pagination }) => {
    dispatch(groupsReducer.queryGroups({
      name: GROUPS_QUERY_NAME,
      params,
      pagination,
    }));
  };
  const queryGroupsLoadMore = ({ additionalResults }: { additionalResults: number }) => {
    dispatch(groupsReducer.queryGroupsLoadMore({
      name: GROUPS_QUERY_NAME,
      additionalResults,
    }));
  };

  const handleGroupChange = (name: string) => {
    queryGroups({
      params: {
        q: name,
      },
      pagination: groupsQuery.pagination,
    });
  };

  const onLoadMoreGroups = (additionalResults: number) => {
    queryGroupsLoadMore({ additionalResults });
  };

  const uniqueEmailName = ({ params }: { params: Params }) => (
    hasAnyPosition({
      params,
      props: {
        returnEntity: true,
      },
    })
  );

  const renderAvailableUserRolesDescription = () => {
    const allAvailableUserRoles = userRoles(
      isPositionLimitedFeatureEnabled,
      message,
    );

    return (
      <ul>
        {Object.values(allAvailableUserRoles)?.map((userRole) => (
          <li key={userRole.value}>
            {userRole.description}
          </li>
        )).filter(Boolean)}
      </ul>
    );
  };

  const getAvailableUserRoles = () => {
    if (!hasAvailableSeatsCheck && isPositionLimitedFeatureEnabled) {
      return [getUserRole(
        USER_ROLE_LIMITED,
        isPositionLimitedFeatureEnabled,
        message,
      )];
    }
    return getUserRolesAsOptions(
      isPositionLimitedFeatureEnabled,
      message,
    );
  };

  const renderGroupAccessSection = () => {
    const hasNoGroups = groupsList?.length === 0 && isEmpty(groupsQuery?.params);

    if (isLimitedSelected || hasNoGroups || !hasAccessToGroupField) {
      return null;
    }

    return (
      <div className={style.GroupSection}>
        <h3 className={style.SectionTitleWorkspaceGroups}>
          <Message id="Groups" comment="Section title in invite user modal" />
        </h3>
        <p className={style.Description}>
          <Message
            id="Assign the user to one or more groups."
            comment="Section header in invite user modal."
          />
        </p>
        <Field
          name="groups"
          data-testid="groups-field"
          placeholder={(
            <Message
              id="Select groups"
              comment="Field placeholder in invite user modal."
            />
          )}
          component={SelectField}
          multi
          valueKey="id"
          labelKey="name"
          closeOnSelect={false}
          options={isLimitedSelected ? [] : groupsList}
          isLoading={groupsQuery?.loading}
          onInputChange={handleGroupChange}
          loadMoreItems={onLoadMoreGroups}
        />
      </div>
    );
  };

  return (
    <div>
      <h2 className={style.FirstTitle}>
        <Message
          id="User details"
          comment="Heading for section within the invite user modal"
        />
      </h2>
      <div className={style.Row}>
        <Field
          name="fullname"
          data-testid="name-field"
          label={message({
            id: 'Full name',
            comment: 'Field label in invite user modal.',
          })}
          placeholder={message({
            id: 'Enter full name',
            comment: 'Field placeholder in invite user modal.',
          })}
          component={TextField}
          autoFocus
          required
          responsive
        />
        <Field
          name="userRole"
          data-testid="user-role-field"
          label={message({
            id: 'User type',
            comment: 'Field label in invite user modal.',
          })}
          placeholder={(
            <Message
              id="Select user type"
              comment="Field placeholder in invite user modal."
            />
          )}
          component={SelectField}
          options={getAvailableUserRoles()}
          initialValue={initialUserRole}
          clearable={false}
          searchable={false}
          required
          fieldinfo={{
            message: renderAvailableUserRolesDescription(),
            side: 'right',
            icon: <InfoIcon width="12px" />,
            zIndex: '10004',
            nextToLabel: true,
          }}
        />
        <OnChange name="userRole">
          {(value: SelectedFieldValue) => {
            if (value.value === USER_ROLE_LIMITED) {
              if (isEmpty(workspaces)) {
                setModalConfig({ ...modalConfig, isLimitedSelected: true });
              } else if (!isEmpty(workspaces)) {
                setModalConfig({
                  ...modalConfig,
                  isLimitedSelected: true,
                  readOnlyWorkspaceRoleId: USER_WORKSPACE_ROLE_READ_ONLY,
                });
              }
            } else {
              setModalConfig({
                ...modalConfig,
                isLimitedSelected: false,
                readOnlyWorkspaceRoleId: null,
              });
            }
          }}
        </OnChange>
      </div>
      <div className={style.Row}>
        <EmailField
          unique={{
            text: emailValidationText,
            param: 'email',
            request: uniqueEmailName,
          }}
          hideErrorsUntilTouched
        />
        <div className={style.LanguageField}>
          <Field
            name="language"
            data-testid="language-field"
            component={SelectField}
            label={message({
              id: 'Application language',
              comment: 'Field label in invite user modal',
            })}
            placeholder={(
              <Message
                id="Select language"
                comment="Field placeholder in invite user modal."
              />
            )}
            options={getEnabledLanguagesAsOptions()}
            initialValue={defaultLanguage?.value}
            clearable={false}
            searchable={false}
            required
          />
        </div>
      </div>
      {hasAccessToAccountSection && (
        <AccountAccessSection
          isLimitedSelected={isLimitedSelected}
          hasAccessToSystemAccountRoles={hasAccessToSystemAccountRoles}
          hasAccessToCustomAccountRoles={hasAccessToCustomAccountRoles}
          message={message}
        />
      )}
      {hasAccessToWorkspacesSection && (
        <WorkspaceSection
          message={message}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
      {renderGroupAccessSection()}
    </div>
  );
};

export default localize(InviteUserBody);
