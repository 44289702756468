// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { Match } from 'react-router';

import templateGroupsReducer from 'reducers/entities/template-groups';

import TemplateGroup from './template-group';

type StateProps = {|
  fetchState: FetchState,
  templateGroup: TemplateGroup,
|};

type DispatchProps = {|
  fetchTemplateGroup: () => void,
|};

type OwnProps = $Diff<$Exact<{}>, StateProps & DispatchProps> & {|
  match: Match,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { match }) => ({
  fetchState: templateGroupsReducer.getFetchSelector(state, { id: match.params.id }),
  templateGroup: templateGroupsReducer.getTemplateGroupSelector(state, { id: match.params.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { match }) => ({
  fetchTemplateGroup: () => {
    dispatch(templateGroupsReducer.fetchTemplateGroup({ id: match.params.id }));
  },
});

export default connect<{||}, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateGroup);
