import Svg from 'components/svg';

const Lt = Svg((
  <>
    <path d="M0 223.908h512v102.988a8.829 8.829 0 0 1-8.828 8.828H8.828A8.829 8.829 0 0 1 0 326.896V223.908z" fill="#FF4B55" />
    <path d="M8.828.276h494.345a8.829 8.829 0 0 1 8.828 8.828v102.988H0V9.103A8.828 8.828 0 0 1 8.828.276z" fill="#FFE15A" />
    <path fill="#73AF00" d="M0 112.088h512V223.9H0z" />
  </>
), { viewBox: '0 0 512 336', fill: 'none' });

Lt.displayName = 'Lt';

export default Lt;
