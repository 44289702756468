// @flow

import * as React from 'react';

import {
  timestampToDateString,
  formatDateString,
} from 'date';

import style from './event-log.module.scss';

type Props = {
  label: React.Node,
  timestamp?: number,
  dateString?: string,
  eventValue?: React.Node,
  dateFormat: string,
};

export const EventLog = ({
  label,
  timestamp,
  dateString,
  eventValue,
  dateFormat,
}: Props) => {
  if (!label) {
    return null;
  }
  let eventDate = timestamp && timestampToDateString(timestamp, dateFormat);

  if (dateString) {
    eventDate = formatDateString(dateString, dateFormat);
  }

  return (
    <div className={style.EventLog}>
      <span className={style.Label}>
        {label}
      </span>
      <div className={style.EventValue}>
        {eventDate || eventValue}
      </div>
    </div>
  );
};

EventLog.defaultProps = {
  timestamp: undefined,
  dateString: undefined,
  eventValue: undefined,
};
