// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './ai-assist.module.scss';

type Props = {
  renderActions: any,
};

const AiAssistFooter = ({
  renderActions,
}: Props) => (
  <div className={style.Footer}>
    <div className={style.Disclaimer}>
      <div className={style.DisclaimerText}>
        <Message
          id="Do not include any personally identifiable information (PII) in your input."
          comment="Warning message under an input"
        />
      </div>
    </div>
    <div className={style.Actions}>
      {renderActions()}
    </div>
  </div>
);

export default AiAssistFooter;
