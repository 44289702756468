// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import AddRowAbove from 'components/icons/add-row-above';
import AddRowUnder from 'components/icons/add-row-under';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import addTableRow from 'components/rich-text-editor/editor-plugins/table-utils/add-table-row';
import ToolbarButton from '../toolbar-button';

type Props = {
  direction: 'above' | 'below',
};

const getLabel = (direction: 'above' | 'below') => {
  if (direction === 'above') {
    return (
      <Message
        id="Add row above"
        comment="The label of the editor's toolbar plugin that inserts a table row."
      />
    );
  }

  return (
    <Message
      id="Add row under"
      comment="The label of the editor's toolbar plugin that inserts a table row."
    />
  );
};

const getIcon = (direction: 'above' | 'below') => {
  if (direction === 'above') {
    return <AddRowAbove />;
  }

  return <AddRowUnder />;
};

const AddTableRow = ({ direction }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      label={getLabel(direction)}
      isMenuItem={false}
      onTrigger={() => {
        focusEditor();
        addTableRow(editor, direction);
      }}
      icon={getIcon(direction)}
    />
  );
};

export default AddTableRow;
