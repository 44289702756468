import { connect } from 'react-redux';

import positionMessageTemplates from 'reducers/entities/position-message-templates';

import AddMessageTemplate from './add-message-template';

const queryName = () => 'user/templates';

export const mapStateToProps = (state) => ({
  formState: positionMessageTemplates.getCreateSelector(state),
});

export const mapDispatchToProps = (dispatch, { id }) => ({
  resetFormState: () => {
    dispatch(positionMessageTemplates.createPositionMessageTemplateReset());
  },
  onSubmit: ({
    body,
    name,
    subject,
    type,
  }) => {
    dispatch(positionMessageTemplates.createPositionMessageTemplate({
      data: {
        positionId: id,
        body,
        name,
        subject,
        type: type.value || 'publish',
      },
      pipe: {
        action: () => positionMessageTemplates.queryPositionMessageTemplatesReload({
          name: queryName(),
        }),
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMessageTemplate);
