/* eslint-disable import/named */
import { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';
import { useSelector, useDispatch } from 'react-redux';

import groupAccountsReducer from 'reducers/entities/group-accounts';
import accountGroupsReducer from 'reducers/entities/account-groups';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getAccountGroupsQueryName } from 'routes/admin/account-access/account-access';

import { RemoveConfirm } from 'components/modals/remove-confirm';
import Badge from 'components/badge';

type Props = {
  children: ReactNode | ((onClick: () => void) => JSX.Element),
  binding: Oneflow.GroupAccountRoleBinding,
  renderedFromAccountAccessPage: boolean,
}

const RemoveGroupAccountAccess = ({
  children, binding, renderedFromAccountAccessPage,
}: Props) => {
  const { group, role, id } = binding;
  const dispatch = useDispatch();
  const account = useSelector(getAccountFromSessionSelector);

  const formState = useSelector((state) => (
    groupAccountsReducer.getRemoveGroupAccountAccessSelector(state, { id })));

  const resetRemoveState = () => {
    dispatch(groupAccountsReducer.removeGroupAccountAccessReset({ id }));
  };

  const removeGroupAccountAccess = () => {
    const GROUP_ACCOUNT_BINDINGS_QUERY_NAME = `admin/group/${group.id}/account-access`;
    const actionData = {
      id,
      data: {
        groupId: group.id,
        roleId: role.id,
      },
      pipe: {
        action: () => groupAccountsReducer.queryGroupAccounts({
          name: GROUP_ACCOUNT_BINDINGS_QUERY_NAME,
          params: {
            groupId: group.id,
            actorType: 'group',
          },
          sort: ['-role.type', 'role.name'],
        }),
      },
    };

    if (renderedFromAccountAccessPage) {
      actionData.pipe.action = () => accountGroupsReducer.queryAccountGroups({
        name: getAccountGroupsQueryName(account.id),
        params: {
          actorType: 'group',
        },
        sort: ['name'],
      });
    }
    dispatch(groupAccountsReducer.removeGroupAccountAccess(actionData));
  };

  return (
    <RemoveConfirm
      onConfirm={removeGroupAccountAccess}
      confirmState={formState}
      resetConfirmState={resetRemoveState}
      confirmMessage={(
        <Message
          id="You are about to remove the {role} account role from {groupName}. This action will affect the group account permissions. Remove the role?"
          values={{
            role: <Badge label={role.name || ''} kind="name" />,
            groupName: <Badge label={group.name || ''} kind="name" />,
          }}
          comment="Modal text when removing group account access."
        />
      )}
      modalKey="remove group account access modal"
      header={(
        <Message
          id="Remove role"
          comment="Modal title for removing group account access account"
        />
      )}
    >
      {children}
    </RemoveConfirm>
  );
};

export default RemoveGroupAccountAccess;
