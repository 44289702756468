// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { Match } from 'react-router';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import templateGroupsReducer from 'reducers/entities/template-groups';
import dataFieldsReducer from 'reducers/entities/data-fields';

import TemplateGroupDataFieldsComponent, { type Props } from './data-fields';

export const QUERY_NAME = '/admin/account/extensions/template-groups/data-fields';

type StateProps = {|
  dataFieldsQuery: Query,
  dataFields: Array<DataField>,
  dataFieldSetId: number,
  isUsedByIntegration: boolean,
|};

type DispatchProps = {|
  queryDataFields: number => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {|
  match: Match,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { match }) => {
  const dataFieldsQuery = dataFieldsReducer.getQuerySelector(state, { name: QUERY_NAME });
  const templateGroup = templateGroupsReducer.getTemplateGroupSelector(state, {
    id: match.params.id,
  });

  return {
    dataFieldSetId: get(templateGroup, 'dataFieldSet.id'),
    isUsedByIntegration: Boolean(templateGroup.usedBy),
    dataFieldsQuery,
    dataFields: sortBy(
      dataFieldsReducer.getDataFieldsSelector(state, { ids: dataFieldsQuery.result }),
      (dataField) => dataField.name.toLowerCase(),
    ),
  };
};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  queryDataFields: (dataFieldSetId) => {
    dispatch(dataFieldsReducer.queryDataFields({
      name: QUERY_NAME,
      params: {
        dataFieldSetId,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateGroupDataFieldsComponent);
