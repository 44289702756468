import React, {
  useState,
  useMemo,
  useCallback,
  ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { Message } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import { PREVIEW } from 'hooks/use-is-in-preview-mode';
import { getAgreementMyParticipant, getGuestToken } from 'agreement/selectors';
import * as agreementConstants from 'agreement/constants';
import { isContractPristine } from 'reducers/current-contract';

import { checkAcl } from 'components/acl';
import AddVideo from 'components/agreement-video/add-video';
import VideoPreview from 'components/agreement-video/video-preview';
import VideoPreviewActions from 'components/agreement-video/video-preview-actions';
import ClockIcon from 'components/icons/clock';

import style from './agreement-video.module.scss';
import getHref from './helpers';

type Props = {
  agreementId: Oneflow.Agreement['id'],
}

type getURLProps = {
  agreementId: Oneflow.Agreement['id'],
  guestToken: string,
}

export const getVideoSourceUrl = ({ agreementId, guestToken }: getURLProps) => {
  const sourceUrl = `/api/agreements/${agreementId}/video`;

  if (guestToken && guestToken !== PREVIEW) {
    return `${sourceUrl}?at=${guestToken}`;
  }

  return sourceUrl;
};

export const getVideoThumbnailUrl = ({ agreementId, guestToken }: getURLProps) => {
  const thumbnailUrl = `/api/agreements/${agreementId}/video/thumbnail`;

  if (guestToken && guestToken !== PREVIEW) {
    return `${thumbnailUrl}?at=${guestToken}`;
  }

  return thumbnailUrl;
};

const AgreementVideo = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const guestToken = useSelector(getGuestToken);
  const myParticipant = useMemo(() => getAgreementMyParticipant(agreement), [agreement]);
  const canAddVideo = useMemo(() => checkAcl(agreement.acl, 'agreement:video:add'), [agreement.acl]);
  const isPristine = useSelector(isContractPristine);

  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const [autoplay, setAutoplay] = useState<boolean>(!myParticipant);

  const renderAddVideo = useCallback((children?: ReactNode) => {
    if (!canAddVideo) {
      return null;
    }

    return (
      <AddVideo agreementId={agreementId}>
        {children}
      </AddVideo>
    );
  }, [agreementId, canAddVideo]);

  const renderVideoPreviewActions = useCallback((children?: ReactNode) => (
    <VideoPreviewActions agreementId={agreement.id}>
      {children}
    </VideoPreviewActions>
  ), [agreement.id]);

  const renderVideoProcessing = useCallback(() => {
    if (!canAddVideo) {
      return null;
    }

    return (
      <div data-testid="video-processing">
        <div>
          <a
            className={clsx(style.VideoProcessingLink)}
            href={getHref(isPristine, agreementId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ClockIcon width="13px" className={style.ClockIcon} />
            <Message id="Your video is being processed" comment="Text to show when a video is being processed." />
          </a>
        </div>
        <div>
          {renderVideoPreviewActions()}
        </div>
      </div>
    );
  }, [agreementId, canAddVideo, renderVideoPreviewActions, isPristine]);

  const handleModalClose = useCallback(() => {
    setIsVideoModalOpen(false);
  }, [setIsVideoModalOpen]);

  const handleOpenModal = useCallback(() => {
    setIsVideoModalOpen(true);
    setAutoplay(true);
  }, [setIsVideoModalOpen, setAutoplay]);

  const renderVideoPreview = useCallback(() => (
    renderVideoPreviewActions(
      <VideoPreview
        isVideoModalOpen={isVideoModalOpen}
        autoplay={autoplay}
        source={getVideoSourceUrl({ agreementId, guestToken })}
        thumbnail={getVideoThumbnailUrl({ agreementId, guestToken })}
        openModal={handleOpenModal}
        handleClose={handleModalClose}
      />,
    )
  ), [
    agreementId,
    autoplay,
    guestToken,
    handleOpenModal,
    handleModalClose,
    isVideoModalOpen,
    renderVideoPreviewActions,
  ]);

  const renderAgreementWithoutVideo = useCallback(
    () => renderAddVideo(renderVideoPreviewActions()),
    [
      renderAddVideo,
      renderVideoPreviewActions,
    ],
  );

  const renderAgreementWithVideo = useCallback(
    () => renderVideoPreview(),
    [
      renderVideoPreview,
    ],
  );

  const renderContent = useCallback(() => {
    const status = agreement.welcomeVideo;
    switch (status) {
      case agreementConstants.AGREEMENT_WITHOUT_VIDEO:
        return renderAgreementWithoutVideo();
      case agreementConstants.AGREEMENT_VIDEO_PROCESSING:
        return renderVideoProcessing();
      case agreementConstants.AGREEMENT_WITH_VIDEO:
        return renderAgreementWithVideo();
      default:
        return null;
    }
  }, [
    agreement.welcomeVideo,
    renderAgreementWithVideo,
    renderAgreementWithoutVideo,
    renderVideoProcessing,
  ]);

  return (
    <div className={style.Video}>
      {renderContent()}
    </div>
  );
};

export default AgreementVideo;
