// @flow

import React from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';
import { isEmpty } from 'lodash';
import adminPage from 'hocs/admin-page';

import BillingPage from 'components/billing-page';

export type Props = {
  accountSeats: AccountSeats,
  billing: Billing,
  accountId: number,
  fetchBilling: ({accountId: number}) => void,
  account: Oneflow.Account,
  dateFormat: string,
};

export class BillingPageComponent extends React.Component<Props, State> {
  componentDidMount() {
    const { fetchBilling, accountId } = this.props;
    fetchBilling({ accountId });
  }

  render() {
    const {
      billing,
      accountSeats,
      account,
      dateFormat,
    } = this.props;

    if (isEmpty(billing)) {
      return null;
    }

    return (
      <BillingPage
        billing={billing}
        accountSeats={accountSeats}
        account={account}
        dateFormat={dateFormat}
      />
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Billing',
    comment: 'Page title for the billing page.',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(BillingPageComponent);
