import { isEmpty } from 'lodash';

import { DATE_FORMAT } from 'date';

import useCurrentContractId from './use-current-contract-id';
import useAgreement from './use-agreement';

const useCurrentDateFormat = (): string => {
  const currentContractId = useCurrentContractId();
  const agreement = useAgreement(currentContractId);

  if (isEmpty(agreement)) {
    return DATE_FORMAT;
  }

  return agreement.config?.dateFormat || DATE_FORMAT;
};

export default useCurrentDateFormat;
