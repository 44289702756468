// @flow

import listItemsWithAnd from 'utils/list-items-with-and';
import { getAgreementDateFormat, formatDateString } from 'date';
import { type MessageTranslator } from '@oneflowab/pomes';
import { isRecurringWithInitial } from 'agreement/agreement';
import { formatTimePeriod } from 'agreement/date-helpers';
import moment from 'moment';
import { getRef, getEventOwner } from 'agreement/event';
import {
  eventHasRef, getFormattedAttribute, getFormattedValue, getFullnameList, getParticipantName,
} from '../helpers';

export const agreementCreate = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const owner = getEventOwner(agreement, event);

  return {
    text: message({
      id: '{name} created the document.',
      values: { name: getParticipantName(owner) },
      comment: 'Audit trail message',
    }),
    byLine: false,
  };
};

export const agreementPublish = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const owner = getEventOwner(agreement, event);
  const names = getFullnameList(agreement, event, message);

  if (names.length) {
    return {
      text: message({
        id: '{name} sent the document to {names}.',
        values: { name: getParticipantName(owner), names: listItemsWithAnd(names) },
        comment: 'Audit trail message',
      }),
      byLine: false,
    };
  }

  return {
    text: message({
      id: '{name} sent the document.',
      values: { name: getParticipantName(owner) },
      comment: 'Audit trail message',
    }),
    byLine: false,
  };
};
export const agreementSign = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const dateFormat = getAgreementDateFormat(agreement.config.dateFormat);
  const isMarkedAsSigned = eventHasRef(event, 'marked_as_signed');
  const hasStartDate = eventHasRef(event, 'start_date');
  const messageFirstPart = isMarkedAsSigned
    ? message({
      id: 'The contract was marked as signed.',
      comment: 'Audit trail message',
    })
    : message({
      id: 'All signatories have signed.',
      comment: 'Audit trail message',
    });
  const messageSecondPart = hasStartDate
    ? message({
      id: 'The start date is {start-date}.',
      values: { 'start-date': formatDateString(getRef(event, 'start_date'), dateFormat) },
      comment: 'Audit trail message',
    })
    : '';

  return {
    text: `${messageFirstPart} ${messageSecondPart}`,
    byLine: false,
  };
};

export const agreementDecline = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const markedAsDeclined = eventHasRef(event, 'marked_as_declined');
  return {
    text: markedAsDeclined
      ? message({
        id: 'The contract was marked as declined.',
        comment: 'Audit trail message',
      })
      : message({
        id: 'The contract was declined.',
        comment: 'Audit trail message',
      }),
    byLine: false,
  };
};

export const agreementUpdate = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const dateFormat = getAgreementDateFormat(agreement.config.dateFormat);
  const key = getRef(event, 'key');
  const attribute = getFormattedAttribute(key, 'agreement');
  let to = getRef(event, 'to');
  let from = getRef(event, 'from');
  const owner = getEventOwner(agreement, event);

  // This is crappy, but I think it is hard to do it in a better way
  switch (key) {
    case 'box_order':
      return {
        text: message({
          id: '{name} rearranged the layout.',
          values: {
            name: getParticipantName(owner),
          },
          comment: 'Audit trail message',
        }),
        showEditIcon: true,
        byLine: false,
      };
    case 'expire_date':
      if (!to) {
        return {
          text: message({
            id: '{attribute-name} was removed.',
            values: { 'attribute-name': attribute },
            comment: 'Audit trail message',

          }),
          showEditIcon: true,
        };
      }
      // If a to-date is provided: handled after the switch statement
      break;
    case 'sign_later':
      if (to) {
        return {
          text: message({
            id: 'Signing has been disabled.',
            comment: 'Audit trail message',
          }),
          showEditIcon: true,
        };
      }
      return {
        text: message({
          id: 'Signing has been enabled.',
          comment: 'Audit trail message',
        }),
        showEditIcon: true,
      };
    case 'single_sign':
      if (to) {
        return {
          text: message({
            id: '{attribute-name} was enabled, requiring only one signature.',
            values: { 'attribute-name': attribute },
            comment: 'Audit trail message',
          }),
          showEditIcon: true,
        };
      }
      return {
        text: message({
          id: '{attribute-name} was disabled, requiring signatures from everyone.',
          values: { 'attribute-name': attribute },
          comment: 'Audit trail message',
        }),
        showEditIcon: true,
      };
    case 'type':
    case 'duration':
    case 'initial_duration':
    case 'notice_period':
      to = getFormattedValue(key, to, message);
      from = getFormattedValue(key, from, message);
      break;
    default:
      // No preparing needed. Handled in the next code block
  }

  let text = message({
    id: 'Unknown update event',
    comment: 'Audit trail message',
  });
  if (from) {
    if (to) {
      if (['start_date', 'end_date', 'expire_date'].includes(key)) {
        to = formatDateString(to, dateFormat);
        from = formatDateString(from, dateFormat);
      }
    } else {
      if (['start_date', 'end_date'].includes(key)) {
        from = formatDateString(from, dateFormat);
      }
      to = message({
        id: 'Not set',
        comment: 'Audit trail partial message',
      });
    }
    text = key === 'sign_order'
      ? message({
        id: 'Signing order was changed.',
        comment: 'Audit trail message',
      })
      : message({
        id: '{attribute-name} was changed from {from-value} to {to-value}.',
        values: { 'attribute-name': attribute, 'from-value': from, 'to-value': to },
        comment: 'Audit trail message',
      });
  } else {
    if (['start_date', 'end_date'].includes(key)) {
      to = formatDateString(to, dateFormat);
    }
    text = message({
      id: '{attribute-name} was set to {to-value}.',
      values: { 'attribute-name': attribute, 'to-value': to },
      comment: 'Audit trail message',
    });
  }

  return {
    text,
    showEditIcon: true,
  };
};

export const agreementRevive = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'The signing period was extended.',
    comment: 'Audit trail message',
  }),
});

export const agreementDurationEnd = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'The contract period has ended.',
    comment: 'Audit trail message',
  }),
  byLine: false,
});

export const agreementStartLifeCycle = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'The contract period has started and it is now active.',
    comment: 'Audit trail message',
  }),
  byLine: false,
});

export const agreementNewRecurringPeriod = (
  agreement: Agreement,
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  if (isRecurringWithInitial(agreement)) {
    return {
      text: message({
        id: 'The initial contract period has ended. It is now active with a notice period of {notice-period}.',
        values: { 'notice-period': formatTimePeriod(agreement.noticePeriod, message) },
        comment: 'Audit trail message',
      }),
      byLine: false,
    };
  }
  const dateFormat = getAgreementDateFormat(agreement.config.dateFormat);
  const nextPeriodTimestamp = getRef(event, 'next_period_timestamp');
  if (nextPeriodTimestamp) {
    const terminateDate = moment.unix(nextPeriodTimestamp).format(`${dateFormat}, HH:mm`);
    return {
      text: message({
        id: 'The contract has entered a final contract period because it was canceled after the notice period started. The contract period will end {date}.',
        values: { date: terminateDate },
        comment: 'Audit trail message',
      }),
      byLine: false,
    };
  }
  return {
    text: message({
      id: 'A new contract period has been entered.',
      comment: 'Audit trail message',
    }),
    byLine: false,
  };
};

export const agreementExpire = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'The signing period has expired.',
    comment: 'Audit trail message',
  }),
  byLine: false,
});

export const agreementCancel = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'The contract has been canceled.',
    comment: 'Audit trail message',
  }),
});

export const agreementTerminate = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'The contract has ended.',
    comment: 'Audit trail message',
  }),
  byLine: false,
});

export const agreementSignatureReset = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'All signatures were reset.',
    comment: 'Audit trail message',
  }),
});

export const agreementPendingStateApprovalReset = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'All approvals were reset.',
    comment: 'Audit trail message',
  }),
});

export const agreementContentUpdate = (
  message: MessageTranslator,
) => ({
  text: message({
    id: 'Updates made.',
    comment: 'Audit trail message',
  }),
  showEditIcon: true,
  byLine: true,
});

export const pdfDownload = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const owner = getEventOwner(agreement, event);

  return {
    text: message({
      id: '{name} downloaded the document as a PDF.',
      values: { name: getParticipantName(owner) },
      comment: 'Audit trail message',
    }),
    showEditIcon: true,
    byLine: false,
  };
};
