// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import webhooksReducer from 'reducers/entities/webhooks';

import AddWebhook, { type CreateWebhook, type Props } from './add-webhook';

export const QUERY_NAME = '/admin/account/extensions/webhooks';

type StateProps = {|
  createState: CreateState,
|};

type DispatchProps = {|
  createWebhook: CreateWebhook => void,
  resetCreateState: () => void,
  verifyWebhookUrl: (string) => void,
|};

type OwnProps = {|
  children: Function => React.Node
|}

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  createState: webhooksReducer.getCreateSelector(state),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  createWebhook: ({
    url,
    signKey,
  }) => {
    dispatch(webhooksReducer.createWebhook({
      data: {
        url,
        signKey,
      },
      pipe: {
        action: () => webhooksReducer.queryWebhooksReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
  resetCreateState: () => {
    dispatch(webhooksReducer.createWebhookReset());
  },
  verifyWebhookUrl: (url) => {
    dispatch(webhooksReducer.urlCheck({
      id: 'create',
      data: {
        url,
      },
    }));
  },
});

export default connect<Props, *, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(AddWebhook);
