import Svg from 'components/svg';

const BeItsMe = Svg((
  <>
    <path d="M19.4 260a75.5 75.5 0 0 1-17.6-65.7L32.3 80.4a75.5 75.5 0 0 1 48.1-48.1L194.3 1.8A75.5 75.5 0 0 1 260 19.4l83.3 83.4a75.6 75.6 0 0 1 17.7 65.7l-30.6 113.8a75.5 75.5 0 0 1-48 48.1l-114 30.6a75.5 75.5 0 0 1-65.7-17.6z" fill="#ff4612" className="back" />
    <path d="M222.6 130.6c-10.8-3.7-17.7-5.1-17.2-11.5.4-5 4.2-7.6 11.1-7 5 .4 8.2 2.5 9.7 5.6a12 12 0 0 0 10.5 6.7c10.1.2 16.3-11.5 10-19.4-5.7-7-15.1-11.8-28.3-12.9-23.2-1.7-37.8 8.6-39.1 26.3-1.5 18.9 11.2 24 30.4 30.5 13.2 4.2 16.4 6.2 16 11.8-.4 5.3-5 8-12.2 7.4-6-.4-9.6-2.8-11.3-6.3a12 12 0 0 0-10.7-6.3 11.9 11.9 0 0 0-8.8 3.6s-5 5.6-14.7 5.6c-7.1-.2-13.4-4-13.4-14l.2-33.2a14.2 14.2 0 1 0 7.4-26.3 13.9 13.9 0 0 0-7.2 2v-12a7.8 7.8 0 0 0-7.8-7.8h-10.5a7.8 7.8 0 0 0-7.8 7.7l-.5 71.3c-.7 8.8-6.8 12.3-13.7 12.3-7.2 0-13.8-3.8-13.8-14l.5-45a5.1 5.1 0 0 0-5.1-5.1H80.5a5.1 5.1 0 0 0-5.2 5.1l-.5 51.4c-.2 21.4 16.7 31 40 31 9.7 0 16.3-4 21-9.4 5.8 6.4 15 9.4 26.2 9.4s19.5-1.5 27.8-6.2a50.7 50.7 0 0 0 22 6.2c24 1.9 38.7-9 40.1-27.7 1.3-16.8-11.3-23.5-29.3-29.7zM89 89.1A14.2 14.2 0 1 0 74.8 75 14.2 14.2 0 0 0 89 89.1zM256 194c-23.3 0-40.8 13-45.6 35.2-1.3-20.8-14.5-33.2-34-33.2a31 31 0 0 0-27.8 14.3 32.1 32.1 0 0 0-28.3-14.3c-11.5 0-47.8-1.4-47.8 45.3v33.9a12.7 12.7 0 1 0 25.5 0V238c0-10.8 6.1-18.1 16.2-18.1 9.3 0 14.7 6.4 14.7 18v37.5a12.7 12.7 0 0 0 25.5 0v-40.5c1-9.1 6.6-15 15.7-15 9.7 0 15 6.4 15 18v37.5a12.7 12.7 0 1 0 25.4 0v-20c5 21.8 22.5 34.8 46.2 34.8 11.2 0 21.8-3.4 29.5-9l.8-.6.8-.6v-.1l1.4-1c7.4-6.8 3.1-19.4-6.9-20.7a12 12 0 0 0-11.4 5c-3 3.8-8 6-14.8 6-11.8 0-18.8-7.4-19.4-18.8H292a8.3 8.3 0 0 0 8.3-8.3V240c0-28.8-17.5-46-44.1-46zm-19.3 39c.8-11.9 8-18.7 19.2-18.7 11.8 0 17.5 7.4 18 18.6z" fill="#fff" className="front" />
  </>
), {
  viewBox: '0 0 362.8 362.8',
});

BeItsMe.displayName = 'BeItsMe';

export default BeItsMe;
