import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@oneflowab/pomes';

import ActionBar from 'components/action-bar';
import Button from 'components/button';
import {
  CancelButton,
} from 'components/buttons';
import Edit from 'components/icons/edit';
import CircularSpinner from 'components/icons/circular-spinner';

import style from './notifications.module.scss';

class NotificationsActionBar extends React.Component {
  static propTypes = {
    updateState: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      success: PropTypes.bool.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    actionBarCollapsed: true,
    actionBarContent: undefined,
  };

  componentDidUpdate(prevProps) {
    const updateStateChanged = prevProps.updateState.success !== this.props.updateState.success;

    if (updateStateChanged) {
      this.collapseActionBar();
      this.props.onSuccess();
    }
  }

  cancel = () => {
    this.collapseActionBar();
    this.props.onCancel();
  };

  enableEditing({ actionBarContent }) {
    this.expandActionBar({ actionBarContent });
    this.props.onClick();
  }

  expandActionBar({ actionBarContent }) {
    this.setState({
      actionBarCollapsed: false,
      actionBarContent,
    });
  }

  collapseActionBar() {
    this.setState({
      actionBarCollapsed: true,
      actionBarContent: undefined,
    });
  }

  render() {
    return (
      <ActionBar collapsed={this.state.actionBarCollapsed}>
        <ActionBar.Group>
          <Button
            key="edit-notifications"
            icon={Edit}
            kind="primary"
            onClick={() => this.enableEditing({ actionBarContent: 'edit-notifications' })}
          >
            <Message
              id="Edit"
              comment="The button text for editing notifications"
            />
          </Button>
        </ActionBar.Group>
        <ActionBar.Content active={this.state.actionBarContent === 'edit-notifications'}>
          <div className={style.ActionButtons}>
            <Button
              icon={this.props.updateState.loading ? CircularSpinner : null}
              kind="primary"
              type="submit"
              disabled={this.props.updateState.loading}
            >
              <Message
                id="Confirm"
                comment="Button text to confirm notification changes."
              />
            </Button>
            <CancelButton onClick={this.cancel} />
          </div>
        </ActionBar.Content>
      </ActionBar>
    );
  }
}

export default NotificationsActionBar;
