import { Message } from '@oneflowab/pomes';

export const titles = {
  deliveryChannels: (
    <Message
      id="Delivery channels"
      comment="Title for delivery channels checkbox group."
    />
  ),
  signMethods: (
    <Message
      id="Sign method"
      comment="Title for sign methods checkbox group."
    />
  ),
  mfaChannels: (
    <Message
      id="Two-factor authentication"
      comment="Title for two-factor authentication checkbox group."
    />
  ),
};

export const modalData = {
  deliveryChannels: {
    email: (
      <Message
        id="Email"
        comment="Delivery channel"
      />
    ),
    SMS: (
      <Message
        id="SMS"
        comment="Delivery channel"
      />
    ),
    sameDevice: (
      <Message
        id="Same device"
        comment="Delivery channel"
      />
    ),
    emailAndSMS: (
      <Message
        id="Email and SMS"
        comment="Delivery channel"
      />
    ),
  },
  signMethods: {
    standardESignature: (
      <Message
        id="Standard e-signature"
        comment="Sign methods"
      />
    ),
    SMSVerification: (
      <Message
        id="SMS verification"
        comment="Sign methods"
      />
    ),
    swedishBankID: (
      <Message
        id="Swedish BankID"
        comment="Sign methods"
      />
    ),
    norwegianBankID: (
      <Message
        id="Norwegian BankID"
        comment="Sign methods"
      />
    ),
    danishMitID: (
      <Message
        id="Danish MitID"
        comment="Sign methods"
      />
    ),
    finnishFTN: (
      <Message
        id="Finnish FTN"
        comment="Sign methods"
      />
    ),
    handwrittenSignature: (
      <Message
        id="Handwritten Signature"
        comment="Sign methods"
      />
    ),
    electronicID: (
      <Message
        id="Electronic ID"
        comment="Sign methods"
      />
    ),
  },
  mfaChannels: {
    none: (
      <Message
        id="Off"
        comment="MFA method"
      />
    ),
    email: (
      <Message
        id="Email"
        comment="MFA method"
      />
    ),
    sms: (
      <Message
        id="SMS"
        comment="MFA method"
      />
    ),
    personalIdentification: (
      <Message
        id="Personal Identification"
        comment="MFA method"
      />
    ),
  },
};
