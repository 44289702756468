import { Message } from '@oneflowab/pomes';

import Modal from 'components/modal';
import Button from 'components/button';
import CancellationReasonList from 'components/modals/cancellation/cancellation-reasons/cancellation-reason-list';
import CancellationNavigation from 'components/cancel-subscription/navigation/cancellation-navigation';
import CancellationReason from './cancellation-reason/cancellation-reason';

import style from './cancellation-reasons.module.scss';

export type Props = {
  modalKey: string;
  onPreviousStep: () => void;
  onClose: () => void;
  onChange: (reference: string) => void;
};

const CancellationReasonsModal = ({
  modalKey,
  onPreviousStep,
  onClose,
  onChange,
}: Props) => {
  const renderBody = () => (
    <div className={style.CancellationReasonsContainer}>
      <CancellationNavigation onPreviousStep={onPreviousStep} />
      <p>
        <Message
          id="We'd love to understand your reason for canceling. Your feedback will help us improve the Oneflow experience for everyone."
          comment="Header for cancellation reason section"
        />
      </p>
      {CancellationReasonList.map(
        ({
          id,
          label,
          reference,
          enabled,
        }) => (
          <CancellationReason
            key={id}
            label={label}
            reference={reference}
            onSelect={onChange}
            enabled={enabled}
          />
        ),
      )}
    </div>
  );

  const getAction = () => (
    <Button
      kind="linkSeparate"
      onClick={onClose}
    >
      <Message
        id="Keep my Subscription Plan"
        comment="Button text for keeping the subscription plan"
      />
    </Button>
  );

  return (
    <Modal
      header={(
        <Message
          id="Why do you want to cancel?"
          comment="Header in the cancellation reasons modal"
        />
    )}
      isOpen
      modalKey={modalKey}
      onCancel={onClose}
    >
      <Modal.Body isLoading={false}>
        {renderBody()}
      </Modal.Body>
      <Modal.Actions>
        {getAction()}
      </Modal.Actions>
    </Modal>
  );
};

export default CancellationReasonsModal;
