import { FlattenedAiData } from 'components/modals/import-contract/helpers';
import sessionStorage from 'utils/session-storage';

export const removeAiProperty = (
  agreementId: number,
  matchId: number,
  type: 'boxes' | 'parties' | 'participants' | 'closeTime',
) => {
  const storedData = sessionStorage.getItem('extracted_data');
  if (!storedData) return;
  const extractedData = JSON.parse(storedData)[agreementId] as FlattenedAiData;

  if (!extractedData) {
    return;
  }

  if (type === 'closeTime') {
    sessionStorage.setItem('extracted_data', JSON.stringify({
      [agreementId]: {
        ...extractedData,
        closeTime: '',
      },
    }));
    return;
  }

  const updatedData = {
    [agreementId]: {
      ...extractedData,
      [type]: Object.fromEntries(
        Object.entries(extractedData[type]).filter(([key]) => key !== String(matchId)),
      ),
    },
  };
  sessionStorage.setItem('extracted_data', JSON.stringify(updatedData));
};
