import React from 'react';
import { Message } from '@oneflowab/pomes';

import agreements from 'reducers/entities/agreements';

import Checkbox from './components/checkbox/checkbox';

import BulkMoveAction from './actions/move/bulk-move-action';
import { BulkDeleteAction } from './actions/delete/bulk-delete-action';
import StickyElement from './components/sticky-element/sticky-element';
import style from './bulk-actions-bar.module.scss';
import { useSelectionContext } from '../selection-context';

type Props = {
  query: ReturnType<typeof agreements.getQuerySelector>;
  queryName: string,
  onSelectAllCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

export const BulkActionsBar = ({
  query,
  queryName,
  onSelectAllCheckboxChange,
}: Props) => {
  const { selectedDocuments } = useSelectionContext();

  const { result } = query;
  const someChecked = result.some((agreementId) => selectedDocuments[agreementId]);
  const allChecked = result.every((agreementId) => selectedDocuments[agreementId]);
  const checkedCount = Object.values(selectedDocuments).filter((value) => value).length;

  return (
    <StickyElement shouldStick className={style.BulkActionsMenu}>
      <div className={style.Content}>
        <Checkbox
          customClass={style.CheckboxLabel}
          showMinusIcon={someChecked && !allChecked}
          input={{
            checked: someChecked,
            onChange: onSelectAllCheckboxChange,
          }}
          label={someChecked ? null : (
            <Message
              id="Select all"
              comment="Label for the select all checkbox"
            />
          )}
        />
        {someChecked && (
          <>
            <span className={style.SelectedCount} data-testid="selected-count">
              <Message
                id="{count} document selected"
                pluralId="{count} documents selected"
                pluralCondition="count"
                comment="Label for the number of documents selected"
                values={{ count: checkedCount }}
              />
            </span>
            <BulkMoveAction queryName={queryName} />
            <BulkDeleteAction queryName={queryName} />
          </>
        )}
      </div>
    </StickyElement>
  );
};
