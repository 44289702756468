// @flow
import { getAgreementMyParticipant } from 'agreement/selectors';
import { isSigned } from 'agreement';
import isEmpty from 'lodash/isEmpty';
import slugify from 'slugify';

// eslint-disable-next-line import/prefer-default-export
export const getPDFContractURL = (agreement: Agreement, position: Position, guestToken: string) => {
  const extension = '.pdf';
  const participant = getAgreementMyParticipant(agreement);
  const accessTokenParam = 'at';
  const contractName = agreement.name;
  let name = 'oneflow';
  const baseUrl = `${document.location.protocol}//${document.location.hostname}/api/agreements/${agreement.id}/assets/`;
  if (!isEmpty(position) && contractName) {
    name = slugify(contractName);
  }

  const fileName = isSigned(agreement)
    ? `${name}-signed-${agreement.id}${extension}`
    : `${name}-${agreement.id}${extension}`;
  const link = `${baseUrl}contract.pdf?redirect=true&download=${fileName}`;

  if (participant) {
    if (!guestToken) {
      return link;
    }
    const separator = link.indexOf('?') === -1 ? '?' : '&';
    const newLink = `${link}${separator}${accessTokenParam}=${guestToken}`;
    return newLink;
  }

  return link;
};
