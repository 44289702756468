import { forwardRef, useEffect } from 'react';
import { PopoverTrigger } from '@radix-ui/react-popover';

import Item from './item';
import { useSubContext } from './sub-context';
import { useRootMenuStateContext } from './root';
import type { ItemProps } from './item';
import type { DivProps } from './types';

const SubTrigger = forwardRef<HTMLDivElement, Omit<ItemProps, 'onSelect'> & DivProps>(({
  children,
  ...props
}, ref) => {
  const {
    id: subMenuId,
    isSubmenuOpen,
    triggerRefId,
  } = useSubContext();
  const { setState } = useRootMenuStateContext();

  useEffect(() => {
    triggerRefId.current = props.id;
  }, [props.id, triggerRefId]);

  return (
    <PopoverTrigger asChild>
      <Item
        {...props}
        type="trigger"
        subMenuId={subMenuId}
        onSelect={() => {
          setState((prevState) => ({
            ...prevState,
            subMenus: {
              ...prevState.subMenus,
              [subMenuId]: true,
            },
          }));
        }}
        onMouseEnter={(e) => {
          setState((prevState) => ({
            ...prevState,
            activeItemId: props.id,
            subMenus: {
              ...prevState.subMenus,
              [subMenuId]: true,
            },
          }));
          e.preventDefault();
        }}
        ref={ref}
        data-state={isSubmenuOpen ? 'open' : 'closed'}
        aria-expanded={isSubmenuOpen}
      >
        {children}
      </Item>
    </PopoverTrigger>
  );
});

SubTrigger.displayName = 'SubTrigger';

export default SubTrigger;
