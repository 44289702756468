import { ReactNode, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';
import { isSharedFromAnotherWorkspace } from 'agreement/selectors';
import { getCurrentWorkspaceIdSelector } from 'reducers/app';
import agreementsReducer from 'reducers/entities/agreements';

import {
  isAssetFailed,
  isAssetProcessing,
  showFailureToast,
  showProcessingToast,
} from 'components/asset-error-toasts';

import CreateDocumentsFromTemplateModal from './modal/create-document-from-template';

type Props = {
  agreement: Oneflow.Agreement,
  children: (onClick: (event: SyntheticEvent) => void) => ReactNode,
  onFailure?: (error: any) => void,
  amplitudeScope?: string,
};

type Data = {
  sourceId: number,
  amplitudeScope?: string,
  workspaceId?: number,
  name?: Oneflow.Agreement['name'],
};

export const CreateDocumentFromTemplate = ({
  onFailure,
  children,
  agreement,
  amplitudeScope,
}: Props) => {
  const dispatch = useDispatch();
  const workspaceId: Oneflow.Workspace['id'] = useSelector(getCurrentWorkspaceIdSelector);

  const createDocument = (name?: Oneflow.Agreement['name']) => {
    let data: Data = {
      sourceId: agreement.id,
      name,
      amplitudeScope,
    };

    if (isSharedFromAnotherWorkspace(agreement, workspaceId)) {
      data = {
        ...data,
        workspaceId,
      };
    }

    dispatch(agreementsReducer.createAgreement({
      data,
      pipe: {
        onSuccess: (successData: any) => {
          redirectToDocumentOnDocumentCreate(successData);
        },
        onFailure: (error: any) => {
          const showAssetFailureMessage = isAssetFailed(error);
          const showAssetProcessingMessage = isAssetProcessing(error);

          if (showAssetFailureMessage) {
            showFailureToast();
          }

          if (showAssetProcessingMessage) {
            showProcessingToast();
          }
          onFailure?.(error);
        },
      },
    }));
  };

  const resetFormState = () => {
    dispatch(agreementsReducer.createAgreementReset());
  };

  const formState = useSelector(agreementsReducer.getCreateSelector);

  return (
    <CreateDocumentsFromTemplateModal
      documentName={agreement.name}
      resetFormState={resetFormState}
      createDocument={createDocument}
      formState={formState}
    >
      {children}
    </CreateDocumentsFromTemplateModal>
  );
};
