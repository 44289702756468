// @flow

import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

export const MFA_EMAIL = 'email';
export const MFA_SMS = 'sms';

export const mfaChannelTypes = (message: MessageTranslator) => ({
  [MFA_EMAIL]: {
    label: message({
      id: 'Email',
      comment: 'Used as the label of the channel type email option in the two-step authentication settings page.',
    }),
    aclCheck: 'position:mfa:email',
  },
  [MFA_SMS]: {
    label: message({
      id: 'SMS',
      comment: 'Used as the label of the channel type SMS option in the two-step authentication settings page.',
    }),
    aclCheck: 'position:mfa:sms',
  },
});

export const getMfaChannelType = (channel?: MfaChannelType, message: MessageTranslator) => (
  mfaChannelTypes(message)[channel]
);

type Props = {
  channel?: MfaChannelType,
  message: MessageTranslator,
};

export const MfaChannelComponent = ({ channel, message }: Props) => {
  const channelType = getMfaChannelType(channel, message);

  if (!channelType) {
    return null;
  }

  return channelType.label;
};

MfaChannelComponent.defaultProps = {
  channel: undefined,
};

export default localize<Props>(MfaChannelComponent);
