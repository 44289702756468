/* eslint-disable react/prop-types */
import * as React from 'react';
import clsx from 'clsx';

import style from './form.module.scss';

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx(style.FormItem, className)} {...props} />
));
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<
  HTMLLabelElement,
  React.ComponentPropsWithoutRef<'label'>
>(({ className, htmlFor, ...props }, ref) => (
  <label
    ref={ref}
    className={clsx(style.FormLabel, className)}
    htmlFor={htmlFor}
    {...props}
  />
));
FormLabel.displayName = 'FormLabel';

export { FormItem, FormLabel };
