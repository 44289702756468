import clsx from 'clsx';

import { roundBy } from 'utils/math';
import { formatNumberWithTrailingZeros } from 'utils/format-number-with-trailing-zeros';
import { ProductTableBox, DiscountType } from 'data-validators/entity-schemas/document-box/product-table';

import Message from 'components/message';
import {
  PRODUCT_PERCENTAGE_DISCOUNT,
} from 'components/contract-boxes/product-table-box/constants';

import { hasZeroPriceWithPercentageDiscount, isBasePriceVisible } from 'components/contract-boxes/product-table-box/product-table-box-helpers';

import useCurrentPriceRoundingMethod from 'hooks/use-current-price-rounding-method';
import AffixedPrice from 'components/contract-boxes/product-table-box/product-table/affixed-price';

import style from './expanded-product-price.module.scss';

type Props = {
  price: number,
  discountAmount: number,
  priceDiscounted: number,
  discountType: DiscountType | undefined,
  prefix: ProductTableBox['config']['affixes']['prefix'],
  postfix: ProductTableBox['config']['affixes']['postfix'],
  pricePrecision: ProductTableBox['config']['pricePrecision'],
}

const ExpandedProductPrice = ({
  price, discountAmount, priceDiscounted, discountType, prefix, postfix, pricePrecision,
}: Props) => {
  const currentPriceRoundingMethod = useCurrentPriceRoundingMethod();
  const hasDiscount = Boolean(discountAmount);
  const hasMultipliedByZero = hasZeroPriceWithPercentageDiscount(
    discountAmount,
    price,
    discountType,
  );

  const shouldShowBasePrice = isBasePriceVisible(price, priceDiscounted);

  const renderDiscountedPrice = () => {
    if (!hasDiscount) {
      return null;
    }
    return (
      <div data-testid="discounted-price" className={style.DiscountedPrice}>
        <AffixedPrice
          formattedPrice={formatNumberWithTrailingZeros(roundBy(
            currentPriceRoundingMethod,
            priceDiscounted,
            pricePrecision,
          ), pricePrecision)}
          prefix={prefix}
          postfix={postfix}
        />
      </div>
    );
  };

  const renderDiscountAmount = () => {
    if (!hasDiscount || typeof discountAmount !== 'number' || priceDiscounted < 0) {
      return null;
    }

    const roundedDiscountAmount = formatNumberWithTrailingZeros(roundBy(
      currentPriceRoundingMethod,
      discountAmount * 100,
      pricePrecision,
    ), pricePrecision);

    if (discountType === PRODUCT_PERCENTAGE_DISCOUNT) {
      const percentageDiscount = (
        <span>
          {roundBy(
            currentPriceRoundingMethod,
            discountAmount * 100,
            pricePrecision,
          )}
          {' '}
          %
        </span>
      );

      return (
        <Message
          id="{discountAmount} discount"
          data-testid="discount-amount-percentage"
          values={{
            discountAmount: discountType === PRODUCT_PERCENTAGE_DISCOUNT
              ? percentageDiscount
              : roundedDiscountAmount,
          }}
          comment="The amount discounted from the product price"
        />
      );
    }
    const affixedDiscount = (
      <span>
        <AffixedPrice
          formattedPrice={formatNumberWithTrailingZeros(roundBy(
            currentPriceRoundingMethod,
            discountAmount,
            pricePrecision,
          ), pricePrecision)}
          prefix={prefix}
          postfix={postfix}
        />
      </span>
    );
    return (

      <Message
        id="{discountAmount} discount"
        data-testid="discount-amount-numeric"
        values={{
          discountAmount: affixedDiscount,
        }}
        comment="The amount discounted from the product price"
      />
    );
  };

  if (hasMultipliedByZero) {
    return null;
  }

  return (
    <div className={style.ProductPrice}>
      <div className={style.Prices}>
        {shouldShowBasePrice && (
          <div data-testid="base-price" className={clsx(style.BasePrice, { [style.HasDiscount]: hasDiscount })}>
            <AffixedPrice
              formattedPrice={formatNumberWithTrailingZeros(roundBy(
                currentPriceRoundingMethod, price, pricePrecision,
              ), pricePrecision)}
              prefix={prefix}
              postfix={postfix}
            />
          </div>
        )}
        {renderDiscountedPrice()}
      </div>
      <div className={style.DiscountAmount}>
        {renderDiscountAmount()}
      </div>
    </div>
  );
};

export default ExpandedProductPrice;
