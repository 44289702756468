import Button from 'components/button';

import style from './cancellation-reason.module.scss';

type Props = {
  label: React.ReactNode;
  reference: string;
  onSelect?: (reference: string) => void;
  enabled: boolean | undefined,
}

const CancellationReason = ({
  label,
  reference,
  onSelect,
  enabled, // temporary
}: Props) => {
  const onClickHandler = () => {
    if (onSelect && enabled) {
      onSelect(reference);
    }
  };

  return (
    <div className={style.CancellationReasonContainer}>
      <Button
        onClick={onClickHandler}
        customClass={style.CancellationReasonButton}
      >
        {label}
      </Button>
    </div>
  );
};

export default CancellationReason;
