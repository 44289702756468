// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import AddColumnBefore from 'components/icons/add-column-before';
import AddColumnAfter from 'components/icons/add-column-after';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import addTableColumn from 'components/rich-text-editor/editor-plugins/table-utils/add-table-column';
import ToolbarButton from '../toolbar-button';

type Props = {
  direction: 'before' | 'after',
};

const getLabel = (direction: 'before' | 'after') => {
  if (direction === 'before') {
    return (
      <Message
        id="Add column before"
        comment="The label of the editor's toolbar plugin that inserts a table column."
      />
    );
  }

  return (
    <Message
      id="Add column after"
      comment="The label of the editor's toolbar plugin that inserts a table column."
    />
  );
};

const getIcon = (direction: 'before' | 'after') => {
  if (direction === 'before') {
    return <AddColumnBefore />;
  }

  return <AddColumnAfter />;
};

const AddTableColumn = ({ direction }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      label={getLabel(direction)}
      isMenuItem={false}
      onTrigger={() => {
        focusEditor();
        addTableColumn(editor, direction);
      }}
      icon={getIcon(direction)}
    />
  );
};

export default AddTableColumn;
