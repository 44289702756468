import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Message } from '@oneflowab/pomes';

import useAgreement from 'hooks/use-agreement';
import { getGuestToken, getMyParticipantWhenUpdater } from 'agreement/selectors';
import { isTemplate } from 'agreement';

import BoxEmptyDraftState from 'components/box-empty-draft-state';
import Sigma from 'components/icons/sigma';

type Props = {
  isAllowedToEdit: boolean,
  onClick: () => void,
  agreementId: number,
};

const EmptyProductSumBox = ({
  agreementId,
  isAllowedToEdit,
  onClick,
}: Props) => {
  const isGuest = useSelector(getGuestToken);
  const agreement = useAgreement(agreementId);
  const myParticipant = !isEmpty(agreement) ? getMyParticipantWhenUpdater(agreement) : {};

  if (isGuest) {
    return null;
  }

  if ((isEmpty(myParticipant) && !isTemplate(agreement)) || !isAllowedToEdit) {
    return (
      <i>
        <Message
          id="No summations"
          comment="Text showing when there are no summations added yet."
        />
      </i>
    );
  }

  return (
    <BoxEmptyDraftState
      icon={Sigma}
      onClick={onClick}
      buttonText={(
        <Message
          id="Add product summation"
          comment="Action to create a new product summation."
        />
      )}
    />
  );
};

export default EmptyProductSumBox;
