/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
// @flow

import React from 'react';
import {
  Editable,
  useFocused,
  useSlate,
} from 'slate-react';
import clsx from 'clsx';

import { useRichTextProps } from 'contexts/rich-text';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { handleRedo, handleUndo } from 'components/rich-text-editor/utils';
import AddLinkPopover from 'components/rich-text-editor-toolbars/toolbar-buttons/link-button/add-link-popover';

import useOnEditableFocus from './use-on-editable-focus';
import style from './contract-text-editable.module.scss';

type Props = {
  renderElement: RenderSlateElementProps => React.Node,
  renderLeaf: RenderSlateLeafProps => React.Node,
  onKeyDown?: Function,
  placeholder?: string,
  readOnly?: boolean,
  customClassName?: any,
  hasOutline?: boolean,
  isResponsiveView?: boolean,
};

const TextEditable = ({
  renderElement,
  renderLeaf,
  onKeyDown,
  placeholder,
  readOnly,
  customClassName,
  hasOutline,
  isResponsiveView,
}: Props) => {
  const editor = useSlate();
  const focused = useFocused();
  const onFocus = useOnEditableFocus(editor);
  const { onMouseDown } = useRichTextProps();
  const focusEditor = useFocusEditor();

  const className = clsx('rich-text-region', style.TextEditable, customClassName, {
    [style.ReadOnly]: readOnly,
    open: focused,
  });

  const editableClassName = clsx(
    {
      notranslate: !readOnly,
      [style.EditableContent]: !readOnly,
      [style.EditableReadOnlyContent]: readOnly,
      [style.Borderless]: isResponsiveView,
    },
    {
      open: focused,
      [style.Focused]: focused && hasOutline,
    },
  );

  const onClick = (event: any) => {
    if (event.target.form) {
      return;
    }

    focusEditor();
  };

  return (
    <div
      role="button"
      onClick={onClick} // To set the focus when clicking on the edge
      className={className}
      data-testid="text-editable-container"
    >
      <AddLinkPopover />
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={!readOnly && placeholder}
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        readOnly={readOnly}
        className={editableClassName}
        spellCheck={false}
        onDOMBeforeInput={(event) => {
          handleRedo(editor, event);
          handleUndo(editor, event);
        }}
        onFocus={onFocus}
      />
    </div>
  );
};

TextEditable.defaultProps = {
  onKeyDown: undefined,
};

export default TextEditable;
