/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import {
  MultistepModal,
  ModalStep,
} from 'components/modal';
import { checkAcl } from 'components/acl';

import { WelcomeModal } from './welcome-modal';
import CreateWorkspaceModal from './create-workspace-modal';

function GetStartedModal(props) {
  const {
    canAccessCurrentWorkspace, account, acl, shouldShowWorkspaceModal,
  } = props;
  const initialOnBoardingState = account.onboardingStage === 'new' ? 'completed' : 'pending';
  const [onBoardingStatus, setOnBoardingStatus] = useState(initialOnBoardingState);
  const hasCreateWorkspacePermission = checkAcl(acl.account, 'account:collection:create');

  if (
    shouldShowWorkspaceModal
    && hasCreateWorkspacePermission
  ) {
    return (
      <CreateWorkspaceModal shouldSkipOnboarding />
    );
  }

  return (
    <MultistepModal
      isOpen={canAccessCurrentWorkspace && onBoardingStatus !== 'completed'}
    >
      <ModalStep>
        {({ onStepComplete }) => (
          <CreateWorkspaceModal onStepComplete={onStepComplete} />
        )}
      </ModalStep>
      <ModalStep>
        {({ onStepComplete }) => (
          <WelcomeModal
            onClose={() => {
              setOnBoardingStatus('completed');
              onStepComplete();
            }}
          />
        )}
      </ModalStep>
    </MultistepModal>
  );
}

export default GetStartedModal;
