import type { ReactNode } from 'react';
import clsx from 'clsx';
import * as Tabs from '@radix-ui/react-tabs';
import { Message } from '@oneflowab/pomes';

// eslint-disable-next-line import/named
import {
  MESSAGES_TAB,
  MORE_OPTIONS_TAB,
  SETTINGS_TAB,
  DATA_FIELDS_TAB,
  AUDIT_TRAIL_TAB,
  PARTICIPANTS_TAB,
  DOCUMENT_TAB,
  CONTENT_TAB,
} from 'agreement/constants';
import { getPDFContractURL } from 'utils/download-pdf-link';
import { isSigned } from 'agreement';

import CountBadge from 'components/document-tabs/count-badge';
import CollaborationsIcon from 'components/icons/collaborations';
import CogIcon from 'components/icons/cog';
import MoreOptionsIcon from 'components/icons/new-more-options';
import DownloadIcon from 'components/icons/new-download';
import TypeCursorIcon from 'components/icons/type-cursor';
import AuditTrailIcon from 'components/icons/audit-trail';
import ParticipantsIcon from 'components/icons/new-participants';
import toast from 'components/toasts';

import type { MenuItem } from 'components/document-layout-container/collapsed-document-layout/navigation-bar/burger-menu';
import type { DocumentTabValue } from 'components/document-layout-container/types';
import Button from 'components/button';
import { checkAcl } from 'components/acl';
import ContentTabIcon from 'components/icons/content-tab';

export type TriggerItems = {
  trigger: ReactNode,
  menuItem: MenuItem,
  visible: boolean,
}[]

type Params = {
  availableSpace: number,
  triggerItems: TriggerItems,
  triggerItemWidth?: number,
  gap?: number,
};

const getTriggerItems = ({
  availableSpace, triggerItems, triggerItemWidth = 42, gap = 8,
}: Params) => {
  // Remove first icons (participant tab is always visible)
  const availableSpaceForTriggers = availableSpace - triggerItemWidth;
  // How much space one trigger takes
  const triggerSpace = triggerItemWidth + gap;
  const triggerFitsInSpaceCount = Math.floor(availableSpaceForTriggers / triggerSpace);

  // Filter out triggers that are not visible
  const visibleTriggerItems = triggerItems.filter((trigger) => trigger.visible);

  // Minus one counting the burger menu
  const triggerFitsInSpaceWithBurgerMenu = triggerFitsInSpaceCount - 1;
  const triggerCountInBar = visibleTriggerItems.length <= triggerFitsInSpaceCount
    ? triggerFitsInSpaceCount : triggerFitsInSpaceWithBurgerMenu;

  const newVisibleTriggerItems: ReactNode[] = [];
  const newVisibleBurgerMenuItems: MenuItem[] = [];

  visibleTriggerItems.forEach((trigger, index) => {
    if (index + 1 <= triggerCountInBar) {
      newVisibleTriggerItems.push(trigger.trigger);
    } else {
      newVisibleBurgerMenuItems.push(trigger.menuItem);
    }
  });

  return {
    triggerItems: newVisibleTriggerItems,
    burgerMenuItems: newVisibleBurgerMenuItems,
  };
};

type GetAvailableTriggerItemsParams = {
  agreement: Oneflow.Document,
  guestToken: string,
  setActiveTab: (tab: DocumentTabValue) => void,
  activeTab: DocumentTabValue,
  triggerClassName: string,
  messageTabClassName: string,
  menuMessageTabClassName: string,
  downloadPDFClassName: string,
  position: Oneflow.Position,
  showDataFields: boolean,
  newMessagesCount: number,
  shouldFadeOutCountBadge: boolean,
  newAuditTrailCount: number,
  isInPreviewMode: boolean,
  isCollapsedLayout: boolean,
  isContentTabFeatureEnabled: boolean,
}

export const getAvailableTriggerItems = ({
  agreement,
  guestToken,
  setActiveTab,
  activeTab,
  triggerClassName,
  messageTabClassName,
  menuMessageTabClassName,
  position,
  downloadPDFClassName,
  showDataFields,
  newMessagesCount,
  shouldFadeOutCountBadge,
  newAuditTrailCount,
  isInPreviewMode,
  isCollapsedLayout = false,
  isContentTabFeatureEnabled,
}: GetAvailableTriggerItemsParams): TriggerItems => {
  const isGuest = Boolean(guestToken);
  const pdfContractURL = getPDFContractURL(agreement, position, guestToken);
  const canDownloadPdf = checkAcl(agreement.acl, 'agreement:download:pdf');
  const showSignButton = canDownloadPdf && isSigned(agreement);

  const onClick = (tab: DocumentTabValue) => {
    if (activeTab === tab) {
      setActiveTab(DOCUMENT_TAB);
      return;
    }

    setActiveTab(tab);
  };

  return [
    {
      trigger: (
        <Tabs.Trigger value={PARTICIPANTS_TAB} className={triggerClassName} key="participant-tab" onClick={() => onClick(PARTICIPANTS_TAB)}>
          <ParticipantsIcon height="24px" />
        </Tabs.Trigger>),
      menuItem: {
        icon: <ParticipantsIcon height="16px" />,
        text: <Message id="Participants" comment="Tab title" />,
        onClick: () => onClick(PARTICIPANTS_TAB),
        value: PARTICIPANTS_TAB,
      },
      visible: true,
    },
    {
      trigger: (
        <Tabs.Trigger value={CONTENT_TAB} className={triggerClassName} key="content-tab" onClick={() => onClick(CONTENT_TAB)}>
          <ContentTabIcon height="24px" />
        </Tabs.Trigger>),
      menuItem: {
        icon: <ParticipantsIcon height="16px" />,
        text: <Message id="Content" comment="Tab title" />,
        onClick: () => onClick(CONTENT_TAB),
        value: CONTENT_TAB,
      },
      visible: !isGuest && isContentTabFeatureEnabled,
    },
    {
      trigger: (
        <Tabs.Trigger
          value={MESSAGES_TAB}
          className={clsx(messageTabClassName, triggerClassName, 'collaboration-trigger')}
          key="message-tab"
          onClick={() => {
            if (isInPreviewMode && isCollapsedLayout) {
              return toast.warning({
                id: 'not-available-in-preview',
                title: <Message
                  id="Not available in preview"
                  comment="Title for the warning message when signing is not available in preview mode."
                />,
                duration: 5000,
              });
            }
            return onClick(MESSAGES_TAB);
          }}
        >
          <CollaborationsIcon width="24px" height="24px" />
          {!isInPreviewMode
            && (<CountBadge fadeOut={shouldFadeOutCountBadge} count={newMessagesCount} />)}
        </Tabs.Trigger>),
      menuItem: {
        icon: (
          <div className={menuMessageTabClassName}>
            <CollaborationsIcon width="16px" />
            {!isInPreviewMode
             && (<CountBadge fadeOut={shouldFadeOutCountBadge} count={newMessagesCount} />)}
          </div>),
        text: <Message id="Collaboration" comment="Tab title" />,
        onClick: () => onClick(MESSAGES_TAB),
        value: MESSAGES_TAB,
      },
      visible: true,
    },
    {
      trigger: (
        <Tabs.Trigger value={DATA_FIELDS_TAB} className={triggerClassName} key="data-fields-tab" onClick={() => onClick(DATA_FIELDS_TAB)}>
          <TypeCursorIcon width="24px" height="24px" />
        </Tabs.Trigger>
      ),
      menuItem: {
        icon: <TypeCursorIcon width="16px" />,
        text: <Message id="Data Fields" comment="Tab title" />,
        onClick: () => onClick(DATA_FIELDS_TAB),
        value: DATA_FIELDS_TAB,
      },
      visible: showDataFields,
    },
    {
      trigger: (
        <Tabs.Trigger value={SETTINGS_TAB} className={clsx(triggerClassName, 'settings-trigger')} key="settings-tab" onClick={() => onClick(SETTINGS_TAB)}>
          <CogIcon width="20px" height="20px" />
        </Tabs.Trigger>
      ),
      menuItem: {
        icon: <CogIcon width="16px" />,
        text: <Message id="Settings" comment="Tab title" />,
        onClick: () => onClick(SETTINGS_TAB),
        value: SETTINGS_TAB,
      },
      visible: !isGuest,
    },
    {
      trigger: (
        <Tabs.Trigger value={MORE_OPTIONS_TAB} className={triggerClassName} key="more-options-tab" onClick={() => onClick(MORE_OPTIONS_TAB)}>
          <MoreOptionsIcon width="24px" height="24px" />
        </Tabs.Trigger>
      ),
      menuItem: {
        icon: <MoreOptionsIcon width="16px" />,
        text: <Message id="More options" comment="Tab title" />,
        onClick: () => onClick(MORE_OPTIONS_TAB),
        value: MORE_OPTIONS_TAB,
      },
      visible: isGuest,
    },
    {
      trigger: (
        <Tabs.Trigger
          value={AUDIT_TRAIL_TAB}
          className={clsx(messageTabClassName, triggerClassName)}
          key="audit-trail-tab"
          onClick={() => {
            if (isInPreviewMode && isCollapsedLayout) {
              return toast.warning({
                id: 'not-available-in-preview',
                title: <Message
                  id="Not available in preview"
                  comment="Title for the warning message when signing is not available in preview mode."
                />,
                duration: 5000,
              });
            }
            return onClick(AUDIT_TRAIL_TAB);
          }}
        >
          <AuditTrailIcon height="24px" />
          {!isInPreviewMode && (
            <CountBadge fadeOut={shouldFadeOutCountBadge} count={newAuditTrailCount} />
          )}
        </Tabs.Trigger>
      ),
      menuItem: {
        icon: (
          <div className={menuMessageTabClassName}>
            <AuditTrailIcon width="16px" />
            {!isInPreviewMode && (
              <CountBadge fadeOut={shouldFadeOutCountBadge} count={newAuditTrailCount} />
            )}
          </div>),
        text: <Message id="Audit trail" comment="Tab title" />,
        onClick: () => onClick(AUDIT_TRAIL_TAB),
        value: AUDIT_TRAIL_TAB,
      },
      visible: true,
    },
    {
      trigger: (
        <Button
          href={pdfContractURL}
          download
          disabled={!canDownloadPdf}
          data-testid="download-pdf-button"
          key="download-pdf-button"
          customClass={downloadPDFClassName}
          icon={<DownloadIcon width="24px" height="24px" />}
        />
      ),
      menuItem: {
        icon: <DownloadIcon width="16px" />,
        text: <Message id="Download as PDF" comment="Menu item action name" />,
        type: 'download',
        href: pdfContractURL,
        visible: canDownloadPdf,
      },
      visible: isGuest && showSignButton,
    },
  ];
};

export default getTriggerItems;
