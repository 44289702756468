// @flow

import * as React from 'react';
import clsx from 'clsx';

import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import Field from 'components/field';
import TextField from 'components/text-field';
import TextArea from 'components/text-area';
import { CountryField, EmailField } from 'components/fields';

import style from './add-contact.module.scss';

type Props = {
  message: MessageTranslator,
  lang: EnabledLanguages,
  onSubmit: () => void,
  formState: RpcState,
  resetFormState: () => void,
  children: Function => React.Node,
};

export class AddContactFields extends React.Component<Props> {
  renderBody() {
    const {
      message,
      lang,
    } = this.props;

    return (
      <>
        <div className={style.Row}>
          <Field
            name="fullname"
            label={message({
              id: 'Name',
              comment: 'Field label when adding contact to address book.',
            })}
            placeholder={message({
              id: 'Enter name',
              comment: 'Placeholder when adding contact to address book.',
            })}
            component={TextField}
            maxLength={100}
            required
            autoFocus
          />
          <EmailField />
        </div>
        <div className={style.Row}>
          <Field
            name="title"
            label={message({
              id: 'Title',
              comment: 'Field label when adding contact to address book.',
            })}
            placeholder={message({
              id: 'Enter title',
              comment: 'Placeholder when adding contact to address book.',
            })}
            component={TextField}
            maxLength={100}
          />
          <Field
            name="companyName"
            label={message({
              id: 'Company name',
              comment: 'Field label when adding contact to address book.',
            })}
            placeholder={message({
              id: 'Enter company name',
              comment: 'Placeholder when adding contact to address book.',
            })}
            component={TextField}
            maxLength={100}
          />

        </div>
        <div className={style.Row}>
          <Field
            name="companyOrgnr"
            label={message({
              id: 'Company registration number',
              comment: 'Field label when adding contact to address book.',
            })}
            placeholder={message({
              id: 'Enter company registration number',
              comment: 'Placeholder when adding contact to address book.',
            })}
            component={TextField}
            maxLength={25}
          />
          <Field
            name="ssn"
            label={message({
              id: 'Date of birth',
              comment: 'Field label when adding contact to address book.',
            })}
            placeholder={message({
              id: 'Enter date of birth',
              comment: 'Placeholder when adding contact to address book.',
            })}
            component={TextField}
            maxLength={25}
          />
        </div>
        <div className={style.Row}>
          <Field
            name="phoneNumber"
            label={message({
              id: 'Phone number',
              comment: 'Field label when adding contact to address book.',
            })}
            placeholder={message({
              id: 'Enter phone number',
              comment: 'Placeholder when adding contact to address book.',
            })}
            component={TextField}
            maxLength={50}
          />
          <CountryField languageCode={lang} clearable />
        </div>
        <div className={clsx(style.Row, style.FirstChildFixedHeight)}>
          <Field
            name="notes"
            label={message({
              id: 'Notes',
              comment: 'Field label when adding contact to address book.',
            })}
            placeholder={message({
              id: 'Enter notes',
              comment: 'Placeholder when adding contact to address book.',
            })}
            component={TextArea}
            maxLength={1000}
          />

        </div>
      </>
    );
  }

  render() {
    const {
      onSubmit,
      resetFormState,
      formState,
      children,
    } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Add contact"
            comment="Modal title for adding a contact"
          />
        )}
        body={this.renderBody()}
        onSubmit={onSubmit}
        resetFormState={resetFormState}
        formState={formState}
        isWideModal
        modalKey="add address book contact modal"
      >
        {children}
      </ModalForm>
    );
  }
}

export default localize<Props>(AddContactFields);
