import { useRef } from 'react';
import clsx from 'clsx';
import { useSlate } from 'slate-react';
import * as Popover from '@radix-ui/react-popover';
import type { ComponentProps } from 'react';

// eslint-disable-next-line import/named
import { useRichTextProps } from 'contexts/rich-text';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import SelectedRectangleAnchor from 'components/rich-text-editor-toolbars/toolbar-buttons/selected-rectangle-anchor/selected-rectangle-anchor';
import Menu from './ai-assist-menu/components/menu';
import ChatHistoryProvider from './context/chat-history';

import style from './ai-assist-popover.module.scss';
import Content from './content';

type Props = {
  open: boolean;
  onEscapeKeyDown: ComponentProps<typeof Popover.PopoverContent>['onEscapeKeyDown'];
  onInteractOutside: ComponentProps<typeof Popover.PopoverContent>['onInteractOutside'];
};

const POPOVER_PADDING = 8;

const AIAssistPopover = ({ open, onEscapeKeyDown, onInteractOutside }: Props) => {
  const popoverContentRef = useRef<HTMLDivElement>(null);
  const { selectedRectangle, cursorPosition, editorRef } = useRichTextProps();
  const editor = useSlate();
  const editorRect = (editorRef.current as HTMLDivElement | null)?.getBoundingClientRect();

  const { documentScrollContainerRef } = useDocumentLayout();
  const documentScrollContainerLeft = (
    documentScrollContainerRef?.current?.getBoundingClientRect().left || 0
  );
  const documentScrollContainerTop = (
    documentScrollContainerRef?.current?.getBoundingClientRect().top || 0
  );

  const anchorLeft = (editorRect?.left || 0) - documentScrollContainerLeft + POPOVER_PADDING;
  const sideOffset = selectedRectangle ? (selectedRectangle?.height / 2) + 4 : undefined;

  const selectedLocation = selectedRectangle || cursorPosition;

  const isEditorEmpty = editor.children.length === 1 && editor.isEmpty(editor.children[0]);

  // when there is no selection, we render the popover at the bottom of the editor
  const anchorTop = (isEditorEmpty || !selectedLocation)
    ? (editorRect?.bottom || 0) - documentScrollContainerTop
    : undefined;

  const className = clsx(style.AIAssistPopover, 'rich-text-region', {
    [style.IsVisible]: open,
  });

  return (
    <Popover.Popover open={open}>
      <Popover.PopoverAnchor asChild>
        <SelectedRectangleAnchor left={anchorLeft} top={anchorTop} isCustomAnchor />
      </Popover.PopoverAnchor>
      <Popover.PopoverPortal container={document.body}>
        <Popover.PopoverContent
          ref={popoverContentRef}
          sideOffset={sideOffset}
          onEscapeKeyDown={onEscapeKeyDown}
          onInteractOutside={onInteractOutside}
          avoidCollisions={false}
          className={className}
          style={{ width: editorRect?.width - POPOVER_PADDING * 2 }}
          align="start"
        >
          <Menu.Root id="default">
            <Menu.Provider>
              <ChatHistoryProvider>
                <Content />
              </ChatHistoryProvider>
            </Menu.Provider>
          </Menu.Root>
        </Popover.PopoverContent>
      </Popover.PopoverPortal>
    </Popover.Popover>
  );
};

export default AIAssistPopover;
