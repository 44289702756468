// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import webhooksReducer from 'reducers/entities/webhooks';

import { DeleteWebhook, type Props } from './delete-webhook';

type StateProps = {|
  removeState: RemoveState,
|};

type DispatchProps = {|
  deleteWebhook: () => void,
  resetRemoveState: Function,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  webhook: Webhook,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { webhook }) => ({
  removeState: webhooksReducer.getRemoveSelector(state, { id: webhook.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { webhook }) => ({
  deleteWebhook: () => {
    dispatch(webhooksReducer.removeWebhook({
      id: webhook.id,
    }));
  },
  resetRemoveState: () => {
    dispatch(webhooksReducer.removeWebhookReset({
      id: webhook.id,
    }));
  },
});

const connectedDeleteWebhook = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteWebhook);

export { connectedDeleteWebhook as DeleteWebhook };
