/* eslint-disable react/prop-types */

import clsx from 'clsx';
import React, { useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Message } from '@oneflowab/pomes';

import style from './text-area.module.scss';

const TextAreaInput = ({
  disabled,
  onChange,
  onBlur,
  placeholder,
  value,
  required,
  invalid,
}) => {
  const textAreaRef = useRef(null);

  const onChangeHandler = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div
      className={clsx(style.InputContainer, {
        [style.Invalid]: invalid,
      })}
    >
      <TextareaAutosize
        className={style.TextArea}
        disabled={disabled}
        name="textarea"
        onChange={onChangeHandler}
        onBlur={onBlur}
        placeholder={placeholder}
        ref={textAreaRef}
        value={value}
        required={required}
        data-value={value}
      />
      {invalid && (
        <div className={style.ValidationMessage}>
          <Message id="This field is required" comment="Input field validation message." />
        </div>
      )}
    </div>
  );
};

export default TextAreaInput;
