import React from 'react';
import { Message, localize } from '@oneflowab/pomes';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import type { MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';
import positionsReducer from 'reducers/entities/positions';

import { checkAcl } from 'components/acl';
import { getErrorMessage } from 'components/api-error';
import Button from 'components/button';
import PasswordLock from 'components/icons/password-lock';
import {
  PasswordNewField,
  PasswordConfirmationField,
  PasswordVerificationField,
} from 'components/fields';
// eslint-disable-next-line import/named
import { duplicateValidator, passwordConfirmation } from 'forms/validators';
import { ForgetPassword } from 'components/forget-password/forget-password-container';
import type { FormData, UpdatePasswordParams } from './types';

export type Props = {
  position: Oneflow.Position,
  updateState: ReturnType<typeof positionsReducer.getUpdateSelector>,
  updatePassword: ({ positionId, password, passwordVerification }: UpdatePasswordParams) => void,
  resetUpdateState: (id: number) => void,
  formActions: {
    start: string,
    success: string,
    fail: string,
  },
  sendData: (data: FormData) => void,
};

type LocalizedProps = Props & {
  message: MessageTranslator,
}

export class ChangePasswordModalComponent extends React.Component<LocalizedProps> {
  handleFormValidation = (formData: FormData) => {
    const { message } = this.props;

    const duplicateValidationErrors = duplicateValidator({
      allValues: formData,
      fieldKey: 'password',
      otherFieldKey: 'passwordVerification',
      text: message({
        id: 'The new password is not allowed to be the same as the previous one',
        comment: 'Validation message for not allowing the same password on the user password page.',
      }),
    });

    if (!isEmpty(duplicateValidationErrors)) {
      return duplicateValidationErrors;
    }

    return passwordConfirmation(message)(formData);
  };

  handleSubmit = (formData: FormData) => {
    const { updatePassword, position } = this.props;

    updatePassword({
      positionId: position.id,
      password: formData.password,
      passwordVerification: formData.passwordVerification,
    });
  };

  handleUpdateReset = () => {
    const { resetUpdateState, position } = this.props;

    resetUpdateState(position.id);
  };

  getChildren = (onClick: () => void) => {
    const { position } = this.props;

    return (
      <Button
        icon={PasswordLock}
        kind="secondary"
        onClick={onClick}
        data-testid="change-password"
        disabled={!checkAcl(position.acl, 'position:update:password')}
      >
        <Message
          id="Change password"
          comment="Button text for the change password modal."
        />
      </Button>
    );
  };

  parseSubmitError = (error: any) => {
    const errorCode = get(error, 'body.api_error_code');
    const wrongPasswordErrorCode = 1013;
    const errorMessage = getErrorMessage(wrongPasswordErrorCode);
    if (errorMessage && errorCode === wrongPasswordErrorCode) {
      return {
        passwordVerification: errorMessage.headerText,
      };
    }

    return null;
  }

  render() {
    const { updateState, formActions, sendData } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Change password"
            comment="Modal title for changing password."
          />
        )}
        body={(
          <div>
            <PasswordNewField autoFocus />
            <PasswordConfirmationField />
            <PasswordVerificationField />
            <ForgetPassword />
          </div>
        )}
        resetFormState={this.handleUpdateReset}
        formState={updateState}
        validate={this.handleFormValidation}
        onOpen={this.handleUpdateReset}
        formActions={formActions}
        prepareFormData={sendData}
        parseSubmitError={this.parseSubmitError}
        modalKey="profile change password modal"
      >
        {this.getChildren}
      </ModalForm>
    );
  }
}

export default localize<LocalizedProps>(ChangePasswordModalComponent);
