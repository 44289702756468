// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Confirmable from 'components/confirmable';
import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';

export type Props = {
  updateState: RpcState,
  accountId: number,
  resetUpdateState: (id: number) => void,
  startTrial: (id: number) => void,
  children: (onClick: () => void) => React.Node,
}

export class StartTrial extends React.PureComponent<Props> {
  getIcon = () => {
    const { updateState } = this.props;

    if (!updateState.loading) {
      return null;
    }

    return <CircularSpinner />;
  }

  getActions = () => {
    const { updateState } = this.props;

    return (
      <Button
        color="peace"
        onClick={this.handleConfirm}
        disabled={updateState.loading || updateState.error}
        icon={this.getIcon()}
      >
        <Message
          id="Start trial"
          comment="Button to start free 14-day trial."
        />
      </Button>
    );
  };

  handleResetUpdateState = () => {
    const { resetUpdateState, accountId } = this.props;

    resetUpdateState(accountId);
  };

  handleConfirm = () => {
    const { updateState, startTrial, accountId } = this.props;

    if (updateState.loading) {
      return;
    }

    startTrial(accountId);
  };

  renderBody = () => (
    <>
      <p>
        <Message
          id="Don't miss a trick. Access all features in Oneflow and automate your entire process with smart contracts now!"
          comment="Explanatory text before a user confirms starting their free trial."
        />
      </p>
      <p>
        <Message
          id="Experience true contract magic by activating your free 14-day trial."
          comment="Explanatory text before a user confirms starting their free trial."
        />
      </p>
    </>
  );

  render() {
    const { updateState, children } = this.props;

    return (
      <Confirmable
        header={(
          <Message
            id="Start a free 14-day trial"
            comment="Modal title when starting a free 14-day trial."
          />
        )}
        body={this.renderBody()}
        actions={this.getActions}
        error={updateState.error}
        success={updateState.success}
        onEnter={this.handleConfirm}
        onOpen={this.handleResetUpdateState}
      >
        {children}
      </Confirmable>
    );
  }
}
