import * as React from 'react';
import ReactModal from 'react-modal';
import clsx from 'clsx';
import type { Props as ModalProps } from 'react-modal';
import type { ComponentProps, ReactElement, ReactNode } from 'react';

import partial from 'lodash/partial';
import merge from 'lodash/merge';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

import style from './modal.module.scss';

const CLICK_KEYBOARD_ESCAPE = 'click keyboard-escape';
const CLICK_OUTSIDE = 'click outside';
type MultistepModalContextType = {
  currentIndex?: number,
  isDone?: boolean,
  loaded: boolean,
};

export const MultistepModalContext = React.createContext<MultistepModalContextType>({
  loaded: false,
});

export type ModalStepRenderProps = (props: {
  onPreviousStep: () => void,
  onStepComplete: () => void,
  onSyncStepData: (stepData: any) => void,
  modalData: any,
}) => ReactNode;

export type ModalStepProps = {
  children: ModalStepRenderProps,
  modalKey?: string,
};

// eslint-disable-next-line no-unused-vars
export const ModalStep = ({ children, modalKey }: ModalStepProps) => null;

export type Props = ModalProps & {
  children: ReactElement<typeof ModalStep>[],
  modalKey?: string,
  customModalClass?: string,
  onCancel?: () => void,
  isWideModal?: boolean,
  isMediumWideModal?: boolean,
  isExpansiveModal?: boolean,
  preventClose?: boolean,
  onOpen?: () => void,
  isOpen?: boolean,
};

type State = {
  currentIndex: number,
  isDone?: boolean,
  modalData: any,
};

export default class MultistepModal extends React.Component<Props, State> {
  static defaultProps = {
    onCancel: undefined,
    isWideModal: undefined,
    isMediumWideModal: undefined,
    isExpansiveModal: undefined,
    isOpen: undefined,
    preventClose: undefined,
    onOpen: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      currentIndex: 0,
      isDone: false,
      modalData: {},
    };
  }

  componentDidMount() {
    const { isOpen, onOpen } = this.props;

    if (isOpen && onOpen) {
      onOpen();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isOpen, onOpen } = this.props;

    if (!prevProps.isOpen && isOpen && onOpen) {
      onOpen();
    }
  }

  getModalStepsLength() {
    const { children } = this.props;

    return React.Children.toArray(children)
      .filter((step) => step.type === ModalStep)
      .length;
  }

  handleOnStepComplete = (stepIndex: number) => {
    const modalStepsLength = this.getModalStepsLength();

    if (stepIndex >= modalStepsLength - 1) {
      this.setState({
        isDone: true,
      });
    }

    this.setState({
      currentIndex: stepIndex + 1,
    });
  }

  handleOnPreviousStep = (stepIndex: number) => {
    if (stepIndex <= 0) {
      return;
    }
    this.setState({
      currentIndex: stepIndex - 1,
    });
  }

  handleOnSyncStepData = (stepIndex: number, stepData: any) => {
    const { modalData } = this.state;

    this.setState({
      modalData: merge({}, modalData, stepData),
    });
  };

  onExitModal = (exitType: string) => {
    const { onCancel, children } = this.props;
    const { currentIndex } = this.state;
    const { modalKey } = children[currentIndex].props;

    if (onCancel) {
      onCancel();
    }

    amplitudeLogEvent(
      'Exit Modal',
      {
        location: modalKey,
        'exit action': exitType || CLICK_OUTSIDE,
      },
    );
  };

  handleCancel: ModalProps['onRequestClose'] = (event) => {
    if ((event as React.KeyboardEvent).code === 'Escape') {
      this.onExitModal(CLICK_KEYBOARD_ESCAPE);
    } else {
      this.onExitModal(CLICK_OUTSIDE);
    }
  };

  renderModalStep() {
    const { children } = this.props;
    const { currentIndex, modalData } = this.state;

    const modalStep = React.Children
      .toArray(children)
      .find((step, index) => (
        step.type === ModalStep && index === currentIndex
      )) as ReactElement<ComponentProps<typeof ModalStep>, typeof ModalStep>;

    if (!modalStep) {
      return null;
    }

    return modalStep.props.children({
      onPreviousStep: partial(this.handleOnPreviousStep, currentIndex),
      onStepComplete: partial(this.handleOnStepComplete, currentIndex),
      onSyncStepData: partial(this.handleOnSyncStepData, currentIndex),
      modalData,
    });
  }

  render() {
    const { currentIndex, isDone } = this.state;
    const {
      children,
      onCancel,
      isWideModal,
      isMediumWideModal,
      isExpansiveModal,
      isOpen,
      preventClose,
      customModalClass,
      ...modalProps
    } = this.props;

    if (isDone) {
      return null;
    }

    if (currentIndex === 0 && !isOpen) {
      return null;
    }

    const modalClasses = clsx(style.Modal, {
      [style.IsWide]: isWideModal,
      [style.IsMediumWide]: isMediumWideModal,
      [style.IsExpansive]: isExpansiveModal,
    }, customModalClass);

    return (
      <ReactModal
        className={modalClasses}
        overlayClassName={style.ModalOverlay}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={!preventClose}
        shouldCloseOnEsc={!preventClose}
        {...modalProps}
        onRequestClose={this.handleCancel}
        isOpen={isOpen}
        bodyOpenClassName={style.Open}
      >
        <MultistepModalContext.Provider
          value={{
            loaded: true,
          }}
        >
          {this.renderModalStep()}
        </MultistepModalContext.Provider>
      </ReactModal>
    );
  }
}
