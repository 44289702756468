import type { MouseEventHandler } from 'react';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import DeleteIcon from 'components/icons/delete';
import Tooltip from 'components/tooltip';

import style from './delete-button.module.scss';

interface DeleteButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const DeleteButton = ({ onClick }: DeleteButtonProps) => (
  <Tooltip
    message={(
      <Message
        id="Delete"
        comment="Tooltip message for duplicate button."
      />
    )}
    side="top"
    sideOffset={10}
  >
    <div>
      <Button
        type="button"
        data-overlay-field-popover-cta
        onClick={onClick}
        customClass={style.DeleteOverlayField}
        icon={(<DeleteIcon />)}
        tabIndex={0}
      />
    </div>
  </Tooltip>
);

export default DeleteButton;
