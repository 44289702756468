// @flow

import React from 'react';

import extensionSettings from 'hocs/extension-settings';

import GeneratedToken from 'components/extension/generated-token';

type Props = {
  extension: Extension,
};

export const DemoPage = ({ extension }: Props) => (
  <>
    <GeneratedToken extension={extension} />
  </>
);

export default extensionSettings(DemoPage);
