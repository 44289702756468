// @flow

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { selectWorkspace, getCurrentWorkspaceSelector } from 'reducers/app';
import {
  getAllWorkspacesFromSessionSelector,
} from 'reducers/session';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import {
  isUserOnContractPage, isUserOnTemplatePage, isUserOnGlobalSearchPage, isUserOnAdminPage,
} from 'utils/isOnPage';
import { withWorkspaceNamespace } from 'hocs/with-current-workspace/with-workspace-namespace';

import WorkspaceDropdown from './workspace-dropdown';

export const mapStateToProps = (state: State) => ({
  currentWorkspace: getCurrentWorkspaceSelector(state),
  workspaces: getAllWorkspacesFromSessionSelector(state),
});

export const mapDispatchToProps = (dispatch: any) => ({
  changeCurrentWorkspace: (workspaceId: number, location: string) => {
    dispatch(selectWorkspace({
      workspaceId,
      pipe: {
        onSuccess: amplitudeLogEvent('Switch Workspace', { location }),
      },
    }));

    // Logic handles when changing the workspace when in contract/template view
    // The saga navigateToWorkspace, is called when opening a contract which means that
    // if this logic is apart of the saga, it will directly redirect back to contract list
    const { pathname } = window.location;

    const isOnContractPage = isUserOnContractPage(pathname);
    const isOnContractsPage = window.location.pathname.includes('/contracts/all');
    const hasSearchQuery = window.location.search !== '';
    const isOnTemplatePage = isUserOnTemplatePage(pathname);
    const workspaceLocation = withWorkspaceNamespace({ id: workspaceId });

    const isOnGlobalSearchPage = isUserOnGlobalSearchPage(pathname);
    const isOnAdminPage = isUserOnAdminPage(pathname);

    if (isOnGlobalSearchPage || isOnAdminPage) {
      window.location.assign(`/c/${workspaceId}/contracts/all`);
    }
    if (isOnContractsPage && hasSearchQuery) {
      dispatch(push(`/c/${workspaceId}/contracts/all`));
    }

    if (isOnContractPage || isOnTemplatePage) {
      const link = workspaceLocation('/contracts');
      window.location.assign(link);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceDropdown);
