// @flow

import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getLocationSelector } from 'reducers/router';
import { getRemoveRedirectPath } from 'workspace/folders';
import foldersReducer from 'reducers/entities/folders';

import { checkAcl } from 'components/acl';
import toast from 'components/toasts';
import ActionsMenu from 'components/actions-menu';
import { AddFolderMenuItem } from 'components/menu-items/add-folder';
import { DeleteFolder } from 'components/modals/delete-folder';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { RenameMenuItem } from 'components/menu-items/rename';
import Tooltip from 'components/tooltip';

import style from './folder-actions.module.scss';

const FOLDERS_QUERY = 'folders';

export type Props = {
  acl: Acl,
  didClickRename: () => void,
  folderId: number,
  folderName: string,
  onFolderAddHandler: (number) => void,
  showAddFolderAction?: boolean,
  allFolders: [],
  actionMenuRef?: any,
};

const FolderActions = ({
  acl,
  didClickRename,
  folderId,
  folderName,
  onFolderAddHandler,
  showAddFolderAction,
  allFolders,
  actionMenuRef,
}: Props) => {
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
  const currentWorkspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  const folderAddPermission = checkAcl(acl, 'folder:sub_folder:create');
  const folderDeletePermission = checkAcl(acl, 'folder:remove');
  const folderUpdatePermission = checkAcl(acl, 'folder:update');

  const removeState = useSelector((state) => (
    foldersReducer.getRemoveSelector(state, { id: folderId })
  ));
  const workspaceCreatePermission = checkAcl(currentWorkspace.acl, 'collection:folder:create');

  const dispatch = useDispatch();
  const location = useSelector((state) => getLocationSelector(state));

  const deleteFolder = () => dispatch(foldersReducer.removeFolder({
    id: folderId,
    pipe: {
      onSuccess: () => {
        dispatch(foldersReducer.queryFoldersReload({
          name: FOLDERS_QUERY,
        }));
        dispatch(push(getRemoveRedirectPath(location, folderId)));
      },
      onFailure: () => {
        toast.error({
          id: 'folder-delete-error',
          title: (
            <Message
              id="Could not delete folder"
              comment="Title for toast when deleting a folder"
            />
          ),
          description: (
            <Message
              id="Make sure that the folder is empty before you delete it."
              comment="Message for toast when deleting a folder"
            />
          ),
        });
      },
    },
  }));
  const resetRemoveState = () => dispatch(foldersReducer.removeFolderReset({ id: folderId }));

  const getDeleteMenuItem = () => (
    <DeleteFolder
      folderId={folderId}
      deleteFolder={deleteFolder}
      name={folderName}
      removeState={removeState}
      resetRemoveState={resetRemoveState}
    >
      {(onClick) => (
        <DeleteMenuItem
          disabled={!folderDeletePermission}
          onClick={onClick}
        />
      )}
    </DeleteFolder>
  );

  const handleAddFolderClick = () => {
    onFolderAddHandler(folderId);
  };

  const handleKeyDown = () => undefined;

  const onVisibilityChange = (visible: boolean) => {
    setIsActionMenuOpen(visible);
  };

  const getRenameMenuItem = () => <RenameMenuItem onClick={didClickRename} />;

  const maximumFolderLimit = allFolders?.length === 100;

  const disabledAddFolderMessage = () => {
    if (maximumFolderLimit) {
      return (
        <Message
          id="Your workspace has already reached the maximum of {maxFolders} allowed folders."
          comment="Tooltip for when the add subfolder button is disabled"
          values={{ maxFolders: 500 }}
        />
      );
    }

    return (
      <Message
        id="Folders support a maximum of {maxNestedFolders} nested levels of subfolders."
        comment="Tooltip for when the add subfolder button is disabled"
        values={{ maxNestedFolders: 2 }}
      />
    );
  };

  const getCreateMenuItem = () => {
    if (!workspaceCreatePermission || !folderAddPermission) {
      return (
        <Tooltip
          message={(
            disabledAddFolderMessage()
          )}
          side="top"
          theme="oneflow"
        >
          <AddFolderMenuItem
            onClick={handleAddFolderClick}
            disabled={!workspaceCreatePermission || !folderAddPermission}
          />
        </Tooltip>
      );
    }
    return (
      <AddFolderMenuItem
        onClick={handleAddFolderClick}
        disabled={!workspaceCreatePermission || !folderAddPermission}
      />
    );
  };

  const getActions = () => {
    if (showAddFolderAction) {
      return ([
        getCreateMenuItem(),
        getRenameMenuItem(),
        getDeleteMenuItem(),
      ]);
    }

    return ([
      getRenameMenuItem(),
      getDeleteMenuItem(),
    ]);
  };

  if (
    !workspaceCreatePermission
    && !folderUpdatePermission
    && !folderDeletePermission
  ) {
    return null;
  }

  return (
    <div
      className={!isActionMenuOpen ? style.Hide : style.Show}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      ref={actionMenuRef}
    >
      <ActionsMenu
        actions={getActions()}
        customClassName={style.ActionsMenu}
        onVisibilityChange={onVisibilityChange}
      />
    </div>
  );
};

export default FolderActions;
