/* eslint-disable react/prop-types */

import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Message } from '@oneflowab/pomes';

import useCurrentDateFormat from 'hooks/use-current-date-format';

import Select from 'components/select';
import { SelectItem } from 'components/select-field-radix';
import Description from 'components/contract-boxes/form-box/components/description';
import Edit from 'components/icons/edit';
import Calendar from 'components/icons/calendar';
import TextField from 'components/text-field';
import Label from 'components/contract-boxes/form-box/components/label';
import PropagationBoundary from 'components/propagation-boundary';
import ReadOnly from 'components/contract-boxes/form-box/components/read-only';
import { DayPickerSingleDateController } from 'components/date-picker';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/popover';
import { useBoxItemIsVisible } from 'components/box-list/box-item/box-item-is-visible-context';

import { useFormFieldContext } from '../form-field-context';
import useControlledEscapedInput from '../use-controlled-escaped-input';
import style from './date-field.module.scss';
import { useFieldValidationContext } from '../field-validation-provider';

const FormDateField = ({
  description,
  inputSize,
  isActive,
  isAllowedToUpdateDataValue,
  isAllowedToUpdateSharedDataValue,
  label,
  placeholder,
  required,
}) => {
  const {
    fieldValue,
    onFieldValueChange,
    hasDataField,
  } = useFormFieldContext();
  const { touched, setTouched } = useFieldValidationContext();
  const {
    displayValue,
    onChange,
  } = useControlledEscapedInput(fieldValue, onFieldValueChange);
  const { isVisible } = useBoxItemIsVisible();

  const currentDateFormat = useCurrentDateFormat();
  const [isOpen, setIsOpen] = useState(false);
  const selectedDate = useMemo(() => {
    const dateObject = moment(displayValue, currentDateFormat);
    if (!dateObject.isValid()) {
      return null;
    }

    return dateObject;
  }, [currentDateFormat, displayValue]);

  const handleDateChange = useCallback((newSelectedDate) => {
    onFieldValueChange(newSelectedDate.format(currentDateFormat));
    setIsOpen(false);
  }, [currentDateFormat, onFieldValueChange]);

  const onOpenChange = useCallback((_isOpen) => {
    if (!_isOpen) {
      setTouched(true);
    }
  }, [setTouched]);

  const onBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const fieldContainerClasses = clsx(style.FieldContainer, {
    [style.Active]: isActive,
    [style.DataField]: hasDataField,
    [style.Uneditable]: !isAllowedToUpdateDataValue,
  });

  const invalid = (
    required
    && isAllowedToUpdateSharedDataValue
    && !fieldValue
    && touched
    && isVisible
  );

  const renderMonthElement = useCallback(({
    month,
    onMonthSelect,
    onYearSelect,
  }) => {
    const renderMonths = () => moment.months().map((labl, v) => (
      <SelectItem
        key={labl}
        value={v}
      >
        {labl}
      </SelectItem>
    ));

    const renderYears = () => {
      const years = [];
      for (let yearValue = 1900; yearValue <= moment().add(100, 'y').year(); yearValue += 1) {
        years.push(
          <SelectItem
            key={yearValue}
            value={yearValue}
          >
            {yearValue}
          </SelectItem>,
        );
      }
      return years;
    };

    return (
      <div className={style.SelectorContainer}>
        <Select
          value={month.month()}
          label={moment().month(month.month()).format('MMMM')}
          onValueChange={(monthValue) => onMonthSelect(month, monthValue)}
          selectClassName={clsx(style.Select, style.MonthSelect)}
        >
          {renderMonths()}
        </Select>
        <Select
          value={month.year()}
          label={month.year()}
          onValueChange={(yearValue) => onYearSelect(month, yearValue)}
          selectClassName={style.Select}
        >
          {renderYears()}
        </Select>
      </div>
    );
  }, []);

  return (
    <div className={fieldContainerClasses}>
      <div className={style.Wrapper}>
        <Label
          hasDataField={hasDataField}
          isAllowedToUpdateSharedDataValue={isAllowedToUpdateSharedDataValue}
          label={label}
          required={required}
        />
        <div className={style.Edit}>
          <Edit height="14px" />
        </div>
      </div>
      {isAllowedToUpdateSharedDataValue ? (
        <div className={inputSize}>
          <PropagationBoundary>
            <Popover open={isOpen} onOpenChange={onOpenChange}>
              <PopoverTrigger asChild>
                <div
                  role="button"
                  className={style.PickedDate}
                  onClick={() => setIsOpen(true)}
                  aria-hidden
                >
                  <TextField
                    input={{
                      onChange,
                      value: displayValue,
                      onBlur,
                    }}
                    placeholder={placeholder}
                    icon={<Calendar height="10px" />}
                    staticIconSize
                    clearable
                    readOnly
                    customClass={clsx(style.InputField, {
                      [style.Invalid]: invalid,
                    })}
                    noErrorMessage
                    required={required}
                  />
                  {invalid && (
                    <div className={style.ValidationMessage}>
                      <Message id="This field is required" comment="Input field validation message." />
                    </div>
                  )}
                </div>
              </PopoverTrigger>
              <PopoverContent
                onEscapeKeyDown={() => setIsOpen(false)}
                onInteractOutside={() => setIsOpen(false)}
                className={style.PopoverContent}
              >
                <DayPickerSingleDateController
                  numberOfMonths={1}
                  onDateChange={handleDateChange}
                  focused={isOpen}
                  date={selectedDate}
                  isOutsideRange={() => false}
                  hideKeyboardShortcutsPanel
                  renderMonthElement={renderMonthElement}
                />
              </PopoverContent>
            </Popover>
          </PropagationBoundary>
        </div>
      ) : (
        <ReadOnly value={displayValue} />
      )}
      <Description description={description} />
    </div>
  );
};
export default FormDateField;
