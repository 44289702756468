// @flow

import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { updateBoxAction } from 'reducers/current-contract';
import useCurrentContractId from 'hooks/use-current-contract-id';

import { useVideoBoxProps } from 'contexts/video-box-props';
import TextEditor from 'components/contract-text-editor/text-editor';
import {
  getTextData,
  isVideo,
} from 'components/contract-boxes/video-box/video-box-helpers';
import { disabledPlugins } from '../constants';

import style from './title-editable.module.scss';

type Props = {
  isEditable: boolean,
};

export const VideoTitleEditable = ({ isEditable }: Props) => {
  const { box } = useVideoBoxProps();
  const contractId = useCurrentContractId();
  const data = get(box, 'content.data');
  const dispatch = useDispatch();
  const showTitle = get(box, 'config.title');
  const textData = getTextData(data);
  const textNodes = textData?.value?.nodes;
  const width = get(box, 'config.width');

  const getOtherData = () => {
    const previousData = box.content.data.filter((d) => isVideo(d) || d._removed);
    return previousData;
  };

  const onNodesChange = (nodes) => {
    const previousData = getOtherData();
    const updatedData = [
      ...previousData,
      {
        ...textData,
        value: { nodes },
      },
    ];
    dispatch(updateBoxAction({
      ...box,
      content: {
        ...box.content,
        data: updatedData,
      },
    }));
  };

  if (!showTitle || !textData) {
    return null;
  }

  const textEditorClasses = clsx(style.TextEditor, {
    [style.Small]: width === 'sm',
    [style.FullWidth]: width === 'full',
  });

  return (
    <div className={textEditorClasses}>
      <TextEditor
        contractId={contractId}
        legacyMarkup=""
        nodes={textNodes}
        onNodesChange={debounce((nodes) => {
          onNodesChange(nodes);
        }, 200)}
        placeholder="Add video title..."
        pluginsToDisable={disabledPlugins}
        readOnly={!isEditable}
      />
    </div>
  );
};
