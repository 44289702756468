import {
  createContext,
  useContext,
  useMemo,
  useCallback,
  ReactNode,
} from 'react';
import { useDispatch } from 'react-redux';

import { updateBoxAction, updateDataFieldAction } from 'reducers/current-contract';
import type { DataField } from 'data-validators/entity-schemas/agreement-data';
import type { Box } from 'data-validators/entity-schemas/document-box';

import generateOverlayField from 'components/contract-boxes/pdf-box/overlay-field-helpers';
import { generateSignatureField } from 'components/document-tabs/content-tab/helpers';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import { DATA_FIELD, SIGNATURE_FIELD } from 'components/contract-boxes/constants';

type FieldType = typeof DATA_FIELD | typeof SIGNATURE_FIELD;

type PdfBoxProps = {
  agreementId: number,
  boxId: number,
  onDataFieldDrop: (
    pageNumber: number,
    dataFieldKey: string,
    position: unknown,
    size: unknown,
    type: FieldType,
  ) => void,
  updateDataFieldObject: (
    dataFieldObject: DataField,
    value: string,
  ) => void;
};

type Props = {
  agreementId: number,
  box: Box,
  boxId: number,
  children: ReactNode,
};

type OnDataFieldDrop = (
  pageNumber: number,
  dataFieldKey: string,
  position: {
    x: number,
    y: number,
  },
  size: {
    width: number,
    height: number,
  },
  type: FieldType,
) => void;

export const PdfBoxPropsContext = createContext<PdfBoxProps | null>(null);

export function PdfBoxPropsProvider({
  agreementId,
  box,
  boxId,
  children,
}: Props) {
  const dispatch = useDispatch();

  const updateDataFieldObject = useCallback((
    dataFieldObject: DataField,
    value: string,
  ) => {
    if (!dataFieldObject) {
      // eslint-disable-next-line no-console
      console.warn('Tried to update a non-existing dataFieldObject');
      return;
    }

    const updatedDataFieldObject = {
      ...dataFieldObject,
      value: {
        ...dataFieldObject.value,
        value,
      },
    };
    dispatch(updateDataFieldAction(updatedDataFieldObject));
  }, [dispatch]);

  const onDataFieldDrop = useCallback<OnDataFieldDrop>((
    pageNumber,
    dataFieldKey,
    position,
    size,
    type,
  ) => {
    if (type === DATA_FIELD) {
      const { updatedBox } = generateOverlayField(
        box,
        dataFieldKey,
        size,
        pageNumber - 1,
        position,
      );
      dispatch(updateBoxAction(updatedBox));
      amplitudeLogEvent(
        'Add Overlay Field',
        {
          'field type': 'data',
        },
      );
    } else if (type === SIGNATURE_FIELD) {
      const { updatedBox } = generateSignatureField(
        box,
        size,
        pageNumber - 1,
        position,
      );
      dispatch(updateBoxAction(updatedBox));
      amplitudeLogEvent(
        'Add Overlay Field',
        {
          'field type': 'signature',
        },
      );
    }
  }, [
    box,
    dispatch,
  ]);

  const contextValue = useMemo(
    () => ({
      agreementId,
      boxId,
      onDataFieldDrop,
      updateDataFieldObject,
    }), [
      agreementId,
      boxId,
      onDataFieldDrop,
      updateDataFieldObject,
    ],
  );

  return (
    <PdfBoxPropsContext.Provider value={contextValue}>
      {children}
    </PdfBoxPropsContext.Provider>
  );
}

export const usePdfBoxProps = (): PdfBoxProps => {
  const contextValue = useContext(PdfBoxPropsContext);

  if (!contextValue) {
    throw new Error('usePdfBoxProps should be used inside a PdfBoxPropsContext');
  }

  return contextValue;
};
