/* eslint-disable camelcase */
import React from 'react';
import {
  cloneDeep,
  uniqueId,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import type {
  Config,
  ConfigUpdateData,
  Product,
  ProductTableBox,
  ProductValue,
} from 'data-validators/entity-schemas/document-box/product-table';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import { PRODUCT_PERCENTAGE_DISCOUNT } from 'components/contract-boxes/product-table-box/constants';

export const defaultProductValues = {
  count_type: 2,
  count: null,
  description: '',
  price_1: null,
  price_1_discount_amount: 0,
  price_1_discount_type: null,
  price_1_discounted: 0.0,
  price_2: null,
  price_2_discount_amount: 0,
  price_2_discount_type: null,
  price_2_discounted: 0.0,
  read_only: 0,
  suffix: '', // Seems unused and always empty in the response too
};

// temporary until we add more helpers
// eslint-disable-next-line
export const produceProductData = (box: ProductTableBox, defaultProductName: string | React.ReactNode) => {
  const {
    count,
    count_type,
    description,
    price_1,
    price_1_discount_amount,
    price_1_discount_type,
    price_1_discounted,
    price_2,
    price_2_discount_amount,
    price_2_discount_type,
    price_2_discounted,
    suffix,
    read_only,
  } = defaultProductValues;

  const newDataItem = {
    key: 'product',
    value: {
      count,
      count_type,
      description,
      name: defaultProductName,
      price_1,
      price_1_discount_amount,
      price_1_discount_type,
      price_1_discounted,
      price_2,
      price_2_discount_amount,
      price_2_discount_type,
      price_2_discounted,
      read_only,
      suffix,
      id: uuidv4(),
    },
    _id: Number(uniqueId()),
  } as Product;

  const updatedBox = cloneDeep(box);

  updatedBox.content = {
    ...updatedBox.content,
    data: [
      ...updatedBox.content.data,
      newDataItem,
    ],
  };

  updatedBox.config = {
    ...updatedBox.config,
    order: [
      ...updatedBox.config.order,
      { _id: newDataItem._id },
    ],
  };

  return {
    updatedBox,
    newDataItem,
  };
};

export const produceUpdatedProductData = (
  data: Product[],
  productId: number,
  updatedValue: Partial<ProductValue>,
) => {
  const product = data.find((dataItem) => getId(dataItem) === productId);
  const newValue = {
    value: {
      ...product?.value,
      ...updatedValue,
    },
  };

  return newValue;
};

export const produceContentDataForRemovedItem = (
  data: Product[],
  productId: number,
) => {
  const contentData = data.reduce((contentDataList, datum) => {
    // Do not add the entry of product item if it is newly added and not saved
    if (datum?._id === productId) {
      return contentDataList;
    }

    let updatedDatum = datum;

    // Update removal with flag for existing stored product item
    if (datum?.id === productId) {
      updatedDatum = {
        ...datum,
        _removed: 1,
      };
    }

    // Add rest of the product items as it is
    contentDataList.push(updatedDatum);

    return contentDataList;
  }, [] as Product[]);

  return contentData;
};

export const produceUpdatedConfig = (config: Config, configData: ConfigUpdateData) => {
  const newConfig = {
    ...config,
    ...configData,
  };
  if (configData?.column) {
    const updatedColumn = config.columns.map((column) => {
      if (column.key === configData.column?.key) {
        return { ...column, ...configData.column };
      }
      return column;
    });
    newConfig.columns = updatedColumn;
    delete newConfig.column;
  }
  return newConfig;
};

export const hasZeroPriceWithPercentageDiscount = (
  discountAmount: number | undefined,
  price: number,
  discountType: number | undefined,
): boolean => {
  const hasDiscount = Boolean(discountAmount);
  return Number(price) === 0 && hasDiscount && discountType === PRODUCT_PERCENTAGE_DISCOUNT;
};

export const isBasePriceVisible = (
  price: number | null,
  priceDiscounted: number,
): boolean => {
  const priceDiscountedIsNegative = priceDiscounted < 0;
  return price !== null && price >= 0 && !priceDiscountedIsNegative;
};
