// @flow

import React, { useRef, useMemo } from 'react';

import useSelectedRectangle from 'components/rich-text-editor/hooks/use-selected-rectangle';
import useCursorPosition from 'components/rich-text-editor/hooks/use-cursor-position';

export const RichTextContext = React.createContext<any>();

type Props = {|
  children: React.Node,
  isReadOnly: boolean,
|};

export const RichTextProvider = ({ children, isReadOnly }: Props) => {
  const editorRef = useRef(null);
  const selectedRectangleAnchorRef = useRef(null);

  const {
    resetEditorSelectedRangeInfo,
    editorSelection,
    selectedRectangle,
  } = useSelectedRectangle();

  const {
    onMouseDown,
    cursorPosition,
    resetCursorPosition,
  } = useCursorPosition();

  const contextValue = useMemo(() => ({
    onMouseDown,
    resetEditorSelectedRangeInfo,
    resetCursorPosition,
    editorSelection,
    selectedRectangle,
    isReadOnly,
    editorRef,
    selectedRectangleAnchorRef,
    cursorPosition,
  }), [
    onMouseDown,
    resetEditorSelectedRangeInfo,
    resetCursorPosition,
    editorSelection,
    selectedRectangle,
    isReadOnly,
    editorRef,
    cursorPosition,
  ]);

  return (
    <RichTextContext.Provider value={contextValue}>
      {children}
    </RichTextContext.Provider>
  );
};

export const useRichTextProps = () => {
  const contextValue = React.useContext(RichTextContext);

  if (!contextValue) {
    throw new Error('useRichTextProps should be used inside a RichTextProvider');
  }

  return contextValue;
};
