// @flow

import { connect } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';

import TemplateSharingIndicator from './template-sharing-indicator';

export const mapStateToProps = (state: State) => {
  const currentWorkspace = getCurrentWorkspaceSelector(state);

  return {
    currentWorkspace,
  };
};

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSharingIndicator);
