/* eslint-disable import/named */
import clsx from 'clsx';

import { useRichTextProps } from 'contexts/rich-text';
import { useContractProps } from 'contexts/contract-props';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';

import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverAnchor,
} from 'components/popover';

import LinkEditForm from '../link-edit-form/link-edit-form';

import style from './add-link-popover.module.scss';

const AddLinkPopover = () => {
  const {
    cursorPosition,
    selectedRectangle,
    resetEditorSelectedRangeInfo,
    resetCursorPosition,
    selectedRectangleAnchorRef,
  } = useRichTextProps();
  const { documentScrollContainerRef } = useDocumentLayout();
  const selectedLocation = selectedRectangle || cursorPosition;
  const sideOffset = selectedLocation ? (selectedLocation?.height / 2) + 4 : undefined;
  const {
    isLinkPopoverOpen,
    setLinkPopoverOpen,
  } = useContractProps();

  const resetSelection = () => {
    if (selectedRectangle) {
      resetEditorSelectedRangeInfo();
    } else {
      resetCursorPosition();
    }
  };

  const onPopoverClose = () => {
    setLinkPopoverOpen(false);
    resetSelection();
  };

  return (
    <Popover open={isLinkPopoverOpen}>
      <PopoverAnchor virtualRef={selectedRectangleAnchorRef} />
      <PopoverPortal
        container={documentScrollContainerRef.current}
      >
        <div className={style.PopoverContainer}>
          <PopoverContent
            sideOffset={sideOffset}
            onEscapeKeyDown={onPopoverClose}
            onInteractOutside={onPopoverClose}
            className={clsx(style.Popover, 'rich-text-region')}
            collisionBoundary={document.getElementById('agreement-body')}
            hideWhenDetached
            arrowHeight={0}
          >
            <LinkEditForm
              canChangeLinkType
              onClose={onPopoverClose}
            />
          </PopoverContent>
        </div>
      </PopoverPortal>
    </Popover>
  );
};

export default AddLinkPopover;
