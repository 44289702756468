// @flow

const createMediaStream = (tracks?: Array<MediaStreamTrack>) => {
  if (typeof MediaStream === 'undefined') {
    throw new Error('Unsupported browser');
  }

  return new MediaStream(tracks);
};

export default createMediaStream;
