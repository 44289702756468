// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import workspaceMessageTemplateReducer from 'reducers/entities/workspace-message-templates';

import MessageTemplates, { type Props } from './message-templates';

const QUERY_NAME = 'templates/messages';

type StateProps = {|
  messageTemplates: Array<AgreementTemplate>,
  messageTemplatesQuery: Query,
  workspace: Workspace,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const workspace = getCurrentWorkspaceSelector(state);

  const query = workspaceMessageTemplateReducer.getQuerySelector(state, { name: QUERY_NAME });

  return {
    messageTemplates: workspaceMessageTemplateReducer.getWorkspaceMessageTemplatesSelector(state, {
      ids: query.result,
    }),
    messageTemplatesQuery: query,
    workspace,
  };
};

type DispatchProps = {|
  queryMessageTemplates: QueryFuncArgs => void,
  queryReload: () => void,
|};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  queryMessageTemplates: ({ pagination, params }) => {
    dispatch(workspaceMessageTemplateReducer.queryWorkspaceMessageTemplates({
      name: QUERY_NAME,
      pagination: {
        ...pagination,
        limit: 50,
      },
      params,
    }));
  },
  queryReload: () => {
    dispatch(workspaceMessageTemplateReducer.queryWorkspaceMessageTemplatesReload({
      name: QUERY_NAME,
    }));
  },
});

export default connect<Props, {||}, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(MessageTemplates);
