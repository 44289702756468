import { uniqueId, cloneDeep } from 'lodash';

// eslint-disable-next-line import/named
import { getAPIPath } from 'oneflow-client/core';

import { getPreviousContentData } from 'components/contract-boxes/generic-box-helpers';
import type { Box } from 'data-validators/entity-schemas/document-box';
import type { Asset } from 'reducers/current-contract';
import type { PdfContentData } from 'data-validators/entity-schemas/document-box/pdf-box';

type GeneratePdfBoxDataParams = {
  box: Box,
  asset: Asset,
  ownerParticipantId: Oneflow.Participant['id'],
}

export const generatePdfBoxData = ({
  box,
  asset,
  ownerParticipantId,
}: GeneratePdfBoxDataParams) => {
  const newDataItem = {
    key: 'file',
    value: {
      assetIdentifier: asset.assetIdentifier,
      name: asset.assetName,
      hasFile: 1,
      ownerParticipantId,
      assetId: asset.assetId,
      status: asset.status,
    },
    _id: Number(uniqueId()),
  };

  const updatedBox = cloneDeep(box);
  const previousContentData = getPreviousContentData(updatedBox.content.data);

  updatedBox.content = {
    ...updatedBox.content,
    data: [
      ...previousContentData,
      newDataItem,
    ],
  };

  return {
    newDataItem,
    updatedBox,
  };
};

export const generateDocumentPageURL = (
  asset: PdfContentData,
  page: number,
  contractId: Oneflow.Agreement['id'],
  guestToken: string,
) => {
  if (!asset?.value) {
    return '';
  }
  const { assetIdentifier } = asset.value;

  const assetsRoot = getAPIPath(`/agreements/${contractId}/assets`);
  const domain = `${document.location.protocol}//${document.domain}`;
  const link = `${domain}${assetsRoot}/${assetIdentifier}/${page}?redirect=true`;

  if (guestToken) {
    return `${link}&at=${guestToken}`;
  }

  return link;
};
