// @flow

import { connect } from 'react-redux';

import groupsReducer from 'reducers/entities/groups';
import groupWorkspacesReducer from 'reducers/entities/group-workspaces';

import GroupWorkspaceAccess from './group-workspace-access';

const getQueryName = (id) => `admin/groups/${id}/workspaces`;
export const GROUP_ACCESS_QUERY = 'userWorkspaces/groupAccess';

export const mapStateToProps = (state, ownProps) => {
  const groupId = ownProps.match.params.id;
  const query = groupWorkspacesReducer.getQuerySelector(state, {
    name: getQueryName(groupId),
  });
  const groupWorkspaces = groupWorkspacesReducer.getGroupWorkspacesSelector(state, {
    ids: query.result,
    groupId,
  });

  return {
    query,
    group: groupsReducer.getGroupSelector(state, { id: ownProps.match.params.id }),
    groupsBindingsRoles: groupWorkspaces,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  queryGroupWorkspaces: ({ pagination }: Object) => (
    dispatch(groupWorkspacesReducer.queryGroupWorkspaces({
      name: getQueryName(ownProps.match.params.id),
      params: {
        groupId: ownProps.match.params.id,
      },
      pagination,
    }))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupWorkspaceAccess);
