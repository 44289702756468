/* eslint-disable react/prop-types */

import React from 'react';
import { unescape } from 'lodash';

import style from './description.module.scss';

const Description = ({ description }) => {
  if (!description) {
    return null;
  }

  return (
    <p className={style.Description}>{unescape(description)}</p>
  );
};

export default Description;
