// @flow

import * as React from 'react';
import Media from 'react-media';
import take from 'lodash/take';
import without from 'lodash/without';
import clsx from 'clsx';
import useWindowSize from 'hooks/use-window-size';

import TagPopover from 'components/tags/tag-popover';
import Tag from 'components/tags/tag';
import AddTag from 'components/tags/add-tag';

import style from './tags.module.scss';

type Breakpoints = Array<{
  maxTagsNumber: number,
  query: {
    minWidth?: number,
    maxWidth?: number,
  },
}>;

type Props = {
  tagConnections: Array<TagConnection>,
  targetId: number,
  targetType: string,
  allowTagChange: boolean,
  allowSearch?: boolean,
  responsive?: boolean,
  preferPopoverPlace?: 'start' | 'end' | 'above' | 'right' | 'below' | 'left' | 'column' | 'row',
  tagsSelectorQuery: Query,
  numberOfTags?: number,
  breakpoints?: Breakpoints,
  amplitudeScope?: string,
  searchGlobally?: boolean,
  renderAsNativeAnchorElement?: boolean,
  isDocumentPage?: boolean,
};

const breakpointLg = 1320;
const breakpointXl = 1920;

export const breakpointsDefault: Breakpoints = [{
  maxTagsNumber: 0,
  query: {
    maxWidth: breakpointLg - 1,
  },
}, {
  maxTagsNumber: 2,
  query: {
    minWidth: breakpointLg,
    maxWidth: breakpointXl,
  },
}, {
  maxTagsNumber: 8,
  query: {
    minWidth: breakpointXl + 1,
  },
}];

const TagsList = ({
  allowSearch = true,
  responsive,
  preferPopoverPlace,
  numberOfTags,
  breakpoints = breakpointsDefault,
  tagConnections,
  allowTagChange,
  targetId,
  amplitudeScope,
  searchGlobally,
  renderAsNativeAnchorElement,
  tagsSelectorQuery,
  targetType,
  isDocumentPage = false,
}: Props) => {
  const windowSize = useWindowSize();

  const lockedStateWithNoTags = tagConnections.length === 0 && !allowTagChange;

  const tagsClassNames = clsx(
    { [style.TagsContainer]: !lockedStateWithNoTags },
    { [style.Responsive]: responsive },
  );

  const renderTag = (tagConnection: TagConnection) => (
    <Tag
      handleOverflow
      allowTagChange={allowTagChange}
      allowSearch={allowSearch}
      key={tagConnection.id}
      tagConnection={tagConnection}
      amplitudeScope={amplitudeScope}
      searchGlobally={searchGlobally}
      renderAsNativeAnchorElement={renderAsNativeAnchorElement}
    />
  );

  const getTags = (maxTagsNumber: number) => () => {
    let tagsToRender = take<TagConnection>(tagConnections, maxTagsNumber);
    let extraTagsForPopover = without(tagConnections, ...tagsToRender);

    const isScreenWidthInRange = (windowSize.width >= 1320) && ((windowSize.width <= 1640));

    if (isScreenWidthInRange) {
      // Don't show tags longer than 20 characters, instead add it to the + x more
      tagsToRender = tagConnections
        .filter((tagData) => tagData.tag.name.length <= 20);

      const averageTagToRenderLength = tagsToRender
        .reduce(
          (accumulator, currentValue) => accumulator + currentValue.tag.name.length,
          0,
        ) / tagsToRender.length;

      // Depending on what the average length is we will display different amount of tags
      let maxTags = 3;

      if (averageTagToRenderLength < 10) {
        maxTags = 2;
      } else if (averageTagToRenderLength <= 15) {
        maxTags = 1;
      }

      tagsToRender = tagsToRender.slice(0, maxTags);
      extraTagsForPopover = without(tagConnections, ...tagsToRender);
    }

    return (
      <div className={style.Tags}>
        {tagsToRender.map(renderTag)}
        <TagPopover
          searchGlobally={searchGlobally}
          tagConnections={extraTagsForPopover}
          allowTagChange={allowTagChange}
          allowSearch={allowSearch}
          amplitudeScope={amplitudeScope}
          renderAsNativeAnchorElement={renderAsNativeAnchorElement}
        />
      </div>
    );
  };

  const renderTagAdding = () => {
    if (!allowTagChange || isDocumentPage) {
      return null;
    }

    return (
      <AddTag
        blacklist={tagConnections.map((tagConnection) => tagConnection.tag.id)}
        preferPlace={preferPopoverPlace || 'right'}
        tagsSelectorQuery={tagsSelectorQuery}
        targetId={targetId}
        targetType={targetType}
        amplitudeScope={amplitudeScope}
      />
    );
  };

  const renderTagsWithBreakpoints = () => {
    if (numberOfTags) {
      return getTags(numberOfTags)();
    }

    return breakpoints.map<React.Node>(({ maxTagsNumber, query }) => (
      <Media
        key={maxTagsNumber}
        query={query}
        render={getTags(maxTagsNumber)}
      />
    ));
  };

  return (
    <div className={tagsClassNames}>
      {renderTagsWithBreakpoints()}
      {renderTagAdding()}
    </div>
  );
};

export default TagsList;
