// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import CheckCircle from 'components/icons/check-circle';
import Error from 'components/icons/error';
import SecurityLock from 'components/icons/security-lock';
import ExternalLink from 'components/icons/external-link';
import RevokeConnection from 'routes/marketplace/marketplace-settings/salesforce/revoke-connection';

import style from './salesforce-authentication.module.scss';

type AuthStatusProps = {
  oauthState: 'success' | 'error',
  instanceUrl?: string,
};

export const AuthStateComponent = ({
  oauthState,
  instanceUrl,
}: AuthStatusProps) => {
  const stateConfig = {
    success: {
      wrapperClass: style.ConnectionActive,
      StatusMessage: <Message
        id="Connection to Salesforce is active"
        comment="Status message for connection to Salesforce, when authorization is successful."
      />,
      StateIcon: <CheckCircle className={style.ConnectionActiveIcon} />,
    },
    error: {
      wrapperClass: style.ConnectionError,
      StatusMessage: <Message
        id="Could not authenticate to Salesforce"
        comment="Status message for connection to Salesforce, when failing to authorize."
      />,
      StateIcon: <Error className={style.ConnectionErrorIcon} />,
    },
  }[oauthState];

  return (
    <div className={stateConfig.wrapperClass}>
      <div className={style.Header}>
        {stateConfig.StatusMessage}
        {stateConfig.StateIcon}
      </div>

      <div className={style.AuthStatus}>
        <Message
          id="Currently connected to {instanceUrl}"
          comment="Info text about which Salesforce environment the extention is connected to."
          values={{
            instanceUrl: <em>{instanceUrl}</em>,
          }}
        />
        <RevokeConnection />
      </div>
    </div>
  );
};

AuthStateComponent.defaultProps = {
  instanceUrl: '',
};

export const InitAuthComponent = () => (
  <>
    <div className={style.ExtensionConfigurationBody}>
      <Message
        id="Please authenticate with Salesforce, using your Salesforce credentials, to allow Oneflow to send contract updates to Salesforce. You will only need to enter your credentials once, unless the credentials are revoked from Salesforce."
        comment="Help text for the Salesforce extension page."
      />
    </div>
    <Button
      customClass={style.InitAuthButton}
      href="/api/ext/salesforce/oauth_proxy"
      icon={<SecurityLock className={style.InitAuthButtonIcon} />}
      kind="primary"
      external
    >
      <Message
        id="Authenticate with Salesforce"
        comment="Button label. For button to iniate authorization towards Salesforce. On extension page."
      />
    </Button>
    <div className={style.ExtensionConfigurationBody}>
      <a href="/api/ext/salesforce/oauth_proxy?sandbox=1">
        <Message
          id="Authenticate with Salesforce sandbox"
          comment="Button label. For button to initiate authorization towards Salesforce. On extension page."
        />
        <ExternalLink className={style.ExternalLink} height="14px" />
      </a>
    </div>
  </>
);

export type AuthenticationProps = {
  oauthState: string | void,
  instanceUrl: string | void,
  refreshOauth: () => void,
  revokeState: RpcState,
};

export const Authentication = ({
  oauthState,
  instanceUrl,
  refreshOauth,
  revokeState,
}: AuthenticationProps) => {
  React.useEffect(() => {
    if (!oauthState) {
      refreshOauth();
    }
  }, [refreshOauth, oauthState]);

  if (revokeState.success) {
    return <InitAuthComponent />;
  }

  if (oauthState === 'success') {
    return (
      <AuthStateComponent
        oauthState="success"
        instanceUrl={instanceUrl}
      />
    );
  }

  if (oauthState === 'error') {
    return (
      <>
        <AuthStateComponent
          oauthState="error"
          instanceUrl={instanceUrl}
        />
        <InitAuthComponent />
      </>
    );
  }

  return <InitAuthComponent />;
};
