import { useState } from 'react';
import { useSelector } from 'react-redux';

import Message from 'components/message';
import Button from 'components/button';
import { getCountryListAsOptions } from 'components/countries';
import { getCurrentLanguageSelector } from 'reducers/i18n';

import BillingSection from 'components/billing-section';
import EditBillingDetails from 'components/modals/edit-billing-details';

import type { Billing } from 'types/billing';

type Props = {
  billing: Billing;
  accountId: number;
}

const BillingDetails = ({
  billing,
  accountId,
}: Props) => {
  const [showEditBillingDetails, setShowEditBillingDetails] = useState<boolean>(false);
  const languageCode = useSelector(getCurrentLanguageSelector);
  const countries = getCountryListAsOptions(languageCode);
  const currentCountry = countries.find((country) => country.value === billing.country);

  const openModal = () => {
    setShowEditBillingDetails(true);
  };

  const closeModal = () => {
    setShowEditBillingDetails(false);
  };

  return (
    <>
      <BillingSection
        title={(
          <Message
            id="Billing details"
            comment="Heading for the billing details section in subscription and billing page"
          />
        )}
        items={[{
          id: 'company',
          label: <Message
            id="Company name"
            comment="Label in the billing page"
          />,
          value: billing.name,
        },
        {
          id: 'vatNumber',
          label: <Message
            id="VAT number"
            comment="Label in the billing page"
          />,
          value: billing.vatNumber,
        },
        {
          id: 'address',
          label: <Message
            id="Address"
            comment="Label in the billing page"
          />,
          value: billing.address,
        },
        {
          id: 'zipCode',
          label: <Message
            id="ZIP code"
            comment="Label in the billing page"
          />,
          value: billing.zipCode,
        },
        {
          id: 'city',
          label: <Message
            id="City"
            comment="Label in the billing page"
          />,
          value: billing.city,
        },
        {
          id: 'country',
          label: <Message
            id="Country"
            comment="Label in the billing page"
          />,
          value: currentCountry.label,
        }]}
      />
      <Button
        kind="secondary"
        color="forest-green"
        onClick={openModal}
      >
        <Message
          id="Edit billing details"
          comment="Button text for edit billing details in subscription and billing page"
        />
      </Button>
      <EditBillingDetails
        billing={billing}
        isOpen={showEditBillingDetails}
        closeModal={closeModal}
        accountId={accountId}
      />
    </>
  );
};

export default BillingDetails;
