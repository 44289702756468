// @flow

import { connect } from 'react-redux';
import contactsReducer from 'reducers/entities/contacts';

import { DeleteContacts } from './delete-contacts';

const requestID = 'bulk';

type OwnProps = {
  ids: Array<number>,
  queryName: string,
};

export const mapStateToProps = (state: State) => ({
  removeState: contactsReducer.getRemoveSelector(state, { id: requestID }),
});

export const mapDispatchToProps = (dispatch: any, { ids, queryName }: OwnProps) => ({
  deleteContacts: () => {
    dispatch(contactsReducer.removeContact({
      id: requestID,
      data: {
        ids,
      },
      pipe: {
        action: () => contactsReducer.queryContactsReload({
          name: queryName,
        }),
        wait: contactsReducer.QUERY_CONTACTS_SUCCESS,
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(contactsReducer.removeContactReset({
      id: requestID,
    }));
  },
});

const connectedDeleteContacts = connect<any, State, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteContacts);

export { connectedDeleteContacts as DeleteContacts };
