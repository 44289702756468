// @flow

import omit from 'lodash/omit';
import { schema, normalize } from 'normalizr';

import {
  extractResponseBodyAsJSON,
  getMultiple,
  post,
} from 'oneflow-client/core';
import { roleSchema } from 'oneflow-client/roles';

import type { SortParams } from 'oneflow-client/core';
import { decamelizeKeys } from 'humps';

const bindingSchema = new schema.Entity('bindings', {
  role: roleSchema,
});

export const workspaceGroupsSchema = new schema.Entity('workspaceGroups');

const normalizeWorkspaceGroups = (groupData) => ({
  ...normalize(groupData.collection, [workspaceGroupsSchema]),
  count: groupData.count,
});

export type GetGroupsWithBindings = ({
  params: {
    workspaceId: number,
    [string]: any,
  },
  pagination?: {},
  sorting?: SortParams,
}) => Promise<any>;

export const getWorkspaceGroups: GetGroupsWithBindings = ({
  pagination,
  params,
  sorting = { attr: 'name', direction: 'asc' },
}) => (
  getMultiple({
    url: `/collections/${params.workspaceId}/bindings/`,
    params: decamelizeKeys(omit(params, 'workspaceId'), { separator: '_' }),
    pagination,
    sorting,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeWorkspaceGroups)
);

export type NormalizedBindings = {
  bindings: {
    [number]: Binding,
  },
};

type BindingsNormalizer = (any) => NormalizedBindings;

const normalizeBindings: BindingsNormalizer = (data) => (
  normalize(data.bindings, [bindingSchema])
);

type AssignGroupWorkspaces = ({
  actorId: number,
  roleId: number,
  workspaceIds: Array<number>
}) => Promise<NormalizedBindings>;

export const assignGroupWorkspaces: AssignGroupWorkspaces = ({
  actorId,
  roleId,
  workspaceIds,
}) => post({
  url: `/collections/groups/${actorId}/bindings/`,
  body: {
    role_id: roleId,
    workspace_ids: workspaceIds,
  },
})
  .then(extractResponseBodyAsJSON)
  .then(normalizeBindings);
