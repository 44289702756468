import QueryStringParamsProvider from 'hocs/query-string-params';
import { FilterContextProvider } from 'hocs/search/filter/context';

import AiInsights from './ai-insights';

export const AiInsightsWithProviders = () => (
  <QueryStringParamsProvider>
    <FilterContextProvider defaultAvailableFilters={{}}>
      <AiInsights />
    </FilterContextProvider>
  </QueryStringParamsProvider>
);
