import Message from 'components/message';

import { TYPE_RECURRING } from 'agreement/constants';
import { DurationBox } from 'data-validators/entity-schemas/document-box/duration-box';

export type AttributeUnit = 'd' | 'w' | 'm' | 'y';

const MAX_NUMBER_OF_DAYS = 36500;

const getDurationLimits = (attributeName: string) => ({
  d: {
    limit: MAX_NUMBER_OF_DAYS,
    message: (
      <Message
        id="{attributeName} can not be more than {limit} days."
        values={{
          limit: MAX_NUMBER_OF_DAYS,
          attributeName,
        }}
        comment="Used in max value validation of Period duration"
      />
    ),
  },
  w: {
    limit: Math.floor(MAX_NUMBER_OF_DAYS / 7),
    message: (
      <Message
        id="{attributeName} can not be more than {limit} weeks."
        values={{
          limit: Math.floor(MAX_NUMBER_OF_DAYS / 7),
          attributeName,
        }}
        comment="Used in max value validation of Period duration"
      />
    ),
  },
  m: {
    limit: Math.floor(MAX_NUMBER_OF_DAYS / 30),
    message: (
      <Message
        id="{attributeName} can not be more than {limit} months."
        values={{
          limit: Math.floor(MAX_NUMBER_OF_DAYS / 30),
          attributeName,
        }}
        comment="Used in max value validation of Period duration"
      />
    ),
  },
  y: {
    limit: Math.floor(MAX_NUMBER_OF_DAYS / 365),
    message: (
      <Message
        id="{attributeName} can not be more than {limit} years."
        values={{
          limit: Math.floor(MAX_NUMBER_OF_DAYS / 365),
          attributeName,
        }}
        comment="Used in max value validation of Period duration"
      />
    ),
  },
});

const convertToDays = (attributeScalar: number, attributeUnit: AttributeUnit) => {
  switch (attributeUnit) {
    case 'y': return Math.floor(attributeScalar * 365);

    case 'm': return Math.floor(attributeScalar * 30);

    case 'w': return Math.floor(attributeScalar * 7);

    default: return attributeScalar;
  }
};

export const getAttributeScalar = (attribute: string) => parseInt(
  (attribute?.substr(0, attribute.length - 1) || 0), 10,
);

export const getAttributeUnit = (
  attribute: string,
) => attribute?.split(getAttributeScalar(attribute))[1];

type AttributeValidatorProps = {
  duration: string,
  noticePeriod: string,
  initialDuration: string,
  attributeToValidate: string,
  attributeUnit: AttributeUnit,
  type: DurationBox['content']['agreement']['type'],
};

export const attributeValidator = (value: any, {
  duration,
  noticePeriod,
  initialDuration,
  attributeToValidate,
  attributeUnit,
  type,
}: AttributeValidatorProps) => {
  const convertedAttribute = convertToDays(value, attributeUnit);
  const convertedNoticePeriod = convertToDays(
    getAttributeScalar(noticePeriod), getAttributeUnit(noticePeriod),
  );

  // Validations for initialDuration popover
  if (initialDuration && attributeToValidate === 'initialDuration') {
    if (convertedAttribute < convertedNoticePeriod) {
      return (
        <Message
          id="Initial Duration must be greater than Notice period"
          comment="Used in max value validation of Period duration"
        />
      );
    }
  }

  // Validations for duration popover
  if (attributeToValidate === 'duration' && type === TYPE_RECURRING) {
    if (convertedAttribute < convertedNoticePeriod) {
      return (
        <Message
          id="Duration must be greater than Notice period"
          comment="Used in max value validation of Period duration"
        />
      );
    }
  }

  // Validations for noticePeriod popover
  if (attributeToValidate === 'noticePeriod' && type === TYPE_RECURRING) {
    const convertedDuration = convertToDays(
      getAttributeScalar(duration), getAttributeUnit(duration),
    );
    const convertedInitialDuration = convertToDays(
      getAttributeScalar(initialDuration), getAttributeUnit(initialDuration),
    );

    if (initialDuration && convertedAttribute > convertedInitialDuration) {
      return (
        <Message
          id="Notice period must be less than Initial Duration"
          comment="Used in max value validation of Period duration"
        />
      );
    }

    if (convertedAttribute > convertedDuration) {
      return (
        <Message
          id="Notice period must be less than Duration"
          comment="Used in max value validation of Period duration"
        />
      );
    }
  }

  return undefined;
};

type AttributeScalar = {
  attributeUnit: AttributeUnit,
  attributeName: string,
}

export const attributeScalarValidator = (
  value: string,
  { attributeUnit, attributeName }: AttributeScalar,
) => {
  if (!getDurationLimits(attributeName)[attributeUnit]) {
    return (
      <Message
        id="{attributeName} unit must be valid."
        comment="Used in max value validation of Period duration"
        values={{
          attributeName,
        }}
      />
    );
  }
  const { limit, message } = getDurationLimits(attributeName)[attributeUnit];
  const numberValue = Number(value);

  if (!Number.isNaN(numberValue) && Number(value) <= limit) {
    return undefined;
  }

  return message;
};
