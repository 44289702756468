import getFontAssetsDomain from './get-font-asset-domain.js';

const loadInter = () => {
  const normalInter = new FontFace(
    'Inter',
    `url(${getFontAssetsDomain()}/resources/fonts/inter/inter_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const boldInter = new FontFace(
    'Inter',
    `url(${getFontAssetsDomain()}/resources/fonts/inter/inter_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );

  return [normalInter, boldInter];
};

export default loadInter;
