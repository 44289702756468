// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import webhooksReducer from 'reducers/entities/webhooks';

import WebhookUrlResponse from './webhook-url-response';
import type { Props } from './webhook-url-response';

type StateProps = {|
  verifyWebhookUrlState: RpcState,
  responseStatusCode?: number,
|};

type DispatchProps = {|
  resetVerifyWebhookUrlState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  webhookId?: number,
};
type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { webhookId = 'create' }) => ({
  responseStatusCode: webhooksReducer.getWebhookSelector(state, {
    id: webhookId,
  }).responseStatusCode,
  verifyWebhookUrlState: webhooksReducer.getUrlCheckSelector(state, {
    id: webhookId,
  }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { webhookId = 'create' }) => ({
  resetVerifyWebhookUrlState: () => {
    dispatch(webhooksReducer.urlCheckReset({
      id: webhookId,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(WebhookUrlResponse);
