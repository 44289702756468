import { isEmpty } from 'lodash';
import { Editor } from 'slate';

import useAnnotationsMap from './use-annotations-map';

const useAnnotationEditor = (annotationId: number): Editor | null => {
  const annotationsMap = useAnnotationsMap();
  const annotationInfo = annotationsMap[annotationId];

  if (isEmpty(annotationInfo)) {
    return null;
  }

  const { editor } = annotationInfo;

  return editor as Editor;
};

export default useAnnotationEditor;
