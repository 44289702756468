// @flow

import { decamelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';

import {
  getMultiple,
  post,
  put,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

export const dataFieldSchema = new schema.Entity('dataFields');

export type NormalizedDataFields = {
  entities: {
    dataFields: {
      [number]: DataField,
    },
  },
  result: number | Array<number>,
  count: number,
};

type DataFieldNormalizer = (any) => NormalizedDataFields;

const normalizeDataFields: DataFieldNormalizer = (dataFields) => ({
  ...normalize(dataFields, [dataFieldSchema]),
  count: dataFields.length,
});

const normalizeDataField: DataFieldNormalizer = (dataField) => ({
  ...normalize(dataField, dataFieldSchema),
  count: 1,
});

type GetDataFields = ({
  params: {
    dataFieldSetId: number,
  },
}) => Promise<NormalizedDataFields>;

export const getDataFields: GetDataFields = ({ params }) => (
  getMultiple({
    url: `/data_field_sets/${params.dataFieldSetId}/fields/`,
    params: {},
    pagination: {
      limit: undefined,
      offset: undefined,
    },
  }).then(extractResponseBodyAsJSON)
    .then(normalizeDataFields)
);

type CreateDataField = ({
  dataFieldSetId: number,
  name: string,
  description?: string,
  placeholder?: string,
  value?: string,
  externalKey?: string,
}) => Promise<NormalizedDataFields>;

export const createDataField: CreateDataField = ({
  dataFieldSetId,
  name,
  description,
  placeholder,
  value,
  externalKey,
}) => (
  post({
    url: `/data_field_sets/${dataFieldSetId}/fields/`,
    body: decamelizeKeys({
      name,
      description,
      placeholder,
      value,
      externalKey,
    }, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
    .then(normalizeDataField)
);

type RemoveDataField = ({
  dataFieldSetId: number,
  id: number,
}) => Promise<{}>;

export const removeDataField: RemoveDataField = ({ dataFieldSetId, id }) => (
  remove({
    url: `/data_field_sets/${dataFieldSetId}/fields/${id}`,
  }).then(() => ({}))
);

type UpdateDataField = ({
  dataFieldSetId: number,
  id: number,
  name: string,
  description?: string,
  placeholder?: string,
  value?: string,
  externalKey?: string,
}) => Promise<NormalizedDataFields>;

export const updateDataField: UpdateDataField = ({
  dataFieldSetId,
  id,
  name,
  description,
  placeholder,
  value,
  externalKey,
}) => (
  put({
    url: `/data_field_sets/${dataFieldSetId}/fields/${id}`,
    body: decamelizeKeys({
      name,
      description,
      placeholder,
      value,
      externalKey,
    }, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
    .then(normalizeDataField)
);
