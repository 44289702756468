import { Message } from '@oneflowab/pomes';

import Field from 'components/field';
import Checkbox from 'components/checkbox';

type Props = {
  billingCompanyName: string;
};

export const AccountPurchaseConfirmField = ({ billingCompanyName }: Props) => {
  const validateIfChecked = (value: boolean) => {
    if (value !== true) {
      return true;
    }

    return undefined;
  };

  return (
    <Field
      name="accountPurchaseConfirm"
      component={Checkbox}
      type="checkbox"
      validate={validateIfChecked}
      label={(
        <Message
          id="I confirm I have the right to and want to carry out the above purchase on behalf of {billingCompanyName}."
          comment="Checkbox label for the user to confirm when making purchases on behalf of the company."
          values={{
            billingCompanyName,
          }}
        />
      )}
      wrapLabelText
    />
  );
};
