// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import CircularSpinner from 'components/icons/circular-spinner';

import style from './webhook-url-response.module.scss';

export type Props = {
  verifyWebhookUrlState: RpcState,
  responseStatusCode?: number,
  resetVerifyWebhookUrlState: () => void,
};

class WebhookUrlResponse extends React.Component<Props> {
  static defaultProps = {
    responseStatusCode: undefined,
  };

  componentDidMount() {
    const { resetVerifyWebhookUrlState } = this.props;

    resetVerifyWebhookUrlState();
  }

  renderSuccessText() {
    const { responseStatusCode } = this.props;

    let statusCode = <span className={style.StatusCode}>{responseStatusCode}</span>;
    if (responseStatusCode !== 200) {
      statusCode = <span className={style.ErrorCode}>{responseStatusCode}</span>;
    }

    return (
      <Message
        id="URL responded with status code: {statusCode}"
        values={{
          statusCode,
        }}
        comment="Help text for webhook URL response."
      />
    );
  }

  renderText() {
    const { verifyWebhookUrlState } = this.props;

    if (verifyWebhookUrlState.error) {
      return (
        <span className={style.Error}>
          <Message
            id="Request failed: Invalid URL"
            comment="Help text for webhook URL response."
          />
        </span>
      );
    }

    if (verifyWebhookUrlState.loading) {
      return <CircularSpinner height="20" className={style.Loading} />;
    }

    if (verifyWebhookUrlState.success) {
      return this.renderSuccessText();
    }

    return (
      <Message
        id="Test your webhook URL to make sure it is valid!"
        comment="Help text for webhook URL response."
      />
    );
  }

  render() {
    return (
      <div className={style.WebhookUrlResponse}>
        {this.renderText()}
      </div>
    );
  }
}

export default WebhookUrlResponse;
