// @flow

import { connect } from 'react-redux';

import positionMfaReducer from 'reducers/entities/position-mfa';

import type { Dispatch } from 'redux';

import DisableMfa, { type Props, type FormData } from './disable-mfa';

type StateProps = {|
  formState: CreateState,
  sendData: FormData => void,
  formActions: {
    start: string,
    success: string,
    fail: string,
  },
|};

type DispatchProps = {|
  resetFormState: () => void,
|};

type OwnProps = $Diff<Props, StateProps & DispatchProps> & {|
  position: Position,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, ownProps) => ({
  formState: positionMfaReducer.getRemoveSelector(state, {
    id: ownProps.position.id,
  }),
  formActions: {
    start: positionMfaReducer.actions.types.removeStart,
    success: positionMfaReducer.actions.types.removeSuccess,
    fail: positionMfaReducer.actions.types.removeFail,
  },
  sendData: (formData) => ({
    id: ownProps.position.id,
    data: {
      password: formData.passwordVerification,
    },
  }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, ownProps) => ({
  resetFormState: () => {
    dispatch(
      positionMfaReducer.removePositionMfaReset({
        id: ownProps.position.id,
      }),
    );
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(DisableMfa);
