/* eslint-disable import/named */
import { useCallback, useState, useEffect } from 'react';
import log from 'logging';
import { useDispatch, useSelector } from 'react-redux';
import { useSlate } from 'slate-react';
import { get, head } from 'lodash';
import clsx from 'clsx';

import {
  isContractBoxPristine,
  onSaveComment,
  onSaveSuggestion,
} from 'reducers/current-contract';
import { setFocusedEditor } from 'reducers/editor';

import { useContractProps } from 'contexts/contract-props';
import { useAnnotationProps } from 'contexts/annotation-props';
import { useRichTextProps } from 'contexts/rich-text';
import { isDraft } from 'agreement';

import useAgreement from 'hooks/use-agreement';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import useCurrentBox from 'hooks/use-current-box';
import useMatchMedia, { DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY } from 'hooks/use-match-media';

import {
  isCommentsEnabled,
  isSuggestionsEnabled,
} from 'comments';
import {
  SAVE_ANNOTATION_IN_TEXT_BOX_ERROR_TITLE,
  SAVE_COMMENT_IN_TEXT_BOX_ERROR,
  SAVE_SUGGESTION_IN_TEXT_BOX_ERROR,
  contractVersionNotUpToDateToast,
} from 'comments/constants';
import {
  focusNodeAfterAnnotation,
} from 'components/contract-text-editor/editor-plugins/annotation-plugin';
import toast from 'components/toasts';
import { useSelectedRectanglePopoverProps } from 'components/rich-text-editor-toolbars/inline-toolbar/use-selected-rectangle-popover-props';
import { UI_STATE_MAP } from 'components/rich-text-editor-toolbars/inline-toolbar/constants';
import { getErrorCode } from 'components/api-error';

import useShouldRenderAnnotationToolbar from 'components/rich-text-editor-toolbars/toolbar-buttons/post-annotation/annotation-toolbar/use-should-render-annotation-toolbar';
import useShouldRenderAIAssistToolbar from 'components/rich-text-editor-toolbars/inline-toolbar/ai-assist-toolbar/use-should-render-ai-assist-toolbar';
import SelectedRectanglePopover from 'components/rich-text-editor-toolbars/inline-toolbar/selected-rectangle-popover';
import AIAssistToolbarButton from 'components/rich-text-editor-toolbars/inline-toolbar/ai-assist-toolbar/ai-assist-toolbar-button';
import RichTextEditorToolbarDivider from 'components/rich-text-editor-toolbar-divider';
import AIAssistPopover from 'components/rich-text-editor-toolbars/inline-toolbar/ai-assist-toolbar/popover/ai-assist-popover';
import PostMessage from 'components/document-tabs/messages-tab/post-message';

import AnnotationToolbar from '../annotation-toolbar';
import PostSuggestion from '../post-suggestion';
import SaveChangesModal from '../save-changes-modal/save-changes-modal';

import style from './annotation-interaction-container.module.scss';

type Props = {
  contractId: number,
  readOnly: boolean,
};

type SuggestionType = null | 'change' | 'delete';

export const AnnotationInteractionContainer = ({
  contractId,
  readOnly,
}: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(contractId);
  const {
    boxId,
    isAiAssistPopoverOpen,
    setIsAiAssistPopoverOpen,
  } = useContractProps();

  const isBoxPristine = useSelector((state) => isContractBoxPristine(state, boxId));
  const { newAnnotationIdRef } = useAnnotationProps();
  const box = useCurrentBox(boxId);
  const data = get(box, 'content.data');
  const dataId = head(data)?.id;
  const inlineCommentsEnabled = isCommentsEnabled(agreement);
  const suggestionsEnabled = isSuggestionsEnabled(agreement);
  const [isSuggestion, setIsSuggestion] = useState<boolean>(false);
  const [shouldOpenSuggestionInput, setShouldOpenSuggestionInput] = useState<boolean>(false);
  const [suggestionType, setSuggestionType] = useState<SuggestionType>(null);
  const messageIsPrivate = isDraft(agreement);
  const focusEditor = useFocusEditor();
  const editor = useSlate();

  const isCollapsedLayout = useMatchMedia(DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY);
  const {
    editorSelection,
    resetEditorSelectedRangeInfo,
  } = useRichTextProps();

  const {
    UIState,
    resetUIState,
    setUIState,
    onInteractOutside,
    onEscapeKeyDown,
  } = useSelectedRectanglePopoverProps();

  const {
    annotationToolbar,
    noneActive,
    postCommentPopover,
    postSuggestionPopover,
    aiAssistPopover,
    saveChangesModal,
  } = UI_STATE_MAP;

  const shouldRenderAIAssistToolbar = useShouldRenderAIAssistToolbar(contractId) && !readOnly;
  const shouldRenderAnnotationsToolbar = useShouldRenderAnnotationToolbar(contractId);
  const shouldRenderBetaBadge = UIState === postCommentPopover || UIState === postSuggestionPopover;

  const canInteractWithAnnotation = shouldRenderAnnotationsToolbar || shouldRenderAIAssistToolbar;

  const onCommentButtonMouseDown = useCallback((e: MouseEvent) => {
    if (readOnly) {
      dispatch(setFocusedEditor(editor));
    }

    setIsSuggestion(false);
    e.preventDefault();
    e.stopPropagation();

    if (isBoxPristine) {
      setUIState(postCommentPopover);
    } else {
      setUIState(saveChangesModal);
    }
  }, [dispatch, editor, isBoxPristine, postCommentPopover, readOnly, saveChangesModal, setUIState]);

  const resetCommentStates = useCallback(() => {
    resetEditorSelectedRangeInfo();
    resetUIState();
    setSuggestionType(null);
  }, [resetEditorSelectedRangeInfo, resetUIState]);

  const onFailure = useCallback((error: any) => {
    resetCommentStates();
    const errorCodeMessage = getErrorCode(error);
    if (!isSuggestion) {
      log.error(error, 'Comments: Attaching comment to text failed');
      if (errorCodeMessage === 'DATA_NODES_CONFLICT') {
        contractVersionNotUpToDateToast();
        return;
      }

      toast.error({
        id: 'post-comment',
        title: SAVE_ANNOTATION_IN_TEXT_BOX_ERROR_TITLE,
        description: SAVE_COMMENT_IN_TEXT_BOX_ERROR,
      });
    } else {
      log.error(error, 'Suggestions: Adding suggestion failed');
      if (errorCodeMessage === 'DATA_NODES_CONFLICT') {
        contractVersionNotUpToDateToast();
        return;
      }

      toast.error({
        id: 'post-suggestion',
        title: SAVE_ANNOTATION_IN_TEXT_BOX_ERROR_TITLE,
        description: SAVE_SUGGESTION_IN_TEXT_BOX_ERROR,
      });
    }
  }, [isSuggestion, resetCommentStates]);

  const onSuccess = useCallback((commentId: number) => {
    resetCommentStates();

    newAnnotationIdRef.current = commentId;
    focusNodeAfterAnnotation(editor, commentId, isSuggestion);

    setTimeout(() => {
      newAnnotationIdRef.current = null;
    }, 200);
  }, [editor, isSuggestion, newAnnotationIdRef, resetCommentStates]);

  const addSuggestion = useCallback((suggestedText: string) => {
    if (readOnly) {
      dispatch(setFocusedEditor(null));
    }

    dispatch(onSaveSuggestion({
      boxId,
      dataId,
      editor,
      editorSelection,
      suggestedText,
      readOnly,
      onSuccess,
      onFailure,
    }));
    resetCommentStates();
  }, [
    dispatch,
    boxId,
    dataId,
    editor,
    editorSelection,
    readOnly,
    onSuccess,
    onFailure,
    resetCommentStates,
  ]);

  const onSuggestDeletion = (e: MouseEvent) => {
    setIsSuggestion(true);
    e.preventDefault();
    e.stopPropagation();

    setSuggestionType('delete');

    if (isBoxPristine) {
      addSuggestion('');
    } else {
      setUIState(saveChangesModal);
    }
  };
  const onSuggestChange = useCallback((e: MouseEvent) => {
    setIsSuggestion(true);
    e.preventDefault();
    e.stopPropagation();

    if (readOnly) {
      dispatch(setFocusedEditor(editor));
    }

    setSuggestionType('change');

    if (isBoxPristine) {
      setUIState(postSuggestionPopover);
    } else {
      setUIState(saveChangesModal);
    }
  }, [
    dispatch,
    editor,
    isBoxPristine,
    postSuggestionPopover,
    readOnly,
    saveChangesModal,
    setUIState,
  ]);

  const onPostComment = useCallback((message: any) => {
    if (readOnly) {
      dispatch(setFocusedEditor(null));
    }

    dispatch(onSaveComment({
      contractId,
      editorSelection,
      editor,
      boxId,
      dataId,
      message,
      onSuccess,
      onFailure,
    }));
  }, [
    boxId,
    dataId,
    editor,
    dispatch,
    contractId,
    onSuccess,
    onFailure,
    editorSelection,
    readOnly,
  ]);

  const handleEscapeKeyDown = useCallback(() => {
    resetCommentStates();
    onEscapeKeyDown();

    if (readOnly) {
      dispatch(setFocusedEditor(null));
    }
  }, [dispatch, onEscapeKeyDown, readOnly, resetCommentStates]);

  const handleInteractOutside = useCallback(() => {
    if (readOnly) {
      dispatch(setFocusedEditor(null));
      resetEditorSelectedRangeInfo();
    }
  }, [dispatch, readOnly, resetEditorSelectedRangeInfo]);

  const handleAiAssistPopoverEscapeKeyDown = useCallback(() => {
    setIsAiAssistPopoverOpen(false);
    onEscapeKeyDown();
  }, [onEscapeKeyDown, setIsAiAssistPopoverOpen]);

  const handleAiAssistPopoverInteractOutside = useCallback(() => {
    setIsAiAssistPopoverOpen(false);
    onInteractOutside();
  }, [onInteractOutside, setIsAiAssistPopoverOpen]);

  const onSaveCommentChanges = useCallback(() => {
    focusEditor();
    setUIState(postCommentPopover);
  }, [focusEditor, postCommentPopover, setUIState]);

  const onSaveSuggestionChanges = useCallback(() => {
    if (suggestionType === 'delete') {
      addSuggestion('');
      focusEditor();
    }

    setUIState(noneActive);
    setShouldOpenSuggestionInput(suggestionType !== 'delete');
  }, [addSuggestion, focusEditor, noneActive, setUIState, suggestionType]);

  useEffect(() => {
    if (shouldOpenSuggestionInput) {
      setUIState(postSuggestionPopover);
      setShouldOpenSuggestionInput(false);
    }
  }, [postSuggestionPopover, setUIState, shouldOpenSuggestionInput]);

  const renderAnnotationToolbar = () => {
    if (UIState !== annotationToolbar) {
      return null;
    }

    return (
      <div className={style.AnnotationToolbar}>
        {shouldRenderAIAssistToolbar && !isCollapsedLayout && (
          <>
            <AIAssistToolbarButton />
            {shouldRenderAnnotationsToolbar && <RichTextEditorToolbarDivider />}
          </>
        )}
        {shouldRenderAnnotationsToolbar && (
          <AnnotationToolbar
            agreementId={agreement.id}
            commentsEnabled={inlineCommentsEnabled}
            onCommentMouseDown={onCommentButtonMouseDown}
            onSuggestChange={onSuggestChange}
            onSuggestDeletion={onSuggestDeletion}
            state={agreement.state as number}
            suggestionsEnabled={suggestionsEnabled}
          />
        )}
      </div>
    );
  };

  const popoverContentClasses = clsx({
    [style.PostPopover]: UIState === postCommentPopover
      || UIState === postSuggestionPopover,
  });

  if (!canInteractWithAnnotation) {
    return null;
  }

  return (
    <>
      {
        UIState !== aiAssistPopover && (
          <SelectedRectanglePopover
            handleEscapeKeyDown={handleEscapeKeyDown}
            handleInteractOutside={handleInteractOutside}
            contentClasses={popoverContentClasses}
          >
            {renderAnnotationToolbar()}
            {UIState === postCommentPopover && (
              <PostMessage
                onPostMessage={onPostComment}
                agreementId={contractId}
                isComment
                autoFocus
                shouldRenderBetaBadge={shouldRenderBetaBadge}
              />
            )}
            {UIState === postSuggestionPopover && (
              <PostSuggestion
                addSuggestion={addSuggestion}
                isPrivate={messageIsPrivate}
                shouldRenderBetaBadge={shouldRenderBetaBadge}
              />
            )}
          </SelectedRectanglePopover>
        )
      }
      <SaveChangesModal
        onSaveChanges={isSuggestion ? onSaveSuggestionChanges : onSaveCommentChanges}
        isOpen={UIState === saveChangesModal}
        onCancel={resetUIState}
        isSuggestion={isSuggestion}
        shouldShowSaveSuccessToast
      />
      {shouldRenderAIAssistToolbar && (
        <AIAssistPopover
          open={isAiAssistPopoverOpen}
          onEscapeKeyDown={handleAiAssistPopoverEscapeKeyDown}
          onInteractOutside={handleAiAssistPopoverInteractOutside}
        />
      )}
    </>
  );
};
