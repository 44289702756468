import { useSelector } from 'react-redux';
import {
  hasDraftApprovalFlow,
  canBePublishedByMyParticipant,
} from 'agreement';

import useAgreement from 'hooks/use-agreement';
import { getAgreementMyParticipant, getEnabledParticipants } from 'agreement/selectors';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getHasNonPdfSections } from 'reducers/current-contract';

import { checkAcl } from 'components/acl';
import { EXPANDED } from 'components/document-layout-container/helpers';
import type { Layout } from 'components/document-layout-container/types';
import {
  SaveButton,
  DiscardButton,
  SendDocumentButton,
  SendDraftForApprovalButton,
} from './buttons';

type Props = {
  layout?: Layout,
  agreementId: Oneflow.Agreement['id'],

}

const DiscardSaveSendActions = ({ layout = EXPANDED, agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const myParticipant = getAgreementMyParticipant(agreement);
  const participants = getEnabledParticipants(agreement);
  const account: Oneflow.Account = useSelector(getAccountFromSessionSelector);

  const hasNonPdfSections = useSelector(getHasNonPdfSections) ?? false;
  const hasPermissionToPublish = checkAcl(agreement.acl, 'agreement:publish');

  const isFreemiumWithNonPdfSections = account.isFreemium && hasNonPdfSections;

  const canPublish = canBePublishedByMyParticipant(
    agreement,
    myParticipant,
    participants,
    isFreemiumWithNonPdfSections,
    hasPermissionToPublish,
  );

  const renderButton = () => {
    if (hasDraftApprovalFlow(agreement)) {
      return (
        <SendDraftForApprovalButton
          agreementId={agreementId}
          layout={layout}
        />
      );
    }

    return <SendDocumentButton agreementId={agreementId} layout={layout} canPublish={canPublish} />;
  };

  return (
    <>
      <DiscardButton />
      <SaveButton agreementId={agreementId} />
      {layout === EXPANDED && renderButton()}
    </>
  );
};

export default DiscardSaveSendActions;
