// @flow

import {
  Editor,
  Range,
  Point,
} from 'slate';

/* eslint-disable no-param-reassign */
const withTable = (editor: Editor) => {
  const { deleteBackward, deleteForward } = editor;

  const preventDeleteCell = (operation, pointCallback) => (
    unit: any,
  ) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) => n.type === 'table-cell',
        mode: 'lowest',
      });

      if (cell) {
        const [, cellPath] = cell;
        const start = pointCallback(editor, cellPath);

        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
    }
    operation(unit);
  };

  editor.deleteBackward = preventDeleteCell(deleteBackward, Editor.start);

  editor.deleteForward = preventDeleteCell(deleteForward, Editor.end);

  return editor;
};

export default withTable;
