import React from 'react';
import PropTypes from 'prop-types';

import style from './table.module.scss';

const TableFoot = ({ children }) => (
  <tfoot className={style.TableFoot}>
    {children}
  </tfoot>
);

TableFoot.propTypes = {
  children: PropTypes.node,
};

TableFoot.defaultProps = {
  children: null,
};

export default TableFoot;
