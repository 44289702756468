import { useCallback, useMemo } from 'react';
import type { CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ControlProps } from 'react-select';
import { debounce, isEmpty, head } from 'lodash';
import clsx from 'clsx';
import { OnChange } from 'react-final-form-listeners';

import type { MessageTranslator } from '@oneflowab/pomes';
import {
  USER_WORKSPACE_ROLE_READ_ONLY,
  USER_ROLE_SCOPE_WORKSPACE,
  USER_ROLE_LIMITED,
} from 'user';

// eslint-disable-next-line import/no-named-as-default
import workspacesReducer from 'reducers/entities/workspaces';
import rolesReducer from 'reducers/entities/roles';

import Field from 'components/field';
import SelectField from 'components/select-field';
import Message from 'components/message';

import getCSSPropertyValue from 'utils/get-css-property-value';

import {
  LIMIT,
  INPUT_DEBOUNCE_TIME,
} from '../constants';

import style from '../invite-user.module.scss';
import { ModalConfig } from '../invite-user-body';

export const WORKSPACES_QUERY_NAME = 'InviteUser/workspaces';
export const WORKSPACE_ROLES_QUERY_NAME = 'InviteUser/roles';
export type FormValues = {
  accountRole: Oneflow.Role,
  email: string,
  fullname: string,
  groups: Oneflow.Group[],
  language: string,
  userRole: { value: string, label: string },
  workspaces: Oneflow.Workspace[],
  workspaceRole?: Oneflow.Role,
}
export type Value = Oneflow.Role[] | Record<string, Oneflow.Role> | undefined;

export const validateWorkspaceFields = (value: Value, allValues: FormValues) => {
  const hasSelectedWorkspaceRole = Boolean(
    allValues.workspaceRole,
  );
  const hasSelectedWorkspaces = !isEmpty(allValues.workspaces);
  const isLimitedUserType = allValues.userRole.value === USER_ROLE_LIMITED;

  if (!isEmpty(value)) {
    return undefined;
  }

  if (isLimitedUserType) {
    return undefined;
  }

  if (hasSelectedWorkspaceRole === hasSelectedWorkspaces) {
    return undefined;
  }

  return (
    <Message
      id="Workspaces and workspace role must both be set if using direct access"
      comment="Used validation of workspace access fields in invite user modal."
    />
  );
};

type WorkspaceSectionProps = {
  message: MessageTranslator,
  modalConfig: ModalConfig,
  setModalConfig: (modalConfig: ModalConfig) => void,
}

const WorkspaceSection = ({
  message,
  modalConfig,
  setModalConfig,
}: WorkspaceSectionProps): JSX.Element => {
  const { isLimitedSelected, isWorkspaceSelected, readOnlyWorkspaceRoleId } = modalConfig;
  const workspacesQuery = useSelector((state) => (
    workspacesReducer.getQuerySelector(state, { name: WORKSPACES_QUERY_NAME })
  ));
  const workspaceRolesQuery = useSelector((state) => (
    rolesReducer.getQuerySelector(state, { name: WORKSPACE_ROLES_QUERY_NAME })
  ));
  const workspacesList = useSelector((state) => (
    workspacesReducer.getWorkspacesSelector(state, { ids: workspacesQuery.result })
  ));

  const workspaceRoles = useSelector((state) => (
    rolesReducer.getRolesSelector(state, { ids: workspaceRolesQuery.result })
  ));

  const dispatch = useDispatch();
  const queryMatchingWorkspaces = useCallback(({ searchValue }: { searchValue: string}) => {
    dispatch(workspacesReducer.queryWorkspaces({
      name: WORKSPACES_QUERY_NAME,
      pagination: {
        limit: LIMIT,
      },
      params: {
        q: searchValue,
        workspaceAdmin: 1,
      },
    }));
  }, [dispatch]);
  const queryWorkspacesLoadMore = ({ additionalResults }: { additionalResults: number}) => {
    dispatch(workspacesReducer.queryWorkspacesLoadMore({
      name: WORKSPACES_QUERY_NAME,
      additionalResults,
    }));
  };

  const queryMatchingWorkspaceRoles = useCallback(({ searchValue }: { searchValue: string}) => {
    dispatch(rolesReducer.queryRoles({
      name: WORKSPACE_ROLES_QUERY_NAME,
      pagination: {
        limit: LIMIT,
      },
      params: {
        scope: USER_ROLE_SCOPE_WORKSPACE,
        q: searchValue,
      },
      sort: ['-type', 'name'],
    }));
  }, [dispatch]);

  const queryWorkspaceRolesLoadMore = ({ additionalResults }: { additionalResults: number}) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: WORKSPACE_ROLES_QUERY_NAME,
      params: {
        scope: USER_ROLE_SCOPE_WORKSPACE,
      },
      sort: ['-type', 'name'],
      additionalResults,
    }));
  };

  const debouncedQueryWorkspacesSearch = useMemo(() => debounce((searchValue) => {
    queryMatchingWorkspaces({ searchValue });
  }, INPUT_DEBOUNCE_TIME, { leading: true }), [queryMatchingWorkspaces]);

  const debouncedQueryWorkspaceRolesSearch = useMemo(() => debounce((searchValue) => {
    queryMatchingWorkspaceRoles({ searchValue });
  }, INPUT_DEBOUNCE_TIME, { leading: true }), [queryMatchingWorkspaceRoles]);

  const onLoadMoreWorkspaces = (additionalResults: number) => {
    queryWorkspacesLoadMore({ additionalResults });
  };

  const onLoadMoreWorkspaceRoles = (additionalResults: number) => {
    queryWorkspaceRolesLoadMore({ additionalResults });
  };

  const getWorkspacesOnInputChange = (searchValue: string) => {
    debouncedQueryWorkspacesSearch(searchValue);
  };

  const getWorkspaceRolesOnInputChange = (searchValue: string) => {
    debouncedQueryWorkspaceRolesSearch(searchValue);
  };

  const onWorkspaceChange = (selectedWorkspace?: Oneflow.Workspace) => {
    setModalConfig({ ...modalConfig, isWorkspaceSelected: Boolean(selectedWorkspace) });
  };

  const renderWorkspaceRoleField = () => (
    <Field
      name="workspaceRole"
      data-testid="workspace-role-field"
      customStyles={{
        singleValue: (providedStyles: CSSProperties, state: ControlProps): CSSProperties => {
          if (state.isDisabled && USER_WORKSPACE_ROLE_READ_ONLY) {
            return {
              ...providedStyles,
              color: `${getCSSPropertyValue('--of-forest-green-03')} !important`,
              display: 'flex',
              alignItems: 'center',
            };
          }
          return providedStyles;
        },
      }}
      label={message({
        id: 'Workspace role',
        comment: 'Field label for role in invite user modal.',
      })}
      placeholder={(
        <Message
          id="Select workspace role"
          comment="Field placeholder in invite user modal."
        />
      )}
      component={SelectField}
      valueKey="id"
      labelKey="name"
      options={workspaceRoles}
      isLoading={workspaceRolesQuery?.loading}
      validate={validateWorkspaceFields}
      onInputChange={getWorkspaceRolesOnInputChange}
      disabled={isLimitedSelected}
      loadMoreItems={onLoadMoreWorkspaceRoles}
      displayErrorEarly
      clearable
    />
  );

  const renderReadOnlyWorkspaceRoleField = () => {
    const readOnlyOption = workspaceRoles
      .filter((role: Oneflow.Role) => role.id === USER_WORKSPACE_ROLE_READ_ONLY);

    return (
      <Field
        name="workspaceRoleReadOnly"
        data-testid="workspace-role-read-only-field"
        label={message({
          id: 'Workspace role',
          comment: 'Field label for role in invite user modal.',
        })}
        placeholder={(
          <Message
            id="Select workspace role"
            comment="Field placeholder in invite user modal."
          />
        )}
        component={SelectField}
        valueKey="id"
        labelKey="name"
        options={readOnlyOption}
        isLoading={workspaceRolesQuery?.loading}
        validate={validateWorkspaceFields}
        disabled
        input={{
          value: readOnlyWorkspaceRoleId,
          name: 'workspaceRoleReadOnly',
          initialValue: head(readOnlyOption),
        }}
      />
    );
  };

  const getWorkspaceRoleField = () => {
    if (isLimitedSelected && isWorkspaceSelected) {
      return renderReadOnlyWorkspaceRoleField();
    }

    if (isWorkspaceSelected) {
      return renderWorkspaceRoleField();
    }

    return null;
  };

  return (
    <>
      <h3 className={style.SectionTitleWorkspaceGroups}>
        <Message id="Workspaces" comment="Section title in invite user modal" />
      </h3>
      <p className={style.Description}>
        <Message
          id="Select one or more workspaces and specify the user's role for these workspaces"
          comment="Section header in invite user modal."
        />
      </p>
      <div className={clsx(style.Row, style.WorkspaceRow)}>
        <div className={style.WorkspaceField}>
          <Field
            name="workspaces"
            data-testid="workspaces-field"
            label={message({
              id: 'Workspaces',
              comment: 'Field label in invite user modal.',
            })}
            placeholder={(
              <Message
                id="Select workspaces"
                comment="Field placeholder in invite user modal."
              />
            )}
            component={SelectField}
            multi
            valueKey="id"
            labelKey="name"
            closeOnSelect={false}
            options={workspacesList}
            isLoading={workspacesQuery?.loading}
            validate={validateWorkspaceFields}
            displayErrorEarly
            searchable
            onInputChange={getWorkspacesOnInputChange}
            onChange={onWorkspaceChange}
            loadMoreItems={onLoadMoreWorkspaces}
          />
          <OnChange name="workspaces">
            {(value: Oneflow.Workspace[]) => {
              if (isEmpty(value) && isLimitedSelected) {
                setModalConfig({ ...modalConfig, readOnlyWorkspaceRoleId: null });
              }
              if (!isEmpty(value) && isLimitedSelected) {
                setModalConfig({
                  ...modalConfig,
                  readOnlyWorkspaceRoleId: USER_WORKSPACE_ROLE_READ_ONLY,
                });
              }
            }}
          </OnChange>
        </div>
        {getWorkspaceRoleField()}
      </div>
    </>
  );
};

export default WorkspaceSection;
