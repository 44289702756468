// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import AddText from 'components/icons/add-text';
import ToolbarButton from 'components/rich-text-editor-toolbars/toolbar-buttons/toolbar-button.jsx';

type Props = {
  toggleTitle: Function,
  isActive: boolean,
}

const AddTitle = ({ toggleTitle, isActive }: Props) => (
  <ToolbarButton
    label={(
      <Message
        id="Add title"
        comment="The label of the video's toolbar that adds a title for video."
      />
      )}
    isMenuItem={false}
    onTrigger={toggleTitle}
    icon={AddText}
    isActive={isActive}
  />
);

export default AddTitle;
