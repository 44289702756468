// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import BreakCenter from 'components/icons/break-center';
import BreakLeft from 'components/icons/break-left';
import BreakRight from 'components/icons/break-right';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { isBreakTextActive, toggleBreakText } from './break-plugin';
import ToolbarButton from '../toolbar-button';

type Props = {
  direction: 'left' | 'right' | 'center',
};

const getLabel = (direction: 'left' | 'right' | 'center') => {
  if (direction === 'left') {
    return (
      <Message
        id="Break text left"
        comment="The label of the editor's image toolbar plugin that breaks the text and positions image to the left."
      />
    );
  }

  if (direction === 'center') {
    return (
      <Message
        id="Break text center"
        comment="The label of the editor's image toolbar plugin that breaks the text and positions image in the center."
      />
    );
  }

  return (
    <Message
      id="Break text right"
      comment="The label of the editor's image toolbar plugin that breaks the text and positions image to the right."
    />
  );
};

const getIcon = (direction: 'left' | 'right' | 'center') => {
  if (direction === 'center') {
    return <BreakCenter />;
  }

  if (direction === 'right') {
    return <BreakRight />;
  }

  return <BreakLeft />;
};

const BreakText = ({ direction }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      isActive={isBreakTextActive(editor, direction)}
      label={getLabel(direction)}
      onTrigger={() => {
        focusEditor();
        toggleBreakText(editor, direction);
      }}
      icon={getIcon(direction)}
    />
  );
};

export default BreakText;
