import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

// eslint-disable-next-line import/named
import { Table } from 'components/table';

import styles from './user-account-access.module.scss';
import { renderRoleName, renderPermissionCount } from './helpers';

export type Query = {
  count: number,
  error?: unknown,
  loading: boolean,
  pagination: { limit: number, offset: number },
  params: object,
  result: number[],
  sort: string[],
  status: string,
};

type Props = {
  query: Query,
  message: MessageTranslator,
  items: Oneflow.AccountGroupRoleBinding,
  children: ReactNode,
}
const GroupAccessTable = ({
  query, message, items, children,
}: Props) => {
  const getGroupAccessTableConfig = () => {
    const columns = [
      {
        name: 'account role',
        label: message({
          id: 'Account role',
          comment: 'Column header for the account access list in the user pages.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountGroupRoleBinding) => (
          <Link to={`/admin/roles/${item.role.id}`} className={styles.Name}>
            {renderRoleName(item.role)}
          </Link>
        ),
      },
      {
        name: 'permissions',
        label: message({
          id: 'Permissions',
          comment: 'Column header for the account access list in the user pages.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountGroupRoleBinding) => renderPermissionCount(item.role),
      },
      {
        name: 'assigned via group',
        label: message({
          id: 'Assigned via group',
          comment: 'Column header for the account access list in the user pages.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountGroupRoleBinding) => (
          <Link to={`/admin/groups/${item.group.id}`} className={styles.Name}>
            {item.group.name}
          </Link>
        ),
      },
    ];

    return {
      items,
      itemKey: 'id',
      columns,
    };
  };

  return (
    <>
      <div className={styles.ContentHeader}>
        <h2 className={styles.Header}>
          <Message id="Group access" comment="Title, above the table." />
        </h2>
        <p>
          <Message
            id="Account roles assigned to the user via group membership."
            comment="Help text for the account access list in the user pages, groups table."
          />
        </p>
      </div>
      <Table
        config={getGroupAccessTableConfig()}
        query={query}
      />
      {children}
    </>
  );
};

export default GroupAccessTable;
