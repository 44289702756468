const getHref = (
  isPristine: boolean,
  agreementId: string,
) => {
  if (!isPristine) {
    return undefined;
  }

  return `/documents/${agreementId}/video`;
};
export default getHref;
