// @flow

import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FadeIn } from 'components/transitions';
import { localize } from '@oneflowab/pomes';
import clsx from 'clsx';

import {
  loadSupport,
  getSupportLoadedSelector,
  getSupportIsLoadingSelector,
} from 'reducers/freshworks';

import CircularSpinner from 'components/icons/circular-spinner';
import QuestionCircle from 'components/icons/question-circle';
import SidebarTooltip from 'components/sidebar/sidebar-tooltip';
import type { MessageTranslator } from '@oneflowab/pomes';

import { getHelpCenterBaseUrl } from 'utils/help-center';

import style from './support-button.module.scss';

export type Props = {
  expanded?: boolean,
  isFreshworksAllowed: boolean,
  isMobile: boolean,
  message: MessageTranslator,
  onItemClick: () => void,
  tooltipText?: string,
};

export const SupportButtonComponent = (props: Props) => {
  const {
    expanded,
    isFreshworksAllowed,
    isMobile,
    message,
    onItemClick,
    tooltipText,
  } = props;
  const dispatch = useDispatch();
  const isSupportLoaded = useSelector(getSupportLoadedSelector);
  const isSupportLoading = useSelector(getSupportIsLoadingSelector);

  const toggleFreshchat = useCallback(() => {
    if (!isFreshworksAllowed || !window.fcWidget) {
      window.open(getHelpCenterBaseUrl(), '_blank');
      return;
    }

    if (document.querySelector('.fc-widget-open')) {
      window.fcWidget.close();
    } else {
      window.fcWidget.open();
    }
  }, [isFreshworksAllowed]);

  useEffect(() => {
    if (isSupportLoaded) {
      toggleFreshchat();
    }
  }, [isSupportLoaded, toggleFreshchat]);

  const handleSupport = () => {
    if (isSupportLoading) {
      return;
    }

    if (onItemClick) {
      onItemClick();
    }

    if (!isSupportLoaded && isFreshworksAllowed) {
      dispatch(loadSupport());
      return;
    }

    toggleFreshchat();
  };

  const handleKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    handleSupport();
  };

  const handleOnClick = (event) => {
    event.preventDefault();
    handleSupport();
  };

  const getIcon = () => {
    if (isSupportLoading) {
      return (
        <CircularSpinner height="20px" />
      );
    }

    return <QuestionCircle height="20px" />;
  };

  const renderButton = () => {
    const menuItemClassNames = clsx(style.MenuItem, {
      [style.Mobile]: isMobile,
    });

    const textClassNames = clsx(style.MenuItemText, {
      [style.WithIcon]: true,
    });

    return (
      <span className={menuItemClassNames}>
        {getIcon()}
        {(isMobile || expanded) && tooltipText ? (
          <FadeIn in timeout={0} transitionTime={500}>
            <span className={textClassNames} id="sidebar-option-label-support">
              {message({
                id: 'Help',
                comment: 'Tooltip for help center',
              })}
            </span>
          </FadeIn>
        ) : null}
      </span>
    );
  };

  if (!isFreshworksAllowed) {
    return renderButton();
  }

  if (isMobile || expanded) {
    return (
      <div
        onClick={handleOnClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        className={style.Link}
        aria-labelledby="sidebar-option-label-support"
        data-testid="support-freshdesk"
      >
        {renderButton()}
      </div>
    );
  }

  return (
    <SidebarTooltip sideOffset={15} tooltipText={tooltipText}>
      <div
        onClick={handleOnClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        className={style.Link}
        aria-label={message({
          id: 'Help',
          comment: 'Tooltip for help center',
        })}
        data-testid="support-freshdesk"
      >
        {renderButton()}
      </div>
    </SidebarTooltip>
  );
};

export default localize<Props>(SupportButtonComponent);
