// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import type { MessageTranslator } from '@oneflowab/pomes';
import isEqual from 'lodash/isEqual';

import { isUserLimited } from 'user';
import Message from 'components/message';
import { Table } from 'components/table';
import ActionBar from 'components/action-bar';
import { ActionBarText } from 'components/action-bar-text';
import adminPage from 'hocs/admin-page';
import AssignUserToWorkspaces from 'components/modals/assign-user-to-workspaces';
import Conditional from 'components/conditional';
import Divider from 'components/divider';
import NoWorkspacesIcon from 'components/icons/no-workspaces';
import Pagination from 'components/pagination';
import ActionsMenu from 'components/actions-menu';
import { EditWorkspaceGroupRole, EditWorkspaceUserRole } from 'components/modals/edit-workspace-role';
import { RemoveUserFromWorkspace } from 'components/modals/remove-user-from-workspace';
import RemoveGroupFromWorkspace from 'components/modals/remove-group-from-workspace';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { EditMenuItem } from 'components/menu-items/edit';
import { checkAcl } from 'components/acl';
import AlertIcon from 'components/icons/alert';

import style from './user-workspace-access.module.scss';

const USER_WORKSPACES_ACCESS_QUERY = 'userWorkspaces/groupAccess';

export type Props = {
  groups: Array<Group>,
  groupWorkspaces: Array<Workspace>,
  userWorkspaces: Array<Workspace>,
  position: Position,
  queryGroupWorkspaces: Function,
  queryUserWorkspaces: Function,
  groupAccessQuery: Query,
  directAccessQuery: Query,
  message: MessageTranslator,
  assignUserWorkspaces: RpcState,
  DIRECT_ACCESS_QUERY: string,
  GROUP_ACCESS_QUERY: string,
};

export class UserWorkspaceAccessComponent extends React.Component<Props> {
  componentDidMount() {
    const {
      directAccessQuery,
      groups,
      queryUserWorkspaces,
    } = this.props;

    if (groups.length) {
      this.getGroupWorkspaces();
    }

    queryUserWorkspaces({ pagination: directAccessQuery.pagination });
  }

  componentDidUpdate(prevProps: Props) {
    const {
      position,
      groups,
      assignUserWorkspaces,
      queryUserWorkspaces,
      directAccessQuery,
    } = this.props;
    const hasGroupsChanged = !isEqual(prevProps.groups, groups);

    if (hasGroupsChanged && groups.length && !isUserLimited(position)) {
      this.getGroupWorkspaces();
    }

    if (assignUserWorkspaces.success && !prevProps.assignUserWorkspaces.success) {
      queryUserWorkspaces({ pagination: directAccessQuery.pagination });
    }
  }

  getGroupWorkspaces() {
    const { queryGroupWorkspaces, groupAccessQuery } = this.props;
    queryGroupWorkspaces({ pagination: groupAccessQuery.pagination });
  }

  getGroupTableItems() {
    const { groupWorkspaces } = this.props;
    return groupWorkspaces;
  }

  getGroupTableConfig() {
    const { message } = this.props;
    const actions = [];

    const columns = [
      {
        name: 'workspace name',
        label: message({
          id: 'Workspace name',
          comment: 'Column header for the workspace access list in the user pages.',
        }),
        type: 'cell',
        value: (item) => (
          <Link to={`/admin/workspaces/${item.resource.id}`} className={style.Name}>
            {item.resource.name}
          </Link>
        ),
      },
      {
        name: 'workspace role',
        label: message({
          id: 'Workspace role',
          comment: 'Column header for roles of a user in group',
        }),
        type: 'cell',
        value: (item) => (
          <span className={style.GroupRoles}>
            <Link className={style.RoleLink} to={`/admin/roles/${item.role.id}`}>
              {item.role.name}
            </Link>
          </span>
        ),
      },
      {
        name: 'groups',
        label: message({
          id: 'Access via group',
          comment: 'Column header for the workspace access list in the user pages.',
        }),
        type: 'cell',
        value: (item) => (
          <span className={style.GroupLink}>
            <Link to={`/admin/groups/${item.group.id}`}>{item.group.name}</Link>
          </span>
        ),
      },
      {
        name: 'permissions',
        label: message({ id: 'Permissions', comment: 'Column label in users page' }),
        type: 'cell',
        value: (item) => (
          <span>
            {`${item.role.permissionCount}/${item.role.maxPermissionCount}`}
          </span>
        ),
      }];

    return {
      items: this.getGroupTableItems(),
      itemKey: 'id',
      actions,
      columns,
    };
  }

  getUserTableConfig() {
    const { message } = this.props;
    const actions = [];

    const columns = [
      {
        name: 'workspace name',
        label: message({
          id: 'Workspace name',
          comment: 'Column header for the workspace access list in the user pages.',
        }),
        type: 'cell',
        value: (item) => (
          <Link to={`/admin/workspaces/${item.resource.id}`} className={style.Name}>
            {item.resource.name}
          </Link>
        ),
      },
      {
        name: 'workspace-role',
        label: message({
          id: 'Workspace role',
          comment: 'Column header for the workspace access list in the user pages.',
        }),
        type: 'cell',
        value: (item) => (
          <span className={style.GroupLink}>
            <Link key={item.id} to={`/admin/roles/${item.role.id}`}>
              {item.role.name}
            </Link>
          </span>
        ),
      },
      {
        name: 'permissions',
        label: message({ id: 'Permissions', comment: 'Column header for the permissions count in that role in the user pages.' }),
        type: 'cell',
        value: (item) => (
          <span>
            {`${item.role.permissionCount}/${item.role.maxPermissionCount}`}
          </span>
        ),
      },
      {
        name: 'actions',
        label: message({ id: 'Actions', comment: 'Column label in users page' }),
        type: 'actions',
        value: (item) => this.getActionsForUserAccess(item),
      },
    ];

    return {
      items: this.props.userWorkspaces,
      itemKey: 'id',
      actions,
      columns,
    };
  }

  getEmptyState() {
    const { message } = this.props;

    return {
      header: message({
        id: "This user doesn't have access to any workspaces",
        comment: 'Empty state header for the workspace access list in the user pages.',
      }),
      icon: <NoWorkspacesIcon height="33px" />,
      showEmptyState: this.shouldShowEmptyState(),
    };
  }

  shouldShowEmptyState() {
    const {
      groupAccessQuery,
      directAccessQuery,
    } = this.props;

    return (
      groupAccessQuery.count === 0
      && directAccessQuery.count === 0
      && !groupAccessQuery.loading
      && !directAccessQuery.loading
    );
  }

  changePaginationHandler(type, pagination) {
    if (type === 'group') {
      this.props.queryGroupWorkspaces({
        pagination,
      });
    } else if (type === 'position') {
      this.props.queryUserWorkspaces({
        pagination,
      });
    }
  }

  renderPagination(type) {
    const { message } = this.props;
    const query = type === 'group' ? this.props.groupAccessQuery : this.props.directAccessQuery;
    return (
      <Conditional ifCondition={!query.loading && query.count > 0}>
        <Pagination
          totalItems={query.count}
          itemsPerPage={query.pagination.limit}
          currentOffset={query.pagination.offset}
          onChange={(params) => this.changePaginationHandler(type, params)}
          entityName={message({
            id: 'workspaces',
            comment: 'Plural form in pagination of workspaces in the user pages.',
          })}
        />
      </Conditional>
    );
  }

  renderGroupSection() {
    const { groups, groupAccessQuery } = this.props;

    if (!groups.length) {
      return null;
    }

    return (
      <>
        <Divider />
        <div className={style.ContentHeader}>
          <h2 className={style.Header}>
            <Message id="Group access" comment="Title, above a table." />
          </h2>
          <p>
            <Message
              id="Workspaces access granted to the user via group membership."
              comment="Help text for the workspace access list in the user pages."
            />
          </p>
        </div>
        <Table config={this.getGroupTableConfig()} query={groupAccessQuery} />
        {this.renderPagination('group')}
      </>
    );
  }

  getEditMenuItem = (permission: boolean) => (onClick: () => void) => (
    <EditMenuItem
      onClick={onClick}
      disabled={!permission}
    />
  );

  getRemoveMenuItem = (permission: boolean) => (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!permission}
    />
  )

  getActionBar = (position: Position) => {
    const renderActionBarText = () => {
      if (isUserLimited(position)) {
        return (
          <>
            <AlertIcon height="14px" className={style.LimitedAccessIcon} />
            <Message
              id="Limited access users can only view, sign, and comment on documents, regardless of their roles."
              comment="Header text for limited access user in user account access tab"
            />
          </>
        );
      }
      return null;
    };

    return (
      <ActionBar collapsed>
        <ActionBar.Group>
          <div className={style.Buttons}>
            <AssignUserToWorkspaces position={position} />
          </div>
          <ActionBarText
            header={(
              <Message
                id="Manage access to workspaces for this user"
                comment="Header text for the workspace access list in the user pages."
              />
            )}
            text={renderActionBarText()}
          />
        </ActionBar.Group>
      </ActionBar>
    );
  };

  getActionsForUserAccess = (binding) => {
    const {
      message,
      position,
      DIRECT_ACCESS_QUERY,
    } = this.props;
    const workspace = binding.resource;
    const { role } = binding;
    const hasEditUserRolePermission = checkAcl(position.acl, 'position:collection:binding:create') && !isUserLimited(position);
    const hasRemoveUserAccessPermission = checkAcl(position.acl, 'position:collection:binding:remove');

    return (
      <ActionsMenu
        actions={[
          <EditWorkspaceUserRole
            title={message({
              id: 'Edit user role',
              comment: 'Modal title for changing workspace access role.',
            })}
            workspaceId={workspace.id}
            position={position}
            role={role}
            bindingId={binding.id}
          >
            {this.getEditMenuItem(hasEditUserRolePermission)}
          </EditWorkspaceUserRole>,
          <RemoveUserFromWorkspace
            pipeAction={DIRECT_ACCESS_QUERY}
            workspace={workspace}
            position={position}
            bindingId={binding.id}
            roleId={role.id}
          >
            {this.getRemoveMenuItem(hasRemoveUserAccessPermission)}
          </RemoveUserFromWorkspace>,
        ]}
        focusOnCloseDisabled
      />
    );
  };

  getActionsForGroupAccess = (binding) => {
    const {
      message,
      position,
      GROUP_ACCESS_QUERY,
    } = this.props;
    const { group } = binding;
    const hasEditGroupRolePermission = checkAcl(group.acl, 'group:collection:binding:create');
    const hasRemoveGroupAccessPermission = checkAcl(group.acl, 'group:collection:binding:remove');

    return (
      <ActionsMenu
        actions={[
          <EditWorkspaceGroupRole
            title={message({
              id: 'Edit group role',
              comment: 'Modal title for changing workspace access role.',
            })}
            workspaceId={binding.resource.id}
            group={group}
            role={binding.role}
            bindingId={binding.id}
            pipeAction={USER_WORKSPACES_ACCESS_QUERY}
            positionId={position.id}
          >
            {this.getEditMenuItem(hasEditGroupRolePermission)}
          </EditWorkspaceGroupRole>,
          <RemoveGroupFromWorkspace
            position={position}
            pipeAction={GROUP_ACCESS_QUERY}
            workspace={binding.resource}
            group={group}
            bindingId={binding.id}
            roleId={binding.role.id}
          >
            {this.getRemoveMenuItem(hasRemoveGroupAccessPermission)}
          </RemoveGroupFromWorkspace>,
        ]}
      />
    );
  };

  render() {
    const { position } = this.props;

    return (
      <div className={style.Workspaces}>
        {this.getActionBar(position)}
        <div>
          <Conditional ifCondition={!this.shouldShowEmptyState()}>
            <div className={style.ContentHeader}>
              <h2 className={style.Header}>
                <Message id="Direct access" comment="Title, above a table." />
              </h2>
              <p>
                <Message
                  id="Workspaces access granted directly to the user."
                  comment="Help text for the workspace access list in the user pages."
                />
              </p>
            </div>
          </Conditional>
          <Table
            config={this.getUserTableConfig()}
            query={this.props.directAccessQuery}
            emptyState={this.getEmptyState()}
          />
          <Conditional ifCondition={!this.shouldShowEmptyState()}>
            {this.renderPagination('position')}
            {this.renderGroupSection()}
          </Conditional>
        </div>
      </div>
    );
  }
}

type MapperProps = {
  props: {
    [any]: any,
    message: MessageTranslator,
  },
};

export const propsMapper = ({ props: { message } }: MapperProps) => ({
  title: message({
    id: 'Workspace access',
    comment: 'Page title for the workspace access list in the user pages.',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(UserWorkspaceAccessComponent);
