import { useMemo } from 'react';

import useCurrentBoxes from 'hooks/use-current-boxes';
import useBoxIdMap from 'hooks/use-box-id-map';
import type { Box } from 'data-validators/entity-schemas/document-box';

import { getCurrentBox } from 'components/contract-boxes/generic-box-helpers';

const useCurrentBox = <T extends Box>(boxId: number): T | null => {
  const boxes = useCurrentBoxes();
  const idMap = useBoxIdMap();

  return useMemo(() => {
    const currentBox = getCurrentBox(boxes, boxId);

    if (currentBox) {
      return currentBox as T;
    }

    if (idMap?.[boxId]) {
      return getCurrentBox(boxes, idMap[boxId]) as T;
    }

    return null;
  }, [boxId, boxes, idMap]);
};

export default useCurrentBox;
