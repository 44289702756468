// @flow

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import isEmpty from 'lodash/isEmpty';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { getAccountFromSessionSelector } from 'reducers/session';
import AIAssistFieldButton from 'components/rich-text-editor-toolbars/toolbar-buttons/ai-assist-field-button';

import {
  isAgreementOwner,
} from 'agreement/selectors';

import { RadioGroup } from 'components/radio-group';
import { RadioGroupItem } from 'components/radio-group-item';

import {
  MessageTemplateSubjectField,
  MessageTemplateBodyField,
} from 'components/fields';

import SelectField from 'components/select-field';
import style from './message-template-picker.module.scss';

export type Props = {
  message: MessageTranslator,
  contractMessageTemplates: Array<MessageTemplate>,
  updateSelectedMessageTemplate: Function,
  selectedMessageTemplate: MessageTemplate,
  isSubjectFieldRequired: boolean,
  isMessageFieldRequired: boolean,
  autoFocus?: boolean,
  getMessageBody?: Function,
  isReminder?: boolean,
  signature?: String,
  checkForAgreementOwner?: boolean,
  agreement?: Agreement,
  context?: string,
};

export const MessageTemplatePicker = ({
  message,
  autoFocus,
  contractMessageTemplates,
  selectedMessageTemplate,
  updateSelectedMessageTemplate,
  isSubjectFieldRequired,
  isMessageFieldRequired,
  getMessageBody,
  isReminder,
  signature,
  checkForAgreementOwner,
  agreement,
  context,
}: Props) => {
  const form = useForm();
  const formState = useFormState();

  const accountFromSession = useSelector((state) => (
    getAccountFromSessionSelector(state)
  ));

  useEffect(() => {
    if (selectedMessageTemplate) {
      form.batch(() => {
        form.change('subject', selectedMessageTemplate.subject);
        form.change('body', (selectedMessageTemplate.body));
      });
    }
  }, [selectedMessageTemplate, form]);

  const getPositionMessageTemplates = () => contractMessageTemplates.filter<MessageTemplate>(
    (messageTemplate) => !isEmpty(messageTemplate.position),
  );

  const getWorkspaceMessageTemplates = () => contractMessageTemplates.filter<MessageTemplate>(
    (messageTemplate) => !isEmpty(messageTemplate.collection),
  );

  const getDefaultEmptyTemplate = () => contractMessageTemplates.filter(
    (messageTemplate) => messageTemplate?.id === 'standard_message',
  );

  const isTemplateStringPristine = (
    string,
    selectedString,
  ) => (string && string === selectedString);

  const areFieldsPristine = () => {
    const { body, subject } = formState.values;
    const isSubjectPristine = isTemplateStringPristine(subject, selectedMessageTemplate?.subject);

    if (signature) {
      return isTemplateStringPristine(body?.split(`\n\n${signature}`)[0], selectedMessageTemplate?.body) && isSubjectPristine;
    }

    return isTemplateStringPristine(body, selectedMessageTemplate?.body) && isSubjectPristine;
  };

  const getGroupedOptions = () => {
    const defaultEmptyTemplate = getDefaultEmptyTemplate();
    const positionMessageTemplates = getPositionMessageTemplates();
    const workspaceMessageTemplates = getWorkspaceMessageTemplates();

    const groupedOptions = defaultEmptyTemplate || [];

    if (positionMessageTemplates) {
      groupedOptions.push({
        label: message({
          id: 'Your message templates',
          comment: 'Label text in dropdown',
        }),
        options: positionMessageTemplates,
      });
    }

    if (workspaceMessageTemplates) {
      groupedOptions.push({
        label: message({
          id: 'Workspace message templates',
          comment: 'Label text in dropdown',
        }),
        options: workspaceMessageTemplates,
      });
    }

    return groupedOptions;
  };

  const onMessageTemplateChange = (selectedTemplate: MessageTemplate) => {
    updateSelectedMessageTemplate(selectedTemplate);

    if (selectedTemplate) {
      if (!areFieldsPristine()) {
        form.batch(() => {
          form.change('subject', selectedTemplate.subject);
          form.change('body', (selectedTemplate.body));
        });
      }

      if (isReminder) {
        form.batch(() => {
          form.change('subject', selectedTemplate.subject);
          form.change('body', getMessageBody(selectedTemplate));
        });
      }
    } else {
      form.reset();
    }
  };

  const renderMessageTemplateContentFields = () => (
    <>
      <div className={style.SubjectField}>
        <MessageTemplateSubjectField required={isSubjectFieldRequired} autoFocus={autoFocus} />
      </div>
      <div className={style.AIAssistWrapper}>
        <div className={style.AIAssistContainer}>
          <AIAssistFieldButton
            context={context}
            onInsertGeneratedText={(text) => form.change('body', text)}
          />
        </div>
      </div>
      <MessageTemplateBodyField required={isMessageFieldRequired} />
    </>
  );

  const renderMessageTemplateSelectField = () => {
    const isOwner = agreement && isAgreementOwner(accountFromSession, agreement);
    if ((checkForAgreementOwner && !isOwner) || isEmpty(contractMessageTemplates)) {
      return null;
    }

    const input = {
      onChange: onMessageTemplateChange,
      value: areFieldsPristine() ? selectedMessageTemplate?.id : null,
      name: 'messageTemplateSelectField',
    };

    return (
      <SelectField
        label={
          message({
            id: 'Select message template',
            comment: 'Label for the type field in the SSO settings modal.',
          })
        }
        clearable
        multi={false}
        searchable={false}
        labelKey="name"
        valueKey="id"
        input={input}
        options={getGroupedOptions()}
      />
    );
  };

  const [messageType, setMessageType] = useState('Template');

  const onChange = (type) => {
    setMessageType(type);

    form.batch(() => {
      form.change('body', undefined);
      form.change('subject', undefined);
    });
  };

  const renderMessagePicker = () => {
    const isOwner = agreement && isAgreementOwner(accountFromSession, agreement);
    if ((checkForAgreementOwner && !isOwner) || isEmpty(contractMessageTemplates)) {
      return null;
    }

    return (
      <div className={style.Message}>
        <RadioGroup
          name="MessageTypes"
          defaultValue="Template"
          onChange={onChange}
        >
          <RadioGroupItem
            className={style.MessageItem}
            value="Template"
            label={message({
              id: 'Use template',
              comment: 'Label for radio button',
            })}
          />
          <RadioGroupItem
            className={style.MessageItem}
            value="Custom"
            label={message({
              id: 'Create custom message',
              comment: 'Label for radio button',
            })}
          />
        </RadioGroup>
      </div>
    );
  };

  const renderMessageTemplate = () => {
    if (messageType === 'Template') {
      return (
        <div>
          {renderMessageTemplateSelectField()}
          {renderMessageTemplateContentFields()}
        </div>
      );
    }

    return renderMessageTemplateContentFields();
  };

  return (
    <div>
      {renderMessagePicker()}
      {renderMessageTemplate()}
    </div>
  );
};

MessageTemplatePicker.defaultProps = {
  autoFocus: undefined,
  isReminder: false,
  getMessageBody: () => {},
  context: undefined,
};

export default localize<Props>(MessageTemplatePicker);
