// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

type Props = {
  group: Group,
  position: Position,
  removeState: RemoveState,
  resetRemoveState: Function,
  removeMember: () => void,
  children: Function,
};

export const handleConfirm = (removeMember: Function, isLoading: boolean) => () => {
  if (isLoading) {
    return;
  }

  removeMember();
};

export const RemoveMember = ({
  group,
  position,
  removeState,
  removeMember,
  resetRemoveState,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={removeMember}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <Message
        id="You are about to remove {userName} from {groupName}."
        pluralId="You are about to remove {userName} from {count} groups."
        pluralCondition="count"
        values={{
          userName: <Badge label={position.fullname} kind="name" />,
          groupName: <Badge label={group.name} kind="name" />,
          count: 1,
        }}
        comment="Confirmation message in modal. In remove user from group modal."
      />
    )}
    modalKey="remove user from group modal"
  >
    {children}
  </RemoveConfirm>
);
