import { Message } from '@oneflowab/pomes';

import Button from 'components/button';

type Props = {
  onClick: () => void,
};

export const CloseButton = ({ onClick }: Props) => (
  <Button
    kind="linkSeparate"
    data-testid="close"
    onClick={onClick}
  >
    <Message
      id="Close"
      comment="Text for button to close action."
    />
  </Button>
);
