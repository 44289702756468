// @flow

import * as React from 'react';
import DynamicLink from 'components/dynamic-link';
import clsx from 'clsx';

import { Message } from '@oneflowab/pomes';

import { ExtensionName } from 'components/extension/extension-name';
import { ExtensionLogo } from 'components/extension/extension-logo';
import { ExtensionOwner } from 'components/extension/extension-owner';

import rainbow from 'styles/rainbow-shadow.module.scss';

import style from './marketplace-card.module.scss';

export type Props = {
  extension: Extension,
  trackable?: TrackableObject,
};

const MarketplaceCard = ({
  extension,
  trackable,
}: Props) => {
  const renderLabel = () => {
    // Extension is active
    if (extension.state) {
      return (
        <>
          <span className={style.ActiveLabel}>
            <Message
              id="Active"
              comment="Label for active extensions"
            />
          </span>
        </>
      );
    }

    if (!extension.isEntitled) {
      return (
        <>
          <span className={style.EntitlementMissingLabel}>
            <Message
              id="Upgrade"
              comment="Label for extensions you are not entitled to"
            />
          </span>
        </>
      );
    }

    return null;
  };

  const innerContainerclasses = clsx(style.InnerContainer);
  const linkContainerclasses = clsx(style.LinkContainer, rainbow.ShadowOnHover);

  return (
    <div className={style.SectionContainer}>
      <DynamicLink
        url={`/marketplace/${extension.slug}`}
        className={linkContainerclasses}
        trackable={trackable}
      >
        <div className={innerContainerclasses}>
          <div className={style.LogoContainer}>
            <div>
              <ExtensionLogo extension={extension} className={style.LogoSquare} />
            </div>
            <div style={{ flex: 1 }} />
            <div className={style.ActiveLabelContainer}>
              {renderLabel()}
            </div>
          </div>

          <div className={style.ContentContainer}>
            <div className={style.Title}>
              <ExtensionName extension={extension} />
            </div>
            <div className={style.SubTitle}>
              <ExtensionOwner extension={extension} />
            </div>
          </div>

          <div className={style.FooterContainer}>
            <span className={style.ExtensionTag}>CRM</span>
            <span className={style.ExtensionTag}>ATS</span>
            <span className={style.ExtensionTag}>Productivity</span>
          </div>
        </div>
      </DynamicLink>
    </div>
  );
};

export default MarketplaceCard;
