// @flow

import React from 'react';
import clsx from 'clsx';

import { AnnotationContextProvider } from 'contexts/parent-annotation';
import { isComment } from 'comments';
import AnnotationWrapper from './annotation-wrapper';
import style from './annotation.module.scss';

const AnnotationReadOnly = ({
  children,
  annotation,
}: RenderSlateElementProps) => {
  const annotationClasses = clsx({
    [style.CommentAnnotation]: isComment(annotation),
  });

  return (
    <AnnotationContextProvider annotation={annotation} isFocused={false}>
      <AnnotationWrapper
        annotation={annotation}
        className={annotationClasses}
        isReadOnly
      >
        {children}
      </AnnotationWrapper>
    </AnnotationContextProvider>
  );
};

export default AnnotationReadOnly;
