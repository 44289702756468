import { connect } from 'react-redux';

import positionsReducer from 'reducers/entities/positions';
import userWorkspacesReducer from 'reducers/entities/user-workspaces';
import workspaceUsersReducer from 'reducers/entities/workspace-users';

import UserWorkspaceAccess from './user-workspace-access';

export const GROUP_ACCESS_QUERY = 'userWorkspaces/groupAccess';
export const DIRECT_ACCESS_QUERY = 'userWorkspaces/directAccess';

export const mapStateToProps = (state, ownProps) => {
  const position = positionsReducer.getPositionSelector(state, { id: ownProps.match.params.id });
  const groupAccessQuery = userWorkspacesReducer.getQuerySelector(state, {
    name: GROUP_ACCESS_QUERY,
  });
  const directAccessQuery = userWorkspacesReducer.getQuerySelector(state, {
    name: DIRECT_ACCESS_QUERY,
  });

  const userWorkspaces = userWorkspacesReducer.getUserWorkspacesSelector(state, {
    ids: directAccessQuery.result,
    positionId: ownProps.match.params.id,
  });

  const groupWorkspaces = userWorkspacesReducer.getUserWorkspacesSelector(state, {
    ids: groupAccessQuery.result,
    positionId: ownProps.match.params.id,
    actorType: 'group',
  });

  const assignUserWorkspaces = workspaceUsersReducer.getAssignUserWorkspacesSelector(state, {
    id: position.id,
  });

  return {
    position,
    groupWorkspaces,
    groupAccessQuery,
    userWorkspaces,
    directAccessQuery,
    assignUserWorkspaces,
    GROUP_ACCESS_QUERY,
    DIRECT_ACCESS_QUERY,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  queryGroupWorkspaces: ({ pagination } = {}) => {
    dispatch(userWorkspacesReducer.queryUserWorkspaces({
      name: GROUP_ACCESS_QUERY,
      pagination,
      params: {
        positionId: ownProps.match.params.id,
        actorType: 'group',
      },
    }));
  },
  queryUserWorkspaces: ({ pagination } = {}) => {
    dispatch(userWorkspacesReducer.queryUserWorkspaces({
      name: DIRECT_ACCESS_QUERY,
      pagination,
      params: {
        positionId: ownProps.match.params.id,
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserWorkspaceAccess);
