// @flow

import * as React from 'react';

import DynamicLink from 'components/dynamic-link';
import Button from 'components/button';

import style from './my-profile-item.module.scss';

type Props = {
  onClick: () => void,
  link?: string,
  label: string,
  IconComponent: React.ComponentType<IconProps>,
  isContractView?: boolean,
  dataTestId?: string,
  trackable?: string | TrackableObject,
  isExternal?: boolean,
}

class MyProfileItem extends React.PureComponent<Props> {
  static defaultProps = {
    link: undefined,
    trackable: undefined,
  };

  renderIcon = () => {
    const { IconComponent } = this.props;

    return <IconComponent height="14px" />;
  }

  render() {
    const {
      link,
      onClick,
      label,
      isContractView,
      dataTestId,
      trackable,
      isExternal,
    } = this.props;

    let target;
    let rel;
    if (isExternal) {
      target = '_blank';
      rel = 'noopener noreferrer';
    }

    if (isContractView && link) {
      return (
        <a
          className={style.MyProfileItem}
          data-testid={dataTestId}
          href={link}
          onClick={onClick}
          target={target}
          rel={rel}
        >
          <div className={style.Icon}>
            {this.renderIcon()}
          </div>
          <span className={style.Text}>
            {label}
          </span>
        </a>

      );
    }

    if (link) {
      return (
        <DynamicLink
          className={style.MyProfileItem}
          icon={this.renderIcon()}
          onClick={onClick}
          textClass={style.Text}
          url={link}
          trackable={trackable}
          hideExternalIcon
        >
          {label}
        </DynamicLink>
      );
    }

    return (
      <Button
        customClass={style.MyProfileItem}
        icon={this.renderIcon}
        onClick={onClick}
        trackable={trackable}
      >
        {label}
      </Button>
    );
  }
}

export default MyProfileItem;
