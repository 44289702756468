// @flow

import clsx from 'clsx';
import React from 'react';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { Form } from 'react-final-form';

import extensionSettings from 'hocs/extension-settings';

import * as EditableForm from 'components/editable-form';
import Field from 'components/field';
import TextField from 'components/text-field';
import Toggle from 'components/toggle';

import style from './sms.module.scss';

export type Props = {
  extension: Extension,
  updateExtension: Extension => void,
  updateState: UpdateState,
  resetUpdateState: () => void,
  currentLanguage: EnabledLanguages,
  accountCountry: string,
  message: MessageTranslator,
};

type State = {
  isEditMode: boolean,
};

type FormData = {
  displayName: string,
  notificationAgreementPublish: boolean,
  notificationAgreementClose: boolean,
  notificationAgreementMessage: boolean,
  notificationReminderExpired: boolean,
  notificationAgreementUpdate: boolean,
};

export class SmsPage extends React.Component<Props, State> {
  state = {
    isEditMode: false,
  };

  componentDidUpdate() {
    const { isEditMode } = this.state;
    const { updateState } = this.props;

    if (updateState.success && isEditMode) {
      this.setState({ isEditMode: false });
    }
  }

  getPriceInformation = () => {
    const { accountCountry } = this.props;

    switch (accountCountry) {
      case 'SE':
        return { currency: 'SEK', amount: 1 };
      case 'NO':
        return { currency: 'NOK', amount: 1 };
      case 'DK':
        return { currency: 'DKK', amount: 1 };
      default:
        return { currency: 'EUR', amount: 0.12 };
    }
  };

  getLocalizedPrice = () => {
    const { currentLanguage } = this.props;
    const { currency, amount } = this.getPriceInformation();

    return amount.toLocaleString(currentLanguage, {
      style: 'currency',
      currency,
      currencyDisplay: 'code',
      minimumFractionDigits: 0,
    });
  };

  getInitialValues() {
    const {
      displayName,
      notificationAgreementPublish,
      notificationAgreementClose,
      notificationAgreementMessage,
      notificationReminderExpired,
      notificationAgreementUpdate,
    } = this.props.extension.config;

    return {
      displayName,
      notificationAgreementPublish: Boolean(Number(notificationAgreementPublish)),
      notificationAgreementClose: Boolean(Number(notificationAgreementClose)),
      notificationAgreementMessage: Boolean(Number(notificationAgreementMessage)),
      notificationReminderExpired: Boolean(Number(notificationReminderExpired)),
      notificationAgreementUpdate: Boolean(Number(notificationAgreementUpdate)),
    };
  }

  handleSubmit = ({
    displayName,
    notificationAgreementPublish,
    notificationAgreementClose,
    notificationAgreementMessage,
    notificationReminderExpired,
    notificationAgreementUpdate,
  }: FormData) => {
    const { updateExtension, extension } = this.props;

    updateExtension({
      id: extension.extensionId,
      config: {
        ...extension.config,
        displayName: displayName || undefined,
        notificationAgreementPublish: String(Number(notificationAgreementPublish)),
        notificationAgreementClose: String(Number(notificationAgreementClose)),
        notificationAgreementMessage: String(Number(notificationAgreementMessage)),
        notificationReminderExpired: String(Number(notificationReminderExpired)),
        notificationAgreementUpdate: String(Number(notificationAgreementUpdate)),
      },
    });
  };

  setIsEditMode = (isEditMode: boolean) => {
    const { updateState, resetUpdateState } = this.props;

    if (!updateState.pristine) {
      resetUpdateState();
    }

    this.setState({ isEditMode });
  };

  getPlaceholder = () => {
    const { message } = this.props;
    const { isEditMode } = this.state;
    const { displayName } = this.getInitialValues();

    if (isEditMode) {
      return message({
        id: 'Enter display name',
        comment: 'Placeholder for the display name field for the SMS extension.',
      });
    }

    if (!displayName) {
      return '-';
    }

    return null;
  };

  render() {
    const { isEditMode } = this.state;
    const { updateState, resetUpdateState } = this.props;

    const {
      displayName,
      notificationAgreementPublish,
      notificationAgreementClose,
      notificationAgreementMessage,
      notificationReminderExpired,
      notificationAgreementUpdate,
    } = this.getInitialValues();

    return (
      <div className={style.FormContainer}>
        <Form
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={style.Actions}>
                <EditableForm.Actions
                  resetFormState={resetUpdateState}
                  formState={updateState}
                  isEditMode={isEditMode}
                  setIsEditMode={this.setIsEditMode}
                />
              </div>
              <h2 className={style.Header}>
                <Message
                  id="SMS signing"
                  comment="Section header for the SMS extension."
                />
              </h2>
              <p>
                <Message
                  id="Signing with SMS provides an additional layer of identification in signatures. The mobile number a participant uses when signing with an SMS code becomes part of the digital signature. Select SMS verification as the sign method on a participant to let them sign with SMS. A default sign method can be set on each template."
                  comment="Help text for the SMS extension."
                />
              </p>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Display name"
                    comment="Label of the display name field for the SMS extension."
                  />
                </EditableForm.Label>
                <Field
                  name="displayName"
                  component={TextField}
                  value={displayName || '-'}
                  placeholder={this.getPlaceholder()}
                  maxLength={11}
                  minLength={3}
                  pattern={{ regexp: /^[a-zA-Z][a-zA-Z0-9]+$/ }}
                  autoFocus
                  disabled={!isEditMode}
                />
              </div>
              <p className={style.DisplayNameDescription}>
                <Message
                  id="Optional display name shown as the SMS sender when sending contracts. Must be 3-11 characters without whitespace and can only contain letters (A-Z) and numbers and start with a letter."
                  comment="Help text for the SMS extension."
                />
              </p>
              <div className={style.SectionHeader}>
                <Message
                  id="Notifications"
                  comment="Section header for the SMS extension."
                />
              </div>
              <p className={style.NotificationsDescription}>
                <Message
                  id="Send SMS notifications to my counterparties when:"
                  comment="Help text for the SMS extension."
                />
              </p>
              <div className={clsx(style.Row, style.ToggleRow)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="They are invited to the contract"
                    comment="Label of the notification agreement publish toggle for the SMS extension."
                  />
                </EditableForm.Label>
                <Field
                  name="notificationAgreementPublish"
                  component={Toggle}
                  value={notificationAgreementPublish}
                  type="checkbox"
                  version="latest"
                  disabled
                />
              </div>
              <div className={clsx(style.Row, style.ToggleRow)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="Signing completed"
                    comment="Label of the notification agreement close toggle for the SMS extension."
                  />
                </EditableForm.Label>
                <Field
                  name="notificationAgreementClose"
                  component={Toggle}
                  value={notificationAgreementClose}
                  type="checkbox"
                  version="latest"
                  disabled
                />
              </div>
              <div className={clsx(style.Row, style.ToggleRow)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="A comment is added to the contract"
                    comment="Label of the notification agreement message toggle for the SMS extension."
                  />
                </EditableForm.Label>
                <Field
                  name="notificationAgreementMessage"
                  component={Toggle}
                  value={notificationAgreementMessage}
                  type="checkbox"
                  version="latest"
                  disabled={!isEditMode}
                />
              </div>
              <div className={clsx(style.Row, style.ToggleRow)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="3 days before signing period expires"
                    comment="Toggle label for signing period expiration reminder, used in the SMS extension."
                  />
                </EditableForm.Label>
                <Field
                  name="notificationReminderExpired"
                  component={Toggle}
                  value={notificationReminderExpired}
                  type="checkbox"
                  version="latest"
                  disabled={!isEditMode}
                />
              </div>
              <div className={clsx(style.Row, style.ToggleRow)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="The content in the contract is changed"
                    comment="Label of the notification agreement update toggle for the SMS extension."
                  />
                </EditableForm.Label>
                <Field
                  name="notificationAgreementUpdate"
                  component={Toggle}
                  value={notificationAgreementUpdate}
                  type="checkbox"
                  version="latest"
                  disabled={!isEditMode}
                />
              </div>
            </form>
          )}
        />
        <div className={style.SectionHeader}>
          <Message
            id="SMS notification settings"
            comment="Section header for the SMS extension."
          />
        </div>
        <p>
          <Message
            id="You have the option to send contract invitations and notifications via SMS. Select SMS as the delivery channel on a counterpart to enable SMS notifications. A default delivery channel can be set on each template."
            comment="Help text for the SMS extension."
          />
        </p>
        <div className={style.SectionHeader}>
          <Message
            id="Details"
            comment="Section header for the SMS extension."
          />
        </div>
        <p>
          <Message
            id="An SMS notification, invitation or code typically consists of 1 - 2 SMS messages and each SMS will incur a cost of {price}. Your account will be billed with the total at the end of each month, quarter or year, depending on volume."
            values={{
              price: <span className={style.Price}>{this.getLocalizedPrice()}</span>,
            }}
            comment="Help text for the SMS extension."
          />
        </p>

      </div>
    );
  }
}

export default extensionSettings(SmsPage);
