import clsx from 'clsx';
import take from 'lodash/take';
import uniqueId from 'lodash/uniqueId';
import head from 'lodash/head';

import { getAgreementMyParticipant } from 'agreement/selectors';
import { getPendingStateApproverParticipants } from 'agreement/pending-state-flow';

import useAgreement from 'hooks/use-agreement';

import { getParticipantInternalApprovers, hasDraftApprovalFlow } from 'agreement';

import { UserBadge } from 'components/user-badge';
import ParticipantBubble, { SimpleBubble } from 'components/contract-card/participant-bubble';

import style from './approve-bubbles.module.scss';

type Props = {
  agreementId: number;
};

type RenderParticipantBubbleOptions = {
  index: number;
  hasApproved?: boolean;
};

const MAX_VISIBLE_PARTICIPANTS = 4;
const NUMBER_OF_PARTICIPANTS_WHEN_LIMITED = MAX_VISIBLE_PARTICIPANTS - 1;

export const ApproveBubbles = ({
  agreementId,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const myParticipant = getAgreementMyParticipant(agreement);
  const isDraftApproval = hasDraftApprovalFlow(agreement);

  const draftApprovers = getParticipantInternalApprovers(agreement);
  const draftApproversWithoutMe = draftApprovers ? draftApprovers
    .filter((approver) => approver?.id !== myParticipant?.id) : null;

  const externalApprovers = getPendingStateApproverParticipants(agreement);
  const externalApproversWithoutMe = externalApprovers ? externalApprovers
    .filter((approver) => approver?.id !== myParticipant?.id) : null;

  const myApprovalStatus = () => {
    const action = head(myParticipant?.actions);
    const hasApproved: boolean = action?.status === 'SUCCEEDED';

    return hasApproved;
  };

  const getApprovedDraftApprovers = () => {
    if (!draftApproversWithoutMe) {
      return null;
    }

    return (
      draftApproversWithoutMe.filter((approver: AgreementParticipant) => {
        const action = head(approver?.actions);
        const hasApproved: boolean = action?.status === 'SUCCEEDED';
        return hasApproved;
      }));
  };

  const getPendingDraftApprovers = () => {
    if (!draftApproversWithoutMe) {
      return null;
    }

    return (
      draftApproversWithoutMe.filter((approver: AgreementParticipant) => {
        const action = head(approver?.actions);
        const pending: boolean = action?.status === 'RUNNING' || action?.status === 'NOT_STARTED';
        return pending;
      }));
  };

  const getApprovedExternalApprovers = () => {
    if (!externalApproversWithoutMe) {
      return null;
    }

    return (
      externalApproversWithoutMe.filter((approver: AgreementParticipant) => {
        const hasApproved = approver?.actions.some((action) => action.status === 'SUCCEEDED');
        return hasApproved;
      })
    );
  };

  const getPendingExternalApprovers = () => {
    if (!externalApproversWithoutMe) {
      return null;
    }

    return (
      externalApproversWithoutMe.filter((approver: AgreementParticipant) => {
        const pending = approver?.actions.some((action) => action.status === 'RUNNING' || action.status === 'NOT_STARTED');

        return pending;
      })
    );
  };

  const approvedExternalApprovers = getApprovedExternalApprovers();
  const pendingExternalApprovers = getPendingExternalApprovers();

  const approvedDraftApprovers = getApprovedDraftApprovers();
  const pendingDraftApprovers = getPendingDraftApprovers();
  const myParticipantHasApproved = myApprovalStatus();

  const renderApproverBubble = (
    approver: Oneflow.Participant,
    {
      index,
      hasApproved,
    }: RenderParticipantBubbleOptions,
  ) => {
    const badgeClasses = clsx(style.Badge, {
      [style.Approved]: hasApproved,
    });

    const renderBadge = () => (
      <div
        className={badgeClasses}
      >
        <UserBadge
          diameter={46}
          borderSize={2}
          fillColor={hasApproved ? '#8638E5' : 'FFFFF'}
          borderColor={hasApproved ? '#BD96ED' : '#8638E5'}
        />
      </div>
    );

    const participantBubbleClasses = clsx({
      [style[`BadgeI${MAX_VISIBLE_PARTICIPANTS - index}`]]: hasApproved,
    });

    return (
      <ParticipantBubble
        data-testid="participant-bubble"
        participant={approver}
        renderBadge={renderBadge}
        className={participantBubbleClasses}
        agreement={agreement}
      />
    );
  };

  const renderExtraBubble = (approvers: Oneflow.Participant[], approvedBubble?: boolean) => {
    const renderDefaultBadge = () => (
      <div className={style.SimpleBubbleUserBadge}>
        <UserBadge
          diameter={46}
          borderSize={2}
          fillColor="FFFFF"
          borderColor="#ebedf2"
        />
      </div>
    );

    const extraBubbleClasses = clsx(style.Participant, {
      [style.LeftExtraBubble]: approvedBubble,
    });

    const getLabel = () => `+${approvers.length + 1 - MAX_VISIBLE_PARTICIPANTS}`;

    return (
      <div className={extraBubbleClasses}>
        <SimpleBubble
          label={getLabel()}
          borderColor={style.GrayBorder}
          diameter={46}
          renderBadge={renderDefaultBadge}
          data-testid="extra-bubble"
        />
      </div>
    );
  };

  const renderApprovedBubbles = (approvers: Oneflow.Participant[]) => {
    if (approvers.length <= 0) {
      return null;
    }

    let visibleApprovers = approvers;

    if (visibleApprovers.length > MAX_VISIBLE_PARTICIPANTS) {
      visibleApprovers = take(visibleApprovers, NUMBER_OF_PARTICIPANTS_WHEN_LIMITED);
    }

    const shouldRenderExtraBubble = approvers.length > MAX_VISIBLE_PARTICIPANTS;

    return (
      <>
        {shouldRenderExtraBubble ? renderExtraBubble(approvers, true) : null}
        {visibleApprovers.map((approver: Oneflow.Participant, i: number) => (
          <div className={style.Approved} key={uniqueId()}>
            {renderApproverBubble(approver, {
              index: i,
              hasApproved: true,
            })}
          </div>
        ))}
      </>
    );
  };

  const renderPendingBubbles = (approvers: Oneflow.Participant[]) => {
    if (approvers.length <= 0) {
      return null;
    }

    let visiblePending = approvers;

    if (visiblePending.length > MAX_VISIBLE_PARTICIPANTS) {
      visiblePending = take(visiblePending, NUMBER_OF_PARTICIPANTS_WHEN_LIMITED);
    }

    const shouldRenderExtraBubble = approvers.length > MAX_VISIBLE_PARTICIPANTS;

    return (
      <>
        {visiblePending.map((approver: Oneflow.Participant, i: number) => (
          <div className={i === 0 ? style.FirstBubble : style.NotApprovedYet} key={uniqueId()}>
            {renderApproverBubble(
              approver,
              {
                index: i,
                hasApproved: false,
              },
            )}
          </div>
        ))}
        {shouldRenderExtraBubble ? renderExtraBubble(approvers) : null}
      </>
    );
  };

  return (
    <div className={style.Bubbles}>
      <div className={style.ApprovedApprovers}>
        {renderApprovedBubbles(
          isDraftApproval ? approvedDraftApprovers : approvedExternalApprovers,
        )}
      </div>
      <div className={style.Me}>
        <ParticipantBubble
          participant={myParticipant}
          borderColor="#8638E5"
          userBadgeDiameter={66}
          userBadgeBorderSize={2}
          agreement={agreement}
          data-testid="my-participant-bubble"
        />
      </div>
      <div className={style.PendingApprovers}>
        {renderPendingBubbles(
          isDraftApproval ? pendingDraftApprovers : pendingExternalApprovers,
        )}
      </div>
    </div>
  );
};
