// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';
import { ContractNameField, TemplateNameField } from 'components/fields';
import { isTemplate } from 'agreement/states';

export type Props = {|
  updateState: UpdateState,
  currentContractName?: string,
  setContractName: string => void,
  resetUpdateState: () => void,
  children: React.Node,
  agreement: Agreement,
|};

export type FormData = {
  name?: string,
};

export class SetContractNameModal extends React.PureComponent<Props> {
  static defaultProps = {
    currentContractName: undefined,
  };

  renderNameField() {
    const { agreement } = this.props;

    if (isTemplate(agreement)) {
      return <TemplateNameField autoFocus />;
    }

    return <ContractNameField autoFocus />;
  }

  renderModalMessage() {
    const { agreement } = this.props;
    if (isTemplate(agreement)) {
      return null;
    }
    return (
      <p>
        <Message
          id="Contract names are not visible to counterparties."
          comment="Help text for contract name in edit contract name modal."
        />
      </p>
    );
  }

  render() {
    const {
      updateState,
      setContractName,
      currentContractName,
      resetUpdateState,
      children,
    } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Rename"
            comment="Modal title for editing contract name."
          />
        )}
        body={(
          <div>
            {this.renderNameField()}
            {this.renderModalMessage()}
          </div>
        )}
        onSubmit={setContractName}
        resetFormState={resetUpdateState}
        formState={updateState}
        initialValues={{
          name: currentContractName,
        }}
        modalKey="set contract name modal"
      >
        {children}
      </ModalForm>
    );
  }
}
