// @flow

import isEmpty from 'lodash/isEmpty';

export const INVITE_MESSAGE_TEMPLATE_STANDARD = 'standard_message';

export const getStandardInviteMessageTemplate = (
  message,
  position,
) => ({
  id: INVITE_MESSAGE_TEMPLATE_STANDARD,
  name: message({
    id: 'Standard message',
    comment: 'This will be used as the name of the message template',
  }),
  body: message({
    id: 'Your colleague {publisher} needs help with a draft before sending it to the counterparty.',
    comment: 'This will be used as the body of notification email',
    values: {
      publisher: position?.fullname,
    },
  }),
  subject: message({
    id: 'Your colleague {publisher} invited you',
    comment: 'This will be used as a subject for the notification email',
    values: {
      publisher: position?.fullname,
    },
  }),
});

export const getInviteMessageBody = (
  agreement,
  messageTemplate,
  position,
) => {
  if (isEmpty(agreement) || isEmpty(messageTemplate)) {
    return null;
  }

  if (messageTemplate.id === INVITE_MESSAGE_TEMPLATE_STANDARD) {
    return `${messageTemplate.body}`;
  }

  if (position.signature && !isEmpty(messageTemplate) && messageTemplate.body) {
    return `${messageTemplate.body}\n\n${position.signature}`;
  }

  if (position.signature) {
    return position.signature;
  }

  if (messageTemplate && messageTemplate.body) {
    return messageTemplate.body;
  }

  return undefined;
};
