// @flow

import { put } from 'redux-saga/effects';
import generateEntitySagas from 'normalized-redux/sagas';
import { getGroupWorkspaces } from 'oneflow-client/group-workspaces';

import apiWrapper from 'sagas/api-wrapper';

import groupWorkspacesReducer from 'reducers/entities/group-workspaces';

export function* mapper({ data }) {
  yield put(groupWorkspacesReducer.setGroupWorkspaces(data.entities.groupWorkspaces));
}

const mappers = {
  query: {
    mapper,
    request: getGroupWorkspaces,
  },
};

const groupWorkspacesSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: groupWorkspacesReducer,
  mappers,
});

export default groupWorkspacesSagas;
