import Message from 'components/message';
import style from './invitation-header.module.scss';

const InvitationHeader = () => (
  <h2 className={style.InvitationHeader}>
    <Message
      id="Message to participants"
      comment="Header in the body of the modal to send a message to the participants of the document."
    />
  </h2>
);

export default InvitationHeader;
