import type { ClientRect } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import type { Size } from 'types/overlay';

const restrictToBoundingRect = (
  transform: Transform,
  rect: ClientRect,
  boundingRect: ClientRect,
  size: Size,
  scale: number,
): Transform => {
  const value = {
    ...transform,
  };

  const rectBottom = rect.top + (size.height * scale) + 2;
  const rectRight = rect.left + (size.width * scale) + 2;

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (
    rectBottom + transform.y >= boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rectBottom;
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left;
  } else if (
    rectRight + transform.x + 2 >= boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rectRight;
  }

  return value;
};

export default restrictToBoundingRect;
