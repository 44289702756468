import { useState } from 'react';
import { isEqual } from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  getDataFieldExternalKeyMap,
  getDataFieldExternalKeyValueMap,
  setSignAttempted,
} from 'reducers/current-contract';
import { getGuestToken } from 'agreement/selectors';
import agreementsReducer from 'reducers/entities/agreements';
import useCurrentBoxes from 'hooks/use-current-boxes';
import useCurrentData from 'hooks/use-current-data';
import useDetachedModalRenderer, { unmountDetachedModal } from 'hooks/use-detached-modal-renderer';

import {
  COLLAPSED,
  EXPANDED,
} from 'components/document-layout-container/helpers';
import { handleEmptyRequiredFields } from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons/sign/handle-empty-required-fields';
import SignContractModal from 'components/modals/sign-contract';
import {
  isAssetFailed,
  isAssetProcessing,
  showFailureToast,
  showProcessingToast,
} from 'components/asset-error-toasts';

import { SingleParticipantModalCollapsed } from './collapsed';
import { SingleParticipantModalExpanded } from './expanded';
import type { SingleParticipantModalProps } from './types';

const isSingleParticipantSignOrderError = (
  error: any,
) => isEqual(error?.body?.api_error_code, 4054);

export function SingleParticipantModal({
  agreementId,
  children,
  isSignatory,
  layout = EXPANDED,
}: SingleParticipantModalProps) {
  const [hasErrorDisplayed, setHasErrorDisplayed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const boxMap = useCurrentBoxes();
  const data = useCurrentData();
  const dataFieldExternalKeyMap = useSelector(getDataFieldExternalKeyMap);
  const dataFieldExternalKeyValueMap = useSelector(getDataFieldExternalKeyValueMap);
  const dispatch = useDispatch();
  const formState = useSelector(
    (state) => agreementsReducer.getPublishAgreementSelector(state, { id: agreementId }),
  );
  const guestToken = useSelector(getGuestToken);
  const isGuest = Boolean(guestToken);

  const renderSignModal = () => (
    <SignContractModal
      agreementId={agreementId}
      sendChecksum
      onClose={unmountDetachedModal}
      isOpen
    />
  );

  const triggerSignModal = useDetachedModalRenderer(renderSignModal);

  const handleProceedToSigning = () => {
    dispatch(setSignAttempted(true));
    const { hasMissingRequiredFields } = handleEmptyRequiredFields({
      isGuest,
      boxMap,
      data,
      dataFieldExternalKeyMap,
      dataFieldExternalKeyValueMap,
    });

    if (hasMissingRequiredFields) {
      setIsModalOpen(false);
      return;
    }

    dispatch(agreementsReducer.publishAgreement({
      id: agreementId,
      pipe: {
        onSuccess: triggerSignModal,
        onFailure: (error) => {
          const showAssetFailureToast = isAssetFailed(error);
          const showAssetProcessingToast = isAssetProcessing(error);
          const showSingleParticipantSignOrderErrorToast = isSingleParticipantSignOrderError(error);

          if (!showSingleParticipantSignOrderErrorToast) {
            setIsModalOpen(false);
            setHasErrorDisplayed(false);
          } else {
            setHasErrorDisplayed(true);
          }

          if (showAssetFailureToast) {
            showFailureToast();
          }

          if (showAssetProcessingToast) {
            showProcessingToast();
          }
        },
      },
    }));
  };

  const handleTrigger = () => {
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
    dispatch(agreementsReducer.publishAgreementReset({ id: agreementId }));
  };

  if (layout === COLLAPSED) {
    return (
      <SingleParticipantModalCollapsed
        proceed={handleProceedToSigning}
        isSignatory={isSignatory}
        isOpen={isModalOpen}
        onClose={onClose}
        formState={formState}
        hasError={hasErrorDisplayed}
      >
        {typeof children === 'function' ? () => children(handleTrigger) : () => null}
      </SingleParticipantModalCollapsed>
    );
  }

  return (
    <SingleParticipantModalExpanded
      proceed={handleProceedToSigning}
      isSignatory={isSignatory}
      isOpen={isModalOpen}
      onClose={onClose}
      formState={formState}
      hasError={hasErrorDisplayed}
    >
      {typeof children === 'function' ? () => children(handleTrigger) : () => null}
    </SingleParticipantModalExpanded>
  );
}
