import { Message } from '@oneflowab/pomes';

import Select from 'components/select';
import TooltipInfo from 'components/tooltip-info';

import style from './decimal-selector.module.scss';

const decimalItems = [0, 1, 2, 3, 4];

type Props = {
  type: 'productTable' | 'productSummation' | 'quantityField',
  decimalValue: number,
  handleValueChange: (value: number) => void,
};

const DecimalSelector = ({
  type,
  decimalValue,
  handleValueChange,
}: Props) => (
  <div className={style.DecimalContainer}>
    <div className={style.DecimalTitle}>
      <Message
        id="Decimal places"
        comment="Title for decimal places of price form"
      />
      <TooltipInfo
        message={(
          <Message
            id="Decimal places are the digits after a decimal point. For example, the number 1.25 has two decimal places."
            comment="Tooltip message for decimal places input"
          />
        )}
        zIndex={99999999}
        align="start"
        alignOffset={-40}
      />
    </div>
    {type === 'productTable' && (
      <div>
        <Message
          id="The selected option will apply to all prices in the product table."
          comment="Label for number of decimal places input"
        />
      </div>
    )}
    {type === 'productSummation' && (
      <div>
        <Message
          id="The selected option will apply to all prices in the product summation."
          comment="Label for number of decimal places input"
        />
      </div>
    )}
    {type === 'quantityField' && (
      <div>
        <Message
          id="The selected option will apply to all numbers in the quantity field."
          comment="Label for number of decimal places input"
        />
      </div>
    )}
    <Select
      name="pricePrecision"
      selectClassName={style.Select}
      value={decimalValue}
      itemsClassName={style.SelectItems}
      label={String(decimalValue)}
      onValueChange={handleValueChange}
      items={decimalItems}
    />
  </div>
);

export default DecimalSelector;
