// @flow
import * as React from 'react';
import clsx from 'clsx';

import truncate from 'lodash/truncate';
import Cross from 'components/icons/cross';
import Button from 'components/button';

import style from './send-to.module.scss';

type Props = {
  recipientLabel: string,
  onDelete?: () => void,
  error?: boolean,
  maxLength: number,
}

const SendTo = ({
  recipientLabel,
  onDelete,
  error,
  maxLength,
}: Props) => {
  if (!recipientLabel) {
    return null;
  }

  const recipientSpan = (
    <span>
      @
      {truncate(recipientLabel, { length: maxLength })}
    </span>
  );

  if (!onDelete) {
    return (
      <div className={style.SendToButton}>{recipientSpan}</div>
    );
  }

  return (
    <Button
      customClass={clsx(style.SendToButton, {
        [style.Error]: error,
      })}
      onClick={onDelete}
    >
      {recipientSpan}
      <Cross className={style.Icon} />
    </Button>
  );
};

export default SendTo;
