// @flow

import {
  KEY_DEMO,
} from 'extensions';

const Demo = {
  key: KEY_DEMO,
};

export default Demo;
