import { MouseEvent } from 'react';
import { Message } from '@oneflowab/pomes';

import EditIcon from 'components/icons/edit';
import { MenuItem } from 'components/menu-item';

type Props = {
  disabled?: boolean,
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
}

export const ChangeRoleMenuItem = ({
  disabled,
  onClick,
}: Props) => (
  <MenuItem
    disabled={disabled}
    label={(
      <Message
        id="Change role"
        comment="Label for change role action in menu."
      />
    )}
    onClick={onClick}
    icon={EditIcon}
  />
);
