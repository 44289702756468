import { Message } from '@oneflowab/pomes';

import SaveChangesButton from 'components/document-call-to-actions/actions/buttons-call-to-action/actions/save';
import SplitButtonWithDropdown from 'components/split-button-with-dropdown';
import type { MenuItems } from 'components/split-button-with-dropdown/types';
import useSaveChangesButton from 'components/document-call-to-actions/actions/buttons-call-to-action/hooks/use-save-changes-button';
import style from './save-button.module.scss';

type Props = {
  isDropdown?: boolean,
  menuItems?: MenuItems,
  agreementId: Oneflow.Document['id']
}

const SaveButton = ({ isDropdown = false, menuItems = [], agreementId }: Props) => {
  const { disabled } = useSaveChangesButton();

  const saveButton = (
    <SaveChangesButton agreementId={agreementId} buttonKind="save" buttonClassName={style.SaveButton}>
      <Message
        id="Save"
        comment="Button for saving changes in the document"
      />
    </SaveChangesButton>
  );

  if (!isDropdown) {
    return saveButton;
  }

  return (
    <SplitButtonWithDropdown
      customMainButton={saveButton}
      menuItems={menuItems}
      kind="save"
      disabled={disabled}
    />
  );
};

export default SaveButton;
