import { Message } from '@oneflowab/pomes';
import get from 'lodash/get';
import type { AuditLogEvent } from 'types/entities';

type Props = {
  auditLogEvent: AuditLogEvent,
};

export const AgreementPermanentDeleteEvent = ({ auditLogEvent }: Props) => {
  const name = get(auditLogEvent.information, 'name.from');
  const contractId = auditLogEvent.target?.id;
  const displayName = name ? `${name} (${contractId})` : contractId;

  const getStringVariables = () => ({
    displayName,
    workspace: auditLogEvent.secondaryTarget?.name,
  });

  return (
    <Message
      id="The document {displayName} was permanently deleted from {workspace}"
      comment="Audit log event message for a deleted document"
      values={getStringVariables()}
    />
  );
};
