// @flow

import * as React from 'react';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import {
  composeValidators,
  minValue,
  maxValue,
  numberValidator,
} from 'forms/validators';
import Field from 'components/field';
import { NumberField } from 'components/text-field';

export type Props = {|
  message: MessageTranslator,
  children: ((onClick?: Function) => React.Node) | React.Node,
  updateState: UpdateState,
  currentContractValue?: number,
  setContractValue: number => void,
  resetUpdateState: () => void,
  onSuccess?: () => void,
|};

export type FormData = {
  contractValue?: number,
};

export class SetContractValueComponent extends React.PureComponent<Props> {
  static defaultProps = {
    currentContractValue: undefined,
    onSuccess: undefined,
  };

  validations = (({ message }) => composeValidators(
    minValue({
      message,
      field: message({
        id: 'Amount',
        comment: 'Used in min value validation of Contract Value textbox',
      }),
      limit: 0,
    }),
    maxValue({
      message,
      field: message({
        id: 'Amount',
        comment: 'Used in max value validation of Contract Value textbox',
      }),
      limit: 999999999,
    }),
    numberValidator({
      message,
      field: message({
        id: 'Amount',
        comment: 'Used in number and decimals validation of Contract Value textbox',
      }),
      decimals: 2,
    }),
  ))(this.props);

  componentDidUpdate(prevProps: Props) {
    const { updateState, onSuccess } = this.props;

    if (!onSuccess) {
      return;
    }

    if (!prevProps.updateState.success && updateState.success) {
      onSuccess();
    }
  }

  renderBody() {
    const { message } = this.props;

    return (
      <div>
        <Field
          name="contractValue"
          type=""
          autoFocus
          label={message({
            id: 'Value',
            comment: 'Label for contract value in edit contract value modal.',
          })}
          placeholder={message({
            id: 'Enter value',
            comment: 'Placeholder for contract value in edit contract value modal.',
          })}
          component={NumberField}
          validate={this.validations}
          step={0.01}
        />
        <p>
          <Message
            id="Contract values are not visible to counterparties."
            comment="Help text for contract value in edit contract value modal."
          />
        </p>
      </div>
    );
  }

  render() {
    const {
      children,
      updateState,
      setContractValue,
      currentContractValue,
      resetUpdateState,
    } = this.props;

    return (
      <ModalForm
        title={(
          <Message
            id="Set value"
            comment="Modal title for editing contract value."
          />
        )}
        body={this.renderBody()}
        onSubmit={setContractValue}
        resetFormState={resetUpdateState}
        formState={updateState}
        initialValues={{
          contractValue: currentContractValue,
        }}
        modalKey="set contract value modal"
      >
        {children}
      </ModalForm>
    );
  }
}

export default localize<Props>(SetContractValueComponent);
