// @flow

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import agreementsReducer from 'reducers/entities/agreements';
import { getSignedParticipants } from 'agreement/selectors';

import ModalForm from 'hocs/modal-form';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import useAgreement from 'hooks/use-agreement';

import style from './single-sign.module.scss';

const modalKey = 'single signature modal';
export type Props = {|
  singleSignToggleStatus: boolean,
  agreementId: number,
  setIsModalOpen: (boolean) => void,
  isModalOpen: boolean,
  syncState: Function
|};

const SingleSign = ({
  singleSignToggleStatus,
  agreementId,
  setIsModalOpen,
  isModalOpen,
  syncState,
}: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const hasSomeoneSigned = Boolean(getSignedParticipants(agreement).length);

  const updateState = useSelector((state) => (
    agreementsReducer.getUpdateConfigSelector(state, { id: agreementId })
  ));

  const resetUpdateState = () => {
    dispatch(agreementsReducer.updateConfigReset({
      id: agreementId,
    }));
  };

  const handleClose = useCallback(() => {
    syncState();
    setIsModalOpen(false);
  }, [setIsModalOpen, syncState]);

  const handleSubmit = useCallback(async () => {
    const { singleSign } = agreement.config;
    dispatch(agreementsReducer.updateConfig({
      id: agreementId,
      data: {
        singleSign: !singleSign,
      },
    }));
  }, [agreement.config, agreementId, dispatch]);

  const renderBody = React.useCallback(() => {
    if (!singleSignToggleStatus) {
      return (
        <>
          <p className={style.Heading}>
            <Message
              id="This contract will now require signatures from everyone."
              comment="Message explaining what turning off single sign feature does."
            />
          </p>
          <Message
            id="Single signature can be turned on at any time, but will reset all previous signatures."
            comment="Message explaining when single sign featured can be turned on."
          />
        </>
      );
    }

    if (hasSomeoneSigned) {
      return (
        <>
          <p className={style.Heading}>
            <Message
              id="This will reset all signatures and will now only require one new signature to become legally binding."
              comment="Message explaining what turning on single sign feature does."
            />
          </p>
          <Message
            id="This setting can be disabled at any time before anyone has signed."
            comment="Message explaining when single sign featured can be turned off."
          />
        </>
      );
    }

    return (
      <>
        <p className={style.Heading}>
          <Message
            id="The contract will from now on only require one signature to become legally binding. "
            comment="Message explaining what turning on single sign feature does."
          />
        </p>
        <Message
          id="This setting can be disabled at any time before anyone has signed."
          comment="Message explaining when single sign featured can be turned off."
        />
      </>
    );
  }, [hasSomeoneSigned, singleSignToggleStatus]);

  const renderHeader = React.useCallback(() => {
    if (singleSignToggleStatus) {
      return (
        <Message
          id="Confirm single signature"
          comment="Modal title for Single signature modal."
        />
      );
    }

    return (
      <Message
        id="Disable single signature"
        comment="Modal title for Single signature modal."
      />
    );
  }, [singleSignToggleStatus]);

  const renderConfirmationMessage = React.useCallback(() => {
    if (singleSignToggleStatus) {
      return (
        <Message
          id="Are you sure you want to enable single signature?"
          comment="confirmation message when single sign is turned on"
        />
      );
    }

    return (
      <Message
        id="Are you sure you want to disable single signature?"
        comment="confirmation message when single sign is turned off"
      />
    );
  }, [singleSignToggleStatus]);

  const renderModalContent = () => (
    <>
      <div className={style.BodyContainer}>
        {renderBody()}
      </div>
      <div className={style.ConfirmationMessage}>
        {renderConfirmationMessage()}
      </div>
    </>
  );

  const getActions = useCallback(() => (
    <>
      <CancelButton
        onClick={handleClose}
        modalKey={modalKey}
      />
      <Button
        color="yellow"
        onClick={handleSubmit}
        kind="secondary"
      >
        <Message
          id="Continue"
          comment="Button text in the edit template warning modal."
        />
      </Button>
    </>
  ), [handleClose, handleSubmit]);

  return (
    <ModalForm
      title={renderHeader()}
      body={renderModalContent()}
      onSubmit={handleSubmit}
      resetFormState={resetUpdateState}
      formState={updateState}
      actions={getActions}
      isOpen={isModalOpen}
      modalKey={modalKey}
      onClose={handleClose}
    >
      {() => null}
    </ModalForm>
  );
};

export default SingleSign;
