import { Message } from '@oneflowab/pomes';

import CancellationReason from 'components/modals/cancellation/cancellation-reasons/cancellation-reason';

import style from './your-alternative.module.scss';

const FoundAlternativeOptions = [
  {
    reference: 'another_digital_solution',
    label:
  <Message
    id="Another digital solution"
    comment="Cancellation reason"
  />,
    id: 1,
    enabled: true,
  },
  {
    reference: 'pdf_or_non_online_solution',
    label:
  <Message
    id="PDFs or non-online solution"
    comment="Cancellation reason"
  />,
    id: 2,
    enabled: true,
  },
];

export type Props = {
  onChange: (reference: string) => void;
}

const YourAlternative = ({
  onChange,
}: Props) => (
  <div className={style.FoundAlternativeContainer}>
    <p className={style.FoundAlternativeTitle}>
      <Message
        id="Where are you going next?"
        comment="Header for found alternative section"
      />
    </p>
    <div className={style.OptionsWrapper}>
      {FoundAlternativeOptions.map(({
        id,
        label,
        reference,
        enabled,
      }) => (
        <CancellationReason
          key={id}
          label={label}
          reference={reference}
          onSelect={onChange}
          enabled={enabled}
        />
      ))}
    </div>
  </div>
);

export default YourAlternative;
