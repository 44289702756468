import { useState, useCallback, useMemo } from 'react';
import type { ChangeEvent } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Form } from 'react-final-form';
import type { FormRenderProps } from 'react-final-form';

import { isEmpty } from 'lodash';

import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import localStorage from 'utils/local-storage';
import { convert3HexTo6Hex } from 'utils/color';

import { isValidColor } from 'components/rich-text-editor/utils';
import Button from 'components/button';
import ColorBubble from 'components/color-picker/color-bubble';
import TextField from 'components/text-field';
import Field from 'components/field';
import style from './color-picker.module.scss';

type Props = {
  preSelectedColor: string,
  applyColor: (color: string) => void,
  removeColor: () => void,
  message: MessageTranslator,
};

function ColorPicker({
  preSelectedColor,
  applyColor,
  removeColor,
  message,
}: Props) {
  const [color, setColor] = useState<string>(preSelectedColor);
  const recentlyUsedColors = useMemo<string[]>(() => {
    try {
      const res = JSON.parse(localStorage.getItem('recently_used_colors') ?? '[]');
      return res || [];
    } catch {
      return [];
    }
  }, []);

  const updateRecentlyUsedColors = useCallback((colorToUpdate: string) => {
    let colors = recentlyUsedColors || [];

    if (colors.includes(colorToUpdate)) {
      // To move the colorToUpdate to the start of the array
      const colorsWithoutColorToUpdate = colors.filter((c) => c !== colorToUpdate);
      colors = [colorToUpdate, ...colorsWithoutColorToUpdate];
    } else {
      colors = [colorToUpdate, ...colors];
    }

    if (colors.length > 9) {
      colors.pop();
    }

    localStorage.setItem('recently_used_colors', JSON.stringify(colors));
  }, [recentlyUsedColors]);

  const onColorPickerClick = useCallback((e: MouseEvent) => {
    // Prevent the editor toolbar "More" menu from closing in collapsed view when you pick a color
    // Otherwise, the closing of that menu will also close the color picker before we submit
    // the chosen color
    e.stopPropagation();
  }, []);

  const onColorSubmit = useCallback(() => {
    const hex = convert3HexTo6Hex(color) ?? color;
    updateRecentlyUsedColors(hex);
    applyColor(hex);
  }, [applyColor, updateRecentlyUsedColors, color]);

  const validateOnChangeColor = (newColor: string): string | null => {
    if (isEmpty(newColor)) return null;

    if (!isValidColor(newColor)) {
      return message({
        id: 'The value must be in hex format, e.g., #f00 or #ffffff.',
        comment: 'Error message when input value is invalid',
      });
    }

    return null;
  };

  const renderRecentlyUsedColors = () => {
    if (isEmpty(recentlyUsedColors)) return null;

    const recentlyUsedValidColors = recentlyUsedColors.filter(isValidColor);

    return (
      <div className={style.RecentlyUsedColorsSection}>
        <div className={style.RecentlyUsedColorsLabel}>
          <Message
            id="Recently used colors"
            comment="Heading of the section which has buttons of recently used colors"
          />
        </div>
        <div className={style.RecentlyUsedColorsButtons}>
          {recentlyUsedValidColors.map((recentlyUsedColor) => (
            <ColorBubble
              key={recentlyUsedColor}
              color={recentlyUsedColor}
              onClick={() => setColor(recentlyUsedColor)}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <Form
      onSubmit={onColorSubmit}
      initialValues={{ colorField: color }}
      render={(formProps: FormRenderProps<{ colorField: string }>) => (
        <form onSubmit={formProps.handleSubmit} className={style.ColorForm}>
          {renderRecentlyUsedColors()}
          <HexColorPicker
            className={style.HexColorPicker}
            color={color}
            onChange={setColor}
            onClick={onColorPickerClick}
            data-testid="hex-color-picker"
          />
          <div className={style.ColorInputContainer} data-testid="color-input-container">
            <span className={style.ColorInputLabel}>
              <ColorBubble color={color} />
              HEX:
            </span>
            <Field
              name="colorField"
              component={TextField}
              customClass={style.ColorInput}
              placeholder={message({
                id: 'e.g. #f00 or #ff0000',
                comment: 'Placeholder message for valid types of hex color input',
              })}
              validate={validateOnChangeColor}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setColor(event.target.value);
                formProps.form.change('colorField', event.target.value);
              }}
              displayErrorEarly
            />
          </div>
          <div className={style.ColorActions}>
            <Button onClick={removeColor} customClass={style.ResetButton}>
              <Message
                id="Reset"
                comment="The label of the button that clears the previously selected color."
              />
            </Button>
            <Button type="submit" kind="primary" disabled={!isValidColor(color)}>
              <Message
                id="Apply"
                comment="The label of the button that applies the selected color."
              />
            </Button>
          </div>
        </form>
      )}
    />
  );
}

export default localize<Props>(ColorPicker);
