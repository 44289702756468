// eslint-disable-next-line import/named
import { createBulkOperation } from 'oneflow-client/bulk-operations';

import generateEntitySagas from 'normalized-redux/sagas';
import apiWrapper from 'sagas/api-wrapper';
import bulkOperations from 'reducers/entities/bulk-operations';

const mappers = {
  create: {
    request: createBulkOperation,
  },
};

const bulkOperationsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: bulkOperations,
  mappers,
});

export default bulkOperationsSagas;
