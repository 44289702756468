import { Message } from '@oneflowab/pomes';

const Overdue = () => (
  <>
    <b>
      <Message
        id="Document is overdue."
        comment="Text for overdue contract. Visible in contract."
      />
    </b>
    {' '}
    <Message
      id="Contact the document owner to extend the signing period."
      comment="Text for overdue contract. Visible in contract."
    />
  </>
);

export default Overdue;
