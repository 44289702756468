// @flow

import get from 'lodash/get';
import { normalize, schema } from 'normalizr';
import { decamelizeKeys } from 'humps';

import {
  getMultiple,
  post,
  put,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import {
  KEY_TEMPLATE_GROUPS,
  KEY_API_TOKENS,
  KEY_WEBHOOKS,
} from 'extensions';

const extensionSchema = new schema.Entity('extensions');

export type NormalizedExtensions = {
  entities: {
    extensions: {
      [number]: Extension,
    },
  },
  result: number | Array<number>,
  count: number,
};

export const mapExtensionTypeToId = (extension: Extension) => ({
  id: extension.key,
  extensionId: extension.id,
  key: extension.key,
  slug: extension.slug,
  extensionClass: extension.extensionClass,
  isEntitled: extension.isEntitled,
  canEnable: extension.canEnable,
  canDisable: extension.canDisable,
  category: extension.category,
  state: Boolean(extension.state),
  config: extension.config,
  name: extension.name,
  overview: extension.overview,
  faqs: extension.faqs,
  keyTakeaways: extension.keyTakeaways,
  helpTopics: extension.helpTopics,
  terms: extension.terms,
  ownerName: extension.ownerName,
  setupUrl: extension.setupUrl,
  setupMethod: extension.setupMethod,
  acl: extension.acl,
  token: get(extension, 'sideEffects.token.token'),
  disableText: extension.disableText,
  enableText: extension.enableText,
});

const getDependenciesByKey = (extensionKey: number, extensionId?: number) => {
  if (!extensionId) {
    return [];
  }

  return [{
    type: extensionKey,
    id: extensionKey,
    extensionId,
    state: true,
  }];
};

const extractDependencies = ({ sideEffects = {} }: Extension) => ([
  ...getDependenciesByKey(KEY_TEMPLATE_GROUPS, get(sideEffects, 'templateGroups[0].extension.id')),
  ...getDependenciesByKey(KEY_API_TOKENS, get(sideEffects, 'token.extension.id')),
  ...getDependenciesByKey(KEY_WEBHOOKS, get(sideEffects, 'webhook.extension.id')),
]);

type ExtensionsNormalizer = (any) => NormalizedExtensions;

export const normalizeExtensions: ExtensionsNormalizer = (extensions) => ({
  ...normalize(extensions.collection.map(mapExtensionTypeToId), [extensionSchema]),
  count: extensions.count,
});

const normalizeExtension = (extension: Extension) => {
  const currentExtension = mapExtensionTypeToId(extension);
  const extensionDependencies = extractDependencies(extension);

  return {
    ...normalize([currentExtension, ...extensionDependencies], [extensionSchema]),
    count: 1 + extensionDependencies.length,
  };
};

type GetExtensions = ({
  params?: {},
}) => Promise<NormalizedExtensions>;

export const getExtensions: GetExtensions = ({ params }) => (
  getMultiple({
    url: '/ext/',
    params,
    pagination: {
      limit: undefined,
      offset: undefined,
    },
  }).then(extractResponseBodyAsJSON)
    .then(normalizeExtensions)
);

type CreateExtension = ({
  key: string,
}) => Promise<NormalizedExtensions>;

export const createExtension: CreateExtension = ({ key }) => (
  post({
    url: '/ext/',
    body: {
      key,
    },
  }).then(extractResponseBodyAsJSON)
    .then(normalizeExtension)
);

type UpdateExtension = ({
  id: number,
  state: boolean,
  config: {},
}) => Promise<NormalizedExtensions>;

export const updateExtension: UpdateExtension = ({
  id,
  state,
  config,
}) => (
  put({
    url: `/ext/${id}`,
    body: decamelizeKeys({
      state,
      config,
    }, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
    .then(normalizeExtension)
);
