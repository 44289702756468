// @flow

import React from 'react';
import { connect } from 'react-redux';
import { type MessageTranslator, Message } from '@oneflowab/pomes';
import get from 'lodash/get';
import type { Dispatch } from 'redux';

import { getPathnameSelector } from 'reducers/router';
import { isTemplate } from 'agreement/states';
import agreementsReducer from 'reducers/entities/agreements';
import agreementTemplatesReducer from 'reducers/entities/agreement-templates';
import foldersReducer from 'reducers/entities/folders';
import workspacesReducer, { getAllWorkspaces, getAllMoveToWorkspaces } from 'reducers/entities/workspaces';
import toast from 'components/toasts';

import MoveContract, { type Props, type SubmitData } from './move-contract';

const FOLDERS_QUERY = 'folders';

type MainProps = $Diff<Props, {| message: MessageTranslator |}>;

type StateProps = {|
  addFolderState: RpcState,
  allAvailableWorkspaces: Array<Workspace>,
  availableWorkspaces: Array<Workspace>,
  formState: RpcState,
  pathname: any,
  workspace: Workspace | {},
|};

type OwnProps = $Diff<MainProps, OwnProps>;

type DispatchProps = {|
  onSubmit: SubmitData => void,
  resetFormState: () => void,
  queryFoldersReload: () => void,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { agreement }) => {
  const pathname = getPathnameSelector(state);
  const workspace = workspacesReducer.getWorkspaceSelector(state, {
    id: get(agreement, 'collection.id'),
  });

  const foldersQuery = foldersReducer.getQuerySelector(state, { name: FOLDERS_QUERY });
  const folders = foldersReducer.getFoldersSelector(state, { ids: foldersQuery.result });

  return {
    addFolderState: foldersReducer.getCreateSelector(state, { name: FOLDERS_QUERY }),
    allAvailableWorkspaces: getAllWorkspaces(state),
    availableWorkspaces: getAllMoveToWorkspaces(state, workspace.id),
    formState: agreementsReducer.getMoveAgreementSelector(state, { id: agreement.id }),
    pathname,
    workspace,
    folders,
  };
};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { agreement, queryName }) => ({
  resetFormState: () => {
    dispatch(agreementsReducer.moveAgreementReset({ id: agreement.id }));
  },
  onSubmit: ({
    isGlobalSearch,
    targetFolder,
    targetWorkspace,
  }: SubmitData) => {
    const actionData = {
      id: agreement.id,
      data: {
        targetWorkspaceId: targetWorkspace?.id,
        targetFolderId: targetFolder?.id ?? null,
      },
      pipe: {
        onSuccess: () => {
          toast.success({
            id: 'document-moved',
            title: (
              <Message
                id="Document moved"
                comment="Title for the notification when a document is moved"
              />
            ),
            description: (
              <Message
                id="To {workspace}{folder}"
                comment="Description for the notification when a document is moved, showing its destination"
                values={{
                  workspace: targetWorkspace?.name || '',
                  folder: targetFolder?.id && targetFolder?.id !== -1
                    ? ` / ${targetFolder?.name || ''}`
                    : '',
                }}
              />
            ),
            duration: 8000,
          });
        },
      },
    };

    if (queryName) {
      let action;

      if (!isGlobalSearch) {
        action = () => agreementsReducer.queryAgreementsReload({
          name: queryName,
        });
      }

      if (isTemplate(agreement)) {
        action = () => agreementTemplatesReducer.queryAgreementTemplatesReload({
          name: queryName,
        });
      }

      actionData.pipe.action = action;
    }

    dispatch(agreementsReducer.moveAgreement(actionData));
  },
  queryFoldersReload: () => {
    dispatch(foldersReducer.queryFoldersReload({
      name: FOLDERS_QUERY,
    }));
  },
});

export default connect<MainProps, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(MoveContract);
