import { isRoleEditable } from 'role';
import Message from 'components/message';
import { HelpCenterLink } from 'components/help-center-link';

import styles from './role-details-header-text.module.scss';

const RoleDetailsHeaderText = ({ role }: {role: Oneflow.Role}) => {
  if (!isRoleEditable(role)) {
    return null;
  }

  const title = (
    role.scope === 'account' ? (
      <Message
        id="Account role"
        comment="Title for the account role."
      />
    ) : (
      <Message
        id="Workspace role"
        comment="Title for the workspace role."
      />
    )
  );

  const description = (
    role.scope === 'account' ? (
      <>
        <span data-testid="header-description">
          <Message
            id="Account permissions impact your entire company’s account including all users, workspaces, billing, and integrations."
            comment="Description for the account role."
          />
        </span>
        <span data-testid="help-center-url">
          {' '}
          <HelpCenterLink path="support/solutions/articles/77000557804-account-permissions-beta-#account-permissions-0-0" />
        </span>
      </>
    ) : (
      <>
        <span data-testid="header-description">
          <Message
            id="Workspace permissions impact individual workspaces."
            comment="Description for the workspace role."
          />
        </span>
        <span data-testid="help-center-url">
          {' '}
          <HelpCenterLink path="support/solutions/articles/77000551848-workspaces-workspace-roles#workspace-roles-0-0" />
        </span>
      </>
    )
  );
  return (
    <div className={styles.Container}>
      <h3 className={styles.Header}>
        {title}
      </h3>
      {description}
    </div>
  );
};

export default RoleDetailsHeaderText;
