// @flow

import React from 'react';

import Svg from 'components/svg';

const TableCellBackgroundColor = Svg((
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 2.99999L10.6114 1.61139L9.19718 3.0256L10.5858 4.4142L7.41421 7.58577L6 8.99998L7.41421 10.4142L10.5858 13.5858L12 15L13.4142 13.5858L16.5858 10.4142L18 8.99998L16.5858 7.58577L13.4142 4.4142L12 2.99998L12 2.99999ZM12 5.82841L15.1716 8.99998L15.1716 8.99999H8.82843L8.82843 8.99998L12 5.82841ZM18 13.875C18 14.4963 17.4963 15 16.875 15C16.2537 15 15.75 14.4963 15.75 13.875C15.75 13.2537 16.2537 12.75 16.875 12.75C17.4963 12.75 18 13.2537 18 13.875Z" fill="currentColor" />
    <path d="M4 17H20V21H4V17Z" fill="currentColor" />
  </>
), { viewBox: '0 0 24 24', fill: 'none' });

TableCellBackgroundColor.displayName = 'TableCellBackgroundColor';

export default TableCellBackgroundColor;
