// @flow

import { connect } from 'react-redux';
import get from 'lodash/get';

import contactsReducer from 'reducers/entities/contacts';
import { getCurrentLanguageSelector } from 'reducers/i18n';

import AddContact from './add-contact';

const QUERY_NAME = 'address-book/contacts';

export const mapStateToProps = (state) => ({
  formState: contactsReducer.getCreateSelector(state),
  lang: getCurrentLanguageSelector(state),
});

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (contactData) => {
    dispatch(contactsReducer.createContact({
      data: {
        ...contactData,
        country: get(contactData, 'country.value'),
      },
      pipe: {
        action: () => contactsReducer.queryContactsReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
  resetFormState: () => {
    dispatch(contactsReducer.createContactReset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
