import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import SignContractModal from 'components/modals/sign-contract';
import NewCheck from 'components/icons/new-check';
import { MenuItem } from 'components/menu-item';

import style from './sign-contract.module.scss';

type ChildrenFunctionProps = {onClick: React.MouseEventHandler, canSign: boolean};

export type Props = {
  agreement: Oneflow.Agreement,
};

class SignContract extends React.PureComponent<Props> {
  getChildren = ({ onClick, canSign }: ChildrenFunctionProps) => (
    <MenuItem
      icon={NewCheck}
      label={(
        <Message
          id="Sign"
          comment="Action to sign a contract."
        />
      )}
      disabled={!canSign}
      onClick={onClick}
      className={canSign ? style.SignMenuItem : undefined}
    />
  );

  render() {
    const { agreement } = this.props;

    return (
      <SignContractModal agreementId={agreement.id}>
        {this.getChildren}
      </SignContractModal>
    );
  }
}

export default SignContract;
