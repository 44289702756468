export const partyParticipants = {
  collectionName: 'participants',
  ownProps: [
    'state',
    'type',
    'fullname',
    'deliveryChannesStatus',
    'id',
    'deliveryChannel',
    'signMethod',
    'mfaChannel',
    'title',
    'ssn',
    'email',
    'phoneNumber',
    'handwrittenSignatureData',
    'publishedAt',
    'visits',
    'firstVisitTs',
    'lastVisitTs',
    'stateTimestampTs',
  ],
};

export const contractParties = {
  collectionName: 'parties',
  ownProps: [
    'state',
    'id',
    'name',
  ],
  relations: {
    participants: partyParticipants,
  },
};

const contractSchemaSpecification = {
  entityName: 'contract',
  ownProps: [
    'id',
    'name',
    'created',
    'expireDate',
    'lifecycle',
    'periodEndTime',
    'publishTime',
    'signPlatform',
    'startTime',
    'state',
    'stateTime',
    'type',
    'updated',
  ],
  // In isolation subSchemas doesn't mean anything and give
  // More info about the schema (eg., contract.config)
  subSchemas: {
    config: {
      ownProps: [
        'dateFormat',
      ],
    },
  },
  // Relations are independant entities connected to the schema
  relations: {
    parties: contractParties,
  },
};

export default contractSchemaSpecification;
