// @flow

import React from 'react';
import { Route, type Match } from 'react-router';

import { pathWithWorkspaceNamespace } from 'hocs/with-current-workspace';

import { checkAcl } from 'components/acl';

import AddressBook from 'routes/address-book';
import Dashboard from 'routes/dashboard';
import Templates from 'routes/templates';
import Admin from 'routes/admin';
import User from 'routes/user';
import Marketplace from 'routes/marketplace';
import Contracts from 'routes/contracts';
import GlobalSearch from 'routes/global-search';
import Download from 'routes/download';

import Switch from 'components/switch';
import { NotFound } from 'components/errors';

import Conditional from 'components/conditional';

export const useRedirect = (redirectPath: string) => () => <NotFound redirect to={redirectPath} />;

type RouteProps = {
  match: Match,
};

export const useReplaceRedirect = (oldName: string, newName: string) => ({ match }: RouteProps) => (
  <NotFound redirect to={match.url.replace(oldName, newName)} />
);

type Props = {
  acl: {
    workspace: Acl,
    account: Acl,
  },
};

const Routes = ({ acl }: Props) => (
  <Switch>
    <Dashboard path={pathWithWorkspaceNamespace('/dashboard')} acl={acl} />
    <Route path={pathWithWorkspaceNamespace('/search/contracts')}>
      {useReplaceRedirect('/search/', '/')}
    </Route>
    <Contracts path={pathWithWorkspaceNamespace('/contracts')} acl={acl} />
    <Templates path={pathWithWorkspaceNamespace('/templates')} acl={acl} />
    <AddressBook
      path={pathWithWorkspaceNamespace('/address-book')}
      acl={acl}
    />
    <GlobalSearch path="/search" />
    <User path="/user" />

    <Route path="/marketplace">
      {(routeProps) => {
        if (!checkAcl(acl.account, 'account:marketplace', { match: 'any' })) {
          return <NotFound redirect to="/dashboard" />;
        }
        return (
          <Marketplace
            {...routeProps}
            acl={acl}
            isMainPage
            section="Marketplace"
          />
        );
      }}
    </Route>

    <Route path="/admin/account/extensions">
      {useRedirect('/marketplace')}
    </Route>

    <Route path="/admin/account/details">
      {useRedirect('/admin/account')}
    </Route>
    <Route path="/admin/collections/:id?">
      {useReplaceRedirect('collections', 'workspaces')}
    </Route>
    <Route path="/admin/users/:id/collections-access">
      {useReplaceRedirect('collections-access', 'workspace-access')}
    </Route>
    <Route path="/admin/groups/:id/collections-access">
      {useReplaceRedirect('collections-access', 'workspace-access')}
    </Route>
    <Route path="/admin">
      {(routeProps) => (
        <Conditional
          ifCondition={checkAcl(acl.account, 'account:admin', { match: 'any' })}
          elseCondition={<NotFound redirect to="/dashboard" />}
        >
          <Admin
            {...routeProps}
            acl={acl}
          />
        </Conditional>
      )}
    </Route>
    <Route path="/accounts/:accountId/exports/:assetId.:extension(xlsx|zip|csv)" component={Download} />
    <Route path="/settings" component={NotFound} />
    <Route>
      <NotFound redirect to="/dashboard" />
    </Route>
  </Switch>
);

export default Routes;
