// @flow

import * as React from 'react';
import { getDateFormat } from 'date';
import { useSelector } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import {
  getPositionFromSessionSelector,
  getAccountFromSessionSelector,
} from 'reducers/session';

type Props = {|
  refetchInternalReminders: () => void,
  children: React.Node,
  modal: React.Element,
|};

export const UserEventsContext = React.createContext<any>();

// When we need only timeValues we can directly use this instead of the hook
export const timeValues = [
  { time: '00:00' },
  { time: '01:00' },
  { time: '02:00' },
  { time: '03:00' },
  { time: '04:00' },
  { time: '05:00' },
  { time: '06:00' },
  { time: '07:00' },
  { time: '08:00' },
  { time: '09:00' },
  { time: '10:00' },
  { time: '11:00' },
  { time: '12:00' },
  { time: '13:00' },
  { time: '14:00' },
  { time: '15:00' },
  { time: '16:00' },
  { time: '17:00' },
  { time: '18:00' },
  { time: '19:00' },
  { time: '20:00' },
  { time: '21:00' },
  { time: '22:00' },
  { time: '23:00' },
];

export function UserEventsProvider({
  refetchInternalReminders,
  children,
  modal: InternalReminderModal,
}: Props) {
  const [selectedEvent, setSelectedEvent] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const resetSelectedEvent = React.useCallback(() => setSelectedEvent(undefined), []);
  const openModal = React.useCallback(() => setIsModalOpen(true), []);
  const closeModal = React.useCallback(() => {
    setIsModalOpen(false);
    resetSelectedEvent();
  }, [resetSelectedEvent]);

  const currentUser = useSelector(getPositionFromSessionSelector);

  const account = useSelector((state) => getAccountFromSessionSelector(state));
  const workspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  const brandingDateFormat = workspace?.brandingDateFormat || account.brandingDateFormat;
  const dateFormat = getDateFormat(brandingDateFormat);

  const contextValue = React.useMemo(
    () => ({
      currentUser,
      refetchInternalReminders,
      dateFormat,
      isModalOpen,
      selectedEvent,
      setSelectedEvent,
      openModal,
      closeModal,
      timeValues,
    }),
    [
      currentUser,
      refetchInternalReminders,
      dateFormat,
      isModalOpen,
      selectedEvent,
      setSelectedEvent,
      openModal,
      closeModal,
    ],
  );

  return (
    <UserEventsContext.Provider value={contextValue}>
      {children}
      {selectedEvent && (
        <InternalReminderModal
          onOpen={openModal}
          isOpen={isModalOpen}
          internalReminderEvent={selectedEvent}
          refetchInternalReminders={refetchInternalReminders}
          onClose={closeModal}
          isEditable={selectedEvent?.isEditable}
        />
      )}
    </UserEventsContext.Provider>
  );
}

export const useUserEvents = () => {
  const contextValue = React.useContext(UserEventsContext);

  if (!contextValue) {
    throw new Error('useUserEvents should be used inside a UserEventsContext');
  }

  return contextValue;
};
