import { isEmpty } from 'lodash';
import moment from 'moment';

import * as agreementUtils from 'agreement';
import { TYPE_NO_DURATION } from 'agreement/constants';

type Props = {
  agreement: Oneflow.Agreement,
  initialNoticePeriodReminder: number,
  dateFormat: string
}

// Takes the first type from the hasSinglePeriod and isAnyRecurringType method parameters
type BoxContentAgreement = (
  Parameters<typeof agreementUtils.hasSinglePeriod> &
  Parameters<typeof agreementUtils.isAnyRecurringType>
)[0]

export const isRemindMeVisible = (currentDurationData: BoxContentAgreement): boolean => {
  const isDurationTypeUsingReminders = agreementUtils.hasSinglePeriod(currentDurationData)
      || agreementUtils.isAnyRecurringType(currentDurationData);

  return isDurationTypeUsingReminders;
};

export const getCurrentDurationData = (boxes: Oneflow.Agreement['boxes'], durationBoxId: number) => {
  const durationBox = boxes?.[durationBoxId];
  if (!durationBox) {
    return { type: TYPE_NO_DURATION };
  }
  const currentAgreement = durationBox.content?.agreement;
  return currentAgreement;
};

export const getSendReminderDate = (
  { agreement, initialNoticePeriodReminder, dateFormat }: Props,
) => {
  if (isEmpty(agreement)) {
    return null;
  }

  return moment(agreement.periodNoticePeriodStartTime).subtract(initialNoticePeriodReminder, 'days').format(dateFormat);
};
