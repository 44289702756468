import { ReactNode } from 'react';
import clsx from 'clsx';

import { useAnnotationProps } from 'contexts/annotation-props';
import style from './suggestion.module.scss';

type Props = {
  children: ReactNode,
};

const Suggestion = ({
  children,
}: Props) => {
  const { acceptedSuggestion } = useAnnotationProps();
  const { isAcceptedSuggestion, messageId } = acceptedSuggestion;

  const element = document.getElementById(`annotation-${messageId}`);

  const suggestionClasses = clsx(style.Suggestion, {
    [style.AcceptSuggestionAnimation]: element && isAcceptedSuggestion,
  });

  return (
    <span className={suggestionClasses}>{children}</span>
  );
};

export default Suggestion;
