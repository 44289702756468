// @flow

import React from 'react';
import { type Match } from 'react-router';

import adminPage from 'hocs/admin-page';
import type { PropsMapper } from 'hocs/admin-page/admin-page';

import Country from 'components/countries';

import Account from './account-page';

import style from './account.module.scss';

export type Props = AdminPageComponentProps & {
  currentLanguage: EnabledLanguages,
  fetchAccount: ({id: number}) => void,
  match: Match,
};

export const AccountComponent = () => (
  <Account />
);

export function renderLogo(account: Account) {
  const { logoUrl } = account;

  if (!logoUrl) {
    return null;
  }

  return (
    <div className={style.Badge}>
      <img alt="logo" src={logoUrl} className={style.BrandingLogo} />
    </div>
  );
}

export function getCountry(account: Account, currentLanguage: EnabledLanguages) {
  const { country } = account;

  return (
    <Country
      country={country}
      lang={currentLanguage}
    />
  );
}

export const accountModule: PropsMapper = ({
  props: {
    message,
  },
}) => ({
  title: message({
    id: 'Account',
    comment: 'Used as the page title of the account page.',
  }),
  showAsLink: true,
  modules: [],
});

export default adminPage(accountModule)(AccountComponent);
