// @flow
import { isAnyRecurringType } from 'agreement';

const getDocumentPeriod = (agreement: Agreement) => {
  if (!agreement.duration) {
    return undefined;
  }
  const regex = new RegExp(/\d+/);
  const recurring = isAnyRecurringType(agreement);
  const durationString = agreement.duration.match(regex);
  const durationNumber = parseInt(durationString[0], 10);
  const agreementNoticePeriod = agreement.noticePeriod || agreement.notice_period;
  const noticePeriodString = agreementNoticePeriod.match(regex);
  const noticePeriodNumber = parseInt(noticePeriodString[0], 10);

  let documentPeriod;
  switch (true) {
    case agreement.duration.includes('d'):
      documentPeriod = durationNumber;
      break;
    case agreement.duration.includes('w'):
      documentPeriod = durationNumber * 7;
      break;
    case agreement.duration.includes('m'):
      documentPeriod = durationNumber * 30 + Math.floor(durationNumber / 2);
      break;
    case agreement.duration.includes('y'):
      documentPeriod = durationNumber * 365;
      break;
    default:
      documentPeriod = durationNumber * 30 + Math.floor(durationNumber / 2);
      break;
  }
  if (recurring) {
    let noticePeriod;
    switch (true) {
      case agreementNoticePeriod.includes('d'):
        noticePeriod = noticePeriodNumber;
        break;
      case agreementNoticePeriod.includes('w'):
        noticePeriod = noticePeriodNumber * 7;
        break;
      case agreementNoticePeriod.includes('m'):
        noticePeriod = noticePeriodNumber * 30 + Math.floor(noticePeriodNumber / 2);
        break;
      case agreementNoticePeriod.includes('y'):
        noticePeriod = noticePeriodNumber * 365;
        break;
      default:
        noticePeriod = noticePeriodNumber * 30 + Math.floor(noticePeriodNumber / 2);
        break;
    }
    return documentPeriod - noticePeriod;
  }
  return documentPeriod;
};

export default getDocumentPeriod;
