import { ErrorCode } from 'react-dropzone';
import { Message } from '@oneflowab/pomes';

import {
  DROPZONE_EXCEED_FILE_SIZE_ERROR,
  DROPZONE_EXCEED_FILE_COUNT_ERROR,
  DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR,
} from 'components/contract-boxes/constants';

const getErrorMessageFromCode = (code: string, limitations: { attachmentsCountLimit: number }) => {
  switch (code) {
    case ErrorCode.FileInvalidType:
      return <Message id="The file type is not supported." comment="Individual Error message for file upload" />;
    case ErrorCode.FileTooLarge:
      return <Message id="The file size is too big." comment="Individual Error message for file upload" />;
    case DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR:
      return (
        <Message
          id="Upload limit of {attachmentsCountLimit} files reached."
          values={{
            attachmentsCountLimit: limitations.attachmentsCountLimit,
          }}
          comment="Individual Error message for file upload"
        />
      );
    case DROPZONE_EXCEED_FILE_SIZE_ERROR:
      return '';
    case DROPZONE_EXCEED_FILE_COUNT_ERROR:
      return '';
    default:
      return <Message id="The file couldn't be uploaded." comment="Individual Error message for file upload" />;
  }
};

export default getErrorMessageFromCode;
