// @flow

import {
  Editor,
  Transforms,
  Element as SlateElement,
} from 'slate';

export const isWrapTextActive = (editor: Editor, direction: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.wrapText === direction && n.type === 'image'
    ),
    mode: 'lowest',
  });

  return !!match;
};

export const toggleWrapText = (editor: Editor, direction: string) => {
  const isActive = isWrapTextActive(editor, direction);

  Transforms.setNodes(editor, {
    wrapText: isActive ? 'inherit' : direction,
    breakText: null,
  }, {
    match: (n) => (
      n.type === 'image'
    ),
    mode: 'lowest',
  });
};
