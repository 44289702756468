// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Calendar from 'components/icons/calendar';

const WillStart = () => (
  <>
    <Calendar height="12px" />
    <span>
      <Message
        id="Will start"
        comment="An insight for lifecycle events that indicates that a contract will start."
      />
    </span>
  </>
);

export default WillStart;
