/* eslint-disable import/named */
import { getMultiple, extractResponseBodyAsJSON, MultipleParams } from 'oneflow-client/core';

import type { NormalizedContacts } from 'oneflow-client/contacts';
import { normalizeContacts } from 'oneflow-client/contacts';

type SearchAddressBookParams = MultipleParams & {
  name: string
};

type SearchAddressBook = (options: SearchAddressBookParams) => Promise<NormalizedContacts>;

export const searchAddressBook: SearchAddressBook = ({
  pagination,
  params,
}) => (
  getMultiple({
    url: '/address_book/persons/',
    pagination,
    params,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeContacts)
);
