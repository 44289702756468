// @flow

import uniqBy from 'lodash/uniqBy';
import { get, extractResponseBodyAsJSON } from 'oneflow-client/core';

export const normalizeDashboard = (data) => ({
  dashboard: {
    ...data,
  },
  result: ['dashboard'],
  count: 1,
});

export const getDashboard = ({
  params: {
    workspaceId,
    positions,
    fromDate,
    toDate,
  },
}) => (
  get({
    url: '/dashboard/',
    params: {
      collection_id: workspaceId,
      from_date: fromDate,
      to_date: toDate,
      positions: uniqBy(positions),
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeDashboard)
);
