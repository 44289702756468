// @flow

import {
  Editor,
  Element as SlateElement,
  Transforms,
} from 'slate';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const isBlockActive = (editor: any, type: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => (
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type
    ),
  });

  return !!match;
};

export const toggleBlock = (editor: any, type: string) => {
  const isActive = isBlockActive(editor, type);
  const isList = LIST_TYPES.includes(type);

  Transforms.unwrapNodes(editor, {
    match: (n) => (
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type,
      )
    ),
    split: true,
  });

  if (isActive) {
    Transforms.setNodes(editor, {
      type: 'paragraph',
    });

    return;
  }

  if (!isList) {
    Transforms.setNodes(editor, { type });

    return;
  }

  Transforms.setNodes(editor, {
    type: 'list-item',
  });

  const block = { type, children: [] };
  Transforms.wrapNodes(editor, block);
};
