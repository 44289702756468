// @flow

import {
  Range,
  Editor,
  Transforms,
  Text,
} from 'slate';
import omit from 'lodash/omit';
import { isSelectionEmpty } from 'components/rich-text-editor/utils';

/* eslint-disable no-param-reassign */
const withDataFields = (editor: Editor) => {
  const {
    isVoid,
    isInline,
    addMark,
    removeMark,
  } = editor;

  editor.isVoid = (element) => (
    element.type === 'data-field' ? true : isVoid(element)
  );

  editor.isInline = (element) => (
    element.type === 'data-field' ? true : isInline(element)
  );

  editor.addMark = (key, value) => {
    const { selection } = editor;

    if (selection) {
      const [...dataFields] = Editor.nodes(editor, {
        match: (n) => (
          n.type === 'data-field'
        ),
      });

      dataFields.forEach(([dataField]) => {
        Transforms.setNodes(editor, {
          marks: {
            ...dataField.marks,
            [key]: value,
          },
        }, {
          match: (n) => (n === dataField),
          hanging: true,
        });
      });
    }

    if (!isSelectionEmpty(selection) && Range.isExpanded(selection)) {
      Transforms.setNodes(
        editor,
        { [key]: value },
        { match: Text.isText, split: true, hanging: true },
      );

      return;
    }

    addMark(key, value);
  };

  editor.removeMark = (key) => {
    const { selection } = editor;

    if (selection) {
      const [...dataFields] = Editor.nodes(editor, {
        match: (n) => (
          n.type === 'data-field'
        ),
      });

      dataFields.forEach(([dataField]) => {
        Transforms.setNodes(editor, {
          marks: {
            ...omit(dataField.marks, key),
          },
        }, {
          match: (n) => (n === dataField),
          hanging: true,
        });
      });
    }

    if (Range.isExpanded(selection)) {
      Transforms.unsetNodes(editor, key, {
        match: Text.isText,
        split: true,
        hanging: true,
      });

      return;
    }

    removeMark(key);
  };

  return editor;
};

export default withDataFields;
