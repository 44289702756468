// @flow

const createAudioContext = () => {
  if (typeof AudioContext === 'undefined') {
    throw new Error('Unsupported browser');
  }

  return new AudioContext();
};

export default createAudioContext;
