import { useMemo } from 'react';
import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';

import { getAllWorkspaces } from 'reducers/entities/workspaces';

import TerminateIcon from 'components/icons/terminate';

import style from './currency-warning.module.scss';

type Props = {
  selectedDocumentsData: Oneflow.Agreement[],
  selectedWorkspace: Oneflow.Workspace,
};

export const CurrencyWarning = ({
  selectedDocumentsData,
  selectedWorkspace,
}: Props) => {
  const allAvailableWorkspaces = useSelector(getAllWorkspaces);

  const allAvailableWorkspacesIdMap = useMemo(
    () => allAvailableWorkspaces.reduce<Record<number, Oneflow.Workspace>>(
      (acc, _workspace) => {
        acc[_workspace.id] = _workspace;

        return acc;
      },
      {},
    ),
    [allAvailableWorkspaces],
  );

  const selectedDocumentsWithDifferentCurrencies = useMemo(
    () => selectedDocumentsData.filter(
      ({ collection, agreementValue }) => {
        if (!collection || !agreementValue) {
          return false;
        }

        const workspace = allAvailableWorkspacesIdMap[collection?.id];

        return workspace?.currency !== selectedWorkspace.currency;
      },
    ),
    [allAvailableWorkspacesIdMap, selectedDocumentsData, selectedWorkspace],
  );

  if (!selectedDocumentsWithDifferentCurrencies.length || !selectedWorkspace.currency) {
    return null;
  }

  return (
    <div>
      <div className={style.WarningMessage} data-testid="warning-message">
        <TerminateIcon width={10} height={10} className={style.WarningIcon} />
        <Message
          id="The selected workspace has a different currency. The document value remains the same but is displayed in the target workspace's currency."
          comment="Warning text for moving contract to a workspace with a different currency than the current workspace."
        />
      </div>
      <div className={style.WorkspaceCurrencyWarning}>
        <div className={style.GridContainer}>
          <div className={style.GridHeader}>
            <div className={style.DocumentIdColumnHeader}>
              <Message
                id="Document ID"
                comment="Header for contract value."
              />
            </div>
            <div className={style.CurrencyColumnHeader}>
              <Message
                id="Current workspace"
                comment="Header for current workspace."
              />
            </div>
            <div className={style.ArrowColumnHeader} aria-label="→" />
            <div className={style.CurrencyColumnHeader}>
              <Message
                id="Target workspace"
                comment="Header for target workspace."
              />
            </div>
          </div>
          <div className={style.GridBody}>
            {selectedDocumentsWithDifferentCurrencies
              .map(({ id, agreementValue }) => (
                <div key={id} className={style.GridRow} data-testid="row">
                  <div data-testid="document-id-cell">{id}</div>
                  <div className={style.TextCenter} data-testid="current-workspace-cell">
                    {agreementValue?.amount}
                    {' '}
                    <span className={style.LineThrough}>
                      {agreementValue?.currency}
                    </span>
                  </div>
                  <div className={style.TextCenter}>→</div>
                  <div className={style.TextCenter} data-testid="target-workspace-cell">
                    {agreementValue?.amount}
                    {' '}
                    {selectedWorkspace.currency}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
