import { Message } from '@oneflowab/pomes';

import style from './extension-sidebar-status.module.scss';

type Props = {
  extension: Oneflow.Extension;
};

const ExtensionSidebarStatusComponent = ({ extension }: Props) => {
  const renderHeader = () => {
    const { isEntitled, state } = extension;
    const isEnabled = state === true;

    // Extension is enabled, which means we can keep it simple, show it's enabled and included
    if (isEnabled) {
      return (
        <>
          <h3 className={style.ExtensionSidebarHeader}>
            <Message
              id="Enabled"
              comment="Extension sidebar status header."
            />
          </h3>
          <div className={style.ExtensionSidebarPlanInformation}>
            <Message
              id="included in your current plan"
              comment="Extension sidebar status plan information (do not capitalize)."
            />
          </div>
        </>
      );
    }

    // Extension is not enabled, but you're entitled to adding it, show it's available and included
    if (isEntitled) {
      return (
        <>
          <h3 className={style.ExtensionSidebarHeader}>
            <Message
              id="Available"
              comment="Extension sidebar status header."
            />
          </h3>
          <div className={style.ExtensionSidebarPlanInformation}>
            <Message
              id="included in your current plan"
              comment="Extension sidebar status plan information (do not capitalize)."
            />
          </div>
        </>
      );
    }

    // Extension is not enabled and you're not entitled to adding it, show upgrade
    return (
      <>
        <h3 className={style.ExtensionSidebarHeader}>
          <Message
            id="Upgrade"
            comment="Extension sidebar status header."
          />
        </h3>
        <div className={style.ExtensionSidebarPlanInformation}>
          <Message
            id="not included in your current plan"
            comment="Extension sidebar status plan information (do not capitalize)."
          />
        </div>
      </>
    );
  };

  return (
    <div className={style.ExtensionSidebarStatus}>
      {renderHeader()}
    </div>
  );
};

export default ExtensionSidebarStatusComponent;
