// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import { isBlockActive, toggleBlock } from './block-plugin';
import ToolbarButton from '../toolbar-button';

type Props = {
  type: any,
  icon: React.Node,
  isMenuItem: boolean,
  label: React.Node,
  disabled: boolean,
};

const BlockButton = ({
  type,
  icon,
  label,
  isMenuItem,
  disabled,
}: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      isMenuItem={isMenuItem}
      disabled={disabled}
      label={label}
      isActive={isBlockActive(editor, type)}
      onTrigger={() => {
        focusEditor();
        toggleBlock(editor, type);
      }}
      icon={icon}
    />
  );
};

export default BlockButton;
