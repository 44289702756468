// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Reminder from 'components/icons/reminder';

const OverdueReminder = () => (
  <>
    <Reminder height="12px" />
    <span>
      <Message
        id="Overdue reminder"
        comment="An insight for lifecycle events that reminds users that a contract is overdue or will be overdue."
      />
    </span>
  </>
);

export default OverdueReminder;
