// @flow

import React, { useLayoutEffect } from 'react';
import { Editor } from 'slate';
import { useFocused } from 'slate-react';

const useCurrentActiveMarks = (editor: any) => {
  const focused = useFocused();
  const marks = Editor.marks(editor);
  const editorMarksRef = React.useRef(marks);

  useLayoutEffect(() => {
    // when selection becomes null it means toolbar button is clicked and
    // editor is not focused and we ignore that
    if (focused) {
      editorMarksRef.current = marks;
    }
  }, [focused, marks]);

  return editorMarksRef.current;
};

export default useCurrentActiveMarks;
