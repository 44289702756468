import { get } from 'lodash';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import { BetaLabel } from 'components/beta-label';

import style from './footer.module.scss';

const TOO_MANY_REQUESTS = 429;

const Footer = ({ error }: { error: any }) => (
  <div className={style.Footer}>
    <div
      className={clsx(style.Left, {
        [style.Error]: get(error, 'body.status_code') === TOO_MANY_REQUESTS,
      })}
    >
      {get(error, 'body.status_code') === TOO_MANY_REQUESTS ? (
        <Message
          id="It looks like you've reached today's limit of AI responses. Don't worry, you can try again tomorrow."
          comment="Footer text that explains an error occurred"
        />
      ) : (
        <Message
          id="Do not include any personally identifiable information (PII) in your input or highlighted text."
          comment="Footer text that explains what not to include in the input or highlighted text"
          data-testid="footer-text"
        />
      )}
    </div>
    <div className={style.Right}>
      <BetaLabel />
    </div>
  </div>
);

export default Footer;
