// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

const subdomainPattern = /^[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?$/i;

type WebhookPlaceholderValidator = ({
  message: MessageTranslator,
}) => Validator;

const webhookPlaceholderValidator: WebhookPlaceholderValidator = (
  { message },
) => (value: string) => {
  if (value && subdomainPattern.test(value)) {
    return undefined;
  }

  return message({
    id: 'Invalid configuration value',
    comment: 'Validation message for configuration value.',
  });
};

export default webhookPlaceholderValidator;
