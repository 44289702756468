import React, { useEffect } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import MoveIcon from 'components/icons/move';

import style from './draggable-field.module.scss';

type DraggableFieldProps = {
  activeId: string | null,
  children: React.ReactNode,
  columnIndex: number,
  data: any,
  field: any,
  fieldKey: string,
  id: string,
  isValueEditable: boolean,
  rowIndex: number,
  setActiveField: (field: any) => void,
  setActiveId: (id: string | null) => void,
};

const DraggableField = ({
  id,
  rowIndex,
  columnIndex,
  data,
  field,
  fieldKey,
  setActiveField,
  activeId,
  setActiveId,
  children,
}: DraggableFieldProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
  } = useDraggable({ id });

  useEffect(() => {
    if (isDragging && activeId !== id) {
      setActiveId(id);
      setActiveField({
        rowIndex,
        columnIndex,
        data,
        field,
        key: fieldKey,
      });
    }

    if (!isDragging) {
      setActiveId(null);
    }
  }, [
    activeId,
    columnIndex,
    data,
    field,
    fieldKey,
    id,
    isDragging,
    rowIndex,
    setActiveField,
    setActiveId,
  ]);

  const styleOwn = {
    transform: CSS.Translate.toString(transform),
    visibility: isDragging ? 'hidden' : 'visible',
  };

  return (
    <div ref={setNodeRef} style={styleOwn} className={style.DragHandlerWrapper}>
      <div className={style.Center}>
        <div className={style.IconWrapper} {...listeners} {...attributes}>
          <div className={style.Icon}>
            <MoveIcon height="14px" />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default DraggableField;
