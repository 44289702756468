import { Message } from '@oneflowab/pomes';
import ModalForm from 'hocs/modal-form';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNode, useCallback } from 'react';
import { push } from 'connected-react-router';

import groupsReducer from 'reducers/entities/groups';
import rolesReducer from 'reducers/entities/roles';
import workspaceGroupsReducer from 'reducers/entities/workspace-groups';
import workspacesReducer from 'reducers/entities/workspaces';

import Field from 'components/field';
import SelectField from 'components/select-field';
import type { Pagination } from 'types/request-params';
import { USER_ROLE_SCOPE_WORKSPACE } from 'user/constants';

export type AvailableGroup = {
  id: Oneflow.Group['id'],
  name: Oneflow.Group['name'],
}
export type FormData = {
  group: AvailableGroup,
  role: Oneflow.Role,
}

export type QueryFuncArgs = {
  name: string,
  pagination: Pagination
}

export type LoadMoreArgs = {
  additionalResults: number
}

const defaultPagination = {
  limit: 10000,
  offset: 0,
};

export type Props = {
  children: ReactNode,
  shouldRedirectToWorkspaceAccess: boolean,
  workspace: Oneflow.Workspace,
}

export const GrantGroupAccess = ({
  children,
  shouldRedirectToWorkspaceAccess,
  workspace,
}: Props) => {
  const dispatch = useDispatch();
  const GROUPS_QUERY_NAME = 'admin/groups';

  const queryName = `grant-access/${workspace.id}/group`;
  const rolesQuery = useSelector(
    (state) => rolesReducer.getQuerySelector(state, { name: queryName }),
  );
  const roles = useSelector(
    (state) => rolesReducer.getRolesSelector(state, { ids: rolesQuery.result }),
  );

  const availableGroupsQuery = useSelector(
    (state) => groupsReducer.getQuerySelector(state, { name: GROUPS_QUERY_NAME }),
  );
  const availableGroups = useSelector(
    (state) => groupsReducer.getGroupsSelector(state, { ids: availableGroupsQuery.result }),
  );
  const formState = useSelector(workspaceGroupsReducer.getCreateSelector);

  const resetFormState = () => {
    dispatch(workspaceGroupsReducer.createWorkspaceGroupReset());
  };

  const onSubmit = ({ group, role }: FormData) => {
    const actionData = {
      data: {
        resourceType: 'collection',
        resourceId: workspace.id,
        actorType: 'group',
        actorId: group.id,
        roleId: role.id,
      },
      pipe: {
        onSuccess: () => {
          dispatch(workspaceGroupsReducer.queryWorkspaceGroupsReload({
            name: `workspace/${workspace.id}/relationships`,
          }));

          if (workspace.id) {
            dispatch(workspacesReducer.fetchWorkspace({
              id: workspace.id,
              params: {
                accessStats: 1,
                includeAgreementStats: 1,
              },
            }));
          }
        },
      },
    };

    if (shouldRedirectToWorkspaceAccess) {
      actionData.pipe.onSuccess = undefined;
      actionData.pipe.action = () => push(`/admin/workspaces/${workspace.id}/access`);
    }

    dispatch(workspaceGroupsReducer.createWorkspaceGroup(actionData));
  };
  const queryAvailableGroups = useCallback(
    ({ name, pagination = defaultPagination }: QueryFuncArgs) => {
      dispatch(groupsReducer.queryGroups({
        name: GROUPS_QUERY_NAME,
        params: {
          q: name,
        },
        pagination,
      }));
    }, [dispatch, GROUPS_QUERY_NAME],
  );

  const queryAvailableGroupsLoadMore = useCallback(
    (additionalResults: number) => {
      dispatch(groupsReducer.queryGroupsLoadMore({
        name: GROUPS_QUERY_NAME,
        additionalResults,
      }));
    }, [dispatch, GROUPS_QUERY_NAME],
  );

  const queryRoles = useCallback(
    ({ name, pagination = defaultPagination }: QueryFuncArgs) => {
      dispatch(rolesReducer.queryRoles({
        name: queryName,
        params: {
          q: name,
          scope: USER_ROLE_SCOPE_WORKSPACE,
        },
        pagination,
        sort: ['-type', 'name'],
      }));
    }, [dispatch, queryName],
  );

  const queryRolesLoadMore = useCallback(({ additionalResults }: LoadMoreArgs) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: queryName,
      additionalResults,
    }));
  }, [dispatch, queryName]);

  const runInitialQueries = useCallback(() => {
    queryAvailableGroups({});
    queryRoles({});
  }, [queryAvailableGroups, queryRoles]);

  const handleLoadMoreRoles = useCallback((additionalResults: number) => {
    queryRolesLoadMore({
      additionalResults,
    });
  }, [queryRolesLoadMore]);

  const handleGroupInputChange = useCallback((name: string) => {
    queryAvailableGroups({
      name,
      pagination: availableGroupsQuery.pagination,
    });
  }, [queryAvailableGroups, availableGroupsQuery.pagination]);

  const handleRoleInputChange = useCallback((name: string) => {
    queryRoles({
      name,
      pagination: rolesQuery.pagination,
    });
  }, [queryRoles, rolesQuery.pagination]);

  const renderBody = useCallback(() => (
    <div>
      <Field
        name="group"
        label={(
          <Message
            id="Group"
            comment="Input label"
          />
        )}
        placeholder={(
          <Message
            id="Select group"
            comment="Input field placeholder"
          />
        )}
        component={SelectField}
        options={availableGroups}
        onInputChange={handleGroupInputChange}
        valueKey="id"
        labelKey="name"
        isLoading={availableGroupsQuery.loading}
        loadMoreItems={queryAvailableGroupsLoadMore}
        required
      />
      <Field
        name="role"
        label={<Message id="Role" comment="The label of the relevant field" />}
        placeholder={<Message id="Select role" comment="The placeholder of the relevant field" />}
        component={SelectField}
        options={roles}
        onInputChange={handleRoleInputChange}
        valueKey="id"
        labelKey="name"
        backspaceRemoves
        isLoading={rolesQuery.loading}
        loadMoreItems={handleLoadMoreRoles}
        required
      />
    </div>
  ), [
    availableGroups,
    handleGroupInputChange,
    availableGroupsQuery.loading,
    roles,
    handleRoleInputChange,
    rolesQuery.loading,
    handleLoadMoreRoles,
    queryAvailableGroupsLoadMore,
  ]);

  return (
    <ModalForm
      title={(
        <Message
          id="Grant group access"
          comment="Modal title for granting group access"
        />
      )}
      body={renderBody()}
      onSubmit={onSubmit}
      resetFormState={resetFormState}
      formState={formState}
      onOpen={runInitialQueries}
      modalKey="grant group access modal"
    >
      {children}
    </ModalForm>
  );
};

export default GrantGroupAccess;
