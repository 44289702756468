import type { ReactNode } from 'react';
import EmptyState from 'components/empty-state';
import TypeCursorIcon from 'components/icons/type-cursor';

import style from '../data-fields-tab.module.scss';

type Props = {
  headerMessage: ReactNode,
  contentMessage: ReactNode,
};

const EmptyStateDataFields = ({
  headerMessage,
  contentMessage,
}: Props) => (
  <EmptyState
    icon={(
      <div className={style.IconWrapper}>
        <TypeCursorIcon height="50px" width="50px" />
      </div>
    )}
    header={(
      <div className={style.NoTemplateGroupHeader}>
        {headerMessage}
      </div>
    )}
    className={style.NoTemplateGroupEmptyState}
    content={contentMessage}
  />
);

export default EmptyStateDataFields;
