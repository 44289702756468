// @flow

import React from 'react';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import Divider from 'components/divider';
import ExternalLink from 'components/icons/external-link';
import { checkAcl } from 'components/acl';
import EidCountryPicker from 'components/eid-country-picker';

import extensionSettings from 'hocs/extension-settings';
import { ExtensionConfigurationHeader } from 'components/extension/extension-configuration-header';

import { ElectronicId } from './electronic-id';
import style from './electronic-ids.module.scss';
import { getEidHubList } from './electronic-ids-eid-hub-items';
import { getEidStandAloneList } from './electronic-ids-standalone-items';

export type Props = {
  extension: Extension,
  updateExtension: (Extension) => void,
  currentLanguage: EnabledLanguages,
  message: MessageTranslator,
};

type EidKey = string;

export class ElectronicIdsPage extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      selectedCountry: '',
      eidList: [],
    };
  }

  componentDidMount() {
    this.updateEidList();
  }

  handleEidToggle = (eidKey: EidKey) => () => {
    const { updateExtension, extension } = this.props;
    const newEidValue = !extension.config[eidKey];

    updateExtension({
      id: extension.extensionId,
      config: {
        ...extension.config,
        [(eidKey: string)]: newEidValue,
      },
    });
  };

  onCountryChange = (countryCode: string) => {
    this.setState({ selectedCountry: countryCode }, this.updateEidList);
  };

  updateEidList = () => {
    const { selectedCountry } = this.state;
    const { message } = this.props;

    const updatedList = getEidHubList(message, selectedCountry);

    this.setState({ eidList: updatedList });
  };

  renderEidHub = () => {
    const { extension, currentLanguage } = this.props;
    const { config } = extension;
    const canUpdate = checkAcl(extension.acl, 'extension:config:update', { match: 'any' });

    const items = this.state.eidList;

    const getEids = () => items.map(({
      key,
      title,
      description,
      isEnabled,
      isVisible,
      countries,
    }) => (
      <ElectronicId
        key={key}
        itemKey={key}
        isChecked={config[key]}
        isEnabled={isEnabled}
        isVisible={isVisible}
        canUpdate={canUpdate}
        onEidToggle={this.handleEidToggle(key)}
        title={title}
        description={description}
        currentLanguage={currentLanguage}
        countries={countries}
      />
    ));

    return (
      <>
        <Divider />
        <h4 className={style.Header}>
          <Message
            id="E-ID Hub"
            comment="Header for E-ID Hub"
          />
        </h4>
        <div className={style.DescriptionContainer}>
          <div className={style.Description}>
            <Message
              id="The E-ID hub allows your document participants to sign with their preferred electronic ID. Selecting the E-ID hub as a sign method on a participant in a document or template will let them choose from the Electronic IDs you have enabled."
              comment="Description for E-ID Hub"
            />
          </div>
          <div className={style.CountryPicker}>
            <EidCountryPicker
              currentLanguage={currentLanguage}
              selectedCountry={this.state.selectedCountry}
              onCountryChange={this.onCountryChange}
            />
          </div>
        </div>
        <div className={style.MainContainer}>
          {getEids()}
        </div>
      </>
    );
  }

  renderStandAlone = () => {
    const { extension, currentLanguage, message } = this.props;
    const { config } = extension;
    const canUpdate = checkAcl(extension.acl, 'extension:config:update', { match: 'any' });

    const items = getEidStandAloneList(message);

    const getEids = () => items.map(({
      key,
      title,
      description,
      isEnabled,
      isVisible,
      countries,
    }) => (
      <ElectronicId
        key={key}
        itemKey={key}
        isChecked={config[key]}
        isEnabled={isEnabled}
        isVisible={isVisible}
        canUpdate={canUpdate}
        onEidToggle={this.handleEidToggle(key)}
        title={title}
        description={description}
        currentLanguage={currentLanguage}
        countries={countries}
      />
    ));

    return (
      <>
        <h4 className={style.Header}>
          <Message
            id="Stand-alone"
            comment="Header for stand-alone electronic IDs"
          />
        </h4>
        <div className={style.DescriptionContainer}>
          <div className={style.Description}>
            <Message
              id="These electronic IDs are added as stand-alone sign methods in your documents when you want your participants to use a specific electronic ID."
              comment="Description for stand-alone electronic IDs"
            />
          </div>
        </div>
        <div className={style.MainContainer}>
          {getEids()}
        </div>
      </>
    );
  }

  getPricelistLink = (url: string) => (
    <a
      className={style.ExternalLink}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Message
        id="Price list"
        comment="Used as the text of for the electronic price list link"
      />
      <ExternalLink height="14px" className={style.ExternalLinkIcon} />
    </a>
  );

  renderBillingInfo = () => (
    <div>
      <Divider />
      <span>
        <Message
          id="Your account will be billed quarterly based on your usage. See prices at {priceListLink}"
          comment="Help text for the electronic IDs extension."
          values={{
            priceListLink: this.getPricelistLink('https://oneflow.com/pricing/electronic-ids'),
          }}
        />
      </span>
    </div>
  )

  render() {
    return (
      <div>
        <ExtensionConfigurationHeader />
        {this.renderStandAlone()}
        {this.renderEidHub()}
        {this.renderBillingInfo()}
      </div>
    );
  }
}

export default localize <Props>(extensionSettings(ElectronicIdsPage));
