// @flow

import { put, select } from 'redux-saga/effects';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getAgreementTemplates,
  createAgreementTemplate,
  updateAgreementTemplate,
  removeAgreementTemplates,
  shareAgreementTemplate,
} from 'oneflow-client/agreement-templates';

import type { NormalizedAgreementTemplates } from 'oneflow-client/agreement-templates';
import type { PutEffect, SelectEffect } from 'redux-saga';

import apiWrapper from 'sagas/api-wrapper';

import agreementTemplates from 'reducers/entities/agreement-templates';
import agreements from 'reducers/entities/agreements';
import tagConnections from 'reducers/entities/tag-connections';
import tags from 'reducers/entities/tags';
import { getCurrentWorkspaceSelector } from 'reducers/app';

type ExtraAgreementTemplateArguments = {
  workspaceId: number,
}

type ExtraQueryParams = {
  params: ExtraAgreementTemplateArguments,
}

type ExtraCreateParams = ExtraAgreementTemplateArguments;

type SetWorkspaceId = Generator<SelectEffect<any, any>, ExtraQueryParams | ExtraCreateParams, any>;

export function* setCurrentWorkspaceIdForQuery(): SetWorkspaceId {
  const workspace = yield select(getCurrentWorkspaceSelector);

  return {
    params: {
      workspaceId: workspace.id,
    },
  };
}

type MapperArgs = {
  data: NormalizedAgreementTemplates,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(tagConnections.setTagConnections(data.entities.tagConnections));
  yield put(tags.setTags(data.entities.tags));
  yield put(agreements.setAgreements(data.entities.agreements));
  yield put(agreementTemplates.setAgreementTemplates(data.entities.agreementTemplates));
}

export function* updateMapper({ data }: MapperArgs): Mapper {
  yield put(agreementTemplates.setAgreementTemplates(data.entities.agreementTemplates));
}

export function* clearMapper({ action }: MapperArgs): Mapper {
  yield put(agreementTemplates.clearAgreementTemplate({ ids: action.data.templateIds }));
}

const mappers = {
  query: {
    prepare: setCurrentWorkspaceIdForQuery,
    mapper,
    request: getAgreementTemplates,
  },
  create: {
    mapper,
    request: createAgreementTemplate,
  },
  update: {
    mapper: updateMapper,
    request: updateAgreementTemplate,
  },
  remove: {
    mapper: clearMapper,
    request: removeAgreementTemplates,
  },
  rpcs: {
    shareAgreementTemplate: {
      name: 'shareAgreementTemplate',
      request: shareAgreementTemplate,
    },
  },
};

const agreementTemplatesSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: agreementTemplates,
  mappers,
});

export default agreementTemplatesSagas;
