import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { getCurrentWorkspaceSelector } from 'reducers/app';

import { getGuestToken } from 'agreement/selectors';
import DocumentActions from 'components/document-actions';
import ArrowLeftIcon from 'components/icons/arrow-left';
import Button from 'components/button';

import useAgreement from 'hooks/use-agreement';
import LeftSide from '../left-side';
import style from './collapsed-document-topbar.module.scss';
import GuestTopBar from './guest-topbar';

type Props = {
  agreementId: Oneflow.Document['id']
}

const CollapsedDocumentTopBar = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const workspace = useSelector(getCurrentWorkspaceSelector);
  const dispatch = useDispatch();
  const guestToken = useSelector(getGuestToken);
  const isGuestView = Boolean(guestToken);
  const companyName = agreement.parties?.[0]?.name ?? '';
  const { logoUrl } = agreement;

  return (
    <div className={style.Topbar}>
      {isGuestView ? (
        <GuestTopBar
          companyName={companyName}
          logoUrl={logoUrl}
        />
      ) : (
        <>
          <div className={style.LeftSideContainer}>
            <Button
              onClick={() => {
                dispatch(push(`/c/${workspace.id}/contracts`));
              }}
              className={style.IconButton}
            >
              <ArrowLeftIcon height="24" />
            </Button>
            <LeftSide agreement={agreement} />
          </div>
          <DocumentActions agreementId={agreementId} />
        </>
      )}
    </div>
  );
};

export default CollapsedDocumentTopBar;
