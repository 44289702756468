// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import { Message } from '@oneflowab/pomes';

import { withWorkspaceNamespace } from 'hocs/with-current-workspace';

import { checkAcl } from 'components/acl';

type Props = {
  workspace: Workspace,
}

export const getTemplatesCount = (count?: number) => (
  <Message
    id="{count} template"
    pluralId="{count} templates"
    pluralCondition="count"
    values={{
      count: count || 0,
    }}
    comment="Showing number of templates a workspace contains"
  />
);

export const WorkspaceTemplatesCount = ({ workspace }: Props) => {
  if (!checkAcl(workspace.acl, 'collection:module:template:use')) {
    return <span>{getTemplatesCount(workspace.templateCount)}</span>;
  }

  return (
    <Link to={withWorkspaceNamespace(workspace)('/templates')}>
      {getTemplatesCount(workspace.templateCount)}
    </Link>
  );
};
