// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import workspacesReducer from 'reducers/entities/workspaces';

import { DeleteWorkspaces, type Props } from './delete-workspaces';

export const QUERY_NAME = 'admin/workspaces';
export const requestName = 'bulk';

type StateProps = {|
  removeState: RemoveState,
|};

type DispatchProps = {|
  deleteWorkspaces: () => void,
  resetRemoveState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps>;

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  removeState: workspacesReducer.getRemoveSelector(state, {
    id: requestName,
  }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { selectedWorkspaces }) => ({
  deleteWorkspaces: () => {
    const selectedIds = selectedWorkspaces.map((workspace) => workspace.id);

    dispatch(workspacesReducer.removeWorkspace({
      id: requestName,
      data: {
        ids: selectedIds,
      },
      pipe: {
        action: () => workspacesReducer.queryWorkspacesReload({
          name: QUERY_NAME,
        }),
        wait: workspacesReducer.QUERY_WORKSPACES_SUCCESS,
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(workspacesReducer.removeWorkspaceReset({
      id: requestName,
    }));
  },
});

const connectedDeleteWorkspaces = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteWorkspaces);

export { connectedDeleteWorkspaces as DeleteWorkspaces };
