// @flow

import {
  KEY_INTELLIPLAN,
} from 'extensions';

const Intelliplan = {
  key: KEY_INTELLIPLAN,
  typeSpecificConfigKeys: [
    'intelliplanCustomerDomain',
  ],
};

export default Intelliplan;
