// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import Field from 'components/field';
import SelectField from 'components/select-field';
import Button from 'components/button';
import { ConfirmButton } from 'components/buttons';
import TemplateIcon from 'components/icons/template';
import { getWorkspaceTypesAsOptions } from 'components/workspace-type';
import TemplatePicker from 'components/template-picker';

import style from './template-library.module.scss';

export type Props = {
  currentWorkspaceId: number,
  message: MessageTranslator,
  createState: CreateState,
  resetCreateState: () => void,
  requestPublicTemplates: () => void,
  copyTemplate: (number, number, string) => void,
  templates: Array<Template>,
};

type State = {
  category?: string,
  selectedItems: any,
};

type GetActions = ({ formProps: any, closeConfirmation: () => void }) => React.Node;

export class TemplateLibraryComponent extends React.Component<Props, State> {
  state = {
    category: 'all',
    selectedItems: [],
  };

  handleCategorySelect = (option: Option) => {
    if (!option) {
      return;
    }

    this.setState({
      category: option.value,
      selectedItems: [],
    });
  };

  handleSubmit = () => {
    const {
      copyTemplate,
      currentWorkspaceId,
    } = this.props;

    const items = this.state.selectedItems;
    items.forEach((item) => {
      copyTemplate(currentWorkspaceId, item.agreementId, item.title);
    });
  };

  getTemplatesForCategory = (): Array<Template> => {
    const { templates } = this.props;
    const { category } = this.state;

    if (category === 'all') {
      const removeDuplicates = (array: Array<Template>) => array.filter((
        template: Template,
        index,
        arr,
      ) => {
        if (!template.agreement) {
          return template;
        }

        return arr.findIndex((item) => item.agreement === template.agreement) === index;
      });

      return removeDuplicates(templates);
    }

    return templates.filter((template) => template.type === category);
  };

  onTemplatesSelectionChange = (item: any) => {
    this.setState({ selectedItems: [item] });
  };

  getCategories = () => {
    const { message } = this.props;

    const workspaceTypes = getWorkspaceTypesAsOptions(message);
    const customTypes = [{
      value: 'all',
      label: message({
        id: 'All',
        comment: 'Template library category label for All option',
      }),
    }];

    return customTypes.concat(workspaceTypes);
  };

  getActions: GetActions = ({ formProps }) => {
    const { createState } = this.props;
    const { selectedItems } = this.state;

    return (
      <ConfirmButton
        isLoading={createState.loading}
        disabled={
          createState.loading
          || selectedItems.length === 0
          || formProps.validating
          || formProps.hasValidationErrors
        }
        onClick={this.handleSubmit}
      />
    );
  };

  renderBody() {
    const { message } = this.props;
    const { category } = this.state;

    return (
      <div className={style.TemplateLibrary}>
        <div className={style.Title}>
          <Message id="Category" comment="Header in template library modal" />
        </div>
        <Field
          name="type"
          label={message({
            id: 'Category',
            comment: 'Label for the category field on the template library modal.',
          })}
          placeholder={message({
            id: 'Select category',
            comment: 'Category placeholder on the template library modal.',
          })}
          hideLabel
          component={SelectField}
          options={this.getCategories()}
          onChange={this.handleCategorySelect}
          clearable={false}
          searchable={false}
          defaultValue={category}
        />
        <div className={style.TemplateList}>
          {this.renderTemplatePicker()}
        </div>
      </div>
    );
  }

  renderTemplatePicker() {
    if (!this.state.category) {
      return null;
    }

    return (
      <TemplatePicker
        templates={this.getTemplatesForCategory()}
        onSelectionChange={this.onTemplatesSelectionChange}
        selectedItems={this.state.selectedItems}
      />
    );
  }

  renderChildren = (onClick: Function) => (
    <div className={style.TemplateLibraryButton}>
      <Button
        data-testid="template-library"
        icon={TemplateIcon}
        kind="special"
        onClick={onClick}
        trackable="Go To Template Library"
      >
        <Message id="Template library" comment="Used as the label of the template library button" />
      </Button>
    </div>
  );

  render() {
    const {
      message,
      createState,
      requestPublicTemplates,
      resetCreateState,
    } = this.props;

    return (
      <ModalForm
        title={message({
          id: 'Template library',
          comment: 'Title for the template library modal.',
        })}
        body={this.renderBody()}
        resetFormState={resetCreateState}
        formState={createState}
        onSubmit={this.handleSubmit}
        portalClassName={style.TemplateLibraryModalPortal}
        isWideModal
        onOpen={requestPublicTemplates}
        actions={this.getActions}
        modalKey="template library modal"
        shouldRenderStaticScrollbar
      >
        {this.renderChildren}
      </ModalForm>
    );
  }
}

export default localize<Props>(TemplateLibraryComponent);
