import {
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext,
  createContext,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import client from 'oneflow-client';
import { Message } from '@oneflowab/pomes';
import UUID from 'node-uuid';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import type { DropzoneProps, FileError as DropzoneFileError } from 'react-dropzone';

import useAgreement from 'hooks/use-agreement';
import useCurrentBox from 'hooks/use-current-box';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import { useAsyncAssetProps } from 'contexts/async-asset-props';
import { getAccountFromSessionSelector, getPositionFromSessionSelector } from 'reducers/session';
import { getAttachmentsCountLimit } from 'agreement';
import { isUserLimited } from 'user';
import {
  getGuestToken,
  getMyParticipantWhenUpdater,
  isAgreementOwner,
} from 'agreement/selectors';
import {
  addAttachmentFile,
  updateTotalFileCountAndSize,
  getAttachedFilesTotalSize,
  getAttachedFilesTotalCount,
  FILES_UPLOAD_STATUS,
  FILES_UPLOAD_FAIL,
  FILES_DROP,
  FILES_UPDATE_STATUS,
  FILES_UPLOAD_FAIL_BATCH,
  FILES_STATUS_BOX_MAXIMIZE,
  FILES_STATUS_BOX_OPEN,
  Asset,
} from 'reducers/current-contract';
import { AttachmentBox } from 'data-validators/entity-schemas/document-box/attachment-box';

import toast from 'components/toasts';
import { fileToJson, getId } from 'components/contract-boxes/generic-box-helpers';
import {
  getAttachmentData,
  getFileRejectionsSnackbarErrorMessage,
  hasErrorCodeInRejections,
} from 'components/contract-boxes/attachment-box/attachment-box-helpers';

import {
  DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR,
  DROPZONE_EXCEED_FILE_SIZE_ERROR,
  DROPZONE_EXCEED_FILE_COUNT_ERROR,
  UNKNOWN_ERROR,
  API_ERROR,
  MAX_TOTAL_ATTACHMENT_SIZE_PER_CONTRACT,
} from 'components/contract-boxes/constants';
import { getErrorMessage } from 'components/api-error';

import { FileRejectionWithId } from 'components/contract-boxes/attachment-box/types';

type ContextValue = {
  box: AttachmentBox,
  agreement: Oneflow.Agreement,
  isOwner: boolean | null | undefined,
  loading: boolean,
  attachmentData: AttachmentBox['content']['data'],
  guestToken: string,
  editable: boolean,
  counterpartEdit: boolean,
  requiredForSignatures: boolean,
  colleagueEdit: boolean,
  managerLock: AttachmentBox['config']['managerLock'],
  isAllowedToReorder: boolean,
  updaterParticipant: Oneflow.Participant | null | undefined,
  maxSizePerContractValidator: DropzoneProps['validator'],
  onDrop: NonNullable<DropzoneProps['onDrop']>,
  onDragEnter: NonNullable<DropzoneProps['onDragEnter']>,
  onDragLeave: NonNullable<DropzoneProps['onDragLeave']>,
  isDragOver: boolean,
  attachmentsCountLimit: number,
  uploadedFileStatus: Record<Asset['assetId'], Asset['status']>,
  setUploadedFileStatus: Dispatch<SetStateAction<Record<Asset['assetId'], Asset['status']>>>,
  contractId: number,
  onErrorHandler: (errorMessage: ReactNode) => void,
};

export const FileUploadBoxPropsContext = createContext<ContextValue | null>(null);

type AcceptedFileWithId = {
  file: File,
  id: string,
};

type HandleBatchRejectionsProps = {
  fileRejectionsWithIds: FileRejectionWithId[],
  acceptedFilesWithIds: AcceptedFileWithId[],
};

type Props = {
  children: ReactNode,
  boxId: number,
  editable: boolean,
  agreementId: number,
};

export function FileUploadBoxPropsProvider({
  children,
  boxId,
  agreementId,
  editable,
}: Props) {
  const dispatch = useDispatch();
  const {
    succeededAsyncAssetData,
    failedAsyncAssetData,
    resetFailedAsyncAssetState,
    resetSucceededAsyncAssetState,
  } = useAsyncAssetProps();
  const [loading, setLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadAssetsData, setUploadAssetsData] = useState<Record<Asset['assetId'], Asset & { fileId: string }>>({});
  const [uploadedFileStatus, setUploadedFileStatus] = useState<Record<Asset['assetId'], Asset['status']>>({});
  const accountFromSession = useSelector(getAccountFromSessionSelector);
  const position = useSelector(getPositionFromSessionSelector);
  const attachedFilesTotalSize = useSelector(getAttachedFilesTotalSize);
  const attachedFilesTotalCount = useSelector(getAttachedFilesTotalCount);
  const isInPreviewMode = useIsInPreviewMode();

  const guestToken = useSelector(getGuestToken);
  const box = useCurrentBox(boxId) as AttachmentBox;
  const data = get(box, 'content.data');

  const managerLock = get(box, 'config.managerLock');
  const counterpartEdit = Boolean(get(box, 'config.counterpartEdit'));
  const requiredForSignatures = Boolean(get(box, 'config.requiredForSignatures'));
  const colleagueEdit = get(box, 'config.colleagueEdit');

  const attachmentData = useMemo(() => {
    const rawAttachmentData = getAttachmentData(data);
    const attachmentOrder = box.config.order || [];
    const list = attachmentOrder
      .map((orderObject) => rawAttachmentData
        .find((attachment) => getId(attachment) === getId(orderObject))) as AttachmentBox['content']['data'];

    return list;
  }, [box.config.order, data]);

  const agreement = useAgreement(agreementId);
  const attachmentsCountLimit = useMemo(() => getAttachmentsCountLimit(agreement), [agreement]);

  const updaterParticipant = useMemo(() => (
    agreement?.parties && getMyParticipantWhenUpdater(agreement)
  ), [agreement]);

  const isOwner = updaterParticipant?.account && isAgreementOwner(accountFromSession, agreement);

  const isAllowedToReorder = !isUserLimited(position);
  const totalFileSizeRef = useRef(attachedFilesTotalSize);
  const totalFileCountRef = useRef(attachedFilesTotalCount);

  useEffect(() => {
    if (
      totalFileCountRef.current === attachedFilesTotalCount
      && totalFileSizeRef.current === attachedFilesTotalSize
    ) {
      return;
    }
    totalFileCountRef.current = attachedFilesTotalCount;
    totalFileSizeRef.current = attachedFilesTotalSize;
  }, [attachedFilesTotalSize, attachedFilesTotalCount]);

  const syncTotalCountAndSizeRefsWithRedux = useCallback(() => {
    dispatch(updateTotalFileCountAndSize(totalFileCountRef.current, totalFileSizeRef.current));
  }, [dispatch]);

  const resetTotalCountAndSizeRefs = useCallback(() => {
    totalFileSizeRef.current = attachedFilesTotalSize;
    totalFileCountRef.current = attachedFilesTotalCount;
  }, [attachedFilesTotalCount, attachedFilesTotalSize]);

  const addFile = useCallback(({ asset, status }: { asset: Asset, status: Asset['status'] }) => {
    const attachmentProps = {
      boxId,
      asset,
      ownerParticipantId: updaterParticipant?.id,
      status,
    };

    dispatch(addAttachmentFile(attachmentProps));
  }, [
    boxId,
    updaterParticipant?.id,
    dispatch,
  ]);

  const addNonPdfFile = useCallback(({ asset, fileId, status }: { asset: Asset, fileId: string, status: Asset['status'] }) => {
    setLoading(false);
    const attachmentProps = {
      boxId,
      asset,
      ownerParticipantId: updaterParticipant?.id,
      status,
    };

    dispatch(addAttachmentFile(attachmentProps));
    dispatch({
      type: FILES_UPDATE_STATUS,
      status: FILES_UPLOAD_STATUS.SUCCESS,
      id: fileId,
    });
    setUploadedFileStatus((prev) => ({
      ...prev,
      [asset.assetId]: status,
    }));
  }, [
    boxId,
    updaterParticipant?.id,
    dispatch,
  ]);

  const onAssetPagesDone = useCallback((asyncData: { assetId: Asset['assetId'] }, assets: typeof uploadAssetsData) => {
    const { assetId } = asyncData;
    if (!assets[assetId]) {
      return;
    }

    setLoading(false);

    dispatch({
      type: FILES_UPDATE_STATUS,
      status: FILES_UPLOAD_STATUS.SUCCESS,
      id: assets[assetId].fileId,
    });

    resetSucceededAsyncAssetState();
  }, [dispatch, resetSucceededAsyncAssetState]);

  useEffect(() => {
    if (isEmpty(uploadAssetsData)) {
      return;
    }

    if (!isEmpty(succeededAsyncAssetData)
      && uploadAssetsData[succeededAsyncAssetData.assetId]) {
      const { assetId } = succeededAsyncAssetData;
      onAssetPagesDone(succeededAsyncAssetData, uploadAssetsData);
      setUploadedFileStatus((prev) => ({
        ...prev,
        [assetId]: succeededAsyncAssetData.status,
      }));

      resetSucceededAsyncAssetState();
    }

    if (!isEmpty(failedAsyncAssetData)
      && uploadAssetsData[failedAsyncAssetData.assetId]) {
      const { assetId, apiCode } = failedAsyncAssetData;
      const fileId = get(uploadAssetsData[assetId], 'fileId');
      if (!fileId) {
        return;
      }

      setUploadedFileStatus((prev) => ({
        ...prev,
        [assetId]: failedAsyncAssetData.status,
      }));

      dispatch({
        type: FILES_STATUS_BOX_OPEN,
      });
      dispatch({
        type: FILES_STATUS_BOX_MAXIMIZE,
      });
      dispatch({
        type: FILES_UPLOAD_FAIL,
        id: fileId,
        errors: [{ code: apiCode }],
      });

      toast.error({
        id: 'upload-attachment-failed',
        title: <Message
          id="Upload attachment failed"
          comment="Notification message for upload attachment fail"
        />,
        description: getErrorMessage(apiCode)?.bodyText,
      });

      resetFailedAsyncAssetState();
      setLoading(false);
    }
  }, [
    succeededAsyncAssetData,
    onAssetPagesDone,
    uploadAssetsData,
    failedAsyncAssetData,
    dispatch,
    resetSucceededAsyncAssetState,
    resetFailedAsyncAssetState,
  ]);

  const handleAcceptedFiles = useCallback(async (files: AcceptedFileWithId[]) => {
    setLoading(true);

    await Promise.allSettled(files.map(async ({ file, id: fileId }) => {
      try {
        const asset: Asset & { fileId: string } = await client.uploadDocument({
          file,
          assetType: 'document',
          contractId: agreementId,
          guestToken,
        });

        asset.fileId = fileId;

        if (file.type === 'application/pdf') {
          addFile({
            asset,
            status: asset.status,
          });
          setUploadAssetsData((prev) => ({
            ...prev,
            [asset.assetId]: asset,
          }));
          return;
        }

        addNonPdfFile({ asset, fileId, status: asset.status });
      } catch (error: any) {
        totalFileSizeRef.current -= file.size;
        totalFileCountRef.current -= 1;
        syncTotalCountAndSizeRefsWithRedux();
        setLoading(false);

        const getErrorDescription = () => {
          const errorMessage = get(error, 'body.error');
          if (errorMessage) {
            return getErrorMessage(error.body.api_error_code)?.bodyText;
          }

          return (
            <Message
              id="An internal error has occurred. Please reload the page or try again later."
              comment="Notification about failure when an unknown error happens"
            />
          );
        };

        toast.error({
          id: 'upload-attachment-failed',
          title: <Message
            id="Upload attachment failed"
            comment="Notification message for upload attachment fail"
          />,
          description: getErrorDescription(),
        });

        const errorCode = error && error instanceof TypeError ? UNKNOWN_ERROR : API_ERROR;
        dispatch({
          type: FILES_STATUS_BOX_OPEN,
        });
        dispatch({
          type: FILES_STATUS_BOX_MAXIMIZE,
        });
        dispatch({
          type: FILES_UPLOAD_FAIL,
          id: fileId,
          errors: [{ code: errorCode }],
        });
      }
    }));
  }, [
    addNonPdfFile,
    agreementId,
    dispatch,
    guestToken,
    addFile,
    syncTotalCountAndSizeRefsWithRedux,
  ]);

  const onErrorHandler = useCallback((errorMessage: ReactNode) => {
    toast.error({
      id: 'upload-attachment-failed',
      title: <Message
        id="Upload attachment failed"
        comment="Notification message for upload attachment fail"
      />,
      description: errorMessage as unknown as string,
    });
  }, []);

  const handleFileRejectionsErrorMessage = useCallback((
    rejections: FileRejectionWithId[],
    haveAcceptedFiles: boolean,
  ) => {
    const snackbarErrorMessage = getFileRejectionsSnackbarErrorMessage(
      rejections,
      haveAcceptedFiles,
      { attachmentsCountLimit },
    );

    if (!snackbarErrorMessage) return;

    onErrorHandler(snackbarErrorMessage);
  }, [onErrorHandler, attachmentsCountLimit]);

  const handleBatchRejections = useCallback(({
    fileRejectionsWithIds,
    acceptedFilesWithIds,
  }: HandleBatchRejectionsProps) => {
    const codes = [
      DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR,
      DROPZONE_EXCEED_FILE_SIZE_ERROR,
      DROPZONE_EXCEED_FILE_COUNT_ERROR,
    ];

    let hasErrorHandlerTriggered = false;

    codes.forEach((errorCode) => {
      const hasErrorCode = hasErrorCodeInRejections(fileRejectionsWithIds, errorCode);

      if (!hasErrorCode || hasErrorHandlerTriggered) return;

      hasErrorHandlerTriggered = true;

      const rejections: FileRejectionWithId[] = [
        ...fileRejectionsWithIds,
        ...acceptedFilesWithIds.map(({ file, id }) => ({
          file: fileToJson(file) as unknown as File,
          errors: [{ code: errorCode }],
          id,
        } as FileRejectionWithId)),
      ];

      dispatch({
        type: FILES_UPLOAD_FAIL_BATCH,
        files: rejections,
      });

      handleFileRejectionsErrorMessage(rejections, false);
    });

    return hasErrorHandlerTriggered;
  }, [dispatch, handleFileRejectionsErrorMessage]);

  const onDragEnter = useCallback<NonNullable<DropzoneProps['onDragEnter']>>(() => {
    setIsDragOver(true);
  }, [setIsDragOver]);

  const onDragLeave = useCallback<NonNullable<DropzoneProps['onDragLeave']>>(() => {
    setIsDragOver(false);
  }, [setIsDragOver]);

  const onDrop = useCallback<NonNullable<DropzoneProps['onDrop']>>(async (
    acceptedFiles: File[],
    fileRejections = [],
  ) => {
    setIsDragOver(false);
    if (isInPreviewMode) {
      toast.warning({
        id: 'attachment-box-preview-warning',
        title: (
          <Message
            id="Not available in preview"
            comment="Title for the warning message when the user can't upload a file."
          />
        ),
        duration: 5000,
      });
      return;
    }
    const fileRejectionsWithIds = fileRejections ? fileRejections.map((fileRejection) => ({
      ...fileRejection,
      id: UUID.v4(),
    })) : [];

    const acceptedFilesWithIds: AcceptedFileWithId[] = acceptedFiles?.map((file) => ({
      file,
      id: UUID.v4(),
    }));

    dispatch({
      type: FILES_DROP,
      files: [
        ...fileRejectionsWithIds,
        ...acceptedFilesWithIds.map(({ file, id }) => ({
          id,
          file: fileToJson(file),
          errors: [],
        })),
      ],
    });

    dispatch({ type: FILES_STATUS_BOX_OPEN });
    dispatch({ type: FILES_STATUS_BOX_MAXIMIZE });

    if (fileRejections.length > 0) {
      const hasDocumentLimitationErrors = handleBatchRejections({
        fileRejectionsWithIds,
        acceptedFilesWithIds,
      });

      if (hasDocumentLimitationErrors) {
        resetTotalCountAndSizeRefs();
        return;
      }

      fileRejectionsWithIds.forEach((rejection) => {
        totalFileSizeRef.current -= rejection.file.size;
        totalFileCountRef.current -= 1;
      });

      dispatch({
        type: FILES_UPLOAD_FAIL_BATCH,
        files: fileRejectionsWithIds,
      });

      handleFileRejectionsErrorMessage(fileRejectionsWithIds, acceptedFiles.length > 0);
    }

    if (acceptedFilesWithIds.length > 0) {
      syncTotalCountAndSizeRefsWithRedux();
      handleAcceptedFiles(acceptedFilesWithIds);
    }
  }, [
    dispatch,
    handleBatchRejections,
    handleFileRejectionsErrorMessage,
    handleAcceptedFiles,
    syncTotalCountAndSizeRefsWithRedux,
    resetTotalCountAndSizeRefs,
    isInPreviewMode,
  ]);

  const maxSizePerContractValidator = useCallback<NonNullable<DropzoneProps['validator']>>((file) => {
    if (file.size > 0) {
      const newTotalSize = totalFileSizeRef.current + file.size;
      const newTotalCount = totalFileCountRef.current + 1;

      if (attachedFilesTotalCount >= attachmentsCountLimit) {
        return {
          code: DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR,
        } as DropzoneFileError;
      }

      if (newTotalCount > attachmentsCountLimit) {
        return {
          code: DROPZONE_EXCEED_FILE_COUNT_ERROR,
        } as DropzoneFileError;
      }

      if (newTotalSize > MAX_TOTAL_ATTACHMENT_SIZE_PER_CONTRACT) {
        return {
          code: DROPZONE_EXCEED_FILE_SIZE_ERROR,
        } as DropzoneFileError;
      }

      totalFileSizeRef.current = newTotalSize;
      totalFileCountRef.current = newTotalCount;
    }
    return null;
  }, [attachmentsCountLimit, attachedFilesTotalCount]);

  const contextValue = useMemo<ContextValue>(() => ({
    box,
    agreement,
    isOwner,
    loading,
    attachmentData,
    guestToken,
    onErrorHandler,
    editable,
    counterpartEdit,
    requiredForSignatures,
    colleagueEdit,
    managerLock,
    isAllowedToReorder,
    updaterParticipant,
    maxSizePerContractValidator,
    onDrop,
    onDragEnter,
    onDragLeave,
    isDragOver,
    attachmentsCountLimit,
    uploadedFileStatus,
    setUploadedFileStatus,
    contractId: agreementId,
  }), [
    box,
    agreement,
    isOwner,
    loading,
    attachmentData,
    guestToken,
    onErrorHandler,
    editable,
    counterpartEdit,
    requiredForSignatures,
    colleagueEdit,
    managerLock,
    isAllowedToReorder,
    updaterParticipant,
    maxSizePerContractValidator,
    onDrop,
    onDragEnter,
    onDragLeave,
    isDragOver,
    attachmentsCountLimit,
    uploadedFileStatus,
    agreementId,
  ]);

  return (
    <FileUploadBoxPropsContext.Provider value={contextValue}>
      {children}
    </FileUploadBoxPropsContext.Provider>
  );
}

export const useFileUploadBoxProps = () => {
  const contextValue = useContext(FileUploadBoxPropsContext);

  if (!contextValue) {
    throw new Error('useFileUploadBoxProps should be used inside a FileUploadBoxPropsContext');
  }

  return contextValue;
};
