import { Message } from '@oneflowab/pomes';
import { isConcluded } from 'agreement/states';
import ExclamationCircle from 'components/icons/exclamation-circle';
import style from './participant-info-box.module.scss';

export type Props = {
  agreement: Oneflow.Agreement,
};

export const ParticipantInfoBox = ({ agreement }: Props) => {
  const getInfoText = () => {
    if (!isConcluded(agreement)) {
      return (
        <p className={style.InfoMessage}>
          <Message
            id="To enable this participant's approval, place at least one signatory after them in the signing order."
            comment="Text for external approver who cannot approve the document because there are no signatories after them."
          />
        </p>
      );
    }

    return (
      <p className={style.InfoMessage}>
        <Message
          id="This participant can no longer approve as the document is fully signed."
          comment="Text for external approver who cannot approve the document because the document is fully signed."
        />
      </p>
    );
  };

  return (
    <div className={style.InfoBox}>
      <div className={style.InfoBoxTitle}>
        <ExclamationCircle className={style.ExclamationCircle} />
        <p>
          <Message
            id="Cannot approve"
            comment="Text for external approver who cannot approve the document"
          />
        </p>
      </div>
      {getInfoText()}
    </div>
  );
};
