// @flow

import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { isFunction } from 'lodash';

import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import debounce from 'lodash/debounce';
import take from 'lodash/take';
import get from 'lodash/get';
import toLower from 'lodash/toLower';

import { checkAcl } from 'components/acl';
import Add from 'components/icons/add';
import SmallAdd from 'components/icons/small-add';
import NewArrowRight from 'components/icons/new-arrow-right';
import CircularSpinner from 'components/icons/circular-spinner';
import MagnifyingGlass from 'components/icons/magnifying-glass';
import NoTagsIcon from 'components/icons/no-tags';
import { Popover, PopoverContent, PopoverPortal } from 'components/popover';
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';

import Button from 'components/button';
import TextField from 'components/text-field';
import Tag from 'components/tags/tag';
import EmptyState from 'components/empty-state';

import ModalForm from 'hocs/modal-form';
import style from './add-tag.module.scss';

type Props = {
  acl: {
    account: Acl,
  },
  tags: Array<Tag>,
  tagsBlacklisted: Array<Tag>,
  createTagConnection: (tag: Tag) => void,
  createTagAndTagConnection: (tagData: { name: string }) => void,
  queryState: Query,
  createState: CreateState,
  createTagState: CreateState,
  message: MessageTranslator,
  queryTags: (name?: string) => void,
  preferPlace?: 'start' | 'end' | 'above' | 'right' | 'below' | 'left' | 'column' | 'row',
  children?: () => ReactNode,
  isModal?: boolean
};

type State = {
  tagToFind: string,
};

export const handleQuery = (queryTags: Function, tagToFind: string) => (
  queryTags(toLower(tagToFind))
);

export class AddTagComponent extends React.Component<Props, State> {
  static defaultProps = {
    preferPlace: undefined,
  };

  state = {
    tagToFind: '',
  }

  debouncedQuery = debounce(handleQuery, 300);

  onInputTextChange = (event: InputEvent) => {
    const { queryTags } = this.props;
    const tagToFind = event.target.value;

    this.debouncedQuery(queryTags, toLower(tagToFind));
    this.setState({
      tagToFind,
    });
  }

  onTagButtonClick = (tag: Tag) => () => {
    const { createTagConnection } = this.props;
    return createTagConnection(tag);
  };

  onInputEnter = (event: KeyboardEvent) => {
    const { tagToFind } = this.state;
    const { queryState, createTagAndTagConnection } = this.props;
    const isTagBlacklisted = this.isTagBlacklisted();

    if (!this.isTagCreationAllowed()
      || this.isCreating()
      || queryState.loading
      || isTagBlacklisted
      || event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    createTagAndTagConnection({ name: tagToFind });
  };

  isTagBlacklisted() {
    const { tagsBlacklisted } = this.props;
    const { tagToFind } = this.state;

    return tagsBlacklisted.find(
      (tagBlacklisted: Tag) => toLower(tagToFind) === toLower(tagBlacklisted.name),
    );
  }

  isTagCreationAllowed() {
    const { acl, tags } = this.props;
    const { tagToFind } = this.state;
    const hasPermission = checkAcl(acl.account, 'account:admin:tag');

    if (!hasPermission || this.isCreating() || !tagToFind) {
      return false;
    }

    return !tags.find((tag: Tag) => toLower(tagToFind) === toLower(tag.name));
  }

  isCreating() {
    const { createState, createTagState } = this.props;

    return createState.loading || createTagState.loading;
  }

  renderEmptyState() {
    const { message, queryState } = this.props;
    const isSearching = get(queryState, 'params.q');
    let emptyStateReason = message({
      id: 'There are no tags available to select.',
      comment: 'Empty state header. Used when adding tags in a list when no tags are available.',
    });

    if (isSearching) {
      emptyStateReason = message({
        id: 'Please adjust your search criteria and try again.',
        comment: 'Empty state text. Used when adding tags in a list when no tags are matching search.',
      });
    }

    return (
      <EmptyState
        icon={<NoTagsIcon height="33px" />}
        header={message({
          id: 'No tags found',
          comment: 'When there is not tag in the list this message shows up',
        })}
        content={emptyStateReason}
        defaultStyle
      />
    );
  }

  renderBlacklistButton() {
    if (!this.isTagBlacklisted()) {
      return null;
    }

    return (
      <Button
        color="thunder"
        customClass={style.CreateTagButton}
        disabled
      >
        <Message
          id="This tag already exists"
          comment="When the tag exists and is already selected this message shows up"
        />
      </Button>
    );
  }

  renderTagList() {
    const {
      tags,
      queryState,
      createTagState,
    } = this.props;

    if (this.isCreating() || queryState.loading || createTagState.loading) {
      return (
        <div className={style.TagList}>
          <CircularSpinner className={style.LoadingSpinner} />
        </div>
      );
    }

    if (!tags.length && !this.isTagBlacklisted()) {
      return this.renderEmptyState();
    }

    const tagsToShow = take<Tag>(tags, 10);

    return (
      <div className={style.TagList}>
        {tagsToShow.map<React.Node>((tag: Tag) => (
          <Tag
            key={tag.id}
            tagConnection={{ tag }}
            allowTagChange={false}
            allowSearch={false}
            onClick={this.onTagButtonClick(tag)}
          />
        ))}
      </div>
    );
  }

  renderAddIcon = () => {
    if (this.isCreating()) {
      return <CircularSpinner height="12px" className={style.AddButtonIcon} />;
    }

    return <SmallAdd height="8px" className={style.AddButtonIcon} />;
  }

  renderFooter() {
    const { acl } = this.props;

    if (!checkAcl(acl.account, 'account:admin:tag')) {
      return null;
    }

    return (
      <div className={style.Footer}>
        <a href="/admin/tags" target="_blank" className={style.ManageTags}>
          <Message
            id="Manage tags"
            comment="Used as link text to tags management"
          />
          <NewArrowRight className={style.ExternalIcon} height="12px" />
        </a>
      </div>
    );
  }

  renderCreateTagButton() {
    const { createTagAndTagConnection } = this.props;
    const { tagToFind } = this.state;

    if (!this.isTagCreationAllowed()) {
      return null;
    }

    if (this.isTagBlacklisted()) {
      return null;
    }

    return (
      <Button
        icon={Add}
        kind="secondary"
        customClass={style.CreateTagButton}
        onClick={() => { createTagAndTagConnection({ name: tagToFind }); }}
      >
        <Message
          id="Create tag"
          comment="Create tag when the searched tag name is not a match."
        />
        {` (${tagToFind})`}
      </Button>
    );
  }

  renderPopoverBodyContent() {
    const { tagToFind } = this.state;
    const { message } = this.props;
    const withCreateTagButton = this.isTagCreationAllowed();

    const bodyClassName = clsx(style.Body, {
      [style.WithCreateTagButton]: withCreateTagButton,
    });

    return (
      <div className={bodyClassName}>
        <div className={style.SearchTagRow}>
          <TextField
            icon={<MagnifyingGlass responsive className={style.SearchFieldIcon} />}
            input={{
              value: tagToFind,
              onChange: this.onInputTextChange,
              onKeyDown: this.onInputEnter,
            }}
            placeholder={message({
              id: 'Find tags',
              comment: 'Used as the placeholder when searching for tag to add',
            })}
            lengthLimit={30}
            autoFocus
            name="search"
          />
        </div>
        {this.renderCreateTagButton()}
        {this.renderBlacklistButton()}
        {this.renderTagList()}
      </div>

    );
  }

  renderPopoverContent() {
    return (
      <div className={style.ContentWrapper}>
        <div className={style.Header}>
          <Message
            id="Add tag"
            comment="Used as the header of the Add Tag popover"
          />
        </div>
        {this.renderPopoverBodyContent()}
        {this.renderFooter()}
      </div>
    );
  }

  modalFooter() {
    return (
      <div className={style.ModalFooter}>
        {this.renderFooter()}
      </div>
    );
  }

  modalBody() {
    return (
      <div className={style.ModalBody}>
        {this.renderPopoverBodyContent()}
      </div>
    );
  }

  render() {
    const {
      preferPlace, children, isModal, queryTags,
    } = this.props;

    if (isModal && isFunction(children)) {
      return (
        <ModalForm
          title={(
            <Message
              id="Add tag"
              comment="Used as the header of the Add Tag popover"
            />
          )}
          body={this.modalBody()}
          onSubmit={() => {}}
          modalKey="set contract tags modal"
          actions={() => this.modalFooter()}
          onOpen={() => queryTags()}
        >
          {children}
        </ModalForm>
      );
    }

    return (
      <Popover side={preferPlace}>
        <PopoverTriggerButton
          customClass={style.AddButton}
          kind="linkSeparate"
          icon={this.renderAddIcon}
          underlineLink
          color="forest-green"
          onClick={() => queryTags()}
        >
          <Message
            id="Add tag"
            comment="Used as the text of the Add Tag buttton"
          />
        </PopoverTriggerButton>
        <PopoverPortal>
          <PopoverContent className={style.PopoverContent}>
            {this.renderPopoverContent()}
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    );
  }
}

export default localize<Props>(AddTagComponent);
