import { Message } from '@oneflowab/pomes';

import WarningIcon from 'components/icons/warning';

import style from './error.module.scss';

export const PdfViewerError = () => (
  <div className={style.ErrorContainer} data-testid="error-container">
    <WarningIcon height="40px" />
    <h1 className={style.Header}>
      <Message id="Something went wrong" comment="Error message title" />
    </h1>
    <p data-testid="error-details">
      <Message
        id="Due to an internal error, we couldn’t load the PDF file."
        comment="Error message to show the pdf file could not load"
      />
    </p>
    <p className={style.Contact}>
      <Message
        id="Please contact support at {email}."
        values={{
          email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
        }}
        comment="Error message contact info."
      />
    </p>
  </div>
);
