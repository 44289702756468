import type { ReactNode, ComponentType } from 'react';
import Button from 'components/button';
import type { IconProps } from 'types/svg-icon-props';
import style from './bulk-action.module.scss';

type Props = {
  icon: ComponentType<IconProps>,
  label: ReactNode,
  onClick: (args?: any) => void,
  disabled?: boolean,
};

const BulkAction = ({
  icon,
  label,
  onClick,
  disabled,
}: Props) => (
  <Button
    customClass={style.SmallButton}
    onClick={onClick}
    icon={icon}
    disabled={disabled}
    outline
  >
    {label}
  </Button>
);

BulkAction.defaultProps = {
  disabled: undefined,
};

export default BulkAction;
