// @flow

import * as React from 'react';
import { DataFieldsProvider } from 'contexts/data-fields';

import {
  Link,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  Image,
  PageBreak,
  HorizontalLine,
  DataField,
  Annotation,
  Suggestion,
} from './nodes';
import * as elementTypes from './element-types';
import { getStyle } from './utils';

export const ELEMENT_MAPPERS = {
  [elementTypes.BLOCK_QUOTE]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <blockquote {...attributes} style={getStyle(element)}>{children}</blockquote>
  ),
  [elementTypes.BULLETED_LIST]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <ul {...attributes} style={getStyle(element)}>{children}</ul>
  ),
  [elementTypes.HEADING_ONE]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <h1 {...attributes} style={getStyle(element)}>{children}</h1>
  ),
  [elementTypes.HEADING_TWO]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <h2 {...attributes} style={getStyle(element)}>{children}</h2>
  ),
  [elementTypes.HEADING_THREE]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <h3 {...attributes} style={getStyle(element)}>{children}</h3>
  ),
  [elementTypes.HEADING_FOUR]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <h4 {...attributes} style={getStyle(element)}>{children}</h4>
  ),
  [elementTypes.HEADING_FIVE]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <h5 {...attributes} style={getStyle(element)}>{children}</h5>
  ),
  [elementTypes.HEADING_SIX]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <h6 {...attributes} style={getStyle(element)}>{children}</h6>
  ),
  [elementTypes.LIST_ITEM]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <li {...attributes} style={getStyle(element)}>{children}</li>
  ),
  [elementTypes.NUMBERED_LIST]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <ol {...attributes} style={getStyle(element)}>{children}</ol>
  ),
  [elementTypes.TABLE]: (renderProps: RenderSlateElementProps) => (
    <Table {...renderProps} />
  ),
  [elementTypes.TABLE_HEAD]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <thead {...attributes} style={getStyle(element)}>{children}</thead>
  ),
  [elementTypes.TABLE_HEADER]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <th
      {...attributes}
      colSpan={element.colSpan}
      rowSpan={element.rowSpan}
      style={getStyle(element)}
    >
      {children}
    </th>
  ),
  [elementTypes.TABLE_BODY]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <tbody {...attributes} style={getStyle(element)}>{children}</tbody>
  ),
  [elementTypes.TABLE_ROW]: (renderProps: RenderSlateElementProps) => (
    <TableRow {...renderProps} />
  ),
  [elementTypes.TABLE_CELL]: (renderProps: RenderSlateElementProps) => (
    <TableCell {...renderProps} />
  ),
  // eslint-disable-next-line no-unused-vars
  [elementTypes.TITLE]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <h1 {...attributes} className="title" style={getStyle(element)}>{children}</h1>
  ),
  // eslint-disable-next-line no-unused-vars
  [elementTypes.SUBTITLE]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <h1 {...attributes} className="subtitle" style={getStyle(element)}>{children}</h1>
  ),
  [elementTypes.LINK]: (renderProps: RenderSlateElementProps) => (
    <Link {...renderProps} />
  ),
  [elementTypes.ANNOTATION]: (renderProps: RenderSlateElementProps) => (
    <Annotation {...renderProps} />
  ),
  [elementTypes.SUGGESTION]: (renderProps: RenderSlateElementProps) => (
    <Suggestion {...renderProps} />
  ),
  [elementTypes.IMAGE]: (renderProps: RenderSlateElementProps) => (
    <Image {...renderProps} />
  ),
  [elementTypes.PARAGRAPH]: (renderProps: RenderSlateElementProps) => (
    <Paragraph {...renderProps} />
  ),
  [elementTypes.PAGE_BREAK]: (renderProps: RenderSlateElementProps) => (
    <PageBreak {...renderProps} />
  ),
  [elementTypes.HORIZONTAL_LINE]: (renderProps: RenderSlateElementProps) => (
    <HorizontalLine {...renderProps} />
  ),
  [elementTypes.NEW_LINE]: (renderProps: RenderSlateElementProps) => (
    <Paragraph {...renderProps} />
  ),
  [elementTypes.CAPTION]: ({ attributes, element, children }: RenderSlateElementProps) => (
    <caption {...attributes} style={getStyle(element)}>{children}</caption>
  ),
  [elementTypes.DATA_FIELD]: (renderProps: RenderSlateElementProps) => (
    <DataFieldsProvider>
      <DataField {...renderProps} />
    </DataFieldsProvider>
  ),
};

const Element = (props: RenderSlateElementProps) => {
  const { element } = props;
  const elementMapper = ELEMENT_MAPPERS[element.type] || ELEMENT_MAPPERS[elementTypes.PARAGRAPH];

  return elementMapper(props);
};

export default Element;
