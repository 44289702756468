/* eslint-disable no-continue */
import {
  cloneDeep,
  isNumber,
  uniqueId,
} from 'lodash';

import { formatNumberWithTrailingZeros } from 'utils/format-number-with-trailing-zeros';
import { roundBy } from 'utils/math';

import { getVisiblePriceColumns } from 'reducers/helpers/current-contract';
import { Uuid } from 'data-validators/entity-schemas/document-box/product-sum-box';
import type { Box } from 'data-validators/entity-schemas/document-box';
import type { PriceColumnsGroup } from 'reducers/helpers/current-contract';
import type { PriceRoundingMethod } from 'data-validators/entity-schemas/document-box/product-table';

export const priceTypes = ['price_1', 'price_2'];

// TODO: Move to CurrentContract types when current contract is converted to TS
type PriceColumn = {
  visible: boolean,
  column: {
    label: string,
    key: string,
    fixedAmount: number,
    enabled: boolean,
    uuid: string,
    boxId: number,
    price: number,
    // eslint-disable-next-line camelcase
    price_discounted: number,
  },
  formatted: {
    boxId: number,
    value: string,
    label: string,
  }
}

// TODO: Move to CurrentContract types when current contract is converted to TS
type SummationValue = {
  columnUuids: Uuid[]
  id: string,
  name: string,
  prefix: string
  postfix: string,
  sum: number
  sumDiscounted: number | null,
  sumPrecision: number,
}

const defaultSumValues: Omit<SummationValue, 'id'> = Object.freeze({
  name: 'Untitled',
  postfix: '',
  prefix: '',
  sum: 0,
  columnUuids: [],
  sumDiscounted: null,
  sumPrecision: 2,
});

export const hasValidDiscount = (sum: number, sumDiscounted: SummationValue['sumDiscounted']) => (
  isNumber(sumDiscounted) && isNumber(sum) && sumDiscounted !== sum
);

export const generateProductSumBoxData = (box: Box) => {
  const {
    name,
    postfix,
    prefix,
    sum,
    columnUuids,
    sumPrecision,
  } = defaultSumValues;

  const newDataItem = {
    key: 'product_sum',
    value: {
      name,
      postfix,
      prefix,
      sum: formatNumberWithTrailingZeros(String(sum), sumPrecision),
      columnUuids,
      sumPrecision,
    },
    _id: Number(uniqueId()),
  };

  const updatedBox = cloneDeep(box);

  updatedBox.content = {
    ...updatedBox.content,
    data: [
      // TODO: OF-10145 update the box types to include product sum box types as well
      ...updatedBox.content.data,
      newDataItem,
    ],
  };

  return {
    updatedBox,
    newDataItem,
  };
};

export const getProductSumString = (
  priceRoundingMethod: PriceRoundingMethod,
  sumValues: SummationValue,
  priceType: 'full' | 'discounted',
) => {
  const {
    prefix,
    postfix,
    sum,
    sumDiscounted,
    sumPrecision,
  } = sumValues;

  let amount = sum;
  if (priceType === 'discounted' && hasValidDiscount(sum, sumDiscounted)) {
    amount = sumDiscounted as number;
  }

  const roundedAmount = roundBy(priceRoundingMethod, amount, sumPrecision);
  // Add trailing zeros to the sum, plus adding non breaking space as thousand separator
  let formattedSum = formatNumberWithTrailingZeros(String(roundedAmount), sumPrecision);
  if (prefix) {
    formattedSum = `${prefix}${formattedSum}`;
  }
  if (postfix) {
    formattedSum = `${formattedSum}${postfix}`;
  }

  return formattedSum;
};

const calculateSum = (amounts: number[]) => (
  amounts.filter(Boolean).reduce((partialSum, a) => partialSum + a, 0)
);

const getSelectedSums = (
  priceColumnsGroups: PriceColumnsGroup[],
  selectedUuids: string[],
) => (
  priceColumnsGroups
    .map((priceColumnsGroup) => ({
      ...priceColumnsGroup,
      priceColumns: priceColumnsGroup.priceColumns
        .filter(({ column }) => selectedUuids.includes(column.uuid)),
    }))
);

export const calculateSumsFromProductTables = (
  priceRoundingMethod: PriceRoundingMethod,
  selectedUuids: string[],
  priceColumnsGroups: PriceColumnsGroup[],
  precision: number,
) => {
  const visiblePriceColumns = getVisiblePriceColumns(priceColumnsGroups);
  const selectedSums = getSelectedSums(visiblePriceColumns, selectedUuids);
  const sum = roundBy(
    priceRoundingMethod,
    calculateSum(
      selectedSums
        .reduce<PriceColumn[]>(
          (acc, priceColumnsGroup) => [...acc, ...priceColumnsGroup.priceColumns], [],
        ).map(({ column }) => column.price),
    ),
    precision,
  );
  const sumDiscounted = roundBy(
    priceRoundingMethod,
    calculateSum(
      selectedSums
        .reduce<PriceColumn[]>(
          (acc, priceColumnsGroup) => [...acc, ...priceColumnsGroup.priceColumns], [],
        ).map(({ column }) => column.price_discounted),
    ),
    precision,
  );

  return {
    sum,
    sumDiscounted: isNumber(sumDiscounted) ? sumDiscounted : null,
  };
};
