import { forwardRef } from 'react';

import useFilterableState from './hooks/use-filterable-state';
import type { Children, DivProps } from './types';

type Props = Children & DivProps;
/**
 * Automatically renders when there are no results for the search query.
 */
const Empty = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const render = useFilterableState((state) => state.filtered.count === 0);

  if (!render) return null;
  // eslint-disable-next-line react/no-unknown-property
  return <div ref={forwardedRef} {...props} cmdk-empty="" role="presentation" />;
});

Empty.displayName = 'Empty';

export default Empty;
