// @flow

import React, {
  useEffect, useCallback, useRef, useState, type Node, type ComponentType,
} from 'react';
import clsx from 'clsx';

import ChevronDownIcon from 'components/icons/chevron-down';
import Button from 'components/button/button';

import useOutsideClick from 'hooks/use-outside-click';

import SelectDropdownOption from './select-dropdown-option';
import style from './select-dropdown.module.scss';

type Option = {
  label: Node,
  IconComponent: ComponentType<IconProps>,
  id: Number,
};

type Props = {
  customButtonClass?: string,
  customDropdownClass?: string,
  emptyState?: Node,
  fullWidth?: boolean,
  isDisabled?: boolean,
  onDropdownToggle?: (boolean) => boolean,
  onChange: (Number) => void,
  options: Array<Option>,
  selectedItem: Node,
  location?: string,
  className: string,
  onClick?: (e: SyntheticMouseEvent<HTMLButtonElement>) => void,
};

const EmptyIcon = () => <div />;

// TODO: Replace this component with other proper select/dropdown component

function SelectDropdown(props: Props) {
  const {
    selectedItem,
    customButtonClass,
    customDropdownClass,
    isDisabled,
    fullWidth,
    onChange,
    onDropdownToggle,
    location,
    options,
    emptyState,
    className,
    onClick, // could come from tooltip trigger
    ...buttonProps
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownTriggerRef = useRef(null);

  const getButtonIcon = () => {
    if (isDisabled) {
      return EmptyIcon;
    }
    return <ChevronDownIcon height="8px" />;
  };

  const closeDropdown = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((isOpen) => !isOpen);
  }, []);

  useOutsideClick(dropdownTriggerRef, closeDropdown, isDropdownOpen);

  useEffect(() => {
    if (typeof onDropdownToggle === 'function') {
      onDropdownToggle(isDropdownOpen);
    }
  }, [isDropdownOpen, onDropdownToggle]);

  const handleSelect = useCallback((optionId: Number) => {
    closeDropdown();
    onChange(optionId, location);
  }, [closeDropdown, onChange, location]);

  const renderOption = (option: Option) => (
    <SelectDropdownOption
      key={option.id.toString()}
      id={option.id}
      label={option.label}
      IconComponent={option.IconComponent}
      onSelect={handleSelect}
    />
  );

  const renderOptions = () => {
    if (!options.length) {
      return emptyState;
    }

    return options.map<Node>(renderOption);
  };

  const renderDropdown = () => {
    if (!isDropdownOpen) {
      return null;
    }

    const dropdownClasses = clsx(style.Dropdown, customDropdownClass);

    return (
      <div className={dropdownClasses}>
        <div className={style.Options}>
          {renderOptions()}
        </div>
      </div>
    );
  };

  const buttonClasses = clsx(style.DropdownButton, customButtonClass);

  const dropdownClasses = clsx(style.SelectDropdown, className, {
    [style.FullWidth]: fullWidth,
  });

  return (
    <div className={dropdownClasses} ref={dropdownTriggerRef}>
      <Button
        customClass={buttonClasses}
        icon={getButtonIcon()}
        hasIconRight
        disabled={isDisabled}
        onClick={(e) => {
          toggleDropdown();
          onClick?.(e);
        }}
        {...buttonProps}
      >
        {selectedItem}
      </Button>
      {renderDropdown()}
    </div>
  );
}

export default SelectDropdown;
