// @flow

import React, { useEffect, useState } from 'react';
import { Message } from '@oneflowab/pomes';
import isEmpty from 'lodash/isEmpty';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import rainbow from 'styles/rainbow-shadow.module.scss';

import libraryTemplatesReducer from 'reducers/entities/library-templates';
import agreementsReducer from 'reducers/entities/agreements';
import { getCurrentWorkspaceIdSelector } from 'reducers/app';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';

import Modal from 'components/modal';
import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';
import { BlankDocumentButton } from 'components/buttons';
import { PdfDropzoneButton } from 'components/buttons/pdf-dropzone';
import magicData from './magic-data.png';

import style from './welcome-modal.module.scss';

export const QUERY_NAME = 'publicTemplates';

export type Props = {
  onClose: () => void,
};

export const WelcomeModal = ({ onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const workspaceId = useSelector((state) => getCurrentWorkspaceIdSelector(state));
  const query = useSelector((state) => (
    libraryTemplatesReducer.getQuerySelector(state, { name: QUERY_NAME })
  ));
  const publicTemplates = useSelector((state) => (
    libraryTemplatesReducer.getLibraryTemplatesSelector(state, { ids: query.result })
  ));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(libraryTemplatesReducer.queryLibraryTemplates({
      name: QUERY_NAME,
    }));
  }, [dispatch]);

  const getGuideLibraryTemplate = () => {
    if (isEmpty(publicTemplates)) {
      return null;
    }
    return publicTemplates.find((template) => template.onboardingType === 'guide');
  };

  const createLibraryTemplate = (sourceId: number, name: string) => {
    dispatch(libraryTemplatesReducer.createLibraryTemplate({
      data: {
        workspaceId,
        sourceId,
        name,
      },
    }));
  };

  const createAgreementFromTemplate = (sourceId: number, name: string) => {
    dispatch(agreementsReducer.createAgreement({
      data: {
        workspaceId,
        sourceId,
        name,
        amplitudeScope: 'welcome modal - create guide',
      },
      pipe: {
        action: (data) => redirectToDocumentOnDocumentCreate(data?.data),
      },
    }));
  };

  const handleClick = async () => {
    setIsLoading(true);
    const guideLibraryTemplate = getGuideLibraryTemplate();

    if (guideLibraryTemplate) {
      const { agreement, name } = guideLibraryTemplate;

      await createLibraryTemplate(agreement, name);
      createAgreementFromTemplate(agreement, name);
      amplitudeLogEvent('Go To Welcome Guide');
    }
  };

  const handleClose = () => {
    amplitudeLogEvent('Close Welcome Modal');
    onClose();
  };

  const contentBoxClasses = clsx(style.ContentBox, rainbow.Shadow);

  return (
    <Modal
      header={(
        <Message
          id="Get started with Oneflow"
          comment="Message shown in onboarding modal"
        />
      )}
      isOpen
      onCancel={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      modalKey="onboarding welcome modal"
    >
      <Modal.Body>
        <div className={style.Welcome}>
          <div className={contentBoxClasses}>
            <p className={style.Header}>
              <Message
                id="Experience the magic of truly digital contracts"
                comment="Header for the second step of the onboarding modal for new accounts"
              />
            </p>
            <p>
              <Message
                id="Open this guide to learn how to create truly digital contracts and start exploring the full functionality Oneflow can have for your company."
                comment="Paragraph that helps onboard brand new users."
              />
            </p>
            <Button
              kind="primary"
              icon={isLoading ? CircularSpinner : null}
              onClick={handleClick}
              disabled={isLoading}
            >
              <Message
                id="Open the guide"
                comment="Button text to open onboarding guide for new users."
              />
            </Button>
            <img src={magicData} alt="Oneflow" height="120px" className={style.MagicData} />
          </div>
          <div className={style.Buttons}>
            <BlankDocumentButton />
            <PdfDropzoneButton />
          </div>
          <div className={style.Footer}>
            <Button
              kind="linkSeparate"
              onClick={handleClose}
            >
              <Message
                id="Close and explore on my own"
                comment="Button text to close onboarding guide for new users."
              />
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
