import { MutableRefObject, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { isEmpty } from 'lodash';

import { getGuestToken, getAgreementMyParticipant } from 'agreement/selectors';
// eslint-disable-next-line import/named
import { isViewer } from 'agreement/participant/participant';
import { isUserLimited } from 'user';
import { getPositionFromSessionSelector } from 'reducers/session';
import extensionsReducer from 'reducers/entities/extensions';
import useAgreement from 'hooks/use-agreement';
import { useFetchTemplateGroups } from 'hooks/use-template-groups';
import { KEY_TEMPLATE_GROUPS } from 'extensions';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import useFeatureFlag from 'hooks/use-feature-flag';

import DocumentCallToActions from 'components/document-call-to-actions/document-call-to-actions';
import { COLLAPSED } from 'components/document-layout-container/helpers';
import { DocumentTabValue } from 'components/document-layout-container/types';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import { DATA_FIELD } from 'components/contract-boxes/constants';

import BurgerMenu from './burger-menu';
import getTriggerItems, { getAvailableTriggerItems } from './burger-menu/helpers';

import style from './navigation-bar.module.scss';

type Props = {
  agreementId: Oneflow.Document['id'],
  setActiveTab: (tab: DocumentTabValue) => void,
  activeTab: DocumentTabValue,
}

const NavigationBar = ({
  agreementId, setActiveTab, activeTab,
}: Props) => {
  const { newMessagesCount, shouldFadeOutCountBadge, newAuditTrailCount } = useDocumentLayout();
  const agreement = useAgreement(agreementId);
  const guestToken = useSelector(getGuestToken);
  const participant = getAgreementMyParticipant(agreement);
  const extension = useSelector((state) => (
    extensionsReducer.getExtensionSelector(state, { id: KEY_TEMPLATE_GROUPS })
  ));
  useFetchTemplateGroups(extension);
  const position = useSelector(getPositionFromSessionSelector);
  const isLimitedUser = isUserLimited(position);
  const isGuest = Boolean(guestToken);
  const isParticipantViewer = !isEmpty(participant) && isViewer(participant);
  const isViewerGuestOrLimited = isGuest || isParticipantViewer || isLimitedUser;
  const isInPreviewMode = useIsInPreviewMode();
  const isContentTabFeatureEnabled = useFeatureFlag('temporaryContentTab');

  const hasDataFields = agreement?.data?.some((data) => data?.key === DATA_FIELD) ?? false;
  const hasFieldsOrExtension = hasDataFields || (extension?.state ?? false);

  const navigationBarRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const documentCallToActionsRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const navigationBar = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 16,
    targetRef: navigationBarRef,
  });
  const documentCallToActions = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 16,
    targetRef: documentCallToActionsRef,
  });

  // Find out how much space is available for the triggers
  const gap = 32;
  const availableSpace = navigationBar?.width
    - documentCallToActions?.width - gap;

  const triggers = getAvailableTriggerItems({
    agreement,
    guestToken,
    setActiveTab,
    activeTab,
    triggerClassName: style.Trigger,
    messageTabClassName: style.MessageTabContainer,
    menuMessageTabClassName: style.MenuMessageTabContainer,
    position,
    downloadPDFClassName: style.DownloadPDF,
    showDataFields: !(
      isViewerGuestOrLimited
      || !hasFieldsOrExtension
    ),
    newMessagesCount,
    shouldFadeOutCountBadge,
    newAuditTrailCount,
    isInPreviewMode,
    isCollapsedLayout: true,
    isContentTabFeatureEnabled,
  });

  const { triggerItems, burgerMenuItems } = getTriggerItems({
    availableSpace,
    triggerItems: triggers,
  });

  return (
    <div className={style.TriggerContainer} ref={navigationBarRef}>
      <div className={style.TriggerIcons}>
        {triggerItems}
        {
          burgerMenuItems.length > 0
          && (
            <BurgerMenu
              menuItems={burgerMenuItems}
              activeTab={activeTab}
            />
          )
        }
      </div>
      <div ref={documentCallToActionsRef} className={style.DocumentCallToActions}>
        <DocumentCallToActions agreementId={agreementId} layout={COLLAPSED} />
      </div>
    </div>
  );
};

export default NavigationBar;
