// @flow

import { connect } from 'react-redux';

import { getPositionFromSessionSelector } from 'reducers/session';
import { getCurrentWorkspaceSelector } from 'reducers/app';
// TODO: Generalize this, since Search is a HOC, it could receive the normalized entity object
import agreements from 'reducers/entities/agreements';
import { getOffsetForPage } from 'components/pagination/helpers';
import { getPathnameSelector } from 'reducers/router';
import foldersReducer from 'reducers/entities/folders';

import { Search } from './search';
import type { Props } from './search';

const FOLDERS_QUERY = 'folders';
const SEARCH_PAGINATION_LIMIT = 20;

type OwnProps = {
  queryName: string,
}

type ConnectedProps = {
  query: Query,
  result: Array<{}>,
}

export const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { queryName } = ownProps;
  const foldersQuery = foldersReducer.getQuerySelector(state, { name: FOLDERS_QUERY });
  const folders = foldersReducer.getFoldersSelector(state, { ids: foldersQuery.result });
  const pathname = getPathnameSelector(state);
  const position = getPositionFromSessionSelector(state);
  const query = agreements.getQuerySelector(state, { name: queryName });
  const result = agreements.getAgreementsSelector(state, { ids: query.result });
  const workspace = getCurrentWorkspaceSelector(state);

  return {
    folders,
    pathname,
    position,
    query,
    result,
    workspace,
  };
};

export const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => {
  const { queryName } = ownProps;

  return {
    executeQuery: (params: {}, sort: Array<string>, pageNumber = 1) => {
      dispatch(agreements.queryAgreements({
        name: queryName,
        params,
        pagination: {
          limit: SEARCH_PAGINATION_LIMIT,
          offset: getOffsetForPage(pageNumber, SEARCH_PAGINATION_LIMIT),
        },
        sort,
      }));
    },
    queryAgreementsReload: () => {
      dispatch(agreements.queryAgreementsReload({ name: queryName }));
    },
  };
};

const connectedSearch = connect <
  Search<Agreement>, State, Props<Agreement>, any, ConnectedProps, any,
  >(
    mapStateToProps,
    mapDispatchToProps,
  )(Search);

export { connectedSearch as Search };
