// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Message } from '@oneflowab/pomes';
import { isSharedFromAnotherWorkspace } from 'agreement/selectors';
import TemplateSharingIndicator from 'components/template-sharing-indicator';

import style from './template-name.module.scss';

export type Props = {
  template: AgreementTemplate,
  currentWorkspace: Workspace,
  isTemplateAccessible: boolean,
}

class TemplateName extends React.PureComponent<Props> {
  getNameLink(templateName) {
    const {
      template,
    } = this.props;

    return (
      <Link to={`/documents/templates/${template.agreement.id}`} className={style.Link}>
        {templateName}
      </Link>
    );
  }

  getName() {
    const {
      currentWorkspace,
      isTemplateAccessible,
      template,
    } = this.props;

    const templateName = (
      <span className={style.Name}>
        {template.agreement.name || (
          <Message
            id="Untitled"
            comment="Shown in template list for templates without a name."
          />
        )}
      </span>
    );

    if (!isSharedFromAnotherWorkspace(template.agreement, currentWorkspace.id)
      || isTemplateAccessible) {
      return this.getNameLink(templateName);
    }

    return templateName;
  }

  render() {
    const { template, isTemplateAccessible } = this.props;

    return (
      <div className={style.NameContainer}>
        {this.getName()}
        <TemplateSharingIndicator
          agreement={template.agreement}
          isTemplateAccessible={isTemplateAccessible}
        />
      </div>
    );
  }
}

export default TemplateName;
