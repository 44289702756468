import { pipe } from 'lodash/fp';
import {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import isHotkey from 'is-hotkey';
import { localize, Message, MessageTranslator } from '@oneflowab/pomes';

import * as Command from 'components/filterable';

import Dialog from './dialog';
import Input from './input';
import style from './command-menu.module.scss';
import { Home } from './pages/home';
import { SearchMarketplace } from './pages/search-marketplace';

type Props = {
  message: MessageTranslator;
};

const CommandMenu = ({ message }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [pages, setPages] = useState<string[]>(['home']);
  const activePage = pages[pages.length - 1];
  const isHome = activePage === 'home';

  const popPage = useCallback(() => {
    setPages((_pages) => {
      const x = [..._pages];
      x.splice(-1, 1);
      return x;
    });
  }, []);

  const pushPage = useCallback((page: string) => {
    setPages((_pages) => [..._pages, page]);
  }, []);

  const resetPages = useCallback(() => {
    setPages(['home']);
  }, []);

  const closeCmdk = () => {
    resetPages();
    setIsOpen(false);
  };

  const handleInputValueChange = (value: string) => {
    setInputValue(value);
    queueMicrotask(() => {
      if (listRef.current) {
        listRef.current.scrollTo({
          top: 0,
        });
      }
    });
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // TODO: make sure this is working for windows when we want to enable it for all users
      if (isHotkey('cmd+k', e)) {
        e.preventDefault();
        e.stopPropagation();
        resetPages();
        setIsOpen((previousOpenState) => {
          // if closing the command menu, reset the pages
          if (previousOpenState === true) {
            resetPages();
          }

          return !previousOpenState;
        });
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // register the event listener only once when the component mounts
    // the dependency array should be empty
  }, []);

  function bounce() {
    if (ref.current) {
      ref.current.style.transform = 'scale(0.96)';
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = '';
        }
      }, 100);

      setInputValue('');
    }
  }

  const handleCommandRootKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      bounce();
    }

    if (isHome || inputValue.length) {
      return;
    }

    if (e.key === 'Backspace') {
      e.preventDefault();
      popPage();
      bounce();
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onRest={() => setIsOpen(false)}
      className={style.CommandMenuDialogContent}
      ref={ref}
    >
      <Command.Root
        listId="command-menu-list-id"
        labelId="command-menu-label-id"
        inputId="command-menu-input-id"
        className={style.CommandMenu}
        onKeyDown={handleCommandRootKeyDown}
      >
        <Input
          placeholder="Type a command or search..."
          loading={false}
          value={inputValue}
          onValueChange={handleInputValueChange}
        />
        <Command.List ref={listRef}>
          <Command.Empty>
            <Message
              id="No results found"
              comment="Message displayed when no commands are found"
            />
          </Command.Empty>
          {activePage === 'home' && <Home message={message} closeCmdk={closeCmdk} pushPage={pushPage} />}
          {activePage === 'marketplace' && <SearchMarketplace message={message} closeCmdk={pipe(closeCmdk)} />}
        </Command.List>
      </Command.Root>
    </Dialog>
  );
};

export default localize(CommandMenu);
