import { forwardRef } from 'react';

import List from './list';
import { subMenuStateContext } from './sub-root';
import type { ListProps } from './list';

const SubList = forwardRef<HTMLDivElement, Omit<ListProps, 'scope'>>(({ children, ...props }, ref) => (
  <List {...props} scope={subMenuStateContext} ref={ref}>
    {children}
  </List>
));

SubList.displayName = 'SubMenuList';

export default SubList;
