import type { MessageTranslator } from '@oneflowab/pomes';
import { useCallback, useState } from 'react';
import { localize } from '@oneflowab/pomes';
import clsx from 'clsx';

import VideoModal from 'components/agreement-video/video-preview/video-modal';
import VideoPopupInBody from 'components/agreement-video/video-preview/video-popup-in-body';
import VideoModalContent from 'components/agreement-video/video-preview/video-modal/video-modal-content';

import style from './video-preview.module.scss';

type Props = {
  isVideoModalOpen: boolean;
  thumbnail: string;
  source: string;
  autoplay: boolean;
  openModal?: () => void,
  handleClose?: () => void,
  message: MessageTranslator,
}

const VideoPreview = ({
  isVideoModalOpen = false,
  thumbnail,
  source,
  autoplay,
  openModal = undefined,
  handleClose = undefined,
  message,
}: Props) => {
  const [thumbnailLoadFailed, setThumbnailLoadFailed] = useState<boolean>(false);

  const renderModal = useCallback(() => {
    if (!isVideoModalOpen) {
      return null;
    }

    return (
      <VideoPopupInBody>
        <VideoModal
          isOpen={isVideoModalOpen}
          handleClose={handleClose}
          noOuterClose={false}
          modalKey="agreement video modal"
          fluid
        >
          <VideoModalContent hideControls={['playbackrates']} autoplay={autoplay} source={source} thumbnail={thumbnail} />
        </VideoModal>
      </VideoPopupInBody>
    );
  }, [isVideoModalOpen, handleClose, autoplay, source, thumbnail]);

  const onThumbnailLoadError = useCallback(() => {
    setThumbnailLoadFailed(true);
  }, [setThumbnailLoadFailed]);

  const renderThumbnailLink = useCallback(() => {
    if (thumbnailLoadFailed) {
      return (
        <button
          className={clsx(style.FakePreview, style.ThumbNailPlaceholder)}
          aria-label={
            message({
              id: 'Black thumbnail for contract video',
              comment: 'Arial label for video play button',
            })
          }
          onClick={openModal}
        >
          <span className={style.PlayButton} />
        </button>
      );
    }

    return (
      <button
        className={style.FakePreview}
        aria-label={
          message({
            id: 'Thumbnail for contract video',
            comment: 'Aria label for video preview thumbnail',
          })
        }
        onClick={openModal}
      >
        <img
          src={thumbnail}
          onError={() => onThumbnailLoadError()}
          alt="Agreement preview video thumbnail"
        />
        <span className={style.PlayButton} />
      </button>
    );
  }, [thumbnailLoadFailed, message, openModal, thumbnail, onThumbnailLoadError]);

  return (
    <div>
      {renderThumbnailLink()}
      {renderModal()}
    </div>
  );
};

export default localize<Props>(VideoPreview);
