// @flow
import { useSelector } from 'react-redux';
import { getCurrentContractMessages } from 'reducers/current-contract';

const emptyMessages = Object.freeze([]);

const useCurrentMessages = () => {
  const messages = useSelector(getCurrentContractMessages);

  return messages || emptyMessages;
};

export default useCurrentMessages;
