// @flow

import { connect } from 'react-redux';

import workspaceGroupsReducer from 'reducers/entities/workspace-groups';
import groupWorkspacesReducer from 'reducers/entities/group-workspaces';
import userWorkspacesReducer from 'reducers/entities/user-workspaces';
import rolesReducer from 'reducers/entities/roles';
import { USER_ROLE_SCOPE_WORKSPACE } from 'user';

import EditWorkspaceRole from './edit-workspace-role';

const defaultPagination = {
  limit: 20,
  offset: 0,
};

export const mapStateToProps = (state, { group }) => {
  const rolesQuery = rolesReducer.getQuerySelector(state, {
    name: 'edit-workspace-group-role',
  });

  return ({
    formState: workspaceGroupsReducer.getUpdateSelector(state, { id: group.id }),
    roles: rolesReducer.getRolesSelector(state, { ids: rolesQuery.result }),
    rolesQuery,
  });
};

const WORKSPACE_GROUPS_ACCESS_QUERY = 'workspaceGroups/groupAccess';
const GROUP_WORKSPACES_ACCESS_QUERY = 'groupWorkspaces/groupAccess';
const USER_WORKSPACES_ACCESS_QUERY = 'userWorkspaces/groupAccess';

export const mapDispatchToProps = (
  dispatch,
  {
    workspaceId,
    bindingId,
    role,
    group,
    pipeAction,
    positionId,
  },
) => ({
  queryRoles: ({ name, pagination = defaultPagination }) => {
    dispatch(rolesReducer.queryRoles({
      name: 'edit-workspace-group-role',
      params: {
        scope: USER_ROLE_SCOPE_WORKSPACE,
        q: name,
      },
      pagination,
      sort: ['-type', 'name'],
    }));
  },
  queryRolesLoadMore: ({ additionalResults }) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: 'edit-workspace-group-role',
      params: {
        scope: USER_ROLE_SCOPE_WORKSPACE,
      },
      additionalResults,
    }));
  },
  resetFormState: () => {
    dispatch(workspaceGroupsReducer.updateWorkspaceGroupReset({
      id: group.id,
    }));
  },
  onSubmit: (formData) => {
    dispatch(workspaceGroupsReducer.updateWorkspaceGroup({
      id: group.id,
      data: {
        removeData: {
          id: bindingId,
          resourceType: 'collection',
          resourceId: workspaceId,
          actorType: 'group',
          actorId: group.id,
          roleId: role.id,
        },
        createData: {
          id: group.id,
          resourceType: 'collection',
          resourceId: workspaceId,
          actorType: 'group',
          actorId: group.id,
          roleId: formData.role.id,
        },
      },
      pipe: {
        action: () => {
          const BINDINGS_QUERY = `workspace/${workspaceId}/relationships`;
          if (pipeAction === WORKSPACE_GROUPS_ACCESS_QUERY) {
            return workspaceGroupsReducer.queryWorkspaceGroups({
              name: BINDINGS_QUERY,
              pagination: {},
              params: {
                workspaceId,
                actorType: 'group',
              },
            });
          }

          if (pipeAction === GROUP_WORKSPACES_ACCESS_QUERY) {
            const GROUP_WORKSPACES_QUERY = `admin/groups/${group.id}/workspaces`;
            return groupWorkspacesReducer.queryGroupWorkspaces({
              name: GROUP_WORKSPACES_QUERY,
              pagination: {},
              params: {
                groupId: group.id,
              },
            });
          }

          return userWorkspacesReducer.queryUserWorkspaces({
            name: USER_WORKSPACES_ACCESS_QUERY,
            pagination: {},
            params: {
              positionId,
              actorType: 'group',
            },
          });
        },
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditWorkspaceRole);
