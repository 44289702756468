import { Message } from '@oneflowab/pomes';

import { MenuItem } from 'components/menu-item';
import { DelegatePendingApprovalRightsModal } from 'components/modals/delegate-pending-approval-rights';
import DelegatePendingApprovalIcon from 'components/icons/delegate-approval';

type Props = {
  agreement: Oneflow.Agreement
}

export const DelegatePendingApproval = ({ agreement }: Props) => (
  <DelegatePendingApprovalRightsModal
    agreement={agreement}
  >
    {(onClick: () => void) => (
      <MenuItem
        icon={DelegatePendingApprovalIcon}
        onClick={onClick}
        label={(
          <Message
            id="Delegate approval"
            comment="Label for action to Delegate pending approval."
          />
          )}
      />
    )}
  </DelegatePendingApprovalRightsModal>
);
