// @flow

import * as React from 'react';

import {
  getMomentWithMonthAndYear,
  getStartOfMonthISOString,
  getEndOfMonthISOString,
} from 'date';

import LifecycleCalendar from 'components/lifecycle-calendar';
import * as lifecycleClient from 'oneflow-client/lifecycle';

import style from './lifecycle.module.scss';

type QueryLifecycleEventsDateParams = {
  month: number,
  year: number,
};

export type Props = {
  currentWorkspaceId: number,
};

type State = {
  events: Array<DelayedEvent>,
};

class Lifecycle extends React.Component<Props, State> {
  state = {
    events: [],
  };

  queryLifecycleEvents = async (
    { year, month }: QueryLifecycleEventsDateParams,
    filterOnCreator?: string,
  ) => {
    const { currentWorkspaceId } = this.props;
    const selectedDate = getMomentWithMonthAndYear(month, year);

    let eventInfo = {
      workspaceId: currentWorkspaceId,
      fromDate: getStartOfMonthISOString(selectedDate),
      toDate: getEndOfMonthISOString(selectedDate),
      eventTypes: ['all'],
      createdBy: ['all'],
      userInRecipients: true,
    };

    if (filterOnCreator === 'me' || filterOnCreator === 'others') {
      eventInfo = {
        ...eventInfo,
        eventTypes: ['user'],
        createdBy: filterOnCreator === 'me' ? ['me'] : ['others'],
      };
    }

    eventInfo = {
      ...eventInfo,
      createdBy: [filterOnCreator],
    };
    const response = await lifecycleClient.getDelayedEvents(eventInfo);

    this.setState({
      events: response.collection,
    });
  };

  render() {
    const { events } = this.state;
    const { currentWorkspaceId } = this.props;

    return (
      <div className={style.Lifecycle}>
        <LifecycleCalendar
          events={events}
          queryLifecycleEvents={this.queryLifecycleEvents}
          currentWorkspaceId={currentWorkspaceId}
        />
      </div>
    );
  }
}

export default Lifecycle;
