// @flow

import {
  KEY_ADOCKA,
} from 'extensions';

const Adocka = {
  key: KEY_ADOCKA,
};

export default Adocka;
