// @flow

import React from 'react';
import get from 'lodash/get';

import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import LabelValueSeparator from 'components/label-value-separator';
import Tooltip from 'components/tooltip';

export type Props = {
  message: MessageTranslator,
  workspace: Workspace,
};

export const WorkspaceUserAccess = ({ workspace, message }: Props) => {
  const directAccess = get(workspace.collectionAccessStats, 'directActivePosition');
  const groupAccess = get(workspace.collectionAccessStats, 'group');
  const totalAccess = get(workspace.collectionAccessStats, 'totalActivePosition');
  const hasAccessToGroups = groupAccess !== undefined;

  const getGroupToolTipMessage = () => {
    if (!hasAccessToGroups) {
      return null;
    }

    return (
      <p>
        <strong>
          {message({
            id: 'Group access',
            comment: 'label for displaying number of users that has access to a workspace',
          })}
          <LabelValueSeparator />
        </strong>
        {message({
          id: '{count} group',
          pluralId: '{count} groups',
          pluralCondition: 'count',
          values: { count: groupAccess },
          comment: 'displays number of users that has access to a workspace',
        })}
      </p>
    );
  };

  const tooltipMessage = (
    <>
      <p>
        <strong>
          {message({
            id: 'Direct access',
            comment: 'label for displaying number of users that has access to a workspace',
          })}
          <LabelValueSeparator />
        </strong>
        {message({
          id: '{count} user',
          pluralId: '{count} users',
          pluralCondition: 'count',
          values: { count: directAccess },
          comment: 'displays number of users that has access to a workspace',
        })}
      </p>
      {getGroupToolTipMessage()}
    </>
  );

  return (
    <Tooltip
      side="top"
      message={tooltipMessage}
      zIndex="10003"
    >
      <div data-align-with-tooltip>
        <Message
          id="{count} user"
          pluralId="{count} users"
          pluralCondition="count"
          values={{ count: totalAccess }}
          comment="displays number of users that has access to a workspace"
        />
      </div>
    </Tooltip>
  );
};

export default localize<Props>(WorkspaceUserAccess);
