import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { rollbar } from 'logging';
import client from 'oneflow-client';
import { logout } from 'oneflow-client/login';
import { isEnvironment } from 'utils/environment';

import apiWrapper from 'sagas/api-wrapper';

import {
  REQUEST_ME_SUCCESS,
  LOGOUT,
  PING,
  REFRESH,
  getPositionFromSessionSelector,
  setSessionExpire,
  setRefreshSuccess,
} from 'reducers/session';

import { setError, setLoading } from 'reducers/app';

export function* setRollbarPayload() {
  const person = yield select(getPositionFromSessionSelector);

  if (!isEnvironment('development')) {
    yield call(rollbar.setPayload, { person });
  }
}

export const navigateToPostLogoutPath = (path) => {
  let postLoginPath = '/login';

  if (path && path !== '/') {
    postLoginPath = path;
  }

  return (
    new Promise(() => {
      window.location.assign(postLoginPath);
    })
  );
};

export function* logoutSaga(action) {
  try {
    yield put(setLoading(true));

    const data = yield call(apiWrapper, { method: logout });

    let postLogoutPath = data.returnUrl;

    if (action && action.postLogoutPath) {
      ({ postLogoutPath } = action);
    }

    yield call(navigateToPostLogoutPath, postLogoutPath);
  } catch (error) {
    yield put(setError(true, error));
    yield put(setLoading(false));
  }
}

export function* pingSaga(action) {
  try {
    const data = yield call(
      apiWrapper, { method: client.ping, params: { positionId: action.positionId } },
    );
    yield put(setSessionExpire(data));
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
  }
}

export function* refreshSaga() {
  try {
    const data = yield call(
      apiWrapper, { method: client.refresh },
    );
    yield put(setSessionExpire(data));
    yield put(setRefreshSuccess(true));
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(setRefreshSuccess(false));
  }
}

export default function* session() {
  yield takeEvery(REQUEST_ME_SUCCESS, setRollbarPayload);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeEvery(PING, pingSaga);
  yield takeEvery(REFRESH, refreshSaga);
}
