import { Message } from '@oneflowab/pomes';

import Modal from 'components/modal';
import Button from 'components/button';
// eslint-disable-next-line import/named
import { CancelButton } from 'components/buttons';
import WarningIcon from 'components/icons/warning';

import style from './reset-signatures-participant.module.scss';

export type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  type: 'participantRemoved' | 'partyUpdated' | 'participantUpdated' | 'partyRemoved';
};

const ResetSignatures = ({
  isOpen,
  onCancel,
  onConfirm,
  type = 'participantUpdated',
}: Props) => {
  const getButtonText = () => {
    if (type === 'participantRemoved' || type === 'partyRemoved') {
      return (
        <Message
          id="Remove and reset"
          comment="Button text in the remove participant and reset signatures modal."
        />
      );
    }

    return (
      <Message
        id="Confirm and reset"
        comment="Button text in the update participant and reset signatures modal."
      />
    );
  };

  const getMainText = () => {
    if (type === 'partyRemoved') {
      return (
        <strong>
          <Message
            id="Removing company from the document will reset all existing signatures."
            comment="Text in the removing company and reset signatures modal."
          />
        </strong>
      );
    }
    if (type === 'participantRemoved') {
      return (
        <strong>
          <Message
            id="Removing participants from the document will reset all existing signatures."
            comment="Text in the remove participant and reset signatures modal."
          />
        </strong>
      );
    }
    if (type === 'partyUpdated') {
      return (
        <strong>
          <Message
            id="Changing company details will reset all existing signatures."
            comment="Text in the updating company and reset signatures modal."
          />
        </strong>
      );
    }
    return (
      <strong>
        <Message
          id="Changing participant details will reset all existing signatures."
          comment="Text in the update participant and reset signatures modal."
        />
      </strong>
    );
  };

  return (
    <Modal
      header={(
        <Message
          id="Save and reset"
          comment="Modal title for save and reset approvals warning modal."
        />
      )}
      isOpen={isOpen}
      onCancel={onCancel}
      modalKey="reset signatures modal"
    >
      <Modal.Body isLoading={false}>
        <div className={style.BodyContainer}>
          <WarningIcon className={style.WarningIcon} />
          <div className={style.BodyContainerText}>
            {getMainText()}
            <p>
              <Message
                id="The document will need to be signed again."
                comment="Text in the update participant and reset signatures modal."
              />
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <div className={style.CancelButton}>
          <CancelButton
            onClick={onCancel}
            modalKey="reset signatures modal"
          />
        </div>
        <Button
          onClick={onConfirm}
          kind="primary"
        >
          {getButtonText()}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ResetSignatures;
