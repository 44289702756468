// @flow

import React, { useState } from 'react';
import clsx from 'clsx';
import { RadioGroupItem, RadioGroupItemInternal } from 'components/radio-group-item/radio-group-item';
import style from './radio-group.module.scss';

type Props = {
  children: Array<RadioGroupItem>,
  name: string,
  defaultValue?: string | null,
  onChange?: (string) => void,
  customClassName?: string,
};

export const RadioGroup = ({
  name,
  children,
  onChange,
  defaultValue,
  customClassName,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const onChangeHandler = (value) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  if (!Array.isArray(children) || !children.every((item) => item.type === RadioGroupItem)) {
    // eslint-disable-next-line no-console
    console.warn('Invalid RadioGroup children. Needs to be Array<RadioGroupItem>');
    return null;
  }

  const containerClasses = clsx(style.RadioGroupContainer, customClassName);

  return (
    <div className={containerClasses}>
      {
        children.map((item) => (
          <RadioGroupItemInternal
            value={item.props.value}
            label={item.props.label}
            checked={selectedValue === item.props.value}
            onChange={onChangeHandler}
            groupName={name}
            key={item.props.value}
            className={item.props.className}
          />
        ))
      }
    </div>
  );
};
