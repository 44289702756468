// @flow

import * as React from 'react';

import SmallWorkspaceIcon from 'components/icons/small-workspace';
import Button from 'components/button';

import style from './topbar-workspace.module.scss';

export type Props = {
  agreement: Agreement,
  onClick?: Function,
  isMobile?: boolean,
  canPerformAction: boolean,
};

const TopbarWorkspace = ({
  agreement,
  onClick,
  isMobile,
  canPerformAction,
}: Props) => {
  if (!agreement.collection) {
    return null;
  }

  if (isMobile) {
    return (
      <div className={style.WorkspaceMobile} data-testid="mobile">
        <SmallWorkspaceIcon height="12px" />
        <span className={style.WorkspaceName}>{agreement.collection.name}</span>
      </div>
    );
  }

  if (!canPerformAction) {
    return (
      <div className={style.WorkspaceDesktop}>
        <SmallWorkspaceIcon className={style.WorkspaceIcon} height="12px" />
        <span className={style.WorkspaceText}>{agreement.collection.name}</span>
      </div>
    );
  }

  return (
    <div className={style.WorkspaceDesktop} data-testid="desktop">
      <SmallWorkspaceIcon className={style.WorkspaceIcon} height="12px" />
      <Button
        onClick={onClick}
        customClass={style.WorkspaceButton}
        kind="linkSeparate"
        disabled={!canPerformAction}
      >
        {agreement.collection.name}
      </Button>
    </div>
  );
};

TopbarWorkspace.defaultProps = {
  onClick: undefined,
  isMobile: undefined,
};

export default TopbarWorkspace;
