// @flow

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tagsReducer from 'reducers/entities/tags';
import positionsReducer from 'reducers/entities/positions';
import { USER_NOT_INVITED } from 'user';

const TAGS_QUERY_NAME = 'tags-selector';
const COLLEAGUES_QUERY_NAME = 'colleagues-selector';

export function useColleagues({
  setColleaguesOptions,
}: { setColleaguesOptions: Function }) {
  const dispatch = useDispatch();
  const colleagueQuery = useSelector(
    (state) => positionsReducer.getQuerySelector(state, { name: COLLEAGUES_QUERY_NAME }),
  );
  const filterableColleagues = useSelector(
    (state) => positionsReducer.getPositionsSelector(state, { ids: colleagueQuery.result }),
  );

  useEffect(() => {
    if (colleagueQuery.status === 'pristine') {
      dispatch(positionsReducer.queryPositions({
        name: COLLEAGUES_QUERY_NAME,
        pagination: {
          offset: 0,
          limit: 50,
        },
        params: {
          invited: USER_NOT_INVITED,
        },
      }));
    }
  }, [dispatch, colleagueQuery.status]);

  useEffect(() => {
    if (colleagueQuery.status === 'success') {
      setColleaguesOptions(filterableColleagues);
    }
  }, [colleagueQuery.status, filterableColleagues, setColleaguesOptions]);
}

export function useTags({
  setTagsOptions,
}: { setTagsOptions: Function }) {
  const dispatch = useDispatch();
  const tagsQuery = useSelector(
    (state) => tagsReducer.getQuerySelector(state, { name: TAGS_QUERY_NAME }),
  );
  const filterableTags = useSelector(
    (state) => tagsReducer.getTagsSelector(state, { ids: tagsQuery.result }),
  );

  useEffect(() => {
    if (tagsQuery.status === 'pristine') {
      dispatch(tagsReducer.queryTags({
        name: TAGS_QUERY_NAME,
        pagination: {
          offset: 0,
          limit: 10000,
        },
      }));
    }
  }, [dispatch, tagsQuery.status]);

  useEffect(() => {
    if (tagsQuery.status === 'success') {
      setTagsOptions(filterableTags);
    }
  }, [filterableTags, setTagsOptions, tagsQuery.status]);
}
