// @flow

import { connect } from 'react-redux';

import tagsReducer from 'reducers/entities/tags';

import { DeleteTagsConfirmation } from './delete-tags';

const QUERY_NAME = '/admin/tags';

export const requestID = 'bulk';

type StateProps = {|
  removeState: RemoveState,
|};

type OwnProps = $Diff<Props, StateProps>;

type DispatchProps = {|
  deleteTags: () => void,
  resetRemoveState: Function,
|};

type MapStateToProps = (state: State) => StateProps;

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  removeState: tagsReducer.getRemoveSelector(state, { id: requestID }),
});

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { ids }) => ({
  deleteTags: () => {
    dispatch(tagsReducer.removeTag({
      id: requestID,
      data: {
        ids,
      },
      pipe: {
        action: () => tagsReducer.queryTagsReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(tagsReducer.removeTagReset({
      id: requestID,
    }));
  },
});

const connectedDeleteTagsConfirmation = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteTagsConfirmation);

export { connectedDeleteTagsConfirmation as DeleteTagsConfirmation };
