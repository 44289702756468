import { useState } from 'react';
import { useDispatch } from 'react-redux';
import agreementsReducer from 'reducers/entities/agreements';
import { Message } from '@oneflowab/pomes';

import Confirmable from 'components/confirmable';
import { ApproveButton } from 'components/buttons/approve';
import DocumentActionErrorBody from 'components/document-action-error';
import { TryAgainButton } from 'components/buttons';
import { ApproveBubbles } from 'components/modals/approve-draft/approve-bubbles/approve-bubbles';

import style from './approve-pending-document.module.scss';

const modalKey = 'approve pending document modal';

export type Props = {
  agreementId: number;
  onStepComplete: () => void;
  onClose: () => void;
  agreementChecksum?: string;
  approverId: number;
  guestToken: string | undefined;
};

export const ApprovePendingDocumentModal = ({
  agreementId,
  onStepComplete,
  onClose,
  agreementChecksum,
  approverId,
  guestToken,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const renderInformationText = () => (
    <Message
      id="You are about to approve this document.{breakLine} The document can be modified, but
      approvals would be reset and you will need to approve again."
      values={{
        breakLine: <br />,
      }}
      comment="Tells the user that after approving the document it can be modified and reset the approval."
    />
  );

  const onSuccess = () => {
    setIsLoading(false);
    onStepComplete();
  };

  const onFailure = (submitError) => {
    setError(submitError);
    setIsLoading(false);
  };

  const approvePendingState = () => {
    setIsLoading(true);
    dispatch(agreementsReducer.approvePendingState({
      id: agreementId,
      data: {
        agreementId,
        participantId: approverId,
        checksum: agreementChecksum,
        guestToken,
      },
      pipe: {
        onSuccess,
        onFailure,
      },
    }));
  };

  const handleConfirm = () => {
    approvePendingState();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const renderBody = () => {
    if (error) {
      return <DocumentActionErrorBody error={error} />;
    }

    return (
      <div className={style.BodyContainer}>
        <ApproveBubbles
          agreementId={agreementId}
        />
        <p>
          {renderInformationText()}
        </p>
      </div>
    );
  };

  const getActions = ({ closeConfirmation }: { closeConfirmation: () => void }) => {
    if (error) {
      return (
        <div className={style.TryAgainButtonContainer}>
          <TryAgainButton onClick={closeConfirmation} />
        </div>
      );
    }

    return (
      <div className={style.Confirm}>
        <ApproveButton
          onClick={() => handleConfirm()}
          smallSize
          disabled={isLoading}
        />
      </div>
    );
  };

  return (
    <Confirmable
      header={(
        <Message
          id="Approve document?"
          comment="Title for the document approval modal"
        />
    )}
      body={renderBody()}
      actions={getActions}
      onEnter={handleConfirm}
      onConfirm={handleConfirm}
      onClose={onClose}
      onOpen={handleOpen}
      isOpen={isOpen}
      modalKey={modalKey}
      customBodyClass={style.Body}
    />
  );
};
