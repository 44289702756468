// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  formatDateString,
  getDaysSince,
} from 'date';

import Cancel from 'components/icons/cancel';

import Insight from './insight';

import style from './insight.module.scss';

type Props = {
  cancelTime?: string,
  dateFormat: string,
};

const Canceled = ({ cancelTime, dateFormat }: Props) => {
  const renderInsight = () => {
    if (!cancelTime) {
      return null;
    }

    const daysSince = getDaysSince(cancelTime);
    const dateString = formatDateString(cancelTime, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    if (daysSince > 7) {
      return (
        <Message
          id="Canceled {date}"
          values={{
            date,
          }}
          comment="Insight for signed and canceled agreement. Visible in contract card."
        />
      );
    }

    if (daysSince > 0) {
      return (
        <Message
          id="Canceled yesterday ({date})"
          pluralId="Canceled {days} days ago ({date})"
          pluralCondition="days"
          values={{
            days: daysSince,
            date,
          }}
          comment="Insight for signed and canceled agreement. Visible in contract card."
        />
      );
    }

    if (daysSince === 0) {
      return (
        <Message
          id="Canceled today ({date})"
          values={{
            date,
          }}
          comment="Insight for signed and canceled agreement. Visible in contract card."
        />
      );
    }

    return null;
  };

  return (
    <Insight
      IconComponent={Cancel}
      insight={renderInsight()}
    />
  );
};

export default Canceled;
