// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { runExport } from 'oneflow-client/agreements';

import ContractIcon from 'components/icons/contract';
import ExportButton from 'components/export-button';

export type Props = {|
  agreementsQuery: Query,
  email: string,
  folderId: number,
  selectedFolder: any,
  showExportButton: boolean,
  workspace: Workspace,
  workspaceId: number,
|};

type State = {
  includeSubfolders: boolean,
}

class ExportContracts extends React.PureComponent<Props, State> {
  state = {
    includeSubfolders: true,
  }

  onExport = (onSuccess: Function, onFailure: Function) => {
    const { agreementsQuery, workspaceId } = this.props;
    const { includeSubfolders } = this.state;

    const includeFolderSubtree = includeSubfolders;

    runExport(workspaceId, agreementsQuery, includeFolderSubtree)
      .then(onSuccess)
      .catch((error) => {
        onFailure(error.body.status_code);
      });
  };

  changeCheckboxState = () => {
    const { includeSubfolders } = this.state;

    this.setState({
      includeSubfolders: !includeSubfolders,
    });
  }

  getModalHeader() {
    const { folderId } = this.props;

    if (folderId !== -1) {
      return (
        <Message
          id="Export folder documents"
          comment="Confirm modal title for exporting contracts."
        />
      );
    }
    return (
      <Message
        id="Export workspace documents"
        comment="Confirm modal title for exporting contracts."
      />
    );
  }

  render() {
    const {
      agreementsQuery,
      email,
      folderId,
      selectedFolder,
      showExportButton,
      workspace,
    } = this.props;

    const { includeSubfolders } = this.state;

    if (!showExportButton) {
      return null;
    }

    const isGlobalSearch = window.location.pathname === '/search/all';

    return (
      <ExportButton
        changeCheckboxState={this.changeCheckboxState}
        includeSubfolders={includeSubfolders}
        folderId={folderId}
        selectedFolder={selectedFolder}
        workspace={isGlobalSearch ? '' : workspace}
        header={this.getModalHeader()}
        emptyStateHeader={(
          <Message
            id="No documents to export"
            comment="Empty state header. Shown in contract export modal when having no contracts."
          />
        )}
        icon={<ContractIcon height="33px" />}
        email={email}
        query={agreementsQuery}
        onExport={this.onExport}
        isExportAgreements
      />
    );
  }
}

export default ExportContracts;
