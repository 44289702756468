import { connect } from 'react-redux';

import { getCounterpartyNames } from 'agreement/selectors';

import SignContractBody from './sign-contract-confirm-body';
import type { Props } from './sign-contract-confirm-body';

type OwnProps = {
  agreement: Agreement
};

export const mapStateToProps = (state: State, {
  agreement,
}: OwnProps) => ({
  counterpartyNames: getCounterpartyNames(agreement),
});

export const mapDispatchToProps = () => ({});

export default connect<Props, OwnProps, any, any, any, any>(
  mapStateToProps, mapDispatchToProps,
)(SignContractBody);
