// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { updateAgreementConfig } from 'oneflow-client/agreements';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';

import agreements from 'reducers/entities/agreements';
import agreementTemplates, { getTemplatesWithAgreementsSelector } from 'reducers/entities/agreement-templates';

import ContractTemplates, { type Props } from './contract-templates';

const QUERY_NAME = 'templates/contracts';

type StateProps = {|
  templates: Array<AgreementTemplate>,
  templatesQuery: Query,
  createState: CreateState,
  currentWorkspace: {},
  queryName: string,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const workspace = getCurrentWorkspaceSelector(state);

  const query = agreementTemplates.getQuerySelector(state, { name: QUERY_NAME });

  return {
    createState: agreementTemplates.getCreateSelector(state),
    queryName: QUERY_NAME,
    templates: getTemplatesWithAgreementsSelector(state, { ids: query.result }),
    templatesQuery: query,
    workspace,
  };
};

type DispatchProps = {|
  queryTemplates: QueryFuncArgs => void,
  queryReload: () => void,
  createTemplate: number => void,
  changeTemplateStatus: ({ id: number, status: boolean }) => Promise<void>,
|};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  queryTemplates: ({ params, pagination }: QueryFuncArgs) => {
    dispatch(agreementTemplates.queryAgreementTemplates({
      name: QUERY_NAME,
      pagination: {
        ...pagination,
        limit: 50,
      },
      params,
    }));
  },
  queryReload: () => {
    dispatch(agreementTemplates.queryAgreementTemplatesReload({ name: QUERY_NAME }));
  },
  createTemplate: (currentWorkspaceId) => {
    dispatch(agreementTemplates.createAgreementTemplate({
      data: {
        workspaceId: currentWorkspaceId,
        amplitudeScope: 'template list - create template button',
      },
      pipe: {
        action: ({ data }) => redirectToDocumentOnDocumentCreate(data, true),
      },
    }));
  },
  changeTemplateStatus: async ({ id, status }) => {
    const updatedConfig = await updateAgreementConfig({
      id,
      templateActive: status,
    });
    dispatch(agreements.setAgreements({
      [id]: { config: updatedConfig },
    }));
  },
});

export default connect<Props, {||}, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(ContractTemplates);
