// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import get from 'lodash/get';

import billingsReducer from 'reducers/entities/billings';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import { getAccountFromSessionSelector, getPositionFromSessionSelector } from 'reducers/session';
import { FREEMIUM_SELF_SERVICE_HEADER } from 'account/billing';

import FreemiumSelfServiceBilling, { type Props, type FormData } from './freemium-self-service-billing';

type StateProps = {|
  languageCode: EnabledLanguages,
  updateState: UpdateState,
  accountId: number,
|};

type DispatchProps = {|
  resetUpdateState: (id: number) => void;
  onSubmit: (id: number, formData: FormData) => void;
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps>;

type MapStateToProps = (State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);
  const position = getPositionFromSessionSelector(state);

  return {
    languageCode: getCurrentLanguageSelector(state),
    updateState: billingsReducer.getUpsertBillingSelector(state, { id: account.id }),
    account,
    position,
  };
};

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  resetUpdateState: (id) => {
    dispatch(billingsReducer.upsertBillingReset({ id }));
  },
  onSubmit: (
    id,
    {
      address,
      city,
      country,
      invoiceEmail,
      name,
      invoiceReference,
      vatNumber,
      zipCode,
      period,
    },
  ) => {
    dispatch(billingsReducer.upsertBilling({
      id,
      data: {
        address,
        city,
        country: get(country, 'value'),
        invoiceEmail,
        name,
        invoiceReference,
        vatNumber,
        zipCode,
        period,
        amplitudeScope: FREEMIUM_SELF_SERVICE_HEADER,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(FreemiumSelfServiceBilling);
