import { useCallback } from 'react';
import type { ReactNode } from 'react';

const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
};

type Props = {
  localizer: {
    messages: {
      today: ReactNode,
      previous: ReactNode,
      next: ReactNode,
    },
  },
  label: ReactNode,
  onNavigate: (to: string) => void,
  navigationPreviousDisabled: boolean,
  navigationNextDisabled: boolean,
};

const LifecycleCalendarToolbar = ({
  localizer: { messages },
  label,
  onNavigate,
  navigationPreviousDisabled,
  navigationNextDisabled,
}: Props) => {
  const onTodayClick = useCallback(() => {
    onNavigate(navigate.TODAY);
  }, [onNavigate]);
  const onPreviousClick = useCallback(() => {
    onNavigate(navigate.PREVIOUS);
  }, [onNavigate]);
  const onNextClick = useCallback(() => {
    onNavigate(navigate.NEXT);
  }, [onNavigate]);

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button
          type="button"
          onClick={onTodayClick}
        >
          {messages.today}
        </button>
        <button
          type="button"
          onClick={onPreviousClick}
          disabled={navigationPreviousDisabled}
        >
          {messages.previous}
        </button>
        <button
          type="button"
          onClick={onNextClick}
          disabled={navigationNextDisabled}
        >
          {messages.next}
        </button>
      </span>
      <span className="rbc-toolbar-label">{label}</span>
    </div>
  );
};

export default LifecycleCalendarToolbar;
