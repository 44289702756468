// @flow

import {
  get,
  post,
  remove,
  checkResponse,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

type PreSignedPost = {
  fields: {
    key: string,
  },
  url: string,
};

type GetPreSignedPost = () => Promise<PreSignedPost>;
export const getPreSignedPost: GetPreSignedPost = () => (
  get({
    url: '/assets/videos/upload/',
  }).then(extractResponseBodyAsJSON)
);

type UploadVideo = ({ id: number, file: Blob }) => Promise<string>;
export const uploadVideo: UploadVideo = async ({ id, file }) => {
  const { url, fields } = await getPreSignedPost();

  const formData = new FormData();

  Object
    .keys(fields)
    .forEach((key) => formData.append(key, fields[key]));

  formData.append('file', file, fields.key);

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'omit',
    body: formData,
  });

  await checkResponse(response);

  return post({
    url: `/agreements/${id}/video`,
    body: {
      key: fields.key,
    },
  }).then(extractResponseBodyAsJSON);
};

type RemoveVideo = ({ id: number }) => Promise<Agreement>;
export const removeVideo: RemoveVideo = ({ id }) => (
  remove({
    url: `/agreements/${id}/video`,
  }).then(extractResponseBodyAsJSON)
);

type UploadVideoForBox = ({
  file: Blob,
  url: string,
  fields: any,
}) => Promise<string>;

export const uploadVideoForBox: UploadVideoForBox = async ({
  file,
  url,
  fields,
}) => {
  const formData = new FormData();

  Object
    .keys(fields)
    .forEach((key) => formData.append(key, fields[key]));

  formData.append('file', file, fields.key);

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'omit',
    body: formData,
  });

  await checkResponse(response);
};

export const requestVideoProcessing = async ({
  id,
  key,
}) => {
  const result = await post({
    url: `/agreements/${id}/video`,
    body: {
      key,
      welcome_video: false,
    },
  });

  return extractResponseBodyAsJSON(result);
};
