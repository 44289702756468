// @flow
import { denormalize, schema } from 'normalizr';
import {
  camelizeKeys,
  decamelizeKeys,
  excludedAttributes,
  excludedKeys,
} from 'utils/camelizer';

import { post } from 'oneflow-client/core';

export const SHARED_WITH_ME_ID = 0;

export const camelizeBulkOperation = (obj: any) => camelizeKeys(obj, {
  process: (key, convert) => {
    if (/.+:.+/.test(key) || excludedAttributes.includes(key)) {
      return key;
    }
    return convert(key);
  },
  excludedKeys,
});

export const bulkOperationDenormalizeSchema = new schema.Entity('bulk_operations');

type BulkOperation = {
  id: number,
}

export type NormalizedAgreements = {
  entities: {
    bulk_operations: {
      [number]: BulkOperation,
    },
  },
  result: number,
  count: number,
}

export type AgreementNormalizer = (any) => NormalizedAgreements;

export const denormalizeBulkOperation: AgreementNormalizer = (bulkOperation) => (
  decamelizeKeys(denormalize(bulkOperation, bulkOperationDenormalizeSchema), {
    separator: '_',
    excludedKeys: ['nodes'],
  })
);

type CreateRemoveBulkOperation = ({
  async: boolean,
  actions: Array<{
    model_id: number,
    model_type: string,
    action_type: string,
  }>,
}) => Promise<any>;

export const createBulkOperation: CreateRemoveBulkOperation = ({
  async,
  actions,
}) => (
  post({
    url: '/bulk_operations/',
    body: {
      async,
      actions: decamelizeKeys(actions, {
        separator: '_',
      }),
    },
  })
    .then((response) => response.json())
    .then(camelizeBulkOperation)
);
