// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import { useVideoBoxProps } from 'contexts/video-box-props';

import ReplaceIcon from 'components/icons/replace-new';
import ToolbarButton from 'components/rich-text-editor-toolbars/toolbar-buttons/toolbar-button.jsx';
import VideoUpload from '../../../video/video-upload/video-upload';

import style from './replace-video.module.scss';

const ReplaceVideo = () => {
  const { isAllowedToAddBoxData } = useVideoBoxProps();
  return (
    <VideoUpload
      customClassName={clsx(style.ReplaceVideoDropzone, ({
        [style.Disabled]: !isAllowedToAddBoxData,
      }))}
      disabled={!isAllowedToAddBoxData}
    >
      <ToolbarButton
        label={(
          <Message
            id="Replace video"
            comment="The label of the video's toolbar that replace a video in the video box."
          />
      )}
        isMenuItem={false}
        icon={ReplaceIcon}
        disabled={!isAllowedToAddBoxData}
      />
    </VideoUpload>
  );
};

export default ReplaceVideo;
