// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Calendar from 'components/icons/calendar';

const Started = () => (
  <>
    <Calendar height="12px" />
    <span>
      <Message
        id="Started"
        comment="An insight for lifecycle events that indicates that a contract is started."
      />
    </span>
  </>
);

export default Started;
