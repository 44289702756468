// @flow
import * as React from 'react';

import { getPreferredCavasSizeForWidth } from 'utils/get-preferred-cavas-size';

import styles from './handwritten-signature.module.scss';

export type Props = {|
  handwrittenSignature?: string,
  handwrittenSignatureType?: string,
|}

const imageSize = getPreferredCavasSizeForWidth(275);

const HandwrittenSignature = ({ handwrittenSignature, handwrittenSignatureType }: Props) => {
  if (!handwrittenSignatureType || !handwrittenSignature) {
    return null;
  }

  return (
    <div
      className={styles.SignatureImageContainer}
      style={{
        maxWidth: imageSize.get('width'),
        maxHeight: imageSize.get('height'),
        width: imageSize.get('width'),
        height: imageSize.get('height'),
      }}
    >
      <img src={handwrittenSignature} alt="handwritten signature" />
    </div>
  );
};
export default HandwrittenSignature;
