// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';

import {
  createTagConnection,
  removeTagConnection,
  getTagConnections,
} from 'oneflow-client/tags';
import type { NormalizedTagConnections } from 'oneflow-client/tags';

import apiWrapper from 'sagas/api-wrapper';

import tagConnections from 'reducers/entities/tag-connections';
import tags from 'reducers/entities/tags';

type MapperArgs = {
  data: NormalizedTagConnections,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* queryMapper({ data }: MapperArgs): Mapper {
  yield put(tagConnections.setTagConnections(data.entities.tagConnections));
  yield put(tags.setTags(data.entities.tags));
}

export function* removeMapper({ action }: MapperArgs): Mapper {
  yield put(tagConnections.clearTagConnection({ id: action.id }));
}

const mappers = {
  query: {
    mapper: queryMapper,
    request: getTagConnections,
  },
  create: {
    mapper: queryMapper,
    request: createTagConnection,
  },
  remove: {
    mapper: removeMapper,
    request: removeTagConnection,
  },
};

const tagsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: tagConnections,
  mappers,
});

export default tagsSagas;
