/* eslint-disable import/prefer-default-export */
export const convert3HexTo6Hex = (hex: string): string | null => {
  // Check if the input is a valid 3-digit hexadecimal color code
  const validHex3 = /^#?([0-9A-Fa-f]{3})$/;

  // If not valid 3-digit hex return null
  if (!validHex3.test(hex)) {
    return null;
  }

  // Replicate each digit to form a 6-digit hexadecimal color code
  const hex6 = hex
    .replace('#', '')
    .split('')
    .map((char) => char.repeat(2))
    .join('');

  // Add the '#' symbol back to the 6-digit color code
  return `#${hex6}`;
};
