// @flow

import { isClosed } from 'agreement/states';
import * as AGREEMENT_CONSTANTS from 'agreement/participant/constants';
import React from 'react';

export const setInitialSelectedValue = (
  agreement: Agreement,
  type: string,
) => {
  const mfaChannelIsNone = () => agreement.config
          && Object.hasOwn(agreement.config, 'defaultMfaChannel')
          && agreement.config.defaultMfaChannel === null;
  switch (type) {
    case 'defaultCounterparties':
    case 'counterparties':
      return agreement.config?.defaultPartyType || 0;
    case 'deliveryChannels':
      return agreement.config?.defaultDeliveryChannel
        || agreement.availableOptions.deliveryChannels[0];
    case 'signMethods':
      return agreement.config?.defaultSignMethod || agreement.availableOptions.signMethods[0];
    case 'mfaChannels':
      if (mfaChannelIsNone()) {
        return 'none';
      }

      return agreement.config?.defaultMfaChannel || agreement.availableOptions.mfaChannels[0];
    case 'creatorRole':
      return (agreement.config && Object.hasOwn(agreement.config, 'defaultCreatorRole'))
        ? agreement.config.defaultCreatorRole
        : AGREEMENT_CONSTANTS.TYPE_IS_SIGNATORY;
    default:
      return null;
  }
};

export const getAvailableCreatorRules = (agreement) => {
  const availableCreatorRules = [
  ];
  if (!isClosed(agreement)) {
    availableCreatorRules.push(AGREEMENT_CONSTANTS.TYPE_IS_SIGNATORY);
    availableCreatorRules.push(AGREEMENT_CONSTANTS.TYPE_IS_INFLUENCER);
  }
  availableCreatorRules.push(AGREEMENT_CONSTANTS.TYPE_IS_ORGANIZER);
  return availableCreatorRules;
};

export const filterCreatorRoleTooltips: Array<React.Node> = (
  tooltips: Array<React.Node>,
  availableOptions: Array<number>,
) => {
  const filteredTooltips = [];
  const copiedTooltips = [...tooltips];
  filteredTooltips.push(copiedTooltips.shift()); // The header
  availableOptions.forEach((o) => {
    const t = copiedTooltips[o];
    filteredTooltips.push(t);
  });
  return filteredTooltips;
};
