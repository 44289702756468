// @flow

import { workspacesSanitizer } from 'agreement/sanitizers';

type GetWorkspacesFilterProps = () => {
  onSanitize: Function,
};

const getWorkspacesFilterProps: GetWorkspacesFilterProps = () => {
  const onSanitize = workspacesSanitizer();
  return {
    onSanitize,
  };
};

export default getWorkspacesFilterProps;
