// @flow

import * as React from 'react';
import { useTags } from './hooks';

type Props = {
  children: React.Node,
  setTagsOptions?: Function
};

function AsyncFilterBoxWithTags(props: Props) {
  const {
    children,
    setTagsOptions,
  } = props;

  useTags({
    setTagsOptions,
  });

  return children;
}

export default AsyncFilterBoxWithTags;
