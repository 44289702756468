// @flow

import React from 'react';
import isNaN from 'lodash/isNaN';
import get from 'lodash/get';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Pagination from 'components/pagination';
import { Table } from 'components/table';
import TooltipInfo from 'components/tooltip-info';

import style from './most-active-users.module.scss';

const NUMBER_OF_USERS = 20;

type Props = {
  message: MessageTranslator,
  mostActiveUsers: Array<any>,
  activeUsers: Function,
};

type State = {
  offset: number,
};

type User = {
  nrAgreementsSent: number,
  nrAgreementsSigned: number,
  nrAgreementsDeclined: number,
  nrAgreementsOverdue: number,
  signRate: number,
  avgTimeToSign: number,
};

export class MostActiveUsersComponent extends React.Component<Props, State> {
  state = {
    offset: 0,
  };

  getFullname = (position: Position) => (position.fullname);

  getSentAgreements = (user: User) => (user.nrAgreementsSent);

  getSignedAgreements = (user: User) => (user.nrAgreementsSigned);

  getDeclinedAgreements = (user: User) => (user.nrAgreementsDeclined);

  getOverdueAgreements = (user: User) => (user.nrAgreementsOverdue);

  getSignRate = (user: User) => {
    if (user.signRate === null) {
      return '-';
    }
    return `${+(user.signRate.toFixed(2))}%`;
  };

  getAvgTimeToSign = (user: User) => (this.getFormattedAverage(user.avgTimeToSign));

  getColumnLabel = () => {
    const { message } = this.props;
    return message({
      id: 'Pending',
      comment: 'Used as the label for the number of pending contracts column in the dashboard active user list',
    });
  }

  getNewColumns = () => {
    const { message } = this.props;
    return [
      {
        name: 'agreementsDeclined',
        label: message({
          id: 'Declined',
          comment: 'Used as the label for the number of declined contracts column in the dashboard active user list',
        }),
        type: 'cell',
        value: this.getDeclinedAgreements,
      },
      {
        name: 'agreementsOverdue',
        label: message({
          id: 'Overdue',
          comment: 'Used as the label for the number of overdue contracts column in the dashboard active user list',
        }),
        type: 'cell',
        value: this.getOverdueAgreements,
      },
    ];
  }

  getUsers = () => {
    const { activeUsers } = this.props;
    const { offset } = this.state;

    let users = activeUsers({
      offset,
      limit: NUMBER_OF_USERS,
    });

    users = users.map((user) => {
      const pending = get(user, 'contractStat.pending.count');
      const signed = get(user, 'contractStat.signed.count');
      const declined = get(user, 'contractStat.declined.count');
      const overdue = get(user, 'contractStat.overdue.count');
      const total = pending + signed + declined + overdue;
      return ({
        avgTimeToSign: user.timeToSignStat.mean,
        fullname: user.fullname,
        id: user.positionId,
        nrAgreementsSent: pending,
        nrAgreementsSigned: signed,
        nrAgreementsDeclined: declined,
        nrAgreementsOverdue: overdue,
        signRate: this.calculateSignRate(signed, total),
      });
    });

    return users;
  }

  getTableConfig() {
    const { message } = this.props;

    const actions = [];

    return {
      items: this.getUsers(),
      itemKey: 'id',
      actions,
      columns: [
        {
          name: 'name',
          label: message({
            id: 'User',
            comment: 'Used as the label for the user column in the dashboard active user list',
          }),
          type: 'cell',
          value: this.getFullname,
          className: style.Name,
        },
        {
          name: 'agreementsSent',
          label: this.getColumnLabel(),
          type: 'cell',
          value: this.getSentAgreements,
        },
        {
          name: 'agreementsSigned',
          label: message({
            id: 'Signed',
            comment: 'Used as the label for the number of signed contracts column in the dashboard active user list',
          }),
          type: 'cell',
          value: this.getSignedAgreements,
        },
        ...this.getNewColumns(),
        {
          name: 'signRate',
          label: message({
            id: 'Sign rate',
            comment: 'Used as the label for the sign rate column in the dashboard active user list',
          }),
          type: 'cell',
          value: this.getSignRate,
        },
        {
          name: 'avgTimeToSign',
          label: message({
            id: 'Average time to sign',
            comment: 'Used as the label for the average time for signing contracts column in the dashboard active user list',
          }),
          type: 'cell',
          value: this.getAvgTimeToSign,
        },
      ],
    };
  }

  calculateSignRate = (signed: number, total: number) => {
    if (!total) {
      return null;
    }
    return Math.round((signed / total) * 100);
  };

  getFormattedAverage = (seconds: number) => {
    const minutes = (seconds || 0) / 60;
    const { message } = this.props;
    const hourInMinutes = 60;
    const dayInMinutes = 24 * hourInMinutes;
    const yearInMinutes = 365 * dayInMinutes;
    let duration = minutes;

    if (seconds === null || isNaN(Number(seconds))) {
      return '-';
    }

    if (minutes < 1) {
      return message({
        id: '< 1 minute',
        comment: 'Used as the duration for displaying average time to sign in minutes on the dashboard',
      });
    }
    if (minutes >= 1 && minutes < hourInMinutes) {
      duration = Math.round(minutes);
      const minutesText = message({
        id: '1 minute',
        pluralId: '{duration} minutes',
        pluralCondition: 'duration',
        values: {
          duration,
        },
        comment: 'Used as the duration for displaying average time to sign in minutes on the dashboard',
      });
      return minutesText;
    }
    if (minutes >= hourInMinutes && minutes < 2 * dayInMinutes) {
      duration = +(minutes / 60).toFixed(1);
      const hoursText = message({
        id: '1 hour',
        pluralId: '{duration} hours',
        pluralCondition: 'duration',
        values: {
          duration,
        },
        comment: 'Used as the duration for displaying average time to sign in hours on the dashboard',
      });
      return hoursText;
    }
    if (minutes >= 2 * dayInMinutes && minutes < yearInMinutes) {
      duration = +(minutes / dayInMinutes).toFixed(1);
      const daysText = message({
        id: '1 day',
        pluralId: '{duration} days',
        pluralCondition: 'duration',
        values: {
          duration,
        },
        comment: 'Used as the duration for displaying average time to sign in days on the dashboard',
      });
      return daysText;
    }

    return '> 1 year';
  };

  changeHandler = ({ offset }: { offset: number }) => {
    this.setState({ offset });
  };

  render() {
    const { message, mostActiveUsers } = this.props;
    const { offset } = this.state;
    return (
      <div className={style.MostActiveUsers}>
        <div className={style.MostActiveUsersHeader}>
          <Message
            id="Active users"
            comment="Widget header for the most active users list on the dashboard."
          />
          <TooltipInfo
            message={message({
              id: 'Overview of active users in a workspace based on documents sent and signed. Only users that have been active during the selected date range will be shown in the list.',
              comment: 'Tooltip message for the most active users list on the dashboard.',
            })}
          />
        </div>
        <div className={style.Divider} />
        <Table
          config={this.getTableConfig()}
          query={{ loading: false }}
        />
        <Pagination
          totalItems={mostActiveUsers.length}
          itemsPerPage={NUMBER_OF_USERS}
          currentOffset={offset}
          onChange={this.changeHandler}
          entityName={message({
            id: 'users',
            comment: 'Used as the entity name in pagination of users.',
          })}
        />
      </div>
    );
  }
}

export default localize<Props>(MostActiveUsersComponent);
