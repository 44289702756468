// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

import style from './delete-webhook.module.scss';

export type Props = {
  webhook: Webhook,
  removeState: RemoveState,
  deleteWebhook: () => void,
  resetRemoveState: Function,
  children: Function,
};

export const DeleteWebhook = ({
  removeState,
  children,
  deleteWebhook,
  resetRemoveState,
  webhook,
}: Props) => (
  <RemoveConfirm
    onConfirm={deleteWebhook}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <>
        <Message
          id="You are about to delete the webhook with the URL below."
          comment="Modal text for deleting webhook."
        />
        <span className={style.Url}>
          <Badge label={webhook.url} kind="name" />
        </span>
      </>
    )}
    modalKey="delete webhook modal"
  >
    {children}
  </RemoveConfirm>
);
