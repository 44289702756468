import { ReactNode, CSSProperties } from 'react';
import clsx from 'clsx';

import NewCrossIcon from 'components/icons/new-cross';
import { SEVERITY_TYPES } from './constants';

import style from './alert.module.scss';

type Props = {
  severity?: typeof SEVERITY_TYPES[keyof typeof SEVERITY_TYPES];
  icon?: ReactNode;
  onClose?: () => void;
  children: ReactNode;
  sx?: CSSProperties;
};

const Alert = ({
  icon,
  onClose,
  severity,
  sx,
  children,
  ...rest
}: Props) => {
  const severityStyles = {
    [style.SuccessAlert]: severity === SEVERITY_TYPES.SUCCESS,
    [style.WarningErrorAlert]: severity === SEVERITY_TYPES.WARNING
      || severity === SEVERITY_TYPES.ERROR,
  };

  return (
    <div
      className={clsx(style.AlertWrapper, severityStyles)}
      style={sx}
      {...rest}
    >
      {icon && (
        <span className={style.AlertIconWrapper}>
          <span className={clsx(style.AlertIcon, severityStyles)}>
            {icon}
          </span>
        </span>
      )}
      <div className={clsx(style.AlertMessage, severityStyles)}>
        {children}
      </div>
      {onClose && (
        <div className={style.AlertActionWrapper}>
          <button
            aria-label="Close"
            className={style.AlertActionButton}
            onClick={onClose}
          >
            <span className={clsx(style.AlertActionIcon, severityStyles)}>
              <NewCrossIcon width="10px" height="10px" />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Alert;
