// @flow

import omit from 'lodash/omit';
import { normalize, schema } from 'normalizr';

import {
  getMultiple,
  extractResponseBodyAsJSON,
  type SortParams,
} from 'oneflow-client/core';

export const groupWorkspacesSchema = new schema.Entity('groupWorkspaces');
const normalizeGroupWorkspaces = (groupWorkspacesData) => ({
  ...normalize(groupWorkspacesData.collection, [groupWorkspacesSchema]),
  count: groupWorkspacesData.count,
});

type GetGroupWorkspaces = ({
  params: {
    groupId: number,
    [string]: any,
  },
  pagination?: {},
  sorting?: SortParams,
}) => Promise<any>;

// eslint-disable-next-line import/prefer-default-export
export const getGroupWorkspaces: GetGroupWorkspaces = ({
  pagination,
  params,
  sorting = { attr: 'name', direction: 'asc' },
}) => (
  getMultiple({
    url: `/groups/${params.groupId}/collections/bindings/`,
    params: omit(params, 'groupId'),
    pagination,
    sorting,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeGroupWorkspaces)
);
