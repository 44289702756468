// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import type { Match } from 'react-router';

import Button from 'components/button';
import { LogoSymbol } from 'components/logo';
import { NotFound } from 'components/errors';
import Video from 'routes/agreements/agreement/video';
import { isTemplate } from 'agreement/states';

import style from './agreement.module.scss';

export type Props = {|
  fetchAgreement: Function,
  agreement: Agreement,
  fetchState: FetchState,
  match: Match,
|};

type State = {
  backToHref: string,
}

const Logo = () => <LogoSymbol height="40px" />;

class AgreementPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      backToHref: '/documents',
    };
  }

  componentDidMount() {
    const { fetchAgreement } = this.props;

    fetchAgreement();
  }

  render() {
    const {
      agreement,
      fetchState,
      match,
    } = this.props;

    if (!match.params.id) {
      return <NotFound redirect to="/dashboard" />;
    }

    if (fetchState.loading) {
      return null;
    }

    if (fetchState.error) {
      return <NotFound />;
    }

    if (!fetchState.success) {
      return null;
    }

    return (
      <div className={style.Body}>
        <div className={style.Content}>
          <Video
            agreement={agreement}
          />
        </div>
        <div className={style.Footer}>
          <Button
            href="/"
            icon={Logo}
            external
          />
          <Button
            color="forest-green"
            customClass={style.Back}
            href={`${this.state.backToHref}/${agreement.id}`}
            external
          >
            {isTemplate(agreement)
              ? (
                <Message
                  id="Back to template"
                  comment="Button label. Navigates back to the template after a video is successfully added"
                />
              ) : (
                <Message
                  id="Back to contract"
                  comment="Button label. Navigates back to the contract after a video is successfully added"
                />
              )}
          </Button>
        </div>
      </div>
    );
  }
}

export default AgreementPage;
