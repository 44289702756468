import Message from 'components/message';

import Button from 'components/button';
import SmallAdd from 'components/icons/small-add';

import style from './add-product.module.scss';

type Props = {
  onClick: () => void,
  disabled?: boolean,
};

export const AddProductButton = ({
  onClick,
  disabled = false,
}: Props) => (
  <Button
    data-testid="add-product"
    icon={<SmallAdd height="8px" />}
    disabled={disabled}
    onClick={onClick}
    customClass={style.AddProduct}
  >
    <Message
      id="Add product"
      comment="Text for button to add a product action."
    />
  </Button>
);
