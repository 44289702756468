// @flow

import moment from 'moment';
import type Moment from 'moment';
import log from 'logging';

export const DATE_FORMAT = 'YYYY-MM-DD';

const CLIENT_FORMAT = 'L';

type DateInput = string | Moment | Array<number>;
type GetMoment = (date?: any) => Moment;
type GetTimestamp = (date: DateInput) => number;
type GetTodayTimestamp = () => number;
type GetCurrentYear = () => number;
type GetMonths = () => Array<string>;
type GetCurrentHour = () => number;
type GetCurrentMonth = () => number;

export const getMoment: GetMoment = (date) => {
  if (!date) {
    return moment();
  }

  if (date && moment.isMoment(date)) {
    return date.local();
  }

  return moment(date);
};

export const toTimestamp = (date: Moment) => (
  date.unix()
);

export const toMoment = (timestamp: number) => (
  moment.unix(timestamp)
);

export const timestampToDateString = (timestamp: number, dateFormat?: string = CLIENT_FORMAT) => (
  toMoment(timestamp).format(dateFormat)
);

export const formatDateString = (dateString?: string, dateFormat?: string = CLIENT_FORMAT) => (
  moment(dateString).format(dateFormat)
);

export const formatJan312021 = (dateFormat: string) => (
  timestampToDateString(1612047600, dateFormat)
);

export const getAvailableDateFormats = ({ message }) => ([
  { id: 'YYYY-MM-DD', translatedDateFormat: message({ id: 'YYYY-MM-DD', comment: 'Date format' }) },
  { id: 'MM-DD-YYYY', translatedDateFormat: message({ id: 'MM-DD-YYYY', comment: 'Date format' }) },
  { id: 'DD-MM-YYYY', translatedDateFormat: message({ id: 'DD-MM-YYYY', comment: 'Date format' }) },
  { id: 'YYYY/MM/DD', translatedDateFormat: message({ id: 'YYYY/MM/DD', comment: 'Date format' }) },
  { id: 'MM/DD/YYYY', translatedDateFormat: message({ id: 'MM/DD/YYYY', comment: 'Date format' }) },
  { id: 'DD/MM/YYYY', translatedDateFormat: message({ id: 'DD/MM/YYYY', comment: 'Date format' }) },
  { id: 'YYYY.MM.DD', translatedDateFormat: message({ id: 'YYYY.MM.DD', comment: 'Date format' }) },
  { id: 'MM.DD.YYYY', translatedDateFormat: message({ id: 'MM.DD.YYYY', comment: 'Date format' }) },
  { id: 'DD.MM.YYYY', translatedDateFormat: message({ id: 'DD.MM.YYYY', comment: 'Date format' }) },
]);

export const getTranslationForDateFormat = ({ message, dateFormat }) => {
  const match = getAvailableDateFormats({ message }).find((format) => format.id === dateFormat);

  if (!match) {
    return '';
  }

  return match.translatedDateFormat;
};

export const getDateFormatsAsOptions = ({ message }) => (
  getAvailableDateFormats({ message }).map((dateFormat) => (
    {
      label: `${getTranslationForDateFormat({
        message,
        dateFormat: dateFormat.id,
      })} (${formatJan312021(dateFormat.id)})`,
      value: dateFormat.id,
    }
  ))
);

export const getDateFormat = (
  brandingDateFormat: string,
  showTime: boolean = false,
) => {
  let dateFormat = brandingDateFormat || DATE_FORMAT;

  if (showTime) {
    dateFormat = `${dateFormat} LT`;
  }

  return dateFormat;
};

export const getDateWithSetHour = (date: DateInput, hour: number) => (
  getMoment(date).set({
    hour,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  }).format()
);

export const getStartOfDay = (date: DateInput) => (
  getMoment(date).startOf('day')
);

export const getEndOfDay = (date: DateInput) => (
  getMoment(date).endOf('day')
);

export const getStartOfMonth = (date: DateInput) => (
  getMoment(date).startOf('month')
);

export const getEndOfMonth = (date: DateInput) => (
  getMoment(date).endOf('month')
);

export const getStartOfMonthISOString = (date: DateInput) => (
  getStartOfMonth(date).format()
);

export const getEndOfMonthISOString = (date: DateInput) => (
  getEndOfMonth(date).format()
);

export const getStartOfToday = () => (
  getMoment().startOf('day')
);

export const getEndOfToday = () => (
  getMoment().endOf('day')
);

export const getDaysUntil = (dateString: string) => (
  getStartOfDay(dateString).diff(getStartOfToday(), 'days')
);

export const getDaysSince = (dateString: string) => (
  getStartOfToday().diff(getStartOfDay(dateString), 'days')
);

export const getMinutesBetween = (dateStringLatest: string, dateStringOldest: string) => (
  getMoment(dateStringLatest).diff(getMoment(dateStringOldest), 'minutes')
);

export const getDaysUntilTimestamp = (timestamp: number) => (
  getDaysUntil(timestampToDateString(timestamp, DATE_FORMAT))
);

export const getDaysSinceTimestamp = (timestamp: number) => (
  getDaysSince(timestampToDateString(timestamp, DATE_FORMAT))
);

export const getEndOfDayTimestamp: GetTimestamp = (date) => (
  toTimestamp(getEndOfDay(date))
);

export const getStartOfDayTimestamp: GetTimestamp = (date) => (
  toTimestamp(getStartOfDay(date))
);

export const getEndOfTodayTimestamp: GetTodayTimestamp = () => (
  getEndOfDayTimestamp(getEndOfToday())
);

export const isToday = (date: DateInput) => (
  getMoment().isSame(date, 'day')
);

export const hasDatePassed = (date: DateInput) => (
  getMoment(date).isBefore(getMoment(), 'day')
);
export const dateAtNoon = (date: Moment): Moment => (
  date
    .hours(12)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
);

export const isValidMonth = (month: number): boolean => (
  !Number.isNaN(month) && month > 0 && month <= 12
);

export const getCurrentHour: GetCurrentHour = () => (
  getMoment().hour()
);

export const getCurrentMonth: GetCurrentMonth = () => (
  getMoment().month() + 1
);

export const getCurrentYear: GetCurrentYear = () => (
  getMoment().year()
);

export const getMonths: GetMonths = () => moment.months();

export const addDaysToDate = (date: Moment, days: number): Moment => (
  moment(date).add(days, 'day')
);

export const getMomentWithMonthAndYear = (month: number, year: number): Moment => (
  moment([year, month])
);

export const getMonthsSinceCurrent = (month: number, year: number): number => (
  (year * 12 + month - getCurrentYear() * 12 - getCurrentMonth())
);

export const isTimeSameOrBefore = (time: string, date: string) => moment(time).isSameOrBefore(date);

export const getAgreementDateFormat = (dateformat: string | null | undefined) => {
  if (!dateformat) {
    log.warning('Agreement dateformat is missing!');
    return 'YYYY-MM-DD';
  }
  return dateformat;
};
