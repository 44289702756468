// @flow

import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import isEmpty from 'lodash/isEmpty';

import {
  getActiveTable,
  getActiveTableBody,
  getActiveTableRow,
  getActiveTableCell,
} from './table-utils';
import { addEmptyColumn } from './table-columns';
import { getColumnIndex, createBlockMatrix } from './block-matrix';

const insertBefore = (editor: any) => {
  const blockMatrix = createBlockMatrix(editor);
  if (!blockMatrix) {
    return;
  }

  const activeTable = getActiveTable(editor);
  const activeTableRow = getActiveTableRow(editor);
  const activeTableCell = getActiveTableCell(editor);
  const columnIndex = getColumnIndex(blockMatrix, activeTableRow, activeTableCell);

  const insertedNodes = new Map();

  blockMatrix.forEach((row) => {
    const cell = row[columnIndex];

    if (isEmpty(cell)) {
      return;
    }
    if (cell.colSpan === 1 || cell.isLeftOfMergedCell || blockMatrix.length === 1) {
      if (insertedNodes.get(cell.parentNode)) {
        return;
      }

      Transforms.insertNodes(editor, {
        type: 'table-cell',
        children: [{
          type: 'paragraph',
          children: [{
            text: '',
          }],
        }],
        rowSpan: cell.node.rowSpan,
      }, {
        at: [...ReactEditor.findPath(editor, cell.parentNode), cell.nodeIndex],
        select: true,
      });

      insertedNodes.set(cell.parentNode, true);
    } else {
      if (insertedNodes.get(cell.parentNode)) {
        return;
      }
      Transforms.setNodes(editor, {
        colSpan: cell.colSpan + 1,
      }, {
        at: ReactEditor.findPath(editor, cell.node),
      });
      insertedNodes.set(cell.parentNode, true);
    }
  });

  addEmptyColumn({
    editor,
    tableElement: activeTable,
    columnIndex,
  });
};

const insertAfter = (editor: any) => {
  const blockMatrix = createBlockMatrix(editor);
  if (!blockMatrix) {
    return;
  }
  const activeTable = getActiveTable(editor);
  const activeTableBody = getActiveTableBody(editor);
  const activeTableRow = getActiveTableRow(editor);
  const activeTableCell = getActiveTableCell(editor);
  const activeRowIndex = activeTableBody.children.indexOf(activeTableRow);
  const activeRow = blockMatrix[activeRowIndex];
  let columnIndex = getColumnIndex(blockMatrix, activeTableRow, activeTableCell);
  const activeCell = activeRow[columnIndex];

  if (activeCell.isLeftOfMergedCell) {
    columnIndex += activeCell.colSpan - 1;
  }

  const insertedNodes = new Map();
  blockMatrix.forEach((row) => {
    const cell = row[columnIndex];
    if (isEmpty(cell)) {
      return;
    }
    if (cell.colSpan === 1 || cell.isRightOfMergedCell || blockMatrix.length === 1) {
      if (insertedNodes.get(cell.parentNode)) {
        return;
      }

      Transforms.insertNodes(editor, {
        type: 'table-cell',
        children: [{
          type: 'paragraph',
          children: [{
            text: '',
          }],
        }],
        rowSpan: cell.node.rowSpan,
        style: cell?.node?.style,
      }, {
        at: [...ReactEditor.findPath(editor, cell.parentNode), cell.nodeIndex + 1],
        select: true,
      });

      insertedNodes.set(cell.parentNode, true);
    } else {
      if (insertedNodes.get(cell.parentNode)) {
        return;
      }

      Transforms.setNodes(editor, {
        colSpan: cell.colSpan + 1,
      }, {
        at: ReactEditor.findPath(editor, cell.node),
      });

      insertedNodes.set(cell.parentNode, true);
    }
  });

  addEmptyColumn({
    editor,
    tableElement: activeTable,
    columnIndex: columnIndex + 1,
  });
};

const addTableColumn = (editor: any, direction: 'before' | 'after') => {
  if (direction === 'before') {
    insertBefore(editor);
    return;
  }

  insertAfter(editor);
};

export default addTableColumn;
