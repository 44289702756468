import { useState } from 'react';
import type { ReactNode } from 'react';

import { MultistepModal, ModalStep } from 'components/modal';

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import MoveContractStep from './move-contract-container';
import { MoveContractWarningsStep } from '../move-contract-bulk/steps/move-contract-warnings-step/move-contract-warnings-step';

export type SkippedDocumentData = {
  type: 'withoutMovePermission' | 'alreadyInTargetFolder',
  documentId: Oneflow.Agreement['id'],
};

export type Props = {
  children: (openModal: () => void) => ReactNode,
  queryName: string,
  agreement: Oneflow.Agreement,
};

export const MoveContractBulk = ({
  children,
  queryName,
  agreement,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [skippedDocuments, setSkippedDocuments] = useState<SkippedDocumentData[]>([]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <MultistepModal isOpen={isOpen} onCancel={handleClose}>
        <ModalStep>
          {({ onStepComplete }) => (
            <MoveContractStep
              agreement={agreement}
              onClose={handleClose}
              queryName={queryName}
              setSkippedDocuments={setSkippedDocuments}
              onStepComplete={() => {
                onStepComplete();
              }}
            />
          )}
        </ModalStep>
        <ModalStep>
          {({ onPreviousStep }) => (
            <MoveContractWarningsStep
              skippedDocuments={skippedDocuments}
              onClose={() => {
                onPreviousStep();
                handleClose();
              }}
            />
          )}
        </ModalStep>
      </MultistepModal>
      {children(handleOpen)}
    </>
  );
};
