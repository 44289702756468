import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import type { ReactNode } from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import ActionsDotsIcon from 'components/icons/actions-dots';

import useMatchMedia, { DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY } from 'hooks/use-match-media';
import style from './actions-menu.module.scss';

type Props = {
  actions: ReactNode[],
  disabled?: boolean,
  customIcon?: ReactNode,
  customClassName?: string,
  customTriggerClassname?: string,
  focusOnCloseDisabled?: boolean,
  setVisibility?: (visibility: boolean) => void,
}

const ActionsMenu = ({
  actions,
  disabled = false,
  customIcon,
  customClassName,
  customTriggerClassname,
  focusOnCloseDisabled = false,
  setVisibility,
}: Props) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const actionsMemo = useMemo(() => actions.map((action) => ({ action, key: uniqueId('action-menu-item') })), [actions]);
  const menuClassNames = clsx(style.ActionsMenu, customClassName);
  const triggerClassNames = clsx(style.TriggerButton, customTriggerClassname);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const isCollapsedLayout = useMatchMedia(DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY);

  const dropdownIcon = customIcon ?? <ActionsDotsIcon />;

  const handleOnCloseAutoFocus = (event: Event) => {
    if (focusOnCloseDisabled) {
      event.preventDefault();
      if (triggerRef.current) {
        triggerRef.current.blur();
      }
    }
  };

  const handleOnOpen = (open: boolean) => {
    setIsDropDownOpen(open);
    setVisibility?.(open);
  };

  useEffect(() => () => {
    setIsDropDownOpen(false);
    setVisibility?.(false);
  }, [setVisibility]);

  return (
    <DropdownMenu.Root
      modal
      open={isDropDownOpen}
      onOpenChange={handleOnOpen}
    >
      <DropdownMenu.Trigger
        ref={triggerRef}
        className={triggerClassNames}
        disabled={disabled}
        // Collapsed view: onPointerDown & onClick
        // Prevents the menu to open when pressing the button whilst scrolling
        onPointerDown={isCollapsedLayout ? (e) => e.preventDefault() : undefined}
        onClick={isCollapsedLayout ? () => handleOnOpen(!isDropDownOpen) : undefined}
      >
        {dropdownIcon}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          onCloseAutoFocus={handleOnCloseAutoFocus}
          className={menuClassNames}
          side="bottom"
          align="end"
          sideOffset={2}
          hideWhenDetached
          collisionPadding={8}
        >
          {actionsMemo.map(({ action, key }) => {
            if (action === 'separator') {
              return <DropdownMenu.Separator key={key} />;
            }

            return (
              <DropdownMenu.Item
                key={key}
                className={style.ActionItem}
              >
                {action}
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default ActionsMenu;
