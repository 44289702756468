import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import useAgreement from 'hooks/use-agreement';
import { getSignedParticipants } from 'agreement/selectors';
import { getCurrentContractId } from 'reducers/current-contract';

import Message from 'components/message';
import WarningIcon from 'components/icons/warning';

import style from '../save-changes-modal.module.scss';

type Props = {
  isSuggestion: boolean,
}

const Body = ({ isSuggestion }: Props) => {
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const willResetSignatures = !isEmpty(getSignedParticipants(agreement));

  return (
    <>
      {willResetSignatures && <div className={style.WarningIconContainer}><WarningIcon /></div>}
      {isSuggestion ? (
        <p style={{ marginTop: 8 }}>
          <Message
            id="To suggest an edit, you need to save your previous changes."
            comment="Modal body explaining that you need to save changes to add a suggestion."
          />
        </p>
      ) : (
        <p style={{ marginTop: 8 }}>
          <Message
            id="To add a comment, you need to save your previous changes."
            comment="Modal body explaining that you need to save changes to add comments."
          />
        </p>
      )}
      {willResetSignatures && (
        <p>
          <strong>
            <Message
              id="All existing signatures will be reset after saving and the document will need to be signed again."
              comment="Modal body warning text."
            />
          </strong>
          {' '}
          <Message
            id="Save changes?"
            comment="Modal body warning text."
          />
        </p>
      )}
    </>
  );
};

export default Body;
