import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';

import { isContractPristine } from 'reducers/current-contract';

import SignButtonComponent from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons/sign/sign-button';
import { Layout } from 'components/document-layout-container/types';
import { EXPANDED, isContractEditable } from 'components/document-layout-container/helpers';
import Tooltip from 'components/tooltip';

import SignOnSameDevice from '../modals/sign-on-same-device';
import {
  SaveButton,
  DiscardButton,
} from './buttons';

type Props = {
  agreement: Oneflow.Document;
  layout: Layout;
};

const SignOnSameDeviceActions = ({ agreement, layout }: Props) => {
  const isPristine = useSelector(isContractPristine);
  const isEditable = isContractEditable(agreement);

  if (!isPristine && isEditable) {
    return (
      <>
        <DiscardButton />
        <SaveButton agreementId={agreement.id} />
        {layout === EXPANDED && (
          <Tooltip
            message={(
              <Message
                id="You need to save first"
                comment="Tool tip message for why the send button is disabled."
              />
        )}
            side="top"
          >
            <div>
              <SignOnSameDevice
                agreement={agreement}
              >
                {(onClick) => (
                  <SignButtonComponent
                    onClick={onClick}
                    layout={layout}
                    data-testid="sign-button-component-same-device"
                    disabled={!isPristine}
                  >
                    <Message
                      id="Start signing"
                      comment="Button label for sing on same device method"
                    />
                  </SignButtonComponent>
                )}
              </SignOnSameDevice>
            </div>
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <>
      <SignOnSameDevice
        agreement={agreement}
      >
        {(onClick) => (
          <SignButtonComponent
            onClick={onClick}
            layout={layout}
            data-testid="sign-button-component-same-device"
            disabled={!isPristine}
          >
            <Message
              id="Start signing"
              comment="Button label for sing on same device method"
            />
          </SignButtonComponent>
        )}
      </SignOnSameDevice>
    </>
  );
};

export default SignOnSameDeviceActions;
