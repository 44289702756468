import { ReactNode, useCallback, useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { isEqual } from 'lodash';
import { localize } from '@oneflowab/pomes';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import type { MessageTranslator } from '@oneflowab/pomes';

// eslint-disable-next-line import/named
import { composeValidators, maxLength } from 'forms/validators';
import { DEFAULT_ROUNDING_PRECISION } from 'utils/math';
import { PriceColumn, getEnabledPriceColumns } from 'reducers/helpers/current-contract';
import { isBoxDataRemoveAllowed } from 'agreement/box-data-remove-permissions';
import {
  getContractMetaData,
  getCurrentBoxes,
  updateBoxAction,
  updateBoxDataItemAction,
} from 'reducers/current-contract';
import type {
  ProductSumBox, ProductSumBoxData, Sums, Uuid,
} from 'data-validators/entity-schemas/document-box/product-sum-box';

import { AFFIX_MAX_LENGTH, COL_NAME_MAX_LENGTH } from 'components/contract-boxes/product-table-box/constants';
import {
  getId,
  removeContentDataItems,
} from 'components/contract-boxes/generic-box-helpers';
import { hasValidDiscount } from 'components/contract-boxes/product-sum-box/product-sum-box-helpers';
import AffixFields from 'components/contract-boxes/product-table-box/product-table/popup-forms/affix-fields';
import Button from 'components/button';
import CheckboxGroup from 'components/checkbox-group';
import DecimalSelector from 'components/contract-boxes/product-table-box/product-table/popup-forms/decimal-selector';
import DeleteIcon from 'components/icons/delete';
import Field from 'components/field';
import Message from 'components/message';
import TextField from 'components/text-field';

import style from './product-sum-edit.module.scss';

type Props = {
  summation: ProductSumBoxData,
  box: ProductSumBox,
  close: () => void,
  sums: Sums,
  message: MessageTranslator,
};

const ProductSumEdit = ({
  box,
  summation,
  close,
  sums,
  message,
}: Props) => {
  const dispatch = useDispatch();
  const summationId = getId(summation);
  const { value } = summation;
  const initSelectedColumns = summation.value.columnUuids?.map((item) => item.uuid || item) || [];
  const [selectedColumnUuids, setSelectedColumnUuids] = useState(initSelectedColumns);

  let initialDecimalValue;
  if (Number(value.sumPrecision) === 0) {
    initialDecimalValue = 0;
  } else {
    initialDecimalValue = value.sumPrecision || DEFAULT_ROUNDING_PRECISION;
  }
  const [decimalValue, setDecimalValue] = useState(initialDecimalValue);
  const { priceColumnsGroups } = useSelector(getContractMetaData);
  const boxes = useSelector(getCurrentBoxes);
  const enabledPriceColumns = getEnabledPriceColumns(priceColumnsGroups);

  const affixValidations = composeValidators(
    maxLength({
      message,
      field: 'prefix',
      customMessage: message({
        id: 'Maximum {maxLength} characters allowed.',
        comment: 'Validation message for price price affix validation.',
        values: {
          maxLength: AFFIX_MAX_LENGTH,
        },
      }),
      maxLength: AFFIX_MAX_LENGTH,
    }),
    maxLength({
      message,
      field: 'postfix',
      customMessage: message({
        id: 'Maximum {maxLength} characters allowed.',
        comment: 'Validation message for price price affix validation.',
        values: {
          maxLength: AFFIX_MAX_LENGTH,
        },
      }),
      maxLength: AFFIX_MAX_LENGTH,
    }),
  );

  const handleValueChange = (precision: number) => {
    setDecimalValue(Number(precision));
  };

  const onFormSubmit = (formProps: FormRenderProps) => {
    const priceColumnsPristine = isEqual(initSelectedColumns, selectedColumnUuids);
    const formPristine = Boolean(formProps.pristine
      && priceColumnsPristine)
      && decimalValue === value.sumPrecision;

    if (formPristine) {
      close();
      return;
    }

    const { values } = formProps;
    const updatedSum: ProductSumBoxData = {
      value: {
        ...summation.value,
        columnUuids: selectedColumnUuids.map((uuid) => ({ uuid })),
        name: values.name,
        postfix: values.postfix || '',
        prefix: values.prefix || '',
        sum: sums.sum,
        sumDiscounted: hasValidDiscount(sums.sum, sums.sumDiscounted) ? sums.sumDiscounted : null,
        sumPrecision: decimalValue,
      },
    };
    dispatch(updateBoxDataItemAction(getId(box), summationId, updatedSum));

    close();
  };

  const onSelectPriceColumns = (uuids: Uuid[]) => {
    setSelectedColumnUuids(uuids);
  };

  const removeSum = () => {
    const newData = removeContentDataItems(box, [summationId]);
    dispatch(updateBoxAction({
      ...box,
      content: {
        ...box.content,
        data: newData,
      },
    }));
  };

  const renderActionButtons = (formProps: FormRenderProps) => (
    <div className={style.Row}>
      <Button
        customClass={style.ButtonCancel}
        onClick={close}
      >
        <Message
          id="Cancel"
          comment="Action to cancel the editing product summation."
        />
      </Button>
      <Button
        customClass={style.ButtonSave}
        onClick={() => onFormSubmit(formProps)}
        disabled={!formProps.valid}
      >
        <Message
          id="Save"
          comment="Action to save the edited product summation."
        />
      </Button>
    </div>
  );

  const getCheckboxLabel = useCallback((values: PriceColumn['formatted'], key: PriceColumn['column']['key']) => {
    let priceLabel: null | string | ReactNode | undefined = values?.priceLabel;
    if (!priceLabel) {
      if (key === 'price_1') {
        priceLabel = message({
          id: 'Price 1',
          comment: 'Fallback label for missing price 1 label',
        });
      } else {
        priceLabel = message({
          id: 'Price 2',
          comment: 'Fallback label for missing price 2 label',
        });
      }
    }
    return priceLabel;
  }, [message]);

  const renderSortedColumns = () => {
    if (priceColumnsGroups.length) {
      return enabledPriceColumns.map((priceColumnsGroup) => {
        const showHeader = priceColumnsGroup.priceColumns.some(({ column }) => column.enabled);
        return (
          <div key={priceColumnsGroup.boxId} className={style.ColumnInfoWrapper}>
            {showHeader
              && (
                <div className={style.Title}>
                  <Message
                    id="Product table: {productTableName}"
                    comment="Text to show Product table name"
                    values={{
                      productTableName: boxes?.[priceColumnsGroup.boxId].config.header.label,
                    }}
                  />
                </div>
              )}
            <CheckboxGroup
              value={selectedColumnUuids}
              options={priceColumnsGroup.priceColumns.map(({ column, formatted }) => ({
                value: formatted.value,
                label: getCheckboxLabel(formatted, column.key),
              }))}
              onChange={onSelectPriceColumns}
              customClass={style.ProductSumCheckboxOption}
            />
          </div>
        );
      });
    }

    return (
      <Message
        id="To select columns at least one column must be enabled in a product table."
        comment="Text that shows when there are no price columns available"
      />
    );
  };

  return (
    <div className={style.ProductSumEdit}>
      <Form
        onSubmit={onFormSubmit}
        initialValues={{
          name: value.name,
          postfix: value.postfix,
          prefix: value.prefix,
        }}
        render={(formProps) => (
          <form
            onSubmit={formProps.handleSubmit}
            className={style.Form}
            autoComplete="off"
          >
            <div className={style.PopupHeader}>
              <Message
                id="Edit summation"
                comment="Header for the form where you can edit product summation details."
              />
            </div>
            <div className={style.NameContainer}>
              <Field
                name="name"
                label={(
                  <Message
                    id="Name"
                    comment="Label for the name field for the edited product summation."
                  />
                )}
                component={TextField}
                customClass={clsx(style.ProductSumInputContainer, {
                  [style.Error]: formProps.values.name?.length > COL_NAME_MAX_LENGTH,
                })}
                labelCustomClass={style.LabelProductSum}
                maxLength={COL_NAME_MAX_LENGTH}
              />
            </div>
            <AffixFields
              message={message}
              affixValidations={affixValidations}
            />
            <DecimalSelector
              type="productSummation"
              decimalValue={decimalValue}
              handleValueChange={handleValueChange}
            />
            <div className={style.ProductSumCheckboxOptionsContainer}>
              <div className={style.Title}>
                <Message
                  id="Select price columns"
                  comment="Label for selection of prices the user wants to sum up"
                />
              </div>
              <div>
                <Message
                  id="Choose the price columns to include in the total sum."
                  comment="Additional text explaining the prices selection"
                />
              </div>
              <div className={style.ProductSumCheckboxOptions}>
                {renderSortedColumns()}
              </div>
            </div>
            <div className={style.RemoveButtonContainer}>
              <Button
                customClass={style.ButtonRemove}
                disabled={!isBoxDataRemoveAllowed(box, summation)}
                icon={DeleteIcon}
                onClick={removeSum}
              >
                <Message
                  id="Remove summation"
                  comment="Action to remove summation."
                />
              </Button>
            </div>
            {renderActionButtons(formProps)}
          </form>
        )}
      />
    </div>
  );
};

export default localize<Props>(ProductSumEdit);
