// @flow

import * as React from 'react';
import clsx from 'clsx';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { Acl, checkAcl } from 'components/acl';
import AddressBook from 'components/icons/address-book';
import DashboardIcon from 'components/icons/new-dashboard';
import NewContractIcon from 'components/icons/new-contract';
import SidebarOption from 'components/sidebar/sidebar-option/sidebar-option';
import Template from 'components/icons/template';

import style from './sidebar-items-top.module.scss';

type Props = {
  acl: {
    account: Acl,
    workspace: Acl,
  },
  expanded: boolean,
  isMobile: boolean,
  isContractView: boolean,
  onItemClick: () => void,
  message: MessageTranslator,
  withCurrentWorkspaceNamespace: (path: string) => string,
  lastContractSearch?: string,
};

const SidebarItemsTop = ({
  acl,
  expanded,
  isMobile,
  isContractView,
  onItemClick,
  message,
  withCurrentWorkspaceNamespace,
  lastContractSearch,
}: Props) => {
  const getContractsListLink = () => {
    if (lastContractSearch) {
      return lastContractSearch;
    }

    return withCurrentWorkspaceNamespace('/contracts');
  };

  const sideMenuClasses = clsx(style.SideMenu, {
    [style.Mobile]: isMobile,
  });

  return (
    <div className={sideMenuClasses}>
      <SidebarOption
        data-testid="sidebar-dashboard"
        expanded={expanded}
        IconComponent={DashboardIcon}
        isContractView={isContractView}
        isMobile={isMobile}
        isDisabled={!checkAcl(acl.workspace, 'collection:module:dashboard:use')}
        link={withCurrentWorkspaceNamespace('/dashboard')}
        tooltipText={message({ id: 'Dashboard', comment: 'Used as a tooltip for the sidebar item' })}
        trackable={{
          name: 'Go To Dashboard',
          props: {
            location: 'Main navigation',
          },
        }}
        onItemClick={onItemClick}
      />
      <SidebarOption
        data-testid="sidebar-contracts"
        IconComponent={NewContractIcon}
        expanded={expanded}
        isContractView={isContractView}
        isMobile={isMobile}
        isDisabled={!checkAcl(acl.workspace, 'collection:module:agreement:use')}
        link={getContractsListLink()}
        tooltipText={message({ id: 'Documents', comment: 'Used as a tooltip for the sidebar item' })}
        trackable={{
          name: 'Go To Contracts',
          props: {
            location: 'Main navigation',
          },
        }}
        onItemClick={onItemClick}
      />
      <SidebarOption
        data-testid="sidebar-templates"
        expanded={expanded}
        IconComponent={Template}
        isContractView={isContractView}
        isMobile={isMobile}
        isDisabled={!checkAcl(acl.workspace, 'collection:module:template:use')}
        link={withCurrentWorkspaceNamespace('/templates')}
        tooltipText={message({ id: 'Templates', comment: 'Used as a tooltip for the sidebar item' })}
        trackable={{
          name: 'Go To Templates',
          props: {
            location: 'Main navigation',
          },
        }}
        onItemClick={onItemClick}
      />
      <SidebarOption
        data-testid="sidebar-addressbook"
        expanded={expanded}
        IconComponent={AddressBook}
        isContractView={isContractView}
        isMobile={isMobile}
        isDisabled={!checkAcl(acl.workspace, 'collection:addressbook:view')}
        link={withCurrentWorkspaceNamespace('/address-book')}
        tooltipText={message({
          comment: 'Used as a tooltip for the sidebar item',
          id: 'Address book',
        })}
        trackable={{
          name: 'Go To Address Book',
          props: {
            location: 'Main navigation',
          },
        }}
        onItemClick={onItemClick}
      />
    </div>
  );
};

export default localize<Props>(SidebarItemsTop);
