import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';

import {
  getDataFieldExternalKeyMap,
  getDataFieldExternalKeyValueMap,
} from 'reducers/current-contract';
import { getEmptyRequiredElementsCount } from 'reducers/helpers/current-contract';
import { getGuestToken } from 'agreement/selectors';
import useCurrentBoxes from 'hooks/use-current-boxes';
import useCurrentData from 'hooks/use-current-data';

import {
  EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
  focusNextEmptyRequiredElement,
} from 'components/document-call-to-actions/actions/buttons-call-to-action/buttons/sign/helpers';
import toast, { Button } from 'components/toasts';

const NextEmptyInputButton = () => {
  const boxMap = useCurrentBoxes();
  const data = useCurrentData();
  const dataFieldExternalKeyMap = useSelector(getDataFieldExternalKeyMap);
  const dataFieldExternalKeyValueMap = useSelector(getDataFieldExternalKeyValueMap);
  const guestToken = useSelector(getGuestToken);
  const isGuest = Boolean(guestToken);

  const handleClick = () => {
    const { editableEmptyRequiredElementsCount } = getEmptyRequiredElementsCount({
      isGuest,
      boxMap,
      data,
      dataFieldExternalKeyMap,
      dataFieldExternalKeyValueMap,
    });

    if (editableEmptyRequiredElementsCount === 0) {
      toast.remove({
        toastId: EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
      });
      return;
    }

    toast.update({
      id: EMPTY_EDITABLE_REQUIRED_FIELDS_TOAST_ID,
      description: (
        <Message
          id="Fill in {count} required fields to proceed"
          comment="Notification message for missing required fields"
          values={{ count: editableEmptyRequiredElementsCount }}
        />
      ),
    });

    focusNextEmptyRequiredElement();
  };

  return (
    <Button onClick={handleClick}>
      <Message id="Next" comment="Button text to show the next missing required field" />
    </Button>
  );
};

export default NextEmptyInputButton;
