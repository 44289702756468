// @flow

import * as React from 'react';
import { Redirect } from 'react-router';
import urlJoin from 'url-join';
import type { Match } from 'react-router';
import { Message } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';
import { checkAcl } from 'components/acl';
import { NotFound } from 'components/errors';

import All from 'components/all-contracts';
import DeleteIcon from 'components/icons/delete';
import Lifecycle from '../lifecycle';
import AiInsights from '../ai-insights';
import Trash from '../trash';

export type Props = AdminPageComponentProps & {
  acl: {
    workspace: Acl,
  },
  match: Match,
  workspace: Workspace,
  dateFormat: string,
};

export const ContractsComponent = (props: Props) => {
  if (checkAcl(props.acl.workspace, 'collection:module:agreement:use')) {
    return (
      <Redirect
        from={props.match.path}
        to={urlJoin(props.match.url, '/all')}
      />
    );
  }

  return <NotFound redirect to="/templates" />;
};

const getContractsModules = ({
  acl,
  currentUser,
  dateFormat,
  message,
  workspace,
  isTrashEnabled,
}: any) => {
  let contractsModules = [];

  if (checkAcl(acl.workspace, 'collection:module:agreement:use')) {
    contractsModules = [
      ...contractsModules,
      {
        component: All,
        currentUser,
        dateFormat,
        hideBreadCrumb: true,
        isMainPage: true,
        id: 'all-tab',
        path: '/all',
        section: message({ id: 'Documents', comment: 'Used as the title for the section.' }),
        showWorkspace: true,
        title: message({ id: 'Documents', comment: 'Tab title. Showing all contracts in the contract list.' }),
        workspace,
        event: {
          event: 'Go To Contracts',
          props: {
            location: 'documents view',
          },
        },
      },
      {
        component: Lifecycle,
        hideBreadCrumb: true,
        isMainPage: true,
        path: '/calendar',
        section: message({ id: 'Documents', comment: 'Used as the title for the section.' }),
        showWorkspace: true,
        title: message({ id: 'Calendar', comment: 'Page title' }),
        workspace,
        event: {
          event: 'Go To Calendar',
          props: {
            location: 'documents view',
          },
        },
      },
    ];
  }

  if (checkAcl(workspace.acl, 'collection:ai_review:view')) {
    contractsModules = [
      ...contractsModules,
      {
        component: AiInsights,
        hideBreadCrumb: true,
        isMainPage: true,
        path: '/insights',
        section: message({ id: 'Documents', comment: 'Used as the title for the section.' }),
        showWorkspace: true,
        title: 'AI Insights',
        workspace,
        isBeta: true,
      },
    ];
  }

  if (checkAcl(workspace.acl, 'collection:agreement:trash:view') && isTrashEnabled) {
    contractsModules = [
      ...contractsModules,
      {
        component: Trash,
        hideBreadCrumb: true,
        isMainPage: true,
        path: '/trash',
        section: message({ id: 'Documents', comment: 'Used as the title for the section.' }),
        showWorkspace: true,
        title: (
          <div style={{ display: 'flex', gap: '4px' }}>
            <DeleteIcon width="14px" />
            <Message id="Trash" comment="Tab title. Showing all contracts in the contract list." />
          </div>
        ),
        workspace,
      },
    ];
  }

  return contractsModules;
};

export const contractsModule = ({
  props: {
    acl,
    currentUser,
    dateFormat,
    message,
    workspace,
    isTrashEnabled,
  },
}: any) => ({
  title: message({
    id: 'Documents',
    comment: 'The title of the page. Contract search module.',
  }),
  showAsLink: false,
  modules: [getContractsModules({
    acl,
    currentUser,
    dateFormat,
    message,
    workspace,
    isTrashEnabled,
  })],
});

export default adminPage(contractsModule)(ContractsComponent);
