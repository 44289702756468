// @flow

import React from 'react';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import { MfaTooltipMessage } from 'components/mfa-tooltip-message';
import Tooltip from 'components/tooltip';

type Props = {
  position: Position,
  message: MessageTranslator,
}

export const UserSecurityStatus = ({ position, message }: Props) => {
  if (!position.mfaChannel) {
    return (
      <Message
        id="Off"
        comment="Text for indicating that two-step authentication is not enabled"
      />
    );
  }

  return (
    <Tooltip
      side="right"
      message={<MfaTooltipMessage position={position} message={message} />}
    >
      <div data-align-with-tooltip>
        <Message id="On" comment="Text for indicating that two-step authentication is enabled" />
      </div>
    </Tooltip>
  );
};

export default localize<Props>(UserSecurityStatus);
