import React from 'react';

type ConditionalProps = {
  children: React.ReactNode;
  ifCondition: boolean | (() => boolean);
  elseCondition?: React.ReactNode;
};

const Conditional = ({
  children = null,
  ifCondition = false,
  elseCondition = null,
}: ConditionalProps) => {
  const shouldRender = () => {
    if (typeof ifCondition === 'function') {
      return ifCondition();
    }
    return ifCondition;
  };

  if (shouldRender()) {
    return children;
  }

  return elseCondition;
};

export default Conditional;
