import type { ComponentType } from 'react';
import ClockFuture from 'components/icons/clock-future';
import GrantUserAccessIcon from 'components/icons/grant-user-access';
import NewCheck from 'components/icons/new-check';
import ViewCountEye from 'components/icons/view-count-eye';

/**
 * Approval Flow Badges
*/
export const BADGE_APPROVAL_REQUIRED = 'approval-required';
export const BADGE_SIGN_LATER_ENABLED = 'sign-later-enabled';
export const BADGE_READY_TO_SIGN = 'ready-to-sign';
export const BADGE_SIGNING_ORDER_ENABLED = 'signing-order-enabled';
export const BADGE_SINGLE_SIGNATURE = 'single-signature';
export const BADGE_SIGN_ON_SAME_DEVICE = 'sign-on-same-device';

interface BadgeIconsMap {
  [type: string]: ComponentType<Svg.IconProps>
}

const badgeIcons: BadgeIconsMap = {
  [BADGE_APPROVAL_REQUIRED]: ViewCountEye,
  [BADGE_SIGN_LATER_ENABLED]: ClockFuture,
  [BADGE_READY_TO_SIGN]: NewCheck,
  [BADGE_SIGNING_ORDER_ENABLED]: NewCheck,
  [BADGE_SINGLE_SIGNATURE]: GrantUserAccessIcon,
  [BADGE_SIGN_ON_SAME_DEVICE]: NewCheck,
};

export default badgeIcons;
