// @flow

import * as React from 'react';
import { localize, type MessageTranslator, Message } from '@oneflowab/pomes';
import clsx from 'clsx';
import { render } from 'react-dom';

import { WithCurrentWorkspaceLink } from 'hocs/with-current-workspace';
import { checkAcl } from 'components/acl';
import EmptyState from 'components/empty-state';
import CircularSpinner from 'components/icons/circular-spinner';
import Template from 'components/icons/template';
import CreateContract from 'components/icons/create-contract';
import NoTemplatesIcon from 'components/icons/no-templates';
import Button from 'components/button';
import TemplateSharingIndicator from 'components/template-sharing-indicator';
import { TemplateListLinkWithCurrentWorkspace } from 'components/template-list-link';
import { BlankDocumentButton } from 'components/buttons/blank-document';
import { PdfDropzoneButton } from 'components/buttons/pdf-dropzone';
import TooltipInfo from 'components/tooltip-info';
import { ErrorCode } from 'react-dropzone';

import { FILE_APPLICATION_PDF } from 'utils/file.js';
import { MAX_ATTACHMENT_SIZE } from 'components/contract-boxes/constants';
import FileUploadFailureAlert, { UPLOAD_FAILURE_ALERT_ID } from 'components/file-upload/upload-error-alert';
import style from './template-results.module.scss';

export const UPLOAD_FAILURE_ALERT_ZONE_ID = 'upload-failure-alert-zone';

type Props = {
  templates: Array<AgreementTemplate>,
  message: MessageTranslator,
  workspace: Workspace,
  createState: CreateState,
  createContract: (?Agreement, number, ?number) => void,
  isDisabled: boolean,
  isInImportContractModal?: boolean,
  isSearching: boolean,
  closeOnRedirect?: () => void,
  isContractView?: boolean,
  folderId?: number,
  selectedUniqueKey: any,
  isImportModal?: boolean,
};

export class TemplateResultsComponent extends React.Component<Props, State> {
  static defaultProps = {
    isInImportContractModal: undefined,
    closeOnRedirect: undefined,
    isContractView: undefined,
    isImportModal: undefined,
  };

  handleTemplateClick = (agreement: Agreement) => (event: Function) => {
    const {
      createContract,
      workspace,
      selectedUniqueKey,
      folderId,
    } = this.props;

    const folderToImportTo = selectedUniqueKey || folderId;

    event.preventDefault();
    event.stopPropagation();
    createContract(agreement, workspace.id, folderToImportTo);
  };

  getCreateBlankButton = () => {
    const { createContract, workspace, folderId } = this.props;
    const handleClick = () => createContract(undefined, workspace.id, folderId);

    return (
      <Button
        kind="linkInline"
        icon={CreateContract}
        onClick={handleClick}
        customClass={style.Button}
      >
        <Message
          id="Create blank"
          comment="Buttons that lets users create new, blank contracts."
        />
      </Button>
    );
  };

  renderNoTemplates(): React.Node {
    const {
      message,
      isSearching,
      isInImportContractModal,
      workspace,
      folderId,
    } = this.props;

    if (isInImportContractModal) {
      return (
        <>
          <Message
            id="Create a template with an import tag and publish it."
            comment="Empty state text in import modal when no templates are available."
          />
          <WithCurrentWorkspaceLink>
            {(namespaceProps) => (
              <Button
                href={namespaceProps.withCurrentWorkspaceNamespace('/templates')}
                kind="linkInline"
                customClass={style.TemplateListLink}
              >
                <Message
                  id="Go to template list"
                  comment="CTA to go to the template list."
                />
              </Button>
            )}
          </WithCurrentWorkspaceLink>
        </>
      );
    }

    if (isSearching) {
      return (
        <EmptyState
          icon={<NoTemplatesIcon height="72px" />}
          header={message({
            id: 'No templates found',
            comment: 'Empty state header. Used in template launcher or import modal when no templates are available.',
          })}
          content={(
            this.renderFullTemplateListMessage()
          )}
          className={style.EmptySearchState}
          defaultStyle
        />
      );
    }

    if (checkAcl(workspace.acl, 'collection:agreement:create_blank')) {
      return (
        <EmptyState
          content={(
            <div className={style.EmptyStateContent}>
              <div className={style.Header}>
                <Message
                  id="Create your custom document from scratch or upload a PDF"
                  comment="Empty state header in template launcher when no templates are available."
                />
              </div>
              <div className={style.CreateDocumentButtons}>
                <BlankDocumentButton folderId={folderId} fromTemplateResults />
                <PdfDropzoneButton
                  className={style.Dropzone}
                  onLoaded={
                  (uploadingError) => {
                    const buttonsDiv = document.getElementById(UPLOAD_FAILURE_ALERT_ZONE_ID);
                    if (buttonsDiv && uploadingError) {
                      render(this.renderError(
                        uploadingError.errorCode,
                        uploadingError.fileName,
                        uploadingError.fileSize,
                      ), buttonsDiv);
                    } else {
                      render(this.removeErrorAlert(), buttonsDiv);
                    }
                  }
                }
                />
              </div>
              <div>
                <div id={UPLOAD_FAILURE_ALERT_ZONE_ID} />
              </div>
              {this.renderFullTemplateListMessage()}
            </div>
          )}
          className={style.EmptyState}
          defaultStyle
        />
      );
    }

    return (
      <EmptyState
        icon={<NoTemplatesIcon height="72px" />}
        header={message({
          id: 'No templates',
          comment: 'Empty state header. Used in template launcher when no templates are available.',
        })}
        content={(
          <div className={style.EmptyStateContent}>
            <Message
              id="There are no templates available in this workspace."
              comment="Empty state text in template launcher when no templates are available."
            />
            {this.renderFullTemplateListMessage()}
          </div>
        )}
        className={style.EmptyState}
        defaultStyle
      />
    );
  }

  renderCreateSpinner(templateId: number) {
    const { createState } = this.props;

    if (!createState.loading || templateId !== createState.data.sourceId) {
      return null;
    }

    return (
      <CircularSpinner className={style.CreateSpinner} />
    );
  }

  renderDisabledTemplate = (name: string, id: string) => (
    <div className={style.Container} key={`template_${id}`}>
      <Button
        kind="linkSeparate"
        customClass={style.TemplateLink}
        icon={Template}
        disabled
      >
        {name || (
        <Message
          id="Untitled"
          comment="Shown in template list for templates without a name."
        />
        )}
      </Button>
      <TooltipInfo
        message={(
          <Message
            id="This template has approvers or signing order, which is not compatible with import."
            comment="Tooltip message shown in template list for templates that are disabled."
          />
        )}
        messageClassName={style.TooltipMessage}
        side="top"
        theme="oneflow"
        zIndex="10002"
        disabled
      />
    </div>
  );

  renderTemplate = ({ agreement }: AgreementTemplate) => {
    const { name, id, acl } = agreement;
    const { isDisabled, isImportModal } = this.props;

    if (isImportModal && !checkAcl(acl, 'agreement:source:import')) {
      return this.renderDisabledTemplate(name, id);
    }

    return (
      <div className={style.Container} key={`template_${id}`}>
        <Button
          kind="linkSeparate"
          customClass={style.TemplateLink}
          onClick={this.handleTemplateClick(agreement)}
          icon={Template}
          disabled={isDisabled}
        >
          {name || (
            <Message
              id="Untitled"
              comment="Shown in template list for templates without a name."
            />
          )}
        </Button>
        <TemplateSharingIndicator
          agreement={agreement}
          height="14px"
          isTemplateAccessible
        />
        {this.renderCreateSpinner(id)}
      </div>
    );
  };

  renderTemplateListLink = () => {
    const { workspace, closeOnRedirect, isContractView } = this.props;

    if (!checkAcl(workspace.acl, 'collection:module:template:use')) {
      return null;
    }

    return (
      <TemplateListLinkWithCurrentWorkspace
        closeOnRedirect={closeOnRedirect}
        isContractView={isContractView}
      />
    );
  }

  renderFullTemplateListMessage() {
    const { workspace, isInImportContractModal } = this.props;

    if (!checkAcl(workspace.acl, 'collection:module:template:use')) {
      return null;
    }

    if (isInImportContractModal) {
      return (
        <Message
          id="Go to {templateList} and activate a template by adding the import tag."
          values={{
            templateList: this.renderTemplateListLink(),
          }}
          comment="Empty state text in import modal when no templates are available."
        />
      );
    }

    return (
      <div className={style.Message}>
        <Message
          id="To reuse a document, create a template in the {templateList}, then activate your templates to show them here."
          values={{
            templateList: this.renderTemplateListLink(),
          }}
          comment="Empty state text in modal when no templates are available."
        />
      </div>
    );
  }

  renderError = (errorCode: ErrorCode, fileName: string, fileSize: number) => {
    if (!errorCode) {
      return null;
    }
    return React.createElement(FileUploadFailureAlert, {
      errorCode,
      maxFileSize: MAX_ATTACHMENT_SIZE,
      acceptedFileTypes: FILE_APPLICATION_PDF.extensions,
      attachmentsCountLimit: 1,
      fileName,
      fileSize,
    });
  }

  removeErrorAlert = () => {
    document.getElementById(UPLOAD_FAILURE_ALERT_ID)?.remove();
    return null;
  }

  render() {
    const { templates, isInImportContractModal } = this.props;

    const templateItemsClassName = clsx(style.TemplateItems, {
      [style.Imported]: isInImportContractModal,
    });

    if (!templates.length) {
      return this.renderNoTemplates();
    }

    return (
      <div className={style.TemplateList}>
        {!isInImportContractModal && (
          <p className={style.Heading}>
            <Message
              id="Use a template"
              comment="Helper text to guide user to use a template."
            />
          </p>
        )}
        <div className={templateItemsClassName}>
          {templates.map(this.renderTemplate)}
        </div>
      </div>
    );
  }
}

export default localize<Props>(TemplateResultsComponent);
