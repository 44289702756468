/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getFoldersByParentId, getRedirectPath } from 'workspace/folders';
import { getLocationSelector } from 'reducers/router';
import foldersReducer from 'reducers/entities/folders';

import BreadCrumb from 'components/bread-crumb';

import style from './folder-bread-crumb.module.scss';

const FOLDERS_QUERY = 'folders';

export const getCurrentWorkspaceName = (currentWorkspace: Workspace) => {
  if (!currentWorkspace) {
    return '';
  }

  return currentWorkspace.name;
};

export const mapToLink = (breadCrumb: BreadCrumb) => ({
  id: breadCrumb.id,
  label: breadCrumb.name,
  to: '',
});

type Props = {
  selectedUniqueKey?: any,
}

export const FolderBreadCrumb = ({
  selectedUniqueKey,
}: Props) => {
  const currentWorkspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  const foldersQuery = useSelector((state) => (
    foldersReducer.getQuerySelector(state, { name: FOLDERS_QUERY })
  ));
  const folders = useSelector((state) => (
    foldersReducer.getFoldersSelector(state, { ids: foldersQuery.result })
  ));
  const foldersArr = Object.values(folders);
  const selectedId = selectedUniqueKey === -1 ? null : selectedUniqueKey;
  const displayedFolders = getFoldersByParentId(
    foldersArr, selectedId, false,
  ).sort((a, b) => a.name.localeCompare(b.name));

  const dispatch = useDispatch();
  const location = useSelector((state) => getLocationSelector(state));

  const renderBreadCrumb = () => {
    const getFolderById = (folderId) => foldersArr.find((item) => item.id === folderId);
    const folder = getFolderById(selectedUniqueKey);

    if (!folder) {
      return null;
    }

    const breadCrumbs = [{
      id: -1,
      name: getCurrentWorkspaceName(currentWorkspace),
      to: '',
    }];

    const parentPath = folder.parentPath.split('/').slice(0, -1).map((path) => Number(path));

    parentPath.forEach((parentFolderId) => {
      breadCrumbs.push(getFolderById(parentFolderId));
    });

    breadCrumbs.push(folder);

    return (
      <BreadCrumb
        isFolderBreadCrumb
        links={breadCrumbs.map(mapToLink)}
        onClickHandler={(id) => {
          if (id) {
            dispatch(push(getRedirectPath(location, [id])));
          }
        }}
      />
    );
  };

  if (
    isEmpty(displayedFolders)
    && selectedUniqueKey === -1
  ) {
    return null;
  }

  const breadCrumb = renderBreadCrumb();

  if (!breadCrumb) {
    return null;
  }

  return (
    <div className={style.Header}>
      <ul className={style.BreadCrumb}>
        {breadCrumb}
      </ul>
    </div>
  );
};
