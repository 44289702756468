import { cloneDeep, uniqueId } from 'lodash';
import { PDFBox } from 'data-validators/entity-schemas/document-box/pdf-box';
import { SIGNATURE_FIELD } from 'components/contract-boxes/constants';

const generateSignatureField = (
  box: PDFBox,
  size: OverlayField.Size,
  page: number,
  position: OverlayField.Position,
) => {
  const newDataItem = {
    key: SIGNATURE_FIELD,
    value: {
      fillOpacity: 0,
      participant: null,
      page,
      position,
      size,
    },
    _id: Number(uniqueId()),
  };

  const updatedBox = cloneDeep(box);

  updatedBox.content = {
    ...updatedBox.content,
    data: [
      ...updatedBox.content.data,
      newDataItem,
    ],
  };

  return {
    updatedBox,
    newDataItem,
  };
};

export { generateSignatureField };
