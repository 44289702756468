// @flow

import * as React from 'react';
import moment from 'moment';

import { getStartOfToday, getEndOfToday } from 'date';

import { DatePresetsContext } from 'hocs/with-date-presets';
import type {
  DatePresetsContextType,
  DateRange,
  OnDatesChange,
} from 'hocs/with-date-presets';

import {
  getNextMessage,
  getLastMessage,
  getPreviousMessage,
} from './date-preset-messages';
import Preset from './preset';

export type PresetType = 'day' | 'week' | 'month' | 'year';

export type Props = {
  next?: number,
  previous?: number,
  last?: number,
  type: PresetType,
};

class DatePreset extends React.PureComponent<Props> {
  static defaultProps = {
    next: undefined,
    previous: undefined,
    last: undefined,
  };

  getDateRange = (): DateRange => {
    const {
      next,
      type,
      last,
      previous,
    } = this.props;

    if (next) {
      return {
        startDate: getStartOfToday(),
        endDate: moment()
          .add(next, type)
          .subtract(1, 'day')
          .endOf('day'),
      };
    }

    if (last) {
      return {
        startDate: moment()
          .subtract(last, type)
          .add(1, 'day')
          .startOf('day'),
        endDate: getEndOfToday(),
      };
    }

    if (previous) {
      return {
        startDate: moment()
          .subtract(previous, type)
          .startOf(type),
        endDate: moment()
          .subtract(1, type)
          .endOf(type),
      };
    }

    return {};
  };

  getDateRangeMessage() {
    const {
      next,
      type,
      last,
      previous,
    } = this.props;

    if (next) {
      return getNextMessage(next, type);
    }

    if (last) {
      return getLastMessage(last, type);
    }

    if (previous) {
      return getPreviousMessage(previous, type);
    }

    return null;
  }

  getPresetOnClick = (onDatesChange: OnDatesChange) => (
    () => {
      onDatesChange(this.getDateRange());
    }
  );

  render() {
    const {
      next,
      type,
      last,
      previous,
      ...buttonProps
    } = this.props;

    const dateRangeMessage = this.getDateRangeMessage();

    return (
      <DatePresetsContext.Consumer>
        {({ onDatesChange }: DatePresetsContextType) => (
          <Preset
            {...buttonProps}
            onClick={this.getPresetOnClick(onDatesChange)}
          >
            {dateRangeMessage}
          </Preset>
        )}
      </DatePresetsContext.Consumer>
    );
  }
}

export default DatePreset;
