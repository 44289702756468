import { useState } from 'react';
import { Message } from '@oneflowab/pomes';

import FiFtnLogo from '../logos/fi_ftn';
import DkMitIdLogo from '../logos/dk_mitid';
import NoBankIdLogo from '../logos/no_bankid';
import SeBankIdLogo from '../logos/se_bankid';
import BeItsmeLogo from '../logos/be_itsme';
import FrejaLogo from '../logos/freja';
import NlIdinLogo from '../logos/nl_idin';
import SmartIdLogo from '../logos/smartid';
import EeEstEidLogo from '../logos/ee_esteid';
import { EeEsteIdMobile } from '../logos/ee_esteid_mobile';
import { BeBeId } from '../logos/be_beid';
import ZealIdLogo from '../logos/zealid';
import EvrotrustLogo from '../logos/evrotrust';
import { DTrustSignMe } from '../logos/d_trust_sign_me';
import { SimplySign } from '../logos/simply_sign';

import style from './sign-methods-select.module.scss';

type SignMethod = {
  countries: string[],
  name: string,
};

const getSignMethodAssets = (name: string) => {
  switch (name) {
    case 'bankid':
      return { label: 'Swedish BankID', svg: SeBankIdLogo };
    case 'ftn':
      return { label: 'Finnish Trust Network', svg: FiFtnLogo };
    case 'mitid':
      return { label: 'MitID', svg: DkMitIdLogo };
    case 'no_bankid':
      return { label: 'Norwegian BankID', svg: NoBankIdLogo };
    case 'beid':
    case 'beid_qes':
      return { label: '.beID', svg: BeBeId };
    case 'itsme':
    case 'itsme_qes':
      return { label: 'itsme', svg: BeItsmeLogo };
    case 'smartid':
    case 'smartid_qes':
      return { label: 'Smart-ID', svg: SmartIdLogo };
    case 'estid':
    case 'estid_qes':
      return { label: 'Estonian ID (EstID)', svg: EeEstEidLogo };
    case 'estid_mobile':
    case 'estid_mobile_qes':
      return { label: 'Estonian Mobile ID', svg: EeEsteIdMobile };
    case 'freja':
      return { label: 'Freja eID', svg: FrejaLogo };
    case 'idin':
      return { label: 'iDIN', svg: NlIdinLogo };
    case 'zealid':
      return { label: 'ZealID', svg: ZealIdLogo };
    case 'evrotrust':
      return { label: 'Evrotrust', svg: EvrotrustLogo };
    case 'd_trust_sign_me_qes':
      return { label: 'sign-me', svg: DTrustSignMe };
    case 'simply_sign':
    case 'simply_sign_qes':
      return { label: 'SimplySign', svg: SimplySign };
    default:
      return { label: name, svg: () => <></> };
  }
};

const SignMethodsSelector = ({
  methods,
  updateSelectedMethod,
}: {
  methods: SignMethod[],
  updateSelectedMethod: (arg: string) => void,
}) => {
  const sortedMethods = [...methods].sort((a, b) => {
    const labelA = getSignMethodAssets(a.name).label;
    const labelB = getSignMethodAssets(b.name).label;
    return labelA.localeCompare(labelB);
  });
  const [checkedValue, setCheckedValue] = useState<string>('');
  const onMethodClickHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedValue(event.target.value);
    updateSelectedMethod(event.target.value);
  };
  return (
    <>
      {sortedMethods.length === 0 && (
      <p>
        <Message
          id="No methods available"
          comment="Message when no signing methods are available"
        />
      </p>
      )}
      {sortedMethods.map((method) => {
        const { svg: LogoComponent, label } = getSignMethodAssets(method.name);
        return (
          <label
            key={method.name}
            htmlFor={`signMethod-${method.name}`}
          >
            <div
              className={style.SignMethodButton}
            >
              <div className={style.SignMethodAssetsContainer}>
                <LogoComponent className={style.Logo} />
                {label}
              </div>
              <input
                type="radio"
                value={method.name}
                name="signMethod"
                id={`signMethod-${method.name}`}
                checked={checkedValue === method.name}
                onChange={onMethodClickHandler}
                className={style.RadioButton}
              />
            </div>
          </label>
        );
      })}
    </>
  );
};
export default SignMethodsSelector;
