import {
  isEmpty,
  values,
  flatten,
  differenceWith,
  isEqual,
  omitBy,
} from 'lodash';
import { MessageTranslator } from '@oneflowab/pomes';
import { Element as SlateElement } from 'slate';

import { IS_INDIVIDUAL } from 'agreement/party/constants';
import { BOX_TEXT_AND_IMAGE } from 'agreement/constants';
import { camelizeKeys } from 'utils/camelizer';
import type { Box } from 'data-validators/entity-schemas/document-box';
import type { DataField } from 'data-validators/entity-schemas/agreement-data';
import type { Data } from 'reducers/current-contract';

// eslint-disable-next-line import/named
import { getErrorMessage, unknownApiError } from 'components/api-error';
import { unwrapSuggestions } from 'components/contract-text-editor/editor-plugins/annotation-plugin';

import { getDiff } from './get-diff';

export const INVALID_FIELDS_TOAST_ID = 'invalid-fields-toast';

type APIError = {
  body: {
    error: string;
    // eslint-disable-next-line camelcase
    field_errors: Record<string, string>;
    // eslint-disable-next-line camelcase
    status_code: number;
  }
  message?: string;
  response: Response;
};

export const getDefaultNotificationData = (
  party: Oneflow.Party | null,
  myParticipant: Oneflow.Participant | null,
  message: MessageTranslator,
) => {
  const isIndividual = party?.individual === IS_INDIVIDUAL;

  const user = isEmpty(myParticipant) ? '' : myParticipant?.fullname;
  const company = isEmpty(party) ? '' : party?.name;

  const companyPartyMessage = message({
    id: '{user} at {company} made an update.',
    comment: 'will be used as the body of notification email',
    values: {
      user,
      company,
    },
  });

  const individualPartyMessage = message({
    id: '{user} made an update.',
    comment: 'will be used as the body of notification email',
    values: {
      user,
    },
  });

  return {
    subject: message({
      id: 'New updates - {company}',
      comment: 'will be used as a subject for the notification email',
      values: {
        company,
      },
    }),
    message: isIndividual ? individualPartyMessage : companyPartyMessage,
  };
};

const isContentLimitExceededError = (code: number, errors: any) => (
  code === 400 && flatten(values(errors.boxes)).includes('invalid length')
);

const getApiErrorMessage = (errorBody?: APIError['body']) => {
  if (!errorBody) {
    return '';
  }

  const body = camelizeKeys(errorBody);
  const { apiErrorCode = '', statusCode = '', fieldErrors = {} } = body;

  // Temporary solution until we fix the lack of
  // api_error_code in verification schema errors
  if (isContentLimitExceededError(statusCode, fieldErrors)) {
    return getErrorMessage('CONTENT_LIMIT_EXCEEDED');
  }

  return getErrorMessage(apiErrorCode) || unknownApiError;
};

export const getError = (error?: APIError | TypeError) => {
  if (isEmpty(error)) {
    return '';
  }

  // One of the scenarios when error is TypeError is when user is offline
  if (error instanceof TypeError) {
    // returning empty string to avoid changing the existing behavior
    // TODO: determine what should be returned here (OF-9195)
    return '';
  }

  return getApiErrorMessage(error.body);
};

const OVERLAY_TEXT_FIELD = '[name="overlay-text-field"]';
const INVALID_FIELD_SELECTOR = '[aria-invalid="true"]';
const ACTIVE_ELEMENT_ATTRIBUTE = 'data-active-invalid-element';

export const focusNextInvalidElement = () => {
  const elements = Array.from(document.querySelectorAll<HTMLElement>(`${OVERLAY_TEXT_FIELD}${INVALID_FIELD_SELECTOR}`));

  if (elements.length === 0) {
    return;
  }

  // We keep track of the active element by the `data-active-element` attribute
  const activeElementIndex = elements.findIndex((element) => element.getAttribute(ACTIVE_ELEMENT_ATTRIBUTE) === 'true');
  let nextIndex = activeElementIndex + 1;

  // If the current active element is the last element, we need to go back to the first element.
  if (nextIndex === elements.length) {
    nextIndex = 0;
  }

  const nextElement = elements[nextIndex];
  // Move the active element attribute to the next element
  if (activeElementIndex > -1) {
    elements[activeElementIndex].removeAttribute(ACTIVE_ELEMENT_ATTRIBUTE);
  }
  nextElement.setAttribute(ACTIVE_ELEMENT_ATTRIBUTE, 'true');
  // To make sure that the element is in middle of the screen, we need this! Don't remove it!
  nextElement.scrollIntoView({ block: 'center' });
  nextElement.focus();
};

type PristineState = {
  boxOrder: boolean;
  dataFieldsMap: Record<number, boolean>;
  boxesMap: Record<number, boolean>;
};

type Suggestion = {
  id: number,
  nodes: SlateElement[],
};

export const hasValidContractChanges = (
  rejectedSuggestions: Suggestion[],
  acceptedSuggestions: Suggestion[],
  pristineState: PristineState,
  boxes: Record<string, Box>,
  pristineContract: Oneflow.Agreement,
) => {
  if (isEmpty(rejectedSuggestions) || !isEmpty(acceptedSuggestions)) {
    return true;
  }
  if (!pristineState.boxOrder || !pristineState.data) {
    return true;
  }

  const notPristineBoxIds = Object.entries(pristineState.boxesMap)
    .filter(([, isPristine]) => !isPristine)
    .map(([boxId]) => Number(boxId));

  const isNotTextBox = (boxId: number) => boxes[boxId].type !== BOX_TEXT_AND_IMAGE;
  if (notPristineBoxIds.some(isNotTextBox)) {
    return true;
  }

  const unwrapRejectedSuggestions = (
    rejectedSuggestionIds: number[],
    pristineNode: SlateElement,
  ): SlateElement[] => {
    const unwrappedPristineNode = unwrapSuggestions([pristineNode], rejectedSuggestionIds);
    return unwrappedPristineNode;
  };

  const hasTextBoxChanges = notPristineBoxIds.some((boxId) => {
    const pristineBox = pristineContract.boxes && pristineContract.boxes[boxId];
    const currentBox = boxes[boxId] as ContractView.TextBox;

    if (!isEqual(pristineBox?.config, currentBox.config)) {
      return true;
    }

    const pristineNodes = pristineBox?.content?.data?.[0]?.value?.nodes || [];
    const currentNodes = currentBox?.content?.data?.[0].value?.nodes || [];

    const difference = differenceWith(
      pristineNodes,
      currentNodes,
      (pristineNode, currentNode) => {
        const rejectedSuggestionIds = rejectedSuggestions.map((suggestion) => suggestion.id);
        const unwrappedPristineNode = unwrapRejectedSuggestions(
          rejectedSuggestionIds, pristineNode as SlateElement,
        );
        return isEqual(unwrappedPristineNode[0], currentNode);
      },
    );

    return difference.length > 0;
  });

  if (hasTextBoxChanges) {
    return true;
  }

  return false;
};

export const getNonPristineBoxesMap = (boxes: ContractView.Boxes, pristineState: PristineState) => (
  omitBy(boxes, (box) => pristineState.boxesMap[box.id])
);

export const getNonPristineDataFieldsMap = (data: Data, pristineState: PristineState) => (
  omitBy(data, (dataItem) => pristineState.dataFieldsMap[dataItem.id])
);

type DataDiff = {
  key?: string;
};

export const getDataFieldChangedValues = (
  nonPristineDataFields: Record<string, DataField>,
  pristineDataFields: Record<string, DataField>,
) => Object.entries(nonPristineDataFields)
  .reduce<DataDiff[]>((acc, [dataId, nonPristineDataField]) => {
    if (!pristineDataFields[dataId]) {
      acc.push(nonPristineDataField);
      return acc;
    }

    const diff = getDiff(nonPristineDataField, pristineDataFields[dataId]);

    if (isEmpty(diff)) {
      return acc;
    }

    acc.push({
      key: pristineDataFields[dataId].key,
      ...diff,
    });

    return acc;
  }, []);
