// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import sortBy from 'lodash/sortBy';
import { getDateFormat } from 'date';

import { getExtensionsById } from 'reducers/entities/extensions';
import templateGroupsReducer from 'reducers/entities/template-groups';
import { getAccountFromSessionSelector } from 'reducers/session';

import ExtensionsTemplateGroups from './template-groups';
import type { Props } from './template-groups';

export const QUERY_NAME = '/admin/account/extensions/template-groups';

type StateProps = {|
  templateGroups: Array<TemplateGroup>,
  templateGroupsQuery: Query,
  extensionsById: {
    [number]: Extension,
  },
  dateFormat: string,
|};

type DispatchProps = {|
  updateTemplateGroupState: ({ id: number, active: boolean }) => void,
  queryTemplateGroups: () => void,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const templateGroupsQuery = templateGroupsReducer.getQuerySelector(state, { name: QUERY_NAME });

  const account = getAccountFromSessionSelector(state);
  const dateFormat = getDateFormat(account.brandingDateFormat);

  return {
    templateGroupsQuery,
    templateGroups: sortBy(
      templateGroupsReducer.getTemplateGroupsSelector(state, { ids: templateGroupsQuery.result }),
      (templateGroup) => templateGroup.name.toLowerCase(),
    ),
    extensionsById: getExtensionsById(state),
    dateFormat,
  };
};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  queryTemplateGroups: () => {
    dispatch(templateGroupsReducer.queryTemplateGroups({
      name: QUERY_NAME,
    }));
  },
  updateTemplateGroupState: ({ id, active }) => {
    dispatch(templateGroupsReducer.updateTemplateGroup({
      id,
      data: {
        active,
      },
    }));
  },
});

export default connect<Props, {||}, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(ExtensionsTemplateGroups);
