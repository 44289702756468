import { useMemo } from 'react';
import { reduce } from 'lodash';

import { generateDocumentOpenURL } from 'components/contract-boxes/generic-box-helpers';
import PdfViewer from 'components/contract-boxes/pdf-box/pdf-file/pdf-viewer';
import { PDFBox } from 'data-validators/entity-schemas/document-box/pdf-box';
import { OVERLAY_FIELD, SIGNATURE_FIELD } from 'components/contract-boxes/constants';

type Props = {
  contentData: PDFBox['content']['data'];
  contractId: number;
  guestToken: string;
};

const PdfFile = ({
  contentData,
  contractId,
  guestToken,
}: Props) => {
  const { pdfFile, overlayFieldsPerPage } = useMemo(() => (
    reduce(contentData, (acc, contentDataItem) => {
      const {
        key,
        value,
      } = contentDataItem;

      if (key === OVERLAY_FIELD || key === SIGNATURE_FIELD) {
        const page = value.page + 1;
        if (!acc.overlayFieldsPerPage[page]) {
          acc.overlayFieldsPerPage[page] = [];
        }
        acc.overlayFieldsPerPage[page].push(contentDataItem);
      } else if (key === 'file') {
        acc.pdfFile = value as unknown as PDFBox['content']['data'];
      }

      return acc;
    }, {
      pdfFile: null as PDFBox['content']['data'] | null,
      overlayFieldsPerPage: {} as ContractView.OverlayFieldsGroupedByPdfPage,
    })
  ), [contentData]);

  if (!pdfFile) {
    return null;
  }

  return (
    <PdfViewer
      pages={pdfFile.pages}
      overlayFieldsPerPage={overlayFieldsPerPage}
      documentURL={generateDocumentOpenURL(contractId, pdfFile, guestToken)}
    />
  );
};

export default PdfFile;
