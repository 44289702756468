// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import positionMfaReducer from 'reducers/entities/position-mfa';

import VerifyDevice from './verify-device';
import type { Props, FormData } from './verify-device';

type StateProps = {|
  verifyDeviceState: RpcState,
  requestMfaCodeState: RpcState,
|};

type DispatchProps = {|
  verifyDevice: FormData => void,
  requestMfaCode: () => void,
  resetVerifyDeviceState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  position: Position,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, ownProps) => ({
  requestMfaCodeState: positionMfaReducer.getRequestPositionMfaCodeSelector(state, {
    id: ownProps.position.id,
  }),
  verifyDeviceState: positionMfaReducer.getValidateChannelSelector(state, {
    id: ownProps.position.id,
  }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, ownProps) => ({
  requestMfaCode: () => {
    dispatch(positionMfaReducer.requestPositionMfaCode({
      id: ownProps.position.id,
    }));
  },
  verifyDevice: ({ securityCode }) => {
    dispatch(positionMfaReducer.validateChannel({
      id: ownProps.position.id,
      data: {
        securityCode,
      },
    }));
  },
  resetVerifyDeviceState: () => {
    dispatch(positionMfaReducer.validateChannelReset({ id: ownProps.position.id }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyDevice);
