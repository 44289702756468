import { useSelector } from 'react-redux';
import { getCurrentBoxOrder } from 'reducers/current-contract';

const useCurrentBoxOrder: () => ContractView.BoxOrder = () => {
  const boxOrder = useSelector(getCurrentBoxOrder);

  if (!boxOrder) {
    return [];
  }

  return boxOrder;
};

export default useCurrentBoxOrder;
