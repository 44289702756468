// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import {
  isUserOnAdminPage,
  isUserOnGlobalSearchPage,
  isUserOnGlobalTrashPage,
} from 'utils/isOnPage';

import Conditional from 'components/conditional';
import EmptyState from 'components/empty-state';
import NoWorkspacesIcon from 'components/icons/no-workspaces';
import SelectDropdown from 'components/select-dropdown';
import SidebarTooltip from 'components/sidebar/sidebar-tooltip';
import WorkspaceIcon from 'components/icons/workspace';
import CheckIcon from 'components/icons/check';

import style from './workspace-dropdown.module.scss';

type Props = {
  changeCurrentWorkspace: (number, string) => void,
  expanded: boolean,
  message: MessageTranslator,
  tooltipText?: string,
  currentWorkspace: Workspace,
  workspaces: Array<Workspace>,
  isMobile?: boolean,
  toggleMobileMenu: () => void,
  pathname?: string,
};

export class WorkspaceDropdownComponent extends React.PureComponent<Props> {
  toDropdownOption = (workspace: Workspace) => {
    const { currentWorkspace } = this.props;

    let icon = <WorkspaceIcon height="13px" />;
    if (currentWorkspace?.id === workspace.id) {
      icon = <CheckIcon height="13px" />;
    }

    return {
      label: this.getWorkspaceName(workspace),
      IconComponent: () => icon,
      id: workspace.id,
    };
  };

  getWorkspaceName = (workspace: Workspace) => {
    const { message } = this.props;

    if (workspace.virtual) {
      return message({ id: 'Shared with me', comment: 'The virtual workspace name' });
    }

    return workspace.name;
  };

  renderEmptyState() {
    const { message } = this.props;

    return (
      <EmptyState
        icon={<NoWorkspacesIcon height="33px" />}
        header={message({
          id: 'No workspaces available',
          comment: 'Empty state header in the workspace dropdown when not having access to any workspaces.',
        })}
        content={message({
          id: 'You need to be granted access to a workspace to see it in this dropdown.',
          comment: 'Empty state text in the workspace dropdown when not having access to any workspaces.',
        })}
        defaultStyle
        className={style.EmptyState}
      />
    );
  }

  renderSelectedItem() {
    const {
      expanded,
      isMobile,
      currentWorkspace,
      pathname,
      message,
    } = this.props;

    const isOnAdminPage = isUserOnAdminPage(pathname);
    const isOnGlobalSearchPage = isUserOnGlobalSearchPage(pathname);
    const isOnGlobalTrashSearchPage = isUserOnGlobalTrashPage(pathname);
    const isOnAdminOrGlobalSearchPage = isOnAdminPage
      || isOnGlobalSearchPage || isOnGlobalTrashSearchPage;
    const workspaceName = isOnAdminOrGlobalSearchPage
      ? message({
        id: 'Workspace',
        comment: 'Workspace placeholder name for dropdown selector',
      }) : this.getWorkspaceName(currentWorkspace);

    return (
      <>
        <WorkspaceIcon className={style.WorkspaceButtonIcon} width="18px" height="18px" />
        <Conditional ifCondition={isMobile || expanded}>
          <span>
            {workspaceName}
          </span>
        </Conditional>
      </>
    );
  }

  handleChange = (workspaceId: number, location: string) => {
    const { toggleMobileMenu, changeCurrentWorkspace } = this.props;
    toggleMobileMenu();
    changeCurrentWorkspace(workspaceId, location);
  };

  render() {
    const {
      changeCurrentWorkspace,
      expanded,
      isMobile,
      tooltipText,
      workspaces,
    } = this.props;

    if (isMobile || expanded) {
      return (
        <SelectDropdown
          customButtonClass={style.WorkspaceButton}
          customDropdownClass={style.WorkspaceDropdown}
          className={style.WorkspaceSelectDropdown}
          emptyState={this.renderEmptyState()}
          fullWidth
          onChange={this.handleChange}
          options={workspaces.map(this.toDropdownOption)}
          selectedItem={this.renderSelectedItem()}
          location="Main navigation"
        />
      );
    }

    return (
      <SidebarTooltip sideOffset={15} tooltipText={tooltipText}>
        <SelectDropdown
          customButtonClass={style.WorkspaceButton}
          customDropdownClass={style.WorkspaceDropdown}
          className={style.WorkspaceSelectDropdown}
          emptyState={this.renderEmptyState()}
          fullWidth
          onChange={changeCurrentWorkspace}
          options={workspaces.map(this.toDropdownOption)}
          selectedItem={this.renderSelectedItem()}
          location="Main navigation"
        />
      </SidebarTooltip>
    );
  }
}

export default localize<Props>(WorkspaceDropdownComponent);
