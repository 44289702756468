import clsx from 'clsx';
import { noop } from 'lodash';
import { Message } from '@oneflowab/pomes';

import { PARTICIPANTS_TAB } from 'agreement/constants';
import ModalForm from 'hocs/modal-form';
import SingleParticipantIcon from 'components/icons/single-participant';
import { useCollapsedDocumentLayout } from 'components/document-layout-container/collapsed-document-layout/context';
import { COLLAPSED } from 'components/document-layout-container/helpers';
import Actions from './actions';
import type { CollapsedAndExpandedProps, ModalActionsType } from './types';

import style from './single-participant.module.scss';

export function SingleParticipantModalCollapsed({
  children,
  proceed,
  isSignatory,
  isOpen,
  onClose,
  hasError,
  formState,
}: CollapsedAndExpandedProps) {
  const collapsedContext = useCollapsedDocumentLayout();
  const getActions = ({ closeConfirmation }: ModalActionsType) => (
    <div className={clsx(style.Actions, style.CollapsedView)}>
      <Actions
        layout={COLLAPSED}
        isSignatory={isSignatory}
        actions={{
          addParticipants: () => {
            closeConfirmation();
            collapsedContext?.setActiveTab(PARTICIPANTS_TAB);
          },
          proceed,
          cancel: closeConfirmation,
        }}
      />
    </div>
  );

  const actionClasses = clsx(style.CollapsedSingleParticipantActions, {
    [style.ErrorMargin]: hasError,
  });

  return (
    <ModalForm
      title={(
        <Message id="Add participants?" comment="Modal title" />
      )}
      customBodyClass={clsx(style.InvitationContainer, style.CollapsedView)}
      body={(
        <div className={style.Content}>
          <SingleParticipantIcon width="74px" height="74px" />
          <p className={style.SingleParticipantLabel}>
            <Message id="You are the only participant" comment="Information label to participant that they are the only participant in the document" />
          </p>
          <div className={style.ContinueInformation}>
            {isSignatory ? (
              <>
                <p>
                  <Message id="Add more participants or proceed to signing." comment="Information to the participant on actions they can take" />
                </p>
                <p>
                  <b>
                    <Message
                      id="Once you sign, you won't be able to modify the document."
                      comment="Information to the participant about the document being locked after signing"
                    />
                  </b>
                </p>
              </>
            ) : (
              <div className={style.MoveToPending}>
                <Message
                  id="Add more participants or move this document to"
                  comment="Information to the participant on actions they can take"
                />
                <div className={style.PendingStatePill}>
                  <Message
                    id="Pending"
                    comment="Current document state"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      actions={getActions}
      onSubmit={noop}
      onClose={onClose}
      modalKey="single participant modal collapsed"
      customModalClass={style.ModalReset}
      isOpen={isOpen}
      formState={formState}
      centeredActions
      actionClasses={actionClasses}
    >
      {children}
    </ModalForm>
  );
}
