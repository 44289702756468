import React, { useEffect } from 'react';
import { Message } from '@oneflowab/pomes';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import { updateBoxAction } from 'reducers/current-contract';

import useCurrentBoxId from 'hooks/use-current-box-id';
import useCurrentBox from 'hooks/use-current-box';
import { PdfContentData, PDFBox } from 'data-validators/entity-schemas/document-box/pdf-box';

import EmptyState from 'components/empty-state';
import FileUpload from 'components/file-upload';
import PdfDocument from 'components/icons/pdf-document';
import Failed from 'components/icons/failed';
import Delete from 'components/icons/delete';
import Button from 'components/button';
import { showAssetFailedToast } from 'components/asset-error-toasts';

import { Box } from 'data-validators/entity-schemas/document-box';
import style from './empty-pdf-box.module.scss';

const renderFileUploadHeader = (pdfData: PdfContentData) => {
  const hasContentData = !isEmpty(pdfData);

  if (hasContentData) {
    return null;
  }

  return (
    <EmptyState
      className={style.EmptyStateContainer}
      icon={(
        <div className={style.EmptyStateIcon}>
          <PdfDocument height="24px" />
        </div>
      )}
    />
  );
};

type Props = {
  notEditable: boolean,
  fileUploadProps: any,
  pdfData: PdfContentData,
};

export const EmptyPdfBox = ({
  notEditable,
  fileUploadProps,
  pdfData,
}: Props) => {
  const dispatch = useDispatch();
  const hasFailedAsset = pdfData?.value.status === 'failed';

  const currentBoxId = useCurrentBoxId(fileUploadProps.boxId);
  const box = useCurrentBox<PDFBox>(currentBoxId);

  useEffect(() => {
    if (hasFailedAsset) {
      showAssetFailedToast();
    }
  }, [hasFailedAsset]);

  const resetContentData = React.useCallback(() => {
    // removes newly added box data
    const filteredData = box?.content.data?.filter((dataItem) => !dataItem._id);

    dispatch(updateBoxAction({
      ...box,
      content: {
        ...box?.content,
        data: filteredData?.map((dataItem) => {
          if (dataItem.key === 'file') {
            return {
              ...dataItem,
              _removed: true,
            };
          }

          return dataItem;
        }),
      },
    } as Box));
  }, [box, dispatch]);

  if (hasFailedAsset) {
    return (
      <div className={clsx(style.FailedAssetWrapper, 'FailedAsset')}>
        <Failed className={style.FailedIcon} />
        <span className={style.FailedMessage}>
          <Message
            id="Processing of the file failed"
            comment="message saying that file upload has failed"
          />
        </span>
        <span className={style.AssetNameWrapper}>
          <PdfDocument height="14px" />
          <span>
            {pdfData?.value.name}
            .
            {pdfData?.value.assetExtension}
          </span>
        </span>
        {notEditable ? (
          <span className={style.HelperText}>
            <Message
              id="Contact document creator to fix the issue"
              comment="message saying that file upload has failed and user should contact document creator"
            />
          </span>
        ) : (
          <Button
            outline
            icon={Delete}
            onClick={resetContentData}
          >
            <Message
              id="Remove file"
              comment="Text for button to remove a file"
            />
          </Button>
        )}
      </div>
    );
  }

  if (notEditable) {
    return (
      <div className={style.ReadOnlyNoFile}>
        <div className={style.ReadOnlyNoFileIcon}>
          <PdfDocument height={75} />
        </div>
        <Message
          id="No PDF document"
          comment="Text when there is no added pdf file"
        />
      </div>
    );
  }

  return (
    <FileUpload
      {...fileUploadProps}
      header={renderFileUploadHeader(pdfData)}
      isContractBox
    />
  );
};
