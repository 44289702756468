// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import positionsReducer from 'reducers/entities/positions';

import { ResendUserInvitation, type Props } from './resend-user-invitation';

type OwnProps = {|
  position: Position,
|};

type StateProps = {|
  resendInvitationState: RpcState,
|};

type DispatchProps = {|
  resendInvitation: () => void,
  resetResendInvitationState: () => void,
|};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { position }) => ({
  resendInvitationState: positionsReducer.getResendInvitationSelector(state, { id: position.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { position }) => ({
  resendInvitation: () => {
    dispatch(positionsReducer.resendInvitation({ id: position.id }));
  },
  resetResendInvitationState: () => {
    dispatch(positionsReducer.resendInvitationReset({ id: position.id }));
  },
});

const connectedResendUserInvitation = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(ResendUserInvitation);

export { connectedResendUserInvitation as ResendUserInvitation };
