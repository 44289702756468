import moment from 'moment';

import clsx from 'clsx';
import style from './create-trashed-time.module.scss';

type Props = {
  time: Date | null,
  name?: string | null,
  dateFormat?: string,
  className?: string,
}

export const CreatedTrashedTime = ({
  time,
  name,
  dateFormat,
  className,
}: Props) => {
  const formattedTime = time ? moment(time).format(dateFormat || 'YYYY-MM-DD') : '';
  return (
    <div className={clsx(style.CreateTrashedTimeContainer, className)}>
      <p data-testid="formatted-time">{formattedTime}</p>
      {name && <p data-testid="name">{name}</p>}
    </div>
  );
};
