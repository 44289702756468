// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getAccount,
  updateAccount,
  updateAccountDataManagement,
  buySeats,
  exportAccount,
  startTrial,
  createCancellation,
} from 'oneflow-client/accounts';

import type { NormalizedAccounts } from 'oneflow-client/accounts';

import apiWrapper from 'sagas/api-wrapper';

import accounts from 'reducers/entities/accounts';
import accountSeatsReducer from 'reducers/entities/account-seats';

type MapperArgs = {
  data: NormalizedAccounts,
  action: any,
};

type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(accounts.setAccounts(data.entities.accounts));
}

export function* mapperDataManagement({ action, data }: MapperArgs): Mapper {
  yield put(accounts.setAccounts({
    [action.id]: {
      dataManagement: data,
    },
  }));
}

export function* mapperWithSeats({ data }: MapperArgs): Mapper {
  yield put(accountSeatsReducer.setAccountSeats(data.entities.accountSeats));
}

export function* mapperWithSeatsAndAccount({ data }: MapperArgs): Mapper {
  yield put(accountSeatsReducer.setAccountSeats(data.entities.accountSeats));
  yield put(accounts.setAccounts(data.entities.accounts));
}

export function* startTrialMapper({ data, action }: MapperArgs): Mapper {
  yield put(accounts.setAccounts({
    [action.id]: data,
  }));
}

const mappers = {
  fetch: {
    mapper: mapperWithSeatsAndAccount,
    request: getAccount,
  },
  update: {
    mapper,
    request: updateAccount,
  },
  rpcs: {
    updateAccountDataManagement: {
      mapper: mapperDataManagement,
      name: 'updateAccountDataManagement',
      request: updateAccountDataManagement,
    },
    buySeats: {
      name: 'buySeats',
      mapper: mapperWithSeats,
      request: buySeats,
    },
    exportAccount: {
      name: 'exportAccount',
      request: exportAccount,
    },
    startTrial: {
      name: 'startTrial',
      request: startTrial,
      mapper: startTrialMapper,
    },
    createCancellation: {
      name: 'createCancellation',
      request: createCancellation,
    },
  },
};

const accountsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: accounts,
  mappers,
});

export default accountsSagas;
