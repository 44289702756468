import { pipe } from 'lodash/fp';
import { localize, Message } from '@oneflowab/pomes';
import type { FunctionComponent } from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';

import ArrowRightIcon from 'components/icons/new-arrow-right';
import ChevronRightIcon from 'components/icons/chevron-right';
import { useFilterableState } from 'components/filterable';

import CommandItem from '../item';

type Props = {
  message: MessageTranslator;
  navigateTo: (path: string) => void;
  handleSelect: () => void;
};

const ProfileItems = ({ message, navigateTo, handleSelect }: Props) => {
  const search = useFilterableState(state => state.search);
  return (
    <>
      <CommandItem
        id="navigate-to-profile"
        value={`${message({
          id: 'Go to',
          comment: 'Navigate action',
        })} ${message({
          id: 'My profile',
          comment: 'Label for My profile section',
        })}`}
        onSelect={pipe(handleSelect, () => {
          navigateTo('/user/profile');
        })}
      >
        <ArrowRightIcon height="20px" />
        <Message
          id="Go to"
          comment="Link text for navigating to one of sub-pages of admin"
        />
        {' '}
        <Message
          id="my profile"
          comment="Link text for navigating to the user profile page"
        />
      </CommandItem>
      {search && (
        <>
          <CommandItem
            id="navigate-to-profile-notifications"
            value={`${message({
              id: 'Go to',
              comment: 'Navigate action',
            })} ${message({
              id: 'My profile',
              comment: 'Label for My profile section',
            })} ${message({
              id: 'Notifications',
              comment: 'Label for Message templates section',
            })}`}
            onSelect={pipe(handleSelect, () => {
              navigateTo('/user/notifications');
            })}
          >
            <ArrowRightIcon height="20px" />
            <Message
              id="Go to"
              comment="Link text for navigating to one of sub-pages of admin"
            />
            {' '}
            <Message
              id="My profile"
              comment="Link text for navigating to the user profile page"
            />
            <ChevronRightIcon width="8px" />
            <Message
              id="Notifications"
              comment="Link text for navigating to the user notifications page"
            />
          </CommandItem>
          <CommandItem
            id="navigate-to-profile-message-templates"
            value={`${message({
              id: 'Go to',
              comment: 'Navigate action',
            })} ${message({
              id: 'My profile',
              comment: 'Label for My profile section',
            })} ${message({
              id: 'Message templates',
              comment: 'Label for Message templates section',
            })}`}
            onSelect={pipe(handleSelect, () => {
              navigateTo('/user/templates');
            })}
          >
            <ArrowRightIcon height="20px" />
            <Message
              id="Go to"
              comment="Link text for navigating to one of sub-pages of admin"
            />
            {' '}
            <Message
              id="My profile"
              comment="Link text for navigating to the user profile page"
            />
            <ChevronRightIcon width="8px" />
            <Message
              id="Message templates"
              comment="Link text for navigating to the user message templates page"
            />
          </CommandItem>
          <CommandItem
            id="navigate-to-profile-security"
            value={`${message({
              id: 'Go to',
              comment: 'Navigate action',
            })} ${message({
              id: 'My profile',
              comment: 'Label for My profile section',
            })} ${message({
              id: 'Security',
              comment: 'Label for Security section',
            })}`}
            onSelect={pipe(handleSelect, () => {
              navigateTo('/user/security');
            })}
          >
            <ArrowRightIcon height="20px" />
            <Message
              id="Go to"
              comment="Link text for navigating to one of sub-pages of admin"
            />
            {' '}
            <Message
              id="My profile"
              comment="Link text for navigating to the user profile page"
            />
            <ChevronRightIcon width="8px" />
            <Message
              id="Security"
              comment="Link text for navigating to the user security page"
            />
          </CommandItem>
        </>
      )}
    </>
  );
};

export default localize(ProfileItems) as unknown as FunctionComponent<Omit<Props, 'message'>>;
