import { Message } from '@oneflowab/pomes';

type Props = {
  markedAsSignDate: string,
  validUntil: string
};

const MarkedAsSignedAndCanceled = ({ markedAsSignDate, validUntil }: Props) => (
  <span>
    <Message
      id="Document was marked as signed on {date} and then canceled. Document is valid until {validUntil} and has no legally binding e-signature."
      values={{
        date: markedAsSignDate,
        validUntil,
      }}
      comment="Text for a canceled marked as signed contract. Visible in contract."
    />
  </span>
);

export default MarkedAsSignedAndCanceled;
