import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import { useEffect, useRef, useState } from 'react';
import type { ReactNode, ReactPortal } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: ReactNode
};

/**
 * Async scrollTo
 * @param offset - offset to scroll to
 */
const scrollTo = async (el: HTMLElement, offset: number) => (
  new Promise((resolve) => {
    const fixedOffset = offset.toFixed();
    const onScroll = () => {
      if (el.scrollTop.toFixed() === fixedOffset) {
        el.removeEventListener('scroll', onScroll);
        resolve(null);
      } else if (Math.ceil(el.scrollTop) + Math.ceil(el.clientHeight) >= el.scrollHeight) {
        el.removeEventListener('scroll', onScroll);
        resolve(null);
      }
    };

    el.addEventListener('scroll', onScroll);
    onScroll();
    el.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  })
);

const SCROLL_PADDING = 20;

/**
 * Component that ensures the popover content stays in view when the height of its content changes.
 * since popovers are position: fix, we need to do manual calculations to ensure the popover
 * stays in view. If we could use position: absolute for popovers, we wouldn't need this component.
 */
const KeepInView = ({ children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { documentScrollContainerRef } = useDocumentLayout();
  const [paddingHeight, setPaddingHeight] = useState(0);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (ref.current) {
      const popover = ref.current;

      const scrollToBottom = async (scrollableElement: HTMLElement) => {
        const popoverRect = popover.getBoundingClientRect();

        if (popoverRect.bottom + SCROLL_PADDING > window.innerHeight) {
          const scrollAmount = (
            popoverRect.bottom - window.innerHeight + SCROLL_PADDING
          );

          return await scrollTo(
            scrollableElement,
            scrollableElement.scrollTop + scrollAmount,
          );
        }

        return null;
      };

      let animationFrame: number | null = null;
      const observer = new ResizeObserver(() => {
        animationFrame = requestAnimationFrame(async () => {
          const scrollableElement = documentScrollContainerRef.current || document.documentElement;
          if (!popover) {
            return;
          }

          await scrollToBottom(scrollableElement);

          const popoverRect = popover.getBoundingClientRect();

          // if the popover is still outside the viewport after scrolling, this means that there is
          // not enough space at the bottom of the page for the popover to be fully visible
          // so we need to add padding to the bottom of the page
          if (popoverRect.bottom + SCROLL_PADDING > window.innerHeight) {
            setPaddingHeight((curr) => (
              curr + popoverRect.bottom - window.innerHeight + SCROLL_PADDING
            ));

            await scrollToBottom(scrollableElement);
          }
        });
      });

      observer.observe(popover);
      return () => {
        if (animationFrame) {
          cancelAnimationFrame(animationFrame);
          observer.unobserve(popover);
        }
      };
    }
  // register the resizeObserver only once when the component mounts
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref}>
      {children}
      {/* virtual padding for when popover is at the bottom of the page */}
      {createPortal(
        <div style={{ height: paddingHeight }} />,
        documentScrollContainerRef.current || document.body,
      ) as ReactPortal}
    </div>
  );
};

export default KeepInView;
