import { Message } from '@oneflowab/pomes';

/* eslint-disable import/prefer-default-export */
export const documentFrequencyList = [
  {
    name: 'daily_weekly',
    label: <Message id="Daily/weekly" comment="Label for radio group item" />,
    id: 1,
  },
  {
    name: 'monthly',
    label: <Message id="Monthly" comment="Label for radio group item" />,
    id: 2,
  },
  {
    name: 'quarterly',
    label: <Message id="Quarterly" comment="Label for radio group item" />,
    id: 3,
  },
  {
    name: 'occasionally',
    label: <Message id="Occasionally" comment="Label for radio group item" />,
    id: 4,
  },
];
