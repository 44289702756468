import { Message } from '@oneflowab/pomes';
import { useSelector, useDispatch } from 'react-redux';

import ModalForm from 'hocs/modal-form';
import agreementsReducer from 'reducers/entities/agreements';

import TerminateIcon from 'components/icons/terminate';
import { CancelButton } from 'components/buttons';
import Button from 'components/button';

import styles from './turn-off-notifications.module.scss';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onSubmit: () => void;
  participantId?: Oneflow.Participant['id'];
}

const TurnOffNotifications = ({
  isModalOpen, setIsModalOpen, onSubmit, participantId,
}: Props) => {
  const dispatch = useDispatch();
  const formState = useSelector((state) => (
    agreementsReducer.getToggleNotificationsGuestSelector(state, { id: participantId })
  ));

  const resetFormState = () => {
    dispatch(agreementsReducer.toggleNotificationsGuestReset({ id: participantId }));
  };

  const renderBody = () => (
    <div className={styles.Body}>
      <TerminateIcon height="74" className={styles.TerminateIcon} />
      <span className={styles.BodyText}>
        <Message
          id="You will not receive any notifications for signing-related updates, comments, and other document changes."
          comment="Turn off notifications confirmation modal text"
        />
      </span>
    </div>
  );

  const renderActions = () => (
    <>
      <CancelButton onClick={() => setIsModalOpen(false)} />
      <Button
        kind="primary"
        onClick={onSubmit}
        disabled={formState.loading}
      >
        <Message
          id="Yes, turn off notifications"
          comment="Button text for confirming turning off the notifications"
        />
      </Button>
    </>
  );

  return (
    <ModalForm
      title={(
        <Message
          id="Turn off notifications?"
          comment="The title of the turn off notifications confirmation modal"
        />
      )}
      onSubmit={onSubmit}
      isOpen={isModalOpen}
      formState={formState}
      body={renderBody()}
      onClose={() => setIsModalOpen(false)}
      actions={renderActions}
      resetFormState={resetFormState}
    >
      {() => null}
    </ModalForm>
  );
};

export default TurnOffNotifications;
