// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, uniqueId } from 'lodash';
import { Message, type MessageTranslator } from '@oneflowab/pomes';
import clsx from 'clsx';

import adminPage from 'hocs/admin-page';

import AddMember from 'components/modals/add-member';
import { RemoveMember } from 'components/modals/remove-member';
import { RemoveMembers } from 'components/modals/remove-members';

import { DeleteBulkAction } from 'components/bulk-action-items/delete';
import { HelpCenterLink } from 'components/help-center-link';
import ActionBar from 'components/action-bar';
import { ActionBarText } from 'components/action-bar-text';
import ActionsMenu from 'components/actions-menu';
import { DeleteMenuItem } from 'components/menu-items/delete';
import Pagination from 'components/pagination';
import { checkAcl } from 'components/acl';
import Conditional from 'components/conditional';
import { BadgeInactive } from 'components/badges/badge-inactive';
import {
  Table,
  TableFiltering,
} from 'components/table';
import NoGroupMemberIcon from 'components/icons/no-groupmember';
import AddMembersIcon from 'components/icons/add-members';
import Button from 'components/button';
import UserRole from 'components/user-roles';

import style from './members.module.scss';

export type Props = {
  group: Group,
  positions: Array<Position>,
  query: Query,
  createState: CreateState,
  queryGroupPositions: (args: QueryFuncArgs) => void,
  message: MessageTranslator,
};

type Filter = {
  q: string,
};

type State = {
  filters: Filter,
  selectedItems: Array<number>,
  clearTableSelection: boolean,
};

export class GroupMembers extends React.Component<Props, State> {
  state = {
    filters: undefined,
    selectedItems: [],
    clearTableSelection: false,
  };

  componentDidMount() {
    this.changeHandler({
      offset: 0,
      params: {},
    });
  }

  onFilter(params) {
    const cleanParams = (isEmpty(params) || params.q === '') ? undefined : params;
    this.props.queryGroupPositions({ params });
    this.setState({ filters: cleanParams });
  }

  onMembersRemoved() {
    this.setState({ clearTableSelection: true });
  }

  getAddedMembersCount() {
    if (this.props.createState.data.positionIds) {
      return this.props.createState.data.positionIds.length;
    }

    return 0;
  }

  getSelectedIds() {
    return this.state.selectedItems;
  }

  getDeleteMenuItem = (onClick: () => void) => {
    const { group } = this.props;

    return (
      <DeleteMenuItem
        onClick={onClick}
        disabled={!checkAcl(group.acl, 'group:position:remove')}
      />
    );
  }

  getActions = (item: Position) => {
    const { group } = this.props;

    return (
      <ActionsMenu
        actions={[
          <RemoveMember group={group} position={item}>
            {this.getDeleteMenuItem}
          </RemoveMember>,
        ]}
        focusOnCloseDisabled
      />
    );
  }

  getDeleteBulkAction = (onClick: () => void) => {
    const { group } = this.props;

    return (
      <DeleteBulkAction
        onClick={onClick}
        disabled={!checkAcl(group.acl, 'group:position:remove')}
      />
    );
  }

  getName = (position: Position) => {
    let badges = [];

    if (!position.active) {
      badges = [
        ...badges,
        <BadgeInactive
          key={uniqueId()}
        />,
      ];
    }

    return (
      <div className={style.User}>
        <Link to={`/admin/users/${position.id}`}>
          {position.fullname}
        </Link>
        {badges}
      </div>
    );
  };

  getTableConfig() {
    const { message, group } = this.props;

    const actions = [
      <RemoveMembers
        group={group}
        ids={this.getSelectedIds()}
        key="remove"
      >
        {this.getDeleteBulkAction}
      </RemoveMembers>,
    ];

    return {
      items: this.props.positions,
      itemKey: 'id',
      actions,
      columns: [
        {
          name: 'name',
          label: message({
            id: 'User name',
            comment: 'A label for user name link',
          }),
          type: 'cell',
          value: this.getName,
        }, {
          name: 'role',
          label: message({ id: 'User type', comment: 'Column label in group members page' }),
          type: 'cell',
          value: (item) => (
            <UserRole userRole={item.userRole} withTooltip />
          ),
        }, {
          name: 'actions',
          label: message({
            id: 'Actions',
            comment: 'A label for Actions button',
          }),
          type: 'actions',
          value: this.getActions,
        },
      ],
    };
  }

  getAddMemberTextButton = (onClick: () => void) => (
    <Button
      kind="linkInline"
      customClass={clsx(style.AddButton, style.ActionLink)}
      onClick={onClick}
      disabled={!checkAcl(this.props.group.acl, 'group:position:create')}
      icon={<AddMembersIcon height="10px" />}
    >
      <Message
        id="Add members"
        comment="Used as the label of the add members button"
      />
    </Button>
  );

  getAddMemberButton = (onClick: () => void) => (
    <Button
      icon={AddMembersIcon}
      kind="primary"
      onClick={onClick}
      disabled={!checkAcl(this.props.group.acl, 'group:position:create')}
    >
      <Message id="Add members" comment="Text inside the button" />
    </Button>
  );

  getEmptyContent() {
    const { group } = this.props;

    return (
      <div>
        <Message
          id="Click {addMemberButton} to grant users access to this group."
          values={{
            addMemberButton: (
              <AddMember group={group}>
                {this.getAddMemberTextButton}
              </AddMember>
            ),
          }}
          comment="A test for around the add member button"
        />
        <br />
        <HelpCenterLink path="support/solutions/articles/77000435912-add-users-to-groups" />
      </div>
    );
  }

  getEmptyState() {
    const { message } = this.props;
    return {
      header: message({
        id: 'This group has no members',
        comment: 'A message for when the group is empty',
      }),
      icon: <NoGroupMemberIcon height="33px" />,
      content: this.getEmptyContent(),
      showEmptyState: this.shouldShowEmptyState(),
    };
  }

  changeHandler({ offset, limit, params }) {
    this.props.queryGroupPositions({
      params: params || this.props.query.params,
      pagination: {
        offset,
        limit,
      },
    });
  }

  selectionChangeHandler(items) {
    this.setState({ selectedItems: items, clearTableSelection: false });
  }

  shouldShowEmptyState() {
    return this.props.query.count === 0 && !this.props.query.loading && !this.state.filters;
  }

  render() {
    const { group, message, query } = this.props;
    const { clearTableSelection } = this.state;

    return (
      <div>
        <ActionBar collapsed>
          <ActionBar.Group>
            <AddMember group={group}>
              {this.getAddMemberButton}
            </AddMember>
            <ActionBarText
              header={(
                <Message
                  id="Organize access to your contracts and templates"
                  comment="Header"
                />
              )}
              text={(
                <Message
                  id="By adding users into a group, you can easily manage access to workspaces for all users in the group."
                  comment="Help text for the groups tab in the user pages."
                />
              )}
            />

          </ActionBar.Group>
        </ActionBar>
        <Conditional ifCondition={!this.shouldShowEmptyState()}>
          <TableFiltering
            filters={[
              {
                type: 'text',
                name: 'search',
                defaultValue: '',
                queryKey: 'q',
                autoFocus: true,
                placeholder: message({
                  id: 'Find members',
                  comment: 'Placeholder in search field.',
                }),
              },
            ]}
            onFilterHandler={(params) => this.onFilter(params)}
            loading={query.loading}
          />
        </Conditional>
        <Table
          config={this.getTableConfig()}
          query={query}
          onSelectionChanged={(items) => { this.selectionChangeHandler(items); }}
          clearSelection={clearTableSelection}
          emptyState={this.getEmptyState()}
        />
        <Conditional ifCondition={!this.shouldShowEmptyState()
          && !query.loading
          && query.count > 0}
        >
          <Pagination
            totalItems={query.count}
            itemsPerPage={query.pagination.limit}
            currentOffset={query.pagination.offset}
            onChange={(params) => this.changeHandler(params)}
            entityName={message({
              id: 'users',
              comment: 'Plural form in pagination of users.',
            })}
          />
        </Conditional>
      </div>
    );
  }
}

export default adminPage(({ props: { message } }) => ({
  title: message({
    id: 'Members',
    comment: 'Used for the title of the Members page',
  }),
  modules: [[]],
}))(GroupMembers);
