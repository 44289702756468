import { Message } from '@oneflowab/pomes';

import { getAgreementDateFormat, formatDateString } from 'date';

import style from './style.module.scss';
import Dot from '../dot';

type Props = {
  agreement: Oneflow.Agreement;
};

const Ended = ({ agreement }: Props) => {
  const dateFormat = getAgreementDateFormat(agreement?.config?.dateFormat);
  const dateString = formatDateString(agreement?.periodEndTime?.toString(), dateFormat);

  return (
    <div className={style.Insight} data-testid="ended">
      <Dot color="red" />
      <span className={style.SemiBold}>
        <Message
          id="Ended:"
          comment="Agreement has ended at date."
        />
      </span>
      <span data-testid="ended-date">
        {' '}
        {dateString}
      </span>
    </div>
  );
};

export default Ended;
