import { ACTION_STATUS_NOT_STARTED, ACTION_STATUS_RUNNING } from 'agreement/actions/constants';

import { getDraftApprovers } from 'agreement/draft-approval-flow';

const isCurrentInternalApprover = (agreement, participant) => {
  const agreementApprovers = getDraftApprovers(agreement);

  if (!agreementApprovers.length) {
    return false;
  }

  const currentApprover = agreementApprovers.filter(
    (node) => node.status === ACTION_STATUS_RUNNING,
  );

  return currentApprover.some((approver) => approver.config.actor.id === participant.id);
};

export default isCurrentInternalApprover;

/* This function is to check if there are other remaining internal approvers
who need to approve the draft */
export const hasOtherPendingDraftApprovals = (agreement, participantId) => {
  const agreementApprovers = getDraftApprovers(agreement);

  if (!agreementApprovers.length) {
    return false;
  }

  const otherPendingApprovers = agreementApprovers.filter(
    (approver) => (approver.status === ACTION_STATUS_RUNNING
    || approver.status === ACTION_STATUS_NOT_STARTED)
    && approver.config.actor.id !== participantId,
  );

  return otherPendingApprovers.length > 0;
};
