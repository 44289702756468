import {
  createContext,
  useContext,
} from 'react';

type FormFieldContextType = {
  fieldValue: string,
  onFieldValueChange: (newFieldValue: string) => void,
  onFieldValueChangeDebounced: (newFieldValue: string) => void,
  hasDataField: boolean,
};

export const FormFieldContext = createContext<FormFieldContextType | null>(null);

export const useFormFieldContext = () => {
  const contextValue = useContext(FormFieldContext);

  if (!contextValue) {
    return {};
  }

  return contextValue;
};
