import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import I18n, { I18nContext } from '@oneflowab/pomes';

const renderDetachedModal = ({
  store,
  translations,
  children,
}) => {
  ReactDOM.render(
    <Provider store={store}>
      <I18n translations={translations} initialized>
        {children}
      </I18n>
    </Provider>,
    document.getElementById('react-portal-container'),
  );
};

export const unmountDetachedModal = () => {
  ReactDOM.unmountComponentAtNode(document.getElementById('react-portal-container'));
};

const useDetachedModalRenderer = (renderChildren) => {
  const { store } = React.useContext(ReactReduxContext);
  const { translations } = React.useContext(I18nContext);

  return useCallback(() => renderDetachedModal({
    store,
    translations,
    children: renderChildren(),
  }), [renderChildren, store, translations]);
};

export default useDetachedModalRenderer;
