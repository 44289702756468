// @flow

import { type MessageTranslator } from '@oneflowab/pomes';
import { getRef } from 'agreement/event';
import AuditTrailConstants from 'agreement/audit-trail-constants';
import { getFormattedAttribute } from '../helpers';

// Yes, we are using space as thousand separator for ALL languages/locales
const formatNumber = ({ value }) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

const formatSelected = ({ value, message }) => (
  value === 1
    ? message({ id: 'selected', comment: 'Indicates selected value' })
    : message({ id: 'not selected', comment: 'Indicates non-selected value' })
);

export const dataCreate = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const key = getRef(event, 'key');
  const label = getRef(event, 'label') || getFormattedAttribute(key, 'data');
  return {
    text: message({
      id: '{label} was added.',
      values: { label },
      comment: 'Audit trail message',
    }),
    showEditIcon: true,
  };
};

export const dataUpdate = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const key = getRef(event, 'key');
  const label = getRef(event, 'label') || getFormattedAttribute(key, 'data');
  const to = getRef(event, 'to');
  const from = getRef(event, 'from');
  const selectTypes = [AuditTrailConstants.TYPE_CHECKBOX, AuditTrailConstants.TYPE_RADIO_BUTTON];
  const hasNewlines = /<br>/g;

  // Have to declare these here, since it's not allowed to do it inside a case statement
  let valueKey: string;
  let fromType: number;
  let toType: number;
  let fromIsSelectType: boolean;
  let toIsSelectType: boolean;
  let fromValueFormatFunc = (options) => options.value;
  let toValueFormatFunc = (options) => options.value;
  let fromValue: string | number | null;
  let toValue: string | number | null;

  switch (key) {
    case 'text':
    case 'product_sum':
      return ({
        text: message({
          id: '{label} was updated.',
          values: { label },
          comment: 'Audit trail message',
        }),
        showEditIcon: true,
      });

    case 'form_input':
    case 'product':
      if (!to) {
        return ({
          text: message({
            id: '{label} was updated.',
            values: { label },
            comment: 'Audit trail message',
          }),
          showEditIcon: true,
        });
      }
      valueKey = key === 'product' ? 'count' : 'value';
      // Use "to" count_type if it exists, but since it isn't always sent,
      // fallback to "from" count_type, and vise versa
      fromType = from ? from.count_type : to.count_type;
      toType = to ? to.count_type : from.count_type;
      fromIsSelectType = selectTypes.includes(fromType);
      toIsSelectType = selectTypes.includes(toType);
      fromValueFormatFunc = fromIsSelectType ? formatSelected : formatNumber;
      toValueFormatFunc = toIsSelectType ? formatSelected : formatNumber;
      fromValue = from && from[valueKey];
      toValue = to && to[valueKey];

      if ((!fromValue && fromValue !== 0) && (toValue || toValue === 0)) {
        if (hasNewlines.test(toValue)) {
          return {
            text: message({
              id: '{attribute-name} was set <strong>to:</strong><br>{to-value}.',
              values: {
                'attribute-name': label,
                'to-value': toValueFormatFunc({ value: toValue, message }),
              },
              comment: 'Audit trail message',
            }),
            showEditIcon: true,
          };
        }
        return {
          text: message({
            id: '{attribute-name} was set to {to-value}.',
            values: {
              'attribute-name': label,
              'to-value': toValueFormatFunc({ value: toValue, message }),
            },
            comment: 'Audit trail message',
          }),
          showEditIcon: true,
        };
      }

      if ((fromValue || fromValue === 0) && fromValue !== toValue) {
        if (!toValue) {
          toValue = `<i>${message({
            id: 'empty',
            comment: 'Audit trail partial message',
          })}</i>`;
        }

        if (hasNewlines.test(fromValue) || hasNewlines.test(toValue)) {
          return {
            text: message({
              id: '{attribute-name} was changed <strong>from:</strong><br>{from-value}<br><br><strong>to:</strong><br>{to-value}.',
              values: {
                'attribute-name': label,
                'from-value': fromValueFormatFunc({ value: fromValue, message }),
                'to-value': toValueFormatFunc({ value: toValue, message }),
              },
              comment: 'Audit trail message',
            }),
            showEditIcon: true,
          };
        }
        return {
          text: message({
            id: '{attribute-name} was changed from {from-value} to {to-value}.',
            values: {
              'attribute-name': label,
              'from-value': fromValueFormatFunc({ value: fromValue, message }),
              'to-value': toValueFormatFunc({ value: toValue, message }),
            },
            comment: 'Audit trail message',
          }),
          showEditIcon: true,
        };
      }

      return {
        text: message({
          id: '{label} was updated.',
          values: { label },
          comment: 'Audit trail message',
        }),
        showEditIcon: true,
      };

    case 'file':
      if (from && to && from.name !== to.name) {
        return {
          text: message({
            id: '{label} was renamed to {new-label}.',
            values: { label: from.name, 'new-label': to.name },
            comment: 'Audit trail message',
          }),
          showEditIcon: true,
        };
      }

      return {
        text: message({
          id: '{label} was updated.',
          values: { label },
          comment: 'Audit trail message',
        }),
        showEditIcon: true,
      };

    default:
      return {
        text: message({
          id: '{label} was updated.',
          values: { label },
          comment: 'Audit trail message',
        }),
        showEditIcon: true,
      };
  }
};

export const dataRemove = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const key = getRef(event, 'key');
  const label = getRef(event, 'label') || getFormattedAttribute(key, 'data');
  return {
    text: message({
      id: '{label} was removed.',
      values: { label },
      comment: 'Audit trail message',
    }),
    showEditIcon: true,
  };
};
