import { Message } from '@oneflowab/pomes';
import { useDispatch, useSelector } from 'react-redux';
import agreementsReducer from 'reducers/entities/agreements';
import clsx from 'clsx';

import useAgreement from 'hooks/use-agreement';
import { areSettingsDisabled } from 'components/document-tabs/settings-tab/helpers';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';
import { getCurrentDurationData, isRemindMeVisible } from 'components/document-tabs/settings-tab/sections/duration-reminders/remind-me/helper';
import useCurrentBoxes from 'hooks/use-current-boxes';
import { getContractMetaData } from 'reducers/current-contract';

import { checkAcl } from 'components/acl';
import SelectField, { Props as SelectFieldProps } from 'components/document-tabs/settings-tab/components/select-field';
import Divider from 'components/divider';
import Label from 'components/document-tabs/settings-tab/components/label';
import SectionSeparator from 'components/document-tabs/settings-tab/components/section-separator/section-separator';
import { getPositionFromSessionSelector } from 'reducers/session';
import CounterpartyToggle from './toggles/counterparty-toggle';

import style from './counterparty-preferences.module.scss';

type Props = {
  agreementId: number,
};

const allOptions = [
  {
    value: 0,
    label: <Message id="Company" comment="Counterparty type" />,
  },
  {
    value: 1,
    label: <Message id="Individual" comment="Counterparty type" />,
  },
];

type Option = typeof allOptions[number];

const CounterpartyPreferences = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const dispatch = useDispatch();
  const { resetRPCStates } = useAPIError(agreementId);

  const boxes = useCurrentBoxes();
  const { durationBoxId } = useSelector(getContractMetaData);
  const currentDurationData = getCurrentDurationData(boxes, durationBoxId);
  const showDurationSection = isRemindMeVisible(currentDurationData);
  const myPosition = useSelector(getPositionFromSessionSelector);

  const getIsDisabledState = ({ acl, position }: { acl?: string, position?: Readonly<object> }) => {
    if (position) {
      return areSettingsDisabled(agreement, position);
    }

    if (acl) {
      return areSettingsDisabled(agreement) || !checkAcl(agreement.acl, acl);
    }
    return areSettingsDisabled(agreement);
  };

  const counterpartDecline = (checkboxValue: boolean) => {
    resetRPCStates();
    dispatch(agreementsReducer.updateAgreementPreferences({
      id: agreementId,
      data: {
        counterpartDecline: checkboxValue,
      },
    }));
  };

  const counterpartSelfUpdateAndRemove = (checkboxValue: boolean) => {
    resetRPCStates();
    dispatch(agreementsReducer.updateConfig({
      id: agreementId,
      data: {
        counterpartSelfUpdateAndRemove: checkboxValue,
      },
    }));
  };

  const handleChange: SelectFieldProps<Option['value']>['onChange'] = (value, options) => {
    resetRPCStates();
    dispatch(agreementsReducer.updateConfig({
      id: agreementId,
      data: {
        defaultPartyType: value,
      },
      pipe: {
        onFailure: options?.onFailure,
      },
    }));
  };

  const isSettingsDisabledForUser = getIsDisabledState({ position: myPosition });

  const label = (
    <Label
      hint={(
        <Message
          id="Default tab when adding counterparties"
          comment="Tooltip message for dropdown menu for selecting the type of counterparty in a document"
        />
      )}
      customClassName={isSettingsDisabledForUser ? style.Disabled : undefined}
    >
      <Message
        id="Counterparty type"
        comment="Label of dropdown menu for selecting the type of counterparty in a document."
      />
    </Label>
  );

  return (
    <>
      {!getIsDisabledState({}) && (
        <>
          <SelectField
            label={label}
            options={allOptions}
            value={agreement.config?.defaultPartyType || 0}
            name="defaultParty-type"
            onChange={handleChange}
            disabled={isSettingsDisabledForUser}
          />
          <div className={
            clsx(style.Description, {
              [style.Disabled]: isSettingsDisabledForUser,
            })
          }
          >
            <Message
              id="Choose the default type for all new counterparties."
              comment="Explanatory text underneath counterparty type dropdown field."
            />
          </div>
          <SectionSeparator />
        </>
      )}
      <CounterpartyToggle
        title={(
          <Message
            id="Allow counterparties to decline the document"
            comment="Label for turning decline setting on or off"
          />
        )}
        onChange={counterpartDecline}
        value={Boolean(agreement.counterpartDecline)}
        disabled={getIsDisabledState({ acl: 'agreement:update:counterpart_decline' })}
        data-testid="decline"
      />
      <SectionSeparator />
      <CounterpartyToggle
        title={(
          <Message
            id="Allow counterparties to update their details"
            comment="Label for turning allowing counterparties to update their details setting on or off"
          />
        )}
        onChange={counterpartSelfUpdateAndRemove}
        value={agreement?.config?.counterpartSelfUpdateAndRemove}
        disabled={getIsDisabledState({ acl: 'agreement:update:config:counterpart_self_update_and_remove' })}
        data-testid="self-update-and-remove"
      />
      {showDurationSection && <Divider solid className={style.Divider} />}
    </>
  );
};

export default CounterpartyPreferences;
