// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import {
  DataFieldDescription,
  DataFieldKey,
  DataFieldExternalKey,
  DataFieldName,
  DataFieldPlaceholder,
  DataFieldValue,
} from 'components/fields';

import style from './edit-data-field.module.scss';

export type Props = {
  dataField: DataField,
  updateState: UpdateState,
  updateDataField: DataField => void,
  resetUpdateState: () => void,
  usedExternalKeys: Array<string>,
  children: (onClick: () => void) => React.Node,
};

const EditDataFieldModal = ({
  dataField,
  updateState,
  updateDataField,
  resetUpdateState,
  usedExternalKeys,
  children,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Edit data field"
        comment="Modal title for editing data field."
      />
    )}
    body={(
      <>
        <div className={style.Row}>
          <DataFieldName autoFocus />
          <DataFieldKey />
        </div>
        <div className={style.Row}>
          <DataFieldDescription />
          <DataFieldPlaceholder />
        </div>
        <div className={style.Row}>
          <DataFieldValue />
          <DataFieldExternalKey usedExternalKeys={usedExternalKeys} />
        </div>
      </>
    )}
    initialValues={{
      name: dataField.name,
      key: dataField.key,
      description: dataField.description,
      placeholder: dataField.placeholder,
      value: dataField.value,
      externalKey: dataField.externalKey,
    }}
    onSubmit={updateDataField}
    resetFormState={resetUpdateState}
    formState={updateState}
    isWideModal
    modalKey="edit data field in template groups modal"
  >
    {children}
  </ModalForm>
);

export default EditDataFieldModal;
