import { Message } from '@oneflowab/pomes';

type Props = {
  timePeriod?: string,
};

const TimePeriodFormatter = ({ timePeriod }: Props) => {
  const scalar = parseInt(timePeriod?.substr(0, timePeriod.length - 1) || 0, 10);
  const unit = timePeriod?.substr(-1);

  if (unit === 'd') {
    return (
      <Message
        id="{count} day"
        pluralId="{count} days"
        pluralCondition="count"
        values={{
          count: scalar,
        }}
        comment="This text will explain the number of days"
      />
    );
  }

  if (unit === 'w') {
    return (
      <Message
        id="{count} week"
        pluralId="{count} weeks"
        pluralCondition="count"
        values={{
          count: scalar,
        }}
        comment="This text will explain the number of weeks"
      />
    );
  }

  if (unit === 'm') {
    return (
      <Message
        id="{count} month"
        pluralId="{count} months"
        pluralCondition="count"
        values={{
          count: scalar,
        }}
        comment="This text will explain the number of months"
      />
    );
  }

  if (unit === 'y') {
    return (
      <Message
        id="{count} year"
        pluralId="{count} years"
        pluralCondition="count"
        values={{
          count: scalar,
        }}
        comment="This text will explain the number of years"
      />
    );
  }

  return null;
};

export default TimePeriodFormatter;
