// @flow
import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import { Form } from 'react-final-form';

import { urlValidator } from 'forms/validators';
import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  targetExternalUrl: string,
  targetExternalUrlTitle: string,
  setTargetExternalUrl: () => void,
  setTargetExternalUrlTitle: () => void,
};

export const ContractExternalLinkSelector = ({
  message,
  targetExternalUrl,
  targetExternalUrlTitle,
  setTargetExternalUrl,
  setTargetExternalUrlTitle,
}: Props) => (
  <div>
    <Form
      onSubmit={() => {}}
      initialValues={{
        externalLinkUrl: targetExternalUrl,
        externalLinkUrlTitle: targetExternalUrlTitle,
      }}
      render={(formProps) => (
        <form>
          <Field
            name="externalLinkUrlTitle"
            label={String(message({
              id: 'Title',
              comment: 'Label for the URL field in the external link modal.',
            }))}
            component={TextField}
            placeholder={message({
              id: 'Enter title',
              comment: 'Placeholder for the URL field in the external link modal.',
            })}
            maxLength={130}
            required
            onChange={(event) => {
              setTargetExternalUrlTitle(event.target.value);
              formProps.form.change('externalLinkUrlTitle', event.target.value);
            }}
          />
          <Field
            name="externalLinkUrl"
            label={String(message({
              id: 'Link',
              comment: 'Label for the URL field in the external link modal.',
            }))}
            component={TextField}
            placeholder="https://example.com"
            maxLength={1024}
            required
            onChange={(event) => {
              setTargetExternalUrl(event.target.value);
              formProps.form.change('externalLinkUrl', event.target.value);
            }}
            validate={
              urlValidator({
                message,
                field: message({
                  id: 'Link',
                  comment: 'Used in the validation of the URL field in the external link modal.',
                }),
              })
            }
          />
        </form>
      )}
    />
  </div>
);

export default localize<Props>(ContractExternalLinkSelector);
