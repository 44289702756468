// @flow

import * as React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { Message } from '@oneflowab/pomes';

import {
  hasSMSDeliveryFailed,
  hasEmailDeliveryFailed,
  hasAnyDeliverySuccess,
} from 'agreement/participant';
import hasDeliveryChannelSameDevice from 'agreement/participant/has-delivery-channel-same-device';

import { isMarkedAsDeclined, isMarkedAsSigned } from 'agreement/agreement';

import { getParticipantSignOrderBlockIndex } from 'agreement/pending-state-flow';

import { EventLog } from './event-log';

type Props = {
  contractPublishTime: string,
  participant: AgreementParticipant,
  dateFormat: string,
};

export const deliveryStates = [
  {
    status: (
      <Message
        id="SMS delivery failed"
        comment="Delivery status when having sent a contract to a participant. Seen in a tooltip in the contract list"
      />
    ),
    condition: hasSMSDeliveryFailed,
  },
  {
    status: (
      <Message
        id="Email delivery failed"
        comment="Delivery status when having sent a contract to a participant. Seen in a tooltip in the contract list"
      />
    ),
    condition: hasEmailDeliveryFailed,
  },
  {
    status: (
      <Message
        id="Same device delivery"
        comment="Delivery status when having sent a contract to a participant. Seen in a tooltip in the contract list"
      />
    ),
    condition: hasDeliveryChannelSameDevice,
  },
];

export const getDeliveryInfo = (participant: any) => (
  get(
    deliveryStates.find((status) => status.condition(participant)),
    'status',
  )
);

const getPublishedTime = (contractPublishTime, participant) => {
  if (contractPublishTime
    && moment(participant.publishedAt).isBefore(moment(contractPublishTime))) {
    return contractPublishTime;
  }

  return participant.publishedAt;
};

const DeliveryInfo = ({
  contractPublishTime,
  participant,
  dateFormat,
}: Props) => {
  const signOrder = getParticipantSignOrderBlockIndex(participant.agreement, participant.id);
  const publishedTime = getPublishedTime(contractPublishTime, participant);

  if (signOrder === null && !hasAnyDeliverySuccess(participant)) {
    return (
      <Message
        id="Not delivered"
        comment="Delivery status when having sent a contract to a participant. Seen in a tooltip in the contract list"
      />
    );
  }

  if (hasAnyDeliverySuccess(participant) && publishedTime) {
    if (isMarkedAsSigned(participant.agreement) || isMarkedAsDeclined(participant.agreement)) {
      return null;
    }

    return (
      <EventLog
        label={(
          <Message
            id="Sent"
            comment="Delivery status when having sent a contract to a participant. Seen in a tooltip in the contract list"
          />
        )}
        dateString={publishedTime}
        dateFormat={dateFormat}
      />
    );
  }

  return (
    <EventLog
      label={getDeliveryInfo(participant)}
      timestamp={participant.deliveryChannelStatusTimestampTs}
      dateFormat={dateFormat}
    />
  );
};

export default DeliveryInfo;
