// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import extensionSettings from 'hocs/extension-settings';

import { Table } from 'components/table';
import ActionsMenu from 'components/actions-menu';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { EditMenuItem } from 'components/menu-items/edit';
import Button from 'components/button';
import Add from 'components/icons/add';
import Tooltip from 'components/tooltip';
import AddWebhook from 'components/modals/add-webhook';
import { DeleteWebhook } from 'components/modals/delete-webhook';
import { EditWebhookModal } from 'components/modals/edit-webhook';
import { ExtensionName } from 'components/extension/extension-name';
import { LocalizedDateTime } from 'components/localized-date-time';

import style from './webhooks.module.scss';

export type Props = {
  queryWebhooks: () => void,
  webhooks: Array<Webhook>,
  webhooksQuery: Query,
  message: MessageTranslator,
  extensionsById: {
    [?number]: Extension,
  },
};

const WEBHOOK_TYPE_EMAIL = 'email';

export class WebhooksPage extends React.Component<Props> {
  componentDidMount() {
    const { queryWebhooks } = this.props;

    queryWebhooks();
  }

  getTarget = (webhook: Webhook) => {
    if (webhook.type === WEBHOOK_TYPE_EMAIL) {
      return webhook.email;
    }

    return webhook.url;
  };

  getWebhookInfo = (webhook: Webhook) => (
    <div className={style.Webhook}>
      {this.getTarget(webhook)}
    </div>
  );

  getUsedByLink = (webhook: Webhook) => {
    const { extensionsById } = this.props;
    const extensionUsingWebhook = extensionsById[webhook.usedBy];

    if (!extensionUsingWebhook) {
      return '-';
    }

    return (
      <ExtensionName extension={extensionUsingWebhook} isLinked />
    );
  };

  getWebhookFormat = (webhook: Webhook) => {
    if (webhook.imwProxy) {
      return (
        <Message
          id="Current"
          comment="Text in the webhook format column that shows when a webhook is using the new format."
        />
      );
    }

    return (
      <Message
        id="Legacy"
        comment="Text in the webhook format column that shows when a webhook is using the old format."
      />
    );
  };

  getActiveState = (webhook: Webhook) => {
    if (webhook.active) {
      return (
        <Message
          id="Active"
          comment="Text explaining that the webhook is active."
        />
      );
    }

    return (
      <Message
        id="Inactive"
        comment="Text explaining that the webhook is inactive."
      />
    );
  };

  getCreatedDate = (webhook: Webhook) => (
    <LocalizedDateTime datetime={webhook.createdTime} />
  );

  getEditMenuItem = (onClick: () => void) => (
    <EditMenuItem
      onClick={onClick}
    />
  );

  getDeleteMenuItem = (onClick: () => void) => (
    <DeleteMenuItem
      onClick={onClick}
    />
  );

  getActions = (webhook: Webhook) => {
    const { message } = this.props;

    if (!webhook.usedBy) {
      return (
        <ActionsMenu
          actions={[
            <EditWebhookModal webhook={webhook}>
              {this.getEditMenuItem}
            </EditWebhookModal>,
            <DeleteWebhook webhook={webhook}>
              {this.getDeleteMenuItem}
            </DeleteWebhook>,
          ]}
          focusOnCloseDisabled
        />
      );
    }

    return (
      <>
        <Tooltip
          message={message({
            id: 'Not allowed to modify webhooks created from other extensions',
            comment: 'Tooltip showing reason for not being allowed to edit webhook.',
          })}
          side="top"
        >
          <ActionsMenu
            actions={[]}
            disabled
            focusOnCloseDisabled
          />
        </Tooltip>
      </>
    );
  };

  getTableConfig() {
    const { message, webhooks } = this.props;

    const webhookInfoColumn = {
      name: 'info',
      label: message({
        id: 'Target',
        comment: 'Column label in webhooks list',
      }),
      type: 'cell',
      value: this.getWebhookInfo,
    };
    const activeColumn = {
      name: 'active',
      label: message({
        id: 'Active',
        comment: 'Column label in webhooks list',
      }),
      type: 'cell',
      value: this.getActiveState,
    };
    const formatColumn = {
      name: 'format',
      label: message({
        id: 'Format',
        comment: 'Column label in webhooks list',
      }),
      type: 'cell',
      value: this.getWebhookFormat,
    };
    const usedByColumn = {
      name: 'usedBy',
      label: message({
        id: 'Used by',
        comment: 'Column label in webhooks list',
      }),
      type: 'cell',
      value: this.getUsedByLink,
    };
    const createdColumn = {
      name: 'createdTime',
      label: message({
        id: 'Created',
        comment: 'Column label in api token list',
      }),
      type: 'cell',
      value: this.getCreatedDate,
    };
    const actionsColumn = {
      name: 'actions',
      label: message({
        id: 'Actions',
        comment: 'Column label in webhooks list',
      }),
      type: 'actions',
      value: this.getActions,
    };

    return {
      items: webhooks,
      itemKey: 'id',
      actions: [],
      columns: [
        webhookInfoColumn,
        activeColumn,
        formatColumn,
        usedByColumn,
        createdColumn,
        actionsColumn,
      ],
    };
  }

  getCreateWebhookButton = (onClick: () => void) => (
    <Button
      data-testid="add-webhook"
      icon={Add}
      kind="primary"
      onClick={onClick}
    >
      <Message
        id="Create webhook"
        comment="Button text for adding webhook on webhooks list page."
      />
    </Button>
  );

  render() {
    const { webhooksQuery } = this.props;

    return (
      <div className={style.PageContainer}>
        <div>
          <AddWebhook>
            {this.getCreateWebhookButton}
          </AddWebhook>
        </div>
        <Table
          config={this.getTableConfig()}
          query={webhooksQuery}
        />
      </div>
    );
  }
}

export default extensionSettings(WebhooksPage);
