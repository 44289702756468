import { Message } from '@oneflowab/pomes';

import Modal from 'components/modal';
import Button from 'components/button';
import WarningIcon from 'components/icons/warning';

import type { SkippedDocumentData } from '../move-contract-step/types';
import style from './move-contract-warnings-step.module.scss';

type Props = {
  onClose: () => void,
  skippedDocuments: SkippedDocumentData[],
};

export const MoveContractWarningsStep = ({
  onClose,
  skippedDocuments,
}: Props) => {
  const documentsWithoutMovePermission = skippedDocuments.filter((document) => (
    document.type === 'withoutMovePermission'
  ));

  const documentsThatAreAlreadyInTheTargetFolder = skippedDocuments.filter((document) => (
    document.type === 'alreadyInTargetFolder'
  ));

  return (
    <Modal
      header={(
        <Message
          id="Some documents couldn't be moved"
          comment="Header for the move contract modal"
        />
      )}
      modalKey="move-contract-warnings"
      onCancel={onClose}
    >
      <Modal.Body>
        <div className={style.Content}>
          <WarningIcon width="74px" height="74px" className={style.WarningIcon} />
          <h2 className={style.Title}>
            <Message
              id="{count} document couldn't be moved"
              pluralId="{count} documents couldn't be moved"
              pluralCondition="count"
              comment="Header for the move contract modal"
              values={{ count: skippedDocuments.length }}
            />
          </h2>
          {documentsThatAreAlreadyInTheTargetFolder.length <= 0 ? (
            <p>
              <Message
                id="Contact admin for permissions to move these documents"
                comment="Header for the move contract modal"
              />
            </p>
          ) : (
            <>
              {documentsWithoutMovePermission.length > 0 && (
                <p>
                  <Message
                    id="{count} document requires admin permission to move"
                    pluralId="{count} documents require admin permission to move"
                    pluralCondition="count"
                    comment="Header for the move contract modal"
                    values={{ count: documentsWithoutMovePermission.length }}
                  />
                </p>
              )}

              {documentsThatAreAlreadyInTheTargetFolder.length > 0 && (
                <p>
                  <Message
                    id="{count} document is already in the selected folder"
                    pluralId="{count} documents are already in the selected folder"
                    pluralCondition="count"
                    comment="Header for the move contract modal"
                    values={{ count: documentsThatAreAlreadyInTheTargetFolder.length }}
                  />
                </p>
              )}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Actions>
        <Button
          kind="primary"
          onClick={onClose}
        >
          <Message id="Ok" comment="Button label to close the modal" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
