import {
  Editor,
  Path,
  Range,
  Text,
} from 'slate';
import type { EditorInterface } from 'slate';

// eslint-disable-next-line import/prefer-default-export
export const convertEditorSelectionToString: EditorInterface['string'] = (editor, at, options = {}) => {
  const { voids = false } = options;
  const range = Editor.range(editor, at);
  const [start, end] = Range.edges(range);
  let text = '';

  // eslint-disable-next-line no-restricted-syntax
  for (const [_, path] of Editor.nodes(editor, {
    at: range,
    match: Text.isText,
    voids,
  })) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [child, childPath] of Editor.nodes(editor, {
      at: path,
      match: Text.isText,
      voids,
    })) {
      let { text: _text } = child;

      if (Path.equals(childPath, end.path)) {
        _text = _text.slice(0, end.offset);
      }

      if (Path.equals(childPath, start.path)) {
        _text = _text.slice(start.offset);
      }
      text += _text;
    }
    text += '\n';
  }

  return text;
};
