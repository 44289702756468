import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import bulkOperations, { ACTION_TYPE_REMOVE } from 'reducers/entities/bulk-operations';
import agreementsReducer from 'reducers/entities/agreements';
import useFeatureFlag from 'hooks/use-feature-flag';

// eslint-disable-next-line import/named
import { RemoveConfirm } from 'components/modals/remove-confirm';
import { useSelectionContext } from 'components/contract-list/selection-context';
import { MoveToTrashButton } from 'components/modals/remove-confirm/button';
import { CancelButton, DeleteButton } from 'components/buttons';
import Tooltip from 'components/tooltip';
import toast from 'components/toasts';
import DeleteThinIcon from 'components/icons/delete-thin';

import { get } from 'lodash';
import type { SkippedDocument } from './types';

const hasDocumentDeletePermission = (document: Oneflow.Agreement) => (
  document.acl?.['agreement:remove'] === 'allow'
);

type Props = {
  queryName: string,
  onClose: () => void,
  setSkippedDocuments: (documents: SkippedDocument[]) => void,
  onStepComplete: () => void,
};

const MODAL_KEY = 'delete contract modal';

export const DeleteContractStep = ({
  queryName,
  onClose,
  setSkippedDocuments,
  onStepComplete,
}: Props) => {
  const dispatch = useDispatch();
  const { selectedDocuments, setSelectedDocuments } = useSelectionContext();
  const removeState = useSelector((state) => bulkOperations.getCreateSelector(state));
  const resetRemoveState = () => {
    dispatch(bulkOperations.createBulkOperationReset());
  };
  const isTemporaryTrashFeatureEnabled = useFeatureFlag('temporaryDocumentTrashCan');

  const selectedDocumentsData = useSelector((state) => (
    agreementsReducer.getAgreementsSelector(state, {
      ids: Object.entries(selectedDocuments)
        .filter(([_, value]) => value)
        .map(([key]) => Number(key)),
    })
  ));

  const documentsToDelete = selectedDocumentsData
    .filter((document) => hasDocumentDeletePermission(document));

  const deleteContracts = () => {
    const action = () => agreementsReducer.queryAgreementsReload({
      name: queryName,
    });

    const skippedDocuments = selectedDocumentsData
      .filter((document) => !hasDocumentDeletePermission(document))
      .map((document) => ({
        id: document.id,
      }));

    setSkippedDocuments(skippedDocuments);

    dispatch(bulkOperations.createBulkOperation({
      data: {
        async: false,
        actions: documentsToDelete
          .map((document) => ({
            actionType: ACTION_TYPE_REMOVE,
            actionParams: {
              agreementId: document.id,
            },
          } as Parameters<typeof bulkOperations.createBulkOperation>[0]['data']['actions'][number])),
      },
      pipe: {
        action,
        onSuccess: () => {
          setSelectedDocuments((_selectedDocuments) => (
            Array
              .from(Object.keys(_selectedDocuments))
              .reduce((acc, agreementId) => {
                if (documentsToDelete.some((document) => document.id === Number(agreementId))) {
                  acc[Number(agreementId)] = false;
                } else {
                  acc[Number(agreementId)] = _selectedDocuments[Number(agreementId)];
                }
                return acc;
              }, {} as Record<number, boolean>)
          ));
          if (skippedDocuments.length > 0) {
            onStepComplete();
          } else {
            onClose();
            toast.success({
              id: 'contracts-deleted',
              title: isTemporaryTrashFeatureEnabled ? (
                <Message
                  id="{count} document moved to trash"
                  pluralId="{count} documents moved to trash"
                  comment="Message for the contracts deleted toast."
                  pluralCondition="count"
                  values={{
                    count: documentsToDelete.length,
                  }}
                />
              ) : (
                <Message
                  id="{count} document deleted"
                  pluralId="{count} documents deleted"
                  comment="Message for the contracts deleted toast."
                  pluralCondition="count"
                  values={{
                    count: documentsToDelete.length,
                  }}
                />
              ),
              duration: 8000,
            });
          }
        },
      },
    }));
  };

  const getHeader = () => (isTemporaryTrashFeatureEnabled ? (
    <Message id="Move to trash" comment="Modal title when moving a document to trash." />
  ) : (
    <Message id="Delete document" comment="Modal title when deleting a document." />
  ));

  const confirmMessage = isTemporaryTrashFeatureEnabled ? (
    <Message
      id="{count} documents will be moved to trash."
      values={{
        count: Object.values(selectedDocuments).filter((isSelected) => isSelected).length,
      }}
      comment="Warning message in modal when attempting to remove a contract"
    />
  ) : (
    <>
      <p>
        <Message
          id="{count} documents will be deleted"
          values={{
            count: Object.values(selectedDocuments).filter((isSelected) => isSelected).length,
          }}
          comment="Warning message in modal when attempting to remove a contract"
        />
      </p>
      <p>
        <Message
          id="This action cannot be undone."
          comment="Warning message in modal when attempting to remove a contract"
        />
      </p>
      <Message
        id="Delete the document?"
        comment="Warning message in modal when attempting to remove a contract"
      />
    </>
  );

  const getCustomErrorMessage = () => {
    const apiErrorCode = get(removeState, 'error.body.status_code');

    if (apiErrorCode === 404) {
      return {
        bodyText: (
          <Message
            id="Document not found."
            comment="Error message when document not found."
          />
        ),
      };
    }

    return undefined;
  };

  return (
    <RemoveConfirm
      customErrorMessage={getCustomErrorMessage()}
      onConfirm={deleteContracts}
      confirmState={removeState}
      isOpen
      resetConfirmState={resetRemoveState}
      onClose={onClose}
      header={getHeader()}
      icon={isTemporaryTrashFeatureEnabled ? <DeleteThinIcon style={{ marginBottom: '33px', marginTop: '41px' }} height="74px" /> : undefined}
      renderButton={() => (
        <>
          <CancelButton
            onClick={onClose}
            modalKey={MODAL_KEY}
          />
          <Tooltip
            theme="oneflow"
            side="top"
            message={(
              <Message
                id="You don't have permission to delete this document."
                pluralId="You don't have permission to delete these documents."
                values={{
                  count: selectedDocumentsData.length,
                }}
                pluralCondition="count"
                comment="Tooltip message when you don't have permission to delete a document."
              />
            )}
            hideContent={documentsToDelete.length > 0}
            // (ModalOverlay's z-index) + 1
            zIndex="10002"
            sideOffset={4}
          >
            {isTemporaryTrashFeatureEnabled ? (
              <MoveToTrashButton
                isLoading={removeState?.loading}
                disabled={removeState?.loading || documentsToDelete.length === 0}
                onClick={deleteContracts}
              />
            ) : (
              <DeleteButton
                isLoading={removeState?.loading}
                disabled={removeState?.loading || documentsToDelete.length === 0}
                onClick={deleteContracts}
              />
            )}
          </Tooltip>
        </>
      )}
      confirmMessage={confirmMessage}
      modalKey={MODAL_KEY}
    />
  );
};
