import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { localize } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import type { MessageTranslator } from '@oneflowab/pomes';

import {
  updateBoxAction,
  updateBoxConfigAction,
} from 'reducers/current-contract';
import { useProductTableBoxContext } from 'contexts/product-table-box-context';
import type { ConfigUpdateData } from 'data-validators/entity-schemas/document-box/product-table';

import { InlineEditablePropsProvider } from 'components/inline-editable';
import { ProductTableBoxName } from 'components/contract-boxes/product-table-box/product-table-box-name';
import BoxWrapper from 'components/contract-boxes/box-wrapper';
import EmptyProductTableBox from 'components/contract-boxes/product-table-box/empty-product-table-box';
import Message from 'components/message';
import ProductTable from 'components/contract-boxes/product-table-box/product-table';
import ProductTableBoxActions from 'components/contract-boxes/product-table-box/product-table-actions';

import {
  produceProductData,
  produceUpdatedConfig,
} from './product-table-box-helpers';
import style from './product-table-box.module.scss';

type Props = {
  message: MessageTranslator,
  onAddSectionRules: () => void,
  onRemoveBox: () => void,
};

const ProductTableBoxContainerComponent = ({
  message,
  onAddSectionRules,
  onRemoveBox,
}: Props) => {
  const dispatch = useDispatch();
  const {
    box,
    boxId,
    config,
    data,
    isAllowedToAddBoxData,
    isAllowedToEditBoxData,
  } = useProductTableBoxContext();

  const addNewProduct = () => {
    const defaultProductName = message({
      id: 'Untitled',
      comment: 'Default product name',
    });

    const { updatedBox } = produceProductData(box, defaultProductName);

    dispatch(updateBoxAction(updatedBox));
  };

  const updateProductConfig = useCallback((configData: ConfigUpdateData) => {
    const updatedConfig = produceUpdatedConfig(config, configData);

    dispatch(updateBoxConfigAction(boxId, updatedConfig));
  }, [boxId, config, dispatch]);

  const isProductTableEmpty = isEmpty(data);

  const renderProductTable = () => {
    if (isProductTableEmpty) {
      return (
        <EmptyProductTableBox
          onClick={addNewProduct}
          isAllowedToEdit={isAllowedToAddBoxData}
          buttonText={(
            <Message
              id="Add product"
              comment="Action to add product to the product table."
            />
          )}
        />
      );
    }

    return (
      <ProductTable
        addNewProduct={addNewProduct}
        updateProductConfig={updateProductConfig}
      />
    );
  };

  return (
    <BoxWrapper
      boxId={boxId}
      isAllowedToEdit={isAllowedToEditBoxData}
      nonFixedActions={[
        <ProductTableBoxActions
          key={`product-table-box-id-${boxId}`}
          message={message}
          updateProductConfig={updateProductConfig}
        />,
      ]}
      onAddSectionRules={onAddSectionRules}
      onRemoveBox={onRemoveBox}
    >
      <div className={style.ProductTableWrapper}>
        {config.header.enabled && (
          <InlineEditablePropsProvider>
            <ProductTableBoxName
              message={message}
              updateProductConfig={updateProductConfig}
            />
          </InlineEditablePropsProvider>
        )}
        {renderProductTable()}
      </div>
    </BoxWrapper>
  );
};

const ProductTableBoxContainer = localize(ProductTableBoxContainerComponent);

export {
  ProductTableBoxContainer,
  ProductTableBoxContainerComponent,
};
