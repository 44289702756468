// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import { get } from 'lodash';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

import style from './delete-workspace.module.scss';

export type Props = {
  workspace: Workspace,
  removeState: RemoveState,
  resetRemoveState: () => void,
  removeWorkspace: () => void,
  children: Function,
};

export class DeleteWorkspace extends React.PureComponent<Props> {
  handleConfirm = () => {
    const { removeState, removeWorkspace } = this.props;
    if (removeState.loading) {
      return;
    }

    removeWorkspace();
  }

  renderBody() {
    const { workspace } = this.props;

    return (
      <>
        <div className={style.BodyParagraph}>
          <Message
            id="Are you sure you want to delete the {name} workspace? This action can not be undone."
            values={{
              name: <Badge label={workspace.name} kind="name" />,
            }}
            comment="Modal text for deleting workspace."
          />
        </div>
        <div className={style.BodyParagraphRed}>
          <Message
            id="All address book contacts and message templates in this workspace will also be deleted."
            comment="Modal text for deleting workspace."
          />
        </div>
      </>
    );
  }

  render() {
    const {
      removeState,
      children,
      resetRemoveState,
    } = this.props;

    const getCustomErrorMessage = () => {
      const apiErrorCode = get(removeState?.error, 'body.api_error_code');

      // Backend decided to return 102 (conflict) for the following case.
      // Since we don't return a conflict message for this case I haven't added it to constants.
      if (apiErrorCode !== 102) {
        return undefined;
      }

      return {
        bodyText: (
          <Message
            id="Can not delete workspace, make sure it’s empty and not your only workspace."
            comment="Error text when trying to delete workspaces"
          />
        ),
      };
    };

    return (
      <RemoveConfirm
        onConfirm={this.handleConfirm}
        confirmState={removeState}
        resetConfirmState={resetRemoveState}
        confirmMessage={this.renderBody()}
        modalKey="delete workspace modal"
        customErrorMessage={getCustomErrorMessage()}
      >
        {children}
      </RemoveConfirm>
    );
  }
}
