// @flow
import type { MessageTranslator } from '@oneflowab/pomes';
import { SYSTEM_TAG_KEY } from 'tags/constants';

export const isSystemImportTag = (tag: Tag) => (
  tag.name === SYSTEM_TAG_KEY
);

export const getTagName = (tag: Tag, message: MessageTranslator) => {
  if (isSystemImportTag(tag)) {
    return message({ id: 'Import', comment: 'Used as a text for tag name' });
  }

  return tag.name;
};
