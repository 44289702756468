// @flow

import { connect } from 'react-redux';
import contactsReducer from 'reducers/entities/contacts';

import { RemoveContact } from './remove-contact';

type OwnProps = {
  contact: Contact,
  queryName: string,
};

export const mapStateToProps = (state: State, { contact }: OwnProps) => ({
  removeState: contactsReducer.getRemoveSelector(state, { id: contact.id }),
});

export const mapDispatchToProps = (dispatch: any, { contact, queryName }: OwnProps) => ({
  removeContact: () => {
    dispatch(contactsReducer.removeContact({
      id: contact.id,
      data: {
        ids: [contact.id],
      },
      pipe: {
        action: () => contactsReducer.queryContactsReload({
          name: queryName,
        }),
        wait: contactsReducer.QUERY_CONTACTS_SUCCESS,
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(contactsReducer.removeContactReset({
      id: contact.id,
    }));
  },
});

const connectedRemoveContact = connect<any, State, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveContact);

export { connectedRemoveContact as RemoveContact };
