// @flow

import React from 'react';
import { useFormState } from 'react-final-form';
import { components } from 'react-select';
import clsx from 'clsx';

import { getTranslationForDateFormat } from 'date';

import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { useUserEvents } from 'contexts/user-events';

import Field from 'components/field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import TextArea from 'components/text-area';
import { SingleDatePickerComponent } from 'components/single-date-picker';

import ViewableContract from './viewable-contract';
// eslint-disable-next-line import/no-named-as-default
import RecipientsSelectField from './recipients-select-field';

import style from './internal-reminder-body.module.scss';

export type Props = {
  message: MessageTranslator,
};

const TimeOption = ({ isDisabled, ...restProps }: any) => (
  <components.Option
    className={clsx({ [style.DisabledOption]: isDisabled })}
    {...restProps}
  />
);

function InternalReminderReadBody({
  message,
}: Props) {
  const { values } = useFormState();
  const {
    dateFormat,
    timeValues,
  } = useUserEvents();

  return (
    <>
      <div className={clsx(style.Row, style.ContractSelection)}>
        <ViewableContract contract={values?.contractFieldOption?.contract} />
      </div>
      <fieldset
        disabled
        className={clsx(style.FormFieldset, style.ReadOnly)}
      >
        <RecipientsSelectField.ReadOnly />
        {/* To match other modals (create & edit) spacing */}
        <div className={style.RecipientsValidationMessage} />
        <div className={clsx(style.Row, style.DatePickerField)}>
          <Field
            label={message({
              id: 'Date',
              comment: 'Label for date-selection in custom reminder modal',
            })}
            name="date"
            component={SingleDatePickerComponent}
            initialVisibleMonth={null}
            placeholder={getTranslationForDateFormat({ message, dateFormat })}
            displayFormat={dateFormat}
            disabled
            customClassName={style.OverrideSingleDatePicker}
            block={false}
            noCloseIcon
          />
          <Field
            label={message({
              id: 'Time',
              comment: 'Label for time-selection in custom reminder modal',
            })}
            name="timeFieldOption"
            labelKey="time"
            valueKey="time"
            placeholder={message({
              id: 'HH:MM',
              comment: 'Placeholder used in the time field in custom reminder modal',
            })}
            options={timeValues}
            isOptionDisabled={(option) => option.isDisabled}
            component={SelectField}
            components={{ Option: TimeOption }}
            disabled
            noDropDownIndicator
          />
        </div>
        <div className={clsx(style.Row, style.SubjectField)}>
          <Field
            label={message({
              id: 'Subject',
              comment: 'Label for subject-field in custom reminder modal',
            })}
            name="subject"
            placeholder={message({
              id: 'Enter subject',
              comment: 'Placeholder used in the subject field in custom reminder modal',
            })}
            component={TextField}
            maxLength={150}
            customClass={style.TextFieldLabel}
            disabled
          />
        </div>
        <div className={clsx(style.Row, style.MessageField)}>
          <Field
            label={message({
              id: 'Message',
              comment: 'Label for message-field in custom reminder modal',
            })}
            name="message"
            placeholder={message({
              id: 'Enter message',
              comment: 'Placeholder used in the message field in custom reminder modal',
            })}
            component={TextArea}
            maxLength={4500}
            minRows={5}
            maxRows={10}
            customClass={style.TextArea}
            disabled
          />
        </div>
      </fieldset>
    </>
  );
}

export default localize<Props>(InternalReminderReadBody);
