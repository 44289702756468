/* eslint-disable no-param-reassign */
/* eslint-disable import/named */
import {
  useRef,
  useCallback,
  useState,
  memo,
} from 'react';
import clsx from 'clsx';
import { isEmpty, noop } from 'lodash';

import { useDispatch } from 'react-redux';

import type React from 'react';

import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import type { OverlayAccessTypes, Formatting } from 'types/overlay';

import useCurrentBox from 'hooks/use-current-box';
import { useBoxPermissions } from 'hooks/use-permissions';

import { updateBoxAction, updateBoxDataItemAction } from 'reducers/current-contract';
import type { DataField } from 'data-validators/entity-schemas/agreement-data';
import type { PDFBox, PdfContentData, PDFOverlayFieldData } from 'data-validators/entity-schemas/document-box/pdf-box';

import Message from 'components/message';
import Tooltip from 'components/tooltip';
import LockIcon from 'components/icons/lock';
import { OVERLAY_FIELD } from 'components/contract-boxes/constants';

import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverPortal,
} from 'components/popover';
import type { DismissableLayerProps } from '@radix-ui/react-popover';

import Settings from '../settings';
import { OverlayFieldFormatting } from './overlay-field-formatting';
import DataFieldSelector from './data-field-selector';

import {
  getNextOverlayField,
  getPreviousOverlayField,
  getFocusableElementsWithinPopover,
} from './keyboard-navigation-helpers';
import {
  DEFAULT_OVERLAY_FIELD_CONTAINER_Z_INDEX,
  OPEN_OVERLAY_FIELD_CONTAINER_Z_INDEX,
} from '../values';
import useControlledField from './use-controlled-field';

import style from './overlay-text-field.module.scss';
import DeleteButton from '../delete-button';

const renderTooltipMessage = (isRequired: boolean, isValueEmpty: boolean) => {
  if (isRequired && isValueEmpty) {
    return (
      <Message
        id="This field is locked, you can not sign until it is filled."
        comment="A message to inform the user that the field is locked and required, and the document can not be signed until it is filled."
      />
    );
  }

  return (
    <Message
      id="This field is locked, you can not edit it."
      comment="A message to inform the user that the field is locked and can not be edited."
    />
  );
};

type Props = {
  agreement: Oneflow.Agreement,
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
  overlayFieldContainerRef: React.RefObject<HTMLDivElement>,
  boxId: number,
  dataFieldObject: DataField,
  overlayFieldContentDataId: number,
  overlayFieldValueId?: string, // Newly added overlayField will not have id prop
  pageNumber: number,
  topPosition: number,
  readOnly: boolean,
  canRemoveOverlayField: boolean,
  isRequired: boolean,
  isConcluded: boolean,
  dataFields: Array<DataField>,
  overlayFieldValue: PDFOverlayFieldData['value'],
  onFocus: () => void,
  onBlur: () => void,
  message: MessageTranslator,
  focused: boolean,
};

type PointerDownOutside = Exclude<DismissableLayerProps['onPointerDownOutside'], undefined>;
type PointerDownOutsideEvent = Parameters<PointerDownOutside>[0]
type PopoverKeyboardEvent = React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>;
type ClosePopoverEventHandler<
  E = PointerDownOutsideEvent | PopoverKeyboardEvent
> = (
  event: E
) => void

export const OverlayTextFieldComponent = ({
  agreement,
  textAreaRef,
  overlayFieldContainerRef,
  boxId,
  dataFieldObject,
  overlayFieldContentDataId,
  overlayFieldValueId,
  pageNumber,
  topPosition,
  readOnly,
  canRemoveOverlayField,
  isRequired,
  isConcluded,
  dataFields,
  overlayFieldValue,
  onFocus,
  onBlur,
  message,
  focused,
}: Props) => {
  const dispatch = useDispatch();
  const isBroken = isEmpty(dataFieldObject);
  const pdfBox = useCurrentBox(boxId) as ContractView.PDFBox;
  const {
    counterpartEdit: boxCounterpartEdit,
    colleagueEdit: boxColleagueEdit,
    managerLock,
  } = pdfBox.config;
  const permissions = useBoxPermissions();
  const canAlterOverlayField = permissions.update;
  const {
    colleagueEdit: overlayFieldColleagueEdit,
    counterpartEdit: overlayFieldCounterpartEdit,
  } = overlayFieldValue.permissions;
  const showLockIcon = (Boolean(managerLock) && overlayFieldColleagueEdit === 'deny') || overlayFieldCounterpartEdit === 'deny';
  const requiredOrPermissionsChanged = isRequired || showLockIcon;

  const agreementFormatting = agreement?.config?.formatting;
  const overlayFieldFormatting = overlayFieldValue.formatting;
  const overlayFieldStyle = {
    fontFamily: overlayFieldFormatting.font || agreementFormatting?.font,
    color: overlayFieldFormatting.color || agreementFormatting?.color,
    fontSize: overlayFieldFormatting.size || agreementFormatting?.size,
    fontWeight: overlayFieldFormatting.weight,
    fontStyle: overlayFieldFormatting.style,
  };

  const overlayFieldPlaceholder = isBroken
    ? message({ id: 'Press to link or remove', comment: 'Placeholder text for when an overlay field has lost its linked data field.' })
    : message({ id: 'Enter value', comment: 'Placeholder text for overlay fields in PDF section' });

  const [popoverSide, setPopoverSide] = useState<'top' | 'right' | 'bottom' | 'left'>('top');

  const popoverContentRef = useRef<HTMLDivElement>(null);

  const [selectedDataField, setSelectedDataField] = useState(dataFieldObject?.value?.key);

  const { displayValue, onChange } = useControlledField(dataFieldObject);
  const isValueEmpty = displayValue === '';

  const dataFieldValueList = dataFields?.map<DataField['value']>((dataField) => dataField.value);

  const setFocusOnNextField = useCallback(() => {
    if (!textAreaRef.current) {
      return;
    }
    const nextElement = getNextOverlayField(textAreaRef.current) as HTMLTextAreaElement;
    nextElement?.focus();
  }, [textAreaRef]);

  const setFocusOnPreviousField = useCallback(() => {
    if (!textAreaRef.current) {
      return;
    }
    const previousElement = getPreviousOverlayField(textAreaRef.current) as HTMLTextAreaElement;
    previousElement?.focus();
  }, [textAreaRef]);

  const deleteOverlayField = useCallback(() => {
    const updatedBox = {
      ...pdfBox,
      content: {
        ...pdfBox?.content,
        data: pdfBox?.content?.data?.reduce((
          updatedData: PDFBox['content']['data'],
          datum: PdfContentData | PDFOverlayFieldData,
        ) => {
          if ((datum as PdfContentData).key === 'file') {
            (updatedData as PdfContentData[]).push(
              datum as PdfContentData,
            );
            return updatedData;
          }

          const isNewlyAddedOverlayField = Boolean(datum?._id && !datum.id);
          if (isNewlyAddedOverlayField && datum._id === overlayFieldContentDataId) {
            // Remove immediately from the list and
            // Avoid round trip to server and to client
            return updatedData;
          }

          const isExistingOverlayField = (
            !isNewlyAddedOverlayField && datum?.value?.id === overlayFieldValueId
          );

          if (isExistingOverlayField) {
            (updatedData as PDFOverlayFieldData[]).push({
              ...(datum as PDFOverlayFieldData),
              _removed: 1,
            });

            return updatedData;
          }

          (updatedData as PDFOverlayFieldData[]).push(
            datum as PDFOverlayFieldData,
          );

          return updatedData;
        }, []),
      },
    };
    setFocusOnNextField();
    dispatch(updateBoxAction(updatedBox));
  }, [
    pdfBox,
    dispatch,
    setFocusOnNextField,
    overlayFieldContentDataId,
    overlayFieldValueId,
  ]);

  const openToolbar = useCallback(() => {
    if (onFocus) {
      onFocus();
    }

    if (!overlayFieldContainerRef.current) {
      return;
    }

    if (!focused) {
      overlayFieldContainerRef.current.style.zIndex = String(OPEN_OVERLAY_FIELD_CONTAINER_Z_INDEX);
    }
  }, [onFocus, focused, overlayFieldContainerRef]);

  const closeToolbar: ClosePopoverEventHandler = useCallback(
    (event) => {
      if (onBlur) {
        onBlur();
      }

      if (!overlayFieldContainerRef.current) {
        return;
      }

      if (event.currentTarget !== textAreaRef.current) {
        overlayFieldContainerRef.current.style.zIndex = String(
          DEFAULT_OVERLAY_FIELD_CONTAINER_Z_INDEX,
        );
      }
    }, [onBlur, overlayFieldContainerRef, textAreaRef],
  );

  const handleKeyBoardNavigation = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const focusableElements = getFocusableElementsWithinPopover();
    const eventTargetIndex = focusableElements.indexOf(event.target as HTMLElement);

    const isEventTargetFirstCTA = eventTargetIndex === 0;
    const isEventTargetLastCTA = eventTargetIndex === (focusableElements.length - 1);

    if (event.key === 'Tab') {
      if (isEventTargetFirstCTA && event.shiftKey) {
        event.preventDefault();
        queueMicrotask(() => {
          textAreaRef.current?.focus();
        });
        return;
      }

      if (isEventTargetLastCTA && !event.shiftKey) {
        event.preventDefault();
        setFocusOnNextField();
        closeToolbar(event);
      }
    }
  };

  const handleTextAreaKeyBoardNavigation = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const focusableElements = getFocusableElementsWithinPopover();
    const firstFocusableElement = focusableElements[0];

    if (event.key === 'Tab') {
      if (!event.shiftKey) {
        event.preventDefault();
        (firstFocusableElement as HTMLElement)?.focus();
      } else {
        event.preventDefault();
        if (!overlayFieldContainerRef.current) {
          return;
        }
        overlayFieldContainerRef.current.style.zIndex = String(
          DEFAULT_OVERLAY_FIELD_CONTAINER_Z_INDEX,
        );
        closeToolbar(event);
        setFocusOnPreviousField();
      }
    }
  };

  const handlePopoverOverflowCallbackRef = (node: HTMLDivElement) => {
    if (!node) {
      return;
    }

    if (pageNumber === 1 && topPosition < node.clientHeight + 10) {
      setPopoverSide('bottom');
      return;
    }
    setPopoverSide('top');
  };

  const changeDataFieldConnection = useCallback((selectedDataFieldValue: string) => {
    const selectedDataFieldKey = selectedDataFieldValue;
    setSelectedDataField(selectedDataFieldKey);
    dispatch(updateBoxDataItemAction(
      boxId,
      overlayFieldContentDataId,
      {
        value: {
          ...overlayFieldValue,
          valueDataFieldKey: selectedDataFieldKey,
        },
      },
    ));
  }, [dispatch, overlayFieldValue, overlayFieldContentDataId, boxId]);

  const handleAccessChange = useCallback((newPermmissions: {
    counterpartEdit?: OverlayAccessTypes,
    colleagueEdit?: OverlayAccessTypes,
  }) => {
    dispatch(updateBoxDataItemAction(
      boxId,
      overlayFieldContentDataId,
      {
        key: OVERLAY_FIELD,
        value: {
          ...overlayFieldValue,
          permissions: {
            ...overlayFieldValue.permissions,
            ...newPermmissions,
          },
        },
      },
    ));
  }, [dispatch, overlayFieldValue, overlayFieldContentDataId, boxId]);

  const toggleValueRequiredForSigning = useCallback((required: boolean) => {
    dispatch(updateBoxDataItemAction(
      boxId,
      overlayFieldContentDataId,
      {
        value: {
          ...overlayFieldValue,
          required,
        },
      },
    ));
  }, [dispatch, overlayFieldValue, overlayFieldContentDataId, boxId]);

  const changeOverlayFieldFormatting = (newFormatting: Partial<Formatting>) => {
    dispatch(updateBoxDataItemAction(
      boxId,
      overlayFieldContentDataId,
      {
        value: {
          ...overlayFieldValue,
          formatting: {
            ...overlayFieldValue.formatting,
            ...newFormatting,
          },
        },
      },
    ));
  };

  if (readOnly) {
    return (
      !isConcluded ? (
        <Tooltip
          messageClassName={style.TooltipMessage}
          contentClassName={style.TooltipContentContainerInnerWrapper}
          message={(renderTooltipMessage(isRequired, isValueEmpty))}
          side="top"
          sideOffset={-3}
          align="end"
          delayDuration={500}
          zIndex="2"
        >
          <div
            className={style.ReadOnlyContainer}
            role="button"
            tabIndex={0}
          >
            <textarea
              style={overlayFieldStyle}
              className={clsx(style.TextField, style.ReadOnly)}
              name="overlay-text-field"
              aria-label="textarea"
              data-testid="overlay-field-text-field-readonly-not-concluded"
              value={displayValue}
              placeholder={overlayFieldPlaceholder}
              disabled
            />
            <div className={style.IconsContainer}>
              {isRequired && (<div className={style.RequiredMark}>*</div>)}
              <LockIcon className={style.OverlayLockIcon} height="14px" />
            </div>
          </div>
        </Tooltip>
      ) : (
        <div className={style.ReadOnlyContainer}>
          <textarea
            style={overlayFieldStyle}
            className={clsx(style.TextField, style.ReadOnly, {
              [style.Concluded]: isConcluded && !isRequired,
            })}
            name="overlay-text-field"
            aria-label="textarea"
            data-testid="overlay-field-text-field-readonly"
            value={displayValue}
            disabled
          />
        </div>
      )
    );
  }

  // Only allowed to change the value of the overlay field
  // Since readonly check is false above
  if (!canAlterOverlayField) {
    return (
      <div className={style.TextFieldContainer}>
        <textarea
          ref={textAreaRef}
          style={overlayFieldStyle}
          className={clsx(style.TextField, {
            [style.FieldEmpty]: isValueEmpty,
            [style.IsBroken]: isBroken,
          })}
          disabled={isBroken}
          name="overlay-text-field"
          aria-label="textarea"
          data-testid="overlay-field-text-field-only-value-update-allowed"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={displayValue}
          data-value={displayValue}
          required={isRequired}
          placeholder={overlayFieldPlaceholder ?? ''}
          tabIndex={0}
          rows={1}
        />
        {isRequired && (
          <div className={clsx(style.IconsContainer, style.RequiredOnly)}>
            <div className={style.RequiredMark}>*</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <Popover
        open={focused}
      >
        <PopoverAnchor asChild>
          <div className={style.TextFieldContainer}>
            <textarea
              ref={textAreaRef}
              style={overlayFieldStyle}
              className={clsx(style.TextField, {
                [style.FieldEmpty]: isValueEmpty,
                [style.IsBroken]: isBroken,
                [style.EditDenied]: showLockIcon,
              })}
              name="overlay-text-field"
              aria-label="textarea"
              data-testid="overlay-field-text-field"
              onChange={isBroken ? noop : onChange}
              value={displayValue}
              data-value={displayValue}
              onClick={openToolbar}
              // When it become active via keyboard
              onFocus={openToolbar}
              onKeyDown={handleTextAreaKeyBoardNavigation}
              placeholder={overlayFieldPlaceholder ?? ''}
              tabIndex={0}
              rows={1}
              required={isRequired}
            />
            {(requiredOrPermissionsChanged && !isConcluded) && (
              <div className={clsx(style.IconsContainer, {
                [style.RequiredOnly]: isRequired && !showLockIcon,
              })}
              >
                {isRequired && (<div className={style.RequiredMark}>*</div>)}
                {showLockIcon && <LockIcon className={style.OverlayLockIcon} height="14px" />}
              </div>
            )}
          </div>
        </PopoverAnchor>
        <PopoverPortal>
          <PopoverContent
            ref={popoverContentRef}
            side={popoverSide}
            sideOffset={10}
            align="start"
            arrowHeight={0}
            onPointerDownOutside={closeToolbar}
            onOpenAutoFocus={(event) => {
              event.preventDefault();
              textAreaRef.current?.focus();
            }}
            tabIndex={-1}
            className={style.OverlayFieldPopover}
            onKeyDown={handleKeyBoardNavigation}
            hideWhenDetached
          >
            <div
              className={style.OverlayToolbar}
              ref={handlePopoverOverflowCallbackRef}
              tabIndex={-1}
            >
              <DataFieldSelector
                data-overlay-field-popover-cta
                name="connected-data-field"
                options={dataFieldValueList}
                value={selectedDataField}
                dataFieldName={dataFieldObject?.value?.name}
                onValueChange={changeDataFieldConnection}
                isBroken={isBroken}
              />
              {!isBroken && (
                <>
                  <div className={style.VerticalDivider} tabIndex={-1} />
                  <Settings
                    data-overlay-field-popover-cta
                    handleAccessChange={handleAccessChange}
                    counterpartyAccess={overlayFieldValue.permissions.counterpartEdit}
                    colleagueAccess={overlayFieldValue.permissions.colleagueEdit}
                    boxCounterpartEdit={Boolean(boxCounterpartEdit)}
                    boxColleagueEdit={Boolean(boxColleagueEdit)}
                    toggleValueRequiredForSigning={toggleValueRequiredForSigning}
                    valueRequiredForSigning={Boolean(isRequired)}
                    managerLock={Boolean(managerLock)}
                    overlayFieldStyle={overlayFieldStyle}
                    changeOverlayFieldFormatting={changeOverlayFieldFormatting}
                  />
                </>
              )}
              <>
                <div className={style.VerticalDivider} tabIndex={-1} />
                <OverlayFieldFormatting
                  changeOverlayFieldFormatting={changeOverlayFieldFormatting}
                  overlayFieldStyle={overlayFieldStyle}
                  agreementColor={agreementFormatting?.color}
                />
              </>
              {canRemoveOverlayField && (
                <>
                  <div className={style.VerticalDivider} tabIndex={-1} />
                  <DeleteButton onClick={deleteOverlayField} />
                </>
              )}
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </>
  );
};

export default memo(localize(OverlayTextFieldComponent));
