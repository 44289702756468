// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { getWebhookFormatValue } from 'extensions';

import webhooksReducer from 'reducers/entities/webhooks';

import { EditWebhookModal, type Props } from './edit-webhook';

type StateProps = {|
  updateState: UpdateState,
|};

type FormData = {|
  url: string,
  imwProxy: Option,
  signKey?: string,
|};

type DispatchProps = {|
  updateWebhook: FormData => void,
  resetUpdateState: () => void,
  verifyWebhookUrl: string => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  webhook: Webhook,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { webhook }) => ({
  updateState: webhooksReducer.getUpdateSelector(state, { id: webhook.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { webhook }) => ({
  updateWebhook: ({ url, imwProxy, signKey }) => {
    dispatch(webhooksReducer.updateWebhook({
      id: webhook.id,
      data: {
        url,
        imwProxy: getWebhookFormatValue(imwProxy),
        signKey,
      },
    }));
  },
  resetUpdateState: () => {
    dispatch(webhooksReducer.updateWebhookReset({
      id: webhook.id,
    }));
  },
  verifyWebhookUrl: (url) => {
    dispatch(webhooksReducer.urlCheck({
      id: webhook.id,
      data: {
        url,
      },
    }));
  },
});

const connectedEditWebhookModal = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(EditWebhookModal);

export { connectedEditWebhookModal as EditWebhookModal };
