// @flow

import * as React from 'react';

import defaultLogo from './logos/default.svg';
import salesforceLogo from './logos/salesforce.svg';
import dynamicsCrmLogo from './logos/dynamics-crm.svg';
import upsalesLogo from './logos/upsales.svg';
import limeLogo from './logos/lime.svg';
import intelliplanLogo from './logos/intelliplan.svg';
import superofficeLogo from './logos/superoffice.svg';
import teamtailorLogo from './logos/teamtailor.svg';
import sympaLogo from './logos/sympa.svg';
import cinodeLogo from './logos/cinode.svg';
import microsoft365Logo from './logos/microsoft-365.svg';
import hibobLogo from './logos/hibob.svg';
import adockaLogo from './logos/adocka.svg';
import membrainLogo from './logos/membrain.svg';
import jobylonLogo from './logos/jobylon.svg';
import hubspotLogo from './logos/hubspot.svg';
import hubspotWorkflowsLogo from './logos/hubspot-workflows.svg';
import webhooksLogo from './logos/webhooks.svg';
import apiTokensLogo from './logos/api-tokens.svg';
import templateGroupsLogo from './logos/template-groups.svg';
import electronicIdLogo from './logos/electronic-id.svg';
import handwrittenSignatureLogo from './logos/handwritten-signature.svg';
import scimLogo from './logos/scim.svg';
import ssoLogo from './logos/sso.svg';
import smsLogo from './logos/sms.svg';
import googleWorkspaceLogo from './logos/google-workspace.svg';
import pipedriveLogo from './logos/pipedrive.svg';
import linkityLogo from './logos/linkity.svg';
import slackLogo from './logos/slack.svg';
import zapierLogo from './logos/zapier.svg';
import powerAutomateLogo from './logos/power-automate.svg';
import aiAssistLogo from './logos/ai-assist.svg';
import onepagecrmLogo from './logos/onepagecrm.svg';
import customEmailDomainLogo from './logos/custom-email-domain.svg';
import shareTemplateLogo from './logos/share-template.svg';
import groupsLogo from './logos/groups.svg';
import replaceDocumentLogo from './logos/replace-document.svg';
import recrutoLogo from './logos/recruto.svg';
import suggestionsLogo from './logos/suggestions.svg';
import overlayFieldsLogo from './logos/overlay-fields.svg';
import inlineCommentsLogo from './logos/inline-comments.svg';
import draftApprovalFlowLogo from './logos/draft-approval-flow.svg';
import sectionRulesLogo from './logos/section-rules.svg';
import aiReviewLogo from './logos/ai-review.svg';
import aiReviewPlusLogo from './logos/ai-review-plus.svg';
import talentechLogo from './logos/talentech.svg';
import documentSignatureFields from './logos/document-signature-fields.svg';
import heartpaceLogo from './logos/heartpace.svg';
import signingOrder from './logos/signing-order.svg';
import signRedirectLogo from './logos/sign-redirect.svg';
import customAccountRolesLogo from './logos/custom-account-roles.svg';
import customWorkspaceRolesLogo from './logos/custom-workspace-roles.svg';
import aiImportLogo from './logos/ai-import.svg';

type Props = {
  extension: Extension,
  className?: string,
};

export class ExtensionLogo extends React.PureComponent<Props> {
  render() {
    const { extension, className } = this.props;

    const itemKey = extension.key;
    let logo = defaultLogo;

    if (itemKey === 'api_tokens') {
      logo = apiTokensLogo;
    }

    if (itemKey === 'electronic_id') {
      logo = electronicIdLogo;
    }

    if (itemKey === 'handwritten_signature') {
      logo = handwrittenSignatureLogo;
    }

    if (itemKey === 'scim') {
      logo = scimLogo;
    }

    if (itemKey === 'sms') {
      logo = smsLogo;
    }

    if (itemKey === 'sso_saml') {
      logo = ssoLogo;
    }

    if (itemKey === 'template_groups') {
      logo = templateGroupsLogo;
    }

    if (itemKey === 'webhooks') {
      logo = webhooksLogo;
    }

    if (itemKey === 'adocka') {
      logo = adockaLogo;
    }

    if (itemKey === 'dynamics_crm') {
      logo = dynamicsCrmLogo;
    }

    if (itemKey === 'hubspot') {
      logo = hubspotLogo;
    }

    if (itemKey === 'hubspot_workflows') {
      logo = hubspotWorkflowsLogo;
    }

    if (itemKey === 'intelliplan') {
      logo = intelliplanLogo;
    }

    if (itemKey === 'jobylon') {
      logo = jobylonLogo;
    }

    if (itemKey === 'lime') {
      logo = limeLogo;
    }

    if (itemKey === 'membrain') {
      logo = membrainLogo;
    }

    if (itemKey === 'pipedrive') {
      logo = pipedriveLogo;
    }

    if (itemKey === 'salesforce') {
      logo = salesforceLogo;
    }

    if (itemKey === 'superoffice') {
      logo = superofficeLogo;
    }

    if (itemKey === 'teamtailor') {
      logo = teamtailorLogo;
    }

    if (itemKey === 'upsales_v2') {
      logo = upsalesLogo;
    }

    if (itemKey === 'sympa') {
      logo = sympaLogo;
    }

    if (itemKey === 'zerolime') {
      logo = intelliplanLogo;
    }

    if (itemKey === 'cinode') {
      logo = cinodeLogo;
    }

    if (itemKey === 'cinode_test') {
      logo = cinodeLogo;
    }

    if (itemKey === 'hibob') {
      logo = hibobLogo;
    }

    if (itemKey === 'microsoft_365') {
      logo = microsoft365Logo;
    }

    if (itemKey === 'google_workspace') {
      logo = googleWorkspaceLogo;
    }

    if (itemKey === 'linkity') {
      logo = linkityLogo;
    }

    if (itemKey === 'slack') {
      logo = slackLogo;
    }

    if (itemKey === 'zapier') {
      logo = zapierLogo;
    }

    if (itemKey === 'power_automate') {
      logo = powerAutomateLogo;
    }

    if (itemKey === 'ai_assist') {
      logo = aiAssistLogo;
    }

    if (itemKey === 'onepagecrm') {
      logo = onepagecrmLogo;
    }

    if (itemKey === 'custom_email_domain') {
      logo = customEmailDomainLogo;
    }

    if (itemKey === 'share_template') {
      logo = shareTemplateLogo;
    }

    if (itemKey === 'groups') {
      logo = groupsLogo;
    }

    if (itemKey === 'replace_document') {
      logo = replaceDocumentLogo;
    }

    if (itemKey === 'recruto_contract') {
      logo = recrutoLogo;
    }

    if (itemKey === 'suggestions') {
      logo = suggestionsLogo;
    }

    if (itemKey === 'document_overlay_fields') {
      logo = overlayFieldsLogo;
    }

    if (itemKey === 'inline_comments') {
      logo = inlineCommentsLogo;
    }

    if (itemKey === 'draft_approval_flow') {
      logo = draftApprovalFlowLogo;
    }

    if (itemKey === 'section_rules') {
      logo = sectionRulesLogo;
    }

    if (itemKey === 'ai_review') {
      logo = aiReviewLogo;
    }

    if (itemKey === 'ai_review_plus') {
      logo = aiReviewPlusLogo;
    }

    if (itemKey === 'talentech') {
      logo = talentechLogo;
    }

    if (itemKey === 'document_signature_fields') {
      logo = documentSignatureFields;
    }

    if (itemKey === 'heartpace') {
      logo = heartpaceLogo;
    }

    if (itemKey === 'signing_order') {
      logo = signingOrder;
    }

    if (itemKey === 'sign_redirect') {
      logo = signRedirectLogo;
    }

    if (itemKey === 'custom_account_roles') {
      logo = customAccountRolesLogo;
    }

    if (itemKey === 'custom_workspace_roles') {
      logo = customWorkspaceRolesLogo;
    }

    if (itemKey === 'ai_import') {
      logo = aiImportLogo;
    }

    return (
      <img alt="logo" src={logo} className={className} />
    );
  }
}
