import adminPage from 'hocs/admin-page';
import { AiInsights } from 'components/pages';

export const insightsModule = () => ({
  title: 'AI Insights',
  showAsLink: false,
  modules: [[]],
});

export default adminPage(insightsModule)(AiInsights);
