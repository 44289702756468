import { Message } from '@oneflowab/pomes';

import FormattingOptions from 'components/document-tabs/settings-tab/sections/formatting-options';
import Accordion from '../accordion';
import style from './accordion.module.scss';

type Props = { agreementId: number };

const FormattingOptionsSection = ({ agreementId }: Props) => {
  const title = (
    <Message
      id="Text formatting"
      comment="Header for formatting section, where you choose font size, style and color."
    />
  );

  return (
    <Accordion title={title} name="formattingOptions" contentClassName={style.Content}>
      <FormattingOptions agreementId={agreementId} />
    </Accordion>
  );
};

export default FormattingOptionsSection;
