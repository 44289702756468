import { call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';

import generateEntitySagas from 'normalized-redux/sagas';
import { getStatistics } from 'oneflow-client/statistics';

import apiWrapper from 'sagas/api-wrapper';

import statistics from 'reducers/entities/statistics';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getPositionFromSessionSelector } from 'reducers/session';

export function* getPositionIdFromAction(query) {
  const user = get(query, 'params.user');

  if (!user || user === 'me') {
    const position = yield select(getPositionFromSessionSelector);

    return position.id;
  }

  return 0;
}

export function* setQueryParams({ action } = {}) {
  const workspace = yield select(getCurrentWorkspaceSelector);
  const positionId = yield call(getPositionIdFromAction, action.query);

  return {
    params: {
      workspaceId: workspace.id,
      positionId,
    },
  };
}

export function* mapper({ data }) {
  yield put(statistics.setStatistics(data.entities.agreementStatistics));
}

const mappers = {
  query: {
    prepare: setQueryParams,
    mapper,
    request: getStatistics,
  },
};

const statisticsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: statistics,
  mappers,
});

export default statisticsSagas;
