// @flow

import {
  USER_ROLE_LIMITED,
  USER_STATE_INVITED,
  USER_ACTIVE,
} from './constants';

type PositionHelper = (position: Position) => boolean;

export const isUserLimited: PositionHelper = (position: Position) => (
  position.userRole === USER_ROLE_LIMITED
);

export const isUserInvited: PositionHelper = (position: Position) => (
  position.state === USER_STATE_INVITED
);

export const isUserActive: PositionHelper = (position: Position) => (
  position.active === USER_ACTIVE
);

export const getUserInitials = (name: string) => {
  if (!name) {
    return '';
  }

  // remove special characters like +-()/|\. from the name string
  const normalizedName = name.normalize('NFC').replace(/[^\p{L}\p{M}\p{Zs}]+/gu, '');
  const names = normalizedName.split(' ');

  const firstInitial = names[0].charAt(0).toLocaleUpperCase();

  if (names.length > 1) {
    const lastInitial = names[names.length - 1].charAt(0).toLocaleUpperCase();
    return firstInitial + lastInitial;
  }

  return firstInitial;
};
