import { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import type { MouseEvent, FocusEvent, KeyboardEvent } from 'react';
import useAgreement from 'hooks/use-agreement';
import { isConcluded } from 'agreement';
import { getCurrentContractId, getDataField } from 'reducers/current-contract';
import useIsDragHandlerVisible from 'hooks/use-is-drag-handler-visible';

import { useDocumentDndContext } from 'components/document-layout-container/document-dnd-context';
import EditDocumentDataFieldModal from 'components/modals/edit-document-data-field';
import Draggable from 'components/draggable';
import Message from 'components/message';
import DragHandlerIcon from 'components/icons/drag-handler';
import { checkAcl } from 'components/acl';

import style from './data-field-item.module.scss';

type Props = {
  dataFieldKey: DataFieldValue['key'],
};

type ValueType = string | null;

const sanitizeValue = (value: string | null): string => (value ? value.replace(/[\r\n]+/g, '') : '');

const DataFieldItem = ({ dataFieldKey }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataFieldObject = useSelector((state) => getDataField(state, dataFieldKey));
  const isDragHandlerVisible = useIsDragHandlerVisible();
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const documentIsNotConcluded = !isConcluded(agreement);
  const canEditDataField = checkAcl(dataFieldObject?.acl, 'agreementdata:value:update');
  const isDataFieldVisible = documentIsNotConcluded && canEditDataField;

  const { activeDndId } = useDocumentDndContext();
  const isDragOverlayActive = dataFieldKey === activeDndId;
  if (!dataFieldObject) {
    return null;
  }

  const { value: { value = null, placeholder = null, name } } = dataFieldObject;

  const dataFieldBody: ValueType = sanitizeValue(value) || placeholder;
  const isBodyShort = (dataFieldBody ?? '').length <= 35; // infered from checking the length of the string before ellipsed
  const isNameShort = (name ?? '').length <= 31; // infered from checking the length of the string before ellipsed
  const canExpand = (!isBodyShort || !isNameShort) && !isModalOpen;

  const dataFieldClassNames = clsx(style.DataField, {
    [style.DataFieldEmpty]: !dataFieldBody,
    [style.DragIsDisabled]: !isDragHandlerVisible,
    [style.IsDragOverlayActive]: isDragOverlayActive,
    [style.CanBeExpanded]: canExpand,
  });

  const dataFieldContainerClassNames = clsx(style.DataFieldContainer, {
    [style.IsDragOverlayActiveContainer]: isDragOverlayActive,
  });

  const bodyClassNames = clsx(style.DataFieldBody, {
    [style.DataFieldPlaceholder]: !value && placeholder,
    [style.DragIsActive]: isDragOverlayActive,
    [style.IsExpanded]: expanded,
    [style.EmptyValue]: !dataFieldBody,
  });

  const dataFieldNameClassNames = clsx(style.DataFieldName, {
    [style.IsExpanded]: expanded,
    [style.IsActive]: isDragOverlayActive,
  });

  const draggableClassNames = clsx(style.Draggable, style.ItemDragHandler);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (canExpand) {
      setExpanded(!expanded);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (canExpand) {
      setExpanded(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && canExpand) {
      event.preventDefault();
      event.stopPropagation();
      setExpanded(!expanded);
    }
  };

  return (
    <div
      className={dataFieldContainerClassNames}
    >
      {isDragHandlerVisible && (
      <Draggable
        id={dataFieldKey}
        data={dataFieldObject.value}
        customClassName={draggableClassNames}
        draggableType={dataFieldObject.key}
        segmentId="smart_fields"
      >
        {!isDragOverlayActive && <DragHandlerIcon width="8px" height="13px" />}
      </Draggable>
      )}
      <div
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        className={dataFieldClassNames}
      >
        <div className={style.DataFieldHeader}>
          <span className={dataFieldNameClassNames} data-testid="data-field-item-name">
            {name}
          </span>
          {isDataFieldVisible && (
          <div className={style.EditButton}>
            <EditDocumentDataFieldModal
              onOpen={() => setIsModalOpen(true)}
              onClose={() => setIsModalOpen(false)}
              isInDocumentPage
              dataFieldKey={dataFieldKey}
            >
              <Message
                id="Edit"
                comment="Edit element for user to update and edit a data field."
              />
            </EditDocumentDataFieldModal>
          </div>
          )}
        </div>
        {dataFieldBody ? (
          <div
            className={bodyClassNames}
            data-testid="data-field-item-value"
          >
            {dataFieldBody}
          </div>
        ) : (
          <div className={bodyClassNames}>
            <Message id="No value set" comment="Empty data field value" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DataFieldItem;
