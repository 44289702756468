import {
  useState, useRef, useCallback,
} from 'react';
import { Message } from '@oneflowab/pomes';
import { useSelector, useDispatch } from 'react-redux';

import { getCurrentWorkspaceIdSelector, getCurrentWorkspaceSelector } from 'reducers/app';
import workspacesReducer from 'reducers/entities/workspaces';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

import Modal from 'components/modal';
import CircularSpinner from 'components/icons/circular-spinner';
// eslint-disable-next-line import/named
import { ContractCard } from 'components/contract-card';
import { ApiError, unknownApiError } from 'components/api-error';
import EmptyState from 'components/empty-state';
import NoContractsIcon from 'components/icons/no-contracts';

import style from './ai-insights.module.scss';

const LIMIT = 20;

export type Props = {
  onClose: () => void,
  filterParams: any,
  clickedInsightType: { key: string, value: string },
  clickedLabel?: { key: string, value: string },
  issuesOnly?: boolean,
};

export const AiInsightsModal = ({
  onClose,
  filterParams,
  clickedInsightType,
  clickedLabel,
  issuesOnly,
}: Props) => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const workspaceId = useSelector(getCurrentWorkspaceIdSelector) || 0;
  const paginationRef = useRef<number | undefined>(undefined);
  const isEndReached = useRef<boolean>(false);
  const [contractList, setContractList] = useState<any[]>([]);
  const workspace = useSelector(getCurrentWorkspaceSelector);

  const { loading, error } = useSelector((state) => (
    workspacesReducer.getGetAiInsightsContractListSelector(state, { id: workspaceId })
  ));
  const { state, lifecycle, folderIds } = filterParams;

  const executeQuery = useCallback(() => {
    if (isEndReached.current) return;

    const params = {
      state,
      lifecycle,
      folderids: folderIds,
      issuesonly: issuesOnly || false,
      labels: clickedLabel ? [clickedLabel.key] : undefined,
      paginationcursor: paginationRef.current,
      limit: LIMIT,
    };

    dispatch(workspacesReducer.getAiInsightsContractList({
      id: workspaceId,
      data: {
        insightType: clickedInsightType.key || 'all',
        params,
      },
      pipe: {
        onSuccess: (data: {
          collection: Oneflow.Agreement[],
          paginationCursor: number,
          count: number,
        }) => {
          const { collection, paginationCursor, count } = data;
          if (count < LIMIT) {
            isEndReached.current = true;
          }
          paginationRef.current = paginationCursor;
          setContractList((prev) => [...prev, ...collection]);
        },
      },
    }));
  }, [
    state,
    lifecycle,
    folderIds,
    clickedLabel,
    dispatch,
    workspaceId,
    clickedInsightType,
    issuesOnly,
  ]);

  const handleScrollCapture = useCallback(() => {
    if (!scrollContainerRef.current || isEndReached.current) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (loading) return;
      executeQuery();
    }
  }, [executeQuery, loading]);

  return (
    <Modal
      header={(
        <>
          {clickedInsightType.value}
          {' - '}
          {clickedLabel?.value || <Message id="All issues" comment="Message shown in a modal" />}
        </>
      )}
      isOpen
      onCancel={onClose}
      hideFooter
      onAfterOpen={executeQuery}
      modalKey="ai insights contract list modal"
      isWideModal
    >
      <Modal.Body>
        {contractList.length > 0 && (
          <div
            className={style.ScrollContainer}
            ref={scrollContainerRef}
            onScrollCapture={handleScrollCapture}
          >
            {contractList.map((document) => (
              <ContractCard
                key={document.id}
                dataTestId="contract-card"
                agreement={document}
                isReadOnly
                onClick={() => {
                  amplitudeLogEvent('Open Document', {
                    location: 'ai insights modal',
                    workspaceId: workspace.id,
                  });
                }}
              />
            ))}
            <div className={style.ScrollLoadingContainer}>
              {loading && (
                <div className={style.Loading}><CircularSpinner /></div>
              )}
            </div>
          </div>
        )}
        {contractList.length === 0 && (
          loading ? (
            <div className={style.InitialLoadingContainer}>
              <div className={style.Loading}><CircularSpinner /></div>
            </div>
          ) : (
            <EmptyState
              withPadding
              icon={<NoContractsIcon height="33px" />}
              header={(
                <Message
                  id="No documents found"
                  comment="Empty state header. Shown in document list when no contracts are found."
                />
              )}
              content={(
                <Message
                  id="Please adjust your search criteria and try again."
                  comment="Empty state content. Shown in document list when no documents are found."
                />
              )}
              defaultStyle
            />
          )
        )}
        {error && (
        <ApiError customMessage={unknownApiError} />
        )}
      </Modal.Body>
    </Modal>
  );
};
