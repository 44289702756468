// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import HorizontalLine from 'components/icons/horizontal-line';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { addHorizontalLine } from './horizontal-line-plugin';
import ToolbarButton from '../toolbar-button';

type Props = {
  isMenuItem: boolean,
  disabled: boolean,
};

const HorizontalLineButton = ({ isMenuItem, disabled }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      isMenuItem={isMenuItem}
      disabled={disabled}
      label={(
        <Message
          id="Horizontal line"
          comment="The label of the editor's toolbar plugin that adds a Horizontal line."
        />
      )}
      onTrigger={() => {
        focusEditor();
        addHorizontalLine(editor);
      }}
      icon={<HorizontalLine />}
    />
  );
};

export default HorizontalLineButton;
