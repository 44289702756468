/* eslint-disable no-continue */
// @flow

import findLastIndex from 'lodash/findLastIndex';
import isEmpty from 'lodash/isEmpty';
import { Editor, Element } from 'slate';

import { getActiveTableBody } from './table-utils';

type CellPosition = {
  row: number;
  col: number;
};

export type Cell = {
  colSpan: number;
  rowSpan: number;
  node: Element;
  parentNode: Element;
  nodeIndex: number;
  isLeftOfMergedCell: boolean;
  isRightOfMergedCell: boolean;
  isTopOfMergedCell: boolean;
  isBottomOfMergedCell: boolean;
} & CellPosition;

export type BlockMatrix = Array<Array<Cell>>;

export const getColumnCount = (rows: Array<any>) => (
  Math.max(
    ...rows.map((row) => {
      if (!Element.isElement(row)) {
        return 0;
      }
      const rowColumnCount = row.children.reduce((acc: number = 0, cell) => {
        if (!Element.isElement(cell)) {
          return acc;
        }
        return acc + (cell.colSpan || 1);
      }, 0);

      return rowColumnCount;
    }),
  )
);

export const getTableColumnCount = (element: Element) => {
  const tableBody = element.children.find((child) => child.type === 'table-body');
  if (!tableBody) {
    return 0;
  }
  return getColumnCount(tableBody.children);
};

export function createRowsBlockMatrix(rows: Array<any>): ?BlockMatrix {
  if (isEmpty(rows)) {
    return [];
  }

  const rowCount = rows.length;
  const columnCount = getColumnCount(rows);
  const blockMatrix: BlockMatrix = [];

  for (
    let row = 0, cellY = 0;
    row < rowCount;
    row += 1, cellY += 1
  ) {
    let nodeIndex = 0;
    let cellX = 0;
    for (let col = 0; col < columnCount; col += 1) {
      if (blockMatrix[row] && blockMatrix[row][col]) {
        continue;
      }
      if (!blockMatrix[row]) {
        blockMatrix[row] = [];
      }
      const rowNode = rows[cellY];
      if (!Element.isElement(rowNode)) {
        continue;
      }

      const cell = rowNode.children[nodeIndex];
      if (!cell) {
        blockMatrix[row][col] = {};
        continue;
      }

      const colSpan = Number(cell.colSpan || 1);
      const rowSpan = Number(cell.rowSpan || 1);
      blockMatrix[row][col] = {
        row,
        col,
        colSpan,
        rowSpan,
        node: cell,
        parentNode: rowNode,
        nodeIndex,
        isLeftOfMergedCell: cellX === 0,
        isRightOfMergedCell: cellX === colSpan - 1,
        isBottomOfMergedCell: false,
        isTopOfMergedCell: rowSpan > 1,
      };

      if (rowSpan > 1) {
        for (let r = 0; r < rowSpan - 1; r += 1) {
          if (!blockMatrix[row + r + 1]) {
            blockMatrix[row + r + 1] = [];
          }
          blockMatrix[row + r + 1][col] = {
            row: row + r + 1,
            col,
            colSpan,
            rowSpan,
            node: cell,
            nodeIndex,
            parentNode: rowNode,
            isLeftOfMergedCell: cellX === 0,
            isRightOfMergedCell: cellX === colSpan - 1,
            isBottomOfMergedCell: r === rowSpan - 1,
            isTopOfMergedCell: false,
          };
        }
      }
      if (cellX === colSpan - 1) {
        cellX = 0;
        nodeIndex += 1;
      } else {
        cellX += 1;
      }
    }
  }
  return blockMatrix;
}

export function createBlockMatrix(editor: Editor): ?BlockMatrix {
  if (!editor) {
    return null;
  }

  const rows = getActiveTableBody(editor)?.children;

  if (!rows) {
    return null;
  }

  return createRowsBlockMatrix(rows);
}

const getTableRows = (tableElement: Element) => {
  if (!tableElement || isEmpty(tableElement.children)) {
    return [];
  }

  const tableBody = tableElement.children.find((child) => child.type === 'table-body');

  if (!tableBody || isEmpty(tableBody.children)) {
    return [];
  }

  return tableBody.children;
};

export function createTableBlockMatrix(tableElement: Element): ?BlockMatrix {
  const tableRows = getTableRows(tableElement);

  if (isEmpty(tableRows)) {
    return null;
  }

  return createRowsBlockMatrix(tableRows);
}

export const getColumnIndex = (blockMatrix: ?BlockMatrix, targetRow: any, targetCell: any) => {
  if (!blockMatrix || !targetRow || !targetCell) {
    return -1;
  }

  for (let i = 0; i <= blockMatrix.length; i += 1) {
    const rowBlock = blockMatrix[i];
    const columnIndex = findLastIndex(rowBlock, (cellBlock) => cellBlock.node === targetCell);
    if (columnIndex > -1) {
      return columnIndex;
    }
  }

  return -1;
};
