class DataParseError extends Error {
  message = '';

  index = undefined;

  constructor(message, index) {
    super(message);

    this.name = 'DataParseError';
    this.index = index;
    this.validationInfo = `conditional validation at the index ${index} failed to parse`;
  }

  toString() {
    return `${this.name}:${this.message} - ${this.validationInfo}`;
  }
}

export default DataParseError;
