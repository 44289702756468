// @flow

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty,
  isNull,
  isNumber,
  isUndefined,
} from 'lodash';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import {
  isDraft,
  isTemplate,
  isImport,
  isConcluded,
  isAnySignedState,
  isPending,
} from 'agreement';

import {
  getNewParticipantBlockIndex,
  getParticipantSignOrderBlockIndex,
} from 'agreement/pending-state-flow';

import {
  TYPE_IS_SIGNATORY,
} from 'agreement/participant/constants';
import { isImportInProgress } from 'agreement/import';
import {
  getDefaultPartyType,
  getDefaultDeliveryChannel,
  getDefaultParticipantRole,
  getDefaultTwoStepAuthenticationMethod,
  getDefaultSignMethod,
  getHiddenRoles,
} from 'agreement/agreement';

import {
  isPhoneNumberRequired,
  isEmailRequired,
} from 'agreement/participant';
import hasDeliveryChannelSms from 'agreement/participant/has-delivery-channel-sms';

import agreementsReducer from 'reducers/entities/agreements';
import workspacesReducer from 'reducers/entities/workspaces';
import { getAccountFromSessionSelector, getPositionFromSessionSelector } from 'reducers/session';
import {
  hasParticipantEmail,
  hasParticipantPhone,
} from 'agreement/agreement-participants';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';

import { getInviteMessageBody } from 'agreement/message-templates';

import {
  getGuestToken,
  isAgreementOwner,
  isSignOrderEnabled,
  getAgreementMyParticipant,
} from 'agreement/selectors';

import { isUserLimited } from 'user';
import { AddParticipantButton } from 'components/buttons';
import { CancelButton } from 'components/buttons/cancel';
import { ConfirmButton } from 'components/buttons/confirm';
import AddressBookSearch from 'components/address-book-search';
import { checkAcl } from 'components/acl';
import Checkbox from 'components/checkbox';
import Field from 'components/field';
import MessageSection from 'components/message-section';
import ParticipantSection from 'components/participant-section';
import PartyTypeSelector from 'components/party-type-selector';
import PartyCompany from 'components/party-company';
import * as partyConstants from 'agreement/party/constants';
import SigningDetails from 'components/signing-details';
import { showDocumentSentToast } from '../add-colleague/add-colleague';

import style from './add-counterparty.module.scss';

type Props = {
  message: MessageTranslator,
  agreement: Agreement,
  party?: AgreementParty,
  hasParticipantList?: boolean,
};

export const AddCounterpartyComponent = ({
  message,
  agreement,
  party,
  hasParticipantList,
}: Props) => {
  const [countryCode, setCountryCode] = useState('');
  const [country, setCountry] = useState('');
  const isInPreviewMode = useIsInPreviewMode();
  const myParticipant = getAgreementMyParticipant(agreement);

  const getAgreementDefaultPartyType = () => {
    if (party) {
      return partyConstants.COMPANY;
    }

    return getDefaultPartyType(agreement);
  };

  const agreementWorkspace = useSelector((state) => (
    workspacesReducer.getWorkspaceSelector(state, { id: agreement.collection?.id })
  ));

  const accountFromSession = useSelector((state) => (
    getAccountFromSessionSelector(state)
  ));

  const getDefaultCountryValue = () => {
    if (party?.country) {
      return party.country;
    }
    return agreementWorkspace?.brandingCountry || accountFromSession?.country;
  };

  const defaultCompanyValues = {
    companyRegNumber: null,
    companyName: null,
    country: getDefaultCountryValue(),
  };

  const [partyType, setPartyType] = useState(getAgreementDefaultPartyType());
  const defaultParticipantValues = {
    fullname: null,
    email: null,
    phoneNumber: null,
    personalIdentification: null,
    role: getDefaultParticipantRole(agreement),
    country: getDefaultCountryValue(),
    jobTitle: null,
  };

  const defaultSigningDetailsValues = {
    deliveryChannel: getDefaultDeliveryChannel(agreement),
    signMethod: getDefaultSignMethod(
      agreement,
      getDefaultDeliveryChannel(agreement),
      accountFromSession,
    ),
    twoStepAuthenticationMethod: getDefaultTwoStepAuthenticationMethod(agreement),
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState();
  const [currentCompanyValues, setCurrentCompanyValues] = useState(defaultCompanyValues);
  const [currentParticipantValues, setCurrentParticipantValues] = useState(
    defaultParticipantValues,
  );
  const [currentSigningDetailsValues, setCurrentSigningDetailsValues] = useState(
    defaultSigningDetailsValues,
  );

  // need to consider update party/participant details acls when implementing edit
  // need to consider email field empty -> to implement when making email field not required
  // because of delivery channel
  const shouldCheckSaveToAddressBook = () => (
    !isImportInProgress(agreement) && !hasDeliveryChannelSms(currentSigningDetailsValues)
  );

  const [currentSaveToAddressBook, setCurrentSaveToAddressBook] = useState(
    shouldCheckSaveToAddressBook(),
  );

  const position = useSelector(getPositionFromSessionSelector);
  const rpcState = useSelector((state) => (
    agreementsReducer.getAddParticipantSelector(state, { id: agreement.id })
  ));

  const getSignOrderBlockIndex = () => {
    const isOwner = isAgreementOwner(accountFromSession, agreement);

    if (isOwner) {
      return getNewParticipantBlockIndex(agreement);
    }

    return getParticipantSignOrderBlockIndex(agreement, myParticipant?.id);
  };

  const dispatch = useDispatch();
  const hasSignOrder = isSignOrderEnabled(agreement);
  const pendingStateFlowId = hasSignOrder ? agreement.pendingStateFlow?.id : null;
  const signOrderBlockIndex = hasSignOrder ? getSignOrderBlockIndex() : 0;

  const addCounterparty = (params) => dispatch(agreementsReducer.addParticipant({
    id: agreement.id,
    data: {
      ...params,
      pendingStateFlowId,
      signOrderBlockIndex,
    },
    pipe: {
      onSuccess: () => {
        if (isPending(agreement)) {
          setIsOpen(false);
          showDocumentSentToast();
        }
      },
    },
  }));

  const resetRpcState = () => dispatch(agreementsReducer.addParticipantReset({
    id: agreement.id,
  }));

  const getMessageBody = (messageTemplate) => getInviteMessageBody(
    agreement, messageTemplate, position,
  );

  const hasAddressBookViewPermission = () => checkAcl(
    agreementWorkspace.acl, 'collection:addressbook:view',
  );

  const onChangeSaveToAddressBook = (event) => {
    const { checked } = event.target;
    setCurrentSaveToAddressBook(checked);
  };

  const isAddingToExistingParty = () => !!party;

  const renderSaveToAddressBook = () => {
    if (!hasAddressBookViewPermission()) {
      return null;
    }

    return (
      <div className={style.SaveToAddressBook}>
        <Field
          name="saveToAddressBook"
          component={Checkbox}
          type="checkbox"
          label={message({
            id: 'Add contact',
            comment: 'Checkbox label to save participant information to the address book',
          })}
          input={{
            checked: currentSaveToAddressBook,
            onChange: onChangeSaveToAddressBook,
          }}
          initialValue={currentSaveToAddressBook}
        />
      </div>
    );
  };

  const renderMessageTemplateSection = () => {
    if (
      isImport(agreement)
      || isDraft(agreement)
      || isTemplate(agreement)
      || isConcluded(agreement)
      || isAnySignedState(agreement)
      || (isPending(agreement) && isSignOrderEnabled(agreement))
    ) {
      return null;
    }

    return (
      <MessageSection
        agreement={agreement}
        updateSelectedMessageTemplate={setSelectedMessageTemplate}
        selectedMessageTemplate={selectedMessageTemplate}
        getMessageBody={getMessageBody}
        position={position}
      />
    );
  };

  const guestToken = useSelector((state) => getGuestToken(state));
  const isGuest = Boolean(guestToken);

  const uniqueEmailValidation = (
    { params },
  ) => {
    if (isGuest || rpcState.success) {
      return false;
    }
    return hasParticipantEmail({ params, agreementId: agreement.id });
  };

  const uniquePhoneValidation = (
    { params },
  ) => {
    if (isGuest) {
      return false;
    }
    return hasParticipantPhone({ params, agreementId: agreement.id });
  };

  const onSigningDetailsChanged = (signingDetails) => {
    const { signMethod, deliveryChannel, twoStepAuthenticationMethod } = signingDetails;
    const hasSignMethod = !isNull(signMethod) && !isUndefined(signMethod);
    const hasDeliveryChannel = !isNull(deliveryChannel) && !isUndefined(deliveryChannel);
    const hasTwoStepAuthenticationMethod = !isNull(twoStepAuthenticationMethod)
      && !isUndefined(twoStepAuthenticationMethod);

    setCurrentSigningDetailsValues({
      ...currentSigningDetailsValues,
      ...(hasSignMethod && { signMethod }),
      ...(hasDeliveryChannel && { deliveryChannel }),
      ...(hasTwoStepAuthenticationMethod && { twoStepAuthenticationMethod }),
    });
  };

  const onRoleChanged = (roleId) => {
    setCurrentParticipantValues({
      ...currentParticipantValues,
      role: roleId,
    });
  };

  const getCompanyValues = () => {
    if (partyType === partyConstants.INDIVIDUAL) {
      return null;
    }

    return ({
      companyName: currentCompanyValues?.companyName,
      companyRegNumber: currentCompanyValues?.companyRegNumber,
      country: { value: currentCompanyValues?.country },
    });
  };

  const getParticipantValues = () => {
    const commonFields = {
      fullname: currentParticipantValues?.fullname,
      email: currentParticipantValues?.email,
      phoneNumber: currentParticipantValues?.phoneNumber,
      personalIdentification: currentParticipantValues?.personalIdentification,
      role: currentParticipantValues?.role,
      emailUnique: currentParticipantValues?.email,
      countryData: { countryCode, country },
    };

    if (partyType === partyConstants.INDIVIDUAL) {
      return ({
        ...commonFields,
        country: { value: currentParticipantValues?.country },
      });
    }

    if (partyType === partyConstants.COMPANY) {
      return ({
        ...commonFields,
        jobTitle: currentParticipantValues?.jobTitle,
      });
    }

    return null;
  };

  const getSigningDetailsValues = () => ({
    signMethod: currentSigningDetailsValues?.signMethod,
    deliveryChannel: currentSigningDetailsValues?.deliveryChannel,
    twoStepAuthenticationMethod: currentSigningDetailsValues?.twoStepAuthenticationMethod,
  });

  const onCountryChanged = (option) => {
    if (partyType === partyConstants.INDIVIDUAL) {
      setCurrentParticipantValues({
        ...currentParticipantValues,
        country: option.value,
      });
    } else {
      setCurrentCompanyValues({ country: option.value });
    }
  };

  const renderPartySection = () => {
    if (!isAddingToExistingParty()) {
      return (
        <PartyCompany
          values={getCompanyValues()}
          onCountryChanged={onCountryChanged}
        />
      );
    }

    return null;
  };

  const renderSigningDetails = () => {
    const isOwner = isAgreementOwner(accountFromSession, agreement);
    if (!isOwner || isImportInProgress(agreement)) {
      return null;
    }

    return (
      <SigningDetails
        onChange={onSigningDetailsChanged}
        hideSignMethod={
          currentParticipantValues?.role !== TYPE_IS_SIGNATORY
        }
        values={getSigningDetailsValues()}
        agreement={agreement}
        account={accountFromSession}
      />
    );
  };

  const renderParty = () => {
    if (partyType === partyConstants.INDIVIDUAL) {
      return (
        <>
          <ParticipantSection
            individual
            validateUniqueEmail={uniqueEmailValidation}
            validateUniquePhone={uniquePhoneValidation}
            hasSaveToAddressBook={hasAddressBookViewPermission()}
            onRoleChanged={onRoleChanged}
            values={getParticipantValues()}
            isPhoneNumberRequired={isPhoneNumberRequired(currentSigningDetailsValues)}
            isEmailRequired={
              isEmailRequired(currentSigningDetailsValues) && !isImportInProgress(agreement)
            }
            hiddenRoles={getHiddenRoles(agreement, party)}
            countryForPhone={currentParticipantValues?.country?.toLowerCase()}
            onCountryChanged={onCountryChanged}
            currentSignMethod={currentSigningDetailsValues?.signMethod}
            agreement={agreement}
            setCountryCode={setCountryCode}
            setCountry={setCountry}
          />
          {renderSaveToAddressBook()}
          {renderSigningDetails()}
          {renderMessageTemplateSection()}
        </>
      );
    }

    return (
      <>
        {renderPartySection()}
        <ParticipantSection
          validateUniqueEmail={uniqueEmailValidation}
          validateUniquePhone={uniquePhoneValidation}
          hasSaveToAddressBook={hasAddressBookViewPermission()}
          onRoleChanged={onRoleChanged}
          values={getParticipantValues()}
          isPhoneNumberRequired={isPhoneNumberRequired(currentSigningDetailsValues)}
          isEmailRequired={
            isEmailRequired(currentSigningDetailsValues) && !isImportInProgress(agreement)
          }
          hiddenRoles={getHiddenRoles(agreement, party)}
          countryForPhone={currentCompanyValues?.country?.toLowerCase()}
          currentSignMethod={currentSigningDetailsValues?.signMethod}
          agreement={agreement}
          setCountryCode={setCountryCode}
          setCountry={setCountry}
        />
        {renderSaveToAddressBook()}
        {renderSigningDetails()}
        {renderMessageTemplateSection()}
      </>
    );
  };

  const onPartyTypeChanged = (type) => {
    setPartyType(type);
    setCurrentCompanyValues(defaultCompanyValues);
    setCurrentParticipantValues(defaultParticipantValues);
    setCurrentSigningDetailsValues(defaultSigningDetailsValues);
  };

  const onSearchResultSelected = (result) => {
    const { person } = result;
    if (partyType === partyConstants.COMPANY) {
      setCurrentCompanyValues({
        companyName: person?.companyName || '',
        companyRegNumber: person?.companyOrgnr || '',
        country: person?.country || '',
      });
    }

    setCurrentParticipantValues({
      fullname: person.fullname,
      email: person.email,
      phoneNumber: person.phoneNumber,
      personalIdentification: person.ssn,
      ...(partyType === partyConstants.COMPANY && { jobTitle: person.title }),
      ...(partyType === partyConstants.INDIVIDUAL && { country: person.country }),
      role: currentParticipantValues?.role || undefined,
    });

    setCurrentSigningDetailsValues({
      deliveryChannel: currentSigningDetailsValues?.deliveryChannel
        || getDefaultDeliveryChannel(agreement),
      signMethod: currentSigningDetailsValues?.signMethod
        || getDefaultSignMethod(
          agreement,
          currentSigningDetailsValues?.deliveryChannel,
          accountFromSession,
        ),
      twoStepAuthenticationMethod: currentSigningDetailsValues?.twoStepAuthenticationMethod
        || getDefaultTwoStepAuthenticationMethod(agreement),
    });
  };

  const renderPartyTypeSelector = () => {
    if (!isAddingToExistingParty()) {
      return (
        <div className={style.Section}>
          <PartyTypeSelector
            onChange={onPartyTypeChanged}
            defaultValue={getAgreementDefaultPartyType()}
          />
        </div>
      );
    }

    return null;
  };

  const renderAddressBookSearch = () => {
    const isOwner = isAgreementOwner(accountFromSession, agreement);
    if (!isOwner || !hasAddressBookViewPermission()) {
      return null;
    }
    return (
      <AddressBookSearch
        onSearchResultSelected={onSearchResultSelected}
        companyName={party?.name}
      />
    );
  };

  const renderBody = () => (
    <>
      {renderAddressBookSearch()}
      {renderPartyTypeSelector()}
      {renderParty()}
    </>
  );

  const getErrorActions = () => null;
  const getModalTitle = () => {
    if (isAddingToExistingParty()) {
      return (
        <Message
          id="Add participant to {party}"
          comment="Dialog title for the add counterparty modal"
          values={{
            party: party.name,
          }}
        />
      );
    }

    if (isPending(agreement)) {
      return (
        <Message
          id="Add counterparty and send invitation"
          comment="Dialog title for the add counterparty modal"
        />
      );
    }

    return (
      <Message
        id="Add counterparty"
        comment="Dialog title for the add counterparty modal"
      />
    );
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const resetForm = () => {
    setIsOpen(false);
    setPartyType(getAgreementDefaultPartyType());
    setCurrentCompanyValues(defaultCompanyValues);
    setCurrentParticipantValues(defaultParticipantValues);
    setCurrentSigningDetailsValues(defaultSigningDetailsValues);
    setSelectedMessageTemplate();
    resetRpcState();
  };

  const renderChildren = () => {
    const getType = (params) => {
      if (!isAddingToExistingParty()) {
        return 'counterparty';
      }

      if (isAgreementOwner(params.account, params.agreement)) {
        return 'counterparty_participant';
      }

      return 'colleague';
    };

    return (
      <AddParticipantButton
        type={getType({ account: accountFromSession, agreement })}
        onClick={handleOpen}
        disabled={isUserLimited(position) || isInPreviewMode}
        hasParticipantList={hasParticipantList}
      />
    );
  };

  const handleSubmit = (formData: AgreementParticipant) => {
    if (!formData || isEmpty(formData)) {
      return;
    }

    const shouldInvite = formData.subject && formData.body ? 1 : 0;

    const deliveryChannelValue = isImport(agreement) ? 4 : formData.deliveryChannel?.value;

    const getSignMethod = () => {
      if (!isUndefined(formData.signMethod) && isNumber(formData.signMethod.value)) {
        return formData.signMethod.value;
      }
      return formData.signMethod;
    };

    const getJobTitle = () => {
      if (partyType === partyConstants.INDIVIDUAL) {
        return null;
      }
      return formData.jobTitle || undefined;
    };

    // Make sure we don't save the country code as phone number
    const phoneNumber = formData.phoneNumber === `+${formData.countryData.countryCode}` ? undefined : formData.phoneNumber || undefined;

    addCounterparty({
      agreement: agreement.id,
      agreementCompany: isAddingToExistingParty() ? party.id : undefined,
      individual: partyType === partyConstants.INDIVIDUAL ? 1 : 0,
      companyName: partyType === partyConstants.INDIVIDUAL
        ? formData.fullname : formData.companyName,
      country: formData.country?.value || formData.country || undefined,
      companyRegNumber: partyType === partyConstants.INDIVIDUAL
        ? formData.personalIdentification : formData.companyRegNumber,
      fullname: formData.fullname,
      email: formData.email,
      jobTitle: getJobTitle(),
      phoneNumber,
      personalIdentification: formData.personalIdentification || undefined,
      type: formData.role?.value ?? TYPE_IS_SIGNATORY,
      signMethod: getSignMethod(),
      deliveryChannel: deliveryChannelValue,
      twoStepAuthenticationMethod: formData.twoStepAuthenticationMethod?.value,
      invite: shouldInvite,
      saveToAddressBook: formData.saveToAddressBook,
      token: guestToken,
      subject: formData.subject || undefined,
      message: formData.body || undefined,
    });
  };

  const getCustomButtonTitle = () => {
    if (isPending(agreement)) {
      return (
        <Message
          id="Send invitation"
          comment="Button to send invitation to the counterparty"
        />
      );
    }

    return (
      <Message
        id="Add"
        comment="Button to send invitation to the counterparty"
      />
    );
  };

  const handleSubmitClick = (formProps, args) => {
    const { errors, form } = formProps;
    if (!isEmpty(errors)) {
      const fields = Object.keys(errors);
      if (fields.length) {
        const problematicField = fields[0];
        form.blur(problematicField);
        const fieldElement = document.querySelector(`[name='${problematicField}']`);
        fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    formProps.handleSubmit({ ...args });
  };

  const getActions = ({ closeConfirmation, formProps }) => (
    <div className={style.ActionButtons}>
      <CancelButton onClick={closeConfirmation} />
      <ConfirmButton
        onClick={(...args) => handleSubmitClick(formProps, args)}
        isLoading={rpcState.loading}
        disabled={
          rpcState.loading
        }
      >
        {getCustomButtonTitle()}
      </ConfirmButton>
    </div>
  );

  return (
    <ModalForm
      body={renderBody()}
      actions={getActions}
      errorActions={getErrorActions}
      title={getModalTitle()}
      isOpen={isOpen}
      onOpen={handleOpen}
      formState={rpcState}
      resetFormState={resetRpcState}
      onSubmit={handleSubmit}
      onClose={resetForm}
      modalKey="add contract counterparty modal"
      isWideModal
      allowPristine
      shouldRenderStaticScrollbar
    >
      {renderChildren}
    </ModalForm>
  );
};

export default localize<Props>(AddCounterpartyComponent);
