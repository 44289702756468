// @flow

import { connect } from 'react-redux';
import { checkAcl } from 'components/acl';
import { getPositionFromSessionSelector } from 'reducers/session';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import LifecycleCalendarComponent, { type Props } from './lifecycle-calendar';

type StateProps = {|
  currentUser: Position,
|};

type OwnProps = $Diff<Props, StateProps>;
type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const currentWorkspace = getCurrentWorkspaceSelector(state);
  const hasAccessToInternalRemindersButton = checkAcl(currentWorkspace.acl, 'collection:internal_reminder:create');
  const currentUser = getPositionFromSessionSelector(state);

  return {
    currentUser,
    hasAccessToInternalRemindersButton,
  };
};

export default connect<Props, OwnProps, StateProps, any, State, any>(
  mapStateToProps,
)(LifecycleCalendarComponent);
