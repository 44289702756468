import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { LocalizationProvider } from 'contexts/localization';
import {
  getPositionFromSessionSelector,
} from 'reducers/session';

type Props = {
  children: ReactNode
};

export const AppLocalization = ({ children }: Props) => {
  const position = useSelector(getPositionFromSessionSelector) as Oneflow.Position;
  const { timezone, dateFormat } = position;

  return (
    <LocalizationProvider
      timezone={timezone}
      dateFormat={dateFormat}
    >
      {children}
    </LocalizationProvider>
  );
};
