import { cloneElement, isValidElement } from 'react';
import type { ReactNode } from 'react';

// eslint-disable-next-line consistent-return
export const findNextSibling = (el: Element, selector: string) => {
  let sibling = el.nextElementSibling;

  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
};

// eslint-disable-next-line consistent-return
export const findPreviousSibling = (el: Element, selector: string) => {
  let sibling = el.previousElementSibling;

  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.previousElementSibling;
  }
};

const renderChildren = (children: React.ReactElement) => {
  const childrenType = children.type as any;
  // The children is a component
  if (typeof childrenType === 'function') return childrenType(children.props);
  // The children is a component with `forwardRef`
  if ('render' in childrenType) return childrenType.render(children.props);
  // It's a string, boolean, etc.
  return children;
};

export const SlottableWithNestedChildren = (
  { asChild, children }: { asChild?: boolean; children?: ReactNode },
  render: (child: ReactNode) => JSX.Element,
) => {
  if (asChild && isValidElement(children)) {
    return cloneElement(
      renderChildren(children),
      { ref: (children as any).ref },
      render(children.props.children),
    );
  }
  return render(children);
};
