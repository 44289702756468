import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import { checkAcl } from 'components/acl';
import { getAccountFromSessionSelector } from 'reducers/session';
import styles from './freemium-message.module.scss';

const FreemiumMessage = () => {
  const account = useSelector(getAccountFromSessionSelector);
  const hasBillingAcl = checkAcl(account.acl, 'account:order:create');

  return (
    <p className={styles.FreemiumMessage}>
      {hasBillingAcl ? (
        <Message
          id="You are on a free plan. You can add any section but only send contracts with PDF document sections. To use the other sections, {link}."
          comment="Text showing on error page when the document cannot be accessed"
          values={{
            link: (
              <a
                href="/admin/users/?m=fss"
                className={styles.Link}
              >
                <Message
                  id="upgrade your account"
                  comment="Link to upgrade account page"
                />
              </a>
            ),
          }}
        />
      ) : (
        <Message
          id="You are on a free plan. You can add any section but only send contracts with PDF document sections. To use the other sections, please talk to your admin about upgrading your account."
          comment="This message is visible when the user has a freemium account and is not admin"
        />
      )}
    </p>
  );
};

export default FreemiumMessage;
