import { useState } from 'react';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import { isTemplate } from 'agreement';
import { useSelector } from 'react-redux';

import useAgreement from 'hooks/use-agreement';
import { isAgreementOwner } from 'agreement/selectors';
import { getAccountFromSessionSelector } from 'reducers/session';

import ContractOptions from 'components/contract-options';
import Tooltip from 'components/tooltip';
import ActionsDotsIcon from 'components/icons/actions-dots';

import style from './document-actions.module.scss';

type Props = {
  agreementId: Oneflow.Agreement['id'],
}

const DocumentActions = ({
  agreementId,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const agreement = useAgreement(agreementId);
  const accountFromSession = useSelector(getAccountFromSessionSelector);
  const isOwner = isAgreementOwner(accountFromSession, agreement);

  if (!isOwner) {
    return null;
  }

  const isVisibleTemplate = isTemplate(agreement) && agreement.config?.templateActive;

  const documentActionsClasses = clsx(style.DocumentActionsContainer, { [style.Open]: isOpen });

  return (
    <ContractOptions
      isVisibleTemplate={isVisibleTemplate}
      agreementId={agreementId}
      onActionClick={() => null}
      onContractUpdate={() => null}
      disableDropdownIcon
      menuClassName={documentActionsClasses}
      onChange={setIsOpen}
    >
      <Tooltip
        message={(
          <Message
            id="More actions"
            comment="Tooltip message for document actions icon."
          />
        )}
        side="top"
      >
        <div className={style.DocumentActionsTrigger}>
          <ActionsDotsIcon height="16px" />
        </div>
      </Tooltip>
    </ContractOptions>
  );
};

export default DocumentActions;
