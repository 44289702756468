import clsx from 'clsx';

import {
  getFormattedPrice,
  shouldDisplayDiscount,
} from 'agreement/boxes/product';
import { formatNumberWithTrailingZeros } from 'utils/format-number-with-trailing-zeros';
import type {
  Column,
  Sums,
} from 'data-validators/entity-schemas/document-box/product-table';

import AffixedPrice from 'components/contract-boxes/product-table-box/product-table/affixed-price';

import style from './price-summation.module.scss';

type Props = {
  columns: Column[];
  sums: Sums;
  prefix?: string,
  postfix?: string,
  isResponsiveView: boolean,
  pricePrecision: number,
}

type PriceSums = { price: number, priceDiscounted: number};

const PriceSummation = ({
  columns,
  sums,
  prefix,
  postfix,
  isResponsiveView,
  pricePrecision,
}: Props) => {
  const isPrice1 = (priceItem: { key: string }) => priceItem.key === 'price_1';
  const isPrice2 = (priceItem: { key: string }) => priceItem.key === 'price_2';

  const price1Label = columns.find(isPrice1)?.label || '';
  const price2Label = columns.find(isPrice2)?.label || '';
  const isPrice1Enabled = columns.find(isPrice1)?.enabled;
  const isPrice2Enabled = columns.find(isPrice2)?.enabled;

  const discountedPriceClassNames = clsx(style.SummatedPrice, {
    [style.ExpandedDiscountedPrice]: !isResponsiveView,
    [style.ResponsiveDiscountedPrice]: isResponsiveView,
  });

  const priceLabelClassNames = clsx(style.PriceLabel, {
    [style.ResponsivePriceLabel]: isResponsiveView,
  });

  const priceSummationContainerClassNames = clsx(style.PriceSummationContainer, {
    [style.PriceSummationContainerResponsive]: isResponsiveView,
  });

  const renderPriceSum = (priceSums: PriceSums) => {
    const { price, priceDiscounted } = priceSums;
    const formattedPrice = formatNumberWithTrailingZeros(
      getFormattedPrice(price, pricePrecision), pricePrecision,
    );
    const formattedPriceDiscounted = formatNumberWithTrailingZeros(
      getFormattedPrice(priceDiscounted, pricePrecision), pricePrecision,
    );
    if (!shouldDisplayDiscount(price, priceDiscounted) && (price || price === 0)) {
      return (
        <span className={style.SummatedPrice} data-testid="price-summation">
          <AffixedPrice
            formattedPrice={formattedPrice}
            prefix={prefix}
            postfix={postfix}
          />
        </span>
      );
    }

    if (
      shouldDisplayDiscount(price, priceDiscounted) && (priceDiscounted || priceDiscounted === 0)
    ) {
      return (
        <div data-testid="price-summation-discounted" className={discountedPriceClassNames}>
          <span className={style.StrikeThrough}>
            <AffixedPrice
              formattedPrice={formattedPrice}
              prefix={prefix}
              postfix={postfix}
            />
          </span>
          <span>
            <AffixedPrice
              formattedPrice={formattedPriceDiscounted}
              prefix={prefix}
              postfix={postfix}
            />
          </span>
        </div>
      );
    }

    return null;
  };

  const renderPriceSummationSection = (priceLabel: string, priceSums: PriceSums) => (
    <div className={style.PriceSummation}>
      <p className={priceLabelClassNames}>
        {priceLabel !== '' ? `${priceLabel}:` : null}
      </p>
      {renderPriceSum(priceSums)}
    </div>
  );

  return (
    <div className={priceSummationContainerClassNames} data-testid="price-summation-container">
      {isPrice1Enabled && renderPriceSummationSection(price1Label, {
        price: sums.price_1,
        priceDiscounted: sums?.price_1_discounted,
      })}
      {isPrice2Enabled && renderPriceSummationSection(price2Label, {
        price: sums.price_2,
        priceDiscounted: sums?.price_2_discounted,
      })}
    </div>
  );
};

export default PriceSummation;
