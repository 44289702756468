// @flow

import {
  Editor,
  Transforms,
} from 'slate';

import { blockPlugin } from '../block-button';
import { isTextStyleElement } from '../text-styles/text-styles-plugin';

export const clearMarks = (editor: any) => {
  Editor.removeMark(editor, 'bold');
  Editor.removeMark(editor, 'italic');
  Editor.removeMark(editor, 'underline');
  Editor.removeMark(editor, 'strikethrough');
  Editor.removeMark(editor, 'superscript');
  Editor.removeMark(editor, 'subscript');
  Editor.removeMark(editor, 'color');
  Editor.removeMark(editor, 'backgroundColor');
  Editor.removeMark(editor, 'style');
  Editor.removeMark(editor, 'fontSize');
  Transforms.setNodes(editor, {
    style: undefined,
    fontSize: undefined,
    textAlign: undefined,
    color: undefined,
    backgroundColor: undefined,
  });
};

const clearBlockFormatting = (editor: any) => {
  Transforms.setNodes(editor, {
    style: undefined,
    fontSize: undefined,
    textAlign: undefined,
    color: undefined,
    backgroundColor: undefined,
    marginLeft: undefined,
  }, {
    match: (n) => (
      n.type === 'table-cell'
      || n.type === 'paragraph'
      || n.type === 'list-item'
      || blockPlugin.LIST_TYPES.includes(n.type)
      || isTextStyleElement(n)
    ),
    mode: 'all',
  });
};

export const clearFormatting = (editor: any) => {
  clearMarks(editor);
  clearBlockFormatting(editor);
};
