import Subscription from 'components/subscription/subscription';
import InvoiceDetails from 'components/invoice-details';
import BillingDetails from 'components/billing-details';

import type { Account } from 'types/account';
import type { Billing } from 'types/billing';
import type { AccountSeats } from 'types/account-seats';

import style from './billing-page.module.scss';

type Props = {
  account: Account,
  billing: Billing,
  accountSeats: AccountSeats,
  dateFormat: string,
}

const BillingPage = ({
  billing,
  accountSeats,
  account,
  dateFormat,
}: Props) => (
  <div className={style.Wrapper}>
    <section className={style.Container}>
      <Subscription
        billing={billing}
        accountSeats={accountSeats}
        account={account}
        dateFormat={dateFormat}
      />
    </section>
    <section className={style.Container}>
      <InvoiceDetails accountId={account.id} billing={billing} />
    </section>
    <section className={style.Container}>
      <BillingDetails accountId={account.id} billing={billing} />
    </section>
  </div>
);

export default BillingPage;
