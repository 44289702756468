import {
  Editor,
  Element as SlateElement,
} from 'slate';

export const getCurrentNode = (editor) => {
  const [match] = Editor.nodes(editor, {
    match: (n, path) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type !== 'table' && path.length === 1,
    mode: 'lowest',
  });

  return match;
};

export const isPageBreakAllowed = (editor) => !!getCurrentNode(editor);

export const addPageBreak = (editor) => {
  Editor.insertNode(editor, {
    type: 'page-break',
    children: [{ text: '' }],
  });
  Editor.insertNode(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  });
};
