import log from 'logging';
import { matchPath } from 'react-router';
import { loadSingleFont } from 'font-manager';

import {
  ERROR_404,
  ERROR_404_GUEST,
  ERROR_403,
  ERROR_LOCKED,
  ERROR_502,
  ERROR_UNKNOWN,
} from './constants';

export const getErrorStatusCode = (error: any) => {
  if (error.cause === 'invalid_agreement_id') {
    return 404;
  }

  if (!error.body?.status_code && error.response?.status === 502) {
    return 502;
  }

  return error.body?.status_code || 0;
};

export const getErrorMessages = (error: any, isGuest: boolean) => {
  const statusCode = getErrorStatusCode(error);

  if (error.body?.api_error_code === 4999) {
    return ERROR_LOCKED;
  }

  switch (statusCode) {
    case 403: return ERROR_403;
    case 404: return isGuest ? ERROR_404_GUEST : ERROR_404;
    case 502: return ERROR_502;
    default: return ERROR_UNKNOWN;
  }
};

export const agreementHasMFA = (error: any, guestToken: string) => (
  getErrorStatusCode(error) === 401 && error.body.api_error_code === 4108 && Boolean(guestToken)
);

export const redirectToMFA = (
  agreementId: number,
  guestToken: string,
  isNewDesignEnabled: boolean,
) => {
  const urlPrefix = isNewDesignEnabled ? '/documents' : '/contracts';
  let path = `${urlPrefix}/${agreementId}/authorize/at/${guestToken}`;

  const match = matchPath(window.location.pathname, {
    path: `${urlPrefix}/:agreementId/at/:guestToken/m/:messageId`,
    exact: true,
  });
  if (match?.params.messageId) {
    path = `${path}/m/${match.params.messageId}`;
  }

  window.location.assign(path);
};

export const loadWorkSansFont = async () => {
  try {
    await loadSingleFont('Work Sans');
  } catch (error) {
    log.error(error);
  }
};
