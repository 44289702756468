// @flow

import { type MessageTranslator } from '@oneflowab/pomes';
import listItemsWithAnd from 'utils/list-items-with-and';
import {
  getRef,
  getEventOwner,
  getEventParticipants,
  isRoleChangeToDraftApprover,
  isRoleChangeFromDraftApprover,
  isRoleChangeToPendingStateApprover,
  isRoleChangeFromPendingStateApprover,
} from 'agreement/event';

import { isViewer } from 'agreement/participant/participant';
import isOrganizer from 'agreement/participant/is-organizer';
import isSignatory from 'agreement/participant/is-signatory';
import isInfluencer from 'agreement/participant/is-influencer';
import {
  eventHasRef,
  getFormattedAttribute,
  getFormattedValue,
  getFullnameList,
  getParticipantName,
} from '../helpers';
import {
  auditTrailRolesCreators,
} from '../constants';

export const participantPublish = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const participantIds: Array<number> = getRef(event, 'participantIds');
  const names = getFullnameList(agreement, event, message);
  const participantRoles = getRef(event, 'participantRoles') || {};

  const pendingStateApprovers = participantIds.filter((id) => Array.isArray(participantRoles[id])
    && participantRoles[id].includes('PENDING_STATE_APPROVER'));

  if (pendingStateApprovers.length) {
    return {
      text: message({
        id: '{names} was invited to approve.',
        pluralId: '{names} were invited to approve.',
        pluralCondition: 'participantCount',
        values: {
          names: listItemsWithAnd(names),
          participantCount: participantIds.length,
        },
        comment: 'Audit trail message',
      }),
      showEditIcon: true,
      byLine: true,
      icon: 'new-check',
    };
  }

  return {
    text: message({
      id: '{names} was given access.',
      pluralId: '{names} were given access.',
      pluralCondition: 'participantCount',
      values: {
        names: listItemsWithAnd(names),
        participantCount: participantIds.length,
      },
      comment: 'Audit trail message',
    }),
    showEditIcon: true,
    byLine: true,
  };
};

export const participantSign = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const markedAsSigned = getRef(event, 'marked_as_signed');
  const signMethod = getRef(event, 'sign_method');
  const name = getRef(event, 'name') || getParticipantName(getEventOwner(agreement, event));
  const signMethodText = getFormattedValue('sign_method_short', signMethod, message);

  if (markedAsSigned) {
    return {
      text: message({
        id: 'Marked as signed by {name}.',
        values: { name },
        comment: 'Audit trail message',
      }),
      byLine: false,
    };
  }

  if (signMethodText) {
    return {
      text: message({
        id: '{name} signed with {signMethodText}.',
        values: { name, signMethodText },
        comment: 'Audit trail message',
      }),
      byLine: false,
    };
  }

  return {
    text: message({
      id: '{name} signed.',
      values: { name },
      comment: 'Audit trail message',
    }),
    byLine: false,
  };
};

export const participantSignAttachment = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const name = getRef(event, 'name');

  return {
    text: message({
      id: 'The attachment {name} was also signed.',
      values: { name },
      comment: 'Audit trail message',
    }),
    byLine: false,
  };
};

export const participantDecline = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const markedAsDeclined = getRef(event, 'marked_as_declined');
  const owner = getEventOwner(agreement, event);

  if (markedAsDeclined) {
    return {
      text: message({
        id: 'Marked as declined by {name}.',
        values: { name: getParticipantName(owner) },
        comment: 'Audit trail message',
      }),
      byLine: false,
    };
  }
  return {
    text: message({
      id: '{name} declined.',
      values: { name: getParticipantName(owner) },
      comment: 'Audit trail message',
    }),
    byLine: false,
  };
};

export const participantAdd = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const participant = getEventParticipants(event)[0];

  if (participant && isSignatory(participant)) {
    return {
      text: message({
        id: '{name} was added as a signatory.',
        values: { name: getParticipantName(participant) },
        comment: 'Audit trail message',
      }),
    };
  }

  if (participant && isOrganizer(participant)) {
    const hasParticipantRole = eventHasRef(event, 'participantRoles');
    const participantRoles = getRef(event, 'participantRoles');
    const participantRole = participantRoles?.length && participantRoles[0];

    if (hasParticipantRole && participantRole === 'DRAFT_APPROVER') {
      return {
        text: message({
          id: '{name} was added as an internal approver.',
          values: { name: getParticipantName(participant) },
          comment: 'Audit trail message',
        }),
        icon: 'line-icon user-added',
      };
    }

    return {
      text: message({
        id: '{name} was added as an organizer.',
        values: { name: getParticipantName(participant) },
        comment: 'Audit trail message',
      }),
    };
  }

  if (participant && isViewer(participant)) {
    return {
      text: message({
        id: '{name} was added as a viewer.',
        values: { name: getParticipantName(participant) },
        comment: 'Audit trail message',
      }),
    };
  }

  // Pending approval flow: special case
  if (participant && isInfluencer(participant)) {
    const hasParticipantRole = eventHasRef(event, 'participantRoles');
    const participantRoles = getRef(event, 'participantRoles');
    const participantRole = participantRoles?.length && participantRoles[0];

    if (hasParticipantRole && participantRole === 'PENDING_STATE_APPROVER') {
      return {
        text: message({
          id: '{name} was added as an approver.',
          values: { name: getParticipantName(participant) },
          comment: 'Audit trail message',
        }),
        icon: 'line-icon user-added',
      };
    }

    return {
      text: message({
        id: '{name} was added as an influencer.',
        values: { name: getParticipantName(participant) },
        comment: 'Audit trail message',
      }),
    };
  }

  return null;
};

export const participantDelegate = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const participant = getEventParticipants(event)[0];
  const owner = getEventOwner(agreement, event);
  const participantRoles = getRef(event, 'participantRoles') || [];

  if (
    Array.isArray(participantRoles)
    && participantRoles.length
    && participantRoles[0] === 'PENDING_STATE_APPROVER'
  ) {
    return {
      text: message({
        id: '{name} delegated their approval rights and added {added-name} as an approver.',
        values: {
          name: getParticipantName(owner),
          'added-name': getParticipantName(participant),
        },
        comment: 'Audit trail message',
      }),
      byLine: false,
    };
  }

  return {
    text: message({
      id: '{name} delegated their signing rights and added {added-name} as a signatory.',
      values: {
        name: getParticipantName(owner),
        'added-name': getParticipantName(participant),
      },
      comment: 'Audit trail message',
    }),
    byLine: false,
  };
};

export const participantDisable = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const participant = getEventParticipants(event)[0];

  return {
    text: message({
      id: '{name} was removed.',
      values: {
        name: getParticipantName(participant),
      },
      comment: 'Audit trail message',
    }),
  };
};

export const participantFirstVisit = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const owner = getEventOwner(agreement, event);

  return {
    text: message({
      id: '{name} opened for the first time.',
      values: {
        name: getParticipantName(owner),
      },
      comment: 'Audit trail message',
    }),
    byLine: false,
  };
};

export const participantUpdate = (
  event: AgreementEvent,
  message: MessageTranslator,
) => {
  const participant = getEventParticipants(event)[0];

  return {
    text: message({
      id: '{name} was updated.',
      values: {
        name: getParticipantName(participant),
      },
      comment: 'Audit trail message',
    }),
  };
};

export const participantUpdateItem = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const key = getRef(event, 'key');
  const attribute = getFormattedAttribute(key, 'participant');
  let to = getRef(event, 'to');
  let from = getRef(event, 'from');

  // we need to store the original from value, since getToRole() relies on it
  const originalFrom = from;

  const isTo = Boolean(to && to.length);
  const isFrom = Boolean(from && from.length);
  const draftApproverFormattedValue = getFormattedValue('roles', 1000, message);
  const pendingStateApproverFormattedValue = getFormattedValue('roles', 1001, message);

  // special cases for draft/pending approval flows
  // only used for changes to/from organizer/influencer (events with type 'roles')
  // all other cases are handled separately
  const getFromRole = () => {
    if (to[0] === 'PENDING_STATE_APPROVER') {
      // if we are changing to pending approver, we know it is from influencer
      return getFormattedValue('role', 0, message);
    }
    // otherwise we are changing to draft approver and we know it is from organizer
    return getFormattedValue('role', 2, message);
  };

  const getToRole = () => {
    if (originalFrom[0] === 'PENDING_STATE_APPROVER') {
      // if we are changing from pending approver, we know it is to influencer
      return getFormattedValue('role', 0, message);
    }
    // otherwise we are changing from draft approver and we know it is from to
    return getFormattedValue('role', 2, message);
  };

  switch (key) {
    case 'type':
      if (isRoleChangeToPendingStateApprover(agreement, event.id)) {
        to = auditTrailRolesCreators(1001)();
      } else if (isRoleChangeToDraftApprover(agreement, event.id)) {
        to = auditTrailRolesCreators(1000)();
      } else {
        to = getFormattedValue('role', to, message);
      }

      if (isRoleChangeFromPendingStateApprover(agreement, event.id)) {
        from = auditTrailRolesCreators(1001)();
      } else if (isRoleChangeFromDraftApprover(agreement, event.id)) {
        from = auditTrailRolesCreators(1000)();
      } else {
        from = getFormattedValue('role', from, message);
      }
      break;
    case 'sign_method':
      to = getFormattedValue('sign_method', to, message);
      from = getFormattedValue('sign_method', from, message);
      break;
    case 'country':
    case 'company_country':
      to = getFormattedValue('country', to, message);
      from = getFormattedValue('country', from, message);
      break;
    case 'delivery_channel':
      to = getFormattedValue('delivery_channel', to, message);
      from = getFormattedValue('delivery_channel', from, message);
      break;
    case 'roles': // change between organizer/draft approver and influencer/pending state approver
      /*
      Careful about moving the logic around, since the current implementation kind of relies
      on this order
      Should be refactored, but that would be a bigger change; maybe when we change the backend
      implementation
      */
      if (isFrom && from[0] === 'PENDING_STATE_APPROVER') {
        from = pendingStateApproverFormattedValue;
      } else if (isFrom && from[0] === 'DRAFT_APPROVER') {
        from = draftApproverFormattedValue;
      } else {
        from = getFromRole();
      }

      if (isTo && to[0] === 'PENDING_STATE_APPROVER') {
        to = pendingStateApproverFormattedValue;
      } else if (isTo && to[0] === 'DRAFT_APPROVER') {
        to = draftApproverFormattedValue;
      } else {
        to = getToRole();
      }
      break;
    default:
    // No preparing needed. Handled in the next code block
  }

  if (from) {
    if (!to) {
      to = `<i>${message({
        id: 'empty',
        comment: 'Audit trail partial message',
      })}</i>`;
    }
    return {
      text: message({
        id: '{attribute-name} was changed from {from-value} to {to-value}.',
        values: {
          'attribute-name': attribute,
          'from-value': from,
          'to-value': to,
        },
        comment: 'Audit trail message',
      }),
    };
  }

  return {
    text: message({
      id: '{attribute-name} was set to {to-value}.',
      values: {
        'attribute-name': attribute,
        'to-value': to,
      },
      comment: 'Audit trail message',
    }),
  };
};
