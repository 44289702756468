// Temporary replacement for react 18's useSyncExternalStore
import { useState, useEffect } from 'react';

const useSyncExternalStore = <Snapshot>(
  subscribe: (onStoreChange: () => void) => () => void,
  getSnapshot: () => Snapshot,
) => {
  const [state, setState] = useState(getSnapshot);
  const [forceRerender, setForceRerender] = useState({});

  useEffect(() => {
    // Update the state with the current snapshot
    setState(getSnapshot());

    // Subscribe to the external store
    const unsubscribe = subscribe(() => {
      setState(getSnapshot());
      setForceRerender({});
    });

    // Unsubscribe on cleanup
    return unsubscribe;
  }, [subscribe, getSnapshot]);

  return state;
};

export default useSyncExternalStore;
