import Svg from 'components/svg';

const Be = Svg((
  <>
    <path d="M170.667 335.724H8.828A8.829 8.829 0 0 1 0 326.896V9.103A8.829 8.829 0 0 1 8.828.275h161.839v335.449z" fill="#464655" />
    <path fill="#FFE15A" d="M170.67.276h170.67v335.448H170.67z" />
    <path d="M503.172 335.724H341.333V.276h161.839A8.829 8.829 0 0 1 512 9.104v317.793a8.828 8.828 0 0 1-8.828 8.827z" fill="#FF4B55" />
  </>
), { viewBox: '0 0 512 336', fill: 'none' });

Be.displayName = 'Be';

export default Be;
