// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

export type Props = {
  apiToken: ApiToken,
  children: Function,
  removeState: RemoveState,
  resetRemoveState: Function,
  revokeApiToken: () => void,
};

export class RevokeApiTokenConfirmation extends React.Component<Props> {
  handleConfirm = () => {
    const { removeState, revokeApiToken } = this.props;

    if (removeState.loading) {
      return;
    }

    revokeApiToken();
  };

  renderBody() {
    const { apiToken } = this.props;

    return (
      <Message
        id="Revoking a token will disable access for any integration currently using it. Are you sure you want to revoke the {apiToken} token?"
        comment="Modal text for revoking API token."
        values={{
          apiToken: <Badge label={apiToken.description} kind="name" />,
        }}
      />
    );
  }

  render() {
    const {
      children,
      removeState,
      resetRemoveState,
      revokeApiToken,
    } = this.props;

    return (
      <RemoveConfirm
        onConfirm={revokeApiToken}
        confirmState={removeState}
        resetConfirmState={resetRemoveState}
        confirmMessage={(this.renderBody())}
        modalKey="revoke api token modal"
      >
        {children}
      </RemoveConfirm>
    );
  }
}
