// @flow
import {
  put,
  get,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

export type UpdateParticipantEmailParams = {
  agreement: Agreement,
  participantId: number,
  email: string | null,
  guestToken?: string,
};

export type UpdateParticipantEmail = (
  params: UpdateParticipantEmailParams,
) => Promise<any>;

export const updateParticipantEmail: UpdateParticipantEmail = (params) => {
  const options = {};
  if (params.guestToken) {
    options.token = params.guestToken;
  }

  return put({
    url: `/agreements/${params.agreement.id}/participants/${params.participantId}`,
    token: params.guestToken,
    body: ({
      email: params.email,
    }),
  }, options).then(extractResponseBodyAsJSON);
};

type FetchSignMethods = (data: any) => Promise<any>;

export const fetchParticipantEidSignMethods: FetchSignMethods = async ({
  agreementId,
  participantId,
  guestToken,
}) => {
  const response = await get({
    url: `/agreements/${agreementId}/participants/${participantId}/eid_sign_methods`,
  }, {
    token: guestToken,
  });

  return extractResponseBodyAsJSON(response);
};
