import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import { indexOf, sortBy, values } from 'lodash';

import useAgreement from 'hooks/use-agreement';
import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';
import agreements from 'reducers/entities/agreements';

import SelectField, { Props as SelectFieldProps } from 'components/document-tabs/settings-tab/components/select-field';
import Label from 'components/document-tabs/settings-tab/components/label';
import { areSettingsDisabled, shouldBeDisabled } from 'components/document-tabs/settings-tab/helpers';
import CustomizeOptions from 'components/document-tabs/settings-tab/sections/signing-and-security/customize-options';

import { getPositionFromSessionSelector } from 'reducers/session';
// eslint-disable-next-line import/no-named-as-default
import { deliveryChannels, deliveryChannelsOrder } from 'components/delivery-channels';
import { getDeliveryChannelPayload, getAvailableSignMethodIds, getDefaultDeliveryChannel } from './helpers';
import style from '../../signing-and-security.module.scss';

type Props = {
  agreementId: number;
};

const allOptions = sortBy(values(deliveryChannels),
  (option) => indexOf(deliveryChannelsOrder, option.value));

type Option = typeof allOptions[number];

const DeliveryChannel = ({ agreementId }: Props) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const { resetRPCStates } = useAPIError(agreementId);
  const availableSigningMethodIds = getAvailableSignMethodIds(agreement);
  const myPosition = useSelector(getPositionFromSessionSelector);
  const settingsDisabled = areSettingsDisabled(agreement, myPosition);

  const onChange: SelectFieldProps<Option['value']>['onChange'] = (value, options) => {
    resetRPCStates();
    dispatch(agreements.updateAgreementPreferences({
      id: agreementId,
      data: {
        config: getDeliveryChannelPayload(agreement, value),
      },
      pipe: {
        onFailure: options?.onFailure,
      },
    }));
  };

  const options = allOptions.filter((option) => availableSigningMethodIds.includes(option.value));

  const label = (
    <Label
      hint={(
        <Message
          id="Select the default way to send documents and notifications to counterparties. The selected method can be changed when adding participants."
          comment="Tooltip for delivery channel type dropdown"
        />
      )}
      customClassName={settingsDisabled ? style.Disabled : undefined}
    >
      <Message
        id="Delivery channel"
        comment="Label for delivery channel type dropdown"
      />
    </Label>
  );

  return (
    <>
      <SelectField
        disabled={shouldBeDisabled(agreement) || settingsDisabled}
        label={label}
        options={options}
        value={getDefaultDeliveryChannel(agreement)}
        name="default-delivery-channel"
        onChange={onChange}
      />
      <CustomizeOptions agreementId={agreementId} type="deliveryChannels" />
    </>
  );
};

export default DeliveryChannel;
