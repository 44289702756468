// @flow
import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Badge from 'components/badge';
import Confirmable from 'components/confirmable';

type Props = {
  selectedGroups: Array<Group>,
  position: Position,
  removeState: RemoveState,
  resetRemoveState: () => void,
  removeUserFromGroups: () => void,
  children: () => React.Node,
};

export const handleConfirm = ({ removeUserFromGroups, isLoading }: Props) => () => {
  if (isLoading) {
    return;
  }

  removeUserFromGroups();
};

export const RemoveUserFromGroups = ({
  position,
  selectedGroups,
  removeState,
  resetRemoveState,
  removeUserFromGroups,
  children,
}: Props) => (
  <Confirmable
    header={(
      <Message
        id="Confirm removing user from groups"
        comment="Confirm modal title. Remove user from groups confirmation modal."
      />
    )}
    body={(
      <p>
        <Message
          id="You are about to remove {userName} from {groupName}."
          pluralId="You are about to remove {userName} from {count} groups."
          pluralCondition="count"
          values={{
            userName: <Badge label={position.fullname} kind="name" />,
            groupName: <Badge label={selectedGroups[0].name} kind="name" />,
            count: selectedGroups.length,
          }}
          comment="Confirmation message in modal. In remove user from groups modal."
        />
      </p>
    )}
    error={removeState.error}
    success={removeState.success}
    data-testid="remove-user-from-groups"
    onEnter={handleConfirm({ removeUserFromGroups, isLoading: removeState.loading })}
    onOpen={resetRemoveState}
    isConfirmLoading={removeState.loading}
    onConfirm={removeUserFromGroups}
    modalKey="remove groups for user modal"
  >
    {children}
  </Confirmable>
);
