import { Message } from '@oneflowab/pomes';

import Button from 'components/button';

type Props = {
  onClick: () => void,
  disabled?: boolean,
};

export const PreviousButton = ({ onClick, disabled }: Props) => (
  <Button
    data-testid="previous"
    disabled={disabled}
    onClick={onClick}
    kind="linkSeparate"
  >
    <Message
      id="Previous"
      comment="Button that goes to the previous step in a multistep process."
    />
  </Button>
);

PreviousButton.defaultProps = {
  disabled: undefined,
};
