import { forwardRef, useContext } from 'react';
import type { Context } from 'react';

import { useComposedRefs } from 'hooks/compose-refs';

import { rootMenuStateContext } from './root';
import type { MenuStateContextValue } from './menu-state';
import type { DivProps } from './types';

export type ListProps = {
  scope?: Context<MenuStateContextValue | null>;
} & DivProps;

const List = forwardRef<HTMLDivElement, ListProps>(({
  children,
  scope = rootMenuStateContext,
  ...props
}, ref) => {
  const menuStateContextValue = useContext(scope);

  if (!menuStateContextValue) {
    throw new Error('menuStateContextValue must be used within a MenuStateProvider');
  }

  const { listRef } = menuStateContextValue;

  const composedRef = useComposedRefs(ref, listRef);

  return (
    <div {...props} ref={composedRef}>
      {children}
    </div>
  );
});

List.displayName = 'MenuList';

export default List;
