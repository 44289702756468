// @flow

import {
  keyBy,
  pickBy,
  union,
  values,
} from 'lodash';
import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';

import {
  getExtensions,
  createExtension,
  updateExtension,
} from 'oneflow-client/extensions';
import {
  oauthRefresh,
  revokeConnection,
} from 'oneflow-client/extensions/salesforce';
import { authorizeWithDynamics } from 'oneflow-client/extensions/dynamics-crm';
import { setupWithGeneric } from 'oneflow-client/extensions/generic';
import { authorizeWithPipedrive } from 'oneflow-client/extensions/pipedrive';
import type { NormalizedExtensions } from 'oneflow-client/extensions';

import apiWrapper from 'sagas/api-wrapper';

import extensions from 'reducers/entities/extensions';

import staticInfoList from 'extensions/static-info';
import { getTypeSpecificConfig } from 'extensions';

type MapperArgs = {
  data: NormalizedExtensions,
  action: any,
};

type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  const extensionsByKey = data.entities.extensions;
  const genericExtensions = values(pickBy(extensionsByKey, (extension) => extension.extensionClass === 'generic'));
  const decoratedExtensions = staticInfoList.filter((
    staticInfo,
  ) => extensionsByKey[staticInfo.key])
    .map((staticInfo) => ({
      ...staticInfo,
      ...extensionsByKey[staticInfo.key],
    }))
    .map((extension) => ({
      ...extension,
      config: {
        ...getTypeSpecificConfig(extension),
        ...extension.config,
      },
    }));
  const unifiedExtensions = union(decoratedExtensions, genericExtensions);

  yield put(extensions.setExtensions(keyBy(unifiedExtensions, 'key')));
}

type OauthMapperArgs = {
  data: {
    state: string,
  },
  action: {
    id: number,
  },
};

export function* setOauthState({ action, data }: OauthMapperArgs): Mapper {
  yield put(extensions.setExtensions({
    [action.id]: {
      oauthState: data.state,
    },
  }));
}

type DynamicsAuthorizationMapperArgs = {
  data: {
    oauthLink: string,
  },
  action: {
    id: number,
  },
};

export function* setDynamicsAuthorizationState(
  { action, data }: DynamicsAuthorizationMapperArgs,
): Mapper {
  yield put(extensions.setExtensions({
    [action.id]: {
      oauthLink: data.oauthLink,
    },
  }));
}

type GenericSetupMapperArgs = {
  data: {
    status: string,
    setupUrl: string,
  },
  action: {
    id: number,
  },
};

export function* setGenericSetupState(
  { action, data }: GenericSetupMapperArgs,
): Mapper {
  yield put(extensions.setExtensions({
    [action.id]: {
      status: data.status,
      setupUrl: data.setupUrl,
    },
  }));
}

type PipedriveAuthorizationMapperArgs = {
  data: {
    status: string,
  },
  action: {
    id: number,
  },
};

export function* setPipedriveAuthorizationState(
  { action, data }: PipedriveAuthorizationMapperArgs,
): Mapper {
  yield put(extensions.setExtensions({
    [action.id]: {
      status: data.status,
    },
  }));
}

const mappers = {
  query: {
    mapper,
    request: getExtensions,
  },
  create: {
    mapper,
    request: createExtension,
  },
  update: {
    mapper,
    request: updateExtension,
  },
  rpcs: {
    refreshOauth: {
      name: 'refreshOauth',
      request: oauthRefresh,
      mapper: setOauthState,
    },
    revokeConnection: {
      name: 'revokeConnection',
      request: revokeConnection,
    },
    authorizeWithDynamics: {
      name: 'authorizeWithDynamics',
      request: authorizeWithDynamics,
      mapper: setDynamicsAuthorizationState,
    },
    authorizeWithPipedrive: {
      name: 'authorizeWithPipedrive',
      request: authorizeWithPipedrive,
      mapper: setPipedriveAuthorizationState,
    },
    setupWithGeneric: {
      name: 'setupWithGeneric',
      request: setupWithGeneric,
      mapper: setGenericSetupState,
    },
  },
};

const extensionsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: extensions,
  mappers,
});

export default extensionsSagas;
