import { flatten, filter } from 'lodash';

import normalizeEntity from 'normalized-redux/entity-normalizer';

const normalizedUserAccounts = normalizeEntity({
  entity: 'userAccount',
  rpcs: ['grantUserAccountAccess', 'removeUserAccountAccess', 'getUsersAccountBindings'],
});

export const filterUserAccountBindingsSelector = (state, {
  resourceId,
  actorId,
}) => (
  filter(normalizedUserAccounts.getAllUserAccountsSelector(state), (binding) => (
    binding?.resource?.id === resourceId && binding?.position?.id === actorId
  ))
);

export const getUserAccountBindings = (state, { ids, account }) => {
  const userAccountBindings = ids.map((id) => (
    filterUserAccountBindingsSelector(state, {
      resourceId: account.id,
      actorId: id,
    })
  ));

  return flatten(userAccountBindings);
};

export default normalizedUserAccounts;
