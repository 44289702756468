import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { omit } from 'lodash';
import type { MutableRefObject } from 'react';

import { useQueryStringParams } from 'hocs/query-string-params';

import { getActiveFilters, makeFilterValueGetter, pageSanitizer } from './utils';
import type { SubscribedFilters, Subscribe } from './types';

type FilterContextType = {
  subscribe: Subscribe;
  activeFilters: SubscribedFilters;
  availableFilters: MutableRefObject<SubscribedFilters>;
};

const DEFAULT_AVAILABLE_FILTERS = {
  page: makeFilterValueGetter(1, pageSanitizer),
};

export const FilterContext = createContext<FilterContextType | null>(null);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterContextProvider');
  }
  return context;
};

type FilterContextProviderProps = {
  children: React.ReactNode;
  defaultAvailableFilters?: SubscribedFilters;
};

export const FilterContextProvider = ({
  children,
  // Use to override the default available filters when you don't want the page filter to be
  // included in the params.
  defaultAvailableFilters = DEFAULT_AVAILABLE_FILTERS,
}: FilterContextProviderProps) => {
  const { params } = useQueryStringParams();
  const availableFilters = useRef<any>(defaultAvailableFilters);

  // The filters that are currently being applied
  const activeFilters = useMemo(() => (
    getActiveFilters(params, availableFilters.current)
  ), [params]);

  const subscribe = useCallback<Subscribe>((key, defaultValue, sanitize) => {
    availableFilters.current = {
      ...defaultAvailableFilters,
      ...availableFilters.current || {},
      [key]: makeFilterValueGetter(defaultValue, sanitize),
    };

    const unsubscribe = () => {
      availableFilters.current = omit(availableFilters.current, key);
    };

    return unsubscribe;
  }, []);

  return (
    <FilterContext.Provider value={{
      subscribe,
      activeFilters,
      availableFilters,
    }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const withFilterConsumer = (Component: React.ComponentType) => {
  const WithFilterConsumer = (props: any) => (
    <FilterContext.Consumer>
      {(context) => <Component {...props} {...context} />}
    </FilterContext.Consumer>
  );

  return WithFilterConsumer;
};

export const withFilterContextProvider = (Component: React.ComponentType) => {
  const WithFilterContextProvider = (props: any) => (
    <FilterContextProvider>
      <Component {...props} />
    </FilterContextProvider>
  );

  return WithFilterContextProvider;
};
