import { useSelector } from 'react-redux';
import clsx from 'clsx';
import type { MutableRefObject } from 'react';

import { getCurrentContractId } from 'reducers/current-contract';
import useFormatting from 'components/contract-boxes/box-wrapper/hooks/use-formatting';

import style from './droppable-drag-overlay-field.module.scss';

type Props = {
  droppableDragOverlayRef: MutableRefObject<HTMLDivElement | null>,
  dataFieldValueObject: DataFieldValue,
  scale?: number,
};

export const DroppableDragOverlayField = ({
  droppableDragOverlayRef,
  dataFieldValueObject,
  scale,
}: Props) => {
  const agreementId = useSelector(getCurrentContractId);
  const formattingStyles = useFormatting(agreementId);
  const { value, name } = dataFieldValueObject;
  const dataFieldValue = value ?? '';
  const isValueEmpty = dataFieldValue === '';

  return (
    <div
      className={style.ScaledDroppableDragOverlay}
      style={{
        ...formattingStyles,
        transformOrigin: 'top left',
        transform: `translate3d(0, 0, 0) scale3d(${scale}, ${scale}, 1)`,
      }}
      data-testid="scaled-droppable-drag-overlay"
    >
      <div
        className={style.DroppableDragOverlayFieldWrapper}
        ref={droppableDragOverlayRef}
      >
        <div className={style.DataFieldName}>
          {name}
        </div>
        <div
          className={clsx(style.DroppableDragOverlayField, {
            [style.FieldEmpty]: isValueEmpty,
          })}
        >
          {dataFieldValue}
        </div>
      </div>
    </div>
  );
};
