import { useEffect } from 'react';
import webSocket from 'web-socket';
import { useDispatch } from 'react-redux';

import useAgreement from 'hooks/use-agreement';
import agreementsReducer from 'reducers/entities/agreements';
import { AGREEMENT_VIDEO_PROCESSING, AGREEMENT_WITHOUT_VIDEO, AGREEMENT_WITH_VIDEO } from 'agreement/constants';
import { checkAcl } from 'components/acl';

const getPusherEvents = () => [
  {
    event: 'agreement:video:remove',
    agreementVideoValue: AGREEMENT_WITHOUT_VIDEO,
  },
  {
    event: 'agreement:video:processing:start',
    agreementVideoValue: AGREEMENT_VIDEO_PROCESSING,
  },
  {
    event: 'agreement:video:processing:done',
    agreementVideoValue: AGREEMENT_WITH_VIDEO,
  },
];

type Props = {
  agreementId: Oneflow.Document['id'];
  callback: (agreementVideoValue: number) => void;
};

type ProcessData = {
  event: string;
  success: boolean;
};

const subscribeToEvents = ({
  agreementId,
  callback,
}: Props) => {
  const subscriptions: (() => void)[] = [];

  const events = getPusherEvents();
  events.forEach(({ event, agreementVideoValue }) => {
    const unsubscribe = webSocket.subscribe({
      channelName: `private-agreement-${agreementId}`,
      event,
      eventCallback: (processData: ProcessData) => {
        if (event === 'agreement:video:processing:done' && !processData.success) {
          callback(AGREEMENT_WITHOUT_VIDEO);
        } else {
          callback(agreementVideoValue);
        }
      },
    });
    if (unsubscribe) {
      subscriptions.push(unsubscribe);
    }
  });

  return subscriptions;
};

const useAgreementVideoListener = (
  agreementId: Oneflow.Document['id'],
) => {
  const dispatch = useDispatch();
  const agreement = useAgreement(agreementId);
  const canAddVideo = checkAcl(agreement.acl, 'agreement:video:add');

  useEffect(() => {
    let subscriptions: (() => void)[] = [];
    if (canAddVideo) {
      subscriptions = subscribeToEvents({
        agreementId,
        callback: (agreementVideoValue: number) => {
          dispatch(agreementsReducer.setAgreements({
            [agreementId]: {
              welcomeVideo: agreementVideoValue,
            },
          }));
        },
      });
    }

    return () => {
      subscriptions?.forEach((unsubscribe) => unsubscribe?.());
    };
  }, [dispatch, agreementId, canAddVideo]);
};

export default useAgreementVideoListener;
