import {
  useCallback, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormRenderProps } from 'react-final-form';
import { Message } from '@oneflowab/pomes';
import { push } from 'connected-react-router';
import debounce from 'lodash/debounce';

import { USER_ROLE_SCOPE_ACCOUNT } from 'user';
import rolesReducer from 'reducers/entities/roles';
import groupAccountsReducer from 'reducers/entities/group-accounts';
import groupsReducer from 'reducers/entities/groups';
import accountGroupsReducer from 'reducers/entities/account-groups';
import { getAccountFromSessionSelector } from 'reducers/session';
import ModalForm from 'hocs/modal-form';

import { getAccountGroupsQueryName } from 'routes/admin/account-access/account-access';
import Field from 'components/field';
import SelectField from 'components/select-field';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import CircularSpinnerIcon from 'components/icons/circular-spinner';

import styles from './grant-group-account-access.module.scss';

export const rolesQueryName = 'admin/group/accounts';
export const groupsQueryName = 'admin/groups/group-account-access';
const defaultPagination = {
  limit: 50,
  offset: 0,
};

const GROUP_FIELD = 'group';
const ACCOUNT_ROLE_FIELD = 'account role';

type Props = {
  group?: Oneflow.Group,
  children: JSX.Element | ((onClick: () => void) => JSX.Element),
  shouldRedirectToAccountAccess: boolean,
  renderedFromAccountAccessPage: boolean,
}

type FormValues = {
  accountRole: string,
}

type QueryRoles = { name?: string, pagination?: { limit: number, offset: number }};

const GrantGroupAccountAccess = ({
  group: groupFromProps,
  children,
  shouldRedirectToAccountAccess,
  renderedFromAccountAccessPage,
}: Props) => {
  const dispatch = useDispatch();
  const [group, setGroup] = useState(groupFromProps);
  const account = useSelector(getAccountFromSessionSelector);

  const rolesQuery = useSelector((state) => (
    rolesReducer.getQuerySelector(state, { name: rolesQueryName })
  ));

  const roles = useSelector((state) => (
    rolesReducer.getRolesSelector(state, { ids: rolesQuery.result })
  ));

  const groupsQuery = useSelector((state) => (
    groupsReducer.getQuerySelector(state, { name: groupsQueryName })
  ));

  const groups = useSelector((state) => (
    groupsReducer.getGroupsSelector(state, { ids: groupsQuery.result })
  ));

  const formState = useSelector((state) => (
    groupAccountsReducer.getGrantGroupAccountAccessSelector(state, { id: group?.id })
  ));

  const resetFormState = () => {
    dispatch(groupAccountsReducer.grantGroupAccountAccessReset({ id: group?.id }));
  };

  const queryRoles = useCallback((
    { name, pagination = defaultPagination }: QueryRoles,
  ) => {
    dispatch(rolesReducer.queryRoles({
      name: rolesQueryName,
      params: {
        scope: USER_ROLE_SCOPE_ACCOUNT,
        q: name,
      },
      pagination,
      sort: ['-type', 'name'],
    }));
  }, [dispatch]);

  const queryGroups = useCallback(
    ({ name, pagination = defaultPagination }: QueryRoles) => {
      dispatch(groupsReducer.queryGroups({
        name: groupsQueryName,
        params: {
          q: name,
        },
        pagination,
        sort: ['name'],
      }));
    }, [dispatch],
  );

  const handleLoadMoreAccountRoles = (additionalResults: number) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: rolesQueryName,
      params: { scope: USER_ROLE_SCOPE_ACCOUNT },
      additionalResults,
    }));
  };

  const handleLoadMoreGroups = (additionalResults: number) => {
    dispatch(groupsReducer.queryGroupsLoadMore({
      name: groupsQueryName,
      additionalResults,
    }));
  };

  const handleInputChange = useMemo(() => debounce((name: string, field: string) => {
    if (field === ACCOUNT_ROLE_FIELD) {
      queryRoles({ name });
    }
    if (field === GROUP_FIELD) {
      queryGroups({ name });
    }
  }, 500), [queryGroups, queryRoles]);

  const onOpenHandler = () => {
    queryRoles({});
    if (renderedFromAccountAccessPage) queryGroups({});
  };

  const renderBody = () => (
    <div className={styles.AccountRole}>
      {renderedFromAccountAccessPage
      && (
      <Field
        name="group"
        label={(
          <Message
            id="Group"
            comment="Input field label"
          />
        )}
        placeholder={(
          <Message
            id="Select group"
            comment="Input field placeholder"
          />
        )}
        component={SelectField}
        options={groups}
        valueKey="id"
        labelKey="name"
        isLoading={groupsQuery.loading}
        loadMoreItems={handleLoadMoreGroups}
        required
        isSearchable
        onInputChange={(name: string) => handleInputChange(name, GROUP_FIELD)}
        onChange={(selectedGroup: Oneflow.Group) => setGroup(selectedGroup)}
      />
      )}
      <Field
        name="accountRole"
        label={(
          <Message
            id="Account role"
            comment="Input field label"
          />
        )}
        placeholder={(
          <Message
            id="Select account role for this group"
            comment="Input field placeholder"
          />
        )}
        component={SelectField}
        options={roles}
        valueKey="id"
        labelKey="name"
        isLoading={rolesQuery.loading}
        loadMoreItems={handleLoadMoreAccountRoles}
        required
        isSearchable
        onInputChange={handleInputChange}
      />
    </div>
  );

  const renderActions = ({ closeConfirmation, formProps }: {
    closeConfirmation: () => void, formProps: FormRenderProps<FormValues>,
  }) => (
    <div className={styles.ActionButtons}>
      <CancelButton onClick={closeConfirmation} />
      <Button
        kind="primary"
        onClick={formProps.handleSubmit}
        disabled={formState.loading}
        icon={formState.loading ? CircularSpinnerIcon : null}
      >
        <Message
          id="Grant access"
          comment="Text for button to grant access."
        />
      </Button>
    </div>
  );

  const grantGroupAccountAccess = (
    { accountRole }: { accountRole: Oneflow.GroupAccountRoleBinding },
  ) => {
    const GROUP_ACCOUNT_BINDINGS_QUERY_NAME = `admin/groups/${group?.id}/account-access`;
    const actionData = {
      id: group?.id,
      data: {
        groupId: group?.id,
        roleId: accountRole.id,
      },
      pipe: {
        action: () => groupAccountsReducer.queryGroupAccounts({
          name: GROUP_ACCOUNT_BINDINGS_QUERY_NAME,
          params: {
            groupId: group?.id,
            actorType: 'group',
          },
          sort: ['-role.type', 'role.name'],
        }),
      },
    };

    if (shouldRedirectToAccountAccess) {
      actionData.pipe.action = () => push(`/admin/groups/${group?.id}/account-access`);
    }

    if (renderedFromAccountAccessPage) {
      actionData.pipe.action = () => accountGroupsReducer.queryAccountGroups({
        name: getAccountGroupsQueryName(account.id),
        params: {
          actorType: 'group',
        },
        pagination: defaultPagination,
        sort: ['name'],
      });
    }

    dispatch(groupAccountsReducer.grantGroupAccountAccess(actionData));
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Grant account access"
          comment="Modal title for granting account access"
        />
    )}
      body={renderBody()}
      onSubmit={grantGroupAccountAccess}
      formState={formState}
      resetFormState={resetFormState}
      onOpen={onOpenHandler}
      actions={renderActions}
      modalKey="grant group account access modal"
    >
      {children}
    </ModalForm>
  );
};

export default GrantGroupAccountAccess;
