// @flow

/* eslint-disable import/prefer-default-export */
/* eslint-disable quote-props */
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';

import fonts from './fonts';

const loadFontByUrl = (fontFaceMapper: () => FontFaces[]) => Promise.all(fontFaceMapper()
  .map((fontFace) => {
    document.fonts.add(fontFace);
    return fontFace.load();
  }));

export const loadSingleFont = async (font: string) => {
  const fontFaceMapper = fonts[font];
  if (!fontFaceMapper) {
    return;
  }

  await loadFontByUrl(fontFaceMapper);
};

export const loadContractFont = async (agreement: Agreement) => {
  if (isEmpty(agreement) || isEmpty(agreement.config) || isEmpty(agreement.config.formatting)) {
    return;
  }

  const { font } = agreement.config.formatting;
  const mainFont = head(font);

  if (!mainFont) {
    return;
  }

  const fontFaceMapper = fonts[mainFont];

  if (!fontFaceMapper) {
    return;
  }

  await loadFontByUrl(fontFaceMapper);
};

export const loadRemainingFonts = async (selectedFont: string) => {
  if (isEmpty(selectedFont)) {
    return;
  }

  const loadedFonts = Array.from(document.fonts.values());

  // Only load fonts that have not been loaded
  // Excluding Whisper since it's only used in handwritten signature
  const notLoadedFonts = Object.keys(fonts).filter(
    (font) => !loadedFonts.some((loadedFont) => loadedFont.family === font) && font !== 'Whisper',
  );

  await Promise.all(notLoadedFonts.map((key) => loadFontByUrl(fonts[key])));
};

export const loadHandwrittenFonts = async () => {
  const handwrittenFonts = [
    'Caveat',
    'Sacramento',
    'Whisper',
  ];

  await Promise.all(handwrittenFonts.map((key) => loadFontByUrl(fonts[key])));
};
