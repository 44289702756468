// @flow

import type { MessageTranslator } from '@oneflowab/pomes';

import { isUserActive } from 'user';

type Params = {
  users: Array<Position>,
  currentUserId?: number,
  creatorId: number,
  message: MessageTranslator,
};

function addUserLabels({
  users,
  currentUserId,
  creatorId,
  message,
}: Params) {
  return (users: any).map((user) => {
    // if session user becomes inactive he lose access to account
    // So we no need to label for the scenario user.id === currentUserId and !isUserActive(user)
    if (user.id === creatorId && !isUserActive(user) && !user.labeled) {
      return {
        ...user,
        labeled: true, // Defensive flag for when already labled list is passed
        fullname: message({
          id: '{user} (creator) (inactive)',
          values: { user: user.fullname },
          comment: 'Label to identify who created the reminder who has become inactive',
        }),
      };
    }

    if (!isUserActive(user) && !user.labeled) {
      return {
        ...user,
        labeled: true, // Defensive flag for when already labled list is passed
        fullname: message({
          id: '{user} (inactive)',
          values: { user: user.fullname },
          comment: 'Label to identify inactivated users in a list',
        }),
      };
    }

    if (user.id === currentUserId && !user.labeled) {
      return {
        ...user,
        labeled: true, // Defensive flag for when already labled list is passed
        isFixed: true, // Used to block removal in the dropdown
        fullname: message({
          id: '{user} (you)',
          values: { user: user.fullname },
          comment: 'Label for the user to identify himself/herself in a list',
        }),
      };
    }

    if (user.id === creatorId && !user.labeled) {
      return {
        ...user,
        labeled: true, // Defensive flag for when already labled list is passed
        fullname: message({
          id: '{user} (creator)',
          values: { user: user.fullname },
          comment: 'Label for the user to identify who created the reminder',
        }),
      };
    }

    return user;
  });
}

export default addUserLabels;
