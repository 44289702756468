// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './extension-content-overview.module.scss';

type Props = {
  extension: Extension,
};

export const ExtensionContentOverview = ({ extension }: Props) => (
  <>
    <h3 className={style.ExtensionContentHeader}>
      <Message
        id="Overview"
        comment="Section header for an extension details page."
      />
    </h3>
    <div className={style.ExtensionContentBody}>
      {extension.overview}
    </div>
  </>
);
