import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import { entries } from 'lodash';

import useFeatureFlag from 'hooks/use-feature-flag';
import agreementsReducer from 'reducers/entities/agreements';

import { DeleteContractBulk } from 'components/modals/delete-contract-bulk/delete-contract-bulk';
import DeleteIcon from 'components/icons/delete';
import Tooltip from 'components/tooltip';
import { useSelectionContext } from 'components/contract-list/selection-context';

import style from './bulk-delete-action.module.scss';

const hasDocumentDeletePermission = (document: Oneflow.Agreement) => (
  document.acl?.['agreement:remove'] === 'allow'
);

export const BulkDeleteAction = ({ queryName }: { queryName: string }) => {
  const isTrashEnabled = useFeatureFlag('temporaryDocumentTrashCan');
  const { selectedDocuments } = useSelectionContext();

  const selectedDocumentsData = useSelector((state) => (
    agreementsReducer.getAgreementsSelector(state, {
      ids: entries(selectedDocuments)
        .filter(([_, value]) => value)
        .map(([key]) => Number(key)),
    })
  ));

  const documentsThatCanNotBeDeleted = selectedDocumentsData.filter((document) => (
    !hasDocumentDeletePermission(document)
  ));

  const isDisabled = documentsThatCanNotBeDeleted.length === selectedDocumentsData.length;

  return (
    <DeleteContractBulk
      queryName={queryName}
    >
      {(openModal) => (
        <Tooltip
          message={(
            <Message
              id="Permission is required."
              comment="Label for the delete button when permission is missing"
            />
          )}
          side="top"
          hideContent={!isDisabled}
        >
          <button
            onClick={openModal}
            className={style.DeleteButton}
            disabled={isDisabled}
          >
            <DeleteIcon />
            {
              isTrashEnabled ? (
                <Message
                  id="Move to trash"
                  comment="Label for the trash button"
                />
              ) : (
                <Message
                  id="Delete"
                  comment="Label for the delete button"
                />
              )
            }
          </button>
        </Tooltip>
      )}
    </DeleteContractBulk>
  );
};
