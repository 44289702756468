// @flow

import * as React from 'react';
import { Element } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import {
  createTableBlockMatrix,
  getTableColumnCount,
} from 'components/rich-text-editor/editor-plugins/table-utils/block-matrix';

export const TableElementContext = React.createContext<any>();

type Props = {|
  children: React.Node,
  element: Element,
  colgroupRef: { current: any },
|};

export function TableElementProvider({
  children,
  element,
  colgroupRef,
}: Props) {
  const editor = useSlate();
  const tableMatrix = React.useMemo(() => (
    createTableBlockMatrix(element)
  ), [element]);
  const columnCount = React.useMemo(() => (
    getTableColumnCount(element)
  ), [element]);

  const [tableDomElement, setTableDomElement] = React.useState(null);
  React.useEffect(() => {
    setTableDomElement(ReactEditor.toDOMNode(editor, element));
  }, [editor, element]);

  const value = React.useMemo(() => ({
    tableElement: element,
    colgroupRef,
    tableMatrix,
    columnCount,
    tableDomElement,
  }), [element, colgroupRef, tableMatrix, columnCount, tableDomElement]);

  return (
    <TableElementContext.Provider value={value}>
      {children}
    </TableElementContext.Provider>
  );
}

const emptyContextValue = Object.freeze({
  tableElement: null,
  tableDomElement: null,
  columnCount: 0,
  colgroupRef: { current: null },
  tableMatrix: null,
});

const useTableElement = () => {
  const contextValue = React.useContext(TableElementContext);

  if (!contextValue) {
    return emptyContextValue;
  }

  return contextValue;
};

export default useTableElement;
