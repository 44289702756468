import { useState } from 'react';
import Message from 'components/message';
import Button from 'components/button';
import CancelSubscriptionFlow from 'components/cancel-subscription/flows/cancel-subscription-flow';

import type { FlowData } from 'types/cancellation-flow';

import MissingFeaturesDetails from 'components/modals/missing-features-details';
import LetUsHelp from '../let-us-help';

type Props = {
  onClose: () => void;
  onPreviousStep: () => void;
  setCurrentFlow: (flow: string) => void;
  onSyncFlowData: (data: FlowData) => void;
  onSubmit: (data: FlowData, submitHandler: () => void) => void;
};

const MissingFeatures = ({
  onClose,
  onPreviousStep,
  setCurrentFlow,
  onSyncFlowData,
  onSubmit,
}: Props) => {
  const [missingDetails, setMissingDetails] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <CancelSubscriptionFlow
      onCancel={onClose}
      setFlow={setCurrentFlow}
    >
      <CancelSubscriptionFlow.Steps>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="Help us improve"
              comment="Header in the missing features modal"
            />
          )}
          handlePreviousStep={onPreviousStep}
          allowContinue
        >
          <CancelSubscriptionFlow.StepBody>
            <MissingFeaturesDetails onChange={setMissingDetails} />
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            <Button
              kind="linkSeparate"
              onClick={onClose}
            >
              <Message
                id="Keep my Subscription Plan"
                comment="Button text for keeping the subscription"
              />
            </Button>
            <CancelSubscriptionFlow.ShowFlow
              kind="secondary"
              syncData={{ missing_details: missingDetails || '' }}
              onSyncFlowData={onSyncFlowData}
              flow="let_us_help"
            >
              <Message
                id="Continue to cancel"
                comment="Button text to continue with the cancellation"
              />
            </CancelSubscriptionFlow.ShowFlow>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
      </CancelSubscriptionFlow.Steps>
    </CancelSubscriptionFlow>
  );
};

export default MissingFeatures;
