// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import extensionSettings from 'hocs/extension-settings';

import GeneratedToken from 'components/extension/generated-token';
import DynamicLink from 'components/dynamic-link';

import { Authentication } from './authentication';
import style from './salesforce.module.scss';

type Props = {
  extension: Extension,
};

export const SalesforcePage = ({ extension }: Props) => (
  <>
    <h3 className={style.ExtensionConfigurationHeader}>
      <Message
        id="Install application in Salesforce"
        comment="Section header for the extension details page."
      />
    </h3>
    <div className={style.ExtensionConfigurationBody}>
      <Message
        id="This extension requires that you install the application 'Oneflow for Salesforce' in your Salesforce organisation. Follow the link below to install the application from Salesforce AppExchange."
        comment="Help text for the Salesforce extension page."
      />
    </div>
    <div className={style.ExtensionConfigurationBody}>
      <DynamicLink url="https://app.oneflow.com/salesforce/app">
        <Message
          id="Go to AppExchange"
          comment="Link external page from Salesforce extension page."
        />
      </DynamicLink>
    </div>

    <h3 className={style.ExtensionConfigurationHeader}>
      <Message
        id="Authentication"
        comment="Section header for the authentication section in an extension."
      />
    </h3>

    <Authentication extension={extension} />

    <GeneratedToken extension={extension} />
  </>
);

export default extensionSettings(SalesforcePage);
