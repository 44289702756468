// @flow

import React from 'react';
import { Message, localize } from '@oneflowab/pomes';

import Button from 'components/button';
import Cross from 'components/icons/cross';
import { BetaLabel } from 'components/beta-label';

import style from './ai-assist.module.scss';

type Props = {
  hasSearched: any,
  generatingResponse: boolean,
  handleClose: any,
};

const AIAssistHeader = ({
  handleClose,
  hasSearched,
  generatingResponse,
}: Props) => {
  const renderCloseButton = () => (
    <div className={style.CloseButtonContainer}>
      <Button customClass={style.CloseButton} icon={Cross} onClick={handleClose} />
    </div>
  );

  if (hasSearched > 0 || generatingResponse) {
    return (
      <div className={style.EmptyHeader}>
        <div />
        <div />
        {renderCloseButton()}
      </div>
    );
  }

  return (
    <>
      <div className={style.HeaderVideo}>
        <video autoPlay loop muted playsInline>
          <source
            src="https://static.oneflow.com/misc/ai_assist/gradient-loop.mp4"
            type="video/mp4"
          />
        </video>
        <div className={style.HeaderContainer}>
          <div />
          <div className={style.HeaderTextContainer}>
            <span className={style.HeaderTagContainer}>
              <h1 className={style.Header}>
                <Message id="AI Assist" comment="Used as the subtitle in Oneflow AI Assist modal" />
              </h1>
              <div className={style.BetaLabelWrapper}>
                <BetaLabel />
              </div>
            </span>
            <div className={style.Text}>
              <Message
                id="Magically generate content on any topic simply by asking your very own AI assistant to do it for you."
                comment="Used as the subtitle in AI Assist modal"
              />
            </div>
          </div>
          {renderCloseButton()}
        </div>
      </div>
    </>
  );
};

export default localize<Props>(AIAssistHeader);
