// @flow

import { Transforms } from 'slate';

// eslint-disable-next-line import/prefer-default-export
export const insertImage = (editor: any, src: string, selection: any) => {
  const text = { text: '' };
  const image = { type: 'image', src, children: [text] };
  Transforms.insertNodes(editor, image, { at: selection, select: true });
  setTimeout(() => {
    Transforms.move(editor);
  }, 0);
};
