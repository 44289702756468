import type { ElementType } from 'react';

import Attachment from 'components/icons/attachment';
import CalendarBox from 'components/icons/calendar-box';
import PdfDocument from 'components/icons/pdf-document';
import ShoppingCart from 'components/icons/shopping-cart';
import Sigma from 'components/icons/sigma';
import TextFormat from 'components/icons/text-format';
import TypeCursor from 'components/icons/type-cursor';
import Video from 'components/icons/video';

import {
  BOX_ATTACHMENTS,
  BOX_DURATION,
  BOX_FORM,
  BOX_PDF,
  BOX_PRODUCT_SUMMATION,
  BOX_PRODUCT_TABLE,
  BOX_TEXT_AND_IMAGE,
  BOX_VIDEO,
} from 'agreement/constants';

export type BoxIconsType = {
  [key in ContractView.BoxType]: ElementType;
};

const boxIcons: BoxIconsType = {
  [BOX_DURATION]: CalendarBox,
  [BOX_PDF]: PdfDocument,
  [BOX_ATTACHMENTS]: Attachment,
  [BOX_TEXT_AND_IMAGE]: TextFormat,
  [BOX_FORM]: TypeCursor,
  [BOX_PRODUCT_TABLE]: ShoppingCart,
  [BOX_PRODUCT_SUMMATION]: Sigma,
  [BOX_VIDEO]: Video,
};

export default boxIcons;
