// media query is based on: legacy/src/app/modules/agreements/agreement/state/controller.js
import useMatchMedia from 'hooks/use-match-media';

function useDocumentViewLayoutInfo(mediaQueryString = '(min-width: 1000px)') {
  const matches = useMatchMedia(mediaQueryString);

  return {
    columnLayout: matches ? 'double' : 'single',
    columnsCount: matches ? 2 : 1,
  };
}

export default useDocumentViewLayoutInfo;
