import clsx from 'clsx';

import style from './dot.module.scss';

type Props = {
  color: 'green' | 'orange' | 'red'
}

const Dot = ({ color }: Props) => {
  const dotClass = clsx(style.Dot, {
    [style.Green]: color === 'green',
    [style.Orange]: color === 'orange',
    [style.Red]: color === 'red',
  });

  return (
    <div className={dotClass} />
  );
};

export default Dot;
