// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import { get } from 'lodash';

import Badge from 'components/badge';
import { RemoveConfirm } from '../remove-confirm';

export type Props = {
  role: Role,
  removeState: RemoveState,
  resetRemoveState: Function,
  removeRole: () => void,
  children: Function,
};

export class RemoveRole extends React.PureComponent<Props> {
  handleConfirm = () => {
    const { removeRole } = this.props;
    removeRole();
  };

  getCustomErrorMessage() {
    const { removeState } = this.props;
    const apiErrorCode = get(removeState, 'error.body.api_error_code');

    if (apiErrorCode !== 102) {
      return '';
    }

    return {
      bodyText: (
        <Message
          id="This role can't be deleted. To continue, remove assigned users or groups."
          comment="Error text when not allowing deletion of a role."
        />
      ),
    };
  }

  renderBody() {
    const { role } = this.props;

    return (
      <Message
        id="You are about to delete {name}. This action can't be undone."
        values={{
          name: <Badge label={role.name} kind="name" />,
        }}
        comment="Modal text when removing a role from the role list."
      />
    );
  }

  render() {
    const { removeState, children, resetRemoveState } = this.props;

    return (
      <RemoveConfirm
        onConfirm={this.handleConfirm}
        confirmState={removeState}
        resetConfirmState={resetRemoveState}
        confirmMessage={this.renderBody()}
        modalKey="delete role modal"
        customErrorMessage={this.getCustomErrorMessage()}
      >
        {children}
      </RemoveConfirm>
    );
  }
}
