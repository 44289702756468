import * as React from 'react';

const useIsElementRenderedInside = (rootRef, childSelector) => {
  const [isElementRenderedInside, setIsElementRenderedInside] = React.useState(false);
  React.useEffect(() => {
    const { current } = rootRef;

    if (!current) {
      return undefined;
    }

    const observer = new MutationObserver(() => {
      setIsElementRenderedInside(Boolean(current.querySelector(childSelector)));
    });

    observer.observe(current, {
      attributes: false,
      childList: true,
      subtree: false,
    });

    return () => {
      observer.disconnect();
    };
  }, [rootRef, childSelector]);

  return isElementRenderedInside;
};

export default useIsElementRenderedInside;
