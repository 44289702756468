import { Message } from '@oneflowab/pomes';
import style from './over-non-pdf-sections-tooltip.module.scss';

const OverNonPdfSectionsTooltip = () => (
  <div className={style.IsOverNonPdfSectionsTooltip}>
    <Message
      id="Placement only allowed on PDF sections."
      comment="The message shown when a datafield id dragged over non pdf sections"
    />
  </div>
);

export { OverNonPdfSectionsTooltip };
