// @flow

function getCSSPropertyValue(variableName: string) {
  if (!variableName.startsWith('--')) {
    // eslint-disable-next-line no-console
    console.warn('Did you forget the prefix --?');
    return '';
  }

  return window.getComputedStyle(document.documentElement).getPropertyValue(variableName);
}

export default getCSSPropertyValue;
