import { useEffect, useState } from 'react';
import * as Switch from '@radix-ui/react-switch';
import clsx from 'clsx';
import { isBoolean } from 'lodash';

import ToggleCheckIcon from 'components/icons/toggle-check';

import style from './radix-toggle.module.scss';

type Input = {
  checked?: boolean,
  disabled?: boolean,
  name?: string,
  onBlur?: () => void,
  onChange?: () => void,
  onFocus?: () => void,
  value?: string,
}

type OldToggleProps = {
  containerClassName?: string,
  input?: Input,
  name?: string,
  onChange?: (args: unknown) => void,
  placeholder?: string,
}

type ToggleProps = {
  checked?: boolean,
  className?: string,
  disabled?: boolean,
  id?: string,
  isControlled?: boolean,
  label?: string | JSX.Element,
  onClick?: () => void,
  value?: string,
} & OldToggleProps;

const ToggleSwitch = Switch.Root;
const Indicator = Switch.Thumb;
const IndicatorIcon = ToggleCheckIcon;

export const RadixToggle = (props: ToggleProps) => {
  const {
    checked,
    className,
    disabled,
    id,
    isControlled,
    label,
    onClick,
    // Old Toggle-related
    containerClassName,
    input,
    name,
    onChange,
    placeholder,
    value,
  } = props;
  const valueProp = value || input?.value;
  const checkedProp = checked || input?.checked;

  const valuePropAsBoolean: boolean = isBoolean(valueProp)
    ? valueProp
    : valueProp === 'on';

  const [toggleState, setToggleState] = useState(checkedProp || valuePropAsBoolean);
  const [hasError, setHasError] = useState(false);

  // Gross hack in order to be able to control the toggle from the outside
  useEffect(() => {
    if (isControlled && checked !== undefined) {
      setToggleState(checked);
    }
  }, [checked, isControlled]);

  const sanitizedOnChange = onChange || input?.onChange;

  const inputName = name || (
    (input && typeof input === 'object' && 'name' in input)
      ? input.name
      : undefined
  );

  const ariaLabel = typeof label === 'string' ? label : 'No label tag';

  const idProp = inputName || id;
  const nameProp = inputName || name;
  const valueState = valuePropAsBoolean ? 'on' : 'off';

  // Handle onChange events: async or not
  const handleCheckedChange = (newCheckedState: boolean) => {
    // Optimistically update the toggle's state
    setToggleState(newCheckedState);

    // Call the onChange handler and store the result
    const result = sanitizedOnChange?.(newCheckedState);

    // If onChange is an async function or the result returns a promise
    const onChangeIsResultingInPromise = result && typeof result.then === 'function';
    if (onChangeIsResultingInPromise) {
      Promise.resolve(result)
        .catch(() => {
          // If the async operation fails, revert the toggle's state and show an error message
          // TOAST_TODO: Show an error message toast({ description: 'Something went wrong' }})
          setToggleState(!newCheckedState);
          setHasError(true);

          setTimeout(() => setHasError(false), 1000);
        });
    }
  };

  return (
    <div className={clsx(containerClassName)}>
      {label && <label htmlFor={idProp}>{label}</label>}
      <ToggleSwitch
        id={idProp}
        aria-label={ariaLabel}
        name={nameProp}
        onClick={onClick}
        disabled={disabled}
        checked={toggleState}
        className={clsx(
          style.ToggleSwitch,
          { [style.HasFailed]: hasError },
          className,
        )}
        placeholder={placeholder}
        onCheckedChange={handleCheckedChange}
        value={valueState}
      >
        <Indicator className={style.Indicator}>
          <IndicatorIcon height="10px" width="10px" />
        </Indicator>
      </ToggleSwitch>
    </div>
  );
};
