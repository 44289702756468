// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { checkAcl } from 'components/acl';

import accountSeatsReducer from 'reducers/entities/account-seats';
import billingsReducer from 'reducers/entities/billings';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import { getDateFormat } from 'date';

import BillingComponent, { type Props, type RequestData } from './billing';

type StateProps = {|
  accountSeats: AccountSeats,
  billing: Billing,
  currentLanguage: string,
  updateState: UpdateState,
  accountId: number,
  account: Account,
  canEdit: boolean,
  dateFormat: string,
  |};

type DispatchProps = {|
  resetUpdateState: (accountId: number) => void,
  onSubmit: (number, RequestData) => void,
  fetchBilling: ({ accountId: number }) => void
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);
  const dateFormat = getDateFormat(account.brandingDateFormat);

  return {
    accountSeats: accountSeatsReducer.getAccountSeatSelector(state, { id: account.id }),
    billing: billingsReducer.getBillingSelector(state, { id: account.id }),
    currentLanguage: getCurrentLanguageSelector(state),
    updateState: billingsReducer.getUpsertBillingSelector(state, { id: account.id }),
    accountId: account.id,
    canEdit: checkAcl(account.acl, 'account:billing:update'),
    account,
    dateFormat,
  };
};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  resetUpdateState: (accountId) => {
    dispatch(billingsReducer.upsertBillingReset({ id: accountId }));
  },
  onSubmit: (
    id,
    {
      address,
      city,
      country,
      invoiceEmail,
      name,
      invoiceReference,
      vatNumber,
      zipCode,
    },
  ) => {
    dispatch(billingsReducer.upsertBilling({
      id,
      data: {
        address,
        city,
        country,
        invoiceEmail,
        name,
        invoiceReference,
        vatNumber,
        zipCode,
      },
      pipe: {
        action: () => billingsReducer.fetchBilling({ id }),
        wait: billingsReducer.FETCH_BILLING_SUCCESS,
      },
    }));
  },
  fetchBilling: ({ accountId }) => {
    dispatch(billingsReducer.fetchBilling({
      id: accountId,
    }));
  },
});

export default connect<Props, any, StateProps, $Exact<DispatchProps>, State, Dispatch<*>>(
  mapStateToProps, mapDispatchToProps,
)(BillingComponent);
