import clsx from 'clsx';
import {
  useCallback, useEffect, useRef, useState, forwardRef,
} from 'react';
import { isEmpty } from 'lodash';

import { getAgreementDateFormat, formatDateString } from 'date';
import {
  isAnySignedState,
  isPending,
  isImport,
  isMarkedAsSigned,
  isOverdue,
  isSignedAndAwaiting,
  isAnyDeclinedState,
  isMarkedAsDeclined,
  isCanceled,
  isEnded,
  isSignedAndEndedAndTerminated,
  isSignLaterEnabled,
} from 'agreement';
import { isPartiallySigned, getAgreementMyParticipant } from 'agreement/selectors';
import { setAgreementSidebarActiveTabName } from 'reducers/app';
import { setBannerVisibility } from 'reducers/current-contract';
import { SETTINGS_TAB } from 'agreement/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getPositionFromSessionSelector } from 'reducers/session';
import { isUserLimited } from 'user';
import getCSSPropertyValue from 'utils/get-css-property-value';

import { checkAcl } from 'components/acl';
import { useExpandableSidebarProps } from 'components/document-layout-container/expanded-document-layout/context';
import { useCollapsedDocumentLayout } from 'components/document-layout-container/collapsed-document-layout/context';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';

import style from './document-state-banner.module.scss';
import {
  Declined,
  MarkedAsDeclined,
  MarkedAsSigned,
  MarkedAsSignedAndCanceled,
  Overdue,
  OverdueOwner,
  PartialSigned,
  Pending,
  Signed,
  SignedAndAwaiting,
  SignedAndCanceled,
  SignedAndEnded,
  SignedAndTerminated,
  SignLater,
} from './states';

type Props = {
  agreement: Oneflow.Document,
  setIsBannerVisible?: (value: boolean) => void,
}

type DocumentTextParams = {
  agreement: Oneflow.Document,
  overdueOnClick?: () => void,
  limitedAccess?: boolean,
}

export const getDocumentStateBannerText = ({
  agreement, overdueOnClick, limitedAccess = true,
}: DocumentTextParams) => {
  const dateFormat = getAgreementDateFormat(agreement.config?.dateFormat);

  const dateString = isAnySignedState(agreement) || isAnyDeclinedState(agreement)
    ? formatDateString(agreement.stateTime, dateFormat)
    : formatDateString(agreement.expireDate, dateFormat);

  if (isCanceled(agreement)) {
    const validUntil = formatDateString(agreement?.periodEndTime?.toString(), dateFormat);

    if (isMarkedAsSigned(agreement)) {
      return (
        <MarkedAsSignedAndCanceled
          markedAsSignDate={dateString}
          validUntil={validUntil}
        />
      );
    }

    return (
      <SignedAndCanceled
        signedDate={dateString}
        validUntil={validUntil}
      />
    );
  }

  if (isAnySignedState(agreement)) {
    if (isSignedAndAwaiting(agreement)) {
      const startTime = formatDateString(agreement?.startTime?.toString(), dateFormat);

      return (
        <SignedAndAwaiting signedDate={dateString} startTime={startTime} />
      );
    }

    if (isSignedAndEndedAndTerminated(agreement)) {
      return (
        <SignedAndTerminated
          signedDate={dateString}
          terminatedDate={formatDateString(agreement?.terminateTime?.toString(), dateFormat)}
        />
      );
    }

    if (isEnded(agreement)) {
      return (
        <SignedAndEnded
          signedDate={dateString}
          endDate={formatDateString(agreement?.periodEndTime?.toString(), dateFormat)}
        />
      );
    }

    if (isMarkedAsSigned(agreement)) {
      return (
        <MarkedAsSigned signedDate={dateString} />
      );
    }

    return (
      <Signed signedDate={dateString} />
    );
  }

  if (isAnyDeclinedState(agreement)) {
    if (isMarkedAsDeclined(agreement)) {
      return (
        <MarkedAsDeclined declinedDate={dateString} />
      );
    }

    return (
      <Declined declinedDate={dateString} />
    );
  }

  if (isPending(agreement) && !isImport(agreement)) {
    if (!agreement.expireDate) {
      return null;
    }

    if (isSignLaterEnabled(agreement)) {
      return (
        <SignLater expiryDate={dateString} />
      );
    }

    if (isPartiallySigned(agreement)) {
      return (
        <PartialSigned date={dateString} />
      );
    }

    return (
      <Pending date={dateString} />
    );
  }

  if (isOverdue(agreement) && (!limitedAccess) && getAgreementMyParticipant(agreement)) {
    if (checkAcl(agreement.acl, 'agreement:update:expire_date')) {
      return (
        <OverdueOwner onClick={overdueOnClick} aTagClassName={style.OverdueLink} />
      );
    }

    return (
      <Overdue />
    );
  }

  return null;
};

export const DocumentStateIndicator = ({ agreement }: Partial<Props>) => {
  if (!agreement) return null;

  const isSigned = (
    isAnySignedState(agreement) && !isCanceled(agreement)
    && !isEnded(agreement) && !isSignedAndEndedAndTerminated(agreement)
  );

  const stateIndicatorClasses = clsx(style.StateIndicator, {
    [style.Signed]: isSigned,
    [style.Pending]: isPending(agreement) && !isImport(agreement),
    [style.Overdue]: isOverdue(agreement),
    [style.Declined]: isAnyDeclinedState(agreement),
    [style.Canceled]: isCanceled(agreement),
    [style.Ended]: isEnded(agreement),
    [style.Terminated]: isSignedAndEndedAndTerminated(agreement),
  });

  return <div data-testid="state-indicator" className={stateIndicatorClasses} />;
};

const DocumentStateBanner = forwardRef<HTMLElement | null, Props>((props, ref) => {
  const { agreement, setIsBannerVisible } = props;
  const dispatch = useDispatch();
  const expandedLayout = useExpandableSidebarProps();
  const collapsedLayout = useCollapsedDocumentLayout();
  const position = useSelector(getPositionFromSessionSelector);
  const { updateAccordionStates } = useDocumentLayout();
  const [showBanner, setShowBanner] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [bannerHeight, setBannerHeight] = useState(0);

  const prevScrollRef = useRef<number>(0);

  const overdueOnClick = () => {
    updateAccordionStates({ name: 'signingAndSecurity', state: true });

    if (expandedLayout !== null) {
      dispatch(setAgreementSidebarActiveTabName(SETTINGS_TAB));
      expandedLayout.setExpandedSidebar(true);
    }

    if (collapsedLayout !== null) {
      collapsedLayout.setActiveTab(SETTINGS_TAB);
    }

    setTimeout(() => {
      const signingPeriodHeader = document.getElementById('signing-period')
        ?.childNodes.item(0);

      signingPeriodHeader?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' });
    }, 300);
  };

  const limitedAccess = isUserLimited(position);

  const documentText = getDocumentStateBannerText({
    agreement,
    overdueOnClick,
    limitedAccess,
  });

  const handleScroll = useCallback((event: Event) => {
    const scrollY = (event.target as HTMLElement).scrollTop;
    const prevScrollY = prevScrollRef.current;

    const maxScroll = (event.target as HTMLElement).scrollHeight
      - (event.target as HTMLElement).offsetHeight;

    const scrollingDown = scrollY > prevScrollY;
    const scrollingUp = scrollY < prevScrollY;
    // minus one because if you scroll to the bottom it would
    // sometimes get stuck at a 0.5 value and glitch
    const hasNotMaxScrolled = scrollY < (maxScroll - 1);
    const hasNotScrolled = scrollY === 0;
    const topThreshold = collapsedLayout ? 10 : 40;
    const hasBarelyScrolled = scrollY < topThreshold;

    const shouldShowBanner = (hasNotScrolled || hasBarelyScrolled)
      || (scrollingUp && hasNotMaxScrolled) || maxScroll < 100;
    const shouldHideBanner = scrollingDown && hasNotMaxScrolled && maxScroll > 100;

    // Animations is not set in the style file because it should not play on mount
    // Therefore we need to set it here
    const animationName = containerRef.current?.style.animationName === style.slideDown;
    if (scrollingUp && containerRef.current && shouldShowBanner && !animationName) {
      containerRef.current.style.animationName = style.slideDown;
    } else if (scrollingDown && containerRef.current && shouldHideBanner && animationName) {
      containerRef.current.style.animationName = '';
    }

    if (shouldShowBanner && !showBanner) {
      // Prevents banner jumping on top of the page
      setShowBanner(true);
      setIsBannerVisible?.(true);
    } else if (shouldHideBanner && showBanner) {
      setShowBanner(false);
      setIsBannerVisible?.(false);
    }

    prevScrollRef.current = scrollY;
  }, [setIsBannerVisible, collapsedLayout, showBanner]);

  useEffect(() => {
    const refCopy = ref;

    ref?.current?.addEventListener('scroll', handleScroll);

    return () => {
      refCopy?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, ref]);

  useEffect(() => {
    if (isEmpty(documentText)) {
      dispatch(setBannerVisibility(false));
    } else {
      dispatch(setBannerVisibility(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (collapsedLayout) {
    // Checking banner height and if it's higher than 40px
    // 40px is the default height, sometimes it can be higher for example expired banner
    if (containerRef.current) {
      const isContainerLargerThanDefaultHeight = containerRef.current.clientHeight > 40;
      const isBannerHighestPossibleValue = containerRef.current.clientHeight > bannerHeight;

      if (isContainerLargerThanDefaultHeight && isBannerHighestPossibleValue) {
        setBannerHeight(containerRef.current.clientHeight);
      }
    }

    if (bannerHeight > 40) {
      const bannerHeightValue = getCSSPropertyValue('--document-state-banner-height');

      if (bannerHeightValue === '40px') {
        // + 1px to cover for border
        (document.querySelector(':root') as HTMLElement)?.style.setProperty('--document-state-banner-height', `${bannerHeight + 1}px`);
      }
    }
  }

  // Resetting banner height to default value
  useEffect(() => () => {
    (document.querySelector(':root') as HTMLElement)?.style.setProperty('--document-state-banner-height', '40px');
  }, []);

  if (isEmpty(documentText)) return null;

  return (
    <div
      ref={containerRef}
      className={clsx(style.DocumentStateContainer, { [style.HideBanner]: !showBanner })}
      data-testid="document-state-banner"
    >
      <DocumentStateIndicator agreement={agreement} />
      {documentText}
    </div>
  );
});

DocumentStateBanner.displayName = 'DocumentStateBanner';

export default DocumentStateBanner;
