// @flow

import * as React from 'react';
import clsx from 'clsx';

import { MenuItem } from 'components/menu-item';

import style from './font-size-selector.module.scss';

type Props = {
  fontSize: string,
  isActive: boolean,
  changeFontSize: Function,
};

const FontSizeMenuItem = ({
  fontSize,
  isActive,
  changeFontSize,
}: Props) => {
  const menuItemClassNames = clsx(style.FontSizeMenuItem, {
    [style.ActiveFontSize]: isActive,
  });

  return (
    <MenuItem
      label={String(Number.parseInt(fontSize, 10))}
      onClick={(e) => {
        e.preventDefault();

        changeFontSize(fontSize);
      }}
      className={menuItemClassNames}
    />
  );
};

export default FontSizeMenuItem;
