import { uniqueId } from 'lodash';
import clsx from 'clsx';
import type { ReactNode, InputHTMLAttributes } from 'react';

import MinusIcon from 'components/icons/minus';
import CheckIcon from 'components/icons/check';
import Tooltip from 'components/tooltip';
import TooltipInfo from 'components/tooltip-info';

import style from './checkbox.module.scss';

type CheckboxInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  checked: boolean,
};

type Props = {
  customCheckboxClass?: string,
  customClass?: string,
  customLabelStyle?: string,
  disabled?: boolean,
  height?: string,
  input: CheckboxInputProps,
  label?: ReactNode,
  tooltipMessage?: ReactNode,
  tooltipOnLabel?: boolean,
  tooltipType?: string,
  required?: boolean,
  invalid?: boolean,
  showMinusIcon?: boolean,
};

const Checkbox = ({
  customCheckboxClass,
  customClass,
  customLabelStyle,
  disabled,
  input,
  label,
  tooltipMessage,
  tooltipOnLabel,
  tooltipType,
  required,
  invalid,
  showMinusIcon,
}: Props) => {
  const id = uniqueId();

  let icon: ReactNode = '';
  if (input.checked) {
    if (showMinusIcon) {
      icon = <MinusIcon className={style.Icon} height="12px" width="12px" />;
    } else {
      icon = <CheckIcon className={style.Icon} height="10px" width="10px" />;
    }
  }

  const labelClasses = clsx(
    style.LabelText,
    customClass,
    customLabelStyle,
  );

  const text = label ? <span className={labelClasses}>{label}</span> : '';

  const containerClasses = clsx(
    style.Label,
    {
      [style.DisabledCursor]: disabled,
    },
  );

  const checkboxClasses = clsx(
    style.Checkbox,
    customCheckboxClass,
    {
      [style.Invalid]: invalid,
      [style.Checked]: input.checked,
      [style.Disabled]: disabled,
    },
  );

  const getTooltip = () => {
    if (tooltipOnLabel) {
      return (
        <div className={style.TextContainer}>
          <Tooltip
            messageClassName={style.TooltipText}
            message={tooltipMessage}
            side="top"
            theme={tooltipType}
          >
            {text}
          </Tooltip>
        </div>
      );
    }

    return (
      <div className={style.TextContainer}>
        {text}
        <TooltipInfo
          message={tooltipMessage}
          side="top"
          theme={tooltipType}
          zIndex="10002"
          disabled={disabled}
        />
      </div>
    );
  };

  const getText = () => {
    if (tooltipMessage) {
      return getTooltip();
    }

    return text;
  };

  return (
    <div className={style.CheckboxContainer}>
      <input
        className={style.HiddenCheckbox}
        type="checkbox"
        id={`checkbox-${id}`}
        disabled={disabled}
        required={required}
        {...input}
      />
      <label className={containerClasses} htmlFor={`checkbox-${id}`}>
        <div
          className={checkboxClasses}
          aria-required={required}
          aria-disabled={disabled}
          aria-checked={input.checked}
          role="checkbox"
        >
          {icon}
        </div>
        {getText()}
      </label>
    </div>
  );
};

export default Checkbox;
