/* eslint-disable react/display-name */
import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import { Link } from 'react-router-dom';

import { checkAcl } from 'components/acl';
import ActionsMenu from 'components/actions-menu';
// eslint-disable-next-line import/named
import { Table } from 'components/table';
import EditUserAccountAccess from 'components/modals/edit-user-account-access';
import RemoveUserAccountAccess from 'components/modals/remove-user-account-access';
import { DeleteMenuItem } from 'components/menu-items/delete';
import { ChangeRoleMenuItem } from 'components/menu-items/change-role';

import styles from './user-account-access.module.scss';
import { renderRoleName, renderPermissionCount } from './helpers';
import { Query } from './group-access-table';

type Props = {
  account: Oneflow.Account,
  query: Query,
  children: ReactNode,
  items: Oneflow.AccountUserRoleBinding[],
  message: MessageTranslator,
  position: Oneflow.Position,
  hasGrantSystemRolePermission: boolean,
  hasGrantCustomRolePermission: boolean,
}

const DirectAccessTable = ({
  account,
  query,
  children,
  items,
  message,
  position,
  hasGrantSystemRolePermission,
  hasGrantCustomRolePermission,
}: Props) => {
  const hasRemoveSystemRolePermission = useMemo(() => checkAcl(account.acl, 'account:position:system_role_binding:remove'), [account.acl]);
  const hasRemoveCustomRolePermission = useMemo(() => checkAcl(account.acl, 'account:position:custom_role_binding:remove'), [account.acl]);
  const getEditMenuItem = () => (onClick: () => ReactNode) => {
    const hasEditSystemRolePermission = hasRemoveSystemRolePermission
      && hasGrantSystemRolePermission;
    const hasEditCustomRolePermission = hasRemoveCustomRolePermission
      && hasGrantCustomRolePermission;

    if (!hasGrantCustomRolePermission) {
      return null;
    }

    return (
      <ChangeRoleMenuItem
        onClick={onClick}
        disabled={!hasEditSystemRolePermission && !hasEditCustomRolePermission}
      />
    );
  };

  const getRemoveMenuItem = () => (onClick: () => ReactNode) => (
    <DeleteMenuItem
      onClick={onClick}
      disabled={!hasRemoveSystemRolePermission && !hasRemoveCustomRolePermission}
    />
  );

  const getActionsForUserAccess = (item: Oneflow.AccountUserRoleBinding) => (
    <ActionsMenu
      actions={[
        <EditUserAccountAccess
          position={position}
          binding={item}
          renderedFromAccountAccessPage={false}
          key={`edit_${item.role.id}`}
        >
          {getEditMenuItem()}
        </EditUserAccountAccess>,
        <RemoveUserAccountAccess
          position={position}
          binding={item}
          key={`remove_${item.role.id}`}
        >
          {getRemoveMenuItem()}
        </RemoveUserAccountAccess>,
      ]}
      focusOnCloseDisabled
    />
  );

  const getDirectAccessTableConfig = () => {
    const actions: JSX.Element[] = [];
    const columns = [
      {
        name: 'account role',
        label: message({
          id: 'Account role',
          comment: 'Column header for the account access list in the user pages.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountUserRoleBinding) => (
          <Link to={`/admin/roles/${item.role.id}`} className={styles.Name}>
            {renderRoleName(item.role)}
          </Link>
        ),
      },
      {
        name: 'permissions',
        label: message({
          id: 'Permissions',
          comment: 'Column header for the account access list in the user pages.',
        }),
        type: 'cell',
        value: (item: Oneflow.AccountUserRoleBinding) => renderPermissionCount(item.role),
      },
      {
        name: 'actions',
        label: message({ id: 'Actions', comment: 'Column label in users page' }),
        type: 'actions',
        value: (item: Oneflow.AccountUserRoleBinding) => getActionsForUserAccess(item),
      },
    ];

    return {
      items,
      itemKey: 'id',
      actions,
      columns,
    };
  };
  return (
    <>
      <div className={styles.ContentHeader}>
        <h2 className={styles.Header}>
          <Message id="Direct access" comment="Title, above the table." />
        </h2>
        <p>
          <Message
            id="Account role assigned directly to the user."
            comment="Help text for the account access list in the user pages."
          />
        </p>
      </div>
      <Table
        config={getDirectAccessTableConfig()}
        query={query}
      />
      {children}
    </>
  );
};

export default DirectAccessTable;
