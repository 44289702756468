// @flow

import { connect } from 'react-redux';

import {
  getExpireFromSessionSelector,
  getPositionFromSessionSelector,
  refresh,
  logout,
  getRefreshStateSelector,
  setRefreshSuccess,
} from 'reducers/session';

import { SessionExpirationWarning } from './session-expiration-warning';

export const mapStateToProps = (state: State) => ({
  positionId: getPositionFromSessionSelector(state).id,
  expire: getExpireFromSessionSelector(state),
  successState: getRefreshStateSelector(state),
});

export const mapDispatchToProps = (dispatch: any) => ({
  refresh: () => dispatch(refresh()),
  logout: () => dispatch(logout()),
  setRefreshSuccess: () => dispatch(setRefreshSuccess(false)),
});

const connectedSessionExpirationWarning = connect(
  mapStateToProps, mapDispatchToProps,
)(SessionExpirationWarning);

export { connectedSessionExpirationWarning as SessionExpirationWarning };
