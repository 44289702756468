import type { Box } from 'data-validators/entity-schemas/document-box';

import { checkAcl } from 'components/acl';

import { isNewBox } from './selectors';

export const isBoxDataReorderAllowed = (
  box: Box,
) => {
  if (isNewBox(box)) {
    return true;
  }

  return checkAcl(box.acl, 'agreementbox:data:reorder');
};
