// @flow

import {
  post,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

export const oauthRefresh = () => (
  post({
    url: '/ext/salesforce/oauth_refresh',
  })
    .then(extractResponseBodyAsJSON)
);

export const revokeConnection = () => (
  post({
    url: '/ext/salesforce/oauth_revoke',
  })
    .then(extractResponseBodyAsJSON)
);

export default {
  oauthRefresh,
  revokeConnection,
};
