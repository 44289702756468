// @flow

import React, { useCallback } from 'react';
import { Editor, Transforms } from 'slate';
import {
  Editable,
  ReactEditor,
  useFocused,
  useSlate,
} from 'slate-react';
import { isEqual, isEmpty } from 'lodash';
import clsx from 'clsx';

import { isSmallScreenWidth } from 'ui/config';
import { AnnotationPropsProvider } from 'contexts/annotation-props';
import { useRichTextProps } from 'contexts/rich-text';

import {
  handleRedo,
  handleUndo,
  hasMarginLeft,
} from 'components/rich-text-editor/utils';
import AddLinkPopover from 'components/rich-text-editor-toolbars/toolbar-buttons/link-button/add-link-popover';
import useIsEditorSelectionMemoized from 'components/rich-text-editor/hooks/use-is-editor-selection-memoized';
import { AnnotationInteractionContainer } from 'components/rich-text-editor-toolbars/toolbar-buttons/post-annotation/annotation-interaction-container';

import { SelectedRectanglePopoverPropsProvider } from 'components/rich-text-editor-toolbars/inline-toolbar/use-selected-rectangle-popover-props';

import { MobileAnnotationInteractionContainer } from 'components/rich-text-editor-toolbars/toolbar-buttons/post-annotation/mobile-annotation-interaction-container';
import useOnEditableFocus from './use-on-editable-focus';
import style from './contract-text-editable.module.scss';

const isPlaceholderHidden = (editor) => {
  const marksAreEmpty = isEmpty(editor.marks);
  const [node] = editor.children;
  const isParagraph = !node.type || node.type === 'paragraph';
  return !isParagraph
    || !marksAreEmpty
    || node.textAlign === 'right'
    || node.textAlign === 'center'
    || hasMarginLeft(node)
    || !isEqual(node.children, [{ text: '' }]);
};

type Props = {
  renderElement: RenderSlateElementProps => React.Node,
  renderLeaf: RenderSlateLeafProps => React.Node,
  onKeyDown?: Function,
  autoFocus?: boolean,
  contractId: number,
  readOnly: boolean,
};

const ContractTextEditable = ({
  renderElement,
  renderLeaf,
  onKeyDown,
  autoFocus,
  contractId,
  readOnly,
}: Props) => {
  const editor = useSlate();
  const focused = useFocused();
  const isEditorSelectionMemoized = useIsEditorSelectionMemoized();
  const isMobile = isSmallScreenWidth();
  const onFocus = useOnEditableFocus(editor);
  const { onMouseDown, editorRef } = useRichTextProps();

  React.useEffect(() => {
    if (!autoFocus) {
      return;
    }

    const [, path] = Editor.last(editor, []);
    Transforms.select(editor, Editor.end(editor, path));
    ReactEditor.focus(editor);
  }, [autoFocus, editor]);

  const renderPlaceholder = React.useCallback(({ children, attributes }) => {
    if (isPlaceholderHidden(editor)) {
      return <div {...attributes} />;
    }

    return <div {...attributes}>{children}</div>;
  }, [editor]);

  // Be careful about the order of the classes in the className when removing the legacy.
  const className = clsx(style.Editable,
    style.DocumentViewEditable,
    'rich-text-region', {
      open: focused || isEditorSelectionMemoized,
      [style.EditableBorder]: !readOnly,
    });
  const editableClassName = clsx({
    notranslate: !readOnly,
    [style.EditableContent]: !readOnly,
    [style.EditableReadOnlyContent]: readOnly,
  });

  const renderAnnotationInteractionContainer = useCallback(() => {
    if (isMobile) {
      return <MobileAnnotationInteractionContainer contractId={contractId} readOnly={readOnly} />;
    }

    return (
      <SelectedRectanglePopoverPropsProvider>
        <AnnotationInteractionContainer contractId={contractId} readOnly={readOnly} />
      </SelectedRectanglePopoverPropsProvider>
    );
  }, [contractId, isMobile, readOnly]);

  return (
    <div className={className} ref={editorRef}>
      <AnnotationPropsProvider>
        {renderAnnotationInteractionContainer()}
        <AddLinkPopover />
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={!readOnly && 'Enter text or images...'}
          renderPlaceholder={renderPlaceholder}
          onKeyDown={onKeyDown}
          onMouseDown={onMouseDown}
          readOnly={readOnly}
          className={editableClassName}
          spellCheck={false}
          onDOMBeforeInput={(event) => {
            handleRedo(editor, event);
            handleUndo(editor, event);
          }}
          onFocus={onFocus}
        />
      </AnnotationPropsProvider>
    </div>
  );
};

ContractTextEditable.defaultProps = {
  onKeyDown: undefined,
  autoFocus: undefined,
};

export default ContractTextEditable;
