/* eslint-disable react/no-array-index-key */
// @flow
import * as React from 'react';

import { RichTextProvider } from 'contexts/rich-text';
import ElementViewer from './element-viewer';

type Props = {
  nodes: Array<SlateDescendant>,
};

const RichTextViewer = (props: Props) => {
  const { nodes } = props;

  return (
    <RichTextProvider isReadOnly>
      <div>
        {nodes.map((element, index) => (
          <ElementViewer key={index} element={element}>
            {element.children}
          </ElementViewer>
        ))}
      </div>
    </RichTextProvider>
  );
};

export default RichTextViewer;
