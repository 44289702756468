import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import SmallAdd from 'components/icons/small-add';

import style from './add-participant.module.scss';

export type Props = {
  type: string,
  onClick: () => void,
  disabled?: boolean,
  hasParticipantList?: boolean,
  addAppcuesClass?: boolean,
};

export const AddParticipantButton = ({
  type,
  onClick,
  disabled,
  hasParticipantList,
  addAppcuesClass,
}: Props) => {
  const addParticipantStyles = clsx({
    [style.ButtonRadiusRounded]: !hasParticipantList,
    [style.ButtonRadiusHalfRounded]: hasParticipantList,
    [style.ButtonFormerColleague]: type === 'formerColleague',
    AppcuesAddColleague: addAppcuesClass,
  });

  const getButtonLabel = () => {
    if (type === 'formerColleague') {
      return (
        <Message
          id="Add former colleague"
          comment="Button to add a former colleague to your contract."
        />
      );
    }

    if (type === 'colleague') {
      return (
        <Message
          id="Add colleague"
          comment="Button to add a colleague to your contract."
        />
      );
    }

    if (type === 'counterparty') {
      return (
        <Message
          id="Add counterparty"
          comment="Button to add a counterparty to your contract."
        />
      );
    }

    return (
      <Message
        id="Add participant"
        comment="Button to add a participant (counterparty) to your contract."
      />
    );
  };

  return (
    <Button
      icon={<SmallAdd height="8px" className={style.AddButtonIcon} />}
      customClass={addParticipantStyles}
      onClick={onClick}
      disabled={disabled}
      kind="add-participant"
    >
      {getButtonLabel()}
    </Button>
  );
};
