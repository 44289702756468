import type { ReactNode } from 'react';
import clsx from 'clsx';

import { uniqueId } from 'lodash';

import BreadCrumbLink from './bread-crumb-link';
import style from './bread-crumb.module.scss';

export type BreadCrumbLinkType = {
  id?: number;
  label: ReactNode;
  showAsLink?: boolean;
  to?: string;
};

type Props = {
  isBreadCrumbInFolderCard: boolean;
  isFolderBreadCrumb?: boolean;
  isSearchedFolderBreadcrumb?: boolean;
  label: ReactNode;
  links?: BreadCrumbLinkType[];
  onClickHandler?: (id?: number) => void;
};

const BreadCrumbComponent = ({
  isBreadCrumbInFolderCard,
  isFolderBreadCrumb,
  isSearchedFolderBreadcrumb,
  label,
  links = [],
  onClickHandler,
}: Props) => {
  const hasDuplicates = (linkTo?: string) => links.filter((item) => item.to === linkTo).length > 1;

  const isLastBreadCrumbLink = (index: number) => index === links.length - 1;

  const breadcrumbClasses = clsx(style.BreadCrumb, {
    [style.FolderBreadCrumbContainer]: isFolderBreadCrumb,
    [style.IsSearchedFolderBreadcrumb]: isSearchedFolderBreadcrumb,
  });

  return (
    <div className={breadcrumbClasses}>
      {links.map((link, index) => {
        const key = hasDuplicates(link.to)
          ? `${link.to}_${uniqueId()}`
          : link.to || uniqueId();

        if (onClickHandler && !isLastBreadCrumbLink(index)) {
          return (
            <BreadCrumbLink
              isFolderBreadCrumb={isFolderBreadCrumb}
              isLastBreadCrumbLink={isLastBreadCrumbLink(index)}
              key={key}
              onClickHandler={() => onClickHandler(link.id)}
              {...link}
            />
          );
        }

        return (
          <BreadCrumbLink
            isBreadCrumbInFolderCard={isBreadCrumbInFolderCard}
            isFolderBreadCrumb={isFolderBreadCrumb}
            isLastBreadCrumbLink={isLastBreadCrumbLink(index)}
            isSearchedFolderBreadcrumb={isSearchedFolderBreadcrumb}
            key={key}
            {...link}
          />
        );
      })}
      <span className={style.Label}>{label}</span>
    </div>
  );
};

export default BreadCrumbComponent;
