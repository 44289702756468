// @flow

import {
  KEY_SCIM,
} from 'extensions';

const SCIM = {
  key: KEY_SCIM,
};

export default SCIM;
