import { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';

import type { Layout } from 'components/document-layout-container/types';
import { COLLAPSED, EXPANDED } from 'components/document-layout-container/helpers';
import { ButtonFC as Button } from 'components/button';

import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import style from './sign.module.scss';

type Props = {
  disabled?: boolean,
  layout?: Layout
  onClick: () => void,
  children: ReactNode,
}

const SignButton = forwardRef<HTMLDivElement, Props>(({
  disabled = false, layout = EXPANDED, onClick, children,
}, ref) => {
  const isInPreviewMode = useIsInPreviewMode();
  return (
    <Button
      id={isInPreviewMode ? 'preview-sign' : undefined}
      kind="document-sign"
      onClick={onClick}
      disabled={disabled}
      customClass={clsx(style.SignButton, {
        [style.Collapsed]: layout === COLLAPSED,
      })}
      ref={ref}
    >
      {children}
    </Button>
  );
});

SignButton.displayName = 'SignButton';

export default SignButton;
