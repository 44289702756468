import { Message } from '@oneflowab/pomes';

const SignLater = ({ expiryDate }: { expiryDate: string }) => (
  <Message
    id="Document will expire on {expiryDate}."
    values={{
      expiryDate,
    }}
    comment="Text for sign later contract. Visible in contract."
  />
);

export default SignLater;
