import { Message } from '@oneflowab/pomes';
import Badge from 'components/badge';

import style from './badge-inactive.module.scss';

export const BadgeInactive = () => (
  <Badge
    kind="type"
    label={(
      <Message
        id="Inactive"
        comment="Inactive badge label"
      />
    )}
    outline
    className={style.ReducedLineHeight}
  />
);
