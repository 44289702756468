// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';

import { getAccountFromSessionSelector } from 'reducers/session';
import agreementsReducer from 'reducers/entities/agreements';

export const ContractPropsContext = React.createContext<any>();

type Props = {|
  children: React.Node,
  contractId: number,
  boxId: number,
  disabledPlugins?: Array<Function>,
  boxType?: string,
|};

export function ContractPropsProvider({
  children,
  contractId,
  boxId,
  disabledPlugins = [],
  boxType,
}: Props) {
  const [isLinkPopoverOpen, setLinkPopoverOpen] = React.useState(false);
  const [isTableToolbarOpen, setTableToolbarOpen] = React.useState(false);
  const [isAiAssistPopoverOpen, setIsAiAssistPopoverOpen] = React.useState(false);
  const agreement = useSelector((state) => (
    agreementsReducer.getAgreementSelector(state, { id: contractId })
  ));
  const account = useSelector(getAccountFromSessionSelector);

  const contextValue = React.useMemo(() => ({
    account,
    agreement,
    boxId,
    contractId,
    isLinkPopoverOpen,
    setLinkPopoverOpen,
    isTableToolbarOpen,
    setTableToolbarOpen,
    disabledPlugins,
    boxType,
    isAiAssistPopoverOpen,
    setIsAiAssistPopoverOpen,
  }), [
    account,
    agreement,
    contractId,
    boxId,
    disabledPlugins,
    boxType,
    isLinkPopoverOpen,
    setLinkPopoverOpen,
    isTableToolbarOpen,
    setTableToolbarOpen,
    isAiAssistPopoverOpen,
    setIsAiAssistPopoverOpen,
  ]);

  return (
    <ContractPropsContext.Provider value={contextValue}>
      {children}
    </ContractPropsContext.Provider>
  );
}

export const useContractProps = () => {
  const contextValue = React.useContext(ContractPropsContext);

  if (!contextValue) {
    throw new Error('useContractProps should be used inside a ContractPropsContext');
  }

  return contextValue;
};
