import Svg from 'components/svg';

export const BeBeId = Svg((
  <>
    <clipPath id="a"><path clipRule="evenodd" d="m0 800h177.797v128h-177.797z" /></clipPath>
    <g transform="translate(0 -800)">
      <path d="m0 800h177.797v128h-177.797z" fill="none" />
      <g clipPath="url(#a)">
        <path d="m138.05 35.915c0-11.361-9.224-20.585-20.585-20.585h-96.88c-11.361 0-20.585 9.224-20.585 20.585v38.76c0 11.361 9.224 20.585 20.585 20.585h96.88c11.361 0 20.585-9.224 20.585-20.585z" fill="#87cea3" transform="matrix(1.15377504705 0 0 1.15377922296 .0000276875 801.00002594)" />
        <circle cx="21.16" cy="72.21" r="3.6" transform="matrix(1.15377504705 0 0 1.15377922296 .0000276875 801.00002594)" />
        <g fillRule="nonzero">
          <path d="m26 74.52v-40.73h6.88v14.38c10.29-2.89 14.85 1.15 17.87 7 4.94-12.13 24.68-11.8 23.67 8.48h-17.73c1.23 9.49 11.51 7.62 14.83 5l2 4.58c-12.12 7.94-20.93.61-23.14-5.21-2.84 3.75-8.53 12.17-24.38 6.5z" transform="matrix(1.15377504705 0 0 1.15377922296 .0000276875 801.00002594)" />
          <path d="m32.92 54.07c16.43-6.41 15.84 21.12 0 16.26z" fill="#f9e819" transform="matrix(1.15377504705 0 0 1.15377922296 .0000276875 801.00002594)" />
          <path d="m56.28 58.94c0-9.06 11.24-8.69 11.24 0z" fill="#e2141a" transform="matrix(1.15377504705 0 0 1.15377922296 .0000276875 801.00002594)" />
        </g>
        <path d="m81.83 33.79h6.8v43h-6.8z" fill="#fff" transform="matrix(1.15377504705 0 0 1.15377922296 .0000276875 801.00002594)" />
        <path d="m91.31 33.79h12.1c12.14 0 17.09 7 17.09 20.53 0 14.55-4.65 22.47-17.8 22.47h-11.39zm6.69 37.48h4.59c7.56 0 11.09-5.11 11.09-16.87 0-10.41-3.52-15-11-15h-4.68z" fill="#fff" fillRule="nonzero" transform="matrix(1.15377504705 0 0 1.15377922296 .0000276875 801.00002594)" />
        <path d="m115.39 0h-16.11v12h16.11c14.648.022 26.694 12.072 26.71 26.72v33.5c-.016 14.648-12.062 26.698-26.71 26.72h-16.11v12h16.11c21.229-.027 38.688-17.491 38.71-38.72v-33.5c-.022-21.229-17.481-38.693-38.71-38.72z" fillRule="nonzero" transform="matrix(1.15377504705 0 0 1.15377922296 .0000276875 801.00002594)" />
      </g>
    </g>
  </>
), {
  viewBox: '0 0 178 128',
});

BeBeId.displayName = 'BeBeId';
