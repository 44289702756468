import { ReactNode, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGuestToken } from 'agreement/selectors';
import { isPending, isDraft } from 'agreement/states';
import { MESSAGE_TYPES, COMMENT_STATES } from 'comments/constants';
import { MESSAGES_TAB } from 'agreement/constants';
import { useAnnotationProps } from 'contexts/annotation-props';

import {
  setAgreementSidebarActiveTabName,
  setAgreementSidebarCommentState,
  setAgreementSidebarMessageType,
} from 'reducers/app';
import useAgreement from 'hooks/use-agreement';
import useCurrentMessages from 'hooks/use-current-messages';

import AlertIcon from 'components/icons/alert';
import Button from 'components/button';
import Confirmable from 'components/confirmable';
import Message from 'components/message';

import style from './resolve-annotations.module.scss';

const BodyContent = ({ getActionMessage }: { getActionMessage: () => ReactNode }) => (
  <div className={style.Body}>
    <div className={style.Icon}><AlertIcon /></div>
    {getActionMessage()}
  </div>
);

type Props = {
  children: ReactNode | ((openModal: () => void) => ReactNode),
  agreementId: number,
  openCommentsTabCb?: () => void,
}

const ResolveAnnotations = ({ children, agreementId, openCommentsTabCb }: Props) => {
  const {
    activeComments,
    activeSuggestions,
  } = useCurrentMessages();
  const { setHighlightedAnnotationId } = useAnnotationProps();
  const dispatch = useDispatch();
  const isGuest = Boolean(useSelector(getGuestToken));
  const agreement = useAgreement(agreementId);

  const annotationsNumbers = useMemo(() => {
    if (isDraft({ state: agreement.state })) {
      return {
        activeCommentsLength: activeComments.length,
        activeSuggestionsLength: activeSuggestions.length,
      };
    }
    if (isPending({ state: agreement.state })) {
      return {
        activeSuggestionsLength: activeSuggestions.length,
      };
    }
    return {};
  }, [agreement.state, activeComments.length, activeSuggestions.length]);

  const showComments = useCallback(() => {
    dispatch(setAgreementSidebarActiveTabName(MESSAGES_TAB));
    dispatch(setAgreementSidebarMessageType(MESSAGE_TYPES.COMMENTS));
    dispatch(setAgreementSidebarCommentState(COMMENT_STATES.COMMENT_ACTIVE));

    if (isDraft({ state: agreement.state })) {
      const allAnnotationsInDocument = document.getElementsByClassName('annotation');
      setHighlightedAnnotationId(Number(allAnnotationsInDocument[0].id.split('-')[1]));
    } else {
      const suggestionsInDocument = document.querySelectorAll('span[class*="_SuggestionAnnotation"]');
      setHighlightedAnnotationId(Number(suggestionsInDocument[0].id.split('-')[1]));
    }

    if (typeof openCommentsTabCb !== 'undefined') {
      openCommentsTabCb();
    }
    // TODO: Refactor to use React Router Redirect API when migrated to react-router v6
  }, [dispatch, openCommentsTabCb, setHighlightedAnnotationId, agreement.state]);

  const getActionMessage = () => {
    if (isPending({ state: agreement.state })) {
      if (isGuest) {
        return (
          <>
            <Message
              id="Document sender needs to review and resolve {jsx-start}{number} active suggestion{jsx-end}."
              pluralId="Document sender needs to review and resolve {jsx-start}{number} active suggestions{jsx-end}."
              pluralCondition="number"
              component="strong"
              values={{
                number: activeSuggestions.length,
              }}
              comment="Text to show that the document owner needs to resolve suggestions for the agreement to be ready for signing"
            />
            <Message
              id="Once resolved, you'll be able to sign."
              comment="Text to show that the document owner needs to resolve suggestions for the agreement to be ready for signing"
            />
          </>
        );
      }

      return (
        <Message
          id="To sign the document, please review and resolve {jsx-start}{number} active suggestion{jsx-end}."
          pluralId="To sign the document, please review and resolve {jsx-start}{number} active suggestions{jsx-end}."
          pluralCondition="number"
          component="strong"
          values={{
            number: activeSuggestions.length,
          }}
          comment="Text to show that the user needs to resolve suggestions to be able to sign the agreement"
        />
      );
    }

    if (annotationsNumbers.activeSuggestionsLength && annotationsNumbers.activeCommentsLength) {
      return (
        <div style={{ width: 400 }}>
          <Message
            id="To send the document, please review and resolve:"
            comment="Text to show that the user needs to resolve suggestions to be able to send the agreement"
          />
          <li>
            <strong>
              <Message
                id="{number} active comment"
                pluralId="{number} active comments"
                pluralCondition="number"
                values={{
                  number: activeComments.length,
                }}
                comment="Text to show that the user needs to resolve suggestions to be able to send the agreement"
              />
            </strong>
          </li>
          <li>
            <strong>
              <Message
                id="{number} active suggestion."
                pluralId="{number} active suggestions."
                pluralCondition="number"
                values={{
                  number: activeSuggestions.length,
                }}
                comment="Text to show that the user needs to resolve suggestions to be able to send the agreement"
              />
            </strong>
          </li>
        </div>
      );
    }

    if (annotationsNumbers.activeSuggestionsLength) {
      return (
        <Message
          id="To send the document, please resolve {jsx-start}{number} active suggestion{jsx-end}."
          pluralId="Please review and resolve {jsx-start}{number} active suggestions{jsx-end}."
          pluralCondition="number"
          component="strong"
          values={{
            number: activeSuggestions.length,
          }}
          comment="Text to show that the user needs to resolve suggestions to be able to send the agreement"
        />
      );
    }

    return (
      <Message
        id="To send the document, please review and resolve {jsx-start}{number} active comment{jsx-end}."
        pluralId="To send the document, please review and resolve {jsx-start}{number} active comments{jsx-end}."
        pluralCondition="number"
        component="strong"
        values={{
          number: activeComments.length,
        }}
        comment="Text to show that the user needs to resolve comments to be able to send the agreement"
      />
    );
  };

  const primaryActionText = useMemo(() => {
    if (annotationsNumbers.activeSuggestionsLength && annotationsNumbers.activeCommentsLength) {
      return (
        <Message
          id="Show pending"
          comment="Text for button show active suggestions."
        />
      );
    }

    if (annotationsNumbers.activeSuggestionsLength) {
      return (
        <Message
          id="Show suggestions"
          comment="Text for button show active suggestions."
        />
      );
    }

    return (
      <Message
        id="Show comments"
        comment="Text for button show active comments."
      />
    );
  }, [annotationsNumbers.activeSuggestionsLength, annotationsNumbers.activeCommentsLength]);

  const getActions = useCallback(({ closeConfirmation }) => (
    <div className={style.ActionsWrapper}>
      <Button
        onClick={closeConfirmation}
        kind="transparent"
        className={style.CancelButton}
      >
        <Message
          id="Cancel"
          comment="Text for button to close the resolve comments modal."
        />
      </Button>
      <Button
        onClick={() => {
          closeConfirmation();
          showComments();
        }}
        kind="primary"
      >
        {primaryActionText}
      </Button>
    </div>
  ), [primaryActionText, showComments]);

  return (
    <Confirmable
      header={(
        <Message
          id="Pending review"
          comment="Text to show in the header of a modal when there are unresolved comments or text change suggestions."
        />
      )}
      body={<BodyContent getActionMessage={getActionMessage} />}
      modalKey="Resolve comments modal"
      actions={getActions}
    >
      {children}
    </Confirmable>
  );
};

export default ResolveAnnotations;
