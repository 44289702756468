// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getAdjustedCurrentLanguageSelector } from 'reducers/i18n';
import { getAccountFromSessionSelector } from 'reducers/session';
import accountSeatsReducer from 'reducers/entities/account-seats';

import { FreemiumSelfServiceSeats, type Props } from './freemium-self-service-seats';

type StateProps = {|
  languageCode: EnabledLanguages,
  accountSeats: AccountSeats,
|};

type DispatchProps = $Exact<{}>;

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps>;

type MapStateToProps = (State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const account = getAccountFromSessionSelector(state);

  return {
    languageCode: getAdjustedCurrentLanguageSelector(state),
    accountSeats: accountSeatsReducer.getAccountSeatSelector(state, { id: account.id }),
  };
};

type MapDispatchToProps = () => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

const connectedFreemiumSelfServiceSeats = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(FreemiumSelfServiceSeats);

export { connectedFreemiumSelfServiceSeats as FreemiumSelfServiceSeats };
