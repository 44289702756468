import { useCallback, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import { startCase, debounce } from 'lodash';
import { push } from 'connected-react-router';

import {
  USER_ACCOUNT_ROLE_ADMIN_ID, USER_ACTIVE, USER_ROLE_USER,
} from 'user';
import userAccountsReducer from 'reducers/entities/user-accounts';
import positionsReducer from 'reducers/entities/positions';
import accountUsersReducer from 'reducers/entities/account-users';
import { getAccountFromSessionSelector } from 'reducers/session';

import Confirmable from 'components/confirmable';
import Badge from 'components/badge';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import Field from 'components/field';
import SelectField from 'components/select-field';
import ModalForm from 'hocs/modal-form';
import CircularSpinnerIcon from 'components/icons/circular-spinner';
import AdministratorIcon from 'components/icons/administrator';
import { getAccountUsersQueryName } from 'routes/admin/account-access/account-access';

import styles from './grant-user-account-access.module.scss';

export const rolesQueryName = 'grant-user-account-access/roles';
export const positionsQueryName = 'admin/users';

const ADMINISTRATOR_ROLE = {
  id: USER_ACCOUNT_ROLE_ADMIN_ID,
  name: startCase(USER_ROLE_USER),
};

const DEFAULT_PAGINATION = {
  limit: 50,
  offset: 0,
};

type QueryFunctionType = {
  fullname?: string,
  pagination?: RequestParams.Pagination,
};

type Props = {
  position?: Oneflow.Position,
  children: JSX.Element | ((onClick: () => void) => JSX.Element),
  shouldRedirectToAccountAccess: boolean,
  renderedFromAccountAccessPage: boolean,
};

type ModalActionsType = {
  closeConfirmation: () => void,
};

const GrantUserAccountAccessNonEnterprise = (
  {
    position: positionFromProps,
    children,
    shouldRedirectToAccountAccess,
    renderedFromAccountAccessPage,
  }: Props,
): JSX.Element => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountFromSessionSelector);
  const [position, setPosition] = useState(positionFromProps);

  const submissionState = useSelector((state) => (
    userAccountsReducer.getGrantUserAccountAccessSelector(state, { id: position?.id })
  ));

  const resetSubmissionState = () => {
    dispatch(userAccountsReducer.grantUserAccountAccessReset({ id: position?.id }));
  };

  const positionsQuery = useSelector((state) => (
    positionsReducer.getQuerySelector(state, { name: positionsQueryName })
  ));

  const positions = useSelector((state) => (
    positionsReducer.getPositionsSelector(state, { ids: positionsQuery.result })
  ));

  const queryPositions = useCallback(
    ({ fullname, pagination = DEFAULT_PAGINATION }: QueryFunctionType) => {
      dispatch(positionsReducer.queryPositions({
        name: positionsQueryName,
        params: {
          active: USER_ACTIVE,
          q: fullname,
          userRole: USER_ROLE_USER,
        },
        pagination,
        sort: ['fullname'],
      }));
    }, [dispatch],
  );

  const handleLoadMorePositions = (additionalResults: number) => {
    dispatch(positionsReducer.queryPositionsLoadMore({
      name: positionsQueryName,
      additionalResults,
    }));
  };

  const handleInputChange = useMemo(() => debounce((fullname) => {
    queryPositions({ fullname });
  }, 500), [queryPositions]);

  const renderIcon = (): JSX.Element => <div className={styles.AdministratorIcon}><AdministratorIcon height="72" width="60" /></div>;

  const renderHeader = (): ReactNode => (
    <Message
      id="Assign role"
      comment="Modal title for assigning account role for a user"
    />
  );

  const renderBody = (): JSX.Element => {
    if (!renderedFromAccountAccessPage) {
      return (
        <div className={styles.NonEnterpriseBody}>
          {renderIcon()}
          <b>
            <Message
              id="You are about to assign {positionName} as the account {roleName}."
              values={{
                positionName: <Badge label={position?.fullname} kind="name" />,
                roleName: ADMINISTRATOR_ROLE.name,
              }}
              comment="Modal text when granting account role for a user."
            />
          </b>
          <br />
          <Message
            id="This role allows managing all account role permission for users."
            comment="Modal text when granting account role for a user."
          />
        </div>
      );
    }

    return (
      <div>
        <Field
          name="user"
          label={(
            <Message
              id="User"
              comment="Input field label"
            />
          )}
          placeholder={(
            <Message
              id="Select user"
              comment="Input field placeholder"
            />
          )}
          component={SelectField}
          options={positions}
          valueKey="id"
          labelKey="fullname"
          isLoading={positionsQuery.loading}
          loadMoreItems={handleLoadMorePositions}
          required
          isSearchable
          onInputChange={handleInputChange}
          onChange={(selectedPosition: Oneflow.Position) => setPosition(selectedPosition)}
        />
        <b>
          <Message
            id="You are about to assign this user as the account {roleName}."
            values={{
              roleName: ADMINISTRATOR_ROLE.name,
            }}
            comment="Modal text when granting account role for a user."
          />
        </b>
        {' '}
        <Message
          id="This role allows managing all account role permission for users."
          comment="Modal text when granting account role for a user."
        />
      </div>
    );
  };

  const grantUserAccountAccess = (): void => {
    const USER_ACCOUNTS_BINDINGS_QUERY_NAME = `admin/user/${position?.id}/account-access`;
    const actionData = {
      id: position?.id,
      data: {
        positionId: position?.id,
        roleId: ADMINISTRATOR_ROLE.id,
      },
      pipe: {
        action: () => userAccountsReducer.queryUserAccounts({
          name: USER_ACCOUNTS_BINDINGS_QUERY_NAME,
          params: {
            id: position?.id,
            actorType: 'position',
          },
          sort: ['-role.type', 'role.name'],
        }),
      },
    };

    if (shouldRedirectToAccountAccess) {
      actionData.pipe.action = () => push(`/admin/users/${position?.id}/account-access`);
    }

    if (renderedFromAccountAccessPage) {
      actionData.pipe.action = () => accountUsersReducer.queryAccountUsers({
        name: getAccountUsersQueryName(account.id),
        params: {
          actorType: 'position',
        },
        pagination: DEFAULT_PAGINATION,
        sort: ['fullname'],
      });
    }

    dispatch(userAccountsReducer.grantUserAccountAccess(actionData));
  };

  const renderActions = ({ closeConfirmation }: ModalActionsType) => (
    <div className={styles.ActionButtons}>
      <CancelButton onClick={closeConfirmation} />
      <Button
        kind="primary"
        onClick={grantUserAccountAccess}
        disabled={submissionState.loading}
        icon={submissionState.loading ? CircularSpinnerIcon : null}
      >
        <Message
          id="Assign role"
          comment="Text for button to assigning the access."
        />
      </Button>
    </div>
  );

  if (renderedFromAccountAccessPage) {
    return (
      <ModalForm
        title={(
          <Message
            id="Assign role"
            comment="Modal title for assigning account access"
          />
        )}
        body={renderBody()}
        onSubmit={grantUserAccountAccess}
        formState={submissionState}
        resetFormState={resetSubmissionState}
        onOpen={() => queryPositions({})}
        actions={renderActions}
        modalKey="grant user account access modal"
      >
        {children}
      </ModalForm>
    );
  }

  return (
    <Confirmable
      header={renderHeader()}
      body={renderBody()}
      error={submissionState?.error}
      success={submissionState?.success}
      onEnter={grantUserAccountAccess}
      isConfirmLoading={submissionState?.loading}
      onConfirm={grantUserAccountAccess}
      actions={renderActions}
      onOpen={resetSubmissionState}
      modalKey="grant user account access non enterprise modal"
    >
      {children}
    </Confirmable>
  );
};

export default GrantUserAccountAccessNonEnterprise;
