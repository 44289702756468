export const LOAD_SUPPORT = 'freshworks/LOAD_SUPPORT' as const;
export const SET_SUPPORT_IS_LOADING = 'freshworks/SET_SUPPORT_IS_LOADING' as const;
export const SET_SUPPORT_LOADED = 'freshworks/SET_SUPPORT_LOADED' as const;

type CurrentFreshworksState = {
  isLoading: boolean,
  isLoaded: boolean,
}

// action creators

export const loadSupport = () => ({
  type: LOAD_SUPPORT,
  isLoading: true,
});

export const setSupportIsLoading = (isLoading: boolean) => ({
  type: SET_SUPPORT_IS_LOADING,
  isLoading,
});

export const setSupportLoaded = (isLoaded: boolean) => ({
  type: SET_SUPPORT_LOADED,
  isLoaded,
});

const initialState: CurrentFreshworksState = {
  isLoaded: false,
  isLoading: false,
};

type Action =
  | ReturnType<typeof setSupportIsLoading>
  | ReturnType<typeof setSupportLoaded>
  | ReturnType<typeof loadSupport>;

const freshworks = (state: CurrentFreshworksState = initialState, action: Action) => {
  switch (action.type) {
    case SET_SUPPORT_LOADED:
      return {
        ...state,
        isLoaded: action.isLoaded,
      };
    case SET_SUPPORT_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

// selectors

type RootState = {
  freshworks: CurrentFreshworksState;
};

export const getSupportLoadedSelector = (state: RootState) => (
  state.freshworks.isLoaded
);

export const getSupportIsLoadingSelector = (state: RootState) => (
  state.freshworks.isLoading
);

export default freshworks;
