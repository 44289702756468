// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import Sso from './sso';
import type { Props } from './sso';

type StateProps = {|
  currentLanguage: string,
|};
type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = () => ({});

type DispatchProps = $Exact<{}>;
type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect < Props, any, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(Sso);
