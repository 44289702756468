// @flow

import isEmpty from 'lodash/isEmpty';

function joinListWithSeparator(list: Array<any>, separator: any) {
  return list.filter((listItem) => !isEmpty(listItem)).reduce((acc, listItem, index) => {
    if (index > 0 && listItem !== separator) {
      acc.push(separator);
    }
    acc.push(listItem);
    return acc;
  }, []);
}

export default joinListWithSeparator;
