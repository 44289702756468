// @flow

import * as React from 'react';
import debounce from 'lodash/debounce';
import clsx from 'clsx';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { checkAcl } from 'components/acl';
import Search from 'components/search';

import style from './template-search.module.scss';

type Props = {
  templates: Array<AgreementTemplate>,
  queryName: string,
  queryNameWithImportTag: string,
  requestTemplates: (string, ?any) => void,
  message: MessageTranslator,
  workspace: Workspace,
  isLoading: boolean,
  isDisabled?: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  isInImportContractModal?: boolean,
  isSearching: boolean,
};

export class TemplateSearchComponent extends React.Component<Props> {
  static defaultProps = {
    isDisabled: undefined,
    isInImportContractModal: undefined,
  };

  onSearchHandler = (value: string) => {
    const {
      queryName,
      requestTemplates,
      isInImportContractModal,
      queryNameWithImportTag,
    } = this.props;

    if (isInImportContractModal) {
      requestTemplates(queryNameWithImportTag, value);
      return;
    }
    requestTemplates(queryName, value);
  };

  render() {
    const {
      templates,
      message,
      workspace,
      isSearching,
      isLoading,
      isDisabled,
      isInImportContractModal,
    } = this.props;
    const hasNoTemplates = !isLoading && !templates.length && !isSearching;
    const isContractCreateEnabled = checkAcl(workspace.acl, 'collection:agreement:create');

    if (isInImportContractModal || hasNoTemplates || !isContractCreateEnabled) {
      return null;
    }

    const templateSearchClasses = clsx(style.TemplateSearch, {
      [style.TemplateSearchDisabled]: isDisabled,
    });

    return (
      <div className={templateSearchClasses}>
        <Search
          autoFocus
          onChange={debounce(this.onSearchHandler, 400)}
          loading={isLoading}
          placeholder={message({
            id: 'Search templates',
            comment: 'Placeholder text used in template launcher.',
          })}
        />
      </div>
    );
  }
}

export default localize<Props>(TemplateSearchComponent);
