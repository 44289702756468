import { Editor } from 'slate';

// eslint-disable-next-line import/prefer-default-export
export const addHorizontalLine = (editor) => {
  Editor.insertNode(editor, {
    type: 'horizontal-line',
    children: [{ text: '' }],
  });
  Editor.insertNode(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  });
};
