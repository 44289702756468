import Message from 'components/message';
import {
  MAX_PRICE_VALUE,
} from 'components/contract-boxes/product-table-box/constants';

import {
  isValueSet,
  canCastToNumber,
  canCompareInBusinessContext,
  isDiscountHigherThanPrice,
  isValidPercentage,
  isValidDiscountInputValue,
  isDiscountPercentageHigher,
  isDiscountPercentageLower,
  isStepMismatch,
} from './update-product-helpers';

export const discountCustomErrorMapper = {
  higherPercentage: (
    <Message
      id="The discount can't exceed 100%"
      comment="Validation message when discount percentage is higher than 100%"
    />
  ),
  lowerPercentage: (
    <Message
      id="Discount must be higher than 0%"
      comment="Validation message when discount percentage is less than or equal to 0%"
    />
  ),
  higherThanPrice: (
    <Message
      id="The discount can't be more than the price"
      comment="Validation message when discount amount is higher than price"
    />
  ),
  toString() {
    return 'Custom validation error';
  },
};

// @check properties of Object.getOwnPropertyNames(ValidityState.prototype)
export const validationMessagesMapper = {
  price: { // These are the partial keys of InputElement.validity
    rangeOverflow: <Message id="The price must be between 0 and 999999999" comment="Invalid price input message: the product price is outside the expected range of valid values" />,
    rangeUnderflow: <Message id="The Price must be between 0 and 999999999" comment="Invalid price input message: the product price is outside the expected range of valid values" />,
    stepMismatch: <Message id="The maximum amount of decimals is 4." comment="Invalid price input message: the current price input contains more than 4 decimal digits" />,
    badInput: <Message id="Enter a numeric value." comment="Invalid price input message: the price contains characters that are not valid" />,
    patternMismatch: <Message id="Use a dot for decimals, e.g. 1.25." comment="Invalid price input message: the price uses a comma as a decimal separator" />,
  },
  discount: {
    rangeOverflow: <Message id="Discount must be less than or equal to 999999999" comment="Invalid discount input message: the discount is more than the maximum accepted value" />,
    rangeUnderflow: <Message id="Enter a number greater than 0" comment="Invalid discount input message: the discount is less than the minimum accepted value" />,
    stepMismatch: <Message id="The maximum amount of decimals is 4." comment="Invalid price input message: the current discount input contains more than 4 decimal digits" />,
    badInput: <Message id="Enter a numeric value." comment="Invalid discount input message: the discount contains characters that are not valid" />,
    patternMismatch: <Message id="Use a dot for decimals, e.g. 1.25." comment="Invalid discount input message: the discount uses a comma as a decimal separator" />,
    customError: discountCustomErrorMapper,
  },
};

export const getPriceValidators = () => [
  {
    validate: (amount: string) => amount.includes(','),
    validationMessage: () => validationMessagesMapper.price.patternMismatch,
  },
  {
    validate: (amount: string) => !canCastToNumber(amount),
    validationMessage: () => validationMessagesMapper.price.badInput,
  },
  {
    validate: (amount: string) => isStepMismatch(amount),
    validationMessage: () => validationMessagesMapper.price.stepMismatch,
  },
  {
    validate: (amount: string) => canCastToNumber(
      amount,
    ) && Number(amount) > MAX_PRICE_VALUE,
    validationMessage: () => validationMessagesMapper.price.rangeOverflow,
  },
  {
    validate: (amount: string) => isValueSet(amount)
        && canCastToNumber(amount)
        && Number(amount) < 0,
    validationMessage: () => validationMessagesMapper.price.rangeUnderflow,
  },
];

export const getDiscountValidators = (price?: string) => [
  {
    validate: (amount: string) => amount.includes(','),
    validationMessage: () => validationMessagesMapper.discount.patternMismatch,
  },
  {
    // Keep this as a second check as it validate the possibility of casting to number
    validate: (discountAmount: string) => !isValidDiscountInputValue(discountAmount),
    validationMessage: () => validationMessagesMapper.discount.badInput,
  },
  {
    validate: (discountAmount: string) => canCastToNumber(
      discountAmount,
    ) && Number(discountAmount) > MAX_PRICE_VALUE,
    validationMessage: () => validationMessagesMapper.discount.rangeOverflow,
  },
  // discount is manually set to 0 or > 0, regardless of the price value
  {
    validate: (discountAmount: string) => isValueSet(discountAmount)
      && canCastToNumber(discountAmount)
      && Number(discountAmount) <= 0,
    validationMessage: () => validationMessagesMapper.discount.rangeUnderflow,
  },
  {
    validate: (discountAmount: string) => isValidPercentage(discountAmount)
      && isDiscountPercentageHigher(discountAmount),
    validationMessage: () => validationMessagesMapper.discount.customError.higherPercentage,
  },
  {
    validate: (discountAmount: string) => isValidPercentage(discountAmount)
      && isDiscountPercentageLower(discountAmount),
    validationMessage: () => validationMessagesMapper.discount.customError.lowerPercentage,
  },
  {
    validate: (discountAmount: string) => canCompareInBusinessContext(discountAmount, price ?? 0)
      && isDiscountHigherThanPrice(discountAmount, price ?? 0),
    validationMessage: () => validationMessagesMapper.discount.customError.higherThanPrice,
  },
];
