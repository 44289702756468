import type { ReactNode } from 'react';
import clsx from 'clsx';

import type { Layout } from 'components/document-layout-container/types';
import { EXPANDED } from 'components/document-layout-container/helpers';
import { ButtonFC as Button } from 'components/button';

import style from './approve-button.module.scss';

export type ButtonSize = 'sm';

type Props = {
  layout?: Layout;
  onClick: () => void;
  children: ReactNode;
  size?: ButtonSize;
  disabled?: boolean;
};

export const ApproveButton = ({
  layout = EXPANDED, onClick, children, size, disabled,
}: Props) => (
  <Button
    kind="approve"
    onClick={onClick}
    layout={layout}
    customClass={clsx(style.ApproveButton, {
      [style.SmallExpanded]: layout === EXPANDED && size === 'sm',
    })}
    disabled={disabled}
  >
    {children}
  </Button>
);
