// @flow

import { isEqual } from 'lodash';

const defaultFormatting = {
  color: '#000000',
  font: [
    'Proxima Nova',
    'sans-serif',
  ],
  size: 14,
};

// eslint-disable-next-line max-len
export const isNotDefaultFormatting = (formattingConfig) => !isEqual(defaultFormatting, formattingConfig);

const fontNameMapper = [
  { validFonts: ['arimo'], fontName: 'Arimo' },
  { validFonts: ['questrial'], fontName: 'Questrial' },
  { validFonts: ['anton'], fontName: 'Anton' },
  { validFonts: ['poppins'], fontName: 'Poppins' },
  { validFonts: ['proxima nova'], fontName: 'Proxima Nova' },
  { validFonts: ['roboto'], fontName: 'Roboto' },
  { validFonts: ['raleway'], fontName: 'Raleway' },
  { validFonts: ['jost'], fontName: 'Jost' },
  { validFonts: ['inter'], fontName: 'Inter' },
  { validFonts: ['ibm plex sans'], fontName: 'IBM Plex Sans' },
  { validFonts: ['source sans pro'], fontName: 'Source Sans Pro' },
  { validFonts: ['work sans'], fontName: 'Work Sans' },
  { validFonts: ['ibm plex serif'], fontName: 'IBM Plex Serif' },
  { validFonts: ['gelasio'], fontName: 'Gelasio' },
  { validFonts: ['garamond'], fontName: 'Garamond' },
  { validFonts: ['tinos'], fontName: 'Tinos' },
  { validFonts: ['libre baskerville'], fontName: 'Libre Baskerville' },
  { validFonts: ['courier prime'], fontName: 'Courier Prime' },
  { validFonts: ['ibm plex mono'], fontName: 'IBM Plex Mono' },
  { validFonts: ['comic neue'], fontName: 'Comic Neue' },
  { validFonts: ['caveat'], fontName: 'Caveat' },
  { validFonts: ['sacramento'], fontName: 'Sacramento' },
  // Fallbacks
  { validFonts: ['sans-serif'], fontName: 'sans-serif' },
  { validFonts: ['serif'], fontName: 'serif' },
  { validFonts: ['monospace'], fontName: 'Monospace' },
];

export const getFontSelectorLabel = (fontName: string = '') => {
  const fontNameLowerCased = fontName.toLowerCase();
  const mappedLabel = fontNameMapper.find((font) => font.validFonts.includes(fontNameLowerCased));

  return mappedLabel?.fontName ?? 'Proxima Nova';
};

export const getAgreementFontValues = (fontValues: string[]) => {
  const fonts = fontValues?.length > 0 ? fontValues : ['Proxima Nova, sans-serif'];

  /*
    Will filter out any fonts that does not exist
    but also get the right naming in case of lowercased font
  */
  const filteredFonts = [];
  fonts.forEach((font) => {
    const fontName = font.toLowerCase();

    const foundFont = fontNameMapper.find(
      (fontNameMapperName) => fontNameMapperName.validFonts.includes(fontName),
    )?.fontName;

    if (foundFont) {
      filteredFonts.push(foundFont);
    }
  });

  return filteredFonts.join(', ');
};
