import type { ReactNode } from 'react';
import { isEmpty } from 'lodash';

import style from './participant.module.scss';

type Props = {
  participant?: Oneflow.Participant | null;
  children: ReactNode;
}

const Participant = ({
  participant, children,
}: Props) => {
  if (isEmpty(participant)) return null;
  let contactInfo = participant?.email;
  if (!participant?.email) {
    contactInfo = participant?.phoneNumber;
  }

  return (
    <>
      <div className={style.ParticipantInfo}>
        <span
          className={style.Name}
          data-testid="name"
        >
          {participant?.fullname}
        </span>
        <span className={style.Email} data-testid="email">{contactInfo ?? ''}</span>
      </div>
      {children}
    </>
  );
};

export default Participant;
