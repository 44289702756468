import useCurrentContractId from 'hooks/use-current-contract-id';
import useAgreement from 'hooks/use-agreement';
import { checkAcl } from 'components/acl';

import { isSigned } from 'agreement/states';

import { useExpandableSidebarProps } from 'components/document-layout-container/expanded-document-layout/context';

const useIsDragHandlerVisible = () => {
  const contractId = useCurrentContractId();
  const agreement = useAgreement(contractId);
  const canDrag = checkAcl(agreement?.acl, 'agreement:layout:update');

  const expandedContext = useExpandableSidebarProps();
  const isExpandedSidebarOpen = expandedContext?.expandedSidebar;
  const isFieldsOnPDFEnabled = agreement?.availableOptions?.documentOverlayFields;

  return isFieldsOnPDFEnabled && canDrag && !!isExpandedSidebarOpen && !isSigned(agreement);
};

export default useIsDragHandlerVisible;
