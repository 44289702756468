import { connect } from 'react-redux';

import tagsReducer from 'reducers/entities/tags';

import { RemoveTagConfirmation } from './remove-tag';

const QUERY_NAME = '/admin/tags';

export const mapStateToProps = (state, { tag }) => ({
  removeState: tagsReducer.getRemoveSelector(state, { id: tag.id }),
});

export const mapDispatchToProps = (dispatch, { tag }) => ({
  removeTag: () => {
    dispatch(tagsReducer.removeTag({
      id: tag.id,
      data: {
        ids: [tag.id],
      },
      pipe: {
        action: () => tagsReducer.queryTagsReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(tagsReducer.removeTagReset({
      id: tag.id,
    }));
  },
});

const connectedRemoveTagConfirmation = connect(
  mapStateToProps, mapDispatchToProps,
)(RemoveTagConfirmation);

export { connectedRemoveTagConfirmation as RemoveTagConfirmation };
