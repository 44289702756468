import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import log from 'logging';
import debounce from 'debounce-promise';
import unionBy from 'lodash/unionBy';
import { orderBy } from 'natural-orderby';
import { localize, Message, MessageTranslator } from '@oneflowab/pomes';

import { fetchColleagues } from 'oneflow-client/positions';
import { getCurrentContractId } from 'reducers/current-contract';

import useAgreement from 'hooks/use-agreement';

import { isImportInProgress } from 'agreement/import';
import { getAgreementMyParty } from 'agreement/selectors';
import * as participantConstants from 'agreement/participant/constants';

import SelectField from 'components/select-field';
import Field from 'components/field/field';

import ColleagueOption from './colleague-option';

type PositionItem = Oneflow.Position & { disabled?: boolean };

type Option = Partial<PositionItem> & {
  value: number;
  label?: string;
  disabled?: boolean;
} | undefined;

type Props = {
  onChange: (value: Option) => void;
  message: MessageTranslator;
};

const DEFAULT_LOAD_LIMIT = 50;
const SEARCH_LOAD_LIMIT = 50;

const orderColleagues = (colleagueList: PositionItem[]): PositionItem[] => orderBy(
  colleagueList,
  [(v) => v.fullname],
  ['asc'],
);

const getColleagueLabel = (colleague: PositionItem) => `${colleague.fullname} - ${colleague.email}`;

const mapColleagueToOption = (colleague: PositionItem) => ({
  ...colleague,
  label: getColleagueLabel(colleague),
  value: colleague.id,
  disabled: colleague.disabled,
});

const Colleague = ({
  onChange,
  message,
}: Props) => {
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const myParty = getAgreementMyParty(agreement);
  const disableList: Oneflow.Participant[] = useMemo(() => {
    if (!myParty || !myParty.participants) {
      return [];
    }

    return myParty.participants.filter(
      (participant) => participant.state !== participantConstants.STATE_IS_DISABLED,
    );
  }, [myParty]);
  const includeInactiveColleagues = useMemo(() => (
    isImportInProgress(agreement) ? undefined : 1
  ), [agreement]);

  const [isLoading, setIsLoading] = useState(false);
  const [colleagues, setColleagues] = useState<PositionItem[]>([]);
  const [totalColleagues, setTotalColleagues] = useState(0);
  const [selectedColleague, setSelectedColleague] = useState<Option>(undefined);
  const [offset, setOffset] = useState(0);

  const disableExistingColleagues = useCallback((
    colleaguesData: PositionItem[],
  ): PositionItem[] => {
    if (!disableList) {
      return colleaguesData;
    }
    const agreementActiveColleaguesIds = disableList.map(
      (participant) => participant.position?.id,
    );

    return colleaguesData.map((colleague) => ({
      ...colleague,
      disabled: agreementActiveColleaguesIds?.includes(colleague.id),
    }));
  }, [disableList]);

  const onColleagueSelected = useCallback((item: Option) => {
    setSelectedColleague(item);
    onChange(item);
  }, [onChange]);

  const addToColleagues = useCallback((
    prevState: PositionItem[],
    newColleagues: PositionItem[],
  ): PositionItem[] => {
    const finalList = unionBy(prevState, newColleagues, (item) => item.id);
    const listWithDisabled = disableExistingColleagues(finalList);
    return orderColleagues(listWithDisabled);
  }, [disableExistingColleagues]);

  const loadMoreColleagues = useCallback((limit: number) => {
    const loadMore = async (newOffset: number) => {
      setIsLoading(true);
      try {
        const {
          collection: moreColleagues,
        }: { collection: PositionItem[] } = await fetchColleagues({
          active: includeInactiveColleagues,
          invited: 0,
          limit,
          offset: newOffset,
        });

        setColleagues((prevState) => addToColleagues(prevState, moreColleagues));
        setOffset(newOffset);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw new Error('Could not load colleagues');
      }
    };

    if (colleagues.length >= totalColleagues) {
      return;
    }

    loadMore(offset + limit);
  }, [addToColleagues, colleagues.length, includeInactiveColleagues, offset, totalColleagues]);

  const onColleaguesSearch = useCallback((searchValue: string) => {
    const searchColleagues = async () => {
      try {
        const { collection: colleaguesFound } = await fetchColleagues({
          active: includeInactiveColleagues,
          invited: 0,
          limit: SEARCH_LOAD_LIMIT,
          q: searchValue,
        });
        const searchColleaguesData = disableExistingColleagues(colleaguesFound);
        return orderColleagues(searchColleaguesData).map(mapColleagueToOption);
      } catch {
        return [];
      }
    };

    return searchColleagues();
  }, [disableExistingColleagues, includeInactiveColleagues]);

  const debouncedColleaguesSearch = useMemo(() => (
    debounce(onColleaguesSearch, 500)
  ), [onColleaguesSearch]);

  useEffect(() => {
    const asyncFetchColleagues = async () => {
      setIsLoading(true);
      try {
        const response = await fetchColleagues({
          active: includeInactiveColleagues,
          invited: 0,
          limit: DEFAULT_LOAD_LIMIT,
        });

        const colleaguesData = response.collection;
        const totalColleaguesCount = response.count;

        setTotalColleagues(totalColleaguesCount);
        setColleagues((prevState) => addToColleagues(prevState, colleaguesData));
      } catch (error) {
        log.error(error, {
          errorContext: 'AddColleagueModal',
        });
      } finally {
        setIsLoading(false);
      }
    };

    asyncFetchColleagues();
  }, [addToColleagues, includeInactiveColleagues]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      setSelectedColleague(undefined);
    };
  }, []);

  return (
    <Field
      name="colleague"
      label={(
        <Message
          id="Colleague"
          comment="Field label in add/edit colleague modal."
        />
      )}
      placeholder={(
        message({
          id: 'Select colleague',
          comment: 'Label for the colleague field in the add/edit colleague modal.',
        })
      )}
      component={SelectField}
      clearable={false}
      required
      input={{
        onChange,
        value: selectedColleague,
        name: 'colleague',
      }}
      loadMoreItems={loadMoreColleagues}
      additionalItemsPerLoad={DEFAULT_LOAD_LIMIT}
      isOptionDisabled={(option: PositionItem) => option.disabled}
      isLoading={isLoading}
      async
      defaultOptions={orderColleagues(colleagues).map(mapColleagueToOption)}
      loadOptions={debouncedColleaguesSearch}
      cacheOptions
      onChange={onColleagueSelected}
      components={{ Option: ColleagueOption }}
    />
  );
};

export default localize<Props>(Colleague);
