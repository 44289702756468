// eslint-disable-next-line import/named
import { PopoverClose } from 'components/popover';
import type { ButtonProps } from 'components/button';
import { ButtonWithForwardRef } from './popover-trigger-button';

export default function PopoverCloseButton(props: ButtonProps) {
  return (
    <PopoverClose asChild>
      <ButtonWithForwardRef {...props} />
    </PopoverClose>
  );
}
