import type { MomentInput } from 'moment';

import { usePrimaryLocalization } from 'contexts/localization';
import Tooltip from 'components/tooltip';
import {
  getLocalizedDateTime,
  getLocalizedDateTimeWithTimezone,
} from 'localization';

type LocalizedDateTimeProps = {
  datetime: MomentInput;
};

export const LocalizedDateTime = ({ datetime }: LocalizedDateTimeProps) => {
  const primaryLocalization = usePrimaryLocalization();
  const formattedDate = getLocalizedDateTime(primaryLocalization, datetime);
  const formattedDateWithTimezone = getLocalizedDateTimeWithTimezone(primaryLocalization, datetime);

  return (
    <Tooltip
      message={formattedDateWithTimezone}
      theme="oneflow"
      side="top"
      sideOffset={4}
    >
      <span>{formattedDate}</span>
    </Tooltip>
  );
};
