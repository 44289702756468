/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { History } from 'history';
import { useContext } from 'react';
import { __RouterContext } from 'react-router';

const useRouterHistory = (): History | null => {
  // @ts-ignores
  const routerContext = useContext(__RouterContext);

  if (!routerContext) {
    return null;
  }

  return routerContext.history;
};

export default useRouterHistory;
