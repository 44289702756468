/* eslint-disable react/prop-types */

import React from 'react';
import clsx from 'clsx';

import Description from 'components/contract-boxes/form-box/components/description';
import Edit from 'components/icons/edit';
import Label from 'components/contract-boxes/form-box/components/label';
import PropagationBoundary from 'components/propagation-boundary';
import ReadOnly from 'components/contract-boxes/form-box/components/read-only';
import TextInput from 'components/contract-boxes/form-box/components/text';
import { useBoxItemIsVisible } from 'components/box-list/box-item/box-item-is-visible-context';

import { useFormFieldContext } from '../form-field-context';
import useControlledEscapedInput from '../use-controlled-escaped-input';
import style from './text-field.module.scss';
import { useFieldValidationContext } from '../field-validation-provider';

const FormTextField = ({
  description,
  inputSize,
  isActive,
  isAllowedToUpdateDataValue,
  isAllowedToUpdateSharedDataValue,
  label,
  placeholder,
  required,
}) => {
  const {
    fieldValue,
    onFieldValueChangeDebounced,
    hasDataField,
  } = useFormFieldContext();
  const { touched, setTouched } = useFieldValidationContext();
  const {
    displayValue,
    onChange,
  } = useControlledEscapedInput(fieldValue, onFieldValueChangeDebounced);
  const { isVisible } = useBoxItemIsVisible();

  const onBlur = () => {
    setTouched(true);
  };

  const fieldContainerClasses = clsx(style.FieldContainer, {
    [style.Active]: isActive,
    [style.Uneditable]: !isAllowedToUpdateDataValue,
  });

  const invalid = (
    required
    && isAllowedToUpdateSharedDataValue
    && !fieldValue
    && touched
    && isVisible);

  return (
    <div className={fieldContainerClasses}>
      <div className={style.Wrapper}>
        <Label
          hasDataField={hasDataField}
          isAllowedToUpdateSharedDataValue={isAllowedToUpdateSharedDataValue}
          label={label}
          required={required}
        />
        <div className={style.Edit}>
          <Edit height="14px" />
        </div>
      </div>
      {isAllowedToUpdateSharedDataValue ? (
        <div className={inputSize}>
          <PropagationBoundary>
            <TextInput
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              value={displayValue}
              required={required}
              invalid={invalid}
            />
          </PropagationBoundary>
        </div>
      ) : (
        <ReadOnly value={displayValue} />
      )}
      <Description description={description} />
    </div>
  );
};

export default FormTextField;
