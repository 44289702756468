// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import groupBy from 'lodash/groupBy';

import agreements from 'reducers/entities/agreements';
import { removeContractLink } from 'oneflow-client/agreement-links';
import MiniContractCard from 'components/mini-contract-card';
import ContractLinkExternalUrl from 'components/contract-link-external-url';
import LinkToContract from 'components/contract-actions/link-to-contract';
import { checkAcl } from 'components/acl';
import {
  LINK_TYPE_EXTENSION,
  LINK_TYPE_RELATION,
  LINK_TYPE_REPLACEMENT,
  LINK_TYPE_SUBCONTRACT,
  LINK_TYPE_EXTERNAL_URL,
} from 'agreement/constants';

import style from './contract-links-list.module.scss';

type Props = {
  agreement: Agreement,
};

const getAgreementToShow = (clickedAgreement: Agreement, link) => {
  let agreementToShow = link.targetAgreement;
  if (link.agreement.id !== clickedAgreement.id) {
    agreementToShow = link.agreement;
  }

  return agreementToShow;
};

const isClickedContractTheTarget = (agreement, link) => (agreement.id === link.targetAgreement?.id);

const getLinkTypeInfo = (linkType: string, isTarget: boolean) => {
  switch (linkType) {
    case LINK_TYPE_RELATION:
      return {
        label: (
          <Message
            id="Relates to"
            comment="Label for contract links, for contracts that are related to the clicked contract."
          />
        ),
        order: 1,
      };
    case LINK_TYPE_SUBCONTRACT:
      if (isTarget) {
        return {
          label: (
            <Message
              id="Is the main contract to"
              comment="Label for contract links, for contracts that are subcontracts of the clicked contract."
            />
          ),
          order: 2,
        };
      }
      return {
        label: (
          <Message
            id="Is a sub-contract to"
            comment="Label for contract links, for contracts that the clicked contract is a subcontract of."
          />
        ),
        order: 3,
      };
    case LINK_TYPE_EXTENSION:
      if (isTarget) {
        return {
          label: (
            <Message
              id="Is amended by"
              comment="Label for contract links, for contracts that are amendments of the clicked contract."
            />
          ),
          order: 4,
        };
      }
      return {
        label: (
          <Message
            id="Amends"
            comment="Label for contract links, for contracts that the clicked contract amends."
          />
        ),
        order: 5,
      };
    case LINK_TYPE_REPLACEMENT:
      if (isTarget) {
        return {
          label: (
            <Message
              id="Is replaced by"
              comment="Label for contract links, for contracts that replace the clicked contract."
            />
          ),
          order: 6,
        };
      }
      return {
        label: (
          <Message
            id="Replaces"
            comment="Label for contract links, for contracts that the clicked contract is a replacement for."
          />
        ),
        order: 7,
      };
    case LINK_TYPE_EXTERNAL_URL:
      return {
        label: (
          <Message
            id="External"
            comment="Label for contract links, for external links."
          />
        ),
        order: 8,
      };

    default:
      return '';
  }
};

const ContractLinksList = ({ agreement }: Props) => {
  const dispatch = useDispatch();
  const [isRemovingLink, setIsRemovingLink] = useState(null);
  const [confirmState, setConfirmState] = useState({ success: false, loading: false, error: null });

  const fetchLinks = React.useCallback(() => {
    dispatch(agreements.fetchContractLinks({
      id: agreement.id,
    }));
  }, [agreement, dispatch]);

  const onRemove = useCallback(async (selectedLinkToRemove: Agreement) => {
    const removeLinkData = {
      agreementId: agreement.id,
      id: selectedLinkToRemove.id,
    };

    try {
      setConfirmState({
        success: false,
        loading: true,
        error: null,
      });
      setIsRemovingLink(selectedLinkToRemove.id);
      await removeContractLink(removeLinkData);

      setConfirmState({
        success: true,
        loading: false,
        error: null,
      });
      fetchLinks();
    } catch (err) {
      setConfirmState({
        success: false,
        loading: false,
        error: err,
      });
      setIsRemovingLink(null);
    }
  }, [fetchLinks, agreement.id]);

  const resetRemoveState = () => {
    setConfirmState({
      success: false,
      loading: false,
      error: null,
    });
  };

  useEffect(() => {
    setIsRemovingLink(null);
  }, [agreement.links]);

  const agreementLinks = agreement.links.map((link) => ({
    ...link,
    linkTypeInfo: getLinkTypeInfo(link.linkType, isClickedContractTheTarget(agreement, link)),
  }));
  const groupedLinks = Object.values(groupBy(agreementLinks, 'linkTypeInfo.order'));

  const renderMiniContractCards = () => (
    groupedLinks.map((linksArray, index) => {
      const [{ linkTypeInfo: { label } }] = linksArray;
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className={style.LinkTypeContainer} key={index}>
          <p className={style.LinkTypeLabel}>
            {label}
          </p>
          {linksArray.map((link) => {
            if (link.linkType === LINK_TYPE_EXTERNAL_URL) {
              const canBeRemoved = checkAcl(agreement.acl, 'agreement:link:remove');

              return (
                <ContractLinkExternalUrl
                  key={link.id}
                  externalUrl={link.targetExternalUrl}
                  externalUrlTitle={link.targetExternalUrlTitle}
                  canBeRemoved={canBeRemoved}
                  onRemove={() => onRemove(link)}
                  isRemoving={isRemovingLink === link.id}
                  resetRemoveState={resetRemoveState}
                  confirmState={confirmState}
                />
              );
            }

            const linkedAgreement = getAgreementToShow(agreement, link);
            const canBeRemoved = checkAcl(linkedAgreement.acl, 'agreement:link:remove')
              && checkAcl(agreement.acl, 'agreement:link:remove');

            return (
              <MiniContractCard
                key={link.id}
                agreement={linkedAgreement}
                shouldRenderTitleAsLink
                canBeRemoved={canBeRemoved}
                onRemove={() => onRemove(link)}
                isRemoving={isRemovingLink === link.id}
                showParticipants
                resetRemoveState={resetRemoveState}
                confirmState={confirmState}
                clickableContractId
              />
            );
          })}
        </div>
      );
    })
  );

  return (
    <div className={style.MiniContractCardsContainer}>
      {groupedLinks.length > 0 && (
        <article className={style.LinkListHeader}>
          LINKS
        </article>
      )}
      <div className={style.Cards}>
        {renderMiniContractCards()}
      </div>
      <div className={style.LinkToButton}>
        <LinkToContract isRenderedInLinkList agreement={agreement} />
      </div>
    </div>
  );
};

export default ContractLinksList;
