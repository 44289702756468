import clsx from 'clsx';

import LockIcon from 'components/icons/lock';
import WebIcon from 'components/icons/web';
import Message from 'components/message';
import TooltipInfo from 'components/tooltip-info';

import style from './privacy-indicator.module.scss';

type DescriptionProps = {
  isPrivate: boolean;
};

export const PrivacyDescription = ({ isPrivate }: DescriptionProps) => {
  let text = (
    <Message
      id="Visible to all parties in this document"
      comment="Text that indicates the message is visible to all parties in the document."
    />
  );
  if (isPrivate) {
    text = (
      <Message
        id="Visible only for users from your organization"
        comment="Text that indicates the message is visible only to users from your organization."
      />
    );
  }
  return (
    <div className={style.Description}>
      <TooltipInfo
        message={text}
        messageClassName={style.TooltipMessage}
        align="start"
        alignOffset={-40}
      />
    </div>
  );
};

type Props = {
  isPrivate: boolean;
  isReply?: boolean;
};

const PrivacyIndicator = ({
  isPrivate,
  isReply,
}: Props) => {
  if (isReply) {
    return null;
  }

  const badgeClassNames = clsx(style.Badge, {
    [style.Internal]: isPrivate,
    [style.Public]: !isPrivate,
  });

  return (
    <div className={badgeClassNames}>
      <span className={style.Icon}>{isPrivate ? <LockIcon /> : <WebIcon />}</span>
      {isPrivate ? (
        <Message
          id="Internal"
          comment="Text shows that this message is only visible to your colleagues."
        />
      ) : (
        <Message
          id="Public"
          comment="Text shows that this message is visible to everyone."
        />
      )}
    </div>
  );
};

export default PrivacyIndicator;
