import Svg from 'components/svg';

const FiFtn = Svg((
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M7.54,6.32V18.68h19.6v6.38H7.54V40.6H0V0H29.64V6.32Z" />
      <path d="M47.47,6.38H34V0H68.47V6.38H55V40.6H47.47Z" />
      <path d="M110.23,0V40.6H104L81.63,13.11V40.6H74.15V0h6.21l22.38,27.49V0Z" />
    </g>
  </g>
), {
  viewBox: '0 0 166 40.6',
  fill: '#000',
});

FiFtn.displayName = 'FiFtn';

export default FiFtn;
