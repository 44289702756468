// @flow

import React from 'react';
import { type ContextRouter } from 'react-router-dom';

import extensionSettings from 'hocs/extension-settings';

import GeneratedToken from 'components/extension/generated-token';
import { ExtensionInstallApplication } from 'components/extension/extension-install-application';

import Authorization from './dynamics-authorization';

type Props = {
  extension: Extension,
  ...ContextRouter,
};

const appName = 'Oneflow for Dynamics CRM';
const appStoreName = 'AppSource';
const appStoreUrl = 'https://integrations.oneflow.com/dynamics/redirect/appsource';

export const DynamicsCrmPage = ({ extension, location }: Props) => (
  <>
    <Authorization location={location} oauthLink={extension.oauthLink} />

    <GeneratedToken extension={extension} />

    <ExtensionInstallApplication
      extension={extension}
      appName={appName}
      appStoreName={appStoreName}
      appStoreUrl={appStoreUrl}
    />
  </>
);

export default extensionSettings(DynamicsCrmPage);
