// @flow

import * as React from 'react';
import Message from 'components/message';

type Props = {
  extension: Extension,
};

export class ExtensionOwner extends React.PureComponent<Props> {
  render() {
    const { extension } = this.props;
    const { ownerName } = extension;

    return (
      <>
        <Message
          id="By {owner}"
          values={{
            owner: <span>{ownerName}</span>,
          }}
          comment="Byline for each card on the Extension list, By entity-name"
        />
      </>
    );
  }
}
