import {
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';

// Extended from usehooks-ts
// @see https://github.com/juliencrn/usehooks-ts/tree/master/packages/usehooks-ts/src/useInterval
function useInterval(
  callback: () => void,
  // delay=Falsy will prevent scheduling new interval and stop the existing interval
  delay?: number | null,
) {
  // Schedule interval only when delay is specified and 0 is a valid value for delay.
  const hasDelay = Boolean(delay) || delay === 0;

  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    if (!hasDelay || typeof delay !== 'number') {
      // Reaching here after any dependencies' value change
      // already cleared previous interval in cleanup callback
      return undefined;
    }

    const id = setInterval(
      // Asynchronicizing will make sure both sync/async callbacks works
      async () => {
        try {
          await savedCallback.current();
        } catch {
          // Stop the interval at first failure
          clearInterval(id);
        }
      },
      delay,
    );

    return () => {
      clearInterval(id);
    };
  }, [
    hasDelay,
    delay,
  ]);
}

export default useInterval;
