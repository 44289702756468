const getPerformancePolyfill = () => {
  if (typeof performance !== 'undefined') {
    return performance;
  }

  const performancePolyfill = {};
  const startTime = Date.now();
  performancePolyfill.now = () => Date.now() - startTime;

  if (typeof window !== 'undefined' && !window.performance) {
    window.performance = performancePolyfill;
  }

  return performancePolyfill;
};

export default getPerformancePolyfill();
