// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import isEqual from 'lodash/isEqual';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { isUserLimited } from 'user';

import { LifecycleEvent } from 'components/lifecycle-event';
import NewArrowLeft from 'components/icons/new-arrow-left';
import NewArrowRight from 'components/icons/new-arrow-right';
import Add from 'components/icons/add';

import InternalReminderCreateModalButton from 'components/modals/lifecycle-events/internal-reminder/internal-reminder-create';
import { InternalReminderFilter } from 'components/lifecycle-calendar/internal-reminder-filter/internal-reminder-filter';

import { getFiltersValues } from 'hocs/search/filter/utils';
import {
  withQueryStringParamsProvider,
  withQueryStringParamsConsumer,
} from 'hocs/query-string-params';
import type { Params, UpdateQueryStringParam } from 'hocs/query-string-params/types';
import type { SubscribedFilters } from 'hocs/search/filter/types';

import { UserEventsProvider } from 'contexts/user-events';
import getMyInternalReminders from 'utils/get-my-internal-reminders';

import { addDaysToDate, getMomentWithMonthAndYear, getMonthsSinceCurrent } from 'date';
import getLifecycleFilters, { isInAllowedRange } from './lifecycle-filters';
import InternalReminderModal from './internal-reminder-modal';

import mapDelayedEvents from './map-delayed-events';
import LifecycleCalendarToolbar from './lifecycle-calendar-toolbar';

import 'styles/external/react-big-calendar.scss';
import style from './lifecycle-calendar.module.scss';

const localizer = momentLocalizer(moment);

type QueryLifecycleEventsDateParams = {
  month?: number,
  year?: number,
};

type DateRange = {
  start: Date,
  end: Date,
};

type Options = { navigate: boolean };
type UrlParams = { [string]: any };

export type Props = {
  events: Array<DelayedEvent>,
  params?: Params,
  updateParam: UpdateQueryStringParam,
  replaceParams: (urlParams?: UrlParams, options?: Options) => void,
  queryLifecycleEvents: (?QueryLifecycleEventsDateParams, filter?: string) => void,
  currentWorkspaceId?: number,
  currentUser: Position,
  hasAccessToInternalRemindersButton: boolean,
}

type State = {
  lifecycleFilters: SubscribedFilters,
  activeFilter: string,
};

const hasFilterValuesChanged = (
  prevProps: Props,
  queryParams: any,
  lifecycleFilters: SubscribedFilters,
) => {
  const {
    queryParams: previousQueryParams,
  } = getFiltersValues(prevProps.params, lifecycleFilters);

  return !isEqual(queryParams, previousQueryParams);
};

export class LifecycleCalendar extends React.Component<Props, State> {
  static defaultProps = {
    params: {},
    currentWorkspaceId: undefined,
  };

  state = {
    lifecycleFilters: {},
    activeFilter: '',
  }

  static getDerivedStateFromProps() {
    return {
      lifecycleFilters: getLifecycleFilters(),
    };
  }

  componentDidMount() {
    const { lifecycleFilters } = this.state;
    const { params } = this.props;
    const { urlParams } = getFiltersValues(
      params,
      lifecycleFilters,
    );

    this.replaceOrDispatchQuery(urlParams);
  }

  componentDidUpdate(prevProps: Props) {
    const { lifecycleFilters, activeFilter } = this.state;
    const { params, currentWorkspaceId, queryLifecycleEvents } = this.props;

    const { queryParams, urlParams } = getFiltersValues(
      params,
      lifecycleFilters,
    );

    // update events when workspace changes
    if (currentWorkspaceId !== prevProps.currentWorkspaceId) {
      queryLifecycleEvents(this.getVisibleMonthAndYear(), activeFilter);
    }

    if (this.hasInitialValuesChanged(prevProps)
      || hasFilterValuesChanged(prevProps, queryParams, lifecycleFilters)) {
      this.replaceOrDispatchQuery(urlParams, { navigate: false });
    }
  }

  onRangeChange = (dateRange: DateRange) => {
    const { updateParam } = this.props;
    const date = addDaysToDate(dateRange.start, 7);

    updateParam('calendar', {
      month: date.month() + 1,
      year: date.year(),
    });
  };

  updateActiveFilter = (filter: string) => {
    this.setState({ activeFilter: filter });
  }

  getFiltersQueryValues() {
    const { lifecycleFilters } = this.state;
    const { params } = this.props;
    const filtersValue = getFiltersValues(params, lifecycleFilters);

    return filtersValue.queryParams;
  }

  getVisibleMonthAndYear() {
    const { calendar: { month, year } } = this.getFiltersQueryValues();

    return {
      month: month - 1,
      year,
    };
  }

  getVisibleDate() {
    const { month, year } = this.getVisibleMonthAndYear();

    return getMomentWithMonthAndYear(month, year).toDate();
  }

  getNextButtonDisabledProp() {
    const { calendar: { month, year } } = this.getFiltersQueryValues();

    return !isInAllowedRange(getMonthsSinceCurrent(month, year) + 1);
  }

  getPreviousButtonDisabledProp() {
    const { calendar: { month, year } } = this.getFiltersQueryValues();

    return !isInAllowedRange(getMonthsSinceCurrent(month, year) - 1);
  }

  replaceOrDispatchQuery(urlParams: UrlParams, options?: Options) {
    const { replaceParams, params } = this.props;

    if (!isEqual(params, urlParams)) {
      replaceParams(urlParams, options);
    } else {
      this.queryLifecycleEvents();
    }
  }

  hasInitialValuesChanged(prevProps: Props) {
    return !isEqual(this.props.params, prevProps.params);
  }

  queryLifecycleEvents() {
    const { queryLifecycleEvents } = this.props;
    const { activeFilter } = this.state;

    queryLifecycleEvents(this.getVisibleMonthAndYear(), activeFilter);
  }

  renderInternalReminderButton = () => {
    const {
      currentWorkspaceId,
      queryLifecycleEvents,
      currentUser,
      hasAccessToInternalRemindersButton,
    } = this.props;

    if (!hasAccessToInternalRemindersButton) {
      return null;
    }

    const { activeFilter } = this.state;
    const isDisabled = isUserLimited(currentUser);

    return (
      <InternalReminderCreateModalButton
        disabled={isDisabled}
        kind="secondary"
        icon={<Add height="14px" />}
        currentWorkspaceId={currentWorkspaceId}
        refetchInternalReminders={
          () => queryLifecycleEvents(this.getVisibleMonthAndYear(), activeFilter)
        }
      >
        <Message
          id="New Reminder"
          comment="Confirm modal title for sending a contract reminder."
        />
      </InternalReminderCreateModalButton>
    );
  };

  renderInternalReminderFilter = () => {
    const { currentWorkspaceId, queryLifecycleEvents } = this.props;

    return (
      <InternalReminderFilter
        currentWorkspaceId={currentWorkspaceId}
        getVisibleMonthAndYear={this.getVisibleMonthAndYear()}
        queryLifecycleEvents={queryLifecycleEvents}
        title={(
          <Message
            id="Quick filters"
            comment="Title for filter section for custom reminders"
          />
        )}
        updateActiveFilter={this.updateActiveFilter}
      />
    );
  };

  renderLifecycleSidebar() {
    return (
      <div className={style.LifecycleSidebarContainer}>
        <div className={style.LifecycleSidebar}>
          {this.renderInternalReminderButton()}
          {this.renderInternalReminderFilter()}
        </div>
      </div>
    );
  }

  renderCalendarEvents() {
    const { events, currentUser } = this.props;

    const myCustomUserEvents = getMyInternalReminders(events, currentUser);

    const automaticEvents = mapDelayedEvents(events)
      .filter((event) => event.sourceEvent?.type !== 'agreement_custom_reminder');

    const allMyEvents = [...automaticEvents, ...myCustomUserEvents];

    return allMyEvents;
  }

  getToolbarComponent = (props) => (
    <LifecycleCalendarToolbar
      {...props}
      navigationPreviousDisabled={this.getPreviousButtonDisabledProp()}
      navigationNextDisabled={this.getNextButtonDisabledProp()}
    />
  );

  render() {
    const {
      currentWorkspaceId,
      queryLifecycleEvents,
    } = this.props;

    const { activeFilter } = this.state;

    return (
      <UserEventsProvider
        currentWorkspaceId={currentWorkspaceId}
        modal={InternalReminderModal}
        refetchInternalReminders={
          () => queryLifecycleEvents(this.getVisibleMonthAndYear(), activeFilter)
        }
      >
        <div className={style.Lifecycle}>
          {this.renderLifecycleSidebar()}
          <div className={style.LifecycleCalendar}>
            <Calendar
              drilldownView={null}
              popup
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              views={['month']}
              events={this.renderCalendarEvents()}
              components={{
                event: LifecycleEvent,
                toolbar: this.getToolbarComponent,
              }}
              date={this.getVisibleDate()}
              onNavigate={() => { }}
              onRangeChange={this.onRangeChange}
              messages={{
                showMore: (count) => (
                  <div role="presentation" onClick={(e) => e.preventDefault()}>
                    <Message
                      id="+{count} more"
                      values={{ count }}
                      comment="Label to identify how many more events there are in the date entry"
                    />
                  </div>
                ),
                next: <NewArrowRight height="11px" />,
                previous: <NewArrowLeft height="11px" />,
              }}
            />
          </div>
        </div>
      </UserEventsProvider>
    );
  }
}

export default withQueryStringParamsProvider(withQueryStringParamsConsumer(LifecycleCalendar));
