import * as React from 'react';
import SignaturePad from 'signature_pad';

import Button from 'components/button';
import { Message } from '@oneflowab/pomes';
import getCSSPropertyValue from 'utils/get-css-property-value';
import { getPreferredCavasSizeForWidth } from 'utils/get-preferred-cavas-size';

import HandwrittenContext from './handwritten-context';
import style from './handwritten.module.scss';

export type Props = {
  maxCanvasWidth: number,
  placeholderText: string
};

const DrawSignature = (props: Props) => {
  const { maxCanvasWidth, placeholderText } = props;
  const canvasSize = React.useMemo(
    () => getPreferredCavasSizeForWidth(maxCanvasWidth),
    [maxCanvasWidth],
  );
  const canvasWidth = canvasSize.get('width');
  const canvasHeight = canvasSize.get('height');

  const [signature, setSignature] = React.useState<any>();
  const placeholderTextSize = canvasWidth / 20; // decided with ux
  const canvasRef = React.useRef(null);
  // Refer: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const canvasTrackingRef = React.useCallback((canvasNode) => {
    if (!canvasNode) {
      return;
    }
    canvasRef.current = {
      signaturePad: new SignaturePad(canvasNode),
      canvasNode,
    };
  }, []);
  const { setButtonDisabled } = React.useContext(HandwrittenContext);

  const fillPlaceholder = React.useCallback(() => {
    const ctx = canvasRef.current?.canvasNode?.getContext('2d');
    ctx.fillStyle = getCSSPropertyValue('--of-forest-green-03');
    ctx.font = `normal 400 ${placeholderTextSize}px Proxima Nova, Work Sans`;
    ctx.textAlign = 'center';
    ctx.fillText(placeholderText, canvasWidth / 2, canvasHeight / 2);
  }, [canvasWidth, canvasHeight, placeholderText, placeholderTextSize]);

  const addClearCanvasListener = React.useCallback(() => {
    canvasRef.current?.signaturePad.addEventListener(
      'afterUpdateStroke',
      () => {
        canvasRef.current?.signaturePad.clear();
      },
      { once: true },
    );
  }, []);

  const updateSignature = React.useCallback(() => {
    setSignature(canvasRef.current?.signaturePad.toDataURL());
    setButtonDisabled(false);
  }, [setButtonDisabled]);

  const resetCanvas = React.useCallback(() => {
    canvasRef.current?.signaturePad.clear();
    setSignature();
    addClearCanvasListener();
    fillPlaceholder();
    setButtonDisabled(true);
  }, [addClearCanvasListener, fillPlaceholder, setButtonDisabled]);

  React.useLayoutEffect(() => {
    setButtonDisabled(true);
    fillPlaceholder();
    addClearCanvasListener();
    const signaturePadRef = canvasRef.current;
    signaturePadRef?.signaturePad.addEventListener('endStroke', updateSignature);
    return () => {
      signaturePadRef?.signaturePad.removeEventListener('endStroke', updateSignature);
    };
  }, [addClearCanvasListener, fillPlaceholder, setButtonDisabled, updateSignature]);

  return (
    <>
      <canvas
        className={style.Canvas}
        width={canvasSize.get('width')}
        height={canvasSize.get('height')}
        ref={canvasTrackingRef}
      />
      <input name="signature" type="hidden" defaultValue={signature} key={signature} />
      <div className={style.ClearButtonContainer}>
        <Button
          kind="link"
          underlineLink
          data-testid="confirm"
          onClick={resetCanvas}
        >
          <Message id="Clear" comment="Clear button to clean draw signature area" />
        </Button>
      </div>
    </>
  );
};

export default DrawSignature;
