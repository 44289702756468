// @flow

import * as React from 'react';
import DatePreset from './date-preset';

const TodayPreset = (props: {}) => (
  <DatePreset {...props} last={1} type="day" />
);

export default TodayPreset;
