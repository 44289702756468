import React from 'react';
import { get } from 'lodash';

import type {
  Column,
  Config,
  ConfigUpdateData,
} from 'data-validators/entity-schemas/document-box/product-table';

import {
  NameAndDescriptionPopupForm,
  PricePopupForm,
  QuantityPopupForm,
} from 'components/contract-boxes/product-table-box/product-table/popup-forms';
import Popup from 'components/popup';
import type { UpdatedProduct } from 'components/contract-boxes/product-table-box/product-table/popup-forms/update-product-helpers';

import style from './product-table-popup.module.scss';

type Props = {
  asChild?: boolean,
  children: React.ReactNode,
  column: Column,
  config: Config,
  disabled?: boolean,
  isResponsiveView: boolean;
  restProps?: React.HTMLAttributes<HTMLDivElement>,
  updateProductConfig: (configData: ConfigUpdateData) => void,
  updateProductData: (productId: number, updatedProduct: UpdatedProduct) => void,
}

type FormProps = {
  column: Column;
  config?: Config;
  label: string;
  popupType: 'popover' | 'dialog';
  updateProductConfig: (configData: ConfigUpdateData) => void;
  updateProductData: (productId: number, updatedProduct: UpdatedProduct) => void;
}

const ProductTablePopup = ({
  asChild,
  children,
  column,
  config,
  disabled,
  isResponsiveView,
  updateProductConfig,
  updateProductData,
  ...restProps
}: Props) => {
  const popupType = !isResponsiveView ? 'popover' : 'dialog';

  const formProps: FormProps = {
    column,
    config,
    label: column.label,
    updateProductConfig,
    popupType,
    updateProductData,
  };

  const popupForms = {
    name: NameAndDescriptionPopupForm,
    description: NameAndDescriptionPopupForm,
    price_1: PricePopupForm,
    price_2: PricePopupForm,
    count: QuantityPopupForm,
  };

  const PopupForm = get(popupForms, column.key);

  const isDisabled = disabled || !PopupForm;

  return (
    <Popup
      isPortal
      popupType={popupType}
      triggerClassName={style.TriggerButton}
      className={style.ProductTablePopup}
      trigger={children}
      asChild={asChild}
      /* @ts-expect-error types/pomes type is not loaded as does in PopupForm */
      content={PopupForm && <PopupForm {...formProps} />}
      disabled={isDisabled}
      {...restProps}
    />
  );
};

export default ProductTablePopup;
