// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import { getAllWorkspaces } from 'reducers/entities/workspaces';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
  type: string,
};

const AgreementTemplateMoveEvent = ({ auditLogEvent, type }: Props) => {
  const {
    information: { workspace: { from, to } = {} } = {},
    target: { id } = {},
  } = auditLogEvent;
  const allWorkspaces = useSelector((state) => getAllWorkspaces(state));
  const fromWorkspace = allWorkspaces.find((workspace) => workspace.id === from)?.name;
  const toWorkspace = allWorkspaces.find((workspace) => workspace.id === to)?.name;

  const StyledId = <span className={style.Italic}>{id}</span>;
  const StyledFromWorkspace = (<span className={style.Italic}>{fromWorkspace}</span>);
  const StyledToWorkspace = (<span className={style.Italic}>{toWorkspace}</span>);

  return (
    <p data-testid="move-event-text">
      {type === 'agreement'
        ? (
          <Message
            id="{jsx-start}Moved{jsx-end} the document with the ID {id} from the {fromWorkspace} workspace to the {toWorkspace} workspace."
            comment="Audit log event message for moved group"
            component="span"
            className={style.Green}
            values={{
              id: StyledId,
              fromWorkspace: StyledFromWorkspace,
              toWorkspace: StyledToWorkspace,
            }}
          />
        )
        : (
          <Message
            id="{jsx-start}Moved{jsx-end} the template with the ID {id} from the {fromWorkspace} workspace to the {toWorkspace} workspace."
            comment="Audit log event message for moved group"
            component="span"
            className={style.Green}
            values={{
              id: StyledId,
              fromWorkspace: StyledFromWorkspace,
              toWorkspace: StyledToWorkspace,
            }}
          />
        )}
    </p>
  );
};

export default AgreementTemplateMoveEvent;
