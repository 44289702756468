import { PendingHintCodes } from './types';

export const POLLING_INTERVAL = 3000;
export const DEBOUNCE_POLLING_TIMEOUT = 20 * 1000;

export const SWEDISH_BANKID_SIGNING_DEVICE = {
  SAME: 'same',
  OTHER: 'other',
};

export const pendingHintCodesForManualFlow: PendingHintCodes[] = [
  'NO_CLIENT',
  'OUTSTANDING_TRANSACTION',
];

export const failedHintCodes: PendingHintCodes[] = [
  'EXPIRED_TRANSACTION',
  'CERTIFICATE_ERR',
  'USER_CANCEL',
  'CANCELLED',
];
