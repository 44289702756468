// @flow

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { checkAcl } from 'components/acl';
import {
  isSignedAndEndedAndTerminated,
  isDraft,
  isPending,
  isOverdue,
  isTemplate,
  hasSignOrder,
} from 'agreement';
import {
  isSigned,
  isConcluded,
} from 'agreement/states';
import {
  isAgreementOwner,
  getAgreementMyParticipant,
  getAgreementSignatories,
  getAgreementMyParty,
  getMyParticipantWhenUpdater,
  usesSameDeviceAll,
  getMyParticipantWhenSignatory,
  canBeSigned,
  isMyParticipantTurnToSign,
} from 'agreement/selectors';
import { isViewer } from 'agreement/participant';
import hasDeliveryChannelSameDevice from 'agreement/participant/has-delivery-channel-same-device';
import { isIndividual } from 'agreement/party';
import isUndecided from 'agreement/participant/is-undecided';

import { BOX_FORM } from './constants';

const contractOnlyTokens = [
  'contract_only',
  'contractonly',
];

export const isContractOnly = (location: Location) => (
  contractOnlyTokens
    .includes(String(location.query.view_mode).toLowerCase())
);

export const canBeSignedByMyParticipant = (
  agreement: Agreement,
  myParticipant?: AgreementParticipant,
) => {
  const agreementCanBeSigned = agreement.parties ? canBeSigned(agreement) : undefined;
  const isSignLaterEnabled = agreement.config?.signLater;
  return agreementCanBeSigned
    && myParticipant
    && isMyParticipantTurnToSign(agreement, myParticipant)
    && isUndecided(myParticipant)
    && isPending(agreement)
    && (!isSignLaterEnabled);
};

export const isSignContractVisible = (
  agreement: Agreement,
) => {
  const myParticipantWhenSignatory = getMyParticipantWhenSignatory(agreement);
  return isPending(agreement) && canBeSignedByMyParticipant(
    agreement,
    myParticipantWhenSignatory,
  );
};

export const allowMarkAsSigned = (
  agreement: Agreement,
  account: Account,
) => {
  const signatories = getAgreementSignatories(agreement);

  return isAgreementOwner(account, agreement)
    && (isDraft(agreement) || isPending(agreement) || isOverdue(agreement))
    && signatories.length > 0 && Boolean(getMyParticipantWhenUpdater(agreement));
};

export const allowContractTerminate = (agreement: Agreement, account: Account) => (
  isSigned(agreement)
  && !isSignedAndEndedAndTerminated(agreement)
  && isAgreementOwner(account, agreement)
  && Boolean(getMyParticipantWhenUpdater(agreement))
);

export const allowMessage = (
  agreement: Agreement,
  account: Account,
) => {
  const myParticipant = getAgreementMyParticipant(agreement);
  const myParticipantIsViewer = myParticipant && isViewer(myParticipant);

  return !isConcluded(agreement)
    && !myParticipantIsViewer
    && isAgreementOwner(account, agreement)
    && !agreement.private
    && !usesSameDeviceAll(agreement)
    && !isEmpty(myParticipant);
};

export const boxIsManagedLocked = (box: {}) => (
  Boolean(get(box, 'config.managerLock'))
);

export const allowAddParticipants = (
  account: Account,
  agreement: Agreement,
  workspace: Workspace,
) => {
  // don't think this acl check is needed or relevant, but copying logic from
  // legacy/src/app/entities/agreement/agreement.js (line 1587)
  // since this will always be overriden by the check for template
  // legacy/src/app/modules/agreements/agreement/sidebar/views/party_actions.js (line 69)
  // that is now done on the renderAddCounterpartyButton in
  // app/components/participant-list/participant-list.jsx
  const allowedInWorkspace = checkAcl(workspace.acl, 'collection:agreement:create');
  if (isTemplate(agreement) && allowedInWorkspace) {
    return true;
  }

  if (
    isDraft(agreement)
    || ((isPending(agreement)
      || isOverdue(agreement))
      && ((hasSignOrder(agreement) && isAgreementOwner(account, agreement))
        || !hasSignOrder(agreement)))
  ) {
    return true;
  }

  return false;
};

export const allowAddParties = (
  account: Account,
  agreement: Agreement,
) => {
  if (
    isDraft(agreement)
    || ((isPending(agreement) || isOverdue(agreement))
      && ((hasSignOrder(agreement) && isAgreementOwner(account, agreement))
        || !hasSignOrder(agreement)))
  ) {
    const myParty = getAgreementMyParty(agreement);
    const myParticipant = getAgreementMyParticipant(agreement);
    const myParticipantIsSameDevice = myParticipant && hasDeliveryChannelSameDevice(myParticipant);
    const myParticipantIsViewer = myParticipant && isViewer(myParticipant);
    const myPartyIsIndividual = myParty && isIndividual(myParty);
    if (
      !myParticipant
      || myParticipantIsViewer
      || myPartyIsIndividual
      || myParticipantIsSameDevice
    ) {
      return false;
    }
    return checkAcl(agreement.acl, 'agreement:participant:other_party:create');
  }

  return false;
};

// TODO: uncomment when file is converted to TypeScript
// type CanCounterpartEditBoxDataItemFieldArgs = {
//   agreement: Oneflow.Agreement,
//   box?: ContractView.Box | null,
//   isReadOnly?: boolean | undefined,
// }

export const canCounterpartEditBoxDataItemField = ({
  agreement,
  box,
  isReadOnly,
  isAllowedToUpdateDataValue,
  counterpartEdit,
  // TOOD: uncomment when file is converted to TypeScript
}/* : CanCounterpartEditBoxDataItemFieldArgs */) => {
  if (isReadOnly) {
    return false;
  }

  if (box?.type === BOX_FORM && !isAllowedToUpdateDataValue) {
    return false;
  }

  // TODO: To be deleted when ACLs updated in the backend
  // Temporary client code starts
  const currentUserMayBeAsParticipant = getAgreementMyParticipant(agreement);

  if (!currentUserMayBeAsParticipant || isViewer(currentUserMayBeAsParticipant)) {
    return false;
  }
  // Temporary client code ends

  if (counterpartEdit === 'allow') {
    return true;
  }

  return Boolean(box?.config?.counterpartEdit); /* && checkAcl(
        acl, aclKeys
      ); */
};
