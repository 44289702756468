// @flow

import { put, select } from 'redux-saga/effects';
import type { SelectEffect, PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getPositionMessageTemplates,
  createPositionMessageTemplate,
  updatePositionMessageTemplate,
  deletePositionMessageTemplates,
} from 'oneflow-client/message-templates';
import type { NormalizedMessageTemplates } from 'oneflow-client/message-templates';

import apiWrapper from 'sagas/api-wrapper';

import positionMessageTemplates from 'reducers/entities/position-message-templates';
import { getPositionFromSessionSelector } from 'reducers/session';

type ExtraArguments = {
  positionId: number,
}

type ExtraQueryParams = {
  params: ExtraArguments,
}

type SetPositionId = Generator<SelectEffect<any, any>, ExtraQueryParams, any>;

export function* setCurrentPositionId(): SetPositionId {
  const position = yield select(getPositionFromSessionSelector);

  return {
    params: {
      positionId: position.id,
    },
  };
}

type MapperArgs = {
  data: NormalizedMessageTemplates,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(positionMessageTemplates.setPositionMessageTemplates(data.entities.messageTemplates));
}

export function* removeMapper({ action }: MapperArgs): Mapper {
  yield put(positionMessageTemplates.clearPositionMessageTemplate({
    ids: action.data.ids,
  }));
}

const mappers = {
  query: {
    prepare: setCurrentPositionId,
    mapper,
    request: getPositionMessageTemplates,
  },
  create: {
    mapper,
    request: createPositionMessageTemplate,
  },
  update: {
    mapper,
    request: updatePositionMessageTemplate,
  },
  remove: {
    mapper: removeMapper,
    request: deletePositionMessageTemplates,
  },
};

const positionMessageTemplatesSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: positionMessageTemplates,
  mappers,
});

export default positionMessageTemplatesSagas;
