import { debounce } from 'lodash';
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';

import webSocket from 'web-socket';

import useInterval from 'hooks/use-interval';
import { getParticipantInfo } from 'oneflow-client/agreements';
import { STATE_HAS_SIGNED } from 'agreement/participant/constants';
import {
  DEBOUNCE_POLLING_TIMEOUT,
  POLLING_INTERVAL,
  failedHintCodes,
  pendingHintCodesForManualFlow,
} from '../constants';

import {
  WebsocketEventResponse,
  WebsocketEventData,
  PendingHintCodes,
} from '../types';

export type SignState = {
  error?: WebsocketEventResponse,
  loading: boolean,
  success: boolean,
  started: boolean,
  response: WebsocketEventResponse,
}

type State = {
  signState: SignState,
  hasParticipantSigned: boolean,
}

type Props = {
  guestToken: string,
  agreementId: number,
  participantId: number,
  onError: (error: string) => void,
  onSuccess: () => void,
}

const useBankID = ({
  guestToken,
  agreementId,
  participantId,
  onError,
  onSuccess,
}: Props) => {
  const [state, setState] = useState<State>(() => ({
    signState: {
      error: undefined,
      loading: false,
      success: false,
      started: false,
      response: '',
    },
    hasParticipantSigned: false,
  }));

  const [polling, setPolling] = useState<{ manual: boolean, interval: number | null }>(() => ({
    manual: false,
    interval: null,
  }));

  const startPolling = useCallback(
    () => setPolling(
      () => ({ manual: true, interval: POLLING_INTERVAL }),
    ),
    [],
  );
  const stopPolling = useCallback(
    () => setPolling(
      () => ({ manual: false, interval: null }),
    ),
    [],
  );
  const debouncePolling = useMemo(
    () => debounce(startPolling, DEBOUNCE_POLLING_TIMEOUT),
    [startPolling],
  );

  const unsubscribeToBankIDPending = useRef<() => void>();
  const unsubscribeToSignState = useRef<() => void>();
  const unsubscribeToBankIDError = useRef<() => void>();

  useInterval(
    async () => {
      const participantInfo = await getParticipantInfo({
        guestToken,
        agreementId,
        participantId: String(participantId),
      });
      setState((previousState) => ({
        ...previousState,
        hasParticipantSigned: participantInfo.state === STATE_HAS_SIGNED,
      }));
    },
    polling.interval,
  );

  // Clearing subscriptions
  const clearBankIDPendingSubscription = (id?: number) => {
    if (unsubscribeToBankIDPending.current && typeof unsubscribeToBankIDPending.current === 'function' && id === participantId) {
      unsubscribeToBankIDPending.current();
    }
  };

  const clearBankIDErrorSubscription = (id?: number) => {
    if (unsubscribeToBankIDError.current && typeof unsubscribeToBankIDError.current === 'function' && id === participantId) {
      unsubscribeToBankIDError.current();
    }
  };

  const clearSignStateSubscription = () => {
    if (unsubscribeToSignState.current && typeof unsubscribeToSignState.current === 'function') {
      unsubscribeToSignState.current();
    }
  };

  const clearSubscriptions = useCallback((id?: number) => {
    clearBankIDPendingSubscription(id);
    clearBankIDErrorSubscription(id);
    clearSignStateSubscription();
  }, []);

  const onSignBankIDPending = useCallback((responseData: WebsocketEventData) => {
    setState((previousState) => ({
      ...previousState,
      signState: {
        ...previousState.signState,
        error: undefined,
        loading: true,
        success: false,
        response: responseData.response,
      },
    }));
    clearBankIDPendingSubscription(responseData?.participant?.id);
  }, []);

  const onSignBankIDError = useCallback((responseData: WebsocketEventData) => {
    clearSubscriptions();

    onError(responseData.response as string);
    setState((previousState) => ({
      ...previousState,
      signState: {
        ...previousState.signState,
        error: responseData.response,
        loading: false,
        success: false,
        response: '',
      },
    }));
  }, [clearSubscriptions, onError]);

  const handleSuccess = useCallback((
    responseData: { participant: { id: number | undefined } },
  ) => {
    const isSignedByParticipant = responseData?.participant?.id === participantId;
    clearSubscriptions(responseData?.participant?.id);

    setState((previousState) => ({
      ...previousState,
      signState: {
        ...previousState.signState,
        error: undefined,
        loading: false,
        success: isSignedByParticipant,
        response: '',
      },
    }));
    if (isSignedByParticipant) {
      onSuccess();
    }
  }, [clearSubscriptions]);

  // WebSocket subscriptions:
  const subscribeToBankIDPending = useCallback(() => {
    try {
      // if called multiple times, it will unsubscribe the previous subscription
      unsubscribeToBankIDPending.current = webSocket.subscribe({
        channelName: `private-agreement-participant-${participantId}`,
        event: 'sign:bankid:pending',
        eventCallback: onSignBankIDPending,
      });
    } catch {
      // When subscribing to channel failed start manual polling
      startPolling();
    }
  }, [onSignBankIDPending, startPolling, participantId]);

  const subscribeToBankIDError = useCallback(() => {
    clearBankIDErrorSubscription();
    unsubscribeToBankIDError.current = webSocket.subscribe({
      channelName: `private-agreement-participant-${participantId}`,
      event: 'sign:bankid:error',
      eventCallback: onSignBankIDError,
    });
  }, [onSignBankIDError, participantId]);

  const subscribeToSignState = useCallback(() => {
    clearSignStateSubscription();

    unsubscribeToSignState.current = webSocket.subscribe({
      channelName: `private-agreement-${agreementId}`,
      event: 'agreement:participant:sign',
      eventCallback: handleSuccess,
    });
  }, [agreementId, handleSuccess]);

  useEffect(() => {
    if (webSocket.isConnected) {
      subscribeToBankIDPending();
      subscribeToBankIDError();
      subscribeToSignState();

      // When websocket disconnected start manual polling
      webSocket.onDisconnect(startPolling);

      // When websocket connected stop manual polling
      webSocket.onConnect(stopPolling);
    } else if (!polling.manual) {
      startPolling();
    }

    return () => {
      clearSubscriptions();
    };
  }, []);

  useEffect(() => {
    if (state.hasParticipantSigned) {
      clearSubscriptions(participantId);
      setState((previousState) => ({
        ...previousState,
        signState: {
          ...previousState.signState,
          error: undefined,
          loading: false,
          success: true,
          response: '',
        },
      }));
      onSuccess();
      return;
    }

    if (failedHintCodes.includes(state.signState.response as PendingHintCodes)) {
      stopPolling();
      return;
    }

    if (pendingHintCodesForManualFlow.includes(state.signState.response as PendingHintCodes)) {
      startPolling();
      return;
    }

    // Schedule polling after DEBOUNCE_POLLING_TIMEOUT (20) seconds of
    // Inactive websocket communication
    debouncePolling();
  }, [
    state.signState.response,
    polling.manual,
    state.hasParticipantSigned,
    startPolling,
    stopPolling,
    handleSuccess,
    debouncePolling,
  ]);

  return {
    signState: state.signState,
    hasParticipantSigned: state.hasParticipantSigned,
  };
};

export default useBankID;
