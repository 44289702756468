// @flow

import React, { useEffect, useState } from 'react';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import agreementTemplatesReducer from 'reducers/entities/agreement-templates';
import { getLocationSelector } from 'reducers/router';
import { getCurrentWorkspaceIdSelector } from 'reducers/app';
import usePrevious from 'hooks/use-previous';
import {
  getRedirectPath,
} from 'workspace/folders';

import Button from 'components/button';
import { DeleteButton, CancelButton, CloseButton } from 'components/buttons';
import Badge from 'components/badge';
import Alert from 'components/icons/alert';
import FolderWarning from 'components/icons/folder-warning';
import { RemoveConfirm } from 'components/modals/remove-confirm';

import style from './delete-folder.module.scss';

export type Props = {|
children: Function,
deleteFolder: () => void,
name: string,
removeState: RemoveState,
resetRemoveState: () => void,
folderId: number,
|};

const modalKey = 'delete folder modal';

export const DeleteFolder = ({
  children,
  deleteFolder,
  name,
  removeState,
  resetRemoveState,
  folderId,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useSelector((state) => getLocationSelector(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceIdSelector(state));

  const prevModalOpen = usePrevious(modalOpen);

  useEffect(() => {
    if (modalOpen && !prevModalOpen) {
      dispatch(agreementTemplatesReducer.queryAgreementTemplates({
        name: 'QUERY_FOLDER_TEMPLATES',
        params: {
          folderId,
          workspaceId,
        },
      }));
    }
  }, [modalOpen, dispatch, prevModalOpen, folderId, workspaceId]);

  const templatesQuery = useSelector((state) => (
    agreementTemplatesReducer.getQuerySelector(state, { name: 'QUERY_FOLDER_TEMPLATES' })
  ));

  const templates = useSelector((state) => (
    agreementTemplatesReducer.getAgreementTemplatesSelector(state, { ids: templatesQuery.result })
  ));

  const onDelete = () => {
    deleteFolder();
    setModalOpen(false);
  };

  const onClickHandler = () => {
    dispatch(push(getRedirectPath(location, [folderId])));
    setModalOpen(false);
  };

  const hasTemplates = templates.length > 0;

  const getConfirmMessage = () => {
    const getFolderNameButton = () => <Button customClass={style.FolderButton} kind="linkInline" onClick={onClickHandler}>{name}</Button>;

    if (hasTemplates) {
      return (
        <div className={style.TemplateModalWrapper}>
          <Message
            id="To delete the folder, make sure it's not used as the destination folder in templates and doesn't contain folders or documents."
            comment="Modal text when deleting a folder."
          />
          <p>
            <Message
              id="The following templates use the {folderName} folder for saving documents. "
              values={{
                folderName: getFolderNameButton(),
              }}
              comment="Modal text when deleting a folder."
            />
          </p>

          <ul className={style.TemplateList}>
            {templates.map((template) => (
              <li key={template.agreement}>
                <Message
                  id="Template ID"
                  comment="Text for template ID"
                />
                <span className={style.TemplateId}>{template.agreement}</span>
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return (
      <>
        <Message
          id="The {folderName} folder will be deleted."
          values={{
            folderName: <Badge className={style.FolderName} label={name} kind="name" />,
          }}
          comment="Modal text when deleting a folder."
        />
        <p className={style.folderInfoText}>
          <Message
            id="This action cannot be undone."
            comment="Modal text when deleting a folder."
          />
        </p>
        <Message
          id="Delete the folder?"
          comment="Modal text when deleting a folder."
        />
      </>
    );
  };

  const onResetRemoveState = (action) => {
    if (!modalOpen) {
      setModalOpen(true);
    }
    action();
  };

  const getActions = ({ closeConfirmation }: { closeConfirmation: Function }) => {
    if (!hasTemplates) {
      return (
        <div className={style.Actions}>
          <div className={style.CancelButton}>
            <CancelButton
              onClick={() => {
                closeConfirmation();
                setModalOpen(false);
              }}
              modalKey={modalKey}
            />
          </div>
          <DeleteButton isLoading={removeState.loading} onClick={onDelete} />
        </div>
      );
    }

    return (
      <CloseButton
        onClick={() => {
          closeConfirmation();
          setModalOpen(false);
        }}
        modalKey={modalKey}
      />
    );
  };

  const iconClasses = clsx(style.Icon, {
    [style.TemplateIcon]: hasTemplates,
  });

  const getIcon = () => {
    if (hasTemplates) {
      return <FolderWarning className={iconClasses} height="69px" />;
    }

    return <Alert className={style.RedWarningIcon} height="69px" />;
  };

  return (
    <RemoveConfirm
      confirmMessage={(
        <div className={style.ConfirmMessage}>
          {getConfirmMessage()}
        </div>
      )}
      confirmState={removeState}
      header={(
        <Message id="Delete folder" comment="Modal title when deleting folder." />
      )}
      icon={getIcon()}
      isOpen={modalOpen}
      modalKey="delete folder modal"
      onClose={() => setModalOpen(false)}
      onConfirm={onDelete}
      renderButton={getActions}
      resetConfirmState={() => onResetRemoveState(resetRemoveState)}
    >
      {children}
    </RemoveConfirm>
  );
};
