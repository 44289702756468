import { ReactNode } from 'react';
import {
  get,
  noop,
} from 'lodash';
import { Message } from '@oneflowab/pomes';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import clsx from 'clsx';

import {
  BOX_FORM,
  BOX_PRODUCT_SUMMATION,
  BOX_PRODUCT_TABLE,
  BOX_TEXT_AND_IMAGE,
  BOX_VIDEO,
} from 'agreement/constants';
import {
  duplicateBox,
  updateBoxConfigAction,
} from 'reducers/current-contract';
import { getAccountFromSessionSelector } from 'reducers/session';
import { isAgreementOwner } from 'agreement/selectors';
import { isBoxConfigLockUpdateAllowed } from 'agreement/box-config-lock-update-permissions';
import { isBoxCreateAllowed } from 'agreement/add-box-permissions';
import { isBoxRemoveAllowed } from 'agreement/box-remove-permissions';
import {
  isConcluded,
  isDecliningInProgress,
  isSigningInProgress,
} from 'agreement/states';
import joinListWithSeparator from 'utils/join-list-with-separator';
import useAgreement from 'hooks/use-agreement';
import useCurrentContractId from 'hooks/use-current-contract-id';
import useMatchMedia, { DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY } from 'hooks/use-match-media';
import type { Box } from 'data-validators/entity-schemas/document-box';

import { DeleteMenuItem } from 'components/menu-items/delete';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import { MenuItem } from 'components/menu-item';
import { shouldRenderSectionRules } from 'components/contract-boxes/box-wrapper/helpers';
import ActionsMenu from 'components/actions-menu-v2/actions-menu';
import AdjusterIcon from 'components/icons/adjuster';
import CogIcon from 'components/icons/cog';
import CopyIcon from 'components/icons/copy';
import LockIcon from 'components/icons/lock';
import Tooltip from 'components/tooltip';
import UnlockIcon from 'components/icons/unlock';

import style from './box-actions-menu.module.scss';

const getManagerLockMessage = (isLocked: number | undefined) => {
  if (isLocked) {
    return (
      <Message
        id="Unlock section"
        comment="Action to unlock sections."
      />
    );
  }

  return (
    <Message
      id="Lock section"
      comment="Action to lock sections."
    />
  );
};

export type Props = {
  box: Box,
  isEditable: boolean,
  isViewer: boolean,
  nonFixedActions?: ReactNode[],
  onAddSectionRules?: () => void,
  onRemoveBox: () => void,
  setVisibility?: (visibility: boolean) => void,
  triggerToggleLock?: () => void,
}

const BoxActionsMenu = ({
  box,
  isEditable,
  isViewer,
  nonFixedActions,
  onAddSectionRules,
  onRemoveBox,
  setVisibility,
  triggerToggleLock = noop,
}: Props) => {
  const accountFromSession = useSelector(getAccountFromSessionSelector);
  const contractId = useCurrentContractId() as number;
  const agreement = useAgreement(contractId);
  const dispatch = useDispatch();

  const isAllowedToAddBox = isBoxCreateAllowed(agreement);
  const isAllowedToRemoveBox = isBoxRemoveAllowed(box);
  const isAllowedToUpdateBoxConfigLock = isBoxConfigLockUpdateAllowed(box);
  const isCollapsedLayout = useMatchMedia(DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY);

  const managerLock = get(box, 'config.managerLock');
  const showLock = Boolean(managerLock) && isAgreementOwner(accountFromSession, agreement);

  const toggleLock = () => {
    dispatch(updateBoxConfigAction(getId(box), { managerLock: managerLock ? 0 : 1 }));

    triggerToggleLock();
  };

  const getToggleLockMenuItem = () => {
    if (!isAllowedToUpdateBoxConfigLock) {
      return null;
    }

    return (
      <Tooltip
        message={(
          <div className={style.TooltipMessage}>
            <Message
              id="Sections can be locked or unlocked by users with the appropriate workspace permissions. Input fields may remain editable based on section settings."
              comment="This messages show the behaviour of locked or unlocked menu item."
            />
          </div>
        )}
        side="left"
        zIndex={10003}
        sideOffset={8}
      >
        <span>
          <MenuItem
            icon={managerLock ? <UnlockIcon height={14} /> : <LockIcon height={16} />}
            label={getManagerLockMessage(managerLock)}
            onClick={toggleLock}
            className={style.MenuItem}
          />
        </span>
      </Tooltip>
    );
  };

  const getSectionRulesMenuItem = () => {
    if (!shouldRenderSectionRules(box, agreement) || !onAddSectionRules) {
      return null;
    }

    return (
      <MenuItem
        icon={<AdjusterIcon height={14} />}
        label={(
          <Message
            id="Section rules"
            comment="Action to add section rules."
          />
        )}
        onClick={onAddSectionRules}
        className={style.MenuItem}
      />
    );
  };

  const getDeleteMenuItem = () => {
    if (!isAllowedToRemoveBox) {
      return null;
    }

    return (
      <DeleteMenuItem
        onClick={onRemoveBox}
      />
    );
  };

  const onDuplicateSection = () => {
    dispatch(duplicateBox(getId(box)));
  };

  const getDuplicateMenuItem = () => {
    const boxTypesWithDuplicateEnabled = [
      BOX_TEXT_AND_IMAGE,
      BOX_PRODUCT_SUMMATION,
      BOX_FORM,
      BOX_PRODUCT_TABLE,
      BOX_VIDEO,
    ];

    if (!isAllowedToAddBox || !boxTypesWithDuplicateEnabled.includes(box?.type)) {
      return null;
    }

    return (
      <MenuItem
        icon={<CopyIcon height={14} />}
        label={(
          <Message
            id="Duplicate section"
            comment="Action to duplicate sections."
          />
        )}
        onClick={onDuplicateSection}
        className={style.MenuItem}
      />
    );
  };

  const fixedActions = [
    getToggleLockMenuItem(),
    getSectionRulesMenuItem(),
    getDuplicateMenuItem(),
    getDeleteMenuItem(),
  ];

  const actions = joinListWithSeparator([
    nonFixedActions || [],
    fixedActions,
  ], 'separator').flat();

  if (
    isViewer
    || isConcluded(agreement)
    || isSigningInProgress(agreement)
    || isDecliningInProgress(agreement)
  ) {
    return null;
  }

  return (
    <div
      className={clsx(style.BoxActionsMenu, {
        [style.Editable]: isEditable,
        [style.CollapsedLayout]: isCollapsedLayout,
      })}
    >
      {isEditable && (
        <div className={clsx(style.CogwheelContainer, {
          [style.CollapsedLayout]: isCollapsedLayout,
        })}
        >
          <ActionsMenu
            actions={actions}
            customIcon={<CogIcon height={18} />}
            customTriggerClassname={style.Cogwheel}
            customClassName={clsx({
              [style.CollapsedLayout]: isCollapsedLayout,
            })}
            focusOnCloseDisabled
            setVisibility={setVisibility}
          />
        </div>
      )}
      {showLock && (
        <div className={style.Lock}>
          <Tooltip
            message={(
              <div className={style.TooltipMessage}>
                <Message
                  id="This section is locked"
                  comment="This messages show that this section is locked."
                />
              </div>
            )}
            side="left"
            zIndex={10003}
            sideOffset={8}
          >
            <LockIcon />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default BoxActionsMenu;
