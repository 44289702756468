/* eslint-disable import/named */
import {
  useCallback,
  useRef,
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { amplitudeLogEvent } from 'client-analytics/amplitude';
import { getGuestToken } from 'agreement/selectors';
import { useRichTextProps } from 'contexts/rich-text';
import { useContractProps } from 'contexts/contract-props';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { InlineToolbarUIState } from './types';
import { UI_STATE_MAP } from './constants';

type MouseDownCallback = (event: MouseEvent) => void;

export type SelectedRectanglePopoverProps = {
  toolbarRef: React.RefObject<HTMLDivElement>;
  triggerInlineToolbarAction: (
    triggerHandler: MouseDownCallback,
    event: MouseEvent,
    actionType?: string,
    agreementId?: number,
  ) => void;
  onInteractOutside: () => void;
  onEscapeKeyDown: () => void;
  setUIState: (state: InlineToolbarUIState) => void;
  resetUIState: () => void;
  UIState: InlineToolbarUIState;
  sideOffset: number;
  isVisible: boolean;
  side: 'top' | 'bottom';
};

type Props = {
  children: Node,
};

export const SelectedRectanglePopoverContext = createContext<SelectedRectanglePopoverProps | null>(
  null,
);

export const SelectedRectanglePopoverPropsProvider = ({
  children,
}: Props) => {
  const [UIState, setUIState] = useState<InlineToolbarUIState>(UI_STATE_MAP.noneActive);
  const {
    selectedRectangle,
  } = useRichTextProps();

  const isVisible = useMemo(() => (Boolean(selectedRectangle)
    && UIState !== UI_STATE_MAP.noneActive), [UIState, selectedRectangle]);

  const {
    isLinkPopoverOpen,
    isAiAssistPopoverOpen,
    isTableToolbarOpen,
  } = useContractProps();

  const side = useMemo(() => {
    if (isTableToolbarOpen || UIState !== UI_STATE_MAP.annotationToolbar) {
      return 'bottom';
    }

    return 'top';
  }, [UIState, isTableToolbarOpen]);

  const focusEditor = useFocusEditor();
  const guestToken = useSelector(getGuestToken);
  const toolbarRef = useRef<HTMLDivElement>(null);

  const sideOffset = useMemo(() => {
    if (!selectedRectangle) {
      return 0;
    }

    return (selectedRectangle.height) / 2 + 8;
  }, [selectedRectangle]);

  useEffect(() => {
    if (!selectedRectangle) {
      setUIState(UI_STATE_MAP.noneActive);
      return;
    }

    if (selectedRectangle && !isLinkPopoverOpen) {
      setUIState(UI_STATE_MAP.annotationToolbar);
    }
  }, [isLinkPopoverOpen, selectedRectangle]);

  useEffect(() => {
    if (selectedRectangle && isAiAssistPopoverOpen) {
      setUIState(UI_STATE_MAP.aiAssistPopover);
    }
  }, [isAiAssistPopoverOpen, selectedRectangle]);

  const resetUIState = useCallback(() => {
    setUIState(UI_STATE_MAP.noneActive);
  }, []);

  const onEscapeKeyDown = useCallback(() => {
    resetUIState();

    focusEditor({
      collapse: true,
    });
  }, [focusEditor, resetUIState]);

  const onInteractOutside = useCallback(() => {
    resetUIState();
  }, [resetUIState]);

  const triggerInlineToolbarAction = useCallback((
    triggerHandler: MouseDownCallback,
    event: MouseEvent,
    eventType?: string,
    agreementId?: number,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (eventType) {
      amplitudeLogEvent(eventType, {
        'participant type': guestToken ? 'guest' : 'owner',
      },
      {
        'document id': agreementId,
      });
    }

    triggerHandler(event);
  }, [guestToken]);

  const contextValue = useMemo(() => ({
    toolbarRef,
    triggerInlineToolbarAction,
    onInteractOutside,
    onEscapeKeyDown,
    setUIState,
    resetUIState,
    UIState,
    sideOffset,
    isVisible,
    side,
  }), [
    UIState,
    onEscapeKeyDown,
    onInteractOutside,
    resetUIState,
    sideOffset,
    triggerInlineToolbarAction,
    isVisible,
    side,
  ]);

  return (
    <SelectedRectanglePopoverContext.Provider value={contextValue}>
      {children}
    </SelectedRectanglePopoverContext.Provider>
  );
};

export const useSelectedRectanglePopoverProps = (): SelectedRectanglePopoverProps => {
  const context = useContext(SelectedRectanglePopoverContext);

  if (!context) {
    throw new Error('useSelectedRectanglePopoverProps must be used within SelectedRectanglePopoverPropsProvider');
  }

  return context;
};
