// @flow

import { decamelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';

import {
  getMultiple,
  post,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

export const apiTokenSchema = new schema.Entity('apiTokens');

export type NormalizedApiTokens = {
  entities: {
    apiTokens: {
      [number]: ApiToken,
    },
  },
  result: number | Array<number>,
  count: number,
};

type ApiTokenNormalizer = (any) => NormalizedApiTokens;

const normalizeApiTokens: ApiTokenNormalizer = (apiTokens) => ({
  ...normalize(apiTokens.collection, [apiTokenSchema]),
  count: apiTokens.count,
});

const normalizeApiToken: ApiTokenNormalizer = (apiToken) => ({
  ...normalize(apiToken, apiTokenSchema),
  count: 1,
});

type GetApiTokens = ({
  params?: {},
}) => Promise<NormalizedApiTokens>;

export const getApiTokens: GetApiTokens = ({ params }) => (
  getMultiple({
    url: '/ext/tokens/',
    params,
    pagination: {
      limit: undefined,
      offset: undefined,
    },
  }).then(extractResponseBodyAsJSON)
    .then(normalizeApiTokens)
);

type CreateApiToken = ({
  description: string,
}) => Promise<NormalizedApiTokens>;

export const createApiToken: CreateApiToken = ({
  description,
}) => (
  post({
    url: '/ext/tokens/',
    body: decamelizeKeys({
      description,
    }, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
    .then(normalizeApiToken)
);

type RemoveApiToken = ({
  id: number,
}) => Promise<{}>;

export const removeApiToken: RemoveApiToken = ({ id }) => (
  remove({
    url: `/ext/tokens/${id}`,
  }).then(extractResponseBodyAsJSON)
);
