import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import CircularSpinner from 'components/icons/circular-spinner';

type Props = {
  onClick: () => void,
  isLoading?: boolean,
  disabled?: boolean,
  trackable?: {
    name: string,
    props: {
      [name: string]: string
    }
  },
};

export const NextButton = ({
  onClick,
  isLoading,
  disabled,
  trackable,
}: Props) => (
  <Button
    kind="secondary"
    data-testid="next"
    icon={isLoading ? CircularSpinner : undefined}
    disabled={isLoading || disabled}
    onClick={onClick}
    trackable={trackable}
  >
    <Message
      id="Next"
      comment="Button that goes to the next step in a multistep process"
    />
  </Button>
);

NextButton.defaultProps = {
  disabled: undefined,
  isLoading: undefined,
  trackable: undefined,
};
