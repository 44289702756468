// @flow

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'natural-orderby';
import { isEmpty } from 'lodash';
import type { FormRenderProps } from 'react-final-form';
import ModalForm from 'hocs/modal-form';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import {
  isAnySignedState,
  isTemplate,
  isConcluded,
  hasDraftApprovalFlow,
  isPending,
} from 'agreement';
import * as participantConstants from 'agreement/participant/constants';
import { getNewInternalApproverBlockIndex } from 'agreement/draft-approval-flow';
import { getNewParticipantBlockIndex } from 'agreement/pending-state-flow';
import agreements from 'reducers/entities/agreements';

import { getPositionFromSessionSelector } from 'reducers/session';
import { getAgreementMyParty } from 'agreement/selectors';

import { getContractMessageTemplates } from 'oneflow-client/message-templates';

import Button from 'components/button';
import { AddParticipantButton, CancelButton } from 'components/buttons';
import Error from 'components/icons/error';
import CircularSpinner from 'components/icons/circular-spinner';
import { checkAcl } from 'components/acl';
import { getParticipantRolesWithApproverAsOptions } from 'components/participant-roles/participant-roles';
import { getSignMethod } from 'components/sign-methods/sign-methods';
import toast from 'components/toasts';
import AddColleagueBody from './add-colleague-body';
import style from './add-colleague.module.scss';

const modalKey = 'add contract colleague modal';
export type FormData = {|
  role?: number,
  signMethod?: number,
  subject?: string,
  body?: string,
|};

export type Props = {
  agreement: Agreement,
  message: MessageTranslator,
  hasParticipantList?: boolean,
};

type State = {
  contractMessageTemplates: Array<MessageTemplate>,
  selectedMessageTemplate: any,
  selectedColleague: any,
  selectedRole: any,
  selectedSignMethod: any,
  defaultColleague: any,
  defaultMessageTemplate: any,
  defaultSignMethod: any,
  defaultRole: any,
  isLoadingMessageTemplates: boolean,
  error: any,
}

export const showDocumentSentToast = () => (
  toast.info({
    id: 'document-sent-to-participants',
    title: (
      <Message
        id="Document sent"
        comment="Title for the info message when the user sends an invitation to participant(s)"
      />
    ),
    description: (
      <Message
        id="The document was sent to participants"
        comment="Description for the info message when the user sends an invitation to participant(s)"
      />
    ),
    duration: 5000,
  })
);

export const AddColleagueComponent = ({
  agreement,
  message,
  hasParticipantList,
}: Props) => {
  const { availableOptions } = agreement;
  const dispatch = useDispatch();
  const position = useSelector(getPositionFromSessionSelector);
  const rpcState = useSelector((state) => agreements.getAddParticipantSelector(state, {
    id: agreement.id,
  }));

  const myParty = getAgreementMyParty(agreement);
  const resetRpcState = () => dispatch(agreements.addParticipantReset({
    id: agreement.id,
  }));

  const hasAccessToInternalApproval = agreement
    ? checkAcl(agreement.acl, 'agreement:draft_approval_flow:update') : false;

  const hasAccessToPendingStateApprover = agreement
    ? checkAcl(agreement.acl, 'agreement:participant:pending_state_approver:create') : false;
  const hasAddAnotherPartyPermission = checkAcl(agreement.acl, 'agreement:participant:other_party:create');
  const hasAddOwnPartyPermission = checkAcl(agreement.acl, 'agreement:participant:own_party:create');
  const hasAddColleaguePermission = checkAcl(agreement.acl, 'agreement:participant:colleague:create');

  const hasPermissionToAddAnyParticipants = hasAddAnotherPartyPermission
    || hasAddOwnPartyPermission
    || hasAddColleaguePermission;

  const standardMessageTemplate = {
    id: 'standard_message',
    name: message({
      id: 'Standard message',
      comment: 'This will be used as the name of the message template',
    }),
    body: message({
      id: 'Your colleague {publisher} needs help with a draft before sending it to the counterparty.',
      comment: 'This will be used as the body of notification email',
      values: {
        publisher: position?.fullname,
      },
    }),
    subject: message({
      id: 'Your colleague {publisher} invited you',
      comment: 'This will be used as a subject for the notification email',
      values: {
        publisher: position?.fullname,
      },
    }),
  };

  const initialState: State = {
    contractMessageTemplates: [],
    defaultColleague: undefined,
    defaultRole: undefined,
    defaultSignMethod: undefined,
    error: null,
    hasSucceeded: false,
    isLoadingMessageTemplates: false,
    isOpen: false,
    selectedColleague: undefined,
    selectedRole: undefined,
    selectedSignMethod: undefined,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const {
    contractMessageTemplates,
    defaultColleague,
    defaultMessageTemplate,
    defaultRole,
    defaultSignMethod,
    selectedColleague,
    selectedMessageTemplate,
    selectedRole,
    selectedSignMethod,
  } = state;

  const getCustomButtonTitle = () => {
    if (isPending(agreement)) {
      return (
        <Message
          id="Send invitation"
          comment="Button label to send invitation to the counterparty"
        />
      );
    }

    return (
      <Message
        id="Add"
        comment="Button label to send invitation to the counterparty"
      />
    );
  };

  const getIcon = () => rpcState.loading && CircularSpinner;

  const getActions = ({ formProps }: FormRenderProps) => (
    <Button
      color="yellow"
      data-testid="confirm"
      icon={getIcon()}
      onClick={formProps.handleSubmit}
      disabled={
        state.isLoadingMessageTemplates
        || rpcState.loading
        || state.error
        || !selectedColleague
      }
    >
      {getCustomButtonTitle()}
    </Button>
  );

  const addColleague = (formData: FormData) => {
    const shouldInvite = formData.subject && formData.body ? 1 : 0;
    const hasDraftApproval = hasDraftApprovalFlow(agreement);
    const flowId = hasDraftApproval ? agreement.draftApprovalFlow.id : null;
    const blockIndex = hasDraftApproval ? getNewInternalApproverBlockIndex(agreement) : 0;

    const isSignOrderEnabled = agreement.config?.signOrder;
    const pendingStateFlowId = isSignOrderEnabled ? agreement.pendingStateFlow?.id : null;
    const signOrderBlockIndex = isSignOrderEnabled && !isConcluded(agreement)
      ? getNewParticipantBlockIndex(agreement) : 0;

    dispatch(agreements.addParticipant({
      id: agreement.id,
      data: {
        id: agreement.id,
        agreementCompany: myParty?.id,
        deliveryChannel: 0,
        isPrivate: 1,
        invite: shouldInvite,
        message: formData.body ? formData.body : '',
        subject: formData.subject ? formData.subject : '',
        positionId: selectedColleague?.value,
        signMethod: selectedSignMethod?.value,
        type: selectedRole?.value,
        flowId,
        blockIndex,
        pendingStateFlowId,
        signOrderBlockIndex,
      },
      pipe: {
        onSuccess: () => {
          if (isPending(agreement)) {
            setIsOpen(false);
            showDocumentSentToast();
          }
        },
      },
    }));
  };

  const getErrorActions: GetActions = ({ closeConfirmation }) => (
    <CancelButton onClick={closeConfirmation} modalKey={modalKey} />
  );

  const resetForm = () => {
    setState((prevState) => ({
      ...prevState,
      contractMessageTemplates: [],
      selectedMessageTemplate: undefined,
      selectedColleague: undefined,
      isLoadingMessageTemplates: false,
      error: null,
    }));

    setIsOpen(false);
    resetRpcState();
  };

  const handleSubmit = (formData: FormData) => {
    if (!formData || isEmpty(formData)) {
      return;
    }

    addColleague(formData);
  };

  const updateSelectedColleague = (selectedColleagueOption?: any) => {
    if (selectedColleagueOption) {
      setState((prevState) => ({
        ...prevState,
        selectedColleague: selectedColleagueOption,
      }));
    }
  };

  const updateSelectedMessageTemplate = (selectedMessageTemplateOption?: MessageTemplate) => {
    setState((prevState) => ({
      ...prevState,
      selectedMessageTemplate: selectedMessageTemplateOption,
    }));
  };

  const updateSelectedRole = (selectedRoleOption: any) => {
    if (selectedRoleOption) {
      setState((prevState) => ({
        ...prevState,
        selectedRole: selectedRoleOption,
      }));
    }
  };

  const updateSelectedSignMethod = (selectedSignMethodOption: any) => {
    if (selectedSignMethodOption) {
      setState((prevState) => ({
        ...prevState,
        selectedSignMethod: selectedSignMethodOption,
      }));
    }
  };

  const getMessageBody = (messageTemplate: MessageTemplate) => {
    if (isEmpty(agreement)) {
      return null;
    }

    if (messageTemplate?.id === 'standard_message') {
      return `${messageTemplate.body}`;
    }

    if (position.signature && !isEmpty(messageTemplate) && messageTemplate.body) {
      return `${messageTemplate.body}\n\n${position.signature}`;
    }

    if (position.signature) {
      return position.signature;
    }

    if (messageTemplate && messageTemplate.body) {
      return messageTemplate.body;
    }

    return undefined;
  };

  const getModalTitle = () => {
    if (isEmpty(agreement)) {
      return null;
    }

    if (isTemplate(agreement)) {
      return (
        <Message
          id="Add colleague"
          comment="Confirm modal text."
        />
      );
    }

    if (isPending(agreement)) {
      return (
        <Message
          id="Add colleague and send invitation"
          comment="Confirm modal text."
        />
      );
    }

    return (
      <Message
        id="Add colleague to {party}"
        comment="Confirm modal text."
        values={{
          party: myParty.name,
        }}
      />
    );
  };

  const getDefaultParticipantRole = () => {
    if (isConcluded(agreement) || isAnySignedState(agreement)) {
      return getParticipantRolesWithApproverAsOptions(
        message,
        hasAccessToInternalApproval,
        hasAccessToPendingStateApprover,
      ).filter(
        (role) => role.value === participantConstants.TYPE_IS_ORGANIZER,
      )[0];
    }

    return getParticipantRolesWithApproverAsOptions(message, hasAccessToInternalApproval,
      hasAccessToPendingStateApprover)[0];
  };

  const getSignMethods = () => (
    availableOptions?.signMethods.map<any>(
      (signMethod) => ({
        value: signMethod && signMethod,
        label: getSignMethod(signMethod, message).label,
      }),
    )
  );

  const getDefaultSignMethod = () => {
    if (isEmpty(agreement)) {
      return null;
    }

    return (
      getSignMethods()[0]
    );
  };

  const fetchData = async () => {
    const { isLoadingMessageTemplates } = state;
    if (isLoadingMessageTemplates) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      isLoadingMessageTemplates: true,
      error: null,
    }));

    try {
      const { collection: contractMessageTemplatesData } = await getContractMessageTemplates({
        contractId: agreement.id,
        type: 'publish',
      });
      const orderedContractMessages = orderBy(
        contractMessageTemplatesData,
        [(v) => v.name],
        ['asc'],
      );

      setState((prevState) => ({
        ...prevState,
        contractMessageTemplates: [standardMessageTemplate, ...orderedContractMessages],
        selectedColleague: undefined,
        selectedMessageTemplate: undefined,
        selectedSignMethod: getDefaultSignMethod(),
        selectedRole: getDefaultParticipantRole(),
        defaultColleague: { value: 'empty_colleague' },
        defaultMessageTemplate: undefined,
        defaultSignMethod: getDefaultSignMethod(),
        defaultRole: getDefaultParticipantRole(),
        isLoadingMessageTemplates: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        contractMessageTemplates: [],
        isLoadingMessageTemplates: false,
        error,
      }));
    }
  };

  const handleOpen = () => {
    if (!hasPermissionToAddAnyParticipants) {
      return;
    }

    setIsOpen(true);
    fetchData();
    updateSelectedMessageTemplate(contractMessageTemplates[0]);
  };

  const renderBody = () => {
    const {
      error,
      isLoadingMessageTemplates,
    } = state;
    if (isEmpty(agreement)) {
      return null;
    }

    if (isLoadingMessageTemplates) {
      return (
        <div className={style.SpinnerContainer}>
          <CircularSpinner />
        </div>
      );
    }

    if (error) {
      return (
        <span className={style.ErrorMessage}>
          <Error className={style.ErrorIcon} />
          <Message
            id="An error has occurred. Please reload the page and try again."
            comment="Used to show the error message after confirmation."
          />
        </span>
      );
    }

    return (
      <AddColleagueBody
        agreement={agreement}
        contractMessageTemplates={contractMessageTemplates}
        getMessageBody={getMessageBody}
        getSignMethods={getSignMethods}
        position={position}
        selectedColleague={selectedColleague}
        selectedMessageTemplate={selectedMessageTemplate}
        selectedRole={selectedRole}
        selectedSignMethod={selectedSignMethod}
        updateSelectedMessageTemplate={updateSelectedMessageTemplate}
        updateSelectedColleague={updateSelectedColleague}
        updateSelectedRole={updateSelectedRole}
        updateSelectedSignMethod={updateSelectedSignMethod}
      />
    );
  };

  const renderChildren = () => (
    <AddParticipantButton
      type="colleague"
      onClick={handleOpen}
      disabled={!hasPermissionToAddAnyParticipants}
      hasParticipantList={hasParticipantList}
      addAppcuesClass
    />
  );

  return (
    <ModalForm
      actions={getActions}
      body={renderBody()}
      errorActions={getErrorActions}
      title={getModalTitle()}
      isOpen={isOpen}
      formState={rpcState}
      resetFormState={resetRpcState}
      onSubmit={handleSubmit}
      onClose={resetForm}
      initialValues={{
        body: getMessageBody(defaultMessageTemplate),
        colleague: defaultColleague?.value,
        subject: defaultMessageTemplate?.subject,
        signMethod: defaultSignMethod?.value,
        role: defaultRole?.value,
      }}
      modalKey={modalKey}
    >
      {renderChildren}
    </ModalForm>
  );
};

export default localize<Props>(AddColleagueComponent);
