import { useMemo, useState } from 'react';
import {
  States, getAccordionStates, persistAccordionStates, sectionNames,
} from '../helpers';

const useAccordionStates = () => {
  const [accordionStates, setAccordionStates] = useState<States>(() => getAccordionStates());
  const updateAccordionStates = useMemo(() => (
    { name, state }
    : { name: typeof sectionNames[number], state: boolean },
  ) => {
    setAccordionStates((_accordionStates) => ({ ..._accordionStates, [name]: state }));
    persistAccordionStates({ ...accordionStates, [name]: state });
  }, [accordionStates]);

  return { accordionStates, updateAccordionStates };
};

export default useAccordionStates;
