// @flow

import {
  KEY_TEAMTAILOR,
} from 'extensions';

const Teamtailor = {
  key: KEY_TEAMTAILOR,
};

export default Teamtailor;
