import { useMemo } from 'react';

import { PopoverPrimitive } from 'components/popover';
import { DialogPrimitive } from 'components/dialog';

import { PopupDialogContent, PopupPopoverContent } from './popup-content';

function usePopupDialog(popupType = 'popover') {
  const popupComponents = useMemo(() => ({
    Dialog: DialogPrimitive,
    Popover: PopoverPrimitive,
    Popup: popupType === 'popover' ? PopoverPrimitive : DialogPrimitive,
    PopupContent: popupType === 'popover' ? PopupPopoverContent : PopupDialogContent,
    popupType,
  }), [popupType]);

  return popupComponents;
}

export default usePopupDialog;
