import { connect } from 'react-redux';
import { get } from 'lodash';

import workspacesReducer from 'reducers/entities/workspaces';
import { requestMe, getPositionFromSessionSelector } from 'reducers/session';
import workspaceUsersReducer from 'reducers/entities/workspace-users';

import AddWorkspace from './add-workspace';

export const QUERY_NAME = 'admin/workspaces';

export const mapStateToProps = (state) => ({
  formState: workspacesReducer.getCreateSelector(state),
  position: getPositionFromSessionSelector(state),
});

export const mapDispatchToProps = (dispatch) => ({
  onSubmit: ({ name, type, description }) => {
    dispatch(workspacesReducer.createWorkspace({
      data: {
        name,
        description,
        type: get(type, 'value'),
      },
      pipe: {
        action: () => workspacesReducer.queryWorkspacesReload({
          name: QUERY_NAME,
        }),
      },
    }));
  },
  resetFormState: () => {
    dispatch(workspacesReducer.createWorkspaceReset());
  },
  addMyselfToWorkspace: (data) => {
    dispatch(workspaceUsersReducer.createWorkspaceUser({
      data,
      pipe: {
        action: () => workspacesReducer.queryWorkspacesReload({ name: QUERY_NAME }),
        onSuccess: () => {
          dispatch(requestMe());
        },
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkspace);
