import { BOX_TYPES } from 'agreement/constants';

const boxTypeNames = {
  [BOX_TYPES.BOX_TEXT_AND_IMAGE]: 'text and image',
  [BOX_TYPES.BOX_VIDEO]: 'video',
  [BOX_TYPES.BOX_PRODUCT_TABLE]: 'product table',
  [BOX_TYPES.BOX_FORM]: 'form',
  [BOX_TYPES.BOX_DURATION]: 'duration',
  [BOX_TYPES.BOX_PDF]: 'embedded pdf',
  [BOX_TYPES.BOX_ATTACHMENTS]: 'attachments',
  [BOX_TYPES.BOX_PRODUCT_SUMMATION]: 'product summation',
};

export default function getBoxTypeName(boxType: typeof BOX_TYPES[keyof typeof BOX_TYPES]) {
  return boxTypeNames[boxType] || undefined;
}
