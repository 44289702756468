// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import ActionBar from 'components/action-bar';
import Button from 'components/button';
import {
  CancelButton,
  ConfirmButton,
} from 'components/buttons';
import Edit from 'components/icons/edit';

import style from './action-bar.module.scss';
import RoleDetailsHeaderText from '../../role-details-header-text';

type Props = {
  isConfirmDisabled: boolean,
  canEdit: boolean,
  updateState: RpcState,
  onClick: (args: ?Object) => void,
  onConfirm: () => void,
  onCancel: () => void,
  onSuccess: () => void,
  isEditingEnabled: boolean,
  role: Oneflow.Role,
}

type State = {
  actionBarCollapsed: boolean,
  actionBarContent: ?string,
}

class RolePageActionBar extends React.Component<Props, State> {
  state = {
    actionBarCollapsed: true,
    actionBarContent: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    const { updateState, isEditingEnabled, onSuccess } = this.props;
    const isUpdateSuccess = !prevProps.updateState.success && updateState.success;
    const switchedToEdit = !prevProps.isEditingEnabled && isEditingEnabled;

    if (isUpdateSuccess) {
      this.collapseActionBar();
      onSuccess();
    }

    if (switchedToEdit) {
      this.onEdit();
    }
  }

  cancel = () => {
    const { onCancel } = this.props;

    this.collapseActionBar();
    onCancel();
  };

  onEdit = () => {
    this.enableEditing({ actionBarContent: 'edit-role-form' });
  };

  enableEditing({ actionBarContent }) {
    const { onClick } = this.props;

    this.expandActionBar({ actionBarContent });
    onClick({ actionBarContent });
  }

  expandActionBar({ actionBarContent }) {
    this.setState({
      actionBarCollapsed: false,
      actionBarContent,
    });
  }

  collapseActionBar() {
    this.setState({
      actionBarCollapsed: true,
      actionBarContent: undefined,
    });
  }

  render() {
    const {
      onConfirm,
      updateState,
      isConfirmDisabled,
      canEdit,
      role,
    } = this.props;

    return (
      <ActionBar collapsed={this.state.actionBarCollapsed}>
        <ActionBar.Group>
          <div className={style.RoleButtons}>
            <Button
              key="edit-role-form"
              icon={Edit}
              kind="primary"
              data-testid="edit-user-role"
              onClick={this.onEdit}
              disabled={!canEdit}
            >
              <Message
                id="Edit"
                comment="The text shown in the button for editing the role"
              />
            </Button>
          </div>
          <RoleDetailsHeaderText role={role} />
        </ActionBar.Group>
        <ActionBar.Content active={this.state.actionBarContent === 'edit-role-form'}>
          <div className={style.EditButtonHeaderContainer}>
            <div className={style.ActionButtons}>
              <ConfirmButton
                disabled={isConfirmDisabled}
                isLoading={updateState.loading}
                onClick={onConfirm}
              />
              <CancelButton onClick={this.cancel} />
            </div>
            <RoleDetailsHeaderText role={role} />
          </div>
        </ActionBar.Content>
      </ActionBar>
    );
  }
}

export default RolePageActionBar;
