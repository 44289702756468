import Dropzone from 'react-dropzone';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import type { DropzoneProps } from 'react-dropzone';

import { MAX_ATTACHMENT_SIZE, MAX_ATTACHMENTS_COUNT_PER_CONTRACT_DEFAULT } from 'components/contract-boxes/constants';

import style from './box-file-upload.module.scss';

type Props = {
  customClassName?: string,
  children: ReactNode,
  disabled?: DropzoneProps['disabled'],
  multiple?: DropzoneProps['multiple'],
  uploadingError?: boolean,
  maxSize?: DropzoneProps['maxSize'],
  onDrop?: DropzoneProps['onDrop'],
  acceptedExtensions?: DropzoneProps['accept'],
  onError?: DropzoneProps['onError'],
  myParticipant?: Oneflow.Participant,
  maxFiles?: DropzoneProps['maxFiles'],
  maxSizePerContractValidator: DropzoneProps['validator'],
  onDragEnter?: DropzoneProps['onDragEnter'],
  onDragLeave?: DropzoneProps['onDragLeave'],
  ariaLabel?: string,
  required?: boolean,
  invalid?: boolean,
  onFileDialogCancel?: DropzoneProps['onFileDialogCancel'],
}

const BoxFileUpload = ({
  customClassName,
  children,
  disabled,
  multiple,
  uploadingError,
  maxSize = MAX_ATTACHMENT_SIZE,
  maxFiles = MAX_ATTACHMENTS_COUNT_PER_CONTRACT_DEFAULT,
  acceptedExtensions,
  onError,
  myParticipant,
  maxSizePerContractValidator,
  onDrop,
  onDragEnter,
  onDragLeave,
  ariaLabel,
  required,
  invalid,
  onFileDialogCancel,
}: Props) => {
  const dropzoneClasses = clsx(style.Dropzone, customClassName, {
    [style.Invalid]: invalid,
    [style.DashedBorder]: !disabled,
    [style.HiddenDropzone]: isEmpty(myParticipant),
    [style.DropzoneErrors]: uploadingError,
  });

  return (
    <Dropzone
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      multiple={multiple}
      maxSize={maxSize}
      accept={acceptedExtensions}
      onError={onError}
      disabled={disabled}
      maxFiles={maxFiles}
      validator={maxSizePerContractValidator}
      onDrop={onDrop}
      onFileDialogCancel={onFileDialogCancel}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getRootProps({ className: dropzoneClasses })}
            aria-label={ariaLabel}
            aria-required={required}
          >
            <input {...getInputProps()} />
            {children}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default BoxFileUpload;
