import { put } from 'redux-saga/effects';

import generateEntitySagas from 'normalized-redux/sagas';
import apiWrapper from 'sagas/api-wrapper';
import accountGroupsReducer from 'reducers/entities/account-groups';
import getAccountGroupBindings from 'oneflow-client/account-groups';

type DataType = {
  data: {
    entities: {
      accountGroupsBindings: Oneflow.AccountGroupRoleBinding
    }
  },
};

export function* mapper({ data }: DataType) {
  yield put(accountGroupsReducer.setAccountGroups(data.entities.accountGroupsBindings));
}

const mappers = {
  query: {
    mapper,
    request: getAccountGroupBindings,
  },
};

const accountGroupsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: accountGroupsReducer,
  mappers,
});

export default accountGroupsSagas;
