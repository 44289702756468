/* eslint-disable import/named */
import {
  useRef,
  useState,
} from 'react';
import {
  isEmpty,
  some,
} from 'lodash';
import {
  Form,
  FormRenderProps,
} from 'react-final-form';

import {
  COL_NAME_MAX_LENGTH,
  DEFAULT_QUANTITY_PRECISION,
} from 'components/contract-boxes/product-table-box/constants';
import { maxLength } from 'forms/validators';
import { localize } from '@oneflowab/pomes';
import usePopupDialog from 'hooks/popup/use-popup-dialog';
import type {
  Column,
  Config,
  ConfigUpdateData,
} from 'data-validators/entity-schemas/document-box/product-table';
import type { MessageTranslator } from '@oneflowab/pomes';
import { CancelButton } from 'components/buttons';
import Button from 'components/button';
import CheckBox from 'components/checkbox';
import DecimalSelector from 'components/contract-boxes/product-table-box/product-table/popup-forms/decimal-selector';
import Field from 'components/field';
import Message from 'components/message';
import TextField from 'components/text-field';
import TooltipInfo from 'components/tooltip-info';

import style from './quantity.module.scss';

type Props = {
  column: Column,
  config: Config,
  message: MessageTranslator,
  popupType: 'popover' | 'dialog',
  updateProductConfig: (configData: ConfigUpdateData) => void,
}

type FormValues = {
  colleagueEdit: boolean,
  columnName: string,
  counterpartEdit: boolean,
}

export const QuantityPopupForm = ({
  column,
  config,
  message,
  popupType,
  updateProductConfig,
}: Props) => {
  const [decimalValue, setDecimalValue] = useState(
    config.quantityPrecision || DEFAULT_QUANTITY_PRECISION,
  );
  const closeButtonRef = useRef<HTMLInputElement>(null);
  const closePopup = () => closeButtonRef.current?.click();
  const { Popup } = usePopupDialog(popupType);
  const columnNameValidator = maxLength({
    message,
    field: 'columnName',
    customMessage: message({
      id: 'Maximum {maxLength} characters allowed.',
      comment: 'Validation message for price price affix validation.',
      values: {
        maxLength: COL_NAME_MAX_LENGTH,
      },
    }),
    maxLength: COL_NAME_MAX_LENGTH,
  });

  const handleValueChange = (value: number) => {
    setDecimalValue(Number(value));
  };

  const handleSubmit = ({
    columnName, counterpartEdit, colleagueEdit,
  }: FormValues) => {
    const configData = {
      column: {
        ...column,
        label: columnName || '',
      },
      colleagueEdit,
      counterpartEdit,
      quantityPrecision: decimalValue,
    };

    updateProductConfig(configData);
    closePopup();
  };

  const renderQuantityForm = (formProps: FormRenderProps<FormValues>) => {
    const disabled = some([
      formProps.invalid,
      formProps.submitting,
      !isEmpty(formProps.errors),
    ]);
    const isLockedByManager = config?.managerLock === 1;

    const colleagueEditExplanationTooltip = () => (
      <>
        <Message
          id="Allow colleagues to edit field value"
          comment="Checkbox label to denote if a colleague is allowed to edit the value of the field."
        />
        <TooltipInfo
          message={(
            <Message
              id="Allow colleagues to edit field values in a locked product section."
              comment="Tooltip for checkbox that denotes if colleagues are allowed to edit field values"
            />
          )}
          side="top"
          zIndex="100000"
          align="end"
          alignOffset={-40}
        />
      </>
    );

    return (
      <form
        onSubmit={formProps.handleSubmit}
      >
        <div className={style.PopupHeader}>
          <Message
            id="Edit Column"
            comment="Header title for product table when editing the column name"
          />
        </div>
        <div className={style.PopupFormContainer}>
          <div className={style.PopupFormElement}>
            <div className={style.ColumnNameInputContainer}>
              <Field
                label={<Message id="Column name" comment="Field label for editing the column name" />}
                aria-labelledby="textfieldlabel"
                maxLength={COL_NAME_MAX_LENGTH}
                name="columnName"
                id="columnName"
                component={TextField}
                customClass={style.PopupTextField}
                labelCustomClass={style.PopupTextFieldLabel}
                validate={columnNameValidator}
                autoComplete="off"
              />
            </div>
          </div>
          <div className={style.DecimalSelectorContainer}>
            <DecimalSelector
              type="quantityField"
              decimalValue={decimalValue}
              handleValueChange={handleValueChange}
            />
          </div>
          <div className={style.EditingCheckboxesWrapper}>
            <Field
              name="counterpartEdit"
              customCheckboxClass={style.CheckBox}
              customLabelStyle={style.CheckBoxLabelText}
              wrapLabelText
              component={CheckBox}
              type="checkbox"
              label={<Message id="Allow counterparties to edit field value" comment="Checkbox label to denote if a counterpart is allowed to edit the value of the field." />}
              checked={config?.counterpartEdit}
              data-testid="counterpart-edit"
              autoComplete="off"
            />
            <Field
              name="colleagueEdit"
              component={CheckBox}
              type="checkbox"
              label={colleagueEditExplanationTooltip()}
              checked={config?.colleagueEdit}
              data-testid="colleague-edit"
              disabled={!isLockedByManager}
              wrapLabelText
              customLabelStyle={style.CheckBoxLabelText}
              customClass={style.ColleagueCheckboxLabel}
              customCheckboxClass={style.ColleagueCheckbox}
              autoComplete="off"
            />
          </div>
          <div className={style.ActionButtonsContainer}>
            <Popup.Close asChild>
              <CancelButton />
            </Popup.Close>
            <Button
              data-testid="save-button"
              type="submit"
              kind="secondary"
              disabled={disabled}
            >
              <Message
                id="Save"
                comment="Action to save."
              />
            </Button>
            <Popup.Close asChild>
              {/* No translation needed and not part of accessibility */}
              <Button
                aria-hidden="true"
                customClass={style.HiddenButton}
                buttonRef={closeButtonRef}
                type="button"
              >
                Programmatic hidden close button
              </Button>
            </Popup.Close>
          </div>
        </div>
      </form>
    );
  };

  return (
    <Form
      render={renderQuantityForm}
      initialValues={{
        columnName: column.label,
        counterpartEdit: config.counterpartEdit,
        colleagueEdit: config.colleagueEdit,
      }}
      onSubmit={handleSubmit}
    />
  );
};

export default localize(QuantityPopupForm);
