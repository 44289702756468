import { checkAcl } from 'components/acl';
import SetContractName from 'components/document-topbar/left-side/set-contract-name';
import MoveContract from 'components/modals/move-contract';
import ContractValue from 'components/contract-value';
import Tags from 'components/tags';
import State from 'components/document-information/state';
import TopbarWorkspace from 'components/document-topbar/left-side/topbar-workspace';
import useWindowSize from 'hooks/use-window-size';

import { DOCUMENT_COLLAPSED_LAYOUT_SIZE } from 'components/document-layout-container/helpers';
import style from './left-side.module.scss';

type Props = {
  agreement: Oneflow.Document
}

type Breakpoints = Array<{
  maxTagsNumber: number,
  query: {
    minWidth?: number,
    maxWidth?: number,
  },
}>;

const breakpointLg = 600;
const breakpointXl = 1920;

const breakpoints: Breakpoints = [{
  maxTagsNumber: 0,
  query: {
    maxWidth: breakpointLg - 1,
  },
}, {
  maxTagsNumber: 2,
  query: {
    minWidth: breakpointLg,
    maxWidth: breakpointXl,
  },
}, {
  maxTagsNumber: 8,
  query: {
    minWidth: breakpointXl + 1,
  },
}];

const LeftSide = ({ agreement }: Props) => {
  const windowSize = useWindowSize();
  const isDeviceSmallerThanLg = windowSize.width && windowSize.width < breakpointLg;
  const isCollapsedLayout = windowSize.width
    && windowSize.width < parseInt(DOCUMENT_COLLAPSED_LAYOUT_SIZE, 10);

  const renderTopBarWorkspace = (onClick: () => void) => {
    if (isDeviceSmallerThanLg) {
      return null;
    }

    return (
      <TopbarWorkspace
        agreement={agreement}
        onClick={onClick}
      />
    );
  };

  const renderTags = () => {
    if (isCollapsedLayout) {
      return null;
    }

    return (
      <Tags
        allowSearch
        searchGlobally
        allowTagChange={checkAcl(agreement.acl, 'agreement:tag:use')}
        breakpoints={breakpoints}
        preferPopoverPlace="below"
        responsive
        targetId={agreement.id}
        targetType="agreement"
        amplitudeScope="contract view"
        renderAsNativeAnchorElement
        isDocumentPage
      />
    );
  };

  return (
    <div className={style.DocumentInformation}>
      <div className={style.NameTagContainer}>
        <SetContractName agreement={agreement} />
        {renderTags()}
      </div>
      <div className={style.DocumentInformationContainer}>
        <State agreement={agreement} />
        <MoveContract agreement={agreement}>
          {(onClick: () => void) => renderTopBarWorkspace(onClick)}
        </MoveContract>
        <ContractValue
          agreement={agreement}
          agreementValue={agreement.agreementValue}
          className={style.ContractValue}
        />
      </div>
    </div>
  );
};

export default LeftSide;
