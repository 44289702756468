import map from 'lodash/map';

import normalizeEntity from 'normalized-redux/entity-normalizer';
import agreements from 'reducers/entities/agreements';

const normalizedAgreementTemplates = normalizeEntity({
  entity: 'agreementTemplate',
  rpcs: ['shareAgreementTemplate'],
});

const { getAgreementTemplatesSelector } = normalizedAgreementTemplates;

export const getTemplatesWithAgreementsSelector = (state, { ids }) => {
  const templates = getAgreementTemplatesSelector(state, { ids });

  return map(templates, (template) => (
    {
      ...template,
      agreement: agreements.getAgreementSelector(state, { id: template.agreement }),
    }
  ));
};

export default normalizedAgreementTemplates;
