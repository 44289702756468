// @flow
import React, { useCallback, useEffect } from 'react';
import type { Element } from 'react';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import clsx from 'clsx';
import * as Progress from '@radix-ui/react-progress';

import NoInternetIcon from 'components/icons/no-internet';
import UploadFailedIcon from 'components/icons/upload-failed';

import style from './dropzone-upload-space.module.scss';
import { OFFLINE_ERROR, API_ERROR_PDF_CONTAINS_TOO_MANY_PAGES } from './file-upload-error';

type Props = {
  errorCode: string | number,
  isDragReject?: boolean,
  body?: Element,
  loading: boolean,
  asyncAssetState: string,
  isContractBox: boolean,
  acceptedFileTypes?: string,
  displayFileType?: string,
  showRejectMessage?: boolean,
  message: MessageTranslator,
  required?: boolean,
};

export const getRejectMessage = ({ isDragReject }: { isDragReject: boolean }) => {
  if (!isDragReject) {
    return null;
  }

  return (
    <span>
      <Message
        id="The file format is not supported"
        comment="Info text to be display to the user when trying to upload a file of an unaccepted type"
      />
    </span>
  );
};

const DropzoneUploadSpace = ({
  isDragReject,
  body,
  loading,
  asyncAssetState,
  isContractBox,
  acceptedFileTypes,
  displayFileType,
  showRejectMessage = true,
  errorCode,
  message,
  required,
}: Props) => {
  const initialProgress = asyncAssetState === 'processing' ? 90 : 10;
  const [progress, setProgress] = React.useState(initialProgress);

  useEffect(() => {
    let timer;

    if (loading) {
      timer = setTimeout(() => {
        setProgress(90);
      }, 500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [loading, asyncAssetState]);

  const renderMessage = useCallback(() => {
    const fileTypes = acceptedFileTypes?.split(', ');
    let fileTypeLabel;

    switch (displayFileType?.toLowerCase()) {
      case 'pdf':
        fileTypeLabel = 'PDF';
        break;
      case 'video':
        fileTypeLabel = message({ id: 'video', comment: 'file type label to be used inside upload component' });
        break;
      case 'attachment':
        fileTypeLabel = message({ id: 'attachments', comment: 'file type label to be used inside upload component' });
        break;
      default:
        fileTypeLabel = displayFileType ?? fileTypes?.[0];
        break;
    }

    return (
      <span>
        <Message
          id="Drop {fileTypeLabel} here or {jsx-start}choose file{jsx-end}"
          pluralId="Drop {fileTypeLabel} here or {jsx-start}choose files{jsx-end}"
          pluralCondition="filePlural"
          comment="Text for upload area instructing where to drop files for processing, the fileTypeLabel could be 'PDF' or 'attachment' or 'video'"
          values={{
            fileTypeLabel,
            filePlural: displayFileType === 'attachment' ? 2 : 1,
          }}
          component="span"
          className={style.MessageUnderline}
        />
        {required && (
          <span className={style.RequiredMark}>
            {' '}
            *
          </span>
        )}
      </span>
    );
  }, [acceptedFileTypes, displayFileType, message, required]);

  const uploadMessage = useCallback(() => {
    if ((displayFileType || acceptedFileTypes) && isContractBox) {
      return (
        <div className={style.MessageTop}>
          {renderMessage()}
        </div>
      );
    }

    return (
      <div className={style.MessageTop}>
        <Message
          id="Drop file here or click to upload"
          comment="Text for upload area instructing where to drop files for processing"
        />
      </div>
    );
  }, [acceptedFileTypes, displayFileType, isContractBox, renderMessage]);

  const renderLoadingAnimation = useCallback(() => {
    const getAssetStateMessage = () => {
      switch (asyncAssetState) {
        case 'processing':
          return <Message id="Processing" comment="Indicates a file is in processing state" />;
        case 'uploading':
          return <Message id="Uploading" comment="Indicates a file is in uploading state" />;
        default:
          return <Message id="Uploading" comment="Indicates a file is in uploading state" />;
      }
    };

    return (
      <>
        <div className={style.LoadingInfo}>
          {getAssetStateMessage()}
          ...&nbsp;
          <Message id="Please wait" comment="Text for upload area instructing to wait for the file to be processed" />
        </div>
        <Progress.Root className={style.ProgressRoot} value={progress}>
          <Progress.Indicator
            className={style.ProgressIndicator}
            style={{ transform: `translateX(-${100 - progress}%)` }}
          />
        </Progress.Root>
      </>
    );
  }, [progress, asyncAssetState]);

  if (loading) {
    const loadingClasses = clsx(style.Loading, {
      [style.ContractBox]: isContractBox,
    });

    return (
      <div className={loadingClasses}>
        {renderLoadingAnimation()}
      </div>
    );
  }

  if (errorCode === OFFLINE_ERROR) {
    return (
      <div className={style.OfflineErrorWrapper}>
        <div className={style.IconWrapper}>
          <NoInternetIcon width="32px" height="32px" />
        </div>
        <div className={style.MessageTop}>
          <Message
            id="Looks like you're offline"
            comment="Text for upload area saying that there is an error due to an issue with network connectivity"
          />
        </div>
        <div className={style.ErrorMessageContainer}>
          <p>
            <Message
              id="Sorry, we couldn't upload the file."
              comment="Text for upload area saying that upload process was not successful"
            />
          </p>
          <p>
            <Message
              id="Please check your network connection and try again."
              comment="Text for upload area instructing to check network connection when there is upload error"
            />
          </p>
        </div>
      </div>
    );
  }

  if (errorCode === API_ERROR_PDF_CONTAINS_TOO_MANY_PAGES) {
    return (
      <div className={style.OfflineErrorWrapper}>
        <div className={style.IconWrapper}>
          <UploadFailedIcon width="36px" height="36px" />
        </div>
        <div className={style.MessageTop}>
          <Message
            id="Sorry, we couldn't upload the file"
            comment="Text for upload area saying that there is an error due to an issue with the PDF document"
          />
        </div>
        <div className={style.ErrorMessageContainer}>
          <p>
            <Message
              id="The document should not exceed 200 pages."
              comment="Text for upload area saying that the PDF document shuold not have more than 200 pages"
            />
          </p>
          <p>
            <Message
              id="Please reduce the number of pages or choose another document."
              comment="Text for upload area instructing to reduce number of pages of the PDF file and try uploading again"
            />
          </p>
        </div>
      </div>
    );
  }

  const messageBottomClasses = clsx(style.MessageBottom, {
    [style.ContractBox]: isContractBox,
  });

  return (
    <>
      {uploadMessage()}
      {body && (
        <div className={messageBottomClasses}>
          {body}
          {showRejectMessage && (
            <div className={style.RejectMessageArea}>
              {getRejectMessage({ isDragReject })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default localize<Props>(DropzoneUploadSpace);
