import { ReactNode } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';

import { isBoxDataReorderAllowed } from 'agreement/box-data-reorder-permissions';
import { useFormBoxProps } from 'contexts/form-box-props';
import useCurrentBox from 'hooks/use-current-box';
import type { Box } from 'data-validators/entity-schemas/document-box';

import SmallDragHandlerIcon from 'components/icons/small-drag-handler';

import style from './drag-control-item.module.scss';

type Props = {
  children: ReactNode,
  index: number,
  item: object,
}

const DragControlItem = (props: Props) => {
  const { item } = props;
  const id = getId(item);
  const { boxId } = useFormBoxProps();
  const box = useCurrentBox(boxId) as Box;
  const isAllowedToReorderFormData = isBoxDataReorderAllowed(box);

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges: () => false,
  });
  const dragWrapperClasses = clsx(style.DragWrapper, {
    [style.IsDragging]: isDragging,
    [style.Disabled]: !isAllowedToReorderFormData,
  });

  return (
    <div
      className={dragWrapperClasses}
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
    >
      <div
        {...attributes}
        {...listeners}
        className={style.DragHandlerWrapper}
      >
        <div className={style.IconWrapper}>
          <div className={style.Icon}>
            <SmallDragHandlerIcon width="10px" height="10px" />
          </div>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default DragControlItem;
