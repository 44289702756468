import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  isCommentResolved,
  isSuggestionResolved,
  isParentResolved,
} from 'comments';
import { MESSAGES_TAB, AUDIT_TRAIL_TAB } from 'agreement/constants';
import { isNewUpdate } from 'agreement/selectors';
import {
  getAgreementSidebarActiveTabNameSelector,
  getSeenMessageThreadsSelector,
} from 'reducers/app';
import useAgreement from 'hooks/use-agreement';
import useCurrentMessages from 'hooks/use-current-messages';
import type { RootState } from 'reducers';

import { sectionNames } from 'components/document-tabs/settings-tab/helpers';
import useAccordionStates from 'components/document-tabs/settings-tab/hooks/use-accordion-states.ts';
import type { States } from 'components/document-tabs/settings-tab/helpers';

type DocumentLayoutContextType = {
  accordionStates: States,
  documentContentRef: React.RefObject<HTMLElement | null>;
  documentContentWrapperCallback: (node: HTMLDivElement | null) => void;
  documentContentWrapperNode: HTMLDivElement | null;
  documentLayoutRef: React.RefObject<HTMLElement | null>;
  documentScrollContainerRef: React.RefObject<HTMLElement | null>;
  newAuditTrailCount: number;
  newMessagesCount: number;
  setLastSeenAudit: React.Dispatch<React.SetStateAction<string | undefined>>;
  shouldFadeOutCountBadge: boolean;
  updateAccordionStates: (state: { name: typeof sectionNames[number], state: boolean }) => void,
};

const DocumentLayoutContext = createContext<DocumentLayoutContextType>({
  documentContentRef: { current: null },
  documentContentWrapperCallback: () => undefined,
  documentContentWrapperNode: null,
  documentLayoutRef: { current: null },
  documentScrollContainerRef: { current: null },
  newAuditTrailCount: 0,
  newMessagesCount: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLastSeenAudit: () => { },
  shouldFadeOutCountBadge: false,
  accordionStates: {},
  updateAccordionStates: () => undefined,
});

type Props = {
  children: ReactNode;
  agreementId: number;
};

export const DocumentLayoutProvider = ({
  children,
  agreementId,
}: Props) => {
  const documentContentRef = useRef<HTMLElement | null>(null);
  const [
    documentContentWrapperNode,
    setDocumentContentWrapperNode,
  ] = useState<HTMLDivElement | null>(null);
  const documentContentWrapperCallback = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setDocumentContentWrapperNode(node);
    }
  }, []);
  const documentLayoutRef = useRef<HTMLElement | null>(null);
  const documentScrollContainerRef = useRef<HTMLElement | null>(null);

  const agreement = useAgreement(agreementId);
  const activeTabName = useSelector(getAgreementSidebarActiveTabNameSelector);
  const [shouldFadeOutCountBadge, setShouldFadeOutCountBadge] = useState<boolean>(false);
  const [lastSeenAudit, setLastSeenAudit] = useState<string | undefined>(undefined);
  const { accordionStates, updateAccordionStates } = useAccordionStates();

  const { allMessages, resolvedAnnotations } = useCurrentMessages();
  const seenThreads = useSelector(getSeenMessageThreadsSelector);
  const isUpdateSeen = useCallback((message: Message) => (
    !message || seenThreads.includes(message.id) || seenThreads.includes(message.parent?.id)
  ), [seenThreads]);

  const newMessagesCount = useMemo(() => {
    if (!agreement || isEmpty(allMessages)) {
      return 0;
    }

    return allMessages.filter((message: Message) => isNewUpdate(agreement, message)
      && !isUpdateSeen(message)
      && !isCommentResolved(message)
      && !isSuggestionResolved(message)
      && !isParentResolved(message, resolvedAnnotations)).length;
  }, [
    agreement,
    allMessages,
    isUpdateSeen,
    resolvedAnnotations,
  ]);

  const eventsList = useSelector((state: RootState) => state.currentContract.eventsList);

  const newAuditTrailCount = useMemo<number>(() => {
    if (!agreement) {
      return 0;
    }

    const newEvents = eventsList.filter((event: { id: number }) => isNewUpdate(agreement, event));
    if (lastSeenAudit) {
      return newEvents.filter((event) => moment(event.created)
        .isAfter(moment(lastSeenAudit))).length;
    }
    return newEvents.length;
  }, [agreement, eventsList, lastSeenAudit]);

  useEffect(() => {
    if ((activeTabName === MESSAGES_TAB
      || activeTabName === AUDIT_TRAIL_TAB)
      && !shouldFadeOutCountBadge) {
      setShouldFadeOutCountBadge(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabName]);

  const contextValue = useMemo(() => ({
    accordionStates,
    documentContentRef,
    documentContentWrapperCallback,
    documentContentWrapperNode,
    documentLayoutRef,
    documentScrollContainerRef,
    lastSeenAudit,
    newAuditTrailCount,
    newMessagesCount,
    setLastSeenAudit,
    shouldFadeOutCountBadge,
    updateAccordionStates,
  }), [
    accordionStates,
    documentLayoutRef,
    lastSeenAudit,
    newAuditTrailCount,
    newMessagesCount,
    setLastSeenAudit,
    shouldFadeOutCountBadge,
    updateAccordionStates,
    documentContentWrapperNode,
  ]);

  return (
    <DocumentLayoutContext.Provider value={contextValue}>
      {children}
    </DocumentLayoutContext.Provider>
  );
};

export const useDocumentLayout = (): DocumentLayoutContextType => {
  const contextValue = useContext(DocumentLayoutContext);

  if (!contextValue) {
    return {
      documentContentRef: { current: null },
      documentContentWrapperCallback: () => undefined,
      documentContentWrapperNode: null,
      documentLayoutRef: { current: null },
      documentScrollContainerRef: { current: null },
      newAuditTrailCount: 0,
      newMessagesCount: 0,
      shouldFadeOutCountBadge: false,
      accordionStates: {},
      updateAccordionStates: () => undefined,
      setLastSeenAudit: () => undefined,
    };
  }

  return contextValue;
};
