// @flow

import * as React from 'react';
import { Redirect } from 'react-router';
import urlJoin from 'url-join';
import { Message } from '@oneflowab/pomes';
import type { Match } from 'react-router';

import adminPage from 'hocs/admin-page';

import DeleteIcon from 'components/icons/delete';
import All from 'components/all-contracts';
import Trash from 'routes/contracts/trash';
import { checkAcl } from 'components/acl';

export type Props = AdminPageComponentProps & {
  match: Match,
  dateFormat: string,
};

export const ContractsComponent = (props: Props) => (
  <Redirect
    from={props.match.path}
    to={urlJoin(props.match.url, '/all')}
  />
);

const getContractsModules = ({
  currentUser,
  dateFormat,
  message,
  account,
  isTrashEnabled,
}: any) => {
  let contractsModules = [
    {
      component: All,
      currentUser,
      dateFormat,
      hideBreadCrumb: true,
      isMainPage: true,
      hideImport: true,
      id: 'all-tab',
      path: '/all',
      section: message({ id: 'Search', comment: 'Used as the title for the section.' }),
      sendWorkspaceIds: true,
      shouldShowWorkspacesFilter: true,
      showWorkspaceName: true,
      title: message({ id: 'Documents', comment: 'Tab title. Showing all contracts in the contract list.' }),
      withoutWorkspaceId: true,
    },
  ];

  if (checkAcl(account.acl, 'account:agreement:trash:view') && isTrashEnabled) {
    contractsModules = [
      ...contractsModules,
      {
        component: Trash,
        currentUser,
        dateFormat,
        hideBreadCrumb: true,
        isMainPage: true,
        hideImport: true,
        id: 'trash-all-tab',
        path: '/trash',
        section: message({ id: 'Search', comment: 'Used as the title for the section.' }),
        sendWorkspaceIds: true,
        shouldShowWorkspacesFilter: true,
        showWorkspaceName: true,
        title: (
          <div style={{ display: 'flex', gap: '4px' }}>
            <DeleteIcon width="14px" />
            <Message id="Trash" comment="Tab title. Showing all contracts in the contract list." />
          </div>
        ),
        withoutWorkspaceId: true,
      },
    ];
  }

  return contractsModules;
};

export const contractsModule = ({
  props: {
    currentUser,
    dateFormat,
    message,
    account,
    isTrashEnabled,
  },
}: any) => ({
  title: message({
    id: 'Contracts',
    comment: 'The title of the page. Contract search module.',
  }),
  showAsLink: false,
  modules: [getContractsModules({
    currentUser,
    dateFormat,
    message,
    account,
    isTrashEnabled,
  })],
});

export default adminPage(contractsModule)(ContractsComponent);
