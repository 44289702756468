import { Message } from '@oneflowab/pomes';
import type { MouseEventHandler } from 'react';

import { checkAcl } from 'components/acl';
import { MenuItem } from 'components/menu-item';
import CopyContractModal from 'components/modals/copy-contract';
import CreateContract from 'components/icons/create-contract';

export type Props = {
  agreement: Oneflow.Agreement,
  workspace: Oneflow.Workspace,
};

const CreateTemplateFromContract = ({
  agreement,
  workspace,
}: Props) => (
  <CopyContractModal agreement={agreement} amplitudeScope="contract view - create template modal" isCreatingTemplate>
    {(onClick: MouseEventHandler) => (
      <MenuItem
        icon={CreateContract}
        label={(
          <Message
            id="Save as template"
            comment="Label for create template action in menu."
          />
        )}
        disabled={!checkAcl(workspace.acl, 'collection:agreement:template:create')}
        onClick={onClick}
      />
    )}
  </CopyContractModal>
);

export default CreateTemplateFromContract;
