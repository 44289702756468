import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { FocusEventHandler } from 'react';
import { Transforms } from 'slate';
import type{ Editor } from 'slate';
import useFocusedEditor from 'hooks/rich-text-editor/use-focused-editor';
import { setFocusedEditor } from 'reducers/editor';

const useOnEditableFocus = (editor: Editor): FocusEventHandler<HTMLDivElement> => {
  const dispatch = useDispatch();
  const focusedEditor = useFocusedEditor();
  const onFocus = useCallback(() => {
    if (focusedEditor && editor !== focusedEditor) {
      Transforms.deselect(focusedEditor);
    }
    dispatch(setFocusedEditor(editor));
  }, [dispatch, editor, focusedEditor]);
  return onFocus;
};
export default useOnEditableFocus;
