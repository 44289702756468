// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import agreementTemplatesReducer from 'reducers/entities/agreement-templates';
import { DeleteTemplates, type Props } from './delete-templates';

const requestName = 'bulk';

type StateProps = {|
  removeState: RemoveState,
|};

type OwnProps = $Diff<Props, StateProps> & {|
  selectedAgreementTemplates: Array<AgreementTemplate>,
  queryName: string,
|};

type DispatchProps = {|
  deleteTemplates: () => void,
  resetRemoveState: () => void,
|};

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  removeState: agreementTemplatesReducer.getRemoveSelector(state, { id: requestName }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  selectedAgreementTemplates,
  queryName,
}) => ({
  deleteTemplates: () => {
    const agreementIds = selectedAgreementTemplates.map((template) => template.agreement.id);
    const templateIds = selectedAgreementTemplates.map((template) => template.id);

    dispatch(agreementTemplatesReducer.removeAgreementTemplate({
      id: requestName,
      data: {
        ids: agreementIds,
        templateIds,
      },
      pipe: {
        action: () => (
          agreementTemplatesReducer.queryAgreementTemplatesReload({
            name: queryName,
          })
        ),
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(agreementTemplatesReducer.removeAgreementTemplateReset({
      id: requestName,
    }));
  },
});

const connectedDeleteTemplates = connect<
Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteTemplates);

export { connectedDeleteTemplates as DeleteTemplates };
