// @flow

import * as React from 'react';

import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import { checkAcl } from 'components/acl';
import BreadCrumb from 'components/bread-crumb';
import ExtensionSidebarMainAction from 'components/extension/extension-sidebar-main-action';
import { ExtensionName } from 'components/extension/extension-name';
import ExtensionSidebarStatus from 'components/extension/extension-sidebar-status';
import { ExtensionContentOverview } from 'components/extension/extension-content-overview';
import { ExtensionContentKeyTakeaways } from 'components/extension/extension-content-key-takeaways';
import { ExtensionContentFaqs } from 'components/extension/extension-content-faqs';
import { ExtensionContentHelpTopics } from 'components/extension/extension-content-help-topics';
import { ExtensionLogo } from 'components/extension/extension-logo';
import { ExtensionOwner } from 'components/extension/extension-owner';

import style from './extension-settings.module.scss';

type WrappedProps = {
  [any]: any,
};

type Parent = {
  path: string,
  showAsLink?: boolean,
  title: string,
};

export const mapToLink = (parent: Parent) => ({
  to: parent.path,
  label: parent.title,
  showAsLink: parent.showAsLink,
});

type DecoratedComponent =
  | Class<React.Component<WrappedProps, any>>
  | ((WrappedProps) => React.Node);

type ComponentProps = {
  extension: Extension,
  toggleExtensionState: Extension => void,
  resetUpdateState: (arg0: number) => void;
  updateState: { loading: boolean },
  message: MessageTranslator,
  parents: Array<Parent>,
};

export const getExtensionSettingsComponent = (
  WrappedComponent: React.ComponentType<ComponentProps>,
) => {
  class SettingsPage extends React.PureComponent<ComponentProps> {
    handleStateChange = () => {
      const { extension, toggleExtensionState } = this.props;

      toggleExtensionState(extension);
    };

    renderConfiguration() {
      const { extension } = this.props;
      if (!extension.state) {
        return null;
      }

      // Do not show config to users without permissions
      if (!checkAcl(extension.acl, 'extension:config:view', { match: 'any' })) {
        return null;
      }

      return (
        <WrappedComponent
          {...this.props}
        />
      );
    }

    renderContent() {
      const { extension } = this.props;

      return (
        <>
          <ExtensionContentOverview extension={extension} />
          <ExtensionContentKeyTakeaways extension={extension} />
          <ExtensionContentFaqs extension={extension} />
          <ExtensionContentHelpTopics extension={extension} />
        </>
      );
    }

    renderSidePanelContent() {
      const { extension } = this.props;

      return (
        <>
          <ExtensionSidebarStatus extension={extension} />
          <ExtensionSidebarMainAction
            extension={extension}
            onStateChange={this.handleStateChange}
            updateState={this.props.updateState}
            resetUpdateState={this.props.resetUpdateState}
          />
        </>
      );
    }

    render() {
      const {
        parents,
        extension,
      } = this.props;

      if (!extension) {
        return null;
      }

      return (
        <div className={style.ExtensionSettings}>
          <span className={style.Title}>
            <Message
              id="Marketplace"
              comment="Used as the title for the section."
            />
          </span>
          <BreadCrumb
            label={<ExtensionName extension={extension} />}
            links={parents.map(mapToLink)}
          />
          <div className={style.MainPage}>
            <div className={style.Container}>
              <div className={style.ExtensionContentContainer}>
                <div className={style.ExtensionTop}>
                  <div className={style.ExtensionLogo}>
                    <ExtensionLogo
                      extension={extension}
                      className={style.ExtensionLogoSquare}
                    />
                  </div>

                  <div className={style.ExtensionInfo}>
                    <h2 className={style.ExtensionTitle} data-testid="title">
                      <ExtensionName extension={extension} />
                    </h2>
                    <div className={style.ExtensionSubTitle}>
                      <ExtensionOwner extension={extension} />
                    </div>
                  </div>
                </div>

                <div className={style.ExtensionContent}>
                  {this.renderContent()}
                </div>

                <div className={style.ExtensionConfiguration}>
                  {this.renderConfiguration()}
                </div>
              </div>

              <div className={style.ExtensionSidebarContainer}>
                <div className={style.ExtensionSidebarFloatingContent}>
                  {this.renderSidePanelContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return SettingsPage;
};

const extensionSettings = (
  WrappedComponent: DecoratedComponent,
) => (
  localize<ComponentProps>(getExtensionSettingsComponent(WrappedComponent))
);

export default extensionSettings;
