// @flow

import React, {
  useEffect, useState, useRef, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Message } from '@oneflowab/pomes';
import autosize from 'autosize';

import useAPIError from 'components/document-tabs/settings-tab/hooks/use-api-error';
import useAgreement from 'hooks/use-agreement';
import useFeatureFlag from 'hooks/use-feature-flag';
import useFormatting from 'components/contract-boxes/box-wrapper/hooks/use-formatting';
import agreements from 'reducers/entities/agreements';
import { hexToRgb } from 'utils/color-type-conversion';

import { checkAcl } from 'components/acl';
import Divider from 'components/divider';
import SelectField from 'components/select-field';
import { ColorPickerField } from 'components/color-picker-field';
import { FontSelectField } from 'components/font-select-field';
import FieldTooltip from 'components/document-tabs/settings-tab/sections/formatting-options/field-tooltip';

import style from './formatting-options.module.scss';
import { isNotDefaultFormatting, getFontSelectorLabel, getAgreementFontValues } from './formatting-font-helper';

type FontFamily = { label: string, value: string }

type Props = {
  agreementId?: number,
}

const FormattingOptions = ({
  agreementId,
}: Props) => {
  const dispatch = useDispatch();
  const formattingDivRef = useRef(null);
  const formattingStyles = useFormatting(agreementId);
  const agreementFromRedux = useAgreement(agreementId);
  const { resetRPCStates } = useAPIError(agreementId);

  const agreementFormatting = agreementFromRedux?.config?.formatting;

  const agreementFontValues = getAgreementFontValues(agreementFormatting?.font);
  const agreementFontLabel = getFontSelectorLabel(agreementFormatting?.font[0]);

  useEffect(() => {
    const textareas = Array.from(document.querySelectorAll('[data-id].form-field textarea'));

    textareas.forEach((element) => {
      setTimeout(() => autosize.update(element));
    });
  }, [agreementFormatting?.size, agreementFromRedux?.data]);

  const [formattingValues, setFormattingValues] = useState({
    font: { label: agreementFontLabel, value: agreementFontValues },
    color: agreementFormatting?.color || '#000000',
    size: agreementFormatting?.size || 14,
  });

  const setDocumentBodyStyle = useCallback((formatType, formatValue) => {
    switch (formatType) {
      case 'color': {
        const { r = 0, g = 0, b = 0 } = hexToRgb(formatValue);
        document.body.style.setProperty(
          '--formatting-font-color',
          formatValue,
        );
        document.body.style.setProperty(
          '--formatting-font-color-70-percent',
          `rgba(${r}, ${g}, ${b}, 0.7)`,
        );
        break;
      }
      case 'size':
        document.body.style.setProperty(
          '--formatting-font-size',
          `${formatValue}px`,
        );
        break;
      case 'font':
        document.body.style.setProperty(
          '--formatting-font-family',
          formatValue?.value,
        );
        break;
      default:
    }
  }, []);

  const saveFormatting = useCallback(async (formatType, formatValue) => {
    const formattedRequest = {
      color: formattingValues.color,
      size: formattingValues.size,
      font: formattingValues.font.value.split(', '),
    };

    if (formatType === 'color') {
      formattedRequest.color = formatValue;
    }

    if (formatType === 'font') {
      formattedRequest.font = formatValue.value.split(', ');
    }

    if (formatType === 'size') {
      formattedRequest.size = formatValue;
    }

    setDocumentBodyStyle(formatType, formatValue);

    resetRPCStates();
    dispatch(agreements.updateConfig({
      id: agreementId,
      data: {
        formatting: formattedRequest,
      },
      pipe: {
        onFailure: () => {
          setDocumentBodyStyle(formatType, agreementFormatting[formatType]);
        },
      },
    }));
  }, [
    agreementId,
    dispatch,
    formattingValues,
    setDocumentBodyStyle,
    agreementFormatting,
    resetRPCStates,
  ]);

  const showFormatting = useMemo(
    () => isNotDefaultFormatting(agreementFormatting),
    [agreementFormatting],
  );
  const canChangeFormatting = checkAcl(agreementFromRedux.acl, 'agreement:update:config:formatting');

  const isTypographyFeatureFlagEnabled = useFeatureFlag('temporaryTypography');
  const [isDisabled, setIsDisabled] = useState(!canChangeFormatting);

  useEffect(() => {
    if (showFormatting && !isTypographyFeatureFlagEnabled) {
      setIsDisabled(true);
    }
  }, [showFormatting, isTypographyFeatureFlagEnabled]);

  if (!isTypographyFeatureFlagEnabled) {
    return null;
  }

  const predefinedFontSizes = [
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '14', value: 14 },
    { label: '16', value: 16 },
    { label: '18', value: 18 },
    { label: '24', value: 24 },
  ];

  // General rule is capitalized and spaced. serif,sans-serif are exceptions
  const fontFamilyOptions = [
    { label: 'Arimo', value: 'Arimo, sans-serif' },
    { label: 'Questrial', value: 'Questrial, sans-serif' },
    { label: 'Anton', value: 'Anton, sans-serif' },
    { label: 'Poppins', value: 'Poppins, sans-serif' },
    { label: 'Proxima Nova', value: 'Proxima Nova, sans-serif' },
    { label: 'Roboto', value: 'Roboto, sans-serif' },
    { label: 'Raleway', value: 'Raleway, sans-serif' },
    { label: 'Jost', value: 'Jost, sans-serif' },
    { label: 'Inter', value: 'Inter, sans-serif' },
    { label: 'IBM Plex Sans', value: 'IBM Plex Sans, sans-serif' },
    { label: 'Source Sans Pro', value: 'Source Sans Pro, sans-serif' },
    { label: 'Work Sans', value: 'Work Sans, sans-serif' },
    { label: 'IBM Plex Serif', value: 'IBM Plex Serif, serif' },
    { label: 'Gelasio', value: 'Gelasio, serif' },
    { label: 'Garamond', value: 'Garamond, serif' },
    { label: 'Tinos', value: 'Tinos, serif' },
    { label: 'Libre Baskerville', value: 'Libre Baskerville, serif' },
    { label: 'Courier Prime', value: 'Courier Prime, Monospace' },
    { label: 'IBM Plex Mono', value: 'IBM Plex Mono, Monospace' },
    { label: 'Comic Neue', value: 'Comic Neue, sans-serif' },
    { label: 'Caveat', value: 'Caveat, serif' },
    { label: 'Sacramento', value: 'Sacramento, serif' },
  ];

  const onChangeColor = (color: string) => {
    setFormattingValues({ ...formattingValues, color });
    saveFormatting('color', color);
  };

  const onChangeFontFamily = (font: FontFamily) => {
    setFormattingValues({ ...formattingValues, font });
    saveFormatting('font', font);
  };

  const onChangeFontSize = ({ value }) => {
    setFormattingValues({ ...formattingValues, size: value });
    saveFormatting('size', value);
  };

  const getMessage = () => (
    <p className={style.FormattingInfo}>
      <Message
        id="Applies to the entire document except for parts formatted manually."
        comment="Description for formatting section within a contract"
      />
    </p>
  );

  const renderBody = () => (
    <div className={style.FormattingForm} ref={formattingDivRef} data-testid="form">
      <FieldTooltip hideContent={!isDisabled}>
        <FontSelectField
          selectedFontFamily={formattingValues.font}
          fontFamilyOptions={fontFamilyOptions}
          setSelectedFontFamily={onChangeFontFamily}
          disabled={isDisabled}
          initialFontFamily={formattingValues.font}
        />
      </FieldTooltip>
      <FieldTooltip hideContent={!isDisabled}>
        <SelectField
          label={(
            <span className={style.Label}>
              <Message
                id="Size"
                comment="Label for field where you select your preferred font size"
              />
            </span>
          )}
          options={predefinedFontSizes}
          input={{
            value: formattingValues.size,
            name: 'size',
            onChange: onChangeFontSize,
          }}
          disabled={isDisabled}
          labelKey="label"
          multi={false}
          searchable={false}
          clearable={false}
          hideErrorElement
        />
      </FieldTooltip>
      <FieldTooltip hideContent={!isDisabled}>
        <ColorPickerField
          color={formattingValues.color}
          setColor={onChangeColor}
          disabled={isDisabled}
        />
      </FieldTooltip>
    </div>
  );

  return (
    <>
      <div
        className={clsx({
          [style.DisabledFormatting]: isDisabled,
        })}
        data-testid="formatting-options-container"
        style={formattingStyles}
      >
        {getMessage()}
        {renderBody()}
      </div>
      <Divider solid className={style.Divider} />
    </>
  );
};

export default FormattingOptions;
