/* eslint-disable react/prop-types */

import React from 'react';

const PropagationBoundary = ({ children }) => {
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      onClick={stopPropagation}
      onKeyDown={() => null}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export default PropagationBoundary;
