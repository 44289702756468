import { connect } from 'react-redux';

import groupPositionsReducer from 'reducers/entities/group-positions';

import { RemoveMember } from './remove-member';

export const mapStateToProps = (state, { group }) => ({
  removeState: groupPositionsReducer.getRemoveSelector(state, { id: group.id }),
});

export const mapDispatchToProps = (dispatch, { group, position }) => ({
  removeMember: () => {
    dispatch(groupPositionsReducer.removeGroupPosition({
      id: group.id,
      data: {
        positionIds: [position.id],
        groupId: group.id,
      },
      pipe: {
        action: () => groupPositionsReducer.queryGroupPositionsReload({
          name: `admin/groups/${group.id}/members`,
        }),
        wait: groupPositionsReducer.QUERY_GROUP_POSITIONS_SUCCESS,
      },
    }));
  },
  resetRemoveState: () => {
    dispatch(groupPositionsReducer.removeGroupPositionReset({
      id: group.id,
    }));
  },
});

const connectedRemoveMember = connect(mapStateToProps, mapDispatchToProps)(RemoveMember);

export { connectedRemoveMember as RemoveMember };
