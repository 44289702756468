import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import { KPI } from 'components/kpis/kpi/kpi-container';
import type { DashboardFilters } from 'components/kpis/kpi';

type Props = {
  message: MessageTranslator,
  count: number,
  value: number,
  filters: DashboardFilters,
}

export const KPIDeclinedComponent = ({
  message,
  count,
  value,
  filters,
}: Props) => (
  <KPI
    label={message({
      id: 'Declined',
      comment: 'Dashboard KPI for declined documents',
    })}
    type="Declined"
    count={count}
    tooltip={message({
      id: 'The number of declined documents that were sent during the selected date range.',
      comment: 'Dashboard KPI tooltip message',
    })}
    agreementValueAmount={value}
    filters={filters}
  />
);

export default localize<Props>(KPIDeclinedComponent);
