// @flow
import React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './video-dropzone-upload-space.module.scss';

type Props = {
  fileRejections?: Array<File>
}

const VideoDropzoneUploadSpace = ({ fileRejections }: Props) => {
  if (fileRejections.length > 1) {
    return (
      <Message
        id="You can only select one video"
        comment="Info text to be display to the user when trying to upload a file of an unaccepted type"
      />
    );
  }

  if (fileRejections.length === 1) {
    return (
      <>
        <div>
          <Message
            id="Invalid file"
            comment="Info text to be display to the user when trying to upload a file of an unaccepted type"
          />
        </div>
        <div>
          <Message
            id="Select a valid video file smaller than 120MB"
            comment="Info text to be display to the user when trying to upload a file of an unaccepted type or size"
          />
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      <div className={style.MessageTop}>
        <Message
          id="Drop file here or click to upload"
          comment="Text for upload area instructing where to drop files for processing"
        />
      </div>
      <div className={style.MessageBottom}>
        <div>
          <Message
            id="Uploading a new video will replace the existing one. We support files up to 120MB."
            comment="Information and instructions for uploading videos"
          />
        </div>
        <div>
          <Message
            id="If your video is longer than 5 minutes, it will be cropped to 5 minutes."
            comment="Information and instructions for uploading videos"
          />
        </div>
        <div className={style.ImageRecommendation}>
          <Message
            id="For best result we recommend the 16:9 aspect ratio."
            comment="Information about supported file formats and dimensions"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default VideoDropzoneUploadSpace;
