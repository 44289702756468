// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import hasSigned from 'agreement/participant/has-signed';
import hasDeclined from 'agreement/participant/has-declined';
import hasSignMethodMarkedAsSigned from 'agreement/participant/has-sign-method-marked-as-signed';

import { EventLog } from './event-log';

type Props = {
  agreement: Agreement,
  participant: AgreementParticipant,
  isContractMarkedAsSigned: boolean,
  dateFormat: string,
};

const DecisionInfo = ({
  agreement,
  participant,
  isContractMarkedAsSigned,
  dateFormat,
}: Props) => {
  const getDecisionInfo = () => {
    if (isContractMarkedAsSigned) {
      return (
        <Message
          id="Marked as signed"
          comment="Participant decision on the agreement. Seen in a tooltip in the contract list"
        />
      );
    }

    if (hasSigned(participant)) {
      return (
        <Message
          id="Signed"
          comment="Participant decision on the agreement. Seen in a tooltip in the contract list"
        />
      );
    }

    if (hasDeclined(participant)) {
      return (
        <Message
          id="Declined"
          comment="Participant decision on the agreement. Seen in a tooltip in the contract list"
        />
      );
    }

    return null;
  };

  const getDecisionTimestamp = () => {
    if (isContractMarkedAsSigned) {
      return agreement.stateTimestampTs;
    }

    return participant.stateTimestampTs;
  };

  if (isContractMarkedAsSigned && !hasSignMethodMarkedAsSigned(participant)) {
    return null;
  }

  const decisionInfo = getDecisionInfo();

  if (!decisionInfo) {
    return null;
  }

  return (
    <EventLog
      label={decisionInfo}
      timestamp={getDecisionTimestamp()}
      dateFormat={dateFormat}
    />
  );
};

export default DecisionInfo;
