import type { ReactNode, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import { isContractPristine } from 'reducers/current-contract';
import useAgreement from 'hooks/use-agreement';

import Button from 'components/button/button-fc';
import Tooltip from 'components/tooltip';

import style from './send-button.module.scss';

type SendButtonProps = {
  agreementId: number,
  customClassName?: string,
  onClick: (event: SyntheticEvent) => void,
  isAgreementDraft: boolean,
  hasPermissionToPublish: boolean,
  children: ReactNode,
  disabled?: boolean,
  icon?: ReactNode,
};

const SendButton = ({
  agreementId,
  customClassName,
  onClick,
  disabled,
  isAgreementDraft,
  hasPermissionToPublish,
  children,
  icon,
}: SendButtonProps) => {
  const agreement = useAgreement(agreementId);
  const isPristine = useSelector(isContractPristine);
  const sendButton = (
    <Button
      customClass={clsx(
        style.PublishButton,
        customClassName,
      )}
      onClick={onClick}
      kind="send"
      disabled={disabled}
      icon={icon}
    >
      {children}
    </Button>
  );

  if (isAgreementDraft && !hasPermissionToPublish && Boolean(agreement.config?.signOrder)) {
    return (
      <Tooltip
        message={(
          <Message
            id="Current plan doesn't support signing order, disable signing order to send document."
            comment="Tool tip message for why the send button is disabled."
          />
        )}
        theme="oneflow"
        side="top"
      >
        {sendButton}
      </Tooltip>
    );
  }

  if (!isPristine) {
    return (
      <Tooltip
        zIndex="10004"
        message={(
          <Message
            id="You need to save first"
            comment="Tool tip message for why the send button is disabled."
          />
        )}
        theme="oneflow"
        side="top"
        messageClassName={style.TooltipMessage}
        sideOffset={7.5}
      >
        <div>
          {sendButton}
        </div>
      </Tooltip>
    );
  }

  return sendButton;
};

export default SendButton;
