import React from 'react';
import { Message } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import type { MessageTranslator } from '@oneflowab/pomes';

import { updatePriceColumnsAction } from 'reducers/current-contract';
import { useProductTableBoxContext } from 'contexts/product-table-box-context';
import type {
  Column,
  ConfigUpdateData,
} from 'data-validators/entity-schemas/document-box/product-table';

import { MenuItem } from 'components/menu-item';
import {
  PRODUCT_TABLE_COLUMNS,
  PRODUCT_TABLE_META_DATA,
  PRODUCT_TABLE_NAME,
} from 'components/contract-boxes/product-table-box/constants';
import NewCheckIcon from 'components/icons/new-check';

import style from './product-table-actions.module.scss';

type Props = {
  updateProductConfig: (configData: ConfigUpdateData) => void,
  message: MessageTranslator,
};

const ProductTableBoxActions = ({ updateProductConfig, message }: Props) => {
  const dispatch = useDispatch();
  const {
    columns,
    config,
    isAllowedToUpdateBoxConfig,
  } = useProductTableBoxContext();
  const { hideSum, header } = config;
  const defaultLabels = [
    {
      key: PRODUCT_TABLE_COLUMNS.NAME,
      label: message({
        id: 'Product',
        comment: 'A message to select or deselect the visibility of the product name column in the contract product table box.',
      }),
    },
    {
      key: PRODUCT_TABLE_COLUMNS.DESCRIPTION,
      label: message({
        id: 'Description',
        comment: 'A message to select or deselect the visibility of the description column in the contract product table box.',
      }),
    },
    {
      key: PRODUCT_TABLE_COLUMNS.PRICE_1,
      label: message({
        id: 'Price 1',
        comment: 'A message to select or deselect the visibility of the price 1 column in the contract product table box.',
      }),
    },
    {
      key: PRODUCT_TABLE_COLUMNS.PRICE_2,
      label: message({
        id: 'Price 2',
        comment: 'A message to select or deselect the visibility of the price 2 column in the contract product table box.',
      }),
    },
    {
      key: PRODUCT_TABLE_COLUMNS.COUNT,
      label: message({
        id: 'Quantity',
        comment: 'A message to select or deselect the visibility of the quantity column in the contract product table box.',
      }),
    },
    {
      key: PRODUCT_TABLE_META_DATA.PRICE_SUM,
      label: message({
        id: 'Price summation',
        comment: 'A message to select or deselect the visibility of the price summation in the contract product table box.',
      }),
    },
  ];

  const toggleColumnVisibility = (columnKey: string) => {
    const updatedColumns = columns.map((column) => {
      if (column.key === columnKey) {
        return {
          ...column,
          enabled: !column.enabled,
        };
      }
      return column;
    });

    const updatedColumnVisibilityConfig = {
      ...config,
      columns: updatedColumns,
    };
    updateProductConfig(updatedColumnVisibilityConfig);
    dispatch(updatePriceColumnsAction());
  };

  const enabledColumns = columns?.filter((column) => column?.enabled).map((column) => column?.key);

  const BlankIcon = (): JSX.Element => <div className={style.BlankIcon} />;

  const getLabel = (column: Column) => {
    const correspondingLabel = defaultLabels.find((item) => item.key === column.key);
    const defaultLabel = correspondingLabel?.label;

    const shouldShowEllipsis = column?.label?.length > 20;
    const customLabelClasses = clsx({ [style.CustomLabel]: shouldShowEllipsis });

    if (!column.label || column.label === defaultLabel) {
      return defaultLabel;
    }

    const labels = (
      <div className={customLabelClasses}>
        <span>
          {defaultLabel}
          {' '}
        </span>
        <span>
          (
          {column.label}
          )
        </span>
      </div>
    );
    return labels;
  };

  const getMenuItems = (column: Column) => {
    const actionLabel = getLabel(column);
    const isThisColumnEnabled = enabledColumns?.includes(column.key);
    const onlyOneColumnEnabled = (enabledColumns?.length || 0) === 1;
    const onlyThisColumnEnabled = isThisColumnEnabled && onlyOneColumnEnabled;
    const isDisabled = !isAllowedToUpdateBoxConfig || onlyThisColumnEnabled;

    return (
      <MenuItem
        key={column.key}
        icon={isThisColumnEnabled ? NewCheckIcon : BlankIcon}
        label={actionLabel}
        onClick={() => toggleColumnVisibility(column.key)}
        disabled={isDisabled}
        data-testid={`${column.key}-menu-item`}
      />
    );
  };

  const menuItems = columns?.map((column: Column) => getMenuItems(column));

  const priceSumLabel = defaultLabels
    .find((item) => item.key === PRODUCT_TABLE_META_DATA.PRICE_SUM);
  const isPriceSumDisabled = (!enabledColumns?.includes(PRODUCT_TABLE_COLUMNS.PRICE_1)
    && !enabledColumns?.includes(PRODUCT_TABLE_COLUMNS.PRICE_2));

  const productTableNameMenuItem = (
    <MenuItem
      key={PRODUCT_TABLE_NAME.NAME}
      icon={header?.enabled ? NewCheckIcon : BlankIcon}
      label={(
        <Message
          id="Product table name"
          comment="A message to select or deselect the visibility of the product table name in the product table box."
        />
      )}
      onClick={() => updateProductConfig({
        ...config,
        header: {
          ...header,
          enabled: !header?.enabled,
        },
      })}
      disabled={!isAllowedToUpdateBoxConfig}
    />
  );

  const priceSumMenuItem = priceSumLabel && (
    <MenuItem
      key={priceSumLabel.key}
      icon={!hideSum ? NewCheckIcon : BlankIcon}
      label={priceSumLabel.label}
      onClick={() => updateProductConfig({ ...config, hideSum: !hideSum })}
      disabled={!isAllowedToUpdateBoxConfig || isPriceSumDisabled}
      data-testid="price-sum-menu-item"
    />
  );

  return (
    <React.Fragment>
      {[productTableNameMenuItem, ...menuItems, priceSumMenuItem]}
    </React.Fragment>
  );
};

export default ProductTableBoxActions;
