import {
  call,
  select,
  takeEvery,
  debounce,
  put,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import localStorage from 'utils/local-storage';
import sessionStorage from 'utils/session-storage';
import { isUserOnAdminPage, isUserOnGlobalSearchPage } from 'utils/isOnPage';

import { requestMe } from 'sagas/me';

import {
  getPositionFromSessionSelector,
  getAllWorkspacesFromSessionSelector,
} from 'reducers/session';
import {
  changeCurrentWorkspace as changeCurrentWorkspaceAction,
  setCurrentWorkspace as setCurrentWorkspaceAction,
  setDefaultWorkspace as setDefaultWorkspaceAction,
  getCurrentWorkspaceIdSelector,
  SET_DEFAULT_WORKSPACE,
  SET_CURRENT_WORKSPACE,
  CHANGE_CURRENT_WORKSPACE,
  SELECT_WORKSPACE,
} from 'reducers/app';
import { getLocationSelector, LOCATION_CHANGE } from 'reducers/router';

import { isAValidWorkspaceId } from 'reducers/entities/workspaces';

import { readWorkspaceIdFromLocation } from 'hocs/with-current-workspace';

export function* saveDefaultWorkspaceIdInLocalStorage(action) {
  const position = yield select(getPositionFromSessionSelector);

  yield call(
    localStorage.setItem,
    `position-${position.id}:default-collection-id`,
    action.workspaceId,
  );
}

export function* saveCurrentWorkspaceIdInSessionStorage(action) {
  const position = yield select(getPositionFromSessionSelector);

  yield call(
    sessionStorage.setItem,
    `position-${position.id}:current-collection-id`,
    action.workspaceId,
  );
}

export function* extractWorkspaceIdFromUrl() {
  const location = yield select(getLocationSelector);

  return yield call(readWorkspaceIdFromLocation, location);
}

export function* navigateToWorkspace(workspaceId) {
  let workspaceIdFromUrl = yield call(extractWorkspaceIdFromUrl);

  const location = yield select(getLocationSelector);
  const { pathname } = location;
  const queryString = location?.search || '';

  const isOnGlobalSearchPage = isUserOnGlobalSearchPage(pathname);
  const isOnAdminPage = isUserOnAdminPage(pathname);
  const isOnPageWithNoWorkspaceId = isOnAdminPage || isOnGlobalSearchPage;

  workspaceIdFromUrl = isOnPageWithNoWorkspaceId ? workspaceId : workspaceIdFromUrl;

  if (
    !isAValidWorkspaceId(workspaceIdFromUrl)
    || (workspaceId === workspaceIdFromUrl && !isOnPageWithNoWorkspaceId)) {
    return;
  }

  const newNamespacedPath = pathname.replace(`/c/${workspaceIdFromUrl}/`, `/c/${workspaceId}/`);
  const newNamespacedUrl = newNamespacedPath + queryString;
  const workspaces = yield select(getAllWorkspacesFromSessionSelector);
  const workspaceIds = workspaces.map((workspace) => workspace.id);

  if (isAValidWorkspaceId(workspaceIdFromUrl) && workspaceIds.includes(workspaceIdFromUrl)) {
    yield put(push(newNamespacedUrl));
  } else {
    yield put(replace(newNamespacedUrl));
  }
}

export function* executeSetWorkspaceSideEffects(action) {
  yield call(saveCurrentWorkspaceIdInSessionStorage, action);
  yield call(navigateToWorkspace, action.workspaceId);
}

export function* readDefaultWorkspaceIdFromLocalStorage() {
  const position = yield select(getPositionFromSessionSelector);

  return yield call(localStorage.getInt, `position-${position.id}:default-collection-id`);
}

export function* readCurrentWorkspaceIdFromSessionStorage() {
  const position = yield select(getPositionFromSessionSelector);

  return yield call(sessionStorage.getInt, `position-${position.id}:current-collection-id`);
}

export function* getFirstAccessibleWorkspaceId(candidateWorkspaceId) {
  const workspaces = yield select(getAllWorkspacesFromSessionSelector);
  const workspaceIds = workspaces.map((workspace) => workspace.id);
  const currentWorkspaceIdFromSessionStorage = (
    yield call(readCurrentWorkspaceIdFromSessionStorage)
  );
  const defaultWorkspaceIdFromLocalStorage = yield call(readDefaultWorkspaceIdFromLocalStorage);
  const firstWorkspaceId = workspaces[0].id;

  const isWorkspaceIdAccessible = (workspaceId) => (
    isAValidWorkspaceId(workspaceId) && workspaceIds.includes(workspaceId)
  );

  let accessibleWorkspaceId = [
    candidateWorkspaceId,
    currentWorkspaceIdFromSessionStorage,
    defaultWorkspaceIdFromLocalStorage,
  ].find(isWorkspaceIdAccessible);

  if (!isAValidWorkspaceId(accessibleWorkspaceId)) {
    accessibleWorkspaceId = firstWorkspaceId;
  }

  return accessibleWorkspaceId;
}

export function* whitelistCurrentWorkspace(action) {
  yield call(requestMe);

  const accessibleWorkspaceId = yield call(getFirstAccessibleWorkspaceId, action.workspaceId);

  if (accessibleWorkspaceId !== action.workspaceId) {
    yield put(setCurrentWorkspaceAction({ workspaceId: accessibleWorkspaceId }));

    return;
  }

  yield call(executeSetWorkspaceSideEffects, action);
}

export function* selectWorkspaceSaga(action) {
  yield put(setDefaultWorkspaceAction({ workspaceId: action.workspaceId }));
  yield put(changeCurrentWorkspaceAction({ workspaceId: action.workspaceId }));
}

const isTheSamePath = (location, previousLocation) => {
  if (!previousLocation) {
    return false;
  }

  const { pathname } = location;
  const { pathname: previousPathname } = previousLocation;
  const workspaceNamespace = /\/c\/\d+\//;
  const pathnameWithoutNamespace = pathname.replace(workspaceNamespace, '');
  const previousPathnameWithoutNamespace = previousPathname.replace(workspaceNamespace, '');

  return pathnameWithoutNamespace === previousPathnameWithoutNamespace;
};

const isSearchPageNavigation = (location, previousLocation) => {
  if (!previousLocation) {
    return false;
  }

  const { pathname } = location;
  const { pathname: previousPathname } = previousLocation;
  const searchPageRegExp = /\/c\/\d+\/search\//;

  if (previousPathname.endsWith('/contracts')) {
    return false;
  }

  return searchPageRegExp.test(pathname) && searchPageRegExp.test(previousPathname);
};

export function* syncStateWithCurrentWorkspaceFromUrl({
  location,
  previousLocation,
  action: navigationAction,
}) {
  const workspaceIdFromUrl = yield call(readWorkspaceIdFromLocation, location);

  if (!isAValidWorkspaceId(workspaceIdFromUrl)) {
    return;
  }

  const currentWorkspaceFromState = yield select(getCurrentWorkspaceIdSelector);

  if (currentWorkspaceFromState === null) {
    return;
  }

  if (isTheSamePath(location, previousLocation) && navigationAction !== 'POP') {
    return;
  }

  if (isSearchPageNavigation(location, previousLocation)) {
    return;
  }

  yield put(changeCurrentWorkspaceAction({ workspaceId: workspaceIdFromUrl }));
}

export default function* currentWorkspace() {
  yield takeEvery(SET_DEFAULT_WORKSPACE, saveDefaultWorkspaceIdInLocalStorage);
  yield takeEvery(SET_CURRENT_WORKSPACE, executeSetWorkspaceSideEffects);
  yield takeEvery(CHANGE_CURRENT_WORKSPACE, whitelistCurrentWorkspace);
  yield takeEvery(SELECT_WORKSPACE, selectWorkspaceSaga);
  yield debounce(10, LOCATION_CHANGE, syncStateWithCurrentWorkspaceFromUrl);
}
