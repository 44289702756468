// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import ModalForm from 'hocs/modal-form';

import CircularSpinner from 'components/icons/circular-spinner';
import Field from 'components/field';
import TextField from 'components/text-field';

import style from './verify-device.module.scss';

export type FormData = {
  securityCode: string,
};

export type Props = {
  message: MessageTranslator,
  verifyDevice: FormData => void,
  verifyDeviceState: RpcState,
  resetVerifyDeviceState: () => void,
  requestMfaCode: () => void,
  requestMfaCodeState: RpcState,
  fetchPositionMfa: () => void,
  channelData: string,
  hasCode: boolean,
  isModalOpen: boolean,
  onModalClose: () => void,
};

class VerifyDevice extends React.Component<Props, State> {
  componentDidUpdate(prevProps: Props) {
    const { verifyDeviceState, fetchPositionMfa } = this.props;

    if (verifyDeviceState.success && !prevProps.verifyDeviceState.success) {
      fetchPositionMfa();
    }
  }

  handleVerificationRequest = () => {
    const { requestMfaCode, hasCode } = this.props;

    if (!hasCode) {
      requestMfaCode();
    }
  };

  renderBody() {
    const {
      message,
      channelData,
      verifyDeviceState,
      requestMfaCodeState,
    } = this.props;

    if (verifyDeviceState.loading || requestMfaCodeState.loading) {
      return (
        <div className={style.Loading}>
          <CircularSpinner />
        </div>
      );
    }

    return (
      <div>
        <p>
          <Message
            id="A verification code has ben sent to {mfaChannel}."
            comment="Modal text, explanation for phone number verification."
            values={{
              mfaChannel: <strong>{channelData}</strong>,
            }}
          />
        </p>
        <Field
          name="securityCode"
          label={message({
            id: 'Verification code',
            comment: 'Used as the label of the method field in the two-step authentication settings page',
          })}
          placeholder={message({
            id: 'Enter verification code',
            comment: 'Used as the placeholder of the code field in the two-step authentication phone verification modal',
          })}
          autoFocus
          component={TextField}
          maxLength={10}
          required
        />
      </div>
    );
  }

  render() {
    const {
      verifyDeviceState,
      verifyDevice,
      resetVerifyDeviceState,
      requestMfaCodeState,
      isModalOpen,
      onModalClose,
      message,
    } = this.props;

    return (
      <ModalForm
        title={message({
          id: 'Verify phone number',
          comment: 'Modal title, confirmation for phone number verification.',
        })}
        body={this.renderBody()}
        onSubmit={verifyDevice}
        resetFormState={resetVerifyDeviceState}
        formState={verifyDeviceState}
        icon={requestMfaCodeState.loading ? <CircularSpinner height="12px" /> : undefined}
        outline
        onOpen={this.handleVerificationRequest}
        isOpen={isModalOpen}
        onClose={onModalClose}
        modalKey="verify 2fa phone number modal"
      >
        <Message
          id="Verify number"
          comment="Button label for verifying phone number"
        />
      </ModalForm>
    );
  }
}

export default VerifyDevice;
