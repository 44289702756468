// @flow

import { checkAcl } from 'components/acl';
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
  requestMe,
  getPositionFromSessionSelector,
} from 'reducers/session';
import positions from 'reducers/entities/positions';

import Profile from './profile';

type ProfileForm = {
  email: string,
  fullname: string,
  language?: Option,
  passwordVerification?: string,
  phoneNumber?: string,
  signature?: string,
  title?: string,
};

export const mapStateToProps = (state: State) => {
  const position = getPositionFromSessionSelector(state);

  return ({
    updateState: positions.getUpdateSelector(state, { id: position.id }),
    actions: {
      start: positions.actions.types.updateStart,
      success: positions.actions.types.updateSuccess,
      fail: positions.actions.types.updateFail,
    },
    updateData: (formData: ProfileForm) => ({
      id: position.id,
      data: {
        ...formData,
        language: get(formData, 'language.value'),
        phoneNumber: checkAcl(position.acl, 'position:update:phone_number') ? formData.phoneNumber || null : undefined,
        signature: checkAcl(position.acl, 'position:update:signature') ? formData.signature || null : undefined,
        title: checkAcl(position.acl, 'position:update:title') ? formData.title || null : undefined,
      },
    }),
    position,
    positionId: position.id,
  });
};

export const mapDispatchToProps = (dispatch: Function) => ({
  resetUpdateState: ({ positionId }: { positionId: number }) => {
    dispatch(positions.updatePositionReset({ id: positionId }));
  },
  requestMe: () => {
    dispatch(requestMe());
  },
});

export default connect<Profile, State, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
