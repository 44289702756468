// @flow

import React from 'react';
import urlJoin from 'url-join';
import { Redirect } from 'react-router';

import type { Match } from 'react-router';

import adminPage from 'hocs/admin-page';

import Contacts from 'routes/address-book/contacts';
import { checkAcl } from 'components/acl';
import { NotFound } from 'components/errors';

type Props = AdminPageComponentProps & {
  acl: {
    workspace: Acl,
  },
  match: Match,
  workspace: Workspace,
};

export const AddressBookComponent = (props: Props) => {
  const handleRedirect = () => {
    const hasAccessToAddressBook = checkAcl(props.acl.workspace, 'collection:module:addressbook:use');
    if (hasAccessToAddressBook) {
      return (<Redirect from={props.match.path} to={urlJoin(props.match.url, '/contacts')} />);
    }
    const hasAccessToOwnDashboard = checkAcl(props.acl.workspace, 'collection:module:dashboard:use');
    if (hasAccessToOwnDashboard) {
      return (<NotFound redirect to="/dashboard" />);
    }
    window.location.assign('/c/0/contracts/all');
    return null;
  };

  return handleRedirect();
};

export default adminPage(({
  props: {
    message,
    workspace,
  },
}) => ({
  title: message({ id: 'Address book', comment: 'The title of the address book page, used on the breadcrumb and the browser page title' }),
  showAsLink: true,
  modules: [
    [
      {
        component: Contacts,
        hideBreadCrumb: true,
        isMainPage: true,
        path: '/contacts',
        section: message({
          id: 'Address book',
          comment: 'Used as the title for the section.',
        }),
        showWorkspace: true,
        title: message({
          id: 'Contacts',
          comment: 'Used as the title for the contacts tab on the address book page',
        }),
        workspace,
      },
    ],
  ],
}))(AddressBookComponent);
