// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import queryString from 'query-string';

import FilterBox from 'components/filter-box';
import * as agreementConstants from 'agreement/constants';
import style from './quick-filters.module.scss';

type Props = {
  message: MessageTranslator,
  location: Location,
  workspace?: Workspace,
};

const draftContractsStates = [
  agreementConstants.STATE_DRAFT,
];

const sentContractsStates = [
  agreementConstants.STATE_PENDING,
  agreementConstants.STATE_OVERDUE,
];

const signedContractsStates = [
  agreementConstants.STATE_SIGNED,
];

const ignoredOperators = ['positionOperator', 'tagOperator', 'folderIds', 'q'];
const getIncludedParams = (params) => params.filter(
  (param) => ignoredOperators.some((operator) => param.includes(operator)),
);

const getRedirectPath = (location: Location, contractStates: Array<number>) => {
  const searchParams = location.search?.split('&');
  searchParams[0] = searchParams[0].substring(1);

  const newSearchParams = [`${queryString.stringify({
    'state[]': contractStates.join(','),
  })}`, ...getIncludedParams(searchParams)];

  return `?${newSearchParams.join('&')}`;
};

const getRedirectPathMyTurnToSign = (location: Location, scope: 'last' | 'all') => {
  const searchParams = location.search?.split('&');
  searchParams[0] = searchParams[0].substring(1);

  const newSearchParams = [`${queryString.stringify({
    'signOrder[]': scope,
  })}`, ...getIncludedParams(searchParams)];

  return `?${newSearchParams.join('&')}`;
};

type FilterOption = {
  label: React.Node,
  path: string,
};

type GetOptions = (MessageTranslator, Location) => Array<FilterOption>;

const getOptions: GetOptions = (message, location) => ([
  {
    label: message({
      id: 'Drafts',
      comment: 'Quick filter label. Used to filter out drafted contracts',
    }),
    path: getRedirectPath(location, draftContractsStates),
  },
  {
    label: message({
      id: 'Sent',
      comment: 'Quick filter label. Used to filter out sent contracts',
    }),
    path: getRedirectPath(location, sentContractsStates),
  },
  {
    label: message({
      id: 'Signed',
      comment: 'Quick filter label. Used to filter out signed contracts',
    }),
    path: getRedirectPath(location, signedContractsStates),
  },
  {
    label: message({
      id: 'My turn to sign',
      comment: 'Quick filter label. Used to filter out contracts that are waiting for my signature',
    }),
    path: getRedirectPathMyTurnToSign(location, 'last'),
  },
  {
    label: message({
      id: 'I can sign',
      comment: 'Quick filter label. Used to filter out contracts that are waiting for my signature',
    }),
    path: getRedirectPathMyTurnToSign(location, 'all'),
  },
]);

export class QuickFilters extends React.Component<Props> {
  static defaultProps = {
    workspace: undefined,
  }

  getWorkspaceId = () => {
    const { workspace } = this.props;

    if (!workspace) {
      return undefined;
    }

    return workspace.id;
  }

  renderOption = (option: FilterOption, index: number) => (
    <Link to={option.path} key={index} className={style.FilterRow}>
      {option.label}
    </Link>
  );

  renderOptions() {
    const { location, message } = this.props;
    const options = getOptions(message, location);

    return options.map<React.Node, FilterOption>(this.renderOption);
  }

  render() {
    const { message } = this.props;

    return (
      <FilterBox
        title={message({
          id: 'Quick filters',
          comment: 'Filter box title in the contract list.',
        })}
        workspaceId={this.getWorkspaceId()}
        param="quickfilter"
        removeDivider
      >
        {this.renderOptions()}
      </FilterBox>
    );
  }
}

export default localize<Props>(QuickFilters);
