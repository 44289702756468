// @flow

import {
  KEY_SMS,
} from 'extensions';

const Sms = {
  key: KEY_SMS,
  typeSpecificConfigKeys: [
    'displayName',
    'notificationAgreementPublish',
    'notificationAgreementClose',
    'notificationAgreementMessage',
    'notificationReminderExpired',
    'notificationAgreementUpdate',
  ],
};

export default Sms;
