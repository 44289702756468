import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import { checkAcl } from 'components/acl';
import Button from 'components/button';
import Warning from 'components/icons/warning';
import CircularSpinner from 'components/icons/circular-spinner';
import AiSparkle from 'components/icons/ai-sparkle';
import { CheckCircleLight } from 'components/icons/check-circle-light';
import Tooltip from 'components/tooltip';

import style from './ai-review-button.module.scss';

type Review = {
  lastReviewDate: string | null;
  breaches: number;
  highlightIndexForUrl: number;
  warnings: number;
  status: 'IN_PROGRESS' | 'NOT_STARTED' | 'ERROR' | 'FINISHED';
};

type Props = {
  agreement: Oneflow.Agreement;
};

const isInProgress = (review: Review) => review.status === 'IN_PROGRESS';
const hasNoInsights = (review: Review) => review.breaches === 0 && review.warnings === 0;
const hasOnlyBreaches = (review: Review) => review.breaches > 0 && review.warnings === 0;
const hasOnlyWarnings = (review: Review) => review.breaches === 0 && review.warnings > 0;
const hasBreachesAndWarnings = (review: Review) => review.breaches > 0 && review.warnings > 0;
const hasBreachesOrWarnings = (review: Review) => review.breaches > 0 || review.warnings > 0;

export const AiReviewButton = ({ agreement }: Props) => {
  const { review } = agreement || {};
  const hasAiReviewAccess = checkAcl(agreement.acl, 'agreement:ai_review:view');

  if (!review || !hasAiReviewAccess) {
    return null;
  }

  const getIcon = () => {
    if (isInProgress(review)) {
      return <CircularSpinner height="16px" />;
    }

    if (hasBreachesOrWarnings(review)) {
      return <Warning height="14px" />;
    }

    return <CheckCircleLight height="14px" />;
  };

  const getTooltipText = () => {
    if (isInProgress(review)) {
      return (
        <Message
          id="Please wait. Review in progress"
          comment="Text in a tooltip that shows above a disabled button"
        />
      );
    }

    if (hasBreachesOrWarnings(review)) {
      return (
        <Message
          id="Open and show issues"
          comment="Text in a tooltip that shows above a button that opens a new tab with found legal issues in the contract"
        />
      );
    }

    return (
      <Message
        id="No issues were found!"
        comment="Text in a tooltip that shows above a button, issues refer to found legal issues in a contract"
      />
    );
  };
  return (
    <Tooltip
      messageClassName={style.TooltipText}
      side="top"
      message={getTooltipText()}
      sideOffset={6}
    >
      <Button
        target="_blank"
        rel="noopener noreferrer"
        customClass={clsx(style.InsightsButton, {
          [style.Critical]: !isInProgress(review) && review.breaches > 0,
          [style.Warning]: !isInProgress(review) && hasOnlyWarnings(review),
          [style.Passed]: !isInProgress(review) && hasNoInsights(review),
          [style.Loading]: isInProgress(review),
        })}
        external
        href={`/api/ext/ai_review/redirect/agreement/${agreement.id}?highlight_index_for_url=${review.highlightIndexForUrl || 1}`}
        icon={getIcon()}
        disabled={isInProgress(review)}
        trackable={{
          name: 'Go To Single AI Review',
          props: {
            location: 'contract list - breach label',
          },
        }}
      >
        {hasNoInsights(review) && !isInProgress(review) && (
          <Message
            id="Review passed"
            comment="Text in a button that shows that there are no found legal issues in a contract"
          />
        )}
        {hasOnlyBreaches(review) && !isInProgress(review) && (
          <Message
            values={{ issueCount: review.breaches || review.warnings }}
            id="{issueCount} issue found"
            pluralId="{issueCount} issues found"
            pluralCondition="issueCount"
            comment="Text in a button that shows the number of found legal issues in a contract"
          />
        )}
        {hasOnlyWarnings(review) && !isInProgress(review) && (
          <Message
            values={{ issueCount: review.warnings }}
            id="{issueCount} risk found"
            pluralId="{issueCount} risks found"
            pluralCondition="issueCount"
            comment="Text in a button that shows the number of insights found in a contract"
          />
        )}
        {hasBreachesAndWarnings(review) && !isInProgress(review) && (
          <>
            <Message
              values={{ issueCount: review.breaches }}
              id="{issueCount} issue"
              pluralId="{issueCount} issues"
              pluralCondition="issueCount"
              comment="Text in a button that shows the number of found legal issues in a contract"
            />
            <span>&nbsp;&&nbsp;</span>
            <Message
              values={{ issueCount: review.warnings }}
              id="{issueCount} risk found"
              pluralId="{issueCount} risks found"
              pluralCondition="issueCount"
              comment="Text in a button that shows the number of insights found in a contract"
            />
          </>
        )}
        {isInProgress(review) && (
          <>
            <Message
              id="Reviewing"
              comment="Text in a button that reviews that there are no found legal issues in a contract"
            />
            &nbsp;
            <AiSparkle height="14px" />
          </>
        )}
      </Button>
    </Tooltip>
  );
};
