// @flow

import * as React from 'react';
import { has } from 'lodash';
import Message from 'components/message';
import Toggle from 'components/toggle';
import Tooltip from 'components/tooltip';

import style from './permissions-component.module.scss';

type Props = {
  role: Role,
  editingEnabled?: boolean,
  handlePermissionChange: ({ id: number, enabled: boolean }) => void,
  changedPermissions?: {},
  queryPermissions: (args: ?QueryFuncArgs) => void,
  permissions: Permissions,
};

export class PermissionsComponent extends React.Component<Props> {
  static defaultProps = {
    editingEnabled: false,
    changedPermissions: {},
  };

  componentDidMount() {
    const { queryPermissions, role } = this.props;

    queryPermissions({ params: { scope: role.scope } });
  }

  handleChange = (permissionId: number) => (checked: boolean) => {
    const { handlePermissionChange } = this.props;

    handlePermissionChange({
      id: permissionId,
      enabled: checked,
    });
  };

  hasPermission(permissionId: number) {
    const { role, changedPermissions } = this.props;

    if (!role.permissions) {
      return false;
    }

    if (has(changedPermissions, permissionId)) {
      return changedPermissions[permissionId];
    }

    return (role.permissions || []).includes(permissionId);
  }

  renderPermissionSections() {
    const {
      editingEnabled, permissions,
    } = this.props;

    const renderCategory = (category) => (
      <div className={style.CategoryItemContainer}>
        <h3
          className={style.CategoryItem}
        >
          {category.categoryLabel}
        </h3>
      </div>
    );

    const renderSensitiveWarning = (permission) => {
      if (!permission.warning) {
        return null;
      }

      return (
        <Tooltip
          message={permission.warning}
          side="top"
        >
          <span className={style.SensitiveLabel}>
            <Message
              id="Sensitive"
              comment="Help label text when permission gives sensitive accesst"
            />
          </span>
        </Tooltip>
      );
    };

    const renderPermissions = (category) => category.permissions.map((permission) => (
      <li className={style.PermissionItem} key={permission.id}>
        <div>
          <Toggle
            version="latest"
            checked={this.hasPermission(permission.id)}
            disabled={!editingEnabled || permission.disabled}
            onChange={this.handleChange(permission.id)}
          />
        </div>
        <span className={style.PermissionLabel}>
          {permission.label}
        </span>
        {renderSensitiveWarning(permission)}
      </li>
    ));

    return permissions.map((category) => (
      <div data-testid="permissions" key={category.category}>
        {renderCategory(category)}
        {renderPermissions(category)}
      </div>
    ));
  }

  render() {
    const { role } = this.props;

    if (!role.id) {
      return (
        <p>
          <em>
            <Message
              id="Select a role in the list."
              comment="The help text shown in the roles page to ask the user select one in the list"
            />
          </em>
        </p>
      );
    }

    return (
      <div className={style.Role}>
        <ul className={style.PermissionsList}>
          {this.renderPermissionSections()}
        </ul>
      </div>
    );
  }
}
