import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';

import { setFocusedEditor } from 'reducers/editor';
import useFocusedEditor from 'hooks/rich-text-editor/use-focused-editor';

const useResetFocusedEditor = () => {
  const dispatch = useDispatch();
  const focusedEditor = useFocusedEditor();

  const resetFocusedEditor = useCallback((e?: React.PointerEvent | undefined) => {
    if (e?.target.closest('.rich-text-region') || e?.target.getAttribute('data-slate-editor')) {
      return;
    }

    const documentSelection = document.getSelection();
    if (documentSelection) {
      documentSelection.removeAllRanges();
    }

    if (!focusedEditor) {
      return;
    }

    Transforms.deselect(focusedEditor);
    ReactEditor.blur(focusedEditor as ReactEditor);

    dispatch(setFocusedEditor(null));
  }, [dispatch, focusedEditor]);

  return resetFocusedEditor;
};

export default useResetFocusedEditor;
