import clsx from 'clsx';

import { formatNumberWithTrailingZeros } from 'utils/format-number-with-trailing-zeros';
import { roundBy } from 'utils/math';
import useCurrentPriceRoundingMethod from 'hooks/use-current-price-rounding-method';

import {
  hasZeroPriceWithPercentageDiscount,
  isBasePriceVisible,
} from 'components/contract-boxes/product-table-box/product-table-box-helpers';
import { PRODUCT_PERCENTAGE_DISCOUNT } from 'components/contract-boxes/product-table-box/constants';
import AffixedPrice from 'components/contract-boxes/product-table-box/product-table/affixed-price';
import Message from 'components/message';

import style from './product-price-responsive.module.scss';

type Props = {
  discountAmount?: number,
  discountType?: number,
  postfix: string,
  prefix: string,
  price: number,
  priceDiscounted: number,
  pricePrecision: number,
}

const ProductPrice = ({
  discountAmount,
  discountType,
  postfix,
  prefix,
  price,
  priceDiscounted,
  pricePrecision,
}: Props) => {
  const hasDiscount = Boolean(discountAmount);
  const hasMultipliedByZero = hasZeroPriceWithPercentageDiscount(
    discountAmount,
    price,
    discountType,
  );

  const shouldShowBasePrice = isBasePriceVisible(price, priceDiscounted);

  const currentPriceRoundingMethod = useCurrentPriceRoundingMethod();

  const basePriceClassNames = clsx({
    [style.PriceWithoutDiscount]: (price || price === 0) && !hasDiscount,
    [style.StrikeThrough]: (price || price === 0) && hasDiscount,
  });

  const renderDiscountedPrice = () => {
    if (!hasDiscount) {
      return null;
    }

    return (
      <div data-testid="discounted-price">
        <AffixedPrice
          formattedPrice={formatNumberWithTrailingZeros(roundBy(
            currentPriceRoundingMethod,
            priceDiscounted,
            pricePrecision,
          ), pricePrecision)}
          prefix={prefix}
          postfix={postfix}
        />
      </div>
    );
  };

  const renderDiscountAmount = () => {
    if (!hasDiscount || typeof discountAmount !== 'number' || priceDiscounted < 0) {
      return null;
    }

    const roundedDiscountAmount = formatNumberWithTrailingZeros(roundBy(
      currentPriceRoundingMethod,
      discountAmount * 100,
      pricePrecision,
    ), pricePrecision);

    if (discountType === PRODUCT_PERCENTAGE_DISCOUNT) {
      const percentageDiscount = (
        <span>
          {roundBy(
            currentPriceRoundingMethod,
            discountAmount * 100,
            pricePrecision,
          )}
          {' '}
          %
        </span>
      );

      return (
        <Message
          id="{discountAmount} discount"
          data-testid="discount-amount-percentage"
          values={{
            discountAmount: discountType === PRODUCT_PERCENTAGE_DISCOUNT
              ? percentageDiscount
              : roundedDiscountAmount,
          }}
          comment="The amount discounted from the product price"
        />
      );
    }

    // When price is null and discount is an absolute number, show discount, as a negative number
    const affixedDiscount = (
      <span>
        <span>
          <AffixedPrice
            formattedPrice={formatNumberWithTrailingZeros(roundBy(
              currentPriceRoundingMethod,
              discountAmount,
              pricePrecision,
            ), pricePrecision)}
            prefix={prefix}
            postfix={postfix}
          />
        </span>
      </span>
    );
    return (
      <Message
        id="{discountAmount} discount"
        data-testid="discount-amount-numeric"
        values={{
          discountAmount: affixedDiscount,
        }}
        comment="The amount discounted from the product price"
      />
    );
  };
  if (hasMultipliedByZero) {
    return null;
  }

  return (
    <div role="cell" className={style.ProductPrice}>
      {shouldShowBasePrice && (
      <div data-testid="base-price" className={basePriceClassNames}>
        <AffixedPrice
          formattedPrice={formatNumberWithTrailingZeros(roundBy(
            currentPriceRoundingMethod, price, pricePrecision,
          ), pricePrecision)}
          prefix={prefix}
          postfix={postfix}
        />
      </div>
      )}
      {renderDiscountedPrice()}
      {renderDiscountAmount()}
    </div>
  );
};

export default ProductPrice;
