import clsx from 'clsx';
import type { ReactNode } from 'react';

import CircularSpinner from 'components/icons/circular-spinner';
import Button from 'components/button';
import Remove from 'components/icons/remove';

import style from './loading-status-box.module.scss';

type Props = {
  message: ReactNode;
  onCancelProcessing?: () => void,
  width?: string | number,
  height?: string | number,
  className?: string,
};

export const LoadingStatusBox = ({
  message,
  onCancelProcessing,
  width,
  height,
  className,
}: Props) => (
  <div
    className={clsx(style.Loading, className)}
    style={{ width, height }}
  >
    <div className={style.CircularSpinner}>
      <CircularSpinner />
    </div>
    <div className={style.Messages}>
      {message}
      {onCancelProcessing && (
        <div className={style.Actions}>
          <Button
            onClick={onCancelProcessing}
            icon={Remove}
            data-testid="cancel-processing"
            kind="special"
            customClass={style.Rounded}
          />
        </div>
      )}
    </div>
  </div>
);
