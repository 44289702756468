import { useSelector } from 'react-redux';

import { getCurrentContractId, isContractPristine } from 'reducers/current-contract';
import agreements from 'reducers/entities/agreements';

const useSaveChangesButton = (): { disabled: boolean } => {
  const agreementId = useSelector(getCurrentContractId);
  const isPristine = useSelector(isContractPristine);
  const saveRpcState = useSelector((state) => (
    agreements.getSaveAgreementSelector(state, { id: agreementId })
  ));

  const disabled = isPristine || saveRpcState?.loading;

  return { disabled };
};

export default useSaveChangesButton;
