import { Message } from '@oneflowab/pomes';

const GetInTouch = () => (
  <Message
    id="Thank you for choosing to connect with us, we will reach out to you shortly."
    comment="Text for get in touch confirmation modal"
  />
);

export default GetInTouch;
