import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import templateGroupsReducer from 'reducers/entities/template-groups';
import extensionsReducer from 'reducers/entities/extensions';
import { TEMPLATE_GROUPS_QUERY } from 'data-fields';

export const EXTENSIONS_QUERY = '/admin/account/extensions';

const useTemplateGroups = () => {
  const templateGroupsQuery = useSelector((state) => (
    templateGroupsReducer.getQuerySelector(state, { name: TEMPLATE_GROUPS_QUERY })
  ));

  const templateGroups = useSelector((state) => (
    templateGroupsReducer.getTemplateGroupsSelector(state, { ids: templateGroupsQuery.result })
  ));

  return templateGroups;
};

export default useTemplateGroups;

export const useFetchTemplateGroups = (extension: Oneflow.Extension) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(extensionsReducer.queryExtensions({ name: EXTENSIONS_QUERY }));

    if (extension.state) {
      dispatch(templateGroupsReducer.queryTemplateGroups({ name: TEMPLATE_GROUPS_QUERY }));
    }
  }, [dispatch, extension.state]);
};
