import Svg from 'components/svg';

const Nl = Svg((
  <>
    <path d="M512.001 112.092H0V9.103A8.829 8.829 0 0 1 8.828.275h494.345a8.829 8.829 0 0 1 8.828 8.828v102.989z" fill="#FF4B55" />
    <path d="M503.172 335.724H8.828A8.829 8.829 0 0 1 0 326.896V223.908h512v102.988a8.828 8.828 0 0 1-8.828 8.828z" fill="#41479B" />
    <path fill="#F5F5F5" d="M0 112.088h512V223.9H0z" />
  </>
), { viewBox: '0 0 512 336', fill: 'none' });

Nl.displayName = 'Nl';

export default Nl;
