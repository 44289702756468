// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useVideoBoxProps } from 'contexts/video-box-props';
import { getGuestToken } from 'agreement/selectors';
import VideoPlayer from 'components/video-player';
import AspectRatio from 'components/aspect-ratio';
import { isVideo, getVideoPoster, getVideoUrl } from 'components/contract-boxes/video-box/video-box-helpers';

import style from './video-player.module.scss';

const VideoPlayerComponent = () => {
  const guestToken = useSelector((state) => getGuestToken(state));
  const { box, agreementId } = useVideoBoxProps();
  const videoFiles = box.content.data.filter((d) => isVideo(d));
  const file = videoFiles.find((v) => !v._removed);
  const { width } = box.config;
  const videoPlayerContainerClasses = clsx(style.VideoPlayerContainer, {
    [style.Small]: width === 'sm',
  });

  if (!file || !agreementId) {
    return null;
  }

  return (
    <div className={videoPlayerContainerClasses}>
      <AspectRatio ratio="16:9" className={style.VideoCanvas}>
        <VideoPlayer
          videoUrl={getVideoUrl(agreementId, file, guestToken)}
          poster={getVideoPoster(agreementId, file, guestToken)}
        />
      </AspectRatio>
    </div>
  );
};

export default VideoPlayerComponent;
