// @flow

import React, {
  type Node,
  useCallback,
  useRef,
  useState,
} from 'react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import clsx from 'clsx';
import * as ScrollArea from '@radix-ui/react-scroll-area';

import ChevronDownIcon from 'components/icons/chevron-down';
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectViewport,
  SelectPortal,
  SelectItem,
} from 'components/select-field-radix';

import style from './select.module.scss';

type Props = {
  selectClassName?: string,
  itemsClassName?: string,
  itemClassName?: string,
  label: string | Node,
  name?: string,
  value?: string | number | boolean,
  onValueChange?: Function,
  onOpenChange?: Function,
  children?: Node,
  items?: Array<any>,
  labelKey?: string,
  valueKey?: string,
  customItemRenderer?: Function,
  disabled?: Boolean,
};

const SelectComponent = ({
  selectClassName,
  itemsClassName,
  itemClassName,
  label,
  name,
  value,
  onValueChange,
  onOpenChange,
  children,
  items,
  labelKey,
  valueKey,
  customItemRenderer,
  disabled = false,
}: Props) => {
  const contentRef = useRef(null);
  const [side, setSide] = useState(null);
  const onSelectOpenChange = useCallback((open) => {
    if (onOpenChange) {
      onOpenChange(open);
    }

    if (!open) {
      setSide(null);
      return;
    }

    setTimeout(() => {
      if (!contentRef.current) {
        return;
      }
      setSide(contentRef.current.getAttribute('data-side'));
    }, 0);
  }, [onOpenChange]);

  const renderChildItem = useCallback((item, index) => {
    if (isFunction(customItemRenderer)) {
      return customItemRenderer(item, index);
    }

    if (isString(item) || isNumber(item)) {
      return (
        <SelectItem
          value={item}
          className={itemClassName}
          key={index}
        >
          {item}
        </SelectItem>
      );
    }

    if (isPlainObject(item)) {
      const itemLabel = item[labelKey];
      const itemValue = item[valueKey];

      return (
        <SelectItem
          value={itemValue}
          className={itemClassName}
          key={index}
        >
          {itemLabel}
        </SelectItem>
      );
    }

    return <span key={index}>{item}</span>;
  }, [customItemRenderer, itemClassName, labelKey, valueKey]);

  const renderChildren = useCallback(() => {
    if (children) {
      return children;
    }

    if (isEmpty(items) || !isArray(items)) {
      throw Error('Invalid Select items');
    }

    return items.map(renderChildItem);
  }, [children, items, renderChildItem]);

  const triggerClassNames = clsx(style.Select, selectClassName, {
    [style.OpenedOnBottom]: side === 'bottom',
    [style.OpenedOnTop]: side === 'top',
    [style.Disabled]: disabled,
  });

  return (
    <Select
      name={name}
      value={value}
      onValueChange={onValueChange}
      onOpenChange={onSelectOpenChange}
      disabled={disabled}
    >
      <SelectTrigger className={triggerClassNames}>
        {label}
        <ChevronDownIcon height="9px" />
      </SelectTrigger>
      <SelectPortal>
        <SelectContent
          position="popper"
          className={style.SelectContent}
          ref={contentRef}
          sideOffset={-1}
        >
          <ScrollArea.Root>
            <SelectViewport asChild>
              <ScrollArea.Viewport className={clsx(style.ItemsContainer, itemsClassName)}>
                {renderChildren()}
              </ScrollArea.Viewport>
            </SelectViewport>
          </ScrollArea.Root>
        </SelectContent>
      </SelectPortal>
    </Select>
  );
};

export default SelectComponent;
