import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import type { HTMLAttributes } from 'react';

import style from './sticky-element.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  shouldStick: boolean;
};

// A div component with position sticky. When the element is stuck, it adds a data-stuck attribute
// that can be used to style the element
const StickyElement = ({
  children,
  shouldStick,
  className,
  ...props
}: Props) => {
  const bulkActionsMenuRef = useRef(null);
  useEffect(() => {
    /*
     * Add a data-stuck attribute to the element based on if the element is stuck or not
     * this attribute can be used to style the element if it is stuck
     *
     * Example CSS:
     * .Sticky[data-stuck='true'] { your styles to be applied }
     */
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (!shouldStick) {
          entry.target.setAttribute('data-stuck', 'false');
          return;
        }

        if (!entry.isIntersecting) {
          entry.target.setAttribute('data-stuck', 'true');
        } else {
          entry.target.setAttribute('data-stuck', 'false');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, { threshold: [1] });

    if (bulkActionsMenuRef.current) {
      observer.observe(bulkActionsMenuRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={bulkActionsMenuRef}
      className={clsx(className, {
        [style.Sticky]: shouldStick,
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export default StickyElement;
