// @flow

import React from 'react';
import type { Location } from 'react-router';
import queryString from 'query-string';
import { Message } from '@oneflowab/pomes';

import extensionSettings from 'hocs/extension-settings';

import Button from 'components/button';
import CheckCircle from 'components/icons/check-circle';

import style from './hubspot.module.scss';

type Props = {
  location: Location,
};

const HUBSPOT_OAUTH_LINK = '/api/ext/hubspot/oauth_proxy';

export class HubspotPage extends React.Component<Props> {
  isConnected() {
    const { location } = this.props;
    const { status } = queryString.parse(location.search);

    return status === 'ok';
  }

  renderConnectionIcon() {
    if (!this.isConnected()) {
      return null;
    }

    return <CheckCircle height="20px" className={style.ConnectionIcon} />;
  }

  renderAuthButtonText() {
    if (!this.isConnected()) {
      return (
        <Message
          id="Authenticate to Hubspot"
          comment="Button text in Hubspot settings page"
        />
      );
    }

    return (
      <Message
        id="Re-authenticate to Hubspot"
        comment="Button text in Hubspot settings page"
      />
    );
  }

  render() {
    return (
      <>
        <h3 className={style.ExtensionConfigurationHeader}>
          <Message
            id="Authentication"
            comment="Section header for the authentication section in an extension."
          />
          {this.renderConnectionIcon()}
        </h3>
        <div className={style.ExtensionConfigurationBody}>
          <Message
            id="Please authenticate with your Hubspot login credentials to activate the extension. You will only need to enter your credentials once, unless the credentials are revoked from Hubspot."
            comment="Help text for the authentication section in an extension"
          />
        </div>

        <Button
          external
          href={HUBSPOT_OAUTH_LINK}
          customClass={style.AuthButton}
          kind="primary"
        >
          {this.renderAuthButtonText()}
        </Button>
      </>
    );
  }
}

export default extensionSettings(HubspotPage);
