import type { ReactNode } from 'react';
import clsx from 'clsx';

import NewCrossIcon from 'components/icons/new-cross';
import ToastIcon from 'components/toasts/toast-icon';
import toast from 'components/toasts';
import { DEFAULT_OBSERVER_ID, TOAST_TYPES } from 'components/toasts/constants';
import type { ObserverId, Toast, ToastType } from 'components/toasts/types';

import style from './content.module.scss';

type Props = {
  id: Toast['id'];
  observerId?: ObserverId;
  title?: string;
  description?: string;
  action?: ReactNode;
  type: ToastType
}

const Content = ({
  id,
  observerId = DEFAULT_OBSERVER_ID,
  title,
  description,
  action,
  type,
}: Props) => {
  const handleClose = () => {
    toast.remove({
      observerId,
      toastId: id,
    });
  };

  const contentClassName = clsx(style.Container, {
    [style.SuccessToast]: type === TOAST_TYPES.SUCCESS,
    [style.ErrorToast]: type === TOAST_TYPES.ERROR,
    [style.WarningToast]: type === TOAST_TYPES.WARNING,
    [style.InfoToast]: type === TOAST_TYPES.INFO,
  });

  return (
    <div className={contentClassName}>
      <div className={style.Left}>
        <ToastIcon type={type} />
        <div className={style.Content}>
          <div className={style.Title}>{title}</div>
          {description && <div className={style.Description}>{description}</div>}
        </div>
      </div>
      <div className={style.Middle}>
        {action}
      </div>
      <div className={clsx(style.Right, {
        [style.CenterRight]: !description,
      })}
      >
        <button className={style.CloseButton} onClick={handleClose}>
          <NewCrossIcon width="13px" />
        </button>
      </div>
    </div>
  );
};

export default Content;
