// @flow

import * as React from 'react';
import clsx from 'clsx';
import Message from 'components/message';

import {
  willRenew,
  isOverdueEvent,
  becomesOverdue,
  willTerminate,
  willStart,
  isStarted,
  isRenewed,
  isTerminated,
  willRenewReminder,
  isRenewedReminder,
  willOverdueReminder,
  isOverdueReminder,
  willEnd,
  isEnded,
  isEndedReminder,
  willEndReminder,
  isInternalReminder,
} from 'agreement/delayed-events';
import { getDocumentUrl } from 'agreement/navigation-helpers';

import Tooltip from 'components/tooltip';
import LifecycleEventInsight from 'components/lifecycle-event-insight';

import style from './lifecycle-event.module.scss';

export type Props = {
  event: LifecycleCalendarEvent,
};

export const LifecycleEvent = ({
  event,
}: Props) => {
  const renderEvent = () => {
    const { sourceEvent } = event;
    const eventTypeClasses = clsx(style.LifecycleEvent, {
      [style.Green]:
        isRenewed(sourceEvent)
        || willRenew(sourceEvent)
        || isStarted(sourceEvent)
        || willStart(sourceEvent),
      [style.Blue]: becomesOverdue(sourceEvent),
      [style.Red]:
        isTerminated(sourceEvent)
        || willTerminate(sourceEvent)
        || willEnd(sourceEvent)
        || isEnded(sourceEvent),
      [style.Orange]: isOverdueEvent(sourceEvent),
      [style.Purple]: isInternalReminder(sourceEvent),
      [style.Yellow]:
        willOverdueReminder(sourceEvent)
        || isOverdueReminder(sourceEvent)
        || isRenewedReminder(sourceEvent)
        || willRenewReminder(sourceEvent)
        || isEndedReminder(sourceEvent)
        || willEndReminder(sourceEvent),
    });

    const handleTitleClick = (e: SyntheticInputEvent<*>) => {
      e.stopPropagation();
    };

    const getContractTitle = () => {
      const { contractName, counterpartyNames, ownerPartyName } = event;

      if (contractName) {
        return contractName;
      }

      if (counterpartyNames) {
        return counterpartyNames;
      }

      return ownerPartyName || 'N/A';
    };

    const href = getDocumentUrl(sourceEvent.agreement.id);

    return (
      <div
        className={eventTypeClasses}
      >
        <div className={style.EventType} />
        <div className={style.EventInformation}>
          <div
            className={style.EventLinkContainer}
            aria-hidden
          >
            <a
              data-testid="event-title"
              href={href}
              className={style.EventLink}
              onClick={handleTitleClick}
            >
              {getContractTitle()}
            </a>
          </div>
          <LifecycleEventInsight event={event} />
        </div>
      </div>
    );
  };

  const renderContractName = () => {
    const { contractName } = event;

    if (!contractName) {
      return null;
    }

    return (
      <div className={style.TooltipContent}>
        {contractName}
      </div>
    );
  };

  const renderCounterparties = () => {
    const { counterpartyNames } = event;

    if (!counterpartyNames) {
      return null;
    }

    return (
      <div className={style.TooltipContent}>
        {counterpartyNames}
      </div>
    );
  };

  const renderOwnerPartyName = () => {
    const { contractName, counterpartyNames, ownerPartyName } = event;

    if (contractName || counterpartyNames) {
      return null;
    }

    return (
      <div className={style.TooltipContent}>
        {ownerPartyName}
      </div>
    );
  };

  const renderTooltipContent = () => {
    const { isContractNotAvailable } = event;

    if (isContractNotAvailable) {
      return (
        <Message
          id="Contract is not available"
          comment="Tooltip message title when the user don't have access to the contract"
        />
      );
    }

    return (
      <>
        {renderContractName()}
        {renderCounterparties()}
        {renderOwnerPartyName()}
      </>
    );
  };

  return (
    <Tooltip
      messageClassName={style.TooltipContainer}
      side="top"
      message={renderTooltipContent()}
      theme="oneflow"
      zIndex="10003"
    >
      {renderEvent()}
    </Tooltip>
  );
};
