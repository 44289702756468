import clsx from 'clsx';

import style from './badge.module.scss';

type Props = {
  label?: string,
  kind: 'type' | 'name',
  outline?: boolean,
  className?: string,
}

const Badge = ({
  label = '',
  kind,
  outline,
  className,
}: Props) => {
  const badgeClasses = clsx(style.Badge, style.lightGray, className, {
    [style[kind]]: kind,
    [style.outline]: outline,
  });

  return (
    <span className={badgeClasses}>
      {label}
    </span>
  );
};

export default Badge;
