import { Message } from '@oneflowab/pomes';
import SentForApproval from './send-for-approval.gif';
import style from './document-sent-body.module.scss';

export type Props = {
  isManualSend?: boolean;
};

const DocumentSentBody = ({ isManualSend }: Props) => {
  const renderInformationText = () => {
    if (isManualSend) {
      return (
        <Message
          id="You'll need to manually send the document for signing when it's approved."
          comment="Text explaining that the user has to send the document manually after it's approved."
        />
      );
    }

    return (
      <Message
        id="Participants will get the invitation to sign when the document is approved."
        comment="Text explaining what the next step will be."
      />
    );
  };

  return (
    <div className={style.BodyContainer}>
      <img
        src={SentForApproval}
        height="160px"
        width="160px"
        alt="Sent for approval"
      />
      <h2 className={style.Header}>
        <Message
          id="Sent for approval"
          comment="Header text explaining that the document is sent for approval."
        />
      </h2>
      <p data-testid="information-message">
        {renderInformationText()}
      </p>
    </div>
  );
};

export default DocumentSentBody;
