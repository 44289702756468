// @flow

import { hasCanceledTimestamp } from 'agreement';
import * as agreementConstants from 'agreement/constants';
import { isTimeSameOrBefore } from 'date';

export const START_LIFECYCLE: DelayedEventType = 'agreement_start_lifecycle';
export const NEW_LIFECYCLE: DelayedEventType = 'agreement_new_lifecycle';
export const REMINDER_NEW_LIFECYCLE: DelayedEventType = 'agreement_reminder_new_lifecycle';
export const MARK_AS_EXPIRED: DelayedEventType = 'agreement_mark_as_expired';
export const REMINDER_MARK_AS_EXPIRED: DelayedEventType = 'agreement_reminder_mark_as_expired';
export const INTERNAL_REMINDER: DelayedEventType = 'agreement_custom_reminder';

export const isOverdueEvent = ({
  executed,
  type,
}: DelayedEvent) => Boolean(executed) && type === MARK_AS_EXPIRED;

export const becomesOverdue = ({
  executed,
  type,
}: DelayedEvent) => !executed && type === MARK_AS_EXPIRED;

export const isOverdueReminder = ({
  executed,
  type,
}: DelayedEvent) => Boolean(executed) && type === REMINDER_MARK_AS_EXPIRED;

export const isInternalReminder = ({
  type,
}: DelayedEvent) => type === INTERNAL_REMINDER;

export const willOverdueReminder = ({
  executed,
  type,
}: DelayedEvent) => !executed && type === REMINDER_MARK_AS_EXPIRED;

export const isRenewed = ({
  executed,
  type,
  agreement,
  date,
}: DelayedEvent) => Boolean(executed)
  && type === NEW_LIFECYCLE
  && (!hasCanceledTimestamp(agreement)
  || (hasCanceledTimestamp(agreement) && !isTimeSameOrBefore(agreement.cancelTime, date)));

export const willRenew = ({
  executed,
  type,
  agreement,
}: DelayedEvent) => !executed && type === NEW_LIFECYCLE && !hasCanceledTimestamp(agreement);

export const isRenewedReminder = ({
  executed,
  type,
}: DelayedEvent) => Boolean(executed) && type === REMINDER_NEW_LIFECYCLE;

export const willRenewReminder = ({
  executed,
  type,
}: DelayedEvent) => !executed && type === REMINDER_NEW_LIFECYCLE;

export const isEnded = ({
  executed,
  type,
  agreement,
}: DelayedEvent) => Boolean(executed)
  && type === NEW_LIFECYCLE
  && agreement.type === agreementConstants.TYPE_SINGLE_PERIOD;

export const willEnd = ({
  executed,
  type,
  agreement,
}: DelayedEvent) => !executed
  && type === NEW_LIFECYCLE
  && agreement.type === agreementConstants.TYPE_SINGLE_PERIOD;

export const isEndedReminder = ({
  executed,
  type,
  agreement,
}: DelayedEvent) => Boolean(executed)
  && type === REMINDER_NEW_LIFECYCLE
  && agreement.type === agreementConstants.TYPE_SINGLE_PERIOD;

export const willEndReminder = ({
  executed,
  type,
  agreement,
}: DelayedEvent) => !executed
  && type === REMINDER_NEW_LIFECYCLE
  && agreement.type === agreementConstants.TYPE_SINGLE_PERIOD;

export const isStarted = ({
  executed,
  type,
}: DelayedEvent) => Boolean(executed) && type === START_LIFECYCLE;

export const willStart = ({
  executed,
  type,
}: DelayedEvent) => !executed && type === START_LIFECYCLE;

export const isTerminated = ({
  executed,
  type,
  agreement,
  date,
}: DelayedEvent) => Boolean(executed)
  && type === NEW_LIFECYCLE
  && hasCanceledTimestamp(agreement)
  && isTimeSameOrBefore(agreement.cancelTime, date);

export const willTerminate = ({
  executed,
  type,
  agreement,
}: DelayedEvent) => !executed && type === NEW_LIFECYCLE && hasCanceledTimestamp(agreement);
