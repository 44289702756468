// @flow

import clsx from 'clsx';
import React from 'react';
import type { MessageTranslator } from '@oneflowab/pomes';
import { Message } from '@oneflowab/pomes';
import get from 'lodash/get';
import { Form, Field } from 'react-final-form';

import { urlValidator } from 'forms/validators';
import extensionSettings from 'hocs/extension-settings';

import * as EditableForm from 'components/editable-form';
import Divider from 'components/divider';
import TextField from 'components/text-field';
import TextArea from 'components/text-area';
import SelectField from 'components/select-field';
import Toggle from 'components/toggle';

import { idpTypes, getIdpTypeLabel } from 'extensions/sso';

import style from './sso.module.scss';

export type Props = {
  extension: Extension,
  updateExtension: Extension => void,
  updateState: UpdateState,
  resetUpdateState: () => void,
  message: MessageTranslator,
};

type State = {
  isEditMode: boolean,
};

type FormData = {
  idpType: String,
  idpSingleSignOnServiceUrl: String,
  idpEntityId: String,
  idpX509cert: String,
  enforceSso: boolean,
  remoteAttributes: boolean,
  remoteGroups: boolean,
  forceAuthn: Boolean,
};

export class SsoPage extends React.Component<Props, State> {
  state = {
    isEditMode: false,
  };

  componentDidUpdate() {
    const { isEditMode } = this.state;
    const { updateState } = this.props;

    if (updateState.success && isEditMode) {
      this.setState({
        isEditMode: false,
      });
    }
  }

  getInitialValues() {
    const {
      idpType,
      idpSingleSignOnServiceUrl,
      idpEntityId,
      idpX509cert,
      idpLoginUrl,
      spEntityId,
      spMetadataUrl,
      acsUrl,
      enforceSso,
      remoteAttributes,
      remoteGroups,
      forceAuthn,
      jitCreation,
    } = this.props.extension.config;

    return {
      idpType,
      idpSingleSignOnServiceUrl,
      idpEntityId,
      idpX509cert,
      idpLoginUrl,
      spEntityId,
      spMetadataUrl,
      acsUrl,
      enforceSso: Boolean(Number(enforceSso)),
      remoteAttributes: Boolean(Number(remoteAttributes)),
      remoteGroups: Boolean(Number(remoteGroups)),
      forceAuthn: Boolean(Number(forceAuthn)),
      jitCreation: Boolean(Number(jitCreation)),
    };
  }

  renderToggle = (value: boolean) => (
    <Toggle
      className={style.Toggle}
      checked={value}
      disabled
    />
  );

  handleSubmit = ({
    idpType,
    idpSingleSignOnServiceUrl,
    idpEntityId,
    idpX509cert,
    enforceSso,
    remoteAttributes,
    remoteGroups,
    forceAuthn,
  }: FormData) => {
    const { updateExtension, extension } = this.props;

    updateExtension({
      id: extension.extensionId,
      config: {
        ...extension.config,
        idpType: get(idpType, 'value'),
        idpSingleSignOnServiceUrl,
        idpEntityId,
        idpX509cert,
        enforceSso,
        remoteAttributes,
        remoteGroups,
        forceAuthn,
      },
    });
  };

  setIsEditMode = (isEditMode: boolean) => {
    const { updateState, resetUpdateState } = this.props;

    if (!updateState.pristine) {
      resetUpdateState();
    }

    this.setState({
      isEditMode,
    });
  };

  render() {
    const { isEditMode } = this.state;
    const {
      updateState,
      resetUpdateState,
      message,
      extension,
    } = this.props;

    const {
      attributeMapping,
      domains,
    } = extension.config;

    const {
      roles,
      title,
      language,
      firstName,
      lastName,
      phoneNumber,
    } = attributeMapping;

    const {
      idpType,
      idpSingleSignOnServiceUrl,
      idpEntityId,
      idpX509cert,
      idpLoginUrl,
      spEntityId,
      spMetadataUrl,
      acsUrl,
      jitCreation,
      enforceSso,
      remoteAttributes,
      remoteGroups,
      forceAuthn,
    } = this.getInitialValues();

    return (
      <div className={style.FormContainer}>
        <Form
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={style.Actions}>
                <EditableForm.Actions
                  resetFormState={resetUpdateState}
                  formState={updateState}
                  isEditMode={isEditMode}
                  setIsEditMode={this.setIsEditMode}
                />
              </div>
              <h2 className={style.Header}>
                <Message
                  id="Identity provider (IDP)"
                  comment="Section header for the SSO extension."
                />
              </h2>
              <p>
                <Message
                  id="Configuration for the remote identity provider."
                  comment="Help text for an SSO extension section"
                />
              </p>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Type"
                    comment="Label for an identity provider setting in the SSO settings."
                  />
                  <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                </EditableForm.Label>
                <Field
                  name="idpType"
                  component={SelectField}
                  options={idpTypes}
                  value={getIdpTypeLabel(idpType)}
                  placeholder={message({
                    id: 'Select type',
                    comment: 'Placeholder for an identity provider setting in the SSO settings.',
                  })}
                  clearable={false}
                  searchable={false}
                  required
                  disabled={!isEditMode}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Single sign-on service URL"
                    comment="Label for an identity provider setting in the SSO settings."
                  />
                  <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                </EditableForm.Label>
                <Field
                  name="idpSingleSignOnServiceUrl"
                  component={TextField}
                  placeholder={message({
                    id: 'Enter URL',
                    comment: 'Placeholder for an identity provider setting in the SSO settings.',
                  })}
                  maxLength={255}
                  required
                  validate={urlValidator({
                    message,
                    field: message({
                      id: 'URL',
                      comment: 'Used in the validation of the service URL field in the SSO settings modal.',
                    }),
                    httpsOnly: true,
                  })}
                  value={idpSingleSignOnServiceUrl || '-'}
                  disabled={!isEditMode}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Entity ID"
                    comment="Label for an identity provider setting in the SSO settings."
                  />
                  <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                </EditableForm.Label>
                <Field
                  name="idpEntityId"
                  component={TextField}
                  placeholder={message({
                    id: 'Enter URL',
                    comment: 'Placeholder for an identity provider setting in the SSO settings.',
                  })}
                  maxLength={130}
                  required
                  value={idpEntityId || '-'}
                  disabled={!isEditMode}
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="{certificateName} certificate"
                    comment="Label for an identity provider setting in the SSO settings."
                    values={{
                      certificateName: 'X.509',
                    }}
                  />
                  <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                </EditableForm.Label>
                <Field
                  name="idpX509cert"
                  component={TextArea}
                  placeholder={message({
                    id: 'Enter {certificateName} certificate',
                    comment: 'Placeholder for an identity provider setting in the SSO settings.',
                    values: {
                      certificateName: 'X.509',
                    },
                  })}
                  maxLength={4500}
                  minRows={10}
                  maxRows={20}
                  resize="vertical"
                  required
                  value={idpX509cert || '-'}
                  disabled={!isEditMode}
                />
              </div>
              <h2 className={style.Header}>
                <Message
                  id="Service provider (SP)"
                  comment="Section header for the SSO extension."
                />
              </h2>
              <p>
                <Message
                  id="Read only information about the service provider."
                  comment="Help text for an SSO extension section"
                />
              </p>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Login URL"
                    comment="Label for a service provider setting in the SSO settings modal."
                  />
                </EditableForm.Label>
                <Field
                  name="idpLoginUrl"
                  component={TextField}
                  value={idpLoginUrl}
                  disabled
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Entity ID"
                    comment="Label for a service provider setting in the SSO settings modal."
                  />
                </EditableForm.Label>
                <Field
                  name="spEntityId"
                  component={TextField}
                  value={spEntityId}
                  disabled
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Metadata URL"
                    comment="Label for a service provider setting in the SSO settings modal."
                  />
                </EditableForm.Label>
                <Field
                  name="spMetadataUrl"
                  component={TextField}
                  value={spMetadataUrl}
                  disabled
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="ACS URL"
                    comment="Label for a service provider setting in the SSO settings modal."
                  />
                </EditableForm.Label>
                <Field
                  name="acsUrl"
                  component={TextField}
                  value={acsUrl}
                  disabled
                />
              </div>
              <h2 className={style.Header}>
                <Message
                  id="Settings"
                  comment="Section header for the SSO extension."
                />
              </h2>
              <p>
                <Message
                  id="Additional configuration such as enforcing SSO over passwords, syncing remote attributes and groups, and forcing remote login."
                  comment="Help text for an SSO extension section"
                />
              </p>
              <div className={clsx(style.ToggleRow, style.Row)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="Enforce SSO over passwords"
                    comment="Toggle to control SSO settings."
                  />
                </EditableForm.Label>
                <Field
                  name="enforceSso"
                  component={Toggle}
                  value={enforceSso}
                  type="checkbox"
                  version="latest"
                  disabled={!isEditMode}
                />
              </div>
              <div className={clsx(style.ToggleRow, style.Row)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="Sync remote attributes from IDP"
                    comment="Toggle to control SSO settings."
                  />
                </EditableForm.Label>
                <Field
                  name="remoteAttributes"
                  component={Toggle}
                  value={remoteAttributes}
                  type="checkbox"
                  version="latest"
                  disabled={!isEditMode}
                />
              </div>
              <div className={clsx(style.ToggleRow, style.Row)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="Sync remote groups from IDP"
                    comment="Toggle to control SSO settings."
                  />
                </EditableForm.Label>
                <Field
                  name="remoteGroups"
                  component={Toggle}
                  value={remoteGroups}
                  type="checkbox"
                  version="latest"
                  disabled={!isEditMode}
                />
              </div>
              <div className={clsx(style.ToggleRow, style.Row)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="Force remote login every time"
                    comment="Toggle to control SSO settings."
                  />
                </EditableForm.Label>
                <Field
                  name="forceAuthn"
                  component={Toggle}
                  value={forceAuthn}
                  type="checkbox"
                  version="latest"
                  disabled={!isEditMode}
                />
              </div>
              <div className={clsx(style.ToggleRow, style.Row)}>
                <EditableForm.Label className={style.Label}>
                  <Message
                    id="Automatic user creation"
                    comment="Toggle to control SSO settings."
                  />
                </EditableForm.Label>
                <Field
                  name="jitCreation"
                  component={Toggle}
                  value={jitCreation}
                  type="checkbox"
                  version="latest"
                  disabled
                />
              </div>
              <h2 className={style.Header}>
                <Message
                  id="Claims"
                  comment="Section header for the SSO extension."
                />
              </h2>
              <p>
                <Message
                  id="Claims used if remote attributes or remote groups is enabled. Contact us to update claims or other related settings."
                  comment="Help text for an SSO extension section."
                />
              </p>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Groups (roles)"
                    comment="Label for an SSO attribute claim."
                  />
                </EditableForm.Label>
                <Field
                  name="roles"
                  component={TextField}
                  value={roles || '-'}
                  disabled
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Title"
                    comment="Label for an SSO attribute claim."
                  />
                </EditableForm.Label>
                <Field
                  name="title"
                  component={TextField}
                  value={title || '-'}
                  disabled
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Language"
                    comment="Label for an SSO attribute claim."
                  />
                </EditableForm.Label>
                <Field
                  name="language"
                  component={TextField}
                  value={language || '-'}
                  disabled
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="First name"
                    comment="Label for an SSO attribute claim."
                  />
                </EditableForm.Label>
                <Field
                  name="firstName"
                  component={TextField}
                  value={firstName || '-'}
                  disabled
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Last name"
                    comment="Label for an SSO attribute claim."
                  />
                </EditableForm.Label>
                <Field
                  name="lastName"
                  component={TextField}
                  value={lastName || '-'}
                  disabled
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Phone number"
                    comment="Label for an SSO attribute claim."
                  />
                </EditableForm.Label>
                <Field
                  name="phoneNumber"
                  component={TextField}
                  value={phoneNumber || '-'}
                  disabled
                />
              </div>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Domains used for user creation"
                    comment="Label for an SSO attribute claim."
                  />
                </EditableForm.Label>
                <Field
                  name="domain"
                  component={TextField}
                  value={domains ? domains.join(', ') : '-'}
                  disabled
                />
              </div>
            </form>
          )}
        />
        <Divider />
        <p>
          <Message
            id="If you have any questions regarding your single sign-on, please contact our support at {email}."
            values={{
              email: <a className={style.SupportLink} href="mailto:support@oneflow.com">support@oneflow.com</a>,
            }}
            comment="Help text for an SSO extension section"
          />
        </p>
      </div>
    );
  }
}

export default extensionSettings(SsoPage);
