// @flow

import { connect } from 'react-redux';
import type { Match } from 'react-router';

import workspacesReducer from 'reducers/entities/workspaces';

import DataRetention from './data-retention';

type OwnProps = {
  match: Match,
};

export const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const workspaceId = ownProps.match.params.id;

  return {
    workspace: workspacesReducer.getWorkspaceSelector(state, { id: workspaceId }),
    rpcState: workspacesReducer.getUpdateDataRetentionSelector(state, { id: workspaceId }),
  };
};

export const mapDispatchToProps = (dispatch: Function, ownProps: OwnProps) => ({
  resetRpcState: () => {
    dispatch(workspacesReducer.updateDataRetentionReset({ id: ownProps.match.params.id }));
  },
  updateDataRetention: (dataRetentionData: DataRetentionPolicy) => {
    dispatch(workspacesReducer.updateDataRetention({
      id: ownProps.match.params.id,
      data: dataRetentionData,
    }));
  },
  checkNewDataRetention: (dataRetentionData: DataRetentionPolicy) => {
    dispatch(workspacesReducer.getDataRetention({
      id: ownProps.match.params.id,
      data: dataRetentionData,
    }));
  },
  clearDataRetentionSuggestion: (workspaceId: number) => {
    dispatch(workspacesReducer.setWorkspaces({
      [workspaceId]: {
        dataRetentionPolicy: {
          suggestedDraftDays: null,
          suggestedOverdueDays: null,
          suggestedDeclinedDays: null,
          suggestedTerminatedDays: null,
          affectedAgreementDraft: null,
          affectedAgreementExpired: null,
          affectedAgreementDeclined: null,
          affectedAgreementTerminated: null,
        },
      },
    }));
  },
});

export default connect<DataRetention, OwnProps, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(DataRetention);
