// @flow

import { type Dispatch } from 'redux';
import { connect } from 'react-redux';

import extensionsReducer from 'reducers/entities/extensions';
import { KEY_DYNAMICS_CRM } from 'extensions';

import Authorization, { type Props, type LocalizeProps } from './dynamics-authorization';

type MSProps = {|
  authorizationState: RpcState,
|};

type MDProps = {|
  authorize: ({ dynamicsInstanceUrl: string }) => void,
|};

type OwnProps = $Diff<$Exact<Props>, {|...LocalizeProps, ...MSProps, ...MDProps |}>;

type MapStateToProps = (State) => MSProps;
export const mapStateToProps: MapStateToProps = (state) => ({
  authorizationState: extensionsReducer.getAuthorizeWithDynamicsSelector(state, {
    id: KEY_DYNAMICS_CRM,
  }),
});

type MapDispatchToProps = (Dispatch<*>) => MDProps
export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  authorize: ({ dynamicsInstanceUrl }) => {
    dispatch(extensionsReducer.authorizeWithDynamics({
      id: KEY_DYNAMICS_CRM,
      data: {
        dynamicsInstanceUrl,
      },
    }));
  },
});

export default connect<Props, OwnProps, MSProps, MDProps, State, _>(
  mapStateToProps,
  mapDispatchToProps,
)(Authorization);
