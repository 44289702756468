// @flow

import * as React from 'react';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import * as agreementConstants from 'agreement/constants';
import { getDocumentUrl } from 'agreement/navigation-helpers';
import { checkAcl } from 'components/acl';
import { NotFound } from 'components/errors';
import Video from 'components/video';

import Button from 'components/button';
import AspectRatio from 'components/aspect-ratio';
import { RemoveConfirm } from 'components/modals/remove-confirm';
import TooltipInfo from 'components/tooltip-info';

import OnBeforeUnload from 'components/on-before-unload';

import style from './video.module.scss';

const CONFIRM_RECORDED = 'Recorded';
const CONFIRM_UPLOADED = 'Uploaded';

export type ConfirmType = 'Recorded' | 'Uploaded';

export type Props = {|
  agreement: Agreement,
  setWelcomeVideoStatus: (status: number) => void,
  uploadVideo: (file: File | Blob, type: ConfirmType) => void,
  resetUploadVideoState: () => void,
  uploadVideoState: CreateState,
  removeVideo: () => void,
  removeVideoState: RemoveState,
  resetRemoveState: Function,
  message: MessageTranslator,
|};

type State = {
  recordedVideo?: Blob | File,
  recorderEnabled: boolean,
};

export class VideoPageComponent extends React.Component<Props, State> {
  state = {
    recorderEnabled: false,
    recordedVideo: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    const { uploadVideoState } = this.props;

    if (!prevProps.uploadVideoState.success && uploadVideoState.success) {
      this.disableRecorder();
    }
  }

  clearRecordedVideo = () => {
    this.setState({
      recordedVideo: undefined,
    });
  };

  isReadyForUpload = () => {
    const { recordedVideo } = this.state;

    return recordedVideo instanceof Blob || recordedVideo instanceof File;
  };

  hasVideo = () => {
    const { agreement } = this.props;

    return [
      agreementConstants.AGREEMENT_VIDEO_PROCESSING,
      agreementConstants.AGREEMENT_WITH_VIDEO,
    ].includes(agreement.welcomeVideo);
  };

  enableRecorder = () => {
    this.setState({
      recorderEnabled: true,
    });
  };

  disableRecorder = () => {
    this.setState({
      recorderEnabled: false,
    });
  };

  onCancel = () => {
    this.disableRecorder();
    this.clearRecordedVideo();
  };

  onConfirm = (type: ConfirmType) => {
    const { recordedVideo } = this.state;
    const { uploadVideo } = this.props;

    if (!recordedVideo) {
      return;
    }

    uploadVideo(recordedVideo, type);

    this.setState({
      recordedVideo: undefined,
    });
  };

  onConfirmRecorded = () => {
    this.onConfirm(CONFIRM_RECORDED);
  };

  onRecordFinished = (file: Blob) => {
    this.setState({
      recordedVideo: file,
    });
  };

  onUpload = (file: Blob) => {
    this.setState({
      recordedVideo: file,
    }, () => {
      this.onConfirm(CONFIRM_UPLOADED);
    });
  };

  onRemove = () => {
    const { removeVideo } = this.props;

    this.setState({
      recorderEnabled: false,
      recordedVideo: undefined,
    }, removeVideo);
  };

  handleConfirm = () => {
    const { removeVideoState } = this.props;

    if (removeVideoState.loading) {
      return;
    }

    this.onRemove();
  }

  renderConfirmButton() {
    const { uploadVideoState } = this.props;

    return (
      <Button
        key="confirm"
        color="yellow"
        disabled={!this.isReadyForUpload() || uploadVideoState.loading}
        onClick={this.onConfirmRecorded}
      >
        <Message
          id="Confirm"
          comment="Button, starts processing the currently recorded video"
        />
      </Button>
    );
  }

  renderActions() {
    const { uploadVideoState, removeVideoState, resetRemoveState } = this.props;
    const { recorderEnabled } = this.state;

    if (!this.hasVideo()) {
      return (
        <div className={style.VideoActions}>
          <div className={style.ConfirmRemoveActions}>
            {this.renderConfirmButton()}
          </div>
        </div>
      );
    }

    if (!recorderEnabled) {
      return (
        <div className={style.VideoActions}>
          <div className={style.AddActions}>
            <Button
              outline
              customClass={style.ChooseFromLibrary}
              onClick={this.enableRecorder}
            >
              <Message
                id="Replace video"
                comment="Button, enables the video recorder"
              />
            </Button>
          </div>
          <div>
            <RemoveConfirm
              header={(
                <Message
                  id="Delete video"
                  comment="Modal title when deleting agreement video"
                />
              )}
              onConfirm={this.onRemove}
              confirmState={removeVideoState}
              confirmMessage={(
                <>
                  <Message
                    id="The welcome video will be deleted."
                    comment="Warning message in modal when attempting to delete a welcome video."
                  />
                  <p className={style.DeleteInfoText}>
                    <Message
                      id="This action cannot be undone."
                      comment="Warning message in modal when attempting to delete a welcome video"
                    />
                  </p>
                  <p>
                    <Message
                      id="Delete the video?"
                      comment="Warning emssage in modal when attempting to delete a welcome video "
                    />
                  </p>
                </>
              )}
              outline
              customClass={style.Remove}
              resetConfirmState={resetRemoveState}
              modalKey="delete contract content video modal"
            >
              <Message
                id="Delete"
                comment="Button text for removing the welcome video of a contract."
              />
            </RemoveConfirm>
          </div>
        </div>
      );
    }

    return (
      <div className={style.VideoActions}>
        <div className={style.ConfirmRemoveActions}>
          <Button
            key="cancel"
            kind="linkSeparate"
            disabled={uploadVideoState.loading}
            onClick={this.onCancel}
          >
            <Message
              id="Cancel"
              comment="Button, cancels the recording of a new video"
            />
          </Button>
          {this.renderConfirmButton()}
        </div>
      </div>
    );
  }

  renderVideoPage() {
    const {
      agreement,
      setWelcomeVideoStatus,
      uploadVideoState,
      resetUploadVideoState,
      message,
    } = this.props;
    const {
      recorderEnabled,
      recordedVideo,
    } = this.state;

    const headerText = message({
      id: 'The video will play automatically when a counterparty opens the document for the first time.',
      comment: 'The description showed in the header of video recorder pag',
    });

    return (
      <div className={style.Video}>
        <OnBeforeUnload show={uploadVideoState.loading} />
        <div className={style.VideoPageHeader}>
          <span className={style.Header}>
            <Message
              id="Welcome video"
              comment="The header of the video recorder page"
            />
            <span className={style.Tooltip}>
              <TooltipInfo message={headerText} />
            </span>
          </span>
          <span className={style.HeaderText}>
            {headerText}
          </span>
        </div>
        <AspectRatio ratio="16:9" className={style.VideoCanvas}>
          <Video
            agreement={agreement}
            recorderEnabled={recorderEnabled}
            recordedVideo={recordedVideo}
            setWelcomeVideoStatus={setWelcomeVideoStatus}
            uploadVideoState={uploadVideoState}
            resetUploadVideoState={resetUploadVideoState}
            onRecordFinished={this.onRecordFinished}
            onUpload={this.onUpload}
            onCancelProcessing={this.onRemove}
            clearRecordedVideo={this.clearRecordedVideo}
          />
        </AspectRatio>
        {this.renderActions()}
      </div>
    );
  }

  render() {
    const { agreement } = this.props;
    const href = getDocumentUrl(agreement.id);

    if (!checkAcl(agreement.acl, ['agreement:video:add', 'agreement:video:remove'])) {
      return <NotFound redirect to={href} />;
    }

    return this.renderVideoPage();
  }
}

export default localize< Props >(VideoPageComponent);
