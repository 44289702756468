import { Message } from '@oneflowab/pomes';

import type { CancelFlowAction } from 'types/cancellation-flow';

import style from './submit-error.module.scss';

export type CancelFlowActionClose = CancelFlowAction & {
  onStepComplete: () => void,
  onClose: () => void,
}

export const SubmitError = () => (
  <span className={style.ErrorMessage}>
    <Message
      id="An error has occurred. Please reload the page and try again."
      comment="Used to show the error message after confirmation."
    />
  </span>
);
