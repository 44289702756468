// @ts-expect-error: Currently there is no .d.ts file in this module
import { deserializeDocument } from '@oneflowab/oneflow-node-manager';
import { Element, Text } from 'slate';

type SlateDescendant = Element | Text;

const emptyNode = [{ type: 'paragraph', children: [{ text: '' }] }];

const removeGoogleDocWrapper = (body: HTMLElement) => {
  const googleDocWrapperElement = body.querySelector<HTMLElement>('b[id^=docs-internal-guid-]');

  if (!googleDocWrapperElement) {
    return;
  }

  const nodesList = [...googleDocWrapperElement.childNodes];
  const { parentElement } = googleDocWrapperElement;
  nodesList.forEach((node) => {
    googleDocWrapperElement.removeChild(node);
    // @ts-expect-error: parentElement might be empty
    parentElement.insertBefore(node, googleDocWrapperElement);
  });
  // @ts-expect-error: parentElement might be empty
  parentElement.removeChild(googleDocWrapperElement);
};

type DeserializeMarkup = (markup: string, availableOptions?: object) => Array<SlateDescendant>;

const deserializeMarkup: DeserializeMarkup = (markup, availableOptions) => {
  if (!markup) {
    return emptyNode;
  }

  const markupString = markup
    .replace(/\n/g, '')
    .replace(/\r/g, '')
    .replace(/\t/g, '')
    .replace(/\u200b/g, '');

  const parsedDocument = new DOMParser().parseFromString(markupString, 'text/html');

  if (!parsedDocument.body) {
    return emptyNode;
  }

  removeGoogleDocWrapper(parsedDocument.body);

  const deserializedDocument = deserializeDocument(parsedDocument, availableOptions);

  return deserializedDocument;
};

export default deserializeMarkup;
