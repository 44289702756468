import { PureComponent } from 'react';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import { Link } from 'react-router-dom';

import { checkAcl } from 'components/acl';
import TooltipInfo from 'components/tooltip-info';
import ContractValue from 'components/contract-value';
import NewCross from 'components/icons/new-cross';
import NewCheck from 'components/icons/new-check';
import Envelope from 'components/icons/envelope';
import CalendarAlert2 from 'components/icons/calendar-alert-2';

import style from './kpi.module.scss';

export type DashboardFilters = {
  date: {
    from?: string,
    to?: string,
  },
  user: string,
}

export type Props = {
  label: string,
  count?: number,
  tooltip: string,
  type: 'Sent' | 'Signed' | 'Declined' | 'Overdue',
  agreementValueAmount?: number,
  currentWorkspace: Oneflow.Workspace,
  filters?: DashboardFilters,
  currentPositionId: number,
};

const icons = {
  Sent: Envelope,
  Signed: NewCheck,
  Declined: NewCross,
  Overdue: CalendarAlert2,
};

const states = {
  Sent: 1,
  Signed: 4,
  Declined: 5,
  Overdue: 2,
};

export class KPI extends PureComponent<Props> {
  static defaultProps = {
    count: undefined,
    agreementValueAmount: undefined,
    filters: undefined,
  };

  getLinkUrl() {
    const {
      filters,
      type,
      currentPositionId,
      count,
    } = this.props;

    if (!filters || !count) {
      return null;
    }

    const { date, user } = filters;
    let userQuery = '';
    const state = `state%5B%5D=${states[type]}`;
    const dateFrom = `date_from=${date.from}`;
    const dateTo = `date_to=${date.to}`;
    if (user === 'me') {
      userQuery = `&positions[]=${currentPositionId}`;
    }
    return `contracts/all?${state}&date_field=publish_timestamp&${dateFrom}&${dateTo}${userQuery}`;
  }

  toAgreementValue = (agreementValueAmount?: number) => {
    const { currentWorkspace } = this.props;

    if (!agreementValueAmount && agreementValueAmount !== 0) {
      return undefined;
    }

    return {
      amount: agreementValueAmount,
      currency: currentWorkspace.currency,
    };
  };

  canAccessContracts = () => {
    const { currentWorkspace } = this.props;
    return checkAcl(currentWorkspace.acl, 'collection:module:agreement:use');
  }

  renderCountText() {
    const { count } = this.props;

    if (isUndefined(count)) {
      return '-';
    }

    return count;
  }

  renderIcon() {
    const { type } = this.props;
    const icon = icons[type];
    const IconComponent = icon;

    return <IconComponent height="15px" />;
  }

  renderBox() {
    const {
      type,
      label,
      tooltip,
      agreementValueAmount,
    } = this.props;

    const linkBox = this.canAccessContracts() && this.getLinkUrl();
    const linkStyle = linkBox ? style.withLink : style.withoutLink;

    return (
      <div className={clsx(style.KPI, linkStyle, style[type])} data-testid="kpi-box">
        <div className={style.StateHeader}>
          <div className={clsx(style.IconContainer, style[type])}>
            {this.renderIcon()}
          </div>
          <div className={style.Count} data-testid="Count">
            {this.renderCountText()}
          </div>
        </div>
        <div className={style.StateContainer}>
          <div className={style.State} data-testid="State">
            {label}
            <TooltipInfo message={tooltip} side="right" />
          </div>
          <ContractValue
            agreementValue={this.toAgreementValue(agreementValueAmount)}
            className={style.ContractValue}
            showPlaceholder
            showIcon={false}
          />
        </div>
      </div>
    );
  }

  renderLink() {
    return (
      <Link to={this.getLinkUrl()} className={style.Link}>
        {this.renderBox()}
      </Link>
    );
  }

  render() {
    if (this.getLinkUrl() && this.canAccessContracts()) {
      return this.renderLink();
    }

    return this.renderBox();
  }
}
