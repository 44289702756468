// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { checkAcl } from 'components/acl';

import workspacesReducer from 'reducers/entities/workspaces';
import workspaceGroupsReducer from 'reducers/entities/workspace-groups';
import workspaceUsersReducer from 'reducers/entities/workspace-users';
import groupsReducer from 'reducers/entities/groups';

import WorkspaceAccess, { type Props } from './workspace-access';

type StateProps = {|
  allGroupsQuery: Query,
  allGroups: Array<Group>,
  workspace: Workspace,
  groupsQuery: Query,
  positionsQuery: Query,
  hasGroupPermission: boolean,
|};

type DispatchProps = {|
  queryGroups: QueryFuncArgs => void,
  queryGroupBindings: QueryFuncArgs => void,
  queryPositionBindings: QueryFuncArgs => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  match: {
    params: {
      id: string,
    }
  },
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

type MapDispatchToProps = (Dispatch<*>, OwnProps) => DispatchProps;

const GROUPS_QUERY_NAME = 'admin/groups';

export const mapStateToProps: MapStateToProps = (state, ownProps) => {
  const workspaceId = ownProps.match.params.id;
  const workspace = workspacesReducer.getWorkspaceSelector(state, { id: workspaceId });
  const allGroupsQuery = groupsReducer.getQuerySelector(state, {
    name: GROUPS_QUERY_NAME,
  });

  const BINDINGS_QUERY = `workspace/${workspaceId}/relationships`;
  const hasGroupPermission = checkAcl(workspace.acl, 'collection:group:binding:view');

  const groupsQuery = workspaceGroupsReducer.getQuerySelector(state, { name: BINDINGS_QUERY });
  const positionsQuery = workspaceUsersReducer.getQuerySelector(state, { name: BINDINGS_QUERY });

  const groupBindings = workspaceGroupsReducer.getWorkspaceGroupsSelector(state, {
    ids: groupsQuery.result,
  });

  const positionBindings = workspaceUsersReducer.getWorkspaceUsersSelector(state, {
    ids: positionsQuery.result,
  });

  return {
    allGroupsQuery,
    allGroups: groupsReducer.getGroupsSelector(state, { ids: allGroupsQuery.result }),
    workspace,
    groups: groupBindings,
    groupsQuery,
    positions: positionBindings,
    positionsQuery,
    hasGroupPermission,
  };
};

export const mapDispatchToProps: MapDispatchToProps = (dispatch, ownProps) => ({
  queryGroupBindings: ({ pagination } = {}) => {
    const BINDINGS_QUERY = `workspace/${ownProps.match.params.id}/relationships`;
    dispatch(workspaceGroupsReducer.queryWorkspaceGroups({
      name: BINDINGS_QUERY,
      pagination,
      params: {
        workspaceId: ownProps.match.params.id,
        actorType: 'group',
      },
    }));
  },
  queryPositionBindings: ({ pagination } = {}) => {
    const BINDINGS_QUERY = `workspace/${ownProps.match.params.id}/relationships`;
    dispatch(workspaceUsersReducer.queryWorkspaceUsers({
      name: BINDINGS_QUERY,
      pagination,
      params: {
        workspaceId: ownProps.match.params.id,
        actorType: 'position',
      },
    }));
  },
  queryGroups: ({ pagination } = {}) => {
    dispatch(groupsReducer.queryGroups({
      name: GROUPS_QUERY_NAME,
      params: {},
      pagination,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(WorkspaceAccess);
