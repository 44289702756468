import clsx from 'clsx';

import {
  InlineEditable,
  useInlineEditableProps,
} from 'components/inline-editable';
import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import Tooltip from 'components/tooltip';

import style from './document-name-editable.module.scss';

type Props = {
  defaultTooltip: string,
  defaultValue: string,
  onChange: (newValue: string) => void,
  readOnly: boolean,
  value: string,
}

const CHARACTER_LIMIT = 130;

export const DocumentNameEditable = ({
  defaultTooltip,
  defaultValue,
  onChange,
  readOnly,
  value,
}: Props) => {
  const {
    characterCount,
    hasOverflow,
    isFocused,
    text,
  } = useInlineEditableProps();
  const { documentLayoutRef } = useDocumentLayout();

  const hasValue = Boolean(value);

  const shouldShowTooltip = () => {
    if (isFocused) {
      return false;
    }

    return (!hasValue && text === defaultValue) || hasOverflow;
  };

  const getTooltipMessage = () => {
    if (hasValue) {
      return text;
    }

    return defaultTooltip;
  };

  const renderCharacterCount = () => {
    if (!isFocused) {
      return null;
    }

    return (
      <span
        className={clsx(style.CharacterCount, {
          [style.Error]: characterCount === CHARACTER_LIMIT,
        })}
      >
        {`${characterCount} / ${CHARACTER_LIMIT}`}
      </span>
    );
  };

  return (
    <Tooltip
      collisionBoundary={documentLayoutRef.current}
      collisionPadding={13}
      contentClassName={clsx(style.TooltipContent, {
        [style.Expanded]: Boolean(documentLayoutRef.current),
      })}
      hideContent={!shouldShowTooltip()}
      message={getTooltipMessage()}
      messageClassName={style.TooltipMessage}
      side="bottom"
      zIndex="10003"
    >
      <div className={style.InlineEditableContainer}>
        <InlineEditable
          characterLimit={CHARACTER_LIMIT}
          className={clsx({
            [style.InlineEditable]: isFocused,
          })}
          defaultValue={defaultValue}
          editableClassName={clsx(style.EditableElement, {
            [style.Focused]: isFocused,
          })}
          onChange={onChange}
          readOnly={readOnly}
          value={value}
        />
        {renderCharacterCount()}
      </div>
    </Tooltip>
  );
};
