/* eslint-disable no-restricted-syntax */
// @flow
import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import {
  findActiveTable,
  findActiveTableRow,
  getNextRow,
} from './table-utils';
import { createBlockMatrix } from './block-matrix';

const removeTableRow = (editor: any) => {
  const blockMatrix = createBlockMatrix(editor);
  if (!blockMatrix) {
    return;
  }
  const [, activeTablePath] = findActiveTable(editor);
  const [, path] = findActiveTableRow(editor);
  const rowIndex = path[path.length - 1];
  const [nextRow, nextRowPath] = getNextRow(editor, path);
  const rowLayout = blockMatrix[rowIndex];
  const layoutHeight = blockMatrix.length;

  Editor.withoutNormalizing(editor, () => {
    if (layoutHeight === 1 || !nextRow) {
      rowLayout.forEach((cell) => {
        if (isEmpty(cell) || cell.rowSpan === 1) {
          return;
        }
        Transforms.setNodes(editor, {
          rowSpan: cell.rowSpan - 1,
        }, {
          at: ReactEditor.findPath(editor, cell.node),
        });
      });

      Transforms.removeNodes(editor, {
        at: path,
      });

      if (layoutHeight === 1) {
        Transforms.removeNodes(editor, {
          at: activeTablePath,
        });
      }
      return;
    }

    const childrenToBeMoved = cloneDeep(nextRow.children);
    const movedNodes = new Map();

    rowLayout.forEach((cell) => {
      if (isEmpty(cell) || cell.rowSpan === 1) return;
      if (cell.isTopOfMergedCell) {
        if (movedNodes.get(cell.node)) {
          return;
        }
        childrenToBeMoved.splice(cell.nodeIndex, 0, {
          ...cell.node,
          rowSpan: cell.rowSpan - 1,
        });
        movedNodes.set(cell.node, true);
      } else {
        Transforms.setNodes(editor, {
          rowSpan: cell.rowSpan - 1,
        }, {
          at: ReactEditor.findPath(editor, cell.node),
        });
      }
    });

    const updatedNextRowPath = [...nextRowPath];
    updatedNextRowPath[updatedNextRowPath.length - 1] += 1;
    Transforms.insertNodes(editor, {
      ...nextRow,
      children: childrenToBeMoved,
    }, { at: updatedNextRowPath });
    Transforms.removeNodes(editor, {
      at: nextRowPath,
    });

    Transforms.removeNodes(editor, {
      at: path,
    });
  });
};

export default removeTableRow;
