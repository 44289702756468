import { DefaultRootState, useSelector } from 'react-redux';

import agreements from 'reducers/entities/agreements';

const useAgreement = (agreementId: number) => (
  useSelector<DefaultRootState, Oneflow.Agreement>((state) => (
    agreements.getAgreementSelector(state, { id: agreementId })
  ))
);

export default useAgreement;
