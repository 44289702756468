import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';

import extensionsReducer from 'reducers/entities/extensions';
import { EXTENSION_SIGNATURE_FIELDS } from 'extensions';

import { TabHeader } from 'components/document-tabs/components/tab-header';
import { ScrollableArea } from 'components/document-tabs/components/scrollable-area';
import CloseSidebarButton from 'components/expanded-layout-sidebar/close-sidebar-button';

import { ContentSections } from './content-sections';
import style from './content-tab.module.scss';
import { ContentFields } from './content-fields';

type Props = {
  onClose: () => void;
};

export const ContentTab = ({ onClose }: Props) => {
  const extension = useSelector((state) => (
    extensionsReducer.getExtensionSelector(state, { id: EXTENSION_SIGNATURE_FIELDS })
  ));
  const isSignatureFieldsActive = extension?.state;

  return (
    <>
      <TabHeader>
        <h2 className={style.ContentHeader}>
          <Message
            id="Content"
            comment="Label for Content title under Content tab in contract sidebar"
          />
        </h2>
        {onClose && <CloseSidebarButton onClose={onClose} />}
      </TabHeader>
      <ScrollableArea className={style.ContentTabContainer}>
        <p className={style.Title}>
          <Message id="Sections" comment="Name of the sections part of the content tab" />
        </p>
        <div className={style.ContentTabContainer}>
          <ContentSections />
        </div>
        {isSignatureFieldsActive && (
          <>
            <p className={style.Title}>
              <Message id="PDF fields" comment="Name of the sections part of the content tab" />
            </p>
            <div className={style.ContentTabContainer}>
              <ContentFields />
            </div>
          </>
        )}
      </ScrollableArea>
    </>
  );
};
