import * as zod from 'zod';
import isEmpty from 'lodash/isEmpty';

/* Low level complexity utilities to high level complexity utilities */

// TODO: Can we do better how to use date format? two different props inter connected
// => conditional validations
export const dateSchema = zod.string().pipe(zod.coerce.date());

export const timestampSchema = zod.number().pipe(zod.coerce.date());

export const integerBoolean = zod.number().refine((value) => value === 0 || value === 1, {
  message: '0 and 1 are the only supported values',
});

// TOOD list: required prop spec config verbosity killers towards less typings
// asObject, asArray, asCollection, asModel

export const existenceRequired = (requiredProps = [], zodSchema) => {
  const newShape = {};
  if (!zodSchema || !zodSchema?.shape || typeof zodSchema.shape !== 'object') {
    return zod.object(newShape);
  }

  const { shape } = zodSchema;

  Object.keys(shape).forEach((key) => {
    if (Object.keys(requiredProps).includes(key)) {
      const fieldSchema = shape[key];
      let newField = fieldSchema;

      // nullish prop will return true for isNullable ans isOptional
      if (newField instanceof zod.ZodOptional) {
        newField = newField.unwrap();
      }

      newShape[key] = newField;
    } else {
      newShape[key] = shape[key];
    }
  });

  return zod.object(newShape);
};

export const getOwnSchema = (ownProps, zodOwnSchema) => {
  if (isEmpty(zodOwnSchema)) {
    // eslint-disable-next-line no-console
    console.warn('zod own schema mapper is not passed');
    return zod.object({});
  }

  if (isEmpty(ownProps)) {
    return zodOwnSchema;
  }

  const requiredProps = ownProps.reduce((required, propNamme) => ({
    ...required,
    [propNamme]: true,
  }), {});

  const requiredPropsSchema = existenceRequired(requiredProps, zodOwnSchema);
  // Keep all the props and override the required props schema
  return zodOwnSchema.merge(requiredPropsSchema);
};

export const getSubSchemasSpecification = (subSchemas, zodSubSchemasMapper) => {
  if (isEmpty(zodSubSchemasMapper)) {
    // eslint-disable-next-line no-console
    console.warn('zod subSchema mapper is not passed');
    return {};
  }

  if (isEmpty(subSchemas)) {
    return {};
  }

  const subSchemasKeys = Object.keys(subSchemas);
  const subSchemasSpecification = {};

  subSchemasKeys.forEach((subSchemaName) => {
    let subSchemaOwn = zod.object({});
    let nestedSubSchemasSpecification;

    // Initialize empty shape schema
    subSchemasSpecification[subSchemaName] = subSchemaOwn;

    // For ownProps
    if (!isEmpty(zodSubSchemasMapper[subSchemaName].schema)) {
      subSchemaOwn = getOwnSchema(
        subSchemas[subSchemaName].ownProps,
        zodSubSchemasMapper[subSchemaName].schema,
      );
      subSchemasSpecification[subSchemaName] = subSchemaOwn;
    }

    // For nested subSchemas
    const nestedSubSchemas = subSchemas[subSchemaName].subSchemas;
    if (!isEmpty(nestedSubSchemas)) {
      // Normal object where each values are zod schema for an entity or a collection
      nestedSubSchemasSpecification = getSubSchemasSpecification(
        nestedSubSchemas,
        zodSubSchemasMapper[subSchemaName].subSchemas,
      );
      subSchemasSpecification[subSchemaName] = subSchemasSpecification[subSchemaName].extend(
        nestedSubSchemasSpecification,
      );
    }

    if (zodSubSchemasMapper[subSchemaName].type === 'array') {
      subSchemasSpecification[subSchemaName] = zod.array(subSchemasSpecification[subSchemaName]);
    }
  });

  return subSchemasSpecification;
};

export const getRelationsSchemaSpecification = (relations, zodRelationsSchemaMapper) => {
  if (isEmpty(zodRelationsSchemaMapper)) {
    // eslint-disable-next-line no-console
    console.warn('zod relations mapper is not passed');
    return {};
  }

  if (isEmpty(relations)) {
    return {};
  }

  const relationKeys = Object.keys(relations);
  const relationSpecification = {};

  relationKeys.forEach((relationName) => {
    let relationOwnSchema = zod.object({});
    let nestedRelationsSchemaSpecification;
    let subSchemasSpecification;

    // Initialize empty shape schema
    relationSpecification[relationName] = relationOwnSchema;

    // For ownProps
    if (!isEmpty(zodRelationsSchemaMapper[relationName].schema)) {
      relationOwnSchema = getOwnSchema(
        relations[relationName].ownProps,
        zodRelationsSchemaMapper[relationName].schema,
      );
      relationSpecification[relationName] = relationOwnSchema;
    }

    // for subSchemas inside relations
    const { subSchemas } = relations[relationName];
    if (!isEmpty(subSchemas)) {
      // Normal object where each values are zod schema for an entity or a collection
      subSchemasSpecification = getSubSchemasSpecification(
        subSchemas,
        zodRelationsSchemaMapper[relationName].subSchemas,
      );
      relationSpecification[relationName] = relationSpecification[relationName].extend(
        subSchemasSpecification,
      );
    }

    // For nested relations
    const nestedRelations = relations[relationName].relations;
    if (!isEmpty(nestedRelations)) {
      // Normal object where each values are zod schema for an entity or a collection
      nestedRelationsSchemaSpecification = getRelationsSchemaSpecification(
        nestedRelations,
        zodRelationsSchemaMapper[relationName].relations,
      );
      relationSpecification[relationName] = relationSpecification[relationName].extend(
        nestedRelationsSchemaSpecification,
      );
    }

    if (zodRelationsSchemaMapper[relationName].type === 'collection') {
      relationSpecification[relationName] = zod.array(relationSpecification[relationName]);
    }
  });

  return relationSpecification;
};

export const getDataSchema = (schemaSpecification, dataSchemaMapper) => {
  const schemaName = schemaSpecification?.entityName || schemaSpecification?.collectionName;
  const schemaMapper = dataSchemaMapper[schemaName];

  let zodDataShema = getOwnSchema(schemaSpecification.ownProps, schemaMapper.schema);

  if (!isEmpty(schemaSpecification.subSchemas)) {
    // Normal object where each values are zod schema for an object or a array
    const subSchemasSpecification = getSubSchemasSpecification(
      schemaSpecification.subSchemas,
      schemaMapper.subSchemas,
    );

    zodDataShema = zodDataShema.extend(subSchemasSpecification);
  }

  if (!isEmpty(schemaSpecification.relations)) {
    // Normal object where each values are zod schema for an entity or a collection
    const relationsSchemaSpecification = getRelationsSchemaSpecification(
      schemaSpecification.relations,
      schemaMapper.relations,
    );

    zodDataShema = zodDataShema.extend(relationsSchemaSpecification);
  }

  if (schemaMapper.type === 'collection') {
    zodDataShema = zod.array(zodDataShema);
  }

  return zodDataShema;
};
