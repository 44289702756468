// @flow

import * as React from 'react';

import {
  isSignedAndEndedAndTerminated,
  isSignedAndEndedWithNoDurationType,
  isSignedAndEnded,
  isSignedWithRecurringTypeAndCanceled,
  isSignedAndAwaiting,
  isSignedAndActiveWithRecurringType,
  isSignedAndActive,
  isSignedWithoutLifecycle,
  isPending,
  isDraft,
  isOverdue,
  isAnyDeclinedState,
} from 'agreement';

import {
  Canceled,
  Created,
  Declined,
  Ended,
  Overdue,
  Sent,
  Signed,
  Terminated,
} from 'components/contract-insight/insights';

import style from './mini-contract-insight.module.scss';

type Props = {
  agreement: Agreement,
  dateFormat: string,
  showWorkspaceName: boolean,
  hideInsight?: boolean,
};

type GetInsight = (agreement: Agreement, dateFormat: string) => React.Node;

type Insight = {|
  condition: (agreement: Agreement) => boolean,
  primary: GetInsight,
|};

type InsightList = Array<Insight>;

const insights = {
  canceled: (agreement, dateFormat) => (
    <Canceled cancelTime={agreement.cancelTime} dateFormat={dateFormat} />
  ),
  created: (agreement, dateFormat) => (
    <Created created={agreement.created} dateFormat={dateFormat} />
  ),
  declined: (agreement, dateFormat) => (
    <Declined agreement={agreement} dateFormat={dateFormat} />
  ),
  ended: (agreement, dateFormat) => (
    <Ended periodEndTime={agreement.periodEndTime} dateFormat={dateFormat} />
  ),
  overdue: (agreement, dateFormat) => (
    <Overdue signingPeriodExpiryTime={agreement.signingPeriodExpiryTime} dateFormat={dateFormat} />
  ),
  sent: (agreement, dateFormat) => (
    <Sent publishTime={agreement.publishTime} dateFormat={dateFormat} />
  ),
  signed: (agreement, dateFormat) => (
    <Signed agreement={agreement} dateFormat={dateFormat} />
  ),
  terminated: (agreement, dateFormat) => (
    <Terminated terminateTime={agreement.terminateTime} dateFormat={dateFormat} />
  ),
};

const insightlist: InsightList = [{
  condition: isSignedAndEndedAndTerminated,
  primary: insights.terminated,
}, {
  condition: isSignedAndEndedWithNoDurationType,
  primary: insights.signed,
}, {
  condition: isSignedAndEnded,
  primary: insights.ended,
}, {
  condition: isSignedWithRecurringTypeAndCanceled,
  primary: insights.canceled,
}, {
  condition: isSignedAndAwaiting,
  primary: insights.signed,
}, {
  condition: isSignedAndActiveWithRecurringType,
  primary: insights.signed,
}, {
  condition: isSignedAndActive,
  primary: insights.signed,
}, {
  condition: isSignedWithoutLifecycle,
  primary: insights.signed,
}, {
  condition: isPending,
  primary: insights.sent,
}, {
  condition: isDraft,
  primary: insights.created,
}, {
  condition: isOverdue,
  primary: insights.overdue,
}, {
  condition: isAnyDeclinedState,
  primary: insights.declined,
}];

const MiniContractInsight = ({
  agreement,
  dateFormat,
  showWorkspaceName,
  hideInsight,
}: Props) => {
  const renderInsight = (getInsight: GetInsight) => getInsight(agreement, dateFormat);

  const contractInsight = insightlist.find((insight) => insight.condition(agreement));

  if (!contractInsight) {
    return null;
  }

  if (hideInsight && !showWorkspaceName) {
    return null;
  }

  const { primary } = contractInsight;

  let workspaceName = null;
  if (showWorkspaceName) {
    workspaceName = (
      <div className={style.WorkspaceName}>
        {agreement.collection?.name}
      </div>
    );
  }

  let agreementInsights = null;
  if (!hideInsight) {
    agreementInsights = (
      <div className={style.Insight}>
        {renderInsight(primary)}
      </div>
    );
  }

  return (
    <div className={style.InfoContainer}>
      {agreementInsights}
      {workspaceName}
    </div>
  );
};

MiniContractInsight.defaultProps = {
  hideInsight: undefined,
};

export default MiniContractInsight;
