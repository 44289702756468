/* eslint-disable import/named */
import { get, omit } from 'lodash';
import queryString from 'query-string';
import { FREEMIUM_SELF_SERVICE_QUERY } from 'account/billing';
import {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { getAccountFromSessionSelector } from 'reducers/session';
import accountsReducer from 'reducers/entities/accounts';
import { getLocationSelector } from 'reducers/router';
import plansReducer from 'reducers/entities/plans';
import usePrevious from 'hooks/use-previous';

import {
  MultistepModal,
  ModalStep,
} from 'components/modal';
import type { ModalStepRenderProps } from 'components/modal';

import FreemiumSelfServicePlans from './plans';
import { FreemiumSelfServiceSeats } from './seats';
import FreemiumSelfServiceBilling from './billing';
import { FreemiumSelfServiceConfirm } from './confirm';
import { FreemiumSelfServiceSuccess } from './success';

// modalKeys for ampltitude tracking on Exit Modal events
const SELF_PURCHASE_CHOOSE_PLAN_MODAL = 'self purchase choose plan modal';
const SELF_PURCHASE_PICK_SEATS_MODAL = 'self purchase pick seats modal';
const SELF_PURCHASE_EDIT_BILLING_MODAL = 'self purchase edit billing modal';
const SELF_PURCHASE_CONFIRM_MODAL = 'self purchase confirm purchase modal';
const SELF_PURCHASE_SUCCESS_MODAL = 'self purchase activated modal';

export type Props = {
  children: (handleOpen: () => void) => ReactNode,
  isModalOpen: boolean,
};

export const FreemiumSelfService = ({
  children,
  isModalOpen,
}: Props) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(isModalOpen || false);

  const location = useSelector(getLocationSelector);
  const previousLocation = usePrevious(location);
  const account: Oneflow.Account = useSelector(getAccountFromSessionSelector);
  const updateSeatsState = useSelector(
    (state) => accountsReducer.getBuySeatsSelector(state, { id: account.id }),
  );

  const fetchAccount = useCallback(() => {
    dispatch(accountsReducer.fetchAccount({ id: account.id }));
  }, [account, dispatch]);
  const updateQueryString = useCallback((query: string) => {
    const newLocation = `${location.pathname}?${query}`;

    dispatch(push(newLocation));
  }, [dispatch, location]);
  const queryPlans = useCallback(() => {
    dispatch(plansReducer.queryPlans());
  }, [dispatch]);

  const openModalBasedOnLocation = useCallback((prevLocation: Location<unknown> = {}) => {
    const modalQuery = get(location, 'query.m');

    if (modalQuery === get(prevLocation, 'query.m')) {
      return;
    }

    setIsOpen(modalQuery === FREEMIUM_SELF_SERVICE_QUERY);
  }, [location]);

  useEffect(() => {
    openModalBasedOnLocation(previousLocation);
  }, [previousLocation, openModalBasedOnLocation]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleUpdateQueryString = useCallback(() => {
    if (get(location, 'query.m') !== FREEMIUM_SELF_SERVICE_QUERY) {
      return;
    }

    updateQueryString(
      queryString.stringify({
        ...omit(location.query, 'm'),
      }),
    );
  }, [updateQueryString, location]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    handleUpdateQueryString();
  }, [handleUpdateQueryString]);

  const handleAfterClose = useCallback(() => {
    if (updateSeatsState.success) {
      fetchAccount(account.id);
    }
  }, [fetchAccount, account, updateSeatsState.success]);

  const handleFinalStep = useCallback((onStepComplete: () => void) => () => {
    onStepComplete();
    handleClose();
  }, [handleClose]);

  const onOpen = useCallback(() => {
    queryPlans();
    fetchAccount(account.id);
  }, [queryPlans, fetchAccount, account]);

  const renderPlansModal: ModalStepRenderProps = ({
    onStepComplete,
    onSyncStepData,
    modalData,
  }) => (
    <FreemiumSelfServicePlans
      onClose={handleClose}
      onStepComplete={onStepComplete}
      onSyncStepData={onSyncStepData}
      modalData={modalData}
      modalKey={SELF_PURCHASE_CHOOSE_PLAN_MODAL}
    />
  );

  const renderSeatsModal: ModalStepRenderProps = ({
    onStepComplete,
    onSyncStepData,
    modalData,
    onPreviousStep,
  }) => (
    <FreemiumSelfServiceSeats
      onClose={handleClose}
      onPreviousStep={onPreviousStep}
      onStepComplete={onStepComplete}
      onSyncStepData={onSyncStepData}
      modalData={modalData}
      modalKey={SELF_PURCHASE_PICK_SEATS_MODAL}
    />
  );

  const renderBillingModal: ModalStepRenderProps = ({
    onStepComplete,
    onSyncStepData,
    modalData,
    onPreviousStep,
  }) => (
    <FreemiumSelfServiceBilling
      onClose={handleClose}
      onPreviousStep={onPreviousStep}
      onStepComplete={onStepComplete}
      onSyncStepData={onSyncStepData}
      modalData={modalData}
      modalKey={SELF_PURCHASE_EDIT_BILLING_MODAL}
    />
  );

  const renderConfirmModal: ModalStepRenderProps = ({
    onStepComplete,
    modalData,
    onPreviousStep,
  }) => (
    <FreemiumSelfServiceConfirm
      onClose={handleClose}
      onPreviousStep={onPreviousStep}
      onStepComplete={onStepComplete}
      modalData={modalData}
      modalKey={SELF_PURCHASE_CONFIRM_MODAL}
    />
  );

  const renderSuccessModal: ModalStepRenderProps = ({
    onStepComplete,
    modalData,
  }) => (
    <FreemiumSelfServiceSuccess
      onClose={handleFinalStep(onStepComplete)}
      modalData={modalData}
      modalKey={SELF_PURCHASE_SUCCESS_MODAL}
    />
  );

  if (isOpen) {
    return (
      <>
        <MultistepModal
          isOpen={isOpen}
          isExpansiveModal
          onCancel={handleClose}
          onAfterClose={handleAfterClose}
          onOpen={onOpen}
        >
          <ModalStep modalKey={SELF_PURCHASE_CHOOSE_PLAN_MODAL}>
            {renderPlansModal}
          </ModalStep>
          <ModalStep modalKey={SELF_PURCHASE_PICK_SEATS_MODAL}>
            {renderSeatsModal}
          </ModalStep>
          <ModalStep modalKey={SELF_PURCHASE_EDIT_BILLING_MODAL}>
            {renderBillingModal}
          </ModalStep>
          <ModalStep modalKey={SELF_PURCHASE_CONFIRM_MODAL}>
            {renderConfirmModal}
          </ModalStep>
          <ModalStep modalKey={SELF_PURCHASE_SUCCESS_MODAL}>
            {renderSuccessModal}
          </ModalStep>
        </MultistepModal>
        {children(handleOpen)}
      </>
    );
  }
  return children(handleOpen);
};
