import {
  useRef,
  useEffect,
  useMemo,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { Message } from '@oneflowab/pomes';
import difference from 'lodash/difference';
import reverse from 'lodash/reverse';

import {
  FILES_UPLOAD_STATUS,
  FILES_STATUS_BOX_MINIMIZE,
  FILES_STATUS_BOX_MAXIMIZE,
  FILES_STATUS_BOX_CLOSE,
  getCurrentContractId,
} from 'reducers/current-contract';
import type { CurrentContractState } from 'reducers/current-contract';
import { getAttachmentsCountLimit } from 'agreement';
import useMatchMedia, { DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY } from 'hooks/use-match-media';

import useAgreement from 'hooks/use-agreement';

import NewCrossIcon from 'components/icons/new-cross';
import ChevronDownIcon from 'components/icons/chevron-down';
import ChevronUpIcon from 'components/icons/chevron-up';

import { FilesUploadStatusBoxItem } from './files-upload-status-box-item';

import style from './files-upload-status-box.module.scss';

export const PreviousFilesDivider = () => (
  <div className={style.PreviousFilesDividerContainer}>
    <span className={style.PreviousFilesLabel}>
      <Message id="Previous" comment="Label for previous files" />
    </span>
    <div className={style.PreviousFilesDivider} />
  </div>
);

type RootState = {
  currentContract: CurrentContractState;
}

const FilesUploadStatusBox = () => {
  const dispatch = useDispatch();

  const filesListRef = useRef<HTMLDivElement>(null);

  const isClosed = useSelector(
    (state: RootState) => state.currentContract.filesUploadStatus.isClosed,
  );
  const isMinimized = useSelector(
    (state: RootState) => state.currentContract.filesUploadStatus.isMinimized,
  );

  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const attachmentsCountLimit = useMemo(() => getAttachmentsCountLimit(agreement), [agreement]);
  const isCollapsedLayout = useMatchMedia(DOCUMENT_COLLAPSED_LAYOUT_MEDIA_QUERY);

  const closeBox = () => dispatch({ type: FILES_STATUS_BOX_CLOSE });

  const toggleMinimize = () => dispatch({
    type: isMinimized
      ? FILES_STATUS_BOX_MAXIMIZE
      : FILES_STATUS_BOX_MINIMIZE,
  });

  const files: Array<any> = useSelector(
    (state: RootState) => state.currentContract.filesUploadStatus.files,
  );

  useEffect(() => {
    if (filesListRef.current) {
      filesListRef.current.scrollTop = 0;
    }
  }, [files.length]);

  const newFiles = files.filter((file) => file.new);

  const previousFiles = reverse(difference(files, newFiles));
  const isStatusLoading = files.some(
    (file) => file.status === FILES_UPLOAD_STATUS.LOADING,
  );

  const uploadTitleText = isStatusLoading
    ? <Message id="Uploading..." comment="Label while files are being uploaded" />
    : <Message id="Upload complete" comment="Label when uploads are complete" />;

  const fileUploadStatusBoxContainerClasses = clsx(
    style.FileUploadStatusBoxContainer, {
      [style.Minimized]: isMinimized,
      [style.Closed]: isClosed,
      [style.CollapsedLayout]: isCollapsedLayout,
    },
  );

  const expandIcon = isMinimized
    ? (<ChevronUpIcon width="15px" />)
    : (<ChevronDownIcon width="15px" />);

  return (
    <div className={fileUploadStatusBoxContainerClasses} data-testid="root">
      <div className={style.Header}>
        <div className={style.TitleWrapper}>
          <span className={style.TitleText}>{uploadTitleText}</span>
        </div>
        <div className={style.ContainerActionButtons}>
          <button
            className={style.ActionButton}
            data-testid="toggle-minimize-button"
            onClick={toggleMinimize}
          >
            {expandIcon}
          </button>
          <button className={style.ActionButton} data-testid="close-button" onClick={closeBox}>
            <NewCrossIcon width="15px" />
          </button>
        </div>
      </div>
      <div
        className={clsx(style.FileUploadStatusBoxFilesWrapper, {
          [style.Minimized]: isMinimized,
        })}
      >
        <div className={style.FileUploadStatusBoxFilesList} ref={filesListRef}>
          {newFiles.length > 0 && (
            <>
              <div data-testid="new-files">
                {newFiles?.map(({
                  file,
                  errors,
                  id,
                  status,
                }) => (
                  <FilesUploadStatusBoxItem
                    key={id}
                    file={file}
                    errors={errors}
                    status={status}
                    attachmentsCountLimit={attachmentsCountLimit}
                  />
                ))}
              </div>
              <PreviousFilesDivider />
            </>
          )}
          {previousFiles?.map(({
            file,
            errors,
            id,
            status,
          }) => (
            <FilesUploadStatusBoxItem
              key={id}
              file={file}
              errors={errors}
              status={status}
              attachmentsCountLimit={attachmentsCountLimit}
            />
          ))}
        </div>

      </div>
    </div>
  );
};

export default FilesUploadStatusBox;
