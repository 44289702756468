import { Message } from '@oneflowab/pomes';

import style from './legend.module.scss';

const getLegend = () => <Message id="Pending" comment="Used as the label of the Pending section" />;

const SignRateLegend = () => (
  <div className={style.SignRateLegend}>
    <div className={style.StateGroup}>
      <div className={style.State}>
        <div className={style.Sent} />
        <div>
          {getLegend()}
        </div>
      </div>
      <div className={style.State}>
        <div className={style.Signed} />
        <div>
          <Message id="Signed" comment="Used as the label of the Signed section" />
        </div>
      </div>
    </div>
    <div className={style.StateGroup}>
      <div className={style.State}>
        <div className={style.Overdue} />
        <div>
          <Message id="Overdue" comment="Used as the label of the Overdue section" />
        </div>
      </div>
      <div className={style.State}>
        <div className={style.Declined} />
        <div>
          <Message id="Declined" comment="Used as the label of the Declined section" />
        </div>
      </div>
    </div>
  </div>
);

export default SignRateLegend;
