import { dateSchema, timestampSchema } from 'data-validators/utils';
import * as zod from 'zod';

type AgreementDataKey = 'data_field';

type DataFieldValue = {
  description?: string;
  externalKey?: string | null;
  id: string;
  key: string;
  name?: string;
  placeholder?: string;
  // Not sure about what other values can be here, hence the generic type string
  type: 'string' | string;
  value?: string;
}

type AgreementData = {
  agreement?: { id: number } | null;
  created?: string | null;
  createdTime?: string | null;
  createdTs?: number | null;
  id: number;
  key?: AgreementDataKey | null;
  updated?: string | null;
  updatedTime?: string | null;
  updatedTs?: number | null;
  value?: Record<string, unknown>;
};

export type DataField = AgreementData & {
  key: AgreementDataKey;
  value: DataFieldValue;
  agreement?: { id: number }
};

const agreementData = zod.object({
  agreement: zod.object({ id: zod.number() }).optional(),
  created: dateSchema.optional(),
  createdTime: dateSchema.optional(),
  createdTs: timestampSchema.optional(),
  id: zod.number(),
  key: zod.string().optional(),
  updated: dateSchema.optional(),
  updatedTime: dateSchema.optional(),
  updatedTs: timestampSchema.optional(),
  value: zod.object({
    description: zod.string().optional(),
    externalKey: zod.string().optional(),
    id: zod.string(),
    key: zod.string().optional(),
    name: zod.string().optional(),
    placeholder: zod.string().optional(),
    type: zod.string().optional(),
    value: zod.string().optional(),
  }),
});

export default agreementData;
