import clsx from 'clsx';
import Message from 'components/message';
import Button from 'components/button';

import style from './approve.module.scss';

type Props = {
  onClick?: () => void,
  smallSize?: boolean,
  disabled?: boolean,
};

export const ApproveButton = ({
  onClick,
  smallSize,
  disabled,
}: Props) => {
  const buttonClasses = clsx(style.Approve, {
    [style.Small]: smallSize,
  });

  return (
    <Button
      kind="approve"
      onClick={onClick}
      customClass={buttonClasses}
      disabled={disabled}
    >
      <Message
        id="Approve"
        comment="Label for button to approve a document."
      />
    </Button>
  );
};
