import { Message } from '@oneflowab/pomes';
import style from './change-pending.module.scss';

const ChangePending = () => (
  <div className={style.ChangePending}>
    <Message id="Change pending" comment="Status pill" />
  </div>
);

export default ChangePending;
