// @flow

import React from 'react';
import { isEmpty, isString } from 'lodash';

import { isSuggestion } from 'comments';
import { useParentAnnotation } from 'contexts/parent-annotation';

import style from './leaf.module.scss';

const Leaf = ({
  attributes,
  children,
  leaf,
}: RenderSlateLeafProps) => {
  const parentAnnotation = useParentAnnotation();
  const hasParentSuggestion = isSuggestion(parentAnnotation?.annotation);
  let leafChildren = children;

  if (hasParentSuggestion && !parentAnnotation?.isFocused) {
    if (isEmpty(leaf.text)) {
      leafChildren = (
        <span className={style.VoidLeaf} contentEditable={false}>
          {leafChildren}
        </span>
      );
    } else {
      leafChildren = (
        <span className={style.Suggestion}>
          {leafChildren}
        </span>
      );
    }
  }

  if (leaf.code) {
    leafChildren = <code>{leafChildren}</code>;
  }

  if (leaf.bold) {
    leafChildren = <strong>{leafChildren}</strong>;
  }

  if (leaf.italic) {
    leafChildren = <em>{leafChildren}</em>;
  }

  if (leaf.underline) {
    leafChildren = <u>{leafChildren}</u>;
  }

  if (leaf.strikethrough) {
    leafChildren = <s>{leafChildren}</s>;
  }

  if (leaf.superscript) {
    leafChildren = <sup>{leafChildren}</sup>;
  }

  if (leaf.subscript) {
    leafChildren = <sub>{leafChildren}</sub>;
  }

  if (leaf.className) {
    leafChildren = (
      <span className={leaf.className}>
        {leafChildren}
      </span>
    );
  }

  if (leaf.color) {
    leafChildren = (
      <span style={{ color: leaf.color }}>
        {leafChildren}
      </span>
    );
  }

  if (leaf.backgroundColor) {
    leafChildren = (
      <span style={{ backgroundColor: leaf.backgroundColor }}>
        {leafChildren}
      </span>
    );
  }

  if (leaf.fontSize) {
    leafChildren = (
      <span style={{ fontSize: leaf.fontSize }}>
        {leafChildren}
      </span>
    );
  }

  if (leaf.style) {
    leafChildren = (
      <span style={leaf.style}>
        {leafChildren}
      </span>
    );
  }

  if (hasParentSuggestion && parentAnnotation?.isFocused) {
    if (isEmpty(leaf.text)) {
      leafChildren = (
        <span className={style.VoidLeafWithFocusedParent} contentEditable={false}>
          {leafChildren}
        </span>
      );
    } else {
      leafChildren = (
        <span className={style.SuggestionWithFocusedParent}>
          {leafChildren}
        </span>
      );
    }
  }

  if (isString(leafChildren) && !leafChildren.length) {
    // eslint-disable-next-line react/no-danger
    return <span className={style.Leaf} {...attributes} dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />;
  }

  return <span className={style.Leaf} {...attributes}>{leafChildren}</span>;
};

export default Leaf;
