// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';
import Button from 'components/button';

import style from './forget-password.module.scss';

export type Props = {
  logout: () => void,
};

export class ForgetPassword extends React.Component<Props> {
  handleLogout = () => {
    const { logout } = this.props;

    logout();
  }

  render() {
    return (
      <Button
        kind="link"
        onClick={this.handleLogout}
        customClass={style.PasswordLink}
      >
        <Message
          id="Forgot your password?"
          comment="Link text for going to the forgot password from the login page."
        />
      </Button>
    );
  }
}
