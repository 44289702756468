import clsx from 'clsx';
import type { HTMLAttributes } from 'react';

import styles from './scrollable-area.module.scss';

type Props = HTMLAttributes<HTMLDivElement>;

export const ScrollableArea = ({ children, className, ...props }: Props) => (
  <div className={clsx(styles.ScrollableArea, className)} {...props}>
    {children}
  </div>
);
