// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import DeleteTableColumnIcon from 'components/icons/delete-table-column';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import removeTableColumn from 'components/rich-text-editor/editor-plugins/table-utils/remove-table-column';
import ToolbarButton from '../toolbar-button';

const RemoveTableColumn = () => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();

  return (
    <ToolbarButton
      label={(
        <Message
          id="Remove table column"
          comment="The label of the editor's toolbar plugin that removes a table column."
        />
      )}
      isMenuItem={false}
      onTrigger={() => {
        focusEditor();
        removeTableColumn(editor);
      }}
      icon={<DeleteTableColumnIcon />}
    />
  );
};

export default RemoveTableColumn;
