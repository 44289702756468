// @flow

import React, {
  useCallback,
  useState,
  type Node,
} from 'react';
import { Slate } from 'slate-react';

import { isIE11 } from 'utils/browser';
import { RichTextProvider } from 'contexts/rich-text';
import useEditorValue from 'hooks/rich-text-editor/use-editor-value';
import useMounted from 'hooks/use-mounted';

import RichTextViewer from 'components/rich-text-viewer';
import SelectedRectangleAnchor from 'components/rich-text-editor-toolbars/toolbar-buttons/selected-rectangle-anchor/selected-rectangle-anchor';

import { createEditor } from './slate-editor';
import createOnKeyDown from './create-on-key-down';
import DOMRangeHighlighter from './dom-range-highlighter';
import EditorToolbar from './editor-toolbar';
import Element from './element';
import Leaf from './leaf';

type Props = {
  nodes: Array<SlateDescendant>,
  onNodesChange: Array<SlateDescendant> => void,
  renderToolbar: any => Node,
  renderEditable: any => Node,
  plugins: Array<Function>,
  isTabDisabled?: boolean,
  readOnly: boolean,
};

const RichTextEditor = (props: Props) => {
  const {
    nodes,
    onNodesChange,
    renderToolbar,
    renderEditable,
    plugins,
    isTabDisabled,
    readOnly,
  } = props;

  const mounted = useMounted();
  const [editor] = useState(() => createEditor(plugins));
  const { editorValue, onChange } = useEditorValue(nodes, onNodesChange);
  const renderElement = useCallback((elementProps) => <Element {...elementProps} />, []);
  const renderLeaf = useCallback((leafProps) => <Leaf {...leafProps} />, []);

  const onSlateChange = useCallback((...args) => {
    const isAstChange = editor.operations.some(
      (op) => op.type !== 'set_selection',
    );

    if (!isAstChange) {
      return;
    }

    onChange(...args);
  }, [editor, onChange]);

  if (!mounted) {
    return null;
  }

  if (isIE11()) {
    return <RichTextViewer nodes={nodes} />;
  }

  const editable = renderEditable({
    renderElement,
    renderLeaf,
    onKeyDown: createOnKeyDown(editor, isTabDisabled),
  });

  return (
    <Slate editor={editor} initialValue={editorValue} onChange={onSlateChange}>
      <RichTextProvider isReadOnly={readOnly}>
        <EditorToolbar renderToolbar={renderToolbar} />
        <DOMRangeHighlighter />
        <SelectedRectangleAnchor />
        {editable}
      </RichTextProvider>
    </Slate>
  );
};

export default RichTextEditor;
