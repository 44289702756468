import get from 'lodash/get';
import type { MessageTranslator } from '@oneflowab/pomes';

const PERIOD_LESS_THAN_6_HOURS = 'lessThan6Hours';
const PERIOD_BETWEEN_6_AND_24_HOURS = 'between6And24Hours';
const PERIOD_BETWEEN_1_AND_7_DAYS = 'between1And7Days';
const PERIOD_BETWEEN_1_AND_3_WEEKS = 'between1And3Weeks';
const PERIOD_MORE_THAN_3_WEEKS = 'moreThan3Weeks';

type Bucket = {
  newKey: keyof Oneflow.Dashboard['timeToSignStat']['bucket'];
  key: string;
  label: string;
}

const getEventsBuckets = (message: MessageTranslator): Bucket[] => [{
  newKey: 'hours06',
  key: PERIOD_LESS_THAN_6_HOURS,
  label: message({
    id: '< 6 hours',
    comment: 'Time to sign an agreement, chart label',
  }) as string,
}, {
  newKey: 'hours624',
  key: PERIOD_BETWEEN_6_AND_24_HOURS,
  label: message({
    id: '6 - 24 hours',
    comment: 'Time to sign an agreement, chart label',
  }) as string,
}, {
  newKey: 'days17',
  key: PERIOD_BETWEEN_1_AND_7_DAYS,
  label: message({
    id: '1 - 7 days',
    comment: 'Time to sign an agreement, chart label',
  }) as string,
}, {
  newKey: 'weeks13',
  key: PERIOD_BETWEEN_1_AND_3_WEEKS,
  label: message({
    id: '1 - 3 weeks',
    comment: 'Time to sign an agreement, chart label',
  }) as string,
}, {
  newKey: 'weeks3',
  key: PERIOD_MORE_THAN_3_WEEKS,
  label: message({
    id: '> 3 weeks',
    comment: 'Time to sign an agreement, chart label',
  }) as string,
}];

const getTimeToSignData = (agreementStats: Oneflow.Dashboard, message: MessageTranslator) => {
  const buckets = getEventsBuckets(message);
  const bucketData = get(agreementStats, 'timeToSignStat.bucket');

  return buckets.map((bucket) => ({
    timeToSign: bucket.label,
    count: (bucketData?.[bucket.newKey] as number) || 0,
  }));
};

export default getTimeToSignData;
