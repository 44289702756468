import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import style from './table.module.scss';

const TableCell = ({
  actions,
  position,
  children,
  className,
  ...props
}) => {
  const tableCellClasses = clsx(style.TableCell, className, {
    [style[`TableCell${position}`]]: position,
  });

  if (actions) {
    return (
      <td {...props} className={tableCellClasses}>
        <div className={style.Actions}>
          {children}
        </div>
      </td>
    );
  }

  return (
    <td {...props} className={tableCellClasses}>
      {children}
    </td>
  );
};

TableCell.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.bool,
  position: PropTypes.oneOf(['Start', 'End', '']),
  className: PropTypes.string,
};

TableCell.defaultProps = {
  actions: false,
  position: '',
  children: null,
  className: '',
};

export default TableCell;
