// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Terminate from 'components/icons/terminate';

const WillTerminate = () => (
  <>
    <Terminate height="12px" />
    <span>
      <Message
        id="Will terminate"
        comment="An insight for lifecycle events that indicates that a contract will terminate."
      />
    </span>
  </>
);

export default WillTerminate;
