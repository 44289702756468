// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import workspacesReducer from 'reducers/entities/workspaces';

import DisableWorkspaceBranding, { type Props } from './disable-workspace-branding';

type StateProps = {|
  updateState: UpdateState,
|};

type DispatchProps = {|
  disableWorkspaceBranding: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  workspace: Workspace,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { workspace }) => ({
  updateState: workspacesReducer.getUpdateWorkspaceBrandingSelector(state, { id: workspace.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { workspace }) => ({
  disableWorkspaceBranding: () => {
    dispatch(workspacesReducer.updateWorkspaceBranding({
      id: workspace.id,
      data: {
        brandingName: null,
        brandingCountry: null,
        brandingDateFormat: null,
        brandingOrgnr: null,
        logo: null,
      },
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(DisableWorkspaceBranding);
