import { connect, DefaultRootState } from 'react-redux';
import get from 'lodash/get';

import workspacesReducer from 'reducers/entities/workspaces';

import CreateTemplateFromContract from './create-template-from-contract';
import type { Props } from './create-template-from-contract';

type StateProps = {
  workspace: Oneflow.Workspace,
};

export type OwnProps = Omit<Props, keyof StateProps>;

export const mapStateToProps = (state: DefaultRootState, { agreement }: OwnProps) => ({
  workspace: workspacesReducer.getWorkspaceSelector(state, {
    id: get(agreement, 'collection.id') as unknown as number,
  }),
});

export default connect(mapStateToProps)(CreateTemplateFromContract);
