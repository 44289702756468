// @flow

import React from 'react';

import withCurrentWorkspace from 'hocs/with-current-workspace';

import ContractList from './contract-list';

type Props = {
  acl: {
    workspace: Acl,
  },
  workspace: Workspace,
};

export const ContractsModule = (props: Props) => (
  <ContractList {...props} />
);

export default withCurrentWorkspace(ContractsModule);
