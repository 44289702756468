import { Message } from '@oneflowab/pomes';

import style from './terms-of-use-link.module.scss';

const TermsOfUseLink = () => (
  <a
    href="https://oneflow.com/terms-of-use/"
    target="_blank"
    rel="noopener noreferrer"
    className={style.Link}
  >
    <Message
      id="Read our Terms of Use and Data Processing Agreement."
      comment="Link to our terms of use page"
    />
  </a>
);

export default TermsOfUseLink;
