import { pipe } from 'lodash/fp';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Message, MessageTranslator } from '@oneflowab/pomes';

import { getAccountFromSessionSelector, logout } from 'reducers/session';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { withWorkspaceNamespace } from 'hocs/with-current-workspace';
import { getGuestToken } from 'agreement/selectors';

import * as Command from 'components/filterable';
import ChevronRightIcon from 'components/icons/chevron-right';
import ArrowRightIcon from 'components/icons/new-arrow-right';
import ExternalLinkIcon from 'components/icons/external-link';
import MagnifyingGlassIcon from 'components/icons/magnifying-glass';
import LogoutIcon from 'components/icons/logout';
import { Acl, checkAcl } from 'components/acl';
import { getHelpCenterBaseUrl } from 'utils/help-center';

import CommandItem from '../item';
import style from '../command-menu.module.scss';
import ProfileItems from '../groups/profile-items';
import { ACLs } from '../constants';

const ACL_VALUES = [
  ...Object.values(ACLs.workspace),
  ...Object.values(ACLs.account),
  ...Object.values(ACLs.page),
];

const hasSomeAcl = (
  acl: Oneflow.Agreement['acl'],
  checks: string[],
) => checks.some((check) => checkAcl(acl, check));

type Props = {
  message: MessageTranslator;
  closeCmdk: () => void;
  pushPage: (page: string) => void;
};

export const Home = ({ message, closeCmdk, pushPage }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const acl = useSelector((state) => {
    const currentWorkspace = getCurrentWorkspaceSelector(state);
    return {
      workspace: currentWorkspace.acl || {},
      account: getAccountFromSessionSelector(state).acl || {},
    };
  });

  const isGuest = Boolean(useSelector(getGuestToken));

  const currentWorkspace = useSelector(getCurrentWorkspaceSelector);

  const navigateTo = (path: string) => {
    if (history.location.pathname !== path) {
      history.push(path);
    }
  };

  if (!hasSomeAcl({ ...acl.account, ...acl.workspace }, ACL_VALUES)) {
    return null;
  }

  return (
    <>
      <Acl acl={acl.account} check={ACLs.page.FIND_IN_MARKETPLACE}>
        <CommandItem
          id="search-marketplace"
          value={`${message({
            id: 'Search',
            comment: 'Search action for marketplace',
          })} ${message({
            id: 'Marketplace',
            comment: 'Name of the marketplace section',
          })}`}
          keywords={[
            message({
              id: 'global',
              comment: 'Keyword for global search functionality in marketplace',
            }) as string,
          ]}
          onSelect={() => {
            pushPage('marketplace');
          }}
        >
          <MagnifyingGlassIcon height="16px" width="20px" />
          <Message
            id="Search"
            comment="Search button text in the marketplace section"
          />
          {' '}
          <Message
            id="marketplace"
            comment="Name of the marketplace section in the command menu"
          />
        </CommandItem>
      </Acl>
      <Command.Group id="navigation-group" headingId="navigation-heading" heading="Navigation">
        <CommandItem
          id="navigate-to-search"
          value={`${message({
            id: 'Go to',
            comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
          })} ${message({
            id: 'Search',
            comment: 'Name of the search page',
          })}`}
          keywords={[
            message({
              id: 'global',
              comment: 'Keyword for global search navigation',
            }) as string,
          ]}
          onSelect={pipe(closeCmdk, () => {
            navigateTo('/search');
          })}
        >
          <ArrowRightIcon height="20px" />
          <Message
            id="Go to"
            comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
          />
          {' '}
          <Message
            id="search"
            comment="Name of the search page in navigation menu"
          />
        </CommandItem>
        <Acl acl={acl.workspace} check={ACLs.workspace.NAVIGATE_TO_DASHBOARD}>
          <CommandItem
            id="navigate-to-dashboard"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'Dashboard',
              comment: 'Name of the dashboard page',
            })}`}
            onSelect={pipe(closeCmdk, () => {
              navigateTo(withWorkspaceNamespace(currentWorkspace)('/dashboard'));
            })}
          >
            <ArrowRightIcon height="20px" />
            <Message
              id="Go to"
              comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
            />
            {' '}
            <Message
              id="dashboard"
              comment="Name of the dashboard page in navigation menu"
            />
          </CommandItem>
        </Acl>
        <Acl acl={acl.workspace} check={ACLs.workspace.NAVIGATE_TO_DOCUMENTS}>
          <CommandItem
            id="navigate-to-documents"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'Documents',
              comment: 'Name of the documents page',
            })}`}
            keywords={[
              message({
                id: 'agreements',
                comment: 'Alternative keyword for documents section',
              }) as string,
              message({
                id: 'contracts',
                comment: 'Alternative keyword for documents section',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo(withWorkspaceNamespace(currentWorkspace)('/contracts/all'));
            })}
          >
            <ArrowRightIcon height="20px" />
            <Message
              id="Go to"
              comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
            />
            {' '}
            <Message
              id="documents"
              comment="Name of the documents page in navigation menu"
            />
          </CommandItem>
        </Acl>

        <Acl acl={acl.workspace} check={ACLs.workspace.NAVIGATE_TO_DOCUMENTS}>
          <CommandItem
            id="navigate-to-calendar"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'Calendar',
              comment: 'Name of the calendar page',
            })}`}
            onSelect={pipe(closeCmdk, () => {
              navigateTo(withWorkspaceNamespace(currentWorkspace)('/contracts/calendar'));
            })}
          >
            <ArrowRightIcon height="20px" />
            <Message
              id="Go to"
              comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
            />
            {' '}
            <Message
              id="calendar"
              comment="Name of the calendar page in navigation menu"
            />
          </CommandItem>
        </Acl>

        <Acl acl={acl.workspace} check={ACLs.workspace.NAVIGATE_TO_TEMPLATES}>
          <CommandItem
            id="navigate-to-templates"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'Templates',
              comment: 'Name of the templates page',
            })}`}
            onSelect={pipe(closeCmdk, () => {
              navigateTo(withWorkspaceNamespace(currentWorkspace)('/templates'));
            })}
          >
            <ArrowRightIcon height="20px" />
            <Message
              id="Go to"
              comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
            />
            {' '}
            <Message
              id="templates"
              comment="Name of the templates page in navigation menu"
            />
          </CommandItem>
        </Acl>
        <Acl acl={acl.workspace} check={ACLs.workspace.NAVIGATE_TO_ADDRESS_BOOK}>
          <CommandItem
            id="navigate-to-address-book"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'Address book',
              comment: 'Name of the address book page',
            })}`}
            onSelect={pipe(closeCmdk, () => {
              navigateTo(withWorkspaceNamespace(currentWorkspace)('/address-book'));
            })}
          >
            <ArrowRightIcon height="20px" />
            <Message
              id="Go to"
              comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
            />
            {' '}
            <Message
              id="address book"
              comment="Name of the address book page in navigation menu"
            />
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_MARKETPLACE}>
          <CommandItem
            id="navigate-to-account-marketplace"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'Marketplace',
              comment: 'Name of the marketplace page',
            })}`}
            keywords={[
              message({
                id: 'extensions',
                comment: 'Alternative keyword for marketplace section',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/marketplace');
            })}
          >
            <ArrowRightIcon height="20px" />
            <Message
              id="Go to"
              comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
            />
            {' '}
            <Message
              id="marketplace"
              comment="Name of the marketplace page in navigation menu"
            />
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_ACCOUNT_OVERVIEW}>
          <CommandItem
            id="navigate-to-account"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'Account overview',
              comment: 'Name of the account overview page in admin section',
            })}`}
            keywords={[
              message({
                id: 'admin',
                comment: 'Keyword for admin section navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/account');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Account overview"
                comment="Name of the account overview page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_ACCOUNT_ACCESS}>
          <CommandItem
            id="navigate-to-account-access"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'Account access',
              comment: 'Name of the account access page in admin section',
            })}`}
            keywords={[
              message({
                id: 'admin',
                comment: 'Keyword for admin section navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/account-access');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Account access"
                comment="Name of the account access page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_USERS}>
          <CommandItem
            id="navigate-to-account-users"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'Users',
              comment: 'Name of the users management page in admin section',
            })}`}
            keywords={[
              message({
                id: 'admin',
                comment: 'Keyword for admin section navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/users');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Users"
                comment="Name of the users management page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_WORKSPACE}>
          <CommandItem
            id="navigate-to-account-workspaces"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'Workspaces',
              comment: 'Name of the workspaces management page in admin section',
            })}`}
            keywords={[
              message({
                id: 'admin',
                comment: 'Keyword for admin section navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/workspaces');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Workspaces"
                comment="Name of the workspaces management page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_ROLES}>
          <CommandItem
            id="navigate-to-account-roles"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'roles',
              comment: 'Name of the roles management page in admin section',
            })}`}
            keywords={[
              message({
                id: 'admin',
                comment: 'Keyword for admin section navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/roles');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Roles"
                comment="Name of the roles management page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_GROUPS}>
          <CommandItem
            id="navigate-to-account-groups"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'groups',
              comment: 'Name of the groups management page in admin section',
            })}`}
            keywords={[
              message({
                id: 'admin',
                comment: 'Keyword for admin section navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/groups');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Groups"
                comment="Name of the groups management page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_TAG}>
          <CommandItem
            id="navigate-to-account-tags"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'Tags',
              comment: 'Name of the tags management page in admin section',
            })}`}
            keywords={[
              message({
                id: 'tags',
                comment: 'Keyword for tags management navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/tags');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Tags"
                comment="Name of the tags management page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_BILLING}>
          <CommandItem
            id="navigate-to-account-billing"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'Billing',
              comment: 'Name of the billing management page in admin section',
            })}`}
            keywords={[
              message({
                id: 'billing',
                comment: 'Keyword for billing management navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/billing');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Billing"
                comment="Name of the billing management page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_AUDIT_LOG}>
          <CommandItem
            id="navigate-to-account-audit-log"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'Audit log',
              comment: 'Name of the audit log page in admin section',
            })}`}
            keywords={[
              message({
                id: 'admin',
                comment: 'Keyword for admin section navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/audit-log');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Audit log"
                comment="Name of the audit log page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        <Acl acl={acl.account} check={ACLs.account.NAVIGATE_TO_ADMIN_DATA_MANAGEMENT}>
          <CommandItem
            id="navigate-to-account-data-management"
            value={`${message({
              id: 'Go to',
              comment: 'Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search',
            })} ${message({
              id: 'admin',
              comment: 'Name of the admin section in navigation menu',
            })} ${message({
              id: 'Data management',
              comment: 'Name of the data management page in admin section',
            })}`}
            keywords={[
              message({
                id: 'admin',
                comment: 'Keyword for admin section navigation',
              }) as string,
            ]}
            onSelect={pipe(closeCmdk, () => {
              navigateTo('/admin/data-management');
            })}
          >
            <ArrowRightIcon height="20px" />
            <div className={style.Text}>
              <Message
                id="Go to"
                comment="Navigation prefix text used before destination names. Examples: Go to Dashboard, Go to Search"
              />
              {' '}
              <Message
                id="admin"
                comment="Name of the admin section in navigation menu"
              />
              <ChevronRightIcon width="8px" />
              <Message
                id="Data management"
                comment="Name of the data management page in admin section"
              />
            </div>
          </CommandItem>
        </Acl>
        {/* Profile options */}
        {!isGuest && (
          <>
            <ProfileItems
              navigateTo={navigateTo}
              handleSelect={closeCmdk}
            />
          </>
        )}
      </Command.Group>
      <Command.Group
        id="help"
        headingId="help-heading"
        heading={message({
          id: 'Help',
          comment: 'Heading for the help section in command menu',
        })}
      >
        <CommandItem
          id="navigate-to-help-center"
          value={`${message({
            id: 'open',
            comment: 'Action text for opening external links',
          })} ${message({
            id: 'Help center',
            comment: 'Name of the help center external resource',
          }) as string}`}
          keywords={[
            message({
              id: 'Help',
              comment: 'Keyword for help center navigation',
            }) as string,
          ]}
          onSelect={pipe(closeCmdk, () => {
            window.open(getHelpCenterBaseUrl(), '_blank');
          })}
        >
          <ExternalLinkIcon height="16px" />
          <Message
            id="Open"
            comment="Action text for opening external links"
          />
          {' '}
          <Message
            id="help center"
            comment="Name of the help center external link"
          />
        </CommandItem>
        <CommandItem
          id="navigate-to-product-updates"
          value={`${message({
            id: 'open',
            comment: 'Action text for opening external links',
          })} ${message({
            id: 'Product updates',
            comment: 'Name of the product updates external resource',
          }) as string}`}
          keywords={[
            message({
              id: 'Help',
              comment: 'Keyword for product updates navigation',
            }) as string,
          ]}
          onSelect={pipe(closeCmdk, () => {
            window.open('https://oneflow.com/product-updates/', '_blank');
          })}
        >
          <ExternalLinkIcon height="16px" />
          <Message
            id="Open"
            comment="Action text for opening external links"
          />
          {' '}
          <Message
            id="product updates"
            comment="Name of the product updates external link"
          />
        </CommandItem>
        <CommandItem
          id="navigate-to-academy"
          value={`${message({
            id: 'open',
            comment: 'Action text for opening external links',
          })} Oneflow Academy`}
          keywords={[
            message({
              id: 'Help',
              comment: 'Keyword for Oneflow Academy navigation',
            }) as string,
          ]}
          onSelect={pipe(closeCmdk, () => {
            window.open('https://oneflow.com/academy/', '_blank');
          })}
        >
          <ExternalLinkIcon height="16px" />
          <Message
            id="Open"
            comment="Action text for opening external links"
          />
          {' '}
          Oneflow Academy
        </CommandItem>
        <CommandItem
          id="navigate-to-status"
          value={`${message({
            id: 'open',
            comment: 'Action text for opening external links',
          })} ${message({
            id: 'Oneflow status',
            comment: 'Name of the Oneflow status external resource',
          }) as string}`}
          keywords={[
            message({
              id: 'Help',
              comment: 'Keyword for Oneflow status page navigation',
            }) as string,
          ]}
          onSelect={pipe(closeCmdk, () => {
            window.open('https://status.oneflow.com', '_blank');
          })}
        >
          <ExternalLinkIcon height="16px" />
          <Message
            id="Open"
            comment="Action text for opening external links"
          />
          {' '}
          <Message
            id="Oneflow status"
            comment="Name of the Oneflow status external link"
          />
        </CommandItem>
      </Command.Group>
      <Command.Group
        id="account"
        headingId="account-heading"
        heading={message({
          id: 'Account',
          comment: 'Heading for the account section in command menu',
        })}
      >
        <CommandItem
          id="navigate-to-logout"
          value={`${message({
            id: 'Log out',
            comment: 'Text for the logout action',
          }) as string}`}
          onSelect={pipe(closeCmdk, () => {
            dispatch(logout());
          })}
        >
          <LogoutIcon height="20px" />
          <Message
            id="Log out"
            comment="Text for the logout button in account section"
          />
        </CommandItem>
      </Command.Group>
    </>
  );
};
