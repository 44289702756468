// @flow

import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { components } from 'react-select';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

import { getTranslationForDateFormat } from 'date';

import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import { useUserEvents } from 'contexts/user-events';

import Field from 'components/field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import TextArea from 'components/text-area';
import MiniContractCard from 'components/mini-contract-card';
import ContractSelectField from 'components/contract-select-field';
import { SingleDatePickerComponent } from 'components/single-date-picker';
import Button from 'components/button';
import Cancel from 'components/icons/cancel';

import AIAssistFieldButton from 'components/rich-text-editor-toolbars/toolbar-buttons/ai-assist-field-button';
import { useResetFormValuesRecipientsValidation } from './use-recipients-validation';
// eslint-disable-next-line import/no-named-as-default
import RecipientsSelectField from './recipients-select-field';
import disableHoursBeforeCurrentHourOfToday from './disable-hours-before-current-hour-of-today';

import style from './internal-reminder-body.module.scss';

export type Props = {
  currentWorkspaceId?: number,
  setSelectedContract: () => {},
  message: MessageTranslator,
  selectedContract: Agreement,
  validatedRecipients: Array<Position>,
  sendValidationEvent: () => void,
  userConsent: 'waiting' | 'given' | 'reverted'
};

const TimeOption = ({ isDisabled, ...restProps }: any) => (
  <components.Option
    className={clsx({ [style.DisabledOption]: isDisabled })}
    {...restProps}
  />
);

function InternalReminderCreateBody({
  currentWorkspaceId,
  selectedContract,
  setSelectedContract,
  validatedRecipients,
  sendValidationEvent,
  userConsent,
  message,
}: Props) {
  const { change: formValueChange } = useForm();
  const { values } = useFormState();
  const {
    currentUser,
    dateFormat,
    timeValues,
  } = useUserEvents();
  const resetFormValuesRecipientsValidation = useResetFormValuesRecipientsValidation();

  React.useEffect(() => {
    if (!isEmpty(validatedRecipients)) {
      formValueChange('recipients', validatedRecipients);
    }
  }, [validatedRecipients, formValueChange]);

  const disableField = !values?.contractFieldOption?.contract.id;

  const cancelButtonHandler = () => {
    formValueChange('contractFieldOption', undefined);
    if (userConsent === 'given') {
      resetFormValuesRecipientsValidation();
      sendValidationEvent({ type: 'validation/revert' });
    }
  };

  const cancelButton = (
    <div className={style.CancelButtonWrapper}>
      <Button
        icon={<Cancel width="10px" height="10px" />}
        onClick={cancelButtonHandler}
      />
    </div>
  );

  return (
    <>
      <div className={clsx(style.Row, style.ContractSelection)}>
        {values?.contractFieldOption?.contract?.id ? (
          <div className={style.MiniContractCardLabel}>
            <Message id="Contract " comment="label for a field displaying a contract" />
            <span className={style.MiniContractCardAsterisk}>*</span>
            <MiniContractCard
              agreement={values?.contractFieldOption?.contract}
              cancelButton={cancelButton}
            />
          </div>
        ) : (
          <ContractSelectField
            workspaceId={currentWorkspaceId}
            onChange={(contract) => {
              if (userConsent === 'given') {
                resetFormValuesRecipientsValidation();
                sendValidationEvent({ type: 'validation/revert' });
              }
              setSelectedContract(contract);
            }}
          />
        )}
      </div>
      <fieldset
        disabled={disableField}
        className={style.FormFieldset}
      >
        <RecipientsSelectField
          disabled={disableField}
          contract={selectedContract}
          currentUser={currentUser}
          onChange={(newRecipients) => {
            if (userConsent === 'given') {
              resetFormValuesRecipientsValidation(newRecipients);
              sendValidationEvent({ type: 'validation/revert' });
            }
          }}
        />
        <div className={clsx(style.Row, style.DatePickerField, {
          [style.Disabled]: disableField,
        })}
        >
          <Field
            label={message({
              id: 'Date',
              comment: 'Label for date-selection in custom reminder modal',
            })}
            name="date"
            component={SingleDatePickerComponent}
            initialVisibleMonth={null}
            placeholder={getTranslationForDateFormat({ message, dateFormat })}
            displayFormat={dateFormat}
            required
            disabled={disableField}
            customClassName={style.OverrideSingleDatePicker}
            block={false}
          />
          <Field
            label={message({
              id: 'Time',
              comment: 'Label for time-selection in custom reminder modal',
            })}
            name="timeFieldOption"
            labelKey="time"
            valueKey="time"
            placeholder={message({
              id: 'HH:MM',
              comment: 'Placeholder used in the time field in custom reminder modal',
            })}
            options={disableHoursBeforeCurrentHourOfToday(timeValues, values?.date)}
            isOptionDisabled={(option) => option.isDisabled}
            component={SelectField}
            components={{ Option: TimeOption }}
            required
            disabled={disableField}
          />
        </div>
        <div className={clsx(style.Row, style.SubjectField)}>
          <Field
            label={message({
              id: 'Subject',
              comment: 'Label for subject-field in custom reminder modal',
            })}
            name="subject"
            placeholder={message({
              id: 'Enter subject',
              comment: 'Placeholder used in the subject field in custom reminder modal',
            })}
            component={TextField}
            maxLength={150}
            required
            customClass={style.TextFieldLabel}
            disabled={disableField}
          />
        </div>
        <div className={clsx(style.Row, style.MessageField)}>
          <div className={style.AIAssistWrapper}>
            <div className={style.AIAssistContainer}>
              <AIAssistFieldButton
                agreement={selectedContract}
                context="agreementInternalReminder"
                onInsertGeneratedText={(text) => formValueChange('message', text)}
                disabled={disableField}
              />
            </div>
            <Field
              label={message({
                id: 'Message',
                comment: 'Label for message-field in custom reminder modal',
              })}
              name="message"
              placeholder={message({
                id: 'Enter message',
                comment: 'Placeholder used in the message field in custom reminder modal',
              })}
              component={TextArea}
              maxLength={4500}
              minRows={5}
              maxRows={10}
              required
              customClass={style.TextArea}
              disabled={disableField}
            />
          </div>
        </div>
      </fieldset>
    </>
  );
}

export default localize<Props>(InternalReminderCreateBody);
