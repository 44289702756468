import clsx from 'clsx';
import Message from 'components/message';

import { COLLAPSED, EXPANDED } from 'components/document-layout-container/helpers';
import PublishContractAction from 'components/document-call-to-actions/actions/buttons-call-to-action/actions/publish';
import EmailIcon from 'components/icons/email';
import type { Layout } from 'components/document-layout-container/types';

import style from './send-document-button.module.scss';

type Props = {
  canPublish: boolean,
  layout?: Layout,
  agreementId: Oneflow.Agreement['id']
}

const SendDocumentButton = ({ agreementId, canPublish, layout = EXPANDED }: Props) => {
  if (layout === COLLAPSED) {
    return (
      <PublishContractAction
        agreementId={agreementId}
        customClassName={clsx(style.SendDocumentButton, style.Collapsed)}
        canPublish={canPublish}
        data-testid="collapsed-send-document-button"
        layout={layout}
      >
        <EmailIcon height="20px" />
      </PublishContractAction>
    );
  }

  return (
    <PublishContractAction
      agreementId={agreementId}
      customClassName={clsx(style.SendDocumentButton, style.Expanded)}
      canPublish={canPublish}
      data-testid="expanded-send-document-button"
      layout={layout}
    >
      <EmailIcon height="16px" />
      <Message
        id="Send"
        comment="Button label for sending a document for signature."
      />
    </PublishContractAction>
  );
};

export default SendDocumentButton;
