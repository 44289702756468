// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { EventLog } from './event-log';

type Props = {
  participant: AgreementParticipant,
  dateFormat: string,
};

const firstViewedMessage = (
  <Message
    id="First viewed"
    comment="Visit information in participant visit card in the contract list"
  />
);

const lastViewedMessage = (
  <Message
    id="Last viewed"
    comment="Visit information in participant visit card in the contract list"
  />
);

const VisitInfo = ({
  participant,
  dateFormat,
}: Props) => {
  if (participant.visits === 1) {
    return (
      <EventLog
        label={lastViewedMessage}
        timestamp={participant.lastVisitTs}
        dateFormat={dateFormat}
      />
    );
  }

  return (
    <>
      <EventLog
        label={firstViewedMessage}
        timestamp={participant.firstVisitTs}
        dateFormat={dateFormat}
      />
      <EventLog
        label={lastViewedMessage}
        timestamp={participant.lastVisitTs}
        dateFormat={dateFormat}
      />
    </>
  );
};

export default VisitInfo;
