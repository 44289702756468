import React, { ReactElement } from 'react';

import ShowFlow from 'components/cancel-subscription/actions/show-flow';
import Submit from 'components/cancel-subscription/actions/submit';
import Close from 'components/cancel-subscription/actions/close';
import NextStep from 'components/cancel-subscription/actions/next-step';

import type { FlowData } from 'types/cancellation-flow';

type Props = {
  child: ReactElement;
  allowContinue: boolean;
  onSyncStepData: (data: FlowData) => void;
  modalData: FlowData;
  setShowModal: (flow: string) => void;
  onStepComplete: () => void;
  onClose: () => void;
}

const FlowAction = ({
  child,
  allowContinue,
  onSyncStepData,
  modalData,
  setShowModal,
  onStepComplete,
  onClose,
}: Props) => {
  const allProps = {
    ...child.props,
    allowContinue,
    onSyncStepData,
    modalData,
    setShowModal,
    onStepComplete,
    onClose,
  };
  const { children } = allProps;

  const childType = child.type;
  const nonCustomActions = [Submit, ShowFlow, Close, NextStep];

  // TO-DO: remove support for custom actions if not needed.
  if (!nonCustomActions.includes(childType)) {
    return child;
  }

  return React.createElement(childType, { ...allProps }, children);
};

export default FlowAction;
