import { getGuestToken, getMyParticipantWhenSignatory } from 'agreement/selectors';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { isDeviceWithoutHover } from 'utils/browser';
import useAgreement from 'hooks/use-agreement';
import useBankID from '../hooks/use-bankid';
import BankIDOtherDevice from './bankid-other-device';
import BankIDSameDevice from './bankid-same-device';
import { SWEDISH_BANKID_SIGNING_DEVICE } from '../constants';

import { BankIDSigningDeviceType, SignCodeState } from '../types';

export type Props = {
  signingDevice: BankIDSigningDeviceType,
  setSigningDevice: (signingDevice: BankIDSigningDeviceType) => void,
  signCodeState: SignCodeState,
  agreementId: number,
  changeButtonStatus: (prop: boolean) => void,
  changeSignErrorStatus: () => void,
  onStepComplete: () => void,
  onSuccess: () => void,
  onError: (error: string | null) => void,
}

const getBankIDAutostartURL = (autoStartToken: string) => `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
const isMobileDevice = isDeviceWithoutHover();

export const SwedishBankIDFlow = ({
  signingDevice,
  setSigningDevice,
  signCodeState,
  agreementId,
  onSuccess,
  onStepComplete,
  onError,
  changeButtonStatus,
  changeSignErrorStatus,
  ...props
}: Props) => {
  const agreement = useAgreement(agreementId);

  const myParticipantWhenSignatory = getMyParticipantWhenSignatory(
    agreement,
  ) as Oneflow.Participant;

  const guestToken = useSelector(getGuestToken);

  const handleSuccessCallback = useCallback(() => {
    onSuccess();
    onStepComplete();
  }, [onSuccess, onStepComplete]);

  const handleErrorCallback = useCallback((error) => {
    changeButtonStatus(false);
    changeSignErrorStatus();
    onError(error);
    onStepComplete();
  }, [changeButtonStatus, changeSignErrorStatus, onError, onStepComplete]);

  const { signState, hasParticipantSigned } = useBankID({
    agreementId,
    guestToken,
    participantId: myParticipantWhenSignatory?.id,
    onSuccess: handleSuccessCallback,
    onError: handleErrorCallback,
  });

  return (
    <div>
      {signingDevice === SWEDISH_BANKID_SIGNING_DEVICE.SAME ? (
        <>
          {!isMobileDevice ? (
            <div style={{ height: 0 }}>
              <iframe
                title="seBankID"
                src={getBankIDAutostartURL(signCodeState?.data?.autoStartToken)}
                frameBorder="0"
                style={{ height: 0 }}
              />
            </div>
          ) : null}
          <BankIDSameDevice
            autoStartToken={signCodeState?.data?.autoStartToken}
          />
        </>
      ) : (
        <BankIDOtherDevice
          signCodeState={signCodeState}
          handleError={handleErrorCallback}
          signingInProgress={signState.response === 'USER_SIGN'}
          hasParticipantSigned={hasParticipantSigned}
          switchToSameDevice={() => setSigningDevice(SWEDISH_BANKID_SIGNING_DEVICE.SAME)}
          {...props}
        />
      )}
    </div>
  );
};
