// @flow

import adminPage from 'hocs/admin-page';
import { Lifecycle } from 'components/pages';

export const lifecycleModule = ({ props: { message } }) => ({
  title: message({
    id: 'Calendar',
    comment: 'The page title for the calendar page.',
  }),
  showAsLink: false,
  modules: [[]],
});

export default adminPage(lifecycleModule)(Lifecycle);
