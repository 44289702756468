import type { ComponentProps, ComponentType } from 'react';

import QueryStringParams from './query-string-params-container';

const withQueryStringParamsProvider = (Component: ComponentType<any>) => {
  const WrappedComponent = (props: ComponentProps<typeof Component>) => (
    <QueryStringParams>
      <Component {...props} />
    </QueryStringParams>
  );

  return WrappedComponent;
};

export default withQueryStringParamsProvider;
