/* eslint-disable camelcase */
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { isBoxConfigUpdateAllowed } from 'agreement/box-config-update-permissions';
import { isBoxDataCreateAllowed } from 'agreement/box-data-create-permissions';
import { isBoxDataEditAllowed } from 'agreement/box-data-edit-permissions';
import { isBoxDataSharedValueUpdateAllowed } from 'agreement/box-data-shared-value-update-permissions';
import { isBoxDataValueUpdateAllowed } from 'agreement/box-data-value-update-permissions';
import useCurrentBox from 'hooks/use-current-box';
import useCurrentBoxId from 'hooks/use-current-box-id';
import type {
  Column,
  Config,
  Product,
  ProductTableBox,
} from 'data-validators/entity-schemas/document-box/product-table';

import type { FormBody } from 'components/contract-boxes/product-table-box/product-table/popup-forms/update-product-helpers';

type Props = {
  agreementId: number,
  boxId: number,
  children: React.ReactNode,
};

type EnabledColumnsKeyLabelMapper = {
  name?: string;
  description?: string;
  price_1?: string;
  price_2?: string;
  count?: string;
};

type ProductData = {
  bodyData: FormBody,
  productId: number | null,
};

type ContextValue = {
  agreementId: Props['agreementId'],
  box: ProductTableBox,
  boxId: Props['boxId'],
  columns: Column[],
  config: Config,
  data: Product[],
  enabledColumnsKeyLabelMapper: EnabledColumnsKeyLabelMapper,
  getIsAllowedToUpdateDataValue: (product: Product) => boolean,
  getIsAllowedToUpdateSharedDataValue: (product: Product) => boolean,
  isAllowedToAddBoxData: boolean,
  isAllowedToEditBoxData: boolean,
  isAllowedToUpdateBoxConfig: boolean,
  productData: ProductData,
  setProductData: (data: ProductData) => void,
}

export const ProductTableBoxContext = createContext(null) as unknown as React.Context<ContextValue>;

export const ProductTableBoxContextProvider = (props: Props) => {
  const {
    agreementId,
    boxId,
    children,
  } = props;

  const currentBoxId = useCurrentBoxId(boxId);
  const box = useCurrentBox(currentBoxId) as ProductTableBox;
  const isAllowedToAddBoxData = isBoxDataCreateAllowed(box);
  const isAllowedToEditBoxData = isBoxDataEditAllowed(box);
  const isAllowedToUpdateBoxConfig = isBoxConfigUpdateAllowed(box);

  const { config } = box;
  const { columns } = config;
  const { data } = box.content;
  const [productData, setProductData] = useState<ProductData>({
    productId: null,
    bodyData: {},
  });

  const enabledColumnsKeyLabelMapper = useMemo(() => columns?.reduce(
    (keyLabelMapper: EnabledColumnsKeyLabelMapper, column) => {
      if (!column.enabled) {
        return keyLabelMapper;
      }
      return {
        ...keyLabelMapper,
        [column.key]: column.label || '',
      };
    },
    {},
  ), [columns]);

  const getIsAllowedToUpdateDataValue = useCallback((product: Product) => (
    isBoxDataValueUpdateAllowed(box, product)
  ), [box]);

  const getIsAllowedToUpdateSharedDataValue = useCallback((product: Product) => (
    isBoxDataSharedValueUpdateAllowed(box, product)
  ), [box]);

  const contextValue = useMemo(() => ({
    agreementId,
    box,
    boxId: currentBoxId,
    columns,
    config,
    data,
    enabledColumnsKeyLabelMapper,
    getIsAllowedToUpdateDataValue,
    getIsAllowedToUpdateSharedDataValue,
    isAllowedToAddBoxData,
    isAllowedToEditBoxData,
    isAllowedToUpdateBoxConfig,
    productData,
    setProductData,
  }), [
    agreementId,
    box,
    columns,
    config,
    currentBoxId,
    data,
    enabledColumnsKeyLabelMapper,
    getIsAllowedToUpdateDataValue,
    getIsAllowedToUpdateSharedDataValue,
    isAllowedToAddBoxData,
    isAllowedToEditBoxData,
    isAllowedToUpdateBoxConfig,
    productData,
    setProductData,
  ]);

  return (
    <ProductTableBoxContext.Provider value={contextValue}>
      {children}
    </ProductTableBoxContext.Provider>
  );
};

export const useProductTableBoxContext = () => {
  const contextValue = useContext<ContextValue>(ProductTableBoxContext);

  if (!contextValue) {
    throw new Error('useProductTableBox should be used inside a ProductTableBoxContext');
  }

  return contextValue;
};
