import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { isMobileIOS } from 'utils/browser';

// This component is different form the default meta tag in that it adds maximum-scale=1 to
// the content attribute of the viewport meta tag. This is to prevent the user from zooming in
// when focusing on an input field on an iPhone.
const IphoneMetaTags = () => {
  useEffect(() => {
    if (!isMobileIOS()) {
      return;
    }
    // Find and remove any existing viewport meta tag
    const existingViewportMeta = document.querySelector('meta[name="viewport"]');

    if (existingViewportMeta) {
      existingViewportMeta.parentNode?.removeChild(existingViewportMeta);
    }
  }, []);

  if (!isMobileIOS()) {
    return null;
  }

  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, maximum-scale=1, minimum-scale=1, initial-scale=1, viewport-fit=cover" />
    </Helmet>
  );
};

export default IphoneMetaTags;
