// @flow

import React, { useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  getEndOfDayTimestamp,
  toMoment,
  getDateFormat,
} from 'date';
import { Popover, PopoverContent } from 'components/popover';
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import { useSelector, useDispatch } from 'react-redux';
import type { FormRenderProps } from 'react-final-form';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getAccountFromSessionSelector, getPositionFromSessionSelector } from 'reducers/session';
import agreementsReducer from 'reducers/entities/agreements';
import { getContractMessageTemplates } from 'oneflow-client/message-templates';
import ModalForm from 'hocs/modal-form';
import AdditionalInformation from 'components/additional-information';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import Error from 'components/icons/error';
import MessageTemplatePicker from 'components/message-template-picker';
import RecipientList from 'components/recipient-list';
import CircularSpinner from 'components/icons/circular-spinner';
import { DayPickerSingleDateController } from 'components/date-picker';
import useAgreement from 'hooks/use-agreement';
import InvitationHeader from 'components/invitation-header';
import BadgeWithIcon from 'components/badge-with-icon';
import { BADGE_READY_TO_SIGN } from 'agreement/badge-icons';

import style from './ready-to-sign.module.scss';

const modalKey = 'ready to sign modal';
export type Props = {
  agreementId: number,
  messageTemplatesDisabled?: boolean,
  message: MessageTranslator,
  isModalOpen: boolean,
  setIsModalOpen: Function,
  syncState: Function,
};

type State = {
  contractMessageTemplates: Array<MessageTemplate>,
  selectedMessageTemplate: any,
  defaultMessageTemplate: any,
  isLoadingMessageTemplates: boolean,
  error: any,
  isPopoverOpen: boolean,
  focused: boolean,
}

export const setInitialVisibleMonth = () => moment();

export const ReadyToSignComponent = ({
  agreementId,
  messageTemplatesDisabled,
  message,
  isModalOpen,
  setIsModalOpen,
  syncState,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const account = useSelector((state) => getAccountFromSessionSelector(state));
  const workspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  const position = useSelector((state) => getPositionFromSessionSelector(state));
  useSelector((state) => agreementsReducer.getUpdateExpiryDateSelector(state, {
    id: agreement.id,
  }));
  const updateState = useSelector((state) => (
    agreementsReducer.getUpdateAgreementPreferencesSelector(state, { id: agreementId })
  ));
  const dispatch = useDispatch();

  const resetUpdateState = () => {
    dispatch(agreementsReducer.updateAgreementPreferencesReset({
      id: agreementId,
    }));
  };

  const brandingDateFormat = workspace.brandingDateFormat || account.brandingDateFormat;
  const dateFormat = getDateFormat(brandingDateFormat);

  const standardMessageTemplate = {
    id: 'standard_message',
    name: message({
      id: 'Standard message',
      comment: 'This will be used as the name of the message template',
    }),
    body: message({
      id: 'Your colleague {publisher} needs help with a draft before sending it to the counterparty.',
      comment: 'This will be used as the body of notification email',
      values: {
        publisher: position?.fullname,
      },
    }),
    subject: message({
      id: 'Your colleague {publisher} invited you',
      comment: 'This will be used as a subject for the notification email',
      values: {
        publisher: position?.fullname,
      },
    }),
  };

  const initialState: State = {
    contractMessageTemplates: [],
    error: null,
    hasSucceeded: false,
    isLoadingMessageTemplates: false,
    isOpen: isModalOpen,
    isPopoverOpen: false,
    focused: true,
  };

  const getAgreementExpiryDate = () => {
    if (agreement.expire_date) {
      return moment(agreement.expire_date);
    }
    if (agreement.expireDate) {
      return moment(agreement.expireDate);
    }
    return null;
  };
  const [state, setState] = useState({ ...initialState });
  const [currentExpireDate, setCurrentExpireDate] = useState(getAgreementExpiryDate());

  const {
    contractMessageTemplates,
    selectedMessageTemplate,
    isPopoverOpen,
    focused,
  } = state;

  const onFocusChange = () => {
    // Force the focused states to always be truthy so that date is always selectable
    setState((prevState) => ({
      ...prevState,
      focused: true,
    }));
  };

  const closePopover = () => {
    setState((prevState) => ({
      ...prevState,
      isPopoverOpen: false,
    }));
  };

  const togglePopover = (e: SyntheticInputEvent<*>) => {
    e.stopPropagation();

    setState((prevState) => ({
      ...prevState,
      isPopoverOpen: !prevState.isPopoverOpen,
    }));
  };

  const getExpiryTimestamp = () => {
    const expiryTimestamp = toMoment(getEndOfDayTimestamp(currentExpireDate));
    const formattedExpiryTimestamp = expiryTimestamp.format(dateFormat);

    if (currentExpireDate) {
      return formattedExpiryTimestamp;
    }

    return (
      <Message
        id="Doesn't expire"
        comment="The label of the signing period button when there's no expiry date"
      />
    );
  };

  const onDateChange = (date) => {
    setCurrentExpireDate(date);
    closePopover();
  };

  const clearExpiryDate = () => {
    setCurrentExpireDate(null);
    closePopover();
  };

  const isOutsideRange: IsOutsideRange = (day) => (
    day < moment().startOf('day')
  );

  const getDatePickerDate = () => {
    if (currentExpireDate) {
      return moment(currentExpireDate);
    }

    return null;
  };

  const handleClose = () => {
    syncState();
    setIsModalOpen(false);
  };

  const renderDatePicker = () => (
    <div className={style.DatePicker}>
      <DayPickerSingleDateController
        numberOfMonths={1}
        date={getDatePickerDate()}
        onDateChange={onDateChange}
        hideKeyboardShortcutsPanel
        focused={focused}
        onFocusChange={onFocusChange}
        isOutsideRange={isOutsideRange}
      />
      <Button
        outline
        onClick={clearExpiryDate}
        customClass={style.ClearButton}
      >
        <Message
          id="Clear"
          comment="The label of the button that clears the previously selected signing period."
        />
      </Button>
    </div>
  );

  const renderPopover = () => (
    <Popover open={isPopoverOpen}>
      <PopoverTriggerButton
        kind="linkInline"
        outline
        onClick={togglePopover}
        customClass={style.PopoverTriggerButton}
      >
        {getExpiryTimestamp()}
      </PopoverTriggerButton>
      <PopoverContent
        onEscapeKeyDown={closePopover}
        onInteractOutside={closePopover}
        className={style.PopoverContent}
      >
        {renderDatePicker()}
      </PopoverContent>
    </Popover>
  );

  const updateSelectedMessageTemplate = (selectedMessageTemplateOption?: MessageTemplate) => {
    setState((prevState) => ({
      ...prevState,
      selectedMessageTemplate: selectedMessageTemplateOption,
    }));
  };

  const getMessageBody = () => {
    if (messageTemplatesDisabled) {
      return undefined;
    }

    if (position.signature && !isEmpty(selectedMessageTemplate) && selectedMessageTemplate.body) {
      return `${selectedMessageTemplate.body}\n\n${position.signature}`;
    }

    if (position.signature) {
      return position.signature;
    }

    if (selectedMessageTemplate && selectedMessageTemplate.body) {
      return selectedMessageTemplate.body;
    }

    return undefined;
  };

  const fetchMessageTemplates = async () => {
    if (messageTemplatesDisabled) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      isLoadingMessageTemplates: true,
      error: null,
    }));
    try {
      const { collection: contractMessageTemplatesData } = await getContractMessageTemplates({
        contractId: agreement.id,
        type: 'publish',
      });

      setState((prevState) => ({
        ...prevState,
        contractMessageTemplates: [standardMessageTemplate, ...contractMessageTemplatesData],
        isLoadingMessageTemplates: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        contractMessageTemplates: [],
        isLoadingMessageTemplates: false,
        error,
      }));
    }
  };

  const resetForm = () => {
    resetUpdateState();
    setState((prevState) => ({
      ...prevState,
      contractMessageTemplates: [],
      selectedMessageTemplate: undefined,
      isLoadingMessageTemplates: false,
      error: null,
    }));
  };

  const handleOpen = () => {
    fetchMessageTemplates();
    updateSelectedMessageTemplate(contractMessageTemplates[0]);
  };

  const handleSubmit = ({ subject, body }) => {
    dispatch(agreementsReducer.updateAgreementPreferences({
      id: agreementId,
      data: {
        id: agreementId,
        config: {
          signLater: !agreement.config.signLater,
        },
        subject,
        message: body,
        expireDate: currentExpireDate ? currentExpireDate.format('YYYY-MM-DD') : null,
      },
    }));
  };

  const renderReadyToSignInfo = () => (
    <div data-testid="ready-to-sign" className={style.Container}>
      <BadgeWithIcon type={BADGE_READY_TO_SIGN} />
      <Message
        id="The message will be sent to participants that haven't signed the document yet."
        comment="Text explaining to turn off the sign later setting so the participants can sign."
      />
      <p className={style.ExpiryDate}>
        <Message
          id="Adjust the signing period"
          comment="Text in ready to sign modal"
        />
        :
        {renderPopover()}
      </p>
    </div>
  );

  const renderRecipientsList = () => (
    <RecipientList
      agreement={agreement}
      undecidedParticipantsOnly
      currentSignOrderBlockOnly
      isInternalApproverList={false}
    />
  );

  const renderBody = () => {
    const {
      error,
      isLoadingMessageTemplates,
    } = state;
    if (isEmpty(agreement)) {
      return null;
    }

    if (isLoadingMessageTemplates) {
      return (
        <div className={style.SpinnerContainer}>
          <CircularSpinner />
        </div>
      );
    }

    if (error) {
      return (
        <span className={style.ErrorMessage}>
          <Error className={style.ErrorIcon} />
          <Message
            id="An error has occurred. Please reload the page and try again."
            comment="Used to show the error message after confirmation."
          />
        </span>
      );
    }

    return (
      <div>
        <InvitationHeader />
        <div className={style.BodyContainer}>
          {renderReadyToSignInfo()}
          {renderRecipientsList()}
          <MessageTemplatePicker
            contractMessageTemplates={contractMessageTemplates}
            selectedMessageTemplate={selectedMessageTemplate}
            updateSelectedMessageTemplate={updateSelectedMessageTemplate}
            isSubjectFieldRequired
            isMessageFieldRequired
          />
          <AdditionalInformation />
        </div>
      </div>
    );
  };

  const getActions = ({ formProps }: FormRenderProps) => (
    <>
      <CancelButton onClick={() => setIsModalOpen(false)} modalKey={modalKey} />
      <Button
        data-testid="ready-to-sign"
        kind="primary"
        onClick={formProps.handleSubmit}
        disabled={(
          formProps.hasValidationErrors
        )}
      >
        <Message
          id="Confirm"
          comment="Button text for confirming that you are ready to sign"
        />
      </Button>
    </>
  );

  return (
    <ModalForm
      title={(
        <Message
          id="Ready to sign?"
          comment="The title of the ready to sign modal"
        />
      )}
      body={renderBody()}
      isOpen={isModalOpen}
      onSubmit={handleSubmit}
      resetFormState={resetForm}
      formState={updateState}
      actions={getActions}
      onClose={handleClose}
      onOpen={handleOpen}
      initialValues={{
        subject: selectedMessageTemplate ? selectedMessageTemplate.subject : undefined,
        body: getMessageBody(),
      }}
      modalKey={modalKey}
    >
      {() => null}
    </ModalForm>
  );
};

export default localize<Props>(ReadyToSignComponent);
