import { Message } from '@oneflowab/pomes';

import style from './self-label.module.scss';

const SelfLabel = () => (
  <span className={style.SelfLabel}>
    <Message
      id="You"
      comment="Label for denoting your own participant in a document"
    />
  </span>
);

export default SelfLabel;
