import { ReactElement } from 'react';
import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import Toggle from 'components/toggle';
import Tooltip from 'components/tooltip';

import style from './counterparty-toggle.module.scss';

type Props = {
  title: ReactElement,
  value: boolean,
  onChange: (checkboxValue: boolean) => void,
  disabled: boolean,
};

const CounterpartyToggle = ({
  title,
  value,
  onChange,
  disabled,
}: Props) => {
  const handleChange = (checked: boolean) => onChange(checked);

  const titleClasses = clsx(style.Title, {
    [style.Disabled]: disabled,
  });

  return (
    <Tooltip
      message={(
        <Message
          id="This option can only be changed in the document template."
          comment="Tooltip message explaining why users cannot toggle the option."
        />
      )}
      side="top"
      zIndex="10003"
      hideContent={!disabled}
      sideOffset={5}
    >
      <div className={clsx(style.CounterpartyToggle, { [style.Disabled]: disabled })}>
        <span className={titleClasses}>{title}</span>
        <Toggle
          version="latest"
          checked={value}
          disabled={disabled}
          onChange={handleChange}
        />
      </div>
    </Tooltip>
  );
};

export default CounterpartyToggle;
