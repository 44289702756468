// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Transition } from 'react-transition-group';

import styles from './fade-in.module.scss';

type TimeoutProps = {|
  enter?: number,
  exit?: number,
|};

type TransitionState = 'entering' | 'entered' | 'exiting' | 'exited';

type Props = {
  children: React.Node,
  className?: string,
  in: boolean,
  timeout?: number | TimeoutProps,
  transitionTime?: number,
  unmountOnExit?: boolean,
};

class FadeIn extends React.PureComponent<Props> {
  static defaultProps = {
    className: undefined,
    timeout: {
      enter: 0,
      exit: 100,
    },
    unmountOnExit: true,
  }

  renderChildren = (transitionState: TransitionState) => {
    const { children, className, transitionTime } = this.props;

    return (
      <div
        className={clsx(
          className,
          styles.FadeIn,
          {
            [styles.Enter]: transitionState === 'entering',
            [styles.EnterActive]: transitionState === 'entered',
            [styles.Exit]: transitionState === 'exiting',
            [styles.ExitActive]: transitionState === 'exited',
          },
        )}
        style={{ transition: `${transitionTime || 1000}ms` }}
      >
        {children}
      </div>
    );
  }

  render() {
    const { children, ...transitionProps } = this.props;

    return (
      <Transition
        appear
        {...transitionProps}
      >
        {this.renderChildren}
      </Transition>
    );
  }
}

export default FadeIn;
