// @flow

import isEmpty from 'lodash/isEmpty';

import { checkAcl } from 'components/acl';

type WorkspacePermissionHandler = ?Workspace => boolean;

export const allowTemplateMove: WorkspacePermissionHandler = (workspace) => (
  !isEmpty(workspace)
    && checkAcl(workspace.acl, 'collection:agreement:template:move')
);

export const allowTemplateView: WorkspacePermissionHandler = (workspace) => (
  !isEmpty(workspace)
    && checkAcl(workspace.acl, 'collection:agreement:template:view')
);
export const allowContractMove = (workspace: Workspace) => checkAcl(workspace.acl, 'collection:agreement:move');
