// @flow

import { normalize, schema } from 'normalizr';

import { STATE_TEMPLATE } from 'agreement/constants';

import {
  get,
  getMultiple,
  post,
  put,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import type { SortParams } from 'oneflow-client/core';
import { agreementSchema } from './agreements';
import type { AgreementNormalizer, NormalizedAgreements } from './agreements';

const agreementTemplateSchema = new schema.Entity('agreementTemplates', {
  agreement: agreementSchema,
});

const agreementWithTemplatesSchema = new schema.Entity('agreements', {
  // capital T due to camelize transforming '_template' to 'Template'
  Template: agreementTemplateSchema,
});

export const normalizeAgreementWithTemplate: AgreementNormalizer = (agreement) => ({
  ...normalize(agreement, agreementWithTemplatesSchema),
  count: 1,
});

export type NormalizedAgreementTemplates = {
  entities: {
    agreements: {
      [number]: Agreement,
    },
    agreementTemplates: {
      [number]: AgreementTemplate,
    },
    tagConnections: {
      [number]: TagConnection,
    },
  },
  result: number | Array<number>,
  count: number,
}

type AgreementTemplateNormalizer = (any) => NormalizedAgreementTemplates;

export const normalizeAgreementTemplate: AgreementTemplateNormalizer = (template) => ({
  ...normalize(template, agreementTemplateSchema),
  count: 1,
});

export const normalizeAgreementTemplates: AgreementTemplateNormalizer = (templates) => ({
  ...normalize(templates.collection, [agreementTemplateSchema]),
  count: templates.count,
});

type GetAgreementTemplates = ({
  params: {
    workspaceId: number,
    visible?: number,
    q?: string,
    tags?: Array<number>,
    templateGroupId?: number,
  },
  pagination?: Pagination,
  sorting?: SortParams,
}) => Promise<NormalizedAgreementTemplates>;

const DEFAULT_SORT = { attr: 'name', direction: 'asc' };
export const getAgreementTemplates: GetAgreementTemplates = ({
  params,
  pagination,
  sorting = DEFAULT_SORT,
}) => (
  getMultiple({
    url: '/templates/',
    params: {
      folder_ids: [params.folderId],
      collection_ids: [params.workspaceId],
      visible: params.visible,
      q: params.q,
      tags: params.tags,
      template_group_id: params.templateGroupId,
    },
    pagination,
    sorting,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAgreementTemplates)
);

type CreateAgreementTemplate = ({
  workspaceId: number,
  sourceId?: number,
  amplitudeScope?: string,
}) => Promise<NormalizedAgreements>;

export const createAgreementTemplate: CreateAgreementTemplate = (
  { workspaceId, sourceId, amplitudeScope },
) => (
  post({
    url: '/agreements/',
    body: {
      source_id: sourceId,
      collection_id: workspaceId,
      state: STATE_TEMPLATE,
      source: 'oneflow_client',
    },
  }, { amplitudeScope })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAgreementWithTemplate)
    .then((data) => {
      // Need to remove the extra 'Template' attribute on
      // the agreement we get when creating a template
      const newData = data;
      delete newData.entities.agreements[data.result].Template;
      return newData;
    })
);

type UpdateAgreementTemplate = ({
  id: number,
  visible: 0 | 1,
}) => Promise<NormalizedAgreementTemplates>;

export const updateAgreementTemplate: UpdateAgreementTemplate = ({ id, visible }) => (
  put({
    url: `/templates/${id}`,
    body: { visible },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAgreementTemplate)
);

type RemoveAgreementTemplates = ({
  ids: Array<number>,
  templateIds: Array<number>,
}) => Promise<{}>;

export const removeAgreementTemplates: RemoveAgreementTemplates = ({ ids }) => (
  remove({ url: '/agreements/', body: { ids } })
    .then(extractResponseBodyAsJSON)
);

type ShareAgreementTemplate = ({
  agreementId: number,
  workspaces: Array<{
    id: number,
    enabled: boolean,
  }>
}) => Promise<{}>;

export const shareAgreementTemplate: ShareAgreementTemplate = ({ agreementId, workspaces }) => (
  post({
    url: `/agreements/${agreementId}/share`,
    body: {
      collections: workspaces,
    },
  }).then(extractResponseBodyAsJSON)
);

type AgreementTemplateListShares = ({
  agreementId: number,
}) => Promise<{}>;

export const agreementTemplateListShares: AgreementTemplateListShares = ({ agreementId }) => (
  get({
    url: `/agreements/${agreementId}/share/`,
  }).then(extractResponseBodyAsJSON)
);
