import clsx from 'clsx';
import { forwardRef, useLayoutEffect, useState } from 'react';
import type { ComponentProps, ReactNode } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { useSpring, animated } from 'react-spring';

import style from './dialog.module.scss';

const STATE = {
  OPEN: 0,
  CLOSING: 1,
  CLOSED: 2,
};

type Props = {
  isOpen: boolean;
  onRest?: () => void;
  children: ReactNode;
} & ComponentProps<typeof RadixDialog.Content>;

const Dialog = forwardRef<HTMLDivElement, Props>(({
  isOpen,
  children,
  onRest: onRestProp,
  className,
  ...rest
}: Props, ref) => {
  const [state, setState] = useState(STATE.CLOSED);

  const onRest = () => {
    if (state === STATE.CLOSING) {
      setState(STATE.CLOSED);
      onRestProp?.();
    }
  };

  const open = state === STATE.OPEN;

  const styles = useSpring({
    config: {
      tension: open ? 1500 : 2500,
      friction: 100,
      precision: open ? 0.001 : 0.01,
    },
    from: {
      opacity: 0,
      scale: 0.98,
    },
    to: open ? {
      opacity: 2,
      scale: 1,
    } : {
      opacity: 0,
      scale: 0.98,
    },
    onRest,
  });

  useLayoutEffect(() => {
    if (isOpen) {
      setState(STATE.OPEN);
      return;
    }
    if (!isOpen && state === STATE.OPEN) {
      setState(STATE.CLOSING);
    }
    // to sync the state when the dialog is controlled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onOpenChange = (value: boolean) => {
    if (!value) {
      setState(STATE.CLOSING);
    }
  };

  return (
    <RadixDialog.Root open={state !== STATE.CLOSED} onOpenChange={onOpenChange}>
      {state !== STATE.CLOSED && (
        <>
          <RadixDialog.Overlay
            className={style.Overlay}
            forceMount
            onClick={() => onOpenChange(false)}
          />
          <RadixDialog.Content forceMount className={style.RadixDialogContent}>
            <div className={clsx(style.ContentWrapper, className)} {...rest} ref={ref}>
              <animated.div style={styles} className={style.Content}>
                {children}
              </animated.div>
            </div>
          </RadixDialog.Content>
        </>
      )}
    </RadixDialog.Root>
  );
});

Dialog.displayName = 'Dialog';

export default Dialog;
