import { useLayoutEffect, useState } from 'react';

import useLazyRef from './use-lazy-ref';

/** Imperatively run a function on the next layout effect cycle. */
const useScheduleLayoutEffect = () => {
  const [rerender, setRerender] = useState<object>();
  const functions = useLazyRef(() => new Map<(string | number), () => void>());

  useLayoutEffect(() => {
    functions.current.forEach((_function) => _function());
    functions.current = new Map();
  //  `s` is used to trigger a re-render when the schedule is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerender]);

  return (id: string | number, cb: () => void) => {
    functions.current.set(id, cb);
    setRerender({});
  };
};

export default useScheduleLayoutEffect;
