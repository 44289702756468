// @flow

import React, { useState, useCallback } from 'react';
import { isEmpty, partial } from 'lodash';
import { matchPath } from 'react-router';
import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { getLocationSelector } from 'reducers/router';
import { UserEventsProvider } from 'contexts/user-events';
import useFeatureFlag from 'hooks/use-feature-flag';

import { checkAcl } from 'components/acl';
import Button from 'components/button';
import ChevronDownIcon from 'components/icons/chevron-down';
import ChevronUpIcon from 'components/icons/chevron-up';
import CircularSpinner from 'components/icons/circular-spinner';
import ContractId from 'components/contract-id';
import ContractInsight from 'components/contract-insight';
import ContractInternalRemindersList from 'components/contract-internal-reminders-list';
import ContractNameLink from 'components/contract-name/contract-name-link';
import ContractParties from 'components/contract-parties';
import ContractValue from 'components/contract-value';
import CreateInternalReminderIcon from 'components/icons/create-internal-reminder';
import LinkIcon from 'components/icons/link';
import State from 'components/document-information/state';
import Tags from 'components/tags';
import Tooltip from 'components/tooltip';
import ContractLinksList from 'components/contract-links-list/contract-links-list';
import ContractActions from 'components/contract-actions/contract-actions';
import Checkbox from 'components/checkbox';

import {
  isDraft,
  isPending,
  isOverdue,
  isAnySignedState,
  isMarkedAsSigned,
  isAnyDeclinedState,
  hasDraftApprovalFlow,
} from 'agreement';
import isDraftApproved from 'agreement/actions/is-draft-approved';

import ContractAlert from './contract-alert';
import Participants from './participants';
import style from './contract-card.module.scss';
import InternalReminderModal from '../lifecycle-calendar/internal-reminder-modal';

type Props = {|
  agreement: Agreement,
  counterparties: Array<AgreementParty>,
  // eslint-disable-next-line react/no-unused-prop-types
  dataTestId?: string,
  dateFormat: string,
  disabled?: boolean,
  fetchInternalReminders: () => void,
  fetchInternalRemindersRpcState: RpcState,
  fetchLinks: () => void,
  fetchLinksRpcState: RpcState,
  isReadOnly?: boolean,
  onClick?: (agreement: Agreement, event: Event) => void,
  participants: Array<AgreementParticipant>,
  queryName: string,
  showFolderPath: boolean,
  showWorkspaceName: boolean,
  withoutWorkspaceId: boolean,
  workspaceId: number,
  selected?: boolean,
  onSelect?: (id: string) => void,
  disableTooltip?: boolean,
|};

const renderExpandIcon = (rpcState: RpcState, expanded: boolean) => {
  if (rpcState.loading) {
    return <CircularSpinner height="10px" />;
  }

  if (expanded) {
    return <ChevronUpIcon height="10px" />;
  }

  return <ChevronDownIcon height="10px" />;
};

export const ContractCard = ({
  agreement,
  counterparties,
  dateFormat,
  disabled,
  fetchInternalReminders,
  fetchInternalRemindersRpcState,
  fetchLinks,
  fetchLinksRpcState,
  isReadOnly,
  onClick,
  participants,
  queryName,
  showFolderPath,
  showWorkspaceName,
  withoutWorkspaceId,
  workspaceId,
  selected,
  onSelect,
  disableTooltip,
}: Props) => {
  const [expandedLinksList, toggleExpandedLinksList] = useState(false);
  const [expandedRemindersList, toggleExpandedRemindersList] = useState(false);
  const hasAccessToLinkContract = checkAcl(agreement.acl, 'agreement:link:view');
  const location = useSelector(getLocationSelector);
  const isBulkActionsBarEnabled = useFeatureFlag('temporaryBulkMoveDelete');

  const rightContainerClasses = clsx(style.RightContainer, {
    [style.Draft]: isDraft(agreement),
    [style.Pending]: (isPending(agreement) && !hasDraftApprovalFlow(agreement))
    || (isDraftApproved(agreement) && isPending(agreement)),
    [style.Overdue]: isOverdue(agreement),
    [style.Signed]: isAnySignedState(agreement),
    [style.Declined]: isAnyDeclinedState(agreement),
  });

  const className = clsx(style.ContractCard, {
    [style.Disabled]: disabled,
  });

  const linksContainerClasses = clsx(style.LinksContainer, {
    [style.Expanded]: expandedLinksList && !isEmpty(agreement.links),
    [style.BothExpanded]: expandedLinksList && expandedRemindersList,
  });

  const remindersContainerClasses = clsx(style.ContractInternalRemindersContainer, {
    [style.Expanded]: expandedRemindersList && !isEmpty(agreement.reminders),
    [style.BothExpanded]: expandedLinksList && expandedRemindersList,
  });

  const handleExpandList = ({ closeOtherList, closeList, openList }) => {
    if (closeOtherList) {
      closeList();
      setTimeout(() => {
        openList();
      }, 500);
    } else {
      openList();
    }
  };

  const onExpandRemindersToggle = useCallback(() => {
    handleExpandList({
      closeOtherList: expandedLinksList,
      closeList: () => toggleExpandedLinksList(!expandedLinksList),
      openList: () => toggleExpandedRemindersList(!expandedRemindersList),
    });

    if (!expandedRemindersList) {
      fetchInternalReminders();
    }
  }, [expandedRemindersList, fetchInternalReminders, expandedLinksList]);

  const onExpandLinksToggle = useCallback(() => {
    handleExpandList({
      closeOtherList: expandedRemindersList,
      closeList: () => toggleExpandedRemindersList(!expandedRemindersList),
      openList: () => toggleExpandedLinksList(!expandedLinksList),
    });

    if (!expandedLinksList && isEmpty(agreement.links) && !fetchLinksRpcState.loading) {
      fetchLinks();
    }
  }, [agreement, expandedLinksList, fetchLinks, fetchLinksRpcState, expandedRemindersList]);

  const isOnGlobalSearchPage = () => matchPath(location.pathname, {
    path: '/search',
    exact: false,
  });

  const renderLinkListButton = () => {
    if (!hasAccessToLinkContract || !agreement.linkCount || isReadOnly) {
      return null;
    }

    return (
      <Tooltip
        message={(
          <Message id="Links" comment="Links tooltip text" />
        )}
        side="top"
        sideOffset={4}
      >
        <div>
          <Button
            icon={(
              <LinkIcon height="10px" />
            )}
            customClass={style.LinksButton}
            onClick={onExpandLinksToggle}
          >
            <div className={style.LinksButtonLabel}>
              {agreement.linkCount}
            </div>
            {renderExpandIcon(fetchLinksRpcState, expandedLinksList)}
          </Button>
          <Button
            icon={(<LinkIcon height="10px" />)}
            type="button"
            customClass={style.LinksButtonSmall}
            onClick={onExpandLinksToggle}
          >
            {agreement.linkCount}
            {renderExpandIcon(fetchLinksRpcState, expandedLinksList)}
          </Button>
        </div>
      </Tooltip>
    );
  };

  const renderLinkList = () => {
    if (isEmpty(agreement.links) || !hasAccessToLinkContract) {
      return null;
    }

    return <ContractLinksList agreement={agreement} />;
  };

  const renderContractValue = () => {
    if (!agreement?.agreementValue) {
      return null;
    }

    return (
      <ContractValue
        agreement={agreement}
        agreementValue={agreement.agreementValue}
        className={style.ContractValue}
      />
    );
  };

  return (
    <div
      className={className}
      data-feature-ai-review={checkAcl(agreement.acl, 'agreement:ai_review:view')}
    >
      <div className={style.ContractCardMainArea}>
        <div className={style.ContractInformation}>
          {isBulkActionsBarEnabled && onSelect && (
            <div className={style.CheckboxContainer}>
              <Checkbox
                input={{
                  checked: selected,
                }}
                onPointerDown={partial(onSelect, agreement.id)}
              />
            </div>
          )}
          <div className={style.ContractInformationContent}>
            <div className={style.NameRow}>
              <ContractAlert participants={participants} />
              <ContractNameLink
                agreement={agreement}
                onClick={onClick}
                // When contract card is being rendered in contract view inside the link to modal
                // we want to open the link in a new tab
                target={isReadOnly ? '_blank' : undefined}
                className={style.NameLink}
                disableTooltip={disableTooltip}
              />
              <div className={style.StateContainer}>
                <State agreement={agreement} isClickable={false} />
              </div>
            </div>
            <ContractParties agreement={agreement} hideIfNoContractName />
            <ContractInsight
              agreement={agreement}
              dateFormat={dateFormat}
              isOnGlobalSearchPage={isOnGlobalSearchPage()}
              showFolderPath={showFolderPath}
              showWorkspaceName={showWorkspaceName}
            />
            {renderContractValue()}
            <div className={style.ActionsContainer}>
              <Tags
                targetId={agreement.id}
                targetType="agreement"
                allowTagChange={!isReadOnly && checkAcl(agreement.acl, 'agreement:tag:use')}
                allowSearch={!isReadOnly}
                amplitudeScope="contract list"
                responsive
                searchGlobally={withoutWorkspaceId}
              />
              {(Boolean(agreement.linkCount) || Boolean(agreement?.userEventsCount)) && (
                <div className={style.CardEventActionButtons}>
                  {renderLinkListButton()}
                  {Boolean(agreement?.userEventsCount) && !isReadOnly && (
                    <Tooltip
                      message={(
                        <Message id="Reminders" comment="Reminders tooltip text" />
                      )}
                      side="top"
                      sideOffset={4}
                    >
                      <div>
                        <Button
                          icon={(<CreateInternalReminderIcon height="10px" />)}
                          type="button"
                          onClick={onExpandRemindersToggle}
                          className={style.InternalRemindersButton}
                        >
                          <div className={style.InternalRemindersButtonLabel}>
                            {agreement?.userEventsCount}
                          </div>
                          {renderExpandIcon(fetchInternalRemindersRpcState, expandedRemindersList)}
                        </Button>
                        <Button
                          icon={(<CreateInternalReminderIcon height="10px" />)}
                          type="button"
                          onClick={onExpandRemindersToggle}
                          customClass={style.InternalRemindersButtonSmall}
                        >
                          {agreement?.userEventsCount}
                          {renderExpandIcon(fetchInternalRemindersRpcState, expandedRemindersList)}
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={rightContainerClasses}>
          <div className={style.RightTopBar}>
            <ContractActions
              disabled={isReadOnly}
              agreement={agreement}
              queryName={queryName}
            />
          </div>
          <Participants
            agreement={agreement}
            counterparties={counterparties}
            participants={participants}
            isContractMarkedAsSigned={isMarkedAsSigned(agreement)}
            disabled={disabled}
          />
          <div className={style.RightBottomBar}>
            <ContractId id={agreement.id} />
          </div>
        </div>
      </div>
      <div className={linksContainerClasses}>
        {renderLinkList()}
      </div>
      <div className={remindersContainerClasses}>
        {!isEmpty(agreement.reminders) && (
          <UserEventsProvider
            currentWorkspaceId={workspaceId}
            modal={InternalReminderModal}
            refetchInternalReminders={fetchInternalReminders}
          >
            <ContractInternalRemindersList
              contractInternalReminderEvents={agreement.reminders}
              agreement={agreement}
            />
          </UserEventsProvider>
        )}
      </div>
    </div>
  );
};

ContractCard.defaultProps = {
  onClick: undefined,
  isReadOnly: undefined,
  disabled: undefined,
  dataTestId: undefined,
};
