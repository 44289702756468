import { useLocation } from 'react-router';

import FolderIcon from 'components/icons/folder';

import { isUserOnGlobalTrashPage } from 'utils/isOnPage';
import style from './original-location.module.scss';

type Props = {
  document: Oneflow.Agreement
}

export const OriginalLocation = ({ document }: Props) => {
  const location = useLocation();

  const documentLocation = document.collection?.name || '';
  let folderName: (string | null | undefined)[] = [];
  let path = location.pathname.replace('/trash', '/all');

  if (document.folder?.resolvedPath) {
    folderName = document.folder.resolvedPath.map((folder) => folder.name);
  }

  if (document.folder) {
    path = location.pathname.replace('/trash', `/all?folderIds%5B%5D=${document.folder.id}`);
  }

  if (isUserOnGlobalTrashPage(location.pathname) && document.collection) {
    path = location.pathname.replace('/search/trash', `/c/${document.collection.id}/contracts/all`);

    if (document.folder) {
      path = location.pathname.replace('/search/trash', `/c/${document.collection.id}/contracts/all?folderIds%5B%5D=${document.folder.id}`);
    }
  }

  return (
    <a href={path} className={style.OriginalLocationContainer}>
      <p data-testid="location">{documentLocation}</p>
      {folderName.map((folder) => (
        <div key={folder} className={style.Folder} data-testid="folder">
          <span>/</span>
          <FolderIcon />
          <span>{folder}</span>
        </div>
      ))}
    </a>
  );
};
