// @flow

import React from 'react';
import { get } from 'lodash';
import clsx from 'clsx';

import { isBoxDataRemoveAllowed } from 'agreement/box-data-remove-permissions';
import { useVideoBoxProps } from 'contexts/video-box-props';

import RichTextEditorToolbarDivider from 'components/rich-text-editor-toolbar-divider';

import AddTitle from './toolbar-buttons/add-title';
import RemoveVideo from './toolbar-buttons/remove-video';
import ReplaceVideo from './toolbar-buttons/replace-video';
import style from './video-toolbar.module.scss';
import VideoSize from './toolbar-buttons/video-size';

type Props = {
  toggleTitle: Function,
  changeWidth: Function,
  containerClassName: string,
  isAllowedToUpdateDataValue: boolean,
}

const VideoToolbar = ({
  toggleTitle,
  changeWidth,
  containerClassName,
  isAllowedToUpdateDataValue,
}: Props) => {
  const {
    box,
    addVideoState,
  } = useVideoBoxProps();
  const data = get(box, 'content.data');
  const video = data?.find((d) => !d._removed);
  const loadingState = addVideoState.processingVideo || addVideoState.uploadingVideo;
  const width = get(box, 'config.width');
  const title = get(box, 'config.title');

  if (!video || !isAllowedToUpdateDataValue || loadingState) {
    return null;
  }

  return (
    <div className={clsx('box-toolbar-container', containerClassName)}>
      <div className={style.VideoToolbar}>
        <VideoSize
          size="sm"
          onTrigger={changeWidth}
          isActive={width === 'sm'}
        />
        <VideoSize
          size="lg"
          onTrigger={changeWidth}
          isActive={!width || width === 'lg'}
        />
        <VideoSize
          size="full"
          onTrigger={changeWidth}
          isActive={width === 'full'}
        />
        <RichTextEditorToolbarDivider />
        <AddTitle
          toggleTitle={toggleTitle}
          isActive={title}
        />
        <RichTextEditorToolbarDivider />
        <ReplaceVideo />
        <RemoveVideo disabled={!isBoxDataRemoveAllowed(box, video)} />
      </div>
    </div>
  );
};

export default VideoToolbar;
