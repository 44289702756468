import clsx from 'clsx';

import useAgreement from 'hooks/use-agreement';
import { getAgreementMyParty } from 'agreement/selectors';
import { isIndividual } from 'agreement/party';

import Message from 'components/message';
import WebIcon from 'components/icons/web';
import LockIcon from 'components/icons/lock';
import ChevronDownIcon from 'components/icons/chevron-down';
// eslint-disable-next-line import/no-named-as-default
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';
// eslint-disable-next-line import/named
import { Popover, PopoverContent, PopoverPortal } from 'components/popover';
import Button from 'components/button';

import style from './privacy-selector.module.scss';

type Props = {
  agreementId: number,
  messageIsPrivate: boolean,
  onChange: (arg0: boolean) => void,
  disabled: boolean,
  actionsContainerRef: any,
};

const PrivacySelector = ({
  agreementId,
  messageIsPrivate,
  onChange,
  disabled,
  actionsContainerRef,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const myParty = getAgreementMyParty(agreement);

  const selectorStates = [
    {
      icon: <LockIcon />,
      description: <Message
        id="Only users from your organization will see this message"
        comment="Option group description for internal recipients"
      />,
      label: <Message
        id="Internal"
        comment="Option group label for internal recipients"
      />,
      value: true,
    },
    {
      icon: <WebIcon width="14px" height="14px" />,
      description: <Message
        id="All parties in this document will see this message"
        comment="Option group description for public recipients"
      />,
      label: <Message
        id="Public"
        comment="Option group label for public recipients"
      />,
      value: false,
    },
  ];

  const triggerButtonClasses = clsx(style.TriggerButton, {
    [style.Public]: !messageIsPrivate,
    [style.Internal]: messageIsPrivate,
    [style.Disabled]: disabled,
  });

  if (isIndividual(myParty)) {
    return null;
  }

  return (
    <Popover>
      <PopoverTriggerButton
        icon={messageIsPrivate ? <LockIcon /> : <WebIcon width="14px" height="14px" />}
        customClass={triggerButtonClasses}
      >
        {messageIsPrivate ? (
          <Message
            id="Internal"
            comment="Option group label for internal recipients"
          />
        ) : (
          <Message
            id="Public"
            comment="Option group label for public recipients"
          />
        )}
        <ChevronDownIcon className={style.ChevronDownIcon} />
      </PopoverTriggerButton>
      <PopoverPortal container={actionsContainerRef?.current}>
        <PopoverContent
          side="top"
          align="end"
          arrowHeight={0}
          className={style.PopoverContent}
        >
          <>
            {selectorStates.map((selectorState) => (
              <Button
                key={Number(selectorState.value)}
                className={style.SelectorButton}
                onClick={() => onChange(selectorState.value)}
                data-testid={selectorState.value ? 'internal' : 'public'}
              >
                <div className={style.SelectorOption}>
                  <div className={clsx(style.IconContainer, {
                    [style.Public]: !selectorState.value,
                    [style.Internal]: selectorState.value,
                  })}
                  >
                    {selectorState.icon}
                  </div>
                  <div className={style.SelectorLabel}>
                    <p>{selectorState.label}</p>
                    <p>{selectorState.description}</p>
                  </div>
                </div>
              </Button>
            ))}
          </>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

export default PrivacySelector;
