import clsx from 'clsx';

import style from './divider.module.scss';

type Props = {
  className?: string,
  solid?: boolean,
};

const Divider = ({ className, solid }: Props) => {
  const dividerClassNames = clsx(style.Divider, className, {
    [style.SolidDivider]: solid,
  });

  return (
    <div className={dividerClassNames} />
  );
};

export default Divider;
