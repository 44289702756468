// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import ModalForm from 'hocs/modal-form';
import {
  MessageTemplateNameField,
  MessageTemplateTypeField,
  MessageTemplateSubjectField,
  MessageTemplateBodyField,
} from 'components/fields';

export type MessageTemplateData = {
  name: string,
  type: any,
  subject?: string,
  body?: string,
};

export type Props = {
  messageTemplate: MessageTemplate,
  updateMessageTemplate: MessageTemplate => void,
  resetUpdateState: () => void,
  updateState: UpdateState,
  children: Function,
}

export const EditMessageTemplate = ({
  updateMessageTemplate,
  resetUpdateState,
  updateState,
  messageTemplate,
  children,
}: Props) => (
  <ModalForm
    title={(
      <Message
        id="Edit message template"
        comment="Modal title for editing message template."
      />
    )}
    body={(
      <div>
        <MessageTemplateNameField autoFocus />
        <MessageTemplateTypeField />
        <MessageTemplateSubjectField />
        <MessageTemplateBodyField />
      </div>
    )}
    onSubmit={updateMessageTemplate}
    resetFormState={resetUpdateState}
    formState={updateState}
    initialValues={{
      name: messageTemplate.name,
      type: messageTemplate.type,
      subject: messageTemplate.subject,
      body: messageTemplate.body,
    }}
    modalKey="edit message template modal"
  >
    {children}
  </ModalForm>
);
