// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { type MessageTranslator } from '@oneflowab/pomes';

import agreementsReducer from 'reducers/entities/agreements';
import { isTemplate } from 'agreement/states';
import { redirectToDocumentOnDocumentCreate } from 'agreement/navigation-helpers';

import CopyContract, { type Props, type FormData } from './copy-contract';

type MainProps = $Diff<Props, {| message: MessageTranslator |}>;

type StateProps = {|
  formState: CreateState,
|};

type DispatchProps = {|
  copyContract: FormData => void,
  resetFormState: () => void,
|};

export type OwnProps = $Diff<MainProps, StateProps & DispatchProps>;

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  formState: agreementsReducer.getCreateSelector(state),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  agreement,
  isCreatingTemplate,
  amplitudeScope,
}) => ({
  copyContract: ({ name }: FormData) => {
    dispatch(agreementsReducer.createAgreement({
      data: {
        sourceId: agreement.id,
        name,
        isTemplate: isCreatingTemplate || isTemplate(agreement),
        amplitudeScope,
      },
      pipe: {
        onSuccess: (data) => {
          const { agreements } = data.entities;
          const newAgreement = agreements[data.result];

          if (isTemplate(newAgreement)) {
            return redirectToDocumentOnDocumentCreate(data, true);
          }

          return redirectToDocumentOnDocumentCreate(data);
        },
      },
    }));
  },
  resetFormState: () => {
    dispatch(agreementsReducer.createAgreementReset());
  },
});

export default connect<MainProps, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(CopyContract);
