// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import CalendarAlert from 'components/icons/calendar-alert';

const Overdue = () => (
  <>
    <CalendarAlert height="12px" />
    <span>
      <Message
        id="Overdue"
        comment="An insight for lifecycle events that indicates that a contract is overdue."
      />
    </span>
  </>
);

export default Overdue;
