/* eslint-disable import/named */
import * as React from 'react';
import { localize, Message, MessageTranslator } from '@oneflowab/pomes';

import { hasAttachmentSignatures } from 'agreement';

import {
  hasSignMethodElectronicIdExceptSwedishBankId,
  hasSignMethodHandwritten,
  hasSignMethodSMS,
} from 'agreement/participant';
import * as participantConstants from 'agreement/participant/constants';

import { TryAgainButton } from 'components/buttons';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import CircularSpinner from 'components/icons/circular-spinner';
import Confirmable from 'components/confirmable';
import DocumentActionErrorBody from 'components/document-action-error';

import SignContractBody from './sign-contract-confirm-body';
import style from './sign-contract-confirm.module.scss';

export type Props = {
  agreement: Oneflow.Agreement,
  buttonKind?: string,
  fetchAgreementState: FetchState,
  // eslint-disable-next-line react/no-unused-prop-types
  guestToken?: string,
  isOwner: boolean,
  isSoleSignatory: boolean,
  lastToSign: boolean,
  message: MessageTranslator,
  myParticipantWhenSignatory: Oneflow.Participant,
  myParty?: Oneflow.Party,
  onClose: () => void,
  onStepComplete: () => void,
  onSuccess?: () => void,
  resetRpcState: () => void,
  rpcState: RpcState,
  signAgreement: any,
  renderCustomActions?: ({ isDisabled }: { isDisabled: boolean }) => React.ReactElement,
  modalKey: string,
};

type GetActions = () => React.ReactElement;

type State = {
  isChecked: boolean
  isNewDocumentView: boolean;
};

export const getSubmitButtonLabel = (
  myParticipantWhenSignatory: AgreementParticipant,
) => {
  switch (myParticipantWhenSignatory.signMethod) {
    case participantConstants.SIGN_METHOD_SMS:
      return (
        <Message
          id="Sign with SMS"
          comment="Button label used to confirm signing a contract in a modal"
        />
      );
    case participantConstants.SIGN_METHOD_SE_BANKID:
    case participantConstants.SIGN_METHOD_NO_BANKID_SIGN:
      return (
        <Message
          id="Sign with BankID"
          comment="Button label used to confirm signing a contract in a modal"
        />
      );
    case participantConstants.SIGN_METHOD_FI_FTN_SIGN:
      return (
        <Message
          id="Sign with FTN"
          comment="Button label used to confirm signing a contract in a modal"
        />
      );
    case participantConstants.SIGN_METHOD_DK_MITID_NEMID_SIGN:
      return (
        <Message
          id="Sign with MitID"
          comment="Button label used to confirm signing a contract in a modal"
        />
      );
    case participantConstants.SIGN_METHOD_HANDWRITTEN_SIGN:
      return (
        <Message
          id="Continue"
          comment="Button label used to confirm signing a contract in a modal"
        />
      );
    case participantConstants.SIGN_METHOD_ESIGN:
    default:
      return (
        <Message
          id="Sign"
          comment="Button label used to confirm signing a contract in a modal"
        />
      );
    case participantConstants.SIGN_METHOD_EID_SIGN:
      return (
        <Message
          id="Choose signing method"
          comment="Button label used to confirm signing a contract in a modal"
        />
      );
  }
};

export class SignContractConfirmComponent extends React.Component<Props, State> {
  static defaultProps = {
    myParty: undefined,
    guestToken: undefined,
    onSuccess: undefined,
  };

  state = {
    isChecked: this.props.isOwner,
  }

  confirmableRef: {
    current: null | Confirmable
  };

  componentDidUpdate(previousProps: Props) {
    const {
      rpcState,
      onSuccess,
      onStepComplete,
    } = this.props;
    if (!onSuccess) {
      return;
    }

    if (!previousProps.rpcState.success && rpcState.success) {
      onSuccess();
      onStepComplete();
    }
  }

  handleConfirm = () => {
    const {
      signAgreement,
      myParticipantWhenSignatory,
      rpcState,
      fetchAgreementState,
      onStepComplete,
    } = this.props;

    if (rpcState.loading || fetchAgreementState.loading) {
      return;
    }

    if (hasSignMethodSMS(myParticipantWhenSignatory)) {
      onStepComplete();
    } else if (
      hasSignMethodElectronicIdExceptSwedishBankId(myParticipantWhenSignatory)
      || hasSignMethodHandwritten(myParticipantWhenSignatory)
    ) {
      onStepComplete();
    } else {
      signAgreement(myParticipantWhenSignatory.id);
    }
  };

  getIcon = (buttonKind: string, rpcState: any) => {
    if (buttonKind === 'primary') {
      return null;
    }

    if (rpcState.loading) {
      return <CircularSpinner />;
    }

    return null;
  };

  getActions: GetActions = ({ closeConfirmation }) => {
    const {
      agreement,
      myParticipantWhenSignatory,
      rpcState,
      fetchAgreementState,
      buttonKind = 'document-sign',
      renderCustomActions,
    } = this.props;
    const { isChecked } = this.state;
    let isDisabled = rpcState.loading || fetchAgreementState.loading;

    if (hasAttachmentSignatures(agreement)) {
      isDisabled = isDisabled || !isChecked;
    }

    if (!myParticipantWhenSignatory) {
      return null;
    }

    if (rpcState.error) {
      return this.getErrorActions({ closeConfirmation });
    }

    if (renderCustomActions) {
      return (
        <div className={style.Actions}>
          {renderCustomActions({ isDisabled })}
        </div>
      );
    }

    return (
      <div className={style.Actions}>
        <Button
          kind={buttonKind}
          data-testid="confirm"
          icon={this.getIcon(buttonKind, rpcState)}
          disabled={isDisabled}
          onClick={this.handleConfirm}
          customClass={style.SignButton}
        >
          {getSubmitButtonLabel(myParticipantWhenSignatory)}
        </Button>
      </div>
    );
  };

  handleCheckboxOnChange = (event: React.ChangeEvent<any>) => {
    this.setState({ isChecked: event.target.checked });
  }

  getAttachmentSignaturesCheckbox = () => {
    const { agreement } = this.props;

    if (!hasAttachmentSignatures(agreement)) {
      return null;
    }

    return (
      <div className={style.AttachmentSignatures}>
        <Checkbox
          className={style.AttachmentSignaturesCheckbox}
          input={{
            checked: this.state.isChecked,
            onChange: this.handleCheckboxOnChange,
          }}
          label={(
            <Message
              id="I have read the contract and all of its attachments"
              comment="Sign contract confirmation, confirming that the user understands all attachments"
            />
          )}
        />
      </div>
    );
  };

  getErrorActions: GetActions = ({ closeConfirmation }) => (
    <div className={style.Actions}>
      <TryAgainButton onClick={closeConfirmation} />
    </div>
  );

  resetForm = () => {
    const { onClose } = this.props;
    this.setState({ isChecked: false });
    onClose();
  }

  renderBody() {
    const {
      myParty,
      myParticipantWhenSignatory,
      lastToSign,
      isSoleSignatory,
      agreement,
      rpcState,
    } = this.props;

    if (rpcState.error) {
      return <DocumentActionErrorBody error={rpcState.error} />;
    }

    if (!myParticipantWhenSignatory || !myParty) {
      return null;
    }

    return (
      <SignContractBody
        agreement={agreement}
        attachmentSignaturesCheckbox={this.getAttachmentSignaturesCheckbox()}
        isSoleSignatory={isSoleSignatory}
        lastToSign={lastToSign}
        participant={myParticipantWhenSignatory}
        party={myParty}
      />
    );
  }

  render() {
    const {
      message,
      rpcState,
      resetRpcState,
      modalKey,
    } = this.props;

    return (
      <Confirmable
        header={message({
          id: 'Document signing',
          comment: 'Confirm modal title for signing a contract.',
        })}
        body={this.renderBody()}
        actions={this.getActions}
        errorActions={this.getErrorActions}
        success={rpcState.success}
        onEnter={this.handleConfirm}
        onOpen={resetRpcState}
        isOpen
        onClose={this.resetForm}
        modalKey={modalKey}
      />
    );
  }
}

export default localize<Props>(SignContractConfirmComponent);
