// @flow

import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import clsx from 'clsx';

import Alert from 'components/alert';
import TerminateSnackbar from 'components/icons/terminate-snackbar';
import NewCheck from 'components/icons/new-check';

import style from './snackbar.module.scss';

type Props = {
  uploadingMessage: React.Node,
  setUploadingMessage: Function,
  severity: string,
  autoHideDuration?: number,
}

const SnackbarComponent = ({
  uploadingMessage,
  setUploadingMessage,
  autoHideDuration,
  severity,
}: Props) => {
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setUploadingMessage(null);
  };

  const successStatus = severity === 'success';

  const snackbarClasses = clsx(style.videoBoxSnackbarContainer, {
    [style.Alert]: !successStatus,
    [style.Success]: successStatus,
  });

  const renderSnackbar = () => (
    <Snackbar
      open={Boolean(uploadingMessage)}
      autoHideDuration={autoHideDuration}
      onClose={handleSnackbarClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={successStatus ? 'success' : 'warning'}
        sx={{ width: '100%' }}
        icon={successStatus ? <NewCheck /> : <TerminateSnackbar />}
      >
        {uploadingMessage}
      </Alert>
    </Snackbar>
  );

  if (!uploadingMessage) {
    return null;
  }

  return (
    <div className={snackbarClasses}>
      {renderSnackbar()}
    </div>
  );
};

export default SnackbarComponent;
