// @flow

// Data keys
export const KEY_API_TOKENS = 'api_tokens';
export const KEY_WEBHOOKS = 'webhooks';
export const KEY_TEMPLATE_GROUPS = 'template_groups';

export const KEY_DEMO = 'demo';
export const KEY_INTELLIPLAN = 'intelliplan';
export const KEY_SALESFORCE = 'salesforce';
export const KEY_LIME = 'lime';
export const KEY_SSO_SAML = 'sso_saml';
export const KEY_UPSALES2 = 'upsales_v2';
export const KEY_ADOCKA = 'adocka';
export const KEY_HUBSPOT = 'hubspot';
export const KEY_TEAMTAILOR = 'teamtailor';
export const KEY_SUPER_OFFICE = 'superoffice';
export const KEY_DYNAMICS_CRM = 'dynamics_crm';
export const KEY_JOBYLON = 'jobylon';
export const KEY_NEOCASE = 'neocase';
export const KEY_MEMBRAIN = 'membrain';
export const KEY_PIPEDRIVE = 'pipedrive';

export const KEY_ELECTRONIC_IDS = 'electronic_id';
export const KEY_SMS = 'sms';
export const KEY_PAXML = 'paxml';
export const KEY_HANDWRITTEN_SIGNATURE = 'handwritten_signature';
export const KEY_SCIM = 'scim';
export const KEY_GENERIC = 'generic';
export const KEY_SIGNATURE_FIELDS = 'document_signature_fields';

// Polymorphic controllers
export const EXTENSION_CLASS_API_TOKENS = 'api_tokens';
export const EXTENSION_CLASS_WEBHOOKS = 'webhooks';
export const EXTENSION_CLASS_TEMPLATE_GROUPS = 'template_groups';

export const EXTENSION_CLASS_DEMO = 'demo';
export const EXTENSION_CLASS_INTELLIPLAN = 'intelliplan';
export const EXTENSION_CLASS_SALESFORCE = 'salesforce';
export const EXTENSION_CLASS_LIME = 'lime';
export const EXTENSION_CLASS_SSO_SAML = 'sso_saml';
export const EXTENSION_CLASS_UPSALES2 = 'upsales_v2';
export const EXTENSION_CLASS_ADOCKA = 'adocka';
export const EXTENSION_CLASS_HUBSPOT = 'hubspot';
export const EXTENSION_CLASS_TEAMTAILOR = 'teamtailor';
export const EXTENSION_CLASS_SUPER_OFFICE = 'superoffice';
export const EXTENSION_CLASS_DYNAMICS_CRM = 'dynamics_crm';
export const EXTENSION_CLASS_JOBYLON = 'jobylon';
export const EXTENSION_CLASS_NEOCASE = 'neocase';
export const EXTENSION_CLASS_MEMBRAIN = 'membrain';
export const EXTENSION_CLASS_PIPEDRIVE = 'pipedrive';

export const EXTENSION_CLASS_ELECTRONIC_IDS = 'electronic_id';
export const EXTENSION_CLASS_SMS = 'sms';
export const EXTENSION_CLASS_PAXML = 'paxml';
export const EXTENSION_CLASS_HANDWRITTEN_SIGNATURE = 'handwritten_signature';
export const EXTENSION_CLASS_SCIM = 'scim';
export const EXTENSION_CLASS_GENERIC = 'generic';
export const EXTENSION_SIGNATURE_FIELDS = 'document_signature_fields';

// Function to get the extension class for a given key
export function getExtensionClass(key: string): string {
  const keyToClassMap = {
    [KEY_API_TOKENS]: EXTENSION_CLASS_API_TOKENS,
    [KEY_WEBHOOKS]: EXTENSION_CLASS_WEBHOOKS,
    [KEY_TEMPLATE_GROUPS]: EXTENSION_CLASS_TEMPLATE_GROUPS,
    [KEY_DEMO]: EXTENSION_CLASS_DEMO,
    [KEY_INTELLIPLAN]: EXTENSION_CLASS_INTELLIPLAN,
    [KEY_SALESFORCE]: EXTENSION_CLASS_SALESFORCE,
    [KEY_LIME]: EXTENSION_CLASS_LIME,
    [KEY_SSO_SAML]: EXTENSION_CLASS_SSO_SAML,
    [KEY_UPSALES2]: EXTENSION_CLASS_UPSALES2,
    [KEY_ADOCKA]: EXTENSION_CLASS_ADOCKA,
    [KEY_HUBSPOT]: EXTENSION_CLASS_HUBSPOT,
    [KEY_TEAMTAILOR]: EXTENSION_CLASS_TEAMTAILOR,
    [KEY_SUPER_OFFICE]: EXTENSION_CLASS_SUPER_OFFICE,
    [KEY_DYNAMICS_CRM]: EXTENSION_CLASS_DYNAMICS_CRM,
    [KEY_JOBYLON]: EXTENSION_CLASS_JOBYLON,
    [KEY_NEOCASE]: EXTENSION_CLASS_NEOCASE,
    [KEY_MEMBRAIN]: EXTENSION_CLASS_MEMBRAIN,
    [KEY_PIPEDRIVE]: EXTENSION_CLASS_PIPEDRIVE,
    [KEY_ELECTRONIC_IDS]: EXTENSION_CLASS_ELECTRONIC_IDS,
    [KEY_SMS]: EXTENSION_CLASS_SMS,
    [KEY_PAXML]: EXTENSION_CLASS_PAXML,
    [KEY_HANDWRITTEN_SIGNATURE]: EXTENSION_CLASS_HANDWRITTEN_SIGNATURE,
    [KEY_SCIM]: EXTENSION_CLASS_SCIM,
    [KEY_SIGNATURE_FIELDS]: EXTENSION_SIGNATURE_FIELDS,
  };

  return keyToClassMap[key] || EXTENSION_CLASS_GENERIC;
}
