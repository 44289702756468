// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Modal from 'components/modal';
import { CloseButton } from 'components/buttons';
import CheckCircle from 'components/icons/check-circle';
import { HelpCenterLink } from 'components/help-center-link';

import style from './freemium-self-service-success.module.scss';

export type Props = {
  onClose: () => void,
  modalKey: string,
};

export const FreemiumSelfServiceSuccess = ({ onClose, modalKey }: Props) => (
  <Modal
    header={(
      <Message
        id="Activate your subscription"
        comment="Message shown in upgrade account modal"
      />
    )}
    onCancel={onClose}
    modalKey={modalKey}
  >
    <Modal.Body>
      <div className={style.Confirmation}>
        <CheckCircle height="100px" className={style.Icon} />
        <h2 className={style.Heading}>
          <Message
            id="Thank you for purchasing Oneflow!"
            comment="Heading shown when the purchase of a Oneflow account is confirmed"
          />
        </h2>
        <div className={style.TextBox}>
          <p className={style.Paragraph}>
            <Message
              id="Your subscription is now active and you can start using Oneflow."
              comment="Paragraph shown when the purchase of a Oneflow account is confirmed"
            />
          </p>
        </div>
        <h2 className={style.Heading}>
          <Message
            id="Need help getting started?"
            comment="Heading shown when the purchase of a Oneflow account is confirmed"
          />
        </h2>
        <div className={style.TextBox}>
          <p className={style.Paragraph}>
            <Message
              id="Our help center is filled with great articles on our features and how to make the most out of your Oneflow experience."
              comment="Paragraph shown when the purchase of a Oneflow account is confirmed."
            />
            {' '}
            <HelpCenterLink />
          </p>
        </div>
      </div>
    </Modal.Body>
    <Modal.Actions>
      <CloseButton onClick={onClose} />
    </Modal.Actions>
  </Modal>
);
