import { useCallback } from 'react';
import clsx from 'clsx';

import type { AgreementFileUploadStatusType } from 'reducers/current-contract';

import { FILES_UPLOAD_STATUS } from 'reducers/current-contract';

import NewCrossIcon from 'components/icons/new-cross';
import CheckIcon from 'components/icons/check';
import CircularSpinner from 'components/icons/circular-spinner';

import getErrorMessageFromCode from './get-error-message-from-code';
import FileNameSplitWithExtension from './file-name-split-with-extension';

import style from './files-upload-status-box-item.module.scss';

type Props = {
  file: { name: string },
  errors: Array<any>,
  status: AgreementFileUploadStatusType,
  attachmentsCountLimit: number,
};

export const FilesUploadStatusBoxItem = (props: Props) => {
  const {
    file,
    errors,
    status,
    attachmentsCountLimit,
  } = props;

  const renderIcon = useCallback(() => {
    const icons = {
      LOADING: <CircularSpinner width="15px" />,
      SUCCESS: <CheckIcon width="6px" />,
      FAIL: <NewCrossIcon width="6px" />,
    };
    const iconClasses = clsx(style.Icon, {
      [style.Loading]: status === FILES_UPLOAD_STATUS.LOADING,
      [style.Success]: status === FILES_UPLOAD_STATUS.SUCCESS,
      [style.Error]: status === FILES_UPLOAD_STATUS.FAIL,
    });
    return <div className={iconClasses}>{icons[status]}</div>;
  }, [status]);

  const renderErrorMessage = useCallback(() => errors?.map((error) => (
    <div key={error.code} className={style.ErrorMessageWrapper}>
      <div className={style.ErrorMessage}>
        {getErrorMessageFromCode(error.code, { attachmentsCountLimit })}
      </div>
    </div>
  )), [errors, attachmentsCountLimit]);

  const renderFileName = useCallback(() => (
    <div className={style.FileNameWrapper} data-label={file.name}>
      <FileNameSplitWithExtension>{file.name}</FileNameSplitWithExtension>
    </div>
  ), [file]);

  return (
    <div className={style.FilesUploadStatusBoxItem}>
      <div className={style.IconWrapper}>{renderIcon()}</div>
      <div className={style.FileInfo}>
        {renderFileName()}
        {renderErrorMessage()}
      </div>
    </div>
  );
};
