// @flow

import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import get from 'lodash/get';

import { isBoxDataValueUpdateAllowed } from 'agreement/box-data-value-update-permissions';
import { updateBoxConfigAction } from 'reducers/current-contract';
import { useVideoBoxProps } from 'contexts/video-box-props';

import {
  ApiError,
  getVideoErrorMessage,
} from 'components/api-error';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import { VideoTitleEditable } from 'components/contract-boxes/video-box/title-editable';
import Snackbar from 'components/contract-boxes/video-box/video/video-upload/snackbar';
import VideoContainer from 'components/contract-boxes/video-box/video';
import VideoToolbar from 'components/contract-boxes/video-box/toolbar';

import style from './video-box.module.scss';

const VideoBoxContainer = () => {
  const {
    box,
    addVideoState,
    handleSnackbarSetMessages,
  } = useVideoBoxProps();
  const dispatch = useDispatch();
  const boxId = getId(box);
  const title = get(box, 'config.title');
  const width = get(box, 'config.width');
  const data = get(box, 'content.data');

  const [videoHovered, setVideoHovered] = React.useState(false);
  const [showToolbar, setShowToolbar] = React.useState(false);
  const toolbarVisible = videoHovered || showToolbar;

  const video = data?.find((d) => !d._removed);
  const loadingState = addVideoState.processingVideo || addVideoState.uploadingVideo;
  const isAllowedToUpdateDataValue = isBoxDataValueUpdateAllowed(box, video);

  const extraPadding = (
    video
    && isAllowedToUpdateDataValue
    && !loadingState
  );

  React.useEffect(() => {
    if (addVideoState.uploadSuccess) {
      setShowToolbar(true);
    }
  }, [addVideoState.uploadSuccess]);

  const toggleTitle = React.useCallback(() => {
    dispatch(updateBoxConfigAction(boxId, { title: !title }));
  }, [boxId, dispatch, title]);

  const changeWidth = React.useCallback((newWidth) => {
    dispatch(updateBoxConfigAction(boxId, { width: newWidth }));
  }, [boxId, dispatch]);

  const videoTitleIsEditable = isAllowedToUpdateDataValue
    && !(addVideoState.uploadingVideo || addVideoState.processingVideo);

  const renderApiErrors = () => {
    if (!addVideoState.apiErrors) {
      return null;
    }

    return (
      <div className={style.ApiErrors}>
        <ApiError customMessage={getVideoErrorMessage(addVideoState.apiErrors)} />
      </div>
    );
  };

  const snackbarMessage = addVideoState.successMessage
  || addVideoState.uploadingError
  || null;

  return (
    <>
      <VideoToolbar
        toggleTitle={toggleTitle}
        changeWidth={changeWidth}
        isAllowedToUpdateDataValue={isAllowedToUpdateDataValue}
        containerClassName={clsx(style.VideoToolbarContainer, {
          [style.Visible]: toolbarVisible,
        })}
      />
      <Snackbar
        uploadingMessage={renderApiErrors()}
        setUploadingMessage={handleSnackbarSetMessages()}
        autoHideDuration={addVideoState.successMessage ? 2000 : undefined}
        severity="warning"
      />
      <Snackbar
        uploadingMessage={snackbarMessage}
        setUploadingMessage={handleSnackbarSetMessages()}
        autoHideDuration={addVideoState.successMessage ? 2000 : undefined}
        severity={addVideoState.successMessage ? 'success' : 'warning'}
      />
      <div
        onMouseLeave={() => {
          setVideoHovered(false);
          setShowToolbar(false);
        }}
        onMouseEnter={() => setVideoHovered(true)}
        className={clsx((extraPadding && style.VideoAndEditableContainer), {
          [style.Small]: width === 'sm',
        })}
      >
        <VideoContainer />
        <VideoTitleEditable isEditable={videoTitleIsEditable} />
      </div>
    </>
  );
};

export default VideoBoxContainer;
