import omit from 'lodash/omit';
import { normalize, schema } from 'normalizr';
import { decamelizeKeys } from 'humps';

import {
  post,
  getMultiple,
  extractResponseBodyAsJSON,
  remove,
} from 'oneflow-client/core';

const groupAccountBindingsSchema = new schema.Entity('groupAccountBindings');

const normalizeGroupAccounts = (bindingsData) => {
  if (bindingsData.collection) {
    return ({
      ...normalize(bindingsData.collection, [groupAccountBindingsSchema]),
      count: bindingsData.count,
    });
  }
  return ({
    ...normalize(bindingsData, [groupAccountBindingsSchema]),
    count: 1,
  });
};
// eslint-disable-next-line import/prefer-default-export
export const getGroupAccountBindings = ({
  params,
  pagination,
  sort = ['-role.type', 'role.name'],
}) => (
  getMultiple({
    url: `/groups/${params.groupId}/accounts/bindings/`,
    params: decamelizeKeys(omit(params, 'groupId'), { separator: '_' }),
    pagination,
    sort,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeGroupAccounts)
);
export const grantGroupAccountAccess = ({
  groupId,
  roleId,
}) => post({
  url: `/accounts/groups/${groupId}/bindings/`,
  body: {
    role_id: roleId,
  },
})
  .then(extractResponseBodyAsJSON)
  .then(normalizeGroupAccounts);

export const removeGroupAccountAccess = ({
  groupId,
  roleId,
}) => remove({
  url: `/accounts/groups/${groupId}/bindings/`,
  body: {
    role_id: roleId,
  },
})
  .then(extractResponseBodyAsJSON)
  .then(normalizeGroupAccounts);
