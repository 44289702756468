// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  formatDateString,
  getDaysSince,
} from 'date';

import Terminate from 'components/icons/terminate';

import Insight from './insight';

import style from './insight.module.scss';

type Props = {
  terminateTime?: string,
  dateFormat: string,
};

const Terminated = ({ terminateTime, dateFormat }: Props) => {
  const renderInsight = () => {
    if (!terminateTime) {
      return null;
    }

    const daysSince = getDaysSince(terminateTime);
    const dateString = formatDateString(terminateTime, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    if (daysSince > 7) {
      return (
        <Message
          id="Terminated {date}"
          values={{
            date,
          }}
          comment="Insight for signed and terminated contract. Visible in contract card."
        />
      );
    }

    if (daysSince > 0) {
      return (
        <Message
          id="Terminated yesterday ({date})"
          pluralId="Terminated {days} days ago ({date})"
          pluralCondition="days"
          values={{
            days: daysSince,
            date,
          }}
          comment="Insight for signed and terminated contract. Visible in contract card."
        />
      );
    }

    if (daysSince === 0) {
      return (
        <Message
          id="Terminated today ({date})"
          values={{
            date,
          }}
          comment="Insight for signed and terminated contract. Visible in contract card."
        />
      );
    }

    return null;
  };

  return (
    <Insight
      IconComponent={Terminate}
      insight={renderInsight()}
    />
  );
};

export default Terminated;
