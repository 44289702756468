// @flow

import React from 'react';
import isNumber from 'lodash/isNumber';
import toString from 'lodash/toString';
import debounce from 'lodash/debounce';

import Search from './search';
import type { SearchProps } from './search';

type State = {
  value: string,
};

const INPUT_DEBOUNCE_TIME = 500;

const getDebouncedOnChange = (
  onChange: (value: string) => void,
) => debounce(onChange, INPUT_DEBOUNCE_TIME);

type Props = SearchProps & {
  minSearchLength?: number,
  disabled?: boolean,
};

class ControlledSearch extends React.PureComponent<Props, State> {
  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    value: undefined,
    minSearchLength: undefined,
    disabled: undefined,
  };

  state = {
    value: toString(this.props.value),
  };

  debouncedOnChange = getDebouncedOnChange(this.props.onChange);

  componentDidUpdate(prevProps: SearchProps) {
    const value = toString(this.props.value);
    const prevValue = toString(prevProps.value);

    if (value !== prevValue && value !== this.state.value) {
      this.setValue(value);
    }

    if (this.props.onChange !== prevProps.onChange) {
      this.debouncedOnChange.cancel();
      this.debouncedOnChange = getDebouncedOnChange(this.props.onChange);
    }
  }

  onValueChange = (value: string) => {
    const { minSearchLength } = this.props;

    this.setValue(value);

    if (isNumber(minSearchLength) && value.length !== 0 && value.length < minSearchLength) {
      return;
    }

    this.debouncedOnChange(value);
  }

  setValue = (value: string) => {
    this.setState({
      value,
    });
  };

  render() {
    return (
      <Search
        {...this.props}
        value={this.state.value}
        onChange={this.onValueChange}
      />
    );
  }
}

export default ControlledSearch;
