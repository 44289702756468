import { checkAcl } from 'components/acl';
import useAgreement from 'hooks/use-agreement';
import useFeatureFlag from 'hooks/use-feature-flag';

const useShouldRenderAIAssistToolbar = (agreementId: number) => {
  const agreement = useAgreement(agreementId);
  const hasAgreementAiAssistPermission = checkAcl(agreement.acl, 'agreement:ai_assist');

  return hasAgreementAiAssistPermission;
};

export default useShouldRenderAIAssistToolbar;
