import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useAgreement from 'hooks/use-agreement';
import useIsInPreviewMode from 'hooks/use-is-in-preview-mode';
import { getCurrentContractId, isContractPristine } from 'reducers/current-contract';
import { isConcluded, isPending, isCancelContractVisible } from 'agreement';
import isSignatory from 'agreement/participant/is-signatory';
import { isPendingStateApprover } from 'agreement/participant/is-pending-state-approver';
import hasDeclined from 'agreement/participant/has-declined';

import { getAgreementMyParticipant } from 'agreement/selectors';
import { getCurrentTokenSelector } from 'reducers/app';

import { checkAcl } from 'components/acl';
import Message from 'components/message';
import Button from 'components/button';
import { MarkAsDeclined } from 'components/contract-actions/mark-as-declined';
import { CancelContract } from 'components/document-call-to-actions/actions/modals/cancel-modal/cancel-contract';
import DelegateSigningRights from './delegate-signing-rights';
import { DelegatePendingApprovalRights } from './delegate-pending-approval-rights';

import style from './more-options-tab.module.scss';

const DocumentActions = () => {
  const agreementId = useSelector(getCurrentContractId);
  const agreement = useAgreement(agreementId);
  const participant = getAgreementMyParticipant(agreement);
  const guestToken = useSelector(getCurrentTokenSelector);
  const isPristine = useSelector(isContractPristine);
  const isInPreviewMode = useIsInPreviewMode();
  const agreementAllowsDelegate = useMemo(
    () => checkAcl(agreement.acl, 'agreement:participant:pending_state_approver:create'), [agreement.acl],
  );
  const canParticipantDelegate = useMemo(
    () => checkAcl(participant?.acl, 'participant:delegate:from'), [participant?.acl],
  );

  if (isCancelContractVisible(agreement)) {
    return (
      <>
        <CancelContract agreement={agreement}>
          {(onClick: () => void, disabled: boolean) => (
            <Button
              kind="wide"
              color="outlined-red"
              onClick={onClick}
              customClass={style.ButtonMargin}
              disabled={disabled || isInPreviewMode}
            >
              <Message
                id="Cancel document"
                comment="Button text for counterparties who want to decline a document."
              />
            </Button>
          )}
        </CancelContract>
      </>
    );
  }

  if (agreementAllowsDelegate && canParticipantDelegate && isPendingStateApprover(participant)) {
    return <DelegatePendingApprovalRights />;
  }

  if (isConcluded(agreement) || !isSignatory(participant)) {
    return null;
  }

  const canDecline = Boolean(
    (isPristine || isPending(agreement))
    && isSignatory(participant)
    && !hasDeclined(participant)
    && agreement.counterpartDecline
    && !agreement.config?.signLater,
  );

  const getMarkAsDeclinedButton = (onClick: () => void) => (
    <Button
      kind="wide"
      color="outlined-red"
      onClick={onClick}
      customClass={style.ButtonMargin}
      disabled={!canDecline || isInPreviewMode}
    >
      <Message
        id="Decline document"
        comment="Button text for counterparties who want to decline a document."
      />
    </Button>
  );

  return (
    <>
      <MarkAsDeclined agreementId={agreementId} guestToken={guestToken}>
        {getMarkAsDeclinedButton}
      </MarkAsDeclined>
      <DelegateSigningRights />
    </>
  );
};

export default DocumentActions;
