// @flow

import get from 'lodash/get';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';
import { allowTemplateView } from 'workspace/permissions';
import { isWorkspaceAccessible } from 'reducers/session';
import workspacesReducer from 'reducers/entities/workspaces';

import TemplateName, { type Props } from './template-name';

type StateProps = {|
  isTemplateAccessible: boolean,
  currentWorkspace: {},
|};

type OwnProps = $Diff<$Exact<Props>, StateProps> & {
  template: AgreementTemplate,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { template }) => {
  const workspace = workspacesReducer.getWorkspaceSelector(state, {
    id: get(template.agreement, 'collection.id'),
  });

  return {
    isTemplateAccessible: isWorkspaceAccessible(state, workspace) && allowTemplateView(workspace),
    currentWorkspace: getCurrentWorkspaceSelector(state),
  };
};

type DispatchProps = $Exact<{}>;
type MapDispatchToProps = Dispatch<*> => DispatchProps;
export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateName);
