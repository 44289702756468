// @flow

import { connect } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';

import Lifecycle, { type Props } from './lifecycle';

type StateProps = {|
  currentWorkspaceId: number,
|};

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  currentWorkspaceId: getCurrentWorkspaceSelector(state).id,
});

type DispatchProps = $Exact<{}>;

type MapDispatchToProps = () => DispatchProps;
export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, {||}, StateProps, $Exact<DispatchProps>, State, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Lifecycle);
