// @flow

import {
  getMultiple,
  post,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import type { SortParams } from 'oneflow-client/core';

import { normalizeGroups } from 'oneflow-client/groups';
import type { NormalizedGroups } from 'oneflow-client/groups';

type GetPositionGroups = ({
  pagination?: {},
  params: {
    positionId: number,
    [string]: any,
  },
  sorting?: SortParams,
}) => Promise<NormalizedGroups>;

type AddGroupsToPosition = ({
  id: number,
  groupIds: Array<number>
}) => Promise<{}>;

export const addGroupsToPosition: AddGroupsToPosition = ({ id, groupIds }) => (
  post({
    url: `/positions/${id}/groups/`,
    body: { group_ids: groupIds },
  })
    .then(extractResponseBodyAsJSON)
);

type RemoveGroupsFromPosition = ({
  positionId: number,
  groupIds: Array<number>
}) => Promise<{}>;

export const removeGroupsFromPosition: RemoveGroupsFromPosition = ({ positionId, groupIds }) => (
  remove({
    url: `/positions/${positionId}/groups/`,
    body: { group_ids: groupIds },
  })
    .then(extractResponseBodyAsJSON)
);

// Disable eslint to keep same module interface
// eslint-disable-next-line import/prefer-default-export
export const getPositionGroups: GetPositionGroups = ({
  pagination,
  params: {
    positionId,
    ...params
  },
  sorting = {
    attr: 'name',
    direction: 'asc',
  },
}) => (
  getMultiple({
    url: `/positions/${positionId}/groups/`,
    pagination,
    params,
    sorting,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeGroups)
);
