import filter from 'lodash/filter';

import normalizeEntity from 'normalized-redux/entity-normalizer';

const bindings = normalizeEntity({ entity: 'binding' });

export const filterBindingsSelector = (state, {
  resourceType,
  resourceId,
  actorType,
  actorId,
}) => (
  filter(bindings.getAllBindingsSelector(state), (binding) => (
    binding.resourceType === resourceType && binding.resource.id === resourceId
    && binding.actorType === actorType && binding.actor.id === actorId
  ))
);

export default bindings;
