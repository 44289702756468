// @flow

import React, { useState } from 'react';
import { createEditor } from 'slate';
import { Slate } from 'slate-react';

import { ContractPropsProvider } from 'contexts/contract-props';

import ContractTextEditorToolbar from 'components/contract-text-editor/contract-text-editor-toolbar';

type Props = {
  agreement: Agreement,
  isEditableToolbarVisible: boolean,
};

export const ContractToolbarPlaceholder = ({
  agreement,
  isEditableToolbarVisible,
}: Props) => {
  const [editor] = useState(() => createEditor());

  if (isEditableToolbarVisible) {
    return null;
  }

  return (
    <ContractPropsProvider
      contractId={agreement.id}
    >
      <Slate editor={editor} initialValue={[]} onChange={() => {}}>
        <ContractTextEditorToolbar isPlaceholder />
      </Slate>
    </ContractPropsProvider>
  );
};
