import type { BaseEditor } from 'slate';

export const SET_FOCUSED_EDITOR = 'editor/SET_FOCUSED_EDITOR' as const;
export const SET_NOTIFY_PARTICIPANT = 'editor/SET_NOTIFY_PARTICIPANT' as const;

export const UPDATE_ANNOTATIONS_MAP = 'editor/UPDATE_ANNOTATIONS_MAP' as const;

type AnnotationMapItem = {
  boxId: string,
  editor: BaseEditor,
};

type EditorState = {
  focusedEditor: BaseEditor | null;
  notifyCheckboxSelected: boolean;
  notifyParticipant: boolean;
  annotationsMap: Record<number, AnnotationMapItem>;
};

const initialState: EditorState = {
  focusedEditor: null,
  notifyCheckboxSelected: false,
  notifyParticipant: false,
  annotationsMap: {},
};

// action creators

export const setNotifyParticipant = (notifyParticipant: boolean) => ({
  type: SET_NOTIFY_PARTICIPANT,
  notifyParticipant,
});

export const setFocusedEditor = (focusedEditor: BaseEditor | null) => ({
  type: SET_FOCUSED_EDITOR,
  focusedEditor,
});

export const updateAnnotationsMap = (annotationsMap: Record<number, AnnotationMapItem>) => ({
  type: UPDATE_ANNOTATIONS_MAP,
  annotationsMap,
});

type Action =
  | ReturnType<typeof setNotifyParticipant>
  | ReturnType<typeof setFocusedEditor>
  | ReturnType<typeof updateAnnotationsMap>;

// reducer
const editor = (state: EditorState = initialState, action: Action) => {
  switch (action.type) {
    case SET_FOCUSED_EDITOR:
      return {
        ...state,
        focusedEditor: action.focusedEditor,
      };
    case SET_NOTIFY_PARTICIPANT:
      return {
        ...state,
        notifyParticipant: action.notifyParticipant,
      };
    case UPDATE_ANNOTATIONS_MAP:
      return {
        ...state,
        annotationsMap: {
          ...state.annotationsMap,
          ...action.annotationsMap,
        },
      };
    default:
      return state;
  }
};

// selectors

type RootState = {
  editor: EditorState,
};

export const getFocusedEditor = (state: RootState) => (
  state.editor.focusedEditor
);

export const getAnnotationsMap = (state: RootState) => (
  state.editor.annotationsMap
);

export const getNotifyParticipant = (state: RootState): boolean => (
  Boolean(state.editor.notifyParticipant)
);

export default editor;
