// @flow

const getInputPlaceholders = (context: string, message: any) => {
  const examples = {
    agreementEditor:
      message({
        id: 'How can I help you today?',
        comment: 'Placeholder for text area in generate text modal',
      }),
    agreementMessageParticipants:
      message({
        id: 'How can I help you today?',
        comment: 'Placeholder for text area in generate text modal',
      }),
    agreementPublish:
      message({
        id: 'How can I help you today?',
        comment: 'Placeholder for text area in generate text modal',
      }),
    agreementOnesave:
      message({
        id: 'How can I help you today?',
        comment: 'Placeholder for text area in generate text modal',
      }),
    agreementParticipantCreate:
      message({
        id: 'How can I help you today?',
        comment: 'Placeholder for text area in generate text modal',
      }),
    agreementInternalReminder:
      message({
        id: 'How can I help you today?',
        comment: 'Placeholder for text area in generate text modal',
      }),
    messageTemplate:
      message({
        id: 'How can I help you today?',
        comment: 'Placeholder for text area in generate text modal',
      }),
    other:
      message({
        id: 'How can I help you today?',
        comment: 'Placeholder for text area in generate text modal',
      }),
  };

  return examples?.[context] || examples.other;
};

export default getInputPlaceholders;
