// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import get from 'lodash/get';

import {
  isStateTemplate,
} from 'agreement';
import useFeatureFlag from 'hooks/use-feature-flag';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const ContractDeleteEvent = ({ auditLogEvent }: Props) => {
  const contractState = get(auditLogEvent.information, 'state.from');
  const name = get(auditLogEvent.information, 'name.from');
  const contractId = auditLogEvent.target.id;
  const displayName = name ? `${name} (${contractId})` : contractId;
  const throughAutomationRule = get(auditLogEvent.information, 'throughAutomationRule');
  const isTrashEnabled = useFeatureFlag('temporaryDocumentTrashCan');

  const getStringVariables = () => ({
    displayName,
    workspace: auditLogEvent.secondaryTarget.name,
  });

  if (isStateTemplate(contractState)) {
    return (
      <Message
        id="The template {displayName} was deleted from {workspace}"
        comment="Audit log event message for deleted template"
        values={getStringVariables()}
      />
    );
  }

  // Only documents can be removed through automation rules so this can ignore templates above
  if (throughAutomationRule) {
    if (isTrashEnabled) {
      return (
        <Message
          id="The document {displayName} was automatically moved to trash from {workspace}"
          comment="Audit log event message for a deleted document"
          values={getStringVariables()}
        />
      );
    }

    return (
      <Message
        id="The document {displayName} was automatically deleted from {workspace}"
        comment="Audit log event message for a deleted document"
        values={getStringVariables()}
      />
    );
  }

  if (isTrashEnabled) {
    return (
      <Message
        id="The document {displayName} was moved to trash from {workspace}"
        comment="Audit log event message for a deleted document"
        values={getStringVariables()}
      />
    );
  }

  return (
    <Message
      id="The document {displayName} was deleted from {workspace}"
      comment="Audit log event message for a deleted document"
      values={getStringVariables()}
    />
  );
};

export default ContractDeleteEvent;
