import Svg from 'components/svg';

const DkMitId = Svg((
  <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M19.2,0a1.8,1.8,0,1,1-1.8,1.8A1.79,1.79,0,0,1,19.2,0ZM40.4,0a3.8,3.8,0,1,1-3.8,3.8A3.8,3.8,0,0,1,40.4,0ZM20.7,4.9V15.8h-3V4.9ZM2.9.8,7.5,7.9,12,.8h2.9v15H11.8V6.1L7.5,12.5H7.4L3.1,6.1v9.7H0V.8ZM40.4,9c3.6,0,6.5,2,6.9,6.8H33.6C34,11,36.9,9,40.4,9ZM51.9.2C58.3.2,61,3.7,61,8s-2.7,7.8-9.1,7.8H49.3V.2ZM27.1,1.9v3h2.4V7.3H27.1v4.8c0,.9.5,1.2,1.3,1.2a1.84,1.84,0,0,0,1.3-.4v2.7a5.25,5.25,0,0,1-2,.3c-2.2,0-3.6-1.1-3.6-3.5V7.3H22.4V4.9h1.7v-3Z" /></g></g>
), {
  viewBox: '0 0 66 15.9',
  fill: '#0060e6',
});

DkMitId.displayName = 'DkMitId';

export default DkMitId;
