// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import * as agreementConstants from 'agreement/constants';

import style from './state-filter-options.module.scss';

const options = [
  {
    value: agreementConstants.STATE_DRAFT,
    label: (
      <div>
        <span className={style.Draft} />
        <Message
          id="Draft"
          comment="Search filter label, used when filtering contracts by state."
        />
      </div>
    ),
  },
  {
    value: agreementConstants.STATE_PENDING,
    label: (
      <div>
        <span className={style.Pending} />
        <Message
          id="Pending"
          comment="Search filter label, used when filtering contracts by state."
        />
      </div>
    ),
  },
  {
    value: agreementConstants.STATE_OVERDUE,
    label: (
      <div>
        <span className={style.Overdue} />
        <Message
          id="Overdue"
          comment="Search filter label, used when filtering contracts by state."
        />
      </div>
    ),
  },
  {
    value: agreementConstants.STATE_DECLINED, // including STATE_DECLINE_IN_PROGRESS
    label: (
      <div>
        <span className={style.Declined} />
        <Message
          id="Declined"
          comment="Search filter label, used when filtering contracts by state."
        />
      </div>
    ),
  },
  {
    value: agreementConstants.STATE_SIGNED, // including STATE_SIGN_IN_PROGRESS
    label: (
      <div>
        <span className={style.Signed} />
        <Message
          id="Signed"
          comment="Search filter label, used when filtering contracts by state."
        />
      </div>
    ),
  },
];

type FilterOption = {
  value: number,
  label: React.Node,
};

type GetOptions = (filterables: Array<number>) => Array<FilterOption>;

const stateFilterOptions: GetOptions = (filterables) => options
  .filter((option) => filterables.includes(option.value));

export default stateFilterOptions;
