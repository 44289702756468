import { useState } from 'react';
import Message from 'components/message';
import CancelSubscriptionFlow from 'components/cancel-subscription/flows/cancel-subscription-flow';
import type { FlowData } from 'types/cancellation-flow';
import GetInTouchConfirm from '../../get-in-touch/get-in-touch';
import style from './offer-discount.module.scss';

type Props = {
  onClose: () => void;
  onSyncFlowData: (data: FlowData) => void;
  onPreviousStep: () => void;
  setCurrentFlow: (reference: string) => void;
  onSubmit: (
    data: FlowData,
    submitHandler: () => void,
    submitErrorHandler: () => void,
  ) => void;
};

const OfferDiscount = ({
  onClose,
  onSyncFlowData,
  onPreviousStep,
  setCurrentFlow,
  onSubmit,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const renderError = () => {
    if (!hasError) {
      return null;
    }

    return (
      <CancelSubscriptionFlow.SubmitError />
    );
  };

  return (
    <CancelSubscriptionFlow
      onCancel={onClose}
      setFlow={setCurrentFlow}
      modalKey="let-us-help"
    >
      <CancelSubscriptionFlow.Steps>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="Discount"
              comment="Header in the get in touch modal"
            />
          )}
          handlePreviousStep={onPreviousStep}
          allowContinue={!isLoading}
        >
          <CancelSubscriptionFlow.StepBody>
            <>
              <p className={style.SectionHeader}>
                <Message
                  id="Let's get you a discount ✨"
                  comment="Title for offer discount text"
                />
              </p>
              <Message
                id="Let's discuss what can be done to your current Subscription Plan to make it work for you and your business."
                comment="Text for offer discount section"
              />
            </>
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            {renderError()}
            <CancelSubscriptionFlow.ShowFlow
              kind="linkSeparate"
              flow="confirm_cancel"
            >
              <Message
                id="No, cancel Subscription Plan"
                comment="Header in the cancellation reasons modal"
              />
            </CancelSubscriptionFlow.ShowFlow>
            <CancelSubscriptionFlow.Submit
              kind="primary"
              onSubmit={
                (modalData, stepCompleteHandler: () => void) => {
                  setIsLoading(true);
                  setHasError(false);
                  const submitDoneHandler = () => {
                    setIsLoading(false);
                    stepCompleteHandler();
                  };
                  const submitErrorHandler = () => {
                    setIsLoading(false);
                    setHasError(true);
                  };
                  onSubmit(modalData, submitDoneHandler, submitErrorHandler);
                }
              }
              syncData={{ offer_discount: true, book_time: true, message: '' }}
              onSyncFlowData={onSyncFlowData}
            >
              <Message
                id="Reach out to me"
                comment="Button text to get in touch in cancallation flow"
              />
            </CancelSubscriptionFlow.Submit>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
        <CancelSubscriptionFlow.Step
          header={(
            <Message
              id="We'll be in touch"
              comment="Header in the get in touch modal"
            />
          )}
          handlePreviousStep={onPreviousStep}
          hidePrevious
        >
          <CancelSubscriptionFlow.StepBody>
            <GetInTouchConfirm />
          </CancelSubscriptionFlow.StepBody>
          <CancelSubscriptionFlow.StepActions>
            <CancelSubscriptionFlow.Close
              kind="primary"
            >
              <Message
                id="Close"
                comment="Button text to close get in touch confirmation modal"
              />
            </CancelSubscriptionFlow.Close>
          </CancelSubscriptionFlow.StepActions>
        </CancelSubscriptionFlow.Step>
      </CancelSubscriptionFlow.Steps>
    </CancelSubscriptionFlow>
  );
};

export default OfferDiscount;
