// @flow

import React, { useState } from 'react';
import type { FormRenderProps } from 'react-final-form';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';
import { getContractMessageTemplates } from 'oneflow-client/message-templates';

import agreementsReducer from 'reducers/entities/agreements';
import { getPositionFromSessionSelector } from 'reducers/session';
import useAgreement from 'hooks/use-agreement';
import { isDraft, isImport, isTemplate } from 'agreement';

import ModalForm from 'hocs/modal-form';
import AdditionalInformation from 'components/additional-information';
import NotifyParticipantsCheckbox from 'components/modals/add-colleague/notify-participants-checkbox';
import CircularSpinner from 'components/icons/circular-spinner';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import MessageTemplatePicker from 'components/message-template-picker';
import RecipientList from 'components/recipient-list';
import { SignLaterEnabledInfo } from 'components/sign-later-enabled-info';
import Error from 'components/icons/error';
import InvitationHeader from 'components/invitation-header';

import style from './sign-later.module.scss';

const modalKey = 'sign later modal';

type State = {
  contractMessageTemplates: Array<MessageTemplate>,
  selectedMessageTemplate: any,
  defaultMessageTemplate: any,
  isLoadingMessageTemplates: boolean,
  error: any,
}

export type Props = {
  agreementId: number,
  context?: string,
  isModalOpen: boolean,
  message: MessageTranslator,
  setIsModalOpen: (boolean) => void,
  signature?: string,
  syncState: Function,
  messageTemplatesDisabled?: boolean,
};

export const SignLaterComponent = ({
  agreementId,
  context,
  isModalOpen,
  message,
  setIsModalOpen,
  signature,
  syncState,
  messageTemplatesDisabled,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const [checked, setChecked] = useState(false);
  const position = useSelector((state) => getPositionFromSessionSelector(state));

  const updateState = useSelector((state) => (
    agreementsReducer.getUpdateAgreementPreferencesSelector(state, { id: agreementId })
  ));
  const dispatch = useDispatch();

  const resetUpdateState = () => {
    dispatch(agreementsReducer.updateAgreementPreferencesReset({
      id: agreementId,
    }));
  };

  const standardMessageTemplate = {
    id: 'standard_message',
    name: message({
      id: 'Standard message',
      comment: 'This will be used as the name of the message template',
    }),
    body: message({
      id: 'Your colleague {publisher} needs help with a draft before sending it to the counterparty.',
      comment: 'This will be used as the body of notification email',
      values: {
        publisher: position?.fullname,
      },
    }),
    subject: message({
      id: 'Your colleague {publisher} invited you',
      comment: 'This will be used as a subject for the notification email',
      values: {
        publisher: position?.fullname,
      },
    }),
  };

  const initialState: State = {
    contractMessageTemplates: [],
    error: null,
    hasSucceeded: false,
    isLoadingMessageTemplates: false,
  };

  const [state, setState] = useState({ ...initialState });

  const {
    contractMessageTemplates,
    selectedMessageTemplate,
  } = state;

  const updateSelectedMessageTemplate = (selectedMessageTemplateOption?: MessageTemplate) => {
    setState((prevState) => ({
      ...prevState,
      selectedMessageTemplate: selectedMessageTemplateOption,
    }));
  };

  const fetchMessageTemplates = async () => {
    if (messageTemplatesDisabled) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      isLoadingMessageTemplates: true,
      error: null,
    }));
    try {
      const { collection: contractMessageTemplatesData } = await getContractMessageTemplates({
        contractId: agreement.id,
        type: 'publish',
      });

      setState((prevState) => ({
        ...prevState,
        contractMessageTemplates: [standardMessageTemplate, ...contractMessageTemplatesData],
        isLoadingMessageTemplates: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        contractMessageTemplates: [],
        isLoadingMessageTemplates: false,
        error,
      }));
    }
  };

  const resetForm = () => {
    resetUpdateState();
    setState((prevState) => ({
      ...prevState,
      contractMessageTemplates: [],
      selectedMessageTemplate: undefined,
      isLoadingMessageTemplates: false,
      error: null,
    }));
  };

  const handleOpen = () => {
    fetchMessageTemplates();
    updateSelectedMessageTemplate(contractMessageTemplates[0]);
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = ({ subject, body }) => {
    const { signLater } = agreement.config;

    dispatch(agreementsReducer.updateAgreementPreferences({
      id: agreementId,
      data: {
        id: agreementId,
        config: {
          signLater: !signLater,
        },
        subject,
        message: body,
      },
    }));
  };

  const handleClose = () => {
    syncState();
    setIsModalOpen(false);
  };

  const renderMessageTemplateSection = () => {
    if (!checked || isTemplate(agreement)) {
      return null;
    }

    return (
      <div className={style.MessageContainer}>
        <MessageTemplatePicker
          contractMessageTemplates={contractMessageTemplates}
          selectedMessageTemplate={selectedMessageTemplate}
          updateSelectedMessageTemplate={updateSelectedMessageTemplate}
          isSubjectFieldRequired
          isMessageFieldRequired
          isReminder
          signature={signature}
          context={context}
        />
        <AdditionalInformation />
      </div>
    );
  };

  const renderRecipientsList = () => {
    if (!checked) {
      return null;
    }

    return (
      <RecipientList
        agreement={agreement}
        undecidedParticipantsOnly
        currentSignOrderBlockOnly
        isInternalApproverList={false}
      />
    );
  };

  const renderNotificationSection = () => (
    <div>
      {
        isImport(agreement) || isDraft(agreement)
          ? null : (
            <NotifyParticipantsCheckbox
              checked={checked}
              onChange={handleChange}
            />
          )
      }
      <div className={style.Divider} />
      {renderRecipientsList()}
      {renderMessageTemplateSection()}
    </div>
  );

  const renderBody = () => {
    const {
      error,
      isLoadingMessageTemplates,
    } = state;

    if (isEmpty(agreement)) {
      return null;
    }

    if (isLoadingMessageTemplates) {
      return (
        <div className={style.SpinnerContainer}>
          <CircularSpinner />
        </div>
      );
    }

    if (error) {
      return (
        <span className={style.ErrorMessage}>
          <Error className={style.ErrorIcon} />
          <Message
            id="An error has occurred. Please reload the page and try again."
            comment="Used to show the error message after confirmation."
          />
        </span>
      );
    }

    return (
      <div>
        <InvitationHeader />
        <div className={style.BodyContainer}>
          <SignLaterEnabledInfo
            agreement={agreement}
          />
          {renderNotificationSection()}
        </div>
      </div>
    );
  };

  const getMessageBody = () => {
    if (messageTemplatesDisabled) {
      return undefined;
    }

    if (position?.signature && !isEmpty(selectedMessageTemplate) && selectedMessageTemplate.body) {
      return `${selectedMessageTemplate.body}\n\n${position.signature}`;
    }

    if (position?.signature) {
      return position.signature;
    }

    if (selectedMessageTemplate && selectedMessageTemplate.body) {
      return selectedMessageTemplate.body;
    }

    return undefined;
  };

  const getActions = ({ formProps }: FormRenderProps) => (
    <>
      <CancelButton onClick={() => setIsModalOpen(false)} modalKey={modalKey} />
      <Button
        data-testid="sign-later"
        kind="primary"
        onClick={formProps.handleSubmit}
        disabled={formProps.hasValidationErrors}
      >
        <Message
          id="Confirm"
          comment="Button text for confirming sign later"
        />
      </Button>
    </>
  );

  return (
    <ModalForm
      title={(
        <Message
          id="Confirm sign later"
          comment="The title of the confirm sign later modal"
        />
      )}
      body={renderBody()}
      onSubmit={handleSubmit}
      resetFormState={resetForm}
      formState={updateState}
      actions={getActions}
      isOpen={isModalOpen}
      onOpen={handleOpen}
      modalKey={modalKey}
      onClose={handleClose}
      initialValues={{
        subject: selectedMessageTemplate ? selectedMessageTemplate.subject : undefined,
        body: getMessageBody(),
      }}
    >
      {() => null}
    </ModalForm>
  );
};

export default localize<Props>(SignLaterComponent);
