import { Message } from '@oneflowab/pomes';

import { getAgreementDateFormat, formatDateString } from 'date';

import Dot from '../dot';
import style from './style.module.scss';

type Props = {
  agreement: Oneflow.Agreement;
};

const Overdue = ({ agreement }: Props) => {
  const dateFormat = getAgreementDateFormat(agreement?.config?.dateFormat);
  const dateString = formatDateString(agreement?.signingPeriodExpiryTime?.toString(), dateFormat);

  return (
    <div className={style.Insight} data-testid="overdue">
      <Dot color="orange" />
      <span className={style.SemiBold}>
        <Message
          id="Overdue:"
          comment="Agreement status when the agreement is overdue."
        />
      </span>
      <span data-testid="overdue-date">
        {' '}
        {dateString}
      </span>
    </div>
  );
};

export default Overdue;
