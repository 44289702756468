import { DragOverlay } from '@dnd-kit/core';
import { useFormBoxProps } from 'contexts/form-box-props';
import FormBoxRow from 'components/contract-boxes/form-box/rows/form-box-row';
import Field from 'components/contract-boxes/form-box/components/field/field';
import MoveIcon from 'components/icons/move';

import style from './form-box-rows.module.scss';

type Row = {
  id: string;
};

type Field = {
  id: number;
};

type Value = {
  choices: string[];
  colspan: number;
  description: string;
  id: string;
  label: string;
  placeholder: string;
  required: boolean;
  size: number;
  type: string;
  value: string;
  valueDataFieldKey: string;
};

type Data = {
  created: string;
  createdTime: string;
  createdTs: number;
  id: number;
  key: string;
  updated: string;
  updatedTime: string;
  updatedTs: number;
  value: Value;
};

type FormBoxRowsProps = {
  setActivePopover: () => void;
  activePopoverIndexMap: void;
  dndActive: false;
  activeField: {
    data: Data;
    dateFormat: string;
    field: Field;
    columnIndex: number;
    key: string;
    rowIndex: number;
    isEditable: boolean;
  } | null;
  setActiveField: () => void;
};

const FormBoxRows = ({
  setActivePopover,
  activePopoverIndexMap,
  dndActive,
  activeField,
  setActiveField,
}: FormBoxRowsProps) => {
  const {
    order,
  } = useFormBoxProps();

  if (!order) {
    return null;
  }

  return (
    <>
      {order.map((row: Row, rowIndex: number) => {
        const key = row.id;
        return (
          <FormBoxRow
            key={key}
            row={row}
            rowIndex={rowIndex}
            setActivePopover={setActivePopover}
            activePopoverIndexMap={activePopoverIndexMap}
            setActiveField={setActiveField}
            dndActive={dndActive}
            activeField={activeField}
          />
        );
      })}
      {activeField && (
        <DragOverlay>
          <div className={style.CellContainer}>
            <div className={style.Center}>
              <div className={style.IconWrapper}>
                <div className={style.Icon}>
                  <MoveIcon height="14px" />
                </div>
              </div>
              <Field
                activePopoverIndexMap={activePopoverIndexMap}
                data={activeField.data}
                field={activeField.field}
                fieldIndex={activeField.columnIndex}
                key={activeField.key}
                order={order}
                rowIndex={activeField.rowIndex}
                setActivePopover={setActivePopover}
              />
            </div>
          </div>
        </DragOverlay>
      )}
    </>
  );
};

export default FormBoxRows;
