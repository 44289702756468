/* eslint-disable no-param-reassign */
import { useRef } from 'react';
import camelCase from 'lodash/camelCase';

/**
 * Example usage
 * const domRef = useSetDOMAttributes({
      ref: domInternalRef,
      datasets: {
        testid: 'someDOMActionButton',
      },
      refName: 'someDOMActionButton',
    });
 */

function useSetDOMAttributes(params) {
  const {
    ref,
    attributes = {},
    datasets = {},
    refName = 'dom',
  } = params;

  const functionalRefName = `${refName}Ref`;

  const result = useRef({
    [functionalRefName](DOMNode) {
      if (!DOMNode) {
        return;
      }

      // Start adding the attributes
      Object.entries(attributes).forEach(
        (attributeEntry) => DOMNode.setAttribute(...attributeEntry),
      );
      // Data attributes are camelized however it will be as it is defined
      // In html element.
      // Refer: https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/dataset
      Object.entries(datasets).forEach(
        ([datasetKey, datasetValue]) => {
          DOMNode.dataset[camelCase(datasetKey)] = datasetValue;
        },
      );

      ref.current = DOMNode;
    },
  });

  return result.current?.[functionalRefName];
}

export default useSetDOMAttributes;
