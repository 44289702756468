// @flow

export const FILE_IMAGE_PNG = {
  mimeType: 'image/png',
  extensions: ['png'],
};

export const FILE_IMAGE_JPEG = {
  mimeType: 'image/jpeg',
  extensions: ['jpg', 'jpeg'],
};

export const FILE_IMAGE_BMP = {
  mimeType: 'image/bmp',
  extensions: ['bmp'],
};

export const FILE_IMAGE_GIF = {
  mimeType: 'image/gif',
  extensions: ['gif'],
};

export const FILE_IMAGE_TIFF = {
  mimeType: 'image/tiff',
  extensions: ['tiff'],
};

export const FILE_APPLICATION_PDF = {
  mimeType: 'application/pdf',
  extensions: ['pdf'],
};

export const FILE_VIDEO_DROPZONE_ACCEPT = {
  'video/*': ['.mkv'],
  'video/x-flv': ['.flv'],
  'video/x-f4v': ['.f4v'],
};

export const getFileExtensions = (fileType) => fileType.extensions;

export const getFileMimeType = (fileType) => fileType.mimeType;

export const getFileSize = (bytes, sizeToFixed) => {
  // eslint-disable-next-line no-bitwise
  const i = ~~(Math.log2(bytes) / 10);
  const size = sizeToFixed ? (bytes / (1024 ** i)).toFixed(2) : bytes / (1024 ** i).toFixed(2);
  const suffix = ['bytes', 'KB', 'MB', 'GB', 'TB'][i];
  return `${size} ${suffix}`;
};
