/* eslint-disable react/prop-types */
import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { Message } from '@oneflowab/pomes';
import { push } from 'connected-react-router';
import type { FormProps } from 'react-final-form';

import { USER_ACTIVE, USER_ROLE_SCOPE_ACCOUNT, USER_ROLE_USER } from 'user';
import rolesReducer from 'reducers/entities/roles';
import positionsReducer from 'reducers/entities/positions';
import userAccountsReducer from 'reducers/entities/user-accounts';
import accountUsersReducer from 'reducers/entities/account-users';
import { getAccountFromSessionSelector } from 'reducers/session';
import ModalForm from 'hocs/modal-form';

import Field from 'components/field';
import SelectField from 'components/select-field';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import { getAccountUsersQueryName } from 'routes/admin/account-access/account-access';
import CircularSpinnerIcon from 'components/icons/circular-spinner';

import styles from './grant-user-account-access.module.scss';

export const rolesQueryName = 'admin/user/accounts';
export const positionsQueryName = 'admin/users';
const DEFAULT_PAGINATION = {
  limit: 50,
  offset: 0,
};

const POSITION_FIELD = 'position';
const ACCOUNT_ROLE_FIELD = 'account role';

type PropTypes = {
  position?: Oneflow.Position,
  children: JSX.Element | ((onClick: () => void) => JSX.Element),
  shouldRedirectToAccountAccess: boolean,
  renderedFromAccountAccessPage: boolean,
};

type QueryFunctionType = {
  name?: string,
  pagination?: RequestParams.Pagination,
};

type ModalActionsType = {
  closeConfirmation: () => void,
  formProps: FormProps,
}

const GrantUserAccountAccess = ({
  position: positionFromProps,
  children,
  shouldRedirectToAccountAccess,
  renderedFromAccountAccessPage,
}: PropTypes) => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState(positionFromProps);
  const account = useSelector(getAccountFromSessionSelector);

  const positionsQuery = useSelector((state) => (
    positionsReducer.getQuerySelector(state, { name: positionsQueryName })
  ));

  const positions = useSelector((state) => (
    positionsReducer.getPositionsSelector(state, { ids: positionsQuery.result })
  ));

  const rolesQuery = useSelector((state) => (
    rolesReducer.getQuerySelector(state, { name: rolesQueryName })
  ));

  const roles: Oneflow.Role[] = useSelector((state) => (
    rolesReducer.getRolesSelector(state, { ids: rolesQuery.result })
  ));

  const formState = useSelector((state) => (
    userAccountsReducer.getGrantUserAccountAccessSelector(state, { id: position?.id })
  ));

  const resetFormState = () => {
    dispatch(userAccountsReducer.grantUserAccountAccessReset({ id: position?.id }));
  };

  const queryPositions = useCallback(
    ({ name, pagination = DEFAULT_PAGINATION }: QueryFunctionType) => {
      dispatch(positionsReducer.queryPositions({
        name: positionsQueryName,
        params: {
          active: USER_ACTIVE,
          q: name,
          userRole: USER_ROLE_USER,
        },
        pagination,
        sort: ['fullname'],
      }));
    }, [dispatch],
  );

  const queryRoles = useCallback(({ name, pagination = DEFAULT_PAGINATION }: QueryFunctionType) => {
    dispatch(rolesReducer.queryRoles({
      name: rolesQueryName,
      params: {
        scope: USER_ROLE_SCOPE_ACCOUNT,
        q: name,
      },
      pagination,
      sort: ['-type', 'name'],
    }));
  }, [dispatch]);

  const handleLoadMorePositions = (additionalResults: number) => {
    dispatch(positionsReducer.queryPositionsLoadMore({
      name: positionsQueryName,
      additionalResults,
    }));
  };

  const handleLoadMoreAccountRoles = (additionalResults: number) => {
    dispatch(rolesReducer.queryRolesLoadMore({
      name: rolesQueryName,
      additionalResults,
    }));
  };

  const handleInputChange = useMemo(() => debounce((name, field) => {
    if (field === POSITION_FIELD) {
      queryPositions({ name });
    }
    if (field === ACCOUNT_ROLE_FIELD) {
      queryRoles({ name });
    }
  }, 500), [queryPositions, queryRoles]);

  const renderBody = () => (
    <div>
      {renderedFromAccountAccessPage
      && (
      <Field
        name="user"
        label={(
          <Message
            id="User"
            comment="Input field label"
          />
        )}
        placeholder={(
          <Message
            id="Select user"
            comment="Input field placeholder"
          />
        )}
        component={SelectField}
        options={positions}
        valueKey="id"
        labelKey="fullname"
        isLoading={positionsQuery.loading}
        loadMoreItems={handleLoadMorePositions}
        required
        isSearchable
        onInputChange={(name: string) => handleInputChange(name, POSITION_FIELD)}
        onChange={(selectedPosition: Oneflow.Position) => setPosition(selectedPosition)}
      />
      )}
      <Field
        name="accountRole"
        label={(
          <Message
            id="Account role"
            comment="Input field label"
          />
        )}
        placeholder={(
          <Message
            id="Select account role for this user"
            comment="Input field placeholder"
          />
        )}
        component={SelectField}
        options={roles}
        valueKey="id"
        labelKey="name"
        isLoading={rolesQuery.loading}
        loadMoreItems={handleLoadMoreAccountRoles}
        required
        isSearchable
        onInputChange={(name: string) => handleInputChange(name, ACCOUNT_ROLE_FIELD)}
      />
    </div>
  );

  const renderActions = ({ closeConfirmation, formProps }: ModalActionsType) => (
    <div className={styles.ActionButtons}>
      <CancelButton onClick={closeConfirmation} />
      <Button
        kind="primary"
        onClick={formProps.handleSubmit}
        disabled={formState.loading}
        icon={formState.loading ? CircularSpinnerIcon : null}
      >
        <Message
          id="Grant access"
          comment="Text for button to grant access."
        />
      </Button>
    </div>
  );

  const grantUserAccountAccess = ({ accountRole }: {accountRole: Oneflow.Role}) => {
    if (position) {
      const USER_ACCOUNTS_BINDINGS_QUERY_NAME = `admin/user/${position.id}/account-access`;
      const actionData = {
        id: position.id,
        data: {
          positionId: position.id,
          roleId: accountRole.id,
        },
        pipe: {
          action: () => userAccountsReducer.queryUserAccounts({
            name: USER_ACCOUNTS_BINDINGS_QUERY_NAME,
            params: {
              id: position.id,
              actorType: 'position',
            },
            sort: ['-role.type', 'role.name'],
          }),
        },
      };

      if (shouldRedirectToAccountAccess) {
        actionData.pipe.action = () => push(`/admin/users/${position.id}/account-access`);
      }

      if (renderedFromAccountAccessPage) {
        actionData.pipe.action = () => accountUsersReducer.queryAccountUsers({
          name: getAccountUsersQueryName(account.id),
          params: {
            actorType: 'position',
          },
          pagination: DEFAULT_PAGINATION,
          sort: ['fullname'],
        });
      }

      dispatch(userAccountsReducer.grantUserAccountAccess(actionData));
    }
  };

  return (
    <ModalForm
      title={(
        <Message
          id="Grant account access"
          comment="Modal title for granting account access"
        />
    )}
      body={renderBody()}
      onSubmit={grantUserAccountAccess}
      formState={formState}
      resetFormState={resetFormState}
      onOpen={() => {
        queryRoles({});
        if (renderedFromAccountAccessPage) queryPositions({});
      }}
      actions={renderActions}
      modalKey="grant user account access modal"
    >
      {children}
    </ModalForm>
  );
};

export default GrantUserAccountAccess;
