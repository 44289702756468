/* eslint-disable no-param-reassign */
// @flow

import React, { Fragment, ReactNode } from 'react';
import { take, takeRight, uniqueId } from 'lodash';
import { FontSizeSelector, TextStyles } from 'components/rich-text-editor-toolbars/toolbar-buttons';
import { getActiveImage } from 'components/rich-text-editor/editor-plugins/image-utils';
import HistoryButton from 'components/rich-text-editor-toolbars/toolbar-buttons/history-button';
import RichTextEditorToolbarDivider from 'components/rich-text-editor-toolbar-divider';
import {
  responsiveToolbarPlugins, linkPlugin, boldPlugin, italicPlugin, aiPlugin,
} from '../toolbar-plugins';

// 8px (margin left) + 8px (margin right)
const marginHorizontal = 8 + 8;

// Fixed menu plugin buttons
const defaultPluginButtonWidth = 24 + marginHorizontal;
const undoPluginWidth = 24 + marginHorizontal;
const redoPluginWidth = 24 + marginHorizontal;
const boldPluginWidth = 24 + marginHorizontal;
const italicPluginWidth = 24 + marginHorizontal;

const bigTextStylesWidth = 136;
const smallTextStylesWidth = 62;
const fontSizeSelectorWidth = 62;
const dividerWidth = 1;

// Have a default oversized width if its not provided
const defaultMoreButtonWidth = 72;

// eslint-disable-next-line import/prefer-default-export
export const getToolbarPlugins = (
  editor: any,
  width: number,
  disabledPlugins: Array<Function>,
  isLinkDisabled: boolean,
  isAIDisabled: boolean,
  hasAgreementAiAssistPermission: boolean,
  moreButtonWidth?: number = defaultMoreButtonWidth,
) => {
  if (!width) {
    return {
      toolbarPlugins: [],
      menuPlugins: [],
    };
  }

  const shouldRenderSmallTextStyles = width < 600;
  let fixedPluginsWidth = undoPluginWidth
    + redoPluginWidth
    + bigTextStylesWidth
    + dividerWidth
    + fontSizeSelectorWidth
    + dividerWidth
    + boldPluginWidth
    + italicPluginWidth;

  if (shouldRenderSmallTextStyles) {
    fixedPluginsWidth = undoPluginWidth
    + redoPluginWidth
    + smallTextStylesWidth
    + dividerWidth
    + fontSizeSelectorWidth
    + dividerWidth
    + boldPluginWidth
    + italicPluginWidth;
  }

  const activeImage = getActiveImage(editor);
  const isDisabled = Boolean(activeImage);

  const availableWidth = width - fixedPluginsWidth;

  let availablePlugins = [...responsiveToolbarPlugins];

  if (isLinkDisabled) {
    availablePlugins = availablePlugins
      .filter((plugin) => linkPlugin !== plugin);
  }

  let {
    toolbarPlugins,
    menuPlugins,
  } = availablePlugins.reduce((categorizedPlugins, plugin) => {
    const buttonWidth = plugin.width || defaultPluginButtonWidth;
    // More button adds a divider that needs to be accounted for
    const threshold = moreButtonWidth + buttonWidth + dividerWidth;
    const pluginShouldBeHidden = categorizedPlugins.availableWidth <= threshold;

    if (plugin === aiPlugin) {
      if (!hasAgreementAiAssistPermission) {
        return categorizedPlugins;
      }
    }

    if (pluginShouldBeHidden) {
      categorizedPlugins.menuPlugins.push(plugin);

      return categorizedPlugins;
    }

    if (plugin === 'separator') {
      categorizedPlugins.availableWidth -= dividerWidth;
    } else {
      categorizedPlugins.availableWidth -= buttonWidth;
    }

    categorizedPlugins.toolbarPlugins.push(plugin);

    return categorizedPlugins;
  },
  {
    toolbarPlugins: [],
    menuPlugins: [],
    availableWidth,
  });

  if (toolbarPlugins[toolbarPlugins.length - 1] === 'separator') {
    toolbarPlugins = take(toolbarPlugins, toolbarPlugins.length - 1);
  }

  toolbarPlugins = toolbarPlugins
    .map((plugin) => {
      if (plugin === 'separator') {
        return <RichTextEditorToolbarDivider key={uniqueId()} />;
      }

      if (plugin === aiPlugin) {
        return plugin({
          isMenuItem: false,
          disabled: isDisabled || isAIDisabled,
        });
      }

      return plugin({
        isMenuItem: false,
        disabled: isDisabled || disabledPlugins.includes(plugin),
      });
    });

  if (menuPlugins[0] === 'separator') {
    menuPlugins = takeRight(menuPlugins, menuPlugins.length - 1);
  }

  menuPlugins = menuPlugins
    .map<ReactNode>((plugin) => {
      if (plugin === 'separator') {
        return 'separator';
      }

      if (plugin === aiPlugin) {
        return plugin({
          isMenuItem: true,
          disabled: isDisabled || isAIDisabled,
        });
      }

      return plugin({
        isMenuItem: true,
        disabled: isDisabled || disabledPlugins.includes(plugin),
      });
    });

  return {
    toolbarPlugins: [
      <Fragment key="fixed-plugins">
        <HistoryButton action="undo" disabled={isDisabled} />
        <HistoryButton action="redo" disabled={isDisabled} />
        <TextStyles isSmall={shouldRenderSmallTextStyles} />
        <RichTextEditorToolbarDivider />
        <FontSizeSelector />
        <RichTextEditorToolbarDivider />
        {boldPlugin(
          { isMenuItem: false, disabled: isDisabled || disabledPlugins.includes(boldPlugin) },
        )}
        {italicPlugin(
          { isMenuItem: false, disabled: isDisabled || disabledPlugins.includes(italicPlugin) },
        )}
      </Fragment>,
      ...toolbarPlugins,
    ],
    menuPlugins,
  };
};
