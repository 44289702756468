// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import { isMarkedAsDeclined } from 'agreement';
import {
  formatDateString,
  getDaysSince,
} from 'date';

import NewCross from 'components/icons/new-cross';

import Insight from './insight';

import style from './insight.module.scss';

const markedAsDeclinedInsights = {
  today: (date: string) => (
    <Message
      id="Marked as declined today ({date})"
      values={{
        date,
      }}
      comment="Insight for declined contract, stating when contract was marked as declined. Visible in contract card."
    />
  ),
  recent: (date: string, daysSince: number) => (
    <Message
      id="Marked as declined yesterday ({date})"
      pluralId="Marked as declined {days} days ago ({date})"
      pluralCondition="days"
      values={{
        days: daysSince,
        date,
      }}
      comment="Insight for declined contract, stating when contract was marked as declined. Visible in contract card."
    />
  ),
  old: (date: string) => (
    <Message
      id="Marked as declined {date}"
      values={{
        date,
      }}
      comment="Insight for declined contract, stating when contract was marked as declined. Visible in contract card."
    />
  ),
};
const declinedInsights = {
  today: (date: string) => (
    <Message
      id="Declined today ({date})"
      values={{
        date,
      }}
      comment="Insight for declined contract, stating when contract was declined. Visible in contract card."
    />
  ),
  recent: (date: string, daysSince: number) => (
    <Message
      id="Declined yesterday ({date})"
      pluralId="Declined {days} days ago ({date})"
      pluralCondition="days"
      values={{
        days: daysSince,
        date,
      }}
      comment="Insight for declined contract, stating when contract was declined. Visible in contract card."
    />
  ),
  old: (date: string) => (
    <Message
      id="Declined {date}"
      values={{
        date,
      }}
      comment="Insight for declined contract, stating when contract was declined. Visible in contract card."
    />
  ),
};

type Props = {
  agreement: Agreement,
  dateFormat: string,
};

const Declined = ({ agreement, dateFormat }: Props) => {
  const renderInsight = () => {
    const daysSince = getDaysSince(agreement.stateTime);
    const dateString = formatDateString(agreement.stateTime, dateFormat);
    const date = <span className={style.Date}>{dateString}</span>;

    let insightTexts = declinedInsights;
    if (isMarkedAsDeclined(agreement)) {
      insightTexts = markedAsDeclinedInsights;
    }

    if (daysSince === 0) {
      return insightTexts.today(date);
    }

    if (daysSince > 7) {
      return insightTexts.old(date);
    }

    if (daysSince > 0) {
      return insightTexts.recent(date, daysSince);
    }

    return null;
  };

  return (
    <Insight
      IconComponent={NewCross}
      insight={renderInsight()}
    />
  );
};

export default Declined;
