// @flow

import * as React from 'react';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import { Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';

import * as EditableForm from 'components/editable-form';
import Field from 'components/field';
import Button from 'components/button';
import TooltipInfo from 'components/tooltip-info';
import Download from 'components/icons/download';
import ExportAccount from 'components/export-account';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import Toggle from 'components/toggle';
import {
  getEmailProvider,
  getEmailProvidersAsOptions,
  getDurationUnitsAsOptions,
  getDurationScalar,
  getDurationUnit,
  parseValueToSeconds,
  FIVE_MINS_IN_SECONDS,
  YEAR_IN_SECONDS,
} from 'account';
import { getHelpCenterBaseUrl } from 'utils/help-center';
import Divider from 'components/divider';
import { formatTimePeriod } from 'agreement/date-helpers';

import style from './data-management.module.scss';

export type Props = {
  message: MessageTranslator,
  isAccountExportEnabled: boolean,
  canEdit: boolean,
  currentDpoEmail: string,
  currentEmailProvider: string,
  currentFreshworks: boolean,
  currentCustomerIo: boolean,
  currentOpenAi: boolean,
  currentContractPdfShowContactDetails: boolean,
  currentContractPdfShowSsn: boolean,
  currentContractPdfShowIp: boolean,
  currentContractPdfDownload: boolean,
  currentAddressBook: boolean,
  currentLoginSessionLength: string,
  currentLoginTrustedDeviceLength: string,
  currentHandwrittenSignatureAllowTyped: boolean,
  updateState: UpdateState,
  fetchAccount: ({ id: number }) => void,
  resetUpdateState: (accountId: number) => void,
  updateAccountDataManagement: (id: number, data: {
    dpoEmail: string,
    emailProvider: string,
    freshworks: boolean,
    customerIo: boolean,
    openAi: Boolean,
    contractPdfShowContactDetails: boolean,
    contractPdfShowSsn: boolean,
    contractPdfShowIp: boolean,
    contractPdfDownload: boolean,
    addressBook: boolean,
    loginSessionLength: string,
    loginTrustedDeviceLength: string,
    handwrittenSignatureAllowTyped: boolean,
  }) => void,
  accountId: number,
  message: MessageTranslator,
  location: Location,
  updatePath: (pathname: string) => void,
  fetchState: { loading: boolean },
};

const openAiTermsLink = 'https://openai.com/policies/';

export class DataManagementComponent extends React.Component<Props> {
  state = {
    isEditMode: false,
  };

  componentDidMount() {
    this.fetchAccount();
  }

  fetchAccount = () => {
    const {
      fetchAccount,
      accountId,
    } = this.props;

    fetchAccount({ id: accountId });
  };

  componentDidUpdate() {
    this.enableEditModeIfRedirected();
  }

  getToggleStatus(toggleValue) {
    const { message } = this.props;

    return toggleValue
      ? message({ id: 'Enabled', comment: 'Used to show data management toggle status' })
      : message({ id: 'Disabled', comment: 'Used to show data management toggle status' });
  }

  getHelpCenterLink = () => (`${getHelpCenterBaseUrl()}support/solutions/articles/77000435924-two-step-authentication-for-login`);

  getDurationLabel(value) {
    return formatTimePeriod(value, this.props.message);
  }

  loginSessionValidator = (value: string, { loginSessionScalar, loginSessionUnit }: any) => {
    const { message } = this.props;
    if (parseValueToSeconds(loginSessionScalar, loginSessionUnit) < FIVE_MINS_IN_SECONDS) {
      return message({
        id: 'Select a duration between 5 minutes and 1 year.',
        comment: 'Validation message for login session setting',
      });
    }
    if (parseValueToSeconds(loginSessionScalar, loginSessionUnit) > YEAR_IN_SECONDS) {
      return message({
        id: 'Select a duration between 5 minutes and 1 year.',
        comment: 'Validation message for login session setting',
      });
    }
    if (!Number(loginSessionScalar) || Number.isNaN(loginSessionScalar)) {
      return message({
        id: 'Enter a whole number greater than 0.',
        comment: 'Validation message for login session setting',
      });
    }
    return undefined;
  }

  trustedDeviceValidator = (value: string, {
    loginTrustedDeviceScalar,
    loginTrustedDeviceUnit,
  }: any) => {
    const { message } = this.props;
    if (
      parseValueToSeconds(loginTrustedDeviceScalar, loginTrustedDeviceUnit) < FIVE_MINS_IN_SECONDS
    ) {
      return message({
        id: 'Select a duration between 5 minutes and 10 years.',
        comment: 'Validation message for login session setting',
      });
    }
    if (
      parseValueToSeconds(loginTrustedDeviceScalar, loginTrustedDeviceUnit) > (10 * YEAR_IN_SECONDS)
    ) {
      return message({
        id: 'Select a duration between 5 minutes and 10 years.',
        comment: 'Validation message for login session setting',
      });
    }
    if (!Number(loginTrustedDeviceScalar) || Number.isNaN(loginTrustedDeviceScalar)) {
      return message({
        id: 'Enter a whole number greater than 0.',
        comment: 'Validation message for login session setting',
      });
    }
    return undefined;
  }

  getInitialValues = () => {
    const {
      currentDpoEmail,
      currentEmailProvider,
      currentFreshworks,
      currentCustomerIo,
      currentOpenAi,
      currentContractPdfShowContactDetails,
      currentContractPdfShowSsn,
      currentContractPdfShowIp,
      currentContractPdfDownload,
      currentAddressBook,
      currentHandwrittenSignatureAllowTyped,
      currentLoginSessionLength,
      currentLoginTrustedDeviceLength,
    } = this.props;

    return {
      dpoEmail: currentDpoEmail,
      emailProvider: currentEmailProvider,
      freshworks: currentFreshworks,
      customerIo: currentCustomerIo,
      openAi: currentOpenAi,
      contractPdfShowContactDetails: currentContractPdfShowContactDetails,
      contractPdfShowSsn: currentContractPdfShowSsn,
      contractPdfShowIp: currentContractPdfShowIp,
      contractPdfDownload: currentContractPdfDownload,
      addressBook: currentAddressBook,
      handwrittenSignatureAllowTyped: currentHandwrittenSignatureAllowTyped,
      loginSessionScalar: getDurationScalar(currentLoginSessionLength),
      loginSessionUnit: getDurationUnit(currentLoginSessionLength),
      loginTrustedDeviceScalar: getDurationScalar(currentLoginTrustedDeviceLength),
      loginTrustedDeviceUnit: getDurationUnit(currentLoginTrustedDeviceLength),
    };
  }

  enableEditModeIfRedirected = () => {
    const { updatePath, location, canEdit } = this.props;

    if (!this.state.isEditMode && location?.query.edit && canEdit) {
      updatePath(location.pathname);

      this.setIsEditMode(true);
    }
  };

  setIsEditMode = (_isEditMode: boolean) => {
    this.setState({ isEditMode: _isEditMode });
  }

  getMaintenanceText = (isAccountExportEnabled: boolean) => {
    if (isAccountExportEnabled) {
      return null;
    }

    return (
      <p className={style.InfoText}>
        <Message
          id="Export temporarily unavailable due to maintenance."
          comment="Help text for the export account section in the data management page."
        />
      </p>
    );
  };

  handleResetUpdateState = () => {
    const { accountId, resetUpdateState } = this.props;

    resetUpdateState(accountId);
  };

  handleUpdateAccountDataManagement = ({
    dpoEmail,
    emailProvider,
    freshworks,
    customerIo,
    openAi,
    contractPdfShowContactDetails,
    contractPdfShowSsn,
    contractPdfShowIp,
    contractPdfDownload,
    addressBook,
    handwrittenSignatureAllowTyped,
    loginSessionUnit,
    loginSessionScalar,
    loginTrustedDeviceUnit,
    loginTrustedDeviceScalar,
  }) => {
    const { accountId, updateAccountDataManagement } = this.props;
    updateAccountDataManagement(accountId, {
      dpoEmail,
      emailProvider: emailProvider.value,
      freshworks,
      customerIo,
      openAi,
      contractPdfShowContactDetails,
      contractPdfShowSsn,
      contractPdfShowIp,
      contractPdfDownload,
      addressBook,
      handwrittenSignatureAllowTyped,
      loginSessionLength: loginSessionScalar + (loginSessionUnit.value || loginSessionUnit),
      loginTrustedDeviceLength: (
        loginTrustedDeviceScalar + (loginTrustedDeviceUnit.value || loginTrustedDeviceUnit)
      ),
    }, () => this.setIsEditMode(false));
  };

  getExportAccountButton = (onClick: () => void) => (
    <Button
      kind="secondary"
      icon={Download}
      onClick={onClick}
    >
      <Message
        id="Export account"
        comment="Button label for exporting the account"
      />
    </Button>
  )

  renderDataManagement() {
    const { isEditMode } = this.state;
    const {
      currentDpoEmail,
      currentEmailProvider,
      currentFreshworks,
      currentCustomerIo,
      currentOpenAi,
      currentContractPdfShowContactDetails,
      currentContractPdfShowSsn,
      currentContractPdfShowIp,
      currentContractPdfDownload,
      currentAddressBook,
      currentLoginSessionLength,
      currentLoginTrustedDeviceLength,
      currentHandwrittenSignatureAllowTyped,
      message,
      fetchState,
    } = this.props;

    return (
      <EditableForm.Body>

        <div className={style.Row}>
          <EditableForm.Label>
            <Message
              id="DPO email"
              comment="Label updating a setting in the data management page."
            />
          </EditableForm.Label>
          <Field
            name="dpoEmail"
            component={TextField}
            value={currentDpoEmail}
            placeholder={message({
              id: 'Enter email',
              comment: 'Placeholder for the DPO email setting in the data management page.',
            })}
            email
            autoFocus
            disabled={!isEditMode}
          />
        </div>
        <div className={style.Row}>
          <EditableForm.Label>
            <Message
              id="Email service provider"
              comment="Label updating a setting in the data management page."
            />
            <TooltipInfo
              message={(
                <Message
                  id="You can choose an email service provider for all transactional emails sent from Oneflow. Postmark offers a higher delivery rate than AWS SES. However, the latter is located in the EU."
                  comment="Tooltip message for a data management setting in data management."
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <Field
            name="emailProvider"
            component={SelectField}
            options={getEmailProvidersAsOptions({ message })}
            value={getEmailProvider(currentEmailProvider, message)}
            disabled={!isEditMode}
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Freshworks"
              comment="Label updating a setting in the data management page."
            />
            <TooltipInfo
              message={(
                <Message
                  id="Enable or disable in-app support chat"
                  comment="Tooltip message for a data management setting in data management."
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <Field
            key={fetchState.loading ? 'freshworks-loading' : 'freshworks-loaded'}
            name="freshworks"
            component={Toggle}
            value={currentFreshworks}
            disabled={!isEditMode}
            type="checkbox"
            version="latest"
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Customer.io"
              comment="Label updating a setting in the data management page."
            />
          </EditableForm.Label>
          <Field
            key={fetchState.loading ? 'customerIo-loading' : 'customerIo-loaded'}
            name="customerIo"
            component={Toggle}
            value={currentCustomerIo}
            disabled={!isEditMode}
            type="checkbox"
            version="latest"
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            OpenAI
            <TooltipInfo
              message={(
                <Message
                  id="Read more about LLM Provider terms here: {openaiTermsLink}"
                  comment="Tooltip message for a data management setting in data management."
                  values={{
                    openaiTermsLink: (
                      <a
                        href={openAiTermsLink}
                        onClick={() => window.open(openAiTermsLink, '_blank')}
                        role="button"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={style.LinkText}
                      >
                        OpenAI Terms
                      </a>
                    ),
                  }}
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <Field
            key={fetchState.loading ? 'openAi-loading' : 'openAi-loaded'}
            name="openAi"
            component={Toggle}
            value={currentOpenAi}
            disabled={!isEditMode}
            type="checkbox"
            version="latest"
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Show contact details in PDF"
              comment="Label updating a setting in the data management page."
            />
            <TooltipInfo
              message={(
                <Message
                  id="Show or hide participant's phone and email in PDF documents"
                  comment="Tooltip message for a data management setting in data management."
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <Field
            key={fetchState.loading ? 'contractPdfShowContactDetails-loading' : 'contractPdfShowContactDetails-loaded'}
            name="contractPdfShowContactDetails"
            component={Toggle}
            value={currentContractPdfShowContactDetails}
            disabled={!isEditMode}
            type="checkbox"
            version="latest"
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Show Personal ID number in PDF"
              comment="Label updating a setting in the data management page."
            />
            <TooltipInfo
              message={(
                <Message
                  id="Show or hide participant's Personal identity number or its equivalent in PDF documents"
                  comment="Tooltip message for a data management setting in data management."
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <Field
            key={fetchState.loading ? 'contractPdfShowSsn-loading' : 'contractPdfShowSsn-loaded'}
            name="contractPdfShowSsn"
            component={Toggle}
            value={currentContractPdfShowSsn}
            disabled={!isEditMode}
            type="checkbox"
            version="latest"
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Show IP Address in PDF"
              comment="Label updating a setting in the data management page."
            />
          </EditableForm.Label>
          <Field
            key={fetchState.loading ? 'contractPdfShowIp-loading' : 'contractPdfShowIp-loaded'}
            name="contractPdfShowIp"
            component={Toggle}
            value={currentContractPdfShowIp}
            disabled={!isEditMode}
            type="checkbox"
            version="latest"
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Allow downloading PDFs"
              comment="Label updating a setting in the data management page."
            />
            <TooltipInfo
              message={(
                <Message
                  id="Allow users to download PDFs or export PDF documents"
                  comment="Tooltip message for a data management setting in data management."
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <Field
            key={fetchState.loading ? 'contractPdfDownload-loading' : 'contractPdfDownload-loaded'}
            name="contractPdfDownload"
            component={Toggle}
            value={currentContractPdfDownload}
            disabled={!isEditMode}
            type="checkbox"
            version="latest"
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Address book"
              comment="Label updating a setting in the data management page."
            />
            <TooltipInfo
              message={(
                <Message
                  id="Allow users to store information about counterparties in the address book. Existing contacts will remain in the system even if you disable the address book."
                  comment="Tooltip message for a data management setting in data management."
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <Field
            key={fetchState.loading ? 'addressBook-loading' : 'addressBook-loaded'}
            name="addressBook"
            component={Toggle}
            value={currentAddressBook}
            disabled={!isEditMode}
            type="checkbox"
            version="latest"
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Allow typed handwritten signature"
              comment="Label updating a setting in the data management page."
            />
            <TooltipInfo
              message={(
                <Message
                  id="Enable typed signatures in addition to drawn ones for the 'Handwritten Signature' signing method."
                  comment="Tooltip message for a data management setting in data management."
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <Field
            key={fetchState.loading ? 'handwrittenSignatureAllowTyped-loading' : 'handwrittenSignatureAllowTyped-loaded'}
            name="handwrittenSignatureAllowTyped"
            component={Toggle}
            value={currentHandwrittenSignatureAllowTyped}
            disabled={!isEditMode}
            type="checkbox"
            version="latest"
          />
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Login session duration"
              comment="Label updating a setting in the data management page."
            />
            <TooltipInfo
              message={(
                <Message
                  id="Specify how long users can stay inactive in the system before they are automatically logged out."
                  comment="Tooltip message for a data management setting in data management."
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <div className={style.Right}>
            <div>
              <Field
                name="loginSessionScalar"
                component={TextField}
                value={this.getDurationLabel(currentLoginSessionLength)}
                customClass={style.ScalarInputContainer}
                errorCustomClass={style.Error}
                disabled={!isEditMode}
              />
            </div>
            <div>
              <Field
                name="loginSessionUnit"
                component={SelectField}
                options={getDurationUnitsAsOptions({ message })}
                validate={isEditMode ? this.loginSessionValidator : undefined}
                searchable
                errorCustomClass={style.Error}
                disabled={!isEditMode}
              />
            </div>
          </div>
        </div>
        <div className={clsx(style.Row, style.Flex)}>
          <EditableForm.Label className={style.Label}>
            <Message
              id="Device trust period"
              comment="Label updating a setting in the data management page."
            />
            <TooltipInfo
              message={(
                <Message
                  id="Specify for how long Oneflow remembers trusted devices when 2FA is enabled (from 5 minutes to 10 years). During this time, users don't need to enter a security code to log in. {helpCenterLink}"
                  comment="Tooltip message for a data management setting in data management."
                  values={{
                    helpCenterLink: (
                      <a
                        href={this.getHelpCenterLink()}
                        onClick={() => window.open(this.getHelpCenterLink(), '_blank')}
                        role="button"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={style.LinkText}
                      >
                        <Message
                          id="Learn more about 2FA for logging in."
                          comment="A link that takes the user to the support center and explains more about 2FA"
                        />
                      </a>
                    ),
                  }}
                />
              )}
              side="right"
            />
          </EditableForm.Label>
          <div className={style.Right}>
            <div>
              <Field
                name="loginTrustedDeviceScalar"
                component={TextField}
                value={this.getDurationLabel(currentLoginTrustedDeviceLength)}
                customClass={style.ScalarInputContainer}
                errorCustomClass={style.Error}
                disabled={!isEditMode}
              />
            </div>
            <div>
              <Field
                name="loginTrustedDeviceUnit"
                component={SelectField}
                options={getDurationUnitsAsOptions({ message })}
                searchable
                validate={isEditMode ? this.trustedDeviceValidator : undefined}
                errorCustomClass={style.Error}
                disabled={!isEditMode}
              />
            </div>
          </div>
        </div>
      </EditableForm.Body>
    );
  }

  renderExportAccount() {
    const { isAccountExportEnabled } = this.props;

    return (
      <div className={style.DataManagementExport}>
        <Divider />
        <div className={style.Section}>
          <h2 className={style.Header}>
            <Message
              id="Export account"
              comment="Header text for the export account section in the data management page."
            />
          </h2>
          <p className={style.InfoText}>
            <Message
              id="Request an export of your account data. The link to download your exported files will be sent to your email."
              comment="Help text for the export account section in the data management page."
            />
          </p>
          <ExportAccount>
            {this.getExportAccountButton}
          </ExportAccount>
          {this.getMaintenanceText(isAccountExportEnabled)}
        </div>
      </div>
    );
  }

  render() {
    const { isEditMode } = this.state;
    const { canEdit } = this.props;
    const { resetUpdateState, updateState } = this.props;

    return (
      <Form
        onSubmit={this.handleUpdateAccountDataManagement}
        initialValues={this.getInitialValues()}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={style.DataManagement}>
            <EditableForm.Header>
              <EditableForm.Actions
                resetFormState={resetUpdateState}
                formState={updateState}
                isEditMode={isEditMode}
                setIsEditMode={this.setIsEditMode}
                disabled={!canEdit}
              />
            </EditableForm.Header>
            {this.renderDataManagement()}
            {this.renderExportAccount()}
          </form>
        )}
      />
    );
  }
}

type MapperProps = {
  message: MessageTranslator,
};

export const propsMapper = ({ props: { message } }: { props: MapperProps }) => ({
  title: message({
    id: 'Data management',
    comment: 'The page title',
  }),
  modules: [[]],
});

export default adminPage(propsMapper)(DataManagementComponent);
