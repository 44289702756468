import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { isPending } from 'agreement';
import isUndecided from 'agreement/participant/is-undecided';
import {
  canBeSigned,
  getAgreementMyParty,
  getUndecidedSignatories,
  isAgreementOwner,
  isMyParticipantTurnToSign,
  isSoleSignatory,
} from 'agreement/selectors';

import { getAccountFromSessionSelector } from 'reducers/session';
import agreementsReducer from 'reducers/entities/agreements';
import { getClientFeatureFlagSelector } from 'reducers/app';

import SignContractConfirm, { Props } from './sign-contract-confirm';

type OwnProps = {
  agreement: Agreement,
  guestToken?: string,
  myParticipantWhenSignatory: AgreementParticipant,
  onClose: () => void,
  sendChecksum?: boolean
};

type StateProps = {
  fetchAgreementState: FetchState,
  isOwner: boolean,
  isSoleSignatory: boolean,
  lastToSign: boolean,
  myParty: AgreementParty | null | undefined,
  rpcState: RpcState
};

type DispatchProps = {
  signAgreement: (arg1: number) => void,
  resetRpcState: () => void
};

export const canBeSignedByMyParticipant = (
  agreement: Agreement,
  myParticipant?: AgreementParticipant | null,
) => {
  const agreementCanBeSigned = canBeSigned(agreement);

  return agreementCanBeSigned
    && myParticipant
    && isMyParticipantTurnToSign(agreement, myParticipant)
    && isUndecided(myParticipant)
    && isPending(agreement);
};

export const isLastToSign = (
  agreement: Agreement,
) => getUndecidedSignatories(agreement).length === 1;

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state: State, {
  agreement,
}: OwnProps) => {
  const accountFromSession = getAccountFromSessionSelector(state);

  return {
    fetchAgreementState: agreementsReducer.getFetchSelector(state, { id: agreement.id }),
    isOwner: isAgreementOwner(accountFromSession, agreement),
    isSoleSignatory: isSoleSignatory(agreement),
    lastToSign: isLastToSign(agreement),
    myParty: getAgreementMyParty(agreement),
    rpcState: agreementsReducer.getSignAgreementSelector(state, { id: agreement.id }),
  };
};

type MapDispatchToProps = (dispatch: Dispatch<any>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  agreement,
  guestToken,
  sendChecksum,
}) => ({
  signAgreement: (myParticipantIdWhenSignatory) => {
    let data = {
      participantId: myParticipantIdWhenSignatory,
    };
    if (guestToken) {
      data = {
        ...data,
        guestToken,
      };
    }
    if (sendChecksum) {
      data = {
        ...data,
        checksum: agreement.checksum,
      };
    }

    dispatch(agreementsReducer.signAgreement({
      id: agreement.id,
      data,
    }));
  },
  resetRpcState: () => {
    dispatch(agreementsReducer.signAgreementReset({
      id: agreement.id,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<any>>(
  mapStateToProps, mapDispatchToProps,
)(SignContractConfirm);
