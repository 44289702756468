import { useState } from 'react';
import { useSlate } from 'slate-react';

// eslint-disable-next-line import/named
import { Popover, PopoverContent } from 'components/popover';

import { AltTextButton } from 'components/rich-text-editor-toolbars/toolbar-buttons/alt-text/alt-text-button';
import { AltTextContent } from './alt-text-content/alt-text-content';
import { isAltTextActive } from './alt-text-plugin';

import style from './alt-text.module.scss';

export const AltText = () => {
  const editor = useSlate();
  const isActive = isAltTextActive(editor);
  const [isOpen, setIsOpen] = useState(false);
  const [hideTooltip, setHideTooltip] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <AltTextButton
        isActive={isActive}
        hideTooltip={hideTooltip}
        setHideTooltip={setHideTooltip}
      />
      <PopoverContent
        side="bottom"
        className={style.Popover}
        style={{ userSelect: 'auto' }}
        onClick={
          (event) => {
            event.stopPropagation();
          }
        }
      >
        <AltTextContent />
      </PopoverContent>
    </Popover>
  );
};
