import { getPendingStateApprovers } from 'agreement/pending-state-flow';
import { ACTION_STATUS_RUNNING } from 'agreement/actions/constants';

export const hasOtherPendingStateApprovals = (
  agreement: Oneflow.Agreement,
  participant: Oneflow.Participant,
) => {
  const agreementApprovers = getPendingStateApprovers(agreement);

  if (agreementApprovers === null || !agreementApprovers.length) {
    return false;
  }

  const otherPendingApprovers = agreementApprovers.filter(
    (approver) => (approver.status === ACTION_STATUS_RUNNING
      || approver.status === 'NOT_STARTED')
    && approver.config.actor.id !== participant.id,
  );

  return otherPendingApprovers.length > 0;
};
