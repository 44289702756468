// @flow

import React from 'react';
import urlJoin from 'url-join';
import { Redirect, type Match } from 'react-router';

import adminPage from 'hocs/admin-page';

import { checkAcl } from 'components/acl';

import Profile from 'routes/user/profile';
import Notifications from 'routes/user/notifications';
import UserMessageTemplates from 'routes/user/message-templates';
import Security from 'routes/user/security';
import type { PropsMapper } from 'hocs/admin-page/admin-page';

type Props = {
  match: Match,
};

export const UserComponent = (props: Props) => (
  <Redirect from={props.match.path} to={urlJoin(props.match.url, '/profile')} />
);

export const userModule: PropsMapper = ({
  props: {
    message,
    position,
    account,
    workspace,
  },
}) => {
  let modules = [
    {
      component: Profile,
      id: 'profile-tab',
      isMainPage: true,
      path: '/profile',
      section: message({
        id: 'Profile',
        comment: 'Used as the title for the section.',
      }),
      title: message({ id: 'Profile', comment: 'The tab title' }),
      workspace,
    },
    {
      component: Notifications,
      id: 'notifications-tab',
      isMainPage: true,
      path: '/notifications',
      section: message({
        id: 'Profile',
        comment: 'Used as the title for the section.',
      }),
      title: message({ id: 'Notifications', comment: 'The tab title' }),
      workspace,
    },
    {
      component: UserMessageTemplates,
      id: 'templates-tab',
      isMainPage: true,
      path: '/templates',
      section: message({
        id: 'Profile',
        comment: 'Used as the title for the section.',
      }),
      title: message({ id: 'Message templates', comment: 'The tab title' }),
      workspace,
    },
  ];

  if (checkAcl(position.acl, 'position:mfa')) {
    modules = [
      ...modules,
      {
        component: Security,
        id: 'security-tab',
        isMainPage: true,
        path: '/security',
        section: message({
          id: 'Profile',
          comment: 'Used as the title for the section.',
        }),
        title: message({
          id: 'Security',
          comment: 'The tab title',
        }),
        account,
        workspace,
      },
    ];
  }

  return ({
    title: message({ id: 'User', comment: 'The page title' }),
    showAsLink: false,
    modules: [modules],
  });
};

export default adminPage(userModule)(UserComponent);
