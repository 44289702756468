// @flow

import React from 'react';

import withCurrentWorkspace from 'hocs/with-current-workspace';

import { checkAcl } from 'components/acl';
import { NotFound } from 'components/errors';

import Templates from './templates';

type Props = {
  acl: {
    workspace: Acl,
  },
  workspace: Workspace,
};

export const TemplatesModule = (props: Props) => {
  const handleRedirect = () => {
    const hasAccessToTemplate = checkAcl(props.acl.workspace, 'collection:module:template:use');
    if (hasAccessToTemplate) {
      return (<Templates {...props} />);
    }

    const hasAccessToOwnDashboard = checkAcl(props.acl.workspace, 'collection:module:dashboard:use');
    if (hasAccessToOwnDashboard) {
      return (<NotFound redirect to="/dashboard" />);
    }
    return <NotFound redirect to="/address-book" />;
  };

  return handleRedirect();
};

export default withCurrentWorkspace(TemplatesModule);
