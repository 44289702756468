// @flow

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import urlJoin from 'url-join';

import contacts from 'reducers/entities/contacts';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import { getCurrentWorkspaceSelector } from 'reducers/app';

import Contacts from './contacts';

const QUERY_NAME = 'address-book/contacts';

export const mapStateToProps = (state: State) => {
  const contactsQuery = contacts.getQuerySelector(state, { name: QUERY_NAME });

  return {
    contactsQuery,
    contacts: contacts.getContactsSelector(state, { ids: contactsQuery.result }),
    queryName: QUERY_NAME,
    currentWorkspace: getCurrentWorkspaceSelector(state),
    lang: getCurrentLanguageSelector(state),
  };
};

type QueryContactsArgs = {
  pagination?: Pagination,
  params?: {}
};

export const mapDispatchToProps = (dispatch: any) => ({
  queryContacts: ({ pagination, params }: QueryContactsArgs) => {
    dispatch(contacts.queryContacts({
      name: QUERY_NAME,
      pagination: {
        ...pagination,
        limit: 50,
      },
      params,
    }));
  },
  queryContactsReload: () => {
    dispatch(contacts.queryContactsReload({
      name: QUERY_NAME,
    }));
  },
  editLinkPath: (url: string, contactId: number) => {
    const realUrl = urlJoin(url, String(contactId));
    const editLink = `${realUrl}/details/?edit=true`;

    dispatch(push(editLink));
  },
});

export default connect<Contacts, State, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Contacts);
