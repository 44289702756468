import { PreviousButtonWithArrow } from 'components/buttons/previous-with-arrow';

import style from './cancellation-navigation.module.scss';

type Props = {
  onPreviousStep: () => void;
};

const CancellationNavigation = ({ onPreviousStep }: Props) => (
  <div className={style.CancellationNavigation}>
    <PreviousButtonWithArrow onClick={() => onPreviousStep()} />
  </div>
);

export default CancellationNavigation;
