import { useCallback, useEffect, useState } from 'react';
import { Message } from '@oneflowab/pomes';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import type { MessageTranslator } from '@oneflowab/pomes';

import adminPage from 'hocs/admin-page';
import groupsReducer from 'reducers/entities/groups';
import usePrevious from 'hooks/use-previous';

import { Members } from 'routes/admin/group/columns/members';
import { Workspaces } from 'routes/admin/group/columns/workspaces';
import { Roles } from 'routes/admin/group/columns/roles';

// eslint-disable-next-line import/named
import { hasAnyGroup } from 'oneflow-client/groups';

import { checkAcl } from 'components/acl';
import * as EditableForm from 'components/editable-form';
import TextField from 'components/text-field';
import { DetailsFooter } from 'components/details-footer';
import { LocalizedDateTime } from 'components/localized-date-time';
import Field from 'components/field';

import style from './group-details.module.scss';

type Props = {
  location: Location,
  match: {
    params: {
      id: Oneflow.Group['id'],
    },
  },
  message: MessageTranslator,
};

export type FormData = {
  name: string,
};

export const GroupDetails = ({
  location,
  match,
  message,
}: Props) => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);

  const group = useSelector(
    (state) => groupsReducer.getGroupSelector(state, { id: match.params.id }),
  );
  const updateState = useSelector(
    (state) => groupsReducer.getUpdateSelector(state, { id: match.params.id }),
  );
  const prevUpdateState = usePrevious(updateState);

  const checkEditPermission = useCallback(
    () => checkAcl(group.acl, 'group:update:name'),
    [group.acl],
  );

  const hideForm = () => {
    setIsEditMode(false);
  };

  const resetUpdateState = () => {
    dispatch(groupsReducer.updateGroupReset({
      id: match.params.id,
    }));
  };
  const updateGroup = (groupData: FormData) => {
    dispatch(groupsReducer.updateGroup({
      id: match.params.id,
      data: groupData,
      pipe: {
        onSuccess: () => {
          hideForm();
        },
      },
    }));
  };
  const updatePath = useCallback((pathname: string) => {
    dispatch(replace(pathname));
  }, [dispatch]);

  const enableEditModeIfRedirected = useCallback(() => {
    if (!isEditMode && location?.query.edit && checkEditPermission()) {
      updatePath(location.pathname);
      setIsEditMode(true);
    }
  }, [isEditMode, location?.query.edit, checkEditPermission, updatePath, location?.pathname]);

  useEffect(() => {
    enableEditModeIfRedirected();

    if (
      updateState.success && !prevUpdateState?.success
      && isEditMode
    ) {
      hideForm();
    }
  }, [isEditMode, updateState.success, enableEditModeIfRedirected, prevUpdateState]);

  const getInitialValues = () => {
    const { name } = group;
    return { name };
  };

  const uniqueGroupName = (
    { params }: { params: any },
  ) => hasAnyGroup({ params, entityId: group.id });

  if (!group || !group.acl) {
    return null;
  }

  const infoPairs = [
    {
      label: <Message
        id="Group ID"
        comment="ID label for the details footer."
      />,
      value: group.id,
    },
    {
      label: <Message
        id="Creation date"
        comment="Creation date label for the details footer"
      />,
      value: <LocalizedDateTime datetime={group.createdTime} />,
    },
    {
      label: <Message
        id="Members"
        comment="Group member count for details footer"
      />,
      value: <Members memberCount={group.memberCount} />,
    },
    {
      label: <Message
        id="Workspace access"
        comment="Workspace access count for details footer"
      />,
      value: <Workspaces collectionAccessStats={group.collectionAccessStats} />,
    },
    {
      label: <Message
        id="Account access"
        comment="Account access count for details footer"
      />,
      value: <Roles accountAccessStats={group.accountAccessStats} />,
    },
  ];

  return (
    <>
      <Form
        initialValues={getInitialValues()}
        onSubmit={updateGroup}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <EditableForm.Header>
              <EditableForm.Actions
                resetFormState={resetUpdateState}
                formState={updateState}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                disabled={!checkEditPermission()}
              />
            </EditableForm.Header>
            <EditableForm.Body>
              <h2 className={style.Header}>
                <Message
                  id="Group details"
                  comment="Used as the title of the Group Details section on the group details page"
                />
              </h2>
              <div className={style.Row}>
                <EditableForm.Label>
                  <Message
                    id="Name"
                    comment="Label for group name on the group details page."
                  />
                  <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                </EditableForm.Label>
                <Field
                  id="name"
                  name="name"
                  component={TextField}
                  value={group.name}
                  label={message({
                    id: 'Name',
                    comment: 'Label for group name on the group details page.',
                  })}
                  hideLabel
                  placeholder={message({
                    id: 'Enter name',
                    comment: 'Placeholder for group name on the group details page.',
                  })}
                  unique={{
                    text: message({
                      id: 'A group with this name already exists. Try another one.',
                      comment: 'Unqiueness error message on the group details page',
                    }),
                    param: 'name',
                    request: uniqueGroupName,
                    initialValue: group.name,
                  }}
                  maxLength={50}
                  required
                  autoFocus
                  disabled={!isEditMode}
                />
              </div>
            </EditableForm.Body>
          </form>
        )}
      />
      <DetailsFooter infoPairs={infoPairs} />
    </>
  );
};

export default adminPage(() => ({
  title: <Message id="Details" comment="Page title for group details page." />,
  modules: [[]],
}))(GroupDetails);
