// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import { getEndOfToday, DATE_FORMAT } from 'date';

import Button from 'components/button';
import { DatePicker } from 'components/date-picker';

import style from './paxml-export-area.module.scss';

type Props = {
  dateFormat: string,
};

type State = {
  exportFrom?: moment$Moment,
  exportTo?: moment$Moment,
  dateRangeFocus?: 'startDate' | 'endDate',
};

type HandleDateRangePickerChange = ({
  startDate?: moment$Moment,
  endDate?: moment$Moment,
}) => void;

export class PaxmlExportArea extends React.Component<Props, State> {
  state = {
    exportFrom: getEndOfToday(),
    exportTo: getEndOfToday(),
    dateRangeFocus: undefined,
  }

  handleDateRangePickerFocus = (dateRangeFocus: 'startDate' | 'endDate') => {
    this.setState({
      dateRangeFocus,
    });
  };

  handleDateRangePickerChange: HandleDateRangePickerChange = ({ startDate, endDate }) => {
    this.setState({
      exportFrom: startDate,
      exportTo: endDate,
    });
  };

  getExportLink = () => {
    const {
      exportFrom,
      exportTo,
    } = this.state;

    if (!exportFrom || !exportTo) {
      return '';
    }

    const fromDate = exportFrom.format(DATE_FORMAT);
    const toDate = exportTo.format(DATE_FORMAT);

    return `/api/ext/paxml/export?from=${fromDate}&to=${toDate}`;
  };

  render() {
    const { dateFormat } = this.props;
    const {
      exportFrom,
      exportTo,
      dateRangeFocus,
    } = this.state;

    return (
      <div className={style.ExportArea}>
        <DatePicker
          onDatesChange={this.handleDateRangePickerChange}
          onFocusChange={this.handleDateRangePickerFocus}
          focusedInput={dateRangeFocus}
          startDate={exportFrom}
          endDate={exportTo}
          openDirection="up"
          showClearDates
          dateFormat={dateFormat}
        />
        <Button
          kind="primary"
          customClass={style.ExportButton}
          disabled={!exportFrom || !exportTo}
          href={this.getExportLink()}
          external
        >
          <Message
            id="Export contracts"
            comment="Button text for exporting contracts in Paxml extension."
          />
        </Button>
      </div>
    );
  }
}
