import React from 'react';
import Message from 'components/message';

export function getTemplateExpiryOptions() {
  return [
    {
      label: <Message id="No expiration date" comment="One of the options expiration type" />,
      value: 'no_expiry',
    },
    {
      label: <Message id="Days after sending" comment="One of the options expiration type" />,
      value: 'days',
    },
  ];
}

export function getExpiryTypeOptions({ updateAllowedProperties, isTemplateDocument }) {
  if (isTemplateDocument) {
    return getTemplateExpiryOptions();
  }

  const expiryOptions = [
    {
      label: <Message id="No expiration date" comment="One of the options expiration type" />,
      value: 'no_expiry',
      isDisabled: !updateAllowedProperties.expireDateDays || !updateAllowedProperties.expireDate,
    },
    {
      label: <Message id="Days after sending" comment="One of the options expiration type" />,
      value: 'days',
      isDisabled: !updateAllowedProperties.expireDateDays,
    },
    {
      label: <Message id="Fixed date" comment="One of the options expiration type" />,
      value: 'fixed',
      isDisabled: !updateAllowedProperties.expireDate,
    },
  ];

  // Using sort to keep the reference of the array and keeping the enabled
  // Options top
  return expiryOptions.sort((firstOption, secondOption) => {
    if (firstOption.isDisabled < secondOption.isDisabled) {
      return -1;
    }

    if (firstOption.isDisabled > secondOption.isDisabled) {
      return 1;
    }

    return 0;
  });
}

export function getSelectedExpiryTypeOption(params) {
  const { expireDate, expireDateDays, expiryOptions } = params;

  if (expireDate) {
    return expiryOptions.find((expiryOption) => expiryOption.value === 'fixed');
  }

  if (expireDateDays) {
    return expiryOptions.find((expiryOption) => expiryOption.value === 'days');
  }

  return expiryOptions.find((expiryOption) => expiryOption.value === 'no_expiry');
}
