// @flow

import * as React from 'react';
import { useSlate } from 'slate-react';
import { Message } from '@oneflowab/pomes';

import AddTable from 'components/icons/add-table';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

import { getActiveTable } from 'components/rich-text-editor/editor-plugins/table-utils/table-utils';
import { addTable } from './add-table-plugin';
import ToolbarButton from '../toolbar-button';

type Props = {
  isMenuItem: boolean,
  disabled: boolean,
};

const AddTableButton = ({ isMenuItem, disabled }: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();
  const { selection } = editor;
  const activeTable = getActiveTable(editor, selection);

  return (
    <ToolbarButton
      label={(
        <Message
          id="Insert table"
          comment="The label of the editor's toolbar plugin that inserts a table."
        />
      )}
      isMenuItem={isMenuItem}
      disabled={disabled || activeTable}
      onTrigger={() => {
        focusEditor();
        addTable(editor);
      }}
      icon={<AddTable />}
    />
  );
};

export default AddTableButton;
