import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import log from 'logging';
import { useDispatch, useSelector } from 'react-redux';
import { useSlate } from 'slate-react';
import { get, head } from 'lodash';
import { Message } from '@oneflowab/pomes';

import {
  isCommentsEnabled,
  isSuggestionsEnabled,
} from 'comments';
import ReactDOM from 'react-dom';

import { useDrawerStore } from 'store/zustand-store/use-drawer-store';
import { isDraft } from 'agreement';
// eslint-disable-next-line import/named
import { useRichTextProps } from 'contexts/rich-text';
// eslint-disable-next-line import/named
import { useContractProps } from 'contexts/contract-props';
import { useAnnotationProps } from 'contexts/annotation-props';

import useAgreement from 'hooks/use-agreement';
import useCurrentBox from 'hooks/use-current-box';
import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';
import useResetFocusedEditor from 'hooks/rich-text-editor/use-reset-focused-editor';

import {
  isContractBoxPristine,
  onSaveComment,
  onSaveSuggestion,
} from 'reducers/current-contract';
import { setFocusedEditor } from 'reducers/editor';

import {
  SAVE_ANNOTATION_IN_TEXT_BOX_ERROR_TITLE,
  SAVE_COMMENT_IN_TEXT_BOX_ERROR,
  SAVE_SUGGESTION_IN_TEXT_BOX_ERROR,
  contractVersionNotUpToDateToast,
} from 'comments/constants';

import { isSmallScreenWidth } from 'ui/config';

import {
  focusNodeAfterAnnotation,
} from 'components/contract-text-editor/editor-plugins/annotation-plugin';
import { Drawer } from 'components/drawer';
import toast from 'components/toasts';
import useShouldRenderAnnotationToolbar from 'components/rich-text-editor-toolbars/toolbar-buttons/post-annotation/annotation-toolbar/use-should-render-annotation-toolbar';
import { useCollapsedDocumentLayout } from 'components/document-layout-container/collapsed-document-layout/context';
import PostMessage from 'components/document-tabs/messages-tab/post-message';
import PostSuggestion from 'components/rich-text-editor-toolbars/toolbar-buttons/post-annotation/post-suggestion';
import SaveChangesModal from 'components/rich-text-editor-toolbars/toolbar-buttons/post-annotation/save-changes-modal/save-changes-modal';
import { getErrorCode } from 'components/api-error';

import AnnotationToolbar from '../annotation-toolbar';
import style from './mobile-annotation-interaction-container.module.scss';

type Props = {
  contractId: number,
  readOnly: boolean,
};

type UIStateType = 'noneActive' | 'postComment' | 'postSuggestion' | 'saveChangesModal';

type SuggestionType = null | 'change' | 'delete';

export const MobileAnnotationInteractionContainer = ({
  contractId,
  readOnly,
}: Props) => {
  const agreement = useAgreement(contractId);
  const { boxId } = useContractProps();
  const box = useCurrentBox(boxId);
  const data = get(box, 'content.data');
  const dataId = head(data)?.id;
  const dispatch = useDispatch();
  const editor = useSlate();
  const { selectedRectangle, resetEditorSelectedRangeInfo } = useRichTextProps();
  const { newAnnotationIdRef } = useAnnotationProps();
  const isBoxPristine = useSelector((state) => isContractBoxPristine(state, boxId));
  const {
    bottomFloatingContainerRef,
    setIsRecipientSelectorOpen,
  } = useCollapsedDocumentLayout();
  const isSelectionActive = useMemo(() => Boolean(selectedRectangle), [selectedRectangle]);
  const inlineCommentsEnabled = isCommentsEnabled(agreement);
  const suggestionsEnabled = isSuggestionsEnabled(agreement);
  const messageIsPrivate = isDraft(agreement);
  const focusEditor = useFocusEditor();
  const resetFocusedEditor = useResetFocusedEditor();
  const isSmallScreen = isSmallScreenWidth();

  const { editorSelection } = useRichTextProps();

  const [UIState, setUIState] = useState<UIStateType>('noneActive');
  const [isSuggestion, setIsSuggestion] = useState<boolean>(false);
  const [suggestionType, setSuggestionType] = useState<SuggestionType>(null);

  const openDrawer = useDrawerStore((state) => state.openDrawer);
  const closeDrawer = useDrawerStore((state) => state.closeDrawer);

  const shouldRenderAnnotationsToolbar = useShouldRenderAnnotationToolbar(contractId);

  useEffect(() => {
    const bottomFloatingContainer = bottomFloatingContainerRef.current;

    if (bottomFloatingContainer) {
      bottomFloatingContainer.classList.toggle('rich-text-region', isSelectionActive);
    }
  }, [bottomFloatingContainerRef, isSelectionActive]);

  const resetCommentStates = useCallback(() => {
    resetEditorSelectedRangeInfo();
    setSuggestionType(null);
    setUIState('noneActive');
  }, [resetEditorSelectedRangeInfo]);

  const openSaveChangesModal = useCallback(() => {
    setUIState('saveChangesModal');
    /*
      we don't want focus to be on the editor when the modal is open
      (this wont make selection null, slate still keeps it)
    */
    window.getSelection()?.removeAllRanges();
  }, []);

  const onSuccess = useCallback((commentId: number) => {
    resetCommentStates();

    newAnnotationIdRef.current = commentId;
    focusNodeAfterAnnotation(editor, commentId, isSuggestion);

    closeDrawer(`add-annotation-${box?.id}`);

    setTimeout(() => {
      newAnnotationIdRef.current = null;
    }, 200);
  }, [closeDrawer, editor, isSuggestion, newAnnotationIdRef, resetCommentStates, box?.id]);

  const onFailure = useCallback((error: any) => {
    resetCommentStates();
    closeDrawer(`add-annotation-${box?.id}`);

    const errorCodeMessage = getErrorCode(error);
    if (!isSuggestion) {
      log.error(error, 'Comments: Attaching comment to text failed');
      if (errorCodeMessage === 'DATA_NODES_CONFLICT') {
        contractVersionNotUpToDateToast();
        return;
      }

      toast.error({
        id: 'post-comment',
        title: SAVE_ANNOTATION_IN_TEXT_BOX_ERROR_TITLE,
        description: SAVE_COMMENT_IN_TEXT_BOX_ERROR,
      });
    } else {
      log.error(error, 'Suggestions: Adding suggestion failed');
      if (errorCodeMessage === 'DATA_NODES_CONFLICT') {
        contractVersionNotUpToDateToast();
        return;
      }

      toast.error({
        id: 'post-suggestion',
        title: SAVE_ANNOTATION_IN_TEXT_BOX_ERROR_TITLE,
        description: SAVE_SUGGESTION_IN_TEXT_BOX_ERROR,
      });
    }
  }, [isSuggestion, resetCommentStates]);

  const addSuggestion = useCallback((suggestedText: string) => {
    if (readOnly) {
      dispatch(setFocusedEditor(null));
    }

    dispatch(onSaveSuggestion({
      boxId,
      dataId,
      editor,
      editorSelection,
      suggestedText,
      readOnly,
      onSuccess,
      onFailure,
    }));
  }, [
    dispatch,
    boxId,
    dataId,
    editor,
    editorSelection,
    readOnly,
    onSuccess,
    onFailure,
  ]);

  const onPostComment = useCallback((message: any) => {
    if (readOnly) {
      dispatch(setFocusedEditor(null));
    }

    dispatch(onSaveComment({
      contractId,
      editorSelection,
      editor,
      boxId,
      dataId,
      message,
      onSuccess,
      onFailure,
    }));
  }, [
    boxId,
    dataId,
    editor,
    dispatch,
    contractId,
    onSuccess,
    onFailure,
    editorSelection,
    readOnly,
  ]);

  const onCommentButtonMouseDown = useCallback((event: MouseEvent) => {
    if (isSmallScreen) {
      resetFocusedEditor();
    } else if (readOnly) {
      dispatch(setFocusedEditor(editor));
    }

    setIsSuggestion(false);
    event.preventDefault();
    event.stopPropagation();

    if (isBoxPristine) {
      setUIState('postComment');
      openDrawer(`add-annotation-${box?.id}`);
    } else {
      openSaveChangesModal();
    }
  }, [
    box?.id,
    dispatch,
    editor,
    isBoxPristine,
    openDrawer,
    openSaveChangesModal,
    readOnly,
    resetFocusedEditor,
    isSmallScreen,
  ]);

  const onSaveSuggestionChanges = useCallback(() => {
    if (suggestionType === 'delete') {
      addSuggestion('');
      setUIState('noneActive');
      focusEditor();

      return;
    }

    setUIState('postSuggestion');
    openDrawer(`add-annotation-${box?.id}`);
  }, [addSuggestion, box?.id, focusEditor, openDrawer, suggestionType]);

  const onSaveCommentChanges = useCallback(() => {
    setUIState('postComment');
    openDrawer(`add-annotation-${box?.id}`);
  }, [box?.id, openDrawer]);

  const onSuggestChange = useCallback((event: MouseEvent) => {
    setIsSuggestion(true);
    event.preventDefault();
    event.stopPropagation();

    if (isSmallScreen) {
      resetFocusedEditor();
    } else if (readOnly) {
      dispatch(setFocusedEditor(editor));
    }

    setSuggestionType('change');

    if (isBoxPristine) {
      setUIState('postSuggestion');
      openDrawer(`add-annotation-${box?.id}`);
    } else {
      openSaveChangesModal();
    }
  }, [
    box?.id,
    dispatch,
    editor,
    isBoxPristine,
    openDrawer,
    openSaveChangesModal,
    readOnly,
    resetFocusedEditor,
    isSmallScreen,
  ]);

  const onSuggestDeletion = useCallback((e: MouseEvent) => {
    setIsSuggestion(true);
    e.preventDefault();
    e.stopPropagation();

    setSuggestionType('delete');

    if (isBoxPristine) {
      addSuggestion('');
    } else {
      openSaveChangesModal();
    }
  }, [addSuggestion, isBoxPristine, openSaveChangesModal]);

  const renderAnnotationToolbar = useCallback(() => {
    if (!isSelectionActive || UIState !== 'noneActive') {
      return null;
    }

    return (shouldRenderAnnotationsToolbar && ReactDOM.createPortal(
      <div className={style.AnnotationToolbar}>
        <AnnotationToolbar
          agreementId={agreement.id}
          commentsEnabled={inlineCommentsEnabled}
          onCommentMouseDown={onCommentButtonMouseDown}
          onSuggestChange={onSuggestChange}
          onSuggestDeletion={onSuggestDeletion}
          state={agreement.state as number}
          suggestionsEnabled={suggestionsEnabled}
        />
      </div>,
      bottomFloatingContainerRef.current,
    ));
  }, [
    UIState,
    agreement.id,
    agreement.state,
    inlineCommentsEnabled,
    isSelectionActive,
    onCommentButtonMouseDown,
    onSuggestChange,
    onSuggestDeletion,
    shouldRenderAnnotationsToolbar,
    suggestionsEnabled,
    bottomFloatingContainerRef,
  ]);

  return (
    <>
      {renderAnnotationToolbar()}
      <Drawer
        id={`add-annotation-${box?.id}`}
        isOverlayEnabled
        isDragHandleVisible
        onClose={() => {
          setIsRecipientSelectorOpen(false);
          setUIState('noneActive');
        }}
        renderTitle={() => (
          <>
            {isSuggestion && (
            <Message
              id="Suggestion"
              comment="Text to show this message is a suggestion"
            />
            )}
            {!isSuggestion && (
            <Message
              id="Comment"
              comment="Text to show this message is an inline comment"
            />
            )}
          </>
        )}
      >
        {UIState === 'postComment' && (
        <PostMessage
          onPostMessage={onPostComment}
          agreementId={contractId}
          isComment
          autoFocus
        />
        )}
        {UIState === 'postSuggestion' && (
          <PostSuggestion
            addSuggestion={addSuggestion}
            isPrivate={messageIsPrivate}
          />
        )}
      </Drawer>
      <SaveChangesModal
        onSaveChanges={isSuggestion ? onSaveSuggestionChanges : onSaveCommentChanges}
        isOpen={UIState === 'saveChangesModal'}
        onCancel={() => setUIState('noneActive')}
        isSuggestion={isSuggestion}
        shouldShowSaveSuccessToast={false}
      />
    </>
  );
};
