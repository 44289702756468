import { useSelector } from 'react-redux';
import { getCurrentBoxes } from 'reducers/current-contract';

const useCurrentBoxes = (): ContractView.Boxes => {
  // TODO: remove type assertion when current-contract reducer is converted to TypeScript
  const boxes = useSelector(getCurrentBoxes) as ContractView.Boxes;

  if (!boxes) {
    return {};
  }

  return boxes;
};

export default useCurrentBoxes;
