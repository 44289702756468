// @flow

import * as React from 'react';
import { checkAcl } from 'components/acl';
import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';

import { getAccountFromSessionSelector } from 'reducers/session';

import useCurrentContractId from 'hooks/use-current-contract-id';
import useAgreement from 'hooks/use-agreement';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

import AIAssistModal from 'components/modals/ai-assist';
import Button from 'components/button';
import AiSparkle from 'components/icons/ai-sparkle';

import styles from './ai-assist-field-button.module.scss';

type Props = {
  onInsertGeneratedText: any,
  context: string,
  disabled?: boolean,
};

const AIAssistFieldButton = ({ onInsertGeneratedText, context, disabled }: Props) => {
  const contractId = useCurrentContractId();
  const agreement = useAgreement(contractId);
  const account = useSelector((state) => getAccountFromSessionSelector(state));
  const hasAgreementAIAssistPermission = checkAcl(agreement?.acl, 'agreement:ai_assist');
  const hasAccountAIAssistPermission = checkAcl(account?.acl, 'account:ai_assist');
  const [modalOpen, setModalOpen] = React.useState(false);

  if (agreement && !hasAgreementAIAssistPermission) {
    return null;
  }

  if (account && !hasAccountAIAssistPermission) {
    return null;
  }

  const openModal = () => {
    if (context === 'agreementPublish') {
      amplitudeLogEvent(
        'Go To AI Assist',
        { location: 'send contract modal' },
        { 'document id': contractId },
      );
    }

    setModalOpen(true);
  };

  return (
    <AIAssistModal
      isOpen={modalOpen}
      setModalOpen={setModalOpen}
      onInsertGeneratedText={onInsertGeneratedText}
      insertIntoAField
      context={context}
    >
      {() => (
        <div className={styles.AIAssistWrapper}>
          <Button
            customClass={styles.AIAssistButton}
            icon={AiSparkle}
            onClick={openModal}
            disabled={disabled}
          >
            <Message
              id="AI Assist"
              comment="The label of the editor's toolbar plugin that uses AI Assist."
            />
          </Button>
        </div>
      )}
    </AIAssistModal>
  );
};

export default AIAssistFieldButton;
