import { useState } from 'react';
import { Message, MessageTranslator, localize } from '@oneflowab/pomes';
import { useSlate } from 'slate-react';
import { Form } from 'react-final-form';

import useFocusEditor from 'hooks/rich-text-editor/use-focus-editor';

// eslint-disable-next-line import/named
import { urlValidator } from 'forms/validators';
import Button from 'components/button';
import Field from 'components/field';
import TextField from 'components/text-field';
import TextArea from 'components/text-area';
import LinkIcon from 'components/icons/link';
import EmailIcon from 'components/icons/email';
import {
  insertLink,
} from 'components/rich-text-editor-toolbars/toolbar-buttons/link-button/plugin';

import style from './link-edit-form.module.scss';

type Props = {
  message: MessageTranslator,
  onClose: () => void,
  elementUrl?: string,
  visible?: boolean,
  type?: 'url' | 'email',
  canChangeLinkType?: boolean,
};

const LinkEditForm: React.FC<Props> = ({
  message,
  elementUrl,
  visible = true,
  onClose,
  type,
  canChangeLinkType,
}: Props) => {
  const editor = useSlate();
  const focusEditor = useFocusEditor();
  const [linkType, setLinkType] = useState(type || 'url');
  const [emailUrl, query] = elementUrl?.includes('mailto:') ? elementUrl?.replace('mailto:', '').split('?') : [];
  const params = new URLSearchParams(query);

  if (!visible) {
    return null;
  }

  const validations = (() => ({
    url: urlValidator({
      message,
      field: message({
        id: 'URL',
        comment: 'Used in URL validation of links in the WYSIWYG editor.',
      }),
    }),
  }))();

  type FormData = {
    url: string,
    email: string,
    subject: string,
    body: string,
  };
  const onSubmit = (formData: FormData) => {
    const {
      url,
      email,
      subject,
      body,
    } = formData;

    let link = url;

    if (linkType === 'email') {
      const emailQuery = Object.entries({ subject, body })
        .reduce((acc: string[], [key, value]) => {
          if (value) {
            acc.push(`${key}=${encodeURIComponent(value)}`);
          }
          return acc;
        }, [])
        .join('&');

      link = emailQuery ? `mailto:${email}?${emailQuery}` : `mailto:${email}`;
    }

    focusEditor();
    insertLink(editor, link);

    setLinkType('url');
  };

  const onLinkTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLinkType(e.target?.value as 'url' | 'email');
  };

  const getMainInputField = ({ values }: { values: any }) => {
    if (linkType === 'email') {
      return (
        <Field
          name="email"
          placeholder={message({
            id: 'Email address',
            comment: 'Inside an text input. Placeholder for an email address.',
          })}
          autoFocus
          component={TextField}
          customClass={style.UrlInput}
          errorCustomClass={style.Error}
          email
        />
      );
    }
    return (
      <Field
        key="url"
        name="url"
        placeholder={message({
          id: 'Add a link',
          comment: 'Inside an text input. Placeholder for a url.',
        })}
        autoFocus
        component={TextField}
        validate={values.url ? validations.url : undefined}
        customClass={style.UrlInput}
        errorCustomClass={style.Error}
      />
    );
  };

  const linkTypeOptions = [
    {
      label: message({ id: 'URL', comment: 'One of the link type options' }),
      value: 'url',
    },
    {
      label: message({ id: 'Email', comment: 'One of the link type options' }),
      value: 'email',
    },
  ];

  const emailFields = linkType === 'email' ? (
    <div className={style.FormEmailRow}>
      <Field
        name="subject"
        label={message({
          id: 'Subject',
          comment: 'Label for the subject field for a email mailto link.',
        })}
        placeholder={message({
          id: 'Enter subject',
          comment: 'Name placeholder for a link.',
        })}
        component={TextField}
        noErrorMessage
      />
      <Field
        name="body"
        label={message({
          id: 'Message',
          comment: 'Label for the body field for a email mailto link.',
        })}
        placeholder={message({
          id: 'Enter message',
          comment: 'Placeholder for the subject field for a email mailto link.',
        })}
        component={TextArea}
      />
    </div>
  ) : null;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        url: (elementUrl && !elementUrl.includes('mailto:')) ? elementUrl : '',
        email: emailUrl || '',
        subject: params.get('subject') || '',
        body: params.get('body') || '',
      }}
      render={(formProps) => (
        <form
          onSubmit={formProps.handleSubmit}
          className={style.Form}
        >
          <div className={style.FormTopRow}>
            {canChangeLinkType ? (
              <select
                value={linkType}
                onChange={onLinkTypeChange}
                className={style.Select}
              >
                {linkTypeOptions.map(({ value, label }) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            ) : (
              <>{ linkType === 'url' ? <LinkIcon /> : <EmailIcon height="18px" />}</>
            )}
            {getMainInputField(formProps)}
          </div>
          {emailFields}
          <div className={style.ButtonsContainer}>
            <Button
              onClick={onClose}
            >
              <Message
                id="Cancel"
                comment="CTA showing in a button that closes a popover"
              />
            </Button>
            <Button
              type="submit"
              disabled={(
                formProps.invalid
                || !(formProps.values.email || formProps.values.url)
              )}
              onClick={formProps.handleSubmit}
              kind="primary"
              customClass={style.ApplyButton}
            >
              <Message
                id="Apply"
                comment="The label of the button that applies the changes made in the popover"
              />
            </Button>
          </div>
        </form>
      )}
    />
  );
};

// To overwrite incorrect pomes type hints
const LocalizedLinkEditForm = localize<Props>(
  LinkEditForm,
) as unknown as React.FunctionComponent<Omit<Props, 'message'>>;

export default LocalizedLinkEditForm;
