import { useDataFieldsTabProps } from 'contexts/data-fields-tab-props';

import DataFieldItem from 'components/document-tabs/data-fields-tab/data-field-item';
import DataFieldDragOverlay from 'components/document-tabs/data-fields-tab/data-field-drag-overlay';

import style from './data-fields-list.module.scss';

const DataFieldsList = () => {
  const {
    allDataFields,
    visibleDataFieldIds,
  } = useDataFieldsTabProps();
  const existingVisibleDataFields = visibleDataFieldIds
    .filter((visibleDataFieldId) => visibleDataFieldId in allDataFields);

  return (
    <div className={style.DataFieldsList}>
      {existingVisibleDataFields.map((id) => {
        const item = allDataFields[Number(id)];
        return (
          <DataFieldItem
            key={item?.id}
            dataFieldKey={item?.value?.key}
          />
        );
      })}
      <DataFieldDragOverlay />
    </div>
  );
};

export default DataFieldsList;
