// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import get from 'lodash/get';

import {
  isStateTemplate,
} from 'agreement';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const ContractRestoreEvent = ({ auditLogEvent }: Props) => {
  const contractState = get(auditLogEvent.information, 'state.to');
  const name = get(auditLogEvent.information, 'name.to');
  const contractId = auditLogEvent.target.id;
  const displayName = name ? `${name} (${contractId})` : contractId;

  const getStringVariables = () => ({
    displayName,
    workspace: auditLogEvent.secondaryTarget.name,
  });

  if (isStateTemplate(contractState)) {
    return (
      <Message
        id="The template {displayName} was {jsx-start}restored{jsx-end} to {workspace}"
        comment="Audit log event message for restored template"
        component="span"
        className={style.Red}
        values={getStringVariables()}
      />
    );
  }

  return (
    <Message
      id="The document {displayName} was {jsx-start}restored{jsx-end} to {workspace}"
      comment="Audit log event message for a restored document"
      component="span"
      className={style.Green}
      values={getStringVariables()}
    />
  );
};

export default ContractRestoreEvent;
