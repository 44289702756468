// @flow
import type { MessageTranslator } from '@oneflowab/pomes';
import * as participantConstants from 'agreement/participant/constants';

import type { Validator } from './index';

import swedishSsnValidator from './swedish-ssn';
import danishSsnValidator from './danish-ssn';
import norwegianSsnValidator from './norwegian-ssn';
import finnishSsnValidator from './finnish-ssn';

type SsnValidator = ({
  message: MessageTranslator,
  signMethod: any,
}) => Validator;

const getSsnValidator = (signMethod) => {
  if (signMethod === participantConstants.SIGN_METHOD_NO_BANKID_SIGN) {
    return norwegianSsnValidator;
  }

  if (signMethod === participantConstants.SIGN_METHOD_DK_MITID_NEMID_SIGN) {
    return danishSsnValidator;
  }

  if (signMethod === participantConstants.SIGN_METHOD_FI_FTN_SIGN) {
    return finnishSsnValidator;
  }

  return swedishSsnValidator;
};

const getMessage = (message, signMethod) => {
  if (signMethod === participantConstants.SIGN_METHOD_NO_BANKID_SIGN) {
    return message({
      id: 'Enter a valid Norwegian birth date. DDMMYY.',
      comment: 'Validation message for number of ssn validator.',
    });
  }

  if (signMethod === participantConstants.SIGN_METHOD_DK_MITID_NEMID_SIGN) {
    return message({
      id: 'Enter a valid Danish personal identification number (CPR). DDMMYYXXXX.',
      comment: 'Validation message for number of ssn validator.',
    });
  }

  if (signMethod === participantConstants.SIGN_METHOD_FI_FTN_SIGN) {
    return message({
      id: 'Enter a valid Finnish personal identity code. DDMMYY-XXXX or DDMMYYAXXXX.',
      comment: 'Validation message for number of ssn validator.',
    });
  }

  return message({
    id: 'Enter a valid Swedish personal number. YYYYMMDD-XXXX.',
    comment: 'Validation message for number of ssn validator.',
  });
};

const ssnValidator: SsnValidator = ({ message, signMethod }) => (value: string) => {
  const validator = getSsnValidator(signMethod);

  if (validator && validator.isValid(value)) {
    return undefined;
  }

  return getMessage(message, signMethod);
};

export default ssnValidator;
