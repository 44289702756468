import getFontAssetsDomain from './get-font-asset-domain.js';

const loadSacramento = () => {
  const normalSacramento = new FontFace(
    'Sacramento',
    `url(${getFontAssetsDomain()}/resources/fonts/sacramento/sacramento_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );

  return [normalSacramento];
};

export default loadSacramento;
