import { Message } from '@oneflowab/pomes';

import style from './cancel-subscription-simple.module.scss';

const CancelSubscriptionSimpleModal = () => (
  <div className={style.CancelSubscriptionContainer}>
    <p className={style.Strong}>
      <Message
        id="We are sad to see you leave"
        comment="Title for cancel subscription modal"
      />
    </p>
    <p>
      <Message
        id="Oneflow will be here if you change your mind and want to get back to seamless document creation. ✨"
        comment="Text in the cancel subscription modal"
      />
    </p>
  </div>
);

export default CancelSubscriptionSimpleModal;
