/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import clsx from 'clsx';

import useAgreement from 'hooks/use-agreement';
import { hasDatePassed } from 'date';
import { getStatus } from 'agreement/states';
import { isSignLaterEnabled } from 'agreement';

import Message from 'components/message';

import { getValidUntilDate } from '../helpers/transforms';

import style from './signing-period-section.module.scss';

function CounterParticipantSigningPeriodSection(props) {
  const { agreementId } = props;

  const agreement = useAgreement(agreementId);
  const overdue = getStatus(agreement) === 'overdue';
  const expireDate = agreement?.expireDate;
  const hasExpireDatePassed = overdue || hasDatePassed(expireDate);
  const expireDateDays = agreement?.config?.expireDateDays;
  const dateFormat = agreement?.config?.dateFormat;

  const validUntil = useMemo(
    () => getValidUntilDate({ expireDate, expireDateDays, dateFormat }),
    [dateFormat, expireDate, expireDateDays],
  );

  let expiryInfo = (
    <Message
      id="The document can be signed at any time"
      comment="Text explains that the signing period doesn't expire"
    />
  );

  if (validUntil) {
    if (isSignLaterEnabled(agreement)) {
      expiryInfo = (
        <Message
          id="The document will be expired {date}"
          values={{
            date: (
              <span className={clsx({ [style.DatePassed]: hasExpireDatePassed })}>
                {validUntil}
              </span>
            ),
          }}
          comment="Shows at what date the document will be expired"
        />
      );
    } else {
      expiryInfo = (
        <Message
          id="The document can be signed until {date}"
          values={{
            date: (
              <span className={clsx({ [style.DatePassed]: hasExpireDatePassed })}>
                {validUntil}
              </span>
            ),
          }}
          comment="Shows the number of days signing is possible"
        />
      );
    }
  }

  return (
    <div data-testid="counterParticipantExpiryInfo" className={style.ExpiryInfo}>
      {expiryInfo}
    </div>
  );
}

export default CounterParticipantSigningPeriodSection;
