import { cloneDeep, uniqueId } from 'lodash';
import type { OverlayFieldPermissions } from 'types/overlay';
import type { Box } from 'data-validators/entity-schemas/document-box';
import { OVERLAY_FIELD } from '../constants';

export type Size = {
  width: number;
  height: number;
};

export type OverlayFieldPosition = {
  x: number,
  y: number,
};

type OverlayFieldValue = {
  permissions: OverlayFieldPermissions;
  fillOpacity: number;
};

const defaultOverlayFieldValue: OverlayFieldValue = {
  permissions: {
    colleagueEdit: 'inherit',
    counterpartEdit: 'inherit',
  },
  fillOpacity: 0.0,
};

const generateOverlayField = (
  box: Box,
  valueDataFieldKey: string,
  size: Size,
  page: number,
  position: OverlayFieldPosition,
) => {
  const newDataItem = {
    key: OVERLAY_FIELD,
    value: {
      ...defaultOverlayFieldValue,
      valueDataFieldKey,
      page,
      position,
      size,
      formatting: {},
    },
    _id: Number(uniqueId()),
  };

  const updatedBox = cloneDeep(box);

  updatedBox.content = {
    ...updatedBox.content,
    data: [
      ...updatedBox.content.data,
      newDataItem,
    ],
  };

  return {
    updatedBox,
    newDataItem,
  };
};

export default generateOverlayField;
