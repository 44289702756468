// @flow

import * as React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';
import debounce from 'lodash/debounce';

import ModalForm from 'hocs/modal-form';

import Field from 'components/field';
import SelectField from 'components/select-field';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import CircularSpinner from 'components/icons/circular-spinner';

import style from './edit-workspace-role.module.scss';

const modalKey = 'edit user workspace role modal';
type Props = {
  roles: Array<Role>,
  role: Role,
  queryRoles: () => void,
  rolesQuery: Query,
  message: MessageTranslator,
  onSubmit: Role => void,
  formState: UpdateState,
  resetFormState: () => void,
  title: React.Node,
  children: () => React.Node,
  queryRolesLoadMore: (args: LoadMoreArgs) => void,
};

export class EditWorkspaceRoleComponent extends React.Component<Props> {
  getRoles = () => {
    const { queryRoles } = this.props;

    queryRoles({});
  }

  handleLoadMoreRoles = (additionalResults: number) => {
    const { queryRolesLoadMore } = this.props;

    queryRolesLoadMore({
      additionalResults,
    });
  }

  getInitialValue = () => {
    const { rolesQuery, roles, role } = this.props;
    if (!rolesQuery.loading && roles.length) {
      return role;
    }

    return undefined;
  }

  renderActions = ({ formProps, closeConfirmation }) => (
    <div className={style.ActionButtons}>
      <CancelButton
        onClick={() => closeConfirmation()}
        modalKey={modalKey}
      />
      <Button
        icon={this.props.formState.loading ? CircularSpinner : null}
        kind="primary"
        onClick={formProps.handleSubmit}
        disabled={(this.props.formState.hasValidationErrors || this.props.formState.loading)}
      >
        <Message id="Confirm" comment="Used to confirm changes in a modal" />
      </Button>
    </div>
  );

  handleInputChange = debounce((name) => {
    const { queryRoles } = this.props;
    queryRoles({ name });
  }, 500);

  render() {
    const {
      message,
      roles,
      rolesQuery,
      onSubmit,
      formState,
      resetFormState,
      title,
      children,
    } = this.props;

    return (
      <ModalForm
        title={title}
        body={(
          <div>
            <Field
              name="role"
              label={message({
                id: 'Role',
                comment: 'Label for role selection to be used for workspace access.',
              })}
              placeholder={(
                <Message
                  id="Select role"
                  comment="Placeholder for role selection to be used for workspace access."
                />
              )}
              component={SelectField}
              options={roles}
              valueKey="id"
              labelKey="name"
              backspaceRemoves
              isLoading={rolesQuery.loading}
              required
              initialValue={this.getInitialValue()}
              onInputChange={this.handleInputChange}
              loadMoreItems={this.handleLoadMoreRoles}
            />
          </div>
        )}
        actions={this.renderActions}
        onSubmit={onSubmit}
        resetFormState={resetFormState}
        formState={formState}
        onOpen={this.getRoles}
        modalKey={modalKey}
      >
        {children}
      </ModalForm>
    );
  }
}

export default localize<Props>(EditWorkspaceRoleComponent);
