// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';
import Toggle from 'components/toggle';
import Divider from 'components/divider';
import clsx from 'clsx';

import Info from 'components/icons/info';
import Tooltip from 'components/tooltip';
import ExclamationCircle from 'components/icons/exclamation-circle';
import style from './expandable-options-item.module.scss';

type Props = {
  title: string,
  description: string,
  isOn: boolean,
  onToggle: Function,
  disabled: boolean,
  disabledTooltipMessage: string,
  hideMenuItem: boolean,
  showDivider?: boolean,
  isAdditionalOptions?: boolean,
  additionalInformation?: boolean,
  value?: string,
};

export const ExpandableOptionsItem = ({
  title,
  description,
  isOn,
  onToggle,
  disabled,
  disabledTooltipMessage,
  hideMenuItem,
  showDivider,
  isAdditionalOptions,
  additionalInformation,
  value,
}: Props) => {
  const handleOnKeyDown = () => undefined;
  const handleToggle = (event) => {
    if (disabled || event.defaultPrevented) {
      return;
    }
    onToggle(!isOn);
    event.preventDefault();
  };

  const renderAdditionalInformation = () => {
    if (!isAdditionalOptions || !additionalInformation || value !== 'signOrder') {
      return null;
    }

    return (
      <div className={style.InfoBoxContainer}>
        <div className={style.InfoBoxContent}>
          <ExclamationCircle height="14px" width="14px" className={style.ExclamationCircle} />
          <div className={style.InfoBoxText}>
            <Message
              id="To disable signing order, remove approvers or reassign them to a different role."
              comment="Text explaining how to disable sign order"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderLine = () => {
    if (!showDivider) {
      return null;
    }
    return <Divider solid className={style.Divider} />;
  };

  const classNames = clsx(style.ExpandableOptionsItem, {
    [style.Disabled]: disabled,
    [style.Enabled]: !disabled,
    [style.Hidden]: hideMenuItem,
    [style.AdditionalOptions]: isAdditionalOptions,
  });

  return (
    <>
      <div
        className={classNames}
        onClick={handleToggle}
        role="button"
        tabIndex={0}
        onKeyDown={handleOnKeyDown}
      >
        <div className={style.ItemHeader}>
          <div className={style.TitleWihTooltip}>
            <span>{title}</span>
            {
            disabled
            && disabledTooltipMessage
            && (
            <Tooltip
              message={disabledTooltipMessage}
              zIndex="10003"
              side="top"
            >
              <Info width="12px" />
            </Tooltip>
            )
          }
          </div>
          <Toggle
            checked={isOn}
            disabled={disabled}
            isControlled
          />
        </div>
        <div className={style.ItemDescription}>{description}</div>
        {renderAdditionalInformation()}
      </div>
      {renderLine()}
    </>
  );
};
