// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './pagination-information.module.scss';

type Props = {
  start: number,
  end: number,
  total: number,
  itemsPerPage?: number,
  type?: string,
};

const PaginationInformation = ({
  itemsPerPage,
  total,
  start,
  end,
  type,
}: Props) => {
  if (type === 'contracts' && total === 0) {
    return (
      <div className={style.HiddenPaginationInformation} />
    );
  }

  if (itemsPerPage && itemsPerPage > total) {
    return (
      <div className={style.PaginationInformation}>
        <span>
          <Message
            id="Displaying {count} of {total} {type}"
            values={{
              count: end,
              total,
              type,
            }}
            comment="Pagination information for a list or table. 'type' is the type item/record in the list, (e.g.: users), should always be plural. 'start' and 'end' is the position of the first and last item that is currently shown in the list/table, where 'total' is the total number of items in the list/table."
          />
        </span>
      </div>
    );
  }

  return (
    <div className={style.PaginationInformation}>
      <span>
        <Message
          id="Displaying {start} - {end} of {total} {type}"
          values={{
            start,
            end,
            total,
            type,
          }}
          comment="Pagination information for a list or table. 'type' is the type item/record in the list, (e.g.: users), should always be plural. 'start' and 'end' is the position of the first and last item that is currently shown in the list/table, where 'total' is the total number of items in the list/table."
        />
      </span>
    </div>
  );
};

PaginationInformation.defaultProps = {
  type: 'items',
  itemsPerPage: undefined,
};

export default PaginationInformation;
