// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  addPositionsToGroup,
  getGroupPositions,
  removeGroupPositions,
} from 'oneflow-client/group-positions';
import type { NormalizedPositions } from 'oneflow-client/positions';

import apiWrapper from 'sagas/api-wrapper';

import groupPositions from 'reducers/entities/group-positions';
import positions from 'reducers/entities/positions';

type MapperArgs = {
  data: NormalizedPositions,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(positions.setPositions(data.entities.positions));
}

const mappers = {
  create: {
    request: addPositionsToGroup,
  },
  query: {
    mapper,
    request: getGroupPositions,
  },
  remove: {
    request: removeGroupPositions,
  },
};

const groupPositionsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: groupPositions,
  mappers,
});

export default groupPositionsSagas;
