import Tooltip from 'components/tooltip';
import { ButtonFC as Button } from 'components/button';
import Message from 'components/message';

import style from './unpublish.module.scss';

type Props = {
  disabled?: boolean,
  onClick?: () => void,
}

const Unpublish = ({ disabled = false, onClick }: Props) => (
  <Tooltip
    message={(
      <Message
        id="You need to save first."
        comment="Tooltip for the publish button."
      />
    )}
    theme="oneflow"
    side="top"
    sideOffset={7.5}
    messageClassName={style.TooltipMessage}
    hideContent={!disabled}
  >
    <Button
      kind="unpublish"
      disabled={disabled}
      customClass={style.UnpublishButton}
      onClick={onClick}
    >
      <Message
        id="Unpublish"
        comment="Button label for un-publishing a template."
      />
    </Button>
  </Tooltip>
);

export default Unpublish;
