// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import { get } from 'lodash';

import { RemoveConfirm } from '../remove-confirm';

import style from './delete-workspaces.module.scss';

export type Props = {
  removeState: RemoveState,
  resetRemoveState: () => void,
  deleteWorkspaces: () => void,
  children: Function,
};

export class DeleteWorkspaces extends React.PureComponent<Props> {
  handleConfirm = () => {
    const { removeState, deleteWorkspaces } = this.props;

    if (removeState.loading) {
      return;
    }

    deleteWorkspaces();
  }

  render() {
    const {
      removeState,
      children,
      resetRemoveState,
    } = this.props;

    const getCustomErrorMessage = () => {
      const apiErrorCode = get(removeState?.error, 'body.api_error_code');

      // Backend decided to return 102 (conflict) for the following case.
      // Since we don't return a conflict message for this case I haven't added it to constants.
      if (apiErrorCode !== 102) {
        return undefined;
      }

      return {
        bodyText: (
          <Message
            id="Can not delete workspace, make sure it’s empty and not your only workspace."
            comment="Error text when trying to delete workspaces"
          />
        ),
      };
    };

    return (
      <RemoveConfirm
        onConfirm={this.handleConfirm}
        confirmState={removeState}
        resetConfirmState={resetRemoveState}
        customErrorMessage={getCustomErrorMessage()}
        confirmMessage={(
          <>
            <div className={style.BodyParagraph}>
              <Message
                id="Are you sure you want to delete the selected workspaces?"
                comment="Modal text when deleting one or more workspaces."
              />
            </div>
            <div className={style.BodyParagraphRedTop}>
              <Message
                id="The delete action can't be undone."
                comment="Modal text explaning that the action is irreversible."
              />
            </div>
            <div className={style.BodyParagraphRedBottom}>
              <Message
                id="All address book contacts and message templates in these workspaces will also be deleted."
                comment="Modal text when deleting one or more workspaces."
              />
            </div>
          </>
      )}
        modalKey="delete workspaces in bulk modal"
      >
        {children}
      </RemoveConfirm>
    );
  }
}
