// @flow

import React, { useLayoutEffect, useState, useRef } from 'react';
import { useSlate } from 'slate-react';
import clsx from 'clsx';
import { useResizeDetector } from 'react-resize-detector';
import { Message } from '@oneflowab/pomes';

import DropdownMenu from 'components/dropdown-menu';
import { useContractProps } from 'contexts/contract-props';

import RichTextEditorToolbarDivider from 'components/rich-text-editor-toolbar-divider';
import { checkAcl } from 'components/acl';
import useShouldRenderAIAssistToolbar from 'components/rich-text-editor-toolbars/inline-toolbar/ai-assist-toolbar/use-should-render-ai-assist-toolbar';

import ChevronDownIcon from 'components/icons/chevron-down';
import { getToolbarPlugins } from './responsive-toolbar-plugins';
import style from './contract-text-editor-toolbar.module.scss';

type Props = {
  isPlaceholder?: boolean,
};

const ContractTextEditorToolbar = ({
  isPlaceholder,
}: Props) => {
  const [moreButtonWidth, setMoreButtonWidth] = useState();
  // refreshMode is debounce to avoid ResizeObserver loop limit exceeded
  // refreshRate is 16 [1000ms/60] to make the responsive toolbar smoother
  const { width, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 16,
  });
  const { disabledPlugins, agreement, boxType } = useContractProps();

  const isAiAssistV2Enabled = useShouldRenderAIAssistToolbar(agreement.id);

  // TODO: remove the whole line when AI Assist v2 is fully enabled
  const isAIDisabled = !isAiAssistV2Enabled && boxType !== 'text-and-image';
  const isLinkDisabled = agreement?.availableOptions?.disableLinkPlugin;
  const hasAgreementAiAssistPermission = checkAcl(agreement.acl, 'agreement:ai_assist');
  const moreButtonRef = useRef(null);

  const editor = useSlate();
  const { menuPlugins, toolbarPlugins } = getToolbarPlugins(
    editor,
    width,
    disabledPlugins,
    isLinkDisabled,
    isAIDisabled,
    hasAgreementAiAssistPermission,
    moreButtonWidth,
  );

  useLayoutEffect(() => {
    if (!moreButtonRef.current) return;

    const dimensions = moreButtonRef.current.getBoundingClientRect();
    const margin = 16;

    setMoreButtonWidth(dimensions.width + margin);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreButtonRef.current]);

  const menuIcon = (
    <div ref={moreButtonRef} className={style.MenuDropdownIcon}>
      <Message
        id="More"
        comment="Toolbar selection for viewing more actions"
      />
      <ChevronDownIcon height="10px" />
    </div>
  );

  let responsiveMenu = null;
  if (menuPlugins.length) {
    responsiveMenu = (
      <>
        <RichTextEditorToolbarDivider />
        <DropdownMenu
          className={style.ResponsiveMenu}
          items={menuPlugins}
          anchor={{
            x: 'inner-right',
            y: 'below',
          }}
          dropdownIcon={menuIcon}
          focusOnCloseDisabled
          hideMenuOnExit
          menuClassName={style.ResponsiveMenuDropdown}
        />
      </>
    );
  }

  const className = clsx(
    'rich-text-region',
    'rich-text-toolbar',
    style.ContractTextEditorToolbar,
    {
      [style.Placeholder]: isPlaceholder,
      'rich-text-toolbar-placeholder': isPlaceholder,
    },
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div ref={ref} className={className} tabIndex={0}>
      {toolbarPlugins}
      {responsiveMenu}
    </div>
  );
};

ContractTextEditorToolbar.defaultProps = {
  isPlaceholder: undefined,
};

export default ContractTextEditorToolbar;
