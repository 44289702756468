/* eslint-disable react/prop-types */

import React from 'react';
import { Message } from '@oneflowab/pomes';

import Add from 'components/icons/add';
import Button from 'components/button';

import style from './add-field-button.module.scss';

const AddFieldButton = React.forwardRef((props, ref) => (
  <div className={style.Container}>
    <Button
      customClass={style.AddFieldButton}
      icon={<Add height="13px" />}
      kind="linkInline"
      onClick={props.onClick}
      ref={ref}
    >
      <Message id="Add field" comment="Button label for add field in form box." />
    </Button>
  </div>
));

export default AddFieldButton;
