// @flow

import * as React from 'react';

import { Popover, PopoverContent, PopoverPortal } from 'components/popover';
import PopoverTriggerButton from 'components/buttons/popover-trigger-button';
import Tag from './tag';

import style from './tag-popover.module.scss';

type Props = {
  tagConnections: Array<TagConnection>,
  allowTagChange: boolean,
  allowSearch?: boolean,
  searchGlobally?: boolean,
  renderAsNativeAnchorElement?: boolean,
  amplitudeScope?: string,
};

class TagPopover extends React.Component<Props, State> {
  static defaultProps = {
    allowSearch: true,
  }

  renderBody() {
    const {
      tagConnections,
      allowTagChange,
      allowSearch,
      searchGlobally,
      renderAsNativeAnchorElement,
      amplitudeScope,
    } = this.props;

    return (
      <div className={style.PopoverBody}>
        {tagConnections.map((tagConnection) => (
          <Tag
            searchGlobally={searchGlobally}
            allowTagChange={allowTagChange}
            allowSearch={allowSearch}
            key={tagConnection.id}
            tagConnection={tagConnection}
            amplitudeScope={amplitudeScope}
            renderAsNativeAnchorElement={renderAsNativeAnchorElement}
          />
        ))}
      </div>
    );
  }

  render() {
    const { tagConnections } = this.props;

    if (!tagConnections.length) {
      return null;
    }

    return (
      <Popover>
        <PopoverTriggerButton
          outline
          customClass={style.ExtraTags}
        >
          +
          {tagConnections.length}
        </PopoverTriggerButton>
        <PopoverPortal>
          <PopoverContent className={style.PopoverContent}>
            {this.renderBody()}
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    );
  }
}

export default TagPopover;
