import Svg from 'components/svg';

const Ua = Svg((
  <>
    <path d="M0 168h512v158.897a8.829 8.829 0 0 1-8.828 8.828H8.828A8.829 8.829 0 0 1 0 326.897V168z" fill="#FFE15A" />
    <path d="M512.001 168H0V9.103A8.829 8.829 0 0 1 8.828.275h494.345a8.829 8.829 0 0 1 8.828 8.828V168z" fill="#4173CD" />
  </>
), { viewBox: '0 0 512 336', fill: 'none' });

Ua.displayName = 'Ua';

export default Ua;
