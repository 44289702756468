/* eslint-disable import/prefer-default-export */

type OptionsType = {
  behavior: ScrollBehavior,
  block: ScrollLogicalPosition,
};

export const scrollToElement = (el: HTMLElement, { behavior = 'smooth', block = 'center' }: Partial<OptionsType> = {}) => {
  if (!el) {
    return;
  }

  el.scrollIntoView({
    behavior,
    block,
  });
};

export const scrollToBottom = (el: HTMLElement) => {
  if (!el) {
    return;
  }

  // eslint-disable-next-line no-param-reassign
  el.scrollTop = el.scrollHeight;
};
