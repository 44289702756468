// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

type Props = {
  auditLogEvent: AuditLogEvent,
};

const DataRetentionPolicyUpdateEvent = ({ auditLogEvent }: Props) => (
  <Message
    id="A data retention policy for workspace {workspace} was updated"
    comment="Audit log event message for updated workspace"
    values={{
      workspace: auditLogEvent.target.name,
    }}
  />
);

export default DataRetentionPolicyUpdateEvent;
