// @flow
import { combineReducers } from 'redux';

import accounts from 'reducers/entities/accounts';
import accountSeats from 'reducers/entities/account-seats';
import accountGroups from 'reducers/entities/account-groups';
import accountUsers from 'reducers/entities/account-users';
import agreementCreators from 'reducers/entities/agreement-creators';
import agreements from 'reducers/entities/agreements';
import agreementTemplates from 'reducers/entities/agreement-templates';
import agreementVideo from 'reducers/entities/agreement-video';
import apiTokens from 'reducers/entities/api-tokens';
import auditLogs from 'reducers/entities/audit-logs';
import billings from 'reducers/entities/billings';
import bindings from 'reducers/entities/bindings';
import bulkOperations from 'reducers/entities/bulk-operations';
import contacts from 'reducers/entities/contacts';
import dashboard from 'reducers/entities/dashboard';
import dataFields from 'reducers/entities/data-fields';
import extensions from 'reducers/entities/extensions';
import folders from 'reducers/entities/folders';
import groups from 'reducers/entities/groups';
import groupsAccounts from 'reducers/entities/group-accounts';
import groupPositions from 'reducers/entities/group-positions';
import groupWorkspaces from 'reducers/entities/group-workspaces';
import libraryTemplates from 'reducers/entities/library-templates';
import permissions from 'reducers/entities/permissions';
import plans from 'reducers/entities/plans';
import positionGroups from 'reducers/entities/position-groups';
import positionMessageTemplates from 'reducers/entities/position-message-templates';
import positionMfa from 'reducers/entities/position-mfa';
import positions from 'reducers/entities/positions';
import roles from 'reducers/entities/roles';
import statistics from 'reducers/entities/statistics';
import tagConnections from 'reducers/entities/tag-connections';
import tags from 'reducers/entities/tags';
import templateGroups from 'reducers/entities/template-groups';
import userAccounts from 'reducers/entities/user-accounts';
import userWorkspaces from 'reducers/entities/user-workspaces';
import webhooks from 'reducers/entities/webhooks';
import workspaceGroups from 'reducers/entities/workspace-groups';
import workspaceMessageTemplates from 'reducers/entities/workspace-message-templates';
import workspaces from 'reducers/entities/workspaces';
import workspaceUsers from 'reducers/entities/workspace-users';

export default combineReducers({
  accounts: accounts.reducer,
  accountSeats: accountSeats.reducer,
  accountGroups: accountGroups.reducer,
  accountUsers: accountUsers.reducer,
  agreementCreators: agreementCreators.reducer,
  agreements: agreements.reducer,
  agreementTemplates: agreementTemplates.reducer,
  agreementVideos: agreementVideo.reducer,
  apiTokens: apiTokens.reducer,
  auditLogs: auditLogs.reducer,
  billings: billings.reducer,
  bindings: bindings.reducer,
  bulkOperations: bulkOperations.reducer,
  contacts: contacts.reducer,
  dashboards: dashboard.reducer,
  dataFields: dataFields.reducer,
  extensions: extensions.reducer,
  folders: folders.reducer,
  groups: groups.reducer,
  groupAccounts: groupsAccounts.reducer,
  groupPositions: groupPositions.reducer,
  groupWorkspaces: groupWorkspaces.reducer,
  libraryTemplates: libraryTemplates.reducer,
  permissions: permissions.reducer,
  plans: plans.reducer,
  positionGroups: positionGroups.reducer,
  positionMessageTemplates: positionMessageTemplates.reducer,
  positionMfas: positionMfa.reducer,
  positions: positions.reducer,
  roles: roles.reducer,
  statistics: statistics.reducer,
  tagConnections: tagConnections.reducer,
  tags: tags.reducer,
  templateGroups: templateGroups.reducer,
  userAccounts: userAccounts.reducer,
  userWorkspaces: userWorkspaces.reducer,
  webhooks: webhooks.reducer,
  workspaceGroups: workspaceGroups.reducer,
  workspaceMessageTemplates: workspaceMessageTemplates.reducer,
  workspaces: workspaces.reducer,
  workspaceUsers: workspaceUsers.reducer,
});
