// @flow

import { type MessageTranslator } from '@oneflowab/pomes';
import { getRef, getEventOwner } from 'agreement/event';
import AuditTrailConstants from 'agreement/audit-trail-constants';
import { getParticipantName } from '../helpers';

// eslint-disable-next-line import/prefer-default-export
export const messageAdd = (
  event: AgreementEvent,
  agreement: Agreement,
  message: MessageTranslator,
) => {
  const type = getRef(event, 'message_type');
  const owner = getEventOwner(agreement, event);

  switch (type) {
    case AuditTrailConstants.TYPE_REMINDER:
      return {
        text: message({
          id: '{name} sent a reminder.',
          values: { name: getParticipantName(owner) },
          comment: 'Audit trail message',
        }),
        byLine: false,
      };

    case AuditTrailConstants.TYPE_CONTACT_ME:
      // Deprecated but there might still exist old events with this type
      return {
        text: message({
          id: '{name} asked to be contacted.',
          values: { name: getParticipantName(owner) },
          comment: 'Audit trail message',
        }),
        byLine: false,
      };
    default:
      return {
        text: message({
          id: '{name} added a comment.',
          values: { name: getParticipantName(owner) },
          comment: 'Audit trail message',
        }),
        byLine: false,
      };
  }
};
