// @flow

import { useCallback } from 'react';
import { Transforms, Editor, Range } from 'slate';
import {
  ReactEditor,
  useSlate,
  useFocused,
} from 'slate-react';
import last from 'lodash/last';

import { isSelectionEmpty } from 'components/rich-text-editor/utils';

const getEditorSelection = (editor, selection, options = {}) => {
  const { collapse = false } = options;

  if (collapse && !Range.isCollapsed(selection)) {
    return last(Editor.edges(editor, selection));
  }

  return selection;
};

const useFocusEditor = () => {
  const editor = useSlate();
  const focused = useFocused();
  const { selection } = editor;

  const focusEditor = useCallback((options) => {
    if (!focused && !isSelectionEmpty(selection)) {
      Transforms.select(editor, getEditorSelection(editor, selection, options));
      ReactEditor.focus(editor);
    }
  }, [editor, focused, selection]);

  return focusEditor;
};

export default useFocusEditor;
