import { Message } from '@oneflowab/pomes';

type Props = {
  signedDate: string,
  endDate: string
};

const SignedAndEnded = ({ signedDate, endDate }: Props) => (
  <span>
    <Message
      id="Document was signed on {signedDate} and ended on {endDate}."
      values={{
        signedDate,
        endDate,
      }}
      comment="Text for a signed and ended contract. Visible in contract."
    />
  </span>
);

export default SignedAndEnded;
