// @flow

import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { components } from 'react-select';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

import { getTranslationForDateFormat } from 'date';

import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { useUserEvents } from 'contexts/user-events';

import Field from 'components/field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import TextArea from 'components/text-area';
import { SingleDatePickerComponent } from 'components/single-date-picker';

import { useResetFormValuesRecipientsValidation } from './use-recipients-validation';
import ViewableContract from './viewable-contract';
// eslint-disable-next-line import/no-named-as-default
import RecipientsSelectField from './recipients-select-field';
import disableHoursBeforeCurrentHourOfToday from './disable-hours-before-current-hour-of-today';

import style from './internal-reminder-body.module.scss';

export type Props = {
  message: MessageTranslator,
  internalReminderEvent?: InternalReminderEvent,
  validatedRecipients: Array<Position>,
  sendValidationEvent: () => void,
  userConsent: 'waiting' | 'given' | 'reverted'
};

const TimeOption = ({ isDisabled, ...restProps }: any) => (
  <components.Option
    className={clsx({ [style.DisabledOption]: isDisabled })}
    {...restProps}
  />
);

function InternalReminderEditBody({
  message,
  internalReminderEvent,
  validatedRecipients,
  sendValidationEvent,
  userConsent,
}: Props) {
  const { change: formValueChange } = useForm();
  const { values } = useFormState();

  const {
    currentUser,
    dateFormat,
    timeValues,
  } = useUserEvents();

  const resetFormValuesRecipientsValidation = useResetFormValuesRecipientsValidation();

  React.useEffect(() => {
    if (!isEmpty(validatedRecipients)) {
      formValueChange('recipients', validatedRecipients);
    }
  }, [validatedRecipients, formValueChange]);

  return (
    <>
      <div className={clsx(style.Row, style.ContractSelection)}>
        <ViewableContract contract={values?.contractFieldOption?.contract} />
      </div>
      <fieldset className={style.FormFieldset}>
        <RecipientsSelectField
          contract={internalReminderEvent?.agreement}
          currentUser={currentUser}
          onChange={(newRecipients) => {
            if (userConsent === 'given') {
              resetFormValuesRecipientsValidation(newRecipients);
              sendValidationEvent({ type: 'validation/revert' });
            }
          }}
        />
        <div className={clsx(style.Row, style.DatePickerField)}>
          <Field
            label={message({
              id: 'Date',
              comment: 'Label for date-selection in custom reminder modal',
            })}
            name="date"
            component={SingleDatePickerComponent}
            initialVisibleMonth={null}
            placeholder={getTranslationForDateFormat({
              message,
              dateFormat,
            })}
            displayFormat={dateFormat}
            required
            customClassName={style.OverrideSingleDatePicker}
            block={false}
          />
          <Field
            label={message({
              id: 'Time',
              comment: 'Label for time-selection in custom reminder modal',
            })}
            name="timeFieldOption"
            labelKey="time"
            valueKey="time"
            placeholder={message({
              id: 'HH:MM',
              comment: 'Placeholder used in the time field in custom reminder modal',
            })}
            options={disableHoursBeforeCurrentHourOfToday(timeValues, values?.date)}
            isOptionDisabled={(option) => option.isDisabled}
            component={SelectField}
            components={{ Option: TimeOption }}
            required
          />
        </div>
        <div className={style.Row}>
          <Field
            label={message({
              id: 'Subject',
              comment: 'Label for subject-field in custom reminder modal',
            })}
            name="subject"
            placeholder={message({
              id: 'Enter subject',
              comment: 'Placeholder used in the subject field in custom reminder modal',
            })}
            component={TextField}
            maxLength={150}
            customClass={style.TextField}
            required
          />
        </div>
        <div className={style.Row}>
          <Field
            label={message({
              id: 'Message',
              comment: 'Label for message-field in custom reminder modal',
            })}
            name="message"
            placeholder={message({
              id: 'Enter message',
              comment: 'Placeholder used in the message field in custom reminder modal',
            })}
            component={TextArea}
            maxLength={4500}
            minRows={5}
            maxRows={10}
            customClass={style.TextArea}
            required
          />
        </div>
      </fieldset>
    </>
  );
}

export default localize<Props>(InternalReminderEditBody);
