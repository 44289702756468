import { Message } from '@oneflowab/pomes';

import type { AuditLogEvent } from 'types/entities';

type Props = {
  auditLogEvent: AuditLogEvent;
}

// Later when automation rules are more migrated / understood,
// we can rename "A data retention policy" to "An automation rule"
export const AutomationRuleUpdateEvent = ({ auditLogEvent }: Props) => (
  <Message
    id="A data retention policy for workspace {workspace} was updated"
    comment="Audit log event message for automation rules"
    values={{
      workspace: auditLogEvent.secondaryTarget?.name,
    }}
  />
);
