import { useMemo } from 'react';
import style from './file-name-split-with-extension.module.scss';

type Props = {
  children: string,
};

const FileNameSplitWithExtension = ({ children }: Props) => {
  const fileName = useMemo(() => children.substr(0, children.length - 10), [children]);
  const fileNameSuffix = useMemo(
    () => children.substr(children.length - 10, children.length),
    [children],
  );

  if (children.length <= 12) return <div>{children}</div>;

  return (
    <div className={style.FileNameSplitWithExtension}>
      <div className={style.FileName}>{fileName}</div>
      <div className={style.FileNameExtensionSuffix}>{fileNameSuffix}</div>
    </div>
  );
};

export default FileNameSplitWithExtension;
