export const UNSPECIFIED = 'LABEL_UNSPECIFIED';
export const FOUND = 'LABEL_FOUND';
export const MISSING = 'LABEL_MISSING';

export const COUNTRY_OF_SELLER = 'GOV_COUNTRY_OF_SELLER';

export const forceMajeureTypes = {
  PANDEMIC: 'FORCE_MAJEURE_PANDEMIC',
};

export const terminationTypes = {
  BREACH: 'TERMINATION_BREACH',
  CONVENIENCE: 'TERMINATION_CONVENIENCE',
  CONTROL: 'TERMINATION_CHANGE_OF_CONTROL',
  INSOLVENCY: 'TERMINATION_INSOLVENCY',
};

export const UNTIL_TERMINATED = 'LABEL_UNTIL_TERMINATED';

export const CONTRACT_NOT_ANALYSED = 'CONTRACT_NOT_ANALYSED';
export const CONTRACT_ANALYSED_NO_ISSUES = 'CONTRACT_ANALYSED_NO_ISSUES';
export const CONTRACT_ANALYSED_WITH_ISSUES = 'CONTRACT_ANALYSED_WITH_ISSUES';

export const NON_BREACH_COLOR = '#187bb7';
export const BREACH_COLOR = '#d13d47';
export const CONTRACT_NOT_ANALYSED_COLOR = '#99b0b7';
export const WARNING_COLOR = '#EF9B4E';

export const NON_BREACH_COLORS = [NON_BREACH_COLOR, '#509DD1', '#0f5987', '#8EC4E3'];
export const BREACH_COLORS = [BREACH_COLOR, '#ca2128', '#db686f', '#99221f'];
export const WARNING_COLORS = [WARNING_COLOR, '#db873a'];
