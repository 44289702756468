import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';

import { getLocationSelector } from 'reducers/router';
import type { RootState } from 'reducers';

import QueryStringParams from './query-string-params';

export const mapStateToProps = (state: RootState) => {
  const location = getLocationSelector(state);

  return {
    location,
  };
};

export const mapDispatchToProps = (dispatch: any) => ({
  updateSearch: (location: Location, search: string, navigate: boolean) => {
    const newLocation = `${location.pathname}?${search}`;

    if (navigate) {
      dispatch(push(newLocation));
    } else {
      dispatch(replace(newLocation));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QueryStringParams);
