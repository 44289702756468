// @flow

import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { components } from 'react-select';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

import { getTranslationForDateFormat } from 'date';

import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { useUserEvents } from 'contexts/user-events';

import Field from 'components/field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import TextArea from 'components/text-area';
import MiniContractCard from 'components/mini-contract-card';
import { SingleDatePickerComponent } from 'components/single-date-picker';
import AIAssistFieldButton from 'components/rich-text-editor-toolbars/toolbar-buttons/ai-assist-field-button';

import { useResetFormValuesRecipientsValidation } from './use-recipients-validation';
// eslint-disable-next-line import/no-named-as-default
import RecipientsSelectField from './recipients-select-field';
import disableHoursBeforeCurrentHourOfToday from './disable-hours-before-current-hour-of-today';

import style from './internal-reminder-body.module.scss';

export type Props = {
  message: MessageTranslator,
  selectedContract: Agreement,
  validatedRecipients: Array<Position>,
  sendValidationEvent: () => void,
  userConsent: 'waiting' | 'given' | 'reverted',
  visualTweak?: 'overrideLegacyStyling',
};

const TimeOption = ({ isDisabled, ...restProps }: any) => (
  <components.Option
    className={clsx({ [style.DisabledOption]: isDisabled })}
    {...restProps}
  />
);

function InternalReminderCreateSelectedContractBody({
  selectedContract,
  validatedRecipients,
  sendValidationEvent,
  userConsent,
  message,
  visualTweak,
}: Props) {
  const { change: formValueChange } = useForm();
  const { values } = useFormState();
  const {
    currentUser,
    dateFormat,
    timeValues,
  } = useUserEvents();
  const resetFormValuesRecipientsValidation = useResetFormValuesRecipientsValidation();

  React.useEffect(() => {
    if (!isEmpty(validatedRecipients)) {
      formValueChange('recipients', validatedRecipients);
    }
  }, [validatedRecipients, formValueChange]);

  const disableField = !values?.contractFieldOption?.contract.id;

  return (
    <>
      <div className={clsx(style.Row, style.ContractSelection)}>
        <MiniContractCard
          agreement={values?.contractFieldOption?.contract}
        />
      </div>
      <fieldset
        disabled={disableField}
        className={clsx(style.FormFieldset, {
          [style.ContractViewInternalReminder]: visualTweak === 'overrideLegacyStyling',
        })}
      >
        <RecipientsSelectField
          contract={selectedContract}
          currentUser={currentUser}
          disabled={disableField}
          onChange={(newRecipients) => {
            if (userConsent === 'given') {
              resetFormValuesRecipientsValidation(newRecipients);
              sendValidationEvent({ type: 'validation/revert' });
            }
          }}
        />
        <div className={clsx(style.Row, style.DatePickerField, {
          [style.ContractViewInternalReminder]: visualTweak === 'overrideLegacyStyling',
          [style.Disabled]: disableField,
        })}
        >
          <Field
            label={message({
              id: 'Date',
              comment: 'Label for date-selection in custom reminder modal',
            })}
            name="date"
            component={SingleDatePickerComponent}
            initialVisibleMonth={null}
            placeholder={getTranslationForDateFormat({ message, dateFormat })}
            displayFormat={dateFormat}
            required
            disabled={disableField}
            customClassName={style.OverrideSingleDatePicker}
            block={false}
          />
          <Field
            label={message({
              id: 'Time',
              comment: 'Label for time-selection in custom reminder modal',
            })}
            name="timeFieldOption"
            labelKey="time"
            valueKey="time"
            placeholder={message({
              id: 'HH:MM',
              comment: 'Placeholder used in the time field in custom reminder modal',
            })}
            options={disableHoursBeforeCurrentHourOfToday(timeValues, values?.date)}
            isOptionDisabled={(option) => option.isDisabled}
            component={SelectField}
            components={{ Option: TimeOption }}
            required
            disabled={disableField}
          />
        </div>
        <div className={clsx(style.Row, style.SubjectField)}>
          <Field
            label={message({
              id: 'Subject',
              comment: 'Label for subject-field in custom reminder modal',
            })}
            name="subject"
            placeholder={message({
              id: 'Enter subject',
              comment: 'Placeholder used in the subject field in custom reminder modal',
            })}
            component={TextField}
            maxLength={150}
            required
            customClass={style.TextFieldLabel}
            disabled={disableField}
          />
        </div>
        <div className={clsx(style.Row, style.MessageField, {
          [style.ContractViewInternalReminder]: visualTweak === 'overrideLegacyStyling',
        })}
        >
          <div className={style.AIAssistWrapper}>
            <div className={style.AIAssistContainer}>
              <AIAssistFieldButton
                agreement={selectedContract}
                context="agreementInternalReminder"
                onInsertGeneratedText={(text) => formValueChange('message', text)}
              />
            </div>
          </div>
          <Field
            label={message({
              id: 'Message',
              comment: 'Label for message-field in custom reminder modal',
            })}
            name="message"
            placeholder={message({
              id: 'Enter message',
              comment: 'Placeholder used in the message field in custom reminder modal',
            })}
            component={TextArea}
            maxLength={4500}
            minRows={5}
            maxRows={10}
            required
            customClass={style.TextArea}
            disabled={disableField}
          />
        </div>
      </fieldset>
    </>
  );
}

export default localize<Props>(InternalReminderCreateSelectedContractBody);
