import { REMOVED } from 'agreement/constants';
import { Message } from '@oneflowab/pomes';
import { matchPath, useLocation } from 'react-router';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getAccountFromSessionSelector } from 'reducers/session';
import { getCurrentWorkspaceSelector } from 'reducers/app';

import InfoIcon from 'components/icons/info';
// eslint-disable-next-line import/named
import { Table } from 'components/table';
import EmptyState from 'components/empty-state';
import Tooltip from 'components/tooltip';
import CircularSpinnerIcon from 'components/icons/circular-spinner';
import EmptyTrashStateIcon from 'components/icons/empty-trash-state';
import { HelpCenterLink } from 'components/help-center-link';
import Pagination from 'components/pagination';

import clsx from 'clsx';
import {
  isCanceled,
  isDeclined,
  isDraft,
  isDraftApprovalFlowRunning,
  isEnded,
  isOverdue,
  isPending,
  isSigned,
  isSignedAndEndedAndTerminated,
} from 'agreement';
import isDraftApproved from 'agreement/actions/is-draft-approved';

import style from './trashed-document-list.module.scss';
import { CreatedTrashedTime } from './created-trashed-time';
import { OriginalLocation } from './original-location';
import { Details } from '../details/details';
import { Actions } from './actions';
import { DeleteModal } from './actions/delete-modal';

type Query = {
  count: number,
  error?: unknown,
  loading: boolean,
  pagination: { limit: number, offset: number },
  params: object,
  result: number[],
  sort: string[],
  status: string,
};

type Props = {
  query: Query,
  result: Oneflow.Agreement[],
  onPaginate: (pagination: { limit: number, offset: number }) => void,
  queryName: string,
  emptyingTrashcan: boolean,
}

type TableConfig = {
  trashedDocuments: Oneflow.Agreement[],
  queryName: string,
  setIsDeleteModalOpen: (isOpen: boolean) => void,
  setDocumentData: (documentData: Oneflow.Agreement | null) => void,
  dateFormat: string,
}

const getTrashedDocuments = (
  result: Oneflow.Agreement[],
) => result.filter((agreement) => agreement.removed === REMOVED);

const getTableConfig = ({
  trashedDocuments,
  queryName,
  setIsDeleteModalOpen,
  setDocumentData,
  dateFormat,
}: TableConfig) => ({
  items: trashedDocuments,
  itemKey: 'id',
  dateFormat,
  columns: [
    {
      name: 'Document',
      label: <Message id="Document" comment="Table header" />,
      type: 'cell',
      className: style.DocumentColumn,
      value: (document: Oneflow.Agreement) => (
        <div className={style.DocumentTitleContainer}>
          <div className={clsx(style.State, {
            [style.Draft]: isDraft(document),
            [style.Signed]: isSigned(document),
            [style.Pending]: isPending(document),
            [style.Overdue]: isOverdue(document),
            [style.Declined]: isDeclined(document),
            [style.Canceled]: isCanceled(document),
            [style.Ended]: isEnded(document),
            [style.SignedAndTerminated]: isSignedAndEndedAndTerminated(document),
            [style.ApprovalPending]:
              isDraftApprovalFlowRunning(document) && !isDraftApproved(document),
            [style.Approved]: isDraftApproved(document) && isDraft(document),
          })}
          />
          <div className={style.Bold}>
            {document.name ?? <Message id="Untitled" comment="Placeholder title for untitled documents" />}
          </div>
        </div>
      ),
    },
    {
      name: 'Created',
      label: <Message id="Created" comment="Table header" />,
      type: 'cell',
      className: style.CreatedColumn,
      value: (document: Oneflow.Agreement) => (
        <CreatedTrashedTime
          time={document.createdTime || null}
          dateFormat={dateFormat}
          className={style.CreatedTime}
        />
      ),
    },
    {
      name: 'Trashed',
      label: <Message id="Trashed" comment="Table header" />,
      type: 'cell',
      className: style.UpdatedColumn,
      value: (document: Oneflow.Agreement) => (
        <CreatedTrashedTime
          name={document.removedBy?.name || ''}
          time={document.removedTime || null}
          dateFormat={dateFormat}
          className={style.TrashTime}
        />
      ),
    },
    {
      name: 'Original location',
      label: <Message id="Original location" comment="Table header" />,
      type: 'cell',
      className: style.StatusColumn,
      value: (document: Oneflow.Agreement) => <OriginalLocation document={document} />,
    },
    {
      name: 'Details',
      label: <Message id="Details" comment="Table header" />,
      type: 'cell',
      value: (document: Oneflow.Agreement) => (
        <Tooltip
          message={(
            <Details document={document} />
          )}
          messageClassName={style.InfoTooltipMessage}
          key={document.id}
          theme="light"
          side="left"
          sideOffset={4}
        >
          <InfoIcon />
        </Tooltip>
      ),
    },
    {
      name: 'Actions',
      label: <Message id="Actions" comment="Table header" />,
      type: 'actions',
      value: (document: Oneflow.Agreement) => (
        <Actions
          document={document}
          queryName={queryName}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setDocumentData={setDocumentData}
        />
      ),
    },
  ],
});

export const TrashedDocumentList = ({
  result, query, queryName, onPaginate, emptyingTrashcan,
}: Props) => {
  const trashedDocuments = getTrashedDocuments(result);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentData, setDocumentData] = useState<Oneflow.Agreement | null>(null);
  const account = useSelector(getAccountFromSessionSelector) as Oneflow.Account;
  const currentWorkspace = useSelector(getCurrentWorkspaceSelector);
  const location = useLocation();
  const isGlobalSearchPage = Boolean(matchPath(location.pathname, {
    path: '/search/trash',
    exact: false,
  }));

  // Account branding date format is used as a fallback if workspace date format is not set.
  const workspaceDateFormat = currentWorkspace?.brandingDateFormat || account.brandingDateFormat;
  const dateFormat = isGlobalSearchPage ? account.brandingDateFormat : workspaceDateFormat;

  if (query.loading) {
    return <CircularSpinnerIcon className={style.LoadingSpinner} />;
  }

  if (result.length === 0 && !query.loading) {
    return (
      <EmptyState
        defaultStyle
        withPadding
        icon={<EmptyTrashStateIcon height="74px" width="74px" />}
        header={<Message id="No document in trash" comment="Header for when no trashed document has been found" />}
        content={(
          <div className={style.EmptyState}>
            <p className={style.Paragraph}>
              <Message id="Move documents you don’t need to trash." comment="Description of trash tab" />
              {' '}
              <Message id="Documents will be deleted permanently after 30 days." comment="Description of trash tab" />
            </p>
            <HelpCenterLink path="support/solutions/articles/77000579661-trash-manage-deleted-documents-in-oneflow" />
          </div>
        )}
      />
    );
  }

  return (
    <div className={style.TrashDocumentsContainer}>
      <div className={style.TrashBanner}>
        <InfoIcon height="12px" />
        <Message
          id="Documents will be deleted permanently after {count} days."
          comment="Banner message in the trash tab."
          values={
            {
              count: '30',
            }
          }
        />
      </div>
      {emptyingTrashcan && (
        <div className={style.EmptyTrashLoading} data-testid="empty-trash-loader">
          <CircularSpinnerIcon height="14px" />
          <Message
            id="Emptying trash... This may take a moment."
            comment="Message when the trash is being emptied."
          />
        </div>
      )}
      <Table
        config={getTableConfig({
          trashedDocuments,
          queryName,
          setIsDeleteModalOpen,
          setDocumentData,
          dateFormat,
        })}
        query={query}
        TableClassName={style.TrashedDocumentList}
      />
      {/* We have to mount the modal here because
      modals cannot be mounted in the actions menu v2. */}
      {isDeleteModalOpen && (
        <DeleteModal
          setIsModalOpen={setIsDeleteModalOpen}
          documentData={documentData}
          queryName={queryName}
        />
      )}
      {
        (!query.loading || query.count) && (
          <Pagination
            totalItems={query.count}
            itemsPerPage={query.pagination.limit}
            currentOffset={query.pagination.offset}
            onChange={onPaginate}
            entityName={(
              <Message
                id="documents"
                comment="Plural form in pagination of documents."
              />
            )}
          />
        )
      }
    </div>
  );
};
