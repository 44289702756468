import { useSelector } from 'react-redux';
import { localize, Message } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import { getAccountFromSessionSelector } from 'reducers/session';
import { PLAN_NAMES } from 'account';

import Button from 'components/button';
import { DetailsFooter } from 'components/details-footer';
import { LocalizedDateTime } from 'components/localized-date-time';
import Divider from 'components/divider';
// eslint-disable-next-line import/named
import { FreemiumSelfService } from 'components/modals/freemium-self-service';

import AccountForm from './form';
import style from './account.module.scss';

export type FormData = {
  brandingDateFormat: string;
  country: string;
  logoId: number;
  name: string;
  orgnr: string;
};

type Props = {
  message: MessageTranslator;
};

const Account = ({ message }: Props) => {
  const account = useSelector(getAccountFromSessionSelector) as Oneflow.Account;

  const getUpgradeAccountButton = (onClick: () => void) => (
    <Button
      customClass={style.UpgradeButton}
      data-testid="upgrade-account"
      onClick={onClick}
      outline
      trackable="Go To Purchase Plans"
    >
      <Message
        id="Compare plans"
        comment="Button text for upgrade account button"
      />
    </Button>
  );

  const accountPlan = ({ title, info, showUpgradeModal }) => (
    <>
      <Divider solid className={style.Divider} />
      <div className={style.Plan}>
        <h3 className={style.Title}>
          <Message
            id="Account plan"
            comment="Plan header in the account page."
          />
        </h3>
        <h2 className={style.PlanTitle} data-testid="account-plan-title">
          {title}
        </h2>
        <span className={style.PlanInfo}>
          {info}
        </span>
        {showUpgradeModal && (
          <FreemiumSelfService>
            {getUpgradeAccountButton}
          </FreemiumSelfService>
        )}
      </div>
    </>
  );

  const getAccountPlan = () => {
    const { plan } = account;

    switch (plan.name) {
      case PLAN_NAMES.FREEMIUM:
        return accountPlan({
          title: (
            <Message
              id="Free plan"
              comment="Plan title in the account page."
            />
          ),
          info: (
            <Message
              id="Upgrade your Subscription Plan to create truly digital documents."
              comment="Plan description in the account page."
            />
          ),
          showUpgradeModal: true,
        });
      case PLAN_NAMES.ESSENTIALS:
        return accountPlan({
          title: (
            <Message
              id="Essentials"
              comment="Plan title in the account page."
            />
          ),
          info: (
            <Message
              id="Upgrade your Subscription Plan to improve collaboration across your workspaces."
              comment="Plan description in the account page."
            />
          ),
          showUpgradeModal: false,
        });
      case PLAN_NAMES.BUSINESS:
        return accountPlan({
          title: (
            <Message
              id="Business"
              comment="Plan title in the account page."
            />
          ),
          info: (
            <Message
              id="For teams seeking the magic of flow."
              comment="Plan description in the account page."
            />
          ),
          showUpgradeModal: false,
        });
      case PLAN_NAMES.ENTERPRISE:
        return accountPlan({
          title: (
            <Message
              id="Enterprise"
              comment="Plan title in the account page."
            />
          ),
          info: (
            <Message
              id="For global teams with true digital ambitions."
              comment="Plan description in the account page."
            />
          ),
          showUpgradeModal: false,
        });
      default:
        return null;
    }
  };

  const infoPairs = [
    {
      label: <Message
        id="Account ID"
        comment="Account ID header in the account page."
      />,
      value: account.id,
    },
    {
      label: <Message
        id="Creation date"
        comment="Account creation date header in the account page."
      />,
      value: <LocalizedDateTime datetime={account.createdTime} />,
    },
  ];

  return (
    <>
      <AccountForm message={message} />
      <div className={style.Container}>
        {getAccountPlan()}
      </div>
      <DetailsFooter infoPairs={infoPairs} useMarginTop />
    </>
  );
};

export default localize<Props>(Account);
