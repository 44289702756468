// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import { getPlans } from 'oneflow-client/plans';
import type { NormalizedPlans } from 'oneflow-client/plans';

import apiWrapper from 'sagas/api-wrapper';

import plans from 'reducers/entities/plans';

type MapperArgs = {
  data: NormalizedPlans,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(plans.setPlans(data.entities.plans));
}

const mappers = {
  query: {
    request: getPlans,
    mapper,
  },
};

const plansSaga = generateEntitySagas({
  apiWrapper,
  normalizedEntity: plans,
  mappers,
});

export default plansSaga;
