// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import dataFieldsReducer from 'reducers/entities/data-fields';

import EditDataField from './edit-data-field';
import type { Props } from './edit-data-field';

type StateProps = {|
  updateState: UpdateState,
|};

type DispatchProps = {|
  updateDataField: DataField => void,
  resetUpdateState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  dataField: DataField,
  dataFieldSetId: number,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { dataField }) => ({
  updateState: dataFieldsReducer.getUpdateSelector(state, { id: dataField.id }),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, {
  dataField,
  dataFieldSetId,
}) => ({
  updateDataField: ({
    name,
    description,
    placeholder,
    value,
    externalKey,
  }) => {
    dispatch(dataFieldsReducer.updateDataField({
      id: dataField.id,
      data: {
        dataFieldSetId,
        name,
        description,
        placeholder,
        value,
        externalKey,
      },
    }));
  },
  resetUpdateState: () => {
    dispatch(dataFieldsReducer.updateDataFieldReset({
      id: dataField.id,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(EditDataField);
