import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';

import { isTemplate } from 'agreement';
import { KEY_TEMPLATE_GROUPS } from 'extensions';
import extensionsReducer from 'reducers/entities/extensions';
import useAgreement from 'hooks/use-agreement';

import EditingInternalApproversToggle from 'components/document-tabs/settings-tab/sections/default-settings/toggles';
import AgreementVideo from 'components/agreement-video';
import CollaborationSettings from 'components/document-tabs/settings-tab/sections/default-settings/collaboration-settings';
import DefaultFolder from 'components/document-tabs/settings-tab/sections/default-settings/folder-content/folder';
import Divider from 'components/divider';
import SectionSeparator from 'components/document-tabs/settings-tab/components/section-separator/section-separator';

import CreatorRole from './fields/creator-role';
import LanguageSelector from './fields/language-selector';
import style from './default-settings.module.scss';

export type Props = {
  agreementId: number,
  onUpdate: {
    onAgreementLanguageUpdate: (language: string) => void
  },
};

const DefaultSettings = ({ agreementId, onUpdate }: Props) => {
  const agreement: Oneflow.Agreement = useAgreement(agreementId);
  const extension = useSelector((state) => (
    extensionsReducer.getExtensionSelector(state, { id: KEY_TEMPLATE_GROUPS })
  ));
  const isExtensionActive = extension.state;
  const isChangeTemplateActive = isTemplate(agreement) && isExtensionActive;

  return (
    <div className={style.DefaultSettings}>
      {isChangeTemplateActive && (
        <>
          <p className={style.TemporaryWarning}>
            <Message
              id="The template group selector can be found in the new data fields tab."
              comment="Info message about moving a component to a new tab in the sidebar"
            />
          </p>
          <SectionSeparator />
        </>
      )}
      <LanguageSelector
        agreementId={agreementId}
        onAgreementAttributeUpdate={onUpdate.onAgreementLanguageUpdate}
      />
      <SectionSeparator />
      {isTemplate(agreement) && (
        <>
          <CreatorRole agreementId={agreementId} />
          <SectionSeparator />
          <DefaultFolder agreementId={agreementId} />
          <SectionSeparator />
        </>
      )}
      <AgreementVideo agreementId={agreementId} />
      <CollaborationSettings agreementId={agreementId} />
      <Divider solid className={style.Divider} />
      <EditingInternalApproversToggle agreementId={agreementId} />
    </div>
  );
};

export default DefaultSettings;
