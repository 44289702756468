// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getCurrentLanguageSelector } from 'reducers/i18n';

import ElectronicIds from './electronic-ids';
import type { Props } from './electronic-ids';

type StateProps = {|
  currentLanguage: string,
|};
type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => ({
  currentLanguage: getCurrentLanguageSelector(state),
});

type DispatchProps = $Exact<{}>;
type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = () => Object.freeze({});

export default connect<Props, any, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(ElectronicIds);
