import * as zod from 'zod';

const ExtentableEntity = zod.object({
  id: zod.number(),
}).describe('ExtentableEntity');

// Don't add more props to these schemas, would be good if zod had freeze method
// follow: https://github.com/colinhacks/zod/discussions/1586
export const NumberIdSchema = zod.object({
  id: zod.number(),
}).describe('NumberIdSchema');

export const NullableNumberIdSchema = zod.object({
  id: zod.number().nullable(),
}).describe('NullableNumberIdSchema');

export const StringIdSchema = zod.object({
  id: zod.string(),
}).describe('StringIdSchema');

export const NullableStringIdSchema = zod.object({
  id: zod.string().nullable(),
}).describe('NullableStringIdSchema');

export default ExtentableEntity;
