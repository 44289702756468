import { Message } from '@oneflowab/pomes';
import { getFormattedPlanName } from 'account';

type Props = {
  auditLogEvent: Oneflow.AuditLogEvent,
};

export const AccountPlanUpdateEvent = ({ auditLogEvent }: Props) => {
  const { information } = auditLogEvent;

  const fromPlan = getFormattedPlanName({ plan: information?.planName?.from });
  const toPlan = getFormattedPlanName({ plan: information?.planName?.to });

  return (
    <Message
      id="Subscription Plan was changed from {oldPlan} to {newPlan}"
      comment="Audit log event message for changed plan"
      values={{
        oldPlan: fromPlan,
        newPlan: toPlan,
      }}
    />
  );
};
