import moment from 'moment';

import { formatDateString } from 'date';
import Tooltip from 'components/tooltip';

import style from './date-with-tooltip.module.scss';

type Props = {
  dateString: string,
  dateFormat: string,
  className?: string,
};

const DateWithTooltip = ({
  dateString,
  dateFormat,
  className,
}: Props) => {
  const formattedDateString = formatDateString(dateString, dateFormat);
  const formattedDateStringWithTime = formattedDateString + moment(dateString).format(', HH:mm');
  return (
    <Tooltip
      message={formattedDateStringWithTime}
      messageClassName={style.DateTooltip}
      className={style.Container}
      zIndex="10003"
    >
      <span className={className}>{formattedDateString}</span>
    </Tooltip>
  );
};

export default DateWithTooltip;
