// @flow

import React from 'react';
import { localize } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import type { MessageTranslator } from '@oneflowab/pomes';

import agreements from 'reducers/entities/agreements';

import { checkAcl } from 'components/acl';
import { InlineEditablePropsProvider } from 'components/inline-editable';

import { DocumentNameEditable } from './document-name-editable';
import style from './set-contract-name.module.scss';

const prepareName = (name) => (
  name?.trim() || ''
);

const isNamePristine = (oldName, newName) => (
  prepareName(oldName) === prepareName(newName)
);

type Props = {|
  agreement: Agreement,
  message: MessageTranslator,
|};

const SetContractName = ({ message, agreement }: Props) => {
  const dispatch = useDispatch();
  const isAllowedToEdit = checkAcl(agreement.acl, 'agreement:update:name');

  const saveContractName = React.useCallback((name) => {
    dispatch(agreements.updateContractName({
      id: agreement.id,
      data: {
        name: name || null,
      },
    }));
  }, [agreement, dispatch]);

  const onChange = React.useCallback((value) => {
    const name = prepareName(value);

    if (!isNamePristine(agreement.name, name)) {
      saveContractName(name);
    }
  }, [agreement.name, saveContractName]);

  return (
    <div className={style.EditableNameContainer}>
      <InlineEditablePropsProvider>
        <DocumentNameEditable
          defaultTooltip={message({
            id: 'Document names are not visible to counterparties',
            comment: 'Message informing about document name visibility.',
          })}
          defaultValue={message({
            id: 'Untitled document',
            comment: 'Placeholder for when document name is empty.',
          })}
          onChange={onChange}
          readOnly={!isAllowedToEdit}
          value={agreement.name}
        />
      </InlineEditablePropsProvider>
    </div>
  );
};

export default localize<Props>(SetContractName);
