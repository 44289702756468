// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { getExtensionsById } from 'reducers/entities/extensions';
import apiTokensReducer from 'reducers/entities/api-tokens';

import ExtensionsApiTokens from './api-tokens';
import type { Props } from './api-tokens';

export const QUERY_NAME = '/admin/account/extensions/api-tokens';

type StateProps = {|
  apiTokens: Array<ApiToken>,
  apiTokensQuery: Query,
  extensionsById: {
    [number]: Extension,
  },
  createdApiToken: ApiToken | {},
|};

type DispatchProps = {|
  queryApiTokens: () => void,
|};

type MapStateToProps = State => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const apiTokensQuery = apiTokensReducer.getQuerySelector(state, { name: QUERY_NAME });
  const createState = apiTokensReducer.getCreateSelector(state);

  return {
    apiTokensQuery,
    apiTokens: apiTokensReducer.getApiTokensSelector(state, { ids: apiTokensQuery.result }),
    extensionsById: getExtensionsById(state),
    createdApiToken: apiTokensReducer.getApiTokenSelector(state, { id: createState.result }),
  };
};

type MapDispatchToProps = Dispatch<*> => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  queryApiTokens: () => {
    dispatch(apiTokensReducer.queryApiTokens({
      name: QUERY_NAME,
    }));
  },
});

export default connect<Props, {||}, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(ExtensionsApiTokens);
