import { call, takeEvery } from 'redux-saga/effects';

import log from 'logging';

import { SET_ERROR } from 'reducers/app';
import { LOCATION_ASSIGN } from 'reducers/router';

export function reloadClient() {
  window.location.reload(true);
}

export function* logErrorToRollbar(action) {
  yield call(log.error, action.error.message, action.error);
}

export function* onLocationAssign(action) {
  yield call(() => window.location.assign(action.path));
}

export default function* app() {
  yield takeEvery(SET_ERROR, logErrorToRollbar);
  yield takeEvery(LOCATION_ASSIGN, onLocationAssign);
}
