import { ReactNode } from 'react';
import { Message, MessageTranslator, localize } from '@oneflowab/pomes';

import style from './audit-log-event.module.scss';

type Props = {
  auditLogEvent: Oneflow.AuditLogEvent,
  message: MessageTranslator
};

type LabelNames = 'agreement' | 'tag' | 'position';

const AccountExportEvent = ({ auditLogEvent, message }: Props) => {
  const labelMappings: Record<LabelNames, string | ReactNode> = {
    agreement: message({ id: 'documents', comment: 'Audit log account export event value.' }),
    tag: message({ id: 'tags', comment: 'Audit log account export event value.' }),
    position: message({ id: 'users', comment: 'Audit log account export event value.' }),
  };

  const getEventValues = () => {
    const { information } = auditLogEvent;

    const itemsLength = information?.items?.length || 0;

    return {
      items: information?.items?.reduce(
        (acc: string | ReactNode, item: LabelNames, index: number) => {
          if (!item) return acc;
          if (index === 0) {
            return labelMappings[item];
          }
          if (index === itemsLength - 1) {
            return `${acc}, and ${labelMappings[item]}`;
          }
          return `${acc}, ${labelMappings[item]}`;
        }, '',
      ),
    };
  };

  return (
    <Message
      id="The user has {jsx-start}exported{jsx-end} {items} from the account."
      comment="Audit log event message for exported account"
      component="span"
      className={style.Red}
      values={getEventValues()}
    />
  );
};

export default localize(AccountExportEvent);
