// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';
import ControlPlay from 'components/icons/control-play';
import ButtonRefreshArrows from 'components/icons/button-refresh-arrows';
import { FadeIn } from 'components/transitions';

import style from './playback.module.scss';

type Props = {
  src: Blob,
  onRetake: Function,
};

type State = {
  playing: boolean,
};

class Playback extends React.Component<Props, State> {
  videoRef: {
    current: HTMLVideoElement | null,
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      playing: false,
    };

    this.videoRef = React.createRef();
  }

  onPause = () => {
    this.setState({
      playing: false,
    });
  };

  onPlay = () => {
    this.setState({
      playing: true,
    });
  };

  onRetake = () => {
    const { onRetake } = this.props;

    onRetake();
  };

  play = () => {
    if (!this.videoRef.current) {
      return;
    }

    this.videoRef.current.play();
  };

  renderPlayButton() {
    const { playing } = this.state;

    if (playing) {
      return null;
    }

    return (
      <div className={style.Play}>
        <Button
          onClick={this.play}
          customClass={style.PlayButton}
          icon={ControlPlay}
          data-testid="playback-video"
        />
      </div>
    );
  }

  renderRetakeButton() {
    const { playing } = this.state;

    if (playing) {
      return null;
    }

    return (
      <div className={style.RetakeContainer}>
        <Button
          onClick={this.onRetake}
          icon={<ButtonRefreshArrows />}
          data-testid="retake-video"
          kind="special"
          customClass={style.Rounded}
        />
        <Message
          id="Retake"
          comment="The button text after recordering a video allowing the user to record the video again."
        />
      </div>
    );
  }

  render() {
    const { src } = this.props;
    const { playing } = this.state;

    return (
      <FadeIn className={style.FadeInPlayback} in>
        {this.renderPlayButton()}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          onPlay={this.onPlay}
          onPause={this.onPause}
          onEnded={this.onPause}
          className={style.Playback}
          controls={playing}
          ref={this.videoRef}
        >
          <source src={URL.createObjectURL(src)} type='video/webm;codecs="vp8, opus"' />
        </video>
        {this.renderRetakeButton()}
      </FadeIn>
    );
  }
}

export default Playback;
