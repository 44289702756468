import React, { ComponentProps, forwardRef } from 'react';
import * as ReactSelect from '@radix-ui/react-select';
import clsx from 'clsx';
import CheckIcon from 'components/icons/new-check';

import style from './select-field-radix.module.scss';

export const Select = ReactSelect.Root;
export const SelectContent = ReactSelect.Content;
export const SelectTrigger = ReactSelect.Trigger;
export const SelectViewport = ReactSelect.Viewport;
export const SelectPortal = ReactSelect.Portal;
export const SelectValue = ReactSelect.Value;

export const SelectItem = forwardRef<
  React.ElementRef<typeof ReactSelect.Item>,
  ComponentProps<typeof ReactSelect.Item>
>(({
  children,
  className,
  ...props
}, forwardedRef) => (
  <ReactSelect.Item
    className={clsx(style.SelectItem, className)}
    {...props}
    ref={forwardedRef}
  >
    <ReactSelect.ItemText>
      {children}
    </ReactSelect.ItemText>
    <ReactSelect.ItemIndicator>
      <CheckIcon height="11px" />
    </ReactSelect.ItemIndicator>
  </ReactSelect.Item>
));

SelectItem.displayName = 'SelectItem';
