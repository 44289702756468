import { useState, ReactNode } from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';

import ChevronDownIcon from 'components/icons/chevron-down';
import ChevronUpIcon from 'components/icons/chevron-up';
import Button from 'components/button';
import * as DropdownMenu from 'components/dropdown-menu-v2';
import type { MenuItems } from './types';

import style from './split-button-with-dropdown.module.scss';

type ButtonKinds = 'save'
type Props = {
  kind: ButtonKinds,
  children?: ReactNode,
  menuItems: MenuItems,
  onClick?: () => void,
  customMainButton?: ReactNode;
  disabled?: boolean;
}

const SplitButtonWithDropdown = ({
  kind, children, menuItems, onClick, customMainButton, disabled,
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const renderMainButton = () => {
    if (customMainButton) {
      return (
        <div className={style.ButtonNextToDropdown} data-testid="custom-main-button">
          {customMainButton}
        </div>
      );
    }

    return (
      <Button
        kind={kind}
        customClass={style.ButtonNextToDropdown}
        onClick={onClick}
        disabled={disabled}
        data-testid="dropdown-trigger"
      >
        {children}
      </Button>
    );
  };

  return (
    <div className={style.ButtonDropdownContainer}>
      {renderMainButton()}
      <DropdownMenu.Root
        onOpenChange={(open) => setMenuOpen(open)}
        open={menuOpen}
      >
        <DropdownMenu.Trigger asChild disabled={disabled}>
          <div className={clsx(style.TriggerButton, {
            [style.Disabled]: disabled,
          })}
          >
            <Button
              kind="save"
              customClass={clsx(style.TriggerButton, {
                [style.Disabled]: disabled,
              })}
            >
              {menuOpen ? <ChevronUpIcon height="10px" /> : <ChevronDownIcon height="10px" />}
            </Button>
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content align="end">
            {menuItems.map((item) => (
              <DropdownMenu.Item key={uniqueId()} onSelect={item.onSelect}>
                {item.content}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default SplitButtonWithDropdown;
