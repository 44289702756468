// @flow

import { keys } from 'lodash';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { type MessageTranslator } from '@oneflowab/pomes';

import agreementsReducer from 'reducers/entities/agreements';
import { isSigningInProgress, isDecliningInProgress } from 'agreement';
import { getPositionFromSessionSelector, getHasUserSelector } from 'reducers/session';
import { setSuccessfullySignedModalOpen } from 'reducers/app';
import { getAgreementMyParticipant, getGuestToken } from 'agreement/selectors';

import { getPDFContractURL } from 'utils/download-pdf-link';

import { SigningCompleted, type Props } from './signing-completed';

type MainProps = $Diff<Props, {| message: MessageTranslator |}>;

type StateProps = {|
  agreement: Agreement,
  link: string,
  disabled: boolean,
  myParticipant: AgreementParticipant,
  guestToken: string,
  rpcState: RpcState,
  isGuestConversionFeaturedEnabled: boolean,
  hasUser: boolean,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps> & {
  agreementId: number,
}

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const isInProgress = (
  agreement: Agreement,
) => isSigningInProgress(agreement) || isDecliningInProgress(agreement);

export const mapStateToProps: MapStateToProps = (state: State, { agreementId }: OwnProps) => {
  const agreement = agreementsReducer.getAgreementSelector(state, { id: agreementId });
  const optionKeys = keys(agreement.availableOptions);
  const isGuestConversionFeaturedEnabled = optionKeys.includes('guestConversion');

  const guestToken = getGuestToken(state);
  const position = getPositionFromSessionSelector(state);
  const myParticipant = getAgreementMyParticipant(agreement);
  const hasUser = getHasUserSelector(state);

  return {
    agreement,
    myParticipant,
    link: getPDFContractURL(agreement, position, guestToken),
    disabled: isInProgress(agreement),
    guestToken,
    rpcState: agreementsReducer.getSendConcludedAgreementCopySelector(state, { id: agreement.id }),
    isGuestConversionFeaturedEnabled,
    hasUser,
  };
};

type DispatchProps = {|
  sendAgreementCopy: (agreementId: number, participantId: number) => void,
|};

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (
  dispatch: Function,
) => ({
  sendAgreementCopy: (agreementId, participantId) => {
    dispatch(agreementsReducer.sendConcludedAgreementCopy({
      id: agreementId,
      data: {
        agreementId,
        participantId,
      },
    }));
  },
  setSignedModalOpen: (isOpen: boolean) => {
    dispatch(setSuccessfullySignedModalOpen(isOpen));
  },
});

const connectedSigningCompleted = connect<
MainProps, OwnProps, StateProps, DispatchProps, State, Dispatch<*>
>(
  mapStateToProps,
  mapDispatchToProps,
)(SigningCompleted);

export { connectedSigningCompleted as SigningCompleted };
