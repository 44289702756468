import { Message } from '@oneflowab/pomes';

import { useDocumentLayout } from 'components/document-layout-container/document-layout-context';
import Accordion from 'components/document-tabs/settings-tab/accordion';
import DefaultSettings from 'components/document-tabs/settings-tab/sections/default-settings/default-settings';

import style from './accordion.module.scss';

export type Props = {
  agreementId: number,
  onUpdate: {
    onTemplateGroupChangeSuccess: () => void,
    onAgreementLanguageUpdate: (language: string) => void
  },
};

const GeneralSection = ({ agreementId, onUpdate }: Props) => {
  const { accordionStates } = useDocumentLayout();
  const isExpanded = accordionStates.general;

  const title = (
    <Message
      id="General"
      comment="Header for general section, where you select different settings for your document."
    />
  );

  return (
    <Accordion
      className={isExpanded ? 'general-accordion-expanded' : 'general-accordion-closed'}
      contentClassName={style.Content}
      name="general"
      title={title}
    >
      <DefaultSettings
        agreementId={agreementId}
        onUpdate={onUpdate}
      />
    </Accordion>
  );
};

export default GeneralSection;
