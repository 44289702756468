// @flow

import {
  KEY_MEMBRAIN,
} from 'extensions';

const Membrain = {
  key: KEY_MEMBRAIN,
  typeSpecificConfigKeys: [
    'customerInstanceSubdomain',
  ],
};

export default Membrain;
