import { memo } from 'react';
import { QRCodeSVG, QRCodeCanvas } from 'qrcode.react';
import clsx from 'clsx';

import style from './qr-code.module.scss';

type Props = {
  wrapperClassName?: string,
  renderAs?: 'svg' | 'canvas',
  value: string,
  size?: number,
  level?: 'L' | 'M' | 'Q' | 'H',
  bgColor?: string,
  fgColor?: string,
  includeMargin?: boolean,
  imageSettings?: {
    src: string,
    height: number,
    width: number,
    excavate: boolean,
    x?: number,
    y?: number,
  },
  className?: string,
};

export const MemoizedQRCodeSVG = memo(QRCodeSVG);
export const MemoizedQRCodeCanvas = memo(QRCodeCanvas);

const QRCode = ({
  wrapperClassName,
  value,
  renderAs = 'canvas',
  size = 160,
  level = 'L',
  includeMargin = true,
  imageSettings = {
    src: '',
    excavate: true,
    height: 32,
    width: 32,
  },
  ...rest
}: Props) => {
  const QRCodeComponent = renderAs === 'svg' ? MemoizedQRCodeSVG : MemoizedQRCodeCanvas;

  return (
    <div className={clsx(style.QRCodeWrapper, wrapperClassName)}>
      <QRCodeComponent
        value={value}
        level={level}
        size={size}
        includeMargin={includeMargin}
        imageSettings={imageSettings}
        {...rest}
      />
    </div>
  );
};

export default QRCode;
