// @flow

import React from 'react';
import type { Match } from 'react-router';

import { DownloadComponent } from 'components/download';

type Props = {|
  match: Match,
|};

export default (props: Props) => (
  <DownloadComponent {...props} />
);
