// @flow

import * as React from 'react';
import clsx from 'clsx';

import ChevronDownIcon from 'components/icons/chevron-down';
import ChevronUpIcon from 'components/icons/chevron-up';
import Button from 'components/button';
import { setItemInContractList, getItemInContractList } from 'utils/local-storage';
import AsyncFilterBoxWithTags from './async-filter-box-with-tags';
import AsyncFilterBoxWithColleagues from './async-filter-box-with-colleagues';

import style from './filter-box.module.scss';

type Props = {
  title: React.Node,
  children: React.Node,
  count?: number,
  optionsCount?: number,
  workspaceId?: number,
  param?: string,
  setOptions?: Function,
  removeDivider?: boolean,
};

type State = {
  visible: boolean,
};

export default class FilterBox extends React.Component<Props, State> {
  static defaultProps = {
    count: undefined,
    optionsCount: undefined,
    workspaceId: undefined,
    param: undefined,
    setOptions: undefined,
  }

  getInitialState = () => {
    const { param, workspaceId } = this.props;

    return getItemInContractList({ visible: true, workspaceId, param });
  }

  state = {
    visible: this.getInitialState(),
  };

  componentDidUpdate(prevProps: Props) {
    const { workspaceId } = this.props;
    const { workspaceId: prevWorkspaceId } = prevProps;

    if (typeof workspaceId !== 'number' || typeof prevWorkspaceId !== 'number') {
      return;
    }

    if (workspaceId !== prevWorkspaceId) {
      this.updateState();
    }
  }

  updateState = () => {
    this.setState({
      visible: this.getInitialState(),
    });
  }

  toggleVisibility = () => {
    const { workspaceId, param } = this.props;

    this.setState((prevState: State) => ({
      visible: !prevState.visible,
    }), () => setItemInContractList({ visible: this.state.visible, workspaceId, param }));
  }

  getIcon = () => {
    const { visible } = this.state;

    if (visible) {
      return <ChevronUpIcon height="10px" />;
    }

    return <ChevronDownIcon height="10px" />;
  }

  renderFilter() {
    const { children } = this.props;
    const { visible } = this.state;

    if (!visible) {
      return null;
    }

    return (
      <div className={style.Content}>
        {children}
      </div>
    );
  }

  renderFilterCounter = (count?: number) => {
    if (!count) {
      return null;
    }

    return (
      <div className={style.FilterCounter}>
        {count}
      </div>
    );
  }

  renderFilterBox = () => {
    const {
      count,
      removeDivider,
      title,
    } = this.props;

    const filterBoxClassNames = clsx(style.FilterBox, {
      [style.RemoveDivider]: removeDivider,
    });

    return (
      <div className={filterBoxClassNames} data-testid="filter">
        <Button
          icon={this.getIcon()}
          customClass={style.Header}
          onClick={this.toggleVisibility}
          hasIconRight
        >
          <div className={style.HeaderContent}>
            <h3 className={style.Title}>
              {title}
            </h3>
            {this.renderFilterCounter(count)}
          </div>
        </Button>
        {this.renderFilter()}
      </div>
    );
  }

  render() {
    const {
      count,
      optionsCount,
      param,
      setOptions,
    } = this.props;

    const shouldFetchTags = Boolean(
      !this.state.visible
      && Number(count) > 0
      && optionsCount === 0
      && typeof setOptions === 'function'
      && param === 'tags',
    );

    const shouldFetchColleagues = Boolean(
      !this.state.visible
      && Number(count) > 0
      && optionsCount === 0
      && typeof setOptions === 'function'
      && param === 'positions',
    );

    if (shouldFetchTags) {
      return (
        <AsyncFilterBoxWithTags
          setTagsOptions={setOptions}
        >
          {this.renderFilterBox()}
        </AsyncFilterBoxWithTags>
      );
    }

    if (shouldFetchColleagues) {
      return (
        <AsyncFilterBoxWithColleagues
          setColleaguesOptions={setOptions}
        >
          {this.renderFilterBox()}
        </AsyncFilterBoxWithColleagues>
      );
    }

    return this.renderFilterBox();
  }
}
