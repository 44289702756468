// this will eventually replace permissions.js
import { checkAcl } from 'components/acl';

/* TODO: Remove notes for review:
  - moving from app/components/box-list/helpers.ts
*/
// eslint-disable-next-line import/prefer-default-export
export const isAgreementLayoutEditable = (
  agreement: Oneflow.Agreement,
) => checkAcl(agreement.acl, 'agreement:layout:update');
