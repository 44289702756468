import getFontAssetsDomain from './get-font-asset-domain.js';

const loadRaleway = () => {
  const normalRaleway = new FontFace(
    'Raleway',
    `url(${getFontAssetsDomain()}/resources/fonts/raleway/raleway_400.ttf)`,
    {
      style: 'normal',
      weight: 400,
    },
  );
  const italicRaleway = new FontFace(
    'Raleway',
    `url(${getFontAssetsDomain()}/resources/fonts/raleway/raleway_400_italic.ttf)`,
    {
      style: 'italic',
      weight: 400,
    },
  );
  const boldRaleway = new FontFace(
    'Raleway',
    `url(${getFontAssetsDomain()}/resources/fonts/raleway/raleway_700.ttf)`,
    {
      style: 'normal',
      weight: 700,
    },
  );
  const boldItalicRaleway = new FontFace(
    'Raleway',
    `url(${getFontAssetsDomain()}/resources/fonts/raleway/raleway_700_italic.ttf)`,
    {
      style: 'italic',
      weight: 700,
    },
  );

  return [normalRaleway, italicRaleway, boldRaleway, boldItalicRaleway];
};

export default loadRaleway;
