import { debounce } from 'lodash';
import type { DebouncedFunc } from 'lodash';
import {
  useEffect,
  useMemo,
  useRef,
} from 'react';
import type { DependencyList, MutableRefObject } from 'react';

type CallbackFunction = (...args: unknown[]) => unknown;

function useUpdateRef<T>(value: T): MutableRefObject<T> {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}

function useDebouncedCallback<T extends CallbackFunction>(
  callback: T, deps: DependencyList, wait?: number,
): DebouncedFunc<T> {
  const callbackRef = useUpdateRef(callback);

  const debouncedCallback = useMemo(
    () => debounce((...args) => callbackRef.current(...args), wait || 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wait, ...deps],
  );

  useEffect(() => () => {
    debouncedCallback.cancel();
  }, [debouncedCallback]);

  return debouncedCallback;
}

export default useDebouncedCallback;
