// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';

import generateEntitySagas from 'normalized-redux/sagas';
import {
  getUserWorkspaces,
  type NormalizedUserWorkspaces,
} from 'oneflow-client/user-workspaces';

import apiWrapper from 'sagas/api-wrapper';

import userWorkspacesReducer from 'reducers/entities/user-workspaces';

type MapperArgs = {
  data: NormalizedUserWorkspaces,
  action: {
    id: number,
    type: string,
  },
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(userWorkspacesReducer.setUserWorkspaces(data.entities.workspaces));
}

const mappers = {
  query: {
    mapper,
    request: getUserWorkspaces,
  },
};

const userWorkspacesSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: userWorkspacesReducer,
  mappers,
});

export default userWorkspacesSagas;
