// @flow

import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import eachRight from 'lodash/eachRight';
import { removeColumn } from './table-columns';

import {
  findActiveTable,
  getActiveTableRow,
  getActiveTableCell,
} from './table-utils';
import {
  getColumnIndex,
  createBlockMatrix,
} from './block-matrix';

const filterHorizontalMergedCellsBy = (blockMatrix: any, columnIndex: number) => {
  const cells = [];
  blockMatrix.forEach((row) => {
    const cell = row[columnIndex];
    if (cell.colSpan > 1) {
      cells.push(cell);
    }
  });

  return cells;
};

const removeTableColumn = (editor: any) => {
  const blockMatrix = createBlockMatrix(editor);
  if (!blockMatrix) {
    return;
  }
  const [activeTable, activeTablePath] = findActiveTable(editor);
  const activeTableRow = getActiveTableRow(editor);
  const activeTableCell = getActiveTableCell(editor);
  const columnIndex = getColumnIndex(blockMatrix, activeTableRow, activeTableCell);

  Editor.withoutNormalizing(editor, () => {
    const removedNodes = new Map();
    const updatedCells: Map<any, { rowSpan: number, path: Array<any> }> = new Map();

    filterHorizontalMergedCellsBy(blockMatrix, columnIndex)
      .forEach((cell) => {
        if (removedNodes.get(cell.node)) {
          return;
        }
        Transforms.setNodes(editor, {
          colSpan: cell.colSpan - 1,
        }, {
          at: ReactEditor.findPath(editor, cell.node),
        });
        removedNodes.set(cell.node, true);
      });

    if (blockMatrix[0].length > 1) {
      eachRight(blockMatrix, (row, index) => {
        const cell = row[columnIndex];
        if (cell.colSpan > 1) {
          return;
        }

        if (cell.colSpan === 1) {
          if (removedNodes.get(cell.node)) {
            return;
          }

          if (cell.parentNode.children.length === 1) {
            blockMatrix[index].forEach((item) => {
              if (item.rowSpan > 1) {
                let nodeRowSpan = item.rowSpan;
                let nodePath;
                const updatedCell = updatedCells.get(item.node);
                if (updatedCell) {
                  nodeRowSpan = updatedCell.rowSpan;
                  nodePath = updatedCell.path;
                } else {
                  nodePath = ReactEditor.findPath(editor, item.node);
                }

                Transforms.setNodes(editor, {
                  rowSpan: nodeRowSpan - 1,
                }, {
                  at: nodePath,
                });

                updatedCells.set(item.node, {
                  rowSpan: nodeRowSpan - 1,
                  path: nodePath,
                });
              }
            });

            Transforms.removeNodes(editor, {
              at: ReactEditor.findPath(editor, cell.parentNode),
            });

            removedNodes.set(cell, true);
            return;
          }

          Transforms.removeNodes(editor, {
            at: ReactEditor.findPath(editor, cell.node),
          });

          removedNodes.set(cell.node, true);
        }
      });
      return;
    }

    Transforms.removeNodes(editor, {
      at: activeTablePath,
    });
  });

  removeColumn({
    editor,
    tableElement: activeTable,
    columnIndex,
  });
};

export default removeTableColumn;
