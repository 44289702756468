// @flow

export const idpTypes = [
  { label: 'Other', value: '_other' },
  { label: 'ADFS', value: 'adfs' },
  { label: 'Azure', value: 'azure' },
  { label: 'Duo', value: 'duo' },
  { label: 'ForgeRock', value: 'forgerock' },
  { label: 'Google Workspace', value: 'google_workspace' },
  { label: 'OneTouch', value: 'onetouch' },
];

export const getIdpTypeByValue = (idpType: string) => {
  if (!idpType) {
    return idpTypes[0];
  }

  const type = idpTypes.find((idp) => idp.value === idpType.toLowerCase());

  if (!type) {
    return null;
  }

  return type;
};

export const getIdpTypeLabel = (idpType: string) => {
  const type = getIdpTypeByValue(idpType);

  if (!type) {
    return '-';
  }

  return type.label;
};
