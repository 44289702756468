import { useSelector } from 'react-redux';

import useAgreement from 'hooks/use-agreement';
import { getGuestToken } from 'agreement/selectors';
import DocumentActions from 'components/document-actions';
import DocumentCallToActions from 'components/document-call-to-actions';
import { EXPANDED } from 'components/document-layout-container/helpers';

import PreviewButton from 'components/buttons/preview';
import {
  isDraft, isImport, isOverdue, isPending, isSigned, isTemplate,
} from 'agreement';
import { isContractPristine } from 'reducers/current-contract';
import useFeatureFlag from 'hooks/use-feature-flag';
import LeftSide from './left-side';
import GuestTopBar from './collapsed/guest-topbar/guest-topbar';
import style from './expanded-document-topbar.module.scss';

type Props = {
  agreementId: Oneflow.Document['id']
}

const DocumentTopBar = ({ agreementId }: Props) => {
  const agreement = useAgreement(agreementId);
  const companyName = agreement.parties?.[0]?.name ?? '';
  const guestToken = useSelector(getGuestToken);
  const isGuestView = Boolean(guestToken);
  const isDocumentPreviewEnabled = useFeatureFlag('temporaryDocumentPreview');
  const isPristine = useSelector(isContractPristine);
  const shouldRenderPreviewToggle = !guestToken
    && isDocumentPreviewEnabled
    && isPristine
    && (isPending(agreement) || isDraft(agreement) || isOverdue(agreement) || isTemplate(agreement))
    && !isSigned(agreement)
    && !isImport(agreement);

  return (
    <div className={style.Topbar}>
      {isGuestView ? (
        <GuestTopBar
          companyName={companyName}
          logoUrl={agreement.logoUrl}
        />
      ) : <LeftSide agreement={agreement} />}
      <div className={style.RightSide}>
        {shouldRenderPreviewToggle && <PreviewButton agreementId={agreementId} />}
        <DocumentCallToActions agreementId={agreementId} layout={EXPANDED} />
        <DocumentActions agreementId={agreementId} />
      </div>
    </div>
  );
};

export default DocumentTopBar;
