// @flow

import React from 'react';
import { Message, type MessageTranslator } from '@oneflowab/pomes';
import { Form, Field } from 'react-final-form';

import adminPage from 'hocs/admin-page';
import { hasAnyRole } from 'oneflow-client/roles';
import { isRoleEditable } from 'role';

import * as EditableForm from 'components/editable-form';
import { checkAcl } from 'components/acl';
import { DetailsFooter } from 'components/details-footer';
import { LocalizedDateTime } from 'components/localized-date-time';
import TextField from 'components/text-field';
import RoleDetailsHeaderText from '../role-details-header-text';

import style from './details.module.scss';

type UpdateRole = {
  id: number,
  data: Role
};
type Props = AdminPageComponentProps & {
  role: Role,
  updateRole: UpdateRole => void,
  updateState: UpdateState,
  resetUpdateState: Function,
  message: MessageTranslator,
  updatePath: (pathname: string) => void,
  location: Location,
};
type State = {
  isEditMode: boolean,
}
export type FormData = {
  name: string,
}
export class DetailsComponent extends React.Component<Props, State> {
  state = {
    isEditMode: false,
  }

  componentDidUpdate(prevProps: Props) {
    const { updateState } = this.props;

    this.enableEditModeIfRedirected();

    if (
      updateState.success && !prevProps.updateState.success
      && updateState.success
      && this.state.isEditMode
    ) {
      this.setIsEditMode(false);
    }
  }

  getInitialValues = () => {
    const { name } = this.props.role;

    return { name };
  }

  uniqueRoleName = ({ params }: { params: any }) => {
    const { role } = this.props;

    return hasAnyRole({ params, entityId: role.id });
  };

  setIsEditMode = (_isEditMode: boolean) => {
    this.setState({ isEditMode: _isEditMode });
  };

  checkEditPermission = () => {
    const { role } = this.props;

    return (checkAcl(role.acl, 'role:update:name'));
  };

  enableEditModeIfRedirected() {
    const { updatePath, location } = this.props;

    if (!this.state.isEditMode && location?.query.edit && this.checkEditPermission()) {
      updatePath(location.pathname);

      this.setState({
        isEditMode: true,
      });
    }
  }

  renderSystemRoleWarning = () => (
    <div className={style.DisabledInformTextContainer}>
      <p className={style.DisabledInformText}>
        <Message
          id="This is a predefined system role and cannot be edited."
          comment="Header text for the role details page"
        />
      </p>
    </div>
  );

  renderActionsHeader = () => {
    const { role } = this.props;
    return (<RoleDetailsHeaderText role={role} />);
  }

  render() {
    const {
      updateState,
      resetUpdateState,
      role,
      updateRole,
      message,
    } = this.props;

    const { isEditMode } = this.state;

    if (!role || !role.acl) {
      return null;
    }

    const infoPairs = [
      {
        label: <Message
          id="Role ID"
          comment="ID label for the details footer."
        />,
        value: role.id,
      },
      {
        label: <Message
          id="Creation date"
          comment="Creation date label for the details footer"
        />,
        value: <LocalizedDateTime datetime={role.createdTime} />,
      },
    ];

    return (
      <>
        <Form
          initialValues={this.getInitialValues()}
          onSubmit={updateRole}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <EditableForm.Header>
                {isRoleEditable(role) ? (
                  <EditableForm.Actions
                    resetFormState={resetUpdateState}
                    formState={updateState}
                    isEditMode={isEditMode}
                    setIsEditMode={this.setIsEditMode}
                    disabled={!this.checkEditPermission()}
                  />
                ) : this.renderSystemRoleWarning()}
                {this.renderActionsHeader()}
              </EditableForm.Header>
              <EditableForm.Body>
                <h2 className={style.Header}>
                  <Message
                    id="Role details"
                    comment="Used as the title of the Role Details section on the role details page"
                  />
                </h2>
                <div className={style.Row}>
                  <EditableForm.Label>
                    <Message
                      id="Name"
                      comment="Used as a label for the role details page."
                    />
                    <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                  </EditableForm.Label>
                  <Field
                    id="name"
                    name="name"
                    component={TextField}
                    value={role.name}
                    canEdit={checkAcl(role.acl, 'role:update:name')}
                    maxLength={50}
                    required
                    autoFocus
                    unique={{
                      text: message({
                        id: 'A role with this name already exists in the role list. Try another one.',
                        comment: 'Unqiueness error message on the role details page',
                      }),
                      param: 'name',
                      request: this.uniqueRoleName,
                      initialValue: role.name,
                    }}
                    disabled={!isEditMode}
                  />
                </div>
              </EditableForm.Body>
            </form>
          )}
        />
        <DetailsFooter infoPairs={infoPairs} />
      </>
    );
  }
}
type MapperProps = {
  message: MessageTranslator,
};

export default adminPage(({ props: { message } }: { props: MapperProps }) => ({
  title: message({ id: 'Details', comment: 'Page title for role details page.' }),
  modules: [[]],
}))(DetailsComponent);
