import type { MouseEventHandler } from 'react';

import ReplaceContractModal from 'components/modals/replace-contract';
// eslint-disable-next-line import/named
import { ReplaceMenuItem } from 'components/menu-items/replace';

export type Props = {
  agreement: Oneflow.Agreement,
  disabled?: boolean,
};

export const ReplaceContractItem = ({ agreement, disabled }: Props) => (
  <ReplaceContractModal
    agreement={agreement}
  >
    {(onClick: MouseEventHandler) => (
      <ReplaceMenuItem
        onClick={onClick}
        disabled={disabled}
      />
    )}
  </ReplaceContractModal>
);

ReplaceContractItem.defaultProps = {
  disabled: undefined,
};
