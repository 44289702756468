/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import {
  DATE_FORMAT as EXPIRE_DATE_FORMAT, // only for post request
  getStartOfToday,
  getMoment,
  getMonths,
  getCurrentYear,
  getDaysUntil,
} from 'date';

import { DayPickerSingleDateController } from 'components/date-picker';

import style from './expiry-date-picker.module.scss';

const nextHundredYears = () => {
  const currentYear = getCurrentYear();

  return Array.from({ length: 100 }, (_, index) => index + currentYear);
};

function ExpiryDatePicker(props) {
  const {
    id,
    name,
    onAfterFocus,
    expireDateValue,
    setExpireDateValue,
  } = props;

  // To handle overdue document
  const getStartDate = (selectedDateValue) => {
    const daysUntil = getDaysUntil(selectedDateValue.format());

    if (daysUntil < 0) {
      return getMoment();
    }

    return selectedDateValue;
  };

  const [datePickerFocused, setDatePickerFocused] = useState(true);

  const dateValue = getStartDate(expireDateValue);

  const renderMonthElement = ({ month: momentDate, onMonthSelect, onYearSelect }) => (
    <div className={style.DatePickerMonthElement}>
      <div>
        <select
          value={momentDate.month()}
          onChange={(event) => {
            const selectedMonth = event.target.value;
            onMonthSelect(momentDate, selectedMonth);

            // Select first day of the month
            setExpireDateValue(
              getMoment([momentDate.year(), selectedMonth, 1]),
            );
          }}
        >
          {getMonths().map((label, value) => (
            <option
              key={label}
              value={value}
              disabled={momentDate.year() === getCurrentYear() && value < getMoment().month()}
            >
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          value={momentDate.year()}
          onChange={(event) => {
            const selectedYear = event.target.value;
            onYearSelect(momentDate, selectedYear);

            // Select first day of the month
            setExpireDateValue(
              getMoment([selectedYear, momentDate.month(), 1]),
            );
          }}
        >
          {nextHundredYears(expireDateValue).map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
    </div>
  );

  return (
    <div
      id={id}
      role="dialog"
      onFocus={onAfterFocus}
      className={style.DatePicker}
    >
      <label htmlFor="datePickerValueInput">
        <input
          id="datePickerValueInput"
          type="hidden"
          name={name}
          value={dateValue.format(EXPIRE_DATE_FORMAT)}
        />
        <DayPickerSingleDateController
          numberOfMonths={1}
          onDateChange={setExpireDateValue}
          onFocusChange={() => setDatePickerFocused(true)}
          focused={datePickerFocused}
          date={dateValue}
          initialVisibleMonth={() => getStartDate(expireDateValue)}
          isOutsideRange={(day) => day < getStartOfToday()}
          hideKeyboardShortcutsPanel
          renderMonthElement={renderMonthElement}
          onPrevMonthClick={(previousMonthDate) => setExpireDateValue(
            getMoment([previousMonthDate.year(), previousMonthDate.month(), 1]),
          )}
          onNextMonthClick={(nextMonthDate) => setExpireDateValue(
            getMoment([nextMonthDate.year(), nextMonthDate.month(), 1]),
          )}
        />
      </label>
    </div>
  );
}

export default ExpiryDatePicker;
