import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { formatDateString } from 'date';

import { getFormattedPlanName } from 'account';

import { checkAcl } from 'components/acl';
import BillingSection from 'components/billing-section';
import BuySeatsButton from 'components/billing-header/buy-seats-button';
import CancellationFlowComponent from 'components/modals/cancellation/cancellation';
import Divider from 'components/divider';
import Message from 'components/message';
import ChangePlanComponent from 'components/modals/change-plan';

import {
  PERIOD_MONTHLY,
  PERIOD_QUARTERLY,
  PERIOD_YEARLY,
} from 'account/billing';

import type { Billing } from 'types/billing';
import type { AccountSeats } from 'types/account-seats';

import style from './subscription.module.scss';

type LocationState = {
  openBuySeatsModal: boolean,
}

type Props = {
  account: Oneflow.Account,
  billing: Billing,
  accountSeats: AccountSeats,
  dateFormat: string,
};

function renderPaymentInterval(billing: Billing) {
  switch (billing.period) {
    case PERIOD_MONTHLY:
      return <Message id="Monthly" comment="Label in the billing page" />;
    case PERIOD_QUARTERLY:
      return <Message id="Quarterly" comment="Label in the billing page" />;
    case PERIOD_YEARLY:
      return <Message id="Yearly" comment="Label in the billing page" />;
    default:
      return null;
  }
}

export const getRenewalDate = (billing: Billing, dateFormat: string) => (
  formatDateString(billing.renewalDate, dateFormat)
);

const Subscription = ({
  billing,
  accountSeats,
  account,
  dateFormat,
}: Props) => {
  const location = useLocation<LocationState>();

  const billingCompanyName = billing.name;
  const isAutoOpen = location.state && location.state.openBuySeatsModal === true;

  const canCancelSubscription = useMemo(
    () => checkAcl(account.acl, 'account:cancellation:create'),
    [account.acl],
  );
  const renderCancelSubscriptionButton = useMemo(() => {
    if (!canCancelSubscription) {
      return null;
    }
    return (
      <div className={style.CancelButtonContainer}>
        <CancellationFlowComponent />
      </div>
    );
  }, [canCancelSubscription]);

  return (
    <>
      <BillingSection
        title={
          <Message id="Subscription" comment="Heading" />
        }
        items={[
          {
            id: 'plan',
            label: <Message id="Plan" comment="Heading for the current plan" />,
            value: getFormattedPlanName({ plan: account.plan.name }),
          },
          {
            id: 'renewalDate',
            label: <Message id="Renewal date" comment="Renewal date" />,
            value: getRenewalDate(billing, dateFormat),
          },
        ]}
      />
      <ChangePlanComponent billing={billing} />
      <div className={style.Divider}>
        <Divider />
      </div>
      <BillingSection
        items={[
          {
            id: 'paymentInterval',
            label: <Message id="Payment interval" comment="Billing interval" />,
            value: renderPaymentInterval(billing),
          },
          {
            id: 'seats',
            label: <Message id="Seats used" comment="License usage info" />,
            value: (
              <Message
                id="{taken} of {total}"
                comment="Seats in use"
                values={{
                  taken: accountSeats.taken,
                  total: accountSeats.total,
                }}
              />
            ),
          },
        ]}
      />
      <BuySeatsButton
        account={account}
        accountSeats={accountSeats}
        billing={billing}
        isAutoOpen={isAutoOpen}
        billingCompanyName={billingCompanyName}
      />
      {renderCancelSubscriptionButton}
    </>
  );
};

export default Subscription;
