import * as React from 'react';
import { Message } from '@oneflowab/pomes';
import { useSelector } from 'react-redux';
import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';

import { isUserLimited } from 'user';
import MessageParticipantsIcon from 'components/icons/message-participants';
import { MenuItem } from 'components/menu-item';
import MessageParticipantsModal from 'components/modals/message-participants';
import { isMessageParticipantsVisible } from 'agreement/selectors';
import * as permissions from 'agreement/permissions';

export type State = {
  isOpen: boolean,
};

export type Props = {
  agreement: Oneflow.Agreement,
};

export const MessageParticipants = ({
  agreement,
}: Props) => {
  const position = useSelector(getPositionFromSessionSelector);
  const account = useSelector(getAccountFromSessionSelector);

  const canPerformAction = () => permissions.allowMessage(agreement, account)
  && !isUserLimited(position);

  const getChildren = (onClick: React.MouseEventHandler) => (
    <MenuItem
      icon={MessageParticipantsIcon}
      label={(
        <Message
          id="Message participants"
          comment="Action to send an instant reminder email to participants."
        />
      )}
      disabled={!canPerformAction()}
      onClick={onClick}
    />
  );

  if (!isMessageParticipantsVisible(agreement)) {
    return null;
  }

  return (
    <MessageParticipantsModal
      agreementId={agreement.id}
    >
      {getChildren}
    </MessageParticipantsModal>
  );
};
