import {
  useRef,
  useState,
} from 'react';
import {
  find,
  isEmpty,
  some,
} from 'lodash';
import {
  Form,
  FormRenderProps,
} from 'react-final-form';
import { localize } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import type { MessageTranslator } from '@oneflowab/pomes';

import {
  composeValidators,
  // eslint-disable-next-line import/named
  maxLength,
} from 'forms/validators';
import { DEFAULT_ROUNDING_PRECISION } from 'utils/math';
import { updatePriceColumnsAction } from 'reducers/current-contract';
import { useProductTableBoxContext } from 'contexts/product-table-box-context';
import useCurrentPriceRoundingMethod from 'hooks/use-current-price-rounding-method';
import usePopupDialog from 'hooks/popup/use-popup-dialog';
import type {
  Column,
  Config,
  ConfigUpdateData,
  PriceColumn,
} from 'data-validators/entity-schemas/document-box/product-table';

import {
  AFFIX_MAX_LENGTH,
  COL_NAME_MAX_LENGTH,
} from 'components/contract-boxes/product-table-box/constants';
import { CancelButton } from 'components/buttons';
import { getUpdatedProduct } from 'components/contract-boxes/product-table-box/product-table/popup-forms/update-product-item';
import AffixFields from 'components/contract-boxes/product-table-box/product-table/popup-forms/affix-fields';
import Button from 'components/button';
import CheckBox from 'components/checkbox';
import DecimalSelector from 'components/contract-boxes/product-table-box/product-table/popup-forms/decimal-selector';
import Field from 'components/field';
import Message from 'components/message';
import TextField from 'components/text-field';
import type { UpdatedProduct } from 'components/contract-boxes/product-table-box/product-table/popup-forms/update-product-helpers';

import style from './price.module.scss';

type Props = {
  column: PriceColumn,
  config: Config,
  message: MessageTranslator,
  popupType: 'popover' | 'dialog',
  updateProductConfig: (configData: ConfigUpdateData) => void,
  updateProductData: (productId: number, updatedProduct: UpdatedProduct) => void;
};

type FormValues = {
  columnName: string,
  oneTimePayment: PriceColumn['fixedAmount'],
  postfix: string,
  prefix: string,
  pricePrecision: number,
};

export const PricePopupForm = ({
  column, config, updateProductConfig, popupType, message, updateProductData,
}: Props) => {
  const currentPriceRoundingMethod = useCurrentPriceRoundingMethod();
  const dispatch = useDispatch();
  const { productData, enabledColumnsKeyLabelMapper } = useProductTableBoxContext();
  const { productId, bodyData } = productData;
  const [decimalValue, setDecimalValue] = useState(
    Number(config.pricePrecision) === 0 ? 0 : config.pricePrecision
    || DEFAULT_ROUNDING_PRECISION,
  );
  const [sumPrecision, setSumPrecision] = useState(
    Number(config.sumPrecision) === 0 ? 0 : config.sumPrecision
    || DEFAULT_ROUNDING_PRECISION,
  );

  const closeButtonRef = useRef<HTMLInputElement>(null);
  const closePopup = () => closeButtonRef.current?.click();
  const { Popup } = usePopupDialog(popupType);
  const { label } = column;

  const handleValueChange = (value: number) => {
    setDecimalValue(Number(value));
    setSumPrecision(Number(value));
  };

  const columnNameValidator = composeValidators(
    maxLength({
      message,
      field: 'columnName',
      customMessage: message({
        id: 'Maximum {maxLength} characters allowed.',
        comment: 'Validation message for price price affix validation.',
        values: {
          maxLength: COL_NAME_MAX_LENGTH,
        },
      }),
      maxLength: COL_NAME_MAX_LENGTH,
    }),
  );

  const affixValidations = composeValidators(
    maxLength({
      message,
      field: 'prefix',
      customMessage: message({
        id: 'Maximum {maxLength} characters allowed.',
        comment: 'Validation message for price price affix validation.',
        values: {
          maxLength: AFFIX_MAX_LENGTH,
        },
      }),
      maxLength: AFFIX_MAX_LENGTH,
    }),
    maxLength({
      message,
      field: 'postfix',
      customMessage: message({
        id: 'Maximum {maxLength} characters allowed.',
        comment: 'Validation message for price price affix validation.',
        values: {
          maxLength: AFFIX_MAX_LENGTH,
        },
      }),
      maxLength: AFFIX_MAX_LENGTH,
    }),
  );

  const handleSubmit = ({
    columnName, oneTimePayment, prefix, postfix,
  }: FormValues) => {
    const configData = {
      column: {
        ...column,
        label: columnName || '',
        fixedAmount: oneTimePayment as 0 | 1,
      } as Column,
      affixes: {
        prefix,
        postfix,
      },
      pricePrecision: decimalValue,
      sumPrecision,
    };

    const updatedProductData = getUpdatedProduct(
      bodyData,
      enabledColumnsKeyLabelMapper,
      currentPriceRoundingMethod,
      decimalValue,
    );

    updateProductData(productId as number, updatedProductData);
    updateProductConfig(configData);
    dispatch(updatePriceColumnsAction());
    closePopup();
  };

  const isPrice1Enabled = find(config.columns, { key: 'price_1' })?.enabled;
  const isAffixOrDecimalFieldsInvisible = isPrice1Enabled && column.key === 'price_2';

  const renderForm = (formProps: FormRenderProps<FormValues>) => {
    const disabled = some([
      formProps.invalid,
      formProps.submitting,
      !isEmpty(formProps.errors),
    ]);

    return (
      <form
        className={style.ProductTablePopupForm}
        onSubmit={formProps.handleSubmit}
      >
        <div className={style.PopupHeader}>
          <Message id="Edit Column" comment="Header for edit product table column form" />
        </div>
        <div className={style.PopupFormContainer}>
          <div className={clsx(style.ColumnNameInputContainer, style.PopupFormElement)}>
            <Field
              label={<Message id="Column name" comment="Label for field to edit column name" />}
              name="columnName"
              component={TextField}
              customClass={style.PopupTextField}
              labelCustomClass={style.PopupTextFieldLabel}
              validate={columnNameValidator}
              autoComplete="off"
            />
          </div>
          {!isAffixOrDecimalFieldsInvisible
          && (
            <AffixFields
              message={message}
              affixValidations={affixValidations}
            />
          )}
          {!isAffixOrDecimalFieldsInvisible
          && (
            <DecimalSelector
              type="productTable"
              decimalValue={decimalValue}
              handleValueChange={handleValueChange}
            />
          )}
          <div className={style.PopupFormElement}>
            <Field
              customLabelStyle={style.OneTimePaymentLabel}
              name="oneTimePayment"
              component={CheckBox}
              type="checkbox"
              label={<Message id="One-time Payment" comment="Checkbox label to denote if product table price is a one-time payment" />}
              checked={column.fixedAmount}
            />
            <p className={style.OneTimeParagraph}>
              <Message id="Add as a one-time payment to the total cost, regardless of quantity." comment="Helper text for one time payment checkbox" />
            </p>
          </div>
          <div className={style.ActionButtonsContainer}>
            <Popup.Close asChild>
              <CancelButton />
            </Popup.Close>
            <Button
              data-testid="save-button"
              type="submit"
              kind="secondary"
              disabled={disabled}
            >
              <Message
                id="Save"
                comment="Action to save when the document period reminder should be set."
              />
            </Button>
            <Popup.Close asChild>
              {/* No translation needed and not part of accessibility */}
              <Button
                aria-hidden="true"
                customClass={style.HiddenButton}
                buttonRef={closeButtonRef}
                type="button"
              >
                Programmatic hidden close button
              </Button>
            </Popup.Close>
          </div>
        </div>
      </form>
    );
  };

  return (
    <Form
      render={renderForm}
      initialValues={{
        columnName: label,
        oneTimePayment: column.fixedAmount,
        prefix: config.affixes.prefix,
        postfix: config.affixes.postfix,
      }}
      onSubmit={handleSubmit}
    />
  );
};

export default localize(PricePopupForm);
