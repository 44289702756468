import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

import { getSignAttempted } from 'reducers/current-contract';

type AttachmentBoxValidationContextType = {
  touched: boolean;
  setTouched: Dispatch<SetStateAction<boolean>>;
};

const AttachmentBoxValidationContext = createContext<AttachmentBoxValidationContextType | null>(
  null,
);

const AttachmentBoxValidationProvider = ({ children }: { children: ReactNode }) => {
  const signAttempted = useSelector(getSignAttempted);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (signAttempted) {
      setTouched(true);
    }
  }, [signAttempted]);

  return (
    <AttachmentBoxValidationContext.Provider value={{ touched, setTouched }}>
      {children}
    </AttachmentBoxValidationContext.Provider>
  );
};

export default AttachmentBoxValidationProvider;

export const useAttachmentBoxValidationContext = () => {
  const contextValue = useContext(AttachmentBoxValidationContext);

  if (!contextValue) {
    throw new Error('useAttachmentBoxValidationContext must be used within a AttachmentBoxValidationProvider');
  }

  return contextValue;
};
