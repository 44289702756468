import * as React from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';

import Divider from 'components/divider';

import style from './details-footer.module.scss';

// Strings, numbers and ReactNode (translation message etc)
type InfoPair = {
  label: React.ReactNode;
  value: React.ReactNode;
};

type Props = {
  infoPairs: InfoPair[];
  useMarginTop?: boolean;
};

export const DetailsFooter = ({ infoPairs, useMarginTop }: Props) => {
  const classes = clsx(style.VideoActionButton, style.Divider, {
    [style.DividerWithMarginTop]: useMarginTop,
  });

  return (
    <>
      <Divider solid className={classes} />
      <div className={style.InfoContainer}>
        {infoPairs.map(({ label, value }) => (
          <div key={uniqueId()} className={style.InfoBox}>
            <span>{label}</span>
            <span className={style.InfoValue}>{value}</span>
          </div>
        ))}
      </div>
    </>
  );
};
