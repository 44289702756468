import { ComponentProps } from 'react';
import clsx from 'clsx';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Box } from 'data-validators/entity-schemas/document-box';
import { isBoxDataReorderAllowed } from 'agreement/box-data-reorder-permissions';

import { getId } from 'components/contract-boxes/generic-box-helpers';
import ProductRow from 'components/contract-boxes/product-table-box/product-table/product-table-expanded/product-row';
import type { Props as ProductRowProps } from 'components/contract-boxes/product-table-box/product-table/product-table-expanded/product-row/product-row';
import SmallDragHandlerIcon from 'components/icons/small-drag-handler';

import style from './sortable-product-row.module.scss';

type SortableProductRowProps = {
  index: number,
  box: Box,
} & ComponentProps<'div'> & ProductRowProps;

const SortableProductRow = (props: SortableProductRowProps) => {
  const { box, product } = props;
  const productId = getId(product);
  const productName = product?.value?.name;
  const isAllowedToReorderProducts = isBoxDataReorderAllowed(box);

  const {
    setNodeRef,
    transition,
    attributes,
    transform,
    listeners,
    isDragging,
  } = useSortable({
    id: productId,
    animateLayoutChanges: () => false,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
      className={clsx(style.SortableProductRowWrapper, {
        [style.IsDragging]: isDragging,
        [style.ReadOnly]: !isAllowedToReorderProducts,
      })}
    >
      <div
        className={style.SortableDragContainerWrapper}
        {...attributes}
        {...listeners}
      >
        <div className={style.SortableDragContainer}>
          <div className={style.SortableDragIcon}>
            <SmallDragHandlerIcon width="10px" height="10px" />
          </div>
          {isDragging && (
            <div className={style.SortableDragProductName}>{productName}</div>
          )}
        </div>
      </div>
      <ProductRow {...props} />
    </div>
  );
};

export default SortableProductRow;
