// @flow

export const USER_ROLE_LIMITED = 'limited';
export const USER_ROLE_USER = 'user';
export const USER_STATE_INVITED = 2;
export const USER_NOT_INVITED = 0;
export const USER_ACTIVE = 1;
export const USER_INACTIVE = 0;
export const USER_ROLE_TYPE_SYSTEM = 'system';
export const USER_ROLE_TYPE_ACCOUNT = 'account';
export const USER_ACTOR_TYPE = 'position';
export const USER_ROLE_SCOPE_WORKSPACE = 'workspace';
export const USER_ROLE_SCOPE_ACCOUNT = 'account';

// SYSTEM ROLES
export const USER_ACCOUNT_ROLE_ADMIN_ID = 1;
export const USER_WORKSPACE_ROLE_READ_ONLY = 9;
export const USER_WORKSPACE_ROLE_MANAGER_ID = 7;

export const INVITE_USER_QUERY = 'iu';
