// @flow
import { decamelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';

import {
  getMultiple,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import type { SortParams } from 'oneflow-client/core';

import { accountSchema } from 'oneflow-client/workspaces';
import { positionSchema } from 'oneflow-client/positions';

const auditLogSchema = new schema.Entity('auditLogs', {
  account: accountSchema,
  actorPosition: positionSchema,
});

export type NormalizedAuditLogs = {
  entities: {
    auditLogs: {
      [number]: AuditLogEvent,
    },
    positions: {
      [number]: Position,
    },
  },
  result: number | Array<number>,
  count: number,
};

type AuditLogNormalizer = (any) => NormalizedAuditLogs;

export const normalizeAuditLogs: AuditLogNormalizer = (auditLogs) => ({
  ...normalize(auditLogs.collection, [auditLogSchema]),
  count: auditLogs.count,
});

type GetAuditLogs = ({
  params?: {},
  pagination?: {},
  sorting?: SortParams,
}) => Promise<NormalizedAuditLogs>;

export const getAuditLogs: GetAuditLogs = ({
  pagination,
  params,
}) => (
  getMultiple({
    url: '/audit_log/',
    pagination,
    params: decamelizeKeys(params, { separator: '_' }),
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeAuditLogs)
);
