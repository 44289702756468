// @flow

import get from 'lodash/get';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import agreementsReducer from 'reducers/entities/agreements';

import SetContractValue, { type Props, type FormData } from './set-contract-value';

type StateProps = {|
  updateState: UpdateState,
  currentContractValue?: number,
|};

type DispatchProps = {|
  setContractValue: FormData => void,
  resetUpdateState: () => void,
|};

type OwnProps = $Diff<$Exact<Props>, StateProps & DispatchProps> & {
  agreement: Agreement,
};

type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export const mapStateToProps: MapStateToProps = (state, { agreement }) => ({
  updateState: agreementsReducer.getUpdateContractValueSelector(state, { id: agreement.id }),
  currentContractValue: get(agreement, 'agreementValue.amount'),
});

type MapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch, { agreement }) => ({
  setContractValue: ({ contractValue }: FormData) => {
    dispatch(agreementsReducer.updateContractValue({
      id: agreement.id,
      data: {
        amount: contractValue || null,
      },
    }));
  },
  resetUpdateState: () => {
    dispatch(agreementsReducer.updateContractValueReset({
      id: agreement.id,
    }));
  },
});

export default connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(SetContractValue);
