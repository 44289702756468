// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import { RemoveConfirm } from '../remove-confirm';

type Props = {
  ids: Array<Tag>,
  removeState: RemoveState,
  resetRemoveState: Function,
  deleteTags: () => void,
  children: Function,
};

export const handleConfirm = (deleteTags: Function, isLoading: boolean) => () => {
  if (isLoading) {
    return;
  }

  deleteTags();
};

export const DeleteTagsConfirmation = ({
  ids,
  removeState,
  deleteTags,
  resetRemoveState,
  children,
}: Props) => (
  <RemoveConfirm
    onConfirm={handleConfirm(deleteTags, removeState.loading)}
    confirmState={removeState}
    resetConfirmState={resetRemoveState}
    confirmMessage={(
      <>
        <Message
          id="This tag will be also removed from all contracts and templates where it is used."
          pluralId="{count} tags will be also removed from all contracts and templates where they are used."
          pluralCondition="count"
          values={{
            count: ids.length,
          }}
          comment="Warning message in modal when attempting to remove account tags"
        />
        <br />
        <Message
          id="Are you sure you want to delete the tag?"
          pluralId="Are you sure you want to delete the tags?"
          pluralCondition="count"
          values={{
            count: ids.length,
          }}
          comment="Warning message in modal when attempting to remove account tags"
        />
      </>
    )}
    modalKey="delete tags in bulk modal"
  >
    {children}
  </RemoveConfirm>
);
