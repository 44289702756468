import {
  getDateWithSetHour,
  isTimeSameOrBefore,
  getCurrentHour,
} from 'date';

export default function isPastEvent(date) {
  const nextHour = getCurrentHour() + 1;
  const todayDate = getDateWithSetHour(new Date(Date.now()), nextHour);

  return !isTimeSameOrBefore(todayDate, date);
}
