import type { ReactNode } from 'react';
import { isValidElementType } from 'react-is';
import clsx from 'clsx';

import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import boxTypes from 'agreement/box-types';
import boxIcons from 'agreement/box-icons';

import * as DropdownMenu from 'components/dropdown-menu-v2';

import style from './box-menu-item.module.scss';

export type Props = {
  onSelect: (type: number) => void,
  boxType: ContractView.BoxType
  message: MessageTranslator,
  disabled?: boolean,
};

export const BoxMenuItemComponent = (props: Props) => {
  const {
    onSelect,
    boxType,
    message,
    disabled,
  } = props;

  const name = boxTypes(message)[boxType];
  const icon = boxIcons[boxType];
  const IconComponent = icon;
  let iconElement: ReactNode = null;
  if (isValidElementType(IconComponent)) {
    iconElement = <IconComponent />;
  }

  return (
    <DropdownMenu.Item
      key={name as string}
      onSelect={() => onSelect(boxType)}
      disabled={disabled}
      className={clsx(style.MenuItem, {
        [style.Disabled]: disabled,
      })}
    >
      {iconElement}
      {name}
    </DropdownMenu.Item>
  );
};

export default localize<Props>(BoxMenuItemComponent);
