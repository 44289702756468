// @flow

import * as React from 'react';
import { Transition } from 'react-transition-group';

import Button from 'components/button';
import MenuArrow from 'components/icons/menu-arrow';
import SidebarLogoSymbol from 'components/sidebar-logo-symbol';
import SidebarLogoExpanded from 'components/sidebar-logo-expanded';

import style from './sidebar-header.module.scss';

type Props = {
  expanded: boolean,
  isMobile: boolean,
  showExpandSymbol: boolean,
  toggleExpandedState: () => void,
  isContractView: boolean,
  mobileMenuOpen: boolean,
  toggleMobileMenu: () => void,
};

const SidebarHeader = ({
  expanded,
  isMobile,
  showExpandSymbol,
  toggleExpandedState,
  isContractView,
  mobileMenuOpen,
  toggleMobileMenu,
}: Props) => {
  const transitions = {
    entering: {
      display: 'block',
    },
    entered: {
      opacity: 1,
      display: 'block',
    },
    exiting: {
      opacity: 0,
      display: 'block',
    },
    exited: {
      opacity: '0',
      display: 'none',
    },
  };

  const handleOnKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    toggleExpandedState();
  };

  const renderSideBarHeader = () => {
    if (isContractView && !isMobile) {
      return (
        <div style={{
          left: '0',
          margin: '0 auto',
          position: 'absolute',
          width: '72px',
        }}
        >
          <SidebarLogoSymbol
            isContractView={isContractView}
            toggleExpandedState={toggleExpandedState}
          />
        </div>
      );
    }

    return (
      <>
        <Transition in={!expanded && !showExpandSymbol && !isMobile} timeout={0}>
          {(state) => (
            <div
              style={{
                display: 'none',
                left: '0',
                margin: '0 auto',
                opacity: 0,
                position: 'absolute',
                transition: 'all 300ms',
                width: '72px',
                ...transitions[state],
              }}
            >
              <SidebarLogoSymbol
                isContractView={isContractView}
                toggleExpandedState={toggleExpandedState}
              />
            </div>
          )}
        </Transition>
        <Transition in={expanded || isMobile} timeout={0}>
          {(state) => (
            <div
              style={{
                display: 'none',
                opacity: 0,
                transition: 'all 300ms',
                ...transitions[state],
              }}
              className={style.SidebarLogoExpanded}
            >
              <SidebarLogoExpanded
                isContractView={isContractView}
                mobileMenuOpen={mobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}
                toggleExpandedState={toggleExpandedState}
              />
            </div>
          )}
        </Transition>
        <Transition in={!expanded && showExpandSymbol && !isMobile} timeout={0}>
          {(state) => (
            <div
              onClick={toggleExpandedState}
              onKeyDown={handleOnKeyDown}
              role="button"
              style={{
                cursor: 'pointer',
                display: 'none',
                margin: '0 auto',
                opacity: 0,
                position: 'absolute',
                right: '0',
                top: '20px',
                transition: 'all 300ms',
                width: '72px',
                ...transitions[state],
              }}
              tabIndex={0}
            >
              <MenuArrow height="24px" />
            </div>
          )}
        </Transition>
        <Transition in={expanded && showExpandSymbol && !isMobile} timeout={0}>
          {(state) => (
            <Button
              customClass={style.CloseButton}
              icon={() => <MenuArrow height="24px" className={style.Reversed} />}
              onClick={toggleExpandedState}
              style={{
                transition: 'all 300ms',
                opacity: 0,
                display: 'none',
                ...transitions[state],
              }}
            />
          )}
        </Transition>
      </>
    );
  };

  return (
    <>
      {renderSideBarHeader()}
    </>
  );
};

export default SidebarHeader;
