// @flow

import * as React from 'react';
import moment from 'moment';

import RecordRTC from 'utils/recordrtc';
import performance from 'utils/performance';

import CameraControls from './camera-controls';
import RecordTimer from './record-timer';
import MicrophoneControl from './microphone-control';
import VideoFeedback from './video-feedback';
import Playback from './playback';

import style from './video-recorder.module.scss';

export const FIVE_MINUTES = moment.duration(5, 'minutes').asMilliseconds();

type Recorder = {
  startRecording: () => void,
  pauseRecording: () => void,
  resumeRecording: () => void,
  stopRecording: (Function) => void,
  getState: () => 'inactive' | 'recording' | 'paused' | 'inactive',
  getBlob: () => Blob,
};

export type Props = {|
  onStop: (Blob) => void,
  onCancel: () => void,
  onClear: () => void,
  recordedVideo?: Blob,
  stream: MediaStream,
|};

type State = {
  isCountingDown: boolean,
  isRecording: boolean,
  isPaused: boolean,
  countDown: number,
  startTimestamp: number,
  pausedTimestamp: number,
  totalPausedTime: number,
  duration: number,
  recorder?: Recorder,
};

class VideoRecorder extends React.Component<Props, State> {
  static defaultProps = {
    recordedVideo: undefined,
  };

  state: State = {
    isCountingDown: false,
    isRecording: false,
    isPaused: false,
    countDown: 0,
    startTimestamp: 0,
    pausedTimestamp: 0,
    totalPausedTime: 0,
    duration: 0,
    recorder: undefined,
  }

  componentWillUnmount() {
    this.shutdownRecorder();
    this.clearCountdownInterval();
  }

  startCountdown = () => {
    this.setState({
      isCountingDown: true,
      countDown: 3,
    });

    this.countdownInterval = setInterval(this.countDown, 1000);
  }

  countDown = () => {
    if (this.state.countDown === 1) {
      this.clearCountdownInterval();
      this.startRecording();

      return;
    }

    this.setState((prevState: State) => ({
      countDown: prevState.countDown - 1,
    }));
  }

  startRecording = () => {
    const { stream } = this.props;

    if (!stream || !stream.active) {
      return;
    }

    const recorder = RecordRTC(stream, {
      type: 'video',
      audio: {
        sampleRate: 44100,
      },
      video: true,
      mimeType: 'video/webm;codecs="vp8, opus"',
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 3000000,
      timeSlice: 500,
      onTimeStamp: () => {
        this.setState({
          duration: this.getDuration(),
        });
      },
    });

    recorder.setRecordingDuration(FIVE_MINUTES, this.stopRecording);

    this.setState({
      isRecording: true,
      isPaused: false,
      isCountingDown: false,
      startTimestamp: performance.now(),
      totalPausedTime: 0,
      pausedTimestamp: 0,
      recorder,
    }, () => {
      recorder.startRecording();
    });
  }

  pauseRecording = () => {
    const { recorder, isRecording } = this.state;

    if (isRecording && recorder) {
      this.setState({
        isPaused: true,
        pausedTimestamp: performance.now(),
      }, () => {
        recorder.pauseRecording();
      });
    }
  }

  resumeRecording = () => {
    const { recorder, isRecording } = this.state;

    if (isRecording && recorder) {
      this.setState((prevState: State) => ({
        isPaused: false,
        pausedTimestamp: undefined,
        totalPausedTime: (
          prevState.totalPausedTime + (performance.now() - prevState.pausedTimestamp)
        ),
      }), () => {
        recorder.resumeRecording();
      });
    }
  }

  getDuration = () => {
    const { isRecording, startTimestamp, totalPausedTime } = this.state;

    if (!isRecording) {
      return 0;
    }

    return performance.now() - (startTimestamp + totalPausedTime);
  };

  stopRecording = () => {
    const { isRecording, recorder } = this.state;
    const { onStop } = this.props;

    if (!recorder || !isRecording) {
      return;
    }

    this.setState({
      isPaused: false,
      isRecording: false,
      recorder: undefined,
      totalPausedTime: 0,
      startTimestamp: 0,
      duration: 0,
    }, () => {
      recorder.stopRecording(() => {
        onStop(recorder.getBlob());
      });
    });
  }

  shutdownRecorder = () => {
    const { recorder } = this.state;

    if (recorder && ['recording', 'paused'].includes(recorder.getState())) {
      recorder.stopRecording();
    }

    this.setState({
      recorder: undefined,
    });
  }

  clearRecording = () => {
    const { onClear } = this.props;

    this.shutdownRecorder();

    this.setState({
      isPaused: false,
      isRecording: false,
      totalPausedTime: 0,
      startTimestamp: 0,
      duration: 0,
    }, () => {
      onClear();
    });
  }

  onCancel = () => {
    const { onCancel } = this.props;

    this.shutdownRecorder();

    this.setState({
      isPaused: false,
      isRecording: false,
      totalPausedTime: 0,
      startTimestamp: 0,
      duration: 0,
    }, () => {
      onCancel();
    });
  }

  clearCountdownInterval() {
    clearInterval(this.countdownInterval);
  }

  countdownInterval: ?IntervalID;

  renderCountdown() {
    if (!this.state.isCountingDown) {
      return null;
    }

    return (
      <div className={style.Countdown}>
        {this.state.countDown}
      </div>
    );
  }

  render() {
    const {
      isRecording,
      isPaused,
      isCountingDown,
      duration,
    } = this.state;
    const {
      recordedVideo,
      stream,
    } = this.props;

    if (recordedVideo) {
      return (
        <div className={style.VideoRecorder}>
          <div className={style.Canvas}>
            <Playback src={recordedVideo} onRetake={this.clearRecording} />
          </div>
        </div>
      );
    }

    return (
      <div className={style.VideoRecorder}>
        <div className={style.RecordTimerContainer}>
          <RecordTimer
            isRecording={isRecording}
            isPaused={isPaused}
            duration={duration}
          />
        </div>
        <div className={style.Canvas}>
          <VideoFeedback src={stream} />
        </div>
        {this.renderCountdown()}
        <div className={style.CameraControlsContainer}>
          <CameraControls
            isRecording={isRecording}
            isPaused={isPaused}
            disabled={isCountingDown}
            onStart={this.startCountdown}
            onPause={this.pauseRecording}
            onResume={this.resumeRecording}
            onStop={this.stopRecording}
            onCancel={this.onCancel}
          />
        </div>
        <div className={style.MicrophoneControlContainer}>
          <MicrophoneControl src={stream} />
        </div>
      </div>
    );
  }
}

export default VideoRecorder;
