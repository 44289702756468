// @flow

import { connect } from 'react-redux';

import { isFeatureEnabledSelector } from 'reducers/session';

import UserRole from './user-roles';

export const mapStateToProps = (state: State) => {
  const isPositionLimitedFeatureEnabled = isFeatureEnabledSelector(state, {
    feature: 'temporaryPositionLimited',
  });

  return {
    isPositionLimitedFeatureEnabled,
  };
};

export default connect<any, State, any, any, any, any>(mapStateToProps)(UserRole);
