import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ReactNode } from 'react';
import {
  MeasuringStrategy,
  DndContext,
  pointerWithin,
} from '@dnd-kit/core';
import type {
  DragStartEvent,
  DragCancelEvent,
  DragEndEvent,
  UniqueIdentifier,
  CollisionDetection,
  Modifiers,
} from '@dnd-kit/core';
import { isBoolean } from 'lodash';

export type OnDragStart = (event: DragStartEvent) => void;
export type OnDragCancel = (event: DragCancelEvent) => void;
export type OnDragEnd = (event: DragEndEvent) => void;

type SegmentedDndEventListeners = {
  onDragStart?: OnDragStart,
  onDragCancel?: OnDragCancel,
  onDragEnd?: OnDragEnd,
  collisionDetection?: CollisionDetection,
  modifiers?: Modifiers,
  autoScroll?: boolean,
};

type DocumentDndContextType = {
  activeDndId?: UniqueIdentifier | null,
  activeDndSegmentId?: unknown | null,
  registerSegmentDndEventListeners?: (
    segmentId: UniqueIdentifier,
    segmentDndEventListeners: SegmentedDndEventListeners,
  ) => void,
  unregisterSegmentDndEventListeners?: (segmentId: UniqueIdentifier) => void,
  onDragStart?: OnDragStart,
  onDragCancel?: OnDragCancel,
  onDragEnd?: OnDragEnd,
};

export const DocumentDndContext = createContext<DocumentDndContextType | null>(null);

type Props = {
  children: ReactNode,
};

type DndEventListeners = {
  [segmentId: string]: SegmentedDndEventListeners | null,
};

const documentCollisionDetectionAlgorithm: CollisionDetection = (args) => {
  const activeCollisionDetection = args.active?.data.current?.collisionDetection;

  if (activeCollisionDetection) {
    return activeCollisionDetection(args);
  }

  return pointerWithin(args);
};

export const DocumentDndContextProvider = ({
  children,
}: Props) => {
  const [autoScroll, setAutoScroll] = useState(false);
  const [activeModifiers, setActiveModifiers] = useState<Modifiers>([]);
  const [activeDndId, setActiveDndId] = useState<UniqueIdentifier | null>(null);
  const [activeDndSegmentId, setActiveDndSegmentId] = useState<string | null>(null);
  const dndEventListeners = useRef<DndEventListeners>({});

  const registerSegmentDndEventListeners = useCallback((
    segmentId: UniqueIdentifier,
    segmentDndEventListeners: SegmentedDndEventListeners,
  ): void => {
    dndEventListeners.current[segmentId] = segmentDndEventListeners;
  }, []);

  const unregisterSegmentDndEventListeners = useCallback((
    segmentId: UniqueIdentifier,
  ): void => {
    dndEventListeners.current[segmentId] = null;
  }, []);

  const onDragStart = useCallback((event: DragStartEvent) => {
    const segmentId = event.active.data.current?.segmentId;
    const segmentDndEventListeners = dndEventListeners.current[segmentId];
    if (segmentDndEventListeners?.onDragStart) {
      segmentDndEventListeners?.onDragStart(event);
    }
    if (segmentDndEventListeners?.modifiers) {
      setActiveModifiers(segmentDndEventListeners?.modifiers);
    }
    if (isBoolean(segmentDndEventListeners?.autoScroll)) {
      setAutoScroll(segmentDndEventListeners.autoScroll);
    }
    setActiveDndId(event.active.id);
    setActiveDndSegmentId(segmentId);
  }, []);

  const onDragCancel = useCallback((event: DragCancelEvent) => {
    const segmentId = event.active.data.current?.segmentId;
    const segmentDndEventListeners = dndEventListeners.current[segmentId];
    if (segmentDndEventListeners?.onDragCancel) {
      segmentDndEventListeners?.onDragCancel(event);
    }
    setAutoScroll(false);
    setActiveDndId(null);
    setActiveDndSegmentId(segmentId);
  }, []);

  const onDragEnd = useCallback((event: DragEndEvent) => {
    const segmentId = event.active.data.current?.segmentId;
    const segmentDndEventListeners = dndEventListeners.current[segmentId];
    if (segmentDndEventListeners?.onDragEnd) {
      segmentDndEventListeners?.onDragEnd(event);
    }
    setAutoScroll(false);
    setActiveDndId(null);
    setActiveDndSegmentId(null);
    setActiveModifiers([]);
  }, []);

  const contextValue = useMemo(() => ({
    activeDndId,
    activeDndSegmentId,
    registerSegmentDndEventListeners,
    unregisterSegmentDndEventListeners,
    onDragStart,
    onDragCancel,
    onDragEnd,
  }), [
    activeDndId,
    activeDndSegmentId,
    registerSegmentDndEventListeners,
    unregisterSegmentDndEventListeners,
    onDragStart,
    onDragCancel,
    onDragEnd,
  ]);

  return (
    <DocumentDndContext.Provider value={contextValue}>
      <DndContext
        onDragStart={onDragStart}
        onDragCancel={onDragCancel}
        onDragEnd={onDragEnd}
        collisionDetection={documentCollisionDetectionAlgorithm}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        autoScroll={autoScroll}
        modifiers={activeModifiers}
      >
        {children}
      </DndContext>
    </DocumentDndContext.Provider>
  );
};

export const useDocumentDndContext = (): DocumentDndContextType => {
  const contextValue = useContext(DocumentDndContext);

  if (!contextValue) {
    return {};
  }

  return contextValue;
};
