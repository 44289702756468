import { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import type { MessageTranslator } from '@oneflowab/pomes';
import { localize } from '@oneflowab/pomes';
import { getCurrentLanguageSelector } from 'reducers/i18n';

import { shouldDisableField } from 'agreement/agreement-participants';

import { ExpandableOptionsHeader } from 'components/expandable-options-header';
import Field from 'components/field';
import TextField from 'components/text-field';
import { CountryField } from 'components/fields';

import type { RootState } from 'reducers';

import style from './party-company.module.scss';

type Option = {
  value: string,
  label: string,
};

type Props = {
  message: MessageTranslator,
  values?: {
    companyName?: Oneflow.Party['name'],
    country?: Oneflow.Party['country'],
    companyRegNumber?: Oneflow.Party['orgnr'],
  };
  onCountryChanged?: (option: Option) => void,
  partyAcl?: Oneflow.Party['acl'],
}

export const PartyCompany = ({
  message,
  values,
  onCountryChanged,
  partyAcl,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleArea = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
  };

  const lang = useSelector((state: RootState) => getCurrentLanguageSelector(state));

  const areaClassNames = clsx(style.CompanyArea, {
    [style.Hidden]: !isExpanded,
  });

  return (
    <div className={style.ExpandableArea}>
      <ExpandableOptionsHeader
        title={message({
          id: 'Company',
          comment: 'Used as the header of the section in the counterparty modal.',
        })}
        onClick={toggleArea}
        isExpanded={isExpanded}
        customClass={style.SectionHeader}
      />
      <div className={areaClassNames}>
        <div className={style.Row}>
          <Field
            name="companyName"
            label={message({
              id: 'Company name',
              comment: 'Field label in add/edit counterparty modal.',
            })}
            placeholder={message({
              id: 'Enter company name',
              comment: 'Field placeholder in add/edit counterparty modal.',
            })}
            component={TextField}
            required
            initialValue={values?.companyName}
            validateFields={[]}
            disabled={shouldDisableField(partyAcl, 'party:update:name')}
          />
          <CountryField
            languageCode={lang}
            initialOption={values?.country}
            onChange={onCountryChanged}
            disabled={shouldDisableField(partyAcl, 'party:update:country')}
            required
          />
        </div>
        <div className={style.Row}>
          <Field
            name="companyRegNumber"
            label={message({
              id: 'Registration number',
              comment: 'Field label in add/edit counterparty modal.',
            })}
            placeholder={message({
              id: 'Enter registration number',
              comment: 'Field placeholder in add/edit counterparty modal.',
            })}
            component={TextField}
            initialValue={values?.companyRegNumber}
            validateFields={[]}
            disabled={shouldDisableField(partyAcl, 'party:update:orgnr')}
            maxLength={25}
          />
        </div>
      </div>
    </div>
  );
};

export default localize<Props>(PartyCompany);
