// @flow

import {
  KEY_SSO_SAML,
} from 'extensions';

const Ssosaml = {
  key: KEY_SSO_SAML,
  typeSpecificConfigKeys: [
    'idpType',
    'metadataExchangeActive',
    'idpLoginUrl',
    'enforceSso',
    'remoteAttributes',
    'remoteGroups',
    'jitCreation',
    'domain',
  ],
};

export default Ssosaml;
