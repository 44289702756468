import { Message, MessageTranslator } from '@oneflowab/pomes';

import style from './form.module.scss';

type Props = {
  url: Oneflow.Account['logoUrl'];
  message: MessageTranslator;
};

const AccountLogo = ({ url, message }: Props) => {
  if (!url) {
    return (
      <div className={style.LogoPlaceholder} data-testid="logo-placeholder">
        <p>
          <Message
            id="No logo uploaded"
            comment="Placeholder text when no logotype has been uploaded."
          />
        </p>
      </div>
    );
  }
  return (
    <div className={style.ValueLabel} data-testid="logo">
      <img
        alt={message({
          id: 'Company logotype',
          comment: 'Used as the label of the relevant field in the account branding page.',
        }) as string}
        src={url}
      />
    </div>
  );
};

export default AccountLogo;
