import type { ReactNode } from 'react';
import { Message } from '@oneflowab/pomes';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import agreementsReducer from 'reducers/entities/agreements';
import { getMyParticipantWhenSignatory, getParticipantsSameDevice } from 'agreement/selectors';
import { isPublished } from 'agreement';
import { BADGE_SIGN_ON_SAME_DEVICE } from 'agreement/badge-icons';
import * as participantConstants from 'agreement/participant/constants';
import getSameDeviceLink from 'utils/same-device-link';
import useDetachedModalRenderer, { unmountDetachedModal } from 'hooks/use-detached-modal-renderer';

import Confirmable from 'components/confirmable';
import SignContractModal from 'components/modals/sign-contract';
import BadgeWithIcon from 'components/badge-with-icon';
import Divider from 'components/divider';
import { ButtonFC as Button } from 'components/button';
// eslint-disable-next-line import/named
import { CancelButton } from 'components/buttons';
import NewCheckIcon from 'components/icons/new-check';

import Participant from './participant/participant';
import style from './sign-on-same-device.module.scss';

type Props = {
  agreement: Oneflow.Agreement;
  children: (onClick: () => void) => ReactNode;
};

const SignOnSameDevice = ({
  agreement, children,
}: Props) => {
  const dispatch = useDispatch();
  const myParticipant = getMyParticipantWhenSignatory(agreement) as Oneflow.Participant;
  const isAgreementPublished = isPublished(agreement);
  const sameDeviceParticipants = getParticipantsSameDevice(agreement);

  const renderSignModal = () => (
    <SignContractModal
      agreementId={agreement.id}
      sendChecksum
      onClose={unmountDetachedModal}
      isOpen
    />
  );
  const handleSignModalTriggerClick = useDetachedModalRenderer(renderSignModal);

  const renderStartSigningButton = (participant: Oneflow.Participant) => {
    if (participant?.state === participantConstants.STATE_HAS_SIGNED) {
      return (
        <div className={style.SignedTextIconContainer}>
          <Message
            id="Signed"
            comment="Text for showing if a user is signed the document"
          />
          <div className={style.CheckIconBackground}>
            <NewCheckIcon style={{ color: 'white' }} />
          </div>
        </div>
      );
    }

    const startSigningOnClick = () => {
      let sameDeviceLink = getSameDeviceLink(agreement, participant);

      if (!isAgreementPublished) {
        dispatch(agreementsReducer.publishAgreement({
          id: agreement.id,
          data: {},
          pipe: {
            onSuccess: (response) => {
              if (participant.self === 1) {
                handleSignModalTriggerClick();
              } else {
                const updatedAgreement = response.entities.agreements[response.result];
                sameDeviceLink = getSameDeviceLink(updatedAgreement, participant);
                if (sameDeviceLink) {
                  window.open(sameDeviceLink);
                }
              }
            },
          },
        }));
      } else if (participant.self === 1) {
        handleSignModalTriggerClick();
      } else if (sameDeviceLink) {
        window.open(sameDeviceLink);
      }
    };

    return (
      <Button
        kind="save"
        onClick={startSigningOnClick}
        customClass={style.OpenButton}
      >
        <Message
          id="Start signing"
          comment="Button for opening the document in the new tab"
        />
      </Button>
    );
  };

  const renderBody = () => (
    <>
      <div className={style.ParticipantsWrapper}>
        <BadgeWithIcon type={BADGE_SIGN_ON_SAME_DEVICE} />
        <span className={style.Description}>
          <Message
            id="These participants can view and sign the document on this device"
            comment="Description for sign on same device modal"
          />
        </span>
        {myParticipant && (
          <>
            <div className={style.ParticipantWrapper}>
              <Participant participant={myParticipant}>
                {renderStartSigningButton(myParticipant)}
              </Participant>
            </div>
            <Divider solid />
          </>
        )}
        {sameDeviceParticipants.map((participant, index) => (
          !isEmpty(participant) && (
          <div key={participant.id}>
            <div className={style.ParticipantWrapper}>
              <Participant participant={participant}>
                {renderStartSigningButton(participant)}
              </Participant>
            </div>
            {sameDeviceParticipants.length - 1 !== index && <Divider solid />}
          </div>
          )
        ))}
      </div>
    </>
  );

  const renderActions = ({ closeConfirmation }) => (
    <CancelButton
      onClick={closeConfirmation}
    />
  );

  return (
    <Confirmable
      header={(
        <Message
          id="Sign document"
          comment="The title of the modal"
        />
        )}
      body={renderBody()}
      actions={renderActions}
      modalKey="sign-on-same-device"
    >
      {children}
    </Confirmable>
  );
};

export default SignOnSameDevice;
